import React from 'react'
import {
  VStack,
  Widget,
  Text,
  Icon,
  copyToClipboard,
  TravelWidget,
  HStack,
  Spacer,
  Box,
  Grid,
  Token,
} from '@revolut/ui-kit'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'

const REDIRECT_URL = 'https://revolutpeople.com/public/subdomain-permissions-redirect'

interface Props {
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const SalesforceHelperContent = ({ setLightBoxSrc }: Props) => {
  return (
    <VStack space="s-16">
      <Widget>
        <AutoStepper>
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Log in to your <Text fontWeight={700}>Salesforce account</Text>, go to
                Settings and click <Text fontWeight={700}>App manager</Text> in{' '}
                <Text fontWeight={700}>Platform tools</Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Click <Text fontWeight={700}>New Connected App</Text>, then{' '}
                <Text fontWeight={700}>Create a Connected App</Text>
              </Text>
            }
            subtitle="You need to be an admin or owner in order to do this"
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Enter app's basic detail, check{' '}
                <Text fontWeight={700}>Enable OAuth Settings</Text>, then ented following
                URL in
                <Text fontWeight={700}>Callback URL</Text>
              </Text>
            }
            subtitle={
              <VStack pt="s-12">
                {/* using custom component here as `Tag` is only one line, and redirect_uri can be longer */}
                <Box
                  use="button"
                  onClick={e => {
                    e.preventDefault()
                    REDIRECT_URL &&
                      copyToClipboard(REDIRECT_URL).then(() => {
                        pushNotification({
                          value: 'redirect URL copied to clipboard',
                          duration: SUCCESS_DEFAULT_DURATION,
                          type: NotificationTypes.success,
                        })
                      })
                  }}
                >
                  <Grid
                    columns="auto 1fr"
                    bg={Token.color.black}
                    color={Token.color.white}
                    borderRadius="r16"
                    placeItems="center"
                    gap="s-4"
                    px="s-8"
                    py="s-6"
                  >
                    <Icon name="Copy" size={12} />
                    <Text
                      variant="emphasis4"
                      textAlign="start"
                      style={{ overflowWrap: 'anywhere' }}
                    >
                      {REDIRECT_URL}
                    </Text>
                  </Grid>
                </Box>
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Add following <Text fontWeight={700}>Available OAuth Scopes</Text>
              </Text>
            }
            subtitle={
              <VStack gap="s-2">
                <Text variant="small">
                  Access Analytics REST API resources (wave_api)
                </Text>
                <Text variant="small">Manage user data via APIS (api)</Text>
                <Text variant="small">Manage user data via Web browsers (web)</Text>
                <Text variant="small">
                  Perform requests at any time (refresh_token, offline_access)
                </Text>
              </VStack>
            }
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Enable <Text fontWeight={700}>Token Exchange Flow</Text> and
                <Text fontWeight={700}>Refresh Token Rotation</Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Disable{' '}
                <Text fontWeight={700}>
                  Require Proof Key for Code Exchange (PKCE) Extension for Supported
                  Authorization Flows
                </Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            title={
              <Text>
                Press <Text fontWeight={700}>save</Text>, then{' '}
                <Text fontWeight={700}>continue</Text>
              </Text>
            }
            bulletVariant="filled"
          />
          <NewStepperTitle
            type={NewStepperTitleType.coreUI}
            bulletVariant="filled"
            title={
              <Text>
                Click <Text fontWeight={700}>Manage Customer details</Text> to obtain
                Client ID and and Client Secret
              </Text>
            }
          />
        </AutoStepper>
      </Widget>

      <TravelWidget
        use="div"
        variant="large"
        image={[
          'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help_preview.png',
        ]}
      >
        <TravelWidget.Details
          use="button"
          onClick={() =>
            setLightBoxSrc({
              video:
                'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help.mp4',
              poster:
                'https://assets.revolut.com/revoluters/assets/integrations/salesforce_integration_help_preview.png',
            })
          }
        >
          <HStack align="center">
            <Text>Watch video</Text>
            <Spacer />
            <Icon name="ChevronRight" />
          </HStack>
        </TravelWidget.Details>
      </TravelWidget>
    </VStack>
  )
}
