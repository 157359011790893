import React, { useMemo } from 'react'
import { Input, InputGroup, Text, Widget } from '@revolut/ui-kit'
import {
  QueryParameterInterface,
  QueryParameterTypes,
} from '@src/interfaces/dataAnalytics'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

// TODO: remove forced options after BE fix
const parameterTypes = [
  QueryParameterTypes.Text,
  QueryParameterTypes.Date,
  QueryParameterTypes.Number,
]
const parameterTypesOptions = parameterTypes.map((value, index) => ({
  label: value,
  value: {
    id: index,
    name: value,
  },
}))

interface Props {
  disabled: boolean
  parameter: QueryParameterInterface
}

export const ParametersSidebar = ({ disabled, parameter }: Props) => {
  const parameterType = useMemo(
    () => parameterTypesOptions.find(item => item.value.name === parameter.type),
    [parameter.type],
  )

  return (
    <Widget p="s-16">
      <InputGroup>
        <Text variant="tile">{parameter.name}</Text>
        <RadioSelectInput
          disabled={disabled}
          label="Type"
          mt="s-16"
          onChange={value => {
            if (value) {
              parameter.type = value.name
            }
          }}
          options={parameterTypesOptions}
          value={parameterType?.value}
        />
        {parameter.type === QueryParameterTypes.Date ? (
          <DatePickerInput
            disabled={disabled}
            label="Default value"
            onChange={date => {
              parameter.default = date
            }}
            value={parameter.default}
          />
        ) : (
          <Input
            disabled={disabled}
            label="Default value"
            onChange={e => {
              parameter.default = e.currentTarget.value || null
            }}
            type={parameter.type}
            value={(parameter.default as string | null) || ''}
          />
        )}
      </InputGroup>
    </Widget>
  )
}
