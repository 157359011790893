import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'
import { css } from 'styled-components'

const Highlight = css`
  fill: #f7930d;
`

export const addNode = (
  selection: AddSelection,
  t: HierarchyTransition,
  noWrongFunctional?: boolean,
) => {
  /* Highlight */
  selection
    .append('rect')
    .attr('style', d => {
      if (d.data?.highlight !== true || d.data?.disabled === true || noWrongFunctional) {
        return 'opacity: 0;'
      }
      return Highlight.join('')
    })
    .attr('class', 'CardHighlight')
    .attr('y', d => (d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight + 3)
    .attr('x', d => (d?.parent?.x ?? d.x) - HierarchyConsts.cardWidth / 2)
    .call(call =>
      call
        .transition(t)
        .attr('y', d => d.y - HierarchyConsts.cardHeight + 3)
        .attr('x', d => d.x - HierarchyConsts.cardWidth / 2),
    )
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)

  /* Card */
  selection
    .append('rect')
    .attr('class', d => (d.data.disabled ? 'Card Disabled' : 'Card'))
    .attr('y', d => (d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight)
    .attr('x', d => (d?.parent?.x ?? d.x) - HierarchyConsts.cardWidth / 2)
    .call(call =>
      call
        .transition(t)
        .attr('x', d => d.x - HierarchyConsts.cardWidth / 2)
        .attr('y', d => d.y - HierarchyConsts.cardHeight),
    )
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)
}

export const updateNode = (selection: UpdateSelection, t: HierarchyTransition) => {
  /* Highlight */
  selection
    .select('.CardHighlight')
    .transition(t)
    .attr('y', d => d.y - HierarchyConsts.cardHeight + 3)
    .attr('x', d => d.x - HierarchyConsts.cardWidth / 2)
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)

  /* Card */
  selection
    .select('.Card')
    .transition(t)
    .attr('y', d => d.y - HierarchyConsts.cardHeight)
    .attr('x', d => d.x - HierarchyConsts.cardWidth / 2)
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)
}
