import React, { useState } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupAccessRequestInterface } from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { PageBody } from '@components/Page/PageBody'
import SettingsButtons, { ApproveButton } from '@src/features/SettingsButtons'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { Group, Input, MoreBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { useGetAccessManagementSettings } from '@src/api/settings'
import {
  useGetGroupAccessApprovals,
  approveGroupAccess,
  rejectGroupAccess,
} from '@src/api/accessManagement'
import { EntityPermissions } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { CrossSmall } from '@revolut/icons'

const Preview = () => {
  const { values, reset } = useLapeContext<GroupAccessRequestInterface>()
  const { data: settings } = useGetAccessManagementSettings()

  const [isRejectPending, setIsRejectPending] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useGetGroupAccessApprovals(
    settings?.enable_group_access_request_approvals ? values.id : undefined,
  )

  const onApprove = async () => {
    const response = await approveGroupAccess(values.id)
    reset(response.data)
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectGroupAccess(values.id, rejectionComment)
      reset(response.data)
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectPending(false)
    }
  }

  const canApproveOrReject =
    settings?.enable_group_access_request_approvals &&
    values.approval_status.id === Statuses.pending &&
    values.field_options?.actions?.includes(EntityPermissions.Approve)

  return (
    <>
      <PageHeader
        title="Request to grant access"
        backUrl={ROUTES.MAIN}
        subtitle={
          values.user.user_type === 'employee' ? (
            <UserWithAvatar {...values.user.employee} />
          ) : (
            values.user.email
          )
        }
      />
      <PageBody>
        {canApproveOrReject ? (
          <SettingsButtons mb="s-24">
            <ApproveButton submit={onApprove} onAfterSubmit={refetchApproval} />
            <MoreBar.Action
              onClick={() => setRejectDialogOpen(true)}
              useIcon={CrossSmall}
              variant="negative"
              pending={isRejectPending}
            >
              Reject
            </MoreBar.Action>
          </SettingsButtons>
        ) : null}

        <ApprovalFlow isLoading={isApprovalLoading} steps={approvalSteps || null} />

        <FormPreview<GroupAccessRequestInterface> data={values} title="Request details">
          <Group>
            {values.user.user_type === 'employee' ? (
              <FormPreview.Item<GroupAccessRequestInterface>
                title="Person to grant access to"
                to={data =>
                  data.user.user_type === 'employee' && data.user.employee?.id
                    ? pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: data.user.employee.id,
                      })
                    : undefined
                }
                field="user.employee.name"
              />
            ) : (
              <FormPreview.Item<GroupAccessRequestInterface>
                title="Person to grant access to"
                field="user.email"
              />
            )}

            <FormPreview.Item<GroupAccessRequestInterface>
              title="Access group requested"
              to={data =>
                data.group?.id
                  ? pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, {
                      id: data.group?.id,
                    })
                  : undefined
              }
              field="group.name"
            />
            <FormPreview.Item title="Valid from" type="date" field="start_date_time" />
            <FormPreview.Item title="Valid to" type="date" field="end_date_time" />
            <FormPreview.Details title="Motivation" field="motivation" />
          </Group>
        </FormPreview>
      </PageBody>

      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectPending}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Input
            label="Add reason here"
            value={rejectionComment}
            onChange={e => setRejectionComment(e.currentTarget.value)}
          />
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}

export default Preview
