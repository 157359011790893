import React from 'react'
import { Token, Icon, useTooltip, Tooltip } from '@revolut/ui-kit'

export const LockedSyncIcon = () => {
  const tooltip = useTooltip()

  return (
    <>
      <Icon
        name="16/LockOutline"
        size={16}
        color={Token.color.greyTone20}
        {...tooltip.getAnchorProps()}
      />
      <Tooltip {...tooltip.getTargetProps()} maxWidth={250}>
        Some fields of this employee are automatically synced with external platform. You
        can not edit it.
      </Tooltip>
    </>
  )
}
