import styled from 'styled-components'
import { grid, GridProps, flex, FlexProps } from 'styled-system'
import { Box } from '@revolut/ui-kit'

const NewGrid = styled(Box)<GridProps & FlexProps>`
  display: grid;
  ${grid}
  ${flex}
`

export default NewGrid
