import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'

const SaveToDraftButton = () => {
  const { values, submit } = useLapeContext<InterviewFeedbackInterface>()

  if (
    values.status !== InterviewFeedbackStatus.pending &&
    values.status !== InterviewFeedbackStatus.draft
  ) {
    return null
  }

  const onClick = () => {
    const currentStatus = values.status
    values.status = InterviewFeedbackStatus.draft

    return submit().catch(error => {
      values.status = currentStatus
      throw error
    })
  }

  return (
    <NewSaveButtonWithPopup
      variant="secondary"
      successText="Interview scorecard was saved to drafts"
      hideWhenNoChanges={false}
      onClick={onClick}
    >
      Save to Drafts
    </NewSaveButtonWithPopup>
  )
}

export default connect(SaveToDraftButton)
