import React, { useEffect, useMemo, useState } from 'react'
import { Box, BoxProps, Flex, Skeleton, Widget } from '@revolut/ui-kit'
import styled from 'styled-components'

const TOTAL_SKELETON_LINES = 21
const MAX_LINE_WIDTH_PERCENTAGE = 95
const MIN_LINE_WIDTH_PERCENTAGE = 35

const LongWait = styled(Widget)`
  position: absolute;
  top: 340px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 18px;
  width: 50%;
`

const SHOW_LONG_WAIT_WARNING_TIMEOUT = 5000

const DocumentSkeleton = (props: BoxProps) => {
  const [longWait, setLongWait] = useState(false)
  // generate lines with random from min to max width percentages
  const data = useMemo(
    () =>
      Array.from(
        { length: TOTAL_SKELETON_LINES },
        () =>
          Math.random() * (MAX_LINE_WIDTH_PERCENTAGE - MIN_LINE_WIDTH_PERCENTAGE) +
          MIN_LINE_WIDTH_PERCENTAGE,
      ),
    [],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      setLongWait(true)
    }, SHOW_LONG_WAIT_WARNING_TIMEOUT)

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [])

  return (
    <>
      <Widget
        p="s-32"
        data-testid="document-skeleton"
        style={{ filter: longWait ? 'blur(15px)' : undefined }}
        {...props}
      >
        <Skeleton height={32} mx="s-16" mb="s-64" mt="s-16" width={450} />
        <Flex justifyContent="space-between" alignItems="start">
          <Box width="100%">
            {data.map((len, i) => (
              <Skeleton
                key={i}
                height={16}
                mx="s-16"
                my="s-16"
                // break blocks every 7 lines to make it looks nice, use style attr to avoid generating too many classes
                style={{ width: `${i > 0 && i % 7 === 0 ? 0 : len}%` }}
              />
            ))}
          </Box>
          <Box>
            <Skeleton height={300} width={200} borderRadius={4} />
          </Box>
        </Flex>
      </Widget>
      {longWait && (
        <LongWait p="s-32">
          We're still loading the document, but it's taking longer than we thought. Please
          hang on for a moment or try refreshing the page.
        </LongWait>
      )}
    </>
  )
}

export default DocumentSkeleton
