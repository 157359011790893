import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import { GetRequestData, GetRequestInterface, RequestInterfaceNew } from '../interfaces'
import { SeniorityInterface, SenioritySublevelInterface } from '../interfaces/seniority'
import { AxiosPromise } from 'axios'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'

export const getSeniorityList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<SeniorityInterface>> =>
  api.get(API.SENIORITY, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const seniorityRequests: RequestInterfaceNew<SeniorityInterface> = {
  get: async ({ id }) => api.get(`${API.SENIORITY}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SENIORITY}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.SENIORITY, data),
  delete: async ({ id }) => api.delete(`${API.SENIORITY}/${id}`),
}

export const useGetSeniorityList = ({
  sortBy,
  page,
  filters,
}: FetchDataQueryInterface = {}) => {
  return useFetch<GetRequestData<SeniorityInterface>>({
    url: `${API.SENIORITY}`,
    params: { params: filterSortPageIntoQuery(sortBy, filters, page) },
  })
}

export const getSenioritySublevels = (seniorityId: number, specialisationId: number) =>
  api.get<GetRequestData<SenioritySublevelInterface>>(
    `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels`,
    {
      params: { seniority__id: seniorityId },
    },
  )

export const useGetSenioritySublevels = (
  seniorityId?: number | string,
  specialisationId?: number | string,
) =>
  useFetch<GetRequestData<SenioritySublevelInterface>>(
    seniorityId && specialisationId
      ? `${API.SPECIALISATIONS}/${specialisationId}/senioritySubLevels`
      : null,
    'v1',
    {
      params: { seniority__id: seniorityId },
    },
  )
