import React from 'react'
import { Text, Token, Side } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { LaptopDeliveryInputs } from './LaptopDeliveryInputs'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'

export type Props = {
  isSidebarContent?: boolean
  refreshData?: () => void
}
const LaptopDelivery = ({ isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <PageBody>
      <Text fontSize="caption" color={Token.color.greyTone50} mb="s-16">
        How would you like to receive your work equipment?
      </Text>
      <LaptopDeliveryInputs fetchContactInfo />
      <ActionsWrapper>
        <NewSaveButtonWithPopup
          useValidator
          successText="Laptop delivery address successfully updated."
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </PageBody>
  )
}

export default LaptopDelivery
