import React, { useRef, useState } from 'react'
import { FilterButton, IconName } from '@revolut/ui-kit'

import NewFilterSelect, {
  FilterSelectType,
  NewFilterInputProps,
} from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { FilterDateRange } from '@components/Inputs/Filters/FilterDateRange/FilterDateRange'
import { ButtonFilterType } from '@components/ButtonFilters/ButtonFilters'
import { ButtonFilterTitle } from '@components/ButtonFilters/ButtonFilterTitle'
import { OptionInterface } from '@src/interfaces/selectors'

export interface ButtonFilterProps<T extends OptionInterface = OptionInterface>
  extends Omit<NewFilterInputProps<T>, 'onClose' | 'open' | 'type'> {
  title: string
  variant?: 'default' | 'inactive'
  useIcon?: IconName
  type: ButtonFilterType
  pending?: boolean
}

const ButtonFilter = <T extends OptionInterface = OptionInterface>({
  title,
  variant = 'default',
  onChange,
  value,
  useIcon,
  type,
  renderOption,
  pending,
  ...props
}: ButtonFilterProps<T>) => {
  const anchorRef = useRef<HTMLButtonElement>(null)
  const [open, setOpen] = useState(false)

  return (
    <>
      <FilterButton
        ref={anchorRef}
        useIcon={useIcon || 'Filter'}
        onClick={() => setOpen(!open)}
        onClear={value?.length ? () => onChange([]) : undefined}
        active={variant === 'default' ? !!value?.length : false}
        aria-expanded={open}
        pending={pending}
      >
        <ButtonFilterTitle type={type} title={title} value={value} />
      </FilterButton>

      {type === 'DateRange' && (
        <FilterDateRange
          onChange={o => {
            onChange(o)
            setOpen(false)
          }}
          value={value}
          open={open}
          onClose={() => setOpen(false)}
          anchorRef={anchorRef}
        />
      )}

      {(type === FilterSelectType.MultiSelect ||
        type === FilterSelectType.SingleSelect) && (
        <NewFilterSelect
          anchorRef={anchorRef}
          onChange={onChange}
          open={open}
          onClose={() => setOpen(false)}
          value={value}
          type={type as FilterSelectType}
          renderOption={renderOption}
          {...props}
        />
      )}
    </>
  )
}

export default ButtonFilter
