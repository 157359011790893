import * as React from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import AvatarUploadPopup, {
  AvatarUploadPopupProps,
} from '@src/components/AvatarUploadPopup/AvatarUploadPopup'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'

interface Props
  extends Pick<AvatarUploadPopupProps, 'circularCrop' | 'open' | 'onClose'> {
  onUpload: (file: File) => Promise<AxiosResponse<FileInterface, any>>
  onSuccess: (file: AxiosResponse<FileInterface, any>) => void
}

export const PreferencesLogoUpload = ({
  circularCrop,
  open,
  onClose,
  onSuccess,
  onUpload,
}: Props) => {
  const onSave = async (file: File) => {
    try {
      const response = await onUpload(file)
      onSuccess(response)
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to update company icon',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  return (
    <AvatarUploadPopup
      circularCrop={circularCrop}
      open={open}
      onClose={onClose}
      onSave={onSave}
      title="Upload company logo"
    />
  )
}
