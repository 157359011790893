import { useEffect, useState } from 'react'
import { OneToOneMeetingTemplate } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/hooks/useMeetingTemplate'
import {
  addMinutes,
  addWeeks,
  differenceInCalendarWeeks,
  roundToNearestMinutes,
} from 'date-fns'
import {
  calendarEventTextColor,
  highlightedEventBackgroundColor,
  makeOneToOneEventTitle,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/constants'
import {
  LargeWeeklyCalendarChangeEventInterface,
  LargeWeeklyCalendarCreateEventInterface,
  LargeWeeklyCalendarEventInterface,
} from '@components/LargeWeeklyCalendar'
import { getDateFromEventDate } from '@components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import { NavigationWeek } from '@components/NavigateWeek/NavigateWeek'

interface EventScheduler {
  event?: LargeWeeklyCalendarEventInterface
  updateEvent: (params: LargeWeeklyCalendarChangeEventInterface) => void
  addEvent: (params: LargeWeeklyCalendarCreateEventInterface) => void
}

export const useScheduleEvent = (
  meeting: OneToOneMeeting,
  template: OneToOneMeetingTemplate,
  week: NavigationWeek,
): EventScheduler => {
  const [event, setEvent] = useState<LargeWeeklyCalendarEventInterface>()
  const [startDate, setStartDate] = useState<Date>(
    roundToNearestMinutes(new Date(), { nearestTo: 30 }),
  )
  const [endDate, setEndDate] = useState<Date>(
    addMinutes(new Date(startDate), template.duration),
  )

  useEffect(() => {
    const diffInCalendarWeeks = differenceInCalendarWeeks(new Date(week.start), startDate)

    if (diffInCalendarWeeks !== 0) {
      setStartDate(addWeeks(startDate, diffInCalendarWeeks))
      setEndDate(addWeeks(endDate, diffInCalendarWeeks))
    }
  }, [week.start])

  useEffect(() => {
    setEvent({
      start: startDate,
      end: endDate,
      title: makeOneToOneEventTitle(meeting),
      color: calendarEventTextColor,
      backgroundColor: highlightedEventBackgroundColor,
    })
  }, [meeting, startDate.toISOString(), endDate.toISOString()])

  const updateEvent = ({
    event: selectedEvent,
    changes,
  }: LargeWeeklyCalendarChangeEventInterface) => {
    if (selectedEvent.isReadOnly) {
      return
    }

    if (changes.start) {
      setStartDate(prevState => getDateFromEventDate(changes.start) ?? prevState)
    }

    if (changes.end) {
      setEndDate(prevState => getDateFromEventDate(changes.end) ?? prevState)
    }
  }

  const addEvent = (changes: LargeWeeklyCalendarCreateEventInterface) => {
    if (event?.isReadOnly) {
      return
    }

    setStartDate(prevState => getDateFromEventDate(changes.start) ?? prevState)
    setEndDate(prevState => getDateFromEventDate(changes.end) ?? prevState)
  }

  return {
    event,
    updateEvent,
    addEvent,
  }
}
