import { IdAndName } from '@src/interfaces'

export const getNextDatabaseName = (options: IdAndName<string>[]) => {
  const baseName = 'SQL Database'
  // Find all options that start with 'SQL Database'
  const matchingOptions = options.filter(option => option.name.startsWith(baseName))

  if (!matchingOptions.length) {
    return baseName
  }

  const numbers = matchingOptions.map(option => {
    const match = option.name.match(/\((\d+)\)$/) // Match '(number)' at the end
    return match ? parseInt(match[1], 10) : 0 // Default to 0 if no number is present
  })

  // Get the largest number and increment it by 1
  const nextNumber = Math.max(...numbers, 0) + 1

  // Return the new name with the incremented number
  return `${baseName} (${nextNumber})`
}
