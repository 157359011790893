import React, { ReactNode } from 'react'
import { Ellipsis, Flex, Text } from '@revolut/ui-kit'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'
import { CycleOption } from '@src/interfaces/selectors'
import { Capitalize } from '@components/CommonSC/General'

export const OptionItem = <T extends CycleOption = CycleOption>({
  label,
  value,
}: {
  label?: ReactNode
  value: T
}) => {
  const { title, color } = getCycleOffsetTitleAndColor(value)

  return (
    <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
      <Ellipsis>
        <Text variant="emphasis2">{label}</Text>
      </Ellipsis>
      <Text variant="emphasis2" color={color}>
        <Capitalize>{title}</Capitalize>
      </Text>
    </Flex>
  )
}
