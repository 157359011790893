import React, { useState } from 'react'
import {
  BottomSheet,
  Button,
  Flex,
  Header,
  Input,
  ActionButton,
  Token,
  H5,
  Sticky,
  Box,
} from '@revolut/ui-kit'
import produce from 'immer'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import isEqual from 'lodash/isEqual'

interface Props {
  close: VoidFunction
  talkingPoints: string[]
  onSavePoints: (points: string[]) => void
}

const initializePoints = (talkingPoints: string[]) => {
  return talkingPoints?.length > 0 ? talkingPoints : ['']
}

export const TalkingPointsBottomSheetContent = ({
  close,
  talkingPoints,
  onSavePoints,
}: Props) => {
  const confirmationDialog = useConfirmationDialog()

  const [points, setPoints] = useState(() => initializePoints(talkingPoints))

  const filteredPoints = points.filter(Boolean)

  const handlePointChange = (index: number, newValue: string) => {
    setPoints(prevPoints =>
      produce(prevPoints, draft => {
        draft[index] = newValue
      }),
    )
  }

  const handlePointAdd = () => {
    setPoints(prevPoints => [...prevPoints, ''])
  }

  const handlePointDelete = (index: number) => {
    setPoints(prevPoints => prevPoints.filter((_, i) => i !== index))
  }

  const handlePointSave = () => {
    onSavePoints(filteredPoints)
    close()
  }

  const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !isDisabledAddButton) {
      handlePointAdd()
    }
  }

  const handleClose = () => {
    const isDirty = !isEqual(talkingPoints, filteredPoints)
    if (isDirty) {
      confirmationDialog.show({
        showHeader: false,
        body: (
          <H5 color={Token.color.foreground}>
            You have unsaved changes do you want to proceed?
          </H5>
        ),
        noMessage: 'Cancel',
        yesMessage: 'Confirm',
        onConfirm: async () => {
          close()
        },
      })
    } else {
      close()
    }
  }

  const isDisabledAddButton = filteredPoints.length !== points.length

  return (
    <>
      <Header>
        <Header.Title>Add Talking points</Header.Title>
      </Header>
      <BottomSheet.Description onKeyDown={handleEnterPress}>
        <Box style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <Flex gap="s-8" pb="s-16" flexDirection="column">
            {points.map((pointText, index) => (
              <Flex alignItems="center" gap="s-8" key={index}>
                <Input
                  label="Talking point title"
                  value={pointText}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    handlePointChange(index, event.target.value)
                  }
                />
                {points.length > 1 && (
                  <ActionButton
                    onClick={() => handlePointDelete(index)}
                    useIcon="Minus"
                  />
                )}
              </Flex>
            ))}
          </Flex>
          <Sticky
            bottom={0}
            zIndex={1}
            style={{
              backgroundColor: Token.color.groupedBackground,
              paddingTop: Token.space.s6,
            }}
          >
            <ActionButton
              disabled={isDisabledAddButton}
              onClick={handlePointAdd}
              useIcon="Plus"
            >
              Add
            </ActionButton>
          </Sticky>
        </Box>
      </BottomSheet.Description>
      <BottomSheet.Actions>
        <Button onClick={handlePointSave} variant="primary">
          Save
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
