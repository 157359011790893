import React from 'react'

import { APPLICATIONS } from '@src/constants/hub'
import { HubAppWithOnboardingScreen } from '@src/features/HubApp/HubAppWithOnboardingScreen'

const Positions = () => {
  return <HubAppWithOnboardingScreen category="roles" app={APPLICATIONS.positions} />
}

export default Positions
