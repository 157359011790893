import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Avatar, Box, Cell, Flex, Text, VStack } from '@revolut/ui-kit'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import React from 'react'

interface Props {
  champions: EmployeeOptionInterface[]
}

const BannerForCreation = ({ champions }: Props) => {
  if (!champions.length) {
    return null
  }

  return (
    <Box mt="s-16">
      <Cell data-testid="warning_on_creation">
        <VStack space="s-8">
          <Text variant="primary">KPI champions</Text>
          <Text variant="caption" color="grey-tone-50">
            These people have increased permissions to help when editing and setting KPIs
          </Text>
          <Flex gap="s-24" flexWrap="wrap">
            {champions.map(champion => {
              let initials = ''
              if (!champion.avatar && champion.full_name) {
                initials = getInitials(champion.full_name)
              }
              return (
                <Flex height={24} alignItems="center" key={champion.id} gap="s-8">
                  <Avatar image={getAvatarUrl(champion.avatar)} size={24}>
                    {initials}
                  </Avatar>
                  {champion.full_name || 'New Employee'}
                </Flex>
              )
            })}
          </Flex>
        </VStack>
      </Cell>
    </Box>
  )
}

export default BannerForCreation
