import React, { useState } from 'react'
import { Box, chain, Group, MoreBar, Subheader, Text } from '@revolut/ui-kit'
import { Archive, Delete, Pencil, Unarchive } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import {
  archivePolicyShift,
  deletePolicyShift,
  unarchivePolicyShift,
} from '@src/api/attendance'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SchedulePolicyShiftInterface } from '@src/interfaces/attendance'
import { getStatusColor } from '@src/components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SettingsButtons from '@src/features/SettingsButtons'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { Statuses } from '@src/interfaces'

const Preview = () => {
  const { values, reset } = useLapeContext<SchedulePolicyShiftInterface>()
  const params = useParams<{ policyId: string; id: string }>()

  const [archivePending, setArchivePending] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const isActive = values.status.id === Statuses.active
  const isArchived = values.status.id === Statuses.archived

  const backUrl = pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.PREVIEW, {
    id: params.policyId,
  })

  const onArchiveClick = (action: 'archive' | 'unarchive') => {
    setArchivePending(true)

    const callback = action === 'archive' ? archivePolicyShift : unarchivePolicyShift

    callback(params.policyId, params.id)
      .then(response => reset(response.data))
      .finally(() => setArchivePending(false))
  }

  const onDelete = () => {
    setDeletePending(true)

    deletePolicyShift(params.policyId, params.id)
      .then(() => goBack(backUrl))
      .catch(() => setDeletePending(false))
  }

  return (
    <PageWrapper>
      <PageHeader
        title={chain(
          values.name,
          <Text color={getStatusColor(values.status.id)}>{values.status.name}</Text>,
        )}
        backUrl={backUrl}
      >
        <SettingsButtons>
          <MoreBar.Action
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.GENERAL, params))
            }
            useIcon={Pencil}
          >
            Edit
          </MoreBar.Action>
          {isArchived ? (
            <MoreBar.Action
              onClick={() => onArchiveClick('unarchive')}
              useIcon={Unarchive}
              pending={archivePending}
            >
              Unarchive
            </MoreBar.Action>
          ) : null}
          {isActive ? (
            <MoreBar.Action
              onClick={() => onArchiveClick('archive')}
              useIcon={Archive}
              variant="negative"
              pending={archivePending}
            >
              Archive
            </MoreBar.Action>
          ) : null}
          <MoreBar.Action
            onClick={onDelete}
            useIcon={Delete}
            pending={deletePending}
            variant="negative"
          >
            Delete
          </MoreBar.Action>
        </SettingsButtons>
      </PageHeader>

      <PageBody>
        <FormPreview title="Basic information" data={values}>
          <Group>
            <FormPreview.Item title="Shift type" field="type.name" />
            <FormPreview.Item title="Day period" field="day_period.name" />
            <FormPreview.Item title="Week period" field="week_period.name" />
            <FormPreview.Item
              title="Requires approval"
              field="requires_approval"
              type="boolean"
            />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <FormPreview title="Time characteristics" data={values}>
            <Group>
              <FormPreview.Item title="Earliest start" field="start" />
              <FormPreview.Item title="Latest end" field="end" />
              <FormPreview.Item title="Minimum hours" field="minimum_hours" />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview title="Compensation" data={values}>
            <Group>
              <FormPreview.Item title="Paid shift" field="paid_shift" type="boolean" />
              <FormPreview.Item
                title="Percentage of salary"
                insert={() =>
                  values.compensation_level == null
                    ? '-'
                    : `${values.compensation_level}%`
                }
              />
              <FormPreview.Item
                title="TOIL allowed"
                field="toil_allowed"
                type="boolean"
              />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Additional information</Subheader.Title>
          </Subheader>
          <LapeHTMLEditor name="additional_information" readOnly height="auto" />
        </Box>
      </PageBody>
    </PageWrapper>
  )
}

export default Preview
