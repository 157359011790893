import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { CredentialsLoginInterface } from '@src/interfaces/auth'

export const useGetAuthentication = (token?: string) => {
  return useFetch<CredentialsLoginInterface>(
    token ? `${API.LOGIN}/saml/` : null,
    'v2',
    { params: { token } },
    true,
  )
}
