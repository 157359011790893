import styled from 'styled-components'
import { Link } from 'react-router-dom'

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  justify-self: start;

  &:hover {
    text-decoration: underline;
  }
`

export default StyledLink
