import React from 'react'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { CommunicationGroupForm } from './Edit'
import { CommunicationGroupView } from './View'
import { PageWrapper } from '@src/components/Page/Page'
import { selectPermissions } from '@src/store/auth/selectors'

export default () => {
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions?.includes(PermissionTypes.AddCommunicationGroup)
  return (
    <PageWrapper>
      <Switch>
        {canEdit && (
          <Route exact path={ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT}>
            <CommunicationGroupForm />
          </Route>
        )}
        <Route exact path={ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.ANY}>
          <Route exact path={ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP}>
            <CommunicationGroupView />
          </Route>
        </Route>
      </Switch>
    </PageWrapper>
  )
}
