import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { VStack, Flex } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanyPreferencesInterface } from '@src/interfaces/settings'

const TeamsOrganisation = () => {
  const { values } = useLapeContext<CompanyPreferencesInterface>()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)

  useEffect(() => {
    if (values.enable_departments == null) {
      values.enable_departments = true
    }
  }, [])

  return (
    <VStack gap="s-16">
      <SectionTitle title="Does your organisation use departments?" />

      <LapeBooleanRadioSwitch
        name="enable_departments"
        variant="horizontal"
        disabled={disableEdit}
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to set up department structure during onboarding"
        noDescription="I’ll only configure teams for now"
      />
    </VStack>
  )
}

export default TeamsOrganisation
