import React, { useState } from 'react'
import { API, selectorKeys } from '@src/constants/api'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { InputGroup } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SideBar from '@components/SideBar/SideBar'
import GeneralExamples from '@src/pages/Forms/SpecialisationForm/General/GeneralExamples'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Statuses } from '@src/interfaces'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useGlobalSettings } from '@src/api/settings'
import { ParentRoleField } from '@src/pages/Forms/SpecialisationForm/General/ParentRoleField'
import { useMountSpecialisationForm } from '@src/pages/Forms/SpecialisationForm/General/useMountSpecialisationForm'

const CURRENT_STEP = 'general'

const General = () => {
  const { values, valid } = useLapeContext<SpecialisationInterface>()
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  useMountSpecialisationForm()
  return (
    <>
      <PageBody>
        <AutoStepper pt="s-8" pb="100px">
          <SideBar
            title="Information"
            isOpen={isSidebarOpen}
            onClose={() => setSidebarOpen(false)}
          >
            <GeneralExamples />
          </SideBar>
          <AutoStepperTitle title="Specialisation details" />
          <InputGroup>
            <ParentRoleField />
            <LapeNewInput label="Specialisation name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Specialisation Owner"
              selector={selectorKeys.employee}
              optional
              clearable
            />
          </InputGroup>
          <AutoStepperTitle title="Specialisation description" />
          <InputGroup>
            <LapeNewTextArea name="mission" label="Specialisation mission" rows={3} />
            <LapeMultiInput title="Enter specialisation goals" name="goals" />
          </InputGroup>
          {promotions_enabled && (
            <>
              <AutoStepperTitle
                title="Linked Specialisations"
                subtitle="An employee’s progression progress is retained when their specialisation is changed to a linked specialisation"
              />
              <LapeNewMultiSelect
                name="related_specialisations"
                placeholder="Linked Specialisations"
                selector={selectorKeys.specialisations}
              />
            </>
          )}
        </AutoStepper>
      </PageBody>
      {/* TODO: make it work for multiple buttons */}
      <PageActions>
        <>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="Specialisation"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.GENERAL}
              isNew
              notification={{
                path: pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {
                  id: values?.role?.id,
                }),
                updateMsg: 'Specialisation draft successfully updated.',
                createMsg: 'Specialisation draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.SPECIALISATIONS}
            step={CURRENT_STEP}
            type="specialisation"
            to={nextLink}
            disabled={!valid}
          />
        </>
      </PageActions>
    </>
  )
}

export default connect(General)
