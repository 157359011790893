import React from 'react'
import styled from 'styled-components'
import { Box, Text, Flex, Token, opacity } from '@revolut/ui-kit'

import Button from '@components/Button/Button'
import { CellTypes } from '@src/interfaces/data'
import { PerformanceRating } from '@src/interfaces/performance'
import { RadioSelect } from '@components/Inputs/RadioSelect/RadioSelect'
import { ratingToColor } from '@src/utils/performance'
import MatrixTable, { MatrixCellInsertParams } from '@components/Table/MatrixTable'
import {
  DeliverableInterface,
  DeliverableQualityCriteria,
  DeliverableRating,
  DeliverableScorecardInterface,
} from '@src/interfaces/deliverables'
import RatingLabel, {
  RatingLabelTypes,
} from '@src/features/EmployeePerformance/RatingLabel'
import RatingTooltip from '../Common/RatingTooltip'

const Container = styled.div`
  padding: 67px 20px 33px;
  color: ${Token.color.foreground};
  height: 100%;
  background-color: ${opacity(Token.colorChannel.deepGrey, 0.1)};
  box-sizing: border-box;
  overflow: auto;
`

const JiraKey = styled.a`
  font-weight: 500;
  font-size: 24px;
  color: ${Token.color.blue};
  text-transform: uppercase;
  text-decoration: none;
`

const JiraLink = styled.a`
  color: ${Token.color.blue};
  text-decoration: none;
`

const Notice = styled.div`
  margin: 19px 0 34px;
  font-size: 13px;
`

const Content = styled.div``
const Title = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
`

const RadioSelectStyled = styled(RadioSelect)<{ backgroundColor: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor};
  height: 50px;
`

const Cell = styled.div<{ backgroundColor?: string | null }>`
  padding: 0 16px;
  background-color: ${props => props.backgroundColor};
`

const Result = styled(Cell)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

const TicketSummary = styled.span`
  max-width: 225px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: bottom;
  display: inline-block;
  text-overflow: ellipsis;
`

const RatingTooltipCell = styled.div<{ backgroundColor?: string | null }>`
  background-color: ${props => props.backgroundColor};
  width: 100%;
  height: 49px;
  display: flex;
  align-items: center;
`

const RatingTooltipStyled: typeof RatingTooltip = styled(RatingTooltip)`
  padding: 0 16px;
  justify-content: start;
  width: 100%;
  box-sizing: border-box;
`

const options = [
  {
    id: PerformanceRating.dont_know,
    name: "Don't Know",
  },
  {
    id: PerformanceRating.poor,
    name: 'Poor',
  },
  {
    id: PerformanceRating.basic,
    name: 'Basic',
  },
  {
    id: PerformanceRating.intermediate,
    name: 'Intermediate',
  },
  {
    id: PerformanceRating.advanced,
    name: 'Advanced',
  },
  {
    id: PerformanceRating.expert,
    name: 'Expert',
  },
]

interface QualityItemInterface {
  id: string
  name: string
  value?: PerformanceRating
}

interface RowInterface {
  onChangeScorecard: (
    ticketId: number,
    type: string,
    value: DeliverableQualityCriteria[],
    scorecard?: DeliverableScorecardInterface,
  ) => void
  scorecard?: DeliverableScorecardInterface
  qualityItems: QualityItemInterface[]
  ticketId: number
  ratings?: DeliverableRating[]
  isViewMode: boolean
}

const rows = ({
  onChangeScorecard,
  scorecard,
  qualityItems,
  ticketId,
  ratings,
  isViewMode,
}: RowInterface) => ({
  cells: [
    {
      type: CellTypes.insert,
      title: '',
      width: 276,
      insert: ({ data }: MatrixCellInsertParams<QualityItemInterface>) => (
        <Cell>{data.name}</Cell>
      ),
    },
    {
      type: CellTypes.insert,
      title: '',
      width: 138,
      insert: ({ data, index }: MatrixCellInsertParams<QualityItemInterface>) => {
        if (isViewMode) {
          const filteredRatings = ratings?.filter(item => item.id === data.id) || []

          if (!data.value) {
            return <Cell>-</Cell>
          }

          return (
            <RatingTooltipCell backgroundColor={ratingToColor(data.value)}>
              <RatingTooltipStyled
                ratings={filteredRatings}
                totalRating={data.value}
                placement={'top'}
                withCount
                type={RatingLabelTypes.Inverse}
              />
            </RatingTooltipCell>
          )
        }

        const tooltip = {
          title: '',
          text: '',
        }

        const value = options.find(option => option.id === data.value) || {
          id: '',
          name: '',
        }
        return (
          <RadioSelectStyled
            id={`select_${data.id}_score_${index}`}
            options={options}
            onChange={option => {
              const items = [...qualityItems]

              const found = items.find(item => item.id === data.id)

              if (found) {
                found.value = option.id as PerformanceRating
              }

              onChangeScorecard &&
                onChangeScorecard(ticketId, 'quality_items', items, scorecard)
            }}
            value={value}
            width={480}
            {...tooltip}
            backgroundColor={ratingToColor(data.value)}
          />
        )
      },
    },
  ],
})

interface Props {
  onClose: () => void
  onChangeScorecard: (
    ticketId: number,
    type: string,
    value: DeliverableQualityCriteria[],
    scorecard?: DeliverableScorecardInterface,
  ) => void
  deliverable?: DeliverableInterface
  scorecard?: DeliverableScorecardInterface
  criteria: DeliverableQualityCriteria[]
  ratings?: DeliverableRating[]
  results?: DeliverableRating[]
  isViewMode: boolean
  jiraEnabled?: boolean
}

export const QualitySelector = ({
  onClose,
  onChangeScorecard,
  scorecard,
  criteria,
  deliverable,
  ratings,
  results,
  isViewMode,
  jiraEnabled = true,
}: Props) => {
  if (!deliverable) {
    return null
  }

  const qualityItems = criteria.map(criteriaItem => {
    const items = isViewMode
      ? deliverable.filtered_aggregation?.quality_items
      : scorecard?.quality_items
    return {
      ...criteriaItem,
      value: items?.find(item => item.id === criteriaItem.id)?.value,
    }
  }) as QualityItemInterface[]

  return (
    <Container>
      <Flex justifyContent="space-between" alignItems="center" maxWidth="416px">
        {jiraEnabled ? (
          <JiraKey href={deliverable.url} target="_blank" rel="noopener norefferer">
            {deliverable.key}
          </JiraKey>
        ) : (
          <Text variant="primary" fontSize="h4" maxWidth="90%">
            {deliverable.title}
          </Text>
        )}
        <Button type="button" onClick={onClose} square>
          Done
        </Button>
      </Flex>
      <Notice>
        {jiraEnabled ? (
          <>
            <strong>Ticket Summary:</strong>{' '}
            <TicketSummary title={deliverable.title}>{deliverable.title}.</TicketSummary>{' '}
            <JiraLink href={deliverable.url} target="_blank" rel="noopener norefferer">
              View in JIRA
            </JiraLink>
          </>
        ) : (
          <>
            {deliverable.description && (
              <>
                <strong>Description:</strong>{' '}
                <TicketSummary title={deliverable.description}>
                  {deliverable.description}
                </TicketSummary>
              </>
            )}
          </>
        )}
      </Notice>
      <Title>Quality</Title>
      <Content>
        <Box mt="s-16">
          <MatrixTable
            noPadding
            rows={rows({
              onChangeScorecard,
              scorecard,
              qualityItems,
              ticketId: deliverable.id,
              ratings,
              isViewMode,
            })}
            data={qualityItems}
            noHeader
          />
        </Box>

        <Box mt="s-16">
          <MatrixTable
            noPadding
            noHeader
            rows={{
              cells: [
                {
                  type: CellTypes.insert,
                  width: 276,
                  insert: () => <Cell>Result</Cell>,
                  title: '',
                },
                {
                  type: CellTypes.insert,
                  width: 138,
                  insert: ({ data }) => {
                    if (isViewMode) {
                      if (!data.aggregatedResult) {
                        return ''
                      }

                      return (
                        <RatingTooltipCell
                          backgroundColor={ratingToColor(data.aggregatedResult)}
                        >
                          <RatingTooltipStyled
                            ratings={data.results || []}
                            totalRating={data.aggregatedResult}
                            placement={'top'}
                            type={RatingLabelTypes.Inverse}
                            withCount
                          />
                        </RatingTooltipCell>
                      )
                    }

                    return (
                      <Result backgroundColor={ratingToColor(data.result)}>
                        <RatingLabel
                          type={RatingLabelTypes.Inverse}
                          rating={data.result}
                        />
                      </Result>
                    )
                  },
                  title: '',
                },
              ],
            }}
            data={[
              {
                result: scorecard?.quality_preview,
                aggregatedResult: deliverable.filtered_aggregation?.quality_preview,
                results,
              },
            ]}
          />
        </Box>
      </Content>
    </Container>
  )
}
