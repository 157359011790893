import React, { useState } from 'react'
import { bulkConsiderForAJob } from '@src/api/CRM'
import { Button, Side, VStack } from '@revolut/ui-kit'
import { CRMSelectedCandidate } from '@src/interfaces/CRM'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'
import { selectorKeys } from '@src/constants/api'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import BulkStatusPopup from '@src/components/BulkStatusPopup/BulkStatusPopup'
import pluralize from 'pluralize'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import SideBar from '@src/components/SideBar/SideBar'

type ConsiderForAJobProps = {
  candidates: CRMSelectedCandidate[]
  onClose: VoidFunction
  onRefresh: VoidFunction
}

export const ConsiderForAJob = ({
  candidates,
  onClose,
  onRefresh,
}: ConsiderForAJobProps) => {
  const [jobPosting, setJobPosting] = useState<JobPostingLocationTypeInterface>()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const handleConsiderForAJob = async () => {
    if (jobPosting) {
      setLoading(true)
      try {
        await bulkConsiderForAJob(
          candidates.map(({ candidateId }) => candidateId),
          jobPosting.id,
        )
        setSuccess(true)
      } catch (e) {
        showStatusPopup({
          status: 'error',
          title: 'Something went wrong',
          description: getStringMessageFromError(e),
        })
      } finally {
        setLoading(false)
      }
    }
  }
  return (
    <>
      {success && (
        <BulkStatusPopup
          isSuccess
          onClose={() => {
            onRefresh()
            onClose()
          }}
        />
      )}
      <SideBar
        isOpen
        title={`Consider for a job for ${pluralize(
          'candidate',
          candidates.length,
          true,
        )}`}
        useLayout
        onClose={onClose}
      >
        <VStack gap="s-16">
          <RadioSelectInput<JobPostingLocationTypeInterface>
            disabled={loading}
            label="Select job posting"
            selector={selectorKeys.job_postings_locations_type}
            value={jobPosting}
            onChange={newJobPosting => {
              if (!newJobPosting) {
                return
              }
              setJobPosting(newJobPosting)
            }}
          >
            {renderOption}
          </RadioSelectInput>
        </VStack>
        <Side.Actions horizontal>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!jobPosting}
            pending={loading}
            onClick={handleConsiderForAJob}
          >
            Confirm
          </Button>
        </Side.Actions>
      </SideBar>
    </>
  )
}
