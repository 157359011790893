import React, { useState } from 'react'
import {
  Button,
  Flex,
  Grid,
  H1,
  Item,
  Link,
  Paragraph,
  StatusPopup,
  Widget,
  useStatusPopup,
} from '@revolut/ui-kit'
import {
  submitDiversitySurvey,
  getOrientationSelector,
  getGenderSelector,
  getEthnicitySelector,
  getDisabilititySelector,
} from '@src/api/diversitySurvey'
import { DiversitySurveyInterface } from '@src/interfaces/diversitySurvey'
import { useQuery } from '@src/utils/queryParamsHooks'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PUBLIC_ROUTES } from '@src/constants/routes'

type LayoutProps = {
  subtitle?: string
  children: React.ReactNode
}

const Layout = ({ subtitle, children }: LayoutProps) => {
  return (
    <PageWrapper alignItems="center">
      <Flex flexDirection="column" px="s-16">
        <PageHeader
          title="Revolut Diversity and Inclusion survey"
          subtitle={subtitle}
          backUrl=""
          hideUpperBlock
          noWrap={false}
        />
        {children}
      </Flex>
    </PageWrapper>
  )
}

const Success = () => {
  return (
    <Layout>
      <PageBody>
        <Widget p="s-24" mb="s-32">
          <H1 mb="s-16">Survey completed</H1>
          <Grid gap="s-24">
            <Paragraph variant="secondary">
              Thank you for completing the survey.
            </Paragraph>
            <Paragraph variant="secondary">
              We will use this information to help us better understand the diversity of
              our candidate pool, and to identify areas where we can improve our
              recruitment and hiring practices to be more inclusive.
            </Paragraph>
            <Paragraph variant="secondary">
              We want to make sure everyone feels comfortable and supported during our
              hiring process. If you have a disability or identify as neurodiverse and
              believe certain adjustments could be helpful for you, please don't hesitate
              to reach out to your recruiter via email. We're here to ensure you have the
              best experience possible.
            </Paragraph>
          </Grid>
        </Widget>
      </PageBody>
    </Layout>
  )
}

const DiversitySurvey = () => {
  const { submit } = useLapeContext<DiversitySurveyInterface>()
  const [submitLoading, setSubmitLoading] = useState(false)
  const [submitSuccess, setSubmitSuccess] = useState(false)
  const statusPopup = useStatusPopup()
  const showError = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleSubmit = async () => {
    setSubmitLoading(true)
    try {
      await submit()
      setSubmitSuccess(true)
    } catch {
      showError()
    } finally {
      setSubmitLoading(false)
    }
  }
  if (submitSuccess) {
    return <Success />
  }
  return (
    <Layout subtitle="4 Questions">
      <PageBody>
        <Widget p="s-24" mb="s-32">
          <H1 mb="s-16">Welcome!</H1>
          <Grid gap="s-24">
            <Paragraph variant="secondary">
              As part of our continuous efforts to cultivate and promote a diverse and
              inclusive environment, we invite you to share some personal information with
              us. Your participation is entirely voluntary and all shared information will
              remain confidential.
            </Paragraph>
            <Paragraph variant="secondary">
              We’ll use this information to help us better understand the diversity of our
              candidate pool and to identify areas where we can improve our recruitment
              and hiring practices. Your decision to provide this information will not
              affect your application. You can opt out from answering any of the following
              questions.
            </Paragraph>
            <Paragraph variant="secondary">
              By responding to the survey, you give Revolut your explicit consent to use
              your personal data for the purpose of carrying out equal opportunities
              monitoring. You can withdraw your consent at any time by contacting us at{' '}
              <Link href="mailto:dpo@revolut.com">dpo@revolut.com</Link>
            </Paragraph>
            <Paragraph variant="secondary">
              For information on how we will handle your personal data, please see our{' '}
              <Link href={PUBLIC_ROUTES.PRIVACY_NOTICE} target="_blank">
                Data Privacy Statement for Candidates
              </Link>
              .
            </Paragraph>
          </Grid>
        </Widget>
        <Grid gap="s-24">
          <Item>
            <Item.Content>
              <Item.Title mb="s-32">
                <H1>How would you describe your gender identity?</H1>
              </Item.Title>
              <Item.Description>
                <LapeRadioSelectInput
                  name="gender"
                  label="Gender identity (optional)"
                  selector={getGenderSelector}
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Title mb="s-32">
                <H1>Which of the following best describes your ethnicity?</H1>
              </Item.Title>
              <Item.Description>
                <LapeRadioSelectInput
                  name="ethnicity"
                  label="Ethnicity (optional)"
                  selector={getEthnicitySelector}
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Title mb="s-32">
                <H1>How would you describe your sexual orientation?</H1>
              </Item.Title>
              <Item.Description>
                <LapeRadioSelectInput
                  name="sexual_orientation"
                  label="Sexual orientation (optional)"
                  selector={getOrientationSelector}
                />
              </Item.Description>
            </Item.Content>
          </Item>
          <Item>
            <Item.Content>
              <Item.Title mb="s-32">
                <H1>Do you identify as having a disability?</H1>
              </Item.Title>
              <Item.Description>
                <LapeRadioSelectInput
                  name="disability"
                  label="Disability (optional)"
                  selector={getDisabilititySelector}
                />
              </Item.Description>
            </Item.Content>
          </Item>
        </Grid>
      </PageBody>
      <PageActions>
        <Button disabled={submitLoading} pending={submitLoading} onClick={handleSubmit}>
          Submit
        </Button>
      </PageActions>
    </Layout>
  )
}

type DiversitySurveyParams = {
  token: string
}

export default () => {
  const { query } = useQuery<DiversitySurveyParams>()
  const { token } = query
  return (
    <Form
      api={{
        get: data => submitDiversitySurvey(data, token),
        update: data => submitDiversitySurvey(data, token),
        submit: data => submitDiversitySurvey(data, token),
      }}
      // get and update are never called in this scenario
      forceParams={{ id: undefined }}
      disableLocalStorageCaching
    >
      <DiversitySurvey />
    </Form>
  )
}
