import React from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Box, Tag, Text, Token, VStack } from '@revolut/ui-kit'
import { notReachable } from '@src/utils/notReachable'
import { MultipleEmployees } from '@components/ColumnInserts/MultipleEmployees/MultipleEmployees'

interface Props {
  type: HiringProcessInterface['interviewer_type']
  groups: HiringProcessInterface['interviewers_groups']
  employees: HiringProcessInterface['interviewers']
}

export const HiringStageDetailsInterviewers = ({ type, groups, employees }: Props) => {
  switch (type.id) {
    case 'groups':
      return (
        <VStack gap="s-16">
          <Text>Groups of employees</Text>
          {!!groups?.length && (
            <Box>
              {groups.map(group => (
                <Tag
                  style={{ display: 'inline-flex', margin: '4px 0 0 4px' }}
                  useIcon="People"
                  color={Token.color.black}
                  key={group.id}
                >
                  {group.name}
                </Tag>
              ))}
            </Box>
          )}
        </VStack>
      )

    case 'employees':
      return (
        <VStack gap="s-16">
          <Text>Employees</Text>
          {!!employees?.length && <MultipleEmployees employees={employees} />}
        </VStack>
      )
  }

  return notReachable(type.id)
}
