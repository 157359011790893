import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'
import HubApp from '@src/features/HubApp/HubApp'
import { Responsibility } from './Responsibility'
import { SuccessionPlan } from './SuccessionPlan'

export default () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.ANY}>
        <Responsibility />
      </Route>
      <Route path={ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.ANY}>
        <SuccessionPlan />
      </Route>
      <Route path={ROUTES.APPS.KEY_PERSONS.ANY}>
        <HubApp app={APPLICATIONS.keyPersons} />
      </Route>
    </Switch>
  )
}
