import React, { useMemo } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useIsNewTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar, FilterButton } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { nipsColumn } from '@src/constants/columns/department'
import { roleManagerColumn } from '@src/constants/columns/employee'
import {
  roleFunctionColumn,
  roleHeadcountColumn,
  roleIconNameColumn,
  roleRequisitionsColumn,
  roleSkillsColumn,
  roleStatusColumn,
} from '@src/constants/columns/role'
import { RoleInterface } from '@src/interfaces/roles'
import { rolesRequests, useGetRolesStats } from '@src/api/roles'
import { Statuses } from '@src/interfaces'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { withFavourites } from '@src/features/FavouritesFilter/withFavourites'
import { useFavouritesFilter } from '@src/features/FavouritesFilter/useFavouritesFilter'
import { defaultRolesTableStatusFilter } from './common'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { settingsConfig } from '@src/pages/Settings/SettingsLandingPage/constants'
import Table from '@components/TableV2/Table'
import { StatNavigation } from '@components/StatNavigation/StatNavigation'
import { rolesConfig } from '@src/pages/Organisation/OrganisationSubTabs/common'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const RolesTable = () => {
  const isNewTable = useIsNewTable()
  const user = useSelector(selectUser)
  const [showMyRoles, setShowMyRoles] = useLocalStorage(
    LOCAL_STORAGE.SHOW_MY_ROLES,
    false,
  )
  const [showArchivedRoles, setShowArchivedRoles] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ARCHIVED_ROLES,
    false,
  )
  const { FavouritesFilter, initialFilter } = useFavouritesFilter('role')

  const getFilterByManager = (setFilter: boolean) => ({
    filters: setFilter ? [{ name: user.display_name, id: user.id }] : [],
    columnName: 'role_manager__id',
    nonResettable: true,
  })

  const getFilterByStatus = (archived: boolean) => ({
    filters: archived
      ? [{ name: Statuses.archived, id: Statuses.archived }]
      : defaultRolesTableStatusFilter,
    columnName: 'status',
  })

  const getInitialFilter = () => {
    const filter = []

    filter.push(getFilterByStatus(showArchivedRoles))
    filter.push(getFilterByManager(showMyRoles))

    if (initialFilter) {
      filter.push(initialFilter)
    }

    return filter
  }

  const filterBy = getInitialFilter()
  const table = useTable<RoleInterface>(rolesRequests, filterBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddRoles)
  const canViewSeniorities = permissions.includes(PermissionTypes.ViewSeniority)
  const canManage = permissions.includes(PermissionTypes.ManageRoles)

  const { data: settings } = useGetOrganisationSettings()

  const row = useMemo((): RowInterface<RoleInterface> => {
    return {
      linkToForm: ({ id, status }) => {
        if (status === Statuses.draft) {
          return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, { id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id }))
      },
      cells: [
        {
          ...roleIconNameColumn,
          width: 220,
        },
        {
          ...roleManagerColumn,
          width: 200,
        },
        {
          ...roleFunctionColumn,
          width: 150,
        },
        {
          ...roleHeadcountColumn,
          width: 100,
        },
        {
          ...roleRequisitionsColumn,
          width: 110,
        },
        {
          ...nipsColumn,
          width: 100,
        },
        settings?.enable_roles_approvals
          ? {
              ...roleStatusColumn,
              width: 130,
            }
          : null,
        {
          ...roleSkillsColumn,
          width: 620,
        },
      ].filter(Boolean),
    }
  }, [settings])

  const onToggleMyRoles = () => {
    setShowMyRoles(!showMyRoles)
    table.onFilterChange(getFilterByManager(!showMyRoles))
  }

  const onToggleArchivedRoles = () => {
    setShowArchivedRoles(!showArchivedRoles)
    table.onFilterChange(getFilterByStatus(!showArchivedRoles))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {isNewTable ? (
          <StatNavigation api={useGetRolesStats} config={rolesConfig} />
        ) : (
          <Stat label="Total" val={table?.loading ? undefined : table?.count} />
        )}
      </Table.Widget.Info>
      <Table.Widget.Search>
        <Table.Search
          placeholder="Search by role and function names"
          onFilter={table.onFilterChange}
        />
      </Table.Widget.Search>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canAdd && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.ROLE.GENERAL)}
              useIcon="Plus"
            >
              Add Role
            </PrimaryAction>
          )}
          {canManage && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.ROLE)}
              useIcon="Pencil"
            >
              Edit
            </MoreBar.Action>
          )}
          {canViewSeniorities && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.PERFORMANCE.SENIORITIES)}
              useIcon="ArrowUpgrade"
            >
              Seniorities
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.ROLES.GENERAL}
            canView={settingsConfig.roles.canView}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by role and function names"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton onClick={onToggleMyRoles} active={showMyRoles}>
          My roles
        </FilterButton>
        <FilterButton onClick={onToggleArchivedRoles} active={showArchivedRoles}>
          Archived roles
        </FilterButton>
        <FavouritesFilter table={table} />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<RoleInterface>
          name={TableNames.Roles}
          useWindowScroll
          dataType="Role"
          row={row}
          {...table}
          noDataMessage="Roles will appear here."
          hideCount={!!isNewTable}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default withFavourites(RolesTable)
