import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { Route, Switch } from 'react-router-dom'
import { General } from '@src/pages/Settings/JobPosting/General'
import { ApplicationForm } from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationForm'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { ApprovalSettings } from '@src/pages/Settings/JobPosting/ApprovalSettings'
import { CareerPageSettings } from '@src/pages/Settings/JobPosting/CareerPage'

export const JobPostingSettings = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.JOB_POSTING.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS}>
          <ApplicationForm />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE}>
          <CareerPageSettings />
        </Route>
        <Route exact path={ROUTES.SETTINGS.JOB_POSTING.APPROVAL}>
          <ApprovalSettings />
        </Route>
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
