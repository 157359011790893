export const features = [
  {
    title: 'HR management',
    description: 'Features to manage the complete employee lifecycle',
    icon: 'People' as const,
    items: ['Employees', 'Lifecycle', 'Timeoff', 'Contracts', 'Documents'],
  },
  {
    title: 'Performance',
    description: 'Advanced performance features to continuously raise the bar',
    icon: 'AddContact' as const,
    items: ['Goals', 'Reviews'],
  },
  {
    title: 'Recruitment',
    description: 'End to end applicant tracking and candidate management',
    icon: 'AddContact' as const,
    items: ['Headcount planning', 'Job postings', 'Candidate management', 'Offer forms'],
  },
  {
    title: 'Organisation',
    icon: 'Bank' as const,
    items: ['Teams', 'Roles and Functions', 'Locations', 'Legal entities'],
  },
  {
    title: 'Administration and support',
    icon: 'HelpChatOutline' as const,
    items: [
      'Customer support',
      'Access management',
      'Dedicated customer success manager',
    ],
  },
]
