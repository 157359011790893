import React, { useRef } from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Flex,
  Group,
  Item,
  MoreBar,
  TextButton,
  TextSkeleton,
  Token,
  useIntersectViewport,
  VStack,
} from '@revolut/ui-kit'
import { ChevronRight, Passport, Pencil } from '@revolut/icons'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface, RightToWorkInterface } from '@src/interfaces/employees'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { InternalLink } from '@components/InternalLink/InternalLink'
import SideBar from '@components/SideBar/SideBar'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { useActiveProfileSidebar } from '../common'
import { useEmployeeRightToWorkWidget } from './hooks'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'

type Props = {
  data: EmployeeInterface
}
export const RightToWork = ({ data }: Props) => {
  const ref = useRef(null)
  const rightToWork = useEmployeeRightToWorkWidget(data.id)
  const { sidebar, setSidebarQueryParam } = useActiveProfileSidebar()

  useIntersectViewport(ref, isIntersecting => {
    if (isIntersecting) {
      rightToWork.handler.load()
    }
  })

  const loading =
    rightToWork.handler.status === 'loading' || rightToWork.handler.isRefetching
  const isLoaded = rightToWork.handler.enabled && rightToWork.handler.status !== 'loading'
  const hasActiveRightToWork = Boolean(isLoaded && rightToWork.data)
  const showDetailsPreview = hasActiveRightToWork || !isLoaded

  return (
    <>
      <Group ref={ref}>
        <Item
          use={hasActiveRightToWork ? 'button' : 'div'}
          onClick={
            hasActiveRightToWork
              ? () => setSidebarQueryParam('rightToWorkDetails', true)
              : undefined
          }
        >
          <Item.Avatar>
            <Avatar useIcon={Passport} />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Right to work</Item.Title>
            {isLoaded ? (
              <Item.Description>
                {hasActiveRightToWork
                  ? 'Country, document, validity period'
                  : "Employee doesn't have valid right to work"}
              </Item.Description>
            ) : (
              <Item.Description>
                <TextSkeleton width={200} height={12} />
              </Item.Description>
            )}
          </Item.Content>
          <Item.Side>
            {hasActiveRightToWork ? (
              <ChevronRight color={Token.color.greyTone50} />
            ) : (
              <ActionButton
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, {
                  id: String(data.id),
                })}
              >
                View all
              </ActionButton>
            )}
          </Item.Side>
        </Item>
        {showDetailsPreview && (
          <FormPreview<RightToWorkInterface>
            data={rightToWork.data}
            dataHandler={rightToWork.handler}
          >
            <FormPreview.Item<RightToWorkInterface>
              type="date"
              title="Expiry date"
              field="document_expiry_date"
              loading={loading}
            />
          </FormPreview>
        )}
        {hasActiveRightToWork && (
          <Flex width="100%" justifyContent="center" p="s-16">
            <TextButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, { id: data.id })}
            >
              View all documents
            </TextButton>
          </Flex>
        )}
      </Group>
      {!!rightToWork.data?.id && (
        <SideBar
          title="Right to work details"
          subtitle={<UserWithAvatar id={data.id} full_name={data.full_name} />}
          variant="wide"
          isOpen={sidebar === 'rightToWorkDetails'}
          onClose={() => setSidebarQueryParam('contractDetails', false)}
          sideProps={{ resizable: true }}
          useIcon={Passport}
          avatarProps={{ color: Token.color.greyTone20 }}
        >
          <Box mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
                  id: String(rightToWork.data.id),
                  employeeId: String(data.id),
                })}
                useIcon={Pencil}
              >
                Go to edit page
              </MoreBar.Action>
            </MoreBar>
          </Box>
          <VStack space="s-12">
            <FormPreview data={rightToWork.data} title="General information">
              <Group>
                <FormPreview.Item<RightToWorkInterface>
                  title="Right to work country"
                  field="country.name"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Citizenship country"
                  field="citizenship_country.name"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Type of right to work"
                  field="right_to_work_type.name"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Right to work document"
                  field="document_type.name"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Document number"
                  field="document_number"
                />
                <FormPreview.Item<RightToWorkInterface>
                  type="date"
                  title="Document valid from date"
                  field="document_valid_from_date"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Document expiry date"
                  field="document_expiry_date"
                />
                <FormPreview.Item<RightToWorkInterface>
                  type="date"
                  title="Last verified on"
                  field="last_verified_on_date"
                />
                <FormPreview.Item<RightToWorkInterface>
                  title="Verified by"
                  field="contract_term.name"
                  insert={d =>
                    d.last_verified_by?.id ? (
                      <EmployeeUserWithAvatar
                        id={d.last_verified_by.id}
                        full_name={d.last_verified_by.display_name}
                      />
                    ) : (
                      '-'
                    )
                  }
                />
                <FormPreview.Item title="Notes" field="notes" />
              </Group>
            </FormPreview>
          </VStack>
        </SideBar>
      )}
    </>
  )
}
