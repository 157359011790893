import React, { useCallback, useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  Button,
  Color,
  DragAndDrop,
  Flex,
  Group,
  IconButton,
  Item,
  Side,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { Drag, Pin, Unpin } from '@revolut/icons'
import { groupBy, difference } from 'lodash'

import { move } from '@src/utils/move'
import { usePinnedTabs } from '@src/features/TabPinning/pinnedTabs'
import { TabBarWithPinningTabConfig } from '@src/interfaces/tabPinning'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

interface PinTabsSidebarProps {
  isOpen: boolean
  onClose: () => void
  tabs: TabBarWithPinningTabConfig[]
}

export const PinTabsSidebar = ({ isOpen, onClose, tabs }: PinTabsSidebarProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const { pinnedTabs, setPinnedTabs } = usePinnedTabs()

  const [settings, setSettings] = useState({
    visible: pinnedTabs.map(tab => tabs.find(t => t.id === tab)).filter(Boolean),
    hidden: tabs.filter(tab => tab.id && !pinnedTabs.includes(tab.id)),
  })

  const [activeId, setActiveId] = useState<string | null>(null)

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setSettings(current => ({
        ...current,
        visible:
          startIndex === endIndex
            ? current.visible
            : move(current.visible, startIndex, endIndex),
      }))
    }
    setActiveId(null)
  }, [])

  const activeItem = activeId && settings.visible.find(item => item.id === activeId)

  const handleRemove = (tab: TabBarWithPinningTabConfig) => {
    setSettings(current => ({
      visible: current.visible.filter(t => t.id !== tab.id),
      hidden: [...current.hidden, tab],
    }))
  }

  const handleAdd = (tab: TabBarWithPinningTabConfig) => {
    setSettings(current => ({
      visible: [...current.visible, tab],
      hidden: current.hidden.filter(t => t.id !== tab.id),
    }))
  }

  const handleSave = () => {
    const tabsToPin = settings.visible.map(t => t.id!)
    difference(pinnedTabs, tabsToPin).forEach(removed => {
      sendAnalyticsEvent(AnalyticsEvents.unpin_tab, { tab_id: removed })
    })
    difference(tabsToPin, pinnedTabs).forEach(added => {
      sendAnalyticsEvent(AnalyticsEvents.pin_tab, { tab_id: added })
    })

    setPinnedTabs(() => tabsToPin)
    onClose()
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title="Pinned tabs"
      subtitle="Choose what tabs you want pinned to the Home section and re-order them. You can also pin tab by right click on it in main navigation."
    >
      <Flex flexDirection="column" gap="s-16">
        {settings.visible.length > 0 ? (
          <VStack space="s-8">
            <Text variant="h6" color={Color.GREY_TONE_50}>
              Pinned
            </Text>

            <Group data-testid="Group-Pinned">
              <DragAndDrop.Provider
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
              >
                <DragAndDrop.Sortable
                  id="pinned-tabs"
                  items={settings.visible.map(t => t.id!)}
                >
                  {sortable => {
                    const tab = settings.visible.find(item => item.id === sortable.id)
                    return tab ? (
                      <Item
                        ref={sortable.setNodeRef}
                        style={{
                          transform: sortable.transform
                            ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                            : undefined,
                          transition: sortable.transition || 'none',
                          opacity: sortable.isDragging ? 0 : undefined,
                        }}
                        p="s-12"
                      >
                        <Item.Prefix>
                          <IconButton
                            aria-label={`Unpin ${tab.title}`}
                            useIcon={Pin}
                            color="blue"
                            onClick={() => handleRemove(tab)}
                            size={20}
                          />
                        </Item.Prefix>
                        <Item.Content>
                          <Item.Title>{tab.title}</Item.Title>
                        </Item.Content>
                        <Item.Side>
                          <IconButton
                            aria-label="Drag"
                            useIcon={Drag}
                            color="grey-tone-20"
                            {...sortable.attributes}
                            {...sortable.listeners}
                            size={20}
                          />
                        </Item.Side>
                      </Item>
                    ) : null
                  }}
                </DragAndDrop.Sortable>
                <DragAndDrop.DragOverlay>
                  {activeItem && (
                    <Item p="s-12">
                      <Item.Prefix>
                        <IconButton
                          aria-label="Unpin"
                          useIcon={Pin}
                          color="blue"
                          onClick={() => {}}
                          size={20}
                        />
                      </Item.Prefix>
                      <Item.Content>
                        <Item.Title>{activeItem.title}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <IconButton
                          aria-label="Drag"
                          useIcon={Drag}
                          color="grey-tone-20"
                          onClick={() => {}}
                          size={20}
                        />
                      </Item.Side>
                    </Item>
                  )}
                </DragAndDrop.DragOverlay>
              </DragAndDrop.Provider>
            </Group>
          </VStack>
        ) : null}

        {Object.entries(groupBy(settings.hidden, 'category')).map(
          ([category, categoryTabs]) => (
            <VStack space="s-8" key={category} data-testid={`Group-${category}`}>
              <Text variant="h6" color={Color.GREY_TONE_50}>
                {/* `groupBy` produces string 'undefined' if category is not set for tab */}
                {category === 'undefined' ? 'Other' : category}
              </Text>
              <Group>
                {categoryTabs.map(tab => {
                  return (
                    <Item p="s-12" key={tab.id}>
                      <Item.Prefix>
                        <IconButton
                          aria-label={`Pin ${tab.title}`}
                          useIcon={Unpin}
                          color="blue"
                          onClick={() => handleAdd(tab)}
                          size={20}
                        />
                      </Item.Prefix>
                      <Item.Content>
                        <Item.Title>{tab.title}</Item.Title>
                      </Item.Content>
                    </Item>
                  )
                })}
              </Group>
            </VStack>
          ),
        )}
      </Flex>

      <Side.Actions>
        <Button elevated onClick={handleSave}>
          Done
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
