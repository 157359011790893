import React from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AutomationListInterface } from '@src/interfaces/jobPostings/automation'
import {
  automationCandidatesColumn,
  automationCreatedColumn,
  automationNameColumn,
  automationStatusColumn,
  automationUpdatedColumn,
} from '@src/constants/columns/jobPostings/automation'
import { automationRequests } from '@src/api/jobPostings/automation'
import { useTable } from '@components/TableV2/hooks'
import { useParams } from 'react-router-dom'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const row = (jobPostingId: number): RowInterface<AutomationListInterface> => ({
  linkToForm: ({ id }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, { jobPostingId, id }),
    ),
  cells: [
    {
      ...automationNameColumn,
      width: 300,
    },
    {
      ...automationCandidatesColumn,
      width: 300,
    },
    {
      ...automationCreatedColumn,
      width: 300,
    },
    {
      ...automationUpdatedColumn,
      width: 300,
    },
    {
      ...automationStatusColumn,
      width: 300,
    },
  ],
})

export const Automation = () => {
  const params = useParams<{ id: string }>()
  const { id } = params
  const initialFilter = [
    {
      filters: [{ name: `${id}`, id }],
      columnName: 'job_posting',
      nonResettable: true,
    },
  ]
  const table = useTable<AutomationListInterface>(automationRequests(), initialFilter)

  return (
    <PageWrapper>
      <PageHeader
        title="Automation workflow"
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.GENERAL, params)}
      />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <PrimaryAction
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, {
                  jobPostingId: id,
                })}
                useIcon="Plus"
              >
                Add new automation
              </PrimaryAction>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable<AutomationListInterface>
              name={TableNames.PostingAutomationRules}
              useWindowScroll
              dataType="Automation rule"
              row={row(+id)}
              {...table}
              emptyState={<EmptyTableRaw title="Automation rules will appear here" />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
