import React, { useMemo } from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Cell, Flex } from '@revolut/ui-kit'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { PerformanceTimeRange } from '@src/constants/api'
import { useTable } from '@components/Table/hooks'
import { teamCXSummaryRequests } from '@src/api/teams'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { getMonthsByRange } from '@src/utils/kpi'
import AdjustableTable from '@components/Table/AdjustableTable'
import { CXSummaryInterface } from '@src/interfaces/cx'
import {
  cxSummary28DayAverageColumn,
  cxSummaryCategoryColumn,
  cxSummaryLastValueColumn,
  cxSummaryTrendColumn,
  getCxSummaryActionColumn,
} from '@src/constants/columns/cx'
import { departmentCXSummaryRequests } from '@src/api/department'
import { TableNames } from '@src/constants/table'

interface StructureProps {
  data: TeamInterface | DepartmentInterface
  isTeam?: boolean
}

const timeRangeTabs = [
  PerformanceTimeRange.day,
  PerformanceTimeRange.week,
  PerformanceTimeRange.month,
  PerformanceTimeRange.quarter,
]

const getRow = (isTeam: boolean, id: number): RowInterface<CXSummaryInterface> => ({
  cells: [
    {
      ...cxSummaryCategoryColumn,
      width: 200,
    },
    {
      ...cxSummaryLastValueColumn,
      width: 140,
    },
    {
      ...cxSummaryTrendColumn,
      width: 140,
    },
    {
      ...cxSummary28DayAverageColumn,
      width: 140,
    },
    {
      ...getCxSummaryActionColumn(isTeam, id),
      width: 140,
    },
  ],
})

const defaultTab = PerformanceTimeRange.week
const getFilters = (timeRange: PerformanceTimeRange): FilterByInterface[] => [
  {
    columnName: 'date_partitioning',
    filters: [{ id: timeRange, name: timeRange }],
  },
  {
    columnName: 'months',
    filters: [{ id: getMonthsByRange(timeRange), name: getMonthsByRange(timeRange) }],
  },
]

const CXSummary = ({ data, isTeam = false }: StructureProps) => {
  const table = useTable(
    {
      getItems: isTeam
        ? teamCXSummaryRequests(data.id)
        : departmentCXSummaryRequests(data.id),
    },
    getFilters(defaultTab),
  )

  const { tabBar } = useTabBarSwitcher({
    tabs: timeRangeTabs,
    highlightSelected: false,
    capitalize: true,
    defaultTab,
    onTabChange: tab => table.onFilterChange(getFilters(tab)),
  })

  const row = useMemo(() => getRow(isTeam, data.id), [isTeam, data.id])

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">{tabBar}</Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<CXSummaryInterface>
            name={TableNames.CXData}
            useWindowScroll
            dataType="CX data"
            row={row}
            {...table}
            hideCount
            noDataMessage="CX data will appear here."
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default CXSummary
