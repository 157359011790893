import React from 'react'
import ArchivingCandidateSidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { CandidateInterface } from '@src/interfaces/interviewTool'

type Props = {
  refresh: () => void
  candidate?: CandidateInterface
}

export const CandidateArchive = ({ candidate, refresh }: Props) => {
  const { activeAction, resetActiveAction } = useCandidateProfileContext()

  return (
    <>
      <ArchivingCandidateSidebar
        candidate={candidate}
        open={activeAction?.type === 'archive'}
        onClose={resetActiveAction}
        onAfterArchive={() => {
          refresh()
          resetActiveAction()
        }}
        noSuccessPopup
        showInvalidPlaceholdersWarning
      />
    </>
  )
}
