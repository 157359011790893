import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'lape'
import { tableauDashboardRequests } from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import Form from '@src/features/Form/Form'
import { DashboardForm } from './DashboardForm'

const DashboardView = connect(() => {
  return (
    <PageWrapper>
      <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_TABLEAU_DASHBOARD}>
        <DashboardForm />
      </Route>
    </PageWrapper>
  )
})

const TableauDashboardViewForm = () => {
  return (
    <Form api={tableauDashboardRequests}>
      <DashboardView />
    </Form>
  )
}

export default connect(TableauDashboardViewForm)
