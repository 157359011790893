import React from 'react'
import { Item, Avatar, Box, ErrorWidget, Group, Color } from '@revolut/ui-kit'
import { Present } from '@revolut/icons'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  BenefitEnrolment,
  BenefitPackage,
  BenefitsInterface,
} from '@src/interfaces/benefits'
import { formatMoney } from '@src/utils/format'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { UploadedFileToDownload } from '@src/components/Inputs/FileUploader/UploadedFile'

export const formatContribution = (
  benefit: Partial<BenefitsInterface>,
  value: string | null,
) => {
  if (!value) {
    return '-'
  }

  const method = benefit.contribution_method?.id

  if (method === 'fixed_amount') {
    return formatMoney(+value, benefit.contribution_currency?.iso_code)
  }

  if (
    method === 'percentage_of_salary' ||
    method === 'percentage_of_qualifying_earnings'
  ) {
    return `${value}%`
  }

  return value
}

interface OnPackageDetailsClickArgs {
  packageId?: number
  id: number
  index: number
}

export const onPackageDetailsClick = ({
  packageId,
  id,
  index,
}: OnPackageDetailsClickArgs) => {
  if (packageId) {
    navigateTo(
      pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_PREVIEW, {
        id,
        packageId,
      }),
    )
  } else {
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_NEW, {
        id,
      })}?packageIndex=${index}`,
    )
  }
}

interface PackageDetailItemProps {
  benefitPackage: BenefitPackage
  benefit: Partial<BenefitsInterface>
  onClick: () => void
  selected?: boolean
  enrolment?: BenefitEnrolment | null
}

export const PackageDetailItem = ({
  benefitPackage,
  benefit,
  onClick,
  selected,
  enrolment,
}: PackageDetailItemProps) => {
  const employeeContribution = (() => {
    if (benefit.contribution_method?.id === 'other') {
      return benefitPackage.employee_contribution
    }

    if (selected && enrolment && enrolment.selected_package.id === benefitPackage.id) {
      return enrolment.employee_contribution
    }

    if (benefit.employee_can_edit_contribution && !selected) {
      return `${benefitPackage.employee_min_contribution}-${benefitPackage.employee_max_contribution}`
    }

    if (benefitPackage.employee_contribution) {
      return benefitPackage.employee_contribution
    }

    return '-'
  })()

  return (
    <Item use="button" type="button" onClick={onClick}>
      <Item.Avatar>
        <Avatar useIcon={Present} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{benefitPackage.name}</Item.Title>
        <Item.Description>
          <Box>Cover: {benefitPackage.cover}</Box>
          <Box>
            Contributions: {formatContribution(benefit, employeeContribution)} (Employee
            {selected && benefit.employee_can_edit_contribution
              ? ' - can be changed'
              : ''}
            ), {formatContribution(benefit, benefitPackage.employer_contribution)}{' '}
            (Employer)
          </Box>
        </Item.Description>
      </Item.Content>
      {selected ? (
        <Item.Side>
          <Item.Value color={Color.GREEN}>Selected</Item.Value>
        </Item.Side>
      ) : null}
    </Item>
  )
}

interface PackageNotFoundPageProps {
  backUrl: string
}

export const PackageNotFoundPage = ({ backUrl }: PackageNotFoundPageProps) => {
  return (
    <PageWrapper>
      <PageHeader pb="s-8" title="Package details" backUrl={backUrl} />
      <PageBody>
        <ErrorWidget>
          <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D087@2x.png" />
          <ErrorWidget.Title>Package not found</ErrorWidget.Title>
        </ErrorWidget>
      </PageBody>
    </PageWrapper>
  )
}

interface EmployeeContributionPreviewItemProps {
  benefit: BenefitsInterface
  benefitPackage: BenefitPackage
}

export const EmployeeContributionPreviewItem = ({
  benefit,
  benefitPackage,
}: EmployeeContributionPreviewItemProps) => {
  return (
    <FormPreview.Item
      title="Employee contribution"
      insert={() =>
        formatContribution(
          benefit,
          benefit.employee_can_edit_contribution
            ? `${benefitPackage.employee_min_contribution}-${benefitPackage.employee_max_contribution}`
            : benefitPackage.employee_contribution,
        )
      }
    />
  )
}

interface EmployerContributionPreviewItemProps {
  benefit: BenefitsInterface
  benefitPackage: BenefitPackage
}

export const EmployerContributionPreviewItem = ({
  benefit,
  benefitPackage,
}: EmployerContributionPreviewItemProps) => {
  return (
    <FormPreview.Item
      title="Employer contribution"
      insert={() => formatContribution(benefit, benefitPackage.employer_contribution)}
    />
  )
}

interface DependantsNumberPreviewItemProps {
  benefitPackage: BenefitPackage
}

export const DependantsNumberPreviewItem = ({
  benefitPackage,
}: DependantsNumberPreviewItemProps) => {
  return (
    <FormPreview.Item<BenefitPackage>
      title="Dependants number"
      insert={() => `${benefitPackage.min_dependants}-${benefitPackage.max_dependants}`}
    />
  )
}

interface AdditionalInfoDocumentsProps {
  benefitPackage: BenefitPackage
}

export const AdditionalInfoDocuments = ({
  benefitPackage,
}: AdditionalInfoDocumentsProps) => {
  return (
    <Group>
      {benefitPackage.additional_information.map(document => (
        <UploadedFileToDownload
          title={document.name}
          size={document.size}
          id={document.id}
          key={document.id}
        />
      ))}
    </Group>
  )
}
