import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Group } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccessManagementSettingsInterface } from '@src/interfaces/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'

export const AccessManagement = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const { values } = useLapeContext<AccessManagementSettingsInterface>()

  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeAccessManagementPreferences,
  )

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="All access management related settings"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow limited time access',
                description:
                  'When enabled access to groups of permissions can be granted for a limited amount of time.',
              }}
              name="enable_limited_time_access"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow service accounts',
                description:
                  'Service accounts allows access to the APIs to perform similar actions to what the users can do from the UI. Enabling this will allow management of service accounts and their access.',
              }}
              name="enable_service_accounts"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow automatic assignment of access groups',
                description:
                  'Assigns an access group automatically based on a set of rules. Example: All recruiters should get access to the recruitment permissions.',
              }}
              name="enable_automatic_group_assignment"
              onAfterChange={allowAutomaticGroupAssignment => {
                if (
                  !allowAutomaticGroupAssignment &&
                  values.enable_sql_group_assignment
                ) {
                  values.enable_sql_group_assignment = false
                }
              }}
              disabled={disableEdit}
            />
            <HideIfCommercial>
              {values.enable_automatic_group_assignment && (
                <LapeNewSwitch
                  itemTypeProps={{
                    title: 'Enable SQL group assignment',
                    description: 'Allows using SQL for group assignment',
                  }}
                  name="enable_sql_group_assignment"
                  disabled={disableEdit}
                />
              )}
            </HideIfCommercial>
            <ApprovalSteps
              title="Enable access request approval"
              description="Whenever there is an access request define who will be the people expected to approve it."
              switchField="enable_group_access_request_approvals"
              approvalProcess="group_access_request_approvals"
              entity="access request"
              disabled={disableEdit}
              formRef={values}
            />
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ACCESS_MANAGEMENT_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
