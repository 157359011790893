import { AxiosError } from 'axios'
import { SIGNUP_REDIRECT_PARAM } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'
import { api, apiWithoutHandling } from '.'
import { CredentialsLoginInterface } from '@src/interfaces/auth'
import { KYBSDetailsInterface, KYBSeachInterface } from '@src/interfaces/signup'

export const useGetGoogleSignupUrl = () => {
  const result = useFetch<{ auth_url: string }>(
    `/googleSignup`,
    undefined,
    {
      params: {
        redirect_url: SIGNUP_REDIRECT_PARAM,
      },
    },
    true,
  )

  return result?.data?.auth_url
}

export const googleSignup = (code: string) =>
  api.post<CredentialsLoginInterface>('/googleSignup', {
    code,
    redirect_url: SIGNUP_REDIRECT_PARAM,
  })

export const useKYBSearch = (q: string) => {
  return useFetch<KYBSeachInterface[], AxiosError>(
    `/kyb/search`,
    undefined,
    { params: { q, country_code: 'UK' } },
    true,
    { enabled: q.length > 0 },
  )
}

export const getKYBDetails = (identifier: string) =>
  apiWithoutHandling.get<KYBSDetailsInterface>(
    `/kyb/details?identifier=${identifier}&country_code=UK`,
  )
