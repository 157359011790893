import React from 'react'
import { useTable } from '@components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { OfferPlaceholderInterface } from '@src/interfaces/offerTemplates'
import { offerPlaceholdersTableRequests } from '@src/api/offerTemplates'
import {
  offerPlaceholderLabelColumn,
  offerPlaceholderNameColumn,
  offerPlaceholderPredefinedColumn,
  offerPlaceholderTypeColumn,
} from '@src/constants/columns/offerForm/offerPlaceholders'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const rows: RowInterface<OfferPlaceholderInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...offerPlaceholderNameColumn,
      width: 140,
    },
    {
      ...offerPlaceholderLabelColumn,
      width: 290,
    },
    {
      ...offerPlaceholderPredefinedColumn,
      width: 300,
    },
    {
      ...offerPlaceholderTypeColumn,
      width: 160,
    },
  ],
}

export const OfferPlaceholders = () => {
  const table = useTable<OfferPlaceholderInterface>(offerPlaceholdersTableRequests)
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddOfferFormTemplate)

  return (
    <Table.Widget>
      {canAdd && (
        <Table.Widget.Actions>
          <PrimaryAction
            useIcon={Plus}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL)}
          >
            Create new
          </PrimaryAction>
        </Table.Widget.Actions>
      )}

      <Table.Widget.Table>
        <AdjustableTable<OfferPlaceholderInterface>
          name={TableNames.OfferPlaceholders}
          useWindowScroll
          dataType="Offer placeholders"
          row={rows}
          {...table}
          emptyState={<EmptyTableRaw title="Offer placeholders will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
