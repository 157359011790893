import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { ReviewCyclesMasterSettings } from './ReviewCyclesMasterSettings'
import { ProbationAndPipSettings } from './ProbationAndPipSettings'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@src/components/Page/Page'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { performanceSettings } from '@src/api/performanceSettings'
import Form from '@src/features/Form/Form'
import { API } from '@src/constants/api'

export const PerformanceSettings = () => {
  useSetDocumentTitle('Settings', 'Performance')

  const tabs = [
    {
      to: ROUTES.SETTINGS.PERFORMANCE.REVIEW_CYCLES,
      path: ROUTES.SETTINGS.PERFORMANCE.REVIEW_CYCLES,
      component: ReviewCyclesMasterSettings,
      title: 'Performance review cycles',
    },
    {
      to: ROUTES.SETTINGS.PERFORMANCE.PROBATION_AND_PIP,
      path: ROUTES.SETTINGS.PERFORMANCE.PROBATION_AND_PIP,
      component: ProbationAndPipSettings,
      title: 'Probation & PIP',
    },
  ]

  return (
    <PageWrapper>
      <PageHeader
        title="Performance app settings"
        subtitle="Settings for all performance functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <TabBarNavigation tabs={tabs} />

      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <Form
              api={performanceSettings}
              forceParams={{ id: '1' }}
              invalidateQueries={[API.PERFORMANCE_SETTINGS]}
              fieldsToExclude={['ui_only_grade_calculation_method']}
            >
              <tab.component />
            </Form>
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}
