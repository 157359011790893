import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ActionButton,
  HStack,
  IconButton,
  ProgressStep,
  ProgressWidget,
  StatusWidget,
  Token,
  VStack,
  Text,
  chain,
  useStatusPopup,
  StatusPopup,
  Button,
  Box,
} from '@revolut/ui-kit'
import { Pencil, Plus, Cross } from '@revolut/icons'

import {
  deleteTenantDiscount,
  useTenantDiscounts,
  useTenantSubscriptionInfo,
  useTenantSubscriptionPlans,
} from '@src/api/tenants'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { PageBody } from '@src/components/Page/PageBody'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatMoney, formatPeriod } from '@src/utils/format'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { TenantDiscountStatus } from '@src/interfaces/tenants'
import { IdAndName } from '@src/interfaces'
import { SUBSCRIPTION_CURRENCY } from '@src/pages/Settings/PlanManagement/common'
import { utcToLocalDate } from '@src/utils/timezones'

export const AccountDiscounts = () => {
  const [selectedPlan, setSelectedPlan] = useState<IdAndName | null>(null)
  const [discountToDelete, setDiscountToDelete] = useState<number>()
  const [deleteDiscountPending, setDeleteDiscountPending] = useState(false)

  const params = useParams<{ id: string }>()
  const statusPopup = useStatusPopup()

  const { discounts, isFetching, error, refetch } = useTenantDiscounts(params.id)
  const {
    data: subscriptionInfo,
    error: subscriptionError,
    isFetching: isFetchingSubscriptionInfo,
  } = useTenantSubscriptionInfo(params.id)
  const {
    subscriptionPlans,
    error: subscriptionPlansError,
    isFetching: isFetchingSubscriptionPlans,
  } = useTenantSubscriptionPlans(params.id)

  if (error || subscriptionError || subscriptionPlansError) {
    return <FormErrorGuard error={error || subscriptionError || subscriptionPlansError} />
  }

  if (
    !discounts ||
    isFetching ||
    !subscriptionInfo ||
    isFetchingSubscriptionInfo ||
    !subscriptionPlans ||
    isFetchingSubscriptionPlans
  ) {
    return <PageLoading />
  }

  const planOptions = subscriptionPlans.map(plan => ({
    key: plan.id,
    label: plan.name,
    value: { id: plan.id, name: plan.name },
  }))

  if (!planOptions.length) {
    return (
      <PageBody>
        <Box mt="s-16">
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
                '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
              }}
            />
            <StatusWidget.Title>No subscription plans found</StatusWidget.Title>
          </StatusWidget>
        </Box>
      </PageBody>
    )
  }

  if (!selectedPlan) {
    if (planOptions.length) {
      setSelectedPlan(planOptions[0]?.value)
    }
    return null
  }

  const getStepState = (status: TenantDiscountStatus) => {
    return status === 'used' || status === 'not_started'
      ? 'done'
      : status === 'active'
      ? 'pending'
      : 'default'
  }

  const getStepIndicatorColor = (status: TenantDiscountStatus) => {
    return status === 'used'
      ? Token.color.blue
      : status === 'active'
      ? Token.color.green
      : Token.color.greyTone10
  }

  const getStepColor = (status: TenantDiscountStatus) => {
    return status === 'used'
      ? Token.color.blue
      : status === 'active'
      ? Token.color.green
      : Token.color.foreground
  }

  const discountsToDisplay = discounts.filter(
    d => d.subscription_plan.id === selectedPlan.id,
  )

  return (
    <>
      <PageBody>
        <VStack mt="s-16" space="s-16">
          <RadioSelectInput
            value={selectedPlan}
            onChange={opt => setSelectedPlan(opt)}
            options={planOptions}
            disabled={planOptions.length < 2}
          />

          {discountsToDisplay.length ? (
            <VStack>
              <ProgressWidget style={{ padding: 0 }}>
                <ProgressWidget.Steps variant="vertical-compact">
                  {discountsToDisplay.map(discount => (
                    <ProgressStep
                      state={getStepState(discount.status.id)}
                      indicatorColor={getStepIndicatorColor(discount.status.id)}
                      key={discount.id}
                    >
                      <ProgressStep.Title>
                        <Text
                          color={
                            discount.status.id === 'not_started'
                              ? Token.color.greyTone50
                              : undefined
                          }
                        >
                          {formatMoney(
                            Number(discount.monthly_fee),
                            SUBSCRIPTION_CURRENCY,
                          )}{' '}
                          per active user per month
                        </Text>
                      </ProgressStep.Title>
                      <ProgressStep.Description>
                        {chain(
                          <Text color={getStepColor(discount.status.id)}>
                            {discount.status.name}
                          </Text>,
                          formatPeriod(
                            utcToLocalDate(discount.start_date),
                            utcToLocalDate(discount.end_date),
                          ),
                        )}
                      </ProgressStep.Description>
                      {discount.status.id === 'active' ||
                      discount.status.id === 'not_started' ? (
                        <ProgressStep.Side>
                          <HStack space="s-8">
                            <IconButton
                              use={InternalLink}
                              to={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNT, {
                                id: params.id,
                                discountId: discount.id,
                                subscriptionId: selectedPlan.id,
                              })}
                              useIcon={Pencil}
                              aria-label="Edit discount"
                              color={Token.color.blue}
                              size={20}
                              tapArea={false}
                            />
                            {discount.status.id === 'not_started' ? (
                              <IconButton
                                useIcon={Cross}
                                onClick={() => setDiscountToDelete(discount.id)}
                                aria-label="Remove discount"
                                color={Token.color.blue}
                                size={20}
                                tapArea={false}
                              />
                            ) : null}
                          </HStack>
                        </ProgressStep.Side>
                      ) : null}
                    </ProgressStep>
                  ))}
                </ProgressWidget.Steps>
              </ProgressWidget>

              <ActionButton
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNT, {
                  id: params.id,
                  subscriptionId: selectedPlan.id,
                })}
                useIcon={Plus}
                mt="s-16"
              >
                Add discount
              </ActionButton>
            </VStack>
          ) : (
            <StatusWidget>
              <StatusWidget.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images/3D022.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D022@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D022@3x.png',
                }}
              />
              <StatusWidget.Title>
                {selectedPlan.name} Plan Discounts will appear here
              </StatusWidget.Title>
              <StatusWidget.Action
                useIcon={Plus}
                use={InternalLink}
                to={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.DISCOUNT, {
                  id: params.id,
                  subscriptionId: selectedPlan.id,
                })}
              >
                Add Discount
              </StatusWidget.Action>
            </StatusWidget>
          )}
        </VStack>
      </PageBody>

      <ConfirmationDialog
        label="Are you sure you want to delete this discount?"
        body=""
        variant="compact"
        open={!!discountToDelete}
        loading={deleteDiscountPending}
        onClose={() => setDiscountToDelete(undefined)}
        onReject={() => setDiscountToDelete(undefined)}
        onConfirm={async () => {
          if (discountToDelete) {
            try {
              setDeleteDiscountPending(true)
              await deleteTenantDiscount(params.id, discountToDelete)
              refetch()
            } catch (err) {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to delete discount</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(err)}
                  </StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button onClick={statusPopup.hide} elevated>
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            } finally {
              setDeleteDiscountPending(false)
              setDiscountToDelete(undefined)
            }
          }
        }}
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}
