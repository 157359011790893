import { applyMiddleware, combineReducers, createStore, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import { authSaga } from './auth/sagas'
import { AuthReducer } from './auth/reducers'
import { AuthState } from './auth/types'
import { FeedbackState } from './feedback/types'
import { FeedbackReducer } from './feedback/reducers'
import { feedbackSaga } from './feedback/sagas'
import { performanceSaga } from '@src/store/performance/sagas'
import PerformanceReducer from '@src/store/performance/reducers'
import { PerformanceState } from '@src/store/performance/types'

export interface State {
  feedback: FeedbackState
  auth: AuthState
  performance: PerformanceState
}

function* rootSaga() {
  yield all([fork(authSaga), fork(feedbackSaga), fork(performanceSaga)])
}

export default function configureStore(state?: Partial<State>): Store<State> {
  const composeEnhancers = composeWithDevTools({})
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    combineReducers({
      feedback: FeedbackReducer,
      auth: AuthReducer,
      performance: PerformanceReducer,
    }),
    state,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  )

  sagaMiddleware.run(rootSaga)

  return store
}
