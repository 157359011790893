import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { Switch, Route } from 'react-router-dom'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { Teams } from '@src/pages/Settings/Organisation/Teams/Teams'
import { Departments } from '@src/pages/Settings/Organisation/Departments/Departments'
import { General } from '@src/pages/Settings/Organisation/General/General'
import { List } from '@src/pages/Settings/Organisation/List/List'

export const OrganisationSettings = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.ORGANISATION.LIST}>
          <List />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ORGANISATION.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ORGANISATION.TEAMS}>
          <Teams />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS}>
          <Departments />
        </Route>
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
