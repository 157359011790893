import React from 'react'
import { Box, Text, Flex, Token } from '@revolut/ui-kit'
import Tickets from '@src/pages/Forms/ProbationOverview/ProbationGoals/Tickets'
import { ReviewCategory } from '@src/interfaces/performance'
import { ProbationGoalInterface } from '@src/interfaces/probationReview'
import styled from 'styled-components'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'
import ActionCard from '@components/ActionCard/ActionCard'
import { getLocationDescriptor, navigateTo } from '@src/actions/RouterActions'

const EditLink = styled(Link)`
  color: ${Token.color.blue};
  text-decoration: none;
`

type Props = {
  tickets?: ProbationGoalInterface[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
}

const title = {
  [ReviewCategory.Probation]: 'Probation goals',
  [ReviewCategory.PIP_V2]: 'PIP goals',
}

const route = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS,
}

const ProbationGoals = ({ tickets, employeeId, cycleId, reviewCategory }: Props) => {
  if (!tickets?.length) {
    return (
      <Box mt="s-48">
        <ActionCard
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          caption={title[reviewCategory]}
          title="No goals set"
          text="It is mandatory for the line manager to set probation goals before starting to assess the employee"
          actionLabel="Set goals"
          onClickAction={() => {
            navigateTo(
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              pathToUrl(route[reviewCategory], {
                employeeId,
                cycleId,
              }),
            )
          }}
        />
      </Box>
    )
  }

  return (
    <Box mt="s-48">
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={Token.color.greyTone50} fontWeight={500}>
          {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
          {title[reviewCategory]}
        </Text>
        <EditLink
          to={getLocationDescriptor(
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            pathToUrl(route[reviewCategory], {
              employeeId,
              cycleId,
            }),
          )}
        >
          Edit
        </EditLink>
      </Flex>

      <Tickets canEdit={false} tickets={tickets} />
    </Box>
  )
}

export default ProbationGoals
