import React from 'react'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ROUTES } from '@src/constants/routes'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'

type LapeSkillsFieldProps = {
  isDisabled?: boolean
}

export const LapeSkillsField = ({ isDisabled }: LapeSkillsFieldProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const { openNewTab } = useCreateOptionInNewTab(LOCAL_STORAGE.SKILL_CREATED, {
    ref: 'fill-skills-after-submit',
    route: ROUTES.FORMS.SKILL.GENERAL,
    selector: selectorKeys.approved_functional_skills,
    onLoad: skill => {
      if (skill) {
        values.skills = [...(values.skills || []), skill]
      }
    },
  })

  return (
    <LapeNewMultiSelect<OptionInterface>
      showCreateNewButton
      disabled={isDisabled}
      name="skills"
      placeholder="Skills"
      selector={selectorKeys.approved_functional_skills}
      useQuery
      useQueryOptions={{
        refetchOnWindowFocus: true,
      }}
      onChange={options => {
        if (options) {
          if (options.find(({ value }) => value.id === createNewKey)) {
            openNewTab()
          } else {
            values.skills = options.map(({ value }) => value)
          }
        }
      }}
    />
  )
}
