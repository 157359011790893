import React from 'react'
import { ActionButton, ActionButtonSkeleton } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useGetCompanySettings, useUpdateCompanySettings } from '@src/api/settings'
import { OfficeSuiteProviderType } from '@src/interfaces/settings'
import upperFirst from 'lodash/upperFirst'

type Props = {
  provider: OfficeSuiteProviderType
}

export const OfficeSuiteEnableButton = ({ provider }: Props) => {
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()
  const { data, isLoading: isInitialLoading, refetch } = useGetCompanySettings()
  const { mutateAsync, isLoading } = useUpdateCompanySettings()
  const activeProvider = data?.office_suite_provider?.id
  const enabled = activeProvider === provider

  if (isInitialLoading) {
    return <ActionButtonSkeleton />
  }

  if (enabled) {
    return (
      <ActionButton
        useIcon="Unlink"
        variant="negative"
        pending={isLoading}
        disabled={isLoading}
        onClick={() => {
          confirmationPopup.show({
            label: 'Are you sure?',
            body: 'After disabling this integration you won’t be able to send and receive emails to candidates, schedule interviews, create booking links and synchronise time-off with your employees’ calendars',
            onConfirm: async () => {
              try {
                // we always use id = 1 for settings endpoint
                await mutateAsync(['1', { office_suite_provider: null }])
                showStatusPopup({
                  title: 'Integration disabled',
                })
                refetch()
              } catch (error) {
                showStatusPopup({
                  title: 'Cannot disable integration',
                  description: getStringMessageFromError(error),
                  status: 'error',
                })
              }
            },
          })
        }}
      >
        Disable
      </ActionButton>
    )
  }

  return (
    <ActionButton
      useIcon="Link"
      pending={isLoading}
      disabled={isLoading}
      variant="accent"
      onClick={async () => {
        if (activeProvider) {
          showStatusPopup({
            title: `${upperFirst(activeProvider)} integration is enabled`,
            description: `Please disable ${upperFirst(
              activeProvider,
            )} integration before enabling this integration`,
            status: 'warning',
          })
          return
        }

        try {
          await mutateAsync(['1', { office_suite_provider: { id: provider } }])
          showStatusPopup({ title: 'Integration enabled' })
          refetch()
        } catch (error) {
          showStatusPopup({
            title: 'Cannot disable integration',
            description: getStringMessageFromError(error),
            status: 'error',
          })
        }
      }}
    >
      Enable
    </ActionButton>
  )
}
