import React, { useState } from 'react'
import {
  abbreviate,
  ActionButton,
  Avatar,
  Box,
  HStack,
  Input,
  Item,
  ItemSkeleton,
  Text,
  Token,
} from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { formatPeriod } from '@src/utils/format'
import { Check, Cross } from '@revolut/icons'
import {
  EmployeeTimeOffRequestInterface,
  EmployeeTimeOffRequestSummaryInterface,
} from '@src/interfaces/timeOff'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  canApproveEmployeeTimeOffRequest,
  canRejectEmployeeTimeOffRequest,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { approveTimeOffRequest, rejectTimeOffRequest } from '@src/api/timeOff'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getAvatarUrl } from '@src/utils/employees'

type Props = {
  alwaysShowActions?: boolean
  employee: EmployeeInterface | EmployeeOptionInterface
  request: EmployeeTimeOffRequestSummaryInterface | EmployeeTimeOffRequestInterface
  refetchData: () => Promise<void>
}
export const ApprovalItem = ({
  alwaysShowActions,
  employee,
  request,
  refetchData,
}: Props) => {
  const [isPending, setIsPending] = useState(false)
  const [approvalPending, setApprovalPending] = useState(false)
  const [rejectionPending, setRejectionPending] = useState(false)
  const [showRejectPopup, setShowRejectPopup] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')

  const handleApprove = async () => {
    try {
      setApprovalPending(true)
      await approveTimeOffRequest(String(employee.id), request.id)
    } finally {
      setApprovalPending(false)
      setIsPending(true)
      refetchData().then(() => setIsPending(false))
    }
  }

  const handleReject = () => {
    setShowRejectPopup(true)
  }

  const handleConfirmReject = async () => {
    try {
      setRejectionComment('')
      setRejectionPending(true)
      setShowRejectPopup(false)
      await rejectTimeOffRequest(String(employee.id), request.id, rejectionComment)
    } finally {
      setRejectionPending(false)
      setIsPending(true)
      refetchData().then(() => setIsPending(false))
    }
  }

  if (isPending) {
    return <ItemSkeleton />
  }
  return (
    <>
      <Item
        key={request.id}
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
          employeeId: employee.id,
          id: request.id,
        })}
      >
        <Item.Avatar>
          <Avatar
            size={40}
            image={getAvatarUrl(employee.avatar)}
            label={employee.full_name ? abbreviate(employee.full_name) : undefined}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {`${formatPeriod(request.from_date_time!, request.to_date_time!)} ${
              request.category?.name ? `(${request.category.name})` : ''
            }`}
          </Item.Title>
          <Item.Description>
            <Text color={Token.color.warning}>Pending</Text>
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <HStack gap="s-12">
            {(alwaysShowActions || canApproveEmployeeTimeOffRequest(request)) && (
              <ActionButton
                useIcon={Check}
                onClick={e => {
                  e.preventDefault()
                  return handleApprove()
                }}
                pending={approvalPending}
                disabled={rejectionPending}
              >
                Approve
              </ActionButton>
            )}
            {(alwaysShowActions || canRejectEmployeeTimeOffRequest(request)) && (
              <ActionButton
                useIcon={Cross}
                variant="negative"
                onClick={e => {
                  e.preventDefault()
                  return handleReject()
                }}
                pending={rejectionPending}
                disabled={approvalPending}
              >
                Reject
              </ActionButton>
            )}
          </HStack>
        </Item.Side>
      </Item>
      <ConfirmationDialog
        open={showRejectPopup}
        onClose={() => {
          setShowRejectPopup(false)
          setRejectionComment('')
        }}
        onConfirm={handleConfirmReject}
        onReject={() => {
          setShowRejectPopup(false)
          setRejectionComment('')
        }}
        label="Please enter rejection reason"
        body={
          <Box>
            <Text mb="s-16" display="inline-block" color={Token.color.greyTone50}>
              Kindly let the employee know why you are rejecting this request
            </Text>
            <Input
              label="Add reason here"
              value={rejectionComment}
              onChange={e => setRejectionComment(e.currentTarget.value)}
            />
          </Box>
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}
