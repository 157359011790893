import React from 'react'
import { Text, Widget } from '@revolut/ui-kit'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'

type Props = {
  sectionTitle: string
  title?: string
  text?: string
  children: React.ReactNode
}

const OutcomeWrapper = ({ sectionTitle, title, text, children }: Props) => {
  return (
    <>
      <NewStepperTitle title={sectionTitle} />
      <Widget p="s-16">
        {title && (
          <Text mb="s-16" fontWeight={500} fontSize="h5" use="div">
            {title}
          </Text>
        )}
        {text && (
          <Text mb="s-16" fontSize="caption" use="div">
            {text}
          </Text>
        )}
        {children}
      </Widget>
    </>
  )
}

export default OutcomeWrapper
