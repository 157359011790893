import React from 'react'
import { CalibratedGradeInterface, ReviewerRelation } from '@src/interfaces/performance'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { relationToString } from '@src/features/Scorecard/constants'
import { Tag, Text, Token } from '@revolut/ui-kit'
import PerformanceGrade, {
  getColorsAndGrade,
} from '@components/PerformanceGrade/PerformanceGrade'

interface Props {
  value: CalibratedGradeInterface
  className?: string
}

const TooltipBody = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 15px;
  grid-row-gap: 8px;
  align-items: center;
  white-space: nowrap;
  border-radius: 4px;
  box-shadow: 0px 14px 28px rgba(25, 28, 31, 0.04), 0px 8px 16px rgba(25, 28, 31, 0.04),
    0px -1px 0px rgba(25, 28, 31, 0.04);
`

const PersonalRating: typeof PerformanceGrade = styled(PerformanceGrade)<{
  background: string | null
  color: string | null
}>`
  min-width: 100px;
  font-size: 14px;
  padding: 6px;
  text-align: center;
  border-radius: 4px;
`

const Name = styled.div`
  font-size: 11px;
`

const Relation = styled.span`
  color: ${Token.color.greyTone50};
  padding-left: 6px;
`

const Grade = ({ value, className }: Props) => {
  const data = getColorsAndGrade(value.display_grade)

  if (!data) {
    return null
  }

  const renderTooltipBody = () => {
    if (value.grade_calibrated) {
      return <TooltipBody>Your grade has been calibrated.</TooltipBody>
    }

    const fmData = getColorsAndGrade(value.functional_manager_grade)
    const lmData = getColorsAndGrade(value.line_manager_grade)

    return (
      <TooltipBody>
        {value.line_manager && lmData && (
          <>
            <PersonalRating grade={value.line_manager_grade} />
            <Name>
              {value.line_manager.display_name}
              <Relation>
                ({relationToString(ReviewerRelation.LineManager, true)})
              </Relation>
            </Name>
          </>
        )}
        {value.functional_manager && fmData && (
          <>
            <PersonalRating grade={value.functional_manager_grade} />
            <Name>
              {value.functional_manager.display_name}
              <Relation>
                ({relationToString(ReviewerRelation.FunctionalManager, true)})
              </Relation>
            </Name>
          </>
        )}
      </TooltipBody>
    )
  }

  return (
    <Tooltip
      body={renderTooltipBody()}
      placement={'bottom'}
      backgroundColor={Token.color.background}
      className={className}
    >
      <Tag color={data.background}>
        <Text fontSize="caption" color={data.color}>
          Grade: {data.grade}
        </Text>
      </Tag>
    </Tooltip>
  )
}

export default Grade
