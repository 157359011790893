import { api } from './index'
import { API } from '../constants/api'
import { CompanyInterface } from '../interfaces/company'
import { AxiosPromise } from 'axios'
import { useFetch } from '@src/utils/reactQuery'

export const getCompany = (): AxiosPromise<CompanyInterface> =>
  api.get(API.COMPANY, undefined)

export const useGetCompany = () => useFetch<CompanyInterface>(API.COMPANY)
