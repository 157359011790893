import React, { useMemo } from 'react'
import { Box, Text } from '@revolut/ui-kit'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionHeader'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import {
  InterviewResult,
  InterviewFeedbackLevelsPoints,
  InterviewFeedbackSenioritySectionInterface,
} from '@src/interfaces/interviewTool'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import { PerformanceRating } from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import ResultHireSection from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/ResultHireSection'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'

export interface ResultLevelPoints {
  basic?: number | null
  intermediate?: number | null
  advanced?: number | null
  expert?: number | null
}

export const getResultLevelPoints = (
  values?: InterviewFeedbackLevelsPoints,
): ResultLevelPoints | null =>
  values
    ? {
        basic: values.basic_points,
        intermediate: values.intermediate_points,
        advanced: values.advanced_points,
        expert: values.expert_points,
      }
    : null

type Props = {
  titleIndexNumber: number
  seniorities?: InterviewFeedbackSenioritySectionInterface
  type?: InterviewScorecardType
  levelsPoints?: InterviewFeedbackLevelsPoints
  suggestedLevel?: PerformanceRating
  isViewMode?: boolean
  deviationJustificationRequired?: boolean
  suggestedResult?: InterviewResult
}

const ResultEvaluationSection = ({
  suggestedLevel,
  suggestedResult,
  type,
  seniorities,
  isViewMode,
  levelsPoints,
  deviationJustificationRequired,
  titleIndexNumber,
}: Props) => {
  const { options, asyncState } = useFetchOptions<RadioOption>(
    selectorKeys.interview_feedback_result_choices,
  )

  const disabled = asyncState !== 'ready' || isViewMode
  const levels = getResultLevelPoints(levelsPoints)
  const seniorityOptions = useMemo(() => {
    const items: RadioOption[] = seniorities?.options?.length
      ? [
          ...(seniorities?.options.map(o => ({
            label: o.seniority.name,
            value: o,
          })) || []),
          {
            label: 'Can’t say',
            value: null,
          },
        ]
      : []

    return items
  }, [seniorities])

  const levelsOptions = useMemo<RadioOption[] | null>(() => {
    if (!levels) {
      return null
    }

    return [
      {
        label: `Poor${
          Number.isFinite(levels.basic) ? ` (below ${levels.basic} points)` : ''
        }`,
        value: 'poor',
      },
      {
        label: `Basic${Number.isFinite(levels.basic) ? ` (${levels.basic} points)` : ''}`,
        value: 'basic',
      },
      {
        label: `Intermediate${
          Number.isFinite(levels.intermediate) ? ` (${levels.intermediate} points)` : ''
        }`,
        value: 'intermediate',
      },
      {
        label: `Advanced${
          Number.isFinite(levels.advanced) ? ` (${levels.advanced} points)` : ''
        }`,
        value: 'advanced',
      },
      {
        label: `Expert${
          Number.isFinite(levels.expert) ? ` (${levels.expert} points)` : ''
        }`,
        value: 'expert',
      },
    ]
  }, [levels])

  const getOrder = () => {
    const order = {
      level: '',
      seniority: '',
      justification: '',
    }

    order.level = type === InterviewScorecardType.HiringPanel ? 'a' : 'b'

    if (type === InterviewScorecardType.General) {
      order.seniority = order.level === 'a' ? 'b' : 'c'

      if (seniorityOptions?.length) {
        order.justification = order.seniority === 'b' ? 'c' : 'd'
      } else {
        order.justification = order.seniority
      }
    } else {
      order.seniority = order.level

      if (seniorityOptions?.length) {
        order.justification = order.seniority === 'a' ? 'b' : 'c'
      } else {
        order.justification = order.seniority
      }
    }

    return order
  }

  return (
    <>
      <Box mb="s-32">
        <ResultHireSection
          disabled={disabled}
          options={options}
          suggestedResult={suggestedResult}
          titleIndexNumber={titleIndexNumber}
          type={type}
        />
      </Box>
      {levelsOptions && (
        <Box mb="s-32">
          {type === InterviewScorecardType.General && (
            <Box mb="s-32">
              <SectionHeader
                title={`${titleIndexNumber}${
                  getOrder().level
                }. What is the candidate’s level according to your evaluation?`}
                subtitle={
                  <>
                    System recommendation:{' '}
                    <Text fontWeight={600} data-testid="seniority-recommendation">
                      {suggestedLevel ? PerformanceRatingTitle[suggestedLevel] : '-'}
                    </Text>
                  </>
                }
              />

              <LapeNewRadioButtons
                name="rating"
                options={levelsOptions}
                readOnly={disabled}
                renderRadio={radio => <Box py="s-8">{radio}</Box>}
              />
            </Box>
          )}
          {!!seniorityOptions.length && (
            <>
              <SectionHeader
                title={`${titleIndexNumber}${
                  getOrder().seniority
                }. What is the candidate’s seniority according to your evaluation?`}
              />

              <LapeNewRadioButtons
                name="scorecard.seniority_section.answer"
                options={seniorityOptions}
                readOnly={disabled}
                renderRadio={radio => <Box py="s-8">{radio}</Box>}
              />
            </>
          )}
        </Box>
      )}

      {deviationJustificationRequired && (
        <Box mb="s-32">
          <SectionHeader
            title={`${titleIndexNumber}${
              getOrder().justification
            }. Why did you deviate from system recommendations?`}
          />
          <LapeNewTextArea
            name="deviation_justification"
            label="Why did you deviate from system recommendations"
            rows={3}
            readOnly={disabled}
            required
            data-testid="deviation_justification"
          />
        </Box>
      )}
    </>
  )
}

export default ResultEvaluationSection
