import React, { useEffect, useMemo, useState } from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import ChangeRoundContent from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRoundContent'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'

type Props = {
  candidateId: number
  activeRoundId?: number
  onPreviewRound?: (roundId: number) => void
  readOnly?: boolean
}

const ChangeRound = ({ candidateId, activeRoundId, onPreviewRound, readOnly }: Props) => {
  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)
  const [currentRound, setCurrentRound] = useState<number | undefined>(activeRoundId)

  useEffect(() => {
    setCurrentRound(activeRoundId)
  }, [activeRoundId])

  const list = useMemo(
    () =>
      (data || []).reduce<{
        active: InterviewRoundInterface[]
        archived: InterviewRoundInterface[]
      }>(
        (acc, item) => {
          if (item.archived_reason) {
            acc.archived.push(item)
          } else {
            acc.active.push(item)
          }

          return acc
        },
        { active: [], archived: [] },
      ),
    [data],
  )

  return (
    <ErrorBoundary>
      <ChangeRoundContent
        activeItems={list.active}
        archivedItems={list.archived}
        currentRound={currentRound}
        candidateId={candidateId}
        activeRoundId={activeRoundId}
        onRoundClick={id => {
          onPreviewRound?.(id)
          setCurrentRound(id)
        }}
        isLoading={isLoading}
        readOnly={readOnly}
      />
    </ErrorBoundary>
  )
}

export default ChangeRound
