import React from 'react'
import Form from '@src/features/Form/Form'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { growthPlanFormRequests } from '@src/api/growthPlans'

interface Props {
  children: React.ReactNode
}

export const EditGrowthPlanForm = ({ children }: Props) => {
  return (
    <Form<GrowthPlanInterface> api={growthPlanFormRequests} disableDataCleanup>
      {children}
    </Form>
  )
}
