import React, { useState } from 'react'
import {
  EpicOption,
  KPIEpicsTargetRow,
  KpiTargetEpics,
  KpiTargets,
} from '@src/interfaces/kpis'
import { Flex, TextButton } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import DeleteWarningPopup from '@components/ColumnInserts/KPITargetActions/DeleteWarningPopup'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export interface KpiTargetActionsOptions {
  allTargets: KpiTargets[] | KpiTargetEpics[]
  onEdit: (targetIndex: number) => void
  onDelete: (targetIndex: number) => void
  onDeleteEpic?: (targetIndex: number, epicIndex: number) => void
  onView?: (targetIndex: number) => void
}

export interface KPITargetActionsProps {
  parentIndexes: number[]
  rowData: KPIEpicsTargetRow | KpiTargets | EpicOption
  options: KpiTargetActionsOptions
  id?: number
}

const KPITargetActions = ({
  parentIndexes,
  rowData,
  options,
  id,
}: KPITargetActionsProps) => {
  const { disabled } = useLapeContext()
  const [deletePopupOpen, setDeletePopupOpen] = useState<boolean>(false)
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const { allTargets, onDelete, onEdit, onView, onDeleteEpic } = options
  const targetIndex = parentIndexes[0]

  if ('url' in rowData && parentIndexes.length > 1 && !disabled) {
    const reviewCycle = allTargets[targetIndex].review_cycle

    return reviewCycle?.status !== ReviewCycleStatus.closed ? (
      <Flex>
        <TextButton
          onClick={e => {
            onDeleteEpic?.(targetIndex, parentIndexes[1])
            e.stopPropagation()
          }}
          color="red"
        >
          Delete
        </TextButton>
      </Flex>
    ) : null
  }
  const isRoadmap = 'children' in rowData

  const thisTargetOffset = rowData.review_cycle?.offset
  const firstTargetOffset = allTargets[0]?.review_cycle?.offset
  const lastTargetOffset = allTargets[allTargets.length - 1]?.review_cycle?.offset

  const isFirstCycle = thisTargetOffset === firstTargetOffset
  const isLastCycle = thisTargetOffset === lastTargetOffset

  const canEdit = rowData.review_cycle?.status !== 'closed' && !disabled

  // can delete if it is new or if it exists, but it is not probation KPI. Probation KPI target cannot be deleted after creation
  const canDelete = !id || !('employee_cycle' in rowData && rowData.employee_cycle)
  const canDeleteWithoutPrompt = canEdit && (isFirstCycle || isLastCycle)

  return (
    <>
      <DeleteWarningPopup
        isOpen={deletePopupOpen}
        isRoadmap={isRoadmap}
        onClose={() => setDeletePopupOpen(false)}
        onConfirm={() => onDelete(targetIndex)}
      />
      <Flex>
        {onView && !canEdit && (
          <TextButton
            mr="10px"
            color="blue"
            fontWeight={500}
            onClick={e => {
              onView(targetIndex)
              e.stopPropagation()
            }}
          >
            View
          </TextButton>
        )}
        {canEdit && (
          <>
            {!goalsEnabled && (
              <TextButton
                mr="10px"
                color="blue"
                fontWeight={500}
                onClick={e => {
                  onEdit(targetIndex)
                  e.stopPropagation()
                }}
              >
                Edit
              </TextButton>
            )}
            {canDelete && (
              <TextButton
                color="red"
                fontWeight={500}
                onClick={e => {
                  e.stopPropagation()
                  if (canDeleteWithoutPrompt) {
                    onDelete(targetIndex)
                  } else {
                    setDeletePopupOpen(true)
                  }
                }}
              >
                Delete
              </TextButton>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

export default KPITargetActions
