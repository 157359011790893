import React from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { TabsInterface } from '@src/interfaces/data'
import { ReportInterface } from '@src/interfaces/dataAnalytics'

interface SaveButtonProps {
  tabs: TabsInterface<ReportInterface>[]
}

const SubmitButton = ({ tabs }: SaveButtonProps) => {
  const location = useLocation()
  const { values, submit } = useLapeContext<ReportInterface>()

  const tabIndex = tabs.findIndex(tab => matchPath(location.pathname, tab.path))
  const isLastTab = tabIndex >= tabs.length - 1

  if (!isLastTab) {
    return null
  }

  return (
    <NewSaveButtonWithPopup
      afterSubmitUrl={ROUTES.APPS.DATA_ANALYTICS.REPORTS}
      hideWhenNoChanges={false}
      onClick={submit}
      successText="Report saved successfully"
      useValidator
    >
      {values.id ? 'Save Changes' : 'Submit'}
    </NewSaveButtonWithPopup>
  )
}

export default connect(SubmitButton)
