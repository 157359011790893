import React from 'react'
import {
  Item,
  ActionButton,
  Avatar,
  StatusPopup,
  Button,
  useStatusPopup,
} from '@revolut/ui-kit'
import { MergeConnectionInterface } from '@src/interfaces/integrations'
import { CreateEmployeeUploadSource, createEmployeeUpload } from '@src/api/bulkDataImport'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { formatDateTime } from '@src/utils/format'

interface NotSyncedBannerProps {
  integration?: MergeConnectionInterface
}

export const SyncBanner = ({ integration }: NotSyncedBannerProps) => {
  const statusPopup = useStatusPopup()

  if (!integration) {
    return null
  }

  const createEmployeeUploadFromWorkspace = (type: CreateEmployeeUploadSource) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Importing data</StatusPopup.Title>
      </StatusPopup>,
    )

    createEmployeeUpload(type)
      .then(response => {
        statusPopup.hide()
        navigateReplace(
          pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.IMPORT_EMPLOYEES.UPLOAD.SESSION, {
            id: response.data.id,
          }),
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to import data</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary" elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Database" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {integration.last_sync
            ? `Last synced ${formatDateTime(integration.last_sync.update_date_time)}`
            : 'No employee data has been synced yet'}
        </Item.Title>
      </Item.Content>
      {integration.last_sync ? null : (
        <Item.Side>
          <ActionButton
            useIcon="Retry"
            onClick={() =>
              createEmployeeUploadFromWorkspace(integration.integration_type)
            }
          >
            Sync
          </ActionButton>
        </Item.Side>
      )}
    </Item>
  )
}
