import React, { useMemo } from 'react'
import { Box } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { matchSorter } from 'match-sorter'

import GlobalSearchSection from './GlobalSearchSection/GlobalSearchSection'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { employeeSettingsLinkConfig } from '@src/pages/Settings/Employees/config'
import { timeOffSettingsLinkConfig } from '@src/pages/Settings/TimeOff/config'
import { ResultLimit } from './GlobalSearchSidebar'
import {
  SettingsLinkItem,
  getSettingsLinkConfig,
} from '@src/pages/Settings/common/config'
import { useGlobalSettings } from '@src/api/settings'
import { documentsSettingsLinkConfig } from '@src/pages/Settings/Documents/config'
import { jobsSettingsLinkConfig } from '@src/pages/Settings/Jobs/config'
import { candidatesSettingsLinkConfig } from '@src/pages/Settings/Candidates/config'
import { goalsSettingsLinkConfig } from '@src/pages/Settings/Goals/config'
import { organisationSettingsLinkConfig } from '@src/pages/Settings/Organisation/config'
import { performanceSettingsLinkConfig } from '@src/apps/Performance/Settings/config'

export const useSettingsSearchResults = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const globalSettings = useGlobalSettings()

  const items = useMemo(() => {
    const employeeConfig = getSettingsLinkConfig({
      config: employeeSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const timeOffConfig = getSettingsLinkConfig({
      config: timeOffSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const documentsConfig = getSettingsLinkConfig({
      config: documentsSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const jobsConfig = getSettingsLinkConfig({
      config: jobsSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const candidatesConfig = getSettingsLinkConfig({
      config: candidatesSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const goalsConfig = getSettingsLinkConfig({
      config: goalsSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const organisationConfig = getSettingsLinkConfig({
      config: organisationSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })
    const performanceConfig = getSettingsLinkConfig({
      config: performanceSettingsLinkConfig,
      permissions,
      featureFlags,
      globalSettings: globalSettings.settings,
      type: 'search',
    })

    return Object.values({
      ...employeeConfig,
      ...timeOffConfig,
      ...documentsConfig,
      ...jobsConfig,
      ...candidatesConfig,
      ...goalsConfig,
      ...organisationConfig,
      ...performanceConfig,
    }).filter(Boolean)
  }, [globalSettings.settings])

  return (query: string) => matchSorter(items, query, { keys: [item => item.searchKeys] })
}

interface GlobalSearchSettingsResultsProps {
  results: SettingsLinkItem[]
  onItemClick: () => void
  onShowMore: () => void
  limitResults?: boolean
  isCoreNavigation: boolean
}

export const GlobalSearchSettingsResults = ({
  results,
  onItemClick,
  onShowMore,
  limitResults = true,
  isCoreNavigation,
}: GlobalSearchSettingsResultsProps) => {
  if (!results.length) {
    return null
  }

  return (
    <GlobalSearchSection
      name="Settings"
      showMore={limitResults && results.length > ResultLimit ? onShowMore : undefined}
      isCoreNavigation={isCoreNavigation}
    >
      <Box onClick={onItemClick}>
        {(limitResults ? results.slice(0, ResultLimit) : results).map(item => (
          <React.Fragment key={item.id}>{item.component}</React.Fragment>
        ))}
      </Box>
    </GlobalSearchSection>
  )
}
