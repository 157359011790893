import { TextNode } from 'lexical'
import React, { useState } from 'react'
import styled, { CSSObject } from 'styled-components'
import { Box, Color, Relative, Token } from '@revolut/ui-kit'

import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { HeadingNode } from '@lexical/rich-text'
import { AutoLinkNode, LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { ListPlugin } from '@lexical/react/LexicalListPlugin'
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin'

import { ToolbarPlugin } from '@components/Editor/plugins/ToolbarPlugin'
import ImagesPlugin from '@components/Editor/plugins/ImagePlugin'
import { ImageNode } from '@components/Editor/components/ImageNode'
import LinkPlugin from '@components/Editor/plugins/LinkPlugin'
import { HtmlPlugin } from '@components/Editor/plugins/HtmlPlugin'
import { Placeholder } from '@components/Editor/components/Placeholder'
import { FloatingLinkEditorPlugin } from '@components/Editor/plugins/FloatingLinkEditorPlugin'
import { editorCss } from '@components/Editor/common'
import { ListMaxIndentLevelPlugin } from '@components/Editor/plugins/ListMaxIndentLevelPlugin'
import { ExtendedTextNode } from '@components/Editor/components/ExtendedTextNode'

export const Wrapper = styled.div`
  .underline {
    text-decoration: underline;
  }
  .bold {
    font-weight: bold;
  }
  .italic {
    font-style: italic;
  }
  .image {
    display: inline-block;
    position: relative;
    .focused {
      outline: 2px solid ${Token.color.blue};
    }
  }
`

const nodes = [
  ExtendedTextNode,
  {
    replace: TextNode,
    with: (node: TextNode) => new ExtendedTextNode(node.__text, node.__key),
  },
  ImageNode,
  AutoLinkNode,
  LinkNode,
  HeadingNode,
  ListNode,
  ListItemNode,
]

export interface EditorProps {
  value?: string
  onChange?: (value?: string) => void
  placeholder?: string
  hasError?: boolean
  message?: React.ReactNode | string | null
  allowImages?: boolean
  backgroundColor?: Color
  toolbarBackgroundColor?: Color
  wrapperBackgroundColor?: Color
}

const Editor = ({
  value,
  onChange = () => {},
  placeholder,
  allowImages = true,
  backgroundColor = Token.color.widgetBackground,
  toolbarBackgroundColor,
  wrapperBackgroundColor,
}: EditorProps) => {
  const [floatingAnchorElem, setFloatingAnchorElem] = useState<HTMLDivElement | null>(
    null,
  )

  const onRef = (_floatingAnchorElem: HTMLDivElement) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem)
    }
  }

  const initialConfig = {
    namespace: 'MyEditor',
    onError: (error: Error) => {
      throw error
    },
    nodes,
    theme: {
      image: 'image',
      text: {
        bold: 'bold',
        italic: 'italic',
        underline: 'underline',
      },
    },
  }

  const css: CSSObject = {
    padding: '12px',
    backgroundColor,
    outline: 0,
    resize: 'vertical',
    overflow: 'auto',
    borderBottomLeftRadius: '16px',
    borderBottomRightRadius: '16px',
    minHeight: '100px',
    ...editorCss,
  }

  return (
    <Box bg={wrapperBackgroundColor || backgroundColor} borderRadius="widget">
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin
          allowImages={allowImages}
          backgroundColor={toolbarBackgroundColor || backgroundColor}
        />
        {allowImages ? <ImagesPlugin /> : ''}
        <ListPlugin />
        <ListMaxIndentLevelPlugin maxDepth={1} />
        <TabIndentationPlugin />
        <LinkPlugin />
        <HtmlPlugin onHtmlChanged={onChange} initialHtml={value} />
        <FloatingLinkEditorPlugin anchorElem={floatingAnchorElem || undefined} />

        <Relative>
          <RichTextPlugin
            contentEditable={
              <Wrapper ref={onRef}>
                <ContentEditable css={css} className="root" ariaLabel={placeholder} />
              </Wrapper>
            }
            placeholder={<Placeholder text={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </Relative>
      </LexicalComposer>
    </Box>
  )
}

export default Editor
