import React from 'react'
import MonthPicker from '@components/Inputs/MonthPicker/MonthPicker'
import { HStack } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import { Modifier } from 'react-day-picker/types/Modifiers'

type Props = {
  startDate?: Date | null
  setStartDate: (val?: Date | null) => void
  endDate?: Date | null
  setEndDate: (val?: Date | null) => void
  endDateDisabled?: boolean
  endDateAfterDaysDisabled?: boolean
  endDateLabel?: string
}

const RangeMonthPicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  endDateDisabled,
  endDateAfterDaysDisabled,
  endDateLabel = 'End date',
}: Props) => {
  const getStartDateDisabledDays = () => {
    const disabledDays: { before?: Date; after?: Date } = { after: new Date() }

    if (endDate) {
      disabledDays.after = endDate
    }

    return disabledDays as Modifier
  }

  const getEndDateDisabledDays = () => {
    const disabledDays: { before?: Date; after?: Date } = {}

    if (endDateAfterDaysDisabled) {
      disabledDays.after = new Date()
    }

    if (startDate) {
      disabledDays.before = startDate
    }

    return isEmpty(disabledDays) ? undefined : (disabledDays as Modifier)
  }

  return (
    <HStack gap="s-16">
      <MonthPicker
        label="Start date"
        value={startDate}
        onChange={date => {
          if (date) {
            setStartDate(date)
          }
        }}
        disabledDays={getStartDateDisabledDays()}
      />
      <MonthPicker
        label={endDateLabel}
        value={endDate}
        onChange={date => setEndDate(date)}
        disabled={endDateDisabled}
        disabledDays={getEndDateDisabledDays()}
      />
    </HStack>
  )
}

export default RangeMonthPicker
