import React from 'react'
import { EditableTableProps } from '@components/TableV2/EditableTable/EditableTable'

export interface EditableTableContextInterface {
  data: any[]
  onChange: EditableTableProps<any>['onChange']
}

export const EditableTableContext = React.createContext<EditableTableContextInterface>({
  data: [],
  onChange: () => {},
})
