import React from 'react'

import Table from '@components/TableV2/Table'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { KeyPersonResponsibilityInterface } from '@src/interfaces/keyPerson'
import { selectorKeys } from '../api'

export const keyPersonResponsibilityNameColumn: ColumnInterface<KeyPersonResponsibilityInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.key_person_types_name,
    title: 'Name',
  }

export const keyPersonResponsibilityCategoryColumn: ColumnInterface<KeyPersonResponsibilityInterface> =
  {
    type: CellTypes.text,
    idPoint: 'category.id',
    dataPoint: 'category.name',
    sortKey: 'category',
    filterKey: 'category',
    selectorsKey: selectorKeys.key_person_types_categories,
    title: 'Category',
  }

export const keyPersonResponsibilityRequiredForColumn: ColumnInterface<KeyPersonResponsibilityInterface> =
  {
    type: CellTypes.text,
    idPoint: 'required_for_entities',
    dataPoint: 'required_for_entities',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Required for',
  }

export const keyPersonResponsibilitySuccessionColumn: ColumnInterface<KeyPersonResponsibilityInterface> =
  {
    type: CellTypes.text,
    idPoint: 'succession_plan.id',
    dataPoint: 'succession_plan.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Succession',
  }

export const keyPersonResponsibilityStatusColumn: ColumnInterface<KeyPersonResponsibilityInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.key_person_types_status,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status.name}
        variant={data.status.id === 'active' ? 'success' : 'neutral'}
      />
    ),
  }
