import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DetectPlaceholdersInterface,
  OfferPlaceholderInterface,
  OfferTemplateInterface,
  OfferTemplateHTMLInterface,
} from '@src/interfaces/offerTemplates'
import { AccessRequestInterface } from '@src/interfaces/accessRequest'
import { FileInterface } from '@src/interfaces/files'
import { useFetch } from '@src/utils/reactQuery'
import { CustomFieldInterface } from '@src/interfaces/offerCreation'

export const offerPlaceholdersTableRequests: TableRequestInterface<OfferPlaceholderInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/offerFormPlaceholders`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const offerTemplatesTableRequests: TableRequestInterface<OfferTemplateInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/offerFormTemplates`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const offerPlaceholdersFormRequest: RequestInterfaceNew<AccessRequestInterface> = {
  get: async ({ id }) => api.get(`/offerFormPlaceholders/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/offerFormPlaceholders/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/offerFormPlaceholders`, data),
  delete: async ({ id }) => api.delete(`/offerFormPlaceholders/${id}`),
}

export const offerTemplateRequest: RequestInterfaceNew<OfferTemplateInterface> = {
  get: async ({ id }) => api.get(`/offerFormTemplates/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/offerFormTemplates/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/offerFormTemplates`, data),
  delete: async ({ id }) => api.delete(`/offerFormTemplates/${id}`),
}

export const detectPlaceholdersFromFile = (fileId: number) =>
  api.post<DetectPlaceholdersInterface>(`/offerFormTemplates/detectPlaceholders`, {
    template_file: { id: fileId },
  })

export const getOfferTemplateSample = () =>
  api.get<Partial<FileInterface>[]>('/offerFormTemplates/getFileExamples')

export const useGetOfferTemplateCustomFields = (id?: number | string | null) =>
  useFetch<CustomFieldInterface[]>(id ? `/offerFormTemplates/${id}/customFields` : null)

export const useGetOfferTemplateHTML = (id?: number | string | null) =>
  useFetch<OfferTemplateHTMLInterface>(
    id ? `/offerFormTemplates/${id}/getOfferTemplateAsHTML` : null,
    undefined,
    undefined,
    undefined,
    {
      enabled: false,
    },
  )
