import React from 'react'
import {
  FilterByInterface,
  SortByInterface,
  SORT_DIRECTION,
  FetchDataQueryInterface,
} from '@src/interfaces/data'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { CommonRequisitionTable } from '@src/features/CommonRequisitionTable/CommonRequisitionTable'

export const getFilterBy = (
  data: EmployeeInterface,
  filter: FilterByInterface[] = [],
): FilterByInterface[] => [
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'line_manager__id',
    nonResettable: true,
  },
  {
    filters: initialRequisitionStatusFilter,
    columnName: 'status',
    nonResettable: true,
  },
  ...filter,
]

export const sortBy: SortByInterface[] = [
  {
    sortBy: 'pipeline_queue_position',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'team__name',
    direction: SORT_DIRECTION.DESC,
  },
  {
    sortBy: 'seniority__level',
    direction: SORT_DIRECTION.ASC,
  },
  {
    sortBy: 'priority',
    direction: SORT_DIRECTION.DESC,
  },
]

export const getStatsData = (data: EmployeeInterface): FetchDataQueryInterface => ({
  filters: [
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'line_manager__id',
    },
  ],
})

interface Props {
  data: EmployeeInterface
  navigation?: React.ReactElement
}
export const Hiring = ({ data, navigation }: Props) => {
  return (
    <CommonRequisitionTable
      filterBy={getFilterBy(data)}
      sortBy={sortBy}
      statsData={getStatsData(data)}
      type="employee"
      navigation={navigation}
    />
  )
}
