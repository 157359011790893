import React from 'react'
import { Box, Button, Item, Popup, Text, Token } from '@revolut/ui-kit'
import DefineScorecard from '@src/pages/OnboardingChecklist/HiringProcess/components/DefineScorecard'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

type ScorecardTabProps = {
  disableNext: boolean
  generateScorecard: boolean
  stage: Partial<HiringProcessInterface>
  onCancel: () => void
  onNext: () => void
}

const ScorecardTab = ({
  disableNext,
  generateScorecard,
  stage,
  onCancel,
  onNext,
}: ScorecardTabProps) => {
  const renderHeader = () => {
    return (
      <Text color={Token.color.greyTone50} pb="s-16" use="div" variant="caption">
        Scorecards help interviewers to assess candidate skills during interview.
      </Text>
    )
  }
  if (stage?.stage_type?.id === 'skills_round' && generateScorecard) {
    return (
      <>
        <Box>
          <Box>{renderHeader()}</Box>
          <Item useIcon="Info">
            <Item.Content>
              <Item.Title>Generate skill scorecard</Item.Title>
              <Item.Description>
                Each specialisation has a unique set of skills. You can generate a
                specific scorecard for each specialisation, which will test the skills
                relevant to that specialisation.
              </Item.Description>
            </Item.Content>
          </Item>
        </Box>
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={onNext}>Next</Button>
        </Popup.Actions>
      </>
    )
  }
  return (
    <>
      <Box>{renderHeader()}</Box>
      <DefineScorecard disableNext={disableNext} onCancel={onCancel} onNext={onNext} />
    </>
  )
}

export default ScorecardTab
