import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { TimeOffRequests } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Requests'
import { TimeOffPolicies } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'

export const TimeOffPage = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS}>
          <PageHeader
            backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_MANAGEMENT}
            title="Time Off Requests"
          />
          <TimeOffRequests />
        </Route>
        <Route exact path={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES}>
          <PageHeader
            backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_MANAGEMENT}
            title="Time Off Policies"
          />
          <TimeOffPolicies />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
