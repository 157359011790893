import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface } from '@src/interfaces/employees'

export const hasDocumentManagerPermissions = (data: EmployeeInterface) => {
  const isHrManager = data?.field_options?.permissions?.includes(
    PermissionTypes.HRManagerPermissions,
  )
  const isDocumentManager = data?.field_options?.permissions?.includes(
    PermissionTypes.DocumentsManagerPermissions,
  )
  return Boolean(isHrManager || isDocumentManager)
}
