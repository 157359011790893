import React, { useRef, useState } from 'react'

import {
  Flex,
  Text,
  Icon,
  FilterButtonSkeleton,
  FilterButton,
  Ellipsis,
} from '@revolut/ui-kit'
import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '../hooks'

type Props = {
  onChange: (option: IdAndName) => void
  value: IdAndName | undefined
  surveyId: number | undefined
}

export const RoundSelector = ({ onChange, value, surveyId }: Props) => {
  const [open, setOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  const { rounds: options, asyncState } = useSurveyRounds(surveyId)

  if (asyncState !== 'ready') {
    return <FilterButtonSkeleton width={200} />
  }

  return (
    <>
      <FilterButton
        useIcon="Calendar"
        data-testid="engagement-round-selector"
        aria-haspopup="listbox"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{value?.name || 'Round'}</Text>
          </Ellipsis>

          <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <FilterSelect
        open={open}
        selector={() => Promise.resolve({ options })}
        anchorRef={selectRef}
        onClose={() => {
          setOpen(false)
        }}
        value={value ? [value] : undefined}
        onChange={newValue => {
          const [option] = newValue

          if (option) {
            setOpen(false)
            onChange(option)
          }
        }}
        type={FilterSelectType.SingleSelect}
        width={100}
      />
    </>
  )
}
