import { MoreBar } from '@revolut/ui-kit'
import React from 'react'

import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { getRightsToWork } from '@src/api/employees'
import Stat from '@src/components/Stat/Stat'
import {
  rightToWorkCountryColumn,
  rightToWorkDocumentType,
  rightToWorkIsSponsoredByCompany,
  rightToWorkLastVerifiedOn,
  rightToWorkStatus,
  rightToWorkType,
  rightToWorkUpdatedOn,
  rightToWorkValidFrom,
  rightToWorkValidUntil,
} from '@src/constants/columns/rightToWork'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface, RightToWorkInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<RightToWorkInterface> = {
  cells: [
    {
      ...rightToWorkCountryColumn,
      width: 140,
    },
    {
      ...rightToWorkDocumentType,
      width: 140,
    },
    {
      ...rightToWorkValidFrom,
      width: 140,
    },
    {
      ...rightToWorkValidUntil,
      width: 140,
    },
    {
      ...rightToWorkType,
      width: 140,
    },
    {
      ...rightToWorkIsSponsoredByCompany,
      width: 140,
    },
    {
      ...rightToWorkLastVerifiedOn,
      width: 140,
    },
    {
      ...rightToWorkUpdatedOn,
      width: 140,
    },
    {
      ...rightToWorkStatus,
      width: 140,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const RightToWork = ({ data }: Props) => {
  const table = useTable<RightToWorkInterface>({
    getItems: getRightsToWork(data.id),
  })

  const handleNewRowClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
        employeeId: data.id,
      }),
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat val={table.count} label="Total" />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action onClick={handleNewRowClick} useIcon="Plus">
            Add right to work
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RightToWorkInterface>
          hideCount
          name={TableNames.EmployeeRightToWork}
          useWindowScroll
          dataType="Right to work"
          row={ROW}
          {...table}
          noDataMessage="No right to work"
          onRowClick={rightToWork =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
                employeeId: data.id,
                id: rightToWork.id,
              }),
            )
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
