import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Subheader, Text, Token, Box } from '@revolut/ui-kit'

import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { PageBody } from '@components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ESignatureTemplateEditor } from '@src/features/ESignatureTemplateEditor'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useValidationErrors } from '@src/features/ESignatureTemplateEditor/common'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'

export const TabUploadFile = () => {
  const { values, submit } = useLapeContext<DocumentsTemplatesInterface>()
  const errors = useValidationErrors(values)

  return (
    <PageBody maxWidthMd={Token.breakpoint.xl}>
      <Box mb="s-32">
        <Subheader>
          <Subheader.Title>
            {values.file_name ? (
              <FormattedMessage
                id="documents.templates.esignature.configure.title"
                defaultMessage="Configure the template"
              />
            ) : (
              <FormattedMessage
                id="documents.templates.esignature.upload.title"
                defaultMessage="Upload the template for your eSignature"
              />
            )}
          </Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          {values.file_name ? (
            <FormattedMessage
              id="documents.templates.esignature.configure.subtitle"
              defaultMessage="Add all fields and placeholders to your document"
            />
          ) : (
            <FormattedMessage
              id="documents.templates.esignature.upload.subtitle"
              defaultMessage="This is the base file to be used to generate the signatures on"
            />
          )}
        </Text>
      </Box>
      <ESignatureTemplateEditor canUploadAnotherFile values={values} errors={errors} />
      <PageActions>
        <NewSaveButtonWithPopup
          onClick={() => {
            errors.reset()
            return submit()
          }}
          onAfterSubmit={() =>
            navigateReplace(
              pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, {
                id: String(values.id),
              }),
            )
          }
          errorHandler={errors.parse}
        />
      </PageActions>
    </PageBody>
  )
}
