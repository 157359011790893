import { API, PerformanceTimeRange } from '../constants/api'
import { FetchDataQueryInterface } from '../interfaces/data'
import {
  CreateTeamSimpleInterface,
  TeamGoalsStats,
  TeamInterface,
  TeamNPS,
  TeamNPSStats,
  TeamsStatsInterface,
} from '../interfaces/teams'
import { api, apiWithoutHandling } from './index'
import { filterSortPageIntoQuery } from '../utils/table'
import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
} from '../interfaces'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'
import { useFetch, useInfiniteFetch, useUpdate } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { CXSummaryInterface, SupportLoadBreakdownBy } from '@src/interfaces/cx'
import { FileInterface } from '@src/interfaces/files'

export const teamsRequests: RequestInterface<TeamInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.TEAMS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.TEAMS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.TEAMS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.TEAMS}/${id}`),
  postItem: async data => api.post(API.TEAMS, data),
  getExport: async (exportType, filterQuery) =>
    api.get(`${API.TEAMS}/${exportType}`, {
      params: filterQuery,
      responseType: 'blob',
    }),
}

export const teamsRequestsNew: RequestInterfaceNew<TeamInterface> = {
  get: async ({ id }) => api.get(`${API.TEAMS}/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`${API.TEAMS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.TEAMS, data),
  delete: async ({ id }) => api.delete(`${API.TEAMS}/${id}`),
}

export const silentDeleteTeam = async ({ id }: { id: number }) =>
  apiWithoutHandling.delete(`${API.TEAMS}/${id}`)

export const useGetTeam = (id?: number | string) => {
  return useFetch<TeamInterface>(id ? `${API.TEAMS}/${id}` : null)
}

export const useUpdateTeam = () =>
  useUpdate<TeamInterface, TeamInterface>(
    API.TEAMS,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )

export const useFindTeamByName = (name: string) => {
  const result = useFetch<GetRequestInterface<TeamInterface>>(`${API.TEAMS}?name=${name}`)

  return {
    ...result,
    data: result.data?.results.length ? result.data?.results[0] : null,
  }
}

export const teamCXSummaryRequests =
  (teamId: number | string): RequestInterface<CXSummaryInterface>['getItems'] =>
  ({ sortBy, filters, page }) =>
    api.get(`${API.TEAMS}/${teamId}/cx/summary`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const teamCXComplaintsGraph = (
  teamId: number | string,
  range: PerformanceTimeRange,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.TEAMS}/${teamId}/cx/complaints/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const teamCXCsatDetractorsGraph = (
  teamId: number | string,
  range: PerformanceTimeRange,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.TEAMS}/${teamId}/cx/csatDetractors/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      chart_type: 'percentage',
    },
  })
export const teamCXSupportLoadGraph = (
  teamId: number | string,
  range: PerformanceTimeRange,
  breakdown?: SupportLoadBreakdownBy,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.TEAMS}/${teamId}/cx/supportLoad/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      breakdown,
    },
  })

export const updateTeamStatistics = (id: string | number) => {
  return api.patch(`${API.TEAMS}/${id}/statistics`)
}

export const getKpiPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.TEAMS}/${id}/kpiPerformance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const getTeamNPSGraph =
  (teamId: number | string) =>
  (
    _: string,
    range: PerformanceTimeRange = PerformanceTimeRange.month,
  ): AxiosPromise<PerformanceChartCycles> =>
    api
      .get<PerformanceChartCycles>(`${API.TEAMS}/${teamId}/nps/history`, {
        params: {
          date_partitioning: range,
          months: getMonthsByRange(range),
        },
      })
      .then(resp => {
        return {
          ...resp,
          data: {
            progress_history: resp.data.progress_history.map(item => ({
              ...item,
              progress: item.progress && item.progress * 100,
              estimated: item.estimated && item.estimated * 100,
            })),
            targets: resp.data.targets,
          },
        }
      })

export const getTeamNPSStats =
  (id: number | string) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface): AxiosPromise<TeamNPSStats> =>
    api.get(`${API.TEAMS}/${id}/nps/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getTeamNPSDetractors =
  (id: number | string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<TeamNPS>> =>
    api.get(`${API.TEAMS}/${id}/nps/detractors`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useGetTeamApprovals = (id?: number) => {
  return useFetch<ApprovalFlowResponse>(id ? `${API.TEAMS}/${id}/approvals` : null)
}

export const createTeamSimple = (data: CreateTeamSimpleInterface) =>
  apiWithoutHandling.post(API.TEAMS, data)

export const bulkEditTeams = (teamIds: number[], fields: Record<string, number>) =>
  apiWithoutHandling.patch(`${API.TEAMS}/bulkEdit`, {
    ids: teamIds,
    fields,
  })

export const bulkDeleteTeams = (teamIds: number[]) =>
  apiWithoutHandling.post(`${API.TEAMS}/bulkDelete`, { ids: teamIds })

export const useGetTeamsStats = () => {
  return useFetch<TeamsStatsInterface>(`${API.TEAMS}/stats`)
}

export const useGetTeamGoalsStats = (cycleId?: string | number, hasGoals?: boolean) =>
  useFetch<GetRequestInterface<TeamGoalsStats>>(`${API.TEAMS}/goalStats`, 'v1', {
    params: { cycle__id: cycleId, has_goals: hasGoals, status: Statuses.active },
  })

export const useInfiniteGetTeamGoalsStats = (
  cycleId?: string | number,
  hasGoals?: boolean,
) =>
  useInfiniteFetch<GetRequestInterface<TeamGoalsStats>>({
    url: `${API.TEAMS}/goalStats`,
    version: 'v1',
    params: {
      params: { cycle__id: cycleId, has_goals: hasGoals, status: Statuses.active },
    },
    queryOptions: {
      getNextPageParam: ({ pages }) => pages.next,
    },
  })

export const uploadTeamAvatar = (id: number | string, file: File) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, file.name)
  fileFormData.append('category', 'avatar')

  return apiWithoutHandling.post<FileInterface>(
    `${API.TEAMS}/${id}/avatar`,
    fileFormData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  )
}
