import React from 'react'
import { ApplicationForm } from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import Form from '@src/features/Form/Form'
import { jobDescriptionFormRequest } from '@src/api/jobPosting'

type ApplicationFormPreviewProps = {
  jobPosting: JobPostingInterface
}

export const ApplicationFormPreview = ({ jobPosting }: ApplicationFormPreviewProps) => {
  return (
    <Form
      api={jobDescriptionFormRequest}
      validator={{}}
      disableLocalStorageCaching
      forceParams={{ id: String(jobPosting.id) }}
    >
      <ApplicationForm
        disableEditing
        showNoCustomQuestionsWidget
        showPredefinedSections={false}
        showReuseApplicationFormSection={false}
      />
    </Form>
  )
}
