import React from 'react'
import { Icon } from '@revolut/ui-kit'

import { NotificationTypes } from '@src/store/notifications/types'
import { typeToHighlightColor } from '@components/Notification/common'

interface NotificationIconProps {
  type: NotificationTypes
}

export const NotificationIcon = ({ type }: NotificationIconProps) => {
  if (type === NotificationTypes.error) {
    return <Icon name="ExclamationMark" color={typeToHighlightColor[type]} />
  }
  if (type === NotificationTypes.success) {
    return <Icon name="CheckSuccess" color={typeToHighlightColor[type]} />
  }
  if (type === NotificationTypes.warning) {
    return <Icon name="ExclamationTriangle" color={typeToHighlightColor[type]} />
  }

  return <Icon name="Info" color={typeToHighlightColor[type]} />
}
