import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { SendMessage } from '@revolut/icons'
import { SigningStatuses } from '@src/interfaces/offerCreation'
import { useGetOfferSettings } from '@src/api/settings'

type Props = {
  status?: SigningStatuses
  onClick: () => void
}

const SendOfferButton = ({ status, onClick }: Props) => {
  const { data: offerSettings } = useGetOfferSettings()
  return (
    <MoreBar.Action useIcon={SendMessage} onClick={onClick}>
      {!offerSettings?.enable_offer_signing
        ? 'Send offer'
        : status === 'offer_not_sent'
        ? 'Send offer'
        : 'Re-send offer'}
    </MoreBar.Action>
  )
}

export default SendOfferButton
