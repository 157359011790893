import React, { useEffect, useState } from 'react'
import { Item, Avatar, Group, ItemSkeleton } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PayCyclePreviewInterface, PaygroupInterface } from '@src/interfaces/payroll'
import { getPaycyclePreview } from '@src/api/payroll'
import TimelineSidebar from './PayCycleReportsTable/TimelineSidebar'
import { formatPeriod } from '@src/utils/format'
import isNumber from 'lodash/isNumber'

interface PaycyclesPreviewProps {
  hiddenTimelineSteps?: string[]
}
export const PaycyclesPreview = ({ hiddenTimelineSteps }: PaycyclesPreviewProps) => {
  const PREVIEWS_COUNT = 12

  const { values } = useLapeContext<PaygroupInterface>()

  const [previews, setPreviews] = useState<PayCyclePreviewInterface[]>()
  const [selectedPreviewIdx, setSelectedPreviewIdx] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)

  const loadPreviews = async () => {
    setIsLoading(true)
    try {
      const res = await getPaycyclePreview({
        ...values,
        number_of_schedules: PREVIEWS_COUNT,
      })
      setPreviews(res.data)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    loadPreviews()
  }, [
    values.pay_frequency,
    values.pay_period_start_day,
    values.pay_period_second_start_day,
    values.pay_date_day,
    values.pay_date_second_day,
    values.pay_date_lands_on_weekend,
    values.pay_authorities_date_day_of_month,
    values.pay_authorities_frequency,
    values.pay_authorities_date_month_q1,
    values.pay_authorities_date_month_q2,
    values.pay_authorities_date_month_q3,
    values.pay_authorities_date_month_q4,
    values.pay_authorities_date_day_q1,
    values.pay_authorities_date_day_q2,
    values.pay_authorities_date_day_q3,
    values.pay_authorities_date_day_q4,
    values.cut_off_date_trigger_number_of_days,
    values.cut_off_date_trigger,
    values.report_submission_date_days_after_cycle_ends,
    values.report_submission_date_trigger,
  ])

  return (
    <>
      <Group>
        {isLoading
          ? Array(PREVIEWS_COUNT)
              .fill(null)
              .map((_, index) => <ItemSkeleton key={index} />)
          : previews?.map((preview, index) => (
              <Item
                key={index}
                use="button"
                type="button"
                onClick={() => setSelectedPreviewIdx(index)}
              >
                <Item.Avatar>
                  <Avatar useIcon="Credit" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    {formatPeriod(preview.start_date, preview.end_date)}
                  </Item.Title>
                </Item.Content>
              </Item>
            ))}
      </Group>
      <TimelineSidebar
        isOpen={isNumber(selectedPreviewIdx)}
        onClose={() => setSelectedPreviewIdx(undefined)}
        cyclePreview={
          isNumber(selectedPreviewIdx) ? previews?.[selectedPreviewIdx] : undefined
        }
        hiddenSteps={hiddenTimelineSteps}
      />
    </>
  )
}
