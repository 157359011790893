import {
  meetingRecurrence,
  rruleInterface,
  rruleRecurrence,
} from '@src/interfaces/meetingsTracker'
import { assert } from '@src/utils/assert'
import { notReachable } from '@src/utils/notReachable'
import { format } from 'date-fns'

const unsupportedRecurrenceMessage = `Only following recurrences are supported: ${[
  meetingRecurrence.Weekly,
  meetingRecurrence.BiWeekly,
  meetingRecurrence.Monthly,
].join(', ')}`

export const buildRRule = (recurrence: meetingRecurrence, isoDate: string): string => {
  assert(
    recurrence === meetingRecurrence.Weekly ||
      recurrence === meetingRecurrence.BiWeekly ||
      recurrence === meetingRecurrence.Monthly,
    unsupportedRecurrenceMessage,
  )

  const date = new Date(isoDate)
  const day = format(date, 'eeeeee').toUpperCase()

  const dayToken = buildToken('BYDAY', day)
  let frequencyToken: string | undefined
  let intervalToken: string | undefined

  switch (recurrence) {
    case meetingRecurrence.Weekly:
      frequencyToken = buildToken('FREQ', rruleRecurrence.WEEKLY)
      break
    case meetingRecurrence.BiWeekly:
      frequencyToken = buildToken('FREQ', rruleRecurrence.WEEKLY)
      intervalToken = buildToken('INTERVAL', '2')
      break
    case meetingRecurrence.Monthly:
      frequencyToken = buildToken('FREQ', rruleRecurrence.MONTHLY)
      break
    default:
      notReachable(recurrence as never)
  }

  return `RRULE:${[frequencyToken, dayToken, intervalToken].filter(Boolean).join(';')}`
}

const buildToken = (key: keyof rruleInterface, value: string): string => `${key}=${value}`
