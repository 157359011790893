import React from 'react'
import { Carousel, Image, Box } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { SectionTitle } from '../common'

const LOCATIONS = [
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/london.jpg',
    description: 'London',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/london2.jpg',
    description: 'London',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/berlin.jpg',
    description: 'Berlin',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/krakow.jpg',
    description: 'Krakow',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/moscow.jpg',
    description: 'Moscow',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/porto.jpg',
    description: 'Porto',
  },
  {
    image: 'https://cdn.revolut.com/website/assets/retail/careers/office/vilnius.jpg',
    description: 'Vilnius',
  },
]

const SliderCss = css`
  grid-column: 1/-1;
`

export const Locations = () => {
  return (
    <>
      <SectionTitle title="Check out our offices" />
      <Box css={SliderCss} mx="auto" maxWidth={{ all: '100%', md: 830 }}>
        <Carousel>
          {LOCATIONS.map(({ image, description }) => (
            <Carousel.Item width={{ all: 1, md: 1 / 2 }} key={image}>
              <Image
                maxWidth={{ all: '100%', md: 400 }}
                borderRadius={12}
                src={image}
                aria-label={description}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Box>
    </>
  )
}
