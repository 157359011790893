import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '.'
import { useFetch } from '@src/utils/reactQuery'
import {
  BulkEditItemsResponse,
  BulkEditRequest,
  BulkEditResponse,
} from '@src/interfaces/bulkEdit'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

export const postEmployeesBulkEdit = (request: BulkEditRequest) => {
  return api.post(API.EMPLOYEES_BULK_EDIT, request)
}

export const postApplyEmployeesBulkEdit = (id: string | number) => {
  return api.post(`${API.EMPLOYEES_BULK_EDIT}/${id}/apply`)
}

export const useBulkEdit = (id: string | number) => {
  return useFetch<BulkEditResponse>(`${API.EMPLOYEES_BULK_EDIT}/${id}`)
}

export const getBulkEditItems = (id: string | number) => {
  return api.get<BulkEditItemsResponse>(`${API.EMPLOYEES_BULK_EDIT}/${id}/items`)
}

export const getFilteredEmployeesSelector = async (filters: FilterByInterface[]) => {
  return apiWithoutHandling.get<{ options: OptionInterface[] }>(
    API.EMPLOYEES_SELECTOR,
    {
      params: groupFiltersIntoURLQuery(filters),
    },
    'v3',
  )
}
