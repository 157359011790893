import React, { useState } from 'react'
import styled from 'styled-components'
import { Color, Header, Popup, Text, Token } from '@revolut/ui-kit'
import { Cell } from '@revolut/ui-kit'

interface Props {
  title: string
  justification: string
  subtitle?: string
  label?: string
}

const Wrapper = styled.div`
  color: ${Token.color.blue};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const Review = styled.div`
  white-space: break-spaces;
`

const SummaryCommentsPopup = ({
  justification,
  title,
  subtitle = 'Skipped',
  label = 'View Justification',
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Wrapper
        onClick={() => {
          setIsOpen(true)
        }}
      >
        {label}
      </Wrapper>
      <Popup
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>
            {title} · <Text color={Color.GREY_TONE_50}>{subtitle}</Text>
          </Header.Title>
        </Header>

        <Cell>
          <Review>{justification}</Review>
        </Cell>
      </Popup>
    </>
  )
}

export default SummaryCommentsPopup
