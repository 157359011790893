import React, { useState } from 'react'
import { MoreBar, StatusPopup } from '@revolut/ui-kit'
import { Cross } from '@revolut/icons'

import { goBack } from '@src/actions/RouterActions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EmployeeEmailThreadInterface } from '@src/interfaces/employeeEmails'
import { cancelEmployeeEmail } from '@src/api/employeeEmails'

interface CancelEmailProps {
  employeeId: number
  thread: EmployeeEmailThreadInterface
}

const CancelEmail = ({ thread, employeeId }: CancelEmailProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)

  const handleCancel = async () => {
    if (!employeeId || !thread?.emails[0].id) {
      return
    }
    try {
      setCancelLoading(true)
      const result = await cancelEmployeeEmail(employeeId, thread?.emails[0].id)
      if (result?.data) {
        setConfirmationOpen(false)
        setStatusOpen(true)
      }
    } finally {
      setCancelLoading(false)
    }
  }

  return (
    <>
      <MoreBar.Action
        onClick={() => setConfirmationOpen(true)}
        useIcon={Cross}
        variant="negative"
      >
        Cancel &amp; delete email
      </MoreBar.Action>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        label="Cancel sending this email &amp; delete it?"
        body={
          <p>
            Canceling this scheduled email will also delete it. Please confirm you want to
            cancel &amp; delete it
          </p>
        }
        loading={cancelLoading}
        onConfirm={handleCancel}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Cancel email"
        noMessage="Go back"
      />
      <StatusPopup
        variant="success"
        open={statusOpen}
        onClose={() => {
          setStatusOpen(false)
          goBack()
        }}
      >
        <StatusPopup.Title>Email cancelled &amp; deleted</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default CancelEmail
