import React from 'react'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useGetCompanyPreferences } from '@src/api/settings'
import { ColorTag } from '@components/ColorTag/ColorTag'

export const CompanyUnit = () => {
  const isCommercial = useIsCommercial()
  const { data: settings } = useGetCompanyPreferences()

  if (isCommercial) {
    return <ColorTag>{settings?.brand_name || 'Company'}</ColorTag>
  }

  return <ColorTag>Revolut</ColorTag>
}
