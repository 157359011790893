import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import Form from '@src/features/Form/Form'
import { serviceDeskCategoriesRequest } from '@src/api/faq'
import { ServiceDeskCategoryForm } from './General'
import { ServiceDeskCategoryPreview } from './Preview'

export const SDCategory = connect(() => {
  return (
    <PageWrapper>
      <Form api={serviceDeskCategoriesRequest}>
        <Switch>
          <Route path={ROUTES.APPS.HELP_CENTER.SD_CATEGORY.PREVIEW}>
            <ServiceDeskCategoryPreview />
          </Route>
          <Route path={ROUTES.APPS.HELP_CENTER.SD_CATEGORY.EDIT}>
            <ServiceDeskCategoryForm />
          </Route>
        </Switch>
      </Form>
    </PageWrapper>
  )
})
