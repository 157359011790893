import React from 'react'
import { BoxProps, Caption, Flex, H4, HStack, Skeleton, Token } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'

export interface StatProps extends Omit<BoxProps<'div'>, 'label' | 'value'> {
  label: React.ReactNode
  val?: React.ReactNode
  tooltip?: string
}

const Statistic = ({ color, label, val, tooltip, ...rest }: StatProps) => {
  if (val === undefined) {
    return (
      <Flex alignItems="flex-start" flexDirection="column" {...rest}>
        <Skeleton width={50} height={21} mt="5px" />
        <Skeleton mt="s-8" height={14} width={65} />
      </Flex>
    )
  }

  return (
    <Flex alignItems="flex-start" flexDirection="column" {...rest}>
      <HStack space="s-8" align="center">
        <H4
          textAlign="left"
          fontWeight="bold"
          color={color}
          data-testid={`stat:${label}`}
        >
          {val}
        </H4>
        {tooltip && (
          <Tooltip placement="top" text={tooltip}>
            <InfoOutline size={16} color={Token.color.greyTone50} />
          </Tooltip>
        )}
      </HStack>
      <Caption color={Token.color.greyTone50} whiteSpace="nowrap">
        {label}
      </Caption>
    </Flex>
  )
}

export default Statistic
