import React, { useRef, useState } from 'react'
import { Box, VStack } from '@revolut/ui-kit'

import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { WorkScheduleCustomApprovalFlowInterface } from '@src/interfaces/workSchedule'
import { TableFilter } from '@src/features/AudienceSelection/AudienceTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useQuery } from '@src/utils/queryParamsHooks'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { customApproversFlowEligibilityCustomFiltersRequests } from '@src/api/workSchedule'

import { ApprovalChainInput } from '../../common/ApprovalChainInput'
import { EligibilityConfig } from '../../common/EligibilityConfig'
import { useHandleEligibilityError } from '../../common/useHandleEligibilityError'
import { useHandleNonFieldError } from '../../common/useHandleNonFieldError'
import { CommonTabsProps } from './common'

type Props = CommonTabsProps
export const WorkScheduleCustomApproversDetails = ({ nextTabPath }: Props) => {
  const { query } = useQuery()
  const { values, submit } = useLapeContext<WorkScheduleCustomApprovalFlowInterface>()

  const [customFilters, setCustomFilters] = useState<TableFilter>({})

  const groupsPlaceholderRef = useRef<HTMLDivElement>(null)

  const {
    error: eligibilityGroupError,
    setError: setEligibilityGroupError,
    errorHandler: eligibilityErrorHandler,
  } = useHandleEligibilityError(groupsPlaceholderRef)

  const nonFieldErrorHandler = useHandleNonFieldError([
    'approval_steps_configuration',
    'eligibility_group_type',
    'eligibility_groups',
    'employee_approver',
    'name',
  ])

  return (
    <>
      <VStack space="s-16">
        <Box>
          <SectionTitle title="What should your custom approval flow called?" />
          <LapeNewInput name="name" label="Custom approval flow name" required />
        </Box>
        <ApprovalChainInput values={values} />
        <EligibilityConfig
          values={values}
          eligibilityGroupError={eligibilityGroupError}
          setEligibilityGroupError={setEligibilityGroupError}
          customFilters={customFilters}
          setCustomFilters={setCustomFilters}
          placeholderRef={groupsPlaceholderRef}
          entity="customApprovers"
        />
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          hideWhenNoChanges={false}
          onClick={async () => {
            if (
              values.eligibility_group_type?.id !== 'employee_filters' ||
              !customFilters
            ) {
              return submit()
            }
            if (!values.custom_filters_eligibility_group?.id) {
              const { data: newFilter } =
                await customApproversFlowEligibilityCustomFiltersRequests.submit({
                  table_filters: customFilters,
                  eligibility_group_type: 'employee_filters',
                })
              values.custom_filters_eligibility_group = {
                id: newFilter.id,
                name:
                  newFilter.name || `Custom filters eligibility group ${newFilter.id}`,
              }
            } else {
              await customApproversFlowEligibilityCustomFiltersRequests.update(
                {
                  table_filters: customFilters,
                },
                values.custom_filters_eligibility_group.id,
              )
            }
            return submit()
          }}
          onAfterSubmit={res => {
            navigateReplace(pathToUrl(nextTabPath!, { id: res.id }, query))
          }}
          errorHandler={e => {
            try {
              eligibilityErrorHandler(e)
            } catch (unhandledErr) {
              nonFieldErrorHandler(unhandledErr)
            }
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
