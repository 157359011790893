import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import Icon from '../Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import { ReviewInterface } from '@src/interfaces/performance'
import { Avatar, Color, IconName, Token } from '@revolut/ui-kit'
import {
  BtnWrapper,
  Circle,
  ButtonTooltip,
  Container,
  Count,
  StyledTooltip,
} from '@src/components/AssessButtons/CommonSC'
import { useIsNewTable } from '@components/TableV2/hooks'

export enum AssessButtonTypes {
  positive = 'yes',
  negative = 'no',
  neutral = 'dont_know',
  unknown = 'unknown',
}

interface TooltipInterface {
  [AssessButtonTypes.positive]: ReviewInterface[]
  [AssessButtonTypes.negative]: ReviewInterface[]
  [AssessButtonTypes.neutral]: ReviewInterface[]
  [AssessButtonTypes.unknown]: ReviewInterface[]
}

export interface AssessButtonsProps {
  disabled?: boolean
  checked: AssessButtonTypes[]
  onChange?: (value: AssessButtonTypes) => void
  className?: string
  showCounts?: boolean
  reviewsList: TooltipInterface
  error?: boolean
  visibleButtons?: AssessButtonTypes[]
  name?: string
}

const positiveBtnCSS = css`
  border: 1px solid ${Token.color.teal};
  background-color: ${Token.color.teal_5};
  color: ${Token.color.teal};
  &:active {
    color: ${Token.color.teal};
  }
`

const negativeBtnCSS = css`
  border: 1px solid ${Token.color.red};
  background-color: rgba(245, 76, 62, 0.05);
  color: ${Token.color.red};
  &:active {
    color: ${Token.color.red};
  }
`

const neutralBtnCSS = css`
  border: 1px solid ${Token.color.foreground};
`

const unknownBtnCSS = css`
  border: 1px solid ${Token.color.greyTone20};
`

const ButtonStyled = styled.button<{
  styles: AssessButtonTypes
  checked: boolean
}>`
  box-sizing: border-box;
  border-radius: 4px;
  width: 28px;
  height: 24px;
  background-color: ${props => (props.disabled ? Token.color.background : 'inherit')};
  color: ${props => (props.disabled ? Token.color.greyTone20 : Token.color.greyTone50)};
  cursor: ${props => {
    if (props.disabled) {
      return 'not-allowed'
    }

    if (props.checked) {
      return 'auto'
    }

    return 'pointer'
  }};
  &:active {
    color: ${props => (props.disabled ? Token.color.greyTone20 : Token.color.greyTone50)};
  }
  ${props => {
    if (props.checked) {
      if (props.styles === AssessButtonTypes.positive) {
        return positiveBtnCSS
      }

      if (props.styles === AssessButtonTypes.negative) {
        return negativeBtnCSS
      }

      if (props.styles === AssessButtonTypes.neutral) {
        return neutralBtnCSS
      }

      if (props.styles === AssessButtonTypes.unknown) {
        return unknownBtnCSS
      }
    }
    return `border: 1px solid ${Token.color.greyTone20}`
  }}
`

const Minus = styled.div<{ checked: boolean; disabled?: boolean }>`
  width: 9px;
  height: 1.5px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Token.color.greyTone20};
`

const ErrorTooltip = styled(Tooltip)`
  position: absolute;
  right: -23px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: ${Token.color.red};
`

type ButtonWithTooltipProps = {
  reviewsList: ReviewInterface[]
  disabled?: boolean
  checked: boolean
  onClick: () => void
  name?: string
  bg: Color
  bgChecked: Color
  color: Color
  colorChecked: Color
  label: React.ReactNode
  icon: IconName
  tooltipAlwaysVisible?: boolean
  type: AssessButtonTypes
}

const ButtonWithTooltip = ({
  name,
  onClick,
  reviewsList,
  checked,
  disabled,
  bgChecked,
  colorChecked,
  color,
  bg,
  label,
  icon,
  tooltipAlwaysVisible,
  type,
}: ButtonWithTooltipProps) => {
  return (
    <StyledTooltip
      placement="top"
      body={<ButtonTooltip reviewsList={reviewsList}>{label}</ButtonTooltip>}
      hide={tooltipAlwaysVisible ? false : !reviewsList.length}
    >
      <Avatar
        use={disabled ? undefined : 'button'}
        type="button"
        style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
        useIcon={icon}
        bg={checked ? bgChecked : bg}
        color={checked ? colorChecked : color}
        onClick={() => {
          if (disabled) {
            return
          }
          onClick()
        }}
        data-testid={`${name}_${type}`}
        size={32}
      />
    </StyledTooltip>
  )
}

const AssessButtons = ({
  name,
  disabled,
  checked,
  onChange,
  className,
  reviewsList,
  showCounts,
  error,
  visibleButtons = [
    AssessButtonTypes.positive,
    AssessButtonTypes.negative,
    AssessButtonTypes.neutral,
  ],
}: AssessButtonsProps) => {
  const isNewTable = useIsNewTable()
  const [currentlyChecked, setCurrentlyChecked] = useState<AssessButtonTypes>()

  useEffect(() => {
    setCurrentlyChecked(checked[0])
  }, [checked])

  const onButtonClick = (type: AssessButtonTypes) => {
    setCurrentlyChecked(type)
    onChange && onChange(type)
  }

  const positiveBtnChecked =
    !!checked?.includes(AssessButtonTypes.positive) ||
    currentlyChecked === AssessButtonTypes.positive

  const negativeBtnChecked =
    !!checked?.includes(AssessButtonTypes.negative) ||
    currentlyChecked === AssessButtonTypes.negative

  const neutralBtnChecked =
    !!checked?.includes(AssessButtonTypes.neutral) ||
    currentlyChecked === AssessButtonTypes.neutral

  const unknownBtnChecked =
    !!checked?.includes(AssessButtonTypes.unknown) ||
    currentlyChecked === AssessButtonTypes.unknown

  const countLineHeight = isNewTable ? 32 : 24

  return (
    <Container className={className}>
      {visibleButtons.includes(AssessButtonTypes.positive) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.positive].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.positive].length}
            </Count>
          ) : null}

          {isNewTable ? (
            <ButtonWithTooltip
              onClick={() => onButtonClick(AssessButtonTypes.positive)}
              name={name}
              checked={positiveBtnChecked}
              bg={Token.color.greyTone8}
              bgChecked={Token.color.success}
              color={Token.color.greyTone50}
              colorChecked={Token.color.white}
              reviewsList={reviewsList[AssessButtonTypes.positive]}
              disabled={disabled}
              label="Yes"
              icon="16/Check"
              type={AssessButtonTypes.positive}
            />
          ) : (
            <ButtonStyled
              type="button"
              disabled={disabled}
              styles={AssessButtonTypes.positive}
              checked={positiveBtnChecked}
              data-checked={positiveBtnChecked}
              onClick={() => onButtonClick(AssessButtonTypes.positive)}
              data-testid={`${name}_${AssessButtonTypes.positive}`}
            >
              <StyledTooltip
                placement="top"
                body={
                  <ButtonTooltip reviewsList={reviewsList[AssessButtonTypes.positive]}>
                    Yes
                  </ButtonTooltip>
                }
                hide={!reviewsList[AssessButtonTypes.positive].length}
              >
                <Icon type={'Check'} size={'micro'} />
              </StyledTooltip>
            </ButtonStyled>
          )}
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.negative) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.negative].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.negative].length}
            </Count>
          ) : null}

          {isNewTable ? (
            <ButtonWithTooltip
              onClick={() => onButtonClick(AssessButtonTypes.negative)}
              name={name}
              checked={negativeBtnChecked}
              bg={Token.color.greyTone8}
              bgChecked={Token.color.danger}
              color={Token.color.greyTone50}
              colorChecked={Token.color.white}
              reviewsList={reviewsList[AssessButtonTypes.negative]}
              disabled={disabled}
              label="No"
              icon="16/Cross"
              type={AssessButtonTypes.negative}
            />
          ) : (
            <ButtonStyled
              type="button"
              disabled={disabled}
              styles={AssessButtonTypes.negative}
              checked={negativeBtnChecked}
              data-checked={negativeBtnChecked}
              onClick={() => onButtonClick(AssessButtonTypes.negative)}
              data-testid={`${name}_${AssessButtonTypes.negative}`}
            >
              <StyledTooltip
                placement="top"
                body={
                  <ButtonTooltip reviewsList={reviewsList[AssessButtonTypes.negative]}>
                    No
                  </ButtonTooltip>
                }
                hide={!reviewsList[AssessButtonTypes.negative].length}
              >
                <Icon type={'Close'} size={'micro'} />
              </StyledTooltip>
            </ButtonStyled>
          )}
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.neutral) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.neutral].length ? (
            <Count lineHeight={countLineHeight}>
              {reviewsList[AssessButtonTypes.neutral].length}
            </Count>
          ) : null}

          {isNewTable ? (
            <ButtonWithTooltip
              onClick={() => onButtonClick(AssessButtonTypes.neutral)}
              name={name}
              checked={neutralBtnChecked}
              bg={Token.color.greyTone8}
              bgChecked={Token.color.greyTone50}
              color={Token.color.greyTone50}
              colorChecked={Token.color.white}
              reviewsList={reviewsList[AssessButtonTypes.neutral]}
              disabled={disabled}
              label={<>Don't&nbsp;know</>}
              icon="RadiobuttonOff"
              tooltipAlwaysVisible
              type={AssessButtonTypes.neutral}
            />
          ) : (
            <ButtonStyled
              type="button"
              disabled={disabled}
              checked={neutralBtnChecked}
              data-checked={neutralBtnChecked}
              styles={AssessButtonTypes.neutral}
              onClick={() => onButtonClick(AssessButtonTypes.neutral)}
              data-testid={`${name}_${AssessButtonTypes.neutral}`}
            >
              <StyledTooltip
                placement="top"
                body={
                  <ButtonTooltip reviewsList={reviewsList[AssessButtonTypes.neutral]}>
                    Don't&nbsp;know
                  </ButtonTooltip>
                }
              >
                <Circle
                  checked={!!checked?.includes(AssessButtonTypes.neutral)}
                  disabled={disabled}
                />
              </StyledTooltip>
            </ButtonStyled>
          )}
        </BtnWrapper>
      )}

      {visibleButtons.includes(AssessButtonTypes.unknown) && (
        <BtnWrapper showCounts={showCounts}>
          {reviewsList[AssessButtonTypes.unknown].length ? (
            <Count>{reviewsList[AssessButtonTypes.unknown].length}</Count>
          ) : null}

          {isNewTable ? (
            <ButtonWithTooltip
              onClick={() => onButtonClick(AssessButtonTypes.unknown)}
              name={name}
              checked={unknownBtnChecked}
              bg={Token.color.greyTone8}
              bgChecked={Token.color.greyTone50}
              color={Token.color.greyTone50}
              colorChecked={Token.color.white}
              reviewsList={[]}
              disabled={disabled}
              label="Unknown"
              icon="Minus"
              type={AssessButtonTypes.unknown}
            />
          ) : (
            <ButtonStyled
              type="button"
              disabled={disabled}
              checked={unknownBtnChecked}
              data-checked={unknownBtnChecked}
              styles={AssessButtonTypes.unknown}
              onClick={() => onButtonClick(AssessButtonTypes.unknown)}
              data-testid={`${name}_${AssessButtonTypes.unknown}`}
            >
              <Minus
                checked={!!checked?.includes(AssessButtonTypes.unknown)}
                disabled={disabled}
              />
            </ButtonStyled>
          )}
        </BtnWrapper>
      )}

      {error && (
        <ErrorTooltip placement="right" text="You need to select a rating">
          <Icon type={'WarningOutlined'} size={'tiny'} />
        </ErrorTooltip>
      )}
    </Container>
  )
}

export default AssessButtons
