import React from 'react'
import { Color, HStack, IconButton } from '@revolut/ui-kit'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import Icon from '@src/components/Icon/Icon'
import { slackChannelNameToUrl } from './helpers'

type Props = {
  group?: CommunicationGroupInterface | null
}
export const CommunicationIcons = ({ group }: Props) => {
  const hasSlackChannel = !!group?.slack_channel_name
  return (
    <HStack space="s-8">
      {/* TODO: use icon from UIKit when added */}
      <IconButton
        disabled={!hasSlackChannel}
        useIcon={() => (
          <Icon
            type="SlackMono"
            size="tiny"
            color={hasSlackChannel ? Color.BLUE : Color.GREY_20}
          />
        )}
        onClick={e => {
          if (hasSlackChannel && window) {
            e.stopPropagation()
            const tab = window.open(
              slackChannelNameToUrl(group.slack_channel_name),
              '_blank',
            )
            tab?.focus()
          }
        }}
      />
      {/* TODO: uncomment when supported by BE. Make sure to remove check if Slack is enabled, because at the moment this is used in a column and it's hidden on that condition.
      <Envelope color={Color.GREY_20} size={16} />
      <Megaphone color={Color.GREY_20} size={16} /> */}
    </HStack>
  )
}
