import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { DocumentsSession } from './DocumentsSession'
import { DocumentsUploadFiles } from './DocumentsUploadFiles'

export const ImportDocuments = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Bulk upload documents"
        subtitle="Import multiple documents in the system and add the correct information "
        backUrl={ROUTES.APPS.DOCUMENTS.DOCUMENTS}
      />

      <Switch>
        <Route path={ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.UPLOAD_FILE} exact>
          <DocumentsUploadFiles />
        </Route>
        <Route path={ROUTES.FORMS.IMPORT_DATA.DOCUMENTS.SESSION} exact>
          <DocumentsSession />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
