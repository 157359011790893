import { ContactInformationInterface } from '@src/interfaces/bookingLinks'
import { z, SafeParseReturnType } from 'zod'

const INVALID_EMAIL_ERROR = 'Enter a valid email address.'
const INVALID_URL_ERROR = 'Enter a valid url address. (Please include https://)'
const REQUIRED = 'This field may not be blank.'

export const validateEmail = (email: string) => {
  return z
    .string()
    .email({
      message: INVALID_EMAIL_ERROR,
    })
    .safeParse(email)
}

export const validateUrl = (url: string) => {
  return z.string().url({ message: INVALID_URL_ERROR }).safeParse(url)
}

export const validateRequired = (name: string) => {
  return z.string().min(1, { message: REQUIRED }).safeParse(name)
}

export const getError = (validation: SafeParseReturnType<string, string>) => {
  return validation.success ? '' : validation.error.issues[0].message
}

export type ContactInformationErrors = {
  full_name: string
  email: string
  link?: string
  valid: boolean
}

export const validateContactInformation = ({
  full_name,
  email,
  links,
}: ContactInformationInterface): ContactInformationErrors => {
  const errors = {
    full_name: getError(validateRequired(full_name)),
    email: getError(validateEmail(email)),
    link: links?.[0] ? getError(validateUrl(links[0])) : '',
  }
  return {
    ...errors,
    valid: !(errors.full_name || errors.email || errors.link),
  }
}
