import React, { useState } from 'react'
import { VStack, Text, Color } from '@revolut/ui-kit'

import {
  getDepartmentEmpoyeeCompensationReviews,
  useDepartmentCompensationDetails,
} from '@src/api/compensation'
import { BudgetDistributionProvider } from '@src/features/BudgetDistribution/BudgetDistribution'
import { BudgetDistributionTable } from '@src/features/BudgetDistribution/BudgetDistributionTable'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'

export const ViewCustomBudgetPool = () => {
  const [id, setId] = useState<string>()
  const [currencyCode, setCurrencyCode] = useState<string>()

  const details = useDepartmentCompensationDetails(id, currencyCode)

  const optionsApi = (optionsId: string, optionsCurrencyCode: string) => {
    if (id !== optionsId) {
      setId(optionsId)
    }
    if (currencyCode !== optionsCurrencyCode) {
      setCurrencyCode(optionsCurrencyCode)
    }

    return details
  }

  const backUrl = ROUTES.FORMS.COMPANY.COMPENSATION

  return (
    <PageWrapper>
      <PageHeader
        title={details.data?.budget_pool_config?.name}
        subtitle={
          <VStack>
            <Text
              variant="primary"
              color={Color.FOREGROUND}
              display="inline-block"
              py="s-4"
            >
              Custom budget pool
            </Text>
            <Text>Owner: {details.data?.owner?.name}</Text>
          </VStack>
        }
        isLoading={details.isLoading}
        backUrl={backUrl}
      />

      <BudgetDistributionProvider
        api={getDepartmentEmpoyeeCompensationReviews}
        detailsApi={optionsApi}
      >
        <BudgetDistributionTable />
      </BudgetDistributionProvider>
    </PageWrapper>
  )
}
