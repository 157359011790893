import React from 'react'
import { ROUTES } from '@src/constants/routes'
import * as FunctionTalent from '@src/pages/Forms/CommonTalentTab/Talent'
import * as FunctionTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import { Route, Switch, useParams } from 'react-router-dom'
import { TalentType } from '@src/interfaces/talent/talent'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { RoleInterface } from '@src/interfaces/roles'
import { canViewTalentPerformance } from '../../CommonTalentTab/utils'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { DropdownNavigation } from '@src/components/DropdownNavigation/DropdownNavigation'

const Talent = ({ data }: { data: RoleInterface }) => {
  const params = useParams<{ id: string }>()
  const isNewTable = useIsNewTable()
  const subTabs = [
    {
      path: ROUTES.FORMS.ROLE.TALENT.GENERAL,
      to: pathToUrl(ROUTES.FORMS.ROLE.TALENT.GENERAL, params),
      title: 'General',
      component: FunctionTalent.default,
      canView: true,
    },
    {
      path: ROUTES.FORMS.ROLE.TALENT.PERFORMANCE,
      to: pathToUrl(ROUTES.FORMS.ROLE.TALENT.PERFORMANCE, params),
      title: 'Performance',
      component: FunctionTalentPerformance.default,
      canView: canViewTalentPerformance(data.field_options),
    },
  ]

  const filteredTabs = subTabs.filter(tab => tab.canView)

  return (
    <Flex flexDirection="column" minHeight={0}>
      {!isNewTable && filteredTabs.length > 1 && (
        <Flex alignItems="flex-start" mb={isNewTable ? 0 : 's-24'}>
          <TabBarNavigation isSubtab tabs={filteredTabs} />
        </Flex>
      )}
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={data}
              talentType={TalentType.Role}
              navigation={
                isNewTable ? <DropdownNavigation options={filteredTabs} /> : undefined
              }
            />
          </Route>
        ))}
        {filteredTabs?.[0]?.path && <InternalRedirect to={filteredTabs?.[0]?.path} />}
      </Switch>
    </Flex>
  )
}

export default Talent
