import React from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  Item,
  Subheader,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useGetJobPostingSettings } from '@src/api/settings'
import { HUB_INTEGRATIONS_FLAT } from '@src/constants/hub'
import { useFilterIntegrations } from '@src/pages/Settings/SettingsLandingPage/Integrations'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ItemSwitch } from '@src/components/Inputs/ItemSwitch/ItemSwitch'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobBoardIntegration } from '@src/features/JobPostingFlow/types'

export const JobBoards = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings({
    refetchOnWindowFocus: true,
  })
  const filterIntegrations = useFilterIntegrations()
  const integrations = filterIntegrations<JobBoardIntegration>([
    {
      ...HUB_INTEGRATIONS_FLAT.linkedin,
      jobBoardId: 'linkedin',
      jobBoardDescription: 'Publish this job posting to your company Linkedin page',
      settingEnabled: !!jobPostingSettings?.enable_linkedin_integration,
    },
    {
      ...HUB_INTEGRATIONS_FLAT.indeed,
      jobBoardId: 'indeed',
      jobBoardDescription: 'Publish this job posting to your company Indeed page',
      settingEnabled: !!jobPostingSettings?.indeed_connected,
    },
    {
      ...HUB_INTEGRATIONS_FLAT.monsterJobs,
      jobBoardId: 'monster',
      jobBoardDescription: 'Publish this job posting to your company Monster jobs page',
      settingEnabled: !!jobPostingSettings?.enable_monster_integration,
    },
  ])
  const enabled = integrations.filter(jobBoard => jobBoard.settingEnabled)
  const disabled = integrations.filter(jobBoard => !jobBoard.settingEnabled)
  return (
    <VStack gap="s-16">
      {!!enabled.length && (
        <VStack gap="s-16">
          <Box>
            <Subheader>
              <Subheader.Title>Connected job boards</Subheader.Title>
            </Subheader>
            <Text variant="body1" color={Token.color.greyTone50}>
              All new jobs will be posted automatically to the connected job boards.
            </Text>
          </Box>
          <Widget>
            {enabled.map(jobBoard => {
              const checked = values.publish_to_job_boards?.includes(jobBoard.jobBoardId)
              return (
                <ItemSwitch
                  key={jobBoard.id}
                  avatar={
                    typeof jobBoard.imageV2 === 'string' ? (
                      <Avatar image={jobBoard.imageV2} />
                    ) : null
                  }
                  onChange={() => {
                    values.publish_to_job_boards = checked
                      ? values.publish_to_job_boards?.filter(
                          curr => curr !== jobBoard.jobBoardId,
                        )
                      : [...(values.publish_to_job_boards || []), jobBoard.jobBoardId]
                  }}
                  title={jobBoard.title}
                  description={jobBoard.description}
                  checked={checked}
                />
              )
            })}
          </Widget>
        </VStack>
      )}
      {!!disabled.length && (
        <VStack gap="s-16">
          <Box>
            <Subheader>
              <Subheader.Title>Connect to more job boards</Subheader.Title>
            </Subheader>
            <Text variant="body1" color={Token.color.greyTone50}>
              Connect to our integrated job boards to reach more candidates
            </Text>
          </Box>
          <Widget>
            {disabled.map(jobBoard => (
              <Item key={jobBoard.id}>
                <Item.Avatar>
                  {typeof jobBoard.imageV2 === 'string' && (
                    <Avatar image={jobBoard.imageV2} />
                  )}
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{jobBoard.title}</Item.Title>
                  <Item.Description>{jobBoard.description}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <ActionButton
                    target="_blank"
                    to={pathToUrl(jobBoard.url)}
                    use={InternalLink}
                  >
                    Connect
                  </ActionButton>
                </Item.Side>
              </Item>
            ))}
          </Widget>
        </VStack>
      )}
    </VStack>
  )
}
