import React from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewResultToTitle,
} from '@src/interfaces/interviewTool'
import { HStack, Icon, IconName, Text, Token } from '@revolut/ui-kit'

const IconWrapper = styled(HStack)<{
  result?: InterviewResult | null | ''
  status: InterviewFeedbackStatus
  noColor?: boolean
}>`
  display: inline-flex;
  vertical-align: bottom;
  color: ${props => {
    if (props.noColor) {
      return 'inherit'
    }

    if (
      props.status === InterviewFeedbackStatus.pending ||
      props.status === InterviewFeedbackStatus.draft
    ) {
      return Token.color.orange
    }
    if (props.status !== InterviewFeedbackStatus.completed) {
      return Token.color.greyTone20
    }
    if (
      props.result === InterviewResult.NoHire ||
      props.result === InterviewResult.StrongNoHire
    ) {
      return Token.color.danger
    }
    if (
      props.result === InterviewResult.Hire ||
      props.result === InterviewResult.StrongHire
    ) {
      return Token.color.green
    }
    return Token.color.greyTone20
  }};
`

interface Props {
  status: InterviewFeedbackStatus
  result?: InterviewResult | null | ''
  showTooltip?: boolean
  showLabel?: boolean
  noColor?: boolean
  iconSize?: number
}

interface IconDataInterface {
  icon: IconName
  text: string
}

const getIconData = (
  status: InterviewFeedbackStatus,
  result?: InterviewResult | null | '',
): IconDataInterface => {
  if (status === InterviewFeedbackStatus.completed) {
    switch (result) {
      case InterviewResult.Hire:
        return {
          icon: 'SocialLike',
          text: InterviewResultToTitle[InterviewResult.Hire],
        }
      case InterviewResult.NoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.NoHire],
        }
      case InterviewResult.StrongHire:
        return {
          icon: 'DoubleThumbs',
          text: InterviewResultToTitle[InterviewResult.StrongHire],
        }
      case InterviewResult.StrongNoHire:
        return {
          icon: 'SocialDislike',
          text: InterviewResultToTitle[InterviewResult.StrongNoHire],
        }
    }
  }

  if (status === InterviewFeedbackStatus.rejected) {
    return {
      icon: 'CrossCircle',
      text: 'Rejected',
    }
  }

  return {
    icon: '16/SandWatch',
    text: 'Pending',
  }
}

export const InterviewResultLabel = ({
  status,
  result,
  showTooltip,
  showLabel,
  noColor,
  iconSize = 20,
}: Props) => {
  const data = getIconData(status, result)

  return (
    <IconWrapper result={result} status={status} noColor={noColor} gap="s-4">
      <Tooltip placement="top" text={data.text} hide={!showTooltip}>
        <Icon name={data.icon} size={iconSize} />
      </Tooltip>
      {showLabel && (
        <Text ml="s-4" whiteSpace="nowrap">
          {data.text}
        </Text>
      )}

      {result && <Text>{InterviewResultToTitle[result]}</Text>}
    </IconWrapper>
  )
}
