import React from 'react'
import { connect } from 'lape'
import { Route, Switch } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { employeePolicyAssignmentRequest } from '@src/api/timeOff'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'

import { Preview } from './Preview'
import { General } from './General'

const EmployeeTimeOffPolicyAssignment = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW}>
          <Preview />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL}>
          <General />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeePolicyAssignmentRequest}>
    <EmployeeTimeOffPolicyAssignment />
  </Form>
))
