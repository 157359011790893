import React from 'react'
import { ActionMenu, Icon, IconButton, Layout, useDropdown } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { MainHeaderButton } from './MainHeaderButton'
import { ROUTES } from '@src/constants/routes'
import { useGetBanners } from '@src/api/banners'
import { mapSeverityToColor } from '@src/features/Banners/SystemStatusBanner'
import isEmpty from 'lodash/isEmpty'
import { useCoreNavigation } from '../MainLayout/useCoreNavigation'

export const Warning = () => {
  const { data, isLoading } = useGetBanners()
  const dropdown = useDropdown()
  const isCoreNavigation = useCoreNavigation()

  if (isLoading || !data || isEmpty(data)) {
    return null
  }

  return isCoreNavigation ? (
    <Layout.SideActions>
      <IconButton
        useIcon={() => (
          <Icon name="16/Warning" color={mapSeverityToColor(data.severity)} />
        )}
        aria-label="Warning"
        variant="default"
        {...dropdown.getAnchorProps()}
      />
      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="16/Warning"
            color={mapSeverityToColor(data.severity)}
            onClick={() => navigateTo(ROUTES.MAIN)}
          >
            <ActionMenu.Title>{data.title}</ActionMenu.Title>
            <ActionMenu.Description>{data.message}</ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </Layout.SideActions>
  ) : (
    <>
      <MainHeaderButton>
        <IconButton
          useIcon="16/Warning"
          aria-label="Warning"
          size={20}
          color={mapSeverityToColor(data.severity)}
          {...dropdown.getAnchorProps()}
        />
      </MainHeaderButton>
      <ActionMenu {...dropdown.getTargetProps()}>
        <ActionMenu.Group>
          <ActionMenu.Item
            useIcon="16/Warning"
            color={mapSeverityToColor(data.severity)}
            onClick={() => navigateTo(ROUTES.MAIN)}
          >
            <ActionMenu.Title>{data.title}</ActionMenu.Title>
            <ActionMenu.Description>{data.message}</ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}
