import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ErrorWidget, VStack } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklistV2'

import { checkIfFirstStepsCompleted } from '../common/helpers'
import { onboardingCheckpointsSectionsConfig } from '../common/checkpointsConfig'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { ChecklistSection } from './ChecklistSection'
import { ProgressBanner } from './ProgressBanner'

const Header = () => (
  <OnboardingChecklistHeader
    title={
      <FormattedMessage
        id="onboarding.general.onboardingChecklistTitle"
        defaultMessage="Onboarding checklist"
      />
    }
    description="Complete the configuration steps below to unlock the platform's full potential. Too many steps? Ask your team to help you. Start now!"
    backUrl={ROUTES.MAIN}
  />
)

export const LandingPage = () => {
  useSetDocumentTitle('Onboarding')

  const { checkpoints, isLoading, isError } = useGetOnboardingCheckpoints()
  const firstStepsCompleted = checkIfFirstStepsCompleted(checkpoints)

  if (isLoading) {
    return <PageLoading />
  }
  if (isError) {
    return (
      <PageWrapper>
        <Header />
        <PageBody>
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title />
            <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
              Back to main page
            </ErrorWidget.Action>
          </ErrorWidget>
        </PageBody>
      </PageWrapper>
    )
  }
  return (
    <>
      <PageWrapper>
        <Header />
        <PageBody>
          <VStack space="s-16">
            <ProgressBanner />
            {firstStepsCompleted ? (
              <>
                <ChecklistSection
                  title={onboardingCheckpointsSectionsConfig.performance.title}
                  configs={onboardingCheckpointsSectionsConfig.performance.configs}
                />
                <ChecklistSection
                  title={onboardingCheckpointsSectionsConfig.recruitment.title}
                  configs={onboardingCheckpointsSectionsConfig.recruitment.configs}
                />
                <ChecklistSection
                  title={onboardingCheckpointsSectionsConfig.hr.title}
                  configs={onboardingCheckpointsSectionsConfig.hr.configs}
                />
                <ChecklistSection
                  title={onboardingCheckpointsSectionsConfig.launch.title}
                  configs={onboardingCheckpointsSectionsConfig.launch.configs}
                />
              </>
            ) : (
              <ChecklistSection
                canPostpone={false}
                title={onboardingCheckpointsSectionsConfig.firstSteps.title}
                configs={onboardingCheckpointsSectionsConfig.firstSteps.configs}
              />
            )}
          </VStack>
        </PageBody>
      </PageWrapper>
    </>
  )
}
