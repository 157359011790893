import { Box, Flex, Link, Text, VStack, Widget } from '@revolut/ui-kit'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { PERFORMANCE_SERVICE_DESK } from '@src/constants/externalLinks'
import React, { useState } from 'react'
import { ChevronDown, ChevronUp } from '@revolut/icons'

const FAQ = [
  {
    title: 'What is a KPI?',
    content: (
      <VStack space="s-24">
        <Text>
          A <Text fontWeight={500}>Key Performance Indicator</Text> (KPI) is a metric used
          to monitor performance. A KPI’s value is set to measure and quantify progress
          towards a target over time.
        </Text>
        <Text>
          KPIs allow us to clearly and objectively see how our contributions impact the
          big picture. They help us establish clear links between our efforts at
          Individual, Team, and Department levels and the overall progress of the company.
        </Text>
        <Text>
          We want to:
          <ul style={{ paddingInlineStart: 16, margin: 0 }}>
            <li>Prioritise important initiatives across Departments</li>
            <li>Hold ourselves accountable to our commitments and targets</li>
            <li>Plan the necessary resources required to achieve these targets</li>
          </ul>
        </Text>
      </VStack>
    ),
  },
  {
    title: 'When and who should create KPIs?',
    content: (
      <Text>
        KPIs are set for the start of each review cycle, at each level of the organisation
        (Company, Department, Team, and Employee). The owner of each entity is responsible
        for creating their KPIs during the communicated setting period.
      </Text>
    ),
  },
  {
    title: 'When are KPIs updated?',
    content: (
      <Text>
        KPIs are updated once per day, overnight (UK time). During this time, all the
        queries or roadmaps used inside KPIs will be evaluated for the current value.
      </Text>
    ),
  },
  {
    title: 'How are KPIs finalised for cycle end?',
    content: (
      <VStack space="s-24">
        <Text>
          On the last day of the cycle, all KPIs will receive a final update prior to
          being frozen upon end of day. After the cycle has ended, updated results of
          queries or roadmaps will not be reflected in the progress.
        </Text>
        <Text>
          The system instead will only process updates for targets within the new cycle.
        </Text>
        <HideIfCommercial>
          <Text>
            If you believe the end of quarter values have been captured incorrectly, your
            KPI champions can assist you with raising a ticket on the{' '}
            <Link href={PERFORMANCE_SERVICE_DESK} target="_blank">
              Performance Service Desk.
            </Link>
          </Text>
        </HideIfCommercial>
      </VStack>
    ),
  },
  {
    title: 'What is calibrated performance?',
    content: (
      <Text>
        After KPIs are finalised for each cycle, their results are confirmed with the head
        of department and KPI committee to ensure consistency and accuracy. If the
        progress is altered at all, the calibrated performance will reflect the updated
        result.
      </Text>
    ),
  },
  {
    title: 'I can’t edit my KPI',
    content: (
      <Text>
        KPIs should only be changed during the setting period ahead of the cycle. We are
        accountable for delivering on our agreed targets, and so changes can only be made
        when clear and concise rationale is provide. Your KPI champions can assist you
        with this.
      </Text>
    ),
  },
  {
    title: 'I want to update my progress now',
    content: (
      <Text>
        You can trigger a refresh of only your KPIs by using the link below the table.
        This will happen in the background and may take a few minutes to complete. Each
        KPI can be refreshed individually by clicking into the KPI and using the action
        button.
      </Text>
    ),
  },
  {
    title: 'I think my KPI progress is incorrect',
    content: (
      <VStack space="s-24">
        <Text>
          If your progress percentage looks incorrect, please check that the query or
          roadmaps within are returning the correct{' '}
          <Text fontWeight={500}>current value.</Text>
          <ul style={{ paddingInlineStart: 16, margin: 0 }}>
            <li>
              Note that for SQL KPIs, if there is an error in the query then this could
              result in the progress being incorrect.
            </li>
            <li>
              For roadmap KPIs, if you have moved Jira items between projects that are
              being tracked, they may need to be removed from the KPI first and then
              re-added.
            </li>
            <li>
              If your KPI has been cascaded from a higher level or template, then there
              could be an error in the parent KPI.
            </li>
          </ul>
        </Text>
        <Text>
          Your KPI champions can assist you with this. You can also trigger a refresh of
          only your KPIs by using the link below the table.
        </Text>
      </VStack>
    ),
  },
  {
    title: 'The overall progress doesn’t add up',
    content: (
      <Text>
        The overall KPI progress is determined by the{' '}
        <Text fontWeight={500}>weighted average</Text> of progress for approved KPIs. If
        the overall progress looks incorrect, please check that the weights are set
        correctly, and that all your KPI targets are approved. Pending KPIs will not
        contribute to your overall progress. Mandatory KPIs must be completed by the end
        of cycle, otherwise overall progress will be set to zero.
      </Text>
    ),
  },
]

const KpiHelpButtonFaqItem = ({
  item,
}: {
  item: { title: string; content: JSX.Element }
}) => {
  const [itemOpened, setItemOpened] = useState(false)
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Text variant="h6">{item.title}</Text>
        {itemOpened ? (
          <ChevronUp
            size={15}
            color="grey-tone-50"
            onClick={() => setItemOpened(false)}
            cursor="pointer"
          />
        ) : (
          <ChevronDown
            size={15}
            color="grey-tone-50"
            onClick={() => setItemOpened(true)}
            cursor="pointer"
          />
        )}
      </Flex>
      {itemOpened && (
        <Box mt="s-12" color="grey-tone-50">
          {item.content}
        </Box>
      )}
    </Box>
  )
}

export const KpiHelpButtonFaq = () => {
  return (
    <Widget px="s-16" py="s-12" mt="s-16">
      <VStack space="s-24">
        {FAQ.map((item, ind) => {
          return <KpiHelpButtonFaqItem item={item} key={ind} />
        })}
      </VStack>
    </Widget>
  )
}
