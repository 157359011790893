import React from 'react'
import { copyToClipboard, Flex } from '@revolut/ui-kit'

import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { getEmployeeEmailTemplatesPlaceholders } from '@src/api/employeeEmails'
import { EmployeeEmailPlaceholderInterface } from '@src/interfaces/employeeEmails'
import {
  employeeEmailPlaceholderNameColumn,
  employeeEmailPlaceholderTypeColumn,
} from '@src/constants/columns/employeeEmailPlaceholders'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { TableNames } from '@src/constants/table'

const Row: RowInterface<EmployeeEmailPlaceholderInterface> = {
  cells: [
    {
      ...employeeEmailPlaceholderNameColumn,
      width: 300,
    },
    {
      ...employeeEmailPlaceholderTypeColumn,
      width: 300,
    },
  ],
}

const PlaceholdersTable = () => {
  const table = useTable<EmployeeEmailPlaceholderInterface>({
    getItems: getEmployeeEmailTemplatesPlaceholders,
  })

  const onRowClick = async (row: EmployeeEmailPlaceholderInterface) => {
    await copyToClipboard(row.name)

    pushNotification({
      value: 'Placeholder copied to clipboard',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  }

  return (
    <Flex flexDirection="column" width="100%">
      <AdjustableTable<EmployeeEmailPlaceholderInterface>
        name={TableNames.EmployeeEmailPlaceholders}
        useWindowScroll
        row={Row}
        {...table}
        onRowClick={onRowClick}
        noDataMessage="Placeholders will appear here."
        hideCount
      />
    </Flex>
  )
}

export default PlaceholdersTable
