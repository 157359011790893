import { GetRequestInterface } from '../interfaces'
import { api } from './index'
import { API } from '../constants/api'
import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DeliverableRefreshInterface,
  DeliverablesAveragesInterface,
  DeliverableScorecardInterface,
  DeliverableSettingsInterface,
  DeliverableStatsInterface,
  FunctionalDeliverableInterface,
  FunctionalDeliverableScorecardInterface,
} from '@src/interfaces/deliverables'
import { FetchDataQueryInterface } from '@src/interfaces/data'

export const getJiraTickets = (
  id: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<FunctionalDeliverableInterface>> =>
  api.get(
    `${API.EMPLOYEES}/${id}/performance/functional/tickets`,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v2',
  )

export const postScorecard = (
  employeeId: number,
  scorecard: {},
  category?: string,
): AxiosPromise<
  DeliverableScorecardInterface | FunctionalDeliverableScorecardInterface
> =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/scorecards`,
    scorecard,
    { params: { category } },
    'v2',
  )

export const changeScorecard = (
  employeeId: number,
  id: number,
  scorecard: {},
): AxiosPromise<
  DeliverableScorecardInterface | FunctionalDeliverableScorecardInterface
> =>
  api.patch(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/scorecards/${id}`,
    scorecard,
    {},
    'v2',
  )

export const getDeliverablesSettings = (
  employeeId: number,
): AxiosPromise<DeliverableSettingsInterface> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/tickets/deliverableReviewSettings`,
    undefined,
    'v2',
  )

export const getDeliverablesStats = (
  employeeId: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<DeliverableStatsInterface> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/tickets/stats`,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v2',
  )

export const refreshDeliverablesTickets = (
  employeeId: number,
  cycleIds: number[],
  isProbation?: boolean,
): AxiosPromise<DeliverableRefreshInterface> =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/tickets/refreshJiraIssues`,
    {
      cycle_offset_ids: cycleIds,
      probation: isProbation,
    },
    {},
    'v2',
  )

export const getDeliverablesAverageRatings = (
  employeeId: number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<DeliverablesAveragesInterface> =>
  api.get(
    `${API.EMPLOYEES}/${employeeId}/performance/functional/scorecards/averageRatings`,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v2',
  )
