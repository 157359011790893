import React from 'react'
import { Box, BoxProps, Flex, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { CareersLocationsVariant, CareersPositionLocation } from '@src/interfaces/careers'

import CareersPositionLocations from '@src/pages/Careers/CareersPosition/CareersPositionLocations'
import { Bank, Laptop } from '@revolut/icons'
import { LocationType } from '@src/interfaces/locations'

type CareersPositionWidgetLocationsRowProps = {
  locations: CareersPositionLocation[]
  type: LocationType
  variant?: CareersLocationsVariant
}

const Locations = styled(CareersPositionLocations)<{
  componentVariant?: CareersLocationsVariant
}>(({ componentVariant }) =>
  css({
    textAlign: componentVariant === CareersLocationsVariant.Default ? 'center' : 'left',
    ...(componentVariant === CareersLocationsVariant.Small && {
      fontSize: '14px',
      lineHeight: '20px',
    }),
  }),
)

interface LocationIconProps extends BoxProps {
  type: LocationType
  size?: number
}

const LocationIcon = ({ type, size = 16, ...props }: LocationIconProps) => {
  const commonProps = {
    display: 'inline-block',
    mr: 's-8',
    ...props,
  }

  if (type === 'remote') {
    return (
      <Box {...commonProps}>
        <Laptop size={size} color={Token.color.greyTone20} />
      </Box>
    )
  }

  return (
    <Box {...commonProps}>
      <Bank size={size} color={Token.color.greyTone20} />
    </Box>
  )
}

const CareersPositionLocationsRow = ({
  locations,
  type,
  variant,
}: CareersPositionWidgetLocationsRowProps) => {
  const fontVariant =
    variant === CareersLocationsVariant.Default ? 'caption' : 'secondary'
  return (
    <Flex
      justifyContent={variant === CareersLocationsVariant.Default ? 'center' : 'left'}
      pb={variant === CareersLocationsVariant.Default ? 's-8' : 0}
      alignItems="center"
      data-testid="location-row"
    >
      <Locations
        locations={locations.map(({ name }) => name.replace(/- remote/gi, ''))}
        componentVariant={variant}
        variant={fontVariant}
      >
        {variant === CareersLocationsVariant.Default ? (
          <LocationIcon type={type} size={24} mt="-s-2" />
        ) : (
          <LocationIcon type={type} mt="s-2" />
        )}

        {type === 'office' ? 'Office' : 'Remote'}
        {': '}
      </Locations>
    </Flex>
  )
}

export default CareersPositionLocationsRow
