import React, { useState } from 'react'
import { MoreBar, Popup, Subheader } from '@revolut/ui-kit'
import { PreviewBody } from '@src/pages/Forms/InterviewScorecardTemplate/Preview/Preview'
import ScorecardForm, {
  ScorecardFormWrapper,
} from '@src/pages/OnboardingChecklist/HiringProcess/components/ScorecardForm'

type PreviewScorecardProps = {
  id: number
  name: string
}

const PreviewScorecard = ({ id, name }: PreviewScorecardProps) => {
  const [editScorecard, setEditScorecard] = useState(false)
  const closeEditScorecard = () => {
    setEditScorecard(false)
  }
  return (
    <>
      <Subheader>
        <Subheader.Title>{name}</Subheader.Title>
      </Subheader>
      <ScorecardFormWrapper id={id}>
        <PreviewBody
          id={id}
          hideCopyButton
          editAction={
            <MoreBar.Action
              useIcon="Pencil"
              onClick={() => {
                setEditScorecard(true)
              }}
            >
              Edit
            </MoreBar.Action>
          }
        />
      </ScorecardFormWrapper>
      {editScorecard && (
        <Popup size="lg" open onClose={closeEditScorecard}>
          <ScorecardForm id={id} onSubmit={closeEditScorecard} />
        </Popup>
      )}
    </>
  )
}

export default PreviewScorecard
