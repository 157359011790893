import React from 'react'
import NotesForm, { Props } from './General'
import Form from '@src/features/Form/Form'
import { contractorsRequests, employeesRequestsNew } from '@src/api/employees'

const Notes = (props: Omit<Props, 'type'>) => {
  const type = props.data.employee_type

  return type === 'external' ? (
    <Form api={contractorsRequests}>
      <NotesForm {...props} type={type} />
    </Form>
  ) : (
    <Form api={employeesRequestsNew}>
      <NotesForm {...props} type={type} />
    </Form>
  )
}

export default Notes
