import React from 'react'
import { Widget, Banner, Avatar, ExpandableText, Flex } from '@revolut/ui-kit'

export const ViewBanner = () => {
  return (
    <Widget display="flex" p="s-16">
      <Flex gap="s-16" width="100%">
        <Avatar
          variant="brand"
          image="https://assets.revolut.com/assets/categories/Notepad.png"
          imageSet={{
            '2x': 'https://assets.revolut.com/assets/categories/Notepad@2x.png',
            '3x': 'https://assets.revolut.com/assets/categories/Notepad@3x.png',
          }}
          size={56}
        />
        <Banner.Content>
          <Banner.Title mb="s-4">The probation process</Banner.Title>
          <Banner.Description>
            <ExpandableText labelShowLess="View less" labelShowMore="View more">
              The probation process plays a crucial role in the performance lifecycle. It
              is the first opportunity for both the organisation and a new employee to
              evaluate their suitability for the position and their alignment with the
              company’s values. This is achieved by carefully assessing deliverables,
              skills, and cultural fit, throughout a series of checkpoints designed to
              give every employee the best opportunity to succeed.
            </ExpandableText>
          </Banner.Description>
        </Banner.Content>
      </Flex>
    </Widget>
  )
}
