import React from 'react'
import { employeeBioRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import BioForm, { Props } from './General'

const Bio = (props: Props) => {
  return (
    <Form api={employeeBioRequests}>
      <BioForm {...props} />
    </Form>
  )
}

export default Bio
