import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { Stats } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { canAddDepartmentKpi, selectUser } from '@src/store/auth/selectors'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { CompanyInterface } from '@src/interfaces/company'
import { kpisRequests } from '@src/api/kpis'
import { CompanyKPIRow } from '@src/pages/Forms/Company/KPI/common'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { Flex, Text, TableWidget, MoreBar } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { PermissionTypes } from '@src/store/auth/types'

interface StructureProps {
  data: CompanyInterface
}

const Department = ({ data }: StructureProps) => {
  const initialFilter = [
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
      ],
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [{ name: 'Department', id: 'department_kpi' }],
      columnName: 'kpi_type',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      filters: [{ name: 'true', id: 'true' }],
      columnName: 'include_unlinked',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const companyTable = useTable<KpiInterface, Stats>(kpisRequests, initialFilter)

  const canAdd = useSelector(canAddDepartmentKpi)
  const user = useSelector(selectUser)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        department: { name: data.name, id: data.id },
      }),
    })
  }

  return (
    <TableWidget>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Progress"
            val={
              companyTable?.stats?.avg_progress !== undefined ? (
                <Text
                  use="div"
                  mr="5px"
                  color={getPercentColor(companyTable.stats.avg_progress * 100)}
                >
                  {formatPercentage(companyTable.stats.avg_progress)}
                </Text>
              ) : undefined
            }
            mr="s-32"
          />
          <CycleFilter
            onFilterChange={companyTable.onFilterChange}
            columnName="review_cycle__offset"
            filter={companyTable.filterBy}
            type={CycleFilterType.NewUI}
          />
        </Flex>
        <Flex mb="s-16">
          <MoreBar>
            {canAdd && (
              <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
                Add New KPI
              </MoreBar.Action>
            )}
            <SettingsButton
              path={ROUTES.SETTINGS.KPI}
              canView={[
                PermissionTypes.ViewPerformancePreferences,
                PermissionTypes.ChangePerformancePreferences,
              ]}
            />
          </MoreBar>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<KpiInterface>
            name={TableNames.CompanyKPIDepartment}
            useWindowScroll
            dataType="KPI"
            row={CompanyKPIRow(data.name)}
            onRowClick={onKPITableRowClick}
            noDataMessage="Please add department KPIs to see them here."
            {...companyTable}
            useFetchedChildren
          />
        </Flex>
      </Flex>
    </TableWidget>
  )
}

export default Department
