import React, { useEffect, useState } from 'react'
import {
  Text,
  chain,
  MoreBar,
  ActionButton,
  Item,
  TextButton,
  Avatar,
  ItemSkeleton,
  InputGroup,
  HStack,
  Token,
  VStack,
  Icon,
} from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonSuccessionPlanInterface,
} from '@src/interfaces/keyPerson'
import { getSuccessionPlanStatusColor } from '@src/apps/People/KeyPersons/helpers'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { SuccessorsTable } from '@src/apps/People/KeyPersons/SuccessionPlan/SuccessorsTable'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@src/components/Page/PageActions'
import { pathToUrl } from '@src/utils/router'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import {
  deleteSuccessionPlanFile,
  getEmployeeKeyPersonResponsibilitiesAssignments,
  useSuccessionPlanFiles,
} from '@src/api/keyPerson'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ActiveSuccessorsNumBanner } from './ActiveSuccessorsNumBanner'

type Props = {
  setEmployeeId: (id: number) => void
  showResponsibilitiesSidebar: () => void
}
export const EditSuccessionPlan = ({
  setEmployeeId,
  showResponsibilitiesSidebar,
}: Props) => {
  const { values, isSubmitting } = useLapeContext<KeyPersonSuccessionPlanInterface>()
  const { query, deleteQueryParam } = useQuery()

  const [showUpload, setShowUpload] = useState(!values.id || !values.file_name)
  const [pendingDeleteId, setPendingDeleteId] = useState<number>()
  const [selectedResponsibilityIdx, setSelectedResponsibilityIdx] = useState<number>()

  const [responsibilities, setResponsibilities] = useState<
    KeyPersonResponsibilityAssignmentInterface[]
  >([])

  useEffect(() => {
    if (values.employee.id) {
      getEmployeeKeyPersonResponsibilitiesAssignments(values.employee.id).then(
        ({ data }) => {
          setResponsibilities(data)
        },
      )
    }
  }, [values.employee.id])

  const activeResponsibilities = responsibilities
    .filter(({ assignment_status }) => assignment_status?.id !== 'archived')
    .map(val => ({
      value: {
        id: val.id,
        name: val.key_person_type.name,
      },
      label: val.key_person_type.name,
    }))

  useEffect(() => {
    if (activeResponsibilities.length) {
      values.key_risk_profile_succession_plan_config = activeResponsibilities.map(
        responsibility => {
          const successionConfig = values.key_risk_profile_succession_plan_config.find(
            item => item.key_risk_profile.id === responsibility.value.id,
          )

          return (
            successionConfig || {
              key_risk_profile_succession_plan: {
                id: values.id,
              },
              key_risk_profile: {
                id: responsibility.value.id,
              },
              successors: [],
              successors_order: [],
              succession_strategy: null,
            }
          )
        },
      )
    }
  }, [activeResponsibilities.length])

  useEffect(() => {
    if (activeResponsibilities.length && query.selectedResponsibilityIdx) {
      setSelectedResponsibilityIdx(Number(query.selectedResponsibilityIdx))
      deleteQueryParam('selectedResponsibilityIdx')
    }
  }, [activeResponsibilities, query.selectedResponsibilityIdx])

  useEffect(() => {
    setShowUpload(!values.id || !values.file_name)
  }, [isSubmitting, values.file_name])

  useEffect(() => {
    if (values.employee.id) {
      setEmployeeId(values.employee.id)
    }
  }, [values.employee.id])

  const { data: files, refetch } = useSuccessionPlanFiles(values.id)

  const successionPlanConfig = values.key_risk_profile_succession_plan_config.find(
    item => item.key_risk_profile.id === selectedResponsibilityIdx,
  )

  return (
    <>
      <PageHeader
        title={chain(
          'Edit succession planning',
          values.status?.name ? (
            <Text color={getSuccessionPlanStatusColor(values.status?.id)}>
              {values.status.name}
            </Text>
          ) : undefined,
        )}
        subtitle={
          values.employee ? (
            <EmployeeUserWithAvatar
              id={values.employee.id}
              full_name={values.employee!.full_name}
            />
          ) : undefined
        }
        backUrl={pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, {
          id: String(values.id),
        })}
      />
      <PageBody>
        <MoreBar>
          <MoreBar.Action useIcon="EyeShow" onClick={showResponsibilitiesSidebar}>
            Show responsibilities
          </MoreBar.Action>
        </MoreBar>
        <AutoStepper>
          <NewStepperTitle title="Succession Plan & Handover Document" />
          <InputGroup>
            {!isSubmitting &&
              files?.results.map(fileData => (
                <Item key={fileData.id}>
                  <Item.Avatar>
                    <Avatar>
                      <Icon name="Document" />
                    </Avatar>
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>{fileData.file.name}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <HStack gap="s-6">
                      <ActionButton
                        useIcon="Download"
                        aria-label="Download file"
                        onClick={() => {
                          window.open(`${fileData.file.url}`, '_blank')?.focus()
                        }}
                      />
                      <ActionButton
                        useIcon="Delete"
                        pending={pendingDeleteId === fileData.id}
                        onClick={async () => {
                          setPendingDeleteId(fileData.id)

                          await deleteSuccessionPlanFile(values.id, fileData.id)
                          refetch()

                          setPendingDeleteId(undefined)
                        }}
                      >
                        Delete
                      </ActionButton>
                    </HStack>
                  </Item.Side>
                </Item>
              ))}
            {!showUpload && isSubmitting && <ItemSkeleton />}
            {!isSubmitting && (showUpload || files?.results?.length === 0) && (
              <LapeFileUploader name="succession_plan_attachment" />
            )}
            {!isSubmitting && values.id && !(showUpload || files?.results?.length === 0) && (
              <TextButton mb="s-12" pl="s-8" onClick={() => setShowUpload(true)}>
                Upload another file
              </TextButton>
            )}
            {!isSubmitting && values.id && showUpload && (
              <Text mb="s-12" pl="s-8" color={Token.color.greyTone50}>
                File will being uploading after you save your changes
              </Text>
            )}
            <LapeNewTextArea name="notes" label="Notes" rows={3} />
          </InputGroup>

          <NewStepperTitle title="Successors" />
          <Text use="div" mb="s-24" color={Token.color.greyTone50}>
            You can nominate employees as successors and adjust their rank by changing the
            order in the table
          </Text>
          <VStack gap="s-16">
            <RadioSelectInput
              label="Select responsibility to edit"
              options={activeResponsibilities}
              onChange={val => {
                setSelectedResponsibilityIdx(val?.id)
              }}
              value={
                selectedResponsibilityIdx !== undefined
                  ? activeResponsibilities.find(
                      responsibility =>
                        responsibility.value.id === selectedResponsibilityIdx,
                    )?.value
                  : null
              }
            />
            <ActiveSuccessorsNumBanner successionPlanConfig={successionPlanConfig} />
            {successionPlanConfig && (
              <SuccessorsTable selectedResponsibility={successionPlanConfig} />
            )}
          </VStack>
        </AutoStepper>
        <PageActions>
          <NewSaveButtonWithPopup
            mt="s-48"
            previewUrl={ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS}
          />
        </PageActions>
      </PageBody>
    </>
  )
}
