import React, { useCallback, useContext, useMemo, useState } from 'react'
import { CareersSectionsItem } from '@src/interfaces/careers'

export const CustomSectionsContext = React.createContext<{
  sections: CareersSectionsItem[]
  onChange: (updatedSections?: CareersSectionsItem[]) => void
}>({ sections: [], onChange: () => {} })

export const useCustomSectionsContext = () => useContext(CustomSectionsContext)

export const CustomSectionsContextProvider: React.FC = ({ children }) => {
  const [sections, setSections] = useState<CareersSectionsItem[]>([])

  const onChange = useCallback(
    (updatedSections?: CareersSectionsItem[]) => {
      setSections(updatedSections ? [...updatedSections] : [])
    },
    [setSections],
  )

  const value = useMemo(
    () => ({
      sections,
      onChange,
    }),
    [sections, onChange],
  )

  return (
    <CustomSectionsContext.Provider value={value}>
      {children}
    </CustomSectionsContext.Provider>
  )
}
