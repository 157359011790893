import { API } from '@src/constants/api'
import { RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import {
  FeedbackCategory,
  AnytimeFeedbackInterface,
  AnytimeFeedbackLabel,
  AnytimeFeedbackListInterface,
} from '@src/interfaces/anytimeFeedback'
import { useFetch } from '@src/utils/reactQuery'
import { AxiosPromise } from 'axios'

export const getAnytimeFeedbackRequests: (
  category: FeedbackCategory,
) => RequestInterfaceNew<AnytimeFeedbackInterface> = category => ({
  get: async ({ userId, id }) => api.get(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`),
  update: async (data, { userId, id }) => {
    return apiWithoutHandling.put(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`, data)
  },
  submit: async (data, { userId }) => {
    return apiWithoutHandling.post(`${API.EMPLOYEE_FEEDBACK}/${userId}`, {
      ...data,
      category,
    })
  },
})

export const deleteAnytimeFeedback = (
  userId: number,
  id: number,
): AxiosPromise<AnytimeFeedbackInterface> =>
  api.delete(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`)

export const updateAnytimeFeedback = (
  userId: number,
  id: number,
  data: AnytimeFeedbackInterface,
): AxiosPromise<AnytimeFeedbackInterface> => {
  return api.put(`${API.EMPLOYEE_FEEDBACK}/${userId}/${id}`, data)
}

export const useGetAllFeedbackList = (userId: number, nominationId?: number | string) =>
  useFetch<AnytimeFeedbackListInterface>(
    !userId ? null : `${API.EMPLOYEE_FEEDBACK}/${userId}`,
    undefined,
    {
      params: {
        nomination_id: nominationId,
      },
    },
  )

export const useGetAnytimeFeedbackList = (userId: number) =>
  useFetch<AnytimeFeedbackListInterface>(
    !userId ? null : `${API.EMPLOYEE_FEEDBACK}/${userId}`,
    'v1',
    {
      params: { category: FeedbackCategory.Anytime },
    },
  )

export const getAnytimeFeedbackLabels = (
  userId: string,
): AxiosPromise<AnytimeFeedbackLabel[]> =>
  api.get(`${API.EMPLOYEE_FEEDBACK}/${userId}/labels`)
