import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  AccessManagementInterface,
  AssignGroupAccessManagementInterface,
} from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import Table from '@components/TableV2/Table'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'

export const userNameColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'user.id',
  dataPoint: 'user.email',
  sortKey: null,
  filterKey: 'user__id',
  title: 'User',
  selectorsKey: selectorKeys.active_users,
  insert: ({ data }) =>
    data.user?.user_type === 'employee' ? (
      <Table.EmployeeCell employee={data.user?.employee} />
    ) : (
      data.user?.email || '-'
    ),
}

export const groupNameColumn: ColumnInterface<AssignGroupAccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'group.id',
  dataPoint: 'group.name',
  sortKey: 'group__name',
  filterKey: 'group__id',
  title: 'Group',
  selectorsKey: selectorKeys.groups,
}

export const assignedByColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'assigned_by',
  dataPoint: 'assigned_by',
  sortKey: null,
  filterKey: 'assigned_by__id',
  selectorsKey: selectorKeys.active_users,
  title: 'Assigned by',
  insert: ({ data }) =>
    data.assigned_by?.user_type === 'employee' ? (
      <UserWithAvatar {...data.assigned_by?.employee} />
    ) : (
      data.assigned_by?.email || '-'
    ),
}

export const validFromColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date_time',
  dataPoint: 'start_date_time',
  sortKey: null,
  filterKey: 'start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Valid from',
}

export const validUntilColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.date,
  idPoint: 'end_date_time',
  dataPoint: 'end_date_time',
  sortKey: null,
  filterKey: 'end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Valid until',
}

const getStatusVariant = (status: Statuses): ColorTagVariant => {
  switch (status) {
    case Statuses.active:
      return 'success'
    case Statuses.pending:
      return 'warning'
    case Statuses.rejected:
      return 'danger'
    default:
      return 'neutral'
  }
}

export const statusColumn: ColumnInterface<AssignGroupAccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) =>
    data.status?.name ? (
      <Table.StatusCell
        status={data.status.name}
        variant={getStatusVariant(data.status.id)}
      />
    ) : (
      '-'
    ),
}

export const notesColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'reason',
  dataPoint: 'reason',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notes',
}

export const actionsColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}
