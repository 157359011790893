import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Group, Flex, Box } from '@revolut/ui-kit'
import { getIconImage } from '@src/pages/Hub/utils'
import { APPLICATIONS } from '@src/constants/hub'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CompanyPreferencesInterface,
  PerformanceAppsToSetup,
} from '@src/interfaces/settings'

interface App {
  id: PerformanceAppsToSetup
  title: string
  image: string
  description: string
  isRecommended: boolean
}

const apps: App[] = [
  {
    id: 'performance',
    title: APPLICATIONS.performanceReview.title,
    image: APPLICATIONS.performanceReview.image,
    description: 'I want to set up my Performance Review process',
    isRecommended: true,
  },
  {
    id: 'skills',
    title: APPLICATIONS.skills.title,
    image: APPLICATIONS.skills.image,
    description: 'I want to configure functional skills and cultural values',
    isRecommended: true,
  },
  {
    id: 'goals',
    title: 'Goals & KPIs',
    image: APPLICATIONS.kpis.image,
    description: 'I want to create and manage goals within my organisation',
    isRecommended: true,
  },
  {
    id: 'roadmaps',
    title: APPLICATIONS.roadmaps.title,
    image: APPLICATIONS.roadmaps.image,
    description: 'I want to configure and manage roadmaps and deliverables',
    isRecommended: false,
  },
  {
    id: 'probation',
    title: 'Probation',
    image: 'probation',
    description: 'I want to setup and configure probation process',
    isRecommended: false,
  },
]

const InitialPerformanceApps = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCompanySettings)

  const { values } = useLapeContext<CompanyPreferencesInterface>()

  useEffect(() => {
    if (values.performance_apps_to_setup.selected == null) {
      values.performance_apps_to_setup.selected = true
      values.performance_apps_to_setup.goals = true
      values.performance_apps_to_setup.performance = true
      values.performance_apps_to_setup.roadmaps = true
      values.performance_apps_to_setup.skills = true
    }
  }, [])

  return (
    <>
      <Box pb="s-16">
        <SectionTitle title="Do you want to setup the Performance product now?" />

        <LapeBooleanRadioSwitch
          name="performance_apps_to_setup.selected"
          disabled={disableEdit}
          yesLabel={
            <Flex>
              Yes
              <Recommended />
            </Flex>
          }
          yesDescription="I want to set up my Performance applications during onboarding"
          noDescription="I’d like to skip this step for now. I will set up my Performance applications later"
        />
      </Box>

      {values.performance_apps_to_setup.selected ? (
        <>
          <SectionTitle title="Which Performance applications you would like to setup during onboarding?" />

          <Group>
            {apps.map(app => (
              <LapeNewSwitch
                itemTypeProps={{
                  title: app.isRecommended ? (
                    <Flex>
                      {app.title} <Recommended />
                    </Flex>
                  ) : (
                    app.title
                  ),
                  description: app.description,
                  image: getIconImage(app.image, 60, 'png'),
                }}
                name={`performance_apps_to_setup.${app.id}`}
                disabled={disableEdit}
                key={app.id}
              />
            ))}
          </Group>
        </>
      ) : null}
    </>
  )
}

export default InitialPerformanceApps
