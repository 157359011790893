import ScheduleSidebar from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduleSidebar'
import React, { useEffect, useMemo, useState } from 'react'
import {
  CandidateInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
  ScheduleSidebarModeType,
} from '@src/interfaces/interviewTool'
import { getCandidateInterviewStages } from '@src/api/recruitment/interviews'
import TimelineSidebarLoader from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineSidebarLoader'
import { useGetCandidateSettings } from '@src/api/settings'

interface TimelineScheduleSidebarProps {
  onClose: () => void
  candidate: CandidateInterface
  stageId: number
  interviewId: number
  roundId?: number
  isPrepCall?: boolean
}

const TimelineScheduleSidebar = ({
  onClose,
  candidate,
  roundId,
  stageId,
  isPrepCall,
  interviewId,
}: TimelineScheduleSidebarProps) => {
  const [stages, setStages] = useState<InterviewStageWithoutRoundInterface[]>()
  const [isLoading, setIsLoading] = useState(true)
  const { data: candidateSettings } = useGetCandidateSettings()

  useEffect(() => {
    if (roundId) {
      getCandidateInterviewStages({}, roundId)
        .then(resp => setStages(resp.data.results))
        .finally(() => setIsLoading(false))
    }
  }, [roundId])

  const nextStages = useMemo(() => {
    if (!stages) {
      return []
    }

    const idx = stages.findIndex(item => item.id === stageId)
    return stages.slice(idx)
  }, [stages, stageId])

  const mode = useMemo<ScheduleSidebarModeType | undefined>(() => {
    if (!stages) {
      return undefined
    }

    const stage = stages.find(item => item.id === stageId)

    if (!stage) {
      return undefined
    }

    if (stage.scheduling_status === InterviewStatuses.pending_scheduling) {
      return 'scheduling'
    }

    if (InterviewShowScheduleSidebarStatuses.includes(stage.scheduling_status)) {
      return 'view'
    }

    return undefined
  }, [stages, stageId])

  if (isLoading) {
    return <TimelineSidebarLoader onClose={onClose} />
  }

  if (!stages || !candidateSettings?.enable_scheduling) {
    return null
  }

  return (
    <ScheduleSidebar
      candidate={candidate}
      selectedStageId={stageId}
      stages={mode === 'scheduling' ? nextStages : stages}
      totalStagesCount={stages.length}
      roundId={roundId}
      onClose={onClose}
      initialMode={mode}
      isPrepCall={isPrepCall}
      interviewId={interviewId}
      readonly
    />
  )
}

export default TimelineScheduleSidebar
