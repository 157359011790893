import React, { useState } from 'react'
import {
  PerformanceReviewTypes,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { TabsInterface } from '@src/interfaces/data'
import {
  getCleanValuesBeforeSave,
  getLastTabLocalStorageKey,
} from '@src/utils/performance'
import IncompleteFormWarningDialog from '@src/features/Popups/IncompleteFormWarningDialog'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { history, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { validateTabs } from '@src/utils/form'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import set from 'lodash/set'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { shouldOpenNextReview } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getReviewUrl } from '@src/pages/Forms/EmployeePerformanceLayout/SumbitButton'

type Props = {
  tabs: TabsInterface<ReviewScorecardInterface>[]
  type: 'employee-performance' | 'probation-review' | 'upwards-review' | 'pip-review'
}

const SubmitButton = ({ tabs, type }: Props) => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const { id, employeeId } = useParams<{ id: any; employeeId: any }>()
  const { values, submit, isSubmitting } = form
  const [incompleteWarningOpen, setIncompleteWarningOpen] = useState(false)
  const isCompleted = values.status === Statuses.completed
  const tabIndex = tabs.findIndex(tab => matchPath(location.pathname, tab.path))
  const isLastTab = tabIndex >= tabs.length - 1
  const currentTab = tabs[tabIndex]
  const user = useSelector(selectUser)
  const reviews = useGetNotCompletedReviews(user.id)
  const reviewsList = reviews?.data?.results?.filter(
    review => review.status === Statuses.pending || review.status === Statuses.draft,
  )
  const reviewUrl = getReviewUrl(id, employeeId, reviewsList)
  const statusPopup = useStatusPopup()

  if (!isCompleted && !isLastTab) {
    return null
  }

  const renderError = (reviewType: PerformanceReviewTypes) => {
    const URLS = {
      'employee-performance': {
        [PerformanceReviewTypes.deliverables]: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL,
        [PerformanceReviewTypes.skills]: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SKILLS,
        [PerformanceReviewTypes.managerSkills]: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SKILLS,
        [PerformanceReviewTypes.cultureFit]:
          ROUTES.FORMS.EMPLOYEE_PERFORMANCE.CULTURE_FIT,
        [PerformanceReviewTypes.summary]: ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SUMMARY,
      },
      'probation-review': {
        [PerformanceReviewTypes.deliverables]: ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES,
        [PerformanceReviewTypes.skills]: ROUTES.FORMS.PROBATION_REVIEW.SKILLS,
        [PerformanceReviewTypes.cultureFit]: ROUTES.FORMS.PROBATION_REVIEW.CULTURE_FIT,
        [PerformanceReviewTypes.summary]: ROUTES.FORMS.PROBATION_REVIEW.SUMMARY,
      },
      'upwards-review': {
        [PerformanceReviewTypes.skills]: ROUTES.FORMS.UPWARDS_REVIEW.SKILLS,
        [PerformanceReviewTypes.cultureFit]: ROUTES.FORMS.UPWARDS_REVIEW.CULTURE_FIT,
        [PerformanceReviewTypes.summary]: ROUTES.FORMS.UPWARDS_REVIEW.SUMMARY,
      },
      'pip-review': {
        [PerformanceReviewTypes.deliverables]: ROUTES.FORMS.PIP_REVIEW.DELIVERABLES,
        [PerformanceReviewTypes.skills]: ROUTES.FORMS.PIP_REVIEW.SKILLS,
        [PerformanceReviewTypes.cultureFit]: ROUTES.FORMS.PIP_REVIEW.CULTURE_FIT,
        [PerformanceReviewTypes.summary]: ROUTES.FORMS.PIP_REVIEW.SUMMARY,
      },
    }

    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    if (currentTab?.path !== URLS[type][reviewType]) {
      history.replace(
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        pathToUrl(URLS[type][reviewType], {
          id,
          employeeId,
        }),
      )
    }

    setIncompleteWarningOpen(true)
  }

  const clearLocalStorage = () => {
    workspaceLocalStorage.removeItem(
      getLastTabLocalStorageKey(values.category, employeeId, id),
    )
  }

  const showSuccess = (goNext: boolean) => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={() => {
          statusPopup.hide()
          clearLocalStorage()
          navigateTo(goNext ? reviewUrl : pathToUrl(ROUTES.APPS.TODO.REVIEWS))
        }}
      >
        <StatusPopup.Title>Feedback submitted</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const handleSave = async () => {
    const canOpenNextReview = shouldOpenNextReview(values.category)
    const errors = await validateTabs(values, tabs)

    if (errors?.review_data?.deliverables) {
      renderError(PerformanceReviewTypes.deliverables)
      return
    }

    if (errors?.review_data?.functional_skills) {
      renderError(PerformanceReviewTypes.skills)
      return
    }

    if (errors?.review_data?.manager_skills) {
      renderError(PerformanceReviewTypes.managerSkills)
      return
    }

    if (errors?.review_data?.culture_skills || errors?.review_data?.culture_values) {
      renderError(PerformanceReviewTypes.cultureFit)
      return
    }

    if (errors?.review_data?.overall_feedback) {
      renderError(PerformanceReviewTypes.summary)
      return
    }

    // TODO: maybe need to refactor this by making these changes in api
    form.values = getCleanValuesBeforeSave(values)
    form.values.status = Statuses.completed

    setLoading(true)
    try {
      await submit()
      showSuccess(canOpenNextReview)
    } catch (error) {
      set(form.errors, 'review_data', error.response?.data?.review_data)
    } finally {
      setLoading(false)
    }
  }

  const pending = isSubmitting || loading

  return (
    <>
      {currentTab?.invalidTabMessage && (
        <IncompleteFormWarningDialog
          open={incompleteWarningOpen}
          onClose={() => setIncompleteWarningOpen(false)}
        >
          {currentTab.invalidTabMessage()}
        </IncompleteFormWarningDialog>
      )}

      <Button
        onClick={handleSave}
        pending={pending && form.values.status !== Statuses.draft}
        disabled={pending}
        width={167}
        variant={isLastTab ? 'default' : 'secondary'}
        elevated
        data-testid="btn-submit"
      >
        {isCompleted ? 'Save Changes' : 'Submit'}
      </Button>
    </>
  )
}

export default connect(SubmitButton)
