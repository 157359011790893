import React, { useEffect, useState } from 'react'
import {
  Item,
  Flex,
  Text,
  Image,
  MoreBar,
  Icon,
  Toast,
  useToast,
  Box,
  ActionButton,
} from '@revolut/ui-kit'
import { AddQuestionSidebar } from '@src/apps/People/Engagement/Survey/components/AddQuestionSidebar'
import { DraggableQuestions } from '@src/apps/People/Engagement/Survey/components/DraggableQuestions/DraggableQuestions'
import { sortSurveyQuestionsByOrder } from '@src/apps/People/Engagement/helpers'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  EngagementQuestionInterface,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'
import { MULTIPLE_ITEMS_SELECTOR_CELL_MIN_HEIGHT } from './consts'
import { useTestSurvey } from '../hooks/useTestSurvey'
import { flatten } from 'lodash'
import { EditQuestionSidebar } from '@src/apps/People/Engagement/Survey/components/EditQuestionSidebar'
import { Statuses } from '@src/interfaces'
import { RunningSurveyBanner } from './RunningSurveyBanner'

export const Questions = () => {
  const { values, initialValues } = useLapeContext<EngagementSurveyInterface>()
  const toast = useToast()
  const [addedQuestions, setAddedQuestions] = useState<
    (EngagementQuestionInterface & { parentId?: number })[]
  >([])
  const { runTestSurvey, isPending } = useTestSurvey({
    surveyId: values.id,
    preSubmit: true,
  })
  const [questionsSidebarOpen, setQuestionsSidebarOpen] = useState(false)
  const [sidebarEditId, setSidebarEditId] = useState<number>()

  const handleDeleteAddedQuestion = (id: number) => {
    setAddedQuestions(addedQuestions.filter(q => q.id !== id))
  }

  const handleChangeQuestionsOrder = (id: number, overId: number | null) => {
    if (!overId) {
      return
    }
    const targetIdx = addedQuestions.findIndex(q => q.id === id)
    const overIdx = addedQuestions.findIndex(q => q.id === overId)
    const questionsWithoutDragged = addedQuestions.filter(q => q.id !== id)

    const changedOrderQuestions = [
      ...questionsWithoutDragged.slice(0, overIdx),
      addedQuestions[targetIdx],
      ...questionsWithoutDragged.slice(overIdx, addedQuestions.length - 1),
    ]
    setAddedQuestions(changedOrderQuestions)
  }

  useEffect(() => {
    if (values.id) {
      setAddedQuestions(sortSurveyQuestionsByOrder(initialValues.survey_questions))
    }
  }, [])

  useEffect(() => {
    values.survey_questions = addedQuestions.map(question => {
      const id = question.parentId
      delete question.parentId
      return {
        question,
        id,
        order: addedQuestions.findIndex(q => q.id === question.id),
      }
    })
  }, [addedQuestions])

  return (
    <>
      <RunningSurveyBanner
        isRunning={values.round_status?.id === Statuses.running}
        surveyId={values.id}
        preDescription="While you still can edit questions in order to correct typos. Please keep in
              mind that any changes in the number of questions won’t affect the running
              survey."
      />

      <Item>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="engagement.form.questions.title"
              defaultMessage="Questions"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.form.questions.description"
              defaultMessage="Define which questions should be included in the survey and in which order"
            />
          </Item.Description>
          <Box>
            <Flex
              minHeight={MULTIPLE_ITEMS_SELECTOR_CELL_MIN_HEIGHT}
              flexDirection="column"
              pt="s-8"
              justifyContent={addedQuestions.length ? 'flex-start' : 'center'}
            >
              {addedQuestions.length ? (
                <>
                  <Box pb="s-16">
                    <MoreBar>
                      <MoreBar.Action
                        useIcon="Plus"
                        onClick={() => {
                          setSidebarEditId(undefined)
                          setQuestionsSidebarOpen(true)
                        }}
                      >
                        <FormattedMessage
                          id="engagement.form.questions.addButton"
                          defaultMessage="Add question"
                        />
                      </MoreBar.Action>
                      <MoreBar.Action
                        disabled={isPending || !addedQuestions.length}
                        pending={isPending}
                        useIcon="Play"
                        onClick={runTestSurvey}
                      >
                        Preview survey
                      </MoreBar.Action>
                    </MoreBar>
                  </Box>
                  <DraggableQuestions
                    addedQuestions={addedQuestions}
                    onDeleteQuestion={id => handleDeleteAddedQuestion(id)}
                    onChangeQuestionsOrder={handleChangeQuestionsOrder}
                    onEditQuestion={id => {
                      setQuestionsSidebarOpen(false)
                      setSidebarEditId(id)
                    }}
                  />
                </>
              ) : (
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  height="100%"
                  gap="s-16"
                >
                  <Image
                    src="https://assets.revolut.com/assets/3d-images-v2/3D395.png"
                    size={104}
                  />
                  <Flex flexDirection="column" alignItems="center">
                    <Text variant="h5">
                      <FormattedMessage
                        id="engagement.form.audience.questions.title"
                        defaultMessage="You don’t have any questions"
                      />
                    </Text>
                    <Text>
                      <FormattedMessage
                        id="engagement.form.audience.questions.description"
                        defaultMessage="Create your first question from scratch or select any of our library"
                      />
                    </Text>
                  </Flex>
                  <ActionButton
                    useIcon="Plus"
                    onClick={() => setQuestionsSidebarOpen(true)}
                  >
                    <FormattedMessage
                      id="engagement.form.questions.addButton"
                      defaultMessage="Add question"
                    />
                  </ActionButton>
                </Flex>
              )}
            </Flex>
          </Box>
        </Item.Content>
      </Item>
      <AddQuestionSidebar
        open={questionsSidebarOpen}
        onClose={() => setQuestionsSidebarOpen(false)}
        onAddQuestion={(newQuestion, closeSidebar) => {
          setAddedQuestions([...addedQuestions, ...flatten([newQuestion])])

          if (closeSidebar) {
            setQuestionsSidebarOpen(false)
          } else {
            toast.show(
              <Toast>
                <Toast.Indicator>
                  <Icon name="Check" />
                </Toast.Indicator>
                <Toast.Label>Question added to the survey</Toast.Label>
              </Toast>,
              'long',
            )
          }
        }}
        addedQuestionsIds={addedQuestions.map(({ id }) => id)}
      />
      <EditQuestionSidebar
        questionId={sidebarEditId}
        onClose={result => {
          setSidebarEditId(undefined)
          if (result) {
            setAddedQuestions(prev => {
              const newItems = prev.map(question => {
                if (question.id === result.id) {
                  return result
                }
                return question
              })
              return newItems
            })
          }
        }}
      />
    </>
  )
}
