import React, { useCallback, useEffect, useState } from 'react'
import format from 'date-fns/format'
import debounce from 'lodash/debounce'
import { Button, InputGroup, Popup, VStack } from '@revolut/ui-kit'
import { deleteShift, useShiftOverview } from '@src/api/attendance'
import { selectorKeys } from '@src/constants/api'
import { ScheduleShiftInterface } from '@src/interfaces/attendance'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { LargeWeeklyCalendarEventInterface } from '@src/components/LargeWeeklyCalendar'
import { getDateFromEventDate } from '@src/components/LargeWeeklyCalendar/LargeWeeklyCalendar'
import { getFormattedTime } from '@src/components/LargeWeeklyCalendar/date'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { ShiftOverview } from '@src/pages/Forms/ScheduleShift/ShiftOverview'
import { PermissionTypes } from '@src/store/auth/types'
import { EventTypes } from './AddEventPopup'

export const DEBOUNCE_WAIT = 750

interface FormProps {
  calendarEvent: LargeWeeklyCalendarEventInterface
  employeeId: number
  eventType: EventTypes
  onAfterSubmit: VoidFunction
  onCancel: VoidFunction
}

export const EventForm = ({
  calendarEvent,
  employeeId,
  eventType,
  onAfterSubmit,
  onCancel,
}: FormProps) => {
  const { values } = useLapeContext<ScheduleShiftInterface>()
  const startEventDate = getDateFromEventDate(calendarEvent.start)
  const endEventDate = getDateFromEventDate(calendarEvent.end)
  const isShift = eventType === 'shifts'

  const [start, setStart] = useState<string | undefined | null>(
    startEventDate ? getFormattedTime(startEventDate) : values.start_time,
  )
  const [end, setEnd] = useState<string | undefined | null>(
    endEventDate ? getFormattedTime(endEventDate) : values.end_time,
  )

  useEffect(() => {
    if (startEventDate) {
      values.date = format(startEventDate, 'yyyy-MM-dd')
      values.start_time = getFormattedTime(startEventDate)
    }

    if (endEventDate) {
      values.end_time = getFormattedTime(endEventDate)
    }
  }, [])

  const shiftOverview = useShiftOverview(
    employeeId,
    isShift
      ? {
          id: values.id,
          date: values.date,
          start_time: start,
          end_time: end,
        }
      : {},
  )

  const compensationAllowed = !!shiftOverview.data?.compensation_allowed
  const toilDisallowed = shiftOverview.data?.toil_allowed === false

  useEffect(() => {
    if (toilDisallowed && compensationAllowed) {
      values.preferred_compensation = { id: 'remuneration', name: 'Remuneration' }
    }
  }, [toilDisallowed, compensationAllowed])

  const debouncedSetEnd = useCallback(debounce(setEnd, DEBOUNCE_WAIT), [])
  const debouncedSetStart = useCallback(debounce(setStart, DEBOUNCE_WAIT), [])

  return (
    <VStack gap="s-8">
      {values.id && (
        <SettingsButtons>
          <DeleteButton
            deleteApi={() => deleteShift(employeeId, values.id!, eventType)}
            globalPermissions={[PermissionTypes.DeleteEmployeeShift]}
            onAfterDelete={onAfterSubmit}
            title={isShift ? 'shift' : 'break'}
          />
        </SettingsButtons>
      )}
      <InputGroup>
        <LapeDatePickerInput
          label="Date"
          name="date"
          onChange={date => {
            if (date) {
              values.date = format(date, 'yyyy-MM-dd')
            }
          }}
          required
        />
        <InputGroup variant="horizontal">
          <LapeNewInput
            name="start_time"
            label="Start time"
            onAfterChange={isShift ? debouncedSetStart : undefined}
            required
            type="time"
          />
          <LapeNewInput
            name="end_time"
            label="End time"
            onAfterChange={isShift ? debouncedSetEnd : undefined}
            required
            type="time"
          />
        </InputGroup>
        <ShiftOverview overview={shiftOverview} />

        {compensationAllowed && (
          <LapeRadioSelectInput
            disabled={toilDisallowed}
            label="Preferred compensation"
            name="preferred_compensation"
            message="Please indicate your preferred compensation for the overtime work."
            selector={selectorKeys.shift_compensation_types}
          />
        )}
      </InputGroup>
      <Popup.Actions horizontal>
        <Button onClick={onCancel} variant="secondary">
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          onAfterSubmit={onAfterSubmit}
          successText={
            values?.id
              ? 'Event was successfully updated'
              : 'Event was successfully created'
          }
          useValidator
        >
          Save
        </NewSaveButtonWithPopup>
      </Popup.Actions>
    </VStack>
  )
}
