import {
  DeliverablesOverallFeedbackInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import DeliverablesCommentsBox from '@src/pages/Forms/Probation/DeliverablesCommentsBox'
import { putDeliverablesFeedback } from '@src/api/probationReview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import set from 'lodash/set'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const ConfirmationDialogStyled = styled(ConfirmationDialog)`
  padding: 16px;
  width: 600px;
`

const DeliverablesCommentsPopup = ({ isOpen, onClose }: Props) => {
  const [feedback, setFeedback] = useState<DeliverablesOverallFeedbackInterface>({
    complexity: [],
    quality: [],
    speed: [],
  })
  const [errors, setErrors] = useState<DeliverablesOverallFeedbackInterface>()
  const { values } = useLapeContext<ReviewScorecardInterface>()

  useEffect(() => {
    if (values.review_data.extra_deliverables_overall_feedback) {
      setFeedback(values.review_data.extra_deliverables_overall_feedback)
    }
  }, [])

  if (!isOpen) {
    return null
  }

  const handleConfirm = async () => {
    if (
      !!feedback.complexity.length ||
      !!feedback.speed.length ||
      !!feedback.quality.length
    ) {
      set(values, 'review_data.extra_deliverables_overall_feedback', feedback)
      try {
        await putDeliverablesFeedback(
          values.reviewed_employee.id,
          values.id,
          feedback,
          values.category,
        )
        onClose()
      } catch (e) {
        setErrors(e.response.data)
      }
    } else {
      set(values, 'review_data.extra_deliverables_overall_feedback', null)
      onClose()
    }
  }

  return (
    <ConfirmationDialogStyled
      open={isOpen}
      onClose={onClose}
      onConfirm={handleConfirm}
      onReject={onClose}
      showHeader={false}
      yesMessage="Submit"
      noMessage="Cancel"
      body={
        <DeliverablesCommentsBox
          feedback={feedback}
          onChange={setFeedback}
          errors={errors}
        />
      }
    />
  )
}

export default DeliverablesCommentsPopup
