import React, { useState, useEffect } from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router'
import pick from 'lodash/pick'

import { employeeChangeRequest } from '@src/api/employeeChangeRequest'
import {
  EmployeeChangeRequestInterface,
  EmployeeChangeRequestParams,
} from '@src/interfaces/employeeChangeRequest'
import { useEmployeeFields } from '@src/api/employees'
import Loader from '@src/components/CommonSC/Loader'
import { PageWrapper } from '@src/components/Page/Page'
import LapeForm from '@src/features/Form/LapeForm'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'

import { RequestDetails } from './RequestDetails'
import { RequestNew } from './RequestNew'
import { EmployeeDetails } from './common'
import {
  EmployeeProfileSectionKey,
  getProfileSectionFields,
} from '@src/pages/EmployeeProfile/Preview/common'

const EmployeeChangeRequest = () => {
  const params = useParams<EmployeeChangeRequestParams>()
  const { id, type, employeeId } = params

  const fields = getProfileSectionFields(type as EmployeeProfileSectionKey)

  const { data: employee, isLoading } = useEmployeeFields<EmployeeDetails>(employeeId, [
    'id',
    'full_name',
    'avatar',
    'employee_type',
    ...fields,
  ])

  const [changeRequestDetails, setChangeRequestDetails] =
    useState<EmployeeChangeRequestInterface>()

  useEffect(() => {
    if (employeeId && id && !changeRequestDetails) {
      employeeChangeRequest
        .get({ id, employeeId })
        .then(res => setChangeRequestDetails(res.data))
    }
  }, [employeeId, id, changeRequestDetails])

  if (isLoading || !employee || (id && !changeRequestDetails)) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  return id && changeRequestDetails ? (
    <RequestDetails
      request={changeRequestDetails}
      setChangeRequestDetails={setChangeRequestDetails}
      employee={employee}
    />
  ) : (
    <LapeForm onSubmit={() => Promise.resolve({})} initialValues={pick(employee, fields)}>
      <FormValidatorProvider>
        <RequestNew
          setChangeRequestDetails={setChangeRequestDetails}
          employee={employee}
          type={type}
        />
      </FormValidatorProvider>
    </LapeForm>
  )
}

export default connect(EmployeeChangeRequest)
