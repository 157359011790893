import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useCustomHiringProcessFlag = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const customHiringProcesses = (featureFlags ?? []).includes(
    FeatureFlags.CustomHiringProcesses,
  )
  return customHiringProcesses
}
