import React from 'react'
import { Token, DetailsCell, ExpandableCell, Text, VStack, Group } from '@revolut/ui-kit'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { ToldManagerVariants } from '../constants'

type Props = {
  resignation: EmployeeResignationInterface
}
export const ReasonsPreview = ({ resignation }: Props) => {
  const [customReason] =
    Object.entries(resignation?.selected_reasons || {}).find(([_, subreasons]) => {
      return subreasons.length === 0
    }) || []

  return (
    <Group>
      {Object.entries(resignation.selected_reasons)
        .filter(([_, subreasons]) => !!subreasons.length)
        .map(([reason, subreasons], idx) => {
          return (
            <ExpandableCell key={reason}>
              <ExpandableCell.Title>{`Reason ${idx + 1}`}</ExpandableCell.Title>
              <ExpandableCell.Content>{reason}</ExpandableCell.Content>
              <ExpandableCell.Note>
                <VStack space="s-24" mb="s-12">
                  {subreasons.map(subreason => {
                    return (
                      <Text key={subreason} variant="h6" color={Token.color.greyTone50}>
                        {subreason}
                      </Text>
                    )
                  })}
                </VStack>
              </ExpandableCell.Note>
            </ExpandableCell>
          )
        })}
      {customReason ? (
        <DetailsCell>
          <DetailsCell.Title>{`Reason ${
            Object.keys(resignation.selected_reasons).length
          }`}</DetailsCell.Title>
          <DetailsCell.Content>{customReason}</DetailsCell.Content>
        </DetailsCell>
      ) : null}
      <DetailsCell>
        <DetailsCell.Title>Resignation discussed with LM/FM</DetailsCell.Title>
        <DetailsCell.Content>
          {resignation.told_manager ? ToldManagerVariants.yes : ToldManagerVariants.no}
        </DetailsCell.Content>
      </DetailsCell>
    </Group>
  )
}
