import React from 'react'
import { Box, Flex, InputGroup, Widget, HStack } from '@revolut/ui-kit'
import { get, set } from 'lodash'

import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import {
  PerformanceScorecardOption,
  PerformanceScorecardQuestionType,
} from '@src/interfaces/settings'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { ReviewCyclesScorecardSectionInterface } from '@src/interfaces/reviewCycles'
import { SectionControls } from '@src/pages/Settings/Performance/Scorecards/SectionControls'
import { QuestionContent } from '@src/pages/Settings/Performance/Scorecards/QuestionContent'

type EditQuestionProps = {
  questionIdx: number
}

export const EditScorecardQuestion = ({ questionIdx }: EditQuestionProps) => {
  const { values, errors } = useLapeContext<ReviewCyclesScorecardSectionInterface>()

  const { data: questionTypeOptions, isLoading: isQuestionTypesLoading } =
    useGetSelectors<PerformanceScorecardQuestionType>(
      selectorKeys.custom_scorecard_question_types,
    )

  const question = values?.questions[questionIdx]
  const questionTypeField = `questions.${questionIdx}.question_type`

  const onChangeQuestions = (options: PerformanceScorecardOption[]) => {
    values.questions[questionIdx].options = options
  }

  const onChangeType = (option: PerformanceScorecardQuestionType) => {
    set(errors, questionTypeField, undefined)

    if (option.id === 'checkbox' || option.id === 'option') {
      question.options = []
    } else {
      question.options = undefined
    }
    question.placeholder_text = option.id === 'dropdown' ? 'Select one' : undefined
    question.question_type = option
  }

  const handleDelete = () => {
    values.questions = values.questions.filter((_, i) => i !== questionIdx)
  }

  const handleCopy = () => {
    values.questions.splice(questionIdx + 1, 0, {
      ...question,
      options: question.options?.map(option => ({ ...option, id: undefined })),
      id: undefined,
    })
  }

  return (
    <Widget p="s-16" mt="s-16">
      <Flex justifyContent="space-between" alignItems="center" mb="s-16">
        <Flex>
          <HStack space="s-8">
            <DropdownButton
              options={questionTypeOptions || []}
              onSelect={onChangeType}
              pending={isQuestionTypesLoading}
              variant={get(errors, questionTypeField) ? 'negative' : undefined}
              data-name={questionTypeField}
            >
              {question.question_type?.name || 'Select type'}
            </DropdownButton>
            <SwitchButton
              checked={question.optional}
              onClick={() => {
                question.optional = !question.optional
              }}
              useMoreBar={false}
            >
              Optional
            </SwitchButton>
          </HStack>
        </Flex>

        <SectionControls
          id={questionIdx}
          onDelete={handleDelete}
          onCopy={handleCopy}
          sections={values.questions}
        />
      </Flex>

      <Box mb="s-16">
        <InputGroup>
          <LapeNewInput
            label="Question"
            name={`questions.${questionIdx}.title`}
            required
          />
          <LapeNewInput label="Subtitle" name={`questions.${questionIdx}.subtitle`} />
        </InputGroup>
      </Box>

      <QuestionContent
        options={question.options}
        placeholder={question.placeholder_text}
        type={question.question_type?.id}
        onChangePlaceholder={val => {
          if (val) {
            values.questions[questionIdx].placeholder_text = val
          }
        }}
        onChangeQuestions={onChangeQuestions}
        optionsKey={`questions.${questionIdx}.options`}
      />
    </Widget>
  )
}
