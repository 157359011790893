import {
  CellTypes,
  ColumnCellInterface,
  ColumnInterface,
  FilterType,
} from '@src/interfaces/data'
import { EntityTypes, selectorKeys } from '../api'
import {
  RoadmapInterface,
  RoadmapPriorities,
  RoadmapStatuses,
} from '@src/interfaces/roadmaps'
import { IssueTypes, ItemType } from '@src/interfaces/deliverables'
import DeleteAction, {
  DeleteActionProps,
} from '@components/ColumnInserts/DeleteAction/DeleteAction'
import React from 'react'
import { isBefore } from 'date-fns'
import Url from '@src/components/ColumnInserts/Url/Url'
import { Flex, Link, Tag, Text, Token, Image } from '@revolut/ui-kit'
import RoadmapChangelog from '@src/features/RoadmapChangelog/RoadmapChangelog'
import { formatDate, formatNumber } from '@src/utils/format'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'
import { getEntityLink } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import TableCellLink from '@src/components/TableCellLink/TableCellLink'
import { capitalize } from 'lodash'
import Table from '@src/components/TableV2/Table'
import { RoadmapName } from '@components/ColumnInserts/RoadmapName/RoadmapName'

const checkIfFieldHasChanged = (
  data: RoadmapInterface,
  field: keyof RoadmapInterface,
): boolean => {
  if (!data.fields_changed_after_deadline) {
    return false
  }
  return data.fields_changed_after_deadline.includes(field)
}

export const getRoadmapStatusColor = (data: RoadmapInterface) => {
  switch (data.status) {
    case RoadmapStatuses.DONE:
      return { color: Token.color.success, bg: Token.color.green_20 }
    case RoadmapStatuses.TO_DO:
      return { color: Token.color.greyTone50, bg: Token.color.greyTone20 }
    case RoadmapStatuses.IN_PROGRESS:
    default:
      return { color: Token.color.accent, bg: Token.color.accent_20 }
  }
}

export const roadmapStatusColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.roadmap_status,
  title: 'Status',
  insert: ({ data, children }) => {
    return (
      <RoadmapChangelog column="status" data={data} hasChanged={data.deleted}>
        <Tag variant="faded" {...getRoadmapStatusColor(data)}>
          {children}
        </Tag>
      </RoadmapChangelog>
    )
  },
}

export const roadmapGenericNameColumn: ColumnCellInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  notHoverable: true,
  insert: ({ data }) => <RoadmapName data={data} />,
  width: 600,
}

export const roadmapLevelColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.text,
  filterType: FilterType.selector,
  title: 'Level',
  idPoint: 'type',
  dataPoint: 'type__name',
  filterKey: 'type',
  sortKey: 'type',
  selectorsKey: selectorKeys.roadmap_types,
}

export const teamColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.selector,
  title: 'Team',
  idPoint: 'team.id',
  dataPoint: 'team.id',
  filterKey: 'team_id',
  sortKey: null,
  selectorsKey: selectorKeys.team,
  insert: ({ data }) => {
    if (!data.team) {
      return <Text>-</Text>
    }
    return (
      <TableCellLink to={getEntityLink(EntityTypes.team, data.team.id) || ''}>
        <Text>{data.team.name || '-'}</Text>
      </TableCellLink>
    )
  },
}

export const departmentColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  filterType: FilterType.selector,
  title: 'Department',
  idPoint: 'department.id',
  dataPoint: 'department.id',
  filterKey: 'department__id',
  sortKey: null,
  selectorsKey: selectorKeys.department,
  insert: ({ data }) => {
    if (!data.department) {
      return <Text>-</Text>
    }
    return (
      <TableCellLink to={getEntityLink(EntityTypes.department, data.department.id) || ''}>
        <Text>{data.department?.name || '-'}</Text>
      </TableCellLink>
    )
  },
}

export const roadmapOrgUnitColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.text,
  filterType: FilterType.selector,
  title: 'Organisational Unit',
  idPoint: 'organisational_unit',
  dataPoint: 'organisational_unit__name',
  filterKey: 'organisational_unit',
  sortKey: 'organisational_unit',
  selectorsKey: selectorKeys.roadmap_organisational_units,
  dynamicHyperlinks: data => {
    const type = data?.type?.id
    const id = data?.organisational_unit?.id

    const typeToUrl = {
      employee_roadmap: pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id }),
      team_roadmap: pathToUrl(ROUTES.FORMS.TEAM.ROADMAP, { id }),
      department_roadmap: pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, { id }),
      function_roadmap: pathToUrl(ROUTES.FORMS.FUNCTION.ROADMAP, { id }),
      company_roadmap: pathToUrl(ROUTES.FORMS.COMPANY.ROADMAP),
    }

    return type ? typeToUrl[type] : null
  },
}

export const roadmapCompanyOrgUnitColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.text,
  filterType: FilterType.selector,
  title: 'Organisational Unit',
  idPoint: 'department__id',
  dataPoint: 'department__name',
  filterKey: 'department__id',
  sortKey: 'department__name',
  selectorsKey: selectorKeys.department,
  dynamicHyperlinks: data => {
    const id = data?.department?.id

    return id ? pathToUrl(ROUTES.FORMS.DEPARTMENT.ROADMAP, { id }) : null
  },
}

export const roadmapProgressColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'progress',
  dataPoint: 'progress',
  sortKey: 'done',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return !data?.parent_issue_key ? (
      <Table.ProgressCell
        value={data.done / 100}
        infoTooltip={`Done = ${formatNumber(data.done)}%\\nIn Progress = ${formatNumber(
          data.progress,
        )}%\\nTo Do = ${formatNumber(data.todo)}%`}
      />
    ) : null
  },
  title: 'Progress',
}

export const roadmapResolutionAtColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.date,
  idPoint: 'resolution_date_time',
  dataPoint: 'resolution_date_time',
  sortKey: 'resolution_date_time',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Resolved on',
}

export const roadmapOwnerColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => (
    <RoadmapChangelog
      column="owner"
      data={data}
      hasChanged={checkIfFieldHasChanged(data, 'owner')}
      tooltipPlacement="top"
    >
      <UserWithAvatar {...data.owner} />
    </RoadmapChangelog>
  ),
}

export const roadmapStartDate: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'start_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start Date',
}

export const getRoadmapDueDateColor = (data: RoadmapInterface) => {
  const currentDate = new Date()

  if (data.due_date) {
    const dueDate = new Date(data.due_date)

    // is due date before current date or resolution date?
    const isDeadline =
      isBefore(dueDate, currentDate) ||
      isBefore(dueDate, new Date(data.resolution_date_time))

    if (isDeadline && data.status !== RoadmapStatuses.DONE) {
      return Token.color.red
    }
  }

  return Token.color.foreground
}

export const roadmapDueDate: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Due Date',
  headerTooltip: (
    <TooltipContainer>
      The due date is fetched from the Jira built in field or from a custom field if
      defined. If both are used the greatest value will be set. Please use the built in
      field and remove the custom field to avoid having inaccurate changelog values.
    </TooltipContainer>
  ),
  colors: getRoadmapDueDateColor,
  insert: ({ data }) => {
    return (
      <RoadmapChangelog
        column="due_date"
        data={data}
        hasChanged={checkIfFieldHasChanged(data, 'due_date')}
      >
        {data.due_date ? formatDate(data.due_date) : undefined}
      </RoadmapChangelog>
    )
  },
}

export const roadmapAddedOnColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Added on',
}

export const roadmapProjectColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'project',
  dataPoint: 'project',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Project',
  insert({ data, children }) {
    return <Url href={data.project_url}>{children}</Url>
  },
}

export const roadmapJiraLabelColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'displayable_labels',
  dataPoint: 'displayable_labels',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Labels',
  insert: ({ data }) => {
    if (data?.displayable_labels?.length) {
      return (
        <Flex gap="s-4">
          {data.displayable_labels.map(label => (
            <Tag key={label} variant="faded">
              {label}
            </Tag>
          ))}
        </Flex>
      )
    }

    return '-'
  },
}

export const roadmapEpicUrlColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'key',
  dataPoint: 'key',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Key',
  insert: ({ data, children }) =>
    data.epic_url ? (
      <Link href={data.epic_url} target="_blank">
        <Tag
          variant="faded"
          useIcon={
            data.item_type !== ItemType.Clickup ? (
              'Jira|image'
            ) : (
              <Image
                image={{
                  default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
                  '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
                  '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
                }}
                size={14}
              />
            )
          }
        >
          {children}
        </Tag>
      </Link>
    ) : (
      <Text>-</Text>
    ),
}

export const getRoadmapPriorityColor = (data: RoadmapInterface) => {
  switch (data.priority) {
    case RoadmapPriorities.TRIVIAL:
      return Token.color.greyTone50
    case RoadmapPriorities.MINOR:
      return Token.color.green
    case RoadmapPriorities.MEDIUM:
      return Token.color.warning
    case RoadmapPriorities.HIGH:
    case RoadmapPriorities.MAJOR:
    case RoadmapPriorities.CRITICAL:
    case RoadmapPriorities.COMPANY:
    case RoadmapPriorities.BLOCKER:
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const roadmapPriorityColumn: ColumnInterface<RoadmapInterface> = {
  type: CellTypes.insert,
  idPoint: 'priority',
  dataPoint: 'priority',
  sortKey: 'priority',
  filterKey: 'priority',
  selectorsKey: selectorKeys.roadmap_priority,
  title: 'Priority',
  colors: getRoadmapPriorityColor,
  insert({ data, children }) {
    const content =
      data.priority === RoadmapPriorities.COMPANY ? (
        <b>{capitalize(children)}</b>
      ) : (
        capitalize(children)
      )

    return (
      <RoadmapChangelog
        column="priority"
        data={data}
        hasChanged={checkIfFieldHasChanged(data, 'priority')}
      >
        {content}
      </RoadmapChangelog>
    )
  },
}

export const getDeleteRoadmapColumn = (
  props: Omit<DeleteActionProps, 'data'>,
): ColumnInterface<RoadmapInterface> => ({
  type: CellTypes.insert,
  dataPoint: '',
  idPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  notHoverable: true,
  title: '',
  insert: ({ data }) => {
    return data?.issue_type === IssueTypes.Epic ? (
      <DeleteAction data={data} {...props} />
    ) : null
  },
})
