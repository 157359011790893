import { GetRequestInterface, RequestInterface, RequestInterfaceNew } from '../interfaces'
import { api } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import { filterSortPageIntoQuery } from '../utils/table'
import { CompanyGoalInterface } from '../interfaces/companyGoals'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '../interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'

export const companyGoalRequests: RequestInterface<CompanyGoalInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.COMPANY_GOALS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(API.COMPANY_GOALS_STATS, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.COMPANY_GOALS}/${id}`, undefined),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.COMPANY_GOALS}/${kpiId}`, data, undefined),
  deleteItem: async id => api.delete(`${API.COMPANY_GOALS}/${id}`, undefined),
  postItem: async data => api.post(API.COMPANY_GOALS, data, undefined),
}

export const companyGoalsRequestsNew: RequestInterfaceNew<CompanyGoalInterface> = {
  get: async ({ id }) => api.get(`${API.COMPANY_GOALS}/${id}`),
  update: async (data, { id }) => api.patch(`${API.COMPANY_GOALS}/${id}`, data),
  submit: async data => api.post(API.COMPANY_GOALS, data),
  delete: async ({ id }) => api.delete(`${API.COMPANY_GOALS}/${id}`),
}

export const getCompanyGoalsPerformanceGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.COMPANY_GOALS}/${id}/performance`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })

export const getCompanyGoals = (
  cycleOffset: string = '0',
  status: string[] = ['pending', 'approved'],
): AxiosPromise<GetRequestInterface<CompanyGoalInterface>> =>
  api.get(API.COMPANY_GOALS, {
    params: {
      review_cycle__offset: cycleOffset,
      status: status.join(','),
    },
  })
