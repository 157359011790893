import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import SummarySidebar, {
  SummarySidebarProps,
} from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummarySidebar'
import { useHistory } from 'react-router-dom'
import { Cell, Item } from '@revolut/ui-kit'

type ShowSidebarHandler = (override?: Partial<SummarySidebarProps>) => void
type HideSidebarHandler = () => void
export interface SummarySidebarContextInterface {
  showSummarySidebar: ShowSidebarHandler
  hideSummarySidebar: HideSidebarHandler
}

const SummarySidebarContext = createContext<SummarySidebarContextInterface | null>(null)

export const useSummarySidebarContext = () => {
  return useContext(SummarySidebarContext)
}

interface Props
  extends PropsWithChildren<Omit<SummarySidebarProps, 'isOpen' | 'onClose'>> {
  hideOnNavigation?: boolean
}

export const SummarySidebarProvider = ({
  children,
  hideOnNavigation = true,
  ...sidebarProps
}: PropsWithChildren<Props>) => {
  const [isOpen, setIsOpen] = useState(false)
  const [overwrittenProps, setOverwrittenProps] = useState<Partial<SummarySidebarProps>>()
  const context = useMemo(() => {
    const showSummarySidebar: ShowSidebarHandler = override => {
      setOverwrittenProps(override)
      setIsOpen(true)
    }
    return {
      showSummarySidebar,
      hideSummarySidebar: () => {
        setOverwrittenProps(undefined)
        setIsOpen(false)
      },
    }
  }, [])
  const history = useHistory()

  useEffect(() => {
    if (hideOnNavigation && isOpen) {
      setIsOpen(false)
    }
  }, [history.location.pathname])

  const props: SummarySidebarProps = {
    isOpen,
    onClose: () => setIsOpen(false),
    ...sidebarProps,
    ...overwrittenProps,
  }

  const narrativeCell = (
    <Cell>
      <Item>
        Below you can view the summary of completed scorecards from other reviewers (such
        as the self review or peer reviews).
      </Item>
    </Cell>
  )

  return (
    <SummarySidebarContext.Provider value={context}>
      {children}
      <SummarySidebar {...props} narrativeCell={narrativeCell} />
    </SummarySidebarContext.Provider>
  )
}
