import { useParams } from 'react-router-dom'
import React, { useRef, useState } from 'react'
import { MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { Delete } from '@revolut/icons'
import { AxiosError } from 'axios'
import { GroupedError, groupErrors } from './DependantEntities'
import { ConfirmDeletePopup, FailedDeletePoopup } from './Popups'
import { pushError } from '@src/store/notifications/actions'
import { EntityPermissions } from '@src/store/auth/types'
import { LapeDeleteOrgUnitButtonProps } from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'
import { Statuses, FieldOptions } from '@src/interfaces'

export interface DeleteOrgUnitButtonProps extends LapeDeleteOrgUnitButtonProps {
  data: {
    status: Statuses
    field_options: FieldOptions
    id: number
    name?: string
  }
}

const DeleteOrgUnitButton = ({
  deleteApi,
  prefix,
  onAfterDelete,
  displayName,
  entityLevelPermission = EntityPermissions.Delete,
  renderButton,
  checkPermissions = true,
  confirmMessage,
  forcedId,
  showPendingPopup,
  data,
}: DeleteOrgUnitButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [dialog, setDialog] = useState<'confirm' | 'error' | void>()
  const [errorDetails, setErrorDetails] = useState<GroupedError[]>()
  const deleteResolver = useRef<() => void>()
  const params = useParams<{ id: any }>()
  const statusPopup = useStatusPopup()
  const canDelete = checkPermissions
    ? data.field_options.actions?.includes(entityLevelPermission)
    : true

  const isErrorWithDependencies = (error: AxiosError<{ dependencies: unknown }>) => {
    const dependencies = error?.response?.data?.dependencies
    return Array.isArray(dependencies) && dependencies.length
  }

  const toggleStatusPopup = (isShown: boolean) =>
    isShown
      ? statusPopup.show(
          <StatusPopup variant="in-progress">
            <StatusPopup.Title>Deleting...</StatusPopup.Title>
          </StatusPopup>,
        )
      : statusPopup.hide()

  const togglePendingState = showPendingPopup ? toggleStatusPopup : setLoading

  const onDelete = async () => {
    try {
      togglePendingState(true)
      setDialog()
      await deleteApi(data, params)
      onAfterDelete?.()
    } catch (error) {
      if (isErrorWithDependencies(error)) {
        setErrorDetails(groupErrors(error.response?.data?.dependencies))
        setDialog('error')
      } else {
        pushError({ error })
      }
    } finally {
      togglePendingState(false)
      deleteResolver.current && deleteResolver.current()
    }

    return null
  }

  if (!canDelete) {
    return null
  }

  const handleDelete = async () => {
    setDialog('confirm')
    return new Promise<void>(resolve => {
      deleteResolver.current = resolve
    })
  }

  return (
    <>
      <ConfirmDeletePopup
        open={dialog === 'confirm'}
        name={confirmMessage || (data.name ? `${data.name} ${displayName}` : displayName)}
        onCancel={() => setDialog()}
        onConfirm={onDelete}
      />
      {errorDetails && (
        <FailedDeletePoopup
          prefix={prefix}
          errorDetails={errorDetails}
          open={dialog === 'error'}
          entityId={forcedId || data.id}
          onRetry={onDelete}
          onCancel={() => setDialog()}
        />
      )}
      {renderButton ? (
        renderButton({ onClick: handleDelete, loading })
      ) : (
        <MoreBar.Action
          useIcon={Delete}
          variant="negative"
          pending={loading}
          onClick={e => {
            e.preventDefault()
            handleDelete()
          }}
        >
          Delete
        </MoreBar.Action>
      )}
    </>
  )
}

export default DeleteOrgUnitButton
