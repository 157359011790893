import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalUpdateType } from '@src/interfaces/goals'
import { BaseWidget } from '@src/pages/Forms/GoalForm/Form/Widgets/BaseWidget'
import React from 'react'
import { RoadmapsList } from './RoadmapsList'
import { useTableReturnType } from '@src/components/Table/hooks'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { IdAndName } from '@src/interfaces'

export const RoadmapsWidget = ({
  viewMode = false,
  onSelected,
  onAdded,
  table,
  variant = 'form',
  updateType,
}: {
  viewMode?: boolean
  onAdded?: () => void
  onSelected?: (item: RoadmapInterface) => void
  table?: useTableReturnType<RoadmapInterface>
  variant?: 'preview' | 'form'
  updateType?: IdAndName<GoalUpdateType>
}) => {
  const { values } = useLapeContext<GoalsInterface>()

  const showCta = !!onAdded && !viewMode

  return (
    <BaseWidget
      variant={variant}
      title="Roadmap"
      description={
        viewMode
          ? undefined
          : '(Optional) What projects, tasks and actions are planned to deliver the goal?'
      }
      icon="Map"
      action={
        showCta
          ? {
              handler: onAdded,
              label: 'Add roadmap',
              disabled:
                values.is_company || values.is_top_level ? false : !values.object_id,
            }
          : undefined
      }
    >
      {values.roadmaps && values.roadmaps.length ? (
        <RoadmapsList
          viewMode={viewMode}
          table={table}
          onSelected={onSelected}
          updateType={updateType}
        />
      ) : null}
    </BaseWidget>
  )
}
