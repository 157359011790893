import { Box } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import React from 'react'
import { editorCss } from '@components/Editor/common'
import { CSSObject } from 'styled-components'

interface EditorViewProps {
  html: string
}

export const EditorView = ({ html }: EditorViewProps) => {
  const css: CSSObject = {
    img: {
      maxWidth: '100%',
      objectFit: 'contain',
    },
    ...editorCss,
  }

  return (
    <Box
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html, { ADD_ATTR: ['target'] }),
      }}
      width="100%"
      css={css}
    />
  )
}
