import React, { useEffect, useState } from 'react'

import { ReviewCategory } from '@src/interfaces/performance'
import {
  CommitteeResultInterface,
  ProbationCheckpoint,
} from '@src/interfaces/probationReview'
import CommitteeReviewers from '@src/pages/Forms/Overview/Committee/CommitteeReviewers'
import { committeeResultRequests } from '@src/api/probationReview'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import GenericTimelineStep from './GenericTimelineStep'

type Props = {
  checkpoint: ProbationCheckpoint
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
}

const route = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_OVERVIEW.COMMITTEE,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_OVERVIEW.COMMITTEE,
}

const TimelineDecisionCheckpoint = ({
  checkpoint,
  employeeId,
  cycleId,
  reviewCategory,
}: Props) => {
  const [decision, setDecision] = useState<CommitteeResultInterface | undefined>()

  const fetchDecision = async (id: number) => {
    const result = await committeeResultRequests.get({
      employeeId: String(employeeId),
      id: String(id),
      cycleId,
    })
    setDecision(result.data)
  }

  useEffect(() => {
    if (checkpoint.decision?.id) {
      fetchDecision(checkpoint.decision.id)
    }
  }, [checkpoint.decision?.id])

  return (
    <GenericTimelineStep
      title="Committee result"
      checkpointDate={checkpoint.checkpoint_date_time}
    >
      <CommitteeReviewers checkpoint={checkpoint} decision={decision} />
      <InternalUIKitLink
        variant="h6"
        // @ts-expect-error object works fine here, but UI kit expects string
        to={pathToUrl(route[reviewCategory], {
          employeeId,
          cycleId,
          id: cycleId,
        })}
      >
        More Details
      </InternalUIKitLink>
    </GenericTimelineStep>
  )
}

export default TimelineDecisionCheckpoint
