import { ColumnCellInterface } from '@src/interfaces/data'
import {
  TableColumnSettingsInterface,
  TableSettingsInterface,
} from '@src/interfaces/tableSettings'

export const getCellsBasedOnSettings = (
  cells: ColumnCellInterface<any>[],
  settings?: TableSettingsInterface,
  disabled?: boolean,
): TableColumnSettingsInterface => {
  const allCellsVisible = { visible: cells, hidden: [] }
  if (disabled || !settings) {
    return allCellsVisible
  }

  try {
    let visibleCells = cells
      .slice(1) // first cell always has to stay in place, so we'll prepend it later
      .filter(cell => !settings.hidden.find(title => title === cell.title))
      .sort((a, b) => {
        const indexA = settings.visible.indexOf(a.title)
        const indexB = settings.visible.indexOf(b.title)
        return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB)
      })

    // first cell should always be there
    visibleCells = [cells[0], ...visibleCells]

    return {
      visible: visibleCells,
      hidden: cells.filter(
        cell => !visibleCells.find(vCell => vCell.title === cell.title),
      ),
    }
  } catch (e) {
    console.warn(e)
    return allCellsVisible
  }
}
