import React from 'react'
import { Text } from '@revolut/ui-kit'
import QueuePosition from '@src/pages/Forms/RequisitionForm/General/QueuePosition'
import Pipeline from '@src/features/Pipeline/Pipeline'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  isOpen: boolean
  onClose: () => void
  specialisationId?: number | string
}

const PipelineSidebar = ({ isOpen, onClose, specialisationId }: Props) => {
  return (
    <SideBar title="Hiring progress" isOpen={isOpen} onClose={onClose}>
      <Text color="grey-tone-50" use="div" mb="s-16">
        Your queue position
      </Text>
      <QueuePosition />
      {specialisationId && (
        <Pipeline specialisationId={+specialisationId} chartHeight={'200px'} hideStats />
      )}
    </SideBar>
  )
}

export default PipelineSidebar
