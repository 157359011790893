import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { Communication } from './Communication'
import { communicationSettings } from '@src/api/settings'

const routes = [
  {
    title: 'Communications app settings',
    description: 'Settings related to communications ',
    path: ROUTES.SETTINGS.COMMUNICATION,
    url: ROUTES.SETTINGS.COMMUNICATION,
    canView: [
      PermissionTypes.ViewCommunicationSettings,
      PermissionTypes.ChangeCommunicationSettings,
    ],
    component: Communication,
  },
]

export const CommunicationSettings = () => {
  return <SettingsForm routes={routes} api={communicationSettings} />
}
