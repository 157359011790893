import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import ProbationEmployeesTable from '@src/pages/People/PeopleSubTabs/ProbationEmployees/ProbationEmployeesTable'

export const Common = ({
  backUrl,
  category,
}: {
  backUrl: string
  category: 'probation' | 'pip'
}) => {
  return (
    <PageWrapper>
      <PageHeader
        title={`${category === 'probation' ? 'Probation' : 'PIP'} pipeline`}
        subtitle={`Check out employees who are on ${
          category === 'probation' ? 'probation' : 'PIP'
        }`}
        backUrl={backUrl}
      />
      <ProbationEmployeesTable category={category} />
    </PageWrapper>
  )
}
