import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ProbationInterface } from '@src/interfaces/probation'
import { selectorKeys } from '@src/constants/api'

export const pipStartDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.start_date_time',
  dataPoint: 'cycle.start_date_time',
  sortKey: 'cycle__start_date_time',
  filterKey: 'cycle__start_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP start date',
}

export const pipEndDateColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.date,
  idPoint: 'cycle.end_date_time',
  dataPoint: 'cycle.end_date_time',
  sortKey: 'cycle__end_date_time',
  filterKey: 'cycle__end_date_time',
  selectorsKey: selectorKeys.none,
  title: 'PIP end date',
}

export const pipVersionColumn: ColumnInterface<ProbationInterface> = {
  type: CellTypes.text,
  idPoint: 'version',
  dataPoint: 'version',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Version',
}
