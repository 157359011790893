import React, { useEffect, useRef, useState } from 'react'
import { Box, Color, Flex, Icon, Text, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { connect } from 'lape'
import { cellWrapperRole } from '@components/TableV2/Cell'
import TableCellInput, {
  TableCellInputProps,
} from '@components/Inputs/TableCellInput/TableCellInput'
import { LockedSyncIcon } from '@src/features/LockedSyncIcon/LockedSyncIcon'

export const stateAttr = 'editable_cell_state'

export enum CellState {
  inactive = 'inactive',
  active = 'active',
  hovered = 'hovered',
}

export const WrapperCss = css`
  &[data-${stateAttr}=${CellState.active}],
  &[data-${stateAttr}=${CellState.hovered}] {
    background-color: ${Token.color.blue_5};
    box-shadow: inset 0 0 0 1px ${Token.color.accent};
  }
`

export interface EditableCellProps extends TableCellInputProps {
  suffix?: string
  testid?: string
  color?: Color
  inactiveEditableInsert?: () => React.ReactNode | undefined
  hidePencil?: boolean
  onClick?: () => void
  readonly?: boolean
  synchronised?: boolean
}

const EditableCell = ({
  suffix = '',
  testid = 'editable-cell',
  color,
  inactiveEditableInsert,
  hidePencil,
  onClick,
  ...props
}: EditableCellProps) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const [cellState, setCellState] = useState<CellState>(CellState.inactive)

  useEffect(() => {
    const cellWrapper: HTMLElement | null | undefined = containerRef.current?.closest(
      `[role=${cellWrapperRole}]`,
    )
    if (cellWrapper) {
      cellWrapper.dataset[stateAttr] = cellState
    }
  }, [cellState])

  if (cellState === CellState.active && !(props.disabled || props.synchronised)) {
    return (
      <Box
        data-testid="editable-cell"
        ref={containerRef}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => {
          if (e.key === 'Escape') {
            setCellState(CellState.inactive)
          }
        }}
      >
        <TableCellInput
          {...props}
          onBlur={val => {
            setCellState(CellState.inactive)
            props.onBlur?.(val)
          }}
        />
      </Box>
    )
  }

  const inactiveEditableInserted = inactiveEditableInsert?.()

  return (
    <Flex
      data-testid={testid}
      ref={containerRef}
      alignItems="center"
      onMouseEnter={() => (props.synchronised ? {} : setCellState(CellState.hovered))}
      onMouseLeave={() => setCellState(CellState.inactive)}
      onClick={e => {
        e.stopPropagation()
        if (props.synchronised || props.disabled) {
          return
        }
        setCellState(CellState.active)
        onClick?.()
      }}
    >
      {cellState !== CellState.active && inactiveEditableInserted ? (
        inactiveEditableInserted
      ) : (
        <Text color={props.synchronised ? Token.color.greyTone20 : color}>
          {props.value}
          {suffix}
        </Text>
      )}

      {props.synchronised ? (
        <Flex justifyContent="flex-end" width="100%" height="100%" alignItems="center">
          <LockedSyncIcon />
        </Flex>
      ) : null}

      {/* pencil icon */}
      {props.disabled || props.synchronised || hidePencil ? null : (
        <Flex justifyContent="flex-end" width="100%" height="100%" alignItems="center">
          <Icon
            name="Pencil"
            size={16}
            color={cellState === CellState.hovered ? 'primary' : Token.color.greyTone50}
          />
        </Flex>
      )}
    </Flex>
  )
}

export default connect(EditableCell)
