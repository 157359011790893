import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { engagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import {
  OnboardingChecklistContent,
  OnboardingChecklistRoute,
} from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { EngagementIntro } from './EngagementIntro'
import { EngagementSetup } from './EngagementSetup'
import { pathToUrl } from '@src/utils/router'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { Route, Switch } from 'react-router-dom'
import { EngagementForm } from '@src/pages/Forms/Engagement'

export const Engagement = () => {
  const currentTemplateId = workspaceLocalStorage.getItem(
    LOCAL_STORAGE.ONBOARDING_ENGAGEMENT_CURRENT_DRAFT_ID,
  )

  const routes: OnboardingChecklistRoute[] = [
    {
      title: 'Intro',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.INTRO,
      canView: [PermissionTypes.ViewEngagement],
      component: EngagementIntro,
      hideActions: true,
    },
    {
      title: 'Setup',
      path: ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.SETUP,
      url: pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.SETUP, {
        id: String(currentTemplateId),
      }),
      tabDisabled: !currentTemplateId,
      canView: [PermissionTypes.ViewEngagement],
      component: EngagementSetup,
      hideActions: true,
    },
  ]
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE} exact>
        <EngagementForm />
      </Route>
      <Route>
        <OnboardingChecklistContent config={engagementConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
