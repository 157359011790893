import React from 'react'
import { Widget, Item, Avatar, ActionButton, Flex, HStack, Tag } from '@revolut/ui-kit'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'
import HTMLContent from '@src/components/HTMLContent/HTMLContent'
import { useGetSlackPreferences } from '@src/api/integrations'

export const Notification = ({
  slack_notification_enabled,
  email_notification_enabled,
  system_notification_enabled,
  description,
  onEdit,
}: Pick<
  EngagementSurveyInterface,
  | 'slack_notification_enabled'
  | 'email_notification_enabled'
  | 'description'
  | 'system_notification_enabled'
> & {
  onEdit: VoidFunction
}) => {
  const { data: slackPreferences } = useGetSlackPreferences()
  const allDisabled = [
    system_notification_enabled,
    email_notification_enabled,
    slackPreferences?.enabled && slack_notification_enabled,
  ].every(notificationTypeEnabled => !notificationTypeEnabled)
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Envelope" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="engagement.notification.title"
              defaultMessage="Notification"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.notification.description"
              defaultMessage="How employees will be informed about open survey"
            />
          </Item.Description>
          <HStack space="s-8" pt="s-16">
            {system_notification_enabled && <Tag variant="status">System</Tag>}
            {email_notification_enabled && <Tag variant="status">Email</Tag>}
            {slackPreferences?.enabled && slack_notification_enabled && (
              <Tag variant="status">Slack</Tag>
            )}
            {allDisabled && <Tag variant="status">Disabled</Tag>}
          </HStack>
        </Item.Content>
        <Item.Side style={{ justifyContent: 'flex-start' }}>
          <ActionButton onClick={onEdit}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </ActionButton>
        </Item.Side>
      </Item>
      <Flex p="s-16" pt="0">
        <HTMLContent html={description} />
      </Flex>
    </Widget>
  )
}
