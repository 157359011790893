import React from 'react'
import { ReviewCategory } from '@src/interfaces/performance'
import { PerformanceViewLayout } from '@src/pages/Forms/EmployeePerformanceViewLayout/PerformanceViewLayout'
import { ProbationViewContent } from '@src/pages/Forms/ProbationViewLayout/ProbationViewContent'

export const PipViewLayout = () => {
  return (
    <PerformanceViewLayout
      category={ReviewCategory.PIP_V2}
      content={<ProbationViewContent />}
    />
  )
}
