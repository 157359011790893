import React, { ReactNode } from 'react'
import { Box, HStack, Icon, IconName, Text, Token, VStack, Widget } from '@revolut/ui-kit'

type Item = {
  icon: IconName | { url: string } | ReactNode
  title: React.ReactNode
  text: React.ReactNode
}

type Props = {
  title: React.ReactNode
  text?: React.ReactNode
  items: Item[]
}

export const OnboardingIntroTips = ({ title, text, items }: Props) => {
  return (
    <Widget p="s-16">
      <Text variant="h5" display="block" mb="s-16">
        {title}
      </Text>
      {text && (
        <Text variant="caption" display="block">
          {text}
        </Text>
      )}
      <VStack gap="s-16" mt="s-24">
        {items.map((item, i) => (
          <HStack gap="s-16" key={i}>
            {typeof item.icon === 'string' ? (
              <Icon size={23} name={item.icon as IconName} />
            ) : typeof item.icon === 'object' && item.icon && 'url' in item.icon ? (
              <Icon size={23} src={item.icon.url} variant={'image'} />
            ) : (
              item.icon
            )}
            <Box>
              <Text variant="primary">{item.title}</Text>
              <Text color={Token.color.greyTone50} display="block" mt="s-8">
                {item.text}
              </Text>
            </Box>
          </HStack>
        ))}
      </VStack>
    </Widget>
  )
}
