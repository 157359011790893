import React from 'react'

import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { notReachable } from '@src/utils/notReachable'

import { PublishStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/PublishStats'
import { CalibrationStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/CalibrationStats'
import { ReviewStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/ReviewStats'
import { GoalsStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/GoalsStats'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
}

export const PopupBody = ({ cycle, stage }: Props) => {
  switch (stage) {
    case ReviewCycleStage.TeamGoals:
      return <GoalsStats cycle={cycle} goalsOwner={GoalsOwner.Team} />
    case ReviewCycleStage.DepartmentGoals:
      return <GoalsStats cycle={cycle} goalsOwner={GoalsOwner.Department} />
    case ReviewCycleStage.Review:
      return <ReviewStats cycle={cycle} />
    case ReviewCycleStage.Calibration:
      return <CalibrationStats cycle={cycle} />
    case ReviewCycleStage.ManagersPublish:
      return <PublishStats cycle={cycle} stage={stage} />
    case ReviewCycleStage.EmployeesPublish:
      return <PublishStats cycle={cycle} stage={stage} />
    case ReviewCycleStage.Completed:
      return null
    default:
      return notReachable(stage)
  }
}
