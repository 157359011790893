import React, { useState } from 'react'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import { Box, BoxProps, Bullet, TransitionCollapse } from '@revolut/ui-kit'
import ShevronToggle from '@components/Atoms/ShevronToggle'

export interface CollapsibleStepperSectionProps
  extends Omit<BoxProps, 'title' | 'variant'> {
  title: React.ReactNode
  defaultOpen?: boolean
  subtitle?: React.ReactNode
  children?: React.ReactNode
}

const CollapsibleStepperSection = ({
  title,
  subtitle,
  children,
  defaultOpen = false,
  ...props
}: CollapsibleStepperSectionProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)

  return (
    <>
      <NewStepperTitle
        title={title}
        subtitle={subtitle}
        {...props}
        type={NewStepperTitleType.default}
        bullet={
          <Bullet mr="0.5em" use="span">
            <ShevronToggle isOpen={isOpen} />
          </Bullet>
        }
        role="button"
        onClick={() => setIsOpen(!isOpen)}
        style={{ cursor: 'pointer' }}
      />
      <TransitionCollapse in={isOpen}>
        <Box>{children}</Box>
      </TransitionCollapse>
    </>
  )
}

export default CollapsibleStepperSection
