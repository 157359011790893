import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { List } from '@src/pages/Settings/Candidates/List'
import { General } from '@src/pages/Settings/Candidates/General'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { OffersSettings } from '@src/pages/Settings/Candidates/Offers'
import { RecruitmentGroups } from '@src/pages/Settings/Candidates/RecruitmentGroups'
import { ScreenCallScorecards } from '@src/pages/Settings/Candidates/ScreenCallScorecards/ScreenCallScorecards'
import { DataRetentionSettingsPage } from './DataRetentionSettings/DataRetentionSettingsTable'

export const CandidatesSettings = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.LIST}>
        <List />
      </Route>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.GENERAL}>
        <General />
      </Route>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.DATA_RETENTION_SETTINGS}>
        <DataRetentionSettingsPage />
      </Route>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.OFFERS}>
        <OffersSettings />
      </Route>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.RECRUITMENT_GROUPS}>
        <RecruitmentGroups />
      </Route>
      <Route exact path={ROUTES.SETTINGS.CANDIDATES.SCREEN_CALL_SCORECARDS}>
        <ScreenCallScorecards />
      </Route>
      <Route>
        <NoAccessError />
      </Route>
    </Switch>
  )
}
