import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  DetailsCell,
  Header,
  InputGroup,
  Link,
  Popup,
  Text,
  Token,
} from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { getonboardingV2EmailSuggestions } from '@src/api/onboardingV2'
import { SectionOptions } from '@src/interfaces/customFields'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import { OnboardingBasicInfo } from '@src/interfaces/onboardingV2'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TabProps } from '../common/interface'
import { BackButton, NextButton } from '../components/Buttons'
import { PageHeader } from '../components/PageHeader'

export const BasicInfo = ({
  dynamicGroups,
  isCompleted,
  isPreview,
  nextRoute,
  onboardingSettings,
  onComplete,
  prevRoute,
}: TabProps) => {
  const formValidator = useSafeFormValidator()
  const { onboardingId } = useParams<{ onboardingId: string }>()
  const { values, submit } = useLapeContext<OnboardingBasicInfo>()

  const contactHREmail = onboardingSettings?.contact_hr_email
  const contactHRLink = `mailto:${contactHREmail}`
  const enableEmailSelector = !!onboardingSettings?.enable_email_selector

  const [emailConfirmationVisible, setEmailConfirmationVisible] = useState(false)
  const [preferredEmails, setPreferredEmails] = useState<string[]>([])

  useEffect(() => {
    if (!values.id && values.first_name && values.last_name) {
      values.display_name = `${values.first_name} ${values.last_name}`
    }
  }, [values.first_name, values.last_name])

  useEffect(() => {
    if (isPreview) {
      return
    }
    if (!isCompleted && enableEmailSelector) {
      getonboardingV2EmailSuggestions(onboardingId).then(response =>
        setPreferredEmails(response.data),
      )
    }
  }, [enableEmailSelector, isCompleted, isPreview])

  const navigateToPrevStep = () => {
    if (prevRoute) {
      navigateTo(prevRoute)
    }
  }

  const navigateToNextStep = () => {
    if (nextRoute) {
      navigateTo(nextRoute)
      onComplete?.()
    }
  }

  const onSubmit = formValidator.validate(async () => {
    const result = await submit()
    navigateToNextStep()
    return result
  })

  const onNextButtonClick = () => {
    if (!isCompleted && enableEmailSelector && values.email) {
      setEmailConfirmationVisible(true)
    } else if (isPreview && nextRoute) {
      navigateTo(nextRoute)
    } else {
      onSubmit()
    }
  }

  return (
    <>
      <PageBody pb="s-24">
        <AutoStepper>
          <PageHeader email={contactHREmail} title="Basic" />

          <NewStepperTitle title="Legal names" />
          <NewStepperSectionCustomFields
            dynamicGroups={dynamicGroups}
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
            useExternalRequests
          >
            <InputGroup>
              <LapeNewInput
                label="Legal first name"
                name="first_name"
                description="Your first name as it appears on your identification documents"
                hideOptional
              />
              <LapeNewInput
                label="Legal middle name"
                name="middle_name"
                description="Your middle name as it appears on your identification documents"
              />
              <LapeNewInput
                label="Legal last name"
                name="last_name"
                description="Your last name as it appears on your identification documents"
                hideOptional
              />
            </InputGroup>
          </NewStepperSectionCustomFields>

          <NewStepperTitle title="Preferred name" />
          <LapeNewInput
            label="Preferred name"
            name="display_name"
            description="The name you prefer to be called. E. g. your legal first name is Jonathan, but your friends call you Jon"
          />

          {enableEmailSelector ? (
            <>
              <NewStepperTitle
                title="Company email"
                subtitle={
                  isCompleted || !contactHREmail ? null : (
                    <>
                      Please choose the preferred email address from the list of available
                      ones below. If you spot any major problem with the proposed options,
                      please write to{' '}
                      <Link href={contactHRLink} target="_blank">
                        {contactHREmail}
                      </Link>
                    </>
                  )
                }
              />
              {isCompleted ? (
                <DetailsCell>
                  <DetailsCell.Title>Chosen email address</DetailsCell.Title>
                  <DetailsCell.Content>{values.email}</DetailsCell.Content>
                </DetailsCell>
              ) : (
                <LapeNewRadioButtons
                  name="email"
                  options={preferredEmails.map(email => ({
                    value: email,
                    label: email,
                  }))}
                  variant="group-items"
                />
              )}
            </>
          ) : null}
        </AutoStepper>
      </PageBody>

      <PageActions alignSelf="center" maxWidthMd={Token.breakpoint.sm}>
        <BackButton onClick={navigateToPrevStep} />
        <NextButton afterSubmit={navigateToNextStep} onClick={onNextButtonClick} />
      </PageActions>

      <Popup
        open={emailConfirmationVisible}
        onClose={() => setEmailConfirmationVisible(false)}
        variant="bottom-sheet"
      >
        <Header displayMode="inline" variant="bottom-sheet">
          <Header.Actions>
            <ExclamationTriangle color={Token.color.blue} size={40} />
          </Header.Actions>
          <Text fontSize="h5">Please confirm your email address preference</Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              Email chosen:
              <br />
              <Text fontWeight={500}>{values.email}</Text>
              <br />
              <br />
              Important! Once you have confirmed your email address, you will not be able
              to change it
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setEmailConfirmationVisible(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setEmailConfirmationVisible(false)
              onSubmit()
            }}
            elevated
          >
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
