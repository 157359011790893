import React from 'react'
import { Box } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import Stat from '@components/Stat/Stat'
import {
  getRequisitionsManagerOwnership,
  requisitionManagerOwnershipTransfer,
} from '@src/api/ownership'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'
import {
  requisitionHeadcountColumn,
  requisitionIdColumn,
  requisitionStatusColumn,
  createRequisitionTitleColumn,
} from '@src/constants/columns/requisition'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const RequisitionsManagerOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    requisitionManagerOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        line_manager: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getRequisitionsManagerOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Assign new department owners"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Requisitions" val={stats?.line_manager_requisition_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                {
                  ...requisitionIdColumn,
                  width: 90,
                },
                {
                  ...createRequisitionTitleColumn(false),
                  width: 200,
                },
                {
                  ...requisitionHeadcountColumn,
                  width: 100,
                },
                {
                  ...seniorityNameRequisitionsColumn,
                  width: 150,
                },
                {
                  ...locationNameRequisitionColumn,
                  width: 150,
                },
                {
                  ...requisitionStatusColumn,
                  width: 100,
                },
              ]}
              noDataMessage="Requisitions manager ownership will appear here"
              ownerColTitle="New Line Manager"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default RequisitionsManagerOwnership
