import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import SettingsButtons from '@src/features/SettingsButtons'
import { Group, MoreBar, VStack, chain } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Pencil } from '@revolut/icons'
import { FIELD_DETAILS } from './General'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GDPRPolicy } from '@src/interfaces/dataRetentionSettings'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { EntityPermissions } from '@src/store/auth/types'

const View = () => {
  const { values, initialValues } = useLapeContext<GDPRPolicy>()

  const backUrl = ROUTES.APPS.DATA_RETENTION.EMPLOYMENT
  const canEdit = values.field_options?.actions?.includes(EntityPermissions.Change)

  return (
    <>
      <PageHeader
        pb="s-8"
        title={values?.country?.name || 'Policy'}
        subtitle={chain('Data retention policy', 'Employment')}
        backUrl={backUrl}
      >
        {canEdit ? (
          <SettingsButtons>
            <MoreBar.Action
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.DATA_RETENTION.GENERAL, {
                    id: initialValues.id,
                    countryId: initialValues?.country?.id,
                  }),
                )
              }
              useIcon={Pencil}
            >
              Edit
            </MoreBar.Action>
          </SettingsButtons>
        ) : null}
      </PageHeader>
      <PageBody>
        <VStack gap="s-8" mt="s-8">
          <FormPreview data={initialValues}>
            <Group>
              <FormPreview.Item title="Policy owner" field="owner.name" />
            </Group>
          </FormPreview>
          {values?.sections.map((section, index) => (
            <FormPreview key={section.id} data={initialValues}>
              <Group>
                <FormPreview.Item
                  title="Data points"
                  insert={() =>
                    section?.category?.id ? FIELD_DETAILS[section.category.id] : '-'
                  }
                />
                <FormPreview.Item
                  title="Triggering event"
                  field={`sections[${index}].trigger_event.name`}
                />
                <FormPreview.Item
                  title="Retention period"
                  field={`sections[${index}].retention_period`}
                  insert={() => `${section.retention_period} ${section.time_unit?.name}`}
                />
              </Group>
            </FormPreview>
          ))}
        </VStack>
      </PageBody>
    </>
  )
}

export default View
