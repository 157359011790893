import { useCallback, useEffect, useState } from 'react'
import { assert } from '@src/utils/assert'

const defaultFrom = 0
const defaultTo = 1
const defaultStep = 0.01
const defaultDelay = 150
const defaultVelocity = 5

interface SmoothProgress {
  value: number
  isRunning: boolean
  start: VoidFunction
  stop: VoidFunction
  reset: VoidFunction
}

interface SmoothProgressOptions {
  from: number
  to: number
  step: number
  delay: number
  velocity: number
}

const defaultOptions = {
  from: defaultFrom,
  to: defaultTo,
  step: defaultStep,
  delay: defaultDelay,
  velocity: defaultVelocity,
}

export const useSmoothProgress = (
  value: number,
  options: SmoothProgressOptions = defaultOptions,
): SmoothProgress => {
  assertOptions(options)
  const { from, to, step, delay, velocity } = options

  const [started, setStarted] = useState(false)
  const [smoothValue, setSmoothValue] = useState(from)
  const [threshold, setThreshold] = useState(Number(((to - from) / velocity).toFixed(2)))

  useEffect(() => {
    if (value >= to) {
      setThreshold(to)
    } else {
      setThreshold(Number((value + (to - value) / velocity).toFixed(2)))
    }
  }, [value, to])

  useEffect(() => {
    if (!started) {
      return undefined
    }

    if (value > smoothValue && value < to) {
      setSmoothValue(Number(value.toFixed(2)))
    }

    if (smoothValue >= to) {
      stop()
      return undefined
    }

    if (smoothValue >= threshold) {
      return undefined
    }

    const timerId = window.setTimeout(() => {
      setSmoothValue(prevState => Number((prevState + step).toFixed(2)))
    }, delay)

    return () => {
      clearTimeout(timerId)
    }
  }, [value, smoothValue, to, step, delay, threshold, started])

  const start = useCallback(() => {
    !started && setStarted(true)
  }, [started])

  const stop = useCallback(() => {
    started && setStarted(false)
  }, [started])

  const reset = useCallback(() => {
    setStarted(false)
    setSmoothValue(from)
  }, [from])

  return {
    value: smoothValue,
    isRunning: started,
    start,
    stop,
    reset,
  }
}

const assertOptions = ({ from, to, velocity }: SmoothProgressOptions) => {
  assert(from < to, 'Negative progression is not supported')
  assert(velocity > 0, 'Velocity cannot be negative')
}
