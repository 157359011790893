import {
  Check,
  Coins,
  Document,
  ExclamationMark,
  LinkExternal,
  Present,
  Resort,
  Time,
} from '@revolut/icons'
import {
  Box,
  Group,
  VStack,
  HStack,
  Item,
  Avatar,
  ActionButton,
  StatusWidget,
  chain,
  FilterButton,
  Text,
  Color,
} from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import {
  Attendance,
  Benefit,
  Bonus,
  Contract,
  PayCycleDetailedReportInterface,
  PayCyclePersonalReportInterface,
  Termination,
  TimeOff,
} from '@src/interfaces/payroll'
import { formatDate, formatMoneyWithCode } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import React, { useState, createContext, useMemo } from 'react'
import { InternalLink } from '@components/InternalLink/InternalLink'
import {
  IndentedPayCycleItem,
  PayCycleItem,
  PayCycleRichItem,
  RelevancyType,
} from './PaycycleItem/PaycycleItem'
import { PaycycleTitle } from './PaycycleItem/PaycycleTitle'
import { useParams } from 'react-router-dom'
import CommentsActionButton from '@src/features/Comments/CommentsActionButton'
import { getPayCycleReportsCommentsAPI } from '@src/api/payroll'
import { isEmpty } from 'lodash'
import pluralize from 'pluralize'
import { useGetSectionCustomFields } from '@src/api/customFields'
import { SectionOptions } from '@src/interfaces/customFields'
import Icon from '@src/components/Icon/Icon'
import { getCFValue } from '../FormPreview/FormPreview'
import HideIfCommercial from '../HideIfCommercial/HideIfCommercial'

type Props = {
  data: PayCycleDetailedReportInterface
  showNewTabButton?: boolean
}

export type ParentType =
  | PayCyclePersonalReportInterface
  | Contract
  | Benefit
  | TimeOff
  | Termination
  | Bonus
  | Attendance
  | null

export type Field = keyof (PayCyclePersonalReportInterface &
  Contract &
  Benefit &
  TimeOff &
  Termination &
  Bonus &
  Attendance)

export type Section = keyof PayCycleDetailedReportInterface

export type FinalCountIssuesType = {
  count: number
  issues_level: 'critical' | 'warning' | null
}

type FinalCountType = {
  [key: string]: {
    issues: FinalCountIssuesType
    changes: number
  }
  root: {
    issues: FinalCountIssuesType
    changes: number
  }
}

export const determineParentType = (parent: ParentType) => {
  const propertyTypeMap = [
    { key: 'full_name', type: 'personal_report', itemId: 'id' as keyof typeof parent },
    {
      key: 'start_date',
      type: 'contract_reports',
      itemId: 'contract_id' as keyof typeof parent,
    },
    {
      key: 'selected_package_name',
      type: 'benefits_reports',
      itemId: 'template_id' as keyof typeof parent,
    },
    {
      key: 'time_off_policy_name',
      type: 'time_off_reports',
      itemId: 'time_off_request_id' as keyof typeof parent,
    },
    {
      key: 'termination_date',
      type: 'termination_report',
      itemId: 'termination_id' as keyof typeof parent,
    },
    {
      key: 'bonus_type',
      type: 'bonus_reports',
      itemId: 'bonus_id' as keyof typeof parent,
    },
    {
      key: 'attendance_date',
      type: 'attendance',
      itemId: 'attendance_id' as keyof typeof parent,
    },
  ]

  const foundPropertyType = propertyTypeMap.find(({ key }) =>
    Object.prototype.hasOwnProperty.call(parent, key),
  )

  return foundPropertyType || propertyTypeMap[0]
}

export const PaycycleContext = createContext<ParentType>(null)

export const PersonalDetailsCustomFields = ({
  data,
  showRelevantOnly,
}: {
  data: PayCycleDetailedReportInterface
  showRelevantOnly: RelevancyType
}) => {
  const { personal_report } = data
  const customFieldsKeys = Object.keys(personal_report?.custom_fields || {})

  const { data: customFields } = useGetSectionCustomFields(
    SectionOptions.EmployeeProfile,
    [],
    customFieldsKeys,
  )

  if (!data || !personal_report || !customFields) {
    return null
  }

  const filteredCustomFields = customFields
    ? customFields.results.filter(field => customFieldsKeys.includes(field.uid))
    : []
  const customFieldsLabels = filteredCustomFields.reduce(
    (acc: { [key: string]: string }, item) => {
      acc[item.uid] = item.name
      return acc
    },
    {},
  )

  const getCustomFieldByUid = (uid: string) =>
    filteredCustomFields.find(cf => cf.uid === uid)

  const field_changes = personal_report.field_changes
  const field_issues = personal_report.field_issues

  const getRelevancy = (customFieldKey: string) => {
    if (Object.keys(field_issues).includes(customFieldKey)) {
      return 'issue'
    }
    if (Object.keys(field_changes).includes(customFieldKey)) {
      return 'change'
    }
    return null
  }

  const filterFunction = (customFieldKey: string) => {
    switch (showRelevantOnly) {
      case 'all':
        return (
          Object.keys(field_changes).includes(customFieldKey) ||
          Object.keys(field_issues).includes(customFieldKey)
        )
      case 'change':
        return Object.keys(field_changes).includes(customFieldKey)
      case 'issue':
        return Object.keys(field_issues).includes(customFieldKey)
      default:
        return true
    }
  }

  return (
    <>
      {customFieldsKeys.filter(filterFunction).map(key => {
        const cf = getCustomFieldByUid(key)
        return (
          <PayCycleItem
            key={key}
            data={data}
            showRelevantOnly={null}
            title={
              <HStack gap="s-8" align="center">
                {customFieldsLabels[key]} <Icon type="CF" />
              </HStack>
            }
            field="id"
            insert={
              <Text>
                {!!cf && getCFValue(personal_report.custom_fields[key], cf.input_type.id)}
              </Text>
            }
            forceRelevancy={getRelevancy(key)}
          />
        )
      })}
    </>
  )
}

export const PaycyclePreview = ({ data, showNewTabButton }: Props) => {
  const { id } = useParams<{ id: string }>()

  const {
    id: report_id,
    personal_report,
    contract_reports,
    benefits_reports,
    time_off_reports,
    termination_report,
    bonus_reports,
    attendance_reports,
  } = data
  const [showIssues, setShowIssues] = useState(false)
  const [showChanges, setShowChanges] = useState(false)

  const showRelevantOnly = useMemo<RelevancyType>(() => {
    if (showIssues && showChanges) {
      return 'all'
    }
    if (showIssues) {
      return 'issue'
    }
    if (showChanges) {
      return 'change'
    }
    return null
  }, [showIssues, showChanges])

  const toggleRelevant = (toggle: 'issue' | 'change') => {
    if (toggle === 'issue') {
      setShowIssues(prev => !prev)
    }
    if (toggle === 'change') {
      setShowChanges(prev => !prev)
    }
  }

  const getCount = (
    finalCount: FinalCountType,
    section: Section,
  ): { issues: FinalCountIssuesType; changes: number } => {
    const issues: FinalCountIssuesType = {
      count: 0,
      issues_level: null,
    }
    let changes = 0

    const countIssuesAndChanges = (item: ParentType) => {
      if (!item) {
        return
      }
      if ('field_issues' in item && 'field_changes' in item) {
        const issueKeys = Object.keys(item.field_issues).length
        const changeKeys = Object.keys(item.field_changes).length

        issues.issues_level = item.issues_level
        issues.count += section === 'personal_report' ? issueKeys : issueKeys ? 1 : 0
        changes += section === 'personal_report' ? changeKeys : changeKeys ? 1 : 0

        finalCount.root.issues.count +=
          section === 'personal_report' ? issueKeys : issueKeys ? 1 : 0
        finalCount.root.changes +=
          section === 'personal_report' ? changeKeys : changeKeys ? 1 : 0
      }
    }

    const iterateOverSection = (sectionData: any) => {
      if (Array.isArray(sectionData)) {
        sectionData.forEach((item: ParentType) => countIssuesAndChanges(item))
      } else if (sectionData && typeof sectionData === 'object') {
        countIssuesAndChanges(sectionData as ParentType)
      }
    }

    iterateOverSection(data[section])

    return { issues, changes }
  }

  const doNotHide = (item: {
    field_changes: Partial<Record<Field, string>>
    field_issues: Partial<Record<Field, string>>
  }) => {
    if (showRelevantOnly === 'all') {
      return !isEmpty(item.field_changes) || !isEmpty(item.field_issues)
    }
    if (showRelevantOnly === 'change') {
      return !isEmpty(item.field_changes)
    }
    if (showRelevantOnly === 'issue') {
      return !isEmpty(item.field_issues)
    }
    return true
  }

  const count = useMemo(() => {
    const finalCount: FinalCountType = {
      root: { issues: { count: 0, issues_level: null }, changes: 0 },
    }

    Object.keys(data).forEach(key => {
      finalCount[key] = getCount(finalCount, key as Section)
    })

    return finalCount
  }, [data])

  return (
    <Box mt="s-16">
      <VStack space="s-16">
        <HStack gap="s-16">
          {showNewTabButton && (
            <ActionButton
              useIcon={LinkExternal}
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.PREVIEW, {
                id,
                employeeId: data.employee.id,
              })}
              target="_blank"
            >
              Open in new tab
            </ActionButton>
          )}
          <CommentsActionButton api={getPayCycleReportsCommentsAPI(id, report_id)} />
        </HStack>
        {!!count.root.issues.count && (
          <Group>
            <Item>
              <Item.Avatar>
                <Avatar
                  bg="light-orange-10"
                  useIcon={<ExclamationMark color="orange" size={32} />}
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Some details are missing or pending approval</Item.Title>
                <Item.Description>
                  Please resolve these issues before the payroll cutoff date
                </Item.Description>
                {!!(count.root.issues.count + count.root.changes) && (
                  <HStack gap="s-6" mt="s-6">
                    {!!count.root.issues.count && (
                      <FilterButton
                        active={showIssues}
                        onClick={() => toggleRelevant('issue')}
                      >
                        Show issues ({count.root.issues.count})
                      </FilterButton>
                    )}
                    {!!count.root.changes && (
                      <FilterButton
                        active={showChanges}
                        onClick={() => toggleRelevant('change')}
                      >
                        Show changes ({count.root.changes})
                      </FilterButton>
                    )}
                  </HStack>
                )}
              </Item.Content>
            </Item>
          </Group>
        )}
        {!count.root.issues.count && (
          <Group>
            <Item>
              <Item.Avatar>
                <Avatar bg="light-green-10" useIcon={<Check color="green" size={32} />} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Employee is ready for payroll</Item.Title>
              </Item.Content>
              {!!count.root.changes && (
                <Item.Side>
                  <FilterButton
                    active={showChanges}
                    onClick={() => toggleRelevant('change')}
                  >
                    Show changes ({count.root.changes})
                  </FilterButton>
                </Item.Side>
              )}
            </Item>
          </Group>
        )}

        {showRelevantOnly && !(count.root.issues.count + count.root.changes) && (
          <StatusWidget>
            <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D021.png" />
            <StatusWidget.Title>
              No changes or issues in this pay cycle
            </StatusWidget.Title>
          </StatusWidget>
        )}
        <Box>
          <PaycycleTitle
            count={count.personal_report}
            showRelevantOnly={showRelevantOnly}
            title="Personal details"
          />
          <Group title="Personal details">
            <PaycycleContext.Provider value={personal_report}>
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Full name"
                field="full_name"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Birth date"
                field="birth_date"
                type="date"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Gender"
                field="legal_sex"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Address line 1"
                field="address_line_1"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Address line 2"
                field="address_line_2"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Address line 3"
                field="address_line_3"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Country"
                field="country_name"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Bank Currency ID"
                field="bank_currency_id"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Bank Name"
                field="bank_name"
              />
              <PayCycleItem
                data={data}
                showRelevantOnly={showRelevantOnly}
                title="Account Name"
                field="account_name"
              />
              <PersonalDetailsCustomFields
                data={data}
                showRelevantOnly={showRelevantOnly}
              />
            </PaycycleContext.Provider>
          </Group>
        </Box>
        {termination_report && (
          <Box>
            <PaycycleTitle showRelevantOnly={showRelevantOnly} title="Termination" />
            <Group title="Termination Report">
              <PaycycleContext.Provider value={termination_report}>
                <PayCycleItem
                  data={data}
                  showRelevantOnly={showRelevantOnly}
                  title="Termination date"
                  field="termination_date_time"
                  type="date"
                />
                <PayCycleItem
                  data={data}
                  showRelevantOnly={showRelevantOnly}
                  title="Additional Payment Amount"
                  field="additional_payment_amount"
                  insert={formatMoneyWithCode(
                    termination_report.additional_payment_amount,
                    termination_report.additional_payment_currency_iso_code,
                  )}
                />
                <PayCycleItem
                  data={data}
                  showRelevantOnly={showRelevantOnly}
                  title="Additional Payment Type"
                  field="additional_payment_type"
                />
              </PaycycleContext.Provider>
            </Group>
          </Box>
        )}

        <VStack space="s-8">
          <PaycycleTitle
            count={count.contract_reports}
            showRelevantOnly={showRelevantOnly}
            title="Contracts"
          />
          {!!contract_reports?.length &&
            contract_reports.map(
              contract =>
                doNotHide(contract) && (
                  <Group key={contract?.id} title={`Contract ${contract?.id}`}>
                    <PaycycleContext.Provider value={contract}>
                      <PayCycleRichItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title={chain(
                          contract.specialisation_name,
                          contract.seniority_name,
                        )}
                        subtitle="Contract"
                        status={contract.contract_status}
                        useIcon={Document}
                        field={[
                          'contract_status',
                          'specialisation_name',
                          'seniority_name',
                        ]}
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Start date"
                        field="start_date"
                        type="date"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="End date"
                        field="end_date"
                        type="date"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Location"
                        field="location_name"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Entity"
                        field="entity_name"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Annual salary"
                        field="annual_salary"
                        insert={formatMoneyWithCode(
                          contract.annual_salary,
                          contract.salary_currency_iso_code,
                        )}
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Currency"
                        field="salary_currency_iso_code"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Specialisation"
                        field="specialisation_name"
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Seniority"
                        field="seniority_name"
                      />
                    </PaycycleContext.Provider>
                  </Group>
                ),
            )}
        </VStack>
        <HideIfCommercial>
          <VStack space="s-8">
            <PaycycleTitle
              count={count.benefits_reports}
              showRelevantOnly={showRelevantOnly}
              title="Benefits"
            />
            {!!benefits_reports?.length &&
              benefits_reports.map(
                benefit =>
                  doNotHide(benefit) && (
                    <Group key={benefit?.id} title={`Benefit ${benefit?.id}`}>
                      <PaycycleContext.Provider value={benefit}>
                        <PayCycleRichItem
                          data={data}
                          showRelevantOnly={showRelevantOnly}
                          title={benefit.template_name}
                          subtitle={benefit.selected_package_name}
                          status={benefit.approval_status}
                          useIcon={Present}
                          field={[
                            'approval_status',
                            'template_name',
                            'selected_package_name',
                          ]}
                        />
                        <IndentedPayCycleItem
                          data={data}
                          showRelevantOnly={showRelevantOnly}
                          title="Selected package name"
                          field="selected_package_name"
                        />
                        <IndentedPayCycleItem
                          data={data}
                          showRelevantOnly={showRelevantOnly}
                          title="Employer contribution"
                          field="employee_contribution"
                          insert={formatMoneyWithCode(
                            benefit.employee_contribution,
                            benefit.contribution_currency_iso_code,
                          )}
                        />
                      </PaycycleContext.Provider>
                    </Group>
                  ),
              )}
          </VStack>
        </HideIfCommercial>
        <VStack space="s-8">
          <PaycycleTitle
            count={count.time_off_reports}
            showRelevantOnly={showRelevantOnly}
            title="Time Off"
          />
          {!!time_off_reports?.length &&
            time_off_reports.map(
              timeOff =>
                doNotHide(timeOff) && (
                  <Group key={timeOff?.id} title={`Time Off ${timeOff?.id}`}>
                    <PaycycleContext.Provider value={timeOff}>
                      <PayCycleRichItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title={timeOff.time_off_policy_name}
                        subtitle={`${formatDate(timeOff.from_date_time)} - ${formatDate(
                          timeOff.to_date_time,
                        )} (${pluralize('day', timeOff.duration, true)})`}
                        status={timeOff.approval_status}
                        useIcon={Resort}
                        field={[
                          'approval_status',
                          'time_off_policy_name',
                          'time_off_policy_category_name',
                          'duration',
                        ]}
                      />
                    </PaycycleContext.Provider>
                  </Group>
                ),
            )}
        </VStack>
        <VStack space="s-8">
          <PaycycleTitle
            count={count.bonus_reports}
            showRelevantOnly={showRelevantOnly}
            title="Bonuses"
          />
          {!!bonus_reports?.length &&
            bonus_reports.map(
              bonus =>
                doNotHide(bonus) && (
                  <Group key={bonus.id} title={`Bonus ${bonus.bonus_id}`}>
                    <PaycycleContext.Provider value={bonus}>
                      <PayCycleRichItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title={bonus.bonus_type}
                        subtitle={formatMoneyWithCode(
                          bonus.bonus_amount,
                          bonus.bonus_currency_iso_code,
                        )}
                        status="approved"
                        useIcon={Coins}
                        field={['bonus_type', 'bonus_amount', 'bonus_currency_iso_code']}
                      />
                    </PaycycleContext.Provider>
                  </Group>
                ),
            )}
        </VStack>
        <VStack space="s-8">
          <PaycycleTitle
            count={count.attendance_reports}
            showRelevantOnly={showRelevantOnly}
            title="Attendance"
          />
          {!!attendance_reports?.length &&
            attendance_reports.map(
              attendance =>
                doNotHide(attendance) && (
                  <Group title={`Attendance ${attendance.id}`} key={attendance.id}>
                    <PaycycleContext.Provider value={attendance}>
                      <PayCycleRichItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title={`${formatDate(attendance.date)} - Shift #${
                          attendance.shift_id
                        }`}
                        subtitle={`${attendance.start_time} - ${attendance.end_time} (${attendance.duration_hours}h ${attendance.duration_minutes}m)`}
                        status={attendance.approval_status}
                        useIcon={Time}
                        field={[
                          'approval_status',
                          'date',
                          'shift_id',
                          'start_time',
                          'end_time',
                          'duration_hours',
                          'duration_minutes',
                        ]}
                      />
                      <IndentedPayCycleItem
                        data={data}
                        showRelevantOnly={showRelevantOnly}
                        title="Preferred Compensation"
                        field="preferred_compensation"
                      />
                    </PaycycleContext.Provider>
                  </Group>
                ),
            )}
        </VStack>
        {!!data.payments && (
          <VStack space="s-8">
            <Text use="h6" color={Color.GREY_TONE_50} fontWeight="bold" my="s-12">
              Payments
            </Text>
            <Item>
              <Item.Avatar>
                <Avatar backgroundColor={Color.GREY_10} useIcon={Coins} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>
                  Gross:{' '}
                  {formatMoneyWithCode(
                    data.payments.gross_pay,
                    data.payments.currency.iso_code,
                  )}
                </Item.Title>
                <Item.Description>
                  {chain(
                    `Net: ${formatMoneyWithCode(
                      data.payments.net_pay,
                      data.payments.currency.iso_code,
                    )}`,
                    `Contributions: ${formatMoneyWithCode(
                      data.payments.contribution,
                      data.payments.currency.iso_code,
                    )}`,
                    `Deductions: ${formatMoneyWithCode(
                      data.payments.deductions,
                      data.payments.currency.iso_code,
                    )}`,
                  )}
                </Item.Description>
              </Item.Content>
            </Item>
          </VStack>
        )}
      </VStack>
    </Box>
  )
}
