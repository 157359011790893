import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ActionButton } from '@revolut/ui-kit'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import { ROUTES } from '@src/constants/routes'
import { SetDateButton } from './SetDateButton'
import { formatDate } from '@src/utils/format'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const getApproverActionString = (approval?: ResignationApprovalInterface) => {
  if (!approval) {
    return ''
  }
  const approvalDateTime = approval?.approval_date_time
    ? `, ${formatDate(approval.approval_date_time)}`
    : ''
  return `Performed by ${approval?.approver?.full_name}${approvalDateTime}`
}

type InfoDataFromResignation = {
  title: string
  text: string | React.ReactNode
  actions?: React.ReactNode
}
export const getInfoDataFromResignation = ({
  resignation,
  approval,
  onRequestNoticeClick,
  onSubmitResignationDate,
}: {
  resignation: EmployeeResignationInterface
  approval?: ResignationApprovalInterface
  onRequestNoticeClick: () => void
  onSubmitResignationDate: (date: string) => Promise<void>
}): InfoDataFromResignation | undefined => {
  if (!resignation.is_regrettable) {
    switch (resignation.status.id) {
      case 'pending_review':
        return {
          title: 'Notice wasn’t submitted by employee',
          text:
            'To start the notice period, notice along with resignation letter must be submitted by employee. ' +
            'If employee termination is expected after the resignation was triggered, please request notice from them.',
          actions: (
            <ActionButton useIcon="Document" onClick={onRequestNoticeClick}>
              Request notice
            </ActionButton>
          ),
        }
      case 'pending_notice_file': {
        if (approval?.approval_status?.id === 'pending') {
          return {
            title: 'Notice requested from employee',
            text: getApproverActionString(approval),
          }
        }
        if (approval?.approval_status?.id === 'rejected') {
          return {
            title:
              'Notice was rejected. Employee got notified to upload new resignation letter',
            text: getApproverActionString(approval),
          }
        }
        return undefined
      }

      case 'notice_file_pending_review':
        return {
          title: 'Notice was submitted by employee',
          text:
            'To confirm employee’s resignation, please review the resignation letter and record the resignation effective date. ' +
            'Once that’s done resignation status will be changed to "Completed".',
          actions: (
            <ActionButton
              useIcon="Document"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD_LETTER_APPROVE, {
                id: resignation.id,
                employeeId: resignation.employee.id,
              })}
            >
              Review resignation letter
            </ActionButton>
          ),
        }
      case 'completed':
        return {
          title: 'Employee is ready for termination',
          text: (
            <>
              Effective resignation date: {formatDate(resignation.resignation_date)}
              <br />
              Notice period: {resignation.notice_period}.
              <br />
              Recommended exit date:{' '}
              {formatDate(resignation.recommended_resignation_date)}. Otherwise consult
              with employee’s LM.
            </>
          ),
          actions: <SetDateButton onSubmit={onSubmitResignationDate} />,
        }
      case 'cancelled':
        return {
          title: 'Employee resignation was cancelled',
          text: getApproverActionString(approval),
        }
      default:
        return undefined
    }
  } else {
    switch (resignation.status.id) {
      case 'pending_review':
        return {
          title: 'Notice wasn’t submitted by employee & retention process was triggered',
          text:
            'To start the notice period, notice along with resignation letter must be submitted by employee. ' +
            'If employee termination is expected after the resignation was triggered, please request notice from them. ' +
            'Please note that these is ongoing retention process for this employee.',
          actions: (
            <>
              <ActionButton useIcon="Document" onClick={onRequestNoticeClick}>
                Request notice
              </ActionButton>
              <ActionButton useIcon="ArrowThinRight">
                Go to retention timeline
              </ActionButton>
            </>
          ),
        }
      case 'pending_notice_file':
        if (approval?.approval_status?.id === 'pending') {
          return {
            title: 'Notice requested from employee ',
            text: getApproverActionString(approval),
            actions: (
              <ActionButton useIcon="ArrowThinRight">
                Go to retention timeline
              </ActionButton>
            ),
          }
        }
        if (approval?.approval_status?.id === 'rejected') {
          return {
            title:
              'Notice was rejected. Employee got notified to upload new resignation letter',
            text: getApproverActionString(approval),
            actions: (
              <ActionButton useIcon="ArrowThinRight">
                Go to retention timeline
              </ActionButton>
            ),
          }
        }
        return undefined

      case 'notice_file_pending_review':
        return {
          title: 'Employee submitted notice & retention process was triggered',
          text:
            'To confirm employee’s resignation, please review the resignation letter and record the resignation effective date. ' +
            'Once that’s done resignation status will be changed to "Completed". ' +
            'Please note that there is ongoing retention process for this employee.',
          actions: (
            <>
              <ActionButton
                useIcon="Document"
                use={InternalLink}
                to={pathToUrl(
                  ROUTES.FORMS.EMPLOYEE_RESIGNATION.DASHBOARD_LETTER_APPROVE,
                  {
                    id: resignation.id,
                    employeeId: resignation.employee.id,
                  },
                )}
              >
                Review resignation letter
              </ActionButton>
              <ActionButton useIcon="ArrowThinRight">
                Go to retention timeline
              </ActionButton>
            </>
          ),
        }
      case 'completed':
        return {
          title: 'Employee is ready for termination',
          text: (
            <>
              Please note that there is still retention process ongoing for this employee.
              <br />
              Effective resignation date: {formatDate(resignation.resignation_date)}
              <br />
              Notice period: {resignation.notice_period}.
              <br />
              Recommended exit date:{' '}
              {formatDate(resignation.recommended_resignation_date)}. Otherwise consult
              with employee’s LM.
            </>
          ),
          actions: (
            <>
              <SetDateButton onSubmit={onSubmitResignationDate} />
              <ActionButton useIcon="ArrowThinRight">
                Go to retention timeline
              </ActionButton>
            </>
          ),
        }
      case 'cancelled':
        return {
          title: 'Employee resignation was cancelled',
          text: getApproverActionString(approval),
        }
      default:
        return undefined
    }
  }
}
