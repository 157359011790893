import React from 'react'
import { Header, Text, Token } from '@revolut/ui-kit'

interface PageHeaderProps {
  email?: string | null
  title: string
}

export const PageHeader = ({ email, title }: PageHeaderProps) => {
  const contactLink = `mailto:${email}`
  return (
    <Header mb="s-24" variant="main">
      <Header.Title color={Token.color.foreground}>{title}</Header.Title>
      {email && (
        <Header.Subtitle>
          Please verify that the below information matches with your government issued
          identification document. If this doesn't look right, please write to{' '}
          <Text
            color={Token.color.blue}
            href={contactLink}
            target="_blank"
            textDecoration="none"
            use="a"
          >
            {email}
          </Text>
          .
        </Header.Subtitle>
      )}
    </Header>
  )
}
