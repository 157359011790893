import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { useGetCandidateForms } from '@src/api/hiringProcess'
import { Group, Item, Avatar, ItemSkeleton } from '@revolut/ui-kit'
import { Form, InfoOutline } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CandidateFormInterface } from '@src/interfaces/hiringProccess'
import { formatDateTime } from '@src/utils/format'

const Forms = () => {
  const { id } = useParams<{ id: string }>()
  const { data, isLoading } = useGetCandidateForms(id)

  const handleClick = (form: CandidateFormInterface) => {
    switch (form.source.id) {
      case 'application':
      case 'internal':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.APPLICATION_CANDIDATE.PREVIEW, { id: form.id }),
        )
      case 'referral':
        return navigateTo(
          pathToUrl(ROUTES.FORMS.REFER_CANDIDATE.PREVIEW, { id: form.id }),
        )
      case 'sourcing':
        return navigateTo(pathToUrl(ROUTES.FORMS.NEW_CANDIDATE.PREVIEW, { id: form.id }))
      default:
        return null
    }
  }

  const renderDescription = (form: CandidateFormInterface) => {
    if (form.source.id === 'sourcing') {
      return `Sourced by ${form?.created_by?.display_name}`
    }
    if (form.source.id === 'referral') {
      return `Referred by ${form?.created_by?.display_name}`
    }
    return `Added by ${form.full_name}`
  }

  return (
    <PageBody>
      <Group>
        {isLoading && <ItemSkeleton />}
        {data?.map(form => (
          <Item use="button" key={`form-${form.id}`} onClick={() => handleClick(form)}>
            <Item.Avatar>
              <Avatar useIcon={Form} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{form.source.name}</Item.Title>
              <Item.Description>{renderDescription(form)}</Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value variant="secondary">
                {formatDateTime(form.created_date_time)}
              </Item.Value>
            </Item.Side>
          </Item>
        ))}
        {!isLoading && !data?.length && (
          <Item useIcon={InfoOutline}>
            <Item.Content>
              <Item.Title color="grey-tone-50">No forms were created</Item.Title>
            </Item.Content>
          </Item>
        )}
      </Group>
    </PageBody>
  )
}

export default Forms
