import React from 'react'
import { Group, Text, VStack } from '@revolut/ui-kit'
import { LinkedInIntegrationItem } from '@src/pages/Hub/Integrations/LinkedIn/LinkedInIntegrationItem'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { MonsterJobsIntegrationItem } from '@src/pages/Hub/Integrations/MonsterJobs/MonsterJobsIntegrationItem'
import { JobBoardsSection } from '@src/pages/Settings/SettingsLandingPage/Integrations'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  noTitle?: boolean
}

export const CareersIntegrations = ({ noTitle }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const useNewLayout = featureFlags.includes(FeatureFlags.IndeedIntegration)
  if (useNewLayout) {
    return <JobBoardsSection title={noTitle ? undefined : 'Job boards'} />
  }
  return (
    <VStack gap="s-16">
      {!noTitle && <Text variant="h5">Job posting integrations</Text>}
      <Group>
        <LinkedInIntegrationItem />
        <MonsterJobsIntegrationItem />
      </Group>
    </VStack>
  )
}

export const CareersIntegrationsPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Job posting integrations"
        backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.PREVIEW}
      />
      <PageBody>
        <CareersIntegrations noTitle />
      </PageBody>
    </PageWrapper>
  )
}
