import React from 'react'
import { ActionButton } from '@revolut/ui-kit'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { useOpenNewTab } from '@src/actions/RouterActions'

export const PreviewButton = () => {
  const navigateTo = useOpenNewTab()

  return (
    <ActionButton
      onClick={() => {
        navigateTo(PUBLIC_ROUTES.CAREERS.JOB_LIST)
      }}
    >
      Preview
    </ActionButton>
  )
}
