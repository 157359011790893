import React, { useState } from 'react'
import { MoreBar, Portal, StatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { Check, IconComponentType, Pencil, Profile } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import { hireCandidate } from '@src/api/offerCreation'
import { useGetOfferSettings } from '@src/api/settings'

type BaseActionProps = {
  label: string
  icon?: IconComponentType
  onClick?: () => void
  link?: string
}

type ActionProps = {
  offer: OfferCreationInterface
}

export const BaseAction = ({ label, icon, onClick, link }: BaseActionProps) => (
  <MoreBar.Action
    useIcon={icon}
    onClick={() => {
      if (onClick) {
        onClick()
        return
      }

      if (link) {
        navigateTo(link)
      }
    }}
  >
    {label}
  </MoreBar.Action>
)

export const ViewOfferAction = ({ offer }: ActionProps) => {
  const { data: offerSettings } = useGetOfferSettings()
  const offersEnabled = offerSettings?.enable_offer_templates

  return (
    <BaseAction
      label={`View ${offersEnabled ? 'offer' : 'hire form'}`}
      link={pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
        id: offer.id,
        candidateId: offer.candidate?.id,
      })}
    />
  )
}

export const ViewEmployeeProfileAction = ({ offer }: ActionProps) => (
  <BaseAction
    label="View employee profile"
    link={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
      id: offer.employee_id,
    })}
    icon={Profile}
  />
)

export const EditOfferAction = ({ offer }: ActionProps) => (
  <BaseAction
    label="Edit offer"
    link={pathToUrl(ROUTES.FORMS.OFFER_CREATION.GENERAL, {
      id: offer.id,
      candidateId: offer.candidate?.id,
    })}
    icon={Pencil}
  />
)

type MarkHiredActionProps = {
  offerId: number
  onRefresh: () => void
}

export const MarkHiredAction = ({ offerId, onRefresh }: MarkHiredActionProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [openResultPopup, setOpenResultPopup] = useState<'success' | 'error'>()
  const [loading, setLoading] = useState(false)

  const onConfirm = async () => {
    setLoading(true)

    try {
      await hireCandidate(offerId)
      setOpenResultPopup('success')
      onRefresh()
    } catch (e) {
      setOpenResultPopup('error')
    } finally {
      setLoading(false)
      setOpenConfirmation(false)
    }
  }

  return (
    <>
      <Portal>
        <ConfirmationDialog
          open={openConfirmation}
          label="Mark candidate as hired"
          onClose={() => setOpenConfirmation(false)}
          onReject={() => setOpenConfirmation(false)}
          onConfirm={onConfirm}
          body="Are you sure you want to mark the candidate as hired and create the employee? This will trigger the onboarding steps for this candidate with the HR Team"
          loading={loading}
          submitDisabled={loading}
        />
        <StatusPopup
          variant={openResultPopup === 'error' ? 'error' : 'success'}
          open={!!openResultPopup}
          onClose={() => {
            setOpenResultPopup(undefined)
          }}
          // @ts-expect-error
          labelButtonClose="Close success popup"
        >
          <StatusPopup.Title>
            {openResultPopup === 'error'
              ? 'Something went wrong'
              : 'Candidate marked as hired'}
          </StatusPopup.Title>
        </StatusPopup>
      </Portal>
      <BaseAction
        label="Mark as hired"
        icon={Check}
        onClick={() => setOpenConfirmation(true)}
      />
    </>
  )
}
