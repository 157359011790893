import { RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'

export const kpiTemplateRequests: RequestInterface<KPITemplateInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.KPI_TEMPLATE, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.KPI_TEMPLATE}/${id}`),
  putItem: async (data, kpiId) => api.patch(`${API.KPI_TEMPLATE}/${kpiId}`, data),
  patchItem: async (data, kpiId) => api.patch(`${API.KPI_TEMPLATE}/${kpiId}`, data),
  deleteItem: async id => api.delete(`${API.KPI_TEMPLATE}/${id}`),
  postItem: async data => api.post(API.KPI_TEMPLATE, data),
}

export const kpiTemplateRequestsNew: RequestInterfaceNew<KPITemplateInterface> = {
  get: async ({ id }) => api.get(`${API.KPI_TEMPLATE}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.KPI_TEMPLATE}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.KPI_TEMPLATE, data),
  delete: async ({ id }) => api.delete(`${API.KPI_TEMPLATE}/${id}`),
}
