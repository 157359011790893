import React from 'react'
import { Dropdown, DropdownItemProps, MoreBar, MoreBarActionProps } from '@revolut/ui-kit'

export type MenuActionType = 'dropdown' | 'negative' | 'primary'

interface Props {
  menuType?: MenuActionType
  children: React.ReactNode
  props: MoreBarActionProps | DropdownItemProps
}

const MenuAction = ({ menuType, children, props }: Props) => {
  if (menuType === 'dropdown') {
    return <Dropdown.Item {...(props as DropdownItemProps)}>{children}</Dropdown.Item>
  }

  return (
    <MoreBar.Action variant={menuType} {...(props as MoreBarActionProps)}>
      {children}
    </MoreBar.Action>
  )
}

export default MenuAction
