import React from 'react'
import { useTooltip, Tooltip, TooltipProps, Portal } from '@revolut/ui-kit'
import { IconProps, InfoOutline } from '@revolut/icons'

export type InfoIconWithTooltipProps = {
  content: React.ReactNode
  tooltipProps?: Partial<TooltipProps>
} & IconProps

export const InfoIconWithTooltip = (props: InfoIconWithTooltipProps) => {
  const { tooltipProps = {}, content, ...iconProps } = props
  const tooltip = useTooltip()
  return (
    <>
      <InfoOutline {...tooltip.getAnchorProps()} {...iconProps} />
      <Tooltip {...tooltip.getTargetProps()} usePortal={Portal} {...tooltipProps}>
        {content}
      </Tooltip>
    </>
  )
}
