import { Token } from '@revolut/ui-kit'
import { MeetingEvent, OneToOneEmployeeRelation } from '@src/interfaces/meetings'

export const reportCalendarEventBackgroundColor = Token.color.greyTone50
export const managerCalendarEventBackgroundColor = Token.color.deepGrey
export const otherCalendarEventBackgroundColor = Token.color.greyTone20
export const calendarEventTextColor = Token.color.white

export const getCalendarEventBackgroundColor = ({
  employee_relation_type,
}: MeetingEvent): string => {
  switch (employee_relation_type) {
    case OneToOneEmployeeRelation.Report:
      return reportCalendarEventBackgroundColor

    case OneToOneEmployeeRelation.LineManager:
    case OneToOneEmployeeRelation.FunctionalManager:
      return managerCalendarEventBackgroundColor

    default:
      return otherCalendarEventBackgroundColor
  }
}
