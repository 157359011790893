import React, { useEffect, useMemo, useRef, useState } from 'react'

interface Props {
  count: number
  children: any
  onLoadMore?: () => void
  // initial scroll position
  onScroll?: (node: HTMLDivElement) => void
  scrollTo?: number
}

const defaultSize = 20

const VirtualList = ({ count, children, onLoadMore, scrollTo, onScroll }: Props) => {
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null)
  const [ref1, setRef1] = useState<HTMLDivElement | null>(null)
  const [ref2, setRef2] = useState<HTMLDivElement | null>(null)
  const [ref3, setRef3] = useState<HTMLDivElement | null>(null)
  // size is adjusted to screen size for very large screens
  const [size, setSize] = useState<number>(defaultSize)
  const sizes = useMemo<number[]>(() => [], [])
  const [index, setIndex] = useState(0)
  const handlerRef = useRef(() => {})
  useEffect(() => {
    handlerRef.current = () => {
      if (scrollRef) {
        onScroll?.(scrollRef)
      }
      if (scrollRef && sizes.length) {
        const { index: newIndex } = sizes.reduce(
          (acc, val) => {
            acc.height += val
            if (acc.height + val * 0.2 < scrollRef.scrollTop) {
              acc.index += 1
            }
            return acc
          },
          { index: 0, height: 0 },
        )
        if (index !== newIndex) {
          if (onLoadMore && (newIndex + 3) * size >= children.length) {
            onLoadMore()
          }
          setIndex(newIndex)
        }
      }
    }
  })

  useEffect(() => {
    const callback = () => handlerRef.current()

    if (scrollRef) {
      scrollRef.addEventListener('scroll', callback, {
        capture: false,
        passive: true,
      })
    }

    return () => {
      if (scrollRef) {
        scrollRef.removeEventListener('scroll', callback)
      }
    }
  }, [scrollRef])

  useEffect(() => {
    if (ref1 && ref2 && ref3) {
      sizes[index] = ref1.getBoundingClientRect().height
      sizes[index + 1] = ref2.getBoundingClientRect().height
      sizes[index + 2] = ref3.getBoundingClientRect().height
    }
  }, [size, ref1, ref2, ref3])

  useEffect(() => {
    if (size === defaultSize && scrollRef && sizes[0]) {
      const scrollHeight = scrollRef?.getBoundingClientRect()?.height!
      // size should be at least 60% of scrollHeight
      const aproxSingleSize = sizes[0] / size
      const newSize = Math.ceil((scrollHeight / aproxSingleSize) * 0.65)
      setSize(newSize)
      if (onLoadMore && newSize * 3 >= children.length) {
        onLoadMore()
      }
    }
  }, [scrollRef, ref1])

  useEffect(() => {
    // TODO hack for cycles, only works on small lists, because can't scroll beyond height
    if (scrollTo) {
      const newPosition = size * scrollTo
      scrollRef?.scrollBy({ top: newPosition })
    }
  }, [scrollRef])

  if (count < size * 3) {
    return (
      <div
        key="static"
        ref={setScrollRef}
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {children}
      </div>
    )
  }

  const topPadding = sizes.slice(0, index).reduce((acc, val) => acc + val, 0)
  const bottomPadding = sizes.slice(index + 3).reduce((acc, val) => acc + val, 0)

  return (
    <div
      key="virtualized"
      ref={setScrollRef}
      style={{
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          paddingTop: topPadding,
          paddingBottom: bottomPadding,
        }}
      >
        <div key={index} ref={setRef1}>
          {children.slice(index * size, (index + 1) * size)}
        </div>
        <div key={index + 1} ref={setRef2}>
          {children.slice((index + 1) * size, (index + 2) * size)}
        </div>
        <div key={index + 2} ref={setRef3}>
          {children.slice((index + 2) * size, (index + 3) * size)}
        </div>
      </div>
    </div>
  )
}

export default VirtualList
