import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'

import { useCookie } from '@src/hooks/useCookie'
import { COOKIE } from '@src/constants/api'
import { useNeedAnalytics } from '@src/utils'

interface CookieConsentState {
  isAnalyticsEnabled: boolean
  isBannerDisplayed: boolean
  setIsAnalyticsEnabled: (isEnabled: boolean) => void
  setIsBannerDisplayed: (isDisplayed: boolean) => void
}

export const CookieConsentContext = createContext<CookieConsentState | null>(null)

export function useCookieConsentContext() {
  const value = useContext(CookieConsentContext)

  if (value === null) {
    throw new Error(
      '`useCookieConsentContext` is accessible in `CookieConsentProvider` component only',
    )
  }

  return value
}

const COOKIE_BANNER_EXPIRY = 180 // in days
const COOKIE_CONSENT_PARAM = 'cookie_consent'

export const CookieConsentProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const needAnalytics = useNeedAnalytics()
  const [isCookieBannerDisplayed, setIsCookieBannedDisplayed] = useCookie(
    COOKIE.COOKIE_BANNER_CLOSED,
  )
  const [isCookieAnalyticsEnabled, setIsCookieAnalyticsEnabled] = useCookie(
    COOKIE.COOKIE_ANALYTICS_ENABLED,
  )
  const [isAnalyticsEnabled, setIsAnalyticsEnabled] = useState(
    () => isCookieAnalyticsEnabled === 'true' || !needAnalytics,
  )
  const [isBannerDisplayed, setIsBannerDisplayed] = useState(
    () => isCookieBannerDisplayed === 'true' || !needAnalytics,
  )

  useEffect(() => {
    if (isCookieAnalyticsEnabled !== null) {
      return
    }

    const searchParams = new URL(window.location.href).searchParams
    const cookieConsent = searchParams.get(COOKIE_CONSENT_PARAM)

    if (cookieConsent === 'true') {
      setIsAnalyticsEnabled(true)
      setIsBannerDisplayed(true)
    } else if (cookieConsent === 'false') {
      setIsAnalyticsEnabled(false)
      setIsBannerDisplayed(true)
    }
  }, [window.location.href])

  useEffect(() => {
    // update analytics cookie
    setIsCookieAnalyticsEnabled(isAnalyticsEnabled ? 'true' : 'false', {
      expires: COOKIE_BANNER_EXPIRY,
    })
  }, [isAnalyticsEnabled, setIsCookieAnalyticsEnabled])

  useEffect(() => {
    // update banner cookie
    setIsCookieBannedDisplayed(isBannerDisplayed ? 'true' : 'false', {
      expires: COOKIE_BANNER_EXPIRY,
      sameSite: 'Lax',
    })
  }, [isBannerDisplayed, setIsCookieBannedDisplayed])

  const value = useMemo(
    () => ({
      isAnalyticsEnabled,
      isBannerDisplayed,
      setIsAnalyticsEnabled,
      setIsBannerDisplayed,
    }),
    [isAnalyticsEnabled, isBannerDisplayed, setIsAnalyticsEnabled, setIsBannerDisplayed],
  )

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  )
}
