import React from 'react'
import Form from '@src/features/Form/Form'
import { committeeResultRequests } from '@src/api/probationReview'
import CommitteeResultForm, {
  validator,
} from '@src/pages/Forms/CommitteeResultForm/General'

const CommitteeResult = () => {
  return (
    <Form api={committeeResultRequests} validator={validator}>
      <CommitteeResultForm />
    </Form>
  )
}

export default CommitteeResult
