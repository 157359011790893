import { Box, Flex, Group, Popover, Text, TextButton } from '@revolut/ui-kit'
import React from 'react'

interface GlobalSearchSectionProps {
  name: string
  children: React.ReactNode
  showMore?: () => void
  isCoreNavigation: boolean
}

const GlobalSearchSection = ({
  name,
  children,
  showMore,
  isCoreNavigation,
}: GlobalSearchSectionProps) => {
  const title = (
    <Text
      data-testid="global-search-section-title"
      color="grey-tone-50"
      use="div"
      variant="emphasis2"
      mb={isCoreNavigation ? 's-0' : 's-16'}
    >
      {name}
    </Text>
  )
  const actions = showMore && (
    <TextButton fontSize={14} onClick={showMore}>
      See more
    </TextButton>
  )
  return (
    <Box mb={isCoreNavigation ? 's-12' : 's-32'}>
      {isCoreNavigation ? (
        <Popover.Group
          title={title}
          actions={actions}
          data-testid="global-search-section-container"
        >
          {children}
        </Popover.Group>
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb={isCoreNavigation ? '-s-8' : 's-0'}
          >
            {title}
            {actions}
          </Flex>
          <Group data-testid="global-search-section-container">{children}</Group>
        </>
      )}
    </Box>
  )
}

export default GlobalSearchSection
