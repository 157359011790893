import React, { useEffect, useState } from 'react'
import upperFirst from 'lodash/upperFirst'
import { ChatProps } from '@components/Chat/common'
import SideBar from '@components/SideBar/SideBar'
import ChatMessagesList from '@components/Chat/ChatMessagesList'
import { Box, chain, Flex, Side } from '@revolut/ui-kit'
import ChatTextEditor, {
  ChatTextEditorVariant,
} from '@components/Chat/ChatTextEditor/ChatTextEditor'
import { useQuery } from '@src/utils/queryParamsHooks'
import ChatTagsManager from '@components/Chat/ChatTagsManager'

export interface ChatSidebarProps extends ChatProps {
  isOpen: boolean
  onClose: () => void
  canEdit?: boolean
  canAddComments?: boolean
  withFileUploader?: boolean
}

const ChatSidebar = ({
  isOpen,
  data,
  onClose,
  canEdit = true,
  canAddComments = true,
  withFileUploader,
  ...props
}: ChatSidebarProps) => {
  const qs = useQuery<{ comment: string }>()

  const [forceOpen, setForceOpen] = useState<boolean>(false)
  const [scrollToComment, setScrollToComment] = useState<number | undefined>(undefined)

  useEffect(() => {
    const commentId = Number(qs.query.comment)

    if (!isNaN(commentId)) {
      setForceOpen(true)
      setScrollToComment(commentId)
      qs.deleteQueryParam('comment')
    }
  }, [qs.query.comment])

  return (
    <SideBar
      isOpen={forceOpen || isOpen}
      onClose={() => {
        onClose()
        setForceOpen(false)
      }}
      title={chain(`${upperFirst(props.type)}s`, data?.length || 0)}
      variant="wide"
    >
      <Flex flexDirection="column">
        <ChatMessagesList
          {...props}
          scrollToComment={scrollToComment}
          onAfterScrollToComment={() => setScrollToComment(undefined)}
          canEdit={canEdit}
          reversedOrder
          data={data}
          maxShown={undefined}
          messageBorder={false}
        />
        {canEdit && canAddComments && (
          <Side.Actions>
            <Box pt="s-16">
              <ChatTagsManager>
                <ChatTextEditor
                  variant={ChatTextEditorVariant.Input}
                  onSubmit={(msgBody, createTask, attachedFiles) =>
                    props
                      .onAddMessage(msgBody, createTask, attachedFiles)
                      .then(props.refetch)
                      .then(res => {
                        // In API the most resent comments are returned first
                        // here we scroll to the comment we've just created
                        const lastComment = res?.data?.results?.[0]
                        setScrollToComment(lastComment?.id)
                        return res
                      })
                  }
                  withFileUploader={withFileUploader}
                />
              </ChatTagsManager>
            </Box>
          </Side.Actions>
        )}
      </Flex>
    </SideBar>
  )
}

export default ChatSidebar
