import React from 'react'
import { Box, Color, Header, Popup, Text } from '@revolut/ui-kit'

import { UseEmployeeStatusTransitionsReturnType } from '../../StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from '../hooks'
import { SetInactiveForm } from './SetInactiveForm'

type Props = {
  employeeName: string
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
}
export const SetInactivePopup = ({
  employeeName,
  statusTransitions,
  employeeInactivity,
}: Props) => {
  return (
    <Popup
      open={employeeInactivity.isPopupOpened}
      onClose={() => employeeInactivity.setIsPopupOpened(false)}
      variant="bottom-sheet"
    >
      <Header variant="bottom-sheet">
        <Header.Title>{`You are setting ${employeeName} as inactive employee`}</Header.Title>
      </Header>
      <Text use="p" variant="caption" color={Color.GREY_TONE_50}>
        Please, select the reason why this employee needs to be set as inactive and then
        select dates.
      </Text>
      <Box mt="s-16">
        <SetInactiveForm
          statusTransitions={statusTransitions}
          employeeInactivity={employeeInactivity}
        />
      </Box>
    </Popup>
  )
}
