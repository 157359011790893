import React from 'react'
import { Ellipsis, Item, Text } from '@revolut/ui-kit'
import { format } from 'date-fns'

import { getSenderDetails } from '@src/features/EmailThread/utils'
import { EmployeeEmailThreadInterface } from '@src/interfaces/employeeEmails'

interface EmployeeEmailContentProps {
  thread: EmployeeEmailThreadInterface
}
export const EmployeeEmailContent = ({ thread }: EmployeeEmailContentProps) => {
  const email = thread.emails[0]
  const sender = getSenderDetails({ ...email, direction: email.direction.id })

  const getEmailTitle = () => {
    if (email.status.id === 'scheduled') {
      return thread.subject
    }
    if (email.direction.id === 'received') {
      return `Email received: ${thread.subject}`
    }
    return `Email sent: ${thread.subject}`
  }

  return (
    <Item.Content>
      <Item.Title>
        {email.status.id === 'scheduled' && email.custom_sending_datetime && (
          <Text use="div" color="orange">
            Email scheduled to send on{' '}
            {format(new Date(email.custom_sending_datetime), 'd MMM Y, hh:mm aaa')}
          </Text>
        )}
        {getEmailTitle()}
      </Item.Title>
      <Item.Description>
        <Text>{sender.name || sender.email}</Text>
        <Ellipsis>{email.snippet}</Ellipsis>
        {thread.emails.length > 1 && (
          <Text mt="s-8" use="div">
            {thread.emails.length} emails in thread
          </Text>
        )}
      </Item.Description>
    </Item.Content>
  )
}
