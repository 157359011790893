import * as React from 'react'
import toString from 'lodash/toString'

import { useChangelogProps } from '@src/utils/form'
import FileUploader, {
  FileUploaderProps,
} from '@components/Inputs/FileUploader/FileUploader'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props extends Omit<FileUploaderProps, 'onChange' | 'value'> {
  name: string
  value?: File | File[] | null
  onAfterChange?: (val: File | File[] | null) => void
}

const LapeFileUploader = ({ name, required, onAfterChange, ...props }: Props) => {
  const {
    value,
    error: formError,
    touched,
    apiError,
    onChange,
    hidden,
    disabled,
  } = useLapeField(name)
  const formValidator = useFormValidator()
  const changelogProps = useChangelogProps(name)

  if (hidden) {
    return null
  }

  const error = formError || apiError
  const errorMessage = error ? toString(error) : undefined
  const validated = !!formValidator?.validated

  return (
    <FileUploader
      onChange={val => {
        onChange(val)
        onAfterChange?.(val)
      }}
      required={required}
      value={value}
      name={name}
      {...props}
      {...changelogProps}
      disabled={disabled || props.disabled}
      error={validated && errorMessage ? errorMessage : (touched && error) || props.error}
    />
  )
}

export default connect(LapeFileUploader)
