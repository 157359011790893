import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalsInterface, GoalKpiDetails } from '@src/interfaces/goals'
import { CellWithItem } from '../../../common/CellWithItem'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { ActionButton, Box } from '@revolut/ui-kit'

import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { GoalMetricsItems } from './GoalMetricItem'
import { DeepPartial } from 'redux'
import { useAddMetric } from './useAddMetrics'
import { useGetEmployeeCyclesSelector } from '@src/features/Goals/common/useGetEmployeeCyclesSelector'
import { useLocation } from 'react-router-dom'
import { isString } from 'lodash'

interface TargetsWidgetProps {
  reviewCycle?: ReviewCyclesInterface
  isProbation: boolean
}

export const TargetsWidget = ({ reviewCycle, isProbation }: TargetsWidgetProps) => {
  const { values } = useLapeContext<
    GoalsInterface & { kpis: DeepPartial<GoalsInterface['kpis']> }
  >()

  const hasDeletePermission = !!values.field_options?.permissions?.includes('delete_goal')
  const location = useLocation<{
    reviewCycleId?: string
    history: string[]
    isNew?: boolean
  }>()

  const { data: reviewCycles, isFetching } = useGetSelectors<ReviewCyclesInterface>(
    selectorKeys.review_cycles,
  )
  const { getCycleById, isLoading } = useGetEmployeeCyclesSelector(
    values.content_type?.model === 'employees' ? values.content_object.id : undefined,
  )
  const { getNextMetric, getEmptyMetric } = useAddMetric({ values, reviewCycles })

  const onAddNewMetric = (copyFromId?: number) => {
    const nextMetric = getNextMetric({ copyFromId })
    values.kpis.push(nextMetric)
  }

  const addEmptyMetric = () => {
    const initReviewCycle =
      reviewCycle || reviewCycles?.find(cycle => Number(cycle.offset) === 0)

    const locationCycle =
      location.state?.reviewCycleId && isString(location.state.reviewCycleId)
        ? getCycleById(location.state.reviewCycleId)
        : undefined

    const initEmployeeCycle =
      locationCycle?.category === ReviewCycleCategory.Probation
        ? locationCycle
        : undefined

    const emptyMetric: DeepPartial<GoalKpiDetails> = getEmptyMetric(
      initReviewCycle,
      initEmployeeCycle,
    )
    values.kpis.push(emptyMetric)
  }

  useEffect(() => {
    if (!values.kpis.length && !isFetching && !isLoading) {
      addEmptyMetric()
    }
  }, [isFetching, isLoading])

  return (
    <CellWithItem
      icon="TurboTransfer"
      title="Metric"
      description="What are the metrics and success criteria?"
    >
      <Box p="s-16" pt={0}>
        <GoalMetricsItems
          metrics={values.kpis}
          owner={values.owner}
          onCopy={onAddNewMetric}
          hasDeletePermission={hasDeletePermission}
        />
        {!isProbation && values.update_type?.id === 'target_based' ? (
          <ActionButton
            useIcon="Plus"
            size="sm"
            mt="s-8"
            onClick={() => onAddNewMetric()}
          >
            Add another metric
          </ActionButton>
        ) : null}
      </Box>
    </CellWithItem>
  )
}
