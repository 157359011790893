import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { Text } from '@revolut/ui-kit'
import { getNormalizedLocations } from '@src/pages/Forms/JobPosting/utils'
import React from 'react'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export const renderOptionFilterSidebarItem = <T,>(option: T) => {
  return renderOption(option as RadioSelectOption<JobPostingLocationTypeInterface>)
}

export const renderOption = (
  option: RadioSelectOption<JobPostingLocationTypeInterface>,
) => {
  const locations = getNormalizedLocations(option.value.locations)

  return (
    <>
      <Text use="p">{option.label}</Text>
      <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
        {!!locations.office.length && (
          <Text display="block">Office: {locations.office.join(' | ')}</Text>
        )}
        {!!locations.remote.length && (
          <Text display="block">Remote: {locations.remote.join(' | ')}</Text>
        )}
      </Text>
    </>
  )
}
