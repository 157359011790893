import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import OnboardingActions from '@src/pages/OnboardingChecklistV2/components/OnboardingActions'
import { reviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { API } from '@src/constants/api'
import { performanceSettings } from '@src/api/performanceSettings'
import { connect } from 'lape'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { selectReviewTypesTitle } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/index'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { GroupWithRequired } from '@components/RequiredItem/RequiredItem'

export const PerformanceReviewTypesContent = () => {
  const items = {
    required: [
      {
        title: 'Manager reviews',
        description:
          'Review feedback from managers are the foundation of the performance result',
      },
      {
        title: 'Self reviews',
        description:
          'Allows the employee to submit a review of their own performance and behaviours',
      },
    ],
    controls: [
      {
        title: 'Peer reviews',
        description: 'Allow peers to review each other',
        name: 'enable_peer_reviews',
      },
      {
        title: 'Upwards reviews',
        description:
          'Direct and functional reports are allowed to submit anonymous reviews of their managers',
        name: 'enable_upwards_reviews',
      },
    ],
  }

  return (
    <>
      <PageBody>
        <VStack space="s-8" mb="s-16">
          <Text variant="h5">Who should be invited to provide review feedback?</Text>
          <Text variant="small" color={Token.color.greyTone50}>
            We recommend 360 reviews, however you can disable some review types
          </Text>
        </VStack>
        <GroupWithRequired requiredItems={items.required} highlightRequired={false}>
          {items.controls.map(({ title, description, name }) => (
            <LapeNewSwitch
              key={title}
              itemTypeProps={{ title, description }}
              name={name}
            />
          ))}
        </GroupWithRequired>
      </PageBody>

      <OnboardingActions
        config={reviewsConfig}
        currentStep={selectReviewTypesTitle}
        isForm
        isLastStep={false}
        updateSteps
        disableNext={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD}
      />
    </>
  )
}

export const PerformanceReviewTypes = connect(() => {
  return (
    <Form
      api={performanceSettings}
      invalidateQueries={[API.PERFORMANCE_SETTINGS]}
      forceParams={{ id: 'reviews' }}
      isExistingForm
    >
      <PerformanceReviewTypesContent />
    </Form>
  )
})
