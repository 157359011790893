import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import reduce from 'lodash/reduce'
import { useTable } from '@components/Table/hooks'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  KpiInterface,
  KPIPerformanceTypes,
  KPIWeightModes,
  KpiWeightsInterface,
} from '@src/interfaces/kpis'
import { editableKpiWeightColumn } from '@src/constants/columns/kpi'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Header,
  HStack,
  MoreBar,
  Popup,
  Text,
} from '@revolut/ui-kit'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { kpisRequests } from '@src/api/kpis'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { EntityTypes } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { kpiWeightsSubmitRequest } from '@src/api/kpiWeights'
import { useSelector } from 'react-redux'
import { canAddTeamKpi, selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { InfoOutline, Plus } from '@revolut/icons'
import LapeEditableTable from '@components/TableV2/EditableTable/LapeEditableTable'
import BulkAssignKPIs from '@src/features/BulkAssignKPIs/BulkAssignKPIs'
import NewWarningMessage, {
  NewWarningTypes,
} from '@components/NewWarningMessage/NewWarningMessage'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import Tooltip from '@components/Tooltip/Tooltip'
import { DeleteActionProps } from '@components/ColumnInserts/DeleteAction/DeleteAction'
import {
  addKpiOnClickRouter,
  afterSubmitPathRouter,
  removeNotificationRequestRouter,
  tableInitialFiltersRouter,
} from '@src/pages/Forms/AssignKPIs/routers'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { roundFloat } from '@src/utils/numbers'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getEditableRow, getReadonlyRow } from '@src/pages/Forms/AssignKPIs/rows'
import { PageActions } from '@src/components/Page/PageActions'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { autoCalculateKPIWeights } from '@src/utils/kpi'
import { TableNames } from '@src/constants/table'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { Statuses } from '@src/interfaces'
import { FeatureFlags } from '@src/store/auth/types'
import { AddGoal } from '../ProbationOverview/ProbationGoals/ProbationGoalsTable/AddGoal'
import { EmployeeInterface } from '@src/interfaces/employees'
import Table from '@src/components/TableV2/Table'
import { PERSONAL_KPI_SETTING_AND_APPROVAL_INFO } from '@src/constants/externalLinks'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  id: string
  notificationId?: number
  noNotification?: boolean
  reviewCycle?: ReviewCyclesInterface
  probationCycleId?: string
  entityType: EntityTypes
  defaultWeightMode: KPIWeightModes
  onAdd?: () => void
  noSwitchMode?: boolean
  employeeData?: EmployeeInterface
}

interface FormValues {
  data: KpiInterface[]
  weightMode: KPIWeightModes
  changedWeights?: { [kpiId: number]: number }
}

interface SubmitOptions extends Props {
  kpis: KpiInterface[]
  weightMode: KPIWeightModes
  removeNotification?: boolean
}

export const submitKPIsAssignment = async ({
  id,
  notificationId,
  kpis,
  reviewCycle,
  probationCycleId,
  weightMode,
  entityType,
  removeNotification,
}: SubmitOptions) => {
  const businessKPIs = kpis.filter(
    kpi => kpi.kpi_performance_type.id === KPIPerformanceTypes.business,
  )
  const total = businessKPIs.reduce((acc, kpi) => roundFloat(acc + kpi.weight!, 2), 0)
  const balancedFirstWeight =
    businessKPIs.length > 0 ? roundFloat(businessKPIs[0].weight! + 100 - total, 2) : 0

  const kpiWeights: KpiWeightsInterface = {
    entity_id: Number(id),
    entity_type: entityType,
    kpi_weight_mode: { id: weightMode },
    review_cycle: reviewCycle
      ? { ...reviewCycle, offset: reviewCycle.offset }
      : undefined,
    kpis: businessKPIs.map((kpi, key) => ({
      ...kpi,
      weight: key === 0 ? balancedFirstWeight : roundFloat(kpi.weight!, 2),
      enforce_weight: false,
    })),
    employee_cycle: probationCycleId
      ? {
          id: probationCycleId,
          name: 'Probation',
          category: ReviewCycleCategory.Probation,
        }
      : undefined,
  }

  await kpiWeightsSubmitRequest(kpiWeights)
  localStorage.removeItem(window.location.pathname)

  if (removeNotification && notificationId) {
    await removeNotificationRequestRouter(entityType)(notificationId)
  }

  return Promise.resolve({ data: kpis, weightMode })
}

const autoWeightModeDescription =
  'This will equally distribute the KPI weights and overwrite the current values.'

const initialSort: SortByInterface[] = [
  {
    sortBy: 'kpi_performance_type',
    direction: SORT_DIRECTION.DESC,
  },
]

const AssignKPIsForm = connect((props: Props) => {
  const {
    id,
    reviewCycle: initialReviewCycle,
    entityType,
    probationCycleId,
    noNotification,
    onAdd,
    noSwitchMode,
    employeeData,
  } = props

  const { query } = useQuery()
  const { values } = useLapeContext<FormValues>()

  const canAddKpi = useSelector(canAddTeamKpi)
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const [isBulkAssignOpen, setIsBulkAssignOpen] = useState<boolean>(false)
  const [isWeightsPopupOpen, setIsWeightsPopupOpen] = useState<boolean>(false)
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)
  const [initialTableData, setInitialTableData] = useState<KpiInterface[]>([])

  const currentCycleOffset = query.review_cycle__offset || initialReviewCycle?.offset
  const isReadonly =
    `${currentCycleOffset}` !== `${initialReviewCycle?.offset}` && !noNotification

  const initialFilters = tableInitialFiltersRouter(
    id,
    entityType,
    currentCycleOffset,
    probationCycleId,
    goalsEnabled,
  ).filter(({ columnName }) => columnName !== 'status')

  const table = useTable<KpiInterface>(
    kpisRequests,
    [
      ...initialFilters,
      {
        filters: [
          { name: Statuses.pending, id: Statuses.pending },
          { name: Statuses.approved, id: Statuses.approved },
          { name: Statuses.future, id: Statuses.future },
          { name: Statuses.completed, id: Statuses.completed },
        ],
        columnName: 'target_status',
        nonResettable: true,
      },
    ],
    initialSort,
  )

  const calculateTotal = (): number => {
    const absTotal = reduce(
      values.data,
      (acc, kpi) => {
        if (kpi.kpi_performance_type.id === KPIPerformanceTypes.business) {
          return acc + (Number(kpi.weight) || 0)
        }
        return acc
      },
      0,
    )
    return roundFloat(absTotal, 2)
  }

  const total = calculateTotal()
  const error =
    total === 100 || values.data.length === 0
      ? undefined
      : `The sum of all weights should be 100%, currently ${total}%`

  useEffect(() => {
    if (!table.data) {
      return
    }
    const transformedTableData: KpiInterface[] = []

    table.data.forEach(kpi => {
      const unsavedWeight = values.changedWeights?.[kpi.id]
      transformedTableData.push({ ...kpi, weight: unsavedWeight || kpi.weight })
    })

    setInitialTableData(transformedTableData)
  }, [table.data])

  useEffect(() => {
    if (values.weightMode === KPIWeightModes.auto) {
      autoSetWeights()
    }
  }, [values.data, values.weightMode])

  const onWeightsPopupCancel = () => {
    setIsWeightsPopupOpen(false)
  }

  const autoSetWeights = () => {
    values.changedWeights = autoCalculateKPIWeights(values.data)
  }

  const handleDeleteRow: DeleteActionProps['handleDelete'] = async itemId => {
    await kpisRequests.deleteItem(itemId)
    values.data = values.data.filter(data => String(data.id) !== String(itemId))
  }

  return (
    <>
      <Popup
        open={isWeightsPopupOpen}
        onClose={onWeightsPopupCancel}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Confirm auto distribution of weights</Header.Title>
        </Header>
        <Text use="p" variant="caption" color="grey-tone-50">
          {autoWeightModeDescription}
        </Text>
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={onWeightsPopupCancel}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              values.weightMode = KPIWeightModes.auto
              setIsWeightsPopupOpen(false)
            }}
          >
            Continue
          </Button>
        </Popup.Actions>
      </Popup>
      <Table.Widget>
        <Table.Widget.Info>
          {!probationCycleId && (
            <Flex mb="18px">
              <CycleFilter
                filterInputType={FilterSelectType.SingleSelect}
                type={CycleFilterType.NewUI}
                onFilterChange={table.onFilterChange}
                columnName="review_cycle__offset"
                filter={table.filterBy}
              />
            </Flex>
          )}
          {isReadonly && initialReviewCycle && (
            <NewWarningMessage
              mb="18px"
              title="This is a read-only mode"
              type={NewWarningTypes.warning}
            >
              You are viewing a different cycle and no actions can be done on this cycle.
              Switch back to <Text fontWeight={500}>{initialReviewCycle.name}</Text> to to
              make any changes
            </NewWarningMessage>
          )}
        </Table.Widget.Info>
        {!isReadonly && (
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              {entityType === EntityTypes.employee && !probationCycleId && (
                <MoreBar.Action
                  useIcon={Plus}
                  onClick={() => {
                    setIsBulkAssignOpen(true)
                  }}
                >
                  Assign multiple KPIs
                </MoreBar.Action>
              )}
              {canAddKpi && (
                <HideIfCommercial>
                  {goalsEnabled && probationCycleId && employeeData ? (
                    <AddGoal employee={employeeData} cycleId={probationCycleId} />
                  ) : (
                    <MoreBar.Action
                      disabled={isReadonly}
                      onClick={() => {
                        if (onAdd) {
                          onAdd()
                          return
                        }

                        addKpiOnClickRouter({ ...props, user }).then(callback =>
                          callback(),
                        )
                      }}
                      useIcon="Plus"
                    >
                      Add new KPI
                    </MoreBar.Action>
                  )}
                </HideIfCommercial>
              )}
              {entityType === EntityTypes.employee && (
                <MoreInfoButton href={PERSONAL_KPI_SETTING_AND_APPROVAL_INFO} />
              )}
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
        )}
        {!noSwitchMode && (
          <Table.Widget.Status>
            <HStack space="s-4" align="center">
              <Checkbox
                disabled={isReadonly}
                checked={values.weightMode === KPIWeightModes.auto}
                onChange={e => {
                  if (e.currentTarget.checked) {
                    setIsWeightsPopupOpen(true)
                  } else {
                    values.weightMode = KPIWeightModes.manual
                  }
                }}
              />
              <Text pl="10px">Auto distribute weights</Text>
              <Tooltip placement="right" text={autoWeightModeDescription}>
                <Box pl="10px">
                  <InfoOutline color="grey-tone-50" size={16} />
                </Box>
              </Tooltip>
            </HStack>
          </Table.Widget.Status>
        )}
        <Table.Widget.Table>
          {isReadonly ? (
            <AdjustableTable<KpiInterface>
              name={TableNames.AssignKPIs}
              dataType="KPI"
              row={getReadonlyRow(entityType)}
              {...table}
              useWindowScroll
              noDataMessage="No kpis found"
            />
          ) : (
            <LapeEditableTable<KpiInterface>
              name={TableNames.AssignKPIsEditable}
              dataType="KPI"
              dataFieldName="data"
              row={getEditableRow(handleDeleteRow, entityType, values.weightMode)}
              {...table}
              initialData={initialTableData}
              useWindowScroll
              noDataMessage="No kpis found"
              disableFilters
              replaceOnInitialDataChange
              onChange={({ fieldValue, rowPath, columnTitle }) => {
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                const kpi: KpiInterface | undefined = values.data[rowPath]

                if (columnTitle === editableKpiWeightColumn.title && kpi?.id) {
                  values.changedWeights = {
                    ...(values.changedWeights || {}),
                    [kpi.id]: fieldValue,
                  }
                }
              }}
            />
          )}
        </Table.Widget.Table>

        {entityType === EntityTypes.employee &&
          currentCycleOffset !== undefined &&
          initialReviewCycle && (
            <BulkAssignKPIs
              onClose={() => setIsBulkAssignOpen(false)}
              isOpen={isBulkAssignOpen}
              employeeId={id}
              onAfterSubmit={() => table.refresh()}
              cycleOffset={
                noNotification ? +currentCycleOffset : initialReviewCycle.offset
              }
            />
          )}
      </Table.Widget>
      {!isReadonly && error && table.data.length > 0 && (
        <NewWarningMessage>{error}</NewWarningMessage>
      )}

      {!isReadonly && (
        <PageActions maxWidth="100%">
          {!noNotification && (
            <Button
              variant="secondary"
              pending={isSavingChanges}
              disabled={!!error && table.data.length > 0}
              onClick={() => {
                setIsSavingChanges(true)
                submitKPIsAssignment({
                  ...props,
                  kpis: values.data,
                  weightMode: values.weightMode,
                })
                  .then(() => {
                    goBack(ROUTES.APPS.TODO.KPIS_TO_ASSIGN)
                  })
                  .finally(() => setIsSavingChanges(false))
              }}
            >
              Save for later
            </Button>
          )}
          <NewSaveButtonWithPopup
            tooltipText={error}
            disabled={!!error && table.data.length > 0}
            successText="KPIs have been assigned"
            onAfterSubmit={() => {
              if (probationCycleId || !initialReviewCycle) {
                return
              }

              navigateTo(
                `${pathToUrl(afterSubmitPathRouter(entityType), {
                  id,
                })}?review_cycle__offset=${initialReviewCycle.offset}`,
              )
            }}
          >
            {noNotification ? 'Submit' : 'Submit as done'}
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
    </>
  )
})

export default (props: Props) => {
  const url = window.location.pathname
  const savedData = workspaceLocalStorage.getItem(url)
  const parsedSavedData = savedData ? JSON.parse(savedData) : {}

  return (
    <LapeForm<FormValues>
      disableValidation
      initialValues={{
        weightMode: props.defaultWeightMode,
        data: [],
        reviewCycle: props.reviewCycle,
        ...parsedSavedData,
      }}
      onSubmit={form =>
        submitKPIsAssignment({
          ...props,
          kpis: form.values.data,
          weightMode: form.values.weightMode,
          removeNotification: true,
        })
      }
    >
      <FormLocalstorageLape isExistingData={false} url={url} />
      <AssignKPIsForm {...props} />
    </LapeForm>
  )
}
