import React from 'react'
import { useParams } from 'react-router-dom'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { useEmployeeEmailThread } from '@src/api/employeeEmails'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import EmployeeEmailThread from '@src/features/EmailThread/EmailThread'
import CancelEmail from '@src/pages/Forms/SendEmployeeEmail/View/CancelEmail'
import { pathToUrl } from '@src/utils/router'

export const View = () => {
  const params = useParams<{ employeeId: string; threadId: string; emailId: string }>()

  const { data: thread } = useEmployeeEmailThread(params.employeeId, params.threadId)

  if (!thread) {
    return <PageLoading />
  }

  return (
    <PageBody>
      {thread.emails[0].status.id === 'scheduled' && (
        <Flex mb="s-16">
          <MoreBar>
            <CancelEmail employeeId={+params.employeeId} thread={thread} />
          </MoreBar>
        </Flex>
      )}
      <EmployeeEmailThread
        emails={thread.emails}
        replyUrl={pathToUrl(ROUTES.FORMS.ONBOARDING_SEND_EMAIL_V2.GENERAL, params)}
      />
    </PageBody>
  )
}
