import React from 'react'

import { ChevronRight } from '@revolut/icons'
import { Color, DetailsCellSkeleton, Group, Item, Text } from '@revolut/ui-kit'
import { useFaqTopicOptions } from '@src/api/faq'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { OptionInterface } from '@src/interfaces/selectors'
import { topicsFiltersParams } from '@components/HelpCenter/helpers'
import { FiltersState } from '../hooks'
import { EmptySearchResults } from './EmptySearchResults'

type Props = {
  filtersState: FiltersState
  country?: OptionInterface
  onSelect: (topic: FaqTopicInterface) => void
  onEmptySearchResults: {
    onGoToAllCategoriesClick: () => void
    onChatWithUsClick: () => void
  }
}
export const TopicsSidebarContent = ({
  filtersState,
  country,
  onSelect,
  onEmptySearchResults,
}: Props) => {
  const { data: topicsData, isLoading } = useFaqTopicOptions(
    topicsFiltersParams({
      filtersState,
      country,
    }),
  )
  const topics = topicsData?.results || []

  if (!isLoading && !topics.length) {
    return (
      <EmptySearchResults
        onGoToAllCategoriesClick={onEmptySearchResults.onGoToAllCategoriesClick}
        onChatWithUsClick={onEmptySearchResults.onChatWithUsClick}
      />
    )
  }

  return (
    <>
      <Group>
        {isLoading ? (
          <>
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
          </>
        ) : (
          <>
            {topics.map(topic => (
              <Item
                key={topic.id}
                use="button"
                padding="s-16"
                onClick={() => {
                  onSelect(topic)
                }}
              >
                <Item.Content>
                  <Text>{topic.title}</Text>
                </Item.Content>
                <Item.Side>
                  <ChevronRight color={Color.GREY_TONE_50} />
                </Item.Side>
              </Item>
            ))}
          </>
        )}
      </Group>
    </>
  )
}
