import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Color,
  Dots,
  Flex,
  InputGroup,
  Text,
  Input,
  Cell,
  Group,
} from '@revolut/ui-kit'
import { Play } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { OptionInterface } from '@src/interfaces/selectors'
import { DocumentsRuleInterface } from '@src/interfaces/documentsRule'
import { runDocumentRuleSQL } from '@src/api/documentsTemplates'
import { RunQueryResponse } from '@src/interfaces/kpis'
import { selectUser } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewCodeEditor from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import NewStaticTable from '@src/components/Table/NewStaticTable'
import { CellTypes } from '@src/interfaces/data'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeDatabaseSelect from '@src/components/Inputs/LapeFields/LapeDatabaseSelect'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { getScheduleMessage, Parameter, TemplateType } from '.'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const tempalteTypeToParametersMessage = {
  generate: 'You can define here who is subject to the document generation rule',
  request: 'You can define here who is subject to the document request rule ',
  esignature: '',
}

interface GeneralProps {
  templateType?: TemplateType
  handleTemplateChange: (option?: OptionInterface) => void
  requiredParameters: string[]
}

const General = ({
  templateType,
  handleTemplateChange,
  requiredParameters,
}: GeneralProps) => {
  const [loadingQuery, setLoadingQuery] = useState(false)
  const [response, setResponse] = useState<RunQueryResponse | null>(null)
  const { values } = useLapeContext<DocumentsRuleInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.sql_query_db) {
      values.sql_query_db = { id: 'people_ops', name: 'People Ops' }
    }

    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  const runQuery = async () => {
    setLoadingQuery(true)
    setResponse(null)
    try {
      await runDocumentRuleSQL(
        values.sql_query,
        values.document_template,
        values.sql_query_db,
      ).then(res => {
        setResponse(res.data)
      })
    } catch (e) {
      setResponse({
        error: e?.response?.data?.[0] || 'Query timed out',
      })
    }
    setLoadingQuery(false)
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput name="name" label="Rule Name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
            <LapeRadioSelectInput
              name="issuer"
              label="Issued by"
              selector={selectorKeys.employee}
            />
            <LapeNewTextArea name="description" label="Description" rows={3} />
          </InputGroup>

          <NewStepperTitle title="Template" />
          <InputGroup>
            <LapeRadioSelectInput<OptionInterface>
              name="document_template"
              onAfterChange={option => handleTemplateChange(option || undefined)}
              selector={selectorKeys.document_templates}
              label="Template"
            />
            <Input label="Template type" value={templateType?.name ?? ''} disabled />
            <LapeRadioSelectInput
              name="rule_type"
              label="Rule type"
              selector={selectorKeys.document_rule_types}
              required
            />
          </InputGroup>

          <NewStepperTitle title="Parameters" />
          <InputGroup>
            {templateType ? (
              <Cell>
                <Text color={Color.GREY_TONE_50}>
                  {tempalteTypeToParametersMessage[templateType.id]}
                </Text>
              </Cell>
            ) : null}
            {requiredParameters.length !== 0 && (
              <Group>
                <Cell>
                  <Text color={Color.GREY_TONE_50}>
                    The following template parameters must be included in the query
                  </Text>
                </Cell>
                <Cell mt="-s-16">
                  <Flex
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexWrap="wrap"
                  >
                    {requiredParameters.map(parameter => (
                      <Parameter key={parameter}>{parameter}</Parameter>
                    ))}
                  </Flex>
                </Cell>
              </Group>
            )}
            <Box>
              <LapeDatabaseSelect
                selector={selectorKeys.document_template_rule_sql_query_databases}
                initialValue={{ id: 'people_ops', name: 'People Ops' }}
              />
              <LapeNewCodeEditor name="sql_query" placeholder="SQL Template" />
            </Box>
            <Flex style={{ justifySelf: 'start' }}>
              <Button variant="secondary" onClick={runQuery} disabled={loadingQuery}>
                {loadingQuery ? (
                  <Dots height="1em" color="primary" />
                ) : (
                  <Flex>
                    <Play size="1em" color="primary" />
                    <Text pl="0.5em">Run query</Text>
                  </Flex>
                )}
              </Button>
            </Flex>
            {response?.results?.length ? (
              <Box mb="s-16" maxHeight={360} overflow="auto">
                <NewStaticTable
                  rows={{
                    cells: Object.keys(response.results[0]).map(key => ({
                      dataPoint: key,
                      idPoint: key,
                      type: CellTypes.text,
                      title: key,
                      sortKey: null,
                      filterKey: null,
                      width: 200,
                      selectorsKey: selectorKeys.none,
                    })),
                  }}
                  data={response.results}
                  hasScrollableRow
                />
              </Box>
            ) : null}
            {response?.error?.missing_columns && (
              <Box>
                <Text color="warning">Missing mandatory columns:</Text>
                <Flex
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  flexWrap="wrap"
                  mt="s-16"
                >
                  {response?.error?.missing_columns?.map?.((column: string) => (
                    <Parameter key={column}>{column}</Parameter>
                  )) || []}
                </Flex>
              </Box>
            )}
          </InputGroup>

          <NewStepperTitle title="Scheduling" />
          <InputGroup>
            <LapeRadioSelectInput
              name="schedule_type"
              label="Rule schedule"
              selector={selectorKeys.document_rule_schedule_types}
            />
            {values.schedule_type?.id === 'scheduled' ? (
              <LapeNewInput
                name="schedule"
                label="Schedule period"
                message={getScheduleMessage(values)}
                required
              />
            ) : null}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Document rule successfully saved"
          previewUrl={ROUTES.FORMS.DOCUMENT_RULE.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
