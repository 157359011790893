import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, LinkedInPosting } from '@src/interfaces/jobPosting'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  linkedInPostingName,
  linkedInPostingLocation,
  linkedInPostingLinkedInLocation,
  linkedInPostingType,
  linkedInPostingLink,
  linkedInPostingStatus,
  linkedInPostingExpireDate,
  createLinkedInPostingAction,
} from '@src/constants/columns/linkedInPostings'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import { linkedInPostingsRequests } from '@src/api/jobPosting'
import { PageBody } from '@src/components/Page/PageBody'
import { ActionButton, Avatar, Item, VStack } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { TableNames } from '@src/constants/table'
import { isPublished as isJobPostingPublished } from '@src/pages/Forms/JobPosting/utils'
import {
  isPending,
  isRejected,
  isLocationError,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/utils'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import pluralize from 'pluralize'
import { LocationLink } from '@src/pages/Forms/JobPosting/LinkedInPostings/components/LocationLink'

const row = (
  onSubmit: (data: LinkedInPosting) => void,
): RowInterface<LinkedInPosting> => ({
  cells: [
    { ...linkedInPostingName, width: 100 },
    { ...linkedInPostingLocation, width: 100 },
    { ...linkedInPostingLinkedInLocation, width: 100 },
    { ...linkedInPostingType, width: 100 },
    { ...linkedInPostingLink, width: 100 },
    { ...linkedInPostingStatus, width: 100 },
    { ...linkedInPostingExpireDate, width: 100 },
    { ...createLinkedInPostingAction(onSubmit), width: 100 },
  ],
})

type LinkedInTableBannersProps = {
  linkedInPostings: LinkedInPosting[]
}

const LinkedInTableBanners = ({ linkedInPostings }: LinkedInTableBannersProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  if (!isJobPostingPublished(values)) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="ExclamationTriangle" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>Your job postings are not published</Item.Title>
          <Item.Description>
            Publish your job posting in Revolut People to open the LinkedIn job postings
            associated with it
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }
  const locationErrors = linkedInPostings.filter(isLocationError)
  if (locationErrors.length) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="ExclamationTriangle" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>
            City or country information is missing from{' '}
            {locationErrors.map(({ location }) => location.location_name).join(', ')}{' '}
            {pluralize('location', locationErrors.length)}
          </Item.Title>
          <Item.Description>
            In order to publish a job on LinkedIn, city and country should be defined in
            Revolut People for <i>office</i> locations, and country should be defined for{' '}
            <i>remote</i> locations.
            <br />
            Please add the missing data to the following locations and retry:
            <VStack>
              {locationErrors.map(({ location }) => (
                <LocationLink
                  key={location.id}
                  id={location.id}
                  locationName={location.location_name}
                />
              ))}
            </VStack>
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }
  if (linkedInPostings.some(isRejected)) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="ExclamationTriangle" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>One or more postings were rejected by LinkedIn</Item.Title>
          <Item.Description>
            Please edit your job posting to comply with LinkedIn guidelines. Once you
            updated the posting in Revolut People, the new version will be published on
            LinkedIn.
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            useIcon="Pencil"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING.GENERAL, {
              specId: values.specialisation?.id,
              id: values.id,
            })}
          >
            Edit job posting
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  if (linkedInPostings.some(isPending)) {
    return (
      <Item>
        <Item.Prefix>
          <Avatar useIcon="ExclamationTriangle" />
        </Item.Prefix>
        <Item.Content>
          <Item.Title>Some of your job postings are in pending state</Item.Title>
          <Item.Description>
            We sent your job postings to LinkedIn and they will be published shortly after
            review
          </Item.Description>
        </Item.Content>
      </Item>
    )
  }
  return null
}

export const LinkedInTable = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const table = useTable(linkedInPostingsRequests, [
    {
      filters: [
        {
          name: String(values.id),
          id: String(values.id),
        },
      ],
      columnName: 'job_posting',
      nonResettable: true,
    },
  ])
  return (
    <PageWrapper>
      <PageHeader
        title="LinkedIn postings"
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: values.specialisation?.id,
          id: values.id,
        })}
      />
      <PageBody maxWidth="100%">
        <VStack gap="s-16">
          <LinkedInTableBanners linkedInPostings={table.data} />
          <Table.Widget>
            <Table.Widget.Table>
              <AdjustableTable<LinkedInPosting>
                name={TableNames.LinkedInPostings}
                useWindowScroll
                dataType="LinkedIn postings"
                row={row(() => {
                  table.refresh()
                })}
                {...table}
              />
            </Table.Widget.Table>
          </Table.Widget>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
