import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'
import { useCustomHiringProcessFlag } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/utils'
import { HiringProcessWidget } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessWidget'
import { HiringProcessesTable } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/HiringProcessesTable'

export const HiringProcessesWidget = () => {
  const customHiringProcesses = useCustomHiringProcessFlag()
  if (!customHiringProcesses) {
    return <HiringProcessWidget />
  }
  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="NearMe" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Hiring processes</Item.Title>
          <Item.Description>
            Hiring processes that can be used when hiring for this specialisation
          </Item.Description>
        </Item.Content>
      </Item>
      <HiringProcessesTable />
    </>
  )
}
