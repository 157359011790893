import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'

import MetabaseUserRouter from '@src/pages/Forms/AccessRequestsForm/MetabseUser'
import MetabaseTeamRouter from '@src/pages/Forms/AccessRequestsForm/MetabaseTeam'
import UserRequestRouter from '@src/pages/Forms/AccessRequestsForm/Router/UserRequestRouter'
import LookerModelRouter from '@src/pages/Forms/AccessRequestsForm/LookerModel'
import LookerFolderRouter from '@src/pages/Forms/AccessRequestsForm/LookerFolder'

export const AccessRequestConfirmationDialogueProps = {
  label: 'Warning',
  yesMessage: 'Ok',
  noMessage: 'Cancel',
  body: 'Your line manager will receive a slack notification from the people_ops_bot. If your line manager is away please contact #bi-approvers channel',
}

const AccessRequestRouter = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.ACCESS_REQUESTS.GENERAL}>
        <MetabaseUserRouter />
      </Route>
      <Route exact path={ROUTES.FORMS.TEAM_ACCESS_REQUESTS.ANY}>
        <MetabaseTeamRouter />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_REQUESTS.ROUTER}>
        <UserRequestRouter />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_MODEL}>
        <LookerModelRouter />
      </Route>
      <Route exact path={ROUTES.FORMS.ACCESS_REQUESTS.LOOKER_FOLDER}>
        <LookerFolderRouter />
      </Route>
    </Switch>
  )
}

export default connect(AccessRequestRouter)
