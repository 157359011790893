import React from 'react'
import { Item, Text, VStack } from '@revolut/ui-kit'

const RequisitionsIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>What are requisitions? </Item.Title>
        <Item.Description>
          <VStack gap="s-16">
            <Text use="p">
              The <Text fontWeight={500}>Revolut People Recruitment</Text> app is your
              ultimate tool for organised and scalable hiring.
            </Text>
            <Text use="p">
              It all starts with requisitions, which are key to smart recruitment as they
              provide a structured and systematic approach to the hiring of new employees.
              They reflect the open positions within the company that you can track and
              manage, helping to ensure that each hiring decision is made according to
              real business needs. As such, Requisitions serve as a bridge between
              Recruitment and HR, allowing you to efficiently manage your planned
              headcount while integrating it smoothly with your recruitment processes.
            </Text>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default RequisitionsIntro
