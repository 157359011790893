import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import {
  actionItemsProgressColumn,
  employeeRelationColumn,
  fmColumn,
  gradeColumn,
  lmColumn,
  ownerColumn,
  seniorityProgressionColumn,
  statusColumn,
  targetRoleColumn,
} from '@src/features/GrowthPlans/constants/columns'

export const getRow = (): RowInterface<GrowthPlanRelationInterface> => ({
  cells: [
    { ...employeeRelationColumn, width: 300 },
    { ...gradeColumn, width: 150 },
    { ...targetRoleColumn, width: 200 },
    { ...seniorityProgressionColumn, width: 150 },
    {
      ...actionItemsProgressColumn,
      width: 100,
    },
    { ...ownerColumn, width: 100 },
    { ...lmColumn, width: 100 },
    { ...fmColumn, width: 100 },
    {
      ...statusColumn,
      selectorsKey: () =>
        Promise.resolve({
          options: [
            { id: Statuses.pending, name: 'Pending' },
            { id: Statuses.approved, name: 'Approved' },
            { id: Statuses.requires_changes, name: 'Requires changes' },
          ],
        }),
      width: 100,
    },
  ],
})
