import { IdAndName } from '.'

export enum ImportSessionStatus {
  Valid = 'valid',
  Invalid = 'invalid',
  InProgress = 'in_progress',
  Completed = 'completed',
  CompletedWithErrors = 'completed_with_errors',
  Failed = 'failed',
}

export interface ImportSessionStats {
  id: number
  status: IdAndName<ImportSessionStatus>
  invalid_count: number
  total_count: number
  progress: number
  error_count: number
  errors: Record<string, string[] | undefined>
}
