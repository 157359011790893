import React from 'react'
import { TextSkeleton, Token } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { API } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TenantDiscountInterface } from '@src/interfaces/tenants'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const useAffectedCycles = (id: string, startDate?: string, endDate?: string) => {
  const result = useFetch<{ start_date: string; end_date: string }[]>(
    `${API.TENANTS}/${id}/discounts/affectedCycles`,
    'v2',
    {
      params: {
        start_date: startDate,
        end_date: endDate,
      },
    },
    true,
    { enabled: !!startDate && !!endDate },
  )
  return { cycles: result.data, ...result }
}

export const AffectedCycles = () => {
  const { values } = useLapeContext<TenantDiscountInterface>()
  const params = useParams<{ id: string; discountId?: string; subscriptionId: string }>()
  const { cycles, isLoading, error } = useAffectedCycles(
    params.id,
    values.start_date,
    values.end_date,
  )

  return (
    <ActionWidget
      title="Discount will be applied to following cycles"
      avatarColor={Token.color.warning}
      text={(() => {
        if (!values.start_date || !values.end_date) {
          return `Affected cycles will appear here`
        }
        if (isLoading) {
          return <TextSkeleton />
        }
        if (error) {
          return `Failed to get affected cycles: ${getStringMessageFromError(error)}`
        }
        if (cycles?.length) {
          return (
            <ul style={{ paddingInlineStart: 16, margin: 0 }}>
              {cycles.map(c => (
                <li key={`${c.start_date}${c.end_date}`}>
                  {c.start_date} - {c.end_date}
                </li>
              ))}
            </ul>
          )
        }
        return 'No cycles affected'
      })()}
    />
  )
}
