import React, { useState, useMemo, useCallback } from 'react'
import { Box, Overlay, TransitionSlide } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'

import { CookieConsentInitial } from './CookieConsentInitial'
import { CookieConsentPreferences } from './CookieConsentPreferences'
import type { CookieConsentModalProps } from './types'
import { CookieConsentModalStep } from './types'
import { useCookieConsentContext } from '@src/pages/Landing/hooks/useCookieConsentContext'

const CookieConsentModalBase = styled(Box)(
  css({
    backgroundColor: 'grey-tone-5',
    borderRadius: ['15px 15px 0 0', null, 15],
    bottom: [0, null, 40],
    maxHeight: ['90%', null, 500],
    minWidth: '320px',
    overflow: 'auto',
    padding: 's-24',
    position: 'fixed',
    right: [null, null, 40],
    width: ['100%', null, 400, null, null, 500],
  }),
)

export const CookieConsentModal = ({
  step: initialStep = CookieConsentModalStep.Initial,
}: CookieConsentModalProps) => {
  const {
    isAnalyticsEnabled,
    isBannerDisplayed,
    setIsAnalyticsEnabled,
    setIsBannerDisplayed,
  } = useCookieConsentContext()
  const [step, setStep] = useState(initialStep)

  const handleSaveCookies = useCallback(
    (nextAnalyticsValue: boolean) => {
      setIsBannerDisplayed(true)
      setIsAnalyticsEnabled(nextAnalyticsValue)
      // TODO: maybe reload route?
    },
    [setIsBannerDisplayed, setIsAnalyticsEnabled],
  )

  const popupStepComponent = useMemo(() => {
    switch (step) {
      case CookieConsentModalStep.Preferences:
        return (
          <CookieConsentPreferences
            isAnalyticsEnabled={isAnalyticsEnabled}
            onClickBack={() => setStep(CookieConsentModalStep.Initial)}
            onClickSave={handleSaveCookies}
          />
        )

      case CookieConsentModalStep.Initial:
      default:
        return (
          <CookieConsentInitial
            onClickAllow={() => handleSaveCookies(true)}
            onClickReject={() => handleSaveCookies(false)}
            onClickManage={() => setStep(CookieConsentModalStep.Preferences)}
          />
        )
    }
  }, [step, isAnalyticsEnabled, handleSaveCookies])

  return (
    <Overlay
      closeOnOverlayClick={false}
      open={!isBannerDisplayed}
      role="dialog"
      aria-label="Cookie preferences"
      scrollLock={false}
      className="CookieConsent cookie-consent-widget"
    >
      <TransitionSlide in offsetY={-24}>
        <CookieConsentModalBase>{popupStepComponent}</CookieConsentModalBase>
      </TransitionSlide>
    </Overlay>
  )
}
