import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import React, { useState } from 'react'
import { Statuses } from '@src/interfaces'
import { successNotification } from '@src/actions/NotificationActions'
import { MoreBar } from '@revolut/ui-kit'
import { handleError } from '@src/api'

type Props = {
  isVisible?: boolean
}

const ReopenRequisitionButton = ({ isVisible }: Props) => {
  const { initialValues, submit, values } = useLapeContext<RequisitionInterface>()
  const [submitting, setSubmitting] = useState(false)

  const reopen = () => {
    setSubmitting(true)
    let statusCopy = values.status
    values.status = Statuses.opened
    submit()
      .then(() => {
        successNotification('Requisition opened')
      })
      .catch(error => {
        values.status = statusCopy
        handleError(error)
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (initialValues.status !== Statuses.closed || !isVisible) {
    return null
  }
  return (
    <MoreBar.Action useIcon="ArrowRepeat" onClick={reopen} pending={submitting}>
      Reopen requisition
    </MoreBar.Action>
  )
}

export default ReopenRequisitionButton
