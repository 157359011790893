import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { documentsTemplateFormRequests } from '@src/api/documentsTemplates'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'

import { EditDocumentsDefaultTemplate } from './Edit/Default'
import { EditDocumentsESignatureTemplate } from './Edit/ESignature'

export const DocumentsTemplate = () => {
  return (
    <Switch>
      <Form api={documentsTemplateFormRequests}>
        <Route path={ROUTES.APPS.DOCUMENTS.TEMPLATES.EDIT.ANY}>
          <EditDocumentsDefaultTemplate />
        </Route>
        <Route path={ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.ANY}>
          <EditDocumentsESignatureTemplate />
        </Route>
      </Form>
    </Switch>
  )
}
