import React from 'react'
import SchedulingActionButton, {
  SchedulingActionButtonProps,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/SchedulingActionButton'

interface Props
  extends Pick<SchedulingActionButtonProps, 'menuType' | 'disabled' | 'onProceed'> {}

const RescheduleInterviewButton = ({ menuType, disabled, onProceed }: Props) => (
  <SchedulingActionButton
    label="Reschedule interview"
    title="Reschedule interview?"
    description="The current interview will be cancelled only after you have completed the rescheduling. We will notify the interviewer (and candidate if they are involved) about the rescheduling."
    goBackLabel="Cancel"
    onProceed={onProceed}
    disabled={disabled}
    menuType={menuType}
  />
)

export default RescheduleInterviewButton
