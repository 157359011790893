import React, { useState } from 'react'
import { Popup, Header, Button, TextArea } from '@revolut/ui-kit'

interface Props {
  onClose: () => void
  onSubmit: (comment: string) => void
}

export const EditScorecardPopup = ({ onClose, onSubmit }: Props) => {
  const [value, setValue] = useState('')

  return (
    <Popup labelButtonClose="Close" onClose={onClose} open variant="modal-view">
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Request edits</Header.Title>
        <Header.Description>
          Please explain below the reason edits are required and what should be changed:
        </Header.Description>
      </Header>
      <TextArea
        label="Comments"
        onChange={e => setValue(e.currentTarget.value)}
        rows={5}
        value={value}
      />
      <Popup.Actions>
        <Button disabled={!value} elevated onClick={() => onSubmit(value)}>
          Send
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
