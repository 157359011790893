import React from 'react'
import { useSelector } from 'react-redux'

import { PaygroupTable } from '@src/apps/People/Payroll/PaygroupTable/PaygroupTable'
import { PayGroupTable as PayGroupTableV2 } from '@src/apps/People/PayrollV2/PayGroup/PayGroupTable'
import { PageBody } from '@src/components/Page/PageBody'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const PayGroups = () => {
  const featureFlages = useSelector(selectFeatureFlags)
  const isPayrollV2 = featureFlages.includes(FeatureFlags.PayrollV2)

  return (
    <PageBody maxWidth="100%" mt="s-32">
      {isPayrollV2 ? <PayGroupTableV2 /> : <PaygroupTable />}
    </PageBody>
  )
}
