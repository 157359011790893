import React from 'react'
import { getScorecardCommentsApi } from '@src/api/performanceReview'
import ChatSidebar from '@components/Chat/ChatSidebar'
import { ChatMessageType } from '@components/Chat/common'

interface Props {
  onClose: () => void
  scorecardId: number
}

export const ScorecardComments = ({ scorecardId, onClose }: Props) => {
  const { useGetComments, ...api } = getScorecardCommentsApi(scorecardId)
  const comments = useGetComments()

  return (
    <ChatSidebar
      canAddComments={false}
      canEdit={false}
      data={comments.data?.results || null}
      disableTodolistFeature
      isOpen
      isLoading={comments.isLoading}
      onAddMessage={api.addComment}
      onArchive={api.archiveComment}
      onClose={onClose}
      onEdit={api.editComment}
      onResolve={() => Promise.resolve()}
      refetch={comments.refetch}
      type={ChatMessageType.Comment}
    />
  )
}
