import React from 'react'
import { Badge, HStack, Icon, Spinner, Token } from '@revolut/ui-kit'

interface Props {
  isEdited: boolean
  isLoading: boolean
}
export const UpdatedContentBadge = ({ isEdited, isLoading }: Props) => {
  return isEdited || isLoading ? (
    <Badge backgroundColor={Token.color.widgetBackground} color={Token.color.foreground}>
      <HStack gap="s-2" align="center" pr="s-4">
        {isLoading ? <Spinner size={16} /> : <Icon name="Check" size={16} />}
        {isLoading ? 'Updating' : 'Updated'}
      </HStack>
    </Badge>
  ) : null
}
