import { useDeleteCycle } from '@src/api/reviewCycles'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import React from 'react'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  cycle: ReviewCyclesInterface
}

export const DeleteCycleAction = ({ cycle }: Props) => {
  const { mutateAsync: deleteCycle, isLoading } = useDeleteCycle()
  const showStatusPopup = useShowStatusPopup()

  const handleDeleteCycle = async () => {
    try {
      await deleteCycle(cycle.id)
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: `${cycle.name} cycle was not deleted`,
        description: getStringMessageFromError(err, 'Something went wrong'),
      })
    }
  }

  return (
    <NewSaveButtonWithPopup<ReviewCyclesInterface>
      confirmationDialogue={{
        body: (
          <Text>
            This will also delete all related objects such as scorecards and grades.{' '}
            <Text fontWeight={800}>This action is not reversible.</Text>
          </Text>
        ),
      }}
      variant="negative"
      useIcon="Delete"
      successText={`${cycle.name} cycle is successfully deleted`}
      isMoreAction
      pending={isLoading}
      onAfterSubmit={() => navigateTo(ROUTES.PERFORMANCE.REVIEWS_CYCLES)}
      onClick={handleDeleteCycle}
    >
      Delete cycle
    </NewSaveButtonWithPopup>
  )
}
