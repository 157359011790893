import React, { useEffect, useState } from 'react'
import {
  Button,
  StatusPopup,
  useStatusPopup,
  VStack,
  Text,
  Token,
  Image,
  TextButton,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { selectUser } from '@src/store/auth/selectors'
import {
  accountClosureConfirm,
  accountClosureSendConfirmationEmail,
} from '@src/api/plans'
import { navigateReplace } from '@src/actions/RouterActions'
import { useQuery } from '@src/utils/queryParamsHooks'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { dispatch } from '@src/utils/store'
import { setSubscriptionStateAction } from '@src/store/auth/actions'

const TOKEN_KEY = 'token'
const COOLDOWN_TIME_IN_SECONDS = 30

export const Confirmation = () => {
  const { query, deleteQueryParam } = useQuery()
  const statusPopup = useStatusPopup()
  const user = useSelector(selectUser)

  const [cooldownTimeSeconds, setCooldownTimeSeconds] = useState(0)
  const [submitPending, setSubmitPending] = useState(false)
  const [confirmationToken, setConfirmationToken] = useState<string | undefined>(
    query[TOKEN_KEY],
  )

  useEffect(() => {
    if (confirmationToken) {
      statusPopup.show(
        <StatusPopup variant="loading" preventUserClose>
          <StatusPopup.Title>Closing account</StatusPopup.Title>
        </StatusPopup>,
      )

      accountClosureConfirm(confirmationToken)
        .then(() => {
          dispatch(setSubscriptionStateAction('closing'))
          statusPopup.hide()
          navigateReplace(ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE)
        })
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error" onClose={statusPopup.hide}>
              <StatusPopup.Title>Failed to close account</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
              <StatusPopup.Actions>
                <Button onClick={statusPopup.hide} elevated>
                  Try again
                </Button>
              </StatusPopup.Actions>
            </StatusPopup>,
          )
        })

      setConfirmationToken(undefined)
      deleteQueryParam(TOKEN_KEY)
    } else {
      setSubmitPending(true)
      accountClosureSendConfirmationEmail()
        .then(() => {
          setCooldownTimeSeconds(COOLDOWN_TIME_IN_SECONDS)
        })
        .catch(() => {
          showFailedToSendConfirmationPopup()
        })
        .finally(() => {
          setSubmitPending(false)
        })
    }
  }, [])

  useEffect(() => {
    if (cooldownTimeSeconds > 0) {
      const timeout = setTimeout(() => {
        setCooldownTimeSeconds(cooldownTimeSeconds - 1)
      }, 1000)

      return () => {
        clearTimeout(timeout)
      }
    }
    return undefined
  }, [cooldownTimeSeconds])

  const onSubmit = () => {
    if (submitPending) {
      return
    }

    setSubmitPending(true)

    accountClosureSendConfirmationEmail()
      .then(() => {
        setCooldownTimeSeconds(COOLDOWN_TIME_IN_SECONDS)
        statusPopup.show(
          <StatusPopup variant="success-result" onClose={statusPopup.hide}>
            <StatusPopup.Title>
              Email to confirm closing of account was sent to your email
            </StatusPopup.Title>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .catch(() => {
        showFailedToSendConfirmationPopup()
      })
      .finally(() => {
        setSubmitPending(false)
      })
  }

  const showFailedToSendConfirmationPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error" onClose={statusPopup.hide}>
        <StatusPopup.Title>Failed to send confirmation email</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide} elevated>
            Try again
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Check your email"
        subtitle={
          <Text color={Token.color.foreground} display="block" mt="s-8" variant="primary">
            Click the link we sent to {user.email} to confirm account closure
          </Text>
        }
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody>
        <VStack align="center">
          <Image
            src="https://assets.revolut.com/assets/3d-images/3D001.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D001@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D001@3x.png 3x"
            maxWidth={300}
          />
          <TextButton
            onClick={onSubmit}
            disabled={submitPending || cooldownTimeSeconds > 0}
          >
            Resend email
            {cooldownTimeSeconds > 0
              ? ` in 00:${`${cooldownTimeSeconds}`.padStart(2, '0')}`
              : ''}
          </TextButton>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}
