import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { ColumnCellInterface } from '@src/interfaces/data'
import { candidateBulkUnsnoozeRequests } from '@src/api/hiringProcess'
import { CandidateBulkUnsnoozeInterface } from '@src/interfaces/hiringProccess'

const columns: ColumnCellInterface<CandidateBulkUnsnoozeInterface>[] = []

const CandidateBulkUnsnooze = () => {
  return (
    <BulkActions<CandidateBulkUnsnoozeInterface>
      tableRequest={candidateBulkUnsnoozeRequests}
      columns={columns}
      dataType="Candidate unsnooze bulk actions"
    />
  )
}

export default CandidateBulkUnsnooze
