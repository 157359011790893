import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { useGetNotCompletedReviews } from '@src/api/performanceReview'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FormTitle } from './FormTitle'
import { PerformanceHeaderNavigationBar } from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeaderNavigationBar'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { useParams } from 'react-router-dom'
import HelpSidebar from '@src/pages/Forms/EmployeePerformance/components/HelpSidebar'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import {
  CombinedHelp,
  HelpTabs,
} from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { ItemSkeleton, VStack } from '@revolut/ui-kit'
import { relationToString } from '@src/features/Scorecard/constants'
import { PreviewScorecardRoleInterface } from '@src/pages/Performance/Scorecard/ScorecardSetup/previewState'

export const PerformanceHeader = connect(
  ({
    isHelpOpen,
    setHelpOpen,
    forceEmployeeId,
    variant = 'general',
    role,
  }: {
    isHelpOpen?: HelpTabs
    setHelpOpen?: React.Dispatch<React.SetStateAction<HelpTabs | undefined>>
    forceEmployeeId?: number
    variant?: 'onboarding' | 'general'
    role?: PreviewScorecardRoleInterface
  }) => {
    const { employeeId } = useParams<{ employeeId: any }>()
    const { values } = useLapeContext<ReviewScorecardInterface>()
    const user = useSelector(selectUser)
    const reviews = useGetNotCompletedReviews(variant === 'general' ? user.id : undefined)
    const { data: employee } = useGetEmployeeWithManagerFilter(
      forceEmployeeId || employeeId,
      'line_manager__id',
    )

    const isSummary = (
      review: ReviewScorecardInterface | ReviewSummaryInterface,
    ): review is ReviewSummaryInterface =>
      (review as ReviewSummaryInterface).summary !== undefined

    const data = isSummary(values) ? values.reviews[0] : values

    const getTitle = () => {
      switch (data?.category) {
        case ReviewCategory.Upwards:
          return 'Upwards Review'
        case ReviewCategory.Probation:
          return `Probation Review · CP${data.probation_checkpoint?.number}`
        case ReviewCategory.PIP_V2:
        case ReviewCategory.PIP:
          return 'PIP Checkpoint'
        default:
          return 'Performance Review'
      }
    }

    if (!employee) {
      return <ItemSkeleton height={114} />
    }

    const reviewedEmployee = {
      ...employee,
      team: values.team || employee.team,
      specialisation: role || employee.specialisation,
      job_title: role?.name || employee.job_title,
    }

    const cycle = data?.cycle ? `${data.cycle.name} ` : ''
    const relation = data?.reviewer_relation
      ? ` (${relationToString(data.reviewer_relation, true)})`
      : ''

    return (
      <>
        <VStack space="s-16" mb="s-16">
          {reviews?.data && (
            <PerformanceHeaderNavigationBar reviews={reviews.data} noMaxWidth />
          )}
          <FormTitle
            variant={variant}
            data={reviewedEmployee}
            status={values.status || 'completed'}
            reviewTitle={`${cycle}${getTitle()}${relation}`}
          />
        </VStack>
        <HideIfCommercial>
          <HelpSidebar
            isOpen={!!isHelpOpen}
            helpSection={<CombinedHelp initialTab={isHelpOpen} />}
            onExit={() => setHelpOpen?.(undefined)}
          />
        </HideIfCommercial>
      </>
    )
  },
)
