import { useTableReturnType } from '@components/TableV2/hooks'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { Stats } from '@src/interfaces/data'
import React, { useMemo } from 'react'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapOwnerColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { useRoadmapContext } from '@src/features/Goals/Roadmaps/RoadmapTab'
import { TableNames } from '@src/constants/table'
import { EmptyRoadmapsTable } from '@src/features/Goals/components/EmptyRoadmapsTable'

interface QuarterRoadmapsTableProps {
  table: useTableReturnType<RoadmapInterface, Stats, {}>
}

export const QuarterRoadmapsTable = ({ table }: QuarterRoadmapsTableProps) => {
  const roadmapRow = useMemo(
    () => ({
      cells: [
        {
          ...roadmapGenericNameColumn,
          width: 350,
        },
        {
          ...roadmapProgressColumn,
          width: 100,
        },
        {
          ...roadmapStatusColumn,
          width: 100,
        },
        {
          ...roadmapStartDate,
          width: 100,
        },
        {
          ...roadmapDueDate,
          width: 100,
        },
        {
          ...roadmapResolutionAtColumn,
          width: 100,
        },
        {
          ...roadmapOwnerColumn,
          width: 100,
        },

        {
          ...roadmapEpicUrlColumn,
          width: 100,
        },
      ],
    }),
    [],
  )

  const ctx = useRoadmapContext()
  return (
    <AdjustableTable<RoadmapInterface>
      name={TableNames.DepartmentRoadmap}
      row={roadmapRow}
      hideCountAndButtonSection
      hideHeader={table.data?.length === 0}
      emptyState={<EmptyRoadmapsTable />}
      dataType={ctx.dataType}
      pendingDataType={ctx.isLoading}
      expandableType="chevron"
      {...table}
    />
  )
}
