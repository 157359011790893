import React from 'react'
import { Avatar, AvatarSize, Skeleton } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { CompanyAvatar } from '@src/features/UserAvatarWithMenu/CompanyAvatar'
import { getAvatarUrl } from '@src/utils/employees'

interface UserAvatarWithMenuProps {
  user: EmployeeInterface
  showCompanyLogo?: boolean
  size?: AvatarSize
}

// TODO: https://revolut.atlassian.net/browse/REVCOR-2756 remove
export const UserCompanyAvatar = ({
  user,
  showCompanyLogo,
  size = 32,
}: UserAvatarWithMenuProps) => {
  if (!user?.id) {
    return <Skeleton size={size} />
  }

  const userInitials = user.avatar
    ? null
    : `${user.first_name?.[0] || ''}${user.last_name?.[0] || ''}`

  return (
    <>
      {showCompanyLogo ? (
        <CompanyAvatar
          size={size}
          userAvatar={user.avatar}
          userInitials={userInitials?.[0]}
        />
      ) : (
        <Avatar image={getAvatarUrl(user.avatar)} size={size}>
          {userInitials}
        </Avatar>
      )}
    </>
  )
}
