import React, { useState } from 'react'
import { GoalsPreviewHeader } from './Header'
import { GoalsPreviewContent } from './GoalPreviewContent'
import { PageWrapper } from '@src/components/Page/Page'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { captureException } from '@sentry/react'
import { StatusWidget, Token } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { UpdateTypes } from '@src/interfaces/kpis'
import { useQuery } from '@src/utils/queryParamsHooks'
import get from 'lodash/get'
import set from 'lodash/set'
import { GoalCycleSelectType } from './types'

const isReviewCycle = (cycle: GoalCycleSelectType): cycle is ReviewCyclesInterface => {
  return !!cycle?.id
}

const getGoalTargetsCycles = (goal: GoalPreviewInterface): GoalCycleSelectType[] => {
  const uniqueCycleIds: Record<number, never> = {}

  const goalCycles = (
    goal.update_type?.id === 'aggregated'
      ? goal.goal_cycles.map(goalCycle => {
          const cycle = goalCycle.review_cycle || goalCycle.employee_cycle
          if (!cycle || get(uniqueCycleIds, cycle.id)) {
            return undefined
          }
          set(uniqueCycleIds, cycle.id, true)
          return cycle as ReviewCyclesInterface
        })
      : (goal.kpis || []).map(kpi => {
          const target =
            kpi.update_type === UpdateTypes.roadmap ||
            kpi.update_type === UpdateTypes.clickup
              ? kpi.target_epics?.[0]
              : kpi.targets?.[0]

          const cycle = target?.review_cycle || target?.employee_cycle

          if (!cycle || get(uniqueCycleIds, cycle.id)) {
            return undefined
          }

          set(uniqueCycleIds, cycle.id, true)
          return cycle as ReviewCyclesInterface
        })
  )
    .filter(Boolean)
    .sort((a, b) => Number(b.offset) - Number(a.offset))

  return [{ id: undefined, name: 'All cycles', offset: undefined }, ...goalCycles]
}

export const GoalPreviewPage = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const { query } = useQuery()
  const defaultCycleId = query.cycle__id
  const [availableCycles] = useState(getGoalTargetsCycles(values))

  const baseCycle = availableCycles.find(({ offset, id }) =>
    defaultCycleId ? Number(id) === Number(defaultCycleId) : offset === 0,
  )

  const initialCycle = baseCycle || availableCycles[0]

  const [cycle, setCycle] = useState<GoalCycleSelectType>(initialCycle)

  return (
    <PageWrapper>
      <GoalsPreviewHeader
        cycle={isReviewCycle(cycle) ? cycle : undefined}
        baseCycleId={cycle.id || baseCycle?.id}
      />
      <PageBody maxWidthMd={Token.breakpoint.xxl}>
        {cycle ? (
          <GoalsPreviewContent
            cycle={cycle}
            availableCycles={availableCycles}
            onCycleChanged={id => {
              const newCycle = availableCycles.find(c => c.id === id)!
              setCycle(newCycle)
              if (!newCycle) {
                // should not ever happen
                const meta = {
                  cycleId: id,
                  availableCyclesId: availableCycles.map(c => c.id),
                }
                captureException(`Failed to update cycle in the goal preview page`, {
                  extra: meta,
                })
              }
            }}
          />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
              }}
            />
            <StatusWidget.Title>This goal does not have a cycle</StatusWidget.Title>
            <StatusWidget.Description>
              In order to show this goal data, ensure it has a valid cycle selected
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </PageBody>
    </PageWrapper>
  )
}
