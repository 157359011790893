import React, { useEffect, useState } from 'react'
import isThisMonth from 'date-fns/isThisMonth'
import {
  Flex,
  Icon,
  StatusWidget,
  Text,
  TextSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import { useUpdatePayrunSchedule } from '@src/api/payrollV2'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PayGroupInterface } from '@src/interfaces/payrollV2'
import { formatDate } from '@src/utils/format'
import { formatValuesToGetPayrunSchedule } from './helpers'

const DateSkeleton = <TextSkeleton width={52} height="s-16" use="span" />
const DatesRangeSkeleton = (
  <TextSkeleton
    width={120}
    height="s-16"
    use="span"
    ml="s-4"
    style={{ display: 'inline-flex' }}
  />
)

export const PayrunScheduleWidget = () => {
  const { values } = useLapeContext<PayGroupInterface>()

  const [scheduleFailed, setScheduleFailed] = useState(false)

  const handleScheduleErr = () => {
    setScheduleFailed(true)
  }

  const { mutateAsync: updateSchedule, data } = useUpdatePayrunSchedule(handleScheduleErr)
  const paymentsSchedulesByMonts = data && Array.isArray(data.data) ? data.data : []

  useEffect(() => {
    updateSchedule(formatValuesToGetPayrunSchedule(values))
      .then(() => {
        setScheduleFailed(false)
      })
      .catch(handleScheduleErr)
  }, [
    values.pay_date_day,
    values.pay_frequency,
    values.pay_date_schedule,
    values.pay_period_start_day,
    values.cut_off_date_trigger,
    values.cut_off_date_trigger_number_of_days,
    values.pay_date_lands_on_weekend,
  ])

  const currentPayrunScheduleIndex = paymentsSchedulesByMonts.findIndex(schedule =>
    isThisMonth(new Date(schedule.start_date)),
  )
  const currentPayrunSchedule =
    currentPayrunScheduleIndex >= 0
      ? paymentsSchedulesByMonts[currentPayrunScheduleIndex]
      : undefined
  const nextPayrunSchedule = paymentsSchedulesByMonts[currentPayrunScheduleIndex + 1]

  const formatDateString = (date?: string, format?: string) => {
    if (!date) {
      return undefined
    }
    return formatDate(date, format)
  }
  const currentPayrunCutOffDate = formatDateString(currentPayrunSchedule?.cut_off_date)
  const nextPayrunStartDate = formatDateString(nextPayrunSchedule?.start_date, 'd MMM')
  const nextPayrunCutOffDate = formatDateString(nextPayrunSchedule?.cut_off_date)
  const nextPayrunPayDate = formatDateString(nextPayrunSchedule?.pay_date)
  const nextPayrunEndDate = formatDateString(nextPayrunSchedule?.end_date)

  if (scheduleFailed) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D026.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D026@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D026@3x.png',
          }}
        />
        <StatusWidget.Title>Could not generate timeline</StatusWidget.Title>
        <StatusWidget.Description>
          Please, fill all inputs above to see how pay run timeline will look like
        </StatusWidget.Description>
      </StatusWidget>
    )
  }
  return (
    <Widget p="s-16">
      <VStack width="100%" space="s-16">
        <Flex width="100%" flex="1 0" justifyContent="space-between">
          <Text variant="primary">Next pay run</Text>
          <Text variant="primary">
            {nextPayrunStartDate && nextPayrunEndDate ? (
              <>
                {nextPayrunStartDate} - {nextPayrunEndDate}
              </>
            ) : (
              DatesRangeSkeleton
            )}
          </Text>
        </Flex>
        <Text>
          The changes tracked are always from last cycle’s cut off date, to the cut off
          date of the current cycle. In this case changes would be{' '}
          {currentPayrunCutOffDate && nextPayrunCutOffDate ? (
            <>
              from the {currentPayrunCutOffDate} to {nextPayrunCutOffDate}
            </>
          ) : (
            ' ...'
          )}
        </Text>
        <Flex justifyContent="space-around">
          <VStack align="center" space="s-8">
            <Icon color={Token.color.blue} name="CalendarDate" />
            <VStack align="center">
              <Text>Pay run start</Text>
              <Text>{nextPayrunStartDate || DateSkeleton}</Text>
            </VStack>
          </VStack>
          <VStack align="center" space="s-8">
            <Icon color={Token.color.blue} name="CalendarWeek" />
            <VStack align="center">
              <Text>Cut off date</Text>
              <Text>{nextPayrunCutOffDate || DateSkeleton}</Text>
            </VStack>
          </VStack>
          <VStack align="center" space="s-8">
            <Icon color={Token.color.blue} name="CalendarCoins" />
            <VStack align="center">
              <Text>Pay day</Text>
              <Text>{nextPayrunPayDate || DateSkeleton}</Text>
            </VStack>
          </VStack>
          <VStack align="center" space="s-8">
            <Icon color={Token.color.blue} name="CalendarCheck" />
            <VStack align="center">
              <Text>End of pay run</Text>
              <Text>{nextPayrunEndDate || DateSkeleton}</Text>
            </VStack>
          </VStack>
        </Flex>
      </VStack>
    </Widget>
  )
}
