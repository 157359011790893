import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, chain, Flex, Input, Skeleton, Text, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { Search as SearchIcon } from '@revolut/icons'
import uniqBy from 'lodash/uniqBy'
import debounce from 'lodash/debounce'
import { CAREERS_TEAM_OTHER, CareersPositionLocation } from '@src/interfaces/careers'
import pluralize from 'pluralize'
import { useQuery } from '@src/utils/queryParamsHooks'
import CareersLocationFilter from '@src/pages/Careers/CareersJobList/CareersLocationFilter'
import CareersTeamFilter from '@src/pages/Careers/CareersJobList/CareersTeamFilter'
import CareersPositions from '@src/pages/Careers/CareersJobList/CareersPositions'
import { useCareersSettings, useFetchCareersList } from '@src/api/recruitment/careers'

export const Grid = styled(Flex)(
  css({
    display: 'grid',
    columnGap: 's-8',
    rowGap: ['s-16', null, null, 's-56'],
    gridTemplateColumns: ['auto', null, null, '272px 1fr'],
    justifyContent: 'stretch',
    width: '100%',
  }),
)

const PositionsWrapper = styled(Box)<{ fullWidth?: boolean }>(({ fullWidth }) =>
  css({
    order: 4,
    ...(fullWidth && {
      gridColumn: [null, null, null, 'span 2'],
    }),
  }),
)

const filterByLocation = (
  locations: CareersPositionLocation[],
  filter?: CareersPositionLocation,
) => {
  return (
    !filter ||
    locations.some(
      location =>
        location.name.toLowerCase() === filter.name.toLowerCase() ||
        location?.country?.toLowerCase() === filter.name.toLowerCase(),
    )
  )
}

const filterByTeam = (team: string, filter?: string) => {
  return !filter || team === filter
}

const filterByTitle = (title: string, filter: string) => {
  return (
    filter === '' ||
    filter
      .toLowerCase()
      .split(' ')
      .every(word => title.toLowerCase().includes(word))
  )
}

const CareersJobList = () => {
  const { query, changeQueryParam } = useQuery()
  const [locationFilter, setLocationFilter] = useState<CareersPositionLocation>()
  const [titleFilter, setTitleFilter] = useState<string>('')
  const [teamFilter, setTeamFilter] = useState<string>()
  const { data: positions, isLoading, isError } = useFetchCareersList()
  const { data: settings } = useCareersSettings()

  const allLocations = useMemo(() => {
    return uniqBy(
      positions?.flatMap(position => position.locations),
      'name',
    ).sort((a, b) => a.name.localeCompare(b.name))
  }, [positions])

  const hasOtherTeam = useMemo(() => {
    return positions?.some(position => position.team === CAREERS_TEAM_OTHER)
  }, [positions])

  const filteredByTitleAndLocation = useMemo(() => {
    if (titleFilter === '' && !locationFilter) {
      return positions
    }
    return positions?.filter(
      ({ text, locations }) =>
        filterByTitle(text, titleFilter) && filterByLocation(locations, locationFilter),
    )
  }, [positions, titleFilter, locationFilter])

  const filteredByLocationAndTeam = useMemo(() => {
    if (!locationFilter && !teamFilter) {
      return positions
    }
    return positions?.filter(
      ({ locations, team }) =>
        filterByLocation(locations, locationFilter) && filterByTeam(team, teamFilter),
    )
  }, [positions, locationFilter, teamFilter])

  const filteredByAll = useMemo(() => {
    if (!teamFilter) {
      return filteredByTitleAndLocation
    }
    return filteredByTitleAndLocation?.filter(({ team }) =>
      filterByTeam(team, teamFilter),
    )
  }, [filteredByTitleAndLocation, teamFilter])

  useEffect(() => {
    const text = query.text
    if (!text || (text && typeof text === 'string')) {
      setTitleFilter(text || '')
    }
  }, [])

  const debouncedQueryUpdate = useCallback(
    debounce(value => changeQueryParam('text', value || ''), 300),
    [changeQueryParam],
  )

  const handleChange = (value?: string) => {
    setTitleFilter(value || '')
    debouncedQueryUpdate(value)
  }

  return (
    <Box width="100%">
      <>
        <Text variant="h0" textAlign="center" mb="s-32" display="block">
          We have{' '}
          {isLoading ? (
            <Skeleton
              variant="h0"
              width="5%"
              display="inline-block"
              style={{ verticalAlign: 'middle' }}
              height={36}
            />
          ) : (
            positions?.length
          )}{' '}
          open {pluralize('position', positions?.length)}
        </Text>
        {settings?.slogan && (
          <Text
            variant="h4"
            textAlign="center"
            color={Token.color.greyTone50}
            mb="s-48"
            display="block"
            style={{
              hyphens: 'auto',
            }}
          >
            {settings.slogan}
          </Text>
        )}
      </>
      <Grid>
        <Box order={{ all: 2, lg: 1 }}>
          <CareersLocationFilter
            locations={allLocations}
            onChange={setLocationFilter}
            value={locationFilter}
          />
        </Box>
        <Box order={{ all: 1, lg: 2 }}>
          <Input
            useIcon={SearchIcon}
            label={`Search from ${filteredByLocationAndTeam?.length} open ${pluralize(
              'position',
              filteredByLocationAndTeam?.length,
            )}`}
            onChange={(e: FormEvent<HTMLInputElement>) =>
              handleChange(e.currentTarget.value)
            }
            value={titleFilter}
            onClear={() => handleChange('')}
          />
        </Box>
        <Box order={3}>
          <CareersTeamFilter
            positions={filteredByTitleAndLocation || []}
            onChange={setTeamFilter}
            hasOtherTeam={!!hasOtherTeam}
          />
        </Box>
        <PositionsWrapper>
          <Text use="div" variant="small" color="grey-tone-50" fontWeight={600}>
            {titleFilter
              ? chain('Search results', filteredByAll?.length)
              : 'Recent roles'}
          </Text>
          <CareersPositions
            positions={filteredByAll || []}
            loading={isLoading}
            error={isError}
          />
        </PositionsWrapper>
      </Grid>
    </Box>
  )
}

export default CareersJobList
