import { teamsRequestsNew } from '@src/api/teams'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'
import General from './General'

const TeamSettingsPage = () => {
  const params = useParams<{ id: string }>()

  const isEditing = params.id !== undefined

  const backUrl = isEditing
    ? pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: String(params?.id) })
    : ROUTES.ORGANISATION.TEAMS.TEAMS

  const title = isEditing ? 'Edit Team' : 'New Team'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <Form api={teamsRequestsNew}>
        <General />
      </Form>
    </PageWrapper>
  )
}

export default TeamSettingsPage
