import React, { useState } from 'react'
import DOMPurify from 'dompurify'
import { Flex, Box, TextButton } from '@revolut/ui-kit'

const useShowMoreDescription = (body?: string, testId?: string) => {
  const [showMore, setShowMore] = useState(false)

  if (!body) {
    return null
  }

  const isLongComment = body.split('\n').length > 4 || body.length > 400

  const description = isLongComment ? (
    <Flex flexDirection="column">
      <Box
        mt="s-12"
        style={
          showMore
            ? { whiteSpace: 'break-spaces', wordBreak: 'break-word' }
            : {
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '80px',
              }
        }
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body),
        }}
      />
      <TextButton onClick={() => setShowMore(!showMore)} mt="s-8">
        {showMore ? 'See less' : 'See more'}
      </TextButton>
    </Flex>
  ) : (
    <Box
      data-testid={testId}
      mt="s-12"
      style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(body),
      }}
    />
  )

  return description
}

export default useShowMoreDescription
