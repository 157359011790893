import React, { useEffect, useMemo, useState } from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { CulturalSkillInterface } from '@src/interfaces/skills'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { Statuses } from '@src/interfaces'
import {
  submitCultureSkillsCompetencyMatrix,
  useGetCultureSkillsCompetencyMatrix,
} from '@src/api/skills'
import PageLoading from '@components/PageLoading/PageLoading'
import { ActionButton, Box, Cell, Flex, HStack, Spacer } from '@revolut/ui-kit'
import { CompetencyMatrixInterface } from '@src/interfaces/roles'
import { formatNumber } from '@src/utils/format'
import isNil from 'lodash/isNil'
import NewWarningMessage, {
  NewWarningTypes,
} from '@components/NewWarningMessage/NewWarningMessage'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useGetSeniorityList } from '@src/api/seniority'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

interface Props {
  table: useTableReturnType<CulturalSkillInterface>
}

const checkIfAllValuesSet = (value: CompetencyMatrixInterface[]) => {
  return value.every(skillRow => {
    const skillDefined = !!skillRow.skill?.id
    const weightDefined = !!skillRow.weight // shouldn't be 0 as well

    const competenciesDefined =
      skillRow.competencies?.length &&
      skillRow.competencies.every(competency => {
        return !!competency.competency_level
      })
    return skillDefined && weightDefined && competenciesDefined
  })
}

const CompetencyMatrix = ({ table }: Props) => {
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.CanManageCulturalSkills)

  const { data, refetch } = useGetCultureSkillsCompetencyMatrix()
  const { data: seniorities } = useGetSeniorityList()
  const [value, setValue] = useState<CompetencyMatrixInterface[]>()
  const [error, setError] = useState<string>()
  const [hasChanged, setHasChanged] = useState<boolean>()

  const approvedSkills = useMemo(
    () =>
      table.data
        .filter(skill => skill.status.id === Statuses.approved)
        .map(skill => ({ ...skill, status: skill.status.id })),
    [table.data],
  )

  useEffect(() => {
    setValue(data?.results)
  }, [data])

  const onChange = (newValue?: CompetencyMatrixInterface[]) => {
    setValue(newValue)
    setHasChanged(true)

    const allValuesSet = checkIfAllValuesSet(newValue || [])
    const totalWeight = newValue?.reduce((acc, curr) => acc + (curr.weight || 0), 0)

    if (!allValuesSet) {
      setError('Please set expected competency level for all seniority levels')
    } else if (!isNil(totalWeight) && totalWeight !== 100) {
      setError(
        `The sum of all weights should be 100%, currently ${formatNumber(totalWeight)}%`,
      )
    } else {
      setError(undefined)
    }
  }

  const addNewRow = () => {
    onChange([
      ...(value || []),
      {
        skill: { id: null, name: null },
        // needs to be pre-filled for validation to work properly
        competencies: seniorities?.results?.map(s => ({
          competency_level: null,
          seniority_id: s.id,
          seniority_name: s.name,
          seniority_level: s.level,
        })),
      },
    ])
  }

  if (!value) {
    return <PageLoading />
  }

  return (
    <>
      <Cell>
        <Flex flexDirection="column" width="100%">
          <CompetencyMatrixTable
            competencyMatrices={[
              {
                children: value,
                sectionTitle: 'Cultural values',
                onChange,
                staticSkill: !canEdit,
                disabled: !canEdit,
                disableWeights: !canEdit,
              },
            ]}
            firstRowTitle="Values"
            approvedSkills={approvedSkills}
            withWeightColumn
          />
          {canEdit && (
            <HStack pt="s-16">
              <ActionButton useIcon="Plus" onClick={addNewRow}>
                Add skill
              </ActionButton>
              <Spacer />
              {!!error && (
                <Box width={450}>
                  <NewWarningMessage type={NewWarningTypes.error}>
                    {error}
                  </NewWarningMessage>
                </Box>
              )}
            </HStack>
          )}
        </Flex>
      </Cell>
      {canEdit && hasChanged && (
        <PageActions mt="s-16">
          <NewSaveButtonWithPopup
            onClick={() => submitCultureSkillsCompetencyMatrix(value)}
            onAfterSubmit={() => {
              setHasChanged(false)
              refetch()
            }}
            disabled={!!error}
          >
            Save changes
          </NewSaveButtonWithPopup>
        </PageActions>
      )}
    </>
  )
}

export default CompetencyMatrix
