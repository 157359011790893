import { Avatar, Banner, Token } from '@revolut/ui-kit'
import React from 'react'

export const ActivePolicyDraftWarning = () => (
  <Banner>
    <Banner.Avatar>
      <Avatar variant="brand" useIcon="Pencil" size={56} color={Token.color.orange} />
    </Banner.Avatar>
    <Banner.Content>
      <Banner.Title>You are editing an active policy</Banner.Title>
      <Banner.Description>
        Your changes will only be saved after you complete the review step.
      </Banner.Description>
    </Banner.Content>
  </Banner>
)
