import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import ResignationSubmit from './General'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'

type Props = {
  resignation?: EmployeeResignationInterface
  approval?: ResignationApprovalInterface
}
const ResignationSubmitForm = connect(({ resignation, approval }: Props) => (
  <Form>
    <ResignationSubmit resignation={resignation} approval={approval} />
  </Form>
))

export default ResignationSubmitForm
