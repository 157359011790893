import React from 'react'
import { Action, Box, Subheader } from '@revolut/ui-kit'
import { PreviewBody } from '@src/pages/Forms/InterviewScorecardTemplate/Preview/Preview'
import { ScorecardFormWrapper } from './ScorecardForm'
import { FormattedMessage } from 'react-intl'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

import { useOpenNewTab } from '@src/actions/RouterActions'

type PreviewScorecardProps = {
  id: number
  onClose: () => void
}

export const PreviewScorecard = ({ id, onClose }: PreviewScorecardProps) => {
  const navigateTo = useOpenNewTab()
  return (
    <Box mt="s-32">
      <Subheader>
        <Subheader.Title>
          <FormattedMessage
            id="onboarding.interviews.roundSidebar.scorecard.title"
            defaultMessage="Interview scorecard"
          />
        </Subheader.Title>
        <Subheader.Side>
          <Action
            onClick={() => {
              navigateTo(
                `${pathToUrl(
                  ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.SETTINGS,
                  {
                    id,
                  },
                  { ref: 'onboarding' },
                )}`,
              )
              onClose()
            }}
          >
            <FormattedMessage
              id="onboarding.interviews.roundSidebar.scorecard.edit"
              defaultMessage="Edit scorecard"
            />
          </Action>
        </Subheader.Side>
      </Subheader>
      <ScorecardFormWrapper id={id}>
        <PreviewBody id={id} hideSettingsButtons />
      </ScorecardFormWrapper>
    </Box>
  )
}
