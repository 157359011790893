import React from 'react'
import {
  ProgressWidget,
  VStack,
  Text,
  ActionButton,
  Token,
  Tag,
  Box,
} from '@revolut/ui-kit'

import {
  checkIfFirstStepsCompleted,
  getCompletedCheckpointsNum,
  getFirstStepsCheckpoints,
  getNextStepsCheckpoints,
} from '@src/pages/OnboardingChecklistV2/common/helpers'
import { useGetAllowedCheckpoints } from '../common/hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

export const ProgressBanner = () => {
  const checkpoints = useGetAllowedCheckpoints()

  const allFirstStepsCompleted = checkIfFirstStepsCompleted(checkpoints)
  const checkpointsToTrack = allFirstStepsCompleted
    ? getNextStepsCheckpoints(checkpoints)
    : getFirstStepsCheckpoints(checkpoints)
  const completedNum = getCompletedCheckpointsNum(checkpointsToTrack)

  const title = allFirstStepsCompleted
    ? 'Unlock your core features'
    : 'Setup your account'
  const subTitle = allFirstStepsCompleted
    ? 'Proceed as you prefer, you can also hide modules for now and onboard them later'
    : 'Please enter the details we need to get started'

  return (
    <ProgressWidget>
      <ProgressWidget.Title>
        <VStack>
          <Box mb="s-24">
            <Tag variant="status" useIcon="Time">
              {completedNum}/{checkpointsToTrack.length} completed
            </Tag>
          </Box>
          <Text>{title}</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {subTitle}
          </Text>
        </VStack>
      </ProgressWidget.Title>
      <ProgressWidget.Progress
        value={completedNum ? completedNum / checkpointsToTrack.length : 0.05}
      />
      <ProgressWidget.Actions>
        <ActionButton
          useIcon="Envelope"
          use={InternalLink}
          to={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.EMAILS)}
        >
          Invite admin
        </ActionButton>
      </ProgressWidget.Actions>
    </ProgressWidget>
  )
}
