import { Tag, Token } from '@revolut/ui-kit'
import React from 'react'

const PredefinedLabel = () => (
  <Tag useIcon="LockClosed" color={Token.color.greyTone50}>
    Predefined
  </Tag>
)

export default PredefinedLabel
