import React, { useState } from 'react'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import Stat from '@components/Stat/Stat'
import {
  feedbackDate,
  feedbackScore,
  feedbackStatus,
  feedbackText,
  feedbackTopic,
  feedbackUserID,
} from '@src/constants/columns/feedback'
import { FeedbackInterface } from '@src/store/feedback/types'
import { feedbackRequests } from '@src/api/feedback'
import { FeedbackTableSidebar } from '@src/pages/Admin/AdminSideBars/Feedback/FeedbackTableSidebar'
import { TableNames } from '@src/constants/table'

const RowNotifications = (
  setSelectedId: (id?: number) => void,
): RowInterface<FeedbackInterface> => ({
  hideAddButton: true,
  linkToForm: ({ id }) => setSelectedId(id),
  cells: [
    {
      ...feedbackUserID,
      width: 220,
    },
    {
      ...feedbackScore,
      width: 160,
    },
    {
      ...feedbackTopic,
      width: 120,
    },
    {
      ...feedbackStatus,
      width: 160,
    },
    {
      ...feedbackDate,
      width: 160,
    },
    {
      ...feedbackText,
      width: 1052,
    },
  ],
})

const FeedbackTable = () => {
  const [selectedId, setSelectedId] = useState<number>()
  const filterBy = [
    {
      filters: [{ name: 'completed', id: 'completed' }],
      columnName: 'status',
    },
  ]
  const sortBy = [
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const table = useTable<FeedbackInterface>(feedbackRequests, filterBy, sortBy)

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <Stat label="Total" val={table?.loading ? undefined : table?.count} mr="s-32" />
        </Table.Widget.Info>
        <Table.Widget.Table>
          <AdjustableTable<FeedbackInterface>
            name={TableNames.Feedback}
            useWindowScroll
            dataType="Feedback"
            row={RowNotifications(setSelectedId)}
            {...table}
            noDataMessage="Feedback will appear here."
            hideCount={false}
          />
        </Table.Widget.Table>
      </Table.Widget>
      <FeedbackTableSidebar
        selectedId={selectedId}
        onClose={() => setSelectedId(undefined)}
      />
    </>
  )
}

export default FeedbackTable
