import React, { useState } from 'react'
import { Button, Header, Popup, useStatusPopup } from '@revolut/ui-kit'
import { changeTeamEmployee } from '@src/api/employees'
import { selectorKeys } from '@src/constants/api'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FunctionInterface } from '@src/interfaces/functions'
import { TeamInterface } from '@src/interfaces/teams'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { OptionInterface } from '@src/interfaces/selectors'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface TransferEmployeePopupProps {
  data:
    | DepartmentInterface
    | EmployeeInterface
    | FunctionInterface
    | RoleInterface
    | SpecialisationInterface
    | TeamInterface
  employeeToAssign?: OptionInterface
  isEmployeeInTheTeam: boolean
  onAfterAssign: VoidFunction
  onClose: VoidFunction
  open: boolean
  updateEmployeeToAssign: (val?: OptionInterface) => void
}

export const TransferEmployeePopup = ({
  data,
  employeeToAssign,
  isEmployeeInTheTeam,
  onAfterAssign,
  onClose,
  open,
  updateEmployeeToAssign,
}: TransferEmployeePopupProps) => {
  const statusPopup = useStatusPopup()
  const showStatusPopup = useShowStatusPopup()

  const [loadingAssignEmployee, updateLoadingAssignEmployee] = useState(false)

  const clearEmployeeAssignState = () => {
    updateEmployeeToAssign(undefined)
    onClose()
  }

  const BackActionButton = (
    <Button onClick={statusPopup.hide} variant="secondary">
      Back
    </Button>
  )

  const handleAssignEmployee = async () => {
    if (employeeToAssign && 'name' in data) {
      if (!isEmployeeInTheTeam) {
        updateLoadingAssignEmployee(true)
        try {
          const result = await changeTeamEmployee(employeeToAssign.id, data.id)
          updateLoadingAssignEmployee(false)

          if (result) {
            onAfterAssign()
            clearEmployeeAssignState()
            showStatusPopup({
              title: `${employeeToAssign.name} is assigned to ${data.name}.`,
              status: 'success',
            })
          }
        } catch (err) {
          showStatusPopup({
            status: 'error',
            title: 'Failed to transfer employee',
            description: getStringMessageFromError(err),
            actions: BackActionButton,
          })
          updateLoadingAssignEmployee(false)
        }
      } else {
        showStatusPopup({
          status: 'warning',
          title: `${employeeToAssign.name} is already in ${data.name}.`,
          actions: BackActionButton,
        })
      }
    }
  }

  const handleEmployeeChange = (option: OptionInterface) => {
    updateEmployeeToAssign(option)
  }

  return (
    <Popup onClose={clearEmployeeAssignState} open={open} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Assign employee</Header.Title>
      </Header>
      <RadioSelectInput
        label="Name"
        onChange={option => {
          if (option) {
            handleEmployeeChange(option)
          }
        }}
        selector={selectorKeys.employees_for_teams}
        value={employeeToAssign}
      />
      <Popup.Actions horizontal>
        <Button onClick={clearEmployeeAssignState} variant="secondary">
          Cancel
        </Button>
        <Button
          disabled={!employeeToAssign}
          onClick={handleAssignEmployee}
          pending={loadingAssignEmployee}
          elevated
        >
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
