import React from 'react'
import { Box, ErrorWidget, HStack, Text, Token } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { selectAuth, selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const ClosingSubscriptionPageWall: React.FC = ({ children }) => {
  const { subscriptionState } = useSelector(selectAuth)
  const permissions = useSelector(selectPermissions)

  const canManageSubscriptions = permissions.includes(
    PermissionTypes.ManageSubscriptionPlans,
  )

  if (subscriptionState === 'closing') {
    return (
      <Box maxWidth={Token.breakpoint.md}>
        <ErrorWidget>
          <ErrorWidget.Image
            src="https://assets.revolut.com/assets/3d-images-v2/3D020.png"
            srcSet="https://assets.revolut.com/assets/3d-images-v2/3D020@2x.png 2x, https://assets.revolut.com/assets/3d-images-v2/3D020@3x.png 3x"
          />
          <ErrorWidget.Title>You do not have access to this page</ErrorWidget.Title>
          <ErrorWidget.Description>
            This page is restricted as your account is scheduled for closing.
          </ErrorWidget.Description>
          {canManageSubscriptions ? (
            <ErrorWidget.Action
              use={InternalLink}
              to={ROUTES.SETTINGS.CLOSE_ACCOUNT.SCHEDULED_FOR_CLOSURE}
            >
              <HStack align="center" space="s-4">
                <InfoOutline size={16} />
                <Text>Learn more</Text>
              </HStack>
            </ErrorWidget.Action>
          ) : null}
        </ErrorWidget>
      </Box>
    )
  }

  return <>{children}</>
}
