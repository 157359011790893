import React, { useState } from 'react'
import { ApproveButton, RejectButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { ApprovalFlowResponse, ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { EntityPermissions } from '@src/store/auth/types'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { UseQueryResult } from 'react-query'
import { FieldOptions } from '@src/interfaces'

interface UseApprovalReturnType {
  approvalButtons: React.ReactNode | null
  approvalFlow: React.ReactNode | null
  refetchApproval: () => Promise<any>
}

export interface UseApprovalOptions {
  useGetApprovals: (id?: number) => UseQueryResult<ApprovalFlowResponse>
  isPendingApproval?: boolean
  statusFieldName?: string
  data?: { id: number; field_options: FieldOptions }
  refetch?: () => void
  noReject?: boolean
  approveButtonActionText?: string
}

/** @deprecated use useApprovalFlow */
const useApproval = ({
  data,
  refetch,
  useGetApprovals,
  statusFieldName = 'status',
  noReject,
  approveButtonActionText,
  ...props
}: UseApprovalOptions): UseApprovalReturnType => {
  const [isApproving, setIsApproving] = useState<boolean>(false)
  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useGetApprovals(data?.id)

  if (!data?.id) {
    return { approvalButtons: null, approvalFlow: null, refetchApproval }
  }

  const isPendingApproval =
    props.isPendingApproval !== undefined
      ? props.isPendingApproval
      : /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        data?.[statusFieldName] === ApprovalStatuses.Pending

  const canApprove =
    isPendingApproval && data?.field_options?.actions?.includes(EntityPermissions.Approve)

  const approvalFlow = (
    <ApprovalFlow
      isLoading={isApprovalLoading || isApproving}
      steps={approvalSteps || null}
    />
  )

  const approvalButtons = (
    <>
      <ApproveButton
        onBeforeSubmit={() => setIsApproving(true)}
        onAfterSubmit={() => {
          setIsApproving(false)
          refetch?.()
          refetchApproval()
        }}
        onSubmitFailed={handleError}
        statusFieldName={statusFieldName}
        isVisible={canApprove}
        buttonActionText={approveButtonActionText}
      />
      <RejectButton
        onBeforeSubmit={() => setIsApproving(true)}
        onAfterSubmit={() => {
          setIsApproving(false)
          refetch?.()
          refetchApproval()
        }}
        onSubmitFailed={handleError}
        statusFieldName={statusFieldName}
        isVisible={canApprove && !noReject}
        dialog={{
          title: 'Reason for rejection',
          placeholder: 'a couple of words',
          fieldName: 'rejection_comment',
        }}
      />
    </>
  )

  return { approvalButtons, approvalFlow, refetchApproval }
}

export default useApproval
