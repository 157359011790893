import React, { useState } from 'react'
import { Group, MoreBar, Box, Item, Avatar, Flex } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import { ProcessStages, ProcessStageSteps } from '@src/interfaces/onboarding'
import {
  CompleteStageButton,
  findProcessStage,
  ProcessStageStepItem,
  SLAProgress,
  TabProps,
} from './common'
import SideBar from '@src/components/SideBar/SideBar'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import {
  getDocumentLink,
  useSectionHeader,
} from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import {
  confirmOnboardingStep,
  completeStage,
  completeOnboardingStepAction,
} from '@src/api/onboardingEmployees'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectUser } from '@src/store/auth/selectors'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { getEmployeeDocuments } from '@src/api/accessRequests'
import { PageActions } from '@src/components/Page/PageActions'
import { updateEmployeeStatus } from '@src/api/employees'
import {
  IdStatuses,
  InternalOrExternalEmployee,
  EmployeeStatusTransitionsInterface,
} from '@src/interfaces/employees'
import { EmployeeDocumentListItemInterface } from '@src/interfaces/documents'
import { useGetEmployeeSettings } from '@src/api/settings'
import { ScreeningCheck } from '../../EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'

export const StartStage = (props: TabProps) => {
  const { stage, data, updateStep, updateStage, openedSidebar, setOpenedSidebar } = props

  const screeningStageExists = !!findProcessStage(data, ProcessStages.Screening)

  const [confirmDetailsPending, setConfirmDetailsPending] = useState(false)
  const [documentsPending, setDocumentsPending] = useState(false)
  const [onboardingPending, setOnboardingPending] = useState(false)

  const user = useSelector(selectUser)

  const { data: settings } = useGetEmployeeSettings()

  const { process_stage_steps } = stage

  const reviewDetailsStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ReviewDetails,
  )!
  const reviewRequestedDocumentsStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ReviewDocuments,
  )!

  const disableStartOnboarding =
    reviewDetailsStep.status.id !== 'completed' ||
    reviewRequestedDocumentsStep.status.id !== 'completed'

  const onStartOnboarding = async () => {
    setOnboardingPending(true)

    try {
      if (
        data.employee.status.id !== IdStatuses.onboarding &&
        data.employee.status.id !== IdStatuses.hired &&
        data.employee.status.id !== IdStatuses.active
      ) {
        const statusResponse = await updateEmployeeStatus(data.employee.id, {
          status: {
            id: IdStatuses.onboarding,
          },
        } as EmployeeStatusTransitionsInterface)
        props.updateEmployeeStatus(statusResponse.data.status)
      }
      if (stage.status.id !== 'completed') {
        const stageResponse = await completeStage(data.employee.id, stage.id)
        updateStage(stageResponse.data)
      }
      navigateReplace(
        pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.WORK, {
          employeeId: data.employee.id,
          id: data.id,
        }),
      )
    } catch {
      setOnboardingPending(false)
    }
  }

  return (
    <>
      <PageBody mt="s-32">
        <SLAProgress
          elapsedSla={stage.sla}
          sla={stage.stage.sla_in_hours}
          caption="Complete basic steps to start onboarding"
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        <Group>
          <ProcessStageStepItem
            step={reviewDetailsStep}
            onClick={() => setOpenedSidebar('onboarding-details')}
          />
          <ProcessStageStepItem
            step={reviewRequestedDocumentsStep}
            onClick={() => setOpenedSidebar('requested-documents')}
          />
        </Group>

        {screeningStageExists ? (
          <Box mt="s-24">
            <ScreeningCheck employeeId={data.employee.id} hideTableRoute />
          </Box>
        ) : null}
      </PageBody>

      <PageActions>
        <CompleteStageButton
          onClick={onStartOnboarding}
          footnote={`Once that's done, candidate's onboarding will start`}
          disabled={disableStartOnboarding}
          pending={onboardingPending}
          {...props}
        />
      </PageActions>

      <SideBar
        title="Candidate's onboarding details"
        isOpen={openedSidebar === 'onboarding-details'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon="Check"
            onClick={() => {
              setConfirmDetailsPending(true)
              completeOnboardingStepAction(data.employee.id, reviewDetailsStep.id)
                .then(response => {
                  updateStep(response.data.process)
                  setOpenedSidebar(null)
                })
                .finally(() => setConfirmDetailsPending(false))
            }}
            variant="accent"
            disabled={reviewDetailsStep.status.id === 'completed'}
            pending={confirmDetailsPending}
          >
            Confirm
          </MoreBar.Action>
        </MoreBar>

        <Flex flexDirection="column" gap="s-16" mt="s-16">
          <FormPreview<InternalOrExternalEmployee>
            data={data?.employee}
            title="Name"
            {...useSectionHeader({ employeeId: `${data.employee.id}`, type: 'name' })}
          >
            <Group>
              <FormPreview.Item title="Legal first name" field="first_name" />
              <FormPreview.Item title="Legal middle name" field="middle_name" />
              <FormPreview.Item title="Legal last name" field="last_name" />
              {!!settings?.enable_show_employee_id && (
                <FormPreview.Item title="Employee ID" insert={() => data.employee.id} />
              )}
            </Group>
          </FormPreview>
          <FormPreview<InternalOrExternalEmployee>
            data={data?.employee}
            title="Position"
            {...useSectionHeader({ employeeId: `${data.employee.id}`, type: 'position' })}
          >
            <Group>
              <FormPreview.Item<InternalOrExternalEmployee>
                title="Contract type"
                field="contract_type"
                insert={position => position.contract_type.name}
              />
              <FormPreview.Item title="Location" field="location.name" />
              <FormPreview.Item title="Entity" field="entity.name" />
            </Group>
          </FormPreview>
          <FormPreview
            data={data?.employee}
            title="Contact info"
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO, {
                  id: data?.employee.id,
                }),
              )
            }
          >
            <Group>
              <FormPreview.Item title="Personal email" field="personal_email" />
              <FormPreview.Item title="Mobile number" field="phone_number" />
            </Group>
          </FormPreview>
          {!!settings?.enable_notes && (
            <FormPreview
              data={data?.employee}
              title="Notes"
              onEdit={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES, {
                    id: data?.employee.id,
                  }),
                )
              }
            >
              <Group>
                <FormPreview.Item
                  title="Work authorisation status"
                  field="work_authorisation_status.name"
                />
                <FormPreview.Item title="Relocation type" field="relocation_type.name" />
                <FormPreview.Item
                  type="boolean"
                  title="Regulated role"
                  field="is_regulated"
                />
              </Group>
            </FormPreview>
          )}
        </Flex>
      </SideBar>

      <SideBar
        title="Requested documents"
        isOpen={openedSidebar === 'requested-documents'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon="Check"
            onClick={() => {
              setDocumentsPending(true)
              confirmOnboardingStep(data.employee.id, reviewRequestedDocumentsStep.id)
                .then(response => {
                  updateStep(response.data.process)
                  setOpenedSidebar(null)
                })
                .finally(() => setDocumentsPending(false))
            }}
            variant="accent"
            disabled={reviewRequestedDocumentsStep.status.id === 'completed'}
            pending={documentsPending}
          >
            Confirm
          </MoreBar.Action>
          <MoreBar.Action
            useIcon="DocumentsPair"
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, {
                  employeeId: data.employee.id,
                }),
                {
                  initialValues: {
                    employee: { id: data.employee.id, name: data.employee.full_name },
                    issuer: { id: user.id, name: user.full_name },
                  },
                },
              )
            }}
          >
            Request more documents
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <Group>
            <TablePreview<EmployeeDocumentListItemInterface>
              api={{
                getItems: requestData =>
                  getEmployeeDocuments(requestData, data.employee.id),
              }}
              emptyState={{
                title: 'No documents added',
                icon: 'Document',
              }}
              row={document => {
                return (
                  <Item
                    use="button"
                    onClick={() => getDocumentLink(document)}
                    key={document.id}
                  >
                    <Item.Avatar>
                      <Avatar useIcon="Document" />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{document.document_name}</Item.Title>
                    </Item.Content>
                  </Item>
                )
              }}
            />
          </Group>
        </Box>
      </SideBar>
    </>
  )
}
