import React, { useMemo } from 'react'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import {
  InterviewScorecardType,
  InterviewScorecardTemplateInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import { NewMultiSelectGroupInterface } from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { useGetSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'

type Props = {
  loading?: boolean
}

const SkillSelector = ({ loading }: Props) => {
  const { values } = useLapeContext<InterviewScorecardTemplateInterface>()
  const { data: skillsOptions } = useGetSelectors(selectorKeys.functional_skills)
  const { data: valuesOptions } = useGetSelectors(selectorKeys.company_values)

  const scorecardType = values.scorecard_type?.id

  const groups: NewMultiSelectGroupInterface<OptionInterface>[] = useMemo(
    () => [
      {
        title: 'Skills',
        options:
          skillsOptions?.map(value => ({
            label: value.name,
            value,
          })) || [],
      },
      {
        title: 'Values',
        options:
          valuesOptions?.map(value => ({
            label: value.name,
            value,
          })) || [],
      },
    ],
    [skillsOptions, valuesOptions],
  )

  return (
    <LapeNewMultiSelect
      name="skills"
      placeholder="Skills"
      variant="grey"
      disabled={scorecardType === InterviewScorecardType.Delivery || loading}
      groups={groups}
      required
    />
  )
}

export default SkillSelector
