import React from 'react'
import { Box, Button, Popup, Link as UIKitLink } from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'

import { onCopyLinkToClipboard } from '../../common'

type Props = {
  link: string | undefined
  open: boolean
  onClose: () => void
}
export const GetMagicLinkPopup = ({ link, open, onClose }: Props) => {
  return (
    <Popup open={open} variant="bottom-sheet" onClose={onClose}>
      <Box style={{ overflowWrap: 'break-word' }}>
        <UIKitLink href={link} target="_blank">
          {link}
        </UIKitLink>
      </Box>
      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        <Button onClick={() => onCopyLinkToClipboard(link)} useIcon={Copy}>
          Copy link
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
