import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { DownloadReportsTable } from './DownloadReportsTable'

export const AdpDownloadReportsTable = () => {
  const params = useParams<{ action: string; id: string }>()
  const backUrl = pathToUrl(ROUTES.PEOPLE.PAYROLL, params)

  const tabs = [
    {
      title: 'Download',
      path: ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.DOWNLOAD,
      to: pathToUrl(
        ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.DOWNLOAD,
        params,
      ),
      component: DownloadReportsTable,
    },
    {
      title: 'Send',
      path: ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.UPLOAD,
      to: pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.UPLOAD, params),
      component: DownloadReportsTable,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader backUrl={backUrl} title="Download Reports" />
      <Box mb="s-8">
        <TabBarNavigation tabs={tabs} />
      </Box>
      <PageBody maxWidth="100%">
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component action={params.action} id={params.id} />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
}
