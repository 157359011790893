import React, { useEffect, useRef } from 'react'
import {
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Avatar, Box, Flex, MoreBar, Text, VStack, Widget } from '@revolut/ui-kit'
import { AdditionalScorecards } from '@src/pages/Forms/EmployeePerformance/AdditionalScorecards'
import BottomText from '@components/Inputs/partials/BottomText'
import isString from 'lodash/isString'

interface Props {
  data: ReviewDataInterface | ReviewSummaryDataInterface | null
  isViewMode: boolean
  renderTitleButton?: (id: number) => React.ReactNode
  actions?: React.ReactNode
}

export const AdditionalQuestions = connect(
  ({ data, isViewMode, renderTitleButton, actions }: Props) => {
    const { errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const headerRef = useRef<HTMLDivElement>(null)

    const additionalScorecards = data?.cycle_sections
    const scorecardAnswers = additionalScorecards?.[0]?.questions[0]?.answers
    const scorecardsRelations = scorecardAnswers
      ? [...(Object.keys(scorecardAnswers) as ReviewerRelation[])]
      : []

    useEffect(() => {
      if (errors.review_data?.cycle_sections) {
        headerRef?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data])

    const renderError = () => {
      if (!submitFailed || !errors.review_data?.cycle_sections) {
        return null
      }

      return isString(errors.review_data.cycle_sections) ? (
        <BottomText error={errors.review_data.cycle_sections} />
      ) : (
        <BottomText error="Additional questions are obligatory. Please fill in the given questions." />
      )
    }

    if (!additionalScorecards?.length) {
      return null
    }

    return (
      <>
        <Widget p="s-16">
          <VStack space="s-16" ref={headerRef}>
            <Flex alignItems="center" justifyContent="space-between">
              <Flex alignItems="center" gap="s-16" width="30%">
                <Avatar useIcon="Question" />
                <Text variant="primary">Additional questions</Text>
              </Flex>
            </Flex>
            {renderError()}
            {actions && <MoreBar>{actions}</MoreBar>}
            {isViewMode ? (
              <VStack space="s-16">
                {scorecardsRelations.map((relation, index) => (
                  <VStack space="s-16" key={index}>
                    {additionalScorecards.map((scorecard, ind) => (
                      <Box key={scorecard.id}>
                        <AdditionalScorecards
                          data={scorecard}
                          scorecardIdx={ind}
                          relation={relation}
                          viewMode
                          noWidget
                        />
                      </Box>
                    ))}
                  </VStack>
                ))}
              </VStack>
            ) : (
              <VStack space="s-16">
                {additionalScorecards.map((scorecard, ind) => (
                  <Box key={scorecard.id}>
                    <AdditionalScorecards
                      data={scorecard}
                      scorecardIdx={ind}
                      viewMode={isViewMode}
                      noWidget
                      titleButton={
                        scorecard.id ? renderTitleButton?.(scorecard.id) : undefined
                      }
                    />
                  </Box>
                ))}
              </VStack>
            )}
          </VStack>
        </Widget>
      </>
    )
  },
)
