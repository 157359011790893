import React from 'react'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { ReviewedEmployeeInterface } from '@src/interfaces/performance'
import { Grid } from '@components/CommonSC/Grid'
import HistoryTooltip, {
  HistoryTooltipData,
  HistoryTypes,
} from '@components/Stepper/HistoryTooltip'
import { getEmployeeFieldChangelog } from '@src/api/employees'
import { Statuses } from '@src/interfaces'
import { differenceInDays, startOfToday } from 'date-fns'
import { formatDate } from '@src/utils/format'
import { useGetOrganisationSettings } from '@src/api/settings'

const getFieldsForChangelog = async (
  id: number,
  field: string,
): Promise<HistoryTooltipData[]> => {
  const result = await getEmployeeFieldChangelog(id, field)

  let currentFlag = false

  if (result.data?.results) {
    return (
      result.data?.results.reverse().map(c => {
        if (c.status?.id === Statuses.pending) {
          if (c.effective_date_time) {
            const isChangeTimePassed =
              differenceInDays(new Date(c.effective_date_time), startOfToday()) <= 0

            return {
              type: HistoryTypes.Future,
              name: `${c.field_display_value} ${
                isChangeTimePassed ? 'on' : 'scheduled for'
              } ${formatDate(new Date(c.effective_date_time))} by ${
                c.created_by.display_name
              }`,
            }
          }
          return {
            type: HistoryTypes.Future,
            name: `${c.field_display_value} on ${formatDate(
              new Date(c.updated_date_time),
            )} (pending)`,
          }
        }

        if (!currentFlag && c.status?.id === Statuses.completed) {
          currentFlag = true
          return {
            type: HistoryTypes.Current,
            name: `${c.field_display_value} on ${formatDate(
              new Date(c.effective_date_time),
            )} by ${c.created_by.display_name} (current)`,
          }
        }

        return {
          type: HistoryTypes.Past,
          name: `${c.field_display_value} on ${formatDate(
            new Date(c.effective_date_time),
          )} by ${c.created_by.display_name}`,
        }
      }) || []
    )
  }
  return []
}

type Props = {
  employee: ReviewedEmployeeInterface
}

const TextItem: React.FC = ({ children }) => {
  return (
    <Text variant="caption" color={Token.color.greyTone50} fontWeight={500}>
      {children}
    </Text>
  )
}

export const SummarySidebarRoleSeniority = ({ employee }: Props) => {
  const { seniority, specialisation_seniority_sublevel } = employee
  const { data: settings } = useGetOrganisationSettings()

  return (
    <VStack space="s-16">
      <TextItem>Current position details</TextItem>
      <Box
        border={`1px solid ${Token.color.greyTone10}`}
        borderRadius={Token.radius.r12}
        p="s-16"
      >
        <VStack space="s-16">
          <Flex justifyContent="space-between">
            <TextItem>Role (Specialisation)</TextItem>
            <Grid flow="column" gap={4} alignItems="center">
              {employee.specialisation?.name}
              <HistoryTooltip
                color={Token.color.greyTone50}
                request={() => getFieldsForChangelog(employee.id, 'position')}
                placement="left"
              />
            </Grid>
          </Flex>
          <Flex justifyContent="space-between">
            <TextItem>Seniority</TextItem>
            <Grid flow="column" gap={4} alignItems="center">
              {`${seniority?.name || '-'}${
                !!settings?.enable_multiple_levels_per_seniority &&
                specialisation_seniority_sublevel
                  ? ` (${specialisation_seniority_sublevel.name})`
                  : ''
              }`}
              <HistoryTooltip
                color={Token.color.greyTone50}
                request={() => getFieldsForChangelog(employee.id, 'seniority')}
                placement="left"
              />
            </Grid>
          </Flex>
        </VStack>
      </Box>
    </VStack>
  )
}
