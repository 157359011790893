import { AxiosError } from 'axios'
import { Token } from '@revolut/ui-kit'
import {
  ParameterDefaultValue,
  QueryInterface,
  QueryRunStatuses,
  QueryStatuses,
} from '@src/interfaces/dataAnalytics'
import { MIN_EDITOR_HEIGHT, MIN_EDITOR_HEIGHT_WITH_PARAMS } from './constants'

export const isEditorMinHeight = (height: number) =>
  height === MIN_EDITOR_HEIGHT || height === MIN_EDITOR_HEIGHT_WITH_PARAMS

export const isQueryRunning = (status?: QueryRunStatuses) =>
  status === QueryRunStatuses.Running || status === QueryRunStatuses.Queued

export const getQueryRunData = (
  values: QueryInterface,
  runParameters: { [key: string]: ParameterDefaultValue },
) => {
  return {
    query: {
      connection: {
        id: values.connection?.id,
      },
      owner: values.owner?.id
        ? {
            id: values.owner.id,
          }
        : null,
      sql: values.sql,
    },
    parameters: runParameters,
  }
}

export const getErrorDescription = (error?: AxiosError) => {
  return JSON.stringify(error?.response?.data, undefined, 2).replace(
    /\[|\]|"|{|}|_|:/g,
    ' ',
  )
}

const isUsingWindows = () => navigator.userAgent.search('Windows') >= 0

export const isRunQueryKeysUsed = (e: KeyboardEvent) => {
  return e.code === 'Enter' && (isUsingWindows() ? e.ctrlKey : e.metaKey)
}

export const queryStatusToColor = (status: QueryStatuses) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}
