import React, { useEffect, useMemo } from 'react'
import isNumber from 'lodash/isNumber'
import { HireInterface } from '@src/interfaces/requisitions'
import SideBar from '@components/SideBar/SideBar'
import { Box, Button, Caption, InputGroup, Side, TextArea } from '@revolut/ui-kit'
import { moveRequisitionHire } from '@src/api/requisitions'
import { OptionInterface } from '@src/interfaces/selectors'
import { connect, useLape } from 'lape'
import { successNotification } from '@src/store/notifications/actions'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  initialHireIndex: number | null
  allHires: HireInterface[]
  requisitionId: number
  onClose: () => void
  isOpen: boolean
  onAfterSubmit?: () => void
}

interface State {
  hire?: HireInterface
  newRequisition?: OptionInterface
  notes?: string
  isSubmitting?: boolean
}

const MoveHireSideBar = ({
  initialHireIndex,
  allHires,
  requisitionId,
  isOpen,
  onClose,
  onAfterSubmit,
}: Props) => {
  const state = useLape<State>({})

  useEffect(() => {
    if (isNumber(initialHireIndex)) {
      state.hire = allHires[initialHireIndex]
    }
  }, [initialHireIndex])

  const hireOptions: RadioSelectOption<HireInterface>[] = useMemo(() => {
    return allHires.map(hire => ({
      label: hire.full_name,
      value: hire,
    }))
  }, [allHires, allHires.length])

  const submit = async () => {
    if (state.hire && state.newRequisition) {
      try {
        state.isSubmitting = true
        await moveRequisitionHire(state.hire.id, requisitionId, state.newRequisition.id)
        successNotification('Hired candidate successfully moved')

        onAfterSubmit?.()
      } finally {
        state.isSubmitting = false
      }
    }
  }

  return (
    <SideBar title="Move hire" isOpen={isOpen} onClose={onClose}>
      <Box>
        <Caption color="grey-tone-50" mb="s-24">
          Candidates can only be moved within 30 days. Post that, kindly raise a HR
          service desk ticket
        </Caption>
        <InputGroup>
          <RadioSelectInput<HireInterface>
            value={state.hire}
            options={hireOptions}
            label="Hired candidate"
            onChange={option => {
              if (option) {
                state.hire = option
              }
            }}
          />
          <RadioSelectInput<OptionInterface>
            selector={selectorKeys.requisitions}
            label="Move to requisition"
            onChange={option => {
              if (option) {
                state.newRequisition = option
              }
            }}
            value={state.newRequisition}
          />
          <TextArea label="Notes (optional)" rows={2} />
        </InputGroup>
        <Side.Actions>
          <Button
            pending={state.isSubmitting}
            disabled={!state.hire || !state.newRequisition}
            onClick={submit}
          >
            Submit
          </Button>
        </Side.Actions>
      </Box>
    </SideBar>
  )
}

export default connect(MoveHireSideBar)
