import React from 'react'
import { chain, Item, ProgressStepState, TableWidget, VStack } from '@revolut/ui-kit'
import Message from '@src/pages/OnboardingChecklist/components/Message'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTemplateTimelineType,
} from '@src/interfaces/probationTemplate'
import { RowInterface } from '@src/interfaces/data'
import {
  probationManagersRulesColumn,
  probationTemplateCheckpointsColumn,
  probationTemplateDurationColumn,
  probationTemplateNameColumn,
} from '@src/constants/columns/probation'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  TimelinePreviewSectionInterface,
  WithTimelinePreview,
} from '@src/pages/OnboardingChecklist/Performance/common'
import { formatToOrdinal } from '@src/utils/format'

const Row: RowInterface<ProbationTemplateInterface> = {
  cells: [
    {
      ...probationTemplateNameColumn,
      width: 100,
    },
    {
      ...probationTemplateDurationColumn,
      width: 80,
    },
    {
      ...probationTemplateCheckpointsColumn,
      width: 80,
    },
    {
      ...probationManagersRulesColumn,
      width: 100,
    },
  ],
}

const generateTimeline = (
  template: ProbationTemplateInterface,
): TimelinePreviewSectionInterface[] => {
  let checkpointsCount = 0
  return template.timelines.map(step => {
    switch (step.timeline_type) {
      case ProbationTemplateTimelineType.goals:
        return {
          label: 'Goal setting',
          description: `${step.weeks_due_date} weeks after joining`,
          state: ProgressStepState.PENDING,
        }
      case ProbationTemplateTimelineType.decision:
        return {
          label: 'Final decision deadline',
          description: `${formatToOrdinal(
            template.total_weeks - step.weeks_due_date,
          )} week`,
          state: ProgressStepState.PENDING,
        }
      default:
        checkpointsCount += 1
        return {
          label: `Checkpoint ${checkpointsCount}`,
          description: `${formatToOrdinal(step.weeks_due_date)} week`,
          state: ProgressStepState.PENDING,
        }
    }
  })
}

export const Preview = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  if (!values.id) {
    return (
      <Item>
        <Item.Content>
          <Item.Title pb="s-8">Please define probation template</Item.Title>
        </Item.Content>
      </Item>
    )
  }

  return (
    <WithTimelinePreview
      title={chain(values.name, `Timeline preview (total ${values.total_weeks} weeks)`)}
      sections={generateTimeline(values)}
    >
      <VStack flex={3} space="s-16">
        <Message
          storageKey="onboarding_probation_preview_message"
          title="We have created the first probation cycle template for you!"
          description="Feel free to take a look and make any adjustments to suit your preferences"
        />

        <TableWidget>
          <TableWidget.Table>
            <AdjustableTable<ProbationTemplateInterface>
              name={TableNames.ProbationTemplates}
              useWindowScroll
              row={Row}
              data={[values]}
              count={1}
              hideCountAndButtonSection
              noDataMessage="Probation templates will appear here."
            />
          </TableWidget.Table>
        </TableWidget>
      </VStack>
    </WithTimelinePreview>
  )
}
