import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'

export const BenefitsSettings = () => {
  useSetDocumentTitle('Settings', 'Benefits')

  return (
    <PageWrapper>
      <PageHeader
        title="Benefits settings"
        subtitle="Custom fields"
        backUrl={ROUTES.SETTINGS.ALL}
      />
    </PageWrapper>
  )
}
