import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { HStack, Tag, Text, TextSkeleton, Token } from '@revolut/ui-kit'

import { useGetPayCycle } from '@src/api/payrollV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import Loader from '@src/components/CommonSC/Loader'
import { formatDate } from '@src/utils/format'
import { PayCycleInterface } from '@src/interfaces/payrollV2'

import PageLoading from '@src/components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import type { CycleOption } from './common'
import { PAYCYCLE_SELECTED_QUERY_KEY, PayrollCycleSelector } from './common/CycleSelector'
import { useGetPayCyclesOptions } from './common/CycleSelector/hooks'
import { PayCycleTabChanges } from './TabChanges'
import { PayCycleTabComments } from './TabComments'
import { PayCycleTabOverview } from './TabOverview'

type RouteParams = { id: string }

const getTabs = (params: RouteParams, query: Record<string, string>) => [
  {
    key: 'overview',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW,
    to: {
      pathname: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.OVERVIEW, params),
      search: new URLSearchParams(query).toString(),
    },
    title: 'Overview',
    component: PayCycleTabOverview,
  },
  {
    key: 'changes',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.CHANGES,
    to: {
      pathname: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.CHANGES, params),
      search: new URLSearchParams(query).toString(),
    },
    title: 'Changes',
    component: PayCycleTabChanges,
  },
  {
    key: 'comments',
    path: ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.COMMENTS,
    to: {
      pathname: pathToUrl(ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.COMMENTS, params),
      search: new URLSearchParams(query).toString(),
    },
    title: 'Comments',
    component: PayCycleTabComments,
  },
]

type PayCycleTagsProps = {
  initCycle: PayCycleInterface | undefined
  isInitCycleLoading: boolean
  selectedCycle: CycleOption | undefined
}
const PayCycleTags = ({
  initCycle,
  isInitCycleLoading,
  selectedCycle,
}: PayCycleTagsProps) => {
  const cycle = selectedCycle || initCycle

  if (isInitCycleLoading) {
    return <TextSkeleton height="s-16" width={100} />
  }
  const cutOffRange = cycle?.previous_pay_cycle
    ? `Cut off: ${formatDate(cycle.previous_pay_cycle.cut_off_date, undefined, {
        hideSameYear: true,
      })} - ${formatDate(cycle.cut_off_date)}`
    : 'Cut off range not found'
  return (
    <HStack mt="s-6" space="s-8">
      {initCycle?.pay_group && (
        <Tag variant="status" useIcon="Bank">
          {initCycle.pay_group.payroll_provider}
        </Tag>
      )}
      <Tag variant="status" useIcon="CalendarWeek">
        {cutOffRange}
      </Tag>
    </HStack>
  )
}

export const PayCycle = () => {
  const params = useParams<RouteParams>()
  const { query } = useQuery()
  const tabs = getTabs(params, query)

  const [selectedCycle, setSelectedCycle] = useState<CycleOption>()

  const { data: initPayCycleData, isLoading: isInitPayCycleDataLoading } = useGetPayCycle(
    params.id,
  )
  const groupId = initPayCycleData?.pay_group.id
  const { options: cyclesOptions, isLoading: isCyclesOptionsDataLoading } =
    useGetPayCyclesOptions(groupId ? String(groupId) : undefined)
  const isCycleSelectorLoading = isInitPayCycleDataLoading || isCyclesOptionsDataLoading

  const groupName = initPayCycleData?.pay_group.name
  const title = isInitPayCycleDataLoading ? (
    <TextSkeleton height="s-32" width={360} />
  ) : (
    <HStack space="s-24">
      <Text>{groupName}</Text>
      <PayrollCycleSelector
        value={selectedCycle}
        onChange={setSelectedCycle}
        options={cyclesOptions}
        isLoading={isCycleSelectorLoading}
      />
    </HStack>
  )

  useEffect(() => {
    if (selectedCycle) {
      return
    }
    const selectedId = query[PAYCYCLE_SELECTED_QUERY_KEY]
    const currentCycle = cyclesOptions.find(cycle =>
      selectedId ? cycle.id === Number(selectedId) : cycle.status?.id === 'open',
    )
    if (currentCycle) {
      setSelectedCycle(currentCycle)
    }
  }, [selectedCycle, cyclesOptions])

  if (!initPayCycleData) {
    return <Loader />
  }
  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.ANY}>
        <PageWrapper>
          <PageHeader
            title={
              <PageHeader.Title
                title={title}
                labels={
                  <PayCycleTags
                    initCycle={initPayCycleData}
                    isInitCycleLoading={isInitPayCycleDataLoading}
                    selectedCycle={selectedCycle}
                  />
                }
                useIcon="Coins"
              />
            }
            backUrl={ROUTES.PEOPLE.PAYROLL}
          />
          <PageBody maxWidthMd={Token.breakpoint.xxl}>
            <TabBarNavigation tabs={tabs} mb="s-16" />
            {initPayCycleData ? (
              <Switch>
                {tabs.map(tab => (
                  <Route exact key={tab.key} path={tab.path}>
                    <tab.component
                      data={initPayCycleData}
                      selectedCycle={selectedCycle}
                      setSelectedCycle={setSelectedCycle}
                      cycleSelectorOptions={cyclesOptions}
                      isCycleSelectorLoading={isCycleSelectorLoading}
                    />
                  </Route>
                ))}
              </Switch>
            ) : (
              <PageLoading />
            )}
          </PageBody>
        </PageWrapper>
      </Route>
    </Switch>
  )
}
