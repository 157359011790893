import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { PermissionInterface } from '@src/interfaces/accessManagement'
import { selectorKeys } from '@src/constants/api'

export const permissionNameColumn: ColumnInterface<PermissionInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.permissions,
  title: 'Name',
}

export const permissionCodeNameColumn: ColumnInterface<PermissionInterface> = {
  type: CellTypes.insert,
  idPoint: 'codename',
  dataPoint: 'codename',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Code name',
  insert: ({ data }) => data.codename,
}

export const permissionUsersColumn: ColumnInterface<PermissionInterface> = {
  type: CellTypes.text,
  idPoint: 'users_count',
  dataPoint: 'users_count',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Users',
}

export const permissionApplicationColumn: ColumnInterface<PermissionInterface> = {
  type: CellTypes.text,
  idPoint: 'content_type.app_label',
  dataPoint: 'content_type.app_label',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Application',
}
