import React from 'react'
import styled from 'styled-components'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { Token } from '@revolut/ui-kit'
import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'

const LockTooltip = styled(Tooltip)`
  justify-content: flex-start;
  width: 20px;
`

const GradeLockIcon = () => {
  return (
    <LockTooltip
      placement="top"
      text="You can't see your own grades before the publishing date"
    >
      <Colored color={Token.color.greyTone20}>
        <Icon type="Lock" size="small" />
      </Colored>
    </LockTooltip>
  )
}

export default GradeLockIcon
