import React from 'react'
import {
  InterviewOptionInterface,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { useLapeContext } from '@src/features/Form/LapeForm'
import get from 'lodash/get'
import Section from './Section'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import { setTimeCodeHandler } from '@src/utils/hiring'

type Props = {
  data: InterviewOptionInterface[]
  answerName: string
  titleIndexNumber: number
  title: string
  notesName?: string
  subtitle?: string
  isViewMode?: boolean
  isOptional?: boolean
  placeholder?: string
}

const DropdownCard = ({
  data,
  answerName,
  isViewMode,
  isOptional,
  title,
  subtitle,
  notesName,
  titleIndexNumber,
  placeholder,
}: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const points = get(values, answerName)?.points || 0

  return (
    <Section
      index={titleIndexNumber}
      title={title}
      subtitle={subtitle}
      points={points}
      isViewMode={isViewMode}
      isOptional={isOptional}
      notesName={notesName}
      onAddTimeCode={setTimeCodeHandler(notesName, values)}
    >
      <LapeRadioSelectInput
        label={placeholder}
        name={answerName}
        options={data.map(option => {
          const label = option.points < 0 ? `🚩\u00A0 ${option.option}` : option.option
          return {
            label,
            value: {
              id: option.internal_data_id,
              ...option,
            },
          }
        })}
        disabled={isViewMode}
        inputProps={{
          'data-testid': answerName,
        }}
      />
    </Section>
  )
}

export default connect(DropdownCard)
