import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OptionInterface } from '@src/interfaces/selectors'
import Tags, { TagsProps } from '@components/Tags/Tags'

interface Props extends TagsProps {
  title?: string
  tags: OptionInterface[]
}

const CardTitleTags = ({ title, tags, ...props }: Props) => {
  return (
    <Tags
      tags={tags.map(tag => ({
        ...tag,
        link: pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id: tag.id }),
      }))}
      title={title}
      {...props}
    />
  )
}

export default CardTitleTags
