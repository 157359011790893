import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { saveFile } from '@src/utils'
import { useGetOfferSettings } from '@src/api/settings'

export const DownloadOffer = () => {
  const { values } = useLapeContext<OfferCreationInterface>()
  const { data: offerSettings } = useGetOfferSettings()
  const { url, saved_name } = values.offer_file || {}
  if (offerSettings?.enable_offer_signing || !url || !saved_name) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="Download"
      onClick={() => {
        if (url && saved_name) {
          saveFile(url, saved_name)
        }
      }}
    >
      Download offer
    </MoreBar.Action>
  )
}
