import React, { useState } from 'react'
import {
  Popup,
  Text,
  Header,
  Button,
  useStatusPopup,
  StatusPopup,
  Token,
} from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'

import { ActionPopupVariant } from '../types'
import {
  archiveEngagementSurvey,
  cancelEngagementSurvey,
  unarchiveEngagementSurvey,
} from '@src/api/engagement'

interface Props {
  variant: ActionPopupVariant
  isOpen: boolean
  onClose: VoidFunction
  surveyId: number
  refetch: VoidFunction
}

const mapVariantToFunction = (variant: ActionPopupVariant) => {
  switch (variant) {
    case 'archive':
      return archiveEngagementSurvey
    case 'unarchive':
      return unarchiveEngagementSurvey
    case 'close':
      return cancelEngagementSurvey
    default:
      return undefined
  }
}

const mapVariantToDescription = (variant: ActionPopupVariant) => {
  switch (variant) {
    case 'close':
      return 'It will not be sent to any member of the audience. You will still be able to reactivate it until the scheduled sending date.'
    default:
      return null
  }
}

export const SurveyPopup = ({ isOpen, onClose, surveyId, refetch, variant }: Props) => {
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  const handleSubmit = async () => {
    setIsPending(true)
    try {
      const actionFn = mapVariantToFunction(variant)
      await actionFn?.(surveyId)
      refetch()
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to {variant} survey run</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
      onClose()
    }
  }

  return (
    <Popup open={isOpen} onClose={onClose} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Are you sure you want to {variant} this survey?</Header.Title>
      </Header>
      <Text use="p" variant="caption" color={Token.color.greyTone50}>
        {mapVariantToDescription(variant)}
      </Text>
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={e => {
            e.stopPropagation()
            onClose()
          }}
          disabled={isPending}
        >
          Cancel
        </Button>
        <Button elevated pending={isPending} disabled={isPending} onClick={handleSubmit}>
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
