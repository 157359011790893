import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import AccountForm from './General'
import AccountPreview from './Preview'

const LinkedAccounts = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.LINKED_ACCOUNT.GENERAL}>
        <AccountForm />
      </Route>
      <Route exact path={ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW}>
        <AccountPreview />
      </Route>
    </Switch>
  )
}

export default LinkedAccounts
