import React from 'react'
import { Text } from '@revolut/ui-kit'

type Props = {
  text?: string | null
}

const PreviewNotes = ({ text }: Props) => {
  return (
    <>
      <Text use="div" variant="primary" pt="s-20" pb="s-8">
        Notes
      </Text>
      <Text use="div" variant="caption" whiteSpace="break-spaces">
        {text}
      </Text>
    </>
  )
}

export default PreviewNotes
