import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, HStack, InputGroup, Side } from '@revolut/ui-kit'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import React from 'react'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const ExternalChannelForm = ({ onCancel }: { onCancel: () => void }) => {
  const { isSubmitting, submit } = useLapeContext<{ name: string; handle: string }>()
  const showStatusPopup = useShowStatusPopup()

  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeNewInput name="handle" label="Handle" required />
      </InputGroup>
      <Side.Actions>
        <HStack space="s-16">
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={validate(
              () => submit(),
              error => {
                showStatusPopup({
                  status: 'error',
                  title: 'Failed to update',
                  description: getStringMessageFromError(error),
                })
              },
            )}
            pending={isSubmitting}
          >
            Save changes
          </Button>
        </HStack>
      </Side.Actions>
    </>
  )
}
