import React from 'react'
import { useSelector } from 'react-redux'

import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { performanceSettings } from '@src/api/performanceSettings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import { TargetTypeFields } from '@src/pages/Settings/KPI/TargetTypesFields'
import { SaveKPISettingsButton } from '@src/pages/Settings/KPI/SaveButton'
import { GoalsSettingsProps } from './Goals'

const TargetTypes = () => {
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangePerformancePreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <TargetTypeFields disableEdit={disableEdit} variant="goal" />
        </AutoStepper>
      </PageBody>

      <SaveKPISettingsButton />
    </>
  )
}

export const GoalsSettingsTargetTypes = ({ targetTypesRoute }: GoalsSettingsProps) => {
  const routes = [
    {
      title: 'General',
      path: targetTypesRoute,
      url: targetTypesRoute,
      canView: [
        PermissionTypes.ViewPerformancePreferences,
        PermissionTypes.ChangePerformancePreferences,
      ],
      component: TargetTypes,
    },
  ]

  return <SettingsForm routes={routes} api={performanceSettings} title="Metric types" />
}
