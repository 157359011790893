import React from 'react'
import { Avatar, Box, Icon, Item, Token, VStack, Text, Group } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { OnboardingCheckpointStateId } from '@src/interfaces/onboardingChecklistV2'

import {
  OnboardingCheckpointConfig,
  OnboardingCheckpointData,
} from '../common/checkpointsConfig'
import { useGetAllowedCheckpointsWithConfigs } from '../common/hooks'
import { SectionTitle } from '../components/SectionTitle'
import { isCheckpointDisabled } from '../common/helpers'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

type OnboardingChecklistStateBadgeProps = {
  state?: OnboardingCheckpointStateId
}
export const OnboardingChecklistStateBadge = ({
  state,
}: OnboardingChecklistStateBadgeProps) => {
  switch (state) {
    case 'not_started':
      return <Avatar.Badge useIcon="16/ExclamationMarkSign" bg={Token.color.orange} />
    case 'completed':
      return <Avatar.Badge useIcon="Check" bg={Token.color.green} />
    case 'in_progress':
      return <Avatar.Badge useIcon="16/StatusClockArrows" bg={Token.color.blue} />
    default:
      return null
  }
}

type ChecklistItemProps = {
  checkpoint: OnboardingCheckpointData
  isDisabled: boolean
}
const ChecklistItem = ({ checkpoint, isDisabled }: ChecklistItemProps) => {
  const { config, data: checkpointData } = checkpoint

  return (
    <Item
      variant="disclosure"
      use="button"
      onClick={() => navigateTo(pathToUrl(config.path))}
      disabled={isDisabled || checkpointData?.state?.id === 'locked'}
      aria-label={config.label}
    >
      <Item.Avatar>
        <Avatar
          useIcon={config.icon}
          color={Token.color.background}
          bg={Token.color.blue}
        >
          <OnboardingChecklistStateBadge state={checkpointData?.state?.id} />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{config.title}</Item.Title>
        <Item.Description>
          {checkpointData?.state?.id === 'not_started' ? (
            <Text color={Token.color.orange}>Requires action</Text>
          ) : checkpointData?.state?.id === 'in_progress' ? (
            <Text color={Token.color.blue}>In progress</Text>
          ) : checkpointData?.state?.id === 'completed' ? (
            <Text color={Token.color.green}>Completed</Text>
          ) : null}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        {checkpointData?.state?.id === 'locked' ? (
          <Icon name="LockClosed" size={20} color={Token.color.greyTone20} />
        ) : null}
      </Item.Side>
    </Item>
  )
}

type ChecklistSectionProps = {
  configs: OnboardingCheckpointConfig[]
  title: string
  subtitle?: string
  canPostpone?: boolean
}
export const ChecklistSection = ({
  configs: sectionConfigs,
  title,
  subtitle,
  canPostpone = true,
}: ChecklistSectionProps) => {
  const permissions = useSelector(selectPermissions)
  const checkpoints = useGetAllowedCheckpointsWithConfigs()
  const sectionCheckpoints = sectionConfigs
    .map(config => checkpoints.find(c => c.config.category === config.category))
    .filter(Boolean)

  if (!sectionCheckpoints.length) {
    return null
  }

  return (
    <Box>
      <SectionTitle
        title={title}
        subtitle={subtitle}
        categoriesToPostpone={canPostpone ? sectionConfigs.map(c => c.category) : []}
      />
      <Group>
        <VStack width="100%">
          {sectionCheckpoints.map(checkpoint => {
            const isDisabled =
              checkpoint.config.category === 'paymentMethod'
                ? !checkpoint.config.canView.every(p => permissions.includes(p))
                : isCheckpointDisabled(
                    checkpoint?.data.category,
                    checkpoints.map(c => c.data),
                  )
            return (
              <ChecklistItem
                key={checkpoint.config.path}
                checkpoint={checkpoint}
                isDisabled={isDisabled}
              />
            )
          })}
        </VStack>
      </Group>
    </Box>
  )
}
