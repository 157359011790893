import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { Token } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { useCareersSettings, useFetchPosition } from '@src/api/recruitment/careers'
import { getIdFromPositionSlug } from '@src/pages/Careers/utils'
import CareersPositionMetadata from '@src/pages/Careers/CareersPosition/CareersPositionMetadata'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { CareersPositionContent } from '@src/pages/Careers/CareersPosition/CareersPositionContent'

export const CareersPosition = () => {
  const { id } = useParams<{ id: string }>()
  const { data: position } = useFetchPosition(getIdFromPositionSlug(id))
  const { data: settings } = useCareersSettings()

  useSetDocumentTitle(
    position ? position.text : undefined,
    settings?.company_name ? `Careers at ${settings.company_name}` : undefined,
  )

  if (!position) {
    return null
  }

  return (
    <PageBody maxWidth={Token.breakpoint.lg}>
      <CareersPositionMetadata position={position} />
      <CareersPositionContent
        text={position.text}
        locations={position.locations}
        id={position.id}
        description={position.description}
        video={position.presentation_video_url}
      />
    </PageBody>
  )
}
