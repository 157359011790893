import React from 'react'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { ResultHeader } from '@src/pages/Forms/Candidate/Performance/components/ResultHeader'
import { OverviewHeader } from '@src/pages/Forms/Candidate/Performance/components/OverviewHeader'
import { OverallGrade } from '@src/pages/Forms/Candidate/Performance/components/OverallGrade'

type PerformanceOverviewProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
}

export const PerformanceOverview = ({ candidate, round }: PerformanceOverviewProps) => {
  const { data, isLoading } = useGetCandidatePerformanceProfile(round?.id)
  return (
    <OverviewHeader
      overallGrade={
        <OverallGrade grade={data?.skill_ratings?.average_rating} loading={isLoading} />
      }
      results={
        <>
          <ResultHeader
            loading={isLoading}
            value={data?.skill_ratings?.skill?.average_rating}
            type="skills"
          />
          <ResultHeader
            loading={isLoading}
            value={data?.skill_ratings?.value?.average_rating}
            type="values"
          />
          <ResultHeader
            label="Expected seniority"
            loading={isLoading}
            type="seniority"
            value={candidate?.active_interview_round?.seniority?.name}
          />
        </>
      }
      resultsLabel="Assessment"
    />
  )
}
