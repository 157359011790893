import React, { useMemo, useState } from 'react'
import { Group } from '@revolut/ui-kit'

import EmailThreadItem from '@src/features/EmailThread/EmailThreadItem'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'

export interface EmailThreadProps {
  replyUrl?: string
  emails: SendEmployeeEmailInterface[] | SendCandidateEmailInterface[]
  emailId?: number | string
}

const EmailThread = ({ replyUrl, emails, emailId }: EmailThreadProps) => {
  const [filePreview, setFilePreview] =
    useState<{ id: number; url: string | null; name: string }>()

  const filteredEmails = useMemo(() => {
    const index = emailId ? emails.findIndex(email => +email.id === +emailId) : 0

    return emails.slice(index)
  }, [emails, emailId])

  return (
    <>
      {filePreview && (
        <PreviewDocumentSidebar
          file={filePreview}
          onClose={() => setFilePreview(undefined)}
        />
      )}

      <Group>
        {filteredEmails.map((email, idx) => (
          <EmailThreadItem
            key={`email_${email.id}`}
            email={email}
            replyUrl={replyUrl}
            isFirst={idx === 0}
            onShowFilePreview={setFilePreview}
          />
        ))}
      </Group>
    </>
  )
}

export default EmailThread
