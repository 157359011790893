import React from 'react'
import { connect } from 'lape'
import { InputGroup, MoreBar, Box } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { documentCategoriesRequests } from '@src/api/documentsCategories'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'
import { EntityPermissions } from '@src/store/auth/types'
import { DeleteButtonLape } from '../FormButtons'

const DocumentCategory = () => {
  const { values } = useLapeContext<DocumentsCategoriesInterface>()

  const backUrl = ROUTES.APPS.DOCUMENTS.CATEGORIES
  const canDelete = values.field_options?.actions?.includes(EntityPermissions.Delete)

  return (
    <PageWrapper>
      <PageHeader title="Document category" backUrl={backUrl} />

      <PageBody>
        {canDelete && (
          <Box mb="s-32">
            <MoreBar>
              <DeleteButtonLape
                useMoreBar
                data={values}
                backUrl={backUrl}
                deleteApi={documentCategoriesRequests.delete!}
                title="Delete category"
              />
            </MoreBar>
          </Box>
        )}

        <InputGroup>
          <LapeNewInput name="name" label="Name" required />
          <LapeNewTextArea name="description" label="Description" />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Document category saved"
          useValidator
          onAfterSubmit={data =>
            navigateReplace(
              pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL, { id: data.id }),
            )
          }
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => {
  return (
    <Form api={documentCategoriesRequests}>
      <DocumentCategory />
    </Form>
  )
})
