import React, { MouseEvent, useEffect } from 'react'
import {
  Avatar,
  Box,
  Color,
  Flex,
  IconButton,
  InputGroup,
  Item,
  Grid,
  Link,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { Delete, Plus } from '@revolut/icons'
import { navigateReplace } from '@src/actions/RouterActions'
import { PROBLEM_SOLVING_PLAYBOOK } from '@src/constants/externalLinks'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  CultureValueInterface,
  CultureValueType,
  CultureValueTypeTitle,
} from '@src/interfaces/cultureValues'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import useIsCommercial from '@src/hooks/useIsCommercial'

const valueTypeOptions: RadioOption[] = [
  {
    label: CultureValueTypeTitle[CultureValueType.Culture],
    value: CultureValueType.Culture,
    tooltip:
      'These values and behaviours will appear under the culture section for all scorecards.',
  },
  {
    label: CultureValueTypeTitle[CultureValueType.Management],
    value: CultureValueType.Management,
    tooltip:
      'These values and behaviours will appear under the management section for upwards review scorecards only.',
  },
]

export const ValuesForm = ({
  hidePlaybookInput = false,
}: {
  hidePlaybookInput?: boolean
}) => {
  const isCommercial = useIsCommercial()
  const { values } = useLapeContext<CultureValueInterface>()

  useEffect(() => {
    if (!values.id) {
      values.is_enabled = true
    }
  }, [])

  const handleAddBehaviour = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    values.behaviours = [...(values.behaviours || []), { description: '', name: '' }]
  }

  const handleDeleteBehaviour = (id: number) => {
    values.behaviours = values.behaviours?.filter((_, i) => i !== id)
  }

  return (
    <AutoStepper>
      <NewStepperTitle title="General info" />
      <InputGroup>
        <LapeNewInput name="name" label="Value name" required />
        <LapeNewTextArea
          name="description"
          label="Description"
          required
          rows={2}
          message="A short description that will help managers conducting performance reviews understand what this value means"
        />
        {hidePlaybookInput ? null : (
          <LapeNewInput
            message={
              isCommercial ? undefined : (
                <>
                  Please link to the confluence playbook that defines further information
                  for this value.{' '}
                  <Link href={PROBLEM_SOLVING_PLAYBOOK} target="_blank">
                    View example of a playbook
                  </Link>
                </>
              )
            }
            name="playbook_link"
            label="Link to playbook"
          />
        )}
        <Text color={Color.GREY_TONE_50} pt="s-16" variant="caption">
          Value category
        </Text>
        <LapeNewRadioButtons
          defaultOptionIndex={0}
          name="category"
          options={valueTypeOptions}
          variant="cell"
        />
      </InputGroup>
      <NewStepperTitle title="Value based behaviours" />
      <Grid gap="s-16">
        {values?.behaviours?.map((_, id) => {
          return (
            <Widget key={id}>
              <Box p="s-16">
                <VStack gap="s-16" mb="s-16">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text fontWeight={500} variant="primary" use="div">
                      Behaviour {id + 1}
                    </Text>
                    <IconButton
                      aria-label="Delete"
                      color={Color.GREY_TONE_50}
                      onClick={() => handleDeleteBehaviour(id)}
                      useIcon={Delete}
                    />
                  </Flex>
                  <InputGroup>
                    <LapeNewInput name={`behaviours.${id}.name`} label="Name" required />
                    <LapeNewTextArea
                      data-testid={`behaviour_description_${id}`}
                      name={`behaviours.${id}.description`}
                      label="Description"
                      required
                      rows={5}
                    />
                  </InputGroup>
                </VStack>
              </Box>
            </Widget>
          )
        })}
        <Item onClick={handleAddBehaviour} type="button" use="button">
          <Item.Avatar>
            <Avatar useIcon={Plus} />
          </Item.Avatar>
          <Item.Content color="primary">Add new behaviour</Item.Content>
        </Item>
      </Grid>
      <InputGroup />
    </AutoStepper>
  )
}

const General = () => {
  return (
    <>
      <PageBody>
        <ValuesForm />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={res =>
            navigateReplace(pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id: res.id }))
          }
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
