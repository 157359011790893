import { AxiosPromise } from 'axios'

import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'

export const getDocumentsCategories = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<DocumentsCategoriesInterface>
> =>
  api.get(API.DOCUMENT_CATEGORIES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const documentCategoriesRequests: RequestInterfaceNew<DocumentsCategoriesInterface> =
  {
    get: async ({ id }) => api.get(`${API.DOCUMENT_CATEGORIES}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUMENT_CATEGORIES}/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.DOCUMENT_CATEGORIES}`, data),
    delete: async ({ id }) => api.delete(`${API.DOCUMENT_CATEGORIES}/${id}`),
  }
