import React from 'react'
import { getOnboardingTimelineV2CommentsAPI } from '@src/api/onboardingEmployeesV2'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { PageBody } from '@components/Page/PageBody'

type Props = {
  onboardingId: string
  onClickSeeAll?: VoidFunction
  onRefetch?: VoidFunction
}

export const Comments = ({ onboardingId, onClickSeeAll, onRefetch }: Props) => {
  const commentsApi = getOnboardingTimelineV2CommentsAPI(onboardingId)
  return (
    <PageBody>
      <CommentsSection
        api={commentsApi}
        onRefetch={onRefetch}
        onSideOpen={onClickSeeAll}
        withFileUploader
        withInfiniteLoading
      />
    </PageBody>
  )
}
