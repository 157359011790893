import React, { useState } from 'react'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { engagementSurveyFormRequests } from '@src/api/engagement'

export const usePublishResults = (surveyId: number, refetch?: VoidFunction) => {
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  const togglePublishSurveyState = async (newState: boolean) => {
    try {
      setIsPending(true)
      await engagementSurveyFormRequests.update(
        { is_published: newState },
        { id: String(surveyId) },
      )
      refetch && (await refetch())
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't start test survey</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const publishResults = () => {
    togglePublishSurveyState(true)
  }

  const unpublishResults = () => {
    togglePublishSurveyState(false)
  }

  return {
    publishResults,
    unpublishResults,
    isPending,
  }
}
