import React from 'react'
import { Box, Flex, Text, Token, Tooltip, useTooltip, VStack } from '@revolut/ui-kit'
import { BarChart, CheckSuccess, CrossCircle } from '@revolut/icons'
import { MeetingQuality, MeetingTrackerInterface } from '@src/interfaces/meetingsTracker'

const getMeetingQuality = (data: MeetingTrackerInterface) => {
  const minDetailedAgendaLength = 150
  const agendaWithoutTags = data.agenda?.replace(/<([\s\S]+?)>/g, '')
  const hasAgenda = agendaWithoutTags?.length >= minDetailedAgendaLength
  const hasNotes = !!data.attached_notes?.length
  let quality = MeetingQuality.Low
  let color = Token.color.red
  if (hasAgenda && hasNotes) {
    quality = MeetingQuality.High
    color = Token.color.green
  } else if (hasAgenda || hasNotes) {
    quality = MeetingQuality.Medium
    color = Token.color.warning
  }

  return { quality, color, hasAgenda, hasNotes }
}

export const QualityWithTooltip: React.FC<{ data: MeetingTrackerInterface }> = ({
  data,
}) => {
  const tooltip = useTooltip()
  const { quality, color, hasAgenda, hasNotes } = getMeetingQuality(data)

  const getIcon = (value: boolean) => {
    return value ? (
      <CheckSuccess size={16} color={Token.color.green} />
    ) : (
      <CrossCircle size={16} color={Token.color.red} />
    )
  }

  return (
    <>
      <Box {...tooltip.getAnchorProps()}>
        <Flex alignItems="center" justifyContent="flex-end" gap="s-4">
          <Text color={color} style={{ cursor: 'default' }}>
            {quality}
          </Text>
          <BarChart size={16} color={color} />
        </Flex>
        <Tooltip {...tooltip.getTargetProps()} placement="left-end">
          <VStack>
            <Flex gap="s-4" alignItems="center">
              {getIcon(hasAgenda)}
              <Text>Detailed agenda</Text>
            </Flex>
            <Flex gap="s-4" alignItems="center">
              {getIcon(hasNotes)}
              <Text>Shared notes</Text>
            </Flex>
          </VStack>
        </Tooltip>
      </Box>
    </>
  )
}
