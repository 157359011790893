import React from 'react'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

const yesOption = { id: 1, name: 'Yes' }
const noOption = { id: 0, name: 'No' }

export interface YesOrNoRadioSelectProps
  extends Omit<
    RadioSelectInputProps<{ id: number; name: string }>,
    'value' | 'onChange'
  > {
  value?: boolean | null
  onChange: (option: boolean | null) => void
}

export const YesOrNoRadioSelect = ({
  value,
  onChange,
  ...props
}: YesOrNoRadioSelectProps) => {
  let val = null

  if (value != null) {
    val = value ? yesOption : noOption
  }

  return (
    <RadioSelectInput
      options={[
        { label: 'Yes', value: yesOption },
        { label: 'No', value: noOption },
      ]}
      value={val}
      searchable={false}
      onChange={option => {
        onChange(option ? Boolean(option.id) : null)
      }}
      {...props}
    />
  )
}
