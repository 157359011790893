import React from 'react'
import { Button, Popup } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { interviewScorecardTemplateFormRequests } from '@src/api/interviewScorecardTemplate'
import InterviewScorecardTemplateForm, {
  validator,
} from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import Form from '@src/features/Form/Form'

type CreateNewScorecardProps = {
  onCancel: () => void
  onNext: () => void
}

const CreateNewScorecard = ({ onCancel, onNext }: CreateNewScorecardProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const updateScorecardTemplate = (result: InterviewScorecardTemplateInterface) => {
    values.scorecard_template = {
      id: result.id,
      name: result.name ?? '',
    }
  }
  return (
    <Form
      api={interviewScorecardTemplateFormRequests}
      validator={validator}
      forceParams={{ id: undefined }}
      disableLocalStorageCaching
    >
      <InterviewScorecardTemplateForm
        noPopup
        renderActions={children => (
          <Popup.Actions horizontal>
            <Button variant="secondary" onClick={onCancel}>
              Cancel
            </Button>
            {children}
          </Popup.Actions>
        )}
        submitLabel="Next"
        onSubmit={updateScorecardTemplate}
        onAfterSubmit={onNext}
      />
    </Form>
  )
}

export default CreateNewScorecard
