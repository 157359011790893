import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { EmployeeEmails } from '@src/interfaces/selectors'

export const useOptions = () => {
  const { data: employees = [] } = useGetSelectors<EmployeeEmails>(
    selectorKeys.employee_emails,
  )
  const { data: policies = [] } = useGetSelectors<IdAndName<string>>(
    selectorKeys.active_time_off_policies,
  )
  const { data: approvalStatuses = [] } = useGetSelectors<IdAndName<string>>(
    selectorKeys.time_off_request_approval_statuses,
  )
  const { data: units = [] } = useGetSelectors<IdAndName<string>>(
    selectorKeys.time_off_units,
  )
  const { data: periods = [] } = useGetSelectors<IdAndName<string>>(
    selectorKeys.time_off_request_periods,
  )

  return { employees, policies, approvalStatuses, units, periods }
}
