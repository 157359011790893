import React from 'react'
import { Box, Flex, Token } from '@revolut/ui-kit'
import { COMPLAINTS_REVOLUT_PEOPLE, CONTACT_REVOLUT_PEOPLE } from '@src/constants/emails'

export const Complaints = () => {
  return (
    <Flex justifyContent="center" p="s-32">
      <Box maxWidth={1000} color={Token.color.foreground}>
        <h1>How to make a complaint</h1>

        <p>
          You can submit a complaint by emailing our dedicated email address{' '}
          <a
            href={`mailto:${COMPLAINTS_REVOLUT_PEOPLE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {COMPLAINTS_REVOLUT_PEOPLE}
          </a>
          .
        </p>

        <p>
          For any other enquiries, please reach out to us via{' '}
          <a
            href={`mailto:${CONTACT_REVOLUT_PEOPLE}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {CONTACT_REVOLUT_PEOPLE}
          </a>
          .
        </p>

        <p>
          Once we have received your complaint we will acknowledge this via email. We will
          then investigate all the details of your complaint, and issue our response
          within a couple of days, but this can take up to 15 business days.
        </p>
      </Box>
    </Flex>
  )
}
