export const DEBOUNCE_WAIT = 750

export const DEFAULT_INVITATION_EMAIL =
  "<h2>Welcome {first_name}!</h2><p><br></p><p>We're thrilled to have you join us. To begin your onboarding journey:</p><p><br></p><h3>Set up your account</h3><p><br></p><ol><li>Click the button below to create your password</li><li>Use your personal email to log in to Revolut People, our people platform</li><li>From this moment forward, this password can be used to log in to the system, even once you start working with us</li></ol><p><br></p><h3>Complete pre-onboarding</h3><p><br></p><ol><li>Access the Onboarding Portal by logging to the platform.</li><li>Provide necessary information and documents</li><li>You can log in to the Onboarding Portal at any time to continue your progress and complete any remaining actions</li></ol><p><br></p><h3>Your first day: {joining_date_time}</h3><p><br></p><p>On your first day, please come to the office and collect your badge from reception. Your manager will be your primary point of contact and will guide you through your initial tasks. The IT team will provide you with your tech equipment to get you started.</p><p><br></p><p>We may contact you before your start date with additional questions or clarifications. In the meantime, please don't hesitate to contact us if you need anything.</p><p><br></p><p>Best regards,</p><p>HR Team</p>"

export const INVITATION_EMAIL_PLACEHOLDERS = [
  '{full_name}',
  '{first_name}',
  '{last_name}',
  '{email}',
  '{location}',
  '{country}',
  '{joining_date_time}',
  '{team_name}',
  '{job_title}',
  '{line_manager_name}',
]
