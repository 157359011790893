import React, { useState } from 'react'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  Button,
  Flex,
  Header,
  MoreBar,
  Popup,
  Text,
  Tooltip as UIKitTooltip,
  useTooltip,
  Widget,
} from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { ownerNameColumn } from '@src/constants/columns/employee'
import { ExportRequest, Statuses } from '@src/interfaces'
import {
  kpiCurrentValueColumn,
  kpiEntityColumn,
  kpiEntityTypeColumn,
  kpiGenericNameColumnFilteredByUnique,
  kpiInitialValueColumn,
  kpiPerformanceColumn,
  kpiPerformanceTypeColumn,
  kpiStatusColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import { companyGoalTarget1YColumn } from '@src/constants/columns/companyGoals'
import { KpiInterface } from '@src/interfaces/kpis'
import { getKPIsCSV, kpisRequests, refreshKPIs } from '@src/api/kpis'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { ArrowRecurring, ArrowSend, ShareIOs } from '@revolut/icons'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { sendNotificationPersonalKPIsToAssign } from '@src/api/kpiNotifications'
import { successNotification } from '@src/actions/NotificationActions'
import { onDatePickerInputChange } from '@components/Inputs/LapeFields/LapeDatePickerInput'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { CycleOption } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { ExportTypes } from '@src/constants/export'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { TableWrapper } from '@components/Table/TableWrapper'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { useLocation } from 'react-router-dom'

const RowNotifications: RowInterface<KpiInterface> = {
  disabled: data => data.status === Statuses.archived,
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id })),
  cells: [
    {
      ...kpiGenericNameColumnFilteredByUnique,
      width: 400,
    },
    {
      ...kpiPerformanceColumn,
      width: 120,
    },
    {
      ...kpiPerformanceTypeColumn,
      width: 80,
    },
    {
      ...kpiEntityTypeColumn,
      width: 80,
    },
    {
      ...kpiEntityColumn,
      width: 100,
    },
    {
      ...kpiInitialValueColumn,
      width: 50,
    },
    {
      ...kpiCurrentValueColumn,
      width: 50,
    },
    {
      ...kpiTargetColumn,
      width: 50,
    },
    {
      ...companyGoalTarget1YColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
    {
      ...ownerNameColumn,
      width: 120,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 70,
    },
    {
      ...kpiStatusColumn,
      width: 50,
    },
  ],
}

const KPIsTable = () => {
  const location = useLocation()
  const searchQuery = new URLSearchParams(location.search)
  const reviewCycleQuery = searchQuery.get('review_cycle__offset') || ''

  const [showCascadeKPIsPopup, setShowCascadeKPIsPopup] = useState(false)
  const [cascadeKPIsPending, setKpiNotificationPending] = useState(false)
  const [cascadeKPIsDeadline, setKpiNotificationDeadline] = useState<string>()

  const [refreshKPIsPending, setRefreshKPIsPending] = useState(false)

  const { options } = useFetchOptions<CycleOption>(selectorKeys.cycle_offsets)
  const reviewCycles = options?.map(option => option.value)

  const cascadeTooltip = useTooltip()
  const refreshTooltip = useTooltip()

  const table = useTable<KpiInterface>(kpisRequests, [
    {
      columnName: 'status',
      filters: [{ name: Statuses.active, id: Statuses.active }],
    },
    {
      filters: searchQuery.has('review_cycle__offset')
        ? [{ name: reviewCycleQuery, id: reviewCycleQuery }]
        : [{ name: '0', id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ])
  const permissions = useSelector(selectPermissions)
  const isKPIAdministrator = permissions.includes(
    PermissionTypes.KPIAdministratorPermissions,
  )

  const selectedFilters = table.filterBy?.find(
    (filterCyclesBy: FilterByInterface) =>
      filterCyclesBy.columnName === 'review_cycle__offset',
  )?.filters
  const foundCycleId = reviewCycles?.find(
    cycle => cycle.id === Number(selectedFilters?.[0]?.id),
  )?.cycle_id

  const handleCascadeKPIs = () => {
    if (selectedFilters?.length === 1) {
      setShowCascadeKPIsPopup(true)
    }
  }

  const handleCascadeKPIsSendButtonClick = async () => {
    if (cascadeKPIsDeadline && foundCycleId) {
      try {
        setKpiNotificationPending(true)
        await sendNotificationPersonalKPIsToAssign(foundCycleId, cascadeKPIsDeadline)
        successNotification('Cascade KPIs request has been sent')
        setKpiNotificationDeadline(undefined)
      } finally {
        setShowCascadeKPIsPopup(false)
        setKpiNotificationPending(false)
      }
    }
  }

  const handleRefreshKPIs = async () => {
    try {
      setRefreshKPIsPending(true)
      await refreshKPIs()
      successNotification('Successfully sent the request to refresh KPIs')
    } finally {
      setRefreshKPIsPending(false)
    }
  }

  return (
    <>
      <TableWrapper>
        <Flex mb="s-24">
          <Stat label="Total KPI" val={table?.count} mr="s-32" />
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        </Flex>

        {isKPIAdministrator && (
          <Flex mb="s-16">
            <MoreBar>
              <MoreBar.Action
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.KPIS.UPLOAD_FILE)}
                useIcon={ShareIOs}
              >
                Import data
              </MoreBar.Action>
              <MoreBar.Action
                use="button"
                useIcon={ArrowSend}
                onClick={handleCascadeKPIs}
                aria-disabled={selectedFilters?.length !== 1}
                {...cascadeTooltip.getAnchorProps()}
              >
                Cascade KPIs
                <UIKitTooltip
                  {...cascadeTooltip.getTargetProps()}
                  style={{ textAlign: 'center' }}
                  placement="bottom-start"
                  width={250}
                >
                  {selectedFilters?.length === 1
                    ? 'Cascade department and team KPIs for the selected cycle to their owners as employee level KPIs. Use the dynamic group to manage exceptions.'
                    : 'You can only cascade KPIs for a single cycle'}
                </UIKitTooltip>
              </MoreBar.Action>
              <MoreBar.Action
                use="button"
                useIcon={ArrowRecurring}
                onClick={handleRefreshKPIs}
                pending={refreshKPIsPending}
                {...refreshTooltip.getAnchorProps()}
              >
                Refresh KPIs
                <UIKitTooltip
                  {...refreshTooltip.getTargetProps()}
                  style={{ textAlign: 'center' }}
                  placement="bottom-start"
                  width={250}
                >
                  Refresh the data for all automatic KPIs in the selected cycle. This will
                  take ~3 hrs to complete and should be triggered during off-peak times.
                </UIKitTooltip>
              </MoreBar.Action>
              <ExportMenu
                request={
                  (() => foundCycleId && getKPIsCSV(foundCycleId)) as ExportRequest
                }
                fileName="KPI"
                supportedTypes={[ExportTypes.csv]}
                isDisabled={selectedFilters?.length !== 1}
                tooltip="You can only download CSV for a single cycle"
              />
            </MoreBar>
          </Flex>
        )}
        <AdjustableTable<KpiInterface>
          name={TableNames.KPIs}
          useWindowScroll
          dataType="KPI"
          row={RowNotifications}
          {...table}
          noDataMessage="KPIs will appear here."
          onRowClick={onKPITableRowClick}
        />
      </TableWrapper>

      <Popup
        open={showCascadeKPIsPopup}
        variant="bottom-sheet"
        onClose={() => setShowCascadeKPIsPopup(false)}
      >
        <Header variant="bottom-sheet">
          <Header.Title>Cascade KPIs</Header.Title>
        </Header>
        <Text use="p" variant="caption" color="grey-tone-50">
          Please set a deadline for assigning KPIs
        </Text>
        <Widget>
          <DatePickerInput
            onChange={date =>
              onDatePickerInputChange(date, value => {
                setKpiNotificationDeadline(value || undefined)
              })
            }
            value={cascadeKPIsDeadline}
          />
        </Widget>
        <Popup.Actions horizontal>
          <Button
            variant="secondary"
            autoFocus
            onClick={() => setShowCascadeKPIsPopup(false)}
          >
            Cancel
          </Button>
          <Button
            elevated
            disabled={!cascadeKPIsDeadline}
            onClick={handleCascadeKPIsSendButtonClick}
            pending={cascadeKPIsPending}
          >
            Send
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default KPIsTable
