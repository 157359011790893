import React from 'react'
import { useQuery } from '@src/utils/queryParamsHooks'
import GoalsPage from '@src/pages/Forms/Company/KPI/Goals'
import CompanyPage from '@src/pages/Forms/Company/KPI/Company'
import DepartmentPage from '@src/pages/Forms/Company/KPI/Department'
import { CompanyInterface } from '@src/interfaces/company'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Route, Switch, useParams } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface StructureProps {
  data: CompanyInterface
}

enum CompanyKPITypes {
  Goals = 'Goals',
  Company = 'Company',
  Department = 'Department',
}

/** @deprecated */
const Culture = ({ data }: StructureProps) => {
  const params = useParams<{ id: string; subtab?: string }>()
  const { query } = useQuery()

  const tabs = [
    {
      title: CompanyKPITypes.Goals,
      path: ROUTES.FORMS.COMPANY.KPI.GOALS,
      to: pathToUrl(ROUTES.FORMS.COMPANY.KPI.GOALS, params),
      component: <GoalsPage data={data} />,
    },
    {
      title: CompanyKPITypes.Company,
      path: ROUTES.FORMS.COMPANY.KPI.COMPANY,
      to: pathToUrl(ROUTES.FORMS.COMPANY.KPI.COMPANY, params),
      component: <CompanyPage data={data} />,
    },
    {
      title: CompanyKPITypes.Department,
      path: ROUTES.FORMS.COMPANY.KPI.DEPARTMENT,
      to: pathToUrl(ROUTES.FORMS.COMPANY.KPI.DEPARTMENT, params),
      component: <DepartmentPage data={data} />,
    },
  ]

  if (query.tab || params?.subtab === undefined) {
    switch (query.tab) {
      case CompanyKPITypes.Company:
        return (
          <InternalRedirect to={pathToUrl(ROUTES.FORMS.COMPANY.KPI.COMPANY, params)} />
        )
      case CompanyKPITypes.Goals:
        return <InternalRedirect to={pathToUrl(ROUTES.FORMS.COMPANY.KPI.GOALS, params)} />
      case CompanyKPITypes.Department:
      default:
        return (
          <InternalRedirect to={pathToUrl(ROUTES.FORMS.COMPANY.KPI.COMPANY, params)} />
        )
    }
  }

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={tabs} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            {tab.component}
          </Route>
        ))}
      </Switch>
    </Flex>
  )
}

export default Culture
