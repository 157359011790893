import React from 'react'
import Form from '@src/features/Form/Form'
import { whitelistRequests } from '@src/api/whitelist'
import WhiteListForm from '@src/pages/Forms/WhitelistForm/General'

const WhiteListPage = () => {
  return (
    <Form api={whitelistRequests} validator={{}}>
      <WhiteListForm />
    </Form>
  )
}

export default WhiteListPage
