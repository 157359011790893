import React, { useState, useRef } from 'react'
import { connect } from 'lape'
import { Bar, ActionButton, Banner, Avatar, VStack } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import {
  MetricsGrid,
  MetricsGridHandle,
} from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsGrid'
import { MetricsSidebar } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsSidebar'

export const Metrics = connect(() => {
  const gridRef = useRef<MetricsGridHandle>(null)
  const [open, setOpen] = useState(true)

  return (
    <PageBody maxWidth="100%">
      <VStack space="s-24">
        <Bar>
          <ActionButton useIcon="IndustrialGear" onClick={() => setOpen(true)}>
            Select queries
          </ActionButton>
          <ActionButton
            useIcon="Text"
            onClick={() => {
              gridRef.current?.add('', false)
            }}
          >
            Add text card
          </ActionButton>
        </Bar>

        <Banner>
          <Banner.Avatar>
            <Avatar
              variant="square"
              image={{
                default: `https://assets.revolut.com/assets/3d-images-v2/3D244.png`,
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D244@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D244@3x.png',
              }}
              size={56}
            />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>Customize your dashboard</Banner.Title>
            <Banner.Description>
              Take control of your dashboard's appearance. Add, resize and rearrange
              metrics effortlessly to design the perfect layout that suits your data needs
            </Banner.Description>
          </Banner.Content>
        </Banner>

        <MetricsGrid ref={gridRef} />
      </VStack>

      <MetricsSidebar
        isOpen={open}
        onClose={() => setOpen(false)}
        onAdd={(id, isInDashboard) => gridRef.current?.add(id, isInDashboard)}
        onRemove={id => gridRef.current?.remove(id)}
      />
    </PageBody>
  )
})
