import React from 'react'
import { Flex, StatusWidget } from '@revolut/ui-kit'
import { ERRORS } from '@src/constants/notifications'

interface Props {
  title?: string
  message?: string
}

const PerformanceErrorWidget = ({
  title = ERRORS.UNKNOWN,
  message = "You don't have permissions to see this page",
}: Props) => {
  return (
    <Flex justifyContent="center" alignItems="center" height="100vh">
      <StatusWidget>
        <StatusWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
        <StatusWidget.Title>{title}</StatusWidget.Title>
        <StatusWidget.Description>{message}</StatusWidget.Description>
      </StatusWidget>
    </Flex>
  )
}

export default PerformanceErrorWidget
