import React, { useState } from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { Token, ActionButton } from '@revolut/ui-kit'
import Navigation from './Navigation'
import { useParams } from 'react-router-dom'
import {
  getBulkEditItems,
  applyContractsBulkEdit,
  useBulkEdit,
} from '@src/api/contractsBulkEdit'
import PageLoading from '@src/components/PageLoading/PageLoading'
import Stat from '@src/components/Stat/Stat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { navigateTo } from '@src/actions/RouterActions'
import BulkEditTable from '@src/components/BulkEdit/BulkEditTable'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import pluralize from 'pluralize'

const ReviewChanges = () => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const onApplyClick = async () => {
    setIsLoading(true)
    try {
      await applyContractsBulkEdit(id)
      navigateTo(pathToUrl(ROUTES.FORMS.BULK_EDIT_CONTRACTS.TRACK, { id }))
    } catch (e) {
      setError(e.message)
    }
    setIsLoading(false)
  }

  const { data: bulkEditData } = useBulkEdit(id)

  if (!bulkEditData) {
    return <PageLoading />
  }

  if (error) {
    return <FormErrorGuard error={error} />
  }

  const validationsPassed = bulkEditData.valid > 0
  const selectedIds = bulkEditData?.object_ids || []

  return (
    <>
      <PageBody pb="s-32" maxWidth={Token.breakpoint.xl}>
        <Navigation selectedIds={selectedIds} />
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Ready to change" val={bulkEditData.valid} />
            <Stat label="Can't be changed" val={bulkEditData.invalid} color="red" />
          </Table.Widget.Info>
          <Table.Widget.Table>
            <BulkEditTable
              id={id}
              autoRefresh={false}
              getItems={getBulkEditItems}
              tableName={TableNames.ContractsBulkEdit}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      <PageActions>
        <ActionButton
          variant="accent"
          pending={isLoading}
          onClick={onApplyClick}
          disabled={!validationsPassed}
          type="submit"
        >
          Apply {pluralize('change', bulkEditData?.valid, true)}
        </ActionButton>
      </PageActions>
    </>
  )
}

export default ReviewChanges
