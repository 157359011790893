import React from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'
import { Profile } from '@revolut/icons'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeButtonProps } from '../types'
import { canViewCandidate } from '../common'

export const CandidateProfile = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const showCandidate = canViewCandidate(data)

  if (!showCandidate) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use={InternalLink}
        useIcon={<Profile color={Color.BLUE} />}
        to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: data.candidate_id,
        })}
        color={Color.FOREGROUND}
      >
        Candidate profile
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      useIcon={Profile}
      onClick={() =>
        navigateTo(
          pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
            id: data.candidate_id,
          }),
        )
      }
    >
      Candidate profile
    </MoreBar.Action>
  )
}
