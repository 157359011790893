import React from 'react'
import styled from 'styled-components'
import { PerformanceRatingTitle, RatingColor } from '@src/constants/performance'
import { DeliverableOptions, PerformanceRating } from '@src/interfaces/performance'
import { InterviewResult } from '@src/interfaces/interviewTool'
import { Token } from '@revolut/ui-kit'

const Wrap = styled.span<{ color: string; underline?: boolean }>`
  color: ${props => props.color || 'inherit'};
  text-decoration: ${props => (props.underline ? 'underline' : 'inherit')};
`

export enum RatingLabelTypes {
  General = 'General',
  Inverse = 'Inverse',
  InverseUnderline = 'InverseUnderline',
}

interface Props {
  type?: RatingLabelTypes
  children?: React.ReactNode
  noUnderline?: boolean
  rating?: PerformanceRating | DeliverableOptions | InterviewResult | null
  className?: string
}

const UNDERLINE_LABELS = [RatingLabelTypes.InverseUnderline]

const RatingLabel = ({
  children,
  rating,
  type = RatingLabelTypes.General,
  noUnderline,
  className,
}: Props) => {
  let defaultColor

  switch (type) {
    case RatingLabelTypes.General:
      defaultColor = Token.color.teal
      break
    default:
      defaultColor = Token.color.foreground
  }

  let ratingColor
  let content = null

  if (rating) {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    ratingColor = RatingColor[rating] || defaultColor
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    content = PerformanceRatingTitle[rating]
  } else {
    ratingColor = Token.color.greyTone50
  }

  return (
    <Wrap
      color={ratingColor}
      underline={!noUnderline ? UNDERLINE_LABELS.includes(type) : undefined}
      className={className}
    >
      {children || content}
    </Wrap>
  )
}

export default RatingLabel
