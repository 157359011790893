import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import { IdAndName } from '@src/interfaces'
import { KpiGoals, KpiInterface } from '@src/interfaces/kpis'
import React from 'react'

export const ProgressDirection = () => {
  const { values } = useLapeContext<KpiInterface>()
  const { options: kpiGoalOptions } = useFetchOptions<IdAndName<KpiGoals>>(
    selectorKeys.kpi_goals,
    true,
  )

  const noKpiGoalOption =
    !!kpiGoalOptions.length &&
    !!values.targets?.[0]?.kpi_goal &&
    !kpiGoalOptions.find(opt => opt.value.id === values.targets?.[0]?.kpi_goal)

  return (
    <LapeRadioSelectInput
      name="kpi_goal"
      clearable={false}
      options={kpiGoalOptions}
      label="Strategy"
      value={
        values.targets?.[0]?.kpi_goal && {
          id: values.targets[0]?.kpi_goal,
          name: strategyLabelsMap[values.targets[0]?.kpi_goal],
        }
      }
      onChange={option => {
        if (option) {
          if (values.targets[0]) {
            values.targets[0].kpi_goal = option.id
          } else {
            values.targets.push({
              kpi_goal: option.id,
              initial_value: null,
              target: null,
              parent_kpi: null,
            })
          }
        }
      }}
      message={noKpiGoalOption ? 'Strategy needs to be changed' : undefined}
      hasError={noKpiGoalOption}
    />
  )
}
