import { Group, ItemSkeleton } from '@revolut/ui-kit'
import React from 'react'

export const CyclesNavigationSkeleton = () => {
  return (
    <Group>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
  )
}
