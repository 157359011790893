import React from 'react'
import { Tag, Box, Flex, Text, Token } from '@revolut/ui-kit'
import { PerformanceSelector } from '@src/interfaces/performance'
import capitalize from 'lodash/capitalize'
import { getCycleOffsetTitleAndColor, useGetPeriodTypes } from '@src/utils/performance'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { notReachable } from '@src/utils/notReachable'

type Props = {
  selectedPeriod?: PerformanceSelector
}

const getProbationPipOffset = (status: ReviewCycleStatus) => {
  switch (status) {
    case ReviewCycleStatus.ongoing:
      return 0
    case ReviewCycleStatus.planned:
      return -1
    case ReviewCycleStatus.closed:
      return 1
    default:
      return notReachable(status)
  }
}

export const PerformanceLayoutCycle = ({ selectedPeriod }: Props) => {
  const { isNewProbation, isOldProbation, isPIPv2, isPIP } =
    useGetPeriodTypes(selectedPeriod)
  const isProbationOrPip = isNewProbation || isOldProbation || isPIPv2 || isPIP

  const { title: tagTitle } = getCycleOffsetTitleAndColor(
    isProbationOrPip && selectedPeriod?.status
      ? getProbationPipOffset(selectedPeriod.status)
      : selectedPeriod?.offset,
  )

  return (
    <Box>
      <Flex alignItems="center" gap="s-8">
        <Text variant="heading3">{selectedPeriod?.name}</Text>
        {!!tagTitle.length && (
          <Tag variant="faded" bg={Token.color.greyTone10} color={Token.color.greyTone50}>
            {capitalize(tagTitle)}
          </Tag>
        )}
      </Flex>

      <Text color={Token.color.greyTone50} use="div">
        Performance cycle
      </Text>
    </Box>
  )
}
