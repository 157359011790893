import { lape } from 'lape'

export interface BugReportSideState {
  open: boolean
}

const defaultState: BugReportSideState = {
  open: false,
}

export default lape(defaultState)
