import React, { useEffect, useMemo } from 'react'
import { Box } from '@revolut/ui-kit'
import initializeTagBlot, {
  TagBlotOptions,
} from '@components/Chat/ChatTextEditor/TagBlot'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import useTagTooltip from '@components/Chat/ChatTextEditor/useTagTooltip'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  children: React.ReactNode
  noTooltip?: boolean
}

export type TagsManagerContextValue = {
  employeeOptions: RadioSelectOption<EmployeeOptionInterface>[]
}

export const TagsManagerContext = React.createContext<TagsManagerContextValue>({
  employeeOptions: [],
})

const ChatTagsManager = ({ children, noTooltip }: Props) => {
  const { tagTooltip, getOnTagMouseOver, onTagMouseLeave, onWheel } = useTagTooltip()

  const { options: employeeOptions } = useFetchOptions<EmployeeOptionInterface>(
    selectorKeys.employee,
  )

  useEffect(() => {
    const onCreate: TagBlotOptions['onCreate'] = node => {
      // this should never happen
      if (!node) {
        return
      }
      const onMouseOver = getOnTagMouseOver(node)

      node.addEventListener('mouseover', onMouseOver)
      node.addEventListener('mouseleave', onTagMouseLeave)
    }

    initializeTagBlot({ onCreate, onDelete: onTagMouseLeave })
  }, [])

  const contextValue: TagsManagerContextValue = useMemo(
    () => ({
      employeeOptions,
    }),
    [employeeOptions],
  )

  return (
    <TagsManagerContext.Provider value={contextValue}>
      <Box onWheel={onWheel}>{children}</Box>
      {!noTooltip && tagTooltip}
    </TagsManagerContext.Provider>
  )
}

export default ChatTagsManager
