import React, { useMemo } from 'react'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'

type JobPostingSelectorProps = {
  disabled: boolean
  onChange: () => void
}

const JobPostingSelector = ({ disabled, onChange }: JobPostingSelectorProps) => {
  const { data: postingsOptions, isLoading: loading } =
    useGetSelectors<JobPostingLocationTypeInterface>(
      selectorKeys.job_postings_locations_type,
    )
  const options = useMemo(() => {
    return (
      postingsOptions?.map(item => ({
        label: item.name,
        value: item,
      })) || []
    )
  }, [postingsOptions])
  return (
    <LapeRadioSelectInput
      label="Job posting"
      name="job_posting"
      required
      options={options}
      onAfterChange={() => {
        onChange()
      }}
      loading={loading}
      disabled={disabled}
    >
      {renderOption}
    </LapeRadioSelectInput>
  )
}

export default JobPostingSelector
