import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { CompensationSettings } from '@src/interfaces/settings'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const Bands = () => {
  const { values } = useLapeContext<CompensationSettings>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeTotalCompensationPreferences,
  )

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="Approval"
            subtitle="Settings that apply to the approval of bands"
          />
          <ApprovalSteps
            approvalProcess="benchmarks_approvals"
            description="This means when a new band is added, it will need to be approved before becoming active"
            disabled={disableEdit}
            entity="bands"
            formRef={values}
            title="All new compensation bands need to be approved"
            switchField="benchmarks_approval_enabled"
          />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.COMMUNICATION_SETTINGS)
          }}
          successText="Settings saved successfully"
          useValidator
        />
      </PageActions>
    </>
  )
}
