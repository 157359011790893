import React from 'react'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { ColorTag, ColorTagVariant } from '@components/ColorTag/ColorTag'
import { Capitalize } from '@components/CommonSC/General'

interface Props {
  status: ReviewCycleStatus
}

export const StatusTag = ({ status }: Props) => {
  let tagVariant: ColorTagVariant

  switch (status) {
    case ReviewCycleStatus.closed:
      tagVariant = 'neutral'
      break
    case ReviewCycleStatus.ongoing:
      tagVariant = 'success'
      break
    case ReviewCycleStatus.planned:
      tagVariant = 'warning'
      break
    default:
      tagVariant = 'default'
  }

  return (
    <ColorTag variant={tagVariant}>
      <Capitalize data-testid="status">{status}</Capitalize>
    </ColorTag>
  )
}
