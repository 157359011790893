import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  AccessManagementInterface,
  PermissionManagementInterface,
} from '@src/interfaces/accessManagement'
import { selectorKeys } from '@src/constants/api'
import { Status } from '@components/CommonSC/General'
import React from 'react'

export const nameColumn: ColumnInterface<AccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'permission.id',
  dataPoint: 'permission.name',
  sortKey: null,
  filterKey: 'permission__id',
  selectorsKey: selectorKeys.permissions,
  title: 'Name',
}

export const statusColumn: ColumnInterface<PermissionManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.performance_whitelist_status_choices,
  insert: ({ data }) => <Status status={data.status}>{data.status}</Status>,
  title: 'Status',
}
