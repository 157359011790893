import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'lape'
import mean from 'lodash/mean'
import styled, { keyframes } from 'styled-components'
import {
  ActionButton,
  Box,
  Button,
  HStack,
  Icon,
  Popup,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { engagementQuestionBoxFormRequests } from '@src/api/engagement'
import { GLASS_DOOR_URL } from '@src/constants/externalLinks'
import Form from '@src/features/Form/Form'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { useLapeContext } from '@src/features/Form/LapeForm'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useKeyUp } from '@src/hooks/useKeyUp'
import {
  EngagementAnswerInterface,
  EngagementQuestionInterface,
  EngagementQuestionVoicesInterface,
} from '@src/interfaces/engagement'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import {
  NpsOptionsButtons,
  ratingNumbersOptions,
  RatingOptionsButtons,
  TextOptionsButtons,
} from './AnswerButtons'
import { NumberOption } from './types'

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Comment = styled(Box)`
  animation: ${appear} 200ms linear;
`

const getCanSubmit = (
  question: EngagementQuestionInterface,
  formValues: EngagementAnswerInterface,
  selectedRatingOption: NumberOption | undefined,
  selectedTextOption: string | undefined,
) => {
  const isCommentRequired = question.comment.id === 'mandatory'
  const isCommentValidated = isCommentRequired ? !!formValues.answer_text : true

  if (!isCommentValidated) {
    return false
  }
  if (question.type?.id === 'open_ended') {
    const textOptionsAvailable = !!question.categories?.names?.length
    return textOptionsAvailable ? !!selectedTextOption : true
  }
  return !!selectedRatingOption
}

interface Props {
  questions: EngagementQuestionVoicesInterface[]
  onComplete: VoidFunction
  onClose: VoidFunction
  isPreview?: boolean
  questionIndex: number
  setQuestionIndex: React.Dispatch<React.SetStateAction<number>>
}
const QuestionBoxContent = ({
  questions,
  onComplete,
  onClose,
  isPreview,
  questionIndex,
  setQuestionIndex,
}: Props) => {
  const { initialValues, values, reset } = useLapeContext<EngagementAnswerInterface>()
  const formValidator = useFormValidator()
  const isCommercial = useIsCommercial()

  const [pending, setPending] = useState(false)
  const [glassdoorPopupOpen, setGlassDoorPopupOpen] = useState(false)
  const [selectedRatingOption, setSelectedRatingOption] = useState<NumberOption>()
  const [selectedTextOption, setSelectedTextOption] = useState<string>()

  const scores = useRef<number[]>([])
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  useEffect(() => {
    if (question.comment.id === 'mandatory' && question.type?.id === 'open_ended') {
      textAreaRef.current && textAreaRef.current.focus()
    }
  }, [questionIndex])

  const { question, id } = questions[questionIndex]
  const isLastQuestion = questionIndex + 1 === questions.length

  const canSubmit = getCanSubmit(
    question,
    values,
    selectedRatingOption,
    selectedTextOption,
  )

  const { query } = useQuery<{ is_test: string }>()
  const showStatusPopup = useShowStatusPopup()
  const isTestSurveyPayloadFlag = query.is_test === 'true' ? { is_test: true } : null

  const submitAnswer = (onSuccess?: () => void) => {
    if (isPreview || !canSubmit) {
      return
    }
    values.answer_score = null // answer_score is required, should be null if no score

    if (selectedRatingOption?.id) {
      scores.current = [...scores.current, selectedRatingOption.id]
      values.answer_score = selectedRatingOption.id
    }
    values.question = { id: question.id }
    values.question_queue_item_id = id

    if (selectedTextOption) {
      values.categories = {
        names: [selectedTextOption],
      }
    }
    formValidator!.validate(async () => {
      try {
        setPending(true)
        await engagementQuestionBoxFormRequests.submit(
          { ...values, ...isTestSurveyPayloadFlag },
          {},
        )
        onSuccess?.()
      } catch (e) {
        let errorDescription = getStringMessageFromError(e)

        if (e.response?.status === 400) {
          if (e.response?.data?.answer_score) {
            errorDescription = e.response.data.answer_score
          } else if (e.response?.data?.answer_text) {
            errorDescription = e.response.data.answer_text
          }
        }
        showStatusPopup({
          status: 'error',
          title: 'Could not submit answer',
          description: errorDescription,
        })
      } finally {
        setPending(false)
      }
    })()
  }

  const submitAnswerAndShowNextQuestion = () => {
    submitAnswer(() => {
      setSelectedRatingOption(undefined)
      setSelectedTextOption(undefined)
      setQuestionIndex(prev => prev + 1)
      reset(initialValues as EngagementAnswerInterface)
    })
  }

  const submitAnswerForLastQuestion = () => {
    if (isPreview) {
      return
    }
    submitAnswer(() => {
      const averageScore = mean(scores.current)

      if (averageScore > 4 && !isCommercial) {
        setGlassDoorPopupOpen(true)
      } else {
        onComplete()
      }
    })
  }

  useKeyUp(event => {
    if (event.target === textAreaRef.current) {
      return
    }
    if (Number(event.key) >= 1 && Number(event.key) <= 6) {
      setSelectedRatingOption(ratingNumbersOptions[Number(event.key) - 1])
      question.comment.id === 'mandatory' &&
        textAreaRef.current &&
        textAreaRef.current.focus()
    } else if (event.key === 'Enter' && canSubmit) {
      submitAnswerAndShowNextQuestion()
    }
  })

  return (
    <>
      <VStack space="s-32" mb="s-32">
        <Widget p="s-16" width={Token.breakpoint.md}>
          <VStack space="s-40">
            <VStack space="s-8">
              <Text variant="h1">{question.question_text}</Text>
              {!!question.description && (
                <HTMLEditor
                  readOnly
                  nonResizable
                  noPadding
                  color={Token.color.greyTone50}
                  transparentBg
                  height="auto"
                  onChange={() => {}}
                  value={question.description}
                />
              )}
            </VStack>
            <VStack space="s-16">
              {question.type?.id === 'open_ended' ? (
                <TextOptionsButtons
                  selectedOption={selectedTextOption}
                  textOptions={question.categories?.names || []}
                  onChange={setSelectedTextOption}
                />
              ) : question.type?.id === 'nps' ? (
                <NpsOptionsButtons
                  selectedOption={selectedRatingOption}
                  onChange={setSelectedRatingOption}
                />
              ) : question.type?.id === 'rating' ? (
                <RatingOptionsButtons
                  selectedOption={selectedRatingOption}
                  onChange={setSelectedRatingOption}
                />
              ) : null}
              {(question.comment?.id !== 'no_comment' ||
                (question.type?.id === 'open_ended' &&
                  !question.categories?.names?.length)) && (
                <Comment>
                  <LapeNewTextArea
                    required
                    elementRef={textAreaRef}
                    name="answer_text"
                    aria-label="Answer comment input"
                    rows={3}
                    maxRows={3}
                    placeholder={
                      question.comment.id === 'mandatory'
                        ? 'Written feedback for this question is mandatory'
                        : 'Written feedback for this question is optional'
                    }
                  />
                </Comment>
              )}
            </VStack>
          </VStack>
        </Widget>
        <Box>
          <HStack space="s-8">
            {isLastQuestion ? (
              <ActionButton
                variant="accent"
                onClick={submitAnswerForLastQuestion}
                disabled={!canSubmit}
                pending={pending}
              >
                Submit
              </ActionButton>
            ) : (
              <ActionButton
                variant="accent"
                onClick={submitAnswerAndShowNextQuestion}
                disabled={!canSubmit}
                useIcon="ArrowThinRight"
                pending={pending}
              >
                Next question
              </ActionButton>
            )}
          </HStack>
        </Box>
      </VStack>
      <Popup
        open={glassdoorPopupOpen}
        variant="bottom-sheet"
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <VStack align="center" space="s-24" my="s-8">
          <Icon name="104/Success" color={Token.color.blue} size={104} />
          <Text use="p" variant="h4">
            Thanks for your feedback!
          </Text>
          <Text
            use="p"
            variant="caption"
            color={Token.color.greyTone50}
            textAlign="center"
          >
            Rate us on Glassdoor and share your experience with future Revoluters
          </Text>
        </VStack>
        <Popup.Actions mt="s-24">
          <Button
            elevated
            onClick={() => {
              window.open(GLASS_DOOR_URL, '_blank', 'noopener noreferrer')
              onClose()
            }}
          >
            Go to Glassdoor
          </Button>
          <Button
            variant="secondary"
            onClick={e => {
              e.stopPropagation()
              setGlassDoorPopupOpen(false)
              onComplete()
            }}
          >
            Maybe later
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export const QuestionBox = connect((props: Props) => (
  <Form disableLocalStorageCaching>
    <QuestionBoxContent {...props} />
  </Form>
))
