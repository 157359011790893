import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { candidatesConfig } from '@src/pages/OnboardingChecklist/common/constants'
import CandidatesIntro from '@src/pages/OnboardingChecklist/Candidates/CandidatesIntro'
import CandidatesFreezingPeriod from '@src/pages/OnboardingChecklist/Candidates/CandidatesFreezingPeriod'
import { candidateSettings } from '@src/api/settings'
import CandidatesCommunication from '@src/pages/OnboardingChecklist/Candidates/CandidatesCommunication'
import { CandidatesBulkUpload } from '@src/pages/OnboardingChecklist/Candidates/CandidatesBulkUpload'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.INTRO,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesIntro,
  },
  {
    title: 'Candidates freezing period',
    path: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.FREEZING_PERIOD,
    url: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.FREEZING_PERIOD,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesFreezingPeriod,
    form: {
      api: candidateSettings,
    },
  },
  {
    title: 'Candidates communication',
    path: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.COMMUNICATION,
    url: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.COMMUNICATION,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesCommunication,
    form: {
      api: candidateSettings,
    },
  },
  {
    title: 'Upload candidates',
    path: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.CANDIDATES.UPLOAD.IMPORT,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesBulkUpload,
    hideActions: true,
    hidePageBody: true,
  },
]

const Candidates = () => {
  return <OnboardingChecklistContent config={candidatesConfig} routes={routes} />
}

export default Candidates
