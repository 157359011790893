import React from 'react'
import {
  Action,
  Box,
  Group,
  Link,
  Subheader,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  CultureValueInterface,
  CultureValueTypeTitle,
} from '@src/interfaces/cultureValues'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { PageBody } from '@components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'

const Preview = () => {
  const { values } = useLapeContext<CultureValueInterface>()

  return (
    <PageBody>
      <FormPreview<CultureValueInterface> data={values}>
        <Group>
          <FormPreview.Details field="description" title="Value description" />
          <FormPreview.Item<CultureValueInterface>
            field="playbook_url"
            insert={data =>
              data.playbook_link ? (
                <Link href={data.playbook_link} rel="noreferrer noopener" target="_blank">
                  <LinkExternal />
                </Link>
              ) : (
                '-'
              )
            }
            title="Playbook link"
          />
          <FormPreview.Item<CultureValueInterface>
            insert={data => CultureValueTypeTitle[data.category]}
            title="Category"
          />
        </Group>
      </FormPreview>
      {!!values?.behaviours?.length && (
        <Box pt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Behaviours</Subheader.Title>
            <Subheader.Side>
              <Action
                onClick={() =>
                  navigateTo(pathToUrl(ROUTES.FORMS.VALUE.GENERAL, { id: values.id }))
                }
              >
                Edit
              </Action>
            </Subheader.Side>
          </Subheader>
          <Widget p="s-16">
            <VStack space="s-16">
              {values.behaviours.map((behaviour, id) => {
                return (
                  <Box key={id}>
                    <Text variant="h6" use="h6">
                      {behaviour.name}
                    </Text>
                    <Text variant="caption" whiteSpace="pre-wrap">
                      {behaviour.description}
                    </Text>
                  </Box>
                )
              })}
            </VStack>
          </Widget>
        </Box>
      )}
    </PageBody>
  )
}

export default Preview
