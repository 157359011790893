import React, { useState } from 'react'
import { ActionButton, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { EmployeeRulesList } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesList'
import { EmployeeRulesSidebarForm } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesSidebarForm'
import { isEmpty } from 'lodash'
import { probationConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { ROUTES } from '@src/constants/routes'
import OnboardingActions from '@src/pages/OnboardingChecklist/components/OnboardingActions'
import PageLoading from '@components/PageLoading/PageLoading'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'

export const EligibleEmployees = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  const [rulesSidebarOpen, setRulesSidebarOpen] = useState(false)
  const hasRulesSet = !isEmpty(values.rules)

  const checkpoint = useGetOnboardingCheckpointCategory('probation')
  const stepCompleted =
    checkpoint.data?.state.id === 'completed' ||
    checkpoint.data?.current_step === 'Preview'

  if (!values.id) {
    return <PageLoading />
  }

  return (
    <>
      <VStack space="s-8">
        <SectionTitle
          title="To which new joiners should this probation cycle apply?"
          subtitle="Set rules to define which new joining employees this cycle will apply for."
        />
        <ActionButton
          useIcon={hasRulesSet ? 'Pencil' : 'Plus'}
          onClick={() => setRulesSidebarOpen(!rulesSidebarOpen)}
        >
          {hasRulesSet ? 'Edit rules' : 'Set rules'}
        </ActionButton>
        <EmployeeRulesList rules={values.rules} />
        <EmployeeRulesSidebarForm
          isOpen={rulesSidebarOpen}
          handleClose={() => setRulesSidebarOpen(false)}
        />
      </VStack>
      <OnboardingActions
        currentStep="Eligible employees"
        config={probationConfig}
        isLastStep={false}
        disableBack={false}
        pendingBack={false}
        disableNext={!values.id}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST.PROBATION.PREVIEW}
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.PROBATION.COMMITTEE}
        updateSteps={!stepCompleted}
        isForm
      />
    </>
  )
}
