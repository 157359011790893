import React from 'react'
import styled from 'styled-components'

const HandlerWrapper = styled.div`
  height: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: row-resize;
`

const Handler = styled.div`
  width: 100px;
  height: 5px;
  background: rgb(233, 233, 233);
  border-radius: 4px;
  cursor: row-resize;
`
interface Props {
  onMouseDown: React.MouseEventHandler
}

export const ResizeHandler = ({ onMouseDown }: Props) => {
  return (
    <HandlerWrapper onMouseDown={onMouseDown}>
      <Handler />
    </HandlerWrapper>
  )
}
