import { IconProps } from '@revolut/icons'
import { Icon } from '@revolut/ui-kit'
import React from 'react'

export const AiIcon = ({ size = 24 }: Pick<IconProps, 'size'>) => {
  return (
    <Icon
      name="Cleaning"
      size={size}
      style={{ background: 'linear-gradient(#13D1A3, #2BB2FF)' }}
    />
  )
}
