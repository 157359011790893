import React from 'react'
import { ActionButton, Caption, H1, Paragraph, Token, VStack } from '@revolut/ui-kit'

type ExpiredLinkProps = {
  recruiterEmail?: string
}

const ExpiredLink = ({ recruiterEmail }: ExpiredLinkProps) => {
  return (
    <VStack maxWidth={600} gap="s-16" px="s-16" pt="s-32" color={Token.color.foreground}>
      <H1>This link has expired </H1>
      <Paragraph>
        Please contact the recruiter to receive a new link and book a call
      </Paragraph>
      {recruiterEmail && (
        <>
          <Caption>{recruiterEmail}</Caption>
          <ActionButton use="a" href={`mailto:${recruiterEmail}`}>
            Contact recruiter
          </ActionButton>
        </>
      )}
    </VStack>
  )
}

export default ExpiredLink
