import React from 'react'
import { Box, Token, Link, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCategory } from '@src/interfaces/performance'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface GenericOverviewProps {
  category: ReviewCategory
  cycleId: string
  data: EmployeeInterface
  children: React.ReactNode
}

const PageTitle: { [key: string]: string } = {
  [ReviewCategory.Probation]: 'Probation Goals of',
  [ReviewCategory.PIP_V2]: 'PIP Goals of',
}

export const GenericGoals = ({
  category,
  cycleId,
  data,
  children,
}: GenericOverviewProps) => {
  const cycle = data.performance_cycles?.find(el => el.id === cycleId)

  return (
    <PageWrapper>
      <PageHeader
        title={`${PageTitle[category]} ${data.display_name}`}
        subtitle={
          <>
            <Box>
              <Link
                color={Token.color.foreground}
                to={pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {
                  id: data.position.id,
                })}
                use={InternalLink}
                target="_blank"
              >
                {data.position.name}
              </Link>
              <Text color={Token.color.greyTone50}> at </Text>
              <Link
                color={Token.color.foreground}
                to={pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, {
                  // @ts-ignore FIXME: REVPI-19 support optional team value
                  id: data.team.id,
                })}
                use={InternalLink}
                target="_blank"
              >
                {/* @ts-ignore FIXME: REVPI-19 support optional team value */}
                {data.team.name}
              </Link>
            </Box>
            {cycle && (
              <Box>
                <Text color={Token.color.greyTone50}>
                  From {formatDate(cycle?.start_date_time)} -{' '}
                  {formatDate(cycle?.end_date_time)}
                </Text>
              </Box>
            )}
          </>
        }
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: data.id,
        })}
      />
      {children}
    </PageWrapper>
  )
}
