import * as React from 'react'
import Info from './Info'
import { ReactNode } from 'react'
import { Box, Color, Flex, Link } from '@revolut/ui-kit'
import { Text } from '@revolut/ui-kit'

export interface NewLabelProps {
  /** Marks field as optional otherwise */
  required?: boolean
  /** hides the optional text for display only fields */
  hideOptional?: boolean
  /** Top tooltip hovarable text */
  tooltipTitle?: string
  /** Content text of the tooltip */
  tooltipText?: string
  /** Tooltip body if you want to use components instead of text */
  tooltipBody?: ReactNode
  /** Top link url */
  link?: string
  /** Top link text value */
  linkText?: string
  /** Label of Input */
  label?: ReactNode
}

const NewLabel = ({
  required,
  hideOptional,
  tooltipText,
  tooltipTitle,
  tooltipBody,
  link,
  linkText,
  label,
}: NewLabelProps) => (
  <Flex alignItems="center" justifyContent="space-between" mb="s-8">
    <Flex alignItems="center">
      {label && (
        <Box>
          <Text color={Color.GREY_TONE_50} variant="h6">
            {label}
            {!required && !hideOptional && ' (optional)'}
          </Text>
        </Box>
      )}
      <Info
        tooltipBody={tooltipBody}
        tooltipText={tooltipText}
        tooltipTitle={tooltipTitle}
      />
    </Flex>
    {link && linkText && (
      <Link href={link} target="_blank">
        {linkText}
      </Link>
    )}
  </Flex>
)

export default NewLabel
