import { useGetInvalidEligibleEmployees } from '@src/api/supportTool/eligibleEmployees'
import { InvalidEligibilityItemProps } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { useAdaptInvalidEligibilityObjects } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/ValidateScorecards/hooks/useAdaptInvalidEligibilityObjects'

interface InvalidEligibilityObjects {
  itemProps: InvalidEligibilityItemProps[]
  isLoading: boolean
  isError: boolean
  handleFetchMore: VoidFunction
}

export const useInvalidEligibilityObjects = (
  cycleId: number | string,
): InvalidEligibilityObjects => {
  const { data, isLoading, isError, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useGetInvalidEligibleEmployees(cycleId)
  const itemProps = useAdaptInvalidEligibilityObjects(data?.pages ?? [])

  const handleFetchMore = () => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }

  return {
    itemProps,
    isLoading,
    isError,
    handleFetchMore,
  }
}
