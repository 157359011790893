import React from 'react'
import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

interface Props {
  value: number
}

const QualityScore = ({ value }: Props) => {
  if (value === null) {
    return <ColoredText color={Token.color.greyTone20}>N/A</ColoredText>
  }
  if (value < 50) {
    return <ColoredText color={Token.color.red}>Low</ColoredText>
  }
  if (value < 70) {
    return <ColoredText color={Token.color.warning}>Medium</ColoredText>
  }
  if (value >= 70) {
    return <ColoredText color={Token.color.green}>High</ColoredText>
  }
  return <ColoredText color={Token.color.greyTone20}>N/A</ColoredText>
}

export default QualityScore
