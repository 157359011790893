import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { EmployeeButtonProps } from '@src/pages/EmployeeProfile/Preview/components/Buttons/types'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewManageDataAccess } from '../common'

export const ManageDataAccess = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = useCanViewManageDataAccess({ data })

  if (!canView) {
    return null
  }

  const actionProps = {
    use: InternalLink,
    useIcon: <Icon name="16/LockOutline" size={16} />,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE.ACCESS_REQUESTS, { id: String(data.id) }),
    children: 'Manage data access',
  }

  return isDropdownItem ? (
    <Dropdown.Item {...actionProps} />
  ) : (
    <MoreBar.Action {...actionProps} />
  )
}
