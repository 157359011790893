import { MoreBar } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'
import { MoreBarActionProps } from '@revolut/ui-kit/types/dist/components/MoreBar/MoreBar'
import { useIsNewTable } from '@components/TableV2/hooks'

export const PrimaryAction = ({
  children,
  ...props
}: PropsWithChildren<MoreBarActionProps>) => {
  const isNewTable = useIsNewTable()

  return (
    <MoreBar.Action {...props} variant={isNewTable ? 'accent' : props.variant}>
      {children}
    </MoreBar.Action>
  )
}
