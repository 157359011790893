import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import ProbationGoals from '@src/pages/Forms/ProbationOverview/ProbationGoals/ProbationGoals'
import ProbationKPI from '@src/pages/Forms/ProbationOverview/ProbationGoals/ProbationKPI'
import Recommendations from '@src/pages/Forms/ProbationOverview/Recommendations/Recommendations'
import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequestsNew } from '@src/api/employees'
import Loader from '@components/CommonSC/Loader'
import { useGetPerformanceSelector } from '@src/api/performance'
import { ReviewCategory } from '@src/interfaces/performance'
import { CheckpointType } from '@src/interfaces/probationReview'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import { PageWrapper } from '@components/Page/Page'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import { isBefore } from 'date-fns'
import { Statuses } from '@src/interfaces'
import { GenericOverview } from '@src/pages/Forms/Overview'
import { Checkpoint } from '@src/pages/Forms/Overview/Checkpoint/Checkpoint'
import { Committee } from '@src/pages/Forms/Overview/Committee/Committee'
import ProbationSettings from '@src/pages/Forms/Overview/Settings/ProbationSettings'
import { ProbationGoalsTable } from './ProbationGoals/ProbationGoalsTable/ProbationGoalsTable'

const ProbationOverview = () => {
  const params = useParams<{ employeeId: string; cycleId: string }>()
  const { employeeId, cycleId } = params
  const employeeIdValid = employeeId && !isNaN(parseFloat(employeeId))
  const [data, setData] = useState<EmployeeInterface>()
  const [loading, setLoading] = useState(employeeIdValid)
  const { data: checkpoints, refetch } = useGetProbationCheckpoints(
    employeeIdValid ? Number(employeeId) : null,
    cycleId,
  )

  const performanceSelector = useGetPerformanceSelector(
    employeeIdValid ? Number(employeeId) : undefined,
  )

  const deliveryType = performanceSelector.data?.find(
    item => item.id === cycleId,
  )?.probation_reviews_deliverables_type

  useEffect(() => {
    if (employeeId && !isNaN(parseFloat(employeeId))) {
      employeesRequestsNew.get({ id: employeeId }).then(res => {
        setData(res.data)
        setLoading(false)
      })
    }
  }, [])

  if (loading) {
    return (
      <PageWrapper>
        <Loader />
      </PageWrapper>
    )
  }

  const isCpCompleted = (date?: string) => {
    if (!date) {
      return false
    }

    return isBefore(new Date(date), new Date())
  }

  const recommendationCp = checkpoints?.checkpoints.find(
    item => item.checkpoint_type === CheckpointType.Recommendation,
  )
  const committeeCp = checkpoints?.decision_checkpoints?.find(
    item => item.checkpoint_type === CheckpointType.Decision,
  )

  const recommendationDate = recommendationCp?.checkpoint_date_time
  const recommendationCompleted = isCpCompleted(recommendationDate)

  const committeeDate = committeeCp?.checkpoint_date_time
  const committeeCompleted = isCpCompleted(committeeDate)

  const goalsCheckpoint = checkpoints?.checkpoints.find(
    item => item.checkpoint_type === CheckpointType.Goals,
  )

  const reviewCheckpoints =
    checkpoints?.checkpoints.filter(
      item => item.checkpoint_type === CheckpointType.Review,
    ) || []

  const checkpointTabs = reviewCheckpoints?.map(checkpoint => {
    return {
      title: `Checkpoint ${checkpoint.checkpoint_number}`,
      preTitle: (
        <ValidationPre
          isValid={isCpCompleted(checkpoint?.checkpoint_date_time)}
          isVisible
        />
      ),
      path: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT, {
        ...params,
        id: checkpoint.checkpoint_number,
      }),
      url: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT, {
        ...params,
        id: checkpoint.checkpoint_number,
      }),
      component: Checkpoint,
      checkpointNumber: checkpoint.checkpoint_number,
      disabled: false,
    }
  })

  const tabs = [
    {
      title: 'Probation goals',
      path: ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS,
      url: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS, params),
      preTitle: (
        <ValidationPre
          isVisible
          isValid
          isPending={goalsCheckpoint?.goals?.status !== Statuses.approved}
        />
      ),
      component:
        deliveryType === 'goal'
          ? ProbationGoalsTable
          : deliveryType === 'kpi'
          ? ProbationKPI
          : ProbationGoals,
    },
    ...checkpointTabs,
    {
      title: 'Recommendations',
      path: ROUTES.FORMS.PROBATION_OVERVIEW.RECOMMENDATIONS,
      url: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.RECOMMENDATIONS, params),
      preTitle: <ValidationPre isVisible isValid={recommendationCompleted} />,
      disabled: !recommendationCp,
      component: Recommendations,
    },
    {
      title: 'Committee',
      path: ROUTES.FORMS.PROBATION_OVERVIEW.COMMITTEE,
      url: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.COMMITTEE, params),
      preTitle: <ValidationPre isVisible isValid={committeeCompleted} />,
      disabled: !committeeCp,
      component: Committee,
    },
    {
      title: 'Settings',
      path: ROUTES.FORMS.PROBATION_OVERVIEW.CYCLE_SETTINGS,
      url: pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.CYCLE_SETTINGS, params),
      preTitle: <ValidationPre isVisible isValid />,
      component: ProbationSettings,
    },
  ].filter(tab => !tab.disabled)

  if (!data) {
    return null
  }

  return (
    <GenericOverview
      category={ReviewCategory.Probation}
      checkpoints={checkpoints}
      cycleId={cycleId}
      data={data}
      refetch={refetch}
      tabs={tabs}
      performanceCycles={performanceSelector.data}
    />
  )
}

export default ProbationOverview
