import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { VStack, InputGroup, Box } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { CompanyDesign } from '@src/pages/Settings/General/CompanyDesign'

const InitialCompanyPreferences = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCompanySettings)

  return (
    <VStack gap="s-16">
      <SectionTitle title="Company logo" />
      <CompanyDesign />

      <SectionTitle title="Company details" />
      <InputGroup>
        <LapeRadioSelectInput
          name="point_of_contact"
          label="Owner"
          selector={selectorKeys.employee}
        />
        <LapeNewInput name="company_name" label="Company name" required />
        <LapeRadioSelectInput
          name="country"
          label="Country of incorporation"
          selector={selectorKeys.countries}
          disabled={disableEdit}
        />
        <LapeNewInput
          name="post_code"
          label="Postal code"
          required
          disabled={disableEdit}
        />
        <InputGroup variant="horizontal" disabled={disableEdit}>
          <Box flex={1}>
            <LapeNewInput name="address_line_1" label="Address line 1" required />
          </Box>
          <Box flex={1}>
            <LapeNewInput name="address_line_2" label="Address line 2" />
          </Box>
        </InputGroup>
        <LapeNewInput name="city" label="City" required />
        <LapeNewInput name="vat_number" label="VAT number" required />
      </InputGroup>
    </VStack>
  )
}

export default InitialCompanyPreferences
