import { Avatar, HStack, Text, Box, Token, Icon } from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import startCase from 'lodash/startCase'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import { formatTimeAgo } from '@src/utils/format'
import { getStatusColor } from '@components/CommonSC/General'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import Tooltip from '@components/Tooltip/Tooltip'
import { Statuses } from '@src/interfaces'
import Table from '@src/components/TableV2/Table'

export const promotionEmployeeColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee',
  dataPoint: 'employee',
  sortKey: null,
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
}

export const promotionSeniorityColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.text,
  idPoint: 'initial_seniority__id',
  dataPoint: 'initial_seniority__name',
  filterKey: 'employee__seniority__id',
  sortKey: 'employee__seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const promotionSpecialisationColumn: ColumnInterface<PromotionNominationInterface> =
  {
    type: CellTypes.text,
    idPoint: 'initial_specialisation__id',
    dataPoint: 'initial_specialisation__name',
    sortKey: 'employee__specialisation__id',
    filterKey: 'employee__specialisation__id',
    selectorsKey: selectorKeys.approved_specialisations,
    title: 'Role (Specialisation)',
  }

export const promotionTargetSeniorityColumn: ColumnInterface<PromotionNominationInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'target_seniority__id',
    dataPoint: 'target_seniority__name',
    filterKey: 'target_seniority__id',
    sortKey: 'target_seniority__name',
    selectorsKey: selectorKeys.seniority,
    title: 'Target Seniority',
    insert: ({ data: { initial_seniority, target_seniority } }) => {
      if (!target_seniority) {
        return null
      }
      return (
        <HStack space="s-2" align="center">
          <Box>
            <Text>{initial_seniority.name}</Text>
          </Box>
          <Box>
            <Icon name="ArrowThinRight" size={16} color={Token.color.greyTone50} />
          </Box>
          <Box>
            <Text>{target_seniority?.name}</Text>
          </Box>
        </HStack>
      )
    },
  }

export const promotionTargetSpecialisationColumn: ColumnInterface<PromotionNominationInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'target_specialisation__id',
    dataPoint: 'target_specialisation__name',
    sortKey: 'target_specialisation__name',
    filterKey: 'target_specialisation__id',
    selectorsKey: selectorKeys.approved_specialisations,
    title: 'Target Role (Specialisation)',
    colors: data => {
      if (!data.target_specialisation) {
        return Token.color.greyTone20
      }
      return 'inherit'
    },
    insert: ({ data }) => {
      if (!data.target_specialisation) {
        return 'No change'
      }
      return (
        <HStack space="s-2" align="center">
          <Box>{data.initial_specialisation.name}</Box>
          <Box>
            <Icon name="ArrowThinRight" size={16} color={Token.color.greyTone50} />
          </Box>
          <Box>{data.target_specialisation?.name}</Box>
        </HStack>
      )
    },
  }

export const promotionLMColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee__line_manager__id',
  dataPoint: 'employee__line_manager__name',
  sortKey: 'employee__line_manager__id',
  filterKey: 'employee__line_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'LM',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee.line_manager} />,
}

export const promotionNominatedByColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'nominated_by__id',
  dataPoint: 'nominated_by__name',
  sortKey: 'nominated_by__id',
  filterKey: 'nominated_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Nominated by',
  insert: ({ data }) => <Table.EmployeeCell employee={data.nominated_by} />,
}

export const promotionStageColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_stages',
  dataPoint: 'current_stages',
  filterKey: 'current_stages',
  sortKey: null,
  selectorsKey: selectorKeys.promotion_stage_types,
  title: 'Current stage',
  insert: ({ data: { current_stages } }) => {
    if (!current_stages.length) {
      return ''
    }
    return current_stages
      .filter(stage => (stage.outcome ? stage.outcome === Statuses.pending : true))
      .map(stage => stage.name || startCase(stage.id))
      .join(', ')
  },
}

export const promotionStatusColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.promotion_status,
  colors: data => getStatusColor(data.status),
  title: 'Status',
  insert: ({ children }) => capitalize(children.replaceAll('_', ' ')),
}

export const promotionUpdateTimeColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  idPoint: 'update_date_time',
  dataPoint: 'update_date_time',
  sortKey: 'update_date_time',
  filterKey: 'update_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Update date',
  insert: ({ data }) => {
    if (data.update_date_time && data.last_updated_by) {
      return (
        <Tooltip text={`Updated by ${data.last_updated_by?.full_name}`} placement="left">
          {formatTimeAgo(data.update_date_time)}
        </Tooltip>
      )
    }

    return data.update_date_time ? formatTimeAgo(data.update_date_time) : null
  },
}

export const promotionProgressionTrackColumn: ColumnInterface<PromotionNominationInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'progression_track',
    dataPoint: 'progression_track',
    sortKey: 'progression_track',
    filterKey: 'progression_track',
    selectorsKey: selectorKeys.promotion_pregression_tracks,
    title: 'Track',
    insert: ({ data }) => {
      if (data.progression_track?.toLocaleLowerCase() === 'normal') {
        return 'Standard'
      }
      return capitalize(data.progression_track || '')
    },
  }

export const promotionRevieversColumn: ColumnInterface<PromotionNominationInterface> = {
  type: CellTypes.insert,
  title: 'Reviewers',
  idPoint: 'current_stages__reviewer__id',
  dataPoint: 'current_stages__reviewer__id',
  sortKey: null,
  filterKey: 'reviewers',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => {
    const reviewers: EmployeeOptionInterface[] = data.current_stages
      .map(stage => (stage.reviewer?.id && stage.reviewer) || null)
      .filter(Boolean)
    if (reviewers.length) {
      return (
        <HStack space="s-8">
          {reviewers.map(reviewer => (
            <Avatar size={24} image={getAvatarUrl(reviewer.avatar)} key={reviewer.id}>
              {!reviewer.avatar &&
                getInitials(reviewer.display_name || reviewer.name || '')}
            </Avatar>
          ))}
        </HStack>
      )
    }

    return reviewers[0] ? <Table.EmployeeCell employee={reviewers[0]} /> : null
  },
}
