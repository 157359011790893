import React from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, ActionButtonSkeleton } from '@revolut/ui-kit'
import {
  useGetIndeedIntegrationPreferences,
  useGetJobPostingSettings,
} from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const ConnectIndeed = () => {
  const permissions = useSelector(selectPermissions)
  const disabled = !permissions.includes(PermissionTypes.ChangeJobPostingPreferences)
  const { data: indeedIntegrationData, isLoading: loadingIndeedIntegrationData } =
    useGetIndeedIntegrationPreferences()
  const { data: jobPostingSettings, isLoading: loadingJobPostingSettings } =
    useGetJobPostingSettings()
  if (loadingIndeedIntegrationData || loadingJobPostingSettings) {
    return <ActionButtonSkeleton />
  }
  if (
    !indeedIntegrationData ||
    !jobPostingSettings ||
    jobPostingSettings.indeed_connected
  ) {
    return null
  }
  return (
    <ActionButton
      use="a"
      useIcon="Link"
      disabled={disabled}
      variant="accent"
      href={indeedIntegrationData.auth_url}
    >
      Connect
    </ActionButton>
  )
}
