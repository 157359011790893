import React from 'react'
import { Token } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const resignatingEmployeeNameColumn: ColumnInterface<EmployeeResignationInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee_id',
    selectorsKey: selectorKeys.employee_resignation_employee,
    insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
    title: 'Employee',
  }

export const roleNameColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.text,
  idPoint: 'employee.specialisation.id',
  dataPoint: 'employee.specialisation.name',
  sortKey: 'employee__specialisation__role',
  filterKey: 'employee__specialisation__role',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
}

export const seniorityNameColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.text,
  idPoint: 'employee.seniority.id',
  dataPoint: 'employee.seniority.name',
  sortKey: 'employee__seniority',
  filterKey: 'employee__seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const isKeyPersonColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.has_key_profile',
  dataPoint: 'employee.has_key_profile',
  sortKey: 'employee__has_key_profile',
  filterKey: 'employee__has_key_profile',
  selectorsKey: selectorKeys.yes_no_value_options,
  insert: ({ data }) => (data.has_key_profile ? 'Yes' : 'No'),
  colors: data => (data.has_key_profile ? Token.color.red : Token.color.foreground),
  title: 'Key person',
}

export const isRegrettableColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.insert,
  idPoint: 'is_regrettable',
  dataPoint: 'is_regrettable',
  sortKey: 'employee__is_regrettable',
  filterKey: 'employee__is_regrettable',
  selectorsKey: selectorKeys.yes_no_value_options,
  insert: ({ data }) => (data.is_regrettable ? 'Yes' : 'No'),
  colors: data => (data.is_regrettable ? Token.color.red : Token.color.foreground),
  title: 'Regrettable',
}

export const lineManagerColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.line_manager.id',
  dataPoint: 'employee.line_manager.full_name',
  sortKey: 'employee__line_manager',
  filterKey: 'employee__line_manager',
  selectorsKey: selectorKeys.manager,
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee.line_manager} />,
  title: 'LM',
}

export const createdOnColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const noticePeriodColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.text,
  idPoint: 'notice_period',
  dataPoint: 'notice_period',
  sortKey: 'notice_period',
  filterKey: 'notice_period',
  selectorsKey: selectorKeys.employee_resignation_notice_period,
  title: 'Notice period',
}

export const resignationStatusColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_resignation_statuses,
  insert: ({ data }) => {
    let variant: ColorTagVariant

    switch (data.status.id) {
      case 'completed':
        variant = 'success'
        break
      case 'pending_review':
      case 'pending_notice_file':
      case 'notice_file_pending_review':
        variant = 'warning'
        break
      case 'cancelled':
        variant = 'neutral'
        break
      default:
        variant = 'default'
    }
    return <Table.StatusCell status={data.status.name} variant={variant} />
  },
  title: 'Status',
}

export const hrManagerColumn: ColumnInterface<EmployeeResignationInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.hr_manager.id',
  dataPoint: 'employee.hr_manager.full_name',
  sortKey: 'hr_manager',
  filterKey: 'hr_manager',
  selectorsKey: selectorKeys.specialisations,
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee.line_manager} />,
  title: 'HR manager',
}
