import React from 'react'
import { TextButton } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface } from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import {
  kpiDashboardsColumn,
  kpiGenericNameColumn,
  kpiUpdateTypeColumn,
} from '@src/constants/columns/kpi'
import {
  companyGoalCurrentValueColumn,
  companyGoalInitialValueColumn,
  companyGoalsKPILevel,
  companyGoalsName,
  companyGoalsPerformance,
  companyGoalStatusColumn,
  companyGoalsWeight,
  companyGoalTarget1YColumn,
  companyGoalTargetColumn,
  companyGoalUnitColumn,
  companyKPICompanyGoalColumn,
} from '@src/constants/columns/companyGoals'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { KPITypes } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { rowHighlight } from '@src/features/KPI'

const getCompanyKPIRow =
  (isGoal: boolean): ((companyName: string) => RowInterface<KpiInterface>) =>
  companyName => ({
    highlight: data => rowHighlight(data.target_status),
    disabled: data => data.target_status === Statuses.archived,
    cells: [
      {
        ...(isGoal ? companyGoalsName : kpiGenericNameColumn),
        width: 250,
      },
      {
        ...companyGoalsWeight,
        width: 100,
      },
      {
        ...companyGoalsPerformance,
        width: 100,
      },
      ...(isGoal ? [] : [{ ...companyKPICompanyGoalColumn, width: 180 }]),
      {
        ...companyGoalsKPILevel,
        insert: ({ data }) => {
          if (data.id === -1) {
            return ' '
          }
          if (data.type === KPITypes.company_goal || data.type === KPITypes.company_kpi) {
            return companyName
          }
          if (data.type === KPITypes.employee_kpi) {
            return data.owner?.full_name || ''
          }

          if (data.type === KPITypes.function_kpi) {
            return data?.function?.name || ''
          }

          if (data.type === KPITypes.department_kpi) {
            return data?.department?.name || ''
          }

          if (data.type === KPITypes.team_kpi) {
            return data?.team?.name || ''
          }

          return ' '
        },
        width: 180,
      },
      {
        ...companyGoalInitialValueColumn,
        width: 75,
      },
      {
        ...companyGoalCurrentValueColumn,
        width: 95,
      },
      {
        ...companyGoalTargetColumn,
        width: 85,
      },
      {
        ...companyGoalTarget1YColumn,
        width: 85,
      },
      {
        ...companyGoalUnitColumn,
        width: 85,
      },
      {
        ...ownerNameWithAvatarColumn,
        width: 180,
      },
      {
        ...kpiUpdateTypeColumn,
        width: 100,
      },
      {
        ...companyGoalStatusColumn,
        width: 100,
      },
      ...(isGoal
        ? []
        : [
            {
              ...kpiDashboardsColumn,
              width: 110,
              insert: ({ data }: { data: KpiInterface }) => {
                return (
                  <>
                    {data.related_dashboards?.length ? (
                      <TextButton
                        onClick={e => {
                          e.stopPropagation()
                          navigateTo(
                            `${pathToUrl(
                              ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS,
                            )}?related_kpis=${data.id}&company_related=${
                              data.type === KPITypes.company_kpi
                            }`,
                          )
                        }}
                      >
                        View
                      </TextButton>
                    ) : null}
                  </>
                )
              },
            },
          ]),
    ],
  })

export const CompanyKPIRow = getCompanyKPIRow(false)
export const CompanyGoalRow = getCompanyKPIRow(true)
