import React from 'react'
import { ResponsiveContainer as RechartsContainer } from 'recharts'

const TEST_FIXED_VALUE = 800

type ResponsiveContainerProps = {
  height: React.ComponentProps<typeof RechartsContainer>['height']
  width: React.ComponentProps<typeof RechartsContainer>['width']
  children: React.ReactElement
}

export const ResponsiveContainer = ({
  height,
  width,
  children,
}: ResponsiveContainerProps) => {
  const isTest = process.env.NODE_ENV === 'test'
  const trueHeight = isTest ? TEST_FIXED_VALUE : height
  const trueWidth = isTest ? TEST_FIXED_VALUE : width

  return (
    <RechartsContainer height={trueHeight} width={trueWidth}>
      {children}
    </RechartsContainer>
  )
}
