import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import { API, selectorKeys } from '@src/constants/api'
import { RoleInterface } from '@src/interfaces/roles'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { EntityPermissions } from '@src/store/auth/types'
import { rolesRequests, rolesRequestsNew } from '@src/api/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { InputGroup } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import SideBar from '@components/SideBar/SideBar'
import GeneralExamples from '@src/pages/Forms/RoleForm/General/GeneralExamples'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { Statuses } from '@src/interfaces'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { OptionInterface } from '@src/interfaces/selectors'
import { locationsRequests } from '@src/api/locations'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Form from '@src/features/Form/Form'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import { SectionOptions } from '@src/interfaces/customFields'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'

export const validator = {
  name: yup.string().required(),
  role_goals: yup.array(),
  role_description: yup.string().nullable(),
  role_manager: yup.object().required(),
}

const CURRENT_STEP = 'general'

const General = () => {
  const { data: settings } = useGetOrganisationSettings()
  const { values, valid } = useLapeContext<RoleInterface>()
  const user = useSelector(selectUser)
  const [isSidebarOpen, setSidebarOpen] = useState(true)
  const [fetchingLocations, setFetchingLocations] = useState(false)
  const [locations, setLocations] = useState<OptionInterface[]>([])
  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    if (!values.role_manager && user) {
      values.role_manager = { id: user.id, full_name: user.full_name }
    }

    if (!values.id && !values.goals?.length) {
      values.goals = []
    }

    if (!values.function) {
      setFunction()
    }
  }, [])

  useEffect(() => {
    if (settings?.enable_location_limitations) {
      fetchLocations()
    }
  }, [settings?.enable_location_limitations])

  const fetchLocations = async () => {
    try {
      setFetchingLocations(true)
      const result = await locationsRequests.getItems({})
      if (result?.data?.results) {
        setLocations(
          result?.data?.results.map(location => ({
            name: location.location_name,
            id: location.id,
          })),
        )
      }
    } finally {
      setFetchingLocations(false)
    }
  }

  const setFunction = async () => {
    if (user.position) {
      const result = await rolesRequests.getItem(user.position.id)
      if (result.data) {
        values.function = {
          name: result?.data?.function?.name,
          id: result?.data?.function?.id,
          function_type: result?.data?.function?.function_type,
        }
      }
    }
  }

  let canChange = true

  if (values.field_options) {
    canChange = !!values.field_options?.actions?.includes?.(EntityPermissions.Change)
  }

  return (
    <>
      <SideBar
        title="Information"
        isOpen={isSidebarOpen}
        onClose={() => setSidebarOpen(false)}
      >
        <GeneralExamples />
      </SideBar>
      <PageBody>
        <NewStepperSectionCustomFields
          sectionId={SectionOptions.Roles}
          roleId={values.id}
        >
          <AutoStepperTitle title="Role details" />
          <InputGroup>
            <LapeNewInput label="Role name" name="name" required />
            <LapeRadioSelectInput
              name="function"
              label="Function"
              disabled={!canChange}
              selector={selectorKeys.functions}
            />
            <LapeRadioSelectInput
              name="role_manager"
              label="Role Owner"
              disabled={!canChange}
              selector={selectorKeys.employee}
            />
            {settings?.enable_location_limitations ? (
              <>
                <LapeNewMultiSelect
                  name="locations"
                  placeholder="Location"
                  options={locations?.map(location => ({
                    label: location.name,
                    value: location,
                  }))}
                  disabled={fetchingLocations || !canChange}
                />
              </>
            ) : null}
          </InputGroup>
          <AutoStepperTitle title="Role description" />
          <InputGroup>
            <LapeNewTextArea
              name="role_description"
              label="Role mission"
              disabled={!canChange}
              rows={3}
            />
            <LapeMultiInput title="Enter role goals" name="role_goals" />
          </InputGroup>
        </NewStepperSectionCustomFields>
      </PageBody>
      <PageActions>
        <>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="role"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.ROLE.GENERAL}
              isNew
              notification={{
                path: ROUTES.FORMS.ROLE.GENERAL,
                updateMsg: 'Role draft successfully updated.',
                createMsg: 'Role draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.ROLES}
            type="role"
            step={CURRENT_STEP}
            to={nextLink}
            disabled={!valid}
          />
        </>
      </PageActions>
    </>
  )
}

export default connect(() => (
  <Form api={rolesRequestsNew}>
    <General />
  </Form>
))
