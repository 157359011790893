import React, { useEffect } from 'react'
import { Flex, Text, Progress } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { TableNames } from '@src/constants/table'
import BulkEditTable from '@src/components/BulkEdit/BulkEditTable'
import { UseFetchResult } from '@src/interfaces'
import { BulkEditItemsResponse, BulkEditResponse } from '@src/interfaces/bulkEdit'
import { AxiosPromise } from 'axios'
import Table from '@src/components/TableV2/Table'

const REFRESH_INTERVAL = 10000

type TrackProgressWidgetProps = {
  getItems: (id: string | number) => AxiosPromise<BulkEditItemsResponse>
  useBulkEdit: (id: string | number) => UseFetchResult<BulkEditResponse>
  tableName: TableNames
}

const TrackProgressWidget = ({
  getItems,
  useBulkEdit,
  tableName,
}: TrackProgressWidgetProps) => {
  const params = useParams<{ id: string }>()
  const { id } = params

  const { data: bulkEditData, refetch, isLoading } = useBulkEdit(id)

  const isDone = bulkEditData?.status.id === 'completed'
  const isWaitingToStart =
    bulkEditData?.completed === 0 && bulkEditData?.in_progress === 0

  const progressMessage = () => {
    if (isDone) {
      return 'All changes were successfully submitted.'
    }
    if (isWaitingToStart) {
      return 'Waiting for background task to start...'
    }
    return 'Applying changes in progress...'
  }

  useEffect(() => {
    // refetch every REFRESH_INTERVAL
    const interval = setInterval(() => {
      if (isLoading) {
        return
      }
      if (isDone) {
        clearInterval(interval)
        return
      }
      refetch()
    }, REFRESH_INTERVAL)
    return () => clearInterval(interval)
  }, [isLoading, isDone])

  if (!bulkEditData) {
    return <PageLoading />
  }

  const totalToDo = bulkEditData.total - bulkEditData.invalid

  const progressValue = bulkEditData.completed / totalToDo
  const progressColor = isDone ? 'green' : 'orange'

  return (
    <Table.Widget>
      <Table.Widget.Status>
        <Flex flexDirection="column" width="100%">
          <Text fontSize="h1" fontWeight="bold" mb="s-2">
            {bulkEditData.completed} of {totalToDo}
          </Text>
          <Text color="gray" mb="s-16">
            Changes completed
          </Text>
          <Progress value={progressValue} color={progressColor} mb="s-16" />
          <Text color="gray" mb="s-16">
            {progressMessage()}
          </Text>
        </Flex>
      </Table.Widget.Status>
      <Table.Widget.Table>
        <BulkEditTable
          id={id}
          autoRefresh={!isDone}
          getItems={getItems}
          tableName={tableName}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default TrackProgressWidget
