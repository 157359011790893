import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { FilterType, CellTypes, FilterOption } from '@src/interfaces/data'
import { FilterNumberRange } from '@components/Inputs/Filters/FilterNumberRange/FilterNumberRange'
import FilterTextInput, {
  FilterTextInputType,
} from '@components/Inputs/Filters/FilterTextInput/FilterTextInput'
import { FilterDateRange } from '@components/Inputs/Filters/FilterDateRange/FilterDateRange'
import FilterSelect from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { VirtualOrHTMLElement } from '@revolut/ui-kit/types/dist/types'
import { OptionInterface } from '@src/interfaces/selectors'

interface HeaderCellFilterProps {
  filterType?: FilterType
  cellType: CellTypes
  open: boolean
  onClose: () => void
  onChange: (options: FilterOption[]) => void
  selectorsKey: selectorKeys | (() => Promise<{ options: OptionInterface[] }>)
  value?: FilterOption[]
  anchorRef?: React.RefObject<VirtualOrHTMLElement | null>
  dataPoint?: string
  fetchSelectors?: (key: selectorKeys) => () => Promise<{ options: OptionInterface[] }>
}

export const HeaderCellFilter = ({
  filterType,
  cellType,
  selectorsKey,
  open,
  onClose,
  onChange,
  value,
  anchorRef,
  dataPoint,
  fetchSelectors,
}: HeaderCellFilterProps) => {
  if (filterType === FilterType.range) {
    return (
      <FilterNumberRange
        onChange={onChange}
        value={value}
        open={open}
        onClose={onClose}
        anchorRef={anchorRef}
      />
    )
  }

  if (filterType === FilterType.percentRange) {
    return (
      <FilterNumberRange
        multiplier={100}
        onChange={onChange}
        value={value}
        open={open}
        onClose={onClose}
        anchorRef={anchorRef}
      />
    )
  }

  if (filterType === FilterType.text) {
    return (
      <FilterTextInput
        open={open}
        onClose={onClose}
        anchorRef={anchorRef}
        onChange={onChange}
        value={value}
        type={FilterTextInputType.Text}
      />
    )
  }

  if (filterType === FilterType.number) {
    return (
      <FilterTextInput
        open={open}
        onClose={onClose}
        anchorRef={anchorRef}
        onChange={onChange}
        value={value}
        type={FilterTextInputType.Number}
      />
    )
  }

  if (
    filterType === FilterType.date ||
    filterType === FilterType.shortDate ||
    cellType === CellTypes.date ||
    cellType === CellTypes.dateTime
  ) {
    return (
      <FilterDateRange
        onChange={onChange}
        value={value}
        open={open}
        onClose={onClose}
        type={filterType}
        anchorRef={anchorRef}
      />
    )
  }

  if (selectorsKey === selectorKeys.automatic && fetchSelectors && dataPoint) {
    return (
      <FilterSelect
        value={value}
        onChange={onChange}
        selector={fetchSelectors(dataPoint.replace(/\./g, '__') as selectorKeys)}
        open={open}
        onClose={onClose}
        anchorRef={anchorRef}
      />
    )
  }

  return (
    <FilterSelect
      value={value}
      onChange={onChange}
      selector={selectorsKey}
      open={open}
      onClose={onClose}
      anchorRef={anchorRef}
    />
  )
}
