import React, { useEffect } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Badge, Box, TextSkeleton, Token } from '@revolut/ui-kit'

import {
  useGetWorkScheduleCustomApprovers,
  workScheduleCustomApproversRequests,
} from '@src/api/workSchedule'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { RequestInterfaceNew } from '@src/interfaces'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'

import { TitleWithStatus } from '../../common/TitleWithStatus'
import { WorkScheduleCustomApproversDetails } from './Details'
import { WorkScheduleCustomApproversReview } from './Review'
import { CommonTabsProps } from './common'

const tabsOrder = [
  {
    key: 'details' as const,
    path: ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT.DETAILS,
    title: 'Details',
    component: WorkScheduleCustomApproversDetails,
    api: workScheduleCustomApproversRequests,
  },
  {
    key: 'review' as const,
    path: ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT.REVIEW,
    title: 'Review',
    component: WorkScheduleCustomApproversReview,
  },
]

type TabKey = 'details' | 'review'
type EditTab = TabBarTableNavigationInterface & {
  key: TabKey
  path: string
  title: string
  component: React.FC<CommonTabsProps>
  api?: RequestInterfaceNew<{}>
}
type TabParams = { id?: string; subtab: TabKey }

const getTabs = (params: TabParams) => {
  return tabsOrder.reduce<EditTab[]>((tabsWithStatuses, tab, idx) => {
    const disabled = tab.key === 'review' && !params.id
    const isCompleted = tab.key === 'details' && !!params.id

    tabsWithStatuses.push({
      ...tab,
      disabled,
      url: pathToUrl(tab.path, params),
      preTitle: (
        <Box mr="s-8">
          <Badge size={16} bg={isCompleted ? Token.color.green : Token.color.foreground}>
            {idx + 1}
          </Badge>
        </Box>
      ),
    })

    return tabsWithStatuses
  }, [])
}

export const EditWorkScheduleCustomApprovers = connect(() => {
  const params = useParams<TabParams>()
  const tabs = getTabs(params)
  const { data, refetch } = useGetWorkScheduleCustomApprovers(params.id)
  const titleFallback = params.id ? (
    <TextSkeleton width={280} height={24} />
  ) : (
    'New custom approval flow'
  )

  useEffect(() => {
    refetch()
  }, [params.subtab])

  return (
    <PageWrapper>
      <PageHeader
        title={
          data ? (
            <TitleWithStatus data={{ name: data.name, status: data.status }} />
          ) : (
            titleFallback
          )
        }
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.LIST}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-24" />
        <Switch>
          {tabs.map((tab, idx) => (
            <Route exact key={tab.key} path={tab.path}>
              <Form api={tab.api} disableLocalStorageCaching>
                <tab.component nextTabPath={tabs[idx + 1]?.path} />
              </Form>
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
})
