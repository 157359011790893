import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DeliverableSettingsInterface } from '@src/interfaces/deliverables'
import { getDeliverablesSettings } from '@src/api/deliverables'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { EmployeeInterface } from '@src/interfaces/employees'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import Deliverables from './Deliverables'
import { PerformanceHistory } from './PerformanceHistory/PerformanceHistory'
import PerformanceReview from './PerformanceReview'
import { Skills } from './Skills'
import { useSkillsTable } from './Skills/hooks'
import UpwardsReview from './Upwards'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  max-height: 100%;
`

interface Props {
  data: EmployeeInterface
}

export const Performance = (props: Props) => {
  const params = useParams<{ id?: string }>()
  const id = params.id

  const featureFlags = useSelector(selectFeatureFlags)
  const canViewPerformanceHistory = featureFlags.includes(FeatureFlags.PerformanceHistory)
  const { data: performanceSettings } = useGetPerformanceSettings()

  const [deliverablesSettings, setDeliverablesSettings] = useState<{
    settings: DeliverableSettingsInterface
    loaded: boolean
  }>({
    settings: {
      is_deliverable_review_enabled: false,
      deliverable_review_quality_criteria: null,
      permissions: {
        can_review: false,
      },
    },
    loaded: false,
  })

  const fetchDeliverablesSettings = async () => {
    if (!id) {
      return
    }

    try {
      const settings = await getDeliverablesSettings(+id)
      setDeliverablesSettings({
        settings: settings.data,
        loaded: true,
      })
    } catch (e) {
      setDeliverablesSettings(prevState => ({
        ...prevState,
        loaded: true,
      }))
    }
  }

  useEffect(() => {
    fetchDeliverablesSettings()
  }, [])

  const canViewDeliverables = deliverablesSettings.settings.is_deliverable_review_enabled
  const canViewPerformanceHistoryTab = props.data?.field_options?.permissions?.includes(
    PermissionTypes.CanViewPerformanceHistoryTab,
  )

  const skillsTable = useSkillsTable(props.data)

  const tabs = [
    {
      title: 'Performance' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, params),
      component: PerformanceReview,
    },
    {
      title: 'History' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.TIMELINE,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.TIMELINE, params),
      component: PerformanceHistory,
      visibilityCheck: canViewPerformanceHistory && canViewPerformanceHistoryTab,
    },
    {
      title: 'Upwards' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS, params),
      component: UpwardsReview,
      visibilityCheck: performanceSettings?.enable_upwards_reviews,
    },
    {
      title: 'Deliverables' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.DELIVERABLES,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.DELIVERABLES, params),
      component: Deliverables,
      visibilityCheck: deliverablesSettings.loaded ? !!canViewDeliverables : true,
    },
    {
      title: 'Skills' as const,
      path: ROUTES.FORMS.EMPLOYEE.PERFORMANCE.SKILLS,
      url: pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.SKILLS, params),
      component: Skills,
      visibilityCheck:
        !!performanceSettings?.enable_skill_assessment &&
        canViewPerformanceHistory &&
        !skillsTable.error,
    },
  ]

  const filteredTabs = tabs
    .filter(tab => (tab.visibilityCheck !== undefined ? tab.visibilityCheck : true))
    .map(({ visibilityCheck, ...tab }) => tab)

  return (
    <Wrapper>
      <Flex alignItems="flex-start" mb="s-16">
        <TabBarNavigation isSubtab tabs={filteredTabs} />
      </Flex>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.title}>
            <tab.component {...props} settings={deliverablesSettings.settings} />
          </Route>
        ))}
        <InternalRedirect to={tabs[0].path} />
      </Switch>
    </Wrapper>
  )
}
