import React, { useContext } from 'react'
import styled from 'styled-components'
import AssessButtons, { AssessButtonTypes } from '@components/AssessButtons/AssessButtons'
import { formatListLetters } from '@src/utils/format'
import {
  ChangeScorecardInterface,
  CriteriaAssessment,
  CriteriaAssessmentItem,
  CriteriaAssessmentValues,
  DeliverableOptions,
  ReviewInterface,
} from '@src/interfaces/performance'
import { PreviousBlock } from '@components/ScorecardGeneral/Common'
import Tooltip from '@components/Tooltip/Tooltip'
import { Flex, Tag, Token } from '@revolut/ui-kit'
import { PREVIOUS_CYCLE_WIDTH } from '@src/constants/performance'
import { ShowPreviousValuesContext } from '@components/ScorecardGeneral/utils'

const Level = styled.div`
  padding: 16px;
  &:first-child {
    margin-top: 4px;
  }
`
const LevelTitle = styled.div`
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
`

const LevelOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  position: relative;
  gap: 16px;
`
const LevelListLetter = styled.span`
  color: ${Token.color.greyTone50};
  padding-right: 8px;
`

const StyledAssessButtons = styled(AssessButtons)`
  margin: 0 16px;
`

const PreviewReview = styled(AssessButtons)`
  width: ${PREVIOUS_CYCLE_WIDTH}px;
  display: flex;
  justify-content: center;
`

const PreviousReviews = styled.div`
  ${PreviousBlock};
`

interface ScorecardAssessLevelProps {
  content: CriteriaAssessment
  cardIndex: number
  sectionIndex: number
  onChange?: (params: ChangeScorecardInterface) => void
  viewMode?: boolean
  error?: string
  touched?: boolean
  generateOptionDataName?: (
    cardIndex: number,
    sectionIndex: number,
    optionIndex: number,
  ) => string
  ratingExpectation: DeliverableOptions | null
  hideCounts?: boolean
}

export const ScorecardAssessLevel = ({
  content,
  cardIndex,
  onChange,
  sectionIndex,
  error,
  viewMode,
  touched,
  generateOptionDataName,
  ratingExpectation,
  hideCounts,
}: ScorecardAssessLevelProps) => {
  const showPreviousRatings = useContext(ShowPreviousValuesContext)

  const getCheckedItems = (option: CriteriaAssessmentItem) => {
    if (Array.isArray(option.values)) {
      return option.values.map(val => val.value!)
    }

    if (option.value) {
      return [option.value]
    }

    return []
  }

  const getReviewsList = (values?: CriteriaAssessmentValues[]) => {
    const reviewsList = {
      [AssessButtonTypes.positive]: [] as ReviewInterface[],
      [AssessButtonTypes.negative]: [] as ReviewInterface[],
      [AssessButtonTypes.neutral]: [] as ReviewInterface[],
      [AssessButtonTypes.unknown]: [] as ReviewInterface[],
    }

    values?.forEach(review => {
      if (reviewsList[review.value!]) {
        reviewsList[review.value!].push(review.review)
      }
    })

    return reviewsList
  }

  return (
    <Level>
      <Flex gap="s-12" alignItems="center">
        {content.level_key && <LevelTitle>{content.level_key}</LevelTitle>}
        {ratingExpectation === content.level_key && (
          <Tag variant="outlined" color={Token.color.greyTone50}>
            Expected for seniority
          </Tag>
        )}
      </Flex>
      {content.items?.map((option, optionIndex) => (
        <LevelOption
          key={optionIndex}
          data-name={generateOptionDataName?.(cardIndex, sectionIndex, optionIndex)}
        >
          <div data-testid={`section-title-${cardIndex}-${sectionIndex}-${optionIndex}`}>
            <LevelListLetter>{formatListLetters(optionIndex)}.</LevelListLetter>
            <span>{option.text}</span>
          </div>
          <StyledAssessButtons
            disabled={viewMode}
            checked={getCheckedItems(option)}
            onChange={value => {
              onChange && onChange({ cardIndex, sectionIndex, optionIndex, value })
            }}
            reviewsList={getReviewsList(option.values)}
            showCounts={!hideCounts && viewMode}
            error={touched && !!error && !option.value}
            name={`assess_btn_${cardIndex}_${sectionIndex}_${optionIndex}`}
          />
          {showPreviousRatings && option.previous_values && (
            <PreviousReviews>
              {option.previous_values.map(item => (
                <Tooltip
                  key={item.review_id}
                  placement="top"
                  text="This is a new question / \n You didn't rate this before"
                  hide={!!item.value}
                >
                  <PreviewReview
                    disabled
                    checked={item.value ? [item.value] : []}
                    reviewsList={getReviewsList()}
                    visibleButtons={[item.value || AssessButtonTypes.unknown]}
                  />
                </Tooltip>
              ))}
            </PreviousReviews>
          )}
        </LevelOption>
      ))}
    </Level>
  )
}
