import { Action } from '@revolut/ui-kit'
import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'

interface ShowLogsActionProps {
  requestId: number
}

const ShowLogsAction = ({ requestId }: ShowLogsActionProps) => {
  const params = useParams<{ id?: string }>()

  return (
    <Action
      onClick={e => {
        e.stopPropagation()
        navigateTo(
          `${pathToUrl(ROUTES.FORMS.NOTIFICATION_TEMPLATE.LOGS, {
            id: params.id,
          })}?request__id=${requestId}`,
        )
      }}
      iconOnly
      useIcon="16/ListBullet"
      aria-label="Show logs"
    />
  )
}

export default ShowLogsAction
