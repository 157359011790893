import React, { useState } from 'react'
import pluralize from 'pluralize'
import {
  Text,
  chain,
  Group,
  Item,
  Avatar,
  Box,
  Color,
  Flex,
  ProgressSteps,
  ProgressStep,
  VStack,
  Cell,
  MoreBar,
  HStack,
} from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import {
  HirerightScreeningChecksInterface,
  ScreeningCheckStatuses,
} from '@src/interfaces/screeningChecksInterface'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  useRouteParams,
  getScreeningStatusColor,
  getHirerightAdjudicationStatusColor,
} from '../common'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  ArrowThinRight,
  CalendarCheck,
  ChevronDown,
  ChevronUp,
  InfoOutline,
  Time,
} from '@revolut/icons'
import {
  getHirerightExternalLink,
  useHirerightScreeningStatusTimeline,
} from '@src/api/screening'
import { formatDate } from '@src/utils/format'
import { format } from 'date-fns'
import SideBar from '@components/SideBar/SideBar'
import { ApprovalBanner } from '@src/pages/Forms/ScreeningForm/Components/ApprovalBanner'
import SettingsButtons from '@src/features/SettingsButtons'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { pushError } from '@src/store/notifications/actions'

export const HirerightScreeningPreview = () => {
  const { id, employeeId } = useRouteParams()
  const { initialValues } = useLapeContext<Partial<HirerightScreeningChecksInterface>>()

  const [packageExpanded, setPackageExpanded] = useState(false)
  const [timelineSidebarOpen, setTimelineSidebarOpen] = useState(false)
  const [isFetchingUrl, setIsFetchingUrl] = useState(false)

  const { data: screeningStatusTimeline } = useHirerightScreeningStatusTimeline(
    id,
    employeeId,
  )

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING, {
    id: employeeId,
  })

  const status = initialValues.adjudication_status || initialValues.status
  const statusColor = initialValues.adjudication_status
    ? getHirerightAdjudicationStatusColor(initialValues.adjudication_status?.id)
    : getScreeningStatusColor(initialValues.status?.id)

  return (
    <>
      <PageHeader
        title={chain(
          initialValues.package_name,
          status && <Text color={statusColor}>{status?.name}</Text>,
        )}
        subtitle={<EmployeeUserWithAvatar id={employeeId} />}
        backUrl={backUrl}
      />

      <PageBody>
        <SettingsButtons mb="s-16">
          <MoreBar.Action
            useIcon={ArrowThinRight}
            pending={isFetchingUrl}
            onClick={async e => {
              try {
                setIsFetchingUrl(true)
                const { data } = await getHirerightExternalLink(employeeId, id)

                if (window) {
                  e.stopPropagation()
                  const tab = window.open(data.external_url, '_blank')
                  tab?.focus()
                }
              } catch (error) {
                pushError({ error })
              } finally {
                setIsFetchingUrl(false)
              }
            }}
          >
            View in HireRight
          </MoreBar.Action>
          <MoreBar.Action useIcon={Time} onClick={() => setTimelineSidebarOpen(true)}>
            Show status history
          </MoreBar.Action>
        </SettingsButtons>
        <ApprovalBanner<HirerightScreeningChecksInterface>
          isHireRight
          text={
            'Please review screening check results and record adjudication decision in the HireRight platform directly. ' +
            'The screening status will be updated accordingly.'
          }
          isVisible={!!initialValues.adjudication_status}
        />

        <FormPreview title="Screening details" data={initialValues}>
          <Group>
            <FormPreview.Item
              title="Source"
              field="source.name"
              insert={() => (
                <HStack space="s-8">
                  <Text>HireRight</Text>
                  <Tooltip
                    noArrow
                    placement="left"
                    body={
                      <Box width={230} p="s-8">
                        <VStack space="s-8">
                          <Text color={Color.BACKGROUND} fontSize="14px">
                            Editing or deleting check is possible only on the provider’s
                            page.
                          </Text>
                          <Text color={Color.BACKGROUND} fontSize="14px">
                            Status will be updated automatically.
                          </Text>
                        </VStack>
                      </Box>
                    }
                  >
                    <InfoOutline size={16} color={Color.GREY_TONE_50} />
                  </Tooltip>
                </HStack>
              )}
            />
            <FormPreview.Item title="Triggered by" field="triggered_by" type="employee" />
            <FormPreview.Item
              title="Triggered on"
              field="creation_date_time"
              type="date"
            />
            <FormPreview.Item title="Progress status" field="status.name" />
            <FormPreview.Item
              title="Adjudication status"
              field="adjudication_status.name"
            />
            <FormPreview.Item title="Last update" field="update_date_time" type="date" />
            <FormPreview.Item
              title="Completed on"
              field="completion_date_time"
              type="date"
            />
          </Group>
        </FormPreview>

        <Box mt="s-16" data-testid="package_preview">
          <Group>
            <Item use="label" onClick={() => setPackageExpanded(!packageExpanded)}>
              <Item.Avatar>
                <Avatar useIcon={CalendarCheck} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{initialValues.package_name}</Item.Title>
                <Item.Description>
                  {initialValues.products?.length &&
                    `${pluralize('check', initialValues.products.length, true)} included`}
                </Item.Description>
              </Item.Content>
              <Item.Side>
                {packageExpanded ? (
                  <ChevronUp color={Color.GREY_TONE_50} />
                ) : (
                  <ChevronDown color={Color.GREY_TONE_50} />
                )}
              </Item.Side>
            </Item>
            {packageExpanded
              ? initialValues.products?.map(product => (
                  <Item key={product.id}>
                    <Item.Content>
                      <Item.Title>{product.name}</Item.Title>
                      {product.status && (
                        <Item.Description>{product.status.name}</Item.Description>
                      )}
                    </Item.Content>
                    <Item.Side>
                      {product.adjudication_status && (
                        <Text
                          color={getHirerightAdjudicationStatusColor(
                            product.adjudication_status.id,
                          )}
                        >
                          {product.adjudication_status.name}
                        </Text>
                      )}
                      {product.update_date_time && (
                        <Item.Description>
                          Updated on {formatDate(product.update_date_time)}
                        </Item.Description>
                      )}
                    </Item.Side>
                  </Item>
                ))
              : null}
          </Group>
        </Box>
        <SideBar
          isOpen={timelineSidebarOpen}
          onClose={() => setTimelineSidebarOpen(false)}
        >
          <Flex flexDirection="column">
            {screeningStatusTimeline?.length ? (
              // TODO: use 'vertical-compact' variant when available
              <Cell pb="s-16">
                <ProgressSteps variant="vertical">
                  {screeningStatusTimeline.map(step => (
                    <ProgressStep
                      state={step.date ? 'done' : 'pending'}
                      color={
                        step.status.id === ScreeningCheckStatuses.requiresAdjudication
                          ? Color.ORANGE
                          : undefined
                      }
                      key={step.status.id}
                    >
                      <ProgressStep.Title>{step.status.name}</ProgressStep.Title>
                      <ProgressStep.Caption>
                        {step.date ? (
                          <VStack color={Color.FOREGROUND}>
                            <Text>{format(new Date(step.date), 'MMM d')}</Text>
                            <Text>{format(new Date(step.date), 'HH:mm')}</Text>
                          </VStack>
                        ) : null}
                      </ProgressStep.Caption>
                    </ProgressStep>
                  ))}
                </ProgressSteps>
              </Cell>
            ) : null}
          </Flex>
        </SideBar>
      </PageBody>
    </>
  )
}
