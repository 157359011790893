import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { SlackBotInterface } from '@src/interfaces/integrations'
import Table from '@components/TableV2/Table'
import React from 'react'

export const slackBotNameColumn: ColumnInterface<SlackBotInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => (
    <Table.ItemCell
      iconProps={{ image: 'https://assets.revolut.com/assets/apps/Slack.png' }}
    >
      <Table.ItemCell.Title>{data.name}</Table.ItemCell.Title>
    </Table.ItemCell>
  ),
}

export const slackBotDescriptionColumn: ColumnInterface<SlackBotInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const slackBotScopesColumn: ColumnInterface<SlackBotInterface> = {
  type: CellTypes.text,
  idPoint: 'scopes',
  dataPoint: 'scopes',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Scopes',
}

export const slackBotActionsColumn: ColumnInterface<SlackBotInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
  title: 'Actions',
}
