import React from 'react'
import { Item, Spacer, Text } from '@revolut/ui-kit'

export const Intro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>Introducing our cutting-edge Performance App</Item.Title>
        <Item.Description>
          <Text use="p">
            Performance App — the ultimate solution for businesses seeking to optimize
            their workforce productivity, foster employee development, and drive
            organizational success.
          </Text>
          <Spacer height={16} />
          <Text use="p">
            Our Performance Management Tool is a comprehensive and intuitive software
            designed to streamline and enhance the entire performance management process.
            With a user-friendly interface and powerful features, it empowers HR
            professionals, managers, and employees to collaborate effectively and achieve
            their performance goals with precision.
          </Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
