import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import reduce from 'lodash/reduce'
import { useTable } from '@components/Table/hooks'
import {
  KpiInterface,
  KPIPerformanceTypes,
  KPIWeightModes,
  KpiWeightsInterface,
} from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import {
  editableKpiWeightColumn,
  getKpiApprovalActionsColumn,
  kpiCurrentValueColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiParentColumn,
  kpiPerformanceColumn,
  kpiPerformanceTypeColumn,
  kpiStatusTextColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { Box, Button, Checkbox, Flex, Header, Popup, Text } from '@revolut/ui-kit'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import { EditableRowInterface } from '@components/Table/EditableTable/EditableTable'
import { kpisApprovalsRequests } from '@src/api/kpis'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { EntityTypes } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { kpiWeightsSubmitRequest } from '@src/api/kpiWeights'
import { InfoOutline } from '@revolut/icons'
import LapeEditableTable from '@components/Table/EditableTable/LapeEditableTable'
import NewWarningMessage from '@components/NewWarningMessage/NewWarningMessage'
import Tooltip from '@components/Tooltip/Tooltip'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import {
  removeNotificationRequestRouter,
  tableInitialFiltersRouter,
} from '@src/pages/Forms/ApproveKPIs/routers'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import { KPIsToApproveNotification } from '@src/interfaces/kpiNotifications'
import { ApproveAllButton } from '@src/pages/Forms/ApproveKPIs/Buttons'
import { KPIApproveRejectProps } from '@components/ColumnInserts/KPIApproveReject/KPIApproveReject'
import KPIPreviewSide from '@src/features/KPIPreviewSide/KPIPreviewSide'
import { roundFloat } from '@src/utils/numbers'
import { successNotification } from '@src/actions/NotificationActions'
import { PageActions } from '@src/components/Page/PageActions'
import { autoCalculateKPIWeights } from '@src/utils/kpi'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'
import { rowHighlight } from '@src/features/KPI'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  id: string
  notification: KPIsToApproveNotification
  entityType: EntityTypes
  defaultWeightMode: KPIWeightModes
}

interface FormValues {
  data: KpiInterface[]
  weightMode: KPIWeightModes
  changedWeights?: { [kpiId: number]: number }
}

interface SubmitOptions extends Props {
  kpis: KpiInterface[]
  weightMode: KPIWeightModes
  removeNotification?: boolean
}

const getROW = (
  onAfterApproveReject: KPIApproveRejectProps['onAfterSubmit'],
  weightMode: KPIWeightModes,
  reviewCycle: ReviewCyclesInterface,
): EditableRowInterface<KpiInterface> => ({
  highlight: data => rowHighlight(data.target_status),
  cells: [
    {
      ...kpiGenericNameColumn,
    },
    {
      ...(weightMode === KPIWeightModes.auto ? kpiWeightColumn : editableKpiWeightColumn),
      width: 100,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...kpiPerformanceTypeColumn,
      width: 115,
    },
    {
      ...kpiInitialValueColumn,
      width: 70,
    },
    {
      ...kpiCurrentValueColumn,
      width: 70,
    },
    {
      ...kpiTargetColumn,
      width: 70,
    },
    {
      ...kpiUnitColumn,
      width: 70,
    },
    {
      ...kpiParentColumn,
      width: 80,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 150,
    },
    {
      ...kpiUpdateTypeColumn,
      width: 80,
    },
    {
      ...kpiStatusTextColumn,
      width: 150,
    },
    {
      ...getKpiApprovalActionsColumn(onAfterApproveReject, reviewCycle),
      width: 250,
    },
  ],
})

export const submitApproval = async ({
  id,
  kpis,
  notification,
  weightMode,
  entityType,
  removeNotification,
}: SubmitOptions) => {
  const businessKPIs = kpis.filter(
    kpi =>
      kpi.kpi_performance_type.id === KPIPerformanceTypes.business &&
      [Statuses.approved, Statuses.pending].includes(kpi.target_status),
  )
  const reviewCycle = notification.review_cycle
  const total = businessKPIs.reduce((acc, kpi) => roundFloat(acc + kpi.weight!, 2), 0)
  const balancedFirstWeight =
    businessKPIs.length > 0 ? roundFloat(businessKPIs[0].weight! + 100 - total, 2) : 0

  const kpiWeights: KpiWeightsInterface = {
    entity_id: Number(id),
    entity_type: entityType,
    kpi_weight_mode: { id: weightMode },
    review_cycle: { ...reviewCycle, offset: reviewCycle.offset },
    kpis: businessKPIs.map((kpi, key) => ({
      ...kpi,
      weight: key === 0 ? balancedFirstWeight : roundFloat(kpi.weight!, 2),
      enforce_weight: false,
    })),
  }

  if (businessKPIs.length > 0) {
    await kpiWeightsSubmitRequest(kpiWeights)
  }
  localStorage.removeItem(window.location.pathname)

  if (removeNotification) {
    await removeNotificationRequestRouter(entityType)(notification.id)
  }

  return Promise.resolve({ data: kpis, weightMode })
}

const onAfterSubmit = () => {
  navigateTo(ROUTES.APPS.TODO.KPIS_TO_APPROVE)
  successNotification('KPIs have been approved')
}

const tableOptions = { disableQuery: true }

const autoWeightModeDescription =
  'This will equally distribute the KPI weights and overwrite the current values.'

const ApproveKPIsForm = connect((props: Props) => {
  const { id, notification, entityType } = props
  const { review_cycle: reviewCycle } = notification

  const form = useLapeContext<FormValues>()
  const { values } = form

  const [isWeightsPopupOpen, setIsWeightsPopupOpen] = useState<boolean>(false)
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)
  const [initialTableData, setInitialTableData] = useState<KpiInterface[]>([])
  const [kpiPreviewId, setKpiPreviewId] = useState<number | null>(null)

  const table = useTable<KpiInterface>(
    kpisApprovalsRequests,
    tableInitialFiltersRouter(id, reviewCycle.offset, entityType),
    [],
    tableOptions,
  )

  const activeKPIs: KpiInterface[] = values.data.filter(
    kpi => kpi.target_status !== Statuses.rejected,
  )
  const pendingKPIs: KpiInterface[] = values.data.filter(
    kpi =>
      kpi.target_status === Statuses.pending ||
      kpi.target_status === Statuses.requires_changes,
  )

  const calculateTotal = (): number => {
    const absTotal = reduce(
      activeKPIs,
      (acc, kpi) => {
        if (kpi.kpi_performance_type.id === KPIPerformanceTypes.business) {
          return acc + (Number(kpi.weight) || 0)
        }
        return acc
      },
      0,
    )
    return roundFloat(absTotal, 1)
  }

  const total = calculateTotal()
  const weightsError =
    total === 100 || total === 0
      ? undefined
      : `The sum of all weights should be 100%, currently ${total}%`

  useEffect(() => {
    if (!table.data) {
      return
    }
    const transformedTableData: KpiInterface[] = []

    table.data.forEach(kpi => {
      const unsavedWeight = values.changedWeights?.[kpi.id]
      transformedTableData.push({ ...kpi, weight: unsavedWeight || kpi.weight })
    })

    setInitialTableData(transformedTableData)
  }, [table.data])

  useEffect(() => {
    if (values.weightMode === KPIWeightModes.auto) {
      autoSetWeights()
    }
  }, [values.data, values.weightMode])

  const onWeightsPopupCancel = () => {
    setIsWeightsPopupOpen(false)
  }

  const autoSetWeights = () => {
    values.changedWeights = autoCalculateKPIWeights(activeKPIs)
  }

  const hasWeightsError = weightsError && activeKPIs.length > 0

  return (
    <>
      <Popup
        open={isWeightsPopupOpen}
        onClose={onWeightsPopupCancel}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet">
          <Header.Title>Confirm auto distribution of weights</Header.Title>
        </Header>
        <Text use="p" variant="caption" color="grey-tone-50">
          {autoWeightModeDescription}
        </Text>
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={onWeightsPopupCancel}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => {
              values.weightMode = KPIWeightModes.auto
              setIsWeightsPopupOpen(false)
            }}
          >
            Continue
          </Button>
        </Popup.Actions>
      </Popup>
      <TableWrapper mb="s-20">
        <Flex mb="18px">
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
            disabled
          />
        </Flex>
        <Flex mb="18px">
          {pendingKPIs.length > 0 && (
            <ApproveAllButton
              mr="s-16"
              kpis={values.data}
              onAfterSubmit={() => {
                form.submit().then(onAfterSubmit)
              }}
              approvalStep={notification.approval_step.id}
              reviewCycleId={reviewCycle.id}
            />
          )}
          <Flex height="100%" alignItems="center">
            <Checkbox
              checked={values.weightMode === KPIWeightModes.auto}
              onChange={e => {
                if (e.currentTarget.checked) {
                  setIsWeightsPopupOpen(true)
                } else {
                  values.weightMode = KPIWeightModes.manual
                }
              }}
            />
            <Text pl="10px">Auto distribute weights</Text>
            <Tooltip placement="right" text={autoWeightModeDescription}>
              <Box pl="10px">
                <InfoOutline color="grey-tone-50" size={16} />
              </Box>
            </Tooltip>
          </Flex>
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <LapeEditableTable<KpiInterface>
            name={TableNames.ApproveKPIs}
            dataType="KPI"
            dataFieldName="data"
            row={getROW(table.refresh, values.weightMode, reviewCycle)}
            {...table}
            initialData={initialTableData}
            onRowClick={data => setKpiPreviewId(data.id)}
            useWindowScroll
            noDataMessage="No kpis found"
            disableFilters
            replaceOnInitialDataChange
            onChange={({ fieldValue, rowPath, columnTitle }) => {
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              const kpi: KpiInterface | undefined = values.data[rowPath]

              if (columnTitle === editableKpiWeightColumn.title && kpi?.id) {
                values.changedWeights = {
                  ...(values.changedWeights || {}),
                  [kpi.id]: fieldValue,
                }
              }
            }}
          />
        </Flex>
        {hasWeightsError && (
          <NewWarningMessage mt="s-20">{weightsError}</NewWarningMessage>
        )}
      </TableWrapper>

      <PageActions>
        <Button
          variant="secondary"
          pending={isSavingChanges}
          disabled={!!hasWeightsError && activeKPIs.length > 0}
          onClick={() => {
            setIsSavingChanges(true)
            submitApproval({
              ...props,
              kpis: values.data,
              weightMode: values.weightMode,
            })
              .then(() => {
                goBack(ROUTES.APPS.TODO.KPIS_TO_APPROVE)
              })
              .finally(() => setIsSavingChanges(false))
          }}
        >
          Save for later
        </Button>
        <NewSaveButtonWithPopup
          tooltipText={
            pendingKPIs.length > 0
              ? `There are pending KPIs left. Please approve or reject them in order to finalise the process.`
              : undefined
          }
          disabled={hasWeightsError || pendingKPIs.length > 0}
          successText="KPIs have been approved"
          onAfterSubmit={onAfterSubmit}
          noPopup
        >
          Submit as done
        </NewSaveButtonWithPopup>
      </PageActions>
      <KPIPreviewSide
        onClose={() => setKpiPreviewId(null)}
        isOpen={!!kpiPreviewId}
        kpiId={kpiPreviewId}
      />
    </>
  )
})

export default (props: Props) => {
  const url = window.location.pathname
  const savedData = workspaceLocalStorage.getItem(url)
  const parsedSavedData = savedData ? JSON.parse(savedData) : {}

  return (
    <LapeForm<FormValues>
      disableValidation
      initialValues={{
        weightMode: props.defaultWeightMode,
        data: [],
        ...parsedSavedData,
      }}
      onSubmit={form =>
        submitApproval({
          ...props,
          kpis: form.values.data,
          weightMode: form.values.weightMode,
          removeNotification: true,
        })
      }
    >
      <FormLocalstorageLape isExistingData={false} url={url} />
      <ApproveKPIsForm {...props} />
    </LapeForm>
  )
}
