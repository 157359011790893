import { Statuses } from '@src/interfaces'
import { FilterByInterface } from '@src/interfaces/data'
import { GoalPreviewInterface } from '@src/interfaces/goals'
import { useLocation } from 'react-router-dom'

const searchParamsToObject = (search: URLSearchParams) => {
  return [...search.entries()].reduce((acc, [key, value]) => {
    acc[key] = value
    return acc
  }, {} as Record<string, string>)
}

export const useGetSwitchFilters = (
  goalData: GoalPreviewInterface,
  baseCycleId?: number | string,
) => {
  const location = useLocation()
  const search = location.search
  const searchParams = searchParamsToObject(new URLSearchParams(search))
  const goalsFilterParams: FilterByInterface[] = [
    {
      columnName: 'approval_status',
      filters: [
        { id: Statuses.pending, name: Statuses.pending },
        { id: Statuses.approved, name: Statuses.approved },
        { id: Statuses.requires_changes, name: Statuses.requires_changes },
      ],
    },
  ]
  if (goalData.is_company) {
    goalsFilterParams.push({
      columnName: 'is_company',
      filters: [{ id: 'True', name: 'True' }],
    })
  }
  if (goalData.is_top_level) {
    goalsFilterParams.push({
      columnName: 'is_top_level',
      filters: [{ id: 'True', name: 'True' }],
    })
  }
  if (searchParams.child_goal && goalData?.parent?.id) {
    goalsFilterParams.push({
      columnName: 'parent_id',
      filters: [{ id: goalData.parent.id, name: 'parent_id' }],
    })
  }
  if (searchParams?.cycle__id) {
    goalsFilterParams.push({
      columnName: 'cycle__id',
      filters: [{ id: searchParams.cycle__id, name: 'cycle__id' }],
    })
  } else if (baseCycleId) {
    goalsFilterParams.push({
      columnName: 'cycle__id',
      filters: [{ id: baseCycleId, name: 'cycle__id' }],
    })
  }
  if (searchParams.content_type_id) {
    goalsFilterParams.push({
      columnName: 'content_type_id',
      filters: [
        {
          id: searchParams.content_type_id,
          name: 'content_type_id',
        },
      ],
    })
  } else if (goalData?.content_type?.id) {
    goalsFilterParams.push({
      columnName: 'content_type_id',
      filters: [
        {
          id: goalData?.content_type?.id,
          name: 'content_type_id',
        },
      ],
    })
  }

  if (searchParams?.object_id) {
    goalsFilterParams.push({
      columnName: 'object_id',
      filters: [{ id: searchParams.object_id, name: 'object_id' }],
    })
  } else if (goalData.object_id) {
    goalsFilterParams.push({
      columnName: 'object_id',
      filters: [{ id: goalData.object_id, name: 'object_id' }],
    })
  }
  return { filters: goalsFilterParams, searchParams }
}
