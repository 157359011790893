import Form from '@src/features/Form/Form'
import { serviceAccountRequest } from '@src/api/accessManagement'
import React from 'react'
import General from '@src/pages/Forms/AddServiceAccount/General'

const Index = () => {
  return (
    <Form api={serviceAccountRequest}>
      <General />
    </Form>
  )
}

export default Index
