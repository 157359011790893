import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Fixed, IconButton, Text, Token } from '@revolut/ui-kit'
import { Menu } from '@revolut/icons'
import { CareersCompanyLinkInterface } from '@src/interfaces/careers'
import { defaultTheme } from '@src/styles/theme'
import { PUBLIC_ROUTES } from '@src/constants/routes'

const Btn = styled(IconButton)`
  display: none;

  @media all and (max-width: 1024px) {
    display: block;
  }

  z-index: ${defaultTheme.zIndex.popup + 1};
`

const Logo = styled.img`
  width: auto;
  height: 100%;
`

const LogoLink = styled.a`
  height: 100%;
`

type Props = {
  links?: CareersCompanyLinkInterface[]
  logoUrl?: string
  logoName?: string
}

const MobileMenu = ({ links, logoUrl, logoName }: Props) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Btn useIcon={Menu} onClick={() => setOpen(prev => !prev)} />
      {open && (
        <Fixed
          top={0}
          left={0}
          width="100%"
          height="100%"
          bg={Token.color.layoutBackground}
          zIndex={defaultTheme.zIndex.popup}
        >
          <Box
            p="s-8"
            width="100%"
            height={64}
            borderBottom="1px solid"
            borderBottomColor={Token.color.greyTone10}
            style={{ flexShrink: 0, flexGrow: 0 }}
          >
            {logoUrl && (
              <LogoLink href={PUBLIC_ROUTES.CAREERS.JOB_LIST}>
                <Logo src={logoUrl} title={logoName} />
              </LogoLink>
            )}
          </Box>

          <Box p="s-8" mt="s-16">
            {links?.map(item => (
              <Text
                key={item.label}
                variant="primary"
                use="a"
                href={item.url}
                target="_blank"
                rel="noreferrer noopener"
                textDecoration="none"
                color={Token.color.foreground}
                aria-label={item.label}
                mr="s-40"
                mb="s-8"
                display="inline-block"
              >
                {item.label}
              </Text>
            ))}
          </Box>
        </Fixed>
      )}
    </>
  )
}

export default MobileMenu
