import React, { useState } from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'

import { LapeFormInterface } from '@src/features/Form/LapeForm'
import { ContractorInterface, EmployeeInterface } from '@src/interfaces/employees'
import { useFormValidator } from '@src/features/Form/FormValidator'
import EffectiveDatePopup from '@src/features/Popups/EffectiveDatePopup'
import { DatePickerInputProps } from '@components/Inputs/DatePickerInput/DatePickerInput'

interface UpdateButtonProps {
  form: LapeFormInterface<EmployeeInterface | ContractorInterface>
  employeeTypeChanged: boolean
  datePickerProps?: DatePickerInputProps
  onAfterSubmit?: () => void
}

export const UpdateButton = ({
  form,
  employeeTypeChanged,
  onAfterSubmit,
  ...datePickerProps
}: UpdateButtonProps) => {
  const { dirty, values, disabled, submit } = form

  const formValidator = useFormValidator()

  const [effectiveDatePopupOpen, setEffectiveDatePopupOpen] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [checkingConflicts, setCheckingConflicts] = useState(false)

  const shouldHideButton = (!!values.id && !dirty) || disabled

  const onSaveChanges = () => {
    return submit()
      .then(() => {
        setSuccessPopupOpen(true)
      })
      .finally(() => setEffectiveDatePopupOpen(false))
  }

  return (
    <>
      {effectiveDatePopupOpen && (
        <EffectiveDatePopup
          onClick={formValidator!.validate(onSaveChanges)}
          onClose={() => setEffectiveDatePopupOpen(false)}
          onCheckingConflictsChange={setCheckingConflicts}
          {...datePickerProps}
        />
      )}

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)

          if (onAfterSubmit) {
            onAfterSubmit()
          } else if (employeeTypeChanged) {
            window.location.reload()
          }
        }}
      >
        <StatusPopup.Title>Employee update successfully scheduled</StatusPopup.Title>
      </StatusPopup>

      {shouldHideButton ? null : (
        <Button
          onClick={() => setEffectiveDatePopupOpen(true)}
          elevated
          pending={checkingConflicts}
        >
          Save Changes
        </Button>
      )}
    </>
  )
}
