import { apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'

interface InviteEmployeesRequest {
  employees: { email: string }[]
  assign_it_admin_group?: boolean
}

interface InviteEmployeesResponse {
  employees: EmployeesSimpleInterface[]
}

export const inviteEmployees = (data: InviteEmployeesRequest) =>
  apiWithoutHandling.post<InviteEmployeesResponse>(
    `${API.EMPLOYEES_SIMPLE}/createForInvite`,
    data,
  )
