import React from 'react'
import { Box } from '@revolut/ui-kit'
import styled from 'styled-components'
import CVPreview from '@src/features/CVPreviewSidebar/CVPreview'

// custom behaviour as we should adjust the content considering the sidebar to avoid overlapping
const Wrap = styled(Box)`
  max-width: 960px;
  width: 100%;
  position: relative;
  height: 100%;

  @media all and (max-width: 1433px) {
    max-width: 100%;
  }

  @media all and (max-width: 1024px) {
    width: 100%;
  }
`

type Props = {
  id?: number | null
  loading?: boolean
}

const CVScreeningDocument = ({ id, loading }: Props) => {
  return (
    <Wrap>
      <CVPreview id={id} loading={loading} />
    </Wrap>
  )
}

export default CVScreeningDocument
