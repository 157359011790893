import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useParams } from 'react-router-dom'
import General from './General'

const RoleSettingsPage = () => {
  const params = useParams<{ id?: string }>()

  const isEditing = params.id !== undefined

  const backUrl = isEditing
    ? pathToUrl(ROUTES.FORMS.FUNCTION.ROLES, { id: String(params?.id) })
    : ROUTES.ORGANISATION.ROLES.ROLES

  const title = isEditing ? 'Edit Role' : 'New Role'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />
      <General />
    </PageWrapper>
  )
}

export default RoleSettingsPage
