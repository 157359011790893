import React from 'react'
import { ApplicationFormEnumQuestionIds } from '@src/interfaces/applicationForm'
import OptionSection from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Option/OptionSection'
import { Box, TextArea, Input } from '@revolut/ui-kit'
import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type Props = {
  isPreview?: boolean
  type: ApplicationFormEnumQuestionIds
  options?: InterviewScorecardSectionQuestionType<number>[]
  placeholder?: string
  onChangeQuestions?: (options: InterviewScorecardSectionQuestionType<number>[]) => void
  onChangePlaceholder?: (placeholder: string) => void
  optionsKey?: string
  disableEdit?: boolean
  disableDelete?: boolean
}

const ApplicationFormQuestionContent = ({
  isPreview = false,
  type,
  options,
  placeholder,
  onChangeQuestions,
  onChangePlaceholder,
  optionsKey,
  disableEdit = false,
  disableDelete = false,
}: Props) => {
  switch (type) {
    case ApplicationFormEnumQuestionIds.Checkbox:
      return (
        <OptionSection
          type="checkbox"
          previewMode={isPreview}
          value={options}
          onChange={onChangeQuestions}
          hidePoints
          optionsKey={optionsKey}
          valueDisabled={disableEdit}
          deleteDisabled={disableDelete}
        />
      )
    case ApplicationFormEnumQuestionIds.Option:
      return (
        <OptionSection
          type="radio"
          previewMode={isPreview}
          value={options}
          onChange={onChangeQuestions}
          hidePoints
          optionsKey={optionsKey}
          valueDisabled={disableEdit}
          deleteDisabled={disableDelete}
        />
      )
    case ApplicationFormEnumQuestionIds.Textarea:
      return isPreview ? (
        <TextArea
          label={placeholder}
          rows={3}
          onChange={() => {}}
          value=""
          disabled={disableEdit}
        />
      ) : (
        <Input
          value={placeholder}
          label="Placeholder (optional)"
          onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
          disabled={disableEdit}
        />
      )
    case ApplicationFormEnumQuestionIds.Text:
      return isPreview ? (
        <Input label={placeholder} onChange={() => {}} value="" disabled={disableEdit} />
      ) : (
        <Input
          value={placeholder}
          label="Placeholder (optional)"
          onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
          disabled={disableEdit}
        />
      )
    case ApplicationFormEnumQuestionIds.Dropdown:
      return isPreview ? (
        <RadioSelectInput
          label={placeholder}
          options={options?.map(item => ({
            label: item.text,
            value: item,
          }))}
          disabled={disableEdit}
        />
      ) : (
        <>
          <Box mb="s-16">
            <Input
              value={placeholder}
              label="Placeholder (optional)"
              onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
              disabled={disableEdit}
            />
          </Box>

          <OptionSection
            type="radio"
            previewMode={isPreview}
            value={options}
            onChange={onChangeQuestions}
            hidePoints
            optionsKey={optionsKey}
            valueDisabled={disableEdit}
            deleteDisabled={disableDelete}
            allowBulk
          />
        </>
      )
    default:
      return null
  }
}

export default ApplicationFormQuestionContent
