import { useEffect, useState } from 'react'

enum MessageType {
  ONE_TO_ONE_EXTENSION_CHECK_AVAILABILITY = 'ONE_TO_ONE_EXTENSION_CHECK_AVAILABILITY',
  ONE_TO_ONE_EXTENSION_AVAILABILITY = 'ONE_TO_ONE_EXTENSION_AVAILABILITY',
}

interface Message<T, K> {
  type: T
  payload: K
}

const isOfMessageType = <T, K>(message: unknown, type: T): message is Message<T, K> => {
  if (typeof message !== 'object' || message === null) {
    return false
  }

  return Object.getOwnPropertyDescriptor(message, 'type')?.value === type
}

export enum AvailabilityStatus {
  Unknown = 'Unknown',
  Installed = 'Installed',
}

interface AvailabilityCheckPayload {
  status: AvailabilityStatus
}

interface Props {
  status: AvailabilityStatus
  isChecking: boolean
}

export const useExtensionAvailability = (): Props => {
  const [status, setStatus] = useState<AvailabilityStatus>(AvailabilityStatus.Unknown)
  // TODO: change to "true" by default when the extension is published https://revolut.atlassian.net/browse/REVC-6613
  //  and the solution to check availability is confirmed to be working
  const [isChecking, setIsChecking] = useState(false)

  useEffect(() => {
    // TODO: test this approach when the extension is published https://revolut.atlassian.net/browse/REVC-6613
    //  NOTE: the domain must be whitelisted in ext manifest
    const messageListener = ({ data }: { data: object }) => {
      if (
        !isOfMessageType<
          MessageType.ONE_TO_ONE_EXTENSION_AVAILABILITY,
          AvailabilityCheckPayload
        >(data, MessageType.ONE_TO_ONE_EXTENSION_AVAILABILITY)
      ) {
        return
      }

      setStatus(data.payload.status)
      setIsChecking(false)
    }

    window.postMessage({ type: MessageType.ONE_TO_ONE_EXTENSION_CHECK_AVAILABILITY })
    window.addEventListener('message', messageListener, { once: true })

    return () => {
      window.removeEventListener('message', messageListener)
    }
  }, [])

  return {
    status,
    isChecking,
  }
}
