import React, { ReactNode } from 'react'
import { opacity, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import Icon from '../Icon/Icon'

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** Callback for clear button click */
  onRemove?: () => void
  /** Callback for on click anywhere else */
  onClick?: () => void
  /** Label of the Chip */
  label: ReactNode
}

const RemoveButton = styled.button.attrs({ type: 'button' })`
  flex: 0 0 32px;
  width: 32px;
  color: ${Token.color.blue};
  opacity: 0.1;
  &:hover {
    opacity: 0.2;
  }
`

const Label = styled.button.attrs({ type: 'button' })`
  display: flex;
  font-size: 12px;
  flex-wrap: nowrap;
  align-items: center;
  font-weight: 500;
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
  padding-left: 8px;
`

const Wrapper = styled.div<{ disabled?: boolean }>`
  max-width: 180px;
  background-color: ${({ disabled }) =>
    disabled ? opacity(Token.colorChannel.black, 0.05) : 'rgba(0, 117, 235, 0.06)'};
  min-width: 110px;
  font-size: 10px;
  height: 32px;
  border-radius: 4px;
  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? opacity(Token.colorChannel.black, 0.05) : 'rgba(0, 117, 235, 0.06)'};
  }
  display: flex;

  & > ${Label} {
    color: ${({ disabled }) => (disabled ? Token.color.greyTone50 : Token.color.blue)};
  }
`

const Chip = ({ label, onRemove, onClick, className, disabled }: Props) => {
  return (
    <Wrapper className={className} disabled={disabled}>
      <Label onClick={!disabled ? onClick : () => {}}>{label}</Label>
      {onRemove && (
        <RemoveButton onClick={onRemove}>
          <Icon type="CloseCircle" size="small" />
        </RemoveButton>
      )}
    </Wrapper>
  )
}

export default Chip
