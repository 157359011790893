import { Token } from '@revolut/ui-kit'
import { OneToOneMeeting } from '@src/interfaces/meetings'

export const baseCalendarEventBackgroundColor = Token.color.greyTone20
export const highlightedEventBackgroundColor = Token.color.deepGrey
export const calendarEventTextColor = Token.color.white

export const makeOneToOneEventTitle = (meeting: OneToOneMeeting): string =>
  `[1:1] ${meeting.employee.first_name} / ${meeting.manager.first_name}`

export const startDayOfWeek = 1
