import React from 'react'
import { Absolute, AspectRatio, Image, useMatchMedia, Token, Flex } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import { HeroBlockContent } from '@src/pages/Landing/components/HeroBlockContent'
import { useHeroBlockImageAnimation } from '@src/pages/Landing/hooks/useHeroBlockImageAnimation'
import { HERO_IMG, HERO_ICONS, HERO_MOBILE, HERO_LG } from '@src/pages/Landing/constants'
import { ContactUsHeroBlockContent } from '@src/pages/Landing/components/ContactUsHeroBlockContent'

const HeroBlockBase = styled(Flex)(
  css({
    position: 'relative',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
)

interface HeroProps {
  isContactUsForm?: boolean
}

export const Hero = ({ isContactUsForm }: HeroProps) => {
  const isLgBreakpoint = useMatchMedia(Token.media.lg)

  const imageRef = useHeroBlockImageAnimation({
    transform: true,
    opacity: true,
  })

  return (
    <HeroBlockBase minHeight={{ lg: '100vh' }}>
      {isContactUsForm ? null : (
        <Absolute height="100%" left={0} maxHeight="100vh" width="100%" overflow="hidden">
          <AspectRatio ratio={{ md: 1440 / 657 }} height="100%">
            <Image
              alt=""
              src={HERO_IMG}
              sizes="100vw"
              style={{
                objectFit: 'cover',
                objectPosition: isLgBreakpoint ? '50% 50%' : '50% 100%',
              }}
            />
          </AspectRatio>
        </Absolute>
      )}
      <Absolute
        height="100%"
        left={0}
        maxHeight="100vh"
        width="100%"
        overflow="hidden"
        hide="*-lg"
      >
        <AspectRatio ratio={{ md: 1440 / 657 }} height="100%" ref={imageRef}>
          <Image
            alt=""
            src={isContactUsForm ? HERO_LG : HERO_ICONS}
            sizes="100vw"
            style={{
              objectFit: 'contain',
              objectPosition: isLgBreakpoint ? '50% 40%' : '50% 100%',
              background: isContactUsForm
                ? undefined
                : 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(25,28,31,1) 100%)',
            }}
          />
        </AspectRatio>
      </Absolute>
      <AspectRatio ratio={375 / 220} hide={['*-md', 'lg']} width="100%" mt="s-64">
        <Image alt="" sizes="100vw" src={HERO_MOBILE} style={{ objectFit: 'cover' }} />
      </AspectRatio>
      {isContactUsForm ? <ContactUsHeroBlockContent /> : <HeroBlockContent />}
    </HeroBlockBase>
  )
}
