import React from 'react'
import { DetailsCell, Group, Token } from '@revolut/ui-kit'
import { formatDate, formatDateTime } from '@src/utils/format'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { formatSnakeCase } from '@src/utils/string'

type EngagementInfoProps = {
  candidate: CandidateInterface
  isArchived: boolean
}

const EngagementInfo = ({ candidate, isArchived }: EngagementInfoProps) => {
  if (!isArchived) {
    return null
  }

  return (
    <Group>
      <DetailsCell variant="header">
        <DetailsCell.Title>Engagement info</DetailsCell.Title>
      </DetailsCell>
      <DetailsCell>
        <DetailsCell.Title>Can be contacted</DetailsCell.Title>
        <DetailsCell.Content
          color={candidate.can_re_engage ? Token.color.green : Token.color.red}
        >
          {candidate.can_re_engage ? 'Yes' : 'No'}
        </DetailsCell.Content>
      </DetailsCell>
      {candidate.anonymising_expected_date_time && (
        <DetailsCell>
          <DetailsCell.Title>Data retained until</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDate(candidate.anonymising_expected_date_time)}
          </DetailsCell.Content>
        </DetailsCell>
      )}
      <DetailsCell>
        <DetailsCell.Title>Re-engagement status</DetailsCell.Title>
        <DetailsCell.Content>
          {formatSnakeCase(candidate.re_engagement_status)}
        </DetailsCell.Content>
      </DetailsCell>
      {candidate.re_engagement_date_time && (
        <DetailsCell>
          <DetailsCell.Title>Re-engagement date</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDateTime(candidate.re_engagement_date_time)}
          </DetailsCell.Content>
        </DetailsCell>
      )}
    </Group>
  )
}

export default EngagementInfo
