import React from 'react'
import { StarEmpty, StarFilled } from '@revolut/icons'
import { Spinner } from '@revolut/ui-kit'

interface StarItemProps {
  isFavourite: boolean
  isLoading: boolean
  name?: string
  onClick: () => void
}

const StarItem = ({ isFavourite, isLoading, name, onClick }: StarItemProps) => {
  if (isLoading) {
    return <Spinner size={20} mx="s-2" />
  }

  return (
    <button
      type="button"
      aria-label={isFavourite ? `Unfavourite ${name}` : `Favourite ${name}`}
      onClick={onClick}
    >
      {isFavourite ? (
        <StarFilled color="grey-tone-50" />
      ) : (
        <StarEmpty color="grey-tone-50" />
      )}
    </button>
  )
}

export default StarItem
