import { ScheduleOneToOneMeetingPayload } from '@src/api/meetings'
import { formatRRule } from '@src/utils/format'
import { format } from 'date-fns'
import { TimeSlot } from '@src/interfaces/meetings'

export const formatRecurrence = (event: ScheduleOneToOneMeetingPayload): string =>
  formatRRule(event.recurrence[0])

export const formatTimeDiff = (slot: TimeSlot, fullDayName: boolean = false): string => {
  const startDate = new Date(slot.start)
  const endDate = new Date(slot.end)

  const day = format(startDate, fullDayName ? 'eeee' : 'eee')
  const fromTime = format(startDate, 'HH:mm')
  const toTime = format(endDate, 'HH:mm')

  return `${day}, ${fromTime} - ${toTime}`
}
