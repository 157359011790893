import { useEffect, useState } from 'react'
import { getEmployeeChangeRequests } from '@src/api/employeeChangeRequest'
import { getProfileSectionFields } from '@src/pages/EmployeeProfile/Preview/common'

export const useOrganisationPendingChanges = (
  employeeId: number,
  canEdit: boolean,
  canRequestChange: boolean,
) => {
  const [hasPendingChanges, setHasPendingChanges] = useState(false)
  const [canCheckForPendingChanges, setCanCheckForPendingChanges] = useState(false)

  useEffect(() => {
    if (canEdit || canRequestChange) {
      setCanCheckForPendingChanges(true)
    }
  }, [canEdit, canRequestChange])

  useEffect(() => {
    if (canCheckForPendingChanges) {
      getEmployeeChangeRequests(employeeId)({
        filters: [
          {
            columnName: 'status',
            filters: [
              { id: 'pending_approval', name: 'pending_approval' },
              { id: 'approved', name: 'approved' },
            ],
          },
          {
            columnName: 'field__field_name',
            filters: getProfileSectionFields('organisation').map(f => ({
              id: f,
              name: f,
            })),
          },
        ],
      }).then(response => {
        if (response.data.count) {
          setHasPendingChanges(true)
        }
      })
    }
  }, [canCheckForPendingChanges])

  return hasPendingChanges
}
