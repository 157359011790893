import React from 'react'
import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { BulkDataImportUploadFile } from '@src/features/BulkDataImport/BulkDataImportUploadFile'

export const Upload = () => {
  return (
    <PageBody>
      <BulkDataImportUploadFile
        apiEndpoint={API.CONTRACTS_UPLOAD}
        category="contract_bulk_upload"
        name="contract"
        nextRoute={ROUTES.FORMS.IMPORT_DATA.CONTRACTS.REVIEW_DATA}
      />
    </PageBody>
  )
}
