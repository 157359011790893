import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { locationSettings } from '@src/api/settings'
import { Locations } from '@src/pages/Settings/Locations/Locations'

const routes = [
  {
    title: 'Locations settings',
    description: 'Settings for the Locations application',
    path: ROUTES.SETTINGS.LOCATIONS,
    url: ROUTES.SETTINGS.LOCATIONS,
    canView: [
      PermissionTypes.ViewLocationPreferences,
      PermissionTypes.ChangeLocationPreferences,
    ],
    component: Locations,
  },
]

export const LocationSettings = () => {
  return <SettingsForm routes={routes} api={locationSettings} />
}
