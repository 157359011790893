import React from 'react'
import { useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TimeOffPolicyInterface } from '@src/interfaces/timeOff'
import { PageActions } from '@components/Page/PageActions'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useQuery } from '@src/utils/queryParamsHooks'
import { NextTabButtonCommonProps } from './common'

type Props = NextTabButtonCommonProps & {
  onBeforeSubmit?: () => Promise<void>
  onAfterSubmit?: () => Promise<void>
  disabled?: boolean
}
export const NextTabButton = ({
  nextTabPath,
  onNavigateToNextTab,
  onBeforeSubmit,
  onAfterSubmit,
  disabled,
}: Props) => {
  const params = useParams<{ id?: string }>()
  const { query } = useQuery()
  const { submit } = useLapeContext<TimeOffPolicyInterface>()

  const isLastTab = !nextTabPath

  return (
    <PageActions pt="s-64">
      <NewSaveButtonWithPopup
        useValidator
        successText="Policy saved successfully"
        previewUrl={ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW}
        disabled={disabled}
        onClick={async () => {
          await onBeforeSubmit?.()
          const res = await submit()
          await onAfterSubmit?.()

          const policyId = params.id || res.id

          if (nextTabPath) {
            navigateReplace(pathToUrl(nextTabPath, { id: policyId }, query))
            onNavigateToNextTab()
          }
        }}
        hideWhenNoChanges={false}
        isExistingData
      >
        {isLastTab ? 'Save policy' : 'Next'}
      </NewSaveButtonWithPopup>
    </PageActions>
  )
}
