import React, { useState } from 'react'
import {
  Box,
  Group,
  IconButton,
  MoreBar,
  Color,
  chain,
  Text,
  Token,
} from '@revolut/ui-kit'
import { Archive, Document, Unarchive } from '@revolut/icons'
import DOMPurify from 'dompurify'
import { upperFirst } from 'lodash'

import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName, Statuses } from '@src/interfaces'
import { EmployeeEmailTemplateInterface } from '@src/interfaces/employeeEmails'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { FileInterface } from '@src/interfaces/files'
import SettingsButtons, { CopyButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EntityPermissions } from '@src/store/auth/types'
import { changeEmployeeEmailTemplateStatus } from '@src/api/employeeEmails'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useSenderOptions } from '@src/features/EmployeeEmailForm/common'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { getStatusColor } from '@src/components/CommonSC/General'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'

const Preview = () => {
  const { values, initialValues, reset } =
    useLapeContext<EmployeeEmailTemplateInterface>()

  const [documentId, setDocumentId] = useState<number>()
  const [archivePending, setArchivePending] = useState(false)
  const [isArchiveConfirmationVisible, setIsArchiveConfirmationVisible] = useState(false)

  const onChangeStatus = (status: Statuses.archived | Statuses.active) => {
    setArchivePending(true)

    changeEmployeeEmailTemplateStatus(values.id, status)
      .then(response => {
        reset(response.data)
        setIsArchiveConfirmationVisible(false)
      })
      .finally(() => setArchivePending(false))
  }

  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)

  const senderOptions = useSenderOptions()

  return (
    <>
      <PageHeader
        title={chain(
          initialValues?.name,
          <Text color={getStatusColor(initialValues?.status?.id)}>
            {upperFirst(initialValues.status?.name)}
          </Text>,
        )}
        backUrl={ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES}
      />
      <PageBody>
        {canChange ? (
          <SettingsButtons mb="s-16">
            <EditButton
              route={pathToUrl(ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.GENERAL, {
                id: values.id,
              })}
            />
            <CopyButton
              afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.GENERAL)}
            />
            {values.status.id === Statuses.archived ? (
              <MoreBar.Action
                onClick={() => onChangeStatus(Statuses.active)}
                useIcon={Unarchive}
                pending={archivePending}
              >
                Unarchive
              </MoreBar.Action>
            ) : null}
            {values.status.id === Statuses.active ? (
              <MoreBar.Action
                onClick={() => setIsArchiveConfirmationVisible(true)}
                useIcon={Archive}
                variant="negative"
                pending={archivePending}
              >
                Archive
              </MoreBar.Action>
            ) : null}
          </SettingsButtons>
        ) : null}

        <Box mt="s-16">
          <FormPreview data={initialValues}>
            <Group>
              <FormPreview.Item title="Owner" field="owner" type="employee" />
              <FormPreview.Item title="Category" field="category.name" />
              <FormPreview.Details title="Description" field="description" />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview title="Email details" data={initialValues}>
            <Group>
              <FormPreview.Item<EmployeeEmailTemplateInterface>
                title="Send as"
                insert={data =>
                  senderOptions.find(sender => sender.value.id === data.sender_type.id)
                    ?.value.name
                }
              />
              {!!initialValues.recipients_cc?.length && (
                <FormPreview.Item<EmployeeEmailTemplateInterface>
                  title="Cc"
                  field="recipient_cc"
                  insert={data => data.recipients_cc?.join(', ') || '-'}
                />
              )}
              {!!initialValues.recipients_bcc?.length && (
                <FormPreview.Item<EmployeeEmailTemplateInterface>
                  title="Bcc"
                  field="recipient_bcc"
                  insert={data => data.recipients_bcc?.join(', ') || '-'}
                />
              )}
              <FormPreview.Item title="Subject line" field="subject" />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview data={initialValues}>
            <Group>
              <FormPreview.Details<EmployeeEmailTemplateInterface>
                title="Email body"
                field="email_body"
                insert={data => (
                  <Box
                    mt="s-16"
                    backgroundColor={Token.color.widgetBackground}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data.email_body),
                    }}
                  />
                )}
              />
            </Group>
          </FormPreview>
        </Box>

        {!!initialValues.attachments?.length && (
          <Box mt="s-16">
            <FormPreview title="Attachments" data={initialValues}>
              <Group>
                {(initialValues.attachments as FileInterface[])?.map(attachment => (
                  <FormPreview.Item
                    key={attachment.id}
                    title={attachment.name}
                    centered
                    insert={() =>
                      attachment.id ? (
                        <IconButton
                          useIcon={Document}
                          size={16}
                          color={Color.BLUE}
                          onClick={() => setDocumentId(attachment.id)}
                        />
                      ) : (
                        '-'
                      )
                    }
                  />
                ))}
              </Group>
            </FormPreview>
          </Box>
        )}
      </PageBody>

      {!!initialValues.attachments && documentId && (
        <PreviewDocumentSidebar
          file={
            (initialValues.attachments as FileInterface[]).find(
              attachment => attachment.id === documentId,
            ) as IdAndName
          }
          onClose={() => setDocumentId(undefined)}
        />
      )}

      <ConfirmationDialog
        open={isArchiveConfirmationVisible}
        onClose={() => setIsArchiveConfirmationVisible(false)}
        onConfirm={() => onChangeStatus(Statuses.archived)}
        loading={archivePending}
        onReject={() => setIsArchiveConfirmationVisible(false)}
        label="Archive email template"
        body="You or any employee will no longer be able to use this email template when sending emails. Are you sure you want to proceed?"
      />
    </>
  )
}

export default Preview
