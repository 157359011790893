import React from 'react'
import ReviewCyclesTable from '@src/pages/Performance/Review Cycles/ReviewCyclesTable'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const ReviewsCycles = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Manage cycles"
        subtitle="Manage performance review cycles"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.GENERAL)}
      />
      <ReviewCyclesTable />
    </PageWrapper>
  )
}
