import { createGlobalStyle } from 'styled-components'

// TODO: https://revolut.atlassian.net/browse/REVCOR-3046
export const GlobalStyle = createGlobalStyle`
[data-theme-transparent='true'] [data-rui='group'][data-rui='group'] > * {
    background-color: transparent;
}
[data-theme-transparent='true'] [data-rui='header'] [data-rui='avatar'][data-glow='true']::after {
    display: none;
}
[data-theme-transparent='true'] .recharts-rectangle.horizontalBarChartDashed, 
[data-theme-transparent='true'] .recharts-sector.pieChartCellsDashed {
    stroke: #1A1E31 !important;
}
`
