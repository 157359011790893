import { addDays } from 'date-fns'

// BE closing date is sometimes messed up. So we need to check if it's not before the start date
export const getRoundEndTime = ({
  startTime,
  deadlineDays,
  endTime,
}: {
  startTime: string | null
  deadlineDays: number
  endTime: string | null
}) => {
  if (!endTime || !startTime) {
    return new Date().toISOString()
  }
  if (new Date(endTime).getTime() < new Date(startTime).getTime()) {
    return addDays(new Date(startTime), deadlineDays).toISOString()
  }

  return endTime
}
