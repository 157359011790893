import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { skillSettings } from '@src/api/settings'
import { Skills } from '@src/pages/Settings/Skills/Skills'

const routes = [
  {
    title: 'Skills & Values settings',
    description: 'Settings for the skills and values app',
    path: ROUTES.SETTINGS.SKILLS,
    url: ROUTES.SETTINGS.SKILLS,
    canView: [
      PermissionTypes.ViewSkillsPreferences,
      PermissionTypes.ChangeSkillsPreferences,
    ],
    component: Skills,
  },
]

export const SkillSettings = () => {
  return <SettingsForm routes={routes} api={skillSettings} />
}
