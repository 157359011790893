import React from 'react'
import { Flex, FlexProps, Token } from '@revolut/ui-kit'

export const PageBody: React.FC<FlexProps & { maxWidthMd?: string }> = ({
  maxWidthMd = Token.breakpoint.md,
  ...props
}) => (
  <Flex
    maxWidth={{ all: '100%', md: maxWidthMd }}
    flex="1 0"
    flexDirection="column"
    pt="s-8"
    mb="s-24"
    {...props}
  />
)
