import React, { useState } from 'react'
import { TabBar } from '@revolut/ui-kit'
import {
  QueryColumnInterface,
  ReportHighlightInterface,
} from '@src/interfaces/dataAnalytics'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { ReorderColumnsSettings } from './UiFormatTable/ReorderColumns'
import { SortColumnsSettings } from './UiFormatTable/SortColumns'
import {
  ReorderSettingsInterface,
  SortSettingsInterface,
  FilterSettingsInterface,
} from '../hooks/useTableSettings'
import { IconName } from '@revolut/ui-kit/types/dist/components/Icon/types'
import { FilterColumnsSettings } from '@src/pages/Forms/DataAnalyticsReportForm/components/UiFormatTable/FilterColumns'
import { Highlight } from '@src/pages/Forms/DataAnalyticsReportForm/components/UiFormatTable/Highlight'
import SideBar from '@src/components/SideBar/SideBar'
import { RenameColumn } from '@src/pages/Forms/DataAnalyticsReportForm/components/RenameColumn'

interface Props {
  isOpen: boolean
  handleClose: () => void
  reorderSettings: ReorderSettingsInterface
  setReorderSettings: React.Dispatch<React.SetStateAction<ReorderSettingsInterface>>
  sortSettings: SortSettingsInterface
  setSortSettings: React.Dispatch<React.SetStateAction<SortSettingsInterface>>
  filterSettings: FilterSettingsInterface
  setFilterSettings: React.Dispatch<React.SetStateAction<FilterSettingsInterface>>
  highlightSettings: ReportHighlightInterface[] | null
  setHighlightSettings: React.Dispatch<
    React.SetStateAction<ReportHighlightInterface[] | null>
  >
  options: RadioSelectOption<QueryColumnInterface>[]
  runId?: number
  queryId?: number
}

enum tabsCategory {
  Filter = 'filter',
  Reorder = 'reorder',
  Sort = 'sort',
  Summarize = 'summarize',
  Colors = 'highlight',
}

const tabs: { title: string; id: tabsCategory; icon: IconName }[] = [
  {
    title: 'Edit Columns',
    id: tabsCategory.Reorder,
    icon: 'Drag',
  },
  {
    title: 'Sort',
    id: tabsCategory.Sort,
    icon: 'ArrowsSort',
  },
  {
    title: 'Filter',
    id: tabsCategory.Filter,
    icon: 'Filter',
  },
  {
    title: 'Highlight',
    id: tabsCategory.Colors,
    icon: 'Palette',
  },
]

export const FormatTableSidebar = ({
  isOpen,
  handleClose,
  reorderSettings,
  setReorderSettings,
  sortSettings,
  setSortSettings,
  filterSettings,
  setFilterSettings,
  highlightSettings,
  setHighlightSettings,
  options,
  runId,
  queryId,
}: Props) => {
  const [currentTabId, setCurrentTabId] = useState<tabsCategory>(tabsCategory.Reorder)
  const [renameItem, setRenameItem] = useState<string | null>(null)

  return (
    <SideBar
      isOpen={isOpen}
      onClose={() => {
        setRenameItem(null)
        if (!renameItem) {
          handleClose()
        }
      }}
      title="Edit columns"
      useLayout
    >
      {renameItem ? (
        <RenameColumn
          columnName={renameItem}
          reorderSettings={reorderSettings}
          setReorderSettings={setReorderSettings}
          onClose={() => setRenameItem(null)}
        />
      ) : (
        <>
          <TabBar mb="s-16" value={currentTabId} variant="segmented">
            {tabs.map(tab => (
              <TabBar.Item
                aria-selected={currentTabId === tab.id}
                key={tab.id}
                onClick={() => setCurrentTabId(tab.id)}
                useIcon={tab.icon}
              >
                {tab.title}
              </TabBar.Item>
            ))}
          </TabBar>
          {currentTabId === tabsCategory.Reorder && (
            <ReorderColumnsSettings
              tableSettings={reorderSettings}
              setTableSettings={setReorderSettings}
              setRenameItem={setRenameItem}
            />
          )}
          {currentTabId === tabsCategory.Sort && (
            <SortColumnsSettings
              tableSettings={sortSettings}
              setTableSettings={setSortSettings}
            />
          )}
          {currentTabId === tabsCategory.Filter && (
            <FilterColumnsSettings
              tableSettings={filterSettings}
              setTableSettings={setFilterSettings}
              visibleColumns={reorderSettings.visible}
              runId={runId}
              queryId={queryId}
            />
          )}
          {currentTabId === tabsCategory.Colors && (
            <Highlight
              options={options}
              highlightSettings={highlightSettings}
              setHighlightSettings={setHighlightSettings}
            />
          )}
        </>
      )}
    </SideBar>
  )
}
