import React from 'react'
import { profileRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import IdentificationForm, { Props } from './General'

const Identification = (props: Props) => {
  return (
    <Form api={profileRequests}>
      <IdentificationForm {...props} />
    </Form>
  )
}

export default Identification
