import { ReportHighlightColor } from '@src/interfaces/dataAnalytics'
import { Token } from '@revolut/ui-kit'

export const valueColorMap: Record<ReportHighlightColor, string> = {
  green: Token.color.green,
  yellow: Token.color.yellow_70,
  orange: Token.color.warning,
  red: Token.color.danger,
}

export const rowColorMap: Record<ReportHighlightColor, string> = {
  green: Token.color.green_10,
  yellow: Token.color.yellow_10,
  orange: Token.color.orange_10,
  red: Token.color.red_10,
}
