import React, { useEffect } from 'react'
import {
  Button,
  Color,
  IconName,
  StatusPopup,
  Token,
  useStatusPopup,
} from '@revolut/ui-kit'

import { Statuses } from '@src/interfaces'
import { LocalStorageKeys } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { OfficeSuiteProviderType } from '@src/api/officeSuiteProvider'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const mapAccountStatusToProps = (
  status?: Statuses,
): {
  statusText: string
  statusIcon: IconName
  color: Color
  variant: ColorTagVariant
} => {
  switch (status) {
    case 'no_account':
      return {
        statusText: 'No account',
        statusIcon: 'Reverted',
        color: Token.color.greyTone50,
        variant: 'neutral',
      }
    case 'pending':
      return {
        statusText: 'Pending action',
        statusIcon: '16/SandWatch',
        color: Token.color.warning,
        variant: 'warning',
      }
    case 'linked':
      return {
        statusText: 'Linked',
        statusIcon: 'Check',
        color: Token.color.success,
        variant: 'success',
      }
    case 'failed':
      return {
        statusText: 'Failed',
        statusIcon: 'Cross',
        color: Token.color.danger,
        variant: 'danger',
      }
    case 'archived':
      return {
        statusText: 'Archived',
        statusIcon: 'Archive',
        color: Token.color.greyTone50,
        variant: 'neutral',
      }
    default:
      return {
        statusText: 'No account',
        statusIcon: 'Reverted',
        color: Token.color.greyTone50,
        variant: 'neutral',
      }
  }
}

export const useShowOfficeSuitePermissionsError = () => {
  const statusPopup = useStatusPopup()

  useEffect(() => {
    const data = workspaceLocalStorage.getItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

    if (data) {
      workspaceLocalStorage.removeItem(LocalStorageKeys.GOOGLE_PERMISSIONS_ERROR)

      let label = 'provider'
      let error

      try {
        const parsed = JSON.parse(data)

        if ('type' in parsed) {
          switch (parsed.type as unknown as OfficeSuiteProviderType) {
            case 'microsoftCalendar':
              label = 'Microsoft Calendar'
              break

            case 'microsoftEmail':
              label = 'Microsoft Email'
              break

            case 'googleEmail':
              label = 'Gmail'
              break

            case 'googleCalendar':
              label = 'Google Calendar'
              break
          }
        }

        if ('error' in parsed && typeof parsed.error === 'string') {
          error = parsed.error
        }
      } catch (e) {
        error = UNKNOWN_ERROR
      }

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Could not link {label} account</StatusPopup.Title>
          {error && <StatusPopup.Description>{error}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Close</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }, [])
}
