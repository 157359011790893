import React from 'react'
import KPIPage from '@src/pages/Forms/TeamForm/KPI/KPI'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { FilterByInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { kpisRequests } from '@src/api/kpis'
import { TeamInterface } from '@src/interfaces/teams'
import { getBaseKpiFilters } from '@src/features/KPI'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'

interface StructureProps {
  data: TeamInterface
}

const getKpiFilters = (
  data: TeamInterface,
  goalsEnabled: boolean,
): FilterByInterface[] => [
  ...getBaseKpiFilters(goalsEnabled),
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'team__id',
    nonResettable: true,
    nonInheritable: true,
  },
]

export const kpiSorting = [
  {
    sortBy: 'weight',
    direction: SORT_DIRECTION.ASC,
    nonResettable: true,
  },
]

const KPI = ({ data }: StructureProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const table = useTable<KpiInterface, Stats>(
    kpisRequests,
    getKpiFilters(data, goalsEnabled),
    kpiSorting,
  )

  return (
    <KPIPage
      data={data}
      table={table}
      warnAboutMandatoryKPIs={!!table?.stats?.mandatory_kpi_incomplete}
    />
  )
}

export default KPI
