import React from 'react'
import { useSelector } from 'react-redux'
import { navigateTo } from '@src/actions/RouterActions'
import { employeeOnboardingTemplatesTableRequests } from '@src/api/employeeOnboardingTemplateRequests'
import {
  employeeOnboardingTemplateActionsColumn,
  employeeOnboardingTemplateCreationDateColumn,
  employeeOnboardingTemplateNameColumn,
} from '@src/constants/columns/employeeOnboardingTemplate'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PageBody } from '@src/components/Page/PageBody'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const Row: RowInterface<EmployeeOnboardingTemplateInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.PREVIEW, { id: data.id }),
    ),
  cells: [
    {
      ...employeeOnboardingTemplateNameColumn,
      width: 400,
    },
    {
      ...employeeOnboardingTemplateCreationDateColumn,
      width: 200,
    },
    {
      ...employeeOnboardingTemplateActionsColumn,
      width: 100,
    },
  ],
}

export const OnboardingTemplates = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.HRManagerPermissions)
  const table = useTable<EmployeeOnboardingTemplateInterface>(
    employeeOnboardingTemplatesTableRequests,
  )

  return (
    <PageBody maxWidth="100%" mt="s-16">
      <Table.Widget>
        <Table.Widget.Actions>
          {canAdd && (
            <Table.Widget.MoreBar>
              <PrimaryAction
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.GENERAL)}
                use={InternalLink}
                useIcon="Plus"
              >
                Create new template
              </PrimaryAction>
            </Table.Widget.MoreBar>
          )}
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.CustomFields}
            noDataMessage="Onboarding templates will appear here."
            row={Row}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
}
