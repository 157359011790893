import { refreshDeliverablesTickets } from '@src/api/deliverables'
import { pushError, pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import React, { useState } from 'react'
import { ActionButton } from '@revolut/ui-kit'

interface Props {
  employeeId: number
}

const RefreshButton = ({ employeeId }: Props) => {
  const [refreshPending, setRefreshPending] = useState(false)

  const refreshTicketsRequest = async () => {
    try {
      setRefreshPending(true)
      const resp = await refreshDeliverablesTickets(employeeId, [], true)

      if (resp.data.accepted) {
        pushNotification({
          value:
            'You request to refresh jira tickets is added to queue and will be executed shortly.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      } else {
        pushNotification({
          value: 'Something went wrong. Please try later.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      }
    } catch (e) {
      pushError({ error: e })
    } finally {
      setRefreshPending(false)
    }
  }

  return (
    <ActionButton pending={refreshPending} onClick={refreshTicketsRequest}>
      Refresh ticket list
    </ActionButton>
  )
}

export default RefreshButton
