import { HierarchyPointLink } from 'd3-hierarchy'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { HierarchyConsts } from '@components/Hierarchy/HierarchyTree'

export const lineBreak = (str: string, maxLength: number): string[] => {
  const strArr = str.split(' ')
  const result: string[] = []

  for (let i = 0; i < strArr.length; i++) {
    if (i === 0 && strArr[i].length > maxLength) {
      result.push(`${strArr[i].substring(0, maxLength - 3)}...`)
      break
    } else if (i === 0) {
      result.push(strArr[i])
    } else if (result.length === 1 && `${result[0]} ${strArr[i]}`.length < maxLength) {
      result[0] = `${result[0]} ${strArr[i]}`
    } else if (result.length === 1) {
      result.push(strArr[i])
    } else if (result.length === 2 && `${result[1]} ${strArr[i]}`.length < maxLength) {
      result[1] = `${result[1]} ${strArr[i]}`
    } else if (result.length === 2) {
      result[1] = `${`${result[1]} ${strArr[i]}`.substring(0, maxLength)}...`
      return result
    }
  }

  return result
}

export const CreateLink = ({
  source,
  target,
}: HierarchyPointLink<HierarchyNodeInterface>) => {
  // Calculate some variables based on source and target coordinates
  const x = source.x
  const y = source.y
  const tx = target.x
  const ty = target.y - HierarchyConsts.cardHeight
  const xrvs = tx - x < 0 ? -1 : 1
  const yrvs = ty - y < 0 ? -1 : 1
  const r = 0
  const h = Math.abs(ty - y) / 2 - r
  const w = Math.abs(tx - x) - r * 2

  return `
               M ${x} ${y}
               L ${x} ${y + h * yrvs}
               C  ${x} ${y + h * yrvs + r * yrvs} ${x} ${y + h * yrvs + r * yrvs} ${
    x + r * xrvs
  } ${y + h * yrvs + r * yrvs}
               L ${x + w * xrvs + r * xrvs} ${y + h * yrvs + r * yrvs}
               C ${tx}  ${y + h * yrvs + r * yrvs} ${tx}  ${
    y + h * yrvs + r * yrvs
  } ${tx} ${ty - h * yrvs}
               L ${tx} ${ty}
             `
}
