import React from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'

export const HiringStagesListSkeleton = () => {
  return (
    <Group>
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
    </Group>
  )
}
