import React from 'react'
import { Cell, Link, Text } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { Grid } from '@components/CommonSC/Grid'
import { CREATING_SKILLS } from '@src/constants/externalLinks'
import { StyledOL } from '@components/CommonSC/General'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

type Props = {
  isOpen?: boolean
  onClose: () => void
}

const Instructions = ({ isOpen, onClose }: Props) => {
  return (
    <SideBar
      title="Instructions"
      isOpen={isOpen}
      onClose={onClose}
      data-testid="instructions_sidebar"
    >
      <Grid flow="row" gap={16}>
        <Cell display="block">
          <Text variant="primary" use="p">
            General
          </Text>
          <Text use="div" mt="s-8" variant="caption">
            <StyledOL>
              <li>
                A skill is one of the competencies needed to fulfil a role. We use skills
                to define a role and assess people during a recruitment process and
                through the performance cycles.
              </li>
              <li>
                Please check if what you need is not already fulfilled by other skills.
                Check roles belonging to same function and what skills they use to compare{' '}
              </li>
              <HideIfCommercial>
                <li>
                  If you find a skill that is close to what you need, copy it and make
                  required adjustments according to the guideline below (e.g, Data Science
                  and Data Science for NLP)
                </li>
                <li>
                  Check the{' '}
                  <Link target="_blank" href={CREATING_SKILLS}>
                    confluence guide here
                  </Link>
                </li>
              </HideIfCommercial>{' '}
            </StyledOL>
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            How skill mastery is used:
          </Text>
          <Text use="div" mt="s-8" variant="caption">
            <StyledOL>
              <li>Hiring</li>
              <StyledOL type="a">
                <li>Identifying the right talent when hiring for a role</li>
                <li>Setting expectations for what an employee will achieve</li>
              </StyledOL>
              <li>Career progression and reward</li>
              <StyledOL type="a">
                <li>Assessing the skills of current employees</li>
                <li>Offering a progression path for promotions internally</li>
                <li>Recognising and rewarding employees for working hard to improve</li>
              </StyledOL>
              <li>Performance management and exit</li>
              <StyledOL type="a">
                <li>
                  Identifying employees who do not meet expectations for their role and
                  seniority
                </li>
                <li>Defining next steps for employees who continue to underperform</li>
              </StyledOL>
            </StyledOL>
          </Text>
        </Cell>
      </Grid>
    </SideBar>
  )
}

export default Instructions
