import React from 'react'
import { formatMoney } from '@src/utils/format'
import { Text } from '@revolut/ui-kit'
import { TextProps } from '@revolut/ui-kit'

interface Props extends TextProps {
  localLowerBand: number
  localUpperBand: number
  localCurrencyCode: string
  lowerBand: number
  upperBand: number
  currencyCode: string
}

const BenchmarkBand = ({
  localLowerBand,
  localUpperBand,
  localCurrencyCode,
  lowerBand,
  upperBand,
  currencyCode,
  ...props
}: Props) => {
  return (
    <Text {...props}>
      {formatMoney(localLowerBand, localCurrencyCode)}
      {' - '}
      {formatMoney(localUpperBand, localCurrencyCode)}
      {lowerBand &&
        upperBand &&
        ` (${formatMoney(lowerBand, currencyCode)} - ${formatMoney(
          upperBand,
          currencyCode,
        )})`}
    </Text>
  )
}

export default BenchmarkBand
