import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { KeeperGrade, ManagerRecommendationInterface } from '@src/interfaces/performance'
import { RecommendationExtraSectionQuestionInterface } from './BarRaiserSummaryCollapsable'
import { uniqBy } from 'lodash'

type AnswerEntity = {
  answer?: KeeperGrade | null
  checkpoint?: number
  reviewer?: EmployeeOptionInterface
  amount: number
}

interface AgregatedAnswersWithFilters {
  groupedAnswers: Record<string, AnswerEntity>
  answersArray: AnswerEntity[]
  reviewers: EmployeeOptionInterface[]
}

export const filteredAnswersArrayByAnswer = (
  array: AnswerEntity[],
  targetAnswer: string,
) => {
  return array.filter(({ answer }) => answer === targetAnswer)
}

export const agregatedQuestionsAndCheckpointsForSingleFilter = (
  lmReviewAnswers: ManagerRecommendationInterface[] | undefined,
  data: RecommendationExtraSectionQuestionInterface,
) => {
  const agregatedQuestionsAndCheckpoints = lmReviewAnswers?.reduce<
    Record<
      string,
      {
        answer?: KeeperGrade | null
        checkpoint?: number
        reviewer?: EmployeeOptionInterface
      }[]
    >
  >((acc, item) => {
    const targetQuestion = item.recommendation?.find(({ title }) => title === data.title)
    const questionEntity = {
      answer: targetQuestion?.value,
      checkpoint: item.checkpoint_number,
      reviewer: item.reviewer,
    }
    if (data.title && acc[data.title]) {
      acc[data.title] = [...acc[data.title], questionEntity]
    } else if (data.title) {
      acc[data.title] = [questionEntity]
    }
    return acc
  }, {})

  const answersArray = data.title ? agregatedQuestionsAndCheckpoints?.[data.title] : []
  return answersArray
}

export const agregatedQuestionsAndCheckpointsForMultipleFilters = (
  lmReviewAnswers: ManagerRecommendationInterface[] | undefined,
  data: RecommendationExtraSectionQuestionInterface,
) => {
  const initialAgregatedState = {
    groupedAnswers: {},
    answersArray: [],
    reviewers: [],
  }
  const agregatedQuestionsAndCheckpoints =
    lmReviewAnswers?.reduce<AgregatedAnswersWithFilters>((acc, item) => {
      const targetQuestion = item.recommendation?.find(
        ({ title }) => title === data.title,
      )
      const questionEntity = {
        answer: targetQuestion?.value,
        checkpoint: item.checkpoint_number,
        reviewer: item.reviewer,
        amount: 1,
      }
      item.reviewer && acc.reviewers.push(item.reviewer)
      acc.answersArray.push(questionEntity)
      if (questionEntity?.answer && acc.groupedAnswers[questionEntity.answer]) {
        acc.groupedAnswers[questionEntity.answer] = {
          ...acc.groupedAnswers[questionEntity.answer],
          amount: acc.groupedAnswers[questionEntity.answer].amount + 1,
        }
      } else if (questionEntity?.answer) {
        acc.groupedAnswers[questionEntity.answer] = questionEntity
      }
      return acc
    }, initialAgregatedState) || initialAgregatedState

  const groupedAnswersArray = Object.values(
    agregatedQuestionsAndCheckpoints.groupedAnswers,
  )
  const answersArray = agregatedQuestionsAndCheckpoints.answersArray
  const reviewers = uniqBy(agregatedQuestionsAndCheckpoints.reviewers, 'id')
  return { groupedAnswersArray, answersArray, reviewers }
}
