import React from 'react'
import { Text, VStack, Avatar, HStack, abbreviate, Token, chain } from '@revolut/ui-kit'

import StyledLink from '@components/Atoms/StyledLink'
import { getLocationDescriptor } from '@src/actions/RouterActions'

export interface UserCardProps {
  avatar?: string
  displayName: string
  position: string
  userLink?: ReturnType<typeof getLocationDescriptor>
}

export const UserCard = (props: UserCardProps) => {
  const { avatar, displayName, position, userLink } = props

  return (
    <HStack space="s-12" align="center">
      <Avatar size={40} image={avatar} alignSelf="center">
        {abbreviate(displayName)}
      </Avatar>
      <VStack>
        {userLink ? (
          <StyledLink to={userLink}>
            <Text variant="h5">{displayName}</Text>
          </StyledLink>
        ) : (
          <Text variant="h5">{displayName}</Text>
        )}
        <Text variant="small" color={Token.color.greyTone50}>
          {position}
        </Text>
      </VStack>
    </HStack>
  )
}

export const UserCardOneRow = (props: UserCardProps) => {
  const { avatar, displayName, position, userLink } = props

  const disaplayName = userLink ? (
    <StyledLink to={userLink} target="_blank">
      <Text variant="primary" color={Token.color.blue}>
        {displayName}
      </Text>
    </StyledLink>
  ) : (
    <Text variant="primary">{displayName}</Text>
  )

  const title = chain([
    disaplayName,
    <Text variant="primary" key={position}>
      {position}
    </Text>,
  ])

  return (
    <HStack space="s-8" align="center">
      <Avatar size={24} image={avatar} alignSelf="center">
        {abbreviate(displayName)}
      </Avatar>
      <Text color={Token.color.greyTone20}>{title}</Text>
    </HStack>
  )
}
