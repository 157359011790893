import React from 'react'

import { PageBody } from '@src/components/Page/PageBody'
import { getBenefitChangelog } from '@src/api/benefits'
import { useEmployeeBenefitsParams } from './common'
import { CellTypes, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  changelogChangedByWithAvatar,
  changelogEffectiveDate,
  changelogFieldsChangedFields,
  changelogFieldsNewValue,
} from '@src/constants/columns/changelog'
import { useTable } from '@src/components/Table/hooks'
import { TableNames } from '@src/constants/table'

const row = {
  cells: [
    {
      ...changelogFieldsChangedFields,
      filterKey: null,
      sortKey: null,
      width: 100,
    },
    {
      ...changelogFieldsNewValue,
      filterKey: null,
      sortKey: null,
      width: 200,
    },
    {
      ...changelogChangedByWithAvatar,
      width: 150,
    },
    {
      ...changelogEffectiveDate,
      title: 'Changed on',
      type: CellTypes.dateTime,
      width: 120,
    },
  ],
}

export const Changelog = () => {
  const params = useEmployeeBenefitsParams()
  const table = useTable(
    { getItems: getBenefitChangelog(params.id, params.employeeId) },
    undefined,
    [
      {
        sortBy: 'effective_date_time',
        direction: SORT_DIRECTION.ASC,
        nonResettable: true,
      },
    ],
  )

  return (
    <PageBody>
      <AdjustableTable
        name={TableNames.EmployeeBenefitChangelog}
        row={row}
        {...table}
        useWindowScroll
      />
    </PageBody>
  )
}
