import React from 'react'
import { StatusPopup } from '@revolut/ui-kit'

const SavingKPIPopup = ({
  pending,
  isRoadmap = false,
}: {
  pending: boolean
  isRoadmap?: boolean
}) => {
  return (
    <StatusPopup variant="loading" open={pending}>
      <StatusPopup.Title>Saving KPI changes...</StatusPopup.Title>
      <StatusPopup.Description>
        Once saved you'll be sent to {isRoadmap ? 'deliverables' : 'the target'} setting
      </StatusPopup.Description>
    </StatusPopup>
  )
}

export default SavingKPIPopup
