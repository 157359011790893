import React from 'react'
import { Box, InputGroup, Side, VStack, Input } from '@revolut/ui-kit'
import { connect } from 'lape'
import SideBar from '@components/SideBar/SideBar'
import { usePayCycles, usePaygroups } from '@src/api/payroll'
import {
  PayCycleInterface,
  PaygroupInterface,
  PayrollElementInterface,
} from '@src/interfaces/payroll'
import { selectorKeys } from '@src/constants/api'
import { formatPeriod } from '@src/utils/format'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type Props = {
  onExit: () => void
  onSuccess: () => void
  isOpen: boolean
}

type SidebarState = {
  id: undefined
  paygroup: PaygroupInterface | undefined
  amount: string
  notes: string
  pay_cycle_id: PayCycleInterface | undefined
  payroll_element_id: PayrollElementInterface | undefined
  currency: string | undefined
  entity_id: number | undefined
  employee_id: number | undefined
}

const AddChargeSidebar = ({ onExit, isOpen, onSuccess }: Props) => {
  const { values, reset } = useLapeContext<SidebarState>()

  const { data: paygroups, isLoading: isLoadingPaygroups } = usePaygroups()
  const { data: paycycles, isLoading: isLoadingPaycycles } = usePayCycles(
    values.paygroup?.id.toString(),
  )

  const resetValues = () => {
    reset({
      id: undefined,
      paygroup: undefined,
      amount: '',
      notes: '',
      currency: undefined,
      entity_id: undefined,
      employee_id: undefined,
      pay_cycle_id: undefined,
      payroll_element_id: undefined,
    })
  }

  return (
    <SideBar isOpen={isOpen} onClose={onExit} title="Add charge manually">
      <VStack gap="s-16">
        <Box>
          <InputGroup>
            <LapeRadioSelectInput
              label="Employee"
              name="employee_id"
              value={values.employee_id ? { id: values.employee_id } : null}
              onChange={option => {
                values.employee_id = option?.id
              }}
              selector={selectorKeys.employee}
              required={false}
            />
            <LapeRadioSelectInput
              label="Paygroup"
              name="paygroup"
              onChange={option => {
                values.paygroup = option || undefined
                values.currency = option?.country.national_currency.iso_code
                values.entity_id = option?.company_entity.id
                values.pay_cycle_id = undefined
                values.payroll_element_id = undefined
              }}
              options={
                paygroups?.results.map(option => ({
                  value: option,
                  label: option.name,
                })) || []
              }
              loading={isLoadingPaygroups}
            />
            <LapeRadioSelectInput
              label="Cycle"
              name="pay_cycle_id"
              disabled={!values.paygroup}
              onChange={option => {
                values.pay_cycle_id =
                  (option?.id as unknown as PayCycleInterface) || undefined
                values.payroll_element_id = undefined
              }}
              value={
                values.pay_cycle_id
                  ? ({ id: values.pay_cycle_id } as unknown as PayCycleInterface)
                  : null
              }
              options={
                paycycles?.results.map(option => ({
                  value: {
                    ...option,
                    name: formatPeriod(
                      new Date(option.start_date),
                      new Date(option.end_date),
                    ),
                  },
                  label: formatPeriod(
                    new Date(option.start_date),
                    new Date(option.end_date),
                  ),
                })) || []
              }
              loading={isLoadingPaycycles}
            />
            <LapeRadioSelectInput
              label="Element"
              name="payroll_element_id"
              disabled={!values.pay_cycle_id}
              value={
                values.payroll_element_id
                  ? ({
                      id: values.payroll_element_id,
                    } as unknown as PayrollElementInterface)
                  : null
              }
              onChange={option => {
                values.payroll_element_id =
                  (option?.id as unknown as PayrollElementInterface) || undefined
              }}
              options={
                values.paygroup?.payroll_elements.map(option => ({
                  value: option,
                  label: option.name,
                })) || []
              }
            />

            <InputGroup variant="horizontal">
              <LapeNewInput name="amount" label="Amount" required />
              <Input width="30%" disabled value={`${values.currency || '-'}`} />
            </InputGroup>

            <LapeNewTextArea name="notes" rows={4} label="Notes" />
          </InputGroup>
        </Box>
      </VStack>
      <Side.Actions>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            resetValues()
            onSuccess()
            onExit()
          }}
        />
      </Side.Actions>
    </SideBar>
  )
}

export default connect(AddChargeSidebar)
