import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  InterviewRoundSummaryRatingInterface,
  InterviewRoundSummaryRatingNormalizedInterface,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import { Flex, Text } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import React from 'react'
import { ratingToColor } from '@src/utils/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'

export const interviewCompetencyMatrixCriteriaColumn: ColumnInterface<
  InterviewRoundSummaryRatingNormalizedInterface | InterviewRoundSummaryRatingInterface
> = {
  title: 'Criteria',
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
}

export const interviewCompetencyMatrixExpectationColumn: ColumnInterface<
  InterviewRoundSummaryRatingNormalizedInterface | InterviewRoundSummaryRatingInterface
> = {
  title: 'vs expectation',
  type: CellTypes.insert,
  idPoint: 'interviewer_ratings',
  dataPoint: 'interviewer_ratings',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
}

export const interviewCompetencyMatrixInterviewerColumn: ColumnInterface<
  InterviewRoundSummaryRatingNormalizedInterface | InterviewRoundSummaryRatingInterface
> = {
  title: 'Interviewer',
  type: CellTypes.insert,
  idPoint: 'interviewer_ratings',
  dataPoint: 'interviewer_ratings',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
  insert: ({ data }) => {
    if ('children' in data) {
      return ''
    }

    return (
      <Flex>
        {data.interviewer_ratings.map(item => (
          <UserWithAvatar
            key={item.interviewer.id}
            avatar={item.interviewer.avatar}
            full_name={item.interviewer.full_name}
            id={item.interviewer.id}
            mr="s-8"
            compact
          />
        ))}
      </Flex>
    )
  },
}

export const interviewCompetencyMatrixRatingColumn: ColumnInterface<
  InterviewRoundSummaryRatingNormalizedInterface | InterviewRoundSummaryRatingInterface
> = {
  title: 'Rating',
  type: CellTypes.insert,
  idPoint: 'average_rating',
  dataPoint: 'average_rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
  background: data => {
    if ('children' in data) {
      return 'inherit'
    }
    return ratingToColor(data.average_rating) || 'inherit'
  },
  insert: ({ data }) => {
    if ('children' in data) {
      return ''
    }
    return data.average_rating ? (
      <>
        {PerformanceRatingTitle[data.average_rating]}
        {data.interviewer_ratings.length > 1 && (
          <Text color="grey-tone-50"> · {data.interviewer_ratings.length}</Text>
        )}
      </>
    ) : (
      ''
    )
  },
}
