import { useCompanyCompensationYearOptions } from '@src/api/compensation'
import { selectorKeys } from '@src/constants/api'
import { useMemo } from 'react'

export const companyCurrencySelector = selectorKeys.company_compensation_review_currencies

export const defaultCurrency = 'USD'

export const useReviewData = (
  currencyId: number | null,
  selectedYear?: number | null,
) => {
  const yearOptions = useCompanyCompensationYearOptions(currencyId)

  const selectedCompensationReview = yearOptions.data?.results.find(
    opt => opt.id === selectedYear,
  )
  const modifiedCompensationReview = !yearOptions.isFetching &&
    selectedCompensationReview && {
      ...selectedCompensationReview,
      salary: currencyId
        ? selectedCompensationReview.exchanged?.total_salary_budget
        : selectedCompensationReview.total_salary_budget_usd,
      bonus: currencyId
        ? selectedCompensationReview.exchanged?.total_bonus_budget
        : selectedCompensationReview.total_bonus_budget_usd,
    }

  return useMemo(
    () => ({
      yearOptions,
      selectedCompensationReview,
      modifiedCompensationReview,
    }),
    [yearOptions, selectedCompensationReview, modifiedCompensationReview],
  )
}
