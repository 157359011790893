import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import capitalize from 'lodash/capitalize'
import { chain, HStack, Text, Token } from '@revolut/ui-kit'
import { Warning } from '@revolut/icons'
import { reportRequestsNew } from '@src/api/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { formatDateTime } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { DetailsForm } from './DetailsForm'
import { PreviewForm } from './PreviewForm'
import { reportStatusToColor } from './utils'

const Report = connect(() => {
  const { values } = useLapeContext<ReportInterface>()
  const params = useParams<{ id: string }>()

  const tabs = [
    {
      title: 'Data Preview',
      path: ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.PREVIEW, params),
      component: PreviewForm,
    },
    {
      title: 'Report Details',
      path: ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.DETAILS,
      to: pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.DETAILS, params),
      component: DetailsForm,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.APPS.DATA_ANALYTICS.REPORTS}
        title={values.name}
        subtitle={
          <>
            {chain(
              <Text color={reportStatusToColor(values.status)}>
                {capitalize(values.status)}
              </Text>,
              <Text>{`Edited ${formatDateTime(values.update_date_time)}`}</Text>,
            )}
            {values.query.status === 'outdated' && (
              <HStack align="center" color={Token.color.warning} gap="s-8" mt="s-4">
                <Warning />
                <Text>Outdated Query: Please refresh data source</Text>
              </HStack>
            )}
          </>
        }
      >
        <TabBarNavigation mb="s-16" tabs={tabs} />
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.DETAILS}>
          <DetailsForm />
        </Route>
        <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_REPORT.PREVIEW}>
          <PreviewForm />
        </Route>
      </Switch>
    </PageWrapper>
  )
})

const ReportViewForm = () => {
  return (
    <Form api={reportRequestsNew}>
      <Report />
    </Form>
  )
}

export default connect(ReportViewForm)
