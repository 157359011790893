import React from 'react'
import styled from 'styled-components'
import { Flex } from '@revolut/ui-kit'

import { Status } from '@components/CommonSC/General'

import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { Avatar } from '@revolut/ui-kit'
import { getInitials, getAvatarUrl } from '@src/utils/employees'

/** @deprecated use UI Kit chain */
export const Dot = styled.div`
  display: inline-block;
  padding: 0 4px;
`

export const InlineStatus = styled(Status)`
  display: inline-flex;
  align-items: center;
  text-transform: none;
`

interface AnytimeFeedbackFormSubtitleProps {
  data: EmployeeInterface | EmployeeOptionInterface
}

export const AnytimeFeedbackFormSubtitle = ({
  data,
}: AnytimeFeedbackFormSubtitleProps) => {
  let userInitials = ''
  if (data.full_name) {
    userInitials = getInitials(data.full_name)
  }

  return (
    <Flex flex={1} height={24} alignItems="center" gap="s-8">
      {data.full_name && data.id && (
        <Avatar image={getAvatarUrl(data.avatar)} size={24}>
          {userInitials}
        </Avatar>
      )}
      {data.full_name ? data.full_name : `New Employee`}
    </Flex>
  )
}
