import React, { useEffect } from 'react'
import { VStack, Flex } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { connect } from 'lape'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanyPreferencesInterface } from '@src/interfaces/settings'
import Form from '@src/features/Form/Form'
import { companyPreferencesWithInvalidation } from '@src/api/settings'
import { useQueryClient } from 'react-query'
import OnboardingActions from '../components/OnboardingActions'
import { rolesConfig } from '../common/constants'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'

const RolesSettings = () => {
  const { values } = useLapeContext<CompanyPreferencesInterface>()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)

  useEffect(() => {
    if (values.enable_multi_specialisations == null) {
      values.enable_multi_specialisations = false
    }
  }, [])

  return (
    <>
      <PageBody>
        <VStack gap="s-24">
          <VStack gap="s-16">
            <SectionTitle title="Do you want to have multiple Specialisation within one Role?" />

            <LapeBooleanRadioSwitch
              name="enable_multi_specialisations"
              variant="horizontal"
              disabled={disableEdit}
              noLabel={
                <Flex>
                  No
                  <Recommended />
                </Flex>
              }
              yesDescription="I want to be able to assign multiple specialisations to a single role, for example, Product Designer, Visual Designer"
              noDescription="I do not want to assign multiple specialisations at the moment, will decide later"
            />
          </VStack>
        </VStack>
      </PageBody>

      <OnboardingActions
        currentStep="Settings"
        config={rolesConfig}
        isLastStep={false}
        disableBack={false}
        pendingBack={false}
        disableNext={false}
        pendingNext={false}
        nextRoute={
          values.enable_functions
            ? ROUTES.ONBOARDING_CHECKLIST.ROLES.FUNCTIONS
            : ROUTES.ONBOARDING_CHECKLIST.ROLES.ROLES
        }
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.ROLES.APPROVALS}
        updateSteps
        isForm
      />
    </>
  )
}

export default connect(() => {
  const queryClient = useQueryClient()

  return (
    <Form api={companyPreferencesWithInvalidation(queryClient)} forceParams={{ id: '1' }}>
      <RolesSettings />
    </Form>
  )
})
