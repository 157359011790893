import React, { useMemo } from 'react'
import {
  ReviewCyclesInterface,
  ReviewCyclesPreviewInterface,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'
import { Text } from '@revolut/ui-kit'
import { KpiReviewCycle, KpiReviewEmployeeCycle } from '@src/interfaces/kpis'
import { getReviewCycleStatusColor } from '@src/utils/reviewCycles'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PerformanceSelector } from '@src/interfaces/performance'

export interface CycleSelectorProps {
  reviewCycles:
    | ReviewCyclesInterface[]
    | PerformanceSelector[]
    | ReviewCyclesPreviewInterface[]
  value?:
    | ReviewCyclesInterface
    | PerformanceSelector
    | KpiReviewCycle
    | KpiReviewEmployeeCycle
    | ReviewCyclesPreviewInterface
  onSelect: (cycle: ReviewCyclesInterface) => void
  disabled?: boolean
  message?: string
  hasError?: boolean
}

export const cycleStatusText = {
  [ReviewCycleStatus.closed]: 'Past cycle',
  [ReviewCycleStatus.ongoing]: 'Ongoing cycle',
  [ReviewCycleStatus.planned]: 'Future cycle',
}

const CycleSelector = ({
  onSelect,
  reviewCycles,
  value,
  disabled,
  ...selectProps
}: CycleSelectorProps) => {
  const options = useMemo(() => {
    return reviewCycles.map(cycle => ({ value: cycle, label: cycle.name }))
  }, [reviewCycles])

  return (
    <RadioSelectInput
      label="Review cycle"
      options={options}
      value={value}
      disabled={disabled}
      onChange={cycle => {
        if (cycle) {
          onSelect(cycle as ReviewCyclesInterface)
        }
      }}
      {...selectProps}
    >
      {option => (
        <>
          {option.value.name}
          {'status' in option.value && option.value.status && (
            <Text ml="1em" color={getReviewCycleStatusColor(option.value.status)}>
              {option.value.status}
            </Text>
          )}
        </>
      )}
    </RadioSelectInput>
  )
}

export default CycleSelector
