import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import isArray from 'lodash/isArray'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import { uploadPayrollCyclePayments } from '@src/api/importData'
import { navigateTo } from '@src/actions/RouterActions'

export const UploadPayments = () => {
  const { id } = useParams<{ id: string }>()
  const [importPending, setImportPending] = useState(false)

  const onFileUpload = (file: File) => {
    setImportPending(true)

    uploadPayrollCyclePayments(id, file)
      .then(response => {
        navigateTo(
          pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REVIEW, {
            id,
            sessionId: response.data.id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    // TODO: add template when provided by BE
    window.open('about:blank', '_blank')
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Import data"
        subtitle="Payments"
        backUrl={ROUTES.APPS.COMPENSATION.BONUSES}
      />
      <PageBody>
        <ImportFile
          importPending={importPending}
          onDownloadTemplate={onDownloadTemplate}
          supportedFormats={['CSV']}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </PageBody>
    </PageWrapper>
  )
}
