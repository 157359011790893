import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGoalsStats } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Details/GoalsStats/useGoalsStats'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import pluralize from 'pluralize'
import {
  makeDescriptionText,
  Progress,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/components/Progress/Progress'
import { PortionLabel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/components/Progress/PortionLabel'
import {
  goalsOwnerToEntityNameMap,
  makeGoalsProgressText,
  makeMissingEntitiesText,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'

const makePortionText = (name: string, dividend: number, divisor: number): string =>
  `${pluralize(name, divisor)} ${pluralize('have', dividend)} provided goals`

interface Props {
  cycle: ReviewCyclesInterface
  goalsOwner: GoalsOwner
}

export const GoalsStats = ({ cycle, goalsOwner }: Props) => {
  const { totalCount, withGoalsCount, isLoading, isError } = useGoalsStats(
    cycle,
    goalsOwner,
  )
  const withGoalsPortion = withGoalsCount / totalCount

  const entityName = goalsOwnerToEntityNameMap[goalsOwner]
  const title = makeGoalsProgressText(goalsOwner)
  const descriptionText = makeDescriptionText(withGoalsCount, totalCount)
  const portionText = makePortionText(entityName, withGoalsCount, totalCount)
  const emptyPortionText = makeMissingEntitiesText(goalsOwner)

  if (isError) {
    return null
  }

  return (
    <Progress
      value={withGoalsPortion}
      title={title}
      description={descriptionText}
      isLoading={isLoading}
      label={
        <PortionLabel
          dividend={withGoalsCount}
          divisor={totalCount}
          separator=" of "
          description={portionText}
          emptyDescription={emptyPortionText}
          highlight
        />
      }
    />
  )
}
