import React from 'react'
import { notificationsPipRequests } from '@src/api/notifications'
import { selectorKeys } from '@src/constants/api'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeeColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { CellTypes, RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'

const PipReviews = ({
  employeeId,
  filterBy,
  refreshStats,
  tableRef,
  type,
}: TabComponentProps) => {
  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 400,
      },
      {
        type: CellTypes.insert,
        idPoint: 'reviewer_relation',
        dataPoint: 'reviewer_relation',
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.notification_reviewer_relation,
        insert: ({ data }) => {
          switch (data.reviewer_relation) {
            case 'committee':
              return 'Committee'
            case 'line_manager':
              return 'Direct report review'
            case 'self':
              return 'Self review'
            case 'team_mate':
              return 'Additional review'
            default:
              return 'Other review'
          }
        },
        title: 'Relation',
        width: 140,
      },
      {
        ...notificationsEmployeeColumn,
        width: 210,
      },
      {
        ...notificationsReceivedColumn,
        width: 130,
      },
      {
        ...notificationsDeadline,
        width: 130,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 160,
      },
    ],
  }

  return (
    <GenericNotificationTable
      filterBy={filterBy}
      refreshStats={refreshStats}
      request={notificationsPipRequests(employeeId)}
      rows={RowNotifications}
      tableRef={tableRef}
      type={type}
    />
  )
}

export default PipReviews
