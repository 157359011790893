import React from 'react'
import { Button, HStack } from '@revolut/ui-kit'

import { TableNames } from '@src/constants/table'
import { ApiVersion } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { BulkDataImportSessionV2 } from '@src/features/BulkDataImportV2/BulkDataImportSessionV2'
import {
  CommonGenericEditableTableEntity,
  CommonGenericEditableTableProps,
  CommonGenericEditableTableRowOptions,
  TableActionsProps,
} from '@src/features/GenericEditableTable/GenericEditableTable'

export type UploadSessionV2Props<T> = {
  apiEndpoint: string
  apiVersion?: ApiVersion
  previousRoute: string
  tableName: TableNames
  row: (options: CommonGenericEditableTableRowOptions) => RowInterface<ImportInterface<T>>
  entity: CommonGenericEditableTableEntity
  tableActions?: (props: TableActionsProps<T>) => React.ReactNode
  hiddenColumns?: Record<string, boolean>
  onAfterConfirmRoute?: string
  ignoreQueryParams?: string[]
  localStorageKey?: string
} & Pick<
  CommonGenericEditableTableProps<T>,
  'getCustomEditCellAction' | 'onActionColumnPreview' | 'table' | 'refreshTableHandler'
>

export const UploadSessionV2 = <T,>({
  row,
  apiEndpoint,
  apiVersion,
  tableName,
  table,
  previousRoute,
  entity,
  tableActions,
  hiddenColumns,
  onAfterConfirmRoute,
  ignoreQueryParams,
  getCustomEditCellAction,
  onActionColumnPreview,
  localStorageKey,
  refreshTableHandler,
}: UploadSessionV2Props<T>) => {
  return (
    <BulkDataImportSessionV2
      row={row}
      apiEndpoint={apiEndpoint}
      apiVersion={apiVersion}
      entity={entity}
      variant="temporaryEntities"
      tableName={tableName}
      table={table}
      tableActions={tableActions}
      hiddenColumns={hiddenColumns}
      ignoreQueryParams={ignoreQueryParams}
      getCustomEditCellAction={getCustomEditCellAction}
      onActionColumnPreview={onActionColumnPreview}
      refreshTableHandler={refreshTableHandler}
      actions={({ onSubmit, submitPending, sessionData, disableActions, empty }) => {
        if (sessionData.state.id === 'success' && localStorageKey) {
          localStorage.removeItem(localStorageKey)
        }
        return sessionData.state.id === 'ready_for_review' ||
          sessionData.state.id === 'failure' ||
          sessionData.state.id === 'applying' ? (
          <PageActions>
            <HStack gap="s-8" maxWidth={375}>
              <Button
                variant="secondary"
                onClick={() => {
                  goBack(pathToUrl(previousRoute))
                }}
              >
                Cancel
              </Button>
              {!empty && (
                <Button
                  onClick={onSubmit}
                  pending={submitPending}
                  disabled={disableActions || sessionData.state.id === 'applying'}
                  elevated
                >
                  Apply changes
                </Button>
              )}
            </HStack>
          </PageActions>
        ) : (
          <PageActions>
            <Button
              onClick={() => {
                onAfterConfirmRoute
                  ? navigateTo(pathToUrl(onAfterConfirmRoute))
                  : goBack(pathToUrl(previousRoute))
              }}
              elevated
            >
              Confirm
            </Button>
          </PageActions>
        )
      }}
    />
  )
}
