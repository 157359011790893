import React from 'react'
import { Action, StatusWidget } from '@revolut/ui-kit'
import { ERRORS } from '@src/constants/notifications'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'

type Props = {
  id?: number
  isContractError?: boolean
  title?: string
}

export const ErrorWidget = ({
  id,
  isContractError,
  title = ERRORS.UNKNOWN_REFRESH,
}: Props) => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D274.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D274@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D274@3x.png',
        }}
      />
      <StatusWidget.Title>{title}</StatusWidget.Title>
      {id && isContractError && (
        <StatusWidget.Actions
          style={{ textDecoration: 'none' }}
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, { id })}
          use={InternalLink}
        >
          <Action>Go to contracts</Action>
        </StatusWidget.Actions>
      )}
    </StatusWidget>
  )
}
