import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  Box,
  Flex,
  SelectOptionItemType,
  SelectLoadingState,
  Icon,
} from '@revolut/ui-kit'

import { getIconKeyByIssue } from '@src/utils/performance'
import debounce from 'lodash/debounce'
import { OptionInterface } from '@src/interfaces/selectors'
import { connect } from 'lape'
import { JiraIssueInterface } from '@src/interfaces/probationReview'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

const Wrap = styled.div`
  width: 100%;
`

type Props<T, S> = {
  onChange: (option?: T) => void
  api: (term: string) => Promise<{ issues?: T[]; cancelPrevQuery?: boolean }>
  message?: React.ReactNode
  onFilter?: (issue: T) => boolean
  disabled?: boolean
  placeholder?: string
  data: S[]
}

const FetchingDropdown = <T extends JiraIssueInterface, S extends {}>({
  onChange,
  api,
  message,
  onFilter,
  disabled,
  placeholder,
  data,
}: Props<T, S>) => {
  const [options, setOptions] = useState<T[]>([])
  const [value, setValue] = useState('')
  const [searchState, setSearchState] = useState<SelectLoadingState>('ready')

  useEffect(() => {
    // TODO: UI kit RadioSelect search is disabled if initial options are empty. Remove this when UI kit fixes this.
    onSearch('PERF')
  }, [])

  const onSearch = async (val: string) => {
    setSearchState('ready')

    if (val.length < 4) {
      return
    }

    setSearchState('pending')

    try {
      const { issues, cancelPrevQuery } = await api(val)

      if (cancelPrevQuery || !issues) {
        return
      }

      let filteredIssues = issues

      if (onFilter) {
        filteredIssues = issues.filter(onFilter)
      }

      setOptions(filteredIssues)
      setSearchState('ready')
    } catch (err) {
      setSearchState('failed')
    }
  }

  const handleChange = (option: OptionInterface | null) => {
    setValue('')
    onChange(option ? options.find(item => +item.id === +option.id) : undefined)
  }

  const debouncedSearch = useCallback(
    debounce(onSearch, 500, { trailing: true, leading: false }),
    [data],
  )

  const onInputValueChange = (e: FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
    debouncedSearch(e.currentTarget.value)
  }

  const normalizedOptions = useMemo(() => {
    return options.map(issue => ({
      label: `${issue.key}: ${issue.fields.summary}`,
      value: {
        id: +issue.id,
        name: `${issue.key}: ${issue.fields.summary}`,
        prefix: (
          <Icon
            name={getIconKeyByIssue(issue.fields.issuetype.name)}
            size="small"
            mr="s-8"
          />
        ),
      },
    }))
  }, [options])

  const renderOption = (item: SelectOptionItemType<OptionInterface>) => {
    return (
      <Flex>
        {item.value.prefix}
        <Box flex="1 1 auto">{item.label}</Box>
      </Flex>
    )
  }

  return (
    <Wrap>
      <RadioSelectInput<OptionInterface>
        label={placeholder}
        loading={searchState === 'pending'}
        options={normalizedOptions}
        onChange={handleChange}
        inputProps={{
          onChange: onInputValueChange,
          value,
        }}
        message={message}
        disabled={disabled}
        data-testid="search"
        clearable={false}
        onSearchText={onSearch}
        labelSearch="Start typing..."
        searchState={searchState}
      >
        {renderOption}
      </RadioSelectInput>
    </Wrap>
  )
}

export default connect(FetchingDropdown)
