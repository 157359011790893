import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { HStack, Input, SelectOptionItemType, Text } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'

type MakeFieldsOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>
export type ReviewCycle = MakeFieldsOptional<
  Pick<ReviewCyclesInterface, 'name' | 'offset' | 'status' | 'id' | 'category'>,
  'offset'
>

export interface CycleSelectorProps {
  reviewCycles: ReviewCycle[]
  value?: ReviewCycle
  onSelect: (cycle: ReviewCycle) => void
  disabled?: boolean
  message?: string
  hasError?: boolean
  children?: (cycle: SelectOptionItemType<ReviewCycle>) => ReactNode
}

export const renderCycleName = (cycle?: ReviewCycle) => {
  const { color, title } = getCycleOffsetTitleAndColor(cycle?.offset)
  return cycle ? (
    <HStack space="s-16">
      <Text>{cycle?.name}</Text>
      {cycle.status && (
        <Text ml="1em" color={color}>
          {title}
        </Text>
      )}
    </HStack>
  ) : undefined
}

const CycleSelector = ({
  onSelect,
  reviewCycles,
  value,
  disabled,
  children,
  ...selectProps
}: CycleSelectorProps) => {
  const options = useMemo(() => {
    return reviewCycles.map(cycle => ({
      value: cycle,
      label: cycle.name,
    }))
  }, [reviewCycles])
  const [selectedCycle, setSelectedCycle] = useState(value)
  const { title } = getCycleOffsetTitleAndColor(selectedCycle?.offset)

  useEffect(() => {
    setSelectedCycle(value)
  }, [value])

  return (
    <RadioSelectInput<ReviewCycle>
      renderInput={(open, setOpen, anchorRef) => (
        <Input
          label="Review cycle"
          containerRef={anchorRef}
          type="button"
          useIcon="ChevronDown"
          onClick={() => setOpen(!open)}
          aria-haspopup="listbox"
          aria-expanded={open}
          focused={open}
          aria-invalid={selectProps.hasError}
          errorMessage={selectProps.message}
          disabled={disabled}
          value={
            selectedCycle && selectedCycle.status && title
              ? `${selectedCycle.name} (${title})`
              : selectedCycle?.name
          }
        />
      )}
      options={options}
      value={value}
      disabled={disabled}
      onChange={cycle => {
        if (cycle) {
          onSelect(cycle)
        }
        setSelectedCycle(cycle || undefined)
      }}
      {...selectProps}
    >
      {option => renderCycleName(option.value)}
    </RadioSelectInput>
  )
}

export default CycleSelector
