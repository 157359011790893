import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { IdAndName, RequestInterfaceNew } from '@src/interfaces'
import { usePost } from '@src/utils/reactQuery'

export interface SalesContactRequestInterface {
  id: number
  first_name: string
  last_name: string
  phone_number_short: string
  email: string
  company_name: string
  country: IdAndName
  company_size: IdAndName
  comment: string
}

export const salesContactRequests: RequestInterfaceNew<SalesContactRequestInterface> = {
  get: async ({ id }) => api.get(`${API.SALES_CONTACT_REQUESTS}/${id}`),
  submit: async data => apiWithoutHandling.post(`${API.SALES_CONTACT_REQUESTS}`, data),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.SALES_CONTACT_REQUESTS}/${id}`, data),
}

export const usePostEmail = () =>
  usePost(`${API.SALES_CONTACT_REQUESTS}/email`, undefined, undefined, undefined, true)
