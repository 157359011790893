import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { Link } from '@revolut/ui-kit'
import { KPI_CALIBRATED_PERFORMANCE_VALUES_CONFLUENCE } from '@src/constants/externalLinks'
import React from 'react'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

export const CalibratedTooltip = () => {
  return (
    <TooltipContainer>
      Roadmap metrics are evaluated based upon their Complexity, Speed, and Quality, to
      produce a calibration factor. The product of this factor with the metrics progress
      results in the Calibrated Performance value{' '}
      <HideIfCommercial>
        <Link
          use="a"
          rel="noreferrer noopener"
          target="_blank"
          href={KPI_CALIBRATED_PERFORMANCE_VALUES_CONFLUENCE}
        >
          further info
        </Link>
      </HideIfCommercial>
    </TooltipContainer>
  )
}

export default CalibratedTooltip
