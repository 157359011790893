import {
  ActionButton,
  Avatar,
  Group,
  InputGroup,
  Item,
  ItemSkeleton,
  textChain,
  Token,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import ChangeRoundItem from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRoundItem'

type Props = {
  activeItems: InterviewRoundInterface[]
  archivedItems: InterviewRoundInterface[]
  candidateId: number
  activeRoundId?: number
  currentRound?: number
  onRoundClick: (roundId: number) => void
  isLoading: boolean
  readOnly?: boolean
}

const ChangeRoundContent = ({
  activeItems,
  archivedItems,
  candidateId,
  currentRound,
  onRoundClick,
  isLoading,
  readOnly,
  activeRoundId,
}: Props) => {
  const getCommonItemProps = (item: InterviewRoundInterface) => ({
    item,
    activeRoundId,
    currentRound,
    onClick: () => {
      onRoundClick(item.id)
    },
    readOnly,
  })

  return (
    <InputGroup>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Megaphone" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>
              {textChain('Active opportunities', activeItems.length)}
            </Item.Title>
          </Item.Content>
          <Item.Side>
            {!readOnly && (
              <ActionButton
                useIcon="Plus"
                onClick={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.ADD_INTERVIEW_ROUND.GENERAL, {
                      candidateId,
                    }),
                  )
                }
                disabled={isLoading}
              >
                Add new
              </ActionButton>
            )}
          </Item.Side>
        </Item>

        {isLoading ? (
          <ItemSkeleton />
        ) : (
          <>
            <InputGroup>
              <Group>
                {activeItems.map(item => (
                  <ChangeRoundItem key={item.id} {...getCommonItemProps(item)} />
                ))}
              </Group>
            </InputGroup>
          </>
        )}
      </Group>

      {!isLoading && !!archivedItems.length && (
        <Group>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Archive" color={Token.color.red} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                {textChain('Archived opportunities', archivedItems.length)}
              </Item.Title>
            </Item.Content>
          </Item>
          <Group>
            {archivedItems.map(item => (
              <ChangeRoundItem key={item.id} {...getCommonItemProps(item)} archived />
            ))}
          </Group>
        </Group>
      )}
    </InputGroup>
  )
}

export default ChangeRoundContent
