import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { hiringProcessConfig } from '@src/pages/OnboardingChecklist/common/constants'
import HiringProcessIntro from '@src/pages/OnboardingChecklist/HiringProcess/HiringProcessIntro'
import DefaultHiringProcess from '@src/pages/OnboardingChecklist/HiringProcess/DefaultHiringProcess'
import CustomiseHiringProcess from '@src/pages/OnboardingChecklist/HiringProcess/CustomiseHiringProcess'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.INTRO,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: HiringProcessIntro,
  },
  {
    title: 'Default hiring process',
    path: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.DEFAULT_HIRING_PROCESS,
    url: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.DEFAULT_HIRING_PROCESS,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: DefaultHiringProcess,
    hidePageBody: true,
    hideActions: true,
  },
  {
    title: 'Customise hiring process',
    path: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.CUSTOMISE_HIRING_PROCESS,
    url: ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.CUSTOMISE_HIRING_PROCESS,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: CustomiseHiringProcess,
  },
]

const HiringProcess = () => {
  return <OnboardingChecklistContent config={hiringProcessConfig} routes={routes} />
}

export default HiringProcess
