import SideBar from '@components/SideBar/SideBar'
import React, { useState, useCallback } from 'react'
import { useQueryClient } from 'react-query'
import pluralize from 'pluralize'
import {
  Item,
  Portal,
  VStack,
  DragAndDrop,
  IconButton,
  ItemSkeleton,
  Avatar,
  Color,
  BottomSheet,
  Button,
  Description,
  Header,
  Input,
  Tooltip,
  useTooltip,
  HStack,
  Spinner,
  MoreBar,
  Token,
  Icon,
} from '@revolut/ui-kit'

import { move } from '@src/utils/move'
import {
  useGetFavourites,
  changeFavouriteOrder,
  useDeleteFavourite,
  useUpdateFavourite,
} from '@src/api/favourites'
import { API } from '@src/constants/api'
import { FavouriteInterface } from '@src/interfaces/favourites'
import { getInitials } from '@src/utils/employees'
import { getFavouriteTypeIconProps } from '@components/Favourites/common'
import { DefaultSortableItemState } from '@revolut/ui-kit/types/dist/components/DragAndDrop/SortableItem'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'

interface FavouriteAvatarProps {
  favourite: FavouriteInterface
}

interface FavouriteItemProps {
  favourite: FavouriteInterface
  sortable: DefaultSortableItemState<{}>
}

const FavouriteAvatar = ({ favourite }: FavouriteAvatarProps) => {
  const isEmployee = favourite.favourite_object.category.id === 'employee'

  if (isEmployee) {
    const initials = getInitials(favourite.favourite_object.object_name)
    return (
      <Avatar
        image={favourite.favourite_object.thumbnail_url}
        label={initials}
        aria-label={favourite.favourite_object.object_name}
      />
    )
  }

  const [iconName, background] = getFavouriteTypeIconProps(
    favourite.favourite_object.category.id,
  )

  return (
    <Avatar
      variant="brand"
      useIcon={iconName}
      bg={background}
      color={Token.color.white}
    />
  )
}

const FavouriteItem = ({ favourite, sortable }: FavouriteItemProps) => {
  const [popupOpen, setPopupOpen] = useState(false)
  const { mutateAsync: deleteFavourite, isLoading: isDeleting } = useDeleteFavourite()
  const { mutateAsync: updateFavourite, isLoading: isRenaming } = useUpdateFavourite()
  const [name, setName] = useState(
    favourite.display_name || favourite.favourite_object.object_name,
  )
  const renameTooltip = useTooltip()
  const removeTooltip = useTooltip()

  const handleRemove = () => {
    deleteFavourite(favourite.id)
  }

  const handleEdit = () => {
    setPopupOpen(true)
  }

  const updateName = async () => {
    await updateFavourite([favourite.id, { display_name: name }])
    setPopupOpen(false)
  }

  return (
    <>
      <Item
        ref={sortable.setNodeRef}
        style={{
          transform: sortable.transform
            ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
            : undefined,
          transition: sortable.transition || 'none',
          opacity: sortable.isDragging ? 0 : undefined,
        }}
        aria-label={favourite.favourite_object.object_name}
      >
        <Item.Prefix>
          <IconButton
            aria-label="Drag"
            useIcon="Drag"
            color={Color.GREY_TONE_20}
            {...sortable.attributes}
            {...sortable.listeners}
            size={24}
          />
        </Item.Prefix>
        <Item.Avatar>
          <FavouriteAvatar favourite={favourite} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {favourite.display_name || favourite.favourite_object.object_name}
          </Item.Title>
          <Item.Description>{favourite.favourite_object.subtitle}</Item.Description>
        </Item.Content>
        <Item.Side>
          <HStack align="center" space="s-8">
            <IconButton
              useIcon="Pencil"
              aria-label="Rename"
              color={Token.color.greyTone20}
              onClick={handleEdit}
              size={18}
              tapArea={24}
              {...renameTooltip.getAnchorProps()}
            />

            {isDeleting ? (
              <Spinner color={Color.GREY_TONE_20} size={18} />
            ) : (
              <IconButton
                useIcon="Cross"
                aria-label="Remove"
                color={Token.color.greyTone20}
                onClick={handleRemove}
                disabled={isDeleting}
                size={18}
                tapArea={24}
                {...removeTooltip.getAnchorProps()}
              />
            )}
          </HStack>
          <Tooltip
            {...renameTooltip.getTargetProps({ placement: 'top' })}
            usePortal={false}
          >
            Rename
          </Tooltip>
          <Tooltip
            {...removeTooltip.getTargetProps({ placement: 'top' })}
            usePortal={false}
          >
            Remove
          </Tooltip>
        </Item.Side>
      </Item>
      <BottomSheet open={popupOpen} onClose={() => setPopupOpen(false)}>
        <Header>
          <Header.Title>Rename favourite</Header.Title>
        </Header>
        <Description>
          <Input
            label="Name"
            renderAction={() => {
              if (favourite.favourite_object.object_name === name) {
                return null
              }

              return (
                <IconButton
                  useIcon="RevertLeft"
                  aria-label="Rename"
                  color={Token.color.greyTone50}
                  onClick={() => setName(favourite.favourite_object.object_name)}
                />
              )
            }}
            value={name}
            onChange={event => setName(event.currentTarget.value)}
          />
        </Description>
        <BottomSheet.Actions horizontal>
          <Button variant="secondary" onClick={() => setPopupOpen(false)}>
            Cancel
          </Button>
          <Button
            elevated
            onClick={() => updateName()}
            disabled={isRenaming}
            pending={isRenaming}
          >
            Rename
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
    </>
  )
}

export const Customise = () => {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const { data, isLoading, refetch } = useGetFavourites()
  const [activeId, setActiveId] = useState<number | null>(null)
  const tooltip = useTooltip()

  const favourites = data?.results

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        const startIndex = event.active.data.current.sortable.index
        const endIndex = event.over.data.current.sortable.index

        const newData =
          startIndex === endIndex || !favourites
            ? favourites
            : move(favourites, startIndex, endIndex)

        queryClient.setQueryData([API.FAVOURITES, 'v1', null], {
          ...data,
          results: newData,
        })

        changeFavouriteOrder(event.active.id, event.over.id).catch(() => {
          refetch()
        })
      }

      setActiveId(null)
    },
    [favourites],
  )

  const activeItem = !!activeId && favourites?.find(item => item.id === activeId)

  const onAddFavourites = () => {
    globalSearchState.open = true
    setOpen(false)
  }

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        useIcon="Pencil"
        aria-label="Customise"
        color={Token.color.greyTone20}
        size={16}
        tapArea={32}
        {...tooltip.getAnchorProps()}
      />
      <Tooltip {...tooltip.getTargetProps({ placement: 'top' })}>Customise</Tooltip>
      <Portal>
        <SideBar
          title="Favourites"
          subtitle={
            data?.count ? `${pluralize('item', data.count, true)} saved` : undefined
          }
          isOpen={open}
          onClose={() => setOpen(false)}
          useIcon="StarFilled"
          variant="wide"
          avatarProps={{ color: 'grey-tone-20' }}
          headerContent={
            <Header.Bar>
              <MoreBar>
                <MoreBar.Action useIcon="Plus" onClick={onAddFavourites}>
                  Add favourites
                </MoreBar.Action>
              </MoreBar>
            </Header.Bar>
          }
        >
          <VStack gap="s-16">
            {favourites ? (
              <DragAndDrop.Provider
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onDragCancel={onDragCancel}
              >
                <DragAndDrop.Sortable id="sortable-favourites" items={favourites}>
                  {sortable => {
                    const favourite = favourites.find(item => item.id === sortable.id)

                    if (!favourite) {
                      return null
                    }

                    return (
                      <FavouriteItem
                        sortable={sortable}
                        favourite={favourite}
                        key={favourite.id}
                      />
                    )
                  }}
                </DragAndDrop.Sortable>

                <DragAndDrop.DragOverlay>
                  {activeItem && (
                    <Item>
                      <Item.Prefix>
                        <Icon name="Drag" color={Token.color.greyTone50} />
                      </Item.Prefix>
                      <Item.Avatar>
                        <FavouriteAvatar favourite={activeItem} />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>
                          {activeItem.display_name ||
                            activeItem.favourite_object.object_name}
                        </Item.Title>
                        <Item.Description>
                          {activeItem.favourite_object.subtitle}
                        </Item.Description>
                      </Item.Content>
                    </Item>
                  )}
                </DragAndDrop.DragOverlay>
              </DragAndDrop.Provider>
            ) : null}
            {isLoading && !favourites ? (
              <>
                <ItemSkeleton />
                <ItemSkeleton />
                <ItemSkeleton />
              </>
            ) : null}
          </VStack>
        </SideBar>
      </Portal>
    </>
  )
}
