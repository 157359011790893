import React from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const Contracts = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeContractsPreferences)

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="Activate additional form fields" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow multiple contracts',
                description: 'Allow the creation of secondary contracts for employees.',
              }}
              name="secondary_contracts_enabled"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Ask for justification when changing contracts',
                description:
                  'Ask the user to provide the justification for changing the contract.',
              }}
              name="change_justification_enabled"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow attaching documents',
                description:
                  'Allow adding additional documents to support the contract form.',
              }}
              name="documents_enabled"
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable "Link to justification" field',
                  description:
                    'Request the user to submit a justification link while adding a contract.',
                }}
                name="link_to_justification_enabled"
                disabled={disableEdit}
              />
            </HideIfCommercial>
          </Group>
          <NewStepperTitle title="External employees" />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Require end date for external employees',
                description:
                  'Makes it mandatory for external employees to have an end date for their contract',
              }}
              name="force_termination_enabled"
              disabled={disableEdit}
            />
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.CONTRACTS_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
