import React, { useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { Cell } from '@revolut/ui-kit'
import PlaceholdersTable from '@src/pages/Forms/NotificationTemplate/components/PlaceholdersTable/PlaceholdersTable'

const ViewPlaceholders = () => {
  const [isPlaceholderSidebarOpen, setIsPlaceholderSidebarOpen] = useState(false)

  return (
    <>
      <Cell
        use="button"
        type="button"
        variant="disclosure"
        onClick={() => setIsPlaceholderSidebarOpen(true)}
      >
        View placeholders
      </Cell>
      <SideBar
        isOpen={isPlaceholderSidebarOpen}
        onClose={() => setIsPlaceholderSidebarOpen(false)}
        title="Placeholders"
      >
        <PlaceholdersTable />
      </SideBar>
    </>
  )
}

export default ViewPlaceholders
