import React from 'react'
import { InterviewStatuses } from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getTimeZoneLabel } from '@src/utils/timezones'

type Props = {
  status?: InterviewStatuses
  timeZoneId: string
  time?: string
}

const ScheduledWidget = ({ status, time, timeZoneId }: Props) => {
  switch (status) {
    case InterviewStatuses.scheduling_expired:
      return (
        <ActionWidget
          mt="s-16"
          title="Slots expired"
          text="All slots you sent to the candidate are now unavailable. Kindly reschedule to
            send new slots."
        />
      )

    case InterviewStatuses.interviewer_rejected: {
      let text = 'Interviewer has rejected the interview request'
      if (time) {
        text = `Interviewer has rejected the interview request at ${getTimeZoneLabel(
          time,
          timeZoneId,
          'd MMM yyyy hh:mm aa',
        )}`
      }
      return (
        <ActionWidget mt="s-16" title="Interviewer declined the invite" text={text} />
      )
    }

    default:
      return null
  }
}

export default ScheduledWidget
