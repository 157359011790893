import { useGetMergeConnections } from '@src/api/integrations'
import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { Text, Avatar, Spacer, Token, HStack, VStack, Box, Bullet } from '@revolut/ui-kit'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTextWidget } from '@src/components/OnboardingIntro/OnboardingIntroTextWidget'
import { FormattedMessage } from 'react-intl'
import { OnboardingIntroTips } from '@src/components/OnboardingIntro/OnboardingIntroTips'
import { DisconnectMergeIntegrationItem } from '@src/pages/Hub/Integrations/components/DisconnectMergeIntegrationItem'
import { ConnectMergeIntegrationButton } from '@src/pages/Hub/Integrations/components/ConnectMergeIntegrationButton'
import { MergeDisclaimer } from '@src/pages/Hub/Integrations/components/MergeDisclaimer'
import { SyncBanner } from '@src/pages/Hub/Integrations/components/SyncBanner'

export const BambooHRIntegration = () => {
  return <BambooHRForm />
}

const BambooHRForm = () => {
  const { data: integrations, refetch, isLoading } = useGetMergeConnections()

  const integration = integrations?.results?.find(
    int => int.integration_type === 'bamboohr',
  )
  const isConnected = Boolean(integration)

  return (
    <PageWrapper>
      <PageHeader
        title={
          <VStack maxWidth={{ all: '100%', md: Token.breakpoint.md }}>
            <HStack align="start">
              <VStack>
                <Text>BambooHR</Text>
                {isConnected && (
                  <Text variant="emphasis1" color={Token.color.success}>
                    Connected
                  </Text>
                )}
                <Text color={Token.color.greyTone50} variant="body2">
                  HR Platforms
                </Text>
              </VStack>
              <Spacer />
              <Avatar
                image="https://assets.revolut.com/revoluters/hub-icons/bamboo-40@2x.png"
                size={56}
              >
                {isConnected ? (
                  <Avatar.Badge useIcon="Check" bg={Token.color.success} />
                ) : null}
              </Avatar>
            </HStack>
          </VStack>
        }
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      />

      {!isLoading && !isConnected && (
        <Box>
          <ConnectMergeIntegrationButton
            integration="bamboohr"
            onSuccess={() => refetch()}
            useIcon="Link"
            variant="accent"
          >
            Enable
          </ConnectMergeIntegrationButton>
        </Box>
      )}

      <PageBody mb="s-64" mt="s-16" gap="s-16">
        <SyncBanner integration={integration} />
        <OnboardingIntro>
          <OnboardingIntroTextWidget
            title={
              <FormattedMessage
                id="integrations.bamboo.intro.title"
                defaultMessage="About integration"
              />
            }
          >
            <p>
              <FormattedMessage
                id="integrations.bamboo.intro.text"
                defaultMessage="Automatically sync data in your BambooHR account with Revolut People. Easily import employees and keep personal and work details in sync."
              />
            </p>
          </OnboardingIntroTextWidget>
        </OnboardingIntro>
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="integrations.bamboo.intro.tips.title"
              defaultMessage="How to use"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.integrate.title"
                  defaultMessage="Connect your BambooHR account"
                />
              ),
              icon: <Bullet variant="filled">1</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.integrate.text"
                  defaultMessage="To get started, you'll need to provide your BambooHR login details. Please ensure you have admin access to BambooHR to connect it to Revolut People."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.mapping.title"
                  defaultMessage="Sync employees manually for the first time"
                />
              ),
              icon: <Bullet variant="filled">2</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.mapping.text"
                  defaultMessage="Once your BambooHR account is connected, you can review and edit the employee list before importing. Customise any additional fields as needed."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.sync.title"
                  defaultMessage="Stay updated with automated syncing"
                />
              ),
              icon: <Bullet variant="filled">3</Bullet>,
              text: (
                <FormattedMessage
                  id="integrations.bamboo.intro.tips.sync.text"
                  defaultMessage="All changes in BambooHR, including updates to existing employees and new profiles, will be reflected in Revolut People."
                />
              ),
            },
          ]}
        />
        {!isLoading && !isConnected ? <MergeDisclaimer /> : null}

        {isConnected && !!integration && (
          <DisconnectMergeIntegrationItem
            title="BambooHR"
            integrationId={integration.id}
            onSuccess={() => refetch()}
          />
        )}
      </PageBody>
    </PageWrapper>
  )
}
