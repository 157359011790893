import React from 'react'
import { InputGroup, Side, Text } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import {
  ContractorInterface,
  EmployeeInterface,
  EmployeeType,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'

import { UpdateButton } from '../common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { LapeYesOrNoRadioSelect } from '@components/Inputs/LapeFields/LapeYesOrNoRadioSelect'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'

export interface Props {
  data: EmployeeInterface
  type: EmployeeType
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
  refreshData?: () => void
}

const Notes = ({ dynamicGroups, type, data, isSidebarContent, refreshData }: Props) => {
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { values } = form

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <NewStepperSectionCustomFields
          sectionId={SectionOptions.EmployeeProfile}
          subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.NOTES}
          dynamicGroups={dynamicGroups}
        >
          {values.field_options?.no_access?.includes('info') ? null : (
            <>
              <Text use="div" mb="s-20" fontSize="caption" color="grey-tone-50">
                {`This section is visible for HR managers and super admins only. It is not visible for ${
                  values.first_name || 'this employee'
                }.`}
              </Text>
              <InputGroup>
                <LapeRadioSelectInput
                  name="work_authorisation_status"
                  label="Work authorisation status"
                  selector={selectorKeys.employee_work_authorisation_statuses}
                />
                <LapeRadioSelectInput
                  name="relocation_type"
                  label="Relocation type"
                  selector={selectorKeys.employee_relocation_types}
                />
                <LapeYesOrNoRadioSelect name="is_regulated" label="Regulated role" />
                <LapeNewTextArea
                  label="Notes"
                  name="info"
                  message="Leave any notes regarding the employees work profile"
                />
              </InputGroup>
            </>
          )}
        </NewStepperSectionCustomFields>
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          form={form}
          employeeTypeChanged={type !== data?.employee_type}
          datePickerProps={{
            disabledDays: { before: new Date() },
          }}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Notes
