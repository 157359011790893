import React from 'react'
import { ProgressSteps, ProgressStep } from '@revolut/ui-kit'
import { format } from 'date-fns'
import { PerformanceSelectorReview } from '@src/interfaces/performance'

type Props = {
  reviews: PerformanceSelectorReview[]
}

export const PerformanceLayoutKeyDates = ({ reviews }: Props) => {
  const steps = ['First', 'Second', 'Third']

  return (
    <ProgressSteps>
      {reviews.map((review, i) => (
        <ProgressStep key={i} state="done">
          <ProgressStep.Title>{steps[i]} checkpoint</ProgressStep.Title>
          <ProgressStep.Description>
            {format(new Date(review.submit_date!), 'd MMM yyyy')}
          </ProgressStep.Description>
        </ProgressStep>
      ))}
    </ProgressSteps>
  )
}
