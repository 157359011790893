import {
  CalendarCheck,
  Envelope,
  Check,
  ExclamationMark,
  Document,
  Warning,
  Profile,
} from '@revolut/icons'

export const iconsMap = {
  Envelope: {
    icon: Envelope,
    color: 'blue',
  },
  Check: {
    icon: Check,
    color: 'green',
  },
  Warning: {
    icon: Warning,
    color: 'orange',
  },
  ExclamationMark: {
    icon: ExclamationMark,
    color: 'red',
  },
  Document: {
    icon: Document,
    color: 'blue',
  },
  CalendarCheck: {
    icon: CalendarCheck,
    color: 'blue',
  },
  Profile: {
    icon: Profile,
    color: 'blue',
  },
}
