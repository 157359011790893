import React, { useState } from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { CreateAccountForm } from '@src/pages/SignUp/CreateAccount/CreateAccountForm'
import { CredentialsLoginInterface } from '@src/interfaces/auth'
import { tenantsAdminRequests } from '@src/api/tenants'
import { signupState, SignupVerificationForm } from '../common'

const CreateAccountPage = () => {
  const [twoFALoginData, setTwoFALoginData] = useState<CredentialsLoginInterface>()

  const token =
    twoFALoginData?.token ||
    (signupState.cookie?.state === 'email_confirmation'
      ? signupState.cookie.token
      : undefined)

  if (token) {
    return <SignupVerificationForm token={token} />
  }

  return <CreateAccountForm onSuccess={setTwoFALoginData} />
}

const CreateAccount = connect(() => (
  <Form api={tenantsAdminRequests}>
    <CreateAccountPage />
  </Form>
))

export default CreateAccount
