import { filterSortPageIntoQuery } from '../utils/table'
import { api } from './index'
import { API, PerformanceTimeRange } from '../constants/api'
import {
  RiskSummaryInterface,
  RiskStatsInterface,
  RiskIndicatorInterface,
  RiskIncidentsInterface,
  RiskControlsInterface,
} from '../interfaces/risk'
import { GetRequestInterface } from '../interfaces'
import { AxiosPromise } from 'axios'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { getMonthsByRange } from '@src/utils/kpi'

export const getRiskSummary = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RiskSummaryInterface>> =>
  api.get(`${API.RISK}/summary`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskSummaryStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RiskStatsInterface> =>
  api.get(`${API.RISK}/summary/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskIndicators = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RiskIndicatorInterface>> =>
  api.get(`${API.RISK}/indicators`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskIndicatorsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RiskStatsInterface> =>
  api.get(`${API.RISK}/indicators/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskControls = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RiskControlsInterface>> =>
  api.get(`${API.RISK}/controls`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskControlsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RiskStatsInterface> =>
  api.get(`${API.RISK}/controls/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskIncidents = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RiskIncidentsInterface>> =>
  api.get(`${API.RISK}/incidents`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskIncidentsStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<RiskStatsInterface> =>
  api.get(`${API.RISK}/incidents/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getRiskValueGraph = (
  id: string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
) =>
  api.get<PerformanceChartCycles>(`${API.RISK}/indicators/${id}/historyCycles`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })
