import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'

export const addHighlightIcon = (
  selection: AddSelection,
  t: HierarchyTransition,
  noWrongFunctional?: boolean,
) => {
  selection
    .append('g')
    .attr('style', 'pointer-events: none;')
    .attr('class', d => {
      if (d.data?.highlight !== true || noWrongFunctional) {
        return 'HighlightIcon Hidden'
      }
      if (d.data.disabled === true) {
        return 'HighlightIcon Disabled'
      }
      return 'HighlightIcon'
    })
    .attr('transform', d => {
      return `translate(${
        (d?.parent?.x ?? d.x) + HierarchyConsts.cardWidth / 2 - 12 - 7
      }, ${(d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight + 7})`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x + HierarchyConsts.cardWidth / 2 - 12 - 7}, ${
          d.y - HierarchyConsts.cardHeight + 7
        })`
      }),
    )
    .html(
      `
            <circle cx="6" cy="6" r="6" fill="#F7930D"/>
            <path d="M6 4V7" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 9H6.01" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
      `,
    )
}

export const updateHighlightIcon = (
  selection: UpdateSelection,
  t: HierarchyTransition,
) => {
  selection
    .select('.HighlightIcon')
    .transition(t)
    .attr('transform', d => {
      return `translate(${d.x + HierarchyConsts.cardWidth / 2 - 12 - 7}, ${
        d.y - HierarchyConsts.cardHeight + 7
      })`
    })
}
