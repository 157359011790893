import React, { useEffect, useRef, useState, Suspense } from 'react'
import styled, { css } from 'styled-components'
import BottomText, { BottomProps } from '@components/Inputs/partials/BottomText'
import { Box, Cell, Skeleton, Text } from '@revolut/ui-kit'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

const MonacoEditor = React.lazy(() => import('react-monaco-editor'))
const MonacoDiffEditor = React.lazy(() =>
  import('react-monaco-editor').then(module => ({ default: module.MonacoDiffEditor })),
)

const WrapperCssForDrag = css`
  width: max-content;
`

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
`

const EditorWrapper = styled(Cell)<{ error?: string; readonly?: boolean }>`
  padding: 0.7em 40px 20px 16px;
  flex: 1 0 auto;
  display: grid;
  align-items: start;
  align-content: start;
  position: relative;
  ${({ readonly }) => readonly && `pointer-events: none;`}

  .diffOverview {
    display: none;
  }
`

const DraggableCorner = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
  cursor: se-resize;
`

const WhileMonacoLoading = (props: { width: number; height: number }) => {
  return (
    <Box {...props}>
      <Skeleton data-testid="monaco-skeleton" />
    </Box>
  )
}

export interface NewCodeEditorProps extends BottomProps {
  value: string
  placeholder?: string
  onChange?: (value: string) => void
  readonly?: boolean
  disabled?: boolean
  diff?: string
  name?: string
  language?: 'sql' | 'html'
}

const NewCodeEditor = ({
  value,
  placeholder,
  onChange,
  error,
  bottomInfo,
  diff,
  name,
  readonly,
  language = 'sql',
  ...rest
}: NewCodeEditorProps) => {
  const outerRef = useRef<HTMLDivElement>(null)
  const innerRef = useRef<HTMLDivElement>(null)

  const { theme } = useAppTheme()

  const [defaultSize, setDefaultSize] = useState({
    width: 0,
    height: 260,
  })
  const [size, setSize] = useState({
    width: '488',
    height: defaultSize.height.toString(),
  })

  useEffect(() => {
    if (innerRef.current && defaultSize.width === 0) {
      const parent = outerRef?.current?.parentElement || innerRef.current

      const parentStyle = getComputedStyle(parent)
      const innerStyle = getComputedStyle(innerRef.current)

      const defaultWidth =
        parseFloat(parentStyle.width) -
        parseFloat(innerStyle.paddingLeft) -
        parseFloat(innerStyle.paddingRight)

      setDefaultSize({
        ...defaultSize,
        width: defaultWidth,
      })

      setSize({
        ...size,
        width: defaultWidth.toString(),
      })
    }
  }, [innerRef.current])

  const onDrag = (event: React.MouseEvent) => {
    event.preventDefault()
    const initialSize = {
      ...size,
    }
    const initialX = event.pageX
    const initialY = event.pageY
    function drag(e: MouseEvent) {
      e.preventDefault()
      const newX = e.pageX
      const newY = e.pageY
      const newWidth = parseInt(initialSize.width, 10) - initialX + newX
      const newHeight = parseInt(initialSize.height, 10) - initialY + newY
      setSize({
        width: `${newWidth > defaultSize.width ? newWidth : defaultSize.width}`,
        height: `${newHeight > defaultSize.height ? newHeight : defaultSize.height}`,
      })
      return false
    }
    window.addEventListener('mousemove', drag)
    window.addEventListener('mouseup', stopDragging)
    function stopDragging(e: MouseEvent) {
      e.preventDefault()

      window.removeEventListener('mousemove', drag)
      window.removeEventListener('mouseup', stopDragging)
      return false
    }
    return false
  }
  const options = {
    renderIndicators: false,
    selectOnLineNumbers: true,
    minimap: {
      enabled: false,
    },
    renderLineHighlight: 'none',
    scrollbar: {
      vertical: 'hidden',
      horizontal: 'hidden',
      alwaysConsumeMouseWheel: false,
    },
    readOnly: readonly,
    scrollBeyondLastLine: false,
    hideCursorInOverviewRuler: true,
    overviewRulerBorder: false,
    overviewRulerLanes: 0,
  } as const

  return (
    <Wrapper
      css={parseFloat(size.width) > 10 ? WrapperCssForDrag : undefined}
      data-name={name}
      ref={outerRef}
      aria-label={name}
    >
      <EditorWrapper
        ref={innerRef}
        readonly={readonly}
        error={error}
        onClick={e => e.preventDefault()}
      >
        <Text color="grey-tone-50" variant="small" use="small" mb="0.5em">
          {placeholder}
        </Text>
        <Suspense
          fallback={
            <WhileMonacoLoading
              width={parseInt(size.width, 10)}
              height={parseInt(size.height, 10)}
            />
          }
        >
          {diff ? (
            <MonacoDiffEditor
              {...size}
              language={language}
              disabled
              theme={theme === 'dark' ? 'vs-dark' : 'vs'}
              original={diff}
              value={value}
              options={options}
              onChange={onChange}
              {...rest}
            />
          ) : (
            <MonacoEditor
              {...size}
              language={language}
              disabled
              theme={theme === 'dark' ? 'vs-dark' : 'vs'}
              value={value}
              options={options}
              onChange={onChange}
              {...rest}
            />
          )}
        </Suspense>

        <DraggableCorner onMouseDown={onDrag} />
      </EditorWrapper>
      <BottomText bottomInfo={bottomInfo} error={error} />
    </Wrapper>
  )
}

export default NewCodeEditor
