import React from 'react'
import styled from 'styled-components'
import { useTable } from '@components/Table/hooks'
import {
  FormattedCurrency,
  getPercentColor,
  mapCurrencyColorValues,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { CompanyBudgetStats, RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import {
  getBudgetsForCompanyProfile,
  getBudgetsForCompanyProfileGraph,
  getBudgetsForCompanyProfileStat,
} from '@src/api/budgets'
import { BudgetTypes, CompanyBudgetInterface } from '@src/interfaces/budgets'
import {
  companyBudgetDepartmentNameColumn,
  companyBudgetLocationColumn,
  companyBudgetOwnerColumn,
  companyBudgetPerfReviewColumn,
  companyBudgetRoleColumn,
  companyBudgetSeniorityColumn,
  companyBudgetStatusColumn,
  companyBudgetTotalColumn,
} from '@src/constants/columns/budgets'
import { useQuery } from '@src/utils/queryParamsHooks'
import BudgetChartInner from '@components/Charts/BudgetChart/BudgetChartInner'
import { formatPercentage } from '@src/utils/format'
import { Flex, HStack, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@components/Table/TableWrapper'

const InlineFormattedCurrency = styled(FormattedCurrency)`
  display: inline-block;
`

const departmentRow: RowInterface<CompanyBudgetInterface> = {
  linkToForm: data => {
    switch (data.type) {
      case BudgetTypes.EMPLOYEE_BUDGET: {
        if (data.is_requisition) {
          navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: data.entity_id }))
          break
        }
        navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.entity_id }))
        break
      }
      case BudgetTypes.DEPARTMENT_BUDGET:
        navigateTo(
          pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.SPENT, { id: data.entity_id }),
        )
        break
      case BudgetTypes.TEAM_BUDGET:
        navigateTo(pathToUrl(ROUTES.FORMS.TEAM.BUDGETS, { id: data.entity_id }))
        break
    }
  },
  cells: [
    {
      ...companyBudgetDepartmentNameColumn,
      width: 320,
    },
    {
      ...companyBudgetTotalColumn,
      width: 140,
    },
    {
      ...companyBudgetPerfReviewColumn,
      width: 140,
    },
    {
      ...companyBudgetRoleColumn,
      width: 210,
    },
    {
      ...companyBudgetSeniorityColumn,
      width: 140,
    },
    {
      ...companyBudgetOwnerColumn,
      width: 210,
    },
    {
      ...companyBudgetLocationColumn,
      width: 140,
    },
    {
      ...companyBudgetStatusColumn,
      width: 140,
    },
  ],
}

const Spend = () => {
  const { query } = useQuery()
  const { currentTab, tabBar } = useChartTableSwitcher({
    showTableByDefault: query.switch === ChartTableTabs.Table,
  })
  const initialFilter = [
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const table = useTable<CompanyBudgetInterface, CompanyBudgetStats>(
    {
      getItems: getBudgetsForCompanyProfile,
      getStats: getBudgetsForCompanyProfileStat,
    },
    initialFilter,
  )

  return (
    <TableWrapper flex={currentTab === ChartTableTabs.Chart ? '1 0' : ''}>
      <Flex mb="s-24" alignItems="center">
        <Stat
          label="Consumption"
          val={
            table?.stats?.consumption !== undefined ? (
              <Text
                use="div"
                mr="5px"
                color={getPercentColor(table.stats.consumption * 100)}
              >
                {formatPercentage(table.stats.consumption)}
              </Text>
            ) : undefined
          }
          mr="s-32"
        />
        {table?.stats?.total_budget !== undefined &&
          table?.stats?.total_spent !== undefined && (
            <Stat
              label="Total spend"
              val={
                <>
                  <InlineFormattedCurrency
                    amount={table?.stats?.total_spent}
                    currency={table?.stats?.budget_currency}
                    color={mapCurrencyColorValues(table.stats.consumption)}
                  />{' '}
                  /{' '}
                  <InlineFormattedCurrency
                    amount={table?.stats?.total_budget}
                    currency={table?.stats?.budget_currency}
                  />
                </>
              }
              mr="s-32"
            />
          )}
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={table.onFilterChange}
          columnName="review_cycle__offset"
          filter={table.filterBy}
        />
      </Flex>
      <Flex>
        <HStack space="s-16">
          <Flex>{tabBar}</Flex>
          <MoreInfoButton
            useMoreBar={false}
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1343554313/How+to+manage+your+department+budget"
          >
            Get More Info
          </MoreInfoButton>
        </HStack>
      </Flex>
      {currentTab === ChartTableTabs.Table && (
        <AdjustableTable<CompanyBudgetInterface, CompanyBudgetStats>
          name={TableNames.BudgetSpend}
          row={departmentRow}
          noDataMessage="No company budgets"
          useWindowScroll
          dataType="NPS"
          {...table}
        />
      )}
      {currentTab === ChartTableTabs.Chart && (
        <Flex
          flexDirection="column"
          flex="1 0"
          minHeight={0}
          style={{ position: 'relative' }}
        >
          <BudgetChartInner
            fetchData={getBudgetsForCompanyProfileGraph}
            id={0}
            showWarning
            isNew
            currency={table?.stats?.budget_currency}
          />
        </Flex>
      )}
    </TableWrapper>
  )
}

export default Spend
