import React from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, Box, FilterButton, Flex } from '@revolut/ui-kit'

import { Grid } from '@src/components/CommonSC/Grid'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { NumberOption } from './types'

interface TextOptionsButtonsProps {
  textOptions: string[]
  selectedOption: string | undefined
  onChange: (newValue: string) => void
}
export const TextOptionsButtons = ({
  textOptions,
  selectedOption,
  onChange,
}: TextOptionsButtonsProps) => {
  return (
    <Flex gap="s-8" mb="s-8" width="100%" flexWrap="wrap">
      {textOptions.map(option => (
        <FilterButton
          key={option}
          active={selectedOption === option}
          onClick={() => onChange(option)}
        >
          {option}
        </FilterButton>
      ))}
    </Flex>
  )
}

export const ratingNumbersOptions: NumberOption[] = [
  { name: '1', id: 1, icon: 'SocialDislike' },
  { name: '2', id: 2 },
  { name: '3', id: 3 },
  { name: '4', id: 4 },
  { name: '5', id: 5, icon: 'SocialLike' },
  { name: "I don't know", id: null },
]

const NumberButton = ({
  value,
  selectedOption,
  onChange,
  isEngagementV2,
}: {
  value: NumberOption
  selectedOption: NumberOption | undefined
  onChange: (buttonValue: NumberOption) => void
  isEngagementV2: boolean
}) => {
  return (
    <ActionButton
      key={value.id}
      useIcon={isEngagementV2 ? undefined : value.icon}
      variant={selectedOption?.id === value.id ? 'accent' : undefined}
      onClick={() => onChange(value)}
      width="100%"
    >
      {value.name}
    </ActionButton>
  )
}

const tooltipWrapper = (
  text: string,
  button: React.ReactNode,
  showTooltip: boolean = true,
) => {
  return showTooltip ? (
    <Tooltip text={text} placement="top" delay={750}>
      {button}
    </Tooltip>
  ) : (
    <>{button}</>
  )
}

interface NumberOptionsButtonsProps {
  selectedOption: NumberOption | undefined
  onChange: (option: NumberOption) => void
}

export const RatingOptionsButtons = ({
  selectedOption,
  onChange,
}: NumberOptionsButtonsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)
  const commonButtonProps = { selectedOption, onChange, isEngagementV2 }

  return (
    <Grid flow="column" gap={24} mb="auto" pb="s-8">
      {tooltipWrapper(
        'Strongly disagree',
        <NumberButton value={ratingNumbersOptions[0]} {...commonButtonProps} />,
        !isEngagementV2,
      )}

      <NumberButton value={ratingNumbersOptions[1]} {...commonButtonProps} />
      <NumberButton value={ratingNumbersOptions[2]} {...commonButtonProps} />
      <NumberButton value={ratingNumbersOptions[3]} {...commonButtonProps} />

      {tooltipWrapper(
        'Strongly agree',
        <NumberButton value={ratingNumbersOptions[4]} {...commonButtonProps} />,
        !isEngagementV2,
      )}
      <Box />
      <NumberButton value={ratingNumbersOptions[5]} {...commonButtonProps} />
    </Grid>
  )
}

export const npsNumbersOptions: NumberOption[] = [
  { name: '0', id: 0 },
  { name: '1', id: 1 },
  { name: '2', id: 2 },
  { name: '3', id: 3 },
  { name: '4', id: 4 },
  { name: '5', id: 5 },
  { name: '6', id: 6 },
  { name: '7', id: 7 },
  { name: '8', id: 8 },
  { name: '9', id: 9 },
  { name: '10', id: 10 },
]

export const NpsOptionsButtons = ({
  selectedOption,
  onChange,
}: NumberOptionsButtonsProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isEngagementV2 = featureFlags.includes(FeatureFlags.EngagementV2)
  const commonButtonProps = { selectedOption, onChange, isEngagementV2 }

  return (
    <Grid flow="column" gap={24} mb="auto" pb="s-8">
      {npsNumbersOptions.map(option => (
        <NumberButton key={option.id} value={option} {...commonButtonProps} />
      ))}
    </Grid>
  )
}
