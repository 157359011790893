import React from 'react'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { goBack } from '@src/actions/RouterActions'

export const GrowthPlanFormActions = () => {
  const { values, submit } = useLapeContext<GrowthPlanInterface>()

  const handleSubmitClick = () => {
    values.status = null
    return submit()
  }

  const handleAfterSubmit = () => {
    goBack()
  }

  return (
    <HStack gap="s-16">
      <NewSaveButtonWithPopup
        variant="secondary"
        successText="Growth Plan saved as draft"
        useValidator
        hideWhenNoChanges={false}
        onAfterSubmit={handleAfterSubmit}
      >
        Save as draft
      </NewSaveButtonWithPopup>
      <NewSaveButtonWithPopup
        successText="Growth Plan successfully created"
        useValidator
        hideWhenNoChanges={false}
        onClick={handleSubmitClick}
        onAfterSubmit={handleAfterSubmit}
      >
        Submit
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
