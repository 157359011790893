import { DetailsCell, Group } from '@revolut/ui-kit'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import React from 'react'
import {
  AddToAlumniNetworkVariants,
  AnonymousDisclosureVariants,
  ExperienceVariants,
} from '../constants'

const withFallback = (fieldValue: unknown, truthyValue: string, falsyValue: string) => {
  if (fieldValue === null) {
    return '-'
  }
  return fieldValue ? truthyValue : falsyValue
}

type Props = {
  resignation: EmployeeResignationInterface
}
export const SubmitPreview = ({ resignation }: Props) => (
  <Group>
    <DetailsCell>
      <DetailsCell.Title>
        What ultimately made you decide to leave the company and is there anything Revolut
        could have done to keep you?
      </DetailsCell.Title>
      <DetailsCell.Note>{resignation.resignation_text}</DetailsCell.Note>
    </DetailsCell>
    <DetailsCell>
      <DetailsCell.Title>Overall experience in Revolut</DetailsCell.Title>
      <DetailsCell.Content>
        {withFallback(
          resignation.positive_experience,
          ExperienceVariants.positive,
          ExperienceVariants.negative,
        )}
      </DetailsCell.Content>
    </DetailsCell>
    <DetailsCell>
      <DetailsCell.Title>
        How likely you would recomment Revout as a place for work for a friend?
      </DetailsCell.Title>
      <DetailsCell.Content>{resignation.recommend_revolut || '-'}</DetailsCell.Content>
    </DetailsCell>
    <DetailsCell>
      <DetailsCell.Title>Revolut Alumni Network</DetailsCell.Title>
      <DetailsCell.Content>
        {withFallback(
          resignation.revolut_alumni,
          AddToAlumniNetworkVariants.yes,
          AddToAlumniNetworkVariants.no,
        )}
      </DetailsCell.Content>
    </DetailsCell>
    <DetailsCell>
      <DetailsCell.Title>
        Consent for my responses being anonymously disclosed to management
      </DetailsCell.Title>
      <DetailsCell.Content>
        {withFallback(
          resignation.anonymous_disclosure,
          AnonymousDisclosureVariants.accept,
          AnonymousDisclosureVariants.refuse,
        )}
      </DetailsCell.Content>
    </DetailsCell>
    <DetailsCell>
      <DetailsCell.Title>Next steps for you</DetailsCell.Title>
      <DetailsCell.Content>{resignation.employee_next_steps || '-'}</DetailsCell.Content>
    </DetailsCell>
  </Group>
)
