import React, { useState } from 'react'
import {
  Fixed,
  Side,
  Text,
  VStack,
  HStack,
  Color,
  MoreBar,
  Item,
  MoreBarSkeleton,
  TextWidgetSkeleton,
  TextSkeleton,
  ItemSkeleton,
  ActionButton,
  IconButton,
  Token,
} from '@revolut/ui-kit'
import { defaultTheme } from '@src/styles/theme'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import { Pagination } from '@src/components/Pagination/Pagination'
import { FinalGrade, ReviewCategory } from '@src/interfaces/performance'
import { TalentType } from '@src/interfaces/talent/talent'
import { isDepartmentType, useShowRanking } from '../utils'
import OpenReviewButton from '@src/pages/EmployeeProfile/Preview/Performance/Summary/OpenReviewButton'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { useFetchPerformanceSummary } from '@src/pages/EmployeeProfile/Preview/Performance/Summary/hooks'
import { getTalentCommentsAPI } from '@src/api/talent'
import { ChatWidget } from '@components/Chat/ChatWidget'
import { InfoIconWithTooltip } from '@components/Icon/InfoIconWithTooltip'
import { UserCard } from '@components/UserCard/UserCard'
import { PerformanceHistorySection } from './PerformanceHistorySection'
import { ReviewsSection } from './ReviewsSection'
import { GradeSelector } from './GradeSelector'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { useHistory } from 'react-router-dom'
import Tooltip from '@components/Tooltip/Tooltip'

import {
  DelegateCalibrationMoreAction,
  DelegateCalibrationProps,
} from '../DelegateCalibration'
import { getAvatarUrl } from '@src/utils/employees'
import { PerformanceGradeRevamped } from '@components/PerformanceGrade/PerformanceGradeRevamped'
import { SelectorType } from '@src/interfaces/selectors'
import { GradesMapInterface } from '@src/utils/grades'

interface Props {
  talent: TalentPerformanceInterface
  onClose: () => void
  isOpen: boolean
  currentIndex: number
  total: number
  onPageChanaged: (index: number) => void
  onReviewRequested?: () => void
  onDelegate?: DelegateCalibrationProps['delegate']
  talentType: TalentType
  onGradeSelected: (value: { id: FinalGrade } | null) => Promise<void>
  canChangeGrade: boolean
  canDelegate: boolean
  selector: SelectorType
  scrollToBarRaiser?: boolean
  gradesMap: GradesMapInterface
}

const SummarySidebar = ({
  talent,
  onClose,
  isOpen,
  currentIndex,
  total,
  onPageChanaged,
  onReviewRequested,
  onDelegate,
  talentType,
  onGradeSelected,
  canChangeGrade,
  canDelegate,
  selector,
  scrollToBarRaiser,
  gradesMap,
}: Props) => {
  const history = useHistory()
  const [pendingGradeUpdate, setPendingGradeUpdate] = useState(false)
  const {
    reviews,
    data: summary,
    isLoading: isLoadingSummary,
    refresh,
    resetReviwersFilters,
  } = useFetchPerformanceSummary(
    String(talent.cycle.id),
    talent.employee.id,
    ReviewCategory.Performance,
  )
  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: ['Reviews', 'Performance history'],
    defaultTab: 'Reviews',
    highlightSelected: false,
  })
  const commentsApi = getTalentCommentsAPI(talent.id)

  const showRanking = useShowRanking(talentType)

  const acceptedGrade = isDepartmentType(talentType)
    ? talent.department_owner_grade_suggestion
    : talent.function_owner_grade_suggestion

  const recommenedGradeId = isDepartmentType(talentType)
    ? talent.performance_extra_grade_suggestion?.id
    : talent.ranking_grade
  const recommenedGrade = recommenedGradeId ? gradesMap[recommenedGradeId] || '-' : '-'

  const hodGrade = talent.department_owner_grade_suggestion?.id
  const hodSelectedPoor = hodGrade === FinalGrade.Poor

  const gradeDescription = isDepartmentType(talentType)
    ? 'HoD calibrated grade'
    : 'HoF calibrated grade'

  const renderGrade = () => {
    if (pendingGradeUpdate) {
      return <TextSkeleton width="50%" />
    }
    return acceptedGrade ? (
      <PerformanceGradeRevamped grade={acceptedGrade} inverse />
    ) : (
      recommenedGrade
    )
  }

  const renderGradeWidget = () => {
    if (pendingGradeUpdate) {
      return (
        <ItemSkeleton>
          <ItemSkeleton.Content />
          <ItemSkeleton.Side />
        </ItemSkeleton>
      )
    }
    return (
      <Item>
        <Item.Content color={Color.GREY_TONE_50}>
          <Item.Title variant="h5" style={acceptedGrade ? {} : { fontStyle: 'italic' }}>
            {renderGrade()}
          </Item.Title>
          <Item.Description>
            <HStack space="s-8" align="center">
              <Text variant="caption">
                {acceptedGrade ? gradeDescription : 'Recommended grade'}
              </Text>
              {!acceptedGrade && (
                <InfoIconWithTooltip
                  size={16}
                  tooltipProps={{ maxWidth: '280px' }}
                  content="This is the grade recommended by the system based on the feedback and suggestions from the manager reviews."
                />
              )}
            </HStack>
          </Item.Description>
        </Item.Content>
        {canChangeGrade ? (
          <Item.Side>
            <HStack space="s-8">
              {talent.performance_extra_grade_suggestion && !acceptedGrade ? (
                <ActionButton
                  variant="accent"
                  pending={pendingGradeUpdate}
                  useIcon="Check"
                  onClick={() =>
                    handleGradeChange({
                      id: talent.performance_extra_grade_suggestion?.id!,
                    })
                  }
                >
                  Accept
                </ActionButton>
              ) : null}
              <GradeSelector
                emptyOptionLabel="Empty"
                selector={selector}
                filter={
                  showRanking
                    ? value =>
                        value.id !== FinalGrade.Poor && value.id !== FinalGrade.Empty
                    : undefined
                }
                renderInput={(open, setOpen, ref) => (
                  <Tooltip
                    hide={!hodSelectedPoor}
                    placement="left"
                    text="This grade cannot be changed, as the HoD input has priority for underperfomance"
                  >
                    <ActionButton
                      disabled={hodSelectedPoor}
                      pending={pendingGradeUpdate}
                      useIcon={open ? 'ChevronUp' : 'ChevronDown'}
                      onClick={() => setOpen(!open)}
                      ref={ref}
                    >
                      Change
                    </ActionButton>
                  </Tooltip>
                )}
                onChange={handleGradeChange}
              />
            </HStack>
          </Item.Side>
        ) : null}
      </Item>
    )
  }

  const handleGradeChange: typeof onGradeSelected = async props => {
    setPendingGradeUpdate(true)
    await onGradeSelected(props)
    setPendingGradeUpdate(false)
  }

  return isOpen && talent ? (
    <Fixed
      right={0}
      top={0}
      zIndex={defaultTheme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      overflow="auto"
    >
      <Side open onClose={onClose} variant="wide" height="100%">
        <VStack space="s-16">
          <IconButton
            useIcon="Cross"
            size={24}
            color={Token.color.foreground}
            aria-label="Close"
            onClick={onClose}
          />
          <Pagination
            currentPage={currentIndex}
            total={total}
            onPageSelect={page => {
              history.replace({ search: '' })
              resetReviwersFilters()
              onPageChanaged(page)
            }}
            title="Calibration"
          />
          <UserCard
            userLink={getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: talent.employee.id }),
            )}
            avatar={getAvatarUrl(talent.employee.avatar)}
            displayName={talent.employee.display_name || talent.employee.full_name}
            position={`${talent.specialisation?.name} - ${talent.seniority?.name}`}
          />
          <MoreBar style={{ padding: 0, margin: '0 0 16px 0' }}>
            {isLoadingSummary ? (
              <MoreBarSkeleton />
            ) : (
              <>
                {reviews && reviews.length ? (
                  <OpenReviewButton
                    category={ReviewCategory.Performance}
                    employeeId={talent.employee.id}
                    useMoreBar
                    cycleId={String(talent.cycle.id)}
                  />
                ) : null}
                {!!onReviewRequested && (
                  <MoreBar.Action onClick={onReviewRequested} useIcon="16/CommentAdd">
                    Request Review
                  </MoreBar.Action>
                )}
                {!!onDelegate && canDelegate && (
                  <DelegateCalibrationMoreAction
                    talents={[talent]}
                    delegate={onDelegate}
                  />
                )}
              </>
            )}
          </MoreBar>
          {renderGradeWidget()}
          <VStack space="s-24">
            <ChatWidget
              canEdit={canChangeGrade}
              commentsApi={commentsApi}
              disableTodolistFeature={false}
            />
            {tabBar}
            {isLoadingSummary && <TextWidgetSkeleton />}
            {currentTab === 'Reviews' && !!summary && (
              <ReviewsSection
                data={summary}
                onFilterChanged={options => {
                  refresh(options, undefined, true)
                }}
                scrollToBarRaiser={scrollToBarRaiser}
              />
            )}
            {currentTab === 'Performance history' && (
              <PerformanceHistorySection
                employeeId={talent.employee.id}
                cycleId={talent.cycle.id}
              />
            )}
          </VStack>
        </VStack>
      </Side>
    </Fixed>
  ) : null
}

export default SummarySidebar
