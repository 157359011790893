import React from 'react'
import { Box, Color, Text } from '@revolut/ui-kit'
import { QuestionContent } from './QuestionContent'
import {
  ReviewDataAdditionalScorecardsQuestionInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'

type QuestionProps = {
  question?: ReviewDataAdditionalScorecardsQuestionInterface | null
  questionIdx: number
  questionName: string
  viewMode?: boolean
  relation?: ReviewerRelation
}

export const Question = ({
  question,
  questionIdx,
  questionName,
  viewMode,
  relation,
}: QuestionProps) => {
  return question ? (
    <Box>
      <Text variant="primary" display="block">
        {questionIdx + 1}. {question?.title || `Question ${questionIdx + 1}`}{' '}
        {question?.optional && <Text color={Color.GREY_TONE_50}>(optional)</Text>}
      </Text>
      {question?.subtitle && (
        <Box mt="s-8">
          <Text variant="caption">{question?.subtitle}</Text>
        </Box>
      )}

      {question && (
        <Box mt="s-16">
          <QuestionContent
            question={question}
            questionName={questionName}
            viewMode={viewMode}
            relation={relation}
          />
        </Box>
      )}
    </Box>
  ) : null
}
