import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { General } from '@src/pages/Forms/RecruitmentGroupsForm/General'
import { RecruitmentGroupsPage } from '@src/pages/Forms/RecruitmentGroupsForm/RecruitmentGroupsPage'

export const RecruitmentGroups = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.RECRUITMENT_GROUPS.ALL}>
        <RecruitmentGroupsPage />
      </Route>
      <Route exact path={ROUTES.FORMS.RECRUITMENT_GROUPS.GENERAL}>
        <General />
      </Route>
    </Switch>
  )
}
