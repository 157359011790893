import {
  DetailsCell,
  Token,
  Text,
  HStack,
  MoreBar,
  Avatar,
  VStack,
  Flex,
} from '@revolut/ui-kit'

import {
  GoalsInterfaceWithChildren,
  GoalsStats,
  goalsListTableRequests,
} from '@src/api/goals'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import {
  goalsOrgUnitColumn,
  goalsWeightColumn,
  goalsChangeStatusColumn,
  singleTargetAdditionalColumns,
  goalsOwnerColumn,
  goalsApprovalStatusColumn,
  goalsInputWeightColumn,
  childGoalsNameColumn,
  goalsProgressColumn,
} from '@src/constants/columns/goals'
import { TableNames } from '@src/constants/table'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'

import { FilterByInterface, FilterOption, RowInterface } from '@src/interfaces/data'
import { GoalsInterface } from '@src/interfaces/goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'

import React, { ComponentProps, useEffect, useMemo } from 'react'

import { PermissionTypes } from '@src/store/auth/types'
import { useManageGoalsWeights } from '@src/features/Goals/useManageGoalsWeights'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import LapeEditableTable from '@src/components/TableV2/EditableTable/LapeEditableTable'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeForm from '@src/features/Form/LapeForm'
import { navigateTo } from '@src/actions/RouterActions'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { getGoalUrl } from '../../Preview/helpers'
import { GoalCycleSelectType } from '../../Preview/types'

const getRows = (params: {
  isOnboarding: boolean
  manageMode: boolean
  entity: OrgEntityInterface | null
  isMultipleGoalsTargetsEnabled: boolean | undefined
  canEditStatus: boolean
  table: useTableReturnType<
    GoalsInterfaceWithChildren,
    GoalsStats,
    {
      [key: string]: any
    }
  >
}): EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface> => {
  const {
    isOnboarding,
    manageMode,
    entity,
    isMultipleGoalsTargetsEnabled,
    table,
    canEditStatus,
  } = params
  return {
    linkToForm: goal =>
      navigateTo(getGoalUrl(goal, isOnboarding, { child_goal: 'true' })),
    cells: [
      {
        ...childGoalsNameColumn,
        width: 280,
      },
      {
        ...goalsOrgUnitColumn({ companyName: entity?.data.name || '' }),
        width: 150,
      },
      {
        ...(manageMode ? goalsInputWeightColumn : goalsWeightColumn),
        width: 90,
      },
      {
        ...goalsProgressColumn,
        width: 160,
      },
      ...(!isMultipleGoalsTargetsEnabled ? singleTargetAdditionalColumns : []),
      {
        ...goalsOwnerColumn,
        width: 150,
      },
      {
        ...goalsChangeStatusColumn(
          (status, goalId) =>
            table.updateRows(
              r => r.id === goalId,
              r => ({ ...r, status }),
            ),
          canEditStatus,
        ),
        width: 100,
      },
      {
        ...goalsApprovalStatusColumn,
        width: 75,
      },
    ].filter(Boolean),
  }
}

const ChildGoalsPreviewWidget = ({
  parentId,
  cycle,
}: {
  parentId: number
  cycle: GoalCycleSelectType
}) => {
  const isOnboarding = isOnboardingPath()

  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  const { entity } = useOrgEntity()

  const canManageGoals =
    entity?.data.field_options.permissions?.includes(PermissionTypes.CanManageGoals) ||
    false

  const filters: FilterByInterface[] = [
    {
      filters: [{ name: 'parent_id', id: parentId }],
      columnName: 'parent_id',
      nonInheritable: true,
      nonResettable: true,
    },
    {
      filters: [{ name: `True`, id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      columnName: 'cycle__id',
      filters: [{ id: cycle.id, name: cycle.name }] as FilterOption[],
    },
  ]

  const table = useTable(goalsListTableRequests, filters, undefined, {
    parentIdFilterKey: 'parent_id',
    omitKeys: ['is_top_level', 'cycle__id', 'parent_id'],
  })

  useEffect(() => {
    table.onFilterChange(filters)
  }, [cycle])

  const {
    manageMode,
    handleSubmit,
    weightMode,
    toggleManageMode,
    confirmationDialog,
    autoDistributeWeights,
    validationMessage,
  } = useManageGoalsWeights({
    entity,
    tableData: table.data,
    cycleId: table.filterBy.find(f => f.columnName === 'cycle__id')?.filters[0]?.id,
    onUpdated: () => table.refresh(),
  })
  const isNewTable = useIsNewTable()

  const row = useMemo<
    EditableRowInterface<GoalsInterface> | RowInterface<GoalsInterface>
  >(
    () =>
      getRows({
        isOnboarding,
        isMultipleGoalsTargetsEnabled,
        entity,
        table,
        manageMode,
        canEditStatus: canManageGoals,
      }),
    [manageMode, table.data, weightMode, canManageGoals],
  )

  return (
    <DetailsCell {...(isNewTable && { p: 0, pb: 0 })}>
      <DetailsCell.Title {...(isNewTable && { px: 's-16' })}>
        <HStack space="s-8" align="center">
          <Avatar useIcon="TurboTransfer" />
          <Text variant="h6" color={Token.color.greyTone50}>
            Goals
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Content>
        <Table.Widget.MoreBar>
          {table.data.length > 0 && canManageGoals && (
            <MoreBar.Action
              useIcon={manageMode ? 'SwitchOn' : 'SwitchOff'}
              onClick={toggleManageMode}
            >
              Edit weights
            </MoreBar.Action>
          )}
          {manageMode && (
            <MoreBar.Action useIcon="AutoExchange" onClick={autoDistributeWeights}>
              Auto-distribute weights
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </DetailsCell.Content>
      <DetailsCell.Note>
        <VStack>
          {manageMode ? (
            <LapeEditableTable<GoalsInterface>
              dataFieldName="goals"
              name={TableNames.Goals}
              dataType="Goal"
              disableFilters={manageMode}
              {...table}
              initialData={table.data}
              row={row}
              replaceOnInitialDataChange
            />
          ) : (
            <AdjustableTable<GoalsInterface, GoalsStats>
              name={TableNames.Goals}
              useWindowScroll
              dataType="goals"
              {...table}
              row={row as RowInterface<GoalsInterface>}
              noDataMessage="Add child goals to see them here."
            />
          )}
          <VStack px="s-16">
            {manageMode && !table.loading && table.data.length ? (
              <Flex height="s-20" pt="s-16">
                {validationMessage}
              </Flex>
            ) : null}

            {manageMode && (
              <Flex
                justifyContent="center"
                my="s-16"
                mx="auto"
                minWidth={Token.breakpoint.sm}
              >
                <NewSaveButtonWithPopup
                  hideWhenNoChanges={false}
                  disabled={!!validationMessage}
                  onClick={() => handleSubmit()}
                  successText="Goal weights have been updated"
                >
                  Submit
                </NewSaveButtonWithPopup>
              </Flex>
            )}
          </VStack>
        </VStack>
      </DetailsCell.Note>
      {confirmationDialog}
    </DetailsCell>
  )
}

export const ChildGoalsPreviewFormWidget = (
  props: ComponentProps<typeof ChildGoalsPreviewWidget>,
) => {
  const { entity } = useOrgEntity()

  const initialValues = useMemo(() => {
    return entity && 'goal_weight_mode' in entity.data && entity.data.goal_weight_mode
      ? {
          goals: [],
          goal_weight_mode: entity.data.goal_weight_mode,
        }
      : {
          goals: [],
          goal_weight_mode: { id: 'automatic' },
        }
  }, [entity])
  return (
    <LapeForm
      disableValidation
      onSubmit={() => Promise.resolve({})}
      initialValues={initialValues}
    >
      <ChildGoalsPreviewWidget {...props} />
    </LapeForm>
  )
}
