import React from 'react'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { PermissionInterface } from '@src/interfaces/accessManagement'
import { useTable } from '@components/TableV2/hooks'
import { permissionsRequests } from '@src/api/accessManagement'
import { RowInterface } from '@src/interfaces/data'
import {
  permissionApplicationColumn,
  permissionCodeNameColumn,
  permissionNameColumn,
  permissionUsersColumn,
} from '@src/constants/columns/accessManagement/permissions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const Row: RowInterface<PermissionInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.DETAILS, { id })),
  cells: [
    {
      ...permissionNameColumn,
      width: 200,
    },
    {
      ...permissionCodeNameColumn,
      width: 200,
    },
    {
      ...permissionApplicationColumn,
      width: 200,
    },
    {
      ...permissionUsersColumn,
      width: 200,
    },
  ],
}

const PermissionsTable = () => {
  const table = useTable<PermissionInterface>(permissionsRequests)

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<PermissionInterface>
          name={TableNames.Permissions}
          useWindowScroll
          dataType="Permissions"
          row={Row}
          {...table}
          emptyState={<EmptyTableRaw title="Permissions will appear here." />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default PermissionsTable
