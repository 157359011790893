import { PerformanceSettingsInterface } from '@src/interfaces/settings'

interface SettingsModel {
  hasFunctionalManagement: (settings: PerformanceSettingsInterface) => boolean
}

const hasFunctionalManagement = (settings: PerformanceSettingsInterface) =>
  settings.enable_functional_management

const createSettingsModel = (): SettingsModel => {
  return {
    hasFunctionalManagement,
  }
}

export const settingsModel = createSettingsModel()
