import {
  Flex,
  Box,
  Color,
  useTooltip,
  Tooltip,
  Widget,
  VStack,
  Text,
  HorizontalBarChart,
} from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import { formatPercentage } from '@src/utils/format'

interface BarChartProps {
  data: { value: number; color: Color; label: string }[]
  label: string
}

const BarChart = ({ data, label }: BarChartProps) => {
  const tooltip = useTooltip()

  const distribution = useMemo(() => {
    const sum = data.reduce((partialSum, val) => partialSum + val.value, 0)
    return data.map(val => ({ ...val, value: (val.value * 100) / sum }))
  }, [data])

  return (
    <>
      <Flex
        flex="1 0"
        alignItems="center"
        radius="toast"
        height="s-8"
        overflow="hidden"
        borderRadius="r2"
        {...tooltip.getAnchorProps()}
      >
        <Box width={distribution[0].value} height="s-8" bg={distribution[0].color} />
        {distribution.length > 2
          ? distribution
              .slice(1, distribution.length - 1)
              .map((datum, idx) => (
                <Box key={idx} width={datum.value} height="s-8" bg={datum.color} />
              ))
          : null}
        {distribution.length > 1 && (
          <Box
            width={distribution[distribution.length - 1].value}
            height="s-8"
            bg={distribution[distribution.length - 1].color}
          />
        )}
      </Flex>
      <Tooltip {...tooltip.getTargetProps({ placement: 'bottom' })} p={0} radius="cell">
        <Widget p="s-16" width={440}>
          <VStack space="s-16">
            <Text variant="caption">{label}</Text>
            <HorizontalBarChart
              data={distribution.map(item => ({
                labelLeft: item.label,
                value: item.value,
                labelRight: `${formatPercentage(item.value / 100, 2)}`,
                color: item.color,
              }))}
              role="img"
              aria-label={label}
            />
          </VStack>
        </Widget>
      </Tooltip>
    </>
  )
}

export default BarChart
