import React, { useContext } from 'react'
import { ApiHandlerInterface } from '@src/interfaces'

export const ExtensionApiHandlerContext = React.createContext<
  ApiHandlerInterface | undefined
>(undefined)

export const useExtensionApiContext = () => useContext(ExtensionApiHandlerContext)

export interface SchedulingSidebarHeaderInterface {
  onBack?: (e: React.SyntheticEvent) => void
  actions?: React.ReactNode
}

export const HeaderSchedulingSidebarContext =
  React.createContext<SchedulingSidebarHeaderInterface>({
    onBack: undefined,
    actions: undefined,
  })
