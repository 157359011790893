import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { HStack, Action } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { generatePositionId } from '@src/pages/Careers/utils'

type JobPreviewActionsButtonProps = {
  onCopyJobPosting: () => void
}

const JobPreviewActionsButton = ({ onCopyJobPosting }: JobPreviewActionsButtonProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const {
    settings: { career_page_enabled },
  } = useGlobalSettings()
  const isCommercial = useIsCommercial()

  const published =
    values.status &&
    values.status !== PublishingStatuses.closed &&
    values.status !== PublishingStatuses.unpublished
  const showUrl = published && (!isCommercial || career_page_enabled)
  return (
    <HStack gap="s-8">
      <Action useIcon="Copy" onClick={onCopyJobPosting}>
        Copy content
      </Action>
      {showUrl && (
        <Action
          target="_blank"
          to={
            !values.careers_position_url && values.public_id
              ? pathToUrl(PUBLIC_ROUTES.CAREERS.POSITION, {
                  id: generatePositionId(values.name, values.public_id),
                })
              : values.careers_position_url
          }
          use={InternalLink}
          useIcon="16/LinkExternal"
        >
          Careers website
        </Action>
      )}
    </HStack>
  )
}

export default JobPreviewActionsButton
