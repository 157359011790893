import React, { useMemo } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { Box, Flex, Grid, Token } from '@revolut/ui-kit'
import { formatNumberMillions } from '@src/utils/format'
import { RequisitionBudgetImpactByLocationInterface } from '@src/interfaces/requisitions'
import { SeniorityInterface } from '@src/interfaces/seniority'
import Tooltip from '@components/Tooltip/Tooltip'
import Icon from '@components/Icon/Icon'
import { BarLayerType } from '@components/Charts/BaseChart/BaseChartInner'
import { CartesianMarkerProps } from '@nivo/core'
import styled from 'styled-components'
import { groupBy } from 'lodash'

interface BudgetImpactGraphInterface {
  data: RequisitionBudgetImpactByLocationInterface
  availableBudget: number
  requisitionCost: number | null
  seniorityMin: SeniorityInterface
  seniorityMax: SeniorityInterface
}

const Line = styled.div`
  width: 16px;
  height: 2px;
  margin: 3px 0 9px 0;
  background: ${() => `repeating-linear-gradient(
    to right,
    ${Token.color.red} 0,
    ${Token.color.red} 3px,
    transparent 3px,
    transparent 6px
  )`};
`

const getWarningLayer = ({ bars }: any) => {
  const groups = groupBy(bars, 'data.index')

  return (
    <>
      {Object.values(groups).map(group => {
        const errCount = group.filter(bar => !bar.data.value).length

        return group.map((bar: any, i: number) => {
          if (errCount === 0) {
            return null
          }

          const oneErrorMultipleCols = errCount === 1 && group.length > 1

          if (oneErrorMultipleCols && !bar.data.value) {
            return null
          }

          const x =
            group.length > 1 && errCount > 1
              ? (group[0].x + group[1].x) / 2
              : bar.x + bar.width / 2

          return (
            <foreignObject
              width="20"
              height="20"
              x={x - 10}
              y={bar.y - 24}
              color={Token.color.orange}
              key={i}
            >
              <Tooltip
                placement="top"
                text={
                  oneErrorMultipleCols
                    ? `Missing benchmark for ${group[i === 1 ? 0 : 1].data.id}`
                    : `Missing benchmark for this location`
                }
              >
                <Icon type="WarningTriangle" />
              </Tooltip>
            </foreignObject>
          )
        })
      })}
    </>
  )
}

const BudgetImpactGraph = ({
  data,
  availableBudget,
  requisitionCost,
  seniorityMin,
  seniorityMax,
}: BudgetImpactGraphInterface) => {
  const keys = [`${seniorityMin.name} seniority salary`]
  if (seniorityMin.id !== seniorityMax.id) {
    keys.push(`${seniorityMax.name} seniority salary`)
  }
  const colors = [Token.color.deepGrey, Token.color.blue]
  const numberOfColumns = Object.values(data).length

  const warningLayer = useMemo(() => getWarningLayer, [keys.length])

  const barData = useMemo(
    () =>
      Object.entries(data).map(([country, ranges]) => ({
        country,
        [keys[0]]: ranges.min_value,
        ...(keys[1] ? { [keys[1]]: ranges.max_value } : {}),
      })),
    [data],
  )

  const markers = useMemo<CartesianMarkerProps[]>(() => {
    if (
      availableBudget < 0 ||
      (requisitionCost && availableBudget > requisitionCost * 2)
    ) {
      return []
    }

    return [
      {
        axis: 'y',
        value: availableBudget,
        lineStyle: {
          stroke: Token.color.red,
          strokeWidth: 2,
          strokeDasharray: '4, 4',
        },
        legendOrientation: 'vertical',
      },
    ]
  }, [requisitionCost, availableBudget])

  const maxValue = useMemo(() => {
    if (!requisitionCost) {
      return undefined
    }
    if (availableBudget > requisitionCost * 2) {
      return requisitionCost * 1.2
    }
    return Math.max(availableBudget, requisitionCost) * 1.2
  }, [availableBudget, requisitionCost])

  return (
    <>
      <Flex
        height="230px"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <ResponsiveBar
          margin={{ top: 10, bottom: numberOfColumns > 7 ? 100 : 50, right: 40 }}
          data={barData}
          layers={[
            BarLayerType.Grid,
            BarLayerType.Axes,
            BarLayerType.Markers,
            BarLayerType.Legends,
            BarLayerType.Bars,
            warningLayer,
          ]}
          indexBy={'country'}
          keys={keys}
          labelSkipWidth={16}
          labelSkipHeight={16}
          groupMode="grouped"
          maxValue={maxValue}
          axisTop={null}
          axisLeft={null}
          axisBottom={{
            tickPadding: 8,
            tickSize: 0,
            tickRotation: numberOfColumns > 7 ? 50 : 0,
          }}
          axisRight={{
            tickValues: 5,
            tickSize: 0,
            tickPadding: 8,
            tickRotation: 0,
            format: (d: any) => formatNumberMillions(d),
          }}
          padding={
            keys.length > 1
              ? 0.98 - numberOfColumns * 0.02
              : 0.99 - numberOfColumns * 0.005
          }
          innerPadding={4}
          enableLabel={false}
          markers={markers}
          colors={keys.length === 1 ? [colors[1]] : colors}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: Token.color.greyTone50,
                },
                text: {
                  fill: Token.color.greyTone50,
                },
              },
            },
            grid: {
              line: {
                strokeWidth: 0.5,
              },
            },
            tooltip: {
              container: {
                background: Token.color.background,
                color: Token.color.foreground,
                padding: '10px 15px',
                borderRadius: 4,
              },
            },
          }}
        />
      </Flex>
      <Grid columns="repeat(3, 110px)" columnGap="s-16">
        {keys.map((key, index) => (
          <Box key={`legend-${key}`}>
            <Box
              backgroundColor={keys.length > 1 ? colors[index] : colors[1]}
              width="s-8"
              height="s-8"
              borderRadius={2}
              mb="s-6"
            />
            {key}
          </Box>
        ))}
        {markers.length > 0 && (
          <Box>
            <Line />
            Budget available
          </Box>
        )}
      </Grid>
    </>
  )
}

export default BudgetImpactGraph
