import { useEffect, useState } from 'react'
import {
  EmployeeInterface,
  EmployeeStatusTransitionsInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { updateEmployeeStatus, useGetEmployeeStatusTransitions } from '@src/api/employees'
import { OptionInterface } from '@src/interfaces/selectors'
import { PermissionTypes } from '@src/store/auth/types'

type UseEmployeeStatusTransitionsParams = {
  employee: EmployeeInterface | undefined
  updateData: (newValues: Partial<EmployeeInterface>) => void
}
export type UseEmployeeStatusTransitionsReturnType = {
  data: EmployeeStatusTransitionsInterface | undefined
  update: (
    newValues: Partial<EmployeeStatusTransitionsInterface>,
    onSuccess?: (statusTransitions: OptionInterface[]) => void,
  ) => void
  pending: boolean
  refetch: () => void
  isInactiveEmployee: boolean
}
export const useEmployeeStatusTransitions = ({
  employee,
  updateData,
}: UseEmployeeStatusTransitionsParams): UseEmployeeStatusTransitionsReturnType => {
  const [pending, setPending] = useState(false)
  const [currentData, setCurrentData] = useState<EmployeeStatusTransitionsInterface>()

  useEffect(() => {
    if (currentData?.status) {
      updateData({ status: currentData.status })
    }
  }, [currentData])

  const canChangeEmployeeStatus = employee?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeStatus,
  )
  const shouldGetStatusTransitions = employee?.id && canChangeEmployeeStatus

  const {
    data: fetchedData,
    refetch,
    isLoading,
  } = useGetEmployeeStatusTransitions(shouldGetStatusTransitions ? employee.id : null)

  const data = currentData || fetchedData
  const isInactiveEmployee = !!data?.status_transitions.some(
    status => status.id === IdStatuses.active,
  )

  const updateEmployeeStatusTransitions = async (
    newValues: Partial<EmployeeStatusTransitionsInterface>,
    onSuccess?: (statusTransitions: OptionInterface[]) => void,
  ) => {
    if (!employee?.id) {
      return
    }
    try {
      setPending(true)
      const res = await updateEmployeeStatus(employee.id, newValues)
      setCurrentData(res.data)
      onSuccess?.(res.data.status_transitions)
    } finally {
      setPending(false)
    }
  }

  return {
    data,
    pending: pending || isLoading,
    update: updateEmployeeStatusTransitions,
    refetch,
    isInactiveEmployee,
  }
}
