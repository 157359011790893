import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { AuditInterface, AuditLatenessText, AuditSeverities } from '@src/interfaces/audit'
import Url from '@components/ColumnInserts/Url/Url'
import React from 'react'
import { formatDistanceStrict } from 'date-fns'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { Token } from '@revolut/ui-kit'

export const auditIssueLinkColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  insert: ({ data }) => <Url href={data.issue_link}>{data.issue_id}</Url>,
  idPoint: 'issue_id',
  dataPoint: 'issue_link',
  sortKey: 'issue_id',
  filterKey: 'issue_id',
  selectorsKey: selectorKeys.audit_issue_id,
  title: 'Issue',
}

export const auditParentLinkColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  insert: ({ data }) => <Url href={data.parent_issue_link}>{data.parent_issue_key}</Url>,
  idPoint: 'parent_issue_key',
  dataPoint: 'parent_issue_link',
  sortKey: 'parent_issue_key',
  filterKey: 'parent_issue_key',
  selectorsKey: selectorKeys.audit_parent_issue_key,
  title: 'Parent',
}

export const auditBugLinkColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  insert: ({ data }) => <Url href={data.issue_link}>{data.issue_id}</Url>,
  idPoint: 'issue_id',
  dataPoint: 'issue_link',
  sortKey: 'issue_id',
  filterKey: 'issue_id',
  selectorsKey: selectorKeys.cx_issue_id,
  title: 'Issue',
}

export const auditSummaryColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: 'summary',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
}

export const auditAssigneeColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'assignee_name',
  dataPoint: 'assignee_name',
  sortKey: 'assignee_name',
  filterKey: 'assignee_name',
  selectorsKey: selectorKeys.audit_assignee_name,
  title: 'Assignee',
}

export const bugAssigneeColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'assignee.id',
  dataPoint: 'assignee.name',
  sortKey: 'assignee__full_name',
  filterKey: 'assignee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
}

export const auditTeamNameColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'team_id',
  dataPoint: 'team_name',
  sortKey: 'team_name',
  filterKey: 'team_id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const auditDepartmentNameColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'department_id',
  dataPoint: 'department_name',
  sortKey: 'department_name',
  filterKey: 'department_id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const auditCreationDateColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Creation date',
}

export const auditDueDateColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.date,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: 'due_date',
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}
export const auditSeverityColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'severity_v2.id',
  dataPoint: 'severity_v2.name',
  sortKey: 'severity_v2',
  filterKey: 'severity_v2',
  selectorsKey: selectorKeys.audit_severity_v2,
  title: 'Severity',
  colors: data => {
    if (data.severity_v2.id === AuditSeverities.Critical) {
      return Token.color.red
    }
    if (data.severity_v2.id === AuditSeverities.High) {
      return Token.color.warning
    }
    if (data.severity_v2.id === AuditSeverities.Medium) {
      return Token.color.orange_70
    }
    if (data.severity_v2.id === AuditSeverities.Low) {
      return Token.color.greyTone50
    }

    return Token.color.foreground
  },
}
export const auditSeverityV1Column: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'severity.id',
  dataPoint: 'severity.name',
  sortKey: 'severity_sorter',
  filterKey: 'severity',
  selectorsKey: selectorKeys.cx_issue_severity,
  title: 'Severity',
  colors: data => {
    if (
      data.severity?.id === AuditSeverities.Blocker ||
      data.severity?.id === AuditSeverities.Critical
    ) {
      return Token.color.red
    }
    if (
      data.severity?.id === AuditSeverities.High ||
      data.severity?.id === AuditSeverities.Major
    ) {
      return Token.color.warning
    }
    if (data.severity?.id === AuditSeverities.Medium) {
      return Token.color.orange_70
    }
    if (
      data.severity?.id === AuditSeverities.Low ||
      data.severity?.id === AuditSeverities.Minor ||
      data.severity?.id === AuditSeverities.Improvement
    ) {
      return Token.color.greyTone50
    }

    return Token.color.foreground
  },
}
export const auditLatenessColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_lateness',
  dataPoint: 'issue_lateness',
  sortKey: 'days_left',
  filterKey: 'issue_lateness',
  selectorsKey: selectorKeys.audit_lateness,
  title: 'Days left / Overdue',
  insert: ({ data }) => {
    if (data.due_date) {
      if (data.issue_lateness === AuditLatenessText.Overdue) {
        return AuditLatenessText.Overdue
      }
      return formatDistanceStrict(new Date(data.due_date), new Date(), { unit: 'day' })
    }
    return '-'
  },
}

export const auditBugsLatenessColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_lateness',
  dataPoint: 'issue_lateness',
  sortKey: 'days_left',
  filterKey: 'issue_lateness',
  selectorsKey: selectorKeys.audit_lateness,
  title: 'Days left / Overdue',
  insert: ({ data }) => {
    if (data.due_date) {
      if (data.issue_lateness === AuditLatenessText.Overdue) {
        return AuditLatenessText.Overdue
      }
      return formatDistanceStrict(new Date(data.due_date), new Date(), { unit: 'day' })
    }
    return '-'
  },
}

export const auditTypeColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.text,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: 'category',
  filterKey: 'category',
  selectorsKey: selectorKeys.audit_category,
  title: 'Type',
}

export const auditBonusImpactColumn: ColumnInterface<AuditInterface> = {
  type: CellTypes.insert,
  idPoint: 'bonus_impact',
  dataPoint: 'bonus_impact',
  sortKey: 'bonus_impact',
  filterKey: 'bonus_impact',
  selectorsKey: selectorKeys.audit_bonus_impact,
  headerTooltip: (
    <TooltipContainer>
      Bonus impact has effect only at team level. At department level it is used to
      quantify the impact of all unsolved findings linked to it.
    </TooltipContainer>
  ),
  insert: ({ data }) => (data.bonus_impact ? `${data.bonus_impact * 100}%` : '-'),
  title: 'Bonus impact',
}
