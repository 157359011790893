import { Box, Details, TransitionCollapse, Widget } from '@revolut/ui-kit'
import LapeNewSwitch, {
  type LapeNewSwitchProps,
} from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import React, { MutableRefObject, PropsWithChildren, ReactNode } from 'react'

export interface CollapsibleRadioSwitchProps extends LapeNewSwitchProps {
  alwaysShownContent?: ReactNode
  reversed?: boolean
  blockRef?: MutableRefObject<HTMLDivElement | null>
}

export const CollapsibleRadioSwitch = <T extends {}>({
  children,
  alwaysShownContent,
  reversed,
  blockRef,
  ...switchProps
}: PropsWithChildren<CollapsibleRadioSwitchProps>) => {
  const { values } = useLapeContext<T>()
  const expanded = !!values[switchProps.name as keyof T]

  return (
    <Widget>
      <Details style={{ padding: 0 }}>
        <Details.Title>
          <LapeNewSwitch {...switchProps} />
        </Details.Title>
        {expanded || alwaysShownContent ? (
          <Details.Note>
            <Box>
              {alwaysShownContent && !reversed ? <Box>{alwaysShownContent}</Box> : null}
              <TransitionCollapse in={expanded}>{children}</TransitionCollapse>
              {alwaysShownContent && reversed ? <Box>{alwaysShownContent}</Box> : null}
            </Box>
          </Details.Note>
        ) : null}
      </Details>
    </Widget>
  )
}
