import React from 'react'
import { useSelector } from 'react-redux'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { SchedulingPolicyTableInterface } from '@src/interfaces/attendance'
import { getSchedulingPolicies } from '@src/api/attendance'
import {
  schedulingPoliciesCreatedOnColumn,
  schedulingPoliciesGroupsColumn,
  schedulingPoliciesLocationColumn,
  schedulingPoliciesMembersColumn,
  schedulingPoliciesNameColumn,
  schedulingPoliciesOwnerColumn,
  schedulingPoliciesShiftsColumn,
  schedulingPoliciesStatusColumn,
} from '@src/constants/columns/attendance'
import { Statuses } from '@src/interfaces'
import SettingsButtons from '@src/features/SettingsButtons'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<SchedulingPolicyTableInterface> = {
  linkToForm: ({ id, status }) =>
    navigateTo(
      pathToUrl(
        status.id === Statuses.draft
          ? ROUTES.FORMS.SCHEDULING_POLICY.GENERAL
          : ROUTES.FORMS.SCHEDULING_POLICY.PREVIEW,
        { id },
      ),
    ),
  cells: [
    {
      ...schedulingPoliciesNameColumn,
      width: 120,
    },
    {
      ...schedulingPoliciesLocationColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesGroupsColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesMembersColumn,
      width: 80,
    },
    {
      ...schedulingPoliciesShiftsColumn,
      width: 80,
    },
    {
      ...schedulingPoliciesCreatedOnColumn,
      width: 100,
    },
    {
      ...schedulingPoliciesOwnerColumn,
      width: 120,
    },
    {
      ...schedulingPoliciesStatusColumn,
      width: 100,
    },
  ],
}

const Policies = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddSchedulingPolicy)

  const table = useTable({ getItems: getSchedulingPolicies })

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-16">
          <Stat
            label="Scheduling policies"
            val={table?.loading ? undefined : table?.count}
            mr="s-32"
          />
        </Flex>
        {canAdd && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.GENERAL)}
              useIcon={Plus}
            >
              Create new
            </MoreBar.Action>
          </SettingsButtons>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.AttendancePolicies}
            useWindowScroll
            row={ROW}
            {...table}
            noDataMessage="Policies will appear here"
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default Policies
