import React, { useEffect } from 'react'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { KpiInterface } from '@src/interfaces/kpis'
import { kpisRequestsNew } from '@src/api/kpis'

import Header from './common/Header'
import Preview from './Preview'
import General from './General'
import New from './New'
import Changelog from './Changelog'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import { EntityPermissions } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { Box, Flex } from '@revolut/ui-kit'
import TargetsApprovals from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import { KPITypes } from '@src/constants/table'
import SelectKPIType from '@src/pages/Forms/KpiForm/SelectKPIType'
import PageLoading from '@components/PageLoading/PageLoading'
import { goBack } from '@src/actions/RouterActions'

const getPatchFormTabs = (params: { id: string }) => [
  {
    title: 'Preview',
    path: ROUTES.FORMS.KPI.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.KPI.PREVIEW, params),
    component: Preview,
    visibilityCheck: () => true,
  },
  {
    title: 'Changelog',
    path: ROUTES.FORMS.KPI.CHANGELOG,
    to: pathToUrl(ROUTES.FORMS.KPI.CHANGELOG, params),
    component: Changelog,
    visibilityCheck: (data: KpiInterface) =>
      !!data?.id && !!data?.field_options?.actions?.includes(EntityPermissions.Change),
  },
]

const getKpiLevelType = (kpi: Partial<KpiInterface>): KpiInterface['kpi_type'] | null => {
  if (kpi.is_company) {
    return { id: KPITypes.company_kpi, name: 'Company' }
  }
  if (kpi.function) {
    return { id: KPITypes.function_kpi, name: 'Function' }
  }
  if (kpi.role) {
    return { id: KPITypes.role_kpi, name: 'Role' }
  }
  if (kpi.department) {
    return { id: KPITypes.department_kpi, name: 'Department' }
  }
  if (kpi.team) {
    return { id: KPITypes.team_kpi, name: 'Team' }
  }
  if (kpi.is_employee) {
    return { id: KPITypes.employee_kpi, name: 'Personal' }
  }
  return null
}

const Kpi = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<KpiInterface>()

  const isNewInstance = !values.id || !params.id

  const tabs = getPatchFormTabs(params).filter(tab => tab.visibilityCheck(values))

  useEffect(() => {
    if (!values.kpi_type?.id) {
      const levelType = getKpiLevelType(values)
      if (!levelType) {
        goBack(ROUTES.FORMS.COMPANY.KPI.COMPANY)
        return
      }
      values.kpi_type = levelType
    }
  }, [values])

  if (!values.kpi_type?.id) {
    return <PageLoading />
  }

  const generalSelectRouteMatch =
    useRouteMatch(ROUTES.FORMS.KPI.GENERAL) || useRouteMatch(ROUTES.FORMS.KPI.SELECT_TYPE)

  return (
    <PageWrapper>
      <TargetsApprovals>
        <Header isNewInstance={isNewInstance}>
          {!generalSelectRouteMatch && (
            <Box mb="s-8">
              <TabBarNavigation tabs={tabs} />
            </Box>
          )}
        </Header>

        <Flex flexDirection="column" width="100%" flex={1}>
          <Switch>
            <Route exact path={ROUTES.FORMS.KPI.GENERAL}>
              {isNewInstance ? <New /> : <General />}
            </Route>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component />
              </Route>
            ))}
            <Route exact path={ROUTES.FORMS.KPI.SELECT_TYPE}>
              <SelectKPIType />
            </Route>
          </Switch>
        </Flex>
      </TargetsApprovals>
    </PageWrapper>
  )
})

const KpiForm = () => {
  return (
    <Form<KpiInterface>
      api={kpisRequestsNew}
      fieldsToExclude={['targets', 'target_epics']}
      localStorageProps={{
        followUrlTransitions: true,
        removeDataAfterTransitionFromUrls: [
          pathToUrl(ROUTES.FORMS.KPI.SELECT_TYPE),
          pathToUrl(ROUTES.FORMS.KPI.GENERAL),
        ],
      }}
    >
      <Kpi />
    </Form>
  )
}

export default connect(KpiForm)
