import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { Form } from './common'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const General = () => {
  const { values } = useLapeContext<GroupAccessManagementInterface>()
  const params = useParams<{ id?: string }>()

  useEffect(() => {
    if (!values.settings) {
      values.settings = {}
    }
  }, [])

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit permission group' : 'Add permission group'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, params)
            : ROUTES.ADMIN.ACCESS_MANAGEMENT.GROUPS
        }
      />

      <Form />

      <PageActions>
        <NewSaveButtonWithPopup
          previewUrl={ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
