import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  TextCell,
  RadioSelectInputCell,
  GenericEditableTableOnChange,
} from '@src/features/GenericEditableTable/components'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { DocumentListItemInterface } from '@src/interfaces/documents'
import { selectorKeys } from '../api'
import { IdAndName } from '@src/interfaces'

type EditableDocumentsBulkSessionTableColumn = (
  onChange: GenericEditableTableOnChange,
) => ColumnInterface<ImportInterface<ImportDocumentsItemInterface>>

type EditableDocumentsExistingItemsTableColumn = (
  onChange: GenericEditableTableOnChange,
) => ColumnInterface<ImportInterface<DocumentListItemInterface>>
type EditableDocumentsExistingItemsWithCreateColumn = (
  onChange: GenericEditableTableOnChange,
  onCreate: (onChangeAction: (newEntity: IdAndName) => void) => void,
) => ColumnInterface<ImportInterface<DocumentListItemInterface>>

// BE has not updated bulk documents session to a new format (and not planning to) so we have to mimic it here
const mapDataForEditableCell = (
  data: ImportDocumentsItemInterface,
): ImportInterface<ImportDocumentsItemInterface> => {
  const { errors, ...documentItemData } = data

  return {
    id: data.id,
    data: documentItemData,
    errors: errors || {},
    state: { id: 'success', name: 'Success' },
    error_message: null,
    loading: {},
  }
}

export const onboardingBulkSessionDocumentsNameColumn: EditableDocumentsBulkSessionTableColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'name',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Document name',
    insert: ({ data }) => (
      <TextCell
        // since we pretend that data format is correct for using it in editable table, we have to force the type back
        // to the actual one before remapping
        data={mapDataForEditableCell(data as unknown as ImportDocumentsItemInterface)}
        onChange={onChange}
        field="name"
      />
    ),
  })

export const onboardingBulkSessionDocumentsCategoryColumn: EditableDocumentsBulkSessionTableColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'category',
    dataPoint: 'category',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Category',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={mapDataForEditableCell(data as unknown as ImportDocumentsItemInterface)}
        onChange={onChange}
        field="category"
        selector={selectorKeys.document_categories}
      />
    ),
  })

export const onboardingBulkSessionDocumentsAssigneeColumn: EditableDocumentsBulkSessionTableColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Assignee',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={mapDataForEditableCell(data as unknown as ImportDocumentsItemInterface)}
        onChange={onChange}
        field="employee"
        fieldName="assignee"
        selector={selectorKeys.all_employees_avatar_email}
      />
    ),
  })

export const onboardingDocumentsExistingItemsNameColumn: EditableDocumentsExistingItemsTableColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'document_name',
    dataPoint: 'document_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Document name',
    insert: ({ data }) => (
      <TextCell data={data} onChange={onChange} field="document_name" />
    ),
  })

export const onboardingDocumentsExistingItemsCategoryColumn: EditableDocumentsExistingItemsWithCreateColumn =
  (onChange, onCreate) => ({
    type: CellTypes.insert,
    idPoint: 'category',
    dataPoint: 'category',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Category',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="category"
        selector={selectorKeys.document_categories}
        onCreateNewClick={onCreate}
      />
    ),
  })

export const onboardingDocumentsExistingItemsAssigneeColumn: EditableDocumentsExistingItemsTableColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Assignee',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        onChange={onChange}
        field="employee"
        fieldName="assignee"
        selector={selectorKeys.all_employees_avatar_email}
      />
    ),
  })
