import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  Flex,
  Header,
  HStack,
  InputGroup,
  Popup,
  StatusPopup,
  Switch,
  Text,
  useStatusPopup,
} from '@revolut/ui-kit'
import { saveQueryDeployments, updateQueryDeployments } from '@src/api/dataAnalytics'
import { useTenantSelector } from '@src/api/tenants'
import { IdAndName } from '@src/interfaces'
import {
  TenantQueryDeploymentInterface,
  QueryInterface,
} from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  onClose: () => void
  open: boolean
}

export const TenantDeploymentPopup = ({ onClose, open }: Props) => {
  const statusPopup = useStatusPopup()
  const { data: tenants } = useTenantSelector([])
  const { values } = useLapeContext<QueryInterface>()

  const tenantsOptions = useMemo(
    () =>
      (tenants?.options || []).map(item => ({
        label: item.name,
        value: item,
      })),
    [tenants],
  )

  const [isAllTenants, setIsAllTenants] = useState(
    values.tenant_deployment?.all_tenants || false,
  )
  const [isPending, setIsPending] = useState(false)
  const [selectedTenants, setSelectedTenants] = useState<
    RadioSelectOption<IdAndName<string>>[]
  >(
    (values.tenant_deployment?.tenants || []).map(item => ({
      label: item.name,
      value: item,
    })),
  )

  const handleSaveDeployment = async (
    cb: (
      deploymentData: Omit<TenantQueryDeploymentInterface, 'id'>,
    ) => ReturnType<typeof updateQueryDeployments>,
  ) => {
    setIsPending(true)
    const deploymentData = {
      all_tenants: isAllTenants,
      tenants: selectedTenants.map(item => item.value),
      query_id: values.id,
    }

    try {
      await cb(deploymentData)

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Query deployed</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Query was not deployed</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
      onClose()
    }
  }

  const saveDeployment = () => {
    if (values.tenant_deployment) {
      handleSaveDeployment(data =>
        updateQueryDeployments(data, values.tenant_deployment?.id!),
      )
    } else {
      handleSaveDeployment(data => saveQueryDeployments(data))
    }
  }

  return (
    <Popup onClose={onClose} open={open} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Select tenants to deploy this query to</Header.Title>
        <Header.Subtitle>
          If you select all current and future tenants the query will be deployed to all
          tenants as a system query. Otherwise you can select the tenants to deploy the
          query to individually.
        </Header.Subtitle>
      </Header>
      <InputGroup>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="caption">Deploy to all tenants and all future tenants</Text>
          <Switch
            checked={isAllTenants}
            onChange={e => setIsAllTenants(e.target.checked)}
          />
        </Flex>
        {!isAllTenants && (
          <NewMultiSelect
            onChange={val => {
              setSelectedTenants(val)
            }}
            options={tenantsOptions}
            placeholder="Tenants"
            required
            value={selectedTenants}
          />
        )}
      </InputGroup>
      <Popup.Actions>
        <HStack space="s-16">
          <ActionButton onClick={onClose}>Cancel</ActionButton>
          <ActionButton variant="accent" onClick={saveDeployment} pending={isPending}>
            Submit
          </ActionButton>
        </HStack>
      </Popup.Actions>
    </Popup>
  )
}
