import { action } from 'typesafe-actions'
import { FeedbackActionsTypes, FeedbackSubmitInterface } from './types'

export const setFeedbackOpen = (open: boolean) =>
  action(FeedbackActionsTypes.SET_FEEDBACK_OPEN, open)

export const closeFeedback = () => action(FeedbackActionsTypes.CLOSE_FEEDBACK)

export const setFeedbackLoading = (loading: boolean) =>
  action(FeedbackActionsTypes.SET_LOADING, loading)

export const submitFeedback = (data: FeedbackSubmitInterface) =>
  action(FeedbackActionsTypes.SUBMIT_FEEDBACK, data)
