import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep, get, isEmpty, unset, set } from 'lodash'
import { QueryObserverResult } from 'react-query'
import { produce } from 'immer'

import {
  applyImportTimeOffRequestsSession,
  editImportTimeOffRequestsSessionRow,
  getTimeOffRequestsUploadSessionTable,
  useGetImporTimeOffRequestsSessionData,
} from '@src/api/importData'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import { PageBody } from '@src/components/Page/PageBody'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { PageActions } from '@src/components/Page/PageActions'
import { Button, Token } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import {
  ImportTimeOffRequestsInterface,
  ImportTimeOffRequestsSessionInterface,
} from '@src/interfaces/importTimeOffRequests'
import {
  importTimeOffRequestsDurationColumn,
  importTimeOffRequestsEmployeeColumn,
  importTimeOffRequestsPolicyColumn,
  importTimeOffRequestsActionColumn,
  ImportTimeOffRequestsSelectInputOnChange,
  ImportTimeOffRequestsInputSelectCellOptions,
  ImportTimeOffRequestsInputOnChange,
  importTimeOffRequestsFromDateColumn,
  importTimeOffRequestsToDateColumn,
  importTimeOffRequestsFromTimePeriodColumn,
  importTimeOffRequestsToTimePeriodColumn,
  importTimeOffRequestsNoteColumn,
  importTimeOffRequestsApprovalStatusColumn,
  importTimeOffRequestsApprovalDateColumn,
  importTimeOffRequestsApproverColumn,
  importTimeOffRequestsRequestedOnDateColumn,
  importTimeOffRequestsUnitColumn,
} from '@src/constants/columns/importTimeOffRequests'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import { useOptions } from './common'
import { WrapperCss } from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'

const getRow = (
  sessionId: string,
  onInputOrDateRowChange: ImportTimeOffRequestsInputOnChange,
  onSelectRowChange: ImportTimeOffRequestsSelectInputOnChange,
  onDeleteRowSuccess: () => void,
  options: ImportTimeOffRequestsInputSelectCellOptions,
): RowInterface<ImportTimeOffRequestsInterface> => ({
  highlight: data => (isEmpty(data.errors) ? '' : Token.color.redActionBackground),
  cells: [
    {
      ...importTimeOffRequestsEmployeeColumn(onSelectRowChange, options.employees),
      width: 150,
    },
    {
      ...importTimeOffRequestsPolicyColumn(onSelectRowChange, options.policies),
      width: 150,
    },
    {
      ...importTimeOffRequestsFromDateColumn(onInputOrDateRowChange),
      width: 150,
    },
    {
      ...importTimeOffRequestsToDateColumn(onInputOrDateRowChange),
      width: 150,
    },
    {
      ...importTimeOffRequestsFromTimePeriodColumn(onSelectRowChange, options.periods),
      width: 120,
    },
    {
      ...importTimeOffRequestsToTimePeriodColumn(onSelectRowChange, options.periods),
      width: 120,
    },
    {
      ...importTimeOffRequestsDurationColumn(onInputOrDateRowChange),
      wrapperCss: () => WrapperCss,
      notHoverable: true,
      width: 120,
    },
    {
      ...importTimeOffRequestsNoteColumn(onInputOrDateRowChange),
      wrapperCss: () => WrapperCss,
      notHoverable: true,
      width: 150,
    },
    {
      ...importTimeOffRequestsApprovalStatusColumn(
        onSelectRowChange,
        options.approvalStatuses,
      ),
      width: 150,
    },
    {
      ...importTimeOffRequestsApprovalDateColumn(onInputOrDateRowChange),
      width: 150,
    },
    {
      ...importTimeOffRequestsApproverColumn(onSelectRowChange, options.employees),
      width: 150,
    },
    {
      ...importTimeOffRequestsRequestedOnDateColumn(onInputOrDateRowChange),
      width: 150,
    },
    {
      ...importTimeOffRequestsUnitColumn(onSelectRowChange, options.units),
      width: 150,
    },
    {
      ...importTimeOffRequestsActionColumn(onDeleteRowSuccess, sessionId),
      width: 150,
    },
  ],
})

interface CustomActionsInterface {
  customActions?: (options: {
    refetch: () => void
    disabled: boolean
    onSubmit: () => Promise<any>
  }) => React.ReactNode
}

interface TimeOffRequestsSessionProps extends CustomActionsInterface {
  customSuccessActions?: React.ReactNode
}

export const TimeOffRequestsSession = ({
  customSuccessActions,
  customActions,
}: TimeOffRequestsSessionProps) => {
  const params = useParams<{ id: string }>()

  const { data, refetch } = useGetImporTimeOffRequestsSessionData(params.id)

  if (!data) {
    return <PageLoading />
  }

  if (
    data.state.id === 'pending' ||
    data.state.id === 'processing_file' ||
    data.state.id === 'applying'
  ) {
    return <ProcessingFileState />
  }

  if (data.state.id === 'success') {
    return (
      <>
        <PageBody>
          <SuccessWidget
            title="Upload successful"
            type="success"
            text="Time off request data imported successfully"
            maxWidth="100%"
          />
        </PageBody>

        {customSuccessActions || (
          <PageActions>
            <Button
              onClick={() => goBack(ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS)}
              elevated
            >
              Done
            </Button>
          </PageActions>
        )}
      </>
    )
  }

  if (data.state.id === 'failure') {
    return (
      <PageBody>
        <SuccessWidget
          title="Task failed!"
          type="error"
          text="There was an error with the upload"
          maxWidth="100%"
        />
      </PageBody>
    )
  }

  return <Table refetchSessionData={refetch} customActions={customActions} />
}

interface TableProps extends CustomActionsInterface {
  refetchSessionData: () => Promise<
    QueryObserverResult<ImportTimeOffRequestsSessionInterface, Error>
  >
}

const Table = ({ refetchSessionData, customActions }: TableProps) => {
  const [submitPending, setSubmitPending] = useState(false)
  const [tableLoading, setTableLoading] = useState(false)

  const params = useParams<{ id: string }>()

  const table = useTable({
    getItems: getTimeOffRequestsUploadSessionTable(params.id),
  })

  const options = useOptions()

  const onDeleteRow = () => {
    table.refresh()
  }

  const setTableRowData = (row: ImportTimeOffRequestsInterface) => {
    table.setData(
      produce(table.data, draft => draft.map(r => (r.id === row.id ? row : r))),
    )
  }

  const updateTableRow = (row: ImportTimeOffRequestsInterface) => {
    setTableRowData(row)
    setTableLoading(true)

    editImportTimeOffRequestsSessionRow(params.id, row.id, row)
      .then(response => setTableRowData(response.data))
      .finally(() => setTableLoading(false))
  }

  const onSelectRowChange: ImportTimeOffRequestsSelectInputOnChange = (
    row,
    option,
    field,
  ) => {
    const initialData = get(row.data, field)

    const optionValue =
      field === 'from_time_period' ||
      field === 'to_time_period' ||
      field === 'unit' ||
      field === 'approval_status'
        ? option?.id
        : option?.name

    if (initialData !== optionValue) {
      const updatedRow = set(cloneDeep(row), `data.${field}`, optionValue)

      if (field === 'unit' && optionValue === 'day') {
        set(updatedRow.data, 'from_time_period', 'all_day')
        set(updatedRow.data, 'to_time_period', 'all_day')
      }
      if (field === 'unit' && optionValue === 'hour') {
        unset(updatedRow.data, 'from_time_period')
        unset(updatedRow.data, 'to_time_period')
      }

      updateTableRow(updatedRow)
    }
  }

  const onInputOrDateRowChange: ImportTimeOffRequestsInputOnChange = (
    row,
    value,
    field,
  ) => {
    const initialData = get(row.data, field)

    if (initialData === value || (!initialData && !value)) {
      return
    }
    const updatedRow = set(cloneDeep(row), `data.${field}`, value)
    updateTableRow(updatedRow)
  }

  const onSubmit = async () => {
    setSubmitPending(true)

    try {
      await applyImportTimeOffRequestsSession(params.id)
      await refetchSessionData()
    } finally {
      setSubmitPending(false)
    }
  }

  const disabled = table.data.some(r => !isEmpty(r.errors)) || table.loading

  return (
    <>
      <TableWrapper>
        <AdjustableTable
          name={TableNames.UploadTimeOffRequests}
          {...table}
          loading={table.loading || tableLoading}
          useWindowScroll
          row={getRow(
            params.id,
            onInputOrDateRowChange,
            onSelectRowChange,
            onDeleteRow,
            options,
          )}
          hideCountAndButtonSection
          lockFirstColumn={false}
          rowHeight="medium"
        />
      </TableWrapper>

      {table.data?.length
        ? customActions?.({
            refetch: refetchSessionData,
            disabled,
            onSubmit,
          }) || (
            <PageActions>
              <Button
                onClick={onSubmit}
                pending={submitPending}
                disabled={disabled}
                elevated
              >
                Continue
              </Button>
            </PageActions>
          )
        : null}
    </>
  )
}
