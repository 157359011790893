import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { ReCaptchaKeyInterface } from '@src/interfaces/reCaptcha'

export const useGetRecaptchaKey = () =>
  useFetch<ReCaptchaKeyInterface>(
    API.RECAPTCHA,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  )
