import React from 'react'
import { Text, VStack, HStack, Flex, BottomSheet } from '@revolut/ui-kit'

import { ChatMessageInterface, CommentsAPIInterface } from '@src/interfaces/chat'
import { ChatMessageType } from './common'
import ChatTagsManager from './ChatTagsManager'
import ChatMessagesList, { ChatMessagesListProps } from './ChatMessagesList'

export interface ChatPopupProps
  extends Omit<
    ChatMessagesListProps,
    'type' | 'data' | 'onEdit' | 'onArchive' | 'onResolve'
  > {
  type?: ChatMessageType
  commentsApi: CommentsAPIInterface
  open: boolean
  onClose: () => void
  messages: ChatMessageInterface[]
  isLoading: boolean
}

export const ChatPopup = (props: ChatPopupProps) => {
  const {
    type = ChatMessageType.Comment,
    commentsApi,
    canEdit,
    open,
    onClose,
    messages,
    isLoading,
    refetch,
    ...chatMessagesListProps
  } = props

  return (
    <ChatTagsManager>
      <BottomSheet open={open} onClose={onClose}>
        <VStack space="s-12">
          <HStack>
            <Flex flex={1} width="100%">
              <Text variant="h5">Comments</Text>
            </Flex>
          </HStack>
          <ChatMessagesList
            type={type}
            onEdit={commentsApi.editComment}
            onArchive={commentsApi.archiveComment}
            onResolve={commentsApi.resolveComment}
            isLoading={isLoading}
            data={messages}
            maxShown={undefined}
            refetch={refetch}
            {...chatMessagesListProps}
          />
        </VStack>
      </BottomSheet>
    </ChatTagsManager>
  )
}
