import React, { useMemo } from 'react'
import { localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import isSameDay from 'date-fns/isSameDay'
import toString from 'lodash/toString'
import { useParams } from 'react-router-dom'
import { Calendar, Dropdown, Input, Token, useDropdown } from '@revolut/ui-kit'

import { useNonWorkingDays } from '@src/api/timeOff'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'
import { formatDate, formatPeriod } from '@src/utils/format'
import { RouteParams } from '..'

const ALL_DAY_OPTION = { id: 'all_day', name: 'All day' } as const

const utcToLocalConvert = (value: string, unit: 'day' | 'hour' | null) => {
  if (unit === 'day') {
    return utcToLocalDate(value)
  }
  return new Date(value)
}

const localToUtcConvert = (value: Date, unit: 'day' | 'hour' | null) => {
  if (unit === 'day') {
    return localDateToUtc(value)
  }
  return value.toISOString()
}

export const DateRangePicker = () => {
  const { employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<EmployeeTimeOffRequestInterface>()

  const { getAnchorProps, getTargetProps } = useDropdown()

  const { data: nonWorkingDays } = useNonWorkingDays(
    employeeId,
    localDateToUtc(new Date(2015, 0, 1)),
    // End of next year
    localDateToUtc(new Date(new Date().getFullYear() + 1, 11, 31)),
    values.balance?.id,
  )

  const disabled =
    values?.field_options?.read_only?.includes('from_date_time') ||
    values?.field_options?.read_only?.includes('to_date_time')

  const fromField = useLapeField('from_date_time')
  const toField = useLapeField('to_date_time')
  const error = fromField.error || toField.error
  const errorMessage = error && toString(error)
  const unit = values.balance?.unit?.id || null

  const events = useMemo(() => {
    return nonWorkingDays
      ?.filter(event => event.type === 'public_holiday')
      .map(event => ({
        color: Token.color.red,
        date: utcToLocalDate(event.day),
      }))
  }, [nonWorkingDays])

  const disabledDays = useMemo(() => {
    return nonWorkingDays?.map(event => utcToLocalDate(event.day))
  }, [nonWorkingDays])

  const dateRange = (() => {
    if (!values.from_date_time || !values.to_date_time) {
      return ''
    }
    if (isSameDay(new Date(values.from_date_time), new Date(values.to_date_time))) {
      return formatDate(utcToLocalConvert(values.from_date_time, unit))
    }
    return formatPeriod(
      utcToLocalConvert(values.from_date_time, unit),
      utcToLocalConvert(values.to_date_time, unit),
    )
  })()

  return (
    <>
      <Input
        label="Date range"
        value={dateRange}
        type="button"
        disabled={disabled}
        errorMessage={errorMessage}
        invalid={!!errorMessage}
        useIcon="ChevronDown"
        data-name="from_date_time to_date_time"
        data-testid="from_date_time to_date_time"
        {...getAnchorProps()}
      />
      <Dropdown width={343} maxHeight={null} {...getTargetProps()}>
        <Calendar
          variant="range"
          value={
            values.from_date_time && values.to_date_time
              ? {
                  from: utcToLocalConvert(values.from_date_time, unit),
                  to: utcToLocalConvert(values.to_date_time, unit),
                }
              : undefined
          }
          onChange={value => {
            const from = value?.from
            const to = value?.to

            if (unit === 'day') {
              values.from_time_period = ALL_DAY_OPTION
              values.to_time_period = ALL_DAY_OPTION
            }

            if (from) {
              values.from_date_time = localToUtcConvert(from, unit)
            }
            if (to) {
              values.to_date_time = localToUtcConvert(to, unit)
            }
          }}
          events={events}
          disabledDays={disabledDays}
        />
      </Dropdown>
    </>
  )
}
