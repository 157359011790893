import React from 'react'
import { Item, Text, VStack } from '@revolut/ui-kit'

export const LocationsIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>How to setup Legal Entities & Locations?</Item.Title>
        <Item.Description>
          <VStack gap="s-16">
            <Text>
              Setting up entities and locations in the Revolut People tool involves
              creating organisational divisions or units (entities) and linking specific
              physical sites or offices (locations) to them. This enables HR to manage
              hiring and payroll efficiently, and maintain compliance by categorising and
              organising the workforce based on geographical or structural segments within
              the tool.
            </Text>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
