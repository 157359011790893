import React, { useMemo, useState } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { IdAndName } from '@src/interfaces'
import { HStack, IconButton, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import Tooltip from '@components/Tooltip/Tooltip'
import { SwitchItemsSidebar } from '@components/SwitchItemsSidebar/SwitchItemsSidebar'

type SwitcherButtonsProps = {
  loading?: boolean
  next?: unknown
  nextLabel?: string
  prev?: unknown
  prevLabel?: string
  onMenuClick?: VoidFunction
  onNextClick: VoidFunction
  onPrevClick: VoidFunction
}

export const SwitcherButtons = ({
  loading = false,
  next,
  nextLabel,
  prev,
  prevLabel,
  onMenuClick,
  onNextClick,
  onPrevClick,
}: SwitcherButtonsProps) => {
  return (
    <HStack gap="s-16">
      <Tooltip placement="top" text={prevLabel} hide={!prev || loading}>
        <IconButton
          useIcon="ChevronLeft"
          color={!prev ? Token.color.greyTone20 : undefined}
          disabled={!prev || loading}
          onClick={() => {
            if (prev) {
              onPrevClick()
            }
          }}
        />
      </Tooltip>
      <Tooltip placement="top" text={nextLabel} hide={!next || loading}>
        <IconButton
          useIcon="ChevronRight"
          disabled={!next || loading}
          color={!next ? Token.color.greyTone20 : undefined}
          onClick={() => {
            if (next) {
              onNextClick()
            }
          }}
        />
      </Tooltip>
      {onMenuClick && (
        <IconButton
          useIcon="Menu"
          disabled={loading}
          onClick={() => {
            onMenuClick()
          }}
        />
      )}
    </HStack>
  )
}

type Props = {
  currentId?: number | string
  localStorageKey: string
  route: string
  useLayout?: boolean
  title: string
}

export const SwitcherButtonsWithSidebar = ({
  currentId,
  localStorageKey,
  route,
  useLayout = false,
  title,
}: Props) => {
  const [savedDataContext] = useLocalStorage<{
    data?: IdAndName[]
  }>(localStorageKey, { data: [] })
  const [openSidebar, setOpenSidebar] = useState(false)

  const currentIdx = savedDataContext.data?.findIndex(item => item.id === currentId)

  const sidebarItems = useMemo(
    () =>
      savedDataContext.data?.map(item => ({
        id: item.id,
        avatar: {
          id: item.id,
          name: item.name,
        },
      })) || [],
    [savedDataContext],
  )

  if (
    !savedDataContext.data?.length ||
    !currentId ||
    currentIdx === undefined ||
    currentIdx === -1
  ) {
    return null
  }

  const next = savedDataContext.data[currentIdx + 1]
  const prev = savedDataContext.data[currentIdx - 1]

  return (
    <>
      <SwitcherButtons
        next={next}
        nextLabel={next?.name}
        prev={prev}
        prevLabel={prev?.name}
        onMenuClick={() => {
          setOpenSidebar(!openSidebar)
        }}
        onNextClick={() => {
          navigateTo(pathToUrl(route, { id: next?.id }))
        }}
        onPrevClick={() => {
          navigateTo(pathToUrl(route, { id: prev?.id }))
        }}
      />
      <SwitchItemsSidebar
        title={title}
        items={sidebarItems}
        isOpen={openSidebar}
        onClose={() => setOpenSidebar(false)}
        selectedIndex={currentIdx}
        onSelect={item => {
          navigateTo(pathToUrl(route, { id: item.id }))
        }}
        avatarUrlFunc={id => pathToUrl(route, { id })}
        useLayout={useLayout}
      />
    </>
  )
}
