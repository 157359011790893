import React, { useEffect } from 'react'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { lineManagerColumn, recruiterNameColumn } from '@src/constants/columns/employee'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/TableV2/hooks'
import { TableNames } from '@src/constants/table'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { requisitionsRequests } from '@src/api/requisitions'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import {
  requisitionIdColumn,
  createRequisitionTitleColumn,
  requisitionHeadcountColumn,
  requisitionQueuePosition,
  requisitionStatusColumn,
} from '@src/constants/columns/requisition'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { teamDepartmentColumn, teamNameColumn } from '@src/constants/columns/team'
import { seniorityNameRequisitionsColumn } from '@src/constants/columns/seniority'
import { locationNameRequisitionColumn } from '@src/constants/columns/location'
import { TableProps } from '@src/pages/Forms/CustomFieldsForm/FilterTable/types'
import { filterChangeHandler } from '@src/pages/Forms/CustomFieldsForm/FilterTable/common'

const Row: RowInterface<RequisitionInterface> = {
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id })),
  disabled: data => data.status === Statuses.rejected || data.status === Statuses.closed,
  cells: [
    {
      ...requisitionIdColumn,
      width: 90,
    },
    {
      ...createRequisitionTitleColumn(false),
      width: 200,
    },
    {
      ...specialisationRoleNameColumn,
      width: 160,
    },
    {
      ...teamDepartmentColumn,
      width: 130,
    },
    {
      ...teamNameColumn,
      width: 130,
    },
    {
      ...seniorityNameRequisitionsColumn,
      width: 100,
    },
    {
      ...locationNameRequisitionColumn,
      width: 100,
    },
    {
      ...lineManagerColumn,
      width: 130,
    },
    {
      ...requisitionHeadcountColumn,
      width: 100,
    },
    {
      ...recruiterNameColumn,
      width: 130,
    },
    {
      ...requisitionQueuePosition,
      width: 100,
    },
    {
      ...requisitionStatusColumn,
      width: 100,
    },
  ],
}

interface RequisitionsFilterTableProps extends TableProps {
  filter?: FilterByInterface[]
}

export const RequisitionsFilterTable = ({
  isPreview,
  filter,
  onFilterChange,
}: RequisitionsFilterTableProps) => {
  const tableOptions = {
    disableQuery: true,
  }

  const table = useTable<RequisitionInterface>(
    requisitionsRequests,
    filter,
    [],
    tableOptions,
  )

  useEffect(() => {
    onFilterChange?.(filterChangeHandler(table.filterBy))
  }, [table.filterBy])

  return (
    <AdjustableTable
      name={TableNames.RequisitionsFilter}
      row={Row}
      {...table}
      noReset={isPreview}
      disabledFiltering={isPreview}
      useWindowScroll
      onFilterChange={f => table?.onFilterChange(f, false)}
    />
  )
}
