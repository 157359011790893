import React from 'react'
import { Box, DetailsCellSkeleton, Group } from '@revolut/ui-kit'

const InterviewSlotsSkeleton = () => {
  return (
    <>
      <Box mt="s-16" data-testid="slots-skeleton">
        <Group>
          <DetailsCellSkeleton />
        </Group>
      </Box>
      {Array.from(Array(4).keys()).map(i => (
        <Box mt="s-16" key={i}>
          <Group>
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
          </Group>
        </Box>
      ))}
    </>
  )
}

export default InterviewSlotsSkeleton
