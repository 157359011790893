import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Check, Cross, Delete } from '@revolut/icons'
import {
  Box,
  Token,
  DetailsCell,
  DetailsCellSkeleton,
  Group,
  MoreBar,
  Text,
  VStack,
  BarSkeleton,
} from '@revolut/ui-kit'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import {
  approveResignationFile,
  getResignationCommentsAPI,
  rejectResignationFile,
  resignationReasonsFormRequests,
} from '@src/api/employeeResignation'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PageBody } from '@src/components/Page/PageBody'
import { UploadedFileToDownload } from '@src/components/Inputs/FileUploader/UploadedFile'
import { RouteParams } from '../../types'
import { useResignationPermissions } from '../../hooks'
import { SubmitPopup } from '../../Components/SubmitPopup'
import { LetterFileWithStatus } from '../../Components/LetterFileWithStatus'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { formatDate } from '@src/utils/format'

export type Props = {
  resignation?: EmployeeResignationInterface
  approvalSteps: ApprovalFlowResponse | null
  getApprovals: () => Promise<void>
  updateResignation: React.Dispatch<
    React.SetStateAction<EmployeeResignationInterface | undefined>
  >
}
const ResignationLetterForm = ({
  resignation,
  approvalSteps,
  getApprovals,
  updateResignation,
}: Props) => {
  const { id, employeeId } = useParams<RouteParams>()
  const { values } = useLapeContext<{ rejection_reason: string }>()
  const { canView, canApprove, canEdit, canComment } =
    useResignationPermissions(resignation)

  const [isSubmitApprovePopupShown, setIsSubmitApprovePopupShown] =
    useState<boolean>(false)
  const [isSubmitApprovePopupPending, setIsSubmitApprovePopupPending] =
    useState<boolean>(false)

  const [isSubmitRejectPopupShown, setIsSubmitRejectPopupShown] = useState<boolean>(false)
  const [isSubmitRejectPopupPending, setIsSubmitRejectPopupPending] =
    useState<boolean>(false)

  const [isSubmitDeletePopupShown, setIsSubmitDeletePopupShown] = useState<boolean>(false)
  const [isSubmitDeletePopupPending, setIsSubmitDeletePopupPending] =
    useState<boolean>(false)
  const recentApprovalStep = approvalSteps?.length
    ? approvalSteps[approvalSteps.length - 1]
    : undefined
  const showActionBar = canApprove || canEdit

  if (!resignation) {
    return (
      <VStack space="s-16">
        <BarSkeleton />
        <Group>
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
        </Group>
      </VStack>
    )
  }
  if (!canView) {
    return null
  }
  return (
    <PageBody>
      <VStack space="s-16">
        {showActionBar && (
          <MoreBar>
            {canApprove && recentApprovalStep?.approval_status === 'pending' && (
              <>
                <MoreBar.Action
                  useIcon={Check}
                  onClick={() => setIsSubmitApprovePopupShown(true)}
                >
                  Approve
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon={Cross}
                  variant="negative"
                  onClick={() => setIsSubmitRejectPopupShown(true)}
                >
                  Reject
                </MoreBar.Action>
              </>
            )}
            {canEdit && resignation.notice_file && (
              <MoreBar.Action
                useIcon={Delete}
                variant="negative"
                onClick={() => setIsSubmitDeletePopupShown(true)}
              >
                Delete
              </MoreBar.Action>
            )}
          </MoreBar>
        )}
        <ApprovalFlow steps={approvalSteps} isLoading={false} />
        {resignation.notice_file ? (
          <UploadedFileToDownload
            id={resignation.notice_file.id}
            title={resignation.notice_file.name}
            size={resignation.notice_file.size}
          />
        ) : (
          <LetterFileWithStatus
            file={resignation.notice_file}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER_PREVIEW, {
              id,
              employeeId,
            })}
            employeeId={employeeId}
            resignationId={resignation.id}
            approval={recentApprovalStep}
          />
        )}

        {resignation?.notice_file && (
          <>
            <Text variant="h6" color={Token.color.greyTone50} use="div" mt="s-16">
              Details
            </Text>
            <Group>
              <DetailsCell>
                <DetailsCell.Title>Uploaded on</DetailsCell.Title>
                <DetailsCell.Content>
                  {formatDate(resignation.notice_file.created_date_time, 'EEEE, d MMMM')}
                </DetailsCell.Content>
              </DetailsCell>
              <DetailsCell>
                <DetailsCell.Title>Uploaded by</DetailsCell.Title>
                <DetailsCell.Content>
                  {resignation.notice_file.created_by?.display_name || '-'}
                </DetailsCell.Content>
              </DetailsCell>
            </Group>
          </>
        )}
        {canComment && (
          <CommentsSection
            api={getResignationCommentsAPI(employeeId, id)}
            disableTodolistFeature
          />
        )}
      </VStack>
      <SubmitPopup
        open={isSubmitDeletePopupShown}
        pending={isSubmitDeletePopupPending}
        title="Resignation will not be considered successfully submitted without resignation document. Are you sure you want to delete this attachment?"
        subtitle="Once that’s done, you will be able to add another document and get is approved by HR."
        onClose={() => setIsSubmitDeletePopupShown(false)}
        onSubmit={async () => {
          setIsSubmitDeletePopupPending(true)
          try {
            await resignationReasonsFormRequests.update(
              { notice_file: null },
              { id, employeeId },
            )
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT_PREVIEW, {
                employeeId,
                id,
              }),
            )
          } finally {
            setIsSubmitDeletePopupPending(false)
            setIsSubmitDeletePopupShown(false)
          }
        }}
      />
      <SubmitPopup
        open={isSubmitRejectPopupShown}
        pending={isSubmitRejectPopupPending}
        title="Are you sure you want to reject employee’s resignation letter?"
        subtitle='Resignation status will be changed to "Pending notice" and employee will be requested to upload the document once again. Please kindly let employee know why you reject the document.'
        onClose={() => setIsSubmitRejectPopupShown(false)}
        content={
          <Box mt="s-16">
            <LapeNewTextArea
              required
              name="rejection_reason"
              label="Reason for rejection"
            />
          </Box>
        }
        onSubmit={async () => {
          setIsSubmitRejectPopupPending(true)
          try {
            const { data } = await rejectResignationFile(
              { rejection_reason: values.rejection_reason },
              { employeeId, resignationId: id },
            )
            updateResignation(data)
            await getApprovals()
          } finally {
            setIsSubmitRejectPopupPending(false)
            setIsSubmitRejectPopupShown(false)
          }
        }}
      />
      <SubmitPopup
        open={isSubmitApprovePopupShown}
        pending={isSubmitApprovePopupPending}
        title="Are you sure you want to approve employee’s resigantion letter?"
        onClose={() => setIsSubmitApprovePopupShown(false)}
        onSubmit={async () => {
          setIsSubmitApprovePopupPending(true)
          try {
            const { data } = await approveResignationFile(employeeId, id)
            updateResignation(data)
            await getApprovals()
          } finally {
            setIsSubmitApprovePopupPending(false)
            setIsSubmitApprovePopupShown(false)
          }
        }}
      />
    </PageBody>
  )
}

export default ResignationLetterForm
