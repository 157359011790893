import { ReviewCategory, ReviewStatuses } from '@src/interfaces/performance'
import { ROUTES } from '@src/constants/routes'
import {
  ProbationCheckpoint,
  ProbationRecommendationInterface,
} from '@src/interfaces/probationReview'
import { AvatarSkeleton, Box, Flex, TextSkeleton, VStack } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { getProbationRecommendations } from '@src/api/probationReview'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EmptyTimelineElement } from '@src/pages/EmployeeProfile/Layout/Performance/PerfomanceStepDetails'

const ReviewDeliverablesPath: { [key: string]: string } = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_FINAL_RESULT.GENERAL,
}

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Recommendation',
  [ButtonStates.Edit]: 'Edit Recommendation',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
}

export const RecommendationStepReviewers = ({
  checkpoint,
  reviewCategory,
  cycleId,
  employeeId,
  isNewDesign = false,
}: {
  checkpoint: ProbationCheckpoint
  reviewCategory: ReviewCategory
  cycleId: string
  employeeId: number
  isNewDesign?: boolean
}) => {
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()

  const fetchRecommendations = async () => {
    try {
      setLoading(true)
      const resp = await getProbationRecommendations(employeeId, cycleId)
      setRecommendations(resp.data.results)
    } catch (e) {
      showStatusPopup({
        title: 'Something went wrong',
        description: getStringMessageFromError(e),
        status: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchRecommendations()
  }, [])

  const buttonDisabled = !checkpoint?.can_be_submitted

  const onClickAddReview = () => {
    const id = checkpoint.recommendation?.id
    const cpEmployeeId = checkpoint.recommendation?.reviewed_employee?.id
    const cpCycleId = checkpoint.recommendation?.cycle?.id
    if (id && cpEmployeeId && cpCycleId) {
      navigateTo(
        pathToUrl(ReviewDeliverablesPath[reviewCategory], {
          id,
          employeeId: cpEmployeeId,
          cycleId: cpCycleId,
        }),
      )
    }
  }

  const state =
    !checkpoint?.recommendation ||
    checkpoint.recommendation.status === ReviewStatuses.pending
      ? ButtonStates.Add
      : ButtonStates.Edit
  return (
    <>
      {isNewDesign ? (
        <Box width="100%">
          {loading ? (
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap="s-16" alignItems="center">
                <AvatarSkeleton size={40} />
                <Flex gap="s-2" flexDirection="column">
                  <TextSkeleton size={16} variant="emphasis1" />
                  <TextSkeleton size={14} variant="body2" />
                </Flex>
              </Flex>
              <TextSkeleton size={14} variant="emphasis2" />
            </Flex>
          ) : (
            <VStack gap="s-8">
              {recommendations && recommendations.length > 0 && (
                <StepReviewersList
                  isProfileLink
                  isNewDesign={isNewDesign}
                  reviews={recommendations}
                  onClickAddReview={onClickAddReview}
                  buttonDisabled={buttonDisabled}
                  icon={ButtonIcon[state]}
                  title={ButtonTitles[state]}
                />
              )}
            </VStack>
          )}
          {!recommendations?.length && !loading && <EmptyTimelineElement />}
        </Box>
      ) : (
        <Box>
          <VStack gap="s-8">
            {recommendations && (
              <StepReviewersList
                isProfileLink
                isNewDesign={isNewDesign}
                reviews={recommendations}
                onClickAddReview={onClickAddReview}
                buttonDisabled={buttonDisabled}
                icon={ButtonIcon[state]}
                title={ButtonTitles[state]}
              />
            )}
          </VStack>
        </Box>
      )}
    </>
  )
}
