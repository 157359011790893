import React from 'react'
import { Color, createChain, DetailsCell, Text } from '@revolut/ui-kit'
import { HStack, Skeleton } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { LocationInterface } from '@src/interfaces/requisitions'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useGetOfferFormBenchmark } from '@src/api/benchmarks'
import BenchmarkBand from '@src/pages/Forms/RequisitionForm/General/BenchmarkBand'
import { useGlobalSettings } from '@src/api/settings'

type Props = {
  specialisation?: SpecialisationInterface
  location?: LocationInterface
  seniority?: SeniorityInterface
}

const TARGET_CURRENCY = 'GBP'

const BenchmarkWidget = ({ specialisation, location, seniority }: Props) => {
  const { data: benchmark, isLoading } = useGetOfferFormBenchmark(
    seniority?.id,
    specialisation?.id,
    location?.id,
    TARGET_CURRENCY,
  )

  const renderContent = () => {
    if (isLoading) {
      return <Skeleton width={150} />
    }

    if (benchmark) {
      return (
        <BenchmarkBand
          localLowerBand={benchmark.lower_band}
          localUpperBand={benchmark.upper_band}
          localCurrencyCode={benchmark.currency.iso_code}
          lowerBand={benchmark.exchanged_lower_band}
          upperBand={benchmark.exchanged_upper_band}
          currencyCode={benchmark.target_currency?.iso_code || TARGET_CURRENCY}
        />
      )
    }

    return (
      <Text color={Color.ORANGE}>
        <HStack gap="s-8" align="center">
          Benchmark missing
          <ExclamationTriangle size={16} />
        </HStack>
      </Text>
    )
  }

  return (
    <DetailsCell data-testid="salary-benchmark">
      <DetailsCell.Title>
        Salary band for{' '}
        {createChain(', ')(seniority?.name, specialisation?.name, location?.name)}
      </DetailsCell.Title>
      <DetailsCell.Content>{renderContent()}</DetailsCell.Content>
    </DetailsCell>
  )
}

export default (props: Props) => {
  const {
    settings: { compensation_enabled },
  } = useGlobalSettings()
  if (!compensation_enabled) {
    return null
  }
  return <BenchmarkWidget {...props} />
}
