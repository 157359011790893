import React, { useState, useRef, RefObject } from 'react'
import { Flex, Button, InputGroup, StatusPopup, Layout, Header } from '@revolut/ui-kit'

import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { NonFieldErrors } from './common'
import { resetPassword } from '@src/api/login'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { arrayErrorsToFormError } from '@src/utils/form'
import { ReCaptcha } from '@components/ReCaptcha/ReCaptcha'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'

interface ResetPasswordFormInterface {
  email: string
  captcha: string
}

interface ResetPasswordFormProps {
  captchaRef: RefObject<ReCaptchaHandle>
}

const ResetPasswordForm = ({ captchaRef }: ResetPasswordFormProps) => {
  const { values, isSubmitting, apiErrors, errors, submit } =
    useLapeContext<ResetPasswordFormInterface>()

  const nonFieldErrors = Object.entries(apiErrors)
    .filter(([key]) => key !== 'email')
    .map(([, value]) => value)

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="email"
          label="Email"
          hasError={!!apiErrors.email}
          message={apiErrors.email}
          hideOptional
        />
        <NonFieldErrors nonFieldErrors={nonFieldErrors} />
        <Flex alignItems="center" flexDirection="column" my="s-16">
          <ReCaptcha
            onVerify={value => {
              values.captcha = value
            }}
            name="captcha"
            error={errors?.captcha}
            ref={captchaRef}
          />
        </Flex>
      </InputGroup>

      <Layout.ActionsFill>
        <Button onClick={() => submit()} pending={isSubmitting} type="submit" elevated>
          Reset password
        </Button>
      </Layout.ActionsFill>
    </>
  )
}

export const ResetPassword = () => {
  const [resetPasswordPopupOpen, setResetPasswordPopupOpen] = useState(false)
  const captchaRef = useRef<ReCaptchaHandle>(null)

  const handleResetPassword = async (
    form: LapeFormInterface<ResetPasswordFormInterface>,
  ) => {
    let formValues = form.values
    const token = await captchaRef.current?.verify()
    if (token) {
      formValues = { ...form.values, captcha: token }
    }

    return resetPassword(formValues)
      .then(({ data }) => {
        setResetPasswordPopupOpen(true)
        return data
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        form.apiErrors = errors
        captchaRef.current?.reset()
        throw error
      })
  }

  const onClose = () => navigateReplace(pathToUrl(ROUTES.LOGIN.MAIN))

  return (
    <>
      <Flex flexDirection="column" mt={{ all: '80px', lg: 'auto' }} mb="auto">
        <Header>
          <Header.BackButton
            onClick={() => goBack(ROUTES.LOGIN.MAIN)}
            aria-label="Back to login"
          />
          <Header.Title>Reset your password</Header.Title>
        </Header>

        <LapeForm onSubmit={handleResetPassword}>
          <ResetPasswordForm captchaRef={captchaRef} />
        </LapeForm>
      </Flex>

      <StatusPopup
        variant="success-result"
        open={resetPasswordPopupOpen}
        onClose={onClose}
      >
        <StatusPopup.Title>
          You should receive a password reset email soon!
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={onClose} elevated>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>
    </>
  )
}
