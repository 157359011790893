import React, { MouseEvent, useState } from 'react'
import { Box, ActionButtonProps, Action } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'
import { CellActionButton } from '@components/TableV2/Buttons/CellActionButton'

interface LoadingActionProps extends ActionButtonProps {
  onSubmit: () => Promise<any>
}

const LoadingAction = ({ onSubmit, children, ...rest }: LoadingActionProps) => {
  const [loading, setLoading] = useState(false)
  const isNewTable = useIsNewTable()

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  if (!isNewTable) {
    return (
      <Action style={{ fontSize: 13 }} disabled={loading} onClick={handleClick}>
        {loading ? <Box minWidth={80} /> : children}
      </Action>
    )
  }

  return (
    <CellActionButton
      style={{ fontSize: 14 }}
      {...rest}
      disabled={loading}
      pending={loading}
      onClick={handleClick}
    >
      {loading ? <Box minWidth={80} /> : children}
    </CellActionButton>
  )
}

export default LoadingAction
