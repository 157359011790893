import { useState } from 'react'

import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInactivityInterface, EmployeeInterface } from '@src/interfaces/employees'
import {
  updateEmployeeInactivityData,
  useGetEmployeeInactivityData,
} from '@src/api/employees'
import { isInactivityScheduled } from './helpers'

type UseEmployeeInactivityDataParams = {
  employee: EmployeeInterface | undefined
}
export type UseEmployeeInactivityDataReturnType = {
  data: EmployeeInactivityInterface | undefined
  update: (newValues: EmployeeInactivityInterface) => void
  pending: boolean
  refetch: () => void
  isScheduled: boolean
  isPopupOpened: boolean
  setIsPopupOpened: (isOpened: boolean) => void
}
export const useEmployeeInactivityData = ({
  employee,
}: UseEmployeeInactivityDataParams): UseEmployeeInactivityDataReturnType => {
  const [pending, setPending] = useState(false)
  const [currentData, setCurrentData] = useState<EmployeeInactivityInterface>()
  const [isPopupOpened, setIsPopupOpened] = useState(false)

  const canChangeEmployeeStatus = employee?.field_options?.permissions?.includes(
    PermissionTypes.ChangeEmployeeStatus,
  )
  const {
    data: fetchedData,
    refetch,
    isLoading,
  } = useGetEmployeeInactivityData(
    employee?.id && canChangeEmployeeStatus ? employee.id : null,
  )

  const data = currentData || fetchedData
  const isScheduled = isInactivityScheduled(data)

  const updateEmployeeInactivity = async (newValues: EmployeeInactivityInterface) => {
    if (!employee?.id) {
      return
    }
    try {
      setPending(true)
      const res = await updateEmployeeInactivityData(employee.id, newValues)
      setCurrentData(res.data)
    } finally {
      setPending(false)
    }
  }

  return {
    data,
    pending: pending || isLoading,
    update: updateEmployeeInactivity,
    refetch,
    isScheduled,
    isPopupOpened,
    setIsPopupOpened,
  }
}
