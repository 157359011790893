import { Avatar, HStack, Text } from '@revolut/ui-kit'
import React, { useEffect, useRef } from 'react'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackStatus,
  InterviewRoundInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { format } from 'date-fns'
import { connect } from 'lape'
import SideBar from '@components/SideBar/SideBar'
import { getAvatarUrl } from '@src/utils/employees'
import { InterviewFeedback } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedback'

type Props = {
  isOpen: boolean
  onExit: () => void
  scorecard?: InterviewFeedbackInterface
  round?: InterviewRoundInterface
  title?: string
  stageType?: InterviewType
}

export const InterviewFeedbackSidebar = connect(
  ({ isOpen, onExit, scorecard, round, title, stageType }: Props) => {
    const scrollRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      scrollRef.current?.scrollIntoView({ block: 'center' })
    }, [scorecard?.id])

    if (!isOpen) {
      return null
    }

    return (
      <SideBar
        title={title}
        variant="wide"
        onClose={onExit}
        subtitle={
          scorecard?.interviewer ? (
            <HStack gap="s-8">
              <Avatar
                style={{ flexShrink: 0 }}
                size={24}
                image={getAvatarUrl(scorecard?.interviewer?.avatar)}
              >
                {!scorecard?.interviewer?.avatar ? (
                  <Text>{scorecard?.interviewer?.full_name?.[0]}</Text>
                ) : undefined}
              </Avatar>
              {scorecard?.status === InterviewFeedbackStatus.completed ? (
                <Text>
                  Submitted by {scorecard?.interviewer?.full_name} on
                  {scorecard?.updated_date_time
                    ? format(
                        new Date(scorecard.updated_date_time),
                        ' d MMM yyyy, hh:mm a',
                      )
                    : ''}
                </Text>
              ) : (
                <Text>Pending with {scorecard?.interviewer?.full_name}</Text>
              )}
            </HStack>
          ) : undefined
        }
      >
        <InterviewFeedback
          ref={scrollRef}
          round={round}
          scorecard={scorecard}
          stageType={stageType}
        />
      </SideBar>
    )
  },
)
