import React, { useMemo, useState } from 'react'
import { Button, Header, Popup, TabBar } from '@revolut/ui-kit'
import StageDetails from '@src/pages/Forms/HiringStage/FormSections/StageDetails'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import ScorecardTab from '@src/pages/OnboardingChecklist/HiringProcess/components/ScorecardTab'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const isCVScreening = (values?: Partial<HiringProcessInterface>) =>
  values?.stage_type?.id === 'cv_screening'

type HiringStagePopupProps = {
  disableStageType: boolean
  isSpecialisation: boolean
  generateScorecard: boolean
  onClose: (result?: HiringProcessInterface) => void
  onRefresh: () => void
}

type TabState = 'stageDetails' | 'scorecard' | 'interviewers' | null

const HiringStagePopup = ({
  disableStageType,
  isSpecialisation,
  generateScorecard,
  onClose,
  onRefresh,
}: HiringStagePopupProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const [currentTab, setCurrentTab] = useState<TabState>('stageDetails')
  const isNewStage = !values?.id
  const title = useMemo(() => {
    return isNewStage
      ? `Add ${values?.stage_type?.name ?? 'stage'}`
      : `Edit ${values.title}`
  }, [])
  const disableNextInStageDetailsTab = isCVScreening(values)
    ? !(values.title && values.stage_type)
    : !(values.title && values.stage_type && values.duration)
  const disableNextInScorecardTab =
    values?.stage_type?.id === 'skills_round'
      ? disableNextInStageDetailsTab
      : !values.scorecard_template && !disableNextInStageDetailsTab
  const disableFinishInInterviewersTab =
    !(values?.interviewers_groups?.length || values?.interviewers?.length) &&
    !disableNextInScorecardTab
  return (
    <Popup open onClose={onClose} size="lg" preventUserClose shouldKeepMaxHeight>
      <Header variant="compact">
        <Header.Title>{title}</Header.Title>
      </Header>
      <TabBar mb="s-24" value={currentTab} onChange={setCurrentTab}>
        <TabBar.Item to="stageDetails">Stage details</TabBar.Item>
        {!isCVScreening(values) && (
          <>
            <TabBar.Item to="scorecard" disabled={disableNextInStageDetailsTab}>
              Scorecard
            </TabBar.Item>
            <TabBar.Item
              to="interviewers"
              disabled={disableNextInStageDetailsTab || disableNextInScorecardTab}
            >
              Interviewers
            </TabBar.Item>
          </>
        )}
      </TabBar>
      {currentTab === 'stageDetails' && (
        <StageDetails
          disableStageType={disableStageType}
          requiredDuration
          isSpecialisation={isSpecialisation}
          isRole={false}
        />
      )}
      {currentTab === 'scorecard' && (
        <ScorecardTab
          disableNext={disableNextInScorecardTab}
          generateScorecard={generateScorecard}
          stage={values}
          onCancel={() => {
            onClose()
          }}
          onNext={() => {
            setCurrentTab('interviewers')
          }}
        />
      )}
      {currentTab === 'interviewers' && <EligibleInterviewers isRequired />}
      {currentTab !== 'scorecard' && (
        <Popup.Actions horizontal>
          <Button
            variant="secondary"
            onClick={() => {
              onClose()
            }}
          >
            Cancel
          </Button>
          {currentTab === 'interviewers' || isCVScreening(values) ? (
            <NewSaveButtonWithPopup
              disabled={disableFinishInInterviewersTab}
              successText={
                isNewStage
                  ? `New ${values.stage_type?.name} saved successfully`
                  : `${values.title} saved successfully`
              }
              hideWhenNoChanges={false}
              onAfterSubmit={result => {
                onClose(result as HiringProcessInterface)
                onRefresh()
              }}
            >
              Finish
            </NewSaveButtonWithPopup>
          ) : (
            <Button
              disabled={disableNextInStageDetailsTab}
              onClick={() => {
                setCurrentTab(isCVScreening(values) ? 'interviewers' : 'scorecard')
              }}
            >
              Next
            </Button>
          )}
        </Popup.Actions>
      )}
    </Popup>
  )
}

type HiringStagePopupFormProps = HiringStagePopupProps & {
  stage?: Partial<HiringProcessInterface>
}

export default (props: HiringStagePopupFormProps) => {
  const api = props.isSpecialisation
    ? formHiringProcessStagesSpecialisationRequest
    : formHiringProcessStagesRequest
  return (
    <Form
      api={api}
      forceParams={{ id: props.stage?.id ? props.stage.id.toString() : undefined }}
      initialValues={props.stage}
      disableLocalStorageCaching
      disableLoading
    >
      <HiringStagePopup {...props} />
    </Form>
  )
}
