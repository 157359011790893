import React, { useState } from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { teamCXCsatDetractorsGraph } from '@src/api/teams'
import {
  BaseChartInner,
  timeRangeTabs,
} from '@components/Charts/BaseChart/BaseChartInner'
import { connect } from 'lape'
import { PerformanceTimeRange } from '@src/constants/api'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { getCXCsatDetractors } from '@src/api/cx'
import { CXCsatDetractorsInterface } from '@src/interfaces/businessDebts'
import {
  cxComplaintCreatedAtColumn,
  cxComplaintSummaryColumn,
  cxCSATLinkColumn,
  cxCSATScoreColumn,
} from '@src/constants/columns/cx'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { departmentCXCsatDetractorsGraph } from '@src/api/department'
import { getBarMonthFilter } from '@src/utils/graph'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { NoHeliosPermissions } from '@src/features/FormTabs/CX/components/NoAccessWidgets'
import CXTable from '@src/features/FormTabs/CX/components/CXTable'
import useChartTableSwitcher, {
  ChartTableTabs,
} from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { formatPercentage } from '@src/utils/format'
import { Flex, Token, Widget } from '@revolut/ui-kit'
import NewGrid from '@components/CommonSC/NewGrid'
import { TableNames } from '@src/constants/table'

interface StructureProps {
  data: TeamInterface | DepartmentInterface
  isTeam?: boolean
}

const ROW: RowInterface<CXCsatDetractorsInterface> = {
  cells: [
    {
      ...cxCSATScoreColumn,
      width: 100,
    },
    {
      ...cxComplaintSummaryColumn,
      width: 800,
      title: 'Tag',
    },
    {
      ...cxComplaintCreatedAtColumn,
      width: 200,
    },
    {
      ...cxCSATLinkColumn,
      width: 200,
    },
  ],
}

const SupportNPS = ({ data, isTeam }: StructureProps) => {
  const canViewComments = data.field_options.permissions?.includes(
    PermissionTypes.ViewDetractorsComments,
  )
  const { tabBar, currentTab, setCurrentTab } = useChartTableSwitcher()
  const { tabBar: chartRangeTabBar, currentTab: chartRange } = useTabBarSwitcher({
    tabs: timeRangeTabs,
    capitalize: true,
    defaultTab: PerformanceTimeRange.week,
  })
  const [filter, setFilter] = useState<FilterByInterface | undefined>()

  const initialFilterBy = [
    {
      filters: [{ id: data.id, name: `${data.id}` }],
      columnName: isTeam ? 'team__id' : 'department__id',
      nonResettable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'creation_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const tableTab = canViewComments ? (
    <CXTable<CXCsatDetractorsInterface>
      name={TableNames.CXSupportNPS}
      dataType="item"
      hideCount
      row={ROW}
      filter={filter}
      noDataMessage="No support NPS data"
      getItems={getCXCsatDetractors}
      initialFilter={initialFilterBy}
      initialSort={initialSortBy}
    />
  ) : (
    <NoHeliosPermissions />
  )

  const chartColors = [Token.color.blue, Token.color.cyan, Token.color.deepPink]

  return (
    <Widget
      display="flex"
      p="s-16"
      width="100%"
      flex={currentTab === ChartTableTabs.Chart ? '1 0' : ''}
      style={{ position: 'relative' }}
    >
      <Flex flexDirection="column" flex="1 0" width="100%">
        <Flex mb="s-24" alignItems="center">
          <Stat
            label="Support NPS (last 28 days)"
            val={formatPercentage(data.cx_csat_detractors_last_30_days)}
            color={data.cx_csat_detractors_last_30_days > 0 ? 'error' : 'grey-20'}
          />
        </Flex>
        <NewGrid
          width="min-content"
          mb="s-16"
          gridAutoFlow={{ all: 'row', md: 'column' }}
          gridGap="s-8"
        >
          {tabBar}
          {chartRangeTabBar}
        </NewGrid>
        {currentTab === ChartTableTabs.Table && tableTab}
        {currentTab === ChartTableTabs.Chart && (
          <Flex flexDirection="column" flex="1 0">
            <BaseChartInner
              colors={chartColors}
              isPercentageChart
              isNew
              fetchData={
                isTeam ? teamCXCsatDetractorsGraph : departmentCXCsatDetractorsGraph
              }
              id={data.id}
              graphTimeRange={chartRange}
              highlightHoveredCells
              onClick={bar => {
                if (!canViewComments) {
                  return
                }
                setCurrentTab(ChartTableTabs.Table)
                setFilter(getBarMonthFilter(bar.data.progress_datetime))
              }}
            />
          </Flex>
        )}
      </Flex>
    </Widget>
  )
}

export default connect(SupportNPS)
