import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { seniorityGenericNameColumn } from '@src/constants/columns/seniority'

import Stat from '@components/Stat/Stat'
import { getSeniorityList } from '@src/api/seniority'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'

const RowNotifications: RowInterface<SeniorityInterface> = {
  linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.SENIORITY.GENERAL, { id })),
  cells: [
    {
      ...seniorityGenericNameColumn,
      width: 100,
    },
  ],
}

const SenioritiesTable = () => {
  const table = useTable<SeniorityInterface>({ getItems: getSeniorityList })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total" val={table?.count} mr="s-32" />
      </Table.Widget.Info>
      <Table.Widget.Table>
        <AdjustableTable<SeniorityInterface>
          name={TableNames.Seniorities}
          hideCount
          useWindowScroll
          dataType="Seniority"
          row={RowNotifications}
          {...table}
          noDataMessage="List of seniority will appear here."
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default SenioritiesTable
