import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import ExistingCandidatesContent, {
  ExistingCandidatesContentProps,
} from '@components/ExistingCandidatesSidebar/ExistingCandidatesContent'

interface Props extends ExistingCandidatesContentProps {
  isOpen?: boolean
  onClose: () => void
}

const ExistingCandidatesSidebar = ({ isOpen, onClose, items }: Props) => {
  return (
    <SideBar
      title="Candidates in the system"
      isOpen={isOpen}
      onClose={onClose}
      variant="wide"
    >
      <ExistingCandidatesContent items={items} />
    </SideBar>
  )
}

export default ExistingCandidatesSidebar
