import React, { RefObject } from 'react'
import {
  ActionButton,
  Bar,
  Box,
  Flex,
  IconButton,
  Input,
  Text,
  Token,
} from '@revolut/ui-kit'

import { StateInterface } from './common'

type Props = {
  state: StateInterface
  inputValue: string
  setInputValue: (newValue: string) => void
  inputRef: RefObject<HTMLInputElement>
  onIssueResolved: () => void
  onSubmit: () => Promise<void>
  onRestartConversation: () => void
}
export const MessageInput = ({
  state,
  inputValue,
  setInputValue,
  inputRef,
  onIssueResolved,
  onSubmit,
  onRestartConversation,
}: Props) => {
  if (state.confirmEndConversation) {
    return (
      <Flex
        radius="widget"
        bg={Token.color.widgetBackground}
        alignItems="center"
        justifyContent="center"
        p="s-16"
        m="s-24"
      >
        <Flex width="100%" flexDirection="column" alignItems="center" gap="s-16">
          <Text
            use="div"
            variant="h6"
            color={Token.color.greyTone50}
            alignSelf="flex-start"
          >
            Did we solve your issue?
          </Text>
          <Bar>
            <ActionButton onClick={onRestartConversation}>No, continue</ActionButton>
            <ActionButton variant="accent" onClick={onIssueResolved}>
              Yes, thanks!
            </ActionButton>
          </Bar>
        </Flex>
      </Flex>
    )
  }

  const stateToLabel = (currentState: StateInterface) => {
    if (currentState.pending) {
      return 'Processing your request...'
    }
    if (currentState.inputMode === 'waitForCmdOptionSelect') {
      return 'Please, select one of the options above'
    }
    return 'Type a message...'
  }

  return (
    <Box py="s-24" bg={Token.color.groupedBackground}>
      <Input
        ref={inputRef}
        disabled={state.pending || state.inputMode === 'waitForCmdOptionSelect'}
        label={stateToLabel(state)}
        value={inputValue}
        onChange={e => setInputValue(e.currentTarget.value)}
        onKeyDown={async e => {
          if (!!inputValue && e.key === 'Enter') {
            await onSubmit()
          }
        }}
        renderAction={() => {
          const disabled = !inputValue || state.pending

          return (
            <IconButton
              aria-label="Send message"
              color={disabled ? Token.color.greyTone20 : Token.color.blue}
              disabled={disabled}
              useIcon="SendMessage"
              onClick={onSubmit}
            />
          )
        }}
      />
    </Box>
  )
}
