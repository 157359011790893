import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { payrollConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { PayrollIntro } from './Intro'
import { PayrollSettings } from './Settings'

const routes = [
  {
    title: 'Intro',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.PAYROLL.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.PAYROLL.INTRO,
    canView: [PermissionTypes.ViewPayrollPreferences],
    component: PayrollIntro,
  },
  {
    title: 'Settings',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.PAYROLL.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST.PAYROLL.SETTINGS,
    canView: [PermissionTypes.ViewPayrollPreferences],
    component: PayrollSettings,
  },
]

export const Payroll = () => {
  return <OnboardingChecklistContent config={payrollConfig} routes={routes} />
}
