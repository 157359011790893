import React from 'react'
import { MoreBar, MoreBarActionProps, MoreButton } from '@revolut/ui-kit'

export interface SettingsButtonProps extends MoreBarActionProps {
  useMoreButton?: boolean
}

const MoreBarButton = ({ useMoreButton = false, ...props }: SettingsButtonProps) => {
  const Component = useMoreButton ? MoreButton.Action : MoreBar.Action
  return <Component {...props} />
}

export default MoreBarButton
