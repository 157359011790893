import styled from 'styled-components'
import { HStack, type HStackProps, Box, Token } from '@revolut/ui-kit'
import React from 'react'

const PaginationItem = styled.li((props: { current?: boolean }) => [
  {
    listStyleType: 'none',
    transition: `
      ${Token.transition.width},
      ${Token.transition.height},
      ${Token.transition.opacity}`,
    opacity: 0.7,
    width: Token.space.s6,
    height: Token.space.s6,
    [`@media ${Token.media.xxl}`]: {
      height: Token.space.s12,
      width: Token.space.s12,
    },
  },
  props.current && {
    opacity: 1,
    width: Token.space.s8,
    height: Token.space.s8,
    [`@media ${Token.media.xxl}`]: {
      height: Token.space.s16,
      width: Token.space.s16,
    },
  },
])

const PaginationDot = styled.button({
  display: 'block',
  padding: 0,
  width: '100%',
  height: '100%',
  border: 'none',
  borderRadius: Token.radius.round,
  backgroundColor: Token.color.grey20,
  cursor: 'pointer',
})

const PaginationContainer = styled(HStack)({
  isolation: 'isolate',
  height: Token.space.s6,
  alignItems: 'center',
  justifyContent: 'center',
  gap: Token.space.s6,
  [`@media ${Token.media.xxl}`]: {
    bottom: Token.space.n12,
    gap: Token.space.s12,
  },
})

interface PageIndicatorProps extends Omit<HStackProps, 'onClick'> {
  current: number
  total: number
  onClick?: (index: number) => void
}

export const PageIndicator = ({
  current,
  total,
  onClick,
  ...rest
}: PageIndicatorProps) => {
  return (
    <Box use="nav" role="navigation" color="grey-20" {...rest}>
      <PaginationContainer use="ul">
        {Array.from({ length: total }).map((_, index) => {
          const active = index === current
          return (
            <PaginationItem key={index} current={active}>
              <PaginationDot
                aria-current={active}
                // TODO: add translations
                // Fix `Buttons must have discernible text` a11y error in tests
                aria-label={`${active ? 'Current page, ' : ''}Page ${index + 1}`}
                onClick={onClick ? () => onClick(index) : undefined}
              />
            </PaginationItem>
          )
        }, total)}
      </PaginationContainer>
    </Box>
  )
}
