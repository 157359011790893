import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from '@revolut/ui-kit'
import ErrorBoundary, { DefaultFallBack } from '@src/features/ErrorBoundary/ErrorBoundary'
import MatrixTable, { MatrixTableProps } from '@components/Table/MatrixTable'

export interface AdjustableMatrixTableProps<T> extends MatrixTableProps<T> {
  dataType?: string
  initialWidth?: number
}

const AdjustableMatrixTable = <T extends { [prop: string]: any; id?: number | string }>({
  dataType = 'Item',
  initialWidth,
  ...props
}: AdjustableMatrixTableProps<T>) => {
  const [width, setWidth] = useState<number | undefined>(initialWidth)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (containerRef?.current) {
      setWidth(containerRef.current?.getBoundingClientRect().width)
    }
  }, [containerRef?.current])

  return (
    <ErrorBoundary
      fallback={
        <Box width="100%">
          <DefaultFallBack />
        </Box>
      }
    >
      <Flex minHeight={0} height="100%" width="100%" ref={containerRef}>
        {width && <MatrixTable<T> {...props} width={width} />}
      </Flex>
    </ErrorBoundary>
  )
}

/** @deprecated Legacy component, don't use for the new features. Use Table instead */
export default AdjustableMatrixTable
