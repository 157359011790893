import React, { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import {
  Box,
  Cell,
  Color,
  Details,
  Ellipsis,
  ExpandableCell,
  Flex,
  HStack,
  Icon,
  Spinner,
  Text,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import { formatWithoutTimezone } from '@src/utils/format'
import { formatSnakeCase } from '@src/utils/string'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollTimelineChangeInterface,
} from '@src/interfaces/payrollV2'
import { ParsedDomainFieldChanges } from '..'
import { ValuesDiff } from '../ValuesDiff'
import { sendChangesToDeel } from '@src/api/payrollV2'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const getStatusColor = (
  status?: PayrollTimelineChangeInterface['processing_status'],
  fallbackColor?: Color,
) => {
  switch (status?.id) {
    case 'success':
      return Token.color.success
    case 'not_processed':
    case 'pending':
      return Token.color.warning
    case 'failed':
      return Token.color.danger
    default:
      return fallbackColor || Token.color.greyTone50
  }
}

interface SendToDeelStatusProps {
  change: PayrollTimelineChangeInterface
}
const SendToDeelStatus = ({ change }: SendToDeelStatusProps) => {
  const [pending, setPending] = useState(false)

  const showStatusPopup = useShowStatusPopup()

  const handleRetry = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    try {
      setPending(true)
      await sendChangesToDeel(change.id)
    } catch (err) {
      showStatusPopup({
        title: 'Could not retry sending changes',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    } finally {
      setPending(false)
    }
  }

  if (!change.processing_status) {
    return null
  }
  return (
    <VStack space="s-8">
      <HStack align="center" space="s-12">
        <HStack space="s-6">
          <Text variant="emphasis2" color={Token.color.foreground}>
            Deel integration:{' '}
          </Text>
          <Text variant="emphasis2" color={Token.color.foreground}>
            {change.processing_status.id === 'not_processed'
              ? 'Pending'
              : change.processing_status.name || 'unknown'}
          </Text>
        </HStack>
        {change.processing_status.id === 'failed' && (
          <>
            {pending ? (
              <Spinner size={15} color={Token.color.blue} />
            ) : (
              <TextButton textStyle="emphasis2" onClick={handleRetry}>
                <HStack space="s-2" align="center">
                  <Icon name="Retry" size={14} color={Token.color.blue} />
                  <Text>Retry</Text>
                </HStack>
              </TextButton>
            )}
          </>
        )}
      </HStack>
      {!!change.processing_errors?.length && (
        <VStack space="s-8">
          {change.processing_errors.map(err => (
            <Text color={Token.color.greyTone50} key={err}>
              {err}
            </Text>
          ))}
        </VStack>
      )}
    </VStack>
  )
}

const getChangeHeaderInfo = (change: PayrollTimelineChangeInterface) => {
  const tooltipBody = (
    <Box
      radius={Token.radius.r12}
      bg={Token.color.widgetBackground}
      width={320}
      p="s-16"
      elevation="level4"
    >
      <VStack space="s-8">
        <Box>
          <Text variant="emphasis2" color={Token.color.foreground}>
            Change done on:{' '}
          </Text>
          <Text variant="emphasis2" whiteSpace="pre" color={Token.color.foreground}>
            {formatWithoutTimezone(change.effective_date_time, true)}
          </Text>
        </Box>
        <SendToDeelStatus change={change} />
      </VStack>
    </Box>
  )

  return {
    domainName: formatSnakeCase(change.domain_name),
    infoTooltip: (
      <Tooltip
        noArrow
        placement="left"
        body={tooltipBody}
        backgroundColor={Token.color.popoverBackground}
      >
        <Icon
          name="InfoOutline"
          color={getStatusColor(change.processing_status)}
          aria-label="Change info icon"
          size={16}
          mr="s-4"
        />
      </Tooltip>
    ),
  }
}

type SingleChangeCellProps = {
  change: PayrollTimelineChangeInterface
  label?: string
  children: React.ReactNode
}
export const SingleChangeCellWrapper = ({
  label,
  change,
  children,
}: SingleChangeCellProps) => {
  const { domainName, infoTooltip } = getChangeHeaderInfo(change)

  return (
    <Cell>
      <Flex flex="1 0" width="100%" justifyContent="space-between">
        <HStack align="center" space="s-4">
          <div style={{ cursor: 'pointer' }}>{infoTooltip}</div>
          <Text color={Token.color.greyTone50} variant="h6">
            {label || domainName}
          </Text>
        </HStack>
        <Text variant="h6">{children}</Text>
      </Flex>
    </Cell>
  )
}

type MultipleChangesCellWrapperProps = {
  change: PayrollTimelineChangeInterface
  isExpandedByDefault?: boolean
  customTitle?: string
  children: React.ReactNode
}
export const MultipleChangesCellWrapper = ({
  change,
  isExpandedByDefault = true,
  customTitle,
  children,
}: MultipleChangesCellWrapperProps) => {
  const [expanded, setExpanded] = useState(isExpandedByDefault)

  const { domainName, infoTooltip } = getChangeHeaderInfo(change)

  return (
    <ExpandableCell expanded={expanded} onToggle={() => setExpanded(!expanded)}>
      <ExpandableCell.Title>
        <HStack align="center" space="s-4">
          {infoTooltip}
          <Ellipsis maxWidth={440}>{customTitle || domainName}</Ellipsis>
        </HStack>
      </ExpandableCell.Title>
      <ExpandableCell.Note>{children}</ExpandableCell.Note>
    </ExpandableCell>
  )
}

interface Props {
  data: {
    change: PayrollTimelineChangeInterface
    fieldsChanges: ParsedDomainFieldChanges[]
    customTitle?: string
    renderAll?: (change: PayrollTimelineChangeInterface) => React.ReactNode
  }
  isExpandedByDefault?: boolean
}
export const DomainChangesWidget = ({ data, isExpandedByDefault = true }: Props) => {
  const { change, fieldsChanges, customTitle, renderAll } = data

  const hasIssues = isPlainObject(change.issues) && !isEmpty(change.issues)
  const nonFieldIssues: { [key: keyof typeof change.issues]: string } = hasIssues
    ? { ...change.issues }
    : {}
  Object.keys(nonFieldIssues).forEach(key => {
    if (key in change.to_value) {
      delete nonFieldIssues[key]
    }
  })
  const hasNonFieldIssues = !isEmpty(nonFieldIssues)
  const issuesLevelColor =
    change.issues_level.id === PAYROLL_ISSUES_LEVEL_CRITICAL
      ? Token.color.danger
      : change.issues_level.id === PAYROLL_ISSUES_LEVEL_WARNING
      ? Token.color.warning
      : undefined

  const customRenderedDomainData = renderAll?.(change)

  if (!hasIssues && customRenderedDomainData !== undefined) {
    return <>{customRenderedDomainData}</>
  }
  if (!hasNonFieldIssues && fieldsChanges.length === 1) {
    const { from, to, changeType, label } = fieldsChanges[0]

    return (
      <SingleChangeCellWrapper label={customTitle || label} change={change}>
        <ValuesDiff from={from} to={to} type={changeType} />
      </SingleChangeCellWrapper>
    )
  }
  return (
    <MultipleChangesCellWrapper
      change={change}
      customTitle={customTitle}
      isExpandedByDefault={isExpandedByDefault}
    >
      {fieldsChanges.map(parsedField => {
        const { from, to, label, changeType } = parsedField
        return (
          <Details key={label}>
            <Details.Title>{label}</Details.Title>
            <Details.Content>
              <ValuesDiff from={from} to={to} type={changeType} />
            </Details.Content>
          </Details>
        )
      })}
      {hasNonFieldIssues && (
        <VStack>
          {Object.entries(nonFieldIssues).map(([field, message]) => (
            <Details key={field}>
              <Details.Title>
                <Text color={Token.color.greyTone50}>{formatSnakeCase(field)}</Text>
              </Details.Title>
              <Details.Content>
                <HStack space="s-4" align="center">
                  <Icon
                    color={issuesLevelColor}
                    name="ExclamationMarkOutline"
                    size={15}
                  />
                  <Text color={issuesLevelColor}>{message}</Text>
                </HStack>
              </Details.Content>
            </Details>
          ))}
        </VStack>
      )}
    </MultipleChangesCellWrapper>
  )
}
