import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectUser } from '@src/store/auth/selectors'
import { Bar, FilterButton } from '@revolut/ui-kit'
import { useTableReturnType } from '@components/Table/hooks'
import { useQuery } from '@src/utils/queryParamsHooks'

const EMPLOYEE_ID_COL_NAME = 'employee__id'
const EMPLOYEE_STATUS_COL_NAME = 'employee__status'
const TERMINATED_EMPLOYEE_STATUS = 'terminated'

export const useFilterButtons = <T, S>(table: useTableReturnType<T, S>) => {
  const user = useSelector(selectUser)

  const [showAssignedToMe, setShowAssignedToMe] = useState(false)
  const [showTerminated, setShowTerminated] = useState(false)

  const { query } = useQuery()

  const employeeIdQueryParam = query[EMPLOYEE_ID_COL_NAME]
  const employeeStatusQueryParam = query[EMPLOYEE_STATUS_COL_NAME]

  useEffect(() => {
    setShowAssignedToMe(employeeIdQueryParam === String(user.id))
    setShowTerminated(employeeStatusQueryParam === TERMINATED_EMPLOYEE_STATUS)
  }, [employeeIdQueryParam, employeeStatusQueryParam])

  const getFiltersByCurrentUser = (assignedToMeOnly: boolean) => {
    const filters = assignedToMeOnly
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : []

    return {
      filters,
      columnName: EMPLOYEE_ID_COL_NAME,
    }
  }

  const getFiltersByTerminated = (terminatedOnly: boolean) => {
    const filters = terminatedOnly
      ? [
          {
            name: TERMINATED_EMPLOYEE_STATUS,
            id: TERMINATED_EMPLOYEE_STATUS,
          },
        ]
      : []

    return {
      filters,
      columnName: EMPLOYEE_STATUS_COL_NAME,
    }
  }

  const onToggleAssignedToMe = () => {
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFiltersByCurrentUser(!showAssignedToMe))
  }

  const onToggleTerminated = () => {
    setShowTerminated(!showTerminated)
    table.onFilterChange(getFiltersByTerminated(!showTerminated))
  }

  return (
    <Bar>
      <FilterButton onClick={onToggleTerminated} active={showTerminated}>
        Terminated employees
      </FilterButton>
      <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
        Assigned to me
      </FilterButton>
    </Bar>
  )
}
