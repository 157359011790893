import * as React from 'react'
import styled from 'styled-components'
import {
  DeliverableJustificationValue,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { history } from '@src/utils/router'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Box, Text } from '@revolut/ui-kit'
import pluralize from 'pluralize'

const Container = styled.div``

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 16px;
`

const ErrorItem = styled.div`
  &::before {
    content: '•';
    padding-right: 4px;
  }
`

interface Props {
  type: PerformanceReviewTypes
}

const PerformanceValidationDialog = ({ type }: Props) => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { errors, fields } = useLapeContext<ReviewScorecardInterface>()
  const { hash, pathname } = useLocation()
  const reviewDataValues = form.values.review_data

  useEffect(() => {
    Object.values(fields).forEach(state => {
      state.touched = true
    })

    return () => {
      if (hash === SCROLL_ERROR_HASH) {
        // to trigger change location
        history.replace(pathname)
        setTimeout(() => {
          history.replace(`${pathname}${SCROLL_ERROR_HASH}`)
        }, 100)
      } else {
        history.replace(`${pathname}${SCROLL_ERROR_HASH}`)
      }
    }
  }, [])

  const formattedErrors = {
    invalidJustificationLink: false,
    unansweredQuestion: false,
    missingJustification: false,
    invalidOverallFeedback: false,
    unansweredBarRaiser: false,
    unansweredQuarterPerformance: false,
    invalidImprovementFeedback: false,
  }
  const unansweredQuestionsTitles: string[] = []

  const setSkillsErrors = (
    data?: SkillCardInterface[],
    cardsValues?: SkillCardInterface[] | ValueBasedCardInterface[],
  ) => {
    data?.forEach((card, ind) => {
      const missingJustification = !!card?.justification
      if (missingJustification) {
        formattedErrors.missingJustification = true
      }

      const unansweredQuestion = !!card?.sections
      if (unansweredQuestion) {
        formattedErrors.unansweredQuestion = true
        const cardName = cardsValues?.[ind].name
        if (cardName) {
          unansweredQuestionsTitles.push(cardName)
        }
      }
    })
  }

  switch (type) {
    case PerformanceReviewTypes.deliverables:
      {
        const data = errors.review_data?.deliverables?.cards as SkillCardInterface[]
        data?.forEach((card, ind) => {
          const missingJustification = !!(
            card?.justifications as DeliverableJustificationValue[]
          )?.some(justification => justification?.comment)
          if (missingJustification) {
            formattedErrors.missingJustification = true
          }

          const invalidJustificationLink = !!(
            card?.justifications as DeliverableJustificationValue[]
          )?.some(justification => justification?.reference_url)
          if (invalidJustificationLink) {
            formattedErrors.invalidJustificationLink = true
          }

          const unansweredQuestion = !!card?.sections
          if (unansweredQuestion) {
            formattedErrors.unansweredQuestion = true
            const cardName = reviewDataValues.deliverables?.cards?.[ind].name
            if (cardName) {
              unansweredQuestionsTitles.push(cardName)
            }
          }
        })

        const justificationsData = errors.review_data?.deliverables
          ?.justifications as DeliverableJustificationValue[]
        const invalidJustificationLink = !!justificationsData?.some(
          justification => justification?.reference_url,
        )
        if (invalidJustificationLink) {
          formattedErrors.invalidJustificationLink = true
        }
      }

      if (errors.review_data?.deliverables?.skipped_section_justification) {
        formattedErrors.missingJustification = true
      }

      break

    case PerformanceReviewTypes.skills: {
      const data = errors.review_data?.functional_skills?.cards as SkillCardInterface[]
      setSkillsErrors(data, reviewDataValues.functional_skills?.cards)
      if (errors.review_data?.functional_skills?.skipped_section_justification) {
        formattedErrors.missingJustification = true
      }
      break
    }

    case PerformanceReviewTypes.managerValuesSkills: {
      const data = errors.review_data?.manager_values?.cards as SkillCardInterface[]
      setSkillsErrors(data, reviewDataValues.manager_values?.cards)

      if (errors.review_data?.manager_values?.skipped_section_justification) {
        formattedErrors.missingJustification = true
      }

      if (errors.review_data?.culture_values?.rating_expectation) {
        formattedErrors.invalidImprovementFeedback = true
      }

      break
    }

    case PerformanceReviewTypes.cultureFit: {
      const data = errors.review_data?.culture_skills?.cards as SkillCardInterface[]
      setSkillsErrors(data, reviewDataValues.culture_skills?.cards)
      // @ts-ignore TODO type mismatch, check interface
      if (errors.review_data?.cultureFit?.skipped_section_justification) {
        formattedErrors.missingJustification = true
      }
      break
    }

    case PerformanceReviewTypes.cultureValuesFit: {
      const data = errors.review_data?.culture_values?.cards as SkillCardInterface[]
      setSkillsErrors(data, reviewDataValues.culture_values?.cards)

      if (errors.review_data?.culture_values?.skipped_section_justification) {
        formattedErrors.missingJustification = true
      }

      if (errors.review_data?.culture_values?.rating_expectation) {
        formattedErrors.invalidImprovementFeedback = true
      }

      break
    }

    case PerformanceReviewTypes.summary: {
      if (errors.review_data?.overall_feedback) {
        formattedErrors.invalidOverallFeedback = true
      }
      break
    }

    case PerformanceReviewTypes.recommendation: {
      if (
        errors.review_data?.line_manager_extra_section?.keeper_test_section?.questions
      ) {
        formattedErrors.unansweredBarRaiser = true
      }
      if (
        errors.review_data?.line_manager_extra_section?.employee_project_performance
          ?.value
      ) {
        formattedErrors.unansweredQuarterPerformance = true
      }
      if (
        errors.review_data?.functional_manager_extra_section?.keeper_test_section
          ?.questions
      ) {
        formattedErrors.unansweredBarRaiser = true
      }
      if (
        errors.review_data?.functional_manager_extra_section?.employee_project_performance
          ?.value
      ) {
        formattedErrors.unansweredQuarterPerformance = true
      }
      break
    }
  }

  // TODO: this is not a good idea, refactor
  if (formattedErrors) {
    form.submitFailed = true
  }

  const unansweredQuestionTitles = unansweredQuestionsTitles.length
    ? `: ${unansweredQuestionsTitles.join(', ')} missing`
    : ''

  const errorsCount = Object.values(formattedErrors).filter(errType => errType).length

  return (
    <Container>
      <Title>Incomplete scorecard</Title>
      <Box style={{ textAlign: 'left' }}>
        We found the following {pluralize('issues', errorsCount, false)} on this page:
        <br />
        {formattedErrors.invalidJustificationLink && (
          <ErrorItem>Invalid justification link</ErrorItem>
        )}
        {formattedErrors.unansweredQuestion && (
          <ErrorItem>
            Unanswered {pluralize('questions', unansweredQuestionsTitles.length, false)}{' '}
            {unansweredQuestionTitles}
          </ErrorItem>
        )}
        {formattedErrors.missingJustification && (
          <ErrorItem>Missing justification</ErrorItem>
        )}
        {formattedErrors.invalidOverallFeedback && (
          <ErrorItem>Overall feedback is obligatory</ErrorItem>
        )}
        {formattedErrors.unansweredBarRaiser && (
          <ErrorItem>Bar raiser questions are obligatory</ErrorItem>
        )}
        {formattedErrors.unansweredQuarterPerformance && (
          <ErrorItem>Missing quarter performance assessment</ErrorItem>
        )}
        {formattedErrors.invalidImprovementFeedback && (
          <ErrorItem>
            <Text>
              You should select at least 2 behaviours for the “Improvement focus”
              category.
            </Text>
            <Text use="p">
              In the true spirit of our “Never Settle” mentality, we want our employees to
              constantly strive for growth.
            </Text>
            <Text use="p">
              Please choose behaviours that should be the main opportunities for
              development to become a true culture champion.
            </Text>
          </ErrorItem>
        )}
        <br />
        You can’t proceed without resolving {errorsCount > 1 ? 'these' : 'this'}{' '}
        {pluralize('issues', errorsCount, false)}.
      </Box>
    </Container>
  )
}

export default connect(PerformanceValidationDialog)
