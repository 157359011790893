import React, { useState } from 'react'
import { connect } from 'lape'
import { toLower } from 'lodash'
import capitalize from 'lodash/capitalize'
import pluralize from 'pluralize'
import { useSelector } from 'react-redux'
import { Box, chain, Group, MoreBar, Text } from '@revolut/ui-kit'

import { PageWrapper } from '@components/Page/Page'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { paygroupRequests, updatePaygroupStatus } from '@src/api/payroll'
import { formatPayrollElementsOnLoad } from '@src/apps/People/Payroll/Paygroup/helpers'
import { PayElementsTable } from '@src/apps/People/Payroll/Paygroup/PayElementsTable'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DeleteButton, EditButton } from '@src/features/SettingsButtons'
import { PaygroupInterface, PaygroupStatus } from '@src/interfaces/payroll'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { formatToOrdinal } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { mapStatusToColor } from '../helpers'
import { BaseReportDownload } from './BaseReportDownload'

const formatDeadline = (days: string | number | null, trigger: string | null) =>
  !days || !trigger
    ? '-'
    : `${days} ${pluralize('day', Number(days))} ${toLower(trigger)}`

const formatMonth = (monthNumber: number) => {
  if (monthNumber < 1 || monthNumber > 12) {
    return '-'
  }
  return new Date(0, monthNumber - 1).toLocaleString('default', { month: 'long' })
}

export const PaygroupPreviewInner = () => {
  const { initialValues, reset } = useLapeContext<PaygroupInterface>()

  const [isStatusPending, setIsStatusPending] = useState(false)

  const permissions = initialValues.field_options?.permissions || []
  const canEdit = permissions.includes(PermissionTypes.ChangePaygroup)
  const canDelete = permissions.includes(PermissionTypes.DeletePaygroup)
  const showActionBar = canEdit || canDelete

  const featureFlags = useSelector(selectFeatureFlags)
  const isPayrollV2 = featureFlags.includes(FeatureFlags.PayrollV2)

  const title = chain(
    initialValues.name,
    initialValues.status?.name ? (
      <Text color={mapStatusToColor(initialValues)}>
        {capitalize(initialValues.status.name || '')}
      </Text>
    ) : undefined,
  )
  const isActive = initialValues.status?.id === 'active'
  const isInactive = initialValues.status?.id === 'inactive'

  const onToggleActiveStatus = async () => {
    setIsStatusPending(true)
    try {
      const { data } = await updatePaygroupStatus(
        {
          id: isActive ? 'inactive' : 'active',
        } as PaygroupStatus,
        initialValues.id!,
      )
      reset(data)
    } finally {
      setIsStatusPending(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        subtitle="Pay group"
        backUrl={ROUTES.SETTINGS.PAYROLL.CONFIGURATION}
      />
      <PageBody>
        {showActionBar && (
          <Box mb="s-24">
            <MoreBar>
              <EditButton
                route={pathToUrl(
                  isPayrollV2
                    ? ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT
                    : ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT,
                  {
                    id: initialValues.id,
                  },
                )}
                isVisible={canEdit}
              />
              <BaseReportDownload id={`${initialValues.id}`} />
              {(isActive || isInactive) && (
                <MoreBar.Action
                  useIcon={isActive ? 'CrossSmall' : '16/CheckCircle'}
                  pending={isStatusPending}
                  variant={isActive ? 'negative' : undefined}
                  onClick={onToggleActiveStatus}
                >
                  {isActive ? 'Make inactive' : 'Make active'}
                </MoreBar.Action>
              )}
              {canDelete && (
                <DeleteButton
                  title="pay group"
                  deleteApi={paygroupRequests.delete!}
                  backUrl={ROUTES.SETTINGS.PAYROLL.CONFIGURATION}
                />
              )}
            </MoreBar>
          </Box>
        )}

        <FormPreview title="General information" data={initialValues}>
          {isPayrollV2 ? (
            <Group>
              <FormPreview.Item<PaygroupInterface> title="Name" field="name" />
              <FormPreview.Item<PaygroupInterface>
                title="Provider"
                field="payroll_provider.name"
              />
              <FormPreview.Item<PaygroupInterface> title="Headcount" field="headcount" />
            </Group>
          ) : (
            <Group>
              <FormPreview.Item<PaygroupInterface> title="Headcount" field="headcount" />
              <FormPreview.Item<PaygroupInterface> title="Country" field="country.name" />
              <FormPreview.Item<PaygroupInterface>
                title="Entity"
                field="company_entity.name"
              />
              <FormPreview.Item<PaygroupInterface>
                title="Currency"
                field="country.national_currency.name"
              />
              <FormPreview.Item<PaygroupInterface>
                title="Owner"
                field="owner.full_name"
                insert={data => <UserWithAvatar {...data.owner} />}
              />
            </Group>
          )}
        </FormPreview>

        <Box mt="s-16">
          <FormPreview title="Pay schedule" data={initialValues}>
            <Group>
              <FormPreview.Item<PaygroupInterface>
                title="Pay period start"
                field="pay_period_start_day"
                insert={values =>
                  values.pay_period_start_day ? (
                    <Text>
                      {values.pay_frequency?.name}:{' '}
                      {chain(
                        formatToOrdinal(values.pay_period_start_day),
                        values.pay_period_second_start_day
                          ? formatToOrdinal(values.pay_period_second_start_day)
                          : null,
                      )}
                    </Text>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Item<PaygroupInterface>
                title="Pay date"
                field="pay_date_day"
                insert={values =>
                  values.pay_date_day ? (
                    <Text>
                      {values.pay_frequency?.name}:{' '}
                      {chain(
                        formatToOrdinal(values.pay_date_day),
                        values.pay_date_second_day
                          ? formatToOrdinal(values.pay_date_second_day)
                          : null,
                      )}{' '}
                      {values.pay_date_schedule?.name}
                    </Text>
                  ) : (
                    '-'
                  )
                }
              />
              {initialValues.pay_authorities_frequency?.id === 'quarterly' ? (
                <>
                  <FormPreview.Item<PaygroupInterface>
                    title="Pay authorities date - Q1"
                    field="pay_authorities_date_day_q1"
                    insert={values =>
                      values.pay_authorities_date_day_q1 &&
                      values.pay_authorities_date_month_q1 ? (
                        <Text>
                          {formatToOrdinal(values.pay_authorities_date_day_q1)} of{' '}
                          {formatMonth(values.pay_authorities_date_month_q1)}
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                  />
                  <FormPreview.Item<PaygroupInterface>
                    title="Pay authorities date - Q2"
                    field="pay_authorities_date_day_q2"
                    insert={values =>
                      values.pay_authorities_date_day_q2 &&
                      values.pay_authorities_date_month_q2 ? (
                        <Text>
                          {formatToOrdinal(values.pay_authorities_date_day_q2)} of{' '}
                          {formatMonth(values.pay_authorities_date_month_q2)}
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                  />

                  <FormPreview.Item<PaygroupInterface>
                    title="Pay authorities date - Q3"
                    field="pay_authorities_date_day_q3"
                    insert={values =>
                      values.pay_authorities_date_day_q3 &&
                      values.pay_authorities_date_month_q3 ? (
                        <Text>
                          {formatToOrdinal(values.pay_authorities_date_day_q3)} of{' '}
                          {formatMonth(values.pay_authorities_date_month_q3)}
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                  />
                  <FormPreview.Item<PaygroupInterface>
                    title="Pay authorities date - Q4"
                    field="pay_authorities_date_day_q4"
                    insert={values =>
                      values.pay_authorities_date_day_q4 &&
                      values.pay_authorities_date_month_q4 ? (
                        <Text>
                          {formatToOrdinal(values.pay_authorities_date_day_q4)} of{' '}
                          {formatMonth(values.pay_authorities_date_month_q4)}
                        </Text>
                      ) : (
                        '-'
                      )
                    }
                  />
                </>
              ) : (
                <FormPreview.Item<PaygroupInterface>
                  title="Pay authorities date (day of month)"
                  field="pay_authorities_date_day_of_month"
                  insert={values =>
                    values.pay_authorities_date_day_of_month
                      ? formatToOrdinal(values.pay_authorities_date_day_of_month)
                      : '-'
                  }
                />
              )}
              <FormPreview.Item<PaygroupInterface>
                title="Employee changes deadline (cut off)"
                field="pay_authorities_date_day_of_month"
                insert={values =>
                  formatDeadline(
                    values.cut_off_date_trigger_number_of_days,
                    values.cut_off_date_trigger?.name,
                  )
                }
              />
              <FormPreview.Item<PaygroupInterface>
                title="Report submission deadline"
                field="report_submission_date_days_after_cycle_ends"
                insert={values =>
                  formatDeadline(
                    values.report_submission_date_days_after_cycle_ends,
                    values.report_submission_date_trigger?.name,
                  )
                }
              />
            </Group>
          </FormPreview>
        </Box>

        <HideIfCommercial>
          {!isPayrollV2 && (
            <Box mt="s-16">
              <FormPreview title="Pay elements" data={initialValues}>
                <PayElementsTable
                  payments={formatPayrollElementsOnLoad(
                    initialValues.payroll_elements || [],
                  )}
                />
              </FormPreview>
            </Box>
          )}
        </HideIfCommercial>
      </PageBody>
    </PageWrapper>
  )
}

export const PayGroupPreview = connect(() => (
  <Form api={paygroupRequests}>
    <PaygroupPreviewInner />
  </Form>
))
