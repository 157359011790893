import React from 'react'
import { Link as UIKitLink, LinkProps as UIKitLinkProps } from '@revolut/ui-kit'
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'

interface InternalLinkProps extends RouterLinkProps {
  disabled?: boolean
}

export const InternalLink = React.forwardRef<HTMLAnchorElement, InternalLinkProps>(
  ({ to, disabled, style, ...props }, ref) => {
    return (
      <RouterLink
        {...props}
        ref={ref}
        to={typeof to === 'string' ? getLocationDescriptor(to) : to}
        style={disabled ? { ...style, pointerEvents: 'none' } : style}
      />
    )
  },
)

export const InternalUIKitLink = (props: UIKitLinkProps) => {
  return <UIKitLink use={InternalLink} {...props} />
}
