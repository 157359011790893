import React from 'react'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { Sandbox } from '@src/features/Sandbox/Sandbox'

export const SandboxModeSettings = () => {
  return (
    <PageWrapper>
      <PageHeader title="Demo mode" backUrl={ROUTES.SETTINGS.ALL} />
      <Sandbox />
    </PageWrapper>
  )
}
