import React from 'react'
import { SubtractExchange, ExclamationMark, PlusCircle } from '@revolut/icons'
import Tooltip from '@src/components/Tooltip/Tooltip'

type PaycycleItemIconProps = {
  relevancy: 'change' | 'issue' | null
  message?: string
  isAddition?: boolean
}
export const PaycycleItemIcon = ({
  relevancy,
  message,
  isAddition,
}: PaycycleItemIconProps) => {
  if (isAddition) {
    return <PlusCircle color="blue" size={16} />
  }
  if (relevancy === 'change') {
    return (
      <Tooltip placement="top" title={message}>
        <SubtractExchange color="blue" size={16} />
      </Tooltip>
    )
  }
  if (relevancy === 'issue') {
    return (
      <Tooltip placement="top" title={message}>
        <ExclamationMark color="orange" size={16} />
      </Tooltip>
    )
  }
  return null
}
