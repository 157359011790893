import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  IssueInterface,
  IssuesCounts,
  IssuesCategoryCounts,
} from '@src/interfaces/issues'
import { useFetch } from '@src/utils/reactQuery'

export const getTeamIssues =
  (teamId: number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<IssueInterface>>(`${API.TEAMS}/${teamId}/issues`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useTeamIssuesCounts = (
  id: number | undefined,
  filters?: FilterByInterface[],
) => {
  return useFetch<IssuesCounts>(
    id ? `${API.TEAMS}/${id}/issues/counts` : null,
    'v1',
    filters
      ? {
          params: filterSortPageIntoQuery(undefined, filters, undefined),
        }
      : undefined,
    true,
  )
}

export const useTeamIssuesCategoryCounts = (
  id: number | undefined,
  filters?: FilterByInterface[],
) => {
  return useFetch<IssuesCategoryCounts>(
    id ? `${API.TEAMS}/${id}/issues/categoryCounts` : null,
    'v1',
    filters
      ? {
          params: filterSortPageIntoQuery(undefined, filters, undefined),
        }
      : undefined,
    true,
  )
}

export const getDepartmentIssues =
  (departmentId: number) =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<IssueInterface>>(
      `${API.DEPARTMENT}/${departmentId}/issues`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )

export const useDepartmentIssuesCounts = (
  id: number | undefined,
  filters?: FilterByInterface[],
) => {
  return useFetch<IssuesCounts>(
    id ? `${API.DEPARTMENT}/${id}/issues/counts` : null,
    'v1',
    filters
      ? {
          params: filterSortPageIntoQuery(undefined, filters, undefined),
        }
      : undefined,
    true,
  )
}

export const useDepartmentIssuesCategoryCounts = (
  id: number | undefined,
  filters?: FilterByInterface[],
) => {
  return useFetch<IssuesCategoryCounts>(
    id ? `${API.DEPARTMENT}/${id}/issues/categoryCounts` : null,
    'v1',
    filters
      ? {
          params: filterSortPageIntoQuery(undefined, filters, undefined),
        }
      : undefined,
    true,
  )
}

export const getCompanyIssues =
  () =>
  ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<IssueInterface>>(`${API.COMPANY}/issues`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useCompanyIssuesCategoryCounts = (filters?: FilterByInterface[]) => {
  return useFetch<IssuesCategoryCounts>(
    `${API.COMPANY}/issues/categoryCounts`,
    'v1',
    filters
      ? {
          params: filterSortPageIntoQuery(undefined, filters, undefined),
        }
      : undefined,
    true,
  )
}
