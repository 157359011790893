import React from 'react'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'
import { Flex } from '@revolut/ui-kit'

const SchedulingLinkExpired = () => {
  return (
    <Flex alignItems="center" height="100vh">
      <SuccessWidget
        type="error"
        title="Time slots expired"
        text="Kindly reach out to the recruiter to request new slots"
      />
    </Flex>
  )
}

export default SchedulingLinkExpired
