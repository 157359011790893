import React, { useEffect } from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  Item,
  Radio,
  RadioGroup,
  Subheader,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, PublishingStatuses } from '@src/interfaces/jobPosting'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useCareersSettings } from '@src/api/recruitment/careers'
import {
  useGetOpenRequisitionsByJobPosting,
  useGetPendingRequisitionsByJobPosting,
} from '@src/api/requisitions'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { useLocation, useParams } from 'react-router-dom'
import {
  JobPostingFlowParams,
  JobPostingLocationState,
} from '@src/features/JobPostingFlow/types'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobBoards } from '@src/features/JobPostingFlow/Publish/JobBoards'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { CareersWebsite } from '@src/features/JobPostingFlow/Publish/CareersWebsite'

export const Publish = () => {
  const params = useParams<JobPostingFlowParams>()
  const { state: locationState } = useLocation<JobPostingLocationState>()
  const { values, initialValues } = useLapeContext<JobPostingInterface>()
  const isCommercial = useIsCommercial()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const { data: careerPageSettings } = useCareersSettings()
  const { data: openRequisitions, isLoading: loadingOpenRequisitions } =
    useGetOpenRequisitionsByJobPosting(values.id)
  const { data: pendingRequisitions, isLoading: loadingPendingRequisitions } =
    useGetPendingRequisitionsByJobPosting(values.id)

  useEffect(() => {
    if (!values.status) {
      values.status = PublishingStatuses.unpublished
    }
  }, [values.status])

  if (loadingOpenRequisitions || loadingPendingRequisitions) {
    return <PageLoading />
  }

  const notApproved =
    jobPostingSettings?.enable_approvals && values.approval_status !== 'approved'
  const careersPageDisabled = isCommercial && !careerPageSettings?.publish_career_website
  const openRequisitionCount = openRequisitions?.count ?? 0
  const pendingRequisitionCount = pendingRequisitions?.count ?? 0
  const requisitionChecks = jobPostingSettings?.enable_requisition_publishing_checks
  const failsRequisitionChecks = requisitionChecks && !openRequisitionCount
  const disabled = notApproved || careersPageDisabled || failsRequisitionChecks
  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          <Subheader>
            <Subheader.Title>Publishing Options</Subheader.Title>
          </Subheader>
          {notApproved && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.warning} useIcon="ExclamationTriangle" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Posting is not approved</Item.Title>
                <Item.Description>
                  Posting must be approved in order to be published
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          {careersPageDisabled && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.warning} useIcon="ExclamationTriangle" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Careers website is not published</Item.Title>
                <Item.Description>
                  The application form is currently unavailable to candidates.
                  <br />
                  Please publish Careers website in the settings to start receiving
                  applications.
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <ActionButton
                  target="_blank"
                  to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE)}
                  use={InternalLink}
                  useIcon="Gear"
                >
                  Settings
                </ActionButton>
              </Item.Side>
            </Item>
          )}
          {failsRequisitionChecks && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.warning} useIcon="ExclamationTriangle" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>
                  {pendingRequisitionCount > 1
                    ? 'None of the connected requisitions are opened'
                    : pendingRequisitionCount === 1
                    ? 'Connected requisition is not opened'
                    : 'No requisitions are connected'}
                </Item.Title>
              </Item.Content>
              <Item.Side>
                <ActionButton
                  use={InternalLink}
                  useIcon="Plus"
                  onClick={() => {
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.DETAILS, params),
                      locationState,
                    )
                  }}
                >
                  Manage requisitions
                </ActionButton>
              </Item.Side>
            </Item>
          )}
          <RadioGroup<PublishingStatuses>
            defaultValue={values.status || PublishingStatuses.unpublished}
            onChange={newStatus => {
              if (newStatus) {
                values.status = newStatus
              }
            }}
          >
            {group => (
              <>
                {jobPostingSettings?.enable_all_publishing_statuses && (
                  <Item
                    use="label"
                    aria-disabled={disabled}
                    onClick={e => {
                      if (disabled) {
                        e.preventDefault()
                      }
                    }}
                  >
                    <Item.Prefix>
                      <Radio
                        disabled={!disabled}
                        {...group.getInputProps({
                          value: PublishingStatuses.published_internally,
                        })}
                      />
                    </Item.Prefix>
                    <Item.Avatar>
                      <Avatar useIcon="Bank" />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>Publish internally</Item.Title>
                      <Item.Description>
                        When published internally, job posting will be visible only to
                        your company employees
                      </Item.Description>
                    </Item.Content>
                  </Item>
                )}
                <Item
                  use="label"
                  aria-disabled={disabled}
                  onClick={e => {
                    if (disabled) {
                      e.preventDefault()
                    }
                  }}
                >
                  <Item.Prefix>
                    <Radio
                      disabled={!disabled}
                      {...group.getInputProps({
                        value: PublishingStatuses.fully_published,
                      })}
                    />
                  </Item.Prefix>
                  <Item.Avatar>
                    <Avatar useIcon="Globe" />
                  </Item.Avatar>
                  <Item.Content>
                    <Item.Title>Publish internally and externally</Item.Title>
                    <Item.Description>
                      Publish on careers website and selected job boards
                    </Item.Description>
                  </Item.Content>
                </Item>
                {(initialValues.status === PublishingStatuses.published_internally ||
                  initialValues.status === PublishingStatuses.fully_published) && (
                  <Item use="label">
                    <Item.Prefix>
                      <Radio
                        {...group.getInputProps({
                          value: PublishingStatuses.unpublished,
                        })}
                      />
                    </Item.Prefix>
                    <Item.Content>
                      <Item.Title>Unpublish</Item.Title>
                    </Item.Content>
                  </Item>
                )}
              </>
            )}
          </RadioGroup>
          <CareersWebsite requisitions={openRequisitions?.results || []} />
          <JobBoards />
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          onClick={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.HIRING_PROCESS, params),
              locationState,
            )
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          successText="Job posting saved successfully"
          onAfterSubmit={() => {
            navigateTo(
              pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                id: values.id,
                specId: values.specialisation?.id,
              }),
            )
          }}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
