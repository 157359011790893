import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import Form from '@src/features/Form/Form'
import { faqTopicFormRequests } from '@src/api/faq'
import { HelpCenterTopicPreview } from './Preview'
import { HelpCenterTopicForm } from './General'

export const HelpCenterTopic = connect(() => {
  return (
    <PageWrapper>
      <Form api={faqTopicFormRequests}>
        <Switch>
          <Route path={ROUTES.APPS.HELP_CENTER.TOPIC.PREVIEW}>
            <HelpCenterTopicPreview />
          </Route>
          <Route path={ROUTES.APPS.HELP_CENTER.TOPIC.EDIT}>
            <HelpCenterTopicForm />
          </Route>
        </Switch>
      </Form>
    </PageWrapper>
  )
})
