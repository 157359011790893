import React, { useEffect, useState } from 'react'
import OutcomeWrapper from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Outcome/OutcomeWrapper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  InterviewScorecardEnumSectionIds,
  InterviewScorecardType,
  InterviewScorecardTemplateInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { connect } from 'lape'
import { isPointBasedScorecard } from '@src/utils/interview'
import LevelsOutcome from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Outcome/LevelsOutcome'

const Outcome = () => {
  const { values } = useLapeContext<InterviewScorecardTemplateInterface>()
  const [totalPoints, setTotalPoints] = useState<number | null>()

  const scorecardType = values.scorecard_type?.id

  const calcMaxPoints = () =>
    values.sections?.reduce((sum, s) => {
      if (s.section_type?.id === InterviewScorecardEnumSectionIds.Option) {
        sum += Math.max(
          ...(s?.questions?.map(q => (q.points ? Number(q.points) : 0)) || [0]),
        )
        return sum
      }

      sum +=
        s.questions?.reduce((qSum, q) => {
          qSum +=
            q.points && Number.isFinite(q.points) && q.points > 0 ? Number(q.points) : 0
          return qSum
        }, 0) || 0
      return sum
    }, 0) || 0

  useEffect(() => {
    if (!values.sections || !isPointBasedScorecard(scorecardType)) {
      return
    }

    setTotalPoints(calcMaxPoints())
  }, [values.sections])

  switch (scorecardType) {
    case InterviewScorecardType.CV:
      return (
        <OutcomeWrapper
          sectionTitle={`Pass score${
            Number.isFinite(totalPoints) ? ` (Max score: ${totalPoints})` : ''
          }`}
          text="Define the number of points the candidate needs to acquire to pass the CV screening"
        >
          <LapeNewInput
            type="number"
            label="Number of points"
            name="pass_score"
            min="0"
            max={totalPoints || undefined}
            required
          />
        </OutcomeWrapper>
      )

    case InterviewScorecardType.General:
      return (
        <OutcomeWrapper
          sectionTitle={`Level assessment${
            Number.isFinite(totalPoints) ? ` (Max score: ${totalPoints})` : ''
          }`}
          title="Points assessment"
          text="Define scoring system based on whether to be X seniority, they need to be assessed
        at that seniority in every facet of the interview"
        >
          <LevelsOutcome />
        </OutcomeWrapper>
      )

    default:
      return null
  }
}

export default connect(Outcome)
