import React from 'react'
import { securitySettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { finishConfig } from '@src/pages/OnboardingChecklist/common/constants'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { Integrations } from '@src/pages/OnboardingChecklist/Finish/Integrations'
import { InviteTeam } from '@src/pages/OnboardingChecklist/Finish/InviteTeam'
import { Settings } from '@src/pages/OnboardingChecklist/Finish/Settings'

const routes = [
  {
    title: 'Security Settings',
    path: ROUTES.ONBOARDING_CHECKLIST.FINISH.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST.FINISH.SETTINGS,
    component: Settings,
    form: {
      api: securitySettings,
    },
    hideActions: true,
  },
  {
    title: 'Integrations',
    path: ROUTES.ONBOARDING_CHECKLIST.FINISH.INTEGRATIONS,
    url: ROUTES.ONBOARDING_CHECKLIST.FINISH.INTEGRATIONS,
    component: Integrations,
  },
  {
    title: 'Invite team',
    path: ROUTES.ONBOARDING_CHECKLIST.FINISH.INVITE,
    url: ROUTES.ONBOARDING_CHECKLIST.FINISH.INVITE,
    component: InviteTeam,
    hideActions: true,
  },
]

export const Finish = () => {
  return <OnboardingChecklistContent config={finishConfig} routes={routes} />
}
