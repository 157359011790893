import React from 'react'
import { Widget, Text, VStack, Token } from '@revolut/ui-kit'

export const ContractsIntro = () => {
  return (
    <Widget p="s-16">
      <VStack space="s-8">
        <Text variant="primary">How to setup Contracts?</Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          Contracts are objects on the employee profile that define the individual
          employee salaries, start date, end date, current status & more - think of these
          as digital record of an employee’s employment terms.
        </Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          In this section you will import all the current contract details for your
          employees, storing them all in one place for easy management.
        </Text>
      </VStack>
    </Widget>
  )
}
