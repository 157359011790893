import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { Video } from '@components/Video/Video'
import styled from 'styled-components'
import { CDN_BUCKET } from '@src/constants/externalLinks'

type Props = {
  videoUrl?: string
  videoPosterUrl?: string
  children: React.ReactNode
}

const Poster = styled.img`
  border-radius: 20px;
  outline: none;
  width: 100%;
  height: auto;
`

export const OnboardingIntro = ({ videoUrl, videoPosterUrl, children }: Props) => {
  let fullUrlPoster: string | undefined

  if (videoPosterUrl) {
    fullUrlPoster = videoPosterUrl.startsWith('http')
      ? videoPosterUrl
      : `${CDN_BUCKET}/videos${videoPosterUrl}`
  }

  return (
    <VStack gap="s-16">
      {videoUrl && <Video url={videoUrl} poster={videoPosterUrl} />}
      {!videoUrl && fullUrlPoster && <Poster src={fullUrlPoster} />}
      {children}
    </VStack>
  )
}
