import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import HiringPipelineShareAccess from '@src/pages/Forms/HiringPipelineOverview/HiringPipelineShareAccess'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'

export const ShareCandidatesAccessAction = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [openSidebar, setOpenSidebar] = useState(false)
  const { hiringPipelineId } = useCandidatePipelineData(values.id)
  if (!hiringPipelineId) {
    return null
  }
  return (
    <>
      <MoreBar.Action
        useIcon="LockClosed"
        onClick={() => {
          setOpenSidebar(!openSidebar)
        }}
      >
        Share candidates access
      </MoreBar.Action>
      {openSidebar && (
        <HiringPipelineShareAccess
          id={hiringPipelineId}
          isOpen
          onClose={() => {
            setOpenSidebar(false)
          }}
        />
      )}
    </>
  )
}
