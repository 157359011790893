import React from 'react'
import { Box, Color, ProgressStep, Skeleton, Text } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { format } from 'date-fns'
import { decl } from '@src/utils/string'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import { ReviewCategory } from '@src/interfaces/performance'

type Props = {
  ticketsCount?: number
  employeeId: number
  cycleId: string
  startDate: string
  reviewCategory: ReviewCategory
}

const route = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP.GOAL_SETTINGS,
}

const progressStepTitle = {
  [ReviewCategory.Probation]: 'Probation goals',
  [ReviewCategory.PIP_V2]: 'PIP goals',
}

const GoalsCheckpoint = ({
  ticketsCount,
  employeeId,
  cycleId,
  reviewCategory,
  startDate,
}: Props) => {
  const renderCount = () => {
    if (ticketsCount === undefined) {
      return <Skeleton width={120} mb="s-4" />
    }

    if (!ticketsCount) {
      return (
        <Text fontWeight={500} color={Color.ORANGE}>
          No goals set
        </Text>
      )
    }

    return (
      <>
        Line manager has set {ticketsCount} {decl(ticketsCount, 'goal', 'goals')}.
      </>
    )
  }

  return (
    <ProgressStep state="done">
      {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
      <ProgressStep.Title>{progressStepTitle[reviewCategory]}</ProgressStep.Title>
      <ProgressStep.Description>
        <Box mt="s-8">
          {renderCount()}

          <Box>
            <InternalUIKitLink
              variant="h6"
              // @ts-expect-error object works fine here, but UI kit expects string
              to={pathToUrl(route[reviewCategory], {
                employeeId,
                cycleId,
                id: cycleId,
              })}
            >
              View or add goals
            </InternalUIKitLink>
          </Box>
        </Box>
      </ProgressStep.Description>
      <ProgressStep.Caption>
        {format(new Date(startDate), 'd MMM')} <br />
      </ProgressStep.Caption>
    </ProgressStep>
  )
}

export default GoalsCheckpoint
