import {
  Profile,
  Document,
  Credit,
  ChartBarWithArrow,
  Lightbulb,
  Bank,
} from '@revolut/icons'

export const iconWhitelist = {
  Profile,
  Document,
  Credit,
  ChartBarWithArrow,
  Lightbulb,
  Bank,
}

export const iconList = Object.entries(iconWhitelist).map(([name, Comp]) => ({
  name,
  Comp,
}))
