import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { KeyPersonSuccessorInterface } from '@src/interfaces/keyPerson'
import { getStatusColor } from '@components/CommonSC/General'
import isNumber from 'lodash/isNumber'

export const successorsOrderRankColumn: ColumnInterface<KeyPersonSuccessorInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Rank',
  insert: ({ parentIndexes }) =>
    isNumber(parentIndexes[0]) ? parentIndexes[0] + 1 : '-',
}

export const successorsOrderEmployeeNameColumn: ColumnInterface<KeyPersonSuccessorInterface> =
  {
    type: CellTypes.text,
    idPoint: 'full_name',
    dataPoint: 'full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
  }

export const successorsOrderRoleColumn: ColumnInterface<KeyPersonSuccessorInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation.role.name',
  dataPoint: 'specialisation.role.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role',
}

export const successorsOrderStatusColumn: ColumnInterface<KeyPersonSuccessorInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  colors: data => getStatusColor(data.status),
}

export const successorsOrderActionsColumn: ColumnInterface<KeyPersonSuccessorInterface> =
  {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
