import React, { useCallback, useEffect, useState } from 'react'
import reduce from 'lodash/reduce'
import isArray from 'lodash/isArray'
import upperFirst from 'lodash/upperFirst'
import { skillRequests } from '@src/api/skills'
import { SkillInterface } from '@src/interfaces/skills'
import {
  Text,
  Widget,
  Box,
  VStack,
  Spacer,
  HStack,
  IconButton,
  Group,
  Flex,
} from '@revolut/ui-kit'
import { getListIndicator, ListType } from '@src/utils'
import SideBar from '@components/SideBar/SideBar'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { Check, Copy, InfoOutline } from '@revolut/icons'
import { FormPreview } from '@components/FormPreview/FormPreview'

const getSkillFilters = (func?: IdAndName | null) => [
  ...(func
    ? [
        {
          filters: [{ name: func.name, id: func.id }],
          columnName: 'function__id',
        },
      ]
    : []),
]

type Props = {
  isOpen?: boolean
  onClose: () => void
  onCopy: (field: string, values: string[]) => void
}

const SkillExamples = ({ isOpen, onClose, onCopy }: Props) => {
  const [func, setFunction] = useState<IdAndName>()
  const [skill, setSkill] = useState<SkillInterface>()

  const skillOptionsSelector = useCallback(async () => {
    const filters = getSkillFilters(func)
    const result = await skillRequests.getItems({ filters })
    return result.data?.results
  }, [func])

  const { options: skillOptions, asyncState } =
    useFetchOptions<SkillInterface>(skillOptionsSelector)

  useEffect(() => {
    setSkill(skillOptions[0]?.value)
  }, [skillOptions])

  const skillsError =
    asyncState === 'ready' &&
    skillOptions.length < 1 &&
    'No skill examples for the selected function'

  return (
    <SideBar
      title="Skill Examples"
      isOpen={isOpen}
      onClose={onClose}
      data-testid="examples_sidebar"
      sideProps={{ variant: 'wide' }}
    >
      <VStack space="s-16">
        <RadioSelectInput<IdAndName>
          selector={selectorKeys.functions}
          value={func}
          label="Function"
          onChange={selected => setFunction(selected || undefined)}
          clearable
        />
        <RadioSelectInput<SkillInterface>
          label="Skill"
          value={skill}
          onChange={selected => setSkill(selected || undefined)}
          options={skillOptions}
          hasError={!!skillsError}
          message={skillsError}
          loadingState={asyncState}
        />
        <Widget p="s-16">
          <HStack pb="s-8" align="end">
            <Text variant="primary">
              Points to consider when creating a skill of high quality
            </Text>
            <Spacer />
            <InfoOutline size={24} color="grey-tone-50" />
          </HStack>
          <VStack space="s-8">
            <Flex alignItems="center">
              <Check color="success" />
              <Text pl="s-8">
                Good and concise skill description that helps anyone understand what the
                skill means
              </Text>
            </Flex>
            <Flex alignItems="center">
              <Check color="success" />
              <Text pl="s-8">Well defined playbook</Text>
            </Flex>
            <Flex alignItems="center">
              <Check color="success" />
              <Text pl="s-8">Optimum number of points in each mastery level</Text>
            </Flex>
            <Flex alignItems="center">
              <Check color="success" />
              <Text pl="s-8">Unique skill in product</Text>
            </Flex>
            <Flex alignItems="center">
              <Check color="success" />
              <Text pl="s-8">Reviewed every 3 months for quality</Text>
            </Flex>
          </VStack>
        </Widget>
        {(!!skill || asyncState === 'pending') && (
          <FormPreview<SkillInterface> data={skill}>
            <Group>
              <FormPreview.Details field="description" title="Skill description" />
              <FormPreview.Item<SkillInterface>
                field="playbook_url"
                title="Playbook link"
                to={data => data.playbook_url}
                insert={data => (data.playbook_url ? 'Open' : '-')}
              />
              <FormPreview.Item<SkillInterface>
                title="Owner"
                type="employee"
                field="owner"
              />
              <FormPreview.Item<SkillInterface> title="Function" field="function.name" />
            </Group>
          </FormPreview>
        )}
        {skill && (
          <Widget p="s-16">
            <VStack space="s-32">
              {skill &&
                reduce(
                  skill,
                  (result, propValue, propName) => {
                    if (/^mastery_level_definition_/.test(propName)) {
                      const title = propName.replace(/^mastery_level_definition_/, '')
                      isArray(propValue) &&
                        propName !== 'role_skills' &&
                        result.push(
                          <Box key={propName}>
                            <HStack align="center" mb="s-12">
                              <Text variant="primary" use="p">
                                {upperFirst(title)}
                              </Text>
                              <Spacer />
                              <IconButton
                                color="blue"
                                size={16}
                                useIcon={Copy}
                                // weird legacy code, TS complains that propValue can be IdAndName[], but it cannot because of all previous checks
                                onClick={() => onCopy(propName, propValue as string[])}
                              />
                            </HStack>
                            <VStack>
                              {propValue.map((expectation, index) => (
                                <Text use="p" key={index} fontSize="h6">
                                  {getListIndicator(index, ListType.letters)}.{' '}
                                  {expectation}
                                </Text>
                              ))}
                            </VStack>
                          </Box>,
                        )
                    }
                    return result
                  },
                  [] as React.ReactNode[],
                )}
            </VStack>
          </Widget>
        )}
      </VStack>
    </SideBar>
  )
}

export default SkillExamples
