import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import { AxiosPromise } from 'axios'
import get from 'lodash/get'

import {
  Color,
  createChain,
  Flex,
  HStack,
  Text,
  TextButton,
  useIntersectViewport,
  VStack,
} from '@revolut/ui-kit'
import { GetRequestData } from '@src/interfaces'
import {
  FaqCategoryInterface,
  FaqInterface,
  FaqTopicInterface,
} from '@src/interfaces/faq'
import { searchFiltersToParams } from './helpers'
import { Home } from '@revolut/icons'
import { iconWhitelist } from '@components/HelpCenter/components/iconWhitelist'
import { SidebarView } from '@components/HelpCenter/types'
import { QuestionsSidebarContent } from '@components/HelpCenter/components/QuestionsSidebarContent'
import { ChatBot } from '@components/HelpCenter/ChatBot'
import { CategoriesSidebarContent } from '@components/HelpCenter/components/CategoriesSidebarContent'
import { TopicsSidebarContent } from '@components/HelpCenter/components/TopicsSidebarContent'
import { AnswerSidebarContent } from '@components/HelpCenter/components/AnswerSidebarContent'
import { RateExperienceSidebarContent } from '@components/HelpCenter/components/RateExperienceSidebarContent'
import { useUserCountryByLocation } from '@src/hooks/useUserCountryByLocation'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { BugReport } from '@components/HelpCenter/components/BugReport'
import { FeatureFlags } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'

export type FiltersState = {
  search: {
    value: string
    setValue: (newSearch: string) => void
  }
  category: {
    value: FaqCategoryInterface | undefined
    setValue: (newValue: FaqCategoryInterface | undefined) => void
  }
  topic: {
    value: FaqTopicInterface | undefined
    setValue: (newValue: FaqTopicInterface | undefined) => void
  }
}
export const useFiltersState = (searchValue: string): FiltersState => {
  const [textFilter, setTextFilter] = useState(searchValue)
  const [category, setCategory] = useState<FaqCategoryInterface>()
  const [topic, setTopic] = useState<FaqTopicInterface>()

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 300), [])

  useEffect(() => {
    setTextFilterDebounced(searchValue || '')
  }, [searchValue])

  return useMemo(() => {
    return {
      search: { value: textFilter, setValue: setTextFilter },
      category: { value: category, setValue: setCategory },
      topic: { value: topic, setValue: setTopic },
    }
  }, [textFilter, category, topic])
}

export const useFaqSearch = <T,>({
  getPaginatedOptions,
  filtersState,
  sidebarView,
  country,
  instantSearch,
}: {
  getPaginatedOptions: (filters: Object) => AxiosPromise<GetRequestData<T>>
  filtersState: FiltersState
  sidebarView: SidebarView
  country?: OptionInterface
  instantSearch?: boolean
}) => {
  const [options, setOptions] = useState<T[]>([])
  const [total, setTotal] = useState<number>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloadingFilters, setIsReloadingFilters] = useState<boolean>(
    instantSearch || true,
  )
  const [page, setPage] = useState<number>(1)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const ref = useRef(null)

  useIntersectViewport(ref, isIntersecting => {
    if (isReloadingFilters) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const filtersParams = useMemo(() => {
    return searchFiltersToParams({
      country,
      sidebarView,
      filtersState,
    })
  }, [
    filtersState.search.value,
    filtersState.category.value?.id,
    filtersState.topic.value?.id,
    country?.id,
    sidebarView,
  ])

  const loadOptions = (newPage = 1) => {
    setPage(newPage)

    return getPaginatedOptions({
      filters: { ...filtersParams, page: newPage },
    }).then(({ data }) => {
      const { results, pages, count } = data

      setHasMoreData(!!pages.next)
      setTotal(count)

      return results
    })
  }

  useEffect(() => {
    if (isReloadingFilters || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadOptions(page)
      .then(newOptions => {
        setOptions([...options, ...newOptions])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [page])

  useEffect(() => {
    const skipEmptyInstantSearch = instantSearch && !filtersState.search.value

    if (isLoadingMore || skipEmptyInstantSearch) {
      return
    }
    setIsReloadingFilters(true)

    loadOptions()
      .then(filteredOptions => {
        setOptions(filteredOptions)
      })
      .finally(() => {
        setIsReloadingFilters(false)
      })
  }, [filtersParams])

  return { options, total, isLoadingMore, isReloadingFilters, ref, hasMoreData }
}

export const useTitle = (
  sidebarView: SidebarView | undefined,
  question: FaqInterface | undefined,
) => {
  switch (sidebarView) {
    case 'chatbot':
      return 'Chat with Rita'
    case 'answer':
      return question?.title
    case 'rate-experience':
      return ''
    default:
      return 'What can we help you with?'
  }
}

export const useRenderSidebarContent = (
  filtersState: FiltersState,
  sidebarView: SidebarView | undefined,
  setSidebarView: (view: SidebarView) => void,
  question: FaqInterface | undefined,
  setQuestion: (q: FaqInterface) => void,
  handleClose: () => void,
  searchValue: string,
  setSearchValue: (newSearch: string) => void,
) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const canRaiseTickets = featureFlags?.includes(FeatureFlags.TicketsApplication)

  const { topic, category } = filtersState
  const isSearchActive = !!searchValue

  const country = useUserCountryByLocation()

  const groupedSearchScreens: SidebarView[] = ['categories', 'topics']
  const isInstantSearch =
    isSearchActive && sidebarView && groupedSearchScreens.includes(sidebarView)

  const onSelectCategory = (faqCategory: FaqCategoryInterface) => {
    category.setValue(faqCategory)
    setSidebarView('topics')
  }

  const onEmptySearchResults = {
    onGoToAllCategoriesClick: () => {
      setSidebarView('categories')
      topic.setValue(undefined)
      category.setValue(undefined)
      setSearchValue('')
    },
    onChatWithUsClick: () => {
      setSidebarView('chatbot')
      topic.setValue(undefined)
      category.setValue(undefined)
      setSearchValue('')
    },
  }

  if (isInstantSearch) {
    return (
      <QuestionsSidebarContent
        instantSearch
        country={country}
        filtersState={filtersState}
        onEmptySearchResults={onEmptySearchResults}
        onSelect={selectedQuestion => {
          setSearchValue('')
          setQuestion(selectedQuestion)
          setSidebarView('answer')
          topic.setValue(selectedQuestion.topic)
          category.setValue(selectedQuestion.topic?.category)
        }}
      />
    )
  }
  switch (sidebarView) {
    case 'chatbot':
      return (
        <ChatBot
          faqTopic={topic.value}
          onIssueResolved={() => setSidebarView('rate-experience')}
        />
      )
    case 'categories':
      return (
        <VStack space="s-24">
          <CategoriesSidebarContent country={country} onSelect={onSelectCategory} />{' '}
          {canRaiseTickets ? <BugReport /> : null}
        </VStack>
      )
    case 'topics':
      return (
        <TopicsSidebarContent
          filtersState={filtersState}
          country={country}
          onEmptySearchResults={onEmptySearchResults}
          onSelect={newTopic => {
            topic.setValue(newTopic)
            setSidebarView('questions')
          }}
        />
      )
    case 'questions':
      return (
        <QuestionsSidebarContent
          filtersState={filtersState}
          country={country}
          onEmptySearchResults={onEmptySearchResults}
          onSelect={selectedQuestion => {
            setQuestion(selectedQuestion)
            setSidebarView('answer')
          }}
        />
      )
    case 'answer':
      return (
        <AnswerSidebarContent
          question={question}
          goToIndex={() => setSidebarView('categories')}
          goToChatBot={() => setSidebarView('chatbot')}
        />
      )
    case 'rate-experience':
      return <RateExperienceSidebarContent handleClose={handleClose} />
    default:
      return <CategoriesSidebarContent country={country} onSelect={onSelectCategory} />
  }
}

export const useNavigationBreadcrumbs = (
  filtersState: FiltersState,
  sidebarView: SidebarView | undefined,
  setSidebarView: (view: SidebarView) => void,
  setSearchValue: (newSearch: string) => void,
) => {
  const { topic, category } = filtersState

  const chain = createChain(<>&nbsp;/&nbsp;</>)

  const homeButton = (
    <TextButton
      color={Color.GREY_TONE_50}
      onClick={() => {
        setSidebarView('categories')
        topic.setValue(undefined)
        category.setValue(undefined)
        setSearchValue('')
      }}
    >
      <HStack space="s-4" align="center">
        <Home size={14} />
        <Text>Categories</Text>
      </HStack>
    </TextButton>
  )
  if (sidebarView === 'categories') {
    return homeButton
  }

  const CategoryIconComp = get(iconWhitelist, String(category.value?.icon_id)) || null

  const categoryButton = category.value ? (
    <TextButton
      color={Color.GREY_TONE_50}
      onClick={() => {
        setSidebarView('topics')
        topic.setValue(undefined)
        setSearchValue('')
      }}
    >
      <HStack space="s-4" align="center">
        {CategoryIconComp ? <CategoryIconComp size={14} /> : null}
        <Text>{category.value.name}</Text>
      </HStack>
    </TextButton>
  ) : null

  if (sidebarView === 'topics') {
    return categoryButton ? <Flex>{chain(homeButton, categoryButton)}</Flex> : homeButton
  }
  if (sidebarView === 'questions' || sidebarView === 'answer') {
    return (
      <Flex>
        {chain(
          homeButton,
          categoryButton,
          topic.value && (
            <TextButton
              color={Color.GREY_TONE_50}
              onClick={() => {
                if (sidebarView === 'answer') {
                  setSidebarView('questions')
                }
              }}
            >
              {topic.value.title}
            </TextButton>
          ),
        )}
      </Flex>
    )
  }
  return ''
}

export const useOpenHelpCenter = () => {
  const { changeQueryParam, deleteQueryParam } = useQuery()

  return {
    closeHelpCenter: () => deleteQueryParam('help'),
    openHelpCenter: () => changeQueryParam('help', 'true'),
  }
}
