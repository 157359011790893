import React from 'react'
import {
  Box,
  DataPoint,
  DataPointSkeleton,
  Highlights,
  Separator,
  Token,
} from '@revolut/ui-kit'
import { OneToOneMeetingsStats } from '@src/interfaces/meetings'

interface Props {
  isLoading: boolean
  stats?: OneToOneMeetingsStats
}

const Skeleton = () => (
  <Highlights>
    <DataPointSkeleton />
    <DataPointSkeleton />
    <DataPointSkeleton />
  </Highlights>
)

const getMeetingProgressColor = (value: number) => {
  return value >= 80 ? Token.color.success : Token.color.greyTone50
}

export const CompanyMeetingsProgress = ({ stats, isLoading }: Props) => {
  if (isLoading) {
    return <Skeleton />
  }

  if (!stats) {
    return null
  }

  return (
    <Highlights>
      <DataPoint>
        <DataPoint.Value color={getMeetingProgressColor(stats.total_setup_rate)} use="h3">
          {stats.total_setup_rate > 0
            ? `${stats.total_setup_rate}%`
            : stats.total_setup_rate}
        </DataPoint.Value>
        <DataPoint.Label>Setup Rate</DataPoint.Label>
      </DataPoint>
      <Box height="s-48">
        <Separator orientation="vertical" />
      </Box>
      <DataPoint>
        <DataPoint.Value color={getMeetingProgressColor(stats.lm_setup_rate)} use="h3">
          {stats.lm_setup_rate > 0 ? `${stats.lm_setup_rate}%` : stats.lm_setup_rate}
        </DataPoint.Value>
        <DataPoint.Label>LM Rate</DataPoint.Label>
      </DataPoint>
      <DataPoint>
        <DataPoint.Value color={getMeetingProgressColor(stats.fm_setup_rate)} use="h3">
          {stats.fm_setup_rate > 0 ? `${stats.fm_setup_rate}%` : stats.fm_setup_rate}
        </DataPoint.Value>
        <DataPoint.Label>FM Rate</DataPoint.Label>
      </DataPoint>
    </Highlights>
  )
}
