import React from 'react'
import pluralize from 'pluralize'
import { Avatar, Box, Item, Token } from '@revolut/ui-kit'

const borderStyle = `1px solid ${Token.color.grey50_40}`

interface Props {
  title: string
  employeesCount: number
  filtersCount: number
  isEligible: boolean
  isScoreCardsEnabled: boolean
}

export const EligibleGroup = ({
  title,
  employeesCount,
  filtersCount,
  isEligible,
  isScoreCardsEnabled,
}: Props) => {
  const description = pluralize('employee', employeesCount, true)
  const filterDetails = pluralize('filter', filtersCount, true)
  const groupDetails = isEligible
    ? `Scorecards: ${isScoreCardsEnabled ? 'enabled' : 'disabled'}`
    : 'Set as ineligible'

  return (
    <Box border={borderStyle} borderRadius="r16">
      <Item>
        <Item.Avatar>
          <Avatar useIcon="People" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description>{description}</Item.Description>
        </Item.Content>
        <Item.Side>
          <Item.Value>{filterDetails}</Item.Value>
          <Item.Value variant="secondary">{groupDetails}</Item.Value>
        </Item.Side>
      </Item>
    </Box>
  )
}
