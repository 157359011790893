import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { careersPageConfig } from '@src/pages/OnboardingChecklist/common/constants'
import CareersPageIntro from '@src/pages/OnboardingChecklist/CareersPage/CareersPageIntro'
import CareersPagePersonalisation from '@src/pages/OnboardingChecklist/CareersPage/CareersPagePersonalisation'
import { careerPageSettings } from '@src/api/settings'
import CareersPagePublish from '@src/pages/OnboardingChecklist/CareersPage/CareersPagePublish'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.INTRO,
    canView: [PermissionTypes.ViewCareerPagePreferences],
    component: CareersPageIntro,
  },
  {
    title: 'Personalisation',
    path: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.PERSONALISATION,
    url: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.PERSONALISATION,
    canView: [PermissionTypes.ViewCareerPagePreferences],
    component: CareersPagePersonalisation,
    form: {
      api: careerPageSettings,
    },
  },
  {
    title: 'Publish',
    path: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.PUBLISH,
    url: ROUTES.ONBOARDING_CHECKLIST.CAREERS_PAGE.PUBLISH,
    canView: [PermissionTypes.ViewCareerPagePreferences],
    component: CareersPagePublish,
    form: {
      api: careerPageSettings,
    },
  },
]

const CareersPage = () => {
  return <OnboardingChecklistContent config={careersPageConfig} routes={routes} />
}

export default CareersPage
