import React from 'react'
import { useParams } from 'react-router-dom'
import { Cell, Text, VStack } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import LapeFileUploader from '@src/components/Inputs/LapeFields/LapeFileUploader'
import { RouteParams } from '../../types'
import { useResignationPermissions } from '../../hooks'

type Props = {
  resignation?: EmployeeResignationInterface
}
const LetterAttachmentForm = ({ resignation }: Props) => {
  const { id, employeeId } = useParams<RouteParams>()
  const { canEdit } = useResignationPermissions(resignation)

  if (!canEdit) {
    return null
  }
  return (
    <>
      <PageBody>
        <VStack space="s-8">
          <Cell>
            <Text>
              Please download and fill in the correct resignation letter template based on
              the list provided here. After signing it, you can upload it using the
              attachment field below.
            </Text>
          </Cell>
          <LapeFileUploader name="notice_file" />
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Your resignation letter was successfully uploaded"
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT, {
            id,
            employeeId,
          })}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default LetterAttachmentForm
