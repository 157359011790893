import {
  Details,
  DetailsCell,
  ProgressStep,
  ProgressStepProps,
  ProgressSteps,
  Widget,
  ProgressStepState,
  Flex,
  Box,
} from '@revolut/ui-kit'
import React, { PropsWithChildren, ReactNode } from 'react'

export interface TimelinePreviewSectionInterface extends ProgressStepProps {
  label: string
  description: string
}

interface TimelinePreviewProps {
  sections: TimelinePreviewSectionInterface[]
  title?: ReactNode
}
export const TimelinePreview = ({ sections, title }: TimelinePreviewProps) => {
  const definedStates = [ProgressStepState.DONE, ProgressStepState.PENDING]
  return (
    <Widget>
      <DetailsCell>
        <Details.Title variant="caption">{title || 'Timeline preview'}</Details.Title>
      </DetailsCell>
      <ProgressSteps variant="vertical-compact">
        {sections.map(({ label, description, ...stepProps }, index) => (
          <ProgressStep
            key={label}
            state={definedStates[index] || ProgressStepState.DEFAULT}
            {...stepProps}
          >
            <ProgressStep.Title>{label}</ProgressStep.Title>
            <ProgressStep.Description>{description}</ProgressStep.Description>
          </ProgressStep>
        ))}
      </ProgressSteps>
    </Widget>
  )
}

type WithTimelinePreviewProps = PropsWithChildren<TimelinePreviewProps>

export const WithTimelinePreview = ({
  children,
  ...timelineProps
}: WithTimelinePreviewProps) => {
  return (
    <Flex gap="s-24">
      <Box width="65%">{children}</Box>
      <Box width="35%">
        <TimelinePreview {...timelineProps} />
      </Box>
    </Flex>
  )
}
