import React, { MouseEvent, useState } from 'react'
import Button, { Props as ButtonProps } from '@components/Button/Button'
import Icon, { Icons, IconSizes } from '@components/Icon/Icon'
import IconButton from '@components/ButtonIcon/IconButton'
import Loader from '@components/CommonSC/Loader'

interface OnLoadingButtonProps extends ButtonProps {
  onSubmit: () => Promise<any>
  icon?: Icons
  iconSize?: IconSizes
  customIcon?: React.ReactNode
}

const LoadingButton = ({
  onSubmit,
  iconSize = 'small',
  icon,
  customIcon,
  ...rest
}: OnLoadingButtonProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  if (icon || customIcon) {
    const IconToRender = icon ? <Icon size={iconSize} type={icon} /> : customIcon

    return (
      <IconButton {...rest} onClick={handleClick}>
        {loading ? <Loader size={iconSize} /> : IconToRender}
      </IconButton>
    )
  }

  return <Button {...rest} loading={loading} onClick={handleClick} />
}

/** @deprecated */
export default LoadingButton
