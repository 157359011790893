import { TeamsStatsInterface } from '@src/interfaces/teams'
import { ROUTES } from '@src/constants/routes'
import { RolesStatsInterface } from '@src/interfaces/roles'
import { NavigationConfigInterface } from '@components/StatNavigation/types'

export const teamsConfig: NavigationConfigInterface<TeamsStatsInterface>[] = [
  {
    key: 'departments_count',
    label: 'Departments',
    to: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
  },
  {
    key: 'teams_count',
    label: 'Teams',
    to: ROUTES.ORGANISATION.TEAMS.TEAMS,
  },
]

export const rolesConfig: NavigationConfigInterface<RolesStatsInterface>[] = [
  {
    key: 'functions_count',
    label: 'Functions',
    to: ROUTES.ORGANISATION.ROLES.FUNCTIONS,
  },
  {
    key: 'roles_count',
    label: 'Roles',
    to: ROUTES.ORGANISATION.ROLES.ROLES,
  },
  {
    key: 'specialisations_count',
    label: 'Specialisations',
    to: ROUTES.ORGANISATION.ROLES.SPECIALISATIONS,
  },
]
