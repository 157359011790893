import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'
import {
  employeesAccessGroupColumn,
  employeesAccessLevelColumn,
  employeesEmailColumn,
  employeesFirstNameColumn,
  employeesLastNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesTeamColumn,
} from '@src/constants/columns/employeesV2'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'

export const row =
  (onCreate: CreateCallback, mode: EditableTableRenderMode) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      {
        ...employeesEmailColumn(options.onChange),
        width: 200,
      },
      {
        ...employeesFirstNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesLastNameColumn(options.onChange),
        width: 120,
      },
      {
        ...employeesTeamColumn(
          options.onChange,
          onChangeAction => onCreate('team', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesRoleColumn(
          options.onChange,
          onChangeAction => onCreate('role', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesSeniorityColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...(mode === 'bulkSession'
          ? employeesAccessLevelColumn(options.onChange)
          : employeesAccessGroupColumn(options.onChange)),
        width: 200,
      },
    ],
  })

export const mandatoryBulkSessionFields = ['email', 'first_name', 'last_name']
export const fieldsForBulkEdit = [...baseFieldsForBulkEdit, 'access_level']
export type FieldsForBulkEdit = typeof fieldsForBulkEdit[number]

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  access_groups: ['access_level'],
}
