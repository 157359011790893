import SideBar, { SideBarProps } from '@src/components/SideBar/SideBar'
import omit from 'lodash/omit'
import React, { PropsWithChildren, useContext, useMemo, useRef, useState } from 'react'

type SidebarContextState = {
  show: (props: SideBarProps) => void
  hide: () => void
  isOpen: () => boolean
}

const SidebarContext = React.createContext<SidebarContextState | null>(null)

const emptySidebar: SideBarProps = {
  isOpen: false,
  children: null,
}

export const SidebarProvider = ({ children }: PropsWithChildren<{}>) => {
  const [sidebarProps, setSidebarProps] = useState<SideBarProps>(emptySidebar)
  const isOpen = useRef(false)

  const closeSidebar = () => {
    setSidebarProps(emptySidebar)
    isOpen.current = false
  }

  const contextValue = useMemo<SidebarContextState>(
    () => ({
      show: props => {
        setSidebarProps({ isOpen: true, ...props })
        isOpen.current = true
      },
      hide: closeSidebar,
      isOpen: () => isOpen.current,
    }),
    [],
  )

  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
      <SideBar
        {...omit(sidebarProps, 'children')}
        onClose={() => {
          if (sidebarProps.onClose) {
            sidebarProps.onClose()
          }
          closeSidebar()
        }}
      >
        {sidebarProps.children}
      </SideBar>
    </SidebarContext.Provider>
  )
}

export const useSidebar = () => {
  const sidebarContext = useContext(SidebarContext)
  if (!sidebarContext) {
    throw new Error(
      'Sidebar context is empty, perhaps you forgot to wrap your app with SidebarProvider',
    )
  }

  return sidebarContext
}
