import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise, AxiosResponse } from 'axios'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  EmailPlaceholderInterface,
  EmailTemplateInterface,
} from '@src/interfaces/emailTemplates'
import { FileInterface } from '@src/interfaces/files'
import { uploadFile } from '@src/api/files'
import { isFile } from '@src/utils/files'

export const getEmailTemplates = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<EmailTemplateInterface>> =>
  api.get(`${API.EMAIL_TEMPLATES}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const emailTemplatesRequests: RequestInterfaceNew<EmailTemplateInterface> = {
  get: async ({ id }) => api.get(`${API.EMAIL_TEMPLATES}/${id}`),
  update: async (data, { id }) => {
    const { attachments, ...form } = data

    let files: AxiosResponse<FileInterface>[] | undefined
    if (attachments) {
      files = await Promise.all(
        attachments.map(file =>
          isFile(file)
            ? uploadFile(file, 'email_template')
            : new Promise<AxiosResponse<FileInterface>>(resolve => {
                resolve({ data: file } as AxiosResponse<FileInterface>)
              }),
        ),
      )
    }

    return apiWithoutHandling.patch(`${API.EMAIL_TEMPLATES}/${id}`, {
      ...form,
      ...(attachments || attachments === null
        ? {
            attachments: files?.map(file => ({
              id: file?.data?.id,
            })),
          }
        : {}),
    })
  },
  submit: async data => {
    let files: AxiosResponse<FileInterface>[] | undefined
    if (data.attachments) {
      files = await Promise.all(
        data.attachments.map(file => uploadFile(file as File, 'email_template')),
      )
    }

    return apiWithoutHandling.post(`${API.EMAIL_TEMPLATES}`, {
      ...data,
      attachments:
        files?.map(file => ({
          id: file?.data?.id,
        })) || [],
    })
  },
}

export const getEmailTemplatesPlaceholders = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmailPlaceholderInterface>
> =>
  api.get(`${API.EMAIL_PLACEHOLDERS}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })
