import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { ChartBarWithArrow, InfoOutline } from '@revolut/icons'
import InstructionsSidebar from '@src/pages/Forms/KpiForm/common/InstructionsSidebar'
import QualityCheckSidebar from '@src/pages/Forms/KpiForm/common/QualityCheckSidebar'

interface UseKPISidebarsReturnType {
  sidebars: React.ReactNode
  buttons: React.ReactNode
}

enum SidebarState {
  Instructions = 'instructions',
  QualityChecklist = 'quality_checklist',
  Closed = 'closed',
}

const useKPISidebars = (disabled?: boolean): UseKPISidebarsReturnType => {
  const [state, setState] = useState<SidebarState>(SidebarState.Closed)

  if (disabled) {
    return { buttons: null, sidebars: null }
  }
  return {
    buttons: (
      <>
        <MoreBar.Action
          useIcon={InfoOutline}
          onClick={() => {
            if (state !== SidebarState.Instructions) {
              setState(SidebarState.Instructions)
            } else {
              setState(SidebarState.Closed)
            }
          }}
        >
          View KPI step-by-step
        </MoreBar.Action>
        <MoreBar.Action
          useIcon={ChartBarWithArrow}
          onClick={() => {
            if (state !== SidebarState.QualityChecklist) {
              setState(SidebarState.QualityChecklist)
            } else {
              setState(SidebarState.Closed)
            }
          }}
        >
          View quality checklist
        </MoreBar.Action>
      </>
    ),
    sidebars: (
      <>
        <InstructionsSidebar
          isOpen={state === SidebarState.Instructions}
          onClose={() => {
            setState(SidebarState.Closed)
          }}
        />
        <QualityCheckSidebar
          isOpen={state === SidebarState.QualityChecklist}
          onClose={() => {
            setState(SidebarState.Closed)
          }}
        />
      </>
    ),
  }
}

export default useKPISidebars
