import { SystemNotificationDescriptionInterface } from '@src/interfaces/systemNotifications'
import { useEffect } from 'react'

export const useNotificationPermission = (askForPermission: boolean) => {
  useEffect(() => {
    if (!askForPermission || !isNotificationSupported()) {
      return
    }

    if (Notification.permission === 'default') {
      Notification.requestPermission()
    }
  }, [])
}

export const sendNotification = (
  notification: SystemNotificationDescriptionInterface,
) => {
  if (!isNotificationSupported() || !notification.title) {
    return
  }

  if (Notification.permission === 'granted') {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        const browserNotification = new Notification(notification.title, {
          body: notification.short_description,
        })
        browserNotification.onclick = () => {
          window.focus()
          browserNotification.close()
        }
      }
    })
  }
}

const isNotificationSupported = () => {
  return (
    typeof Notification !== 'undefined' &&
    typeof Notification.requestPermission === 'function'
  )
}
