import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { contractsSettings } from '@src/api/settings'
import { Contracts } from '@src/pages/Settings/Contracts/Contracts'

const routes = [
  {
    title: 'Contracts settings',
    description: 'All settings related to the contracts application',
    path: ROUTES.SETTINGS.CONTRACTS,
    url: ROUTES.SETTINGS.CONTRACTS,
    canView: [
      PermissionTypes.ViewContractsPreferences,
      PermissionTypes.ChangeContractsPreferences,
    ],
    component: Contracts,
  },
]

export const ContractsSettings = () => {
  return <SettingsForm routes={routes} api={contractsSettings} />
}
