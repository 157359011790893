import React from 'react'
import styled from 'styled-components'
import { Color, Dots, Text, Flex, VStack, Box } from '@revolut/ui-kit'

import {
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
} from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import Reviewer from '../Common/Reviewer'
import PipActions from './PIPActions'

const Wrap = styled.div`
  padding-top: 16px;
  grid-gap: 32px;
`

type Props = {
  requests?: (PerfReviewRequestFeedbackInterface | RequestFeedbackInterface)[]
  isNewFlow: boolean
  fetching: boolean
  isPIP?: boolean
  setFilter: (filter: OptionInterface) => void
}

const Reviews = ({ requests, isNewFlow, isPIP, fetching, setFilter }: Props) => {
  if (!requests?.length && !fetching) {
    return null
  }

  return (
    <Wrap>
      <Text color={Color.GREY_TONE_50}>Reviews</Text>

      {fetching ? (
        <Dots color={Color.GREY_TONE_50} />
      ) : (
        <Box mt="s-16">
          <VStack gap="s-16">
            {requests?.map(request => (
              <Flex key={request.id} alignItems="center" justifyContent="space-between">
                <Reviewer request={request} isNewFlow={isNewFlow} />
                <Flex>
                  {isPIP && (
                    <PipActions
                      request={request as RequestFeedbackInterface}
                      setFilter={setFilter}
                    />
                  )}
                </Flex>
              </Flex>
            ))}
          </VStack>
        </Box>
      )}
    </Wrap>
  )
}

export default Reviews
