import React, { useEffect, useState } from 'react'
import { RadioOptionInterface } from '@components/Inputs/RadioButtons/RadioButtons'
import { Box, Flex, Tag, Text, Token } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, Dot } from '@revolut/icons'
import {
  MeetingTrackerCategory,
  MeetingTrackerCategoryColors,
  MeetingTrackerCategoryTitles,
} from '@src/interfaces/meetingsTracker'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export interface MeetingCategorySelectProps {
  value?: MeetingTrackerCategory | null
  categories: RadioOptionInterface[]
  onChange: (category?: MeetingTrackerCategory) => void
  readOnly?: boolean
}

type ShevronProps = {
  open: boolean
}
const Shevron = ({ open }: ShevronProps) => {
  return open ? (
    <ChevronUp size={15} color="grey-tone-50" />
  ) : (
    <ChevronDown size={15} color="grey-tone-50" />
  )
}

export const MeetingCategorySelect = ({
  categories,
  value,
  onChange,
  readOnly,
}: MeetingCategorySelectProps) => {
  const [selectedCategory, setSelectedCategory] = useState(value)

  useEffect(() => {
    setSelectedCategory(value)
  }, [value])

  const options = categories.map(item => ({
    value: { id: item.id },
    label: item.name as string,
  }))

  const notSelectedLabel = readOnly ? '-' : 'Select type'
  const labelText = selectedCategory
    ? MeetingTrackerCategoryTitles[selectedCategory]
    : notSelectedLabel
  const labelColor = selectedCategory
    ? MeetingTrackerCategoryColors[selectedCategory]
    : null

  return (
    <RadioSelectInput
      options={options}
      value={categories.find(category => category.id === selectedCategory)}
      onChange={option => {
        const category = (option?.id as MeetingTrackerCategory) || null
        setSelectedCategory(category)
        onChange(category)
      }}
      disabled={readOnly}
      renderInput={(open, setOpen, ref) => (
        <Flex
          ref={ref}
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          onClick={() => !readOnly && setOpen(!open)}
          style={{ cursor: readOnly ? 'default' : 'pointer' }}
        >
          {!selectedCategory ? (
            <Text color={Token.color.greyTone20}>{labelText}</Text>
          ) : (
            <Tag color={Token.color.black_10}>
              {labelColor && (
                <Box mr="s-4">
                  <Dot color={labelColor} size={10} />
                </Box>
              )}
              <Text color={Token.color.foreground}>{labelText}</Text>
            </Tag>
          )}
          {!readOnly && <Shevron open={open} />}
        </Flex>
      )}
    />
  )
}
