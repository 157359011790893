import React from 'react'
import { Avatar, Box, DetailsCell, Group, IconButton, Item, Token } from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'
import { FormPreviewDivider } from '@components/FormPreview/FormPreview'
import {
  OnlineTestResultEventInterface,
  OnlineTestResultStageInterface,
} from '@src/interfaces/interviewTool'

const getOnlineResultFinalStage = (events: OnlineTestResultEventInterface[]) => {
  const stage = events.find(event => event.workflowStatus)
  let status

  switch (stage?.workflowStatus) {
    case 'WorkflowCompleted':
      status = 'Completed'
      break

    case 'WorkflowPassed':
      status = 'Passed'
      break

    case 'WorkflowFailed':
      status = 'Failed'
      break
  }

  return { status, band: stage?.band }
}

type Props = {
  results?: OnlineTestResultStageInterface[]
}

export const OnlineTestResultEvents = ({ results }: Props) => {
  if (!results) {
    return null
  }

  return (
    <>
      {results.map(item => {
        const finalStage = getOnlineResultFinalStage(item.events)
        const sentDate = item.events.find(
          event =>
            event.interviewStatus === 'Started' || event.interviewStatus === 'Invited',
        )?.startedDate

        const items = item.events.filter(
          event => event.interviewStatus && event.interviewStatus !== 'Started',
        )
        return (
          <Group key={item.interviewId} data-testid={`workflow-${item.interviewId}`}>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="Document" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{item.currentStage || 'Unknown stage'}</Item.Title>
                <Item.Description>
                  <Box>Latest stage status: {finalStage.status || 'N/A'}</Box>
                  <Box>Latest band: {finalStage.band || 'N/A'}</Box>
                  {sentDate && <Box>Sent on: {formatDateTime(sentDate)}</Box>}
                </Item.Description>
              </Item.Content>
            </Item>

            {!!items.length && (
              <Group>
                {items.map((event, idx, arr) => (
                  <Group key={event.id} data-testid={`event-${event.id}`}>
                    <DetailsCell variant="header">
                      <DetailsCell.Title>Interview status</DetailsCell.Title>
                      <DetailsCell.Content>{event.interviewStatus}</DetailsCell.Content>
                    </DetailsCell>
                    {event.interviewStatus === 'Submitted' ? (
                      <>
                        <DetailsCell>
                          <DetailsCell.Title>Start date</DetailsCell.Title>
                          <DetailsCell.Content>
                            {event.startedDate ? formatDateTime(event.startedDate) : '-'}
                          </DetailsCell.Content>
                        </DetailsCell>
                        <DetailsCell>
                          <DetailsCell.Title>Submitted date</DetailsCell.Title>
                          <DetailsCell.Content>
                            {event.submittedDate
                              ? formatDateTime(event.submittedDate)
                              : '-'}
                          </DetailsCell.Content>
                        </DetailsCell>
                      </>
                    ) : (
                      <DetailsCell>
                        <DetailsCell.Title>Date</DetailsCell.Title>
                        <DetailsCell.Content>
                          {event.startedDate ? formatDateTime(event.startedDate) : '-'}
                        </DetailsCell.Content>
                      </DetailsCell>
                    )}
                    {event.score && (
                      <DetailsCell>
                        <DetailsCell.Title>Score</DetailsCell.Title>
                        <DetailsCell.Content>{event.score}</DetailsCell.Content>
                      </DetailsCell>
                    )}
                    {event.band && (
                      <DetailsCell>
                        <DetailsCell.Title>Band</DetailsCell.Title>
                        <DetailsCell.Content>{event.band}</DetailsCell.Content>
                      </DetailsCell>
                    )}
                    {event.reviewUrl && (
                      <DetailsCell>
                        <DetailsCell.Title>Review URL</DetailsCell.Title>
                        <DetailsCell.Content>
                          <IconButton
                            use="a"
                            target="_blank"
                            rel="noreferrer noopener"
                            href={event.reviewUrl}
                            size={16}
                            color={Token.color.blue}
                            useIcon="LinkExternal"
                          />
                        </DetailsCell.Content>
                      </DetailsCell>
                    )}
                    {idx < arr.length - 1 && <FormPreviewDivider />}
                  </Group>
                ))}
              </Group>
            )}
          </Group>
        )
      })}
    </>
  )
}
