import React from 'react'
import DocumentsRequestsForm from './General'
import Form from '@src/features/Form/Form'
import { requestDocumentRequest } from '@src/api/documents'
import { connect } from 'lape'

export default connect(() => {
  return (
    <Form api={requestDocumentRequest}>
      <DocumentsRequestsForm />
    </Form>
  )
})
