import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar } from '@revolut/ui-kit'
import { Envelope } from '@revolut/icons'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  id: number | string
}

const SendDataEmail = ({ id }: Props) => {
  return (
    <MoreBar.Action
      useIcon={Envelope}
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.SEND_EMAIL.CANDIDATE_DATA, { candidateId: id })}
    >
      Send personal data
    </MoreBar.Action>
  )
}

export default SendDataEmail
