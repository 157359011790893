import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { CalendarCheck } from '@revolut/icons'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { ChangeStagePopup } from '@src/pages/Forms/Candidate/InterviewProgress/components/Actions/ChangeStagePopup'

type Props = {
  data: InterviewStageWithoutRoundInterface[]
  currentStageId?: number
  roundId: number
  onChange: (stage: InterviewRoundInterface) => void
}

const ChangeStage = ({ data, currentStageId, roundId, onChange }: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const [open, setOpen] = useState(false)

  return (
    <>
      <MoreBar.Action
        useIcon={CalendarCheck}
        onClick={e => {
          e.preventDefault()
          sendAnalyticsEvent(AnalyticsEvents.click_change_stage_candidate_profile)
          setOpen(true)
        }}
      >
        Change Stage
      </MoreBar.Action>

      <ChangeStagePopup
        open={open}
        onClose={() => setOpen(false)}
        data={data}
        roundId={roundId}
        onChange={onChange}
        currentStageId={currentStageId}
      />
    </>
  )
}

export default ChangeStage
