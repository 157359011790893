import React, { useEffect } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { connect } from 'lape'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { SendCandidateEmailInterface, WhenToSend } from '@src/interfaces/hiringProccess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AxiosPromise } from 'axios'
import SendEmailCommonContent from '@src/pages/Forms/SendEmail/SendEmailCommonContent'
import { Props as EmailFormProps } from '@src/features/EmailForm/EmailForm'
import { OptionInterface } from '@src/interfaces/selectors'
import { Side } from '@revolut/ui-kit'

export type SendEmailCommonProps = {
  children?: React.ReactNode
  onSuccess?: (email: SendCandidateEmailInterface) => void
  fetchEmail?: (templateId: number) => AxiosPromise<SendCandidateEmailInterface>
  replyEmail?: SendCandidateEmailInterface
  bottom?: React.ReactNode
  actions?: React.ReactNode
  noEditor?: boolean
  successText?: string
  btnLabel?: string
  insideSidebar?: boolean
  showInvalidPlaceholdersWarning?: boolean
  emailFormProps?: EmailFormProps
}

const SendEmailCommon = ({
  children,
  bottom,
  onSuccess,
  fetchEmail,
  replyEmail,
  actions,
  noEditor,
  successText = 'Email has been sent',
  btnLabel = 'Send email',
  insideSidebar,
  showInvalidPlaceholdersWarning,
  emailFormProps,
}: SendEmailCommonProps) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()

  useEffect(() => {
    if (!values.sender_type) {
      values.sender_type = 'user'
    }

    if (!values.when_to_send) {
      // TODO: this should be defined by passing prop to LapeRadioSelectInput
      values.when_to_send = { id: WhenToSend.NOW, name: 'Send now' }
    }
  }, [])

  const onEmailTemplateChange = async (emailTemplate: OptionInterface | null) => {
    if (emailTemplate?.id && fetchEmail) {
      const resp = await fetchEmail(+emailTemplate.id)
      values.email_body = resp.data.email_body
      values.sender_type = resp.data.sender_type
      values.recipients_cc = resp.data.recipients_cc
      values.recipients_bcc = resp.data.recipients_bcc
      values.subject = resp.data.subject
      values.attachments = resp.data.attachments
    }
  }

  useEffect(() => {
    if (!replyEmail) {
      return
    }

    values.sender_type = replyEmail.sender_type
    values.recipients_cc = replyEmail.recipients_cc
    values.recipients_bcc = replyEmail.recipients_bcc
    values.subject = `Re: ${replyEmail.subject}`
  }, [replyEmail])

  const actionsNode =
    actions === undefined ? (
      <NewSaveButtonWithPopup<SendCandidateEmailInterface>
        successText={successText}
        onAfterSubmit={onSuccess}
        useValidator
        hideWhenNoChanges={false}
        data-testid="btn-send-email"
      >
        {btnLabel}
      </NewSaveButtonWithPopup>
    ) : (
      actions
    )

  if (insideSidebar) {
    return (
      <>
        <SendEmailCommonContent
          bottom={bottom}
          noEditor={noEditor}
          showInvalidPlaceholdersWarning={showInvalidPlaceholdersWarning}
          reducedTitlePadding
          emailFormProps={emailFormProps}
          onEmailTemplateChange={onEmailTemplateChange}
        >
          {children}
        </SendEmailCommonContent>
        {actionsNode && <Side.Actions>{actionsNode}</Side.Actions>}
      </>
    )
  }

  return (
    <>
      <PageBody>
        <SendEmailCommonContent
          bottom={bottom}
          noEditor={noEditor}
          showInvalidPlaceholdersWarning={showInvalidPlaceholdersWarning}
          emailFormProps={emailFormProps}
          onEmailTemplateChange={onEmailTemplateChange}
        >
          {children}
        </SendEmailCommonContent>
      </PageBody>
      {actionsNode && <PageActions>{actionsNode}</PageActions>}
    </>
  )
}

export default connect(SendEmailCommon)
