import React, { useCallback, useMemo } from 'react'
import {
  Box,
  Fixed,
  Flex,
  HStack,
  List,
  Progress,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { Cross, InfoOutline, StatusCheck } from '@revolut/icons'
import styled from 'styled-components'
import { useGetPredefinedSectionsConfig } from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CareersApplicationInterface,
  GeneralSectionQuestionsKeys,
  PredefinedSectionKeys,
} from '@src/interfaces/careers'
import { getLinkedinUrl } from '@src/utils/hiring'
import { connect } from 'lape'
import { useCustomSectionsContext } from '@src/pages/Careers/CareersApplication/components/CustomSectionsContext'
import { useCareersGeneralSectionFields } from '@src/pages/Careers/hooks/useCareersGeneralSectionFields'
import get from 'lodash/get'

const Wrap = styled(Fixed)`
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.08);
`

const CheckListPoints = {
  general: 'General information',
  cv: 'CV',
  linkedin: 'LinkedIn',
  workExperience: 'Work experience',
  education: 'Education',
  questions: 'Other questions',
}

type CheckListPointsType = keyof typeof CheckListPoints

const BASE_PERCENTAGE: { [key in CheckListPointsType]: number } = {
  general: 10,
  cv: 10,
  linkedin: 15,
  workExperience: 20,
  education: 20,
  questions: 25,
}

const ProgressCompletion = () => {
  const { values } = useLapeContext<CareersApplicationInterface>()
  const tooltip = useTooltip()
  const config = useGetPredefinedSectionsConfig()

  const generalSectionFields = useCareersGeneralSectionFields({})
  const customSectionsContext = useCustomSectionsContext()

  const [mandatoryQuestionsCount, answeredMandatoryQuestionsCount] = useMemo(
    () =>
      values.additional_questions?.sections?.reduce(
        (acc, section) => {
          section.questions?.forEach(question => {
            if (!question.optional) {
              acc[0] += 1

              if (question.internal_data_type === 'checkbox_application_form_question') {
                const answered = question.options.some(option => option.answer)

                if (answered) {
                  acc[1] += 1
                }
              } else if (question.answer) {
                acc[1] += 1
              }
            }
          })

          return acc
        },
        [0, 0],
      ) || [0, 0],
    [customSectionsContext.sections],
  )

  const [generalQuestionsCount, answeredGeneralQuestionsCount] = useMemo(
    () =>
      generalSectionFields.reduce(
        (acc, field) => {
          if (field.required) {
            acc[0] += 1

            if (get(values, field.key)) {
              acc[1] += 1
            }
          }

          return acc
        },
        [0, 0],
      ),
    [
      ...Object.keys(GeneralSectionQuestionsKeys).map(key => get(values, key)),
      generalSectionFields,
    ],
  )

  // true - completed, false - uncompleted, null - section not visible
  const progress: { [key in CheckListPointsType]: boolean | null } = {
    general:
      config.get(PredefinedSectionKeys.general)?.progressIncluded &&
      generalQuestionsCount > 0
        ? generalQuestionsCount === answeredGeneralQuestionsCount
        : null,
    cv: config.get(PredefinedSectionKeys.resume)?.progressIncluded ? !!values.file : null,
    linkedin: config.get(PredefinedSectionKeys.links)?.progressIncluded
      ? !!getLinkedinUrl(values.links)
      : null,
    workExperience: config.get(PredefinedSectionKeys.work_experience)?.progressIncluded
      ? !!values.work_experiences?.length
      : null,
    education: config.get(PredefinedSectionKeys.education)?.progressIncluded
      ? !!values.educations?.length
      : null,
    questions:
      mandatoryQuestionsCount > 0
        ? mandatoryQuestionsCount === answeredMandatoryQuestionsCount
        : null,
  }

  const percentage = useMemo(() => {
    // calculating possible max percentage for visible sections and it's count
    const [maxPercentage, totalVisibleSections] = (
      Object.keys(progress) as CheckListPointsType[]
    ).reduce(
      (acc, key) => {
        if (progress[key] !== null) {
          acc[0] += BASE_PERCENTAGE[key]
          acc[1] += 1
        }

        return acc
      },
      [0, 0],
    )

    // if some sections are not visible we will have less than 100% in total
    const remainingPercentage = 100 - maxPercentage

    if (remainingPercentage === 0) {
      return BASE_PERCENTAGE
    }

    // calculating how much we should top up to each section, spread it equally
    const remainingPercentagePerSection = Math.floor(
      remainingPercentage / totalVisibleSections,
    )

    let lastVisibleSectionKey: CheckListPointsType | undefined
    let totalPercentage = 0
    let result: { [key in CheckListPointsType]: number } | undefined

    result = (Object.keys(progress) as CheckListPointsType[]).reduce<{
      [key in CheckListPointsType]: number
    }>(
      (acc, key) => {
        // if section is not visible
        if (progress[key] === null) {
          acc[key] = 0
        } else {
          // add base percentage and how much it's left to make it 100% in total
          acc[key] = BASE_PERCENTAGE[key] + remainingPercentagePerSection
          lastVisibleSectionKey = key
          // remember actual total percentage to calibrate the value after rounding
          totalPercentage += acc[key]
        }

        return acc
      },
      {
        general: 0,
        questions: 0,
        education: 0,
        cv: 0,
        workExperience: 0,
        linkedin: 0,
      },
    )

    // calibration
    if (lastVisibleSectionKey) {
      if (totalPercentage < 100) {
        // in case if we rounded it we top up remaining value to the last section to make it 100% in total
        result[lastVisibleSectionKey] += 100 - totalPercentage
      } else if (totalPercentage > 100) {
        // should not happen, but checking just in case we made some mistakes in calculation
        result[lastVisibleSectionKey] -= totalPercentage - 100
      }
    }

    return result
  }, [progress, BASE_PERCENTAGE])

  const getMandatoryQuestionsPercentage = useCallback(() => {
    // it could be several questions that have just 1 value (total value for the whole section) in "percentage", but we need to show gradual increase when user filling the form question by question
    if (mandatoryQuestionsCount) {
      return Math.ceil(
        (percentage.questions / mandatoryQuestionsCount) *
          answeredMandatoryQuestionsCount,
      )
    }

    return 0
  }, [mandatoryQuestionsCount, percentage, answeredMandatoryQuestionsCount])

  const getGeneralSectionQuestionsPercentage = useCallback(() => {
    // it could be several questions that have just 1 (total value for the whole section) value in "percentage", but we need to show gradual increase when user filling the form question by question
    if (generalQuestionsCount) {
      return Math.ceil(
        (percentage.general / generalQuestionsCount) * answeredGeneralQuestionsCount,
      )
    }

    return 0
  }, [generalQuestionsCount, percentage, answeredGeneralQuestionsCount])

  const getChecklist = () => {
    return (Object.keys(progress) as CheckListPointsType[]).map(key => {
      const check = progress[key]

      if (check === null) {
        return null
      }

      return (
        <List.Item
          key={key}
          useIcon={check ? StatusCheck : Cross}
          color={check ? Token.color.green : Token.color.red_50}
        >
          <Text
            data-testid={`checklist-row-${key}`}
            data-checked={check}
            color={check ? Token.color.white : Token.color.greyTone50}
          >
            {CheckListPoints[key]}, {percentage[key]}%
          </Text>
        </List.Item>
      )
    })
  }

  const totalPercentage = useMemo(
    () =>
      (Object.keys(percentage) as CheckListPointsType[]).reduce((acc, key) => {
        const val = progress[key] ? percentage[key] : 0

        if (key === 'questions' && mandatoryQuestionsCount > 0) {
          acc += getMandatoryQuestionsPercentage()
        } else if (key === 'general' && generalQuestionsCount > 0) {
          acc += getGeneralSectionQuestionsPercentage()
        } else {
          acc += val
        }

        return acc
      }, 0),
    [
      mandatoryQuestionsCount,
      generalQuestionsCount,
      percentage,
      progress,
      getMandatoryQuestionsPercentage,
      getGeneralSectionQuestionsPercentage,
    ],
  )

  return (
    <Wrap bottom={0} width="100%" bg={Token.color.white}>
      <Box py="s-16" px="s-4" maxWidth={730} m="auto">
        <Tooltip {...tooltip.getTargetProps()}>
          Help us to learn more about your experience: <br />
          <List variant="compact">{getChecklist()}</List>
        </Tooltip>

        <Flex justifyContent="space-between" mb="s-8">
          <HStack gap="s-8" align="center">
            <Text variant="primary">Profile completion</Text>
            <InfoOutline
              size={16}
              aria-label="Progress icon"
              color={Token.color.greyTone50}
              {...tooltip.getAnchorProps()}
            />
          </HStack>
          <Text>
            {totalPercentage}% {totalPercentage === 100 && '🚀'}
          </Text>
        </Flex>
        <Progress value={totalPercentage / 100} />
      </Box>
    </Wrap>
  )
}

export default connect(ProgressCompletion)
