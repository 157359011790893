import { useEffect, useRef, useState } from 'react'

const CHECK_IFRAME_CONTENT_TIMEOUT = 3000

export const useReloadCVIframe = (src?: string | null) => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const interval = useRef<NodeJS.Timeout>()
  const [iframeLoaded, setIframeLoaded] = useState(false)

  const clearCheckingInterval = () => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }

  const onIframeLoaded = () => {
    clearCheckingInterval()
    setIframeLoaded(true)
  }

  useEffect(() => {
    if (!src) {
      return () => {
        clearCheckingInterval()
      }
    }

    // we use Google viewer and sometimes it renders a blank page
    interval.current = setInterval(() => {
      try {
        if (!iframeRef?.current?.contentWindow?.document?.body?.innerHTML) {
          if (iframeRef.current) {
            iframeRef.current.src = src
          }
        } else {
          onIframeLoaded()
        }
        // when innerHTML inside the iframe is finally there, browser will throw the security exception - it means the content has loaded
      } catch (e) {
        onIframeLoaded()
      }
    }, CHECK_IFRAME_CONTENT_TIMEOUT)

    return () => {
      clearCheckingInterval()
    }
  }, [src])

  return { iframeRef, iframeLoaded }
}
