import React from 'react'
import styled from 'styled-components'
import Tooltip from '../../components/Tooltip/Tooltip'
import { HeaderScoreTooltip } from './Tooltips/HeaderScoreTooltip'
import { performanceToColor, performanceToString } from './constants'
import Icon from '../../components/Icon/Icon'
import IconButton from '../../components/ButtonIcon/IconButton'
import { PerformanceScores } from '@src/interfaces/scorecard'
import { formatPercentage } from '@src/utils/format'
import { Token } from '@revolut/ui-kit'

const Container = styled.div`
  display: flex;
  white-space: nowrap;
`
const Performance = styled.div<{ performance?: PerformanceScores }>`
  color: ${props => {
    return performanceToColor(props.performance)
  }};
  font-size: 20px;
`
const Score = styled.div`
  cursor: pointer;
`
const EditReviewIconButton = styled(IconButton)`
  color: ${Token.color.greyTone20};
  margin-left: 10px;
`

interface MainScoreProps {
  onEditClick: () => void
  showEditing: boolean
  performanceLabel?: PerformanceScores
  performancePercentage?: number
}

export const MainScore = ({
  onEditClick,
  showEditing,
  performanceLabel,
  performancePercentage,
}: MainScoreProps) => {
  const ScoreRouter = () => {
    const showLabel =
      performanceLabel &&
      performanceLabel !== PerformanceScores.PROBATION_FAILED &&
      performanceLabel !== PerformanceScores.PROBATION_PASSED

    return (
      <Tooltip body={<HeaderScoreTooltip withLink />} placement="left" hide={!showLabel}>
        <Performance performance={performanceLabel}>
          <Score>
            {showLabel && performanceToString(performanceLabel, true)}
            {performancePercentage && showLabel && ' · '}
            {performancePercentage ? `${formatPercentage(performancePercentage)}` : ''}
          </Score>
        </Performance>
      </Tooltip>
    )
  }
  return (
    <Container>
      {ScoreRouter()}
      {showEditing && (
        <Tooltip placement="left" text="Edit your review">
          <EditReviewIconButton onClick={onEditClick}>
            <Icon size="small" type="Edit" />
          </EditReviewIconButton>
        </Tooltip>
      )}
    </Container>
  )
}
