import React from 'react'
import { Dropdown, VStack, Text, Token } from '@revolut/ui-kit'

interface RemoveAccountItemProps {
  onClick: () => void
}

export const RemoveAccountItem = ({ onClick }: RemoveAccountItemProps) => {
  return (
    <Dropdown.Item onClick={onClick} use="button" useIcon="LogoutDoor">
      <VStack space="s-4">
        <Text>Remove account</Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          Sign out and remove from account selector
        </Text>
      </VStack>
    </Dropdown.Item>
  )
}
