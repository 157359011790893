import React from 'react'
import { MeetingTemplateEditStep } from '../interfaces'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HStack } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { MeetingTemplateInterface, MeetingTemplateStatus } from '@src/interfaces/meetings'
import { useLocation } from 'react-router-dom'
import { getTemplateStatus, isStepDone } from '../helpers'

const nextStepMap: Record<MeetingTemplateEditStep, MeetingTemplateEditStep | 'finish'> = {
  general: 'fields',
  fields: 'audience',
  audience: 'finish',
}

export const FormActions = ({
  currentStep,
}: {
  currentStep: MeetingTemplateEditStep
}) => {
  const { values, dirty, submit } = useLapeContext<MeetingTemplateInterface>()
  const showStatusPopup = useShowStatusPopup()
  const { state } = useLocation<{ shouldGoBackOnSave?: boolean }>()

  const handleFinishClick = () => {
    showStatusPopup({
      title: 'Template saved',
      status: 'success',
    })
    state.shouldGoBackOnSave
      ? goBack()
      : navigateTo(ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES)
  }

  const handleSaveButtonClick = async ({
    variant,
    id,
  }: {
    variant: 'draft' | 'next'
    id?: string | number
  }) => {
    nextStepMap[currentStep] === 'finish' || state.shouldGoBackOnSave
      ? handleFinishClick()
      : navigateReplace(
          variant === 'draft'
            ? ROUTES.PERFORMANCE.ONE_TO_ONE.TEMPLATES
            : pathToUrl(ROUTES.FORMS.MEETING_TEMPLATE.EDIT, {
                id,
                step: nextStepMap[currentStep],
              }),
        )
  }

  const getNextButton = () => {
    if (nextStepMap[currentStep] === 'finish') {
      return state.shouldGoBackOnSave ? 'Save' : 'Finish'
    }

    if (dirty) {
      return 'Save changes'
    }

    return state.shouldGoBackOnSave ? 'Save' : 'Next'
  }

  const handleOnDraftClick = async () => {
    values.status = MeetingTemplateStatus.Draft
    const response = await submit()
    return response
  }

  const handleOnSaveClick = async () => {
    if (typeof state.shouldGoBackOnSave === 'undefined') {
      const shouldSendDraft =
        currentStep !== 'audience' && isStepDone(currentStep, values)
      values.status = shouldSendDraft
        ? MeetingTemplateStatus.Draft
        : MeetingTemplateStatus.Active
    } else {
      values.status =
        values.status === MeetingTemplateStatus.Active
          ? MeetingTemplateStatus.Active
          : getTemplateStatus(
              values.audiences,
              values.items,
              values.name,
              values.frequency,
              values.duration,
            )
    }

    const response = await submit()
    return response
  }

  const tooltipText = values.name ? '' : 'Template title should be filled'
  return (
    <HStack gap="s-16">
      <NewSaveButtonWithPopup
        hideWhenNoChanges={false}
        useValidator
        noPopup
        variant="secondary"
        tooltipText={tooltipText}
        disabled={!values.name}
        onClick={handleOnDraftClick}
        onAfterSubmit={results =>
          handleSaveButtonClick({ variant: 'draft', id: results.id })
        }
      >
        Save as draft
      </NewSaveButtonWithPopup>
      <NewSaveButtonWithPopup
        hideWhenNoChanges={false}
        useValidator
        noPopup
        onClick={handleOnSaveClick}
        onAfterSubmit={results =>
          handleSaveButtonClick({ variant: 'next', id: results.id })
        }
      >
        {getNextButton()}
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
