import React from 'react'
import { Link, useParams } from 'react-router-dom'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import RatingLabel, {
  RatingLabelTypes,
} from '@src/features/EmployeePerformance/RatingLabel'
import Justification from '@components/ColumnInserts/Justification/Justification'
import { Box, Flex } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'

interface Props {
  data: SkillCardInterface | ValueBasedCardInterface
  type: PerformanceReviewTypes
  category: ReviewCategory
}

const CardRating = ({ data, type, category }: Props) => {
  const params = useParams()

  if (!data.rating) {
    return <div>N/A</div>
  }

  const isUpwards = category === ReviewCategory.Upwards

  let path = isUpwards
    ? ROUTES.FORMS.UPWARDS_REVIEW.SUMMARY
    : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SUMMARY
  switch (type) {
    case PerformanceReviewTypes.skills:
    case PerformanceReviewTypes.managerValuesSkills:
      if (category === ReviewCategory.Upwards) {
        path = ROUTES.FORMS.UPWARDS_REVIEW.SKILLS
      }
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE.SKILLS
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW.SKILLS
      }
      break
    case PerformanceReviewTypes.deliverables:
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES
      }
      break
    case PerformanceReviewTypes.cultureFit:
    case PerformanceReviewTypes.cultureValuesFit:
      if (category === ReviewCategory.Upwards) {
        path = ROUTES.FORMS.UPWARDS_REVIEW.CULTURE_FIT
      }
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE.CULTURE_FIT
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW.CULTURE_FIT
      }
      break
  }

  return (
    <Flex justifyContent="space-between" width="100%">
      <Flex>
        <Link
          to={{
            ...getLocationDescriptor(pathToUrl(path, params)),
            hash: `#${
              (data as SkillCardInterface)?.skill_id ||
              (data as SkillCardInterface)?.criteria_key ||
              (data as ValueBasedCardInterface)?.value_id ||
              ''
            }`,
          }}
        >
          <RatingLabel rating={data.rating} type={RatingLabelTypes.InverseUnderline} />
        </Link>
      </Flex>
      <Box width={20} ml="10px">
        <Justification data={data} />
      </Box>
    </Flex>
  )
}

export default CardRating
