import { FormPreview } from '@components/FormPreview/FormPreview'
import { Box, createChain, Group, Item, Subheader, VStack } from '@revolut/ui-kit'
import React from 'react'
import { ROUTES, PUBLIC_ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { CompanySettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Document, Info, LogoFacebook, SocialLike } from '@revolut/icons'
import {
  PRIVACY_POLICY,
  REVOLUT_FACEBOOK,
  REVOLUTERS_SERVICE_DESK,
} from '@src/constants/externalLinks'
import SettingsButtons, { EditButton } from '@src/features/SettingsButtons'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { CompanyDesign } from './CompanyDesign'
import { pathToUrl } from '@src/utils/router'
import { isWorkspacesEnabled } from '@src/utils'

const Preview = () => {
  const { values } = useLapeContext<CompanySettingsInterface>()
  const permissions = useSelector(selectPermissions)

  const canViewCompanySettings = permissions.includes(PermissionTypes.ViewCompanySettings)
  const canChangeCompanySettings = permissions.includes(
    PermissionTypes.ChangeCompanySettings,
  )

  const getWorkspaceMessage = () => {
    if (isWorkspacesEnabled()) {
      return `${document.location.origin}/${values.company_subdomain_key}`
    }

    return values.company_subdomain_key
      ? values.subdomain_template?.replace('{}', values.company_subdomain_key)
      : '-'
  }

  return (
    <PageBody>
      {canChangeCompanySettings ? (
        <SettingsButtons mb="s-16">
          <EditButton route={ROUTES.SETTINGS.GENERAL.EDIT} />
        </SettingsButtons>
      ) : null}

      <VStack space="s-16">
        {canViewCompanySettings && (
          <>
            <FormPreview data={values} title="Admin info">
              <Group>
                <FormPreview.Item
                  title="Owner"
                  field="point_of_contact"
                  type="employee"
                />
                <FormPreview.Item title="Email address" field="point_of_contact.email" />
              </Group>
            </FormPreview>
            <FormPreview data={values} title="Company info">
              <Group>
                <FormPreview.Item title="Company name" field="company_name" />
                <FormPreview.Item<CompanySettingsInterface>
                  title="Company address"
                  insert={data =>
                    createChain(<br />)(
                      [
                        data.address_line_1,
                        data.address_line_2,
                        data.address_line_3,
                        data.post_code,
                        data.city,
                        data.country?.name,
                      ].filter(Boolean),
                    )
                  }
                />
                <FormPreview.Item title="Industry" field="industry.name" />
                <FormPreview.Item title="Number of employees" field="company_size.name" />
                <FormPreview.Item<CompanySettingsInterface>
                  title="Company workspace"
                  insert={getWorkspaceMessage}
                />
                <FormPreview.Item title="VAT number" field="vat_number" />
              </Group>
            </FormPreview>
          </>
        )}

        <FormPreview title="Company design">
          <CompanyDesign />
        </FormPreview>

        <HideIfCommercial>
          <Box>
            <Subheader variant="nested">
              <Subheader.Title>About</Subheader.Title>
            </Subheader>
            <Group>
              <>
                <Item
                  use="a"
                  useIcon={SocialLike}
                  iconColor="grey-20"
                  href={REVOLUTERS_SERVICE_DESK}
                  target="_blank"
                >
                  <Item.Content>
                    <Item.Title>Help us to make it better</Item.Title>
                  </Item.Content>
                </Item>
                <Item
                  use="a"
                  useIcon={LogoFacebook}
                  iconColor="grey-20"
                  href={REVOLUT_FACEBOOK}
                  target="_blank"
                >
                  <Item.Content>
                    <Item.Title>Like us on Facebook</Item.Title>
                  </Item.Content>
                </Item>
              </>
              <Item
                use="a"
                useIcon={Info}
                iconColor="grey-20"
                href={PRIVACY_POLICY}
                target="_blank"
              >
                <Item.Content>
                  <Item.Title>Privacy policy</Item.Title>
                </Item.Content>
              </Item>
              <Item
                use="a"
                useIcon={Document}
                iconColor="grey-20"
                href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)}
                target="_blank"
              >
                <Item.Content>
                  <Item.Title>Terms &amp; Conditions</Item.Title>
                </Item.Content>
              </Item>
            </Group>
          </Box>
        </HideIfCommercial>
      </VStack>
    </PageBody>
  )
}

export default Preview
