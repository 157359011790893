import {
  Item,
  Avatar,
  HStack,
  ActionButton,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import React from 'react'
import { useTicketFileDelete } from '@src/api/tickets'
import { FileInterface } from '@src/interfaces/files'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { TicketFullInterface } from '@src/interfaces/tickets'

interface TicketFileProps {
  ticket: TicketFullInterface
  file: FileInterface
  onDelete: () => void
}

export const TicketFile = ({ ticket, file, onDelete }: TicketFileProps) => {
  const currentUser = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  const { mutateAsync: deleteFile, isLoading: isLoadingDelete } = useTicketFileDelete(
    ticket.id,
  )

  const handleDelete = async (fileId: number) => {
    try {
      await deleteFile(fileId)
      onDelete()
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to delete file</StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Document" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{file.name}</Item.Title>
      </Item.Content>
      <Item.Side>
        <HStack gap="s-6">
          {currentUser.user_uuid === ticket.employee_uuid ? (
            <ActionButton
              useIcon="Delete"
              aria-label="Delete file"
              variant="negative"
              onClick={() => handleDelete(file.id)}
              disabled={isLoadingDelete}
            />
          ) : null}
          <ActionButton
            useIcon="Download"
            aria-label="Download file"
            onClick={() => {
              window.open(`${file.url}`, '_blank')?.focus()
            }}
          />
        </HStack>
      </Item.Side>
    </Item>
  )
}
