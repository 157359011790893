import React from 'react'
import { Box, Flex } from '@revolut/ui-kit'
import useResizeObserver from 'use-resize-observer'
import { Stats } from '@src/interfaces/data'
import Table, { TableProps } from '@components/TableV2/Table'
import LegacyTable from '@components/Table/Table'
import { TableTypes } from '@src/interfaces/table'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import ErrorBoundary, { DefaultFallBack } from '@src/features/ErrorBoundary/ErrorBoundary'
import { useIsNewTable } from '@components/TableV2/hooks'

export interface AdjustableTableProps<T, S = Stats> extends TableProps<T, S> {}

const AdjustableTable = <
  T extends { [prop: string]: any; id?: number | string },
  S = Stats,
>(
  props: AdjustableTableProps<T, S>,
) => {
  const { width, ref: containerRef } = useResizeObserver()

  const isNewTable = useIsNewTable()
  const TableComponent = isNewTable ? Table : LegacyTable

  return (
    <ErrorBoundary
      fallback={
        <Box width="100%">
          <DefaultFallBack />
        </Box>
      }
    >
      <Flex
        minHeight={0}
        flexDirection="column"
        height="100%"
        width="100%"
        ref={containerRef}
      >
        {width && (
          <TableComponent<T, S>
            stickyHeaderTop={props.useWindowScroll ? mainHeaderState.height : undefined}
            type={TableTypes.Adjustable}
            {...props}
            width={width}
          />
        )}
      </Flex>
    </ErrorBoundary>
  )
}

export default AdjustableTable
