import React from 'react'
import { Header, Layout, VStack, Text, Image, Flex, Link } from '@revolut/ui-kit'
import { SUPPORT_REVOLUT_PEOPLE } from '@src/constants/emails'

export const AccountClosed = () => {
  return (
    <Layout>
      <Layout.Main>
        <Header variant="item">
          <Header.Title>Your account has been closed</Header.Title>
          <Header.Description>
            <VStack space="s-16">
              <Text>
                We hope you enjoyed your time with us, however your account has now been
                closed.
              </Text>
              <Text>
                If you feel that this was a mistake or you have other questions, feel free
                to contact us via our{' '}
                <Link href={`mailto:${SUPPORT_REVOLUT_PEOPLE}`}>support</Link>
              </Text>
            </VStack>
          </Header.Description>
        </Header>

        <Flex justifyContent="center">
          <Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images/3D009.png',
              '2x': 'https://assets.revolut.com/assets/3d-images/3D009@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images/3D009@3x.png',
            }}
            maxWidth={300}
          />
        </Flex>
      </Layout.Main>
    </Layout>
  )
}
