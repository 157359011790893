import { ROUTES } from '@src/constants/routes'
import { AnalyticsStatsInterface } from '@src/interfaces/analyticsDashboards'

export const analyticsConfig: {
  key: keyof AnalyticsStatsInterface
  label: string
  to: string
}[] = [
  {
    key: 'dashboards_count',
    label: 'Dashboards',
    to: ROUTES.PERFORMANCE.ANALYTICS.DASHBOARDS,
  },
  {
    key: 'queries_count',
    label: 'Queries',
    to: ROUTES.PERFORMANCE.ANALYTICS.QUERIES,
  },
]
