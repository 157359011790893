import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Bullet, Token } from '@revolut/ui-kit'

import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type EmployeeSettingsItemId =
  | 'employeeProfile'
  | 'employeeAttributes'
  | 'employeeCompensationSettings'
  | 'employeeLifecycleSettings'
  | 'employeeDataRetentionSettings'
  | 'employeeOnboardingSettings'
  | 'employeeLocations'
  | 'employeeEntities'
  | 'employeeGroups'

export const employeeSettingsLinkConfig: SettingsLinkItemConfig<EmployeeSettingsItemId>[] =
  [
    {
      id: 'employeeProfile',
      section: 'Employee settings',
      icon: 'Profile',
      title: 'Profile',
      to: ROUTES.SETTINGS.EMPLOYEES.PROFILE,
      subtitle: (
        <FormattedMessage
          id="settings.employees.profileSubtitle"
          defaultMessage="Settings related to the work and personal profiles of employees"
        />
      ),
      permissions: [PermissionTypes.ViewEmployeePreferences],
    },
    {
      id: 'employeeAttributes',
      section: 'Employee settings',
      icon: () => (
        <Bullet backgroundColor={Token.color.accentNeutral} variant="filled">
          C
        </Bullet>
      ),
      title: 'Employee attributes',
      to: ROUTES.SETTINGS.EMPLOYEES.ATTRIBUTES,
      subtitle: (
        <FormattedMessage
          id="settings.employees.attributesSubtitle"
          defaultMessage="Settings related to the custom fields functionality"
        />
      ),
      permissions: [PermissionTypes.ViewCustomField],
    },
    {
      id: 'employeeCompensationSettings',
      section: 'Employee settings',
      icon: 'CreditBag',
      title: 'Compensation settings',
      to: ROUTES.SETTINGS.COMPENSATION.GENERAL,
      subtitle: (
        <FormattedMessage
          id="settings.employees.compensationSubtitle"
          defaultMessage="Settings for the compensation functionalities"
        />
      ),
      permissions: [PermissionTypes.ViewTotalCompensationPreferences],
    },
    {
      id: 'employeeLifecycleSettings',
      section: 'Employee settings',
      icon: 'ArrowExchange',
      title: 'Lifecycle settings',
      to: ROUTES.SETTINGS.LIFECYCLE,
      subtitle: (
        <FormattedMessage
          id="settings.employees.lifecycleSubtitle"
          defaultMessage="Settings for onboarding and offboarding"
        />
      ),
      permissions: [PermissionTypes.ViewLifecycleSettings],
    },
    {
      id: 'employeeDataRetentionSettings',
      section: 'Employee settings',
      icon: 'Database',
      title: 'Data Retention Settings',
      to: ROUTES.SETTINGS.EMPLOYEES.DATA_RETENTION,
      subtitle: (
        <FormattedMessage
          id="settings.employees.dataRetentionSubtitle"
          defaultMessage="Configure Data Retention Policy for Employee's locations"
        />
      ),
      permissions: [PermissionTypes.ViewDataRetentionPeriod],
    },
    {
      id: 'employeeOnboardingSettings',
      section: 'Employee settings',
      icon: 'Form',
      title: 'Onboarding Settings',
      to: ROUTES.SETTINGS.ONBOARDING.GENERAL,
      subtitle: (
        <FormattedMessage
          id="settings.employees.onboardingSubtitle"
          defaultMessage="Settings for all onboarding functionalities"
        />
      ),
      permissions: [PermissionTypes.HRManagerPermissions],
      featureFlag: FeatureFlags.EmployeeOnboardingV2,
    },
    {
      id: 'employeeLocations',
      section: 'Employee settings',
      icon: 'LocationPin',
      title: 'Locations',
      to: ROUTES.APPS.LOCATIONS,
      subtitle: (
        <FormattedMessage
          id="settings.employees.locationsSubtitle"
          defaultMessage="Manage company locations"
        />
      ),
      permissions: [PermissionTypes.ViewLocationPreferences],
    },
    {
      id: 'employeeEntities',
      section: 'Employee settings',
      icon: 'Bank',
      title: 'Legal entities',
      to: ROUTES.APPS.ENTITIES,
      subtitle: (
        <FormattedMessage
          id="settings.employees.entitiesSubtitle"
          defaultMessage="Manage Legal entities"
        />
      ),
      permissions: [PermissionTypes.AddEntity],
    },
    {
      id: 'employeeEntities',
      section: 'Employee settings',
      icon: 'Bank',
      title: 'Legal entities',
      to: ROUTES.APPS.ENTITIES,
      subtitle: (
        <FormattedMessage
          id="settings.employees.entitiesSubtitle"
          defaultMessage="Manage Legal entities"
        />
      ),
      permissions: [PermissionTypes.AddEntity],
    },
    {
      id: 'employeeGroups',
      section: 'Employee settings',
      icon: 'People',
      title: 'Groups',
      to: ROUTES.APPS.GROUPS,
      permissions: [PermissionTypes.ViewDynamicGroups],
    },
  ]
