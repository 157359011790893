import React, { useState } from 'react'
import { closePayCycle, usePayCycle } from '@src/api/payroll'
import { PayCycleDetailedReportInterface } from '@src/interfaces/payroll'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import {
  Text,
  HStack,
  VStack,
  chain,
  Tag,
  Banner,
  Box,
  Avatar,
  Token,
} from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { PaycyclePreview } from '@components/PaycyclePreview/PaycyclePreview'
import { useParams } from 'react-router-dom'
import PageLoading from '@components/PageLoading/PageLoading'
import { formatDate } from '@src/utils/format'
import { getStatusColor } from '@components/CommonSC/General'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { Statuses } from '@src/interfaces'
import TimelineSidebar from './TimelineSidebar'
import FilesSidebar from './FilesSidebar'
import PaycyclePreviewLoading from '@src/components/PaycyclePreview/PaycyclePreviewLoading'
import { ReportsTableWidget } from './ReportsTableWidget'

const PayrollSubtitle = ({ data }: { data: PayCycleDetailedReportInterface }) => {
  if (data.is_joiner) {
    return <Text color="green">Joiner</Text>
  }

  if (data.is_leaver) {
    return <Text color="deep-grey">Leaver</Text>
  }

  return null
}

export const PayCycleReportsTable = () => {
  const { id } = useParams<{ id: string }>()

  const [selectedReportData, setSelectedReportData] =
    useState<PayCycleDetailedReportInterface | null>(null)
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [showClosePopup, setShowClosePopup] = useState(false)
  const [showTimelineSidebar, setShowTimelineSidebar] = useState(false)
  const [closePending, setClosePending] = useState(false)
  const [showFilesSidebar, setShowFilesSidebar] = useState(false)

  const { data: cycle, isLoading, refetch } = usePayCycle(id)

  const handleClosePayCycle = async () => {
    setClosePending(true)
    try {
      await closePayCycle(id)
      await refetch()
    } finally {
      setClosePending(false)
      setShowClosePopup(false)
    }
  }

  const isPending = cycle?.reports_status.id === 'pending'
  const isFailed = cycle?.reports_status.id === 'failed'

  if (isLoading || !cycle) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          <HStack align="center" gap="s-8">
            {cycle?.pay_group.name}{' '}
            <Tag useIcon="CalendarDate" color="blue">
              {formatDate(cycle?.start_date)} - {formatDate(cycle?.end_date)}
            </Tag>
          </HStack>
        }
        subtitle={
          <VStack gap="s-2">
            <Text fontWeight={500} color={Token.color.foreground}>
              {chain(
                'Pay cycle',
                <Text color={getStatusColor(cycle.status.id as Statuses)}>
                  {cycle.status.name}
                </Text>,
              )}
            </Text>
            {/* TODO: uncomment when implemented on BE */}
            {/* <Text type="small" color={Token.color.greyTone50}> */}
            {/*  Report last downloaded on · 0 changes since last download */}
            {/* </Text> */}
          </VStack>
        }
        backUrl={ROUTES.PEOPLE.PAYROLL}
        routerAction={PageHeaderRouterAction.navigate}
      />

      {isPending && (
        <Box>
          <Banner>
            <Banner.Avatar>
              <Avatar
                variant="brand"
                image={{
                  default: `https://assets.revolut.com/assets/3d-images/3D007.png`,
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D007@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D007@3x.png',
                }}
                size={56}
              />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>Reports are being generated</Banner.Title>
              <Banner.Description>
                The table below will be populated with reports in a few moments. Please
                check again later.
              </Banner.Description>
            </Banner.Content>
          </Banner>
        </Box>
      )}

      {isFailed && (
        <Box>
          <Banner>
            <Banner.Avatar>
              <Avatar
                variant="brand"
                image={{
                  default: `https://assets.revolut.com/assets/3d-images/3D018.png`,
                  '2x': 'https://assets.revolut.com/assets/3d-images/3D018@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images/3D018@3x.png',
                }}
                size={56}
              />
            </Banner.Avatar>
            <Banner.Content>
              <Banner.Title>Reports generation failed</Banner.Title>
              <Banner.Description>
                The error has been reported and we're working to solving it.
              </Banner.Description>
            </Banner.Content>
          </Banner>
        </Box>
      )}

      <ReportsTableWidget
        cycle={cycle}
        id={id}
        setSelectedReportData={setSelectedReportData}
        setShowClosePopup={setShowClosePopup}
        setShowTimelineSidebar={setShowTimelineSidebar}
        setSidebarOpen={setSidebarOpen}
      />

      <SideBar
        variant="wide"
        title={selectedReportData?.employee?.full_name}
        subtitle={!!selectedReportData && <PayrollSubtitle data={selectedReportData} />}
        onClose={() => setSidebarOpen(false)}
        isOpen={sidebarOpen}
      >
        {selectedReportData ? (
          <PaycyclePreview data={selectedReportData} showNewTabButton />
        ) : (
          <PaycyclePreviewLoading />
        )}
      </SideBar>
      <TimelineSidebar
        isOpen={showTimelineSidebar}
        onClose={() => setShowTimelineSidebar(false)}
        payCycleId={id}
      />
      <FilesSidebar
        isOpen={showFilesSidebar}
        onClose={() => setShowFilesSidebar(false)}
        payCycleId={id}
      />
      <ConfirmationDialog
        open={showClosePopup}
        onClose={() => setShowClosePopup(false)}
        onConfirm={handleClosePayCycle}
        onReject={() => setShowClosePopup(false)}
        label="Close this pay cycle?"
        body="Are you sure you want to close this pay cycle?"
        loading={closePending}
        submitDisabled={closePending}
        yesMessage="Close"
        noMessage="Cancel"
      />
    </PageWrapper>
  )
}
