import SideBar from '@components/SideBar/SideBar'
import React, { useEffect, useMemo, useState } from 'react'
import { Group, Token, Flex, Header, Text, Item, Avatar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { useGetRoadmapSettings } from '@src/api/settings'
import {
  DepartmentInterface,
  ExternalChannelItemInterface,
} from '@src/interfaces/deparment'
import { SectionOptions } from '@src/interfaces/customFields'
import {
  useUpdateDepartmentExternalChannels,
  useGetExternalChannels,
} from '@src/api/department'
import { ExternalChannelItem } from '@src/features/ExternalChannels/ExternalChannelItem'

import { useDepartmentSidebar } from '@src/pages/Department/Layout/useDepartmentSidebar'
import { parseSlackConnections } from '@src/features/ExternalChannels/mapper'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import LapeForm from '@src/features/Form/LapeForm'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import { ExternalChannelForm } from '@src/features/ExternalChannels/ExternalChannelForm'
import Icon from '@components/Icon/Icon'

interface DetailsSidebarProps {
  onClose: VoidFunction
  department: DepartmentInterface
  approvalFlow: React.ReactNode
  refetch: VoidFunction
}

export const DetailsSidebar = ({
  onClose,
  department,
  approvalFlow,
  refetch,
}: DetailsSidebarProps) => {
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const [channelEdit, setChannelEdit] = useState<ExternalChannelItemInterface>()
  const { sidebar, editMode, setSidebarQueryParam } = useDepartmentSidebar()
  const setEditMode = (id: number | undefined) =>
    setSidebarQueryParam('departmentDetails', true, id)
  const featureFlags = useSelector(selectFeatureFlags)

  const slackUserGroupEnabled = featureFlags?.includes(FeatureFlags.SlackUserGroupEnabled)
  const { data: externalChannels } = useGetExternalChannels({
    departmentId: department.id,
  })
  const canEdit = !!department.field_options.permissions?.includes(
    PermissionTypes.ChangeDepartment,
  )
  const parsedSlackData: ExternalChannelItemInterface[] = useMemo(
    () => parseSlackConnections(externalChannels),
    [externalChannels],
  )

  useEffect(() => {
    if (editMode) {
      const channel = parsedSlackData.find(item => item.id === Number(editMode))
      setChannelEdit(channel)
    }
  }, [parsedSlackData])

  const setEdit = (channel: ExternalChannelItemInterface | undefined) => {
    if (channel) {
      setChannelEdit({ ...channel })
      setEditMode(channel.id)
    } else {
      setChannelEdit(undefined)
      setEditMode(undefined)
    }
  }
  const { mutateAsync: updateConnection } = useUpdateDepartmentExternalChannels(
    channelEdit?.id,
  )
  return (
    <SideBar
      variant="wide"
      isOpen={sidebar === 'departmentDetails'}
      onClose={onClose}
      title="Department details"
      useIcon="Bank"
      avatarProps={{ color: Token.color.greyTone20 }}
      customHeader={
        channelEdit ? (
          <Header>
            <Header.BackButton
              color={Token.color.foreground}
              onClick={() => {
                setEdit(undefined)
              }}
            />
            <Header.Title data-testid="sidebar-title">
              <Text color={Token.color.foreground}>{channelEdit.name}</Text>
            </Header.Title>
          </Header>
        ) : undefined
      }
    >
      {editMode && channelEdit ? (
        <>
          <LapeForm<{ name: string; handle: string }>
            initialValues={channelEdit}
            onSubmit={form => {
              return updateConnection([undefined, form.values]).then(() => {
                refetch()
                return form.values
              })
            }}
          >
            <FormValidatorProvider>
              <ExternalChannelForm
                onCancel={() => {
                  setEdit(undefined)
                }}
              />
            </FormValidatorProvider>
          </LapeForm>
        </>
      ) : (
        <>
          {approvalFlow}
          <FormPreview<DepartmentInterface>
            data={department}
            title="General details"
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, { id: department.id }),
              )
            }
          >
            <Group>
              <FormPreview.Item<DepartmentInterface>
                field="name"
                title="Department name"
              />
              <FormPreview.Item<DepartmentInterface>
                field="owner"
                title="Owner"
                type="employee"
              />
              <FormPreview.Item<DepartmentInterface>
                field="kpi_committee"
                title="KPI committee"
                type="employee"
              />
              <FormPreview.Item<DepartmentInterface>
                field="performance_dashboard_link"
                title="Performance dashboard link"
                type="link"
              />
              <FormPreview.Details<DepartmentInterface>
                title="KPI champions"
                description="These people have increased permissions to help when editing and setting KPIs"
                insert={d =>
                  d.kpi_champions ? (
                    <Flex flexWrap="wrap">
                      {d.kpi_champions.map(kpiChamp => (
                        <UserWithAvatar
                          height="s-32"
                          mr="s-12"
                          key={kpiChamp.id}
                          {...kpiChamp}
                        />
                      ))}
                    </Flex>
                  ) : (
                    '-'
                  )
                }
              />
              <FormPreview.Details<DepartmentInterface> field="mission" title="Mission" />
              <CommunicationDetails
                jiraProjects={department.jira_projects}
                linkedGroups={department.linked_communication_groups}
                jiraEnabled={roadmapSettings?.jira_epics_enabled}
              />
              <FormPreview.CustomFields
                sectionId={SectionOptions.Departments}
                departmentId={department.id}
              />
            </Group>
          </FormPreview>

          {!!parsedSlackData.length && slackUserGroupEnabled && (
            <Group mt="s-16">
              <Item>
                <Item.Avatar>
                  <Avatar
                    useIcon={
                      <Icon type="SlackMono" size="tiny" color={Token.color.foreground} />
                    }
                  />
                </Item.Avatar>
                <Item.Content>
                  <Text variant="emphasis1"> Slack Integration</Text>
                </Item.Content>
              </Item>
              {parsedSlackData.map((channel, index) => (
                <ExternalChannelItem
                  channel={channel}
                  key={index}
                  canEdit={canEdit}
                  onChannelEdit={() => {
                    setEdit(channel)
                  }}
                />
              ))}
            </Group>
          )}
        </>
      )}
    </SideBar>
  )
}
