import React, { useState } from 'react'

import { LapeFormInterface } from '@src/features/Form/LapeForm'
import { ContractStatuses, EmployeeContractsInterface } from '@src/interfaces/employees'
import { CrossSmall } from '@revolut/icons'
import { MoreBar, StatusPopup, Text } from '@revolut/ui-kit'
import { cancelEmployeeContract } from '@src/api/employees'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useParams } from 'react-router-dom'
import { getPermissions } from '@src/pages/Forms/EmployeeContracts/Preview/getPermissions'

type Props = {
  form: LapeFormInterface<EmployeeContractsInterface>
  onStatusUpdate: (data: EmployeeContractsInterface) => void
}
export const Cancel = ({ form, onStatusUpdate }: Props) => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  const [isCancellingContract, setIsCancellingContract] = useState<boolean>(false)
  const [isCancelSubmitOpened, setIsCancelSubmitOpened] = useState<boolean>(false)
  const [successOpen, setSuccessOpen] = useState<boolean>(false)

  const { values } = form
  const permissions = getPermissions(values.field_options?.permissions)

  return (
    <>
      {values.contract_status.id !== ContractStatuses.Cancelled && (
        <MoreBar.Action
          disabled={!permissions.canCancel}
          onClick={() => setIsCancelSubmitOpened(true)}
          pending={isCancellingContract}
          variant="negative"
          useIcon={CrossSmall}
        >
          Cancel
        </MoreBar.Action>
      )}
      <ConfirmationDialog
        open={isCancelSubmitOpened}
        onClose={() => setIsCancelSubmitOpened(false)}
        label="Are you sure you want to cancel this contract?"
        body={
          <Text use="p" variant="caption" color="grey-tone-50">
            Contracts are automatically terminated by the system when employees are marked
            as terminated. You should only cancel a contract if it is considered to be
            void or null.
          </Text>
        }
        loading={isCancellingContract}
        onConfirm={async () => {
          setIsCancellingContract(true)
          try {
            await cancelEmployeeContract(Number(employeeId), Number(id))
            setSuccessOpen(true)
            // TODO: use returned data from cancel endpoint when fixed on backend
            onStatusUpdate({
              ...values,
              contract_status: { id: ContractStatuses.Cancelled, name: 'Cancelled' },
            })
          } finally {
            setIsCancellingContract(false)
            setIsCancelSubmitOpened(false)
          }
        }}
        onReject={() => setIsCancelSubmitOpened(false)}
        yesMessage="Confirm"
        noMessage="Go back"
      />
      <StatusPopup
        variant="success"
        open={successOpen}
        onClose={async () => {
          setSuccessOpen(false)
        }}
        // @ts-ignore this prop exists, TS definition is lying
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Contract was successfully cancelled</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
