import React from 'react'
import { InterviewScorecardTypeInterface } from '@src/interfaces/interviewScorecardTemplates'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type Props = {
  options?: InterviewScorecardTypeInterface[]
  value?: InterviewScorecardTypeInterface
  onChange: (val: InterviewScorecardTypeInterface | null) => void
}

const ScorecardTypeSelect = ({ options = [], onChange, value }: Props) => {
  return (
    <RadioSelectInput<InterviewScorecardTypeInterface>
      options={options.map(option => ({
        label: option.name,
        value: option,
      }))}
      value={value}
      onChange={onChange}
      searchable={false}
      label="Scorecard type"
    />
  )
}

export default ScorecardTypeSelect
