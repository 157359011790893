import React from 'react'
import { Token } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { ESignatureTemplateEditor } from '@src/features/ESignatureTemplateEditor'
import { PageBody } from '@src/components/Page/PageBody'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const PreviewTemplateInEditor = () => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()
  const isESignatureType = values.template_type.id === 'esignature'

  return (
    <>
      <PageHeader
        title={values.name}
        subtitle="Preview e-signature template configuration"
        backUrl={pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, {
          id: String(values.id),
        })}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody maxWidthMd={Token.breakpoint.lg}>
        <ESignatureTemplateEditor
          initPreview
          values={values}
          canShowSettings={isESignatureType}
        />
      </PageBody>
    </>
  )
}
