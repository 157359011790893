import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { GetRequestData, RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const linkedAccountRequests = (
  employeeId: number,
): RequestInterface<LinkedAccountsInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/accounts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`, data),
  putItem: async (data, id) =>
    api.put(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`, data),
  deleteItem: async id => api.delete(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`),
  postItem: async data => api.post(`${API.EMPLOYEES}/${employeeId}/accounts`, data),
})

export const linkedFormRequests: RequestInterfaceNew<LinkedAccountsInterface> = {
  get: async ({ id, employeeId }) => {
    return api.get(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`)
  },
  update: async (data, { employeeId, id }) => {
    return apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`, data)
  },
  submit: async (data, { employeeId }) => {
    return apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/accounts`, data)
  },
  delete: async ({ id }, { employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}/accounts/${id}`),
}

export const useEmployeeLinkedRevolutAccount = (employeeId?: string | number) =>
  useFetch<GetRequestData<LinkedAccountsInterface>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}/accounts` : null,
  )

export const archiveLinkedAccount = (
  employeeId: string | number,
  accountId: string | number,
) => api.post(`${API.EMPLOYEES}/${employeeId}/accounts/${accountId}/archive`)
