import React, { useCallback, useEffect, useState } from 'react'
import {
  BottomSheet,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
  Search,
  HStack,
  TextButton,
  Input,
  VStack,
  Item,
  Avatar,
  Group,
  ItemSkeleton,
  ErrorWidget,
} from '@revolut/ui-kit'
import { Search as SearchIcon } from '@revolut/icons'
import { connect } from 'lape'
import { debounce, kebabCase } from 'lodash'

import { times } from '@src/utils/times'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { tenantsRequests, useGetTenant } from '@src/api/tenants'
import { TenantInterface, TenantResponseInterface } from '@src/interfaces/tenants'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { signupState, removeSignupStateCookie, setSignupStateCookie } from '../common'
import { getKYBDetails, useKYBSearch } from '@src/api/signup'
import { Country } from '@src/interfaces/selectors'
import { UNITED_KINDGOM_COUNTRY_CODE } from '@src/constants/common'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useGetSelectors } from '@src/api/selectors'
import { getCurrentCountryCode } from '@src/utils/timezones'
import { validateWorkspace } from '@src/pages/Login/common'
import { isWorkspacesEnabled } from '@src/utils'

const createAccountPopup = (
  <StatusPopup variant="loading" preventUserClose>
    <StatusPopup.Title>Creating your account...</StatusPopup.Title>
    <StatusPopup.Description>Should be ready in a moment.</StatusPopup.Description>
  </StatusPopup>
)

const CreateCompanyForm = () => {
  const signupStateTenantId = signupState.cookie?.tenantId

  const { values, submit } = useLapeContext<TenantInterface>()
  const [isChanged, setIsChanged] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [isCompanySearchPopupOpen, setIsCompanySearchPopupOpen] = useState(false)
  const [companySearch, setCompanySearch] = useState('')
  const [companySearchFilter, setCompanySearchFilter] = useState('')
  const [hideManualFields, setHideManualFields] = useState(false)
  const [kybDetailsLoading, setKybDetailsLoading] = useState(false)
  const [workspace, setWorkspace] = useState<string>()
  const [tenantId, setTenantId] = useState<string | undefined>(signupStateTenantId)
  const statusPopup = useStatusPopup()
  const { data: countries } = useGetSelectors<Country>(selectorKeys.countries)
  const { data: tenant } = useGetTenant(tenantId)
  const [error, setError] = useState<string>()

  const setCompanySearchFilterDebounced = useCallback(
    debounce(setCompanySearchFilter, 500),
    [],
  )

  const kybSearch = useKYBSearch(companySearchFilter)

  useEffect(() => {
    if (!isChanged) {
      values.subdomain = kebabCase(values.company_name)
    }
  }, [isChanged, values.company_name, values.subdomain])

  useEffect(() => {
    validate()
  }, [values.subdomain])

  useEffect(() => {
    if (signupStateTenantId) {
      statusPopup.show(createAccountPopup)
    }
  }, [])

  useEffect(() => {
    const region = getCurrentCountryCode()
    const currentCountry = countries?.find(country => country.country_code === region)
    if (!region || values.country || !currentCountry) {
      return
    }

    values.country = currentCountry
  }, [countries])

  useEffect(() => {
    if (tenant?.state === 'active') {
      statusPopup.show(
        <StatusPopup
          variant="success"
          onClose={() => {
            removeSignupStateCookie()
            window.location.href = getTenantWorkspace(workspace)
          }}
        >
          <StatusPopup.Title>Welcome to Revolut People.</StatusPopup.Title>
          <StatusPopup.Description>
            Your company dashboard is ready
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }

    if (tenant?.state === 'setup_failed') {
      navigateToWaitingList()
    }
  }, [tenant?.state])

  const getTenantWorkspace = (domain?: string) => {
    const hostname = window.location.hostname
    const hostnameSanitized =
      hostname.indexOf('www.') === 0 ? hostname.replace('www.', '') : hostname
    const workspaceEnabled = isWorkspacesEnabled()

    const template = workspaceEnabled
      ? `https://${hostnameSanitized}/{}`
      : `https://{}.${hostnameSanitized}`
    return template.replace('{}', domain || values.subdomain || 'your-workspace')
  }

  const validate = () => {
    if (!values.subdomain) {
      setError(undefined)
      return
    }

    const validationResult = validateWorkspace(values.subdomain)
    !validationResult.success
      ? setError(validationResult.error.issues[0]?.message)
      : setError(undefined)
  }

  const handleSubmit = () => {
    setIsPending(true)
    setWorkspace(values.subdomain)

    return submit().finally(() => setIsPending(false))
  }

  const handleResponse = (res: TenantResponseInterface) => {
    if (res.state === 'waiting_list') {
      navigateToWaitingList()
      return
    }

    statusPopup.show(createAccountPopup)

    setSignupStateCookie('company_details', undefined, res.id)
    setTenantId(res.id)
  }

  const navigateToWaitingList = () => {
    setSignupStateCookie('waiting_list')
    navigateTo(ROUTES.SIGNUP.WAITING_LIST)
  }

  if (tenantId) {
    return null
  }

  const isUKSelected = values.country?.country_code === UNITED_KINDGOM_COUNTRY_CODE

  return (
    <>
      <Header variant="main" pt="s-72">
        <Header.Title>Tell us about your business</Header.Title>
        <Header.Description>Now let’s get to know your business</Header.Description>
      </Header>
      <InputGroup>
        <LapeRadioSelectInput<Country>
          name="country"
          label="Country"
          selector={selectorKeys.countries}
          useQuery
          onAfterChange={value => {
            setHideManualFields(value?.country_code === UNITED_KINDGOM_COUNTRY_CODE)
            values.company_name = ''
            values.company_industry = null
            values.company_size = null
            values.subdomain = ''
          }}
        />
        {isUKSelected ? (
          <Input
            label="Search business legal name"
            useIcon={SearchIcon}
            type="button"
            onClick={() => setIsCompanySearchPopupOpen(true)}
            disabled={kybDetailsLoading}
            pending={kybDetailsLoading}
          />
        ) : null}
        {hideManualFields ? null : (
          <>
            <LapeNewInput name="company_name" label="Company name" required />
            <LapeRadioSelectInput
              name="company_size"
              label="Number of employees"
              selector={selectorKeys.company_sizes}
            />
            <LapeRadioSelectInput
              name="company_industry"
              label="Company industry"
              selector={selectorKeys.company_industries}
            />
            <LapeNewInput
              name="subdomain"
              label="Your company workspace"
              /** If the `message` is a string, the inputs automatically uppercase the first letter, so we pass a react element instead */
              message={<>{getTenantWorkspace()}</>}
              type="url"
              onAfterChange={() => setIsChanged(true)}
              hasError={!!error}
              errorMessage={error}
              required
            />
          </>
        )}
      </InputGroup>

      <PageActions>
        <NewSaveButtonWithPopup<TenantResponseInterface>
          useValidator
          onClick={handleSubmit}
          onAfterSubmit={handleResponse}
          noPopup
          pending={isPending}
          disabled={hideManualFields}
        >
          Complete Signup
        </NewSaveButtonWithPopup>
      </PageActions>

      <BottomSheet
        open={isCompanySearchPopupOpen}
        shouldKeepMaxHeight
        onClose={() => setIsCompanySearchPopupOpen(false)}
      >
        <VStack space="s-16">
          <HStack space="s-16" align="center">
            <Search
              value={companySearch}
              placeholder="Search"
              onChange={value => {
                setCompanySearchFilterDebounced(value)
                setCompanySearch(value)
              }}
            />

            <TextButton
              onClick={() => {
                setCompanySearch('')
                setCompanySearchFilterDebounced('')
                setIsCompanySearchPopupOpen(false)
              }}
              fontWeight="500"
            >
              Cancel
            </TextButton>
          </HStack>

          {kybSearch.error ? (
            <ErrorWidget>
              <ErrorWidget.Image />
              <ErrorWidget.Title />
              <ErrorWidget.Description>
                {getStringMessageFromError(kybSearch.error)}
              </ErrorWidget.Description>
            </ErrorWidget>
          ) : null}

          {companySearch.length === 0 ||
          (!kybSearch.data && companySearch.length && !kybSearch.isFetching) ? (
            <Item>
              <Item.Avatar>
                <Avatar useIcon={SearchIcon} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Search results will appear here</Item.Title>
              </Item.Content>
            </Item>
          ) : (
            (() => {
              if (kybSearch.isFetching) {
                return (
                  <Group>
                    {times(
                      index => (
                        <ItemSkeleton key={index}>
                          <ItemSkeleton.Content>
                            <ItemSkeleton.Title />
                            <ItemSkeleton.Description />
                          </ItemSkeleton.Content>
                          <ItemSkeleton.Side />
                        </ItemSkeleton>
                      ),
                      10,
                    )}
                  </Group>
                )
              }

              return (
                <>
                  <TextButton
                    onClick={() => {
                      setIsCompanySearchPopupOpen(false)
                      setHideManualFields(false)
                      setCompanySearch('')
                    }}
                    fontWeight="500"
                  >
                    Can't find my business
                  </TextButton>

                  {kybSearch.data?.length === 0 ? (
                    <Item>
                      <Item.Avatar>
                        <Avatar useIcon={SearchIcon} />
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>No results found</Item.Title>
                      </Item.Content>
                    </Item>
                  ) : null}

                  <Group>
                    {kybSearch.data?.map(item => (
                      <Item
                        onClick={() => {
                          setKybDetailsLoading(true)
                          setIsCompanySearchPopupOpen(false)
                          getKYBDetails(item.registration_number)
                            .then(res => {
                              values.company_name = res.data.company_name
                              values.company_industry = res.data.industry
                              setHideManualFields(false)
                            })
                            .finally(() => setKybDetailsLoading(false))
                        }}
                        use="button"
                        key={item.registration_number}
                      >
                        <Item.Content>
                          <Item.Title>{item.title}</Item.Title>
                          <Item.Description>{item.registration_number}</Item.Description>
                        </Item.Content>
                      </Item>
                    ))}
                  </Group>
                </>
              )
            })()
          )}
        </VStack>
      </BottomSheet>
    </>
  )
}

const CreateCompany = connect(() => (
  <Form api={tenantsRequests} disableLocalStorageCaching>
    <CreateCompanyForm />
  </Form>
))

export default CreateCompany
