import React from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, MoreBar, StatusWidget, Token } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeContracts, getEmployeeContractsStats } from '@src/api/employees'
import {
  contractAnnualSalaryColumn,
  contractEndAtColumn,
  contractEntityColumn,
  contractLocationColumn,
  contractRoleColumn,
  contractSeniorityColumn,
  contractStartAtColumn,
  contractStatusColumn,
  contractTermColumn,
  contractTypeColumn,
} from '@src/constants/columns/contracts'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import {
  ContractStatuses,
  EmployeeContractsInterface,
  EmployeeContractsStatsInterface,
  EmployeeInterface,
} from '@src/interfaces/employees'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

interface Props {
  data: EmployeeInterface
}

const ROW: RowInterface<EmployeeContractsInterface> = {
  highlight: data => {
    if (data.contract_status.id === ContractStatuses.Expired) {
      return Token.color.greyTone2
    }
    return ''
  },
  cells: [
    {
      ...contractRoleColumn,
      width: 200,
    },
    {
      ...contractSeniorityColumn,
      width: 200,
    },
    {
      ...contractTypeColumn,
      width: 200,
    },
    {
      ...contractLocationColumn,
      width: 200,
    },
    {
      ...contractEntityColumn,
      width: 200,
    },
    {
      ...contractTermColumn,
      width: 200,
    },
    {
      ...contractAnnualSalaryColumn,
      width: 200,
    },
    {
      ...contractStartAtColumn,
      width: 200,
    },
    {
      ...contractEndAtColumn,
      width: 200,
    },
    {
      ...contractStatusColumn,
      width: 200,
    },
  ],
}

export const ContractsTable = ({ data }: Props) => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeContract)

  const table = useTable<EmployeeContractsInterface, EmployeeContractsStatsInterface>(
    {
      getItems: getEmployeeContracts(data.id),
      getStats: getEmployeeContractsStats(data.id),
    },
    undefined,
    [
      {
        sortBy: 'seniority',
        direction: SORT_DIRECTION.ASC,
      },
    ],
  )

  const navigateToCreateNew = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId: data.id }))
  }

  const handleRowClick = (contract: EmployeeContractsInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
        employeeId: data.id,
        id: contract.id,
      }),
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Total" val={table.stats?.total} />
        <Stat
          color={Token.color.success}
          label="Active"
          val={table.stats?.active}
          ml="s-32"
        />
        <Stat
          color={Token.color.warning}
          label="Pending"
          val={table.stats?.pending}
          ml="s-32"
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {!!table.stats?.total && canAdd && (
            <MoreBar.Action useIcon="Plus" onClick={navigateToCreateNew}>
              Add new contract
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<EmployeeContractsInterface, EmployeeContractsStatsInterface>
          name={TableNames.EmployeeContracts}
          hideCount
          useWindowScroll
          dataType="Contract"
          row={ROW}
          {...table}
          onRowClick={handleRowClick}
          emptyState={
            <StatusWidget>
              <StatusWidget.Image
                src="https://assets.revolut.com/assets/3d-images/3D109.png"
                srcSet="https://assets.revolut.com/assets/3d-images/3D109@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D109@3x.png 3x"
              />
              <StatusWidget.Title>
                No contracts history for this employee yet
              </StatusWidget.Title>
              <StatusWidget.Description>
                Once you add the new contract, details will be shown here.
              </StatusWidget.Description>
              <StatusWidget.Action>
                <ActionButton mt="s-16" useIcon="Plus" onClick={navigateToCreateNew}>
                  Add new contract
                </ActionButton>
              </StatusWidget.Action>
            </StatusWidget>
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
