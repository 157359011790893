import React from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { FunctionInterface } from '@src/interfaces/functions'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { OrderingDataType } from '@src/interfaces/ordering'
import { useIsNewTable } from '@components/TableV2/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'

const row = (isNewTable?: boolean): RowInterface<GenericAnalyticsDashboardInterface> => ({
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
})

export const AnalyticsDashboards = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const isNewTable = useIsNewTable()
  const initialFilter = [
    {
      filters: [
        {
          name: OrderingDataType.Function,
          id: OrderingDataType.Function,
        },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      data={values}
      entity={EntityTypes.function}
      row={row(isNewTable)}
      initialFilter={initialFilter}
      initialSort={initialSortBy}
    />
  )
}
