import React from 'react'
import Form from '@src/features/Form/Form'

import { addDocumentRequest } from '@src/api/hiringProcess'
import CandidateAddDocument from '@src/pages/Forms/CandidateAddDocument/CandidateAddDocument'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'

const CandidateAddDocumentIndex = () => {
  const { id } = useParams<{ id: string }>()

  return (
    <PageWrapper>
      <PageHeader
        title="Add new document"
        backUrl={pathToUrl(ROUTES.FORMS.CANDIDATE.DOCUMENTS, { id })}
      />
      <Form api={addDocumentRequest} isExistingForm={false}>
        <CandidateAddDocument />
      </Form>
    </PageWrapper>
  )
}

export default CandidateAddDocumentIndex
