import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { General } from './General'

export const KeyPersonsSettings = () => {
  useSetDocumentTitle('Settings', 'Key Persons')

  return (
    <PageWrapper>
      <Switch>
        <Route path={ROUTES.SETTINGS.KEY_PERSONS} exact>
          <General />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
