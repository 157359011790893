import React, { useContext, useEffect, useMemo, useState } from 'react'
import {
  Action,
  Box,
  chain,
  Subheader,
  DetailsCellSkeleton,
  Group,
  Widget,
} from '@revolut/ui-kit'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'
import { getAvailableInterviewSlots } from '@src/api/recruitment/interviewerScheduling'
import DayTimeAvatar from '@src/pages/Forms/Candidate/ScheduleSidebar/DayTimeAvatar'
import { Grid } from '@components/CommonSC/Grid'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import pluralize from 'pluralize'
import isNumber from 'lodash/isNumber'
import { ExtensionApiHandlerContext } from '@src/utils/extension'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { getTimeZoneId } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'

type Props = {
  roundId: number
  selectedSlots?: AvailableInterviewerSlot[]
  onBack?: () => void
  label: string
  interviewId?: number
}

const MAX_SHOWN_SLOTS = 50

const PreviewSlots = ({ label, roundId, onBack, selectedSlots, interviewId }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const [loadingSlots, setLoadingSlots] = useState(false)
  const [slots, setSlots] = useState<AvailableInterviewerSlot[]>(selectedSlots || [])
  const [totalCount, setTotalCount] = useState<number | null>(
    selectedSlots ? selectedSlots.length : null,
  )
  const [showAll, setShowAll] = useState(false)

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)

  const groupedSlots = useGroupByDaySlots(timeZoneId, slots)

  const normalizedSlots = useMemo(() => {
    if (showAll) {
      return groupedSlots
    }

    /*
     * groupedSlots is an array of days. Each day contains "items" field which is a list of actual slots.
     * Here we cut days to show only those that have around MAX_SHOWN_SLOTS slots in total.
     * It could be more than MAX_SHOWN_SLOTS in total, but it's fine, we don't need to hide extra slots.
     * */
    let count = 0
    for (let i = 0; i < groupedSlots.length; ++i) {
      if (count > MAX_SHOWN_SLOTS) {
        return groupedSlots.slice(0, i)
      }

      count += groupedSlots[i].items.length
    }

    return groupedSlots
  }, [showAll, groupedSlots])

  useEffect(() => {
    setLoadingSlots(false)
    setSlots(selectedSlots || [])
    setTotalCount(selectedSlots ? selectedSlots.length : null)

    // because we use state values inside onLoadMore we should directly pass the arguments, as state not updated yet there
    onLoadMore(selectedSlots || [], selectedSlots ? selectedSlots.length : null, false)
  }, [interviewId, selectedSlots])

  const onLoadMore = async (
    scopedSlots = slots,
    scopedTotalCount = totalCount,
    scopedLoadingSlots = loadingSlots,
  ) => {
    if (selectedSlots || scopedSlots.length === scopedTotalCount || scopedLoadingSlots) {
      return
    }

    setLoadingSlots(true)

    try {
      const resp = await getAvailableInterviewSlots({
        stageId: values.interview_stage?.id,
        roundId,
        onlySentSlots: true,
        interviewId,
        apiHandler,
      })

      setTotalCount(resp.data.count)
      setSlots([...scopedSlots, ...resp.data.results])
    } finally {
      setLoadingSlots(false)
    }
  }

  const countLabel = isNumber(totalCount)
    ? pluralize('slot', totalCount, true)
    : undefined

  return (
    <Box>
      <Subheader variant="nested">
        <Subheader.Title>{chain(label, countLabel)}</Subheader.Title>
        {onBack && (
          <Subheader.Side>
            <Action onClick={onBack}>Edit</Action>
          </Subheader.Side>
        )}
      </Subheader>
      {(loadingSlots || !!totalCount) && (
        <Widget p="s-16">
          <Grid flow="row" gap={32} width="100%">
            {normalizedSlots.map(day => (
              <DayTimeAvatar
                key={day.id}
                day={day.label}
                items={day.items}
                timeZone={timeZoneId}
              />
            ))}
            {loadingSlots && (
              <Group aria-label="Preview slots skeleton">
                <DetailsCellSkeleton p={0} />
                <DetailsCellSkeleton p={0} />
                <DetailsCellSkeleton p={0} />
              </Group>
            )}
          </Grid>
          {!showAll && !!totalCount && totalCount > MAX_SHOWN_SLOTS && (
            <Box mt="s-16">
              <Action onClick={() => setShowAll(true)}>Show all {countLabel}</Action>
            </Box>
          )}
        </Widget>
      )}
    </Box>
  )
}

export default PreviewSlots
