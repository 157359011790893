import React, { useState } from 'react'
import { VStack } from '@revolut/ui-kit'
import {
  BooleanRadioSwitch,
  RecommendedLabelType,
} from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import pluralize from 'pluralize'
import OnboardingActions from '@src/pages/OnboardingChecklist/components/OnboardingActions'
import { OnboardingChecklistSectionConfig } from '@src/pages/OnboardingChecklist/common/constants'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { PageBody } from '@src/components/Page/PageBody'
import { BulkDataImportUploadFile } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import { ImportDataCategory } from '@src/interfaces/bulkDataImport'

export type ImportStepProps = {
  category: ImportDataCategory
  config: OnboardingChecklistSectionConfig
  currentStep: string
  apiEndpoint: string
  name: string
  nextRoute: string
  previousRoute: string
  onBeforeSubmit?: () => Promise<void>
  title?: string
  subtitle?: string
  recommendedLabel?: RecommendedLabelType
  yesByDefault?: boolean
}

const ImportStep = ({
  category,
  config,
  currentStep,
  apiEndpoint,
  name,
  nextRoute,
  previousRoute,
  onBeforeSubmit,
  title,
  subtitle,
  recommendedLabel,
  yesByDefault,
}: ImportStepProps) => {
  const [upload, setUpload] = useState(yesByDefault || false)

  const importName = pluralize(name)

  return (
    <>
      <PageBody>
        <VStack gap="s-16" pb="s-16">
          <SectionTitle
            title={title || `Do you want to bulk upload your ${importName}?`}
            subtitle={subtitle}
          />
          <BooleanRadioSwitch
            variant="horizontal"
            value={upload}
            onChange={setUpload}
            yesDescription={`I want to upload my ${importName} data using CSV/XLSX files import`}
            noDescription={`I will add my ${importName} to the system manually, one by one`}
            recommendedLabel={recommendedLabel}
          />
          {upload && (
            <BulkDataImportUploadFile
              category={category}
              apiEndpoint={apiEndpoint}
              name={name}
              nextRoute={nextRoute}
            />
          )}
        </VStack>
      </PageBody>
      <OnboardingActions
        onBeforeSubmit={onBeforeSubmit}
        config={config}
        currentStep={currentStep}
        isLastStep={!upload}
        disableBack={false}
        pendingBack={false}
        disableNext={upload}
        pendingNext={false}
        nextRoute={nextRoute}
        previousRoute={previousRoute}
        updateSteps={!upload}
        isForm={false}
      />
    </>
  )
}

export default ImportStep
