import React from 'react'
import RadioSwitch from '../components/RadioSwitch'
import { VStack, Widget } from '@revolut/ui-kit'
import SectionTitle from '../components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { GradeCalculationMethods } from '@src/interfaces/reviewCycles'
import { RecommendedLabel } from '../components/Recommended'

export const PerformanceGradeCalculation = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const simpleRecommendation = !(values.enable_skill_assessment && values.enable_values)
  const calculationMethods = [
    {
      id: 'line_manager_suggestion',
      label: simpleRecommendation ? (
        <RecommendedLabel label="Grade recommendation from the line manager" />
      ) : (
        'Grade recommendation from the line manager'
      ),
      description: 'From the line manager’s scorecard feedback',
      value: GradeCalculationMethods.LineManagerSuggestion,
    },
    {
      id: 'functional_manager_suggestion',
      label: 'Grade recommendation from the functional manager',
      description: 'From the functional manager’s scorecard feedback',
      value: GradeCalculationMethods.FunctionalManagerSuggestion,
    },
    {
      id: GradeCalculationMethods.PerformanceGradeSuggestion,
      label: simpleRecommendation ? (
        'A comparison between the competency matrix expectations and how the managers rated in their assessments'
      ) : (
        <RecommendedLabel label="A comparison between the competency matrix expectations and how the managers rated in their assessments" />
      ),
      description:
        'Calculated by comparing performance ratings from the manager scorecard assessments against the competency matrix for the employee',
      value: GradeCalculationMethods.PerformanceGradeSuggestion,
    },
    {
      id: GradeCalculationMethods.CalculatedGrade,
      label: 'An average of the recommended and calculated comparison grades',
      description:
        'The product of averaging the grades recommended by managers and the calculated comparison',
      value: GradeCalculationMethods.CalculatedGrade,
    },
  ]
  const setMethod = (method: GradeCalculationMethods) => {
    values.grade_calculation_method = method
  }
  return (
    <VStack>
      <SectionTitle
        title="How should the final performance grade be determined?"
        subtitle="The result of each performance review is a grade, alongside the feedback and scorecard. This can be adjusted in calibration."
      />
      <Widget>
        <RadioSwitch<GradeCalculationMethods>
          onChange={setMethod}
          value={values.grade_calculation_method}
          options={calculationMethods}
        />
      </Widget>
    </VStack>
  )
}
