import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { WhitelistRecordInterface } from '@src/interfaces/supportTool'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const whitelistRequests: RequestInterfaceNew<WhitelistRecordInterface> = {
  get: async ({ id }) => api.get(`${API.SUPPORT_TOOL}/whitelist/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    api.patch(`${API.SUPPORT_TOOL}/whitelist/${id}`, data, undefined, 'v2'),
  submit: async data => api.post(`${API.SUPPORT_TOOL}/whitelist`, data, undefined, 'v2'),
}

export const getWhitelist =
  () =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<WhitelistRecordInterface>
  > =>
    api.get(
      `${API.SUPPORT_TOOL}/whitelist`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    )

export const revokeWhitelist = (id: number) =>
  api.post(`${API.SUPPORT_TOOL}/whitelist/${id}/revoke`, undefined, undefined, 'v2')
