import { VStack, Box, Cell } from '@revolut/ui-kit'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Subtab, HomeSectionId } from '@src/interfaces/tabPinning'
import { PipReviews } from '@src/pages/Performance/Reviews/PipReviews'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { ProbationReviews } from './ProbationReviews'
import { Reviews } from './Reviews'
import { GlobalSettings } from '@src/store/auth/types'
import { useGlobalSettings } from '@src/api/settings'
import { useIsNewTable } from '@src/components/TableV2/hooks'

const reviewsTabs: Subtab[] = [
  {
    id: HomeSectionId.performance,
    title: 'Performance',
    path: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
    to: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
    component: Reviews,
  },
  {
    id: HomeSectionId.reviewsProbation,
    title: 'Probation',
    path: ROUTES.PERFORMANCE.REVIEWS.PROBATION,
    to: ROUTES.PERFORMANCE.REVIEWS.PROBATION,
    globalSetting: GlobalSettings.ProbationEnabled,
    component: ProbationReviews,
  },
  {
    id: HomeSectionId.reviewsPip,
    title: 'PIP',
    path: ROUTES.PERFORMANCE.REVIEWS.PIP,
    to: ROUTES.PERFORMANCE.REVIEWS.PIP,
    globalSetting: GlobalSettings.PipEnabled,
    component: PipReviews,
  },
]

export const ReviewsBase = () => {
  const globalSettings = useGlobalSettings()
  const filteredTabs = reviewsTabs.filter(tab => {
    return tab.globalSetting ? !!globalSettings?.settings?.[tab.globalSetting] : true
  })
  const isNewTable = useIsNewTable()

  const WrapperComponent = isNewTable ? React.Fragment : Cell

  return (
    <OnboardingAppScreen category="reviews">
      <WrapperComponent px={0} pb={0} pt={filteredTabs.length > 1 ? 's-16' : '-s-16'}>
        <VStack width="100%">
          {!isNewTable && filteredTabs.length > 1 && (
            <Box px="s-16" pb="s-8">
              <TabBarNavigation tabs={filteredTabs} isSubtab />
            </Box>
          )}
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.to}>
                <tab.component />
              </Route>
            ))}
            <InternalRedirect to={filteredTabs[0].to} />
          </Switch>
        </VStack>
      </WrapperComponent>
    </OnboardingAppScreen>
  )
}
