import { Text, VStack, chain, Link } from '@revolut/ui-kit'
import { paycycleRequests, usePayCycle } from '@src/api/payroll'
import { getStatusColor } from '@src/components/CommonSC/General'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { PayCycleInterface, PayCyclePreviewInterface } from '@src/interfaces/payroll'
import { formatPeriod } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { connect } from 'lape'
import React from 'react'
import { useParams } from 'react-router-dom'

type Step = {
  id: keyof PayCyclePreviewInterface
  name: string
}

export const TIMELINE_STEPS: Step[] = [
  { id: 'start_date', name: 'Pay Cycle Starts' },
  { id: 'pay_period_second_start_date', name: 'Second Start Date' },
  { id: 'end_date', name: 'End Date' },
  { id: 'cut_off_date', name: 'Cut Off Date' },
  { id: 'submission_date', name: 'Submission Date' },
  {
    id: 'pay_authorities_date',
    name: 'Pay Authorities Date',
  },
  { id: 'pay_date', name: 'Pay Date' },
  {
    id: 'pay_date_second_date',
    name: 'Pay Date Second Date',
  },
]

const TimelineEdit = () => {
  const { id } = useParams<{ id: string }>()
  useLapeContext<PayCycleInterface>()
  const { data: cycle, isLoading } = usePayCycle(id)

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Edit pay schedule"
        subtitle={
          !!cycle &&
          chain(
            formatPeriod(cycle.start_date, cycle.end_date),
            <Text color={getStatusColor(cycle.status.id as Statuses)}>
              {cycle.status.name}
            </Text>,
          )
        }
        backUrl={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id })}
      />
      <PageBody>
        <VStack gap="s-8">
          <Text mb="s-8">
            Here you can adjust the dates for the given pay cycle. To change pay schedule
            permanently, you need to edit it in the{' '}
            <Link
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT, {
                id: cycle?.pay_group.id,
              })}
            >
              pay group settings
            </Link>
            .
          </Text>
          {TIMELINE_STEPS.map(step => (
            <LapeDatePickerInput
              key={step.id}
              name={step.id}
              label={step.name}
              hideOptional
            />
          ))}
          <PageActions>
            <NewSaveButtonWithPopup
              useValidator
              previewUrl={ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS}
            />
          </PageActions>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={paycycleRequests}>
    <TimelineEdit />
  </Form>
))
