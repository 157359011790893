import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import { Flex, Side } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import {
  QuestionMainInputs,
  QuestionSettingsInputs,
} from '@src/apps/People/Engagement/Question/General'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { engagementQuestionFormRequests } from '@src/api/engagement'
import { selectUser } from '@src/store/auth/selectors'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { SidebarTabs } from './types'

type Props = {
  onAddQuestion: (q: EngagementQuestionInterface, closeSidebar?: boolean) => void
  setCurrentTab: React.Dispatch<React.SetStateAction<SidebarTabs | undefined>>
}
const FormContent = ({ onAddQuestion, setCurrentTab }: Props) => {
  const { values } = useLapeContext<EngagementQuestionInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  return (
    <Flex flexDirection="column" gap="s-16">
      <QuestionMainInputs insideSidebarPortal />
      <QuestionSettingsInputs insideSidebarPortal />
      <Side.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={({ id }) => {
            if (id) {
              onAddQuestion({ ...values, id: Number(id) }, false)
              setCurrentTab(SidebarTabs.QuestionLibrary)
            }
          }}
          data-testid="save-sidebar"
        >
          Save
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </Flex>
  )
}

export const CreateNewQuestionTab = connect((props: Props) => (
  <Form api={engagementQuestionFormRequests} forceParams={{ id: undefined }}>
    <FormContent {...props} />
  </Form>
))
