import React from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { ROUTES } from '@src/constants/routes'
import { employeesConfig } from '../common/constants'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { row } from '@src/pages/Forms/ImportData/Employees/EmployeesSession'
import { fields, useOptions } from '@src/pages/Forms/ImportData/Employees/config'

export const EmployeesBulkUpload = () => {
  const options = useOptions()

  return (
    <OnboardingBulkDataUpload
      importRoute={ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.UPLOAD.IMPORT}
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.INTRO}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.ANY}
      apiEndpoint={API.EMPLOYEE_UPLOADS}
      config={employeesConfig}
      currentStep="Upload employees"
      category="employee_upload"
      name="employee"
      row={row(options)}
      tableName={TableNames.ImportEmployees}
      fields={fields}
      recommendedLabel="yes"
      yesByDefault
    />
  )
}
