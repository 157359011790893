import { Banner, ItemSkeleton, MoreBar, Spacer, VStack } from '@revolut/ui-kit'
import React, { useEffect, useMemo } from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PreviewSetupBanner } from './ScorecardSetup/PreviewSetupBanner'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { previewState } from './ScorecardSetup/previewState'
import { useGetPerformancePreview } from '@src/api/performanceReview'
import { ReviewCategory, ReviewScorecardInterface } from '@src/interfaces/performance'
import { Statuses } from '@src/interfaces'
import { ScorecardPreview } from './ScorecardSetup/ScorecardPreview'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { useGetSelectors } from '@src/api/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { selectorKeys } from '@src/constants/api'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

interface Props {
  readonly cycle?: ReviewCyclesInterface
}

export const Scorecard: React.FC<Props> = ({ cycle }) => {
  const user = useSelector(selectUser)
  const { gradesMap } = useGetReviewGradesMap()
  const { data: specialisations } = useGetSelectors<SpecialisationInterface>(
    selectorKeys.approved_pending_specialisations,
  )
  const { data: performanceSettings } = useGetPerformanceSettings()

  useEffect(() => {
    if (
      user &&
      specialisations &&
      user.specialisation &&
      user.specialisation.id !== previewState.role?.id
    ) {
      const roleId = specialisations?.find(
        item => item.id === user.specialisation?.id,
      )?.role_id
      previewState.role = {
        id: user.specialisation.id,
        name: user.specialisation.name,
        role_id: roleId,
      }
    }
    if (user && user.seniority && user.seniority.id !== previewState.seniority?.id) {
      previewState.seniority = { id: user.seniority.id, name: user.seniority.name }
    }
  }, [user, specialisations])

  const { data: preview } = useGetPerformancePreview(
    user && previewState.role && previewState.seniority
      ? {
          employee: String(user.id),
          specialisation: String(previewState.role.id),
          seniority: String(previewState.seniority.id),
          category: previewState.category.id,
          reviewed_employee_type: previewState.contributorType.id,
          reviewer_relation: previewState.seenBy.id,
        }
      : undefined,
  )

  const { scorecard, renderKey } = useMemo<{
    scorecard: ReviewScorecardInterface | null
    renderKey: number | null
  }>(
    () =>
      preview
        ? {
            scorecard: {
              id: 0, // hardcoded contract with BE
              review_data: preview,
              reviewed_employee: user,
              reviewer: user,
              status: Statuses.pending,
              category: previewState.category.id,
              reviewer_relation: previewState.seenBy?.id,
              isManagerReview: previewState.category.id === ReviewCategory.Upwards,
              reviewed_employee_type: previewState.contributorType,
              opened_date_time: new Date().toISOString(),
              cycle,
              grade_label_mapping: gradesMap,
            },
            // timestamp is required to re-render scorecard when filter is the same, but preview data is different
            // e.g. when added company value,
            renderKey: Date.now(),
          }
        : { scorecard: null, renderKey: null },
    [preview],
  )

  return (
    <PageWrapper>
      <VStack space="s-24">
        <Banner>
          <Banner.Content>
            <Banner.Title variant="heading3">This is a Scorecard example</Banner.Title>
            <Banner.Description>
              You can adjust the scorecard that will be used for Performance Reviews by
              adding company values and custom questions. Skills section depends on the
              role
            </Banner.Description>
            <Spacer height="s-16" />
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                onClick={() =>
                  navigateTo(
                    pathToUrl(ROUTES.PERFORMANCE.SCORECARD_SETUP.CUSTOM_QUESTIONS_FORM),
                  )
                }
              >
                Add custom questions
              </MoreBar.Action>
              <MoreBar.Action
                useIcon="Gear"
                onClick={() =>
                  navigateTo(
                    pathToUrl(ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.LANDING),
                  )
                }
              >
                Settings
              </MoreBar.Action>
            </MoreBar>
          </Banner.Content>
        </Banner>
        <PreviewSetupBanner />
        {scorecard ? (
          <Form<ReviewScorecardInterface>
            key={renderKey}
            initialValues={scorecard}
            disableLocalStorageCaching
            useLocalStorageCaching={false}
          >
            <ScorecardPreview
              performanceSettings={performanceSettings}
              isSegmentedDeliverables={
                performanceSettings?.enable_segmented_deliverables_assessment
              }
            />
          </Form>
        ) : (
          <VStack space="s-16" width="100%">
            <ItemSkeleton height={114} />
          </VStack>
        )}
      </VStack>
    </PageWrapper>
  )
}
