import React from 'react'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import {
  PromotionCurrentStageInterface,
  PromotionNominationInterface,
  PromotionStage,
  PromotionStageInterface,
} from '@src/interfaces/promotions'
import {
  getPromotionStageActionsColumn,
  getPromotionStageOutcomeColumn,
  PromotionActionsOptions,
  promotionStageCompletedDate,
  promotionStageName,
  promotionStageReviewer,
} from '@src/constants/columns/promotion/promotionStages'
import { Flex, Token } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { SummaryTableContainer } from '@src/features/Summary/common'
import { TableNames } from '@src/constants/table'

export type StagesSummary = {
  futureStages: PromotionStage[]
  currentStagesIndex: number[]
}

export interface ReviewTimelineProps {
  table: useTableReturnType<PromotionStageInterface, Stats>
  stagesSummary: StagesSummary
  nominatedEmployee: { id: number; full_name: string }
  nomination: PromotionNominationInterface
  refreshData: () => void
  onBeforeViewClick?: () => void
}

const getRow = (
  options: PromotionActionsOptions,
): RowInterface<PromotionStageInterface> => {
  const { stagesSummary } = options
  return {
    highlight: data => {
      if (stagesSummary.futureStages.includes(data.stage_type.id)) {
        return Token.color.greyTone2
      }
      return Token.color.background
    },
    disabled: data => stagesSummary.futureStages.includes(data.stage_type.id),
    cells: [
      {
        ...promotionStageName,
        width: 150,
      },
      {
        ...promotionStageReviewer,
        width: 150,
      },
      {
        ...promotionStageCompletedDate,
        width: 110,
      },
      {
        ...getPromotionStageOutcomeColumn(stagesSummary.currentStagesIndex),
        width: 110,
      },
      {
        ...getPromotionStageActionsColumn(options),
        width: 130,
      },
    ],
  }
}

export const getStagesSummary = (
  currentStages: PromotionCurrentStageInterface[] | undefined,
  stages: PromotionStageInterface[],
): StagesSummary => {
  if (!stages.length || !currentStages?.length) {
    return { currentStagesIndex: [], futureStages: [] }
  }
  const currentStagesIds = currentStages?.map(el => el.id)

  const currentStagesIndex = stages.reduce<number[]>(
    (acc, currStage, ind) =>
      currentStagesIds.includes(currStage.stage_type.id) ? [...acc, ind] : acc,
    [],
  )

  const futureStages = !currentStagesIndex.length
    ? stages.map(stage => stage.stage_type.id)
    : stages
        // currentStagesIndex can contain multiple indexes, eg [2, 3, 4], so to get future stages
        // we need to get the last index value from currentStagesIndex and +1 to start slicing from the closest future stage
        .slice(currentStagesIndex[currentStagesIndex.length - 1] + 1)
        .map(stage => stage.stage_type.id)

  return {
    currentStagesIndex,
    futureStages,
  }
}

const ReviewTimeline = ({
  table,
  stagesSummary,
  nominatedEmployee,
  nomination,
  refreshData,
  onBeforeViewClick,
}: ReviewTimelineProps) => {
  const row = getRow({
    stagesSummary,
    nomination,
    nominatedEmployee,
    refreshData,
    onBeforeViewClick,
  })

  return (
    <Flex flexDirection="column" width="100%">
      <SummaryTableContainer>
        <AdjustableTable<PromotionStageInterface>
          name={TableNames.PromotionTimeline}
          row={row}
          hideCountAndButtonSection
          rowHeight="medium"
          noDataMessage="No promotion timeline found"
          {...table}
        />
      </SummaryTableContainer>
    </Flex>
  )
}

export default React.memo(ReviewTimeline)
