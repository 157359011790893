import { meetingRecurrence } from '@src/interfaces/meetingsTracker'
import { useEffect, useState } from 'react'

export const defaultMeetingRecurrence = meetingRecurrence.Weekly
export const defaultMeetingDuration = 30

// TODO: temporarily hardcoding the interface on FE until BE is ready https://revolut.atlassian.net/browse/REVC-7250
export interface OneToOneMeetingTemplate {
  duration: number
  recurrence: string
}

interface MeetingTemplate {
  isLoading: boolean
  template: OneToOneMeetingTemplate | null
}

export const useMeetingTemplate = (): MeetingTemplate => {
  const [isLoading, setIsLoading] = useState(true)

  // TODO: temporarily mimic api call until BE is ready https://revolut.atlassian.net/browse/REVC-7250
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  return {
    isLoading,
    template: {
      duration: 30,
      recurrence: 'RRULE:FREQ=WEEKLY;BYDAY=TU',
    },
  }
}
