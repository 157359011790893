import React from 'react'
import { ChartWidgetSkeleton } from '@revolut/ui-kit'

const SKELETON_DEFAULT_PADDING = 16

type ChartSkeletonProps = {
  height: number
}

export const ChartSkeleton = ({ height }: ChartSkeletonProps) => {
  return <ChartWidgetSkeleton.Chart height={height - SKELETON_DEFAULT_PADDING} my="s-0" />
}
