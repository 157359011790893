import { DataPoint, Flex, Icon, TextSkeleton, Token } from '@revolut/ui-kit'
import GraphIconChart, {
  GraphIconChartInterface,
} from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import Tooltip from '@src/components/Tooltip/Tooltip'
import React from 'react'

type OverallProgressProps = {
  value?: number
  id?: GraphIconChartInterface['id']
  fetchData?: GraphIconChartInterface['fetchData']
  tooltip?: string
  warnAboutMandatoryGoal?: boolean
  showBarGraph?: boolean
}

export const OverallProgress = ({
  value,
  id,
  fetchData,
  tooltip,
  warnAboutMandatoryGoal,
  showBarGraph = true,
}: OverallProgressProps) => {
  return (
    <DataPoint>
      <DataPoint.Value color={Token.color.greyTone50}>
        {value !== undefined ? (
          <Flex gap="s-8" width="fit-content" aria-labelledby="overallProgress">
            <Flex flex={2}>
              <Tooltip placement="right" text={tooltip}>
                <ColoredPercent percent={value * 100} />
              </Tooltip>
            </Flex>

            {showBarGraph && id && fetchData ? (
              <Flex flex={1}>
                <GraphIconChart vertical="right" id={id} fetchData={fetchData}>
                  <Flex width="100%" justifyContent="center">
                    <Icon
                      name="BarChart"
                      size={16}
                      style={{ cursor: 'pointer' }}
                      color={Token.color.greyTone20}
                    />
                  </Flex>
                </GraphIconChart>
              </Flex>
            ) : null}
            {warnAboutMandatoryGoal && (
              <Tooltip
                placement="right"
                text="This profile has mandatory goals which must have 100% progress by cycle end, otherwise Overall Progress will be set to zero."
              >
                <Icon name="16/Warning" color={Token.color.red} size={16} />
              </Tooltip>
            )}
          </Flex>
        ) : (
          <TextSkeleton />
        )}
      </DataPoint.Value>
      <DataPoint.Label id="overallProgress">Overall Progress</DataPoint.Label>
    </DataPoint>
  )
}
