import React from 'react'
import { TabBarSkeleton, TabBarVariant } from '@revolut/ui-kit'

type Props = {
  variant?: TabBarVariant
  count?: number
}

const TabBarSkeletonComponent = ({ variant = 'navigation', count = 5 }: Props) => {
  return (
    <TabBarSkeleton variant={variant} data-testid="tabbar-skeleton">
      <TabBarSkeleton.Item aria-selected />
      {new Array(count - 1).fill(0).map((_, i) => (
        <TabBarSkeleton.Item key={i} />
      ))}
    </TabBarSkeleton>
  )
}

export default TabBarSkeletonComponent
