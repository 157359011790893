import React from 'react'
import { Flex } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { QuestionsTab } from './QuestionsTab'
import { CreateNewQuestionTab } from './CreateNewQuestionTab'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { SidebarTabs } from './types'

const tabs = [SidebarTabs.QuestionLibrary, SidebarTabs.CreateNew]

type Props = {
  open: boolean
  onClose: () => void
  onAddQuestion: (
    q: EngagementQuestionInterface | EngagementQuestionInterface[],
    closeSidebar?: boolean,
  ) => void
  addedQuestionsIds: number[]
}
export const AddQuestionSidebar = ({
  open,
  onClose,
  onAddQuestion,
  addedQuestionsIds,
}: Props) => {
  const { tabBar, currentTab, setCurrentTab } = useTabBarSwitcher({
    tabs,
    highlightSelected: false,
    defaultTab: SidebarTabs.QuestionLibrary,
  })

  return (
    <SideBar variant="wide" isOpen={open} title="Add question" onClose={onClose}>
      <Flex mb="s-16">{tabBar}</Flex>
      {currentTab === SidebarTabs.QuestionLibrary && (
        <QuestionsTab
          onAddQuestion={onAddQuestion}
          addedQuestionsIds={addedQuestionsIds}
        />
      )}
      {currentTab === SidebarTabs.CreateNew && (
        <CreateNewQuestionTab
          onAddQuestion={onAddQuestion}
          setCurrentTab={setCurrentTab}
        />
      )}
    </SideBar>
  )
}
