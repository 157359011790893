import { api } from '@src/api/index'
import { GlobalSearch } from '@src/interfaces/globalSearch'
import { GetRequestInterface } from '@src/interfaces'

export const fetchGlobalSearch = (search: string) =>
  api.get<GetRequestInterface<GlobalSearch>>('/searchItems', {
    params: {
      search,
    },
  })
