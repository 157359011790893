import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { formDocumentsCollectionsRequests } from '@src/api/documentsCollections'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import Form from '@src/features/Form/Form'
import { General } from './General'
import { Preview } from './Preview'

const DocumentsCollection = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.DOCUMENT_COLLECTION.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.DOCUMENT_COLLECTION.PREVIEW}>
          <Preview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export const DocumentsCollectionForm = connect(() => (
  <Form api={formDocumentsCollectionsRequests}>
    <DocumentsCollection />
  </Form>
))
