import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import { RunQueryResponse } from '@src/interfaces/kpis'
import { CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { Box, Flex, opacity, TabBar, Text, Token, Widget } from '@revolut/ui-kit'
import NewStaticTable from '@components/Table/NewStaticTable'

const Body = styled.div`
  width: 100%;
  padding: 8px 16px;
  background: ${opacity(Token.colorChannel.deepGrey, 0.1)};
  border-radius: 4px;
  border: 1px solid ${Token.color.greyTone20};
`

const Title = styled.div`
  color: ${Token.color.green};
`
const Error = styled.div`
  color: ${Token.color.red};
`

const ScrollCss = css`
  overflow: auto;
`

enum Tabs {
  Table = 'Table',
  Graph = 'Graph',
}

interface Props {
  response: RunQueryResponse
}

export const QueryTestingTable = ({ response }: Props) => {
  const [tab, setTab] = useState(Tabs.Table)

  if (response.progress_history && response.results?.[0]) {
    const rows = {
      cells: Object.keys(response.results?.[0]).map(key => ({
        dataPoint: key,
        idPoint: key,
        type: CellTypes.text,
        title: key,
        sortKey: null,
        filterKey: null,
        width: 200,
        selectorsKey: selectorKeys.none,
      })),
    }
    const tableComponent = (
      <Box maxHeight={360} css={ScrollCss}>
        <NewStaticTable rows={rows} data={response.results} hasScrollableRow />
      </Box>
    )
    const chartComponent = (
      <Widget width="100%" height={300}>
        <BaseChartInner
          isNew
          title="Metric over time"
          fetchData={getKpiPerformanceGraph}
          id={0}
          data={{
            progress_history: response.progress_history,
            targets: response.targets!,
          }}
        />
      </Widget>
    )

    return (
      <>
        <Flex mb="s-16">
          <TabBar variant="segmented">
            {[Tabs.Table, Tabs.Graph].map(tabName => {
              const isSelected = tab === tabName
              return (
                <TabBar.Item
                  aria-selected={isSelected}
                  onClick={() => {
                    setTab(tabName)
                  }}
                  key={tabName}
                >
                  <Text
                    use="div"
                    fontSize="small"
                    px="s-20"
                    color={isSelected ? 'primary' : 'grey-tone-50'}
                    fontWeight={isSelected ? 500 : 300}
                  >
                    {tabName}
                  </Text>
                </TabBar.Item>
              )
            })}
          </TabBar>
        </Flex>
        {tab === Tabs.Table && tableComponent}
        {tab === Tabs.Graph && chartComponent}
      </>
    )
  }

  return (
    <div>
      <Body>
        {response.message && response.message && (
          <>
            <Title>Success:</Title> {JSON.stringify(response.message, undefined, 4)}
          </>
        )}
        {response && response.error && (
          <>
            <Error>Error:</Error> {JSON.stringify(response.error, undefined, 4)}
          </>
        )}
      </Body>
    </div>
  )
}
