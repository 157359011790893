import { Statuses } from '@src/interfaces/index'
import { KPIPerformanceTypes, KpiTargets } from '@src/interfaces/kpis'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { KPITypes } from '@src/constants/table'

export enum TemplateApplicationStrategies {
  apply_all = 'apply_all',
  apply_selected = 'apply_selected',
}

export interface KPIRuleInterface {
  id: number
  name: string
  description: string
  updated_date_time: string
  owner: {
    id: number
    full_name: string
    display_name?: string
    status?: Statuses
  }
  template: {
    id: number
    name: string
    has_probation_target: boolean
    kpi_type: {
      name: string
      id: string
    }
  }
  application_strategy: {
    id: TemplateApplicationStrategies
    name?: string
  }
  filters: {
    [key: string]: (string | number)[]
  }
}

export const KpiTypeOptions = [
  { id: KPITypes.employee_kpi, name: 'Individual' },
  { id: KPITypes.team_kpi, name: 'Team' },
  { id: KPITypes.department_kpi, name: 'Department' },
] as const

export enum KpiTargetStrategyEnum {
  enforce = 'enforce',
  allow_edit = 'allow_edit',
}

export const KpiTargetStrategy = [
  { id: KpiTargetStrategyEnum.enforce, name: 'Enforce targets' },
  { id: KpiTargetStrategyEnum.allow_edit, name: 'Allow editing targets' },
]
export const KpiTargetStrategyOptions: RadioOption[] = KpiTargetStrategy.map(
  strategy => ({
    value: strategy.id,
    label: strategy.name,
  }),
)

export const KpiIsMandatoryOptions: RadioOption[] = [
  {
    value: KPIPerformanceTypes.mandatory,
    label: 'Yes',
  },
  { value: KPIPerformanceTypes.business, label: 'No' },
]

export const KPIDynamicTargetOptions: RadioOption[] = [
  { value: false, label: 'Fixed targets' },
  { value: true, label: 'Dynamic targets' },
]

export interface KPITemplateInterface {
  id: number
  name: string
  description: string
  updated_date_time: string
  creation_date_time: string
  weight: number
  dynamic_targets: boolean
  dynamic_targets_sql_query?: string
  owner: {
    id: number
    full_name: string
    display_name: string
    status: Statuses
  }
  implemented_kpis: number
  unit: string
  db_client_type: {
    id: string
    name: string
  }
  sql_query_template: string
  sql_query_runs: boolean
  parent_template?: {
    id: number
    name: string
  }
  parent?: {
    id: number
    name: string
  }
  kpi_type: typeof KpiTypeOptions[number]
  targets: KpiTargets[]
  targets_strategy: typeof KpiTargetStrategy[number]
  kpi_performance_type: {
    id: KPIPerformanceTypes
    name: string
  }
}
