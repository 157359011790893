import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
}

export const EditAction = ({ employee, growthPlan }: Props) => {
  const onClick = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.GROWTH_PLAN.EDIT, {
        employeeId: employee.id,
        id: growthPlan.id,
      }),
    )
  }

  return (
    <MoreBar.Action useIcon="Pencil" onClick={onClick}>
      Edit
    </MoreBar.Action>
  )
}
