import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  meetingRecurrence,
  MeetingTrackerInterface,
} from '@src/interfaces/meetingsTracker'
import {
  Avatar,
  Box,
  Flex,
  Link,
  Text,
  Tooltip,
  useTooltip,
  Token,
} from '@revolut/ui-kit'
import { LinkExternal, StarFilled } from '@revolut/icons'
import React from 'react'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import {
  formatDateTimePeriod,
  formatDateWithWeekday,
  formatRRule,
  getTotalTimeFromMinutes,
} from '@src/utils/format'
import styled from 'styled-components'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import round from 'lodash/round'
import { QualityWithTooltip } from '@src/pages/Forms/MeetingsTracker/utils'

const ValueWrapper = styled.div`
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Token.color.foreground};
`

export const meetingsTrackerNameColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Meeting name',
  insert: ({ data }) => {
    return (
      <Link
        href={data.link}
        target="_blank"
        rel="noreferrer noopener"
        onClick={e => e.stopPropagation()}
      >
        <Flex justifyContent="space-between">
          <ValueWrapper>{data.title}</ValueWrapper>
          <LinkExternal size={16} />
        </Flex>
      </Link>
    )
  },
}

export const meetingsTrackerOwnerColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'organizer',
  dataPoint: 'organizer',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.organizer} compact />,
}

const IconWithTooltip = ({ data }: { data: MeetingTrackerInterface }) => {
  const tooltip = useTooltip()
  const participantsNames = data.attendees.map(el => el.full_name).join('\n')

  return (
    <>
      <Box {...tooltip.getAnchorProps()} width="s-24">
        <Avatar size={24} color="grey-tone-50" {...tooltip.getAnchorProps()}>
          +{data.attendees.length}
        </Avatar>
      </Box>
      {!!data.attendees.length && (
        <Tooltip {...tooltip.getTargetProps()} placement="right">
          <Box style={{ whiteSpace: 'break-spaces' }}>{participantsNames}</Box>
        </Tooltip>
      )}
    </>
  )
}

export const meetingsTrackerParticipantsColumn: ColumnInterface<MeetingTrackerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'attendees',
    dataPoint: 'attendees',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Participants',
    insert: ({ data }) => <IconWithTooltip data={data} />,
  }

interface DateWithTooltipProps {
  data: MeetingTrackerInterface
}

export const DateWithTooltip: React.FC<DateWithTooltipProps> = ({ data, children }) => {
  const tooltip = useTooltip()
  const dates = data.dates?.map(el => formatDateWithWeekday(el)).join('\n')

  if (!dates?.length) {
    return <>{children}</>
  }

  return (
    <>
      <Box {...tooltip.getAnchorProps()}>
        {children}
        <Tooltip {...tooltip.getTargetProps()} placement="bottom">
          <Box style={{ whiteSpace: 'break-spaces' }}>{dates}</Box>
        </Tooltip>
      </Box>
    </>
  )
}

export const meetingsTrackerDateColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'start',
  dataPoint: 'start',
  sortKey: 'start',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Date',
  insert: ({ data }) => {
    return (
      <DateWithTooltip data={data}>
        {formatDateTimePeriod(data.start, data.end)}
      </DateWithTooltip>
    )
  },
}

export const meetingsTrackerTypeColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: 'category',
  filterKey: 'category',
  selectorsKey: selectorKeys.meeting_categories,
  title: 'Type',
  headerTooltip: (
    <TooltipContainer minWidth={230}>Only meeting owners can categorise</TooltipContainer>
  ),
}

export const meetingsTrackerRecurringTypeColumn: ColumnInterface<MeetingTrackerInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'recurrence',
    dataPoint: 'recurrence',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Recurring type',
    insert: ({ data }) => {
      if (!data.parent_event_id) {
        return meetingRecurrence.Single
      }
      return (
        <DateWithTooltip data={data}>
          {data.recurrence ? formatRRule(data.recurrence) : meetingRecurrence.Custom}
        </DateWithTooltip>
      )
    },
  }

export const meetingsTrackerTotalTimeColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'duration',
  dataPoint: 'duration',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Total time',
  insert: ({ data }) => (data.duration ? getTotalTimeFromMinutes(data.duration) : ''),
}

export const meetingsTrackerQualityColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'quality',
  dataPoint: 'quality',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Quality',
  textAlign: 'right',
  insert: ({ data }) => <QualityWithTooltip data={data} />,
}

export const meetingsTrackerRatingColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'rating',
  dataPoint: 'rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Av. rating',
  textAlign: 'right',
  insert: ({ data }) => {
    return data.rating ? (
      <Flex justifyContent="flex-end" gap="s-4">
        <Text>{round(data.rating, 1)}</Text>
        <StarFilled size={16} color="yellow" />
      </Flex>
    ) : (
      <Box mr="s-6">-</Box>
    )
  },
}

export const meetingsTrackerActionColumn: ColumnInterface<MeetingTrackerInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  headerTooltip: (
    <TooltipContainer minWidth={230}>
      Only past meetings can be rated by meeting participants
    </TooltipContainer>
  ),
}
