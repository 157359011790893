import { useCallback, useEffect, useState } from 'react'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import { getExistingCandidates } from '@src/api/newCandidate'
import { getLinkedinUrl } from '@src/utils/hiring'
import debounce from 'lodash/debounce'
import { useExtensionApiContext } from '@src/utils/extension'

const DEBOUNCE_CHECK_EXISTING_WAIT = 1000

export const useFetchExistingCandidates = (
  name?: string,
  email?: string,
  phone?: string,
  links?: string[],
  disabled?: boolean,
) => {
  const apiHandler = useExtensionApiContext()
  const [existingCandidates, setExistingCandidates] = useState<CandidateInterface[]>()

  const fetchExistingCandidates = async (
    localName?: string,
    localEmail?: string,
    localPhone?: string,
    localLinkedin?: string,
  ) => {
    if (localName || localEmail || localPhone || localLinkedin) {
      try {
        const resp = await getExistingCandidates(
          localName,
          localEmail,
          localPhone,
          localLinkedin,
          apiHandler,
        )
        setExistingCandidates(resp.data)
      } catch (e) {
        setExistingCandidates([])
      }
    } else {
      setExistingCandidates([])
    }
  }

  const linkedinUrl = getLinkedinUrl(links)

  const debouncedFetchExistingCandidates = useCallback(
    debounce(fetchExistingCandidates, DEBOUNCE_CHECK_EXISTING_WAIT),
    [],
  )

  useEffect(() => {
    if (disabled) {
      return
    }
    debouncedFetchExistingCandidates(name, email, phone, linkedinUrl)
  }, [name, email, phone, linkedinUrl, disabled])

  return { existingCandidates }
}
