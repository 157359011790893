import React, { useMemo, useState } from 'react'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Badge, Box, TextSkeleton, Token } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import {
  timeOffPoliciesBasicRequests,
  timeOffPoliciesDetailsRequests,
  timeOffPoliciesEligibilityRequests,
  timeOffPoliciesRequestSettingsRequests,
  useGetTimeOffPolicy,
  useTimeOffPolicyBasicStep,
  useTimeOffPolicyEditStepStatuses,
} from '@src/api/timeOff'
import { RequestInterfaceNew } from '@src/interfaces'
import { TimeOffPolicyEditStepStatusesInterface } from '@src/interfaces/timeOff'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { useQuery } from '@src/utils/queryParamsHooks'

import { PolicyEditBasicsTab } from './Basics'
import { PolicyEditDetailsTab } from './Details'
import { PolicyEditRequestsTab } from './Requests'
import { PolicyEditEligibilityTab } from './Eligibility'
import { PolicyEditReviewTab } from './Review'
import { COPY_FROM_POLICY_ID, PolicyEditTabCommonProps, TabKey } from './common'

type PolicyEditTab = TabBarTableNavigationInterface & {
  key: TabKey
  apiKey: keyof TimeOffPolicyEditStepStatusesInterface
  path: string
  api?: RequestInterfaceNew<{}>
  component: React.FC<PolicyEditTabCommonProps>
}

type TabParams = { id?: string }
type BaseUrl = {
  BASICS: string
  DETAILS: string
  REQUESTS: string
  ELIGIBILITY: string
  REVIEW: string
}

const getTabsOrder = (baseUrl: BaseUrl) => [
  {
    title: 'Basics',
    key: 'basics' as const,
    apiKey: 'draft_basic_step_done' as const,
    api: timeOffPoliciesBasicRequests,
    path: baseUrl.BASICS,
    component: PolicyEditBasicsTab,
  },
  {
    title: 'Details',
    key: 'details' as const,
    apiKey: 'draft_details_step_done' as const,
    api: timeOffPoliciesDetailsRequests,
    path: baseUrl.DETAILS,
    component: PolicyEditDetailsTab,
  },
  {
    title: 'Requests',
    key: 'requests' as const,
    apiKey: 'draft_requests_step_done' as const,
    api: timeOffPoliciesRequestSettingsRequests,
    path: baseUrl.REQUESTS,
    component: PolicyEditRequestsTab,
  },
  {
    title: 'Eligibility',
    key: 'eligibility' as const,
    apiKey: 'draft_eligibility_step_done' as const,
    api: timeOffPoliciesEligibilityRequests,
    path: baseUrl.ELIGIBILITY,
    component: PolicyEditEligibilityTab,
  },
  {
    title: 'Review',
    key: 'review' as const,
    apiKey: 'draft_auto_assignments_step_done' as const,
    path: baseUrl.REVIEW,
    component: PolicyEditReviewTab,
  },
]

const isTabCompleted = (
  t: PolicyEditTab,
  allStepsStatuses: TimeOffPolicyEditStepStatusesInterface | undefined,
) => !!allStepsStatuses?.[t.apiKey]

const getTabs = (
  params: TabParams,
  allStepsStatuses: TimeOffPolicyEditStepStatusesInterface | undefined,
  baseUrl: BaseUrl,
): PolicyEditTab[] =>
  getTabsOrder(baseUrl).reduce((tabsWithStatuses, tab, idx) => {
    const isCurrentTabCompleted = isTabCompleted(tab, allStepsStatuses)
    const isAllPrevTabsCompleted = tabsWithStatuses.every(t =>
      isTabCompleted(t, allStepsStatuses),
    )
    const isCompleted = isCurrentTabCompleted && isAllPrevTabsCompleted
    const isPending = isAllPrevTabsCompleted
    const disabled = !isCompleted && !isPending

    const nextTab = {
      ...tab,
      disabled,
      url: pathToUrl(tab.path, params),
      preTitle: (
        <Box mr="s-8">
          <Badge
            size={16}
            bg={
              isCompleted
                ? Token.color.green
                : isPending
                ? Token.color.foreground
                : Token.color.greyTone50
            }
          >
            {idx + 1}
          </Badge>
        </Box>
      ),
    }
    tabsWithStatuses.push(nextTab)

    return tabsWithStatuses
  }, [] as PolicyEditTab[])

export const EditTimeOffPolicyTabs = () => {
  const params = useParams<TabParams>()
  const { query } = useQuery()
  const { data: allStepsStatuses, refetch: refetchSteps } =
    useTimeOffPolicyEditStepStatuses(params.id)

  const [copyId] = useState<string>(query[COPY_FROM_POLICY_ID])
  const [tabsCopied, setTabsCopied] = useState<Record<TabKey, boolean>>({
    basics: false,
    details: false,
    requests: false,
    eligibility: false,
    review: false,
  })

  const isCustomerOnboarding = useRouteMatch(ROUTES.ONBOARDING_CHECKLIST_V2.ANY)

  const tabs = useMemo(
    () =>
      getTabs(
        params,
        allStepsStatuses,
        isCustomerOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CREATE_NEW_POLICY
          : ROUTES.FORMS.TIME_OFF_POLICY.EDIT,
      ),
    [params, allStepsStatuses],
  )
  const { data: copyFrom, isLoading: isLoadingCopyFrom } = useGetTimeOffPolicy(copyId)
  const { data: basicStepInfo, refetch: refetchBasicStepInfo } =
    useTimeOffPolicyBasicStep(params.id)
  const copiedName = copyFrom?.name ? `${copyFrom?.name} - Copy` : ''
  const displayName = basicStepInfo?.name || copiedName || 'New policy'

  return (
    <>
      <PageHeader
        title={isLoadingCopyFrom ? <TextSkeleton width={340} height={36} /> : displayName}
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-16" />
        <Switch>
          {tabs.map((tab, idx) => (
            <Route exact key={tab.key} path={tab.path}>
              <Form api={tab.api} disableLocalStorageCaching>
                <tab.component
                  copyFrom={tabsCopied[tab.key] ? undefined : copyFrom}
                  setIsCopied={() => {
                    setTabsCopied({ ...tabsCopied, [tab.key]: true })
                  }}
                  nextTabPath={tabs[idx + 1]?.path}
                  onNavigateToNextTab={() => refetchSteps()}
                  basicStepInfo={basicStepInfo}
                  refetchBasicStepInfo={refetchBasicStepInfo}
                />
              </Form>
            </Route>
          ))}
        </Switch>
      </PageBody>
    </>
  )
}
