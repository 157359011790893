import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { EmailPlaceholderInterface } from '@src/interfaces/emailTemplates'

export const emailPlaceholderNameColumn: ColumnInterface<EmailPlaceholderInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Placeholder',
}

export const emailPlaceholderTypeColumn: ColumnInterface<EmailPlaceholderInterface> = {
  type: CellTypes.text,
  idPoint: 'field_type',
  dataPoint: 'field_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}
