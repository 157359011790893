import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box, chain, Text } from '@revolut/ui-kit'

import { timeOffPoliciesRequests } from '@src/api/timeOff'
import { ROUTES } from '@src/constants/routes'
import { getStatusColor } from '@src/components/CommonSC/General'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { TimeOffPolicyInterface } from '@src/interfaces/timeOff'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'

import { Changelog } from './Changelog'
import { Preview } from './Preview'
import { EditTimeOffPolicyTabs } from './EditTabs'

const PreviewTimeOffPolicyTabs = () => {
  const params = useParams()
  const { initialValues } = useLapeContext<TimeOffPolicyInterface>()

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.PREVIEW, params),
      component: Preview,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.TIME_OFF_POLICY.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.CHANGELOG, params),
      component: Changelog,
    },
  ]

  return (
    <>
      <PageHeader
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.POLICIES}
        pb="s-8"
        title={chain(
          initialValues?.name,
          <Text color={getStatusColor(initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
      >
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>

      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </>
  )
}

const TimeOffPolicy = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route
          exact
          path={ROUTES.FORMS.TIME_OFF_POLICY.EDIT.ANY}
          component={EditTimeOffPolicyTabs}
        />
        <Form api={timeOffPoliciesRequests} disableLocalStorageCaching>
          <PreviewTimeOffPolicyTabs />
        </Form>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => <TimeOffPolicy />)
