import { useLapeContext } from '@src/features/Form/LapeForm'
import React, { useEffect } from 'react'
import { connect, ignoreState } from 'lape'
import { TabsInterface } from '@src/interfaces/data'
import { ReviewScorecardInterface } from '@src/interfaces/performance'

type Props = {
  tab: TabsInterface<ReviewScorecardInterface>
  employeeId?: number
  id?: number
}

const Component = ({ tab, ...props }: Props) => {
  const context = useLapeContext()
  const validation = ignoreState(tab.validator || {})

  useEffect(() => {
    context.validation = validation || {}
  }, [tab.path])

  return <tab.component isViewMode={false} {...props} />
}

export default connect(Component)
