import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { reviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { PerformanceReviewsIntro } from './PerformanceReviewsIntro'
import { PerformanceReviewScorecard } from './PerformanceReviewScorecard'
import {
  OnboardingChecklistContent,
  OnboardingChecklistRoute,
} from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

import { PerformanceReviewTypes } from '@src/pages/OnboardingChecklistV2/PerformanceReviews/PerformanceReviewTypes'

export const selectReviewTypesTitle = 'Select review types'

const onboardingSteps: OnboardingChecklistRoute[] = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.INTRO,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewsIntro,
  },
  {
    title: selectReviewTypesTitle,
    path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.REVIEW_TYPES,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.REVIEW_TYPES,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewTypes,
    hideActions: true,
    hidePageBody: true,
  },
  {
    title: 'Setup scorecard',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.REVIEWS.SETUP_SCORECARD,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewScorecard,
  },
]

export const PerformanceReviews = () => {
  return <OnboardingChecklistContent config={reviewsConfig} routes={onboardingSteps} />
}
