import { lape } from 'lape'

export interface GlobalSearchState {
  open: boolean | 'favourites-mode'
  showAction: boolean
}

const defaultState: GlobalSearchState = {
  open: false,
  showAction: false,
}

export default lape(defaultState)
