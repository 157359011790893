import React from 'react'

import { HubAppType } from '@src/interfaces/hub'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { OnboardingCheckpointAppCategory } from '@src/interfaces/onboardingChecklistV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import HubApp from './HubApp'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

interface HubAppWithOnboardingScreenProps {
  app: HubAppType
  category: OnboardingCheckpointAppCategory
}

export const HubAppWithOnboardingScreen = ({
  category,
  app,
}: HubAppWithOnboardingScreenProps) => {
  const isCoreNavigation = useCoreNavigation()

  return (
    <OnboardingAppScreen
      category={category}
      contentAbove={
        <PageHeader
          pt={isCoreNavigation ? 's-0' : 's-8'}
          title={app.title}
          backUrl={ROUTES.FEATURES.APPS}
        />
      }
    >
      <HubApp app={app} />
    </OnboardingAppScreen>
  )
}
