import React from 'react'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetEligibleInterviewers } from '@src/api/recruitment/interviewerScheduling'
import { transformEmployeeToOption } from '@src/utils/employees'

export type Props = {
  onChange: (options?: OptionInterface[]) => void
  value?: OptionInterface[]
  stageId?: number
  roundId?: number
  disabled?: boolean
  isPrepCall: boolean
  anchor?: React.RefObject<HTMLElement | null>
}
const SlotInterviewerFilter = ({
  onChange,
  value,
  stageId,
  roundId,
  disabled,
  isPrepCall,
  anchor,
}: Props) => {
  const { data: eligibleReviewersData } = useGetEligibleInterviewers(
    stageId,
    roundId,
    isPrepCall,
  )
  return (
    <FilterButtonCheckboxSelect
      options={eligibleReviewersData?.map(transformEmployeeToOption) || []}
      label="Interviewers"
      onChange={onChange}
      value={value}
      disabled={disabled}
      width={352}
      anchor={anchor}
      searchable
    />
  )
}

export default SlotInterviewerFilter
