import React from 'react'
import {
  chain,
  Flex,
  MoreBarSkeleton,
  Text,
  TextSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { useGetCRMReview } from '@src/api/CRM'
import { CRMReviewCandidateSwitcher } from '@src/pages/Forms/Candidate/CRMReview/CRMReviewCandidateSwitcher'
import { CRMReviewActions } from '@src/pages/Forms/Candidate/CRMReview/CRMReviewActions'
import { useReviewLocationState } from '@src/pages/Forms/Candidate/CRMReview/utils'

type CRMReviewLayoutProps = {
  actions: React.ReactNode
  switcher: React.ReactNode
  title: React.ReactNode
}

const CRMReviewLayout = ({ actions, switcher, title }: CRMReviewLayoutProps) => {
  return (
    <Widget
      data-testid="CRM Review"
      maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}
      p="s-16"
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <VStack gap="s-16">
          <Text variant="heading3" color={Token.color.greyTone50}>
            {chain(<Text color={Token.color.foreground}>Review candidates</Text>, title)}
          </Text>
          {actions}
        </VStack>
        {switcher}
      </Flex>
    </Widget>
  )
}

const CRMReviewSkeleton = () => {
  return (
    <CRMReviewLayout
      actions={<MoreBarSkeleton />}
      title={
        <>
          <TextSkeleton width={Token.size.s12} />/
          <TextSkeleton width={Token.size.s12} />
        </>
      }
      switcher={<CRMReviewCandidateSwitcher interviewRounds={[]} />}
    />
  )
}

const useCRMReview = () => {
  const { reviewId, interviewRoundId } = useReviewLocationState()
  const { data: review, isLoading: loadingReview } = useGetCRMReview(reviewId)
  return {
    interviewRoundId,
    loadingReview,
    review,
    reviewId,
  }
}

export const CRMReview = () => {
  const { interviewRoundId, loadingReview, review, reviewId } = useCRMReview()
  if (!reviewId) {
    return null
  }
  if (loadingReview && !review) {
    return <CRMReviewSkeleton />
  }
  if (!review) {
    return null
  }
  const totalReviews = review.interview_round_ids.length
  const currentReview =
    (review.interview_round_ids.findIndex(id => id === interviewRoundId) || 0) + 1
  return (
    <CRMReviewLayout
      actions={<CRMReviewActions interviewRounds={review.interview_round_ids} />}
      title={
        <Text>
          {currentReview}/{totalReviews}
        </Text>
      }
      switcher={
        <CRMReviewCandidateSwitcher interviewRounds={review.interview_round_ids} />
      }
    />
  )
}
