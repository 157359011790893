import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { accessManagementSettings } from '@src/api/settings'
import { AccessManagement } from '@src/pages/Settings/AccessManagement/AccessManagement'

const routes = [
  {
    title: 'Access management app settings',
    description: 'Settings related to access management functionalities',
    path: ROUTES.SETTINGS.ACCESS_MANAGEMENT,
    url: ROUTES.SETTINGS.ACCESS_MANAGEMENT,
    canView: [
      PermissionTypes.ViewAccessManagementPreferences,
      PermissionTypes.ChangeAccessManagementPreferences,
    ],
    component: AccessManagement,
  },
]

export const AccessManagementSettings = () => {
  return <SettingsForm routes={routes} api={accessManagementSettings} />
}
