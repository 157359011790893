import React, { useEffect, useRef } from 'react'
import {
  PerformanceReviewTypes,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card, CardField } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  getRoundedRating,
  CommonGradeOption,
  onPrefillWithGrade,
  shouldScrollToError,
  usePrefillSkillsWithPreviousQuarter,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { getNormalizedCards } from '@src/utils/performance'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { LoadingCard } from '@src/pages/Forms/EmployeePerformanceLayout/components/LoadingCard'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { ManagerSkills } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/ManagerSkills'
import { getSkillsRatingOptions } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'

export const ManagerSkillsCard = connect(({ onHelpClick }: CommonCardProps) => {
  const { values, errors } = useLapeContext<ReviewScorecardInterface>()
  const ref = useRef<HTMLDivElement>(null)
  const statusPopup = useStatusPopup()

  const canViewManagerSkills = !!values?.review_data?.manager_skills

  useEffect(() => {
    const shouldScroll = shouldScrollToError(errors, 'review_data.manager_skills')
    if (shouldScroll) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [errors.review_data])

  const { prefillCompleted } = usePrefillSkillsWithPreviousQuarter(
    values,
    values?.review_data?.manager_skills?.cards,
  )

  if (!canViewManagerSkills) {
    return null
  }

  const managerSkillsCards = getNormalizedCards(
    values.review_data?.manager_skills?.cards || [],
  )
  const managerSkillsFields = managerSkillsCards.map((card, ind) => ({
    field: `review_data.manager_skills.cards.${ind}`,
    title: card.name,
    grades: getSkillsRatingOptions(ind, values.review_data?.manager_skills?.cards),
    gradeRecommendation: null,
    cardIndex: ind,
    cardJustification: null,
  }))

  const onSelectGrade = (grade: CommonGradeOption, field: CardField) => {
    const currentRating = get(values, `${field.field}.rating`)
    set(values, `${field.field}.rating`, grade.key)
    try {
      onPrefillWithGrade(
        values,
        grade,
        PerformanceReviewTypes.managerSkills,
        field.cardIndex,
      )
    } catch (e) {
      set(values, `${field.field}.rating`, currentRating)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Could not recalculate rating</StatusPopup.Title>
        </StatusPopup>,
      )
    }
  }

  if (!prefillCompleted) {
    return <LoadingCard />
  }

  return (
    <Card
      data={values}
      renderExpandedContent={expContentField => (
        <ManagerSkills selectedField={expContentField} />
      )}
      type={CardContentTypes.MANAGER_SKILLS}
      title="Manager skills"
      icon="People"
      fields={managerSkillsFields}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.rating
        return !!ratingValue && getRoundedRating(ratingValue) === grade.key
      }}
      onSelectDeliverableGrade={onSelectGrade}
      justification={values?.review_data?.manager_skills?.skipped_section_justification}
      headerRef={ref}
      onHelpClick={() => onHelpClick?.(HelpTabs.Skills)}
      hideMessageBtn
    />
  )
})
