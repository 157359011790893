import React, { useEffect, useState } from 'react'
import { Action, copyToClipboard } from '@revolut/ui-kit'

type Props = {
  text: string
  label?: string
}

const CopyClipboardButton = ({ text, label = 'Copy' }: Props) => {
  const [isCopied, setCopied] = useState(false)

  useEffect(() => {
    let id = setTimeout(() => setCopied(false), 1000)
    return () => clearTimeout(id)
  }, [isCopied])

  return (
    <Action
      onClick={() => copyToClipboard(text).then(() => setCopied(true))}
      disabled={isCopied}
    >
      {isCopied ? 'Copied' : label}
    </Action>
  )
}

export default CopyClipboardButton
