import React from 'react'
import {
  Avatar,
  AvatarSize,
  AvatarSkeleton,
  Box,
  Icon,
  Token,
  TopNav,
} from '@revolut/ui-kit'
import { useGetCompanyLogo, useGetCompanyPreferences } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { SideBarEvent } from '@src/features/MainLayout/SideBar/SideBarEvent'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface UserAvatarWithMenuProps {
  size?: AvatarSize
}

export const TopNavCompanyAvatar = ({ size = 32 }: UserAvatarWithMenuProps) => {
  const isCommercial = useIsCommercial()

  const { data: companyPreferences } = useGetCompanyPreferences()
  const showCompanyLogo = !!companyPreferences?.enable_branding
  const { data: avatarData, isLoading } = useGetCompanyLogo()

  if (isLoading) {
    return <AvatarSkeleton size={size} />
  }

  return (
    <>
      {showCompanyLogo && isCommercial ? (
        <TopNav.Logo
          useIcon={<Avatar image={avatarData?.url} size={size} />}
          to={ROUTES.MAIN}
          use={InternalLink}
          color={Token.color.white}
        />
      ) : (
        <TopNav.Logo
          useIcon={
            <Box style={{ position: 'relative' }}>
              <Icon name="LogoRevolut" color={Token.color.white} />
              <SideBarEvent />
            </Box>
          }
          to={ROUTES.MAIN}
          use={InternalLink}
          bg={Token.color.blue}
          color={Token.color.white}
        />
      )}
    </>
  )
}
