import React, { useRef } from 'react'
import { requisitionsRequests } from '@src/api/requisitions'
import SideBar from '@src/components/SideBar/SideBar'
import { useTable } from '@src/components/Table/hooks'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Button, ItemSkeleton, Side, StatusWidget, VStack } from '@revolut/ui-kit'
import { RequisitionGroup } from '@src/features/JobPostingFlow/Details/RequisitionGroup'
import { RequisitionItem } from '@src/features/JobPostingFlow/Details/RequisitionItem'
import { JobPostingSpecialisationInterface } from '@src/interfaces/jobPosting'
import { Virtuoso } from 'react-virtuoso'
import { ConnectedRequisitionsFilter } from '@src/features/JobPostingFlow/Details/ConnectedRequisitionsFilter'
import { Statuses } from '@src/interfaces'

interface ConnectRequisitionsSidebarProps {
  requisitions: RequisitionInterface[]
  specialisation: JobPostingSpecialisationInterface
  onClose: VoidFunction
  onConnectRequisitions: (newConnectedRequisitions: RequisitionInterface[]) => void
}

export const ConnectRequisitionsSidebar = ({
  requisitions,
  specialisation,
  onClose,
  onConnectRequisitions,
}: ConnectRequisitionsSidebarProps) => {
  const requisitionIds = requisitions.map(({ id }) => id)
  const scrollRef = useRef<HTMLDivElement>(null)
  const table = useTable<RequisitionInterface>(requisitionsRequests, [
    {
      filters: [
        {
          name: String(specialisation.id),
          id: specialisation.id,
        },
      ],
      columnName: 'specialisation__id',
      nonResettable: true,
    },
    {
      filters: [
        {
          name: Statuses.opened,
          id: Statuses.opened,
        },
      ],
      columnName: 'status',
      nonResettable: true,
    },
  ])
  const tableRequisitionIds = table.data.map(({ id }) => id)
  const allSelected = tableRequisitionIds.every(id => requisitionIds.includes(id))
  const noRequisitions = !table.loading && !table.data?.length
  const handleSelectAll = () => {
    if (allSelected) {
      onConnectRequisitions([])
    } else {
      onConnectRequisitions(table.data)
    }
  }
  const handleSelectRequisition = (requisition: RequisitionInterface) => {
    if (requisitionIds.includes(requisition.id)) {
      const newSelectedRequisitionIds = requisitions.filter(
        ({ id }) => id !== requisition.id,
      )
      onConnectRequisitions(newSelectedRequisitionIds)
    } else {
      onConnectRequisitions([...requisitions, requisition])
    }
  }
  return (
    <SideBar
      isOpen
      title="Requisitions"
      subtitle={specialisation.name}
      onClose={onClose}
      sideProps={{ scrollRef }}
    >
      <VStack gap="s-16">
        <ConnectedRequisitionsFilter onFilterChange={table.onFilterChange} />
        <RequisitionGroup
          disabled={noRequisitions}
          loading={table.loading}
          selected={allSelected}
          onClick={handleSelectAll}
        >
          <Virtuoso
            data={table.data}
            customScrollParent={scrollRef?.current || undefined}
            overscan={150}
            // https://github.com/petyosi/react-virtuoso/issues/341
            initialItemCount={table.data.length - 1}
            endReached={() => {
              if (table.count > table.data.length) {
                table.fetchNextPage()
              }
            }}
            itemContent={(_, requisition) => {
              return (
                <RequisitionItem
                  key={requisition.id}
                  requisition={requisition}
                  selected={requisitionIds.includes(requisition.id)}
                  onClick={() => {
                    handleSelectRequisition(requisition)
                  }}
                />
              )
            }}
          />
          {table.loading && <ItemSkeleton />}
        </RequisitionGroup>
        {noRequisitions && (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
              }}
            />
            <StatusWidget.Description>
              No requisitions found for {specialisation.name}
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </VStack>
      <Side.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Confirm</Button>
      </Side.Actions>
    </SideBar>
  )
}
