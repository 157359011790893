import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'
import { getAvatarUrl } from '@src/utils/employees'

export const addAvatar = (selection: AddSelection, t: HierarchyTransition) => {
  /* Avatar Placeholder */
  selection
    .append('rect')
    .attr('class', 'AvatarPlaceholder')
    .attr('clip-path', 'url(#avatar-clip)')
    .attr('width', HierarchyConsts.avatarSize)
    .attr('height', HierarchyConsts.avatarSize)
    .attr('transform', d => {
      return `translate(${(d?.parent?.x ?? d.x) - HierarchyConsts.avatarSize / 2}, ${
        (d?.parent?.y ?? d.y) -
        HierarchyConsts.cardHeight -
        HierarchyConsts.avatarSize / 2
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x - HierarchyConsts.avatarSize / 2}, ${
          d.y - HierarchyConsts.cardHeight - HierarchyConsts.avatarSize / 2
        })`
      }),
    )

  /* Avatar Placeholder Text */
  selection
    .append('text')
    .text(d => d.data.full_name[0])
    .attr('width', 20)
    .attr('opacity', d => (d.data.avatar ? '0' : '1'))
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('class', 'AvatarPlaceholderText')
    .attr('transform', d => {
      return `translate(${d?.parent?.x ?? d.x}, ${
        (d?.parent?.y ?? d.y) - HierarchyConsts.avatarSize / 2
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x}, ${d.y - HierarchyConsts.cardHeight})`
      }),
    )

  /* Avatar */
  selection
    .append('image')
    .attr('class', d => (d.data.disabled ? 'Avatar Disabled' : 'Avatar'))
    .attr('clip-path', 'url(#avatar-clip)')
    .attr('xlink:href', d => getAvatarUrl(d.data.avatar) || '')
    .attr('width', HierarchyConsts.avatarSize)
    .attr('height', HierarchyConsts.avatarSize)
    .attr('transform', d => {
      return `translate(${(d?.parent?.x ?? d.x) - HierarchyConsts.avatarSize / 2}, ${
        (d?.parent?.y ?? d.y) -
        HierarchyConsts.cardHeight -
        HierarchyConsts.avatarSize / 2
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x - HierarchyConsts.avatarSize / 2}, ${
          d.y - HierarchyConsts.cardHeight - HierarchyConsts.avatarSize / 2
        })`
      }),
    )
}

export const updateAvatar = (selection: UpdateSelection, t: HierarchyTransition) => {
  /* Avatar Placeholder */
  selection
    .select('.AvatarPlaceholder')
    .transition(t)
    .attr('transform', d => {
      return `translate(${d.x - HierarchyConsts.avatarSize / 2}, ${
        d.y - HierarchyConsts.cardHeight - HierarchyConsts.avatarSize / 2
      })`
    })

  /* Avatar Placeholder Text */
  selection
    .select('.AvatarPlaceholderText')
    .transition(t)
    .attr('transform', d => {
      return `translate(${d.x}, ${d.y - HierarchyConsts.cardHeight})`
    })

  /* Avatar */
  selection
    .select('.Avatar')
    .transition(t)
    .attr('transform', d => {
      return `translate(${d.x - HierarchyConsts.avatarSize / 2}, ${
        d.y - HierarchyConsts.cardHeight - HierarchyConsts.avatarSize / 2
      })`
    })
}
