import React from 'react'
import { useSelector } from 'react-redux'

import { OnboardingCheckpointAppCategory } from '@src/interfaces/onboardingChecklistV2'
import { OnboardingAppTile } from './OnboardingAppTile'
import { useAppOnboardingState } from '../common/hooks'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useCurrentTenantInfo } from '@src/api/tenants'
import { isCommercial } from '@src/utils'
import { hasTestCycleCookie } from '@src/utils/reviewCycles'

interface OnboardingAppScreenProps {
  category: OnboardingCheckpointAppCategory | null
  contentAbove?: React.ReactNode
}

export const OnboardingAppScreen: React.FC<OnboardingAppScreenProps> = ({
  category,
  contentAbove,
  children,
}) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const onboardingDisabled =
    !featureFlags.includes(FeatureFlags.OnboardingFlowV2) || !category || !isCommercial()
  const sandboxModeEnabled = hasTestCycleCookie()

  const { isDemoMode } = useCurrentTenantInfo(onboardingDisabled)

  if (onboardingDisabled || isDemoMode || sandboxModeEnabled) {
    return <>{children}</>
  }

  return (
    <OnboardingAppScreenContent category={category} contentAbove={contentAbove}>
      {children}
    </OnboardingAppScreenContent>
  )
}

interface OnboardingAppScreenContentProps {
  category: OnboardingCheckpointAppCategory
  contentAbove?: React.ReactNode
}

const OnboardingAppScreenContent: React.FC<OnboardingAppScreenContentProps> = ({
  category,
  contentAbove,
  children,
}) => {
  const onboardingState = useAppOnboardingState(category)

  if (onboardingState.isLoading) {
    return <PageLoading />
  }

  if (onboardingState.onboarded) {
    return <>{children}</>
  }

  return (
    <>
      {contentAbove}
      <OnboardingAppTile category={category} disabled={!onboardingState.canOnboard} />
    </>
  )
}
