import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { VStack } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { ApprovalsMessage } from '@src/pages/OnboardingChecklist/components/Message'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useGetCompanyPreferences } from '@src/api/settings'
import PageLoading from '@src/components/PageLoading/PageLoading'

const TeamsApprovals = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()
  const { data: companyPreferences } = useGetCompanyPreferences()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)

  if (!companyPreferences) {
    return <PageLoading />
  }

  return (
    <VStack gap="s-24">
      {companyPreferences.enable_departments ? (
        <VStack gap="s-16">
          <SectionTitle title="Do you require approval to create a new department?" />

          <LapeBooleanRadioSwitch
            name="enable_departments_approvals"
            variant="horizontal"
            disabled={disableEdit}
            yesDescription="I want to set up approval processes when creating teams"
            noDescription="I do not need approval processes when creating teams"
          />

          {values.enable_departments_approvals && <ApprovalsMessage />}

          <ApprovalSteps
            switchField="enable_departments_approvals"
            approvalProcess="departments_approval_steps"
            entity="department"
            hideSwitch
            disabled={disableEdit}
            formRef={values}
          />
        </VStack>
      ) : null}

      <VStack gap="s-16">
        <SectionTitle title="Do you require approval to create a new team?" />

        <LapeBooleanRadioSwitch
          name="enable_teams_approvals"
          variant="horizontal"
          disabled={disableEdit}
          yesDescription="I want set up approval process for my teams by assigning employees or employee group to approve teams when created"
          noDescription="I do not need approval process when creating departments"
        />

        {values.enable_teams_approvals && <ApprovalsMessage />}

        <ApprovalSteps
          switchField="enable_teams_approvals"
          approvalProcess="teams_approval_steps"
          entity="team"
          hideSwitch
          disabled={disableEdit}
          formRef={values}
        />
      </VStack>
    </VStack>
  )
}

export default TeamsApprovals
