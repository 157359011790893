import React, { useState } from 'react'
import { Button, Header, Popup, TextArea } from '@revolut/ui-kit'

interface PopupWithCommentProps {
  title: string
  subtitle?: string
  isOpen: boolean
  onSubmit: (body: string) => void
  onClose: () => void
  isSubmitting: boolean
}

const PopupWithComment = ({
  title,
  subtitle,
  isOpen,
  onSubmit,
  onClose,
  isSubmitting,
}: PopupWithCommentProps) => {
  const [comment, setComment] = useState<string>('')

  return (
    <Popup open={isOpen} variant="bottom-sheet" onClose={onClose}>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        {subtitle && <Header.Description>{subtitle}</Header.Description>}
      </Header>
      <TextArea
        value={comment}
        label="Type here"
        onChange={e => setComment(e.currentTarget.value)}
        rows={2}
      />
      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(comment)} pending={isSubmitting}>
          Submit
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default PopupWithComment
