import React, { useEffect } from 'react'
import {
  Item,
  Text,
  Token,
  ActionButton,
  HStack,
  InputGroup,
  Icon,
  Box,
} from '@revolut/ui-kit'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import ScheduleWidget from '@src/features/ScheduleWidget/ScheduleWidget'

import { FormattedMessage } from 'react-intl'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import pluralize from 'pluralize'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { Statuses } from '@src/interfaces'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { getMaxDeadlineDays } from '../helpers'

export const Basic = () => {
  const { values } = useLapeContext<EngagementSurveyInterface>()
  const user = useSelector(selectUser)
  const maxDeadlineTimeDays = getMaxDeadlineDays(values.sent_every)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.first_name }
    }
  }, [])

  useEffect(() => {
    if (maxDeadlineTimeDays && values.response_deadline_days > maxDeadlineTimeDays) {
      values.response_deadline_days = maxDeadlineTimeDays
    }
  }, [maxDeadlineTimeDays])

  return (
    <>
      <Item>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="engagement.form.basic.general.title"
              defaultMessage="General"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.form.basic.general.description"
              defaultMessage="Define the name, write an instruction and select the owner for this survey"
            />
          </Item.Description>
          <Box pt="s-8">
            <InputGroup>
              <LapeNewInput label="Survey name" name="title" required />
              <LapeHTMLEditor
                greyBg
                required
                name="instructions"
                placeholder="Instructions"
                message="This will appear before employees start answering questions."
                height={250}
              />
              <LapeRadioSelectInput
                required
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
              />
            </InputGroup>
          </Box>
        </Item.Content>
      </Item>

      <Item>
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="engagement.form.basic.timing.title"
              defaultMessage="Timing"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.form.basic.timing.description"
              defaultMessage="Consider when and how often you want to run this survey"
            />
          </Item.Description>
          <Box pt="s-8">
            <InputGroup>
              <ScheduleWidget
                width="100%"
                selector={selectorKeys.engagement_survey_recurrence}
              />
            </InputGroup>
          </Box>
        </Item.Content>
      </Item>
      <Item>
        <Item.Content>
          <Item.Title as={HStack} gap="s-8">
            <Text>Response deadline</Text>
            {values.round_status?.id === Statuses.running && (
              <Tooltip
                placement="top"
                text="Response deadline cannot be changed while the survey is running"
              >
                <Icon name="Info" size="s-16" />
              </Tooltip>
            )}
          </Item.Title>
          <Item.Description>
            Maximum number of days to answer all the questions in the survey
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <HStack space="s-16" align="center">
            <ActionButton
              useIcon="Minus"
              disabled={
                !values.response_deadline_days ||
                values.round_status?.id === Statuses.running
              }
              onClick={() => {
                if (values.response_deadline_days > 0) {
                  values.response_deadline_days -= 1
                }
              }}
            />
            <Text color={Token.color.blue} fontWeight={500}>
              {pluralize('day', values.response_deadline_days, true)}
            </Text>

            <ActionButton
              useIcon="Plus"
              disabled={
                values.round_status?.id === Statuses.running ||
                (maxDeadlineTimeDays &&
                  values.response_deadline_days >= maxDeadlineTimeDays)
              }
              onClick={() => {
                if (!maxDeadlineTimeDays) {
                  values.response_deadline_days += 1
                } else if (values.response_deadline_days < maxDeadlineTimeDays) {
                  values.response_deadline_days += 1
                }
              }}
            />
          </HStack>
        </Item.Side>
      </Item>
    </>
  )
}
