import React from 'react'
import { InputGroup, Side } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import AutoStepper from '@components/Stepper/AutoStepper'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

export const validator = {}

export interface Props {
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
  refreshData?: () => void
}

const BankDetails = ({ dynamicGroups, isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS}
            dynamicGroups={dynamicGroups}
          >
            <InputGroup>
              <LapeNewInput name="bank_name" label="Bank name" required />
              <LapeNewInput
                name="account_name"
                label="Name on account"
                message="Your name as it appears on your bank statements"
                required
              />
              <LapeRadioSelectInput
                name="bank_currency"
                selector={selectorKeys.currencies}
                label="Bank currency"
                placement="top"
              />
            </InputGroup>
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>
      <ActionsWrapper>
        <NewSaveButtonWithPopup
          useValidator
          successText="Bank details successfully updated."
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default BankDetails
