import React from 'react'
import {
  InterviewScorecardTemplateInterface,
  InterviewScorecardSectionQuestionType,
} from '@src/interfaces/interviewScorecardTemplates'
import OptionSelect from '@src/pages/Forms/InterviewScorecardTemplate/Sections/Option/OptionSection'
import { Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'

type LevelId =
  | 'basic_points'
  | 'intermediate_points'
  | 'advanced_points'
  | 'expert_points'

const LevelsOutcome = () => {
  const { values } = useLapeContext<InterviewScorecardTemplateInterface>()

  const options: InterviewScorecardSectionQuestionType<LevelId>[] = [
    {
      id: 'basic_points',
      points: values.basic_points,
      text: 'Basic',
    },
    {
      id: 'intermediate_points',
      points: values.intermediate_points,
      text: 'Intermediate',
    },
    {
      id: 'advanced_points',
      points: values.advanced_points,
      text: 'Advanced',
    },
    {
      id: 'expert_points',
      points: values.expert_points,
      text: 'Expert',
    },
  ]

  return (
    <Box>
      <OptionSelect<LevelId>
        value={options}
        onChange={val => {
          val.forEach(item => {
            const points = item.points === undefined ? 0 : +item.points

            switch (item.id) {
              case 'basic_points':
                values.basic_points = points
                break

              case 'intermediate_points':
                values.intermediate_points = points
                break

              case 'advanced_points':
                values.advanced_points = points
                break

              case 'expert_points':
                values.expert_points = points
                break
            }
          })
        }}
        type="radio"
        optionsKey="options"
        valueDisabled
        deleteDisabled
      />
    </Box>
  )
}

export default connect(LevelsOutcome)
