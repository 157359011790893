import React from 'react'
import Form from '@src/features/Form/Form'
import { groupAccessRequest } from '@src/api/accessManagement'
import { PageWrapper } from '@components/Page/Page'
import { Switch, Route } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import Preview from '@src/pages/Forms/RequestAccess/Preview'
import General from '@src/pages/Forms/RequestAccess/General'

const RequestAccess = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.GROUP_ACCESS_REQUEST.VIEW}>
          <Preview />
        </Route>
        <Route exact path={ROUTES.FORMS.GROUP_ACCESS_REQUEST.GENERAL}>
          <General />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={groupAccessRequest}>
    <RequestAccess />
  </Form>
))
