import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  BaseBulkActionsInterface,
  CandidateBulkArchiveInterface,
  CandidateBulkEditInterface,
  CandidateBulkEmailsInterface,
  CandidateBulkMoveStageInterface,
  CandidateBulkSnoozeInterface,
} from '@src/interfaces/hiringProccess'
import { Text, Token } from '@revolut/ui-kit'
import { upperFirst } from 'lodash'
import { MultipleEmployees } from '@src/components/ColumnInserts/MultipleEmployees/MultipleEmployees'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'

export const bulkActionsTriggeredByColumn: ColumnInterface<BaseBulkActionsInterface> = {
  type: CellTypes.insert,
  idPoint: 'request_employee',
  dataPoint: 'request_employee',
  sortKey: 'request_employee__full_name',
  filterKey: 'request_employee',
  selectorsKey: selectorKeys.employee,
  title: 'Triggered by',
  insert: ({ data }) => <Table.EmployeeCell employee={data.request_employee} size={40} />,
}

export const bulkActionsCreateDateTimeColumn: ColumnInterface<BaseBulkActionsInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    filterType: FilterType.date,
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const bulkActionsCompleteDateTimeColumn: ColumnInterface<BaseBulkActionsInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'completed_date_time',
    dataPoint: 'completed_date_time',
    sortKey: 'completed_date_time',
    filterKey: 'completed_date_time',
    filterType: FilterType.date,
    selectorsKey: selectorKeys.none,
    title: 'Finish date',
  }

export const bulkActionsItemsCountColumn: ColumnInterface<BaseBulkActionsInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidate_ids',
  dataPoint: 'candidate_ids',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Count',
  insert: ({ data }) => <Text>{data.candidate_ids.length}</Text>,
}

export const bulkActionsFailedItemsCountColumn: ColumnInterface<BaseBulkActionsInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'failed_candidate_ids',
    dataPoint: 'failed_candidate_ids',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Failed count',
    insert: ({ data }) => (
      <Text color={data.failed_candidate_ids.length ? Token.color.red : undefined}>
        {data.failed_candidate_ids.length}
      </Text>
    ),
  }

export const bulkActionsStatusColumn: ColumnInterface<BaseBulkActionsInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.candidate_bulk_action_statuses,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status}
      variant={getDefaultStatusVariant(data.status)}
    />
  ),
}

export const bulkActionsActionsColumn: ColumnInterface<BaseBulkActionsInterface> = {
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
}

export const bulkActionsEmailSubjectColumn: ColumnInterface<CandidateBulkEmailsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'subject',
    dataPoint: 'subject',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Email subject',
  }

export const bulkActionsEmailBodyColumn: ColumnInterface<CandidateBulkEmailsInterface> = {
  type: CellTypes.insert,
  idPoint: 'email_body',
  dataPoint: 'email_body',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email body',
}

export const bulkActionsEmailTemplateColumn: ColumnInterface<CandidateBulkEmailsInterface> =
  {
    type: CellTypes.text,
    idPoint: 'email_template.id',
    dataPoint: 'email_template.name',
    sortKey: 'email_template__id',
    filterKey: 'email_template__name',
    selectorsKey: selectorKeys.email_templates,
    title: 'Email template',
  }

export const bulkActionsArchiveAllRoundsColumn: ColumnInterface<CandidateBulkArchiveInterface> =
  {
    type: CellTypes.text,
    idPoint: 'archive_all_rounds',
    dataPoint: 'archive_all_rounds',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Archive all rounds',
  }

export const bulkActionsArchiveReasonColumn: ColumnInterface<CandidateBulkArchiveInterface> =
  {
    type: CellTypes.text,
    idPoint: 'archived_reason.id',
    dataPoint: 'archived_reason.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Archived reason',
  }

export const bulkActionsArchiveCommentColumn: ColumnInterface<CandidateBulkArchiveInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'archived_comment',
    dataPoint: 'archived_comment',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Archived comment',
    insert: ({ data }) => data.archived_comment || '-',
  }

export const bulkActionsEditFieldsColumn: ColumnInterface<CandidateBulkEditInterface> = {
  type: CellTypes.insert,
  idPoint: 'edit_fields',
  dataPoint: 'edit_fields',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Edit fields',
  insert: ({ data }) => (data.edit_fields ? JSON.stringify(data.edit_fields) : '-'),
}

export const bulkActionsStageIdColumn: ColumnInterface<CandidateBulkMoveStageInterface> =
  {
    type: CellTypes.text,
    idPoint: 'stage_hiring_stage_id',
    dataPoint: 'stage_hiring_stage_id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Stage id',
  }

export const bulkActionsStageTypeColumn: ColumnInterface<CandidateBulkMoveStageInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'stage_hiring_stage_type',
    dataPoint: 'stage_hiring_stage_type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Stage type',
    insert: ({ data }) =>
      data.stage_hiring_stage_type
        ? upperFirst(data.stage_hiring_stage_type.replaceAll('_', ' '))
        : '-',
  }

export const bulkActionsSnoozeUntilColumn: ColumnInterface<CandidateBulkSnoozeInterface> =
  {
    type: CellTypes.date,
    idPoint: 'snooze_until',
    dataPoint: 'snooze_until',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Snoozed until',
  }

export const bulkActionsSnoozeNotifyUsersColumn: ColumnInterface<CandidateBulkSnoozeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'notify_employees',
    dataPoint: 'notify_employees',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Notify when unsnoozed',
    insert: ({ data }) =>
      data?.notify_employees ? (
        <MultipleEmployees maxEmployees={3} employees={data.notify_employees} />
      ) : null,
  }

export const bulkActionsSnoozeCommentColumn: ColumnInterface<CandidateBulkSnoozeInterface> =
  {
    type: CellTypes.text,
    idPoint: 'comment',
    dataPoint: 'comment',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Comment',
  }
