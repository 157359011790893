import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { contractTypesRequests } from '@src/api/contracts'

const ContractTypeForm = () => {
  const params = useParams<{ id?: string }>()

  return (
    <PageWrapper>
      <PageHeader title="Contract type" backUrl={ROUTES.APPS.CONTRACTS.TYPES} />

      <PageBody>
        <SettingsButtons mb="s-16">
          <DeleteButton
            backUrl={ROUTES.APPS.CONTRACTS.TYPES}
            globalPermissions={[PermissionTypes.DeleteContractType]}
            deleteApi={contractTypesRequests.delete!}
            title="contract type"
          />
        </SettingsButtons>

        <InputGroup>
          <LapeNewInput label="Name" name="name" required />
          <LapeRadioSelectInput
            required
            name="employee_type"
            label="Employee type"
            selector={selectorKeys.employee_types}
            searchable={false}
          />
          <LapeNewInput label="Description" name="description" />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={data => {
            if (!params.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.CONTRACT_TYPE.GENERAL, { id: data.id }),
              )
            }
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export const ContractType = connect(() => (
  <Form api={contractTypesRequests}>
    <ContractTypeForm />
  </Form>
))
