import React from 'react'
import { SeniorityInterface } from '@src/interfaces/seniority'
import useSenioritySelector from '@src/features/SenioritySelector/useSenioritySelector'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export interface SenioritySelectorProps
  extends Omit<RadioSelectInputProps<SeniorityInterface>, 'options' | 'selector'> {
  specialisationId: number | string | null
}

const SenioritySelector = ({
  specialisationId,
  value,
  ...props
}: SenioritySelectorProps) => {
  const { isSeniorityValid, autocompleteProps } = useSenioritySelector(
    specialisationId,
    value || null,
  )

  return (
    <RadioSelectInput
      {...autocompleteProps}
      {...props}
      value={isSeniorityValid ? value : undefined}
    />
  )
}

export default SenioritySelector
