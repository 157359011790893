import React from 'react'
import {
  Avatar,
  Cell,
  ExpandableCell,
  Header,
  Item,
  Layout,
  Side,
  Text,
  Token,
} from '@revolut/ui-kit'
import { css } from 'styled-components'

import { SUPPORT_REVOLUT_PEOPLE } from '@src/constants/emails'
import { QuestionAnswerInterface } from './config'

const ExpandableCellNoteCss = css`
  ol,
  ul {
    padding-inline-start: 16px;
  }
`

interface SetupGuideSidebarProps {
  open: boolean
  onClose: () => void
  title: React.ReactNode
  questionsAnswers?: QuestionAnswerInterface[]
  description?: React.ReactNode
  customContent?: React.ReactNode
}

export const SetupGuideSidebar = ({
  title,
  description,
  questionsAnswers,
  open,
  onClose,
  customContent,
}: SetupGuideSidebarProps) => {
  return (
    <Layout.SideFill>
      <Side open={open} onClose={onClose} variant="wide" resizable>
        <Header variant="item">
          <Header.CloseButton aria-label="Close" />
          <Header.Title>{title || 'Setup Guide'}</Header.Title>
          {description ? <Header.Description>{description}</Header.Description> : null}
        </Header>

        {customContent}

        {questionsAnswers ? (
          <Cell p="0" flexDirection="column" alignItems="start">
            {questionsAnswers.map(({ question, answer }, index) => (
              <ExpandableCell
                prefersExpanded={index === 0}
                key={question}
                style={{ width: '100%' }}
              >
                <ExpandableCell.Title>
                  <Text color={Token.color.foreground}>{question}</Text>
                </ExpandableCell.Title>
                <ExpandableCell.Note>
                  <Text color={Token.color.greyTone50} css={ExpandableCellNoteCss}>
                    {answer}
                  </Text>
                </ExpandableCell.Note>
              </ExpandableCell>
            ))}
          </Cell>
        ) : null}

        <Side.Actions>
          <Item
            onClick={() => window.open(`mailto:${SUPPORT_REVOLUT_PEOPLE}`)}
            use="button"
            type="button"
            variant="disclosure"
          >
            <Item.Avatar>
              <Avatar useIcon="Envelope" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Contact us</Item.Title>
              <Item.Description>{SUPPORT_REVOLUT_PEOPLE}</Item.Description>
            </Item.Content>
          </Item>
        </Side.Actions>
      </Side>
    </Layout.SideFill>
  )
}
