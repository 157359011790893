import React, { useEffect, useMemo, useState } from 'react'
import { Box, HStack, Flex, Popup } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import {
  getPerformanceChartSkills,
  getPerformanceTimelineChart,
  getPerformanceTimelineChartSkill,
} from '@src/api/performance'
import { Queries } from '@src/constants/api'
import {
  performanceDeliverablesRatingColumn,
  performanceFeedbackColumn,
  performanceGradeColumn,
  performanceSkillsRatingColumn,
  performanceTimelineNameColumn,
  performanceTimelineReviewersColumn,
  performanceTimelineSeniorityColumn,
  performanceTimelineSpecialisationColumn,
  performanceValuesRatingColumn,
} from '@src/constants/columns/performance'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Chart } from '@components/Charts/EmployeePerformanceChart/EmployeePerformanceChart'
import FilterButtonRadioSelect from '@components/FilterButtonRadioSelect/FilterButtonRadioSelect'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableWrapper } from '@components/Table/TableWrapper'
import { Statuses } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceTimelineChartSingleData,
  PerformanceTimelineInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { ChartTableTabs } from '@src/features/TabBarSwitcher/useChartTableSwitcher'
import { pathToUrl } from '@src/utils/router'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import SummarySidebar from '../Summary/SummarySidebar'
import { HeaderStats } from './HeaderStats'
import {
  ToggleSecondaryTabs,
  usePerformanceChartData,
  usePerformanceTimelineTable,
  useTableSwitchers,
} from './hooks'

interface PerformanceTimelineProps {
  data: EmployeeInterface
}

export type SidebarDetails = {
  cycleId: string
  cycleName: string
  category: ReviewCategory
  employeeSeniorityId?: number
}

export const ROW = (
  onMessageClick: (data: PerformanceTimelineInterface) => void,
): RowInterface<PerformanceTimelineInterface> => ({
  isOpen: rowData => rowData.status === Statuses.ongoing,
  disabled: rowData => {
    if ('children' in rowData) {
      return rowData.status === Statuses.expired
    }

    return rowData.status === Statuses.rejected
  },
  noChildrenRequest: true,
  cells: [
    {
      ...performanceTimelineNameColumn,
      width: 140,
    },
    {
      ...performanceGradeColumn,
      width: 100,
    },
    {
      ...performanceTimelineSeniorityColumn,
      width: 100,
    },
    {
      ...performanceTimelineSpecialisationColumn,
      width: 100,
    },
    {
      ...performanceTimelineReviewersColumn,
      width: 100,
    },
    {
      ...performanceFeedbackColumn(onMessageClick),
      width: 100,
    },
    {
      ...performanceDeliverablesRatingColumn,
      width: 100,
    },
    {
      ...performanceSkillsRatingColumn,
      width: 100,
    },
    {
      ...performanceValuesRatingColumn,
      width: 100,
    },
  ],
})

export const PerformanceHistory = ({ data }: PerformanceTimelineProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [sidebarDetails, setSidebarDetails] = useState<SidebarDetails>()

  const [skillsOptions, setSkillsOptions] = useState<OptionInterface[]>([])
  const [skillsFilter, setSkillsFilter] = useState<OptionInterface>()
  const { changeQueryParam, deleteQueryParam } = useQuery()

  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [popupDetails, setPopupDetails] = useState<PerformanceTimelineInterface>()

  const { mappedChartData, setChartData } = usePerformanceChartData(data)
  const table = usePerformanceTimelineTable(data)

  const { data: performanceSettings } = useGetPerformanceSettings()
  const { tabBar, currentTab, secondaryTabBar, currentSecondaryTab } =
    useTableSwitchers(performanceSettings)

  const onMessageClick = (notesData: PerformanceTimelineInterface) => {
    setPopupDetails(notesData)
    setIsPopupOpen(true)
  }

  const rowDefinition = useMemo(() => {
    return {
      ...ROW(onMessageClick),
      cells: ROW(onMessageClick).cells.filter(cell => {
        switch (cell.title) {
          case 'Skills':
            return performanceSettings?.enable_skill_assessment
          case 'Values':
            return performanceSettings?.enable_values
          default:
            return true
        }
      }),
    }
  }, [performanceSettings?.enable_values, performanceSettings?.enable_values])

  const handleSkillsChange = (skills: PerformanceTimelineChartSingleData) => {
    setChartData(prevData => ({
      ...prevData,
      skills: {
        ...prevData.skills,
        ...skills,
      },
    }))
  }

  useEffect(() => {
    const fetchSkillsOptions = async () => {
      const result = await getPerformanceChartSkills(data.id)
      if (result?.data) {
        setSkillsOptions(result.data)
      }
    }
    fetchSkillsOptions()
  }, [])

  useEffect(() => {
    if (skillsFilter?.id === undefined) {
      const fetchSkillsChartData = async () => {
        const result = await getPerformanceTimelineChart(data.id)
        if (result?.data?.skills) {
          handleSkillsChange(result.data.skills)
        }
      }
      fetchSkillsChartData()
      return
    }

    const fetchSkillChartData = async () => {
      const result = await getPerformanceTimelineChartSkill(data.id, skillsFilter.id)
      if (result?.data?.skills) {
        handleSkillsChange(result.data.skills)
      }
    }

    fetchSkillChartData()
  }, [skillsFilter])

  const handleRowClick = (rowData: PerformanceTimelineInterface) => {
    const { category, cycle, employee_seniority, reviewers, scorecard, status, type } =
      rowData

    if (type === 'pip' || category === ReviewCategory.PIP) {
      const requestQuery = scorecard?.id ? `&${Queries.EditingId}=${scorecard.id}` : ''
      return navigateTo(
        `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: data.id,
        })}?${Queries.CycleId}=${cycle.id}${requestQuery}`,
      )
    }

    setSidebarDetails(prevData => ({
      ...prevData,
      category,
      cycleId: String(cycle.id),
      cycleName: cycle.name,
      employeeSeniorityId: employee_seniority?.id,
    }))

    if (type === 'review' && status === Statuses.completed) {
      const reviewerId = reviewers[0]?.id
      if (reviewerId) {
        changeQueryParam(Queries.ReviewerId, `${reviewerId}`)
      }
    } else {
      deleteQueryParam(Queries.ReviewerId)
    }

    return setSidebarOpen(true)
  }

  const getChartData = (tab?: ToggleSecondaryTabs) => {
    switch (tab) {
      case ToggleSecondaryTabs.Deliverables:
        return mappedChartData.deliverables
      case ToggleSecondaryTabs.Skills:
        return mappedChartData.skills
      default:
        return mappedChartData.grades
    }
  }

  return (
    <>
      <TableWrapper mb="s-20">
        <HeaderStats
          cycleName={table.tableStats?.cycle?.name}
          deliverablesRating={table.tableStats?.latest_deliverables_rating}
          grade={table.tableStats?.latest_grade}
          skillsRating={table.tableStats?.latest_skills_rating}
        />
        <HStack gap="s-12" mb="s-20">
          {tabBar}
          {secondaryTabBar}
          {currentTab === ChartTableTabs.Chart &&
            currentSecondaryTab === ToggleSecondaryTabs.Skills && (
              <FilterButtonRadioSelect
                label="Filters"
                onChange={setSkillsFilter}
                options={skillsOptions}
                value={skillsFilter || null}
              />
            )}
        </HStack>
        {currentTab === ChartTableTabs.Table && (
          <AdjustableTable
            expandableType="chevron"
            name={TableNames.EmployeePerformanceTimeline}
            noDataMessage="Performance timeline will appear here"
            onRowClick={rowData => handleRowClick(rowData)}
            row={rowDefinition}
            useWindowScroll
            {...table.table!}
          />
        )}
        {currentTab === ChartTableTabs.Chart && (
          <Flex flex="1 0" flexDirection="column">
            <Box height={400}>
              <Chart
                data={getChartData(currentSecondaryTab)}
                id={data.id}
                isRating={currentSecondaryTab !== ToggleSecondaryTabs.Grades}
              />
            </Box>
          </Flex>
        )}
      </TableWrapper>
      <SummarySidebar
        employeeId={data.id}
        employeeSeniorityId={data.seniority?.id}
        isOpen={Boolean(sidebarOpen && sidebarDetails)}
        hideCategoryFilter
        onClose={() => {
          setSidebarOpen(false)
          deleteQueryParam(Queries.ReviewerId)
        }}
        predefinedReviewer
        {...sidebarDetails}
      />
      {isPopupOpen && (
        <Popup
          onClose={() => setIsPopupOpen(false)}
          open={Boolean(isPopupOpen && popupDetails)}
          variant="bottom-sheet"
        >
          <OverallFeedback
            avatar={popupDetails?.reviewers?.[0].avatar}
            title={popupDetails?.reviewers?.[0].full_name}
            subtitle={`Relation: ${popupDetails?.stage}`}
            name={popupDetails?.reviewers?.[0].display_name}
            reviewerId={popupDetails?.reviewers?.[0].id}
            pros={popupDetails?.overall_feedback?.pros}
            cons={popupDetails?.overall_feedback?.cons}
            isSidebar
            disabled
          />
        </Popup>
      )}
    </>
  )
}
