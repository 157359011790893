import { useState } from 'react'
import { QueryObserverResult } from 'react-query'

import { RightToWorkInterface } from '@src/interfaces/employees'
import { useGetEmployeeRightsToWork } from '@src/api/employees'

export const useEmployeeRightToWorkWidget = (employeeId: number | string | undefined) => {
  const [loadData, setLoadData] = useState(false)
  const {
    data: rightsToWorkData,
    refetch,
    isRefetching,
    status,
  } = useGetEmployeeRightsToWork(loadData ? employeeId : undefined, [
    { columnName: 'status', filters: [{ id: 'complete', name: 'complete' }] },
  ])

  return {
    data: rightsToWorkData?.results?.[0],
    handler: {
      load: () => setLoadData(true),
      refetch: async () => {
        const res = await refetch()
        return {
          ...res,
          data: rightsToWorkData?.results?.[rightsToWorkData?.results.length - 1],
        } as QueryObserverResult<RightToWorkInterface, Error>
      },
      isRefetching,
      status,
      enabled: loadData,
    },
  }
}
