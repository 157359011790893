import React, { CSSProperties } from 'react'
import {
  Avatar,
  Item,
  Switch,
  SwitchProps,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types/image'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface Props extends Omit<SwitchProps, 'title' | 'onClick'> {
  avatar?: React.ReactNode
  image?: ImageProp
  onClick?: (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name?: string
  title: React.ReactNode
  description?: React.ReactNode
  tooltip?: React.ReactNode
  error?: string
  checked?: boolean
  disabled?: boolean
  transparent?: boolean // TODO: REVCOR-3046 revisit after ui-kit update
}

export const ItemSwitch = ({
  avatar,
  image,
  onClick,
  name,
  title,
  description,
  error,
  onChange,
  checked,
  disabled,
  transparent,
  tooltip: tooltipBody,
  ...props
}: Props) => {
  const tooltip = useTooltip()
  const disabledStylesOverrides: CSSProperties = {
    cursor: disabled ? 'not-allowed' : 'pointer',
  }

  return (
    <>
      <Item
        use="label"
        data-name={name}
        disabled={disabled}
        onClick={e => {
          onClick?.(e)
        }}
        style={
          transparent
            ? { ...disabledStylesOverrides, ...transparentThemeBackgroundOverrides }
            : disabledStylesOverrides
        }
        {...tooltip.getAnchorProps()}
      >
        {avatar || image ? (
          <Item.Avatar>
            {avatar || <Avatar variant="brand" size={40} image={image} />}
          </Item.Avatar>
        ) : null}

        <Item.Content style={disabledStylesOverrides}>
          <Item.Title>{title}</Item.Title>
          {(description || error) && (
            <Item.Description>
              {description}
              {error ? (
                <>
                  <br />
                  <Text color={Token.color.danger}>{error}</Text>
                </>
              ) : null}
            </Item.Description>
          )}
        </Item.Content>
        <Item.Side>
          <Switch onChange={onChange} checked={checked} disabled={disabled} {...props} />
        </Item.Side>
      </Item>
      {tooltipBody && <Tooltip {...tooltip.getTargetProps()}>{tooltipBody}</Tooltip>}
    </>
  )
}
