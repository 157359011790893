import React, { useState } from 'react'
import { ActionButtonSkeleton } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PermissionTypes } from '@src/store/auth/types'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { createDraftGoal } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectorKeys } from '@src/constants/api'
import { captureException } from '@sentry/react'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

interface Props {
  employee: EmployeeInterface
  cycleId: string
}

export const AddGoal = ({ employee, cycleId }: Props) => {
  const [pending, setPending] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )

  const handleNew = async () => {
    setPending(true)
    try {
      const response = await createDraftGoal({
        owner: { id: employee.id },
        content_type: contentTypes?.find(({ model }) => model === 'employees'),
        object_id: employee.id,
      })

      navigateTo(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
        reviewCycleId: cycleId,
        isNew: true,
      })
    } catch (err) {
      captureException(err)
      showStatusPopup({
        status: 'error',
        title: 'Failed to create a goal',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })
    } finally {
      setPending(false)
    }
  }

  if (isLoading) {
    return <ActionButtonSkeleton />
  }

  if (!employee.field_options?.permissions?.includes(PermissionTypes.CanAddGoals)) {
    return null
  }

  return (
    <PrimaryAction useIcon="Plus" pending={pending} onClick={handleNew}>
      Add goal
    </PrimaryAction>
  )
}
