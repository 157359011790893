import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { HiringTable } from '@src/pages/Recruitment/RecruitmentSubTabs/Hiring/HiringTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const StagesBankPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Stages bank"
        backUrl={pathToUrl(ROUTES.RECRUITMENT.INTERVIEWS)}
      />
      <PageBody maxWidth="100%">
        <HiringTable />
      </PageBody>
    </PageWrapper>
  )
}
