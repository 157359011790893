import React from 'react'
import { ItemSkeleton, StatusWidget, VStack } from '@revolut/ui-kit'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { CandidateMatchSidebarOpportunity } from '@src/features/CommonCandidatesTable/CandidateMatchSidebarOpportunity'
import sortBy from 'lodash/sortBy'

type Props = {
  candidateId: number
}

export const CandidateMatchSidebarOpportunities = ({ candidateId }: Props) => {
  const { data: opportunities, isLoading } = useGetFullInterviewRounds(candidateId)
  const sortedOpportunities = sortBy(
    opportunities?.filter(item => item.state !== 'archived'),
    [o => !o.active],
  )

  if (isLoading) {
    return (
      <VStack gap="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }

  return (
    <VStack gap="s-8">
      {sortedOpportunities?.length ? (
        sortedOpportunities.map(opportunity => (
          <CandidateMatchSidebarOpportunity
            opportunity={opportunity}
            key={opportunity.id}
          />
        ))
      ) : (
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images/3D086.png',
              '2x': 'https://assets.revolut.com/assets/3d-images/3D086@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images/3D086@3x.png',
            }}
          />
          <StatusWidget.Title>
            No active opportunities found for this candidate
          </StatusWidget.Title>
        </StatusWidget>
      )}
    </VStack>
  )
}
