import React, { useState } from 'react'
import { Box, Button, InputGroup, TextArea } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewRadioButtons, {
  RadioOption,
} from '@src/components/Inputs/NewRadioButtons/NewRadioButtons'
import { navigateReplace } from '@src/actions/RouterActions'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { closeAccount } from '@src/api/plans'

export const Survey = () => {
  const [option, setOption] = useState<RadioOption>()
  const [feedback, setFeedback] = useState('')
  const [submitPending, setSubmitPending] = useState(false)

  const { options, asyncState } = useFetchOptions(selectorKeys.tenant_closure_reasons)

  const otherOptionSelected = option?.value?.id === 'other'

  if (asyncState === 'pending') {
    return <PageLoading />
  }

  const onSubmit = () => {
    if (submitPending) {
      return
    }

    setSubmitPending(true)

    closeAccount({
      reason: option?.value,
      feedback,
    })
      .then(() => {
        navigateReplace(ROUTES.SETTINGS.CLOSE_ACCOUNT.CONFIRMATION)
      })
      .catch(() => {
        setSubmitPending(false)
      })
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Why do you want to leave us?"
        subtitle="Is there anything we can improve on?"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody>
        <InputGroup>
          <Box>
            <NewRadioButtons
              value={option}
              options={options}
              onChange={setOption}
              variant="group-items"
            />
          </Box>

          {otherOptionSelected ? (
            <TextArea
              label="Suggestion (optional)"
              value={feedback}
              onChange={e => setFeedback(e.currentTarget.value)}
              rows={4}
            />
          ) : null}
        </InputGroup>
      </PageBody>

      <PageActions>
        <Button onClick={onSubmit} pending={submitPending} disabled={!option} elevated>
          Continue
        </Button>
      </PageActions>
    </PageWrapper>
  )
}
