import React, { useCallback, useState } from 'react'
import { Box, DragAndDrop, Icon } from '@revolut/ui-kit'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'
import ApplicationFormPreviewSection from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormPreviewSection'
import { move } from '@src/utils/move'

interface Props {
  sections: ApplicationFormSectionInterface[]
  onChange: (sections: ApplicationFormSectionInterface[]) => void
  onEdit: (idx: number) => void
  disableEditing?: boolean
  openSectionIdx: number | null
}

export const DraggableApplicationFormSections = ({
  sections,
  onChange,
  onEdit,
  disableEditing,
  openSectionIdx,
}: Props) => {
  const [activeIdx, setActiveIdx] = useState<number | null>(null)

  const onDragStart = useCallback(
    event =>
      setActiveIdx(sections.findIndex(section => section.sortId === event.active.id)),
    [sections],
  )
  const onDragCancel = useCallback(() => setActiveIdx(null), [])

  const onDragEnd = useCallback(
    event => {
      if (event.over) {
        const startIndex = event.active.data.current.sortable.index
        const endIndex = event.over.data.current.sortable.index
        onChange(
          startIndex === endIndex ? [...sections] : move(sections, startIndex, endIndex),
        )
      }

      setActiveIdx(null)
    },
    [sections],
  )

  const noSortable = disableEditing || sections.length < 2

  return (
    <DragAndDrop.Provider
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onDragCancel={onDragCancel}
    >
      <DragAndDrop.Sortable
        id="sortable"
        items={sections.map(section => section.sortId)}
        disabled={noSortable}
      >
        {sortable => {
          const sectionIdx = sections.findIndex(section => section.sortId === sortable.id)
          return (
            <Box
              ref={sortable.setNodeRef}
              style={{
                transform: sortable.transform
                  ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                  : undefined,
                transition: sortable.transition || 'none',
                opacity: sortable.isDragging ? 0 : undefined,
              }}
              mb="s-8"
            >
              <ApplicationFormPreviewSection
                idx={sectionIdx}
                onClickEdit={onEdit}
                sections={sections}
                onChange={onChange}
                disableEditing={disableEditing}
                scrollTo={sectionIdx === openSectionIdx}
                prefix={
                  noSortable ? null : (
                    <span {...sortable.attributes} {...sortable.listeners}>
                      <Icon name="Drag" mr="s-6" />
                    </span>
                  )
                }
              />
            </Box>
          )
        }}
      </DragAndDrop.Sortable>
      <DragAndDrop.DragOverlay>
        {activeIdx !== null && (
          <ApplicationFormPreviewSection
            idx={activeIdx}
            onClickEdit={onEdit}
            sections={sections}
            disableEditing
            prefix={<Icon name="Drag" mr="s-6" />}
          />
        )}
      </DragAndDrop.DragOverlay>
    </DragAndDrop.Provider>
  )
}
