import React from 'react'
import { Group } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionSettingsInterface } from '@src/interfaces/settings'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { requisitionSettings } from '@src/api/settings'
import { API } from '@src/constants/api'

const ApprovalSettingsForm = () => {
  const { values } = useLapeContext<RequisitionSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeRequisitionPreferences)
  return (
    <>
      <PageBody mb="s-64">
        <Group>
          <ApprovalSteps
            title="Enable Requisitions approval flow"
            description="Enable and customise an approval flow for requisitions allowing to efficiently attain stakeholder alignment. Define custom approval steps by selecting an employee, a dynamic group or specific position within an organisation with defined relationship to an item pending the approval."
            switchField="enable_approvals"
            approvalProcess="requisition_approvals"
            entity="requisition"
            disabled={disableEdit}
            formRef={values}
          />
        </Group>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.REQUISITION_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Approval settings',
    path: ROUTES.SETTINGS.REQUISITIONS.APPROVAL,
    url: ROUTES.SETTINGS.REQUISITIONS.APPROVAL,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: ApprovalSettingsForm,
  },
]

export const ApprovalSettings = () => {
  return <SettingsForm routes={routes} api={requisitionSettings} />
}
