import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { PaycyclePreview } from '@components/PaycyclePreview/PaycyclePreview'
import { PageBody } from '@components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { useEmployeePayrollData } from '@src/api/payroll'
import PageLoading from '@components/PageLoading/PageLoading'

export const Preview = () => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()
  const { data, isLoading } = useEmployeePayrollData(id, employeeId)

  if (isLoading || !data) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader title={data.employee.full_name} backUrl={ROUTES.PEOPLE.PAYROLL} />
      <PageBody>
        <PaycyclePreview data={data} />
      </PageBody>
    </PageWrapper>
  )
}
