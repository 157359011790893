import { Avatar, Color, Icon, IconName } from '@revolut/ui-kit'
import React from 'react'
import { css } from 'styled-components'
import { FinalGrade } from '@src/interfaces/performance'

const CircleStrokeWidthCss = css`
  svg circle {
    stroke-width: 10px;
  }
`

interface GradeCircleProps {
  grade: FinalGrade
  progressColor: string
  avatarColor: string
  periodName: string | undefined
  gradeSettings: Record<
    FinalGrade,
    { title?: string; color: Color; icon: IconName | undefined }
  >
}

const GradeCircle = ({
  grade,
  progressColor,
  avatarColor,
  periodName,
  gradeSettings,
}: GradeCircleProps) => {
  return (
    <Avatar
      css={CircleStrokeWidthCss}
      size={76}
      progress={1}
      progressColor={progressColor}
      color={avatarColor}
      textStyle="heading2"
      useIcon={periodName ? undefined : 'Trophy'}
    >
      {periodName}
      {gradeSettings?.[grade]?.icon && (
        <Avatar.Badge
          bg={gradeSettings?.[grade]?.color}
          position="top-right"
          useIcon={
            gradeSettings?.[grade]?.icon ? (
              <Icon name={gradeSettings?.[grade].icon} size={20} />
            ) : null
          }
          size={24}
        />
      )}
    </Avatar>
  )
}

export default GradeCircle
