import React from 'react'
import { employeeLaptopDeliveryRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import LaptopDeliveryForm, { Props } from './General'

const LaptopDelivery = (props: Props) => {
  return (
    <Form api={employeeLaptopDeliveryRequests}>
      <LaptopDeliveryForm {...props} />
    </Form>
  )
}

export default LaptopDelivery
