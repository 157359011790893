import { useGetCompanyLogo } from '@src/api/settings'
import { Avatar, AvatarSize, AvatarSkeleton, Icon, Token } from '@revolut/ui-kit'
import React from 'react'
import { AvatarType } from '@src/interfaces/employees'
import { getAvatarUrl } from '@src/utils/employees'

interface CompanyAvatarProps {
  size?: AvatarSize
  userAvatar?: AvatarType
  userInitials?: string
}

export const CompanyAvatar = ({
  size = 32,
  userAvatar,
  userInitials,
}: CompanyAvatarProps) => {
  const { data, isLoading } = useGetCompanyLogo()

  if (isLoading) {
    return <AvatarSkeleton size={size} />
  }
  return data?.url ? (
    <Avatar image={data?.url} size={size}>
      <Avatar.Badge image={getAvatarUrl(userAvatar)}>{userInitials || ''}</Avatar.Badge>
    </Avatar>
  ) : (
    <Avatar useIcon={<Icon name="LogoRevolut" color={Token.color.white} />} />
  )
}
