import React, { useState } from 'react'
import {
  Button,
  Cell,
  DateInput,
  Header,
  InputGroup,
  Popup,
  Radio,
  RadioGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { ExportReportInterface, ReportTypes } from '@src/interfaces/payroll'
import { getReportExportHandler, getReportFtpUploadHandler } from '@src/api/payroll'
import { getDate } from '@src/utils/timezones'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getStringMessageFromError } from '@src/store/notifications/actions'

type ExportOptions = 'download' | 'upload'

type CommonPopupProps = {
  payrollProvider: string
}

export const useExportOptionsPopup = ({
  payrollProvider,
  onClickNext,
  onClickCancel,
}: { onClickNext: VoidFunction; onClickCancel: VoidFunction } & CommonPopupProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [exportOption, setExportOption] = useState<ExportOptions>()

  return {
    exportOption,
    setExportOption,
    showPopup: () => setIsOpen(true),
    popup: (
      <Popup open={isOpen} onClose={() => setIsOpen(false)} variant="bottom-sheet">
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>How would you like to send the file?</Header.Title>
        </Header>
        <InputGroup>
          <RadioGroup<ExportOptions>
            onChange={value => {
              if (value) {
                setExportOption(value)
              }
            }}
            value={exportOption}
          >
            {group => (
              <>
                <Cell>
                  <Radio {...group.getInputProps({ value: 'download' })} defaultChecked>
                    <Radio.Label>Download the file</Radio.Label>
                    <Radio.Description>
                      File will be downloaded manually.
                    </Radio.Description>
                  </Radio>
                </Cell>
                <Cell>
                  <Radio
                    {...group.getInputProps({ value: 'upload' })}
                    disabled={payrollProvider === 'adp_g2'}
                  >
                    <Radio.Label>Send file to provider</Radio.Label>
                    <Radio.Description>
                      File will automatically be sent to provider
                    </Radio.Description>
                  </Radio>
                </Cell>
              </>
            )}
          </RadioGroup>
        </InputGroup>
        <Popup.Actions horizontal>
          <Button
            onClick={() => {
              setIsOpen(false)
              onClickCancel()
            }}
            variant="secondary"
          >
            Cancel
          </Button>
          <Button elevated disabled={!exportOption} onClick={onClickNext}>
            Next
          </Button>
        </Popup.Actions>
      </Popup>
    ),
  }
}

export const useReportPeriodPopup = ({
  isOpen,
  setIsOpen,
  payrollProvider,
  reportType,
  exportOption,
  payCycleId,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  exportOption: ExportOptions | undefined
  payCycleId: number | string
  reportType: ReportTypes | undefined
} & CommonPopupProps) => {
  const [pending, setPending] = useState(false)
  const [selectDates, setSelectDates] = useState(false)
  const [endDate, setEndDate] = useState<Date | null>()
  const [startDate, setStartDate] = useState<Date | null>()

  const statusPopup = useStatusPopup()

  const createExportHandler = async (
    handler: (data: ExportReportInterface) => ReturnType<typeof getReportExportHandler>,
  ) => {
    const reportData = {
      export_provider: payrollProvider,
      report_type: reportType,
      ...(selectDates && {
        from_date: startDate ? getDate(startDate) : undefined,
        to_date: endDate ? getDate(endDate) : undefined,
      }),
    }

    try {
      setPending(true)
      const result = await handler(reportData)

      navigateTo(
        pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORT, {
          id: payCycleId,
          requestId: result.data.id,
          action: exportOption,
        }),
      )
    } catch (e) {
      const errorMsg = getStringMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to create report</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
          <StatusPopup.Actions>
            <Button elevated onClick={() => statusPopup.hide()}>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
    }
  }

  const handleExportReport = () => {
    if (exportOption === 'download') {
      createExportHandler(data => getReportExportHandler(payCycleId, data))
    } else {
      createExportHandler(data => getReportFtpUploadHandler(payCycleId, data))
    }
  }

  return {
    showPopup: () => setIsOpen(true),
    popup: (
      <Popup
        data-testid="select_report_period_popup"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        variant="bottom-sheet"
      >
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>Which period should be sent?</Header.Title>
        </Header>
        <InputGroup>
          <RadioGroup
            onChange={value => {
              if (value !== null) {
                setSelectDates(value)
              }
            }}
            value={selectDates}
          >
            {group => (
              <>
                <Cell>
                  <Radio {...group.getInputProps({ value: false })} defaultChecked>
                    <Radio.Label>Whole paycycle</Radio.Label>
                    <Radio.Description>
                      Dates from pay cycle will be sent
                    </Radio.Description>
                  </Radio>
                </Cell>
                <Cell>
                  <Radio {...group.getInputProps({ value: true })}>
                    <Radio.Label>Specific dates within paycycle</Radio.Label>
                    <Radio.Description>
                      Send specific dates from within paycycle
                    </Radio.Description>
                  </Radio>
                </Cell>
              </>
            )}
          </RadioGroup>
          {selectDates && (
            <InputGroup variant="horizontal">
              <DateInput
                clearable
                label="Start date"
                onChange={value => setStartDate(value)}
                value={startDate}
              />
              <DateInput
                clearable
                label="End date"
                onChange={value => setEndDate(value)}
                value={endDate}
              />
            </InputGroup>
          )}
        </InputGroup>
        <Popup.Actions horizontal>
          <Button onClick={() => setIsOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button
            elevated
            disabled={selectDates && !(startDate && endDate)}
            onClick={handleExportReport}
            pending={pending}
          >
            Next
          </Button>
        </Popup.Actions>
      </Popup>
    ),
  }
}
