import React from 'react'
import { Action, Flex, StatusWidget } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'

import Lock from '@src/assets/3D/Lock@2x.png'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { getErrorMessageFromError } from '@src/store/notifications/actions'
import { ERRORS } from '@src/constants/notifications'

interface FormErrorGuardProps {
  error?: any
  notFoundMessage?: string
}

interface ErrorProps {
  title: string
  image: string
  description?: React.ReactNode
  children?: React.ReactNode
}

export const NoAccessError = ({
  message,
  description,
  children,
}: { message?: string } & Pick<ErrorProps, 'description' | 'children'>) => (
  <Error
    title={message || "You don't have the permission to view this page"}
    image={Lock}
    description={description}
  >
    {children}
  </Error>
)

export const GenericError = () => (
  <Error
    image="https://assets.revolut.com/assets/3d-images/3D087@2x.png"
    title={ERRORS.UNKNOWN}
  />
)

export const NotFoundError = ({
  message = "The page you're looking for could not be found",
}: {
  message?: string
}) => (
  <Error
    title={message}
    image="https://assets.revolut.com/assets/3d-images/3D087@2x.png"
  />
)

export const FormErrorGuard: React.FC<FormErrorGuardProps> = ({
  error,
  children,
  notFoundMessage,
}) => {
  const responseStatus = error?.response?.status

  // Show generic error state for all server errors
  if (responseStatus >= 500 && responseStatus < 600) {
    Sentry.captureException(error)
    return <GenericError />
  }

  if (responseStatus === 403) {
    return <NoAccessError message={getErrorMessageFromError(error)} />
  }

  if (responseStatus === 404) {
    return <NotFoundError message={notFoundMessage} />
  }

  return <>{children}</>
}

export const Error = ({ title, image, description, children }: ErrorProps) => {
  return (
    <Flex height="100%" alignItems="center" justifyContent="center">
      <StatusWidget>
        <StatusWidget.Image src={image} />
        <StatusWidget.Title>{title}</StatusWidget.Title>
        {description && (
          <StatusWidget.Description>{description}</StatusWidget.Description>
        )}
        {children ? (
          <StatusWidget.Actions>{children}</StatusWidget.Actions>
        ) : (
          <StatusWidget.Actions onClick={() => navigateTo(ROUTES.MAIN)}>
            <Action>Go to dashboard</Action>
          </StatusWidget.Actions>
        )}
      </StatusWidget>
    </Flex>
  )
}
