import React, { useState } from 'react'
import {
  Avatar,
  Button,
  Cell,
  Header,
  Item,
  Popup,
  Text,
  VStack,
  ItemSkeleton,
  Token,
} from '@revolut/ui-kit'
import { LogoRevolut, NavigationForward } from '@revolut/icons'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import { mapAccountStatusToProps } from '@src/utils/linkedAccounts'
import { useParams } from 'react-router-dom'
import {
  linkedFormRequests,
  useEmployeeLinkedRevolutAccount,
} from '@src/api/linkedAccounts'
import { REVOLUT_ACCOUNT_ID } from '@src/pages/Forms/LinkRevolutAccountForm/constants'
import ActionWidget from '@components/ActionWidget/ActionWidget'

const getRevolutAccountPendingAction = (id?: number) =>
  ({
    id: id ?? undefined,
    account_type: {
      id: 1,
      name: 'Revolut',
    },
    status: 'pending',
  } as LinkedAccountsInterface)

type Props = {
  canAdd: boolean
  isThisUser: boolean
}
export const RevolutAccountItem = ({ canAdd, isThisUser }: Props) => {
  const { id: employeeId } = useParams<{ id: string }>()
  const { data: accountData, isLoading } = useEmployeeLinkedRevolutAccount(employeeId)

  const [isPopupOpened, setIsPopupOpened] = useState<boolean>(false)

  let revolutAccount = getRevolutAccountPendingAction()
  const existingAccounts = accountData?.results || []
  const existingRevolutAccount = existingAccounts.find(
    account => account.account_type?.id === REVOLUT_ACCOUNT_ID,
  )
  if (existingRevolutAccount) {
    revolutAccount =
      existingRevolutAccount.status === Statuses.archived
        ? getRevolutAccountPendingAction(existingRevolutAccount.id)
        : existingRevolutAccount
  }
  const { id, status, account_type, employee } = revolutAccount
  const { name } = account_type || {}
  const { statusText, statusIcon, color } = mapAccountStatusToProps(status)

  const handleClick = () => {
    if (status === Statuses.pending) {
      setIsPopupOpened(true)
    } else {
      navigateTo(
        pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, {
          employeeId: employee?.id,
          id,
        }),
      )
    }
  }
  const navigateToForm = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.GENERAL, {
        id,
        employeeId,
      }),
    )
  }
  const navigateToNoAccount = async () => {
    if (id) {
      await linkedFormRequests.update(
        {
          status: 'no_account',
        } as LinkedAccountsInterface,
        { id: String(id), employeeId },
      )
      navigateTo(
        pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, { id: String(id), employeeId }),
      )
    } else {
      const { data } = await linkedFormRequests.submit(
        {
          account_type: { id: REVOLUT_ACCOUNT_ID },
          status: 'no_account',
        } as LinkedAccountsInterface,
        { employeeId },
      )
      navigateTo(
        pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.PREVIEW, {
          id: String(data.id),
          employeeId,
        }),
      )
    }
  }

  if (isLoading) {
    return <ItemSkeleton style={{ backgroundColor: 'transparent' }} /> // TODO: REVCOR-3046 revisit after ui-kit update
  }
  if (status === Statuses.archived) {
    return null
  }
  return (
    <Cell p={0}>
      <VStack flex="1 0">
        {status === Statuses.pending && isThisUser && (
          <ActionWidget
            title="Why do I need to link accounts?"
            text="Linked accounts allow you to enable certain features that depend on other systems or applications. You can link your Revolut retail account below to obtain your free Metal membership."
            avatarColor={Token.color.greyTone20}
          />
        )}
        <Item
          use="button"
          onClick={handleClick}
          disabled={!canAdd}
          // TODO: REVCOR-3046 revisit after ui-kit update
          style={{ backgroundColor: 'transparent' }}
        >
          <Item.Avatar>
            <Avatar useIcon={LogoRevolut}>
              <Avatar.Badge useIcon={statusIcon} color="background" bg={color} />
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>{name}</Item.Title>
            <Item.Description color={color}>{statusText}</Item.Description>
          </Item.Content>
          <Item.Side>
            <NavigationForward size={16} color={Token.color.greyTone20} />
          </Item.Side>
        </Item>
        <Popup
          open={isPopupOpened}
          onClose={() => setIsPopupOpened(false)}
          variant="bottom-sheet"
        >
          <Header variant="compact" pt="s-8">
            <Header.Title>
              Do you already have a Revolut account in your name?
            </Header.Title>
          </Header>
          <VStack space="s-16">
            <Text>
              Linked accounts allow you to enable certain features that depend on other
              systems or applications. You can link your Revolut retail account below to
              obtain your free Metal membership.
            </Text>
            <Text pb="s-8">
              Even if you do not have account now and you’ll set in in future, you will
              still be able to link it here.
            </Text>
            <Button variant="primary" onClick={navigateToForm}>
              Yes, I have Revolut account
            </Button>
            <Button variant="secondary" onClick={navigateToNoAccount}>
              No, I don't have Revolut account
            </Button>
          </VStack>
        </Popup>
      </VStack>
    </Cell>
  )
}
