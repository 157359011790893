import React, { useState } from 'react'
import { AxiosResponse } from 'axios'
import pluralize from 'pluralize'
import { MoreBar, Text, Token } from '@revolut/ui-kit'

import { getPayCycleExport } from '@src/api/payroll'
import { ExportTypes } from '@src/constants/export'
import { saveFileByBlob } from '@src/utils'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'

export const ChangeReportDownload = ({
  id,
  issuesCount,
  payrollProvider,
}: {
  id: number | string
  issuesCount: number | undefined
  payrollProvider: string
}) => {
  const [loading, setLoading] = useState(false)

  const confirmationDialog = useConfirmationDialog()

  const onExportItem = () => {
    if (!loading) {
      setLoading(true)
      getPayCycleExport(id)(ExportTypes.xlsx, { report_format: payrollProvider })
        .then((response: AxiosResponse<string>) => {
          saveFileByBlob(response.data, `Changes.xlsx`, response.headers['content-type'])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  const issuesWarning = issuesCount
    ? `There ${issuesCount > 1 ? 'are' : 'is'} ${pluralize(
        'issue',
        issuesCount,
        true,
      )} in this pay cycle, do you want to continue?`
    : undefined

  const handleDownloadReport = () => {
    if (issuesWarning) {
      confirmationDialog.show({
        showHeader: false,
        label: 'Warning',
        body: (
          <Text color={Token.color.foreground} variant="emphasis1">
            {issuesWarning}
          </Text>
        ),
        yesMessage: 'Yes, download',
        noMessage: 'Go back',
        onConfirm: () => {
          confirmationDialog.hide()
          onExportItem()
        },
      })
    } else {
      onExportItem()
    }
  }

  return (
    <>
      <MoreBar.Action
        useIcon="Download"
        pending={loading}
        onClick={handleDownloadReport}
        aria-disabled={loading}
      >
        {loading
          ? 'Fetching report... This might take a few minutes'
          : 'Download change report'}
      </MoreBar.Action>
    </>
  )
}
