import { z } from 'zod'
import { formatPercentage } from '@src/utils/format'

export interface ValidationResult {
  isValid: boolean
  messages: string[]
}

export const gradeDistributionSchema = z
  .record(z.string(), z.number())
  .superRefine((obj, context) => {
    const distributionSum = Object.values(obj).reduce<number>(
      (sum, current) => Number((sum + current).toFixed(2)),
      0,
    )

    if (distributionSum !== 1) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: `The sum of all numbers needs to be 100%. Current sum is ${formatPercentage(
          distributionSum,
        )}.`,
      })
    }
  })

export const validateGradesDistribution = (
  values: Record<string, number>,
): ValidationResult => {
  const validationResult = gradeDistributionSchema.safeParse(values)

  return {
    isValid: validationResult.success,
    messages: !validationResult.success
      ? validationResult.error.issues.map(({ message }) => message)
      : [],
  }
}
