import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { ResignationPermissions } from './types'

export const useResignationPermissions = (
  resignation?: EmployeeResignationInterface,
): ResignationPermissions => {
  const permissions = useSelector(selectPermissions)
  const canView = !!resignation?.field_options?.permissions?.includes(
    PermissionTypes.ViewResignation,
  )
  const canEdit = !!resignation?.field_options?.permissions?.includes(
    PermissionTypes.ChangeResignation,
  )
  const canApprove = !!resignation?.field_options?.permissions?.includes(
    PermissionTypes.ChangeResignationApproval,
  )
  const canDelete = !!resignation?.field_options?.permissions?.includes(
    PermissionTypes.DeleteResignation,
  )
  const canComment = !!resignation?.field_options?.permissions?.includes(
    PermissionTypes.AccessResignationComments,
  )
  const canAdd = !!permissions.includes(PermissionTypes.AddResignation)

  return { canView, canEdit, canApprove, canDelete, canComment, canAdd }
}
