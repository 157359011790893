import get from 'lodash/get'
import React, { useState } from 'react'
import { MoreBar, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import { Archive, Unarchive } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { AxiosError } from 'axios'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { MutationResult } from '@src/features/SettingsButtons/types'
import MoreBarButton, {
  SettingsButtonProps,
} from '@src/features/SettingsButtons/MoreBarButton'

interface ArchiveButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  data: {
    status: Statuses
    field_options: FieldOptions
    id: number
    name?: string
  }
  dialogText?: string
  showDialog?: boolean
  statusFieldName?: string
  unArchiveStatus?: Statuses
  api: () => MutationResult
}

export const parseError = (e: AxiosError) => {
  return e.response?.data?.status?.[0] || getStringMessageFromError(e)
}

export const ArchiveButton = ({
  data,
  useMoreButton,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Archive,
  dialogText,
  showDialog,
  statusFieldName = 'status',
  unArchiveStatus = Statuses.pending,
  api,
}: ArchiveButtonProps) => {
  const { mutateAsync: updateEntity, isLoading } = api()
  const statusPopup = useStatusPopup()
  const isArchived = get(data, statusFieldName) === Statuses.archived

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const permissions = useSelector(selectPermissions)

  const canArchive =
    data.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const archive = async (status = Statuses.archived) => {
    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>Operation in progress...</StatusPopup.Title>
      </StatusPopup>,
    )
    try {
      await updateEntity([data.id, { [statusFieldName]: status }])
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            {status === Statuses.archived
              ? 'Archived successfully'
              : 'Unarchived successfully'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (e) {
      const errorMsg = parseError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed</StatusPopup.Title>
          <StatusPopup.Description>{errorMsg}</StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }

  if (!isVisible || !canArchive) {
    return null
  }

  if (isArchived) {
    return (
      <MoreBar.Action
        useIcon={Unarchive}
        pending={isLoading}
        onClick={() => archive(unArchiveStatus)}
      >
        Unarchive
      </MoreBar.Action>
    )
  }

  return (
    <>
      {showDialog ? (
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={() => {
            archive()
            setDialogOpen(false)
          }}
          loading={isLoading}
          onReject={() => setDialogOpen(false)}
          label={`Archiving${data.name ? ` "${data.name}"` : ''}`}
          body={dialogText}
          variant="compact"
        />
      ) : null}
      <MoreBarButton
        useMoreButton={useMoreButton}
        useIcon={Archive}
        variant="negative"
        pending={isLoading}
        onClick={showDialog ? () => setDialogOpen(true) : () => archive()}
      >
        Archive
      </MoreBarButton>
    </>
  )
}
