import * as React from 'react'

export const useIsOverflow = (ref: React.RefObject<HTMLElement | null>) => {
  const [isOverflow, setIsOverflow] = React.useState<boolean>()

  React.useLayoutEffect(() => {
    const { current } = ref

    const trigger = () => {
      const hasOverflow = current ? current.scrollHeight > current.clientHeight : false
      setIsOverflow(hasOverflow)
    }

    if (current) {
      new ResizeObserver(trigger).observe(current)
      trigger()
    }
  }, [ref])

  return isOverflow
}
