import React from 'react'
import { Avatar, Item, Text } from '@revolut/ui-kit'
import { OnboardingTaskInterface } from '@src/interfaces/onboardingV2'
import { getTaskColor, getTaskIcon } from '../utils'

type Props = {
  handleClick: () => void
  task: OnboardingTaskInterface
}

export const TaskItem = ({ handleClick, task }: Props) => {
  const inactive = task.status.id === 'completed' || task.status.id === 'cancelled'

  return (
    <Item onClick={inactive ? undefined : handleClick} use="button">
      <Item.Avatar>
        <Avatar useIcon="Form">
          {task.status.id !== 'not_started' && (
            <Avatar.Badge
              bg={getTaskColor(task.status.id)}
              useIcon={getTaskIcon(task.status.id)}
            />
          )}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{task.name}</Item.Title>
        <Item.Description>
          <Text color={getTaskColor(task.status.id)}>{`${task.status.name} `}</Text>
          <Text>{`Assigned to ${task.assignee.name}`}</Text>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
