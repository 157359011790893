import { useQueryClient } from 'react-query'
import { useAddGrowthPlanComment, useMarkGrowthPlanAction } from '@src/api/growthPlans'
import {
  GrowthPlanActionInterface,
  GrowthPlanInterface,
} from '@src/interfaces/growthPlans'
import { API } from '@src/constants/api'

interface Props {
  isLoading: boolean
  mark: (action: GrowthPlanActionInterface, comment: string) => Promise<void>
}

export const useMarkAction = (growthPlan: GrowthPlanInterface): Props => {
  const { mutateAsync: markAction, isLoading: isMarkingAction } = useMarkGrowthPlanAction(
    growthPlan.id,
  )
  const { mutateAsync: addComment, isLoading: isAddingComment } = useAddGrowthPlanComment(
    growthPlan.id,
  )
  const queryClient = useQueryClient()

  const mark = async (action: GrowthPlanActionInterface, comment: string) => {
    await markAction([action.id, { is_completed: !action.is_completed }])
    await addComment({ body: comment })

    queryClient.setQueryData<GrowthPlanInterface | undefined>(
      [`${API.GROWTH_PLANS}/${growthPlan.id}`, 'v1', null],
      oldData => {
        if (!oldData) {
          return undefined
        }

        return {
          ...oldData,
          actions: oldData.actions.map(item =>
            item.id === action.id
              ? { ...item, is_completed: !action.is_completed }
              : item,
          ),
        }
      },
    )
  }

  return {
    isLoading: isMarkingAction || isAddingComment,
    mark,
  }
}
