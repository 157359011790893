import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { PaygroupInterface, PaygroupStatus } from '@src/interfaces/payroll'
import { ContractingCountryInterface } from '@src/interfaces/enitities'
import Table from '@components/TableV2/Table'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'

export const paygroupName: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.active_pay_groups,
  title: 'Pay group',
}

export const paygroupHeadcount: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Headcount',
}

export const paygroupCountry: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: 'country__name',
  filterKey: 'country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}

export const paygroupEntity: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.text,
  idPoint: 'company_entity.id',
  dataPoint: 'company_entity.name',
  sortKey: 'company_entity__name',
  filterKey: 'company_entity__id',
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
}

export const paygroupPayFrequency: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.text,
  idPoint: 'pay_frequency.id',
  dataPoint: 'pay_frequency.name',
  sortKey: 'pay_frequency',
  filterKey: 'pay_frequency',
  selectorsKey: selectorKeys.pay_frequencies,
  title: 'Pay frequency',
}

export const paygroupOwner: ColumnInterface<PaygroupInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

const getStatusVariant = (status: PaygroupStatus['id']): ColorTagVariant => {
  switch (status) {
    case 'active':
      return 'success'
    case 'inactive':
      return 'warning'
    default:
      return 'neutral'
  }
}

export const paygroupStatus: ColumnInterface<
  PaygroupInterface | ContractingCountryInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.pay_groups_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getStatusVariant(data.status.id)}
    />
  ),
}
