import React from 'react'
import styled from 'styled-components'
import { DepartmentCompensationReviewInterface } from '@src/interfaces/compensation'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { formatNumberMillions } from '@src/utils/format'
import { Flex, opacity, Text, Token } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

const ContainerTooltip = styled(Tooltip)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const TooltipBody = styled.div`
  display: grid;
  padding: 8px;
  grid-gap: 4px;
`

interface Props {
  children: React.ReactNode
  data: TeamInterface | DepartmentInterface | DepartmentCompensationReviewInterface
}

export const CXTooltip = ({ children, data }: Props) => {
  return (
    <ContainerTooltip
      body={
        <TooltipBody>
          {!!data.cx_csat_detractors_last_30_days && (
            <Flex justifyContent="space-between">
              <Text color={Token.color.greyTone50} whiteSpace="nowrap">
                Support NPS
              </Text>
              <Text color={opacity(Token.colorChannel.black, 0.05)} ml="s-16" use="div">
                {formatNumberMillions(data.cx_csat_detractors_last_30_days)}
              </Text>
            </Flex>
          )}
          {!!data.cx_support_load_last_30_days && (
            <Flex justifyContent="space-between">
              <Text color={Token.color.greyTone50} whiteSpace="nowrap">
                Net Contacts
              </Text>
              <Text color={opacity(Token.colorChannel.black, 0.05)} ml="s-16" use="div">
                {formatNumberMillions(data.cx_support_load_last_30_days)}
              </Text>
            </Flex>
          )}
          {!!data.cx_complaints_last_30_days && (
            <Flex justifyContent="space-between">
              <Text color={Token.color.greyTone50} whiteSpace="nowrap">
                Compliance
              </Text>
              <Text color={opacity(Token.colorChannel.black, 0.05)} ml="s-16" use="div">
                {formatNumberMillions(data.cx_complaints_last_30_days)}
              </Text>
            </Flex>
          )}
        </TooltipBody>
      }
      placement="right"
    >
      {children}
    </ContainerTooltip>
  )
}
