import React from 'react'
import { updateJobPostingSettings, useGetJobPostingSettings } from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { FormattedMessage } from 'react-intl'
import { Avatar, Item, Switch } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const IndeedApply = () => {
  const { data: jobPostingSettings, isLoading, refetch } = useGetJobPostingSettings()
  const showStatusPopup = useShowStatusPopup()
  const handleIndeedApply = async () => {
    try {
      const enable_indeed_apply = !jobPostingSettings?.enable_indeed_apply
      showStatusPopup({
        status: 'loading',
        title: 'Updating integration settings',
        preventUserClose: true,
      })
      await updateJobPostingSettings({
        enable_indeed_apply,
      })
      refetch()
      showStatusPopup({
        title: enable_indeed_apply ? (
          <FormattedMessage
            id="integrations.indeed.settings.apply.enable.success"
            defaultMessage="Indeed apply enabled"
          />
        ) : (
          <FormattedMessage
            id="integrations.indeed.settings.apply.disable.success"
            defaultMessage="Indeed apply disabled"
          />
        ),
      })
    } catch (err) {
      showStatusPopup({
        description: getStringMessageFromError(err),
        status: 'error',
        title: (
          <FormattedMessage
            id="integrations.indeed.settings.apply.fail"
            defaultMessage="Indeed integration failed"
          />
        ),
      })
    }
  }
  return (
    <Item
      use="label"
      onClick={e => {
        e.preventDefault()
        handleIndeedApply()
      }}
    >
      <Item.Avatar>
        <Avatar useIcon="Cleaning" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <FormattedMessage
            id="integrations.indeed.settings.apply.title"
            defaultMessage="Enable Indeed apply"
          />
        </Item.Title>
        <Item.Description>
          <FormattedMessage
            id="integrations.indeed.settings.apply.description"
            defaultMessage="Candidates would be able to apply directly through Indeed using their Indeed resume"
          />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Switch disabled={isLoading} checked={jobPostingSettings?.enable_indeed_apply} />
      </Item.Side>
    </Item>
  )
}
