import React from 'react'
import { Route, useParams } from 'react-router-dom'

import { payGroupRequests } from '@src/api/payrollV2'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'

import { PayGroupEditDetails } from './Details'

export const PayGroupEdit = () => {
  const params = useParams<{ id?: string }>()

  return (
    <PageWrapper>
      <PageHeader
        title={params.id ? 'Edit paygroup' : 'New paygroup'}
        backUrl={ROUTES.APPS.PAYROLL_V2.SETTINGS.PAY_GROUPS}
      />
      <PageBody>
        <Form api={payGroupRequests}>
          <Route exact path={ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT}>
            <PayGroupEditDetails />
          </Route>
        </Form>
      </PageBody>
    </PageWrapper>
  )
}
