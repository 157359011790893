import ScorecardSection from '@src/components/ScorecardGeneral/ScorecardSection'
import React from 'react'
import styled from 'styled-components'
import SectionTitle from '../../components/SectionTitle'
import { skillExample } from './example'
import { getCard } from '@src/pages/Forms/SkillForm/ScorecardPreview'
import { PerformanceReviewTypes, ReviewCategory } from '@src/interfaces/performance'
import { SkillInterface } from '@src/interfaces/skills'

const FitScorecard = styled(ScorecardSection)`
  margin-top: -30px;
  margin-bottom: 0;
  margin-left: -16px;
  margin-right: -16px;
`

export const SkillsTab = ({ skill }: { skill?: SkillInterface }) => {
  const data = skill || skillExample
  return (
    <>
      <SectionTitle title={data.name} subtitle={data.description} />
      <FitScorecard
        skillId="0"
        cardIndex={0}
        onChange={() => {}}
        onSetResultLoading={() => {}}
        card={getCard(data)}
        type={PerformanceReviewTypes.skills}
        category={ReviewCategory.Performance}
        hideCardTitle
        hideJustification
        hideResult
        disabled
        hidePlaybookDescription
      />
    </>
  )
}
