import React from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { refreshRoadmap, roadmapsRequests } from '@src/api/roadmaps'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapProjectColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { getLocalDateTime } from '@src/utils/roadmap'
import isUndefined from 'lodash/isUndefined'
import { chain, Text, Token } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import { useGetRoadmapSettings } from '@src/api/settings'
import {
  OptionalRoadmapActions,
  RoadmapActionsBar,
  useShowRoadmapDetails,
} from '@src/features/Roadmaps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { CycleFilterButton } from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilterButton'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const ROW: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapProgressColumn,
      width: 200,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 170,
    },
    {
      ...ownerNameColumn,
      width: 180,
    },
    {
      ...roadmapProjectColumn,
      width: 120,
    },
    {
      ...roadmapJiraLabelColumn,
      width: 130,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 150,
    },
  ],
}

export const Roadmap = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { query } = useQuery()
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const jiraEnabled = roadmapSettings?.jira_epics_enabled
  const cycleOffset = query.review_cycle__offset || 0
  const { data: reviewCycle } = useGetReviewCycleByOffset(cycleOffset)
  const initialFilter = [
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'function__id',
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const table = useTable<RoadmapInterface, Stats>(
    roadmapsRequests,
    initialFilter,
    initialSortBy,
  )
  const { renderSidebar, showRoadmapDetails } = useShowRoadmapDetails({
    changelogState: false,
  })

  const triggerRefresh = async () => {
    await refreshRoadmap(
      `FUNCTION-${values.id}`,
      !isUndefined(reviewCycle) ? { id: Number(reviewCycle.id) } : undefined,
    )
  }
  const moreBarPending = !roadmapSettings || !reviewCycle

  const isNewTable = useIsNewTable()

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label={chain(
            'Progress',
            table?.stats?.refresh_date_time ? (
              <Text>
                last updated:{' '}
                <Text color={Token.color.greyTone50}>
                  {getLocalDateTime(table?.stats?.refresh_date_time)}
                </Text>
              </Text>
            ) : null,
          )}
          val={
            table?.stats?.avg_done !== undefined ? (
              <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                {formatPercentage(table?.stats?.avg_done)}
              </Text>
            ) : undefined
          }
          mr="s-32"
        />
        {!isNewTable && (
          <CycleFilter
            type={CycleFilterType.NewUI}
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        )}
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <RoadmapActionsBar pending={moreBarPending}>
          <OptionalRoadmapActions refresh={triggerRefresh} allowRefresh />
        </RoadmapActionsBar>
      </Table.Widget.Actions>
      {isNewTable && (
        <Table.Widget.Filters>
          <CycleFilterButton
            onFilterChange={table.onFilterChange}
            columnName="review_cycle__offset"
            filter={table.filterBy}
          />
        </Table.Widget.Filters>
      )}
      <Table.Widget.Table>
        <AdjustableTable<RoadmapInterface>
          name={TableNames.FunctionRoadmap}
          useWindowScroll
          row={ROW}
          onRowClick={showRoadmapDetails}
          {...table}
          dataType={jiraEnabled ? 'epic' : 'roadmap items'}
          expandableType="chevron"
          emptyState={<EmptyTableRaw title="All your roadmap items will appear here" />}
        />
        {renderSidebar({ canEdit: false })}
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Roadmap
