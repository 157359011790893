import React from 'react'
import { Box, BoxProps, Subheader, Text, Token } from '@revolut/ui-kit'

type SectionTitleProps = {
  title: React.ReactNode
  subtitle?: React.ReactNode
} & Pick<BoxProps, 'mt'>

const SectionTitle = ({ title, subtitle, mt }: SectionTitleProps) => {
  return (
    <Box mt={mt}>
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      {subtitle && (
        <Text color={Token.color.greyTone50} pb="s-16" use="div" variant="caption">
          {subtitle}
        </Text>
      )}
    </Box>
  )
}

export default SectionTitle
