import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import debounce from 'lodash/debounce'

import { useIntersectViewport } from '@revolut/ui-kit'
import { getEngagementQuestionsOptions } from '@src/api/engagement'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'
import { OptionInterface } from '@src/interfaces/selectors'
import { getFiltersToParams } from './helpers'

export const useQuestionsFilters = () => {
  const [searchValue, setSearchValue] = useState('')
  const [textFilter, setTextFilter] = useState('')
  const [driversFilter, setDriversFilter] = useState<OptionInterface[]>([])

  const filtersParams = useMemo(() => {
    return getFiltersToParams({
      textFilter,
      driversFilter,
    })
  }, [textFilter, driversFilter])

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 500), [])

  return {
    filtersParams,
    search: {
      searchValue,
      setSearchValue,
    },
    filters: {
      setTextFilter: setTextFilterDebounced,
      driversFilter,
      setDriversFilter,
    },
  }
}

export const useInfiniteQuestionsLoading = ({
  filtersParams,
}: {
  filtersParams: Object
}) => {
  const [questions, setQuestions] = useState<EngagementQuestionInterface[]>([])
  const [total, setTotal] = useState<number>()
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloadingFilters, setIsReloadingFilters] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const ref = useRef(null)

  useIntersectViewport(ref, isIntersecting => {
    if (isReloadingFilters) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const loadQuestions = (newPage = 1) => {
    setPage(newPage)

    return getEngagementQuestionsOptions({
      filters: { ...filtersParams, page: newPage },
    }).then(({ data }) => {
      const { results, pages, count } = data

      setHasMoreData(!!pages.next)
      setTotal(count)

      return results
    })
  }

  useEffect(() => {
    if (isReloadingFilters || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadQuestions(page)
      .then(newQuestions => {
        setQuestions([...questions, ...newQuestions])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [page])

  useEffect(() => {
    if (isLoadingMore) {
      return
    }
    setIsReloadingFilters(true)

    loadQuestions()
      .then(filteredQuestions => {
        setQuestions(filteredQuestions)
      })
      .finally(() => {
        setIsReloadingFilters(false)
      })
  }, [filtersParams])

  return { questions, total, isLoadingMore, isReloadingFilters, ref, hasMoreData }
}
