import React, { useState } from 'react'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { FormattedMessage } from 'react-intl'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ActionButton, Box, Item, VStack, Widget } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import EmailTemplatePreview from '@src/pages/Settings/Candidates/EmailTemplatePreview'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'

export const OnlineTestStageSettings = () => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const [emailPreviewOpen, setEmailPreviewOpen] = useState<number | string>()

  return (
    <>
      <Widget>
        {emailPreviewOpen && (
          <EmailTemplatePreview
            id={emailPreviewOpen}
            onClose={() => setEmailPreviewOpen(undefined)}
          />
        )}
        <VStack>
          <Box p="s-16">
            <LapeNewRadioButtons
              variant="cell"
              name="send_test_automatically"
              onAfterChange={option => {
                if (!option.value) {
                  delete values.send_auto_email_template
                }
              }}
              defaultOptionIndex={0}
              options={[
                {
                  label: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.sendTestManually"
                      defaultMessage="Send test manually"
                    />
                  ),
                  description: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.sendTestManuallyDescr"
                      defaultMessage="Candidates can be moved to this stage without the test being sent. you will have to send test(s) manually either one-by-one or in bulk"
                    />
                  ),
                  value: false,
                },
                {
                  label: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.sendTestAutomatically"
                      defaultMessage="Send tests automatically"
                    />
                  ),
                  value: true,
                  description: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.sendTestAutomaticallyDescr"
                      defaultMessage="Test and an optional email will be sent automatically when the candidate is moved to this stage"
                    />
                  ),
                },
              ]}
            />
          </Box>

          {values.send_test_automatically && (
            <>
              <Item pt="0">
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectEmailTemplate"
                      defaultMessage="Select email template"
                    />
                  </Item.Title>
                  <Item.Description>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectEmailTemplateDescr"
                      defaultMessage="This email will be sent automatically once the candidate arrives at this stage. The link to the test won’t be included – it will be sent in an outstanding email from the online test provider."
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
              <VStack p="s-16" pt="0" space="s-16">
                <LapeRadioSelectInput
                  name="send_auto_email_template"
                  selector={selectorKeys.email_templates_active}
                  label="Online test email template"
                  clearable
                  optional
                  onAfterChange={val => {
                    setEmailPreviewOpen(val?.id)
                  }}
                />
                {values.send_auto_email_template && (
                  <ActionButton
                    onClick={() =>
                      setEmailPreviewOpen(values.send_auto_email_template?.id)
                    }
                  >
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.showPreview"
                      defaultMessage="Show preview"
                    />
                  </ActionButton>
                )}
              </VStack>
            </>
          )}
        </VStack>
      </Widget>

      <Widget mt="s-16">
        <VStack>
          <Box p="s-16">
            <LapeNewRadioButtons
              variant="cell"
              defaultOptionIndex={0}
              name="archive_candidate_automatically"
              onAfterChange={option => {
                if (!option.value) {
                  delete values.online_test_pass_score
                  delete values.archive_auto_email_template
                  delete values.auto_archival_reason
                }
              }}
              options={[
                {
                  label: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.archiveManually"
                      defaultMessage="Archive manually"
                    />
                  ),
                  description: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.archiveManuallyDescr"
                      defaultMessage="If the grade for this test is “unsuccessful” or “failed” the candidates won’t be archived. Recruiters will have to archive them manually ."
                    />
                  ),
                  value: false,
                },
                {
                  label: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.archiveAutomatically"
                      defaultMessage="Archive automatically"
                    />
                  ),
                  value: true,
                  description: (
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.archiveAutomaticallyDescr"
                      defaultMessage="Candidates would be archived automatically if the test was failed by the candidate."
                    />
                  ),
                },
              ]}
            />
          </Box>

          {values.archive_candidate_automatically && (
            <>
              <Item pt="0">
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.defineArchivalCriteria"
                      defaultMessage="Define archival criteria"
                    />
                  </Item.Title>
                  <Item.Description>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.defineArchivalCriteriaDescr"
                      defaultMessage="You can archive the candidates based on the numerical score and the status of the test"
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
              <Box px="s-16">
                <LapeNewInput
                  type="number"
                  name="online_test_pass_score"
                  label="Passing score"
                  required
                  message={
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.passScoreMsg"
                      defaultMessage="Candidates with score lower than the passing score will be archived"
                    />
                  }
                />
              </Box>
              <Item>
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectEmailTemplate"
                      defaultMessage="Select email template"
                    />
                  </Item.Title>
                  <Item.Description>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectEmailTemplateDescr"
                      defaultMessage="This email will be sent to the candidate automatically if the archival criteria is met"
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
              <VStack px="s-16" py="0" space="s-16">
                <LapeRadioSelectInput
                  name="archive_auto_email_template"
                  selector={selectorKeys.email_templates_active}
                  label="Archival email template"
                  optional
                  clearable
                  onAfterChange={val => {
                    setEmailPreviewOpen(val?.id)
                  }}
                />

                {values.archive_auto_email_template && (
                  <ActionButton
                    onClick={() =>
                      setEmailPreviewOpen(values.archive_auto_email_template?.id)
                    }
                  >
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.showPreview"
                      defaultMessage="Show preview"
                    />
                  </ActionButton>
                )}
              </VStack>

              <Item>
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectArchivalReason"
                      defaultMessage="Select archival reason"
                    />
                  </Item.Title>
                  <Item.Description>
                    <FormattedMessage
                      id="recruitment.hiringStageSettings.selectArchivalReasonDescr"
                      defaultMessage="Candidate will be archived with this reason if the test is failed"
                    />
                  </Item.Description>
                </Item.Content>
              </Item>
              <Box px="s-16" pb="s-16">
                <LapeRadioSelectInput
                  name="auto_archival_reason"
                  selector={selectorKeys.interview_round_archived_reasons}
                  label="Archival reason"
                  clearable
                />
              </Box>
            </>
          )}
        </VStack>
      </Widget>
    </>
  )
}
