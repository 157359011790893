import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsCandidateColumn,
  notificationsDeadline,
  notificationsDeadlineDate,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
  notificationsRoleColumn,
  notificationsSeniorityColumn,
  notificationsTextColumn,
} from '@src/constants/columns/notifications'
import { notificationsCatchupInterviews } from '@src/api/notifications'

const RowNotifications: RowInterface<NotificationsInterface> = {
  cells: [
    {
      ...notificationsTextColumn,
      width: 200,
    },
    {
      ...notificationsDeadlineDate,
      title: 'Interview date',
      width: 130,
    },
    {
      ...notificationsCandidateColumn,
      width: 250,
    },
    {
      ...notificationsRoleColumn,
      width: 180,
    },
    {
      ...notificationsSeniorityColumn,
      width: 100,
    },
    {
      ...notificationsReceivedColumn,
      width: 100,
    },
    {
      ...notificationsDeadline,
      width: 100,
    },
    {
      ...notificationsPriorityColumn,
      width: 80,
    },
    {
      ...notificationsActionColumn,
      width: 100,
    },
  ],
}

const CatchupInterviews = ({
  refreshStats,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsCatchupInterviews(employeeId)}
      refreshStats={refreshStats}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default CatchupInterviews
