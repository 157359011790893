import { Flex, Text, Token } from '@revolut/ui-kit'
import { ColorTag } from '@src/components/ColorTag/ColorTag'
import React from 'react'

type StageItemRatingProps = {
  rating?: string
}

export const StageItemRating = ({ rating }: StageItemRatingProps) => {
  return (
    <Flex data-testid="Rating" minWidth="100px" justifyContent="flex-end">
      {rating ? (
        <ColorTag variant="neutral">{rating}</ColorTag>
      ) : (
        <Text variant="emphasis2" color={Token.color.greyTone50}>
          n/a
        </Text>
      )}
    </Flex>
  )
}
