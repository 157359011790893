import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Placeholder } from '@revolut/ui-kit'

import { useTable, useIsNewTable } from '@src/components/TableV2/hooks'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  paygroupCountry,
  paygroupEntity,
  paygroupHeadcount,
  paygroupOwner,
  paygroupPayFrequency,
  paygroupStatus,
} from '@src/constants/columns/paygroups'
import Stat from '@src/components/Stat/Stat'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getPaygroups } from '@src/api/payroll'
import { PaygroupInterface } from '@src/interfaces/payroll'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const Row: RowInterface<PaygroupInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW, {
        id: data.id,
      }),
    ),
  cells: [
    {
      ...paygroupCountry,
      width: 150,
    },
    {
      ...paygroupEntity,
      width: 150,
    },
    {
      ...paygroupHeadcount,
      width: 70,
    },
    {
      ...paygroupPayFrequency,
      width: 70,
    },
    {
      ...paygroupOwner,
      width: 150,
    },
    {
      ...paygroupStatus,
      width: 100,
    },
  ],
}

export const PaygroupTable = () => {
  const isNewTable = useIsNewTable()
  const permissions = useSelector(selectPermissions)

  const table = useTable({
    getItems: getPaygroups,
  })

  const canAdd = permissions.includes(PermissionTypes.AddPaygroup)
  const addButton = (
    <PrimaryAction
      useIcon="Plus"
      use={InternalLink}
      to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT)}
    >
      Add
    </PrimaryAction>
  )

  return (
    <Table.Widget>
      {isNewTable ? null : (
        <Table.Widget.Info>
          <Stat label="Total" val={table.loading ? undefined : table.count} />
        </Table.Widget.Info>
      )}
      <Table.Widget.Actions>
        {canAdd && <Table.Widget.MoreBar>{addButton}</Table.Widget.MoreBar>}
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.PayGroups}
          row={Row}
          useWindowScroll
          {...table}
          emptyState={
            <Flex flexDirection="column" alignItems="center" my="s-64">
              <Placeholder>
                <Placeholder.Image
                  src="https://assets.revolut.com/assets/3d-images/3D183.png"
                  srcSet="https://assets.revolut.com/assets/3d-images/3D183@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D183@3x.png 3x"
                />
                <Placeholder.Title>No pay groups</Placeholder.Title>
              </Placeholder>
              {canAdd && addButton}
            </Flex>
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
