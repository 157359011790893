import React from 'react'
import { Item } from '@revolut/ui-kit'

const TeamsIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>How to setup Departments & Teams?</Item.Title>
        <Item.Description>
          Welcome to the Teams Setup feature in Revolut People! Effortlessly structure and
          manage your organisation's teams. Create, modify, or remove teams seamlessly,
          and watch as your workforce collaboration takes shape.
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default TeamsIntro
