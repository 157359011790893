import { Button, Cell, Header, InputGroup, Popup } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import React, { useEffect, useState } from 'react'
import { CareersApplicationWorkInterface } from '@src/interfaces/careers'
import { IdAndName } from '@src/interfaces'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'lape'
import RangeMonthPicker from '@components/Inputs/RangeMonthPicker/RangeMonthPicker'
import {
  useFetchWorkExperienceCompanies,
  useFetchWorkExperiencePositions,
} from '@src/api/recruitment/careers'

type Props = {
  onClose: () => void
  onSubmit: (data: CareersApplicationWorkInterface) => void
  data: Partial<CareersApplicationWorkInterface>
}

const WorkExperiencePopup = ({ onClose, onSubmit, data }: Props) => {
  const { data: companies } = useFetchWorkExperienceCompanies()
  const { data: positions } = useFetchWorkExperiencePositions()
  const [company, setCompany] = useState<IdAndName | undefined | null>(data.company)
  const [position, setPosition] = useState<IdAndName | undefined | null>(data.position)
  const [startDate, setStartDate] = useState<Date | undefined | null>(
    data.start_date ? new Date(data.start_date) : undefined,
  )
  const [endDate, setEndDate] = useState<Date | undefined | null>(
    data.end_date ? new Date(data.end_date) : undefined,
  )
  const [isWorkHere, setWorkHere] = useState(data.end_date === null)

  const isValid = !!(company && position && startDate)

  useEffect(() => {
    if (isWorkHere) {
      setEndDate(null)
    }
  }, [isWorkHere])

  const onClickAdd = () => {
    if (isValid) {
      onSubmit({
        company,
        position,
        start_date: startDate.toISOString(),
        end_date: endDate?.toISOString() || null,
      })
    }
  }

  return (
    <Popup variant="modal-view" open onClose={onClose}>
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Add experience</Header.Title>
      </Header>
      <InputGroup>
        <RadioSelectInput
          label="Company"
          options={
            companies?.map(value => ({
              label: value.name,
              value,
            })) || []
          }
          value={company}
          onChange={val => {
            if (val) {
              setCompany(val)
            }
          }}
          allowCreateNewOption
        />
        <RadioSelectInput
          label="Position"
          options={
            positions?.map(value => ({
              label: value.name,
              value,
            })) || []
          }
          value={position}
          onChange={val => {
            if (val) {
              setPosition(val)
            }
          }}
          allowCreateNewOption
        />
        <RangeMonthPicker
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          endDateDisabled={isWorkHere}
          endDateAfterDaysDisabled
        />
        <Cell use="label">
          <NewCheckbox
            label="I still work here"
            checked={isWorkHere}
            onChange={() => setWorkHere(prev => !prev)}
          />
        </Cell>
      </InputGroup>
      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button elevated onClick={onClickAdd} disabled={!isValid}>
          {isEmpty(data) ? 'Add' : 'Change'}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default connect(WorkExperiencePopup)
