import { OptionInterface } from '@src/interfaces/selectors'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getFiltersToParams = ({
  textFilter,
  driversFilter,
}: {
  textFilter?: string
  driversFilter?: OptionInterface[]
}) => {
  const filters = []

  if (textFilter) {
    filters.push({
      columnName: 'search',
      filters: [{ id: textFilter, name: textFilter }],
    })
  }

  if (driversFilter) {
    filters.push({
      columnName: 'driver__id',
      filters: [
        ...(driversFilter
          ? driversFilter.map(({ id }) => ({ id: String(id), name: String(id) }))
          : []),
      ],
    })
  }

  return filterSortPageIntoQuery(undefined, filters)
}
