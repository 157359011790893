import React, { useState } from 'react'
import { Button, chain, Header, MoreBar, Popup, Text } from '@revolut/ui-kit'
import { Delete, Reverted } from '@revolut/icons'

import { RowInterface, Stats } from '@src/interfaces/data'
import {
  SchedulePolicyShiftInterface,
  SchedulingPolicyInterface,
} from '@src/interfaces/attendance'
import {
  schedulingPolicyShiftActionsColumn,
  schedulingPolicyShiftCompensationColumn,
  schedulingPolicyShiftDayPeriodColumn,
  schedulingPolicyShiftNameColumn,
  schedulingPolicyShiftStatusColumn,
  schedulingPolicyShiftTypeColumn,
  schedulingPolicyShiftWeekPeriodColumn,
} from '@src/constants/columns/attendance'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTableReturnType } from '@src/components/Table/hooks'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getStatusColor } from '@src/components/CommonSC/General'
import { deletePolicy } from '@src/api/attendance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableNames } from '@src/constants/table'

const ROW = (
  onActionComplete: () => void,
  hideActions: boolean,
): RowInterface<SchedulePolicyShiftInterface> =>
  ({
    cells: [
      {
        ...schedulingPolicyShiftNameColumn,
        width: 130,
      },
      {
        ...schedulingPolicyShiftTypeColumn,
        width: 100,
      },
      {
        ...schedulingPolicyShiftDayPeriodColumn,
        width: 100,
      },
      {
        ...schedulingPolicyShiftWeekPeriodColumn,
        width: 100,
      },
      {
        ...schedulingPolicyShiftCompensationColumn,
        width: 100,
      },
      {
        ...schedulingPolicyShiftStatusColumn,
        width: 100,
      },
      hideActions
        ? null
        : {
            ...schedulingPolicyShiftActionsColumn(onActionComplete),
            width: 150,
          },
    ].filter(Boolean),
  } as RowInterface<SchedulePolicyShiftInterface>)

interface ShiftTableProps {
  policyId: number | string
  table: useTableReturnType<SchedulePolicyShiftInterface, Stats>
  hideActions: boolean
}

export const ShiftTable = ({ policyId, table, hideActions }: ShiftTableProps) => {
  return (
    <AdjustableTable
      name={TableNames.SchedulingShifts}
      row={ROW(table.refresh, hideActions)}
      onRowClick={row =>
        navigateTo(
          pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.PREVIEW, {
            policyId,
            id: row.id,
          }),
        )
      }
      {...table}
      hideCount
      dataType="shift"
      useWindowScroll
      noDataMessage="No shifts added to this scheduling policy"
    />
  )
}

export const useTitle = (values?: SchedulingPolicyInterface) => {
  if (!values?.id) {
    return 'Create new scheduling policy'
  }

  return chain(
    values.location.name,
    values.name,
    <Text color={getStatusColor(values.status.id)}>{values.status.name}</Text>,
  )
}

export const DeleteSchedulingPolicyButton = () => {
  const { values } = useLapeContext<SchedulingPolicyInterface>()

  const [deletePending, setDeletePending] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)

  const backUrl = ROUTES.APPS.ATTENDANCE.POLICIES

  const isCompleted = values.configuration_step.id === 'completed'

  const onDelete = () => {
    setDeletePending(true)

    deletePolicy(values.id)
      .then(() => goBack(backUrl))
      .catch(() => setDeletePending(false))
  }

  return (
    <>
      <MoreBar.Action
        onClick={() => setPopupOpen(true)}
        useIcon={isCompleted ? Delete : Reverted}
        variant="negative"
      >
        {isCompleted ? 'Delete' : 'Discard'}
      </MoreBar.Action>

      <Popup open={popupOpen} variant="bottom-sheet" onClose={() => setPopupOpen(false)}>
        <Header variant="bottom-sheet">
          <Header.Title>
            {isCompleted
              ? `You're going to delete schedule policy and all shifts linked to it`
              : `You're going to discard the policy's draft`}
          </Header.Title>
          <Header.Description>
            {isCompleted
              ? `This action cannot be undone. Are you sure you want to continue?`
              : `Once that's done, all policy and shift configuration will be lost. Are you sure you want to continue?`}
          </Header.Description>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setPopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onDelete} pending={deletePending} elevated>
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
