import React, { useContext, useEffect, useState } from 'react'
import get from 'lodash/get'
import { EditableTableContext } from '@components/Table/EditableTable/common'
import EditableCell, {
  EditableCellProps,
} from '@components/Table/AdvancedCells/EditableCell/EditableCell'
import { parseValueByType } from '@components/Inputs/TableCellInput/TableCellInput'

export interface EditableTableCellProps extends EditableCellProps {
  fieldDataPath: string
  rowPath: string
  columnTitle: string
}

const EditableTableCell = ({
  fieldDataPath,
  rowPath,
  columnTitle,
  ...props
}: EditableTableCellProps) => {
  const { data, onChange } = useContext(EditableTableContext)
  const [value, setValue] = useState<string | number | undefined>()

  useEffect(() => {
    const formValue = get(data, fieldDataPath)
    setValue(parseValueByType[props.type](formValue))
  }, [data])

  return (
    <EditableCell
      {...props}
      value={value}
      onChange={newValue => {
        setValue(newValue)
        props.onChange?.(newValue)
      }}
      // Needs to be called onBlur, otherwise sell unmounts because the table data changes
      onBlur={fieldValue => onChange({ fieldValue, fieldDataPath, rowPath, columnTitle })}
    />
  )
}

export default EditableTableCell
