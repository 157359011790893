import React, { ReactNode } from 'react'
import { ButtonFilterType } from '@components/ButtonFilters/ButtonFilters'
import { chain } from '@revolut/ui-kit'
import { OptionInterface } from '@src/interfaces/selectors'

interface ButtonFilterTitleProps {
  title: string
  type: ButtonFilterType
  value?: OptionInterface[]
}

export const ButtonFilterTitle = ({ title, type, value }: ButtonFilterTitleProps) => {
  let caption: string | ReactNode[] = title
  if (type === 'MultiSelect') {
    caption = chain(title, value?.length || null)
  }

  if (type === 'DateRange' && value?.[0] && value?.[1]) {
    const formatter = new Intl.DateTimeFormat('default', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

    // @ts-ignore formatRange exists
    caption = formatter.formatRange(new Date(value[0].id), new Date(value[1].id))
  }

  return <>{caption}</>
}
