import React, { useRef, useState, useMemo } from 'react'
import {
  MoreBar,
  FilterButton,
  Dropdown,
  VStack,
  Text,
  Side,
  Button,
  chain,
  useStatusPopup,
  StatusPopup,
  Token,
  Item,
  Avatar,
  ActionButton,
  Box,
  IconName,
} from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { getAccounts } from '@src/api/settings'
import {
  accountAdminColumn,
  accountCompanyNameColumn,
  accountStatusColumn,
  accountSubdomainColumn,
  accountCountryColumn,
  accountHeadcountColumn,
  accountPaymentDetailsValidColumn,
  accountProgressColumn,
  accountSubscriptionStateColumn,
  accountLastInvoiceStatusColumn,
} from '@src/constants/columns/settings'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { isDev, isStaging } from '@src/utils'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import { getSelectCellConfig } from '@src/components/TableV2/AdvancedCells/SelectCell/SelectCell'
import SideBar from '@src/components/SideBar/SideBar'
import pluralize from 'pluralize'
import {
  TenantFeaturesCheckboxGroup,
  TenantOnboardingSidebar,
  useTenantFeatures,
} from '../common'
import { IdAndName } from '@src/interfaces'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { setTenantFeatures, useTenantSelector } from '@src/api/tenants'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useGetSelectors } from '@src/api/selectors'
import {
  OnboardingCheckpointSelectorInterface,
  TenantOnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklist'
import { useAnnualPlanRequests } from './useAnnualPlanRequests'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const row = (
  onSelectProgress: (progress?: TenantOnboardingCheckpointInterface[]) => void,
  onboardingCheckpoints?: OnboardingCheckpointSelectorInterface[],
): RowInterface<AccountsSettingsInterface> => ({
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INFORMATION, { id: data.id })),
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...accountCompanyNameColumn,
      width: 200,
    },
    {
      ...accountSubdomainColumn,
      width: 120,
    },
    {
      ...accountAdminColumn,
      width: 120,
    },
    {
      ...accountCountryColumn,
      width: 120,
    },
    {
      ...accountHeadcountColumn,
      width: 120,
    },
    {
      ...accountPaymentDetailsValidColumn,
      width: 120,
    },
    {
      ...accountLastInvoiceStatusColumn,
      width: 120,
    },
    {
      ...accountProgressColumn(onSelectProgress, onboardingCheckpoints),
      width: 120,
    },
    {
      ...accountSubscriptionStateColumn,
      width: 120,
    },
    {
      ...accountStatusColumn,
      width: 120,
    },
  ],
})

export const AccountList = () => {
  const [showTesting, setShowTesting] = useLocalStorage(
    LOCAL_STORAGE.SHOW_TESTING_ACCOUNTS,
    isDev() || isStaging(),
  )

  const statusPopup = useStatusPopup()

  const dropdownAnchorRef = useRef(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [confirmFeaturesDialogOpen, setConfirmFeaturesDialogOpen] = useState(false)
  const [confirmFeaturesLoading, setConfirmFeaturesLoading] = useState(false)
  const [progressSidebarState, setProgressSidebarState] = useState<{
    open: boolean
    data?: TenantOnboardingCheckpointInterface[]
  }>({ open: false })
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<AccountsSettingsInterface>>()
  const [sidebarState, setSidebarState] = useState<{
    open: boolean
    mode?: 'enable' | 'disable'
    features?: IdAndName<string>[]
  }>({ open: false })

  const getFilterByTestingAccounts = (testing: boolean) => ({
    filters: [{ id: testing ? 'true' : 'false', name: testing ? 'true' : 'false' }],
    columnName: 'testing',
    nonResettable: true,
  })

  const table = useTable({ getItems: getAccounts }, [
    getFilterByTestingAccounts(showTesting),
  ])
  const { annualPlanRequests } = useAnnualPlanRequests()

  const { features } = useTenantFeatures(sidebarState.open)
  const { data: tenants } = useTenantSelector(table.filterBy)
  const onboardingCheckpoints = useGetSelectors<OnboardingCheckpointSelectorInterface>(
    selectorKeys.tenant_onboarding_checkpoints,
  )

  const selectTableControls = useRef<SelectionControls<AccountsSettingsInterface>>()

  const onToggleTesting = () => {
    setShowTesting(!showTesting)
    table.onFilterChange(getFilterByTestingAccounts(!showTesting))
  }

  const selectedAccounts = useMemo(() => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData
    }
    if (selectedData?.isAllSelected) {
      return (tenants?.options || [])
        .filter(tenant => !selectedData.excludeListIds.has(`${tenant.id}`))
        .map(tenant => ({ ...tenant, company_name: tenant.name }))
    }
    return []
  }, [selectedData, tenants])

  const sidebarProps = useMemo(() => {
    if (!sidebarState.mode) {
      return {
        title: undefined,
        subtitle: undefined,
        icon: undefined,
        color: undefined,
        submitButtonText: undefined,
      }
    }

    const title = {
      enable: 'Enable features',
      disable: 'Disable features',
    }[sidebarState.mode]

    const accountsByNameLimit = 4
    const accountsByName = selectedAccounts
      .slice(0, accountsByNameLimit)
      .map(a => a.company_name)
      .join(', ')
    const accountsByCount = selectedAccounts.slice(accountsByNameLimit).length
    const subtitleDescription = `${accountsByName}${
      accountsByCount ? ` + ${pluralize('other', accountsByCount, true)}` : ''
    }`
    const subtitle = (
      <VStack space="s-4" mt="s-4">
        <Text variant="primary" color={Token.color.foreground}>
          {pluralize('account', selectedAccounts.length, true)} selected
        </Text>
        <Text>{subtitleDescription}</Text>
      </VStack>
    )

    const iconMap: Record<string, IconName> = {
      enable: 'CheckSuccess',
      disable: 'CrossCircle',
    }
    const icon = iconMap[sidebarState.mode]

    const submitButtonText = {
      enable: 'Enable',
      disable: 'Disable',
    }[sidebarState.mode]

    const color = {
      enable: Token.color.accent,
      disable: Token.color.danger,
    }[sidebarState.mode]

    return { title, subtitle, icon, color, submitButtonText, subtitleDescription }
  }, [sidebarState, selectedAccounts])

  if (sidebarState.open && !selectedAccounts.length) {
    setSidebarState({ open: false })
  }

  const onConfirmFeatures = () => {
    if (sidebarState.features && sidebarState.mode) {
      setConfirmFeaturesLoading(true)

      setTenantFeatures(selectedAccounts, sidebarState.features, sidebarState.mode)
        .then(() => {
          statusPopup.show(
            <StatusPopup variant="success">
              <StatusPopup.Title>Success</StatusPopup.Title>
              <StatusPopup.Description>
                Account features for {sidebarProps.subtitleDescription} updated
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to update features</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => {
          setConfirmFeaturesDialogOpen(false)
          setConfirmFeaturesLoading(false)
          setSidebarState({ open: false })
          selectTableControls.current?.resetState()
        })
    }
  }

  return (
    <>
      <PageHeader title="Accounts" backUrl={ROUTES.SETTINGS.ALL} />

      {annualPlanRequests?.length ? (
        <Box mb="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Bell" color={Token.color.warning} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Annual Plan requests</Item.Title>
              <Item.Description>
                {(() => {
                  const companiesToDisplay = annualPlanRequests
                    .slice(0, 3)
                    .map(c => c.company_name)
                    .join(', ')
                  const companiesToHideLength = annualPlanRequests.slice(3)?.length

                  return `${companiesToDisplay}${
                    companiesToHideLength
                      ? ` and ${companiesToHideLength} more ${pluralize(
                          'company',
                          companiesToHideLength,
                        )}`
                      : ''
                  } requested annual plan`
                })()}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Item.Value>
                <ActionButton
                  onClick={() => {
                    table.resetFiltersAndSorting([
                      {
                        columnName: 'id',
                        filters: [
                          { id: annualPlanRequests.map(i => i.id).join(','), name: '' },
                        ],
                      },
                      {
                        columnName: 'testing',
                        filters: [],
                      },
                    ])
                  }}
                >
                  Review
                </ActionButton>
              </Item.Value>
            </Item.Side>
          </Item>
        </Box>
      ) : null}

      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.SETTINGS.ACCOUNTS.GENERAL)}
              useIcon="Plus"
            >
              Create new account
            </PrimaryAction>
            <MoreBar.Action
              onClick={() => setDropdownOpen(!dropdownOpen)}
              disabled={!selectedData?.someSelected}
              useIcon="Gear"
              ref={dropdownAnchorRef}
            >
              Manage features
            </MoreBar.Action>
            <Dropdown
              open={dropdownOpen}
              anchorRef={dropdownAnchorRef}
              onClose={() => setDropdownOpen(false)}
              minWidth={200}
            >
              <Dropdown.Item
                onClick={() => {
                  setDropdownOpen(false)
                  setSidebarState({ open: true, mode: 'enable' })
                }}
                useIcon="CheckSuccess"
                use="button"
              >
                Enable
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setDropdownOpen(false)
                  setSidebarState({ open: true, mode: 'disable' })
                }}
                useIcon="CrossCircle"
                use="button"
                color={Token.color.danger}
              >
                Disable
              </Dropdown.Item>
            </Dropdown>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Filters>
          <FilterButton onClick={onToggleTesting} active={showTesting}>
            Testing accounts
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            onControlsLoaded={controls => {
              selectTableControls.current = controls
            }}
          >
            <AdjustableTable
              name={TableNames.GlobalAccounts}
              row={row(
                data => setProgressSidebarState({ open: true, data }),
                onboardingCheckpoints.data,
              )}
              useWindowScroll
              {...table}
            />
          </SelectTableWrapper>
        </Table.Widget.Table>
      </Table.Widget>

      <SideBar
        title={sidebarProps.title}
        subtitle={sidebarProps.subtitle}
        isOpen={sidebarState.open}
        onClose={() => setSidebarState({ open: false })}
        useIcon={sidebarProps.icon}
        avatarProps={{ color: sidebarProps.color }}
      >
        <TenantFeaturesCheckboxGroup
          onChange={value => {
            setSidebarState(prev => ({
              ...prev,
              features: value
                .map(feature => features.find(f => f.id === feature))
                .filter(Boolean),
            }))
          }}
          value={sidebarState.features?.map(f => f.id) || []}
        />
        <Side.Actions>
          <Button
            onClick={() => setConfirmFeaturesDialogOpen(true)}
            disabled={!sidebarState.features || sidebarState.features.length === 0}
            elevated
          >
            {chain(sidebarProps.submitButtonText, sidebarState.features?.length)}
          </Button>
        </Side.Actions>
      </SideBar>

      <TenantOnboardingSidebar
        progress={progressSidebarState.data}
        open={progressSidebarState.open}
        onClose={() => setProgressSidebarState({ open: false, data: undefined })}
      />

      <ConfirmationDialog
        label="Update account features?"
        body={`Are you sure you want to update account features for ${pluralize(
          'selected account',
          selectedAccounts.length,
          true,
        )}?`}
        open={confirmFeaturesDialogOpen}
        variant="compact"
        onClose={() => setConfirmFeaturesDialogOpen(false)}
        onConfirm={onConfirmFeatures}
        onReject={() => setConfirmFeaturesDialogOpen(false)}
        onExited={() => setConfirmFeaturesDialogOpen(false)}
        loading={confirmFeaturesLoading}
        yesMessage="Confirm"
        noMessage="Cancel"
      />
    </>
  )
}
