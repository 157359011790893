import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { roadmapSettings } from '@src/api/settings'
import { Roadmaps } from '@src/pages/Settings/Roadmaps/Roadmaps'

const routes = [
  {
    title: 'Roadmaps settings',
    description: 'Settings for the roadmaps app',
    path: ROUTES.SETTINGS.ROADMAPS,
    url: ROUTES.SETTINGS.ROADMAPS,
    canView: [
      PermissionTypes.ViewRoadmapPreferences,
      PermissionTypes.ChangeRoadmapPreferences,
    ],
    component: Roadmaps,
  },
]

export const RoadmapSettings = () => {
  return <SettingsForm routes={routes} api={roadmapSettings} />
}
