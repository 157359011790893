import React, { useMemo, useState } from 'react'
import { Warning } from '@revolut/icons'
import { DataPoint, Flex } from '@revolut/ui-kit'

import Tooltip from '@components/Tooltip/Tooltip'
import IconButton from '@components/ButtonIcon/IconButton'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'
import { formatMoney } from '@src/utils/format'
import { RewardsSummary } from '@src/interfaces/rewards'

const startYear = 2015
const endYear = new Date().getFullYear()

export const useYear = () => useState(String(endYear))

interface YearFilterProps {
  value: string
  onChange: (year: string) => void
  label?: string
}

export const YearFilter = ({ value, onChange, label = 'Period' }: YearFilterProps) => {
  const years = React.useMemo(
    () =>
      Array(endYear - startYear + 1)
        .fill(endYear)
        .map((year, index) => ({
          label: String(year - index),
          value: {
            id: String(year - index),
          },
        })),
    [],
  )

  return (
    <DataPoint>
      <DataPoint.Value>
        <RadioSelectInput
          value={{ id: value }}
          options={years}
          onChange={option => {
            if (option) {
              onChange(option.id)
            }
          }}
          renderInput={(open, setOpen, ref) => (
            <DropdownStatAction open={open} onClick={() => setOpen(!open)} ref={ref}>
              {value}
            </DropdownStatAction>
          )}
        />
      </DataPoint.Value>
      <DataPoint.Label>{label}</DataPoint.Label>
    </DataPoint>
  )
}

export const TotalErrorIcon = () => (
  <Flex justifyContent="flex-end" height={28}>
    <Tooltip
      placement="right"
      text="Unable to compute the total in the selected currency"
    >
      <IconButton>
        <Warning color="red" />
      </IconButton>
    </Tooltip>
  </Flex>
)

export const useCompensationTotal = (
  data: RewardsSummary[] | undefined = [],
  currency: string | undefined,
  isLoading: boolean,
) => {
  const dataWithoutBenefits = useMemo(
    () => data.filter(row => row.reward_type.id !== 'benefits'),
    [data],
  )

  const totalError = useMemo(() => {
    if (isLoading) {
      return false
    }

    if (
      !currency &&
      dataWithoutBenefits.every(
        (item, _i, arr) => item.result_currency === arr[0].result_currency,
      )
    ) {
      return false
    }

    return dataWithoutBenefits.some(item => item.result_currency !== currency)
  }, [dataWithoutBenefits, isLoading, currency])

  const total = useMemo(() => {
    if (isLoading) {
      return undefined
    }

    if (!dataWithoutBenefits.length) {
      return formatMoney(0, currency)
    }

    const totals = dataWithoutBenefits.reduce<Record<string, number>>(
      (tot, item) => ({
        ...tot,
        [item.result_currency]:
          (tot[item.result_currency] || 0) + item.exchanged_total_amount,
      }),
      {},
    )

    return Object.entries(totals)
      .map(([cur, tot]) => formatMoney(tot, cur))
      .join(' + ')
  }, [dataWithoutBenefits, isLoading, currency])

  return { total, totalError }
}
