import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useCompleteGrowthPlan } from '@src/api/growthPlans'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  growthPlan: GrowthPlanInterface
}

export const CompleteAction = ({ growthPlan }: Props) => {
  const { mutateAsync: completeGrowthPlan, isLoading } = useCompleteGrowthPlan(
    growthPlan.id,
  )

  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const handleClick = () => {
    confirmationDialog.show({
      body: 'Are you sure you want to mark this growth plan as "Completed"?',
      onConfirm: async () => {
        try {
          await completeGrowthPlan()
          showStatusPopup({ title: 'Growth plan was successfully completed' })
        } catch (err) {
          showStatusPopup({
            title: 'Failed to complete growth plan',
            description: getStringMessageFromError(err),
            status: 'error',
          })
        }
      },
    })
  }

  return (
    <MoreBar.Action
      variant="accent"
      useIcon="Check"
      pending={isLoading}
      onClick={handleClick}
    >
      Completed
    </MoreBar.Action>
  )
}
