import React from 'react'
import { HStack } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { goBack } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const GrowthPlanFormActions = () => {
  const { values, submit } = useLapeContext<GrowthPlanInterface>()

  const handleSubmitClick = () => {
    values.status = null
    return submit()
  }

  const handleAfterSubmit = () => {
    goBack()
  }

  const isDraftPlan = values.status === Statuses.draft || values.status === null

  return (
    <HStack gap="s-16">
      {isDraftPlan && (
        <NewSaveButtonWithPopup
          useValidator
          variant="secondary"
          successText="Growth Plan saved as draft"
          hideWhenNoChanges={false}
          onAfterSubmit={handleAfterSubmit}
        >
          Save as draft
        </NewSaveButtonWithPopup>
      )}
      <NewSaveButtonWithPopup
        useValidator
        successText="Growth Plan successfully updated"
        hideWhenNoChanges={!isDraftPlan}
        onClick={handleSubmitClick}
        onAfterSubmit={handleAfterSubmit}
      >
        {isDraftPlan ? 'Submit' : 'Save changes'}
      </NewSaveButtonWithPopup>
    </HStack>
  )
}
