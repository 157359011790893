import React, { useState } from 'react'
import upperFirst from 'lodash/upperFirst'
import { connect } from 'lape'
import {
  Flex,
  Tooltip,
  Link,
  MoreBar,
  Text,
  useTooltip,
  VStack,
  Widget,
  Token,
} from '@revolut/ui-kit'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Dot } from '@src/pages/Forms/FormTitles'
import { ROUTES } from '@src/constants/routes'
import { ExclamationMarkOutline, LinkExternal, Pencil, Play, Stop } from '@revolut/icons'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import pluralize from 'pluralize'
import {
  probationTemplateRequests,
  useGetProbationTemplate,
} from '@src/api/probationTemplate'
import { getProbationTemplateStatusColor } from '@src/utils/performance'
import { ViewBanner } from '@src/pages/Forms/ProbationTemplate/ViewBanner'
import SettingsButtons from '@src/features/SettingsButtons'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { TimelinePreview } from '@src/pages/Forms/ProbationTemplate/TimelinePreview'
import { EmployeeRulesList } from '@src/pages/Forms/ProbationTemplate/EmployeeRulesList'

const View = () => {
  const params = useParams<{ id: string }>()
  const [stopLoading, setStartLoading] = useState(false)
  const tooltip = useTooltip()

  const { data } = useGetProbationTemplate(params.id)

  const generalInfo = [
    { name: 'Policy name', value: data?.name || '-' },
    {
      name: 'Probation duration',
      value: data?.amount_of_months
        ? pluralize('month', data.amount_of_months, true)
        : '-',
    },
    {
      name: 'Amount of checkpoints',
      value: data?.num_checkpoints || '-',
    },
    {
      name: 'Policy document',
      value: data?.policy_document_link ? (
        <Link
          href={data.policy_document_link}
          target="_blank"
          rel="noreferrer noopener"
          onClick={e => e.stopPropagation()}
        >
          <LinkExternal size={20} />
        </Link>
      ) : (
        '-'
      ),
    },
  ]

  const handleDisableEnable = async () => {
    try {
      if (data) {
        setStartLoading(true)
        const result = await probationTemplateRequests.update(
          { ...data, status: data.status === 'enabled' ? 'disabled' : 'enabled' },
          { id: String(data.id) },
        )
        setStartLoading(false)

        if (result) {
          data.status = result.data.status
          pushNotification({
            value: `Template was successfully ${data.status}.`,
            duration: SUCCESS_DEFAULT_DURATION,
            type: NotificationTypes.success,
          })
        }
      }
    } finally {
      setStartLoading(false)
    }
  }

  const isEnabled = data?.status === 'enabled'

  return (
    <PageWrapper>
      {data && (
        <PageHeader
          title={
            <>
              {data.name}
              <Dot>·</Dot>
              <Text color={getProbationTemplateStatusColor(data.status)}>
                {upperFirst(data.status)}
              </Text>
            </>
          }
          backUrl={ROUTES.PERFORMANCE.REVIEWS_CYCLES}
        />
      )}

      <PageBody>
        <AutoStepper>
          <SettingsButtons mb="s-32">
            <MoreBar.Action
              pending={stopLoading}
              useIcon={isEnabled ? Stop : Play}
              variant={isEnabled ? 'negative' : 'primary'}
              onClick={handleDisableEnable}
            >
              {isEnabled ? 'Disable' : 'Enable'} template
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.PROBATION.TEMPLATE, { id: data?.id })}
              useIcon={Pencil}
            >
              Edit template
            </MoreBar.Action>
          </SettingsButtons>
          <ViewBanner />
          <>
            <NewStepperTitle title="Template Info" />
            <Widget p="12px 16px">
              <VStack space="s-24">
                {generalInfo.map((item, i) => (
                  <Flex justifyContent="space-between" key={i}>
                    <Text variant="caption" fontWeight={500} color="grey-tone-50">
                      {item.name}
                    </Text>
                    <Text variant="caption">{item.value}</Text>
                  </Flex>
                ))}
              </VStack>
            </Widget>
          </>
          <>
            {data?.timelines && (
              <>
                <NewStepperTitle title="Timeline" />
                <TimelinePreview
                  timelines={data.timelines}
                  totalWeeks={data.total_weeks}
                />
              </>
            )}
          </>
          <NewStepperTitle title="Employee rules" />
          <EmployeeRulesList rules={data?.rules} />
          <NewStepperTitle title="Additional settings" />
          <Widget px="s-16" py="s-12" mb="s-64">
            <Flex justifyContent="space-between">
              <Flex alignItems="center" gap="s-4">
                <Text variant="caption" fontWeight={500} color={Token.color.greyTone50}>
                  Cycle extension
                </Text>
                <ExclamationMarkOutline
                  size={15}
                  {...tooltip.getAnchorProps()}
                  color={Token.color.greyTone50}
                />
                <Tooltip {...tooltip.getTargetProps()} width="250px" placement="right">
                  Allows the final decision to select a new probation end date as a cycle
                  extension
                </Tooltip>
              </Flex>
              <Text variant="caption">
                {data?.allow_extension ? 'Allowed' : 'Not Allowed'}
              </Text>
            </Flex>
          </Widget>
        </AutoStepper>
      </PageBody>
    </PageWrapper>
  )
}

export default connect(View)
