import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { MeetingsTable } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsTable/MeetingsTable'
import { OneToOneExtensionBanner } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/components/ExtensionBanner/OneToOneExtensionBanner'
import { VStack } from '@revolut/ui-kit'

interface Props {
  data: EmployeeInterface
}

export const LayoutTab = ({ data }: Props) => {
  return (
    <>
      <Route exact path={ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.MEETINGS.OVERVIEW}>
        <VStack space="s-16">
          <OneToOneExtensionBanner />
          <MeetingsTable employee={data} />
        </VStack>
      </Route>
    </>
  )
}
