import React from 'react'
import { Box, HStack, Tag, Text, Token } from '@revolut/ui-kit'

type Props<T> = {
  value: T
  recommendedValue: T
  text?: string
  radio: React.ReactNode
}

export const RadioItem = <T,>({ value, recommendedValue, radio, text }: Props<T>) => {
  return (
    <Box py="s-8">
      {recommendedValue === value ? (
        <>
          <HStack gap="s-6" align="center">
            {radio} <Tag variant="faded">Recommended</Tag>
          </HStack>

          {text && (
            <Text
              variant="body2"
              use="div"
              mt="s-4"
              ml="s-40"
              color={Token.color.greyTone50}
            >
              {text}
            </Text>
          )}
        </>
      ) : (
        radio
      )}
    </Box>
  )
}
