import { ActionButton, HStack, StatusWidget, chain } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import SideBar from '@src/components/SideBar/SideBar'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React from 'react'

type Props = {
  isOpen: boolean
  onClose: () => void
  payCycleId: string
}

const FilesSidebar = ({ isOpen, onClose, payCycleId }: Props) => {
  // TODO: make this usable - needs BE changes, will disable upload button until that's ready
  // TODO: https://revolut.atlassian.net/browse/RHR-3182
  // usePayCycle(payCycleId)
  // usePayCycleFiles(payCycleId)

  return (
    <>
      <SideBar variant="wide" title={chain('Files', 0)} onClose={onClose} isOpen={isOpen}>
        <HStack>
          <Tooltip text="Temporarily disabled, pending data migration" placement="right">
            <ActionButton
              useIcon="Upload"
              use={InternalLink}
              to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.UPLOAD_FILE, {
                id: payCycleId,
              })}
              disabled
              mb="s-16"
            >
              Upload file
            </ActionButton>
          </Tooltip>
        </HStack>
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images/3D067.png',
              '2x': 'https://assets.revolut.com/assets/3d-images/3D067@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images/3D067@3x.png',
            }}
          />
          <StatusWidget.Title>No files uploaded</StatusWidget.Title>
          <StatusWidget.Description>
            Use the button above to upload a file
          </StatusWidget.Description>
        </StatusWidget>
      </SideBar>
    </>
  )
}

export default FilesSidebar
