import { ROUTES } from '@src/constants/routes'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import React from 'react'

export const GoalsSettingsButton = () => {
  const isOnboarding = isOnboardingPath()
  return (
    <SettingsButton
      path={pathToUrl(
        isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.ALL
          : ROUTES.SETTINGS.GOALS.ALL,
      )}
      canView={[
        PermissionTypes.ViewKPITemplate,
        PermissionTypes.ViewPerformancePreferences,
        PermissionTypes.ChangePerformancePreferences,
      ]}
    />
  )
}
