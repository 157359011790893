import React from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGlobalSettings } from '@src/api/settings'

export const BookingLinkAction = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const {
    settings: { candidates_scheduling_enabled },
  } = useGlobalSettings()
  const params = useParams()
  const permissions = useSelector(selectPermissions)
  const hasPermission = permissions.includes(PermissionTypes.ViewBookingLinkDefinition)
  const canView = !!values?.id && candidates_scheduling_enabled && hasPermission
  if (!canView) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="20/Linked"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.JOB_POSTING.BOOKING_LINKS, params)}
    >
      Booking links
    </MoreBar.Action>
  )
}
