import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DataRetentionCountriesInterface,
  DataRetentionSettingInterface,
  GDPRPolicy,
} from '@src/interfaces/dataRetentionSettings'
import { SchedulingPolicyInterface } from '@src/interfaces/attendance'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { API } from '@src/constants/api'

export const dataRetentionSettingsRequests: TableRequestInterface<DataRetentionSettingInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`/retentionPeriods`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const dataRetentionSettingsRequestsNew: RequestInterfaceNew<SchedulingPolicyInterface> =
  {
    get: async ({ id }) => api.get(`/retentionPeriods/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/retentionPeriods/${id}`, data),
    submit: async data => apiWithoutHandling.post(`/retentionPeriods`, data),
    delete: async ({ id }) => api.delete(`/retentionPeriods/${id}`),
  }

export const getDataRetentionCountryList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<DataRetentionCountriesInterface>
> =>
  api.get(`${API.GDPR}/countries`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const dataRetentionGDPR: RequestInterfaceNew<GDPRPolicy> = {
  get: async ({ id }) => api.get(`${API.GDPR}/policies/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.GDPR}/policies/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.GDPR}/policies`, data),
}
