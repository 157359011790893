import React, { useState } from 'react'
import { ActionButton } from '@revolut/ui-kit'
import { DocusignUsersInterface } from '@src/interfaces/integrations'
import { docusignUsersSync, docusignUsersTableRequests } from '@src/api/integrations'
import {
  docusignUsersEmailColumn,
  docusignUsersStatusColumn,
} from '@src/constants/columns/docusignUsers'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

const row: RowInterface<DocusignUsersInterface> = {
  cells: [
    {
      ...docusignUsersEmailColumn,
      width: 200,
    },
    {
      ...docusignUsersStatusColumn,
      width: 100,
    },
  ],
}

const initialFilterBy = [
  {
    columnName: 'status',
    filters: [{ id: Statuses.active, name: Statuses.active }],
  },
]

const initialSortBy = [
  {
    sortBy: 'email',
    direction: SORT_DIRECTION.DESC,
  },
]

export const UsersTable = () => {
  const showStatusPopup = useShowStatusPopup()
  const [pending, setPending] = useState(false)

  const table = useTable(docusignUsersTableRequests, initialFilterBy, initialSortBy)

  const handleRefresh = async () => {
    try {
      setPending(true)
      await docusignUsersSync()
      table.refresh()
    } catch (e) {
      showStatusPopup({
        title: 'Synchronisation failed',
        description: getStringMessageFromError(e),
        status: 'error',
      })
    } finally {
      setPending(false)
    }
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <ActionButton pending={pending} onClick={handleRefresh} useIcon="Retry">
          Synchronise with DocuSign
        </ActionButton>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          {...table}
          dataType="Integrations"
          enableSettings
          name={TableNames.DocusignIntegrations}
          noDataMessage="List of all integrations will appear here."
          row={row}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
