import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import { ContractTypes } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'

export const fields: ImportFieldType<ImportContractsDataInterface>[] = [
  {
    type: 'radio-select',
    field: 'email',
    selector: selectorKeys.all_employee_emails,
    label: 'Email',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'contract_type',
    selector: selectorKeys.contract_types,
    label: 'Contract type',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'contract_term',
    selector: selectorKeys.contract_terms,
    label: 'Contract term',
    valueKey: 'id',
  },
  {
    type: 'date-no-timezone',
    field: 'contract_start_date',
    label: 'Start date',
  },
  {
    type: 'date-no-timezone',
    field: 'contract_end_date',
    label: 'End date',
  },
  {
    type: 'radio-select',
    field: 'location',
    selector: selectorKeys.location,
    label: 'Location',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'entity',
    selector: selectorKeys.entity,
    label: 'Company entity',
    valueKey: 'name',
  },
  {
    type: 'input',
    field: 'salary_amount',
    label: 'Salary amount',
  },
  {
    type: 'radio-select',
    field: 'salary_currency',
    selector: selectorKeys.currencies,
    label: 'Salary currency',
    valueKey: 'iso_code',
  },
  {
    type: 'radio-select',
    field: 'salary_time_unit',
    selector: selectorKeys.contract_salary_time_units,
    label: 'Salary time unit',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'salary_payment_frequency',
    selector: selectorKeys.contract_salary_payment_frequencies,
    label: 'Salary payment frequency',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'specialisation',
    selector: selectorKeys.specialisations,
    label: 'Specialisation',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'seniority',
    selector: selectorKeys.seniority,
    label: 'Seniority',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'subseniority',
    selector: selectorKeys.seniority_sublevels,
    label: 'Seniority sublevel',
    valueKey: 'name',
  },
  {
    type: 'input',
    field: 'job_title',
    label: 'Job title',
  },
  {
    type: 'radio-select',
    field: 'contract_status',
    selector: selectorKeys.employee_contract_status,
    label: 'Contract status',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'weekly_working_hours',
    label: 'Weekly working hours',
  },
  {
    type: 'input',
    field: 'full_time_equivalent',
    label: 'Full time equivalent',
  },
  {
    type: 'input',
    field: 'notice_period_during_probation',
    label: 'Notice period during probation',
  },
  {
    type: 'radio-select',
    field: 'notice_period_during_probation_unit',
    selector: selectorKeys.contract_notice_period_time_units,
    label: 'Notice period during probation time unit',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'notice_period_after_probation',
    label: 'Notice period after probation',
  },
  {
    type: 'radio-select',
    field: 'notice_period_after_probation_unit',
    selector: selectorKeys.contract_notice_period_time_units,
    label: 'Notice period after probation time unit',
    valueKey: 'id',
  },
]

export const useOptions = () => {
  const { options: contractTypeOptions } = useFetchOptions<IdAndName<ContractTypes>>(
    selectorKeys.contract_types,
    true,
  )
  const { options: contractTermOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_terms,
    true,
  )
  const { options: salaryTimeUnitOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_salary_time_units,
    true,
  )
  const { options: salaryPaymentFrequencyOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_salary_payment_frequencies,
    true,
  )
  const { options: signOnBonusTypeOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_sign_on_bonus_types,
    true,
  )
  const { options: noticePeriodTimeUnitOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_notice_period_time_units,
    true,
  )
  const { options: contractStatusOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.employee_contract_status,
    true,
  )

  return {
    contractTypeOptions,
    contractTermOptions,
    salaryTimeUnitOptions,
    salaryPaymentFrequencyOptions,
    signOnBonusTypeOptions,
    noticePeriodTimeUnitOptions,
    contractStatusOptions,
  }
}
