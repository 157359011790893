import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FileInterface } from '@src/interfaces/files'
import { FieldOptions, IdAndName, Statuses } from '@src/interfaces'

export type EmployeeEmailDirection = 'sent' | 'received'

export type EmployeeEmailTemplateCategories =
  | 'other'
  | 'offboarding'
  | 'onboarding'
  | 'onboarding_welcome_email'
  | 'onboarding_day_one_instructions'

export enum EmployeeWhenToSend {
  NOW = 'send_now',
  CUSTOM = 'custom_date',
}

export interface EmployeeEmailTemplateInterface {
  id: number
  name: string
  category: IdAndName
  subject: string
  description: string
  email_body: string
  recipients_cc?: string[]
  recipients_bcc?: string[]
  sender_type: EmployeeEmailSenderType
  owner: EmployeeOptionInterface
  attachments: (FileInterface | File)[] | null
  status: IdAndName<Statuses.active | Statuses.archived>
  field_options?: FieldOptions
}

export type EmployeeEmailSenderType = IdAndName<
  'user' | 'generic' | 'onboarding_settings'
>

export interface EmployeeEmailPlaceholderInterface {
  id: number
  name: string
  field_type: IdAndName<'text' | 'date'>
  creation_date_time: string
  update_date_time: string
}

export interface EmployeeEmailThreadInterface {
  id: number
  subject: string
  creation_date_time: string
  update_date_time: string
  latest_sent_date_time: string
  emails: SendEmployeeEmailInterface[]
}

export interface SendEmployeeEmailInterface
  extends Pick<
    EmployeeEmailTemplateInterface,
    | 'id'
    | 'sender_type'
    | 'subject'
    | 'recipients_cc'
    | 'recipients_bcc'
    | 'email_body'
    | 'attachments'
  > {
  status: IdAndName<'scheduled' | 'pending' | 'delivered' | 'canceled'>
  creation_date_time: string
  when_to_send?: IdAndName<EmployeeWhenToSend>
  custom_sending_datetime?: string
  snippet: string
  sender_employee?: EmployeeOptionInterface
  direction: IdAndName<EmployeeEmailDirection>
  category?: IdAndName<EmployeeEmailTemplateCategories> | null
  sender_email: string
  sender_name?: string
  recipient_email: string
  recipient_name?: string
  email_template?: IdAndName | null
  reply_email?: { id: number } | null
}
