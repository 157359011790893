import { Token } from '@revolut/ui-kit'

import { FaqStatus, FaqTopicStatus } from '@src/interfaces/faq'

export const faqStatusToColor = (status?: FaqStatus) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const faqTopicStatusToColor = (status?: FaqTopicStatus) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}
