import React from 'react'
import { InterviewerAvailability } from '@src/pages/Forms/Candidate/ScheduleSidebar/useGetInterviewersAvailability'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'
import { InterviewerAvailabilityLabel } from '@src/pages/Forms/Candidate/ScheduleSidebar/InterviewerAvailabilityLabel'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

type Props = {
  interviewersAvailability: InterviewerAvailability[]
}

export const InterviewerAvailabilityBanner = ({ interviewersAvailability }: Props) => {
  const getAvatar = () => {
    const available = new Set()
    const free = new Set()
    const unavailable = new Set()
    const unknown = new Set()

    interviewersAvailability.forEach(item => {
      if (item.id === 'SlotAvailable') {
        if (item.slot === 'interview') {
          available.add(item.interviewer.id)
        } else {
          free.add(item.interviewer.id)
        }
      } else if (item.id === 'WeekAvailable') {
        unknown.add(item.interviewer.id)
      } else {
        unavailable.add(item.interviewer.id)
      }
    })

    if (available.size === interviewersAvailability.length || free.size) {
      return (
        <Icon data-testid="available" name="CheckSuccess" color={Token.color.success} />
      )
    }

    if (unavailable.size === interviewersAvailability.length) {
      return <Icon data-testid="unavailable" name="CrossCircle" color={Token.color.red} />
    }

    if (unknown.size) {
      return (
        <Icon data-testid="unknown" name="QuestionOutline" color={Token.color.warning} />
      )
    }

    return <Icon data-testid="warning" name="InfoOutline" color={Token.color.warning} />
  }

  if (!interviewersAvailability.length) {
    return null
  }

  return (
    <ActionWidget
      data-testid="interviewerAvailabilityBanner"
      title="Selected interviewers availability"
      avatar={getAvatar()}
      text={
        <VStack gap="s-8">
          {interviewersAvailability.map(availability => (
            <HStack
              gap="s-6"
              align="center"
              key={availability.interviewer.id}
              aria-label={`${availability.interviewer.name} availability`}
            >
              <Text variant="caption" style={{ flexShrink: 0 }}>
                <UserWithAvatar {...availability.interviewer} />
              </Text>

              <InterviewerAvailabilityLabel availability={availability} />
            </HStack>
          ))}
        </VStack>
      }
    />
  )
}
