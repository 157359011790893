import { useEffect, useState } from 'react'
import {
  RoleInterface,
  SkillLevels,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { getSeniorityList } from '@src/api/seniority'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { rolesRequests } from '@src/api/roles'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'

interface UseSeniorityRangeOptions {
  onFetchRoleSuccess?: (role: RoleInterface) => void
}

export const useSeniorityRange = (options?: UseSeniorityRangeOptions) => {
  const { onFetchRoleSuccess } = options ?? {}
  const { values } = useLapeContext<SpecialisationInterface>()
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>([])
  const [loading, setLoading] = useState(false)
  const fetchSeniorities = async (fetchedRole?: RoleInterface) => {
    const response = await getSeniorityList({
      sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
    })

    const newSeniorities = fetchedRole
      ? response.data.results.filter(seniority => {
          const belowMinLevel =
            fetchedRole?.seniority_min?.level &&
            seniority.level < fetchedRole?.seniority_min?.level
          const exceedsMaxLevel =
            fetchedRole?.seniority_max?.level &&
            seniority.level > fetchedRole?.seniority_max?.level
          return !exceedsMaxLevel && !belowMinLevel
        })
      : response.data.results

    setSeniorities(newSeniorities)
    return newSeniorities
  }

  const fetchRole = async () => {
    if (values?.role?.id) {
      try {
        setLoading(true)
        const result = await rolesRequests.getItem(values.role.id)
        if (result.data) {
          onFetchRoleSuccess?.(result.data)
          await fetchSeniorities(result.data)
          if (!values.seniority_max) {
            values.seniority_max = result.data.seniority_max
          }

          if (!values.seniority_min) {
            values.seniority_min = result.data.seniority_min
          }
        }
      } finally {
        setLoading(false)
      }
    } else {
      fetchSeniorities()
    }
  }

  const onSeniorityRangeIncreased = (newSeniority: SeniorityInterface) => {
    if (
      values.functional_competency_matrix &&
      values.functional_competency_matrix.length
    ) {
      values.functional_competency_matrix = values.functional_competency_matrix.map(
        ({ skill, competencies }) => {
          if (!competencies) {
            return { skill, competencies }
          }

          const lastCompetency = competencies.at(-1)
          const lastSeniority = lastCompetency?.seniority_level || 0

          if (newSeniority.level < lastSeniority) {
            return { skill, competencies }
          }

          const resultCompetencies = competencies.concat(
            seniorities
              .filter(({ level }) => level > lastSeniority && level <= newSeniority.level)
              .map(seniority =>
                getDefaultCompetencyFor(
                  seniority,
                  (lastCompetency?.competency_level && {
                    min: lastCompetency.competency_level,
                  }) ||
                    undefined,
                ),
              ),
          )

          return {
            skill,
            competencies: resultCompetencies,
          }
        },
      )
    }

    if (values.deliverables_competencies) {
      const lastCompetency = values.deliverables_competencies.at(-1)
      const minLevel = lastCompetency?.competency_level || SkillLevels.Poor
      const minSeniority = lastCompetency?.seniority_level || 0
      const targetSeniorities = seniorities.filter(
        seniority =>
          seniority.level > minSeniority && seniority.level <= newSeniority.level,
      )
      values.deliverables_competencies = values.deliverables_competencies.concat(
        targetSeniorities.map(s => getDefaultCompetencyFor(s, { min: minLevel })),
      )
    }
  }

  const onSeniorityRangeDecreased = (newSeniority: SeniorityInterface) => {
    if (
      values.functional_competency_matrix &&
      values.functional_competency_matrix.length
    ) {
      values.functional_competency_matrix = (
        values.functional_competency_matrix || []
      ).map(({ skill, competencies }) => {
        if (!competencies) {
          return { skill, competencies }
        }

        const firstCompetency = competencies[0]
        const firstSeniority = firstCompetency.seniority_level || 0

        if (newSeniority.level > firstSeniority) {
          return { skill, competencies }
        }

        const resultCompetencies = [
          ...seniorities
            .filter(({ level }) => level < firstSeniority)
            .map(seniority =>
              getDefaultCompetencyFor(
                seniority,
                (firstCompetency.competency_level && {
                  max: firstCompetency.competency_level,
                }) ||
                  undefined,
              ),
            ),
          ...competencies,
        ]

        return {
          skill,
          competencies: resultCompetencies,
        }
      })
    }
    if (values.deliverables_competencies) {
      const firstCompetency = values.deliverables_competencies[0]
      const maxLevel = firstCompetency?.competency_level || SkillLevels.Poor
      const minSeniority = firstCompetency?.seniority_level || 0
      const targetSeniorities = seniorities.filter(
        seniority =>
          seniority.level < minSeniority && seniority.level >= newSeniority.level,
      )
      values.deliverables_competencies = [
        ...targetSeniorities.map(s => getDefaultCompetencyFor(s, { max: maxLevel })),
        ...values.deliverables_competencies,
      ]
    }
  }

  useEffect(() => {
    if (seniorities.length && values.functional_competency_matrix) {
      // it's needed for old roles those don't have seniority_level
      values.functional_competency_matrix = values.functional_competency_matrix.map(
        item => ({
          ...item,
          competencies: item.competencies?.map(competency => {
            const seniorityLevel = seniorities.find(
              seniority => seniority.id === competency.seniority_id,
            )?.level

            return { ...competency, seniority_level: seniorityLevel }
          }),
        }),
      )
    }
  }, [seniorities])

  useEffect(() => {
    fetchRole()
  }, [values.role])
  return { seniorities, loading, onSeniorityRangeIncreased, onSeniorityRangeDecreased }
}
