import React, { useState } from 'react'
import { Box, Button, Flex } from '@revolut/ui-kit'
import { Play } from '@revolut/icons'
import { AxiosPromise } from 'axios'

import LapeDatabaseSelect from '@src/components/Inputs/LapeFields/LapeDatabaseSelect'
import { selectorKeys } from '@src/constants/api'
import LapeNewCodeEditor from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import NewStaticTable from '@src/components/Table/NewStaticTable'
import { CellTypes } from '@src/interfaces/data'
import { useSafeFormValidator } from '@src/features/Form/FormValidator'
import { arrayErrorsToFormError } from '@src/utils/form'
import { ERRORS } from '@src/constants/notifications'

export type TestQueryData = Record<string, string | number | null>[]

export interface TestQueryResponse {
  data: TestQueryData
}

const typeToDatabaseSelectorMap = {
  'dynamic-groups': selectorKeys.dynamic_group_sql_query_databases,
  'notification-templates': selectorKeys.templated_notification_sql_query_dbs,
  'access-groups': selectorKeys.group_setting_sql_query_databases,
}

const typeToCodeEditorLabelMap = {
  'dynamic-groups': 'SQL Template',
  'notification-templates': 'SQL Query',
  'access-groups': 'SQL Query',
}

const employeeIdColumnMessage = `Please return a column 'employee_id' containing all the IDs of the employees who will be part of the group.`

const typeToCodeEditorMessageMap = {
  'dynamic-groups': employeeIdColumnMessage,
  'notification-templates':
    'It is mandatory to provide all the template tags used in the notification template as columns in the SQL response.',
  'access-groups': employeeIdColumnMessage,
}

interface SQLQueryProps {
  type: 'dynamic-groups' | 'notification-templates' | 'access-groups'
  api: () => AxiosPromise<TestQueryResponse>
  sqlQueryDbName?: string
  /** We need to map run query errors to form fields, the shape doesn't match, so we need to do it manually */
  queryFieldsToFormFields: Record<string, string>
}

export const SQLQuery = ({
  type,
  api,
  sqlQueryDbName,
  queryFieldsToFormFields,
}: SQLQueryProps) => {
  const [queryResponse, setQueryResponse] = useState<TestQueryResponse>()
  const [queryLoading, setQueryLoading] = useState(false)
  const [queryError, setQueryError] = useState<string>()

  const { forceErrors } = useSafeFormValidator()

  const runQuery = () => {
    setQueryError(undefined)
    setQueryLoading(true)
    setQueryResponse(undefined)
    api()
      .then(res => {
        if (Array.isArray(res.data?.data)) {
          setQueryResponse(res.data)
        } else if (
          'error' in res.data &&
          typeof res.data.error === 'object' &&
          res.data.error != null
        ) {
          let errorMessage = ERRORS.UNKNOWN

          if ('message' in res.data.error && typeof res.data.error.message === 'string') {
            errorMessage = res.data.error.message
          }

          if (
            'missing_columns' in res.data.error &&
            Array.isArray(res.data.error.missing_columns)
          ) {
            errorMessage = `${errorMessage} ${res.data.error.missing_columns.join(', ')}`
          }

          setQueryError(errorMessage)
        }
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        if (typeof errors === 'string') {
          setQueryError(errors)
          return
        }

        const formErrors = Object.entries(errors).reduce(
          (err, [key, value]) => ({ ...err, [queryFieldsToFormFields[key]]: value }),
          {},
        )
        forceErrors(formErrors)
      })
      .finally(() => setQueryLoading(false))
  }

  return (
    <>
      <Box>
        <LapeDatabaseSelect
          name={type === 'access-groups' ? 'settings.sql_query_db' : undefined}
          selector={typeToDatabaseSelectorMap[type]}
          label={sqlQueryDbName || 'Select database'}
        />
        <LapeNewCodeEditor
          name={type === 'access-groups' ? 'settings.sql_query' : 'sql_query'}
          placeholder={typeToCodeEditorLabelMap[type]}
          bottomInfo={typeToCodeEditorMessageMap[type]}
          error={queryError}
        />
      </Box>
      <Flex style={{ justifySelf: 'start' }}>
        <Button
          variant="secondary"
          onClick={runQuery}
          useIcon={Play}
          pending={queryLoading}
        >
          Run query
        </Button>
      </Flex>

      {queryResponse?.data?.length ? (
        <Box mb="s-16" maxHeight={360} overflow="auto">
          <NewStaticTable
            rows={{
              cells: Object.keys(queryResponse?.data[0]).map(key => ({
                dataPoint: key,
                idPoint: key,
                type: CellTypes.text,
                title: key,
                sortKey: null,
                filterKey: null,
                width: 200,
                selectorsKey: selectorKeys.none,
              })),
            }}
            data={queryResponse?.data}
            hasScrollableRow
          />
        </Box>
      ) : null}
    </>
  )
}
