import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { Avatar, Flex, InputGroup, Token, Widget } from '@revolut/ui-kit'
import { CompensationSettings } from '@src/interfaces/settings'
import { API, selectorKeys } from '@src/constants/api'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getDateFromString } from '@src/utils/timezones'

export const General = () => {
  const { values, submit } = useLapeContext<CompensationSettings>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.EnabledTotalCompensationApp)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General settings"
            subtitle="Settings which apply for all compensation functionalities"
          />
          <LapeNewSwitch
            disabled={disableEdit}
            itemTypeProps={{
              avatar: <Avatar color={Token.color.blue} size={40} useIcon="CreditBag" />,
              description:
                'You can choose to enable or disable compensation app across the product',
              title: (
                <Flex gap="s-8">
                  Enable the Compensation app <Recommended />
                </Flex>
              ),
            }}
            name="enabled"
          />

          <NewStepperTitle
            subtitle="Set the share value and the date it became effective, shown to employees in the equity screen. This value is for reference only and will not be used in any calculations"
            title="Share value"
          />
          <Widget p="s-16">
            <InputGroup>
              <InputGroup variant="horizontal">
                <LapeNewInput
                  label="Share value"
                  name="last_share_value"
                  required
                  type="number"
                />
                <LapeRadioSelectInput
                  label="Share currency"
                  name="last_share_value_currency"
                  required
                  selector={selectorKeys.currencies}
                />
              </InputGroup>
              <LapeNewDatePicker
                label="Share value updated"
                name="last_share_value_updated"
                required
              />
            </InputGroup>
          </Widget>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.COMPENSATION_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          onClick={() => {
            if (values.last_share_value_updated) {
              const updatedDate = getDateFromString(values.last_share_value_updated)
              values.last_share_value_updated = updatedDate
            }
            return submit()
          }}
          successText="Settings saved"
          useValidator
        />
      </PageActions>
    </>
  )
}
