import { Button, StatusPopup } from '@revolut/ui-kit'
import React from 'react'

interface SecurityErrorPopupProps {
  error?: string
  onClose: () => void
}

export const SecurityErrorPopup = ({ error, onClose }: SecurityErrorPopupProps) => {
  return (
    <StatusPopup variant="error" open={!!error} onClose={onClose}>
      <StatusPopup.Title>{error}</StatusPopup.Title>
      <StatusPopup.Actions>
        <Button elevated onClick={onClose}>
          Close
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}
