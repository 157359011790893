import { API } from '@src/constants/api'
import { DiversitySurveyInterface } from '@src/interfaces/diversitySurvey'
import { api, apiWithoutHandling } from '@src/api'
import { decodeToken } from '@src/utils/decodeToken'
import { OptionInterface } from '@src/interfaces/selectors'

export const getGenderSelector = async () => {
  const res = await api.get<OptionInterface[]>(
    API.APPLICANT_GENDERS,
    undefined,
    undefined,
    true,
  )
  return res.data
}

export const getEthnicitySelector = async () => {
  const res = await api.get<OptionInterface[]>(
    API.APPLICANT_ETHNICITIES,
    undefined,
    undefined,
    true,
  )
  return res.data
}

export const getOrientationSelector = async () => {
  const res = await api.get<OptionInterface[]>(
    API.APPLICANT_SEXUAL_ORIENTATIONS,
    undefined,
    undefined,
    true,
  )
  return res.data
}

export const getDisabilititySelector = async () => {
  const res = await api.get<OptionInterface[]>(
    API.APPLICANT_DISABILITIES,
    undefined,
    undefined,
    true,
  )
  return res.data
}

export const submitDiversitySurvey = (data: DiversitySurveyInterface, token: string) =>
  apiWithoutHandling.post(
    API.APPLICANT_DIVERSITY_SURVEY,
    data,
    {
      headers: {
        apitoken: decodeToken(token),
      },
    },
    undefined,
    true,
  )
