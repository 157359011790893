import React from 'react'
import { Item, Text, VStack } from '@revolut/ui-kit'

export const InitialIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>Welcome to Revolut People!</Item.Title>
        <Item.Description>
          <VStack gap="s-16" mt="s-8">
            <Text>
              At its core, this product is designed to revolutionize how you manage your
              organisation's human resources, performance assessments, and recruitment
              processes.
            </Text>
            <Text>
              <b>HR Management</b>
              <br />
              With our HR module, you can streamline employee data management, time
              tracking. Say goodbye to tedious paperwork and manual tracking. Efficiently
              manage your workforce with ease.
            </Text>
            <Text>
              <b>Performance Assessment</b>
              <br />
              Our Performance module empowers you to set clear goals, conduct timely
              evaluations, and foster a culture of continuous improvement. Boost
              productivity and employee engagement through meaningful performance
              assessments.
            </Text>

            <Text>
              <b>Recruitment</b>
              <br />
              When it comes to hiring top talent, our Recruitment module provides you with
              the tools to effortlessly source, screen, and onboard new employees.
              Simplify the recruitment process to make informed hiring decisions quickly.
            </Text>

            <Text>
              Over the next steps, we'll work closely with you to configure the specific
              features you want to use and help you set up your organisation within our
              platform. We're here to ensure that your HR processes are not only efficient
              but also tailored to your unique needs and goals.
            </Text>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
