import React from 'react'
import { Color } from '@revolut/ui-kit'
import { formatDistance, formatDistanceStrict } from 'date-fns'
import ActionWidget from '@components/ActionWidget/ActionWidget'

type Props = {
  extensionDate: string
  endOfProbationDate?: string
}

const ExtensionDateWarning = ({ extensionDate, endOfProbationDate }: Props) => {
  const baseDate = endOfProbationDate ? new Date(endOfProbationDate) : new Date()
  const strictDistance = formatDistanceStrict(new Date(extensionDate), baseDate, {
    unit: 'day',
  })

  const distance = formatDistance(new Date(extensionDate), baseDate)
  return (
    <ActionWidget
      mt="s-16"
      bg={Color.GROUPED_BACKGROUND}
      text={`This will extend the probation for ${strictDistance}${
        strictDistance !== distance ? ` (${distance})` : ''
      }.`}
    />
  )
}

export default ExtensionDateWarning
