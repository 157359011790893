import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { slackBotRequests } from '@src/api/integrations'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { ActionWidget, Box, InputGroup, Link } from '@revolut/ui-kit'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InfoOutline } from '@revolut/icons'
import { SLACK_APPS } from '@src/constants/externalLinks'
import { FormattedMessage } from 'react-intl'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { OnboardingChecklistHeader } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistHeader'

const Title = () => (
  <FormattedMessage
    id="integrations.slack.bot.title"
    defaultMessage="Register slack bot"
  />
)

const Description = () => (
  <FormattedMessage
    id="integrations.slack.bot.description"
    defaultMessage="Slack bot registration form"
  />
)

const SlackBot = () => {
  const isOnboarding = isOnboardingPath()
  return (
    <PageWrapper>
      {isOnboarding ? (
        <OnboardingChecklistHeader
          title={<Title />}
          description={<Description />}
          backUrl={pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.ALL)}
        />
      ) : (
        <PageHeader
          title={<Title />}
          subtitle={<Description />}
          backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATION.SLACK.ALL)}
        />
      )}
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Bot details" subtitle="Add the bot details here" />
          <InputGroup>
            <ActionWidget>
              <ActionWidget.Title>How to setup</ActionWidget.Title>
              <ActionWidget.Avatar>
                <InfoOutline color="orange" />
              </ActionWidget.Avatar>
              <ActionWidget.Content px="s-16">
                <ol>
                  <li>
                    Navigate to{' '}
                    <Link target="_blank" href={SLACK_APPS}>
                      {SLACK_APPS}
                    </Link>
                    , select <b>Create an App</b> & click on <b>From scratch</b> and then
                    give the Slack Bot a name and select your Slack workspace.
                  </li>
                  <li>
                    Click on the <b>Bots</b> box under the{' '}
                    <b>Add features and functionality header</b>.
                  </li>
                  <li>
                    Click on <b>Review scopes to add</b>.
                  </li>
                  <li>
                    Scroll down to the Bot token scopes header and add the following
                    permissions (these are the permissions the bot needs to write messages
                    and send files to users & Slack channels):
                    <Box pl="s-16">
                      <ul>
                        <li>users:read.email</li>
                        <li>channels:manage</li>
                        <li>groups:write</li>
                        <li>im:write</li>
                        <li>mpim:write</li>
                        <li>chat:write</li>
                        <li>users:read</li>
                        <li>channels:read</li>
                        <li>groups:read</li>
                        <li>im:read</li>
                        <li>mpim:read</li>
                        <li>channels:join</li>
                        <li>channels:write.invites</li>
                        <li>groups:write.invites</li>
                        <li>mpim:write.invites</li>
                      </ul>
                    </Box>
                  </li>
                  <li>
                    Finally, scroll all the way up and click on{' '}
                    <b>Install to workspace</b>, and
                    <b>Allow</b> on the following screen. This should now show a{' '}
                    <b>Bot User OAuth Token</b>. Copy this token since it’s the one we
                    will be using to send messages.
                  </li>
                  <li>
                    Register a new Slack Bot, inputting the previously copied{' '}
                    <b>Bot User OAuth Token</b> into the <b>OAuth Token</b> field.
                  </li>
                </ol>
              </ActionWidget.Content>
            </ActionWidget>

            <LapeNewInput
              name="name"
              label="Name"
              message="Give your Slack bot whatever name you would like"
              required
            />
            <LapeNewInput
              name="description"
              label="Description"
              message="Add more details about what the Slack bot will be used for"
              required
            />
            <LapeNewInput
              name="access_token"
              type="password"
              label="Bot User OAuth Token"
              message="This is the Bot User OAuth Token that you will have to copy from the Slack App settings"
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Slack bot saved successfully"
          afterSubmitUrl={ROUTES.FEATURES.INTEGRATION.SLACK.ALL}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={slackBotRequests}>
    <SlackBot />
  </Form>
))
