import React from 'react'
import { ItemSkeleton, VStack } from '@revolut/ui-kit'
import { EligibleGroup } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EligibleGroup'
import { EligibleGroupInterface } from '@src/interfaces/reviewCycles'

interface Props {
  groups: EligibleGroupInterface[]
  isLoading: boolean
}

export const EligibleGroupList = ({ groups, isLoading }: Props) => {
  if (isLoading) {
    return <ItemSkeleton />
  }

  return (
    <VStack px="s-16" pb="s-16" space="s-8">
      {groups.map(({ id, name, filters, preview, sign, should_create_scorecards }) => (
        <EligibleGroup
          key={id}
          title={name}
          employeesCount={preview?.count ?? 0}
          filtersCount={filters ? Object.keys(filters).length : 0}
          isEligible={sign !== 'exclude'}
          isScoreCardsEnabled={should_create_scorecards}
        />
      ))}
    </VStack>
  )
}
