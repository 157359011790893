import { EntityTypes } from '@src/constants/api'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import React, { useMemo } from 'react'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import {
  OrgEntityProvider,
  OrgEntityInterface,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'

export const Goals = () => {
  const { data, isLoading } = useGetCompany()

  const entity = useMemo<OrgEntityInterface | undefined>(
    () =>
      data
        ? {
            type: EntityTypes.company,
            data,
          }
        : undefined,
    [data],
  )

  if (isLoading) {
    return <Loader />
  }

  return (
    <OrgEntityProvider entity={entity}>
      <GoalsTab />
    </OrgEntityProvider>
  )
}
