import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import RiskCircles from '@components/ColumnInserts/RiskCircles/RiskCircles'
import React from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import Table from '@components/TableV2/Table'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TEAM_DEFAULT_ICON } from '@src/constants/common'

export const teamGenericNameColumn: ColumnInterface<TeamInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.team,
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.id }),
  title: 'Team',
}

export const teamNameIconColumn: ColumnInterface<TeamInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: data.id }),
  insert: ({ data, url }) => (
    <Table.EntityCell
      useIcon={data.icon || TEAM_DEFAULT_ICON}
      avatar={data.avatar}
      url={url}
    >
      {data.name}
    </Table.EntityCell>
  ),
}

export const circlesKriColumn: ColumnInterface<DepartmentInterface | TeamInterface> = {
  type: CellTypes.insert,
  idPoint: 'kris_above_level_1',
  dataPoint: 'kris_above_level_1',
  sortKey: 'total_kris_above_level',
  filterKey: null,
  insert: ({ data }) => {
    return <RiskCircles data={data} />
  },
  selectorsKey: selectorKeys.none,
  title: 'Risk',
}

export const teamNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const teamDepartmentColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'team.department.id',
  dataPoint: 'team.department.name',
  sortKey: 'team__department__name',
  filterKey: 'team__department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

const getApprovalStatusVariant = (status: ApprovalStatuses) => {
  switch (status) {
    case ApprovalStatuses.Pending:
      return 'warning'

    default:
      return 'neutral'
  }
}

export const teamStatusColumn: ColumnInterface<TeamInterface> = {
  type: CellTypes.insert,
  idPoint: 'approval_status',
  dataPoint: 'approval_status',
  sortKey: 'approval_status',
  filterKey: 'approval_status',
  selectorsKey: selectorKeys.teams_approval_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.approval_status}
      variant={getApprovalStatusVariant(data.approval_status)}
    />
  ),
}
