import React from 'react'
import { Text, Token, VStack } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const useStatusPopup = () => {
  const showStatusPopup = useShowStatusPopup()

  const showValidationCompleted = () => {
    showStatusPopup({ status: 'pending', title: 'Validation completed' })
  }

  const showValidationFailed = () => {
    showStatusPopup({ status: 'error', title: 'Validation failed' })
  }

  const showValidationOngoing = (progress: number) => {
    showStatusPopup({
      status: 'loading',
      title: (
        <VStack>
          <Text>Validating employees data</Text>
          <Text color={Token.color.accent}>{formatPercentage(progress)}</Text>
        </VStack>
      ),
      description:
        'We’re checking if we have all valid information for all employees in this cycle',
    })
  }

  return { showValidationCompleted, showValidationFailed, showValidationOngoing }
}
