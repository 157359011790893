import React from 'react'
import { Box, Color, Text, TextButton, Widget } from '@revolut/ui-kit'

type Props = {
  title: string
  text: string
  actionLabel?: string
  onClickAction?: () => void
  caption?: string
}

const ActionCard = ({ caption, title, text, actionLabel, onClickAction }: Props) => {
  return (
    <>
      {caption && (
        <Text use="div" fontWeight={500} color={Color.GREY_TONE_50} mb="s-16">
          {caption}
        </Text>
      )}
      <Widget p="s-16">
        <Text use="div" fontWeight={500} fontSize="primary">
          {title}
        </Text>
        <Text use="div" color={Color.GREY_TONE_50} mt="s-12">
          {text}
        </Text>
        {actionLabel && onClickAction && (
          <Box mt="s-12">
            <TextButton fontWeight={500} onClick={onClickAction}>
              {actionLabel}
            </TextButton>
          </Box>
        )}
      </Widget>
    </>
  )
}

export default ActionCard
