import { Token } from '@revolut/ui-kit'
import React from 'react'
import styled from 'styled-components'

const PerformanceTooltip = styled.div`
  color: white;
  font-size: 12px;
  line-height: 16px;
  width: 220px;
  padding: 10px;
`
const PerformanceTooltipLink = styled.a`
  margin-top: 6px;
  display: block;
  color: ${Token.color.background};
`

const List = styled.div`
  & > div:before {
    display: inline-block;
    content: '•';
    width: 10px;
  }
`

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 6px;
`

interface HeaderScoreTooltipProps {
  withLink?: boolean
}

export const HeaderScoreTooltip = ({ withLink }: HeaderScoreTooltipProps) => {
  return (
    <PerformanceTooltip>
      <Title>OVERALL SCORE</Title>
      <p>The score reflects all the feedback chosen below.</p>
      <div>Section contribution:</div>
      <List>
        <div>50% — Values</div>
        <div>50% — All other sections equally distributed</div>
      </List>
      {withLink && (
        <PerformanceTooltipLink
          target="_blank"
          href="https://revolut.atlassian.net/wiki/spaces/PEOP/pages/1330906998/Individual+performance+-+H2+2019"
        >
          More...
        </PerformanceTooltipLink>
      )}
    </PerformanceTooltip>
  )
}
