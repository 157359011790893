import { RequestInterface } from '../interfaces'
import { api } from './index'
import { API } from '../constants/api'
import { filterSortPageIntoQuery } from '../utils/table'
import { PlaybookInterface } from '../interfaces/playbooks'

export const playbooksRequests: RequestInterface<PlaybookInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.PLAYBOOKS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.PLAYBOOKS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.PLAYBOOKS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.PLAYBOOKS}/${id}`),
  postItem: async data => api.post(API.PLAYBOOKS, data),
}
