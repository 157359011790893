import { HiringPipelineInterface } from '@src/interfaces/hiringPipelines'
import { useFetch } from '@src/utils/reactQuery'

export const useGetHiringPipeline = (id: number | string) =>
  useFetch<HiringPipelineInterface>(`/hiringPipelines/${id}`)

export const useGetHiringPipelineByJobPostingId = (id: number | string) =>
  useFetch<{ results: HiringPipelineInterface[] }>('/hiringPipelines', undefined, {
    params: {
      job_posting__id: id,
    },
  })
