import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import {
  employeeEmailsRequests,
  getPrefilledTemplate,
  useEmployeeEmailThread,
} from '@src/api/employeeEmails'
import { ROUTES } from '@src/constants/routes'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import EmployeeEmailThread from '@src/features/EmailThread/EmailThread'
import Form from '@src/features/Form/Form'
import SendEmployeeEmailCommon from '@src/pages/Forms/SendEmployeeEmail/SendEmployeeEmailCommon'
import { pathToUrl } from '@src/utils/router'

const Employee = () => {
  const { employeeId, threadId, emailId } =
    useParams<{ employeeId: string; emailId?: string; threadId?: string }>()

  const { data: thread } = useEmployeeEmailThread(employeeId, threadId)

  const replyEmail = useMemo(
    () => (emailId ? thread?.emails?.find(item => item.id === +emailId) : undefined),
    [thread, emailId],
  )

  const fetchEmail = (templateId: number) => {
    return getPrefilledTemplate(templateId, +employeeId)
  }

  const onSuccess = (email: SendEmployeeEmailInterface) => {
    if (threadId) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.ONBOARDING_SEND_EMAIL_V2.VIEW, {
          employeeId,
          threadId,
          emailId: email.id,
        }),
      )
    } else {
      goBack()
    }
  }

  return (
    <SendEmployeeEmailCommon
      bottom={
        thread ? (
          <>
            <NewStepperTitle
              title="You are replying to the below email"
              type={NewStepperTitleType.compact}
            />
            <EmployeeEmailThread emails={thread.emails} emailId={emailId} />
          </>
        ) : null
      }
      fetchEmail={fetchEmail}
      onSuccess={onSuccess}
      replyEmail={replyEmail}
      templateFilter={item => item.category.id === 'onboarding'}
      useOnboardingSettings
    />
  )
}

export const General = connect(() => (
  <Form api={employeeEmailsRequests}>
    <Employee />
  </Form>
))
