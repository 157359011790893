import React from 'react'
import styled from 'styled-components'
import { CellTypes, RowInterface } from '@src/interfaces/data'
import { Avatar, Box, Ellipsis, Flex, Grid, Icon, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'

import {
  JiraIssueInterface,
  ProbationGoalInterface,
} from '@src/interfaces/probationReview'
import AddTicketDropdown from '@src/pages/Forms/ProbationOverview/ProbationGoals/AddTicketDropdown'
import { getIconKeyByIssue } from '@src/utils/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import Table from '@src/components/TableV2/Table'
import produce from 'immer'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { TableNames } from '@src/constants/table'

const RemoveBtn = styled.button`
  color: ${Token.color.greyTone50};

  &:hover {
    color: ${Token.color.blue};
  }
`

const RowLink = styled.a`
  text-decoration: none;
  color: inherit;
`

const Key = styled.span`
  color: ${Token.color.blue};
`

interface Props {
  canEdit: boolean
  tickets: ProbationGoalInterface[]
  onDelete?: (id: number) => void
  onChange?: (option?: JiraIssueInterface) => void
}

const statusToColor = (status: string) => {
  switch (status) {
    case 'To Do':
      return Token.color.greyTone50
    case 'Done':
      return Token.color.green
    case 'In Progress':
      return Token.color.foreground
    default:
      return Token.color.foreground
  }
}

const ROW: RowInterface<ProbationGoalInterface> = {
  cells: [
    {
      type: CellTypes.insert,
      idPoint: 'name',
      dataPoint: 'name',
      title: 'Jira',
      width: 390,
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      insert: ({ data }) => (
        <Table.ItemCell>
          <Grid gap="s-8" placeItems="center" columns="auto auto 1fr">
            {data.jira_issue_type && (
              <Avatar useIcon={getIconKeyByIssue(data.jira_issue_type)} />
            )}
            <RowLink
              href={data.jira_issue_url!}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Key>{data.jira_issue_key}</Key>
              <Text color={Token.color.foreground}>:</Text>
            </RowLink>
            <Ellipsis>
              <Text>{data.jira_issue_summary}</Text>
            </Ellipsis>
          </Grid>
        </Table.ItemCell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'status',
      dataPoint: 'status',
      title: 'Progress',
      width: 100,
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      insert: ({ data }) => (
        <Table.ItemCell>
          <Flex justifyContent="flex-end">
            {data.jira_issue_status ? (
              <Text
                color={statusToColor(data.jira_issue_status)}
                style={{ textTransform: 'capitalize' }}
              >
                {data.jira_issue_status.toLowerCase()}
              </Text>
            ) : (
              ' - '
            )}
          </Flex>
        </Table.ItemCell>
      ),
    },
    {
      type: CellTypes.insert,
      idPoint: 'due_date',
      dataPoint: 'due_date',
      title: 'Deadline',
      width: 100,
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      insert: ({ data }) => (
        <Table.ItemCell>
          {data.jira_issue_duedate ? (
            formatDate(data.jira_issue_duedate)
          ) : (
            <Tooltip placement="top" text="Due date is missing">
              -
            </Tooltip>
          )}
        </Table.ItemCell>
      ),
    },
  ],
}

const Tickets = ({ canEdit, tickets, onDelete, onChange }: Props) => {
  const row = produce(ROW, draft => {
    if (canEdit && onDelete) {
      draft.cells.push({
        type: CellTypes.insert,
        idPoint: 'remove',
        dataPoint: 'remove',
        title: 'Remove',
        width: 40,
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.none,
        insert: ({ data }) => (
          <Table.ItemCell>
            <RemoveBtn
              type="button"
              onClick={() => {
                onDelete(data.id!)
              }}
            >
              <Icon name="Delete" size="tiny" />
            </RemoveBtn>
          </Table.ItemCell>
        ),
      })
    }
  })

  const onFilter = (issue: JiraIssueInterface) =>
    !tickets.find(ticket => ticket.jira_issue === +issue.id)

  return (
    <>
      {!!tickets.length && (
        <Table.Widget>
          <AdjustableTable
            data={tickets}
            emptyState={<EmptyTableRaw title="No tickets defined." />}
            count={tickets.length}
            name={TableNames.JiraLegacyProbaitonGoals}
            row={row}
          />
        </Table.Widget>
      )}

      {canEdit && onChange && (
        <Box mt="s-16">
          <AddTicketDropdown<ProbationGoalInterface>
            disabled={!canEdit}
            onFilter={onFilter}
            onChange={onChange}
            data={tickets}
          />
        </Box>
      )}
    </>
  )
}

export default Tickets
