import React from 'react'
import { StatusWidget } from '@revolut/ui-kit'

import { FormPreview } from '@components/FormPreview/FormPreview'
import { EmployeeInterface } from '@src/interfaces/employees'

type Props = {
  data: EmployeeInterface
  title: string
  children: React.ReactNode
}
export const WarnIfProfileDataAnonymised = ({ data, title, children }: Props) => {
  if (data.anonymized?.is_anonymous) {
    return (
      <FormPreview title={title}>
        <StatusWidget>
          <StatusWidget.Image
            src="https://assets.revolut.com/assets/3d-images/3D173a.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D173a@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D173a@3x.png 3x"
          />
          <StatusWidget.Title>This data has been anonymised</StatusWidget.Title>
          <StatusWidget.Description>
            You cannot see this information because the data retention period for this
            information has ended.
          </StatusWidget.Description>
        </StatusWidget>
      </FormPreview>
    )
  }
  return <>{children}</>
}
