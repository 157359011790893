import React from 'react'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import CandidateSendEmailSidebar from '@components/CandidateSendEmailSidebar/CandidateSendEmailSidebar'

type Props = {
  candidateId?: number
  onRefetch: () => void
}

export const CandidateEmailSendProfileSidebar = ({ candidateId, onRefetch }: Props) => {
  const { activeAction, resetActiveAction } = useCandidateProfileContext()

  if (activeAction?.type !== 'sendEmail' || !candidateId) {
    return null
  }

  return (
    <CandidateSendEmailSidebar
      isOpen
      candidateIds={[candidateId]}
      onRefetch={() => {
        resetActiveAction()
        onRefetch()
      }}
      onClose={resetActiveAction}
    />
  )
}
