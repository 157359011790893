// TODO: this component should be moved to UI kit, but we have to release it asap DSDEV-3312-new-weekly-large-calendar
// it's already reviewed by UI kit but having some issues with build https://bitbucket.org/revolut/revolut-ui-kit/pull-requests/2867

import '@toast-ui/calendar/dist/toastui-calendar.min.css'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import type { Options } from '@toast-ui/calendar'
import Calendar from '@toast-ui/calendar'
import styled, { css } from 'styled-components'
import { diffPeriod, getAllDaysBetweenDates, getFormattedTime } from './date'
import { ActionButton, Box, Flex, HStack, IconButton, Text, Token } from '@revolut/ui-kit'

import { getCalendarTheme } from './theme'
import {
  DateType,
  LargeWeeklyCalendarChangeEventInterface,
  LargeWeeklyCalendarClickEventInterface,
  LargeWeeklyCalendarCreateEventInterface,
  LargeWeeklyCalendarEventInterface,
  LargeWeeklyCalendarEventViewType,
  TimezoneOptions,
} from './types'
import { HeaderCellWrapper } from '@components/Table/HeaderCell'
import { TableTypes } from '@src/interfaces/table'
import { defaultTheme } from '@src/styles/theme'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { formatTime } from '@src/utils/format'

export interface LargeWeeklyCalendarProps {
  events: LargeWeeklyCalendarEventInterface[]
  onAddEvent?: (event: LargeWeeklyCalendarCreateEventInterface) => void
  onClickEvent?: (params: LargeWeeklyCalendarClickEventInterface) => void
  onChangeEvent?: (event: LargeWeeklyCalendarChangeEventInterface) => void
  onSwitchWeek?: (startDate: Date, endDate: Date) => void
  disabled?: boolean
  eventView?: boolean | LargeWeeklyCalendarEventViewType[]
  startDayOfWeek?: number
  workweek?: boolean
  side?: React.ReactNode
  weekStartDate?: Date
  timezone?: TimezoneOptions
  useWindowScroll?: boolean
}

export const SECOND = 1000
export const MINUTE = SECOND * 60
export const LARGE_WEEKLY_CALENDAR_MIN_WIDTH = 755
const FULL_HEIGHT = 1400

const Header = styled.div<{
  useWindowScroll?: boolean
  top?: number
  width?: number
}>`
  overflow-x: hidden;
  width: ${({ width }) => (width ? `${width}px` : undefined)};
  display: flex;
  border: 1px solid ${Token.color.greyTone8};
  z-index: ${defaultTheme.zIndex.aboveMain + 2};
  position: ${({ useWindowScroll }) => (useWindowScroll ? 'sticky' : 'relative')};
  top: ${({ top }) => top ?? 0}px;
  background: ${Token.color.greyTone2};
  flex: 0 0 auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow-x: auto;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

const windowScrollCalendarCSS = css`
  .toastui-calendar-timegrid {
    height: 100%;
  }
  .toastui-calendar-panel.toastui-calendar-time {
    overflow-y: inherit;
  }
`

const Wrap = styled(Box)<{ useWindowScroll?: boolean }>`
  .toastui-calendar-week-view-day-names {
    display: none;
  }

  .toastui-calendar-allday {
    border-left: 1px solid ${Token.color.greyTone8};
  }

  .toastui-calendar-time {
    border-left: 1px solid ${Token.color.greyTone8};
    border-bottom: 1px solid ${Token.color.greyTone8};
    border-right: 1px solid ${Token.color.greyTone8};
  }

  .toastui-calendar-timezone-labels-slot {
    background: ${Token.color.widgetBackground};
    border-bottom: 1px solid ${Token.color.greyTone8};
  }

  .toastui-calendar-timegrid-timezone-label {
    background: ${Token.color.widgetBackground};
    color: ${Token.color.greyTone50};
    border-right: 1px solid ${Token.color.greyTone8};

    [data-theme-transparent='true'] & {
      background: ${Token.color.groupedBackground};
    }
  }

  ${({ useWindowScroll }) => useWindowScroll && windowScrollCalendarCSS};
`

export const getDateFromEventDate = (eventDate?: DateType) => {
  if (!eventDate) {
    return null
  }

  if (typeof eventDate === 'string' || typeof eventDate === 'number') {
    return new Date(eventDate)
  }

  if ('toDate' in eventDate) {
    return eventDate.toDate()
  }

  return eventDate
}

const DraggableMatrixCss = css`
  right: -3.5px;
`

const Draggable = styled.div<{ type: TableTypes }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  width: 7px;
  cursor: col-resize;
  ${({ type }) => type === TableTypes.Matrix && DraggableMatrixCss};

  &::after {
    content: '';
    width: 1px;
    transition: background-color 200ms ease-in-out;
    height: 16px;
    background-color: ${Token.color.greyTone50};
  }
`

const TableCellShow = styled.div<{ centered?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding-right: ${props => (props.centered ? 0 : 12)}px;

  &:hover > ${Draggable}::after {
    opacity: 1;
  }
`

const Title = styled.div<{ active: boolean; centered?: boolean }>`
  padding: ${props => (props.centered ? '10px 0' : '10px 0 10px 16px')};
  flex: 1;
  text-align: ${props => (props.centered ? 'center' : 'inherit')};
  text-transform: inherit;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  color: ${props => (props.active ? Token.color.blue_60 : Token.color.greyTone50)};
`

const HEADER_HEIGHT_PX = 80
const LONG_EVENT_MINUTES_THRESHOLD = 45

const defaultZones = { zones: [] }

export function LargeWeeklyCalendar({
  onAddEvent,
  onClickEvent,
  onChangeEvent,
  weekStartDate,
  onSwitchWeek,
  events,
  disabled,
  eventView = true,
  startDayOfWeek,
  workweek,
  side,
  timezone = defaultZones,
  useWindowScroll = false,
}: LargeWeeklyCalendarProps) {
  const ref = useRef<HTMLDivElement | null>(null)
  const wrapRef = useRef(null)
  const calendarRef = useRef<Calendar | null>(null)
  const weekSwitcherRef = useRef<HTMLDivElement | null>(null)
  const mounted = useRef(false)

  const [columns, setColumns] = useState<{ id: string; title: string; width?: number }[]>(
    [],
  )

  const updateHeader = () => {
    if (!calendarRef.current) {
      return
    }

    const dates = getAllDaysBetweenDates(
      calendarRef.current.getDateRangeStart().toDate(),
      calendarRef.current.getDateRangeEnd().toDate(),
    )

    setColumns([
      {
        title: '',
        width: 60,
        id: 'day',
      },
      ...dates.map(date => {
        const day = date.toLocaleDateString('en-EN', {
          day: 'numeric',
          month: 'short',
          weekday: 'short',
        })
        return {
          title: day,
          id: day,
        }
      }),
    ])
  }

  const getTimeTemplate = useCallback((event: LargeWeeklyCalendarEventInterface) => {
    const startDate = getDateFromEventDate(event.start)
    const endDate = getDateFromEventDate(event.end)
    const isLongEvent =
      startDate &&
      endDate &&
      diffPeriod(endDate, startDate, MINUTE) > LONG_EVENT_MINUTES_THRESHOLD
    const textColor = event.color || Token.color.black

    if (isLongEvent) {
      const timeColor = event.color || Token.color.greyTone50
      const titleLabel = event.title
        ? `<span style='color: ${textColor}; font-size: 12px; white-space: pre-wrap; line-height: 18px;'>${event.title}</span>`
        : ''
      const timeLabel =
        !event.raw?.hideTime && startDate && endDate
          ? `<div style="color: ${timeColor}; font-size: 10px; padding-top: 2px;">${getFormattedTime(
              startDate,
            )} - ${getFormattedTime(endDate)}</div>`
          : ''

      return `${titleLabel}${timeLabel}${event.body}`
    }

    return `<span style="color: ${textColor}; white-space: pre-wrap; font-size: 12px;">${
      event.title
    }${event.title && startDate && !event.raw?.hideTime ? ', ' : ''}${
      startDate && !event.raw?.hideTime ? getFormattedTime(startDate) : ''
    }</span>`
  }, [])

  useEffect(() => {
    const options: Options = {
      usageStatistics: false,
      defaultView: 'week',
      week: {
        taskView: false,
        eventView,
        startDayOfWeek,
        workweek,
      },
      template: {
        alldayTitle() {
          return ''
        },
        time: getTimeTemplate,
        timegridDisplayPrimaryTime: ({ time }) => formatTime(time.toString()),
        timegridDisplayTime: ({ time }) => formatTime(time.toString()),
        timegridNowIndicatorLabel: ({ time }) => formatTime(time.toString()),
      },
      theme: getCalendarTheme({ gridLeftColumnsCount: timezone?.zones?.length || 1 }),
    }

    if (ref.current) {
      calendarRef.current = new Calendar(ref.current, options)
      mounted.current = true
      updateHeader()
    }

    return () => {
      calendarRef.current?.destroy()
      mounted.current = false
    }
    // we have to re-create the Calendar, because it does not support changing workweek or theme settings once created
  }, [workweek, timezone])

  useEffect(() => {
    if (weekStartDate && calendarRef.current) {
      calendarRef.current.setDate(weekStartDate)
    }
  }, [weekStartDate])

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.setOptions({
        isReadOnly: disabled,
        week: {
          eventView,
          startDayOfWeek,
        },
        template: {
          time: getTimeTemplate,
        },
        timezone,
      })

      updateHeader()
    }
  }, [disabled, eventView, startDayOfWeek, getTimeTemplate, timezone])

  useEffect(() => {
    if (calendarRef.current) {
      calendarRef.current.clear()
      calendarRef.current.createEvents(
        events.map(event => ({
          backgroundColor: Token.color.actionBlue,
          dragBackgroundColor: Token.color.actionBlue_70,
          borderColor: 'none',
          ...event,
          id: event.id || `${event.start?.toString()}${event.end?.toString()}`,
          title: event.title,
        })),
      )
    }
  }, [events, workweek])

  useEffect(() => {
    if (!calendarRef.current) {
      return () => {}
    }

    calendarRef.current.on('selectDateTime', event => {
      onAddEvent?.(event)
      calendarRef.current?.clearGridSelections()
    })

    if (onClickEvent) {
      calendarRef.current.on('clickEvent', onClickEvent)
    }

    if (onChangeEvent) {
      calendarRef.current.on('beforeUpdateEvent', onChangeEvent)
    }

    return () => {
      // when the library destroys the instance it removes all properties from the internal object, leading to issues when we call calendarRef.current?.off right after we destroyed the instance
      if (mounted.current) {
        calendarRef.current?.off('selectDateTime')
        calendarRef.current?.off('clickEvent')
        calendarRef.current?.off('beforeUpdateEvent')
      }
    }
  }, [onAddEvent, onClickEvent, onChangeEvent, workweek])

  const onHandleSwitchWeek = () => {
    updateHeader()

    if (calendarRef.current && onSwitchWeek) {
      onSwitchWeek(
        calendarRef.current.getDateRangeStart().toDate(),
        calendarRef.current.getDateRangeEnd().toDate(),
      )
    }
  }

  return (
    <>
      <Wrap
        ref={wrapRef}
        height="100%"
        minWidth={LARGE_WEEKLY_CALENDAR_MIN_WIDTH}
        useWindowScroll={useWindowScroll}
      >
        {calendarRef.current && (
          <Flex justifyContent="space-between" mb="s-16">
            <HStack gap="s-16" align="center" ref={weekSwitcherRef}>
              <IconButton
                useIcon="ChevronLeft"
                onClick={() => {
                  calendarRef.current?.prev()
                  onHandleSwitchWeek()
                }}
                aria-label="Previous week"
              />
              <IconButton
                useIcon="ChevronRight"
                onClick={() => {
                  calendarRef.current?.next()
                  onHandleSwitchWeek()
                }}
                aria-label="Next week"
              />
              <Text variant="heading3">
                {calendarRef.current
                  .getDateRangeStart()
                  .toDate()
                  .toLocaleString('default', { month: 'long', year: 'numeric' })}
              </Text>
              <ActionButton
                size="xs"
                onClick={() => {
                  calendarRef.current?.today()
                  onHandleSwitchWeek()
                }}
              >
                Today
              </ActionButton>
            </HStack>
            {side && <Box>{side}</Box>}
          </Flex>
        )}
        <Header
          useWindowScroll={useWindowScroll}
          top={useWindowScroll ? mainHeaderState.height : undefined}
        >
          {columns.map(cell => {
            return (
              <HeaderCellWrapper
                key={cell.id}
                type={TableTypes.Adjustable}
                width={cell.width}
                rowHeight="medium"
              >
                <TableCellShow>
                  <Title active={false}>{cell.title}</Title>
                </TableCellShow>
              </HeaderCellWrapper>
            )
          })}
        </Header>

        <div
          ref={ref}
          style={{
            height: useWindowScroll
              ? `${FULL_HEIGHT}px`
              : `calc(100% - ${HEADER_HEIGHT_PX}px)`,
          }}
        />
      </Wrap>
    </>
  )
}
