import React from 'react'

import {
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainName,
} from '@src/interfaces/payrollV2'

import { FieldConfig } from '.'
import {
  formatNewContractDomain,
  formatNoticePeriodDomain,
  formatSalaryDomain,
  formatSeniorityDomain,
  formatSignOnBonusDomain,
  formatTimeOffDomain,
} from './domainToCustomWidget'

export const domainNameToFieldsConfig: Record<
  PayrollTimelineDomainName,
  {
    fields: Array<string | FieldConfig>
    title?: string
    renderAll?: (change: PayrollTimelineChangeInterface) => React.ReactNode
  }
> = {
  // Work details:
  employee_name: {
    fields: ['name', 'full_name', 'first_name', 'middle_name', 'last_name'],
  },
  employee_status: {
    fields: [
      {
        path: 'status',
        label: 'Employee status',
      },
    ],
  },
  end_of_probation: {
    fields: [
      { path: 'end_of_probation_date_time', label: 'End of probation', type: 'date' },
    ],
  },
  employee_type: { fields: ['employee_type'] },

  // Contract:
  contract: {
    renderAll: formatNewContractDomain,
    fields: [
      'approval_status',
      'company_entity',
      'contract_status',
      'contract_term',
      'contract_type',
      { path: 'end_date', type: 'date' },
      'full_time_equivalent',
      { path: 'inactivity_end_date', type: 'date' },
      'inactivity_reason',
      'inactivity_start_date',
      'location',
      'notice_period_after_probation',
      'notice_period_after_probation_unit',
      'notice_period_during_probation',
      'notice_period_during_probation_unit',
      'position',
      'salary_amount',
      'salary_currency',
      'salary_payment_frequency',
      'salary_time_unit',
      'seniority',
      'sign_on_bonus_amount',
      'sign_on_bonus_currency',
      'sign_on_bonus_type',
      'specialisation',
      'specialisation_seniority_sublevel',
      { path: 'start_date', type: 'date' },
      'title',
      'weekly_working_hours',
    ],
  },
  contract_type: { fields: [{ path: 'contract_type', type: 'snakeCase' }] },
  contract_status: {
    fields: [
      {
        path: 'contract_status',
        label: 'Contract status',
      },
    ],
  },
  contract_term: { fields: ['contract_term'] },
  start_date: { fields: [{ path: 'start_date', type: 'date' }] },
  end_date: { fields: [{ path: 'end_date', type: 'date' }] },
  entity: { fields: ['entity'] },
  location: { fields: ['location'] },
  salary: {
    renderAll: formatSalaryDomain,
    fields: [
      'salary_amount',
      'salary_currency',
      'salary_payment_frequency',
      'salary_time_unit',
    ],
  },
  sign_on_bonus: {
    renderAll: formatSignOnBonusDomain,
    fields: ['sign_on_bonus_amount', 'sign_on_bonus_currency', 'sign_on_bonus_type'],
  },
  position: { fields: ['position'] },
  specialisation: { fields: ['specialisation'] },
  seniority: {
    renderAll: formatSeniorityDomain,
    fields: ['seniority', 'specialisation_seniority_sublevel'],
  },
  job_title: { fields: ['title'] },
  weekly_working_hours: { fields: ['weekly_working_hours'] },
  full_time_equivalent: { fields: ['full_time_equivalent'] },
  notice_period_during_probation: {
    renderAll: formatNoticePeriodDomain,
    fields: [
      { path: 'notice_period_during_probation', label: 'Value' },
      { path: 'notice_period_during_probation_unit', label: 'Unit' },
    ],
  },
  notice_period_after_probation: {
    renderAll: formatNoticePeriodDomain,
    fields: [
      { path: 'notice_period_after_probation', label: 'Value' },
      { path: 'notice_period_after_probation_unit', label: 'Unit' },
    ],
  },
  inactivity: {
    fields: [
      { path: 'inactivity_reason', type: 'snakeCase' },
      { path: 'inactivity_start_date', type: 'date' },
      { path: 'inactivity_end_date', type: 'date' },
    ],
  },
  approval_status: {
    fields: [{ path: 'approval_status' }],
  },

  // Personal details:
  personal_email: { fields: ['personal_email'] },
  legal_sex: { fields: ['legal_sex'] },
  marital_status: { fields: ['marital_status'] },
  birth_date: { fields: [{ path: 'birth_date', type: 'date' }] },
  phone_number: { fields: ['phone_number', 'phone_number_short', 'phone_country_code'] },
  nationality: { fields: ['nationality'] },
  nationalities: { fields: ['nationalities'] },
  languages: { fields: ['languages'] },
  address: {
    fields: [
      'country',
      'county',
      'city',
      'address_line_1',
      'address_line_2',
      'address_line_3',
      'post_code',
    ],
  },
  emergency_contract: {
    title: 'Emergency contact',
    fields: [
      'emergency_contact_full_name',
      'emergency_contact_email',
      'emergency_contact_mobile_phone',
      'emergency_contact_phone_country_code',
      'emergency_contact_phone_number',
      'emergency_contact_relationship',
    ],
  },

  // Time Off:
  time_off_request: {
    renderAll: formatTimeOffDomain,
    fields: [
      'duration',
      'from_date_time',
      { path: 'start_date_time', type: 'date' },
      { path: 'end_date_time', type: 'date' },
      'total_duration',
      'balance',
      'unit',
    ],
  },
}
