import { UseTableOptions, useTable } from '@src/components/Table/hooks'
import { useLocation } from 'react-router-dom'
import { useLocalStorage } from './useLocalStorage'
import { FilterByInterface, SortByInterface, Stats } from '@src/interfaces/data'
import { useEffect, useMemo, useRef, useState } from 'react'
import { isEqual, omit } from 'lodash'
import { tableRequests } from '@src/interfaces'
import { queryToFilter } from '@src/utils/table'
import { useQuery } from '@src/utils/queryParamsHooks'

export const useTableWithPersistentFilters = <
  T,
  S = Stats,
  M extends Record<string, any> = {},
>(
  request: tableRequests<T, S, M>,
  filterByInitial: FilterByInterface[] = [],
  sortByInitial: SortByInterface[] = [],
  options: UseTableOptions = {},
  customKey?: string,
  ignoreColumns?: string[],
) => {
  const { query } = useQuery(false)
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(true)
  const table = useTable(request, filterByInitial, sortByInitial, {
    disable: loading,
    ...options,
  })
  const { ordering, ...filters } = query
  const filtersFromQuery = useMemo(
    () => queryToFilter(omit(filters, options.omitKeys!), filterByInitial),
    [],
  )
  const [savedFilters, setSavedFilters] = useLocalStorage<FilterByInterface[]>(
    customKey || `${pathname}-table-filters`,
    [],
  )
  const isInitialMount = useRef(true)

  const filterIgnoredColumns = (filterBy: FilterByInterface[]) =>
    filterBy.filter(filter => !ignoreColumns?.includes(filter.columnName))

  useEffect(() => {
    const newFilters = [
      ...filterIgnoredColumns(savedFilters),
      ...(filterByInitial || []),
      ...filtersFromQuery,
    ]

    table.onFilterChange(newFilters)

    isInitialMount.current = false
    setLoading(false)
  }, [])

  useEffect(() => {
    if (isInitialMount.current) {
      return
    }
    const newFilters = filterIgnoredColumns(table.filterBy)

    if (!isEqual(savedFilters, newFilters)) {
      setSavedFilters(newFilters)
    }
  }, [table.filterBy, savedFilters])

  return table
}
