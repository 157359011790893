import React from 'react'
import { Button, Header, Popup, VStack } from '@revolut/ui-kit'
import StageDetails from '@src/pages/Forms/HiringStage/FormSections/StageDetails'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import EligibleInterviewers from '@src/pages/Forms/HiringStage/FormSections/EligibleInterviewers'
import {
  formHiringProcessStagesRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormattedMessage } from 'react-intl'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'

type HiringStagePopupProps = {
  disableStageType: boolean
  isSpecialisation: boolean
  onClose: (result?: HiringProcessInterface) => void
  onRefresh: () => void
}

const HiringStagePopup = ({
  disableStageType,
  isSpecialisation,
  onClose,
  onRefresh,
}: HiringStagePopupProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const isNewStage = !values?.id

  return (
    <Popup open onClose={onClose} shouldKeepMaxHeight size="md">
      <Header variant="compact">
        <Header.Title>
          {isNewStage
            ? `Add ${values?.stage_type?.name ?? 'stage'}`
            : `Edit ${values.title}`}
        </Header.Title>
      </Header>
      <VStack gap="s-16">
        <StageDetails
          disableStageType={disableStageType}
          requiredDuration
          isSpecialisation={isSpecialisation}
          isRole={false}
          hiddenFields={['owner', 'skills', 'playbook', 'stageType']}
          scorecard={<LapeScorecardField canAddNewScorecard isClearable isOnboarding />}
        />
        <EligibleInterviewers isRequired />
      </VStack>
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            isNewStage
              ? `New ${values.stage_type?.name} saved successfully`
              : `${values.title} saved successfully`
          }
          hideWhenNoChanges={false}
          onAfterSubmit={result => {
            onClose(result as HiringProcessInterface)
            onRefresh()
          }}
        >
          {isNewStage ? (
            <FormattedMessage id="onboarding.interviews.stage.add" defaultMessage="Add" />
          ) : (
            <FormattedMessage
              id="onboarding.interviews.stage.save"
              defaultMessage="Save changes"
            />
          )}
        </NewSaveButtonWithPopup>
      </Popup.Actions>
    </Popup>
  )
}

type HiringStagePopupFormProps = HiringStagePopupProps & {
  stage?: Partial<HiringProcessInterface>
}

export default (props: HiringStagePopupFormProps) => {
  const api = props.isSpecialisation
    ? formHiringProcessStagesSpecialisationRequest
    : formHiringProcessStagesRequest
  return (
    <Form
      api={api}
      forceParams={{ id: props.stage?.id ? props.stage.id.toString() : undefined }}
      initialValues={props.stage}
      disableLocalStorageCaching
      disableLoading
    >
      <HiringStagePopup {...props} />
    </Form>
  )
}
