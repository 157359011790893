import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { RequisitionsTableWidget } from '@src/pages/Recruitment/RecruitmentSubTabs/Requisitions/RequisitionsTable'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { JobPostingsTableWidget } from '@src/pages/Recruitment/RecruitmentSubTabs/Job Postings/JobPostingsTable'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import { Interviews } from '@src/pages/Recruitment/RecruitmentSubTabs/Interviews/Interviews'
import {
  HomeSectionId,
  TabBarWithPinningTabInterface,
  Subtab,
} from '@src/interfaces/tabPinning'

export const jobsSubTabs: Subtab[] = [
  {
    id: HomeSectionId.jobPostings,
    title: 'Job postings',
    path: ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS,
    to: ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS,
    permission: PermissionTypes.ViewJobdescriptions,
    component: JobPostingsTableWidget,
    globalSetting: GlobalSettings.JobPostingsEnabled,
  },
  {
    id: HomeSectionId.requisitions,
    title: 'Requisitions',
    path: ROUTES.RECRUITMENT.JOBS.REQUISITIONS,
    to: ROUTES.RECRUITMENT.JOBS.REQUISITIONS,
    permission: PermissionTypes.ViewRequisitions,
    component: RequisitionsTableWidget,
    globalSetting: GlobalSettings.RequisitionsEnabled,
  },
]

export const recruitmentSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.jobs,
    title: 'Jobs',
    path: ROUTES.RECRUITMENT.JOBS.ANY,
    url: ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS,
    icon: 'Megaphone',
    subtabs: jobsSubTabs,
    isNewNavigation: true,
    hideNavigation: true,
  },
  {
    id: HomeSectionId.candidates,
    title: 'Candidates',
    path: ROUTES.RECRUITMENT.CANDIDATES,
    url: ROUTES.RECRUITMENT.CANDIDATES,
    permission: PermissionTypes.ViewCandidate,
    component: () => <CommonCandidatesTable type="common" />,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    icon: 'Profile',
  },
  {
    id: HomeSectionId.interviews,
    title: 'Interviews',
    path: ROUTES.RECRUITMENT.INTERVIEWS,
    url: ROUTES.RECRUITMENT.INTERVIEWS,
    permission: PermissionTypes.ViewInterviewFeedback,
    component: Interviews,
    globalSetting: GlobalSettings.ApplicantsTrackingEnabled,
    icon: 'Chat',
  },
]
