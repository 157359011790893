import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import { getTeamOwnership, teamOwnershipTransfer } from '@src/api/ownership'
import {
  departmentHeadcountColumn,
  departmentNameColumn,
  departmentRequisitionsColumn,
} from '@src/constants/columns/department'
import { teamGenericNameColumn } from '@src/constants/columns/team'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const TeamsOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'department__name',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'headcount',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    teamOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        team_owner: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getTeamOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Assign new team owners"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Teams" val={stats?.owned_teams_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                {
                  ...teamGenericNameColumn,
                  title: 'Team name',
                  width: 200,
                },
                {
                  ...departmentNameColumn,
                  width: 200,
                },
                {
                  ...departmentHeadcountColumn,
                  width: 100,
                },
                {
                  ...departmentRequisitionsColumn,
                  width: 100,
                  title: 'Requisitions',
                },
              ]}
              noDataMessage="Teams ownership will appear here"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default TeamsOwnership
