import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { peopleSubtabs } from '@src/pages/People/peopleSubtabs'
import { organisationSubtabs } from '@src/pages/Organisation/organisationSubtabs'
import { recruitmentSubtabs } from '@src/pages/Recruitment/recruitmentSubtabs'
import { PINNABLE_APPLICATION_TABS } from '@src/constants/hub'
import { performanceSubtabs } from '@src/pages/Performance/performanceSubtabs'

const availableMainSectionTabs = [
  ...peopleSubtabs,
  ...organisationSubtabs,
  ...recruitmentSubtabs,
  ...performanceSubtabs,
].map(tab => tab.id)

const availableTabs = [
  ...availableMainSectionTabs,
  ...PINNABLE_APPLICATION_TABS.map(t => t.homeSectionId),
] as string[]

type SetPinnedTabsAction = (tabs: string[]) => string[]

/** Handle case when tab was removed, but it's still in localStorage from before, filter those out */
const filter = (tabs: string[]) => tabs.filter(tab => availableTabs.includes(tab))

interface PinnedTabsContextInterface {
  pinnedTabs: string[]
  setPinnedTabs: (callback: SetPinnedTabsAction) => void
}

export const PinnedTabsContext = createContext<PinnedTabsContextInterface>({
  pinnedTabs: [],
  setPinnedTabs: () => {},
})

export const usePinnedTabs = () => useContext(PinnedTabsContext)

export const PinnedTabsProvider = ({ children }: { children: ReactNode }) => {
  const [localStoragePinnedTabs, setLocalStoragePinnedTabs] = useLocalStorage<string[]>(
    LocalStorageKeys.PINNED_TABS,
    [],
  )

  const contextValue = useMemo(() => {
    const setPinnedTabs = (callback: SetPinnedTabsAction) => {
      setLocalStoragePinnedTabs(tabs => filter(callback(tabs)))
    }

    return {
      pinnedTabs: filter(localStoragePinnedTabs),
      setPinnedTabs,
    }
  }, [localStoragePinnedTabs, setLocalStoragePinnedTabs])

  return (
    <PinnedTabsContext.Provider value={contextValue}>
      {children}
    </PinnedTabsContext.Provider>
  )
}
