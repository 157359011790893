import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import ResignationReasonsForm from './General'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'

type Props = {
  resignation?: EmployeeResignationInterface
}
const ResignationReasons = connect(({ resignation }: Props) => (
  <Form>
    <ResignationReasonsForm resignation={resignation} />
  </Form>
))

export default ResignationReasons
