import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, FilterButton } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { PermissionTypes } from '@src/store/auth/types'
import { documentTemplatesRequests } from '@src/api/documentsTemplates'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import {
  documentsTemplatesCategory,
  documentsTemplatesCreated,
  documentsTemplatesName,
  documentsTemplatesOwner,
  documentsTemplateType,
} from '@src/constants/columns/documentsTemplates'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import Stat from '@src/components/Stat/Stat'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { SelectDocumentsTemplateTypePopup } from '@src/apps/People/Documents/Template/SelectDocumentsTemplateTypePopup'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'

const ROW: RowInterface<DocumentsTemplatesInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, { id })),
  cells: [
    {
      ...documentsTemplatesName,
      width: 350,
    },
    {
      ...documentsTemplateType,
      width: 200,
    },
    {
      ...documentsTemplatesCategory,
      width: 300,
    },
    {
      ...documentsTemplatesOwner,
      width: 200,
    },
    {
      ...documentsTemplatesCreated,
      width: 200,
    },
  ],
}

export const DocumentTemplatesTable = () => {
  const user = useSelector(selectUser)

  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DOCUMENT_TEMPLATES_ASSIGNED_TO_ME,
    false,
  )

  const [templateTypeSelectorOpen, setTemplateTypeSelectorOpen] = useState(false)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<DocumentsTemplatesInterface>(
    documentTemplatesRequests,
    getInitialFilters(),
  )
  const permissions = useSelector(selectPermissions)

  const canAddDocumentsTemplate = permissions?.includes(
    PermissionTypes.AddDocumenttemplates,
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.SETTINGS.DOCUMENTS} title="Documents Templates" />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Total" val={table.loading ? undefined : table.count} mb="s-16" />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            {canAddDocumentsTemplate && (
              <ActionButton
                onClick={() => setTemplateTypeSelectorOpen(true)}
                useIcon="Plus"
              >
                Create new template
              </ActionButton>
            )}
          </Table.Widget.Actions>
          <Table.Widget.Filters>
            <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
              My templates
            </FilterButton>
          </Table.Widget.Filters>
          <Table.Widget.Table>
            <AdjustableTable
              hideCount
              name={TableNames.DocumentTemplates}
              useWindowScroll
              row={ROW}
              {...table}
              noDataMessage="All document templates will appear here"
            />
          </Table.Widget.Table>
        </Table.Widget>
        <SelectDocumentsTemplateTypePopup
          isOpen={templateTypeSelectorOpen}
          onClose={() => setTemplateTypeSelectorOpen(false)}
        />
      </PageBody>
    </PageWrapper>
  )
}
