import React, { useContext } from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { PayCycleReportInterface, AdpReportInterface } from '@src/interfaces/payroll'
import { formatMoneyWithCode } from '@src/utils/format'
import { CurrencyContext } from '@src/apps/People/Payroll/PayCycle/PayCycleReportsTable/ReportsTableWidget'
import { getIssuesInfoString } from '@src/apps/People/Payroll/helpers'
import Table from '@components/TableV2/Table'

export const payCycleReportsEmployeeColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.name',
  sortKey: 'employee__name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Employee',
  insert: ({ data }) => <Table.EmployeeCell employee={data.employee} size={40} />,
}

export const payCycleReportsChangesColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.text,
  idPoint: 'change_count',
  dataPoint: 'change_count',
  sortKey: 'change_count',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Changes',
}

export const payCycleReportsIssuesColumn: ColumnInterface<PayCycleReportInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Issues',
  insert: ({ data }) => getIssuesInfoString<PayCycleReportInterface>(data),
}

const InLocalCurrency = ({ amount }: { amount: string | number }) => {
  const currency = useContext(CurrencyContext)

  return <>{formatMoneyWithCode(Number(amount), currency)}</>
}

export const payCycleReportsGrossSalaryColumn: ColumnInterface<PayCycleReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'total_gross_pay',
    dataPoint: 'total_gross_pay',
    sortKey: 'total_gross_pay',
    filterKey: 'total_gross_pay',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    title: 'Gross salary',
    insert: ({ data }) => <InLocalCurrency amount={data.total_gross_pay} />,
  }

export const payCycleReportsActionsTableColumn: ColumnInterface<PayCycleReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }

export const payCycleAdpChangeReportsPayGroupTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.text,
    idPoint: 'pay_group__id',
    dataPoint: 'pay_group__name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Pay Group',
  }

export const payCycleAdpChangeReportsStartDateTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.date,
    idPoint: 'from_date',
    dataPoint: 'from_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const payCycleAdpChangeReportsEndDateTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.date,
    idPoint: 'to_date',
    dataPoint: 'to_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'End date',
  }

export const payCycleAdpChangeReportsRequestedByTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'requested_by.id',
    dataPoint: 'requested_by.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Requested by',
    insert: ({ data }) => <UserWithAvatar {...data.requested_by} />,
  }

export const payCycleAdpChangeReportsTypeTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'report_type.id',
    dataPoint: 'report_type.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Report type',
    insert: ({ data }) => {
      if ('export_request' in data) {
        return data.export_request.report_type.name
      }
      return data.report_type.name
    },
  }

export const payCycleAdpChangeReportsStatusTableColumn: ColumnInterface<AdpReportInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'requested_by.id',
    dataPoint: 'requested_by.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => {
      if (!data.is_completed) {
        return <Text color={Token.color.warning}>In Progress</Text>
      }
      if (data.is_completed && !!data.error) {
        return <Text color={Token.color.danger}>Failed</Text>
      }
      return <Text color={Token.color.success}>Success</Text>
    },
  }
