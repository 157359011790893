import React, { useEffect, useState } from 'react'
import { Flex, InputGroup, Header, Layout, Spacer, Button } from '@revolut/ui-kit'

import { useQuery } from '@src/utils/queryParamsHooks'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { activateAccount, setPassword, getResetHint } from '@src/api/login'
import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { successNotification } from '@src/actions/NotificationActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { arrayErrorsToFormError } from '@src/utils/form'
import { NonFieldErrors, PASSWORDS_DONT_MATCH_ERROR } from './common'
import PasswordStrength, {
  getStrengthAndErrors,
} from '@src/components/PasswordStrength/PasswordStrength'

interface ChangePasswordForm {
  password: string
  retype_password: string
}

const PasswordChangeForm = () => {
  const { apiErrors, values, isSubmitting, submit } = useLapeContext<ChangePasswordForm>()
  const { query } = useQuery()

  const nonFieldErrors = Object.entries(apiErrors)
    .filter(([key]) => key !== 'password' && key !== 'retype_password')
    .map(([, value]) => value)

  const someFieldsEmpty = !values.password || !values.retype_password
  const passwordsDontMatch =
    !!values.password &&
    !!values.retype_password &&
    values.password !== values.retype_password
  const passwordErrors = !!getStrengthAndErrors(values.password)?.errors.length

  const submitDisabled =
    !query.token || someFieldsEmpty || passwordsDontMatch || passwordErrors

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="password"
          type="password"
          label="New password"
          hasError={!!apiErrors.password}
          message={apiErrors.password || <PasswordStrength password={values.password} />}
          required
        />
        <LapeNewInput
          name="retype_password"
          type="password"
          label="Retype password"
          hasError={!!apiErrors.retype_password || passwordsDontMatch}
          message={
            apiErrors.retype_password ||
            (passwordsDontMatch && PASSWORDS_DONT_MATCH_ERROR)
          }
          required
        />
        <NonFieldErrors nonFieldErrors={nonFieldErrors} />
        {/* This causes the form to be submitted on enter */}
        <input type="submit" hidden />
      </InputGroup>

      <Layout.ActionsFill>
        <Button
          onClick={() => submit()}
          disabled={submitDisabled}
          pending={isSubmitting}
          type="submit"
        >
          Continue
        </Button>
      </Layout.ActionsFill>
    </>
  )
}

function Activate() {
  const { query } = useQuery()
  const [hint, setHint] = useState('')

  useEffect(() => {
    if (query.token) {
      /** Handles credential login */
      if (query.with_password_setting) {
        getResetHint(window.atob(query.token)).then(({ data }) => {
          setHint(data.hint)
        })
      } else {
        /** Handles onboarding and SSO login flows */
        activateAccount(query.token).finally(() =>
          navigateReplace(
            query.next === 'login'
              ? pathToUrl(ROUTES.LOGIN.MAIN)
              : pathToUrl(ROUTES.LOGIN.ONBOARDING),
          ),
        )
      }
    }
  }, [query.token, query.with_password_setting, query.next])

  const handlePasswordChange = (form: LapeFormInterface<ChangePasswordForm>) => {
    return setPassword(form.values, query.token)
      .then(({ data }) => {
        successNotification('Your password was reset successfully')
        navigateReplace(pathToUrl(ROUTES.LOGIN.MAIN))
        return data
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        form.apiErrors = errors
        throw error
      })
  }

  if (query.with_password_setting) {
    return (
      <Flex flexDirection="column" mb="auto" gap="s-16">
        <Header variant="item">
          <Header.Title>Set up your new password</Header.Title>
          {hint && <Header.Description>Create a password for {hint}</Header.Description>}
        </Header>

        <Spacer />

        <LapeForm<ChangePasswordForm> onSubmit={handlePasswordChange}>
          <PasswordChangeForm />
        </LapeForm>
      </Flex>
    )
  }

  return <PageLoading />
}

export default Activate
