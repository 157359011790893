import { ReviewCycleStage } from '@src/interfaces/reviewCycles'

export const stageToLabelMap: Record<ReviewCycleStage, string> = {
  [ReviewCycleStage.DepartmentGoals]: 'Department Goals setting',
  [ReviewCycleStage.TeamGoals]: 'Team Goals setting',
  [ReviewCycleStage.Review]: 'Performance review',
  [ReviewCycleStage.Calibration]: 'Calibration period',
  [ReviewCycleStage.ManagersPublish]: 'Publish to managers',
  [ReviewCycleStage.EmployeesPublish]: 'Publish to employees',
  [ReviewCycleStage.Completed]: 'Published to employees',
}
