import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '.'

export const getBudgetPools = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<BudgetPoolInterface>> =>
  api.get(API.BUDGET_POOL_CONFIGS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const budgetPoolsRequest: RequestInterfaceNew<BudgetPoolInterface> = {
  get: async ({ id }) => api.get(`${API.BUDGET_POOL_CONFIGS}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.BUDGET_POOL_CONFIGS}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.BUDGET_POOL_CONFIGS, data),
  delete: async (_, { id }) => api.delete(`${API.BUDGET_POOL_CONFIGS}/${id}`),
}

export const changeBudgetPoolStatus = (
  id: string | number,
  action: 'archive' | 'unarchive',
) => api.post<BudgetPoolInterface>(`${API.BUDGET_POOL_CONFIGS}/${id}/${action}`)
