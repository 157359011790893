import React from 'react'
import { BarChart } from '@revolut/icons'
import { Flex, Text } from '@revolut/ui-kit'

type ChartEmptyResultsProps = {
  height: number
  label?: string
}

export const ChartEmptyResults = ({
  height,
  label = 'No data to display',
}: ChartEmptyResultsProps) => (
  <Flex
    width="100%"
    height={height}
    justifyContent="center"
    alignItems="center"
    flexDirection="column"
  >
    <BarChart color="grey-tone-20" size={24} />
    <Text variant="h6" color="grey-tone-20">
      {label}
    </Text>
  </Flex>
)
