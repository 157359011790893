import React, { Dispatch, useEffect, useReducer, useRef, useState } from 'react'

import { VStack, Dots, Spacer, Flex, Sticky } from '@revolut/ui-kit'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { sendChatBotQuery, useServiceDeskCategories } from '@src/api/faq'
import { Message } from './Message'
import { MessageInput } from './MessageInput'
import {
  useAutoScrollAndFocusInputOnUpdate,
  useCreateBackgroundTicket,
  useOnIssueResolved,
  useSendGreetingMessageOnOpen,
} from './hooks'
import {
  ActionInterface,
  DEFAULT_SUGGESTIONS,
  END_CONVERSATION_COMMANDS,
  mapTextToSuggestion,
  MessageFrom,
  StateInterface,
  defaultContext,
  AddMessageToHistory,
  createTicketDataCollectionFlow,
  SendUserMessage,
  handleCommandClick,
  getNewMessageId,
  mapTextToCommand,
  getCategoryNamesWithSubcategories,
} from './common'

const getDefaultState = (topic?: FaqTopicInterface): StateInterface => ({
  pending: false,
  confirmEndConversation: false,
  showSuggestions: true,
  suggestions: (topic?.category?.chatbot_suggestions || DEFAULT_SUGGESTIONS).map(
    mapTextToSuggestion,
  ),
  showCommands: false,
  commands: [],
  history: [],
  questionContext: defaultContext,
  inputMode: 'default',
  isStartOfConversation: true,
  issueResolved: false,
  sdCategories: [],
  selectedCategory: undefined,
  categoriesOptions: [],
  subcategoriesOptions: [],
})

const reducer = (state: StateInterface, action: ActionInterface): StateInterface => {
  const { type, data } = action

  switch (type) {
    case 'addMessageToHistory': {
      if (!data?.message) {
        return state
      }
      const history = [...state.history, data.message]

      switch (state.inputMode) {
        case 'default':
        case 'waitForCmdOptionSelect':
          return { ...state, history }
        case 'getTicketSummary':
          return {
            ...state,
            history,
            questionContext:
              data.message.from === 'user'
                ? {
                    ...state.questionContext,
                    summary: data.message.text,
                  }
                : state.questionContext,
          }
        case 'getTicketDescription':
          return {
            ...state,
            history,
            questionContext:
              data.message.from === 'user'
                ? {
                    ...state.questionContext,
                    description: data.message.text,
                  }
                : state.questionContext,
          }
        default:
          return state
      }
    }
    case 'setPending':
      return { ...state, pending: true }
    case 'resetPending':
      return { ...state, pending: false }
    case 'endConversation':
      return { ...state, confirmEndConversation: true }
    case 'restartConversation':
      return { ...state, confirmEndConversation: false, inputMode: 'default' }
    case 'showSuggestions':
      return { ...state, showSuggestions: true }
    case 'hideSuggestions':
      return { ...state, showSuggestions: false }
    case 'showCommands':
      return {
        ...state,
        showCommands: true,
        commands: action.data?.commands || [],
        inputMode: action.data?.inputMode || state.inputMode,
      }
    case 'hideCommands':
      return { ...state, showCommands: false, commands: [] }
    case 'setInputMode':
      return { ...state, inputMode: action.data?.inputMode || 'default' }
    case 'setQuestionContext':
      return {
        ...state,
        questionContext: { ...defaultContext, ...data?.questionContext },
      }
    case 'triggerTicketCreation':
      return {
        ...state,
        questionContext: { ...state.questionContext, create: true },
      }
    case 'conversationStarted':
      return {
        ...state,
        isStartOfConversation: false,
      }
    case 'issueResolved':
      return {
        ...state,
        issueResolved: true,
      }
    case 'setSDCategories':
      return {
        ...state,
        sdCategories: action.data?.sdCategories || [],
        categoriesOptions: getCategoryNamesWithSubcategories(
          action.data?.sdCategories || [],
        ).map(mapTextToCommand),
      }
    case 'setSelectedCategory':
      return {
        ...state,
        selectedCategory: action.data?.selectedCategory,
        subcategoriesOptions:
          action.data?.selectedCategory?.subcategories
            ?.map(subcategory => subcategory.name)
            .map(mapTextToCommand) || [],
      }
    default:
      return state
  }
}

export const createMessageHelpers = ({
  state,
  dispatch,
  inputValue,
}: {
  state: StateInterface
  dispatch: Dispatch<ActionInterface>
  inputValue: string
  setInputValue: (newValue: string) => void
}): {
  addMessageToHistory: AddMessageToHistory
  sendUserMessage: SendUserMessage
} => {
  const addMessageToHistory = ({ from, text }: { from: MessageFrom; text: string }) =>
    dispatch({
      type: 'addMessageToHistory',
      data: {
        message: {
          id: getNewMessageId(),
          from,
          text,
          time: new Date().toISOString(),
        },
      },
    })

  const sendMessage = async (text: string) => {
    dispatch({ type: 'setPending' })

    if (state.isStartOfConversation) {
      dispatch({ type: 'conversationStarted' })
    }

    try {
      const res = await sendChatBotQuery(text, state.isStartOfConversation)
      addMessageToHistory({
        text: res.data.text,
        from: 'chatbot',
      })
      if (!state.confirmEndConversation && res.data.end_conversation) {
        dispatch({
          type: 'showCommands',
          data: {
            commands: END_CONVERSATION_COMMANDS,
            inputMode: 'waitForCmdOptionSelect',
          },
        })
        dispatch({
          type: 'setQuestionContext',
          data: { questionContext: { summary: text } },
        })
      }
    } finally {
      dispatch({ type: 'resetPending' })
    }
  }

  const sendUserMessage: SendUserMessage = async ({
    callApi = true,
    addToHistory = true,
    text = '',
  }) => {
    const messageText = text || inputValue

    if (addToHistory) {
      addMessageToHistory({ from: 'user', text: messageText })
    }
    if (callApi && messageText) {
      await sendMessage(messageText)
    }
  }

  return {
    addMessageToHistory,
    sendUserMessage,
  }
}

type Props = {
  onIssueResolved: () => void
  faqTopic?: FaqTopicInterface
}
export const ChatBot = ({ faqTopic, onIssueResolved }: Props) => {
  const dialogBottomRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [inputValue, setInputValue] = useState('')
  const { data: sdCategories } = useServiceDeskCategories()

  const [state, dispatch] = useReducer(reducer, getDefaultState(faqTopic))

  useEffect(() => {
    if (sdCategories) {
      dispatch({
        type: 'setSDCategories',
        data: { sdCategories: sdCategories.results },
      })
    }
  }, [sdCategories])

  const { sendUserMessage, addMessageToHistory } = createMessageHelpers({
    state,
    dispatch,
    inputValue,
    setInputValue,
  })

  useSendGreetingMessageOnOpen({ addMessageToHistory, inputRef })
  useAutoScrollAndFocusInputOnUpdate({ state, inputRef, dialogBottomRef })
  useCreateBackgroundTicket({
    state,
    dispatch,
    addMessageToHistory,
    faqTopic,
  })
  useOnIssueResolved(state, onIssueResolved)

  const nextTicketDataCollectionStep = createTicketDataCollectionFlow(
    dispatch,
    addMessageToHistory,
  )

  return (
    <Flex height="100%" flexDirection="column" justifyContent="space-between">
      <VStack space="s-12" mb="s-72">
        {state.history.map(message => (
          <Message key={message.id} message={message} />
        ))}
        <VStack space="s-8">
          {state.showSuggestions &&
            state.suggestions.map(suggestion => (
              <Message
                key={suggestion.id}
                message={suggestion}
                onClick={async () => {
                  dispatch({ type: 'hideSuggestions' })
                  dispatch({ type: 'hideCommands' })
                  await sendUserMessage({ text: suggestion.text })
                }}
              />
            ))}
          {state.showCommands &&
            state.commands.map(cmd => (
              <Message
                key={cmd.id}
                message={cmd}
                onClick={() =>
                  handleCommandClick(
                    cmd,
                    state,
                    dispatch,
                    addMessageToHistory,
                    nextTicketDataCollectionStep,
                  )
                }
              />
            ))}
        </VStack>
        {state.pending && <Dots color="grey-tone-50" duration={900} />}
      </VStack>
      <Sticky bottom={0}>
        <Spacer height={state.confirmEndConversation ? 40 : 8} ref={dialogBottomRef} />
        <MessageInput
          state={state}
          inputValue={inputValue}
          setInputValue={setInputValue}
          inputRef={inputRef}
          onIssueResolved={() => {
            dispatch({ type: 'issueResolved' })
          }}
          onSubmit={async () => {
            dispatch({ type: 'hideCommands' })
            dispatch({ type: 'hideSuggestions' })

            const ticketCreationFlow = state.inputMode !== 'default'
            await sendUserMessage({ callApi: !ticketCreationFlow })

            if (ticketCreationFlow) {
              nextTicketDataCollectionStep(state)
            }
            setInputValue('')
          }}
          onRestartConversation={() => {
            dispatch({ type: 'restartConversation' })
            dispatch({ type: 'showSuggestions' })

            inputRef.current?.focus()
          }}
        />
      </Sticky>
    </Flex>
  )
}
