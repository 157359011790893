import React from 'react'

import { useGetAllFeedbackList } from '@src/api/anytimeFeedback'
import {
  AnytimeFeedbackSidebar,
  tabsCategory,
} from '@src/components/AnytimeFeedback/AnytimeFeedbackSidebar'

interface AnytimeFeedbackSidebarStandaloneProps {
  userId: number
  isOpen: boolean
  onClose: () => void
  nominationId?: number | string
}

export const AnytimeFeedbackSidebarStandalone = ({
  userId,
  isOpen,
  onClose,
  nominationId,
}: AnytimeFeedbackSidebarStandaloneProps) => {
  const { data, refetch } = useGetAllFeedbackList(userId, nominationId)

  // fixMe: not sure what is the business case for empty data
  // perhaps we should handle this more user friendly if that's a valid use case
  return data ? (
    <AnytimeFeedbackSidebar
      isOpen={isOpen}
      handleClose={onClose}
      feedbackList={data.results}
      refetchFeedbackList={refetch}
      employeeId={String(userId)}
      openTab={tabsCategory.Anytime}
    />
  ) : null
}
