export const SHOW_EXTERNAL_STAT_FILTERS = [
  'pending_employees',
  'onboarding_employees',
  'hired_employees',
]

export type StatFilterId =
  | 'active_employees'
  | 'pending_employees'
  | 'onboarding_employees'
  | 'hired_employees'
  | 'active_employees_external'

export const getEmployeeTypeFilters = (id: StatFilterId, includeExternal: boolean) => {
  if (SHOW_EXTERNAL_STAT_FILTERS.includes(id) && includeExternal) {
    return {
      filters: [
        {
          name: 'External Employee',
          id: 'external',
        },
        {
          name: 'Internal Employee',
          id: 'internal',
        },
      ],
      columnName: 'employee_type',
      nonResettable: false,
    }
  }
  return {
    filters:
      id === 'active_employees_external'
        ? [
            {
              name: 'External Employee',
              id: 'external',
            },
          ]
        : [
            {
              name: 'Internal Employee',
              id: 'internal',
            },
          ],
    columnName: 'employee_type',
    nonResettable: false,
  }
}

export const getDefaultStatsFilter = () => {
  return hasEmployeeTypeFilter() || hasStatusFilter() ? undefined : 'active_employees'
}

export const hasEmployeeTypeFilter = () => {
  const currentURL = new URL(window.location.href)
  return typeof currentURL.searchParams.get('employee_type') === 'string'
}

export const hasStatusFilter = () => {
  const currentURL = new URL(window.location.href)
  return typeof currentURL.searchParams.get('employee_type') === 'string'
}
