import React, { useState } from 'react'
import { ActionButton, InputGroup, Side } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { successNotification } from '@src/actions/NotificationActions'
import { PerformanceSettingsManagersEntityType } from '@src/interfaces/settings'
import { SidebarState } from './Managers'

interface ManagersFormProps {
  sidebarState: SidebarState
  type: 'probation' | 'pip'
  onComplete: (result: PerformanceSettingsInterface) => void
  onAfterDelete: (result: PerformanceSettingsInterface) => void
}

export const ManagersForm = ({
  sidebarState,
  type,
  onComplete,
  onAfterDelete,
}: ManagersFormProps) => {
  const { values, submit } = useLapeContext<PerformanceSettingsInterface>()
  const [deletePending, setDeletePending] = useState(false)

  const selectedTypeValue =
    !sidebarState.id || sidebarState.full_name
      ? PerformanceSettingsManagersEntityType.Employee
      : PerformanceSettingsManagersEntityType.Group

  const removeSelectedEntity = () => {
    switch (type) {
      case 'probation':
        if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
          const index = values.probation_manager_employees.findIndex(
            el => el.id === sidebarState.id,
          )
          values.probation_manager_employees.splice(index, 1)
        } else {
          const index = values.probation_manager_groups.findIndex(
            el => el.id === sidebarState.id,
          )
          values.probation_manager_groups.splice(index, 1)
        }
        break
      case 'pip':
        if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
          const index = values.pip_manager_employees.findIndex(
            el => el.id === sidebarState.id,
          )
          values.pip_manager_employees.splice(index, 1)
        } else {
          const index = values.pip_manager_groups.findIndex(
            el => el.id === sidebarState.id,
          )
          values.pip_manager_groups.splice(index, 1)
        }
        break
    }
  }

  const [currentEntityType, setCurrentEntityType] =
    useState<PerformanceSettingsManagersEntityType>(selectedTypeValue)
  const [currentEntity, setCurrentEntity] = useState<{ id: number } | null>(
    sidebarState.id ? { id: sidebarState.id } : null,
  )

  const typeOptions = [
    {
      label: PerformanceSettingsManagersEntityType.Employee,
      value: {
        id: PerformanceSettingsManagersEntityType.Employee,
        name: PerformanceSettingsManagersEntityType.Employee,
      },
    },
    {
      label: PerformanceSettingsManagersEntityType.Group,
      value: {
        id: PerformanceSettingsManagersEntityType.Group,
        name: PerformanceSettingsManagersEntityType.Group,
      },
    },
  ]

  const updateValues = (clean: boolean = false) => {
    if (!currentEntity) {
      return
    }
    if (clean) {
      switch (type) {
        case 'probation':
          if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
            values.probation_manager_employees.pop()
          } else {
            values.probation_manager_groups.pop()
          }
          break
        case 'pip':
          if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
            values.pip_manager_employees.pop()
          } else {
            values.pip_manager_groups.pop()
          }
          break
      }
    } else {
      switch (type) {
        case 'probation':
          if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
            values.probation_manager_employees.push({ id: currentEntity?.id })
          } else {
            values.probation_manager_groups.push({ id: currentEntity?.id })
          }
          break
        case 'pip':
          if (currentEntityType === PerformanceSettingsManagersEntityType.Employee) {
            values.pip_manager_employees.push({ id: currentEntity?.id })
          } else {
            values.pip_manager_groups.push({ id: currentEntity?.id })
          }
          break
      }
    }
  }

  const onSubmit = () => {
    updateValues()
    return submit().catch(() => {
      updateValues(true)
    })
  }

  const onDelete = async () => {
    setDeletePending(true)
    try {
      removeSelectedEntity()
      await submit().then(result => onAfterDelete(result))
      successNotification('Mangers removed')
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <>
      {sidebarState.id ? (
        <ActionButton
          onClick={onDelete}
          useIcon="Delete"
          variant="negative"
          pending={deletePending}
          mb="s-16"
        >
          Delete
        </ActionButton>
      ) : null}

      <InputGroup>
        <LapeRadioSelectInput
          label="Type"
          options={typeOptions}
          name="managers_type"
          value={typeOptions.find(el => el.value.id === currentEntityType)?.value}
          onChange={option => {
            if (option) {
              setCurrentEntity(null)
              setCurrentEntityType(option.id)
            }
          }}
        />
        <LapeRadioSelectInput
          label={currentEntityType}
          selector={
            currentEntityType === PerformanceSettingsManagersEntityType.Employee
              ? selectorKeys.all_employees
              : selectorKeys.dynamic_groups
          }
          name="managers"
          onChange={option => {
            if (option) {
              setCurrentEntity(option)
            }
          }}
          value={currentEntity}
        />
      </InputGroup>

      {!sidebarState.id && (
        <Side.Actions>
          <NewSaveButtonWithPopup
            onClick={onSubmit}
            onAfterSubmit={(result: PerformanceSettingsInterface) => {
              onComplete(result)
            }}
            successText={`${type === 'probation' ? 'Probation' : 'PIP'} Managers saved`}
            useValidator
            disabled={!currentEntity}
            hideWhenNoChanges={false}
          >
            Save
          </NewSaveButtonWithPopup>
        </Side.Actions>
      )}
    </>
  )
}
