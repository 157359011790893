import React from 'react'
import { Box, Text, Token } from '@revolut/ui-kit'

type Props = {
  value: string
  title: string
}

export const SummaryItem = ({ value, title }: Props) => {
  return (
    <Box
      padding="s-16"
      radius={Token.radius.widget}
      backgroundColor={Token.color.segmentedBackground}
      width="100%"
    >
      <Text variant="heading3" use="div" color={Token.color.foreground}>
        {value}
      </Text>
      <Text variant="body2" use="div" color={Token.color.greyTone50}>
        {title}
      </Text>
    </Box>
  )
}
