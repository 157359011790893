import { useMemo } from 'react'

import { useGetPayCycles } from '@src/api/payrollV2'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { formatPeriod } from '@src/utils/format'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { CycleOption } from '../../common'

export const useGetPayCyclesOptions = (
  payGroupId: string | undefined,
): { isLoading: boolean; options: CycleOption[] } => {
  const enabled = !!payGroupId
  const payGroupFilter = payGroupId
    ? [
        {
          filters: [{ id: payGroupId, name: payGroupId }],
          columnName: 'pay_group_id',
          nonResettable: true,
        },
      ]
    : []

  const { data, isLoading } = useGetPayCycles(
    filterSortPageIntoQuery(
      [{ sortBy: 'start_date', direction: SORT_DIRECTION.DESC }],
      payGroupFilter,
      1,
    ),
    enabled,
  )

  const options = useMemo(() => {
    return (
      data?.results.map(option => ({
        ...option,
        startToEnd: formatPeriod(new Date(option.start_date), new Date(option.end_date), {
          hideYear: true,
        }),
      })) || []
    ).reverse()
  }, [data])

  return { isLoading, options }
}
