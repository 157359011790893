import React from 'react'
import { Text, Token } from '@revolut/ui-kit'

interface AccessGroupSelectorOptionProps {
  name?: string
  description?: string | null
}

export const AccessGroupSelectorOption = ({
  name,
  description,
}: AccessGroupSelectorOptionProps) => {
  return (
    <>
      <Text use="p">{name}</Text>
      <Text use="p" variant="caption" mt="s-4" color={Token.color.greyTone50}>
        {description}
      </Text>
    </>
  )
}
