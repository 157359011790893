import React from 'react'
import { VStack } from '@revolut/ui-kit'
import SpecialisationsTable from '@src/pages/Organisation/OrganisationSubTabs/Specialisations/SpecialisationsTable'
import Message from '../components/Message'

const RolesSpecialisations = () => {
  return (
    <VStack gap="s-16">
      <Message
        title="We have created specialisations just for you"
        description="An employee specialisation defines the responsibilities, duties, and expectations associated with an employee’s position and specific skills"
      />
      <SpecialisationsTable />
    </VStack>
  )
}

export default RolesSpecialisations
