import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { useGetMonthDays } from '@src/api/calendar'
import { selectorKeys } from '@src/constants/api'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import { OptionInterface } from '@src/interfaces/selectors'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { useLapeContext } from '@src/features/Form/LapeForm'

const mapDaysToOptionsSelector = (days?: { options: OptionInterface[] }) => () =>
  Promise.resolve(days?.options || [])

export const ScheduleWidget = () => {
  const { values } = useLapeContext<ReportInterface>()

  const { data: allMonthDays } = useGetMonthDays(
    values.run_every?.id === 'year' || values.run_every?.id === 'month'
      ? values.run_on_month?.id || 1 // using Jan to get most days
      : undefined,
  )

  const sentEveryId = values.run_every?.id

  return (
    <InputGroup>
      <LapeRadioSelectInput
        label="Refreshed every"
        name="run_every"
        selector={selectorKeys.report_run_every}
        required
      />
      {sentEveryId === 'week' && (
        <LapeRadioSelectInput
          label="Refreshed on"
          name="run_on_day_of_week"
          selector={selectorKeys.days_of_the_week}
          required
        />
      )}
      {sentEveryId === 'month' && (
        <LapeRadioSelectInput
          label="Day of the month"
          name="run_on_day_of_month"
          selector={mapDaysToOptionsSelector(allMonthDays)}
          required
        />
      )}
      {sentEveryId === 'year' && (
        <InputGroup variant="horizontal">
          <LapeRadioSelectInput
            label="Month"
            name="run_on_month"
            selector={selectorKeys.months}
            required
          />
          <LapeRadioSelectInput
            label="Day"
            name="run_on_day_of_month"
            selector={mapDaysToOptionsSelector(allMonthDays)}
            required
          />
        </InputGroup>
      )}
      <InputGroup variant="horizontal">
        <LapeNewTimeInput label="Time" name="run_on_time" required />
        <LapeRadioSelectInput
          label="Timezone"
          name="run_on_timezone"
          selector={selectorKeys.timezones}
        />
      </InputGroup>
    </InputGroup>
  )
}
