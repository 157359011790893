import React from 'react'
import { Box, Color, Group, IconButton } from '@revolut/ui-kit'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import DOMPurify from 'dompurify'
import { FileInterface } from '@src/interfaces/files'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { Document } from '@revolut/icons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetTypesOfSender } from '@src/utils/hiring'

type Props = {
  setDocumentId?: (id: number) => void
}

const EmailTemplatePreviewContent = ({ setDocumentId }: Props) => {
  const { initialValues } = useLapeContext<EmailTemplateInterface>()
  const typesOfSenderOptions = useGetTypesOfSender()

  return (
    <>
      <Box mt="s-16">
        <FormPreview data={initialValues}>
          <Group>
            <FormPreview.Item title="Owner" field="owner" type="employee" />
            <FormPreview.Details title="Description" field="description" />
          </Group>
        </FormPreview>
      </Box>

      <Box mt="s-16">
        <FormPreview title="Email details" data={initialValues}>
          <Group>
            <FormPreview.Item<EmailTemplateInterface>
              title="Send as"
              insert={data =>
                typesOfSenderOptions.find(sender => sender.value.id === data.sender_type)
                  ?.label
              }
            />
            {!!initialValues.recipients_cc?.length && (
              <FormPreview.Item<EmailTemplateInterface>
                title="Cc"
                field="recipient_cc"
                insert={data => data.recipients_cc?.join(', ') || '-'}
              />
            )}
            {!!initialValues.recipients_bcc?.length && (
              <FormPreview.Item<EmailTemplateInterface>
                title="Bcc"
                field="recipient_bcc"
                insert={data => data.recipients_bcc?.join(', ') || '-'}
              />
            )}
            <FormPreview.Item title="Subject line" field="subject" />
          </Group>
        </FormPreview>
      </Box>

      <Box mt="s-16">
        <FormPreview data={initialValues}>
          <Group>
            <FormPreview.Details<EmailTemplateInterface>
              title="Email body"
              field="email_body"
              insert={data => (
                <Box
                  mt="s-16"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data.email_body),
                  }}
                />
              )}
            />
          </Group>
        </FormPreview>
      </Box>

      {!!initialValues.attachments?.length && (
        <Box mt="s-16">
          <FormPreview title="Attachments" data={initialValues}>
            <Group>
              {(initialValues.attachments as FileInterface[])?.map(attachment => (
                <FormPreview.Item<ReferCandidateInterface>
                  key={attachment.id}
                  title={attachment.name}
                  centered
                  insert={() =>
                    attachment.id && setDocumentId ? (
                      <IconButton
                        useIcon={Document}
                        size={16}
                        color={Color.BLUE}
                        onClick={() => setDocumentId(attachment.id)}
                      />
                    ) : (
                      '-'
                    )
                  }
                />
              ))}
            </Group>
          </FormPreview>
        </Box>
      )}
    </>
  )
}

export default EmailTemplatePreviewContent
