import { ErrorWidget, Link } from '@revolut/ui-kit'
import Lock from '@src/assets/3D/Lock@2x.png'
import { REVOLUTERS_SERVICE_DESK } from '@src/constants/externalLinks'
import React from 'react'
import Alert from '@src/assets/3D/Alert.png'
import { AxiosError } from 'axios'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface BudgetImpactErrorProps {
  error: AxiosError
}

const BudgetImpactError = ({ error }: BudgetImpactErrorProps) => {
  if (error.response?.status === 403) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image src={Lock} />
        <ErrorWidget.Title>
          You don’t have access to view compensation bands
        </ErrorWidget.Title>
        <HideIfCommercial>
          <ErrorWidget.Description>
            If this is unexpected, please{' '}
            <Link
              href={REVOLUTERS_SERVICE_DESK}
              target="_blank"
              rel="noreferrer noopener"
            >
              raise
            </Link>{' '}
            a ticket with us
          </ErrorWidget.Description>
        </HideIfCommercial>
      </ErrorWidget>
    )
  }

  if (error.response?.status === 404 && !!error.response?.data?.detail) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image src={Alert} />
        <ErrorWidget.Title>Unable to calculate the budget impact</ErrorWidget.Title>
        <ErrorWidget.Description>{error.response.data.detail}</ErrorWidget.Description>
      </ErrorWidget>
    )
  }

  return (
    <ErrorWidget>
      <ErrorWidget.Image src={Alert} />
      <ErrorWidget.Title>Something went wrong</ErrorWidget.Title>
      <ErrorWidget.Description>
        Error: {error.response?.data?.detail || 'unknown'}.{' '}
        <HideIfCommercial>
          If the error still persists, please{' '}
          <Link href={REVOLUTERS_SERVICE_DESK} target="_blank" rel="noreferrer noopener">
            raise
          </Link>{' '}
          a ticket with us
        </HideIfCommercial>
      </ErrorWidget.Description>
    </ErrorWidget>
  )
}

export default BudgetImpactError
