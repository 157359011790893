import React, { useEffect, useState } from 'react'
import {
  ActionButton,
  Box,
  Button,
  Cell,
  Token,
  Color,
  Flex,
  Header,
  ProgressStep,
  ProgressSteps,
  Sticky,
  Text,
} from '@revolut/ui-kit'
import { useDispatch } from 'react-redux'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { connect, useLape } from 'lape'
import styled, { createGlobalStyle } from 'styled-components'
import useResizeObserver from 'use-resize-observer'

import { ROUTES } from '@src/constants/routes'
import { SubtabStatus, TabBarTableNavigationInterface } from '@src/interfaces/data'
import { ReactComponent as Logo } from '@src/assets/Revolut_People.svg'
import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import Start from '@src/pages/Onboarding/Start/Start'
import BasicInfo from '@src/pages/Onboarding/BasicInfo/BasicInfo'
import PersonalInfo from '@src/pages/Onboarding/PersonalInfo/PersonalInfo'
import Payroll from '@src/pages/Onboarding/Payroll/Payroll'
import Diversity from '@src/pages/Onboarding/Diversity/Diversity'
import { Documents, DocumentsPreview } from '@src/pages/Onboarding/Documents/Documents'
import Welcome from '@src/pages/Onboarding/Welcome/Welcome'
import Form from '@src/features/Form/Form'
import {
  getOnboardingSteps,
  onboardingBasicInfoApi,
  onboardingDiversityApi,
  onboardingDocumentsApi,
  onboardingPayrollApi,
  onboardingPersonalInfoApi,
  onboardingWelcomeStepApi,
} from '@src/api/onboarding'
import { OnboardingData, onboardingSteps } from '@src/interfaces/onboarding'
import { Statuses } from '@src/interfaces'
import { getDynamicGroupsForEmployee } from '@src/api/dynamicGroups'
import { DynamicGroupIDs } from '@src/interfaces/customFields'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { logOutAction } from '@src/store/auth/actions'
import { useOnboardingPreviewMode, WrapperCss } from './common'
import { didAnswerOnboardingQuestion } from '@src/api/engagement'
import DocumentUploadRequest from '@src/pages/Forms/DocumentUpload'
import SideBar from '@src/components/SideBar/SideBar'
import { formatWithoutTimezone } from '@src/utils/format'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateTo } from '@src/actions/RouterActions'
import { DiversityFAQSidebar } from '@src/pages/EmployeeProfile/Forms/Diversity/General'
import { PreTitle } from '@src/features/TabBarNavigation/PreTitle'
import DocumentEmbedded from '@src/pages/Forms/DocumentSigning/Embedded'
import DocumentDocusign from '@src/pages/Forms/DocumentSigning/Docusign'
import {
  useGetEmployeeSettings,
  useGetLifecycleSettings,
  useGlobalSettings,
} from '@src/api/settings'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { defaultTheme } from '@src/styles/theme'

interface OnboardingState {
  data: OnboardingData | null
  dynamicGroups: DynamicGroupIDs
  didAnswerQuestion: boolean | null
}

const OnboardingGlobalStyle = createGlobalStyle`
  html {
    overflow-y: scroll;
  }
`

const ProgressStepStyled = styled(ProgressStep)`
  grid-template-columns: 0 auto 1fr auto;
`

const StyledHeader = styled(Sticky)<{ withPurpleBackground?: boolean }>`
  background-color: ${({ withPurpleBackground }) =>
    withPurpleBackground ? Token.color.deepPurple : Token.color.layoutBackground};
  top: 0;
  padding-top: 16px;
  z-index: ${defaultTheme.zIndex.aboveMain};

  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    backdrop-filter: blur(16px);
    ${({ withPurpleBackground }) =>
      withPurpleBackground && `background-color: ${Token.color.deepPurple_80};`}
  }
`

interface StepsData {
  title: string
  description: string
  color?: Color
}

interface StepsProps {
  data: StepsData[]
}

const Steps = ({ data }: StepsProps) => {
  return (
    <Cell>
      <ProgressSteps variant="vertical">
        {data.map(step => (
          <ProgressStepStyled
            state={step.color ? 'done' : 'pending'}
            indicatorColor={step.color}
            key={step.title}
            data-testid={`step-${step.color || ''}`}
          >
            <ProgressStep.Title>{step.title}</ProgressStep.Title>
            <ProgressStep.Description>{step.description}</ProgressStep.Description>
          </ProgressStepStyled>
        ))}
      </ProgressSteps>
    </Cell>
  )
}

interface SummaryProps {
  data: StepsData[]
  joiningDate: string
  allStepsCompleted: boolean
  id: number | string
  enableLandingPage?: boolean
}

const Summary = ({
  data,
  joiningDate,
  allStepsCompleted,
  id,
  enableLandingPage,
}: SummaryProps) => {
  return (
    <Flex
      flexDirection="column"
      flex="1"
      width="100%"
      css={WrapperCss}
      data-testid="summary-tab"
    >
      <PageBody>
        <Header variant="main" mb="-s-8">
          <Header.Title>
            {allStepsCompleted
              ? 'Your onboarding is complete 🥳'
              : 'Onboarding progress summary'}
          </Header.Title>
          {allStepsCompleted ? null : (
            <Header.Subtitle>
              Before finalising your onboarding, please check if all the steps were
              completed. If not, go back to these were some action is pending completion.
            </Header.Subtitle>
          )}
          <Header.Description>Expected joining date: {joiningDate}</Header.Description>
        </Header>
        <Box mt="s-16">
          <Steps data={data} />
        </Box>
      </PageBody>
      {enableLandingPage && allStepsCompleted ? (
        <PageActions>
          <Button
            onClick={() => navigateTo(pathToUrl(ROUTES.ONBOARDING.WELCOME, { id }))}
            elevated
          >
            Welcome
          </Button>
        </PageActions>
      ) : null}
    </Flex>
  )
}

const PageContentWrapper: React.FC = ({ children }) => (
  <Flex flexDirection="column" flex="1" width="100%" css={WrapperCss}>
    {children}
  </Flex>
)

const Onboarding = () => {
  const state = useLape<OnboardingState>({
    data: null,
    dynamicGroups: [],
    didAnswerQuestion: null,
  })
  const [diversityFaqOpen, setDiversityFaqOpen] = useState(false)

  const { data: settings } = useGetEmployeeSettings()
  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const { settings: globalSettings } = useGlobalSettings()
  const onboardingPreview = useOnboardingPreviewMode()
  const isPreviewEnabled = !!onboardingPreview.config
  const enableDiversity = isPreviewEnabled
    ? onboardingPreview.config?.enable_diversity
    : lifecycleSettings?.enable_diversity
  const enableBankDetails = settings?.enable_bank_details
  const enableLandingPage = lifecycleSettings?.enable_onboarding_landing_page
  const enableFeedback = globalSettings?.feedback_enabled

  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const stepInfo = {
    [onboardingSteps.welcome]: {
      index: 1,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.START),
    },
    [onboardingSteps.basic_info]: {
      index: 2,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.BASIC_INFO),
    },
    [onboardingSteps.personal_info]: {
      index: 3,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.PERSONAL_INFO),
    },
    [onboardingSteps.payroll]: {
      index: 4,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.PAYROLL),
    },
    [onboardingSteps.diversity]: {
      index: 5,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.DIVERSITY),
    },
    [onboardingSteps.documents]: {
      index: 6,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.DOCUMENTS),
    },
    [onboardingSteps.summary]: {
      index: 7,
      isFocussed: !!useRouteMatch(ROUTES.ONBOARDING.SUMMARY),
    },
  }
  const welcomePageMatch = useRouteMatch(ROUTES.ONBOARDING.WELCOME)
  const { height: headerHeight, ref: headerRef } = useResizeObserver()

  const [progressTimelineOpen, setProgressTimelineOpen] = useState(true)

  const textColor = welcomePageMatch ? 'white' : undefined

  const onComplete = (type: onboardingSteps) => {
    const step = state.data?.onboarding_steps.find(current => current.type === type)
    if (step) {
      step.status.id = Statuses.completed
    }
  }

  useEffect(() => {
    return onboardingPreview.cleanup
  }, [])

  useEffect(() => {
    if (id) {
      getOnboardingSteps(id).then(res => {
        state.data = res.data
      })

      getDynamicGroupsForEmployee(id).then(res => {
        state.dynamicGroups = res.data.results
      })
      if (!isPreviewEnabled && enableFeedback) {
        didAnswerOnboardingQuestion().then(res => {
          state.didAnswerQuestion = res.data
        })
      }
    }
  }, [id])

  useEffect(() => {
    /** If steps are disabled in settings, remove those steps */
    if (state.data?.onboarding_steps) {
      if (enableDiversity === false) {
        state.data.onboarding_steps = state.data.onboarding_steps.filter(
          step => step.type !== onboardingSteps.diversity,
        )
      }
      if (enableBankDetails === false) {
        state.data.onboarding_steps = state.data.onboarding_steps.filter(
          step => step.type !== onboardingSteps.payroll,
        )
      }
    }
  }, [enableDiversity, enableBankDetails, state.data])

  const areAllStepsComplete = () => {
    if (isPreviewEnabled) {
      return false
    }
    return !!state.data?.onboarding_steps.every(
      step =>
        step.status.id === Statuses.completed || step.status.id === Statuses.skipped,
    )
  }

  if (!state.data) {
    return (
      <Box height="100vh" width="100vw">
        <PageLoading />
      </Box>
    )
  }

  const onQuestionAnswered = () => {
    state.didAnswerQuestion = true
  }

  const getStatus = (type: onboardingSteps) => {
    if (isPreviewEnabled) {
      return SubtabStatus.Pending
    }
    const step = state.data?.onboarding_steps.find(current => current.type === type)
    return step?.status?.id === Statuses.pending
      ? SubtabStatus.Pending
      : SubtabStatus.Completed
  }

  const getPreTitle = (type: onboardingSteps) => {
    const status = getStatus(type)
    return <PreTitle complete={status !== SubtabStatus.Pending} />
  }

  const getTitle = (title: string) => {
    return <Text color={textColor}>{title}</Text>
  }

  const getStepColor = (step: onboardingSteps) => {
    if (getStatus(step) === SubtabStatus.Completed) {
      return Token.color.green
    }
    if (stepInfo[step].isFocussed) {
      return Token.color.blue
    }
    const indexOfFocussedTab = Object.values(stepInfo).find(s => s.isFocussed)?.index
    if (indexOfFocussedTab != null && stepInfo[step].index < indexOfFocussedTab) {
      return Token.color.orange
    }

    return undefined
  }

  const tabs = [
    {
      id: onboardingSteps.welcome,
      title: getTitle('Start'),
      path: ROUTES.ONBOARDING.START,
      to: pathToUrl(ROUTES.ONBOARDING.START, { id }),
      status: getStatus(onboardingSteps.welcome),
      preTitle: getPreTitle(onboardingSteps.welcome),
      component: Start,
      api: isPreviewEnabled ? undefined : onboardingWelcomeStepApi,
    },
    {
      id: onboardingSteps.basic_info,
      title: getTitle('Basic info'),
      path: ROUTES.ONBOARDING.BASIC_INFO,
      to: pathToUrl(ROUTES.ONBOARDING.BASIC_INFO, { id }),
      status: getStatus(onboardingSteps.basic_info),
      preTitle: getPreTitle(onboardingSteps.basic_info),
      component: BasicInfo,
      api: isPreviewEnabled ? undefined : onboardingBasicInfoApi,
    },
    {
      id: onboardingSteps.personal_info,
      title: getTitle('Personal info'),
      path: ROUTES.ONBOARDING.PERSONAL_INFO,
      to: pathToUrl(ROUTES.ONBOARDING.PERSONAL_INFO, { id }),
      status: getStatus(onboardingSteps.personal_info),
      preTitle: getPreTitle(onboardingSteps.personal_info),
      component: PersonalInfo,
      api: isPreviewEnabled ? undefined : onboardingPersonalInfoApi,
    },
    enableBankDetails
      ? {
          id: onboardingSteps.payroll,
          title: getTitle('Payroll'),
          path: ROUTES.ONBOARDING.PAYROLL,
          to: pathToUrl(ROUTES.ONBOARDING.PAYROLL, { id }),
          status: getStatus(onboardingSteps.payroll),
          preTitle: getPreTitle(onboardingSteps.payroll),
          component: Payroll,
          api: isPreviewEnabled ? undefined : onboardingPayrollApi,
        }
      : null,
    enableDiversity
      ? {
          id: onboardingSteps.diversity,
          title: getTitle('Diversity'),
          path: ROUTES.ONBOARDING.DIVERSITY,
          to: pathToUrl(ROUTES.ONBOARDING.DIVERSITY, { id }),
          status: getStatus(onboardingSteps.diversity),
          preTitle: getPreTitle(onboardingSteps.diversity),
          component: Diversity,
          api: isPreviewEnabled ? undefined : onboardingDiversityApi,
        }
      : null,
    {
      id: onboardingSteps.documents,
      title: getTitle('Documents'),
      path: ROUTES.ONBOARDING.DOCUMENTS,
      to: pathToUrl(ROUTES.ONBOARDING.DOCUMENTS, { id }),
      status: getStatus(onboardingSteps.documents),
      preTitle: getPreTitle(onboardingSteps.documents),
      component: isPreviewEnabled ? DocumentsPreview : Documents,
      api: isPreviewEnabled ? undefined : onboardingDocumentsApi,
    },
  ].filter(Boolean)

  const getTabActionRoute = (tab: onboardingSteps, direction: 'prev' | 'next') => {
    const tabIndex = tabs.findIndex(t => t.id === tab)

    if (tabIndex === -1) {
      return undefined
    }

    const prevTab = tabs[tabIndex - 1]
    const nextTab = tabs[tabIndex + 1]

    return direction === 'next' ? nextTab?.to : prevTab?.to
  }

  const allStepsCompleted = areAllStepsComplete()

  const tabbarTabs = [
    ...tabs,
    {
      title: getTitle('Summary'),
      path: ROUTES.ONBOARDING.SUMMARY,
      to: pathToUrl(ROUTES.ONBOARDING.SUMMARY, { id }),
      preTitle: <PreTitle complete={allStepsCompleted} />,
    },
    allStepsCompleted && enableLandingPage
      ? {
          title: 'Welcome',
          path: ROUTES.ONBOARDING.WELCOME,
          to: pathToUrl(ROUTES.ONBOARDING.WELCOME, { id }),
        }
      : null,
  ].filter(Boolean) as TabBarTableNavigationInterface[]

  const stepsData = [
    {
      title: 'Start',
      description: 'Introduction to your onboarding process.',
      color: getStepColor(onboardingSteps.welcome),
    },
    {
      title: 'Basic information',
      description: 'Verify basic information and choose your work email address.',
      color: getStepColor(onboardingSteps.basic_info),
    },
    {
      title: 'Personal information',
      description: 'Provide necessary data.',
      color: getStepColor(onboardingSteps.personal_info),
    },
    enableBankDetails
      ? {
          title: 'Payroll',
          description: 'Enter your bank account details.',
          color: getStepColor(onboardingSteps.payroll),
        }
      : null,
    enableDiversity
      ? {
          title: 'Diversity',
          description: 'If you wish, share with us more information about you.',
          color: getStepColor(onboardingSteps.diversity),
        }
      : null,
    {
      title: 'Documents',
      description: 'Upload and sign documents to finalise your onboarding.',
      color: getStepColor(onboardingSteps.documents),
    },
  ].filter(Boolean)

  const joiningDate = formatWithoutTimezone(state.data.joining_date_time)

  return (
    <>
      <Flex width="100%" minHeight="100vh" flexDirection="column">
        <OnboardingGlobalStyle />
        <Switch>
          <Route exact path={ROUTES.ONBOARDING.DOCUMENT_UPLOAD_REQUEST}>
            <PageContentWrapper>
              <DocumentUploadRequest />
            </PageContentWrapper>
          </Route>
          <Route exact path={ROUTES.ONBOARDING.DOCUMENT_EMBEDDED}>
            <PageContentWrapper>
              <DocumentEmbedded />
            </PageContentWrapper>
          </Route>
          <Route exact path={ROUTES.ONBOARDING.DOCUMENT_DOCUSIGN}>
            <PageContentWrapper>
              <DocumentDocusign />
            </PageContentWrapper>
          </Route>
          <Route exact path={ROUTES.ONBOARDING.ANY}>
            <StyledHeader ref={headerRef} withPurpleBackground={!!welcomePageMatch}>
              <Flex pt="s-48" pb="s-12" flexDirection="column" css={WrapperCss}>
                <Flex justifyContent="space-between" mb="s-8">
                  {welcomePageMatch ? <LogoAlt height="20" /> : <Logo height="20" />}
                  <Flex gap="s-8">
                    {welcomePageMatch || stepInfo.summary.isFocussed ? null : (
                      <ActionButton
                        onClick={() => setProgressTimelineOpen(true)}
                        useIcon="Time"
                      >
                        Progress timeline
                      </ActionButton>
                    )}
                    <ActionButton
                      onClick={() => {
                        dispatch(logOutAction())
                      }}
                      variant={welcomePageMatch ? 'white' : undefined}
                      useIcon="LogoutDoor"
                    >
                      Sign out
                    </ActionButton>
                  </Flex>
                </Flex>
                <TabBarNavigation tabs={tabbarTabs} py="s-12" />
              </Flex>
            </StyledHeader>
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <PageContentWrapper>
                    <Form api={tab.api!} key={tab.path}>
                      <tab.component
                        data={state.data!}
                        dynamicGroups={state.dynamicGroups}
                        onComplete={onComplete}
                        setDiversityFaqOpen={setDiversityFaqOpen}
                        nextRoute={getTabActionRoute(tab.id, 'next')}
                        prevRoute={getTabActionRoute(tab.id, 'prev')}
                      />
                    </Form>
                  </PageContentWrapper>
                </Route>
              ))}

              <Route exact path={ROUTES.ONBOARDING.SUMMARY}>
                <Summary
                  data={stepsData}
                  joiningDate={joiningDate}
                  allStepsCompleted={allStepsCompleted}
                  id={state.data.id}
                  enableLandingPage={enableLandingPage}
                />
              </Route>

              <Route exact path={ROUTES.ONBOARDING.WELCOME}>
                <Welcome
                  headerHeight={headerHeight}
                  showQuestion={!state.didAnswerQuestion && enableFeedback}
                  onQuestionAnswered={onQuestionAnswered}
                />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Flex>

      {state.data && !stepInfo.summary.isFocussed && !welcomePageMatch ? (
        <SideBar
          isOpen={progressTimelineOpen}
          onClose={() => setProgressTimelineOpen(false)}
          title="Onboarding progress timeline"
          subtitle={`Expected joining date: ${formatWithoutTimezone(joiningDate)}`}
          data-testid="progress-timeline"
        >
          <Steps data={stepsData} />
        </SideBar>
      ) : null}

      <DiversityFAQSidebar
        isOpen={diversityFaqOpen}
        onClose={() => setDiversityFaqOpen(false)}
      />
    </>
  )
}

export default connect(Onboarding)
