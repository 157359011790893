import React from 'react'
import { useGetFavourites } from '@src/api/favourites'
import Loader from '@components/CommonSC/Loader'
import { DefaultFallBack } from '@src/features/ErrorBoundary/ErrorBoundary'

export const withFavourites =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const { isLoading, isError, refetch } = useGetFavourites()

    if (isError) {
      return <DefaultFallBack retryAction={refetch} />
    }

    return isLoading ? <Loader /> : <Component {...props} />
  }
