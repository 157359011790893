import React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import {
  YesOrNoRadioSelect,
  YesOrNoRadioSelectProps,
} from '@components/Inputs/YesNoRadioSelect/YesNoRadioSelect'

interface Props extends Omit<YesOrNoRadioSelectProps, 'onChange'> {
  name: string
}

export const LapeYesOrNoRadioSelect = (props: Props) => {
  const { onChange, value } = useLapeField(props.name)

  return <YesOrNoRadioSelect value={value} onChange={onChange} {...props} />
}
