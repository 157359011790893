import { API } from '@src/constants/api'
import { ApiHandlerInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import { AxiosResponse } from 'axios'
import { FileInterface } from '@src/interfaces/files'
import { uploadFile } from '@src/api/files'
import {
  ExistingCandidatesParamsInterface,
  CandidateInterface,
} from '@src/interfaces/interviewTool'

export const getNewCandidateRequests: (
  apiHandler?: ApiHandlerInterface,
) => RequestInterfaceNew<NewCandidateInterface> = apiHandler => ({
  get: async ({ id }) => (apiHandler || api).get(`${API.INTERVIEWS}/sourcing/${id}`),
  update: async (data, { id }) =>
    (apiHandler || api).patch(`${API.INTERVIEWS}/sourcing/${id}`, data),
  submit: async data => {
    let file: AxiosResponse<FileInterface> | undefined
    if (data.resume) {
      file = await uploadFile(
        data.resume.id as File,
        'candidate_resume',
        undefined,
        apiHandler,
      )
    }

    const payload = { ...data }

    if (file?.data?.id) {
      payload.resume = {
        id: file.data.id,
      }
    }

    return (apiHandler || apiWithoutHandling)
      .post<NewCandidateInterface>(`${API.INTERVIEWS}/sourcing`, payload)
      .catch(error => {
        if (file?.data?.id) {
          ;(apiHandler || apiWithoutHandling).delete(
            `${API.FILES}/${file.data.id}`,
            undefined,
            undefined,
            true,
          )
        }
        throw error
      })
  },
})

export const getExistingCandidates = (
  name?: string,
  email?: string,
  phone?: string,
  linkedin?: string,
  apiHandler = apiWithoutHandling,
) => {
  const params: ExistingCandidatesParamsInterface = {
    full_name: name,
    email,
    phone,
    linkedin_url: linkedin,
  }

  return apiHandler.get<CandidateInterface[]>(
    `${API.INTERVIEWS}/sourcing/findCandidateMatches`,
    { params },
  )
}
