import { useCallback, useEffect, useRef, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewFeedbackStatus } from '@src/interfaces/interviewTool'
import { formatDateTime, formatTimeAgo } from '@src/utils/format'
import { useParams } from 'react-router-dom'
import { RequestUpdateType, Statuses } from '@src/interfaces'
import xxhashjs from 'xxhashjs'
import { difference } from '@src/utils/form'
import * as Sentry from '@sentry/react'
import { useShowSessionExpiredPopup } from '@src/hooks/useShowSessionExpiredPopup'

type StatusType = Statuses | InterviewFeedbackStatus

const AUTO_SAVING_INTERVAL = 1000 * 45
const UPDATE_MESSAGE_INTERVAL = 1000 * 5

const useAutoSavingDraft = <T extends { updated_date_time: string; status: StatusType }>(
  updateHandler: RequestUpdateType<T>,
  disabled?: boolean,
) => {
  const { id } = useParams<{ id: string }>()
  const { values, initialValues } = useLapeContext<T>()
  const [saving, setSaving] = useState(false)
  const [message, setMessage] = useState<string | null>()
  const [autosaveFailed, setAutosaveFailed] = useState(false)
  const prevValuesHash = useRef<string>()
  let autoSavingTimerId: ReturnType<typeof setTimeout>
  let updateMessageTimerId: ReturnType<typeof setInterval>
  const valuesRef = useRef<T>()
  const initialValuesRef = useRef<Partial<T>>()
  const showSessionExpiredPopup = useShowSessionExpiredPopup()

  const isAutoSavingNeeded = (status?: StatusType) =>
    !disabled && (status === Statuses.draft || status === Statuses.pending)

  const getHash = (ref?: Partial<T>) => {
    return xxhashjs
      .h32(JSON.stringify({ ...ref, updated_date_time: undefined }), 0xabcd)
      .toString(16)
  }

  // to save link to the values to be able to use inside setTimeout
  valuesRef.current = values
  initialValuesRef.current = initialValues

  const scheduleSaving = () => {
    autoSavingTimerId = setTimeout(() => {
      setAutosaveFailed(false)

      if (!isAutoSavingNeeded(valuesRef.current?.status)) {
        clearTimeout(autoSavingTimerId)
        return
      }

      const currentHash = getHash(valuesRef.current)

      if (currentHash === prevValuesHash.current) {
        clearTimeout(autoSavingTimerId)
        scheduleSaving()
        return
      }

      setSaving(true)
      let fallbackStatus: StatusType

      if (valuesRef.current) {
        fallbackStatus = valuesRef.current.status
        valuesRef.current.status = Statuses.draft
      }

      const diff = difference(valuesRef.current, initialValuesRef.current)

      updateHandler(
        diff,
        { id },
        // in case of catch jest gets outdated value by link
        { ...valuesRef.current } as Partial<T>,
        initialValuesRef.current,
      )
        .then(resp => {
          if (resp.data.updated_date_time && valuesRef.current) {
            valuesRef.current.updated_date_time = resp.data.updated_date_time
          }
          prevValuesHash.current = currentHash
          setMessage(getMessage())
        })
        .catch(err => {
          if (fallbackStatus && valuesRef.current) {
            valuesRef.current.status = fallbackStatus
          }
          setAutosaveFailed(true)

          if (err?.response?.status === 403 || err?.response?.status === 401) {
            showSessionExpiredPopup(
              'Recent changes cannot be saved. Please re-login to continue',
            )
          }

          Sentry.captureException(err)
        })
        .finally(() => {
          clearTimeout(autoSavingTimerId)
          scheduleSaving()
          setSaving(false)
        })
    }, AUTO_SAVING_INTERVAL)
  }

  const getMessage = useCallback(
    () =>
      valuesRef.current?.updated_date_time
        ? `Last saved ${formatTimeAgo(
            valuesRef.current.updated_date_time,
          )} (${formatDateTime(valuesRef.current.updated_date_time)})`
        : null,
    [valuesRef.current?.updated_date_time],
  )

  const updateMessage = () => {
    setMessage(getMessage())

    updateMessageTimerId = setInterval(() => {
      setMessage(getMessage())
    }, UPDATE_MESSAGE_INTERVAL)
  }

  useEffect(() => {
    if (isAutoSavingNeeded(values.status)) {
      scheduleSaving()
      updateMessage()
    }

    return () => {
      clearTimeout(autoSavingTimerId)
      clearInterval(updateMessageTimerId)
    }
  }, [])

  let lastSavedMessage = null

  if (isAutoSavingNeeded(values.status) && values.updated_date_time) {
    lastSavedMessage = saving ? 'Saving...' : message
  }

  return {
    lastSavedMessage,
    autosaveFailed,
    saving,
    hasChanges: () => {
      const curr = getHash(valuesRef.current)
      const prev = prevValuesHash.current
        ? prevValuesHash.current
        : getHash(initialValuesRef.current)
      return curr !== prev
    },
  }
}

export default useAutoSavingDraft
