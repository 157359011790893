import {
  Avatar,
  DetailsCell,
  Group,
  Link,
  Text,
  HStack,
  Item,
  Token,
  Icon,
} from '@revolut/ui-kit'
import React from 'react'
import {
  InterviewRoundInterface,
  InterviewRoundState,
} from '@src/interfaces/interviewTool'
import { useGetUnarchivedInterviewRounds } from '@src/api/recruitment/interviews'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getAvatarUrl } from '@src/utils/employees'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

type Props = {
  round?: InterviewRoundInterface
}

const ArchiveDetails = ({ round }: Props) => {
  const isArchived = round?.archived_reason
  const { data: rounds } = useGetUnarchivedInterviewRounds(
    isArchived && round.candidate?.id ? round.candidate.id : null,
  )
  const { setActiveAction } = useCandidateProfileContext()

  if (!isArchived) {
    return null
  }

  return (
    <Group>
      <Item
        use="button"
        onClick={() => {
          setActiveAction({
            type: 'archive',
          })
        }}
      >
        <Item.Avatar>
          <Avatar useIcon="Archive" color={Token.color.red} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Archival details</Item.Title>
          {round.state === InterviewRoundState.archived && rounds && rounds.length > 0 && (
            <Item.Description>
              Candidate has been archived for this opportunity. <br />
              There are {rounds.length} other opportunities to which the candidate has
              applied.
            </Item.Description>
          )}
        </Item.Content>
        <Item.Side>
          <Icon name="ChevronRight" color={Token.color.blue} />
        </Item.Side>
      </Item>
      <DetailsCell>
        <DetailsCell.Title>Reason</DetailsCell.Title>
        <DetailsCell.Content>{round.archived_reason?.name}</DetailsCell.Content>
      </DetailsCell>
      {round.archived_comment && (
        <DetailsCell>
          <DetailsCell.Title>Comments</DetailsCell.Title>
          <DetailsCell.Note whiteSpace="pre-line">
            {round.archived_comment}
          </DetailsCell.Note>
        </DetailsCell>
      )}
      {round.archived_employee && (
        <DetailsCell>
          <DetailsCell.Title>Archived by</DetailsCell.Title>
          <DetailsCell.Content>
            <Link
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: round.archived_employee.id,
              })}
              use={InternalLink}
              target="_blank"
              color="inherit"
            >
              <HStack space="s-8">
                <Avatar size={24} image={getAvatarUrl(round.archived_employee.avatar)}>
                  {round.archived_employee.avatar
                    ? ''
                    : round.archived_employee.display_name?.[0]}
                </Avatar>
                <Text>{round.archived_employee.display_name}</Text>
              </HStack>
            </Link>
          </DetailsCell.Content>
        </DetailsCell>
      )}
      {round.archived_date_time && (
        <DetailsCell>
          <DetailsCell.Title>Date</DetailsCell.Title>
          <DetailsCell.Content>
            {formatDate(round.archived_date_time)}
          </DetailsCell.Content>
        </DetailsCell>
      )}
    </Group>
  )
}

export default ArchiveDetails
