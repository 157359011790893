import React, { useState } from 'react'
import { Text, TextProps } from '@revolut/ui-kit'
import { formatNumber, formatNumberMillions } from '@src/utils/format'
import isNil from 'lodash/isNil'

const LongNumberCell = ({
  value,
  textAlign = 'left',
}: {
  value: number | string | null
  textAlign?: TextProps['textAlign']
}) => {
  const [isHovered, setIsHovered] = useState<boolean>()
  const valueNumber = Number(value)

  const onHover = () => setIsHovered(true)
  const onMouseLeave = () => setIsHovered(false)

  if (isNaN(valueNumber) || isNil(value)) {
    return <>-</>
  }

  return (
    <div onMouseOver={onHover} onFocus={onHover} onMouseLeave={onMouseLeave}>
      <Text textAlign={textAlign}>
        {isHovered ? formatNumber(valueNumber, 2) : formatNumberMillions(valueNumber)}
      </Text>
    </div>
  )
}

export default LongNumberCell
