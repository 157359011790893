import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { Comments, CommentsProps } from '@src/pages/Forms/Candidate/Comments/Comments'

export const CommentsPage = (props: CommentsProps) => {
  if (!props.roundId) {
    return null
  }
  return (
    <PageBody>
      <Comments {...props} />
    </PageBody>
  )
}
