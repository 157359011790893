import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { chain, Group, MoreBar, Text, TextArea } from '@revolut/ui-kit'
import { useEmployeeFields } from '@src/api/employees'
import {
  cancelTask,
  completeTask,
  employeeOnboardingTasksRequests,
} from '@src/api/onboardingEmployeesV2'
import { EmployeeInterface } from '@src/interfaces/employees'
import { OnboardingTaskInterface } from '@src/interfaces/onboardingV2'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import SettingsButtons from '@src/features/SettingsButtons'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getTaskColor } from '../utils'

type EmployeeDetails = Pick<EmployeeInterface, 'full_name' | 'avatar' | 'position'>

const TaskView = () => {
  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()
  const { id, onboardingId } = useParams<{ id: string; onboardingId: string }>()
  const { values, reset } = useLapeContext<OnboardingTaskInterface>()

  const { data: employee, isLoading } = useEmployeeFields<EmployeeDetails>(id, [
    'avatar',
    'id',
    'joining_date_time',
    'full_name',
    'name',
    'position',
  ])

  const [isPending, setIsPending] = useState(false)
  const [cancelPending, setCancelPending] = useState(false)
  const [cancelReason, setCancelReason] = useState<string>()

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.MAIN}
        pb="s-16"
        title={chain(
          values.name,
          <Text color={getTaskColor(values.status.id)}>{values.status.name}</Text>,
        )}
      />
      <PageBody>
        {values.status.id === 'in_progress' && (
          <SettingsButtons mb="s-16">
            <MoreBar.Action
              onClick={async () => {
                try {
                  setIsPending(true)
                  const response = await completeTask(onboardingId, values.id)
                  reset(response.data)
                } catch (err) {
                  showStatusPopup({
                    title: 'Failed to start the task',
                    description: getStringMessageFromError(err),
                    status: 'error',
                  })
                } finally {
                  setIsPending(false)
                }
              }}
              pending={isPending}
              useIcon="Check"
              variant="accent"
            >
              Mark as done
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => {
                confirmationPopup.show({
                  body: (
                    <>
                      <Text mb="s-16" use="div">
                        Are you sure you want to cancel this task?
                      </Text>
                      <TextArea
                        label="Cancel reason"
                        onChange={e => setCancelReason(e.currentTarget.value)}
                        rows={3}
                        value={cancelReason}
                      />
                    </>
                  ),
                  noMessage: 'Cancel',
                  yesMessage: 'Confirm',
                  onConfirm: async () => {
                    if (!cancelReason) {
                      return
                    }
                    try {
                      setCancelPending(true)
                      const response = await cancelTask(
                        onboardingId,
                        values.id,
                        cancelReason,
                      )
                      reset(response.data)
                    } catch (err) {
                      showStatusPopup({
                        title: 'Failed to cancel the task',
                        description: getStringMessageFromError(err),
                        status: 'error',
                      })
                    } finally {
                      setCancelPending(false)
                    }
                  },
                })
              }}
              pending={cancelPending}
              useIcon="Delete"
              variant="negative"
            >
              Cancel
            </MoreBar.Action>
          </SettingsButtons>
        )}

        <FormPreview data={employee} title="Employee">
          <Group>
            <FormPreview.Item
              insert={() => employee && <UserWithAvatar {...employee} />}
              loading={isLoading}
              title="Employee"
            />
            <FormPreview.Item
              field="position.name"
              loading={isLoading}
              title="Position"
            />
            <FormPreview.Item
              field="joining_date_time"
              loading={isLoading}
              title="Start date"
              type="date"
            />
          </Group>
        </FormPreview>

        <FormPreview data={values} title="Task">
          <Group>
            <FormPreview.Details field="description" title="Description" />
            <FormPreview.Item title="Assignee" field="assignee" type="employee" />
            <FormPreview.Item field="creation_date_time" title="Created on" type="date" />
            <FormPreview.Item field="update_date_time" title="Updated on" type="date" />
            <FormPreview.Details field="cancel_reason" title="Cancel reason" />
          </Group>
        </FormPreview>
      </PageBody>
    </PageWrapper>
  )
}

export const TaskViewForm = connect(() => (
  <Form api={employeeOnboardingTasksRequests}>
    <TaskView />
  </Form>
))
