import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  IdStatuses,
  LocationInterface,
} from '@src/interfaces/employees'

interface OnboardingStep {
  created_date_time: string
  description: string
  id: number | string
  is_required: boolean
  name: string
  status: { id: Statuses; name: string }
  type: string
  updated_date_time: string
}

export interface OnboardingData {
  full_name: string
  id: number | string
  joining_date_time: string
  location: LocationInterface
  onboarding_steps: OnboardingStep[]
}

export enum onboardingSteps {
  welcome = 'welcome',
  basic_info = 'basic_info',
  personal_info = 'personal_info',
  payroll = 'payroll',
  diversity = 'diversity',
  documents = 'documents',
  summary = 'summary',
}

export type LaptopDeliveryOption = IdAndName<
  'ship_to_different_address' | 'ship_to_address'
>

export interface OnboardingQueueInterface {
  id: number
  employee: EmployeeOptionInterface
  employee_status: IdAndName<IdStatuses>
  hr_manager: EmployeeOptionInterface
  location: IdAndName & { location_name: string }
  max_sla: number
  stage_sla: number
  role: IdAndName
  seniority: IdAndName
  sla: number
  stage: IdAndName & { description: string | null; order: string; sla_in_hours: number }
  start_date: string
  status: IdAndName<OnboardingTimelineStatus>
  team: IdAndName
}

export interface OnboardingQueueStatsInterface {
  average_onboarding_time: number
  breaching_sla: number
  hired: number
  onboarding: number
  pending: number
  total: number
  onboarding_stages: {
    [key: string]: number
  }
}

export enum ProcessStages {
  Start = 1,
  Work = 2,
  Documents = 3,
  Contract = 4,
  Screening = 5,
  RightToWork = 6,
  Finish = 7,
}

export enum ProcessStageSteps {
  ReviewDetails = 1,
  SendWelcomeEmail = 2,
  ReviewDocuments = 3,
  ConfirmAndRecordStartDate = 4,
  ReviewPositionInformation = 5,
  ReviewOrganisationInformation = 6,
  BackgroundChecks = 7,
  CandidatesDocuments = 8,
  Contract = 9,
  RightToWork = 10,
  AssignTimeOffPolicies = 11,
  SendDay1Instructions = 12,
}

export type OnboardingTimelineStatus =
  | 'in_progress'
  | 'skipped'
  | 'pending'
  | 'completed'
  | 'cancelled'

export interface OnboardingProcessInterface {
  id: number
  status: IdAndName<OnboardingTimelineStatus>
}

export interface ProcessStageStep {
  id: number
  step: IdAndName<ProcessStageSteps>
  status: IdAndName<OnboardingTimelineStatus>
  completed_by: EmployeeOptionInterface | null
  completed_on: string | null
  action_type: null
  external_action_type: null
}

export interface OnboardingTimelineProcessStage {
  id: number
  stage: {
    id: number
    name: string
    description: string | null
    sla_in_hours: number
  }
  process: {
    id: number
    employee: EmployeeOptionInterface
    status: IdAndName<OnboardingTimelineStatus>
    hr_manager: EmployeeOptionInterface
  }
  start_date_time: string
  end_date_time: string | null
  status: IdAndName<OnboardingTimelineStatus>
  completed_by: string | null
  process_stage_steps: ProcessStageStep[]
  sla: number
  deadline: string | null
}

export interface OnboardingTimelineEmployee extends EmployeeInterface {
  candidate_id: number
}

export interface OnboardingTimelineInterface {
  id: number
  employee: OnboardingTimelineEmployee
  start_date_time: string
  status: IdAndName<OnboardingTimelineStatus>
  process_stages: OnboardingTimelineProcessStage[]
  current_stage: OnboardingTimelineProcessStage
  hr_manager: EmployeeOptionInterface
  field_options: FieldOptions
}
