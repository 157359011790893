import { Avatar, Icon, Item, ItemSkeleton, Text, Token } from '@revolut/ui-kit'
import React, { useEffect } from 'react'
import { getPeriodCategoryBadgeContent } from './utils'
import { usePerformanceTimelineTable } from '../../Preview/Performance/PerformanceHistory/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceRating,
  PerformanceSelector,
  PerformanceTimelineInterface,
} from '@src/interfaces/performance'
import { getInverseColorsAndGrade } from '@src/utils/grades'
import { PerformanceChartGradeData } from '@src/interfaces/chart'
import { ReviewCycleCategory, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { isPast } from 'date-fns'

interface Props {
  data: EmployeeInterface
  setSelectedPeriod: (target: PerformanceSelector | undefined) => void
  performanceSelectorData: PerformanceSelector[] | undefined
  selectedPeriod: PerformanceSelector | undefined
  setSelectedCycle: (cycle: PerformanceTimelineInterface | undefined) => void
  setRecruitmentCycle: (flag: boolean) => void
  chartDataGradesValues: PerformanceChartGradeData<number>[]
  isFetching: boolean
  isCurrentUserProfile: boolean
}

export const PerformanceHistoryItems = ({
  data,
  setSelectedPeriod,
  performanceSelectorData,
  selectedPeriod,
  chartDataGradesValues,
  setSelectedCycle,
  setRecruitmentCycle,
  isFetching,
  isCurrentUserProfile,
}: Props) => {
  const performanceTimelineTable = usePerformanceTimelineTable(data)

  const performanceTimelineRecordByCycleId = performanceTimelineTable.table?.data.reduce<
    Record<string, PerformanceTimelineInterface>
  >((acc, item) => {
    if (item.cycle) {
      acc[item.cycle.id] = item
    }
    return acc
  }, {})

  useEffect(() => {
    if (selectedPeriod?.id) {
      const performanceTimeLineData =
        performanceTimelineRecordByCycleId?.[selectedPeriod.id]
      setSelectedCycle(performanceTimeLineData)
    }
  }, [selectedPeriod?.id, performanceTimelineTable?.table?.data])

  const probationGrade = chartDataGradesValues.find(
    item => item.progress_datetime_label === 'Probation',
  )?.progress

  const defineCycleSeniority = (
    cycleStatus: ReviewCycleStatus | undefined,
    performanceTimeLineData: PerformanceTimelineInterface | undefined,
  ) => {
    if (cycleStatus === ReviewCycleStatus.ongoing) {
      return data.specialisation_seniority_sublevel?.name || ''
    }

    return performanceTimeLineData?.employee_specialisation_seniority_sublevel?.name
      ? performanceTimeLineData?.employee_specialisation_seniority_sublevel?.name || ''
      : performanceTimeLineData?.employee_seniority?.name || ''
  }

  const defineCycleGrade = (
    cycle: PerformanceSelector,
    performanceTimeLineData: PerformanceTimelineInterface | undefined,
  ) => {
    const grade =
      cycle.category === ReviewCycleCategory.Probation
        ? probationGrade?.label
        : performanceTimeLineData?.grade?.label

    if (cycle.status === ReviewCycleStatus.ongoing && !grade) {
      return 'Pending'
    }

    if (isCurrentUserProfile && cycle.category === ReviewCycleCategory.Probation) {
      return isPast(new Date(cycle.end_date_time)) ? grade : ''
    }

    return grade
  }

  const defineCycleGradeColor = (
    itemGrade: string | undefined,
    itemCategory: ReviewCycleCategory,
    performanceTimeLineData: PerformanceTimelineInterface | undefined,
  ) => {
    if (itemGrade === PerformanceRatingTitle[PerformanceRating.performing]) {
      return Token.color.success
    }

    return (
      getInverseColorsAndGrade(
        itemCategory === ReviewCycleCategory.Probation
          ? probationGrade
          : performanceTimeLineData?.grade,
      )?.color || Token.color.greyTone50
    )
  }

  const isLoading = performanceTimelineTable.table?.loading || isFetching

  return (
    <>
      {isLoading && (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      )}

      {!isLoading &&
        performanceSelectorData?.map(item => {
          const performanceTimeLineData = performanceTimelineRecordByCycleId?.[item.id]
          const itemSeniority = defineCycleSeniority(item.status, performanceTimeLineData)
          const itemGrade = defineCycleGrade(item, performanceTimeLineData)

          return (
            <React.Fragment key={item.id}>
              <Item
                use="button"
                variant="compact"
                aria-pressed={selectedPeriod?.id === item.id}
                onClick={() => {
                  setSelectedPeriod(item)
                  if (performanceTimeLineData) {
                    setSelectedCycle(performanceTimeLineData)
                  }
                  setRecruitmentCycle(false)
                }}
              >
                <Item.Avatar>
                  <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
                    {selectedPeriod?.id === item.id && (
                      <Avatar.Badge
                        bg={Token.color.foreground}
                        position="bottom-right"
                        useIcon={<Icon name="Check" size={12} />}
                        size={16}
                      />
                    )}
                    {getPeriodCategoryBadgeContent(item.category, item.name)}
                  </Avatar>
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{itemSeniority}</Item.Title>
                  <Item.Description>{item.name}</Item.Description>
                </Item.Content>
                <Item.Side>
                  <Item.Value>
                    <Text
                      color={defineCycleGradeColor(
                        itemGrade,
                        item.category,
                        performanceTimeLineData,
                      )}
                      variant="body1"
                    >
                      {itemGrade}
                    </Text>
                  </Item.Value>
                </Item.Side>
              </Item>
            </React.Fragment>
          )
        })}
    </>
  )
}
