import React from 'react'
import {
  ReviewScorecardInterface,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TableWidget } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { KpiInterface } from '@src/interfaces/kpis'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { useGetEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useTable } from '@components/Table/hooks'
import { kpisRequests } from '@src/api/kpis'
import { getKpisRow } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { GradesMapInterface } from '@src/utils/grades'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface DeliverablesCardInterface {
  employee: EmployeeInterface
  gradesMap: GradesMapInterface
}

const DeliverablesCardTableContent = ({
  employee,
  gradesMap,
}: DeliverablesCardInterface) => {
  const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
  const cycleId = 'reviews' in values ? values.reviews[0].cycle?.id : values.cycle?.id
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  const table = useTable<KpiInterface>(kpisRequests, [
    {
      columnName: 'status',
      filters: [{ name: Statuses.active, id: Statuses.active }],
      nonResettable: goalsEnabled,
    },
    {
      columnName: 'cycle__id',
      filters: cycleId
        ? [{ name: String(cycleId), id: cycleId }]
        : [{ name: '0', id: 0 }],
      nonResettable: true,
    },
    {
      // @ts-ignore FIXME: REVPI-19 support optional team value
      filters: [{ name: String(employee?.team.id), id: Number(employee?.team.id) }],
      columnName: 'team__id',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      columnName: 'goals_related',
      filters: [
        { name: goalsEnabled ? 'true' : 'false', id: goalsEnabled ? 'true' : 'false' },
      ],
      nonResettable: true,
    },
    ...(goalsEnabled
      ? [
          {
            filters: [
              { name: Statuses.approved, id: Statuses.approved },
              { name: Statuses.completed, id: Statuses.completed },
            ],
            columnName: 'target_status',
            nonResettable: true,
          },
          {
            filters: [
              { name: Statuses.pending, id: Statuses.pending },
              { name: Statuses.approved, id: Statuses.approved },
            ],
            columnName: 'goal__approval_status',
            nonResettable: true,
          },
        ]
      : []),
  ])

  return (
    <TableWidget style={{ padding: 0 }}>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.EmployeePerformanceKPI}
          dataType={goalsEnabled ? 'Metrics' : 'KPI'}
          row={getKpisRow(gradesMap, goalsEnabled)}
          {...table}
          noDataMessage={`No ${goalsEnabled ? 'metrics' : 'KPI'} defined`}
          onRowClick={onKPITableRowClick}
          noReset
          hideCountAndButtonSection
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export const DeliverablesCardTable = connect(
  ({ gradesMap }: { gradesMap: GradesMapInterface }) => {
    const { values } = useLapeContext<ReviewScorecardInterface | ReviewSummaryInterface>()
    const employeeId =
      'reviews' in values
        ? values.reviews[0].reviewed_employee.id
        : values.reviewed_employee.id
    const { data: employee } = useGetEmployeeWithManagerFilter(
      employeeId,
      'line_manager__id',
    )

    if (!employee) {
      return null
    }

    return <DeliverablesCardTableContent employee={employee} gradesMap={gradesMap} />
  },
)
