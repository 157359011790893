import { Statuses } from '@src/interfaces'
import {
  QueryRunStatuses,
  ReportColumnsTableInterface,
  ReportFilterInterface,
} from '@src/interfaces/dataAnalytics'
import { Token } from '@revolut/ui-kit'
import { CellTypes, FilterType } from '@src/interfaces/data'

export const isExecuted = (status?: QueryRunStatuses) =>
  status === QueryRunStatuses.Success || status === QueryRunStatuses.Error

export const isRunning = (status?: QueryRunStatuses) =>
  status === QueryRunStatuses.Running || status === QueryRunStatuses.Queued

export const reportStatusToColor = (status: Statuses) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const getColumnAttributes = (
  column: ReportColumnsTableInterface,
  filters?: ReportFilterInterface[],
  columnName?: string,
) => {
  const filter = columnName || filters?.find(f => f.column_name === column.name)

  switch (column.type) {
    case 'bool':
      return {
        type: CellTypes.insert,
        filterKey: filter ? `${column.name}__in` : null,
        filterType: filter ? FilterType.boolean : undefined,
      }
    case 'string':
      return {
        type: CellTypes.insert,
        filterKey: filter ? `${column.name}__in` : null,
        filterType: filter ? FilterType.selector : undefined,
      }
    case 'uint32':
    case 'int16':
    case 'int32':
    case 'int64':
    case 'float':
    case 'double':
      return {
        type: CellTypes.text,
        filterKey: filter ? column.name : null,
        filterType: filter ? FilterType.number : undefined,
      }
    case 'date32[day]':
      return {
        type: CellTypes.date,
        filterKey: filter ? `${column.name}__range` : null,
        filterType: filter ? FilterType.date : undefined,
      }
    case 'time64[us]':
      return {
        type: CellTypes.text,
        filterKey: null,
        filterType: undefined,
      }
    case 'timestamp[us]':
      return {
        type: CellTypes.dateTime,
        filterKey: filter ? `${column.name}__range` : null,
        filterType: filter ? FilterType.date : undefined,
      }
    default:
      return undefined
  }
}
