import React, { useMemo } from 'react'

import { Bar, InputGroup } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { serviceDeskCategoriesRequest, useFaqCategories } from '@src/api/faq'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { DeleteButtonLape } from '@src/pages/Forms/FormButtons'
import { Subcategories } from './Subcategories'

export const ServiceDeskCategoryForm = () => {
  const { values } = useLapeContext<ServiceDeskCategoryInterface>()
  const { data: faqCategoriesData } = useFaqCategories()
  const backUrl = values.id
    ? pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.PREVIEW, {
        id: values.id,
      })
    : pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE)

  const faqCategoryOptions = useMemo(
    () =>
      faqCategoriesData?.results.map(category => ({
        label: category.name,
        value: {
          id: category.id,
          name: category.name,
        },
      })),
    [faqCategoriesData],
  )

  return (
    <>
      <PageHeader
        backUrl={backUrl}
        title={values.id ? 'Edit Category' : 'Add Category'}
      />
      <PageBody>
        <Bar>
          {values.id && (
            <DeleteButtonLape
              data={values}
              deleteApi={serviceDeskCategoriesRequest.delete!}
              title="category"
              backUrl={backUrl}
            />
          )}
        </Bar>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewInput required name="name" label="Category Name" />
            <LapeNewInput required name="link" label="Link" />
            <LapeRadioSelectInput
              name="faq_category"
              options={faqCategoryOptions}
              label="FAQ Category"
            />
          </InputGroup>
          <NewStepperTitle title="Subcategories" />
          <Subcategories isEditing />
        </AutoStepper>
        <PageActions>
          <NewSaveButtonWithPopup
            useValidator
            previewUrl={ROUTES.APPS.HELP_CENTER.SD_CATEGORY.PREVIEW}
          />
        </PageActions>
      </PageBody>
    </>
  )
}
