import React from 'react'
import { ColumnInterface, CellTypes } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { IssueInterface } from '@src/interfaces/issues'
import { A } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Link, Text, Token } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import Table from '@components/TableV2/Table'
import { ColorTagVariant, ColorTag } from '@components/ColorTag/ColorTag'
import { AuditStatuses } from '@src/interfaces/audit'
import round from 'lodash/round'
import styled from 'styled-components'

const TooltipContainer = styled.div`
  white-space: nowrap;
  padding: 12px;
`

export const getSeverityColor = (data: IssueInterface) => {
  switch (data.severity?.id) {
    case 'minor':
      return Token.color.greyTone50
    case 'medium':
      return Token.color.warning
    case 'major':
    case 'blocker':
    case 'critical':
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

const getStatusVariant = (status: AuditStatuses): ColorTagVariant => {
  switch (status) {
    case AuditStatuses.DONE:
      return 'success'

    case AuditStatuses.TO_DO:
    case AuditStatuses.PENDING_FILING:
    case AuditStatuses.QUALITY_REVIEW:
      return 'neutral'

    default:
      return 'default'
  }
}

export const issueLinkColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_link',
  dataPoint: 'issue_link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.issue_link} target="_blank">
      <ColorTag icon="Jira|image">{data.issue_id}</ColorTag>
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Issue',
}

export const issueSummaryColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
  insert: ({ data }) =>
    data.summary_link ? (
      <Link use="a" rel="noreferrer noopener" target="_blank" href={data.summary_link}>
        {data.summary}
      </Link>
    ) : (
      data.summary
    ),
}

export const issueTypeColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'issue_type.id',
  dataPoint: 'issue_type.name',
  sortKey: 'issue_type',
  filterKey: 'issue_type',
  selectorsKey: selectorKeys.issue_type,
  title: 'Type',
}

export const issueTeamColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const issueDepartmentColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'department.id',
  dataPoint: 'department.name',
  sortKey: 'department__name',
  filterKey: 'department__id',
  selectorsKey: selectorKeys.department,
  title: 'Department',
}

export const issueSeverityColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.text,
  idPoint: 'severity.id',
  dataPoint: 'severity.name',
  sortKey: 'severity',
  filterKey: 'severity',
  selectorsKey: selectorKeys.issue_severity,
  title: 'Severity',
  colors: getSeverityColor,
}

export const issueAssigneeColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'assignee.id',
  dataPoint: 'assignee.name',
  sortKey: 'assignee__full_name',
  filterKey: 'assignee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
  insert: ({ data }) => <UserWithAvatar {...data.assignee} />,
}

export const issueOpenDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'created_date_time',
  dataPoint: 'created_date_time',
  sortKey: 'created_date_time',
  filterKey: 'created_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Open date',
}

export const issueDueDateColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.date,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: 'due_date',
  selectorsKey: selectorKeys.none,
  title: 'Due date',
}

export const issueDaysLeftColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'days_left',
  dataPoint: 'days_left',
  sortKey: 'days_left',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Days left / Overdue',
  insert: ({ data }) => {
    if (!data.days_left) {
      return '-'
    }
    if (data.days_left < 0) {
      return <Text color={Token.color.danger}>Overdue</Text>
    }
    return pluralize('day', data.days_left, true)
  },
}

export const issueStatusColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.issue_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getStatusVariant(data.status.id)}
    />
  ),
}

export const issueAverageCountColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'average_count',
  dataPoint: 'average_count',
  sortKey: 'average_count',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Current value',
  headerTooltip: (
    <TooltipContainer>
      <Text>Current value SMA28</Text>
    </TooltipContainer>
  ),
  insert: ({ data }) => round(data.average_count),
}

export const issueInitialAverageCountColumn: ColumnInterface<IssueInterface> = {
  type: CellTypes.insert,
  idPoint: 'initial_average_count',
  dataPoint: 'initial_average_count',
  sortKey: 'initial_average_count',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Initial value',
  headerTooltip: (
    <TooltipContainer>
      <Text>Start of Quarter value SMA28</Text>
    </TooltipContainer>
  ),
  insert: ({ data }) => round(data.initial_average_count),
}
