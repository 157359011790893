import React, { useState } from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { Box, VStack, Widget } from '@revolut/ui-kit'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectorKeys } from '@src/constants/api'
import { LocationInterface } from '@src/interfaces/locations'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import PageLoading from '@src/components/PageLoading/PageLoading'

const JobPostingsCompensationBands = () => {
  const [state, setState] = useState<boolean>(false)

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeJobPostingPreferences)
  if (state === undefined) {
    return <PageLoading />
  }
  return (
    <VStack gap="s-16">
      <Box>
        <SectionTitle
          title="Do you want to display compensation in postings created for certain locations?"
          subtitle={
            <>
              In some countries and regions, it is required by law for job postings to
              include a compensation band (salary range). Failing to comply with these
              rules may pose regulatory and financial risks. With the feature provided,
              you can define the countries and locations for which the 'compensation
              bands' field will be mandatory during job posting creation. If you can’t
              find a location or need to access location-specific details, use the{' '}
              <InternalLink to={pathToUrl(ROUTES.APPS.LOCATIONS)}>
                Locations app
              </InternalLink>
              .
            </>
          }
        />
      </Box>
      <BooleanRadioSwitch
        value={state}
        onChange={setState}
        disabled={disableEdit}
        variant="horizontal"
      />
      {state && (
        <>
          <Widget p="s-16">
            <LapeNewMultiSelect<LocationInterface>
              name="mandatory_compensation_locations"
              placeholder="Locations"
              required
              selector={selectorKeys.location}
              disabled={disableEdit}
            />
          </Widget>
        </>
      )}
    </VStack>
  )
}

export default JobPostingsCompensationBands
