import { PerformanceRating } from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'

export const COMPLEXITY_OPTIONS = [
  {
    id: PerformanceRating.dont_know,
    name: "Don't Know",
    text: 'I have insufficient data to assess the complexity for this ticket',
  },
  {
    id: PerformanceRating.basic,
    name: 'Basic',
    summary: '(Score: 2)',
    text: 'Task is standardized, there are multiple examples of such task implemented in the company',
  },
  {
    id: PerformanceRating.intermediate,
    name: 'Intermediate',
    summary: '(Score: 3)',
    text: 'There are few example of similar tasks being solved yet in the department/area',
  },
  {
    id: PerformanceRating.advanced,
    name: 'Advanced',
    summary: '(Score: 4)',
    text: 'Task was never done before, there are no example of such task being solved yet in the company',
  },
  {
    id: PerformanceRating.expert,
    name: 'Expert',
    summary: '(Score: 5)',
    text: 'Innovative across the all industry, there are no example of such task being solved in the industry',
  },
]

export const QUALITY_OPTIONS = [
  {
    id: PerformanceRating.dont_know,
    name: "Don't Know",
    text: 'I have insufficient data to assess the quality for this ticket',
  },
  {
    id: PerformanceRating.poor,
    name: 'Poor',
    summary: '(Score: 1)',
    text: 'The quality is unacceptably low',
  },
  {
    id: PerformanceRating.basic,
    name: 'Basic',
    summary: '(Score: 2)',
    text: 'Tasks are delivered to an acceptable standard, sometimes require several iterations before completion',
  },
  {
    id: PerformanceRating.intermediate,
    name: 'Intermediate',
    summary: '(Score: 3)',
    text: 'Tasks are delivered to a high standard, typically require only 1 iteration/area',
  },
  {
    id: PerformanceRating.advanced,
    name: 'Advanced',
    summary: '(Score: 4)',
    text: 'Tasks are always delivered to high standard, and require no iterations ',
  },
  {
    id: PerformanceRating.expert,
    name: 'Expert',
    summary: '(Score: 5)',
    text: 'Tasks are consistently delivered to the highest possible standards',
  },
]

export const EXTENDED_QUALITY_OPTIONS = Object.keys(PerformanceRatingTitle).map(key => ({
  id: key,
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  name: PerformanceRatingTitle[key],
  text: '',
}))

export const SPEED_OPTIONS = [
  {
    id: PerformanceRating.dont_know,
    name: "Don't Know",
    text: 'I have insufficient data to assess the speed for this ticket',
  },
  {
    id: PerformanceRating.poor,
    name: 'Poor',
    summary: '(Score: 1)',
    text: 'Usually delivers tasks late, misses important deadlines',
  },
  {
    id: PerformanceRating.basic,
    name: 'Basic',
    summary: '(Score: 2)',
    text: 'Usually completes tasks on time, rarely misses deadlines',
  },
  {
    id: PerformanceRating.intermediate,
    name: 'Intermediate',
    summary: '(Score: 3)',
    text: 'Always meets deadlines, often beats them',
  },
  {
    id: PerformanceRating.advanced,
    name: 'Advanced',
    summary: '(Score: 4)',
    text: 'Delivers very fast, habitually beats deadlines',
  },
  {
    id: PerformanceRating.expert,
    name: 'Expert',
    summary: '(Score: 5)',
    text: 'Delivers at exceptional speed, beats deadlines by unbelievable margins',
  },
]

export const RATING_OPTIONS = [
  {
    id: PerformanceRating.dont_know,
    name: "Don't Know",
    text: 'I have insufficient data to assess the quality for this ticket',
  },
  {
    id: PerformanceRating.poor,
    name: 'Poor',
    text: 'The quality is unacceptably low',
  },
  {
    id: PerformanceRating.basic,
    name: 'Basic',
    text: 'Tasks are delivered to an acceptable standard, sometimes require several iterations before completion',
  },
  {
    id: PerformanceRating.intermediate,
    name: 'Intermediate',
    text: 'Tasks are delivered to a high standard, typically require only 1 iteration/area',
  },
  {
    id: PerformanceRating.advanced,
    name: 'Advanced',
    text: 'Tasks are always delivered to high standard, and require no iterations ',
  },
  {
    id: PerformanceRating.expert,
    name: 'Expert',
    text: 'Tasks are consistently delivered to the highest possible standards',
  },
]
