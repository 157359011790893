import React, { useEffect, useMemo, useState } from 'react'
import { sumBy } from 'lodash'
import {
  getQueryRunResult,
  getQueryRunResultColumns,
  getReportColumnValues,
} from '@src/api/dataAnalytics'
import {
  CellTypes,
  ColumnCellInterface,
  FilterType,
  FilterByInterface,
} from '@src/interfaces/data'
import {
  ReportInterface,
  RunReportResponseInterface,
} from '@src/interfaces/dataAnalytics'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { orderingToSort } from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'
import { selectorKeys } from '@src/constants/api'
import { getColumnAttributes } from '@src/pages/Forms/DataAnalyticsReportViewForm/utils'
import { valueColorMap, rowColorMap } from '../../DataAnalyticsReportForm/common'

interface Props {
  run: RunReportResponseInterface
}

export const PreviewTable = ({ run }: Props) => {
  const { values } = useLapeContext<ReportInterface>()
  const initialSortBy = orderingToSort(values.output_format.ordering || [])
  const [tableSummary, setTableSummary] = useState<
    { value: string | null; count: number }[]
  >([])

  const initialFilterBy = values.output_format.columns.reduce((acc, column) => {
    const filter = values.output_format.filters?.find(f => f.column_name === column.name)
    if (!filter) {
      return acc
    }
    const attributes = getColumnAttributes(column, undefined, filter.column_name)
    if (filter.default_value && attributes?.filterKey) {
      acc.push({
        columnName: attributes.filterKey,
        filters: JSON.parse(filter.default_value) || [],
      })
    }
    return acc
  }, [] as FilterByInterface[])

  const table = useTable(
    {
      getItems: getQueryRunResult(run.query_run?.id, values.query.id),
    },
    initialFilterBy,
    initialSortBy,
  )

  const tableRows = useMemo(() => {
    const cells = values.output_format.columns.map(column => {
      const columnAttributes = getColumnAttributes(column, values.output_format.filters)
      return {
        type: columnAttributes?.type || CellTypes.text,
        idPoint: column.name,
        dataPoint: column.name,
        sortKey: column.name,
        filterKey: columnAttributes?.filterKey || null,
        filterType: columnAttributes?.filterType || undefined,
        selectorsKey: () =>
          columnAttributes?.filterType === FilterType.selector
            ? getReportColumnValues(values.id, run.id, column.name)
            : selectorKeys.none,
        title: column.alias || column.name,
        width: 300,
        colors: data => {
          const color = values.output_format.highlighting?.find(
            highlight =>
              highlight.target.id === 'value' &&
              highlight.condition.key === column.name &&
              data[highlight.condition.key].toString() === highlight.condition.value,
          )?.color
          return color ? valueColorMap[color] : undefined
        },
      } as ColumnCellInterface<any>
    })

    return {
      highlight: (data: any) => {
        const color = values.output_format.highlighting?.find(
          highlight =>
            highlight.target.id === 'row' &&
            data[highlight.condition.key].toString() === highlight.condition.value,
        )?.color
        return color ? rowColorMap[color] : ''
      },
      cells,
    }
  }, [])

  const fetchSummary = async () => {
    const runResult = await getQueryRunResultColumns(
      run.query_run.id!,
      values.query.id,
      values.output_format.summary_column!,
    )

    if (runResult.data) {
      setTableSummary(runResult.data)
    }
  }

  useEffect(() => {
    if (run.query_run?.id && values.output_format.summary_column) {
      fetchSummary()
    } else {
      setTableSummary([])
    }
  }, [run.query_run?.id])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {values.output_format.summary_column && (
          <>
            {tableSummary.map(item => (
              <Stat
                key={item.value}
                label={item.value === null ? '(blank)' : item.value}
                val={item.count}
              />
            ))}
            <Stat label="total" val={sumBy(tableSummary, 'count')} />
          </>
        )}
      </Table.Widget.Info>
      <Table.Widget.Table>
        <AdjustableTable
          {...table}
          count={table.count}
          hideCount
          name={TableNames.ReportingAppReportsPreviewTable}
          row={tableRows}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
