import { IssuesCounts } from '@src/interfaces/issues'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import React from 'react'
import { Token, chain } from '@revolut/ui-kit'

interface IssuesCirclesProps {
  counts?: IssuesCounts
  showSum?: boolean
}

export const IssuesCircles = ({ counts, showSum }: IssuesCirclesProps) => {
  const sum = counts
    ? counts.blocker + counts.critical + counts.major + counts.medium + counts.minor
    : 0

  if (!counts || sum === 0 || showSum) {
    return <QuickSummaryCount count={sum} />
  }

  return (
    <>
      {chain(
        counts.blocker > 0 ? (
          <QuickSummaryCount count={counts.blocker} color={Token.color.red} />
        ) : undefined,
        counts.critical > 0 ? (
          <QuickSummaryCount count={counts.critical} color={Token.color.red} />
        ) : undefined,
        counts.major > 0 ? (
          <QuickSummaryCount count={counts.major} color={Token.color.warning} />
        ) : undefined,
        counts.medium > 0 ? (
          <QuickSummaryCount count={counts.medium} color={Token.color.yellow} />
        ) : undefined,
        counts.minor > 0 ? (
          <QuickSummaryCount count={counts.minor} color={Token.color.greyTone50} />
        ) : undefined,
      )}
    </>
  )
}
