import { HStack, Avatar, Text, SelectOptionType } from '@revolut/ui-kit'
import React from 'react'
import { getInitials } from '@src/utils/employees'
import { AvatarType } from '@src/interfaces/employees'

type NameAndAvatar = { name?: string | null; avatar?: AvatarType }

interface OptionWithAvatarProps<T = NameAndAvatar> {
  option: Omit<SelectOptionType<T>, 'key' | 'children'>
}

interface NameAndAvatarProps {
  value?: NameAndAvatar
}

export const OptionAvatar = ({ value }: NameAndAvatarProps) => {
  return (
    <Avatar
      image={typeof value?.avatar === 'string' ? value?.avatar : value?.avatar?.image_72}
      size={24}
      label={value?.name ? getInitials(value?.name) : undefined}
    />
  )
}

export const OptionWithAvatar = ({ option }: OptionWithAvatarProps) => {
  return (
    <>
      <HStack gap="s-8" align="center">
        <OptionAvatar value={option.value} />
        <Text>{option.value?.name}</Text>
      </HStack>
    </>
  )
}
