import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { documentsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { DocumentsIntro } from './Intro'
import { DocumentsUpload } from './DocumentsUpload'

const routes = [
  {
    title: 'Intro',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.INTRO,
    canView: [PermissionTypes.UploadDocuments],
    component: DocumentsIntro,
  },
  {
    title: 'Import documents',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.IMPORT,
    canView: [PermissionTypes.UploadDocuments],
    component: DocumentsUpload,
    hideActions: true,
    hidePageBody: true,
  },
]

export const Documents = () => {
  return <OnboardingChecklistContent config={documentsConfig} routes={routes} />
}
