import React, { useEffect, useState } from 'react'
import { Box, Text, Widget } from '@revolut/ui-kit'
import { formatDate, formatDateDistance } from '@src/utils/format'
import { EstimatedRequisitionAnalytics } from '@src/interfaces/specialisations'
import { calcEstimatedRequisitionAnalytics } from '@src/api/specialisations'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Statuses } from '@src/interfaces'

const QueuePosition = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const [data, setData] = useState<EstimatedRequisitionAnalytics>(
    values.status === Statuses.opened
      ? {
          pipeline_queue_position: values.pipeline_queue_position,
          estimated_fill_date: values.estimated_fill_date,
        }
      : {},
  )

  useEffect(() => {
    if (
      [Statuses.opened, Statuses.closed, Statuses.rejected].includes(values.status) ||
      !values.specialisation?.id ||
      !values.headcount
    ) {
      return
    }

    calcEstimatedRequisitionAnalytics(+values.specialisation.id, values.headcount).then(
      res => setData(res.data),
    )
  }, [values.specialisation?.id, values.headcount])

  if (values.status === Statuses.rejected || values.status === Statuses.closed) {
    return null
  }

  if (!data.pipeline_queue_position && !data.estimated_fill_date) {
    return null
  }

  return (
    <Widget mb="s-16">
      {!!data.pipeline_queue_position && (
        <Box p="s-16">
          <Text use="div" fontWeight={500} mb="s-8">
            Position in queue
          </Text>
          <Text use="div" color="grey-tone-50">
            {data.pipeline_queue_position}
          </Text>
        </Box>
      )}
      {data.estimated_fill_date && (
        <Box p="s-16">
          <Text use="div" fontWeight={500} mb="s-8">
            Time to hire
          </Text>
          <Text use="div" color="grey-tone-50">
            {formatDateDistance(new Date(data.estimated_fill_date))} (
            {formatDate(data.estimated_fill_date)})
          </Text>
        </Box>
      )}
    </Widget>
  )
}

export default QueuePosition
