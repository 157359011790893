import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import Preferences from '@src/pages/UserPreferences/Preferences/Preferences'
import PrivacyPolicies from '@src/pages/UserPreferences/PrivacyPolicies/PrivacyPolicies'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

const UserPreferences = () => {
  const isCoreNavigation = useCoreNavigation()

  return (
    <>
      {isCoreNavigation ? (
        <Switch>
          <Route exact path={ROUTES.PREFERENCES.PRIVACY}>
            <HideIfCommercial>
              <PrivacyPolicies />
            </HideIfCommercial>
          </Route>
          <Route>
            <InternalRedirect to={ROUTES.MAIN} />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path={ROUTES.PREFERENCES.ALL} component={Preferences} />
          <HideIfCommercial>
            <Route exact path={ROUTES.PREFERENCES.PRIVACY} component={PrivacyPolicies} />
          </HideIfCommercial>
        </Switch>
      )}
    </>
  )
}

export default UserPreferences
