import React, { useEffect, useState } from 'react'
import {
  Avatar,
  BottomSheet,
  Box,
  Header,
  InputGroup,
  Text,
  Lightbox,
  TextButton,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { UpdateTypes } from '@src/interfaces/kpis'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { integrationPopupContentMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/integrationPopupContentMap'
import { updateTypeOptionMap } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/updateTypeOptionMap'
import { ConnectionType, useMakeConnection } from '../useMakeConnection'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  IntegrationForm,
  serverNameValidators,
} from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'
import {
  HelperPopupVariant,
  IntegrationHelperPopup,
} from './IntegrationHelperPopup/IntegrationHelperPopup'
import { Video } from '@src/components/Video/Video'

interface Props {
  isOpen: boolean
  onClose: VoidFunction
  type: ConnectionType
}

const connectionTypeToUpdateTypeMap: Record<ConnectionType, UpdateTypes> = {
  looker: UpdateTypes.looker,
  jira: UpdateTypes.roadmap,
  tableau: UpdateTypes.tableau,
  clickup: UpdateTypes.clickup,
  salesforce: UpdateTypes.salesforce,
}

export const IntegrationEnablePopup = ({ isOpen, onClose, type }: Props) => {
  const { name, icon, image, iconBackground } =
    updateTypeOptionMap[connectionTypeToUpdateTypeMap[type]]
  const [helpPopupOpened, setHelpPopupOpened] = useState<HelperPopupVariant>()
  const [lighBoxSrc, setLightBoxSrc] = useState<
    { video: string; poster: string } | undefined
  >()

  return (
    <>
      <BottomSheet open={isOpen} onClose={onClose}>
        <Header>
          <Header.Title>
            <FormattedMessage
              values={{ name }}
              defaultMessage="Your {name} account"
              id="performance.goalForm.connection.popup.title"
            />
          </Header.Title>
          <Header.Actions>
            <Avatar image={image} useIcon={icon} bg={iconBackground} />
          </Header.Actions>
        </Header>

        <UpdateForm
          type={type}
          onClose={onClose}
          setHelpPopupOpened={setHelpPopupOpened}
        />
      </BottomSheet>
      <IntegrationHelperPopup
        helpPopupOpened={helpPopupOpened}
        onClose={() => setHelpPopupOpened(undefined)}
        onAfterSubmit={() => onClose()}
        setLightBoxSrc={setLightBoxSrc}
      />
      <Lightbox
        open={!!lighBoxSrc}
        onClose={() => {
          setLightBoxSrc(undefined)
        }}
      >
        <Lightbox.Item>
          <Box maxWidth="75%">
            <Video poster={lighBoxSrc?.poster} url={lighBoxSrc?.video} />
          </Box>
        </Lightbox.Item>
      </Lightbox>
    </>
  )
}

const UpdateForm = ({
  type,
  onClose,
  setHelpPopupOpened,
}: Omit<Props, 'isOpen'> & {
  setHelpPopupOpened: (variant: HelperPopupVariant) => void
}) => {
  const user = useSelector(selectUser)
  const { values, submit, apiErrors } = useLapeContext<IntegrationForm>()
  const makeConnection = useMakeConnection()

  const handleConnection = async () => {
    if (type === 'looker') {
      setHelpPopupOpened('looker')
      return
    }
    await makeConnection(type)
    onClose()
  }

  useEffect(() => {
    if (type === 'tableau' && (!values.owner || values.owner.id !== user.id)) {
      values.owner = { id: user.id }
    }
    if (type === 'clickup' && !values.is_external_app) {
      values.is_external_app = true
    }
    if (type === 'salesforce' && !values.is_dedicated_connected_app) {
      values.is_dedicated_connected_app = true
    }
  }, [])

  return (
    <>
      <InputGroup>
        {type === 'clickup' ? (
          <>
            <LapeNewInput name="client_id" label="Client ID" required />
            <LapeNewInput
              name="client_secret"
              label="Client Secret"
              required
              type="password"
            />
            <TextButton
              onClick={() => {
                setHelpPopupOpened('clickup')
              }}
            >
              Where to find this?
            </TextButton>
          </>
        ) : (
          <LapeNewInput
            name="server"
            label={integrationPopupContentMap[connectionTypeToUpdateTypeMap[type]].label}
            required
          />
        )}
        {type === UpdateTypes.salesforce && (
          <>
            <LapeNewInput name="client_id" label="Client ID" required />
            <LapeNewInput name="client_secret" label="Client secret" required />
            <TextButton
              onClick={() => {
                setHelpPopupOpened('salesforce')
              }}
            >
              <Text variant="caption">Where to find this?</Text>
            </TextButton>
          </>
        )}
        {type === UpdateTypes.tableau && (
          <>
            <LapeNewInput name="client_id" label="Client ID" required />
            <LapeNewInput name="secret_id" label="Secret ID" required />
            <LapeNewInput
              name="secret_value"
              label="Secret Value"
              required
              type="password"
            />
            <TextButton
              onClick={() => {
                setHelpPopupOpened('tableau')
              }}
            >
              Where to find this?
            </TextButton>
          </>
        )}
        {integrationPopupContentMap[connectionTypeToUpdateTypeMap[type]].description}
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onClick={async () => {
            // need custom validation here to remove trailing /
            if (values.server) {
              await serverNameValidators[connectionTypeToUpdateTypeMap[type]]?.server
                .validate(values.server)
                .then(async res => {
                  if (res) {
                    values.server = res
                  }
                  await submit()
                })
                .catch(err => {
                  apiErrors.server = err.message
                })
            } else {
              await submit()
            }
          }}
          onAfterSubmit={handleConnection}
          isExistingData
          hideWhenNoChanges={false}
        >
          <FormattedMessage
            values={{
              name: updateTypeOptionMap[connectionTypeToUpdateTypeMap[type]].name,
            }}
            defaultMessage="Connect to {name}"
            id="performance.goalForm.connection.popup.cta"
          />
        </NewSaveButtonWithPopup>
      </BottomSheet.Actions>
    </>
  )
}
