import React, { useState } from 'react'
import { MoreBar, Color, Skeleton } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import {
  changeInterviewRound,
  useGetFullInterviewRounds,
} from '@src/api/recruitment/interviews'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  candidateId: number
  round: InterviewRoundInterface
  onRefresh: () => void
}

const ChangeRoundWidget = ({ candidateId, round, onRefresh }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const baseInterviewRoundOnJobPosting = featureFlags.includes(
    FeatureFlags.BaseInterviewRoundOnJobPosting,
  )
  const { data, isLoading } = useGetFullInterviewRounds(candidateId || null)
  const fullInterviewRound = data?.find(d => d.id === round.id)
  const mainJobPosting = fullInterviewRound?.application
  const [loading, setLoading] = useState(false)

  const onClick = async () => {
    setLoading(true)
    try {
      await changeInterviewRound(candidateId, round.id)
      onRefresh()
    } finally {
      setLoading(false)
    }
  }

  if (isLoading) {
    return <ActionWidget title={<Skeleton />} />
  }

  const title =
    baseInterviewRoundOnJobPosting && mainJobPosting?.job_posting?.name
      ? mainJobPosting?.job_posting.name
      : round.specialisation?.name

  const type =
    baseInterviewRoundOnJobPosting && mainJobPosting?.job_posting?.name
      ? 'job posting'
      : 'specialisation'

  return (
    <ActionWidget
      title={`You are previewing the candidate's interview process for ${title}`}
      text={`Do you want to mark this ${type} as the main one for the candidate? Doing this will switch the current active interview process and enable all actions on the profile e.g. interview scheduling, changing stages for this ${type}`}
      avatarColor={Color.BLUE}
    >
      <MoreBar>
        <MoreBar.Action
          variant="accent"
          onClick={onClick}
          pending={loading}
          disabled={loading}
        >
          Mark as main
        </MoreBar.Action>
      </MoreBar>
    </ActionWidget>
  )
}

export default ChangeRoundWidget
