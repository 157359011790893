import React, { useState } from 'react'
import { Avatar, Item, ItemSkeleton, Text, Token, VStack, chain } from '@revolut/ui-kit'

import { useGetPayCycleReportChanges } from '@src/api/payrollV2'
import SideBar from '@src/components/SideBar/SideBar'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { CommonTabProps } from '../common'
import { DetailsSidebarContent } from '../common/DetailsSidebarContent'
import { PayCycleEmployeeReportsTable } from './EmployeeReportsTable'

export const PayCycleTabOverview = (props: CommonTabProps) => {
  const cycleId = props.data?.id

  const [reportId, setReportId] = useState<number>()

  const {
    data: reportChanges = [],
    isLoading: isLoadinReportChanges,
    isError: isReportChangesError,
  } = useGetPayCycleReportChanges(cycleId, reportId)

  const selectedEmployee = reportChanges[0]?.employee

  return (
    <>
      <PayCycleEmployeeReportsTable {...props} onClickReport={id => setReportId(id)} />
      <SideBar
        variant="wide"
        sideProps={{ resizable: true }}
        isOpen={!!reportId}
        onClose={() => setReportId(undefined)}
      >
        {isLoadinReportChanges ? (
          <VStack space="s-16">
            <ItemSkeleton />
            <ItemSkeleton />
            <ItemSkeleton />
          </VStack>
        ) : (
          <VStack space="s-20">
            {selectedEmployee ? (
              <Item>
                <Item.Avatar>
                  <Avatar
                    image={getAvatarUrl(selectedEmployee.avatar)}
                    label={getInitials(selectedEmployee.full_name || '')}
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <Text>{selectedEmployee.full_name}</Text>
                  </Item.Title>
                  {!!(selectedEmployee.job_title || selectedEmployee.seniority) && (
                    <Item.Description>
                      <Text color={Token.color.greyTone50}>
                        {chain(
                          selectedEmployee.job_title,
                          selectedEmployee.seniority?.name,
                        )}
                      </Text>
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
            ) : null}
            <DetailsSidebarContent
              groupChanges
              isLoading={isLoadinReportChanges}
              isError={isReportChangesError}
              timelineChanges={reportChanges}
              onClose={() => setReportId(undefined)}
            />
          </VStack>
        )}
      </SideBar>
    </>
  )
}
