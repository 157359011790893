import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'
import { useTable } from '@components/Table/hooks'
import { kpiTemplateRequests } from '@src/api/kpiTemplate'
import { RowInterface } from '@src/interfaces/data'
import {
  kpiTemplateCountColumn,
  kpiTemplateEnforcedColumn,
  kpiTemplateNameColumn,
  kpiTypeColumn,
} from '@src/constants/columns/kpi'
import { updatedAtColumn } from '@src/constants/columns/dates'
import { ownerFullNameColumn } from '@src/constants/columns/employee'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { Plus } from '@revolut/icons'
import { TableNames } from '@src/constants/table'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'

type TemplateType = 'KPI' | 'goal'

const getRows = (
  variant: TemplateType,
  isOnboarding?: boolean,
): RowInterface<KPITemplateInterface> => ({
  linkToForm: d =>
    navigateTo(
      pathToUrl(
        variant === 'KPI'
          ? ROUTES.FORMS.KPI_TEMPLATE.GENERAL
          : isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TEMPLATES.EDIT
          : ROUTES.SETTINGS.GOALS.TEMPLATES.EDIT,
        { id: d.id },
      ),
    ),
  cells: [
    {
      ...kpiTemplateNameColumn,
      width: 400,
    },
    {
      ...kpiTypeColumn,
      width: 200,
    },
    {
      ...ownerFullNameColumn,
      title: 'Owner',
      width: 200,
    },
    {
      ...updatedAtColumn,
      title: 'Updated',
      width: 200,
    },
    {
      ...kpiTemplateEnforcedColumn,
      width: 220,
    },
    {
      ...kpiTemplateCountColumn,
      ...(variant === 'goal' && { title: 'Implemented goals' }),
      width: 220,
    },
  ],
})

const Templates = ({ variant = 'KPI' }: { variant?: TemplateType }) => {
  const table = useTable<KPITemplateInterface>(kpiTemplateRequests)
  const isOnboarding = isOnboardingPath()
  const rows = getRows(variant)

  const newTemplatePath =
    variant === 'KPI'
      ? ROUTES.FORMS.KPI_TEMPLATE.GENERAL
      : isOnboarding
      ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.SETTINGS.TEMPLATES.NEW
      : ROUTES.SETTINGS.GOALS.TEMPLATES.NEW

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <PrimaryAction
            useIcon={Plus}
            onClick={() => {
              navigateTo(pathToUrl(newTemplatePath))
            }}
          >
            Add New Template
          </PrimaryAction>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<KPITemplateInterface>
          name={TableNames.KpiTemplates}
          dataType={`${variant} template`}
          useWindowScroll
          row={rows}
          {...table}
          noDataMessage={`${variant} templates will appear here.`}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Templates
