import { ProbationTemplateForm } from '@src/pages/Forms/ProbationTemplate'
import { Actions } from './common/PageActions'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import React from 'react'
import { ProbationTemplateFormBody } from '@src/pages/Forms/ProbationTemplate/General'

export const ProbationTemplate = connect(() => {
  return (
    <ProbationTemplateForm>
      <ProbationTemplateFormBody />
      <Actions backPath={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.PROBATION} />
    </ProbationTemplateForm>
  )
})
