import React from 'react'

export const employeeSetupGuide = [
  {
    question: 'How the upload process works?',
    answer: (
      <ol>
        <li>
          Prepare Your CSV File: download template, Ensure you have a CSV (Comma-Separated
          Values) file containing your employees' data. Your HR or management software
          might provide an option to export data in this format. Make sure the file
          includes necessary information such as names, contact details, positions, and
          any other relevant fields.
        </li>
        <li>
          Review Data Format: Open the CSV file and review its format. Make sure the
          columns align with the data fields required by the HRM tool. Common fields
          include first name, last name, email, job title, department, etc.
        </li>
        <li>
          Upload the CSV File: Within the 'Employee Data Upload' section, look for the
          option to 'Upload' or 'Import' the CSV file. Click on it and select the prepared
          file from your computer.
        </li>
        <li>
          Data Validation: The tool might perform some initial data validation. It could
          check for duplicates, missing values, or any discrepancies in the data.
        </li>
        <li>
          Preview and Confirm: After column mapping and validation, the tool might show
          you a preview of the imported data. Review this preview carefully to ensure
          everything appears as expected.
        </li>
      </ol>
    ),
  },
]
