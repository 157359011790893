import { useEffect, useState } from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import isValid from 'date-fns/isValid'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import parseISO from 'date-fns/parseISO'
import { getReciewCyclesSilently } from '@src/api/reviewCycles'
import { FilterByInterface } from '@src/interfaces/data'
import { COOKIE } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export interface useKPIWarningInterface {
  showTeam: boolean
  showDepartment: boolean
  showBanner: boolean
  reviewCycleData?: ReviewCyclesInterface
}

const useKPIWarning = (): useKPIWarningInterface => {
  const [reviewCycleData, setReviewCycleData] = useState<ReviewCyclesInterface>()
  const [showTeam, setShowTeam] = useState(false)
  const [showDepartment, setShowDepartment] = useState(false)
  const initialFilters: FilterByInterface[] = [
    {
      columnName: 'offset',
      filters: [
        {
          id: -1,
          name: '-1',
        },
        {
          id: 0,
          name: '0',
        },
      ],
    },
  ]

  useEffect(() => {
    validateKPIWarning()
  }, [])

  const haveSeenBanner = workspaceLocalStorage.getItem(COOKIE.KPI_IMPORTANT_INFO_SEEN)

  const validateKPIWarning = async () => {
    let reviewCycles = null
    try {
      reviewCycles = await getReciewCyclesSilently({
        filters: initialFilters,
      })
    } catch (e) {
      console.warn(e)
    }
    if (reviewCycles?.data?.results?.length) {
      const results = reviewCycles.data.results
      results.forEach(result => {
        const today = new Date()
        const teamFrom = isValid(parseISO(result.team_kpi_period_start_day!))
          ? new Date(result.team_kpi_period_start_day!)
          : false
        const teamTo = isValid(parseISO(result.team_kpi_period_end_day!))
          ? new Date(result.team_kpi_period_end_day!)
          : false
        const departmentFrom = isValid(parseISO(result.department_kpi_period_start_day!))
          ? new Date(result.department_kpi_period_start_day!)
          : false
        const departmentTo = isValid(parseISO(result.department_kpi_period_end_day!))
          ? new Date(result.department_kpi_period_end_day!)
          : false

        if (teamFrom && teamTo && isAfter(today, teamFrom) && isBefore(today, teamTo)) {
          setShowTeam(true)
          if (!reviewCycleData || reviewCycleData.id !== result.id) {
            setReviewCycleData(result)
          }
        }

        if (
          departmentFrom &&
          departmentTo &&
          isAfter(today, departmentFrom) &&
          isBefore(today, departmentTo)
        ) {
          setShowDepartment(true)
          if (!reviewCycleData || reviewCycleData.id !== result.id) {
            setReviewCycleData(result)
          }
        }
      })
    }
  }

  const showBanner = !haveSeenBanner && (showTeam || showDepartment)

  return {
    showTeam,
    showDepartment,
    reviewCycleData,
    showBanner,
  }
}

export default useKPIWarning
