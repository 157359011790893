import {
  EmployeeInterface,
  EmployeeOptionInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { PerformanceScorecardSectionInterface } from '@src/interfaces/settings'
import { IdContractTypes } from '@src/interfaces/contracts'
import { IdAndName } from '@src/interfaces/index'

export enum ReviewCycleStatus {
  planned = 'planned',
  closed = 'closed',
  ongoing = 'ongoing',
}

export enum ReviewCycleCategory {
  Performance = 'performance',
  PIP = 'PIP',
  PIP_V2 = 'pip',
  Probation = 'probation',
}

export enum ReviewCycleStage {
  DepartmentGoals = 'department_goals',
  TeamGoals = 'team_goals',
  Review = 'review',
  Calibration = 'calibration',
  ManagersPublish = 'publishing_results_to_managers',
  EmployeesPublish = 'publishing_results_to_employees',
  Completed = 'results_published_to_employees',
}

export interface ReviewCyclesInterface {
  id: number | string
  name: string
  sub_title?: string
  category: ReviewCycleCategory
  start_date_time: string
  end_date_time: string
  status?: ReviewCycleStatus
  department_owner_calibration_start_day?: string
  head_of_function_calibration_start_day?: string
  head_of_function_and_department_last_calibration_day?: string
  department_kpi_period_start_day?: string | null
  department_kpi_period_end_day?: string | null
  individual_kpi_setting_deadline_day?: string | null
  team_kpi_period_start_day?: string | null
  team_kpi_period_end_day?: string | null
  individual_kpi_period_start_day?: string | null
  individual_kpi_period_end_day?: string | null
  end_cycle_kpi_freeze_date?: string | null
  scorecards_lock_date_time?: string | null
  review_period_start_day?: string | null
  self_and_peer_reviews_last_day?: string | null
  managers_reviews_last_day?: string | null
  reviews_publishing_day?: string | null
  end_cycle_kpi_freeze_day?: string | null
  roadmap_start_date?: string | null
  roadmap_end_date?: string | null
  performance_reviews_selected_cycle?: boolean
  enabled_eligible_updates: boolean
  enabled_scorecards_generations: boolean
  offset: number
  probation_version: number | null
  managers_publishing_day?: string | null
  template_id?: string
  eligible_employees_count?: number
  eligible_groups?: EligibleGroupInterface[]
  scorecard_sections?: ReviewCyclesScorecardSectionInterface[]
  grade_calculation_method?: GradeCalculationMethods
  grade_distribution?: Record<string, number> | null
  delete_allowed?: boolean
  is_goals_calibration_active?: boolean
  goals_calibration_days_left?: number
  is_manual_stage_switch_enabled?: boolean
  current_stage?: ReviewCycleStage | null
  is_test: boolean
  parent_cycle: IdAndName | null
  enable_upwards_reviews: boolean
  enable_peer_reviews: boolean
  enable_self_reviews: boolean
  enable_functional_manager_reviews: boolean
}

export enum GradeCalculationMethods {
  LineManagerSuggestion = 'line_manager_suggestion',
  FunctionalManagerSuggestion = 'functional_manager_suggestion',
  CalculatedGrade = 'calculated_grade',
  PerformanceGradeSuggestion = 'performance_grade_suggestion',
  PerformanceExtraGradeSuggestion = 'performance_extra_grade_suggestion',
}

export interface EligibleGroupInterface {
  id: number
  name: string
  should_create_scorecards: boolean
  cycle?: number
  filters: EligibleGroupFiltersInterface
  preview?: EligibleGroupPreviewInterface
  sign?: 'include' | 'exclude'
}

export interface EligibleGroupFiltersInterface {
  id?: number[]
  team_id?: number[]
  team__department_id?: number[]
  specialisation_id?: number[]
  position_id?: number[]
  position__function_id?: number[]
  status?: IdStatuses[]
  is_in_pip?: boolean
  employee_type?: EmployeeType[]
  contract_type?: IdContractTypes[]
  is_in_probation?: boolean
  joining_date_time?: string
  end_of_probation_date_time?: string
  end_of_pip_date_time?: string
  location_id?: number[]
  entity_id?: number[]
}

export enum EmployeeType {
  internal = 'internal',
  external = 'external',
}

export interface EligibleGroupPreviewInterface {
  count: number
  results: EmployeeInterface[]
}

export interface ReviewCyclesSelectorInterface {
  id: number
  name: string
  offset: string
}

export interface ReviewCyclesPreviewInterface {
  enabled: boolean
  name: string
  template_id: string
  start_date: string
  end_date: string
}

export interface ReviewCyclesScorecardSectionInterface
  extends PerformanceScorecardSectionInterface {
  cycle: number
}

export interface ProbationCycleSettingsInterface {
  id?: number
  start_date_time: string
  end_date_time: string
  hr_manager: EmployeeOptionInterface | null
  performance_checkpoints: CycleSettingsCheckpointInterface[]
}

export interface CycleSettingsCheckpointInterface {
  id: number
  number: number
  checkpoint_date_time: string
  checkpoint_type: 'review' | 'decision'
}

export interface PipCycleSettingsInterface extends ProbationCycleSettingsInterface {
  review_checkpoints_number: number
}

export interface SandboxInvitation {
  id?: number
  employee: EmployeeOptionInterface
  creation_date_time?: string
  permission_group?: IdAndName
  invited_by?: EmployeeOptionInterface
  invitation_link: string
}

export interface ReviewStageStats {
  total_employees: number
  employees_with_reviews: number
  total_completion: number
  self_completion: number
  lm_completion: number
  fm_completion: number
}
