import React from 'react'
import Form from '@src/features/Form/Form'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { growthPlanFormRequests } from '@src/api/growthPlans'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useDraftGrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/CreateGrowthPlan/hooks/useDraftGrowthPlan'
import PageLoading from '@components/PageLoading/PageLoading'

interface Props {
  employee: EmployeeInterface
  children: React.ReactNode
}

export const CreateGrowthPlanForm = ({ employee, children }: Props) => {
  const { data: draftGrowthPlan, isLoading } = useDraftGrowthPlan(employee)

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <Form<GrowthPlanInterface>
      api={growthPlanFormRequests}
      forceParams={{
        new: 'new',
      }}
      initialValues={draftGrowthPlan}
    >
      {children}
    </Form>
  )
}
