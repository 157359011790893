import React, { useMemo } from 'react'
import { ActionBar, Table } from '@revolut/ui-kit'

import { PayrollElementInterface } from '@src/interfaces/payroll'

export const PayElementsTable = ({
  payments = [],
  onEdit,
  onDelete,
  isLoading,
}: {
  payments: PayrollElementInterface[]
  onEdit?: (id: number) => void
  onDelete?: (id: number) => void
  isLoading?: boolean
}) => {
  const columns = useMemo(
    () =>
      [
        {
          Header: 'Name',
          accessor: 'name',
          width: 100,
        },
        {
          Header: 'Category',
          accessor: 'category.name',
          width: 120,
        },
        {
          Header: 'Mandatory',
          accessor: 'is_mandatory',
          width: 70,
          Cell: ({ value }: { value: boolean }) => (
            <Table.Cell>{value ? 'Yes' : 'No'}</Table.Cell>
          ),
        },
        {
          Header: 'Payable',
          accessor: 'is_payable',
          width: 70,
          Cell: ({ value }: { value: boolean }) => (
            <Table.Cell>{value ? 'Yes' : 'No'}</Table.Cell>
          ),
        },
        {
          Header: 'Settlement profile',
          accessor: 'settlement_profile',
          width: 100,
        },
        {
          Header: 'GL internal',
          accessor: 'gl_internal',
          width: 100,
        },
        onEdit && onDelete
          ? {
              Header: 'Actions',
              width: 120,
              accessor: 'id',
              Cell: ({ value: id }: { value: number }) => (
                <Table.ActionsCell>
                  <ActionBar labelMoreButton="More">
                    <ActionBar.Item
                      aria-label="Edit pay element"
                      onClick={() => onEdit(id)}
                    >
                      Edit
                    </ActionBar.Item>
                    <ActionBar.Item
                      aria-label="Delete pay element"
                      color="red"
                      onClick={() => onDelete(id)}
                    >
                      Delete
                    </ActionBar.Item>
                  </ActionBar>
                </Table.ActionsCell>
              ),
            }
          : null,
      ].filter(Boolean),
    [payments],
  )

  return (
    <Table
      loadingState={isLoading ? 'pending' : undefined}
      data={payments}
      columns={columns}
      labelEmptyState="No payroll elements"
    />
  )
}
