import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PayGroup } from './PayGroup'
import { PayCycle } from './PayCycle'

export const Payroll = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_GROUP.ANY}>
        <PayGroup />
      </Route>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_CYCLE.ANY}>
        <PayCycle />
      </Route>
    </Switch>
  )
}
