import React, { useContext } from 'react'
import { ActionBar, MoreBar } from '@revolut/ui-kit'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'
import { ButtonLayoutProps } from '@revolut/ui-kit/types/dist/components/ButtonLayout'
import { ActionButtonProps } from '@revolut/ui-kit/types/dist/components/ActionButton'

interface CellActionButtonProps
  extends Omit<ButtonLayoutProps, 'variant' | 'size'>,
    Pick<ActionButtonProps, 'hasDot' | 'variant'> {}

export const CellActionButton = ({ variant, ...props }: CellActionButtonProps) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  if (density !== 'large') {
    return (
      <ActionBar.Item {...props} color={variant === 'negative' ? 'red' : undefined} />
    )
  }

  return <MoreBar.Action {...props} variant={variant} />
}
