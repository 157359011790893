import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import { GetRequestData, RequestInterface, RequestInterfaceNew } from '../interfaces'
import { LocationInterface } from '../interfaces/locations'
import { DocumentInterface } from '@src/interfaces/documents'
import { saveFileByBlob } from '@src/utils'
import { useFetch } from '@src/utils/reactQuery'

export const locationsRequests: RequestInterface<LocationInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.LOCATIONS, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.LOCATIONS}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.LOCATIONS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.LOCATIONS}/${id}`),
  postItem: async data => api.post(API.LOCATIONS, data),
}

export const getUserCountryByLocation = (locationId: number) =>
  api.get<LocationInterface>(`${API.LOCATIONS}/${locationId}`, {
    params: { fields: 'country' },
  })

export const newLocationRequests: RequestInterfaceNew<LocationInterface> = {
  get: async ({ id }) => api.get(`${API.LOCATIONS}/${id}`),
  submit: async data => {
    const { file, ...rest } = data
    const formData = new FormData()
    if (file) {
      formData.append('file', file, file.name)
    }
    const result = await apiWithoutHandling.post(`${API.LOCATIONS}`, rest)
    return api
      .post(`${API.LOCATIONS}/${result.data.id}/fileForm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment`,
        },
      })
      .catch(e => {
        api.delete(`${API.LOCATIONS}/${result.data.id}`)
        throw e
      })
  },
  update: async (data, { id }) => {
    const { file, ...rest } = data
    if (file) {
      const formData = new FormData()
      if (file) {
        formData.append('file', file, file.name)
      }
      await apiWithoutHandling.patch(`${API.LOCATIONS}/${id}`, rest)
      return api.post(`${API.LOCATIONS}/${id}/fileForm`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': `attachment`,
        },
      })
    }
    return apiWithoutHandling.patch(`${API.LOCATIONS}/${id}`, rest)
  },
  delete: ({ id }) => apiWithoutHandling.delete(`${API.LOCATIONS}/${id}`),
}

export const downloadMapFile = async (id: number) => {
  const response = await api.get(`${API.LOCATIONS}/${id}/file`, {
    responseType: 'arraybuffer',
  })
  const infoResponse = await api.get<DocumentInterface>(`${API.LOCATIONS}/${id}`)
  saveFileByBlob(response.data as BinaryType, infoResponse?.data.file_name)
}

export const changeLocationStatus = (
  id: string | number,
  action: 'archive' | 'unarchive',
) => api.post<LocationInterface>(`${API.LOCATIONS}/${id}/${action}`)

export const useGetLocationsByIds = (ids?: number[]) =>
  useFetch<GetRequestData<LocationInterface>>(ids ? API.LOCATIONS : null, undefined, {
    params: {
      id: ids?.join(','),
    },
  })
