import { useState } from 'react'
import omit from 'lodash/omit'
import { TaskDefinitionInterface } from '@src/interfaces/employeeOnboardingTemplate'

type FieldsKeys = keyof TaskDefinitionInterface
type ErrorsByFieldsKeys = Partial<Record<FieldsKeys, string>>
type UseValidationReturnType = {
  data: ErrorsByFieldsKeys
  resetFields: (errorFields: FieldsKeys | FieldsKeys[]) => void
  setErrors: (err: ErrorsByFieldsKeys) => void
  validate: () => ErrorsByFieldsKeys
}

const validateForMissing = (values: Partial<TaskDefinitionInterface>) => {
  const result: ErrorsByFieldsKeys = {}

  if (!values.name) {
    result.name = 'Task title is required'
  }
  if (!values.default_assignee_type) {
    result.default_assignee_type = 'Assignee type is required'
  }
  if (values.default_assignee_type?.id === 'employee' && !values.default_assignee) {
    result.default_assignee = 'Assignee is required'
  }
  if (values.default_assignee_type?.id === 'group' && !values.default_assignee_group) {
    result.default_assignee_group = 'Group is required'
  }
  if (
    values.default_assignee_type?.id === 'relationship' &&
    !values.default_assignee_relationship
  ) {
    result.default_assignee_relationship = 'Relationship is required'
  }

  return result
}

export const useValidation = (
  values: Partial<TaskDefinitionInterface>,
): UseValidationReturnType => {
  const [errors, setErrors] = useState<ErrorsByFieldsKeys>({})

  return {
    data: errors,
    resetFields: errorFields => {
      const newErrors = omit(errors, errorFields)
      setErrors(newErrors)
    },
    setErrors,
    validate: () => validateForMissing(values),
  }
}
