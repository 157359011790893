import React, { useState } from 'react'
import { Text, createChain, TextButton, TextProps } from '@revolut/ui-kit'

interface Props extends TextProps {
  items: React.ReactNode[]
  visibleCount?: number
  expandable?: boolean
  hideSuffix?: boolean
}

const TextWithMoreCount = ({
  items,
  visibleCount = 1,
  expandable,
  hideSuffix,
  ...props
}: Props) => {
  const [expanded, setExpanded] = useState(false)
  const visibleItems: React.ReactNode[] = expanded ? items : items.slice(0, visibleCount)
  const remainingCount = items.length - visibleCount

  const label = `+${remainingCount}${!hideSuffix ? ' more' : ''}`

  if (remainingCount > 0) {
    if (expandable) {
      if (!expanded) {
        visibleItems.push(
          <TextButton
            onClick={e => {
              e.stopPropagation()
              setExpanded(true)
            }}
          >
            {label}
          </TextButton>,
        )
      }
    } else {
      visibleItems.push(label)
    }
  }

  return <Text {...props}>{createChain(', ')(visibleItems)}</Text>
}

export default TextWithMoreCount
