import React, { PropsWithChildren, ReactNode } from 'react'
import { Avatar, Group, HStack, IconName, Item, Tag, Text, Token } from '@revolut/ui-kit'

interface RequiredItemProps {
  title: ReactNode
  description: ReactNode
  icon?: IconName
  highlightRequired?: boolean
}
export const RequiredItem = ({
  title,
  description,
  icon,
  highlightRequired = true,
}: RequiredItemProps) => {
  return (
    <Item variant="compact" aria-pressed={highlightRequired}>
      {icon ? (
        <Item.Avatar>
          <Avatar useIcon={icon} size={40} color={Token.color.blue} />
        </Item.Avatar>
      ) : null}
      <Item.Content>
        <Item.Title>
          <HStack space="s-8">
            <Text>{title}</Text>
            <Tag
              variant="outlined"
              useIcon="ChangePasscode"
              color={Token.color.greyTone50}
            >
              Required
            </Tag>
          </HStack>
        </Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
    </Item>
  )
}

type GroupWithRequiredProps = PropsWithChildren<{
  requiredItems: RequiredItemProps[]
  highlightRequired?: boolean
}>
export const GroupWithRequired = ({
  children,
  requiredItems,
  highlightRequired,
}: GroupWithRequiredProps) => {
  return (
    <Group>
      {requiredItems.map((item, idx) => (
        <RequiredItem key={idx} {...item} highlightRequired={highlightRequired} />
      ))}
      {children}
    </Group>
  )
}
