import React from 'react'
import { Button, Layout, Text, VStack, Image, Token, Flex } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const Complete = () => {
  return (
    <Layout>
      <Layout.Main>
        <Flex
          gap="s-72"
          flexDirection="column"
          minHeight="calc(100vh - 100px)"
          alignItems="center"
          justifyContent="center"
        >
          <VStack space="s-32" align="center">
            <Text variant="h1" textAlign="center">
              Congratulations!
              <br />
              You’re ready to roll.
            </Text>

            <Text textAlign="center" variant="caption" color={Token.color.greyTone50}>
              Welcome on board! We have sent an email with instructions to your employees.
              Your account is set up and ready for business
            </Text>
          </VStack>

          <Image
            src="https://assets.revolut.com/assets/3d-images-v2/3D266.png"
            size={200}
          />

          <Button
            onClick={() => {
              navigateReplace(ROUTES.MAIN)
            }}
            elevated
          >
            Go to Dashboard
          </Button>
        </Flex>
      </Layout.Main>
    </Layout>
  )
}
