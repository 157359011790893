import type { ActionItem } from '@src/interfaces/meetings'
import { isNumber } from 'lodash'
import { useRef, useState } from 'react'

export const useActionPointContent = (actionPoints: ActionItem[]) => {
  const [isEdited, setIsEdited] = useState(false)
  const textAreaRefs = useRef<{ [key: number]: HTMLTextAreaElement | null }>({})
  const [actionItems, setActionItems] = useState<(ActionItem & { id: number })[]>(
    actionPoints.length
      ? actionPoints.map((item, index) => ({
          completed: item.completed,
          id: index,
          value: item.value,
        }))
      : [{ id: 0, value: '', completed: false }],
  )

  const updateContent = (id: number, newContent: string) => {
    setIsEdited(true)
    if (!newContent) {
      const newActionItems = actionItems.filter(item => item.id !== id)
      if (newActionItems.length === 0) {
        setActionItems([{ id: 0, value: '', completed: false }])
        textAreaRefs.current[0]?.focus()
        return
      }
      setActionItems(newActionItems)

      const previousItemId = actionItems.findIndex(item => item.id === id) - 1
      const lastItemId = newActionItems.at(-1)?.id
      const focusRefId = previousItemId >= 0 ? previousItemId : lastItemId
      if (isNumber(focusRefId)) {
        const textArea = textAreaRefs.current[focusRefId]
        textArea?.focus()
        // Move cursor to the end of the text
        if (textArea) {
          const textLength = textArea.value.length
          textArea.setSelectionRange(textLength, textLength)
        }
      }
    } else {
      setActionItems(prev => {
        return prev.map(item => {
          if (item.id === id) {
            item.value = newContent
          }
          return item
        })
      })
    }
  }

  const toggleComplete = (id: number) => {
    setIsEdited(true)
    setActionItems(prev =>
      prev.map(item => {
        if (item.id === id) {
          item.completed = !item.completed
        }
        return item
      }),
    )
  }

  const addNew = () => {
    const newId = actionItems.length
    setActionItems(prev => [...prev, { id: newId, value: '', completed: false }])
    setTimeout(() => {
      textAreaRefs.current[newId]?.focus()
    }, 0)
  }

  return { addNew, updateContent, isEdited, actionItems, toggleComplete, textAreaRefs }
}
