import React from 'react'
import { Box, Item, Tag, TagBar, Token } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

interface Props {
  stage: HiringProcessInterface
  onClick: VoidFunction
}

export const HiringStage = ({ stage, onClick }: Props) => {
  return (
    <Item use="button" variant="disclosure" onClick={onClick}>
      <Item.Content>
        <Item.Title>{stage.title}</Item.Title>
        <Item.Description>Description</Item.Description>
        <Box mt="s-16">
          <TagBar>
            <Tag variant="status" useIcon="LockClosed">
              Required
            </Tag>
            <Tag color={Token.color.warning} useIcon="Flag" variant="faded">
              Modified
            </Tag>
          </TagBar>
        </Box>
      </Item.Content>
    </Item>
  )
}
