import React, { useMemo } from 'react'
import { connect } from 'lape'
import { Flex, InputGroup, MoreBar } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { bannersRequests } from '@src/api/banners'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useGetSelectors } from '@src/api/selectors'
import { IdAndName } from '@src/interfaces'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BannerInterface, BannerSeverity } from '@src/interfaces/banners'
import { pathToUrl } from '@src/utils/router'
import { Form as FormIcon } from '@revolut/icons'

const Banner = () => {
  const { values } = useLapeContext<BannerInterface>()

  const backUrl = ROUTES.PLATFORM_STATUS

  const { data: severities } = useGetSelectors<IdAndName<string>>(
    selectorKeys.banner_severities,
  )

  const severityOptions = useMemo(
    () =>
      severities?.map(item => ({
        label: item.name,
        value: item,
      })) || [],
    [severities],
  )

  const onPrefill = () => {
    values.title = 'Platform is being updated'
    values.message =
      'Please wait until deployment is completed to avoid any unexpected errors'
    values.severity = BannerSeverity.warning
  }

  return (
    <PageWrapper>
      <PageHeader title="Create banner" backUrl={backUrl} />

      <PageBody>
        <Flex mb="s-32">
          <MoreBar>
            <MoreBar.Action useIcon={FormIcon} onClick={onPrefill}>
              Prefill
            </MoreBar.Action>
          </MoreBar>
        </Flex>
        <InputGroup>
          <LapeNewInput name="title" label="Title" required />
          <LapeRadioSelectInput
            name="severity"
            label="Severity"
            options={severityOptions}
            value={severityOptions.find(unit => unit.value.id === values.severity)?.value}
            onChange={option => {
              values.severity = option?.id as BannerSeverity
            }}
            searchable={false}
            required
          />
          <LapeNewTextArea name="message" label="Message" required />
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Banner created"
          useValidator
          afterSubmitUrl={pathToUrl(backUrl)}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => {
  return (
    <Form api={bannersRequests}>
      <Banner />
    </Form>
  )
})
