import React from 'react'
import { Dropdown, MoreBar } from '@revolut/ui-kit'

import { canViewSetInactive } from '../../common'
import { StatusChangeButtonProps } from '../../types'
import { UseEmployeeInactivityDataReturnType } from './hooks'
import { SetInactivePopup } from './Popup'

type SetInactiveActionProps = {
  employeeInactivity: UseEmployeeInactivityDataReturnType
  isDropdownItem?: boolean
} & Pick<StatusChangeButtonProps, 'data' | 'statusTransitions'>
export const SetInactiveAction = ({
  isDropdownItem,
  statusTransitions,
  employeeInactivity,
}: SetInactiveActionProps) => {
  const canView = canViewSetInactive(employeeInactivity, statusTransitions)

  if (!canView) {
    return null
  }
  const onClick = () => employeeInactivity.setIsPopupOpened(true)

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" disabled={employeeInactivity.pending} onClick={onClick}>
        Set as ‘Inactive’
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action pending={employeeInactivity.pending} onClick={onClick}>
      Set as ‘Inactive’
    </MoreBar.Action>
  )
}

export const SetInactive = ({
  data,
  statusTransitions,
  employeeInactivity,
}: Omit<SetInactiveActionProps, 'isDropdownItem'>) => {
  return (
    <>
      <SetInactiveAction
        data={data}
        statusTransitions={statusTransitions}
        employeeInactivity={employeeInactivity}
      />
      <SetInactivePopup
        employeeName={data.full_name}
        statusTransitions={statusTransitions}
        employeeInactivity={employeeInactivity}
      />
    </>
  )
}
