import React, { useState } from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  StatusPopup,
  Text,
  Token,
  Tooltip,
  useStatusPopup,
  useTooltip,
  Color,
  IconButton,
  Icon,
} from '@revolut/ui-kit'
import { get } from 'lodash'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import { selectorKeys } from '../api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { deleteDocumentUploadSessionItem } from '@src/api/importData'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import { FileInterface } from '@src/interfaces/files'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { documentCategoriesRequests } from '@src/api/documentsCategories'

type ImportDocumentSelectInputField = 'employee' | 'category'

export type ImportDocumentSelectInputOnChange = (
  row: ImportDocumentsItemInterface,
  option: IdAndName | null,
  field: ImportDocumentSelectInputField,
) => void

type ImportDocumentsSelectInputColumn = (
  onChange: ImportDocumentSelectInputOnChange,
  options: IdAndName[],
) => ColumnInterface<ImportDocumentsItemInterface>

interface ImportDocumentsSelectInputProps {
  onChange: ImportDocumentSelectInputOnChange
  options: IdAndName[]
  field: ImportDocumentSelectInputField
  data: ImportDocumentsItemInterface
  allowCreateNewOption?: boolean
  onCreateNewOption?: (optionName: string) => Promise<{ id: number; name: string }>
}

const ImportDocumentsSelectInput = ({
  data,
  onChange,
  options,
  field,
  allowCreateNewOption,
  onCreateNewOption,
}: ImportDocumentsSelectInputProps) => {
  return (
    <RadioSelectInput
      onChange={option => onChange(data, option, field)}
      options={options.map(value => ({ label: value.name, value }))}
      allowCreateNewOption={allowCreateNewOption}
      onCreateNewOption={onCreateNewOption}
      renderInput={(open, setOpen, ref) => (
        <Flex
          onClick={() => setOpen(!open)}
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          use="button"
          type="button"
          color="inherit"
          ref={ref}
        >
          <Text>{data[field]?.name || `Select ${field}`}</Text>
          <Icon
            name={open ? 'ChevronUp' : 'ChevronDown'}
            color={Token.color.greyTone50}
            size={16}
          />
        </Flex>
      )}
    />
  )
}

interface CellWithErrorProps {
  data: ImportDocumentsItemInterface
  field: 'name' | 'category' | 'employee'
}

const CellWithError: React.FC<CellWithErrorProps> = ({ data, field, children }) => {
  const tooltip = useTooltip()

  const errors = get(data.errors, field)?.join('\n')

  return (
    <Flex alignItems="center" gap="s-8" {...tooltip.getAnchorProps()}>
      <Box flex="1" color={errors ? Token.color.danger : undefined}>
        {children}
      </Box>
      {errors ? (
        <>
          <Box width={18}>
            <Icon
              name="ExclamationTriangle"
              color={Token.color.danger}
              aria-label={`${field} error`}
              size={18}
            />
          </Box>
          <Tooltip {...tooltip.getTargetProps()}>{errors}</Tooltip>
        </>
      ) : null}
    </Flex>
  )
}

interface NameCellProps {
  data: ImportDocumentsItemInterface
  onChange: (rowId: number, value: string) => void
}

const NameCell = ({ data, onChange }: NameCellProps) => {
  const [value, setValue] = useState(data.name || '')

  return (
    <CellWithError data={data} field="name">
      <EditableCell
        type={TableCellInputType.text}
        value={value}
        onChange={val => setValue(val as string)}
        onBlur={val => onChange(data.id, val as string)}
      />
    </CellWithError>
  )
}

export const importDocumentsDocumentNameColumn = (
  onChange: (rowId: number, value: string) => void,
  nameColumnOptions: IdAndName[],
): ColumnInterface<ImportDocumentsItemInterface> => ({
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: 'id',
  selectorsKey: () => Promise.resolve({ options: nameColumnOptions }),
  title: 'Document name',
  insert: ({ data }) => <NameCell data={data} onChange={onChange} />,
})

export const importDocumentsFormatColumn: ColumnInterface<ImportDocumentsItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'file.extension',
    dataPoint: 'file.extension',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Format',
    insert: ({ data }) => <>{data.file.extension || '-'}</>,
  }

export const importDocumentsCategoryColumn: ImportDocumentsSelectInputColumn = (
  onChange,
  options,
): ColumnInterface<ImportDocumentsItemInterface> => ({
  type: CellTypes.insert,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Category',
  insert: ({ data }) => (
    <CellWithError data={data} field="category">
      <ImportDocumentsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="category"
        allowCreateNewOption
        onCreateNewOption={async newCategoryName => {
          const res = await documentCategoriesRequests.submit(
            { name: newCategoryName },
            {},
          )
          return { id: res.data.id, name: res.data.name }
        }}
      />
    </CellWithError>
  ),
})

export const importDocumentsEmployeeColumn: ImportDocumentsSelectInputColumn = (
  onChange,
  options,
): ColumnInterface<ImportDocumentsItemInterface> => ({
  type: CellTypes.insert,
  idPoint: 'employee',
  dataPoint: 'employee',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Assignee',
  insert: ({ data }) => (
    <CellWithError data={data} field="employee">
      <ImportDocumentsSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="employee"
      />
    </CellWithError>
  ),
})

interface ImportDocumentsActionsProps {
  data: ImportDocumentsItemInterface
  onDeleteSuccess: (rowId: number) => void
  onPreviewSidebarOpen: (file: FileInterface) => void
  sessionId: string
  useIcons?: boolean
}

const ImportDocumentsActions = ({
  data,
  onDeleteSuccess,
  onPreviewSidebarOpen,
  sessionId,
  useIcons,
}: ImportDocumentsActionsProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const statusPopup = useStatusPopup()

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await deleteDocumentUploadSessionItem(sessionId, data.id)
      setConfirmationOpen(false)
      setDeletePending(false)

      onDeleteSuccess(data.id)
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to remove document</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={() => statusPopup.hide()} elevated>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
      setDeletePending(false)
    }
  }

  return (
    <>
      <HStack space={useIcons ? 's-16' : 's-8'}>
        {useIcons ? (
          <IconButton
            size={15}
            color={Token.color.greyTone50}
            useIcon="EyeShow"
            onClick={() => onPreviewSidebarOpen(data.file)}
          />
        ) : (
          <TableActionButton onClick={() => onPreviewSidebarOpen(data.file)}>
            Preview
          </TableActionButton>
        )}
        {useIcons ? (
          <IconButton
            size={15}
            color={Token.color.greyTone50}
            useIcon="Delete"
            onClick={e => {
              e.stopPropagation()
              setConfirmationOpen(true)
            }}
          />
        ) : (
          <TableActionButton
            onClick={e => {
              e.stopPropagation()
              setConfirmationOpen(true)
            }}
            color={Color.RED}
          >
            Delete
          </TableActionButton>
        )}
      </HStack>

      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="Are you sure you want to delete this file?"
        body=""
      />
    </>
  )
}

export const importDocumentsActionColumn = (
  onDeleteSuccess: (rowId: number) => void,
  onPreviewSidebarOpen: (file: FileInterface) => void,
  sessionId: string,
  useIcons?: boolean,
): ColumnInterface<ImportDocumentsItemInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => (
    <ImportDocumentsActions
      data={data}
      onDeleteSuccess={onDeleteSuccess}
      onPreviewSidebarOpen={onPreviewSidebarOpen}
      sessionId={sessionId}
      useIcons={useIcons}
    />
  ),
})
