import React from 'react'
import { Popup, Header, Button, Text } from '@revolut/ui-kit'

type Props = {
  isOpen: boolean
  isPending: boolean
  onSubmit: () => void
  onCancel: () => void
}

export const GenerateScorecardsPopup = ({
  onSubmit,
  onCancel,
  isOpen,
  isPending,
}: Props) => {
  return (
    <Popup open={isOpen} onClose={onCancel} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Text variant="h5">
          Are you sure you want to generate performance scorecards for selected cycle?
        </Text>
      </Header>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button elevated onClick={onSubmit} pending={isPending}>
          Proceed
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
