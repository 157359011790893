import React from 'react'
import { usePublicGlobalSettings } from '@src/api/settings'

const ShowIfCommercial: React.FC = ({ children }) => {
  const {
    settings: { commercial_product_disabled },
  } = usePublicGlobalSettings()

  if (commercial_product_disabled === undefined || commercial_product_disabled) {
    return null
  }

  return <>{children}</>
}

export default ShowIfCommercial
