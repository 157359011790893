import React, { useMemo, useState } from 'react'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import {
  CVScreeningSessionInterface,
  getCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'
import { FilterByInterface } from '@src/interfaces/data'
import { InterviewRoundState } from '@src/interfaces/interviewTool'
import { StatFilter, StatFilters } from '@components/StatFilters/StatFilters'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { ErrorWidget } from '@revolut/ui-kit'
import SearchIcon from '@src/assets/3D/Search@2x.png'
import { IdAndName } from '@src/interfaces'
import { useLocation } from 'react-router-dom'

type StatsKeyType = keyof CVScreeningSessionInterface | 'total'

export const CvScreeningSummary = () => {
  const location = useLocation()
  const session = getCVScreeningSession()
  const [filterById, setFilterById] = useState(
    session.screenedCandidateIds?.map(id => ({
      id,
      name: String(id),
    })) || [],
  )

  const filterBy = useMemo<FilterByInterface[]>(
    () => [
      {
        columnName: 'id',
        filters: filterById,
        nonResettable: true,
        disableQueryParam: true,
      },
      {
        filters: [
          { id: InterviewRoundState.active, name: InterviewRoundState.active },
          { id: InterviewRoundState.archived, name: InterviewRoundState.archived },
          { id: InterviewRoundState.hired, name: InterviewRoundState.hired },
        ],
        columnName: 'active_interview_round__state',
        nonResettable: true,
        disableQueryParam: true,
      },
    ],
    [filterById],
  )

  const stats = useMemo<StatFilter<keyof CVScreeningSessionInterface | 'total'>[]>(
    () => [
      {
        id: 'total',
        ariaLabel: 'Total',
        title: 'Total',
        value: session.screenedCandidateIds?.length || 0,
      },
      {
        id: 'archivedCandidateIds',
        ariaLabel: 'Archived',
        title: 'Archived',
        value: session.archivedCandidateIds?.length || 0,
      },
      {
        id: 'skippedCandidateIds',
        ariaLabel: 'Skipped',
        title: 'Skipped',
        value: session.skippedCandidateIds?.length || 0,
      },
      {
        id: 'movedToNextStageCandidateIds',
        ariaLabel: 'Moved to next stage',
        title: 'Moved to next stage',
        value: session.movedToNextStageCandidateIds?.length || 0,
      },
      {
        id: 'scheduledCandidateIds',
        ariaLabel: 'Interview scheduled',
        title: 'Interview scheduled',
        value: session.scheduledCandidateIds?.length || 0,
      },
      {
        id: 'onlineTestSentCandidateIds',
        ariaLabel: 'Online test sent',
        title: 'Online test sent',
        value: session.onlineTestSentCandidateIds?.length || 0,
      },
    ],
    [session],
  )

  return (
    <>
      <PageHeader
        title="Summary"
        subtitle="Review candidates and adjust if necessary"
        routerAction={PageHeaderRouterAction.navigate}
        backUrl={`${ROUTES.RECRUITMENT.CANDIDATES}${location.search}`}
      />
      {session.screenedCandidateIds?.length ? (
        <CommonCandidatesTable type="cvScreeningSummary" filterBy={filterBy} compact>
          <StatFilters<StatsKeyType>
            filters={stats}
            onClick={key => {
              if (key === 'total') {
                setFilterById(
                  session.screenedCandidateIds?.map(id => ({
                    id,
                    name: String(id),
                  })) || [],
                )
                return
              }

              const val = session[key]
              if (Array.isArray(val)) {
                // hack to get nothing from the BE
                let filters: IdAndName[] = [{ name: '0', id: 0 }]

                if (val.length) {
                  if (
                    val?.[0] &&
                    typeof val[0] !== 'string' &&
                    typeof val[0] !== 'number'
                  ) {
                    filters =
                      (val as IdAndName[]).map(item => ({
                        id: item.id,
                        name: String(item.id),
                      })) || []
                  } else {
                    filters =
                      (val as number[]).map(id => ({
                        id,
                        name: String(id),
                      })) || []
                  }
                }

                setFilterById(filters)
              }
            }}
          />
        </CommonCandidatesTable>
      ) : (
        <ErrorWidget>
          <ErrorWidget.Image src={SearchIcon} />
          <ErrorWidget.Title>No screened candidates found</ErrorWidget.Title>
        </ErrorWidget>
      )}
    </>
  )
}
