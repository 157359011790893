import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, InputGroup, Banner, Link, Text, ActionButton } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { isAfter } from 'date-fns'

import { EmployeeInterface, TerminationInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import EmployeeDocSection from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { cancelTermination, terminationRequests } from '@src/api/employees'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewTimeInput from '@components/Inputs/LapeFields/LapeNewTimeInput'
import { getDate, getTimeFromString } from '@src/utils/timezones'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Form from '@src/features/Form/Form'
import { useNonFieldValidationErrors } from './hooks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export interface TerminationProps {
  data: EmployeeInterface
  refreshData: () => void
}

const Termination = ({ data, refreshData }: TerminationProps) => {
  const [cancelLoading, setCancelLoading] = useState(false)
  const { values } = useLapeContext<TerminationInterface>()
  const params = useParams()

  const isInternal = data.employee_type === 'internal'

  useEffect(() => {
    if (data?.location?.timezone) {
      values.termination_date_timezone = data?.location?.timezone
    }
  }, [data])

  let subtitle = 'Terminate Employee by filling the form'

  if (values.termination_date_time) {
    if (isAfter(new Date(values.termination_date_time), new Date())) {
      subtitle = 'Employee is scheduled for termination'
    } else {
      subtitle = 'Employee was terminated'
    }
  }

  const cancel = () => {
    setCancelLoading(true)
    cancelTermination(data.id, 'v1')
      .then(() => {
        refreshData()
        pushNotification({
          value: 'Termination deleted',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        navigateReplace(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id }))
      })
      .finally(() => {
        setCancelLoading(false)
      })
  }

  const onAfterSubmit = () => {
    refreshData()
    navigateReplace(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params))
  }
  const errorHandler = useNonFieldValidationErrors(data)

  return (
    <>
      <PageBody>
        <AutoStepper pb="s-16">
          <HideIfCommercial>
            <Banner mt="s-8">
              <Banner.Avatar>
                <ExclamationTriangle size={56} color="blue" />
              </Banner.Avatar>
              <Banner.Content>
                <Banner.Title>
                  All systems accesses are revoked once a termination is recorded in
                  Revoluters
                </Banner.Title>
                <Banner.Description>
                  If the employee needs to retain some accesses whilst being on gardening
                  leave, please{' '}
                  <Link
                    href="https://revolut.atlassian.net/servicedesk/customer/portal/120/group/378/create/16609"
                    target="_blank"
                  >
                    submit an exception request
                  </Link>{' '}
                  to InfoSec{' '}
                  <Text fontWeight="bold">before recording the termination</Text>
                </Banner.Description>
              </Banner.Content>
            </Banner>
          </HideIfCommercial>

          {!!data.has_termination && (
            <ActionButton onClick={cancel} mt="s-12" pending={cancelLoading}>
              Delete termination
            </ActionButton>
          )}

          <Box mb="s-24">
            <NewStepperTitle title="Termination" subtitle={subtitle} />
            <InputGroup>
              <LapeDatePickerInput
                onChange={value => {
                  if (!value) {
                    return
                  }
                  const time = values.termination_date_time
                    ? getTimeFromString(values.termination_date_time)
                    : '18:00'
                  values.termination_date_time = `${getDate(value)}T${time}`
                }}
                clearable={false}
                name="termination_date_time"
                label="Last day in office"
                displayInUtc={false}
                required
                message="The day employee loses access to office & all corresponding platform access."
              />
              <LapeNewTimeInput name="termination_date_time" label="Time" required />
              <LapeRadioSelectInput
                selector={selectorKeys.timezones}
                label="Timezone"
                name="termination_date_timezone"
              />
              <LapeDatePickerInput
                name="last_day_of_contract"
                label="Last day of contract"
                required
                message="Day on which employee’s employment contract ends."
              />
              <LapeRadioSelectInput
                name="termination_type"
                label="Termination type"
                selector={selectorKeys.termination_type}
              />
              <LapeRadioSelectInput
                name="termination_reason"
                label="Termination reason"
                selector={selectorKeys.termination_reason}
              />
            </InputGroup>

            {isInternal && (
              <>
                <NewStepperTitle title="Pay in lieu of notice (PILON)" />
                <InputGroup>
                  <LapeNewInput name="pilon_time_units_number" label="Number of Units" />
                  <LapeRadioSelectInput
                    name="pilon_time_unit"
                    label="Time Unit"
                    selector={selectorKeys.termination_payment_time_units}
                    optional
                  />
                  <LapeRadioSelectInput
                    name="pilon_salary_type"
                    label="Salary Type"
                    selector={selectorKeys.termination_salary_types}
                    optional
                  />
                </InputGroup>

                <NewStepperTitle title="Lump sum / Severance pay" />
                <InputGroup>
                  <LapeNewInput
                    name="lump_sum_time_units_number"
                    label="Number of Units"
                  />
                  <LapeRadioSelectInput
                    name="lump_sum_time_unit"
                    label="Time Unit"
                    selector={selectorKeys.termination_payment_time_units}
                    optional
                  />
                  <LapeRadioSelectInput
                    name="lump_sum_salary_type"
                    label="Salary Type"
                    selector={selectorKeys.termination_salary_types}
                    optional
                  />
                  <LapeRadioSelectInput
                    name="lump_sum_payment_type"
                    label="Payment type"
                    selector={selectorKeys.termination_lump_payment_types}
                    optional
                  />
                </InputGroup>

                <NewStepperTitle title="Additional payments" />
                <InputGroup>
                  <LapeNewInput
                    name="additional_payment_amount"
                    label="Introduce numeric value"
                  />
                  <LapeRadioSelectInput
                    name="additional_payment_currency"
                    label="Currency"
                    selector={selectorKeys.currencies}
                    optional
                  />
                  <LapeRadioSelectInput
                    name="additional_payment_type"
                    label="Payment Type"
                    selector={selectorKeys.termination_additional_payment_types}
                    placement="top"
                  />
                </InputGroup>

                <NewStepperTitle title="Documents" />
                <InputGroup>
                  <EmployeeDocSection
                    initialValues={{
                      category: {
                        id: 14,
                        name: 'Termination Documentation - Mutual Separation Agreement',
                      },
                      confidentiality: { id: 'hr_manager', name: 'HR Manager' },
                    }}
                    employee={data}
                  />
                </InputGroup>
              </>
            )}
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Termination started"
          onAfterSubmit={onAfterSubmit}
          errorHandler={errorHandler}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default (props: TerminationProps) => (
  <Form api={terminationRequests}>
    <Termination {...props} />
  </Form>
)
