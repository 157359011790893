import React from 'react'

import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@src/components/TableV2/Cells/StatusCell'
import {
  BenefitEnrolmentTableInterface,
  BenefitsTableInterface,
} from '@src/interfaces/benefits'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { getBenefitStatusVariant } from './rewards'

export const benefitsNameColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.benefits_template_names,
  title: 'Name',
}

export const benefitsCategoryColumn: ColumnInterface<
  BenefitsTableInterface | BenefitEnrolmentTableInterface
> = {
  type: CellTypes.text,
  idPoint: 'category.id',
  dataPoint: 'category.name',
  sortKey: 'category',
  filterKey: 'category',
  selectorsKey: selectorKeys.benefits_template_categories,
  title: 'Category',
}

export const benefitsGroupsColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: 'eligibility_groups',
  filterKey: 'eligibility_groups',
  selectorsKey: selectorKeys.dynamic_groups,
  title: 'Groups',
  insert: ({ data }) => data.eligibility_groups.map(group => group.name).join(', '),
}

export const benefitsDescriptionColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.benefits_template_categories,
  title: 'Description',
}

export const benefitsCreatedOnColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const benefitsCreatedByColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.insert,
  idPoint: 'created_by',
  dataPoint: 'created_by',
  sortKey: 'created_by__full_name',
  filterKey: 'created_by_id',
  selectorsKey: selectorKeys.employee,
  title: 'Created by',
  insert: ({ data }) => <Table.EmployeeCell employee={data.created_by} />,
}

export const benefitsStatusColumn: ColumnInterface<BenefitsTableInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.benefits_template_statuses,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getDefaultStatusVariant(data.status.id)}
    />
  ),
}

export const benefitEnrolmentNameColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'benefit_name',
    dataPoint: 'benefit_name',
    sortKey: 'benefit_name',
    filterKey: 'template_id',
    selectorsKey: selectorKeys.benefits_template_names,
    title: 'Name',
  }

export const benefitEnrolmentPackageColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'package',
    dataPoint: 'package',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Package',
  }

export const benefitEnrolmentStatusColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'enrolment_status.id',
    dataPoint: 'enrolment_status.name',
    sortKey: 'status',
    filterKey: 'enrolment_status',
    selectorsKey: selectorKeys.benefits_employee_enrolment_statuses,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.enrolment_status.name}
        variant={getBenefitStatusVariant(data.enrolment_status.id)}
      />
    ),
  }

export const benefitEnrolmentEnrolmentMethodColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'enrolment_method.id',
    dataPoint: 'enrolment_method.name',
    sortKey: 'enrolment_method',
    filterKey: 'enrolment_method',
    selectorsKey: selectorKeys.benefits_package_enrolment_methods,
    title: 'Enrolment method',
  }

export const benefitEnrolmentChangedOnColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.dateTime,
    idPoint: 'changed_on',
    dataPoint: 'changed_on',
    sortKey: 'changed_on',
    filterKey: 'changed_on',
    selectorsKey: selectorKeys.none,
    title: 'Changed on',
  }

export const benefitEnrolmentEmployeeColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: 'employee__full_name',
    filterKey: 'employee_id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
  }

export const benefitEnrolmentManagerColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'rewards_manager',
    dataPoint: 'rewards_manager',
    sortKey: 'rewards_manager__full_name',
    filterKey: 'rewards_manager_id',
    selectorsKey: selectorKeys.employee,
    title: 'Rewards Manager',
    insert: ({ data }) => <Table.EmployeeCell employee={data.rewards_manager} />,
  }

export const benefitEnrolmentLocationColumn: ColumnInterface<BenefitEnrolmentTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'location',
    dataPoint: 'location',
    sortKey: 'location',
    filterKey: 'location_id',
    selectorsKey: selectorKeys.location,
    title: 'Location',
  }
