import React, { useContext, useEffect, useMemo, useState } from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { Button, chain, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { archiveInterviews, bulkArchiveCandidates } from '@src/api/recruitment/interviews'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  SendCandidateEmailInterface,
  SendCandidateEmailPayloadInterface,
} from '@src/interfaces/hiringProccess'
import { LOCAL_STORAGE } from '@src/constants/api'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import {
  getStringMessageFromError,
  pushNotification,
} from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import BulkStatusPopup from '@components/BulkStatusPopup/BulkStatusPopup'
import { clearCVScreeningSession } from '@src/pages/Forms/CVScreening/utils'
import * as Sentry from '@sentry/react'
import { getPrefilledPlaceholdersEmail } from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { useQueryClient } from 'react-query'
import { FileInterface } from '@src/interfaces/files'

export interface ArchivingContextInterface {
  reason: OptionInterface | undefined | null
  setReason: React.Dispatch<React.SetStateAction<OptionInterface | undefined | null>>
  comments: string
  setComments: React.Dispatch<React.SetStateAction<string>>
  archiveAll: boolean
  setArchiveAll: React.Dispatch<React.SetStateAction<boolean>>
  roundId?: number
  setRoundId: React.Dispatch<React.SetStateAction<number | undefined>>
  setSendEmail: (checked: boolean) => void
  sendEmail: boolean
  selectedRounds: InterviewRoundInterface[]
  setSelectedRounds: React.Dispatch<React.SetStateAction<InterviewRoundInterface[]>>
}

const ArchivingContext = React.createContext<ArchivingContextInterface | null>(null)

interface ArchivingProviderProps {
  children: React.ReactNode
  round?: InterviewRoundInterface
}

export const ArchivingProvider = ({ children, round }: ArchivingProviderProps) => {
  const [reason, setReason] = useState<OptionInterface | undefined | null>(
    round?.archived_reason,
  )
  const [sendEmail, setSendEmail] = useState<boolean>(false)
  const [comments, setComments] = useState<string>(round?.archived_comment || '')
  const [archiveAll, setArchiveAll] = useState<boolean>(false)
  const [roundId, setRoundId] = useState<number | undefined>(round?.id)
  const [selectedRounds, setSelectedRounds] = useState<InterviewRoundInterface[]>([])

  const contextValue = useMemo(
    () => ({
      reason,
      setReason,
      comments,
      setComments,
      archiveAll,
      setArchiveAll,
      roundId,
      setRoundId,
      setSendEmail,
      sendEmail,
      selectedRounds,
      setSelectedRounds,
    }),
    [
      reason,
      setReason,
      comments,
      setComments,
      archiveAll,
      setArchiveAll,
      roundId,
      setRoundId,
      setSendEmail,
      sendEmail,
      selectedRounds,
      setSelectedRounds,
    ],
  )

  return (
    <ArchivingContext.Provider value={contextValue}>{children}</ArchivingContext.Provider>
  )
}

export const useArchiving = () => {
  const context = useContext(ArchivingContext)
  if (context === null) {
    throw new Error(`useArchiving must be used within an ArchivingProvider`)
  }
  return context
}

interface ArchiveButtonProps {
  candidateId: number
  pending?: boolean
  scheduled?: boolean
  onAfterArchive?: () => void
  onLoading?: (isLoading: boolean) => void
  noSuccessPopup?: boolean
}

interface BulkArchiveButtonProps
  extends Omit<ArchiveButtonProps, 'candidateId' | 'step'> {
  candidateIds?: (number | string)[]
}

export const ArchiveButton = ({
  candidateId,
  pending,
  scheduled,
  onAfterArchive,
  onLoading,
  noSuccessPopup,
}: ArchiveButtonProps) => {
  const statusPopup = useStatusPopup()
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const { reason, comments, selectedRounds, sendEmail } = useArchiving()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const queryClient = useQueryClient()

  const afterSuccess = () => {
    if (onAfterArchive) {
      onAfterArchive()
    } else {
      navigateReplace(
        pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: candidateId,
        }),
      )
    }
  }

  const onArchive = async () => {
    if (!reason) {
      return
    }
    setIsLoading(true)
    onLoading?.(true)

    const selectedRoundIds = selectedRounds.map(round => round.id)

    try {
      await archiveInterviews(
        candidateId,
        selectedRoundIds,
        reason,
        comments,
        queryClient,
      )
      if (noSuccessPopup) {
        afterSuccess()
      } else {
        setSuccessPopupOpen(true)
      }
    } catch (e) {
      const errorMsg = getStringMessageFromError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Cannot archive the candidate</StatusPopup.Title>
          {errorMsg && <StatusPopup.Description>{errorMsg}</StatusPopup.Description>}
        </StatusPopup>,
      )
      Sentry.captureException(e)
    } finally {
      setIsLoading(false)
      onLoading?.(false)
    }
  }

  const btnPending = pending || isLoading
  const btnDisabled = btnPending || !selectedRounds.length || !reason

  let statusLabel = 'Candidate archived'

  if (sendEmail) {
    statusLabel = scheduled
      ? 'Candidate archived & email scheduled'
      : 'Candidate archived & email sent'
  }

  return (
    <>
      {sendEmail ? (
        <NewSaveButtonWithPopup<SendCandidateEmailInterface>
          successText="Email has been sent"
          onAfterSubmit={onArchive}
          useValidator
          noPopup
          disabled={btnDisabled}
          pending={btnPending}
        >
          Archive & {scheduled ? 'schedule' : 'send'} email
        </NewSaveButtonWithPopup>
      ) : (
        <Button elevated disabled={btnDisabled} onClick={onArchive} pending={btnPending}>
          {chain('Archive', selectedRounds.length)}
        </Button>
      )}

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          afterSuccess()
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>{statusLabel}</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export const BulkArchiveButton = ({
  candidateIds,
  pending,
  scheduled,
  onAfterArchive,
  onLoading,
}: BulkArchiveButtonProps) => {
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const { reason, comments, archiveAll, sendEmail } = useArchiving()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = async () => {
    if (!reason || !candidateIds?.length) {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Reason or candidates list are missing',
        duration: ERROR_DEFAULT_DURATION,
      })
      return
    }
    setIsLoading(true)
    onLoading?.(true)

    try {
      await bulkArchiveCandidates(candidateIds, archiveAll, reason, comments)
      setIsSuccess(true)
    } catch (e) {
      setIsSuccess(false)
    } finally {
      setIsLoading(false)
      onLoading?.(false)
    }
  }

  const btnPending = pending || isLoading
  const btnDisabled = btnPending || !reason

  return (
    <>
      {sendEmail ? (
        <NewSaveButtonWithPopup<SendCandidateEmailInterface>
          successText="Email has been sent"
          onAfterSubmit={onClick}
          onSubmitError={e => {
            if (e?.response?.status !== 400) {
              setIsSuccess(false)
            }
          }}
          pending={btnPending}
          useValidator
          noPopup
          disabled={btnDisabled}
        >
          Archive & {scheduled ? 'schedule' : 'send'} emails
        </NewSaveButtonWithPopup>
      ) : (
        <Button elevated disabled={btnDisabled} onClick={onClick} pending={btnPending}>
          Archive
        </Button>
      )}
      <BulkStatusPopup
        isSuccess={isSuccess}
        onClose={() => {
          setIsSuccess(null)
          onAfterArchive?.()
        }}
      />
    </>
  )
}

type PartialSendCandidateEmailPayloadInterface = Omit<
  SendCandidateEmailPayloadInterface,
  'attachments' | 'subject' | 'email_body'
> &
  Partial<
    Pick<SendCandidateEmailPayloadInterface, 'attachments' | 'subject' | 'email_body'>
  >

export interface RememberedDataInterface
  extends Pick<
      ArchivingContextInterface,
      'reason' | 'comments' | 'sendEmail' | 'archiveAll'
    >,
    PartialSendCandidateEmailPayloadInterface {}

export const getRememberedArchivingData = (): RememberedDataInterface | null => {
  const rememberRawData = workspaceLocalStorage.getItem(
    LOCAL_STORAGE.ARCHIVING_DETAILS_REMEMBER_CHOICE,
  )
  if (rememberRawData) {
    try {
      const rememberDataParsed: RememberedDataInterface = JSON.parse(rememberRawData)
      delete rememberDataParsed.attachments
      delete rememberDataParsed.subject
      delete rememberDataParsed.email_body

      return rememberDataParsed
    } catch (e) {
      clearCVScreeningSession()
      return null
    }
  }

  return null
}

export const saveRememberArchivingData = (data: RememberedDataInterface) => {
  workspaceLocalStorage.setItem(
    LOCAL_STORAGE.ARCHIVING_DETAILS_REMEMBER_CHOICE,
    JSON.stringify(data),
  )
}

export const getUpdatedArchivingData = async (
  candidateId?: number,
): Promise<RememberedDataInterface | null> => {
  const rememberedData = getRememberedArchivingData()

  try {
    if (rememberedData) {
      let newRememberedData = { ...rememberedData }
      if (rememberedData.email_template?.id) {
        const { data } = await getPrefilledPlaceholdersEmail(
          rememberedData.email_template.id,
          candidateId,
        )

        newRememberedData = {
          ...newRememberedData,
          when_to_send: data.when_to_send
            ? { ...data.when_to_send }
            : rememberedData.when_to_send,
          custom_sending_datetime: data.custom_sending_datetime,
          email_template: data.email_template
            ? { ...data.email_template }
            : rememberedData.email_template,
          sender_type: data.sender_type,
          email_body: data.email_body,
          recipients_cc: data.recipients_cc,
          recipients_bcc: data.recipients_bcc,
          subject: data.subject,
          attachments: (data.attachments as FileInterface[]) ?? [],
        }
      }

      return newRememberedData
    }
  } catch {
    return null
  }
  return rememberedData
}

export const usePrefillRememberedChoice = (
  setRememberChoiceChecked: (checked: boolean) => void,
  candidate?: CandidateInterface,
) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { setReason, setComments, setSendEmail } = useArchiving()
  const [loading, setLoading] = useState(true)
  const updateValues = async (candidateId?: number) => {
    setLoading(true)
    const data = await getUpdatedArchivingData(candidateId)

    if (data) {
      setComments(data.comments)
      setReason(data.reason)
      setSendEmail(data.sendEmail)

      values.when_to_send = data.when_to_send ? { ...data.when_to_send } : undefined
      values.custom_sending_datetime = data.custom_sending_datetime
      values.email_template = data.email_template ? { ...data.email_template } : undefined
      values.sender_type = data.sender_type
      values.email_body = data.email_body || ''
      values.recipients_cc = data.recipients_cc
      values.recipients_bcc = data.recipients_bcc
      values.subject = data.subject || ''
      values.attachments = (data.attachments as FileInterface[]) || []

      setRememberChoiceChecked(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    updateValues(candidate?.id)
  }, [candidate])

  return {
    loading,
  }
}
