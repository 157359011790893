import { useSelector } from 'react-redux'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import {
  SystemNotificationsUnreadInterface,
  SystemNotificationInterface,
} from '@src/interfaces/systemNotifications'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const useGetUnreadSystemNotifications = () => {
  // TODO: https://revolut.atlassian.net/browse/REVCOR-2712 revert after infra is fixed
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  return useFetch<SystemNotificationsUnreadInterface>(
    `${API.SYSTEM_NOTIFICATIONS}/unread`,
    undefined,
    undefined,
    true,
    {
      staleTime: isCommercial ? 60 * 1000 : Infinity,
      cacheTime: isCommercial ? 60 * 1000 : Infinity,
    },
  )
}

export const getSystemNotifications = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<SystemNotificationInterface>
> =>
  api.get(
    API.SYSTEM_NOTIFICATIONS,
    { params: filterSortPageIntoQuery(sortBy, filters, page) },
    'v1',
  )

export const markNotificationAsRead = (
  id: number,
): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/${id}/read`)

export const markAllNotificationsAsRead = (): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/allRead`)

export const dismissNotification = (
  id: number,
): AxiosPromise<SystemNotificationInterface> =>
  api.post(`${API.SYSTEM_NOTIFICATIONS}/${id}/dismiss`)
