import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalKpiDetails, GoalsInterface } from '@src/interfaces/goals'
import { useState, useEffect } from 'react'
import { getCache, getMetricRelevantFormData, setCache } from './helpers'
import { isEqual, omit } from 'lodash'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { DeepPartial } from 'redux'
import { UpdateTypes } from '@src/interfaces/kpis'

export const useGoalFormCache = () => {
  const { values, initialValues } = useLapeContext<GoalsInterface>()
  const [cacheUpdated, setCacheUpdated] = useState(false)

  const lsKey = `goal-${values.id}-cache`
  const cache = getCache<GoalsInterface>(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalsInterface but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
    setCacheUpdated(true)
  }, [])

  useEffect(() => {
    if (
      values.name !== initialValues.name ||
      values.description !== initialValues.description ||
      values.owner?.id !== initialValues.owner?.id ||
      values.update_type?.id !== initialValues.update_type?.id ||
      values.content_object?.id !== initialValues.content_object?.id ||
      values.parent?.id !== initialValues.parent?.id ||
      !isEqual(values.kpis, initialValues.kpis) ||
      values.kpis.length === initialValues.kpis?.length // need to check if we don't had empty new metric added then deleted so that it doesn't reappear
    ) {
      setCache<GoalsInterface>(lsKey, {
        name: values.name,
        description: values.description,
        owner: values.owner ? { id: values.owner.id } : undefined,
        parent: values.parent ? ({ id: values.parent.id } as GoalsInterface) : null,
        update_type: values.update_type
          ? { id: values.update_type.id, name: values.update_type.name }
          : undefined,
        content_object: values.content_object,
        kpis: values.kpis,
        is_company: values.is_company,
      })
    }
  }, [
    values.name,
    values.description,
    values.owner?.id,
    values.parent?.id,
    values.update_type?.id,
    values.content_object?.id,
    values.kpis.length,
    values.is_company,
  ])

  return {
    cleanCache: () => workspaceLocalStorage.removeItem(lsKey),
    cacheUpdated,
  }
}

export const cleanGoalCache = (id: number | string) =>
  workspaceLocalStorage.removeItem(`goal-${id}-cache`)

export const cleanMetricCache = (id: number | string) =>
  workspaceLocalStorage.removeItem(`metric-${id}-cache`)

export const useMetricFormCache = () => {
  const { values } = useLapeContext<GoalKpiDetails>()
  const [cacheUpdated, setCacheUpdated] = useState(false)

  const lsKey = `metric-${values.id || values.tempId}-cache`
  const cache = getCache<DeepPartial<GoalKpiDetails>>(lsKey)

  useEffect(() => {
    if (cache) {
      Object.keys(cache).forEach(key => {
        // key is keyof GoalKpiDetails but typescript marks it as string
        // @ts-expect-error
        values[key] = cache[key]
      })
    }
    setCacheUpdated(true)
  }, [])

  useEffect(() => {
    let newValues: DeepPartial<GoalKpiDetails>
    switch (values.update_type) {
      case UpdateTypes.sql:
        newValues = omit(
          values,
          'target_epics',
          'look_url',
          'counted_sql_column',
          'counted_sql_date_column',
          'salesforce_report',
        )
        break
      case UpdateTypes.roadmap:
      case UpdateTypes.clickup:
        newValues = omit(
          values,
          'sql_query',
          'sql_query_db',
          'connection',
          'look_url',
          'counted_sql_column',
          'counted_sql_date_column',
          'salesforce_report',
        )
        break
      case UpdateTypes.manual:
        newValues = omit(
          values,
          'look_url',
          'sql_query',
          'sql_query_db',
          'connection',
          'target_epics',
          'counted_sql_column',
          'counted_sql_date_column',
          'salesforce_report',
        )
        break
      case UpdateTypes.looker:
        newValues = omit(
          values,
          'sql_query',
          'sql_query_db',
          'target_epics',
          'connection',
          'salesforce_report',
        )
        break
      case UpdateTypes.tableau:
        newValues = omit(
          values,
          'counted_sql_date_column',
          'sql_query',
          'sql_query_db',
          'connection',
          'target_epics',
          'salesforce_report',
        )
        break
      case UpdateTypes.salesforce:
        newValues = omit(
          values,
          'sql_query',
          'sql_query_db',
          'target_epics',
          'connection',
          'look_url',
        )
        break
      default:
        newValues = values
    }
    setCache<DeepPartial<GoalKpiDetails>>(lsKey, getMetricRelevantFormData(newValues))
  }, [
    values.name,
    values.targets?.at(0)?.initial_value,
    values.targets?.at(0)?.target,
    values.targets?.at(0)?.kpi_goal,
    values.targets?.at(0)?.review_cycle,
    values.owner?.id,
    values.unit,
    values.update_type,
    values.sql_query,
    values.sql_query_db,
    values.connection,
    values.target_epics?.at(0)?.epics.length,
    values.look_url,
    values.means_of_measure,
    values.todo_items,
    values.target_epics?.at(0)?.epics.map(({ key }) => key),
    values.counted_sql_column,
    values.counted_sql_date_column,
    values.salesforce_report,
  ])

  return {
    cleanCache: () => workspaceLocalStorage.removeItem(lsKey),
    cacheUpdated,
  }
}
