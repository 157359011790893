import React from 'react'
import { Avatar, IconName, Item } from '@revolut/ui-kit'
import { CompetencyMatrixSkill } from '@src/interfaces/roles'
import { CultureValueInterface } from '@src/interfaces/cultureValues'

interface Props {
  value: CompetencyMatrixSkill | CultureValueInterface
  iconName: IconName
  onClick: (metric: CompetencyMatrixSkill | CultureValueInterface) => void
}

export const MetricItem = ({ value, iconName, onClick }: Props) => (
  <Item use="label" onClick={() => onClick(value)}>
    <Item.Avatar>
      <Avatar useIcon={iconName} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{value.name}</Item.Title>
    </Item.Content>
  </Item>
)
