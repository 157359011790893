import React, { useEffect, useRef, useState } from 'react'
import { Box, Portal, TextSkeleton, chain } from '@revolut/ui-kit'
import {
  EngagementResultInterface,
  EngagementResultsComment,
  EngagementResultsScope,
} from '@src/interfaces/engagement'

import SideBar from '@components/SideBar/SideBar'
import {
  getSurveyAdminResultCommentsTableRequests,
  getSurveyPublishedResultCommentsTableRequests,
} from '@src/api/engagement'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { CommentItem } from '@src/apps/People/Engagement/Results/components/Comments/CommentItem'
import { Virtuoso } from 'react-virtuoso'

export const useCommentsSidebar = (
  surveyId: number,
  entity?: { type: EngagementResultsScope; id: number },
) => {
  const [sideBar, setSidebar] = useState<
    | {
        driver: EngagementResultInterface['driver']
        question: EngagementResultInterface['question']
      }
    | undefined
  >()

  return {
    commentsSidebar: sideBar ? (
      <CommentsSideBar
        surveyId={surveyId}
        driver={sideBar?.driver}
        question={sideBar?.question}
        onClose={() => setSidebar(undefined)}
        entity={entity}
      />
    ) : null,
    setSidebar,
  }
}

const useFetchQuestions = (
  surveyId: number,
  driverId?: number,
  questionId?: number,
  entity?: { type: EngagementResultsScope; id: number },
) => {
  const [page, setPage] = useState(1)
  const { getItems } = entity
    ? getSurveyPublishedResultCommentsTableRequests({
        scope: entity.type,
        scopeId: entity.id,
      })
    : getSurveyAdminResultCommentsTableRequests()
  const [isLoading, setIsLoading] = useState(false)
  const showPopup = useShowStatusPopup()
  const [data, setData] =
    useState<{ questions: EngagementResultsComment[]; count: number }>()

  const fetchNextPage = () => {
    setPage(page + 1)
  }

  const fetchQuestions = async () => {
    setIsLoading(true)
    try {
      const response = await getItems({
        page,
        filters: [
          {
            columnName: 'survey__id',
            filters: [{ id: surveyId, name: String(surveyId) }],
            nonResettable: true,
            nonInheritable: true,
          },
          ...(driverId
            ? [
                {
                  columnName: 'driver__id',
                  filters: [{ id: driverId, name: String(driverId) }],
                },
              ]
            : []),
          ...(questionId
            ? [
                {
                  columnName: 'question__id',
                  filters: [{ id: questionId, name: String(questionId) }],
                },
              ]
            : []),
        ],
      })
      setData(prev => ({
        questions: prev?.questions
          ? [...prev.questions, ...response.data.results]
          : response.data.results,
        count: response.data.count,
      }))
    } catch (err) {
      showPopup({ status: 'error', title: 'Something went wrong' })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setPage(1)
    setData(undefined)
  }, [surveyId, driverId, questionId])

  useEffect(() => {
    fetchQuestions()
  }, [page])

  return { isLoading, data, fetchNextPage }
}

const Scroller = React.forwardRef<HTMLDivElement>((props, ref) => {
  return <Box ref={ref} {...props} />
})

const CommentsSideBar = ({
  driver,
  question,
  surveyId,
  onClose,
  entity,
}: {
  driver: EngagementResultInterface['driver']
  question: EngagementResultInterface['question']
  surveyId: number
  onClose: VoidFunction
  entity?: { type: EngagementResultsScope; id: number }
}) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { isLoading, data, fetchNextPage } = useFetchQuestions(
    surveyId,
    driver?.id,
    question?.id,
    entity,
  )

  return (
    <Portal>
      <SideBar
        variant="wide"
        title={isLoading ? <TextSkeleton size={8} /> : chain('Answers', data?.count)}
        subtitle={`for ${
          question
            ? `question: "${question.question_text}"`
            : `category: "${driver?.name}"`
        }`}
        onClose={onClose}
        isOpen
        sideProps={{ scrollRef }}
      >
        {data && (
          <Virtuoso<EngagementResultsComment>
            data={data.questions}
            components={{ Scroller }}
            // https://github.com/petyosi/react-virtuoso/issues/341
            initialItemCount={data.questions.length - 1}
            customScrollParent={scrollRef?.current || undefined}
            overscan={150}
            endReached={() => {
              if (data.count > data.questions.length) {
                fetchNextPage()
              }
            }}
            itemContent={(_, comment) => (
              <Box pb="s-16" key={comment.id}>
                <CommentItem comment={comment} />
              </Box>
            )}
          />
        )}
      </SideBar>
    </Portal>
  )
}
