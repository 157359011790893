import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import CustomFieldsTable from '@src/pages/People/PeopleSubTabs/CustomFields/CustomFieldsTable'

export const CustomFieldsSettings = () => {
  useSetDocumentTitle('Settings', 'Custom fields')

  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.SETTINGS.ALL} title="Custom fields settings" />
      <PageBody maxWidth="100%">
        <CustomFieldsTable />
      </PageBody>
    </PageWrapper>
  )
}
