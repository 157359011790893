import { Box, Checkbox, Text, Token } from '@revolut/ui-kit'
import React from 'react'
import {
  InterviewQuestionInterface,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { useLapeContext } from '@src/features/Form/LapeForm'
import set from 'lodash/set'
import Section from './Section'
import { setTimeCodeHandler } from '@src/utils/hiring'

type Props = {
  data: InterviewQuestionInterface[]
  name: string
  titleIndexNumber: number
  title: string
  notesName?: string
  subtitle?: string
  isViewMode?: boolean
  isOptional?: boolean
}

const CheckboxCard = ({
  data,
  name,
  isViewMode,
  isOptional,
  title,
  subtitle,
  notesName,
  titleIndexNumber,
}: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()

  const points = data?.reduce((acc, val) => (val.answer ? acc + val.points : acc), 0) || 0

  return (
    <Section
      index={titleIndexNumber}
      title={title}
      subtitle={subtitle}
      points={points}
      isViewMode={isViewMode}
      notesName={notesName}
      isOptional={isOptional}
      onAddTimeCode={setTimeCodeHandler(notesName, values)}
    >
      {data.map((option, id) => (
        <Box py="s-8" key={id}>
          <Checkbox
            checked={option.answer}
            onChange={e => {
              if (isViewMode) {
                return
              }
              set(values, `${name}.${id}.answer`, e.target.checked)
            }}
            wrapper={{ alignItems: 'start' }}
            data-testid={`${name}.${id}.answer`}
          >
            {option.points < 0 && <Text pr="s-8">🚩</Text>}
            <Text
              color={
                isViewMode && !option.answer
                  ? Token.color.greyTone50
                  : Token.color.foreground
              }
            >
              {option.question}
            </Text>
          </Checkbox>
        </Box>
      ))}
    </Section>
  )
}

export default CheckboxCard
