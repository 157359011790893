import React, { useEffect } from 'react'
import { Box, Text, Flex, Color, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

import { BlogPosts } from './BlogPosts'
import { Pride } from './Pride'
import { WrapperCss } from '../common'
import { Locations } from './Locations'
import { Values } from './Values'
import { Feedback } from './Feedback'

const BackgroundImageCss = css`
  background-image: url('https://cdn.revolut.com/media/revoluters/trading.png');
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: contain;
  background-image: none;

  @media screen and ${Token.media.lg} {
    background-image: unset;
  }
`

const HeroTextCss = css`
  margin-top: 24px;
  padding-bottom: 24px;
  align-items: center;
  text-align: center;
  max-width: 100%;

  @media screen and ${Token.media.lg} {
    margin-top: 96px;
    padding-bottom: 128px;
    max-width: 500px;
    align-items: unset;
    text-align: unset;
  }
`

interface WelcomeProps {
  /** Should only be set for onboarding flow, and not the public route */
  showQuestion?: boolean
  /** Should only be set for onboarding flow, and not the public route */
  onQuestionAnswered?: () => void
  headerHeight?: number
}

const Welcome = ({ headerHeight, showQuestion, onQuestionAnswered }: WelcomeProps) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Box mb={{ all: '100px', lg: '200px' }}>
      <Box
        backgroundColor={Color.DEEP_PURPLE}
        mt={headerHeight ? (`-${headerHeight}px` as `${number}px`) : undefined}
        pt={`${headerHeight}px` as `${number}px`}
      >
        <Box css={WrapperCss}>
          <Box css={BackgroundImageCss}>
            <Flex flexDirection="column" color="background" css={HeroTextCss}>
              <Text variant="h2" mb="s-32" fontSize="h0" display="block">
                Welcome to
                <br />
                Team Revolut!
              </Text>
              <Text fontSize="h5">
                Your Day 1 is right around the corner, and we are stoked to have you join
                the team that’s building the world's first global financial SuperApp!
                <br />
                <br />
                This portal is designed for you to get to know the company better, learn
                about our culture, values and internal guilds, and to stay connected.
              </Text>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box css={WrapperCss}>
        {showQuestion !== undefined && onQuestionAnswered ? (
          <Feedback showQuestion={showQuestion} onQuestionAnswered={onQuestionAnswered} />
        ) : null}
        <Values />
        <BlogPosts />
        <Pride />
        <Locations />
      </Box>
    </Box>
  )
}

export default Welcome
