import React from 'react'
import { Box, Color, Group, Item, ItemSkeleton, Text } from '@revolut/ui-kit'
import { CandidatesPipelineViewType } from '@src/api/recruitment/candidates'
import pluralize from 'pluralize'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'

type StageCountsProps = {
  stages: CandidatesPipelineViewType[]
}

const StageCounts = ({ stages }: StageCountsProps) => {
  return (
    <Box mb="s-16">
      <NewStepperTitle
        type={NewStepperTitleType.compact}
        title={`Current ${pluralize('stage', stages.length)}`}
        mt="0"
      />
      <Group>
        {!stages.length && (
          <ItemSkeleton data-testid="loading-stages">
            <ItemSkeleton.Content />
            <ItemSkeleton.Side />
          </ItemSkeleton>
        )}
        {stages
          .filter(({ count }) => count)
          .map(({ label, count, status }) => (
            <Item key={`${label}_${status}`} variant="choice">
              <Item.Content>
                <Text variant="secondary">
                  {label}{' '}
                  {status === 'archived' && (
                    <Text variant="secondary" color={Color.ORANGE}>
                      Archived
                    </Text>
                  )}
                </Text>
              </Item.Content>
              <Item.Side>{pluralize('candidates', count, true)}</Item.Side>
            </Item>
          ))}
      </Group>
    </Box>
  )
}

export default StageCounts
