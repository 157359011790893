import React from 'react'
import { Flex, Token, Icon, Text, HStack, Tag } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'
import { IdAndName } from '@src/interfaces'
import pluralize from 'pluralize'

interface RelatedKPIsProps {
  kpis: IdAndName[]
}

export const RelatedKPIs = ({ kpis }: RelatedKPIsProps) => {
  const isNewTable = useIsNewTable()

  if (isNewTable) {
    if (kpis.length === 0) {
      return <>-</>
    }

    return (
      <Flex gap="s-4" alignItems="center" color={Token.color.greyTone50}>
        <Icon name="Limit" size={16} />
        <Text>{pluralize('metric', kpis.length, true)}</Text>
      </Flex>
    )
  }

  return (
    <HStack gap="s-4">
      {kpis.map(item => (
        <Tag key={item.id} variant="faded">
          {item.name}
        </Tag>
      ))}
    </HStack>
  )
}
