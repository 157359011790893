import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Box, chain, Item, Text, Token } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import React from 'react'
import { HiringStageJobPostings } from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/HiringStageJobPostings'

type Props = {
  item: InterviewRoundInterface
  activeRoundId?: number
  currentRound?: number
  onClick?: () => void
  archived?: boolean
  readOnly?: boolean
}

const ChangeRoundItem = ({
  item,
  activeRoundId,
  currentRound,
  onClick,
  archived,
  readOnly,
}: Props) => {
  return (
    <Item
      key={item.id}
      use={readOnly ? undefined : 'button'}
      aria-pressed={currentRound === item.id}
      onClick={readOnly ? undefined : onClick}
      data-testid={`round-${item.id}`}
    >
      <Item.Content>
        <Item.Title>
          {chain(
            item.specialisation.name,
            activeRoundId === item.id && <Text color={Token.color.green}>Main</Text>,
            archived && <Text color={Token.color.red}>Archived</Text>,
          )}
        </Item.Title>
        <Item.Description>
          {item.recruiter && (
            <Box>
              <Text variant="h6">Recruiter: </Text>
              <Text>{item.recruiter.display_name}</Text>
            </Box>
          )}
          {item.latest_interview_stage && (
            <Box>
              <Text variant="h6">Stage: </Text>
              <Text>
                {chain(
                  item.latest_interview_stage.interview_type_display,
                  `${item.latest_interview_stage.interview_number}/${item.stage_count}`,
                )}
              </Text>
            </Box>
          )}
          {item.archived_reason && (
            <Box>
              <Text variant="h6">Archival reason: </Text>
              <Text>{item.archived_reason.name}</Text>
            </Box>
          )}
          <HiringStageJobPostings round={item} />
          {item.latest_interview_stage_updated_date_time && (
            <Box>
              <Text variant="h6">Stage updated: </Text>
              <Text>{formatDate(item.latest_interview_stage_updated_date_time)}</Text>
            </Box>
          )}
        </Item.Description>
      </Item.Content>
    </Item>
  )
}

export default ChangeRoundItem
