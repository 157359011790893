import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { departmentRequests } from '@src/api/department'
import { entitiesRequests } from '@src/api/entities'
import { teamsRequests } from '@src/api/teams'
import { rolesRequests } from '@src/api/roles'
import { UpdateOrganisationUnitType } from '@src/interfaces/updateOrganisationStructure'
import { TableNames } from '@src/constants/table'
import { teamGenericNameColumn } from '@src/constants/columns/team'
import { roleGenericNameColumn, roleHeadcountColumn } from '@src/constants/columns/role'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import {
  departmentGenericNameColumn,
  departmentHeadcountColumn,
} from '@src/constants/columns/department'
import {
  ownerNameColumn,
  roleManagerColumn,
  teamOwnerColumn,
} from '@src/constants/columns/employee'
import {
  entityContractingCountriesColumn,
  entityGenericNameColumn,
  entityPaygroupCountriesColumn,
  entityRegistrationCountryColumn,
  entityRegistrationNumberColumn,
  entityStatusColumn,
} from '@src/constants/columns/entity'
import { defaultRolesTableStatusFilter } from '@src/pages/Organisation/OrganisationSubTabs/Roles/common'
import { Color, HStack } from '@revolut/ui-kit'
import {
  deleteDepartment,
  deleteEntity,
  deleteRole,
  deleteTeam,
} from '@src/api/updateOrganisationStructure'
import LapeDeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/LapeDeleteOrgUnitButton'

export const typeToRoute = {
  department: ROUTES.FORMS.DEPARTMENT.SETTINGS,
  team: ROUTES.FORMS.TEAM.SETTINGS,
  role: ROUTES.FORMS.ROLE.GENERAL,
  entity: ROUTES.FORMS.ENTITY.GENERAL,
}

export const typeToTitle = {
  department: 'Departments',
  team: 'Teams',
  role: 'Roles',
  entity: 'Legal Entities',
}

export const typeToSearchLabel = {
  department: 'Search by department name',
  team: 'Search by team name',
  role: 'Search by role name',
  entity: 'Search by entity name',
}

export const typeToBackUrl = {
  department: ROUTES.ORGANISATION.TEAMS.DEPARTMENTS,
  team: ROUTES.ORGANISATION.TEAMS.TEAMS,
  role: ROUTES.ORGANISATION.ROLES.ROLES,
  entity: ROUTES.APPS.ENTITIES,
}

export const typeToTableRequests = {
  department: departmentRequests,
  team: teamsRequests,
  role: rolesRequests,
  entity: entitiesRequests,
}

export const typeToName = {
  department: 'Department',
  team: 'Team',
  role: 'Role',
  entity: 'Entity',
}

export const typeToMergeTitle = {
  department: 'Merge departments',
  team: 'Merge teams',
  role: 'Merge roles',
  entity: 'Merge entities',
}

export const typeToAddButtonLabel = {
  department: 'Add department',
  team: 'Add team',
  role: 'Add role',
  entity: 'Add entity',
}

export const typeToMergeButtonLabel = {
  department: 'Merge departments',
  team: 'Merge teams',
  role: 'Merge roles',
  entity: 'Merge entities',
}

export const typeToTableName = {
  department: TableNames.DepartmentsStructure,
  team: TableNames.TeamsStructure,
  role: TableNames.RolessStructure,
  entity: TableNames.EntitiesStructure,
}

const typeToDeleteApi = {
  department: deleteDepartment,
  team: deleteTeam,
  role: deleteRole,
  entity: deleteEntity,
}

interface UpdateOrganisationStructureActionProps {
  id: number
  type: UpdateOrganisationUnitType
  onRefresh: () => void
}

const UpdateOrganisationStructureAction = ({
  id,
  type,
  onRefresh,
}: UpdateOrganisationStructureActionProps) => {
  return (
    <>
      <HStack space="s-8">
        <TableActionButton use={InternalLink} to={pathToUrl(typeToRoute[type], { id })}>
          Edit
        </TableActionButton>

        <LapeDeleteOrgUnitButton
          onAfterDelete={onRefresh}
          prefix={type}
          displayName={type}
          renderButton={({ onClick }) => {
            return (
              <TableActionButton onClick={onClick} color={Color.RED}>
                Delete
              </TableActionButton>
            )
          }}
          deleteApi={() => typeToDeleteApi[type](id)}
          checkPermissions={false}
          forcedId={id}
          confirmMessage={`Are you sure you want to delete this ${type}`}
          showPendingPopup
        />
      </HStack>
    </>
  )
}

export const updateOrganisationStructureActionColumn = (
  type: UpdateOrganisationUnitType,
  onRefresh: () => void,
): ColumnInterface<{ id: number }> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <UpdateOrganisationStructureAction type={type} onRefresh={onRefresh} id={data.id} />
  ),
})

const typeToRows = {
  department: [
    {
      ...departmentGenericNameColumn,
      width: 300,
    },
    {
      ...departmentHeadcountColumn,
      width: 250,
    },
    {
      ...ownerNameColumn,
      width: 250,
    },
  ],
  team: [
    {
      ...teamGenericNameColumn,
      width: 232,
    },
    {
      ...departmentHeadcountColumn,
      width: 130,
    },
    {
      ...teamOwnerColumn,
      width: 160,
    },
  ],
  role: [
    {
      ...roleGenericNameColumn,
      width: 220,
    },
    {
      ...roleHeadcountColumn,
      width: 100,
    },
    {
      ...roleManagerColumn,
      width: 200,
    },
  ],
  entity: [
    {
      ...entityGenericNameColumn,
      width: 150,
    },
    {
      ...entityRegistrationCountryColumn,
      width: 120,
    },
    {
      ...entityRegistrationNumberColumn,
      width: 120,
    },
    {
      ...entityPaygroupCountriesColumn,
      width: 300,
    },
    {
      ...entityContractingCountriesColumn,
      width: 300,
    },
    {
      ...entityStatusColumn,
      width: 100,
    },
  ],
}

export const typeToDefaultFilter = (type: UpdateOrganisationUnitType) => [
  {
    columnName: 'status',
    filters:
      type === 'role'
        ? defaultRolesTableStatusFilter
        : [{ id: 'active', name: 'active' }],
    nonResettable: true,
  },
]

export const getRow = (type: UpdateOrganisationUnitType, onRefresh: () => void) => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    ...typeToRows[type],
    {
      ...updateOrganisationStructureActionColumn(type, onRefresh),
      width: 150,
    },
  ],
})
