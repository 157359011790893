import { useEffect, useMemo } from 'react'
import { useCookieConsentContext } from '@src/pages/Landing/hooks/useCookieConsentContext'
import { COOKIE } from '@src/constants/api'
import { useAnalyticsCookiesCleanup } from '@src/pages/Careers/hooks/useAnalyticsCookiesCleanup'
import { useCookie } from '@src/hooks/useCookie'
import isEmpty from 'lodash/isEmpty'

interface CareersAnalytics {
  utm?: { [key: string]: string }
  referrer?: string
}

export function parseCareersAnalyticsCookie(
  cookie?: string | null,
): CareersAnalytics | undefined {
  if (!cookie) {
    return undefined
  }

  try {
    return JSON.parse(cookie)
  } catch {
    return undefined
  }
}

const UTM_PARAMS = [
  'utm_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
]

export function useCareersAnalytics() {
  useAnalyticsCookiesCleanup([COOKIE.COOKIE_CAREERS_ANALYTICS])
  const { isAnalyticsEnabled } = useCookieConsentContext()
  const [analyticsCookie, setAnalyticsCookie] = useCookie(COOKIE.COOKIE_CAREERS_ANALYTICS)
  const analyticsCookieParsed = useMemo(
    () => parseCareersAnalyticsCookie(analyticsCookie),
    [analyticsCookie],
  )
  const date = useMemo(() => new Date().toISOString(), [])

  const [utm, referrer] = useMemo(() => {
    const searchParams = new URL(window.location.href).searchParams

    let utmObj: { [key: string]: string } = {}

    for (const [key, value] of searchParams.entries()) {
      if (UTM_PARAMS.includes(key)) {
        utmObj[key] = value
      }
    }

    return [utmObj, searchParams.get('referrer') || undefined]
  }, [window.location.href])

  useEffect(() => {
    if (!isAnalyticsEnabled) {
      return
    }

    const hasUtm = !isEmpty(utm)

    /** Do not override cookie if it already holds UTM tags, unless new UTM tags are provided */
    if (!hasUtm && Boolean(analyticsCookieParsed?.utm)) {
      return
    }

    const value: CareersAnalytics = {
      referrer,
      utm: hasUtm ? { ...utm, date } : undefined,
    }

    setAnalyticsCookie(JSON.stringify(value))
  }, [isAnalyticsEnabled, utm, analyticsCookieParsed?.utm, date])
}
