import React, { useState } from 'react'
import EditableCell, {
  EditableCellProps,
} from '@components/Table/AdvancedCells/EditableCell/EditableCell'
import { TableCellInputType } from '@components/Inputs/TableCellInput/TableCellInput'

const WeightCell = (props: EditableCellProps) => {
  const [value, setValue] = useState(props.value)

  return (
    <EditableCell
      {...props}
      value={value}
      type={TableCellInputType.percent}
      onChange={setValue}
      suffix="%"
    />
  )
}

export default WeightCell
