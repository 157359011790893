import React, { useState } from 'react'
import {
  ActionButton,
  Box,
  Cell,
  chain,
  Flex,
  Group,
  MoreBar,
  ProgressStep,
  ProgressSteps,
  Text,
  Token,
  useDateRangeFormat,
  useDateTimeFormat,
  VStack,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import isBefore from 'date-fns/isBefore'

import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanyCompensationReviewsFormInterface } from '@src/interfaces/compensation'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SideBar from '@src/components/SideBar/SideBar'
import { COMPENSATION_MANAGEMENT_FAQ } from '@src/constants/externalLinks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface ProgressStepInterface {
  status: 'Ongoing' | 'Completed' | 'Upcoming'
  name: string
  description: string
  startTime: string
  endTime: string | null
}

export const Preview = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { values } = useLapeContext<CompanyCompensationReviewsFormInterface>()

  const permissions = useSelector(selectPermissions)

  const rangePreciseFormat = useDateRangeFormat({ style: 'precise' })
  const rangeAutoFormat = useDateRangeFormat({ style: 'auto' })
  const timeFormat = useDateTimeFormat()

  const canChange = permissions.includes(PermissionTypes.ChangeCompanyCompensationReview)

  const getStepStatus = (
    startTime: string,
    endTime: string | null,
  ): ProgressStepInterface['status'] => {
    const now = new Date()
    const start = new Date(startTime)
    const end = endTime ? new Date(endTime) : null

    if (isBefore(now, start)) {
      return 'Upcoming'
    }
    if (start && end && isBefore(start, now) && isBefore(now, end)) {
      return 'Ongoing'
    }
    return 'Completed'
  }

  const noMissingData = [
    values.currency_freeze_date_time,
    values.preparation_start_date_time,
    values.preparation_end_date_time,
    values.planning_start_date_time,
    values.planning_end_date_time,
    values.review_start_date_time,
    values.review_end_date_time,
    values.alignment_start_date_time,
    values.alignment_end_date_time,
    values.communication_date_time,
    values.payroll_deadline,
  ].every(Boolean)

  const progressSteps: ProgressStepInterface[] = [
    {
      name: 'Currency freeze date',
      description: `Currency exchange rates will be fixed throughout the compensation review process.`,
      startTime: values.currency_freeze_date_time,
      endTime: null,
    },
    {
      name: 'Preparation',
      description: `Compensation team calculates initial recommendations for salary increases and bonus grants`,
      startTime: values.preparation_start_date_time,
      endTime: values.preparation_end_date_time,
    },
    {
      name: 'Planning',
      description: `Owners of budget pools review initial recommendations and submit desired changes within the allowed budget.`,
      startTime: values.planning_start_date_time,
      endTime: values.planning_end_date_time,
    },
    {
      name: 'Review',
      description: `Compensation team and Audit team review the proposed changes to ensure compliance with applicable regulations.`,
      startTime: values.review_start_date_time,
      endTime: values.review_end_date_time,
    },
    {
      name: 'Alignment',
      description: `Compensation team discusses and reviews necessary adjustments with budget pool owners`,
      startTime: values.alignment_start_date_time,
      endTime: values.alignment_end_date_time,
    },
    {
      name: 'Communication',
      description: `Salary increases and bonus amounts are communicated to employees by email.`,
      startTime: values.communication_date_time,
      endTime: null,
    },
    {
      name: 'Payroll',
      description: `Salary increases and bonus amounts are communicated to the payroll team for processing and payment.`,
      startTime: values.payroll_deadline,
      endTime: null,
    },
  ].map(step => ({ ...step, status: getStepStatus(step.startTime, step.endTime) }))

  const canDoAnyAction = canChange || noMissingData

  return (
    <>
      <PageBody>
        {canDoAnyAction && (
          <SettingsButtons mb="s-24">
            {canChange ? (
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.GENERAL, {
                  id: values.id,
                })}
                useIcon="Pencil"
                use={InternalLink}
              >
                Edit
              </MoreBar.Action>
            ) : null}
            {noMissingData ? (
              <MoreBar.Action
                useIcon="16/LinkExternal"
                onClick={() => setSidebarOpen(true)}
              >
                Open timeline
              </MoreBar.Action>
            ) : null}
          </SettingsButtons>
        )}

        <FormPreview data={values} title="General information ">
          <Group>
            <FormPreview.Item title="Year" field="year" />
            <FormPreview.Item title="Cycle start" field="start_date_time" type="date" />
            <FormPreview.Item title="Cycle end" field="end_date_time" type="date" />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <FormPreview data={values} title="Review cycle key dates">
            <Group>
              <FormPreview.Item
                title="Currency freeze"
                field="currency_freeze_date_time"
                type="date"
              />
              <FormPreview.Item
                title="Preparation"
                insert={() => {
                  if (
                    values.preparation_start_date_time &&
                    values.preparation_end_date_time
                  ) {
                    return rangePreciseFormat(
                      new Date(values.preparation_start_date_time),
                      new Date(values.preparation_end_date_time),
                    )
                  }
                  return '-'
                }}
              />
              <FormPreview.Item
                title="Planning"
                insert={() => {
                  if (values.planning_start_date_time && values.planning_end_date_time) {
                    return rangePreciseFormat(
                      new Date(values.planning_start_date_time),
                      new Date(values.planning_end_date_time),
                    )
                  }
                  return '-'
                }}
              />
              <FormPreview.Item
                title="Review"
                insert={() => {
                  if (values.review_start_date_time && values.review_end_date_time) {
                    return rangePreciseFormat(
                      new Date(values.review_start_date_time),
                      new Date(values.review_end_date_time),
                    )
                  }
                  return '-'
                }}
              />
              <FormPreview.Item
                title="Alignment"
                insert={() => {
                  if (
                    values.alignment_start_date_time &&
                    values.alignment_end_date_time
                  ) {
                    return rangePreciseFormat(
                      new Date(values.alignment_start_date_time),
                      new Date(values.alignment_end_date_time),
                    )
                  }
                  return '-'
                }}
              />
              <FormPreview.Item
                title="Communication date"
                field="communication_date_time"
                type="date"
              />
              <FormPreview.Item
                title="Payroll deadline"
                field="payroll_deadline"
                type="date"
              />
            </Group>
          </FormPreview>
        </Box>
      </PageBody>

      <SideBar
        title="Compensation review timeline"
        isOpen={sidebarOpen}
        onClose={() => setSidebarOpen(false)}
        variant="wide"
      >
        <Box mb="s-32">
          <HideIfCommercial>
            <ActionButton
              href={COMPENSATION_MANAGEMENT_FAQ}
              target="_blank"
              rel="noreferrer noopener"
              use="a"
              useIcon="16/QuestionOutline"
            >
              Compensation FAQ
            </ActionButton>
          </HideIfCommercial>
        </Box>

        <Cell>
          <Flex gap="s-16" flexDirection="column">
            <VStack gap="s-8">
              <Text variant="primary">Cycle: {values.year}</Text>
              <Text variant="caption">
                {rangePreciseFormat(
                  new Date(values.start_date_time),
                  new Date(values.end_date_time),
                )}
              </Text>
            </VStack>

            <ProgressSteps variant="vertical">
              {progressSteps.map(step => {
                const color = {
                  Completed: Token.color.green,
                  Ongoing: Token.color.blue,
                  Upcoming: undefined,
                }[step.status]
                const caption = step.endTime
                  ? rangeAutoFormat(new Date(step.startTime), new Date(step.endTime))
                  : timeFormat(new Date(step.startTime))
                const title = chain(
                  step.name,
                  step.status === 'Upcoming' ? null : (
                    <Text color={color}>{step.status}</Text>
                  ),
                )

                return (
                  <ProgressStep
                    state={step.status !== 'Upcoming' ? 'done' : 'pending'}
                    color={color}
                    key={step.name}
                    data-testid={`${step.name}--${step.status}--${caption}`}
                  >
                    <ProgressStep.Caption>{caption}</ProgressStep.Caption>
                    <ProgressStep.Title>{title}</ProgressStep.Title>
                    <ProgressStep.Description>
                      {step.description}
                    </ProgressStep.Description>
                  </ProgressStep>
                )
              })}
            </ProgressSteps>
          </Flex>
        </Cell>
      </SideBar>
    </>
  )
}
