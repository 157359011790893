import React from 'react'
import isString from 'lodash/isString'
import { BoxProps } from '@revolut/ui-kit'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl, pathToUrlWithBaseUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { IdStatuses } from '@src/interfaces/employees'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import UserName, { UserNameProps } from './UserName'

export interface UserLinkProps extends Omit<BoxProps, 'id'> {
  name?: string
  id?: string | number
  full_name?: string
  status?: IdStatuses
  isLoading?: boolean
  usePathWithBaseUrl?: boolean
  avatarUrlFunc?: (id: string) => string
  userNameProps?: UserNameProps
}

export const UserLink = ({
  id,
  name,
  full_name,
  status,
  usePathWithBaseUrl,
  avatarUrlFunc,
  userNameProps = {},
}: UserLinkProps) => {
  const pathToUrlHandler = usePathWithBaseUrl ? pathToUrlWithBaseUrl : pathToUrl
  const linkTarget = usePathWithBaseUrl ? '_blank' : undefined

  const nameString = full_name || name

  if (!nameString || !isString(nameString)) {
    return <>-</>
  }

  const link = getLocationDescriptor(
    id && avatarUrlFunc
      ? avatarUrlFunc(String(id))
      : pathToUrlHandler(ROUTES.FORMS.EMPLOYEE.PROFILE, { id }),
  )

  return (
    <TableCellLink to={link} target={linkTarget} style={{ overflow: 'hidden' }}>
      <UserName {...userNameProps} name={name} full_name={full_name} status={status} />
    </TableCellLink>
  )
}
