import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'
import { select } from 'd3-selection'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'

export const addClickableArea = (
  selection: AddSelection,
  t: HierarchyTransition,
  update: (data: HierarchyNodeInterface) => void,
  data: HierarchyNodeInterface,
  onChildrenRequest: (node: HierarchyNodeInterface) => Promise<HierarchyNodeInterface[]>,
) => {
  selection
    .append('rect')
    .attr('class', 'CardPlaceholder')
    .attr('x', d => (d?.parent?.x ?? d.x) - HierarchyConsts.cardWidth / 2)
    .attr('y', d => (d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight)
    .call(call =>
      call
        .transition(t)
        .attr('x', d => d.x - HierarchyConsts.cardWidth / 2)
        .attr('y', d => d.y - HierarchyConsts.cardHeight),
    )
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)
    .on('mousedown', d => {
      if (d?.data?.reports?.length) {
        if (d.parent) {
          d.parent.data.zoomIn = true
        } else {
          d.data.zoomIn = true
        }
        d.data.reports = undefined
        requestAnimationFrame(() => {
          update(data)
        })
      } else if (d?.data?.reports_count) {
        const spinner = select('.Spinner').node() as SVGElement

        spinner?.setAttribute('y', `${d.y + 25}px`)
        spinner?.setAttribute('x', `${d.x - 7}px`)
        spinner?.classList.add('visible')

        onChildrenRequest(d.data).then(children => {
          if (children?.length) {
            d.data.reports = children
            d.data.zoomIn = true
            update(data)
          }
          spinner?.classList.remove('visible')
        })
      }
    })
}

export const updateClickableArea = (
  selection: UpdateSelection,
  t: HierarchyTransition,
) => {
  selection
    .select('.CardPlaceholder')
    .transition(t)
    .attr('x', d => d.x - HierarchyConsts.cardWidth / 2)
    .attr('y', d => d.y - HierarchyConsts.cardHeight)
    .attr('rx', HierarchyConsts.cardRadius)
    .attr('ry', HierarchyConsts.cardRadius)
    .attr('width', HierarchyConsts.cardWidth)
    .attr('height', HierarchyConsts.cardHeight)
    .attr('class', 'CardPlaceholder')
}
