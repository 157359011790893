import React from 'react'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionHeader'
import { Box, Text } from '@revolut/ui-kit'
import { InterviewResult, InterviewResultToTitle } from '@src/interfaces/interviewTool'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'

type Props = {
  disabled?: boolean
  titleIndexNumber: number
  suggestedResult?: InterviewResult
  options: RadioOption[]
  type?: InterviewScorecardType
}

const ResultHireSection = ({
  disabled,
  titleIndexNumber,
  suggestedResult,
  options,
  type,
}: Props) => {
  return (
    <>
      <SectionHeader
        title={`${titleIndexNumber}${
          type !== InterviewScorecardType.HiringPanel ? 'a' : ''
        }. How should we proceed?`}
        subtitle={
          type !== InterviewScorecardType.General &&
          type !== InterviewScorecardType.HiringPanel && (
            <>
              System recommendation:{' '}
              <Text fontWeight={600}>
                {suggestedResult ? InterviewResultToTitle[suggestedResult] : '-'}
              </Text>
            </>
          )
        }
      />
      <LapeNewRadioButtons
        name="result"
        options={options.map(o => ({ label: o.label, value: o.value.id }))}
        readOnly={disabled}
        renderRadio={radio => <Box py="s-8">{radio}</Box>}
      />
    </>
  )
}

export default ResultHireSection
