import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import SelectEmployeeTable from './SelectEmployeeTable'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import SelectFieldsPage from './SelectFieldsPage'
import ReviewChanges from './ReviewChanges'
import TrackProgress from './TrackProgress'

const EmployeesBulkEdit = () => {
  const backUrl = ROUTES.PEOPLE.EMPLOYEES

  return (
    <>
      <PageHeader pb="s-8" title="Bulk Edit" backUrl={backUrl} />
      <Switch>
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.SELECT}
          component={SelectEmployeeTable}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.EDIT}
          component={SelectFieldsPage}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.REVIEW}
          component={ReviewChanges}
        />
        <Route
          exact
          path={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.TRACK}
          component={TrackProgress}
        />
      </Switch>
    </>
  )
}

export default EmployeesBulkEdit
