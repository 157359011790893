import React from 'react'
import { Dropdown, MoreBar } from '@revolut/ui-kit'

import { hasOffboardingV2Enabled } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { canViewTerminate } from '../../common'
import { EmployeeButtonProps } from '../../types'

export const Terminate = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  if (!canViewTerminate(data)) {
    return null
  }

  const route = hasOffboardingV2Enabled(data)
    ? ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2
    : ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION

  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use={InternalLink}
        to={pathToUrl(route, { id: data.id, new: 'new' })}
      >
        Terminate
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      onClick={() => navigateTo(pathToUrl(route, { id: data.id, new: 'new' }))}
      variant="negative"
    >
      Terminate
    </MoreBar.Action>
  )
}
