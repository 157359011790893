import { Item, Image } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React from 'react'

interface Props {
  isRunning: boolean
  surveyId: number
  preDescription?: string
}

export const RunningSurveyBanner = ({ isRunning, preDescription, surveyId }: Props) => {
  if (!isRunning) {
    return null
  }
  return (
    <Item>
      <Item.Avatar>
        <Image src="https://assets.revolut.com/assets/3d-images-v2/3D021.png" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Changes won’t affect running survey</Item.Title>
        <Item.Description>
          {preDescription} If you want to change the end time of your running survey,
          please go{' '}
          <InternalLink
            to={pathToUrl(ROUTES.PERFORMANCE.ENGAGEMENT.DETAILS_OVERVIEW, {
              id: surveyId,
            })}
          >
            here
          </InternalLink>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
