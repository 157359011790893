import React from 'react'
import { Flex, Text, Token, HStack, chain, Color } from '@revolut/ui-kit'
import pluralize from 'pluralize'
import { FinalCountIssuesType } from '../PaycyclePreview'
import { RelevancyType } from './PaycycleItem'

type PaycycleTitleProps = {
  title: string
  count?: { issues: FinalCountIssuesType; changes: number }
  showRelevantOnly: RelevancyType
}
export const PaycycleTitle = ({ title, count, showRelevantOnly }: PaycycleTitleProps) => {
  if (
    (showRelevantOnly === 'all' && !count?.changes && !count?.issues.count) ||
    (showRelevantOnly === 'issue' && !count?.issues.count) ||
    (showRelevantOnly === 'change' && !count?.changes)
  ) {
    return null
  }
  const changeText = count?.changes ? (
    <Text color={Color.BLUE}>{pluralize('change', count?.changes, true)}</Text>
  ) : (
    'No changes'
  )
  const issueText = count?.issues.count ? (
    <Text
      color={
        count?.issues.issues_level === 'warning' ? Token.color.orange : Token.color.red
      }
    >
      {`${count?.issues.count} ${
        count?.issues.issues_level ? count.issues.issues_level : 'issues'
      }`}
    </Text>
  ) : null
  return (
    <Flex justifyContent="space-between" my="s-12">
      <Text use="h6" color="gray" fontWeight="bold">
        {title}
      </Text>
      <HStack use="h6" color="gray">
        {chain(issueText, changeText)}
      </HStack>
    </Flex>
  )
}
