import React from 'react'

import Form from '@src/features/Form/Form'
import PerformanceForm, { PerformanceFormProps } from './PerformanceReview'

const Performance = (props: PerformanceFormProps) => {
  return (
    <Form validator={{}}>
      <PerformanceForm {...props} />
    </Form>
  )
}

export default Performance
