import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Text, Color, InputGroup, Side } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'

export type Props = {
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
  refreshData?: () => void
}
const About = ({ dynamicGroups, isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <PageBody>
      <Text fontSize="caption" color={Color.GREY_TONE_50} mb="s-16">
        Write a few things about yourself, hobbies, fun facts, other things that you would
        like to share
      </Text>
      <NewStepperSectionCustomFields
        sectionId={SectionOptions.EmployeeProfile}
        subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU}
        dynamicGroups={dynamicGroups}
      >
        <InputGroup>
          <LapeNewTextArea name="about" label="Tell us something about yourself" />
        </InputGroup>
      </NewStepperSectionCustomFields>
      <ActionsWrapper>
        <NewSaveButtonWithPopup
          useValidator
          successText="Information was successfully updated"
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </PageBody>
  )
}

export default About
