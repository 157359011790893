import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const getEmployeePosition = (
  employee: EmployeeOptionInterface & {
    position: IdAndName
    specialisation: IdAndName
  },
) => {
  const isInternal = employee.employee_type === 'internal'

  return isInternal ? employee.position?.name : employee.specialisation?.name
}
