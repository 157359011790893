import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { candidateBulkEditRequests } from '@src/api/hiringProcess'
import { CandidateBulkEditInterface } from '@src/interfaces/hiringProccess'
import { ColumnCellInterface } from '@src/interfaces/data'
import { bulkActionsEditFieldsColumn } from '@src/constants/columns/bulkActions'

const columns: ColumnCellInterface<CandidateBulkEditInterface>[] = [
  {
    ...bulkActionsEditFieldsColumn,
    width: 140,
  },
]

const CandidateBulkEdit = () => {
  return (
    <BulkActions<CandidateBulkEditInterface>
      tableRequest={candidateBulkEditRequests}
      columns={columns}
      dataType="Candidate edit bulk actions"
    />
  )
}

export default CandidateBulkEdit
