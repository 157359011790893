import { EntityTypes } from '@src/constants/api'
import { FilterByInterface } from '@src/interfaces/data'
import { Statuses } from '@src/interfaces'
import {
  removeNotificationDepartmentKPIsToApprove,
  removeNotificationTeamKPIsToApprove,
} from '@src/api/kpiNotifications'

export const tableInitialFiltersRouter = (
  id: string | number,
  cycleOffset: number,
  entityType: EntityTypes,
): FilterByInterface[] => {
  const commonFilters: FilterByInterface[] = [
    {
      filters: [
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.requires_changes, id: Statuses.requires_changes },
      ],
      columnName: 'target_status',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [{ name: `${cycleOffset}`, id: cycleOffset }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
      nonInheritable: true,
    },
  ]

  switch (entityType) {
    case EntityTypes.team:
      return [
        ...commonFilters,
        {
          filters: [{ name: `${id}`, id }],
          columnName: 'team__id',
          nonResettable: true,
          nonInheritable: true,
        },
      ]
    case EntityTypes.department:
      return [
        ...commonFilters,
        {
          filters: [{ name: `${id}`, id }],
          columnName: 'department__id',
          nonResettable: true,
          nonInheritable: true,
        },
      ]
    default:
      return []
  }
}

export const removeNotificationRequestRouter = (entityType: EntityTypes) => {
  switch (entityType) {
    case EntityTypes.team:
      return removeNotificationTeamKPIsToApprove
    case EntityTypes.department:
    default:
      return removeNotificationDepartmentKPIsToApprove
  }
}
