import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { contractsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { ContractsIntro } from './Intro'
import { ContractsBulkUpload } from './BulkUpload'

const routes = [
  {
    title: 'Intro',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.INTRO,
    canView: [PermissionTypes.ViewContractsPreferences],
    component: ContractsIntro,
  },
  {
    title: 'Upload',
    subtitle: 'Import all your employee contracts here',
    path: ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.CONTRACTS.UPLOAD.IMPORT,
    canView: [PermissionTypes.ViewContractsPreferences],
    component: ContractsBulkUpload,
    hideActions: true,
    hidePageBody: true,
  },
]

export const Contracts = () => {
  return <OnboardingChecklistContent config={contractsConfig} routes={routes} />
}
