import React from 'react'
import HelpSections from '@src/pages/Forms/EmployeePerformance/components/HelpSections/HelpSections'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { Link } from '@revolut/ui-kit'

const ProbationDeliverablesHelp = () => {
  const { values } = useLapeContext<ReviewScorecardInterface>()

  return (
    <HelpSections
      sections={[
        `Please provide an overall assessment on all ${values.reviewed_employee.display_name}'s deliverables this cycle.`,
        <React.Fragment key={0}>
          To understand the ticket fetching rules{' '}
          <Link
            href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1698796813/Probation+2.0+Playbook#FAQs"
            rel="noopener norefferer"
            target="_blank"
          >
            click here
          </Link>
          )
        </React.Fragment>,
      ]}
    />
  )
}

export default ProbationDeliverablesHelp
