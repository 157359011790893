import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { HireInterface } from '@src/interfaces/requisitions'
import { selectorKeys } from '@src/constants/api'
import LinkIcon from '@components/ColumnInserts/LinkIcon/LinkIcon'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { IdStatuses } from '@src/interfaces/employees'
import { HStack, TextButton, Token } from '@revolut/ui-kit'
import { CancelHireAction } from '@src/pages/Forms/RequisitionForm/Hires/CancelHireAction'

export const hireNameColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.text,
  idPoint: 'full_name',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'full_name',
  selectorsKey: selectorKeys.all_employees,
  title: 'Name',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ANY, { id: data?.id }),
}

export const hireSpecialisationColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.text,
  idPoint: 'specialisation__name',
  dataPoint: 'specialisation__name',
  sortKey: null,
  filterKey: 'specialisation__name',
  selectorsKey: selectorKeys.specialisations,
  title: 'Role (Specialisation)',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, { id: data?.specialisation?.id }),
}

export const hireSeniorityColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority__name',
  dataPoint: 'seniority__name',
  sortKey: 'seniority__id',
  filterKey: 'seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const hireEmployeeProfileColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee profile',
  insert: ({ data }) => {
    return (
      <LinkIcon to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ANY, { id: data?.id })} />
    )
  },
}

export const hireCandidateProfileColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidate__id',
  dataPoint: 'candidate__id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidate profile',
  insert: ({ data }) => {
    if (!data?.candidate?.id) {
      return ''
    }
    return (
      <LinkIcon
        to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
          id: data?.candidate?.id,
        })}
      />
    )
  },
}

export const hireRecruiterColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.insert,
  idPoint: 'candidate__recruiter__id',
  dataPoint: 'candidate__recruiter__id',
  sortKey: null,
  filterKey: 'recruiter__name',
  selectorsKey: selectorKeys.all_employees,
  title: 'Recruiter',
  insert: ({ data }) => <UserWithAvatar {...(data?.candidate?.recruiter || {})} />,
}

export const hireStartDateColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: 'joining_date_time',
  filterKey: 'joining_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const hireStatusColumn: ColumnInterface<HireInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status,
  title: 'Status',
}

export const getHireActionColumn = (
  canMoveHire: boolean,
  canCancelHire: boolean,
  onRefresh: () => void,
  onMoveHire: (rowIndex: number) => void,
): ColumnInterface<HireInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data, parentIndexes }) => {
    if (!data.status) {
      return null
    }
    const showMoveHire =
      [IdStatuses.hired, IdStatuses.pending, IdStatuses.onboarding].includes(
        data.status,
      ) && canMoveHire
    return (
      <HStack gap="s-8">
        {showMoveHire && (
          <TextButton
            color={Token.color.blue}
            onClick={e => {
              e.stopPropagation()
              onMoveHire(parentIndexes[0])
            }}
          >
            Move hire
          </TextButton>
        )}
        {canCancelHire && <CancelHireAction hire={data} onRefresh={onRefresh} />}
      </HStack>
    )
  },
})
