import { api } from '@src/api'
import { API } from '@src/constants/api'
import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const employeeOnboardingTemplatesTableRequests: TableRequestInterface<EmployeeOnboardingTemplateInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(
        `${API.ONBOARDING}/templates`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v2',
      ),
  }

export const employeeOnboardingTemplateRequests: RequestInterfaceNew<EmployeeOnboardingTemplateInterface> =
  {
    get: async ({ id }) => api.get(`${API.ONBOARDING}/templates/${id}`, undefined, 'v2'),
    update: async (_, { id }, data) => {
      return api.patch(`${API.ONBOARDING}/templates/${id}`, data, undefined, 'v2')
    },
    submit: async data => {
      return api.post(`${API.ONBOARDING}/templates`, data, undefined, 'v2')
    },
    delete: async ({ id }) =>
      api.delete(`${API.ONBOARDING}/templates/${id}`, undefined, 'v2'),
  }
