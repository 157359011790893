import { format } from 'date-fns'
import { CREATE_ONE_TO_ONE_MEETING } from '@src/constants/externalLinks'
import { EmployeeInterface } from '@src/interfaces/employees'

export const getCreateNewMeetingLink = (
  employeeData?: EmployeeInterface,
  managerData?: EmployeeInterface,
) => {
  if (employeeData && managerData) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    const tomorrowStamp = format(tomorrow, 'yyyyMMdd')
    return `${CREATE_ONE_TO_ONE_MEETING}+${employeeData.first_name}+/+${managerData.first_name}&recur=RRULE:FREQ=WEEKLY&add=${employeeData.email},${managerData.email}&dates=${tomorrowStamp}T123000/${tomorrowStamp}T130000`
  }
  return `${CREATE_ONE_TO_ONE_MEETING}+Meeting`
}
