import React, { useEffect, useRef, useState } from 'react'
import {
  Spinner,
  StatusWidget,
  VStack,
  Text,
  Button,
  TableWidget,
  MoreBar,
  useStatusPopup,
  StatusPopup,
  BottomSheet,
  Header,
  IconButton,
  Input,
  InputGroup,
  Item,
  Token,
  Avatar,
  Box,
} from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { cloneDeep, get, isEmpty, unset, set } from 'lodash'
import produce from 'immer'

import { TableNames } from '@src/constants/table'
import {
  importDocumentsActionColumn,
  importDocumentsCategoryColumn,
  importDocumentsDocumentNameColumn,
  ImportDocumentSelectInputOnChange,
  importDocumentsEmployeeColumn,
  importDocumentsFormatColumn,
} from '@src/constants/columns/importDocuments'
import { useTable } from '@src/components/Table/hooks'
import {
  bulkDeleteDocuments,
  bulkUpdateDocuments,
  createDocumentUploadSessionDocuments,
  getDocumentUploadSessionTable,
  getDocumentUploadSessionTableStats,
  updateDocumentUploadSessionItem,
  useDocumentSessionSelector,
  useGetDocumentUploadSessionData,
} from '@src/api/importData'
import {
  ImportDocumentsItemInterface,
  ImportDocumentsSessionStatsInterface,
} from '@src/interfaces/importDocuments'
import { EditableRowInterface } from '@src/components/Table/EditableTable/EditableTable'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { useGetSelectors } from '@src/api/selectors'
import LapeEditableTable from '@src/components/Table/EditableTable/LapeEditableTable'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import PageLoading from '@src/components/PageLoading/PageLoading'
import SuccessWidget from '@src/components/SuccessWidget/SuccessWidget'
import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { FileInterface } from '@src/interfaces/files'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import SelectTableWrapper, {
  SelectionControls,
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import pluralize from 'pluralize'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { StatsConfig, useSelectableTableStats } from '@src/components/StatFilters/hooks'

const row = (
  onChange: ImportDocumentSelectInputOnChange,
  categoryOptions: IdAndName[],
  employeeOptions: IdAndName[],
  onDeleteRowSuccess: (rowId: number) => void,
  onPreviewSidebarOpen: (file: FileInterface) => void,
  sessionId: string,
  onNameChange: (rowId: number, value: string) => void,
  nameColumnOptions: IdAndName[],
): EditableRowInterface<ImportDocumentsItemInterface> => ({
  highlight: data => (isEmpty(data.errors) ? '' : Token.color.redActionBackground),
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...importDocumentsDocumentNameColumn(onNameChange, nameColumnOptions),
      width: 300,
    },
    {
      ...importDocumentsFormatColumn,
      width: 60,
    },
    {
      ...importDocumentsCategoryColumn(onChange, categoryOptions),
      width: 150,
    },
    {
      ...importDocumentsEmployeeColumn(onChange, employeeOptions),
      width: 150,
    },
    {
      ...importDocumentsActionColumn(onDeleteRowSuccess, onPreviewSidebarOpen, sessionId),
      width: 100,
    },
  ],
})

const statsConfig: StatsConfig<ImportDocumentsSessionStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'errors',
    title: 'Errors',
    filterKey: 'error',
    color: Token.color.danger,
  },
  {
    key: 'success',
    title: 'Success',
    filterKey: 'success',
    color: Token.color.success,
  },
]

interface DocumentsSessionTableFormProps {
  customActions?: (options: {
    refetch: () => void
    disabled: boolean
    onSubmit: () => Promise<any>
  }) => React.ReactNode
}

const DocumentsSessionTableForm = ({ customActions }: DocumentsSessionTableFormProps) => {
  const { values } = useLapeContext<{ data: ImportDocumentsItemInterface[] }>()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<ImportDocumentsItemInterface>>()
  const selectTableControls = useRef<SelectionControls<ImportDocumentsItemInterface>>()

  const [previewedFile, setPreviewedFile] = useState<FileInterface>()
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [deleteDocumentsPending, setDeleteDocumentsPending] = useState(false)
  const [showBulkEditPopup, setShowBulkEditPopup] = useState(false)
  const [hidden, setHidden] = useState(false)

  const statusPopup = useStatusPopup()

  const params = useParams<{ id: string }>()

  const { refetch } = useGetDocumentUploadSessionData(params.id)
  const documentOptions = useDocumentSessionSelector(params.id)

  const table = useTable({
    getItems: getDocumentUploadSessionTable(params.id),
    getStats: getDocumentUploadSessionTableStats(params.id),
  })

  const statFiltersProps = useSelectableTableStats<
    ImportDocumentsItemInterface,
    ImportDocumentsSessionStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'status',
    totalKey: 'total',
  })

  const { data: documentCategories = [] } = useGetSelectors<IdAndName>(
    selectorKeys.document_categories,
  )
  const { data: employees = [] } = useGetSelectors<IdAndName>(selectorKeys.employee)

  useEffect(() => {
    if (!values.data) {
      set(values, 'data', [])
    }
    set(values, 'data', cloneDeep(table.data))
    selectTableControls?.current?.resetState()
  }, [table.data, values])

  const mutateTableData = (rowData: ImportDocumentsItemInterface) => {
    table.setData(
      produce(table.data, draft => draft.map(r => (r.id === rowData.id ? rowData : r))),
    )
  }

  const onSelectInputChange: ImportDocumentSelectInputOnChange = (
    rowData,
    option,
    field,
  ) => {
    const rowDataIndex = values.data.findIndex(r => r.id === rowData.id)
    const initialField = get(rowData, field)
    set(rowData, field, option)
    unset(rowData, `errors.${field}`)

    updateDocumentUploadSessionItem(params.id, rowData.id, { [field]: option })
      .then(response => {
        mutateTableData(rowData)
        table.refreshStats()
        set(values.data, rowDataIndex, response.data)
      })
      .catch(() => set(rowData, field, initialField))
  }

  const onNameChange = (rowId: number, value: string) => {
    const rowDataIndex = values.data.findIndex(r => r.id === rowId)
    const rowData = values.data[rowDataIndex]

    if (rowData && rowData.name !== value) {
      unset(rowData, 'errors.name')

      updateDocumentUploadSessionItem(params.id, rowData.id, {
        name: value,
      })
        .then(response => {
          set(values.data, rowDataIndex, response.data)
          mutateTableData(response.data)
          table.refreshStats()
          documentOptions.refetch()
        })
        .catch(error => {
          if (error?.response?.data?.name) {
            set(rowData, 'errors.name', error?.response?.data?.name)
          }
        })
    }
  }

  const onDeleteRow = (rowId: number) => {
    set(
      values,
      'data',
      values.data.filter(r => r.id !== rowId),
    )

    documentOptions.refetch()
    table.refresh()
  }

  const onSubmit = async () => {
    try {
      const result = await createDocumentUploadSessionDocuments(params.id)
      return Promise.resolve(result.data)
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Can't create documents</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={() => statusPopup.hide()} elevated>
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
      throw error
    }
  }

  const onBulkEditSuccess = () => {
    documentOptions.refetch()
    table.refresh()
    setShowBulkEditPopup(false)
  }

  const onPreviewSidebarOpen = (file: FileInterface) => {
    setPreviewedFile(file)
  }

  const getSelectedItems = () => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData.map(r => ({ id: r.id }))
    }

    if (selectedData?.isAllSelected) {
      return documentOptions.options
        ?.filter(r => !selectedData.excludeListIds.has(`${r.id}`))
        .map(r => ({ id: r.id }))
    }

    return []
  }

  const disabled = !table.stats || table.stats?.errors > 0 || table.loading

  return (
    <>
      {!hidden && (
        <Box mb="s-16">
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Lightbulb" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Who will be able to see these documents?</Item.Title>
              <Item.Description>
                All documents uploaded here will be visible by HR managers & Admins. The
                employees will also be able to see their own documents.
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <IconButton
                color={Token.color.greyTone50}
                onClick={() => setHidden(true)}
                useIcon="Cross"
              />
            </Item.Side>
          </Item>
        </Box>
      )}
      <TableWidget>
        <TableWidget.Info>
          <StatFilters {...statFiltersProps} />
        </TableWidget.Info>
        <TableWidget.Actions>
          <MoreBar>
            <MoreBar.Action
              useIcon="Cross"
              disabled={!selectedData?.someSelected}
              onClick={() => setShowDeleteConfirmation(true)}
              variant="negative"
            >
              Remove selected
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => setShowBulkEditPopup(true)}
              disabled={!selectedData?.someSelected}
              useIcon="ChevronDown"
            >
              Bulk update
            </MoreBar.Action>
            <MoreBar.Action
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DOCUMENT_CATEGORIES.GENERAL)}
            >
              Create new document category
            </MoreBar.Action>
          </MoreBar>
        </TableWidget.Actions>
        <TableWidget.Table>
          <SelectTableWrapper
            enabled
            onChange={setSelectedData}
            filters={table.filterBy}
            tableDataLength={table.data.length}
            onControlsLoaded={controls => {
              selectTableControls.current = controls
            }}
          >
            <LapeEditableTable
              name={TableNames.BulkUploadDocuments}
              {...table}
              useWindowScroll
              dataFieldName="data"
              row={row(
                onSelectInputChange,
                documentCategories,
                employees,
                onDeleteRow,
                onPreviewSidebarOpen,
                params.id,
                onNameChange,
                documentOptions.options,
              )}
              enableSettings={false}
              lockFirstColumn={false}
              rowHeight="medium"
              replaceOnInitialDataChange
            />
          </SelectTableWrapper>
        </TableWidget.Table>
      </TableWidget>

      {customActions?.({
        refetch,
        disabled,
        onSubmit,
      }) || (
        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={() => refetch()}
            noPopup
            onClick={onSubmit}
            disabled={disabled}
          >
            Create documents
          </NewSaveButtonWithPopup>
        </PageActions>
      )}

      {previewedFile ? (
        <PreviewDocumentSidebar
          file={previewedFile}
          onClose={() => setPreviewedFile(undefined)}
        />
      ) : null}

      <ConfirmationDialog
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onReject={() => setShowDeleteConfirmation(false)}
        loading={deleteDocumentsPending}
        onConfirm={async () => {
          const items = getSelectedItems()

          try {
            setDeleteDocumentsPending(true)
            await bulkDeleteDocuments(params.id, items)
            documentOptions.refetch()
            table.refresh()
            setShowDeleteConfirmation(false)
          } catch (error) {
            statusPopup.show(
              <StatusPopup variant="error">
                <StatusPopup.Title>Failed to remove documents</StatusPopup.Title>
                <StatusPopup.Description>
                  {getStringMessageFromError(error)}
                </StatusPopup.Description>
                <StatusPopup.Actions>
                  <Button onClick={() => statusPopup.hide()} elevated>
                    Close
                  </Button>
                </StatusPopup.Actions>
              </StatusPopup>,
            )
          } finally {
            setDeleteDocumentsPending(false)
          }
        }}
        label="Are you sure you want to remove these documents?"
        body=""
        yesMessage="Confirm"
        noMessage="Cancel"
      />

      <BulkEditPopup
        open={showBulkEditPopup}
        onClose={() => setShowBulkEditPopup(false)}
        items={getSelectedItems()}
        documentCategoryOptions={documentCategories}
        employeeOptions={employees}
        onSuccess={onBulkEditSuccess}
      />
    </>
  )
}

interface BulkEditPopupProps {
  open: boolean
  onClose: () => void
  items: { id: number }[]
  documentCategoryOptions: IdAndName[]
  employeeOptions: IdAndName[]
  onSuccess: () => void
}

const BulkEditPopup = ({
  open,
  onClose,
  items,
  documentCategoryOptions,
  employeeOptions,
  onSuccess,
}: BulkEditPopupProps) => {
  const params = useParams<{ id: string }>()

  const statusPopup = useStatusPopup()

  const [name, setName] = useState('')
  const [category, setCategory] = useState<IdAndName | null>(null)
  const [employee, setEmployee] = useState<IdAndName | null>(null)

  const [updatePending, setUpdatePending] = useState(false)

  const onSubmit = () => {
    setUpdatePending(true)

    const updateData = items.map(i => ({
      id: i.id,
      employee: employee ? { id: employee.id } : undefined,
      category: category ? { id: category.id } : undefined,
      name: name || undefined,
    }))

    bulkUpdateDocuments(params.id, updateData)
      .then(() => {
        onSuccess()
        setUpdatePending(false)
      })
      .catch(error => {
        setUpdatePending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button elevated>Close</Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Bulk Update</Header.Title>
        <Header.Description>
          You are about to update Name, Category, Assignee for{' '}
          {pluralize('docuemnt', items.length, true)}
        </Header.Description>
      </Header>

      <InputGroup>
        <Input
          label="Document name (optional)"
          value={name}
          onChange={e => setName(e.currentTarget.value)}
        />
        <RadioSelectInput
          label="Category (optional)"
          value={category}
          onChange={value => setCategory(value)}
          options={documentCategoryOptions.map(value => ({ label: value.name, value }))}
        />
        <RadioSelectInput
          label="Assignee (optional)"
          value={employee}
          onChange={value => setEmployee(value)}
          options={employeeOptions.map(value => ({ label: value.name, value }))}
        />
      </InputGroup>

      <BottomSheet.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          disabled={!name && !employee && !category}
          elevated
          pending={updatePending}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

const DocumentsSessionTable = connect((props: DocumentsSessionTableFormProps) => (
  <Form>
    <DocumentsSessionTableForm {...props} />
  </Form>
))

interface DocumentsSessionInterface extends DocumentsSessionTableFormProps {
  customSuccessActions?: React.ReactNode
}

export const DocumentsSession = ({
  customSuccessActions,
  customActions,
}: DocumentsSessionInterface) => {
  const params = useParams<{ id: string }>()

  const { data } = useGetDocumentUploadSessionData(params.id)

  if (!data) {
    return <PageLoading />
  }

  if (data.state.id === 'applying') {
    return (
      <PageBody>
        <StatusWidget>
          <StatusWidget.Title>
            <VStack align="center" space="s-16" pt="s-16">
              <Spinner color="blue" size={60} />
              <Text>Upload in progress</Text>
            </VStack>
          </StatusWidget.Title>
          <StatusWidget.Description>
            Please wait while the files are being processed
          </StatusWidget.Description>
        </StatusWidget>
      </PageBody>
    )
  }

  if (data.state.id === 'success') {
    return (
      <>
        <PageBody>
          <SuccessWidget
            title="Upload successful"
            type="success"
            text="Documents data imported successfully"
            maxWidth="100%"
          />
        </PageBody>

        {customSuccessActions || (
          <PageActions>
            <Button onClick={() => goBack(ROUTES.APPS.DOCUMENTS.DOCUMENTS)} elevated>
              Done
            </Button>
          </PageActions>
        )}
      </>
    )
  }

  if (data.state.id === 'failure') {
    return (
      <PageBody>
        <SuccessWidget
          title="Task failed!"
          type="error"
          text="There was an error with the upload"
          maxWidth="100%"
        />
      </PageBody>
    )
  }

  return <DocumentsSessionTable customActions={customActions} />
}
