import React from 'react'
import { Link, Text, Box } from '@revolut/ui-kit'
import { PERFORMANCE_REVIEW_GUIDE } from '@src/constants/externalLinks'

const GradesInfo = () => {
  return (
    <Box>
      <Text use="div" pb="s-16">
        Your final grade is the result of your manager(s) reviews and calibration from the
        Head of Department, the Head of Function and the Performance Review Committee.
      </Text>
      <Text>
        For more details on grading, refer to{' '}
        <Link href={PERFORMANCE_REVIEW_GUIDE} target="_blank" rel="noreferrer noopener">
          this confluence page
        </Link>
        .
      </Text>
    </Box>
  )
}

export default GradesInfo
