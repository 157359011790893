import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/TableV2/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { copyToClipboard, Flex } from '@revolut/ui-kit'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { TableNames } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'
import { notificationPlaceholderNameColumn } from '@src/constants/columns/notificationPlaceholders'
import { getNotificationPlaceholders } from '@src/api/notificationTemplate'
import { TableTypes } from '@src/interfaces/table'

const Row: RowInterface<IdAndName> = {
  cells: [
    {
      ...notificationPlaceholderNameColumn,
      width: 100,
    },
  ],
}

const PlaceholdersTable = () => {
  const table = useTable<IdAndName>({
    getItems: getNotificationPlaceholders,
  })

  const onRowClick = async (row: IdAndName) => {
    await copyToClipboard(row.name)

    pushNotification({
      value: 'Placeholder copied to clipboard',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  }

  return (
    <Flex flexDirection="column" width="100%">
      <AdjustableTable<IdAndName>
        name={TableNames.NotificationPlaceholders}
        type={TableTypes.Contained}
        useWindowScroll
        row={Row}
        {...table}
        onRowClick={onRowClick}
        noDataMessage="Placeholders will appear here."
        hideCountAndButtonSection
      />
    </Flex>
  )
}

export default PlaceholdersTable
