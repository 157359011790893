import React, { useEffect, useState } from 'react'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Box, TabBarProps } from '@revolut/ui-kit'
import { TabsInterface } from '@src/interfaces/data'
import { useLocation } from 'react-router-dom'

interface Props extends TabBarProps {
  tabs: TabsInterface<any>[]
}

const ViewNavigation = ({ tabs, ...rest }: Props) => {
  const [tabsWithQuery, setTabsWithQuery] = useState<TabsInterface<any>[]>()
  const location = useLocation()

  useEffect(() => {
    setTabsWithQuery(
      tabs.map(tab => ({
        ...tab,
        to: { pathname: tab.to as string, search: location.search },
      })),
    )
  }, [location.search, tabs])

  return (
    <Box mb="s-16">
      <TabBarNavigation tabs={tabsWithQuery} {...rest} />
    </Box>
  )
}

export default ViewNavigation
