import React from 'react'
import {
  InterviewRoundRatingExpectation,
  InterviewRoundSummaryRatingInterface,
} from '@src/interfaces/interviewTool'
import { Box, Flex, Icon, Tag, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import upperFirst from 'lodash/upperFirst'
import {
  convertOperators,
  getOnlineTestFromSummary,
} from '@src/pages/Forms/Candidate/Performance/utils'
import { PerformanceRating } from '@src/interfaces/performance'

const getTagColor = (rating?: string) => {
  switch (convertOperators(rating, '', '')) {
    case 'unsatisfactory':
    case 'poor':
    case 'developing':
    case 'basic':
      return Token.color.greyTone50
    case 'performing':
    case 'intermediate':
      return Token.color.teal_30
    case 'exceeding':
    case 'advanced':
      return Token.color.teal_50
    case 'exceptional':
    case 'expert':
    case 'pass':
      return Token.color.teal
    case 'fail':
      return Token.color.red
    default:
      return Token.color.neutral
  }
}

type RatingTagProps = {
  rating?: PerformanceRating | string
}

const RatingTag = ({ rating }: RatingTagProps) => {
  return (
    <Tag color={getTagColor(rating || '')}>
      {upperFirst(convertOperators(rating || 'Not assessed'))}
    </Tag>
  )
}

type ExpectationIconProps = {
  assessed?: InterviewRoundRatingExpectation
}

const ExpectationIcon = ({ assessed }: ExpectationIconProps) => {
  switch (assessed) {
    case 'above':
      return <Icon name="ArrowThinUp" color={Token.color.success} size={16} />
    case 'below':
      return <Icon name="ArrowThinDown" color={Token.color.warning} size={16} />
    case 'equal':
      return <Icon name="Check" color={Token.color.greyTone50} size={16} />
    default:
      return <Icon name="Dot" color={Token.color.greyTone50} size={16} />
  }
}

type ExpectationProps = ExpectationIconProps & {
  expected?: PerformanceRating
}

const Expectation = ({ assessed, expected }: ExpectationProps) => {
  const tooltip = useTooltip()
  return (
    <Box {...tooltip.getAnchorProps()}>
      <ExpectationIcon assessed={assessed} />
      <Tooltip {...tooltip.getTargetProps()}>
        {!expected
          ? 'No expectation defined'
          : assessed
          ? upperFirst(`${assessed === 'equal' ? 'meets' : assessed} expectation`)
          : 'Not assessed'}
      </Tooltip>
    </Box>
  )
}

type ResultTagProps = {
  data: InterviewRoundSummaryRatingInterface
}

export const ResultTag = ({ data }: ResultTagProps) => {
  const onlineTest = getOnlineTestFromSummary(data)
  if (onlineTest) {
    const isPassed = onlineTest.extra_fields?.[0]?.is_passed
    return (
      <Flex gap="s-4" alignItems="center" justifyContent="end">
        <RatingTag rating={isPassed !== undefined ? (isPassed ? 'pass' : 'fail') : ''} />
      </Flex>
    )
  }
  return (
    <Flex gap="s-4" alignItems="center" justifyContent="end">
      <RatingTag rating={data?.average_rating} />
      <Expectation
        assessed={data?.comparison_to_expected}
        expected={data?.expected_competency}
      />
    </Flex>
  )
}
