import React from 'react'
import { StatusPopup } from '@revolut/ui-kit'

type Props = {
  open: boolean
  onClose: () => void
}

const MrtSuccessPopup = ({ open, onClose }: Props) => {
  return (
    <StatusPopup
      variant="success"
      open={open}
      onClose={onClose}
      // @ts-expect-error
      labelButtonClose="Close success popup"
    >
      <StatusPopup.Title>Marked MRT questionnaire as complete</StatusPopup.Title>
    </StatusPopup>
  )
}

export default MrtSuccessPopup
