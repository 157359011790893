import React from 'react'
import styled from 'styled-components'
import { CommentsPopupJustification, Ratings } from '@src/interfaces/performance'
import { Box, Flex, Text, Widget } from '@revolut/ui-kit'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { getReviewerTitleAndRating } from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

const Comment = styled.div`
  line-height: 22px;
  white-space: pre-line;
  word-break: break-word;
  margin-top: 16px;
`

interface Props {
  justification: CommentsPopupJustification
  ratings?: Ratings[]
  children: React.ReactNode
  isSelfComments?: boolean
}

const CommentsPopupCard = ({
  justification,
  ratings,
  children,
  isSelfComments,
}: Props) => {
  const user = useSelector(selectUser)
  const reviewerInfo = getReviewerTitleAndRating(justification.review, ratings)
  const reviewer = justification.review.reviewer

  const { rating, title } = reviewerInfo

  return (
    <Widget p="s-16" mb="s-20">
      <Flex justifyContent="space-between">
        <Text fontSize="primary" fontWeight={500}>
          {title}
        </Text>
        {isSelfComments ? (
          <UserWithAvatar
            mr="s-8"
            size={24}
            id={user.id}
            name={user.full_name}
            avatar={user.avatar}
            compact
          />
        ) : (
          <UserWithAvatar
            mr="s-8"
            size={24}
            id={reviewer.id}
            name={reviewer.full_name}
            avatar={reviewer.avatar}
            compact
          />
        )}
      </Flex>
      {rating && (
        <Box mt="s-4">
          <PerformanceRatingLabelTag rating={rating} fontSize="primary" />
        </Box>
      )}
      <Comment>{children}</Comment>
    </Widget>
  )
}

export default CommentsPopupCard
