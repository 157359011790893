import React from 'react'
import { Box, Token } from '@revolut/ui-kit'

import { getPayCycleCommentsAPI } from '@src/api/payrollV2'
import PageLoading from '@src/components/PageLoading/PageLoading'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { CommonTabProps } from './common'

export const PayCycleTabComments = ({ selectedCycle }: CommonTabProps) => {
  const payCycleId = selectedCycle?.id

  if (!payCycleId) {
    return <PageLoading />
  }
  const commentsApi = getPayCycleCommentsAPI(payCycleId)

  return (
    <Box maxWidth={Token.breakpoint.md}>
      <CommentsSection api={commentsApi} withFileUploader withInfiniteLoading title="" />
    </Box>
  )
}
