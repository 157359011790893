import { DaySlotGroupInterface } from '@components/DaySlotsGroup/DaySlotsGroup'
import {
  AvailableCandidateSlots,
  AvailableInterviewerSlot,
  DayPotentialSlotGroupInterface,
  PotentialInterviewerSlots,
} from '@src/interfaces/interviewTool'
import { format, isSameDay } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { useMemo } from 'react'

export const useGroupByDaySlots = (
  timeZone?: string,
  slots?: AvailableInterviewerSlot[] | AvailableCandidateSlots[],
) =>
  useMemo(() => {
    if (!slots || !timeZone) {
      return []
    }
    const sorted = [...slots].sort(
      (a, b) =>
        new Date(a.event_start_datetime).getTime() -
        new Date(b.event_start_datetime).getTime(),
    )

    const result: DaySlotGroupInterface[] = []

    sorted.forEach(item => {
      const timeZonedDate = utcToZonedTime(new Date(item.event_start_datetime), timeZone)
      const foundDay = result.find(day => isSameDay(new Date(day.id), timeZonedDate))

      if (!foundDay) {
        result.push({
          id: timeZonedDate.toDateString(),
          label: format(timeZonedDate, 'EEEE, d MMMM'),
          items: [item],
        })
      } else {
        foundDay.items.push(item)
      }
    })

    return result
  }, [slots, timeZone])

export const useGroupByDayPotentialSlots = (
  timeZone?: string,
  slots?: PotentialInterviewerSlots[],
) => {
  if (!slots || !timeZone) {
    return []
  }
  const sorted = [...slots].sort(
    (a, b) => new Date(a.slot_start).getTime() - new Date(b.slot_start).getTime(),
  )

  const result: DayPotentialSlotGroupInterface[] = []

  let previousDate: Date
  sorted.forEach(item => {
    const timeZonedDate = utcToZonedTime(new Date(item.slot_start), timeZone)
    if (previousDate && isSameDay(previousDate, timeZonedDate)) {
      result[result.length - 1].items.push(item)
    } else {
      result.push({
        id: timeZonedDate.toDateString(),
        label: format(timeZonedDate, 'EEEE, d MMMM'),
        items: [item],
      })
    }
    previousDate = timeZonedDate
  })

  return result
}
