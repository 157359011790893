import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Bar, Box, InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'

import { selectorKeys } from '@src/constants/api'
import { EmployeeInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import { employeesRequests } from '@src/api/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SalarySacrificeInterface } from '@src/interfaces/rewards'
import { salarySacrificeRequests } from '@src/api/rewards'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { DeleteButtonLape } from '../FormButtons'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import EmployeeDocSectionLape from '@src/features/FormTabs/Employee/EmployeeDocSectionLape'
import { navigateReplace } from '@src/actions/RouterActions'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const General = () => {
  const { values } = useLapeContext<SalarySacrificeInterface>()
  const [employee, setEmployee] = useState<EmployeeInterface>()
  const { employeeId } = useParams<{ employeeId: string }>()

  useEffect(() => {
    fetchEmployees()
  }, [])

  const fetchEmployees = async () => {
    const { data } = await employeesRequests.getItem(employeeId)
    setEmployee(data)
  }

  let title
  if (values.id) {
    title = `${values.salary_sacrifice_schema?.name} · ${values.status?.name}`
  } else {
    title = 'New salary sacrifice'
  }

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE, {
    id: employeeId,
  })

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={backUrl} />

      <PageBody>
        <Bar>
          {values.id && (
            <DeleteButtonLape
              data={values}
              deleteApi={salarySacrificeRequests.delete!}
              title="salary sacrifice"
              backUrl={backUrl}
            />
          )}
        </Bar>
        <AutoStepper>
          <NewStepperTitle title="Salary sacrifice" />
          <InputGroup>
            <LapeNewInput
              label="Total Sacrificed amount"
              name="sacrificed_amount"
              type="number"
              required
              message="Total sacrificed amount depending on deduction type"
            />
            <LapeRadioSelectInput
              label="Currency"
              name="sacrifice_currency"
              selector={selectorKeys.currencies}
            />
            <LapeNewInput
              label="Number of options"
              name="number_of_shares"
              type="number"
              required
              message="Amount of shares granted from salary sacrifice"
            />

            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                label="Start date"
                name="start_date"
                required
                message="Effective date of the salary sacrifice"
              />
              <LapeDatePickerInput
                label="End date"
                name="end_date"
                required
                message="End date of the salary sacrifice"
              />
            </InputGroup>

            <LapeDatePickerInput
              label="Opt out date"
              name="opt_out_date"
              message="End date if employee decided to stop salary sacrifice early, before the initial end date"
            />
            <LapeRadioSelectInput
              name="salary_sacrifice_schema"
              label="Salary sacrifice schema"
              selector={selectorKeys.salary_sacrifice_schemas}
            />
            <LapeRadioSelectInput
              name="deduction_type"
              label="Deduction type"
              selector={selectorKeys.salary_deduction_types}
            />
          </InputGroup>

          <Box mb="s-24">
            <NewStepperTitle title="Documents" />
            <EmployeeDocSectionLape
              initialValues={{
                confidentiality: { id: 'hr_manager', name: 'HR Manager' },
                category: { id: 12, name: 'SSS Options grant' },
              }}
              employee={employee}
            />
          </Box>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={data => {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.EMPLOYEE_SALARY_SACRIFICE.GENERAL, {
                id: data.id,
                employeeId,
              }),
            )
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={salarySacrificeRequests}>
    <General />
  </Form>
))
