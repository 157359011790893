import React from 'react'
import { committeeResultRequests } from '@src/api/pip'
import Form from '@src/features/Form/Form'
import CommitteeResultForm from '@src/pages/Forms/PipCommittee/General'

const PipCommittee = () => {
  return (
    <Form api={committeeResultRequests}>
      <CommitteeResultForm />
    </Form>
  )
}

export default PipCommittee
