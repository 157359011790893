import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  Header,
  Popup,
  StatusPopup,
  Text,
  Token,
  Tooltip,
  VStack,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { PageBody } from '@src/components/Page/PageBody'
import HiringStagesList from '@src/pages/OnboardingChecklist/HiringProcess/components/HiringStagesList'
import { bulkCreateHiringProcess, useGetHiringStages } from '@src/api/hiringProcess'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { ROUTES } from '@src/constants/routes'
import {
  updateOnboardingCheckpointCategoryCurrentStep,
  useGetOnboardingCheckpointCategory,
} from '@src/api/onboardingChecklist'
import { StepCompletedWidget } from '@src/pages/OnboardingChecklist/components/StepCompletedWidget'
import { hiringProcessConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateTo } from '@src/actions/RouterActions'

type SubmitDefaultHiringProcessProps = {
  hiringProcessRounds: HiringProcessInterface[]
  stepIsCompleted: boolean
}

const SubmitDefaultHiringProcess = ({
  hiringProcessRounds,
  stepIsCompleted,
}: SubmitDefaultHiringProcessProps) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const statusPopup = useStatusPopup()
  const showLoadingPopup = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>
          Applying hiring process rounds to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const navigateToNextStep = () => {
    navigateTo(ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.CUSTOMISE_HIRING_PROCESS)
  }
  const showSuccessPopup = () => {
    statusPopup.show(
      <StatusPopup
        variant="success"
        onClose={async () => {
          await updateOnboardingCheckpointCategoryCurrentStep(
            hiringProcessConfig.category,
            'Default hiring process',
          )
          navigateToNextStep()
        }}
      >
        <StatusPopup.Title>
          Successfully applied hiring process rounds to specialisations
        </StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showErrorPopup = () => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>
          Error applying hiring process rounds to specialisations
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={statusPopup.hide}>Close</Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const handleSubmit = async () => {
    setLoadingSubmit(true)
    setOpenConfirmation(false)
    showLoadingPopup()
    try {
      await bulkCreateHiringProcess(hiringProcessRounds.map(({ id }) => id))
      showSuccessPopup()
    } catch (e) {
      showErrorPopup()
    } finally {
      setLoadingSubmit(false)
    }
  }
  const disabled = loadingSubmit || hiringProcessRounds.length < 1
  const tooltip = useTooltip()
  return (
    <>
      <Box {...tooltip.getAnchorProps()}>
        <Button
          onClick={() => {
            if (stepIsCompleted) {
              navigateToNextStep()
            } else {
              setOpenConfirmation(true)
            }
          }}
          disabled={stepIsCompleted ? false : disabled}
        >
          Next
        </Button>
      </Box>
      {disabled && (
        <Tooltip {...tooltip.getTargetProps()}>Must have at least one stage</Tooltip>
      )}
      {openConfirmation && (
        <Popup
          open
          variant="bottom-sheet"
          onClose={() => {
            setOpenConfirmation(false)
          }}
        >
          <Header>
            <Header.Title>Are you sure?</Header.Title>
            <Header.Description>
              Default hiring process can only be applied once.
            </Header.Description>
          </Header>
          <Popup.Actions horizontal>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenConfirmation(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleSubmit()
              }}
            >
              Submit
            </Button>
          </Popup.Actions>
        </Popup>
      )}
    </>
  )
}

const DefaultHiringProcess = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeHiringProcessPreferences,
  )
  const {
    data: hiringProcessRoundsData,
    isLoading: isLoadingHiringStages,
    refetch,
  } = useGetHiringStages()
  const [hiringProcessRounds, setHiringProcessRounds] = useState<
    HiringProcessInterface[]
  >([])
  const [excludeHiringProcessRounds, setExcludeHiringProcessRounds] = useState<number[]>(
    [],
  )
  const currentHiringRounds = hiringProcessRounds.filter(
    ({ id }) => !excludeHiringProcessRounds.includes(id),
  )
  useEffect(() => {
    if (hiringProcessRoundsData?.results) {
      setHiringProcessRounds(hiringProcessRoundsData.results)
    }
  }, [hiringProcessRoundsData])
  const [applyDefaultHiringProcess, setApplyDefaultHiringProcess] = useState(true)
  const handleApplyDefaultHiringProcess = (value: boolean) => {
    if (hiringProcessRoundsData?.results) {
      if (value) {
        setHiringProcessRounds(hiringProcessRoundsData.results)
      } else {
        const firstCVScreeningStage = hiringProcessRoundsData.results.find(
          ({ stage_type }) => stage_type.id === 'cv_screening',
        )
        setExcludeHiringProcessRounds([])
        setHiringProcessRounds([firstCVScreeningStage!])
      }
    }
    setApplyDefaultHiringProcess(value)
  }
  const { data: checkpoint } = useGetOnboardingCheckpointCategory(
    hiringProcessConfig.category,
  )
  const stepIsCompleted =
    checkpoint?.current_step !== null && checkpoint?.current_step !== 'Intro'

  if (isLoadingHiringStages) {
    return <PageLoading />
  }
  return (
    <>
      <PageBody>
        {stepIsCompleted ? (
          <StepCompletedWidget />
        ) : (
          <VStack gap="s-16">
            <SectionTitle
              title="Do you want to generate and apply a default hiring process?"
              subtitle="You can generate a default hiring processes and apply it to all specialisations you have in the system. In the next step, you will be given the opportunity to adjust individual specialisations."
            />
            <BooleanRadioSwitch
              disabled={disableEdit}
              value={applyDefaultHiringProcess}
              variant="horizontal"
              yesLabel={
                <>
                  Yes
                  <Recommended />
                </>
              }
              yesDescription="Create hiring stages and apply them to all specialisations"
              noLabel="No, I want to start from scratch"
              noDescription="Independently create hiring process for every specialisation you have"
              onChange={handleApplyDefaultHiringProcess}
            />
            <Text color={Token.color.greyTone50} pb="s-16" use="div" variant="caption">
              This hiring process will be applied to all specialisations in the system.
              You can add new stages or remove existing ones to make it fit your needs.
              Once generated and applied, individual specialisations can be adjusted at
              the next stage of the onboarding process.
            </Text>
            <HiringStagesList
              disableAddStageButton={!applyDefaultHiringProcess}
              isSpecialisation={false}
              hiringProcessRounds={currentHiringRounds}
              onDelete={id => {
                setExcludeHiringProcessRounds([...excludeHiringProcessRounds, id])
              }}
              onOrderChange={setHiringProcessRounds}
              onRefresh={refetch}
            />
          </VStack>
        )}
      </PageBody>
      <PageActions>
        <Grid columns={2} gap="s-8" maxWidth={375}>
          <Button
            variant="secondary"
            onClick={() => {
              navigateTo(ROUTES.ONBOARDING_CHECKLIST.HIRING_PROCESS.INTRO)
            }}
          >
            Back
          </Button>
          <SubmitDefaultHiringProcess
            hiringProcessRounds={currentHiringRounds}
            stepIsCompleted={stepIsCompleted}
          />
        </Grid>
      </PageActions>
    </>
  )
}

export default DefaultHiringProcess
