import React, { useState } from 'react'
import { PromotionStageInterface } from '@src/interfaces/promotions'
import { Button, Header, MoreBar, Popup, StatusPopup, TextButton } from '@revolut/ui-kit'
import { ArrowBulk } from '@revolut/icons'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { delegatePromotionStage } from '@src/api/promotions'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'

interface Props {
  nominationId: number
  stage: PromotionStageInterface
  nominatedEmployee: { id: number; full_name: string }
  variant: 'button' | 'text'
  onSuccess?: () => void
  onStatusPopupClose?: () => void
}

const DelegateButton = ({
  nominationId,
  stage,
  nominatedEmployee,
  variant,
  onSuccess,
  onStatusPopupClose,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [openFormPopup, setOpenFormPopup] = useState<boolean>(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false)

  const [selectedEmployee, setSelectedEmployee] =
    useState<EmployeeOptionInterface | null>(null)

  const onFormPopupClose = () => {
    setOpenFormPopup(false)
  }

  const delegate = async () => {
    setIsSubmitting(true)
    try {
      await delegatePromotionStage(nominationId, stage.id, selectedEmployee?.id || 0)
      onFormPopupClose()
      onSuccess?.()
      setShowSuccessPopup(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const onSuccessPopupClose = () => {
    setShowSuccessPopup(false)
    setSelectedEmployee(null)
    onStatusPopupClose?.()
  }

  const onClick = () => {
    setSelectedEmployee(null)
    setOpenFormPopup(true)
  }

  let button
  if (variant === 'text') {
    button = <TextButton onClick={onClick}>Delegate</TextButton>
  } else {
    button = (
      <MoreBar.Action useIcon={ArrowBulk} onClick={onClick}>
        Delegate
      </MoreBar.Action>
    )
  }

  const sameEmployeeError =
    selectedEmployee && selectedEmployee?.id === nominatedEmployee.id
  const stageName = stage.stage_type.name.toLowerCase()

  return (
    <>
      {button}

      <Popup
        open={openFormPopup}
        variant="bottom-sheet"
        onClose={() => setOpenFormPopup(false)}
      >
        <Header variant="bottom-sheet">
          <Header.Title>Delegate {stageName}</Header.Title>
          <Header.Description>
            You are delegating {stageName} of promotion nomination of{' '}
            {nominatedEmployee.full_name}. The below employee will be able to submit{' '}
            {stageName} and view the nomination progress of the nominated employee
          </Header.Description>
        </Header>
        <RadioSelectInput<EmployeeOptionInterface>
          value={selectedEmployee}
          selector={selectorKeys.employee}
          onChange={value => setSelectedEmployee(value)}
          label="Delegated employee"
          hasError={!!sameEmployeeError}
          message={
            sameEmployeeError
              ? 'Delegated and nominated employee can not be the same'
              : undefined
          }
        />
        <Popup.Actions horizontal>
          <Button onClick={onFormPopupClose} variant="secondary">
            Cancel
          </Button>
          <Button onClick={delegate} pending={isSubmitting}>
            Submit
          </Button>
        </Popup.Actions>
      </Popup>

      <StatusPopup
        variant="success"
        open={showSuccessPopup}
        onClose={onSuccessPopupClose}
      >
        <StatusPopup.Title>Delegated to {selectedEmployee?.full_name}</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default DelegateButton
