import React from 'react'

import { useTable } from '@components/Table/hooks'
import {
  RiskControlsInterface,
  RiskIncidentsInterface,
  RiskIndicatorInterface,
  RiskStatsInterface,
  RiskSummaryInterface,
  RiskTabs,
} from '@src/interfaces/risk'
import {
  getRiskControls,
  getRiskControlsStats,
  getRiskIncidents,
  getRiskIncidentsStats,
  getRiskIndicators,
  getRiskIndicatorsStats,
  getRiskSummary,
  getRiskSummaryStats,
} from '@src/api/risk'
import RiskTab from '@src/pages/Forms/CommonRiskTab/RiskTab'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import RiskCircles, {
  statsToKri,
} from '@components/ColumnInserts/RiskCircles/RiskCircles'
import { useParams } from 'react-router-dom'
import Summary from '@src/pages/Forms/CommonRiskTab/Summary'
import Controls from '@src/pages/Forms/CommonRiskTab/Controls'
import Indicators from '@src/pages/Forms/CommonRiskTab/Indicators'
import Incidents from '@src/pages/Forms/CommonRiskTab/Incidents'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useCanView } from '@src/pages/Team/helpers'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import Karma from '@src/pages/Forms/DepartmentForm/Karma/Karma'
import { useGetOrganisationSettings } from '@src/api/settings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useIsNewTable } from '@components/TableV2/hooks'

interface Props {
  data: DepartmentInterface
}

const Risk = ({ data }: Props) => {
  const isNewTable = useIsNewTable()
  const params = useParams<{ id: string; subtab?: string }>()
  const { query } = useQuery()
  const { data: settings } = useGetOrganisationSettings()
  const canView = useCanView(data)

  const karmaEnabled = !!settings?.karma?.enabled

  const initialFilter = [
    {
      filters: [{ name: data.id.toString(), id: data.id }],
      columnName: 'department__id',
      nonResettable: true,
      nonInheritable: true,
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'risk_level_order',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const summaryTable = useTable<RiskSummaryInterface, RiskStatsInterface>(
    {
      getItems: getRiskSummary,
      getStats: getRiskSummaryStats,
    },
    initialFilter,
    initialSortBy,
    { disable: !canView.canViewRisk },
  )

  const indicatorTable = useTable<RiskIndicatorInterface, RiskStatsInterface>(
    {
      getItems: getRiskIndicators,
      getStats: getRiskIndicatorsStats,
    },
    initialFilter,
    initialSortBy,
    { disable: !canView.canViewRisk },
  )

  const incidentTable = useTable<RiskIncidentsInterface, RiskStatsInterface>(
    {
      getItems: getRiskIncidents,
      getStats: getRiskIncidentsStats,
    },
    initialFilter,
    initialSortBy,
    { disable: !canView.canViewRisk },
  )

  const controlTable = useTable<RiskControlsInterface, RiskStatsInterface>(
    {
      getItems: getRiskControls,
      getStats: getRiskControlsStats,
    },
    initialFilter,
    initialSortBy,
    { disable: !canView.canViewRisk },
  )

  const tabs = [
    {
      title: 'Summary',
      path: ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY, params),
      component: <Summary table={summaryTable} data={data} useWrapper={false} />,
      quickSummary: summaryTable.stats !== undefined && (
        <RiskCircles data={statsToKri(summaryTable.stats)} />
      ),
      canView: canView.canViewRisk,
    },
    {
      title: 'Risks and controls',
      path: ROUTES.FORMS.DEPARTMENT.RISK.CONTROLS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.CONTROLS, params),
      component: <Controls table={controlTable} data={data} useWrapper={false} />,
      quickSummary: controlTable.stats !== undefined && (
        <RiskCircles data={statsToKri(controlTable.stats)} />
      ),
      canView: canView.canViewRisk,
    },
    {
      title: 'Risk Indicators',
      path: ROUTES.FORMS.DEPARTMENT.RISK.INDICATORS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INDICATORS, params),
      component: <Indicators table={indicatorTable} useWrapper={false} />,
      quickSummary: indicatorTable.stats !== undefined && (
        <RiskCircles data={statsToKri(indicatorTable.stats)} />
      ),
      canView: canView.canViewRisk,
    },
    {
      title: 'Risk Incidents',
      path: ROUTES.FORMS.DEPARTMENT.RISK.INCIDENTS,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INCIDENTS, params),
      component: <Incidents table={incidentTable} useWrapper={false} />,
      quickSummary: incidentTable.stats !== undefined && (
        <RiskCircles data={statsToKri(incidentTable.stats)} />
      ),
      canView: canView.canViewRisk,
    },
    {
      title: 'Karma',
      path: ROUTES.FORMS.DEPARTMENT.RISK.KARMA,
      to: pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.KARMA, params),
      component: <Karma data={data} />,
      quickSummary: <QuickSummaryCount count={data.karma} />,
      canView: !isNewTable && karmaEnabled,
    },
  ].filter(({ canView: canViewTab }) => canViewTab)

  if (query.tab || params?.subtab === undefined) {
    switch (query.tab) {
      case RiskTabs.risk_indicators:
        return (
          <InternalRedirect
            to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INDICATORS, params)}
          />
        )
      case RiskTabs.risk_incidents:
        return (
          <InternalRedirect
            to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.INCIDENTS, params)}
          />
        )
      case RiskTabs.summary:
      default:
        return (
          <InternalRedirect
            to={pathToUrl(ROUTES.FORMS.DEPARTMENT.RISK.SUMMARY, params)}
          />
        )
    }
  }

  return <RiskTab tabs={tabs} wrapNavigation />
}

export default Risk
