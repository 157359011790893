import { MoreBar } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import React from 'react'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'

type ArchiveOpportunityActionProps = {
  canEditRound: boolean
  round?: InterviewRoundInterface
}

export const ArchiveOpportunityAction = ({
  canEditRound,
  round,
}: ArchiveOpportunityActionProps) => {
  const { setActiveAction } = useCandidateProfileContext()
  if (!(round?.latest_interview_stage && canEditRound && !round.archived_reason)) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="Archive"
      variant="negative"
      onClick={() =>
        setActiveAction({
          type: 'archive',
        })
      }
    >
      Archive opportunity
    </MoreBar.Action>
  )
}
