import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import {
  Avatar,
  Box,
  chain,
  HStack,
  Item,
  ItemSkeleton,
  MoreBar,
  Text,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { useGetCandidateCancelledStages } from '@src/api/recruitment/interviewerScheduling'
import { formatDateTime } from '@src/utils/format'
import { getInitials } from '@src/utils/employees'
import { ArchiveOpportunityAction } from '@src/pages/Forms/Candidate/CandidateActions/ArchiveOpportunity'
import { SnoozeAction } from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'

type CandidateCancelledSchedulingProps = {
  candidate?: CandidateInterface
  canEditRound: boolean
  round?: InterviewRoundInterface
  stages?: InterviewStageWithoutRoundInterface[]
  onRefresh: () => void
  onScheduleInterview: (data: InterviewStageWithoutRoundInterface) => void
}

export const CandidateCancelledScheduling = ({
  candidate,
  canEditRound,
  round,
  stages,
  onRefresh,
  onScheduleInterview,
}: CandidateCancelledSchedulingProps) => {
  const { data, isLoading } = useGetCandidateCancelledStages(round?.id)
  const candidateCancelledStages =
    data?.filter(({ is_cancelled_by_candidate }) => is_cancelled_by_candidate) || []
  if (isLoading) {
    return <ItemSkeleton />
  }
  if (!candidateCancelledStages?.length || !candidate) {
    return null
  }
  return (
    <VStack data-testId="candidateCancelledScheduling" gap="s-16">
      {candidateCancelledStages.map(
        ({
          interview_stage,
          event_date_time,
          candidate_cancellation_notes,
          cancellation_date_time,
        }) => {
          const { id, title } = interview_stage
          const stage = stages?.find(s => s.id === id)
          return (
            <Widget key={id}>
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="ExclamationTriangle" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>
                    <FormattedMessage
                      id="recruitment.candidateProfile.candidateCancelledScheduling.title"
                      defaultMessage="Candidate cancelled the {stageTitle} interview for {scheduledDate}"
                      values={{
                        stageTitle: title,
                        scheduledDate: event_date_time
                          ? formatDateTime(event_date_time)
                          : undefined,
                      }}
                    />
                  </Item.Title>
                  {!!candidate_cancellation_notes && (
                    <Item.Description>
                      <FormattedMessage
                        id="recruitment.candidateProfile.candidateCancelledScheduling.description"
                        defaultMessage="Comment from candidate:"
                      />
                    </Item.Description>
                  )}
                </Item.Content>
              </Item>
              <Box pl="s-72" pb="s-16">
                <VStack gap="s-8">
                  {!!candidate_cancellation_notes && (
                    <>
                      <HStack align="center" gap="s-8">
                        <Avatar
                          variant="brand"
                          image={getInitials(candidate.full_name)}
                          size={24}
                        />
                        <Box>
                          {cancellation_date_time ? (
                            chain(
                              <Text>{candidate.full_name}</Text>,
                              event_date_time ? (
                                <Text color={Token.color.greyTone50}>
                                  {formatDateTime(cancellation_date_time)}
                                </Text>
                              ) : undefined,
                            )
                          ) : (
                            <Text>{candidate.full_name}</Text>
                          )}
                        </Box>
                      </HStack>
                      <Text>{candidate_cancellation_notes}</Text>
                    </>
                  )}
                  <Box mt={candidate_cancellation_notes ? 's-16' : undefined}>
                    <MoreBar labelMoreButton="Candidate cancellation more actions">
                      <ArchiveOpportunityAction
                        canEditRound={canEditRound}
                        round={round}
                      />
                      <SnoozeAction candidate={candidate} onAfterSubmit={onRefresh} />
                      <MoreBar.Action
                        variant="primary"
                        onClick={() => {
                          if (stage) {
                            onScheduleInterview(stage)
                          }
                        }}
                      >
                        Schedule interview
                      </MoreBar.Action>
                    </MoreBar>
                  </Box>
                </VStack>
              </Box>
            </Widget>
          )
        },
      )}
    </VStack>
  )
}
