import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import PageLoading from '@components/PageLoading/PageLoading'

import { CreateEmployee } from './Forms/CreateEmployee'
import { EmployeeProfileLayout } from './Layout'
import { EmployeeForm as EmployeeProfileLayoutObsolete } from './EmployeeForm'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

export const EmployeeForm = () => {
  const newEmployee = useRouteMatch(ROUTES.FORMS.EMPLOYEE.NEW)
  const isNewLayout = useIsNewLayout()

  if (newEmployee) {
    return (
      <PageWrapper>
        <PageHeader title="New employee" backUrl={backUrl} />
        <CreateEmployee />
      </PageWrapper>
    )
  }
  if (isNewLayout === undefined) {
    return <PageLoading />
  }
  return isNewLayout ? <EmployeeProfileLayout /> : <EmployeeProfileLayoutObsolete />
}
