import React from 'react'

import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'

type Props = {
  values: DocumentsTemplatesInterface
}
export const Header = ({ values }: Props) => {
  return (
    <PageHeader
      title={values.id ? values.name : 'New document template'}
      subtitle={values.id ? 'Edit document template' : undefined}
      backUrl={
        values.id
          ? pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, { id: String(values.id) })
          : ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST
      }
      routerAction={PageHeaderRouterAction.navigate}
    />
  )
}
