import React from 'react'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { RightToWork } from '../../EmployeeProfile/Preview/ProfileSummary/components/RightToWork'
import { CompleteStageButton, SkippedStageWidget, SLAProgress, TabProps } from './common'

export const RightToWorkStage = (props: TabProps) => {
  return (
    <>
      <PageBody mt="s-16">
        <SkippedStageWidget status={props.stage.status.id} />

        <SLAProgress
          elapsedSla={props.stage.sla}
          sla={props.stage.stage.sla_in_hours}
          caption="Record and verify right to work."
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        <RightToWork employeeId={props.data.employee.id} hideTableRoute />
      </PageBody>

      <PageActions>
        <CompleteStageButton {...props} />
      </PageActions>
    </>
  )
}
