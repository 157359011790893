import React, { useEffect, useState } from 'react'

import { CookieConsentModal } from '@src/pages/Landing/components/CookieConsent/CookieConsentModal'
import { useCookieConsentContext } from '@src/pages/Landing/hooks/useCookieConsentContext'

interface CookieConsentProps {
  disableCookieConsent: boolean
}

export const CookieConsent = ({ disableCookieConsent }: CookieConsentProps) => {
  const { isBannerDisplayed } = useCookieConsentContext()

  const [canShowCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    setShowCookieBanner(true)
  }, [])

  if (!isBannerDisplayed && canShowCookieBanner && !disableCookieConsent) {
    return <CookieConsentModal />
  }

  return null
}
