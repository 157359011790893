import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import {
  directReportsOwnershipTransfer,
  getDirectReportsOwnership,
} from '@src/api/ownership'
import {
  employeeNameColumn,
  employeeNameIdStatusColumn,
} from '@src/constants/columns/employee'
import Table from '@components/TableV2/Table'
import { locationNameColumn } from '@src/constants/columns/location'
import { roleNameColumn } from '@src/constants/columns/role'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { teamNameColumn } from '@src/constants/columns/team'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'

import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const DirectReportsOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    directReportsOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        line_manager: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getDirectReportsOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer direct reports to a new line manager"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Direct reports" val={stats?.direct_reports_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                {
                  ...employeeNameColumn,
                  width: 200,
                },
                {
                  ...roleNameColumn,
                  width: 130,
                },
                {
                  ...seniorityNameColumn,
                  width: 100,
                },
                {
                  ...teamNameColumn,
                  width: 200,
                },
                {
                  ...locationNameColumn,
                  width: 140,
                },
                {
                  ...employeeNameIdStatusColumn,
                  width: 120,
                },
              ]}
              ownerColTitle="New Line Manager"
              noDataMessage="Direct reports ownership will appear here"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default DirectReportsOwnership
