import React from 'react'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'

const DEFAULT_COMPANY_ID = 1

export const Engagement = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Company,
        id: DEFAULT_COMPANY_ID,
        permissions: data?.field_options.permissions,
      }}
    />
  )
}
