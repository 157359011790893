import React, { useEffect, useMemo, useState } from 'react'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableTypes } from '@src/interfaces/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { PerformanceSelector, ReviewCategory } from '@src/interfaces/performance'
import { DeliverableInterface } from '@src/interfaces/deliverables'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { Queries } from '@src/constants/api'
import { getProbationJiraTickets, getProbationTickets } from '@src/api/probationReview'
import produce from 'immer'
import RefreshButton from '../../Probation/RefreshButton'
import {
  Avatar,
  Box,
  Ellipsis,
  Flex,
  Grid,
  Icon,
  Link,
  Text,
  Token,
} from '@revolut/ui-kit'
import { getIconKeyByIssue } from '@src/utils/performance'
import {
  probationReviewDueColumn,
  probationReviewGoalColumn,
} from '@src/constants/columns/probation'
import { CellInsertParams, RowInterface } from '@src/interfaces/data'
import Tooltip from '@components/Tooltip/Tooltip'
import { formatDate } from '@src/utils/format'
import { useQuery } from '@src/utils/queryParamsHooks'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { EmployeeGoalsCardTable } from '../../EmployeePerformanceLayout/components/EmployeeGoalsCardTable'
import { GradesMapInterface } from '@src/utils/grades'
import { NameIdInterface } from '@src/interfaces/requisitions'
import { KPIsCardTable } from '../../EmployeePerformanceLayout/components/KPIsCardTable'

const legacyGoalRow = (jiraEnabled = true): RowInterface<DeliverableInterface> => ({
  cells: [
    {
      ...probationReviewGoalColumn,
      title: jiraEnabled ? 'Jira goal' : 'Goal',
      insert: ({ data }: CellInsertParams<DeliverableInterface>) => {
        const iconType = getIconKeyByIssue(data.issue_type)
        return jiraEnabled ? (
          <Grid gap="s-8" placeItems="center" columns="auto auto 1fr">
            <Avatar useIcon={iconType} />
            <Link href={data.url} target="_blank" rel="noopener norefferer">
              {data.key}
              <Text color={Token.color.foreground}>:</Text>
            </Link>
            <Ellipsis>
              <Text>{data.title}</Text>
            </Ellipsis>
          </Grid>
        ) : (
          <Flex alignItems="center" gap="s-8">
            <Text>{data.title}</Text>
            {data.description && (
              <Tooltip placement="top" text={data.description}>
                <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
              </Tooltip>
            )}
          </Flex>
        )
      },
      width: 100,
    },
    {
      ...probationReviewDueColumn,
      insert: ({ data }: CellInsertParams<DeliverableInterface>) => {
        return <Box>{data.due_date ? formatDate(data.due_date) : '-'}</Box>
      },
      width: 30,
    },
  ],
})

interface Props {
  employeeId: string
  selectedCycle: PerformanceSelector | undefined
  isViewMode: boolean
  category: ReviewCategory | undefined
  checkpointNum: number | undefined
  gradesMap: GradesMapInterface
  team: NameIdInterface | undefined
}

export const GoalsWidget = ({
  selectedCycle,
  employeeId,
  isViewMode,
  category,
  checkpointNum,
  gradesMap,
  team,
}: Props) => {
  const [legacyGoals, setLegacyGoals] = useState<DeliverableInterface[]>()
  const [loading, setLoading] = useState(false)
  const { query } = useQuery()

  const { data: performanceSettings } = useGetPerformanceSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const pipJiraDisabled = featureFlags.includes(
    FeatureFlags.PipGoalsJiraIntegrationDisabled,
  )
  const isPIP = category === ReviewCategory.PIP || category === ReviewCategory.PIP_V2

  const jiraIntegrationEnabled =
    performanceSettings?.enable_probation_and_pip_goals_via_jira &&
    (category !== ReviewCategory.PIP_V2 || !pipJiraDisabled)

  const filters = useMemo(() => {
    const base = [
      {
        filters: [{ name: `${employeeId}`, id: `${employeeId}` }],
        columnName: 'employee__id',
      },
    ]

    if (selectedCycle?.probation_reviews_deliverables_type === 'jira') {
      const filterCategory = category === ReviewCategory.PIP_V2 ? 'PIP' : category
      base.push({
        filters: [{ name: filterCategory || '', id: filterCategory || '' }],
        columnName: 'category',
      })
    }
    if (
      selectedCycle?.probation_reviews_deliverables_type === 'goal' ||
      selectedCycle?.probation_reviews_deliverables_type === 'kpi' ||
      isPIP
    ) {
      base.push({
        filters: [{ name: `${selectedCycle?.id}`, id: `${selectedCycle?.id}` }],
        columnName: 'review_cycle__id',
      })
    }
    return base
  }, [selectedCycle?.probation_reviews_deliverables_type])

  const fetchLegacyGoalsTickets = async () => {
    if (employeeId && category) {
      try {
        setLoading(true)
        const { data } = await getProbationJiraTickets(
          category,
          Number(employeeId),
          isViewMode ? query[Queries.CheckpointNumber] : checkpointNum,
          isViewMode ? query[Queries.ReviewerId] : undefined,
        )

        let jiraConsideredGoals = data.goals ? [...data.goals] : []
        if (jiraIntegrationEnabled === false && employeeId) {
          const { data: tickets } = await getProbationTickets(Number(employeeId), filters)
          if (tickets?.results.length) {
            jiraConsideredGoals = produce(tickets.results, draft => {
              draft.forEach(item => {
                item.title = item.name || ''
                item.due_date = item.deadline_date_time
              })
            })
          }
        }

        setLegacyGoals(jiraConsideredGoals)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    if (isPIP || selectedCycle?.probation_reviews_deliverables_type === 'jira') {
      fetchLegacyGoalsTickets()
    }
  }, [
    selectedCycle?.probation_reviews_deliverables_type,
    query[Queries.CheckpointNumber],
    query[Queries.ReviewerId],
    jiraIntegrationEnabled,
    employeeId,
    isPIP,
  ])

  if (
    (isPIP || selectedCycle?.probation_reviews_deliverables_type === 'jira') &&
    legacyGoals
  ) {
    return (
      <>
        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable<DeliverableInterface>
              name={TableNames.ProbationGoals}
              type={TableTypes.Contained}
              dataType="Goal"
              row={legacyGoalRow(jiraIntegrationEnabled)}
              data={legacyGoals}
              count={legacyGoals.length}
              emptyState={<EmptyTableRaw title="No Goals defined" />}
              hideCount
              loading={loading}
            />
          </Table.Widget.Table>
        </Table.Widget>
        {jiraIntegrationEnabled && <RefreshButton employeeId={Number(employeeId)} />}
      </>
    )
  }

  if (selectedCycle?.probation_reviews_deliverables_type === 'goal') {
    return (
      <EmployeeGoalsCardTable
        gradesMap={gradesMap}
        employeeId={Number(employeeId)}
        cycle={selectedCycle}
        noWidget
        team={team}
        showBanner
      />
    )
  }

  if (selectedCycle?.probation_reviews_deliverables_type === 'kpi') {
    return (
      <KPIsCardTable
        gradesMap={gradesMap}
        employeeId={Number(employeeId)}
        cycle={selectedCycle}
        noWidget
        team={team}
        showBanner
      />
    )
  }

  return null
}
