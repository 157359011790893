import React from 'react'
import { Cell, AvatarSkeleton, Flex, Skeleton, Box, HStack } from '@revolut/ui-kit'
import { CellProps } from '@revolut/ui-kit/types/dist/components/Cell/Cell'

interface Props extends CellProps {
  count?: number
}

const MultipleDetailsSkeleton = ({ count = 5, ...props }: Props) => {
  return (
    <Box {...props}>
      <Cell mt="s-16" display="block" data-testid="loading-skeleton">
        {new Array(count).fill(0).map((_, i) => (
          <Flex key={i} alignItems="center" justifyContent="flex-start" p="s-16">
            <Skeleton height={16} width={145} mr="s-32" />
            <Skeleton height={16} width={175} mr="s-24" />
            <HStack gap="s-8" align="center">
              <AvatarSkeleton size={24} />
              <AvatarSkeleton size={24} />
            </HStack>

            <Skeleton height={16} width={35} ml="140px" />
          </Flex>
        ))}
      </Cell>
    </Box>
  )
}

export default MultipleDetailsSkeleton
