import React from 'react'
import Statistic from '@components/Stat/Stat'
import { Flex, Text } from '@revolut/ui-kit'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { PerformanceRating } from '@src/interfaces/performance'

interface Props {
  performance?: number
  rating?: PerformanceRating | null
  ratingLabel?: string
  label?: string
}

const KpiStatistic = ({
  performance,
  rating,
  ratingLabel = 'System rating',
  label,
}: Props) => {
  return (
    <Flex alignItems="center">
      <Statistic
        label={label || 'Overall progress'}
        val={
          performance !== undefined ? (
            <Text use="div" mr="5px" color={getPercentColor((performance || 0) * 100)}>
              {formatPercentage(performance || 0)}
            </Text>
          ) : undefined
        }
        mr="s-16"
      />
      {rating && (
        <Statistic
          label={ratingLabel}
          val={<PerformanceRatingLabelTag rating={rating} />}
        />
      )}
    </Flex>
  )
}

export default KpiStatistic
