import React from 'react'
import { VStack } from '@revolut/ui-kit'
import EntitiesTable from '@src/pages/People/PeopleSubTabs/Entities/EntitiesTable'
import Message from '../components/Message'

const LocationsLegal = () => {
  return (
    <VStack gap="s-16">
      <Message
        title="We have created Legal Entities just for you."
        description="We've put together a list of Legal Entities based on your earlier input. Feel free to take a look and make any adjustments to suit your preferences."
      />
      <EntitiesTable />
    </VStack>
  )
}

export default LocationsLegal
