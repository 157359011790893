import React from 'react'
import { Action, Box, Color, Flex, HStack, Text, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PreviewQuestion } from './PreviewScorecardQuestion'
import Tags from '@components/Tags/Tags'
import { ReviewCyclesScorecardSectionInterface } from '@src/interfaces/reviewCycles'

type PreviewSectionProps = {
  data?: ReviewCyclesScorecardSectionInterface
  onClickEdit?: (section: ReviewCyclesScorecardSectionInterface) => void
  onClickDelete?: (section: ReviewCyclesScorecardSectionInterface) => void
  isCurrentSection: boolean
}

export const PreviewSection = ({
  data,
  onClickEdit,
  onClickDelete,
  isCurrentSection,
}: PreviewSectionProps) => {
  const { values } = useLapeContext<ReviewCyclesScorecardSectionInterface>()
  const section = isCurrentSection ? values : data

  return (
    <Widget width="100%" display="block" p="s-24" data-testid="preview-section">
      <Box>
        <Flex justifyContent="space-between" alignItems="start">
          <Text fontWeight={500} fontSize="h5" use="div">
            {section?.title || 'New section'}
          </Text>
          <HStack space="s-8">
            {onClickEdit && (
              <Action
                onClick={() => {
                  if (section) {
                    onClickEdit(section)
                  }
                }}
              >
                Edit
              </Action>
            )}
            {onClickDelete && (
              <Action
                onClick={() => {
                  if (section) {
                    onClickDelete(section)
                  }
                }}
              >
                Delete
              </Action>
            )}
          </HStack>
        </Flex>
        {section?.description && (
          <Box my="s-8">
            <Text variant="secondary">{section?.description}</Text>
          </Box>
        )}
        {section?.show_for ? (
          <Box mt="s-8">
            <Tags
              tags={section.show_for}
              title="Shown for"
              variant="outlined"
              color={Color.DEEP_GREY}
            />
          </Box>
        ) : null}
      </Box>
      {section?.questions?.map((_, questionIdx) => (
        <PreviewQuestion
          key={questionIdx}
          questionIdx={questionIdx}
          data={section}
          isCurrentSection={isCurrentSection}
        />
      ))}
    </Widget>
  )
}
