import React from 'react'
import Tooltip from '@components/Tooltip/Tooltip'
import { Icon, Token } from '@revolut/ui-kit'

export const TabTooltip = ({ text }: { text: string }) => {
  return (
    <Tooltip placement={'top'} text={text}>
      <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
    </Tooltip>
  )
}
