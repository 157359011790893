import React from 'react'
import { connect } from 'lape'
import { Box } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { isHtmlPreviewEnabled } from '@src/pages/Forms/NotificationTemplate'
import LapeNewCodeEditor from '@components/Inputs/LapeFields/LapeNewCodeEditor'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  NotificationTemplateInterface,
  NotificationType,
} from '@src/interfaces/notificationTemplate'
import { useLapeContext } from '@src/features/Form/LapeForm'

const templateTextFieldProps = {
  name: 'template_text',
  placeholder: 'Notification template text',
}

const templateTextFieldMessage =
  'To include template tags use the following format: {template_tag_name}: Ex: Hi {employee_name}, ...'

const TemplateTypeFields = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()

  const { options: templateTypesOptions, asyncState: templateTypesAsyncState } =
    useFetchOptions<OptionInterface>(selectorKeys.templated_notification_template_types)

  const templateTypes = templateTypesOptions.filter(option => {
    if (values.type?.id === NotificationType.sms) {
      return option.value.id === 'text'
    }

    if (values.type?.id === NotificationType.slack) {
      return option.value.id !== 'html'
    }

    return true
  })

  return (
    <>
      <LapeRadioSelectInput
        name="template_type"
        label="Notification template type"
        options={templateTypes}
        disabled={templateTypesAsyncState !== 'ready'}
        required
      />
      {isHtmlPreviewEnabled(values) ? (
        <Box data-testid="html_editor">
          <LapeNewCodeEditor
            {...templateTextFieldProps}
            bottomInfo={templateTextFieldMessage}
            language="html"
          />
        </Box>
      ) : (
        <LapeNewTextArea
          {...templateTextFieldProps}
          message={templateTextFieldMessage}
          required
        />
      )}
    </>
  )
}

export default connect(TemplateTypeFields)
