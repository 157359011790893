import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { ShareIOs } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import {
  requestOfReportsEmployeeColumn,
  requestOfReportsPolicyColumn,
  requestOfReportsTeamColumn,
  timeOffRequestApprovalColumn,
  timeOffRequestApprovedOnColumn,
  timeOffRequestDurationColumn,
  timeOffRequestEndDateColumn,
  timeOffRequestRequestedOnColumn,
  timeOffRequestStartDateColumn,
  timeOffRequestStatusColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getRequestsOfReports, getRequestsOfReportsStats } from '@src/api/timeOff'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { RequestOfReportsInterface } from '@src/interfaces/timeOff'
import Stat from '@src/components/Stat/Stat'
import { TableNames } from '@src/constants/table'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const ROW: RowInterface<RequestOfReportsInterface> = {
  linkToForm: ({ id, employee: { id: employeeId } }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, { id, employeeId }),
    ),
  cells: [
    {
      ...requestOfReportsEmployeeColumn,
      width: 160,
    },
    {
      ...requestOfReportsTeamColumn,
      width: 120,
    },
    {
      ...requestOfReportsPolicyColumn,
      width: 80,
    },
    {
      ...timeOffRequestStartDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestEndDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestDurationColumn,
      width: 80,
    },
    {
      ...timeOffRequestRequestedOnColumn,
      width: 120,
    },
    {
      ...timeOffRequestApprovedOnColumn,
      width: 120,
    },
    {
      ...timeOffRequestApprovalColumn,
      width: 100,
    },
    {
      ...timeOffRequestStatusColumn,
      width: 100,
    },
  ],
}

const noDataMessage = 'Requests which you need to review will appear here'
const pendingRequestsStatsLabel = 'Pending requests'

const WithoutUser = () => {
  return (
    <>
      <Flex mb="s-16">
        <Stat label={pendingRequestsStatsLabel} val={undefined} mr="s-32" />
      </Flex>
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable
          name={TableNames.TimeOffRequests}
          useWindowScroll
          row={ROW}
          loading
          count={0}
          data={[]}
          noDataMessage={noDataMessage}
        />
      </Flex>
    </>
  )
}

interface WithUserProps {
  id: number
}

const WithUser = ({ id }: WithUserProps) => {
  const table = useTable(
    {
      getItems: getRequestsOfReports(id),
      getStats: getRequestsOfReportsStats(id),
    },
    [],
    [
      {
        sortBy: 'approval_status_sorter',
        nonResettable: true,
      },
    ],
  )

  const permissions = useSelector(selectPermissions)

  const canImportRequests = permissions.includes(
    PermissionTypes.AddTimeOffRequestBulkUpload,
  )

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label={pendingRequestsStatsLabel}
          val={table?.stats ? table.stats?.pending_request_count : undefined}
          mr="s-32"
        />
      </Table.Widget.Info>
      {canImportRequests && (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.TIME_OFF_BALANCES.IMPORT)}
              useIcon={ShareIOs}
            >
              Import balance adjustments
            </MoreBar.Action>
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.TIME_OFF.UPLOAD_REQUESTS_FILE)}
              useIcon={ShareIOs}
            >
              Import time off requests
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.TimeOffRequests}
          dataType="request"
          useWindowScroll
          row={ROW}
          {...table}
          noDataMessage={noDataMessage}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const TimeOffRequests = () => {
  const user = useSelector(selectUser)

  return (
    <Flex flexDirection="column" width="100%">
      {user.id ? <WithUser id={user.id} /> : <WithoutUser />}
    </Flex>
  )
}
