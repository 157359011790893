import { CareersApplicationInterface } from '@src/interfaces/careers'
import produce from 'immer'
import { formatDate } from '@src/utils/format'
import { OptionInterface } from '@src/interfaces/selectors'
import { parseCareersAnalyticsCookie } from '@src/pages/Careers/hooks/useCareersAnalytics'
import { COOKIE } from '@src/constants/api'
import { cookiesApi } from '@src/utils/cookies'

export const getFormData = (
  data: CareersApplicationInterface,
  postingId: string,
): FormData => {
  const normalizedData = produce(data, draft => {
    draft.work_experiences = draft.work_experiences?.map(item => ({
      ...item,
      company: item.company?.id ? item.company : null,
      other_company_name: item.company?.id ? undefined : item.company?.name,
      position: item.position?.id ? item.position : null,
      other_position_title: item.position?.id ? undefined : item.position?.name,
      start_date: formatDate(item.start_date, 'yyyy-MM-dd'),
      end_date: item.end_date ? formatDate(item.end_date, 'yyyy-MM-dd') : null,
    }))

    draft.educations = draft.educations?.map(item => ({
      ...item,
      start_date: formatDate(item.start_date, 'yyyy-MM-dd'),
      end_date: formatDate(item.end_date, 'yyyy-MM-dd'),
    }))

    // for some reason the BE requires all options for some types of questions in the request. The issue is it could be too big and cause 413 error.
    // That's why we just send empty options for dropdown questions - it works fine
    draft.additional_questions?.sections.forEach(section => {
      section.questions.forEach(question => {
        if (question.internal_data_type === 'dropdown_application_form_question') {
          question.options = []
        }
      })
    })
  })

  const formData = new FormData()
  formData.append('posting_id', postingId)

  const analyticsParsed = parseCareersAnalyticsCookie(
    cookiesApi.get(COOKIE.COOKIE_CAREERS_ANALYTICS),
  )
  formData.append(
    'utm_data',
    JSON.stringify({ ...(analyticsParsed?.utm || {}), new_application_flow: true }),
  )

  if (analyticsParsed?.referrer) {
    formData.append('referrer_website', analyticsParsed.referrer)
  }

  const entries = Object.entries(normalizedData) as Array<
    [
      keyof CareersApplicationInterface,
      CareersApplicationInterface[keyof CareersApplicationInterface],
    ]
  >

  for (const [key, value] of entries) {
    if (value !== undefined) {
      if (
        key === 'additional_questions' ||
        key === 'work_experiences' ||
        key === 'educations'
      ) {
        formData.append(key, JSON.stringify(value))
      } else if (key === 'file') {
        formData.append(key, value as File)
      } else if (key === 'gender' || key === 'ethnicity' || key === 'country') {
        formData.append(`${key}.id`, String((value as OptionInterface).id))
      } else if (key === 'preferred_locations' && Array.isArray(value)) {
        value.forEach(val => formData.append(key, (val as OptionInterface).name))
      } else if (Array.isArray(value)) {
        value.forEach(val => formData.append(key, val as string))
      } else {
        formData.append(key, String(value))
      }
    }
  }

  return formData
}
