import React, { useEffect, useState } from 'react'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { seniorityNameColumn } from '@src/constants/columns/seniority'
import { FilterButton } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import { lineManagerColumn } from '@src/constants/columns/employee'
import { roleNameColumn } from '@src/constants/columns/role'
import { locationNameColumn } from '@src/constants/columns/location'
import { getProbationItems, getProbationStatistics } from '@src/api/probationEmployees'
import { ProbationInterface } from '@src/interfaces/probation'
import {
  functionalManagerColumn,
  locationHrManagerColumn,
  probationCheckpointColumn,
  probationCheckpointDeadlineColumn,
  probationEmployeeNameColumn,
  probationEndDateColumn,
  probationPendingActionsColumn,
} from '@src/constants/columns/probation'
import {
  pipEndDateColumn,
  pipStartDateColumn,
  pipVersionColumn,
} from '@src/constants/columns/pip'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { CheckpointType } from '@src/interfaces/probationReview'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'
import { getPipItems, getPipStatistics } from '@src/api/pip'
import { startOfToday, endOfToday, addWeeks, formatISO } from 'date-fns'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const getRow = (isPip: boolean) => {
  return {
    hideAddButton: true,
    cells: [
      {
        ...probationEmployeeNameColumn,
        width: 200,
      },
      {
        ...seniorityNameColumn,
        width: 140,
      },
      {
        ...roleNameColumn,
        width: 200,
      },
      {
        ...locationNameColumn,
        width: 100,
      },
      ...(!isPip
        ? [
            {
              ...probationEndDateColumn,
              width: 140,
            },
          ]
        : [
            {
              ...pipStartDateColumn,
              width: 140,
            },
            {
              ...pipEndDateColumn,
              width: 140,
            },
          ]),
      {
        ...lineManagerColumn,
        title: 'LM',
        width: 70,
      },
      {
        ...functionalManagerColumn,
        width: 70,
      },
      {
        ...probationCheckpointColumn,
        width: 180,
      },
      {
        ...probationCheckpointDeadlineColumn,
        width: 140,
      },
      {
        ...probationPendingActionsColumn,
        width: 203,
      },
      {
        ...locationHrManagerColumn,
        width: 180,
      },
      ...(isPip
        ? [
            {
              ...pipVersionColumn,
              width: 140,
            },
          ]
        : []),
    ],
  }
}

const getProbationUrl = (
  employeeId: number,
  cycleId: string | number,
  checkpointType: CheckpointType,
  isPip: boolean,
  checkpointNumber?: number,
) => {
  let probationUrl = ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS
  let pipUrl = ROUTES.FORMS.PIP_OVERVIEW.PIP_GOALS
  if (checkpointType === CheckpointType.Review && checkpointNumber) {
    probationUrl = ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT
  }
  if (checkpointType === CheckpointType.Recommendation) {
    probationUrl = ROUTES.FORMS.PROBATION_OVERVIEW.RECOMMENDATIONS
  }
  if (checkpointType === CheckpointType.Decision) {
    probationUrl = ROUTES.FORMS.PROBATION_OVERVIEW.COMMITTEE
    pipUrl = ROUTES.FORMS.PIP_OVERVIEW.COMMITTEE
  }

  return pathToUrl(isPip ? pipUrl : probationUrl, {
    employeeId,
    cycleId,
    id: checkpointNumber,
  })
}

const ProbationEmployeesTable = ({ category }: { category: 'probation' | 'pip' }) => {
  const [showAssignedToMe, setShowAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_PROBATION_ASSIGNED_TO_ME,
    false,
  )
  const [showPendingCommittee, setShowPendingCommittee] = useLocalStorage(
    LOCAL_STORAGE.SHOW_PROBATION_PENDING_COMMITTEE,
    false,
  )

  const user = useSelector(selectUser)
  const isPip = category === 'pip'

  const getFilterPendingWith = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'pending_with__id',
    nonResettable: true,
  })

  const getFilterPendingCommittee = (setFilter: boolean) => {
    const todayDate = startOfToday()
    const weekLaterDate = addWeeks(endOfToday(), 1)
    return [
      {
        filters: setFilter
          ? [
              {
                name: 'decision',
                id: 'decision',
              },
            ]
          : [],
        columnName: 'checkpoint_type',
        nonResettable: true,
      },
      {
        filters: setFilter
          ? [
              {
                name: formatISO(todayDate),
                id: formatISO(todayDate),
              },
              {
                name: formatISO(weekLaterDate),
                id: formatISO(weekLaterDate),
              },
            ]
          : [],
        columnName: 'checkpoint_date_time',
        nonResettable: true,
      },
    ]
  }

  const getInitialFilters = () => {
    const filters = []

    if (showAssignedToMe) {
      filters.push(getFilterPendingWith(true))
    }
    if (showPendingCommittee) {
      filters.push(...getFilterPendingCommittee(true))
    }

    return filters
  }

  const table = useTable<ProbationInterface>(
    { getItems: isPip ? getPipItems : getProbationItems },
    getInitialFilters(),
  )

  const [stats, setStats] = useState<{
    inProbation?: number
    inPip?: number
    overdue?: number
    overdueSoon?: number
  }>()

  useEffect(() => {
    fetchStatistics()
  }, [])

  const fetchStatistics = async () => {
    const result = isPip ? await getPipStatistics() : await getProbationStatistics()

    if (result.data) {
      setStats({
        inProbation: result.data.employees_in_probation,
        inPip: result.data.employees_in_pip,
        overdue: result.data.overdue,
        overdueSoon: result.data.soon_to_be_overdue,
      })
    }
  }

  const onToggleAssignedToMe = () => {
    setShowAssignedToMe(!showAssignedToMe)
    table.onFilterChange(getFilterPendingWith(!showAssignedToMe))
  }

  const onTogglePendingCommittee = () => {
    setShowPendingCommittee(!showPendingCommittee)
    table.onFilterChange(getFilterPendingCommittee(!showPendingCommittee))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label={`Employees in ${isPip ? 'PIP' : 'probation'}`}
          val={isPip ? stats?.inPip : stats?.inProbation}
          mr="s-32"
        />
        <Stat label="Overdue" val={stats?.overdue} mr="s-32" color="red" />
        <Stat
          label="Soon to be overdue"
          val={stats?.overdueSoon}
          mr="s-32"
          color="orange"
        />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <FilterButton active={showAssignedToMe} onClick={onToggleAssignedToMe}>
          Assigned to me
        </FilterButton>
        <FilterButton active={showPendingCommittee} onClick={onTogglePendingCommittee}>
          Pending committee review
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<ProbationInterface>
          name={isPip ? TableNames.PipEmployees : TableNames.ProbationEmployees}
          useWindowScroll
          row={getRow(isPip)}
          {...table}
          emptyState={
            <EmptyTableRaw
              title={`${isPip ? 'PIP' : 'Probation'} employees will appear here.`}
            />
          }
          onRowClick={data =>
            navigateTo(
              getProbationUrl(
                data.employee.id,
                data.cycle.id,
                data.checkpoint_type,
                isPip,
                data.checkpoint_number,
              ),
            )
          }
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default ProbationEmployeesTable
