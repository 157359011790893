import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ChangeRequest } from '@src/interfaces/employeeChangeRequest'
import { selectorKeys } from '../api'

export const fieldToChangeColumn: ColumnInterface<ChangeRequest> = {
  type: CellTypes.text,
  idPoint: 'field.field_display_name',
  dataPoint: 'field.field_display_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Field to change',
}

export const oldValueColumn: ColumnInterface<ChangeRequest> = {
  type: CellTypes.text,
  idPoint: 'old_value',
  dataPoint: 'old_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Old value',
}

export const newValueColumn: ColumnInterface<ChangeRequest> = {
  type: CellTypes.text,
  idPoint: 'new_value',
  dataPoint: 'new_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'New value',
}

export const statusColumn: ColumnInterface<ChangeRequest> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
}

export const approvalTypeColumn: ColumnInterface<ChangeRequest> = {
  type: CellTypes.insert,
  idPoint: 'field.field_abbreviation',
  dataPoint: 'field.field_abbreviation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Approval type',
  insert: ({ data }) => `${data.field.field_abbreviation} change`,
}
