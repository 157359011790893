import React, { useEffect } from 'react'
import { Box, Button, StatusPopup } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import PageLoading from '@components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import { useGetAuthentication } from '@src/api/auth'
import { setAuthenticatedAction } from '@src/store/auth/actions'
import { Authenticated } from '@src/store/auth/constants'
import { useDispatch } from 'react-redux'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { saveLoginData } from './common'
import { SESSION_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectAuthenticated } from '@src/store/auth/selectors'

export const LoginSSO = () => {
  const isLoggedIn = useSelector(selectAuthenticated)

  const { query } = useQuery<{ token?: string; authenticated?: Authenticated }>()
  const dispatch = useDispatch()
  const { data, isError, isLoading } = useGetAuthentication(
    isLoggedIn ? undefined : query.token,
  )

  const showStatusPopup = useShowStatusPopup()

  useEffect(() => {
    if (isLoggedIn) {
      goBack(ROUTES.MAIN)
      return
    }

    if (
      query?.authenticated === Authenticated.not_authenticated ||
      isError ||
      data?.authenticated === Authenticated.not_authenticated
    ) {
      navigateTo(pathToUrl(ROUTES.LOGIN.MAIN))
      showStatusPopup({
        status: 'error',
        title: 'Authentication failed. Please check your credentials and try again.',
        actions: statusPopup => (
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        ),
      })
    }
    if (data?.authenticated) {
      if (data.two_factor_authentication_enabled) {
        sessionStorage.setItem(SESSION_STORAGE.TWO_FACTOR_LOGIN, JSON.stringify(data))
        goBack(ROUTES.TWO_FACTOR_LOGIN)
      } else {
        dispatch(
          setAuthenticatedAction(data.authenticated === Authenticated.authenticated),
        )
        saveLoginData(data)
        goBack(ROUTES.MAIN)
      }
    }
  }, [query, data, isLoading, isError, isLoggedIn])

  return (
    <Box height="100vh" width="100vw">
      <PageLoading />
    </Box>
  )
}
