import React, { PropsWithChildren } from 'react'
import { interviewScorecardTemplateFormRequests } from '@src/api/interviewScorecardTemplate'
import InterviewScorecardTemplateForm, {
  validator,
} from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import Form from '@src/features/Form/Form'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { Popup } from '@revolut/ui-kit'

type ScorecardFormWrapperProps = {
  id?: number
}

export const ScorecardFormWrapper = ({
  id,
  children,
}: PropsWithChildren<ScorecardFormWrapperProps>) => {
  return (
    <Form
      api={interviewScorecardTemplateFormRequests}
      validator={validator}
      forceParams={{ id: id?.toString() }}
    >
      {children}
    </Form>
  )
}

type ScorecardFormProps = {
  id?: number
  onSubmit: (
    result: InterviewScorecardTemplateInterface,
  ) => Promise<InterviewScorecardTemplateInterface> | void
}

export default ({ id, onSubmit }: ScorecardFormProps) => {
  return (
    <ScorecardFormWrapper id={id}>
      <InterviewScorecardTemplateForm
        renderActions={children => <Popup.Actions horizontal>{children}</Popup.Actions>}
        onSubmit={onSubmit}
      />
    </ScorecardFormWrapper>
  )
}
