import { ReviewScorecardInterface } from '@src/interfaces/performance'

export const useGetProbationMissingValues = (values: ReviewScorecardInterface) => {
  const extraSection = values.review_data.recommendation_extra_section
  if (!extraSection) {
    return { missingRating: false, missingBarRaiser: false }
  }

  const ratingValue = extraSection?.employee_project_performance.value
  const missingRating = !ratingValue

  const barRaiserValues = extraSection?.recommendation?.map(item => item.value)
  const missingBarRaiser = barRaiserValues?.some(value => !value)

  return { missingRating, missingBarRaiser, barRaiserValues }
}
