import { Box, Group, VStack } from '@revolut/ui-kit'
import React from 'react'
import { PerformanceSummarySkeleton } from '@src/pages/Forms/DepartmentForm/Performance/Summary/PerformanceSummarySkeleton'
import {
  Cycle,
  CycleStats,
  CycleTablesData,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import YearSummary from '@src/pages/Forms/DepartmentForm/Performance/Summary/YearSummary'
import QuarterSummary from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterSummary'
import { PerformanceSummaryData } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface PerformanceSummaryProps extends CycleTablesData {
  selectedCycle: Cycle | undefined
  cycleStats: CycleStats[] | undefined
  summaryStats: PerformanceSummaryData | undefined
}

export const PerformanceSummary = ({
  selectedCycle,
  goalTableRequests,
  goalsSettings,
  roadmapsTableRequests,
  roadmapsSettings,
  karmaTableRequests,
  karmaSettings,
  cycleStats,
  summaryStats,
}: PerformanceSummaryProps) => {
  const isYearlyView = selectedCycle?.type === 'year'
  return (
    <>
      {!selectedCycle && <PerformanceSummarySkeleton />}
      {selectedCycle && (
        <Group>
          <Box p="s-16">
            <VStack space="s-16">
              {isYearlyView ? (
                <YearSummary
                  selectedCycle={selectedCycle}
                  goalTableRequests={goalTableRequests}
                  goalsSettings={goalsSettings}
                  roadmapsTableRequests={roadmapsTableRequests}
                  roadmapsSettings={roadmapsSettings}
                  karmaTableRequests={karmaTableRequests}
                  karmaSettings={karmaSettings}
                  cycleStats={cycleStats}
                  summaryStats={summaryStats}
                />
              ) : (
                <QuarterSummary
                  selectedCycle={selectedCycle}
                  goalTableRequests={goalTableRequests}
                  goalsSettings={goalsSettings}
                  roadmapsTableRequests={roadmapsTableRequests}
                  roadmapsSettings={roadmapsSettings}
                  karmaTableRequests={karmaTableRequests}
                  karmaSettings={karmaSettings}
                  cycleStats={cycleStats}
                />
              )}
            </VStack>
          </Box>
        </Group>
      )}
    </>
  )
}
