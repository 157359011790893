import React from 'react'
import { useGetEmployeeTotalCompensationTimeSeries } from '@src/api/totalCompensation'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { CompensationOverTime } from './CompensationOverTime'
import { CompensationTotal } from './CompensationTotal'
import { ErrorWidget } from './ErrorWidget'

type Props = {
  compensationParams: CompensationParams
  id: number
}

export const CompensationWidget = ({ compensationParams, id }: Props) => {
  const { currency } = compensationParams
  const filters = currency
    ? [
        {
          filters: [{ id: `${currency}`, name: `${currency}` }],
          columnName: 'currency_iso_code',
        },
      ]
    : []

  const { data, error, isError, isLoading } = useGetEmployeeTotalCompensationTimeSeries(
    id,
    filters,
  )

  if (isError) {
    const errorsData = error?.response?.data
    const contractErrorsData = errorsData?.contract
    const errorMessage = contractErrorsData || getStringMessageFromError(error)

    return (
      <ErrorWidget id={id} isContractError={!!contractErrorsData} title={errorMessage} />
    )
  }

  return (
    <TwoColumnsLayout
      left={<CompensationTotal compensationParams={compensationParams} id={id} />}
      right={<CompensationOverTime data={data} isLoading={isLoading} />}
      rightFlex={3}
    />
  )
}
