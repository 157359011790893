import React, { useState } from 'react'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { changeCandidateConfidentiality } from '@src/api/recruitment/candidates'
import { MoreBar } from '@revolut/ui-kit'

type Props = {
  candidateId: number
  isConfidential: boolean
  onRefresh: () => void
}

export const MarkConfidentialAction = ({
  candidateId,
  isConfidential,
  onRefresh,
}: Props) => {
  const [confidentialConfirmation, setConfidentialConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)

  return (
    <>
      <ConfirmationDialog
        label="Are you sure?"
        body={
          !isConfidential
            ? 'You’re going to mark this candidate as confidential. If you’re not this candidate’s recruiter or sourcer, you will lose access to this candidate. Do you want to continue?'
            : 'You are going to mark the candidate as non-confidential. Do you want to continue?'
        }
        open={confidentialConfirmation}
        onClose={() => setConfidentialConfirmation(false)}
        onReject={() => setConfidentialConfirmation(false)}
        loading={loading}
        onConfirm={async () => {
          setLoading(true)

          try {
            await changeCandidateConfidentiality(candidateId, !isConfidential)
            onRefresh()
            setConfidentialConfirmation(false)
          } finally {
            setLoading(false)
          }
        }}
      />

      <MoreBar.Action
        useIcon="Shield"
        onClick={() => {
          setConfidentialConfirmation(true)
        }}
      >
        Make {isConfidential ? 'non-confidential' : 'confidential'}
      </MoreBar.Action>
    </>
  )
}
