import React from 'react'
import { Flex, FlexProps, Token } from '@revolut/ui-kit'

interface PageWrapperProps {
  fullWidth?: boolean
}

export const PageWrapper: React.FC<PageWrapperProps & FlexProps> = ({
  fullWidth,
  ...props
}) => {
  return (
    <Flex
      width="100%"
      height="100%"
      maxWidth={fullWidth ? undefined : Token.breakpoint.xxl}
      bg="layout-background"
      flexDirection="column"
      pl={{ all: 0, md: 's-16' }}
      {...props}
    />
  )
}
