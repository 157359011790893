import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewSummaryInterface } from '@src/interfaces/performance'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import React from 'react'
import { NoReviewsGoals } from '../Common/NoReviewsGoals'
import { NoReviewsDeliverables } from '../Common/NoReviewsDeliverables'
import { SummaryGoals } from './SummaryGoals'
import { Box } from '@revolut/ui-kit'

interface Props {
  selectedPeriod: Pick<
    PerformanceSelector,
    | 'category'
    | 'end_date_time'
    | 'probation_reviews_deliverables_type'
    | 'id'
    | 'probation_version'
    | 'version'
    | 'offset'
  >
  employee: Pick<EmployeeInterface, 'id'>
  employeeStats?: Pick<EmployeeStats, 'kpi_performance_percent'>
  reviewSummary?: Pick<
    ReviewSummaryInterface,
    'team' | 'grade_label_mapping' | 'reviewed_employee_type'
  >
}

export const GoalsSummaryTable = ({
  selectedPeriod,
  employee,
  employeeStats,
  reviewSummary,
}: Props) => {
  if (
    (selectedPeriod.category === ReviewCycleCategory.Probation &&
      selectedPeriod.probation_reviews_deliverables_type === 'jira') ||
    selectedPeriod.category === ReviewCycleCategory.PIP
  ) {
    return <SummaryGoals data={employee} selectedPeriod={selectedPeriod} />
  }

  if (
    selectedPeriod.category === ReviewCycleCategory.Performance ||
    selectedPeriod.category === ReviewCycleCategory.Probation
  ) {
    return (
      <Box mt="s-16">
        <NoReviewsGoals
          summary={reviewSummary}
          employee={employee}
          selectedPeriod={selectedPeriod}
        />
      </Box>
    )
  }

  return (
    <NoReviewsDeliverables
      data={employee}
      category={selectedPeriod.category}
      stats={employeeStats}
      selectedPeriod={selectedPeriod}
    />
  )
}
