import React from 'react'

import { EditableGoalsTable } from '@src/features/EditableGoalsTable'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { Route, Switch } from 'react-router-dom'
import { UPLOAD_TYPE_QUERY_KEY } from '@src/features/BulkDataImportV2/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { BulkImportType } from '@src/features/Goals/BulkImportType'
import { pathToUrl } from '@src/utils/router'

export const ImportGoals = () => {
  const { query } = useQuery()

  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT_TYPE}>
        <BulkImportType />
      </Route>
      <Route path={ROUTES.FORMS.IMPORT_DATA.GOALS.ANY}>
        <EditableGoalsTable
          importRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT}
          sessionRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.SESSION}
          anyRoute={ROUTES.FORMS.IMPORT_DATA.GOALS.ANY}
          header={
            <PageHeader
              backUrl={pathToUrl(ROUTES.FORMS.IMPORT_DATA.GOALS.IMPORT_TYPE)}
              title={
                query[UPLOAD_TYPE_QUERY_KEY] === 'update'
                  ? 'Update existing goals’ information'
                  : 'Create multiple goals'
              }
              subtitle="Import data"
            />
          }
        />
      </Route>
    </Switch>
  )
}
