import { selectorKeys } from '@src/constants/api'
import React from 'react'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { Timezone } from '@src/interfaces/selectors'

interface Props {
  value: Timezone
  onChange: (value?: Timezone) => void
}

export const TimezoneSelector = ({ value, onChange }: Props) => {
  return (
    <ButtonFilter<Timezone>
      value={[value]}
      type={FilterSelectType.SingleSelect}
      selector={selectorKeys.timezones}
      title={value.name}
      useIcon="Globe"
      onChange={([singleSelectedValue]) => onChange(singleSelectedValue)}
    />
  )
}
