import { RequestInterfaceNew, GetRequestInterface } from '@src/interfaces'
import {
  InterviewAvailabilityInterface,
  InterviewAvailabilityPreferencesInterface,
} from '@src/interfaces/interviewAvailability'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { FetchDataQueryInterface } from '@src/interfaces/data'

export const useFetchInterviewAvailability = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  useFetch<GetRequestInterface<InterviewAvailabilityInterface>>(
    API.INTERVIEWS_INTERVIEW_SLOTS,
    undefined,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  )

const INTERVIEWER = '/interviewer'
const INTERVIEW_AVAILABILITY_LIMITS = '/interviewsAvailabilityLimits'

export const interviewAvailabilityPreferencesFormRequests = (
  employeeId: string | number,
): RequestInterfaceNew<InterviewAvailabilityPreferencesInterface> => {
  const endpoint = `${API.INTERVIEWS}${INTERVIEWER}/${employeeId}${INTERVIEW_AVAILABILITY_LIMITS}`
  return {
    get: async () => api.get(endpoint),
    update: async data => api.patch(endpoint, data),
    submit: async data => api.post(endpoint, data),
  }
}

const REFRESH_INTERVIEWER_SLOTS = '/refreshInterviewerSlots'

export const refreshInterviewSlots = (employeeId: string | number) => {
  return apiWithoutHandling.post(
    `${API.INTERVIEWS_INTERVIEW_SLOTS}${REFRESH_INTERVIEWER_SLOTS}`,
    undefined,
    {
      params: {
        interviewer_id: employeeId,
      },
    },
  )
}
