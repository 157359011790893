import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { MonsterJobsIntegrationItem } from './MonsterJobsIntegrationItem'
import { useGetJobPostingSettings } from '@src/api/settings'
import PageLoading from '@src/components/PageLoading/PageLoading'

export const MonsterJobsIntegrationPage = () => {
  const { isLoading: loadingJobPostingSettings } = useGetJobPostingSettings()
  if (loadingJobPostingSettings) {
    return <PageLoading />
  }
  return (
    <PageWrapper>
      <PageHeader
        title="Monster Jobs"
        subtitle="Job boards"
        backUrl={ROUTES.FEATURES.INTEGRATIONS}
      />
      <PageBody>
        <MonsterJobsIntegrationItem />
      </PageBody>
    </PageWrapper>
  )
}
