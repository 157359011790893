import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Flex, H4, MoreBar, Skeleton, TextButton } from '@revolut/ui-kit'
import { ChevronDown, ChevronUp, Pencil } from '@revolut/icons'
import { useSelector } from 'react-redux'

import Stat from '@src/components/Stat/Stat'
import { useTable } from '@src/components/Table/hooks'
import {
  CompanyCompensationOption,
  DepartmentCompensationReviewInterface,
} from '@src/interfaces/compensation'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { getDepartmentCompensationReviews } from '@src/api/compensation'
import { formatMoney } from '@src/utils/format'
import FilterSelect, {
  FilterSelectType,
} from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { CurrencySelect } from '@src/components/CurrencySelect/CurrencySelect'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  defaultCurrency,
  useReviewData,
} from '@src/pages/Forms/Budgets/EditCompanyBudget/common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import Table from '@src/components/TableV2/Table'

interface BudgetTableProps {
  getRow: (currency: string) => RowInterface<DepartmentCompensationReviewInterface>
}

export const BudgetTable = ({ getRow }: BudgetTableProps) => {
  const permissions = useSelector(selectPermissions)

  const [selectedYear, setSelectedYear] = useState<number>()
  const [yearSelectOpen, setYearSelectOpen] = useState(false)
  const [currency, setCurrency] = useState<IdAndName>()

  const currencyId = currency?.id || null
  const currencyCode = currency?.name || defaultCurrency

  const { yearOptions, selectedCompensationReview, modifiedCompensationReview } =
    useReviewData(currencyId, selectedYear)

  const table = useTable(
    { getItems: getDepartmentCompensationReviews(currencyId) },
    undefined,
    [
      {
        sortBy: 'department__name',
        direction: SORT_DIRECTION.DESC,
        nonResettable: true,
      },
    ],
    {
      disable: !selectedYear,
      disableQuery: true,
    },
  )

  const yearSelectRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (yearOptions.data?.results[0] && !selectedYear) {
      const review = yearOptions.data.results[0]
      table.onFilterChange([
        {
          columnName: 'company_compensation_review__id',
          filters: [{ id: review.id, name: `${review.id}` }],
          nonResettable: true,
        },
      ])
      setSelectedYear(review.id)
    }
  }, [yearOptions.data, selectedYear])

  const canEditBudget = permissions.includes(
    PermissionTypes.ChangeCompanyCompensationReview,
  )

  const isEmpty =
    (!yearOptions.isLoading && yearOptions.data?.results.length === 0) ||
    (!table.loading && table.data?.length === 0)

  const { salary, bonus } = useMemo(() => {
    if (
      !modifiedCompensationReview ||
      modifiedCompensationReview.salary == null ||
      modifiedCompensationReview.bonus == null
    ) {
      return { salary: undefined, bonus: undefined }
    }

    return {
      salary: formatMoney(modifiedCompensationReview.salary, currencyCode),
      bonus: formatMoney(modifiedCompensationReview.bonus, currencyCode),
    }
  }, [currencyCode, modifiedCompensationReview])

  const yearSelector = async () => ({
    options:
      yearOptions.data?.results.map(option => ({ ...option, name: `${option.year}` })) ||
      [],
  })

  const onCurrencyChange = (value: IdAndName) => {
    setCurrency(value)
    table.onFilterChange([
      {
        columnName: 'target_currency_id',
        filters:
          value.name === defaultCurrency ? [] : [{ id: value.id, name: `${value.id}` }],
        nonResettable: true,
      },
    ])
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Cycle"
          val={
            <Box ref={yearSelectRef}>
              <TextButton onClick={() => setYearSelectOpen(!yearSelectOpen)}>
                {yearOptions.isLoading ? (
                  <Skeleton width={72} height="s-20" my="s-4" />
                ) : (
                  <Flex alignItems="center" color="foreground">
                    <H4 use="span" fontWeight="bold">
                      {selectedCompensationReview?.year}
                    </H4>
                    {yearSelectOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
                  </Flex>
                )}
              </TextButton>
              <FilterSelect<CompanyCompensationOption>
                open={yearSelectOpen}
                selector={yearSelector}
                anchorRef={yearSelectRef}
                onClose={() => setYearSelectOpen(false)}
                value={
                  selectedCompensationReview && [
                    {
                      ...selectedCompensationReview,
                      name: `${selectedCompensationReview.year}`,
                    },
                  ]
                }
                onChange={options => {
                  const option = options[0]
                  if (option) {
                    table.onFilterChange(
                      {
                        columnName: 'company_compensation_review__id',
                        filters: [{ id: option.id, name: `${option.id}` }],
                        nonResettable: true,
                      },
                      false,
                    )
                    setSelectedYear(option.id)
                    setYearSelectOpen(false)
                  }
                }}
                type={FilterSelectType.SingleSelect}
                width={100}
              />
            </Box>
          }
        />
        <CurrencySelect
          value={currencyCode}
          label="Currency"
          onCurrencyChange={onCurrencyChange}
          selector={selectorKeys.company_compensation_review_currencies}
        />
        <Stat label="Salaries budget" val={salary} />
        <Stat label="Bonus budget" val={bonus} />
        <Stat label="Departments" val={selectedCompensationReview?.department_count} />
      </Table.Widget.Info>

      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canEditBudget && selectedCompensationReview?.status.id === 'ongoing' && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EDIT_COMPANY_BUDGET, {
                id: selectedYear,
                currencyId: currency?.id,
              })}
              use={InternalLink}
              useIcon={Pencil}
            >
              Edit budget
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.Budget}
          row={getRow(currencyCode)}
          useWindowScroll
          {...table}
          loading={isEmpty ? false : table.loading}
          emptyState={
            <EmptyTableRaw
              title="No budget data was uploaded yet"
              imageId="3D098"
              description="As soon as company budget for the cycle will be uploaded, the department budget will appear here."
            />
          }
          onRowClick={row => {
            if (row.department?.id) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.DEPARTMENT.BUDGET.DISTRIBUTION, {
                  id: row.department.id,
                }),
              )
            }
            if (row.budget_pool_config?.id) {
              navigateTo(
                pathToUrl(ROUTES.FORMS.VIEW_CUSTOM_BUDGET_POOL, {
                  id: row.id,
                }),
              )
            }
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
