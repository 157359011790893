import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { ErrorWidget, Flex, Spinner } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { ImportSessionStatus } from '@src/interfaces/importData'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  deleteKPIsUploadSession,
  useGetKPIsUploadSessionStats,
} from '@src/api/importData'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'

interface SummaryProps {
  uploadedCount: number
  failedCount: number
}

const Summary = ({ uploadedCount, failedCount }: SummaryProps) => (
  <>
    {uploadedCount} Uploaded successfully
    <br />
    {failedCount} Failed
  </>
)

const PendingState = () => (
  <SuccessWidget
    title="Task in progress"
    type="pending"
    text="Please wait for the data to upload, this can take several minutes. Refresh the page
      to update the status."
  />
)

const ErrorState = (props: SummaryProps) => (
  <ErrorWidget>
    <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
    <ErrorWidget.Title>Unable to upload some KPIs</ErrorWidget.Title>
    <ErrorWidget.Description>
      <Summary {...props} />
    </ErrorWidget.Description>
    <ErrorWidget.Action
      onClick={() => navigateTo(ROUTES.FORMS.IMPORT_DATA.KPIS.UPLOAD_FILE)}
    />
  </ErrorWidget>
)

const SuccessState = (props: SummaryProps) => (
  <SuccessWidget title="Upload successful" type="success" text={<Summary {...props} />} />
)

const StatusPage = () => {
  const { id: sessionId } = useParams<{ id: string }>()
  const { data, isLoading } = useGetKPIsUploadSessionStats(sessionId)

  const status = data?.status.id

  let StatusWidget: React.FC<SummaryProps> = () => null

  switch (status) {
    case ImportSessionStatus.InProgress:
      StatusWidget = PendingState
      break
    case ImportSessionStatus.CompletedWithErrors:
      StatusWidget = ErrorState
      break
    case ImportSessionStatus.Completed:
      StatusWidget = SuccessState
      break
  }

  useEffect(() => {
    return () => {
      if (status === ImportSessionStatus.Completed) {
        deleteKPIsUploadSession(sessionId)
      }
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title={
          status === ImportSessionStatus.InProgress
            ? 'Upload in progress'
            : 'Upload completed'
        }
        backUrl={ROUTES.APPS.TODO.KPIS_UPLOAD}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody>
        {isLoading ? (
          <Flex justifyContent="center" alignItems="center">
            <Spinner color="grey-tone-50" />
          </Flex>
        ) : (
          data && (
            <StatusWidget
              uploadedCount={data.total_count - data.invalid_count}
              failedCount={data.error_count}
            />
          )
        )}
      </PageBody>
    </PageWrapper>
  )
}
export default StatusPage
