import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { useGetFeedback } from '@src/api/feedback'
import { useEmployeeFields } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FeedbackInput } from '@components/Inputs/FeedbackInput/FeedbackInput'
import { Text, VStack, Token } from '@revolut/ui-kit'
import { format } from 'date-fns'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { getAvatarUrl } from '@src/utils/employees'

const A = styled(Link)`
  color: ${Token.color.foreground};
  text-decoration: none;
`

type EmployeeDetails = Pick<EmployeeInterface, 'id' | 'full_name' | 'avatar' | 'position'>

export interface FeedbackTableSidebarProps {
  selectedId?: number
  onClose: () => void
}

export const FeedbackTableSidebar = ({
  selectedId,
  onClose,
}: FeedbackTableSidebarProps) => {
  const { data, isLoading } = useGetFeedback(selectedId)
  const { data: employee, isLoading: isEmployeeLoading } =
    useEmployeeFields<EmployeeDetails>(data?.user?.employee_id, [
      'id',
      'full_name',
      'avatar',
      'position',
    ])

  return (
    <SideBar
      isOpen={Boolean(selectedId)}
      onClose={() => {
        onClose()
      }}
      variant="wide"
      title={
        <A
          to={getLocationDescriptor(
            pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id: employee?.id }),
          )}
        >
          {employee?.full_name}
        </A>
      }
      subtitle={
        <VStack>
          <Text>{employee?.position?.name}</Text>
          <Text variant="primary" color={Token.color.foreground}>
            {data?.user_uuid}
          </Text>
        </VStack>
      }
      avatar={getAvatarUrl(employee?.avatar)}
      loading={isLoading || isEmployeeLoading}
    >
      {data ? (
        <>
          <Text fontSize="h5">
            <FeedbackInput value={data?.score || 0} onChange={() => {}} disabled />
          </Text>
          <Text pt="s-16" fontSize="primary">
            {data?.text}
          </Text>
          <Text pt="s-8" color="grey-tone-50">
            {format(new Date(data.creation_date_time), 'd MMM yyyy')}
          </Text>
        </>
      ) : null}
    </SideBar>
  )
}
