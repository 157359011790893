import React, { useState, useRef } from 'react'
import {
  Subheader,
  Action,
  Item,
  Group,
  ItemSkeleton,
  Avatar,
  useIntersectViewport,
  Box,
  Widget,
  ActionButton,
  Token,
  IconName,
} from '@revolut/ui-kit'

import { useTable, useTableReturnType } from '@src/components/Table/hooks'
import { FilterByInterface, SortByInterface, Stats } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { tableRequests } from '@src/interfaces'
import { ErrorState } from '../FormPreview/FormPreview'
import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'

interface TablePreviewProps<T, S extends {} = Stats, M extends Record<string, any> = {}>
  extends TablePreviewContentsProps<T, S, M> {
  title?: string
  tableRoute?: string
  detailsTitle?: string
  statsConfig?: StatsConfig<S>
  renderActions?: (table?: useTableReturnType<T, S>) => React.ReactNode
}

export const TablePreview = <T, S extends {} = Stats>({
  title,
  tableRoute,
  detailsTitle,
  ...props
}: TablePreviewProps<T, S>) => {
  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false)
  useIntersectViewport(
    ref,
    isIntersecting => {
      if (!isInView && isIntersecting) {
        setIsInView(true)
      }
    },
    0.6,
  )

  const header =
    title || tableRoute ? (
      <Subheader variant="nested">
        <Subheader.Title>{title}</Subheader.Title>
        {tableRoute && (
          <Subheader.Side>
            <Action onClick={() => navigateTo(tableRoute)}>
              {detailsTitle || 'More details'}
            </Action>
          </Subheader.Side>
        )}
      </Subheader>
    ) : null

  return isInView ? (
    <>
      {header}
      <TablePreviewContents {...props} />
    </>
  ) : (
    <Box ref={ref}>
      {header}
      <ItemSkeleton />
    </Box>
  )
}

interface TablePreviewContentsProps<
  T,
  S extends {} = Stats,
  M extends Record<string, any> = {},
> {
  api: tableRequests<T, S, M>
  filterByInitial?: FilterByInterface[]
  sortByInitial?: SortByInterface[]
  row: (row: T) => React.ReactNode
  createNewRoute?: string
  emptyState?: {
    title: string
    icon: IconName
  }
  suffix?: React.ReactNode
  statsConfig?: StatsConfig<S>
  renderActions?: (table?: useTableReturnType<T, S>) => React.ReactNode
  disable?: boolean
}

const TablePreviewContents = <T, S extends {} = Stats>({
  api,
  filterByInitial,
  sortByInitial,
  row,
  createNewRoute,
  emptyState,
  suffix,
  statsConfig,
  renderActions,
  disable,
}: TablePreviewContentsProps<T, S>) => {
  const table = useTable<T, S>(api, filterByInitial, sortByInitial, { disable })
  const statFiltersProps = useSelectableTableStats<T, S>({
    table,
    statsConfig,
    columnName: 'active_stats',
    disableQueryParam: true,
  })

  if (table.fetchError) {
    return <ErrorState onRefresh={table.refresh} />
  }

  return (
    <Group>
      {statsConfig && (
        <Widget p="s-16">
          <StatFilters {...statFiltersProps} />
        </Widget>
      )}
      {renderActions?.(table)}
      {table.loading ? (
        <ItemSkeleton />
      ) : (
        <>
          {table.data.map(row)}
          {table.data.length === 0 && emptyState && (
            <Item>
              <Item.Avatar>
                <Avatar color={Token.color.greyTone20} useIcon={emptyState.icon} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title color={Token.color.greyTone50}>{emptyState.title}</Item.Title>
              </Item.Content>
            </Item>
          )}
          {createNewRoute && (
            <Item>
              <Item.Content>
                <ActionButton onClick={() => navigateTo(createNewRoute)} useIcon="Plus">
                  Add new
                </ActionButton>
              </Item.Content>
            </Item>
          )}
          {suffix}
        </>
      )}
    </Group>
  )
}
