import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { SelectScreeningType } from './SelectScreeningType'
import ManualScreening from './Manual'
import HirerightScreening from './HireRight'

export const Screenings = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.SCREENING.SELECT}>
          <SelectScreeningType />
        </Route>
        <Route exact path={ROUTES.FORMS.SCREENING.MANUAL.ANY}>
          <ManualScreening />
        </Route>
        <Route exact path={ROUTES.FORMS.SCREENING.HIRERIGHT.ANY}>
          <HirerightScreening />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
