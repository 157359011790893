import React from 'react'
import { Common } from '@src/pages/Performance/Reviews/ProbationPipTables/Common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const ProbationReviewsTable = () => {
  return (
    <Common
      backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.PROBATION)}
      category="probation"
    />
  )
}
