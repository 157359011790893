import { useEffect, useState } from 'react'

import {
  getPerformanceSkillsReviews,
  getPerformanceTimelineStats,
} from '@src/api/performance'
import { useGetPerformanceSkillSummary } from '@src/api/performanceReview'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceTimelineStats,
  ReviewCategory,
  SkillCardInterface,
  SkillsReviewsInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { useTable } from '@components/Table/hooks'
import { pushError } from '@src/store/notifications/actions'
import { useGetLatestGrade } from '../PerformanceHistory/hooks'

export const useSkillsTable = (data: EmployeeInterface) => {
  const table = useTable<SkillsReviewsInterface, PerformanceTimelineStats>({
    getItems: () => getPerformanceSkillsReviews(data.id),
    getStats: () => getPerformanceTimelineStats(data.id),
  })
  const getLatestGrade = useGetLatestGrade()

  useEffect(() => {
    if (table.fetchError && table.fetchError.response?.status !== 403) {
      pushError({ error: table.fetchError })
    }
  }, [table.fetchError])

  if (table.fetchError?.response?.status === 403) {
    return { error: true as const }
  }

  return {
    table,
    timelineStats: table.stats,
    latestGrade: getLatestGrade(table.stats),
    error: false as const,
  }
}

export const useFetchPerformanceSkillSummary = (
  category?: ReviewCategory,
  cycleId?: string | number,
  employeeId?: number,
  skillId?: number,
) => {
  const cycle_id = category === ReviewCategory.Probation ? `E-${cycleId}` : cycleId

  const [cards, setCards] = useState<SkillCardInterface[] | ValueBasedCardInterface[]>([])
  const context = useGetPerformanceSkillSummary(category, cycle_id, employeeId, skillId)

  useEffect(() => {
    setCards(context.data?.cards || [])
  }, [context.data?.cards])

  return {
    ...context,
    cards,
  }
}
