import { useEffect } from 'react'
import { SESSION_STORAGE } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'

export const useOnboardingPreviewMode = (): {
  cleanup: () => void
  isPreviewEnabled: boolean
} => {
  const { query, deleteQueryParam } = useQuery()

  useEffect(() => {
    if (query.preview) {
      const isPreview = !!query.preview

      sessionStorage.setItem(SESSION_STORAGE.ONBOARDING_PREVIEW, String(isPreview))
      deleteQueryParam('preview')
    }
  }, [query.preview])

  const isPreviewEnabled = sessionStorage.getItem(SESSION_STORAGE.ONBOARDING_PREVIEW)

  return {
    cleanup: () => sessionStorage.removeItem(SESSION_STORAGE.ONBOARDING_PREVIEW),
    isPreviewEnabled: isPreviewEnabled === 'true',
  }
}
