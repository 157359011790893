import AvatarUploadPopup from '@components/AvatarUploadPopup/AvatarUploadPopup'
import React from 'react'
import { uploadUserAvatar, useGetUserAvatar, deleteUserAvatar } from '@src/api/settings'
import { dispatch } from '@src/utils/store'
import { setUserAction } from '@src/store/auth/actions'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface UserAvatarUploadProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const UserAvatarUpload = ({ open, onClose, onSuccess }: UserAvatarUploadProps) => {
  const user = useSelector(selectUser)
  const { data: userAvatar, refetch } = useGetUserAvatar(open)
  const statusPopup = useStatusPopup()

  const onSavePhoto = async (file: File) => {
    try {
      const response = await uploadUserAvatar(file)

      /** To update the avatar in sidebar without calling /whoami */
      if (response.data.url) {
        dispatch(setUserAction({ ...user, avatar: response.data.url }))
      }

      onSuccess()
      refetch()
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Profile picture updated</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to update user photo',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onRemovePhoto = async () => {
    if (!userAvatar?.id) {
      return
    }
    try {
      await deleteUserAvatar(userAvatar.id)
      /** To update the avatar in sidebar without calling /whoami */
      dispatch(setUserAction({ ...user, avatar: null }))
      refetch()
      onSuccess()
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Profile picture removed</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to remove user photo',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  return (
    <AvatarUploadPopup
      open={open}
      onClose={onClose}
      onSave={onSavePhoto}
      onRemove={userAvatar?.id ? onRemovePhoto : undefined}
      title="Upload user photo"
      minHeight={192}
      minWidth={192}
    />
  )
}
