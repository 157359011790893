import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { ImportGrantsInterface } from '@src/interfaces/importGrants'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportGrantColumn = GenericEditableTableColumn<ImportGrantsInterface>

export const importGrantsEmployeeEmailColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'employee_email',
  dataPoint: 'employee_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee Email',
  insert: ({ data }) => (
    <TextCell data={data} field="employee_email" onChange={onChange} />
  ),
})

export const importGrantsExternalGrantIdColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'external_id',
  dataPoint: 'external_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'External Grant Id',
  insert: ({ data }) => <TextCell data={data} field="external_id" onChange={onChange} />,
})

export const importGrantsReasonColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'reason',
  dataPoint: 'reason',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grant Reason',
  insert: ({ data }) => <TextCell data={data} field="reason" onChange={onChange} />,
})

export const importGrantsVestingScheduleDescriptionColumn: ImportGrantColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'vesting_schedule_description',
    dataPoint: 'vesting_schedule_description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Vesting Schedule Description',
    insert: ({ data }) => (
      <TextCell data={data} field="vesting_schedule_description" onChange={onChange} />
    ),
  })

export const importGrantsGrantDateColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'grant_effective_date',
  dataPoint: 'grant_effective_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grant Date',
  insert: ({ data }) => (
    <TextCell data={data} field="grant_effective_date" onChange={onChange} />
  ),
})

export const importGrantsVestingStartDateColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'vesting_start_date',
  dataPoint: 'vesting_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Vesting Start Date',
  insert: ({ data }) => (
    <TextCell data={data} field="vesting_start_date" onChange={onChange} />
  ),
})

export const importGrantsStockOptionsGrantedColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'total_quantity',
  dataPoint: 'total_quantity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stock Options Granted',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="total_quantity"
      onChange={onChange}
      type={TableCellInputType.positiveInt}
    />
  ),
})

export const importGrantsGrantTransactionTypeColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'grant_transaction_type',
  dataPoint: 'grant_transaction_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grant Transaction Type',
  insert: ({ data }) => (
    <TextCell data={data} field="grant_transaction_type" onChange={onChange} />
  ),
})

export const importGrantsGrantTransactionQuantityColumn: ImportGrantColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'quantity',
    dataPoint: 'quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Grant Transaction Quantity',
    insert: ({ data }) => (
      <TextCell
        data={data}
        field="quantity"
        onChange={onChange}
        type={TableCellInputType.int}
      />
    ),
  })

export const importGrantsStockOptionUnitValueColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'estimated_unit_price',
  dataPoint: 'estimated_unit_price',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stock Option Unit Value',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="estimated_unit_price"
      onChange={onChange}
      type={TableCellInputType.positiveFloat}
    />
  ),
})

export const importGrantsCurrencyColumn: ImportGrantColumn = onChange => ({
  type: CellTypes.insert,
  idPoint: 'estimated_unit_price_currency',
  dataPoint: 'estimated_unit_price_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.currencies,
  title: 'Currency',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="estimated_unit_price_currency"
      onChange={onChange}
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const importGrantsStockOptionVestEffectiveDateColumn: ImportGrantColumn =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'transaction_effective_date',
    dataPoint: 'transaction_effective_date',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Stock Option Vest Effective Date',
    insert: ({ data }) => (
      <TextCell data={data} field="transaction_effective_date" onChange={onChange} />
    ),
  })
