import React from 'react'
import { MoreBar } from '@revolut/ui-kit'

type Props = {
  onClick: () => void
}

const SendEmail = ({ onClick }: Props) => {
  return (
    <MoreBar.Action useIcon="Envelope" onClick={onClick}>
      Send email
    </MoreBar.Action>
  )
}

export default SendEmail
