import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { useGlobalSettings } from '@src/api/settings'
import useIsCommercial from '@src/hooks/useIsCommercial'
import AllOwnership from './AllOwnership'
import DirectReportsOwnership from './DirectReportsOwnership'
import FunctionalReportsOwnership from './FunctionalReportsOwnership'
import TeamsOwnership from './TeamsOwnership'
import DepartmentsOwnership from './DepartmentsOwnership'
import RequisitionsRecruiterOwnership from './RequisitionsRecruiterOwnership'
import KeyPersonOwnership from './KeyPersonOwnership'
import VendorOwnership from './VendorOwnership'
import DataOwnership from './DataOwnership'
import RequisitionsManagerOwnership from './RequisitionsManagerOwnership'
import JobPostingsRecruiterOwnership from './JobPostingsRecruiter'
import CandidatesRecruiterOwnership from './CandidatesRecruiterOwnership'
import CandidatesHiringManagerOwnership from './CandidatesHiringManagerOwnership'
import OffersOwnership from './OffersOwnership'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
  customHeader?: React.ReactNode
}

export const Ownership = (props: Props) => {
  const params = useParams<{ id: string }>()
  const { settings } = useGlobalSettings()
  const isCommercial = useIsCommercial()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.ALL, params),
      title: 'All',
      component: AllOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.all_ownership_items_count} />,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DIRECT_REPORTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DIRECT_REPORTS, params),
      title: 'Direct reports',
      component: DirectReportsOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.direct_reports_count} />,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.FUNCTIONAL_REPORTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.FUNCTIONAL_REPORTS, params),
      title: 'Functional reports',
      component: FunctionalReportsOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.functional_reports_count} />,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.TEAMS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.TEAMS, params),
      title: 'Teams',
      component: TeamsOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.owned_teams_count} />,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DEPARTMENTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DEPARTMENTS, params),
      title: 'Departments',
      component: DepartmentsOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.owned_departments_count} />,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.REQUISITIONS_RECRUITER,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.REQUISITIONS_RECRUITER, params),
      title: 'Requisitions recruiter',
      component: RequisitionsRecruiterOwnership,
      quickSummary: (
        <QuickSummaryCount count={props.stats?.recruiter_requisition_count} />
      ),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.REQUISITIONS_MANAGER,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.REQUISITIONS_MANAGER, params),
      title: 'Requisitions manager',
      component: RequisitionsManagerOwnership,
      quickSummary: (
        <QuickSummaryCount count={props.stats?.line_manager_requisition_count} />
      ),
    },
    ...(isCommercial
      ? []
      : [
          {
            path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.VENDORS,
            to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.VENDORS, params),
            title: 'Vendors',
            component: VendorOwnership,
            quickSummary: <QuickSummaryCount count={props.stats?.owned_vendors_count} />,
          },
          {
            path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DATA,
            to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.DATA, params),
            title: 'Data',
            component: DataOwnership,
            quickSummary: (
              <QuickSummaryCount count={props.stats?.owned_data_items_count} />
            ),
          },
        ]),
    settings?.key_person_enabled
      ? {
          path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.KEY_PERSON,
          to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.KEY_PERSON, params),
          title: 'Key person',
          component: KeyPersonOwnership,
          quickSummary: <QuickSummaryCount count={props.stats?.key_person_count} />,
        }
      : null,
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.JOB_POSTINGS_RECRUITER,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.JOB_POSTINGS_RECRUITER, params),
      title: 'Job postings recruiter',
      component: JobPostingsRecruiterOwnership,
      quickSummary: (
        <QuickSummaryCount count={props.stats?.recruiter_job_posting_count} />
      ),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.CANDIDATES_RECRUITER,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.CANDIDATES_RECRUITER, params),
      title: 'Candidates recruiter',
      component: CandidatesRecruiterOwnership,
      quickSummary: (
        <QuickSummaryCount count={props.stats?.recruiter_interview_round_count} />
      ),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.CANDIDATES_HIRING_MANAGER,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.CANDIDATES_HIRING_MANAGER, params),
      title: 'Candidates hiring manager',
      component: CandidatesHiringManagerOwnership,
      quickSummary: (
        <QuickSummaryCount count={props.stats?.hiring_manager_interview_round_count} />
      ),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.OWNERSHIP.OFFERS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.OWNERSHIP.OFFERS, params),
      title: 'Offers',
      component: OffersOwnership,
      quickSummary: <QuickSummaryCount count={props.stats?.owner_offer_form_count} />,
    },
  ].filter(Boolean)

  return (
    <>
      {props.customHeader}

      <Flex flexDirection="column" flex={1} minHeight={0}>
        <Flex alignItems="flex-start" mb="s-12">
          <TabBarNavigation
            isSubtab
            tabs={tabs}
            key={props.stats?.all_ownership_items_count}
          />
        </Flex>
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component {...props} />
            </Route>
          ))}
          <InternalRedirect to={tabs[0].path} />
        </Switch>
      </Flex>
    </>
  )
}
