import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { EmployeeEmailPlaceholderInterface } from '@src/interfaces/employeeEmails'

export const employeeEmailPlaceholderNameColumn: ColumnInterface<EmployeeEmailPlaceholderInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Placeholder',
  }

export const employeeEmailPlaceholderTypeColumn: ColumnInterface<EmployeeEmailPlaceholderInterface> =
  {
    type: CellTypes.text,
    idPoint: 'field_type.id',
    dataPoint: 'field_type.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
  }
