import React from 'react'
import pick from 'lodash/pick'
import { Box, InputGroup, Item, Token } from '@revolut/ui-kit'

import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PersonalInfoInterface } from '@src/interfaces/employees'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useGetEmployeeContactInfo } from '@src/api/employees'
import { addressLine2Message, addressLine3Message, cityMessage } from '../constants'

interface MandatoryAddressFields
  extends Pick<
    PersonalInfoInterface,
    'address_line_1' | 'city' | 'post_code' | 'country'
  > {}
const checkShipmentInfoCompleteness = (data?: MandatoryAddressFields) =>
  data?.address_line_1 != null &&
  data?.city != null &&
  data?.post_code != null &&
  data?.country != null

interface AddressFields extends MandatoryAddressFields {
  address_line_2: string
  address_line_3?: string
}
const formatAddress = (data?: AddressFields) => {
  if (!data) {
    return ''
  }
  const addressLine3 = data.address_line_3 ? ` ${data.address_line_3}` : ''
  return `${data.address_line_1} ${data.address_line_2}${addressLine3}, ${data.city} ${data.post_code}, ${data.country.name}`
}

type Props = {
  withStepperTitle?: boolean
  fetchContactInfo?: boolean
}
export const LaptopDeliveryInputs = ({ withStepperTitle, fetchContactInfo }: Props) => {
  const { values } = useLapeContext<PersonalInfoInterface>()
  const { data: employeeContactInfo } = useGetEmployeeContactInfo(
    fetchContactInfo ? values.employee?.id : undefined,
  )
  const contactInfo = fetchContactInfo
    ? employeeContactInfo
    : pick(values, [
        'address_line_1',
        'address_line_2',
        'address_line_3',
        'city',
        'post_code',
        'country',
      ])

  const shipToAddress = values.laptop_delivery?.id === 'ship_to_address'
  const shipmentInfoComplete = checkShipmentInfoCompleteness(
    contactInfo as MandatoryAddressFields,
  )

  const laptopDeliveryOptions = [
    { id: 'ship_to_address', name: 'Ship to my address' },
    { id: 'ship_to_different_address', name: 'Ship to different address' },
  ].map(option => ({
    label: option.name,
    value: option,
  }))
  return (
    <>
      {withStepperTitle && (
        <NewStepperTitle
          title="Laptop delivery"
          subtitle="How would you like to receive your work equipment?"
        />
      )}
      <InputGroup>
        <LapeRadioSelectInput
          name="laptop_delivery"
          label="Laptop delivery"
          options={laptopDeliveryOptions || []}
        />
        {values.laptop_delivery?.id === 'ship_to_different_address' ? (
          <>
            <LapeNewInput
              name="laptop_delivery_address_line_1"
              label="Address line 1"
              required
            />
            <LapeNewInput
              name="laptop_delivery_address_line_2"
              label="Address line 2"
              message={addressLine2Message}
              required
            />
            <LapeNewInput
              name="laptop_delivery_address_line_3"
              label="Address line 3"
              message={addressLine3Message}
            />
            <InputGroup variant="horizontal">
              <Box flex="1">
                <LapeNewInput
                  name="laptop_delivery_city"
                  label="City"
                  hideOptional
                  message={cityMessage}
                />
              </Box>
              <Box flex="1">
                <LapeNewInput
                  name="laptop_delivery_post_code"
                  label="ZIP code"
                  hideOptional
                />
              </Box>
            </InputGroup>

            <InputGroup variant="horizontal">
              <Box flex="1">
                <LapeNewInput name="laptop_delivery_county" label="County" />
              </Box>
              <Box flex="1">
                <LapeRadioSelectInput
                  selector={selectorKeys.countries}
                  name="laptop_delivery_country"
                  label="Country"
                />
              </Box>
            </InputGroup>
          </>
        ) : null}
        {shipToAddress && shipmentInfoComplete ? (
          <Item>
            <Item.Content>
              <Item.Title>
                Your laptop will be shipped to the following address:
              </Item.Title>
              <Item.Description color={Token.color.foreground}>
                {formatAddress(contactInfo as AddressFields)}
              </Item.Description>
            </Item.Content>
          </Item>
        ) : null}
        {shipToAddress && !shipmentInfoComplete ? (
          <ActionWidget
            title="You didn't provide your address information"
            text="Please, firstly fill all the information about your address in the above section of the form and then proceed with choosing this delivery option."
          />
        ) : null}
      </InputGroup>
    </>
  )
}
