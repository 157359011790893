import type { BoxProps } from '@revolut/ui-kit'
import { Box } from '@revolut/ui-kit'
import React from 'react'

export const ContentBlockLayout = (props: BoxProps) => (
  <Box
    marginX="auto"
    paddingY={{ all: 's-24', lg: '40px', xxl: '80px' }}
    paddingX={{ all: 's-20', lg: 0 }}
    use="section"
    width="100%"
    maxWidth={{ all: '1000px', xxl: '1432px' }}
    {...props}
  />
)
