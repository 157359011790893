import React, { useMemo, useRef } from 'react'
import { Box, Cell, Checkbox, Flex, InputGroup, Text, Token } from '@revolut/ui-kit'
import SlotDateFilter from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotDateFilter'
import SlotInterviewerFilter from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerFilter'
import InterviewSlotsSkeleton from '@src/pages/Forms/Candidate/ScheduleSidebar/InterviewSlotsSkeleton'
import InterviewDateSlots from '@src/features/InterviewDateSlots/InterviewDateSlots'
import { useGroupByDaySlots } from '@src/pages/Forms/Candidate/ScheduleSidebar/hooks'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import ExpectingMoreSlotsWidget from '@src/pages/Forms/Candidate/ScheduleSidebar/ExpectingMoreSlotsWidget'
import { Props as SlotInterviewerFilterProps } from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotInterviewerFilter'

export type SlotsSelectorProps = {
  dateFilter?: OptionInterface
  interviewerFilter?: OptionInterface[]
  isPrepCall: boolean
  loadingSlots?: boolean
  availableSlots?: AvailableInterviewerSlot[]
  selectedSlots?: AvailableInterviewerSlot[]
  slotInterviewerFilterProps?: Partial<SlotInterviewerFilterProps>
  timeZoneId: string
  warningContent?: React.ReactNode
  onInterviewerFilterChange: (option?: OptionInterface[] | undefined) => void
  onDateFilterChange: (option?: OptionInterface | undefined) => void
  onSlotChange: (slots: AvailableInterviewerSlot[]) => void
}

const SlotsSelector = ({
  dateFilter,
  interviewerFilter,
  isPrepCall,
  loadingSlots,
  availableSlots,
  selectedSlots,
  slotInterviewerFilterProps,
  timeZoneId,
  warningContent,
  onInterviewerFilterChange,
  onDateFilterChange,
  onSlotChange,
}: SlotsSelectorProps) => {
  const filtersAnchor = useRef<HTMLDivElement | null>(null)
  const selectedSlotsIds = useMemo(
    () => selectedSlots?.map(item => item.id),
    [selectedSlots],
  )
  const normalizedSlots = useGroupByDaySlots(timeZoneId, availableSlots)
  return (
    <>
      <Flex mb="s-16" ref={filtersAnchor}>
        <SlotDateFilter
          onChange={onDateFilterChange}
          value={dateFilter}
          anchor={filtersAnchor}
        />
        <Box ml="s-8">
          <SlotInterviewerFilter
            onChange={onInterviewerFilterChange}
            value={interviewerFilter}
            anchor={filtersAnchor}
            isPrepCall={isPrepCall}
            {...slotInterviewerFilterProps}
          />
        </Box>
      </Flex>
      <Box>
        {!normalizedSlots.length && !loadingSlots ? (
          <ActionWidget
            mt="s-16"
            title="No slots available"
            text={
              <Text>
                Eligible interviewers either have not marked any slots, or no slots are
                available for the window that you have selected. <br />
                <br /> To mark slots, interviewers need to create a recurring event in
                their calendar with the string “
                <Text color={Token.color.green}>[interview-slot]</Text>” added either in
                the subject or description of the event.
              </Text>
            }
          >
            {warningContent}
          </ActionWidget>
        ) : (
          <>
            <InputGroup>
              <Cell>
                <Checkbox
                  onChange={e => {
                    const checked = e.target.checked
                    if (checked) {
                      onSlotChange(availableSlots ? [...availableSlots] : [])
                    } else {
                      onSlotChange([])
                    }
                  }}
                  checked={
                    availableSlots?.length !== 0 &&
                    availableSlots?.length === selectedSlots?.length
                  }
                  data-testid="all-slots"
                >
                  <Text>Select all slots for {dateFilter?.name}</Text>
                </Checkbox>
              </Cell>
              <TimezoneSelect />
            </InputGroup>
            <InterviewDateSlots
              slots={normalizedSlots}
              selectedSlotIds={selectedSlotsIds ?? []}
              onChange={ids => {
                onSlotChange(availableSlots!.filter(slot => ids.includes(slot.id)))
              }}
              timeZone={timeZoneId}
            />
            {loadingSlots ? (
              <InterviewSlotsSkeleton />
            ) : (
              <ExpectingMoreSlotsWidget>{warningContent}</ExpectingMoreSlotsWidget>
            )}
          </>
        )}
      </Box>
    </>
  )
}

export default SlotsSelector
