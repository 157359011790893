import React from 'react'
import { useGetLatestOffer } from '@src/api/offerCreation'
import { Statuses } from '@src/interfaces'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Color, MoreBar } from '@revolut/ui-kit'
import { OfferCreationInterface } from '@src/interfaces/offerCreation'
import {
  EditOfferAction,
  MarkHiredAction,
  ViewEmployeeProfileAction,
  ViewOfferAction,
} from '@src/pages/Forms/Candidate/InterviewProgress/components/UpcomingOfferNotificationsActions'

interface Props {
  id: string | number
  signingEnabled: boolean
  onRefresh: () => void
}

const getWidgetData = (
  latestOffer: OfferCreationInterface,
  signingEnabled: boolean,
  onRefresh: () => void,
): {
  title: string
  actions: React.ReactNode[]
} => {
  if (latestOffer.employee_id) {
    return {
      title: 'Candidate hired',
      actions: [
        <ViewEmployeeProfileAction key={1} offer={latestOffer} />,
        <ViewOfferAction key={2} offer={latestOffer} />,
      ],
    }
  }

  switch (latestOffer.status) {
    case Statuses.pending: {
      return {
        title: 'Offer created for this candidate and is pending approval',
        actions: [<ViewOfferAction key={1} offer={latestOffer} />],
      }
    }

    case Statuses.approved: {
      if (!signingEnabled) {
        return {
          title: 'Offer approved for this candidate',
          actions: [
            <MarkHiredAction key={1} offerId={latestOffer.id} onRefresh={onRefresh} />,
            <ViewOfferAction key={1} offer={latestOffer} />,
          ],
        }
      }

      switch (latestOffer.signing_status) {
        case 'offer_signed': {
          return {
            title: 'Offer signed by candidate',
            actions: [
              <MarkHiredAction key={1} offerId={latestOffer.id} onRefresh={onRefresh} />,
              <ViewOfferAction key={2} offer={latestOffer} />,
            ],
          }
        }
      }

      return {
        title: 'Offer approved for this candidate',
        actions: [<ViewOfferAction key={1} offer={latestOffer} />],
      }
    }

    case Statuses.rejected: {
      return {
        title: 'Offer rejected for this candidate',
        actions: [
          <EditOfferAction key={1} offer={latestOffer} />,
          <ViewOfferAction key={2} offer={latestOffer} />,
        ],
      }
    }

    case Statuses.archived: {
      return {
        title: 'Offer archived for this candidate',
        actions: [<ViewOfferAction key={1} offer={latestOffer} />],
      }
    }

    default: {
      return {
        title: 'Offer created for this candidate',
        actions: [<ViewOfferAction key={1} offer={latestOffer} />],
      }
    }
  }
}

const UpcomingOfferNotifications = ({ id, signingEnabled, onRefresh }: Props) => {
  const { data: latestOffer } = useGetLatestOffer(+id)

  if (!latestOffer) {
    return null
  }

  const { title, actions } = getWidgetData(latestOffer, signingEnabled, onRefresh)

  return (
    <ActionWidget title={title} avatarColor={Color.BLUE}>
      <MoreBar>{actions}</MoreBar>
    </ActionWidget>
  )
}

export default UpcomingOfferNotifications
