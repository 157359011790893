import React from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'

interface Props {
  isOpen: boolean
  isRoadmap: boolean
  onClose: () => void
  onConfirm: () => void
}

const commonTitle = 'Deleting this cycle will also delete the future cycles'
const roadmapTitle =
  'Deleting this cycle will also delete the future cycles & its deliverables'

const commonSubmitText = 'Delete cycle'
const roadmapSubmitText = 'Delete cycle & deliverables'

const DeleteWarningPopup = ({ isRoadmap, isOpen, onClose, onConfirm }: Props) => {
  return (
    <StatusPopup variant="warning" open={isOpen} onClose={onClose}>
      <StatusPopup.Title>{isRoadmap ? roadmapTitle : commonTitle}</StatusPopup.Title>
      <StatusPopup.Actions>
        <Button
          elevated
          onClick={e => {
            onConfirm()
            onClose()
            e.stopPropagation()
          }}
        >
          {isRoadmap ? roadmapSubmitText : commonSubmitText}
        </Button>
        <Button
          variant="secondary"
          onClick={e => {
            onClose()
            e.stopPropagation()
          }}
        >
          Cancel
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default DeleteWarningPopup
