import React from 'react'
import last from 'lodash/last'
import {
  Color,
  ExpandableCell,
  ProgressStepState,
  ProgressSteps,
  ProgressStep,
  ProgressStepSkeleton,
  TextSkeleton,
  chain,
} from '@revolut/ui-kit'
import { formatDate, formatDateTime } from '@src/utils/format'
import { SigningStatuses } from '@src/interfaces/offerCreation'
import upperFirst from 'lodash/upperFirst'
import { removeUnderscore } from '@src/utils/string'

type OfferStatusProps = {
  cancelledDate?: string
  createdDate?: string
  declinedDate?: string
  expiredDate?: string
  expirationDate?: string
  openedDate?: string
  sentDate?: string
  signedDate?: string
  signingStatus?: SigningStatuses
}

type OfferStep = {
  index: number
  color: Color
  date?: string
  dueDate?: string
  status: ProgressStepState
  signingStatus: SigningStatuses
  title: string
}

const OfferStatus = ({
  cancelledDate,
  createdDate,
  declinedDate,
  expiredDate,
  expirationDate,
  openedDate,
  sentDate,
  signedDate,
  signingStatus,
}: OfferStatusProps) => {
  if (!signingStatus) {
    return (
      <ExpandableCell>
        <ExpandableCell.Title>Offer letter status</ExpandableCell.Title>
        <ExpandableCell.Content>
          <TextSkeleton size={8} />
        </ExpandableCell.Content>
        <ExpandableCell.Note>
          <ProgressSteps variant="vertical-compact">
            <ProgressStepSkeleton />
            <ProgressStepSkeleton />
            <ProgressStepSkeleton />
            <ProgressStepSkeleton />
          </ProgressSteps>
        </ExpandableCell.Note>
      </ExpandableCell>
    )
  }

  const steps: OfferStep[] = [
    {
      index: 0,
      color: Color.GREY_10,
      date: createdDate,
      status: ProgressStepState.DEFAULT,
      signingStatus: 'pending_candidate_signature',
      title: 'Created on DocuSign',
    },
    {
      index: 1,
      date: sentDate,
      color: Color.GREY_10,
      status: ProgressStepState.DEFAULT,
      signingStatus: 'pending_candidate_signature',
      title: 'Offer sent from DocuSign',
    },
    {
      index: 2,
      date: openedDate,
      color: Color.GREY_10,
      status: ProgressStepState.DEFAULT,
      signingStatus: 'pending_candidate_signature',
      title: 'Offer opened by candidate',
    },
  ]

  switch (signingStatus) {
    case 'offer_not_sent':
    case 'pending_candidate_signature':
    case 'offer_signed': {
      steps.push({
        index: 3,
        color: signingStatus === 'offer_signed' ? Color.GREEN : Color.GREY_10,
        date: signedDate,
        dueDate: expirationDate,
        status: ProgressStepState.DEFAULT,
        signingStatus: 'offer_signed',
        title: 'Offer signed by candidate',
      })
      break
    }
    case 'offer_cancelled_recruiter': {
      steps.push({
        index: 3,
        color: Color.RED,
        date: cancelledDate,
        status: ProgressStepState.FAILED,
        signingStatus,
        title: 'Offer cancelled by recruiter',
      })
      break
    }
    case 'offer_declined_recruiter':
    case 'offer_declined_candidate': {
      steps.push({
        index: 3,
        color: Color.RED,
        date: declinedDate,
        status: ProgressStepState.FAILED,
        signingStatus,
        title: `Offer declined by ${
          signingStatus === 'offer_declined_recruiter' ? 'recruiter' : 'candidate'
        }`,
      })
      break
    }
    case 'offer_expired': {
      steps.push({
        index: 3,
        color: Color.RED,
        date: expiredDate,
        status: ProgressStepState.FAILED,
        signingStatus: 'offer_expired',
        title: 'Offer expired',
      })
    }
  }

  // need to know what steps are done
  const doneSteps = steps.filter(step => {
    if (signingStatus === 'pending_candidate_signature') {
      // all steps that belong to 'pending_candidate_signature' signingStatus
      // are done if they have a date
      return step.date
    }
    if (signingStatus === step.signingStatus) {
      // if the signingStatus is offer_signed
      // | offer_expired
      // | offer_declined_recruiter
      // | offer_declined_candidate
      // | offer_cancelled_recruiter
      // then the step is done
      return true
    }
    return false
  })

  // a step that has no date is considered done if any step after it is done
  // this is also used for defining the pending step
  const lastDoneIndex = last(doneSteps)?.index ?? -1
  const currentSteps = steps.map(step => {
    if (signingStatus === 'offer_not_sent') {
      // in this signingStatus we skip all steps as nothing is done or pending
      return step
    }
    let color: Color = step.color
    let status: ProgressStepState = step.status
    if (lastDoneIndex + 1 === step.index) {
      // last step that is done is behind the current step
      // so current step is marked as pending
      status = ProgressStepState.PENDING
      color = Color.BLUE
    } else if (lastDoneIndex >= step.index) {
      // last step done is still ahead
      // so current step is marked as done
      status = ProgressStepState.DONE
      color = step.color !== Color.GREY_10 ? step.color : Color.BLUE
    }
    return {
      ...step,
      color,
      status,
    }
  })

  const lastDoneStep = currentSteps[lastDoneIndex]
  const lastDoneStepTitle =
    lastDoneStep?.title ?? upperFirst(removeUnderscore(signingStatus))
  const lastDoneStepColor = lastDoneStep?.color ?? Color.GREY_10
  const prefersExpanded = signingStatus === 'pending_candidate_signature'

  return (
    <ExpandableCell key={signingStatus} prefersExpanded={prefersExpanded}>
      <ExpandableCell.Title>Offer letter status</ExpandableCell.Title>
      <ExpandableCell.Content color={lastDoneStepColor} fontWeight={500}>
        {chain(`${lastDoneIndex + 1}/4`, lastDoneStepTitle)}
      </ExpandableCell.Content>
      <ExpandableCell.Note>
        <ProgressSteps variant="vertical-compact">
          {currentSteps.map(({ color, date, dueDate, status, title }) => (
            <ProgressStep
              key={title}
              indicatorColor={color}
              state={status}
              data-testid={`offer-status-step-${status}`}
            >
              <ProgressStep.Title>{title}</ProgressStep.Title>
              <ProgressStep.Description>
                {date && formatDateTime(date)}
                {dueDate && !date && `Due ${formatDate(dueDate)}`}
              </ProgressStep.Description>
            </ProgressStep>
          ))}
        </ProgressSteps>
      </ExpandableCell.Note>
    </ExpandableCell>
  )
}

export default OfferStatus
