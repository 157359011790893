import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FilterButton } from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import {
  dynamicGroupsCreatedDateColumn,
  dynamicGroupsDescriptionColumn,
  dynamicGroupsGroupNameColumn,
  dynamicGroupsOwnerColumn,
  dynamicGroupsStatusColumn,
  dynamicGroupsTypeColumn,
} from '@src/constants/columns/dynamicGroups'
import { getDynamicGroups } from '@src/api/dynamicGroups'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const Row: RowInterface<DynamicGroupInerface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: data.id })),
  cells: [
    {
      ...dynamicGroupsGroupNameColumn,
      width: 240,
    },
    {
      ...dynamicGroupsTypeColumn,
      width: 150,
    },
    {
      ...dynamicGroupsDescriptionColumn,
      width: 500,
    },
    {
      ...dynamicGroupsCreatedDateColumn,
      width: 150,
    },
    {
      ...dynamicGroupsOwnerColumn,
      width: 250,
    },
    {
      ...dynamicGroupsStatusColumn,
      width: 150,
    },
  ],
}

const DynamicGroupsTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_DYNAMIC_GROUPS_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'owner__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<DynamicGroupInerface>(
    {
      getItems: getDynamicGroups,
    },
    getInitialFilters(),
  )
  const permissions = useSelector(selectPermissions)
  const canAddGroup = permissions.includes(PermissionTypes.AddDynamicGroups)

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAddGroup && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
              useIcon="Plus"
            >
              Add Group
            </PrimaryAction>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My Groups
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<DynamicGroupInerface>
          name={TableNames.DynamicGroups}
          useWindowScroll
          dataType="Group"
          row={Row}
          {...table}
          emptyState={<EmptyTableRaw title="Groups will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default DynamicGroupsTable
