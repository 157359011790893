import { Item, Spacer } from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import LapeRadioSelectInput, {
  LapeRadioSelectInputProps,
} from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { GradeCalculationMethods } from '@src/interfaces/reviewCycles'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import React, { useEffect } from 'react'

export const PerformanceGradeLogic = (
  props: Partial<LapeRadioSelectInputProps<IdAndName<GradeCalculationMethods>>>,
) => {
  const { values } = useLapeContext<
    PerformanceSettingsInterface & {
      ui_only_grade_calculation_method: IdAndName<GradeCalculationMethods>
    }
  >()
  const { data: options } = useGetSelectors<IdAndName<GradeCalculationMethods>>(
    selectorKeys.grade_calculation_methods,
  )

  useEffect(() => {
    if (!values.ui_only_grade_calculation_method && values.grade_calculation_method) {
      values.ui_only_grade_calculation_method = {
        id: values.grade_calculation_method,
        name: values.grade_calculation_method,
      }
    }

    if (
      values.ui_only_grade_calculation_method &&
      values.ui_only_grade_calculation_method.id !== values.grade_calculation_method
    ) {
      values.grade_calculation_method = values.ui_only_grade_calculation_method.id
    }
  }, [values.grade_calculation_method, values.ui_only_grade_calculation_method])

  return (
    <Item>
      <Item.Content>
        <Item.Title>Define the performance grade logic</Item.Title>
        <Item.Description>
          This grade will be used as the input for calibration
        </Item.Description>
        <Spacer height="s-16" />
        <LapeRadioSelectInput<IdAndName<GradeCalculationMethods>>
          label="Recommended grade logic"
          name="ui_only_grade_calculation_method"
          options={options?.map(option => ({
            value: option,
            label: option.name,
          }))}
          {...props}
        />
      </Item.Content>
    </Item>
  )
}
