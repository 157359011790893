import React from 'react'
import { H1, Paragraph, ThemeProvider, Flex, Image, Absolute } from '@revolut/ui-kit'
import { ReactComponent as LogoAlt } from '@src/assets/Revolut_People_alt.svg'
import { GlobalStyle } from '@src/pages/Landing/styles'

export const ComingSoon = () => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider mode="dark">
        <Flex
          width="100%"
          height="100%"
          flexDirection="column"
          alignItems="center"
          css={{
            background:
              'url(https://assets.revolut.com/revoluters/assets/bg-blur.jpg) no-repeat center center fixed',
            backgroundSize: 'cover',
          }}
        >
          <Flex
            maxWidth={505}
            height="80%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Absolute top={32}>
              <LogoAlt height="40" width="200" />
            </Absolute>

            <Image
              size={180}
              image={{
                default: 'https://assets.revolut.com/assets/3d-images-v2/3D264.png',
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D264@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D264@3x.png',
              }}
              css={{
                transform: 'rotate(-7deg)',
              }}
            />
            <H1 mb="s-8" mt="s-24" textAlign="center">
              Coming soon!
            </H1>
            <Paragraph variant="secondary" textAlign="center">
              The product is not yet available in your location, but we are working hard
              to make it happen as soon as possible!
            </Paragraph>
          </Flex>
        </Flex>
      </ThemeProvider>
    </>
  )
}
