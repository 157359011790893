import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { ConnectionsTable } from '@src/apps/General/DataAnalytics/ConnectionsTable'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'

export const AnalyticsSettings = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Analytics Settings"
        subtitle="Manage connections"
        backUrl={ROUTES.PERFORMANCE.ANALYTICS.DASHBOARDS}
      />
      <PageBody maxWidth="100%">
        <ConnectionsTable />
      </PageBody>
    </PageWrapper>
  )
}
