import SideBar from '@components/SideBar/SideBar'
import React, { useState } from 'react'
import { MoreBar, Box, Widget, Text, HStack, Color } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotificationTemplateInterface,
  NotificationType,
} from '@src/interfaces/notificationTemplate'
import { connect } from 'lape'
import { AnnouncementTile } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/AnnouncementTile'
import Tooltip from '@components/Tooltip/Tooltip'
import { InfoOutline, Document } from '@revolut/icons'
import { EditorView } from '@components/Editor/EditorView'
import { getAvatarUrl } from '@src/utils/employees'
import { useAnnouncementWidth } from '@src/pages/Home/HomeSubTabs/Dashboard/components/Announcements/common'

const AnnouncementPreview = () => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const { values } = useLapeContext<NotificationTemplateInterface>()

  const announcementWidth = useAnnouncementWidth()

  if (values.type?.id !== NotificationType.announcement) {
    return null
  }

  return (
    <>
      <MoreBar.Action onClick={() => setPreviewOpen(!previewOpen)} useIcon={Document}>
        Preview
      </MoreBar.Action>
      <SideBar
        isOpen={previewOpen}
        onClose={() => setPreviewOpen(false)}
        title="Announcement preview"
        sideProps={{ resizable: true }}
        variant="wide"
      >
        <Text variant="h6" color={Color.GREY_TONE_50} pb="s-16">
          <HStack gap="s-8" align="center">
            Small banner
            <Tooltip placement="top" text="Announcement look on the dashboard">
              <InfoOutline size={16} color={Color.GREY_TONE_50} />
            </Tooltip>
          </HStack>
        </Text>
        <Box width={announcementWidth}>
          <AnnouncementTile
            title={values.announcement_title!}
            color={values.announcement_color}
            icon={getAvatarUrl(values.owner.avatar)}
            backgroundIcon={values.announcement_background_icon}
            description={values.announcement_subtitle}
            senderName={values.owner.full_name}
            onClick={() => {}}
            date={values.created_date_time}
            pinned={values.announcement_pinned!}
            dismiss={() => {}}
            showIcon={values.announcement_show_icon!}
          />
        </Box>

        {values.announcement_action === 'text' ? (
          <>
            <Text variant="h6" color={Color.GREY_TONE_50} pt="s-32" pb="s-16">
              <HStack gap="s-8" align="center">
                Full text
                <Tooltip
                  placement="top"
                  text="Announcement full preview in the side panel"
                >
                  <InfoOutline size={16} color={Color.GREY_TONE_50} />
                </Tooltip>
              </HStack>
            </Text>
            <Widget p="s-16">
              <Text variant="h5" pb="s-16" use="p">
                {values.announcement_title}
              </Text>
              <EditorView html={values.template_text} />
            </Widget>
          </>
        ) : null}
      </SideBar>
    </>
  )
}

export default connect(AnnouncementPreview)
