import React, { useState, useEffect } from 'react'
import {
  Box,
  chain,
  Color,
  Flex,
  Text,
  MoreBar,
  Cell,
  ProgressSteps,
  ProgressStep,
} from '@revolut/ui-kit'
import {
  ArrowRightLeft,
  Chat,
  Check,
  CrossCircle,
  CrossVoid,
  Envelope,
  Profile,
  Time,
} from '@revolut/icons'
import { Route, Switch } from 'react-router-dom'
import format from 'date-fns/format'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { FormErrorGuard } from '@src/features/Form/FormErrorGuard'
import { ROUTES } from '@src/constants/routes'
import {
  cancelOnboardingProcess,
  getOnboardingTimeline,
  getOnboardingTimelineCommentsAPI,
  updateHRManager,
} from '@src/api/onboardingEmployees'
import { updateEmployeeStatus as updateEmployeeStatusApi } from '@src/api/employees'
import {
  OnboardingTimelineInterface,
  OnboardingTimelineProcessStage,
  ProcessStages,
  ProcessStageSteps,
} from '@src/interfaces/onboarding'
import { formatPeriod, formatWithoutTimezone } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { StartStage } from './StartStage'
import { WorkStage } from './WorkStage'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import {
  findProcessStage,
  OpenedSidebar,
  processStatusToColor,
  StartDateSidebar,
  useRouteParams,
} from './common'
import { DocumentsStage } from './DocumentsStage'
import { ContractStage } from './ContractStage'
import { ScreeningStage } from './ScreeningStage'
import { RightToWorkStage } from './RightToWorkStage'
import { FinishStage } from './FinishStage'
import SideBar from '@src/components/SideBar/SideBar'
import { EmployeeEmails } from '@src/features/EmployeeEmails/EmployeeEmails'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  EmployeeStatusTransitionsInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'
import SettingsButtons from '@src/features/SettingsButtons'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { PreTitle } from '@src/features/TabBarNavigation/PreTitle'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { OnboardingSendEmailAction } from '../OnboardingSendEmail/common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetLifecycleSettings, useGetScreeningSettings } from '@src/api/settings'
import { Jira as JiraButton } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Links/Jira'

const formatEmployeeInfo = (employee: EmployeeInterface) => {
  const { position, team, location } = employee

  let employeePosition = position?.name || ''
  let employeeTeam = team?.name || ''
  let employeeLocation = location?.name || ''

  if (!employeePosition && !employeeTeam && !employeeLocation) {
    return ''
  }
  employeeTeam = employeePosition ? ` at ${employeeTeam}` : employeeTeam
  employeeLocation =
    employeePosition || employeeTeam ? `, ${employeeLocation}` : employeeLocation

  return `${employeePosition}${employeeTeam}${employeeLocation}`
}

const OnboardingTimeline = () => {
  const params = useRouteParams()

  const [state, setState] = useState<OnboardingTimelineInterface>()
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<any>(null)
  const [markAsNotHiredPending, setMarkAsNotHiredPending] = useState(false)
  const [confirmOwnershipPending, setConfirmOwnershipPending] = useState(false)
  const [newHrManager, setNewHrManager] = useState<EmployeeOptionInterface>()

  const [openedSidebar, setOpenedSidebar] = useState<OpenedSidebar>(null)
  const [cancelPopupOpen, setCancelPopupOpen] = useState(false)
  const [cancelPending, setCancelPending] = useState(false)

  const commentsApi = getOnboardingTimelineCommentsAPI(params.employeeId, params.id)
  const {
    data: chatData,
    isLoading: isChatLoading,
    refetch: refetchChat,
  } = commentsApi.useGetComments()

  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const { data: screeningSettings } = useGetScreeningSettings()

  const fetchOnboardingTimeline = () => {
    getOnboardingTimeline(params.employeeId, params.id)
      .then(response => {
        setState(response.data)
        setNewHrManager(response.data.hr_manager)
      })
      .catch(err => setError(err))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    fetchOnboardingTimeline()

    /** SLAs are measured in hours, we need to update the view once in a while to see the progress of these SLAs */
    const refreshInterval = setInterval(() => {
      fetchOnboardingTimeline()
    }, 10 * 60 * 1000)

    return () => clearInterval(refreshInterval)
  }, [])

  if (isLoading) {
    return <PageLoading />
  }

  if (error) {
    return <FormErrorGuard error={error} />
  }

  const { employee, hr_manager } = state!
  const employeeInfo = formatEmployeeInfo(employee)

  const showMarkAsNotHired =
    employee.status.id === IdStatuses.onboarding ||
    employee.status.id === IdStatuses.pending ||
    employee.status.id === IdStatuses.hired

  const onMarkAsNotHired = () => {
    setMarkAsNotHiredPending(true)

    updateEmployeeStatusApi(employee.id, {
      status: { id: IdStatuses.not_hired },
    } as EmployeeStatusTransitionsInterface)
      .then(response => {
        updateEmployeeStatus(response.data.status)
      })
      .finally(() => {
        setMarkAsNotHiredPending(false)
      })
  }

  const startStage = findProcessStage(state!, ProcessStages.Start)!
  const workStage = findProcessStage(state!, ProcessStages.Work)!
  const documentsStage = findProcessStage(state!, ProcessStages.Documents)!
  const contractStage = findProcessStage(state!, ProcessStages.Contract)!
  const screeningStage = findProcessStage(state!, ProcessStages.Screening)
  const rightToWorkStage = findProcessStage(state!, ProcessStages.RightToWork)
  const finishStage = findProcessStage(state!, ProcessStages.Finish)!

  const reviewDetailsStep = startStage.process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ReviewDetails,
  )!
  const emailDisabled = reviewDetailsStep.status.id !== 'completed'

  const tabs = [
    {
      title: 'Start',
      description: 'Complete basic steps to start onboarding',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE.START,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.START, params),
      preTitle: <PreTitle complete={startStage.status.id === 'completed'} />,
      component: StartStage,
      data: startStage,
    },
    {
      title: 'Work',
      description: 'Verify work related information',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE.WORK,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.WORK, params),
      preTitle: <PreTitle complete={workStage.status.id === 'completed'} />,
      component: WorkStage,
      data: workStage,
    },
    {
      title: 'Documents',
      description: 'Verify upload status of requested documents.',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.DOCUMENTS, params),
      preTitle: <PreTitle complete={documentsStage.status.id === 'completed'} />,
      component: DocumentsStage,
      data: documentsStage,
    },
    {
      title: 'Contract',
      description:
        'Issue contract, generate documents and obtain signatures from HR and candidate',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE.CONTRACT,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.CONTRACT, params),
      preTitle: <PreTitle complete={contractStage.status.id === 'completed'} />,
      component: ContractStage,
      data: contractStage,
    },
    screeningStage && !!screeningSettings?.enabled
      ? {
          title: 'Screening',
          description:
            'Review screening results and request adjudication (if applicable)',
          path: ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING,
          to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING, params),
          preTitle: (
            <PreTitle
              complete={screeningStage.status.id === 'completed'}
              skipped={screeningStage.status.id === 'skipped'}
            />
          ),
          component: ScreeningStage,
          data: screeningStage,
        }
      : null,
    rightToWorkStage
      ? {
          title: 'Right to work',
          description: 'Review right to work',
          path: ROUTES.FORMS.ONBOARDING_TIMELINE.RIGHT_TO_WORK,
          to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.RIGHT_TO_WORK, params),
          preTitle: (
            <PreTitle
              complete={rightToWorkStage.status.id === 'completed'}
              skipped={rightToWorkStage.status.id === 'skipped'}
            />
          ),
          component: RightToWorkStage,
          data: rightToWorkStage,
        }
      : null,
    {
      title: 'Finish',
      description: 'Complete final steps before Day 1',
      path: ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH,
      to: pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH, params),
      preTitle: <PreTitle complete={finishStage.status.id === 'completed'} />,
      component: FinishStage,
      data: finishStage,
    },
  ].filter(Boolean)

  const updateStep = (process: OnboardingTimelineInterface) => {
    setState(process)
  }

  const updateStage = (stage: OnboardingTimelineProcessStage) => {
    setState(prevState => ({
      ...prevState!,
      process_stages: prevState!.process_stages.map(s => (s.id === stage.id ? stage : s)),
    }))
    fetchOnboardingTimeline()
  }

  const updateEmployeeStatus = (status: IdAndName<IdStatuses>) => {
    setState(prevState => ({
      ...prevState!,
      employee: { ...prevState!.employee, status },
    }))
  }

  const onConfirmTransferOwnership = async () => {
    if (!newHrManager || !state) {
      return
    }

    setConfirmOwnershipPending(true)

    try {
      const response = await updateHRManager(employee.id, state!.id, newHrManager)
      setState({ ...state, hr_manager: response.data.hr_manager })
      setOpenedSidebar(null)
    } finally {
      setConfirmOwnershipPending(false)
    }
  }

  const onCancelProcess = () => {
    setCancelPending(true)

    cancelOnboardingProcess(params.employeeId, params.id)
      .then(response => {
        setState(prevState => ({ ...prevState!, status: response.data.status }))
        setCancelPopupOpen(false)
      })
      .finally(() => {
        setCancelPending(false)
      })
  }

  const markAsNotHiredButton = showMarkAsNotHired ? (
    <MoreBar.Action
      onClick={onMarkAsNotHired}
      pending={markAsNotHiredPending}
      useIcon={CrossVoid}
    >
      Mark as not hired
    </MoreBar.Action>
  ) : null

  const delayStartDateButton = (
    <MoreBar.Action
      onClick={() => setOpenedSidebar('confirm-date')}
      variant="negative"
      useIcon={Time}
    >
      Delay start date
    </MoreBar.Action>
  )

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={chain(
            'Onboarding timeline',
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            <Text color={processStatusToColor[state!.status.id]} data-testid="status">
              {state!.status.name}
            </Text>,
          )}
          subtitle={
            <Flex flexDirection="column" gap="s-4" mt="s-8">
              <Flex>
                {chain(
                  <UserWithAvatar {...state!.employee} status={undefined} asText />,
                  employeeInfo && (
                    <Text fontSize="16px" color={Color.FOREGROUND}>
                      {employeeInfo}
                    </Text>
                  ),
                )}
              </Flex>
              <Text color={Color.GREY_TONE_50}>
                {chain(
                  `Start date: ${
                    state?.employee.joining_date_time
                      ? formatWithoutTimezone(state.employee.joining_date_time)
                      : '-'
                  }`,
                  hr_manager && `Onboarding manager: ${hr_manager.full_name}`,
                )}
              </Text>
            </Flex>
          }
          backUrl={ROUTES.APPS.LIFECYCLE.OFFBOARDING}
          pb="0"
        >
          <TabBarNavigation tabs={tabs} mb="s-16" />
        </PageHeader>

        <Flex flexDirection="column" width="100%" flex={1} minHeight={0}>
          <SettingsButtons maxWidth={720}>
            {lifecycleSettings?.enable_sla_tracking ? (
              <MoreBar.Action onClick={() => setOpenedSidebar('deadline')} useIcon={Time}>
                Deadlines
              </MoreBar.Action>
            ) : null}
            <MoreBar.Action useIcon={Chat} onClick={() => setOpenedSidebar('chat')}>
              Comments
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => setOpenedSidebar('correspondence')}
              useIcon={Envelope}
              disabled={emailDisabled}
            >
              Correspondence
            </MoreBar.Action>
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employee.id })}
              use={InternalLink}
              useIcon={Profile}
            >
              Profile
            </MoreBar.Action>
            <JiraButton data={employee} />
            <MoreBar.Action
              onClick={() => setOpenedSidebar('transfer-ownership')}
              useIcon={ArrowRightLeft}
            >
              Transfer ownership
            </MoreBar.Action>
            {markAsNotHiredButton}
            {delayStartDateButton}
            {state?.status.id === 'cancelled' ? null : (
              <MoreBar.Action
                onClick={() => setCancelPopupOpen(true)}
                variant="negative"
                useIcon={CrossCircle}
              >
                Cancel process
              </MoreBar.Action>
            )}
          </SettingsButtons>

          <Switch>
            {tabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component
                  stage={tab.data}
                  data={state!}
                  updateStep={updateStep}
                  updateStage={updateStage}
                  updateEmployeeStatus={updateEmployeeStatus}
                  setOpenedSidebar={setOpenedSidebar}
                  openedSidebar={openedSidebar}
                />
              </Route>
            ))}
          </Switch>
        </Flex>
      </PageWrapper>

      <SideBar
        title={`Correspondence with ${employee.first_name.substring(0, 1)}. ${
          employee.last_name
        }`}
        isOpen={
          openedSidebar === 'correspondence' ||
          openedSidebar === 'send-welcome-email' ||
          openedSidebar === 'send-day-1-instructions' ||
          openedSidebar === 'send-follow-up-email'
        }
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <EmployeeEmails
          employeeId={employee.id}
          emailUrl={(() => {
            if (openedSidebar === 'send-welcome-email') {
              return pathToUrl(ROUTES.FORMS.ONBOARDING_SEND_EMAIL.GENERAL, {
                employeeId: params.employeeId,
                processId: params.id,
                action: OnboardingSendEmailAction.Welcome,
              })
            }
            if (openedSidebar === 'send-day-1-instructions') {
              return pathToUrl(ROUTES.FORMS.ONBOARDING_SEND_EMAIL.GENERAL, {
                employeeId: params.employeeId,
                processId: params.id,
                action: OnboardingSendEmailAction.Day1Instructions,
              })
            }
            if (openedSidebar === 'send-follow-up-email') {
              return pathToUrl(ROUTES.FORMS.ONBOARDING_SEND_EMAIL.GENERAL, {
                employeeId: params.employeeId,
                processId: params.id,
                action: OnboardingSendEmailAction.FollowUp,
              })
            }
            return undefined
          })()}
        />
      </SideBar>

      <SideBar
        title="Transfer ownership"
        isOpen={openedSidebar === 'transfer-ownership'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            onClick={onConfirmTransferOwnership}
            variant="accent"
            pending={confirmOwnershipPending}
            disabled={newHrManager?.id === hr_manager?.id}
          >
            Confirm
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <RadioSelectInput
            label="HR manager"
            selector={selectorKeys.employee}
            onChange={option => {
              if (option) {
                setNewHrManager(option)
              }
            }}
            value={newHrManager}
          />
        </Box>
      </SideBar>

      <SideBar
        title="Deadlines · SLA timeline"
        isOpen={openedSidebar === 'deadline'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <SettingsButtons mb="s-16">
          {markAsNotHiredButton}
          {delayStartDateButton}
        </SettingsButtons>
        <Cell>
          <Flex gap="s-8" flexDirection="column">
            <Text variant="primary">
              Onboarding timeline:{' '}
              {formatPeriod(state!.start_date_time, finishStage.deadline!)}
            </Text>
            <Text mb="s-16">
              Start date: {formatWithoutTimezone(state!.employee.joining_date_time)}
            </Text>
            <ProgressSteps variant="vertical">
              {tabs.map(step => {
                const completed = step.data.status.id === 'completed'
                const inProgress = step.data.status.id === 'in_progress'
                const slaOverdue = step.data.sla / step.data.stage.sla_in_hours > 1
                const caption = (() => {
                  if (completed && step.data.end_date_time) {
                    return (
                      <Flex justifyContent="center">
                        {format(new Date(step.data.end_date_time), 'd MMM')}
                        <br />
                        {format(new Date(step.data.end_date_time), 'HH:mm')}
                      </Flex>
                    )
                  }
                  return (
                    <Flex justifyContent="center">
                      due {format(new Date(step.data.deadline!), 'd MMM')}
                      <br />
                      {format(new Date(step.data.deadline!), 'HH:mm')}
                    </Flex>
                  )
                })()
                const color = (() => {
                  if (completed) {
                    return Color.GREEN
                  }
                  if (slaOverdue) {
                    return Color.RED
                  }
                  return Color.BLUE
                })()

                return (
                  <ProgressStep
                    state={completed || inProgress ? 'done' : 'pending'}
                    color={color}
                    key={step.title}
                  >
                    <ProgressStep.Caption>{caption}</ProgressStep.Caption>
                    <ProgressStep.Title>{step.title}</ProgressStep.Title>
                    <ProgressStep.Description>
                      {step.description}
                    </ProgressStep.Description>
                  </ProgressStep>
                )
              })}
            </ProgressSteps>
          </Flex>
        </Cell>
      </SideBar>

      <StartDateSidebar
        open={openedSidebar === 'confirm-date'}
        onClose={() => setOpenedSidebar(null)}
        data={state!}
        candidateId={state!.employee.candidate_id}
        refetchProcess={() => fetchOnboardingTimeline()}
      />

      <ChatSidebar
        type={ChatMessageType.Comment}
        isOpen={openedSidebar === 'chat'}
        onClose={() => setOpenedSidebar(null)}
        data={chatData?.results || []}
        isLoading={isChatLoading}
        refetch={refetchChat}
        onAddMessage={commentsApi.addComment}
        onEdit={commentsApi.editComment}
        onArchive={commentsApi.archiveComment}
        onResolve={commentsApi.resolveComment}
      />

      <ConfirmationDialog
        open={cancelPopupOpen}
        onClose={() => setCancelPopupOpen(false)}
        onConfirm={onCancelProcess}
        loading={cancelPending}
        onReject={() => setCancelPopupOpen(false)}
        yesMessage="Yes"
        noMessage="No"
        body="Are you sure you want to cancel this onboarding process?"
      />
    </>
  )
}

export default OnboardingTimeline
