import React from 'react'
import styled from 'styled-components'
import {
  CommentsPopupDataInterface,
  DeliverableJustificationValue,
} from '@src/interfaces/performance'
import { A } from '@components/CommonSC/General'
import { Popup } from '@revolut/ui-kit'
import CommentsPopupCard from '@components/ColumnInserts/Justification/CommentsPopupCard'
import CommentsPopupHeader from '@components/ColumnInserts/Justification/CommentsPopupHeader'

interface Props {
  data: CommentsPopupDataInterface
  isOpen: boolean
  onClose: () => void
  isSelfComments?: boolean
}

const Comment = styled.div`
  line-height: 22px;
  white-space: pre-line;
  word-break: break-word;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const CommentsPopup = ({ data, isOpen, onClose, isSelfComments }: Props) => {
  return (
    <Popup open={isOpen} onClose={onClose} variant="bottom-sheet">
      {data.name && <CommentsPopupHeader name={data.name} rating={data.rating} />}
      {data.justification
        ? data.justification.map((justification, i) => {
            return (
              <CommentsPopupCard
                key={i}
                justification={justification}
                ratings={data.ratings}
                isSelfComments={isSelfComments}
              >
                {justification.value || 'N/A'}
              </CommentsPopupCard>
            )
          })
        : data.justifications?.map((justification, num) => {
            return (
              <CommentsPopupCard
                key={num}
                justification={justification}
                ratings={data.ratings}
                isSelfComments={isSelfComments}
              >
                {(justification?.value as DeliverableJustificationValue[])?.map(
                  (value, i) => {
                    return (
                      <Comment key={i}>
                        {value.reference_url && (
                          <>
                            <A href={value.reference_url} target="_blank">
                              Link {i + 1}
                            </A>
                            {': '}
                          </>
                        )}
                        {value.comment || 'N/A'}
                      </Comment>
                    )
                  },
                )}
              </CommentsPopupCard>
            )
          })}
    </Popup>
  )
}

export default CommentsPopup
