import React, { useEffect, useMemo, useState } from 'react'

import { Avatar, DetailsCell, HStack, TabBar, Token, VStack, Text } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GoalDashboardDetails, GoalPreviewInterface } from '@src/interfaces/goals'
import { UpdateTypes } from '@src/interfaces/kpis'
import { DashboardPreview } from '../../GoalForm'

type DashboardPreviewData = Pick<
  GoalDashboardDetails,
  'embed_url' | 'name' | 'dashboard_type'
>

export const IntegrationDataPreviewWidget = () => {
  const { values } = useLapeContext<GoalPreviewInterface>()
  const [selectedPreview, setSelectedPreview] = useState<
    DashboardPreviewData | undefined
  >()

  const selectableDashboards: DashboardPreviewData[] = useMemo(
    () =>
      values.kpis.reduce((acc: DashboardPreviewData[], kpi) => {
        const cycleName = kpi.targets.at(0)?.review_cycle?.name
        if (kpi.update_type === UpdateTypes.looker && kpi.look_url) {
          acc.push({
            dashboard_type: 'looker',
            embed_url: kpi.look_url,
            name: `${cycleName ? `(${cycleName}) ` : ''}${kpi.name}`,
          })
        }
        if (kpi.update_type === UpdateTypes.tableau && kpi.tableau_view_url) {
          acc.push({
            dashboard_type: 'tableau',
            embed_url: kpi.tableau_view_url,
            name: `${cycleName ? `(${cycleName}) ` : ''}${kpi.name}`,
          })
        }
        return acc
      }, []),
    [values.kpis],
  )

  useEffect(() => {
    if (selectableDashboards.length) {
      setSelectedPreview(selectableDashboards[0])
    }
  }, [selectableDashboards.length])

  if (!selectableDashboards.length) {
    return null
  }

  return (
    <DetailsCell>
      <DetailsCell.Title>
        <HStack space="s-8" align="center">
          <Avatar useIcon="TurboTransfer" />
          <Text variant="h6" color={Token.color.greyTone50}>
            Connected views
          </Text>
        </HStack>
      </DetailsCell.Title>
      <DetailsCell.Note>
        <VStack space="s-16" pt="s-8">
          <TabBar
            variant="segmented fit"
            value={selectedPreview?.name}
            onChange={name =>
              name && setSelectedPreview(selectableDashboards.find(t => t.name === name))
            }
          >
            {selectableDashboards.map(t => (
              <TabBar.Item to={t.name} key={t.name}>
                <Text variant="h6">{t.name}</Text>
              </TabBar.Item>
            ))}
          </TabBar>
          <VStack height="100%" mt="s-16">
            {selectedPreview && (
              <DashboardPreview dashboard={selectedPreview} minHeight={600} />
            )}
          </VStack>
        </VStack>
      </DetailsCell.Note>
    </DetailsCell>
  )
}
