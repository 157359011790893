import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { engagementQuestionFormRequests } from '@src/api/engagement'
import Form from '@src/features/Form/Form'
import { QuestionForm } from './General'
import { QuestionPreview } from './Preview'

export const EngagementQuestion = connect(() => (
  <Form api={engagementQuestionFormRequests}>
    <Switch>
      <Route path={ROUTES.APPS.ENGAGEMENT.QUESTION.EDIT}>
        <QuestionForm />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW}>
        <QuestionPreview />
      </Route>
    </Switch>
  </Form>
))
