import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Upload } from '@src/pages/Forms/ImportData/DepartmentBudget/Upload'
import { ReviewData } from '@src/pages/Forms/ImportData/DepartmentBudget/ReviewData'
import { ROUTES } from '@src/constants/routes'
import { useDepartmentCompensationDetails } from '@src/api/compensation'
import { useImportDepartmentBudgetSessionStats } from '@src/api/importData'
import { useDepartmentBudgetParams } from './common'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const ImportDepartmentBudget = () => {
  const params = useDepartmentBudgetParams()
  const details = useDepartmentCompensationDetails(params.id)
  const stats = useImportDepartmentBudgetSessionStats(params.id, params.sessionId)

  const props = {
    name: details.data?.department?.name || details.data?.budget_pool_config?.name,
    headcount: details.data?.headcount,
    cycle: details.data?.company_compensation_review?.year,
    errors: stats.data?.error_count,
    bonusTotal: details.data?.total_bonus_budget_usd,
    salaryTotal: details.data?.total_salary_budget_usd,
  }

  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.UPLOAD_FILE}>
        <Upload {...props} />
      </Route>
      <Route exact path={ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.REVIEW_DATA}>
        <ReviewData {...props} />
      </Route>
      <InternalRedirect to={ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.UPLOAD_FILE} />
    </Switch>
  )
}

export default ImportDepartmentBudget
