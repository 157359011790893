import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'
import { getGrants } from '@src/api/totalCompensation'
import {
  grantActionsColumn,
  grantDateColumn,
  grantEmployeeColumn,
  grantGrantedSharesColumn,
  grantScheduleColumn,
  grantVestedSharesColumn,
} from '@src/constants/columns/totalCompensation'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTotalCompensationGrantInterface } from '@src/interfaces/totalCompensation'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@components/TableV2/hooks'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const getRows: (
  canDelete: boolean,
  refresh: () => void,
) => RowInterface<EmployeeTotalCompensationGrantInterface> = (canDelete, refresh) => ({
  cells: [
    {
      ...grantEmployeeColumn,
      width: 200,
    },
    {
      ...grantDateColumn,
      width: 100,
    },
    {
      ...grantScheduleColumn,
      width: 200,
    },
    {
      ...grantGrantedSharesColumn,
      width: 100,
    },
    {
      ...grantVestedSharesColumn,
      width: 100,
    },
    canDelete
      ? {
          ...grantActionsColumn(refresh),
          width: 180,
        }
      : null,
  ].filter(Boolean),
})

export const Grants = () => {
  const permissions = useSelector(selectPermissions)
  const canDelete = permissions.includes(PermissionTypes.DeleteEquitygrant)
  const canImport = permissions.includes(PermissionTypes.ManageEquityGrantBulkUploads)

  const table = useTable<EmployeeTotalCompensationGrantInterface>({
    getItems: getGrants,
  })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Stock grants" val={table?.loading ? undefined : table?.count} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={2}>
          {canImport && (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.GRANTS.IMPORT)}
              use={InternalLink}
              useIcon="ShareIOs"
            >
              Import data
            </MoreBar.Action>
          )}
          <SettingsButton
            canView={[PermissionTypes.ViewTotalCompensationPreferences]}
            path={ROUTES.SETTINGS.COMPENSATION.GENERAL}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          {...table}
          dataType="Grant"
          hideCount
          name={TableNames.StockGrants}
          noDataMessage="List of all stock grants will appear here."
          row={getRows(canDelete, table.refresh)}
          useWindowScroll
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
