import React from 'react'
import { FilterButton, HStack } from '@revolut/ui-kit'

type FilterBarProps = {
  assessmentOnly: boolean
  onAssessmentOnlyChange: () => void
  feedbackCompleted: boolean
  onFeedbackCompletedChange: () => void
}

export const FilterBar = ({
  assessmentOnly,
  onAssessmentOnlyChange,
  feedbackCompleted,
  onFeedbackCompletedChange,
}: FilterBarProps) => {
  return (
    <HStack gap="s-8">
      <FilterButton
        active={assessmentOnly}
        useIcon={assessmentOnly ? 'SwitchOn' : 'SwitchOff'}
        onClick={onAssessmentOnlyChange}
      >
        Assessments only
      </FilterButton>
      <FilterButton
        active={feedbackCompleted}
        useIcon={feedbackCompleted ? 'SwitchOn' : 'SwitchOff'}
        onClick={onFeedbackCompletedChange}
      >
        Feedback completed
      </FilterButton>
    </HStack>
  )
}
