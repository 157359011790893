import React, { useState } from 'react'
import { Box, Button, Group, StatusPopup } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import {
  CompleteStageButton,
  DocumentsPreview,
  ProcessStageStepItem,
  SLAProgress,
  TabProps,
} from './common'
import { ProcessStageSteps } from '@src/interfaces/onboarding'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { completeStage } from '@src/api/onboardingEmployees'

export const FinishStage = (props: TabProps) => {
  const { data, stage, updateStage, setOpenedSidebar } = props
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [completeOnboardingPending, setCompleteOnboardingPending] = useState(false)

  const { process_stage_steps } = stage

  const assignTimeOffPoliciesStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.AssignTimeOffPolicies,
  )!
  const sendDay1InstructionsStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.SendDay1Instructions,
  )

  const onCompleteOnboarding = () => {
    setCompleteOnboardingPending(true)

    completeStage(data.employee.id, stage.id)
      .then(response => {
        updateStage(response.data)
        setSuccessPopupOpen(true)
      })
      .finally(() => {
        setCompleteOnboardingPending(false)
      })
  }

  return (
    <>
      <PageBody mt="s-32">
        <SLAProgress
          elapsedSla={props.stage.sla}
          sla={props.stage.stage.sla_in_hours}
          caption="Assign benefits and time off policies, send email with Day 1 instructions and check if all the documents have been provided."
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        <Group>
          <ProcessStageStepItem
            step={assignTimeOffPoliciesStep}
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES, {
                  id: data.employee.id,
                }),
              )
            }
          />
          {sendDay1InstructionsStep && (
            <ProcessStageStepItem
              step={sendDay1InstructionsStep}
              onClick={() => setOpenedSidebar('send-day-1-instructions')}
            />
          )}
        </Group>

        <Box mt="s-24">
          <DocumentsPreview employee={data.employee} type="requested" />
        </Box>
      </PageBody>

      <PageActions>
        <CompleteStageButton
          onClick={onCompleteOnboarding}
          pending={completeOnboardingPending}
          footnote={`Please note that the employee profile will only move to 'Active' at the start date`}
          {...props}
        >
          Complete onboarding
        </CompleteStageButton>
      </PageActions>

      <StatusPopup
        variant="success-result"
        open={successPopupOpen}
        onClose={() => setSuccessPopupOpen(false)}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>
          Onboarding of this candidate was successfully finished
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button onClick={() => navigateTo(ROUTES.APPS.LIFECYCLE.OFFBOARDING)} elevated>
            Back to onboarding queue
          </Button>
          <Button
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }),
              )
            }
            variant="secondary"
          >
            Employee profile
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>
    </>
  )
}
