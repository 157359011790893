import {
  AppIconGrid,
  Box,
  ContextMenuController,
  Dropdown,
  Spacer,
  Subheader,
  Token,
  useMatchMedia,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import React from 'react'
import HubAppIcon from '@src/features/HubAppIcon/HubAppIcon'
import { useHubApps } from '@src/pages/Hub/hooks'
import { Pin, Unpin } from '@revolut/icons'
import { UNPIN_APP_LABEL, PIN_APP_LABEL, usePinnedApps } from '@src/pages/Hub/Apps/common'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

const Apps = () => {
  const { sendAnalyticsEvent } = useAnalytics()
  const size = useMatchMedia(Token.media.md) ? 76 : 60
  const apps = useHubApps({ showDisabled: true })
  const { pinnedApps, setPinnedApps } = usePinnedApps()

  const handlePinning = (appId: string, pin: boolean) => {
    if (pin) {
      sendAnalyticsEvent(AnalyticsEvents.pin_app, { app_id: appId })
      setPinnedApps(prev => [...prev, appId])
      return
    }

    sendAnalyticsEvent(AnalyticsEvents.unpin_app, { app_id: appId })
    setPinnedApps(prev => prev.filter(pinnedAppId => pinnedAppId !== appId))
  }

  return (
    <PageBody>
      {apps.map(appGroup => (
        <React.Fragment key={appGroup.id}>
          <Subheader variant="nested">
            <Subheader.Title>{appGroup.title}</Subheader.Title>
          </Subheader>
          <AppIconGrid size={size}>
            {appGroup.items.map(app => (
              <ContextMenuController key={app.id}>
                {contextMenu => {
                  const isPinned = pinnedApps.includes(app.id)

                  return (
                    <>
                      <Box {...contextMenu.getAnchorProps()}>
                        <HubAppIcon size={size} app={app} disabled={app.disabled} />
                      </Box>
                      <Dropdown {...contextMenu.getTargetProps()} width={160}>
                        <Dropdown.Item
                          use="button"
                          useIcon={isPinned ? Pin : Unpin}
                          onClick={() => handlePinning(app.id, !isPinned)}
                        >
                          {isPinned ? UNPIN_APP_LABEL : PIN_APP_LABEL}
                        </Dropdown.Item>
                      </Dropdown>
                    </>
                  )
                }}
              </ContextMenuController>
            ))}
          </AppIconGrid>
          <Spacer height="s-24" />
        </React.Fragment>
      ))}
    </PageBody>
  )
}

export default Apps
