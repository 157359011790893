import { Text, Widget, VStack, Link, TextSkeleton } from '@revolut/ui-kit'
import React from 'react'
import { GRADE_RECOMMENDATION_GUIDELINES } from '@src/constants/externalLinks'
import { useGetCompanyPreferences } from '@src/api/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const ScorecardRecommendationAlertBanner = () => {
  return (
    <Widget backgroundColor="red-action-background">
      <Text use="p" p="s-16" fontWeight={500} color="red">
        This is only a suggestion, and NOT the final grade the employee will get. This
        will be considered along with the scorecard rating as inputs into the overall
        grade.
      </Text>
    </Widget>
  )
}

export const ScorecardRecommendationBanner = () => {
  const { data: settings } = useGetCompanyPreferences()
  const brandName = settings ? settings.brand_name : <TextSkeleton width={100} />
  return (
    <Widget backgroundColor="red-action-background" color="grey-tone-50">
      <VStack space="s-16" p="s-16">
        <Text use="p">
          The grade recommendation is <Text fontWeight={500}>your opinion</Text> and an
          additional data point for calibration. At {brandName}, we believe in structure,
          simplicity, and succinctness. When providing a justification, make sure to give
          a clear rationale based on real examples and refer to previous sections, if
          required.
        </Text>
        <HideIfCommercial>
          <Text use="p">
            Please refer to the guidelines{' '}
            <Link target="_blank" href={GRADE_RECOMMENDATION_GUIDELINES}>
              here
            </Link>{' '}
            to know more about the considerations when recommending a grade.
          </Text>
        </HideIfCommercial>
      </VStack>
    </Widget>
  )
}
