import React from 'react'
import { updateJobPostingSettings, useGetJobPostingSettings } from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { FormattedMessage } from 'react-intl'
import { Avatar, Item, Switch } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'

export const PostJobsOnIndeed = () => {
  const { data: jobPostingSettings, isLoading, refetch } = useGetJobPostingSettings()
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()
  const updateIndeedIntegration = async () => {
    try {
      const enable_indeed_integration = !jobPostingSettings?.enable_indeed_integration
      showStatusPopup({
        status: 'loading',
        title: 'Updating integration settings',
        preventUserClose: true,
      })
      await updateJobPostingSettings({
        enable_indeed_integration,
      })
      refetch()
      showStatusPopup({
        title: enable_indeed_integration ? (
          <FormattedMessage
            id="integrations.indeed.settings.post.enable.success"
            defaultMessage="Indeed enabled"
          />
        ) : (
          <FormattedMessage
            id="integrations.indeed.settings.post.disable.success"
            defaultMessage="Indeed disabled"
          />
        ),
      })
    } catch (err) {
      showStatusPopup({
        description: getStringMessageFromError(err),
        status: 'error',
        title: (
          <FormattedMessage
            id="integrations.indeed.settings.post.fail"
            defaultMessage="Indeed integration failed"
          />
        ),
      })
    }
  }
  const handlePostJobsOnIndeed = () => {
    if (jobPostingSettings?.enable_indeed_integration) {
      confirmationPopup.show({
        label: 'Are you sure?',
        body: (
          <FormattedMessage
            id="integrations.indeed.settings.post.disable.confirm"
            defaultMessage="By turning this setting off all the postings on Indeed will be closed"
          />
        ),
        onConfirm: updateIndeedIntegration,
      })
    } else {
      updateIndeedIntegration()
    }
  }
  return (
    <Item
      use="label"
      onClick={e => {
        e.preventDefault()
        handlePostJobsOnIndeed()
      }}
    >
      <Item.Avatar>
        <Avatar useIcon="DocumentsPair" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <FormattedMessage
            id="integrations.indeed.settings.post.title"
            defaultMessage="Post my jobs on Indeed"
          />
        </Item.Title>
        <Item.Description>
          <FormattedMessage
            id="integrations.indeed.settings.post.description"
            defaultMessage="Automatically publish all existing and new jobs to your Indeed page"
          />
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Switch
          disabled={isLoading}
          checked={jobPostingSettings?.enable_indeed_integration}
        />
      </Item.Side>
    </Item>
  )
}
