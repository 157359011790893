import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'

export const resolutionDateColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  dataPoint: 'resolution_date_time',
  idPoint: 'resolution_date_time',
  title: 'Resolution date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const issueTypeColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  title: 'T',
  dataPoint: 'issue_type',
  idPoint: 'issue_type',
  headerAlign: 'center',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const taskColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  title: 'Task',
  dataPoint: 'key',
  idPoint: 'key',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const ignoreColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  title: 'Ignore',
  dataPoint: 'scorecard_ignore',
  idPoint: 'scorecard_ignore',
  headerAlign: 'center',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
}

export const ratingColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  title: 'Rating',
  dataPoint: 'user_rating',
  idPoint: 'user_rating',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
}

export const commentColumn: ColumnInterface<{}> = {
  type: CellTypes.insert,
  title: 'Comments',
  dataPoint: 'scorecard_comment',
  idPoint: 'scorecard_comment',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
}
