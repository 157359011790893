import React from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface Props {
  category: ReviewCategory
}

const Deliverables = ({ category }: Props) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const cards = values?.summary?.deliverables?.cards

  if (!cards) {
    return null
  }

  return (
    <ScorecardGeneral
      cards={cards}
      title="Deliverables"
      type={PerformanceReviewTypes.deliverables}
      rating={values.summary?.deliverables?.rating}
      justifications={values.summary?.deliverables?.skipped_section_justifications}
      deliverablesJustifications={values.summary?.deliverables?.justifications}
      category={category}
      disabled
      viewMode
    />
  )
}

export default Deliverables
