import React from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import SideBar from '@src/components/SideBar/SideBar'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { Group, HStack, TagBar } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

type RoundDetailsSidebarProps = {
  hiringProcessRound: HiringProcessInterface
  generatedScorecard: boolean
  onClose: () => void
}

type FormPreviewOptionsAsTagsProps = {
  options?: OptionInterface[]
  title: string
}

const FormPreviewOptionsAsTags = ({ options, title }: FormPreviewOptionsAsTagsProps) => {
  if (!options?.length) {
    return <FormPreview.Item title={title} />
  }
  return (
    <FormPreview.Details
      title={title}
      insert={() => {
        return (
          <TagBar>
            {options.map(o => (
              <TagBar.Item key={o.id}>{o.name}</TagBar.Item>
            ))}
          </TagBar>
        )
      }}
    />
  )
}

type FormPreviewEmployeesProps = {
  interviewers?: EmployeeOptionInterface[]
}

const FormPreviewEmployees = ({ interviewers }: FormPreviewEmployeesProps) => {
  if (!interviewers?.length) {
    return <FormPreview.Item title="Interviewers" />
  }
  return (
    <FormPreview.Details
      title="Interviewers"
      insert={() => {
        return (
          <HStack space="s-4">
            {interviewers?.map(item => (
              <UserWithAvatar key={item.id} {...item} compact />
            ))}
          </HStack>
        )
      }}
    />
  )
}

const RoundDetailsSidebar = ({
  hiringProcessRound,
  generatedScorecard,
  onClose,
}: RoundDetailsSidebarProps) => {
  return (
    <SideBar isOpen onClose={onClose} title={hiringProcessRound.title}>
      <FormPreview data={hiringProcessRound}>
        <Group>
          <FormPreview.Item title="Round name" field="title" />
          <FormPreview.Item title="Stage type" field="stage_type.name" />
          {hiringProcessRound.stage_type.id !== 'cv_screening' && (
            <>
              <FormPreviewOptionsAsTags
                options={hiringProcessRound.skills}
                title="Skills"
              />
              <FormPreview.Item<HiringProcessInterface>
                title="Playbook link"
                field="playbook_link"
                insert={data =>
                  data.playbook_link ? <OpenInNewTab link={data.playbook_link} /> : '-'
                }
              />
              <FormPreview.Item<HiringProcessInterface>
                title="Scorecard"
                field=""
                insert={data => {
                  if (data.stage_type.id === 'skills_round' && generatedScorecard) {
                    return 'Unique scorecard will be generated for every set of skills'
                  }
                  if (!data.scorecard_template) {
                    return '-'
                  }
                  const link = pathToUrl(
                    ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW,
                    {
                      id: data.scorecard_template.id,
                    },
                  )
                  return <OpenInNewTab link={link} />
                }}
              />
              <FormPreview.Item<HiringProcessInterface>
                title="Duration"
                field="duration"
                insert={data => {
                  if (!data.duration) {
                    return '-'
                  }
                  return `${data.duration} ${data.duration_unit.name}`
                }}
              />
              {hiringProcessRound.interviewer_type.id === 'groups' && (
                <FormPreviewOptionsAsTags
                  options={hiringProcessRound.interviewers_groups}
                  title="Interviewer groups"
                />
              )}
              {hiringProcessRound.interviewer_type.id === 'employees' && (
                <FormPreviewEmployees interviewers={hiringProcessRound.interviewers} />
              )}
            </>
          )}
        </Group>
      </FormPreview>
    </SideBar>
  )
}

export default RoundDetailsSidebar
