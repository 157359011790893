import { Button, Header, Popup } from '@revolut/ui-kit'
import React, { ReactChild } from 'react'

export interface DescriptionDialogPropsInterface {
  onClose: () => void
  onSubmit: () => void
  open: boolean
  disabled?: boolean
  loading?: boolean
  children: ReactChild
  title?: string
}

export const NewSubmitDialog = ({
  open,
  onClose,
  title,
  children,
  onSubmit,
  loading,
  disabled,
}: DescriptionDialogPropsInterface) => {
  return (
    <Popup open={open} onClose={onClose} variant="modal-view">
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{title}</Header.Title>
      </Header>
      {children}
      <Popup.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          elevated
          disabled={disabled}
          pending={loading}
          onClick={() => {
            onSubmit()
          }}
        >
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
