import React, { useEffect } from 'react'
import { Box } from '@revolut/ui-kit'

import PageLoading from '@components/PageLoading/PageLoading'
import { googleSignup } from '@src/api/signup'
import { setSignupStateCookie } from './common'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import {
  removeSignupAuthenticatedCookie,
  setSignupAuthenticatedCookie,
} from '@src/utils/cookies'
import { isWorkspacesEnabled } from '@src/utils'

export const SignupRedirect = () => {
  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const code = currentURL.searchParams.get('code')

    if (code) {
      googleSignup(code)
        .then(({ data }) => {
          if (
            data.state === 'company_details' ||
            data.state === 'pending' ||
            data.state === 'preparing'
          ) {
            setSignupStateCookie('company_details', data.token, data.tenant_id)
            navigateTo(ROUTES.SIGNUP.COMPANY_DETAILS)
          }
          if (data.state === 'waiting_list' || data.state === 'setup_failed') {
            setSignupStateCookie('waiting_list')
            navigateTo(ROUTES.SIGNUP.WAITING_LIST)
          }
          if (data.state === 'suspended' || data.state === 'closed') {
            setSignupStateCookie('suspended_or_closed')
            navigateTo(ROUTES.SIGNUP.SUSPENDED)
          }
          if (data.state === 'active' && data.redirect_url) {
            setSignupAuthenticatedCookie()
            window.location.href = data.redirect_url
          }
        })
        .catch(error => {
          removeSignupAuthenticatedCookie()
          navigateTo(isWorkspacesEnabled() ? WORKSPACES.MAIN : ROUTES.LOGIN.MAIN)
          throw error
        })
    }
  }, [])

  return (
    <Box height="100vh" width="100vw">
      <PageLoading />
    </Box>
  )
}
