import { Flex, Group, TextSkeleton } from '@revolut/ui-kit'
import React from 'react'

const AnalyticsSkeleton = () => {
  return (
    <Group>
      <Flex gap="s-48" p="s-16" flexDirection="row">
        <Flex gap="s-2" flexDirection="column">
          <TextSkeleton size={20} variant="heading3" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
        <Flex gap="s-2" flexDirection="column">
          <TextSkeleton size={20} variant="heading3" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
      </Flex>
    </Group>
  )
}

export default AnalyticsSkeleton
