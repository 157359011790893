import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { Flex, Widget } from '@revolut/ui-kit'
import {
  AffectedCandidatesInterface,
  AutomationInterface,
} from '@src/interfaces/jobPostings/automation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  affectedCandidatesDateColumn,
  affectedCandidatesNameColumn,
} from '@src/constants/columns/jobPostings/affectedCandidates'
import { AxiosResponse } from 'axios'
import { GetRequestData } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { TableNames } from '@src/constants/table'

const row: RowInterface<AffectedCandidatesInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.id })),
  cells: [
    {
      ...affectedCandidatesNameColumn,
      width: 300,
    },
    {
      ...affectedCandidatesDateColumn,
      width: 300,
    },
  ],
}

const AffectedCandidates = () => {
  const { values } = useLapeContext<AutomationInterface>()
  const table = useTable<AffectedCandidatesInterface>({
    getItems: () =>
      Promise.resolve({
        data: {
          results: values.candidates,
          count: values.candidates.length,
        },
      } as AxiosResponse<GetRequestData<AffectedCandidatesInterface>>),
  })

  return (
    <Widget p="s-24">
      <Flex style={{ position: 'relative' }} flex="1 0">
        <AdjustableTable<AffectedCandidatesInterface>
          name={TableNames.AffectedCandidates}
          useWindowScroll
          dataType="Affected candidates"
          row={row}
          {...table}
          noDataMessage="Affected candidates will appear here."
        />
      </Flex>
    </Widget>
  )
}

export default AffectedCandidates
