import { VStack, HStack, Box, TagBar, TextButton, Token, Text } from '@revolut/ui-kit'
import React from 'react'

export const Tags = ({
  tags,
  onEditClick,
}: {
  tags?: string[]
  onEditClick: VoidFunction
}) => (
  <VStack>
    <HStack align="center" my="s-8" space="s-8">
      <Box>
        <Text variant="primary">Projects:</Text>
      </Box>
      <Box minWidth={0}>
        {tags?.length ? (
          <TagBar>
            {tags.map(tag => (
              <TagBar.Item key={tag} color="grey-tone-50" variant="outlined">
                {tag}
              </TagBar.Item>
            ))}
          </TagBar>
        ) : (
          <Text variant="primary">-</Text>
        )}
      </Box>
      <Box>
        <TextButton onClick={onEditClick}>Edit</TextButton>
      </Box>
    </HStack>
    {!tags?.length && (
      <Text variant="small" color={Token.color.danger}>
        Please define the projects keys for search
      </Text>
    )}
  </VStack>
)
