import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import { Side } from '@revolut/ui-kit'

import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { documentsTemplateFormRequests } from '@src/api/documentsTemplates'
import { TemplateFormInputs } from '@src/apps/People/Documents/Template/Edit/common/TabBasics'
import { selectUser } from '@src/store/auth/selectors'

const NewTemplateFormContent = connect(({ onCreated }: Props) => {
  const currentUser = useSelector(selectUser)
  const { values, reset } = useLapeContext<DocumentsTemplatesInterface>()

  useEffect(() => {
    values.owner = { id: currentUser.id }
    values.template_type = { id: 'request', name: 'Request' }
  }, [])

  return (
    <>
      <TemplateFormInputs />
      <Side.Actions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={(newTemplate: DocumentsTemplatesInterface) => {
            onCreated(newTemplate)
            reset({} as DocumentsTemplatesInterface)
          }}
        >
          Create new template
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
})

type Props = {
  onCreated: (newTemplate: DocumentsTemplatesInterface) => void
}
export const NewTemplateForm = (props: Props) => {
  return (
    <Form
      disableLocalStorageCaching
      forceParams={{ id: undefined }}
      api={documentsTemplateFormRequests}
    >
      <NewTemplateFormContent {...props} />
    </Form>
  )
}
