import { all, takeEvery } from 'redux-saga/effects'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { PerformanceActionTypes } from '@src/store/performance/types'

// eslint-disable-next-line require-yield
function* performanceUploadFinishSaga(action: { payload: boolean }) {
  if (action.payload) {
    pushNotification({
      value: 'The grade uploading has been successful.',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
  } else {
    pushNotification({
      value:
        'The grade uploading was not successful. Please try again. If the problem persists, please raise an issue on Revoluters Service Desk.',
      duration: ERROR_DEFAULT_DURATION,
      type: NotificationTypes.error,
    })
  }
}

export function* performanceSaga() {
  yield all([
    takeEvery<any>(PerformanceActionTypes.FINISH_UPLOAD, performanceUploadFinishSaga),
  ])
}
