import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  Box,
  chain,
  Color,
  Group,
  Link as UILink,
  MoreBar,
  Text,
  TextSkeleton,
} from '@revolut/ui-kit'
import { Archive, Cross, Link as LinkIcon, Pencil, Retry } from '@revolut/icons'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LinkedAccountsInterface } from '@src/interfaces/linkedAccounts'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { mapAccountStatusToProps } from '@src/utils/linkedAccounts'
import { PageBody } from '@components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Statuses } from '@src/interfaces'
import {
  archiveLinkedAccount,
  linkedAccountRequests,
  linkedFormRequests,
} from '@src/api/linkedAccounts'
import { REVOLUT_ACCOUNT_ID } from '@src/pages/Forms/LinkRevolutAccountForm/constants'
import { LINKED_ACCOUNTS_INFO_LINK } from '@src/constants/externalLinks'
import VideoModal from './VideoModal'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { successNotification } from '@src/actions/NotificationActions'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'

export const AccountPreview = connect(() => {
  const { values, reset } = useLapeContext<LinkedAccountsInterface>()
  const { employeeId, id } = useParams<{ employeeId: string; id: string }>()
  const [isNoAccountPending, setIsNoAccountPending] = useState<boolean>(false)
  const [isArchivationPending, setIsArchivationPending] = useState<boolean>(false)
  const [isArchiveDialogOpen, setIsArchiveDialogOpen] = useState<boolean>(false)

  const { statusText } = mapAccountStatusToProps(values.status)

  useEffect(() => {
    if (!values.id) {
      values.status = Statuses.no_account
    }
  }, [])

  const declareNoAccount = async () => {
    setIsNoAccountPending(true)
    try {
      const { data } = await linkedAccountRequests(Number(employeeId)).patchItem(
        {
          account_type: { id: REVOLUT_ACCOUNT_ID },
          status: Statuses.no_account,
        } as LinkedAccountsInterface,
        Number(id),
      )
      reset(data)
    } finally {
      setIsNoAccountPending(false)
    }
  }

  const handleRefresh = async () => {
    const { data } = await linkedFormRequests.update(values, { employeeId, id })
    reset(data)
  }

  return (
    <PageWrapper>
      <PageHeader
        title={
          isNoAccountPending ? (
            <TextSkeleton size={16} />
          ) : (
            chain(
              'Revolut account',
              <Text color={mapAccountStatusToProps(values.status).color}>
                {statusText}
              </Text>,
            )
          )
        }
        backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
          id: employeeId,
        })}
      />
      <PageBody>
        {values.id && values.status !== Statuses.no_account ? (
          <>
            <Box mt="-s-16" mb="s-40">
              <MoreBar>
                {values.status === Statuses.failed && (
                  <>
                    <MoreBar.Action
                      useIcon={Pencil}
                      use={InternalLink}
                      to={pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.GENERAL, {
                        employeeId,
                        id,
                      })}
                    >
                      Edit
                    </MoreBar.Action>
                    <MoreBar.Action useIcon={Retry} onClick={handleRefresh}>
                      Refresh
                    </MoreBar.Action>
                  </>
                )}
                <MoreBar.Action
                  useIcon={Archive}
                  variant="negative"
                  pending={isArchivationPending}
                  onClick={() => setIsArchiveDialogOpen(true)}
                >
                  Archive
                </MoreBar.Action>
              </MoreBar>
            </Box>
            {values.status === Statuses.failed && (
              <ActionWidget
                mb="s-16"
                title="The account linking has failed, this can be caused by the following
                  things:"
                text={
                  <>
                    <Box ml="s-16" mb="s-8">
                      <ul>
                        <li>The email does not match the one from the account</li>
                        <li>
                          The phone number does not match with the one from the account or
                          the format does not match. Make sure you provide the country
                          code.
                        </li>
                        <li>The date of birth does not match the one in the system</li>
                        <li>
                          You have recently created a Revolut account, in this case please
                          wait at least 24 hours before linking
                        </li>
                      </ul>
                    </Box>
                    As it’s important to link your account successfully, please review all
                    the mentioned reasons and perform one of the following actions.
                  </>
                }
              >
                <MoreBar>
                  <MoreBar.Action
                    useIcon={Pencil}
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.GENERAL, {
                      employeeId,
                      id,
                    })}
                  >
                    Edit account details
                  </MoreBar.Action>
                  <MoreBar.Action
                    useIcon={Cross}
                    variant="negative"
                    pending={isNoAccountPending}
                    onClick={declareNoAccount}
                  >
                    I don't have Revolut account
                  </MoreBar.Action>
                </MoreBar>
              </ActionWidget>
            )}
            <FormPreview data={values}>
              <Group>
                <FormPreview.Item
                  title="Phone number as shown in the account"
                  field="personal_phone_number"
                />
                <FormPreview.Item
                  title="Email address as shown in the account"
                  field="personal_email"
                />
                <FormPreview.Details title="Notes" field="description" />
              </Group>
            </FormPreview>
          </>
        ) : (
          <ActionWidget
            title="You have declared that you don’t have the Revolut account in your name"
            text={
              <>
                However, as soon as you establish Revolut account in your name, you are
                obliged to declare it here and link it with your Revolut People account.
                To find out more about this procedure please visit the following{' '}
                <UILink href={LINKED_ACCOUNTS_INFO_LINK} target="_blank">
                  Confluence page
                </UILink>
                .
              </>
            }
            avatarColor={Color.GREY_20}
          >
            <MoreBar>
              <MoreBar.Action
                useIcon={LinkIcon}
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.LINKED_ACCOUNT.GENERAL, {
                  employeeId,
                  id: values.id,
                })}
              >
                Link Revolut account
              </MoreBar.Action>
            </MoreBar>
          </ActionWidget>
        )}
        <VideoModal />
        {isArchiveDialogOpen ? (
          <ConfirmationDialog
            open={isArchiveDialogOpen}
            onClose={() => setIsArchiveDialogOpen(false)}
            onConfirm={async () => {
              try {
                setIsArchivationPending(true)
                const { data } = await archiveLinkedAccount(employeeId, values.id)
                reset(data)
                successNotification('Account was successfully archived')
              } catch (e) {
                pushNotification({
                  type: NotificationTypes.error,
                  value: e?.response?.data?.status?.[0] || 'Archiving was unsuccessful',
                  duration: ERROR_DEFAULT_DURATION,
                })
              } finally {
                setIsArchiveDialogOpen(false)
                setIsArchivationPending(false)
              }
            }}
            loading={isArchivationPending}
            onReject={() => setIsArchiveDialogOpen(false)}
            label="Are you sure you want to archive linked account?"
            body={null}
          />
        ) : null}
      </PageBody>
    </PageWrapper>
  )
})
