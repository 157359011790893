import React from 'react'
import { Box, Button, Flex, Placeholder, Popup, Text, Token } from '@revolut/ui-kit'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import {
  nextStageToPropsMap,
  previousStageToPropsMap,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/constants'
import { PopupBody } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/PopupBody'

interface Props {
  cycle: ReviewCyclesInterface
  stage: ReviewCycleStage
  direction: 'backward' | 'forward'
  isOpen: boolean
  isSwitchPending: boolean
  handleConfirm: VoidFunction
  handleClose: VoidFunction
}

export const SwitchStagePopup = ({
  cycle,
  stage,
  direction,
  isOpen,
  isSwitchPending,
  handleConfirm,
  handleClose,
}: Props) => {
  const propsMap = direction === 'forward' ? nextStageToPropsMap : previousStageToPropsMap
  const props = propsMap[stage]

  if (props === null) {
    return null
  }

  const { title, description, confirmLabel, cancelLabel, image, note } = props
  const hasBodyContent = direction === 'forward'

  return (
    <Popup size="sm" open={isOpen}>
      <Placeholder py={0}>
        {image && <Placeholder.Image size={88} image={image} />}
        <Placeholder.Title>{title}</Placeholder.Title>
        <Placeholder.Description>{description}</Placeholder.Description>
      </Placeholder>
      {hasBodyContent && (
        <Box pt="s-24">
          <PopupBody cycle={cycle} stage={stage} />
        </Box>
      )}
      {note && (
        <Flex mt="s-24">
          <Text variant="body3" textAlign="center" color={Token.color.greyTone50}>
            {note}
          </Text>
        </Flex>
      )}
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isSwitchPending}>
          {confirmLabel}
        </Button>
        <Button variant="secondary" onClick={handleClose}>
          {cancelLabel}
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
