import { HStack, Icon, IconName, Tag, Text, Token, Link, Color } from '@revolut/ui-kit'
import React from 'react'
import { InternalLink } from '@components/InternalLink/InternalLink'

type Props = {
  icon?: IconName
  useTag?: boolean
  children: React.ReactNode
  to?: string
  color?: Color
}

export const PageHeaderLabel = ({ icon, children, useTag, to, color }: Props) => {
  if (useTag) {
    const tag = (
      <Tag variant="outlined" color={color || Token.color.deepGrey} useIcon={icon}>
        {children}
      </Tag>
    )

    if (to) {
      return (
        <Link use={InternalLink} to={to}>
          {tag}
        </Link>
      )
    }
    return tag
  }

  const label = (
    <HStack gap="s-4" align="center">
      <Icon name={icon} size={16} color={color || Token.color.greyTone50} />
      <Text use="div" color={color || Token.color.greyTone50} variant="caption">
        {children}
      </Text>
    </HStack>
  )

  if (to) {
    return (
      <Link use={InternalLink} to={to}>
        {label}
      </Link>
    )
  }

  return label
}
