import React from 'react'
import { ItemSkeleton, VStack } from '@revolut/ui-kit'

const CareersQuestionsSkeleton = () => {
  return (
    <VStack gap="s-32" mt="s-32">
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
      <ItemSkeleton>
        <ItemSkeleton.Content />
        <ItemSkeleton.Side />
      </ItemSkeleton>
    </VStack>
  )
}

export default CareersQuestionsSkeleton
