import React from 'react'
import { useParams } from 'react-router-dom'
import { Flex, Input, InputGroup } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SchedulePolicyShiftInterface } from '@src/interfaces/attendance'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { LapeYesOrNoRadioSelect } from '@components/Inputs/LapeFields/LapeYesOrNoRadioSelect'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'

const General = () => {
  const params = useParams<{ policyId: string; id?: string }>()

  const { values } = useLapeContext<SchedulePolicyShiftInterface>()

  const title = values.id ? 'Edit shift' : 'Add new shift'

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        backUrl={pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.PREVIEW, params)}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Basic information" />
          <InputGroup>
            <LapeNewInput name="name" label="Shift name" required />
            <LapeRadioSelectInput
              name="type"
              label="Shift type"
              selector={selectorKeys.shift_configuration_types}
            />
            <LapeRadioSelectInput
              name="day_period"
              label="Day period"
              selector={selectorKeys.shift_configuration_day_periods}
            />
            <LapeRadioSelectInput
              name="week_period"
              label="Week period"
              selector={selectorKeys.shift_configuration_week_periods}
            />
            <LapeYesOrNoRadioSelect name="requires_approval" label="Requires approval" />
          </InputGroup>

          <NewStepperTitle title="Time characteristics" />
          <InputGroup>
            <LapeNewInput name="start" label="Earliest start" required type="time" />
            <LapeNewInput name="end" label="Latest end" required type="time" />
            <LapeNewInput name="minimum_hours" label="Minimum hours" required />
          </InputGroup>

          <NewStepperTitle title="Compensation" />
          <InputGroup>
            <LapeYesOrNoRadioSelect name="paid_shift" label="Paid shift" />
            <LapeRadioSelectInput
              name="compensation_type"
              label="Compensation type"
              selector={selectorKeys.shift_configuration_compensation_types}
            />
            <InputGroup variant="horizontal">
              <Flex flex="3">
                <LapeNewInput
                  name="compensation_level"
                  label="Compensation level"
                  message="Specify the percentage of the compensation that will be paid additionaly for this shift."
                  required
                />
              </Flex>
              <Flex flex="1">
                <Input value="%" disabled />
              </Flex>
            </InputGroup>
            <LapeYesOrNoRadioSelect name="toil_allowed" label="TOIL allowed" />
          </InputGroup>

          <NewStepperTitle title="Additional information" />
          <LapeHTMLEditor
            name="additional_information"
            message="You can provide any additional informaton about this shift and instructions how to assign it here."
            required
          />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={response => {
            if (params.id) {
              goBack()
            } else {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.PREVIEW, {
                  id: response.id,
                  policyId: params.policyId,
                }),
              )
            }
          }}
          useValidator
          hideWhenNoChanges={false}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default General
