import React from 'react'
import { Box, FilterButton, FilterButtonSkeleton, Flex } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { EmployeeFilter } from '@src/pages/Forms/Candidate/Performance/components/EmployeeFilter'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import { PerformanceHiringRoundSummaryInterface } from '@src/interfaces/interviewTool'

type FilterBarProps = {
  interviewersFilter: number[]
  round?: InterviewRoundInterface
  onChangeInterviewersFilter: (interviewersFilter: number[]) => void
  prefetchedData?: PerformanceHiringRoundSummaryInterface
  isPrefetchedDataLoading?: boolean
}

export const FilterBar = ({
  interviewersFilter,
  round,
  prefetchedData,
  isPrefetchedDataLoading,
  onChangeInterviewersFilter,
}: FilterBarProps) => {
  const { data: performanceProfileData, isLoading: isCandidateLoading } =
    useGetCandidatePerformanceProfile(round?.id)
  const data = performanceProfileData || prefetchedData
  const isLoading = isCandidateLoading || isPrefetchedDataLoading
  return (
    <Box px="s-16">
      <Box mb="s-8" pb="s-16" overflow="auto">
        <Flex gap="s-8" flexWrap="nowrap" data-testid="performance-summary-filter-bar">
          <FilterButton
            useIcon="People"
            labelClear="All"
            active={!interviewersFilter.length}
            onClick={() => {
              onChangeInterviewersFilter([])
            }}
          >
            All
          </FilterButton>
          {isLoading ? (
            <>
              <FilterButtonSkeleton />
              <FilterButtonSkeleton />
            </>
          ) : (
            data?.all_interviewers?.map(interviewer => {
              const { id, full_name } = interviewer
              const filtering = interviewersFilter.includes(id)
              return (
                <EmployeeFilter
                  key={id}
                  active={interviewersFilter.includes(id)}
                  userWithAvatarProps={{ ...interviewer }}
                  onClick={() => {
                    onChangeInterviewersFilter(
                      filtering
                        ? interviewersFilter.filter(fid => fid !== id)
                        : [...interviewersFilter, id],
                    )
                  }}
                >
                  {full_name}
                </EmployeeFilter>
              )
            })
          )}
        </Flex>
      </Box>
    </Box>
  )
}
