import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetOpenRequisitionsByJobPosting } from '@src/api/requisitions'
import {
  Button,
  Header,
  MoreBar,
  Popup,
  Text,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { EntityPermissions } from '@src/store/auth/types'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const ArchiveJobPostingButton = () => {
  const { values, submit } = useLapeContext<JobPostingInterface>()
  const tooltip = useTooltip()
  const showStatusPopup = useShowStatusPopup()
  const [loading, setLoading] = useState(false)
  const [openArchivePopup, setOpenArchivePopup] = useState(false)
  const { data: openRequisitions, isLoading } = useGetOpenRequisitionsByJobPosting(
    values.id,
  )
  const canArchive = values?.field_options?.actions?.includes(EntityPermissions.Change)

  if (!values?.id || !canArchive) {
    return null
  }

  const isArchived = values.approval_status === ApprovalStatuses.Archived
  const handleArchive = async (status = ApprovalStatuses.Archived) => {
    const fallbackStatus = values.approval_status
    values.approval_status = status
    setLoading(true)
    try {
      showStatusPopup({
        status: 'pending',
        title: isArchived ? 'Un-archiving.' : 'Archiving.',
        description: 'It may take some time',
      })
      const result = await submit()
      showStatusPopup({
        status: 'success',
        title: !isArchived ? 'Archived successfully' : 'Unarchived successfully',
      })
      return result
    } catch (e) {
      const defaultErr = isArchived
        ? 'Un-archive was unsuccessful'
        : 'Archive was unsuccessful'
      showStatusPopup({
        title: 'Something went wrong',
        description: getStringMessageFromError(e) || defaultErr,
        status: 'error',
      })
      values.approval_status = fallbackStatus
    } finally {
      setLoading(false)
    }
    return null
  }

  if (isArchived) {
    return (
      <MoreBar.Action
        useIcon="Unarchive"
        pending={loading}
        onClick={() => {
          handleArchive(ApprovalStatuses.Pending)
        }}
      >
        Unarchive
      </MoreBar.Action>
    )
  }

  const hasOpenRequisitions = !isLoading && !!openRequisitions?.count

  return (
    <>
      {openArchivePopup && (
        <Popup
          open
          preventUserClose
          variant="bottom-sheet"
          onClose={() => {
            setOpenArchivePopup(false)
          }}
        >
          <Header variant="bottom-sheet">
            <Header.Title>Are you sure?</Header.Title>
          </Header>
          <Text use="p" variant="caption" color={Token.color.greyTone50}>
            This action will archive the job posting. It might require approvals to open
            it again after archiving
          </Text>
          <Popup.Actions horizontal>
            <Button
              variant="primary"
              onClick={() => {
                setOpenArchivePopup(false)
              }}
            >
              Cancel
            </Button>
            <Button
              pending={loading}
              elevated
              variant="negative"
              onClick={() => {
                setOpenArchivePopup(false)
                handleArchive()
              }}
            >
              Archive
            </Button>
          </Popup.Actions>
        </Popup>
      )}
      <MoreBar.Action
        {...tooltip.getAnchorProps()}
        useIcon="Archive"
        variant="negative"
        pending={loading}
        onClick={event => {
          if (hasOpenRequisitions) {
            event.preventDefault()
          } else {
            setOpenArchivePopup(true)
          }
        }}
        aria-disabled={hasOpenRequisitions}
      >
        {hasOpenRequisitions && (
          <Tooltip {...tooltip.getTargetProps()}>
            This Job Posting has an active requisition connected.
            <br />
            Please assign requisitions to another job posting before archiving this one.
          </Tooltip>
        )}
        Archive job posting
      </MoreBar.Action>
    </>
  )
}
