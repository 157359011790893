import React, { useState } from 'react'
import { Group, Link, Box, Subheader, MoreBar, Text, chain } from '@revolut/ui-kit'
import { Archive, Unarchive } from '@revolut/icons'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BenefitsInterface } from '@src/interfaces/benefits'
import { PageBody } from '@src/components/Page/PageBody'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import { onPackageDetailsClick, PackageDetailItem } from './common'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { archiveBenefit, benefitsRequests } from '@src/api/benefits'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { UploadedFileToDownload } from '@src/components/Inputs/FileUploader/UploadedFile'
import { formatMoney } from '@src/utils/format'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { getStatusColor } from '@src/components/CommonSC/General'

const Preview = () => {
  const { values, initialValues, reset } = useLapeContext<BenefitsInterface>()

  const [archivePending, setArchivePending] = useState(false)

  const isActive = initialValues.status?.id === Statuses.active

  const canChange = values.field_options?.permissions?.includes(
    PermissionTypes.ChangeBenefitsTemplate,
  )
  const canArchive = values.field_options?.actions?.includes(EntityPermissions.Archive)
  const canDelete = values.field_options?.actions?.includes(EntityPermissions.Delete)
  const canPerformAnyAction = canChange || canArchive || canDelete

  const isFixedAmount = initialValues.contribution_method?.id === 'fixed_amount'
  const isPercentOfQualifyingEarnings =
    initialValues.contribution_method?.id === 'percentage_of_qualifying_earnings'
  const isPercentOfSalary =
    initialValues.contribution_method?.id === 'percentage_of_salary'

  const onArchiveClick = () => {
    setArchivePending(true)

    archiveBenefit(values.id, isActive ? 'archive' : 'unarchive')
      .then(response => reset({ ...values, status: response.data.status }))
      .finally(() => setArchivePending(false))
  }

  return (
    <>
      <PageHeader
        title={chain(
          initialValues?.name,
          <Text color={getStatusColor(initialValues.status?.id)}>
            {initialValues.status?.name}
          </Text>,
        )}
        backUrl={ROUTES.APPS.BENEFITS.TEMPLATES}
      />

      <PageBody>
        {canPerformAnyAction ? (
          <SettingsButtons mb="s-16">
            <EditButton
              route={pathToUrl(ROUTES.FORMS.BENEFITS.GENERAL, { id: values.id })}
              isVisible={canChange}
            />
            {canArchive && (
              <MoreBar.Action
                onClick={onArchiveClick}
                useIcon={isActive ? Archive : Unarchive}
                variant={isActive ? 'negative' : 'primary'}
                pending={archivePending}
              >
                {isActive ? 'Archive' : 'Unarchive'}
              </MoreBar.Action>
            )}
            {canDelete && (
              <DeleteButton
                deleteApi={benefitsRequests.delete!}
                title="benefit template"
                backUrl={ROUTES.APPS.BENEFITS.TEMPLATES}
              />
            )}
          </SettingsButtons>
        ) : null}

        <FormPreview title="Benefit details" data={initialValues}>
          <Group>
            <FormPreview.Item title="Category" field="category.name" />
            <FormPreview.Item
              title="Provider"
              insert={() => {
                if (!initialValues.provider_url) {
                  return '-'
                }
                return (
                  <Link
                    href={initialValues.provider_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open
                  </Link>
                )
              }}
            />
            <FormPreview.Item title="Created on" field="creation_date_time" type="date" />
            <FormPreview.Item title="Owner" field="owner" type="employee" />
            <FormPreview.Item
              title="Benefit guide"
              insert={() => {
                if (!initialValues.confluence_url) {
                  return '-'
                }
                return (
                  <Link
                    href={initialValues.confluence_url}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Open
                  </Link>
                )
              }}
            />
            <FormPreview.Details title="Description" field="description" />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <FormPreview title="Enrolment details" data={initialValues}>
            <Group>
              <FormPreview.Item
                title="Eligibility groups"
                insert={() =>
                  initialValues.eligibility_groups?.map(g => g.name).join(', ') || '-'
                }
              />
              <FormPreview.Item
                title="Cut-off date"
                insert={() => {
                  // TODO: handle yearly cases when BE supports it
                  if (
                    initialValues.cut_off_period?.id === 'monthly' &&
                    initialValues.cut_off_period_day_of_month
                  ) {
                    return `Monthly on ${initialValues.cut_off_period_day_of_month}`
                  }

                  return '-'
                }}
              />
              <FormPreview.Item
                title="Employee can opt out"
                field="employee_can_opt_out"
                type="boolean"
              />
              <FormPreview.Item
                title="Enrolment documents required"
                field="enrolment_documents_required"
                type="boolean"
              />
              {initialValues.enrolment_documents?.map(document => (
                <UploadedFileToDownload
                  id={document.id}
                  title={document.name}
                  size={document.size}
                  key={document.id}
                />
              ))}
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview title="Contribution details" data={initialValues}>
            <Group>
              <FormPreview.Item
                title="Contribution method"
                field="contribution_method.name"
              />
              {isFixedAmount ? (
                <FormPreview.Item
                  title="Contribution currency"
                  field="contribution_currency.name"
                />
              ) : null}
              {isPercentOfSalary || isPercentOfQualifyingEarnings ? (
                <FormPreview.Item
                  title="Employee can edit contribution"
                  field="employee_can_edit_contribution"
                  type="boolean"
                />
              ) : null}
              <FormPreview.Item
                title="Contribution frequency"
                field="contribution_frequency.name"
              />
              {isFixedAmount ? (
                <FormPreview.Item
                  title="Notional value of the benfit"
                  insert={() =>
                    initialValues.notional_value_of_benefit == null
                      ? '-'
                      : formatMoney(
                          +initialValues.notional_value_of_benefit,
                          initialValues.contribution_currency?.iso_code,
                        )
                  }
                />
              ) : null}
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Packages summary</Subheader.Title>
          </Subheader>
          <Group>
            {initialValues.packages?.map((benefitPackage, index) => (
              <PackageDetailItem
                onClick={() =>
                  onPackageDetailsClick({
                    packageId: benefitPackage.id,
                    id: initialValues.id!,
                    index,
                  })
                }
                benefitPackage={benefitPackage}
                benefit={initialValues}
                key={index}
              />
            ))}
          </Group>
        </Box>

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Instructions</Subheader.Title>
          </Subheader>
          <HTMLEditor
            value={initialValues.instructions}
            placeholder="Instructions"
            height="auto"
            onChange={() => {}}
            readOnly
          />
        </Box>
      </PageBody>
    </>
  )
}

export default Preview
