import {
  OrgEntityInterface,
  OrgEntityProvider,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import React, { useMemo } from 'react'
import { GoalsTab } from '@src/features/Goals/GoalsTab'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import { EntityTypes } from '@src/constants/api'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'

const GoalsBase = () => {
  const { data, isLoading } = useGetCompany()

  const entity = useMemo<OrgEntityInterface | undefined>(() => {
    return data
      ? {
          type: EntityTypes.companyV2,
          data,
        }
      : undefined
  }, [data])

  if (isLoading || !data) {
    return <Loader />
  }

  return (
    <OrgEntityProvider entity={entity}>
      <GoalsTab />
    </OrgEntityProvider>
  )
}

export const Goals = () => {
  return (
    <OnboardingAppScreen category="goals">
      <GoalsBase />
    </OnboardingAppScreen>
  )
}
