import React from 'react'
import { Group, MoreBar, TextButton, Text } from '@revolut/ui-kit'

import { FormPreview } from '@components/FormPreview/FormPreview'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { DocumentPreview } from '@src/features/DocumentSidebar/DocumentPreview'
import {
  DocumentUploadRequestInterface,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { pathToUrl } from '@src/utils/router'
import capitalize from 'lodash/capitalize'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { formatDate } from '@src/utils/format'
import { getStatusColor } from '@src/components/CommonSC/General'

type Props = {
  data: DocumentUploadRequestInterface
  documentListItem: EmployeeDocumentListItemInterface
  onClose: VoidFunction
  previewContainerHeight?: number
}
export const UploadPreview = ({
  data,
  documentListItem,
  onClose,
  previewContainerHeight,
}: Props) => {
  return (
    <DocumentPreview
      id={Number(documentListItem.recipient_id)}
      document={data}
      previewContainerHeight={previewContainerHeight}
      renderActions={downloadButton => (
        <MoreBar>
          <MoreBar.Action
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.DOCUMENT_UPLOAD.GENERAL, {
              id: documentListItem.recipient_id,
              employeeId: documentListItem.employee.id,
            })}
            useIcon="ArrowThinRight"
          >
            Go to details page
          </MoreBar.Action>
          {downloadButton}
        </MoreBar>
      )}
      renderDetails={() => (
        <FormPreview data={data}>
          <Group>
            <FormPreview.Item<DocumentUploadRequestInterface>
              title="Category"
              field="category.name"
              insert={d => {
                if (!d.category) {
                  return 'Unknown'
                }
                return (
                  <TextButton
                    onClick={() => {
                      onClose()
                      navigateTo(
                        pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS_BY_CATEGORY, {
                          employeeId: documentListItem.employee.id,
                          categoryId: d.category?.id,
                        }),
                      )
                    }}
                  >
                    {d.category.name}
                  </TextButton>
                )
              }}
            />
            <FormPreview.Item<DocumentUploadRequestInterface>
              title="Status"
              field="status.name"
              insert={d =>
                d.status ? (
                  <Text color={getStatusColor(d.status.id)}>{d.status.name}</Text>
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item
              title="Source"
              insert={() =>
                documentListItem.source ? capitalize(documentListItem.source) : '-'
              }
            />
            <FormPreview.Item title="Issued on" field="issue_date_time" type="date" />
            <FormPreview.Item<DocumentUploadRequestInterface>
              title="Completed on"
              field="signing_date_time"
              insert={() => {
                if (documentListItem.signed_date_time) {
                  return formatDate(documentListItem.signed_date_time)
                }
                return 'n/a'
              }}
            />
            <FormPreview.Item
              title="Issued by"
              field="issuer.name"
              insert={() => <EmployeeUserWithAvatar {...documentListItem.issuer} />}
            />
          </Group>
        </FormPreview>
      )}
    />
  )
}
