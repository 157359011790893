import { createSelector } from 'reselect'
import { State } from '../index'

export const selectFeedbackState = (state: State) => state.feedback

export const selectFeedbackOpen = createSelector(
  selectFeedbackState,
  feedback => feedback.open,
)

export const selectFeedbackLoading = createSelector(
  selectFeedbackState,
  feedback => feedback.loading,
)
