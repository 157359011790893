import React from 'react'
import { Cell, Image, Box, Token } from '@revolut/ui-kit'

import SideBar from '@src/components/SideBar/SideBar'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'
import Loader from '@src/components/CommonSC/Loader'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'

interface InstructionsSidebarProps {
  isOpen: boolean
  onClose: () => void
  instructions?: string | null
}

export const InstructionsSidebar = ({
  isOpen,
  onClose,
  instructions,
}: InstructionsSidebarProps) => {
  return (
    <SideBar
      title="Instructions"
      isOpen={isOpen}
      onClose={onClose}
      sideProps={{ resizable: true }}
    >
      {instructions ? (
        <HTMLEditor value={instructions} onChange={() => {}} readOnly height="auto" />
      ) : (
        <Cell>No instructions available for this type of document request</Cell>
      )}
    </SideBar>
  )
}

interface AttachmentSidebarContentsProps {
  file_media_type?: string | null
  file_name?: string | null
  file: string | null
}

export const AttachmentSidebarContents = ({
  file_media_type,
  file_name,
  file,
}: AttachmentSidebarContentsProps) => {
  const isPdf = file_media_type === 'application/pdf'
  const isImage =
    file_media_type === 'image/jpeg' ||
    file_media_type === 'image/jpg' ||
    file_media_type === 'image/png'

  if (!file_name) {
    return null
  }

  if (!file) {
    return <Loader />
  }

  if (isImage) {
    return <Image src={file} />
  }

  if (isPdf) {
    return (
      <Box style={{ height: 'calc(100vh - 230px)' }}>
        <iframe
          src={`${file}#view=fitH&toolbar=0&navpanes=0`}
          width="100%"
          height="100%"
          frameBorder="0"
          title="Example"
        />
      </Box>
    )
  }

  return (
    <ActionWidget
      title="The example cannot be previewed"
      avatarColor={Token.color.accent}
    />
  )
}
