import { PageHeader } from '@components/Page/Header/PageHeader'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import { groupRequest } from '@src/api/accessManagement'
import { AssignGroupAccessManagementInterface } from '@src/interfaces/accessManagement'

const AssignPermissionGroupToUser = () => {
  const { groupId } = useParams<{ groupId: string }>()
  const { values } = useLapeContext<AssignGroupAccessManagementInterface>()
  const backUrl = pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.USERS, { id: groupId })

  const isGroupSource = values.source?.id === 'group'

  useEffect(() => {
    const fetchGroup = async () => {
      const resp = await groupRequest.get({ id: groupId })
      values.group = {
        id: resp.data.id,
        name: resp.data.name,
      }
    }

    if (!values.id) {
      fetchGroup()
    }

    if (!values.start_date_time) {
      values.start_date_time = new Date().toISOString()
    }

    if (!values.end_date_time) {
      values.end_date_time = null
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Assign permission group to the user"
        subtitle={values.group?.name}
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <InputGroup>
            <LapeRadioSelectInput
              name="user"
              label="User"
              selector={selectorKeys.active_users}
              disabled={isGroupSource}
            />
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                disabledDays={{ before: new Date() }}
                defaultValue={new Date()}
                required
                name="start_date_time"
                label="From Date"
                disabled={isGroupSource}
              />
              <LapeDatePickerInput
                disabledDays={
                  values.start_date_time
                    ? { before: new Date(values.start_date_time) }
                    : undefined
                }
                required
                name="end_date_time"
                label="To Date"
                disabled={isGroupSource}
              />
            </InputGroup>
            <LapeNewTextArea
              name="reason"
              label="Notes"
              rows={2}
              required
              disabled={isGroupSource}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      {isGroupSource ? null : (
        <PageActions>
          <NewSaveButtonWithPopup
            afterSubmitUrl={backUrl}
            useValidator
            successText="Group has been assigned to the user successfully"
          />
        </PageActions>
      )}
    </PageWrapper>
  )
}

export default connect(AssignPermissionGroupToUser)
