import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { PermissionInterface } from '@src/interfaces/accessManagement'
import { Group } from '@revolut/ui-kit'

const Details = () => {
  const { values } = useLapeContext<PermissionInterface>()

  return (
    <PageBody>
      <FormPreview<PermissionInterface> data={values}>
        <Group>
          <FormPreview.Item title="Name" field="name" />
          <FormPreview.Item title="Codename" field="codename" />
        </Group>
      </FormPreview>
    </PageBody>
  )
}

export default Details
