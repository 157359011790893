import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import General from './General'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { connect } from 'lape'
import { dataRetentionSettingsRequestsNew } from '@src/api/dataRetentionSettings'
import Preview from '@src/pages/Forms/DataRetentionSettings/Preview'

const DataRetentionSettings = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route path={ROUTES.FORMS.DATA_RETENTION_SETTINGS.GENERAL}>
          <General />
        </Route>
        <Route path={ROUTES.FORMS.DATA_RETENTION_SETTINGS.PREVIEW}>
          <Preview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={dataRetentionSettingsRequestsNew}>
    <DataRetentionSettings />
  </Form>
))
