export const ANSWER_SCORE_KEY = 'answer_score'
export const QUESTION_TYPE_KEY = 'question__type'
export const DRIVER_ID_KEY = 'driver__id'
export const QUESTION_ID_KEY = 'question__id'
export const CREATION_DATE_TIME_KEY = 'creation_date_time'
export const SURVEY_ID_KEY = 'survey__id'

export enum QueryParams {
  survey = 'survey',
  question = 'question',
  driver = 'driver',
  average = 'average',
  chartView = 'chart_view',
  driverComments = 'driver_comments',
  questionComments = 'question_comments',
  round = 'round',
}

export const PageStateKeys = [
  QueryParams.survey,
  QueryParams.question,
  QueryParams.driver,
  QueryParams.average,
  QueryParams.chartView,
]
