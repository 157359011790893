import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { API } from '@src/constants/api'
import { timelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { useSwitchCycleStage } from '@src/api/reviewCycles'
import { useQueryClient } from 'react-query'
import { useCallback } from 'react'

interface StageSwitcher {
  isLoading: boolean
  next: () => Promise<ReviewCycleStage | null>
  previous: () => Promise<ReviewCycleStage | null>
}

export const useSwitchStage = (
  cycle: ReviewCyclesInterface,
  stages: ReviewCycleStage[],
): StageSwitcher => {
  const queryClient = useQueryClient()
  const { mutateAsync: switchStage, isLoading } = useSwitchCycleStage(cycle.id)

  const handleOnSwitchStage = useCallback(
    (stage: ReviewCycleStage) => {
      queryClient.setQueryData<ReviewCyclesInterface>(
        [`${API.REVIEW_CYCLES}/${cycle.id}`, 'v1', null],
        oldData => ({ ...oldData!, current_stage: stage }),
      )
    },
    [queryClient],
  )

  const move = useCallback(
    async (direction: 'backward' | 'forward'): Promise<ReviewCycleStage | null> => {
      const nextStage =
        direction === 'forward'
          ? timelineModel.getNextStage(cycle, stages)
          : timelineModel.getPreviousStage(cycle, stages)

      if (nextStage === null) {
        return null
      }

      await switchStage(
        {
          current_stage: nextStage,
        },
        {
          onSuccess: () => handleOnSwitchStage(nextStage),
        },
      )

      return nextStage
    },
    [cycle, stages],
  )

  return {
    isLoading,
    next: () => move('forward'),
    previous: () => move('backward'),
  }
}
