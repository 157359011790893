import React, { useMemo } from 'react'
import {
  getPromotionStagesRequest,
  getPromotionComments,
  useGetPromotionNomination,
} from '@src/api/promotions'
import { useLocation, useParams } from 'react-router-dom'
import {
  getPromotionStatusTextAndColor,
  getTargetSeniorityAndSpecialisation,
  PromotionCommonProps,
  PromotionParams,
} from '@src/pages/Forms/PromotionNominationForm/common/common'
import PageLoading from '@components/PageLoading/PageLoading'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Box, Cell, chain, Link, Subheader, Text } from '@revolut/ui-kit'
import { PromotionLocationState } from '@src/interfaces/promotions'
import { PageBody } from '@components/Page/PageBody'
import ReviewTimeline, {
  getStagesSummary,
} from '@src/pages/Forms/PromotionNominationForm/common/ReviewTimeline'
import SettingsButtons from '@src/features/SettingsButtons'
import ChangeStageButton from '@src/pages/Forms/PromotionNominationForm/Buttons/ChangeStageButton'
import { useTable } from '@components/Table/hooks'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import CancelButton from '@src/pages/Forms/PromotionNominationForm/Buttons/CancelButton'
import { ReopenButton } from '@src/pages/Forms/PromotionNominationForm/Buttons/ReopenButton'
import { Statuses } from '@src/interfaces'
import NomineeSummary from '@src/pages/Forms/PromotionNominationForm/common/NomineeSummary'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { EligibilityCriteria } from '@src/pages/Forms/PromotionNominationForm/common/EligibilityCriteria'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import Page404 from '@src/pages/Page404/Page404'

const PromotionNominationPreview = ({ employee }: PromotionCommonProps) => {
  const { id, employeeId } = useParams<PromotionParams>()
  const { state } = useLocation<PromotionLocationState>()

  const {
    data: nomination,
    isLoading,
    refetch: refetchNomination,
  } = useGetPromotionNomination(employee.id, id)

  const stagesTable = useTable({ getItems: getPromotionStagesRequest(id) })
  const stagesSummary = useMemo(
    () => getStagesSummary(nomination?.current_stages, stagesTable.data),
    [stagesTable.data, nomination?.current_stages],
  )

  const permissions = useSelector(selectPermissions)
  const canChangeStage = permissions.includes(PermissionTypes.ChangePromotionStage)
  const canCancel = permissions.includes(PermissionTypes.DeletePromotionNomination)
  const canReopen = nomination?.field_options?.permissions?.includes(
    PermissionTypes.ReopenPromotionNomination,
  )

  if (!id) {
    return (
      <InternalRedirect
        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, { id: employee.id })}
      />
    )
  }

  if (!nomination || isLoading) {
    return <PageLoading />
  }

  if (nomination.employee.id !== Number(employeeId)) {
    return <Page404 />
  }

  const { status, statusColor } = getPromotionStatusTextAndColor(nomination)
  const { targetSeniority, targetSpecialisation } =
    getTargetSeniorityAndSpecialisation(nomination)

  const employeeUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, {
    id: employee.id,
  })

  const backUrl =
    state?.backUrl ||
    pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, { id: employee.id })

  const refreshData = () => {
    refetchNomination()
    stagesTable.refresh()
  }

  const api = getPromotionComments(nomination.id)

  return (
    <PageWrapper>
      <PageHeader
        title={chain('Promotion nomination', <Text color={statusColor}>{status}</Text>)}
        backUrl={backUrl}
        subtitle={
          <Link href={employeeUrl} target="_blank" color="inherit">
            {employee.full_name}
          </Link>
        }
      />
      <PageBody>
        <SettingsButtons pb="s-24">
          {canChangeStage && nomination.status === Statuses.in_review && (
            <ChangeStageButton
              nominationId={id}
              stages={stagesTable.data}
              stagesSummary={stagesSummary}
              onSuccess={refreshData}
              api={api}
            />
          )}
          {canCancel && nomination.status === Statuses.in_review && (
            <CancelButton
              employeeId={employee.id}
              nominationId={nomination.id}
              onSuccess={refreshData}
              api={api}
            />
          )}
          {canReopen && nomination.status === Statuses.cancelled && (
            <ReopenButton
              nominationId={id}
              employeeId={employeeId}
              onSuccess={refetchNomination}
            />
          )}
        </SettingsButtons>
        <NomineeSummary
          nomination={nomination}
          employee={employee}
          employeeUrl={employeeUrl}
          targetSeniority={targetSeniority}
          targetSpecialisation={targetSpecialisation}
        />
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Review Timeline</Subheader.Title>
          </Subheader>
          <ReviewTimeline
            table={stagesTable}
            stagesSummary={stagesSummary}
            nomination={nomination}
            nominatedEmployee={employee}
            refreshData={refreshData}
          />
        </Box>
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Employee eligibility</Subheader.Title>
          </Subheader>
          {nomination?.progression_track && (
            <Cell>
              <EligibilityCriteria
                employeeId={employee.id}
                targetSeniority={targetSeniority}
                targetSpecialisation={targetSpecialisation}
                nominationId={String(nomination.id)}
                showSubtitle
                employee={employee}
                nomination={nomination}
                progressionTrack={nomination.progression_track}
              />
            </Cell>
          )}
        </Box>
        <Box mt="s-32">
          <CommentsSection api={api} />
        </Box>
      </PageBody>
    </PageWrapper>
  )
}

export default PromotionNominationPreview
