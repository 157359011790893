import React from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Button } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { TabsInterface } from '@src/interfaces/data'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import { pathToUrl } from '@src/utils/router'

interface NextButtonProps {
  tabs: TabsInterface<ReportInterface>[]
}

export const NextButton = connect(({ tabs }: NextButtonProps) => {
  const location = useLocation()
  const params = useParams<{ id?: string }>()

  const currentTabIndex = tabs.findIndex((tab: TabsInterface<ReportInterface>) =>
    matchPath(location.pathname, tab.path),
  )

  if (currentTabIndex >= tabs.length - 1) {
    return null
  }

  const handleNext = () => {
    navigateReplace(pathToUrl(tabs[currentTabIndex + 1].path, params) + location.search)
  }

  return (
    <Button elevated onClick={handleNext}>
      Continue
    </Button>
  )
})
