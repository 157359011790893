import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Employee from '@src/pages/Forms/SendEmployeeEmail/Employee'
import View from '@src/pages/Forms/SendEmployeeEmail/View/View'
import { useEmployeeEmailThread } from '@src/api/employeeEmails'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'

export const SendEmployeeEmail = () => {
  const params = useParams<{ employeeId?: string; threadId?: string; emailId?: string }>()
  const backUrl = ROUTES.PEOPLE.EMPLOYEES

  const { data, isLoading } = useEmployeeEmailThread(params.employeeId, params.threadId)

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={data?.subject ? 'Send reply' : 'Send email'}
        subtitle={
          params.employeeId ? <EmployeeUserWithAvatar id={params.employeeId} /> : null
        }
        backUrl={backUrl}
        isLoading={isLoading}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.EMPLOYEE}>
          <Employee />
        </Route>
        <Route exact path={ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.VIEW}>
          <View />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
