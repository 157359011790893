import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  Button,
  Popup,
  PopupProps,
  Input,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { captureException } from '@sentry/react'
import { engagementDriverFormRequests } from '@src/api/engagement'
import { EngagementDriverInterface } from '@src/interfaces/engagement'

export const CreateDriverPopup = (
  props: PopupProps & { onClose: (newCategory?: EngagementDriverInterface) => void },
) => {
  const user = useSelector(selectUser)

  const [name, setName] = useState<string>('')
  const [error, setError] = useState<string>()
  const statusPopup = useStatusPopup()

  const handleClose = (newCategory?: EngagementDriverInterface) => {
    setName('')
    setError(undefined)
    props.onClose(newCategory)
  }

  const handleCreate = async () => {
    try {
      const result = await engagementDriverFormRequests.submit(
        { name, owner: { id: user.id } },
        {},
      )
      handleClose(result.data)
    } catch (err) {
      captureException(err)
      if (err.response?.data?.name) {
        setError(err.response.data.name)
      } else {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to create category</StatusPopup.Title>
            <StatusPopup.Description>
              Something went wrong. Please try again.
            </StatusPopup.Description>
          </StatusPopup>,
        )
      }
    }
  }
  return (
    <Popup {...props} onClose={handleClose} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Add new question category</Header.Title>
      </Header>
      <Input
        label="Name"
        aria-invalid={!!error}
        errorMessage={error}
        value={name}
        onChange={e => {
          if (error) {
            setError(undefined)
          }
          setName(e.currentTarget.value)
        }}
      />
      <BottomSheet.Actions horizontal>
        <Button
          elevated
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleCreate()
          }}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </Popup>
  )
}
