import React from 'react'
import { ActionButton, Box, Item, Subheader, Widget } from '@revolut/ui-kit'
import { useGetJobPostingRequisitions } from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { RequisitionTable } from '@src/features/JobPostingFlow/Details/RequisitionTable'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { RemoveConnectedRequisitionHandler } from '@src/constants/columns/connectedRequisitions'

interface ConnectedRequisitionsProps {
  requisitions: RequisitionInterface[]
  onConnectRequisitions: VoidFunction
  onRemoveConnectedRequisition: RemoveConnectedRequisitionHandler
}

export const ConnectedRequisitions = ({
  requisitions,
  onConnectRequisitions,
  onRemoveConnectedRequisition,
}: ConnectedRequisitionsProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetJobPostingRequisitions(values.id)
  return (
    <Box>
      <Subheader>
        <Subheader.Title>Requisition</Subheader.Title>
      </Subheader>
      <Widget>
        <Item>
          <Item.Content>
            <Item.Title>Connect to requisitions</Item.Title>
          </Item.Content>
          <Item.Side>
            <ActionButton
              useIcon="Plus"
              onClick={onConnectRequisitions}
              disabled={!values.specialisation?.id}
            >
              Add requisition
            </ActionButton>
          </Item.Side>
        </Item>
        <RequisitionTable
          loading={isLoading}
          requisitions={[...requisitions, ...(data?.results || [])]}
          onRemoveConnectedRequisition={onRemoveConnectedRequisition}
        />
      </Widget>
    </Box>
  )
}
