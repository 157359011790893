import React from 'react'

import { ColorTagVariant } from '@components/ColorTag/ColorTag'
import Table from '@src/components/TableV2/Table'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  EmployeeInterface,
  EmployeeOptionInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { selectorKeys } from '../api'
import { Box } from '@revolut/ui-kit'

export const employeeNameColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  insert: ({ data }) => (
    <Table.EmployeeCell employee={{ ...data, status: undefined }} size={40} />
  ),
  selectorsKey: selectorKeys.all_employees,
  title: 'Name',
}

export const employeeNameWithEventColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'full_name',
  sortKey: 'full_name',
  filterKey: 'id',
  insert: ({ data }) => (
    <Box p="5px 0px 5px 12px">
      <Table.EmployeeCell
        employee={{ ...data, status: undefined }}
        size={40}
        event={data.event}
      />
    </Box>
  ),
  padding: '0px 8px 0px 0px',
  selectorsKey: selectorKeys.all_employees,
  title: 'Name',
}

export const ownerFullNameColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Created By',
}

export const ownerNameColumn: ColumnInterface<{
  owner?: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

export const ownerNameWithAvatarColumn: ColumnInterface<{
  owner?: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

export const recruiterNameColumn: ColumnInterface<{
  recruiter?: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'recruiter.id',
  dataPoint: 'recruiter.full_name',
  sortKey: 'recruiter__full_name',
  filterKey: 'recruiter__id',
  selectorsKey: selectorKeys.employee,
  title: 'Recruiter',
  insert: ({ data }) => <Table.EmployeeCell employee={data.recruiter} />,
}

export const lineManagerColumn: ColumnInterface<{
  line_manager: EmployeeOptionInterface | null
}> = {
  type: CellTypes.insert,
  idPoint: 'line_manager.id',
  dataPoint: 'line_manager.full_name',
  sortKey: 'line_manager__full_name',
  filterKey: 'line_manager__id',
  selectorsKey: selectorKeys.active_manager,
  title: 'Line manager',
  insert: ({ data }) => <Table.EmployeeCell employee={data.line_manager} />,
}

export const functionalManagerColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'quality_control.id',
  dataPoint: 'quality_control.full_name',
  sortKey: 'quality_control__full_name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.active_manager,
  title: 'Functional Manager',
}

export const teamOwnerColumn: ColumnInterface<{
  team_owner: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'team_owner.id',
  dataPoint: 'team_owner.full_name',
  sortKey: 'team_owner__full_name',
  filterKey: 'team_owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => {
    return <Table.EmployeeCell employee={data.team_owner} />
  },
}

export const roleManagerColumn: ColumnInterface<{
  role_manager: EmployeeOptionInterface
}> = {
  type: CellTypes.insert,
  idPoint: 'role_manager.id',
  dataPoint: 'role_manager.full_name',
  sortKey: 'role_manager__full_name',
  filterKey: 'role_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.role_manager} />,
}

export const qualityControlRevolutersColumn: ColumnInterface<RevolutersInterface> = {
  type: CellTypes.insert,
  idPoint: 'quality_control.id',
  dataPoint: 'quality_control.name',
  sortKey: 'quality_control__full_name',
  filterKey: 'quality_control__id',
  selectorsKey: selectorKeys.manager,
  title: 'Functional manager',
  insert: ({ data }) => <Table.EmployeeCell employee={data.quality_control} />,
}

export const employeeNameIdStatusColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getStatusVariant(data.status.id)}
    />
  ),
}

export const employeeStartedAtColumn: ColumnInterface<{}> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: 'joining_date_time',
  filterKey: 'joining_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const employeeEntityColumn: ColumnInterface<RevolutersInterface> = {
  type: CellTypes.text,
  idPoint: 'entity.id',
  dataPoint: 'entity.name',
  sortKey: 'entity__name',
  filterKey: 'entity__id',
  selectorsKey: selectorKeys.entity,
  title: 'Entity',
}

const getStatusVariant = (status: IdStatuses): ColorTagVariant => {
  switch (status) {
    case IdStatuses.active:
      return 'success'

    case IdStatuses.pending:
      return 'warning'

    default:
      return 'neutral'
  }
}

export const employeeStatusColumn: ColumnInterface<
  EmployeeInterface | RevolutersInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_status_pending,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getStatusVariant(data.status.id)}
    />
  ),
}

export const employeeTypeColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'employee_type.id',
  dataPoint: 'employee_type.name',
  sortKey: 'employee_type',
  filterKey: 'employee_type',
  selectorsKey: selectorKeys.employee_types,
  title: 'Type',
}

export const eligibleEmployeeTypeColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'employee_type',
  dataPoint: 'employee_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}

export const eligibleEmployeeContractColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'contract_type.id',
  dataPoint: 'contract_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract',
}

export const eligibleEmployeeLocationColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.location_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Header',
}

export const eligibleEmployeeStartedAtColumn: ColumnInterface<{}> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const employeeCountryColumn: ColumnInterface<{}> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: null,
  filterKey: 'location__country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Country',
}
