import { ROUTES } from '@src/constants/routes'
import { Employees } from '@src/pages/People/PeopleSubTabs/Employees'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'
import { AllDocuments } from '@src/pages/People/PeopleSubTabs/Documents/AllDocuments'
import { HomeSectionId, TabBarWithPinningTabInterface } from '@src/interfaces/tabPinning'
import { TimeManagement } from '@src/pages/People/PeopleSubTabs/TimeOffPage/TimeManagement'
import { PayCyclesTable } from '@src/apps/People/Payroll/PayCyclesTable/PayCyclesTable'

export const peopleSubtabs: TabBarWithPinningTabInterface[] = [
  {
    id: HomeSectionId.employees,
    title: 'Employees',
    path: ROUTES.PEOPLE.EMPLOYEES,
    url: ROUTES.PEOPLE.EMPLOYEES,
    permission: PermissionTypes.ViewEmployees,
    component: Employees,
    icon: 'People',
  },
  {
    id: HomeSectionId.timeManagement,
    title: 'Time Management',
    path: ROUTES.PEOPLE.TIME_MANAGEMENT,
    url: ROUTES.PEOPLE.TIME_MANAGEMENT,
    globalSetting: GlobalSettings.TimeOffEnabled,
    icon: 'CalendarDate',
    component: TimeManagement,
  },
  {
    id: HomeSectionId.documents,
    title: 'Documents',
    path: ROUTES.PEOPLE.DOCUMENTS,
    url: ROUTES.PEOPLE.DOCUMENTS,
    permission: PermissionTypes.ViewEmployeeDocuments,
    component: AllDocuments,
    icon: 'DocumentsPair',
  },
  {
    id: HomeSectionId.payrollPayCycles,
    title: 'Payroll',
    path: ROUTES.PEOPLE.PAYROLL,
    url: ROUTES.PEOPLE.PAYROLL,
    globalSetting: GlobalSettings.PayrollEnabled,
    permission: PermissionTypes.ViewPaygroup,
    icon: 'Credit',
    component: PayCyclesTable,
  },
]
