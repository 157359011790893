import { Token } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { FilterByInterface } from '@src/interfaces/data'

export const getBaseKpiFilters = (goalsEnabled: boolean): FilterByInterface[] => [
  {
    filters: [
      { name: Statuses.pending, id: Statuses.pending },
      { name: Statuses.approved, id: Statuses.approved },
      { name: Statuses.future, id: Statuses.future },
      { name: Statuses.completed, id: Statuses.completed },
      { name: Statuses.requires_changes, id: Statuses.requires_changes },
    ],
    columnName: 'target_status',
  },
  {
    filters: [{ name: `0`, id: 0 }],
    columnName: 'review_cycle__offset',
    nonResettable: true,
  },
  {
    columnName: 'goals_related',
    filters: [
      { id: goalsEnabled ? 'true' : 'false', name: goalsEnabled ? 'true' : 'false' },
    ],
    nonResettable: true,
  },
]

export const rowHighlight = (status: Statuses | ApprovalStatuses) => {
  return [Statuses.pending, Statuses.requires_changes].includes(status as Statuses)
    ? Token.color.orange_5
    : ''
}
