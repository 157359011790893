import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  ErrorWidget,
  Group,
  InputGroup,
  Item,
  Text,
  Side,
  Subheader,
  TextButton,
  Color,
  ItemSkeleton,
} from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import SideBar from '@components/SideBar/SideBar'
import {
  addPayCycleFormRequests,
  getPaycyclePreview,
  usePaygroup,
} from '@src/api/payroll'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  ManuallyAddedPayCycleInterface,
  PayCyclePreviewInterface,
} from '@src/interfaces/payroll'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectorKeys } from '@src/constants/api'
import { TIMELINE_STEPS } from '../PayCycle/General/TimelineEdit'
import { formatDate, formatPeriod } from '@src/utils/format'
import { isNil, isNumber } from 'lodash'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type SidebarContentProps = {
  onSuccess: () => void
}
const AddNewPayCycleSidebarContent = connect(({ onSuccess }: SidebarContentProps) => {
  const { values } = useLapeContext<ManuallyAddedPayCycleInterface>()
  const { data: paygroupData } = usePaygroup(values.pay_group?.id)
  const [previews, setPreviews] = useState<PayCyclePreviewInterface[]>()
  const [selectedPreviewIdx, setSelectedPreviewIdx] = useState<number>()
  const [missingFields, setMissingFields] = useState<string[]>([])
  const [isLoadingPreviews, setIsLoadingPreviews] = useState(false)

  const selectablePreviews =
    previews?.map(({ start_date, end_date }, index) => ({
      label: formatPeriod(new Date(start_date), new Date(end_date)),
      value: {
        id: index,
        name: formatPeriod(new Date(start_date), new Date(end_date)),
      },
    })) ?? []

  const getPreviews = async () => {
    if (!paygroupData) {
      return
    }
    setIsLoadingPreviews(true)
    setPreviews([])
    setSelectedPreviewIdx(undefined)
    try {
      setMissingFields([])
      const res = await getPaycyclePreview({ ...paygroupData, number_of_schedules: 12 })
      setPreviews(res.data)
    } catch (e) {
      const newMissingFields = Object.keys(e.response.data).map(field =>
        field.replaceAll('_', ' '),
      )
      setMissingFields(newMissingFields)
    } finally {
      setIsLoadingPreviews(false)
    }
  }

  const fillPreviewDates = (data: PayCyclePreviewInterface) => {
    if (!data) {
      return
    }

    for (const step of TIMELINE_STEPS) {
      values[step.id] = data[step.id] || null
    }
  }

  useEffect(() => {
    if (paygroupData) {
      getPreviews()
    }
  }, [paygroupData])

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput
          name="owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
        <LapeRadioSelectInput
          required
          name="pay_group"
          label="Pay group"
          selector={selectorKeys.active_pay_groups}
        />
        <RadioSelectInput
          required
          name="preview"
          label="Pay cycle"
          options={selectablePreviews}
          value={
            (isNumber(selectedPreviewIdx) &&
              selectablePreviews?.[selectedPreviewIdx]?.value) ||
            null
          }
          onChange={val => {
            if (val && previews) {
              fillPreviewDates(previews[val.id])
              setSelectedPreviewIdx(val.id)
            }
          }}
        />
        <Subheader variant="nested">
          <Subheader.Title>Pay cycle preview</Subheader.Title>
        </Subheader>
        {missingFields.length ? (
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title>Pay group might not be properly set up</ErrorWidget.Title>
            <ErrorWidget.Description>
              Please check if all required fields are filled in, then try again. The
              following fields have issues:{' '}
              <Text fontWeight="bold" color={Color.RED}>
                {missingFields.join(', ')}
              </Text>
            </ErrorWidget.Description>
            <ErrorWidget.Action>
              <TextButton
                use={InternalLink}
                to={pathToUrl(ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT, {
                  id: paygroupData?.id,
                })}
              >
                Edit {paygroupData?.name || 'pay group'}
              </TextButton>
            </ErrorWidget.Action>
          </ErrorWidget>
        ) : (
          <Group>
            {TIMELINE_STEPS.map(({ id, name }) =>
              isLoadingPreviews ? (
                <ItemSkeleton>
                  <ItemSkeleton.Content>
                    <ItemSkeleton.Title />
                  </ItemSkeleton.Content>
                  <ItemSkeleton.Side />
                </ItemSkeleton>
              ) : (
                <Item key={id}>
                  <Item.Content>
                    <Item.Title>{name}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    {!isNil(values[id]) ? formatDate(values[id]) : '-'}
                  </Item.Side>
                </Item>
              ),
            )}
          </Group>
        )}
      </InputGroup>
      <Side.Actions>
        <NewSaveButtonWithPopup useValidator onAfterSubmit={onSuccess} />
      </Side.Actions>
    </>
  )
})

type Props = {
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}
export const AddNewPayCycleSidebar = ({ isOpen, onClose, onSuccess }: Props) => (
  <SideBar title="Add new pay cycle" isOpen={isOpen} onClose={onClose}>
    <Form api={addPayCycleFormRequests} disableLocalStorageCaching>
      <AddNewPayCycleSidebarContent onSuccess={onSuccess} />
    </Form>
  </SideBar>
)
