import { useMemo } from 'react'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  ChatBotConversationTicketInterface,
  ChatBotResponseInterface,
  FaqCategoryInterface,
  FaqInterface,
  FaqTopicInterface,
  ImportFaqInterface,
  ImportFaqSessionInterface,
  ServiceDeskCategoryInterface,
  ServiceDeskSubcategoryInterface,
} from '@src/interfaces/faq'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { ImportSessionStatus } from '@src/interfaces/importData'

export const useFaqTopicOptions = (params?: Object) =>
  useFetch<GetRequestData<FaqTopicInterface>>(`${API.FAQ}/topics`, undefined, { params })

export type UseFormattedFaqTopicOptionsParams = {
  filters?: FilterByInterface[]
  titleAsName?: boolean
}
export const useFormattedFaqTopicOptions = (
  params: UseFormattedFaqTopicOptionsParams = {},
) => {
  const { filters, titleAsName } = params
  const { data: topicsOptionsData, status: topicOptionsDataStatus } = useFaqTopicOptions(
    filterSortPageIntoQuery(undefined, filters),
  )

  return useMemo(() => {
    return topicsOptionsData?.results
      ? topicsOptionsData.results.map(topicOption => ({
          label: topicOption.title,
          value: {
            id: topicOption.id,
            ...(titleAsName ? { name: topicOption.title } : { title: topicOption.title }),
          },
        }))
      : []
  }, [topicOptionsDataStatus])
}

export const getFaqQuestionsOptions = ({ filters }: { filters?: Object }) =>
  api.get<GetRequestData<FaqInterface>>(`${API.FAQ}`, {
    params: filters,
  })

export const getFaqCategories = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<FaqCategoryInterface>> =>
  api.get(`${API.FAQ}/categories`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const faqCategoriesRequest: RequestInterfaceNew<FaqCategoryInterface> = {
  get: async ({ id }) => {
    return api.get(`${API.FAQ}/categories/${id}`)
  },
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.FAQ}/categories/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.FAQ}/categories`, data),
  delete: async (_, { id }) => api.delete(`${API.FAQ}/categories/${id}`),
}

export const useFaqCategories = (countryId?: string | number) =>
  useFetch<GetRequestInterface<FaqCategoryInterface>>(
    `${API.FAQ}/categories`,
    undefined,
    { params: countryId ? { country__id: `${countryId},global` } : undefined },
  )

export const sendChatBotQuery = (query: string, isStartOfConversation?: boolean) =>
  api.post<ChatBotResponseInterface>(`${API.FAQ}/chatbot`, {
    query,
    start_conversation: isStartOfConversation,
  })

export const notifyChatBotSessionClosed = (solved: boolean) =>
  api.post(`${API.FAQ}/chatbot/close`, { issue_solved: solved })

export const notifyChatBotTicketRaised = (
  ticketStatus: null | 'prefilled' | 'raised' = null,
) => api.post(`${API.FAQ}/chatbot/ticketRaised`, { ticket: ticketStatus })

export const faqTableRequests: TableRequestInterface<FaqInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.FAQ}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const faqFormRequests: RequestInterfaceNew<FaqInterface> = {
  get: async ({ id }) => api.get(`${API.FAQ}/${id}`),
  submit: async data => api.post(`${API.FAQ}`, data),
  update: async (data, { id }) => api.patch(`${API.FAQ}/${id}`, data),
}

export const getImportFaqTemplateUrl = async () => {
  const res = await api.get<Blob>(`${API.FAQ_BULK_UPLOAD}/template`, {
    responseType: 'blob',
  })
  return URL.createObjectURL(res.data)
}

export const importFaqFile = (file: File) => {
  const formData = new FormData()
  formData.append('file', file, file.name)

  return api.post(`${API.FAQ_BULK_UPLOAD}`, file, {
    headers: {
      'Content-Type': 'text/csv',
    },
  })
}

export const getFaqUploadSessionTable =
  (sessionId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ImportFaqInterface>> => {
    return api.get(`${API.FAQ_BULK_UPLOAD}/${sessionId}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const useGetImportFaqSessionData = (sessionId: string) => {
  return useFetch<ImportFaqSessionInterface>(
    `${API.FAQ_BULK_UPLOAD}/${sessionId}`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session =>
        session?.status.id === ImportSessionStatus.InProgress ? 1000 : false,
    },
  )
}

export const submitFaqBulkUpload = (sessionId: number) =>
  api.put<ImportFaqSessionInterface>(`${API.FAQ_BULK_UPLOAD}/${sessionId}`)

export const faqTopicTableRequests: TableRequestInterface<FaqTopicInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.FAQ}/topics`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const faqTopicFormRequests: RequestInterfaceNew<FaqTopicInterface> = {
  get: async ({ id }) => api.get(`${API.FAQ}/topics/${id}`),
  submit: async data => api.post(`${API.FAQ}/topics`, data),
  update: async (data, { id }) => api.patch(`${API.FAQ}/topics/${id}`, data),
}

export const serviceDeskCategoryTableRequests: TableRequestInterface<ServiceDeskCategoryInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`${API.FAQ}/service-desk/categories`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
  }

export const useServiceDeskCategories = () =>
  useFetch<GetRequestInterface<ServiceDeskCategoryInterface>>(
    `${API.FAQ}/service-desk/categories`,
  )

export const serviceDeskCategoriesRequest: RequestInterfaceNew<ServiceDeskCategoryInterface> =
  {
    get: async ({ id }) => {
      return api.get(`${API.FAQ}/service-desk/categories/${id}`)
    },
    submit: async data => {
      return apiWithoutHandling.post(`${API.FAQ}/service-desk/categories`, data)
    },
    update: async (data, { id }) => {
      return apiWithoutHandling.patch(`${API.FAQ}/service-desk/categories/${id}`, data)
    },
    delete: async (_, { id }) => {
      return api.delete(`${API.FAQ}/service-desk/categories/${id}`)
    },
  }

export const serviceDeskSubcategoriesRequest: RequestInterfaceNew<ServiceDeskSubcategoryInterface> =
  {
    get: async ({ categoryId, subcategoryId }) => {
      return api.get(
        `${API.FAQ}/service-desk/categories/${categoryId}/subcategories/${subcategoryId}`,
      )
    },
    submit: async (data, { categoryId }) => {
      return api.post(
        `${API.FAQ}/service-desk/categories/${categoryId}/subcategories`,
        data,
      )
    },
    update: async (data, { categoryId, subcategoryId }) => {
      return api.patch(
        `${API.FAQ}/service-desk/categories/${categoryId}/subcategories/${subcategoryId}`,
        data,
      )
    },
    delete: async (_, { categoryId, subcategoryId }) => {
      return api.delete(
        `${API.FAQ}/service-desk/categories/${categoryId}/subcategories/${subcategoryId}`,
      )
    },
  }

export const createTicketFromChatConversation = ({
  userId,
  userEmail,
  summary,
  description,
  issueType,
  projectKey,
  categoryId,
}: {
  userId: number
  userEmail: string
  summary: string
  description: string
  issueType: string
  projectKey: string
  categoryId?: string
}) => {
  return apiWithoutHandling.post<ChatBotConversationTicketInterface>('/jira/issues', {
    summary,
    description,
    reporter: userEmail,
    labels: ['HR'],
    issue_type: issueType,
    project_key: projectKey,
    custom_fields: {
      customfield_34442: userId,
      ...(categoryId ? { customfield_34443: { id: categoryId } } : null),
    },
  })
}
