import { EntityTypes } from '@src/constants/api'
import {
  AuditCyclesInterface,
  AuditInterface,
  AuditStatuses,
  AuditSeverities,
  AuditTypes,
} from '@src/interfaces/audit'

const excludedFromOtherTypes = [AuditTypes.AUDIT, AuditTypes.RISK, AuditTypes.VALIDATION]
export const otherTypes = Object.values(AuditTypes).filter(
  type => !excludedFromOtherTypes.includes(type),
)

export const getDataForAuditCircles = (
  entity: EntityTypes,
  staticCounts: AuditInterface[],
  type?: AuditTypes,
): AuditCyclesInterface => {
  const typePrefix = type ? `${type}_` : ''

  return {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    audits_exceptional: staticCounts[`${typePrefix}${AuditSeverities.Critical}_v2`],
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    audits_critical: staticCounts[`${typePrefix}${AuditSeverities.High}_v2`],
    audits_recommended:
      entity === EntityTypes.company
        ? 0
        : /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          staticCounts[`${typePrefix}${AuditSeverities.Medium}_v2`],
    audits_trivial:
      entity === EntityTypes.company
        ? 0
        : /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          staticCounts[`${typePrefix}${AuditSeverities.Low}_v2`],
  }
}

export const getMultipleDataForAuditCircles = (
  entity: EntityTypes,
  staticCounts: AuditInterface[],
  type: AuditTypes[],
) => {
  return type.reduce(
    (acc, curr) => {
      const typePrefix = `${curr}_`

      acc.audits_exceptional +=
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        staticCounts[`${typePrefix}${AuditSeverities.Critical}_v2`]
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      acc.audits_critical += staticCounts[`${typePrefix}${AuditSeverities.High}_v2`]

      if (entity !== EntityTypes.company) {
        acc.audits_recommended +=
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          staticCounts[`${typePrefix}${AuditSeverities.Medium}_v2`]
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        acc.audits_trivial += staticCounts[`${typePrefix}${AuditSeverities.Low}_v2`]
      }
      return acc
    },
    {
      audits_critical: 0,
      audits_exceptional: 0,
      audits_recommended: 0,
      audits_trivial: 0,
    },
  )
}

const getFilterOptions = (types: AuditTypes[]) => {
  return types.map(type => ({ name: type, id: type }))
}

export const getAuditTypeFilter = (tab: string) => {
  switch (tab) {
    case '':
      return [{ columnName: 'category', filters: [] }]
    case AuditTypes.OTHER:
      return [{ columnName: 'category', filters: getFilterOptions(otherTypes) }]
    default:
      return [{ columnName: 'category', filters: [{ name: tab, id: tab }] }]
  }
}

export const getHistoryFilter = (setFilter: boolean) => ({
  filters: setFilter
    ? [
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
        { id: AuditStatuses.DONE, name: AuditStatuses.DONE },
      ]
    : [
        { id: AuditStatuses.IN_PROGRESS, name: AuditStatuses.IN_PROGRESS },
        { id: AuditStatuses.TO_DO, name: AuditStatuses.TO_DO },
      ],
  columnName: 'status',
})

export const getSubtasksFilter = (setFilter: boolean) => ({
  filters: setFilter ? [{ name: 'True', id: 'True' }] : [],
  columnName: 'only_subtasks',
})
