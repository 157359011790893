import React, { useState } from 'react'
import { FileInterface } from '@src/interfaces/files'
import {
  Subheader,
  Widget,
  Text,
  HStack,
  Icon,
  Token,
  Image,
  ImageGrid,
  Lightbox,
} from '@revolut/ui-kit'
import { isNumber } from 'lodash'

interface Props {
  attachments: FileInterface[]
}

export const Attachments = ({ attachments }: Props) => {
  const [lightboxIndex, setLightboxIndex] = useState<number>()
  const filteredAttachments = attachments.filter(attachment => !!attachment.url)
  if (!filteredAttachments.length) {
    return null
  }
  return (
    <Widget px="s-16" pb="s-16">
      <Subheader>
        <Subheader.Title>
          <HStack align="center" gap="s-8">
            <Icon name="Image" color={Token.color.foreground} />
            <Text color={Token.color.foreground} variant="heading3">
              Attachments
            </Text>
          </HStack>
        </Subheader.Title>
      </Subheader>
      <ImageGrid>
        {filteredAttachments.map((attachment, i) => (
          <ImageGrid.Item
            key={attachment.id}
            onClick={() => {
              setLightboxIndex(i)
            }}
            style={{ cursor: 'pointer' }}
          >
            <Image
              image={attachment.url ? attachment.url : undefined}
              alt={attachment.name}
            />
          </ImageGrid.Item>
        ))}
      </ImageGrid>
      <Lightbox
        open={isNumber(lightboxIndex)}
        onClose={() => {
          setLightboxIndex(undefined)
        }}
        index={lightboxIndex}
      >
        {filteredAttachments.map(attachment => (
          <Lightbox.Item key={attachment.id}>
            <Image
              image={attachment.url ? attachment.url : undefined}
              alt={attachment.name}
            />
          </Lightbox.Item>
        ))}
      </Lightbox>
    </Widget>
  )
}
