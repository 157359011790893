import React from 'react'
import { FilterButton, MoreBar } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { getAllEmployeeScreeningChecks } from '@src/api/screening'
import {
  screeningChecksApproverColumn,
  screeningChecksCompletedOnColumn,
  screeningChecksCreatedByColumn,
  screeningChecksCreatedOnColumn,
  screeningChecksLastUpdateColumn,
  screeningChecksNameColumn,
  screeningChecksSourceColumn,
  screeningChecksStatusColumn,
} from '@src/constants/columns/ScreeningChecks'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useAssignedToMeFilter } from '@src/features/Screening/hooks'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  AllScreeningChecksInterface,
  AllScreeningChecksStatsInterface,
} from '@src/interfaces/screeningChecksInterface'
import { pathToUrl } from '@src/utils/router'
import Stat from '@src/components/Stat/Stat'

const ROW: RowInterface<AllScreeningChecksInterface> = {
  cells: [
    {
      ...screeningChecksNameColumn,
      width: 220,
    },
    {
      ...screeningChecksSourceColumn,
      width: 150,
    },
    {
      ...screeningChecksApproverColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedByColumn,
      width: 240,
    },
    {
      ...screeningChecksCreatedOnColumn,
      width: 180,
    },
    {
      ...screeningChecksLastUpdateColumn,
      width: 180,
    },
    {
      ...screeningChecksCompletedOnColumn,
      width: 140,
    },
    {
      ...screeningChecksStatusColumn,
      width: 180,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const Screening = ({ data }: Props) => {
  const table = useTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>(
    {
      getItems: getAllEmployeeScreeningChecks(data.id),
    },
    undefined,
  )

  const handleTriggerScreeningCheck = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.SCREENING.SELECT, { employeeId: data.id }))
  }

  const handleRowClick = (check: AllScreeningChecksInterface) => {
    if (check.type.id === 'hireright') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    } else if (check.type.id === 'manual') {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.PREVIEW, {
          employeeId: check.employee.id,
          id: check.screening_id,
        }),
      )
    }
  }

  const { showAssignedToMe, onToggleAssignedToMe } = useAssignedToMeFilter<
    AllScreeningChecksInterface,
    AllScreeningChecksStatsInterface
  >(table)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat val={table.count} label="Total" />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <MoreBar.Action onClick={handleTriggerScreeningCheck} useIcon="Plus">
            Add new
          </MoreBar.Action>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={showAssignedToMe}>
          Assigned to me
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<AllScreeningChecksInterface, AllScreeningChecksStatsInterface>
          hideCount
          name={TableNames.EmployeeScreening}
          useWindowScroll
          dataType="Screening Check"
          row={ROW}
          {...table}
          noDataMessage="Employee has no screening checks"
          onRowClick={handleRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
