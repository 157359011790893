import { useCallback, useState } from 'react'
import type { CookieAttributes } from 'js-cookie'
import { cookiesApi } from '@src/utils/cookies'

export function useCookie(key: string, initialValue?: string) {
  const [storedValue, setStoredValue] = useState<string | null>(() => {
    return cookiesApi.get(key) ?? initialValue ?? null
  })

  const setValue = useCallback(
    (value: string, options?: CookieAttributes) => {
      setStoredValue(value)
      cookiesApi.set(key, value, options)
    },
    [key],
  )

  const remove = useCallback(
    (options?: CookieAttributes) => {
      cookiesApi.remove(key, options)
      setStoredValue(null)
    },
    [key],
  )

  return [storedValue, setValue, remove] as const
}
