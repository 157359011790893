import React from 'react'
import { format } from 'date-fns'

type Props = {
  checkpointOpenDate: Date
}

const ClosedCheckpointMessage = ({ checkpointOpenDate }: Props) => {
  return (
    <>
      Checkpoint will open one week before the deadline (
      {format(checkpointOpenDate, 'd MMMM')})
    </>
  )
}

export default ClosedCheckpointMessage
