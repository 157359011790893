import * as React from 'react'
import { Field, useFormState } from 'react-final-form'
import { generateError, required as requiredValidator } from '../../../utils/form'
import TextAreaField, { TextAreaProps } from '../TextAreaField/TextAreaField'

interface Props extends Partial<TextAreaProps> {
  name: string
  // By default Final form drops field if it's empty
  notRemoveField?: boolean
}

const FinalTextAreaField = ({ name, notRemoveField, ...props }: Props) => {
  const { values } = useFormState()

  const hidden = values?.field_options?.no_access?.includes(name)
  const disabled = values?.field_options?.read_only?.includes(name)

  if (hidden) {
    return null
  }

  const identity = (value: string) => value

  return (
    <Field<string>
      parse={notRemoveField ? identity : undefined}
      name={name}
      validate={props.required ? requiredValidator : undefined}
    >
      {({ input: { onChange, onBlur, value }, meta }) => (
        <TextAreaField
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
          {...props}
          error={generateError(name, meta, props.error)}
        />
      )}
    </Field>
  )
}

export default FinalTextAreaField
