import { Route } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { probationAdditionSettings } from '@src/api/employees'
import AdditionSettings from './AdditionalSettingsForm'
import React from 'react'

const AdditionSettingsForm = () => {
  return (
    <Route exact path={ROUTES.FORMS.PROBATION_EXTRA_SETTINGS.GENERAL}>
      <Form api={probationAdditionSettings}>
        <AdditionSettings />
      </Form>
    </Route>
  )
}

export default AdditionSettingsForm
