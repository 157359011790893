import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, useParams } from 'react-router-dom'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import { OptionPaymentsTable } from './OptionPaymentsTable'
import { usePayCycle } from '@src/api/payroll'
import { Text, chain } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { getStatusColor } from '@src/components/CommonSC/General'
import { Statuses } from '@src/interfaces'

// TODO: RHR-3652 Create new pages for recon & delete payments tables instead of using payroll ones

export const OptionExercise = () => {
  const { id } = useParams<{ id: string }>()
  const backUrl = ROUTES.APPS.COMPENSATION.OPTION_EXERCISE
  const { data } = usePayCycle(id)
  return (
    <>
      <PageHeader
        pb="s-8"
        title="Exercise cycle"
        subtitle={
          data
            ? chain(
                formatDate(data.start_date, 'MMM yyyy'),
                <Text color={getStatusColor(data.status.id as Statuses)}>
                  {data.status.name}
                </Text>,
              )
            : undefined
        }
        backUrl={backUrl}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <Route
        exact
        path={ROUTES.FORMS.OPTION_EXERCISE.PAYMENTS}
        component={OptionPaymentsTable}
      />
    </>
  )
}
