import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosPromise } from 'axios'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import {
  HirerightScreeningChecksInterface,
  ManualScreeningChecksInterface,
} from '@src/interfaces/screeningChecksInterface'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Input, Link, MoreBar } from '@revolut/ui-kit'
import { handleError } from '@src/api'
import SettingsButtons, { ApproveButton } from '@src/features/SettingsButtons'
import { ArrowThinRight, CrossSmall } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'

const HIRERIGHT_URL = 'https://www.hireright.com'

type Props<T> = {
  approve?: (employeeId: string, id: string) => AxiosPromise<T>
  reject?: (employeeId: string, id: string, comment: string) => AxiosPromise<T>
  refetchApproval?: () => void
  text: string
  isVisible?: boolean
  isHireRight?: boolean
}

export const ApprovalBanner = <
  T extends ManualScreeningChecksInterface | HirerightScreeningChecksInterface,
>({
  approve,
  reject,
  refetchApproval,
  text,
  isVisible = true,
  isHireRight,
}: Props<T>) => {
  const { employeeId, id } = useParams<{ id: string; employeeId: string }>()
  const { values, reset } = useLapeContext<T>()

  const [isRejectPending, setIsRejectPending] = useState<boolean>(false)
  const [rejectionComment, setRejectionComment] = useState<string>('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState<boolean>(false)

  const canApproveOrReject =
    !isHireRight &&
    values.field_options?.actions?.includes(EntityPermissions.Approve) &&
    values.field_options?.permissions?.includes(PermissionTypes.ApproveScreeningCheck)

  const onApprove = async () => {
    const response = await approve?.(employeeId, id)
    if (response) {
      reset(response.data)
    }
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await reject?.(employeeId, id, rejectionComment)
      if (response) {
        reset(response.data)
      }
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval?.()
      setIsRejectPending(false)
    }
  }

  return (
    <>
      {isVisible && (
        <ActionWidget title="This screening check requires adjudication" text={text}>
          {canApproveOrReject && (
            <SettingsButtons>
              <ApproveButton
                submit={onApprove}
                onAfterSubmit={refetchApproval}
                onSubmitFailed={handleError}
                statusFieldName="approval_status"
              />
              <MoreBar.Action
                onClick={() => setRejectDialogOpen(true)}
                useIcon={CrossSmall}
                variant="negative"
                pending={isRejectPending}
              >
                Reject
              </MoreBar.Action>
            </SettingsButtons>
          )}
          {isHireRight && (
            <SettingsButtons>
              <MoreBar.Action
                useIcon={ArrowThinRight}
                use={Link}
                target="_blank"
                to={HIRERIGHT_URL}
              >
                Go to HireRight
              </MoreBar.Action>
            </SettingsButtons>
          )}
        </ActionWidget>
      )}
      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectPending}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Input
            label="Add reason here"
            value={rejectionComment}
            onChange={e => setRejectionComment(e.currentTarget.value)}
          />
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}
