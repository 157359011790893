import React from 'react'
import { Box, Flex, Text, Token, Widget } from '@revolut/ui-kit'
import CareersPositionLocationsList from '@src/pages/Careers/CareersPosition/CareersPositionLocationsList'
import CareersPositionApplyButton from '@src/pages/Careers/CareersPosition/CareersPositionApplyButton'
import DOMPurify from 'dompurify'
import { CareersPositionLocation } from '@src/interfaces/careers'
import { Video } from '@components/Video/Video'
import { formattedHTMLContentCSS } from '@src/utils/styles'

export interface CareersPositionContentProps {
  text: string
  locations: CareersPositionLocation[]
  id: string
  description: string
  disabledApplyButton?: boolean
  video?: string
}

export const CareersPositionContent = ({
  text,
  locations,
  id,
  description,
  disabledApplyButton,
  video,
}: CareersPositionContentProps) => {
  return (
    <Box pb="s-56">
      <Text
        variant="h1"
        textAlign="center"
        display="block"
        mb="s-16"
        color={Token.color.foreground}
      >
        {text}
      </Text>
      <Flex justifyContent="center" mx={{ all: 0, lg: '-s-32' }} px="s-16">
        <CareersPositionLocationsList locations={locations} />
      </Flex>
      <Box hide="*-lg" mt="s-24">
        <Flex justifyContent="center">
          <CareersPositionApplyButton
            positionId={id}
            positionTitle={text}
            disabled={disabledApplyButton}
          />
        </Flex>
      </Box>
      <Widget p="s-32" mt="s-72">
        <Text
          variant="secondary"
          css={formattedHTMLContentCSS}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
        />

        {video ? (
          <Box pt="s-40">
            <Video url={video} />
          </Box>
        ) : null}

        <Flex justifyContent="center" mt="s-32">
          <CareersPositionApplyButton
            positionId={id}
            positionTitle={text}
            disabled={disabledApplyButton}
          />
        </Flex>
      </Widget>
    </Box>
  )
}
