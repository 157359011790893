import React, { useState } from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { PerformanceOverview } from '@src/pages/Forms/Candidate/Performance/PerformanceOverview'
import { Token, VStack, Widget } from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { PerformanceSummary } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSummary'
import { ScorecardSidebar } from '@src/pages/Forms/Candidate/Performance/Summary/ScorecardSidebar'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'

type PerformanceProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
}

export const Performance = ({ candidate, round }: PerformanceProps) => {
  const [testStage, setTestStage] = useState<InterviewStageWithoutRoundInterface>()
  const [interviewFeedbackIds, setInterviewFeedbackIds] = useState<number[]>()
  const onCloseInterviewFeedbacks = () => {
    setInterviewFeedbackIds([])
  }
  return (
    <>
      <PageBody maxWidth={Token.breakpoint.lg}>
        <VStack gap="s-16">
          <PerformanceOverview candidate={candidate} round={round} />
          <Widget pb="s-16">
            <PerformanceSummary
              round={round}
              onViewScorecard={feedbackIds => {
                setInterviewFeedbackIds(feedbackIds)
                setTestStage(undefined)
              }}
              onViewTest={stage => {
                setInterviewFeedbackIds(undefined)
                setTestStage(stage)
              }}
            />
          </Widget>
        </VStack>
      </PageBody>
      {!!interviewFeedbackIds?.length && (
        <ScorecardSidebar
          interviewFeedbackIds={interviewFeedbackIds}
          round={round}
          onClose={onCloseInterviewFeedbacks}
        />
      )}
      {!!testStage && (
        <OnlineTestSummarySidebar
          isOpen
          onClose={() => {
            setTestStage(undefined)
          }}
          stage={testStage}
          roundId={round?.id}
          candidateId={candidate?.id}
        />
      )}
    </>
  )
}
