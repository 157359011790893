import React from 'react'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { FaqCategoryInterface } from '@src/interfaces/faq'
import { selectorKeys } from '@src/constants/api'
import { CategoryIcon } from '@src/components/HelpCenter/components/CategoryIcon'

export const faqCategoryNameColumn: ColumnInterface<FaqCategoryInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const faqCategoryDescriptionColumn: ColumnInterface<FaqCategoryInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const faqCategoryLinkToSupportColumn: ColumnInterface<FaqCategoryInterface> = {
  type: CellTypes.link,
  idPoint: 'link_to_support',
  dataPoint: 'link_to_support',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link to support',
}

export const faqCategoryIconColumn: ColumnInterface<FaqCategoryInterface> = {
  type: CellTypes.insert,
  idPoint: 'icon_id',
  dataPoint: 'icon_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Icon',
  insert: ({ data }) => {
    if (!data.icon_id) {
      return '-'
    }

    return <CategoryIcon name={data.icon_id} size={24} iconSize={18} showIconName />
  },
}
