import React, { useState, useEffect } from 'react'
import { Button, TabBar, Token } from '@revolut/ui-kit'
import { addMonths } from 'date-fns'

import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { checkIsExpired, Form, Header, checkIsExpiring } from './common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import Users from './Users'
import { PageActions } from '@src/components/Page/PageActions'
import { localDateToUtc } from '@src/utils/timezones'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'

const DEFAULT_CERTIFICATION_DATE_VALIDITY_IN_MONTHS = 3

type RecertifyTab = 'users' | 'details'

export const Recertify = () => {
  const params = useParams()

  const [recertifyTab, setRecertifyTab] = useState<RecertifyTab>('users')

  const { values, initialValues } = useLapeContext<GroupAccessManagementInterface>()

  const isExpired = checkIsExpired(
    initialValues.settings?.certification_expiration_date_time,
  )
  const isExpiring = checkIsExpiring(
    initialValues.settings?.certification_expiration_date_time,
  )

  useEffect(() => {
    if (isExpiring && values.settings?.certification_expiration_date_time) {
      values.settings.certification_expiration_date_time = localDateToUtc(
        addMonths(new Date(), DEFAULT_CERTIFICATION_DATE_VALIDITY_IN_MONTHS),
      )
    }
  }, [isExpiring])

  return (
    <>
      <Header name={values.name}>
        <ActionWidget
          title={
            isExpired
              ? 'The certification for this permission group has expired!'
              : 'The certification for this permission group is expiring soon!'
          }
          text={
            recertifyTab === 'users'
              ? 'Please review the list of users who are part of the group and decide who should still be part of the group and who should be removed. Please be very strict as this is essential for the security of your data.'
              : 'After the list of users is finalised, review the details of the group and adjust or proceed with the same details and set a new certification date in the past (ideally no longer than 3 months).'
          }
          maxWidth={Token.breakpoint.md}
          mb="s-16"
        />
        <TabBar
          value={recertifyTab}
          onChange={tab => setRecertifyTab(tab as RecertifyTab)}
          mb="s-16"
        >
          <TabBar.Item to="users">Users</TabBar.Item>
          <TabBar.Item to="details">Details</TabBar.Item>
        </TabBar>
      </Header>

      {recertifyTab === 'users' && (
        <>
          <Users />
          <PageActions>
            <Button onClick={() => setRecertifyTab('details')} elevated>
              Continue
            </Button>
          </PageActions>
        </>
      )}
      {recertifyTab === 'details' && (
        <>
          <Form />
          <PageActions>
            <NewSaveButtonWithPopup
              successText="Group was recertified"
              onAfterSubmit={() =>
                navigateReplace(
                  pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, params),
                )
              }
              useValidator
            />
          </PageActions>
        </>
      )}
    </>
  )
}
