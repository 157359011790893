import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { CulturalSkillInterface } from '@src/interfaces/skills'
import { getStatusColor } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import { Spacer, Text, TextButton, HStack, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { getCultureSkillAverageRatingGraph } from '@src/api/skills'
import Icon from '@components/Icon/Icon'
import {
  PerformanceRating,
  performanceRatingFromNumber,
  PerformanceRatingToGraphNumber,
} from '@src/interfaces/performance'
import { PerformanceRatingTitle } from '@src/constants/performance'

export const culturalSkillsNameColumn: ColumnInterface<CulturalSkillInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Value',
}

export const culturalSkillsAverageColumn: ColumnInterface<CulturalSkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'rating_avg',
  dataPoint: 'rating_avg',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.performance_ratings,
  title: 'Company average',
  insert: ({ data }) => {
    const formatValue = (value: string | number | Date): string =>
      PerformanceRatingTitle[
        (performanceRatingFromNumber.get(+value) as PerformanceRating) || 'none'
      ]

    return (
      <HStack maxWidth={110}>
        <Text>
          {PerformanceRatingTitle[String(data.rating_avg) as PerformanceRating]}
        </Text>
        <GraphIconChart
          id={data.id}
          fetchData={() => getCultureSkillAverageRatingGraph(data.id)}
          indexBy="progress_datetime_label"
          findMaxValue={() => PerformanceRatingToGraphNumber[PerformanceRating.expert]}
          formatValue={formatValue}
          margin={{ top: 10, right: 110, bottom: 75, left: 20 }}
          showButtons={false}
          axisLeft={{
            tickSize: 0,
            tickPadding: 8,
            tickRotation: 0,
            format: formatValue,
            tickValues: [1, 4, 7, 10, 13],
          }}
        >
          <Spacer />
          <Icon type="Graph" size="tiny" color="grey-20" />
        </GraphIconChart>
      </HStack>
    )
  },
}

export const culturalSkillsStatusColumn: ColumnInterface<CulturalSkillInterface> = {
  type: CellTypes.text,
  idPoint: 'status__id',
  dataPoint: 'status__name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.skillsets_status,
  title: 'Status',
  colors: data => {
    return getStatusColor(data.status.id)
  },
}

export const culturalSkillsValidUntilColumn: ColumnInterface<CulturalSkillInterface> = {
  type: CellTypes.date,
  idPoint: 'valid_until',
  dataPoint: 'valid_until',
  sortKey: null,
  filterKey: null,
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Valid until',
  colors: data => {
    if (data.status.id === Statuses.pending_reapproval) {
      return Token.color.red
    }
    return 'inherit'
  },
}

export const culturalSkillsActionColumn: ColumnInterface<CulturalSkillInterface> = {
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <TextButton
        onClick={e => {
          e.stopPropagation()
          navigateTo(pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id: data.id }))
        }}
      >
        View details
      </TextButton>
    )
  },
}
