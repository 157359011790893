import React, { useState } from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  DragAndDrop,
  Icon,
  Item,
  ItemSkeleton,
  MoreButton,
  Tooltip,
  VStack,
  useTooltip,
  Avatar,
  Token,
} from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'
import { move } from '@src/utils/move'

type HiringProcessRoundDetailsProps = {
  disableDelete?: boolean
  disableDeleteLabel?: string
  hiringProcessRound: HiringProcessInterface
  loading?: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDeleteRound?: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound?: (hiringProcessRound: HiringProcessInterface) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
  idx?: number
}

const HiringProcessRoundDetails = ({
  disableDelete,
  disableDeleteLabel,
  hiringProcessRound,
  loading = false,
  sortable,
  onDeleteRound,
  onEditRound,
  onShowRoundDetails,
  idx,
}: HiringProcessRoundDetailsProps) => {
  const [hover, setHover] = useState(false)
  const tooltip = useTooltip()

  const renderDescription = () => {
    switch (hiringProcessRound.stage_type.id) {
      case 'cv_screening':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.cvScreening.description"
            defaultMessage="Review candidates CV’s before moving them through the process"
          />
        )

      case 'screen_call':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.screenCall.description"
            defaultMessage="Face to face interview with candidate about his motivations and experience"
          />
        )

      case 'skills_round':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.skillsRound.description"
            defaultMessage="Test candidate skills and assess agains competency matrix"
          />
        )

      case 'final_round':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.final.description"
            defaultMessage="Test candidate values and behaviour"
          />
        )

      case 'online_test':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.onlineTest.description"
            defaultMessage="Test candidate skills using online testing platforms"
          />
        )

      case 'home_assessment':
        return (
          <FormattedMessage
            id="onboarding.interviews.stage.homeAssessment.description"
            defaultMessage="Test candidate skills using home assessment"
          />
        )

      default:
        // @TODO: add notReachable
        return null
    }
  }

  if (loading) {
    return <ItemSkeleton />
  }

  return (
    <Item
      use="label"
      onClick={e => {
        e.preventDefault()
        onShowRoundDetails?.(hiringProcessRound)
      }}
      ref={sortable?.setNodeRef}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Item.Avatar {...sortable?.attributes} {...sortable?.listeners}>
        {hover || idx === undefined ? (
          <Icon name="Drag" />
        ) : (
          <Avatar bg={Token.color.greyTone20} color={Token.color.white} size={24}>
            {idx + 1}
          </Avatar>
        )}
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          {hiringProcessRound.title || hiringProcessRound.company_hiring_stage?.name}
        </Item.Title>
        <Item.Description>{renderDescription()}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <MoreButton
            variant="icon"
            label="More actions"
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <MoreButton.Action
              useIcon="Pencil"
              onClick={e => {
                e.stopPropagation()
                onEditRound?.(hiringProcessRound)
              }}
            >
              Edit stage
            </MoreButton.Action>
            <MoreButton.Action
              aria-disabled={disableDelete}
              useIcon="Delete"
              onClick={e => {
                e.stopPropagation()
                if (!disableDelete) {
                  onDeleteRound?.(hiringProcessRound)
                }
              }}
              {...tooltip.getAnchorProps()}
            >
              Remove stage
              {disableDelete && disableDeleteLabel ? (
                <Tooltip placement="bottom" {...tooltip.getTargetProps()}>
                  {disableDeleteLabel}
                </Tooltip>
              ) : null}
            </MoreButton.Action>
          </MoreButton>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}

type HiringProcessDetailsProps = {
  hiringProcessRounds: HiringProcessInterface[]
  loading?: boolean
  onDeleteRound: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound: (hiringProcessRound: HiringProcessInterface) => void
  onOrderChange: (newHiringProcessRounds: HiringProcessInterface[]) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
}

export const HiringProcessDetails = ({
  hiringProcessRounds,
  loading = false,
  onDeleteRound,
  onEditRound,
  onOrderChange,
  onShowRoundDetails,
}: HiringProcessDetailsProps) => {
  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeHiringProcessIndex =
    activeId !== null
      ? hiringProcessRounds.findIndex(({ id }) => String(id) === activeId)
      : -1
  const activeHiringProcess =
    activeHiringProcessIndex >= 0
      ? hiringProcessRounds[activeHiringProcessIndex]
      : undefined
  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      onOrderChange(move(hiringProcessRounds, startIndex, endIndex))
    }
    setActiveId(null)
  }
  const disableCVScreeningDelete =
    hiringProcessRounds.filter(({ stage_type }) => stage_type.id === 'cv_screening')
      .length === 1
  return (
    <VStack gap="s-8">
      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveId(event.active.id)
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveId(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={hiringProcessRounds.map(({ id }) => String(id))}
        >
          {sortable => {
            const currentProcessIndex = hiringProcessRounds.findIndex(
              ({ id }) => String(id) === sortable.id,
            )
            const currentProcess = hiringProcessRounds[currentProcessIndex]
            return (
              <HiringProcessRoundDetails
                key={currentProcess.id}
                idx={currentProcessIndex}
                disableDelete={
                  currentProcess.stage_type.id === 'cv_screening' &&
                  disableCVScreeningDelete
                }
                disableDeleteLabel="Cannot delete cv screening stage"
                hiringProcessRound={currentProcess}
                loading={loading}
                sortable={sortable}
                onDeleteRound={onDeleteRound}
                onEditRound={onEditRound}
                onShowRoundDetails={onShowRoundDetails}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeHiringProcess && (
            <>
              <HiringProcessRoundDetails
                key={activeHiringProcess.id}
                disableDelete
                hiringProcessRound={activeHiringProcess}
              />
            </>
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
    </VStack>
  )
}
