import React, { useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { InputGroup, MoreBar } from '@revolut/ui-kit'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { LocationInterface } from '@src/interfaces/requisitions'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useGetHiringEnabledLocations } from '@src/api/requisitions'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { jobDescriptionFormRequest } from '@src/api/jobPosting'
import Form from '@src/features/Form/Form'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { CareersPositionPreviewSidebar } from '@components/CareersPositionPreviewSidebar/CareersPositionPreviewSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { AboutCompanyWidget } from '@src/pages/OnboardingChecklistV2/Jobs/AboutCompanyWidget'

const JobsEditComponent = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data: enabledLocationsData } = useGetHiringEnabledLocations()
  const locationOptions = (enabledLocationsData ?? []).map(location => ({
    label: location.name,
    value: location,
  }))
  const [openPreview, setOpenPreview] = useState(true)

  return (
    <>
      <PageBody>
        <MoreBar>
          <MoreBar.Action useIcon="Play" onClick={() => setOpenPreview(true)}>
            Preview
          </MoreBar.Action>
        </MoreBar>
        <NewStepperTitle title="General details" />
        <InputGroup>
          <LapeNewInput name="name" label="Job posting title" required />
          <LapeNewMultiSelect<LocationInterface>
            name="locations"
            placeholder="Locations"
            required
            options={locationOptions}
          />
        </InputGroup>

        <NewStepperTitle title="Job Description" />
        <InputGroup>
          <AboutCompanyWidget />
          <LapeHTMLEditor
            name="sections[0].content"
            placeholder="Enter the job description"
            height={350}
            required
            addMarginToParagraphs
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          afterSubmitUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.PREVIEW}
        />
      </PageActions>
      <CareersPositionPreviewSidebar
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        text={values.name}
        locations={values.locations || []}
        description={`<b>About the role</b><br />${values.sections?.[0].content || ''}`}
      />
    </>
  )
}

export const JobsEdit = () => {
  return (
    <Form api={jobDescriptionFormRequest}>
      <PageWrapper>
        <PageHeader
          title="Edit job posting"
          backUrl={ROUTES.ONBOARDING_CHECKLIST_V2.JOBS.PREVIEW}
        />
        <JobsEditComponent />
      </PageWrapper>
    </Form>
  )
}
