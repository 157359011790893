import React from 'react'
import { useParams } from 'react-router'
import { Text, Color, InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { labelsRequests } from '@src/api/labels'
import { ManualLabel } from '@src/interfaces/labels'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'

import { ArchiveButton } from './ArchiveButton'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const General = () => {
  const params = useParams<{ id?: any; employeeId?: any }>()

  const { values, initialValues } = useLapeContext<ManualLabel>()

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.LABELS, { id: params.employeeId })

  return (
    <PageWrapper>
      <PageHeader
        title={
          params.id ? (
            <>
              <Text>
                {values.manual_label?.name} · {values.employee?.full_name}{' '}
              </Text>
              <Text color={Color.GREY_20}>· {initialValues.status?.name}</Text>
            </>
          ) : (
            `Assign label`
          )
        }
        subtitle="Assign a label to the employee to trigger a particular process"
        backUrl={backUrl}
      />

      <PageBody>
        <ArchiveButton />
        <InputGroup>
          <LapeRadioSelectInput
            name="manual_label"
            label="Label"
            selector={selectorKeys.manual_employee_labels}
          />
          {values.id && (
            <LapeRadioSelectInput
              name="visibility"
              label="Visibility"
              selector={selectorKeys.employee_label_visibilities}
            />
          )}
          <LapeNewTextArea name="description" label="Description" rows={3} required />
          {params.id ? (
            <LapeDatePickerInput name="added_on" label="Added on" required />
          ) : null}
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Label saved successfully"
          afterSubmitUrl={backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={labelsRequests}>
    <General />
  </Form>
))
