import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { ImportRequisitionsDataInterface } from '@src/interfaces/importRequisitions'
import { EmployeeEmails } from '@src/interfaces/selectors'

export const fields: ImportFieldType<ImportRequisitionsDataInterface>[] = [
  {
    type: 'input',
    field: 'requisition_title',
    label: 'Title',
  },
  {
    type: 'radio-select',
    field: 'specialisation',
    selector: selectorKeys.specialisations,
    label: 'Role specialisation',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'seniority_min',
    selector: selectorKeys.seniority,
    label: 'Lowest seniority',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'seniority_max',
    selector: selectorKeys.seniority,
    label: 'Highest seniority',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'team',
    selector: selectorKeys.team,
    label: 'Team',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'multi-select',
    field: 'locations',
    selector: selectorKeys.location,
    label: 'Locations',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'line_manager',
    selector: selectorKeys.employee_emails,
    label: 'Line manager',
    valueKey: 'name',
    labelPath: 'full_name',
  },
  {
    type: 'input',
    field: 'headcount',
    label: 'Positions to fill',
  },
  {
    type: 'radio-select',
    field: 'recruiter',
    selector: selectorKeys.employee_emails,
    label: 'Recruiter',
    valueKey: 'name',
    labelPath: 'full_name',
  },
  {
    type: 'date',
    field: 'potential_start_date',
    label: 'Potential start date',
  },
  {
    type: 'input',
    field: 'description',
    label: 'Description',
  },
]

export const useOptions = () => {
  const { options: employeeOptions } = useFetchOptions<EmployeeEmails>(
    selectorKeys.employee_emails,
    true,
  )
  return { employeeOptions }
}
