import { PermissionTypes } from '@src/store/auth/types'

export const getPermissions = (permissions?: string[]) => {
  const canView = !!permissions?.includes(PermissionTypes.ViewEmployeecontract)
  const canEdit = !!permissions?.includes(PermissionTypes.ChangeEmployeeContract)
  const canEditBase = !!permissions?.includes(PermissionTypes.ChangeEmployeeContractBase)
  const canEditElevated = !!permissions?.includes(
    PermissionTypes.ChangeEmployeeContractElevated,
  )
  const canCreate = !!permissions?.includes(PermissionTypes.AddEmployeeContract)
  const canViewComments = !!permissions?.includes(
    PermissionTypes.ViewEmployeeContractComments,
  )
  const canAddComments = !!permissions?.includes(
    PermissionTypes.AddEmployeeContractComments,
  )
  const canEditComments = !!permissions?.includes(
    PermissionTypes.ChangeEmployeeContractComments,
  )
  const canApprove = !!permissions?.includes(PermissionTypes.ApproveEmployeeContract)
  const canReject = !!permissions?.includes(PermissionTypes.RejectEmployeeContract)
  const canCancel = !!permissions?.includes(PermissionTypes.CancelEmployeeContract)

  return {
    canView,
    canEdit,
    canEditBase,
    canEditElevated,
    canCreate,
    canViewComments,
    canAddComments,
    canEditComments,
    canApprove,
    canReject,
    canCancel,
  }
}
