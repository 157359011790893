import { ErrorWidget, Flex } from '@revolut/ui-kit'
import Lock from '@src/assets/3D/Lock@2x.png'
import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

const AnonymizedCandidate = () => {
  return (
    <Flex width="100vw" height="80vh" justifyContent="center" alignItems="center">
      <ErrorWidget>
        <ErrorWidget.Image src={Lock} />
        <ErrorWidget.Title>Candidate Anonymized</ErrorWidget.Title>
        <ErrorWidget.Description>
          This candidate have been anonymized in accordance with GDPR compliance rules
        </ErrorWidget.Description>
        <ErrorWidget.Action onClick={() => navigateTo(ROUTES.RECRUITMENT.CANDIDATES)}>
          View all candidates
        </ErrorWidget.Action>
      </ErrorWidget>
    </Flex>
  )
}

export default AnonymizedCandidate
