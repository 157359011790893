import React, { useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { keyPersonSuccessionPlanFormRequests } from '@src/api/keyPerson'
import { PageWrapper } from '@src/components/Page/Page'
import { PreviewSuccessionPlan } from './Preview'
import { EditSuccessionPlan } from './General'
import { AssignedResponsibilitiesSidebar } from './AssignedResponsibilitiesSidebar'

export const SuccessionPlan = () => {
  const params = useParams<{ subtab: 'changelog' | undefined }>()

  const [employeeId, setEmployeeId] = useState<number>()
  const [isResponsibilitiesSidebarShown, setIsResponsibilitiesSidebarShown] = useState(
    params.subtab !== 'changelog',
  )

  return (
    <Form api={keyPersonSuccessionPlanFormRequests}>
      <PageWrapper>
        <Switch>
          <Route path={ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.ANY}>
            <PreviewSuccessionPlan
              setEmployeeId={setEmployeeId}
              showResponsibilitiesSidebar={() => setIsResponsibilitiesSidebarShown(true)}
            />
          </Route>
          <Route path={ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.EDIT}>
            <EditSuccessionPlan
              setEmployeeId={setEmployeeId}
              showResponsibilitiesSidebar={() => setIsResponsibilitiesSidebarShown(true)}
            />
          </Route>
        </Switch>
        <AssignedResponsibilitiesSidebar
          employeeId={employeeId}
          isOpen={isResponsibilitiesSidebarShown}
          onClose={() => setIsResponsibilitiesSidebarShown(false)}
        />
      </PageWrapper>
    </Form>
  )
}
