import React from 'react'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import { Statuses } from '@src/interfaces'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type HiringScorecardsSubmitButtonProps = {
  noPopup?: boolean
  submitLabel?: string
  onAfterSubmit?: (result: InterviewScorecardTemplateInterface) => void
  onSubmit?: (
    result: InterviewScorecardTemplateInterface,
  ) => Promise<InterviewScorecardTemplateInterface> | void
}

const HiringScorecardsSubmitButton = ({
  noPopup,
  submitLabel,
  onAfterSubmit,
  onSubmit,
}: HiringScorecardsSubmitButtonProps) => {
  const { values, valid, submit } = useLapeContext<InterviewScorecardTemplateInterface>()
  const isExistingData = !!values?.id
  const isDraft = values.status === Statuses.draft

  const handleSubmit = () => {
    values.status = Statuses.completed
    return submit().then(result => {
      if (onSubmit) {
        return onSubmit(result)
      }
      navigateReplace(
        pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, { id: result.id }),
      )
      return result
    })
  }

  const saveLabel = isExistingData && !isDraft ? 'Save Changes' : 'Submit'
  return (
    <NewSaveButtonWithPopup
      disabled={!valid}
      hideWhenNoChanges={false}
      successText={
        isExistingData
          ? 'Scorecard successfully updated.'
          : 'Scorecard successfully created.'
      }
      onClick={handleSubmit}
      onAfterSubmit={onAfterSubmit}
      useValidator
      noPopup={noPopup}
    >
      {submitLabel ?? saveLabel}
    </NewSaveButtonWithPopup>
  )
}

export default connect(HiringScorecardsSubmitButton)
