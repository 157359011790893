import React from 'react'
import { Flex, Box, Text, Token } from '@revolut/ui-kit'
import { Props } from 'recharts/types/component/DefaultLegendContent'

type DataKeyType = { value: unknown; label: string }

interface ChartLegendProps {
  props: Props
  dataKeys: DataKeyType[]
  withProjection?: boolean
}

export const ProjectionLegendItem = () => {
  return (
    <Flex alignItems="center" gap="s-4">
      <svg width="25" height="10">
        <path
          d="M 0 5 L 25 5"
          fill="none"
          stroke={Token.color.greyTone50}
          strokeLinecap="round"
          strokeWidth="1"
          strokeDasharray="5,5"
        />
      </svg>
      <Text color={Token.color.greyTone50} fontSize="small" use="div">
        Projection
      </Text>
    </Flex>
  )
}

export const DefaultLegend = ({ props, dataKeys, withProjection }: ChartLegendProps) => {
  const { payload } = props

  return (
    <Flex columnGap="s-12" rowGap="s-2" justifyContent="end" flexWrap="wrap">
      {payload?.map(entry => {
        const key = dataKeys.find(
          dataKey => String(dataKey.value) === String(entry.dataKey),
        )
        if (!key) {
          return null
        }
        return (
          <Flex key={String(key.value)} alignItems="center" gap="s-4">
            <Box
              bg={entry.color}
              width="s-12"
              height="s-12"
              border={`1px solid ${Token.color.greyTone10}`}
              borderRadius={Token.radius.r2}
            />
            <Text fontSize="small" color={Token.color.greyTone50} use="div">
              {key.label}
            </Text>
          </Flex>
        )
      })}
      {withProjection && <ProjectionLegendItem />}
    </Flex>
  )
}
