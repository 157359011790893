import {
  ActionButton,
  ActionWidget,
  Icon,
  Link,
  List,
  Token,
  VStack,
  Text,
  Cell,
  Flex,
} from '@revolut/ui-kit'
import React, { useEffect } from 'react'
import SectionTitle from '../components/SectionTitle'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { RecommendedLabel } from '../components/Recommended'
import { useGetCompanyPreferences } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JiraPreferencesInterface } from '@src/interfaces/integrations'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'

export const Integration = () => {
  const { data: companyPreferences, isLoading } = useGetCompanyPreferences()
  const { values } = useLapeContext<JiraPreferencesInterface>()

  useEffect(() => {
    if (!values.connection_type) {
      values.connection_type = { id: 'jira', name: 'Jira' }
    }
  }, [])

  const jiraRecommended = !!companyPreferences?.tools.jira

  if (isLoading) {
    return <div />
  }

  return (
    <VStack>
      <SectionTitle
        title="Do you use any BI data tools that you want to integrate with roadmaps?"
        subtitle="If you have existing BI tools for project or task management, these can be integrated and used as roadmap items"
      />
      <LapeBooleanRadioSwitch
        name="enabled"
        variant="horizontal"
        noLabel={jiraRecommended ? undefined : <RecommendedLabel label="No" />}
        yesLabel={jiraRecommended ? <RecommendedLabel label="Yes" /> : undefined}
        yesDescription="I want to connect another tool as a data source for my roadmap items"
        noDescription="I would like to managing roadmaps solely from Revolut People"
      />
      {values.enabled ? (
        <VStack>
          <SectionTitle
            title="Setup connection"
            subtitle="Please enter the connection details"
          />
          <VStack space="s-16">
            <RadioSelectInput
              searchable={false}
              inputProps={{ label: 'Type' }}
              value={{ id: 'jira', name: 'Jira' }}
              selector={selectorKeys.roadmap_connections}
              onChange={() => {}}
            />
            <ActionWidget>
              <ActionWidget.Title>How to setup</ActionWidget.Title>
              <ActionWidget.Avatar>
                <Icon name="InfoOutline" color={Token.color.orange} />
              </ActionWidget.Avatar>
              <ActionWidget.Content>
                <List use="ol" variant="compact" color={Token.color.greyTone50}>
                  <List.Item useIcon="Dot">
                    Create a service account to allow the API to fetch Private projects
                    when needed, e.g. jira@mycompany.com (all public projects will be
                    accessible)
                  </List.Item>
                  <List.Item useIcon="Dot">
                    Log in to{' '}
                    <Link href="https://id.attlassian.com/manage-profile/security/api-tokens">
                      https://id.attlassian.com/manage-profile/security/api-tokens
                    </Link>
                  </List.Item>
                  <List.Item useIcon="Dot">
                    Click <b>Create API token</b>
                  </List.Item>

                  <List.Item useIcon="Dot">
                    Click <b>Copy</b>, then paste the token to <b>API key</b> field
                  </List.Item>
                  <List.Item useIcon="Dot">
                    Jira tickets will update every day at midnight after setup, so please
                    allow up to 24h to start viewing sub-tasks directly on the platform
                  </List.Item>
                </List>
              </ActionWidget.Content>
              <ActionWidget.Actions>
                <ActionButton
                  use="a"
                  useIcon="InfoOutline"
                  target="_blank"
                  href="https://docs.google.com/document/d/1K_IkLRY5c2PgVaZknKqK6O5ebx_rQKNFZU0x971XQ60/edit?usp=sharing"
                >
                  More information
                </ActionButton>
              </ActionWidget.Actions>
            </ActionWidget>
            <Cell>
              <VStack space="s-16" width="100%">
                <Flex justifyContent="space-between">
                  <Text variant="h6" color={Token.color.greyTone50}>
                    Jira settings
                  </Text>
                </Flex>
                <LapeNewInput
                  name="server"
                  label="Server"
                  message="URL to Jira, e.g. https://mysite.atlassian.net"
                  required
                />
                <LapeNewInput
                  name="username"
                  label="Username"
                  type="password"
                  message="Jira username of the user who created API key. It is recommended to use a service account."
                  required
                />
                <LapeNewInput
                  name="api_key"
                  label="API key"
                  type="password"
                  required
                  message={
                    <Text>
                      Log in to{' '}
                      <Link
                        href="https://id.atlassian.com/manage-profile/security/api-tokens"
                        target="_blank"
                      >
                        https://id.atlassian.com/manage-profile/security/api-tokens
                      </Link>
                    </Text>
                  }
                />
              </VStack>
            </Cell>
          </VStack>
        </VStack>
      ) : null}
    </VStack>
  )
}
