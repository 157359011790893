import { useState } from 'react'
import { pushNotification } from '@src/store/notifications/actions'
import {
  ERROR_DEFAULT_DURATION,
  SUCCESS_DEFAULT_DURATION,
} from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { createJiraLifecycleTicket } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useCreateEmployeeTicketAction = ({
  employee,
}: {
  employee: EmployeeInterface
}) => {
  const [pending, setPending] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [createdTicket, setCreatedTicket] = useState<string>()

  const onClick = async () => {
    try {
      setPending(true)
      pushNotification({
        value: 'The creation of the ticket has begun, it may take some time',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })

      const { data: responseData } = await createJiraLifecycleTicket(employee.id)

      if (responseData.status === 'error' && responseData.message) {
        pushNotification({
          value: responseData.message,
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      } else if (responseData.status === 'success' && responseData.result.ticket) {
        setCreatedTicket(responseData.result.ticket)
        setShowPopup(true)
      }
    } finally {
      setPending(false)
    }
  }

  return {
    onClick,
    pending,
    setPending,
    createdTicket,
    setCreatedTicket,
    showPopup,
    setShowPopup,
  }
}
