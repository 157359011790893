import { MoreBar } from '@revolut/ui-kit'
import { Retry } from '@revolut/icons'
import React, { useState } from 'react'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { syncCandidateEmails } from '@src/api/hiringProcess'

interface SyncEmailsProps {
  id: number | string
}

const SyncEmails = ({ id }: SyncEmailsProps) => {
  const [refreshPending, setRefreshPending] = useState(false)

  const onClick = async () => {
    try {
      setRefreshPending(true)
      await syncCandidateEmails(id)
      pushNotification({
        value:
          'Your request to sync emails was added to the queue and will be executed shortly.',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
    } finally {
      setRefreshPending(false)
    }
  }

  return (
    <MoreBar.Action pending={refreshPending} onClick={onClick} useIcon={Retry}>
      Sync emails
    </MoreBar.Action>
  )
}

export default SyncEmails
