import { useEffect, useMemo, useState } from 'react'
import {
  CalendarEventInterface,
  ScheduleInterviewContextInterface,
} from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import {
  useGetAvailableInterviewSlots,
  useGetCustomPotentialInterviewSlots,
  useGetEligibleInterviewers,
} from '@src/api/recruitment/interviewerScheduling'
import {
  getAvailabilityRangeFilter,
  getTimeZoneId,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { addWeeks, areIntervalsOverlapping, formatISO } from 'date-fns'
import { transformEmployeeToOption } from '@src/utils/employees'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

type InterviewerSlotsSingleSlotAvailability = {
  interviewer: EmployeeOptionInterface
  slot: 'interview' | 'free' | 'unavailable'
  date: CalendarEventInterface
}

type InterviewerSlotsWeekAvailability = {
  interviewer: EmployeeOptionInterface
  weekInterviewSlotsCount: number
  weekFreeSlotsCount: number
}

type InterviewerSlotsAvailability =
  | InterviewerSlotsSingleSlotAvailability
  | InterviewerSlotsWeekAvailability

type Props = { roundId?: number | string; enabled: boolean } & Pick<
  ScheduleInterviewContextInterface,
  | 'currentWeek'
  | 'calendarEvent'
  | 'timeZone'
  | 'duration'
  | 'durationUnit'
  | 'interviewStage'
>

export const useGetInterviewersCalendarSlotsAvailability = ({
  roundId,
  currentWeek,
  calendarEvent,
  timeZone,
  duration,
  durationUnit,
  interviewStage,
  enabled,
}: Props): InterviewerSlotsAvailability[] => {
  const [availability, setAvailability] = useState<InterviewerSlotsAvailability[]>([])

  const { data: eligibleReviewers } = useGetEligibleInterviewers(
    enabled ? interviewStage?.id : undefined,
    roundId ? +roundId : undefined,
    false,
    getAvailabilityRangeFilter(currentWeek.start, currentWeek.end),
  )

  const { data: freeSlots } = useGetCustomPotentialInterviewSlots(
    enabled ? interviewStage?.id : undefined,
    roundId ? +roundId : undefined,
    eligibleReviewers,
    false,
    duration !== undefined && durationUnit?.id === 'minutes' && duration < 10
      ? 10
      : duration,
    durationUnit?.id,
  )

  const timeZoneId = getTimeZoneId(timeZone)

  const dateRange = useMemo(() => {
    const timeZonedDate = utcToZonedTime(currentWeek.start, timeZoneId)
    return [
      formatISO(timeZonedDate, { representation: 'date' }),
      formatISO(addWeeks(timeZonedDate, 1), {
        representation: 'date',
      }),
    ]
  }, [timeZoneId, currentWeek.start])

  const { data: interviewSlotsData } = useGetAvailableInterviewSlots({
    stageId: enabled ? interviewStage?.id : undefined,
    roundId: roundId ? +roundId : undefined,
    dateRange,
    interviewers: eligibleReviewers?.map(transformEmployeeToOption) || [],
    duration,
    durationUnit: durationUnit?.id,
    isAdhoc: false,
  })
  const interviewSlots = useMemo(
    () => interviewSlotsData?.results || [],
    [interviewSlotsData],
  )

  useEffect(() => {
    const interviewersAvailability: InterviewerSlotsAvailability[] =
      eligibleReviewers?.map(interviewerItem => {
        if (calendarEvent) {
          const interviewSlot = interviewSlots.find(item => {
            return (
              item.employee.id === interviewerItem.id &&
              // we convert interview slots to the selected timezone, but the calendar event we use in the current timezone (as we don't change it after the timezone changed)
              areIntervalsOverlapping(
                {
                  start: timeZoneId
                    ? utcToZonedTime(item.event_start_datetime, timeZoneId)
                    : new Date(item.event_start_datetime),
                  end: timeZoneId
                    ? utcToZonedTime(item.event_end_datetime, timeZoneId)
                    : new Date(item.event_end_datetime),
                },
                {
                  start: new Date(calendarEvent.start),
                  end: new Date(calendarEvent.end),
                },
              )
            )
          })

          if (interviewSlot) {
            return {
              interviewer: interviewerItem,
              slot: 'interview',
              date: calendarEvent,
            }
          }

          const freeSlot = freeSlots?.find(
            item =>
              item.free_interviewers.some(
                freeInterviewer => freeInterviewer.id === interviewerItem.id,
              ) &&
              // we convert free slots to the selected timezone, but the calendar event we use in the current timezone (as we don't change it after the timezone changed)
              areIntervalsOverlapping(
                {
                  start: timeZoneId
                    ? utcToZonedTime(item.slot_start, timeZoneId)
                    : new Date(item.slot_start),
                  end: timeZoneId
                    ? utcToZonedTime(item.slot_end, timeZoneId)
                    : new Date(item.slot_end),
                },
                {
                  start: new Date(calendarEvent.start),
                  end: new Date(calendarEvent.end),
                },
              ),
          )

          if (freeSlot) {
            return {
              interviewer: interviewerItem,
              slot: 'free',
              date: calendarEvent,
            }
          }

          return {
            interviewer: interviewerItem,
            slot: 'unavailable',
            date: calendarEvent,
          }
        }

        // if no slot selected we show the availability for the current week
        const weekInterviewSlotsCount = interviewSlots.reduce(
          (count, item) => (item.employee.id === interviewerItem.id ? count + 1 : count),
          0,
        )

        return {
          interviewer: interviewerItem,
          weekInterviewSlotsCount,
          weekFreeSlotsCount: freeSlots?.length || 0,
        }
      }) || []

    setAvailability(interviewersAvailability)
  }, [calendarEvent, freeSlots, interviewSlots, eligibleReviewers])

  return availability
}
