import React, { useEffect } from 'react'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { getSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FormattedMessage } from 'react-intl'
import { useOpenNewTab } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

type RefKeys =
  | 'onboarding'
  | 'fill-scorecard-after-submit'
  | 'fill-skills-after-submit'
  | 'fill-interviewer-groups-after-submit'

type LocalStorageIdKey = typeof LOCAL_STORAGE[keyof typeof LOCAL_STORAGE]

type OnLoad = (value?: OptionInterface) => void

const useOnFocusLoadOptionFromStorage = (
  localStorageIdKey?: LocalStorageIdKey,
  selector?: selectorKeys,
  onLoad?: OnLoad,
) => {
  const enabled = localStorageIdKey && selector && onLoad
  const onFocus = async () => {
    if (enabled) {
      const id = workspaceLocalStorage.getItem(localStorageIdKey)
      if (id) {
        const response = await getSelectors(selector)
        const options = response.data.options
        const option = options?.find(item => item.id === +id)
        onLoad(option)
        workspaceLocalStorage.removeItem(localStorageIdKey)
      }
    }
  }
  useEffect(() => {
    if (enabled) {
      window.addEventListener('focus', onFocus)
    }
    return () => {
      if (enabled) {
        window.removeEventListener('focus', onFocus)
      }
    }
  }, [enabled])
}

const useKeepOptionIdInLocalStorage = (localStorageIdKey?: LocalStorageIdKey) => {
  const [, setCreatedId] = useLocalStorage<number | undefined>(
    localStorageIdKey || '',
    undefined,
  )
  const enabled = !!localStorageIdKey
  const keepOptionIdInLocalStorage = (id: number) => {
    if (enabled) {
      setCreatedId(id)
    }
  }
  return keepOptionIdInLocalStorage
}

const useOpenNewTabWithRef = (ref?: RefKeys, route?: string) => {
  const openNewTab = useOpenNewTab()
  return () => {
    if (route && ref) {
      openNewTab(
        pathToUrl(route, undefined, {
          ref,
        }),
      )
    }
  }
}

type CreateOptionInNewTabOptions = {
  ref?: RefKeys
  route?: string
  selector?: selectorKeys
  onLoad?: OnLoad
}

export const useCreateOptionInNewTab = (
  localStorageIdKey: LocalStorageIdKey,
  options: CreateOptionInNewTabOptions = {},
) => {
  const { ref, route, selector, onLoad } = options
  const openNewTab = useOpenNewTabWithRef(ref, route)
  const keepOptionIdInLocalStorage = useKeepOptionIdInLocalStorage(localStorageIdKey)
  useOnFocusLoadOptionFromStorage(localStorageIdKey, selector, onLoad)
  const statusPopup = useStatusPopup()
  const showStatusPopup = (title: React.ReactNode, description: React.ReactNode) => {
    statusPopup.show(
      <StatusPopup variant="success-result" preventUserClose>
        <StatusPopup.Title>{title}</StatusPopup.Title>
        <StatusPopup.Description>{description}</StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            elevated
            onClick={() => {
              window.close()
            }}
          >
            <FormattedMessage
              id="general.closeAndGoBack"
              defaultMessage="Close and go back"
            />
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  return {
    openNewTab,
    keepOptionIdInLocalStorage,
    showStatusPopup,
  }
}
