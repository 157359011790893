import React, { useState } from 'react'

import { TimeOffRequestsUploadFile } from '@src/pages/Forms/ImportData/TimeOff/TimeOffRequests/TimeOffRequestsUploadFile'
import { BooleanRadioSwitch } from '../../components/RadioSwitch'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import OnboardingActions from '../../components/OnboardingActions'
import { timeOffConfig } from '../../common/constants'
import { TimeOffBalancesUploadFile } from '@src/pages/Forms/ImportData/TimeOff/TimeOffBalances/TimeOffBalancesUploadFile'

export const ImportBalanceAdjustment = () => {
  const [isSimpleBalanceAdjustmentMode, setIsSimpleBalanceAdjustmentMode] = useState(true)

  return (
    <>
      <PageBody>
        <BooleanRadioSwitch
          variant="horizontal"
          value={isSimpleBalanceAdjustmentMode}
          onChange={setIsSimpleBalanceAdjustmentMode}
          yesLabel="Simple balance adjustment"
          yesDescription="Update the current employee balance by removing and adding days"
          noLabel="Import time off requests"
          noDescription="Accurately import time off data from the past, present, and future."
        />

        {isSimpleBalanceAdjustmentMode ? (
          <TimeOffBalancesUploadFile
            afterUploadUrl={
              ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.BALANCES
            }
          />
        ) : (
          <TimeOffRequestsUploadFile
            afterUploadUrl={
              ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.REQUESTS
            }
          />
        )}
      </PageBody>

      <OnboardingActions
        config={timeOffConfig}
        currentStep="Balance adjustments"
        isLastStep
        disableBack={false}
        pendingBack={false}
        disableNext={false}
        pendingNext={false}
        nextRoute=""
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICY_ASSIGNMENTS}
        updateSteps
        isForm={false}
        nextButtonLabel="Skip"
      />
    </>
  )
}
