import React, { useEffect } from 'react'

const ClosePopup = () => {
  useEffect(() => {
    window.close()
  }, [])
  return <div />
}

export default ClosePopup
