import React from 'react'
import { CareersPosition } from '@src/interfaces/careers'
import { useCareersSettings } from '@src/api/recruitment/careers'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { env, Environments } from '@src/constants/api'

type Props = {
  position: CareersPosition
}

const CareersPositionMetadata = ({ position }: Props) => {
  const isCommercial = useIsCommercial()
  const { data: settings } = useCareersSettings()
  if (env !== Environments.production && env !== Environments.productionCommercial) {
    return null
  }

  if (isCommercial === undefined) {
    return null
  }

  return (
    <script
      type="application/ld+json"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'JobPosting',
          jobBenefits: isCommercial
            ? undefined
            : 'Medical insurance for you and your close ones, Flexibility to work from home, the office or abroad, A free Revolut Metal subscription loaded with perks',
          datePosted: position.creation_date_time
            ? new Date(position.creation_date_time).toISOString()
            : undefined,
          description: position.description,
          employmentType: 'Full-time',
          jobLocation:
            position.locations?.map(location => ({
              '@type': 'Place',
              address: {
                '@type': 'PostalAddress',
                addressCountry: location.country,
              },
            })) || [],
          hiringOrganization: {
            '@type': 'Organization',
            name: settings?.company_name,
            sameAs: settings?.company_website,
            logo: settings?.company_logo?.url,
          },
          title: position.text,
        }),
      }}
    />
  )
}

export default CareersPositionMetadata
