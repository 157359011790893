import React, { useState } from 'react'
import {
  BottomSheet,
  Button,
  Header,
  StatusPopup,
  VStack,
  useStatusPopup,
  Text,
  Token,
} from '@revolut/ui-kit'

import { MergeTeams } from '@src/pages/Forms/UpdateOrganisationStructure/MergeTeams'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput, {
  createNewKey,
} from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { bulkDeleteTeams, bulkEditTeams } from '@src/api/teams'
import { mergeTeams } from '@src/api/updateOrganisationStructure'

/** Merge teams */

interface MergeTeamsPopupProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  unitsToMerge?: number[]
}

export const MergeTeamsPopup = ({
  open,
  onClose,
  onSuccess,
  unitsToMerge,
}: MergeTeamsPopupProps) => {
  if (!unitsToMerge) {
    return null
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Merge teams</Header.Title>
      </Header>

      <MergeTeams
        unitsToMerge={unitsToMerge.map(id => ({ id }))}
        onSuccess={() => {
          onSuccess()
        }}
        showMissionField={false}
        setCurrentUserAsOwner
      />
    </BottomSheet>
  )
}

/** Bulk update */

type BulkUpdateEntity = 'department' | 'owner'

const getTitleFromEntity = (entity: BulkUpdateEntity) => {
  switch (entity) {
    case 'department':
      return 'Change department'
    case 'owner':
      return 'Change owner'
    default:
      return ''
  }
}

const getLabelFromEntity = (entity: BulkUpdateEntity) => {
  switch (entity) {
    case 'department':
      return 'Department'
    case 'owner':
      return 'Owner'
    default:
      return ''
  }
}

const getFieldFromEntity = (entity: BulkUpdateEntity) => {
  switch (entity) {
    case 'department':
      return 'department'
    case 'owner':
      return 'team_owner'
    default:
      return null
  }
}

const getSelectorFromEntity = (entity: BulkUpdateEntity) => {
  switch (entity) {
    case 'department':
      return selectorKeys.department
    case 'owner':
      return selectorKeys.employee
    default:
      return null
  }
}

interface BulkUpdateTeamPopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
  entity?: 'department' | 'owner'
  selectedTeams?: number[]
  onCreateNew?: (onChangeAction: (entity: IdAndName) => void) => void
}

export const BulkUpdateTeamPopup = ({
  open,
  onSuccess,
  onClose,
  entity,
  selectedTeams,
  onCreateNew,
}: BulkUpdateTeamPopupProps) => {
  const [pending, setPending] = useState(false)
  const [value, setValue] = useState<IdAndName | null>(null)

  const statusPopup = useStatusPopup()

  if (!entity) {
    return null
  }

  const selector = getSelectorFromEntity(entity)

  const onSubmit = (newEntity?: IdAndName) => {
    const field = getFieldFromEntity(entity)

    const valueToSubmit = newEntity?.id || value?.id

    if (!field || !valueToSubmit || !selectedTeams) {
      return
    }

    if (newEntity) {
      setValue(newEntity)
    }

    setPending(true)

    bulkEditTeams(selectedTeams, { [field]: valueToSubmit })
      .then(() => {
        setPending(false)
        setValue(null)
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>{getTitleFromEntity(entity)}</Header.Title>
      </Header>

      <RadioSelectInput
        label={getLabelFromEntity(entity)}
        selector={selector}
        value={value}
        onChange={val => {
          if (typeof val?.id === 'string' && val.id === createNewKey) {
            onCreateNew?.(newEntity => onSubmit(newEntity))
          } else {
            setValue(val)
          }
        }}
        showCreateNewButton={entity === 'department'}
        allowSetValueToCurrentOption={entity === 'department'}
      />

      <BottomSheet.Actions>
        <Button onClick={() => onSubmit()} pending={pending} disabled={!value} elevated>
          Update
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

/** Delete teams */

const DEPENDENCIES_ERROR = `Item cannot be deleted because it's referenced by other entities`

interface DeleteTeamsConfirmationPopupProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  teamsToDelete?: number[]
}

export const DeleteTeamsConfirmationPopup = ({
  open,
  onClose,
  onSuccess,
  teamsToDelete,
}: DeleteTeamsConfirmationPopupProps) => {
  const [mergePopupOpen, setMergePopupOpen] = useState(false)
  const [targetTeam, setTargetTeam] = useState<IdAndName | null>(null)
  const [pending, setPending] = useState(false)

  const statusPopup = useStatusPopup()

  const resetPopupState = () => {
    setPending(false)
    setTargetTeam(null)
    setMergePopupOpen(false)
    onClose()
  }

  const onTransfer = () => {
    if (!teamsToDelete || !targetTeam?.id) {
      return
    }

    setPending(true)

    mergeTeams({
      teams: [{ id: targetTeam.id }, ...teamsToDelete.map(id => ({ id }))],
    })
      .then(() => {
        resetPopupState()
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to merge teams</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  const onDelete = () => {
    if (!teamsToDelete) {
      return
    }

    setPending(true)

    bulkDeleteTeams(teamsToDelete)
      .then(() => {
        resetPopupState()
        onSuccess()
      })
      .catch(error => {
        setPending(false)

        if (
          error.response?.status === 400 &&
          error.response.data?.detail?.includes(DEPENDENCIES_ERROR)
        ) {
          setMergePopupOpen(true)
        } else {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to delete teams</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        }
      })
  }

  return (
    <BottomSheet open={open} onClose={resetPopupState} preventUserClose={pending}>
      <Header>
        <Header.Title>
          {mergePopupOpen
            ? 'Transfer team members'
            : teamsToDelete && teamsToDelete.length > 1
            ? 'Are you sure to delete these teams?'
            : 'Are you sure to delete this team?'}
        </Header.Title>
      </Header>
      {mergePopupOpen ? (
        <>
          <VStack space="s-8">
            <Text color={Token.color.greyTone50}>Choose a team</Text>
            <RadioSelectInput
              label="Team"
              selector={selectorKeys.team}
              value={targetTeam}
              onChange={option => {
                setTargetTeam(option)
              }}
              filter={team => !teamsToDelete?.includes(team.id)}
            />
          </VStack>

          <BottomSheet.Actions horizontal>
            <Button
              onClick={onTransfer}
              pending={pending}
              disabled={!targetTeam}
              elevated
            >
              Continue
            </Button>
          </BottomSheet.Actions>
        </>
      ) : (
        <>
          <Text color={Token.color.greyTone50}>
            This action can not be undone and the team members will need to be assigned to
            a new team.
          </Text>
          <BottomSheet.Actions horizontal>
            <Button onClick={resetPopupState} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => onDelete()} elevated pending={pending}>
              Yes, delete
            </Button>
          </BottomSheet.Actions>
        </>
      )}
    </BottomSheet>
  )
}
