import React from 'react'
import {
  BottomSheet,
  Box,
  Button,
  Color,
  Header,
  Popup,
  Text,
  VStack,
  Link as UIKitLink,
} from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'

import { onCopyLinkToClipboard } from '../../common'

type Props = {
  open: boolean
  onClose: () => void
  link: string | undefined
}
export const GetActivationLinkPopup = ({ open, onClose, link }: Props) => {
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Onboarding magic link</Header.Title>
      </Header>
      <VStack gap="s-8">
        <Text>
          You can share this link with the new joiner in case they are having trouble
          logging in the onboarding portal.
        </Text>
        <Text color={Color.RED}>
          Please note that this link is single use and will expire after 24 hours.
        </Text>
        <Box style={{ overflowWrap: 'break-word' }}>
          <UIKitLink href={link} target="_blank">
            {link}
          </UIKitLink>
        </Box>
      </VStack>
      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Close
        </Button>
        <Button onClick={() => onCopyLinkToClipboard(link)} useIcon={Copy}>
          Copy link
        </Button>
      </Popup.Actions>
    </BottomSheet>
  )
}
