import React, { useState } from 'react'
import {
  Action,
  Avatar,
  AvatarSkeleton,
  Box,
  Radio,
  Group,
  Item,
  Subheader,
} from '@revolut/ui-kit'
import { Profile } from '@revolut/icons'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { useGetUserAvatar } from '@src/api/settings'
import {
  ThemeSetting,
  useAppTheme,
} from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { useGetCanEnableTestCycleSandbox } from '@src/utils/reviewCycles'
import { Sandbox } from '@src/features/Sandbox/Sandbox'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { UserAvatarUpload } from '@src/features/UserAvatarUpload/UserAvatarUpload'

export const UserPreferences = () => {
  const [openUpload, setOpenUpload] = useState(false)
  const canEnableSandbox = useGetCanEnableTestCycleSandbox()

  const { themeSettingValue, setTheme } = useAppTheme()

  const { data: userAvatar, refetch } = useGetUserAvatar()

  const { sendAnalyticsEvent } = useAnalytics()

  const closePopup = () => setOpenUpload(false)

  const onSetTheme = (mode: ThemeSetting) => {
    sendAnalyticsEvent(AnalyticsEvents.change_app_theme, { mode })
    setTheme(mode)
  }

  return (
    <>
      <PageWrapper>
        <PageHeader title="User preferences" backUrl={ROUTES.SETTINGS.ALL} />

        <PageBody>
          <Subheader variant="nested">
            <Subheader.Title>Avatar</Subheader.Title>
          </Subheader>

          <Item>
            <Item.Avatar>
              {userAvatar ? (
                userAvatar?.url ? (
                  <Avatar image={userAvatar?.url} />
                ) : (
                  <Avatar bg="blue-opaque-50" useIcon={<Profile color="background" />} />
                )
              ) : (
                <AvatarSkeleton />
              )}
            </Item.Avatar>

            <Item.Content>
              <Item.Title>Profile picture</Item.Title>
              <Item.Description>
                You can choose to upload a photo for your profile or opt out
              </Item.Description>
            </Item.Content>

            <Item.Side>
              <Action onClick={() => setOpenUpload(true)}>Update photo</Action>
            </Item.Side>
          </Item>

          <Box mt="s-8">
            <Subheader variant="nested">
              <Subheader.Title>Theme</Subheader.Title>
            </Subheader>

            <Group>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('light')}
                    checked={themeSettingValue === 'light'}
                    aria-labelledby="theme-light"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-light">Light</Item.Title>
                </Item.Content>
              </Item>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('dark')}
                    checked={themeSettingValue === 'dark'}
                    aria-labelledby="theme-dark"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-dark">Dark</Item.Title>
                </Item.Content>
              </Item>
              <Item use="label">
                <Item.Prefix>
                  <Radio
                    onClick={() => onSetTheme('auto')}
                    checked={themeSettingValue === 'auto'}
                    aria-labelledby="theme-system"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title id="theme-system">System</Item.Title>
                </Item.Content>
              </Item>
            </Group>
          </Box>

          {canEnableSandbox && (
            <Box mt="s-8">
              <Subheader variant="nested">
                <Subheader.Title>Demo Mode</Subheader.Title>
              </Subheader>
              <Sandbox />
            </Box>
          )}
        </PageBody>
      </PageWrapper>
      <UserAvatarUpload open={openUpload} onClose={closePopup} onSuccess={refetch} />
    </>
  )
}
