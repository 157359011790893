import { Token } from '@revolut/ui-kit'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

import {
  KeyPersonResponsibilityAssignmentStatus,
  KeyPersonResponsibilityStatus,
  KeyPersonSuccessionPlanStatus,
  KeyPersonStatus,
} from '@src/interfaces/keyPerson'

export const getStatusColor = (status?: KeyPersonStatus) => {
  switch (status) {
    case KeyPersonStatus.pending_completion:
    case KeyPersonStatus.pending_approval:
      return Token.color.orange
    case KeyPersonStatus.approved:
    case KeyPersonStatus.active:
      return Token.color.green
    case KeyPersonStatus.cancelled:
    case KeyPersonStatus.rejected:
      return Token.color.red
    case KeyPersonStatus.archived:
    case KeyPersonStatus.expired:
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const getResponsibilityStatusColor = (status?: KeyPersonResponsibilityStatus) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const getResponsibilityAssignmentStatusColor = (
  status?: KeyPersonResponsibilityAssignmentStatus,
) => {
  switch (status) {
    case 'active':
      return Token.color.green
    case 'pending_start':
      return Token.color.orange
    case 'expired':
    case 'unassigned':
      return Token.color.red
    case 'archived':
      return Token.color.greyTone50
    default:
      return Token.color.foreground
  }
}

export const getSuccessionPlanStatusColor = (status?: KeyPersonSuccessionPlanStatus) => {
  switch (status) {
    case 'approved':
    case 'complete':
      return Token.color.green
    case 'pending_completion':
    case 'pending_approval':
      return Token.color.orange
    case 'expired':
    case 'rejected':
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const getSuccessionPlanStatusVariant = (
  status?: KeyPersonSuccessionPlanStatus,
): ColorTagVariant => {
  switch (status) {
    case 'approved':
    case 'complete':
      return 'success'
    case 'pending_completion':
    case 'pending_approval':
      return 'warning'
    case 'expired':
    case 'rejected':
      return 'danger'
    default:
      return 'default'
  }
}
