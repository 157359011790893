import React from 'react'
import { Avatar, Box, Item, Token, VStack } from '@revolut/ui-kit'
import {
  OnboardingChecklistSectionConfig,
  onboardingCheckpointsOrder,
  useFilterConfigs,
} from '@src/pages/OnboardingChecklist/common/constants'
import { pathToUrl } from '@src/utils/router'
import { getIconProps } from '@src/pages/Hub/utils'
import {
  OnboardingCheckpointInterface,
  OnboardingCheckpointStateId,
} from '@src/interfaces/onboardingChecklist'
import { Check, LockClosed, StatusClockArrows } from '@revolut/icons'
import { useGetOnboardingCheckpoints } from '@src/api/onboardingChecklist'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { navigateTo } from '@src/actions/RouterActions'

type OnboardingChecklistStateBadgeProps = {
  state?: OnboardingCheckpointStateId
}

export const OnboardingChecklistStateBadge = ({
  state,
}: OnboardingChecklistStateBadgeProps) => {
  switch (state) {
    case 'completed':
      return <Avatar.Badge useIcon={Check} bg={Token.color.green} />
    case 'in_progress':
      return <Avatar.Badge useIcon={StatusClockArrows} bg={Token.color.orange} />
    default:
      return null
  }
}

type OnboardingChecklistItemProps = {
  config: OnboardingChecklistSectionConfig
  checkpoint?: OnboardingCheckpointInterface
}

const OnboardingChecklistItem = ({
  config,
  checkpoint,
}: OnboardingChecklistItemProps) => {
  return (
    <Item
      variant="disclosure"
      use="button"
      onClick={() => navigateTo(pathToUrl(config.path))}
      disabled={checkpoint?.state?.id === 'locked'}
      aria-label={config.label}
    >
      <Item.Avatar>
        {config.customIcon ? config.customIcon : null}
        {config.icon ? <Avatar useIcon={config.icon} /> : null}
        {(config.image && config.bg) || config.customImage ? (
          <Avatar
            variant="brand"
            {...(config.image && config.bg && getIconProps(config.image, config.bg, 40))}
            size={40}
            {...config.customImage}
          >
            <OnboardingChecklistStateBadge state={checkpoint?.state?.id} />
          </Avatar>
        ) : null}
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{config.title}</Item.Title>
        {config.description ? (
          <Item.Description>{config.description}</Item.Description>
        ) : null}
      </Item.Content>
      <Item.Side>
        {checkpoint?.state?.id === 'locked' ? (
          <LockClosed size={20} color={Token.color.greyTone20} />
        ) : null}
      </Item.Side>
    </Item>
  )
}

type OnboardingChecklistSectionProps = {
  onboardingConfig: OnboardingChecklistSectionConfig[]
  title: string
  subtitle?: string
}

const OnboardingChecklistSection = ({
  onboardingConfig,
  title,
  subtitle,
}: OnboardingChecklistSectionProps) => {
  const { checkpoints } = useGetOnboardingCheckpoints()
  const filterConfigs = useFilterConfigs()
  const sections = filterConfigs(onboardingConfig)

  if (!sections.length) {
    return null
  }

  const sortedSections = sections.sort(
    (a, b) =>
      onboardingCheckpointsOrder.findIndex(c => c.category === a.category) -
      onboardingCheckpointsOrder.findIndex(c => c.category === b.category),
  )

  return (
    <Box>
      <SectionTitle title={title} subtitle={subtitle} />
      <VStack gap="s-16">
        {sortedSections.map(config => {
          const checkpoint = checkpoints?.find(d => d.category === config.category)
          return (
            <OnboardingChecklistItem
              config={config}
              key={config.path}
              checkpoint={checkpoint}
            />
          )
        })}
      </VStack>
    </Box>
  )
}

export default OnboardingChecklistSection
