import { Box, chain, Color, Text, VStack } from '@revolut/ui-kit'
import React, { useEffect, useMemo, useState } from 'react'
import countBy from 'lodash/countBy'
import { CAREERS_TEAM_OTHER, CareersPosition } from '@src/interfaces/careers'
import { useQuery } from '@src/utils/queryParamsHooks'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { normalizeCareersGroups, useCareersSettings } from '@src/api/recruitment/careers'

interface Props {
  positions: CareersPosition[]
  onChange: (value?: string) => void
  hasOtherTeam: boolean
}

const CareersTeamFilter = ({ positions, onChange, hasOtherTeam }: Props) => {
  const { data: settings } = useCareersSettings()
  const { query, changeQueryParam } = useQuery()
  const [value, setValue] = useState<string>()

  const options = useMemo(() => {
    const counts = countBy(positions, position => position.team)
    const list = Object.keys(normalizeCareersGroups(settings?.function_groups)).map(
      team => ({
        key: team,
        label: chain(team, counts[team] || 0),
        value: {
          name: team,
          id: team,
        },
      }),
    )

    if (hasOtherTeam) {
      list.push({
        key: CAREERS_TEAM_OTHER,
        label: chain(CAREERS_TEAM_OTHER, counts[CAREERS_TEAM_OTHER] || 0),
        value: {
          name: CAREERS_TEAM_OTHER,
          id: CAREERS_TEAM_OTHER,
        },
      })
    }

    return list
  }, [positions, hasOtherTeam])

  useEffect(() => {
    const team = query.team
    if (!team || (team && typeof team === 'string')) {
      setValue(team)
      onChange(team)
    }
  }, [onChange, query.team])

  const clickHandler = (team?: string) => {
    changeQueryParam('team', team || '')
  }

  return (
    <>
      <Box hide="*-lg">
        <Text use="div" pb="s-16" variant="small" color="grey-tone-50" fontWeight={600}>
          Filter by teams
        </Text>
        <VStack gap="s-32">
          <Text
            onClick={() => clickHandler()}
            selected={value === undefined}
            color={value === undefined ? Color.BLUE : Color.GREY_TONE_50}
            variant="primary"
            use="button"
            type="button"
            display="block"
            textAlign="left"
          >
            {chain('All teams', positions.length)}
          </Text>
          {options.map(option => (
            <Text
              key={option.key}
              onClick={() => clickHandler(option.value?.id)}
              selected={value === option.value?.id}
              color={value === option.value?.id ? Color.BLUE : Color.GREY_TONE_50}
              variant="primary"
              use="button"
              type="button"
              display="block"
              textAlign="left"
            >
              {option.label}
            </Text>
          ))}
        </VStack>
      </Box>
      <Box hide="lg-*">
        <RadioSelectInput
          options={options}
          value={value ? { id: value, name: value } : null}
          onChange={val => clickHandler(val?.id || undefined)}
          indicatorStyle="highlight"
          label="Team"
        />
      </Box>
    </>
  )
}

export default CareersTeamFilter
