import React, { useEffect, useState } from 'react'
import { Button, HStack, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { getBackUrl, goBack, navigateReplace } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Statuses } from '@src/interfaces'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { activateEngagementSurvey } from '@src/api/engagement'
import { completeOnboardingCheckpoint } from '@src/api/onboardingChecklistV2'
import { engagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { StartSurveyForm } from '@src/apps/People/Engagement/Survey/Preview/StartSurveyForm'
import { renderFinishPopup } from './renderFinishPopup'
import { useDeactivateSurvey } from '../hooks/useDeactivateSurvey'
import { useLocation } from 'react-router-dom'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { completeOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'

const nextStepMap: Record<EngagementEditStep, EngagementEditStep | 'finish'> = {
  basic: 'questions',
  questions: 'audience',
  audience: 'notifications',
  notifications: 'review',
  review: 'finish',
}

export const FormActions = ({ currentStep }: { currentStep: EngagementEditStep }) => {
  const [isPending, setIsPending] = useState(false)
  const isOnboarding = isOnboardingPath()
  const [adHocPopupVisible, setAdHocPopupVisibile] = useState(false)
  const statusPopup = useStatusPopup()
  const featureFlags = useSelector(selectFeatureFlags)
  const isOnboardingV2 = featureFlags.includes(FeatureFlags.OnboardingFlowV2)
  const { values, dirty } = useLapeContext<EngagementSurveyInterface>()
  const { deactivateSurvey } = useDeactivateSurvey({ surveyId: values.id })
  const { state } = useLocation<{ onSaveGoBack?: boolean }>()

  const isNew = !values.id
  const isDraft = values.status?.id === Statuses.inactive
  const isFromTemplate = values.status?.id === Statuses.hidden_draft

  useEffect(() => {
    if (isNew) {
      if (!values.survey_questions) {
        values.survey_questions = []
      }
      if (!values.audiences) {
        values.audiences = []
      }
    }
  }, [])

  const handleLaunchSurvey = async () => {
    if (isNew) {
      return
    }
    setIsPending(true)
    try {
      if (values.status.id !== Statuses.active) {
        await activateEngagementSurvey(values?.id)
      }
      if (isFromTemplate && isOnboarding) {
        if (isOnboardingV2) {
          await completeOnboardingCheckpoint(engagementConfig.category)
        } else {
          await completeOnboardingCheckpointCategory('engagement')
        }
        workspaceLocalStorage.removeItem(
          LOCAL_STORAGE.ONBOARDING_ENGAGEMENT_CURRENT_DRAFT_ID,
        )
      }
      if (
        values.sent_every.id === 'manual' &&
        values.round_status?.id !== Statuses.running &&
        values.round_status?.id !== Statuses.planned
      ) {
        setAdHocPopupVisibile(true)
      } else {
        statusPopup.show(
          renderFinishPopup({
            onClose: () => {
              statusPopup.hide()
              navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
            },
            isOnboarding,
          }),
        )
      }
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const handleSaveButtonClick = async ({
    variant,
    id,
  }: {
    variant: 'draft' | 'next'
    id?: string | number
  }) => {
    if (nextStepMap[currentStep] === 'finish') {
      handleLaunchSurvey()
    } else if (state.onSaveGoBack) {
      goBack(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD, undefined)
    } else {
      navigateReplace(
        variant === 'draft'
          ? ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD
          : pathToUrl(
              isOnboarding
                ? ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE
                : ROUTES.FORMS.ENGAGEMENT.EDIT,
              {
                id,
                step: nextStepMap[currentStep],
              },
            ),
      )
    }
  }

  const getNextButton = () => {
    if (nextStepMap[currentStep] === 'finish') {
      return 'Finish'
    }

    if (isNew || isFromTemplate) {
      if (state.onSaveGoBack) {
        return 'Save'
      }
      return 'Next'
    }

    if (dirty) {
      return 'Save changes'
    }

    return 'Next'
  }

  return (
    <>
      <HStack gap="s-16">
        {isFromTemplate && (
          <Button
            onClick={e => {
              e.preventDefault()
              navigateReplace(
                getBackUrl(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD).pathname,
              )
            }}
            variant="secondary"
          >
            Cancel
          </Button>
        )}
        {(isDraft || isNew) && (
          <NewSaveButtonWithPopup
            hideWhenNoChanges={false}
            pending={isPending}
            useValidator
            noPopup
            variant="secondary"
            onAfterSubmit={results =>
              handleSaveButtonClick({ variant: 'draft', id: results.id })
            }
          >
            Save as draft
          </NewSaveButtonWithPopup>
        )}
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          pending={isPending}
          useValidator
          noPopup
          onAfterSubmit={results =>
            handleSaveButtonClick({ variant: 'next', id: results.id })
          }
        >
          {getNextButton()}
        </NewSaveButtonWithPopup>
      </HStack>
      <StartSurveyForm
        // we activate the survey just before that popup would be displayed
        surveyStatus={{ id: Statuses.active, name: Statuses.active }}
        surveyId={values.id}
        cancelButtonText="Set up later"
        isOpen={adHocPopupVisible}
        onReject={async () => {
          await deactivateSurvey()
          setAdHocPopupVisibile(false)
          statusPopup.show(
            renderFinishPopup({
              onClose: () => {
                statusPopup.hide()
                navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
              },
              isOnboarding,
            }),
          )
        }}
        onClose={() => {
          setAdHocPopupVisibile(false)
          statusPopup.show(
            renderFinishPopup({
              onClose: () => {
                statusPopup.hide()
                navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
              },
              isOnboarding,
            }),
          )
        }}
      />
    </>
  )
}
