import { useParams, useRouteMatch } from 'react-router-dom'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { useActiveProfileSidebar } from '@src/pages/EmployeeProfile/Layout/Summary/common'

export const useEmployeeDetailsOnAfterSubmit = (afterSubmit?: () => void) => {
  const params = useParams<{ id?: string }>()
  const isWorkDetailsRoute = useRouteMatch([
    ROUTES.FORMS.EMPLOYEE.GENERAL.NAME,
    ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION,
    ROUTES.FORMS.EMPLOYEE.GENERAL.ORGANISATION,
    ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES,
  ])
  const isPersonalDetailsRoute = useRouteMatch([
    ROUTES.FORMS.EMPLOYEE.GENERAL.BIO,
    ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO,
    ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION,
    ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS,
    ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY,
    ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT,
  ])
  const isDiversityRoute = useRouteMatch(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY)
  const { sidebar } = useActiveProfileSidebar()
  const sidebarByEditFormPage =
    isPersonalDetailsRoute || isDiversityRoute ? 'personalDetails' : 'workDetails'
  const afterSubmitUrl = useIsNewLayout()
    ? pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, params, {
        sidebar: sidebar || sidebarByEditFormPage,
      })
    : `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, params)}${
        isWorkDetailsRoute
          ? '#work'
          : isPersonalDetailsRoute
          ? '#personal'
          : isDiversityRoute
          ? '#diversity'
          : ''
      }`
  const onAfterSubmit = () => {
    if (params.id) {
      navigateReplace(afterSubmitUrl)
      afterSubmit?.()
    }
  }

  return { onAfterSubmit }
}
