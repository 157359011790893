import { Action, Color } from '@revolut/ui-kit'
import React, { MouseEvent, useState } from 'react'
import { ActionProps } from '@revolut/ui-kit'

interface RevokeActionProps extends Omit<ActionProps, 'onClick' | 'disabled'> {
  onClick: () => void
}

const RevokeAction = ({ onClick, ...rest }: RevokeActionProps) => {
  const [loading, setLoading] = useState(false)

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setLoading(true)

    try {
      await onClick()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Action color={Color.RED} {...rest} disabled={loading} onClick={handleClick}>
      Revoke
    </Action>
  )
}

export default RevokeAction
