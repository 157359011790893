import React from 'react'
import { Action, Caption, Flex, Token } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface Props {
  to: string
  linkText: string
  label?: string
}

export const EditLink = ({ to, linkText, label }: Props) => (
  <Flex alignItems="center" flexWrap="wrap">
    {label && (
      <Caption pr="s-8" color={Token.color.greyTone50}>
        {label}
      </Caption>
    )}
    <Action to={to} use={InternalLink} useIcon="Calendar" useEndIcon="Pencil">
      {linkText}
    </Action>
  </Flex>
)
