import { OptionInterface, SelectorType } from '@src/interfaces/selectors'
import { useCallback } from 'react'
import { getSenioritySublevels } from '@src/api/seniority'
import { SeniorityInterface, SenioritySublevelInterface } from '@src/interfaces/seniority'
import { useGetOrganisationSettings } from '@src/api/settings'

interface SubsenioritySelectorInterface {
  seniority?: SeniorityInterface | OptionInterface | null
  specialisation: OptionInterface | null
  specialisation_seniority_sublevel?: SenioritySublevelInterface
}

export const useSubsenioritySelector = <T extends SubsenioritySelectorInterface>(
  values: T,
  initialValues: Partial<T>,
) => {
  const { data: settings } = useGetOrganisationSettings()
  const multipleSenioritiesEnabled = !!settings?.enable_multiple_levels_per_seniority

  const subsenioritySelector: SelectorType = useCallback(async () => {
    if (
      'seniority' in values &&
      values.seniority &&
      values.specialisation?.id &&
      multipleSenioritiesEnabled
    ) {
      const resp = await getSenioritySublevels(
        +values.seniority.id,
        +values.specialisation.id,
      )
      const levels = resp.data.results

      const shouldAutofillSubSeniority =
        !initialValues.specialisation_seniority_sublevel ||
        values.seniority?.id !== initialValues.seniority?.id

      // set default subseniority
      if (initialValues.seniority && shouldAutofillSubSeniority) {
        values.specialisation_seniority_sublevel = levels[0]
      }
      return levels
    }
    return []
  }, [
    (values as T).seniority,
    (values as T).specialisation?.id,
    multipleSenioritiesEnabled,
  ])

  return subsenioritySelector
}
