import React from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { formatTimeDiff } from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/formatters/schedulingEvent'
import { AxiosError } from 'axios'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { ERRORS } from '@src/constants/notifications'
import { TimeSlot } from '@src/interfaces/meetings'

export const useScheduleStatusPopup = (
  handleConfirm: VoidFunction,
  handleCancel: VoidFunction,
) => {
  const showStatusPopup = useShowStatusPopup()

  const showSuccessPopup = (event: TimeSlot) => {
    showStatusPopup({
      status: 'success-result',
      title: '1:1 meeting created',
      description: `You'll meet ${formatTimeDiff(event, true)}`,
      actions: statusPopup => (
        <StatusPopupActions
          handleConfirm={handleConfirm}
          handleCancel={() => {
            handleCancel()
            statusPopup.hide()
          }}
        />
      ),
    })
  }

  const showErrorPopup = (err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: 'Failed to schedule event',
      description: getStringMessageFromError(err, ERRORS.UNKNOWN_REFRESH),
    })
  }

  return {
    showSuccessPopup,
    showErrorPopup,
  }
}

interface Props {
  handleConfirm: VoidFunction
  handleCancel: VoidFunction
}

export const StatusPopupActions = ({ handleConfirm, handleCancel }: Props) => (
  <StatusPopup.Actions>
    <Button useIcon="LinkExternalSimple" onClick={handleConfirm}>
      Get Revolut Meet Extension
    </Button>
    <Button variant="secondary" onClick={handleCancel}>
      Do it later
    </Button>
  </StatusPopup.Actions>
)
