import React from 'react'

import { Box, Bullet, Color } from '@revolut/ui-kit'
import { CheckSuccess, MinusCircle } from '@revolut/icons'

interface PreTitleProps {
  complete?: boolean
  skipped?: boolean
}

export const PreTitle = ({ complete, skipped }: PreTitleProps) => {
  if (complete) {
    return (
      <Box mr="s-8">
        <CheckSuccess size={16} color={Color.GREEN} />
      </Box>
    )
  }
  if (skipped) {
    return (
      <Box mr="s-8">
        <MinusCircle size={16} color={Color.ORANGE} />
      </Box>
    )
  }
  return <Bullet size={12} mr="s-8" color={Color.GREY_TONE_50} />
}
