import React from 'react'
import { Item, ItemSkeleton, Group } from '@revolut/ui-kit'
import { useGetOffers } from '@src/api/offerCreation'
import { ROUTES } from '@src/constants/routes'
import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'

interface Props {
  candidateId: number
}

const sortBy = [
  {
    sortBy: 'id',
    direction: SORT_DIRECTION.ASC,
  },
]

export const OfferWidget = ({ candidateId }: Props) => {
  const initialFilterBy: FilterByInterface[] = [
    {
      filters: [{ id: `${candidateId}`, name: `${candidateId}` }],
      columnName: 'candidate',
    },
  ]

  const { data: offers, isLoading } = useGetOffers(initialFilterBy, sortBy)

  if (isLoading) {
    return (
      <Group>
        <ItemSkeleton>
          <ItemSkeleton.Content>
            <ItemSkeleton.Title />
          </ItemSkeleton.Content>
          <ItemSkeleton.Side />
        </ItemSkeleton>
      </Group>
    )
  }

  const latestOffer = offers?.[0]

  if (!latestOffer) {
    return null
  }

  return (
    <Group>
      <Item
        to={pathToUrl(ROUTES.FORMS.OFFER_CREATION.PREVIEW, {
          id: latestOffer.id,
          candidateId,
        })}
        variant="disclosure"
        use={InternalLink}
        useIcon="Document"
      >
        <Item.Content>
          <Item.Title>Offer from recruitment</Item.Title>
        </Item.Content>
      </Item>
    </Group>
  )
}
