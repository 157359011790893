import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeDocumentListItemInterface } from '@src/interfaces/documents'
import { PersonalInfoInterface } from '@src/interfaces/employees'
import { OnboardingBasicInfo, OnboardingProgress } from '@src/interfaces/onboardingV2'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetOnboardingProgress = (id: string) =>
  useFetch<OnboardingProgress>(
    `${API.ONBOARDING}/employees/${id}/selfService/progress`,
    'v2',
    undefined,
    true,
    undefined,
    true,
  )

export const onboardingWelcomeStep: RequestInterfaceNew<{}> = {
  // there is no get for welcome screen
  get: () => Promise.resolve({ data: {} } as any),
  update: async (_, { onboardingId }) =>
    api.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/welcome`,
      undefined,
      undefined,
      'v2',
      true,
    ),
  submit: async (_, { onboardingId }) =>
    api.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/welcome`,
      undefined,
      undefined,
      'v2',
      true,
    ),
}

export const useGetOnboardingBasicInfo = (id: string) =>
  useFetch<OnboardingBasicInfo>(
    `${API.ONBOARDING}/employees/${id}/selfService/basic`,
    'v2',
    undefined,
    true,
    undefined,
    true,
  )

export const onboardingBasicInfoStep: RequestInterfaceNew<OnboardingBasicInfo> = {
  get: ({ onboardingId }) =>
    api.get(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/basic`,
      undefined,
      'v2',
      true,
    ),
  update: async (_, { onboardingId }, data) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/basic`,
      data,
      undefined,
      'v2',
      true,
    ),
  submit: async (data, { onboardingId }) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/basic`,
      data,
      undefined,
      'v2',
      true,
    ),
}

export const getonboardingV2EmailSuggestions = (id: string) =>
  api.get<string[]>(
    `${API.ONBOARDING}/employees/${id}/selfService/basic/emailSuggestions`,
    undefined,
    'v2',
    true,
  )

export const onboardingPersonalInfoStep: RequestInterfaceNew<PersonalInfoInterface> = {
  get: ({ onboardingId }) =>
    api.get(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/personal`,
      undefined,
      'v2',
      true,
    ),
  update: async (_, { onboardingId }, data) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/personal`,
      data,
      undefined,
      'v2',
      true,
    ),
  submit: async (data, { onboardingId }) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/personal`,
      data,
      undefined,
      'v2',
      true,
    ),
}

export const onboardingPayrollStep: RequestInterfaceNew<{}> = {
  get: ({ onboardingId }) =>
    api.get(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/payroll`,
      undefined,
      'v2',
      true,
    ),
  update: async (_, { onboardingId }, data) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/payroll`,
      data,
      undefined,
      'v2',
      true,
    ),
  submit: async (data, { onboardingId }) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/payroll`,
      data,
      undefined,
      'v2',
      true,
    ),
}

export const onboardingDiversityStep: RequestInterfaceNew<{}> = {
  get: ({ onboardingId }) =>
    api.get(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/diversity`,
      undefined,
      'v2',
      true,
    ),
  update: async (_, { onboardingId }, data) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/diversity`,
      data,
      undefined,
      'v2',
      true,
    ),
  submit: async (data, { onboardingId }) =>
    apiWithoutHandling.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/diversity`,
      data,
      undefined,
      'v2',
      true,
    ),
}

export const onboardingDocumentsStep: RequestInterfaceNew<{}> = {
  get: ({ onboardingId }) =>
    api.get(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/documents`,
      undefined,
      'v2',
      true,
    ),
  update: async (_, { onboardingId }, data) =>
    api.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/documents`,
      data,
      undefined,
      'v2',
      true,
    ),
  submit: async (data, { onboardingId }) =>
    api.post(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/documents`,
      data,
      undefined,
      'v2',
      true,
    ),
}

export const getOnboardingV2Documents = (
  onboardingId: string,
): RequestInterface<EmployeeDocumentListItemInterface>['getItems'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<EmployeeDocumentListItemInterface>>(
      `${API.ONBOARDING}/employees/${onboardingId}/selfService/documents`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
      true,
    )
}
