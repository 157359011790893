import React from 'react'
import { Box, Header, StatusWidget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { removeSignupStateCookie } from '../common'

const Suspended = () => {
  return (
    <>
      <Header variant="main" pt="s-72">
        <Header.Title>Welcome to Revolut People</Header.Title>
      </Header>
      <Box mt="s-32">
        <StatusWidget>
          <StatusWidget.Title>The account was suspended</StatusWidget.Title>
          <StatusWidget.Action
            onClick={() => {
              removeSignupStateCookie()
              navigateTo(ROUTES.MAIN)
            }}
          >
            Back to home page
          </StatusWidget.Action>
        </StatusWidget>
      </Box>
    </>
  )
}

export default Suspended
