import React, { useMemo, useState } from 'react'
import { connect } from 'lape'
import {
  Box,
  Columns,
  DetailsCell,
  Group,
  HStack,
  MoreBar,
  Subheader,
  Text,
  Token,
} from '@revolut/ui-kit'
import { Download, InfoOutline } from '@revolut/icons'
import { reportRunsTableRequests } from '@src/api/dataAnalytics'
import { navigateTo } from '@src/actions/RouterActions'
import {
  colomnsNameTableColumn,
  colomnsReportNameTableColumn,
  parameterNameTableColumn,
  parameterValueTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import {
  ReportInterface,
  ReportColumnsTableInterface,
  ReportParametersTableInterface,
  RunReportResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Tooltip from '@components/Tooltip/Tooltip'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getScheduleMessage } from '@src/pages/Forms/DataAnalyticsReportForm/utils'
import { pathToUrl } from '@src/utils/router'
import { HistoricalRunsTable } from './components/HistoricalRunsTable'
import { RequiredParametersPopup } from './components/Popup'
import { useActions } from './hooks/useActions'

const PARAMS_ROW: RowInterface<ReportParametersTableInterface> = {
  cells: [
    {
      ...parameterNameTableColumn,
      width: 200,
    },
    {
      ...parameterValueTableColumn,
      width: 200,
    },
  ],
}

const COLUMNS_ROW: RowInterface<ReportColumnsTableInterface> = {
  cells: [
    {
      ...colomnsNameTableColumn,
      width: 200,
    },
    {
      ...colomnsReportNameTableColumn,
      width: 200,
    },
  ],
}

export const DetailsForm = connect(() => {
  const { values } = useLapeContext<ReportInterface>()
  const table = useTable<RunReportResponseInterface>({
    getItems: reportRunsTableRequests(values.id),
  })
  const [popupOpen, setPopupOpen] = useState(false)

  const parametersTableData = useMemo(() => {
    return Object.keys(values.default_query_parameters).map(key => ({
      name: key,
      value: values.default_query_parameters[key],
    }))
  }, [values.default_query_parameters])

  const requiredParameters = useMemo(() => {
    return Object.keys(values.default_query_parameters).filter(
      key => !values.default_query_parameters[key],
    )
  }, [values.default_query_parameters])

  const latestSuccessRun = useMemo(() => {
    return table.data.find(run => run.status === QueryRunStatuses.Success)
  }, [table.data])

  const {
    executionParameters,
    setExecutionParameters,
    isDownloadLoading,
    isLoading,
    runId,
    runStatus,
    runReport,
    downloadReport,
  } = useActions({ latestSuccessRun, setPopupOpen, table })

  return (
    <PageBody maxWidth={Token.breakpoint.xxl}>
      <MoreBar>
        <MoreBar.Action
          disabled={isLoading}
          onClick={requiredParameters.length > 0 ? () => setPopupOpen(true) : runReport}
          pending={isLoading}
          variant="accent"
        >
          {values.report_type === 'ui' ? 'Refresh Report Data Now' : 'Run Report Now'}
        </MoreBar.Action>
        <MoreBar.Action
          disabled={
            !(
              latestSuccessRun?.id ||
              (runId && runStatus === QueryRunStatuses.Success)
            ) || isDownloadLoading
          }
          onClick={downloadReport}
          pending={isDownloadLoading}
          useIcon={Download}
        >
          Download Latest Run
        </MoreBar.Action>
      </MoreBar>
      <Columns defaultCount={2} gap="s-16" size={520}>
        <Box>
          <FormPreview
            data={values}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT, { id: values.id }),
              )
            }
            title="General Details"
          >
            <Group>
              <FormPreview.Item field="name" title="Name" />
              <FormPreview.Details field="description" title="Description" />
            </Group>
          </FormPreview>

          <FormPreview
            data={values}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT, { id: values.id }),
              )
            }
            title="Schedule"
          >
            <Group>
              <FormPreview.Item field="scheduling_type.name" title="Schedule Type" />
              <FormPreview.Item
                field="schedule"
                insert={getScheduleMessage}
                title="Schedule"
              />
              <FormPreview.Item
                field="next_run_date_time"
                title="Next Run"
                type="dateTime"
              />
            </Group>
          </FormPreview>

          <FormPreview
            data={values}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT, { id: values.id }),
              )
            }
            title="Source Details"
          >
            <Group>
              <FormPreview.Item
                field="query.name"
                title="Selected Query"
                insert={() => (
                  <HStack align="center" gap="s-8">
                    <Text>{values.query.name}</Text>
                    {values.query.status === 'outdated' && (
                      <Tooltip
                        placement="top"
                        text="Query outdated please refresh query to get latest updates"
                      >
                        <InfoOutline color={Token.color.warning} size={16} />
                      </Tooltip>
                    )}
                  </HStack>
                )}
              />
              <FormPreview.Context<ReportInterface>
                insert={() => {
                  return (
                    <>
                      <DetailsCell>
                        <DetailsCell.Title>Parameters</DetailsCell.Title>
                      </DetailsCell>
                      <Table.Widget>
                        <Table.Widget.Table>
                          <AdjustableTable
                            data={parametersTableData}
                            dataType="Parameters"
                            count={parametersTableData.length}
                            hideCountAndButtonSection
                            name={TableNames.ReportingAppReportsViewParameters}
                            noDataMessage="No parameters"
                            row={PARAMS_ROW}
                          />
                        </Table.Widget.Table>
                      </Table.Widget>
                    </>
                  )
                }}
              />
            </Group>
          </FormPreview>

          <FormPreview
            data={values}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.FORMAT, { id: values.id }),
              )
            }
            title="Format Details"
          >
            <Group>
              {values.report_type === 'download' && (
                <FormPreview.Item field="output_format.format" title="Output Format" />
              )}
              <FormPreview.Context<ReportInterface>
                insert={() => {
                  return (
                    <>
                      <DetailsCell>
                        <DetailsCell.Title>Selected Columns</DetailsCell.Title>
                      </DetailsCell>
                      <Table.Widget>
                        <Table.Widget.Table>
                          <AdjustableTable
                            data={values.output_format.columns}
                            dataType="Report columns"
                            count={values.output_format.columns.length}
                            hideCountAndButtonSection
                            name={TableNames.ReportingAppReportsViewColumns}
                            noDataMessage="No columns"
                            row={COLUMNS_ROW}
                          />
                        </Table.Widget.Table>
                      </Table.Widget>
                    </>
                  )
                }}
              />
            </Group>
          </FormPreview>

          <FormPreview
            data={values}
            onEdit={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET, {
                  id: values.id,
                }),
              )
            }
            title="Report Type"
          >
            <Group>
              <FormPreview.Item
                field="report_type"
                title="Output Location"
                insert={() =>
                  values.report_type === 'ui' ? 'UI Table' : 'Download from Reporting App'
                }
              />
            </Group>
          </FormPreview>
        </Box>

        <Box>
          <Subheader variant="nested">
            <Subheader.Title>Previous Runs</Subheader.Title>
          </Subheader>
          <HistoricalRunsTable tableData={table.data} />
        </Box>
      </Columns>
      <RequiredParametersPopup
        executionParameters={executionParameters}
        isOpen={popupOpen}
        handleClose={setPopupOpen}
        handleRun={runReport}
        requiredParameters={requiredParameters}
        setExecutionParameters={setExecutionParameters}
      />
    </PageBody>
  )
})
