import { navigateTo } from '@src/actions/RouterActions'
import { submitGoalNotification } from '@src/api/goals'
import LoadingAction from '@src/components/Button/LoadingAction'
import { ROUTES } from '@src/constants/routes'
import { GoalNotificationExtraCategory } from '@src/interfaces/goals'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'
import { CellActionButton } from '@components/TableV2/Buttons/CellActionButton'

export const GoalsNotificationActions = ({
  data,
  onSuccess,
}: {
  data: NotificationsInterface
  onSuccess: (id: string) => void
}) => {
  const { showError } = useSubmitFlowHelpers()

  const categoryToAPIPathMap = {
    team_goal_to_set: 'teamGoalsToSet',
    department_goal_to_set: 'departmentGoalsToSet',

    team_goal_to_approve: 'teamGoalsToApprove',
    department_goal_to_approve: 'departmentGoalsToApprove',

    team_goal_to_calibrate: 'teamGoalsToCalibrate',
    department_goal_to_calibrate: 'departmentGoalsToCalibrate',
  }
  const categoryToRoutePathMap = {
    team_goal_to_set: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department_goal_to_set: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,

    team_goal_to_approve: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department_goal_to_approve: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,

    team_goal_to_calibrate: ROUTES.FORMS.TEAM.GOALS.GENERAL,
    department_goal_to_calibrate: ROUTES.FORMS.DEPARTMENT.GOALS.GENERAL,
  }

  return (
    <>
      <CellActionButton
        onClick={() =>
          navigateTo(
            pathToUrl(
              categoryToRoutePathMap[
                data.category_extra as GoalNotificationExtraCategory
              ],
              { id: data.item_uuid },
              { cycle__id: String(data.cycle_id) },
            ),
          )
        }
      >
        View
      </CellActionButton>
      <LoadingAction
        onSubmit={async () => {
          try {
            await submitGoalNotification(
              data.item_id,
              categoryToAPIPathMap[data.category_extra as GoalNotificationExtraCategory],
              data.cycle_id,
            )

            onSuccess(data.id)
          } catch (err) {
            showError('Failed to mark as done', 'Please try again')
          }
        }}
      >
        Mark as done
      </LoadingAction>
    </>
  )
}
