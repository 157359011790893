import React, { useEffect } from 'react'
import { Header, InputGroup, Box, Text, Flex, Token } from '@revolut/ui-kit'
import { subYears } from 'date-fns'

import { selectorKeys } from '@src/constants/api'
import {
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  PersonalInfoInterface,
} from '@src/interfaces/employees'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { BackButton, NextButton, TabProps } from '../common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  addressLine1Message,
  addressLine2Message,
  addressLine3Message,
  cityMessage,
} from '@src/pages/EmployeeProfile/Forms/constants'
import { LaptopDeliveryInputs } from '@src/pages/EmployeeProfile/Forms/LaptopDelivery/LaptopDeliveryInputs'
import { useGetLifecycleSettings } from '@src/api/settings'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'
import { SectionOptions } from '@src/interfaces/customFields'

const DATE_OF_BIRTH_YEAR_LIMIT = 13

const Personal = ({ data, dynamicGroups, onComplete, nextRoute }: TabProps) => {
  const { values } = useLapeContext<PersonalInfoInterface>()

  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const enableEquipmentDelivery = lifecycleSettings?.enable_equipment_delivery
  const contactHREmail = lifecycleSettings?.contact_hr_email

  const dateOfBirthLimit = subYears(new Date(), DATE_OF_BIRTH_YEAR_LIMIT)

  useEffect(() => {
    // TODO: delete when refactored on BE
    if (values.phone_number) {
      values.phone_number = undefined
    }
  }, [])

  useEffect(() => {
    if (enableEquipmentDelivery === false) {
      values.laptop_delivery = undefined
    }
  }, [enableEquipmentDelivery])

  return (
    <>
      <PageBody>
        <AutoStepper pb="s-24">
          <Header color={Token.color.foreground} variant="main" mb="-s-8">
            <Header.Title>Personal information</Header.Title>
            {contactHREmail ? (
              <Header.Subtitle>
                Please verify that the below information matches with your government
                issued identication document. If this doesn’t look right, please write to{' '}
                <Text
                  use="a"
                  href={`mailto:${contactHREmail}`}
                  target="_blank"
                  color="primary"
                  textDecoration="none"
                >
                  {contactHREmail}
                </Text>
                .
              </Header.Subtitle>
            ) : null}
          </Header>

          <NewStepperTitle title="Bio" />
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BIO}
            dynamicGroups={dynamicGroups}
            useExternalRequests
          >
            <InputGroup>
              <LapeRadioSelectInput
                name="legal_sex"
                label="Legal sex"
                selector={selectorKeys.legal_sexes}
              />
              <LapeNewMultiSelect
                name="nationalities"
                placeholder="Nationalities"
                selector={selectorKeys.countries}
                hideOptional
              />
              <LapeDatePickerInput
                name="birth_date"
                label="Date of birth"
                disabledDays={{ after: dateOfBirthLimit }}
                initialMonth={dateOfBirthLimit}
                required
              />
              <LapeRadioSelectInput
                name="marital_status"
                label="Marital status"
                selector={selectorKeys.marital_statuses}
              />
              <LapeNewMultiSelect
                name="languages"
                placeholder="Languages"
                selector={selectorKeys.language}
                hideOptional
              />
            </InputGroup>
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO}
            dynamicGroups={dynamicGroups}
            useExternalRequests
          >
            <NewStepperTitle title="Address" />
            <InputGroup>
              <LapeNewInput
                name="address_line_1"
                label="Address Line 1"
                message={addressLine1Message}
                hideOptional
              />
              <LapeNewInput
                name="address_line_2"
                label="Address line 2"
                message={addressLine2Message}
                hideOptional
              />
              <LapeNewInput
                name="address_line_3"
                label="Address line 3"
                message={addressLine3Message}
              />
              <InputGroup variant="horizontal">
                <Box flex="1">
                  <LapeNewInput
                    name="city"
                    label="City"
                    hideOptional
                    message={cityMessage}
                  />
                </Box>
                <Box flex="1">
                  <LapeNewInput name="post_code" label="ZIP code" hideOptional />
                </Box>
              </InputGroup>

              <InputGroup variant="horizontal">
                <Box flex="1">
                  <LapeNewInput name="county" label="County" />
                </Box>
                <Box flex="1">
                  <LapeRadioSelectInput
                    selector={selectorKeys.countries}
                    name="country"
                    label="Country"
                  />
                </Box>
              </InputGroup>
            </InputGroup>
          </NewStepperSectionCustomFields>

          {enableEquipmentDelivery ? <LaptopDeliveryInputs withStepperTitle /> : null}

          <NewStepperTitle title="Emergency contact" />
          <InputGroup>
            <LapeNewInput
              name="emergency_contact_full_name"
              label="Full name"
              hideOptional
            />
            <LapeNewInput
              name="emergency_contact_relationship"
              label="Relationship"
              hideOptional
            />
            <LapeNewInput name="emergency_contact_email" label="Email" hideOptional />
            <LapePhoneInput
              prefixName="emergency_contact_phone_country_code"
              phoneName="emergency_contact_phone_number"
              message="Please provide emergency contact mobile number"
              required
            />
          </InputGroup>

          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS}
            dynamicGroups={dynamicGroups}
            customFieldsOnly
            useExternalRequests
          >
            <NewStepperTitle title="Correspondence address" />
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS}
            dynamicGroups={dynamicGroups}
            customFieldsOnly
            useExternalRequests
          >
            <NewStepperTitle title="Identification details" />
          </NewStepperSectionCustomFields>

          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU}
            dynamicGroups={dynamicGroups}
            useExternalRequests
          >
            <NewStepperTitle
              title="About you"
              subtitle="You can write a few things about yourself e.g. hobbies, fun facts, or any other things you would like to share."
            />
            <InputGroup>
              <LapeNewTextArea label="About" name="about" rows={3} />
            </InputGroup>
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <Flex gap="s-16">
          <BackButton
            onClick={() =>
              navigateTo(pathToUrl(ROUTES.ONBOARDING.BASIC_INFO, { id: data.id }))
            }
          />
          <NextButton
            onClickInPreviewMode={() => {
              if (nextRoute) {
                navigateTo(nextRoute)
              }
            }}
            afterSubmit={() => {
              if (nextRoute) {
                navigateTo(nextRoute)
                onComplete(onboardingSteps.personal_info)
              }
            }}
            elevated
          />
        </Flex>
      </PageActions>
    </>
  )
}

export default Personal
