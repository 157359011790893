import {
  Button,
  Input,
  InputGroup,
  Popup,
  Spacer,
  Text,
  TextArea,
  Token,
} from '@revolut/ui-kit'
import React, { useState } from 'react'

export const SetFinalProgress = ({
  onSubmit,
  onCancel,
}: {
  onSubmit: (calibratedProgress: number, comment: string) => Promise<unknown>
  onCancel: () => void
}) => {
  const [progress, setProgress] = useState<number>()
  const [comment, setComment] = useState<string>()

  return (
    <>
      <Text variant="h5">Set final progress for the goal</Text>
      <Spacer height="s-8" />
      <Text variant="caption" color={Token.color.greyTone50}>
        Calibrate the status and progress
      </Text>
      <Spacer height="s-24" />
      <InputGroup>
        <Input
          label="Current progress (%)"
          value={progress}
          onChange={ev => setProgress(Number(ev.currentTarget.value))}
          required
        />
        <TextArea
          rows={1}
          maxRows={3}
          label="Comment"
          value={comment}
          onChange={ev => setComment(ev.currentTarget.value)}
          required
        />
      </InputGroup>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          elevated
          onClick={() => onSubmit((progress || 0) / 100, comment!)}
          disabled={!comment || typeof progress === 'undefined'}
        >
          Submit
        </Button>
      </Popup.Actions>
    </>
  )
}
