import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  CandidateInterface,
  InterviewPendingSchedulingStatuses,
  InterviewPendingSchedulingTypes,
  InterviewRoundInterface,
  InterviewShowScheduleSidebarStatuses,
  InterviewType,
  ScheduleSidebarModeType,
  InterviewStageWithoutRoundInterface,
  InterviewFeedbackInterface,
  CandidateSidebarTypes,
} from '@src/interfaces/interviewTool'
import UpcomingInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingInterviewNotifications'
import { Box } from '@revolut/ui-kit'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import InterviewStartCard from '@src/pages/Forms/Candidate/InterviewProgress/InterviewStartCard'
import { InterviewFeedbackSidebar } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedbackSidebar'
import ArchiveDetails from '@src/pages/Forms/Candidate/InterviewProgress/components/ArchiveDetails'
import CandidateShortSummarySidebar from '@src/features/InterviewTool/CandidateShortSummarySidebar'
import { ROUTES } from '@src/constants/routes'
import UpcomingOnlineTestNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOnlineTestNotifications'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import AnonymizeNotice from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/AnonymizeNotice'
import UpcomingOfferNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/UpcomingOfferNotifications'
import ChangeRoundWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ChangeRoundWidget'
import ConfidentialCandidateWidget from '@src/pages/Forms/Candidate/InterviewProgress/components/Widgets/ConfidentialCandidateWidget'
import PrepCallInterviewNotifications from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/PrepCallInterviewNotifications'
import { useGetOfferSettings } from '@src/api/settings'
import { Comments } from '@src/pages/Forms/Candidate/Comments/Comments'
import CVScreeningNotification from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/CVScreeningNotification'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'
import { SnoozeBanner } from '@src/pages/Forms/Candidate/InterviewProgress/components/Snooze'
import { StagesTable } from '@src/pages/Forms/Candidate/StagesTable/StagesTable'
import { StagesWidget } from '@src/pages/Forms/Candidate/StagesWidget/StagesWidget'
import OnlineTestSummarySidebar from '@src/pages/Forms/Candidate/OnlineTestSummarySidebar/OnlineTestSummarySidebar'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import CandidateInformation from '@src/pages/Forms/Candidate/CandidateInformation'
import { SendOnlineTestSidebar } from '@src/pages/Forms/SendOnlineTest/SendOnlineTestSidebar'
import { CandidateCancelledScheduling } from '@src/pages/Forms/Candidate/InterviewProgress/components/Notifications/CandidateCancelledScheduling'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import SendEngagementEmail from '@src/features/CRM/SendEngagementEmail'

interface Props {
  candidate?: CandidateInterface
  previewStageMode: boolean
  round?: InterviewRoundInterface
  refresh: () => Promise<void>
  refreshStats: () => void
  isLoading?: boolean
  canEditRound: boolean
  fetchRound: (roundId: number) => void
}

const Summary = ({
  candidate,
  round,
  previewStageMode,
  refresh,
  refreshStats,
  isLoading,
  canEditRound,
  fetchRound,
}: Props) => {
  const { setActiveAction, activeAction, setActiveStage, activeStage } =
    useCandidateProfileContext()
  const params = useParams<{ id: string }>()
  const { id } = params

  const featureFlags = useSelector(selectFeatureFlags)
  const canViewNewInterviewStagesWidget = featureFlags.includes(
    FeatureFlags.InterviewStagesUiRevamp,
  )

  const [selectedStageItem, setSelectedStageItem] = useState<
    InterviewStageWithoutRoundInterface | InterviewFeedbackInterface
  >()
  const [selectedStageType, setSelectedStageType] = useState<InterviewType>()
  const currentStageId = candidate?.active_interview_round?.latest_interview_stage?.id
  const canViewEditOffer = candidate?.field_options?.permissions?.includes(
    PermissionTypes.ViewCandidateOffers,
  )

  const { data: offerSettings, isLoading: isLoadingOfferSettings } = useGetOfferSettings()
  const {
    data: stages,
    isLoading: interviewStagesLoading,
    refetch: refetchStages,
    status: stagesLoadingStatus,
  } = useFetchInterviewStages(round?.id)

  useEffect(() => {
    const currentStage = stages?.find(item => item.id === currentStageId)
    setActiveStage(activeAction?.isPrepCall ? currentStage : selectedStageItem)
  }, [activeAction?.isPrepCall, currentStageId, selectedStageItem?.id])

  useEffect(() => {
    refetchStages()
  }, [candidate])

  const handleOpenSidebar = (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
    mode?: ScheduleSidebarModeType,
    actionType?: CandidateSidebarTypes,
  ) => {
    // https://revolut.atlassian.net/wiki/spaces/PP/pages/3444967578/Recruitment+frontend+structure
    /* TODO: needs refactoring (we have a lot of actions, like opening different modes of scheduling sidebar (editing, rescheduling) as well as other actions, like sending online tests etc, around 11 actions. The way how we are handling it is a bit confusing, we should think of a more transparent approach) */
    if (actionType) {
      setActiveAction({ type: actionType })
      return
    }

    if ('scheduling_status' in data) {
      if (previewStageMode) {
        setActiveAction(undefined)
        setSelectedStageItem(data)
        return
      }

      if (data.interview_type === 'online_test') {
        setActiveAction({
          type: 'onlineTestSummary',
        })
      } else if (mode === 'rescheduling') {
        setActiveAction({
          type: 'schedule',
          mode: 'rescheduling',
        })
      } else if (
        InterviewPendingSchedulingStatuses.includes(data.scheduling_status) &&
        InterviewPendingSchedulingTypes.includes(data.interview_type)
      ) {
        setActiveAction({
          type: 'schedule',
          mode: 'scheduling',
        })
      } else if (InterviewShowScheduleSidebarStatuses.includes(data.scheduling_status)) {
        setActiveAction({
          type: 'schedule',
          mode: 'view',
        })
      } else {
        setActiveAction({
          type: 'shortSummary',
        })
      }

      setSelectedStageItem(data)
      return
    }

    setActiveAction({
      type: 'stage',
    })
  }

  const handleStageClick = (
    data: InterviewStageWithoutRoundInterface | InterviewFeedbackInterface,
    mode?: ScheduleSidebarModeType,
    stageType?: InterviewType,
    actionType?: CandidateSidebarTypes,
  ) => {
    setSelectedStageItem(data)
    setSelectedStageType(stageType)
    setActiveStage(data)

    if ('interview_type' in data && data.interview_type === 'offer') {
      return
    }
    handleOpenSidebar(data, mode, actionType)
  }

  const renderStagesTable = () => {
    if (canViewNewInterviewStagesWidget && candidate) {
      return (
        <StagesWidget
          candidate={candidate}
          canViewEditOffer={!!canViewEditOffer}
          disableActions={previewStageMode}
          isMainRound={candidate?.active_interview_round?.id === round?.id}
          loading={isLoading || interviewStagesLoading}
          round={round}
          stages={stages}
          onClick={(data, mode, stageType, actionType) => {
            setSelectedStageItem(data)
            setSelectedStageType(stageType)
            setActiveStage(data)

            if ('interview_type' in data && data.interview_type === 'offer') {
              return
            }
            handleOpenSidebar(data, mode, actionType)
          }}
          onRoundChange={fetchRound}
          onRefresh={() => {
            refetchStages()
            refresh()
            setActiveAction(undefined)
            setSelectedStageItem(undefined)
          }}
        />
      )
    }
    return (
      <StagesTable
        round={round}
        mainRoundId={candidate?.active_interview_round?.id}
        onClick={handleStageClick}
        selectedItemId={selectedStageItem?.id}
        onRefresh={() => {
          refetchStages()
          refresh()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        stages={stages}
        status={isLoading ? 'loading' : stagesLoadingStatus}
        candidateId={candidate?.id}
        fetchRound={fetchRound}
      />
    )
  }

  const renderComments = () => (
    <Comments
      roundId={round?.id}
      refreshStats={refreshStats}
      onClickSeeAll={() => navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.COMMENTS, params))}
    />
  )

  let canAddFeedback = !!round?.field_options?.permissions?.includes(
    PermissionTypes.SeeAddFeedbackButton,
  )
  let canCancel = !!round?.field_options?.permissions?.includes(
    PermissionTypes.CancelPendingScorecard,
  )
  // let canAnonymize = candidate?.field_options?.permissions?.includes(
  //   PermissionTypes.AnonymizeCandidate,
  // )
  //
  if (previewStageMode) {
    canAddFeedback = false
    canCancel = false
    // canAnonymize = false
  }
  //
  const canAddRound = previewStageMode
    ? false
    : !!round?.field_options?.permissions?.includes(PermissionTypes.AddInterviewRound)

  const renderSendEngagementEmailSidebar = () => {
    if (
      candidate?.id &&
      round?.id &&
      round?.latest_interview_stage?.id &&
      activeAction?.type === 'sendEngagementEmail'
    ) {
      return (
        <SendEngagementEmail
          candidates={[
            {
              candidateId: candidate.id,
              roundId: round.id,
              stageId: round.latest_interview_stage.id,
            },
          ]}
          open
          onClose={() => setActiveAction(undefined)}
        />
      )
    }
    return null
  }
  return (
    <>
      <>
        <TwoColumnsLayout
          rightMaxWidth={{
            lg: 400,
            md: '100%',
          }}
          leftMinWidth={{
            lg: 500,
            md: '100%',
          }}
          left={
            previewStageMode ? (
              <>
                {round && candidate && (
                  <ChangeRoundWidget
                    candidateId={candidate.id}
                    round={round}
                    onRefresh={refresh}
                  />
                )}
                <CandidateCancelledScheduling
                  candidate={candidate}
                  canEditRound={canEditRound}
                  round={round}
                  stages={stages}
                  onRefresh={refresh}
                  onScheduleInterview={data => {
                    handleStageClick(data, 'rescheduling', undefined, 'schedule')
                  }}
                />
                <ArchiveDetails round={round} />
                <PrepCallInterviewNotifications
                  roundId={round?.id}
                  onView={(interviewId, stageId) => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'view',
                      interviewId,
                      stageId,
                      isPrepCall: true,
                    })
                  }}
                />
                {renderStagesTable()}
                {renderComments()}
              </>
            ) : (
              <>
                {candidate?.is_confidential && (
                  <ConfidentialCandidateWidget candidate={candidate} />
                )}
                <SnoozeBanner candidate={candidate} onAfterSubmit={refresh} />
                <AnonymizeNotice date={candidate?.anonymising_expected_date_time} />
                <CandidateCancelledScheduling
                  candidate={candidate}
                  canEditRound={canEditRound}
                  round={round}
                  stages={stages}
                  onRefresh={refresh}
                  onScheduleInterview={data => {
                    handleStageClick(data, 'rescheduling', undefined, 'schedule')
                  }}
                />
                {canViewEditOffer && round && !isLoadingOfferSettings && (
                  <UpcomingOfferNotifications
                    id={round.id}
                    signingEnabled={!!offerSettings?.enable_offer_signing}
                    onRefresh={refresh}
                  />
                )}
                <UpcomingInterviewNotifications id={id} />

                {stages &&
                  !!candidate?.active_interview_round?.latest_interview_stage?.id && (
                    <UpcomingOnlineTestNotifications
                      round={candidate.active_interview_round}
                      onOpenSidebar={() => {
                        setActiveAction({ type: 'sendOnlineTest' })

                        setSelectedStageItem(
                          stages.find(
                            item =>
                              item.id ===
                              candidate.active_interview_round!.latest_interview_stage!
                                .id,
                          ),
                        )
                      }}
                    />
                  )}

                <PrepCallInterviewNotifications
                  roundId={round?.id}
                  onView={(interviewId, stageId) => {
                    setActiveAction({
                      type: 'schedule',
                      mode: 'view',
                      interviewId,
                      stageId,
                      isPrepCall: true,
                    })
                  }}
                />
                {candidate && (
                  <CVScreeningNotification
                    candidate={candidate}
                    round={candidate.active_interview_round}
                  />
                )}

                <ArchiveDetails round={round} />
                {candidate && !candidate.active_interview_round && (
                  <Box mt="s-16">
                    <InterviewStartCard candidate={candidate} />
                  </Box>
                )}
                {renderStagesTable()}
                {renderComments()}
              </>
            )
          }
          right={
            <CandidateInformation
              candidate={candidate}
              canAddRound={canAddRound}
              canEditRound={canEditRound}
              round={round}
              loading={isLoading}
              previewStageMode={previewStageMode}
              onOpenSidebar={type => {
                setActiveAction({ type })
              }}
            />
          }
        />
      </>

      <InterviewFeedbackSidebar
        key={activeStage?.id}
        isOpen={activeAction?.type === 'stage'}
        onExit={() => {
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        scorecard={activeStage as InterviewFeedbackInterface}
        title={(activeStage as InterviewFeedbackInterface)?.interviewer?.display_name}
        round={round}
        stageType={selectedStageType}
      />

      <CandidateShortSummarySidebar
        isOpen={Boolean(activeAction?.type === 'shortSummary' && activeStage)}
        onClose={() => setActiveAction(undefined)}
        stages={stages}
        stage={activeStage as InterviewStageWithoutRoundInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refresh()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        canAddFeedback={canAddFeedback}
        canCancel={!!canCancel}
        onOpenSidebar={handleOpenSidebar}
        canViewEditOffer={!!canViewEditOffer}
        disableActions={previewStageMode}
        currentStageId={round?.latest_interview_stage?.id}
        candidateId={candidate?.id}
      />
      <OnlineTestSummarySidebar
        activeActionType={activeAction?.type}
        isOpen={Boolean(activeAction?.type === 'onlineTestSummary' && selectedStageItem)}
        onClose={() => setActiveAction(undefined)}
        stage={activeStage as InterviewStageWithoutRoundInterface | undefined}
        roundId={round?.id}
        onRefresh={() => {
          refetchStages()
          refreshStats()
          setActiveAction(undefined)
          setSelectedStageItem(undefined)
        }}
        onOpenSidebar={handleOpenSidebar}
        candidateId={candidate?.id}
      />
      {candidate?.id && activeAction?.type === 'sendOnlineTest' && (
        <SendOnlineTestSidebar
          candidateId={candidate.id}
          candidateEmail={candidate.email}
          roundId={candidate.active_interview_round?.id}
          stageId={selectedStageItem?.id}
          onClose={() => setActiveAction(undefined)}
          onSuccess={() => {
            refetchStages()
            setActiveAction(undefined)
          }}
        />
      )}
      {renderSendEngagementEmailSidebar()}
    </>
  )
}

export default Summary
