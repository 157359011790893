import set from 'lodash/set'
import { useLapeContext } from '@src/features/Form/LapeForm'
import React, { useState } from 'react'
import { Archive, Unarchive } from '@revolut/icons'
import { Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import MoreBarButton, {
  SettingsButtonProps,
} from '@src/features/SettingsButtons/MoreBarButton'
import { FunctionInterface } from '@src/interfaces/functions'
import { EntityPermissions } from '@src/store/auth/types'
import { archiveFunction, unarchiveFunction } from '@src/api/functions'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { parseError } from '@src/features/SettingsButtons/ArchiveButton/ArchiveButton'

interface ArchiveButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  data?: FunctionInterface
}

export const ArchiveFunctionButton = ({ data }: ArchiveButtonProps) => {
  const { values } = useLapeContext<FunctionInterface>()
  const [loading, setLoading] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const statusPopup = useStatusPopup()
  const isArchived = values.status.id === Statuses.archived

  const canArchive = data?.field_options?.actions?.includes(EntityPermissions.Change)

  if (!data?.id || !canArchive) {
    return null
  }

  const archive = async (status = { id: Statuses.archived, name: 'Archived' }) => {
    setLoading(true)
    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>Operation in progress...</StatusPopup.Title>
      </StatusPopup>,
    )
    const fallbackStatus = values.status
    set(values, 'status', status)
    try {
      const action = status.id === Statuses.archived ? archiveFunction : unarchiveFunction
      await action(data.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            {isArchived ? 'Successfully unarchived' : 'Function successfully archived'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (e) {
      const errorMsg = parseError(e)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed</StatusPopup.Title>
          <StatusPopup.Description>{errorMsg}</StatusPopup.Description>
        </StatusPopup>,
      )
      set(values, 'status', fallbackStatus)
    } finally {
      setLoading(false)
    }
  }

  if (isArchived) {
    return (
      <MoreBarButton
        useIcon={Unarchive}
        pending={loading}
        onClick={() => archive({ id: Statuses.active, name: 'Active' })}
      >
        Unarchive
      </MoreBarButton>
    )
  }

  return (
    <>
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={() => setDialogOpen(false)}
        onConfirm={() => {
          archive()
          setDialogOpen(false)
        }}
        loading={loading}
        onReject={() => setDialogOpen(false)}
        label={`Archiving${values.name ? ` "${values.name}"` : ''}`}
        variant="compact"
      />
      <MoreBarButton
        useIcon={Archive}
        variant="negative"
        pending={loading}
        onClick={() => setDialogOpen(true)}
      >
        Archive
      </MoreBarButton>
    </>
  )
}
