import Form from '@src/features/Form/Form'
import React from 'react'
import MetabaseTeam from './Team'
import { formTeamAccessRequest } from '@src/api/accessRequests'

const MetabaseTeamRouter = () => {
  return (
    <Form api={formTeamAccessRequest}>
      <MetabaseTeam />
    </Form>
  )
}

export default MetabaseTeamRouter
