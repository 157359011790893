import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import CustomFieldsTable from '@src/pages/People/PeopleSubTabs/CustomFields/CustomFieldsTable'

export const AttributesSettings = () => {
  return (
    <>
      <PageHeader
        backUrl={ROUTES.SETTINGS.EMPLOYEES.GENERAL}
        title="Employee attributes"
      />
      <PageBody maxWidth="100%">
        <CustomFieldsTable sectionId={SectionOptions.EmployeeProfile} />
      </PageBody>
    </>
  )
}
