import {
  KPIRoadmapTargetLocationState,
  KPITargetOptions,
  KPIValueTargetLocationState,
} from '@src/features/FormTabs/Kpi/KPITargets/common/types'
import { UpdateTypes } from '@src/interfaces/kpis'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import { history, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getInitialValuesValueTarget } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/ValueTargetForm'
import { getInitialValuesRoadmapTarget } from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/RoadmapTargetForm'

export const createTarget = (options: Omit<KPITargetOptions, 'readonly'>) => {
  const { kpi, defaultCycle, probationId } = options
  const backUrl = `${getLocationPathnameWithoutWorkspace()}${window.location.search}`

  if (kpi.update_type === UpdateTypes.roadmap) {
    const locationState: KPIRoadmapTargetLocationState = {
      ...options,
      backUrl,
      initialValues: getInitialValuesRoadmapTarget(kpi, defaultCycle),
      kpiLocationState: history.location.state,
    }
    navigateTo(
      pathToUrl(ROUTES.FORMS.KPI_TARGET.ROADMAP, { kpiId: kpi.id }),
      locationState,
    )
  } else {
    const locationState: KPIValueTargetLocationState = {
      ...options,
      backUrl,
      initialValues: getInitialValuesValueTarget({
        kpi,
        defaultCycle,
        probationId,
      }),
      kpiLocationState: history.location.state,
    }
    navigateTo(pathToUrl(ROUTES.FORMS.KPI_TARGET.VALUE, { kpiId: kpi.id }), locationState)
  }
}

// If it should be readonly, use "readonly" in options
export const openTarget = (targetId: number | string, options: KPITargetOptions) => {
  const { kpi } = options

  const locationState: KPIValueTargetLocationState = {
    ...options,
    backUrl: `${getLocationPathnameWithoutWorkspace()}${window.location.search}`,
    initialValues: {},
    kpiLocationState: history.location.state,
  }

  if (kpi.update_type === UpdateTypes.roadmap) {
    navigateTo(
      pathToUrl(ROUTES.FORMS.KPI_TARGET.ROADMAP, { kpiId: kpi.id, id: targetId }),
      locationState,
    )
  } else {
    navigateTo(
      pathToUrl(ROUTES.FORMS.KPI_TARGET.VALUE, { kpiId: kpi.id, id: targetId }),
      locationState,
    )
  }
}
