import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { api } from '@src/api/index'
import { API, PerformanceTimeRange } from '@src/constants/api'
import { getMonthsByRange } from '@src/utils/kpi'
import { StaffCostInterface } from '@src/interfaces/staffCost'
import { useFetch } from '@src/utils/reactQuery'

export const getDepartmentStaffCostGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.quarter,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/staffCost/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
    },
  })
export const useGetDepartmentStaffCost = (id?: number | string) =>
  useFetch<StaffCostInterface>(id ? `${API.DEPARTMENT}/${id}/staffCost` : null)
