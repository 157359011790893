import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import addMonths from 'date-fns/addMonths'
import Form from '@src/features/Form/Form'
import { hiringPipelinePermissionsRequestsNew } from '@src/api/permissionManagement'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, Side, InputGroup } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

type HiringPipelineShareAccessProps = {
  onClose: () => void
}

const HiringPipelineShareAccess = ({ onClose }: HiringPipelineShareAccessProps) => {
  const { values } = useLapeContext<PermissionsInterface>()

  return (
    <>
      <InputGroup>
        <LapeRadioSelectInput<PermissionsInterface>
          name="permission"
          label="Permission type"
          selector={selectorKeys.hiring_pipeline_permissions}
        />
        <LapeRadioSelectInput<PermissionsInterface>
          name="employee"
          label="Employee name"
          selector={selectorKeys.employee}
        />
        <InputGroup variant="horizontal">
          <LapeDatePickerInput
            disabledDays={{ before: new Date() }}
            defaultValue={new Date()}
            required
            name="start_date_time"
            label="From Date"
          />
          <LapeDatePickerInput
            disabledDays={
              values.start_date_time
                ? { before: new Date(values.start_date_time) }
                : undefined
            }
            defaultValue={addMonths(new Date(), 3)}
            required
            name="end_date_time"
            label="To Date"
          />
        </InputGroup>
      </InputGroup>
      <Side.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={onClose}
          successText="Pipeline shared successfully"
        />
      </Side.Actions>
    </>
  )
}

interface Props extends HiringPipelineShareAccessProps {
  id: number | string
  isOpen: boolean
}

export default connect(({ id, isOpen, onClose }: Props) => (
  <SideBar
    title="Share access"
    subtitle="Provide candidate access to key stakeholders only if this is strictly necessary"
    isOpen={isOpen}
    onClose={onClose}
    useLayout
  >
    <Form api={hiringPipelinePermissionsRequestsNew(id)} isExistingForm={false}>
      <HiringPipelineShareAccess onClose={onClose} />
    </Form>
  </SideBar>
))
