import React from 'react'
import { Tag, Token } from '@revolut/ui-kit'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { removeUnderscore } from '@src/utils/string'
import upperFirst from 'lodash/upperFirst'

const getStatusColor = (status: PublishingStatuses) => {
  switch (status) {
    case PublishingStatuses.closed:
    case PublishingStatuses.unpublished:
      return Token.color.red
    case PublishingStatuses.published_internally:
    case PublishingStatuses.fully_published:
      return Token.color.green
    default:
      return Token.color.greyTone50
  }
}

type StatusTagProps = {
  status?: PublishingStatuses
}

const StatusTag = ({ status }: StatusTagProps) => {
  if (!status) {
    return null
  }
  return (
    <Tag variant="outlined" color={getStatusColor(status)}>
      {upperFirst(removeUnderscore(status))}
    </Tag>
  )
}

export default StatusTag
