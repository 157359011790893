import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import Loader from '@src/components/CommonSC/Loader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { permissionRequest } from '@src/api/accessManagement'
import { PermissionInterface } from '@src/interfaces/accessManagement'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import Details from '@src/pages/Forms/AccessManagementPermission/Details'
import Users from '@src/pages/Forms/AccessManagementPermission/Users'

const Permission = () => {
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<PermissionInterface>()
  const params = useParams<{ id?: string }>()

  if (!values) {
    return <Loader />
  }

  const tabs = [
    {
      title: 'Details',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.DETAILS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.DETAILS, params),
      component: Details,
      canView: !!values.id,
    },
    {
      title: 'Users',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.USERS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_PERMISSION.USERS, params),
      component: Users,
      quickSummary: values.users_count,
      canView: !!values.id && permissions?.includes(PermissionTypes.ViewPermissions),
    },
  ].filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={values.id ? values.name : 'Add permission'}
        backUrl={ROUTES.ADMIN.ACCESS_MANAGEMENT.PERMISSIONS}
      >
        {tabs.length > 1 && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={permissionRequest}>
    <Permission />
  </Form>
))
