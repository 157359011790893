import React from 'react'
import { Avatar, Item, VStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { useGetCompanyPreferences } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { getIconProps } from '@src/pages/Hub/utils'
import { pathToUrl } from '@src/utils/router'

const integrations = [
  {
    id: 'slack',
    title: 'Slack',
    image: 'slack',
    description: 'Setup custom notifications for your company employees',
    url: ROUTES.FEATURES.INTEGRATION.SLACK.ALL,
  },
  {
    id: 'jira',
    title: 'Jira',
    image: 'jira',
    description: 'Track deliverables and company Roadmap',
    url: ROUTES.FEATURES.INTEGRATION.JIRA,
  },
  {
    id: 'calendar',
    title: 'Google Calendar',
    image: 'calendar',
    description: 'Track meetings and schedule interviews with candidates',
    url: ROUTES.FEATURES.INTEGRATION.GOOGLE,
  },
  {
    id: 'docusign',
    title: 'DocuSign',
    image: 'docusign',
    description: 'Generate sign and update documents in real time',
    url: ROUTES.FEATURES.INTEGRATION.DOCUSIGN,
  },
]

export const Integrations = () => {
  const { data: companyPreferences } = useGetCompanyPreferences()

  return (
    <>
      <SectionTitle
        title="Review your integrations"
        subtitle="Select tools that you would like to use within Revolut People Platform and configure them or skip this step for later."
      />
      <VStack gap="s-16">
        {integrations
          .filter(i => companyPreferences?.tools[i.id])
          .map(integration => {
            return (
              <Item
                key={integration.id}
                onClick={() => navigateTo(pathToUrl(integration.url))}
                use="button"
              >
                <Item.Avatar>
                  <Avatar
                    {...getIconProps(integration.image, 'transparent', 60, 'png')}
                    bg="transparent"
                    size={40}
                    variant="brand"
                  />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{integration.title}</Item.Title>
                  <Item.Description>{integration.description}</Item.Description>
                </Item.Content>
              </Item>
            )
          })}
      </VStack>
    </>
  )
}
