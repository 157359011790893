import React, { useEffect } from 'react'
import {
  Avatar,
  Box,
  Cell,
  FilterButton,
  H5,
  HStack,
  TableWidget,
  Token,
  VStack,
} from '@revolut/ui-kit'

import Loader from '@components/CommonSC/Loader'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  activateWorkSchedule,
  getWorkScheduleReviewEligibleEmployeesTableRequests,
  useGetWorkScheduleReviewEligibility,
} from '@src/api/workSchedule'
import { useTable } from '@src/components/Table/hooks'
import {
  timeOffWorkScheduleReviewEmployeeColumn,
  timeOffWorkScheduleReviewLocationColumn,
  timeOffWorkScheduleReviewRoleColumn,
  timeOffWorkScheduleReviewStatusColumn,
} from '@src/constants/columns/timeOffWorkScheduleReview'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFilterToggle } from '@src/hooks/useFilterToggle'
import { RowInterface } from '@src/interfaces/data'
import {
  WorkScheduleReviewEligibleEmployeesInterface,
  WorkScheduleReviewEligibleEmployeesStatsInterface,
  WorkScheduleReviewStepInterface,
} from '@src/interfaces/workSchedule'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'

const ROW: RowInterface<WorkScheduleReviewEligibleEmployeesInterface> = {
  cells: [
    {
      ...timeOffWorkScheduleReviewEmployeeColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewLocationColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewRoleColumn,
      width: 200,
    },
    {
      ...timeOffWorkScheduleReviewStatusColumn,
      width: 200,
    },
  ],
}

export const WorkScheduleReview = () => {
  const { query } = useQuery()
  const { values } = useLapeContext<WorkScheduleReviewStepInterface>()

  const { data: copySource, isLoading: isCopySourceLoading } =
    useGetWorkScheduleReviewEligibility(query.copyFrom)

  useEffect(() => {
    if (query.copyFrom && copySource) {
      values.recalculate_created_shifts = copySource.recalculate_created_shifts
    }
  }, [query.copyFrom, copySource])

  const table = useTable<
    WorkScheduleReviewEligibleEmployeesInterface,
    WorkScheduleReviewEligibleEmployeesStatsInterface
  >(getWorkScheduleReviewEligibleEmployeesTableRequests(values.id))

  const { toggle: toggleFilterByWarnings, isActive: isFilteredByWarnings } =
    useFilterToggle<
      WorkScheduleReviewEligibleEmployeesInterface,
      WorkScheduleReviewEligibleEmployeesStatsInterface
    >({
      table,
      columnName: 'auto_assignments_details__valid',
      valueName: 'false',
    })

  if (isCopySourceLoading) {
    return <Loader />
  }
  return (
    <>
      <VStack space="s-16">
        <>
          <SectionTitle title="Review how your work schedule will be applied" />
          <Cell p={0}>
            <VStack width="100%">
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Recalculate created shifts',
                  description:
                    'When enabled, all shifts in the current month will be recalculated. Days which have been edited to add overtime or holidays will not be changed',
                  avatar: (
                    <Avatar size={40} color={Token.color.blue} useIcon="ArrowExchange" />
                  ),
                  transparent: true,
                }}
                name="recalculate_created_shifts"
              />
            </VStack>
          </Cell>
        </>
        {!values.is_default_schedule && (
          <Box>
            <TableWidget>
              <TableWidget.Info>
                <VStack gap="s-16">
                  <H5>Review and confirm employees affected by work schedule</H5>
                  <HStack gap="s-32">
                    <Stat label="Total" mb="s-12" val={table.stats?.total} />
                    <Stat
                      label="Warning"
                      mb="s-12"
                      val={table.stats?.warning}
                      color={Token.color.orange}
                    />
                    <Stat
                      label="OK"
                      mb="s-12"
                      val={table.stats?.ok}
                      color={Token.color.green}
                    />
                  </HStack>
                </VStack>
              </TableWidget.Info>
              {!!table.stats?.warning && (
                <TableWidget.Filters>
                  <FilterButton
                    active={isFilteredByWarnings}
                    onClick={toggleFilterByWarnings}
                    disabled={!table.stats?.warning}
                  >
                    Show warnings
                  </FilterButton>
                </TableWidget.Filters>
              )}
              <TableWidget.Table>
                <AdjustableTable
                  {...table}
                  dataType="Employees"
                  name={TableNames.WorkScheduleEligibilityReview}
                  noDataMessage="No employees will be affected as auto-assign is disabled."
                  row={ROW}
                  useWindowScroll
                />
              </TableWidget.Table>
            </TableWidget>
          </Box>
        )}
      </VStack>
      <PageActions pt="s-64">
        <NewSaveButtonWithPopup
          useValidator
          hideWhenNoChanges={false}
          successText="Schedule was successfully saved"
          onAfterSubmit={() => {
            activateWorkSchedule(values.id)
            navigateReplace(
              pathToUrl(ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.PREVIEW, {
                id: String(values.id),
              }),
            )
          }}
        >
          Create & apply
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
