import React from 'react'
import { formatDate } from '@src/utils/format'
import ActionWidget from '@components/ActionWidget/ActionWidget'

interface ReapprovalBannerProps {
  dataType: string
  lastUpdateDate?: string
  actions?: React.ReactNode
}

const ReapprovalBanner = ({
  dataType,
  lastUpdateDate,
  actions,
}: ReapprovalBannerProps) => {
  return (
    <ActionWidget
      title="This is a re-approval"
      text={
        <>
          Some changes were made {lastUpdateDate && `on ${formatDate(lastUpdateDate)} `}
          after the {dataType.toLowerCase()} was approved. Kindly review the changes and
          approve/reject accordingly
        </>
      }
    >
      {actions}
    </ActionWidget>
  )
}

export default ReapprovalBanner
