import React from 'react'

import { BudgetDistributionProvider } from '@src/features/BudgetDistribution/BudgetDistribution'
import {
  getTeamCompensationReviews,
  useTeamCompensationYearOptions,
} from '@src/api/compensation'
import { TeamAndFunctionBudgetTable } from '@src/features/BudgetDistribution/TeamAndFunctionBudgetTable'

interface BudgetProps {
  navigation?: React.ReactElement
}

export default (props: BudgetProps) => (
  <BudgetDistributionProvider
    api={getTeamCompensationReviews}
    optionsApi={useTeamCompensationYearOptions}
  >
    <TeamAndFunctionBudgetTable {...props} />
  </BudgetDistributionProvider>
)
