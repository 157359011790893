import React, { useRef, useState } from 'react'
import {
  Flex,
  Text,
  FilterButtonSkeleton,
  FilterButton,
  Ellipsis,
  Icon,
} from '@revolut/ui-kit'
import FilterSelect, {
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { AsyncState } from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'

type Props = {
  onChange: (option: IdAndName) => void
  value: IdAndName | undefined
  options: IdAndName[]
  asyncState: AsyncState
}

export const SurveySelector = ({ onChange, value, options, asyncState }: Props) => {
  const [open, setOpen] = useState(false)

  const selectRef = useRef<HTMLButtonElement>(null)
  const isNewLayout = useIsNewLayout()

  if (asyncState !== 'ready') {
    return <FilterButtonSkeleton width={200} />
  }

  return (
    <>
      <FilterButton
        useIcon="Questionnaire"
        aria-haspopup="listbox"
        onClick={() => setOpen(!open)}
        aria-expanded={open}
        ref={selectRef}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{value?.name || 'Survey'}</Text>
          </Ellipsis>

          <Icon name={open ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <FilterSelect
        open={open}
        selector={() => Promise.resolve({ options })}
        anchorRef={selectRef}
        onClose={() => {
          setOpen(false)
        }}
        value={[value!]}
        onChange={newValue => {
          const [option] = newValue

          if (option) {
            setOpen(false)
            onChange(option)
          }
        }}
        type={FilterSelectType.SingleSelect}
        width={100}
        placement={isNewLayout ? 'bottom-end' : undefined}
      />
    </>
  )
}
