import React, { useState } from 'react'
import { BottomSheet, Button, Header } from '@revolut/ui-kit'
import {
  InterviewRecruiterInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { updateInterviewRound } from '@src/api/recruitment/interviews'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'

type Props = {
  onClose: () => void
  onSuccess: (recruiter: InterviewRecruiterInterface) => void
  round: InterviewRoundInterface
}

export const CVScreeningChangeRecruiter = ({ onClose, onSuccess, round }: Props) => {
  const [loading, setLoading] = useState(false)
  const [recruiter, setRecruiter] = useState<InterviewRecruiterInterface | null>(
    round.recruiter || null,
  )

  const onSave = async () => {
    if (!recruiter) {
      return
    }

    setLoading(true)

    try {
      await updateInterviewRound({
        id: round.id,
        recruiterId: recruiter.id,
      })

      onSuccess(recruiter)
    } finally {
      setLoading(false)
    }
  }

  return (
    <BottomSheet open onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Assign a new recruiter</Header.Title>
      </Header>
      <RadioSelectInput
        selector={selectorKeys.employee}
        label="Recruiter"
        value={recruiter}
        onChange={setRecruiter}
      />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onSave} disabled={!recruiter || loading} pending={loading}>
          Save
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
