import React, { useEffect, useState } from 'react'
import { InputGroup, Link } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { selectorKeys } from '@src/constants/api'
import { ManualScreeningChecksInterface } from '@src/interfaces/screeningChecksInterface'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Tooltip from '@components/Tooltip/Tooltip'
import { backUrl, subtitle, useRouteParams } from '../common'
import { PageHeader } from '@components/Page/Header/PageHeader'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import LapeNewDatePicker from '@src/components/Inputs/LapeFields/LapeNewDatePicker'
import { manualScreeningRequests } from '@src/api/screening'
import { LapeYesOrNoRadioSelect } from '@components/Inputs/LapeFields/LapeYesOrNoRadioSelect'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const NO_OPTION = { id: 0, name: 'No' }
const YES_OPTION = { id: 1, name: 'Yes' }

const PROVIDERS = [
  { id: 'HireRight', name: 'HireRight' },
  { id: 'Sterling', name: 'Sterling' },
  { id: 'Zinc', name: 'Zinc' },
  { id: '', name: 'Other' },
]

export const ManualScreeningForm = () => {
  const params = useRouteParams()
  const { initialValues, values, submit } =
    useLapeContext<Partial<ManualScreeningChecksInterface>>()

  const [isResultAvailable, setIsResultAvailable] = useState<IdAndName | null>(null)
  const [employee, setEmployee] = useState<EmployeeOptionInterface | undefined>()
  const [provider, setProvider] = useState<IdAndName<string> | null>(null)

  useEffect(() => {
    values.provider = provider?.id || initialValues.provider || ''
  }, [provider?.id])

  useEffect(() => {
    if (typeof initialValues.adjudication_needed === 'boolean') {
      setIsResultAvailable(YES_OPTION)
    }
    if (initialValues.provider) {
      const selectedProvider = PROVIDERS.find(
        item => item.id === initialValues.provider,
      ) || { id: '', name: 'Other' }
      setProvider(selectedProvider)
    }
  }, [])

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit screening check' : 'Add screening check'}
        backUrl={backUrl(params.employeeId)}
        subtitle={subtitle(params.employeeId)}
      />

      <PageBody>
        <AutoStepper>
          {!params.employeeId && (
            <>
              <NewStepperTitle title="Select employee" />
              <RadioSelectInput<EmployeeOptionInterface>
                label="Select employee"
                selector={selectorKeys.employee}
                value={employee}
                onChange={data => {
                  if (data) {
                    setEmployee(data)
                  }
                }}
              />
            </>
          )}
          <NewStepperTitle title="Screening check" />
          <InputGroup>
            <LapeRadioSelectInput
              name="check_type"
              label="Select screening type"
              selector={selectorKeys.screening_check_types}
            />
            <RadioSelectInput
              label="Provider"
              selector={() => Promise.resolve(PROVIDERS)}
              value={provider}
              onChange={value => setProvider(value)}
            />
            {provider?.id === '' && (
              <LapeNewInput required name="provider" label="Provider name" />
            )}
            <RadioSelectInput
              label="Is the result of the background check already available?"
              selector={() => Promise.resolve([YES_OPTION, NO_OPTION])}
              value={isResultAvailable}
              onChange={value => setIsResultAvailable(value)}
              message="Select ‘Yes’ only if you want to record results of the screening check that was already completed."
            />
          </InputGroup>

          {!!isResultAvailable?.id && (
            <>
              <NewStepperTitle title="Screening progress" />
              <InputGroup>
                <LapeYesOrNoRadioSelect
                  required
                  label="Adjudication needed?"
                  name="adjudication_needed"
                />
                <LapeNewInput
                  label="Adjudication status"
                  name="adjudication_status"
                  message="Fill in only if adjudication was requested."
                />
                <LapeNewDatePicker
                  required
                  name="completion_date_time"
                  label="Completed on"
                  message="Screening check completion date."
                />
              </InputGroup>
            </>
          )}

          <NewStepperTitle title="Proof" />
          <LapeNewInput
            name="link_to_proof"
            label="Enter here"
            renderAction={() =>
              initialValues.link_to_proof ? (
                <Link href={initialValues.link_to_proof} target="_blank">
                  <Tooltip text="Open" placement="top">
                    <LinkExternal cursor="pointer" size={16} color="primary" />
                  </Tooltip>
                </Link>
              ) : null
            }
          />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText="Screening successfully saved"
          previewUrl={employee ? undefined : ROUTES.FORMS.SCREENING.MANUAL.PREVIEW}
          onClick={async () => {
            if (employee) {
              const { data } = await manualScreeningRequests.submit(values, {
                employeeId: String(employee.id),
              })
              return navigateTo(
                pathToUrl(ROUTES.FORMS.SCREENING.MANUAL.PREVIEW, {
                  id: data.id,
                  employeeId: String(employee.id),
                }),
              )
            }
            return submit()
          }}
        />
      </PageActions>
    </>
  )
}
