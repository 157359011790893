import React from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import {
  ImportJobPostingsCellOptions,
  importJobPostingsTitleColumn,
  importJobPostingsSpecialisationColumn,
  importJobPostingsRecruiterColumn,
  importJobPostingsLocationsColumn,
  importJobPostingsAboutTheRoleColumn,
  importJobPostingsNiceToHaveColumn,
  importJobPostingsWhatYoullBeDoingColumn,
  importJobPostingsWhatYoullNeedColumn,
} from '@src/constants/columns/importJobPostings'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportJobPostingsDataInterface } from '@src/interfaces/importJobPostings'
import { fields, useOptions } from './sessionConfig'
import { ROUTES } from '@src/constants/routes'
import { jobPostingsConfig } from '../common/constants'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'

const row = (
  options: ImportJobPostingsCellOptions,
): Partial<RowInterface<ImportInterface<ImportJobPostingsDataInterface>>> => ({
  cells: [
    {
      ...importJobPostingsTitleColumn,
      width: 200,
    },
    {
      ...importJobPostingsSpecialisationColumn,
      width: 160,
    },
    {
      ...importJobPostingsRecruiterColumn(options),
      width: 130,
    },
    {
      ...importJobPostingsLocationsColumn,
      width: 100,
    },
    {
      ...importJobPostingsAboutTheRoleColumn,
      width: 150,
    },
    {
      ...importJobPostingsNiceToHaveColumn,
      width: 150,
    },
    {
      ...importJobPostingsWhatYoullBeDoingColumn,
      width: 150,
    },
    {
      ...importJobPostingsWhatYoullNeedColumn,
      width: 150,
    },
  ],
})

export const JobPostingsBulkUpload = () => {
  const options = useOptions()

  return (
    <OnboardingBulkDataUpload
      importRoute={ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.UPLOAD.IMPORT}
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.APPLICATION_FORM}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.ANY}
      apiEndpoint={API.JOB_POSTINGS_UPLOAD}
      config={jobPostingsConfig}
      currentStep="Upload job postings"
      category="job_posting_bulk_upload"
      name="job posting"
      row={row(options)}
      tableName={TableNames.ImportJobPostings}
      fields={fields}
      recommendedLabel="yes"
      yesByDefault
    />
  )
}
