import * as React from 'react'
import { connect } from 'lape'

import { useLapeField } from '@src/features/Form/LapeForm'
import NewCheckbox, { NewCheckboxProps } from '@components/Inputs/NewCheckbox/NewCheckbox'
import { BottomProps } from '@components/Inputs/partials/BottomText'
import { Cell, Box, CellProps } from '@revolut/ui-kit'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'

interface Props extends NewCheckboxProps, BottomProps {
  name: string
  message?: React.ReactNode
  optional?: boolean
  cellProps?: CellProps
  noCell?: boolean
}

const LapeSingleCheckbox = ({
  name,
  optional,
  message,
  cellProps = {},
  noCell,
  label,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const { value, onChange, hidden, error } = lapeProps

  if (hidden) {
    return null
  }

  const commonInputProps = getCommonInputProps(
    {
      name,
      required: !optional,
      placeholder: typeof label === 'string' ? label : undefined,
      ...props,
    },
    lapeProps,
    !!formValidator?.validated,
  )

  const checkbox = (
    <NewCheckbox
      onChange={e => {
        onChange(e.currentTarget.checked)
      }}
      checked={value}
      {...commonInputProps}
      {...props}
      label={label}
    />
  )

  return (
    <Box>
      {noCell ? (
        checkbox
      ) : (
        <Cell use="label" {...cellProps}>
          {checkbox}
        </Cell>
      )}
      <BottomInputMessage hasError={!!error} message={error || message} />
    </Box>
  )
}

export default connect(LapeSingleCheckbox)
