import React from 'react'
import upperFirst from 'lodash/upperFirst'
import {
  ActionButton,
  Avatar,
  Cell,
  Flex,
  HStack,
  Item,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FileInterface } from '@src/interfaces/files'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { getIconFromApprovalStep, getResignationLetterStatusColor } from '../common'
import { ApprovalFlowStep } from '@src/interfaces/approvalFlow'
import { formatDate } from '@src/utils/format'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  to: string
  employeeId: string
  resignationId: number
  file?: FileInterface | null
  canAdd?: boolean
  approval?: ApprovalFlowStep
}
export const LetterFileWithStatus = ({
  to,
  employeeId,
  resignationId,
  file,
  canAdd,
  approval,
}: Props) => {
  const user = useSelector(selectUser)
  const isThisUser = String(user.id) === employeeId

  return file ? (
    <Item use={InternalLink} to={to}>
      <Item.Avatar>
        <Avatar useIcon="Document">
          <Avatar.Badge
            useIcon={getIconFromApprovalStep({
              status: approval?.approval_status,
              color: 'white',
            })}
            color="background"
            backgroundColor={getResignationLetterStatusColor(approval?.approval_status)}
          />
        </Avatar>
      </Item.Avatar>
      <Item.Content>{file.name}</Item.Content>
      {approval && approval.approver && approval.approval_date_time && (
        <>
          <Item.Description>
            {upperFirst(approval.approval_status)} by {approval.approver.display_name} on{' '}
            {formatDate(approval.approval_date_time)}
          </Item.Description>
          {canAdd && isThisUser && approval.approval_status === 'rejected' && (
            <ActionButton
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER, {
                id: resignationId,
                employeeId,
              })}
              useIcon="Plus"
            >
              Add new
            </ActionButton>
          )}
        </>
      )}
      {approval && approval.approval_status && (
        <Item.Side>
          <Text
            textDecoration="none"
            color={getResignationLetterStatusColor(approval.approval_status)}
            fontSize="caption"
          >
            {upperFirst(approval.approval_status)}
          </Text>
        </Item.Side>
      )}
    </Item>
  ) : (
    <Cell>
      <VStack space="s-32">
        <Flex flex={1} alignItems="center" justifyContent="space-between">
          <HStack align="center" space="s-16">
            <Avatar useIcon="Document" color={Token.color.greyTone20} />
            <Text variant="secondary" color={Token.color.greyTone50} fontWeight="500">
              No resignation letter added
            </Text>
          </HStack>
        </Flex>
        {canAdd && isThisUser && (
          <ActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER, {
              id: resignationId,
              employeeId,
            })}
            useIcon="Plus"
          >
            Add new
          </ActionButton>
        )}
      </VStack>
    </Cell>
  )
}
