import { API, PerformanceTimeRange } from '@src/constants/api'
import { AxiosPromise } from 'axios'
import { PerformanceChartCycles } from '@src/interfaces/chart'
import { api } from './index'
import { getMonthsByRange } from '@src/utils/kpi'

export const getNipsForFunctionsGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.FUNCTIONS}/${id}/nips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForRolesGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.ROLES}/${id}/nips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForSpecialisationsGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.SPECIALISATIONS}/${id}/nips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForDepartmentGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.DEPARTMENT}/${id}/nips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForTeamGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.TEAMS}/${id}/nips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForLineManagerGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.EMPLOYEES}/${id}/lineManagerNips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })

export const getNipsForFunctionalManagerGraph = (
  id: number | string,
  range: PerformanceTimeRange = PerformanceTimeRange.week,
  cycleOffset?: string,
): AxiosPromise<PerformanceChartCycles> =>
  api.get(`${API.EMPLOYEES}/${id}/functionalManagerNips/history`, {
    params: {
      date_partitioning: range,
      months: getMonthsByRange(range),
      review_cycle__offset: cycleOffset,
    },
  })
