import React from 'react'

import { EmployeeInterface } from '@src/interfaces/employees'
import { CandidateProfile } from './CandidateProfile'
import { Email } from './Email'
import { Jira } from './Jira'
import { EmployeeButtonProps } from '../types'
import { ButtonsGroup } from '../ButtonsGroup'

type Props = {
  data: EmployeeInterface
  groupActions?: boolean
}
export const LinksGroupDropdown = ({ data, groupActions }: Props) => {
  const actionProps: EmployeeButtonProps = {
    data,
    isDropdownItem: !!groupActions,
  }

  return (
    <ButtonsGroup variant={groupActions ? 'grouped' : 'flat'}>
      <CandidateProfile {...actionProps} />
      <Email {...actionProps} />
      <Jira {...actionProps} />
    </ButtonsGroup>
  )
}
