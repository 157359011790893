import React from 'react'
import { Widget } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'

export const LoadingCard = () => {
  return (
    <Widget p="s-16">
      <Loader />
    </Widget>
  )
}
