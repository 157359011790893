import React, { useEffect, useMemo, useState } from 'react'
import { Flex, Subheader } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  OfferPlaceholderInterface,
  PredefinedPlaceholdersTableInterface,
} from '@src/interfaces/offerTemplates'
import { RowInterface } from '@src/interfaces/data'
import {
  placeholderLabelColumn,
  placeholderNameColumn,
  placeholderTypeColumn,
} from '@src/constants/columns/offerForm/offerTemplates'
import { offerPlaceholdersTableRequests } from '@src/api/offerTemplates'
import { TableNames } from '@src/constants/table'

type Props = {
  data: string[]
  isCustom?: boolean
}

const rows: RowInterface<PredefinedPlaceholdersTableInterface> = {
  cells: [
    {
      ...placeholderNameColumn,
      width: 300,
    },
    {
      ...placeholderLabelColumn,
      width: 300,
    },
    {
      ...placeholderTypeColumn,
      width: 150,
    },
  ],
}

const ExistingPlaceholdersTable = ({ data, isCustom }: Props) => {
  const [placeholders, setPlaceholders] = useState<OfferPlaceholderInterface[]>([])

  const tableData = useMemo<PredefinedPlaceholdersTableInterface[]>(() => {
    return data.reduce<PredefinedPlaceholdersTableInterface[]>((acc, key) => {
      const placeholder = placeholders.find(item => item.placeholder === key)

      if (placeholder) {
        acc.push({
          id: placeholder.id,
          name: key,
          label: placeholder.label || '-',
          type: placeholder.field_type,
        })
      }

      return acc
    }, [])
  }, [placeholders, data])

  useEffect(() => {
    const fetchPlaceholders = async () => {
      const placeholdersResp = await offerPlaceholdersTableRequests.getItems({
        filters: [
          {
            columnName: 'placeholder',
            nonResettable: true,
            filters: data.map(key => ({
              id: key,
              name: key,
            })),
          },
          {
            columnName: 'is_predefined',
            nonResettable: true,
            filters: [
              {
                id: isCustom ? 'False' : 'True',
                name: isCustom ? 'False' : 'True',
              },
            ],
          },
        ],
      })

      setPlaceholders(placeholdersResp.data.results)
    }

    fetchPlaceholders()
  }, [data])

  if (!tableData.length) {
    return null
  }

  return (
    <>
      <Subheader variant="nested">
        <Subheader.Title>{`${
          isCustom ? 'Custom' : 'Predefined'
        } placeholders detected`}</Subheader.Title>
      </Subheader>

      <Flex flexDirection="column" width="100%" data-testid="existing-placeholders-table">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<PredefinedPlaceholdersTableInterface>
            name={TableNames.OfferExistingPlaceholders}
            useWindowScroll
            row={rows}
            data={tableData}
            count={tableData.length}
            hideCount
          />
        </Flex>
      </Flex>
    </>
  )
}

export default ExistingPlaceholdersTable
