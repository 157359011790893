import { EmployeeInactivityInterface } from '@src/interfaces/employees'

export const isInactivityScheduled = (
  inactivity: EmployeeInactivityInterface | undefined,
) => {
  if (!inactivity) {
    return false
  }
  return (
    !!inactivity.inactivity_reason &&
    !!inactivity.inactivity_start_date &&
    !!inactivity.inactivity_end_date
  )
}
