import React from 'react'
import { Caption, Flex, Text, Item, ItemSkeleton, Status } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { UserAvatar } from '@components/UserWithAvatar/UserAvatar'
import { employeeModel } from '@src/features/GrowthPlans/GrowthPlan/models/employeeModel'

interface Props {
  employee?: EmployeeInterface
  isLoading: boolean
}

export const EmployeeItem = ({ employee, isLoading }: Props) => {
  if (isLoading) {
    return <ItemSkeleton />
  }

  if (!employee) {
    return null
  }

  const seniorityName =
    employeeModel.getCurrentSenioritySubLevelName(employee) ??
    employeeModel.getCurrentSeniorityName(employee)
  const specialisationName = employeeModel.getCurrentSpecialisationName(employee)

  return (
    <Item>
      <Item.Avatar size={56}>
        <UserAvatar
          id={employee.id}
          avatar={employee.avatar}
          full_name={employee.full_name}
          size={56}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <Text variant="h1">{employee.full_name}</Text>
        </Item.Title>
        <Item.Description>
          <Flex gap="s-8">
            {specialisationName && (
              <Status useIcon="RepairTool" iconSize={15} use={Caption}>
                {specialisationName}
              </Status>
            )}
            {seniorityName && (
              <Status useIcon="People" iconSize={15} use={Caption}>
                {seniorityName}
              </Status>
            )}
          </Flex>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
