import React from 'react'

import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { HubAppWithOnboardingScreen } from '@src/features/HubApp/HubAppWithOnboardingScreen'

const KPIs = () => {
  const { data: performanceSettings } = useGetPerformanceSettings()

  const tabs = APPLICATIONS.kpis.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { performanceSettings }),
  )

  return (
    <HubAppWithOnboardingScreen category="goals" app={{ ...APPLICATIONS.kpis, tabs }} />
  )
}

export default KPIs
