import Form from '@src/features/Form/Form'
import { jiraPreferencesRequests } from '@src/api/integrations'
import React from 'react'
import { JiraForm } from '@src/pages/Hub/Integrations/Jira/Jira'
import { JiraForm as JiraFormV2 } from '@src/pages/Hub/Integrations/Jira/JiraFormV2'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { FeatureFlags } from '@src/store/auth/types'
import { UpdateTypes } from '@src/interfaces/kpis'
import { serverNameValidators } from '@src/pages/Forms/GoalForm/Form/GoalMetricForm/constants'

const JiraIntegration = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const isJiraOauth = featureFlags?.includes(FeatureFlags.JiraOauthIntegration)

  if (isJiraOauth) {
    return (
      <Form
        api={jiraPreferencesRequests}
        forceParams={{ id: '1' }}
        validator={serverNameValidators[UpdateTypes.roadmap]}
      >
        <JiraFormV2 />
      </Form>
    )
  }

  return (
    <Form api={jiraPreferencesRequests} forceParams={{ id: '1' }}>
      <JiraForm />
    </Form>
  )
}

export default JiraIntegration
