import React, { useState } from 'react'
import { Box, Color, Copyable, Flex, List as UIList, Text } from '@revolut/ui-kit'
import { ChevronDownSmall, ChevronRight, List, Materials } from '@revolut/icons'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SCHEMA_NODE_MARGIN } from '../constants'

interface TableInterface {
  columns: {
    name: string
    type: string
  }[]
  type: string
}

interface SchemaInterface {
  [key: string]: TableInterface
}

export interface CatalogInterface {
  [key: string]: SchemaInterface
}

interface TreeNodeProps {
  level: number
  name: string
  schema?: SchemaInterface
  schemaName?: string
  table?: TableInterface
}

interface TreeProps {
  catalogData?: CatalogInterface
  level?: number
  schemaData?: SchemaInterface
  schemaName?: string
}

const TreeNode = ({ level, name, schema, schemaName, table }: TreeNodeProps) => {
  const [showChildren, setShowChildren] = useState(false)

  const handleClick = () => {
    setShowChildren(!showChildren)
  }

  const renderIconTypeChevron = () => {
    if (showChildren) {
      return <ChevronDownSmall size={16} color={Color.GREY_TONE_50} />
    }

    return <ChevronRight size={16} color={Color.GREY_TONE_50} />
  }

  const renderChildren = () => {
    if (table) {
      return (
        <UIList
          marginTop="s-16"
          space="s-16"
          variant="compact"
          style={{ marginLeft: `${(level + 2) * SCHEMA_NODE_MARGIN}px` }}
        >
          {table.columns.map((column: { name: string }) => (
            <Copyable
              key={column.name}
              onCopy={() => {
                pushNotification({
                  duration: SUCCESS_DEFAULT_DURATION,
                  type: NotificationTypes.success,
                  value: 'Column path copied to clipboard',
                })
              }}
              value={`${schemaName}.${name}.${column.name}`}
            >
              <UIList.Item>
                <Flex alignItems="center" gap="s-12">
                  <List color={Color.GREY_20} size={14} />
                  <Text ml="s-8" variant="primary">
                    {column.name}
                  </Text>
                </Flex>
              </UIList.Item>
            </Copyable>
          ))}
        </UIList>
      )
    }

    return <DatabaseSchema schemaData={schema} schemaName={name} level={level + 1} />
  }

  return (
    <UIList.Item>
      <Flex alignItems="center" gap="s-12" onClick={handleClick}>
        <Box minWidth={16}>{renderIconTypeChevron()}</Box>
        {schema && <Materials color={Color.BLUE} size={18} />}
        {table && <List color={Color.DEEP_GREY} size={14} />}
        <Text variant="primary">{name}</Text>
      </Flex>
      {showChildren && renderChildren()}
    </UIList.Item>
  )
}

export const DatabaseSchema = ({
  catalogData,
  level = 0,
  schemaData,
  schemaName,
}: TreeProps) => {
  if (!catalogData && !schemaData) {
    return null
  }

  return (
    <UIList
      marginTop="s-16"
      space="s-16"
      style={{ marginLeft: `${level * SCHEMA_NODE_MARGIN}px` }}
      variant="compact"
    >
      {catalogData &&
        Object.keys(catalogData).map(key => (
          <TreeNode key={key} level={level} name={key} schema={catalogData[key]} />
        ))}
      {schemaData &&
        Object.keys(schemaData).map(key => (
          <TreeNode
            key={key}
            level={level}
            name={key}
            schemaName={schemaName}
            table={schemaData[key]}
          />
        ))}
    </UIList>
  )
}
