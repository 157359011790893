import { Box, Header, Popup } from '@revolut/ui-kit'
import { kpisRequestsNew } from '@src/api/kpis'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { KPITypes } from '@src/constants/table'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { TeamInterface } from '@src/interfaces/teams'
import New from '@src/pages/Forms/KpiForm/New'
import SelectKPIType from '@src/pages/Forms/KpiForm/SelectKPIType'
import React, { ReactNode, useEffect, useState } from 'react'

export const FormPopup = ({
  onClose,
  onAfterSubmit,
  defaultTeam,
}: {
  onClose: () => void
  onAfterSubmit: () => void
  defaultTeam?: TeamInterface
}) => {
  const [title, setTitle] = useState<ReactNode>('Select KPI type')
  const { values } = useLapeContext<KpiInterface>()

  useEffect(() => {
    values.kpi_type = { id: KPITypes.team_kpi, name: 'Team' }
  }, [])

  useEffect(() => {
    if (defaultTeam) {
      values.team = defaultTeam
    }
  }, [defaultTeam])

  const typeSelected = !!values.update_type || values.is_inherited

  useEffect(() => {
    if (typeSelected) {
      setTitle('KPI form')
    }
  }, [typeSelected])

  const form = typeSelected ? (
    <>
      {defaultTeam ? null : (
        <LapeRadioSelectInput name="team" label="Team" selector={selectorKeys.team} />
      )}
      <New
        hideSettingsButtons
        ignoreAfterRedirect
        onAfterSubmit={() => {
          onAfterSubmit()
          onClose()
        }}
      />
    </>
  ) : (
    <Box minHeight={400}>
      <SelectKPIType
        useLocalStorageCaching={false}
        onSelect={type => {
          if (type === 'inherit') {
            values.is_inherited = true
          } else {
            values.update_type = type
            values.is_inherited = false
          }
        }}
      />
    </Box>
  )

  return (
    <Popup open onClose={onClose} size="md">
      <Header displayMode="inline">
        <Header.CloseButton aria-label="close" />
        <Header.Title>{title}</Header.Title>
      </Header>
      {form}
    </Popup>
  )
}

export const KpiForm = ({
  open,
  onClose,
  onAfterSubmit,
  defaultTeam,
}: {
  open: boolean
  onClose: () => void
  onAfterSubmit: () => void
  defaultTeam?: TeamInterface
}) => {
  return open ? (
    <Form
      forceParams={{ id: undefined }}
      api={kpisRequestsNew}
      disableLocalStorageCaching
    >
      <FormPopup
        defaultTeam={defaultTeam}
        onAfterSubmit={onAfterSubmit}
        onClose={onClose}
      />
    </Form>
  ) : null
}
