import React from 'react'

import { ROUTES } from '@src/constants/routes'
import Preview from './Preview'
import { PermissionTypes } from '@src/store/auth/types'
import Edit from '@src/pages/Settings/General/Edit'
import { companySettings } from '@src/api/settings'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'

const routes = [
  {
    title: 'Company preferences',
    path: ROUTES.SETTINGS.GENERAL.PREVIEW,
    url: ROUTES.SETTINGS.GENERAL.PREVIEW,
    canView: [
      PermissionTypes.ViewCompanySettings,
      PermissionTypes.CanManageCompanyLogo,
      PermissionTypes.ChangeCompanyPreferences,
    ],
    component: Preview,
  },
  {
    title: 'Edit company info',
    path: ROUTES.SETTINGS.GENERAL.EDIT,
    url: ROUTES.SETTINGS.GENERAL.EDIT,
    canView: [PermissionTypes.ChangeCompanySettings],
    hideInTabs: true,
    component: Edit,
  },
]

export const GeneralSettings = () => {
  return <SettingsForm routes={routes} api={companySettings} />
}
