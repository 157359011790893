import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces/index'
import { FileInterface } from '@src/interfaces/files'

export type TaggedUsersMap = {
  [id: number]: EmployeeOptionInterface
}

export enum CommentStatus {
  active = 'active',
  completed = 'completed',
  archived = 'archived',
}

export interface CommentInterface<T> {
  id: number
  object_id: number
  body: string
  category: IdAndName<T>
  created_by: EmployeeOptionInterface
  creation_date_time: string
  update_date_time: string
  edit_date_time: string | null
  create_task: boolean
  status: IdAndName<CommentStatus>
  tagged_employees: TaggedUsersMap
  attached_files: FileInterface[]
}
