import React, { useEffect, useState } from 'react'
import { Box, Text } from '@revolut/ui-kit'

import {
  ProbationCheckpoint,
  ProbationRecommendationInterface,
} from '@src/interfaces/probationReview'
import { getProbationRecommendations } from '@src/api/probationReview'
import RecommendationReviewers from '@src/pages/Forms/ProbationOverview/Recommendations/RecommendationReviewers'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AvatarItemSkeleton from '@components/Skeletons/AvatarItemSkeleton'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import GenericTimelineStep from './GenericTimelineStep'

type Props = {
  checkpoint: ProbationCheckpoint
  employeeId: number
  cycleId: string
}

const TimelineRecommendationCheckpoint = ({ checkpoint, employeeId, cycleId }: Props) => {
  const [recommendations, setRecommendations] =
    useState<ProbationRecommendationInterface[]>()
  const [loading, setLoading] = useState(false)

  const fetchRecommendations = async () => {
    setLoading(true)
    const resp = await getProbationRecommendations(employeeId, cycleId)
    setRecommendations(resp.data.results)
    setLoading(false)
  }

  useEffect(() => {
    fetchRecommendations()
  }, [])

  const renderContent = () => {
    if (!recommendations?.length) {
      return (
        <>
          <Text display="block">No recommendations</Text>
          <InternalUIKitLink
            variant="h6"
            // @ts-expect-error object works fine here, but UI kit expects string
            to={pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.RECOMMENDATIONS, {
              employeeId,
              cycleId,
            })}
          >
            More Details
          </InternalUIKitLink>
        </>
      )
    }

    return (
      <>
        <RecommendationReviewers recommendations={recommendations} />{' '}
        <InternalUIKitLink
          variant="h6"
          // @ts-expect-error object works fine here, but UI kit expects string
          to={pathToUrl(ROUTES.FORMS.PROBATION_OVERVIEW.RECOMMENDATIONS, {
            employeeId,
            cycleId,
          })}
        >
          More Details
        </InternalUIKitLink>
      </>
    )
  }

  return (
    <GenericTimelineStep
      title="Manager recommendation"
      checkpointDate={checkpoint.checkpoint_date_time}
    >
      <Box mt="s-12" mb="s-12">
        {loading ? (
          <Box>
            <AvatarItemSkeleton px={0} />
            <AvatarItemSkeleton px={0} />
          </Box>
        ) : (
          renderContent()
        )}
      </Box>
    </GenericTimelineStep>
  )
}

export default TimelineRecommendationCheckpoint
