import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Group, IconButton, MoreBar, Token } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { EditButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { selectPermissions } from '@src/store/auth/selectors'
import { Subcategories } from './Subcategories'

export const ServiceDeskCategoryPreview = () => {
  const params = useParams()
  const permissions = useSelector(selectPermissions)
  const { initialValues } = useLapeContext<ServiceDeskCategoryInterface>()
  const canEdit = permissions.includes(PermissionTypes.ChangeFaq)

  return (
    <>
      <PageHeader
        backUrl={ROUTES.APPS.HELP_CENTER.SD_CATEGORIES_TABLE}
        title={initialValues.name}
      />
      <PageBody>
        <MoreBar>
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.APPS.HELP_CENTER.SD_CATEGORY.EDIT, params)}
          />
        </MoreBar>
        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Item title="Category Name" field="name" />
            <FormPreview.Item title="FAQ Category" field="faq_category.name" />
            <FormPreview.Item
              title="Link"
              field="link"
              insert={() =>
                initialValues.link ? (
                  <IconButton
                    use="a"
                    target="_blank"
                    href={initialValues.link}
                    size={16}
                    color={Token.color.blue}
                    useIcon="LinkExternal"
                  />
                ) : (
                  '-'
                )
              }
            />
          </Group>
        </FormPreview>
        <FormPreview title="Subcategories">
          <Subcategories />
        </FormPreview>
      </PageBody>
    </>
  )
}
