import React, { useEffect, useRef, useState } from 'react'
import {
  DeliverableOptions,
  deliverableOptionToFinalGrade,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import {
  CardContentTypes,
  CommonCardProps,
  DeliverableGradeOption,
  getKpiGrades,
  PerfRatingToGraphNumber,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get, set } from 'lodash'
import { HelpTabs } from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CombinedHelp'
import { KPI } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/KPI'
import { getGradeFromPerformance } from '@src/constants/columns/kpi'
import isNumber from 'lodash/isNumber'
import { useRecommendedGradesContext } from '@src/pages/Forms/EmployeePerformanceLayout/ScorecardContent'
import { getGradesWithExpectations } from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { IndividualGoalsList } from '@src/pages/Forms/EmployeePerformanceLayout/components/IndividualGoalsList'
import { GoalsInterface } from '@src/interfaces/goals'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

interface KPIsCardInterface extends CommonCardProps {
  setMissingKpiJustification?: React.Dispatch<React.SetStateAction<boolean>>
  showBeforeSubmitCheckError?: boolean
}

export const KPIsCard = connect(
  ({
    onHelpClick,
    gradesMap,
    setMissingKpiJustification,
    showBeforeSubmitCheckError,
  }: KPIsCardInterface) => {
    const { values, errors } = useLapeContext<ReviewScorecardInterface>()
    const hasKPI = !!values.review_data.kpis_section
    const ref = useRef<HTMLDivElement>(null)
    const { grades } = useRecommendedGradesContext()

    const featureFlags = useSelector(selectFeatureFlags)
    const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

    const [gradeRecommendation, setGradeRecommendation] =
      useState<DeliverableOptions | null>(null)
    const [goalsStat, setGoalsStat] = useState<number>()
    const [goalsList, setGoalsList] = useState<GoalsInterface[]>()

    useEffect(() => {
      if (errors.review_data?.kpis_section || showBeforeSubmitCheckError) {
        ref?.current?.scrollIntoView({ behavior: 'smooth' })
      }
    }, [errors.review_data?.kpis_section, showBeforeSubmitCheckError])

    const getGoalsPerformance = (goals?: GoalsInterface[]) => {
      if (!goals || !goals.length) {
        return 0
      }

      const progressSum = goals.reduce(
        (acc, item) => acc + item.progress * item.weight,
        0,
      )
      const weightSum = goals.reduce((acc, item) => acc + item.weight, 0)

      return progressSum / weightSum
    }

    useEffect(() => {
      if (values.review_data.kpis_section) {
        const missingValue = !values.review_data.kpis_section.recommended_rating
        const performance = goalsEnabled
          ? getGoalsPerformance(goalsList)
          : values.review_data.kpis_section.performance
        if (isNumber(performance)) {
          const deliverableOption = getGradeFromPerformance(
            performance,
            gradesMap,
          ).deliverable

          setGradeRecommendation(deliverableOption)
          if (!values.review_data.kpis_section.section_grade) {
            values.review_data.kpis_section.section_grade = deliverableOptionToFinalGrade(
              values.review_data.kpis_section.recommended_rating || deliverableOption,
            )
          }
          if (missingValue) {
            values.review_data.kpis_section.recommended_rating = deliverableOption
          }
        }
      }
    }, [values.review_data.kpis_section, goalsList])

    if (!hasKPI) {
      return null
    }

    const kpiGrades = getKpiGrades(gradesMap, true)
    const isSelfReview = values.reviewer_relation === ReviewerRelation.Self
    const finalRating =
      !isSelfReview && grades?.kpiGrade ? gradesMap[grades?.kpiGrade] : undefined

    const gradeValue = values.review_data.kpis_section?.recommended_rating
    const missingJustification = !values.review_data.kpis_section?.comment
    if (gradeRecommendation && gradeValue && missingJustification) {
      const recommendationScore = PerfRatingToGraphNumber[gradeRecommendation]
      const valueScore = PerfRatingToGraphNumber[gradeValue]
      setMissingKpiJustification?.(valueScore > recommendationScore)
    } else {
      setMissingKpiJustification?.(false)
    }

    const kpiGradesWithExpectations = getGradesWithExpectations(
      kpiGrades,
      gradeRecommendation,
    )

    const onSelectGrade = (
      reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
      grade: DeliverableGradeOption,
    ) => {
      set(reviewData, `kpis_section.recommended_rating`, grade.key)
      set(
        reviewData,
        `kpis_section.section_grade`,
        deliverableOptionToFinalGrade(grade.key),
      )
    }

    return (
      <Card
        data={values}
        renderExpandedContent={() => (
          <KPI
            reviewData={values.review_data}
            kpiGrades={kpiGradesWithExpectations}
            onSelectGrade={onSelectGrade}
            gradesMap={gradesMap}
          />
        )}
        renderExceedingContent={() => (
          <KPI
            reviewData={values.review_data}
            kpiGrades={kpiGradesWithExpectations}
            showJustificationError={showBeforeSubmitCheckError && missingJustification}
            justificationOnly
            gradesMap={gradesMap}
          />
        )}
        additionalInfo={
          <IndividualGoalsList
            gradesMap={gradesMap}
            setGoalsStat={setGoalsStat}
            setGoalsList={setGoalsList}
            employeeId={values.reviewed_employee.id}
            cycle={values.cycle}
          />
        }
        type={CardContentTypes.KPI}
        title="Goals"
        stat={goalsStat}
        finalRating={finalRating}
        icon="Target"
        fields={[
          {
            field: 'review_data.kpis_section',
            title: 'Goal performance',
            grades: kpiGradesWithExpectations,
            cardJustification: values.review_data.kpis_section?.comment || null,
          },
        ]}
        onSelectDeliverableGrade={grade => onSelectGrade(values.review_data, grade)}
        isGradeSelectedRule={(field, grade) => {
          const ratingValue = get(values, field)?.recommended_rating
          return !!ratingValue && ratingValue === grade.key
        }}
        headerRef={ref}
        onHelpClick={() => onHelpClick?.(HelpTabs.Deliverables)}
      />
    )
  },
)
