import { useQueryClient } from 'react-query'

import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import {
  InviteTeamResponse,
  InviteTeamRequestInterface,
  CompanyConfigurationSetup,
  CompanyConfigurationSetupCategory,
  CompanyConfigurationTaskSlug,
} from '@src/interfaces/platformOnboarding'
import { useFetch } from '@src/utils/reactQuery'

export const inviteTeamMembers = (data: InviteTeamRequestInterface[]) =>
  apiWithoutHandling.post<InviteTeamResponse>(
    `${API.EMPLOYEES}/invite`,
    data,
    undefined,
    'v2',
  )

export const useCompanyConfigurationSetup = () => {
  const queryClient = useQueryClient()
  const { data, isLoading } = useFetch<GetRequestInterface<CompanyConfigurationSetup>>(
    `${API.COMPANY}/setup`,
  )
  const steps = data?.results
  const mandatoryStep = steps?.find(step => step.category === 'mandatory')
  const importantStep = steps?.find(step => step.category === 'important')
  const recommendedStep = steps?.find(step => step.category === 'recommended')

  const completedTasks =
    steps?.reduce<CompanyConfigurationTaskSlug[]>(
      (tasks, step) => [
        ...tasks,
        ...step.tasks.filter(task => task.state === 'COMPLETED').map(task => task.slug),
      ],
      [],
    ) || []

  const completeStep = (category: CompanyConfigurationSetupCategory) => {
    if (data) {
      queryClient.setQueryData([`${API.COMPANY}/setup`, 'v1', null], {
        ...data,
        results: steps?.map(step => {
          if (category === step.category) {
            return {
              ...step,
              completed: true,
            }
          }
          return step
        }),
      })
    }
  }

  return {
    steps,
    mandatoryStep,
    importantStep,
    recommendedStep,
    isLoading,
    completeStep,
    completedTasks,
  }
}

export const completeCompanyConfigurationStep = (
  category: CompanyConfigurationSetupCategory,
) =>
  api.patch<{ completed: boolean }>(`${API.COMPANY}/setup/${category}`, {
    completed: true,
  })

export const completeCompanyConfigurationImportantStepTask = (
  slug: CompanyConfigurationTaskSlug,
) => apiWithoutHandling.post(`${API.COMPANY}/setup/important/completeTask`, { slug })

export const bulkActivationEmail = (data: { id: number }[]) =>
  apiWithoutHandling.post<{ success: boolean }>(`/account/bulkActivationEmail`, data)
