import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'
import { select } from 'd3-selection'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { HierarchyPointNode } from 'd3-hierarchy'
import { lineBreak } from '@components/Hierarchy/utils'

const LineHeight = 16
const MarginTop = 37

export const addNameAndTitle = (
  selection: AddSelection,
  t: HierarchyTransition,
  onTitleClick?: (d: HierarchyNodeInterface) => void,
) => {
  /* Name */
  selection
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('class', 'Title')
    .text(d => d.data.full_name)
    .attr('x', d => d?.parent?.x ?? d.x)
    .attr('y', d => (d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight + MarginTop)
    .on('click', d => {
      onTitleClick?.(d.data)
    })
    .transition(t)
    .attr('x', d => d.x)
    .attr('y', d => d.y - HierarchyConsts.cardHeight + MarginTop)
    .each((datum, index, nodes) => {
      const s = select(nodes[index])
      const width = nodes[index]?.getBBox().width
      if (width && HierarchyConsts.cardWidth - 7 < width) {
        s.text(`${datum.data.full_name.substring(0, 16)}...`)
      }
    })

  /* Role */
  selection
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('class', 'Subtitle')
    .text(d => d.data.job_title || '')
    .attr('x', d => d?.parent?.x ?? d.x)
    .attr(
      'y',
      d => (d?.parent?.y ?? d.y) - HierarchyConsts.cardHeight + MarginTop + LineHeight,
    )
    .transition(t)
    .attr('x', d => d.x)
    .attr('y', d => d.y - HierarchyConsts.cardHeight + MarginTop + LineHeight)
    .each((datum: { data: HierarchyNodeInterface }, index: number, nodes) => {
      const s = select<SVGTextElement, HierarchyPointNode<HierarchyNodeInterface>>(
        nodes[index],
      )
      const width = nodes[index]?.getBBox().width
      if (width && HierarchyConsts.cardWidth - 7 < width) {
        const lineBreakArr = lineBreak(datum.data.job_title || '', 18)
        s.text('')
          .append('tspan')
          .attr('class', 'Tspan1')
          .attr('x', d => d?.parent?.x ?? d.x)
          .call(call => call.transition(t).attr('x', d => d.x))
          .attr('dy', 0)
          .text(lineBreakArr[0])
          .append('tspan')
          .attr('class', 'Tspan2')
          .attr('x', d => d?.parent?.x ?? d.x)
          .call(call => call.transition(t).attr('x', d => d.x))
          .attr('dy', 14)
          .text(lineBreakArr[1])
      }
    })
}

export const updateNameAndTitle = (
  selection: UpdateSelection,
  t: HierarchyTransition,
) => {
  /* Name */
  selection
    .select('.Title')
    .transition(t)
    .attr('x', d => d.x)
    .attr('y', d => d.y - HierarchyConsts.cardHeight + MarginTop)

  /* Role */
  selection
    .select('.Subtitle')
    .transition(t)
    .attr('x', d => d.x)
    .attr('y', d => d.y - HierarchyConsts.cardHeight + MarginTop + LineHeight)

  selection
    .select('.Tspan1')
    .transition(t)
    .attr('x', d => d.x)

  selection
    .select('.Tspan2')
    .transition(t)
    .attr('x', d => d.x)
}
