import React from 'react'
import styled from 'styled-components'
import { Checkbox, Input, Text, Token, VStack } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { Grid } from '@components/CommonSC/Grid'
import { OptionInterface } from '@src/interfaces/selectors'
import { FormError } from '@src/features/Form/LapeForm'
import isBoolean from 'lodash/isBoolean'
import { useIsNewTable } from '@components/TableV2/hooks'

const Wrap = styled(Grid)`
  grid-template-columns: repeat(3, 33%);
  grid-column-gap: 8px;
  padding-right: 8px;
`

type FieldNamesType<T> = {
  amount?: keyof T
  frequency?: keyof T
  currency?: keyof T
  declinedToDisclose?: keyof T
  additionalCommentary?: keyof T
}

type Props<T> = {
  label: string
  optional?: boolean
  onChangeCurrency?: (val: OptionInterface | null) => void
  onChangeFrequency?: (val: OptionInterface | null) => void
  onChangeAmount?: (val?: number) => void
  onChangeAdditionalCommentary?: (val?: string | null) => void
  onChangeDeclineToDisclose?: (val?: boolean) => void
  amount?: number | null
  frequency?: OptionInterface | null
  currency?: OptionInterface | null
  additionalCommentary?: string | null
  additionalCommentaryLabel: string
  declinedToDisclose?: boolean | null
  names?: FieldNamesType<T>
  errors?: FormError<T>
  disabled?: boolean
}

const SalaryExpectationField = <T,>({
  label,
  optional,
  onChangeAmount,
  onChangeFrequency,
  onChangeCurrency,
  onChangeAdditionalCommentary,
  onChangeDeclineToDisclose,
  amount,
  frequency,
  currency,
  additionalCommentary,
  additionalCommentaryLabel,
  declinedToDisclose,
  names,
  errors,
  disabled,
}: Props<T>) => {
  const isNewTable = useIsNewTable()

  const getBaseProps = (field: keyof FieldNamesType<T>) => {
    const name = names?.[field]
    return {
      'data-name': name,
      'aria-invalid': name ? !!errors?.[name] : false,
      hasError: name ? !!errors?.[name] : false,
      message: name ? errors?.[name] : undefined,
      disabled,
    }
  }
  const getCommonProps = (field: keyof FieldNamesType<T>) => {
    const baseProps = getBaseProps(field)
    return {
      ...baseProps,
      disabled: isBoolean(declinedToDisclose) ? declinedToDisclose : baseProps.disabled,
    }
  }

  const currencyProps = getCommonProps('currency')
  const frequencyProps = getCommonProps('frequency')
  const amountProps = getCommonProps('amount')
  const additionalCommentaryProps = getBaseProps('additionalCommentary')
  const declinedToDiscloseProps = getBaseProps('declinedToDisclose')

  const optionalPostfix = optional ? ' (optional)' : ''
  return (
    <VStack gap="s-16">
      {isNewTable ? (
        <Text variant="body2" color={Token.color.greyTone50}>
          {label}
        </Text>
      ) : (
        <Text variant="secondary" color={Token.color.greyTone50}>
          {label}
        </Text>
      )}

      <Wrap>
        <RadioSelectInput<OptionInterface>
          selector={selectorKeys.currencies}
          label={`Currency${optionalPostfix}`}
          value={currency}
          onChange={val => {
            onChangeCurrency?.(val)
          }}
          inputProps={{
            'data-name': currencyProps['data-name'] as string,
          }}
          {...currencyProps}
        />
        <RadioSelectInput<OptionInterface>
          selector={selectorKeys.interview_feedback_salary_frequency_choices}
          label={`Frequency${optionalPostfix}`}
          value={frequency}
          onChange={val => {
            onChangeFrequency?.(val)
          }}
          inputProps={{
            'data-name': frequencyProps['data-name'] as string,
          }}
          {...frequencyProps}
        />
        <Input
          label={`Amount${optionalPostfix}`}
          type="number"
          value={amount || undefined}
          onChange={e => {
            const val = e.currentTarget.value
            onChangeAmount?.(val === '' ? undefined : +val)
          }}
          {...amountProps}
        />
      </Wrap>
      <Input
        label={additionalCommentaryLabel}
        type="text"
        value={additionalCommentary || undefined}
        onChange={e => {
          const val = e.currentTarget.value
          onChangeAdditionalCommentary?.(val === '' ? undefined : val)
        }}
        {...additionalCommentaryProps}
      />
      <Checkbox
        checked={!!declinedToDisclose}
        onChange={e => {
          const val = e.currentTarget.checked
          onChangeDeclineToDisclose?.(val)
        }}
        {...declinedToDiscloseProps}
      >
        <Checkbox.Label>Candidate declined to provide information</Checkbox.Label>
      </Checkbox>
    </VStack>
  )
}

export default SalaryExpectationField
