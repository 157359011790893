import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'

type HeaderItemProps = {
  title?: string
}

export const HeaderItem = ({ title }: HeaderItemProps) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Newspaper" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title ?? 'Job Posting'}</Item.Title>
        {!!title && <Item.Description>Job posting overview</Item.Description>}
      </Item.Content>
    </Item>
  )
}
