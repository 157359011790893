import * as React from 'react'
import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import { useState } from 'react'
import { uploadGrades } from '@src/api/supportTool/calibratedGrades'
import { uploadEmployees } from '@src/api/supportTool/eligibleEmployees'
import { Popup, Header, Box, Button } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'

export enum UploadPopupType {
  Grades = 'grades',
  EligibleEmployees = 'eligible_employees',
}

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  type: UploadPopupType
}

const UploadPopup = ({ open, onClose, onSubmit, type }: Props) => {
  const [file, setFile] = useState<File | null>()
  const [uploading, setUploading] = useState(false)

  const handleUpload = () => {
    if (file) {
      setUploading(true)

      switch (type) {
        case UploadPopupType.EligibleEmployees: {
          uploadEmployees(file)
            .then(onSubmit)
            .finally(() => {
              setUploading(false)
              onClose()
            })
          break
        }

        case UploadPopupType.Grades: {
          uploadGrades(file)
            .then(onSubmit)
            .finally(() => {
              setUploading(false)
              onClose()
            })
        }
      }
    }
  }

  return (
    <Popup
      variant="bottom-sheet"
      open={open}
      onClose={onClose}
      closeOnOverlayClick={!uploading}
    >
      <Header variant="bottom-sheet">
        <Header.Title>Upload document</Header.Title>
      </Header>
      {type === UploadPopupType.Grades && (
        <ActionWidget
          title={
            uploading
              ? 'Your file is being processed; this process can take up to 5 minutes.'
              : 'Uploading a new file will override the existing data in the table'
          }
        />
      )}
      <Box mt="s-16">
        <FileUploader
          value={file}
          onChange={data => {
            setFile(data as File | null)
          }}
          disabled={uploading}
        />
      </Box>
      <Popup.Actions>
        <Button variant="secondary" onClick={onClose} disabled={uploading}>
          Cancel
        </Button>
        <Button onClick={handleUpload} disabled={!file || uploading} pending={uploading}>
          Upload and Run
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default UploadPopup
