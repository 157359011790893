import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { initialConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { InitialIntro } from '@src/pages/OnboardingChecklist/Initial/InitialIntro'
import InitialCompanyPreferences from '@src/pages/OnboardingChecklist/Initial/InitialCompanyPreferences'
import { companyPreferencesWithInvalidation, companySettings } from '@src/api/settings'
import InitialIntegrations from '@src/pages/OnboardingChecklist/Initial/InitialIntegrations'
import InitialHrApps from '@src/pages/OnboardingChecklist/Initial/InitialHrApps'
import InitialPerformanceApps from '@src/pages/OnboardingChecklist/Initial/InitialPerformanceApps'
import InitialRecruitmentApps from '@src/pages/OnboardingChecklist/Initial/InitialRecruitmentApps'
import { QueryClient, useQueryClient } from 'react-query'

const routes = (queryClient: QueryClient) => [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.INTRO,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialIntro,
  },
  {
    title: 'Company preferences',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.PREFERENCES,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.PREFERENCES,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialCompanyPreferences,
    form: {
      api: companySettings,
    },
  },
  {
    title: 'Integrations',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.INTEGRATIONS,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.INTEGRATIONS,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialIntegrations,
    form: {
      api: companyPreferencesWithInvalidation(queryClient),
    },
  },
  {
    title: 'HR apps',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.HR_APPS,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.HR_APPS,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialHrApps,
    form: {
      api: companyPreferencesWithInvalidation(queryClient),
    },
  },
  {
    title: 'Performance apps',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.PERFORMANCE_APPS,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.PERFORMANCE_APPS,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialPerformanceApps,
    form: {
      api: companyPreferencesWithInvalidation(queryClient),
    },
  },
  {
    title: 'Recruitment apps',
    path: ROUTES.ONBOARDING_CHECKLIST.INITIAL.RECRUITMENT_APPS,
    url: ROUTES.ONBOARDING_CHECKLIST.INITIAL.RECRUITMENT_APPS,
    canView: [PermissionTypes.ViewCompanySettings],
    component: InitialRecruitmentApps,
    form: {
      api: companyPreferencesWithInvalidation(queryClient),
    },
  },
]

const Initial = () => {
  const queryClient = useQueryClient()

  return (
    <OnboardingChecklistContent config={initialConfig} routes={routes(queryClient)} />
  )
}

export default Initial
