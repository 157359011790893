import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  CompetencyMatrixInterface,
  CompetencyPerformanceWeightType,
  RoleInterface,
  SpecialisationInterface,
} from '@src/interfaces/roles'
import { API } from '@src/constants/api'
import { SeniorityInterface } from '@src/interfaces/seniority'
import Loader from '@components/CommonSC/Loader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  Box,
  HStack,
  Text,
  useTooltip,
  Link,
  Tooltip,
  Group,
  Item,
  Avatar,
  Token,
  Widget,
} from '@revolut/ui-kit'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'

import { connect, useLape, useLapeEffect } from 'lape'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import CompetencyMatrixTable from '@src/features/CompetencyMatrixTable/CompetencyMatrixTable'
import { formatNumber } from '@src/utils/format'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import SpecialisationSublevels from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/SpecialisationSublevels'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetOrganisationSettings, useGetSkillsSettings } from '@src/api/settings'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import { InfoOutline } from '@revolut/icons'
import { getDefaultCompetencyFor } from '@src/features/CompetencyMatrixTable/utils'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSeniorityRange } from '@src/pages/Forms/SpecialisationForm/CompetencyMatrix/useSeniorityRange'

const Message = styled(WarningMessage)`
  padding: 6px 16px;
`

const CURRENT_STEP = 'competency_matrix'

export const checkCanEdit = (values: SpecialisationInterface) =>
  !values.id ||
  [PermissionTypes.AddSpecialisation, PermissionTypes.ChangeSpecialisation].some(p =>
    values.field_options?.permissions?.includes(p),
  )

const CompetencyMatrix = ({ matrixValidated }: { matrixValidated: boolean }) => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const [role, setRole] = useState<RoleInterface>()
  const isNewTable = useIsNewTable()
  const [filteredSeniorities, setFilteredSeniorities] = useState<SeniorityInterface[]>([])
  const [defaultCultureSkills, setDefaultCultureSkills] = useState<
    CompetencyMatrixInterface[]
  >(values.culture_competency_matrix || [])
  const state = useLape<{ totalWeight: number }>({
    totalWeight: 100,
  })
  const { seniorities, loading, onSeniorityRangeIncreased, onSeniorityRangeDecreased } =
    useSeniorityRange({
      onFetchRoleSuccess: fetchedRole => {
        setDefaultCultureSkills(fetchedRole.culture_competency_matrix)
        setRole(fetchedRole)
      },
    })
  const [renderKey, setRenderKey] = useState(0)
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)

  const { data: settings } = useGetOrganisationSettings()
  const { data: skillsSettings } = useGetSkillsSettings()

  const canEdit = checkCanEdit(values)

  const permissions = useSelector(selectPermissions)

  const canEditWeights = Boolean(values.id && values.performance_weights)
  const canEditSublevels =
    values.field_options?.permissions?.includes(
      PermissionTypes.ChangeSpecialisationSublevels,
    ) || permissions.includes(PermissionTypes.ChangeSpecialisationSublevels)

  useEffect(() => {
    if (values.status === 'draft') {
      workspaceLocalStorage.removeItem(
        pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {}),
      )
    }
  }, [])

  useLapeEffect(() => {
    state.totalWeight =
      values.performance_weights?.reduce(
        // need to parse float here and go with toFixed due to JS adding more numbers on float eg 33.3 + 33.4 = 66.69999999...9 instead of 66.7
        (sum, item) => parseFloat((sum + item.weight).toFixed(10)),
        0.0,
      ) || 0
  })

  const weightsError = canEditWeights && state.totalWeight !== 100

  if (!values.functional_competency_matrix) {
    values.functional_competency_matrix = []
  }

  const changeSkillsWeights = (data: CompetencyMatrixInterface[]) => {
    data.forEach(skillItem => {
      const weightItem = values.performance_weights!.find(
        item => item.skill_id === skillItem.skill?.id,
      )

      const skillWeight =
        skillItem.weight && (skillItem.weight >= 5 ? skillItem.weight : 5)

      const weight = skillWeight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights!.push({
          skill_id: skillItem.skill?.id || null,
          weight_type: CompetencyPerformanceWeightType.Skill,
          weight,
        })
      }
    })
  }

  const onChangeSpecialisationMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data) {
      return
    }

    values.functional_competency_matrix = data.map(item => ({
      ...item,
      id: undefined,
      weight: undefined,
    }))

    if (values.performance_weights) {
      const matrix = [...(roleFunctionalMatrix || []), ...data]
      values.performance_weights = values.performance_weights.filter(item =>
        matrix.find(skillItem => {
          if (item.weight_type === CompetencyPerformanceWeightType.Skill) {
            return skillItem.skill?.id === item.skill_id
          }
          return true
        }),
      )

      changeSkillsWeights(data)
    }
  }

  const onChangeRoleMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data || !values.performance_weights) {
      return
    }

    changeSkillsWeights(data)
  }

  const onChangeDeliverablesMatrix = (data?: CompetencyMatrixInterface[]) => {
    if (!data?.[0]) {
      return
    }

    const row = data[0]
    values.deliverables_competencies = row.competencies

    if (values.performance_weights) {
      const weightItem = values.performance_weights.find(
        item => item.weight_type === CompetencyPerformanceWeightType.Deliverables,
      )

      const weight = row.weight || 0

      if (weightItem) {
        weightItem.weight = weight
      } else {
        values.performance_weights.push({
          skill_id: null,
          weight_type: CompetencyPerformanceWeightType.Deliverables,
          weight,
        })
      }
    }
  }

  if (loading) {
    return (
      <Box pt="s-8">
        <Loader size="medium" />
      </Box>
    )
  }

  const deliverablesMatrix: CompetencyMatrixInterface[] = [
    {
      skill: {
        id: null,
        name: 'Deliverables',
      },
      competencies: values.deliverables_competencies || [],
      weight: values.performance_weights?.find(
        w => w.weight_type === CompetencyPerformanceWeightType.Deliverables,
      )?.weight,
    },
  ]

  const roleFunctionalMatrix =
    role?.functional_competency_matrix &&
    role.functional_competency_matrix.map(item => ({
      ...item,
      weight: values.performance_weights?.find(
        w =>
          w.weight_type === CompetencyPerformanceWeightType.Skill &&
          w.skill_id === item.skill?.id,
      )?.weight,
    }))

  const specialisationFunctionalMatrix = values.functional_competency_matrix?.map(
    item => ({
      ...item,
      weight: values.performance_weights?.find(
        w =>
          w.weight_type === CompetencyPerformanceWeightType.Skill &&
          w.skill_id === item.skill?.id,
      )?.weight,
    }),
  )

  const addSkill = () => {
    const competencies = filteredSeniorities.map(seniority =>
      getDefaultCompetencyFor(seniority),
    )

    values.functional_competency_matrix = [
      ...(values.functional_competency_matrix || []),
      {
        skill: {
          id: null,
          name: null,
        },
        competencies,
      },
    ]
  }

  return (
    <>
      <PageBody gap="s-16" maxWidthMd={Token.breakpoint.xxl}>
        <Widget maxWidth={Token.breakpoint.md}>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="TurboTransfer" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Allowed seniorities</Item.Title>
              <Item.Description>
                Select the seniorities that can be considered for this role
              </Item.Description>
            </Item.Content>
            <Item.Side />
          </Item>
          <Box px="s-16" pb={0}>
            <SenioritiesRange
              disabled={!canEdit}
              seniorities={seniorities}
              seniorityMaxValue={values.seniority_max}
              seniorityMinValue={values.seniority_min}
              onChangeMax={val => {
                values.seniority_max = val
                onSeniorityRangeIncreased(val)
              }}
              onChangeMin={val => {
                values.seniority_min = val
                onSeniorityRangeDecreased(val)
              }}
              onFilter={(minIndex, maxIndex) => {
                setFilteredSeniorities(seniorities.slice(minIndex, maxIndex + 1))
              }}
            />
          </Box>
        </Widget>
        <Group>
          <Item>
            <Item.Avatar>
              <Avatar useIcon="RepairTool" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Competency matrix</Item.Title>
              <Item.Description>
                The skills to be assessed and their expected competency level
              </Item.Description>
            </Item.Content>
            <Item.Side>
              {canEdit && <PrimaryAction onClick={addSkill}>Add skill</PrimaryAction>}
            </Item.Side>
          </Item>
          <Box px={isNewTable ? 0 : 's-16'} pb={isNewTable ? 0 : 's-16'}>
            <CompetencyMatrixTable
              isV2Table
              competencyMatrices={[
                {
                  sectionTitle: 'Deliverables',
                  children: deliverablesMatrix,
                  onChange: onChangeDeliverablesMatrix,
                },
                {
                  sectionTitle: (
                    <ParentSkillsTitle
                      link={`/role/competency-matrix/${values.role.id}`}
                    />
                  ),
                  children: roleFunctionalMatrix,
                  onChange: onChangeRoleMatrix,
                  disableWeights: false,
                  disabled: true,
                },
                {
                  sectionTitle: 'Specialised skills',
                  children: specialisationFunctionalMatrix,
                  matrixToFilterOut: roleFunctionalMatrix,
                  onChange: onChangeSpecialisationMatrix,
                  staticSkill: false,
                  isSkillOptional: true,
                },
              ]}
              minSeniority={values.seniority_min}
              maxSeniority={values.seniority_max}
              withWeightColumn={canEditWeights}
              firstRowTitle=""
              weightsError={weightsError}
              isAdjustable={false}
              filterNonExistent
              readOnly={!canEdit}
            />
            {weightsError && (
              <Box>
                <Message>
                  The sum of all weights should be 100%, currently{' '}
                  {formatNumber(state.totalWeight)}%
                </Message>
              </Box>
            )}
          </Box>
        </Group>
        {!skillsSettings?.behaviours_assessment_enabled && (
          <Group>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="TurboTransfer" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Culture fit skills</Item.Title>
                <Item.Description>
                  Culture fit competency matrix is defined at company level. You cannot
                  make any change here
                </Item.Description>
              </Item.Content>
              <Item.Side />
            </Item>
            {!!role && (
              <Box px={isNewTable ? 0 : 's-16'} pb={isNewTable ? 0 : 's-16'}>
                <CompetencyMatrixTable
                  isV2Table
                  competencyMatrices={[
                    {
                      sectionTitle: 'Deliverables',
                      children: defaultCultureSkills,
                      disabled: true,
                      hideActionsColumn: true,
                    },
                  ]}
                  firstRowTitle="Deliverables"
                  minSeniority={values.seniority_min}
                  maxSeniority={values.seniority_max}
                  isAdjustable={false}
                  readOnly={!canEdit}
                />
              </Box>
            )}
          </Group>
        )}
        {settings?.enable_multiple_levels_per_seniority && (
          <Group>
            <Item>
              <Item.Avatar>
                <Avatar useIcon="TurboTransfer" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Seniority levels</Item.Title>
              </Item.Content>
              <Item.Side />
            </Item>
            {!!role && (
              <Box px={isNewTable ? 0 : 's-16'} pb={isNewTable ? 0 : 's-16'}>
                {values.id && values.status !== Statuses.draft ? (
                  <SpecialisationSublevels
                    id={values.id}
                    canEdit={canEditSublevels}
                    key={renderKey}
                  />
                ) : (
                  <Box>
                    <ActionWidget text="Seniority levels can be renamed after the specialisation is created." />
                  </Box>
                )}
              </Box>
            )}
          </Group>
        )}
      </PageBody>
      {canEdit && (
        <PageActions maxWidthMd={Token.breakpoint.xxl}>
          {values.status === Statuses.draft && (
            <RoleSaveDraftButton
              title="specialisation"
              pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
              pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX}
              isNew
              notification={{
                path: ROUTES.FORMS.SPECIALISATIONS.COMPETENCY_MATRIX,
                updateMsg: 'Specialisation draft successfully updated.',
                createMsg: 'Specialisation draft successfully created.',
              }}
            />
          )}
          <ContinueRoleButton
            api={API.SPECIALISATIONS}
            step={CURRENT_STEP}
            type="specialisation"
            to={nextLink}
            disabled={weightsError || !matrixValidated}
            onAfterSubmit={() => setRenderKey(Math.random())}
          />
        </PageActions>
      )}
    </>
  )
}

export default connect(CompetencyMatrix)

function ParentSkillsTitle({ link }: { link: string }) {
  const tooltip = useTooltip()

  return (
    <section {...tooltip.getAnchorProps()}>
      <HStack space="s-8">
        <Text>Parent skills</Text>
        <InfoOutline size={16} />
      </HStack>
      <Tooltip {...tooltip.getTargetProps()} width={450}>
        <Text>
          These skills and their ratings are defined by the role and are used by all
          specialisations within this role.{' '}
          <Link href={link} target="_blank">
            Click here
          </Link>{' '}
          to go to the role.
        </Text>
      </Tooltip>
    </section>
  )
}
