import React from 'react'
import { Box, Separator as UISeparator, Token, VStack, Text, Flex } from '@revolut/ui-kit'

import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { Node } from '@src/pages/EmployeeProfile/Layout/Summary/Reports/Node'

const Separator: React.FC = ({ children }) => (
  <Box height={80} pb="s-24" mb="s-4">
    <UISeparator height={64} orientation="vertical">
      <Flex
        alignItems="center"
        justifyContent="center"
        radius={Token.radius.round}
        bg={Token.color.greyTone50}
        minWidth="s-20"
      >
        <Text variant="small-tile" color={Token.color.greyTone2}>
          {children}
        </Text>
      </Flex>
    </UISeparator>
  </Box>
)

type Props = {
  employeeId: number
  node?: HierarchyNodeInterface
}
export const Hierarchy = ({ node, employeeId }: Props) => {
  const middleNode =
    node?.reports?.find(({ id }) => id === employeeId) || node?.reports?.[0]
  const bottomNode = middleNode?.reports?.[0]

  return (
    <VStack>
      {node && (
        <>
          <Node
            id={node.id}
            avatar={node.avatar}
            name={node.full_name}
            position={node.position}
          />
          {!!middleNode && (
            <Separator>
              <Text color={Token.color.background}>{node.reports_count}</Text>
            </Separator>
          )}
        </>
      )}
      {middleNode && (
        <Node
          id={middleNode.id}
          avatar={middleNode.avatar}
          name={middleNode.full_name}
          position={middleNode.position}
        />
      )}
      {bottomNode && (
        <>
          <Separator>
            <Text color={Token.color.background}>{middleNode.reports?.length}</Text>
          </Separator>
          <Node
            id={bottomNode.id}
            avatar={bottomNode.avatar}
            name={bottomNode.full_name}
            position={bottomNode.position}
          />
        </>
      )}
    </VStack>
  )
}
