import React, { useState } from 'react'
import pluralize from 'pluralize'
import { useParams } from 'react-router-dom'

import {
  HStack,
  VStack,
  Text,
  MoreBar,
  Box,
  Banner,
  Avatar,
  Token,
  TableWidget,
} from '@revolut/ui-kit'
import { columns } from '@src/constants/columns/paymentsUploadReview'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  triggerPayrollCyclePaymentsUpload,
  getPayrollCyclePaymentsUploadProgress,
  usePayrollCyclePaymentsUploadStats,
} from '@src/api/importData'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import Stat from '@components/Stat/Stat'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { formatDate } from '@src/utils/format'
import { usePayCycle } from '@src/api/payroll'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const row: RowInterface<any> = {
  highlight: data => {
    return Object.keys(data.errors || {}).length ? Token.color.redActionBackground : ''
  },
  cells: columns,
}

const ErrorBanner = ({ text }: { text: string }) => {
  const [isHidden, setIsHidden] = useState(false)

  if (isHidden) {
    return null
  }

  return (
    <Banner labelButtonClear="Clear" onClear={() => setIsHidden(true)} mb="s-12">
      <Banner.Avatar>
        <Avatar
          useIcon="16/Warning"
          size={56}
          bg={Token.color.red_10}
          color={Token.color.red}
        />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>Error</Banner.Title>
        <Banner.Description>{text}</Banner.Description>
      </Banner.Content>
    </Banner>
  )
}

export const UploadedPaymentsTable = () => {
  const { id, sessionId } = useParams<{ id: string; sessionId: string }>()

  const [showConfirm, setShowConfirm] = useState(false)

  const { data: cycle } = usePayCycle(id)
  const table = useTable({
    getItems: () => getPayrollCyclePaymentsUploadProgress(id, sessionId),
  })

  const handleConfirm = async () => {
    try {
      await triggerPayrollCyclePaymentsUpload(id, sessionId)
      navigateTo(pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.STATUS, { id, sessionId }))
    } finally {
      setShowConfirm(false)
    }
  }

  const { data: stats } = usePayrollCyclePaymentsUploadStats(id, sessionId)
  const paymentsToImportCount =
    typeof stats?.total_count === 'number' && typeof stats?.invalid_count === 'number'
      ? stats.total_count - stats.invalid_count
      : undefined

  const disableExecuteImport = table.metadata?.status?.id === 'invalid'

  const allErrors = table.metadata?.errors
    ? Object.values(table.metadata.errors).flat()
    : []

  return (
    <>
      <PageWrapper>
        <PageHeader
          title="Import data"
          subtitle="Payments"
          backUrl={pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id })}
          routerAction={PageHeaderRouterAction.navigate}
          mb="28px"
        />
        {allErrors.map((error, index) => (
          <ErrorBanner key={index} text={error} />
        ))}
        <TableWidget>
          <TableWidget.Info>
            <HStack gap="s-32">
              <Stat
                label={`${pluralize('Payment', paymentsToImportCount)} to import`}
                val={paymentsToImportCount}
              />
              {!!stats?.invalid_count && (
                <Stat
                  label={`${pluralize('Invalid item', stats?.invalid_count)}`}
                  val={stats?.invalid_count}
                  color="red"
                />
              )}
              {!!stats?.error_count && (
                <Stat
                  label={`${pluralize('Error', stats?.error_count)}`}
                  val={stats?.error_count}
                  color="red"
                />
              )}
            </HStack>
          </TableWidget.Info>
          <TableWidget.Actions>
            <Box mb="s-16" maxWidth={256}>
              <MoreBar>
                <MoreBar.Action
                  variant="primary"
                  useIcon="ShareIOs"
                  onClick={() => setShowConfirm(true)}
                  disabled={disableExecuteImport}
                >
                  Execute import
                </MoreBar.Action>
              </MoreBar>
            </Box>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.PaymentsUpload}
              useWindowScroll
              dataType="Upload"
              row={row}
              {...table}
            />
          </TableWidget.Table>
        </TableWidget>
      </PageWrapper>
      <ConfirmationDialog
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={handleConfirm}
        onReject={() => setShowConfirm(false)}
        label="Do you want to continue?"
        body={
          <VStack gap="s-8">
            <Text>
              Please note that, once payments are uploaded, these will be published to
              employees as the pay date is reached.
            </Text>
            {cycle && (
              <Text color="red">
                The pay date for this cycle is {formatDate(cycle.pay_date)}
              </Text>
            )}
          </VStack>
        }
        yesMessage="Continue"
        noMessage="Cancel"
      />
    </>
  )
}
