import React from 'react'
import { Link } from 'react-router-dom'
import pluralize from 'pluralize'
import { Item, Image, ActionButton } from '@revolut/ui-kit'

import { useGetKeyPersonsSettings } from '@src/api/settings'
import { KeyRiskProfileSuccessionPlanConfigInterface } from '@src/interfaces/keyPerson'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

type Props = {
  successionPlanConfig?: KeyRiskProfileSuccessionPlanConfigInterface
  withActionButton?: boolean
}
export const ActiveSuccessorsNumBanner = ({
  successionPlanConfig,
  withActionButton,
}: Props) => {
  const { data: keyPersonsSettings } = useGetKeyPersonsSettings()

  if (!keyPersonsSettings?.succession_minimal_successors_count || !successionPlanConfig) {
    return null
  }

  const activeSuccessorsNum = successionPlanConfig.successors.filter(
    successor => successor.status === 'active',
  ).length

  const alreadyHasMinimumSuccessors =
    activeSuccessorsNum >= keyPersonsSettings.succession_minimal_successors_count
  const isNotRequired =
    successionPlanConfig.succession_strategy?.id === 'successors_not_required' ||
    successionPlanConfig.succession_strategy?.id === 'successors_not_yet_identified'

  if (alreadyHasMinimumSuccessors || isNotRequired) {
    return null
  }

  return (
    <Item mb="s-16">
      <Item.Avatar>
        <Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D018@3x.png',
          }}
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          You must nominate at least{' '}
          {keyPersonsSettings.succession_minimal_successors_count} active{' '}
          {pluralize(
            'successors',
            keyPersonsSettings.succession_minimal_successors_count,
          )}
        </Item.Title>
      </Item.Content>
      {withActionButton && (
        <Item.Side>
          <ActionButton
            use={Link}
            to={pathToUrl(
              ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.EDIT,
              {
                id: String(successionPlanConfig.key_risk_profile_succession_plan.id),
              },
              {
                addSuccessor: 'true',
                selectedResponsibilityIdx: String(
                  successionPlanConfig.key_risk_profile.id,
                ),
              },
            )}
          >
            Add successor
          </ActionButton>
        </Item.Side>
      )}
    </Item>
  )
}
