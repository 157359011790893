import React from 'react'
import { VStack, type BoxProps, Box, Flex, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import { css } from '@styled-system/css'
import * as SS from 'styled-system'

import { FeatureBlockImage } from './FeatureBlockImage'
import { Text } from '../components/Text'
import { FeatureBlockVideo } from '@src/pages/Landing/components/FeatureBlockVideo'
import { Button } from '@src/pages/Landing/components/Button'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { BookDemoButton } from '@src/pages/Landing/components/BookDemoButton'

export enum FeatureBlockVariant {
  Left = 'left',
  Right = 'right',
}

export enum FeatureBlockAlign {
  Cover = 'cover',
  Contain = 'contain',
}

const FeatureBlockLayout = (props: BoxProps) => (
  <Box
    marginX="auto"
    paddingY={{ all: 's-24', lg: '40px', xxl: '80px' }}
    paddingX={{ all: 's-20', lg: 0 }}
    use="section"
    width="100%"
    {...props}
  />
)

const FeatureBlockBase = styled(FeatureBlockLayout)<{ variant: FeatureBlockVariant }>(
  css({
    alignItems: 'center',
    display: 'grid',
    gap: ['s-24', null, null, null, null, 's-40'],
  }),
  SS.variant({
    variants: {
      [FeatureBlockVariant.Left]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'image content'`],
      },
      [FeatureBlockVariant.Right]: {
        gridTemplateColumns: ['auto', null, null, '50%'],
        gridTemplateAreas: [`'content' 'image'`, null, null, `'content image'`],
      },
    },
  }),
)

export interface FeatureBlockContent {
  description?: string | React.ReactNode
  image?: string
  video?: string
  title?: string | React.ReactNode
  caption?: string
  variant?: FeatureBlockVariant
  withPadding?: boolean
  url?: string
}

export const FeatureBlock = ({
  image,
  video,
  title,
  description,
  caption,
  url,
  variant = FeatureBlockVariant.Left,
  withPadding = false,
}: FeatureBlockContent) => {
  const { sendAnalyticsEvent } = useAnalytics()

  return (
    <FeatureBlockBase variant={variant}>
      {!video && image && (
        <Box
          pl={
            withPadding && variant === FeatureBlockVariant.Left
              ? { all: 0, lg: 's-32' }
              : 0
          }
          pr={
            withPadding && variant === FeatureBlockVariant.Right
              ? { all: 0, lg: 's-32' }
              : 0
          }
        >
          <FeatureBlockImage image={image} align={FeatureBlockAlign.Contain} />
        </Box>
      )}
      {video && <FeatureBlockVideo video={video} poster={image} />}
      <VStack
        align={{ all: 'center', lg: 'start' }}
        gridArea="content"
        space={{ all: 's-16', md: 's-24', xxl: 's-32' }}
        maxWidth={488}
        margin={{
          all: '0 auto',
          lg: variant === FeatureBlockVariant.Right ? '0 0 0 auto' : 0,
        }}
      >
        {caption && (
          <Text
            variant="h6"
            color={Token.color.greyTone50}
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {caption}
          </Text>
        )}
        {title && (
          <Text
            use="h2"
            variant="h2"
            whiteSpace="pre-wrap"
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {title}
          </Text>
        )}
        {description && (
          <Text
            // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
            variant="subtitle1"
            whiteSpace="pre-wrap"
            use="div"
            textAlign={{ all: 'center', lg: 'start' }}
          >
            {description}
          </Text>
        )}
        <Flex gap="s-24" justifyContent={{ all: 'start', md: 'center' }}>
          <BookDemoButton />
          {url ? (
            <Button
              // @ts-ignore TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant="secondary"
              use="a"
              href={url}
              display={{ all: 'none', md: 'flex' }}
              target="_blank"
              onClick={() => {
                sendAnalyticsEvent(AnalyticsEvents.click_take_product_tour)
              }}
            >
              Take a product tour
            </Button>
          ) : null}
        </Flex>
      </VStack>
    </FeatureBlockBase>
  )
}
