import React from 'react'
import { Avatar, IconButton, Item, ItemSkeleton } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetIndeedPostings, useGetLinkedInPostings } from '@src/api/jobPosting'
import pluralize from 'pluralize'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface JobBoardProps {
  avatar?: React.ReactNode
  description?: React.ReactNode
  id: string
  loading?: boolean
  title?: React.ReactNode
  to?: string
}

const JobBoardDetails = ({
  avatar,
  description,
  id,
  loading,
  title,
  to,
}: JobBoardProps) => {
  if (loading) {
    return <ItemSkeleton />
  }
  return (
    <Item data-testid={id} use={to ? InternalLink : undefined} to={to}>
      <Item.Avatar>{avatar}</Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
      {to && (
        <Item.Side>
          <IconButton useIcon="ChevronRight" />
        </Item.Side>
      )}
    </Item>
  )
}

interface JobBoardPostingCountProps {
  count?: number
}

const JobBoardPostingCount = ({ count }: JobBoardPostingCountProps) => {
  if (count === undefined) {
    return null
  }
  return <>{pluralize('Postings', count, true)}</>
}

export const LinkedInJobBoardDetails = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetLinkedInPostings(values.id)
  return (
    <JobBoardDetails
      avatar={<Avatar image="https://assets.revolut.com/assets/apps/Linkedin.webp" />}
      description={<JobBoardPostingCount count={data?.count} />}
      id="linkedin"
      loading={isLoading}
      title="LinkedIn"
      to={pathToUrl(ROUTES.FORMS.JOB_POSTING.LINKEDIN_POSTINGS, {
        specId: values.specialisation?.id,
        id: values.id,
      })}
    />
  )
}

export const IndeedJobBoardDetails = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data, isLoading } = useGetIndeedPostings(values.id)
  return (
    <JobBoardDetails
      avatar={
        <Avatar size={40} image="https://assets.revolut.com/assets/apps/Indeed.webp" />
      }
      description={<JobBoardPostingCount count={data?.count} />}
      id="indeed"
      loading={isLoading}
      title="Indeed"
      to={pathToUrl(ROUTES.FORMS.JOB_POSTING.INDEED_POSTINGS, {
        specId: values.specialisation?.id,
        id: values.id,
      })}
    />
  )
}

export const MonsterJobBoardDetails = () => {
  return (
    <JobBoardDetails
      avatar={
        <Avatar size={40} image="https://assets.revolut.com/assets/apps/Monster.webp" />
      }
      id="monsterJobs"
      title="Monster jobs"
    />
  )
}
