import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { timeManagementConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { EditTimeOffPolicyTabs } from '@src/pages/Forms/TimeOffPolicies/EditTabs'
import { BalanceImportFlow } from './BalanceImportFlow'
import { ManageDynamicGroups } from './ManageEligibilityGroups'
import { TimeManagementIntro } from './TimeManagementIntro'
import { PolicyConfiguration } from './PolicyConfiguration'
import { PolicyAssignments } from './PolicyAssignments'
import { SelectPolicies } from './SelectPolicies'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.INTRO,
    canView: [PermissionTypes.ViewTimeOffPolicies],
    component: TimeManagementIntro,
  },
  {
    title: 'Select policies',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.SELECT_POLICIES,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.SELECT_POLICIES,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: SelectPolicies,
    hideActions: true,
    hideBody: true,
  },
  {
    title: 'Policy configuration',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CONFIGURATION,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CONFIGURATION,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: PolicyConfiguration,
    isWide: true,
    hideActions: true,
  },
  {
    title: 'Policy assignments',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ASSIGNMENTS,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ASSIGNMENTS,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: PolicyAssignments,
    hideActions: true,
  },
]

export const TimeManagement = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.UPLOAD.ANY}>
        <BalanceImportFlow />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.ELIGIBILITY_GROUPS}>
        <ManageDynamicGroups />
      </Route>
      <Route
        path={ROUTES.ONBOARDING_CHECKLIST_V2.TIME_MANAGEMENT.CREATE_NEW_POLICY.ANY}
        component={EditTimeOffPolicyTabs}
      />
      <Route>
        <OnboardingChecklistContent config={timeManagementConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
