import React from 'react'

import {
  managerCalendarEventBackgroundColor,
  otherCalendarEventBackgroundColor,
  reportCalendarEventBackgroundColor,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ReviewEvents/constants'
import { HStack, Status, Text, Token } from '@revolut/ui-kit'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface ItemProps {
  label: string
  color: string
}

const itemsProps: ItemProps[] = [
  {
    label: '1:1 with manager',
    color: managerCalendarEventBackgroundColor,
  },
  {
    label: '1:1 with report',
    color: reportCalendarEventBackgroundColor,
  },
  {
    label: 'Other meetings',
    color: otherCalendarEventBackgroundColor,
  },
]

export const Legend = () => {
  return (
    <HStack space="s-16" style={transparentThemeBackgroundOverrides}>
      {itemsProps.map(({ label, color }) => (
        <Status
          key={label}
          useIcon="16/BulletSquareFilled"
          iconColor={color}
          iconSize={24}
        >
          <Text variant="caption" color={Token.color.foreground}>
            {label}
          </Text>
        </Status>
      ))}
    </HStack>
  )
}
