import React from 'react'
import { Group, Box, DetailsCellSkeleton } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { RouteParams } from '../../types'
import { SubmitPreview } from '../../Components/SubmitPreview'
import { useResignationPermissions } from '../../hooks'
import { LetterFileWithStatus } from '../../Components/LetterFileWithStatus'
import { formatApproval } from '../../common'

type Props = {
  resignation?: EmployeeResignationInterface
  approval?: ResignationApprovalInterface
}
const ResignationReasonsPreview = ({ resignation, approval }: Props) => {
  const { id, employeeId } = useParams<RouteParams>()
  const { canView } = useResignationPermissions(resignation)

  return (
    <PageBody>
      {resignation ? (
        <>
          {canView && (
            <>
              <Box mb="s-16">
                <LetterFileWithStatus
                  file={resignation.notice_file}
                  to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.LETTER_PREVIEW, {
                    id,
                    employeeId,
                  })}
                  employeeId={employeeId}
                  resignationId={Number(id)}
                  approval={formatApproval(approval)}
                  canAdd
                />
              </Box>
              <SubmitPreview resignation={resignation} />
            </>
          )}
        </>
      ) : (
        <Group>
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
        </Group>
      )}
    </PageBody>
  )
}

export default ResignationReasonsPreview
