import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { UpdateOrganisationStructureTable } from '@src/pages/Forms/UpdateOrganisationStructure/UpdateOrganisationStructure'

const TeamsManageDepartments = () => {
  return (
    <VStack gap="s-16">
      <UpdateOrganisationStructureTable type="department" />
    </VStack>
  )
}

export default TeamsManageDepartments
