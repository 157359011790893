import React from 'react'
import Form from '@src/features/Form/Form'
import {
  formHiringProcessStagesRoleRequest,
  formHiringProcessStagesSpecialisationRequest,
} from '@src/api/hiringProcess'
import { useQuery } from '@src/utils/queryParamsHooks'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Queries } from '@src/constants/api'
import PreviewStageSidebar from '@src/pages/Forms/RoleForm/Preview/PreviewStageSidebar/PreviewStageSidebar'
import { connect } from 'lape'

const PreviewStageSidebarIndex = (props: { isSpecialisation?: boolean }) => {
  const { query } = useQuery()

  return (
    <Form<HiringProcessInterface>
      api={
        props.isSpecialisation
          ? formHiringProcessStagesSpecialisationRequest
          : formHiringProcessStagesRoleRequest
      }
      forceParams={{ id: query[Queries.StageId] }}
      validator={{}}
      key={query[Queries.StageId]}
      disableLoading
    >
      <PreviewStageSidebar />
    </Form>
  )
}

export default connect(PreviewStageSidebarIndex)
