import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'

type Props = {
  group: CommunicationGroupInterface | null
}
export const CommunicationInputs = ({ group }: Props) => {
  if (!group?.name) {
    return null
  }
  return (
    <InputGroup>
      <LapeRadioSelectInput
        name="communication_group"
        label="Default group"
        selector={selectorKeys.communication_groups}
        message="You can modify the group name and the name of the associated channels from the communication group profile"
      />
      <LapeNewMultiSelect
        canCreateNew
        name="linked_communication_groups"
        placeholder="Linked groups"
        selector={selectorKeys.communication_groups}
        message="Users will need to manually join these channels"
      />
    </InputGroup>
  )
}
