import { IdAndName } from '@src/interfaces'
import { ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'
import { ContributorType } from '@src/interfaces/talent/performance'
import { lape } from 'lape'

export interface PreviewScorecardRoleInterface extends IdAndName<number | string> {
  role_id?: number
}

export interface PreviewScorecardState {
  category: IdAndName<ReviewCategory>
  contributorType: IdAndName<ContributorType>
  seenBy: IdAndName<ReviewerRelation>
  role?: PreviewScorecardRoleInterface
  seniority?: IdAndName<number | string>
}

const defaultState: PreviewScorecardState = {
  category: { id: ReviewCategory.Performance, name: 'Performance Review' },
  seenBy: { id: ReviewerRelation.Self, name: 'Self' },
  contributorType: { id: ContributorType.IC, name: 'Individual Contributor' },
}

export const previewState = lape(defaultState)
