import React from 'react'
import { Group, Box, TransitionCollapse } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { API, selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GroupSettingsInterface } from '@src/interfaces/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const Groups = () => {
  const { values } = useLapeContext<GroupSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeEmployeePreferences)

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="Groups"
            subtitle="Settings related to the employee groups functionality"
          />
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Badges',
                description:
                  'Badges are icons or symbols that can be attached to a group of employees to be displayed in the profile of the employees part of the group',
              }}
              name="enable_dynamic_groups_badges"
              disabled={disableEdit}
            />
            <HideIfCommercial>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable SQL filtering',
                  description: `Allows using SQL to aggregate the employees who are going to be part of the group. It's mandatory to return a column with the employee ids (employee_id) in the result of the query.`,
                }}
                name="enable_dynamic_groups_sql"
                disabled={disableEdit}
              />
              <TransitionCollapse in={values.enable_dynamic_groups_sql}>
                <Box pb="s-8" px="s-12">
                  <LapeNewMultiSelect
                    name="dynamic_groups_allowed_sql_clients"
                    placeholder="Allowed SQL connections"
                    selector={selectorKeys.data_source_categories}
                    required
                    message="Connection that can be used to aggregate the employee queries."
                    disabled={disableEdit}
                  />
                </Box>
              </TransitionCollapse>
            </HideIfCommercial>
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.EMPLOYEE_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
