import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { RowHeight } from '@src/interfaces/table'
import { Token } from '@revolut/ui-kit'
import { rowWrapperMinHeight } from '@components/TableV2/common'

interface Props {
  style?: CSSProperties
  rowHeight: RowHeight
  rowWidth?: number
  separatorContent: React.ReactNode
}

const RowWrapper = styled.div<{
  rowHeight: RowHeight
  rowWidth?: number
}>`
  border: 1px solid ${Token.color.greyTone8};
  border-top: 0;
  background-color: ${Token.color.widgetBackground};
  position: relative;
  display: flex;
  min-height: ${({ rowHeight }) => `${rowWrapperMinHeight[rowHeight]}px`};
  width: ${({ rowWidth }) => (rowWidth ? `${rowWidth}px` : 'max-content')};
`

export const RowSeparator = ({
  style = {},
  rowHeight,
  rowWidth,
  separatorContent,
}: Props) => {
  return (
    <RowWrapper rowHeight={rowHeight} rowWidth={rowWidth} style={{ ...style }}>
      {separatorContent}
    </RowWrapper>
  )
}
