import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { generateDocumentRequests } from '@src/api/documents'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Form from '@src/features/Form/Form'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { EmployeeUserWithAvatar } from '../../EmployeeProfile/Preview/ProfileSummary/common'
import { goBack } from '@src/actions/RouterActions'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { DocumentGenerateInterface } from '@src/interfaces/documents'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'

const DocumentGenerate = () => {
  const { values } = useLapeContext<{
    employee: IdAndName
    document_rule: IdAndName
  }>()
  const params = useParams<{ employeeId: string }>()

  const employeeId = params.employeeId || `${values.employee?.id}`

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, { id: employeeId })

  const onClick = async () => {
    const resp = await generateDocumentRequests.submit(values, { employeeId })

    if (resp?.data?.url) {
      window.open(resp.data.url, `_blank`, 'width=600,height=800')
    } else {
      pushNotification({
        value: 'Document generated successfully',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      goBack(backUrl)
    }

    const url = window.location.pathname + window.location.search
    localStorage.removeItem(url)
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Generate document"
        subtitle={!!params.employeeId && <EmployeeUserWithAvatar id={employeeId} />}
        backUrl={backUrl}
      />

      <PageBody>
        <InputGroup>
          {!params.employeeId && (
            <LapeRadioSelectInput
              name="employee"
              selector={selectorKeys.employee}
              label="Employee"
            />
          )}

          <LapeRadioSelectInput
            label="Rule"
            name="document_rule"
            selector={selectorKeys.document_template_rules_individual_generation}
            required
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup<DocumentGenerateInterface>
          disabled={!values.employee && !params.employeeId}
          successText="Document generated"
          useValidator
          onClick={onClick}
          noPopup
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => {
  return (
    <Form api={generateDocumentRequests}>
      <DocumentGenerate />
    </Form>
  )
})
