import { InfoOutline } from '@revolut/icons'
import { ActionButton, ActionWidget } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { pathToUrl } from '@src/utils/router'
import React from 'react'
import { hasOffboardingV2Enabled } from '../ProfileSummary/common'

interface Props {
  data: EmployeeInterface
}

export const TerminationDateRequiredWarning = ({ data }: Props) => {
  const id = data.id

  const terminationRoute = hasOffboardingV2Enabled(data)
    ? ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2
    : ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION

  return (
    <ActionWidget>
      <ActionWidget.Title>Termination date required</ActionWidget.Title>
      <ActionWidget.Avatar>
        <InfoOutline color="red" />
      </ActionWidget.Avatar>
      <ActionWidget.Content>
        Since {data.first_name} is now an external employee, you need to add a termination
        date before you can make any changes.
      </ActionWidget.Content>
      <ActionWidget.Actions>
        <ActionButton
          use={InternalLink}
          to={pathToUrl(terminationRoute, { id, new: 'new' })}
        >
          Add termination date
        </ActionButton>
      </ActionWidget.Actions>
    </ActionWidget>
  )
}
