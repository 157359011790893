import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const EmployeeRecordsIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Employees.mp4"
      videoPosterUrl="/intros/Intro%20-%20Employees.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.employeeRecords.intro.tips.title"
            defaultMessage="Centralise all your employee data"
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.title"
                defaultMessage="Employee records"
              />
            ),
            icon: 'Profile',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.records.text"
                defaultMessage="All your employee information, stored centrally. System generated fields are provided to simplify your setup however you are free to add additional custom fields, now or later, based on the data you would like to track."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.title"
                defaultMessage="Employee lifecycle"
              />
            ),
            icon: 'Rocket',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.lifecycle.text"
                defaultMessage="Manage the complete employee journey with onboarding and off-boarding workflows. Structured processes help you to stay HR compliant, whilst the onboarding portal means new joiners are in control of uploading their data, providing a great experience from the beginning."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.title"
                defaultMessage="Contracts & compensation"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.employeeRecords.intro.tips.compensation.text"
                defaultMessage="All your employment contract data is stored as a digital contract, with compensation, seniority and more; all of which feeds into dynamic payroll reports each months."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
