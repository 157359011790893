import React from 'react'
import { format } from 'date-fns'

import { Color, Flex, Text } from '@revolut/ui-kit'

type DayBoxProps = {
  children?: React.ReactNode
  withBorder?: boolean
  withBackground?: boolean
}
export const DayBox = ({ children, withBorder, withBackground }: DayBoxProps) => {
  const borderProps = withBorder
    ? {
        borderWidth: 1,
        borderColor: Color.GREY_TONE_10,
        borderStyle: 'solid',
      }
    : {}

  return (
    <Flex
      height="s-48"
      width="s-32"
      justifyContent="center"
      alignItems="center"
      borderRadius={10}
      backgroundColor={withBackground ? Color.GREY_TONE_2 : undefined}
      {...borderProps}
    >
      {children}
    </Flex>
  )
}

type Props = {
  day: Date
  isDayOfCurrentMonth: boolean
  renderContent?: (day: Date) => React.ReactNode
}
export const Day = ({ day, isDayOfCurrentMonth, renderContent }: Props) => {
  if (!isDayOfCurrentMonth) {
    return <DayBox />
  }
  const dayContent = renderContent?.(day)

  return (
    <DayBox withBorder={!dayContent}>
      {dayContent || <Text color={Color.GREY_TONE_50}>{format(day, 'd')}</Text>}
    </DayBox>
  )
}
