import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { PublishingStatuses } from '@src/interfaces/jobPosting'
import { bulkEditJobPostings } from '@src/api/jobPosting'
import pluralize from 'pluralize'
import React, { useState } from 'react'

export const useOnPublishClick = (refresh: () => void) => {
  const [publishLoading, setPublishLoading] = useState(false)
  const statusPopup = useStatusPopup()

  const onPublishClick = async (ids: number[], status: PublishingStatuses) => {
    setPublishLoading(true)
    try {
      await bulkEditJobPostings(ids, status)

      refresh()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>
            {pluralize('Job posting', ids.length)}{' '}
            {status === PublishingStatuses.unpublished ? 'unpublished' : 'published'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (error) {
      const errorsData = error?.response?.data
      const statusError = errorsData?.status?.[0]
      const compensationBandsError = errorsData?.salary_bands_by_location
        ? `Missing compensation data for: ${Object.keys(
            errorsData.salary_bands_by_location,
          ).join(', ')}`
        : undefined
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            {statusError || compensationBandsError || 'Error updating job posting status'}
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setPublishLoading(false)
    }
  }

  return { onPublishClick, publishLoading }
}
