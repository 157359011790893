import React, { useRef, useState } from 'react'
import { Dropdown, MoreBar, StatusPopup, Text } from '@revolut/ui-kit'
import { PromotionStageInterface } from '@src/interfaces/promotions'
import { StagesSummary } from '@src/pages/Forms/PromotionNominationForm/common/ReviewTimeline'
import { addPromotionComment, changePromotionStage } from '@src/api/promotions'
import { PermissionTypes } from '@src/store/auth/types'
import PopupWithComment from '@src/pages/Forms/PromotionNominationForm/common/PopupWithComment'
import { CommentsAPIInterface } from '@src/interfaces/chat'

export interface PromotionStageSwitcherProps {
  nominationId: number | string
  stages: PromotionStageInterface[]
  stagesSummary: StagesSummary
  onSuccess: () => void
  api: CommentsAPIInterface
}

interface PromotionStageSwitcherReturnType {
  isSubmitting: boolean
  successPopup: React.ReactNode
  changeStage: (stage: PromotionStageInterface) => Promise<void>
}

export const usePromotionStageSwitcher = ({
  nominationId,
  stages,
  stagesSummary,
  onSuccess,
}: PromotionStageSwitcherProps): PromotionStageSwitcherReturnType => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false)
  const [selectedStage, setSelectedStage] = useState<PromotionStageInterface>()

  const onSuccessPopupClose = () => {
    setShowSuccessPopup(false)
    setSelectedStage(undefined)
  }

  const changeStage = async (stage: PromotionStageInterface) => {
    setIsSubmitting(true)
    setSelectedStage(stage)

    const currentStageId =
      stages[
        stagesSummary.currentStagesIndex[stagesSummary.currentStagesIndex.length - 1]
      ].id

    try {
      await changePromotionStage(nominationId, currentStageId, stage.stage_type.id)
      onSuccess()
      setShowSuccessPopup(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const successPopup = (
    <StatusPopup variant="success" open={showSuccessPopup} onClose={onSuccessPopupClose}>
      <StatusPopup.Title>
        Stage changed to {selectedStage?.stage_type?.name}
      </StatusPopup.Title>
    </StatusPopup>
  )

  return {
    changeStage,
    isSubmitting,
    successPopup,
  }
}

const ChangeStageButton = (props: PromotionStageSwitcherProps) => {
  const anchorRef = useRef(null)
  const { stages, stagesSummary } = props
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [pending, setPending] = useState(false)
  const [selectedStage, setSelectedStage] = useState<PromotionStageInterface>()
  const { refetch } = props.api.useGetComments(true)

  const { successPopup, isSubmitting, changeStage } = usePromotionStageSwitcher(props)

  const currentStageIndex =
    stagesSummary.currentStagesIndex[stagesSummary.currentStagesIndex.length - 1]

  const handleChangeStage = async (comment: string) => {
    if (selectedStage) {
      setPending(true)
      try {
        await changeStage(selectedStage)
        if (comment.length) {
          await addPromotionComment(props.nominationId, comment)
        }
      } finally {
        refetch()
        setPending(false)
        setOpen(false)
        setIsPopupOpen(false)
      }
    }
  }

  return (
    <>
      <MoreBar.Action
        pending={isSubmitting}
        disabled={!stages}
        onClick={() => setOpen(!isOpen)}
        ref={anchorRef}
      >
        Change stage
      </MoreBar.Action>

      <Dropdown open={isOpen} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        {stages.map((stage, index) => (
          <Dropdown.Item
            disabled={
              index <= currentStageIndex ||
              !stage.field_options?.permissions?.includes?.(
                PermissionTypes.ChangePromotionStage,
              )
            }
            use="button"
            key={stage.id}
            onClick={() => {
              setSelectedStage(stage)
              setIsPopupOpen(true)
            }}
          >
            <Text use="p">
              {index + 1}. {stage.stage_type.name}
            </Text>
            <Text use="p" color="grey-tone-50" variant="small">
              {index <= currentStageIndex ? stage.outcome.name : 'Not started'}
            </Text>
          </Dropdown.Item>
        ))}
      </Dropdown>

      <PopupWithComment
        title="Change nomination stage"
        subtitle="Please comment on the reason for this action. This will be added to the nomination comments."
        isOpen={isPopupOpen}
        onSubmit={handleChangeStage}
        onClose={() => setIsPopupOpen(false)}
        isSubmitting={pending}
      />

      {successPopup}
    </>
  )
}

export default ChangeStageButton
