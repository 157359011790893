import React from 'react'
import { Action } from '@revolut/ui-kit'
import { Download } from '@revolut/icons'
import { getReportRunResultExport } from '@src/api/dataAnalytics'
import {
  reportStatusHistoryTableColumn,
  queriesActionsHistoryTableColumn,
  queriesDateRunHistoryTableColumn,
  queriesDurationHistoryTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { RowInterface } from '@src/interfaces/data'
import {
  RunReportResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { pushError } from '@src/store/notifications/actions'
import { saveFile } from '@src/utils'
import { MAX_ITEMS_TO_SHOW } from '../constants'

interface Props {
  tableData: RunReportResponseInterface[]
}

const getRow = (
  handleDownloadReport: (data: RunReportResponseInterface) => void,
): RowInterface<RunReportResponseInterface> => ({
  cells: [
    {
      ...queriesDateRunHistoryTableColumn,
      width: 100,
    },
    {
      ...queriesDurationHistoryTableColumn,
      width: 100,
    },
    {
      ...reportStatusHistoryTableColumn,
      width: 50,
    },
    {
      ...queriesActionsHistoryTableColumn,
      width: 50,
      insert: ({ data }) =>
        data.status === QueryRunStatuses.Success ? (
          <Action
            iconOnly
            onClick={() => handleDownloadReport(data)}
            useIcon={Download}
          />
        ) : null,
    },
  ],
})

export const HistoricalRunsTable = ({ tableData }: Props) => {
  const handleDownloadReport = async (data: RunReportResponseInterface) => {
    try {
      const resp = await getReportRunResultExport(data.report.id, data.id)
      if (resp.data.url) {
        saveFile(resp.data.url, `${data.report.name}`)
      }
    } catch (e) {
      pushError({ error: e })
    }
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<RunReportResponseInterface>
          {...tableData}
          count={MAX_ITEMS_TO_SHOW}
          data={tableData.slice(0, MAX_ITEMS_TO_SHOW)}
          dataType="Previous runs"
          hideCount
          name={TableNames.ReportingAppReportsHistoricalRuns}
          noDataMessage="List of all previous runs"
          row={getRow(handleDownloadReport)}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
