import React, { useState, useRef } from 'react'
import { Coins } from '@revolut/icons'
import { ItemSkeleton, Item, Avatar, Box, useIntersectViewport } from '@revolut/ui-kit'
import { usePayment } from '@src/api/payroll'
import { formatPeriod, formatMoneyWithCode } from '@src/utils/format'

export const PaymentPreviewItem = ({ id }: { id: number }) => {
  const [paymentId, setPaymentId] = useState<number>()
  const { data: payment, isLoading } = usePayment(paymentId)
  const ref = useRef(null)
  useIntersectViewport(ref, isIntersecting => {
    if (isIntersecting && !payment) {
      setPaymentId(id)
    }
  })

  if (isLoading || !payment) {
    return (
      <Box ref={ref}>
        <ItemSkeleton />
      </Box>
    )
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={Coins} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{payment.payroll_element?.name}</Item.Title>
        <Item.Description>{payment.payroll_element?.pay_group?.name}</Item.Description>
        <Item.Description>
          {!!payment.pay_cycle &&
            formatPeriod(
              new Date(payment.pay_cycle?.start_date),
              new Date(payment.pay_cycle?.end_date),
            )}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>{formatMoneyWithCode(payment.amount, payment.currency)}</Item.Value>
        <Item.Value variant="secondary">
          {payment.payroll_element?.is_payable ? 'Payable' : 'Receivable'}
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
