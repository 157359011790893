import React, { ChangeEvent, useState } from 'react'
import isNil from 'lodash/isNil'
import { FilterOption } from '@src/interfaces/data'
import get from 'lodash/get'
import { DropdownProps, Dropdown, Input, InputGroup, ActionButton } from '@revolut/ui-kit'
import NewGrid from '@components/CommonSC/NewGrid'

export enum FilterTextInputType {
  Text = 'text',
  Number = 'number',
}

interface FilterTextInputProps extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor'> {
  open: boolean
  onClose: () => void
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
  type?: FilterTextInputType
}

const FilterTextInput = ({
  open,
  onClose,
  onChange,
  value,
  anchorRef,
  type = FilterTextInputType.Text,
  fitInAnchor,
}: FilterTextInputProps) => {
  const [inputValue, setInputValue] = useState<string>(get(value, '[0].id', ''))

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    setInputValue(e.currentTarget.value)
  }

  const onApply = () => {
    if (isNil(inputValue) || inputValue === '') {
      return
    }
    onChange([{ id: inputValue, name: inputValue }])
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onKeyDown: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Enter') {
      onApply()
    } else if (e.key === 'Escape') {
      onClose()
    }
  }

  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
    >
      <Dropdown.Group sticky="top" p="s-16" onKeyDown={onKeyDown}>
        <InputGroup>
          <Input
            type={type === FilterTextInputType.Number ? type : undefined}
            value={inputValue}
            label="Search"
            onChange={onInputChange}
          />
        </InputGroup>
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16" onKeyDown={onKeyDown}>
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          <ActionButton width="100%" onClick={onClear}>
            Clear
          </ActionButton>
          <ActionButton variant="accent" width="100%" onClick={onApply}>
            Apply
          </ActionButton>
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}

export default FilterTextInput
