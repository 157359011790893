import React from 'react'
import { newLocationRequests } from '@src/api/locations'
import Form from '@src/features/Form/Form'
import { General } from '@src/pages/Forms/LocationForm/General'

interface NewLocationSidebarFormProps {
  locationId?: number
  isOpen: boolean
  onAfterSave: () => void
  onClose: () => void
}
export const LocationSidebarForm = ({
  locationId,
  isOpen,
  onAfterSave,
  onClose,
}: NewLocationSidebarFormProps) => {
  return (
    <Form
      api={newLocationRequests}
      disableLocalStorageCaching
      forceParams={{ ...(locationId ? { id: String(locationId) } : { new: 'new' }) }}
    >
      <General isOpen={isOpen} onAfterSave={onAfterSave} onClose={onClose} useSidebar />
    </Form>
  )
}
