import React from 'react'

import { OnboardingBulkDataUpload } from '../components/BulkDataUpload/OnboardingBulkDataUpload'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import {
  ImportRequisitionsCellOptions,
  importRequisitionsRequisitionTitleColumn,
  importRequisitionsSpecialisationColumn,
  importRequisitionsSeniorityMinColumn,
  importRequisitionsSeniorityMaxColumn,
  importRequisitionsTeamColumn,
  importRequisitionsLocationsColumn,
  importRequisitionsLineManagerColumn,
  importRequisitionsHeadcountColumn,
  importRequisitionsRecruiterColumn,
  importRequisitionsPotentialStartDateColumn,
  importRequisitionsDescriptionColumn,
} from '@src/constants/columns/importRequisitions'
import { fields, useOptions } from './sessionConfig'
import { ROUTES } from '@src/constants/routes'
import { requisitionsConfig } from '../common/constants'
import { API } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { ImportRequisitionsDataInterface } from '@src/interfaces/importRequisitions'

const row = (
  options: ImportRequisitionsCellOptions,
): Partial<RowInterface<ImportInterface<ImportRequisitionsDataInterface>>> => ({
  cells: [
    {
      ...importRequisitionsRequisitionTitleColumn,
      width: 200,
    },
    {
      ...importRequisitionsSpecialisationColumn,
      width: 160,
    },
    {
      ...importRequisitionsSeniorityMinColumn,
      width: 100,
    },
    {
      ...importRequisitionsSeniorityMaxColumn,
      width: 100,
    },
    {
      ...importRequisitionsTeamColumn,
      width: 130,
    },
    {
      ...importRequisitionsLocationsColumn,
      width: 100,
    },
    {
      ...importRequisitionsLineManagerColumn(options),
      width: 130,
    },
    {
      ...importRequisitionsHeadcountColumn,
      width: 100,
    },
    {
      ...importRequisitionsRecruiterColumn(options),
      width: 130,
    },
    {
      ...importRequisitionsPotentialStartDateColumn,
      width: 130,
    },
    {
      ...importRequisitionsDescriptionColumn,
      width: 300,
    },
  ],
})

export const RequisitionsBulkUpload = () => {
  const options = useOptions()

  return (
    <OnboardingBulkDataUpload
      importRoute={ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.UPLOAD.IMPORT}
      previousRoute={ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.SETTINGS}
      sessionRoute={ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.UPLOAD.SESSION}
      anyRoute={ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.ANY}
      apiEndpoint={API.REQUISITIONS_UPLOADS}
      config={requisitionsConfig}
      currentStep="Upload requisitions"
      category="requisition_bulk_upload"
      name="requisition"
      row={row(options)}
      tableName={TableNames.ImportRequisitions}
      fields={fields}
      recommendedLabel="yes"
      yesByDefault
    />
  )
}
