import React, { useEffect } from 'react'
import EditableTable, {
  EditableTableProps,
} from '@components/TableV2/EditableTable/EditableTable'
import { connect } from 'lape'
import set from 'lodash/set'
import { useLapeField } from '@src/features/Form/LapeForm'

interface Props<T> extends Omit<EditableTableProps<T>, 'data' | 'onChange'> {
  dataFieldName: string
  initialData?: T[]
  replaceOnInitialDataChange?: boolean
  onChange?: EditableTableProps<T>['onChange']
}

const LapeEditableTable = <T extends {}>({
  dataFieldName,
  initialData,
  replaceOnInitialDataChange = false,
  ...props
}: Props<T>) => {
  const { value, onChange } = useLapeField(dataFieldName)

  useEffect(() => {
    if (replaceOnInitialDataChange || !value) {
      onChange(initialData)
    }
  }, [initialData])

  return (
    <EditableTable
      {...props}
      data={value}
      onChange={info => {
        const { fieldValue, fieldDataPath } = info

        if (value) {
          set(value, fieldDataPath, fieldValue)
        }
        props.onChange?.(info)
      }}
    />
  )
}

export default connect(LapeEditableTable)
