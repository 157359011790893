import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import General from './General'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@components/Page/Page'
import { connect } from 'lape'
import { dataRetentionGDPR } from '@src/api/dataRetentionSettings'
import View from './View'

const DataRetentionForm = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route path={ROUTES.FORMS.DATA_RETENTION.GENERAL}>
          <General />
        </Route>
        <Route path={ROUTES.FORMS.DATA_RETENTION.PREVIEW}>
          <View />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={dataRetentionGDPR}>
    <DataRetentionForm />
  </Form>
))
