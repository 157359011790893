import React from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings, APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageBody } from '@components/Page/PageBody'
import { Box, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'

const TeamsSettings = () => {
  const queryClient = useQueryClient()

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)

  return (
    <>
      <PageBody>
        <Box mt="s-24">
          <VStack space="s-16">
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Allow team owners to view salaries',
                description:
                  'Allows the team owners to view the salaries of the people in their team',
              }}
              name="enable_team_owners_view_salaries"
              disabled={disableEdit}
            />
            <ApprovalSteps
              title="Enable team approval"
              description="Whenever a new team is created or an existing one is changed it asks for approval"
              switchField="enable_teams_approvals"
              approvalProcess="teams_approval_steps"
              entity="team"
              disabled={disableEdit}
              formRef={values}
            />
          </VStack>
        </Box>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Teams',
    path: ROUTES.SETTINGS.ORGANISATION.TEAMS,
    url: ROUTES.SETTINGS.ORGANISATION.TEAMS,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: TeamsSettings,
  },
]

export const Teams = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Teams settings"
      subtitle="Settings that apply to all teams"
    />
  )
}
