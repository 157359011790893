import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { employeeSetupGuide } from '../../Employees/setupGuide'
import { roleSetupGuide } from '../../Roles/setupGuide'
import { locationSetupGuide } from '../../Locations/setupGuide'

export interface QuestionAnswerInterface {
  question: string
  answer: React.ReactNode
}

interface SetupGuideConfigInterface {
  paths: string[]
  questionsAnswers: QuestionAnswerInterface[]
  customContent?: React.ReactNode
  title?: string
  description?: string
}

export const setupGuideConfig: SetupGuideConfigInterface[] = [
  {
    paths: [ROUTES.ONBOARDING_CHECKLIST.EMPLOYEES.ANY],
    questionsAnswers: employeeSetupGuide,
  },
  {
    paths: [ROUTES.ONBOARDING_CHECKLIST.ROLES.ANY],
    questionsAnswers: roleSetupGuide,
  },
  {
    paths: [ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.ANY],
    questionsAnswers: locationSetupGuide,
  },
]
