import React from 'react'
import get from 'lodash/get'
import { Box, Flex, Token } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { FieldOptions } from '@src/interfaces'
import HistoryTooltip from '@components/Stepper/HistoryTooltip'
import { getHistoryRequest } from '@src/api/changelog'

export interface CellWithChangelogProps {
  fieldName: string
  children: JSX.Element
}

const CellWithChangelog = ({ fieldName, children }: CellWithChangelogProps) => {
  const { values, changelog, changelogApi } = useLapeContext<{
    field_options: FieldOptions
  }>()

  const hasChangelog = !!changelog
  const hasChanged = get(changelog, fieldName) !== undefined

  if (hasChangelog && hasChanged && changelogApi) {
    return (
      <Flex alignItems="center">
        {children}
        <Box ml="s-8">
          <HistoryTooltip
            iconSize="tiny"
            isNew
            color={Token.color.warning}
            request={getHistoryRequest(values, fieldName, changelogApi)}
          />
        </Box>
      </Flex>
    )
  }

  return children
}

export default CellWithChangelog
