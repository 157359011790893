import produce from 'immer'
import {
  InterviewFeedbackInterface,
  InterviewFeedbackSkillSectionInterface,
  InterviewThumbsQuestionInterface,
  ThumbsOptions,
} from '@src/interfaces/interviewTool'
import { AssessButtonTypes } from '@components/AssessButtons/AssessButtons'
import { SectionType } from '@src/interfaces/performance'
import lowerCase from 'lodash/lowerCase'
import { getClearedIncompleteSections, getVisibleSections } from '@src/utils/performance'
import {
  InterviewScorecardType,
  InterviewScorecardTemplateInterface,
} from '@src/interfaces/interviewScorecardTemplates'

export const normalizeInterviewForPreview = (
  diff?: Partial<InterviewScorecardTemplateInterface>,
  data?: Partial<InterviewScorecardTemplateInterface>,
) => {
  if (!diff) {
    return undefined
  }

  const values = data || diff

  return produce(diff, draft => {
    // this type is virtual just for showing in the UI, we have to remove skills before sending to the BE
    if (values.scorecard_type?.id === InterviewScorecardType.Delivery) {
      draft.skills = []
      draft.sections?.forEach(section => {
        section.skills = []
      })
    }
  })
}

export const transformInterviewToPerformanceScorecard = (
  values: InterviewFeedbackInterface,
) => {
  return produce(values, draft => {
    draft.scorecard.sections?.forEach(section => {
      if (section.internal_data_type === 'options_question_interview_section') {
        section.options = section.options.map(o => ({ ...o, id: o.internal_data_id }))
      }
      if (section.internal_data_type === 'skill_interview_section') {
        section.name = section.title
        section.sections = section.levels_questions?.map(level => ({
          internal_data_id: level.internal_data_id,
          internal_data_type: level.internal_data_type,
          type: SectionType.CriteriasAssessment,
          level_key: lowerCase(level.level),
          items: level?.questions?.map(questionItem => ({
            ...questionItem,
            text: questionItem.question,
            value: (questionItem.answer as AssessButtonTypes) || null,
          })),
        }))
      }
    })

    if (draft.result === '') {
      delete draft.result
    }

    if (draft.scorecard.seniority_section) {
      draft.scorecard.seniority_section.options =
        draft.scorecard.seniority_section.options.map(o => ({
          ...o,
          id: o.internal_data_id,
        }))
    }
  })
}

export const transformPerformanceToInterviewScorecard = (
  values: Partial<InterviewFeedbackInterface>,
) => {
  return produce(values, draft => {
    draft.scorecard?.sections?.forEach(section => {
      if (section.internal_data_type === 'options_question_interview_section') {
        section.options.forEach(o => delete o.id)
      }

      if (section.internal_data_type === 'skill_interview_section') {
        const cleanSections = getClearedIncompleteSections(
          section.sections,
          getVisibleSections(section.sections),
        )

        section.title = section.name!
        section.levels_questions = cleanSections.map(level => ({
          internal_data_id: level.internal_data_id,
          internal_data_type: level.internal_data_type,
          level: level.level_key,
          questions:
            level.items?.map(questionItem => {
              return {
                internal_data_id: questionItem.internal_data_id!,
                internal_data_type: questionItem.internal_data_type!,
                question: questionItem.text!,
                answer: (questionItem.value as AssessButtonTypes) || '',
              }
            }) || [],
        }))
      }

      delete (section as InterviewFeedbackSkillSectionInterface).sections
      delete (section as InterviewFeedbackSkillSectionInterface).name
    })
  })
}

export const getThumbsQuestionPoints = (question: InterviewThumbsQuestionInterface) => {
  if (question.answer === ThumbsOptions.Yes) {
    return question.points
  }
  if (question.answer === ThumbsOptions.YesWithHelp) {
    return Math.floor(question.points / 2)
  }
  return 0
}

export const isPointBasedScorecard = (type?: InterviewScorecardType) =>
  type && [InterviewScorecardType.CV, InterviewScorecardType.General].includes(type)

export const isLevelBasedScorecard = (type?: InterviewScorecardType) =>
  type &&
  [
    InterviewScorecardType.Value,
    InterviewScorecardType.Skill,
    InterviewScorecardType.Delivery,
  ].includes(type)
