import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Token } from '@revolut/ui-kit'
import {
  dashboardPermissionsRequests,
  dashboardPermissionsRequestsNew,
} from '@src/api/permissionManagement'
import {
  permissionsActionsColumn,
  permissionsEmployeeNameColumn,
  permissionsNotesColumn,
  permissionsPermissionColumn,
  permissionsStatusColumn,
  permissionsValidFromColumn,
  permissionsValidTillColumn,
} from '@src/constants/columns/permissions'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import PermissionTransferRevokeDialogue from '@src/pages/PermissionTransfer/Dialogue/PermissionTransferRevokeDialogue'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const getRow = (
  onRevoke: (data: PermissionsInterface) => void,
): RowInterface<PermissionsInterface> => ({
  cells: [
    {
      ...permissionsEmployeeNameColumn,
      width: 250,
    },
    {
      ...permissionsPermissionColumn,
      width: 250,
    },
    {
      ...permissionsStatusColumn,
      width: 200,
    },
    {
      ...permissionsValidFromColumn,
      width: 200,
    },
    {
      ...permissionsValidTillColumn,
      width: 150,
    },
    {
      ...permissionsNotesColumn,
      width: 150,
    },
    {
      ...permissionsActionsColumn(onRevoke),
      width: 150,
    },
  ],
})

export interface OptionInterface {
  id: string
  name: string
  fields: string[]
}

export const DataAnalyticsDashboardPermissions = connect(() => {
  const params = useParams<{ id: string }>()
  const table = useTable<any>(dashboardPermissionsRequests(params.id))
  const [isDialogueOpen, setDialogueOpen] = useState(false)
  const [revokingData, setRevokingData] = useState<PermissionsInterface>()

  const onRevoke = (data: PermissionsInterface) => {
    setDialogueOpen(true)
    setRevokingData(data)
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Dashboard permissions"
        backUrl={pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD_VIEW, {
          id: params.id,
        })}
      />
      <PageBody maxWidth={Token.breakpoint.xxl}>
        <Table.Widget>
          <Table.Widget.Actions>
            <PrimaryAction
              to={pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.DASHBOARD, {
                entityId: params.id,
                tab: 'form',
              })}
              use={InternalLink}
              useIcon="ShareAndroid"
            >
              Add new permission
            </PrimaryAction>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable<PermissionsInterface>
              dataType="Permission"
              name={TableNames.ReportingAppDashboardsPermissions}
              emptyState={<EmptyTableRaw title="Permissions will appear here" />}
              row={getRow(onRevoke)}
              useWindowScroll
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>

        <PermissionTransferRevokeDialogue
          isOpen={isDialogueOpen}
          onAfterSubmit={() => {
            setDialogueOpen(false)
            setRevokingData(undefined)
            table.refresh()
          }}
          permission={revokingData}
          revoke={id =>
            dashboardPermissionsRequestsNew(params.id).delete?.({ id }, params)
          }
          setOpen={setDialogueOpen}
        />
      </PageBody>
    </PageWrapper>
  )
})
