import {
  Flex,
  Icon,
  Text,
  IconName,
  TextButton,
  TextSkeleton,
  Token,
  createChain,
} from '@revolut/ui-kit'
import format from 'date-fns/format'
import React from 'react'

type DateSwitchProps =
  | {
      startDate: Date
      lastDate: Date
      onSwitchBack: () => void
      onSwitchForward: () => void
      loading?: false
    }
  | {
      startDate?: Date
      lastDate?: Date
      onSwitchBack: () => void
      onSwitchForward: () => void
      loading: true
    }

export const DateSwitch = ({
  startDate,
  lastDate,
  onSwitchBack,
  onSwitchForward,
  loading,
}: DateSwitchProps) => {
  const renderButton = (icon: IconName, handler: () => void) => {
    return (
      <TextButton color={Token.color.greyTone20} disabled={loading} onClick={handler}>
        <Flex justifyContent="center" minWidth={50}>
          <Icon name={icon} size={16} />
        </Flex>
      </TextButton>
    )
  }

  const renderTitle = () => {
    if (loading) {
      return <TextSkeleton width={200} />
    }

    const isSameMonth =
      startDate.getMonth() === lastDate.getMonth() &&
      startDate.getFullYear() === lastDate.getFullYear()

    const DATE_FORMAT = 'LLL, yyyy'

    const startDateLabel = format(startDate, DATE_FORMAT)
    const endDateLabel = format(lastDate, DATE_FORMAT)

    return (
      <Text variant="primary">
        {isSameMonth ? startDateLabel : createChain(' - ')(startDateLabel, endDateLabel)}
      </Text>
    )
  }

  return (
    <Flex justifyContent="space-between" alignItems="center">
      {renderButton('ChevronLeft', onSwitchBack)}
      {renderTitle()}
      {renderButton('ChevronRight', onSwitchForward)}
    </Flex>
  )
}
