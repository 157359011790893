import React from 'react'
import styled from 'styled-components'
import FileUploader, {
  FileUploaderProps,
} from '@components/Inputs/FileUploader/FileUploader'
import { Button, Flex, HStack, Spinner, Widget } from '@revolut/ui-kit'

const FileUploadContainer = styled(Widget)`
  flex-direction: column;
`

export interface ImportFileProps {
  onDownloadTemplate?: React.MouseEventHandler
  downloadTemplatePending?: boolean
  importPending?: boolean
  supportedFormats: string[]
  onFileUpload: FileUploaderProps['onChange']
  error?: string
  actions?: React.ReactNode
}

const ImportFile = ({
  onDownloadTemplate,
  downloadTemplatePending,
  importPending,
  onFileUpload,
  supportedFormats,
  error,
  actions,
}: ImportFileProps) => {
  const supportedFormatsString = supportedFormats.join('/')

  return (
    <FileUploadContainer p="s-24" display="flex">
      <HStack gap="s-16">
        {!!onDownloadTemplate && (
          <Button
            onClick={onDownloadTemplate}
            pending={downloadTemplatePending}
            mb="s-16"
            useIcon="Download"
          >
            Download template
          </Button>
        )}
        {actions}
      </HStack>
      {importPending ? (
        <Flex justifyContent="center">
          <Spinner />
        </Flex>
      ) : (
        <FileUploader
          label={`${supportedFormatsString} attachment`}
          onChange={onFileUpload}
          hideOptional
          name="file"
          error={error}
        />
      )}
    </FileUploadContainer>
  )
}

export default ImportFile
