import React from 'react'
import { Flex, Token, Icon, Text, HStack, Tag } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'
import { Id } from '@src/interfaces'
import pluralize from 'pluralize'

interface Props {
  goals: (Id & { name?: string })[]
}

export const RelatedGoals = ({ goals }: Props) => {
  const isNewTable = useIsNewTable()

  if (isNewTable) {
    if (goals.length === 0) {
      return <>-</>
    }

    return (
      <Flex gap="s-4" alignItems="center" color={Token.color.greyTone50}>
        <Icon name="Limit" size={16} />
        <Text>{pluralize('goal', goals.length, true)}</Text>
      </Flex>
    )
  }

  return (
    <HStack gap="s-4">
      {goals.map(item =>
        item.name ? (
          <Tag key={item.id} variant="faded">
            {item.name}
          </Tag>
        ) : null,
      )}
    </HStack>
  )
}
