import React from 'react'
import pluralize from 'pluralize'
import { Color, Tag, Token } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { PayrollIssuesLevelId, PayrollStatusId } from '@src/interfaces/payrollV2'

export const payrollIssuesLevelToColor: Record<PayrollIssuesLevelId, Color> = {
  0: Token.color.success,
  5: Token.color.warning,
  10: Token.color.danger,
}

type PayrollStatusTagProps = {
  issuesLevel: IdAndName<PayrollIssuesLevelId>
  totalIssues: number
  status?: IdAndName<PayrollStatusId>
}
export const PayrollStatusTag = ({
  issuesLevel,
  totalIssues,
  status,
}: PayrollStatusTagProps) => {
  if (!status) {
    return (
      <Tag
        variant="faded"
        color={payrollIssuesLevelToColor[issuesLevel.id]}
        bg={Token.color.greyTone5}
        style={{ maxWidth: '100%' }}
      >
        {pluralize('issue', totalIssues, true)}
      </Tag>
    )
  }
  if (status.id === 'approved' || status.id === 'processed') {
    return (
      <Tag
        variant="faded"
        color={Token.color.success}
        bg={Token.color.greyTone5}
        style={{ maxWidth: '100%' }}
      >
        {status.name}
      </Tag>
    )
  }
  if (status.id === 'with_issues') {
    return (
      <Tag
        variant="faded"
        color={payrollIssuesLevelToColor[issuesLevel.id]}
        bg={Token.color.greyTone5}
        style={{ maxWidth: '100%' }}
      >
        {pluralize('issue', totalIssues, true)}
      </Tag>
    )
  }
  return <>-</>
}
