import React from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardViewInterface,
} from '@src/interfaces/performance'
import { getCardsForView } from '@src/utils/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'

const CultureFit = () => {
  const { values } = useLapeContext<ReviewScorecardViewInterface>()

  const initialType = values.summary?.culture_values
    ? PerformanceReviewTypes.cultureValuesFit
    : PerformanceReviewTypes.cultureFit

  const cards = getCardsForView(values?.summary?.culture_skills?.cards || [])
  const cultureValuesCards = values?.summary?.culture_values?.cards || []

  if (!cards) {
    return null
  }

  return (
    <ScorecardGeneral
      cards={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? cultureValuesCards
          : cards
      }
      type={initialType}
      rating={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? values.summary.culture_values?.rating
          : values.summary.culture_skills?.rating
      }
      justifications={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? values.summary?.culture_values?.skipped_section_justifications
          : values.summary?.culture_skills?.skipped_section_justifications
      }
      title="Culture fit"
      category={ReviewCategory.Performance}
      viewMode
    />
  )
}

export default CultureFit
