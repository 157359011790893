import React, { useState } from 'react'
import { ReviewScorecardViewInterface } from '@src/interfaces/performance'
import ScorecardRecommendation from '@components/ScorecardRecommendation/ScorecardRecommendation'
import styled from 'styled-components'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Box, MoreBar } from '@revolut/ui-kit'
import PerformanceViewSummarySidebar from '@src/pages/Forms/EmployeePerformance/PerformanceViewSummarySidebar'

const WarningMessageStyled = styled(WarningMessage)`
  max-width: 908px;
`

const Recommendations = () => {
  const { values } = useLapeContext<ReviewScorecardViewInterface>()
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)

  const recommendations = []

  if (values.summary.line_manager_extra_section) {
    recommendations.push(values.summary.line_manager_extra_section)
  }

  if (values.summary.functional_manager_extra_section) {
    recommendations.push(values.summary.functional_manager_extra_section)
  }

  if (values.summary.peer_extra_sections) {
    recommendations.push(...values.summary.peer_extra_sections)
  }

  if (!recommendations.length) {
    return (
      <Box>
        <WarningMessageStyled>
          There are no recommendations for the selected filters
        </WarningMessageStyled>
      </Box>
    )
  }

  return (
    <>
      <Box width="100%">
        <MoreBar>
          <MoreBar.Action
            onClick={() => {
              setIsOpenSidebar(true)
            }}
          >
            Open summary sidebar
          </MoreBar.Action>
        </MoreBar>
        <ScorecardRecommendation
          data={recommendations[0]}
          reviews={recommendations}
          lmData={values.summary.line_manager_extra_section}
          fmData={values.summary.functional_manager_extra_section}
          peersData={values.summary.peer_extra_sections}
          viewMode
          type={null}
        />
      </Box>
      <PerformanceViewSummarySidebar
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
    </>
  )
}

export default Recommendations
