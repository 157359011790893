import React, { useState } from 'react'
import { OnFilterChangeHandler } from '@src/components/Table/hooks'
import { HStack, VStack } from '@revolut/ui-kit'
import { SearchInput } from '@src/components/TableV2/SearchTable/SearchInput'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import FilterButtonCheckboxSelect from '@src/components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import { OptionInterface } from '@src/interfaces/selectors'

interface ConnectedRequisitionsFilterProps {
  onFilterChange: OnFilterChangeHandler
}

export const ConnectedRequisitionsFilter = ({
  onFilterChange,
}: ConnectedRequisitionsFilterProps) => {
  const { data: teams } = useGetSelectors(selectorKeys.team)
  const [selectedTeams, setSelectedTeams] = useState<OptionInterface[]>([])
  const { data: locations } = useGetSelectors(selectorKeys.location)
  const [selectedLocations, setSelectedLocations] = useState<OptionInterface[]>([])
  return (
    <VStack gap="s-16">
      <SearchInput
        placeholder="Search by title, team, or specialisation"
        onFilter={newFilters => {
          onFilterChange({
            ...newFilters,
            disableQueryParam: true,
          })
        }}
      />
      <HStack gap="s-16">
        <FilterButtonCheckboxSelect
          label="Team"
          searchable
          width={300}
          options={teams || []}
          value={selectedTeams}
          onChange={newSelectedTeams => {
            setSelectedTeams(newSelectedTeams || [])
            onFilterChange({
              filters: newSelectedTeams || [],
              columnName: 'team__id',
              disableQueryParam: true,
            })
          }}
        />
        <FilterButtonCheckboxSelect
          label="Location"
          searchable
          width={300}
          options={locations || []}
          value={selectedLocations}
          onChange={newSelectedLocations => {
            setSelectedLocations(newSelectedLocations || [])
            onFilterChange({
              filters: newSelectedLocations || [],
              columnName: 'locations__id',
              disableQueryParam: true,
            })
          }}
        />
      </HStack>
    </VStack>
  )
}
