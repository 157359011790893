import React from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { CompanyInterface } from '@src/interfaces/company'
import { formatNumberMillions } from '@src/utils/format'
import Icon from '@components/Icon/Icon'
import isNil from 'lodash/isNil'
import { Token } from '@revolut/ui-kit'
import { EmployeeStats } from '@src/interfaces/employees'
import { FunctionInterface } from '@src/interfaces/functions'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { DepartmentCompensationReviewInterface } from '@src/interfaces/compensation'

const ContainerTooltip = styled(Tooltip)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const ColoredCircle = styled.div<{ color: string }>`
  display: inline-block;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.color};
`

const CircleContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`

const TooltipBody = styled.div`
  display: grid;
  padding: 8px;
  grid-gap: 4px;
`

const TooltipRow = styled.div`
  display: flex;
  justify-content: space-between;
`
const TooltipText = styled.div`
  color: ${Token.color.greyTone50};
  white-space: nowrap;
`
const TooltipValue = styled.div`
  margin-left: 16px;
  color: ${Token.color.greyTone50};
`

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

const ColoredTextMargin = styled(ColoredText)`
  margin-left: 2px;
`

interface Props {
  data:
    | TeamInterface
    | DepartmentInterface
    | CompanyInterface
    | EmployeeStats
    | FunctionInterface
    | RoleInterface
    | SpecialisationInterface
    | DepartmentCompensationReviewInterface
  stats?: (
    | 'culture_enps'
    | 'culture_values'
    | 'culture_compliance'
    | 'culture_manager_enps'
  )[]
  locked?: boolean
}

export const cultureValueToColor = (value: number) => {
  if (value < 0) {
    return Token.color.red
  }
  if (value < 20) {
    return Token.color.warning
  }
  return Token.color.green
}

const CultureCircles = ({
  data,
  stats = ['culture_enps', 'culture_values', 'culture_compliance'],
  locked,
}: Props) => {
  if (locked) {
    return (
      <Tooltip
        placement="right"
        text="To protect anonymity, we can’t reveal results as fewer than 3 employees answered the survey."
      >
        <Icon type="Lock" size="tiny" />
      </Tooltip>
    )
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  if (stats.every(stat => isNil(data[stat]))) {
    return <ColoredText color={Token.color.greyTone20}>N/A</ColoredText>
  }

  return (
    <ContainerTooltip
      body={
        <TooltipBody>
          {stats.includes('culture_enps') && data.culture_enps !== null && (
            <TooltipRow>
              <TooltipText>eNPS</TooltipText>
              <TooltipValue>{formatNumberMillions(data.culture_enps, 0)}</TooltipValue>
            </TooltipRow>
          )}
          {stats.includes('culture_values') && data.culture_values !== null && (
            <TooltipRow>
              <TooltipText>Values</TooltipText>
              <TooltipValue>{formatNumberMillions(data.culture_values, 0)}</TooltipValue>
            </TooltipRow>
          )}
          {stats.includes('culture_compliance') && data.culture_compliance !== null && (
            <TooltipRow>
              <TooltipText>Compliance</TooltipText>
              <TooltipValue>
                {formatNumberMillions(data.culture_compliance, 0)}
              </TooltipValue>
            </TooltipRow>
          )}
          {stats.includes('culture_manager_enps') &&
            'culture_manager_enps' in data &&
            data.culture_manager_enps !== null && (
              <TooltipRow>
                <TooltipText>Manager eNPS</TooltipText>
                <TooltipValue>
                  {formatNumberMillions(data.culture_manager_enps, 0)}
                </TooltipValue>
              </TooltipRow>
            )}
        </TooltipBody>
      }
      placement="right"
    >
      {stats.includes('culture_enps') && data.culture_enps !== null && (
        <CircleContainer>
          <ColoredCircle color={cultureValueToColor(data.culture_enps)} />
          <ColoredTextMargin color={cultureValueToColor(data.culture_enps)}>
            {formatNumberMillions(data.culture_enps, 0)}
          </ColoredTextMargin>
        </CircleContainer>
      )}
      {stats.includes('culture_values') && data.culture_values !== null && (
        <CircleContainer>
          <ColoredCircle color={cultureValueToColor(data.culture_values)} />
          <ColoredTextMargin color={cultureValueToColor(data.culture_values)}>
            {formatNumberMillions(data.culture_values, 0)}
          </ColoredTextMargin>
        </CircleContainer>
      )}
      {stats.includes('culture_compliance') && data.culture_compliance !== null && (
        <CircleContainer>
          <ColoredCircle color={cultureValueToColor(data.culture_compliance)} />
          <ColoredTextMargin color={cultureValueToColor(data.culture_compliance)}>
            {formatNumberMillions(data.culture_compliance, 0)}
          </ColoredTextMargin>
        </CircleContainer>
      )}
      {stats.includes('culture_manager_enps') &&
        'culture_manager_enps' in data &&
        data.culture_manager_enps !== null && (
          <CircleContainer>
            <ColoredCircle color={cultureValueToColor(data.culture_manager_enps)} />
            <ColoredTextMargin color={cultureValueToColor(data.culture_manager_enps)}>
              {formatNumberMillions(data.culture_manager_enps, 0)}
            </ColoredTextMargin>
          </CircleContainer>
        )}
    </ContainerTooltip>
  )
}

export default CultureCircles
