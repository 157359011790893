import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  Flex,
  Header,
  HStack,
  InputGroup,
  Popup,
  Switch,
  Text,
} from '@revolut/ui-kit'
import {
  useUpdateDashboardDeployments,
  useSaveDashboardDeployments,
} from '@src/api/analyticsDashboards'
import { useTenantSelector } from '@src/api/tenants'
import { IdAndName } from '@src/interfaces'
import {
  TenantDashboardDeploymentInterface,
  InternalDashboardInterface,
} from '@src/interfaces/analyticsDashboards'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface Props {
  onClose: () => void
  open: boolean
  dashboard: InternalDashboardInterface
}

export const TenantDeploymentPopup = ({ onClose, open, dashboard }: Props) => {
  const statusPopup = useShowStatusPopup()
  const { data: tenants } = useTenantSelector([])
  const { mutateAsync: updateDashboardDeployments } = useUpdateDashboardDeployments()
  const { mutateAsync: saveDashboardDeployments } = useSaveDashboardDeployments()

  const tenantsOptions = useMemo(
    () =>
      (tenants?.options || []).map(item => ({
        label: item.name,
        value: item,
      })),
    [tenants],
  )

  const [isAllTenants, setIsAllTenants] = useState(
    dashboard.tenant_deployment?.all_tenants || false,
  )
  const [isPending, setIsPending] = useState(false)
  const [selectedTenants, setSelectedTenants] = useState<
    RadioSelectOption<IdAndName<string>>[]
  >(
    (dashboard.tenant_deployment?.tenants || []).map(item => ({
      label: item.name,
      value: item,
    })),
  )

  const handleSaveDeployment = async (
    cb: (
      deploymentData: Omit<TenantDashboardDeploymentInterface, 'id'>,
    ) => ReturnType<typeof updateDashboardDeployments>,
  ) => {
    setIsPending(true)
    const deploymentData = {
      all_tenants: isAllTenants,
      tenants: selectedTenants.map(item => item.value),
      dashboard_id: dashboard.id,
    }

    try {
      await cb(deploymentData)

      statusPopup({
        title: 'Dashboard deployed',
      })
    } catch {
      statusPopup({
        title: 'Dashboard was not deployed',
        status: 'error',
      })
    } finally {
      setIsPending(false)
      onClose()
    }
  }

  const saveDeployment = () => {
    if (dashboard.tenant_deployment) {
      handleSaveDeployment(data =>
        updateDashboardDeployments([dashboard.tenant_deployment?.id!, data]),
      )
    } else {
      handleSaveDeployment(data => saveDashboardDeployments(data))
    }
  }

  return (
    <Popup onClose={onClose} open={open} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>Select tenants to deploy this dashboard to</Header.Title>
        <Header.Subtitle>
          If you select all current and future tenants the dashboard will be deployed to
          all tenants. Otherwise you can select the tenants to deploy the dashboard to
          individually.
        </Header.Subtitle>
      </Header>
      <InputGroup>
        <Flex alignItems="center" justifyContent="space-between">
          <Text variant="caption">Deploy to all tenants and all future tenants</Text>
          <Switch
            checked={isAllTenants}
            onChange={e => setIsAllTenants(e.target.checked)}
          />
        </Flex>
        {!isAllTenants && (
          <NewMultiSelect
            onChange={val => {
              setSelectedTenants(val)
            }}
            options={tenantsOptions}
            placeholder="Tenants"
            required
            value={selectedTenants}
          />
        )}
      </InputGroup>
      <Popup.Actions>
        <HStack space="s-16">
          <ActionButton onClick={onClose}>Cancel</ActionButton>
          <ActionButton variant="accent" onClick={saveDeployment} pending={isPending}>
            Submit
          </ActionButton>
        </HStack>
      </Popup.Actions>
    </Popup>
  )
}
