import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { reportRequestsNew } from '@src/api/dataAnalytics'
import { ROUTES } from '@src/constants/routes'
import { ReportInterface } from '@src/interfaces/dataAnalytics'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import { pathToUrl } from '@src/utils/router'
import { NextButton } from './components/NextButton'
import SubmitButton from './components/SubmitButton'
import { ContentForm } from './ContentForm'
import { FormatForm } from './FormatForm'
import { TargetForm } from './TargetForm'

const Report = connect(() => {
  const params = useParams<{ id: string }>()
  const { values } = useLapeContext<ReportInterface>()

  const getTabs = () => [
    {
      title: 'Select Type',
      path: ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET, params),
      component: TargetForm,
      preTitle: <ValidationPre isVisible isValid={!!values.report_type} />,
      visibilityCheck: () => true,
    },
    {
      title: 'Add Content',
      path: ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT, params),
      component: ContentForm,
      preTitle: <ValidationPre isVisible isValid={!!values.name && !!values.query?.id} />,
      visibilityCheck: () => true,
    },
    {
      title: 'Format Data',
      path: ROUTES.FORMS.DATA_ANALYTICS_REPORT.FORMAT,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.FORMAT, params),
      component: FormatForm,
      preTitle: <ValidationPre isVisible isValid={!!values.name && !!values.query?.id} />,
      visibilityCheck: () => true,
    },
  ]

  const tabs = getTabs()

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.APPS.DATA_ANALYTICS.REPORTS}
        title={!params.id ? 'Add report' : values.name}
      >
        <TabBarNavigation tabs={tabs} />
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET}>
          <TargetForm />
        </Route>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT}>
          <ContentForm />
        </Route>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_REPORT.FORMAT}>
          <FormatForm />
        </Route>
      </Switch>
      <PageActions>
        <NextButton tabs={tabs} />
        <SubmitButton tabs={tabs} />
      </PageActions>
    </PageWrapper>
  )
})

const ReportForm = () => {
  return (
    <Form
      api={reportRequestsNew}
      localStorageProps={{
        followUrlTransitions: true,
        removeDataAfterTransitionFromUrls: [
          pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.TARGET),
          pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.CONTENT),
          pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_REPORT.FORMAT),
        ],
      }}
    >
      <Report />
    </Form>
  )
}

export default connect(ReportForm)
