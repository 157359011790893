import React from 'react'
import { Color, Text } from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { PerformanceRating } from '@src/interfaces/performance'

type Props = {
  funcManagerRating?: PerformanceRating
  lineManagerRating?: PerformanceRating
}

const GradesNotice = ({ lineManagerRating, funcManagerRating }: Props) => {
  if (lineManagerRating && !funcManagerRating) {
    return (
      <Text color={Color.GREY_TONE_50}>
        Based on LM rating ({PerformanceRatingTitle[lineManagerRating]})
      </Text>
    )
  }

  if (funcManagerRating && !lineManagerRating) {
    return (
      <Text color={Color.GREY_TONE_50}>
        Based on FM rating ({PerformanceRatingTitle[funcManagerRating]})
      </Text>
    )
  }

  if (funcManagerRating && lineManagerRating) {
    return (
      <Text color={Color.GREY_TONE_50}>
        Based on LM ({PerformanceRatingTitle[lineManagerRating]}) and FM (
        {PerformanceRatingTitle[funcManagerRating]}) ratings
      </Text>
    )
  }

  return null
}

export default GradesNotice
