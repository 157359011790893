import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Loader from '@components/CommonSC/Loader'
import styled from 'styled-components'
import { RequestInterfaceNew, Statuses } from '@src/interfaces'
import LapeForm, { LapeFormInterface } from '@src/features/Form/LapeForm'
import { connect, useLape } from 'lape'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import merge from 'lodash/merge'
import {
  FinalGrade,
  PerformanceKPISection,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import set from 'lodash/set'
import get from 'lodash/get'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import PerformanceErrorWidget from '@src/pages/Forms/EmployeePerformance/components/PerformanceErrorWidget'

const LoaderContainer = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

interface Props {
  children: React.ReactNode
  api: RequestInterfaceNew<ReviewScorecardInterface>
  storageKey: string
  onChange?: (values: any) => void
}

type FeedbackOption = {
  key: FinalGrade
  items: string[]
}

const Form = ({ api, children, storageKey, onChange }: Props) => {
  const params = useParams<{ id: string; new: string }>()
  const [isError, setIsError] = useState(false)

  const location = useLocation<{ initialValues: any; returnAs: string }>()
  const initialValues = location?.state?.initialValues || {}

  const savedData = workspaceLocalStorage.getItem(storageKey)
  const parsedSavedData: Partial<ReviewScorecardInterface> = savedData
    ? JSON.parse(savedData)
    : {}
  const initialFormData = useMemo(
    () => ({ ...parsedSavedData, ...initialValues }),
    [initialValues],
  )
  const state = useLape({
    loading: true,
    formData: initialFormData,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        state.loading = true
        const { data } = await api.get(params)
        if (data.status !== Statuses.completed) {
          // we must not cache these values in localstorage, it should be always actual
          delete parsedSavedData.current_user_can_edit_review
          delete parsedSavedData.status

          // TODO: remove adjustExtraSection after 11/01/2024
          // extra sections options descriptions changed on BE, need to support on FE
          const adjustExtraSection = (
            field: string,
            localFeedbackOptions?: FeedbackOption[],
            parsedFeedbackOptions?: FeedbackOption[],
          ) => {
            if (
              localFeedbackOptions &&
              parsedFeedbackOptions &&
              localFeedbackOptions[0]?.items?.[0] !== parsedFeedbackOptions[0]?.items?.[0]
            ) {
              const dataOptions = get(data, field)
              set(parsedSavedData, field, dataOptions)
            }
          }

          // LM extra section
          adjustExtraSection(
            'review_data.line_manager_extra_section.employee_project_performance.options',
            parsedSavedData.review_data?.line_manager_extra_section
              ?.employee_project_performance.options,
            data.review_data?.line_manager_extra_section?.employee_project_performance
              .options,
          )

          // FM extra section
          adjustExtraSection(
            'review_data.functional_manager_extra_section.employee_project_performance.options',
            parsedSavedData.review_data?.functional_manager_extra_section
              ?.employee_project_performance.options,
            data.review_data?.functional_manager_extra_section
              ?.employee_project_performance.options,
          )

          // Peer extra section
          adjustExtraSection(
            'review_data.peer_extra_section.employee_project_performance.options',
            parsedSavedData.review_data?.peer_extra_section?.employee_project_performance
              .options,
            data.review_data?.peer_extra_section?.employee_project_performance.options,
          )

          // comments from kpis_section shouldn't be removed
          const kpisSection: Partial<PerformanceKPISection> | undefined =
            parsedSavedData.review_data?.kpis_section
          delete kpisSection?.rating
          delete kpisSection?.kpi_items
          delete kpisSection?.ratings
          delete kpisSection?.performance

          state.formData = merge(data, parsedSavedData)
        } else {
          state.formData = data
        }
      } catch (e) {
        setIsError(true)
      } finally {
        state.loading = false
      }
    }

    fetchData()
  }, [params.id])

  const submit = async (form: LapeFormInterface<ReviewScorecardInterface>) => {
    return api.update(form.values, params, form.values).then(({ data }) => {
      form.reset(data)
      return data
    })
  }

  if (isError) {
    return <PerformanceErrorWidget message="Failed to get performance review data" />
  }

  if (state.loading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    )
  }

  return (
    <LapeForm
      onSubmit={submit}
      validation={{}}
      initialValues={state.formData}
      onChange={onChange}
    >
      <FormLocalstorageLape isExistingData={false} url={storageKey} />
      {children}
    </LapeForm>
  )
}

export default connect(Form)
