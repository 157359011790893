import { Text, Widget, VStack, Link } from '@revolut/ui-kit'
import React from 'react'
import { FEEDBACK_PROVIDING_GUIDELINES } from '@src/constants/externalLinks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

const SummaryBanner = () => {
  return (
    <Widget backgroundColor="red-action-background" color="grey-tone-50">
      <VStack space="s-16" p="s-16">
        <Text use="p">
          Use this section to provide actionable feedback to the employee based on their
          strengths and areas of development. Grade rationale should be captured in the
          previous recommendation section.
        </Text>

        <HideIfCommercial>
          <Text use="p">
            Please refer to the guidelines{' '}
            <Link target="_blank" href={FEEDBACK_PROVIDING_GUIDELINES}>
              here
            </Link>{' '}
            to know more about providing feedback.
          </Text>
        </HideIfCommercial>
      </VStack>
    </Widget>
  )
}

export default SummaryBanner
