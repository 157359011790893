import React, { useState } from 'react'
import { Box, Token, Flex, Tooltip, useTooltip } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { get } from 'lodash'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { deleteImportTimeOffBalancesSessionRow } from '@src/api/importData'
import EditableCell from '@src/components/Table/AdvancedCells/EditableCell/EditableCell'
import {
  ImportTimeOffBalancesDataInterface,
  ImportTimeOffBalancesInterface,
} from '@src/interfaces/importTimeOffBalances'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { DropdownSelectCell } from '@src/components/Table/AdvancedCells/DropdownSelectCell/DropdownSelectCell'

type Option = EmployeeEmails | IdAndName<string>

export type ImportTimeOffBalancesInputSelectCellOptions = {
  employees: EmployeeEmails[]
  policies: IdAndName<string>[]
}

type ImportTimeOffBalancesSelectInputField = 'email' | 'policy'

type ImportTimeOffBalancesInputField = 'balance'

export type ImportTimeOffBalancesSelectInputOnChange = (
  row: ImportTimeOffBalancesInterface,
  option: Option | null,
  field: ImportTimeOffBalancesSelectInputField,
) => void

type ImportTimeOffBalanceselectInputColumn = (
  onChange: ImportTimeOffBalancesSelectInputOnChange,
  options: Option[],
) => ColumnInterface<ImportTimeOffBalancesInterface>

export type ImportTimeOffBalancesInputOnChange = (
  row: ImportTimeOffBalancesInterface,
  value: string | undefined,
  field: ImportTimeOffBalancesInputField,
) => void

type ImportTimeOffBalancesInputColumn = (
  onChange: ImportTimeOffBalancesInputOnChange,
) => ColumnInterface<ImportTimeOffBalancesInterface>

interface ImportTimeOffBalancesSelectInputProps {
  onChange: ImportTimeOffBalancesSelectInputOnChange
  options: Option[]
  field: ImportTimeOffBalancesSelectInputField
  data: ImportTimeOffBalancesInterface
}

const ImportTimeOffBalancesSelectInput = ({
  data,
  onChange,
  options,
  field,
}: ImportTimeOffBalancesSelectInputProps) => {
  const fieldValue = data.data[field]

  const label = (() => {
    if (fieldValue && field === 'email') {
      const foundOption = options?.find(opt => opt.name === fieldValue)
      return foundOption && 'full_name' in foundOption
        ? foundOption.full_name
        : fieldValue
    }

    if (fieldValue) {
      const foundOption = options?.find(
        opt => opt.name === fieldValue || opt.id === fieldValue,
      )
      return foundOption?.name || fieldValue
    }

    return '-'
  })()

  return (
    <CellWithError data={data} field={field}>
      <RadioSelectInput
        onChange={option => onChange(data, option, field)}
        options={options.map(value => ({
          label: 'full_name' in value ? value.full_name : value.name,
          value,
        }))}
        renderInput={(open, setOpen, ref) => (
          <DropdownSelectCell
            label={label}
            open={open}
            onClick={() => setOpen(!open)}
            ref={ref}
          />
        )}
      />
    </CellWithError>
  )
}

interface CellWithErrorProps {
  data: ImportTimeOffBalancesInterface
  field: keyof ImportTimeOffBalancesDataInterface
}

const CellWithError: React.FC<CellWithErrorProps> = ({ data, field, children }) => {
  const tooltip = useTooltip()

  const errors = get(data.errors, field)?.join('\n')

  return (
    <Flex alignItems="center" {...tooltip.getAnchorProps()}>
      <Box flex="1" color={errors ? Token.color.danger : undefined}>
        {children}
      </Box>
      {errors ? (
        <>
          <Box width={18}>
            <ExclamationTriangle
              color={Token.color.danger}
              aria-label={`${field} error`}
              size={18}
            />
          </Box>
          <Tooltip {...tooltip.getTargetProps()} maxWidth={400}>
            {errors}
          </Tooltip>
        </>
      ) : null}
    </Flex>
  )
}

interface ImportTimeOffBalancesInputCellProps {
  data: ImportTimeOffBalancesInterface
  field: ImportTimeOffBalancesInputField
  onChange: ImportTimeOffBalancesInputOnChange
}

const InputCell = ({ data, field, onChange }: ImportTimeOffBalancesInputCellProps) => {
  const [value, setValue] = useState(data.data[field] || '')

  return (
    <CellWithError data={data} field={field}>
      <EditableCell
        type={TableCellInputType.text}
        value={value}
        onChange={val => setValue(val as string)}
        onBlur={val => onChange(data, val as string, field)}
      />
    </CellWithError>
  )
}

export const importTimeOffBalancesEmployeeColumn: ImportTimeOffBalanceselectInputColumn =
  (onChange, options) => ({
    type: CellTypes.insert,
    idPoint: 'email',
    dataPoint: 'email',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => (
      <ImportTimeOffBalancesSelectInput
        data={data}
        onChange={onChange}
        options={options}
        field="email"
      />
    ),
  })

export const importTimeOffBalancesBalanceColumn: ImportTimeOffBalancesInputColumn = (
  onChange,
): ColumnInterface<ImportTimeOffBalancesInterface> => ({
  type: CellTypes.insert,
  idPoint: 'balance',
  dataPoint: 'balance',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Balance',
  insert: ({ data }) => <InputCell data={data} onChange={onChange} field="balance" />,
})

export const importTimeOffBalancesPolicyColumn: ImportTimeOffBalanceselectInputColumn = (
  onChange,
  options,
) => ({
  type: CellTypes.insert,
  idPoint: 'policy',
  dataPoint: 'policy',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Policy',
  insert: ({ data }) => (
    <ImportTimeOffBalancesSelectInput
      data={data}
      onChange={onChange}
      options={options}
      field="policy"
    />
  ),
})

interface DeleteRowActionProps {
  data: ImportTimeOffBalancesInterface
  onDeleteSuccess: () => void
  sessionId: string
}

const DeleteRowAction = ({ data, onDeleteSuccess, sessionId }: DeleteRowActionProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await deleteImportTimeOffBalancesSessionRow(sessionId, data.id)
      setConfirmationOpen(false)
      setDeletePending(false)

      onDeleteSuccess()
    } catch {
      setDeletePending(false)
    }
  }

  return (
    <>
      <TableActionButton
        onClick={e => {
          e.stopPropagation()
          setConfirmationOpen(true)
        }}
        color="red"
      >
        Remove
      </TableActionButton>

      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        label="Are you sure you want to remove this balance?"
        body=""
      />
    </>
  )
}

export const importTimeOffBalancesActionColumn = (
  onDeleteSuccess: () => void,
  sessionId: string,
): ColumnInterface<ImportTimeOffBalancesInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <DeleteRowAction
      data={data}
      onDeleteSuccess={onDeleteSuccess}
      sessionId={sessionId}
    />
  ),
})
