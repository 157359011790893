import React, { useState } from 'react'
import { Flex, LineClamp, Text, TextButton } from '@revolut/ui-kit'
import styled from 'styled-components'

const StyledText = styled(Text)`
  word-break: break-word;
`

export const EvidenceDescription = ({ text }: { text: string }) => {
  const isLongComment = text.split('\n').length > 3 || text.length > 300
  const [showMore, setShowMore] = useState<boolean>(false)

  const toggle = () => setShowMore(!showMore)

  return isLongComment ? (
    <Flex flexDirection="column">
      {showMore ? (
        <StyledText variant="caption">{text}</StyledText>
      ) : (
        <LineClamp max={3}>
          <StyledText variant="caption">{text}</StyledText>
        </LineClamp>
      )}
      <TextButton onClick={toggle} fontSize="14px" height="20px" mt="s-8">
        {showMore ? 'Show less' : 'Show more'}
      </TextButton>
    </Flex>
  ) : (
    <StyledText variant="caption">{text}</StyledText>
  )
}
