import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { anonymizeCandidate, useGetCandidate } from '@src/api/recruitment/interviews'
import { Button, TextWidget, VStack, Text } from '@revolut/ui-kit'
import { PageActions } from '@components/Page/PageActions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { navigateTo } from '@src/actions/RouterActions'
import { successNotification } from '@src/actions/NotificationActions'

const General = () => {
  const { id } = useParams<{ id: string }>()
  const { data: candidate } = useGetCandidate(id)
  const [openDialog, setOpenDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleAnonymize = async () => {
    setIsLoading(true)
    try {
      await anonymizeCandidate(id)
      successNotification('Candidate anonymized')
      navigateTo(pathToUrl(ROUTES.RECRUITMENT.CANDIDATES))
    } catch {
      setIsLoading(false)
      setOpenDialog(false)
    }
  }

  return (
    <PageWrapper>
      <ConfirmationDialog
        open={openDialog}
        loading={isLoading}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleAnonymize}
        onReject={() => setOpenDialog(false)}
        yesMessage="Anonymize"
        noMessage="Cancel"
        label="This action can not be undone"
        body={
          <VStack space="s-24" color="grey-tone-50">
            <Text>
              You will no longer have access to the candidate’s dashboard or profile. You
              will also not be able to view the candidate on the candidate table.
            </Text>
            <Text>
              You will not be able to revert this. Please confirm you want to proceed The
              access is about to be permanently revoked.
            </Text>
          </VStack>
        }
      />
      <PageHeader
        title="Anonymize candidate data"
        backUrl={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}
      />
      <PageBody>
        <VStack space="s-16">
          <ActionWidget
            title={`You are anonymizing candidate: ${candidate?.full_name || ''}`}
            text="This will remove candidate’s personal information while keeping some data for reporting purposes. You will no longer have access to the candidate’s dashboard or profile. You will also not be able to view the candidate on the candidate table. Please review if candidate is under any active interview process or not before anonymising the candidate."
          />
          <TextWidget>
            <TextWidget.Title color="red">Things we will delete</TextWidget.Title>
            <TextWidget.Content>
              <ul style={{ margin: 0, paddingInlineStart: 24 }}>
                <li>Name</li>
                <li>Email address, email threads stored in Revoluters</li>
                <li>Phone number</li>
                <li>Current location & preferred work locations</li>
                <li>Current company</li>
                <li>Resume, CVs and other files</li>
                <li>Website, social links</li>
                <li>Scheduled emails</li>
                <li>Upcoming interview invites</li>
                <li>Offer document</li>
              </ul>
            </TextWidget.Content>
          </TextWidget>
          <TextWidget>
            <TextWidget.Title>Things we will retain</TextWidget.Title>
            <TextWidget.Content>
              <ul style={{ margin: 0, paddingInlineStart: 24 }}>
                <li>Candidate origin, source, tags</li>
                <li>Comments</li>
                <li>Custom question responses in application forms</li>
                <li>Interview feedbacks</li>
                <li>Timeline events</li>
              </ul>
            </TextWidget.Content>
          </TextWidget>
        </VStack>
      </PageBody>
      <PageActions>
        <Button onClick={() => setOpenDialog(true)} elevated>
          Anonymize {candidate?.full_name || ''}
        </Button>
      </PageActions>
    </PageWrapper>
  )
}

export default General
