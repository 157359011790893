import { connect } from 'lape'
import InterviewFeedbackForm from '@src/features/InterviewFeedbackForm'
import React, { useEffect } from 'react'
import {
  InterviewRoundInterface,
  InterviewFeedbackInterface,
  InterviewType,
} from '@src/interfaces/interviewTool'
import { useLapeContext } from '@src/features/Form/LapeForm'

type Props = {
  scorecard?: InterviewFeedbackInterface
  round?: InterviewRoundInterface
  stageType?: InterviewType
}

const SidebarForm = ({ scorecard, round, stageType }: Props) => {
  const form = useLapeContext<InterviewFeedbackInterface>()
  const { values } = form

  useEffect(() => {
    if (scorecard) {
      form.values = scorecard
    }
  }, [scorecard?.id])

  if (!values.scorecard) {
    return null
  }

  return <InterviewFeedbackForm isViewMode round={round} stageType={stageType} />
}

export default connect(SidebarForm)
