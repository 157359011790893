import React from 'react'
import { Group, VStack } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const General = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="All settings related to legal entities"
          />
          <VStack space="s-24">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable location hiring limitations',
                  description:
                    'Allow configuring limitations for having external employees in certain locations for a particular entity',
                }}
                name="enable_location_limitations"
                disabled={disableEdit}
              />
            </Group>
          </VStack>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
          }}
          useValidator
          successText="Settings saved"
        />
      </PageActions>
    </>
  )
}
