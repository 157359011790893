import React, { useState, useRef } from 'react'
import {
  Dropdown,
  RadioGroup,
  ColorSelector,
  Grid,
  Box,
  Action,
  Token,
} from '@revolut/ui-kit'
import { CHART_COLORS } from '@src/pages/Forms/QueryForm/components/Charts/constants'
import { BaseColor } from '@src/pages/Forms/QueryForm/components/Charts/types'

interface ColorSelectProps {
  color: BaseColor
  onChange: (color: BaseColor) => void
}

export const ColorSelect = ({ color, onChange }: ColorSelectProps) => {
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleChange = (value: BaseColor | null) => {
    if (value) {
      onChange(value)
    }
    setOpen(false)
  }

  return (
    <>
      <Action ref={anchorRef} onClick={() => setOpen(!open)}>
        <ColorSelector
          aria-label="Select color"
          bg={color || Token.color.greyTone20}
          checked
          size={20}
        />
      </Action>
      <Dropdown open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <Box bg={Token.color.widgetBackground} p="s-8">
          <RadioGroup onChange={handleChange}>
            {group => (
              <Grid gap="s-8" columns={4}>
                {CHART_COLORS.map(colorValue => (
                  <ColorSelector
                    bg={colorValue}
                    aria-label={colorValue}
                    size={32}
                    {...group.getInputProps({ value: colorValue })}
                    key={colorValue}
                    checked={colorValue === color}
                  />
                ))}
              </Grid>
            )}
          </RadioGroup>
        </Box>
      </Dropdown>
    </>
  )
}
