import React from 'react'
import { VStack, Text, Token } from '@revolut/ui-kit'

interface TitleAndSubtitleProps {
  title: React.ReactNode
  subtitle?: React.ReactNode
}

export const TitleAndSubtitle = ({ title, subtitle }: TitleAndSubtitleProps) => {
  return (
    <VStack space="s-8">
      <Text variant="primary">{title}</Text>
      {subtitle ? (
        <Text variant="caption" color={Token.color.greyTone50}>
          {subtitle}
        </Text>
      ) : null}
    </VStack>
  )
}
