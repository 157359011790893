import SideBar from '@components/SideBar/SideBar'
import { Button, DragAndDrop, Flex, Group, IconButton, Item, Side } from '@revolut/ui-kit'
import React, { useCallback, useState } from 'react'
import { Drag, MinusCircle, PlusCircle } from '@revolut/icons'
import {
  TableColumnSettingsInterface,
  TableSettingsInterface,
} from '@src/interfaces/tableSettings'
import { TableNames } from '@src/constants/table'
import { LocalStorageKeys } from '@src/store/auth/types'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { move } from '@src/utils/move'

interface TableSettingsProps {
  name: TableNames
  isOpen: boolean
  onClose: () => void
  onSave: (settings?: TableSettingsInterface) => void
  columnSettings: TableColumnSettingsInterface
}

const TableSettings = ({
  name,
  isOpen,
  onClose,
  onSave,
  columnSettings,
}: TableSettingsProps) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const { visible, hidden } = columnSettings
  const initialState = {
    visible: visible.slice(1).map(cell => cell.title),
    hidden: hidden.map(cell => cell.title),
  }
  const [tableSettings, setTableSettings] = useState<TableSettingsInterface>(initialState)

  const [activeId, setActiveId] = useState(null)

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setTableSettings(current => ({
        ...current,
        visible:
          startIndex === endIndex
            ? current.visible
            : move(current.visible, startIndex, endIndex),
      }))
    }

    setActiveId(null)
  }, [])

  const activeItem = activeId && tableSettings.visible.find(item => item === activeId)

  const handleRemove = (title: string) => {
    setTableSettings(current => ({
      visible: current.visible.filter(t => {
        return t !== title
      }),
      hidden: [...current.hidden, title],
    }))
  }

  const handleAdd = (title: string) => {
    setTableSettings(current => ({
      visible: [...current.visible, title],
      hidden: current.hidden.filter(t => {
        return t !== title
      }),
    }))
  }

  const handleSave = () => {
    sendAnalyticsEvent(AnalyticsEvents.click_update_table_column_settings_btn, {
      table_name: name,
    })
    workspaceLocalStorage.setItem(
      LocalStorageKeys.TABLE_SETTINGS_KEY_TEMPLATE.replace('{}', name),
      JSON.stringify(tableSettings),
    )
    onSave(tableSettings)
    onClose()
  }

  const handleReset = () => {
    workspaceLocalStorage.removeItem(
      LocalStorageKeys.TABLE_SETTINGS_KEY_TEMPLATE.replace('{}', name),
    )
    onSave(undefined)
    onClose()
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title="Column settings"
      subtitle="Choose what columns you want to have in the table and in what order"
      usePortal
    >
      <Flex flexDirection="column" gap="s-16">
        <Group>
          <DragAndDrop.Provider
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragCancel={onDragCancel}
          >
            <DragAndDrop.Sortable
              id="sortable-table-preferences"
              items={tableSettings.visible}
            >
              {sortable => {
                const title = tableSettings.visible.find(item => item === sortable.id)
                return title ? (
                  <Item
                    ref={sortable.setNodeRef}
                    style={{
                      transform: sortable.transform
                        ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                        : undefined,
                      transition: sortable.transition || 'none',
                      opacity: sortable.isDragging ? 0 : undefined,
                    }}
                  >
                    <Item.Prefix>
                      <IconButton
                        aria-label="Remove"
                        useIcon={MinusCircle}
                        color="red"
                        onClick={() => handleRemove(title)}
                        size={16}
                      />
                    </Item.Prefix>
                    <Item.Content>
                      <Item.Title>{title}</Item.Title>
                    </Item.Content>
                    <Item.Side>
                      <IconButton
                        aria-label="Drag"
                        useIcon={Drag}
                        color="grey-tone-20"
                        {...sortable.attributes}
                        {...sortable.listeners}
                        size={16}
                      />
                    </Item.Side>
                  </Item>
                ) : null
              }}
            </DragAndDrop.Sortable>
            <DragAndDrop.DragOverlay>
              {activeItem && (
                <Item>
                  <Item.Prefix>
                    <IconButton
                      aria-label="Remove"
                      useIcon={MinusCircle}
                      color="red"
                      onClick={() => {}}
                      size={16}
                    />
                  </Item.Prefix>
                  <Item.Content>
                    <Item.Title>{activeItem}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <IconButton
                      aria-label="Drag"
                      useIcon={Drag}
                      color="grey-tone-20"
                      onClick={() => {}}
                      size={16}
                    />
                  </Item.Side>
                </Item>
              )}
            </DragAndDrop.DragOverlay>
          </DragAndDrop.Provider>
        </Group>

        <Group>
          {tableSettings.hidden.map(title => {
            return (
              <Item key={title}>
                <Item.Prefix>
                  <IconButton
                    aria-label="Add"
                    useIcon={PlusCircle}
                    color="blue"
                    onClick={() => handleAdd(title)}
                    size={16}
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{title}</Item.Title>
                </Item.Content>
              </Item>
            )
          })}
        </Group>
      </Flex>
      <Side.Actions>
        <Button onClick={handleReset} variant="secondary">
          Reset
        </Button>
        <Button elevated onClick={handleSave}>
          Done
        </Button>
      </Side.Actions>
    </SideBar>
  )
}

export default TableSettings
