import React, { useRef, useState } from 'react'
import { Box, Cell, useIntersectViewport } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'

interface LoadSectionInViewProps {
  placeholder?: React.ReactNode
}

const LoadSectionInView: React.FC<LoadSectionInViewProps> = ({
  placeholder,
  children,
}) => {
  const ref = useRef(null)
  const [isInView, setIsInView] = useState(false)

  useIntersectViewport(
    ref,
    isIntersecting => {
      if (!isInView && isIntersecting) {
        setIsInView(true)
      }
    },
    0.8,
  )

  return isInView ? (
    <>{children}</>
  ) : (
    <Box ref={ref}>
      {placeholder || (
        <Cell>
          <Stat label="" />
        </Cell>
      )}
    </Box>
  )
}

export default LoadSectionInView
