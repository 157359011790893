import React, { ReactNode } from 'react'
import { useFetch } from '@src/utils/reactQuery'
import { OnboardingCheckpointInterface } from '@src/interfaces/onboardingChecklist'
import { API } from '@src/constants/api'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { ActionButton, Avatar, Box, Item, Tile, Token } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'

export const OnboardingV1Experience = ({ children }: { children: ReactNode }) => {
  const { data, isLoading, isError } = useFetch<{
    results: OnboardingCheckpointInterface[]
  }>(API.ONBOARDING_CHECKPOINTS)

  const engagementCheckpoint = data?.results.find(
    ({ category }) => category === 'engagement',
  )
  if (isLoading) {
    return <PageLoading />
  }

  // some tenants may not be migrated on onboarding flow v1 with engagement check point
  // in order to not block engagement we go straight to component without the onboarding
  if (!engagementCheckpoint) {
    return <>{children}</>
  }

  if (engagementCheckpoint?.state.id === 'completed' || isError) {
    return <>{children}</>
  }

  return (
    <Box maxWidth={536}>
      <Tile
        variant="voucher"
        image={'https://assets.revolut.com/revoluters/assets/onboard-surveys.png'}
        onClick={() => navigateTo(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.INTRO)}
      >
        <Tile.Avatar>
          <Avatar
            useIcon="Form"
            size={56}
            color={Token.color.accent}
            bg={Token.color.white}
          />
        </Tile.Avatar>
        <Tile.Title>Explore Surveys</Tile.Title>
        <Tile.Footer>
          <Item>
            <Item.Content>
              <Item.Title>
                Gather valuable insights to boost employee engagement.
              </Item.Title>
            </Item.Content>
            <Item.Side>
              <ActionButton use="span">Get started</ActionButton>
            </Item.Side>
          </Item>
        </Tile.Footer>
      </Tile>
    </Box>
  )
}
