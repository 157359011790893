import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'

export type StageIdParam =
  | 'departmentGoals'
  | 'teamGoals'
  | 'calibration'
  | 'review'
  | 'managersPublish'
  | 'employeesPublish'
  | 'employeesPublished'

export const reviewStageToUrlParamMap: Record<ReviewCycleStage, StageIdParam> = {
  [ReviewCycleStage.DepartmentGoals]: 'departmentGoals',
  [ReviewCycleStage.TeamGoals]: 'teamGoals',
  [ReviewCycleStage.Calibration]: 'calibration',
  [ReviewCycleStage.Review]: 'review',
  [ReviewCycleStage.ManagersPublish]: 'managersPublish',
  [ReviewCycleStage.EmployeesPublish]: 'employeesPublish',
  [ReviewCycleStage.Completed]: 'employeesPublished',
}

export const urlParamToReviewStageMap: Record<StageIdParam, ReviewCycleStage> = {
  departmentGoals: ReviewCycleStage.DepartmentGoals,
  teamGoals: ReviewCycleStage.TeamGoals,
  calibration: ReviewCycleStage.Calibration,
  review: ReviewCycleStage.Review,
  managersPublish: ReviewCycleStage.ManagersPublish,
  employeesPublish: ReviewCycleStage.EmployeesPublish,
  employeesPublished: ReviewCycleStage.Completed,
}

interface StageHistory {
  stage?: ReviewCycleStage
  update: (stage: ReviewCycleStage) => void
}

export const useStageHistory = (cycle: ReviewCyclesInterface): StageHistory => {
  const { stageId } = useParams<{ stageId: StageIdParam }>()
  const stage = urlParamToReviewStageMap[stageId]

  const update = (nextStage: ReviewCycleStage) => {
    navigateReplace(
      pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, {
        id: cycle.id,
        stageId: reviewStageToUrlParamMap[nextStage],
      }),
    )
  }

  return {
    stage,
    update,
  }
}
