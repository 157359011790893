import React from 'react'
import { VStack, Widget } from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { PageBody } from '@components/Page/PageBody'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { performanceSettings } from '@src/api/performanceSettings'
import { Managers } from '@src/pages/Settings/Performance/Managers'

const CommitteeForm = () => {
  return (
    <>
      <PageBody>
        <VStack>
          <SectionTitle
            title="Who will be responsible for managing the cycle and committee decision?"
            subtitle="These users are often HR or People managers responsible for ensuring compliance to probation"
          />
          <Widget p="s-24">
            <Managers type="probation" showTitle={false} />
          </Widget>
        </VStack>
      </PageBody>
    </>
  )
}

export const Committee = connect(() => {
  return (
    <Form api={performanceSettings} forceParams={{ id: '1' }}>
      <CommitteeForm />
    </Form>
  )
})
