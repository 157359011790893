import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box, Group, MoreBar, Text, Token, VStack } from '@revolut/ui-kit'

import {
  archiveWorkScheduleCustomApprovers,
  workScheduleCustomApproversRequests,
} from '@src/api/workSchedule'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { WorkScheduleCustomApprovalFlowInterface } from '@src/interfaces/workSchedule'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { CustomApproversEligibilityTable } from '../components/EligibilityTable'
import { TitleWithStatus } from '../../common/TitleWithStatus'

const Preview = connect(() => {
  const params = useParams<{ id: string }>()
  const { initialValues, reset } =
    useLapeContext<WorkScheduleCustomApprovalFlowInterface>()

  const [archivePending, setArchivePending] = useState(false)

  const showStatusPopup = useShowStatusPopup()
  const isActive = initialValues.status?.id === 'active'

  return (
    <PageWrapper>
      <PageHeader
        title={<TitleWithStatus data={initialValues} />}
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.LIST}
      />
      <PageBody>
        <VStack space="s-24">
          <MoreBar>
            <MoreBar.Action
              useIcon="Pencil"
              use={InternalLink}
              to={pathToUrl(
                ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT.DETAILS,
                params,
              )}
            >
              Edit
            </MoreBar.Action>
            {isActive && (
              <MoreBar.Action
                useIcon="Archive"
                pending={archivePending}
                variant="negative"
                onClick={async () => {
                  if (!initialValues.id) {
                    return
                  }
                  try {
                    setArchivePending(true)
                    const { data } = await archiveWorkScheduleCustomApprovers(
                      initialValues.id,
                    )
                    reset(data)
                  } catch (e) {
                    showStatusPopup({
                      title: 'Something went wrong',
                      description: getStringMessageFromError(e),
                      status: 'error',
                    })
                  } finally {
                    setArchivePending(false)
                  }
                }}
              >
                Archive
              </MoreBar.Action>
            )}
          </MoreBar>
          <FormPreview title="General" data={initialValues}>
            <Group>
              <FormPreview.Item title="Name" field="name" />
              <FormPreview.Item
                title="Created on"
                field="creation_date_time"
                type="date"
              />
            </Group>
          </FormPreview>
          <Box>
            <Text variant="h6" color={Token.color.greyTone50} use="div" my="s-12">
              Eligible employees
            </Text>
            <CustomApproversEligibilityTable />
          </Box>
        </VStack>
      </PageBody>
    </PageWrapper>
  )
})

export const PreviewWorkScheduleCustomApprovers = () => (
  <Form api={workScheduleCustomApproversRequests}>
    <Preview />
  </Form>
)
