import React, { useRef, useState } from 'react'
import {
  Avatar,
  Box,
  Carousel,
  Cell,
  DetailsCell,
  Group,
  TextButton,
  Token,
  VStack,
  Text,
  ActionButton,
  Item,
  chain,
  ActionButtonProps,
  ContactSkeleton,
  HStack,
  Placeholder,
  ItemSkeleton,
} from '@revolut/ui-kit'
import { addDays, format, isSameDay } from 'date-fns'
import { Virtuoso } from 'react-virtuoso'

import Tooltip from '@src/components/Tooltip/Tooltip'
import SideBar from '@src/components/SideBar/SideBar'
import {
  getEmployeeEvents,
  useEmployeeEvents,
  EMPLOYEE_EVENTS_RELATION_TYPE_FILTER_KEY,
  EMPLOYEE_EVENTS_EVENT_TYPE_FILTER_KEY,
  EMPLOYEE_EVENTS_END_DATE_FILTER_KEY,
} from '@src/api/employeeEvents'
import { EmployeeEventInterface } from '@src/interfaces/employeeEvents'
import { formatPeriod, formatDate } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { EmployeeEventAvatar } from '@src/components/EmployeeEvent/EmployeeEventAvatar'
import ButtonFilter from '@src/components/ButtonFilters/ButtonFilter'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import {
  employeeEventToColor,
  employeeEventToIcon,
  formatAnniversary,
} from '@src/components/EmployeeEvent/common'
import { GetRequestData, UseFetchResult } from '@src/interfaces'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import pluralize from 'pluralize'

interface EventTitleProps {
  data: EmployeeEventInterface
}

const EventTitle = ({ data }: EventTitleProps) => {
  switch (data.event_type.id) {
    case 'anniversary':
      return <>{formatAnniversary(data.start_date, data.employee.joining_date_time)}</>
    case 'birthday':
      return <>Happy birthday!</>
    case 'time_off':
      return <>Out of office</>
    default:
      return null
  }
}

interface UseEmployeeEventsDefaultFilterInterface {
  getFilters: (params: { endDateFilter: number }) => FilterByInterface[]
  setRelationFilter: React.Dispatch<React.SetStateAction<FilterOption[]>>
  setTypeFilter: React.Dispatch<React.SetStateAction<FilterOption[]>>
  readableFilterLabel: string
}

const useEmployeeEventsDefaultFilter = (): UseEmployeeEventsDefaultFilterInterface => {
  const [relationFilter, setRelationFilter] = useLocalStorage<FilterOption[]>(
    LocalStorageKeys.EMPLOYEE_EVENT_RELATION_FILTER,
    [{ id: 'department', name: 'Department' }],
  )
  const [typeFilter, setTypeFilter] = useLocalStorage<FilterOption[]>(
    LocalStorageKeys.EMPLOYEE_EVENT_TYPE_FILTER,
    [],
  )
  const readableFilterLabel = (() => {
    const relation = relationFilter?.[0]?.name || 'Company'
    const type =
      typeFilter.length === 1
        ? pluralize(typeFilter[0].name)
        : typeFilter.length === 2
        ? typeFilter.map(f => pluralize(f.name)).join(' and ')
        : 'events'
    return `${relation} ${type.toLowerCase()}`
  })()

  return {
    getFilters: ({ endDateFilter }) => [
      {
        columnName: EMPLOYEE_EVENTS_END_DATE_FILTER_KEY,
        filters: [
          {
            id: `${format(new Date(), 'yyyy-MM-dd')},${format(
              addDays(new Date(), endDateFilter),
              'yyyy-MM-dd',
            )}`,
            name: '',
          },
        ],
        nonResettable: true,
      },
      {
        columnName: EMPLOYEE_EVENTS_RELATION_TYPE_FILTER_KEY,
        filters: relationFilter,
        nonResettable: true,
      },
      {
        columnName: EMPLOYEE_EVENTS_EVENT_TYPE_FILTER_KEY,
        filters: typeFilter,
        nonResettable: true,
      },
    ],
    setRelationFilter,
    setTypeFilter,
    readableFilterLabel,
  }
}

const ErrorState = () => {
  return (
    <Box width="100%">
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D018.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D018@3x.png',
          }}
          size={70}
        />
        <Placeholder.Title>{UNKNOWN_ERROR}</Placeholder.Title>
      </Placeholder>
    </Box>
  )
}

interface EmptyStateProps {
  children: React.ReactNode
}

const EmptyState = ({ children }: EmptyStateProps) => {
  return (
    <Box width="100%">
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
          }}
          size={70}
        />
        <Placeholder.Title>{children}</Placeholder.Title>
      </Placeholder>
    </Box>
  )
}

interface EmployeeEventsContentsProps {
  api: UseFetchResult<GetRequestData<EmployeeEventInterface>>
}

const EmployeeEventsContents = ({ api }: EmployeeEventsContentsProps) => {
  if (api.isLoading) {
    return <EmployeeEventsContentsLoadingState />
  }

  if (api.isError) {
    return <ErrorState />
  }

  if (!api.data?.results?.length) {
    return <EmptyState>No events next 7 days</EmptyState>
  }

  return (
    <Carousel hideButtonsPreview>
      {api.data.results.map(item => (
        <Carousel.Item mt="s-8" key={item.id}>
          <Tooltip
            placement="bottom"
            backgroundColor={Token.color.popoverBackground}
            noArrow
            body={
              <Box
                radius={Token.radius.r12}
                bg={Token.color.widgetBackground}
                width={270}
                p="s-24"
                elevation="level4"
              >
                <VStack space="s-16" align="center">
                  <Avatar
                    size={40}
                    useIcon={employeeEventToIcon[item.event_type.id]}
                    bg={employeeEventToColor[item.event_type.id]}
                    color={Token.color.white}
                  />

                  <Text variant="heading3" color={Token.color.foreground}>
                    <EventTitle data={item} />
                  </Text>

                  <Text variant="body2" color={Token.color.greyTone50}>
                    <Text fontWeight={700}>{item.employee.full_name}</Text>{' '}
                    {(() => {
                      switch (item.event_type.id) {
                        case 'anniversary':
                          return (
                            <>started {formatDate(item.employee.joining_date_time)}</>
                          )
                        case 'birthday':
                          return (
                            <>
                              celebrates{' '}
                              {isSameDay(new Date(item.start_date), new Date())
                                ? 'today'
                                : `on ${formatDate(item.start_date, undefined, {
                                    hideSameYear: true,
                                  })}`}
                            </>
                          )
                        case 'time_off':
                          return (
                            <>
                              is away{' '}
                              {formatPeriod(item.start_date, item.end_date, {
                                showOneDateIfStartAndEndDatesAreSame: true,
                              })}
                            </>
                          )

                        default:
                          return null
                      }
                    })()}
                  </Text>

                  <CongratulationsButton
                    data={item}
                    useIcon="Message"
                    variant="primary"
                    mt="s-8"
                    width="100%"
                  />
                </VStack>
              </Box>
            }
          >
            <EmployeeEventAvatar
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: item.employee.id }),
                )
              }
              eventType={item.event_type}
              avatar={item.employee.avatar}
              fullName={item.employee.full_name}
              showTitle
            />
          </Tooltip>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

const EmployeeEventsContentsLoadingState = () => {
  return (
    <HStack space="s-16">
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
      <ContactSkeleton />
    </HStack>
  )
}

export const EmployeeEvents = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const filters = useEmployeeEventsDefaultFilter()

  const api = useEmployeeEvents(filters.getFilters({ endDateFilter: 7 }))

  return (
    <>
      <Group mb="s-16" overflow="hidden">
        <DetailsCell>
          <DetailsCell.Title>{filters.readableFilterLabel}</DetailsCell.Title>
          <DetailsCell.Content>
            <TextButton onClick={() => setIsSidebarOpen(true)} textStyle="emphasis2">
              View all
            </TextButton>
          </DetailsCell.Content>
        </DetailsCell>
        <Cell pt={0} height={136}>
          <EmployeeEventsContents api={api} />
        </Cell>
      </Group>

      <EmployeeEventsSidebar
        open={isSidebarOpen}
        onClose={() => setIsSidebarOpen(false)}
        filters={filters}
      />
    </>
  )
}

interface CongratulationsButtonProps extends ActionButtonProps {
  data: EmployeeEventInterface
}

const CongratulationsButton = ({ data, ...props }: CongratulationsButtonProps) => {
  if (
    data.event_type.id === 'time_off' ||
    !isSameDay(new Date(), new Date(data.start_date))
  ) {
    return null
  }

  return (
    <ActionButton
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        return data.employee.slack_url
          ? window.open(data.employee.slack_url, '_blank')
          : navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.employee.id }))
      }}
      {...props}
    >
      Say congrats
    </ActionButton>
  )
}

interface EmployeeEventsSidebarProps {
  open: boolean
  onClose: VoidFunction
  filters: UseEmployeeEventsDefaultFilterInterface
}

const EmployeeEventsSidebar = ({
  open,
  onClose,
  filters,
}: EmployeeEventsSidebarProps) => {
  const scrollRef = useRef<HTMLDivElement>(null)

  return (
    <SideBar
      title="Events"
      isOpen={open}
      onClose={onClose}
      sideProps={{ scrollRef }}
      variant="wide"
      usePortal="#employee-events-sidebar-portal-target"
    >
      <EmployeeEventsSidebarContents scrollRef={scrollRef} filters={filters} />
    </SideBar>
  )
}

interface EmployeeEventsSidebarContentsProps {
  scrollRef: React.RefObject<HTMLDivElement>
  filters: UseEmployeeEventsDefaultFilterInterface
}

const EmployeeEventsSidebarContents = ({
  scrollRef,
  filters,
}: EmployeeEventsSidebarContentsProps) => {
  const table = useTable(
    { getItems: getEmployeeEvents },
    filters.getFilters({ endDateFilter: 90 }),
  )

  const { data: relationTypeOptions } = useGetSelectors(
    selectorKeys.employee_event_relation_types,
  )
  const { data: eventTypeOptions } = useGetSelectors(selectorKeys.employee_event_types)

  const relationTypeFilterValue = table.filterBy.find(
    f => f.columnName === EMPLOYEE_EVENTS_RELATION_TYPE_FILTER_KEY,
  )?.filters?.[0]
  const eventTypeFilterValue =
    table.filterBy.find(f => f.columnName === EMPLOYEE_EVENTS_EVENT_TYPE_FILTER_KEY)
      ?.filters || []

  return (
    <>
      <HStack mb="s-16" space="s-8">
        <ButtonFilter
          title={
            relationTypeOptions?.find(o => o.id === relationTypeFilterValue?.id)?.name ||
            'Company'
          }
          pending={!relationTypeOptions}
          selector={() => Promise.resolve({ options: relationTypeOptions || [] })}
          type="SingleSelect"
          value={relationTypeFilterValue ? [relationTypeFilterValue] : undefined}
          onChange={option => {
            filters.setRelationFilter(option)

            if (option) {
              table.onFilterChange([
                {
                  columnName: EMPLOYEE_EVENTS_RELATION_TYPE_FILTER_KEY,
                  nonResettable: true,
                  filters: option,
                  disableQueryParam: true,
                },
              ])
            }
          }}
        />
        <ButtonFilter
          title="Event type"
          pending={!eventTypeOptions}
          selector={() => Promise.resolve({ options: eventTypeOptions || [] })}
          type="MultiSelect"
          value={eventTypeFilterValue}
          onChange={option => {
            filters.setTypeFilter(option)

            if (option) {
              table.onFilterChange([
                {
                  columnName: EMPLOYEE_EVENTS_EVENT_TYPE_FILTER_KEY,
                  nonResettable: true,
                  filters: option,
                  disableQueryParam: true,
                },
              ])
            }
          }}
        />
      </HStack>

      {(() => {
        if (table.fetchError) {
          return <ErrorState />
        }

        if (!table.loading && table.data?.length === 0) {
          return <EmptyState>No events next 90 days</EmptyState>
        }

        return (
          <Group>
            {table.data?.length ? (
              <Virtuoso
                data={table.data}
                customScrollParent={scrollRef.current || undefined}
                overscan={150}
                endReached={table.fetchNextPage}
                itemContent={(_, item) => {
                  return (
                    <Item
                      key={item.id}
                      aria-label={`Go to ${item.employee.full_name} profile`}
                      data-testid={`EmployeeEvents-SidebarItem-${item.id}`}
                      onClick={() => {
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, {
                            id: item.employee.id,
                          }),
                        )
                      }}
                      use="button"
                      // TODO: https://revolut.atlassian.net/browse/REVCOR-3046
                      style={{ background: 'transparent' }}
                    >
                      <Item.Avatar>
                        <Box
                          style={{ transform: `scale(0.7)`, transformOrigin: 'left top' }}
                        >
                          <EmployeeEventAvatar
                            eventType={item.event_type}
                            avatar={item.employee.avatar}
                            fullName={item.employee.full_name}
                            showTitle={false}
                          />
                        </Box>
                      </Item.Avatar>
                      <Item.Content>
                        <Item.Title>{item.employee.full_name}</Item.Title>
                        <Item.Description>
                          {chain(
                            <EventTitle data={item} />,
                            item.event_type.id === 'time_off'
                              ? formatPeriod(item.start_date, item.end_date, {
                                  showOneDateIfStartAndEndDatesAreSame: true,
                                })
                              : isSameDay(new Date(item.start_date), new Date())
                              ? 'Today'
                              : formatDate(item.start_date, undefined, {
                                  hideSameYear: true,
                                }),
                          )}
                        </Item.Description>
                      </Item.Content>
                      <Item.Side>
                        <CongratulationsButton data={item} />
                      </Item.Side>
                    </Item>
                  )
                }}
              />
            ) : null}
            {table.loading ? <EmployeeEventsSidebarContentsLoadingState /> : null}
          </Group>
        )
      })()}
    </>
  )
}

const EmployeeEventsSidebarContentsLoadingState = () => {
  return (
    <>
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
      <ItemSkeleton />
    </>
  )
}
