import { useEffect, useMemo, useState } from 'react'
import format from 'date-fns/format'

import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { PayCycleInterface } from '@src/interfaces/payrollV2'
import { useQuery } from '@src/utils/queryParamsHooks'
import { toIdAndName } from '@src/utils/toIdAndName'
import { IdAndName } from '@src/interfaces'
import { PAYCYCLE_SELECTED_QUERY_KEY } from '../common/CycleSelector'
import { CycleOption } from '../common'

export const TIMELINE_FROM_DATE_KEY = 'from_date'
export const TIMELINE_TO_DATE_KEY = 'to_date'
export const TIMELINE_EVENT_TYPE_KEY = 'payroll_events'

export type QueryParams = Partial<{
  [TIMELINE_FROM_DATE_KEY]: string
  [TIMELINE_TO_DATE_KEY]: string
  [TIMELINE_EVENT_TYPE_KEY]: string
}>

export const EVENT_TYPES_OPTIONS = [
  { id: 'data_change', name: 'Change' },
  { id: 'hire', name: 'Hire' },
  { id: 'termination', name: 'Termination' },
]

export const getDatesFromQuery = (query: QueryParams) => {
  const queryFrom = query[TIMELINE_FROM_DATE_KEY]
  const queryTo = query[TIMELINE_TO_DATE_KEY]

  if (!queryFrom || !queryTo) {
    return []
  }
  return [
    { id: queryFrom, name: queryFrom },
    { id: queryTo, name: queryTo },
  ]
}

const initEventTypesFilterFromQuery = (query: QueryParams) => {
  const eventTypes = query[TIMELINE_EVENT_TYPE_KEY]

  if (!eventTypes) {
    return []
  }
  return eventTypes
    .split(',')
    .map(id => {
      return EVENT_TYPES_OPTIONS.find(option => option.id === id)
    })
    .filter(Boolean)
}

const formatDateToFilterId = (dateStr: string | undefined) => {
  const date = typeof dateStr === 'string' ? new Date(dateStr) : new Date()
  return format(date, 'yyyy-MM-dd')
}

const getDateFilterBy = (
  date: string | number | undefined,
  columnName: string,
): FilterByInterface[] => {
  if (typeof date !== 'string') {
    return []
  }
  const formattedDate = format(new Date(date), 'yyyy-MM-dd')
  return [{ columnName, filters: [{ id: formattedDate, name: formattedDate }] }]
}

const getFiltersByCutOffDates = (
  payCycle: PayCycleInterface | undefined,
  query: QueryParams,
): IdAndName<string>[] => {
  const from = toIdAndName(
    formatDateToFilterId(
      payCycle?.previous_pay_cycle?.cut_off_date
        ? payCycle.previous_pay_cycle.cut_off_date
        : payCycle?.start_date || query[TIMELINE_FROM_DATE_KEY],
    ),
  )
  const to = toIdAndName(
    formatDateToFilterId(
      payCycle?.previous_pay_cycle?.cut_off_date && payCycle?.cut_off_date
        ? payCycle.cut_off_date
        : payCycle?.end_date || query[TIMELINE_TO_DATE_KEY],
    ),
  )
  return [from, to]
}

const getFiltersByStartToEndDates = (
  payCycle: PayCycleInterface | undefined,
): IdAndName<string>[] => {
  const from = toIdAndName(formatDateToFilterId(payCycle?.start_date))
  const to = toIdAndName(formatDateToFilterId(payCycle?.end_date))

  return [from, to]
}

export const useTimelineFilters = (
  initialPayCycle: PayCycleInterface,
  selectedPayCycle: CycleOption | undefined,
) => {
  const { query } = useQuery()

  const selectedPayCycleId = query[PAYCYCLE_SELECTED_QUERY_KEY]

  const [datesRangeFilterValue, setDatesRangeFilterValue] = useState<FilterOption[]>(() =>
    getFiltersByCutOffDates(initialPayCycle, query),
  )
  const [filterValueFrom, filterValueTo] = datesRangeFilterValue

  useEffect(() => {
    if (selectedPayCycleId && selectedPayCycle) {
      setDatesRangeFilterValue(getFiltersByCutOffDates(selectedPayCycle, query))
    } else if (!selectedPayCycleId && initialPayCycle) {
      setDatesRangeFilterValue(getFiltersByCutOffDates(initialPayCycle, query))
    }
  }, [selectedPayCycleId, selectedPayCycle, initialPayCycle])

  const [eventTypesFilterValue, setEventTypesFilterValue] = useState<FilterOption[]>(
    initEventTypesFilterFromQuery(query),
  )

  const [initFrom, initTo] = getFiltersByStartToEndDates(selectedPayCycle)
  const defaultDatesRangeFilterBy = [
    ...getDateFilterBy(initFrom?.id, TIMELINE_FROM_DATE_KEY),
    ...getDateFilterBy(initTo?.id, TIMELINE_TO_DATE_KEY),
  ]
  const datesRangeFilterBy = [
    ...getDateFilterBy(filterValueFrom?.id, TIMELINE_FROM_DATE_KEY),
    ...getDateFilterBy(filterValueTo?.id, TIMELINE_TO_DATE_KEY),
  ]

  const eventTypesFilterValueStr = eventTypesFilterValue?.map(f => f.id).join(',')
  const eventTypeFilterBy = [
    {
      columnName: TIMELINE_EVENT_TYPE_KEY,
      filters: eventTypesFilterValueStr
        ? [{ id: eventTypesFilterValueStr, name: eventTypesFilterValueStr }]
        : [],
    },
  ]

  const allFilters = [
    ...(datesRangeFilterBy.length ? datesRangeFilterBy : defaultDatesRangeFilterBy),
    ...eventTypeFilterBy,
  ]
  const allFiltersIdsStringified = useMemo(
    () =>
      allFilters
        .flatMap(value =>
          Array.isArray(value.filters) ? value.filters : [value.filters],
        )
        .filter(Boolean)
        .map(f => f.id)
        .join(','),
    [datesRangeFilterValue, eventTypesFilterValue],
  )

  return {
    dates: {
      value: datesRangeFilterValue,
      onChange: setDatesRangeFilterValue,
    },
    eventTypes: {
      value: eventTypesFilterValue,
      onChange: setEventTypesFilterValue,
    },
    all: allFilters,
    allStringified: allFiltersIdsStringified,
  }
}
