import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { DataRetentionSettingInterface } from '@src/interfaces/dataRetentionSettings'

export const dataRetentionCountryColumn: ColumnInterface<DataRetentionSettingInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'country.name',
    sortKey: 'country',
    filterKey: 'country',
    selectorsKey: selectorKeys.countries,
    title: 'Country',
  }

export const dataRetentionUnsuccessfulColumn: ColumnInterface<DataRetentionSettingInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'not_hired_candidate_retention_duration_days',
    dataPoint: 'not_hired_candidate_retention_duration_days',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Unsuccessful candidate’s retention period',
    insert: ({ data }) =>
      `${data.not_hired_candidate_retention_duration} ${data.not_hired_candidate_retention_duration_unit}`,
  }

export const dataRetentionSuccessfulColumn: ColumnInterface<DataRetentionSettingInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'hired_candidate_retention_duration_days',
    dataPoint: 'hired_candidate_retention_duration_days',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Successful candidate’s retention period',
    insert: ({ data }) =>
      `${data.hired_candidate_retention_duration} ${data.hired_candidate_retention_duration_unit}`,
  }
