import { COOKIE } from '@src/constants/api'
import { Authenticated } from '@src/store/auth/constants'
import Cookies from 'js-cookie'

export const cookiesApi = Cookies.withAttributes({ sameSite: 'strict', secure: true })

const signupCookiesApi = Cookies.withAttributes({
  sameSite: 'lax',
  secure: true,
})

export const setSignupAuthenticatedCookie = () =>
  signupCookiesApi.set(COOKIE.AUTHENTICATED, Authenticated.authenticated, {
    domain: window.location.hostname,
  })

export const removeSignupAuthenticatedCookie = () =>
  signupCookiesApi.remove(COOKIE.AUTHENTICATED, {
    domain: window.location.hostname.split('.').slice(-2).join('.'),
  })

export const setEmployeeOnboardingV2IdCookie = (onboardingV2Id: string) =>
  cookiesApi.set(COOKIE.COOKIE_EMPLOYEE_ONBOARDING_V2_ID, onboardingV2Id)

export const removeEmployeeOnboardingV2IdCookie = () =>
  cookiesApi.remove(COOKIE.COOKIE_EMPLOYEE_ONBOARDING_V2_ID)
