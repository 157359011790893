import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { payrollConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { PayrollIntro } from './PayrollIntro'
import { PayrollSettings } from './PayrollSettings'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.INTRO,
    canView: [PermissionTypes.ViewPayrollPreferences],
    component: PayrollIntro,
  },
  {
    title: 'Settings',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.PAYROLL.SETTINGS,
    canView: [PermissionTypes.ViewPayrollPreferences],
    component: PayrollSettings,
    hideActions: true,
    hidePageBody: true,
  },
]

export const Payroll = () => {
  return <OnboardingChecklistContent config={payrollConfig} routes={routes} />
}
