import { Input, InputGroup } from '@revolut/ui-kit'
import { HideInputCalendarIndicatorCss } from '@src/components/Inputs/LapeFields/helpers'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'
import { formatTime } from '@src/utils/format'
import React, { useEffect, useState } from 'react'

export const TimePickers = () => {
  const { values, initialValues, apiErrors } =
    useLapeContext<EmployeeTimeOffRequestInterface>()
  const [fromTime, setFromTime] = useState(
    initialValues.from_date_time ? formatTime(initialValues.from_date_time) : '09:00',
  )
  const [toTime, setToTime] = useState(
    initialValues.to_date_time ? formatTime(initialValues.to_date_time) : '18:00',
  )

  const fromTimeError = apiErrors.from_date_time
  const toTimeError = apiErrors.to_date_time

  const disabled =
    values?.field_options?.read_only?.includes('from_date_time') ||
    values?.field_options?.read_only?.includes('to_date_time')

  useEffect(() => {
    if (
      values.from_date_time &&
      fromTime &&
      formatTime(values.from_date_time) !== fromTime
    ) {
      const newFromDate = new Date(values.from_date_time)
      const [hours, minutes] = fromTime.split(':')
      newFromDate.setHours(Number(hours))
      newFromDate.setMinutes(Number(minutes))
      values.from_date_time = newFromDate.toISOString()
    }

    if (values.to_date_time && toTime && formatTime(values.to_date_time) !== toTime) {
      const newToDate = new Date(values.to_date_time)
      const [hours, minutes] = toTime.split(':')
      newToDate.setHours(Number(hours))
      newToDate.setMinutes(Number(minutes))
      values.to_date_time = newToDate.toISOString()
    }
  }, [fromTime, toTime, values.from_date_time, values.to_date_time])

  return (
    <InputGroup variant="horizontal">
      <Input
        label="Start time"
        value={fromTime}
        onChange={e => setFromTime(e.currentTarget.value)}
        type="time"
        css={HideInputCalendarIndicatorCss}
        disabled={disabled}
        invalid={!!fromTimeError}
        errorMessage={fromTimeError}
      />
      <Input
        label="End time"
        value={toTime}
        onChange={e => setToTime(e.currentTarget.value)}
        type="time"
        css={HideInputCalendarIndicatorCss}
        disabled={disabled}
        invalid={!!toTimeError}
        errorMessage={toTimeError}
      />
    </InputGroup>
  )
}
