import React, { useEffect, useMemo, useState } from 'react'
import {
  bulkCandidatesEmailsRequests,
  candidateEmailsRequests,
} from '@src/api/hiringProcess'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { Button, Side, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import BulkStatusPopup from '@components/BulkStatusPopup/BulkStatusPopup'
import pluralize from 'pluralize'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'
import SideBar from '@components/SideBar/SideBar'
import { getEmailNonPrefilledPlaceholders } from '@src/utils/hiring'

type Props = {
  candidateIds?: (number | string)[]
  isOpen: boolean
  onClose: () => void
  onRefetch?: () => void
  bulk?: boolean
}

const CandidateSendEmailSidebar = ({
  candidateIds,
  onClose,
  onRefetch,
  bulk = false,
}: Omit<Props, 'isOpen'>) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null)
  const statusPopup = useStatusPopup()

  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()

  const fetchEmail = async (templateId: number) => {
    const candidateId = bulk ? undefined : candidateIds?.[0]
    return getPrefilledPlaceholdersEmail(
      templateId,
      candidateId ? +candidateId : undefined,
    )
  }

  useEffect(() => {
    if (bulk) {
      values.candidate_ids = candidateIds
    }
  }, [candidateIds, bulk])

  const nonPrefilledPlaceholders = useMemo(
    () => getEmailNonPrefilledPlaceholders(values),
    [values.email_body, values.subject],
  )

  const handleDisclaimer = () => {
    statusPopup.show(
      <StatusPopup variant="warning">
        <StatusPopup.Title>You have non-prefilled placeholders</StatusPopup.Title>
        <StatusPopup.Description>
          The following placeholders were not filled:{' '}
          {nonPrefilledPlaceholders.join(', ')}. Please remove the placeholders or add the
          information required to fill them to candidate profile before sending the email.
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
            }}
          >
            Edit email
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <BulkStatusPopup
        isSuccess={isSuccess}
        onClose={() => {
          setIsSuccess(null)
          onClose()
        }}
      />

      <SendEmailCommon
        insideSidebar
        fetchEmail={candidateIds?.[0] ? fetchEmail : undefined}
        showInvalidPlaceholdersWarning={!bulk}
        actions={null}
      />
      <Side.Actions horizontal>
        {bulk || !nonPrefilledPlaceholders?.length ? (
          <NewSaveButtonWithPopup<SendCandidateEmailInterface>
            successText="Email has been sent"
            pending={loadingFetchEmail}
            noPopup={bulk}
            onAfterSubmit={() => {
              onRefetch?.()
              if (bulk) {
                setIsSuccess(true)
              } else {
                onClose()
              }
            }}
            onSubmitError={e => {
              if (bulk && e?.response?.status !== 400) {
                setIsSuccess(false)
              }
            }}
            useValidator
            hideWhenNoChanges={false}
            data-testid="btn-send-email"
          >
            {bulk
              ? `Send ${candidateIds?.length || 0} ${pluralize(
                  'email',
                  candidateIds?.length || 0,
                )}`
              : 'Send email'}
          </NewSaveButtonWithPopup>
        ) : (
          <Button elevated onClick={handleDisclaimer} pending={loadingFetchEmail}>
            Send email
          </Button>
        )}
      </Side.Actions>
    </>
  )
}

export default (props: Props) => {
  const { candidateIds } = props

  // we have to re-mount the form to reset existing data state inside the Form (we call setIsExistingData(true) after submit and we don't need it here)
  if (!props.isOpen) {
    return null
  }

  return (
    <SideBar
      useLayout
      isOpen
      onClose={props.onClose}
      title={
        props.bulk
          ? `Send email to ${pluralize('candidate', candidateIds?.length || 0, true)}`
          : 'Send email'
      }
    >
      <Form
        api={props.bulk ? bulkCandidatesEmailsRequests : candidateEmailsRequests}
        forceParams={{
          new: 'new',
          candidateId: String(candidateIds?.[0]),
        }}
        disableLocalStorageCaching
      >
        <CandidateSendEmailSidebar {...props} />
      </Form>
    </SideBar>
  )
}
