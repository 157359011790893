import React from 'react'
import { Box, Color, Text } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesScorecardSectionInterface } from '@src/interfaces/reviewCycles'
import { QuestionContent } from '@src/pages/Settings/Performance/Scorecards/QuestionContent'

type PreviewQuestionProps = {
  questionIdx: number
  data: ReviewCyclesScorecardSectionInterface
  isCurrentSection: boolean
}

export const PreviewQuestion = ({
  questionIdx,
  data,
  isCurrentSection,
}: PreviewQuestionProps) => {
  const { values } = useLapeContext<ReviewCyclesScorecardSectionInterface>()
  const section = isCurrentSection ? values : data
  const question = section?.questions[questionIdx]
  const type = question.question_type?.id

  return (
    <Box mt={!questionIdx ? 's-16' : 's-32'}>
      <Text variant="primary" display="block">
        {questionIdx + 1}. {question?.title || `Question ${questionIdx + 1}`}{' '}
        {question?.optional && <Text color={Color.GREY_TONE_50}>(optional)</Text>}
      </Text>
      {question?.subtitle && (
        <Box mt="s-8">
          <Text variant="caption">{question?.subtitle}</Text>
        </Box>
      )}

      <Box mt="s-16">
        <QuestionContent
          isPreview
          options={question?.options}
          placeholder={question?.placeholder_text}
          type={type}
        />
      </Box>
    </Box>
  )
}
