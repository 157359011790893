import { AxiosPromise } from 'axios'

import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
  IdAndName,
} from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  KeyPersonResponsibilityInterface,
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface,
  KeyPersonSuccessionPlanInterface,
  KeyPersonSuccessionPlansStatsInterface,
  KeyPersonResponsibilitiesStatsInterface,
  KeyPersonSuccessionPlanFileInterface,
} from '@src/interfaces/keyPerson'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { uploadFile } from './files'

export const getAllKeyPersonResponsibilitiesAssignments =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<KeyPersonResponsibilityAssignmentInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/keyRiskProfiles`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getAllKeyPersonResponsibilitiesAssignmentsStats =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<KeyPersonResponsibilityAssignmentsStatsInterface> =>
    api.get(`${API.EMPLOYEES}/${employeeId}/keyRiskProfiles/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const keyPersonsResponsibilitiesTableRequests: TableRequestInterface<
  KeyPersonResponsibilityInterface,
  KeyPersonResponsibilitiesStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/keyPersonTypes`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(`/keyPersonTypes/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const keyPersonResponsibilityFormRequests: RequestInterfaceNew<KeyPersonResponsibilityInterface> =
  {
    get: async ({ id }) => api.get(`/keyPersonTypes/${id}`),
    submit: async data => {
      const { succession_plan_attachment, ...rest } = data

      const response = await apiWithoutHandling.post('/keyPersonTypes', rest)

      if (succession_plan_attachment) {
        const formData = new FormData()

        formData.append(
          'file',
          succession_plan_attachment,
          succession_plan_attachment.name,
        )
        return apiWithoutHandling.post(
          `/keyPersonTypes/${response.data.id}/fileForm`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': `attachment`,
            },
          },
        )
      }
      return Promise.resolve(response)
    },
    update: async (data, { id }) => {
      const { succession_plan_attachment, ...rest } = data

      if (succession_plan_attachment) {
        const formData = new FormData()
        formData.append(
          'file',
          succession_plan_attachment,
          succession_plan_attachment.name,
        )
        await api.post(`/keyPersonTypes/${id}/fileForm`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Disposition': `attachment`,
          },
        })
      }

      return apiWithoutHandling.patch(`/keyPersonTypes/${id}`, rest)
    },
  }

export const archiveKeyPersonResponsibility = async (id: number) =>
  api.post(`/keyPersonTypes/${id}/archive`)

export const unarchiveKeyPersonResponsibility = async (id: number) =>
  api.post(`/keyPersonTypes/${id}/unarchive`)

export const useKeyPersonCategories = () =>
  useFetch<GetRequestInterface<IdAndName>>('/keyPersonTypesCategories')

export const createKeyPersonCategory = async (name: string) =>
  api.post<IdAndName>('/keyPersonTypesCategories', { name })

export const editKeyPersonCategory = async (id: number, name: string) =>
  api.patch<IdAndName>(`/keyPersonTypesCategories/${id}`, { name })

export const deleteKeyPersonCategory = async (id: number) =>
  api.delete<IdAndName>(`/keyPersonTypesCategories/${id}`)

export const keyPersonsResponsibilitiesAssignmentsTableRequests: TableRequestInterface<
  KeyPersonResponsibilityAssignmentInterface,
  KeyPersonResponsibilityAssignmentsStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/keyRiskProfiles`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(`/keyRiskProfiles/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const responsibilityAssignmentFormRequests: RequestInterfaceNew<KeyPersonResponsibilityAssignmentInterface> =
  {
    get: async ({ id }) => api.get(`/keyRiskProfiles/${id}`),
    submit: async data => apiWithoutHandling.post('/keyRiskProfiles', data),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/keyRiskProfiles/${id}`, data),
    delete: async ({ id }) => api.delete(`/keyRiskProfiles/${id}`),
  }

export const archiveResponsibilityAssignment = (id: number) =>
  api.post<KeyPersonResponsibilityAssignmentInterface>(`/keyRiskProfiles/${id}/archive`)

export const unarchiveResponsibilityAssignment = (id: number) =>
  api.post<KeyPersonResponsibilityAssignmentInterface>(`/keyRiskProfiles/${id}/unarchive`)

export const transferResponsibilityAssignment = (id: number, newId: number) =>
  api.post<KeyPersonResponsibilityAssignmentInterface>(
    `/keyRiskProfiles/${id}/transfer`,
    { id: newId },
  )

export const exportResponsibilitiesAssignmentsData = () =>
  api.get<string>(`/keyRiskProfiles/csv`, {
    headers: {
      'Content-Disposition': 'attachment',
    },
  })

export const keyPersonsSuccessionPlansTableRequests: TableRequestInterface<
  KeyPersonSuccessionPlanInterface,
  KeyPersonSuccessionPlansStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/keyRiskProfilesSuccessions`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(`/keyRiskProfilesSuccessions/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const getKeyPersonsSuccessionPlanChangelogTableRequests = (
  id: string | number,
): TableRequestInterface<ChangelogInterface<KeyPersonSuccessionPlanInterface>> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/keyRiskProfilesSuccessions/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const archiveSuccessionPlan = (id: number) =>
  api.post<KeyPersonSuccessionPlanInterface>(`/keyRiskProfilesSuccessions/${id}/archive`)

export const unarchiveSuccessionPlan = (id: number) =>
  api.post<KeyPersonSuccessionPlanInterface>(
    `/keyRiskProfilesSuccessions/${id}/unarchive`,
  )

export const approveSuccessionPlan = (id: number) =>
  api.post<KeyPersonSuccessionPlanInterface>(`/keyRiskProfilesSuccessions/${id}/approve`)

export const useSuccessionPlanApprovals = (id: number) =>
  useFetch<ApprovalFlowResponse>(`/keyRiskProfilesSuccessions/${id}/approvals`)

export const rejectSuccessionPlan = (id: number, comment: string) =>
  api.post<KeyPersonSuccessionPlanInterface>(`/keyRiskProfilesSuccessions/${id}/reject`, {
    rejection_comment: comment,
  })

export const useSuccessionPlanFiles = (id: number) =>
  useFetch<GetRequestInterface<KeyPersonSuccessionPlanFileInterface>>(
    `/keyRiskProfilesSuccessions/${id}/files`,
  )

export const deleteSuccessionPlanFile = (successionPlanId: number, fileId: number) =>
  api.delete<{}>(`/keyRiskProfilesSuccessions/${successionPlanId}/files/${fileId}`)

export const uploadSuccessionPlanFile = async (successionPlanId: number, file: File) => {
  const { data: fileResponse } = await uploadFile(file, 'other')
  return api.post(`/keyRiskProfilesSuccessions/${successionPlanId}/files`, {
    file: { id: fileResponse.id },
  })
}

export const keyPersonSuccessionPlanFormRequests: RequestInterfaceNew<KeyPersonSuccessionPlanInterface> =
  {
    get: async ({ id }) => api.get(`/keyRiskProfilesSuccessions/${id}`),
    submit: async data => {
      const { succession_plan_attachment, ...rest } = data

      const response = await apiWithoutHandling.post('/keyRiskProfilesSuccessions', rest)

      if (succession_plan_attachment) {
        await uploadSuccessionPlanFile(response.data.id, succession_plan_attachment)
      }
      return Promise.resolve(response)
    },
    update: async (data, { id }) => {
      const { succession_plan_attachment, ...rest } = data

      if (succession_plan_attachment) {
        await uploadSuccessionPlanFile(Number(id), succession_plan_attachment)
      }

      return api.patch(`/keyRiskProfilesSuccessions/${id}`, rest)
    },
  }

export const getEmployeeKeyPersonResponsibilitiesAssignments = (id: number) =>
  api.get<KeyPersonResponsibilityAssignmentInterface[]>(
    `/employees/${id}/keyRiskProfiles/showAll`,
  )

export const getEmployeeKeyPersonResponsibilitiesAssignmentChangelog = (
  employeeId: string | number,
  assignmentId: string | number,
): TableRequestInterface<
  ChangelogInterface<KeyPersonResponsibilityAssignmentInterface>
> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/employees/${employeeId}/keyRiskProfiles/${assignmentId}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const useEmployeeSuccessionPlans = (employeeId: number | string) =>
  useFetch<GetRequestInterface<KeyPersonSuccessionPlanInterface>>(
    `${API.EMPLOYEES}/${employeeId}/keyRiskSuccessionPlans`,
  )

export const useEmployeeIsSuccessorForResponsibilities = (employeeId: number | string) =>
  useFetch<KeyPersonResponsibilityAssignmentInterface[]>(
    `${API.EMPLOYEES}/${employeeId}/keyRiskSuccessionPlans/successionResponsibilities`,
  )
