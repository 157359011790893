import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GradesDistribution } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/GradesDistribution/GradesDistribution'
import { CalibrationLogic } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/CalibrationLogic/CalibrationLogic'
import { ValidationResult } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/validation'

interface Props {
  handleOnValidate: (validationResult: ValidationResult) => void
}

export const EditFormContent = connect(({ handleOnValidate }: Props) => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const {
    grade_distribution: gradesDistribution,
    grade_calculation_method: calibrationMethod,
  } = values

  return (
    <VStack space="s-24">
      <CalibrationLogic calibrationMethod={calibrationMethod} />
      {!!gradesDistribution && (
        <GradesDistribution
          gradesDistribution={gradesDistribution}
          handleOnValidate={handleOnValidate}
        />
      )}
    </VStack>
  )
})
