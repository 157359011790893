import React from 'react'
import { useCandidateProfileContext } from '@src/pages/Forms/Candidate/CandidateProfileContext'
import CVPreviewSidebar from '@src/features/CVPreviewSidebar/CVPreviewSidebar'

type Props = {
  candidateId?: number
}

export const CandidateCvSidebar = ({ candidateId }: Props) => {
  const { activeAction, setActiveAction } = useCandidateProfileContext()

  if (activeAction?.type !== 'cv') {
    return null
  }

  return (
    <CVPreviewSidebar
      isOpen
      id={candidateId}
      onClose={() => {
        setActiveAction(undefined)
      }}
    />
  )
}
