import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ActionButton,
  Box,
  Button,
  Color,
  Flex,
  Group,
  Icon,
  Item,
  Link,
  Sticky,
  TabBar,
  Text,
  TextWidget,
  Token,
  VStack,
} from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import { css } from 'styled-components'
import { isAfter } from 'date-fns'
import { useSelector } from 'react-redux'

import {
  ContractorInterface,
  DiversityInterface,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  EmployeeAboutInterface,
  EmployeeBioInterface,
  EmployeeContactsInterface,
  EmployeeEmergencyContactInterface,
  EmployeeInterface,
  InternalOrExternalEmployee,
  PayrollInterface,
  PersonalInfoInterface,
  TerminationInterface,
} from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import {
  diversityRequests,
  getContractorFields,
  getEmployeeAbout,
  getEmployeeBio,
  getEmployeeContacts,
  getEmployeeEmergencyContact,
  getEmployeeFields,
  getEmployeeLaptopDeliveryAddress,
  getProfileFields,
  payrollRequests,
  terminationRequests,
  terminationRequestsV2,
} from '@src/api/employees'
import { Status } from '@components/CommonSC/General'
import { formatDate, formatWithoutTimezone } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { usePreviewChangelog } from '@components/FormPreview/changelog'
import { getTimeFromString } from '@src/utils/timezones'
import {
  changelogEmployeeRequests,
  fieldChangelogEmployeeRequests,
} from '@src/api/changelog'
import Tooltip from '@components/Tooltip/Tooltip'
import { PageActions } from '@components/Page/PageActions'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { defaultTheme } from '@src/styles/theme'
import { DocumentSidebar } from '@src/features/DocumentSidebar/DocumentSidebar'
import { DIVERSITY_DATA } from '@src/constants/externalLinks'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useCustomFields } from '@components/Stepper/NewStepperSectionCustomFields'
import { InternalLink } from '@components/InternalLink/InternalLink'
import {
  useGetContractsSettings,
  useGetEmployeeSettings,
  useGetLifecycleSettings,
  useGetOrganisationSettings,
  useGetScreeningSettings,
  useGlobalSettings,
} from '@src/api/settings'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { ActionButtonsGroup } from '../components/Buttons/ActionButtonsGroup'
import { AnonymizedWarning } from '../components/AnonymizedWarning'
import { UseEmployeeStatusTransitionsReturnType } from '../components/Buttons/ChangeStatusDropdown/StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from '../components/Buttons/ChangeStatusDropdown/SetInactive/hooks'
import { InactivityWarning } from '../components/Buttons/ChangeStatusDropdown/SetInactive/InactivityWarning'
import { ResignationNotice } from '../components/ResignationNotice'
import { TerminationDateRequiredWarning } from '../components/TerminationDateRequiredWarning'
import { OnboardingWarning } from './components/OnboardingWarning'
import { WarnIfProfileDataAnonymised } from './components/WarnIfProfileDataAnonymised'
import { KeyPerson } from './components/KeyPerson'
import { EmployeeBadges } from './components/EmployeeBadges'
import { Contracts } from './components/Contracts'
import { RightToWork } from './components/RightToWork'
import { ScreeningCheck } from './components/ScreeningCheck'
import { LinkedAccountsList } from './components/LinkedAccountsList'
import { WarnIfEmptyValue } from './components/WarnIfEmptyValue'
import { canEditSection, getProfileSectionFields } from '../common'
import { ProfileSectionsData } from '../hooks'
import {
  canViewContracts,
  canViewDiversity,
  canViewKeyPerson,
  canViewLinkedAccounts,
  canViewPayroll,
  canViewPersonal,
  canViewRightToWork,
  canViewScreening,
  canViewTermination,
  ChangeRequestsPendingWidget,
  formatDateWithFallback,
  hasOffboardingV2Enabled,
  optionalField,
  useSectionHeader,
} from './common'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

const MarginBottomCss = css`
  margin-bottom: ${Token.space.s16};
`

interface Props {
  data: EmployeeInterface
  dynamicGroups: DynamicGroupIDs
  updateData: (update: Partial<EmployeeInterface>) => void
  refreshData: () => void
  statusTransitions: UseEmployeeStatusTransitionsReturnType
  employeeInactivity: UseEmployeeInactivityDataReturnType
  sectionsData: ProfileSectionsData
}

export const ProfileSummary = (props: Props) => {
  type SectionKey = keyof typeof sections

  const {
    data,
    dynamicGroups,
    refreshData,
    statusTransitions,
    employeeInactivity,
    sectionsData,
  } = props

  const [selectedDocumentId, setSelectedDocumentId] = useState<number>()
  const [documentSidebarOpen, setDocumentSidebarOpen] = useState(false)

  // Currently we have an "Identification" preview section that consists only of one custom field,
  // so we have to fetch the data beforehand to decide if it should be visible at all.
  // This unoptimized request must be removed as soon as the "Identification"
  // section begin to contain anything except the single custom field
  const identificationCustomFields = useCustomFields({
    sectionId: SectionOptions.EmployeeProfile,
    subSectionId: EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS,
    dynamicGroups,
  })
  // the same as above
  const correspondenceAddressCustomFields = useCustomFields({
    sectionId: SectionOptions.EmployeeProfile,
    subSectionId: EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS,
    dynamicGroups,
  })

  const { data: settings } = useGetEmployeeSettings()
  const { data: lifecycleSettings } = useGetLifecycleSettings()
  const { settings: globalSettings } = useGlobalSettings()
  const { data: screeningSettings } = useGetScreeningSettings()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const { data: contractsSettings } = useGetContractsSettings()
  const { data: orgSettings } = useGetOrganisationSettings()

  const params = useParams<{ id: string }>()
  const changelogApi = useMemo(
    () => ({
      form: changelogEmployeeRequests(data.id),
      field: fieldChangelogEmployeeRequests(data.id),
    }),
    [data.id],
  )
  const changelog = usePreviewChangelog({
    api: changelogApi,
    data,
  })

  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [data.id])

  const { hasPendingChanges: hasPendingNameChanges, ...nameSectionProps } =
    useSectionHeader({ employeeId: `${data.id}`, type: 'name' })
  const {
    hasPendingChanges: hasPendingOrganisationChanges,
    ...organisationSectionProps
  } = useSectionHeader({ employeeId: `${data.id}`, type: 'organisation' })
  const positionHeader = useSectionHeader({ employeeId: `${data.id}`, type: 'position' })

  const isInternal = data.employee_type === 'internal'
  const isChangelogOpen = !!changelog.state.values

  const isCommercial = useIsCommercial()

  const sections = {
    summary: {
      title: 'Summary',
      canView: true,
    },
    termination: {
      title: 'Termination',
      canView: canViewTermination(data) && data.has_termination && !isChangelogOpen,
    },
    work: {
      title: 'Work',
      canView: true,
    },
    personal: {
      title: 'Personal',
      canView: canViewPersonal(data) && !isChangelogOpen,
    },
    contracts: {
      title: 'Contracts',
      canView: canViewContracts(data) && !isChangelogOpen,
    },
    rightToWork: {
      title: 'Right to work',
      canView: canViewRightToWork(data) && !isChangelogOpen,
    },
    screening: {
      title: 'Screening',
      canView: canViewScreening(data, !!screeningSettings?.enabled) && !isChangelogOpen,
    },
    keyPerson: {
      title: 'Key person',
      canView: canViewKeyPerson(data, !isCommercial) && !isChangelogOpen,
    },
    diversity: {
      title: 'Diversity',
      canView: canViewDiversity(data) && !isChangelogOpen,
    },
    linkedAccounts: {
      title: 'Linked accounts',
      canView:
        canViewLinkedAccounts(data, !!globalSettings.linked_accounts_enabled) &&
        !isChangelogOpen,
    },
  }

  const querySection = window.location.hash.replace('#', '')
  const userSelectedTab =
    querySection && Object.keys(sections).includes(querySection)
      ? querySection
      : 'summary'

  const visibleSections = Object.entries(sections)
    .map(([key, section]) => ({ key: key as SectionKey, ...section }))
    .filter(section => section.canView)

  const canView = (key: SectionKey) => {
    return (
      sections[key].canView && (userSelectedTab === key || userSelectedTab === 'summary')
    )
  }

  return (
    <Flex flexDirection="column" flex={1} minHeight={0}>
      {!isChangelogOpen && (
        <Sticky
          top={mainHeaderState.height}
          bg={Token.color.layoutBackground}
          zIndex={defaultTheme.zIndex.aboveMain}
          pb="s-8"
        >
          <TabBar variant="segmented fit" behaviour="scroll">
            {visibleSections.map(section => (
              <TabBar.Item
                onClick={() => {
                  if (section.key === 'summary') {
                    window.location.hash = ''
                  } else {
                    window.location.hash = section.key
                  }
                  window.scroll(0, 0)
                }}
                key={section.key}
                aria-selected={section.key === userSelectedTab}
              >
                {section.title}
              </TabBar.Item>
            ))}
          </TabBar>
        </Sticky>
      )}
      <PageBody>
        <AnonymizedWarning employee={data} />
        <Box display={canView('summary') && !isChangelogOpen ? 'initial' : 'none'}>
          {'should_check_profile_for_performance_review' in data &&
            data.should_check_profile_for_performance_review && (
              <Box mb="s-16">
                <TextWidget>
                  <TextWidget.Title fontSize="16px" fontWeight="bold">
                    The Performance Review is starting soon.
                  </TextWidget.Title>
                  <TextWidget.Content>
                    Please check that your profile information is correct, as this will be
                    used to set your review scorecard. <br />
                    a. Line manager <br />
                    b. Functional manager <br />
                    b. Role (Specialisation) and seniority <br />
                    Ask your line manager and use the “<Text fontWeight={500}>Edit</Text>"
                    buttons in your profile if you need to edit anything.
                  </TextWidget.Content>
                </TextWidget>
              </Box>
            )}
          <ActionButtonsGroup
            data={data}
            refreshData={refreshData}
            statusTransitions={statusTransitions}
            employeeInactivity={employeeInactivity}
          />
          <Box mb={employeeInactivity.isScheduled ? 's-16' : undefined}>
            <InactivityWarning
              employee={data}
              employeeInactivity={employeeInactivity}
              statusTransitions={statusTransitions}
            />
          </Box>
        </Box>

        {contractsSettings?.force_termination_enabled &&
          !data.has_termination &&
          data.employee_type === 'external' &&
          data?.field_options.permissions?.includes(
            PermissionTypes.CanTerminateEmployees,
          ) && <TerminationDateRequiredWarning data={data} />}

        {['onboarding', 'hired'].includes(data.status.id) && (
          <OnboardingWarning data={data} />
        )}

        {!isChangelogOpen && userSelectedTab === 'summary' ? (
          <EmployeeBadges id={data.id} />
        ) : null}

        <Box display={canView('termination') ? 'initial' : 'none'} css={MarginBottomCss}>
          {hasOffboardingV2Enabled(data) ? (
            <FormPreview
              api={terminationRequestsV2.get}
              title="Termination"
              onEdit={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION_V2, params),
                )
              }
              hideEdit={termination => !canEditSection(termination)}
            >
              <FormPreview.Context<TerminationInterface>
                insert={termination => {
                  if (!termination?.termination_date_time) {
                    return null
                  }

                  const viewingOwnProfile = user.id === data.id

                  let title = isAfter(
                    new Date(termination.termination_date_time),
                    new Date(),
                  )
                    ? 'Employee is scheduled for termination'
                    : 'Employee was terminated'

                  if (viewingOwnProfile) {
                    title = 'You have been scheduled for termination'
                  }

                  return isInternal ? (
                    <ActionWidget
                      title={title}
                      text={
                        <VStack>
                          <Text>
                            Last day in office:{' '}
                            {formatDate(termination.termination_date_time)}
                          </Text>
                          {termination.last_day_of_contract ? (
                            <Text>
                              Last day of contract:{' '}
                              {formatDate(termination.last_day_of_contract)}
                            </Text>
                          ) : null}
                        </VStack>
                      }
                    >
                      {viewingOwnProfile ? null : (
                        <ActionButton
                          use={InternalLink}
                          useIcon="ArrowSend"
                          to={pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, {
                            employeeId: data.id,
                          })}
                        >
                          Open offboarding dashboard
                        </ActionButton>
                      )}
                    </ActionWidget>
                  ) : (
                    <Item mb="s-16">
                      <Item.Content>
                        <Item.Title>{title}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <Icon name="InfoOutline" color={Token.color.red} />
                      </Item.Side>
                    </Item>
                  )
                }}
              />
              {isInternal ? null : (
                <Group>
                  <FormPreview.Item<TerminationInterface>
                    title="Termination date"
                    insert={termination =>
                      termination.termination_date_time
                        ? `${formatDate(
                            termination.termination_date_time,
                          )}, ${getTimeFromString(termination.termination_date_time)}, (${
                            termination.termination_date_timezone?.name
                          })`
                        : '-'
                    }
                  />
                  <FormPreview.Item
                    title="Termination type"
                    field="termination_type.name"
                  />
                </Group>
              )}
            </FormPreview>
          ) : (
            <FormPreview
              api={terminationRequests.get}
              title="Termination"
              onEdit={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.TERMINATION, params))
              }
              hideEdit={termination => !canEditSection(termination)}
            >
              <FormPreview.Context<TerminationInterface>
                insert={termination => {
                  if (!termination?.termination_date_time) {
                    return null
                  }

                  const title = isAfter(
                    new Date(termination.termination_date_time),
                    new Date(),
                  )
                    ? 'Employee is scheduled for termination'
                    : 'Employee was terminated'

                  return (
                    <Item mb="s-16">
                      <Item.Content>
                        <Item.Title>{title}</Item.Title>
                      </Item.Content>
                      <Item.Side>
                        <Icon name="InfoOutline" color={Token.color.red} />
                      </Item.Side>
                    </Item>
                  )
                }}
              />
              <Group css={MarginBottomCss}>
                <FormPreview.Item<TerminationInterface>
                  title="Last day in office"
                  insert={termination =>
                    termination.termination_date_time
                      ? `${formatDate(
                          termination.termination_date_time,
                        )}, ${getTimeFromString(termination.termination_date_time)}, (${
                          termination.termination_date_timezone?.name
                        })`
                      : '-'
                  }
                />
                <FormPreview.Item<TerminationInterface>
                  title="Last day of contract"
                  insert={termination =>
                    formatDateWithFallback(termination.last_day_of_contract, '-')
                  }
                />
                <FormPreview.Item
                  title="Termination type"
                  field="termination_type.name"
                />
                <FormPreview.Item
                  title="Reason for termination"
                  field="termination_reason.name"
                />
                {isInternal && (
                  <>
                    <FormPreview.Item<TerminationInterface>
                      title="Pay in lieu of notice (PILON)"
                      insert={termination => (
                        <>
                          {optionalField(termination.pilon_time_units_number, ' ')}
                          {optionalField(termination.pilon_time_unit?.name, ', ')}
                          {optionalField(termination.pilon_salary_type?.name)}
                        </>
                      )}
                    />
                    <FormPreview.Item<TerminationInterface>
                      title="Lump sum / Severance pay"
                      insert={termination => (
                        <>
                          {optionalField(termination.lump_sum_time_units_number, ' ')}
                          {optionalField(termination.lump_sum_time_unit?.name, ', ')}
                          {optionalField(termination.lump_sum_salary_type?.name, ', ')}
                          {optionalField(termination.lump_sum_payment_type?.name)}
                        </>
                      )}
                    />
                    <FormPreview.Item<TerminationInterface>
                      title="Additional payments"
                      insert={termination => (
                        <>
                          {optionalField(termination.additional_payment_amount, ' ')}
                          {optionalField(
                            termination.additional_payment_currency?.iso_code,
                            ', ',
                          )}
                          {optionalField(termination.additional_payment_type?.name)}
                        </>
                      )}
                    />
                    <FormPreview.Item<TerminationInterface>
                      title="Documents"
                      insert={termination =>
                        termination.uploaded_documents ? (
                          <Flex>
                            {termination.uploaded_documents.map(doc => (
                              <Tooltip text={doc.name} placement="bottom" key={doc.id}>
                                <ActionButton
                                  onClick={() => {
                                    setDocumentSidebarOpen(true)
                                    setSelectedDocumentId(Number(doc.id))
                                  }}
                                  ml="s-16"
                                  useIcon="Document"
                                />
                              </Tooltip>
                            ))}
                          </Flex>
                        ) : (
                          '-'
                        )
                      }
                    />
                  </>
                )}
              </Group>
            </FormPreview>
          )}
        </Box>

        {userSelectedTab === 'summary' &&
          data.resignation &&
          (permissions?.includes(PermissionTypes.AddResignation) ||
            permissions?.includes(PermissionTypes.ViewResignation)) && (
            <ResignationNotice resignationId={data.resignation} />
          )}

        <Box display={canView('work') ? 'initial' : 'none'}>
          <FormPreview<InternalOrExternalEmployee>
            data={sectionsData.name.data}
            dataHandler={sectionsData.name.handler}
            title="Name"
            changelog={changelog.state}
            {...nameSectionProps}
          >
            <ChangeRequestsPendingWidget id={data.id} isVisible={hasPendingNameChanges} />
            <Group css={MarginBottomCss}>
              <FormPreview.Item title="Legal first name" field="first_name" />
              <FormPreview.Item title="Legal middle name" field="middle_name" />
              <FormPreview.Item title="Legal last name" field="last_name" />
              <FormPreview.Item title="Preferred name" field="display_name" />
              <FormPreview.Item title="Work email" field="email" />
              {!!settings?.enable_show_employee_id && (
                <FormPreview.Item title="Employee ID" insert={() => data.id} />
              )}
              <FormPreview.CustomFields
                dynamicGroups={dynamicGroups}
                sectionId={SectionOptions.EmployeeProfile}
                subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
              />
            </Group>
          </FormPreview>

          <FormPreview<InternalOrExternalEmployee>
            data={sectionsData.position.data}
            dataHandler={sectionsData.position.handler}
            title="Position"
            changelog={changelog.state}
            customHeader={positionHeader.customHeader}
          >
            <Group css={MarginBottomCss}>
              <FormPreview.Item<InternalOrExternalEmployee>
                title="Contract type"
                field="contract_type"
                insert={position => position.contract_type.name}
              />
              <FormPreview.Item
                title="Employee type"
                field="employee_type"
                insert={() => capitalize(data.employee_type)}
              />
              <FormPreview.Item<InternalOrExternalEmployee>
                title="Status"
                field="status"
                insert={() => <Status status={data.status.id}>{data.status.name}</Status>}
              />
              <FormPreview.Item<InternalOrExternalEmployee>
                title="Role (Specialisation)"
                field="specialisation.name"
                to={position =>
                  pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                    id: position.specialisation?.id,
                  })
                }
              />
              <FormPreview.Item<EmployeeInterface>
                title="Seniority"
                hidden={!isInternal}
                insert={e => (
                  <Flex>
                    <Text>{e.seniority?.name || '-'}</Text>
                    {!!orgSettings?.enable_multiple_levels_per_seniority &&
                      e.specialisation_seniority_sublevel && (
                        <Text pl="s-6">({e.specialisation_seniority_sublevel.name})</Text>
                      )}
                  </Flex>
                )}
              />
              <FormPreview.Item
                title="Job title"
                field="job_title"
                hidden={!isInternal || !settings?.enable_job_title}
              />
              <FormPreview.Item title="Location" field="location.name" />
              <FormPreview.Item title="Entity" field="entity.name" />
              <FormPreview.Item
                title="Start date"
                field="joining_date_time"
                type="date"
              />
              {performanceSettings?.enable_probation && (
                <FormPreview.Item
                  title="Probation end date"
                  field="end_of_probation_date_time"
                  type="date"
                />
              )}
              <FormPreview.Context<ContractorInterface>
                insert={position => (
                  <FormPreview.Item
                    title="Termination date"
                    field="termination_date_time"
                    type="date"
                    hidden={isInternal || !('termination_date_time' in position)}
                  />
                )}
              />
              <FormPreview.CustomFields
                dynamicGroups={dynamicGroups}
                sectionId={SectionOptions.EmployeeProfile}
                subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.WORK}
              />
            </Group>
          </FormPreview>

          <FormPreview<InternalOrExternalEmployee>
            data={sectionsData.organisation.data}
            dataHandler={sectionsData.organisation.handler}
            title="Organisation"
            changelog={changelog.state}
            {...organisationSectionProps}
          >
            <ChangeRequestsPendingWidget
              id={data.id}
              isVisible={hasPendingOrganisationChanges}
            />

            <Group css={MarginBottomCss}>
              <FormPreview.Item<InternalOrExternalEmployee>
                title="Team"
                field="team.name"
                to={organisation =>
                  // @ts-ignore FIXME: REVPI-19 support optional team value
                  pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, { id: organisation.team.id })
                }
              />
              <FormPreview.Item<EmployeeInterface>
                title={isInternal ? 'Line manager' : 'Point of contact 1'}
                field="line_manager.name"
                to={organisation =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: organisation.line_manager.id,
                  })
                }
              />
              <FormPreview.Item<EmployeeInterface>
                title="Functional manager"
                field="quality_control.name"
                to={organisation =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: organisation.quality_control?.id,
                  })
                }
                hidden={!isInternal}
              />
              <FormPreview.Item<EmployeeInterface>
                title="Buddy"
                field="mentor.name"
                to={organisation =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: organisation.mentor?.id,
                  })
                }
                hidden={!isInternal || !settings?.enable_buddy}
              />
              <FormPreview.Item<ContractorInterface>
                title="Point of contact 2"
                field="quality_control.name"
                to={organisation =>
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: organisation.quality_control?.id,
                  })
                }
                hidden={isInternal}
              />
            </Group>
          </FormPreview>

          {!!settings?.enable_notes && (
            <FormPreview<InternalOrExternalEmployee>
              api={() => {
                const fields = getProfileSectionFields('notes')
                return isInternal
                  ? getEmployeeFields(data.id, fields)
                  : getContractorFields(data.id, fields)
              }}
              title="Notes"
              onEdit={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.NOTES, params))
              }
              hideEdit={notes => !canEditSection(notes)}
            >
              <Box css={MarginBottomCss}>
                <Group>
                  <FormPreview.Details title="HR notes" field="info" />
                  <FormPreview.Item
                    title="Work authorisation status"
                    field="work_authorisation_status.name"
                  />
                  <FormPreview.Item
                    title="Relocation type"
                    field="relocation_type.name"
                  />
                  <FormPreview.Item
                    type="boolean"
                    title="Regulated role"
                    field="is_regulated"
                  />
                </Group>
              </Box>
              <FormPreview.CustomFields
                dynamicGroups={dynamicGroups}
                sectionId={SectionOptions.EmployeeProfile}
                subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.NOTES}
              />
            </FormPreview>
          )}
        </Box>

        <Box display={canView('personal') ? 'initial' : 'none'}>
          <WarnIfProfileDataAnonymised data={data} title="Bio">
            <FormPreview
              api={() => getEmployeeBio(data.id)}
              title="Bio"
              onEdit={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BIO, params))
              }
              hideEdit={bio => !canEditSection(bio)}
            >
              <Group css={MarginBottomCss}>
                <FormPreview.Item<EmployeeBioInterface>
                  title="Legal sex"
                  field="legal_sex.name"
                  insert={profile => <WarnIfEmptyValue value={profile.legal_sex?.name} />}
                />
                <FormPreview.Item<EmployeeBioInterface>
                  title="Languages"
                  insert={profile => (
                    <WarnIfEmptyValue
                      value={
                        profile.languages?.map(language => language.name).join(', ') ||
                        '-'
                      }
                    />
                  )}
                />
                <FormPreview.Item<EmployeeBioInterface>
                  title="Marital status"
                  field="marital_status.name"
                  insert={profile => (
                    <WarnIfEmptyValue value={profile.marital_status?.name} />
                  )}
                />
                <FormPreview.Item<EmployeeBioInterface>
                  title="Birth date"
                  field="birth_date"
                  insert={profile => (
                    <WarnIfEmptyValue
                      value={
                        profile.birth_date
                          ? formatWithoutTimezone(profile.birth_date)
                          : '-'
                      }
                    />
                  )}
                />
                <FormPreview.Item<EmployeeBioInterface>
                  title="Nationalities"
                  type="options"
                  field="nationalities"
                  insert={profile => (
                    <WarnIfEmptyValue
                      value={profile.nationalities
                        ?.map(nationality => nationality.name)
                        .join(', ' || '-')}
                    />
                  )}
                />
                <FormPreview.CustomFields
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BIO}
                />
              </Group>
            </FormPreview>
          </WarnIfProfileDataAnonymised>

          <WarnIfProfileDataAnonymised data={data} title="Contact info">
            <FormPreview
              api={() => getEmployeeContacts(data.id)}
              title="Contact info"
              onEdit={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTACT_INFO, params))
              }
              hideEdit={contactInfo => !canEditSection(contactInfo)}
            >
              <Group css={MarginBottomCss}>
                <FormPreview.Item<EmployeeContactsInterface>
                  title="Personal email"
                  field="personal_email"
                  insert={profile => <WarnIfEmptyValue value={profile.personal_email} />}
                />
                <FormPreview.Item<EmployeeContactsInterface>
                  title="Mobile number"
                  field="phone_number"
                  insert={profile => <WarnIfEmptyValue value={profile.phone_number} />}
                />
                <FormPreview.Item<PersonalInfoInterface>
                  title="Address"
                  insert={profile => (
                    <Box data-testid="employee-contacts-address">
                      <WarnIfEmptyValue
                        value={[
                          profile.address_line_1,
                          profile.address_line_2,
                          profile.address_line_3,
                          profile.post_code,
                          profile.city,
                          profile.county,
                          profile.country?.name,
                        ]}
                      />
                    </Box>
                  )}
                />

                <FormPreview.CustomFields
                  dynamicGroups={dynamicGroups}
                  sectionId={SectionOptions.EmployeeProfile}
                  subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CONTACT_INFO}
                />
              </Group>
            </FormPreview>
          </WarnIfProfileDataAnonymised>

          {!!correspondenceAddressCustomFields.length && (
            <Box css={MarginBottomCss}>
              <FormPreview
                api={() => getProfileFields(data.id, ['custom_fields'])}
                title="Correspondence address"
                onEdit={() =>
                  navigateTo(
                    pathToUrl(
                      ROUTES.FORMS.EMPLOYEE.GENERAL.CORRESPONDENCE_ADDRESS,
                      params,
                    ),
                  )
                }
                hideEdit={address => !canEditSection(address)}
              >
                <Box mt="-s-16">
                  <FormPreview.CustomFields
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS}
                    withSpacing
                  />
                </Box>
              </FormPreview>
            </Box>
          )}

          {!!identificationCustomFields.length && (
            <Box css={MarginBottomCss}>
              <FormPreview
                api={() => getProfileFields(data.id, ['custom_fields'])}
                title="Identification details"
                onEdit={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.IDENTIFICATION, params),
                  )
                }
                hideEdit={identification => !canEditSection(identification)}
              >
                <Box mt="-s-16">
                  <FormPreview.CustomFields
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS}
                    withSpacing
                  />
                </Box>
              </FormPreview>
            </Box>
          )}

          <Box
            display={
              canViewPayroll(data, !!settings?.enable_bank_details) && !isChangelogOpen
                ? 'initial'
                : 'none'
            }
          >
            <WarnIfProfileDataAnonymised data={data} title="Bank details">
              <FormPreview
                api={payrollRequests.get}
                title="Bank details"
                onEdit={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.BANK_DETAILS, params),
                  )
                }
                hideEdit={payroll => !canEditSection(payroll)}
              >
                <Group css={MarginBottomCss}>
                  <FormPreview.Item<PayrollInterface>
                    title="Bank name"
                    field="bank_name"
                    insert={profile => <WarnIfEmptyValue value={profile.bank_name} />}
                  />
                  <FormPreview.Item<PayrollInterface>
                    title="Account name"
                    field="account_name"
                    insert={profile => <WarnIfEmptyValue value={profile.account_name} />}
                  />
                  <FormPreview.Item<PayrollInterface>
                    title="Bank currency"
                    field="bank_currency.name"
                    insert={profile => (
                      <WarnIfEmptyValue value={profile.bank_currency?.name} />
                    )}
                  />
                  <FormPreview.CustomFields
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BANK_DETAILS}
                  />
                </Group>
              </FormPreview>
            </WarnIfProfileDataAnonymised>
          </Box>

          <Box css={MarginBottomCss}>
            <WarnIfProfileDataAnonymised data={data} title="Emergency contact">
              <FormPreview
                api={() => getEmployeeEmergencyContact(data.id)}
                title="Emergency contact"
                onEdit={() =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.EMERGENCY_CONTACTS, params),
                  )
                }
                hideEdit={emergency => !canEditSection(emergency)}
              >
                <Group>
                  <FormPreview.Item<EmployeeEmergencyContactInterface>
                    title="Name"
                    field="emergency_contact_full_name"
                    insert={profile => (
                      <WarnIfEmptyValue value={profile.emergency_contact_full_name} />
                    )}
                  />
                  <FormPreview.Item<EmployeeEmergencyContactInterface>
                    title="Relationship with employee"
                    field="emergency_contact_relationship"
                    insert={profile => (
                      <WarnIfEmptyValue value={profile.emergency_contact_relationship} />
                    )}
                  />
                  <FormPreview.Item<EmployeeEmergencyContactInterface>
                    title="Email"
                    field="emergency_contact_email"
                    insert={profile => (
                      <WarnIfEmptyValue value={profile.emergency_contact_email} />
                    )}
                  />
                  <FormPreview.Item<EmployeeEmergencyContactInterface>
                    title="Mobile number"
                    field="emergency_contact_mobile_phone"
                    insert={profile => (
                      <WarnIfEmptyValue value={profile.emergency_contact_mobile_phone} />
                    )}
                  />
                </Group>
              </FormPreview>
            </WarnIfProfileDataAnonymised>
          </Box>

          {lifecycleSettings?.enable_equipment_delivery ? (
            <Box css={MarginBottomCss}>
              <WarnIfProfileDataAnonymised data={data} title="Laptop delivery">
                <FormPreview
                  api={() => getEmployeeLaptopDeliveryAddress(data.id)}
                  title="Laptop delivery"
                  onEdit={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.LAPTOP_DELIVERY, params),
                    )
                  }
                  hideEdit={laptopDelivery => !canEditSection(laptopDelivery)}
                >
                  <FormPreview.Item<PersonalInfoInterface>
                    title="Laptop delivery address"
                    insert={profile => {
                      if (profile.laptop_delivery?.id === 'ship_to_different_address') {
                        return (
                          <>
                            {optionalField(
                              profile.laptop_delivery_address_line_1,
                              <br />,
                            )}
                            {optionalField(
                              profile.laptop_delivery_address_line_2,
                              <br />,
                            )}
                            {optionalField(
                              profile.laptop_delivery_address_line_3,
                              <br />,
                            )}
                            {optionalField(profile.laptop_delivery_post_code, <br />)}
                            {optionalField(profile.laptop_delivery_city, ', ')}
                            {optionalField(profile.laptop_delivery_county, ', ')}
                            {optionalField(profile.laptop_delivery_country?.name)}
                          </>
                        )
                      }

                      if (profile.laptop_delivery?.id === 'ship_to_address') {
                        return (
                          <>
                            {optionalField(profile.address_line_1, <br />)}
                            {optionalField(profile.address_line_2, <br />)}
                            {optionalField(profile.address_line_3, <br />)}
                            {optionalField(profile.post_code, <br />)}
                            {optionalField(profile.city, ', ')}
                            {optionalField(profile.county, ', ')}
                            {optionalField(profile.country?.name)}
                          </>
                        )
                      }

                      return '-'
                    }}
                  />
                </FormPreview>
              </WarnIfProfileDataAnonymised>
            </Box>
          ) : null}

          <Box css={MarginBottomCss} mt="s-16">
            <WarnIfProfileDataAnonymised data={data} title="About me">
              <FormPreview<EmployeeAboutInterface>
                api={() => getEmployeeAbout(data.id)}
                title="About me"
                onEdit={() =>
                  navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.ABOUT, params))
                }
                hideEdit={about => !canEditSection(about)}
              >
                <Group>
                  <FormPreview.Details<EmployeeAboutInterface> field="about" />
                  <FormPreview.CustomFields
                    dynamicGroups={dynamicGroups}
                    sectionId={SectionOptions.EmployeeProfile}
                    subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.ABOUT_YOU}
                  />
                </Group>
              </FormPreview>
            </WarnIfProfileDataAnonymised>
          </Box>
        </Box>

        <Box display={canView('contracts') ? 'initial' : 'none'} css={MarginBottomCss}>
          <Contracts employeeId={data.id} detailsTitle="Contracts history" />
        </Box>

        <Box display={canView('rightToWork') ? 'initial' : 'none'} css={MarginBottomCss}>
          <RightToWork employeeId={data.id} />
        </Box>

        <Box display={canView('screening') ? 'initial' : 'none'} css={MarginBottomCss}>
          <ScreeningCheck employeeId={data.id} />
        </Box>

        <Box display={canView('keyPerson') ? 'initial' : 'none'} css={MarginBottomCss}>
          <FormPreview title="Responsibilities">
            <KeyPerson data={data} />
          </FormPreview>
        </Box>

        <Box display={canView('diversity') ? 'initial' : 'none'}>
          <FormPreview
            api={diversityRequests.get}
            title="Diversity"
            onEdit={() =>
              navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.DIVERSITY, params))
            }
            hideEdit={diversity => !canEditSection(diversity)}
          >
            <Text
              variant="caption"
              mb="s-16"
              mt="-12px"
              color={Color.GREY_TONE_50}
              display="inline-block"
            >
              This information is visible only to you. Inputting the details is optional.{' '}
              <HideIfCommercial>
                More about this{' '}
                <Link href={DIVERSITY_DATA} target="_blank" rel="noopener norefferer">
                  here
                </Link>
                .
              </HideIfCommercial>
            </Text>
            <Group css={MarginBottomCss}>
              <FormPreview.Item title="Age group" field="age_range.name" />
              <FormPreview.Item title="Gender identity" field="gender.name" />
              <FormPreview.Item
                title="Sexual orientation"
                field="sexual_orientation.name"
              />
              <FormPreview.Item<DiversityInterface>
                title="Parent/carer"
                insert={diversity =>
                  diversity.carer_types?.map(carer => carer.name).join(', ')
                }
              />
              <FormPreview.Item<DiversityInterface>
                title="Disabilities"
                insert={diversity =>
                  diversity.disabilities?.map(disability => disability.name).join(', ')
                }
              />
              <FormPreview.Item<DiversityInterface>
                title="Ethnicities"
                insert={diversity =>
                  diversity.ethnicities?.map(ethnicity => ethnicity.name).join(', ')
                }
              />
              <FormPreview.Item
                title="Socio-economic background"
                field="socioeconomic_background.name"
              />
              <FormPreview.Item
                title="Educational background"
                field="educational_background.name"
              />
              <FormPreview.Item title="Religion" field="religion.name" />
            </Group>
          </FormPreview>
        </Box>

        <Box display={canView('linkedAccounts') ? 'initial' : 'none'} mt="s-16">
          <LinkedAccountsList showTitle data={data} />
        </Box>
      </PageBody>

      {isChangelogOpen && (
        <PageActions>
          <Button onClick={changelog.onClose} useIcon="Cross" elevated>
            Close changelog
          </Button>
        </PageActions>
      )}

      {selectedDocumentId && (
        <DocumentSidebar
          employeeId={data.id}
          id={selectedDocumentId}
          isOpen={documentSidebarOpen}
          onClose={() => setDocumentSidebarOpen(false)}
        />
      )}
    </Flex>
  )
}
