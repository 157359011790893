import React, { useEffect, useState } from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import SideBar from '@components/SideBar/SideBar'
import PlaceholdersTable from '@src/pages/Forms/EmailTemplate/PlaceholdersTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import EmailForm from '@src/features/EmailForm/EmailForm'
import { selectUser } from '@src/store/auth/selectors'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const General = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { values } = useLapeContext<EmailTemplateInterface>()
  const user = useSelector(selectUser)

  const params = useParams<{ id?: string }>()

  useEffect(() => {
    if (values.owner) {
      return
    }
    values.owner = {
      id: user.id,
      full_name: user.full_name,
      name: user.display_name,
      status: user.status.id,
      avatar: user.avatar,
      employee_type: user.employee_type,
    }
  }, [])

  useEffect(() => {
    // when we remove all attachments we put null, but for PATCH requests it means we don't change anything
    if (values.attachments === null) {
      values.attachments = []
    }
  }, [values.attachments])

  const showPlaceholders = () => {
    setIsOpen(true)
  }

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit email template' : 'New email template'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW, params)
            : ROUTES.FORMS.EMAIL_TEMPLATES
        }
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Template details" />
          <InputGroup>
            <LapeNewInput name="name" label="Template name" required />
            <LapeNewTextArea name="description" label="Description" rows={2} />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
              required
            />
          </InputGroup>

          <NewStepperTitle title="Email details" />
          <EmailForm<EmailTemplateInterface> onShowPlaceholders={showPlaceholders} />
        </AutoStepper>
      </PageBody>

      <SideBar isOpen={isOpen} onClose={() => setIsOpen(false)} title="Placeholders">
        <PlaceholdersTable />
      </SideBar>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Email template saved successfully"
          previewUrl={ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
