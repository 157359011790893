import React from 'react'
import { DataPointSkeleton, Highlights } from '@revolut/ui-kit'
import { useGetCRMReviewStats, useGetCRMStats } from '@src/api/CRM'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { CRMStatsInterface } from '@src/interfaces/CRM'
import { FilterByInterface } from '@src/interfaces/data'

type StatsProps = {
  filters: FilterByInterface[]
  loading: boolean
  stats?: CRMStatsInterface[]
  onFilterChange: (filters: FilterByInterface[]) => void
}

const getSelectedStat = (filters: FilterByInterface[], stats: CRMStatsInterface[]) => {
  if (!stats.length) {
    return undefined
  }
  const stat = stats.find(s => {
    const statFilters = s.filters?.join(',')
    return filters.some(
      f => f.columnName === s.filter_field_name && f?.filters[0]?.id === statFilters,
    )
  })
  return stat || stats[0]
}

const Stats = ({ filters, loading, stats, onFilterChange }: StatsProps) => {
  if (loading) {
    return (
      <Highlights>
        <DataPointSkeleton />
        <DataPointSkeleton />
        <DataPointSkeleton />
      </Highlights>
    )
  }
  if (!stats?.length) {
    return null
  }
  const statsFilterConfig = stats.map(stat => ({
    id: stat.label,
    title: stat.label,
    value: stat.count,
    stat,
  }))
  const handleClick = (id: string) => {
    const filter = statsFilterConfig.find(stat => stat.id === id)?.stat
    const newFilters: FilterByInterface[] = []
    if (filter) {
      newFilters.push({
        filters: [
          {
            name: filter.filter_field_name,
            id: filter.filters?.join(',') || '',
          },
        ],
        columnName: filter.filter_field_name,
      })
    }
    if (filter?.additional_filters) {
      newFilters.push(
        ...Object.entries(filter.additional_filters).map(([k, v]) => ({
          filters: [
            {
              name: k,
              id: v,
            },
          ],
          columnName: k,
        })),
      )
    }
    onFilterChange(newFilters)
  }
  const currentStatFilter = getSelectedStat(filters, stats)
  return (
    <StatFilters
      filters={statsFilterConfig}
      onClick={handleClick}
      selectedFilter={currentStatFilter?.label}
    />
  )
}

type CRMStatsProps = Omit<StatsProps, 'stats' | 'loading'>

export const CRMStats = (props: CRMStatsProps) => {
  const { data: stats, isLoading: loading } = useGetCRMStats(props.filters)
  return <Stats loading={loading} stats={stats} {...props} />
}

type CRMReviewStatsProps = Omit<StatsProps, 'stats' | 'loading'> & {
  reviewId: string
}

export const CRMReviewStats = ({ reviewId, ...props }: CRMReviewStatsProps) => {
  const { data: stats, isLoading: loading } = useGetCRMReviewStats(
    props.filters,
    reviewId,
  )
  return <Stats loading={loading} stats={stats} {...props} />
}
