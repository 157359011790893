import React from 'react'
import { KpiInterface } from '@src/interfaces/kpis'
import { Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { transformParentKpiToIndividual } from '@src/utils/kpi'

interface Props {
  data: KpiInterface
  employee: { id: number; full_name: string }
}

const AssignRelevantKPI = ({ data, employee }: Props) => {
  return (
    <Text
      color="primary"
      onClick={e => {
        // Needed to avoid triggering row click action
        e.stopPropagation()
        navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL, {}), {
          initialValues: transformParentKpiToIndividual(data, employee),
        })
      }}
    >
      Assign
    </Text>
  )
}

export default AssignRelevantKPI
