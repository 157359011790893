import React, { useState } from 'react'
import { Box, Text, VStack, Widget } from '@revolut/ui-kit'
import {
  onboardingTimeManagementImportSetBalanceColumn,
  onboardingTimeManagementImportAdjustBalanceColumn,
  onboardingTimeManagementImportBalanceEmployeeColumn,
  onboardingTimeManagementImportBalancePolicyColumn,
} from '@src/constants/columns/onboardingTimeManagement'
import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportTimeOffBalancesInterface } from '@src/interfaces/importTimeOffBalances'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import RadioSwitch from '@src/pages/OnboardingChecklist/components/RadioSwitch'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportTimeOffBalancesInterface>> => ({
  cells: [
    {
      ...onboardingTimeManagementImportBalanceEmployeeColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportBalancePolicyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportSetBalanceColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingTimeManagementImportAdjustBalanceColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportTimeOffBalancesFlow = () => {
  const adjustBalanceOption = { id: 'adjust_balance', name: 'Adjust balance' }
  const setBalanceOption = { id: 'set_balance', name: 'Set balance' }

  const [adjustmentType, setAdjustmentType] = useState<
    typeof adjustBalanceOption | typeof setBalanceOption
  >(adjustBalanceOption)

  return (
    <BulkDataUploadV2
      apiEndpoint={API.TIME_OFF_BALANCES_BULK_UPLOAD}
      anyRoute={ROUTES.FORMS.IMPORT_DATA.TIME_OFF_BALANCES.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.TIME_OFF_BALANCES.IMPORT}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.TIME_OFF_BALANCES.SESSION}
      category="time_off_balance_bulk_upload"
      entity="balance"
      name="balance"
      tableName={TableNames.UploadTimeOffBalances}
      templateParams={{ version: '2', extra_columns: adjustmentType.id }}
      row={row}
      hiddenColumns={
        adjustmentType.id === 'set_balance'
          ? { adjust_balance: true }
          : { set_balance: true }
      }
      header={
        <PageHeader
          title="Time Off"
          subtitle="Update your historical time-off records to ensure accurate leave balances for all employees"
          backUrl={ROUTES.APPS.TIME_MANAGEMENT.TIME_OFF.REQUESTS}
        />
      }
      uploadFileScreenTopNode={
        <Widget p="s-12">
          <VStack space="s-4">
            <Box p="s-12">
              <Text use="div" variant="h5">
                Do you want to adjust balance or set balance?
              </Text>
            </Box>
            <RadioSwitch<IdAndName<string>>
              highlightSelected
              variant="horizontal"
              options={[
                {
                  id: adjustBalanceOption.id,
                  label: adjustBalanceOption.name,
                  description:
                    'Enter a value to adjust the current balance. A positive number adds to the balance, while a negative number subtracts from it.',
                  value: adjustBalanceOption,
                },
                {
                  id: setBalanceOption.id,
                  label: setBalanceOption.name,
                  description:
                    "Enter the exact balance value for the employee's leave. This value will overwrite any existing balance.",
                  value: setBalanceOption,
                },
              ]}
              value={adjustmentType}
              onChange={newType => setAdjustmentType(newType)}
            />
          </VStack>
        </Widget>
      }
    />
  )
}
