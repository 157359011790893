import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { TeamNPS } from '@src/interfaces/teams'
import { A } from '@components/CommonSC/General'
import React from 'react'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'

const TooltipStyled = styled(Tooltip)`
  display: block;
`

export const npsDetractorScoreColumn: ColumnInterface<TeamNPS> = {
  type: CellTypes.text,
  idPoint: 'score',
  dataPoint: 'score',
  sortKey: 'score',
  filterType: FilterType.range,
  filterKey: 'score',
  selectorsKey: selectorKeys.none,
  title: 'Score',
}

export const npsDetractorCommentColumn: ColumnInterface<TeamNPS> = {
  type: CellTypes.insert,
  idPoint: 'comment',
  dataPoint: 'comment',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <TooltipStyled text={data.comment} placement="right">
      {data.comment}
    </TooltipStyled>
  ),
  title: 'Comments',
}

export const npsDetractorCreationDateColumn: ColumnInterface<TeamNPS> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: null,
  filterKey: 'creation_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Created At',
}

export const npsDetractorLinkColumn: ColumnInterface<TeamNPS> = {
  type: CellTypes.insert,
  idPoint: 'link',
  dataPoint: 'link',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) =>
    data.link ? (
      <A href={data.link} target="_blank">
        Link
      </A>
    ) : (
      '-'
    ),
  selectorsKey: selectorKeys.none,
  title: 'Link',
}
