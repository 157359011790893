import { useDelete, useFetch, usePost, useUpdate } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { FavouriteInterface } from '@src/interfaces/favourites'
import { GetRequestData } from '@src/interfaces'
import { apiWithoutHandling, api } from '.'

export const useGetFavourites = () =>
  useFetch<GetRequestData<FavouriteInterface>>(
    API.FAVOURITES,
    undefined,
    undefined,
    undefined,
    {
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 90,
      refetchOnWindowFocus: false,
    },
  )

export const changeFavouriteOrder = (id: number, targetId: number) =>
  api.patch(`${API.FAVOURITES}/${id}/reorder`, {
    target_position_object_id: targetId,
  })

export const useAddFavourite = () =>
  usePost<
    GetRequestData<FavouriteInterface>,
    FavouriteInterface,
    { favourite_object: { id: number } }
  >(API.FAVOURITES, undefined, undefined, (oldData, newData) => ({
    ...oldData,
    count: oldData.count + 1,
    results: [...oldData.results, newData],
  }))

export const syncFavourites = (data: { favourite_object: { id: number } }[]) =>
  apiWithoutHandling.post<GetRequestData<FavouriteInterface>>(API.FAVOURITES, data)

export const useDeleteFavourite = () =>
  useDelete<GetRequestData<FavouriteInterface>>(
    API.FAVOURITES,
    undefined,
    undefined,
    (oldData, id) => {
      const newResults = oldData.results.filter(f => f.id !== id)
      return {
        ...oldData,
        count: newResults.length,
        results: newResults,
      }
    },
  )

export const useUpdateFavourite = () =>
  useUpdate<GetRequestData<FavouriteInterface>, Partial<FavouriteInterface>>(
    API.FAVOURITES,
    undefined,
    undefined,
    false,
    (oldData, newData, id) => {
      return {
        ...oldData,
        results: oldData.results.map(f => (f.id === id ? { ...f, ...newData } : f)),
      }
    },
    undefined,
    false,
  )
