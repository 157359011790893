import React, { RefObject, useRef } from 'react'
import {
  Flex,
  Layout,
  Button,
  Header,
  StatusPopup,
  useStatusPopup,
  InputGroup,
} from '@revolut/ui-kit'
import { z } from 'zod'

import { GetRevolutPeopleCaption, NonFieldErrors } from './common'
import { findWorkspace } from '@src/api/login'
import { ReCaptcha } from '@components/ReCaptcha/ReCaptcha'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'
import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { arrayErrorsToFormError } from '@src/utils/form'

const INVALID_EMAIL_ERROR = 'Enter a valid email address.'

const validateEmail = (email: string) =>
  z.string().email({ message: INVALID_EMAIL_ERROR }).safeParse(email)

interface FindWorkspaceFormInterface {
  email: string
  captcha: string
}

interface FindWorkspaceFormProps {
  captchaRef: RefObject<ReCaptchaHandle>
}

const FindWorkspaceForm = ({ captchaRef }: FindWorkspaceFormProps) => {
  const { values, isSubmitting, apiErrors, errors, submit } =
    useLapeContext<FindWorkspaceFormInterface>()

  const nonFieldErrors = Object.entries(apiErrors)
    .filter(([key]) => key !== 'email')
    .map(([, value]) => value)

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="email"
          label="Email"
          hasError={!!apiErrors.email}
          message={apiErrors.email}
          required
        />
        <NonFieldErrors nonFieldErrors={nonFieldErrors} />
        <Flex alignItems="center" flexDirection="column" my="s-16">
          <ReCaptcha
            onVerify={value => {
              values.captcha = value
            }}
            name="captcha"
            error={errors?.captcha}
            ref={captchaRef}
          />
        </Flex>
      </InputGroup>

      <Layout.ActionsFill>
        <Button onClick={() => submit()} type="submit" pending={isSubmitting} elevated>
          Continue with email
        </Button>
        <GetRevolutPeopleCaption />
      </Layout.ActionsFill>
    </>
  )
}

export const FindWorkspace = () => {
  const captchaRef = useRef<ReCaptchaHandle>(null)

  const statusPopup = useStatusPopup()

  const onSubmit = async (
    form: LapeFormInterface<FindWorkspaceFormInterface>,
  ): Promise<any> => {
    let formValues = form.values

    const validationResult = validateEmail(formValues.email)

    if (!validationResult.success) {
      form.apiErrors.email = validationResult.error.issues[0].message
      return Promise.reject()
    }

    const token = await captchaRef.current?.verify()

    if (token) {
      formValues = { ...form.values, captcha: token }
    }

    /** Resetting all the errors, to clear the `NonFieldErrors` */
    form.apiErrors = {}

    return findWorkspace(formValues)
      .then(data => {
        statusPopup.show(
          <StatusPopup variant="success-result">
            <StatusPopup.Title>
              You should receive an email with your workspaces soon
            </StatusPopup.Title>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )

        return data
      })
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        form.apiErrors = errors
        throw error
      })
      .finally(() => {
        captchaRef.current?.reset()
      })
  }

  return (
    <Flex flexDirection="column" mt={{ all: '80px', lg: 'auto' }} mb="auto" gap="s-16">
      <Header variant="item">
        <Header.BackButton aria-label="Back" onClick={() => window.history.back()} />
        <Header.Title>Lets find your team</Header.Title>
        <Header.Description>
          We suggest using the email address that you use at work.
        </Header.Description>
      </Header>

      <LapeForm onSubmit={onSubmit}>
        <FindWorkspaceForm captchaRef={captchaRef} />
      </LapeForm>
    </Flex>
  )
}
