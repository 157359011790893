import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { MoreBar } from '@revolut/ui-kit'
import { successNotification } from '@src/actions/NotificationActions'
import { PermissionTypes } from '@src/store/auth/types'
import { toggleRequisitionRegulation } from '@src/api/requisitions'
import { connect } from 'lape'

const RegulatedButton = () => {
  const { values, reset } = useLapeContext<RequisitionInterface>()
  const [submitting, setSubmitting] = useState(false)

  const toggleRegulated = () => {
    const isRegulated = !values.is_regulated
    setSubmitting(true)
    toggleRequisitionRegulation(values.id, isRegulated)
      .then(() => {
        values.is_regulated = isRegulated
        reset(values)
        successNotification(
          `Requisition marked as ${isRegulated ? 'regulated' : 'un-regulated'}`,
        )
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  if (!values.field_options?.permissions?.includes(PermissionTypes.ChangeRegulatedFlag)) {
    return null
  }

  return (
    <MoreBar.Action useIcon="Bank" pending={submitting} onClick={toggleRegulated}>
      Mark as {values.is_regulated ? 'un-regulated' : 'regulated'}
    </MoreBar.Action>
  )
}

export default connect(RegulatedButton)
