import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'

export const keyPersonOwnershipActionsColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
