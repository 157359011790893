import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Spacer, Subheader, VStack } from '@revolut/ui-kit'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentsTemplatesInterface,
  DocumentsTemplateType,
  DocumentsTemplateTypeId,
} from '@src/interfaces/documentsTemplates'
import { pathToUrl } from '@src/utils/router'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectUser } from '@src/store/auth/selectors'
import BottomText from '@components/Inputs/partials/BottomText'
import { PageBody } from '@src/components/Page/PageBody'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import LapeEditor from '@components/Inputs/LapeFields/LapeEditor'
import { CommonTabProps } from '.'

export const TemplateFormInputs = () => {
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  return (
    <VStack space="s-8">
      <Box>
        <Subheader>
          <Subheader.Title>What should your template be called?</Subheader.Title>
        </Subheader>
        <LapeNewInput required name="name" label="Name" />
      </Box>
      <Box>
        <Subheader>
          <Subheader.Title>Which category does this template belong to?</Subheader.Title>
        </Subheader>
        <LapeRadioSelectInput
          required={false}
          name="category"
          label="Category"
          selector={selectorKeys.document_categories}
        />
      </Box>
      <Box>
        <Subheader>
          <Subheader.Title>
            Do you want to include instructions for employees on how to complete this
            request?
          </Subheader.Title>
        </Subheader>
        <Box>
          <LapeEditor
            allowImages={false}
            name="instructions"
            placeholder="Instructions"
          />
          <BottomText
            bottomInfo={
              'Insert the instructions for employees on how to complete the request from this template.' +
              ' For example, include information on what kind of attachment is required.'
            }
          />
        </Box>
      </Box>
      {values.template_type?.id === 'esignature' && (
        <Box>
          <Subheader>
            <Subheader.Title>
              Should employee be allowed to generate this template by themselves?
            </Subheader.Title>
          </Subheader>
          <VStack space="s-16">
            <LapeSingleCheckbox
              name="enable_self_serve"
              label="Enable self serve"
              description="This will allow employees to generate this template inside their profiles"
            />
            {values.enable_self_serve && (
              <LapeRadioSelectInput
                name="issuer"
                label="Issuer"
                selector={selectorKeys.employee}
              />
            )}
          </VStack>
        </Box>
      )}
    </VStack>
  )
}

type Props = {
  templateTypeId: DocumentsTemplateTypeId
}
export const TabBasics = ({ nextTabPath, templateTypeId }: CommonTabProps & Props) => {
  const currentUser = useSelector(selectUser)
  const { values } = useLapeContext<DocumentsTemplatesInterface>()

  useEffect(() => {
    values.owner = { id: currentUser.id }
    values.template_type = { id: templateTypeId } as DocumentsTemplateType
  }, [])

  return (
    <PageBody>
      <TemplateFormInputs />
      <Spacer height={128} />
      <PageActions>
        <NewSaveButtonWithPopup
          noPopup
          useValidator
          onAfterSubmit={res =>
            navigateReplace(pathToUrl(nextTabPath, { id: String(res.id) }))
          }
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageBody>
  )
}
