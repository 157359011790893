import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { CellTypes, RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { selectorKeys } from '@src/constants/api'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeeColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsSkillsRequests } from '@src/api/notifications'

const Skills = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 190,
      },
      {
        type: CellTypes.text,
        idPoint: 'function.id',
        dataPoint: 'function.name',
        sortKey: 'function__name',
        filterKey: 'function_id',
        selectorsKey: selectorKeys.functions,
        title: 'Function',
        width: 150,
      },
      {
        ...notificationsEmployeeColumn,
        width: 260,
      },
      {
        ...notificationsReceivedColumn,
        width: 120,
      },
      {
        ...notificationsDeadline,
        width: 110,
      },
      {
        ...notificationsPriorityColumn,
        width: 100,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }
  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsSkillsRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Skills
