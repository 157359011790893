import React from 'react'
import { MoreBar } from '@revolut/ui-kit'

import { useGlobalSettings } from '@src/api/settings'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetEmployeePromotions } from '@src/api/promotions'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useCanNominateAnyTime } from '@src/utils/promotion'

export const ViewNominationButton = ({
  data,
  cycleId,
}: {
  data: EmployeeInterface
  cycleId?: number | string
}) => {
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  const canNominateAnyTime = useCanNominateAnyTime()

  const canNominate =
    data?.field_options?.permissions?.includes(PermissionTypes.AddPromotionNomination) ||
    canNominateAnyTime

  const { data: pendingNominations } = useGetEmployeePromotions({
    employeeId: canNominate && promotions_enabled ? data.id : undefined,
    cycleId,
  })

  if (!pendingNominations?.count || !data.id || !canNominate) {
    return null
  }

  const nomination = pendingNominations.results[pendingNominations.results.length - 1]

  return (
    <MoreBar.Action
      onClick={() => {
        navigateTo(
          pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM_VIEW, {
            id: nomination.id,
            employeeId: data.id,
          }),
          {
            backUrl: `${getLocationPathnameWithoutWorkspace()}${window.location.search}`,
          },
        )
      }}
    >
      View nomination
    </MoreBar.Action>
  )
}
