import React from 'react'
import { useMatchMedia, Token, Image } from '@revolut/ui-kit'

import type { FeatureBlockAlign } from './types'

interface FeatureBlockImageProps {
  image: string
  align: FeatureBlockAlign
}

export const FeatureBlockImage = ({ image, align }: FeatureBlockImageProps) => {
  const isLg = useMatchMedia(Token.media.lg)

  return (
    <Image
      alt=""
      src={image}
      sizes="100vw"
      style={{ objectFit: align, objectPosition: isLg ? 'center' : '0 0' }}
    />
  )
}
