import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import * as EmployeePerformanceViewDeliverables from '@src/pages/Forms/EmployeePerformanceView/Deliverables'
import * as EmployeePerformanceViewSkills from '@src/pages/Forms/EmployeePerformanceView/Skills'
import * as EmployeePerformanceViewCultureFit from '@src/pages/Forms/EmployeePerformanceView/CultureFit'
import * as EmployeePerformanceViewRecommendations from '@src/pages/Forms/EmployeePerformanceView/Recommendations'
import * as EmployeePerformanceViewSummary from '@src/pages/Forms/EmployeePerformanceView/Summary'
import { pathToUrl } from '@src/utils/router'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import { TabsInterface } from '@src/interfaces/data'
import PerformanceView from '@src/pages/Forms/EmployeePerformance/components/PerformanceView/PerformanceView'
import * as KPI from '@src/pages/Forms/EmployeePerformance/KPI'

const getTabs = (
  params: {
    id: string
    employeeId?: string
  },
  isManagerReview: boolean,
): TabsInterface<ReviewSummaryInterface>[] => [
  {
    title: isManagerReview ? 'KPI' : 'Deliverables',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL, params),
    validator: {},
    component: isManagerReview
      ? KPI.default
      : EmployeePerformanceViewDeliverables.default,
    visibilityCheck: () => true,
  },
  {
    title: 'Skills',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SKILLS,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SKILLS, params),

    validator: {},
    component: EmployeePerformanceViewSkills.default,
    visibilityCheck: (data, options) =>
      !!(
        options?.performanceSettings?.enable_skill_assessment &&
        (data?.summary?.functional_skills || data?.summary?.manager_values)
      ),
  },
  {
    title: 'Culture fit',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.CULTURE_FIT,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.CULTURE_FIT, params),
    validator: {},
    component: EmployeePerformanceViewCultureFit.default,
    visibilityCheck: data =>
      !!data?.summary?.culture_values || !!data?.summary?.culture_skills,
  },
  {
    title: 'Recommendations',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.RECOMMENDATION,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.RECOMMENDATION, params),
    validator: {},
    component: EmployeePerformanceViewRecommendations.default,
    visibilityCheck: data =>
      !!(
        data?.summary?.line_manager_extra_section ||
        data?.summary?.functional_manager_extra_section ||
        data?.summary?.peer_extra_sections
      ),
  },
  {
    title: 'Summary',
    path: ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SUMMARY,
    to: pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SUMMARY, params),
    validator: {},
    component: EmployeePerformanceViewSummary.default,
    visibilityCheck: () => true,
  },
]

const EmployeePerformanceViewForm = () => (
  <PerformanceView getTabs={getTabs} category={ReviewCategory.Performance} />
)

export default connect(EmployeePerformanceViewForm)
