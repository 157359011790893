import React, { useState } from 'react'
import isBefore from 'date-fns/isBefore'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import pluralize from 'pluralize'
import {
  Avatar,
  chain,
  Flex,
  Group,
  HStack,
  Icon,
  IconButton,
  IconName,
  Item,
  ItemSkeleton,
  Placeholder,
  Text,
  TextButton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import {
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_NONE,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollIssuesLevelId,
  PayrollTimelineChangeInterface,
  PayrollTimelineDomainCategory,
} from '@src/interfaces/payrollV2'
import { parseDomainFieldChanges } from '..'
import { domainNameToFieldsConfig } from '../domainToGenericField'
import { DomainChangesWidget } from './DomainChangesWidget'
import { IssueLevelFilter } from './IssueLeveFilter'

const getChangesDescription = (changes: PayrollTimelineChangeInterface[]) => {
  const hasChanges = !!changes?.length
  const issuesCount = changes.reduce<Record<PayrollIssuesLevelId | 'total', number>>(
    (count, change) => {
      const issues = change.issues || {}
      const issuesNum = Object.keys(issues).length
      const issuesLevel = change.issues_level
      if (issuesLevel && issuesNum) {
        count[issuesLevel.id] += issuesNum
        count.total += issuesNum
      }
      return count
    },
    {
      [PAYROLL_ISSUES_LEVEL_NONE]: 0,
      [PAYROLL_ISSUES_LEVEL_WARNING]: 0,
      [PAYROLL_ISSUES_LEVEL_CRITICAL]: 0,
      total: 0,
    },
  )
  const changesNum = hasChanges
    ? `${pluralize('change', changes.length, true)}`
    : 'No changes in selected cycle'
  const totalIssuesNum = issuesCount.total
    ? pluralize('issue', issuesCount.total, true)
    : undefined

  return hasChanges
    ? chain(
        changesNum,
        totalIssuesNum ? (
          <Text color={Token.color.warning}>{totalIssuesNum}</Text>
        ) : undefined,
        issuesCount[PAYROLL_ISSUES_LEVEL_CRITICAL] ? (
          <Text color={Token.color.danger}>
            {issuesCount[PAYROLL_ISSUES_LEVEL_CRITICAL]} critical
          </Text>
        ) : undefined,
      )
    : 'No changes in selected cycle'
}

type Props = {
  isLoading: boolean
  isError: boolean
  timelineChanges: PayrollTimelineChangeInterface[] | undefined
  onClose: () => void
  groupChanges?: boolean
}
export const DetailsSidebarContent = ({
  isLoading,
  isError,
  timelineChanges,
  groupChanges,
  onClose,
}: Props) => {
  const [selectedCategory, setSelectedCategory] =
    useState<PayrollTimelineDomainCategory>()
  const [issueLevelFilter, setIssueLevelFitler] = useState<
    IdAndName<PayrollIssuesLevelId>[]
  >([])

  if (isLoading) {
    return (
      <VStack space="s-16">
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </VStack>
    )
  }
  if (isError) {
    return (
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D083.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D083@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D083@3x.png',
          }}
        />
        <Placeholder.Title>Something went wrong</Placeholder.Title>
        <Placeholder.Description>Please, try again later</Placeholder.Description>
        <Placeholder.Actions>
          <TextButton onClick={onClose}>Close</TextButton>
        </Placeholder.Actions>
      </Placeholder>
    )
  }

  const formattedTimelineChanges = timelineChanges
    ?.map(change => {
      const fieldsConfig = domainNameToFieldsConfig[change.domain_name] || { fields: [] }
      const parsedFields =
        fieldsConfig.fields?.map(cfg => parseDomainFieldChanges(change, cfg)) || []
      return {
        change,
        fieldsChanges: parsedFields.filter(conf => conf.changeType !== 'none'),
        title: fieldsConfig.title,
        renderAll: fieldsConfig.renderAll,
      }
    })
    .filter(
      widgetProps =>
        !!widgetProps.fieldsChanges.length || !isEmpty(widgetProps.change.issues),
    )

  if (!formattedTimelineChanges?.length) {
    return (
      <Widget>
        <Placeholder>
          <Placeholder.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
            }}
          />
          <Placeholder.Title>No changes found for this employee</Placeholder.Title>
          <Placeholder.Actions>
            <TextButton onClick={onClose}>Close</TextButton>
          </Placeholder.Actions>
        </Placeholder>
      </Widget>
    )
  }

  if (groupChanges) {
    const changesByCategory = groupBy(
      formattedTimelineChanges,
      item => item.change.domain_category,
    )
    const availableCategories: {
      key: PayrollTimelineDomainCategory
      title: string
      icon: IconName
    }[] = [
      { key: 'work_details', title: 'Work Details', icon: 'Services' },
      { key: 'contract', title: 'Contract', icon: 'Document' },
      { key: 'personal_details', title: 'Personal Details', icon: 'NationalId' },
      { key: 'time_off', title: 'Time Off', icon: 'CalendarDate' },
    ]

    if (!selectedCategory) {
      return (
        <Group>
          {availableCategories.map(category => {
            const changes = changesByCategory[category.key] || []
            const hasChanges = !!changes.length
            const description = getChangesDescription(changes.map(({ change }) => change))

            return (
              <Item
                key={category.key}
                use="button"
                disabled={!hasChanges}
                onClick={() => setSelectedCategory(category.key)}
              >
                <Item.Avatar>
                  <Avatar useIcon={category.icon} />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{category.title}</Item.Title>
                  <Item.Description>{description}</Item.Description>
                </Item.Content>
                {hasChanges && (
                  <Item.Side>
                    <IconButton useIcon="ChevronRight" color={Token.color.greyTone50} />
                  </Item.Side>
                )}
              </Item>
            )
          })}
        </Group>
      )
    }
    const selectedChanges = changesByCategory[selectedCategory] || []
    const selectedSortedChanges =
      [...selectedChanges].sort((a, b) =>
        isBefore(
          new Date(a.change.effective_date_time),
          new Date(b.change.effective_date_time),
        )
          ? 1
          : -1,
      ) || []
    const selectedCategoryProps = availableCategories.find(
      category => category.key === selectedCategory,
    )
    if (!selectedSortedChanges.length || !selectedCategoryProps) {
      return null
    }
    const description = getChangesDescription(
      selectedSortedChanges.map(({ change }) => change),
    )
    const filteredChanges = issueLevelFilter.length
      ? selectedSortedChanges.filter(
          ({ change }) =>
            !!change.issues_level.id &&
            issueLevelFilter.some(filter => filter.id === change.issues_level.id),
        )
      : selectedSortedChanges

    return (
      <VStack space="s-16">
        <Flex justifyContent="space-between">
          <TextButton
            onClick={() => {
              setSelectedCategory(undefined)
              setIssueLevelFitler([])
            }}
          >
            <HStack space="s-4" align="center">
              <Icon name="ArrowThinLeft" size={12} color={Token.color.blue} />
              <Text>All categories</Text>
            </HStack>
          </TextButton>
          <IssueLevelFilter value={issueLevelFilter} setValue={setIssueLevelFitler} />
        </Flex>
        {filteredChanges.length ? (
          <>
            <Item>
              <Item.Avatar>
                <Avatar useIcon={selectedCategoryProps.icon} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{selectedCategoryProps.title}</Item.Title>
                <Item.Description>{description}</Item.Description>
              </Item.Content>
            </Item>
            {filteredChanges.map(changeData => {
              const { change } = changeData
              return <DomainChangesWidget key={change.id} data={changeData} />
            })}
          </>
        ) : (
          <Widget>
            <Placeholder>
              <Placeholder.Image
                image={{
                  default: 'https://assets.revolut.com/assets/3d-images-v2/3D259.png',
                  '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
                  '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
                }}
              />
              <Placeholder.Title>No changes found</Placeholder.Title>
              {issueLevelFilter.length ? (
                <Placeholder.Actions>
                  <TextButton onClick={() => setIssueLevelFitler([])}>
                    Clear filters
                  </TextButton>
                </Placeholder.Actions>
              ) : null}
            </Placeholder>
          </Widget>
        )}
      </VStack>
    )
  }

  return (
    <VStack space="s-20">
      {formattedTimelineChanges?.map(data => {
        return <DomainChangesWidget key={data.change.id} data={data} />
      })}
    </VStack>
  )
}
