import React, { PropsWithChildren } from 'react'
import UserWithAvatar, {
  UserWithAvatarProps,
} from '@src/components/UserWithAvatar/UserWithAvatar'
import { FilterButton } from '@revolut/ui-kit'

type EmployeeFilterProps = {
  active: boolean
  userWithAvatarProps: UserWithAvatarProps
  onClick: () => void
}

export const EmployeeFilter = ({
  active,
  userWithAvatarProps,
  children,
  onClick,
}: PropsWithChildren<EmployeeFilterProps>) => {
  return (
    <FilterButton
      pl="s-4"
      useIcon={
        <UserWithAvatar pr="s-8" size={32} compact asText {...userWithAvatarProps} />
      }
      active={active}
      onClick={onClick}
    >
      {children}
    </FilterButton>
  )
}
