import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PerfTeamPlansTable } from '@src/features/GrowthPlans/PerfTeamPlans/PerfTeamPlansTable'

export const GrowthPlans = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Manage growth plans"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.GENERAL)}
      />
      <PerfTeamPlansTable />
    </PageWrapper>
  )
}
