import isEmpty from 'lodash/isEmpty'

export const getFieldSetter =
  <T extends object>({
    values,
    overrideNonEmpty,
  }: {
    values: T
    overrideNonEmpty?: boolean
  }) =>
  <K extends keyof T>(key: K, defaultValue: T[typeof key]) => {
    if ((values && values[key] == null) || overrideNonEmpty) {
      values[key] = defaultValue
    }
  }

export const getCopyDefaultField =
  <T extends object>(to: T, from: T | undefined) =>
  <K extends keyof T>(key: K) => {
    if (!from || !from[key] == null) {
      return
    }
    if (to && (to[key] == null || isEmpty(to[key]))) {
      to[key] = from[key]
    }
  }
