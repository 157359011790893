import { IdAndName } from '@src/interfaces'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'

export const employeeOnboardingPlaceholderNameColumn: ColumnInterface<IdAndName> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Placeholder',
}
