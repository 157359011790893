import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import React, { PropsWithChildren } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'

export const InnerRoute = ({
  title,
  description,
  children,
  backPath = ROUTES.PERFORMANCE.SCORECARD_PREVIEW,
}: PropsWithChildren<{ title: string; description?: string; backPath?: string }>) => {
  return (
    <PageWrapper>
      <PageHeader title={title} subtitle={description} backUrl={pathToUrl(backPath)} />
      <PageBody>{children}</PageBody>
    </PageWrapper>
  )
}
