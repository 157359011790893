import React from 'react'
import { Button, Header, Popup } from '@revolut/ui-kit'
import TimelineContent from '@src/pages/Forms/Candidate/Timeline/TimelineContent'

type Props = {
  onClose: () => void
  roundId?: number
}

export const CVScreeningTimeline = ({ onClose, roundId }: Props) => {
  return (
    <Popup size="md" open onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Timeline</Header.Title>
      </Header>

      <TimelineContent roundId={roundId} />

      <Popup.Actions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
