import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { rolesConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { organisationSettings, useGetCompanyPreferences } from '@src/api/settings'
import { RolesIntro } from '@src/pages/OnboardingChecklist/Roles/RolesIntro'
import RolesApprovals from '@src/pages/OnboardingChecklist/Roles/RolesApprovals'
import RolesSettings from '@src/pages/OnboardingChecklist/Roles/RolesSettings'
import RolesFunctions from '@src/pages/OnboardingChecklist/Roles/RolesFunctions'
import RolesRoles from '@src/pages/OnboardingChecklist/Roles/RolesRoles'
import RolesSpecialisations from '@src/pages/OnboardingChecklist/Roles/RolesSpecialisations'
import PageLoading from '@src/components/PageLoading/PageLoading'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.INTRO,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesIntro,
  },
  {
    title: 'Approval process',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.APPROVALS,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.APPROVALS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesApprovals,
    form: {
      api: organisationSettings,
    },
  },
  {
    title: 'Settings',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.SETTINGS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesSettings,
    hideActions: true,
    hidePageBody: true,
  },
  {
    title: 'Review Functions',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.FUNCTIONS,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.FUNCTIONS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesFunctions,
    isWide: true,
  },
  {
    title: 'Review Roles',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.ROLES,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.ROLES,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesRoles,
    isWide: true,
  },
  {
    title: 'Review Specialisations',
    path: ROUTES.ONBOARDING_CHECKLIST.ROLES.SPECIALISATIONS,
    url: ROUTES.ONBOARDING_CHECKLIST.ROLES.SPECIALISATIONS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: RolesSpecialisations,
    isWide: true,
  },
]

const Roles = () => {
  const { data } = useGetCompanyPreferences()

  if (!data) {
    return <PageLoading />
  }

  const filteredRoutes = routes.filter(route =>
    route.path === ROUTES.ONBOARDING_CHECKLIST.ROLES.FUNCTIONS
      ? data.enable_functions
      : route.path === ROUTES.ONBOARDING_CHECKLIST.ROLES.SPECIALISATIONS
      ? data.enable_multi_specialisations
      : true,
  )

  return <OnboardingChecklistContent config={rolesConfig} routes={filteredRoutes} />
}

export default Roles
