import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { Statuses } from '@src/interfaces'
import { successNotification } from '@src/actions/NotificationActions'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { MoreBar } from '@revolut/ui-kit'
import { handleError } from '@src/api'

type Props = {
  isVisible?: boolean
}

const CloseRequisitionButton = ({ isVisible }: Props) => {
  const [confirmDeleteOpen, updateConfirmDeleteOpen] = useState(false)
  const { initialValues, values, submit } = useLapeContext<RequisitionInterface>()
  const [submitting, setSubmitting] = useState(false)

  const close = () => {
    setSubmitting(true)
    const fallbackStatus = values.status
    values.status = Statuses.closed
    submit()
      .then(() => {
        successNotification('Requisition closed')
      })
      .catch(e => {
        values.status = fallbackStatus
        handleError(e)
      })
      .finally(() => {
        setSubmitting(false)
        updateConfirmDeleteOpen(false)
      })
  }

  const closeConfirmationDialog = () => {
    updateConfirmDeleteOpen(false)
  }

  if (initialValues.status !== Statuses.opened || !isVisible) {
    return null
  }

  return (
    <>
      <ConfirmationDialog
        open={confirmDeleteOpen}
        onClose={closeConfirmationDialog}
        onConfirm={close}
        loading={submitting}
        onReject={closeConfirmationDialog}
        label="Close Requisition"
        body="Are you sure you want to close this requisition?"
      />
      <MoreBar.Action useIcon="Key" onClick={() => updateConfirmDeleteOpen(true)}>
        Close requisition
      </MoreBar.Action>
    </>
  )
}

export default CloseRequisitionButton
