import React, { useEffect } from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { getCandidate } from '@src/api/recruitment/interviews'
import { useParams } from 'react-router-dom'
import {
  candidateEmailsRequests,
  getPrefilledPlaceholdersEmail,
  useGetCandidateAllDocuments,
} from '@src/api/hiringProcess'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'

const PERSONAL_DATA_TEMPLATE_NAME = 'Share personal data'

const CandidateData = () => {
  const { candidateId } = useParams<{ candidateId: string }>()
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { data: attachments } = useGetCandidateAllDocuments(candidateId)
  const { data: templates } = useGetSelectors(selectorKeys.email_templates_active)

  useEffect(() => {
    if (attachments) {
      values.attachments = attachments
    }
  }, [attachments])

  // @TODO: refactor duplication, align all flows (page, popup, sidebar) to a single one
  const prefillEmail = async (template?: OptionInterface) => {
    if (template?.id) {
      const resp = await fetchEmail(+template.id)
      values.email_body = resp.data.email_body
      values.sender_type = resp.data.sender_type
      values.recipients_cc = resp.data.recipients_cc
      values.recipients_bcc = resp.data.recipients_bcc
      values.subject = resp.data.subject
      values.attachments = resp.data.attachments
    }
  }

  useEffect(() => {
    const template = templates?.find(t => t.name === PERSONAL_DATA_TEMPLATE_NAME)
    if (!template) {
      return
    }
    values.email_template = template
    prefillEmail(template)
  }, [templates])

  const onSuccess = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId }))
  }

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, +candidateId)
  }

  return (
    <SendEmailCommon onSuccess={onSuccess} fetchEmail={fetchEmail}>
      <FormPreview<CandidateInterface> api={() => getCandidate(candidateId)}>
        <Group>
          <FormPreview.Item title="Candidate" field="full_name" />
          <FormPreview.Item title="Candidate email" field="email" />
        </Group>
      </FormPreview>
    </SendEmailCommon>
  )
}

export default connect(() => (
  <Form api={candidateEmailsRequests} disableLocalStorageCaching>
    <CandidateData />
  </Form>
))
