import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillSettingsInterface } from '@src/interfaces/settings'
import React from 'react'
import SectionTitle from '../components/SectionTitle'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { RecommendedLabel } from '../components/Recommended'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { Spacer } from '@revolut/ui-kit'

export const SkillsApproval = () => {
  const { values } = useLapeContext<SkillSettingsInterface>()
  return (
    <>
      <SectionTitle
        title="Will newly defined skills require approval?"
        subtitle="If enabled, the functional owner will be asked to review and approve when skills are created or edited."
      />
      <ApprovalSteps<SkillSettingsInterface>
        title="Enable Skills approval"
        description="Ask for approval whenever a skill is created or changed."
        switchField="skills_approvals_enabled"
        approvalProcess="skills_approvals"
        entity="skill"
        formRef={values}
        switchEl={
          <>
            <LapeBooleanRadioSwitch
              noLabel={<RecommendedLabel label="No" />}
              highlightSelected
              variant="horizontal"
              name="skills_approvals_enabled"
              yesDescription="The function owner should approve skills that are edited or created"
              noDescription="The system should not request approvals for skills after edits or creation"
            />
            {values.skills_approvals_enabled ? <Spacer height="s-16" /> : null}
          </>
        }
      />
    </>
  )
}
