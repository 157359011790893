import { FilterByInterface } from '@src/interfaces/data'

export const filterChangeHandler = (filterBy: FilterByInterface[]) => {
  return filterBy.reduce(
    (obj, tableFilter) => ({
      ...obj,
      [tableFilter.columnName]: tableFilter.filters.map(f => f.id),
    }),
    {},
  )
}
