import React, {
  Suspense,
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react'
import { useTable } from '@components/TableV2/hooks'
import { getQueryRunResult, queryRun } from '@src/api/dataAnalytics'
import { ChartContent } from '@src/pages/Forms/QueryForm/components/VisualisationResult'
import {
  Box,
  Skeleton,
  Text,
  Ellipsis,
  Flex,
  StatusWidget,
  Token,
  Icon,
} from '@revolut/ui-kit'
import { getQueryRunData, isQueryRunning } from '@src/pages/Forms/QueryForm/utils'
import { RunQueryResponseInterface, QueryInterface } from '@src/interfaces/dataAnalytics'
import Loader from '@components/CommonSC/Loader'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import {
  getFilters,
  orderingToSort,
  getTableRows,
} from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'
import { MetricsItemTooltip } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItemTooltip'
import {
  useGetDashboardQueryRunWithInterval,
  useGetDashboardQuery,
} from '@src/api/analyticsDashboards'
import { MetricsItemParametersList } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsItemParametersList'
import { QueryContext } from '@src/pages/Forms/QueryForm/QueryContextProvider'
import { TableTypes } from '@src/interfaces/table'

interface MetricsItemProps {
  queryId: string
  dashboardId?: string | number
}

interface MetricsItemVisualisationProps {
  query: QueryInterface
  queryRunId: number
  dashboardId?: string | number
}

interface MetricsItemTitleWrapperProps {
  children: React.ReactNode
  query: QueryInterface
}

const MetricsItemVisualisation = ({
  query,
  queryRunId,
  dashboardId,
}: MetricsItemVisualisationProps) => {
  const table = useTable({
    getItems: getQueryRunResult(queryRunId, query.id, undefined, dashboardId),
  })
  if (table.loading) {
    return <Loader />
  }

  return (
    <Suspense fallback={<Skeleton />}>
      <ChartContent
        chartType={query.visualisation.chartType || 'line'}
        chartSettings={query.visualisation}
        data={table.data}
        tableColumns={query.output_format?.columns || []}
      />
    </Suspense>
  )
}

const MetricsItemTable = ({
  query,
  queryRunId,
  dashboardId,
}: MetricsItemVisualisationProps) => {
  const initialFilterBy = useMemo(
    () =>
      query.output_format?.columns
        ? getFilters(query.output_format.columns, query.output_format?.filters || [])
        : undefined,
    [],
  )

  const initialSort = query.output_format?.ordering
    ? orderingToSort(query.output_format?.ordering)
    : undefined

  const table = useTable(
    {
      getItems: getQueryRunResult(queryRunId, query.id, undefined, dashboardId),
    },
    initialFilterBy,
    initialSort,
    { disableQuery: true },
  )

  const tableRows = useMemo(() => {
    return getTableRows(
      query.id,
      query.output_format?.columns!,
      query.output_format?.filters,
      query.output_format?.highlighting,
      queryRunId,
    )
  }, [])
  if (table.loading) {
    return <Loader />
  }

  return (
    <Box overflow="auto" height="100%" width="100%">
      <AdjustableTable
        hideCount
        name={TableNames.ReportingAppQueriesRunResult}
        type={TableTypes.Contained}
        noDataMessage="No data to display"
        row={tableRows}
        {...table}
      />
    </Box>
  )
}

const MetricsItemTitleWrapper = ({ children, query }: MetricsItemTitleWrapperProps) => {
  if (query.visualisation?.chartType === 'counter') {
    return (
      <Box height="100%">
        <Flex justifyContent="end">
          <MetricsItemTooltip description={query.description} />
        </Flex>
        {children}
      </Box>
    )
  }

  return (
    <Box height="100%" width="100%">
      <Flex alignItems="center" pb="s-8">
        <Ellipsis style={{ flexGrow: 1 }}>
          <Text variant="tile" use="div" textAlign="center">
            {query.name}
          </Text>
        </Ellipsis>
        <MetricsItemTooltip description={query.description} />
      </Flex>
      <Box height="calc(100% - 32px)" width="100%">
        {children}
      </Box>
    </Box>
  )
}

export const MetricsItem = ({ dashboardId, queryId }: MetricsItemProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [queryData, setQueryData] = useState<RunQueryResponseInterface>()
  const { data: query, isError, error } = useGetDashboardQuery(queryId, dashboardId)
  const { data: queryRunResult } = useGetDashboardQueryRunWithInterval(
    dashboardId,
    queryId,
    queryData?.id,
  )
  const { queryRunParameters: queryRunParametersContext, setQueryRunParameters } =
    useContext(QueryContext)
  const [debouncedValue, setDebouncedValue] = useState(queryRunParametersContext)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(queryRunParametersContext)
    }, 700)

    return () => {
      clearTimeout(handler)
    }
  }, [queryRunParametersContext])

  useEffect(() => {
    if (!query || isLoading) {
      return
    }
    if (!query.parameters.length) {
      handleQueryRun()
    } else {
      query.parameters.map(p =>
        setQueryRunParameters(prevValue => ({ ...prevValue, [p.name]: p.default })),
      )
    }
  }, [query])

  useEffect(() => {
    const hasNulls =
      query?.parameters.length &&
      (!Object.keys(debouncedValue).length ||
        Object.keys(debouncedValue).some(key => debouncedValue[key] === null))
    if (!hasNulls) {
      handleQueryRun()
    } else {
      setQueryData(undefined)
    }
  }, [debouncedValue])

  const handleQueryRun = async () => {
    if (!query) {
      return
    }

    setIsLoading(true)

    const queryRunParameters = query.parameters?.reduce(
      (o, key) =>
        Object.assign(o, {
          [key.name]: queryRunParametersContext[key.name] || key.default || null,
        }),
      {},
    )
    const queryRunData = getQueryRunData(query, queryRunParameters)
    const queryResponse = await queryRun(query.id, queryRunData, dashboardId)

    setQueryData(queryResponse.data)
  }

  const isPermissionDenied = error?.response?.status === 403
  const hasParams =
    !!query?.parameters.length && query.parameters.some(p => p.name !== '')

  const ErrorView = () => (
    <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
      <StatusWidget>
        <StatusWidget.Image
          src={
            isPermissionDenied
              ? 'https://assets.revolut.com/assets/3d-images-v2/3D020.png'
              : 'https://assets.revolut.com/assets/3d-images-v2/3D018.png'
          }
        />
        <StatusWidget.Title>
          {isPermissionDenied
            ? 'You do not have permission to view it'
            : 'Failed to load'}
        </StatusWidget.Title>
      </StatusWidget>
    </Flex>
  )

  const NoDataToDisplay = () => (
    <Flex
      alignItems="center"
      flex="1 0"
      flexDirection="column"
      justifyContent="center"
      height="100%"
    >
      <Icon name="BarChart" color={Token.color.greyTone20} />
      <Text color={Token.color.greyTone20}>No data to display</Text>
    </Flex>
  )

  const QueryResult = () =>
    query && queryRunResult ? (
      query.visualisation_type === 'table' ? (
        <MetricsItemTable
          query={query}
          queryRunId={queryRunResult.id}
          dashboardId={dashboardId}
        />
      ) : (
        <Flex style={{ flexGrow: 1 }}>
          <MetricsItemVisualisation
            query={query}
            queryRunId={queryRunResult.id}
            dashboardId={dashboardId}
          />
        </Flex>
      )
    ) : (
      <NoDataToDisplay />
    )

  const QueryView = useCallback(() => {
    return (
      <Box height="100%">
        <MetricsItemTitleWrapper query={query!}>
          <Flex flexDirection="column" height="100%">
            {hasParams && <MetricsItemParametersList parameters={query!.parameters} />}
            {!query || isQueryRunning(queryRunResult?.status) ? (
              <Loader />
            ) : (
              <QueryResult />
            )}
          </Flex>
        </MetricsItemTitleWrapper>
      </Box>
    )
  }, [queryRunResult?.status, query])

  const shouldShowLoader =
    !query ||
    (!hasParams &&
      (!queryRunResult?.id ||
        !queryRunResult?.status ||
        isQueryRunning(queryRunResult?.status)))

  return (
    <Box height="100%" overflow={'hidden'}>
      {shouldShowLoader ? <Loader /> : isError ? <ErrorView /> : <QueryView />}
    </Box>
  )
}
