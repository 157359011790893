import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Hiearchy } from './Hierarchy'

export const EmployeesOrgChart = () => {
  return (
    <PageWrapper>
      <PageHeader backUrl={ROUTES.PEOPLE.EMPLOYEES} title="Org Chart" />
      <PageBody maxWidth="100%">
        <Hiearchy />
      </PageBody>
    </PageWrapper>
  )
}
