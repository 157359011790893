import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { APPLICATIONS } from '@src/constants/hub'
import HubApp from '@src/features/HubApp/HubApp'
import { ROUTES } from '@src/constants/routes'
import { HelpCenterQuestion } from './Question'
import { HelpCenterTopic } from './Topic'
import { QuestionsBulkUpload } from './QuestionsBulkUpload'
import { SDCategory } from './SDCategory'

const HelpCenter = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.HELP_CENTER.QUESTION.ANY}>
        <HelpCenterQuestion />
      </Route>
      <Route path={ROUTES.APPS.HELP_CENTER.TOPIC.ANY}>
        <HelpCenterTopic />
      </Route>
      <Route path={ROUTES.APPS.HELP_CENTER.SD_CATEGORY.ANY}>
        <SDCategory />
      </Route>
      <Route path={ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.ANY}>
        <QuestionsBulkUpload />
      </Route>
      <Route path={ROUTES.APPS.HELP_CENTER.ANY}>
        <HubApp app={APPLICATIONS.helpCenter} />
      </Route>
    </Switch>
  )
}

export default HelpCenter
