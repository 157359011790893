import React from 'react'
import styled from 'styled-components'

const List = styled.ul`
  font-weight: 700;
  margin-left: 20px;
  padding: 16px 0;
`

const ListItem = styled.li``

type Item = {
  msg: React.ReactNode
}

type Props = {
  items: Item[]
}

export const OnboardingIntroList = ({ items }: Props) => {
  return (
    <List>
      {items.map((item, i) => (
        <ListItem key={i}>{item.msg}</ListItem>
      ))}
    </List>
  )
}
