import React, { useState } from 'react'
import {
  BottomSheet,
  Button,
  Input,
  Header,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { bulkEditTimeOffPolicies } from '@src/api/timeOff'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export type BulkUpdateField = 'category' | 'group' | 'accrual_amount'

interface BulkUpdateFieldPopupProps {
  field: BulkUpdateField
  selectedItems: number[]
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

const getTitleFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return 'Change category'
    case 'group':
      return 'Change group'
    case 'accrual_amount':
      return 'Change balance'
    default:
      return ''
  }
}

const getLabelFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return 'Category'
    case 'group':
      return 'Group'
    case 'accrual_amount':
      return 'Balance'
    default:
      return ''
  }
}

const getSelectorFromField = (field: BulkUpdateField) => {
  switch (field) {
    case 'category':
      return selectorKeys.time_off_policy_categories
    case 'group':
      return selectorKeys.dynamic_groups
    default:
      return null
  }
}

export const BulkUpdateFieldPopup = ({
  open,
  onClose,
  onSuccess,
  field,
  selectedItems,
}: BulkUpdateFieldPopupProps) => {
  const selector = getSelectorFromField(field)
  const statusPopup = useStatusPopup()
  const [pending, setPending] = useState(false)
  const [selectorValue, setSelectorValue] = useState<IdAndName | null>(null)
  const [value, setValue] = useState<string>()

  const onSubmit = () => {
    setPending(true)

    let payload

    if (field === 'accrual_amount' && value) {
      payload = {
        [field]: value,
      }
    } else if (selectorValue) {
      payload = { [field]: selectorValue.id }
    }

    if (!payload) {
      return
    }

    bulkEditTimeOffPolicies(selectedItems, payload)
      .then(() => {
        setPending(false)
        setSelectorValue(null)
        setValue(undefined)
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>{getTitleFromField(field)}</Header.Title>
      </Header>
      {field === 'accrual_amount' ? (
        <Input
          label={getLabelFromField(field)}
          onChange={event => setValue(event.currentTarget.value)}
          value={value}
        />
      ) : (
        <RadioSelectInput
          label={getLabelFromField(field)}
          onChange={option => {
            setSelectorValue(option)
          }}
          selector={selector}
          value={selectorValue}
        />
      )}
      <BottomSheet.Actions>
        <Button
          disabled={field === 'accrual_amount' ? !value : !selectorValue}
          elevated
          onClick={onSubmit}
          pending={pending}
        >
          Update
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
