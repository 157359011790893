import { TextArea, Token } from '@revolut/ui-kit'
import React, { useState, useRef } from 'react'
import styled from 'styled-components'

interface Props {
  value?: string
  onChange: (value: string) => void
  disabled?: boolean
  placeholder?: string
  rows?: number
}

const BULLET = '•'
const AFTER_BULLET_SPACE = 2

const StyledTextArea = styled(TextArea)`
  & > div {
    background-color: transparent !important;
    padding: 10px 16px 0 16px;

    :hover,
    :focus,
    :active,
    :focus-within {
      background-color: transparent !important;
    }

    textarea:disabled {
      color: ${Token.color.foreground};
      -webkit-text-fill-color: ${Token.color.foreground};
    }
  }
`

export const BulletPointTextArea = ({
  value = '',
  onChange,
  disabled,
  placeholder,
  rows,
}: Props) => {
  const [displayText, setDisplayText] = useState(value)
  const [history, setHistory] = useState<string[]>([value])
  const textAreaRef = useRef<HTMLTextAreaElement>(null)

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDisplayText(e.target.value)
    setHistory(prevHistory => [...prevHistory, e.target.value])
    onChange(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const { selectionStart, selectionEnd } = e.currentTarget

    if ((e.metaKey && e.key === 'z') || (e.ctrlKey && e.key === 'z')) {
      e.preventDefault()

      setHistory(prevHistory => {
        if (prevHistory.length > 1) {
          const newHistory = prevHistory.slice(0, -1)
          setDisplayText(newHistory[newHistory.length - 1] || '')
          return newHistory
        }
        return history
      })
      return
    }

    if (!displayText && e.key.length === 1 && !e.metaKey && !e.ctrlKey) {
      e.preventDefault()

      const newText = `${BULLET} ${e.key}`
      setDisplayText(newText)
      setHistory(prevHistory => [...prevHistory, newText])

      setTimeout(() => {
        if (textAreaRef.current) {
          const newCursorPosition = BULLET.length + AFTER_BULLET_SPACE
          textAreaRef.current.selectionStart = newCursorPosition
          textAreaRef.current.selectionEnd = newCursorPosition
        }
      }, 0)
    } else if (e.key === 'Enter') {
      e.preventDefault()

      if (e.shiftKey) {
        const beforeCursor = displayText.slice(0, selectionStart)
        const afterCursor = displayText.slice(selectionEnd)
        const newText = `${beforeCursor}\n${afterCursor}`
        setDisplayText(newText)
        setHistory(prevHistory => [...prevHistory, newText])

        setTimeout(() => {
          if (textAreaRef.current) {
            textAreaRef.current.selectionStart = selectionStart + 1
            textAreaRef.current.selectionEnd = selectionStart + 1
          }
        }, 0)
      } else {
        const beforeCursor = displayText.slice(0, selectionStart)
        const afterCursor = displayText.slice(selectionEnd)
        const newText = `${beforeCursor}\n${BULLET} ${afterCursor}`
        setDisplayText(newText)
        setHistory(prevHistory => [...prevHistory, newText])

        setTimeout(() => {
          if (textAreaRef.current) {
            const newCursorPosition = selectionStart + BULLET.length + AFTER_BULLET_SPACE
            textAreaRef.current.selectionStart = newCursorPosition
            textAreaRef.current.selectionEnd = newCursorPosition
          }
        }, 0)
      }
    }
  }

  return (
    <StyledTextArea
      placeholder={placeholder}
      disabled={disabled}
      ref={textAreaRef}
      rows={rows}
      cols={50}
      value={displayText}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />
  )
}
