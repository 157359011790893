import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportDocumentsItemInterface } from '@src/interfaces/importDocuments'
import {
  onboardingBulkSessionDocumentsAssigneeColumn,
  onboardingBulkSessionDocumentsCategoryColumn,
  onboardingBulkSessionDocumentsNameColumn,
  onboardingDocumentsExistingItemsAssigneeColumn,
  onboardingDocumentsExistingItemsCategoryColumn,
  onboardingDocumentsExistingItemsNameColumn,
} from '@src/constants/columns/onboardingDocumentsV2'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { DocumentListItemInterface } from '@src/interfaces/documents'
import { GenericEditableTableCreateCallbackType } from '@src/features/GenericEditableTable/components'
import { Token } from '@revolut/ui-kit'

export type CreateCallback = GenericEditableTableCreateCallbackType<'category'>

export const existingItemsRow =
  (onCreate: CreateCallback, activePreviewId: number | undefined) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<DocumentListItemInterface>> => ({
    highlight: data => (data.id === activePreviewId ? Token.color.greyTone2 : ''),
    cells: [
      {
        ...onboardingDocumentsExistingItemsNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingDocumentsExistingItemsCategoryColumn(
          options.onChange,
          onChangeAction => onCreate('category', onChangeAction),
        ),
        width: 200,
      },
      {
        ...onboardingDocumentsExistingItemsAssigneeColumn(options.onChange),
        width: 200,
      },
    ],
  })

export const getBulkSessionRow =
  (activePreviewId: number | undefined) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<ImportDocumentsItemInterface>> => ({
    highlight: data => (data.id === activePreviewId ? Token.color.greyTone2 : ''),
    cells: [
      {
        ...onboardingBulkSessionDocumentsNameColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingBulkSessionDocumentsCategoryColumn(options.onChange),
        width: 200,
      },
      {
        ...onboardingBulkSessionDocumentsAssigneeColumn(options.onChange),
        width: 200,
      },
    ],
  })
