import React, { useContext } from 'react'
import { Box, Button, DetailsCell, Header, Side, VStack } from '@revolut/ui-kit'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import ScheduledDetails from '@src/pages/Forms/Candidate/ScheduleSidebar/ScheduledDetails'
import { Group } from '@revolut/ui-kit'
import { useGetSchedulingInterview } from '@src/api/recruitment/interviewerScheduling'
import { HeaderSchedulingSidebarContext } from '@src/utils/extension'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'

const getStageLabel = (stage?: InterviewStageWithoutRoundInterface, length?: number) =>
  stage
    ? `${stage.title}${
        stage.duration
          ? ` · ${stage.interview_number}/${length} · ${getDuration(
              stage.duration,
              stage.duration_unit,
            )}`
          : ''
      }`
    : ''

type Props = {
  stages: InterviewStageWithoutRoundInterface[]
  onSubmit: () => void
  totalStagesCount: number
  children?: React.ReactNode
  isSubmitEnabled: boolean
  isRescheduling?: boolean
  isPrepCall?: boolean
  isEditing?: boolean
  isStageDisabled?: boolean
  roundId?: number
  loading?: boolean
  onStageChange: (stage?: InterviewStageWithoutRoundInterface) => void
}

const EditScheduleSidebar = ({
  stages,
  roundId,
  onSubmit,
  totalStagesCount,
  children,
  isSubmitEnabled,
  isRescheduling,
  isEditing,
  isStageDisabled,
  isPrepCall,
  loading,
  onStageChange,
}: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const headerContext = useContext(HeaderSchedulingSidebarContext)
  const { data: interview, isLoading: isInterviewLoading } = useGetSchedulingInterview(
    values.interview_stage?.id,
    roundId,
  )

  let title = 'Interview details'

  if (isEditing) {
    title = 'Edit interview'
  }

  if (isRescheduling) {
    title = 'Reschedule interview'
  }

  if (isPrepCall) {
    title = 'Schedule a catch-up call'
  }

  const renderContent = () => {
    if (isEditing || isRescheduling) {
      return (
        <>
          <Group>
            {!isPrepCall && (
              <DetailsCell>
                <DetailsCell.Title>Stage</DetailsCell.Title>
                <DetailsCell.Content>{values.interview_stage?.title}</DetailsCell.Content>
              </DetailsCell>
            )}
            {!isEditing && (
              <ScheduledDetails interview={interview} isLoading={isInterviewLoading} />
            )}
          </Group>
          <Box>
            {children}
            <Side.Actions>
              <Button
                elevated
                onClick={onSubmit}
                disabled={!isSubmitEnabled}
                data-testid="btn-continue"
                pending={loading}
              >
                {isEditing ? 'Save changes' : 'Continue'}
              </Button>
            </Side.Actions>
          </Box>
        </>
      )
    }

    if (!stages.length) {
      return <ActionWidget title="There are no stages to schedule the interview" />
    }

    return (
      <>
        {!isPrepCall && (
          <LapeRadioSelectInput<InterviewStageWithoutRoundInterface>
            label="Stage"
            name="interview_stage"
            options={stages.map(item => ({
              label: getStageLabel(item, totalStagesCount),
              value: {
                ...item,
                title: getStageLabel(item, totalStagesCount),
              },
            }))}
            searchable={false}
            disabled={isStageDisabled}
            onAfterChange={val => onStageChange(val || undefined)}
          />
        )}
        <Box>
          {children}
          <Side.Actions>
            <Button
              elevated
              onClick={onSubmit}
              disabled={!isSubmitEnabled}
              data-testid="btn-continue"
            >
              Continue
            </Button>
          </Side.Actions>
        </Box>
      </>
    )
  }

  return (
    <>
      <Header variant="item">
        {headerContext?.onBack ? (
          <Header.BackButton aria-label="Back" onClick={headerContext.onBack} />
        ) : (
          <Header.CloseButton aria-label="Close" />
        )}
        <Header.Title aria-label="sidebar-title">{title}</Header.Title>
        {!isEditing && !!stages.length && (
          <Header.Description>
            Confirm the details before sending the invite
          </Header.Description>
        )}
        {headerContext?.actions && (
          <Header.Actions>{headerContext?.actions}</Header.Actions>
        )}
      </Header>
      <VStack gap="s-16">{renderContent()}</VStack>
    </>
  )
}

export default EditScheduleSidebar
