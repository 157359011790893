import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
  &:hover:not(:disabled) {
    opacity: 0.7;
  }

  &:disabled {
    cursor: inherit;
  }
`

interface IconButtonInterface extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  tooltip?: string | ReactNode
}

const IconButton: React.FC<IconButtonInterface> = ({ tooltip, ...props }) => {
  return <Button type="button" {...props} />
}

export default IconButton
