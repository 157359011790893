import { Cell, Flex, Text } from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import React from 'react'

interface JustificationGuidelinesProps {
  isOpen: boolean
  onClose: () => void
}

const JustificationGuidelines = ({ isOpen, onClose }: JustificationGuidelinesProps) => (
  <SideBar title="Justification Guidelines" isOpen={isOpen} onClose={onClose}>
    <Cell>
      <Flex flexDirection="column" gap="s-8">
        <Text>
          <b>Extraordinary performance:</b> The employee has continuously outperformed her
          peers as visible in the team KPIs QoQ. While striving for better results, she
          has received several commendations from our customers indicating that she is
          able to demonstrate customer care at the intermediate level.
        </Text>
        <Text>
          <b>Flight risk:</b> The employee is a strong performer with a high likelihood of
          leaving due to being below benchmark based on prior conversations. Retention of
          employee via increasing salary is critical to ensure Q1 product deliverables due
          to their continued contributions as a valued designer within their team.
        </Text>
        <Text>
          <b>Key person risk:</b> This employee is in a critical role that if he/she
          decides to leave, it will be a significant risk to our business operation and/or
          will be extremely difficult to replace. (A note to the manager: connect with
          your HR business partners and minimise the key person risk in your team with the
          correct 4 eye planning or succession planning).
        </Text>
        <Text>
          <b>Succession planning:</b> this employee is a strong performer with the
          likelihood of succeeding my role or another high impact role in my team. Further
          incentivizing through salary increases or bonuses is essential to send this
          message across.
        </Text>
        <Text>
          <b>Diversity or ER issue:</b> Concern about diversity representation in my team
          or open employee relation issue. (Please connect with an HR business partner. DO
          NOT make notation about the specifics on this screen.)
        </Text>
      </Flex>
    </Cell>
  </SideBar>
)

export default JustificationGuidelines
