import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { QuestionsUploadFile } from './QuestionsUploadFile'
import { QuestionsBulkUploadSession } from './QuestionsBulkUploadSession'

export const QuestionsBulkUpload = () => {
  const params = useParams<{ subtab: 'upload' | 'session' }>()

  return (
    <PageWrapper>
      <PageHeader
        title="Import wizard"
        subtitle={
          params.subtab === 'upload'
            ? 'Follow our guidelines to import the data at once'
            : ''
        }
        backUrl={ROUTES.APPS.HELP_CENTER.FAQ_TABLE}
      />

      <Switch>
        <Route path={ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.UPLOAD} exact>
          <QuestionsUploadFile />
        </Route>
        <Route path={ROUTES.APPS.HELP_CENTER.FAQ_IMPORT.SESSION} exact>
          <QuestionsBulkUploadSession />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
