import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { ImportBonusesV2Interface } from '@src/interfaces/importBonuses'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportBonusesV2Column = GenericEditableTableColumn<ImportBonusesV2Interface>

export const importBonusesV2EmployeeEmailColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'employee_email',
  dataPoint: 'employee_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee Email',
  insert: ({ data }) => (
    <TextCell data={data} field="employee_email" onChange={onChange} />
  ),
})

export const importBonusesV2BonusCycleColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_cycle',
  dataPoint: 'bonus_cycle',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Cycle',
  insert: ({ data }) => <TextCell data={data} field="bonus_cycle" onChange={onChange} />,
})

export const importBonusesV2BonusCategoryColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_category',
  dataPoint: 'bonus_category',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Category',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="bonus_category"
      onChange={onChange}
      selector={selectorKeys.employee_bonus_categories}
      selectorField="name"
    />
  ),
})

export const importBonusesV2TargetColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_amount',
  dataPoint: 'bonus_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Amount',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="bonus_amount"
      onChange={onChange}
      type={TableCellInputType.positiveFloat}
    />
  ),
})

export const importBonusesV2BonusCurrencyISOCodeColumn: ImportBonusesV2Column =
  onChange => ({
    type: CellTypes.insert,
    idPoint: 'bonus_currency_iso_code',
    dataPoint: 'bonus_currency_iso_code',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.currencies,
    title: 'Bonus Currency ISO Code',
    insert: ({ data }) => (
      <RadioSelectInputCell
        data={data}
        field="bonus_currency_iso_code"
        onChange={onChange}
        selector={selectorKeys.currencies}
        selectorField="iso_code"
      />
    ),
  })

export const importBonusesV2BonusDateColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'bonus_date',
  dataPoint: 'bonus_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Bonus Date',
  insert: ({ data }) => <TextCell data={data} field="bonus_date" onChange={onChange} />,
})

export const importBonusesV2TaxabilityColumn: ImportBonusesV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'taxability',
  dataPoint: 'taxability',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.employee_bonus_taxabilities,
  title: 'Taxability',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="taxability"
      onChange={onChange}
      selector={selectorKeys.employee_bonus_taxabilities}
      selectorField="name"
    />
  ),
})
