import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CommunicationGroupInterface,
  CommunicationGroupId,
} from '@src/interfaces/communicationGroup'
import { pathToUrl } from '@src/utils/router'
import kebabCase from 'lodash/kebabCase'
import { useGetCommunicationSettings } from '@src/api/settings'

const getAudienceTypeOption = (
  audienceTypeId?: CommunicationGroupId,
): { id: CommunicationGroupId; name: string } => {
  switch (audienceTypeId) {
    case 'department':
      return { id: 'department', name: 'Department' }
    case 'dynamic_group':
      return { id: 'dynamic_group', name: 'Dynamic group' }
    case 'function':
      return { id: 'function', name: 'Function' }
    case 'team':
      return { id: 'team', name: 'Team' }
    default:
      return { id: 'dynamic_group', name: 'Dynamic group' }
  }
}

export const CommunicationGroup = () => {
  const { values } = useLapeContext<CommunicationGroupInterface>()
  const disabledForNonGroupForm =
    !!values.id && values.audience_type?.id !== 'dynamic_group'
  const { data: communicationSettings } = useGetCommunicationSettings()
  const enableSlackChannel =
    !!communicationSettings?.enable_automatic_slack_channel_and_group_creation

  return (
    <>
      <PageHeader
        title={values.id ? 'Edit communication group' : 'Create new communication group'}
        backUrl={
          values.id
            ? pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP, { id: values.id })
            : ROUTES.PEOPLE.EMPLOYEES
        }
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Details" />
          <InputGroup>
            <LapeNewInput
              required
              disabled={disabledForNonGroupForm}
              name="name"
              label="Group name"
              message="Your group name will be used to auto generate the different channel names. The channel names can be changed if needed."
              onAfterChange={value => {
                if (!values.id) {
                  if (enableSlackChannel) {
                    values.slack_channel_name = value ? `#${kebabCase(value)}` : ''
                  }
                  values.email_name = value ? `${kebabCase(value)}@revolut.com` : ''
                  values.news_feed_name = value || ''
                }
              }}
            />
            <LapeRadioSelectInput
              required
              disabled={disabledForNonGroupForm}
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
            <LapeNewTextArea name="description" label="Description" rows={2} />
          </InputGroup>

          <NewStepperTitle title="Audience" />
          <InputGroup>
            <LapeRadioSelectInput
              required
              disabled
              name="audience_type"
              label="Type"
              message="Communication groups are managed automatically for teams, departments and functions. You can create custom groups and define their audience using dynamic groups."
              selector={selectorKeys.communication_group_audience_types}
              value={getAudienceTypeOption(values.audience_type?.id)}
            />
            {!disabledForNonGroupForm && (
              <LapeNewMultiSelect
                required
                disabled={disabledForNonGroupForm}
                name="dynamic_groups"
                placeholder="Dynamic groups"
                selector={selectorKeys.dynamic_groups}
              />
            )}
          </InputGroup>

          {enableSlackChannel && (
            <>
              <NewStepperTitle title="Channels" />
              <InputGroup>
                <LapeNewInput
                  required
                  disabled={disabledForNonGroupForm}
                  name="slack_channel_name"
                  label="Slack channel name"
                  message="New members will automatically be added to the slack channel"
                />
                {/* TODO: uncomment when backend API is ready
            <LapeNewInput
              required
              disabled
              name="email_name"
              placeholder="Email group name"
              message="New members will automatically be added to the email group"
            />
            <LapeNewInput
              required
              disabled
              name="news_feed_name"
              placeholder="News feed"
              message="New members will automatically be added to the news feed"
            /> */}
              </InputGroup>
            </>
          )}

          {/* TODO: uncomment when backend API is ready
          <NewStepperTitle title="Settings" />
          <Group>
            <Cell>
              <LapeNewSwitch isMedia disabled name="private_channels">
                <Text variant="h6" color={Color.GREY_TONE_50}>
                  Make slack channel and news feed private (users will need to request to
                  join)
                </Text>
              </LapeNewSwitch>
            </Cell>
            <Cell>
              <LapeNewSwitch isMedia disabled name="join_manually">
                <Text variant="h6" color={Color.GREY_TONE_50}>
                  Allow people to join manually
                </Text>
              </LapeNewSwitch>
            </Cell>
          </Group> */}
        </AutoStepper>
        <PageActions mt="s-32">
          <NewSaveButtonWithPopup
            useValidator
            successText={
              values.id
                ? 'Communication group was successfully updated'
                : 'Communication group was successfully created'
            }
            previewUrl={ROUTES.FORMS.COMMUNICATION_GROUPS.VIEW.GROUP}
          >
            Submit
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageBody>
    </>
  )
}
