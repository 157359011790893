import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionSettingsInterface } from '@src/interfaces/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { VStack } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { ApprovalsMessage } from '@src/pages/OnboardingChecklist/components/Message'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const RequisitionsApprovals = () => {
  const { values } = useLapeContext<RequisitionSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeRequisitionPreferences)
  return (
    <VStack gap="s-16">
      <SectionTitle title="Do you require approval to create a requisition?" />
      <LapeBooleanRadioSwitch
        name="enable_approvals"
        disabled={disableEdit}
        yesLabel="Yes, enable approvals for requisitions"
        yesDescription="Ensure oversight and control by defining approval steps. Select an employee, a dynamic group, or specific position within an organisation with defined relationship to the requisition pending approval."
        noLabel="No, open requisitions immediately upon creation"
        noDescription="Streamline the process by skipping the approvals and creating the requisitions in the “Opened” status automatically"
      />
      {values.enable_approvals && <ApprovalsMessage />}
      <ApprovalSteps
        switchField="enable_approvals"
        hideSwitch
        approvalProcess="requisition_approvals"
        entity="requisition"
        disabled={disableEdit}
        formRef={values}
      />
    </VStack>
  )
}

export default RequisitionsApprovals
