import React, { useEffect } from 'react'
import get from 'lodash/get'
import set from 'lodash/set'
import { SeniorityInterface } from '@src/interfaces/seniority'
import useSenioritySelector from '@src/features/SenioritySelector/useSenioritySelector'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput, {
  LapeRadioSelectInputProps,
} from '@components/Inputs/LapeFields/LapeRadioSelectInput'

export interface LapeSenioritySelectorProps
  extends LapeRadioSelectInputProps<SeniorityInterface> {
  specialisationId: number | string | null
}

const LapeSenioritySelector = ({
  specialisationId,
  ...props
}: LapeSenioritySelectorProps) => {
  const form = useLapeContext()

  const { isSeniorityValid, autocompleteProps } = useSenioritySelector(
    specialisationId,
    get(form.values, props.name),
  )

  useEffect(() => {
    if (!isSeniorityValid) {
      set(form.values, props.name, undefined)
    }
  }, [isSeniorityValid])

  return <LapeRadioSelectInput {...autocompleteProps} {...props} />
}

export default LapeSenioritySelector
