import { useEffect, useState } from 'react'

import { useEmployeeFields } from '@src/api/employees'
import { useGetEmployeeSettings } from '@src/api/settings'
import { EmployeeInterface, InternalOrExternalEmployee } from '@src/interfaces/employees'
import { DataHandlerInterface } from '@components/FormPreview/FormPreview'
import {
  EmployeeProfileSectionKey,
  getProfileSectionFields,
  getSectionPermissions,
  SectionInterface,
  SectionPermissions,
} from './common'

export type SectionData<T> = {
  data: T | undefined
  permissions?: SectionPermissions
  handler: DataHandlerInterface<T>
}

type UseEmployeeProfileDataParams = {
  legacyForm?: boolean
  employeeId: string | number | undefined
  instantLoad?: {
    name?: boolean
    position?: boolean
    organisation?: boolean
  }
}
export type ProfileSectionsData<T extends SectionInterface = InternalOrExternalEmployee> =
  Record<EmployeeProfileSectionKey, SectionData<T>>
export type UseEmployeeProfileDataReturnType<
  T extends SectionInterface = InternalOrExternalEmployee,
> = {
  data: EmployeeInterface | undefined
  setData: (d: EmployeeInterface) => void
  handler: DataHandlerInterface<T>
  sections: ProfileSectionsData<T>
}

export const useEmployeeProfileData = <T extends EmployeeInterface>({
  legacyForm,
  employeeId,
  instantLoad,
}: UseEmployeeProfileDataParams): UseEmployeeProfileDataReturnType<T> => {
  const [data, setData] = useState<EmployeeInterface>() // TODO: <InternalOrExternalEmployee>
  const isExternal = data?.employee_type === 'external'

  const [loadPrimaryData, setLoadPrimaryData] = useState(!legacyForm)
  const [loadNameData, setLoadNameData] = useState<boolean>(!!instantLoad?.name)
  const [loadPositionData, setLoadPositionData] = useState<boolean>(
    !!instantLoad?.position,
  )
  const [loadOrganisationData, setLoadOrganisationData] = useState<boolean>(
    !!instantLoad?.organisation,
  )
  const [loadNotesData, setLoadNotesData] = useState(false)

  const canLoadPrimaryData = legacyForm ? false : loadPrimaryData

  const {
    data: primaryData,
    refetch: refetchPrimaryData,
    isRefetching: isPrimaryDataRefetching,
    status: primaryDataStatus,
  } = useEmployeeFields<T>(canLoadPrimaryData ? employeeId : undefined)

  useEffect(() => {
    setData({ ...data, ...primaryData } as EmployeeInterface)
  }, [primaryData])

  useEffect(() => {
    setData(undefined)
    refetchPrimaryData().then(res => {
      setData(res.data)
    })
  }, [employeeId])

  const canLoadSectionsData = legacyForm
    ? true
    : !!data && String(data.id) === String(employeeId)

  const canLoadName = canLoadSectionsData && loadNameData
  const canLoadPosition = canLoadSectionsData && loadPositionData
  const canLoadOrganisation = canLoadSectionsData && loadOrganisationData

  const {
    data: nameData,
    refetch: refetchNameData,
    status: nameDataStatus,
    isRefetching: isNameDataRefetching,
  } = useEmployeeFields<T>(
    canLoadName ? employeeId : undefined,
    getProfileSectionFields('name'),
    isExternal,
  )

  const {
    data: positionData,
    refetch: refetchPositionData,
    isRefetching: isPositionDataRefetching,
    status: positionDataStatus,
  } = useEmployeeFields<T>(
    canLoadPosition ? employeeId : undefined,
    getProfileSectionFields('position'),
    isExternal,
  )

  const {
    data: organisationData,
    refetch: refetchOrganisationData,
    isRefetching: isOrganisationDataRefetching,
    status: organisationDataStatus,
  } = useEmployeeFields<T>(
    canLoadOrganisation ? employeeId : undefined,
    getProfileSectionFields('organisation'),
    isExternal,
  )

  const {
    data: notesData,
    refetch: refetchNotesData,
    isRefetching: isNotesDataRefetching,
    status: notesDataStatus,
  } = useEmployeeFields<T>(
    loadNotesData ? employeeId : undefined,
    getProfileSectionFields('notes'),
    isExternal,
  )

  const { data: settings } = useGetEmployeeSettings()

  return {
    data,
    setData,
    handler: {
      load: () => setLoadPrimaryData(true),
      refetch: refetchPrimaryData,
      isRefetching: isPrimaryDataRefetching,
      status: primaryDataStatus,
      enabled: loadPrimaryData,
    },
    // TODO: move sections handlers to work details hook
    sections: {
      name: {
        data: nameData,
        permissions: getSectionPermissions(
          !!settings?.enable_request_changes_name,
          nameData,
        ),
        handler: {
          load: () => setLoadNameData(true),
          refetch: () => refetchNameData(),
          isRefetching: isNameDataRefetching,
          status: nameDataStatus,
          enabled: loadNameData,
        },
      },
      position: {
        data: positionData,
        permissions: getSectionPermissions(
          !!settings?.enable_request_changes_position,
          positionData,
        ),
        handler: {
          load: () => setLoadPositionData(true),
          refetch: () => refetchPositionData(),
          isRefetching: isPositionDataRefetching,
          status: positionDataStatus,
          enabled: loadPositionData,
        },
      },
      organisation: {
        data: organisationData,
        permissions: getSectionPermissions(
          !!settings?.enable_request_changes_organisation,
          organisationData,
        ),
        handler: {
          load: () => setLoadOrganisationData(true),
          refetch: () => refetchOrganisationData(),
          isRefetching: isOrganisationDataRefetching,
          status: organisationDataStatus,
          enabled: loadOrganisationData,
        },
      },
      notes: {
        data: notesData,
        permissions: getSectionPermissions(true, notesData),
        handler: {
          load: () => setLoadNotesData(true),
          refetch: () => refetchNotesData(),
          isRefetching: isNotesDataRefetching,
          status: notesDataStatus,
          enabled: loadNotesData,
        },
      },
    },
  }
}
