import {
  FunctionalDeliverableInterface,
  FunctionalDeliverableScorecardInterface,
} from '@src/interfaces/deliverables'
import { ReviewerRelation } from '@src/interfaces/performance'

export const getCutOffText = (cycleName?: string) =>
  `Cut-off date for ${cycleName || 'cycle'} has passed. You cannot change reviews now.`

export const isIgnoredTicket = (
  deliverable: FunctionalDeliverableInterface,
  scorecard?: FunctionalDeliverableScorecardInterface,
) => {
  return scorecard?.ignored || deliverable.filtered_aggregation?.ignored
}

export const getDeliverableScorecard = (deliverable: FunctionalDeliverableInterface) => {
  const FMscorecard = deliverable.deliverable_scorecards.find(
    item => item.reviewer_relation === ReviewerRelation.FunctionalManager,
  )
  const LMscorecard = deliverable.deliverable_scorecards.find(
    item => item.reviewer_relation === ReviewerRelation.LineManager,
  )

  return FMscorecard || LMscorecard
}
