import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { MoreBar } from '@revolut/ui-kit'
import { ContractStatuses, EmployeeContractsInterface } from '@src/interfaces/employees'
import { Chat, Copy, ExclamationTriangle, Pencil } from '@revolut/icons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { LapeFormInterface } from '@src/features/Form/LapeForm'
import { getPermissions } from '@src/pages/Forms/EmployeeContracts/Preview/getPermissions'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { Cancel } from './Cancel'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Terminate } from './Terminate'

type Props = {
  form: LapeFormInterface<EmployeeContractsInterface>
  showComments: () => void
  onCopyContract: () => void
  onStatusUpdate: (data: EmployeeContractsInterface) => void
  isBulkReview?: boolean
}
export const ContractActionButtons = ({
  form,
  showComments,
  onCopyContract,
  onStatusUpdate,
  isBulkReview,
}: Props) => {
  const { id, employeeId } = useParams<{ id: string; employeeId: string }>()

  const { values } = form

  const [terminationModalOpen, setTerminationModalOpen] = useState(false)

  const userPermissions = useSelector(selectPermissions)
  const globalPermissions = userPermissions.filter(
    p =>
      p === PermissionTypes.ChangeEmployeeContractBase ||
      p === PermissionTypes.ChangeEmployeeContractElevated,
  )
  const permissions = getPermissions([
    ...(values.field_options?.permissions || []),
    ...globalPermissions,
  ])

  const isPending = [
    ContractStatuses.Pending,
    ContractStatuses.PendingCompletion,
  ].includes(values.contract_status?.id)

  const isActive = values.contract_status?.id === ContractStatuses.Active

  const showEditButton = isPending
    ? permissions.canEdit
    : permissions.canEditBase || permissions.canEditElevated

  const showTerminateButton =
    !isBulkReview && isActive && !values.end_date && permissions.canCreate // this permission should be used for termination too

  if (!values.id) {
    return null
  }
  return (
    <>
      <MoreBar>
        {permissions.canCreate && (
          <MoreBar.Action onClick={onCopyContract} useIcon={Copy}>
            Duplicate & modify
          </MoreBar.Action>
        )}
        {showEditButton && (
          <MoreBar.Action
            useIcon={Pencil}
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId, id })}
          >
            {values.contract_status?.id === 'pending_completion'
              ? 'Edit & complete'
              : 'Edit'}
          </MoreBar.Action>
        )}
        {permissions.canViewComments && (
          <MoreBar.Action useIcon={Chat} onClick={showComments}>
            Comments
          </MoreBar.Action>
        )}
        {!isBulkReview && <Cancel form={form} onStatusUpdate={onStatusUpdate} />}
        {showTerminateButton && (
          <>
            <MoreBar.Action
              disabled={!permissions.canCancel}
              onClick={() => setTerminationModalOpen(true)}
              variant="negative"
              useIcon={ExclamationTriangle}
            >
              Terminate
            </MoreBar.Action>
            <Terminate
              open={terminationModalOpen}
              setOpen={setTerminationModalOpen}
              id={id}
              employeeId={employeeId}
            />
          </>
        )}
      </MoreBar>
    </>
  )
}
