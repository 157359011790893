import { Flex, Text, Checkbox, Link, Token, Icon } from '@revolut/ui-kit'
import React from 'react'
import { SidebarOption } from '../types'

interface Props {
  option: SidebarOption
  checked: boolean
  disabled?: boolean
  handleItemClick: (item: SidebarOption, checked: boolean) => void
}

export const Item = ({ option, checked, disabled, handleItemClick }: Props) => {
  const itemDisabled = disabled || option.disabled

  return (
    <Flex
      gap="s-8"
      alignItems="center"
      key={option.id}
      data-testid={`sidebar-multiselect-checkbox-${option.id}`}
      onClick={() => !itemDisabled && handleItemClick(option, checked)}
      style={{ cursor: itemDisabled ? 'not-allowed' : 'pointer' }}
    >
      <Checkbox
        aria-label={option.link?.name}
        checked={checked}
        disabled={itemDisabled}
        value={option.id}
        onChange={() => !itemDisabled && handleItemClick(option, checked)}
      />
      {option.icon ? <Icon name={option.icon} size={16} /> : null}
      {option?.link && (
        <Link href={option.link?.href} target="_blank" minWidth="fit-content">
          {option.link?.name}
        </Link>
      )}
      <Text color={itemDisabled ? Token.color.greyTone10 : undefined}>{option.name}</Text>
      {!!option.labels?.length && (
        <Flex flexWrap="wrap" gap="s-8">
          {option.labels.map((label, idx) => {
            return (
              <Text
                key={idx}
                fontWeight={500}
                fontSize="tiny"
                borderRadius={4}
                py="s-4"
                px="s-6"
                backgroundColor={Token.color.greyTone5}
                color={label.color}
              >
                {label.name}
              </Text>
            )
          })}
        </Flex>
      )}
    </Flex>
  )
}
