import React, { createContext, useMemo, useRef, useState } from 'react'
import {
  ParameterDefaultValue,
  RunQueryResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'

interface QueryRunContextProviderProps {
  children: React.ReactNode
}

interface QueryRunParametersInterface {
  [key: string]: ParameterDefaultValue
}

export const QueryContext = createContext<{
  chartRef?: React.MutableRefObject<any>
  fetchingError: string
  setFetchingError: React.Dispatch<React.SetStateAction<string>>
  fetchingErrorDescription: string
  setFetchingErrorDescription: React.Dispatch<React.SetStateAction<string>>
  resetParameters: boolean
  setResetParameters: React.Dispatch<React.SetStateAction<boolean>>
  queryRunParameters: QueryRunParametersInterface
  setQueryRunParameters: React.Dispatch<React.SetStateAction<QueryRunParametersInterface>>
  queryRunResponse?: RunQueryResponseInterface
  setQueryRunResponse: React.Dispatch<
    React.SetStateAction<RunQueryResponseInterface | undefined>
  >
  queryRunStatus?: QueryRunStatuses
  setQueryRunStatus: React.Dispatch<React.SetStateAction<QueryRunStatuses | undefined>>
}>({
  fetchingError: '',
  setFetchingError: () => {},
  fetchingErrorDescription: '',
  setFetchingErrorDescription: () => {},
  resetParameters: false,
  setResetParameters: () => {},
  queryRunParameters: {},
  setQueryRunParameters: () => {},
  setQueryRunResponse: () => {},
  setQueryRunStatus: () => {},
})

export const QueryContextProvider = ({ children }: QueryRunContextProviderProps) => {
  const chartRef = useRef(null)
  const [fetchingError, setFetchingError] = useState('')
  const [fetchingErrorDescription, setFetchingErrorDescription] = useState('')
  const [resetParameters, setResetParameters] = useState(false)
  const [queryRunParameters, setQueryRunParameters] =
    useState<QueryRunParametersInterface>({})
  const [queryRunResponse, setQueryRunResponse] = useState<RunQueryResponseInterface>()
  const [queryRunStatus, setQueryRunStatus] = useState<QueryRunStatuses>()

  const contextValue = useMemo(
    () => ({
      chartRef,
      fetchingError,
      setFetchingError,
      fetchingErrorDescription,
      setFetchingErrorDescription,
      resetParameters,
      setResetParameters,
      queryRunParameters,
      setQueryRunParameters,
      queryRunResponse,
      setQueryRunResponse,
      queryRunStatus,
      setQueryRunStatus,
    }),
    [
      chartRef,
      fetchingError,
      setFetchingError,
      fetchingErrorDescription,
      setFetchingErrorDescription,
      resetParameters,
      setResetParameters,
      queryRunParameters,
      setQueryRunParameters,
      queryRunResponse,
      setQueryRunResponse,
      queryRunStatus,
      setQueryRunStatus,
    ],
  )
  return <QueryContext.Provider value={contextValue}>{children}</QueryContext.Provider>
}
