import React, { useState } from 'react'
import { FilterButton, Flex, MoreBar } from '@revolut/ui-kit'
import { getRoadmapsMain } from '@src/api/roadmaps'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { useTable } from '@src/components/Table/hooks'
import { ADDING_ROADMAPS_TO_REVOLUTERS } from '@src/constants/externalLinks'
import { FilterByInterface, SORT_DIRECTION, Stats } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { TableWrapper } from '@src/components/Table/TableWrapper'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import Stat from '@src/components/Stat/Stat'
import SearchTable from '@src/components/Table/SearchTable/SearchTable'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

import { rows } from './rows'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

export const RoadmapsMain = () => {
  const user = useSelector(selectUser)

  const cycleFilter = [
    {
      filters: [{ name: '0', id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'priority',
      direction: SORT_DIRECTION.DESC,
    },
    {
      sortBy: 'due_date',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const table = useTable<RoadmapInterface, Stats>(
    { getItems: getRoadmapsMain },
    cycleFilter,
    initialSortBy,
  )

  const [filteredByOwn, setFilteredByOwn] = useState(false)

  const toggleOwnRoadmaps = () => {
    const shouldFilter = !filteredByOwn
    setFilteredByOwn(shouldFilter)
    table.onFilterChange([
      {
        columnName: 'reporter__id',
        nonResettable: true,
        filters: shouldFilter
          ? [
              {
                name: user.display_name,
                id: user.id,
              },
            ]
          : [],
      },
    ])
  }

  const onSearch = (filter: FilterByInterface) => {
    table.onFilterChange(filter, false)
  }

  const openEpicUrl = ({ epic_url }: RoadmapInterface) => {
    if (epic_url) {
      window.open(epic_url, '_blank')
    }
  }

  return (
    <>
      <TableWrapper flexDirection="column" mt="s-8" minHeight={0}>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <Flex mb="s-24">
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
            <CycleFilter
              type={CycleFilterType.NewUI}
              filterInputType={FilterSelectType.SingleSelect}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          </Flex>
          <SearchTable
            placeholder="Search by name and key"
            mb="s-24"
            onFilter={onSearch}
          />
        </Flex>
        <Flex flexWrap="wrap" justifyContent="space-between">
          <HideIfCommercial>
            <Flex mb="s-16">
              <MoreBar>
                <MoreInfoButton href={ADDING_ROADMAPS_TO_REVOLUTERS} />
              </MoreBar>
            </Flex>
          </HideIfCommercial>
          <Flex mb="s-24">
            <Flex gap="s-8">
              <FilterButton
                onClick={toggleOwnRoadmaps}
                active={filteredByOwn}
                aria-label="My roadmaps"
              >
                My roadmaps
              </FilterButton>
            </Flex>
          </Flex>
        </Flex>

        <AdjustableTable<RoadmapInterface>
          name={TableNames.RoadmapsMain}
          useWindowScroll
          dataType="Roadmap"
          row={rows}
          {...table}
          noDataMessage="Roadmaps will appear here."
          onRowClick={openEpicUrl}
        />
      </TableWrapper>
    </>
  )
}

export default RoadmapsMain
