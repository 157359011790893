import Form from '@src/features/Form/Form'
import React from 'react'
import LookerFolder from './LookerFolder'
import { formLookerFolderAccessRequest } from '@src/api/accessRequests'

const LookerFolderRouter = () => {
  return (
    <Form api={formLookerFolderAccessRequest}>
      <LookerFolder />
    </Form>
  )
}

export default LookerFolderRouter
