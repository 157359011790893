import { FieldOptions, IdAndName, Statuses } from './index'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import { TeamInterface } from '@src/interfaces/teams'
import { OptionInterface } from '@src/interfaces/selectors'

export enum AccessTypes {
  METABASE_USER = 'metabase_database_user_access_request',
  METABASE_TEAM = 'metabase_database_team_access_request',
  LOOKER_MODEL = 'looker_model_user_access_request',
  LOOKER_FOLDER = 'looker_folder_user_access_request',
}

export interface LookerModelAccessInterface {
  approved_by: EmployeeInterface
  approved_date_time: string
  canceled_by: EmployeeInterface
  canceled_date_time: string
  creation_date_time: string
  employee: { id: number; full_name: string }
  expired_date_time: string
  field_options: FieldOptions
  from_date_time: string
  id: number
  lookml_model: { id: string; label: string; owner: EmployeeInterface }
  permission_set: OptionInterface
  reason: string
  rejected_by: EmployeeInterface
  rejected_date_time: string
  rejection_reason: string
  revoked_by: EmployeeInterface
  revoked_date_time: string
  status: IdAndName<Statuses>
  submitted_by: EmployeeInterface
  to_date_time: string
  updated_date_time: string
}

export interface AccessRequestInterface {
  id: number
  employee: { id?: number; full_name: string }
  team: { id?: number; full_name: string }
  status?: Statuses
  type?: string
  database: {
    id: number
    name: string
  }
  source?: 'user_access_request' | 'team_access_request'
  permission: string
  access_request_id?: number
  from_date_time: string
  tool?: OptionInterface
  access_type: { id: AccessTypes }
  to_date_time: string
  reason: string
  field_options: FieldOptions
}

export interface TeamAccessRequestInterface {
  approved_by: EmployeeOptionInterface
  approved_date_time: string
  canceled_by: EmployeeOptionInterface
  canceled_date_time: string
  creation_date_time: string
  database: { id: number; name: string }
  expired_date_time: string
  from_date_time: string
  id: number
  permission: string
  reason: string
  rejected_by: EmployeeOptionInterface
  field_options: FieldOptions
  rejected_date_time: string
  rejection_reason: string
  revoked_by: EmployeeOptionInterface
  revoked_date_time: string
  role: { id: number; name: string; status: Statuses }
  status: Statuses
  submitted_by: EmployeeOptionInterface
  team: TeamInterface
  to_date_time: string
  updated_date_time: string
  can_extend: boolean
  days_since_queried: string
  days_since_viewed: string
  is_assigned_permission_used: boolean
  review_cycle: OptionInterface
  review_status: OptionInterface
  sql_ct: string
  team_role_usage_perc: string
  view_ct: number
}
