import React from 'react'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { IconUrl } from '@src/components/ColumnInserts/IconLink/IconLink'
import { FunctionInterface } from '@src/interfaces/functions'
import { formatMoney } from '@src/utils/format'
import { Token } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Table from '@components/TableV2/Table'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'

export const functionNameIconColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.functions,
  title: 'Function',
  dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id: data.id }),
  insert: ({ data, url }) => (
    <Table.EntityCell
      useIcon={data.icon || FUNCTION_DEFAULT_ICON}
      avatar={data.avatar}
      url={url}
    >
      {data.name}
    </Table.EntityCell>
  ),
}

export const functionHeadcountColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.text,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: 'headcount',
  filterType: FilterType.range,
  filterKey: 'headcount',
  selectorsKey: selectorKeys.none,
  colors: (data: FunctionInterface) =>
    data.headcount ? Token.color.foreground : Token.color.greyTone20,
  title: 'headcount',
}

export const functionRequisitionsColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.text,
  idPoint: 'requisition_headcount',
  dataPoint: 'requisition_headcount',
  sortKey: 'requisition_headcount',
  filterKey: 'requisition_headcount',
  filterType: FilterType.range,
  colors: (data: FunctionInterface) =>
    data.requisition_headcount ? Token.color.foreground : Token.color.greyTone20,
  selectorsKey: selectorKeys.none,
  title: 'Hiring',
}

export const functionRoleColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.text,
  idPoint: 'role_count',
  dataPoint: 'role_count',
  sortKey: 'role_count',
  filterKey: 'role_count',
  filterType: FilterType.range,
  colors: data => (data.role_count ? Token.color.foreground : Token.color.greyTone20),
  selectorsKey: selectorKeys.none,
  title: 'Roles',
}

export const functionKPIColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.insert,
  idPoint: 'kpi_performance_percent',
  insert: ({ data }) => <ColoredPercent percent={data.kpi_performance_percent * 100} />,
  dataPoint: 'kpi_performance_percent',
  sortKey: 'kpi_performance_percent',
  filterKey: 'kpi_performance_percent',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.kpi_performance_percent,
  title: 'KPI',
}

export const functionRoadmapColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.insert,
  idPoint: 'roadmap_progress_percent',
  insert: ({ data }) => <ColoredPercent percent={data.roadmap_progress_percent * 100} />,
  dataPoint: 'roadmap_progress_percent',
  sortKey: 'roadmap_progress_percent',
  filterKey: 'roadmap_progress_percent',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.roadmap_progress_percent,
  title: 'Roadmap',
}

export const functionLinkColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.insert,
  idPoint: 'documentation_link',
  dataPoint: 'documentation_link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <IconUrl type="Link" href={data.documentation_link} />
  },
  title: 'Conf link',
}

export const functionBudgetColumn: ColumnInterface<FunctionInterface> = {
  type: CellTypes.insert,
  idPoint: 'total_current_spent',
  dataPoint: 'total_current_spent',
  sortKey: 'total_current_spent',
  filterKey: 'total_current_spent',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (typeof data.total_current_spent !== 'number') {
      return '-'
    }

    return formatMoney(data.total_current_spent, data.budget_currency)
  },
  textAlign: 'right',
  title: 'Cost/month',
}
