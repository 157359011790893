import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ImportStep, { ImportStepProps } from '../ImportStep'
import { UploadSession, UploadSessionProps } from './UploadSession'
import { OnboardingChecklistSectionConfig } from '../../common/constants'
import { RecommendedLabelType } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

type OnboardingBulkDataUploadProps<T> = {
  importRoute: string
  sessionRoute: string
  anyRoute: string
  apiEndpoint: string
  config: OnboardingChecklistSectionConfig
  currentStep: string
  recommendedLabel?: RecommendedLabelType
  yesByDefault?: boolean
} & Pick<ImportStepProps, 'category' | 'name' | 'previousRoute' | 'title' | 'subtitle'> &
  Pick<UploadSessionProps<T>, 'row' | 'tableName' | 'fields' | 'onBeforeSubmit'>

export const OnboardingBulkDataUpload = <T,>({
  importRoute,
  sessionRoute,
  anyRoute,
  apiEndpoint,
  config,
  currentStep,
  category,
  name,
  previousRoute,
  tableName,
  row,
  fields,
  onBeforeSubmit,
  title,
  subtitle,
  recommendedLabel,
  yesByDefault,
}: OnboardingBulkDataUploadProps<T>) => {
  return (
    <Switch>
      <Route exact path={importRoute}>
        <ImportStep
          onBeforeSubmit={onBeforeSubmit}
          nextRoute={sessionRoute}
          apiEndpoint={apiEndpoint}
          config={config}
          currentStep={currentStep}
          category={category}
          name={name}
          previousRoute={previousRoute}
          title={title}
          subtitle={subtitle}
          recommendedLabel={recommendedLabel}
          yesByDefault={yesByDefault}
        />
      </Route>
      <Route exact path={sessionRoute}>
        <UploadSession
          onBeforeSubmit={onBeforeSubmit}
          apiEndpoint={apiEndpoint}
          config={config}
          currentStep={currentStep}
          previousRoute={importRoute}
          tableName={tableName}
          row={row}
          fields={fields}
        />
      </Route>
      <Route exact path={anyRoute}>
        <InternalRedirect to={importRoute} />
      </Route>
    </Switch>
  )
}
