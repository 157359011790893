import React, { useRef } from 'react'
import { Box, useIntersectViewport } from '@revolut/ui-kit'

type Props = {
  onReach: () => void
}

const LazyLoadPlaceholder = ({ onReach }: Props) => {
  const ref = useRef(null)

  useIntersectViewport(
    ref,
    isIntersecting => {
      if (isIntersecting) {
        onReach()
      }
    },
    0.3,
  )

  return <Box ref={ref} />
}

export default LazyLoadPlaceholder
