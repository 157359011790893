import { useEffect } from 'react'

export const DocuSignConfirmation = () => {
  useEffect(() => {
    window.opener?.focus?.()
    window.opener?.submitDocusign?.()
    window.close()
  }, [])

  return null
}
