import { Token } from '@revolut/ui-kit'
import { defaultTheme } from '@src/styles/theme'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

const ShowFullText = css`
  position: absolute;
  width: auto;
  overflow: auto;
  background-color: ${Token.color.background};
  z-index: ${defaultTheme.zIndex.aboveMain};
  white-space: pre-wrap;
`

const Container = styled.div`
  height: 1.43em;
`

const HidingLayer = styled.div<{ show: boolean; width?: number }>`
  position: inherit;
  overflow: hidden;
  ${props => (props.width ? `width: ${props.width}px` : '')};
  white-space: nowrap;
  text-overflow: ellipsis;
  ${props => props.show && ShowFullText}
`

interface SmartEllipsisProps {
  className?: string
  children: string
}

export const SmartEllipsis = ({ className, children }: SmartEllipsisProps) => {
  const [showFullText, setShowFullText] = useState(false)
  const [hidingLayerWidth, setHidingLayerWidth] = useState<number>() // Safari fix, ellipsis wouldn't work after showing full text once.
  const hidingLayer = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (hidingLayer && hidingLayer.current) {
      setHidingLayerWidth(hidingLayer.current.offsetWidth)
    }
  }, [])

  const handleMouseEnter = () => {
    setShowFullText(true)
  }

  const handleMouseLeave = () => {
    setShowFullText(false)
  }

  return (
    <Container
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={className}
    >
      <HidingLayer width={hidingLayerWidth} ref={hidingLayer} show={showFullText}>
        {children}
      </HidingLayer>
    </Container>
  )
}
