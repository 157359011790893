import React from 'react'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import { Flex } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { NewPlaceholdersTableInterface } from '@src/interfaces/offerTemplates'
import { RowInterface } from '@src/interfaces/data'
import {
  placeholderActionColumn,
  placeholderNameColumn,
  placeholderSimilarColumn,
} from '@src/constants/columns/offerForm/offerTemplates'
import { TableNames } from '@src/constants/table'

type Props = {
  data: {
    [key: string]: string[]
  }
}

const rows: RowInterface<NewPlaceholdersTableInterface> = {
  cells: [
    {
      ...placeholderNameColumn,
      width: 300,
    },
    {
      ...placeholderSimilarColumn,
      width: 300,
    },
    {
      ...placeholderActionColumn,
      width: 150,
    },
  ],
}

const NewPlaceholdersTable = ({ data }: Props) => {
  const tableData = Object.keys(data).map(key => ({
    name: key,
    similar: data[key].join(', '),
  }))

  return (
    <>
      <NewStepperTitle
        title="New placeholders detected"
        type={NewStepperTitleType.compact}
      />

      <Flex flexDirection="column" width="100%">
        <Flex
          style={{ position: 'relative' }}
          flex="1 0"
          data-testid="new-placeholders-table"
        >
          <AdjustableTable<NewPlaceholdersTableInterface>
            name={TableNames.OfferNewPlaceholders}
            useWindowScroll
            row={rows}
            data={tableData}
            count={tableData.length}
            hideCount
          />
        </Flex>
      </Flex>
    </>
  )
}

export default NewPlaceholdersTable
