import { IndeedPosting, IntegrationPostingStatuses } from '@src/interfaces/jobPosting'

export const isRejected = ({ status }: IndeedPosting) =>
  status === IntegrationPostingStatuses.rejected

export const isPending = ({ status }: IndeedPosting) =>
  status === IntegrationPostingStatuses.pending

export const isPublished = ({ status }: IndeedPosting) =>
  status === IntegrationPostingStatuses.published
