import { Token } from '@revolut/ui-kit'
import {
  JobPostingInterface,
  JobPostingLocationInterface,
  PublishingStatuses,
} from '@src/interfaces/jobPosting'

export const getNormalizedLocations = (locations: JobPostingLocationInterface[]) =>
  locations.reduce<{ office: string[]; remote: string[] }>(
    (acc, item) => {
      switch (item.type) {
        case 'remote': {
          acc.remote.push(item.location_name)
          break
        }

        case 'office': {
          acc.office.push(item.location_name)
          break
        }

        default: {
          acc.remote.push(item.location_name)
        }
      }
      return acc
    },
    {
      office: [],
      remote: [],
    },
  )

export const getJobPostingStatusColor = (status: PublishingStatuses) => {
  let color

  switch (status) {
    case PublishingStatuses.closed:
      color = Token.color.grey20
      break

    case PublishingStatuses.fully_published:
    case PublishingStatuses.published_internally:
      color = Token.color.teal
      break

    default:
      color = Token.color.foreground
  }

  return color
}

export const isPublished = (jobPosting: JobPostingInterface) =>
  jobPosting.status !== PublishingStatuses.unpublished &&
  jobPosting.status !== PublishingStatuses.closed
