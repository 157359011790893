import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import { selectorKeys } from '@src/constants/api'
import { TextButton } from '@revolut/ui-kit'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'

export const userNameColumn: ColumnInterface<UserAccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee',
  dataPoint: 'employee',
  sortKey: null,
  filterKey: 'id',
  title: 'Name',
  selectorsKey: selectorKeys.active_users,
  insert: ({ data }) =>
    data.user_type === 'employee' && data.employee ? (
      <Table.EmployeeCell employee={data.employee} size={40} />
    ) : (
      data.email
    ),
}

export const typeColumn: ColumnInterface<UserAccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'user_type',
  dataPoint: 'user_type',
  sortKey: null,
  filterKey: 'user_type',
  selectorsKey: selectorKeys.user_types,
  title: 'type',
}

export const emailColumn: ColumnInterface<UserAccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => data.email,
}

export const lastLoginColumn: ColumnInterface<UserAccessManagementInterface> = {
  type: CellTypes.dateTime,
  idPoint: 'last_login',
  dataPoint: 'last_login',
  sortKey: 'last_login',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last login',
}

export const statusColumn: ColumnInterface<UserAccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: 'status',
  selectorsKey: selectorKeys.users_status,
  title: 'Status',
  insert: ({ data }) =>
    data.status ? (
      <Table.StatusCell
        status={data.status.name}
        variant={getDefaultStatusVariant(data.status.id)}
      />
    ) : (
      '-'
    ),
}

export const actionColumn = (
  onClick: (data: UserAccessManagementInterface) => void,
): ColumnInterface<UserAccessManagementInterface> => ({
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => (
    <TextButton
      onClick={e => {
        e.stopPropagation()
        onClick(data)
      }}
    >
      Change access
    </TextButton>
  ),
})
