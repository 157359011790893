import React from 'react'
import { Group, VStack } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const General = () => {
  const queryClient = useQueryClient()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangePayrollPreferences)

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="Settings that apply for payroll functionalities"
          />
          <VStack space="s-24">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable payroll',
                  description: 'Enable payroll management',
                }}
                name="enabled"
                disabled={disableEdit}
              />
            </Group>
          </VStack>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.PAYROLL_SETTINGS)
            queryClient.invalidateQueries(API.APP_SETTINGS)
          }}
          useValidator
          successText="Settings saved"
        />
      </PageActions>
    </>
  )
}
