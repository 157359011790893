import { useEffect, useState } from 'react'
import { userRequest } from '@src/api/accessManagement'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'

export const useFetchUser = (userId: string) => {
  const [user, setUser] = useState<UserAccessManagementInterface>()

  useEffect(() => {
    const fetchUser = async () => {
      const resp = await userRequest.get({ id: userId })
      setUser(resp.data)
    }

    fetchUser()
  }, [])

  return user
}
