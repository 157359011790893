import React, { useState } from 'react'
import { Color, Box } from '@revolut/ui-kit'
import format from 'date-fns/format'

import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import Loader from '@components/CommonSC/Loader'
import { getEmployeeSalaryHistory, SalaryHistoryresponse } from '@src/api/rewards'
import { formatMoney } from '@src/utils/format'
import {
  Dot,
  DotContainer,
  HistoryItemContainer,
  HistoryItemText,
  HistoryTypes,
  Line,
  NoChangesMessage,
  TooltipBodyContainer,
  TooltipLoaderContainer,
} from '@src/components/Stepper/HistoryTooltip'

interface SalaryHistoryTooltipProps {
  employeeId: number
  targetCurrency: string
}

const SalaryHistoryTooltip: React.FC<SalaryHistoryTooltipProps> = ({
  employeeId,
  targetCurrency,
}: SalaryHistoryTooltipProps) => {
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState<SalaryHistoryresponse[]>()

  const renderTooltipBody = () => {
    if (loading) {
      return (
        <TooltipLoaderContainer>
          <Loader size="tiny" />
        </TooltipLoaderContainer>
      )
    }

    if (history && !history.length) {
      return (
        <TooltipBodyContainer>
          <NoChangesMessage>There is no history records</NoChangesMessage>
        </TooltipBodyContainer>
      )
    }

    return (
      <TooltipBodyContainer>
        {history?.map((item, index) => {
          const type =
            item.period_end_date == null ? HistoryTypes.Current : HistoryTypes.Past
          return (
            <HistoryItemContainer type={type} key={index}>
              <DotContainer>
                <Dot type={type} />
                {!(index === history?.length - 1) && <Line />}
              </DotContainer>
              {item.period_start_date ? (
                <HistoryItemText>
                  {formatMoney(item.period_amount, item.currency?.iso_code)},{' '}
                  {format(new Date(item.period_start_date), 'MMM yyyy')} -{' '}
                  {item.period_end_date
                    ? format(new Date(item.period_end_date), 'MMM yyyy')
                    : 'Current'}
                </HistoryItemText>
              ) : null}
            </HistoryItemContainer>
          )
        })}
      </TooltipBodyContainer>
    )
  }

  const handleMouseIn = async () => {
    try {
      setLoading(true)
      const result = await getEmployeeSalaryHistory(employeeId, targetCurrency)

      if (result) {
        setHistory(result.data.results)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Tooltip onHover={handleMouseIn} placement="right" body={renderTooltipBody()}>
        <Icon color={Color.GREY_TONE_50} size="tiny" type="Time" />
      </Tooltip>
    </Box>
  )
}

export default SalaryHistoryTooltip
