import React, { useState } from 'react'
import { ActionWidget, Button, Cell, Box, Flex, ActionButton } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import {
  completeSchedulingPolicyConfiguration,
  getScheduleShifts,
} from '@src/api/attendance'
import { SchedulingPolicyInterface } from '@src/interfaces/attendance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ShiftTable } from './common'

const Shifts = () => {
  const params = useParams<{ id: string }>()

  const { values, reset } = useLapeContext<SchedulingPolicyInterface>()

  const table = useTable({ getItems: getScheduleShifts(params.id) })

  const [completePending, setCompletePending] = useState(false)

  const onComplete = () => {
    setCompletePending(true)

    completeSchedulingPolicyConfiguration(values)
      .then(response => {
        reset(response.data)
        navigateReplace(pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.PREVIEW, params))
      })
      .catch(() => setCompletePending(false))
  }

  return (
    <>
      <ActionWidget>
        <ActionWidget.Title>
          Manage shifts linked to the scheduling policy
        </ActionWidget.Title>
        <ActionWidget.Content>
          In this step you need to specify the time and compensation characteristics of
          each shift that may be assigned to the employee that’s subject to the scheduling
          policy. Basic shifts were automatically created – you can confirm and activate
          them, edit or delete. You can also add all other shifts that should be linked
          with this scheduling policy.
        </ActionWidget.Content>
      </ActionWidget>

      <Box mt="s-16">
        <Cell>
          <Flex flexDirection="column" gap="s-16" width="100%">
            <ActionButton
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.GENERAL, {
                    policyId: values.id,
                  }),
                )
              }
              useIcon="Plus"
            >
              Add shift
            </ActionButton>
            <ShiftTable policyId={values.id} table={table} hideActions={false} />
          </Flex>
        </Cell>
      </Box>

      {values.configuration_step.id === 'shifts' ? (
        <PageActions>
          <Flex maxWidth={344} gap="s-16">
            <Button
              onClick={() =>
                navigateReplace(pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.GENERAL, params))
              }
              variant="secondary"
            >
              Back
            </Button>
            <Button
              onClick={onComplete}
              disabled={!table.data.length}
              pending={completePending}
              elevated
            >
              Complete
            </Button>
          </Flex>
        </PageActions>
      ) : null}
    </>
  )
}

export default Shifts
