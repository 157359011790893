export enum TableTypes {
  /** @deprecated */
  Form = 'form',
  /** @deprecated */
  Popup = 'popup',
  /** @deprecated */
  Stepper = 'stepper',
  /** @deprecated */
  NewStepper = 'stepper-new',
  /** @deprecated */
  Matrix = 'matrix',
  Adjustable = 'adjustable',
  Contained = 'contained',
}

export type RowHeight = 'large' | 'medium' | 'small'
