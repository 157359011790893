import React from 'react'
import { Box, Text } from '@revolut/ui-kit'
import SectionSubtitle from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SectionSubtitle'

type Props = {
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
}

const SectionHeader = ({ title, subtitle }: Props) => {
  return (
    <Box mb="s-16">
      <Text use="div" variant="emphasis1">
        {title}
      </Text>
      <SectionSubtitle value={subtitle} />
    </Box>
  )
}

export default SectionHeader
