import React, { useMemo } from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { CheckboxSelect, CheckboxSelectProps, RadioSelect } from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { Ellipsis, Text, SelectOptionItemType } from '@revolut/ui-kit'

export enum FilterSelectType {
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
}

export type FilterInputSelectorType =
  | selectorKeys
  | (() => Promise<{ options: OptionInterface[] }>)

export interface NewFilterInputProps<T = OptionInterface>
  extends Pick<
    CheckboxSelectProps<T>,
    'anchorRef' | 'open' | 'children' | 'width' | 'maxWidth'
  > {
  /** Array of selected items */
  value?: T[]
  /** Callback for when you select an item */
  onChange: (option: T[]) => void
  /** Selector id to request items for the list */
  selector: FilterInputSelectorType
  /** Type of input  */
  type?: FilterSelectType
  /** On dropdown close */
  onClose: () => void
  renderOption?: (option: SelectOptionItemType<T>) => React.ReactNode
}

const NewFilterSelect = <
  T extends { id: number | string; name: string; sub_title?: string } = OptionInterface,
>({
  selector,
  onChange,
  value = [],
  anchorRef,
  open,
  onClose,
  type = FilterSelectType.MultiSelect,
  renderOption,
  ...rest
}: NewFilterInputProps<T>) => {
  const { options, asyncState } = useFetchOptions<T>(selector)
  const isSingleSelect = type === FilterSelectType.SingleSelect

  const selectedValue = useMemo(
    () =>
      options
        .filter(option => value.some(val => String(option.value.id) === String(val.id)))
        .map(inter => inter.value),
    [value, options],
  )

  if (isSingleSelect) {
    return (
      <RadioSelect
        anchorRef={anchorRef}
        open={open}
        onClose={onClose}
        options={options}
        value={selectedValue[0] || null}
        onChange={selected => {
          onChange(selected ? [selected] : [])
        }}
        labelList="Options"
        loadingState={asyncState}
        searchable
        indicatorStyle="highlight"
        autoClose
        {...rest}
      >
        {renderOption ||
          (option => (
            <>
              <Text use="p">{option.value.name}</Text>
              {option.value.sub_title && (
                <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
                  <Ellipsis>{option.value.sub_title}</Ellipsis>
                </Text>
              )}
            </>
          ))}
      </RadioSelect>
    )
  }

  return (
    <CheckboxSelect
      anchorRef={anchorRef}
      open={open}
      onClose={onClose}
      options={options}
      value={selectedValue}
      onChange={onChange}
      labelList="Options"
      loadingState={asyncState}
      type="confirm"
      searchable
      {...rest}
    >
      {renderOption}
    </CheckboxSelect>
  )
}

export default NewFilterSelect
