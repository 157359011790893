import React, { useState } from 'react'

import { useQuery } from '@src/utils/queryParamsHooks'
import { documentsBulkRequestFormRequests } from '@src/api/documents'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import {
  AttachedTemplateInterface,
  DocumentsTemplateTypeId,
} from '@src/interfaces/documentsTemplates'
import { SelectTemplatesSidebar } from '../components/SelectTemplatesSidebar'
import { CommonTabProps } from '../../common'
import { BasicsTabContent } from './BasicsTabContent'
import {
  BulkRequestOptionsQueryParams,
  isESignatureTemplatesEnabled,
  isRequestTemplatesEnabled,
} from '../../SelectBulkRequestOptionsPopup'

export const Basics = (props: CommonTabProps) => {
  const { query } = useQuery<BulkRequestOptionsQueryParams>()

  const [activeSidebar, setActiveSidebar] = useState<'template'>()
  const [attachedTemplates, setAttachedTemplates] = useState<AttachedTemplateInterface[]>(
    [],
  )
  const filterByTypes: DocumentsTemplateTypeId[] = [
    isRequestTemplatesEnabled(query) ? 'request' : null,
    isESignatureTemplatesEnabled(query) ? 'esignature' : null,
  ].filter(Boolean)

  return (
    <>
      <PageBody>
        <Form
          useEntityIdFromData
          disableLocalStorageCaching
          api={documentsBulkRequestFormRequests}
        >
          <BasicsTabContent
            {...props}
            openTemplatesSidebar={() => setActiveSidebar('template')}
            attachedTemplates={attachedTemplates}
            initAttachedTemplates={templates => setAttachedTemplates(templates)}
            deleteAttachedTemplate={templateId =>
              setAttachedTemplates(attachedTemplates.filter(t => t.id !== templateId))
            }
          />
        </Form>
      </PageBody>
      {activeSidebar === 'template' && (
        <SelectTemplatesSidebar
          isOpen={activeSidebar === 'template'}
          onClose={() => setActiveSidebar(undefined)}
          attachedTemplates={attachedTemplates}
          onAddTemplate={newTemplate => {
            setAttachedTemplates([...attachedTemplates, newTemplate])
          }}
          filterByTypes={filterByTypes}
          allowCreate={isRequestTemplatesEnabled(query)}
        />
      )}
    </>
  )
}
