import React, { useState, useMemo } from 'react'
import { Box, Fixed, Flex, Group, Header, MoreBar, Side } from '@revolut/ui-kit'

import { defaultTheme } from '@src/styles/theme'
import { DocumentInterface } from '@src/interfaces/documents'
import { newDocumentRequests } from '@src/api/documents'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { SideBarHeaderTitle } from '@src/components/SideBar/SideBarHeaderTitle'
import { DocumentPreview } from './DocumentPreview'

type Props = {
  id: number
  employeeId: number
  isOpen: boolean
  onClose: () => void
}
export const DocumentSidebar = ({ id, employeeId, isOpen, onClose }: Props) => {
  const [document, setDocument] = useState<DocumentInterface>()

  const renderKey = useMemo(() => Math.random(), [id, employeeId])

  if (!isOpen) {
    return null
  }

  return (
    <Fixed
      right={0}
      top={0}
      zIndex={defaultTheme.zIndex.sideBar}
      p="s-16"
      height="100vh"
      key={renderKey}
    >
      <Flex height="calc(100vh - 32px)">
        <Side open onClose={onClose} variant="wide" resizable height="100%">
          <Header variant="item">
            <Header.CloseButton aria-label="Close" />
            <SideBarHeaderTitle>Document preview</SideBarHeaderTitle>
            <Header.Bar>
              <MoreBar>
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { id, employeeId }),
                    )
                  }
                >
                  Edit
                </MoreBar.Action>
              </MoreBar>
            </Header.Bar>
          </Header>
          <Box mt="-16px">
            <FormPreview
              title="Details"
              data={document}
              api={() =>
                newDocumentRequests.get({
                  id: `${id}`,
                  employeeId: `${employeeId}`,
                })
              }
              onDataLoaded={setDocument}
            >
              <Group>
                <FormPreview.Item title="File name" field="name" />
                <FormPreview.Item
                  title="Issue date"
                  field="issue_date_time"
                  type="date"
                />
                <FormPreview.Item title="Issued by" field="issuer.name" />
                <FormPreview.Item
                  title="Signing date"
                  field="signing_date_time"
                  type="date"
                />
                <FormPreview.Item title="Category" field="category.name" />
              </Group>

              <Box my="s-16">
                <FormPreview.Details title="Description" field="description" />
              </Box>
            </FormPreview>
          </Box>

          <DocumentPreview id={id} document={document} />
        </Side>
      </Flex>
    </Fixed>
  )
}
