import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import { Box, Group, InputGroup } from '@revolut/ui-kit'
import addDays from 'date-fns/addDays'
import { pipRequestsNew } from '@src/api/pip'
import { selectorKeys } from '@src/constants/api'
import { PIP_PLAYBOOK } from '@src/constants/externalLinks'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import SettingsButtons from '@src/features/SettingsButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PipFormProps, PipFormInterface } from '@src/pages/Forms/PipV2/interfaces'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { localDateToUtc } from '@src/utils/timezones'

const getEndDateTime = (start: string, length: number) =>
  localDateToUtc(addDays(new Date(start), length))

const PipSettings = ({ employee }: PipFormProps) => {
  const { values } = useLapeContext<PipFormInterface>()

  const permissions = useSelector(selectPermissions)
  const canManagePIPCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManagePIPCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )

  useEffect(() => {
    if (!values.days_length) {
      values.days_length = employee?.default_pip_length || 90
    }

    if (!values.hr_manager) {
      values.hr_manager = employee?.default_hr_manager
    }

    if (!values.review_checkpoints_number) {
      values.review_checkpoints_number = employee?.default_pip_review_checkpoints_number
    }
  }, [])

  const onStarDateTimeAfterChange = (value: string | null) => {
    if (value && values.days_length) {
      values.end_date_time = getEndDateTime(value, values.days_length)
    } else {
      values.end_date_time = null
    }
  }

  return (
    <>
      <PageBody>
        <SettingsButtons>
          <MoreInfoButton href={PIP_PLAYBOOK}>Open PIP Playbook</MoreInfoButton>
        </SettingsButtons>
        <Box mt="s-24">
          <FormPreview data={employee}>
            <Group>
              <FormPreview.Item
                field="specialisation.name"
                title="Role (Specialisation)"
              />
              <FormPreview.Item field="seniority" title="Seniority (Level)" />
              <FormPreview.Item field="team" title="Team" />
              <FormPreview.Item field="line_manager.name" title="Line Manager" />
              <FormPreview.Item field="location" title="Location" />
            </Group>
          </FormPreview>
        </Box>
        <AutoStepper>
          <NewStepperTitle title="General info" />
          <InputGroup>
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                name="start_date_time"
                label="PIP start date"
                onAfterChange={onStarDateTimeAfterChange}
                required
              />
              <LapeDatePickerInput label="PIP end date" name="end_date_time" required />
            </InputGroup>
            <LapeNewInput
              disabled={!(canManagePIPCycle || canManagePIPCommitteeDecision)}
              label="Number of Checkpoints"
              name="review_checkpoints_number"
              required
              type="number"
            />
            <LapeRadioSelectInput
              disabled={!(canManagePIPCycle || canManagePIPCommitteeDecision)}
              label="HR Manager"
              name="hr_manager"
              selector={selectorKeys.pip_hr_managers}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
            id: employee.id,
          })}
          successText="PIP sent to LM for goal setting"
          useValidator
        />
      </PageActions>
    </>
  )
}

const PipSettingsForm = ({ employee }: PipFormProps) => {
  return (
    <Form<PipFormInterface> api={pipRequestsNew}>
      <PipSettings employee={employee} />
    </Form>
  )
}

export default connect(PipSettingsForm)
