import React, { forwardRef } from 'react'
import styled, { keyframes } from 'styled-components'
import { Box, Skeleton, SkeletonProps, Token } from '@revolut/ui-kit'
import { RowHeight } from '@src/interfaces/table'
import { rowWrapperMinHeight } from '@components/TableV2/common'

const moveGradient = keyframes`
  100% {
    transform: translateX(60%);
  }
`

const RowSkeleton = styled(Skeleton)<Pick<SkeletonProps, 'duration' | 'delay'>>`
  overflow: hidden;
  background: ${Token.color.groupedBackground};

  :first-child {
    margin-top: -1px;
  }

  &::after {
    transform: translateX(-60%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 245, 255, 0) 40%,
      ${Token.color.shimmer} 50%,
      rgba(255, 255, 255, 0) 60%
    );
    animation-name: ${moveGradient};
  }
`

interface TableBodySkeletonProps {
  containerWidth?: number
  rowHeight: RowHeight
}

export const TableBodySkeleton = forwardRef<HTMLDivElement, TableBodySkeletonProps>(
  ({ rowHeight, containerWidth }, ref) => {
    return (
      <Box
        ref={ref}
        role="presentation"
        data-testid="table-body-skeleton"
        width={containerWidth}
      >
        <RowSkeleton radius={Token.radius.none} height={rowWrapperMinHeight[rowHeight]} />
        <RowSkeleton
          radius={Token.radius.none}
          height={rowWrapperMinHeight[rowHeight]}
          delay={200}
        />
        <RowSkeleton
          radius={Token.radius.none}
          height={rowWrapperMinHeight[rowHeight]}
          delay={400}
        />
      </Box>
    )
  },
)
