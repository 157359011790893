import React from 'react'
import { TableWidget } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import {
  benefitBenefitLinkColumn,
  benefitConfluenceLinkColumn,
  benefitDescriptionColumn,
  benefitProviderLegacyColumn,
  benefitTypeColumn,
} from '@src/constants/columns/rewards'
import AdjustableTable from '@components/Table/AdjustableTable'
import {
  getEmployeeRewardsBenefitsLegacy,
  getEmployeeRewardsBenefitsStatsLegacy,
} from '@src/api/rewards'
import Stat from '@components/Stat/Stat'
import { BenefitsInterfaceLegacy, BenefitsStatsLegacy } from '@src/interfaces/rewards'
import { TableNames } from '@src/constants/table'

const ROW: RowInterface<BenefitsInterfaceLegacy> = {
  cells: [
    {
      ...benefitTypeColumn,
      width: 80,
    },
    {
      ...benefitProviderLegacyColumn,
      width: 80,
    },
    {
      ...benefitDescriptionColumn,
      width: 400,
    },
    {
      ...benefitConfluenceLinkColumn,
      width: 60,
    },
    {
      ...benefitBenefitLinkColumn,
      width: 120,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

export const Benefits = ({ data }: Props) => {
  const table = useTable<BenefitsInterfaceLegacy, BenefitsStatsLegacy>({
    getItems: getEmployeeRewardsBenefitsLegacy(data.id),
    getStats: getEmployeeRewardsBenefitsStatsLegacy(data.id),
  })

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat
          label="Benefits available"
          val={table.stats?.benefits_available}
          mr="s-32"
        />
      </TableWidget.Info>
      <TableWidget.Table>
        <AdjustableTable
          name={TableNames.Benefits}
          useWindowScroll
          dataType="Benefits"
          row={ROW}
          {...table}
          noDataMessage="Benefits will appear here"
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
