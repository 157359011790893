import React, { useEffect, useState } from 'react'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { Box, Cell, StatusWidget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { getPayrollCyclePaymentsUploadProgress } from '@src/api/importData'
import { PayCyclePaymentsBulkUploadStatus } from '@src/interfaces/payroll'

const INTERVAL_TIMER = 5000

export const Status = () => {
  const { id, sessionId } = useParams<{ id: string; sessionId: string }>()
  const [uploadStatusId, setUploadStatusId] =
    useState<PayCyclePaymentsBulkUploadStatus>('in_progress')

  useEffect(() => {
    const interval = setInterval(async () => {
      const { data } = await getPayrollCyclePaymentsUploadProgress(id, sessionId)
      const statusId = data?.status?.id

      if (statusId !== uploadStatusId) {
        setUploadStatusId(data?.status?.id)
      }
      if (statusId && statusId !== 'in_progress') {
        clearInterval(interval)
      }
    }, INTERVAL_TIMER)

    return () => clearInterval(interval)
  }, [])

  const goToDashboard = () => {
    navigateTo(pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, { id }))
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Import data"
        subtitle="Payments"
        backUrl={ROUTES.PEOPLE.PAYROLL}
        mb="28px"
      />
      <PageBody>
        <Cell>
          {uploadStatusId === 'in_progress' && (
            <Box mx="auto">
              <StatusWidget>
                <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D007.png" />
                <StatusWidget.Title>Task in progress</StatusWidget.Title>
                <StatusWidget.Description>
                  Import of data will be handled as the background job.
                </StatusWidget.Description>
                <StatusWidget.Action onClick={goToDashboard}>
                  Go back to pay cycle dashboard
                </StatusWidget.Action>
              </StatusWidget>
            </Box>
          )}
          {uploadStatusId === 'completed' && (
            <Box mx="auto">
              <StatusWidget>
                <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D151A.png" />
                <StatusWidget.Title>Upload successful</StatusWidget.Title>
                <StatusWidget.Description>
                  Payments upload is completed
                </StatusWidget.Description>
                <StatusWidget.Action onClick={goToDashboard}>
                  Go back to pay cycle dashboard
                </StatusWidget.Action>
              </StatusWidget>
            </Box>
          )}
          {uploadStatusId === 'failed' && (
            <Box mx="auto">
              <StatusWidget>
                <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D083.png" />
                <StatusWidget.Title>Upload failed</StatusWidget.Title>
                <StatusWidget.Description>Something went wrong</StatusWidget.Description>
                <StatusWidget.Action onClick={goToDashboard}>
                  Go back to pay cycle dashboard
                </StatusWidget.Action>
              </StatusWidget>
            </Box>
          )}
        </Cell>
      </PageBody>
    </PageWrapper>
  )
}
