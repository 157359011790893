import {
  AvatarType,
  ContractorInterface,
  EmployeeInterface,
  EmployeeOptionInterface,
  IdStatuses,
} from '@src/interfaces/employees'
import { localDateToUtc } from '@src/utils/timezones'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { notReachable } from '@src/utils/notReachable'

export const getInitials = (fullName: string) =>
  fullName.split(' ').reduce((acc, chunk, index, arr) => {
    if (index === 0 || index === arr.length - 1) {
      return acc + (chunk[0] || '')
    }
    return acc
  }, '')

export const getProbationEndDate = (startDateTimestamp: string, months: number) => {
  const startDate = new Date(startDateTimestamp)
  const endDate = new Date()
  endDate.setMonth(startDate.getMonth() + months)
  endDate.setDate(startDate.getDate() - 1)

  return localDateToUtc(endDate)
}

export const updateProbationEndDate = (
  startDateTimestamp: string,
  months: number,
  values:
    | EmployeeInterface
    | ContractorInterface
    | Pick<EmployeeInterface, 'end_of_probation_date_time'>,
) => {
  values.end_of_probation_date_time = getProbationEndDate(startDateTimestamp, months)
}

export const getAvatarUrl = (avatar?: AvatarType, size: 'sm' | 'md' = 'sm') => {
  if (!avatar) {
    return undefined
  }
  if (typeof avatar === 'string') {
    return avatar
  }
  return size === 'sm' ? avatar.image_72 : avatar.image_192
}

export const transformEmployeeToOption = (
  employee: EmployeeOptionInterface,
): OptionInterface => {
  let status: Statuses | undefined

  switch (employee.status) {
    case IdStatuses.hired:
      status = Statuses.hired
      break

    case IdStatuses.active:
      status = Statuses.active
      break

    case IdStatuses.inactive:
      status = Statuses.inactive
      break

    case IdStatuses.failed:
      status = Statuses.failed
      break

    case IdStatuses.pending:
      status = Statuses.pending
      break

    case IdStatuses.onboarding:
      status = Statuses.onboarding
      break

    case IdStatuses.terminated:
      status = Statuses.terminated
      break

    case IdStatuses.cancelled:
      status = Statuses.cancelled
      break

    case IdStatuses.completed:
      status = Statuses.completed
      break

    case IdStatuses.refused:
    case IdStatuses.not_hired:
      throw Error(`[transformEmployeeToOption] unexpected status ${employee.status}`)

    case undefined:
      status = undefined
      break

    default:
      throw notReachable(employee.status)
  }

  return {
    ...employee,
    avatar: getAvatarUrl(employee.avatar),
    name: employee.full_name || employee.display_name || employee.name || '',
    status,
  }
}

export const transformOptionToEmployee = (
  option: OptionInterface,
): EmployeeOptionInterface => {
  let status: IdStatuses | undefined

  let optionStatus = typeof option.status === 'object' ? option.status.id : option.status
  switch (optionStatus) {
    case Statuses.hired:
      status = IdStatuses.hired
      break

    case Statuses.active:
      status = IdStatuses.active
      break

    case Statuses.inactive:
      status = IdStatuses.inactive
      break

    case Statuses.failed:
      status = IdStatuses.failed
      break

    case Statuses.pending:
      status = IdStatuses.pending
      break

    case Statuses.onboarding:
      status = IdStatuses.onboarding
      break

    case Statuses.terminated:
      status = IdStatuses.terminated
      break

    case Statuses.canceled:
    case Statuses.cancelled:
      status = IdStatuses.cancelled
      break

    case Statuses.completed:
      status = IdStatuses.completed
      break

    case Statuses.delayed:
    case Statuses.hidden_draft:
    case Statuses.opened:
    case Statuses.open:
    case Statuses.running:
    case Statuses.template:
    case Statuses.complete:
    case Statuses.completed_late:
    case Statuses.in_review:
    case Statuses.approved:
    case Statuses.accepted:
    case Statuses.preapproved:
    case Statuses.pending_reapproval:
    case Statuses.funcapproved:
    case Statuses.applied:
    case Statuses.pending_approval:
    case Statuses.pending_completion:
    case Statuses.scheduled:
    case Statuses.revoked:
    case Statuses.rejected:
    case Statuses.ongoing:
    case Statuses.planned:
    case Statuses.closed:
    case Statuses.archived:
    case Statuses.expired:
    case Statuses.extended:
    case Statuses.draft:
    case Statuses.skipped:
    case Statuses.no_account:
    case Statuses.linked:
    case Statuses.progress:
    case Statuses.created:
    case Statuses.unassigned:
    case Statuses.future:
    case Statuses.deactivated:
    case Statuses.requires_changes:
    case Statuses.on_track:
    case Statuses.at_risk:
    case Statuses.on_hold:
    case Statuses.recapproved:
      throw Error(`[transformOptionToEmployee] unexpected status ${option.status}`)

    case undefined:
      status = undefined
      break

    default:
      throw notReachable(optionStatus)
  }

  if (typeof option.id !== 'number') {
    throw Error(`[transformOptionToEmployee] id should be a number, but got ${option.id}`)
  }

  return {
    ...option,
    id: option.id,
    status,
  }
}
