import { AxiosPromise } from 'axios'

import { LabelInterface, ManualLabel } from '@src/interfaces/labels'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '.'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getEmployeeLabels = (
  id: string | number,
  { sortBy, filters, page }: FetchDataQueryInterface,
): AxiosPromise<GetRequestInterface<LabelInterface>> =>
  api.get(`${API.EMPLOYEES}/${id}/employeeLabels`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const labelsRequests: RequestInterfaceNew<ManualLabel> = {
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.LABEL}/${id}`),
  update: async (data, { id, employeeId }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}${API.LABEL}/${id}`, data),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}${API.LABEL}`, data),
}
