import { ReviewCycleStage } from '@src/interfaces/reviewCycles'
import {
  BaseStatsItemProps,
  GoalsStatsItemProps,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/Stats/StatsItems'
import pluralize from 'pluralize'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { Token } from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import { goalsOwnerToEntityNameMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/constants'
import { CalibrationStat } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/SwitchStage/hooks/useCalibrationStats'
import { formatPercentage } from '@src/utils/format'

export const makePublishItemProps = (
  stage: ReviewCycleStage.ManagersPublish | ReviewCycleStage.EmployeesPublish,
  totalCount: number,
  completedCount: number,
): BaseStatsItemProps => {
  if (totalCount === 0) {
    return {
      title: 'There are no final grades assigned for current cycle',
      iconName: 'Profile',
    }
  }

  if (stage === ReviewCycleStage.ManagersPublish) {
    return {
      title: pluralize('final grade', completedCount, true),
      description: `${completedCount}/${totalCount} ${pluralize(
        'final grade',
        totalCount,
      )} ${pluralize('have', completedCount)} been assigned`,
      iconName: 'DocumentsPair',
    }
  }

  return {
    title: pluralize('final grade', completedCount, true),
    description: 'Assigned in this cycle',
    iconName: 'Profile',
  }
}

export const makeGoalsStatsItemProps = (
  goalsOwner: GoalsOwner,
  totalCount: number,
): GoalsStatsItemProps => ({
  color: totalCount > 0 ? Token.color.success : Token.color.warning,
  iconName: goalsOwner === GoalsOwner.Team ? 'People' : 'Bank',
  statusIconName: totalCount > 0 ? 'Check' : 'Cross',
  title: `${capitalize(goalsOwnerToEntityNameMap[goalsOwner])} goals`,
  description:
    totalCount > 0
      ? `${totalCount} ${pluralize(
          goalsOwnerToEntityNameMap[goalsOwner],
          totalCount,
        )} with goals`
      : 'No goals created',
})

export const makeCalibrationStatsItemProps = ({
  name,
  targetDistribution,
  actualDistribution,
  employeesCount,
}: CalibrationStat): BaseStatsItemProps => ({
  title: `${name} ${
    actualDistribution !== null ? formatPercentage(actualDistribution) : 'N/A'
  } ${employeesCount !== null ? `(${employeesCount})` : ''}`,
  description: `Target ${formatPercentage(targetDistribution)}`,
  iconName: 'Profile',
  color:
    actualDistribution !== null && actualDistribution < targetDistribution
      ? Token.color.warning
      : Token.color.success,
  statusIconName:
    actualDistribution !== null && actualDistribution < targetDistribution
      ? 'Cross'
      : 'Check',
})

interface ReviewStatsItemTitleProps {
  completedTitle: string
  notCompletedTitle: string
  noReviewsTitle: string
}

export const makeReviewStatsItemTitleProps = (
  completedCount: number,
  notCompletedCount: number,
): ReviewStatsItemTitleProps => {
  const completedTitle = `${completedCount} ${pluralize(
    'review',
    completedCount,
  )} completed`
  const notCompletedTitle = `${notCompletedCount} ${pluralize(
    'review',
    notCompletedCount,
  )} not completed`
  const noReviewsTitle = 'There are no reviews for current cycle'

  return {
    completedTitle,
    notCompletedTitle,
    noReviewsTitle,
  }
}
