import React from 'react'
import { Avatar, IconName, Item, Text } from '@revolut/ui-kit'
import { DocumentsTemplateTypeId } from '@src/interfaces/documentsTemplates'
import {
  OnboardingStatus,
  OnboardingTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { formatDateTime } from '@src/utils/format'
import { getTemplateIcon, getTemplateStatusColor } from '../utils'

type Props = {
  handleClick: () => void
  status: OnboardingStatus
  template: OnboardingTemplateInterface
}

const typeToIcon: Record<DocumentsTemplateTypeId, IconName> = {
  esignature: 'Pencil',
  generate: 'Lightning',
  request: 'Document',
}

export const TemplateItem = ({ handleClick, status, template }: Props) => {
  return (
    <Item onClick={handleClick} use="button">
      <Item.Avatar>
        <Avatar useIcon={typeToIcon[template.document_template.template_type.id]}>
          {status !== 'not_started' && (
            <Avatar.Badge
              bg={getTemplateStatusColor(template.status.id)}
              useIcon={getTemplateIcon(template.status.id)}
            />
          )}
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{template.document_template.name}</Item.Title>
        {status !== 'not_started' && (
          <Item.Description>
            <Text color={getTemplateStatusColor(template.status.id)}>
              {`${template.status.name} `}
            </Text>
            <Text>{formatDateTime(template.update_date_time)}</Text>
          </Item.Description>
        )}
      </Item.Content>
    </Item>
  )
}
