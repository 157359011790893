import React from 'react'
import {
  ColorSelector,
  RadioGroup,
  Icon,
  Token,
  Widget,
  IconName,
  Grid,
} from '@revolut/ui-kit'

const iconList: IconName[] = [
  'Bank',
  'Profile',
  'People',
  'RepairTool',
  'Laptop',
  'Lightning',
  'Coffee',
  'IndustrialGear',
  'BarChart',
  'Rocket',
  'Document',
  'Travel',
  'CreditBag',
  'Pencil',
  'Controls',
  'Palette',
]

interface IconSelectProps {
  onChange: (iconName: IconName | null) => void
  selectedIcon: IconName | null
}

export const IconSelect = ({ onChange, selectedIcon }: IconSelectProps) => {
  return (
    <Widget p="s-16">
      <RadioGroup<IconName> onChange={onChange} value={selectedIcon}>
        {group => (
          <Grid columns={{ all: 4 }} css={{ justifyItems: 'center' }} gap="s-12">
            {iconList.map(icon => (
              <ColorSelector
                bg={Token.color.actionBackground}
                {...group.getInputProps({ value: icon })}
                key={icon}
                size={56}
                aria-label={icon}
              >
                <Icon name={icon} size={24} color={Token.color.accent} />
              </ColorSelector>
            ))}
          </Grid>
        )}
      </RadioGroup>
    </Widget>
  )
}
