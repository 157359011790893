import React from 'react'
import { ReviewCategory } from '@src/interfaces/performance'
import { PerformanceViewLayout } from '@src/pages/Forms/EmployeePerformanceViewLayout/PerformanceViewLayout'
import { ScorecardContentView } from '@src/pages/Forms/EmployeePerformanceViewLayout/ScorecardContentView'

export const UpwardsViewLayout = () => {
  return (
    <PerformanceViewLayout
      category={ReviewCategory.Upwards}
      content={<ScorecardContentView />}
    />
  )
}
