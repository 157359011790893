import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { APPLICATIONS } from '@src/constants/hub'
import HubApp from '@src/features/HubApp/HubApp'
import { ROUTES } from '@src/constants/routes'
import { EngagementDriver } from './Driver'
import { EngagementQuestion } from './Question'
import { EngagementSurvey } from './Survey'

const Engagement = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.ENGAGEMENT.QUESTION.ANY}>
        <EngagementQuestion />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.DRIVER.ANY}>
        <EngagementDriver />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.SURVEY.ANY}>
        <EngagementSurvey />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.ANY}>
        <HubApp app={APPLICATIONS.engagement} />
      </Route>
    </Switch>
  )
}

export default Engagement
