import { useGetOrganisationSettings } from '@src/api/settings'
import { TalentType } from '@src/interfaces/talent/talent'

export const useGetEnableDownloadSetting = (type: TalentType) => {
  const { data: organisationSettings } = useGetOrganisationSettings()

  const isDepartmentEntity =
    type === TalentType.Company ||
    type === TalentType.Department ||
    type === TalentType.Team
  const isFunctionEntity =
    type === TalentType.Function ||
    type === TalentType.Specialisation ||
    type === TalentType.Role

  return isDepartmentEntity
    ? organisationSettings?.enable_download_actions_for_department_data
    : isFunctionEntity
    ? organisationSettings?.enable_download_actions_for_function_data
    : true
}
