import { TabBar } from '@revolut/ui-kit'
import { Link as RouterLink } from 'react-router-dom'
import React, { forwardRef } from 'react'
import { TabProps } from '@revolut/ui-kit'

interface Props extends Omit<TabProps, 'to'> {
  to?: object | string
  exact?: boolean
}

const TabBarLink = forwardRef<HTMLButtonElement | null, Props>(
  ({ to, exact, ...rest }, ref) => {
    return (
      <TabBar.Item
        ref={ref}
        use={RouterLink}
        // @ts-expect-error object works fine here, but UI kit expects string
        to={to}
        {...rest}
      />
    )
  },
)

export default TabBarLink
