import React, { useState } from 'react'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { Text } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const StopRunConfirmation = ({
  surveyId,
  roundId,
  isOpen,
  onClose,
  refetch,
  actionFn,
}: {
  surveyId: EngagementSurveyInterface['id']
  roundId?: number
  isOpen: boolean
  actionFn: (survey: EngagementSurveyInterface['id'], round: number) => void
  onClose: VoidFunction
  refetch: VoidFunction
}) => {
  const showStatusPopup = useShowStatusPopup()

  const [isPending, setIsPending] = useState(false)
  return (
    <ConfirmationDialog
      open={isOpen}
      variant="compact"
      bodyVariant="normal"
      onClose={onClose}
      onConfirm={async () => {
        setIsPending(true)
        try {
          if (!roundId) {
            throw new Error('Round id missing')
          }
          await actionFn(surveyId, roundId)
        } catch (error) {
          showStatusPopup({
            status: 'error',
            title: 'Failed to close survey run',
            description: getStringMessageFromError(error),
          })
        } finally {
          setIsPending(false)
          onClose()
          refetch()
        }
      }}
      onReject={onClose}
      loading={isPending}
      label="Stop the current run?"
      body={
        <Text textAlign="center">
          This will only stop the current run of the survey. Scheduled runs in the future
          will not be affected
        </Text>
      }
      yesMessage="Yes, stop survey"
      noMessage="No, don't stop"
    />
  )
}
