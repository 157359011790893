import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { attendanceTrackingSettings } from '@src/api/settings'
import { Attendance } from './Attendance'

const routes = [
  {
    title: 'Attendance Settings',
    description: 'Settings for the Attendance app',
    path: ROUTES.SETTINGS.ATTENDANCE,
    url: ROUTES.SETTINGS.ATTENDANCE,
    canView: [PermissionTypes.ViewAttendanceTrackingPreferences],
    component: Attendance,
  },
]

export const AttendanceSettings = () => {
  return (
    <SettingsForm
      routes={routes}
      api={attendanceTrackingSettings}
      multiInputFields={['kpi_measuring_units']}
    />
  )
}
