import { Statuses } from '@src/interfaces'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button } from '@revolut/ui-kit'
import { goBack } from '@src/actions/RouterActions'
import { successNotification } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { TabsInterface } from '@src/interfaces/data'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { getCleanValuesBeforeSave } from '@src/utils/performance'

type Props = {
  tabs: TabsInterface<ReviewScorecardInterface>[]
  type: 'employee-performance' | 'probation-review' | 'upwards-review' | 'pip-review'
}

const SaveDraftButton = ({ tabs, type }: Props) => {
  const { employeeId } = useParams<{ employeeId: any }>()
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submit, isSubmitting } = form
  const location = useLocation()

  if (
    values.status !== Statuses.draft &&
    values.status !== Statuses.pending &&
    values.status !== Statuses.rejected
  ) {
    return null
  }

  const tab = tabs.find(t => matchPath(location.pathname, t.path!))

  const getBackUrl = () => {
    switch (type) {
      case 'upwards-review':
        return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.UPWARDS, {
          id: employeeId,
        })
      default:
        return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
          id: employeeId,
        })
    }
  }

  const onSaveDraft = () => {
    form.values = getCleanValuesBeforeSave(values)
    form.values.status = Statuses.draft

    return submit().then(result => {
      goBack(getBackUrl())
      if (tab) {
        successNotification(
          'Saved to drafts',
          pathToUrl(tab.path!, {
            employeeId: result.reviewed_employee.id,
            id: result.id,
          }),
        )
      }
    })
  }

  return (
    <Button
      onClick={onSaveDraft}
      variant="secondary"
      pending={isSubmitting && values.status === Statuses.draft}
      disabled={isSubmitting}
      width={167}
      elevated
      data-testid="btn-save-draft"
    >
      Save to Drafts
    </Button>
  )
}

export default connect(SaveDraftButton)
