import React, { useEffect, useState } from 'react'

import { KpiTypeOptions } from '@src/interfaces/KpiTemplates'
import { OptionInterface } from '@src/interfaces/selectors'
import { getSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import {
  ActionButton,
  Flex,
  Text,
  VStack,
  Widget,
  copyToClipboard,
  Token,
} from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'

interface Props {
  type: typeof KpiTypeOptions[number]
}

const typeToSelector = {
  employee_kpi: selectorKeys.kpi_template_employees_fields,
  team_kpi: selectorKeys.kpi_template_teams_fields,
  department_kpi: selectorKeys.kpi_template_department_fields,
} as const

const KPITemplateParameters = ({ type }: Props) => {
  const [parameters, setParameters] = useState<OptionInterface[]>([])
  useEffect(() => {
    const fetchSelector = async () => {
      const { data } = await getSelectors(typeToSelector[type.id])
      setParameters(data.options)
    }

    if (type) {
      fetchSelector()
    }
  }, [type])

  return (
    <Widget p="s-16">
      <Text
        use="p"
        fontSize="caption"
        lineHeight="22px"
        mb="s-16"
        fontWeight={500}
        color="grey-tone-50"
      >
        Parameters
      </Text>
      <VStack space="s-16">
        <Text variant="caption">
          {`You can use the below parameters in your SQL query with double curly brackets
              eg: SELECT time, value FROM employees WHERE employee_id={{employee.id}}`}
        </Text>
        <Flex flexWrap="wrap">
          {parameters.map((param, i) => (
            <ActionButton
              mr="s-8"
              mb="s-8"
              size="xs"
              variant="primary"
              onClick={() => {
                copyToClipboard(`{{${param.name}}}`).then(() => {
                  pushNotification({
                    value: `{{${param.name}}} copied to clipboard`,
                    duration: SUCCESS_DEFAULT_DURATION,
                    type: NotificationTypes.success,
                  })
                })
              }}
              useEndIcon={Copy}
              key={i}
              style={{
                backgroundColor: Token.color.greyTone5,
                color: Token.color.foreground,
              }}
            >
              {param.name}
            </ActionButton>
          ))}
        </Flex>
      </VStack>
    </Widget>
  )
}

export default KPITemplateParameters
