import React, { useCallback } from 'react'
import { DropdownController, Toolbox, Dropdown } from '@revolut/ui-kit'
import { LexicalEditor, $isRangeSelection, $getSelection } from 'lexical'
import { $patchStyleText } from '@lexical/selection'

interface FontSizePluginProps {
  editor: LexicalEditor
  value: string
}

const FONT_SIZE_OPTIONS = [
  '10px',
  '11px',
  '12px',
  '13px',
  '14px',
  '15px',
  '16px',
  '17px',
  '18px',
  '19px',
  '20px',
]

export const FontSizePlugin = ({ editor, value }: FontSizePluginProps) => {
  const handleClick = useCallback(
    (option: string) => {
      editor.update(() => {
        const selection = $getSelection()
        if ($isRangeSelection(selection)) {
          $patchStyleText(selection, {
            'font-size': option,
          })
        }
      })
    },
    [editor],
  )

  return (
    <DropdownController>
      {dropdown => (
        <>
          <Toolbox.Action
            aria-label="Font size"
            useEndIcon="16/ChevronDownSmall"
            {...dropdown.getAnchorProps()}
          >
            {value}
          </Toolbox.Action>
          <Dropdown autoClose {...dropdown.getTargetProps()}>
            {FONT_SIZE_OPTIONS.map(fontSize => (
              <Dropdown.Item
                use="button"
                color="foreground"
                onClick={() => handleClick(fontSize)}
                key={fontSize}
                aria-pressed={fontSize === value}
              >
                {fontSize}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </>
      )}
    </DropdownController>
  )
}
