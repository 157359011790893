import { Link } from '@revolut/ui-kit'
import React from 'react'

interface LinkifyProps {
  text: string
}

export const Linkify = ({ text }: LinkifyProps) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g

  const renderTextWithLinks = (words: string) => {
    const parts = words.split(urlRegex)
    return parts.map(part => {
      if (part.match(urlRegex)) {
        return (
          <Link href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </Link>
        )
      }
      return part
    })
  }

  return <>{renderTextWithLinks(text)}</>
}
