import { Flex, VStack } from '@revolut/ui-kit'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import React from 'react'

export const LoadingState = () => {
  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100%" gap="s-24">
      <VStack space="s-16" mt="s-16">
        <InputsSkeleton />
        <InputsSkeleton />
      </VStack>
    </Flex>
  )
}
