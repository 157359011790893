import React, { useMemo } from 'react'
import { Box, Color, Text } from '@revolut/ui-kit'
import { PerformanceSelector } from '@src/interfaces/performance'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'

type Props = {
  selectedPeriod?: PerformanceSelector
  performanceSelector?: PerformanceSelector[]
  onChange: (selector?: PerformanceSelector) => void
}

const PerformanceCycleSelect = ({
  selectedPeriod,
  performanceSelector,
  onChange,
}: Props) => {
  const options = useMemo(
    () =>
      performanceSelector?.map(item => ({
        label: item.name,
        value: {
          id: String(item.id),
        },
      })) || [],
    [performanceSelector, selectedPeriod],
  )

  return (
    <Box width="100%">
      <RadioSelectInput
        value={{
          id: String(selectedPeriod?.id),
        }}
        options={options}
        onChange={val => {
          if (val) {
            onChange(
              performanceSelector?.find(item => String(item.id) === String(val.id)),
            )
          }
        }}
        renderInput={(open, setOpen, ref) => (
          <DropdownStatAction open={open} onClick={() => setOpen(!open)} ref={ref}>
            {selectedPeriod?.name || 'Select cycle'}
          </DropdownStatAction>
        )}
      />
      <Text color={Color.GREY_TONE_50} use="div">
        Cycle
      </Text>
    </Box>
  )
}

export default PerformanceCycleSelect
