import React from 'react'
import { Route, Switch } from 'react-router-dom'

import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS } from '@src/constants/hub'
import { ROUTES } from '@src/constants/routes'

import { DocumentsBulkRequest } from './BulkRequest'
import { DocumentsBulkRequestsTable } from '@src/apps/People/Documents/BulkRequest/BulkRequestsTable'
import { DocumentsTemplate } from './Template'
import { DocumentTemplatesTable } from '@src/pages/People/PeopleSubTabs/Documents/Templates'
import { DocumentCategories } from '@src/pages/People/PeopleSubTabs/Documents/Categories'
import { DocumentsCollections } from '@src/pages/People/PeopleSubTabs/Documents/Collections'
import { DocumentRules } from '@src/pages/People/PeopleSubTabs/Documents/Rules'

const Documents = () => {
  return (
    <Switch>
      <Route
        path={[
          ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW,
          ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.ANY,
        ]}
      >
        <DocumentsBulkRequest />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}>
        <DocumentsBulkRequestsTable />
      </Route>
      <Route
        path={[
          ROUTES.APPS.DOCUMENTS.TEMPLATES.EDIT.ANY,
          ROUTES.APPS.DOCUMENTS.E_SIGNATURE_TEMPLATES.EDIT.ANY,
        ]}
      >
        <DocumentsTemplate />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.TEMPLATES.LIST}>
        <DocumentTemplatesTable />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.COLLECTIONS}>
        <DocumentsCollections />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.CATEGORIES}>
        <DocumentCategories />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.RULES}>
        <DocumentRules />
      </Route>

      <Route path={ROUTES.APPS.DOCUMENTS.ANY}>
        <HubApp app={APPLICATIONS.documents} />
      </Route>
    </Switch>
  )
}

export default Documents
