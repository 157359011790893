import React, { useState } from 'react'
import { Avatar, Box, Group, Item, MoreBar, Color } from '@revolut/ui-kit'
import { Check, ExclamationMarkSign, ArrowExchange, Pencil } from '@revolut/icons'
import capitalize from 'lodash/capitalize'

import { PageBody } from '@src/components/Page/PageBody'
import {
  CompleteStageButton,
  ProcessStageStepItem,
  SLAProgress,
  StartDateSidebar,
  TabProps,
} from './common'
import { OnboardingTimelineEmployee, ProcessStageSteps } from '@src/interfaces/onboarding'
import SideBar from '@src/components/SideBar/SideBar'
import {
  completeOnboardingStepAction,
  confirmOnboardingStep,
} from '@src/api/onboardingEmployees'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { PageActions } from '@src/components/Page/PageActions'
import { useGetEmployeeSettings } from '@src/api/settings'
import { getAvatarUrl } from '@src/utils/employees'

export const WorkStage = (props: TabProps) => {
  const { stage, data, openedSidebar, setOpenedSidebar } = props
  const { data: settings } = useGetEmployeeSettings()

  const [confirmPositionInfoPending, setConfirmPositionInfoPending] = useState(false)
  const [confirmOrganisationInfoPending, setConfirmOrganisationInfoPending] =
    useState(false)

  const { process_stage_steps } = stage

  const confirmDateStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ConfirmAndRecordStartDate,
  )!
  const reviewPositionInfoStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ReviewPositionInformation,
  )!
  const reviewOrganisationInfoStep = process_stage_steps.find(
    s => s.step.id === ProcessStageSteps.ReviewOrganisationInformation,
  )!

  const isInternal = props.data.employee.employee_type === 'internal'
  const allStepsCompleted = process_stage_steps.every(
    step => step.status.id === 'completed',
  )

  return (
    <>
      <PageBody mt="s-32">
        <SLAProgress
          elapsedSla={stage.sla}
          sla={stage.stage.sla_in_hours}
          caption="Confirm start date with candidate and review work related information."
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        <Group>
          <ProcessStageStepItem
            step={confirmDateStep}
            onClick={() => setOpenedSidebar('confirm-start-date')}
          />
          <ProcessStageStepItem
            step={reviewPositionInfoStep}
            onClick={() => setOpenedSidebar('review-position-info')}
          />
          <ProcessStageStepItem
            step={reviewOrganisationInfoStep}
            onClick={() => setOpenedSidebar('review-organisation-info')}
          />
        </Group>
      </PageBody>

      <PageActions>
        <CompleteStageButton disabled={!allStepsCompleted} {...props} />
      </PageActions>

      <StartDateSidebar
        open={openedSidebar === 'confirm-start-date'}
        onClose={() => setOpenedSidebar(null)}
        step={confirmDateStep}
        data={data}
        updateStep={props.updateStep}
        candidateId={data.employee.candidate_id}
      />

      <SideBar
        title="Position"
        isOpen={openedSidebar === 'review-position-info'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            onClick={() => {
              setConfirmPositionInfoPending(true)
              confirmOnboardingStep(props.data.employee.id, reviewPositionInfoStep.id)
                .then(response => {
                  props.updateStep(response.data.process)
                  setOpenedSidebar(null)
                })
                .finally(() => setConfirmPositionInfoPending(false))
            }}
            variant="accent"
            disabled={reviewPositionInfoStep.status.id === 'completed'}
            pending={confirmPositionInfoPending}
          >
            Confirm
          </MoreBar.Action>
          <MoreBar.Action
            useIcon={Pencil}
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.POSITION, {
                  id: data.employee.id,
                }),
              )
            }
          >
            Edit
          </MoreBar.Action>
        </MoreBar>

        <Box mt="s-16">
          <FormPreview data={props.data?.employee}>
            <Group>
              <FormPreview.Item title="Contract type" field="contract_type.name" />
              <FormPreview.Item<OnboardingTimelineEmployee>
                title="Employee type"
                insert={employee => capitalize(employee.employee_type)}
              />
              <FormPreview.Item<OnboardingTimelineEmployee>
                title="Role (Specialisation)"
                field="specialisation.name"
                to={employee =>
                  pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                    id: employee.specialisation?.id,
                  })
                }
              />
              <FormPreview.Item
                title="Seniority"
                field="seniority.name"
                hidden={!isInternal}
              />
              <FormPreview.Item
                title="Job title"
                field="job_title"
                hidden={!isInternal}
              />
              <FormPreview.Item title="Location" field="location.name" />
              <FormPreview.Item title="Entity" field="entity.name" />
            </Group>
          </FormPreview>
        </Box>
      </SideBar>

      <SideBar
        title="Organisation"
        isOpen={openedSidebar === 'review-organisation-info'}
        onClose={() => setOpenedSidebar(null)}
        variant="wide"
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            onClick={() => {
              setConfirmOrganisationInfoPending(true)
              completeOnboardingStepAction(
                props.data.employee.id,
                reviewOrganisationInfoStep.id,
              )
                .then(response => {
                  props.updateStep(response.data.process)
                  setOpenedSidebar(null)
                })
                .finally(() => setConfirmOrganisationInfoPending(false))
            }}
            variant="accent"
            disabled={reviewOrganisationInfoStep.status.id === 'completed'}
            pending={confirmOrganisationInfoPending}
          >
            Confirm
          </MoreBar.Action>
          {!!settings?.enable_request_changes_organisation && (
            <MoreBar.Action
              useIcon={ArrowExchange}
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_CHANGE_REQUEST.NEW, {
                    employeeId: data.employee.id,
                    type: 'organisation',
                  }),
                )
              }
            >
              Request change
            </MoreBar.Action>
          )}
        </MoreBar>

        <Box mt="s-16">
          <Group>
            <OrganisationItem
              title="Line manager"
              employee={data.employee.line_manager}
            />
            <OrganisationItem
              title="Functional manager"
              employee={data.employee.quality_control}
            />
            {!!settings?.enable_buddy && (
              <OrganisationItem title="Buddy" employee={data.employee.mentor} />
            )}
          </Group>
        </Box>
      </SideBar>
    </>
  )
}

interface OrganisationItemProps {
  title: string
  employee: EmployeeOptionInterface | null
}

const OrganisationItem = ({ title, employee }: OrganisationItemProps) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar image={getAvatarUrl(employee?.avatar)}>
          <Avatar.Badge
            useIcon={employee ? Check : ExclamationMarkSign}
            bg={employee ? Color.GREEN : Color.ORANGE}
          />
        </Avatar>
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {employee ? <Item.Description>{employee.full_name}</Item.Description> : null}
      </Item.Content>
      {employee ? null : (
        <Item.Side>
          <Item.Title color={Color.ORANGE}>Not assigned</Item.Title>
        </Item.Side>
      )}
    </Item>
  )
}
