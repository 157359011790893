import React, { useEffect, useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ActionButton, Box, Group, Subheader } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import ApplicationFormEditSection from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationFormEditSection'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import isEmpty from 'lodash/isEmpty'
import { applicationFormSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import NewCheckbox from '@src/components/Inputs/NewCheckbox/NewCheckbox'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { generateRandomId } from '@src/utils/numbers'
import { DraggableApplicationFormSections } from '@src/pages/Forms/JobPosting/ApplicationForm/DraggableApplicationFormSections'

type SwitchWithOptionProps = {
  switchName: string
  optionName: string
  title: string
  description: string
  disabled: boolean
}

const SwitchWithOption = ({
  switchName,
  optionName,
  title,
  description,
  disabled,
}: SwitchWithOptionProps) => {
  const { values } = useLapeContext<ApplicationFormSettingsInterface>()
  return (
    <LapeNewSwitch
      name={switchName}
      itemTypeProps={{
        title,
        description: (
          <>
            {description}
            <Box mt="s-16">
              <NewCheckbox
                onClick={e => {
                  e.stopPropagation()
                  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                  values[optionName] = !values[optionName]
                }}
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                checked={values[optionName]}
                description="Make optional"
                disabled={disabled}
                data-testid={optionName}
              />
            </Box>
          </>
        ),
        onLabelClick: e => {
          e.preventDefault()
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          values[switchName] = !values[switchName]
        },
      }}
      disabled={disabled}
      data-testid={switchName}
    />
  )
}

type SectionTitleProps = {
  useStep?: boolean
  title: React.ReactNode
  subtitle?: React.ReactNode
}

const SectionTitle = ({ useStep, title, subtitle }: SectionTitleProps) => {
  if (useStep) {
    return <NewStepperTitle title={title} subtitle={subtitle} />
  }
  return (
    <>
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <Subheader variant="nested">
        <Subheader.Title>{subtitle}</Subheader.Title>
      </Subheader>
    </>
  )
}

type ApplicationFormBodyProps = {
  useSteps?: boolean
}

export const ApplicationFormBody = ({ useSteps }: ApplicationFormBodyProps) => {
  const { values, errors } = useLapeContext<ApplicationFormSettingsInterface>()
  const [openSectionIdx, setOpenSectionIdx] = useState<number | null>(null)
  const permissions = useSelector(selectPermissions)
  const disableAdd = !permissions.includes(PermissionTypes.AddApplicationFormPreferences)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeApplicationFormPreferences,
  )
  useEffect(() => {
    if (errors.global_sections) {
      setOpenSectionIdx(errors.global_sections.findIndex(item => !isEmpty(item)))
    }
  }, [errors])
  const handleAddSection = () => {
    if (!values.global_sections) {
      values.global_sections = [
        {
          title: 'New section',
          description: '',
          questions: [],
          sortId: generateRandomId(),
        },
      ]
    } else {
      values.global_sections.push({
        title: 'New section',
        description: '',
        questions: [],
        sortId: generateRandomId(),
      })
    }

    setOpenSectionIdx(values.global_sections.length - 1)
  }
  return (
    <>
      <AutoStepper>
        <SectionTitle
          useStep={useSteps}
          title="Standard application form questions"
          subtitle="Enable or disable predefined questions and set their optional or mandatory status to tailor the candidate experience. Essential fields can not be disabled."
        />
        <Group>
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Full name, Email, Country',
              description:
                'These fields are mandatory. They are required to create the candidate profile.',
            }}
            checked
            disabled
          />
          <SwitchWithOption
            switchName="enable_phone_number_field"
            optionName="is_phone_number_optional"
            title="Phone number"
            description="Ask for phone number in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_current_company_field"
            optionName="is_current_company_optional"
            title="Current company"
            description="Ask for current company in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_preferred_work_location_field"
            optionName="is_preferred_work_location_optional"
            title="Preferred work location"
            description="Ask for preferred work location in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_resume_field"
            optionName="is_resume_field_optional"
            title="CV / Resume"
            description="Ask to upload a CV / Resume file in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_portfolio_link_field"
            optionName="is_portfolio_link_optional"
            title="LinkedIn link / Portfolio"
            description="Ask for LinkedIn / Portfolio links in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_education_field"
            optionName="is_education_optional"
            title="Education"
            description="Ask for educational background in the application form"
            disabled={disableEdit}
          />
          <SwitchWithOption
            switchName="enable_work_experience_field"
            optionName="is_work_experience_optional"
            title="Work experience"
            description="Ask for work experience in the application form"
            disabled={disableEdit}
          />
        </Group>
        <SectionTitle
          useStep={useSteps}
          title={'Custom application form questions'}
          subtitle={
            <>
              Define questions that you want to ask during the application process for all
              job postings. Additional questions might still be added for each specific
              job posting through respective “Application questions” tab.
              <br />
              Disclaimer: Please be advised that adding more questions to the application
              process may result in a lower candidate conversion rate. It is recommended
              to carefully consider the number and relevance of questions to balance the
              need for information with the candidate experience.
            </>
          }
        />
        <Grid gap={16}>
          {Boolean(values.global_sections?.length) && (
            <DraggableApplicationFormSections
              sections={values.global_sections}
              onChange={sections => {
                values.global_sections = sections || []
              }}
              onEdit={setOpenSectionIdx}
              openSectionIdx={openSectionIdx}
            />
          )}
          <ActionButton useIcon="Plus" onClick={handleAddSection} disabled={disableAdd}>
            Add section
          </ActionButton>
        </Grid>
      </AutoStepper>
      <ApplicationFormEditSection
        idx={openSectionIdx}
        onClose={() => setOpenSectionIdx(null)}
      />
    </>
  )
}

const ApplicationFormPage = () => {
  return (
    <>
      <PageBody>
        <ApplicationFormBody useSteps />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Application form saved successfully"
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'Application form',
    path: ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS,
    url: ROUTES.SETTINGS.JOB_POSTING.APPLICATION_FORM_SETTINGS,
    canView: [PermissionTypes.ViewApplicationFormPreferences],
    component: ApplicationFormPage,
  },
]

export const ApplicationForm = () => {
  return <SettingsForm routes={routes} api={applicationFormSettings} />
}
