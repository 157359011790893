import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeMultiInput from '@components/Inputs/LapeFields/LapeMultiInput'
import { InputGroup } from '@revolut/ui-kit'

export const Locations = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeLocationPreferences)

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" subtitle="All Location settings" />

          <InputGroup>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable people partner fields',
                description:
                  'Allow you to define people partner, people specialist, and workspace manager for each location',
              }}
              name="enable_location_hr_fields"
              disabled={disableEdit}
            />
            <LapeMultiInput
              title="Labels"
              description="Allow you to define possible labels for locations"
              name="location_labels"
              valueFieldName={undefined}
              label="E.g. office"
              withCell
              disabled={disableEdit}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.LOCATION_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
