import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BottomSheet, Flex } from '@revolut/ui-kit'

import { selectFeedbackLoading, selectFeedbackOpen } from '@src/store/feedback/selectors'
import { closeFeedback, submitFeedback } from '@src/store/feedback/actions'
import { FeedbackCard } from './FeedbackCard'
import { PRODUCT_NAME } from '@src/constants/product'

export const Feedback = () => {
  const openFeedback = useSelector(selectFeedbackOpen)
  const loading = useSelector(selectFeedbackLoading)
  const dispatch = useDispatch()

  const [score, setScore] = useState<number | null>(null)
  const [text, setText] = useState<string>()

  const handleClose = () => {
    dispatch(closeFeedback())
  }

  const submit = () => {
    if (score) {
      dispatch(
        submitFeedback({
          score: score * 0.2,
          text,
          status: { id: 'completed' },
          topic: { id: 'platform' },
        }),
      )
    }
  }

  return (
    <BottomSheet open={!!openFeedback} onClose={handleClose}>
      <Flex flexDirection="column" alignItems="center" data-autofocus="true">
        <FeedbackCard
          title={`Rate your experience with ${PRODUCT_NAME}`}
          placeholder="Leave your review"
          score={score}
          onScoreChange={setScore}
          text={text}
          onTextChange={setText}
          onSubmit={submit}
          pending={loading}
        />
      </Flex>
    </BottomSheet>
  )
}
