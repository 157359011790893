import React from 'react'

import Form from '@src/features/Form/Form'
import UpwardsForm, { UpwardsFormProps } from './Upwards'

const UpwardsReview = (props: UpwardsFormProps) => {
  return (
    <Form validator={{}}>
      <UpwardsForm {...props} />
    </Form>
  )
}

export default UpwardsReview
