import { useTooltip, Icon, Token, Tooltip } from '@revolut/ui-kit'
import React from 'react'

interface MetricsItemTooltipProps {
  description?: string
}

export const MetricsItemTooltip = ({ description }: MetricsItemTooltipProps) => {
  const tooltip = useTooltip()

  if (!description) {
    return null
  }

  return (
    <>
      <Icon
        name="InfoOutline"
        size={16}
        color={Token.color.greyTone50}
        {...tooltip.getAnchorProps({ placement: 'bottom-left' })}
      />
      <Tooltip
        {...tooltip.getTargetProps()}
        maxWidth={400}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {description}
      </Tooltip>
    </>
  )
}
