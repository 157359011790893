import { Environments, env } from '@src/constants/api'

type ShareOptionsHookReturn = {
  payGroupId?: number
  isAvailable: boolean
}

const DEV_PAYGROUP_ID = 109
const PROD_PAYGROUP_ID = 121

export const findShareOptionsPayGroup = (): ShareOptionsHookReturn => {
  if (env === Environments.development || env === Environments.local) {
    return {
      payGroupId: DEV_PAYGROUP_ID,
      isAvailable: true,
    }
  }
  if (env === Environments.production) {
    return {
      payGroupId: PROD_PAYGROUP_ID,
      isAvailable: true,
    }
  }

  return {
    isAvailable: false,
  }
}
