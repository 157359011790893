import React, { useEffect, useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Badge, Box, Token } from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useQuery } from '@src/utils/queryParamsHooks'
import { documentsBulkRequestFormRequests } from '@src/api/documents'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { DocumentsBulkRequestInterface } from '@src/interfaces/documents'

import { Basics } from './Basics'
import { Templates } from './Templates'
import { Review } from './Review'
import {
  BulkRequestOptionsQueryParams,
  isESignatureTemplatesEnabled,
} from '../SelectBulkRequestOptionsPopup'

const tabsOrder = [
  {
    title: 'Basics',
    key: 'basics' as const,
    path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS,
    component: Basics,
  },
  {
    title: 'Templates',
    key: 'templates' as const,
    path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.TEMPLATES,
    component: Templates,
  },
  {
    title: 'Review',
    key: 'review' as const,
    path: ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW,
    component: Review,
  },
]

export const EditDocumentsBulkRequest = () => {
  const params = useParams<{ id?: string }>()
  const { query } = useQuery<BulkRequestOptionsQueryParams>()

  const [bulkRequestData, setBulkRequestData] = useState<DocumentsBulkRequestInterface>()

  useEffect(() => {
    // as parent component has different forms as descendants, we can't wrap everything into the bulk request
    // data context on top and have to refetch it on every tab change to sync header info with the actual changes
    if (params.id) {
      documentsBulkRequestFormRequests.get({ id: params.id }).then(res => {
        setBulkRequestData(res.data)
      })
    }
  }, [])

  const statusId = bulkRequestData?.status?.id
  const isRequestCreated = !!statusId

  const getTabProps = (path: string) => {
    if (path === ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS) {
      return {
        disabled: false,
        completed: isRequestCreated,
      }
    }
    if (path === ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.TEMPLATES) {
      return {
        disabled: !isRequestCreated || !bulkRequestData?.document_templates?.length,
        completed: isRequestCreated,
      }
    }
    if (path === ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.REVIEW) {
      return {
        disabled: !isRequestCreated,
        completed: statusId === 'submit_completed',
      }
    }
    return { disabled: false, completed: false }
  }

  const tabs = tabsOrder
    .filter(tab => {
      if (tab.key === 'templates') {
        return isESignatureTemplatesEnabled(query)
      }
      return true
    })
    .map((tab, idx) => {
      const { disabled, completed } = getTabProps(tab.path)

      return {
        ...tab,
        disabled,
        to: {
          pathname: pathToUrl(tab.path, params),
          search: new URLSearchParams(query).toString(),
        },
        preTitle: (
          <Box mr="s-8">
            <Badge size={16} bg={completed ? Token.color.green : Token.color.foreground}>
              {idx + 1}
            </Badge>
          </Box>
        ),
      }
    })

  return (
    <>
      <PageHeader
        title={isRequestCreated ? bulkRequestData!.name : 'Bulk request documents'}
        subtitle={isRequestCreated ? 'Bulk request documents' : undefined}
        backUrl={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.LIST}
        routerAction={PageHeaderRouterAction.goBack}
      />
      <TabBarNavigation tabs={tabs} mb="s-16" />
      <Switch>
        {tabs.map((tab, idx) => (
          <Route exact key={tab.key} path={tab.path}>
            <tab.component
              nextTabPath={tabs[idx + 1]?.path}
              bulkRequestData={bulkRequestData}
              updateBulkRequestData={setBulkRequestData}
            />
          </Route>
        ))}
      </Switch>
    </>
  )
}
