import React from 'react'
import { connect } from 'lape'
import { Text, TextProps, Radio, RadioProps } from '@revolut/ui-kit'

export interface NewRadioProps extends Omit<RadioProps, 'label'> {
  label?: React.ReactNode
  labelProps?: TextProps
  disabled?: boolean
  description?: React.ReactNode
}
const defaultProps: Partial<NewRadioProps> = {
  label: '',
  labelProps: {},
}

const NewRadio = (props: NewRadioProps) => {
  const { label, labelProps, description, ...radioProps } = props

  return (
    <Radio type="checkbox" {...radioProps}>
      <Radio.Label>
        <Text {...labelProps}>{label}</Text>
      </Radio.Label>
      {description && <Radio.Description>{description}</Radio.Description>}
    </Radio>
  )
}

NewRadio.defaultProps = defaultProps
export default connect(NewRadio)
