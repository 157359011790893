import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import EligibleEmployees from '@src/pages/Performance/SupportTool/EligibleEmployees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'

export const EligibleEmployeesList = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Eligible employees"
        subtitle="Manage employees eligible for selected review cycles"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS_CYCLES)}
      />
      <EligibleEmployees />
    </PageWrapper>
  )
}
