import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Subtab } from '@src/interfaces/tabPinning'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { usePermissionChecks } from '@components/PageWithTabs/usePermissionChecks'

interface TableWithSubtabsProps {
  subtabs: Subtab[]
}

export const TableWithSubtabs = ({ subtabs }: TableWithSubtabsProps) => {
  const { checkPermissions, isLoading } = usePermissionChecks()

  if (!subtabs || isLoading) {
    return null
  }

  const filteredTabs = subtabs.filter(subtab => checkPermissions(subtab))

  return (
    <>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={filteredTabs} />
      </Flex>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
        <InternalRedirect to={filteredTabs[0].path} />
      </Switch>
    </>
  )
}
