import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { isDev } from '@src/utils'
import IconButton from '../../components/ButtonIcon/IconButton'
import Icon from '../../components/Icon/Icon'
import { defaultTheme } from '@src/styles/theme'
import { Token } from '@revolut/ui-kit'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 18px;
  text-align: center;
  font-size: 13px;
  color: white;
  z-index: ${defaultTheme.zIndex.popup};
  background-color: ${Token.color.red};
`

const CloseButton = styled(IconButton)`
  color: white;
  position: absolute;
  right: 10px;
  top: 0;
`

interface DevWarningBarProps {
  className?: string
}

const DevWarningBar = ({ className }: DevWarningBarProps) => {
  const HIDE_DEV_BAR = 'hide_dev_bar'
  const [visible, setVisible] = useState(!localStorage.getItem(HIDE_DEV_BAR))
  const user = useSelector(selectUser)

  if (!visible || !isDev()) {
    return null
  }

  const handleCloseBar = () => {
    setVisible(false)
    localStorage.setItem(HIDE_DEV_BAR, 'true')
  }

  return (
    <Container className={className}>
      DEV: as {user?.full_name}
      <CloseButton onClick={handleCloseBar}>
        <Icon type="Close" size="tiny" />
      </CloseButton>
    </Container>
  )
}

export default DevWarningBar
