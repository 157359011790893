import React from 'react'
import axios from 'axios'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'

import { ArrowThinRight } from '@revolut/icons'
import { newTabTo, pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useNonFieldValidationErrors = (employee: EmployeeInterface) => {
  const popup = useStatusPopup()

  return (e: Error) => {
    if (!axios.isAxiosError(e)) {
      throw e
    }
    const nonFieldErrors = e?.response?.data?.non_field_errors
    if (nonFieldErrors?.length) {
      popup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Termination was not submitted</StatusPopup.Title>
          <StatusPopup.Description>{nonFieldErrors[0]}</StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              useEndIcon={ArrowThinRight}
              onClick={() => {
                newTabTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: employee.id }))
              }}
            >
              Open employee profile
            </Button>
            <Button variant="secondary" onClick={popup.hide}>
              Go back
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
      return
    }
    throw e
  }
}
