import React, { useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import {
  ActionButton,
  Box,
  Flex,
  IconButton,
  InputGroup,
  HStack,
  Popup,
  StatusPopup,
  Text,
  Token,
  useTooltip,
  Tooltip,
  MoreBar,
  VStack,
} from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { adhocQueryRun, getQueryRun, queryRun } from '@src/api/dataAnalytics'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import {
  QueryInterface,
  QueryParameterTypes,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatabaseSelect from '@src/components/Inputs/LapeFields/LapeDatabaseSelect'
import LapeNewCodeEditor from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  selectUser,
  selectFeatureFlags,
  selectPermissions,
} from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import {
  MATCH_REG_EXP,
  MIN_EDITOR_HEIGHT,
  MIN_EDITOR_HEIGHT_WITH_PARAMS,
  MIN_RESULT_HEIGHT,
  REPLACE_REG_EXP,
  RUN_QUERY_TIMEOUT,
  UNKNOWN_ERROR,
} from './constants'
import { useResize } from './hooks/useResize'
import { ParametersList } from './components/ParametersList'
import { PublishButton } from './components/PublishButton'
import { QueryToolkitSidebar } from './components/QueryToolkitSidebar'
import { ResizeHandler } from './components/ResizeHandler'
import { TenantDeploymentPopup } from './components/TenantDeploymentPopup'
import { QueryContext } from './QueryContextProvider'
import {
  isEditorMinHeight,
  isRunQueryKeysUsed,
  isQueryRunning,
  getQueryRunData,
  getErrorDescription,
} from './utils'
import { QueryResultContainer } from '@src/pages/Forms/QueryForm/components/QueryResultContainer'
import { ArchiveButton } from '@src/pages/Forms/QueryForm/components/ArchiveButton'
import styled from 'styled-components'
import isEqual from 'lodash/isEqual'

// TODO: REVCOR-3046 revisit after ui-kit update
const StyledMoreBarAction = styled(MoreBar.Action)`
  :disabled {
    color: ${Token.color.onAccentNeutral};
  }
`

export const QueryFormPage = connect(() => {
  const { values } = useLapeContext<QueryInterface>()
  const featureFlags = useSelector(selectFeatureFlags)
  const [sideBarOpen, setSideBarOpen] = useState(false)

  const permissions = values.field_options?.permissions || []
  const canManageQuery = featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)
  const canChangeSavedQuery = values?.id
    ? permissions.includes(PermissionTypes.CanChangeSavedQuery) && canManageQuery
    : true
  const canManageConnection = featureFlags?.includes(
    FeatureFlags.ReportingConnectionsManagement,
  )

  return (
    <>
      <EditQuery toggleSidebar={setSideBarOpen} />
      <QueryToolkitSidebar
        canEdit={canChangeSavedQuery}
        canManageConnection={canManageConnection}
        connectionId={values.connection?.id}
        isOpen={sideBarOpen}
        handleClose={() => setSideBarOpen(false)}
        parameters={values.parameters}
      />
    </>
  )
})

interface EditQueryProps {
  toggleSidebar: React.Dispatch<React.SetStateAction<boolean>>
}

export const EditQuery = connect(({ toggleSidebar }: EditQueryProps) => {
  const user = useSelector(selectUser)
  const { submit, values, initialValues } = useLapeContext<QueryInterface>()
  const {
    setFetchingError,
    setFetchingErrorDescription,
    setResetParameters,
    queryRunParameters,
    setQueryRunParameters,
    queryRunResponse,
    setQueryRunResponse,
    queryRunStatus,
    setQueryRunStatus,
  } = useContext(QueryContext)

  const featureFlags = useSelector(selectFeatureFlags)
  const globalPermissions = useSelector(selectPermissions)
  const permissions = values.field_options?.permissions || []
  const canManageConnection = featureFlags?.includes(
    FeatureFlags.ReportingConnectionsManagement,
  )
  const canManageQuery = featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)
  const canChangeSavedQuery = values?.id
    ? permissions.includes(PermissionTypes.CanChangeSavedQuery) && canManageQuery
    : true
  const canViewSavedQuery = values?.id ? values.sql !== undefined : true
  const canManageDeployment =
    featureFlags?.includes(FeatureFlags.QueryDeployments) &&
    featureFlags?.includes(FeatureFlags.TenantAccounts) &&
    globalPermissions.includes(PermissionTypes.ManageQueryDeployments)

  const editorRef = useRef<HTMLDivElement>(null)
  const queryRunTimerId = useRef<NodeJS.Timeout>()
  const [isLoading, setIsLoading] = useState(false)
  const [queryId, setQueryId] = useState<number>(values?.id)
  const [editorHeightMemo, setEditorHeightMemo] = useState(0)
  const [hideEditor, setHideEditor] = useState(!canViewSavedQuery || !!queryId)
  const [popupOpen, setPopupOpen] = useState(false)
  const [deploymentPopupOpen, setDeploymentPopupOpen] = useState(false)
  const [statusPopupOpen, setStatusPopupOpen] = useState(false)
  const editorTooltip = useTooltip()
  const buttonTooltip = useTooltip()

  const { editorHeight, resultHeight, setEditorHeight, setResultHeight } = useResize(
    editorRef,
    values.parameters || [],
    hideEditor,
  )

  const handleKeydown = (e: KeyboardEvent) => {
    if (isRunQueryKeysUsed(e)) {
      runQuery()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, true)

    return () => {
      document.removeEventListener('keydown', handleKeydown, true)
    }
  }, [handleKeydown])

  useEffect(() => {
    return () => {
      if (queryRunTimerId.current) {
        clearTimeout(queryRunTimerId.current)
      }
    }
  }, [])

  useEffect(() => {
    if (!values.owner && !values.id) {
      values.owner = { id: user.id, full_name: user.full_name }
    }

    if (!values.columns) {
      values.columns = []
    }

    if (!values.parameters) {
      values.parameters = []
    }
  }, [])

  useEffect(() => {
    const newQueryRunParameters = values.parameters?.reduce(
      (o, key) =>
        Object.assign(o, {
          [key.name]: queryRunParameters[key.name] || key.default || null,
        }),
      {},
    )

    setQueryRunParameters(newQueryRunParameters)
  }, [values.parameters])

  useEffect(() => {
    if (isQueryRunning(queryRunStatus)) {
      scheduleGetQueryRun()
    }
  }, [queryRunStatus])

  const canRunQuery = !!values.connection && (!!values.sql || !canViewSavedQuery)

  const onAfterSQLChange = (sql?: string) => {
    const prevParameters = values.parameters
    if (sql !== undefined) {
      const matches = sql.match(MATCH_REG_EXP)
      const extractedParameters = matches
        ? matches.map(match => match.replace(REPLACE_REG_EXP, '').trim())
        : []

      const parameters = [...new Set(extractedParameters)].map(parameter => {
        const prevValue = prevParameters.find(item => item.name === parameter)

        return {
          default: prevValue?.default || null,
          name: parameter,
          type: prevValue?.type || QueryParameterTypes.Text,
        }
      })

      if (!isEqual(prevParameters, parameters)) {
        values.parameters = parameters
      }
    }
  }

  const scheduleGetQueryRun = () => {
    queryRunTimerId.current = setTimeout(async () => {
      setQueryRunStatus(undefined)

      try {
        const result = await getQueryRun(queryId!, queryRunResponse?.id!)
        setQueryRunResponse(result.data)
        setQueryRunStatus(result.data.status)
        setIsLoading(isQueryRunning(result.data.status))
      } catch (e) {
        setFetchingError(e.message || UNKNOWN_ERROR)
        setFetchingErrorDescription(getErrorDescription(e))
        setQueryRunStatus(QueryRunStatuses.Error)
        setIsLoading(false)
      }
    }, RUN_QUERY_TIMEOUT)
  }

  const saveQuery = () => {
    if (canChangeSavedQuery) {
      if (values.name && values.owner) {
        submit().then(() => setStatusPopupOpen(true))
      } else {
        setPopupOpen(true)
      }
    }
  }

  const handleRunQuery = async (
    cb: (queryRunData: ReturnType<typeof getQueryRunData>) => ReturnType<typeof queryRun>,
  ) => {
    setIsLoading(true)
    setResetParameters(true)
    setFetchingError('')
    setFetchingErrorDescription('')
    setQueryRunStatus(undefined)
    const queryRunData = getQueryRunData(values, queryRunParameters)

    try {
      const result = await cb(queryRunData)
      setQueryId(result.data.query.id)
      setQueryRunResponse(result.data)
      setQueryRunStatus(result.data.status)
      setIsLoading(isQueryRunning(result.data.status))
    } catch (e) {
      setFetchingError(e.message || UNKNOWN_ERROR)
      setFetchingErrorDescription(getErrorDescription(e))
      setQueryRunStatus(QueryRunStatuses.Error)
      setIsLoading(false)
    } finally {
      setResetParameters(false)
    }
  }

  const isQueryDirty = () => {
    return (
      values.connection?.id !== initialValues?.connection?.id ||
      values.owner?.id !== initialValues.owner?.id ||
      values.sql !== initialValues.sql
    )
  }

  const runQuery = () => {
    if (!canRunQuery) {
      return
    }
    if (values.id && !isQueryDirty()) {
      handleRunQuery(data => queryRun(values.id, data))
    } else {
      handleRunQuery(data => adhocQueryRun(data))
    }
  }

  const onRowDrag = (event: React.MouseEvent) => {
    event.preventDefault()

    if (!canViewSavedQuery) {
      return null
    }

    const initialY = event.pageY
    function drag(e: MouseEvent) {
      e.preventDefault()
      const deltaY = e.pageY - initialY
      const newEditorHeight = Math.max(
        editorHeight + deltaY,
        values.parameters.length > 0 ? MIN_EDITOR_HEIGHT_WITH_PARAMS : MIN_EDITOR_HEIGHT,
      )
      const newResultHeight = resultHeight + editorHeight - newEditorHeight

      if (newResultHeight < MIN_RESULT_HEIGHT) {
        const calcEditorHeight = newEditorHeight + newResultHeight - MIN_RESULT_HEIGHT
        setEditorHeight(calcEditorHeight)
        setResultHeight(MIN_RESULT_HEIGHT)
        setHideEditor(isEditorMinHeight(calcEditorHeight))
      } else {
        setEditorHeight(newEditorHeight)
        setResultHeight(newResultHeight)
        setHideEditor(isEditorMinHeight(newEditorHeight))
      }

      return false
    }

    window.addEventListener('mousemove', drag)
    window.addEventListener('mouseup', stopDragging)
    function stopDragging(e: MouseEvent) {
      e.preventDefault()

      window.removeEventListener('mousemove', drag)
      window.removeEventListener('mouseup', stopDragging)
      return false
    }
    return false
  }

  const saveButton = !canChangeSavedQuery ? null : values.name && values.owner ? (
    <NewSaveButtonWithPopup
      disabled={!values.sql || !values.connection?.id}
      hideWhenNoChanges={false}
      onAfterSubmit={res => {
        navigateReplace(pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id: res.id }))
      }}
      successText={`Query ${values.name} successfully saved`}
      variant="secondary"
      useIcon="Plus"
      useValidator
      small
      isMoreAction
    >
      Save
    </NewSaveButtonWithPopup>
  ) : (
    <MoreBar.Action
      disabled={!values.sql || !values.connection?.id}
      onClick={() => {
        setPopupOpen(true)
      }}
      useIcon="Plus"
    >
      Save
    </MoreBar.Action>
  )

  const getEditorHeight = () => {
    if (hideEditor) {
      return values.parameters.length > 0
        ? MIN_EDITOR_HEIGHT_WITH_PARAMS
        : MIN_EDITOR_HEIGHT
    }

    return editorHeight || '40vh'
  }

  return (
    <PageBody margin={0} maxWidth="100%" width="100%">
      <Box mb="s-16">
        <MoreBar maxCount={3}>
          <StyledMoreBarAction
            aria-disabled={!canRunQuery}
            disabled={isLoading}
            onClick={runQuery}
            pending={isLoading}
            variant="accent"
            useIcon="Play"
            {...buttonTooltip.getAnchorProps()}
          >
            Run Query
          </StyledMoreBarAction>
          {saveButton}
          <PublishButton />
          {canManageDeployment && (
            <MoreBar.Action
              disabled={values.status !== 'active'}
              onClick={() => setDeploymentPopupOpen(true)}
              useIcon="ShareAndroid"
            >
              Deploy Query
            </MoreBar.Action>
          )}
          {values.owner && <ArchiveButton />}
        </MoreBar>
        {!canRunQuery ? (
          <Tooltip {...buttonTooltip.getTargetProps()}>
            <VStack gap="s-4" p="s-4">
              <Text>Before you can run query:</Text>
              {!values.connection && <Text fontWeight={500}>• Select connection</Text>}
              {!values.sql && canViewSavedQuery && (
                <Text fontWeight={500}>• Enter SQL query</Text>
              )}
            </VStack>
          </Tooltip>
        ) : null}
      </Box>
      <Flex
        backgroundColor={Token.color.widgetBackground}
        borderRadius={12}
        flexDirection="column"
        gap="s-8"
        p="s-16"
        ref={editorRef}
        style={{ height: getEditorHeight() }}
      >
        <Flex alignItems="center" justifyContent="space-between">
          <LapeDatabaseSelect
            label={values?.connection?.name || 'Select Connection'}
            name="connection"
            selector={canManageConnection ? selectorKeys.connection_names : undefined}
          />
          <Flex alignItems="center" gap="s-16">
            {canViewSavedQuery && (
              <>
                <IconButton
                  aria-label="Hide sql editor"
                  color={Token.color.greyTone50}
                  onClick={() => {
                    if (hideEditor) {
                      setEditorHeight(editorHeightMemo)
                    }

                    if (!hideEditor) {
                      setEditorHeightMemo(editorHeight)
                    }

                    setHideEditor(!hideEditor)
                  }}
                  useIcon={hideEditor ? 'Size' : 'ExitFullscreen'}
                  {...editorTooltip.getAnchorProps()}
                />
                <Tooltip {...editorTooltip.getTargetProps()}>
                  {hideEditor ? 'Expand SQL editor' : 'Collapse SQL editor'}
                </Tooltip>
              </>
            )}
            <IconButton
              aria-label="Query toolkit"
              color={Token.color.greyTone50}
              onClick={() => toggleSidebar(prev => !prev)}
              useIcon="Gear"
            />
          </Flex>
        </Flex>
        {values.parameters?.length > 0 && <ParametersList />}
        {!hideEditor && (
          <Box flex="1">
            <LapeNewCodeEditor
              height="95%"
              name="sql"
              onSave={saveQuery}
              onAfterChange={onAfterSQLChange}
              readonly={!canChangeSavedQuery}
              responsive
              width="100%"
            />
          </Box>
        )}
      </Flex>
      <ResizeHandler onMouseDown={onRowDrag} />
      <QueryResultContainer
        height={resultHeight}
        queryId={queryId}
        pending={isLoading}
        isQueryDirty={isQueryDirty()}
      />
      <Popup
        onClose={() => {
          setPopupOpen(false)
        }}
        open={popupOpen}
        variant="bottom-sheet"
      >
        <Text mb="s-16" variant="h5" use="div">
          We are missing some information
        </Text>
        <Text mb="s-16" variant="caption" use="p" color={Token.color.greyTone50}>
          Please add the information below to save this query
        </Text>
        <InputGroup>
          <LapeNewInput label="Query name" name="name" required />
          <LapeNewTextArea label="Description" name="description" rows={3} />
          <LapeRadioSelectInput
            label="Owner"
            name="owner"
            required
            selector={selectorKeys.employee}
          />
        </InputGroup>
        <Popup.Actions>
          <HStack space="s-16">
            <ActionButton
              onClick={() => {
                setPopupOpen(false)
              }}
            >
              Cancel
            </ActionButton>
            <NewSaveButtonWithPopup
              hideWhenNoChanges={false}
              onAfterSubmit={res => {
                setQueryRunStatus(undefined)
                navigateReplace(pathToUrl(ROUTES.FORMS.QUERY.GENERAL, { id: res.id }))
              }}
              successText={`Query ${values.name} successfully saved`}
            >
              Submit
            </NewSaveButtonWithPopup>
          </HStack>
        </Popup.Actions>
      </Popup>
      <StatusPopup
        // @ts-ignore this prop exists, TS definition is lying
        labelButtonClose="Close success popup"
        onClose={() => setStatusPopupOpen(false)}
        open={statusPopupOpen}
        variant="success"
      >
        <StatusPopup.Title>{`Query ${values.name} successfully saved`}</StatusPopup.Title>
      </StatusPopup>
      {canManageDeployment && (
        <TenantDeploymentPopup
          onClose={() => setDeploymentPopupOpen(false)}
          open={deploymentPopupOpen}
        />
      )}
    </PageBody>
  )
})
