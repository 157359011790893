import React from 'react'
import {
  ProbationTemplateRuleInterface,
  ProbationTemplateRules,
  ProbationTemplateRulesInterface,
  ProbationTemplateRulesNames,
} from '@src/interfaces/probationTemplate'
import {
  Box,
  createChain,
  ErrorWidget,
  Flex,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
  Widget,
} from '@revolut/ui-kit'

const rulesOrderMap = {
  [ProbationTemplateRules.seniority_id]: 0,
  [ProbationTemplateRules.team_id]: 1,
  [ProbationTemplateRules.team__department_id]: 2,
  [ProbationTemplateRules.specialisation_id]: 3,
  [ProbationTemplateRules.position_id]: 4,
  [ProbationTemplateRules.position__function_id]: 5,
  [ProbationTemplateRules.employee_type]: 6,
  [ProbationTemplateRules.contract_type]: 7,
  [ProbationTemplateRules.location_id]: 8,
  [ProbationTemplateRules.entity_id]: 9,
}

const RulesWithTooltip = ({ rules }: { rules: string[] }) => {
  const tooltip = useTooltip()
  const tooltipText = rules.join('\n')

  return (
    <Flex style={{ cursor: 'default' }} gap="s-4">
      <Box variant="caption" color={Token.color.blue} {...tooltip.getAnchorProps()}>
        +{rules.length}
      </Box>
      <Tooltip {...tooltip.getTargetProps()} placement="right">
        <Box style={{ whiteSpace: 'break-spaces' }}>{tooltipText}</Box>
      </Tooltip>
    </Flex>
  )
}

export const EmployeeRulesList = ({
  rules,
}: {
  rules?: ProbationTemplateRulesInterface
}) => {
  if (!rules || !Object.keys(rules)?.length) {
    return (
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/assets/3d-images/3D022.png" />
        <ErrorWidget.Title>
          No rules defined - all employees are eligible
        </ErrorWidget.Title>
      </ErrorWidget>
    )
  }

  return (
    <Widget px="s-16" py="s-12">
      <VStack space="s-24">
        {Object.entries(rules)
          .sort(
            (a, b) =>
              rulesOrderMap[a[0] as ProbationTemplateRules] -
              rulesOrderMap[b[0] as ProbationTemplateRules],
          )
          .map(item => {
            const [key, rulesList] = item
            const ruleName = ProbationTemplateRulesNames[key as ProbationTemplateRules]
            const rulesApplied = rulesList?.map(
              (el: ProbationTemplateRuleInterface) => el.name,
            )
            const firstRule = rulesApplied?.[0]
            const leftRules = rulesApplied?.slice(1)

            if (!firstRule) {
              return null
            }

            return (
              <Flex justifyContent="space-between" key={key}>
                <Text variant="caption" fontWeight={500} color={Token.color.greyTone50}>
                  {ruleName}
                </Text>
                <Flex>
                  {createChain(<>,&nbsp;</>)(
                    <Text variant="caption">{firstRule}</Text>,
                    leftRules?.length ? (
                      <RulesWithTooltip rules={leftRules} />
                    ) : undefined,
                  )}
                </Flex>
              </Flex>
            )
          })}
      </VStack>
    </Widget>
  )
}
