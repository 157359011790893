import { useState } from 'react'

import { getMagicLinkUrl } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useEmployeeMagicLinkAction = ({
  employee,
}: {
  employee: EmployeeInterface
}) => {
  const [link, setLink] = useState<string>()
  const [pending, setPending] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const onClick = async () => {
    try {
      setPending(true)
      setLink(undefined)
      const res = await getMagicLinkUrl(employee.id)
      setLink(res.data.magic_link)
      setShowPopup(true)
    } finally {
      setPending(false)
    }
  }

  return {
    link,
    pending,
    showPopup,
    setShowPopup,
    onClick,
  }
}
