import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { Stats } from '@src/interfaces/data'
import { RequestInterface } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import {
  canAddCompanyGoal,
  canChangeCompanyGoal,
  selectUser,
} from '@src/store/auth/selectors'
import { companyGoalRequests } from '@src/api/companyGoals'
import { KPITypes, TableNames } from '@src/constants/table'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { CompanyInterface } from '@src/interfaces/company'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { Flex, Text, TableWidget, ActionButton } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { CompanyGoalRow } from '@src/pages/Forms/Company/KPI/common'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { PermissionTypes } from '@src/store/auth/types'

interface StructureProps {
  data: CompanyInterface
}

const Goals = ({ data }: StructureProps) => {
  const initialFilter = [
    {
      filters: [
        { name: 'pending', id: 'pending' },
        { name: 'approved', id: 'approved' },
      ],
      columnName: 'status',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]
  const companyTable = useTable<KpiInterface, Stats>(
    companyGoalRequests as unknown as RequestInterface<KpiInterface>,
    initialFilter,
  )

  const canAdd = useSelector(canAddCompanyGoal)
  const user = useSelector(selectUser)
  const canChange = useSelector(canChangeCompanyGoal)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        department: { name: data.name, id: data.id },
      }),
    })
  }

  const handleRowEdit = (row: { id: number; type: KPITypes }) => {
    if (row.id === -1) {
      return
    }

    if (row.type === KPITypes.company_goal || !row.id) {
      row.id
        ? navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, { id: row.id }))
        : navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL))
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: row.id }))
    }
  }

  const handleEditWeights = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS_WEIGHTS.GENERAL), {
      initialValues: {
        company_goals: null,
      },
    })
  }

  return (
    <TableWidget>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24">
          <Stat
            label="Progress"
            val={
              companyTable?.stats?.avg_progress !== undefined ? (
                <Text
                  use="div"
                  mr="5px"
                  color={getPercentColor(companyTable.stats.avg_progress * 100)}
                >
                  {formatPercentage(companyTable.stats.avg_progress)}
                </Text>
              ) : undefined
            }
            mr="s-32"
          />
          <CycleFilter
            onFilterChange={companyTable.onFilterChange}
            columnName="review_cycle__offset"
            filter={companyTable.filterBy}
            type={CycleFilterType.NewUI}
          />
        </Flex>
        <Flex mb="s-16">
          {canAdd && (
            <ActionButton onClick={handleNewRow} mr="s-16" useIcon="Plus">
              Add New Company Goal
            </ActionButton>
          )}
          {canChange && (
            <ActionButton onClick={handleEditWeights} mr="s-16" useIcon="Pencil">
              Edit Weights
            </ActionButton>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.KPI}
            canView={[
              PermissionTypes.ViewPerformancePreferences,
              PermissionTypes.ChangePerformancePreferences,
            ]}
          />
        </Flex>
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<KpiInterface>
            name={TableNames.CompanyKPIGoals}
            useWindowScroll
            dataType="KPI"
            row={CompanyGoalRow(data.name)}
            onRowClick={handleRowEdit}
            noDataMessage="Please add company KPIs to see them here."
            {...companyTable}
            useFetchedChildren
          />
        </Flex>
      </Flex>
    </TableWidget>
  )
}

export default Goals
