import React, { useState } from 'react'
import { LinkedInPosting } from '@src/interfaces/jobPosting'
import {
  isClosed,
  isPending,
  isPublished,
  isRejected,
} from '@src/pages/Forms/JobPosting/LinkedInPostings/utils'
import {
  Button,
  HStack,
  Icon,
  Link,
  StatusPopup,
  TextButton,
  Tooltip,
  useStatusPopup,
  useTooltip,
  Text,
} from '@revolut/ui-kit'
import { promoteLinkedInPosting, retryLinkedInPosting } from '@src/api/jobPosting'
import { useGetJobPostingSettings } from '@src/api/settings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'

type LinkedInPostingActionProps = {
  linkedInPosting: LinkedInPosting
  onSubmit: (linkedInPosting: LinkedInPosting) => void
}

export const LinkedInPostingAction = ({
  linkedInPosting,
  onSubmit,
}: LinkedInPostingActionProps) => {
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const tooltip = useTooltip()
  const { data: settings } = useGetJobPostingSettings()
  const hasContractId = !!settings?.linkedin_contract_id
  const promoted = !!linkedInPosting.is_promoted
  const disabled =
    !isPublished(linkedInPosting) || !hasContractId || linkedInPosting.is_pending_changes
  let tooltipLabel: React.ReactNode
  if (isPending(linkedInPosting)) {
    tooltipLabel = `Can't ${promoted ? 'demote' : 'promote'} a pending job posting`
  }
  if (isClosed(linkedInPosting)) {
    tooltipLabel = `Can't ${promoted ? 'demote' : 'promote'} a closed job posting`
  }
  if (!promoted && !hasContractId) {
    tooltipLabel = (
      <>
        Connect your contract in the{' '}
        <Link
          use={InternalLink}
          to={{
            ...getLocationDescriptor(pathToUrl(ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE)),
            hash: `#linkedInIntegration`,
          }}
        >
          settings
        </Link>{' '}
        to post premium jobs on LinkedIn
      </>
    )
  }
  if (linkedInPosting.is_pending_changes) {
    tooltipLabel = `Can't ${
      promoted ? 'demote' : 'promote'
    } a job posting with pending changes`
  }
  const handlePromoted = async () => {
    setLoading(true)
    try {
      await promoteLinkedInPosting(linkedInPosting.id, !promoted)
      statusPopup.show(
        <StatusPopup variant="success-result" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            The type for {linkedInPosting.location.location_name} job posting will be
            changed to {promoted ? 'basic' : 'promoted'}. You will be able to see this
            change in the next hour
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            Failed chaging the type for {linkedInPosting.location.location_name}
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
      onSubmit(linkedInPosting)
    }
  }
  const handleReset = async () => {
    setLoading(true)
    try {
      await retryLinkedInPosting(linkedInPosting.id)
      statusPopup.show(
        <StatusPopup variant="success-result" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            The {linkedInPosting.location.location_name} job posting will be published.
            You will be able to see this change in the next hour
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>
            Failed publishing {linkedInPosting.location.location_name}
          </StatusPopup.Title>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide}>Ok</Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
      onSubmit(linkedInPosting)
    }
  }
  if (isClosed(linkedInPosting)) {
    return null
  }
  if (isRejected(linkedInPosting)) {
    return (
      <TextButton
        textStyle="h6"
        onClick={() => {
          handleReset()
        }}
      >
        <HStack align="center" space="s-4">
          <Icon name="Retry" size={14} />
          <Text>Retry</Text>
        </HStack>
      </TextButton>
    )
  }
  return (
    <TextButton
      textStyle="h6"
      aria-disabled={disabled}
      disabled={loading}
      onClick={() => {
        if (!disabled) {
          handlePromoted()
        }
      }}
      {...tooltip.getAnchorProps()}
    >
      <HStack align="center" space="s-4">
        <Icon name={promoted ? 'ArrowThinDown' : 'ArrowThinUp'} size={14} />
        <Text>
          {promoted ? 'Demote' : 'Promote'}
          {tooltipLabel && (
            <Tooltip {...tooltip.getTargetProps()}>{tooltipLabel}</Tooltip>
          )}
        </Text>
      </HStack>
    </TextButton>
  )
}
