import { AxiosPromise } from 'axios'

import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'
import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import {
  OnboardingQueueInterface,
  OnboardingQueueStatsInterface,
  OnboardingTimelineInterface,
  OnboardingTimelineProcessStage,
} from '@src/interfaces/onboarding'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { getCommentsAPI } from './comments'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const getOnboardingQueue = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<OnboardingQueueInterface>
> =>
  api.get(
    API.HR_ONBOARDING,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    undefined,
    true,
  )

export const getOnboardingQueueStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<OnboardingQueueStatsInterface> =>
  api.get(
    `${API.HR_ONBOARDING}/stats`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    undefined,
    true,
  )

export const getOnboardingTimeline = (employeeId: string, id: string) =>
  apiWithoutHandling.get<OnboardingTimelineInterface>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/${id}`,
    undefined,
    undefined,
    true,
  )

export const confirmOnboardingStep = (employeeId: number, stepId: number) =>
  api.patch<{ process: OnboardingTimelineInterface }>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/steps/${stepId}/confirm`,
    undefined,
    undefined,
    undefined,
    true,
  )

export const completeOnboardingStepAction = (
  employeeId: number | string,
  stepId: number | string,
) =>
  api.patch<{ process: OnboardingTimelineInterface }>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/steps/${stepId}/complete_action`,
    undefined,
    undefined,
    undefined,
    true,
  )

export const getOnboardingTimelineCommentsAPI = (employeeId: string, id: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/hrOnboarding/${id}/comments`,
  })

export const completeStage = (employeeId: number, stageId: number) =>
  api.patch<OnboardingTimelineProcessStage>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/stages/${stageId}/complete`,
    undefined,
    undefined,
    undefined,
    true,
  )

export const updateHRManager = (
  employeeId: number,
  id: number,
  hr_manager: EmployeeOptionInterface,
) =>
  api.patch<OnboardingTimelineInterface>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/${id}`,
    { hr_manager },
    undefined,
    undefined,
    true,
  )

export const cancelOnboardingProcess = (employeeId: string, id: string) =>
  api.post<OnboardingTimelineInterface>(
    `${API.EMPLOYEES}/${employeeId}/hrOnboarding/${id}/cancel`,
    undefined,
    undefined,
    undefined,
    true,
  )

export const forceTriggerOnboardingProcess = (employeeId: string | number) =>
  apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/startCurrentOnboarding`)
