import React from 'react'
import map from 'lodash/map'
import upperFirst from 'lodash/upperFirst'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { Bar, Box, BoxProps, IconName } from '@revolut/ui-kit'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { connect, useLape } from 'lape'
import { NewFilterInputProps } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'

export type ButtonFilterType = 'DateRange' | 'SingleSelect' | 'MultiSelect'

export interface ButtonFilterInterface {
  selector: NewFilterInputProps['selector']
  type: ButtonFilterType
  title?: string
  useIcon?: IconName
  renderOption?: NewFilterInputProps['renderOption']
  width?: string
}

export interface ButtonFilterConfig {
  [columnName: string]: ButtonFilterInterface
}

export interface ButtonFiltersProps extends Omit<BoxProps, 'onChange'> {
  filtersConfig: ButtonFilterConfig
  filters: FilterByInterface[]
  onChange: (filter: FilterByInterface) => void
}

const ButtonFilters = ({
  filtersConfig,
  onChange = () => {},
  filters,
  ...props
}: ButtonFiltersProps) => {
  const state = useLape<{ [columnName: string]: OptionInterface[] }>({})

  return (
    <Box {...props}>
      <Bar>
        {map(
          filtersConfig,
          ({ selector, title, type, useIcon, renderOption, width }, columnName) => (
            <ButtonFilter
              key={columnName}
              title={title || upperFirst(columnName.replace(/_/g, ' '))}
              onChange={options => {
                state[columnName] = options
                onChange({ filters: options, columnName })
              }}
              selector={selector}
              value={filters.find(f => f.columnName === columnName)?.filters}
              type={type}
              useIcon={useIcon}
              renderOption={renderOption}
              width={width}
              maxWidth={width}
            />
          ),
        )}
      </Bar>
    </Box>
  )
}

export default connect(ButtonFilters)
