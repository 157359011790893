import React from 'react'
import styled from 'styled-components'
import CompensationLocationItem from '@components/LocationCompensationBand/CompensationLocationItem'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { CompensationBandInterface } from '@src/interfaces/benchmarks'
import { allCompensationBandsAvailable } from '@components/LocationCompensationBand/utils'

const Ul = styled.ul`
  margin: 0;
  padding: 0 0 0 16px;
`

type Props = {
  text: string
  bands?: CompensationBandInterface[]
  bottomText?: React.ReactNode
}

const CompensationBandBase = ({ bands, text, bottomText }: Props) => {
  if (!bands?.length) {
    return null
  }

  const bandsAvailable = allCompensationBandsAvailable(bands)

  return (
    <ActionWidget
      title={
        bandsAvailable
          ? 'Legal requirement to display below compensation bands'
          : 'Compensation band not found for one or more locations'
      }
      text={
        <>
          {text}
          <br />
          <br />
          <Ul>
            {bands.map(item => (
              <CompensationLocationItem key={item.location.id} band={item} />
            ))}
          </Ul>
          {bottomText}
        </>
      }
      avatar={bandsAvailable ? null : undefined}
      data-testid="location-compensation-band"
    />
  )
}

export default CompensationBandBase
