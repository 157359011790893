import isNil from 'lodash/isNil'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { KpiInterface, RelevantKpiInterface } from '@src/interfaces/kpis'
import { Statuses } from '@src/interfaces'
import GraphIconChart from '@src/components/Charts/GraphIconChart/GraphIconChart'
import { ColoredPercent } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import Icon from '@src/components/Icon/Icon'
import React from 'react'
import { PercentageWrapper } from '@src/components/CommonSC/TableStatistics'
import ParentLink from '@src/components/ColumnInserts/ParentLink/ParentLink'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { ColoredText } from '@src/components/CommonSC/General'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { formatNumber } from '@src/utils/format'
import { Box, Flex, Token } from '@revolut/ui-kit'
import AssignRelevantKPI from '@components/ColumnInserts/AssignRelevantKPI/AssignRelevantKPI'
import { KPITypes } from '@src/constants/table'
import { kpiWeightColumn } from '@src/constants/columns/kpi'
import { ArrowExchange, ExclamationMarkOutline, InfoOutline } from '@revolut/icons'
import {
  calculateRelevantKPISectionPerformance,
  getEntity,
  getLinkForKPIEntity,
} from '@src/utils/kpi'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { EmployeeInterface } from '@src/interfaces/employees'
import CalibratedTooltip from '@components/ColumnInserts/CalibratedTooltip/CalibratedTooltip'

export const relevantKpiGenericNameColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.kpi_names,
  title: 'KPI',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return <b>{data.name}</b>
    }

    return (
      <TableCellLink
        to={getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: (data as KpiInterface).id }),
        )}
      >
        {data.name}
      </TableCellLink>
    )
  },
}

export const relevantKpiInitialValueColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: 'initial_value',
  filterKey: 'initial_value',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    return formatNumber((data as KpiInterface).initial_value, 2)
  },
  title: 'Initial',
}

export const relevantKpiCurrentValueColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: 'current_progress',
  filterKey: 'current_progress',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    return formatNumber((data as KpiInterface).current_progress, 2)
  },
  title: 'Current',
}

export const relevantKpiTargetColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: 'target',
  filterKey: 'target',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    return formatNumber((data as KpiInterface).target, 2)
  },
  title: 'Target',
}

export const relevantKpiOwnerNameColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    return (data as KpiInterface).owner.name!
  },
}

export const relevantKpiPerformanceColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      let progress = 0
      let notDoneMandatory = false

      if (data.children?.length) {
        const result = calculateRelevantKPISectionPerformance(data.children)
        progress = result.performance || 0
        notDoneMandatory = result.notDoneMandatory
      }

      return (
        <Flex alignItems="center">
          <Flex>
            <PercentageWrapper>
              <ColoredPercent percent={progress * 100} />
            </PercentageWrapper>
          </Flex>
          {notDoneMandatory && (
            <Flex pl="s-4">
              <Tooltip placement="right" text="Some mandatory KPIs are not done">
                <ExclamationMarkOutline size={14} color="error" />
              </Tooltip>
            </Flex>
          )}
        </Flex>
      )
    }

    const kpiData = data as KpiInterface
    return (
      <GraphIconChart id={kpiData.id} fetchData={getKpiPerformanceGraph}>
        <PercentageWrapper>
          <ColoredPercent percent={kpiData.performance * 100} />
          <Box pl="s-6">
            <Icon type="Graph" size="tiny" />
          </Box>
        </PercentageWrapper>
      </GraphIconChart>
    )
  },
  title: 'Progress',
}

export const relevantKPICalibratedPerformanceColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'calibrated_progress',
  dataPoint: 'calibrated_progress',
  sortKey: 'calibrated_progress',
  filterKey: 'calibrated_progress',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  headerTooltip: <CalibratedTooltip />,
  insert: ({ data, parentIndexes }) => {
    let calibratedProgress: number | null | undefined = null
    const calibrationComment = 'children' in data ? undefined : data.calibration_comment

    if ('children' in data && parentIndexes.length === 1) {
      calibratedProgress = calculateRelevantKPISectionPerformance(
        data.children,
        true,
      ).performance
    } else {
      calibratedProgress = (data as KpiInterface).calibrated_progress
    }

    if (isNil(calibratedProgress)) {
      return '-'
    }

    return (
      <Tooltip placement="top" text={calibrationComment}>
        <Flex width={100} justifyContent="space-between">
          <PercentageWrapper>
            <ColoredPercent percent={calibratedProgress * 100} />
            {calibrationComment && (
              <Box pl="s-4" pr="s-4">
                <InfoOutline size={14} />
              </Box>
            )}
          </PercentageWrapper>
        </Flex>
      </Tooltip>
    )
  },
  title: 'Calibrated Performance',
}

export const relevantKPIEntityColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'type',
  dataPoint: 'type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Organisational unit',
  insert: ({ data, parentIndexes }) => {
    if (parentIndexes.length === 1) {
      return null
    }
    const kpi = data as KpiInterface
    const base = (kpi.is_inherited ? kpi.parent : data) || {}
    const entityName = getEntity(base) || '-'

    if (entityName === '-') {
      return '-'
    }
    return (
      <TableCellLink to={getLinkForKPIEntity(base) || '/'}>{entityName}</TableCellLink>
    )
  },
}

export const relevantKpiStatusColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    switch ((data as KpiInterface).target_status) {
      case Statuses.approved:
        return (
          <Tooltip text="Approved" placement="left">
            <ColoredText color={Token.color.green_60}>
              <Icon type="Check" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.completed:
        return (
          <Tooltip text="Completed" placement="left">
            <ColoredText color={Token.color.greyTone50}>
              <Icon type="Check" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.pending:
        return (
          <Tooltip text="Pending" placement="left">
            <ColoredText color={Token.color.warning}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.future:
        return (
          <Tooltip text="Future" placement="left">
            <ColoredText color={Token.color.lightBlue}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.archived:
        return (
          <Tooltip text="Archived" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Delete" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.requires_changes:
        return (
          <Tooltip text="Requires changes" placement="left">
            <ColoredText color={Token.color.warning}>
              <ArrowExchange size={16} />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.rejected:
        return (
          <Tooltip text="Rejected" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Close" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      default:
        return Token.color.foreground
    }
  },
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.kpi_statuses,
  title: 'Status',
}

export const relevantKpiParentColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'parent',
  dataPoint: 'parent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Parent',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }

    return <ParentLink data={data as KpiInterface} />
  },
}

export const relevantKpiWeight: ColumnInterface<KpiInterface | RelevantKpiInterface> =
  kpiWeightColumn as ColumnInterface<KpiInterface | RelevantKpiInterface>

export const relevantCategoryColumn: ColumnInterface<
  KpiInterface | RelevantKpiInterface
> = {
  type: CellTypes.insert,
  idPoint: 'kpi_category.name',
  dataPoint: 'kpi_category.name',
  sortKey: 'kpi_category',
  filterKey: 'kpi_category',
  selectorsKey: selectorKeys.kpi_categories,
  title: 'Category',
  insert: ({ data, parentIndexes }) => {
    if ('children' in data && parentIndexes.length === 1) {
      return ''
    }
    return (data as KpiInterface)?.kpi_category?.name
  },
}

export const getAssignActionColumn = (
  employee: EmployeeInterface,
): ColumnInterface<KpiInterface | RelevantKpiInterface> => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.kpi_categories,
  title: 'Action',
  insert: ({ data, parentIndexes }) => {
    if (
      (data as KpiInterface).kpi_type?.id === KPITypes.employee_kpi ||
      parentIndexes.length === 1
    ) {
      return ''
    }
    return <AssignRelevantKPI data={data as KpiInterface} employee={employee} />
  },
})
