import React, { useEffect, useRef, useState } from 'react'
import { Popup, Header, Button } from '@revolut/ui-kit'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'

export interface SchedulingActionButtonProps {
  label: string
  title: string
  description: string
  menuType?: MenuActionType
  disabled?: boolean
  onProceed: (() => Promise<void>) | (() => void)
  goBackLabel?: string
  loading?: boolean
}

const SchedulingActionButton = ({
  label,
  title,
  description,
  menuType,
  disabled,
  onProceed,
  goBackLabel = 'Go back',
  loading,
}: SchedulingActionButtonProps) => {
  const unmounted = useRef(false)

  const [opened, setOpened] = useState(false)

  const onHandlerClick = () => {
    setOpened(true)
  }

  const onProceedClick = async () => {
    await onProceed()

    // sometimes the parent component could be closed outside
    if (!unmounted.current) {
      setOpened(false)
    }
  }

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  })

  return (
    <>
      <MenuAction
        menuType={menuType}
        props={{
          disabled,
          onClick: onHandlerClick,
          variant: menuType === 'dropdown' ? undefined : menuType,
          'data-testid': `btn-${label}`,
          use: 'button',
        }}
      >
        {label}
      </MenuAction>

      <Popup open={opened} variant="bottom-sheet" onClose={() => setOpened(false)}>
        <Header variant="bottom-sheet">
          <Header.Title>{title}</Header.Title>
          <Header.Description>{description}</Header.Description>
        </Header>
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={() => setOpened(false)} disabled={loading}>
            {goBackLabel}
          </Button>
          <Button elevated onClick={onProceedClick} disabled={loading} pending={loading}>
            Proceed
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}

export default SchedulingActionButton
