import React from 'react'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const useShowSessionExpiredPopup = () => {
  const statusPopup = useStatusPopup()

  return (description: string) =>
    statusPopup.show(
      <StatusPopup variant="error" preventUserClose>
        <StatusPopup.Title>Session expired</StatusPopup.Title>
        <StatusPopup.Description>{description}</StatusPopup.Description>
        <StatusPopup.Actions>
          <Button
            variant="primary"
            onClick={() => {
              navigateTo(ROUTES.LOGIN.MAIN)
              statusPopup.hide()
            }}
          >
            Re-login
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
}
