import React from 'react'
import styled from 'styled-components'
import { Token } from '@revolut/ui-kit'

const UrlWrapper = styled.a`
  color: ${Token.color.blue};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

interface Props {
  children: React.ReactNode
  href: string
}

const Url = ({ children, href }: Props) => {
  if (children === '' || !href) {
    return <span>-</span>
  }
  return (
    <UrlWrapper onClick={e => e.stopPropagation()} href={href} target="_blank">
      {children}
    </UrlWrapper>
  )
}

export default Url
