import {
  Box,
  DetailsCellSkeleton,
  DetailsSkeleton,
  Group,
  ItemSkeleton,
  MoreBarSkeleton,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import React from 'react'

const PaycyclePreviewLoading = () => {
  return (
    <Box>
      <VStack gap="s-16">
        <TextSkeleton variant="h1" size={16} />
        <MoreBarSkeleton />
        <ItemSkeleton />

        {Array(5)
          .fill(null)
          .map((_, index) => (
            <Box key={index}>
              <DetailsSkeleton />
              <Group>
                <ItemSkeleton />
                <DetailsCellSkeleton />
                <DetailsCellSkeleton />
                <DetailsCellSkeleton />
                <DetailsCellSkeleton />
                <DetailsCellSkeleton />
              </Group>
            </Box>
          ))}
      </VStack>
    </Box>
  )
}

export default PaycyclePreviewLoading
