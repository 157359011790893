import React, { useEffect, useState } from 'react'
import {
  cancelInterviewFeedback,
  getUpcomingInterviews,
} from '@src/api/recruitment/interviews'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Color, MoreBar } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'

interface Props {
  id: string
}

const UpcomingInterviewNotifications = ({ id }: Props) => {
  const [interviews, setInterviews] = useState<InterviewFeedbackInterface[]>()

  useEffect(() => {
    const fetchUpcomingInterviews = async () => {
      const result = await getUpcomingInterviews(id)
      setInterviews(
        result.data.filter(
          item => item.interview_stage.interview_type !== 'cv_screening',
        ),
      )
    }
    fetchUpcomingInterviews()
  }, [id])

  const rejectFeedback = async (interviewId: number) => {
    await cancelInterviewFeedback(interviewId)
    setInterviews(interviews?.filter(interview => interview.id !== interviewId))
  }

  const addFeedback = async (interviewId: number) => {
    navigateTo(pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id: interviewId }))
  }

  return (
    <>
      {interviews?.map(interview => (
        <ActionWidget
          key={interview.id}
          title={`Feedback for "${interview.interview_stage.title}" stage is pending`}
          text="Please complete or reject the feedback"
          avatarColor={Color.BLUE}
        >
          <MoreBar>
            <MoreBar.Action useIcon={Plus} onClick={() => addFeedback(interview.id)}>
              Add feedback
            </MoreBar.Action>
            <MoreBar.Action
              onClick={() => rejectFeedback(interview.id)}
              variant="negative"
            >
              Reject
            </MoreBar.Action>
          </MoreBar>
        </ActionWidget>
      ))}
    </>
  )
}

export default UpcomingInterviewNotifications
