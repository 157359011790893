import React from 'react'
import { H5, TextButton, Token } from '@revolut/ui-kit'
import { deleteGrant } from '@src/api/totalCompensation'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

type Props = {
  id: number
  onDelete: () => void
}
export const GrantActions = ({ id, onDelete }: Props) => {
  const confirmationPopup = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  return (
    <TextButton
      onClick={() => {
        confirmationPopup.show({
          showHeader: false,
          body: (
            <H5 color={Token.color.foreground}>
              Are you sure you want to delete this grant?
            </H5>
          ),
          onConfirm: async () => {
            try {
              await deleteGrant(id)
              onDelete()
            } catch (error) {
              showStatusPopup({
                title: 'Something went wrong',
                description: getStringMessageFromError(error),
                status: 'error',
              })
            }
          },
        })
      }}
      variant="danger"
    >
      Delete
    </TextButton>
  )
}
