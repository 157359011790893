import React, { useEffect, useMemo } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DataRetentionSettingInterface } from '@src/interfaces/dataRetentionSettings'
import { ActionWidget, DetailsCell, HStack, InputGroup, Text } from '@revolut/ui-kit'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InfoOutline } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { useGetSelectors } from '@src/api/selectors'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { IdAndName } from '@src/interfaces'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const General = () => {
  const { values } = useLapeContext<DataRetentionSettingInterface>()
  const { data: units } = useGetSelectors<IdAndName<string>>(
    selectorKeys.retention_period_duration_units,
  )

  const durationUnitOptions = useMemo(
    () =>
      units?.map(item => ({
        label: item.name,
        value: item,
      })) || [],
    [units],
  )

  const backUrl = ROUTES.SETTINGS.CANDIDATES.DATA_RETENTION_SETTINGS
  const title = values.id
    ? `Edit ${values.country.name}`
    : 'New country data retention setting'

  useEffect(() => {
    if (!values.not_hired_candidate_retention_duration_unit) {
      values.not_hired_candidate_retention_duration_unit = 'years'
    }

    if (!values.hired_candidate_retention_duration_unit) {
      values.hired_candidate_retention_duration_unit = 'years'
    }
  }, [])

  return (
    <>
      <PageHeader pb="s-8" title={title} backUrl={backUrl} />
      <PageBody>
        <ActionWidget>
          <ActionWidget.Title>
            Philosophy/Business Justification adopted in the Retention Schedule
          </ActionWidget.Title>
          <ActionWidget.Content>
            <ul style={{ paddingInlineStart: 16, margin: 0 }}>
              <Text color="orange">
                <li>
                  Please be extremely careful while updating the retention period since it
                  might trigger anonymization of 1000s of candidates for this country &
                  anonymization is an irreversible action
                </li>
              </Text>
              <li>
                Please check the local data retention laws to define the retention period.
                When in doubt please check with the legal team.
              </li>
            </ul>
          </ActionWidget.Content>
        </ActionWidget>
        <AutoStepper>
          <NewStepperTitle title="Location" />
          <InputGroup>
            <LapeRadioSelectInput
              name="country"
              label="Country"
              selector={selectorKeys.countries}
              disabled={!!values.id}
            />
          </InputGroup>

          <NewStepperTitle title="Unsuccessful applicants" />
          <DetailsCell mb="s-16">
            <DetailsCell.Title alignSelf="center">Triggering event</DetailsCell.Title>
            <DetailsCell.Content>
              <HStack space="s-8" align="center">
                <Text>Date of decision</Text>
                <Tooltip
                  placement="right"
                  text="This is the date when the candidate is archived for all the specialisations/interview rounds"
                >
                  <InfoOutline size={16} color="grey-tone-50" />
                </Tooltip>
              </HStack>
            </DetailsCell.Content>
          </DetailsCell>
          <InputGroup variant="horizontal" data-testid="duration-not-hired">
            <LapeRadioSelectInput
              name="not_hired_candidate_retention_duration_unit"
              label="Duration unit"
              options={durationUnitOptions}
              value={
                durationUnitOptions.find(
                  unit =>
                    unit.value.id === values.not_hired_candidate_retention_duration_unit,
                )?.value
              }
              onChange={option => {
                values.not_hired_candidate_retention_duration_unit = option?.id
              }}
              searchable={false}
            />
            <LapeNewInput
              name="not_hired_candidate_retention_duration"
              label="Retention period"
              type="number"
              required
            />
          </InputGroup>

          <NewStepperTitle title="Successful applicants" />
          <DetailsCell mb="s-16">
            <DetailsCell.Title alignSelf="center">Triggering event</DetailsCell.Title>
            <DetailsCell.Content>
              <HStack space="s-8" align="center">
                <Text>End of employment</Text>
                <Tooltip
                  placement="right"
                  text="This is the last day of contract for the terminated employee"
                >
                  <InfoOutline size={16} color="grey-tone-50" />
                </Tooltip>
              </HStack>
            </DetailsCell.Content>
          </DetailsCell>
          <InputGroup variant="horizontal" data-testid="duration-hired">
            <LapeRadioSelectInput
              name="hired_candidate_retention_duration_unit"
              label="Duration unit"
              options={durationUnitOptions}
              value={
                durationUnitOptions.find(
                  unit =>
                    unit.value.id === values.hired_candidate_retention_duration_unit,
                )?.value
              }
              onChange={option => {
                values.hired_candidate_retention_duration_unit = option?.id
              }}
              searchable={false}
            />
            <LapeNewInput
              name="hired_candidate_retention_duration"
              label="Retention period"
              type="number"
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <PageActions>
          <NewSaveButtonWithPopup
            successText="Retention setting saved successfully"
            onAfterSubmit={response => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.PREVIEW, {
                  id: response.id,
                }),
              )
            }}
            useValidator
          />
        </PageActions>
      </PageActions>
    </>
  )
}

export default General
