import React from 'react'
import CandidateDataSkeleton from '@src/pages/Forms/Candidate/InterviewProgress/components/CandidateDataSkeleton'
import CandidateData from '@src/features/InterviewTool/CandidateData'
import {
  CandidateInterface,
  CandidateSidebarTypes,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'

type CandidateInformationProps = {
  candidate?: CandidateInterface
  canAddRound: boolean
  canEditRound: boolean
  round?: InterviewRoundInterface
  loading?: boolean
  previewStageMode: boolean
  onOpenSidebar: (type: CandidateSidebarTypes) => void
}

const CandidateInformation = ({
  candidate,
  canAddRound,
  canEditRound,
  round,
  loading,
  previewStageMode,
  onOpenSidebar,
}: CandidateInformationProps) => {
  const previewPermissions = {
    canAddRound: false,
    canEditRound: false,
    canEditCandidateSidebar: false,
    canEditPersonalDetails: false,
    canOpenAppliedJobs: false,
    canViewAppliedJobs: false,
    canViewCandidate: true,
  }
  const defaultPermissions = {
    canAddRound,
    canEditRound,
    canEditCandidateSidebar: true,
    canEditPersonalDetails: true,
    canOpenAppliedJobs: false,
    canViewAppliedJobs: false,
    canViewCandidate: true,
  }
  const permissions = previewStageMode ? previewPermissions : defaultPermissions

  if (loading) {
    return <CandidateDataSkeleton />
  }

  if (!candidate) {
    return null
  }

  return (
    <CandidateData
      candidate={candidate}
      hideCandidate
      permissions={permissions}
      round={round}
      onOpenSidebar={onOpenSidebar}
      onShowCV={() => {
        onOpenSidebar('cv')
      }}
    />
  )
}

export default CandidateInformation
