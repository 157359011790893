import React, { useMemo, useState } from 'react'
import { useTable } from '@components/Table/hooks'
import {
  BudgetStats,
  FilterByInterface,
  RowInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { getTalent, getTalentStat } from '@src/api/budgets'
import { BudgetInterface } from '@src/interfaces/budgets'
import {
  budgetAnnualExpenseColumn,
  budgetEmployeeNameColumn,
  budgetFinalGradeReviewColumn,
  budgetFunctionalManagerColumn,
  budgetLineManagerColumn,
  budgetLocationColumn,
  budgetPerfLabelReviewColumn,
  budgetRoleColumn,
  budgetSeniorityAndSublevelColumn,
  budgetSeniorityColumn,
  budgetStartDateColumn,
  budgetStatusColumn,
  budgetTeamNameColumn,
} from '@src/constants/columns/budgets'
import { FunctionInterface } from '@src/interfaces/functions'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import { MoreBar, FilterButton, HStack, Spacer } from '@revolut/ui-kit'
import { ArrowRightLeft, ChartBarWithArrow, Pencil, Plus } from '@revolut/icons'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import TalentHeader from '@src/pages/Forms/CommonTalentTab/TalentHeader'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TeamInterface } from '@src/interfaces/teams'
import { RoleInterface, SpecialisationInterface } from '@src/interfaces/roles'
import { OptionInterface } from '@src/interfaces/selectors'
import { getRequisitionInitialValues } from '../RequisitionForm/General/General'
import { useSelector } from 'react-redux'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import { getRevolutersExport } from '@src/api/revoluters'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { PermissionTypes } from '@src/store/auth/types'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'
import {
  cultureTalentColumn,
  deliverablesTalentColumn,
  skillsTalentColumn,
} from '@src/constants/columns/talent/performance'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { AllowedExportMenu } from '@src/features/ExportMenu/AllowedExportMenu'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import Table from '@src/components/TableV2/Table'
import { TransferEmployeePopup } from './TransferEmployeePopup'
import { isDepartmentType, isFunctionType } from './utils'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface StructureProps {
  data:
    | FunctionInterface
    | DepartmentInterface
    | TeamInterface
    | RoleInterface
    | SpecialisationInterface
    | EmployeeInterface
  talentType: TalentType
  navigation?: React.ReactElement
}

const defaultEmployeeStatusFilter = [
  { name: 'active', id: 'active' },
  { name: 'hired', id: 'hired' },
  { name: 'pending', id: 'pending' },
  { name: 'onboarding', id: 'onboarding' },
  { name: 'inactive', id: 'inactive' },
]

export const useTalentTable = (filter?: FilterByInterface) => {
  const initialFilter = [
    filter,
    {
      filters: [{ name: '0', id: 0 }],
      columnName: 'is_requisition',
      nonResettable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
    {
      filters: defaultEmployeeStatusFilter,
      columnName: 'status',
      nonResettable: true,
    },
    {
      filters: [{ name: 'USD', id: 'USD' }],
      columnName: 'target_currency',
      nonResettable: true,
    },
    {
      filters: [{ name: 'true', id: 'true' }],
      columnName: 'return_all',
      nonResettable: true,
    },
  ].filter(i => i) as FilterByInterface[]

  const initialSortBy = [
    {
      sortBy: 'seniority__level',
      direction: SORT_DIRECTION.ASC,
    },
    {
      sortBy: 'full_name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  return useTable<BudgetInterface, BudgetStats>(
    {
      getItems: getTalent,
      getStats: getTalentStat,
    },
    initialFilter,
    initialSortBy,
  )
}

export type Period = 'annual' | 'monthly'

const Talent = ({
  data,
  talentType = TalentType.Function,
  navigation,
}: StructureProps) => {
  const { data: settings } = useGetOrganisationSettings()
  const budgetEnabled = !!settings?.enable_budget_management
  const multipleSenioritiesEnabled = !!settings?.enable_multiple_levels_per_seniority
  const { data: performanceSettings } = useGetPerformanceSettings()
  const isNewLayout = useIsNewLayout()

  const isEmployeeTalent =
    talentType === TalentType.FunctionalReport || talentType === TalentType.DirectReport
  const isOrganisationTalent = isDepartmentType(talentType)
  const isFunctionalTalent = isFunctionType(talentType)

  let performanceColumns = [
    {
      ...deliverablesTalentColumn,
      width: 150,
    },
    ...(performanceSettings?.enable_skill_assessment
      ? [
          {
            ...skillsTalentColumn,
            width: 150,
          },
        ]
      : []),
    ...(performanceSettings?.enable_values
      ? [
          {
            ...cultureTalentColumn,
            width: 150,
          },
        ]
      : []),
  ]

  const talentRow: RowInterface<BudgetInterface> = useMemo(
    () => ({
      linkToForm: rowData => {
        if (rowData.is_requisition) {
          return navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: rowData.id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: rowData.id }))
      },
      cells: [
        {
          ...budgetEmployeeNameColumn,
          width: 220,
        },
        {
          ...budgetTeamNameColumn,
          width: 160,
        },
        ...(talentType === TalentType.FunctionalReport && multipleSenioritiesEnabled
          ? [
              {
                ...budgetSeniorityAndSublevelColumn,
                width: 160,
              },
            ]
          : [
              {
                ...budgetSeniorityColumn,
                width: 140,
              },
            ]),
        {
          ...budgetRoleColumn,
          width: 180,
        },
        {
          ...budgetAnnualExpenseColumn,
          masked: true,
          width: 140,
        },
        ...(talentType === TalentType.FunctionalReport
          ? [
              {
                ...budgetFinalGradeReviewColumn,
                masked: true,
                width: 160,
              },
            ]
          : [
              {
                ...budgetPerfLabelReviewColumn,
                masked: true,
                width: 160,
              },
            ]),

        {
          ...budgetLineManagerColumn,
          width: 80,
        },
        {
          ...budgetFunctionalManagerColumn,
          width: 80,
        },
        {
          ...budgetStartDateColumn,
          width: 100,
        },
        {
          ...budgetLocationColumn,
          width: 140,
        },
        {
          ...budgetStatusColumn,
          width: 130,
        },
        ...(talentType === TalentType.FunctionalReport ? performanceColumns : []),
      ],
    }),
    [talentType, multipleSenioritiesEnabled, performanceColumns.length],
  )

  const directReportTalent: RowInterface<BudgetInterface> = useMemo(() => {
    return {
      linkToForm: rowData => {
        if (rowData.is_requisition) {
          return navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: rowData.id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: rowData.id }))
      },
      cells: [
        {
          ...budgetEmployeeNameColumn,
          width: 220,
        },
        {
          ...budgetTeamNameColumn,
          width: 150,
        },
        {
          ...budgetAnnualExpenseColumn,
          masked: true,
          width: 130,
        },
        {
          ...budgetFinalGradeReviewColumn,
          masked: true,
          width: 130,
        },
        {
          ...budgetRoleColumn,
          width: 180,
        },
        ...(multipleSenioritiesEnabled
          ? [
              {
                ...budgetSeniorityAndSublevelColumn,
                width: 160,
              },
            ]
          : [
              {
                ...budgetSeniorityColumn,
                width: 160,
              },
            ]),
        {
          ...budgetLineManagerColumn,
          width: 80,
        },
        {
          ...budgetFunctionalManagerColumn,
          width: 80,
        },
        {
          ...budgetStartDateColumn,
          width: 120,
        },
        {
          ...budgetLocationColumn,
          width: 140,
        },
        {
          ...budgetStatusColumn,
          width: 120,
        },
        ...(isEmployeeTalent ? performanceColumns : []),
      ],
    }
  }, [isEmployeeTalent, multipleSenioritiesEnabled, performanceColumns.length])

  const talentRoleRow: RowInterface<BudgetInterface> = useMemo(
    () => ({
      linkToForm: rowData => {
        if (rowData.is_requisition) {
          return navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: rowData.id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: rowData.id }))
      },
      cells: [
        {
          ...budgetEmployeeNameColumn,
          width: 280,
        },
        {
          ...budgetAnnualExpenseColumn,
          masked: true,
          width: 200,
        },
        {
          ...budgetPerfLabelReviewColumn,
          masked: true,
          width: 200,
        },
        {
          ...budgetSeniorityColumn,
          width: 200,
        },
        {
          ...budgetLineManagerColumn,
          width: 140,
        },
        {
          ...budgetStartDateColumn,
          width: 140,
        },
        {
          ...budgetLocationColumn,
          width: 140,
        },
        {
          ...budgetStatusColumn,
          width: 140,
        },
      ],
    }),
    [],
  )

  const name = 'name' in data ? data.name : undefined
  const TalentTypes = getTalentTypes(data.id)
  const [showOnlyEmployees, setShowOnlyEmployees] = useState(true)
  const [openAssignEmployee, updateOpenAssignEmployee] = useState(false)
  const [employeeToAssign, updateEmployeeToAssign] = useState<OptionInterface>()
  const isNewTable = useIsNewTable()

  const { filter } = TalentTypes[talentType]

  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)

  const table = useTalentTable(filter)

  const row = useMemo(() => {
    let ROW
    switch (talentType) {
      case TalentType.Role:
        ROW = talentRoleRow
        break
      case TalentType.DirectReport:
        ROW = directReportTalent
        break
      default:
        ROW = talentRow
    }
    return ROW
  }, [talentType, multipleSenioritiesEnabled])

  const isEmployeeInTheTeam = useMemo(() => {
    return table.data.some(employee => employee.id === employeeToAssign?.id)
  }, [employeeToAssign, table.data])

  const handleOnlyEmployeesSwitch = () => {
    if (!showOnlyEmployees) {
      table.resetFiltersAndSorting(
        [
          {
            filters: [{ name: '0', id: 0 }],
            columnName: 'is_requisition',
            nonResettable: true,
          },
          {
            filters: defaultEmployeeStatusFilter,
            columnName: 'status',
            nonResettable: true,
          },
          ...table.filterBy,
        ],
        table.sortBy,
      )
    } else {
      table.resetFiltersAndSorting(
        [
          {
            filters: [],
            columnName: 'is_requisition',
            nonResettable: true,
          },
          {
            filters: [],
            columnName: 'status',
            nonResettable: true,
          },
          ...table.filterBy,
        ],
        table.sortBy,
      )
    }
    setShowOnlyEmployees(!showOnlyEmployees)
  }

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {}), {
      initialValues: {
        ...getRequisitionInitialValues(user),
        team: { name, id: data.id },
      },
    })
  }

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  const statsLink = TalentTypes[talentType].statLink

  const isEmployeeType =
    talentType === TalentType.FunctionalReport || talentType === TalentType.DirectReport

  const hasExpensesPermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeeExpenses,
  )
  const hasPerformancePermission = !!data.field_options.permissions?.includes(
    PermissionTypes.ViewEmployeePerformance,
  )
  const canBulkEdit = permissions.includes(PermissionTypes.BulkEditEmployees)

  const hideSpend =
    !budgetEnabled || !hasExpensesPermission || talentType === TalentType.FunctionalReport

  const hiddenCells: Partial<Record<keyof BudgetInterface, boolean>> = {
    monthly_expense: hideSpend,
    annual_expense: hideSpend,
    performance_label: !hasPerformancePermission,
    line_manager_id: talentType === TalentType.DirectReport,
    quality_control_id: talentType === TalentType.FunctionalReport,
    team_id: talentType === TalentType.Team,
    specialisation_id: talentType === TalentType.Specialisation,
    [budgetFunctionalManagerColumn.idPoint]:
      !performanceSettings?.enable_functional_management,
  }

  const onCurrencyChange = (currency: string) => {
    table.onFilterChange({
      filters: [{ name: currency, id: currency }],
      columnName: 'target_currency',
      nonResettable: true,
    })
  }

  return (
    <>
      {talentType === TalentType.Team && (
        <TransferEmployeePopup
          data={data}
          employeeToAssign={employeeToAssign}
          isEmployeeInTheTeam={isEmployeeInTheTeam}
          onAfterAssign={table.refresh}
          onClose={() => updateOpenAssignEmployee(false)}
          open={openAssignEmployee}
          updateEmployeeToAssign={updateEmployeeToAssign}
        />
      )}

      <Table.Widget>
        <Table.Widget.Info>
          <HStack width="100%">
            {!isNewTable && (
              <>
                {((isNewLayout && isEmployeeTalent) ||
                  isOrganisationTalent ||
                  isFunctionalTalent) &&
                  navigation}
                <Spacer />
              </>
            )}
            <TalentHeader
              id={data.id}
              filterBy={table.filterBy}
              tableStats={table.stats}
              type={talentType}
              showExpensesStats={!hideSpend}
              showPerformanceStats={hasPerformancePermission}
              onCurrencyChange={onCurrencyChange}
              period="annual"
            />
          </HStack>
        </Table.Widget.Info>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {talentType === TalentType.Team && (
              <>
                {data.field_options.permissions?.includes(
                  PermissionTypes.AddRequisitionForTeams,
                ) && (
                  <MoreBar.Action onClick={handleNewRow} useIcon={Plus}>
                    Add Requisition
                  </MoreBar.Action>
                )}
                {data.field_options.permissions?.includes(
                  PermissionTypes.TransferEmployeesTeams,
                ) && (
                  <MoreBar.Action
                    onClick={() => updateOpenAssignEmployee(true)}
                    useIcon={ArrowRightLeft}
                  >
                    Transfer Employee
                  </MoreBar.Action>
                )}
              </>
            )}
            {canBulkEdit &&
              (talentType === TalentType.Team ||
                talentType === TalentType.Department) && (
                <MoreBar.Action
                  onClick={() => {
                    const filterKey = {
                      [TalentType.Team]: 'team__id',
                      [TalentType.Department]: 'team__department__id',
                    }[talentType]

                    navigateTo(
                      `${pathToUrl(
                        ROUTES.FORMS.BULK_EDIT_EMPLOYEES.SELECT,
                      )}?${filterKey}=${data.id}`,
                    )
                  }}
                  useIcon={Pencil}
                >
                  Edit
                </MoreBar.Action>
              )}
            {statsLink && (
              <HideIfCommercial>
                <MoreBar.Action
                  target="_blank"
                  rel="noopener noreferrer"
                  href={statsLink}
                  use="a"
                  useIcon={ChartBarWithArrow}
                >
                  See stats
                </MoreBar.Action>
              </HideIfCommercial>
            )}
            {!isEmployeeType && (
              <MoreInfoButton href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1343554313/How+to+manage+your+department+budget" />
            )}
            <AllowedExportMenu
              request={getRevolutersExport}
              filterQuery={filterQuery}
              fileName="Employees"
              type={talentType}
            />
            <Table.ColumnsSettingsButton />
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Filters>
          {isNewTable && navigation}
          <FilterButton active={showOnlyEmployees} onClick={handleOnlyEmployeesSwitch}>
            Active employees
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Table>
          <AdjustableTable<BudgetInterface, BudgetStats>
            name={TableNames.Talent}
            useWindowScroll
            row={row}
            tableSettings={{
              hidden: [
                deliverablesTalentColumn.title,
                skillsTalentColumn.title,
                cultureTalentColumn.title,
              ],
              visible: [],
            }}
            emptyState={<EmptyTableRaw title="No data" />}
            hiddenCells={hiddenCells}
            {...table}
            dataType="Employee"
          />
        </Table.Widget.Table>
      </Table.Widget>
    </>
  )
}

export default Talent
