import React from 'react'

export const roleSetupGuide = [
  {
    question: 'What is the role of Functions in Revolut People?',
    answer: (
      <ul>
        <li>
          Employee function relates to the broader purpose or contribution that an
          employee makes to the organization. It encompasses the tasks, responsibilities,
          and activities that an employee performs as part of their rule to help the
          organization achieve its objectives.
        </li>
        <li>
          Employee functions can vary widely across different roles and departments. For
          instance, the functions of an HR Manager may include recruitment, employee
          training, and policy development, while the functions of a Marketing Specialist
          may involve market research, campaign planning, and promotional activities.
        </li>
        <li>
          Employee functions are essential for the efficient and effective operation of an
          organization, as they collectively contribute to the achievement of business
          goals.
        </li>
      </ul>
    ),
  },
]
