import React from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'

type Props = {
  isSuccess: boolean | null
  onClose: () => void
}

const BulkStatusPopup = ({ isSuccess, onClose }: Props) => {
  return (
    <StatusPopup
      variant={isSuccess ? 'success-result' : 'error'}
      open={isSuccess !== null}
      onClose={() => onClose()}
      // @ts-expect-error
      labelButtonClose="Close success popup"
    >
      <StatusPopup.Title data-testid="bulk_status_popup_title">
        {isSuccess
          ? 'The task has been queued. It might take a few minutes to complete.'
          : 'Something went wrong. Please try again'}
      </StatusPopup.Title>
      <StatusPopup.Actions>
        <Button elevated onClick={onClose}>
          Close
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default BulkStatusPopup
