import React, { useState } from 'react'
import {
  Color,
  MoreBar,
  StatusPopup,
  Text,
  useStatusPopup,
  VStack,
} from '@revolut/ui-kit'
import { Cross, Pencil } from '@revolut/icons'

import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { formatDate } from '@src/utils/format'
import { cancelEmployeeInactivity } from '@src/api/employees'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { StatusChange } from '../StatusChange/StatusChange'
import { UseEmployeeStatusTransitionsReturnType } from '../StatusChange/hooks'
import { UseEmployeeInactivityDataReturnType } from './hooks'

type Props = {
  employee: EmployeeInterface
  employeeInactivity: UseEmployeeInactivityDataReturnType
  statusTransitions: UseEmployeeStatusTransitionsReturnType
}
export const InactivityWarning = ({
  employee,
  employeeInactivity,
  statusTransitions,
}: Props) => {
  const [isCancelling, setIsCancelling] = useState<boolean>()

  const statusPopup = useStatusPopup()

  if (!employeeInactivity.isScheduled) {
    return null
  }

  return (
    <ActionWidget
      avatarColor={Color.GREY_TONE_50}
      title={
        statusTransitions.isInactiveEmployee
          ? 'Employee was set as inactive'
          : 'Employee is scheduled to be inactive'
      }
    >
      <VStack space="s-8">
        <VStack space="s-2">
          <Text color={Color.GREY_TONE_50}>
            Reason: {employeeInactivity.data?.inactivity_reason?.name}
          </Text>
          <Text color={Color.GREY_TONE_50}>
            Dates: from {formatDate(employeeInactivity.data?.inactivity_start_date || '')}{' '}
            to {formatDate(employeeInactivity.data?.inactivity_end_date || '')}
          </Text>
          {statusTransitions.isInactiveEmployee && (
            <Text color={Color.GREY_TONE_50}>
              Employee will be activated automatically at the end of inactivity period.
            </Text>
          )}
        </VStack>
        <MoreBar>
          {statusTransitions.isInactiveEmployee && (
            <StatusChange
              data={employee}
              title="Switch to active"
              notification="Status set to Active"
              newStatus={{ id: IdStatuses.active, name: 'Active' }}
              statusTransitions={statusTransitions}
              onStatusTransitionSuccess={async () => {
                await employeeInactivity.refetch()
              }}
            />
          )}
          {!statusTransitions.isInactiveEmployee && (
            <>
              <MoreBar.Action
                useIcon={Pencil}
                onClick={() => employeeInactivity.setIsPopupOpened(true)}
              >
                Edit
              </MoreBar.Action>
              <MoreBar.Action
                variant="negative"
                useIcon={Cross}
                pending={isCancelling}
                onClick={async () => {
                  try {
                    setIsCancelling(true)
                    await cancelEmployeeInactivity(employee.id)
                    await employeeInactivity.refetch()

                    statusPopup.show(
                      <StatusPopup variant="success">
                        <StatusPopup.Title>
                          Employee inactivity was successfully cancelled
                        </StatusPopup.Title>
                      </StatusPopup>,
                    )
                  } finally {
                    setIsCancelling(false)
                  }
                }}
              >
                Cancel
              </MoreBar.Action>
            </>
          )}
        </MoreBar>
      </VStack>
    </ActionWidget>
  )
}
