import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  AnchorCopy,
  HoverableEmptySpace,
  placement,
  TOOLTIP_DELAY,
  TooltipContainer,
  TooltipPopover,
} from '@components/Tooltip/Tooltip'
import { getTagValueFromNode, TagValue } from '@components/Chat/ChatTextEditor/TagBlot'
import { Modal } from '@components/Modals/Modal'
import { Flex } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export type UseTagTooltipReturnType = {
  getOnTagMouseOver: (node: HTMLAnchorElement) => EventListener
  onTagMouseLeave: (e?: Event) => void
  onWheel: () => void
  tagTooltip: React.ReactNode
}
const tooltipPlacement: placement = 'top'

const useTagTooltip = (): UseTagTooltipReturnType => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)
  const [hovered, setHovered] = useState<boolean>(false)
  const [tooltipPosition, setTooltipPosition] = useState<DOMRect | null>(null)
  const [hoveredTag, setHoveredTag] = useState<{
    node: HTMLElement | null
    value: TagValue
  } | null>(null)

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    if (hovered) {
      timeout = setTimeout(() => setShowTooltip(true), TOOLTIP_DELAY)
    } else {
      timeout = setTimeout(() => {
        exitHovering()
      }, TOOLTIP_DELAY / 2)
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [hovered])

  useEffect(() => {
    if (hoveredTag?.node) {
      const tagRect = hoveredTag.node.getBoundingClientRect()
      setTooltipPosition(tagRect)
      setHovered(true)
    }
  }, [hoveredTag])

  const exitHovering = useCallback(() => {
    setShowTooltip(false)
    setHoveredTag(null)
    setTooltipPosition(null)
  }, [])

  const onMouseLeave = useCallback(() => {
    setHovered(false)
    exitHovering()
  }, [])

  const onMouseMove = useCallback(() => {
    setHovered(true)
  }, [])

  const getOnTagMouseOver = useCallback((node: HTMLAnchorElement) => {
    return () =>
      setHoveredTag({
        node,
        value: getTagValueFromNode(node),
      })
  }, [])

  const onTagMouseLeave = useCallback(() => {
    setHovered(false)
  }, [])

  const tagTooltip = useMemo(() => {
    return (
      <>
        {hoveredTag && tooltipPosition && showTooltip && (
          <Modal>
            <AnchorCopy position={tooltipPosition}>
              <TooltipContainer placement={tooltipPlacement} backgroundColor="background">
                <HoverableEmptySpace
                  placement={tooltipPlacement}
                  backgroundColor="background"
                >
                  <TooltipPopover
                    placement={tooltipPlacement}
                    onMouseMove={onMouseMove}
                    onMouseLeave={onMouseLeave}
                  >
                    <Flex py="s-8" px="s-16" color="background" minWidth="max-content">
                      <UserWithAvatar {...hoveredTag.value} />
                    </Flex>
                  </TooltipPopover>
                </HoverableEmptySpace>
              </TooltipContainer>
            </AnchorCopy>
          </Modal>
        )}
      </>
    )
  }, [
    tooltipPosition,
    hoveredTag,
    showTooltip,
    tooltipPlacement,
    onMouseMove,
    onMouseLeave,
  ])

  return useMemo(() => {
    return {
      tagTooltip,
      onTagMouseLeave,
      getOnTagMouseOver,
      onWheel: onMouseLeave,
    }
  }, [tagTooltip, onTagMouseLeave, getOnTagMouseOver, onMouseLeave])
}

export default useTagTooltip
