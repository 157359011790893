import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { chain, Color, Group, IconButton, MoreBar, Text, Token } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { PageBody } from '@src/components/Page/PageBody'
import { ArchiveButton, EditButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import { selectPermissions } from '@src/store/auth/selectors'
import { faqTopicStatusToColor } from '@src/apps/General/HelpCenter/helpers'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

export const HelpCenterTopicPreview = () => {
  const params = useParams()
  const permissions = useSelector(selectPermissions)
  const { initialValues } = useLapeContext<FaqTopicInterface>()

  const canEdit = permissions.includes(PermissionTypes.ChangeFaqTopic)

  return (
    <>
      <PageHeader
        backUrl={ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE}
        title={chain(
          initialValues.title,
          initialValues.status ? (
            <Text color={faqTopicStatusToColor(initialValues.status.id)}>
              {initialValues.status.name}
            </Text>
          ) : null,
        )}
      />
      <PageBody>
        <MoreBar>
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.APPS.HELP_CENTER.TOPIC.EDIT, params)}
          />
          <ArchiveButton
            isVisible={canEdit}
            globalPermissions={[PermissionTypes.ChangeFaqTopic]}
            statusFieldName="status.id"
            unArchiveStatus={Statuses.active}
          />
        </MoreBar>
        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Item title="Topic name" field="title" />
            <FormPreview.Item title="Topic category" field="category.name" />
            <FormPreview.Item
              title="Customer portal"
              field="customer_portal"
              insert={() =>
                initialValues.customer_portal ? (
                  <IconButton
                    use="a"
                    target="_blank"
                    href={initialValues.customer_portal}
                    size={16}
                    color={Color.BLUE}
                    useIcon="LinkExternal"
                  />
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item
              title="Link to support"
              field="link_to_support"
              insert={() =>
                initialValues.link_to_support ? (
                  <IconButton
                    use="a"
                    target="_blank"
                    href={initialValues.link_to_support}
                    size={16}
                    color={Token.color.blue}
                    useIcon="LinkExternal"
                  />
                ) : (
                  '-'
                )
              }
            />
            <FormPreview.Item<FaqTopicInterface>
              title="Maintainer"
              field="maintainer.id"
              insert={() => <UserWithAvatar {...initialValues.maintainer} />}
            />
            <FormPreview.Details title="Description" field="description" />
          </Group>
        </FormPreview>
      </PageBody>
    </>
  )
}
