import React from 'react'
import {
  ActionButton,
  Box,
  StatusPopup,
  Text,
  useStatusPopup,
  Widget,
} from '@revolut/ui-kit'
import { PermissionTypes } from '@src/store/auth/types'
import {
  CandidateInterface,
  InterviewRecruiterInterface,
  InterviewSpecialisationInterface,
} from '@src/interfaces/interviewTool'
import SenioritySelector from '@src/features/SenioritySelector/SenioritySelector'
import {
  createInterviewRound,
  getCandidateEligibleRequisitions,
} from '@src/api/recruitment/interviews'
import { useLape } from 'lape'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

type Props = {
  candidate: CandidateInterface
}

const InterviewStartCard = ({ candidate }: Props) => {
  const statusPopup = useStatusPopup()
  const state = useLape<{
    specialisation?: InterviewSpecialisationInterface
    seniority?: SeniorityInterface
    recruiter?: InterviewRecruiterInterface
    isLoading: boolean
  }>({
    specialisation: undefined,
    seniority: undefined,
    recruiter: candidate.recruiter,
    isLoading: false,
  })
  const canStart = candidate.field_options?.permissions?.includes(
    PermissionTypes.AddInterviewRound,
  )

  const fetchEligibleRequisitions = async () => {
    const result = await getCandidateEligibleRequisitions(candidate.id)
    return result.data.results
  }

  const handleStart = async () => {
    if (!state.specialisation?.id) {
      return
    }

    try {
      state.isLoading = true
      await createInterviewRound(
        candidate.id,
        state.specialisation.id,
        state.seniority?.id,
        state.recruiter?.id,
      )

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Specialisation assigned</StatusPopup.Title>
        </StatusPopup>,
      )

      window.location.reload()
    } finally {
      state.isLoading = false
    }
  }

  if (!canStart) {
    return null
  }

  return (
    <Widget p="s-16">
      <Text fontSize="primary" fontWeight={500} use="div" mb="10px">
        Start interview process
      </Text>
      <Text fontSize="caption">
        Select a specialisation, seniority (optional) and recruiter (optional) for which
        you would like to start the interview process.
      </Text>
      <Box my="s-16">
        <RadioSelectInput<InterviewSpecialisationInterface>
          label="Specialisation"
          selector={fetchEligibleRequisitions}
          selectDefaultOption={options =>
            options.find(option => option.value.id === state.specialisation?.id)
          }
          value={state.specialisation}
          onChange={option => {
            state.specialisation = option || undefined
          }}
        />
      </Box>

      <Box mb="s-16">
        <SenioritySelector
          specialisationId={state.specialisation?.id || null}
          label="Seniority (optional)"
          onChange={option => {
            state.seniority = option || undefined
          }}
          value={state.seniority}
        />
      </Box>

      <RadioSelectInput<InterviewRecruiterInterface>
        label="Recruiter (optional)"
        selector={selectorKeys.employee}
        selectDefaultOption={options =>
          options.find(option => option.value.id === state.recruiter?.id)
        }
        value={state.recruiter}
        onChange={option => {
          state.recruiter = option || undefined
        }}
      />

      <ActionButton
        variant="accent"
        mt="s-16"
        onClick={handleStart}
        disabled={!state.specialisation?.id || state.isLoading}
        pending={state.isLoading}
      >
        Start interview process
      </ActionButton>
    </Widget>
  )
}

export default InterviewStartCard
