import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  ReviewCategory,
  ReviewScorecardInterface,
  ReviewerRelation,
} from '@src/interfaces/performance'
import styled from 'styled-components'
import * as yup from 'yup'
import {
  overallFeedbackValidation,
  useGetFeedbackSidebarData,
} from '@src/utils/performance'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import PreviousOverallFeedback from '@components/ScorecardGeneral/PreviousOverallFeedback'
import { Box } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import PerformanceEditSummarySidebar from '@src/pages/Forms/EmployeePerformance/PerformanceEditSummarySidebar'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { AnytimeFeedbackSidebar } from '@components/AnytimeFeedback/AnytimeFeedbackSidebar'
import { Widget } from '@revolut/ui-kit'
import SummaryBanner from '@src/pages/Forms/EmployeePerformance/components/SummaryBanner'
import { AdditionalScorecards } from './AdditionalScorecards'
import { EmployeePerformanceActionsBar } from './EmployeePerformanceActionsBar'
import { useSummarySidebarContext } from '@src/features/EmployeePerformance/SummarySidebarProvider'

const Container = styled.div`
  width: 100%;
  margin: 0 120px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  counter-reset: section;
`

const LeftWrapper = styled.div``

const Sticky = styled.div`
  position: sticky;
  top: 48px;
`

const Caption = styled.div`
  padding: 0 16px 16px 0;
`

export const validator = {
  review_data: yup.object().shape({
    overall_feedback: yup
      .object()
      .shape<any>({
        pros: yup.array().of(yup.string()),
        cons: yup.array().of(yup.string()),
      })
      .test(
        'overall_feedback',
        'Overall feedback is obligatory. You cannot submit review without it.',
        overallFeedbackValidation,
      ),
  }),
}

const Summary = () => {
  const { values, errors, change } = useLapeContext<ReviewScorecardInterface>()
  const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const { hash } = useLocation()

  const [isFeedbackSidebarOpen, setIsFeedbackSidebarOpen] = useState(false)
  const { feedbackList, refetchFeedbackList, employeeId } = useGetFeedbackSidebarData()
  const reviewsSidebar = useSummarySidebarContext()
  useEffect(() => {
    if (!values.review_data.overall_feedback) {
      change('review_data.overall_feedback', {
        pros: [],
        cons: [],
      })
    }
    if (values.visible_to_reviewee === null) {
      change('visible_to_reviewee', false)
    }
  }, [])

  useEffect(() => {
    if (hash === SCROLL_ERROR_HASH && errors.review_data?.overall_feedback) {
      !!prosElement && prosElement.focus()
    }
  }, [hash])

  const additionalScorecards = values.review_data.cycle_sections

  const getOpenSidebarHandler = (cb: () => void) => () => {
    setIsOpenSidebar(false)
    setIsFeedbackSidebarOpen(false)
    reviewsSidebar && reviewsSidebar.hideSummarySidebar()

    cb()
  }

  return (
    <Container>
      <LeftWrapper>
        <Sticky>
          <EmployeePerformanceActionsBar
            onSummaryRequested={getOpenSidebarHandler(() => setIsOpenSidebar(true))}
            onFeedbackRequested={{
              handler: getOpenSidebarHandler(() => setIsFeedbackSidebarOpen(true)),
              feedbackCount: feedbackList?.length,
            }}
            onReviewsRequested={
              reviewsSidebar
                ? getOpenSidebarHandler(reviewsSidebar.showSummarySidebar)
                : undefined
            }
          />
          <AutoStepper width={628}>
            <AutoStepperTitle title="Overall feedback" variant="letter" />
            {values.category === ReviewCategory.Performance &&
              values.reviewer_relation !== ReviewerRelation.Self && <SummaryBanner />}
            <OverallFeedback
              pros={values.review_data.overall_feedback?.pros}
              cons={values.review_data.overall_feedback?.cons}
              onChangePros={val => {
                change('review_data.overall_feedback.pros', val.split('\n'))
              }}
              onChangeCons={val => {
                change('review_data.overall_feedback.cons', val.split('\n'))
              }}
              onGetProsElement={elem => {
                setProsElement(elem)
              }}
            />
            <PreviousOverallFeedback
              data={values.review_data.previous_reviews_by_cycle}
            />

            {values.category === ReviewCategory.Performance &&
              !!additionalScorecards?.length && (
                <>
                  <AutoStepperTitle title="Additional questions" variant="letter" />
                  {additionalScorecards.map((scorecard, ind) => (
                    <Box mb="s-16" key={scorecard.id}>
                      <AdditionalScorecards data={scorecard} scorecardIdx={ind} />
                    </Box>
                  ))}
                </>
              )}

            {values.visible_to_reviewee !== undefined && (
              <>
                <AutoStepperTitle
                  title={
                    <>Allow {values.reviewed_employee.display_name} to see your review?</>
                  }
                  variant="letter"
                />
                <Widget p="s-16">
                  <Caption>
                    {values.category === ReviewCategory.Upwards ? (
                      <>
                        If you select YES, {values.reviewed_employee.display_name} will
                        see your review anonymously.{' '}
                        {values.reviewed_employee.display_name} will not see your name on
                        the review, but only your feedback and ratings. <br /> If you
                        select NO, {values.reviewed_employee.display_name} will not see
                        anything from you (no ratings and no comments). Your review will
                        only be visible to their manager.
                      </>
                    ) : (
                      <>
                        You can let {values.reviewed_employee.display_name} see your
                        review. If you select 'No', only{' '}
                        {values.reviewed_employee.display_name}'s managers will see this
                        review.
                      </>
                    )}
                  </Caption>
                  <LapeNewRadioButtons
                    name="visible_to_reviewee"
                    options={[
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ]}
                    variant="cell"
                  />
                </Widget>
              </>
            )}
          </AutoStepper>
        </Sticky>
      </LeftWrapper>
      <PerformanceEditSummarySidebar
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
      {feedbackList && (
        <AnytimeFeedbackSidebar
          isOpen={isFeedbackSidebarOpen}
          handleClose={() => setIsFeedbackSidebarOpen(false)}
          feedbackList={feedbackList}
          refetchFeedbackList={refetchFeedbackList}
          employeeId={employeeId}
        />
      )}
    </Container>
  )
}

export default connect(Summary)
