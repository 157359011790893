import React from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'

import Icon from '@components/Icon/Icon'
import { EmployeeButtonProps } from '../types'

export const Jira = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  if (!data.id || !data.lifecycle_ticket_url) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use="a"
        useIcon={() => <Icon color={Color.BLUE} type="Jira" />}
        href={data.lifecycle_ticket_url}
        target="_blank"
        color={Color.FOREGROUND}
      >
        Jira
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      useIcon={() => <Icon type="Jira" />}
      use="a"
      href={data.lifecycle_ticket_url}
      target="_blank"
    >
      Jira ticket
    </MoreBar.Action>
  )
}
