import { useMemo } from 'react'
import { useGetHiringEnabledLocations } from '@src/api/requisitions'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { useGetSpecialisationPreferredHiringLocations } from '@src/api/specialisations'

export const getApproveButtonTooltipLabel = (
  showMrtTooltip: boolean,
  showCompensationBandTooltip: boolean,
) => {
  if (showMrtTooltip) {
    return 'Can’t approve due to missing MRT questionnaire'
  }

  if (showCompensationBandTooltip) {
    return 'Can’t approve due to missing compensation band'
  }

  return undefined
}

export const useGetHiringRiskLocations = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const { data: enabledLocationsOptions } = useGetHiringEnabledLocations()
  const { data: organisationSettings } = useGetOrganisationSettings()
  const { data: preferredLocationsOptions } =
    useGetSpecialisationPreferredHiringLocations(values?.specialisation?.id)

  const enableLocationLimitations = organisationSettings?.enable_location_limitations

  const { highRiskLocations, mediumRiskLocations } = useMemo(() => {
    const highRisk = new Set<string>()
    const mediumRisk = new Set<string>()

    if (enableLocationLimitations) {
      values.locations?.forEach(location => {
        if (location.country_employment_legal_risk === 'high') {
          highRisk.add(location.name)
        }
        if (
          preferredLocationsOptions &&
          !preferredLocationsOptions.find(item => item.id === location.id) &&
          location.country_employment_legal_risk !== 'high'
        ) {
          mediumRisk.add(location.name)
        }
      })
    }

    return { highRiskLocations: highRisk, mediumRiskLocations: mediumRisk }
  }, [
    values.locations,
    enabledLocationsOptions,
    enableLocationLimitations,
    preferredLocationsOptions,
  ])

  return { highRiskLocations, mediumRiskLocations }
}
