import React, { useState } from 'react'
import { Box, Cell, Flex, Group, MoreBar, Subheader } from '@revolut/ui-kit'
import { Archive, Lightning } from '@revolut/icons'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { DocumentsRuleInterface } from '@src/interfaces/documentsRule'
import LapeNewCodeEditor from '@src/components/Inputs/LapeFields/LapeNewCodeEditor'
import { getScheduleMessage, Parameter, TemplateType } from '.'
import SettingsButtons from '@src/features/SettingsButtons'
import { Statuses } from '@src/interfaces'
import {
  changeRuleStatus,
  documentRulesFormRequest,
  sendRule,
} from '@src/api/documentsRules'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { DeleteButtonLape } from '../FormButtons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'

interface PreviewProps {
  templateType?: TemplateType
  requiredParameters: string[]
}

const Preview = ({ templateType, requiredParameters }: PreviewProps) => {
  const { values, initialValues, dirty, reset } = useLapeContext<DocumentsRuleInterface>()

  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [sendLoading, setSendLoading] = useState(false)
  const [changeStatusPending, setChangeStatusPending] = useState(false)

  const handleSend = async () => {
    try {
      setSendLoading(true)
      const result = await sendRule(values.id)
      if (result.data?.url) {
        window.open(result.data?.url, `_blank`, 'width=600,height=800')
        setConfirmationOpen(false)
      } else {
        pushNotification({
          value: 'Documents sent successfully',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        setConfirmationOpen(false)
      }
    } finally {
      setSendLoading(false)
    }
  }

  const onChangeStatus = (status: Statuses) => {
    setChangeStatusPending(true)

    changeRuleStatus(values.id, status)
      .then(response => reset(response.data))
      .finally(() => setChangeStatusPending(false))
  }

  return (
    <>
      <PageBody>
        <SettingsButtons mb="s-16">
          <EditButton
            route={pathToUrl(ROUTES.FORMS.DOCUMENT_RULE.GENERAL, { id: values.id })}
          />
          {initialValues.status?.id === Statuses.created ||
          initialValues.status?.id === Statuses.archived ? (
            <MoreBar.Action
              onClick={() => onChangeStatus(Statuses.active)}
              useIcon={Lightning}
              pending={changeStatusPending}
            >
              Activate
            </MoreBar.Action>
          ) : null}
          {initialValues.status?.id === Statuses.active ? (
            <MoreBar.Action
              onClick={() => onChangeStatus(Statuses.archived)}
              useIcon={Archive}
              variant="negative"
              pending={changeStatusPending}
            >
              Archive
            </MoreBar.Action>
          ) : null}
          {!dirty && (
            <MoreBar.Action onClick={() => setConfirmationOpen(true)}>
              Send
            </MoreBar.Action>
          )}
          <DeleteButtonLape
            useMoreBar
            data={values}
            backUrl={ROUTES.APPS.DOCUMENTS.RULES}
            deleteApi={documentRulesFormRequest.delete!}
            title="Document rule"
          />
        </SettingsButtons>

        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Item title="Name" field="name" />
            <FormPreview.Item<DocumentsRuleInterface>
              title="Owner"
              field="owner"
              type="employee"
            />
            <FormPreview.Item<DocumentsRuleInterface>
              title="Issued by"
              field="issuer"
              type="employee"
            />
          </Group>

          <Box mt="s-16">
            <FormPreview.Details title="Description" field="description" />
          </Box>
        </FormPreview>

        <FormPreview title="Template" data={initialValues}>
          <Group>
            <FormPreview.Item<DocumentsRuleInterface>
              title="Template"
              field="document_template.name"
              to={rule =>
                pathToUrl(ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW, {
                  id: rule.document_template?.id,
                })
              }
            />
            <FormPreview.Item
              title="Template type"
              insert={() => templateType?.name ?? ''}
            />
            <FormPreview.Item title="Rule type" field="rule_type.name" />
          </Group>
        </FormPreview>

        <Subheader variant="nested">
          <Subheader.Title>Parameters</Subheader.Title>
        </Subheader>
        <Group>
          {requiredParameters.length !== 0 && (
            <Group>
              <Cell mb="-s-16">
                <Flex justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
                  {requiredParameters.map(parameter => (
                    <Parameter key={parameter}>{parameter}</Parameter>
                  ))}
                </Flex>
              </Cell>
            </Group>
          )}
          <LapeNewCodeEditor name="sql_query" placeholder="SQL Template" readonly />
        </Group>

        <FormPreview title="Schedule" data={initialValues}>
          <Group>
            <FormPreview.Item title="Rule schedule" field="schedule_type.name" />
            {initialValues.schedule_type?.id === 'scheduled' ? (
              <FormPreview.Item title="Schedule period" insert={getScheduleMessage} />
            ) : null}
          </Group>
        </FormPreview>
      </PageBody>

      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        label="Are you sure you want to send out the documents? "
        body={
          <p>
            The recipients will be able to access and sign their documents from their
            profile. They will also be notified via Slack.
          </p>
        }
        loading={sendLoading}
        onConfirm={handleSend}
        onReject={() => setConfirmationOpen(false)}
      />
    </>
  )
}

export default Preview
