import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { General } from './General'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings } from '@src/api/settings'

const routes = [
  {
    title: 'Legal Entities settings',
    description: 'Settings for the legal entities',
    path: ROUTES.SETTINGS.ENTITIES,
    url: ROUTES.SETTINGS.ENTITIES,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: General,
  },
]

export const EntitiesSettings = () => {
  return <SettingsForm routes={routes} api={organisationSettings} />
}
