import React from 'react'
import {
  JobPostingInterface,
  JobPostingLocationTypeInterface,
} from '@src/interfaces/jobPosting'
import Loader from '@components/CommonSC/Loader'
import { Box, InputGroup, TabBar, Text } from '@revolut/ui-kit'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import { CriteriaSidebarFormProps } from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'

interface Props extends Pick<CriteriaSidebarFormProps, 'isSelector'> {
  jobPosting?: JobPostingInterface
  setJobPosting: (value: JobPostingInterface) => void
  jobPostings?: JobPostingInterface[]
  loading?: boolean
}

const mapJobPostingToJobPostingLocation = (jp: JobPostingInterface) => {
  const specialisation = jp.specialisation
    ? {
        id: +jp.specialisation.id,
        name: jp.specialisation.name ?? '',
      }
    : undefined
  return {
    id: jp.id,
    name: jp.name,
    locations: jp.locations ?? [],
    specialisation,
  }
}

const JobPostingSidebarContent = ({
  isSelector,
  jobPosting,
  jobPostings,
  loading,
  setJobPosting,
}: Props) => {
  const renderSelectors = () => {
    if (loading) {
      return <Loader />
    }

    if (isSelector) {
      const options = (jobPostings ?? []).map(jp => ({
        label: jp.name,
        value: mapJobPostingToJobPostingLocation(jp),
      }))
      const value = jobPosting ? mapJobPostingToJobPostingLocation(jobPosting) : undefined
      return (
        <RadioSelectInput<JobPostingLocationTypeInterface>
          label="Job postings"
          value={value}
          clearable={false}
          onChange={option => {
            if (option) {
              const newJobPosting = jobPostings?.find(({ id }) => option.id === id)
              if (newJobPosting) {
                setJobPosting(newJobPosting)
              }
            }
          }}
          options={options}
        >
          {renderOption}
        </RadioSelectInput>
      )
    }

    return (
      <TabBar
        display={jobPostings?.length && jobPostings?.length > 1 ? undefined : 'none'}
        behaviour="scroll"
      >
        {jobPostings?.map(posting => (
          <TabBar.Item
            aria-selected={posting.id === jobPosting?.id}
            onClick={() => setJobPosting(posting)}
            key={posting.id}
            // use="button" has some issues when the text is long, something with UI kit width calculations
            style={{ cursor: 'pointer' }}
            use="div"
          >
            <Text use="div" wrap="nowrap">
              {posting.name}
            </Text>
          </TabBar.Item>
        ))}
      </TabBar>
    )
  }

  return (
    <Box>
      <InputGroup>
        {renderSelectors()}
        {jobPosting && (
          <JobDescription id={jobPosting.id} locations={jobPosting.locations} />
        )}
      </InputGroup>
    </Box>
  )
}

export default JobPostingSidebarContent
