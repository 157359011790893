import React, { useMemo, useState } from 'react'
import { useGetCareerSettings, useGetCompanyLogo } from '@src/api/settings'
import { ActionButton, Avatar, Box, Item, Subheader, VStack } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { CareersPositionPreviewSidebar } from '@src/components/CareersPositionPreviewSidebar/CareersPositionPreviewSidebar'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface, LocationSource } from '@src/interfaces/jobPosting'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import uniqBy from 'lodash/uniqBy'

interface CareersWebsiteProps {
  requisitions: RequisitionInterface[]
}

export const CareersWebsite = ({ requisitions }: CareersWebsiteProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const [showPreview, setShowPreview] = useState(false)
  const { data: careersPageSettings } = useGetCareerSettings({
    refetchOnWindowFocus: true,
  })
  const { data: companyLogoData } = useGetCompanyLogo()
  const careersPageEnabled = careersPageSettings?.publish_career_website
  const requisitionLocations = useMemo(
    () =>
      uniqBy(requisitions.flatMap(({ locations }) => locations).filter(Boolean), 'id'),
    [requisitions],
  )
  return (
    <>
      <VStack gap="s-16">
        <Box>
          <Subheader>
            <Subheader.Title>Careers website</Subheader.Title>
          </Subheader>
        </Box>
        <Item>
          <Item.Avatar>
            <Avatar
              useIcon={!companyLogoData?.url ? 'Globe' : undefined}
              image={companyLogoData?.url}
            />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Publish on your company’s careers site</Item.Title>
            <Item.Description>
              {careersPageEnabled
                ? 'Publish this job posting to your company careers page'
                : 'Enable careers page in settings to publish to your company careers page'}
            </Item.Description>
          </Item.Content>
          <Item.Side>
            {careersPageEnabled ? (
              <ActionButton
                useIcon="EyeShow"
                onClick={() => {
                  setShowPreview(!showPreview)
                }}
              >
                Preview
              </ActionButton>
            ) : (
              <ActionButton
                target="_blank"
                to={pathToUrl(ROUTES.SETTINGS.JOB_POSTING.CAREER_PAGE)}
                use={InternalLink}
                useIcon="Gear"
              >
                Settings
              </ActionButton>
            )}
          </Item.Side>
        </Item>
      </VStack>
      <CareersPositionPreviewSidebar
        description={
          values.sections
            ?.filter(section => section.content)
            ?.map(section => `<b>${section.title}</b><br />${section.content}`)
            ?.join('<br />') || ''
        }
        locations={
          (values.location_source === LocationSource.requisition
            ? requisitionLocations
            : values.locations) || []
        }
        open={showPreview}
        text={values?.name || ''}
        useLayout={false}
        onClose={() => setShowPreview(false)}
      />
    </>
  )
}
