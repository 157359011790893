import React, { useEffect } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeFileUploader from '@components/Inputs/LapeFields/LapeFileUploader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CandidateAddDocumentInterface,
  CandidateDocumentCategory,
} from '@src/interfaces/interviewTool'

const getSupportedFiles = (category: CandidateDocumentCategory) => {
  switch (category) {
    case 'candidate_resume':
      return {
        extensions: ['.docx', '.doc', '.pdf'],
        formats: [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/msword',
        ],
      }

    default:
      return {
        extensions: ['.docx', '.doc', '.pdf', '.xsl', '.xslx', '.csv', '.png', '.jpg'],
        formats: [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'text/csv',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/msword',
          'image/jpeg',
          'image/png',
        ],
      }
  }
}

const CandidateAddDocument = () => {
  const { values } = useLapeContext<CandidateAddDocumentInterface>()
  const { id } = useParams<{ id: string }>()
  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.file && !values.file.name) {
      values.file = undefined
    }
  }, [])

  const supportedFiles = getSupportedFiles(values.category?.id || 'candidate_other')

  return (
    <>
      <PageBody>
        <InputGroup>
          <LapeRadioSelectInput
            name="category"
            label="Category"
            selector={selectorKeys.candidate_file_categories}
          />
          <LapeNewInput name="name" label="File name" />
          <LapeFileUploader
            name="file"
            bottomInfo={`Supported types: ${supportedFiles.extensions.join(
              ', ',
            )}.  Max size: 10MB`}
            accept={supportedFiles.formats}
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Document added successfully"
          useValidator
          afterSubmitUrl={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
            id,
          })}
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default CandidateAddDocument
