import React from 'react'
import { StatusWidget } from '@revolut/ui-kit'
import { AxiosError } from 'axios'
import { ERRORS } from '@src/constants/notifications'

type Props = {
  error: AxiosError
  onReset?: () => void
}

export const TableError = ({ error, onReset }: Props) => {
  const errorCode = error.response?.status
  let errorMessage = ERRORS.UNKNOWN_REFRESH

  switch (errorCode) {
    case 403:
      errorMessage = 'You do not have access to this table.'
      break

    case 404:
      errorMessage = 'This table does not exist.'
      break

    // if the request is too long it's being cut by nginx with Network Error with no status code at all
    case undefined:
    case 413:
      errorMessage =
        'The filters you have used are incorrect or too long. Please try changing them.'
  }

  return (
    <StatusWidget>
      <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images-v2/3D018.png" />
      <StatusWidget.Title>Sorry, the table failed to load</StatusWidget.Title>
      <StatusWidget.Description>{errorMessage}</StatusWidget.Description>
      {onReset && (
        <StatusWidget.Action onClick={onReset}>Reset filters</StatusWidget.Action>
      )}
    </StatusWidget>
  )
}
