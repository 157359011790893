import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import { ImportEmployeeDataInterface } from '@src/interfaces/importEmployees'
import { EmployeeEmails } from '@src/interfaces/selectors'

export const fields: ImportFieldType<ImportEmployeeDataInterface>[] = [
  {
    type: 'input',
    field: 'first_name',
    label: 'First name',
  },
  {
    type: 'input',
    field: 'middle_name',
    label: 'Middle name',
  },
  {
    type: 'input',
    field: 'last_name',
    label: 'Last name',
  },
  {
    type: 'input',
    field: 'preferred_name',
    label: 'Preferred name',
  },
  {
    type: 'input',
    field: 'email',
    label: 'Email',
  },
  {
    type: 'radio-select',
    field: 'access_level',
    selector: selectorKeys.groups,
    label: 'Access level',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'team',
    selector: selectorKeys.team,
    label: 'Team',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'department',
    selector: selectorKeys.department,
    label: 'Department',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'specialisation',
    selector: selectorKeys.specialisations,
    label: 'Specialisation',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'input',
    field: 'job_title',
    label: 'Job title',
  },
  {
    type: 'radio-select',
    field: 'employee_type',
    selector: selectorKeys.employee_types,
    label: 'Employee type',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'seniority',
    selector: selectorKeys.seniority,
    label: 'Seniority',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'line_manager',
    selector: selectorKeys.employee_emails,
    label: 'Line manager',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'functional_manager',
    selector: selectorKeys.employee_emails,
    label: 'Functional manager',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'location',
    selector: selectorKeys.location,
    label: 'Location',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'country',
    selector: selectorKeys.countries,
    label: 'Country',
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'entity',
    selector: selectorKeys.entity,
    label: 'Entity',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'date',
    field: 'start_date',
    label: 'Start date',
  },
  {
    type: 'date',
    field: 'probation_end_date',
    label: 'Probation end date',
  },
  {
    type: 'input',
    field: 'salary_amount',
    label: 'Salary amount',
  },
  {
    type: 'radio-select',
    field: 'contract_type',
    selector: selectorKeys.contract_types,
    label: 'Contract type',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'contract_term',
    selector: selectorKeys.contract_terms,
    label: 'Contract term',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'salary_currency',
    selector: selectorKeys.currencies,
    label: 'Salary currency',
    valueKey: 'iso_code',
  },
  {
    type: 'radio-select',
    field: 'salary_time_unit',
    selector: selectorKeys.contract_salary_time_units,
    label: 'Salary time unit',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'work_modality',
    selector: selectorKeys.contract_work_modalities,
    label: 'Work modality',
    valueKey: 'id',
  },
  {
    type: 'radio-select',
    field: 'salary_payment_frequency',
    selector: selectorKeys.contract_salary_payment_frequencies,
    label: 'Salary payment frequency',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'weekly_working_hours',
    label: 'Weekly working hours',
  },
  {
    type: 'input',
    field: 'full_time_equivalent',
    label: 'Full time equivalent',
  },
  {
    type: 'input',
    field: 'notice_period_after_probation',
    label: 'Notice period after probation',
  },
  {
    type: 'radio-select',
    field: 'notice_period_after_probation_unit',
    selector: selectorKeys.contract_notice_period_time_units,
    label: 'Notice period after probation unit',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'notice_period_during_probation',
    label: 'Notice period during probation',
  },
  {
    type: 'radio-select',
    field: 'notice_period_during_probation_unit',
    selector: selectorKeys.contract_notice_period_time_units,
    label: 'Notice period during probation unit',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'personal_email',
    label: 'Personal email',
  },
  {
    type: 'radio-select',
    field: 'phone_country_code',
    selector: selectorKeys.country_calling_codes,
    label: 'Phone country code',
    valueKey: 'code',
  },
  {
    type: 'input',
    field: 'phone_number_short',
    label: 'Phone number short',
  },
  {
    type: 'input',
    field: 'address_line_1',
    label: 'Address line 1',
  },
  {
    type: 'input',
    field: 'address_line_2',
    label: 'Address line 2',
  },
  {
    type: 'input',
    field: 'address_line_3',
    label: 'Address line 3',
  },
  {
    type: 'radio-select',
    field: 'legal_sex',
    selector: selectorKeys.legal_sexes,
    label: 'Legal sex',
    valueKey: 'id',
  },
  {
    type: 'input',
    field: 'national_insurance_number',
    label: 'National insurance number',
  },
  {
    type: 'input',
    field: 'employee_pension_contribution',
    label: 'Employee pension contribution',
  },
  {
    type: 'input',
    field: 'employer_pension_contribution',
    label: 'Employer pension contribution',
  },
  {
    type: 'input',
    field: 'bank_name',
    label: 'Bank name',
  },
  {
    type: 'input',
    field: 'account_name',
    label: 'Account name',
  },
  {
    type: 'radio-select',
    field: 'bank_currency',
    selector: selectorKeys.currencies,
    label: 'Bank currency',
    valueKey: 'iso_code',
  },
  {
    type: 'input',
    field: 'bank_account_number',
    label: 'Bank account number',
  },
  {
    type: 'input',
    field: 'sort_code',
    label: 'Sort code',
  },
  {
    type: 'radio-select',
    field: 'status',
    selector: selectorKeys.employee_status,
    label: 'Status',
    valueKey: 'id',
  },
]

export const useOptions = () => {
  const { options: employeeOptions } = useFetchOptions<EmployeeEmails>(
    selectorKeys.employee_emails,
    true,
  )
  const { options: contractTypeOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_types,
    true,
  )
  const { options: employeeTypeOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.employee_types,
    true,
  )
  const { options: contractTermOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_terms,
    true,
  )
  const { options: salaryPaymentFrequencyOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_salary_payment_frequencies,
    true,
  )
  const { options: employeeStatusOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.employee_status,
    true,
  )
  const { options: salaryTimeUnitOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_salary_time_units,
    true,
  )
  const { options: contractWorkModalityOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.contract_work_modalities,
    true,
  )
  const { options: countryOptions } = useFetchOptions<IdAndName<string>>(
    selectorKeys.countries,
    true,
  )
  const { options: contractNoticePeriodTimeUnitOptions } = useFetchOptions<
    IdAndName<string>
  >(selectorKeys.contract_notice_period_time_units, true)

  return {
    employeeOptions,
    contractTypeOptions,
    employeeTypeOptions,
    contractTermOptions,
    salaryPaymentFrequencyOptions,
    employeeStatusOptions,
    salaryTimeUnitOptions,
    contractWorkModalityOptions,
    countryOptions,
    contractNoticePeriodTimeUnitOptions,
  }
}
