import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import { HStack, Link, Subheader } from '@revolut/ui-kit'
import { BetaBadge } from '@components/BetaBadge/BetaBadge'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { CandidateMatchSidebarOpportunities } from '@src/features/CommonCandidatesTable/CandidateMatchSidebarOpportunities'

type Props = {
  candidateName: string
  candidateId: number
  onClose: VoidFunction
}

export const CandidateMatchSidebar = ({ onClose, candidateId, candidateName }: Props) => {
  const { data: opportunities } = useGetFullInterviewRounds(candidateId)
  const specialisation = opportunities?.find(item => item.active)?.specialisation

  return (
    <SideBar
      isOpen
      onClose={onClose}
      title={candidateName}
      variant="medium"
      subtitle={
        specialisation && (
          <Link
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
              id: specialisation.id,
            })}
            target="_blank"
            color="inherit"
          >
            {specialisation.name}
          </Link>
        )
      }
    >
      <Subheader>
        <HStack gap="s-8" align="center">
          Candidate’s active opportunities <BetaBadge />
        </HStack>
      </Subheader>

      <CandidateMatchSidebarOpportunities candidateId={candidateId} />
    </SideBar>
  )
}
