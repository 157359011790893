import React from 'react'
import { employeeContactsRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import ContactInfoForm, { Props } from './General'

const ContactInfo = (props: Props) => {
  return (
    <Form api={employeeContactsRequests}>
      <ContactInfoForm {...props} />
    </Form>
  )
}

export default ContactInfo
