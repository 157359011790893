import React from 'react'

import { Chat, Question } from '@revolut/icons'
import { Box, Cell, Group, H5, Item, VStack } from '@revolut/ui-kit'
import { FaqInterface } from '@src/interfaces/faq'
import { useGetHelpCenterSettings } from '@src/api/settings'
import { EditorView } from '@components/Editor/EditorView'

type Props = {
  question?: FaqInterface
  goToIndex: () => void
  goToChatBot: () => void
}

export const AnswerSidebarContent = ({ question, goToIndex, goToChatBot }: Props) => {
  const { data } = useGetHelpCenterSettings()

  if (!question) {
    return null
  }

  return (
    <VStack space="s-24">
      <Cell>
        <EditorView html={question.content} />
      </Cell>
      <Box>
        <H5 mb="s-16">I need more help</H5>
        <Group>
          {data?.chatbot_enabled ? (
            <Item use="button" onClick={goToChatBot} useIcon={Chat}>
              <Item.Content>
                <Item.Title>Chat with us</Item.Title>
              </Item.Content>
            </Item>
          ) : null}
          <Item use="button" onClick={goToIndex} useIcon={Question}>
            <Item.Content>
              <Item.Title>Help with something else</Item.Title>
            </Item.Content>
          </Item>
        </Group>
      </Box>
    </VStack>
  )
}
