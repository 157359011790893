export enum ExperienceVariants {
  positive = 'Positive',
  negative = 'Negative',
}

export enum AddToAlumniNetworkVariants {
  yes = 'Yes',
  no = 'No',
}

export enum AnonymousDisclosureVariants {
  accept = 'Accept',
  refuse = 'Refuse',
}

export enum ToldManagerVariants {
  yes = 'Yes',
  no = 'No',
}
