import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { documentsSettings } from '@src/api/settings'
import { Documents } from '@src/pages/Settings/Documents/Documents'

const routes = [
  {
    title: 'Documents app settings',
    description: 'Settings for all documents functionalities',
    path: ROUTES.SETTINGS.DOCUMENTS,
    url: ROUTES.SETTINGS.DOCUMENTS,
    canView: [
      PermissionTypes.ViewDocumentsPreferences,
      PermissionTypes.ChangeDocumentsPreferences,
    ],
    component: Documents,
  },
]

export const DocumentsSettings = () => {
  return <SettingsForm routes={routes} api={documentsSettings} />
}
