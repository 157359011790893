import { RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from './index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ReferralBonusInterface } from '@src/interfaces/referralBonus'

export const referralBonusRequests: RequestInterface<ReferralBonusInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.REFERRAL_BONUSES, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ filters, page }) =>
    api.get(`${API.REFERRAL_BONUSES}/stats`, {
      params: filterSortPageIntoQuery(undefined, filters, page),
    }),
  getItem: async id => api.get(`${API.REFERRAL_BONUSES}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.REFERRAL_BONUSES}/${id}`, data),
  deleteItem: async id => api.delete(`${API.REFERRAL_BONUSES}/${id}`),
  postItem: async data => api.post(API.REFERRAL_BONUSES, data),
  putItem: async (data, id) => api.put(`${API.REFERRAL_BONUSES}/${id}`, data),
}

export const referralBonusFormRequests: RequestInterfaceNew<ReferralBonusInterface> = {
  get: async ({ id }) => api.get(`${API.REFERRAL_BONUSES}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.REFERRAL_BONUSES}/${id}`, data),
  submit: async data => apiWithoutHandling.post(API.REFERRAL_BONUSES, data),
}
