import { Placeholder, BottomSheet, Button } from '@revolut/ui-kit'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { askLookerWorkspacePermissions } from '@src/api/officeSuiteProvider'

import { updateTypeOptionMap } from '../../../../Forms/GoalForm/Form/GoalMetricForm/updateTypeOptionMap'
import { TableauHelperContent } from './TableauHelperContent'
import { LookerHelperContent } from './LookerHelperContent'
import { UpdateTypes } from '@src/interfaces/kpis'
import { ClickupHelperContent } from './ClickupHelperContent'
import { SalesforceHelperContent } from './SalesforceHelperContent'

export type HelperPopupVariant =
  | 'tableau'
  | 'looker'
  | 'clickup'
  | 'salesforce'
  | undefined

interface Props {
  helpPopupOpened: HelperPopupVariant
  onClose: VoidFunction
  onAfterSubmit: VoidFunction
  setLightBoxSrc: (src: { video: string; poster: string } | undefined) => void
}

export const IntegrationHelperPopup = ({
  helpPopupOpened,
  onClose,
  onAfterSubmit,
  setLightBoxSrc,
}: Props) => {
  if (!helpPopupOpened) {
    return null
  }

  const { name, image } =
    updateTypeOptionMap[
      helpPopupOpened === 'clickup' ? UpdateTypes.clickup : helpPopupOpened
    ]

  return (
    <BottomSheet open={!!helpPopupOpened} onClose={onClose}>
      <Placeholder>
        <Placeholder.Image image={image} />
        <Placeholder.Title>
          <FormattedMessage
            values={{ name }}
            defaultMessage="Setup Revolut app in {name}"
            id="performance.goalForm.connection.helpPopup.title"
          />
        </Placeholder.Title>
        <Placeholder.Description>
          <FormattedMessage
            values={{ name }}
            defaultMessage="Before you can connect your {name} account, you need to set up the Revolut app in {name}."
            id="performance.goalForm.connection.helpPopup.description"
          />
        </Placeholder.Description>
      </Placeholder>
      {helpPopupOpened === 'clickup' && (
        <ClickupHelperContent setLightBoxSrc={setLightBoxSrc} />
      )}
      {helpPopupOpened === 'tableau' && <TableauHelperContent />}
      {helpPopupOpened === 'looker' && (
        <LookerHelperContent setLightBoxSrc={setLightBoxSrc} />
      )}
      {helpPopupOpened === 'salesforce' && (
        <SalesforceHelperContent setLightBoxSrc={setLightBoxSrc} />
      )}

      <BottomSheet.Actions gradient="full">
        <Button
          variant="primary"
          onClick={async () => {
            if (['tableau', 'clickup', 'salesforce'].includes(helpPopupOpened)) {
              onClose()
            }
            if (helpPopupOpened === 'looker') {
              await askLookerWorkspacePermissions()
              onAfterSubmit()
            }
          }}
        >
          {['tableau', 'clickup', 'salesforce'].includes(helpPopupOpened) && 'Got it'}
          {helpPopupOpened === 'looker' && 'Confirm & authenticate'}
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
