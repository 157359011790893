import React from 'react'
import { Box, BoxProps, Cell, Link, Skeleton, Text, TextProps } from '@revolut/ui-kit'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
// import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface Props extends BoxProps {
  checked?: boolean
  onCheck: (checked: boolean) => void
  loading?: boolean
  consentNeeded?: boolean
  textAlign?: TextProps['textAlign']
}

const ReEngageConsent = ({
  checked,
  onCheck,
  loading,
  consentNeeded,
  textAlign,
  ...props
}: Props) => {
  if (loading) {
    return (
      <Box {...props}>
        <Skeleton borderRadius={16} height={90} />
      </Box>
    )
  }

  if (!consentNeeded) {
    return null
  }

  return (
    <Box {...props}>
      <Cell use="label">
        <NewCheckbox
          checked={checked}
          label="I agree that my data will be used to assess my suitability for other roles"
          onChange={e => {
            onCheck(e.currentTarget.checked)
          }}
        />
      </Cell>
      <HideIfCommercial>
        <Text mt="s-24" display="block" textAlign={textAlign}>
          You can withdraw your consent at any time by contacting{' '}
          <Link href="mailto:dpo@revolut.com">dpo@revolut.com</Link>
        </Text>
      </HideIfCommercial>
    </Box>
  )
}

export default ReEngageConsent
