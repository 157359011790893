import React from 'react'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'

export const Todo = () => {
  const queryClient = useQueryClient()

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="All To do application related settings"
          />
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.TODO_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
