import React, { useMemo } from 'react'
import { useTable } from '@components/Table/hooks'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { RoleInterface } from '@src/interfaces/roles'
import { rolesRequests } from '@src/api/roles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  roleHeadcountColumn,
  rolePerfReviewColumn,
  roleRequisitionsColumn,
  roleStatusColumn,
  roleIconNameColumn,
} from '@src/constants/columns/role'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { FunctionInterface } from '@src/interfaces/functions'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { roleManagerColumn } from '@src/constants/columns/employee'

const row: RowInterface<RoleInterface> = {
  cells: [
    {
      ...roleIconNameColumn,
      width: 300,
    },
    {
      ...roleManagerColumn,
      width: 390,
    },
    {
      ...roleHeadcountColumn,
      width: 150,
    },
    {
      ...roleRequisitionsColumn,
      width: 150,
    },
    {
      ...rolePerfReviewColumn,
      width: 230,
    },
    {
      ...roleStatusColumn,
      width: 220,
    },
  ],
}

const Structure = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: orgSettings } = useGetOrganisationSettings()
  const initialFilter = [
    {
      filters: [{ name: values.name, id: values.id }],
      columnName: 'function__id',
      nonResettable: true,
    },
    {
      filters: [
        { id: 'pending', name: 'pending' },
        { id: 'approved', name: 'approved' },
        { id: 'draft', name: 'draft' },
      ],
      columnName: 'status',
    },
  ]
  const initialSortBy = [
    {
      sortBy: 'headcount',
      direction: SORT_DIRECTION.ASC,
    },
  ]
  const hiddenCells = useMemo(
    () => ({
      [rolePerfReviewColumn.idPoint]: !values?.field_options?.no_access?.includes(
        'average_performance_review_label',
      ),
      [roleStatusColumn.idPoint]: !(orgSettings?.enable_roles_approvals === true),
    }),
    [orgSettings?.enable_roles_approvals, values?.field_options?.no_access],
  )

  const table = useTable<RoleInterface>(rolesRequests, initialFilter, initialSortBy)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {}), {
      initialValues: { function: { name: values.name, id: values.id } },
    })
  }

  const handleRowEdit = (role: RoleInterface) => {
    navigateTo(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id: role.id }))
  }

  const isNewTable = useIsNewTable()

  return (
    <Table.Widget>
      {!isNewTable && (
        <Table.Widget.Info>
          <Stat mr="s-16" label="Roles" val={table.loading ? undefined : table.count} />
        </Table.Widget.Info>
      )}
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <PrimaryAction onClick={handleNewRow} useIcon="Plus">
            Add Role
          </PrimaryAction>
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<RoleInterface>
          name={TableNames.FunctionRoles}
          useWindowScroll
          dataType="Role"
          row={row}
          {...table}
          onRowClick={handleRowEdit}
          emptyState={<EmptyTableRaw title="All roles will appear here" />}
          hiddenCells={hiddenCells}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Structure
