import React from 'react'
import { employeeEmergencyContactRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import EmergencyContactsForm, { Props } from './General'

const EmergencyContacts = (props: Props) => {
  return (
    <Form api={employeeEmergencyContactRequests}>
      <EmergencyContactsForm {...props} />
    </Form>
  )
}

export default EmergencyContacts
