import React from 'react'
import { TableWidget } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import {
  adpReportsFtpUploadTableRequests,
  adpReportsExportTableRequests,
} from '@src/api/payroll'
import { AdpReportInterface } from '@src/interfaces/payroll'
import {
  payCycleAdpChangeReportsEndDateTableColumn,
  payCycleAdpChangeReportsPayGroupTableColumn,
  payCycleAdpChangeReportsTypeTableColumn,
  payCycleAdpChangeReportsRequestedByTableColumn,
  payCycleAdpChangeReportsStartDateTableColumn,
  payCycleAdpChangeReportsStatusTableColumn,
} from '@src/constants/columns/payCycleReports'
import { TableNames } from '@src/constants/table'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { pathToUrl } from '@src/utils/router'

interface Props {
  action: string
  id: string
}

const rows = {
  cells: [
    {
      ...payCycleAdpChangeReportsPayGroupTableColumn,
      width: 180,
    },
    {
      ...payCycleAdpChangeReportsTypeTableColumn,
      width: 120,
    },
    {
      ...payCycleAdpChangeReportsStartDateTableColumn,
      width: 100,
    },
    {
      ...payCycleAdpChangeReportsEndDateTableColumn,
      width: 100,
    },
    {
      ...payCycleAdpChangeReportsRequestedByTableColumn,
      width: 120,
    },
    {
      ...payCycleAdpChangeReportsStatusTableColumn,
      width: 120,
    },
  ],
}

export const DownloadReportsTable = ({ action, id }: Props) => {
  const table = useTable<AdpReportInterface>(
    action === 'download'
      ? adpReportsExportTableRequests(id)
      : adpReportsFtpUploadTableRequests(id),
  )

  return (
    <TableWidget>
      <TableWidget.Table>
        <AdjustableTable<AdpReportInterface>
          dataType="Report"
          name={TableNames.AdpChangeReports}
          noDataMessage="Reports will appear here."
          onRowClick={(row: { id: number }) => {
            navigateTo(
              pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORT, {
                action,
                id,
                requestId: row.id,
              }),
            )
          }}
          row={rows}
          useWindowScroll
          {...table}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}
