import React from 'react'
import styled, { css } from 'styled-components'
import {
  PerformanceRating,
  PerformanceReviewTypes,
  PreviousReviewInterface,
  ReviewCategory,
  SingleChoice,
  SkillCardInterface,
} from '@src/interfaces/performance'
import Icon from '@components/Icon/Icon'
import Tooltip from '@components/Tooltip/Tooltip'
import { PreviousBlock } from '@components/ScorecardGeneral/Common'
import { Box, Color, Flex, Text, Token } from '@revolut/ui-kit'
import PreviousDeliverablesValues from '@components/ScorecardGeneral/PreviousDeliverablesValues'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import {
  getQuarterAndYearFromCycle,
  PREVIOUS_CYCLE_WIDTH,
} from '@src/constants/performance'
import PrefillScorecardButton from '@components/ScorecardGeneral/PrefillScorecardButton'
import CardTitleTags from '@components/ScorecardGeneral/CardTitleTags'
import { OptionInterface } from '@src/interfaces/selectors'
import { QuestionOutline } from '@revolut/icons'
import DOMPurify from 'dompurify'
import { formattedHTMLContentCSS } from '@src/utils/styles'
import { isHTMLFieldEmpty } from '@src/utils/string'

const StyledSubtitle = styled(Text)`
  p {
    /* in this case it should overwrite the default css for HTML content */
    margin: 0 !important;
  }
`

const ErrorTooltip = styled(Tooltip)`
  color: ${Token.color.red};
`

const PreviousCycles = styled.div`
  ${PreviousBlock};
  line-height: 1.5;
  top: 0;
`

const PreviousCycle = styled.div`
  width: ${PREVIOUS_CYCLE_WIDTH}px;
  display: flex;
  justify-content: center;
`

const PositionCss = css`
  position: relative;
`

const StyledTitleTooltip = styled(Tooltip)`
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
`

type Props = {
  card: SkillCardInterface
  type: PerformanceReviewTypes
  cardIndex: number
  cardTitleNumber?: number
  previousReviews?: PreviousReviewInterface[]
  viewMode?: boolean
  onSetResultLoading: (ids: (number | string)[]) => void
  category: ReviewCategory
  tags?: OptionInterface[]
  tooltip?: string
  hideCounts?: boolean
  variant?: 'default' | 'prefill-toggle'
}

const TitleTooltip = ({ tooltip }: { tooltip?: string }) =>
  tooltip ? (
    <StyledTitleTooltip placement="top" text={tooltip}>
      <QuestionOutline size={16} color={Color.GREY_TONE_50} />
    </StyledTitleTooltip>
  ) : null

const CardTitle = ({
  card,
  type,
  cardIndex,
  cardTitleNumber,
  previousReviews,
  viewMode,
  onSetResultLoading,
  category,
  tags,
  tooltip,
  hideCounts = false,
  variant = 'default',
}: Props) => {
  const titleNumber = cardTitleNumber !== undefined ? cardTitleNumber : cardIndex + 1
  switch (type) {
    case PerformanceReviewTypes.deliverables:
      return (
        <Flex alignItems="center" data-testid={`card-title-${cardIndex}`}>
          <Text use="div" variant="h4">
            {titleNumber}. {card.name} <TitleTooltip tooltip={tooltip} />
          </Text>
          <PreviousDeliverablesValues
            previousReviews={previousReviews}
            previousValues={(card.sections as SingleChoice[])[0]?.previous_values}
          />
          {viewMode && card.rating && (
            <Box ml="auto">
              <PerformanceRatingLabelTag rating={card.rating} />
            </Box>
          )}
        </Flex>
      )
    default:
      return (
        <>
          <Flex
            alignItems={tags?.length ? 'baseline' : 'center'}
            css={PositionCss}
            data-testid={`card-title-${cardIndex}`}
          >
            <Flex flexDirection="column">
              {!!tags?.length && (
                <CardTitleTags mb="s-16" title="Skill assessed" tags={tags} />
              )}
              <Text use="div" variant="h4">
                {hideCounts ? '' : `${titleNumber}. `}
                {card.name} <TitleTooltip tooltip={tooltip} />
              </Text>
            </Flex>

            {viewMode && !!card.rating && (
              <Box ml="auto">
                <PerformanceRatingLabelTag rating={card.rating} />
              </Box>
            )}
            {!viewMode && (
              <Box ml="auto">
                <PrefillScorecardButton
                  category={category}
                  type={type}
                  onSetResultLoading={onSetResultLoading}
                  cardIndex={cardIndex}
                  useToggle={variant === 'prefill-toggle'}
                />
              </Box>
            )}
            {!!previousReviews?.length && (
              <PreviousCycles>
                {previousReviews.map((reviewItem, i) => {
                  let title = ''
                  if (reviewItem.checkpoint) {
                    title = `CP${reviewItem.checkpoint.number}`
                  }

                  if (reviewItem.cycle) {
                    title = getQuarterAndYearFromCycle(reviewItem.cycle)
                  }

                  return (
                    <Tooltip
                      key={i}
                      placement={'top'}
                      hide={!reviewItem.checkpoint?.number}
                      text={`Checkpoint ${reviewItem.checkpoint?.number}`}
                    >
                      <PreviousCycle>
                        <Text
                          use="div"
                          fontSize="primary"
                          fontWeight={500}
                          whiteSpace="nowrap"
                        >
                          {title}
                        </Text>
                        {card.previous_ratings?.find(
                          item => item.review_id === reviewItem.review.id,
                        )?.value === PerformanceRating.skipped && (
                          <ErrorTooltip
                            placement="right"
                            text="You had skipped this section and hence no ratings are shown"
                          >
                            &nbsp;
                            <Icon type={'WarningOutlined'} size={'tiny'} />
                          </ErrorTooltip>
                        )}
                      </PreviousCycle>
                    </Tooltip>
                  )
                })}
              </PreviousCycles>
            )}
          </Flex>
          {!isHTMLFieldEmpty(card.sub_title) && (
            <StyledSubtitle
              mt="s-6"
              display="block"
              fontSize="primary"
              style={{ wordBreak: 'break-word' }}
              css={formattedHTMLContentCSS}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(card.sub_title) }}
            />
          )}
        </>
      )
  }
}

export default CardTitle
