import React, { useMemo } from 'react'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { Box, Text, TextStyle } from '@revolut/ui-kit'

type Props = {
  round: InterviewRoundInterface
  textVariant?: TextStyle
  labelVariant?: TextStyle
  textColor?: string
}

export const HiringStageJobPostings = ({
  round,
  labelVariant = 'h6',
  textVariant = 'caption',
  textColor,
}: Props) => {
  const jobPostings = useMemo(() => {
    if (!round.application_forms) {
      return []
    }
    return round.application_forms
      .filter(form => !!form.job_posting)
      .map(el => el.job_posting?.name)
      ?.filter(Boolean)
  }, [round.application_forms])

  if (!jobPostings.length) {
    return null
  }

  return (
    <Box>
      <Text variant={labelVariant} color={textColor}>
        Job postings:{' '}
        <TextWithMoreCount
          items={jobPostings}
          visibleCount={3}
          expandable
          color={textColor}
          variant={textVariant}
        />
      </Text>
    </Box>
  )
}
