import React from 'react'
import { Text } from '@revolut/ui-kit'

type Props = {
  label: React.ReactNode
  subtitle?: React.ReactNode
}

const RadioSelectOption = ({ label, subtitle }: Props) => {
  return (
    <>
      <Text use="p">{label}</Text>
      {subtitle && (
        <Text use="p" variant="caption" mt="s-4" color="grey-tone-50">
          {subtitle}
        </Text>
      )}
    </>
  )
}

export default RadioSelectOption
