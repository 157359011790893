import React, { useEffect } from 'react'
import {
  DetailsCell,
  Group,
  Header,
  Layout,
  Subheader,
  Text,
  Color,
  Box,
  Footnote,
} from '@revolut/ui-kit'

import { goBack } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useSubscriptionPlanContext } from './SubscriptionPlanProvider'
import { useSubsciptionInfo } from '@src/api/plans'
import { formatMoney } from '@src/utils/format'
import { CheckoutWidget } from './CheckoutWidget'
import { formatSubscriptionFreePeriod } from '@src/pages/Settings/PlanManagement/common'
import { useRetryPaymentFlow } from '@src/pages/AccountRestricted/useRetryPaymentFlow'
import { TermsAndConditionsDisclaimer } from '@src/pages/SignUp/common'

export const Checkout = () => {
  const { orderAndConfig, selectedPlan } = useSubscriptionPlanContext()
  const { info } = useSubsciptionInfo()

  const onSuccess = () => {
    /** Going back 2 times to leave the plan selection flow, which has 2 routes, so we can get back to where we opened it */
    goBack()
    goBack(ROUTES.MAIN)
  }

  const { onRetryPayment } = useRetryPaymentFlow({
    onSuccessPopupClose: onSuccess,
  })

  useEffect(() => {
    if (!orderAndConfig) {
      goBack(ROUTES.PLANS.SELECT_PLAN)
    }
  }, [orderAndConfig])

  if (!orderAndConfig) {
    return null
  }

  return (
    <CheckoutWidget
      orderAndConfig={orderAndConfig}
      initialPaymentMethod="revolut-pay"
      plan={selectedPlan}
      onSuccessPopupClose={onSuccess}
      onRetryPayment={onRetryPayment}
    >
      <Layout.Main>
        <Header variant="item">
          <Header.BackButton
            onClick={() => goBack(ROUTES.PLANS.SELECT_PLAN)}
            aria-label="Back"
          />
          <Header.Title>Checkout</Header.Title>
          <Header.Description>
            Complete your purchase by providing your payment details
          </Header.Description>
        </Header>

        <CheckoutWidget.Details />

        <Box mt="s-16">
          <Subheader variant="default">
            <Subheader.Title>Checkout summary</Subheader.Title>
          </Subheader>
        </Box>

        <Group>
          <DetailsCell>
            <DetailsCell.Title>Active employees</DetailsCell.Title>
            <DetailsCell.Content>{info?.active_headcount}</DetailsCell.Content>
          </DetailsCell>

          <DetailsCell>
            <DetailsCell.Title>
              <Text color={Color.FOREGROUND}>Total amount</Text>
            </DetailsCell.Title>
            <DetailsCell.Content>
              {(() => {
                const orderAmount = formatMoney(
                  Number(selectedPlan?.total_amount),
                  selectedPlan?.subscription_plan.currency,
                )

                if (
                  orderAndConfig?.order?.revolut_merchant_api_order?.order_amount
                    ?.value === 0
                ) {
                  return (
                    <>
                      <Text color={Color.GREY_TONE_50}>
                        <s>{orderAmount}</s>
                      </Text>{' '}
                      <Text fontWeight="500">Free</Text>
                    </>
                  )
                }

                return orderAmount
              })()}
            </DetailsCell.Content>
          </DetailsCell>

          {orderAndConfig.type === 'subscribe' &&
          selectedPlan?.subscription_plan.free_days &&
          selectedPlan.subscription_plan.free_days > 0 ? (
            <DetailsCell>
              <DetailsCell.Title>Trial period</DetailsCell.Title>
              <DetailsCell.Content>
                {formatSubscriptionFreePeriod(selectedPlan.subscription_plan.free_days)}
              </DetailsCell.Content>
            </DetailsCell>
          ) : null}
        </Group>
      </Layout.Main>

      <Layout.Actions gradient="high">
        <Footnote>
          <TermsAndConditionsDisclaimer />
        </Footnote>
        <CheckoutWidget.Actions />
      </Layout.Actions>
    </CheckoutWidget>
  )
}
