import React from 'react'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import {
  NotificationLogsInterface,
  NotificationTemplateInterface,
} from '@src/interfaces/notificationTemplate'
import { useTable } from '@src/components/TableV2/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getNotificationLogs } from '@src/api/notificationTemplate'
import {
  notificationLogsCompletedOn,
  notificationLogsSentBy,
  notificationLogsSentOn,
  notificationLogsSentTo,
  notificationLogsStatus,
} from '@src/constants/columns/notificationTemplate'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { Box } from '@revolut/ui-kit'

const ROW: RowInterface<NotificationLogsInterface> = {
  cells: [
    {
      ...notificationLogsSentOn,
      width: 200,
    },
    {
      ...notificationLogsSentBy,
      width: 200,
    },
    {
      ...notificationLogsSentTo,
      width: 200,
    },
    {
      ...notificationLogsCompletedOn,
      width: 200,
    },
    {
      ...notificationLogsStatus,
      width: 100,
    },
  ],
}

const Logs = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()

  const table = useTable({ getItems: getNotificationLogs(values.id) })

  return (
    <Box mt="s-16">
      <Table.Widget>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.NotificationsTemplateLogs}
            row={ROW}
            {...table}
            useWindowScroll
            emptyState={<EmptyTableRaw title="List of logs will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </Box>
  )
}

export default Logs
