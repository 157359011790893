import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import toString from 'lodash/toString'
import NewCodeEditor, {
  NewCodeEditorProps,
} from '@components/Inputs/NewCodeEditor/NewCodeEditor'
import NewResponsiveCodeEditor from '@components/Inputs/NewCodeEditor/NewResponsiveCodeEditor'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { Flex, HStack, Icon, Text, Token, VStack, Widget } from '@revolut/ui-kit'
import styled from 'styled-components'

const BorderedWidget = styled(Widget)<{ hasError?: boolean }>`
  border: ${props =>
    `1px solid ${props.hasError ? Token.color.red : Token.color.greyTone10}`};
`

export interface LapeCodeEditorProps
  extends Omit<NewCodeEditorProps, 'value' | 'onChange'> {
  name: string
  diff?: string
  onRun?: () => void
  onSave?: () => void
  responsive?: boolean
  height?: number | string
  width?: number | string
  wrapResponsive?: boolean
  onAfterChange?: (value?: string) => void
}

const LapeCodeEditor = ({
  name,
  responsive,
  wrapResponsive = false,
  onAfterChange,
  ...props
}: LapeCodeEditorProps) => {
  const {
    value,
    error: formError,
    touched,
    apiError,
    onChange,
    hidden,
    disabled,
    changelog,
  } = useLapeField(name)
  const formValidator = useFormValidator()

  if (hidden) {
    return null
  }

  const showChangelog = !!changelog
  const error = formError || apiError
  const errorMessage = error ? toString(error) : undefined
  const validated = !!formValidator?.validated

  if (responsive && wrapResponsive) {
    return (
      <VStack>
        <BorderedWidget hasError={!!errorMessage}>
          <Flex flexDirection="column" gap="s-12" p="s-12">
            <Text color={Token.color.greyTone50} variant="small" use="small">
              SQL Query
            </Text>
            <NewResponsiveCodeEditor
              onChange={val => {
                onChange(val)
                onAfterChange?.(val)
              }}
              value={value}
              {...props}
              disabled={disabled}
              name={name}
            />
          </Flex>
        </BorderedWidget>

        {errorMessage && (
          <HStack align="center" space="s-12">
            <Icon name="InfoOutline" size={12} color={Token.color.red} />
            <Text variant="small" color={Token.color.red}>
              {errorMessage}
            </Text>
          </HStack>
        )}
      </VStack>
    )
  }

  const EditorComponent = responsive ? NewResponsiveCodeEditor : NewCodeEditor

  return (
    <EditorComponent
      onChange={val => {
        onChange(val)
        onAfterChange?.(val)
      }}
      value={value}
      {...props}
      disabled={disabled}
      diff={showChangelog ? props.diff : undefined}
      error={validated && errorMessage ? errorMessage : (touched && error) || props.error}
      name={name}
    />
  )
}

export default connect(LapeCodeEditor)
