import React from 'react'
import { MoreBar, Text } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useMissGrowthPlan } from '@src/api/growthPlans'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  growthPlan: GrowthPlanInterface
}

export const MissAction = ({ growthPlan }: Props) => {
  const { mutateAsync: missGrowthPlan, isLoading } = useMissGrowthPlan(growthPlan.id)

  const confirmationDialog = useConfirmationDialog()
  const showStatusPopup = useShowStatusPopup()

  const handleClick = () => {
    confirmationDialog.show({
      body: (
        <>
          <Text>Are you sure you want to mark this growth plan as "Missed"? </Text>
          <Text fontWeight={800}>This plan will be archived.</Text>
        </>
      ),
      onConfirm: async () => {
        try {
          await missGrowthPlan()
          showStatusPopup({ title: 'Growth plan was successfully archived' })
        } catch (err) {
          showStatusPopup({
            title: 'Failed to archive growth plan',
            description: getStringMessageFromError(err),
            status: 'error',
          })
        }
      },
    })
  }

  return (
    <MoreBar.Action useIcon="Cross" pending={isLoading} onClick={handleClick}>
      Missed
    </MoreBar.Action>
  )
}
