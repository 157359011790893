import React from 'react'
import { Cell } from '@revolut/ui-kit'

export const MergeDisclaimer = () => {
  return (
    <Cell>
      By clicking Enable, you agree that Merge API, Inc. will access personal data from
      your HRIS as our sub-processor
    </Cell>
  )
}
