import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { candidateBulkMoveStageRequests } from '@src/api/hiringProcess'
import { CandidateBulkMoveStageInterface } from '@src/interfaces/hiringProccess'
import { ColumnCellInterface } from '@src/interfaces/data'
import {
  bulkActionsStageIdColumn,
  bulkActionsStageTypeColumn,
} from '@src/constants/columns/bulkActions'

const columns: ColumnCellInterface<CandidateBulkMoveStageInterface>[] = [
  {
    ...bulkActionsStageIdColumn,
    width: 140,
  },
  {
    ...bulkActionsStageTypeColumn,
    width: 140,
  },
]

const CandidateBulkMoveStage = () => {
  return (
    <BulkActions<CandidateBulkMoveStageInterface>
      tableRequest={candidateBulkMoveStageRequests}
      columns={columns}
      dataType="Candidate move to stage bulk actions"
    />
  )
}

export default CandidateBulkMoveStage
