import { AxiosPromise } from 'axios'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  CompanyCompensationReviewsFormInterface,
  CompanyCompensationReviewsInterface,
  CompensationBonusInterface,
  DepartmentCompensationReviewDetailsInterface,
  DepartmentCompensationReviewInterface,
  DepartmentCompensationReviewsUpdate,
  EmployeeCompensationEditInterface,
  EmployeeCompensationReviewInterface,
  EmployeeCompensationReviewsUpdate,
  SalaryHistoryInterface,
  TeamCompensationReviewInterface,
} from '@src/interfaces/compensation'
import { useFetch, usePost } from '@src/utils/reactQuery'
import { getCommentsAPI } from './comments'
import { roundFloat } from '@src/utils/numbers'
import { API } from '@src/constants/api'
import { targetCurrencyCodeKey } from '@src/features/BudgetDistribution/BudgetDistribution'

export const getDepartmentCompensationReviews =
  (currencyId: number | null) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<DepartmentCompensationReviewInterface>
  > =>
    apiWithoutHandling
      .get<GetRequestInterface<DepartmentCompensationReviewInterface>>(
        API.DEPARTMENT_COMPENSATION_REVIEWS,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: response.data.results.map(row => ({
            ...row,
            salary:
              currencyId && row.exchanged
                ? row.exchanged.total_salary_budget
                : row.total_salary_budget_usd,
            bonus:
              currencyId && row.exchanged
                ? row.exchanged.total_bonus_budget
                : row.total_bonus_budget_usd,
          })),
        },
      }))

export const updateDepartmentCompensationReviews = (
  data: DepartmentCompensationReviewsUpdate[],
) => api.patch(`${API.DEPARTMENT_COMPENSATION_REVIEWS}/bulkUpdate`, data)

export const updateEmployeeCompensationReviews = (
  data: EmployeeCompensationReviewsUpdate[],
) => api.patch(`/employeeCompensationReviews/bulkUpdate`, data)

export const useCompanyCompensationYearOptions = (currencyId: number | null) =>
  useFetch<{ results: CompanyCompensationReviewsInterface[] }>(
    API.COMPANY_COMPENSATION_REVIEWS,
    undefined,
    {
      params: filterSortPageIntoQuery(
        [
          {
            sortBy: 'year',
            direction: SORT_DIRECTION.ASC,
            nonResettable: true,
          },
        ],
        currencyId
          ? [
              {
                columnName: 'target_currency_id',
                filters: [{ id: currencyId, name: `${currencyId}` }],
                nonResettable: true,
              },
            ]
          : undefined,
      ),
    },
    undefined,
    { refetchOnWindowFocus: false },
  )

export const getCompanyCompensationReviews = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<CompanyCompensationReviewsFormInterface>
> =>
  api.get(API.COMPANY_COMPENSATION_REVIEWS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const companyCompensationReviewsRequest: RequestInterfaceNew<CompanyCompensationReviewsFormInterface> =
  {
    get: async ({ id }) => api.get(`${API.COMPANY_COMPENSATION_REVIEWS}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.COMPANY_COMPENSATION_REVIEWS}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.COMPANY_COMPENSATION_REVIEWS, data),
    delete: async (_, { id }) => api.delete(`${API.COMPANY_COMPENSATION_REVIEWS}/${id}`),
  }

const compensationYearOptionsFetchOptions = (currencyCode: string | null) =>
  [
    undefined,
    {
      params: filterSortPageIntoQuery(
        [
          {
            sortBy: 'company_compensation_review__year',
            direction: SORT_DIRECTION.ASC,
            nonResettable: true,
          },
        ],
        currencyCode
          ? [
              {
                columnName: targetCurrencyCodeKey,
                filters: [{ id: currencyCode, name: currencyCode }],
                nonResettable: true,
              },
            ]
          : undefined,
      ),
    },
    undefined,
    { refetchOnWindowFocus: false },
  ] as const

export const useDepartmentCompensationYearOptions = (
  id: string,
  currencyCode: string | null,
) =>
  useFetch<{ results: DepartmentCompensationReviewInterface[] }>(
    `/departments/${id}/compensationReviews`,
    ...compensationYearOptionsFetchOptions(currencyCode),
  )

export const getDepartmentCompensationReviewsCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/comments`,
  })

export const getEmployeeCompensationReviewsCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `/employeeCompensationReviews/${id}/comments`,
  })

export const getChangePercent = (prevValue: number, newValue: number) =>
  roundFloat(((newValue - prevValue) * 100) / Math.abs(prevValue), 2)

export const getDataWithChanges = (
  data: EmployeeCompensationReviewInterface[],
): EmployeeCompensationEditInterface[] => {
  return data.map(row => ({
    ...row,
    salary_amount_new: row.salary_amount_new == null ? 0 : row.salary_amount_new,
    salary_change: row.salary_amount_new - row.salary_amount,
    salary_change_percent: getChangePercent(row.salary_amount, row.salary_amount_new),
    bonus_change: row.bonus_amount_new - (row.bonus_amount || 0),
  }))
}

export const getDepartmentEmpoyeeCompensationReviews =
  (id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeCompensationEditInterface>
  > =>
    apiWithoutHandling
      .get<GetRequestInterface<EmployeeCompensationEditInterface>>(
        `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: getDataWithChanges(response.data.results),
        },
      }))

export const approveDepartmentCompensation = (id: number) =>
  api.post(`${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/approve`)

export const unapproveDepartmentCompensation = (id: number) =>
  api.post(`${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/unapprove`)

export interface CalculateAllocatedBudgetsResponse {
  allocated_salary_budget: number
  allocated_bonus_budget: number
}

export const useCalculateAllocatedBudgets = (currencyCode?: string) =>
  usePost<
    CalculateAllocatedBudgetsResponse,
    CalculateAllocatedBudgetsResponse,
    EmployeeCompensationReviewsUpdate[]
  >(`/employeeCompensationReviews/calculateAllocatedBudgets`, undefined, {
    params: { [targetCurrencyCodeKey]: currencyCode },
  })

export const useDepartmentCompensationDetails = (
  id?: number | string,
  currencyCode?: string,
) =>
  useFetch<DepartmentCompensationReviewDetailsInterface>(
    id ? `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}` : null,
    undefined,
    currencyCode ? { params: { [targetCurrencyCodeKey]: currencyCode } } : undefined,
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )

export const getTeamCompensationReviews =
  (id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeCompensationEditInterface>
  > =>
    apiWithoutHandling
      .get<GetRequestInterface<EmployeeCompensationEditInterface>>(
        `/teamCompensationReviews/${id}/employeeCompensationReviews`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: getDataWithChanges(response.data.results),
        },
      }))

export const useTeamCompensationYearOptions = (id: string, currencyCode: string | null) =>
  useFetch<{ results: TeamCompensationReviewInterface[] }>(
    `${API.TEAMS}/${id}/compensationReviews`,
    ...compensationYearOptionsFetchOptions(currencyCode),
  )

export const getNewSalaryHistory = (reviewId: number, params: Object) =>
  api.get<GetRequestInterface<SalaryHistoryInterface>>(
    `/employeeCompensationReviews/${reviewId}/salaryChangelog`,
    {
      params,
    },
  )

export const getFunctionCompensationReviews =
  (id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeCompensationEditInterface>
  > =>
    apiWithoutHandling
      .get<GetRequestInterface<EmployeeCompensationEditInterface>>(
        `/functionCompensationReviews/${id}/employeeCompensationReviews`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
      )
      .then(response => ({
        ...response,
        data: {
          ...response.data,
          results: getDataWithChanges(response.data.results),
        },
      }))

export const useFunctionCompensationYearOptions = (
  id: string,
  currencyCode: string | null,
) =>
  useFetch<{ results: DepartmentCompensationReviewInterface[] }>(
    `${API.FUNCTIONS}/${id}/compensationReviews`,
    ...compensationYearOptionsFetchOptions(currencyCode),
  )

export const getCompensationCSV = async (id: number | string, targetCurrency: string) =>
  api.get(
    `${API.DEPARTMENT_COMPENSATION_REVIEWS}/${id}/employeeCompensationReviews/csv`,
    {
      responseType: 'blob',
      params: { target_currency_iso_code: targetCurrency },
    },
  )

export const compensationBonusFormRequests: RequestInterfaceNew<CompensationBonusInterface> =
  {
    get: async ({ id }) => api.get(`${API.EMPLOYEE_BONUSES}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.EMPLOYEE_BONUSES}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.EMPLOYEE_BONUSES, data),
    delete: async (_, { id }) => api.delete(`${API.EMPLOYEE_BONUSES}/${id}`),
  }

export const deleteCompensationBonus = (id: number) =>
  api.delete(`${API.EMPLOYEE_BONUSES}/${id}`)
