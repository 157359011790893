import React, { useEffect, useState } from 'react'
import {
  BottomSheet,
  Button,
  Header,
  StatusPopup,
  VStack,
  useStatusPopup,
  Text,
  Token,
  InputGroup,
} from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { IdAndName } from '@src/interfaces'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { mergeSpecialisations } from '@src/api/updateOrganisationStructure'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import {
  bulkEditSpecialisation,
  bulkDeleteSpecialisations,
  updateSpecialisation,
} from '@src/api/specialisations'
import { SpecialisationInterface } from '@src/interfaces/roles'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'

/** Merge roles */

interface MergeRolesPayloadInterface {
  roles: { id: number }[]
  name: string
  role_manager: IdAndName
}

interface MergeRolesProps {
  unitsToMerge: { id: number }[]
  onSuccess: () => void
}

export const MergeRoles = ({ unitsToMerge, onSuccess }: MergeRolesProps) => {
  return (
    <LapeForm<MergeRolesPayloadInterface>
      onSubmit={form =>
        mergeSpecialisations({ ...form.values, specialisations: unitsToMerge }).then(
          () => {
            onSuccess()
            return form.values
          },
        )
      }
    >
      <FormValidatorProvider>
        <MergeRolesForm />
      </FormValidatorProvider>
    </LapeForm>
  )
}

const MergeRolesForm = () => {
  const { isSubmitting, submit } = useLapeContext<MergeRolesPayloadInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Role name" required />
      </InputGroup>
      <BottomSheet.Actions>
        <Button onClick={validate(() => submit())} pending={isSubmitting} elevated>
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

interface MergeRolesPopupProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  unitsToMerge?: number[]
}
export const MergeRolesPopup = ({
  open,
  onClose,
  onSuccess,
  unitsToMerge,
}: MergeRolesPopupProps) => {
  if (!unitsToMerge) {
    return null
  }
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Merge roles</Header.Title>
      </Header>
      <MergeRoles
        unitsToMerge={unitsToMerge.map(id => ({ id }))}
        onSuccess={() => {
          onSuccess()
        }}
      />
    </BottomSheet>
  )
}

/** Bulk update */

interface BulkUpdateRolePopupProps {
  open: boolean
  onSuccess: () => void
  onClose: () => void
  entity?: 'owner'
  selectedRoles?: number[]
}
export const BulkUpdateRolePopup = ({
  open,
  onSuccess,
  onClose,
  entity,
  selectedRoles,
}: BulkUpdateRolePopupProps) => {
  const [pending, setPending] = useState(false)
  const [value, setValue] = useState<IdAndName | null>(null)
  const statusPopup = useStatusPopup()

  if (!entity) {
    return null
  }

  const onSubmit = () => {
    if (!value?.id || !selectedRoles) {
      return
    }

    setPending(true)
    bulkEditSpecialisation(selectedRoles, { owner: value.id })
      .then(() => {
        setPending(false)
        setValue(null)
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }
  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Change owner</Header.Title>
      </Header>
      <RadioSelectInput
        label="Owner"
        selector={selectorKeys.employee}
        value={value}
        onChange={option => {
          setValue(option)
        }}
      />
      <BottomSheet.Actions>
        <Button onClick={onSubmit} pending={pending} disabled={!value} elevated>
          Update
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

/** Delete roles */

const DEPENDENCIES_ERROR = `Item cannot be deleted because it's referenced by other entities`

interface DeleteRolesConfirmationPopupProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
  rolesToDelete?: number[]
}
export const DeleteRolesConfirmationPopup = ({
  open,
  onClose,
  onSuccess,
  rolesToDelete,
}: DeleteRolesConfirmationPopupProps) => {
  const [mergePopupOpen, setMergePopupOpen] = useState(false)
  const [targetRole, setTargetRole] = useState<IdAndName | null>(null)
  const [pending, setPending] = useState(false)

  const statusPopup = useStatusPopup()

  const resetPopupState = () => {
    setPending(false)
    setTargetRole(null)
    setMergePopupOpen(false)
    onClose()
  }

  const onTransfer = () => {
    if (!rolesToDelete || !targetRole?.id) {
      return
    }
    setPending(true)
    mergeSpecialisations({
      specialisations: [{ id: targetRole.id }, ...rolesToDelete.map(id => ({ id }))],
    })
      .then(() => {
        resetPopupState()
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to merge roles</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  const onDelete = () => {
    if (!rolesToDelete) {
      return
    }

    setPending(true)

    bulkDeleteSpecialisations(rolesToDelete)
      .then(() => {
        resetPopupState()
        onSuccess()
      })
      .catch(error => {
        setPending(false)

        if (
          error.response?.status === 400 &&
          error.response.data?.detail?.includes(DEPENDENCIES_ERROR)
        ) {
          setMergePopupOpen(true)
        } else {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to delete roles</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        }
      })
  }

  return (
    <BottomSheet open={open} onClose={resetPopupState} preventUserClose={pending}>
      <Header>
        <Header.Title>
          {mergePopupOpen
            ? 'Transfer members'
            : rolesToDelete && rolesToDelete.length > 1
            ? 'Are you sure to delete these roles?'
            : 'Are you sure to delete this role?'}
        </Header.Title>
      </Header>
      {mergePopupOpen ? (
        <>
          <VStack space="s-8">
            <Text color={Token.color.greyTone50}>Choose a role</Text>
            <RadioSelectInput<IdAndName<number> & { status?: 'archived' }>
              label="Role"
              selector={selectorKeys.specialisations}
              value={targetRole}
              onChange={option => {
                setTargetRole(option)
              }}
              filter={i => i.status !== 'archived' && !rolesToDelete?.includes(i.id)}
            />
          </VStack>
          <BottomSheet.Actions horizontal>
            <Button
              onClick={onTransfer}
              pending={pending}
              disabled={!targetRole}
              elevated
            >
              Continue
            </Button>
          </BottomSheet.Actions>
        </>
      ) : (
        <>
          <Text color={Token.color.greyTone50}>
            This action can not be undone and the role members will need to be assigned to
            a new role.
          </Text>
          <BottomSheet.Actions horizontal>
            <Button onClick={resetPopupState} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => onDelete()} pending={pending} elevated>
              Yes, delete
            </Button>
          </BottomSheet.Actions>
        </>
      )}
    </BottomSheet>
  )
}

/** Change seniorities */

interface ChangeSenioritiesPopupProps {
  data: SpecialisationInterface
  open: boolean
  onSuccess: (id: IdAndName) => void
  onClose: () => void
}

export const ChangeSenioritiesPopup = ({
  data,
  open,
  onSuccess,
  onClose,
}: ChangeSenioritiesPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Change seniorities</Header.Title>
      </Header>

      <LapeForm<SpecialisationInterface>
        onSubmit={form => {
          setPending(true)

          return updateSpecialisation(data.id, form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <ChangeSenioritiesForm data={data} />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface ChangeSenioritiesFormProps {
  data: SpecialisationInterface
}

const ChangeSenioritiesForm = ({ data }: ChangeSenioritiesFormProps) => {
  const { validate, forceErrors } = useSafeFormValidator()
  const { isSubmitting, submit, values } = useLapeContext<SpecialisationInterface>()

  const statusPopup = useStatusPopup()

  useEffect(() => {
    values.seniority_min = data.seniority_min
    values.seniority_max = data.seniority_max
  }, [])

  return (
    <>
      <InputGroup variant="horizontal">
        <LapeRadioSelectInput
          name="seniority_min"
          label="Min Seniority"
          selector={selectorKeys.seniority}
        />
        <LapeRadioSelectInput
          name="seniority_max"
          label="Max Seniority"
          selector={selectorKeys.seniority}
        />
      </InputGroup>

      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              if (
                error.response?.status === 400 &&
                error.response?.data?.non_field_errors?.[0].includes?.(
                  'seniority_min and seniority_max must be provided',
                )
              ) {
                forceErrors({
                  seniority_min: !('seniority_min' in values) ? 'Required' : null,
                  seniority_max: !('seniority_max' in values) ? 'Required' : null,
                })
              } else {
                statusPopup.show(
                  <StatusPopup variant="error">
                    <StatusPopup.Title>Failed to update role</StatusPopup.Title>
                    <StatusPopup.Description>
                      {getStringMessageFromError(error)}
                    </StatusPopup.Description>
                  </StatusPopup>,
                )
              }
            },
          )}
          pending={isSubmitting}
          elevated
          disabled={!values.seniority_min || !values.seniority_max}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
