import { useGradesDistribution } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useGradesDistribution'
import { useGetCalibrationStats } from '@src/api/reviewCycles'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GradeDistributionStats } from '@src/interfaces/talent/performance'

export interface CalibrationStat {
  name: string
  targetDistribution: number
  actualDistribution: number | null
  employeesCount: number | null
}

interface CalibrationStats {
  stats: CalibrationStat[]
  isLoading: boolean
}

export const useCalibrationStats = (cycle: ReviewCyclesInterface): CalibrationStats => {
  const { grades, isLoading: isGradesLoading } = useGradesDistribution(cycle)
  const { data: stats, isLoading: isStatsLoading } = useGetCalibrationStats(cycle.id)

  const calibrationStats: CalibrationStat[] =
    grades && stats
      ? grades.map(({ id, name, value: targetDistribution }) => {
          const employeesCount = getGradeValue(id, stats)
          const actualDistribution = stats.grade_percentage
            ? Number(getGradeValue(id, stats.grade_percentage)) / 100
            : null

          return {
            name,
            targetDistribution,
            actualDistribution,
            employeesCount,
          }
        })
      : []

  return {
    stats: calibrationStats,
    isLoading: isGradesLoading || isStatsLoading,
  }
}

const getGradeValue = <T>(key: string, source: GradeDistributionStats<T>): T | null =>
  isGradeDistributionKey(key, source) ? source[key] : null

const isGradeDistributionKey = <T>(
  key: string,
  source: GradeDistributionStats<T>,
): key is keyof GradeDistributionStats<T> => Object.hasOwn(source, key)
