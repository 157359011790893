import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { unarchiveInterviewRound } from '@src/api/recruitment/interviews'
import { useQueryClient } from 'react-query'

type Props = {
  onSuccess: () => void
  round: InterviewRoundInterface
}

const Unarchive = ({ round, onSuccess }: Props) => {
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onClick = async () => {
    setIsLoading(true)
    try {
      await unarchiveInterviewRound(round.id, round.candidate.id, queryClient)
      onSuccess()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <MoreBar.Action onClick={onClick} pending={isLoading}>
      Unarchive
    </MoreBar.Action>
  )
}

export default Unarchive
