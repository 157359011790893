import { useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import {
  MIN_EDITOR_HEIGHT,
  MIN_EDITOR_HEIGHT_WITH_PARAMS,
  MIN_RESULT_HEIGHT,
  RESIZE_HANDLER_HEIGHT,
  PAGE_PADDING_BOTTOM,
} from '../constants'

export const useResize = (
  ref: React.RefObject<HTMLElement | null>,
  parameters: any[],
  hideEditor: boolean,
) => {
  const [editorHeight, setEditorHeight] = useState(0)
  const [resultHeight, setResultHeight] = useState(0)
  const [windowSize, setWindowSize] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return () => window.removeEventListener('resize', debouncedHandleResize)
  }, [])

  useEffect(() => {
    if (ref.current) {
      const editorRect = ref.current.getBoundingClientRect()
      const windowHeight = windowSize.height - PAGE_PADDING_BOTTOM
      const calcResultheight = windowHeight - editorRect.bottom - RESIZE_HANDLER_HEIGHT

      if (calcResultheight < MIN_RESULT_HEIGHT) {
        const calcEditorHeight = editorRect.height + calcResultheight - MIN_RESULT_HEIGHT
        const newEditorHeight = Math.max(
          calcEditorHeight,
          parameters.length > 0 ? MIN_EDITOR_HEIGHT_WITH_PARAMS : MIN_EDITOR_HEIGHT,
        )
        setEditorHeight(newEditorHeight)
        setResultHeight(MIN_RESULT_HEIGHT)
      } else {
        setEditorHeight(editorRect.height)
        setResultHeight(calcResultheight)
      }
    }
  }, [parameters, windowSize, hideEditor])

  return { editorHeight, resultHeight, windowSize, setEditorHeight, setResultHeight }
}
