import React, { useCallback } from 'react'
import { connect } from 'lape'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { Item, Box, Flex, InputGroup, VStack, HStack } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { TurboTransfer } from '@revolut/icons'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLocation, useParams } from 'react-router-dom'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { CreateRoadmapLocationState } from './common/AddRoadmapMoreBarAction'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getRoadmapBackUrl, getRoadmapEntityName } from './common/utils'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

type DisabledFields = {
  [key in keyof RoadmapInterface]?: string
}

export const RoadmapForm = ({
  disabledFields,
  orgUnitSelector,
}: {
  disabledFields?: DisabledFields
  orgUnitSelector?: CreateRoadmapLocationState['genericRoadmapCreationType']
}) => {
  return (
    <InputGroup>
      {orgUnitSelector === 'department' && (
        <LapeRadioSelectInput
          required
          name="department"
          selector={selectorKeys.department}
          label="Department"
        />
      )}
      {orgUnitSelector === 'team' && (
        <LapeRadioSelectInput
          required
          name="team"
          selector={selectorKeys.team}
          label="Team"
        />
      )}
      <LapeRadioSelectInput
        required
        disabled={!!(disabledFields && disabledFields.review_cycle)}
        name="review_cycle"
        label="Review Cycle"
        selector={selectorKeys.review_cycles}
        message={disabledFields?.review_cycle}
      />
      <LapeNewTextArea label="Roadmap name" name="name" required />

      <LapeRadioSelectInput
        required
        disabled={!!(disabledFields && disabledFields.owner)}
        name="owner"
        label="Owner"
        selector={selectorKeys.employee}
      />
      <VStack space="s-16">
        <LapeRadioSelectInput
          name="priority"
          label="Priority"
          selector={selectorKeys.roadmap_priority}
        />
        <HStack space="s-16">
          <LapeDatePickerInput name="start_date" label="Start Date" hideOptional />
          <LapeDatePickerInput name="due_date" label="Due Date" hideOptional />
        </HStack>
      </VStack>
    </InputGroup>
  )
}

export const RoadmapProgressForm = () => {
  return (
    <InputGroup>
      <Flex flexDirection="row" justifyContent="space-between">
        <Box flex={1} mr="s-16">
          <LapeRadioSelectInput
            name="status"
            label="Status"
            selector={selectorKeys.roadmap_status}
          />
        </Box>
        <Box flex={1}>
          <LapeDatePickerInput
            name="resolution_date_time"
            label="Resolved On"
            hideOptional
          />
        </Box>
      </Flex>
    </InputGroup>
  )
}

const RoadmapsFormPage = () => {
  const { id } = useParams<{ id: string }>()
  const { initialValues, values } = useLapeContext<RoadmapInterface>()
  const location = useLocation<CreateRoadmapLocationState>()
  const title = id ? 'Edit Roadmap' : 'Create Roadmap'
  const subtitle = location.state?.name || getRoadmapEntityName(values)
  const backUrl = location.state?.backUrl || getRoadmapBackUrl(values)
  const genericRoadmapCreationType = location.state.genericRoadmapCreationType
  const editMode = !!id
  const ctaLabel = editMode ? 'Save Changes' : 'Submit'
  const popupSuccessMessage = editMode ? 'Roadmap Updated' : 'Roadmap Created'
  const user = useSelector(selectUser)
  const navigateAfterSuccess = useCallback(() => navigateReplace(backUrl), [backUrl])

  if (!id && Object.keys(initialValues).length === 0) {
    return (
      <InternalRedirect to={pathToUrl(ROUTES.FORMS.EMPLOYEE.ROADMAP, { id: user.id })} />
    )
  }

  return (
    <PageWrapper>
      <PageHeader title={title} subtitle={subtitle} backUrl={backUrl} />
      <PageBody>
        <AutoStepper pt="s-8" pb="100px">
          {!editMode && (
            <Item useIcon={TurboTransfer}>
              <Item.Content>
                <Item.Title>Creating Roadmaps</Item.Title>
                <Item.Description>
                  Roadmaps are a high-level planning tool used to define an objective or
                  goal and major deliverables, tasks, or milestone steps required to
                  achieve it. You can create multiple roadmaps for your organisation in
                  each performance review cycle.
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          <Box mt="s-16" />
          {editMode && <NewStepperTitle title="General details" />}
          <RoadmapForm orgUnitSelector={genericRoadmapCreationType} />
          {editMode && <NewStepperTitle title="Progress" />}
          {editMode && <RoadmapProgressForm />}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={popupSuccessMessage}
          onAfterSubmit={navigateAfterSuccess}
          preventUserClose
        >
          {ctaLabel}
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}

export default connect(RoadmapsFormPage)
