import React from 'react'
import pluralize from 'pluralize'
import { Widget, Token, Box, H6, ItemSkeleton } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetEmployeeTimeOffApprovableRequests } from '@src/api/timeOff'
import {
  END_DATE_FILTER_KEY,
  START_DATE_FILTER_KEY,
} from '@src/features/TimeOffCalendarTable/constants'
import { format, addYears, subYears } from 'date-fns'
import { ApprovalItem } from '@src/pages/EmployeeProfile/Layout/TimeManagement/ApprovalsWidget/ApprovalItem'

type Props = {
  data: EmployeeInterface
}
export const ApprovalsWidget = ({ data }: Props) => {
  const startDateFilter = format(subYears(Date.now(), 1), 'yyyy-MM-dd')
  const endDateFilter = format(addYears(Date.now(), 1), 'yyyy-MM-dd')

  const {
    data: pendingApprovalRequests,
    refetch: refetchPendingApprovalRequests,
    isLoading: isLoadingPendingApprovalRequests,
  } = useGetEmployeeTimeOffApprovableRequests(data.id, [
    { columnName: 'approval_status', filters: [{ id: 'pending', name: 'pending' }] },
    {
      filters: [
        {
          id: startDateFilter,
          name: startDateFilter,
        },
      ],
      columnName: START_DATE_FILTER_KEY,
      nonResettable: true,
    },
    {
      filters: [
        {
          id: endDateFilter,
          name: endDateFilter,
        },
      ],
      columnName: END_DATE_FILTER_KEY,
      nonResettable: true,
    },
  ])

  if (isLoadingPendingApprovalRequests) {
    return (
      <Widget>
        <ItemSkeleton />
        <ItemSkeleton />
        <ItemSkeleton />
      </Widget>
    )
  }
  if (!pendingApprovalRequests?.length) {
    return null
  }
  return (
    <Widget>
      <Box px="s-16" pt="s-16">
        <H6 color={Token.color.greyTone50}>
          Showing {pluralize('request', pendingApprovalRequests.length, true)}
        </H6>
      </Box>
      {pendingApprovalRequests?.map(request => {
        const hasTimePeriodData = !!(request.from_date_time && request.to_date_time)

        if (hasTimePeriodData) {
          return (
            <ApprovalItem
              key={request.id}
              employee={data}
              request={request}
              refetchData={async () => {
                await refetchPendingApprovalRequests()
              }}
            />
          )
        }
        return null
      })}
    </Widget>
  )
}
