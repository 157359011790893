import React from 'react'
import { Flex, ItemSkeleton, Link, Widget, chain } from '@revolut/ui-kit'
import { useGetPendingTimeOffRequestsByCurrentUser } from '@src/api/timeOff'
import { ROUTES } from '@src/constants/routes'
import { ApprovalItem } from '@src/pages/EmployeeProfile/Layout/TimeManagement/ApprovalsWidget/ApprovalItem'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'

export const ApprovalsWidget = () => {
  const {
    data: allPendingRequests,
    isLoading: isLoadingPendingRequests,
    refetch: refetchPendingApprovalRequests,
  } = useGetPendingTimeOffRequestsByCurrentUser()

  if (isLoadingPendingRequests) {
    return (
      <Widget>
        <ItemSkeleton />
      </Widget>
    )
  }
  if (!allPendingRequests?.length) {
    return null
  }

  const firstPendingRequest = allPendingRequests[0]
  const hasTimePeriodData = !!(
    firstPendingRequest.from_date_time && firstPendingRequest.to_date_time
  )

  return (
    <>
      {allPendingRequests.length > 1 && (
        <Flex justifyContent="flex-end" px="s-16" pt="s-16">
          <Link
            fontWeight={500}
            href={pathToFullUrlWithActiveWorkspace(
              ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW,
              {
                employeeId: firstPendingRequest.employee.id,
                id: firstPendingRequest.id,
              },
            )}
            target="_blank"
            use="a"
          >
            {chain('Show all', allPendingRequests.length)}
          </Link>
        </Flex>
      )}
      <Widget>
        {hasTimePeriodData && (
          <ApprovalItem
            alwaysShowActions
            key={firstPendingRequest.id}
            employee={firstPendingRequest.employee}
            refetchData={async () => {
              await refetchPendingApprovalRequests()
            }}
            request={firstPendingRequest}
          />
        )}
      </Widget>
    </>
  )
}
