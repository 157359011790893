import React from 'react'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AttendanceTrackingSettings } from '@src/interfaces/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Box, Group } from '@revolut/ui-kit'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'

export const Attendance = () => {
  const { values } = useLapeContext<AttendanceTrackingSettings>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeAttendanceTrackingPreferences,
  )

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle
            title="Approval"
            subtitle="Settings that apply to the approval of attendance"
          />
          <ApprovalSteps
            title="Enable Employee Attendance Tracking Approval"
            description="Whether attendance tracking requires approval or not"
            switchField="enable_employee_attendance_tracking_approvals"
            approvalProcess="employee_attendance_tracking_overtime_approvals"
            entity="Attendance"
            disabled={disableEdit}
            formRef={values}
          />
          <NewStepperTitle
            title="Overtime requests"
            subtitle="Settings and permissions related to OT requests"
          />
          <Box>
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Restrict Overtime Requests',
                  description: 'Prevent employees from self-requesting overtime',
                }}
                name="restrict_employee_overtime_requests"
                disabled={disableEdit}
              />
            </Group>
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup successText="Settings saved successfully" useValidator />
      </PageActions>
    </>
  )
}
