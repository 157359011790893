import { ReviewCycleTimelineField, Timeline } from '@src/interfaces/settings'
import { useMemo, useState } from 'react'

type TimelinesMap = Record<ReviewCycleTimelineField, Timeline | undefined>
export type TimelineUIFields =
  | 'openReviewDay'
  | 'reviewDuration'
  | 'goalSettingOpenDay'
  | 'goalSettingDuration'
  | 'calibrationStartDay'
  | 'calibrationDuration'
  | 'globalPublishDay'
  | 'managersPublishDay'

interface UseTimelineMappingsProps {
  timelines: Timeline[]
  onAfterChanged: () => void
}
export const useTimelineMappings = ({
  timelines,
  onAfterChanged,
}: UseTimelineMappingsProps) => {
  const map: TimelinesMap = useMemo(
    () =>
      timelines.reduce(
        (acc, item) => ({
          ...acc,
          [item.field]: item,
        }),
        {} as TimelinesMap,
      ),
    [timelines],
  )
  const openReviewDay = map.review_period_start_day?.days
    ? map.review_period_start_day.days * -1
    : 0
  const reviewDuration =
    (map.self_and_peer_reviews_last_day?.days
      ? map.self_and_peer_reviews_last_day.days
      : 0) + openReviewDay

  const goalSettingOpenDay = map.department_kpi_period_start_day?.days
    ? map.department_kpi_period_start_day?.days * -1
    : 0
  const goalSettingDuration =
    (map.department_kpi_period_end_day?.days
      ? map.department_kpi_period_end_day.days
      : 0) + goalSettingOpenDay

  const calibrationStartDay = map.head_of_function_calibration_start_day?.days
    ? map.head_of_function_calibration_start_day.days
    : 0
  const calibrationDuration = map.head_of_function_and_department_last_calibration_day
    ?.days
    ? map.head_of_function_and_department_last_calibration_day.days
    : 0
  const globalPublishDay = map.reviews_publishing_day?.days
    ? map.reviews_publishing_day.days
    : 0
  const managersPublishDay = map.managers_publishing_day?.days
    ? map.managers_publishing_day.days
    : 0

  const [state, setState] = useState<Record<TimelineUIFields, string | undefined>>({
    reviewDuration: String(reviewDuration),
    openReviewDay: String(openReviewDay),
    goalSettingOpenDay: String(goalSettingOpenDay),
    goalSettingDuration: String(goalSettingDuration),
    calibrationStartDay: String(calibrationStartDay),
    calibrationDuration: String(calibrationDuration),
    globalPublishDay: String(globalPublishDay),
    managersPublishDay: String(managersPublishDay),
  })

  return {
    ...state,
    onChange: (field: TimelineUIFields, value: string | undefined) => {
      setState({
        ...state,
        [field]: value,
      })
      onAfterChanged()
    },
    getTimelines: (): Timeline[] => {
      return timelines.map(t => {
        switch (t.field) {
          case 'review_period_start_day':
            return {
              ...t,
              days: Number(state.openReviewDay || 0) * -1,
            }
          case 'self_and_peer_reviews_last_day':
            return {
              ...t,
              days: Number(state.reviewDuration || 0) - Number(state.openReviewDay || 0),
            }
          case 'team_kpi_period_start_day':
          case 'department_kpi_period_start_day':
            return {
              ...t,
              days: Number(state.goalSettingOpenDay || 0) * -1,
            }
          case 'department_kpi_period_end_day':
          case 'team_kpi_period_end_day':
            return {
              ...t,
              days:
                Number(state.goalSettingDuration || 0) -
                Number(state.goalSettingOpenDay || 0),
            }
          case 'department_owner_calibration_start_day':
          case 'head_of_function_calibration_start_day':
            return {
              ...t,
              days: Number(state.calibrationStartDay),
            }
          case 'head_of_function_and_department_last_calibration_day':
            return {
              ...t,
              days: Number(state.calibrationDuration),
            }
          case 'reviews_publishing_day':
            return {
              ...t,
              days: Number(state.globalPublishDay),
            }
          case 'managers_publishing_day':
            return {
              ...t,
              days: Number(state.managersPublishDay),
            }
          default:
            return t
        }
      })
    },
  }
}
