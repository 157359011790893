import { ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { ReviewCycleSubStage } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'

export const stageToEditLinkLabelMap: Partial<
  Record<ReviewCycleStage | ReviewCycleSubStage, string>
> = {
  [ReviewCycleSubStage.EmployeeReview]: 'Employee',
  [ReviewCycleSubStage.ManagerReview]: 'Manager',
  [ReviewCycleSubStage.HODCalibration]: 'Head of Department',
  [ReviewCycleSubStage.HOFCalibration]: 'Head of Function',
}
