import React from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CareerPageSettingsInterface } from '@src/interfaces/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { API } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import {
  Avatar,
  Box,
  Copyable,
  HStack,
  Item,
  Text,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import CompanyLogoInput from '@src/pages/Settings/JobPosting/CareerPage/CompanyLogoInput'
import LinksInput from '@src/pages/Settings/JobPosting/CareerPage/LinksInput'
import JobPostingGroupsInput from '@src/pages/Settings/JobPosting/CareerPage/JobPostingGroupsInput'
import LapeHTMLEditor from '@src/components/Inputs/LapeFields/LapeHTMLEditor'
import { useGetPathWithWorkspace } from '@src/actions/RouterActions'

export const CareerLink = () => {
  const getPathWithWorkspace = useGetPathWithWorkspace()
  const path = getPathWithWorkspace(PUBLIC_ROUTES.CAREERS.JOB_LIST)
  const url = `${window.location.protocol}//${window.location.hostname}${path}`

  return (
    <Box>
      <Text>You’ll be able to access page at&nbsp;</Text>

      <HStack align="center" display="inline-flex">
        <InternalUIKitLink use="a" href={path} target="_blank" whiteSpace="nowrap">
          {url}
        </InternalUIKitLink>
        <Box>
          <Copyable value={url} labelButtonCopy="Copy" />
        </Box>
      </HStack>
    </Box>
  )
}

type Props = {
  onboarding?: boolean
}

export const CareerPageCommon = ({ onboarding }: Props) => {
  const { values } = useLapeContext<CareerPageSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCareerPagePreferences)
  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle noAutoStep title="Publish" subtitle={<CareerLink />} />
          <LapeNewSwitch
            required
            itemTypeProps={{
              title: 'Publish careers page',
              description:
                'Make the page visible for candidates. All the published Job Postings will appear there',
              avatar: <Avatar size={40} useIcon="Globe" />,
            }}
            name="publish_career_website"
            disabled={disableEdit}
          />
          {!onboarding && (
            <>
              <NewStepperTitle
                noAutoStep
                title="Compliance"
                subtitle="Make sure you’re compliant with regulations"
              />
              <Item use="label">
                <Item.Content>
                  <Item.Title>Data Privacy notice</Item.Title>
                  <Item.Description>
                    Upload a link to your Data Privacy Notice for candidates. It will be
                    placed in the footer of the application page and added to each job
                    posting. Please be advised that a Privacy Notice explaining how
                    personal data is processed might be mandated by law in certain
                    geographies.
                  </Item.Description>
                  <Box mt="s-16">
                    <LapeNewInput
                      name="data_privacy_notice_url"
                      placeholder="Link"
                      disabled={disableEdit}
                    />
                  </Box>
                </Item.Content>
              </Item>
            </>
          )}

          <NewStepperTitle
            noAutoStep
            title="Personalisation"
            subtitle="Personalise the Careers page using your brand assets"
          />
          <Box mt="s-24">
            <Widget>
              <VStack width="100%">
                <Item>
                  <Item.Content>
                    <Item.Title>Slogan</Item.Title>
                    <Item.Description>
                      One or two sentences. i.e. “Join us to build the fastest rocketship
                      to Mars!”
                    </Item.Description>
                    <Box mt="s-16">
                      <LapeNewTextArea
                        required
                        name="slogan"
                        label="Slogan"
                        disabled={disableEdit}
                        maxCharacters={140}
                      />
                    </Box>
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Title>Company logo</Item.Title>
                    <Item.Description>
                      Upload a logo to be used at the header of the page
                    </Item.Description>
                    <CompanyLogoInput disabled={disableEdit} />
                  </Item.Content>
                </Item>
                <Item>
                  <Item.Content>
                    <Item.Title>Custom links</Item.Title>
                    <Item.Description>
                      Add up to 4 links to the header of the page. For example, your
                      company’s blog or “About us” page.
                      <br />
                      When adding custom links, keep in mind that they will be displayed
                      from left to right on the final page.
                      <br />
                      The order is determined by how they appear in the customisation
                      settings.
                    </Item.Description>
                  </Item.Content>
                </Item>
                <LinksInput disabled={disableEdit} />
                <Item>
                  <Item.Content>
                    <Item.Title>About company</Item.Title>
                    <Item.Description>
                      This text that will be added to each job posting’s “About the
                      company” section
                    </Item.Description>
                    <Box mt="s-16">
                      <LapeHTMLEditor
                        required
                        name="about_the_company"
                        placeholder={`Example:
At our company, we are driven by a single goal: to be the benchmark of excellence in our industry. Since our founding, we've been at the forefront of innovation, committed to not only providing exceptional products and services but also to making a positive impact on our community and the environment.

We want exceptional candidates that love great companies.`}
                        disabled={disableEdit}
                        maxCharacters={5000}
                      />
                    </Box>
                  </Item.Content>
                </Item>
              </VStack>
            </Widget>
          </Box>
          {!onboarding && (
            <>
              <NewStepperTitle
                noAutoStep
                title="Job postings grouping"
                subtitle="Decide how your Job Postings will be grouped on the Careers page"
              />
              <Box mt="s-24">
                <Widget>
                  <VStack width="100%">
                    <LapeNewSwitch
                      required
                      itemTypeProps={{
                        title: 'Enable custom grouping by function',
                        description: (
                          <>
                            By default all the Job Postings are grouped by their
                            corresponding Functions. Activate this option if you wish to
                            define custom groupings that combine multiple Functions under
                            a single custom group. Any functions not included in these
                            custom groupings will be categorised under 'Others'.
                          </>
                        ),
                      }}
                      name="group_by_function"
                      disabled={disableEdit}
                      onAfterChange={groupByFunction => {
                        if (!groupByFunction) {
                          values.function_groups = []
                        }
                      }}
                    />
                    {values.group_by_function && (
                      <JobPostingGroupsInput disabled={disableEdit} />
                    )}
                  </VStack>
                </Widget>
              </Box>
            </>
          )}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.CAREER_PAGE_SETTINGS)
            queryClient.invalidateQueries(API.POSTING_CAREER_PAGE_SETTINGS)
          }}
          useValidator
          isExistingData
        />
      </PageActions>
    </>
  )
}

export const CareerPageGeneral = () => <CareerPageCommon />
export const CareerPageOnboardingGeneral = () => <CareerPageCommon onboarding />
