import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { keyPersonResponsibilityFormRequests } from '@src/api/keyPerson'
import { PageWrapper } from '@src/components/Page/Page'
import { EditResponsibility } from './General'
import { PreviewResponsibility } from './Preview'

export const Responsibility = () => {
  return (
    <Form api={keyPersonResponsibilityFormRequests}>
      <PageWrapper>
        <Switch>
          <Route path={ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.EDIT}>
            <EditResponsibility />
          </Route>
          <Route path={ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW}>
            <PreviewResponsibility />
          </Route>
        </Switch>
      </PageWrapper>
    </Form>
  )
}
