import React from 'react'
import { Group, ItemSkeleton } from '@revolut/ui-kit'

import { navigateReplace } from '@src/actions/RouterActions'
import NewSelectionCard from '@src/components/NewSelectionCard/NewSelectionCard'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'

import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { EntityData } from '@src/pages/Forms/DataAnalyticsDashboardForm/DashboardForm'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { useGetTableauPreferences } from '@src/api/integrations'

const Title = () => (
  <FormattedMessage
    id="analyticsDashboardSelect.page.title"
    defaultMessage="Create new dashboard"
  />
)

const Description = () => (
  <FormattedMessage
    id="analyticsDashboardSelect.page.description"
    defaultMessage="Select dashboard type"
  />
)

export const DashboardSelect = () => {
  const location = useLocation<EntityData>()
  const featureFlags = useSelector(selectFeatureFlags)
  const canCreateInternal = featureFlags.includes(FeatureFlags.EnableInternalDashboards)
  const { data: tableauPreferences, isLoading } = useGetTableauPreferences()

  return (
    <PageWrapper>
      <PageHeader
        title={<Title />}
        subtitle={<Description />}
        backUrl={pathToUrl(ROUTES.APPS.DATA_ANALYTICS.DASHBOARD)}
      />

      <PageBody>
        <Group>
          {isLoading && (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          )}
          {canCreateInternal && (
            <NewSelectionCard
              icon="BarChart"
              title="Metrics dashboard"
              use="button"
              onClick={() => {
                navigateReplace(
                  pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.DETAILS, {}),
                  location.state,
                )
              }}
            />
          )}
          <NewSelectionCard
            image="https://assets.revolut.com/assets/apps/Looker.png"
            title="Looker dashboard"
            use="button"
            onClick={() => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, {}),
                location.state,
              )
            }}
          />
          {tableauPreferences?.enabled && (
            <NewSelectionCard
              image="https://assets.revolut.com/assets/apps/Tableau.webp"
              title="Tableau dashboard"
              use="button"
              onClick={() => {
                navigateReplace(
                  pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_TABLEAU_DASHBOARD.DETAILS, {}),
                  location.state,
                )
              }}
            />
          )}
        </Group>
      </PageBody>
    </PageWrapper>
  )
}
