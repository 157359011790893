import React from 'react'
import { Box, Subheader, Group } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { getBackUrl, useEmployeeBenefitsParams } from './common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeBenefitInterface } from '@src/interfaces/benefits'
import { PageBody } from '@src/components/Page/PageBody'
import {
  AdditionalInfoDocuments,
  DependantsNumberPreviewItem,
  EmployeeContributionPreviewItem,
  EmployerContributionPreviewItem,
  PackageNotFoundPage,
} from '@src/pages/Forms/Benefits/common'
import { FormPreview } from '@src/components/FormPreview/FormPreview'

export const PackageDetails = () => {
  const { initialValues } = useLapeContext<EmployeeBenefitInterface>()
  const params = useEmployeeBenefitsParams()

  const backUrl = getBackUrl(params.employeeId)

  const benefitPackage = initialValues?.template?.packages.find(
    p => params.packageId && p.id === +params.packageId,
  )

  if (!benefitPackage) {
    return <PackageNotFoundPage backUrl={backUrl} />
  }

  return (
    <PageWrapper>
      <PageHeader title={benefitPackage.name} backUrl={backUrl} />
      <PageBody>
        <FormPreview data={benefitPackage}>
          <Group>
            <FormPreview.Item title="Package name" field="name" />
            <FormPreview.Item title="Package cover" field="cover" />
            <EmployeeContributionPreviewItem
              benefit={initialValues.template!}
              benefitPackage={benefitPackage}
            />
            <EmployerContributionPreviewItem
              benefit={initialValues.template!}
              benefitPackage={benefitPackage}
            />
            <FormPreview.Item
              title="Dependants allowed"
              field="dependants_allowed"
              type="boolean"
            />
            {benefitPackage.dependants_allowed ? (
              <DependantsNumberPreviewItem benefitPackage={benefitPackage} />
            ) : null}
          </Group>
        </FormPreview>
        {benefitPackage.additional_information.length ? (
          <Box mt="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Additional information</Subheader.Title>
            </Subheader>
            <AdditionalInfoDocuments benefitPackage={benefitPackage} />
          </Box>
        ) : null}
      </PageBody>
    </PageWrapper>
  )
}
