import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { AccountList } from '@src/pages/Settings/Accounts/AccountList/AccountList'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { GeneralForm } from '@src/pages/Settings/Accounts/General'
import { Details } from '@src/pages/Settings/Accounts/Account/Details'
import { AccountInvoiceDetails } from '@src/pages/Settings/Accounts/AccountInvoiceDetails'
import { AccountDiscount } from '@src/pages/Settings/Accounts/AccountDiscount/AccountDiscount'

export const AccountsSettings = () => {
  useSetDocumentTitle('Settings', 'Accounts')

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.ACCOUNTS.LIST}>
          <AccountList />
        </Route>
        <Route exact path={ROUTES.SETTINGS.ACCOUNTS.GENERAL}>
          <GeneralForm />
        </Route>
        <Route
          path={[
            ROUTES.SETTINGS.ACCOUNTS.INFORMATION,
            ROUTES.SETTINGS.ACCOUNTS.INVOICES,
            ROUTES.SETTINGS.ACCOUNTS.FEATURES,
            ROUTES.SETTINGS.ACCOUNTS.DISCOUNTS,
          ]}
        >
          <Details />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.INVOICE}>
          <AccountInvoiceDetails />
        </Route>
        <Route path={ROUTES.SETTINGS.ACCOUNTS.DISCOUNT}>
          <AccountDiscount />
        </Route>
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
