import { Token } from '@revolut/ui-kit'

export const getColorForPortion = (portion: number) => {
  if (portion < 0.5) {
    return Token.color.danger
  }

  if (portion < 0.8) {
    return Token.color.warning
  }

  if (portion <= 1) {
    return Token.color.success
  }

  return Token.color.greyTone50
}
