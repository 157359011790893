import React, { ReactNode } from 'react'
import { Text, Token, Link, Banner, Avatar } from '@revolut/ui-kit'
import { UpdateTypes } from '@src/interfaces/kpis'
import { JIRA_EMAIL_VISIBILITY_LINK } from '@src/constants/externalLinks'

export const integrationPopupContentMap: Record<
  UpdateTypes,
  { label: string | undefined; description: ReactNode }
> = {
  // there is no integration for SQL / Manual so in reality it'd never happen but to keep types aligned
  // adding them as well
  [UpdateTypes.sql]: {
    label: undefined,
    description: undefined,
  },
  [UpdateTypes.manual]: {
    label: undefined,
    description: undefined,
  },
  [UpdateTypes.salesforce]: {
    label: 'Salesforce server URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Salesforce account URL is your unique identifier, followed by{' '}
        <Text color={Token.color.foreground}>salesforce.com</Text>, for example{' '}
        <Text color={Token.color.foreground}>
          https://dream-customer.my.salesforce.com
        </Text>
        .
      </Text>
    ),
  },
  [UpdateTypes.roadmap]: {
    label: 'Jira server URL',
    description: (
      <>
        <Text variant="body2" color={Token.color.greyTone50}>
          Your Jira account URL is your unique identifier followed by{' '}
          <Text color={Token.color.foreground}>atlassian.net</Text>, for example{' '}
          <Text color={Token.color.foreground}>https://revolut.atlassian.net</Text>.
        </Text>

        <Banner>
          <Banner.Avatar>
            <Avatar
              useIcon="16/Warning"
              size={40}
              bg={Token.color.orange_10}
              color={Token.color.warning}
            />
          </Banner.Avatar>
          <Banner.Content>
            <Banner.Title>Jira action might be required</Banner.Title>
            <Banner.Description>
              All Jira users need to set their email visibility to <b>Anyone</b>, as
              described{' '}
              <Link
                use="a"
                rel="noreferrer noopener"
                target="_blank"
                href={JIRA_EMAIL_VISIBILITY_LINK}
              >
                here
              </Link>
              , in order to be imported to Revolut People.
            </Banner.Description>
          </Banner.Content>
        </Banner>
      </>
    ),
  },
  [UpdateTypes.looker]: {
    label: 'Looker account URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Looker account URL is your unique identifier, followed by{' '}
        <Text color={Token.color.foreground}>cloud.looker.com</Text>, for example{' '}
        <Text color={Token.color.foreground}>https://revolut.cloud.looker.com</Text>.
      </Text>
    ),
  },
  // clickup does not require a popup
  [UpdateTypes.clickup]: {
    label: undefined,
    description: undefined,
  },
  [UpdateTypes.tableau]: {
    label: 'Tableau instance URL',
    description: (
      <Text variant="body2" color={Token.color.greyTone50}>
        Your Tableau instance URL contains your tenant_id and your{' '}
        <Text color={Token.color.foreground}>site_id</Text>, for example{' '}
        <Text color={Token.color.foreground}>
          {'https://test.online.tableau.com/#/site/test'}
        </Text>
        .
      </Text>
    ),
  },
}
