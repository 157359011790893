import React from 'react'
import { Text, TextProps } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { formatDate } from '@src/utils/format'
import { compareAsc } from 'date-fns'

interface RoadmapLockedBannerProps extends Omit<TextProps, 'variant' | 'to'> {
  reviewCycle: ReviewCyclesInterface | null
}

const RoadmapLockedChangesBanner = ({
  reviewCycle,
  hidden,
  ...rest
}: RoadmapLockedBannerProps) => {
  const { roadmap_start_date: startDate, roadmap_end_date: endDate } = reviewCycle || {}

  if (
    hidden ||
    !startDate ||
    !endDate ||
    compareAsc(new Date(), new Date(startDate)) <= 0
  ) {
    return null
  }

  return (
    <Text
      variant="caption"
      color="grey-tone-50"
      data-testid="roadmap-locked-banner"
      {...rest}
    >
      Showing all roadmap changes from {formatDate(startDate)} up to {''}
      {formatDate(endDate)}
    </Text>
  )
}

export default RoadmapLockedChangesBanner
