import React, { useEffect } from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  ChangeScorecardInterface,
  CriteriaAssessment,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import {
  assessBehaviourButtonsValidation,
  assessButtonsValidation,
  getClearedIncompleteSections,
  getNormalizedCards,
  getUpdatedRatingCard,
  improvementFeedbackValidation,
} from '@src/utils/performance'
import * as yup from 'yup'
import get from 'lodash/get'
import { useLapeContext } from '@src/features/Form/LapeForm'
import CultureFitHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/CultureFitHelp'
import { useGetSkillsSettings } from '@src/api/settings'

export const validator = {
  review_data: yup.object().shape({
    culture_skills: yup
      .object()
      .nullable()
      .shape({
        cards: yup.array().of(
          yup.object().shape({
            sections: yup.array().of(
              yup
                .object()
                .shape<any>({
                  items: yup.array().of(
                    yup.object().shape({
                      value: yup.string().nullable(),
                    }),
                  ),
                })
                .test(
                  'assess-buttons',
                  'Please complete Culture Fit before submitting.',
                  assessButtonsValidation as any,
                ),
            ),
          }),
        ),
      }),
    culture_values: yup
      .object()
      .nullable()
      .shape({
        cards: yup.array().of(
          yup.object().shape({
            sections: yup.array().of(
              yup
                .object()
                .shape<any>({
                  value: yup.string().nullable(),
                })
                .test(
                  'assess-buttons',
                  'Please complete Culture Fit before submitting.',
                  assessBehaviourButtonsValidation as any,
                ),
            ),
          }),
        ),
      })
      .test(
        'improvement-focus',
        'You should select at least 2 behaviours as "Improvement focus" across all values',
        improvementFeedbackValidation as any,
      ),
  }),
}

const CultureFit = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors, submitFailed, fields, change } = form
  const { data: skillsPreferences } = useGetSkillsSettings()

  useEffect(() => {
    if (form.validation?.review_data?.fields?.culture_values && skillsPreferences) {
      form.validation.review_data.fields.culture_values =
        form.validation.review_data.fields.culture_values.meta({ skillsPreferences })
    }
  }, [skillsPreferences, form.validation?.review_data])

  const initialType = values.review_data?.culture_values
    ? PerformanceReviewTypes.cultureValuesFit
    : PerformanceReviewTypes.cultureFit

  const cultureValuesCards = values?.review_data?.culture_values?.cards || []

  const normalizedCards = getNormalizedCards(
    values.review_data?.culture_skills?.cards || [],
  )

  useEffect(() => {
    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  if (!normalizedCards) {
    return null
  }

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_skills.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id,
      values.review_data?.culture_skills?.cards?.[cardIndex],
    )

    if (updatedCard) {
      change(`${path}.rating`, updatedCard.rating)
    }

    const visibleSections = getNormalizedCards(
      values.review_data?.culture_skills?.cards || [],
    )?.[cardIndex]?.sections
    const sections = get(values, `${path}.sections`, []) as CriteriaAssessment[]

    const clearedIncompleteLevels = getClearedIncompleteSections(
      sections,
      visibleSections,
    )
    change(`${path}.sections`, clearedIncompleteLevels)
  }

  const onChangeCultureValues = async ({
    cardIndex,
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    change(
      `review_data.culture_values.cards.${cardIndex}.sections.${sectionIndex}.value`,
      value,
    )
  }

  return (
    <ScorecardGeneral
      cards={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? cultureValuesCards
          : normalizedCards
      }
      category={values.category}
      errors={(() => {
        if (submitFailed) {
          return initialType === PerformanceReviewTypes.cultureValuesFit
            ? (errors?.review_data?.culture_values?.cards as ScorecardError[])
            : (errors?.review_data?.culture_skills?.cards as ScorecardError[])
        }

        return null
      })()}
      helpSection={
        <CultureFitHelp
          isCultureValues={initialType === PerformanceReviewTypes.cultureValuesFit}
        />
      }
      onChange={
        initialType === PerformanceReviewTypes.cultureValuesFit
          ? onChangeCultureValues
          : onChange
      }
      previousReviews={values?.review_data?.previous_reviews_by_cycle}
      title="Culture Fit"
      touched={Object.values(fields).some(state => state.touched)}
      type={initialType}
    />
  )
}

export default CultureFit
