import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportFaqInterface } from '@src/interfaces/faq'
import { selectorKeys } from '../api'

export const importFaqQuestionColumn: ColumnInterface<ImportFaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Question',
}

export const importFaqTopicColumn: ColumnInterface<ImportFaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'topic_id',
  dataPoint: 'topic_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Topic',
}

export const importFaqCountriesColumn: ColumnInterface<ImportFaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'countries',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Countries',
}

export const importFaqContentColumn: ColumnInterface<ImportFaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'content',
  dataPoint: 'content',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Content',
}

export const importFaqTrainingPhrasesColumn: ColumnInterface<ImportFaqInterface> = {
  type: CellTypes.insert,
  idPoint: 'content',
  dataPoint: 'content',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Training phrases',
  notHoverable: true,
}
