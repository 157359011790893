import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { ClosingSubscriptionPageWall } from '@src/features/Subscriptions/ClosingSubscriptionPageWall'
import { BulkDataImportUploadFile } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import { API } from '@src/constants/api'
import { PageBody } from '@src/components/Page/PageBody'
import { EmployeeSession } from './EmployeesSession'

export const ImportEmployees = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Import wizard"
        subtitle="Follow our guidelines to import the data at once"
        backUrl={ROUTES.FORMS.EMPLOYEES_BULK_IMPORT}
      />

      <ClosingSubscriptionPageWall>
        <Switch>
          <Route path={ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.UPLOAD_FILE} exact>
            <PageBody>
              <BulkDataImportUploadFile
                category="employee_upload"
                apiEndpoint={API.EMPLOYEE_UPLOADS}
                name="employee"
                nextRoute={ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.SESSION}
              />
            </PageBody>
          </Route>
          <Route path={ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.SESSION} exact>
            <EmployeeSession />
          </Route>
        </Switch>
      </ClosingSubscriptionPageWall>
    </PageWrapper>
  )
}
