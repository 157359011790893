import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapLevelColumn,
  roadmapOrgUnitColumn,
  roadmapOwnerColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapResolutionAtColumn,
  roadmapStartDate,
  roadmapStatusColumn,
} from '@src/constants/columns/roadmap'
import { RowInterface } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'

export const rows: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapLevelColumn,
      width: 120,
    },
    {
      ...roadmapOrgUnitColumn,
      width: 120,
    },
    {
      ...roadmapPriorityColumn,
      width: 120,
    },
    {
      ...roadmapProgressColumn,
      width: 120,
    },
    {
      ...roadmapStatusColumn,
      width: 100,
    },
    {
      ...roadmapStartDate,
      width: 120,
    },
    {
      ...roadmapDueDate,
      width: 120,
    },
    {
      ...roadmapResolutionAtColumn,
      width: 120,
    },
    {
      ...roadmapOwnerColumn,
      width: 120,
    },
    {
      ...roadmapJiraLabelColumn,
      width: 120,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}
