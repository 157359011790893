import React, { useEffect } from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import {
  assessBehaviourButtonsValidation,
  assessButtonsValidation,
  getNormalizedCards,
  getUpdatedRatingCard,
} from '@src/utils/performance'
import * as yup from 'yup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import SkillsHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/SkillsHelp'
import { Statuses } from '@src/interfaces'
import ManagerSkills, {
  validator as managerSkillsValidator,
} from '@src/pages/Forms/EmployeePerformance/ManagerSkills'
import { List, Text } from '@revolut/ui-kit'
import { Gift } from '@revolut/icons'
import { useGetSkillsSettings } from '@src/api/settings'

export const validator = {
  review_data: yup.object().shape({
    functional_skills: yup
      .object()
      .nullable()
      .shape({
        skipped_section_justification: yup
          .string()
          .test('no-empty', 'Justification should not be empty', text =>
            typeof text?.trim() === 'string' ? text.trim().length > 0 : true,
          ),
        cards: yup.array().when('skipped_section_justification', {
          is: undefined,
          then: yup.array().of(
            yup.object().shape({
              sections: yup.array().of(
                yup
                  .object()
                  .shape<any>({
                    items: yup.array().of(
                      yup.object().shape({
                        value: yup.string().nullable(),
                      }),
                    ),
                  })
                  .test(
                    'assess-buttons',
                    'Please complete Skills before submitting.',
                    assessButtonsValidation as any,
                  ),
              ),
            }),
          ),
          otherwise: yup.array(),
        }),
      }),
    ...managerSkillsValidator,
    manager_values: yup
      .object()
      .nullable()
      .shape({
        skipped_section_justification: yup
          .string()
          .test('no-empty', 'Justification should not be empty', text =>
            typeof text?.trim() === 'string' ? text.trim().length > 0 : true,
          ),
        cards: yup.array().when('skipped_section_justification', {
          is: undefined,
          then: yup.array().of(
            yup.object().shape({
              sections: yup.array().of(
                yup
                  .object()
                  .shape<any>({
                    items: yup.array().of(
                      yup.object().shape({
                        value: yup.string().nullable(),
                      }),
                    ),
                  })
                  .test(
                    'assess-buttons',
                    'Please complete Culture Fit before submitting.',
                    assessBehaviourButtonsValidation as any,
                  ),
              ),
            }),
          ),
          otherwise: yup.array(),
        }),
      }),
  }),
}

const Skills = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submitFailed, errors, fields, change } = form
  const { data: skillsPreferences } = useGetSkillsSettings()

  const initialType = values.review_data?.manager_values
    ? PerformanceReviewTypes.managerValuesSkills
    : PerformanceReviewTypes.skills

  const normalizedCards = getNormalizedCards(
    values.review_data?.functional_skills?.cards || [],
  )
  const managerValuesCards = values?.review_data?.manager_values?.cards || []

  useEffect(() => {
    if (values.review_data.prefilled && values.status !== Statuses.completed) {
      Object.values(fields).forEach(state => {
        state.touched = true
      })
      form.submitFailed = true
      return
    }

    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  const onSkip = (value?: string) => {
    if (initialType === PerformanceReviewTypes.managerValuesSkills) {
      change('review_data.manager_values.skipped_section_justification', value)
    } else {
      change('review_data.functional_skills.skipped_section_justification', value)
      if (values.review_data.manager_skills) {
        change('review_data.manager_skills.skipped_section_justification', value)
      }
    }
  }

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.functional_skills.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id,
      values.review_data?.functional_skills?.cards?.[cardIndex],
    )

    if (updatedCard) {
      change(`${path}.rating`, updatedCard.rating)
    }
  }

  const onChangeManagerValues = async ({
    cardIndex,
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    change(
      `review_data.manager_values.cards.${cardIndex}.sections.${sectionIndex}.value`,
      value,
    )
  }

  const touched = Object.values(fields).some(state => state.touched)

  const description = (
    <>
      <Text>
        Managerial excellence is assessed based on behaviours rated from an “improvement
        focus” up to a “superpower”. Please select the most appropriate rating given their
        strengths and opportunities to develop.
      </Text>
      {skillsPreferences?.company_values_validation_enabled && (
        <List variant="compact" mt="s-8">
          <List.Item useIcon={<Gift color="green" size={16} />}>
            You can select <Text fontWeight="500">up to 3 superpower</Text> ratings
          </List.Item>
        </List>
      )}
    </>
  )

  return (
    <ScorecardGeneral
      allowSkip={
        values.category === ReviewCategory.Performance &&
        values.reviewer_relation !== ReviewerRelation.FunctionalManager
      }
      cards={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? managerValuesCards
          : normalizedCards
      }
      category={values.category}
      description={
        initialType === PerformanceReviewTypes.managerValuesSkills ? description : null
      }
      errors={(() => {
        if (submitFailed) {
          return initialType === PerformanceReviewTypes.managerValuesSkills
            ? (errors?.review_data?.manager_values?.cards as ScorecardError[])
            : (errors?.review_data?.functional_skills?.cards as ScorecardError[])
        }
        return null
      })()}
      justification={
        values?.review_data?.manager_values?.skipped_section_justification ||
        values?.review_data?.functional_skills?.skipped_section_justification
      }
      helpSection={
        <SkillsHelp
          isManagerBehaviour={initialType === PerformanceReviewTypes.managerValuesSkills}
        />
      }
      onChange={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? onChangeManagerValues
          : onChange
      }
      onSkip={onSkip}
      previousReviews={values?.review_data?.previous_reviews_by_cycle}
      subTitle={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? 'Management Behaviours'
          : null
      }
      title="Skills"
      touched={touched}
      type={initialType}
    >
      <ManagerSkills category={values.category} touched={touched} />
    </ScorecardGeneral>
  )
}

export default connect(Skills)
