import { useCallback, useEffect } from 'react'

export const useArrowNavigation = ({
  onLeftArrow,
  onRightArrow,
}: {
  onLeftArrow?: VoidFunction
  onRightArrow?: VoidFunction
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const focused = document.activeElement

      // prevent action if input types are focused
      if (
        focused &&
        (focused instanceof HTMLSelectElement ||
          focused instanceof HTMLButtonElement ||
          focused instanceof HTMLInputElement ||
          focused instanceof HTMLTextAreaElement ||
          focused instanceof HTMLOptionElement)
      ) {
        return
      }
      if (event.code === 'ArrowLeft' && onLeftArrow) {
        onLeftArrow()
      }
      if (event.code === 'ArrowRight' && onRightArrow) {
        onRightArrow()
      }
    },
    [onLeftArrow, onRightArrow],
  )

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])
}
