import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportJobPostingsDataInterface } from '@src/interfaces/importJobPostings'
import { selectorKeys } from '../api'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportCell } from '@src/constants/columns/bulkDataImport'

export type ImportJobPostingsCellOptions = {
  employeeOptions: SelectOptionItemType<EmployeeEmails>[]
}

export const importJobPostingsTitleColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => <ImportCell field="title" data={data} />,
}

export const importJobPostingsSpecialisationColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Specialisation',
  insert: ({ data }) => <ImportCell field="specialisation" data={data} />,
}

export const importJobPostingsRecruiterColumn = (
  options: ImportJobPostingsCellOptions,
): ColumnInterface<ImportInterface<ImportJobPostingsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <ImportCell
      field="recruiter"
      data={data}
      options={options?.employeeOptions}
      type="employee"
    />
  ),
})

export const importJobPostingsLocationsColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Locations',
  insert: ({ data }) => <ImportCell field="locations" data={data} />,
}

export const importJobPostingsAboutTheRoleColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'about_the_role',
  dataPoint: 'about_the_role',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'About the role',
  insert: ({ data }) => <ImportCell field="about_the_role" data={data} />,
}

export const importJobPostingsNiceToHaveColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'nice_to_have',
  dataPoint: 'nice_to_have',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Nice to have',
  insert: ({ data }) => <ImportCell field="nice_to_have" data={data} />,
}

export const importJobPostingsWhatYoullBeDoingColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'what_youll_be_doing',
  dataPoint: 'what_youll_be_doing',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: "What you'll be doing",
  insert: ({ data }) => <ImportCell field="what_youll_be_doing" data={data} />,
}

export const importJobPostingsWhatYoullNeedColumn: ColumnInterface<
  ImportInterface<ImportJobPostingsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'what_youll_need',
  dataPoint: 'what_youll_need',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: "What you'll need",
  insert: ({ data }) => <ImportCell field="what_youll_need" data={data} />,
}
