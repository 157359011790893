import { Group, Item, Text } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'

type Props = {
  data: InterviewStageWithoutRoundInterface[]
  onClick: (stageId: number) => void
  currentId?: number
  selectedId?: number
}

const StagePicker = ({ data, onClick, currentId, selectedId }: Props) => {
  const [selected, setSelected] = useState<number | undefined>(selectedId)

  return (
    <Group>
      {data.map((stage, id) => (
        <Item
          key={stage.id}
          use="button"
          variant="choice"
          aria-pressed={selected === stage.id}
          onClick={() => {
            setSelected(stage.id)
            onClick(stage.id)
          }}
        >
          <Item.Content>
            <Text variant="secondary">
              {stage.title} · {id + 1}/{data.length}
            </Text>
          </Item.Content>
          {currentId === stage.id && (
            <Item.Side>
              <Text variant="caption" color="grey-tone-50">
                Current
              </Text>
            </Item.Side>
          )}
        </Item>
      ))}
    </Group>
  )
}

export default StagePicker
