import addYears from 'date-fns/addYears'

import { useGetEmployee } from '@src/api/employees'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { EmployeeInterface } from '@src/interfaces/employees'

type UseGetTerminationDisabledDaysParams = {
  employeeId?: number | string
  employeeData?: EmployeeInterface
}
export const useGetTerminationDisabledDays = ({
  employeeId,
  employeeData,
}: UseGetTerminationDisabledDaysParams) => {
  const isCommercial = useIsCommercial()

  const { data: loadedEmployeeData } = useGetEmployee(employeeId, !!employeeData)
  const employee = employeeData || loadedEmployeeData
  const isInternal = employee ? employee.employee_type === 'internal' : undefined

  if (isCommercial) {
    return undefined
  }
  if (isInternal === undefined) {
    return { before: new Date(), after: addYears(new Date(), 1) }
  }
  return isInternal ? { before: new Date() } : { after: addYears(new Date(), 1) }
}
