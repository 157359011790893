import React from 'react'
import { Skeleton, Text } from '@revolut/ui-kit'
import { InterviewRoundState } from '@src/interfaces/interviewTool'

type Props = {
  name?: string
  state?: InterviewRoundState
  isConfidential?: boolean
  loading?: boolean
}

const Title = ({ name, state, isConfidential, loading }: Props) => {
  if (loading) {
    return <Skeleton width={450} height={40} mb="s-6" />
  }
  return (
    <>
      {isConfidential && <Text color="orange">[Confidential] </Text>}
      {name}
      {state === InterviewRoundState.archived && (
        <Text color="grey-tone-50"> · Archived</Text>
      )}
    </>
  )
}

export default Title
