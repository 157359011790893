import React, { useEffect, useState } from 'react'
import {
  EngagementResultsScope,
  EngagementSurveyInterface,
} from '@src/interfaces/engagement'
import { Highlights, TabBar, HStack, Text, Token } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { RoundSelector } from '@src/apps/People/Engagement/Results/components/RoundSelector'
import { IdAndName } from '@src/interfaces'
import { useSurveyRounds } from '@src/apps/People/Engagement/Results/hooks'
import Loader from '@src/components/CommonSC/Loader'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { ScoreType, ViewType } from '../types'
import { useLocation, useParams } from 'react-router-dom'
import {
  useAdminEngagementResultStats,
  useEngagementSurveyParticipationStats,
  useEngagementSurveyRounds,
} from '@src/api/engagement'
import {
  overallScoreToColor,
  participationRateToColor,
} from '@src/apps/People/Engagement/helpers'
import { isNumber, round } from 'lodash'
import { TableComponent } from './TableComponent'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { isHubAppPath } from '../helpers/isHubApp'
import { TabTooltip } from './TabTooltip'
import { formatPercentage } from '@src/utils/format'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

export const SurveyResultsAll = ({ survey }: { survey: EngagementSurveyInterface }) => {
  const { state } = useLocation<{ roundId?: number }>()
  const [selectedRound, setSelectedRound] = useState<IdAndName>()
  const [hasRounds, setHasRounds] = useState(true)
  const { rounds: options, asyncState } = useSurveyRounds(survey.id)
  const { type: tableType = 'categories' } =
    useParams<{ type: 'categories' | 'questions' | 'comments' }>()
  const [type, setType] = useState<ScoreType>('engagement')
  const [view] = useState<ViewType>('table')
  const {
    data: stats,
    refetch,
    isLoading,
  } = useAdminEngagementResultStats(survey.id, selectedRound?.id)

  const {
    data: participationStats,
    isLoading: isParticipationLoading,
    isError: participationStatsError,
  } = useEngagementSurveyParticipationStats({
    surveyRoundId: selectedRound?.id,
    surveyId: survey.id,
    // here for admin paths we display data for entire company
    entityType: EngagementResultsScope.Company,
    entityId: 1,
  })

  const { isLoading: isRoundsLoading } = useEngagementSurveyRounds(survey.id)

  useEffect(() => {
    if (asyncState === 'ready') {
      if (options.length) {
        let initRound = options.at(0)
        if (state?.roundId) {
          const preselectedRound = options.find(option => option.id === state.roundId)
          if (preselectedRound) {
            initRound = preselectedRound
          }
        }
        setSelectedRound(initRound)
      } else {
        setHasRounds(false)
      }
    }
  }, [asyncState])

  useEffect(() => {
    if (selectedRound) {
      refetch()
    }
  }, [selectedRound])

  if (asyncState === 'pending') {
    return <Loader />
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Highlights>
          {tableType !== 'comments' && (
            <Stat
              color={overallScoreToColor(stats?.nps_score)}
              val={
                isLoading
                  ? undefined
                  : stats?.nps_score
                  ? round(stats.nps_score, 2)
                  : 'N/A'
              }
              tooltip="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100"
              label="NPS score"
            />
          )}
          <Stat
            val={
              participationStatsError
                ? 'N/A'
                : isParticipationLoading || isRoundsLoading
                ? undefined
                : participationStats?.audience_size || 'N/A'
            }
            label="Audience size"
            tooltip="This is the number of active employees who were requested to complete the survey"
          />
          <Stat
            tooltip="This is based on the amount of employees who finished the survey (i.e. who answered all questions)"
            val={
              participationStatsError
                ? 'N/A'
                : isParticipationLoading || isRoundsLoading
                ? undefined
                : formatPercentage(participationStats?.participation_rate || null, 2)
            }
            label="Participation"
            color={
              !participationStats?.participation_rate
                ? Token.color.foreground
                : participationRateToColor(
                    Math.round(
                      isNumber(participationStats?.participation_rate)
                        ? participationStats!.participation_rate * 100
                        : 0,
                    ),
                  )
            }
          />
        </Highlights>
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <RoundSelector
          value={selectedRound}
          surveyId={survey?.id}
          onChange={value => {
            if (value?.id) {
              setSelectedRound(value)
            }
          }}
        />
        <HStack gap="s-16">
          {/* 
          TODO https://revolut.atlassian.net/browse/REVC-6347
          at the moment we just display table version
          <TabBar
            variant="segmented fit"
            mx="auto"
            value={view}
            onChange={value => {
              setView(value || 'table')
            }}
          >
            <TabBar.Item to="chart" useIcon="AvatarGrid" />
            <TabBar.Item to="table" useIcon="Menu" />
          </TabBar> */}
          {tableType !== 'comments' ? (
            <TabBar
              variant="segmented fit"
              mx="auto"
              value={type}
              onChange={value => {
                setType(value || 'engagement')
              }}
            >
              <TabBar.Item to="engagement">
                <HStack space="s-8">
                  <Text>NPS</Text>
                  <TabTooltip text="The NPS (net promoter score) is equal to the percentage of promoters minus the percentage of detractors, it ranges from -100 to +100" />
                </HStack>
              </TabBar.Item>
              <TabBar.Item to="average">
                <HStack space="s-8">
                  <Text>Average</Text>
                  <TabTooltip text="Average score is equal to the average of all answers for each particular question or driver, it ranges from 1 to 5" />
                </HStack>
              </TabBar.Item>
            </TabBar>
          ) : null}
        </HStack>
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <RadioSelectInput<IdAndName<'categories' | 'questions' | 'comments'>>
          inputProps={{ width: 240 }}
          label="Analyse"
          searchable={false}
          value={{ id: tableType, name: tableType }}
          options={[
            { value: { id: 'categories', name: 'Categories' }, label: 'Categories' },
            { value: { id: 'questions', name: 'Questions' }, label: 'Questions' },
            { value: { id: 'comments', name: 'Comments' }, label: 'Comments' },
          ]}
          onChange={value => {
            if (value) {
              const isHubApp = isHubAppPath()
              navigateReplace(
                pathToUrl(
                  ROUTES[isHubApp ? 'APPS' : 'PERFORMANCE'].ENGAGEMENT.DETAILS_RESULTS
                    .ANY,
                  {
                    id: survey.id,
                    type: value.id,
                  },
                ),
              )
            }
          }}
        />
      </Table.Widget.Actions>
      <Table.Widget.Table>
        {hasRounds ? (
          <TableComponent
            scoreType={type}
            view={view}
            selectedRound={selectedRound}
            surveyId={survey.id}
            canSeeComments
          />
        ) : (
          <EmptyTableRaw
            title="This survey does not have any rounds"
            description="Start the survey first to get some results"
            imageId="3D018"
          />
        )}
      </Table.Widget.Table>
    </Table.Widget>
  )
}
