import React from 'react'
import {
  Avatar,
  AvatarSkeleton,
  HStack,
  IconName,
  Text,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import { upperFirst } from 'lodash'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'

type ResultType = 'skills' | 'values' | 'seniority'

const getResultIcon = (type: ResultType, grade?: string | null): IconName | undefined => {
  if (type === 'seniority') {
    return 'Profile'
  }
  const str = convertOperators(grade, '', '')
  switch (str) {
    case 'unsatisfactory':
    case 'poor':
      return '16/ExclamationMarkSign'
    case 'basic':
      return 'ArrowDowngrade'
    case 'developing':
    case 'intermediate':
    case 'performing':
      return 'Check'
    case 'advanced':
      return 'ArrowUpgrade'
    case 'exceeding':
      return 'Trophy'
    case 'expert':
    case 'exceptional':
      return 'Cleaning'
    default:
      return 'QuestionSign'
  }
}

type ResultHeaderProps = {
  label?: string
  loading: boolean
  type: ResultType
  value?: string | null
}

export const ResultHeader = ({ label, loading, type, value }: ResultHeaderProps) => {
  if (loading) {
    return (
      <HStack gap="s-16" data-testId={`result-header-${type}-loading`}>
        <AvatarSkeleton />
        <VStack>
          <TextSkeleton variant="emphasis1" width="120px" />
          <TextSkeleton variant="body2" width="120px" />
        </VStack>
      </HStack>
    )
  }
  return (
    <HStack gap="s-16" data-testId={`result-header-${type}`}>
      <Avatar useIcon={getResultIcon(type, value)} />
      <VStack>
        <Text variant="emphasis1" whiteSpace="nowrap">
          {value ? convertOperators(upperFirst(value)) : 'Not assessed'}
        </Text>
        <Text variant="body2" whiteSpace="nowrap">
          {label || upperFirst(type)}
        </Text>
      </VStack>
    </HStack>
  )
}
