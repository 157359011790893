import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsCandidateColumn,
  notificationsTextColumn,
  notificationsInterviewerColumn,
  notificationsInterviewStageColumn,
  notificationsLastActivity,
} from '@src/constants/columns/notifications'
import { notificationsCandidates } from '@src/api/notifications'

const RowNotifications: RowInterface<NotificationsInterface> = {
  cells: [
    {
      ...notificationsCandidateColumn,
      width: 100,
    },
    {
      ...notificationsTextColumn,
      width: 250,
    },
    {
      ...notificationsInterviewerColumn,
      width: 100,
    },
    {
      ...notificationsInterviewStageColumn,
      width: 100,
    },
    {
      ...notificationsLastActivity,
      width: 100,
    },
    {
      ...notificationsActionColumn,
      width: 70,
    },
  ],
}

const Candidates = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsCandidates(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Candidates
