import React from 'react'
import { Box, Placeholder } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'

const maxPlaceholderHeight = 180

export const EmptySkillsView = () => (
  <Box width="100%" maxHeight={maxPlaceholderHeight}>
    <Placeholder>
      <Placeholder.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-business/3D299.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-business/3D299@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-business/3D299@3x.png',
        }}
        size={88}
      />
      <Placeholder.Title>There are no skills matching your query</Placeholder.Title>
    </Placeholder>
  </Box>
)

export const LoadingView = () => (
  <Box width="100%" height={maxPlaceholderHeight}>
    <Loader />
  </Box>
)
