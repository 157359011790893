import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { MeetingsWithPageParams } from '@src/pages/Forms/MeetingsWith/common'
import { useGetCompanionMeetingsDetails } from '@src/api/meetings'
import PageLoading from '@components/PageLoading/PageLoading'
import Upcoming from '@src/pages/Forms/MeetingsWith/Upcoming'
import Past from '@src/pages/Forms/MeetingsWith/Past'

const getTabs = (params: MeetingsWithPageParams) => [
  {
    title: 'Upcoming',
    path: ROUTES.FORMS.MEETINGS_WITH.UPCOMING,
    to: pathToUrl(ROUTES.FORMS.MEETINGS_WITH.UPCOMING, params),
    component: Upcoming,
  },
  {
    title: 'Past',
    path: ROUTES.FORMS.MEETINGS_WITH.PAST,
    to: pathToUrl(ROUTES.FORMS.MEETINGS_WITH.PAST, params),
    component: Past,
  },
]

const MeetingsWith = () => {
  const params = useParams<MeetingsWithPageParams>()
  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.MEETINGS, { id: params.employeeId })

  const { data } = useGetCompanionMeetingsDetails(params.employeeId, params.companionId)

  if (!data) {
    return <PageLoading />
  }

  const tabs = getTabs(params)

  return (
    <PageWrapper>
      <PageHeader
        title={`1:1 ${data.request_employee.full_name} / ${data.relation_employee.full_name}`}
        subtitle={`Meeting with ${data.relation}`}
        backUrl={backUrl}
      />
      <TabBarNavigation tabs={tabs} mb="s-24" />
      <Switch>
        {getTabs(params).map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component data={data} />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default MeetingsWith
