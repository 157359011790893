import { useEffect } from 'react'

const useLoadScript = (src: string, onLoad?: () => void) => {
  useEffect(() => {
    const elem = document.createElement('script')
    elem.src = src
    elem.onload = () => {
      onLoad?.()
    }

    document.body.appendChild(elem)

    return () => {
      elem.remove()
    }
  }, [src])
}

export default useLoadScript
