import { RefObject, useEffect, useState } from 'react'

export const useScrollParent = <T>(scrollRef?: RefObject<T>) => {
  const [scrollParent, setScrollParent] = useState<T | undefined>(undefined)

  useEffect(() => {
    if (scrollRef?.current && !scrollParent) {
      setScrollParent(scrollRef.current)
    }
  }, [scrollRef?.current])

  return scrollParent
}
