import React from 'react'
import {
  ReviewSummaryInterface,
  SkillCardInterface,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { getCardsForView } from '@src/utils/performance'
import { SkillsCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/SkillsCommon'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillsProps } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/Skills'

export const SkillsView = connect(({ selectedField }: SkillsProps) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  const hasManagerValues = !!values.summary?.manager_values
  const managerValuesCards = values.summary?.manager_values?.cards || []
  const skillsCards = hasManagerValues
    ? managerValuesCards
    : getCardsForView(
        (values.summary?.functional_skills?.cards as SkillCardInterface[]) || [],
      )

  return (
    <SkillsCommon
      skillsCards={skillsCards as SummarySkillCardInterface[]}
      selectedField={selectedField}
      hasManagerValues={!!values.summary?.manager_values}
      isViewMode
    />
  )
})
