import React, { useState } from 'react'
import { Button } from '@revolut/ui-kit'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { TabsInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { tabToSchema, validateSync } from '@src/utils/form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import IncompleteFormWarningDialog from '@src/features/Popups/IncompleteFormWarningDialog'
import { navigateReplace } from '@src/actions/RouterActions'

type Props = {
  tabs: TabsInterface<any>[]
}

const NextButton = ({ tabs }: Props) => {
  const location = useLocation()
  const queryParams = useParams<{ employeeId?: string }>()
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const [incompleteWarningOpen, setIncompleteWarningOpen] = useState(false)
  const visibleTabs = tabs

  const currentTabIndex = visibleTabs.findIndex(
    (tab: TabsInterface<ReviewScorecardInterface>) =>
      matchPath(location.pathname, tab.path),
  )

  const currentTab = visibleTabs[currentTabIndex]

  if (currentTabIndex >= visibleTabs.length - 1) {
    return null
  }

  const handleNext = () => {
    values.review_data?.manager_values?.cards?.forEach(card => {
      card.sections.forEach(section => {
        section.justification =
          section?.justification === '' ? null : section.justification
      })
    })
    values.review_data?.culture_values?.cards?.forEach(card => {
      card.sections.forEach(section => {
        section.justification =
          section?.justification === '' ? null : section.justification
      })
    })
    if (currentTab?.invalidTabMessage && validateSync(values, tabToSchema(currentTab))) {
      setIncompleteWarningOpen(true)
      return
    }

    if (currentTabIndex < visibleTabs.length - 1) {
      navigateReplace(
        pathToUrl(visibleTabs[currentTabIndex + 1].path, queryParams) + location.search,
      )
    }
  }

  return (
    <>
      {currentTab?.invalidTabMessage && (
        <IncompleteFormWarningDialog
          open={incompleteWarningOpen}
          onClose={() => setIncompleteWarningOpen(false)}
        >
          {currentTab.invalidTabMessage(values)}
        </IncompleteFormWarningDialog>
      )}

      <Button onClick={handleNext} data-testid="btn-next" width={167} elevated>
        Next
      </Button>
    </>
  )
}

export default connect(NextButton)
