import React, { useEffect, useMemo } from 'react'
import { connect } from 'lape'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName } from '@src/interfaces'
import { TeamFunction, TeamInterface } from '@src/interfaces/teams'
import { ROUTES } from '@src/constants/routes'
import { Cell, InputGroup, Text, Token } from '@revolut/ui-kit'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { CommunicationInputs } from '@src/features/CommunicationGroups/CommunicationInputs'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { FeatureFlags } from '@src/store/auth/types'

const functionNameMap = {
  [TeamFunction.product]: 'Product',
  [TeamFunction.service]: 'Services',
}

const General = () => {
  const { values } = useLapeContext<TeamInterface>()
  const currentUser = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)
  const slackUserGroupEnabled = featureFlags?.includes(FeatureFlags.SlackUserGroupEnabled)
  const { data: roadmapSettings } = useGetRoadmapSettings()

  const { data: jiraProjects = [] } = useGetSelectors<IdAndName<number>>(
    roadmapSettings?.jira_epics_enabled ? selectorKeys.jira_projects : null,
  )

  const jiraProjectsOptions = useMemo(
    () =>
      jiraProjects.map(project => ({
        label: project.name,
        value: { id: project.name },
      })),
    [jiraProjects],
  )

  useEffect(() => {
    if (!values.name && values.team_function && values.department) {
      values.name = `${functionNameMap[values.team_function]} · ${
        values.department.name
      } · `
    }
  }, [values.team_function, values.department])

  useEffect(() => {
    if (!values.team_owner) {
      values.team_owner = { id: currentUser.id } as TeamInterface['team_owner']
    }
  }, [])

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General Information" />
          <InputGroup>
            <LapeNewRadioButtons
              name="team_function"
              options={[
                {
                  value: TeamFunction.product,
                  label: functionNameMap[TeamFunction.product],
                },
                {
                  value: TeamFunction.service,
                  label: functionNameMap[TeamFunction.service],
                },
              ]}
            />
            <LapeRadioSelectInput
              name="department"
              label="Department"
              selector={selectorKeys.department}
            />
            <LapeNewInput label="Team Name" name="name" required />
            <LapeRadioSelectInput
              name="team_owner"
              label="Team owner"
              selector={selectorKeys.employees_for_teams}
            />
          </InputGroup>
          <NewStepperTitle
            title="Mission"
            subtitle="A short summary explaining the goals and the scope of the team."
          />
          <InputGroup>
            <LapeNewTextArea label="Mission" name="mission" required rows={3} />
          </InputGroup>
          {roadmapSettings?.jira_epics_enabled || slackUserGroupEnabled ? (
            <>
              <NewStepperTitle title="Integrations" />
              {roadmapSettings?.jira_epics_enabled && (
                <InputGroup>
                  <Cell flexDirection="column" p="s-12">
                    <Text alignSelf="flex-start" variant="h6">
                      JIRA Roadmaps
                    </Text>
                    <Text
                      alignSelf="flex-start"
                      color={Token.color.greyTone50}
                      variant="caption"
                    >
                      You can add any JIRA project keys which you are a member of and plan
                      to select from when adding roadmaps or creating a goal. Epics from
                      those projects will then be available to you.
                    </Text>
                  </Cell>
                  <LapeNewMultiSelect<{ id: string }>
                    name="jira_projects"
                    message="These are the Jira projects you will be able to select from"
                    onChange={options => {
                      values.jira_projects = options.map(option => option.value.id)
                    }}
                    options={jiraProjectsOptions}
                    placeholder="Jira Projects for Roadmaps"
                    value={
                      values.jira_projects?.map(project => ({
                        label: project,
                        value: { id: project },
                      })) || []
                    }
                  />
                </InputGroup>
              )}
              {slackUserGroupEnabled && (
                <LapeNewSwitch
                  name="settings.enable_slack_user_groups"
                  itemTypeProps={{
                    title: 'Automatically create user groups',
                    description:
                      'User groups will be set up automatically in your Slack workspace for this team',
                  }}
                />
              )}
            </>
          ) : null}
          {values.communication_group && (
            <>
              <NewStepperTitle
                title="Communication"
                subtitle="Communication groups are created automatically for each team, department and function. You can also choose to manually link custom communication groups which are relevant to your team’s work."
              />
              <CommunicationInputs group={values.communication_group} />
            </>
          )}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup previewUrl={ROUTES.FORMS.TEAM.SUMMARY} useValidator />
      </PageActions>
    </>
  )
}

export default connect(General)
