import React, { useState } from 'react'
import {
  ManageGoalEntityDefinition,
  ManageGoalsPayload,
  manageGoals,
} from '@src/api/goals'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrgEntityInterface } from '../OrgEntityProvider/OrgEntityProvider'
import { EntityTypes } from '@src/constants/api'
import { captureException } from '@sentry/react'
import { roundFloat } from '@src/utils/numbers'
import { GoalWeightMode, GoalsInterface } from '@src/interfaces/goals'
import { useSubmitFlowHelpers } from '@src/pages/Forms/GoalForm/common/utils'
import { Flex, Icon, Text, Token } from '@revolut/ui-kit'
import get from 'lodash/get'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const useManageGoalsWeights = ({
  entity,
  contentType,
  tableData,
  cycleId,
  onUpdated,
  cycleCategory,
}: {
  entity: OrgEntityInterface | null
  contentType?: number
  tableData: GoalsInterface[]
  cycleId?: number | string
  onUpdated: () => void
  cycleCategory?: ReviewCycleCategory
}) => {
  const form = useLapeContext<ManageGoalsPayload & GoalsInterface['performance_type']>()
  const nonMandatoryGoals = tableData.filter(dataPoint =>
    dataPoint.performance_type ? dataPoint.performance_type.id !== 'mandatory' : true,
  )
  const defaultWeightMode =
    (entity && 'goal_weight_mode' in entity.data && entity?.data?.goal_weight_mode?.id) ||
    'automatic'
  const [manageMode, setManageMode] = useState(false)
  const [weightMode, setWeightMode] = useState<GoalWeightMode>(defaultWeightMode)
  const { confirm, confirmationDialog, showError } = useSubmitFlowHelpers()

  const autoDistributeWeights = (): GoalsInterface[] => {
    const count = nonMandatoryGoals.length
    const weightPerGoal = parseFloat(Number(100 / count).toFixed(10))

    const { goals } = nonMandatoryGoals.reduce(
      (acc: { goals: GoalsInterface[]; total: number }, current, idx, { length }) => {
        let nextWeight = weightPerGoal
        acc.total += nextWeight
        // there are some instances in the amount of goals where the number of goals / 100 does not equal 100
        // due to JS working with numbers. We may end up with 100.000000000001% or 99.99999999999999%. For those instances we
        // just subtract / add that value from the last goal
        if (idx + 1 === length && acc.total !== 100) {
          if (acc.total < 100) {
            nextWeight += parseFloat(Number(Math.abs(acc.total - 100)).toFixed(10))
          } else {
            nextWeight -= parseFloat(Number(Math.abs(acc.total - 100)).toFixed(10))
          }
        }
        acc.goals.push({ ...current, weight: nextWeight })

        return acc
      },
      { goals: [], total: 0 },
    )
    return goals
  }

  const getManageGoalsEntityDefinition = (): ManageGoalEntityDefinition | null => {
    if (!entity) {
      return null
    }

    if (entity.type === EntityTypes.company || entity.type === EntityTypes.companyV2) {
      return {
        is_company: true,
      }
    }
    if (contentType) {
      return { content_type: { id: contentType }, object_id: entity.data.id }
    }
    return null
  }

  const handleSubmit = async (autodistribute?: boolean) => {
    const values = form.values
    const entityDefinition = getManageGoalsEntityDefinition()

    if (!entityDefinition) {
      captureException('Failed to determine entity definition for manage goals action')
      throw new Error('Please reload page and try again.')
    }

    const autodistributeWeights = autoDistributeWeights()

    const updatedForm = {
      ...form.values,
      ...entityDefinition,
      goal_weight_mode: autodistribute
        ? { id: 'automatic' as const }
        : { id: 'manual' as const },
      goals: (autodistribute ? autodistributeWeights : values.goals)
        ?.filter(goal => goal.performance_type?.id !== 'mandatory')
        .map(({ id, weight }) => ({
          id,
          weight,
        })),
      ...(cycleCategory === ReviewCycleCategory.Probation
        ? { employee_cycle: { id: cycleId } }
        : { review_cycle: { id: String(cycleId) } }),
    }

    try {
      setApiError(null)
      await manageGoals(updatedForm)

      onUpdated()

      if (autodistribute) {
        setWeightMode('automatic')
      }

      setManageMode(false)

      return updatedForm
    } catch (err) {
      const expectedError = get(err, 'response.data.detail.0')
      if (expectedError) {
        setApiError(expectedError)
      } else {
        showError('Failed to update weights.', 'Please try again.')
      }

      throw err
    }
  }

  const totalWeight = form.values.goals
    ?.filter(goal => goal.performance_type?.id !== 'mandatory')
    .map(g => g.weight)
    .reduce(
      (a, b) =>
        // need to parse float here and go with toFixed due to JS adding more numbers on float eg 33.3 + 33.4 = 66.69999999...9 instead of 66.7
        parseFloat(Number(a + b).toFixed(10)),
      0.0,
    )

  const [apiError, setApiError] = useState<string | null>(null)

  const validationMessage =
    totalWeight !== 100 ? (
      <Flex alignItems="center" gap="s-8">
        <Icon name="ExclamationMarkOutline" color={Token.color.orange} size={16} />
        <Text color={Token.color.greyTone50} variant="caption">
          Custom weights defined.{' '}
          {apiError ||
            `The sum of all weights should be 100%, currently ${roundFloat(
              totalWeight,
              2,
            )}%`}
        </Text>
      </Flex>
    ) : null

  return {
    handleSubmit,
    weightMode,
    manageMode,
    toggleManageMode: () => setManageMode(current => !current),
    confirmationDialog,
    autoDistributeWeights: async () => {
      const confirmed = await confirm({
        variant: 'compact',
        label: 'Confirm auto distribution of weights',
        body: 'This will equally distribute the goals weights and overwrite the current values.',
        yesMessage: 'Continue',
        noMessage: 'Cancel',
      })

      if (confirmed.status === 'confirmed') {
        handleSubmit(true)
      }
    },
    validationMessage,
  }
}
