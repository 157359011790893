import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import isArray from 'lodash/isArray'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import NewMultiSelect, {
  NewMultiSelectProps,
} from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { getOptionLabel } from '@components/Inputs/hooks/useFetchOptions'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { Box, Item, Text, Token } from '@revolut/ui-kit'

export interface LapeNewMultiSelectProps<T> extends NewMultiSelectProps<T> {
  name: string
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
  itemTypeProps?: {
    title?: string
    description?: string | React.ReactNode
  }
  onAfterChange?: (option: RadioSelectOption<T>[] | null) => void
}

const LapeNewMultiSelect = <T extends { id: number | string }>({
  name,
  hideOptional,
  itemTypeProps,
  onAfterChange = () => {},
  ...props
}: LapeNewMultiSelectProps<T>) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const { value, onChange, hidden, apiError } = lapeProps

  if (hidden) {
    return null
  }

  const renderMultiSelect = () => (
    <NewMultiSelect
      onChange={selectedOptions => {
        onChange(selectedOptions.map(option => option.value))
        onAfterChange(selectedOptions)
      }}
      value={
        // don't call if there is props.value to avoid showing the warning in getOptionLabel
        isArray(value) && !props.value
          ? value.map((optionValue: T) => ({
              value: optionValue,
              label: getOptionLabel(optionValue),
            }))
          : []
      }
      name={name}
      {...props}
      {...getCommonInputProps(
        { ...props, hideOptional, name },
        lapeProps,
        !!formValidator?.validated,
      )}
    />
  )

  if (itemTypeProps) {
    return (
      <Item use="label" data-name={name} onClick={e => e.preventDefault()}>
        <Item.Content>
          <Item.Title>{itemTypeProps.title}</Item.Title>
          <Item.Description>
            {itemTypeProps.description}
            {apiError ? (
              <>
                <br />
                <Text color={Token.color.danger}>{apiError}</Text>
              </>
            ) : null}
          </Item.Description>
          <Box mt="s-16">{renderMultiSelect()}</Box>
        </Item.Content>
      </Item>
    )
  }

  return renderMultiSelect()
}

export default connect(LapeNewMultiSelect)
