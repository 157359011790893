import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { Cell, Text, Link } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'
import { PROMOTION_ELIGIBILITY } from '@src/constants/externalLinks'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const Help = ({ isOpen, onClose }: Props) => {
  return (
    <SideBar title="Nomination guidelines" isOpen={isOpen} onClose={onClose}>
      <Grid flow="row" gap={16}>
        <Cell display="block">
          <Text variant="primary" use="p">
            1. Is the employee eligible?
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            You can find all the criteria for promotion eligibility at each seniority by
            reviewing our{' '}
            <Link target="_blank" href={PROMOTION_ELIGIBILITY}>
              philosophy page
            </Link>
            .
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            2. Who can nominate?
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            Promotions nominations should be raised by either the Line Manager or
            Functional Manager of an employee.
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            3. Whats next?
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            Nominations will only be considered if they are supported by the Head of
            Department and Head of Function during approval.
          </Text>
        </Cell>
        <Cell display="block">
          <Text variant="primary" use="p">
            4. Employee does not meet eligibility criteria, but I still want to nominate
            them for promotion
          </Text>
          <Text use="p" mt="s-8" variant="caption">
            Employees who do not meet the criteria will be flagged, with a reduced chance
            of being approved at a later stage. <br />
            Strong rationale for why they deserve promotion now, will be asked for. If you
            still think they deserve promotion, kindly justify your reasons.
          </Text>
        </Cell>
      </Grid>
    </SideBar>
  )
}

export default Help
