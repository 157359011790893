import React from 'react'
import { Box, Flex, InputGroup, Widget } from '@revolut/ui-kit'
import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import {
  ApplicationFormEnumQuestionIds,
  ApplicationFormEnumQuestionOptions,
  ApplicationFormQuestionType,
} from '@src/interfaces/applicationForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'
import produce from 'immer'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import ApplicationFormQuestionContent from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormQuestionContent'
import { OptionInterface } from '@src/interfaces/selectors'
import ScorecardSectionControls from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { connect } from 'lape'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { HStack } from '@revolut/ui-kit'

type Props = {
  sectionIdx: number
  questionIdx: number
}

const ApplicationFormEditQuestion = ({ sectionIdx, questionIdx }: Props) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const question = values.application_form_sections![sectionIdx]?.questions[questionIdx]
  const type = question.question_type.id

  const onChangeQuestions = (
    options: InterviewScorecardSectionQuestionType<number>[],
  ) => {
    // to be able to track changes
    values.application_form_sections = produce(
      values.application_form_sections!,
      draft => {
        draft[sectionIdx].questions[questionIdx].options = options
      },
    )
  }

  const onChangeType = (option: OptionInterface) => {
    if (
      option.id === ApplicationFormEnumQuestionIds.Checkbox ||
      option.id === ApplicationFormEnumQuestionIds.Option
    ) {
      question.options = []
    } else {
      question.options = undefined
    }
    question.placeholder_text =
      option.id === ApplicationFormEnumQuestionIds.Dropdown ? 'Select one' : undefined
    question.question_type = option as ApplicationFormQuestionType
  }

  const handleDelete = () => {
    values.application_form_sections[sectionIdx].questions =
      values.application_form_sections![sectionIdx].questions.filter(
        (_, i) => i !== questionIdx,
      )
  }

  const handleCopy = () => {
    values.application_form_sections[sectionIdx].questions.splice(questionIdx + 1, 0, {
      ...question,
      options: question.options?.map(option => ({
        ...option,
        id: undefined,
      })),
      id: undefined,
    })
  }

  return (
    <Widget p="s-16" mt="s-16" data-testid={`question_${sectionIdx}_${questionIdx}`}>
      <Flex justifyContent="space-between" alignItems="center" mb="s-16">
        <Flex>
          <HStack space="s-8">
            <DropdownButton
              options={ApplicationFormEnumQuestionOptions}
              onSelect={onChangeType}
              data-testid="type-question-btn"
            >
              Type: {type || 'none'}
            </DropdownButton>
            <SwitchButton
              checked={question.optional}
              onClick={() => {
                question.optional = !question.optional
              }}
              useMoreBar={false}
            >
              Optional
            </SwitchButton>
          </HStack>
        </Flex>

        <ScorecardSectionControls
          id={questionIdx}
          onDelete={handleDelete}
          onCopy={handleCopy}
          sections={values.application_form_sections[sectionIdx].questions}
        />
      </Flex>

      <Box mb="s-16">
        <InputGroup>
          <LapeNewInput
            label="Question"
            name={`application_form_sections.${sectionIdx}.questions.${questionIdx}.title`}
            required
          />
          <LapeNewInput
            label="Subtitle"
            name={`application_form_sections.${sectionIdx}.questions.${questionIdx}.subtitle`}
          />
          {!!values.locations?.length && (
            <LapeNewMultiSelect
              placeholder="Location setting"
              name={`application_form_sections.${sectionIdx}.questions.${questionIdx}.locations`}
              options={values.locations?.map(item => ({
                label: item.name,
                value: {
                  id: item.id,
                  name: item.name,
                },
              }))}
            />
          )}
        </InputGroup>
      </Box>

      <ApplicationFormQuestionContent
        options={question?.options}
        placeholder={question?.placeholder_text}
        type={type}
        onChangePlaceholder={val => {
          values.application_form_sections[sectionIdx].questions[
            questionIdx
          ].placeholder_text = val
        }}
        onChangeQuestions={onChangeQuestions}
        optionsKey={`application_form_sections.${sectionIdx}.questions.${questionIdx}.options`}
      />
    </Widget>
  )
}

export default connect(ApplicationFormEditQuestion)
