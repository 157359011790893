import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import Letter, { Props } from './General'

export default connect((props: Props) => (
  <Form>
    <Letter {...props} />
  </Form>
))
