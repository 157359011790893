import { IconName } from '@revolut/ui-kit'

export type VisualisationType =
  | 'line'
  | 'bar'
  | 'combo'
  | 'row'
  | 'pie'
  | 'counter'
  | 'stack'

export const visualisationTypeIconMap: {
  [key in VisualisationType]: IconName
} = {
  line: '16/Timeline',
  bar: 'BarChart',
  combo: 'Invest',
  row: 'Paragraph',
  pie: 'PieChart',
  counter: 'Target',
  stack: 'Materials',
}

export const visualisationOptions: {
  type: VisualisationType
  name: string
  icon: IconName
}[] = [
  {
    type: 'line',
    name: 'Line',
    icon: visualisationTypeIconMap.line,
  },
  {
    type: 'bar',
    name: 'Bar',
    icon: visualisationTypeIconMap.bar,
  },
  {
    type: 'combo',
    name: 'Combo',
    icon: visualisationTypeIconMap.combo,
  },
  {
    type: 'row',
    name: 'Row',
    icon: visualisationTypeIconMap.row,
  },
  {
    type: 'stack',
    name: 'Stacked',
    icon: visualisationTypeIconMap.stack,
  },
  {
    type: 'pie',
    name: 'Pie',
    icon: visualisationTypeIconMap.pie,
  },
  {
    type: 'counter',
    name: 'Value',
    icon: visualisationTypeIconMap.counter,
  },
]
