import { MoreBar, Tooltip, useTooltip } from '@revolut/ui-kit'
import { AnytimeFeedbackSidebarButton } from '@src/components/AnytimeFeedback/AnytimeFeedbackSidebarButton'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import React from 'react'

type Handler = () => void

interface Props {
  onSummaryRequested?: Handler
  onFeedbackRequested?: { handler: Handler; feedbackCount?: number }
  onPreviousRatingsRequested?: { handler: Handler; checked: boolean }
  onSkipRequested?: { handler: Handler; checked: boolean }
  onHelpRequested?: Handler
  onReviewsRequested?: Handler
}

export const EmployeePerformanceActionsBar = ({
  onSummaryRequested,
  onHelpRequested,
  onFeedbackRequested,
  onPreviousRatingsRequested,
  onSkipRequested,
  onReviewsRequested,
}: Props) => {
  const tooltip = useTooltip()

  return (
    <>
      <MoreBar>
        {onSummaryRequested ? (
          <MoreBar.Action onClick={onSummaryRequested} useIcon="16/ListBullet">
            Show summary
          </MoreBar.Action>
        ) : null}
        {onSkipRequested && (
          <SwitchButton
            onClick={onSkipRequested.handler}
            checked={onSkipRequested.checked}
          >
            Skip section
          </SwitchButton>
        )}
        {onReviewsRequested ? (
          <MoreBar.Action
            onClick={onReviewsRequested}
            {...tooltip.getAnchorProps()}
            useIcon="CheckSuccess"
          >
            View completed reviews
            <Tooltip {...tooltip.getTargetProps()} maxWidth="250px">
              See a summary of scorecards already submitted for this employee
            </Tooltip>
          </MoreBar.Action>
        ) : null}
        {onFeedbackRequested && onFeedbackRequested.feedbackCount ? (
          <AnytimeFeedbackSidebarButton
            title={'Show feedback'}
            onClick={onFeedbackRequested.handler}
            feedbackCount={onFeedbackRequested.feedbackCount}
          />
        ) : null}
        {onPreviousRatingsRequested ? (
          <SwitchButton
            onClick={onPreviousRatingsRequested.handler}
            checked={onPreviousRatingsRequested.checked}
          >
            Previous ratings
          </SwitchButton>
        ) : null}
        {onHelpRequested ? (
          <HideIfCommercial>
            <MoreBar.Action onClick={onHelpRequested} useIcon="InfoOutline">
              Help & Info
            </MoreBar.Action>
          </HideIfCommercial>
        ) : null}
      </MoreBar>
    </>
  )
}
