import React from 'react'
import { Box } from '@revolut/ui-kit'
import NewRadioButtons from '@src/components/Inputs/NewRadioButtons/NewRadioButtons'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'

type GeneralQuestionProps = {
  disabled?: boolean
  error?: boolean | null
  name: string
  value?: boolean | null
  onChange: (value: boolean) => void
}

const GeneralQuestion = ({
  disabled,
  error,
  name,
  value,
  onChange,
}: GeneralQuestionProps) => {
  const { data } = useGetSelectors(selectorKeys.yes_no_value_options)
  const options = data?.map(o => ({ label: o.name, value: o.id === 'true' })) ?? []
  const checkedValue = options.find(o => {
    if (value || value === false) {
      return o.value === value
    }
    return false
  })
  const props = {
    'data-name': name,
    'aria-invalid': !!error,
    hasError: !!error,
    message: error,
    disabled,
  }
  return (
    <NewRadioButtons
      options={options}
      value={checkedValue}
      renderRadio={r => <Box py="s-8">{r}</Box>}
      onChange={o => {
        const checked = o.value
        onChange(checked)
      }}
      {...props}
    />
  )
}

export default GeneralQuestion
