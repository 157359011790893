import React from 'react'
import { Box, Button, H5, Popup, VStack, Link as UIKitLink } from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'

import { onCopyLinkToClipboard } from '../../common'

type Props = {
  open: boolean
  onClose: () => void
  createdTicket: string | undefined
  setCreatedTicket: (createdTicket: string | undefined) => void
}
export const CreateTicketPopup = ({
  open,
  onClose,
  createdTicket,
  setCreatedTicket,
}: Props) => {
  return (
    <Popup open={open} variant="bottom-sheet" onClose={onClose}>
      <VStack space="s-16">
        <H5>Your ticket was successfully created</H5>
        <Box style={{ overflowWrap: 'break-word' }}>
          <UIKitLink href={createdTicket} target="_blank">
            {createdTicket}
          </UIKitLink>
        </Box>
      </VStack>
      <Popup.Actions horizontal>
        <Button
          onClick={() => {
            setCreatedTicket(undefined)
          }}
          variant="secondary"
        >
          Close
        </Button>
        <Button onClick={() => onCopyLinkToClipboard(createdTicket)} useIcon={Copy}>
          Copy link
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
