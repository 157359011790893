import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { useParams } from 'react-router'
import { pipCycleSettingsRequests } from '@src/api/pip'
import { Settings } from '@src/pages/Forms/Overview/Settings/General'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'

const PipSettings = () => {
  const params = useParams<{ employeeId?: string; cycleId?: string }>()
  const cycleIdValue = params?.cycleId
    ? getReviewCycleIdWithoutPrefix(params.cycleId)
    : undefined

  return (
    <Form
      api={pipCycleSettingsRequests}
      forceParams={{ employeeId: params?.employeeId, id: cycleIdValue }}
      disableDataCleanup
    >
      <Settings type={ReviewCycleCategory.PIP} />
    </Form>
  )
}

export default connect(PipSettings)
