import React from 'react'
import { CompensationBandInterface } from '@src/interfaces/benchmarks'
import { formatMoney } from '@src/utils/format'
import { isCompensationAvailable } from '@components/LocationCompensationBand/utils'

type Props = { band: CompensationBandInterface }

export const getPostingPeriodLabel = (postingCompensationPeriod?: string) => {
  switch (postingCompensationPeriod) {
    case 'yearly':
      return 'annual'
    default:
      return postingCompensationPeriod ?? ''
  }
}

const CompensationLocationItem = ({ band }: Props) => {
  const period = getPostingPeriodLabel(band.location.posting_compensation_period)
  if (isCompensationAvailable(band)) {
    return (
      <li>
        {band.location.location_name}:{' '}
        {formatMoney(band.lower_band, band.currency!.iso_code)}-{' '}
        {formatMoney(band.upper_band, band.currency!.iso_code)} gross {period}
      </li>
    )
  }

  return <li>{band.location.location_name}</li>
}

export default CompensationLocationItem
