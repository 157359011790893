import React from 'react'
import FilterSelect, {
  FilterInputProps,
  FilterSelectType,
} from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { selectorKeys } from '@src/constants/api'

interface FilterBooleanProps extends Omit<FilterInputProps, 'selector'> {}

const FilterBoolean = (props: FilterBooleanProps) => {
  return (
    <FilterSelect
      {...props}
      selector={selectorKeys.yes_no_uppercase_value_options}
      type={FilterSelectType.SingleSelect}
      searchable={false}
    />
  )
}

export default FilterBoolean
