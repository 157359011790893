import { apiWithoutHandling } from '@src/api/index'
import { FileInterface } from '@src/interfaces/files'
import { useQueryClient, useMutation } from 'react-query'
import { AxiosResponse, AxiosError } from 'axios'
import { useDelete } from '@src/utils/reactQuery'

const uploadEntityAvatar = (id: number | string, file: File, apiUrl?: string) => {
  const fileFormData = new FormData()
  fileFormData.append('file', file, file.name)
  fileFormData.append('category', 'avatar')

  return apiWithoutHandling.post<FileInterface>(`${apiUrl}/${id}/avatar`, fileFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const useUpdateEntityAvatar = (id: number | string, apiUrl?: string) => {
  const queryClient = useQueryClient()

  return useMutation<AxiosResponse<FileInterface>, AxiosError, File>(
    data => uploadEntityAvatar(id, data, apiUrl),
    {
      onSuccess: response => {
        if (response.data) {
          queryClient.setQueryData([`${apiUrl}/${id}`, 'v1', null], oldData => ({
            ...oldData!,
            avatar: response.data.url,
          }))
        }
      },
    },
  )
}

export const useDeleteEntityAvatar = (id: number | string, apiUrl?: string) =>
  useDelete(`${apiUrl}/${id}/avatar`)
