import React, { useState } from 'react'
import { ActionButton, Button, IconName, StatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { connect } from 'lape'
import LapeDescriptionDialog from '@src/features/Popups/LapeDescriptionDialog'
import { successNotification } from '@src/actions/NotificationActions'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import omit from 'lodash/omit'

export interface AsyncButtonProps {
  hide?: boolean
  successMessage?: string
  afterSubmitUrl?: string
  submit?: () => Promise<any>
  notification?: {
    path?: string
    updateMsg: string
  }
  dialog?: {
    title: string
    placeholder: string
    fieldName: string
  }
  children: React.ReactNode
  variant?: 'negative'
  useIcon?: IconName
  large?: boolean
}

const AsyncButton = ({
  afterSubmitUrl,
  notification,
  hide,
  successMessage,
  submit,
  children,
  dialog,
  large,
  ...props
}: AsyncButtonProps) => {
  const [pending, setPending] = useState(false)
  const params = useParams()
  const [popupOpen, setPopupOpen] = useState(false)
  const [openedDialog, setOpenedDialog] = useState(false)

  const onSubmit = async () => {
    setPending(true)
    try {
      if (submit) {
        const result = await submit()

        if (result) {
          if (successMessage) {
            setPopupOpen(true)
          } else {
            navigateAfterSubmit()
          }

          if (notification) {
            successNotification(
              notification.updateMsg,
              notification.path
                ? pathToUrl(notification.path, {
                    ...params,
                    id: result?.id || result?.data?.id,
                  })
                : undefined,
            )
          }
        }
      }
    } finally {
      setPending(false)
    }

    return null
  }

  const handleClick = () => {
    if (dialog) {
      setOpenedDialog(true)
    } else {
      onSubmit()
    }
  }

  const navigateAfterSubmit = () => {
    if (afterSubmitUrl) {
      navigateTo(afterSubmitUrl)
    }
  }

  if (hide) {
    return null
  }

  return (
    <>
      {dialog && openedDialog && (
        <LapeDescriptionDialog
          loading={pending}
          onSubmit={onSubmit}
          onClose={() => setOpenedDialog(false)}
          data={dialog}
        />
      )}
      {large ? (
        <Button
          elevated
          pending={pending}
          onClick={handleClick}
          {...omit(props, 'variant')}
        >
          {children}
        </Button>
      ) : (
        <ActionButton pending={pending} onClick={handleClick} {...props}>
          {children}
        </ActionButton>
      )}
      <StatusPopup
        variant="success"
        open={popupOpen}
        onClose={() => {
          setPopupOpen(false)
          navigateAfterSubmit()
        }}
      >
        <StatusPopup.Title>{successMessage}</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default connect(AsyncButton)
