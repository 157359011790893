import React from 'react'
import { PerfReviewRequestFeedbackInterface } from '@src/interfaces/performance'
import { Box, Color, Dots, Flex, Text } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import Stat from '@components/Stat/Stat'

type Props = {
  requests?: PerfReviewRequestFeedbackInterface[]
  fetching: boolean
}

const UpwardsReviews = ({ requests, fetching }: Props) => {
  if (!requests?.length && !fetching) {
    return null
  }

  return (
    <Box mt="s-32">
      <Text color={Color.GREY_TONE_50}>Reviews</Text>

      {fetching ? (
        <Dots color={Color.GREY_TONE_50} />
      ) : (
        <Flex justifyContent="flex-start" mt="s-16">
          <Stat label="Requests sent" val={requests?.length} mr="s-32" />
          <Stat
            label="Review completed"
            val={requests?.filter(r => r.status === Statuses.completed).length}
          />
        </Flex>
      )}
    </Box>
  )
}

export default UpwardsReviews
