import React from 'react'
import upperFirst from 'lodash/upperFirst'
import { Flex, Link, MoreBar, Text, VStack, Widget } from '@revolut/ui-kit'
import { Document } from '@revolut/icons'

import { useGetSkillDescription } from '@src/api/skills'
import { ROUTES } from '@src/constants/routes'
import {
  PerformanceReviewTypes,
  SkillsReviewsInterface,
} from '@src/interfaces/performance'
import SideBar from '@components/SideBar/SideBar'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import SidebarSkeleton from '../Summary/SidebarSkeleton'
import { useFetchPerformanceSkillSummary } from './hooks'

interface Props {
  employeeId: number
  isOpen: boolean
  onClose?: () => void
  sidebarDetails?: SkillsReviewsInterface
}

const SummarySidebar = ({ employeeId, isOpen, onClose, sidebarDetails }: Props) => {
  const { isLoading, cards } = useFetchPerformanceSkillSummary(
    sidebarDetails?.cycle_category,
    sidebarDetails?.cycle_id,
    employeeId,
    sidebarDetails?.skill?.id,
  )
  const { data: skillDescription } = useGetSkillDescription(sidebarDetails?.skill?.id)

  if (!isOpen) {
    return null
  }

  return (
    <SideBar
      isOpen={isOpen}
      title={upperFirst(sidebarDetails?.skill?.name?.trim())}
      onClose={onClose}
      variant="wide"
    >
      <SettingsButtons mb="s-16">
        <MoreBar.Action
          to={pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, {
            id: sidebarDetails?.skill?.id,
          })}
          use={InternalLink}
          useIcon={Document}
        >
          View Skill
        </MoreBar.Action>
      </SettingsButtons>

      {sidebarDetails?.cycle_name && (
        <Text variant="primary" mb="s-16">
          {sidebarDetails.cycle_name}
        </Text>
      )}

      {!!(skillDescription?.description || skillDescription?.playbook_url) && (
        <Widget p="s-16">
          <VStack space="s-12">
            {!!skillDescription?.description && (
              <>
                <Text variant="h6" use="p">
                  Skill description
                </Text>
                <Text>{skillDescription.description}</Text>
              </>
            )}
            {!!skillDescription?.playbook_url && (
              <Flex justifyContent="space-between">
                <Text variant="h6" use="p">
                  Playbook link
                </Text>
                <Link
                  href={skillDescription.playbook_url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Open
                </Link>
              </Flex>
            )}
          </VStack>
        </Widget>
      )}

      {isLoading ? (
        <SidebarSkeleton />
      ) : (
        <ScorecardGeneral
          category={sidebarDetails?.cycle_category!}
          cards={cards}
          hideJustification
          title="Skills"
          type={PerformanceReviewTypes.skills}
          viewMode
          width="100%"
        />
      )}
    </SideBar>
  )
}

export default SummarySidebar
