import React from 'react'
import { VStack } from '@revolut/ui-kit'
import RolesTable from '@src/pages/Organisation/OrganisationSubTabs/Roles/RolesTable'
import Message from '../components/Message'

const RolesRoles = () => {
  return (
    <VStack gap="s-16">
      <Message
        title="We have created roles just for you."
        description="An employee role defines the responsibilities, duties, and expectations associated with an employee’s position"
      />
      <RolesTable />
    </VStack>
  )
}

export default RolesRoles
