import { AppIcon, AppIconSize, Text } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { HubAppType } from '@src/interfaces/hub'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { LockClosed } from '@revolut/icons'
import { getIconProps } from '@src/pages/Hub/utils'
import { getAppOrTab } from '@src/features/HubAppIcon/utils'

interface HubAppIconProps {
  app: HubAppType
  size: AppIconSize
  onClick?: () => void
  disabled?: boolean
}

const HubAppIcon = ({ app, size, onClick, disabled }: HubAppIconProps) => {
  const permissions = useSelector(selectPermissions)

  const item = getAppOrTab(permissions, app)
  const enabled = !disabled && !!item

  return (
    <AppIcon
      // @ts-expect-error object works fine here, but UI kit expects string
      to={item ? getLocationDescriptor(pathToUrl(item.url)) : ''}
      use={NavLink}
      {...(app?.icon
        ? { useIcon: app.icon, bg: app.bg, size }
        : getIconProps(app.image, app.bg, size))}
      key={app.id}
      disabled={!enabled}
      data-testid={`app-${app.id}`}
      onClick={onClick}
    >
      {!enabled && <AppIcon.Badge bg="grey-tone-50" useIcon={LockClosed} />}
      {app.title && (
        <AppIcon.Title>
          <Text whiteSpace="pre-wrap">{app.title}</Text>
        </AppIcon.Title>
      )}
    </AppIcon>
  )
}

export default HubAppIcon
