import React from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useParams } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetJobPostingSettings } from '@src/api/settings'

export const AutomationAction = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const params = useParams()
  const permissions = useSelector(selectPermissions)
  const hasPermission = permissions.includes(
    PermissionTypes.ViewApplicationautomationrule,
  )
  const canView =
    !!values?.id && jobPostingSettings?.enable_automation_rules && hasPermission
  if (!canView) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="AutoExchange"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.JOB_POSTING.AUTOMATION, params)}
    >
      Automation workflow
    </MoreBar.Action>
  )
}
