import React from 'react'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import { ExclamationMark } from '@revolut/icons'

import Tooltip from '@components/Tooltip/Tooltip'
import { optionalField } from '../common'

export const WarnIfEmptyValue = ({ value }: { value: string | string[] | undefined }) => {
  const isFalsy = value === '-' || !value

  const tooltipWarning = (
    <Tooltip
      text="This information is missing. Please update it now to avoid any issues."
      placement="right"
    >
      <ExclamationMark color="orange" size={16} data-testid="empty-warning" />
    </Tooltip>
  )

  if (isFalsy || isEmpty(value)) {
    return tooltipWarning
  }
  if (isArray(value)) {
    return (
      <>
        {value.map(val => (
          <span key={val}>{optionalField(val, <br />)}</span>
        ))}
      </>
    )
  }

  return <>{value}</>
}
