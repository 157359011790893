import React from 'react'
import { BottomSheet, Button, InputGroup } from '@revolut/ui-kit'
import { mergeEntities } from '@src/api/updateOrganisationStructure'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import {
  MergeEntitiesPayloadInterface,
  MergeEntitiesResponseInterface,
} from '@src/interfaces/updateOrganisationStructure'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'

interface MergeRolesProps {
  unitsToMerge: { id: number }[]
  onSuccess: (data: MergeEntitiesResponseInterface) => void
}

export const MergeEntities = ({ unitsToMerge, onSuccess }: MergeRolesProps) => {
  return (
    <LapeForm<MergeEntitiesPayloadInterface>
      onSubmit={form =>
        mergeEntities({ ...form.values, entities: unitsToMerge }).then(response => {
          onSuccess(response.data)
          return form.values
        })
      }
    >
      <FormValidatorProvider>
        <MergeEntitiesForm />
      </FormValidatorProvider>
    </LapeForm>
  )
}

const MergeEntitiesForm = () => {
  const { isSubmitting, submit } = useLapeContext<MergeEntitiesPayloadInterface>()
  const { validate } = useSafeFormValidator()

  return (
    <>
      <InputGroup>
        <LapeNewInput label="New entity name" name="name" required />
        <LapeRadioSelectInput
          label="Registration country"
          name="registration_country"
          selector={selectorKeys.countries}
        />
        <LapeNewInput label="Registration number" name="registration_number" required />
        <LapeNewTextArea label="Description" name="description" rows={4} />
      </InputGroup>
      <BottomSheet.Actions>
        <Button elevated onClick={validate(() => submit())} pending={isSubmitting}>
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
