import React from 'react'
import SupportToolGradeCalibration from '@src/pages/Performance/SupportTool/GradeCalibration'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'

export const GradeCalibration = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Performance summary"
        backUrl={ROUTES.PERFORMANCE.REVIEWS.GENERAL}
      />
      <PageBody maxWidth="100%">
        <SupportToolGradeCalibration />
      </PageBody>
    </PageWrapper>
  )
}
