import React from 'react'
import { InputGroup, Widget } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'

import { timeOffCategoriesRequests } from '@src/api/timeOff'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const TimeOffCategoriesForm = () => {
  const params = useParams<{ id?: string }>()

  return (
    <PageWrapper>
      <PageHeader
        title="Time off category"
        backUrl={ROUTES.SETTINGS.TIME_OFF.CATEGORIES}
      />

      <PageBody>
        <SettingsButtons mb="s-16">
          <DeleteButton
            backUrl={ROUTES.SETTINGS.TIME_OFF.CATEGORIES}
            globalPermissions={[PermissionTypes.DeleteTimeOffPolicyCategory]}
            deleteApi={timeOffCategoriesRequests.delete!}
            title="time off category"
          />
        </SettingsButtons>

        <InputGroup>
          <LapeNewInput label="Name" name="name" required />
          <LapeNewInput label="Description" name="description" />

          <Widget>
            <LapeNewSwitch
              itemTypeProps={{
                description:
                  'If enabled, will allow policies of this category to be booked on non-working days',
                title: 'Allow booking on non-working days',
                transparent: true,
              }}
              name="timeoff_includes_all_days"
            />
          </Widget>
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={data => {
            if (!params.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.TIME_OFF_CATEGORIES.GENERAL, { id: data.id }),
              )
            }
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export const TimeOffCategories = connect(() => (
  <Form api={timeOffCategoriesRequests}>
    <TimeOffCategoriesForm />
  </Form>
))
