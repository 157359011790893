import React from 'react'
import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useInitialFilters } from '@src/features/Goals/GoalsTab'
import { GoalsTableFormWidget } from '@src/features/Goals/GoalsTableWidget'

export const GoalsTable = () => {
  const { data: currentCycle, isLoading: cycleLoading } = useGetReviewCycleByOffset(0)
  const initialFilters = useInitialFilters(currentCycle)
  return !cycleLoading && initialFilters ? (
    <GoalsTableFormWidget initialFilters={initialFilters} />
  ) : (
    <PageLoading />
  )
}
