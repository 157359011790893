import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Spacer } from '@revolut/ui-kit'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'

export const LifecycleCompliance = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeLifecycleSettings)

  return (
    <>
      <SectionTitle
        title="Does your company require to have employee’s background checked?"
        subtitle="If enabled we provide a process-step in the onboarding for your HR-managers to request related documents or work with your provider."
      />
      <LapeBooleanRadioSwitch
        name="enable_screening_tab"
        variant="horizontal"
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to add a Screening-Tab to the Onboarding panel"
        noDescription="I will not need to have a Screening-Tab in the Onboarding panel"
        disabled={disableEdit}
      />
      <Spacer height="s-24" />
      <SectionTitle
        title="Does your company require to have a “right to work” check with your employees?"
        subtitle="If enabled we provide a process-step in the onboarding for your HR-managers to request related documents or work with your provider."
      />
      <LapeBooleanRadioSwitch
        name="enable_right_to_work_tab"
        variant="horizontal"
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to add a Right-to-Work-Tab to the Onboarding panel"
        noDescription="I will not need to have a Right-to-Work-Tab in the Onboarding panel"
        disabled={disableEdit}
      />
    </>
  )
}
