import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { requisitionsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import RequisitionsIntro from '@src/pages/OnboardingChecklist/Requisitions/RequisitionsIntro'
import { requisitionSettings } from '@src/api/settings'
import RequisitionsApprovals from '@src/pages/OnboardingChecklist/Requisitions/RequisitionsApprovals'
import RequisitionsSettings from '@src/pages/OnboardingChecklist/Requisitions/RequisitionsSettings'
import { RequisitionsBulkUpload } from './RequisitionsBulkUpload'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.INTRO,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: RequisitionsIntro,
  },
  {
    title: 'Requisitions approvals',
    path: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.APPROVALS,
    url: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.APPROVALS,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: RequisitionsApprovals,
    form: {
      api: requisitionSettings,
    },
  },
  {
    title: 'Requisitions settings',
    path: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.SETTINGS,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: RequisitionsSettings,
    form: {
      api: requisitionSettings,
    },
  },
  {
    title: 'Upload requisitions',
    path: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.REQUISITIONS.UPLOAD.ANY,
    canView: [PermissionTypes.ViewRequisitionPreferences],
    component: RequisitionsBulkUpload,
    hideActions: true,
    hidePageBody: true,
  },
]

const Requisitions = () => {
  return <OnboardingChecklistContent config={requisitionsConfig} routes={routes} />
}

export default Requisitions
