import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import EmailThread from '@src/features/EmailThread/EmailThread'
import { useGetCandidateEmailThread } from '@src/api/hiringProcess'
import { useParams } from 'react-router-dom'
import { Flex, MoreBar } from '@revolut/ui-kit'
import CancelEmail from '@src/pages/Forms/SendEmail/View/CancelEmail'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const View = () => {
  const params =
    useParams<{ candidateId?: string; threadId?: string; emailId?: string }>()

  const { data: thread } = useGetCandidateEmailThread(params.candidateId, params.threadId)

  if (!thread || !params.candidateId) {
    return null
  }

  return (
    <PageBody>
      {thread.candidate_emails[0].status === 'scheduled' && (
        <Flex mb="s-16">
          <MoreBar>
            <CancelEmail candidateId={+params.candidateId} thread={thread} />
          </MoreBar>
        </Flex>
      )}
      <EmailThread
        replyUrl={pathToUrl(ROUTES.FORMS.SEND_EMAIL.CANDIDATE, {
          threadId: params.threadId,
          candidateId: params.candidateId,
          emailId: params.emailId,
        })}
        emails={thread.candidate_emails}
      />
    </PageBody>
  )
}

export default View
