import { Reason, ReasonsInterface } from '@src/interfaces/employeeResignation'
import { CustomReason, ReasonsState, SubreasonsState } from './types'

export const getCustomReason = (values: Reason[], options: Reason[]): CustomReason => {
  const text = values.find(value => !options.includes(value)) || ''
  return { checked: !!text, text }
}

export const mapReasonsToState = (
  reasonsValue: Reason[],
  reasonsOptions: Reason[],
): ReasonsState => {
  const reasonsState: ReasonsState = {
    values: [],
    custom: getCustomReason(reasonsValue, reasonsOptions),
  }
  reasonsState.values =
    reasonsValue.filter(value => value !== reasonsState.custom.text) || []

  return reasonsState
}

export const mapSubreasonsToState = (
  valueObject: ReasonsInterface,
  optionsObject: ReasonsInterface,
  customReason: Reason,
): SubreasonsState => {
  return Object.keys(optionsObject).reduce((acc, reasonOption) => {
    if (reasonOption === customReason) {
      return acc
    }
    const subreasonsValue = valueObject[reasonOption] || []
    const subreasonsOptions = optionsObject[reasonOption]
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    acc[reasonOption] = mapReasonsToState(subreasonsValue, subreasonsOptions)
    return acc
  }, {})
}

export const isCustomSelected = ({ checked, text }: CustomReason): boolean =>
  !!text && checked

export const insertCustomReasons = (
  selectedReasons: ReasonsState,
  selectedSubreasons: SubreasonsState,
) => {
  const selectedReasonsWithCustom = {}

  if (isCustomSelected(selectedReasons.custom)) {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    selectedReasonsWithCustom[selectedReasons.custom.text] = []
  }
  for (const [reason, { custom, values }] of Object.entries(selectedSubreasons)) {
    const subreasonsValues = isCustomSelected(custom)
      ? [...values, custom.text]
      : [...values]
    if (values.length) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      selectedReasonsWithCustom[reason] = subreasonsValues
    }
  }
  return selectedReasonsWithCustom
}
