import { InputGroup, Text, Input, Bar, ActionButton, VStack } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { ReorderSettingsInterface } from '@src/pages/Forms/DataAnalyticsReportForm/hooks/useTableSettings'

interface RenameColumnProps {
  columnName: string
  reorderSettings: ReorderSettingsInterface
  setReorderSettings: React.Dispatch<React.SetStateAction<ReorderSettingsInterface>>
  onClose: () => void
}

export const RenameColumn = ({
  columnName,
  reorderSettings,
  setReorderSettings,
  onClose,
}: RenameColumnProps) => {
  const [value, setValue] = useState<string>(
    reorderSettings.visible.find(t => {
      return t.name === columnName
    })?.alias || '',
  )
  const handleSave = () => {
    setReorderSettings(current => ({
      ...current,
      visible: current.visible.map(t => {
        return t.name === columnName
          ? { ...t, name: columnName, alias: value || null }
          : t
      }),
    }))
    onClose()
  }

  return (
    <VStack gap="s-16" width="100%">
      <InputGroup>
        <Text variant="secondary">Set Column Header Name</Text>
        <Input
          label={columnName}
          onChange={e => {
            setValue(e.currentTarget?.value)
          }}
          value={value}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              handleSave()
            }
          }}
        />
      </InputGroup>
      <Bar alignSelf="flex-end">
        <ActionButton useIcon="16/Check" onClick={handleSave}>
          Save
        </ActionButton>
      </Bar>
    </VStack>
  )
}
