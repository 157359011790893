import { useCallback, useEffect, useMemo, useState } from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  EmployeeResignationInterface,
  ReasonsInterface,
} from '@src/interfaces/employeeResignation'
import {
  resignationReasonsFormRequests,
  resignationReasonsRequests,
} from '@src/api/employeeResignation'
import { ReasonsState, SubreasonsState } from './types'
import { ToldManagerVariants } from '../../constants'
import {
  insertCustomReasons,
  isCustomSelected,
  mapReasonsToState,
  mapSubreasonsToState,
} from './helpers'
import { RouteParams } from '../../types'
import { useParams } from 'react-router-dom'
import { pushError } from '@src/store/notifications/actions'

export const useReasons = (selectedReasonsInitValue?: ReasonsInterface) => {
  const [reasonsOptions, setReasonsOptions] = useState<ReasonsInterface>({})
  const [selectedReasons, setSelectedReasons] = useState<ReasonsState>({
    values: [],
    custom: { checked: false, text: '' },
  })
  const [selectedSubreasons, setSelectedSubreasons] = useState<SubreasonsState>({})

  useEffect(() => {
    const getResignationReasonsAndSetOptions = async () => {
      const { data } = await resignationReasonsRequests.get({})
      setReasonsOptions(data)
    }
    getResignationReasonsAndSetOptions()
  }, [])

  useEffect(() => {
    const initValue = selectedReasonsInitValue || {}
    const reasonsState = mapReasonsToState(
      Object.keys(initValue),
      Object.keys(reasonsOptions),
    )
    setSelectedReasons(reasonsState)

    const subreasonsState = mapSubreasonsToState(
      initValue,
      reasonsOptions,
      reasonsState.custom.text,
    )
    setSelectedSubreasons(subreasonsState)
  }, [reasonsOptions, selectedReasonsInitValue])

  const reasonsMeta = useMemo(() => {
    const selectedReasonsNum = selectedReasons.values.length
    const selectedReasonsWithCustomNum = isCustomSelected(selectedReasons.custom)
      ? selectedReasonsNum + 1
      : selectedReasonsNum
    const isMaxReasons = selectedReasonsWithCustomNum >= 3
    const isEmpty =
      !isCustomSelected(selectedReasons.custom) && selectedReasons.values.length === 0

    return { isMaxReasons, isEmpty }
  }, [selectedReasons])

  const emptySubreasonsListsKeys = useMemo(() => {
    return Object.keys(selectedSubreasons).filter(reason => {
      const { custom: customSubreason, values: subreasonsValues } =
        selectedSubreasons[reason]
      const isCustomSubreasonSelected = isCustomSelected(customSubreason)
      return (
        selectedReasons.values.includes(reason) &&
        !isCustomSubreasonSelected &&
        subreasonsValues.length === 0
      )
    })
  }, [selectedReasons, selectedSubreasons])

  return {
    reasonsOptions,
    selectedReasons,
    selectedSubreasons,
    reasonsMeta,
    emptySubreasonsListsKeys,
    setSelectedReasons,
    setSelectedSubreasons,
  }
}

type UseFromSubmitParams = {
  selectedReasons: ReasonsState
  selectedSubreasons: SubreasonsState
  toldManager: string | null
  hasErrors: boolean
  setNewResignationId: (id: string) => void
  showErrorPopup: () => void
  showIsRegrettablePopup: () => void
}
export const useFormSubmit = ({
  selectedReasons,
  selectedSubreasons,
  toldManager,
  hasErrors,
  setNewResignationId,
  showErrorPopup,
  showIsRegrettablePopup,
}: UseFromSubmitParams) => {
  const { id, employeeId } = useParams<RouteParams>()
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  useEffect(() => {
    setIsFormSubmitted(false)
  }, [selectedReasons])

  const formData = useMemo<Partial<EmployeeResignationInterface>>(() => {
    return {
      selected_reasons: insertCustomReasons(selectedReasons, selectedSubreasons),
      told_manager: toldManager === ToldManagerVariants.yes,
    }
  }, [selectedReasons, selectedSubreasons, toldManager])

  const handleSubmit = useCallback(async () => {
    setIsFormSubmitted(true)

    if (hasErrors) {
      showErrorPopup()
      return
    }
    const submitAction = id
      ? resignationReasonsFormRequests.update
      : resignationReasonsFormRequests.submit

    try {
      const { data: newResignation } = await submitAction(formData, { employeeId, id })

      if (newResignation.is_regrettable) {
        setNewResignationId(String(newResignation.id))
        showIsRegrettablePopup()
      } else {
        navigateTo(
          pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.SUBMIT, {
            employeeId,
            id: String(newResignation.id),
          }),
        )
      }
    } catch (error) {
      pushError({ error })
    }
  }, [hasErrors, formData])

  return {
    isFormSubmitted,
    handleSubmit,
  }
}
