import React, { useEffect } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { isWorkspacesEnabled } from '@src/utils'

type SubdomainRedirectProps = {
  route: string
}

export const SubdomainRedirect = ({ route }: SubdomainRedirectProps) => {
  useEffect(() => {
    const currentURL = new URL(window.location.href)
    const state = currentURL.searchParams.get('state')
    let subdomain

    try {
      const parsedState = state && JSON.parse(state)

      if ('subdomain' in parsedState) {
        subdomain = parsedState.subdomain
      }
    } catch {
      subdomain = null
    }

    // if we are in the tenants env we cannot redirect back as each tenant has different
    // subdomain and providers doesn't support wildcard authorized redirect urls, for
    // example google and indeed, as a solution we define subdomain in the url state,
    // redirect to the main domain (which is authorized), where we check the subdomain
    // and redirect there
    if (subdomain) {
      window.location.href = isWorkspacesEnabled()
        ? `${currentURL.origin}/${subdomain}${route}${currentURL.search}`
        : `${currentURL.protocol}//${subdomain}.${currentURL.hostname}${route}${currentURL.search}`
    } else {
      window.location.href = `${route}${currentURL.search}`
    }
  }, [])

  return <PageLoading />
}
