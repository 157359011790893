import React from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  InterviewScorecardEnumSectionIds,
  InterviewScorecardTemplateInterface,
} from '@src/interfaces/interviewScorecardTemplates'
import { isLevelBasedScorecard } from '@src/utils/interview'
import { connect } from 'lape'
import { OptionInterface } from '@src/interfaces/selectors'

const MissingSkillsWarning = () => {
  const { values } = useLapeContext<InterviewScorecardTemplateInterface>()

  const getAllSectionsSkills = () => {
    if (!values.sections?.length) {
      return []
    }

    return values.sections.reduce<OptionInterface[]>((skills, section) => {
      if (section.section_type?.id !== InterviewScorecardEnumSectionIds.Level) {
        return skills
      }

      section.skills?.forEach(sectionSkill => {
        if (!skills.find(skill => skill.id === sectionSkill.id)) {
          skills.push(sectionSkill)
        }
      })
      return skills
    }, [])
  }

  const getMissingSkills = () => {
    if (!isLevelBasedScorecard(values.scorecard_type?.id)) {
      return []
    }

    if (!values.skills?.length) {
      return []
    }

    if (!values.sections?.length) {
      return values.skills
    }

    const allSectionsSkills = getAllSectionsSkills()

    return values.skills.filter(skill => {
      return allSectionsSkills.every(sectionSkill => sectionSkill.id !== skill.id)
    })
  }

  const getExtraSkills = () => {
    if (!isLevelBasedScorecard(values.scorecard_type?.id)) {
      return []
    }

    if (!values.sections?.length) {
      return values.skills || []
    }

    const allSectionsSkills = getAllSectionsSkills()

    if (!values.skills?.length) {
      return allSectionsSkills
    }

    return allSectionsSkills.filter(sectionSkill => {
      return values.skills!.every(skill => skill.id !== sectionSkill.id)
    })
  }

  const missingSkills = getMissingSkills()
  const extraSkills = getExtraSkills()

  if (missingSkills.length) {
    return (
      <ActionWidget
        mt="s-16"
        title="Some skill assessment missing"
        text={
          <>
            You had selected the skills:{' '}
            <strong>{missingSkills.map(skill => skill.name).join(', ')}</strong> which are
            not being assessed in the current scorecard. Either add sections to assess
            them or remove them from the scorecard details sections
          </>
        }
      />
    )
  }

  if (extraSkills.length) {
    return (
      <ActionWidget
        mt="s-16"
        title="Some section skills are not included in the scorecard skills"
        text={
          <>
            You had selected the skills:{' '}
            <strong>{extraSkills.map(skill => skill.name).join(', ')}</strong> which are
            assessed, but not included in the scorecard skills. Either add these skills to
            the scorecard details skills or remove them from sections.
          </>
        }
      />
    )
  }

  return null
}

export default connect(MissingSkillsWarning)
