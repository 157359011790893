import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import CompanyTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import { TalentType } from '@src/interfaces/talent/talent'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import Page404 from '@src/pages/Page404/Page404'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'

const Talent = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return <Page404 />
  }

  return (
    <Switch>
      <Route
        exact
        path={ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE}
        key={ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE}
      >
        <CompanyTalentPerformance
          data={data}
          talentType={TalentType.Company}
          navigation={<CompanyNavigation />}
        />
      </Route>
      <InternalRedirect to={ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE} />
    </Switch>
  )
}

export default Talent
