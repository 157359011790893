import { useEffect, useRef, useState } from 'react'
import uniqBy from 'lodash/uniqBy'

import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { ApiVersion, GetRequestData, GetRequestInterface } from '@src/interfaces'
import { ChatMessageInterface, CommentsAPIInterface } from '@src/interfaces/chat'
import { useFetch } from '@src/utils/reactQuery'
import { CommentStatus, TaggedUsersMap } from '@src/interfaces/comments'

export interface CommentsAPIOptions {
  baseUrl: string
  apiVersion?: ApiVersion
  isExternal?: boolean
  pageSize?: number
}

export const getCommentsAPI = ({
  baseUrl,
  apiVersion = 'v1',
  isExternal = false,
  pageSize = 10,
}: CommentsAPIOptions): CommentsAPIInterface => {
  return {
    useGetComments: (showArchived, disabled) => {
      const [page, setPage] = useState(1)
      const [allLoadedComments, setAllLoadedComments] = useState<ChatMessageInterface[]>(
        [],
      )
      const baseUrlRef = useRef<string>(baseUrl)
      const pauseOnUrlChangeRef = useRef<boolean>(false)

      // this check is needed for an edge case when we've updated baseUrl param,
      // but state.page still has an old value -- in this case we need to skip
      // an API call until all stateful params are reset to init values
      if (baseUrlRef.current !== baseUrl && page !== 1) {
        pauseOnUrlChangeRef.current = true
      } else {
        pauseOnUrlChangeRef.current = false
        baseUrlRef.current = baseUrl
      }

      useEffect(() => {
        if (baseUrl) {
          setPage(1)
          setAllLoadedComments([])
        }
      }, [baseUrl])

      const params = {
        status: showArchived
          ? undefined
          : `${CommentStatus.active},${CommentStatus.completed}`,
        page,
        page_size: pageSize,
      }

      const suspended = pauseOnUrlChangeRef.current || disabled
      const comments = useFetch<GetRequestData<ChatMessageInterface>>(
        suspended ? null : baseUrl,
        apiVersion,
        { params },
        undefined,
        undefined,
        isExternal,
      )

      useEffect(() => {
        if (comments.status !== 'success' || !comments.data?.results) {
          return
        }
        if (page === 1) {
          setAllLoadedComments(comments.data.results)
        } else if (page > 1) {
          setAllLoadedComments(
            uniqBy([...allLoadedComments, ...comments.data.results], 'id'),
          )
        }
      }, [comments.data?.results, comments.status])

      return {
        ...comments,
        allLoadedComments,
        loadNextPage: () => {
          const totalPages = comments.data?.pages.total || 0

          if (page < totalPages) {
            setPage(page + 1)
          }
        },
        refetchFirstPage: async () => {
          const res = await api.get<GetRequestInterface<ChatMessageInterface>>(
            baseUrl,
            { params: { ...params, page: 1 } },
            apiVersion,
            isExternal,
          )
          setAllLoadedComments(uniqBy([...res.data.results, ...allLoadedComments], 'id'))

          return res
        },
      }
    },
    useGetComment: id => {
      return useFetch<ChatMessageInterface>(
        id ? `${baseUrl}/${id}` : null,
        apiVersion,
        undefined,
        undefined,
        undefined,
        isExternal,
      )
    },
    addComment: (comment, createTask, attachedFiles) => {
      return api.post<ChatMessageInterface>(
        baseUrl,
        {
          body: comment,
          create_task: createTask,
          attached_files: attachedFiles,
        },
        undefined,
        apiVersion,
        isExternal,
      )
    },
    editComment: (commentId, updatedMsg, attachedFiles) => {
      return api.patch<ChatMessageInterface>(
        `${baseUrl}/${commentId}`,
        {
          body: updatedMsg,
          attached_files: attachedFiles,
        },
        undefined,
        apiVersion,
        isExternal,
      )
    },
    archiveComment: commentId => {
      return api.post(
        `${baseUrl}/${commentId}/archive`,
        undefined,
        undefined,
        apiVersion,
        isExternal,
      )
    },
    resolveComment: commentId => {
      return api.post(
        `${baseUrl}/${commentId}/complete`,
        undefined,
        undefined,
        apiVersion,
        isExternal,
      )
    },
  }
}

export const useGetInboxTaggedEmployees = () => {
  return useFetch<TaggedUsersMap>(`${API.NOTIFICATIONS}/taggedEmployees`, 'v2')
}
