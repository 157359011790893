import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { timeOffConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { TimeOffIntro } from './TimeOffIntro'
import { ManageTimeOffPolicies } from './ManageTimeOffPolicies/ManageTimeOffPolicies'
import { Route, Switch } from 'react-router-dom'
import { ManageEligibilityGroups } from './ManageEligibilityGroups'
import { PolicyAssignments } from './PolicyAssignments'
import { BalanceAdjustments } from './BalanceAdjustments'

const routes = [
  {
    title: 'Intro',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.INTRO,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: TimeOffIntro,
  },
  {
    title: 'Policy configuration',
    subtitle: 'Configure the time off policies for your business',
    path: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICIES,
    url: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICIES,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: ManageTimeOffPolicies,
    hideActions: true,
    hidePageBody: true,
  },
  {
    title: 'Policy assignments',
    subtitle: 'Configure the time off policies for your business',
    path: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICY_ASSIGNMENTS,
    url: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICY_ASSIGNMENTS,
    canView: [PermissionTypes.AddTimeOffPolicies],
    component: PolicyAssignments,
    hideActions: true,
    hidePageBody: true,
    isWide: true,
  },
  {
    title: 'Balance adjustments',
    subtitle:
      'Update your historical time-off records to ensure accurate leave balances for all employees',
    path: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.IMPORT,
    canView: [PermissionTypes.AddTimeOffRequestBulkUpload],
    component: BalanceAdjustments,
    hideActions: true,
    hidePageBody: true,
  },
]

export const TimeOff = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.ELIGIBILITY_GROUPS}>
        <ManageEligibilityGroups />
      </Route>
      <Route>
        <OnboardingChecklistContent config={timeOffConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
