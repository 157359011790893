import React, { useState, useRef } from 'react'
import {
  Button,
  Group,
  Header,
  MoreBar,
  Popup,
  Box,
  Color,
  Item,
  Avatar,
} from '@revolut/ui-kit'
import { CrossSmall, Delete, Laptop, Pencil, TimeAndMoney } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import {
  deleteShift,
  useShiftApprovals,
  approveShift,
  rejectShift,
  getShiftCommentsAPI,
} from '@src/api/attendance'
import { formatShiftDuration } from '@src/constants/columns/attendance'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ScheduleShiftInterface } from '@src/interfaces/attendance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SettingsButtons, { ApproveButton } from '@src/features/SettingsButtons'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import { handleError } from '@src/api'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { ShiftRejectDialiog } from './common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const Preview = () => {
  const { values, reset } = useLapeContext<ScheduleShiftInterface>()
  const params = useParams<{ employeeId: string; id: string }>()

  const [deletePending, setDeletePending] = useState(false)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [isApprovePending, setIsApprovePending] = useState(false)
  const [isRejectPending, setIsRejectPending] = useState(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useShiftApprovals(params.employeeId, params.id)

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  const canDelete =
    values.field_options?.actions?.includes(EntityPermissions.Delete) &&
    values.field_options?.permissions?.includes(PermissionTypes.DeleteEmployeeShift)
  const canEdit =
    values.field_options?.actions?.includes(EntityPermissions.Change) &&
    values.field_options?.permissions?.includes(PermissionTypes.ChangeEmployeeShift)
  const canViewComments =
    values.field_options?.actions?.includes(EntityPermissions.ViewComments) &&
    values.field_options?.permissions?.includes(PermissionTypes.ViewCommentsEmployeeShift)
  const canApprove = values.field_options?.actions?.includes(EntityPermissions.Approve)
  const canReject = values.field_options?.actions?.includes(EntityPermissions.Reject)
  const commentsApi = getShiftCommentsAPI(params.employeeId, params.id)
  const getComments = commentsApi.useGetComments(false, !canViewComments)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.SCHEDULE, { id: params.employeeId })

  const anyPending = isApprovePending || isRejectPending

  const onDelete = () => {
    setDeletePending(true)
    deleteShift(params.employeeId, params.id, 'shifts')
      .then(() => goBack(backUrl))
      .catch(() => setDeletePending(false))
  }

  const onApprove = async () => {
    const response = await approveShift(params.employeeId, params.id)
    reset(response.data)
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectShift(params.employeeId, params.id, rejectionComment)
      reset(response.data)
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectPending(false)
      if (canViewComments) {
        getComments.refetch()
      }
    }
  }

  return (
    <>
      <PageBody>
        <SettingsButtons mb="s-16">
          {values.approval_status.id === ApprovalStatuses.Pending && (
            <>
              <ApproveButton
                submit={onApprove}
                onBeforeSubmit={() => setIsApprovePending(true)}
                onAfterSubmit={() => {
                  setIsApprovePending(false)
                  refetchApproval()
                }}
                onSubmitFailed={handleError}
                statusFieldName="approval_status"
                isVisible={canApprove}
              />
              {canReject && (
                <MoreBar.Action
                  onClick={() => setRejectDialogOpen(true)}
                  useIcon={CrossSmall}
                  variant="negative"
                  pending={isRejectPending}
                  disabled={anyPending}
                >
                  Reject
                </MoreBar.Action>
              )}
            </>
          )}
          {canEdit && (
            <MoreBar.Action
              onClick={() =>
                navigateTo(pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.GENERAL, params))
              }
              useIcon={Pencil}
            >
              Edit
            </MoreBar.Action>
          )}
          {canDelete && (
            <MoreBar.Action
              onClick={() => setDeletePopupOpen(true)}
              useIcon={Delete}
              pending={deletePending}
              variant="negative"
            >
              Delete
            </MoreBar.Action>
          )}
        </SettingsButtons>

        {approvalSteps === undefined || approvalSteps?.length ? (
          <ApprovalFlow
            isLoading={isApprovalLoading || isApprovePending}
            steps={approvalSteps || null}
            onViewRejectionReasonClick={
              canViewComments
                ? () => {
                    setCommentsSidebarOpen.current(true)
                  }
                : undefined
            }
          />
        ) : null}

        <FormPreview title="Shift details" data={values}>
          <Group>
            <FormPreview.Item title="Start time" field="start_time" />
            <FormPreview.Item title="End time" field="end_time" />
            <FormPreview.Item
              title="Duration"
              insert={() =>
                formatShiftDuration({
                  hours: values.duration_hours,
                  minutes: values.duration_minutes,
                })
              }
            />
            {values.overtime_hours || values.overtime_minutes ? (
              <FormPreview.Item
                title="Overtime hours"
                insert={() =>
                  formatShiftDuration({
                    hours: values.overtime_hours,
                    minutes: values.overtime_minutes,
                  })
                }
              />
            ) : null}
            {values.unsocial_hours || values.unsocial_minutes ? (
              <FormPreview.Item
                title="Unsocial hours"
                insert={() =>
                  formatShiftDuration({
                    hours: values.unsocial_hours,
                    minutes: values.unsocial_minutes,
                  })
                }
              />
            ) : null}
            {values.preferred_compensation?.name ? (
              <FormPreview.Item
                title="Preferred compensation"
                field="preferred_compensation.name"
              />
            ) : null}
          </Group>
        </FormPreview>

        {!!values.breakdowns?.length && (
          <Box mt="s-16">
            <FormPreview title="Shift breakdown">
              <Group>
                {values.breakdowns.map(breakdown => (
                  <Item
                    variant="disclosure"
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.PREVIEW, {
                      policyId: breakdown.configuration.policy_id,
                      id: breakdown.configuration.id,
                    })}
                    key={breakdown.configuration.id}
                  >
                    <Item.Avatar>
                      <Avatar
                        color={
                          breakdown.configuration.paid_shift ? Color.ORANGE : Color.BLUE
                        }
                        useIcon={
                          breakdown.configuration.paid_shift ? TimeAndMoney : Laptop
                        }
                      />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title>{breakdown.configuration.name}</Item.Title>
                      {breakdown.configuration.compensation_level ? (
                        <Item.Description>
                          {breakdown.configuration.compensation_level}%{' '}
                          {breakdown.configuration.compensation_type.name}
                        </Item.Description>
                      ) : null}
                    </Item.Content>
                    <Item.Side>
                      <Item.Value>
                        {breakdown.time_range.start} - {breakdown.time_range.end}
                      </Item.Value>
                      <Item.Value variant="secondary">
                        Duration:{' '}
                        {formatShiftDuration({
                          hours: breakdown.time_range.duration_hours,
                          minutes: breakdown.time_range.duration_minutes,
                        })}
                      </Item.Value>
                    </Item.Side>
                  </Item>
                ))}
              </Group>
            </FormPreview>
          </Box>
        )}

        {canViewComments ? (
          <Box mt="s-16">
            <CommentsSection
              api={commentsApi}
              setSidebarOpen={setCommentsSidebarOpen}
              disableTodolistFeature
            />
          </Box>
        ) : null}
      </PageBody>

      <Popup
        open={deletePopupOpen}
        variant="bottom-sheet"
        onClose={() => setDeletePopupOpen(false)}
      >
        <Header variant="bottom-sheet">
          <Header.Title>Are you sure you want to delete this shift?</Header.Title>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setDeletePopupOpen(false)} variant="secondary">
            Cancel
          </Button>
          <Button onClick={onDelete} pending={deletePending} elevated>
            Confirm
          </Button>
        </Popup.Actions>
      </Popup>

      <ShiftRejectDialiog
        open={rejectDialogOpen}
        isPending={isRejectPending}
        onReject={onReject}
        onClose={() => setRejectDialogOpen(false)}
        comment={rejectionComment}
        setComment={setRejectionComment}
      />
    </>
  )
}

export default Preview
