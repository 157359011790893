import React from 'react'
import styled from 'styled-components'
import Tooltip from '../../Tooltip/Tooltip'
import { formatNumber } from '@src/utils/format'
import Icon from '../../Icon/Icon'
import { Token } from '@revolut/ui-kit'

const Wrapper = styled.div`
  display: flex;
`

const StarIcon = styled(Icon)<{ isFull: boolean }>`
  color: ${props => (props.isFull ? Token.color.orange : Token.color.greyTone20)};
  margin: 0 1px;
`

export interface RatingProps {
  score: number
  className?: string
}

const Rating = ({ className, score }: RatingProps) => {
  const stars = []
  for (let i = 0; i < 5; ++i) {
    const isFull = Math.round(score) > i
    stars.push(
      <StarIcon
        type={isFull ? 'Star' : 'StarOutlined'}
        isFull={isFull}
        key={i}
        size="tiny"
      />,
    )
  }

  return (
    <Wrapper>
      <Tooltip text={formatNumber(score)} placement="right">
        <Wrapper className={className}>{stars}</Wrapper>
      </Tooltip>
    </Wrapper>
  )
}

export default Rating
