import React from 'react'
import { Link as UIKitLink } from '@revolut/ui-kit'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'

const OnlineTestPreviewLink = <
  T extends Pick<HiringProcessInterface, 'online_test'>,
>() => {
  return (
    <FormPreview.Item<T>
      title="Test link"
      insert={data =>
        data.online_test?.test_url ? (
          <UIKitLink
            use="a"
            href={data.online_test.test_url}
            target="_blank"
            rel="noreferrer noopener"
          >
            View
          </UIKitLink>
        ) : (
          '-'
        )
      }
    />
  )
}

export default OnlineTestPreviewLink
