import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import React, { ReactNode } from 'react'
import { FieldOptions, Statuses } from '@src/interfaces'
import { AxiosPromise } from 'axios'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import DeleteOrgUnitButton from '@src/features/SettingsButtons/DeleteOrgUnitButton/DeleteOrgUnitButton'

export interface LapeDeleteOrgUnitButtonProps extends SettingsButtonsInstanceProps {
  deleteApi: (data: any, params: { id: string }) => AxiosPromise<any>
  onAfterDelete?: () => void
  prefix?: string
  displayName?: string
  entityLevelPermission?: EntityPermissions
  renderButton?: ({
    onClick,
    loading,
  }: {
    onClick: () => Promise<void>
    loading: boolean
  }) => ReactNode
  checkPermissions?: boolean
  confirmMessage?: string
  forcedId?: number
  showPendingPopup?: boolean
}

const LapeDeleteOrgUnitButton = (props: LapeDeleteOrgUnitButtonProps) => {
  const { values } = useLapeContext<{
    status: Statuses
    field_options: FieldOptions
    id: number
    name?: string
  }>()

  return <DeleteOrgUnitButton data={values} {...props} />
}

export default connect(LapeDeleteOrgUnitButton)
