import { Group } from '@revolut/ui-kit'
import React from 'react'

import { EmployeeInterface } from '@src/interfaces/employees'
import {
  PerformanceSelector,
  PerformanceTimelineInterface,
} from '@src/interfaces/performance'
import { PerformanceChartGradeData } from '@src/interfaces/chart'
import { RecruitmentCycleItem } from './RecruitmentCycleItem'
import { PerformanceHistoryItems } from './PerformanceHistoryItems'

interface Props {
  data: EmployeeInterface
  setSelectedPeriod: (target: PerformanceSelector | undefined) => void
  performanceSelectorData: PerformanceSelector[] | undefined
  selectedPeriod: PerformanceSelector | undefined
  setSelectedCycle: (cycle: PerformanceTimelineInterface | undefined) => void
  chartDataGradesValues: PerformanceChartGradeData<number>[]
  setRecruitmentCycle: (flag: boolean) => void
  canViewRecruitmentPerformance: boolean
  canViewPerformanceHistoryTab: boolean
  isRecruitmentCycle: boolean
  isFetching: boolean
  isCurrentUserProfile: boolean
}

export const PerformanceHistoryNavigation = ({
  data,
  setSelectedPeriod,
  performanceSelectorData,
  selectedPeriod,
  chartDataGradesValues,
  setRecruitmentCycle,
  setSelectedCycle,
  isRecruitmentCycle,
  canViewRecruitmentPerformance,
  canViewPerformanceHistoryTab,
  isFetching,
  isCurrentUserProfile,
}: Props) => {
  return (
    <Group>
      {canViewPerformanceHistoryTab && (
        <PerformanceHistoryItems
          data={data}
          isCurrentUserProfile={isCurrentUserProfile}
          isFetching={isFetching}
          setSelectedPeriod={setSelectedPeriod}
          setSelectedCycle={setSelectedCycle}
          chartDataGradesValues={chartDataGradesValues}
          performanceSelectorData={performanceSelectorData}
          selectedPeriod={selectedPeriod}
          setRecruitmentCycle={setRecruitmentCycle}
        />
      )}

      {canViewRecruitmentPerformance && (
        <RecruitmentCycleItem
          canViewRecruitmentPerformance={canViewRecruitmentPerformance}
          isRecruitmentCycle={isRecruitmentCycle}
          setSelectedPeriod={setSelectedPeriod}
          setRecruitmentCycle={setRecruitmentCycle}
          employeeId={data.id}
        />
      )}
    </Group>
  )
}
