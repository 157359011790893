import React from 'react'
import { Tag, Text } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  PromotionNominationInterface,
  PromotionReviewerType,
  PromotionStage,
  PromotionStageInterface,
} from '@src/interfaces/promotions'
import { selectorKeys } from '@src/constants/api'
import { getStatusColor } from '@components/CommonSC/General'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { formatDate } from '@src/utils/format'
import DelegateButton from '@src/pages/Forms/PromotionNominationForm/Buttons/DelegateButton'
import ResetStageButton from '@src/pages/Forms/PromotionNominationForm/Buttons/ResetStageButton'
import { StagesSummary } from '@src/pages/Forms/PromotionNominationForm/common/ReviewTimeline'
import { HStack, TextButton } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { PermissionTypes } from '@src/store/auth/types'
import { promotionTimelineStepsMap } from '@src/utils/promotion'

export const promotionStageName: ColumnInterface<PromotionStageInterface> = {
  type: CellTypes.insert,
  idPoint: 'stage_type__id',
  dataPoint: 'stage_type__name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Stage',
  insert: ({ data, parentIndexes }) => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    const stageNumber = promotionTimelineStepsMap[parentIndexes[0] + 1]
    return `${stageNumber}. ${data.stage_type.name}`
  },
}

export const promotionStageReviewer: ColumnInterface<PromotionStageInterface> = {
  type: CellTypes.insert,
  idPoint: 'reviewer',
  dataPoint: 'reviewer',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reviewer',
  insert: ({ data }) => {
    if (data.reviewer_type === PromotionReviewerType.Employee) {
      return data.reviewer ? <UserWithAvatar {...data.reviewer} /> : 'unassigned'
    }
    return (
      <Tag variant="faded" color="primary">
        {data.reviewer_group?.name || 'not specified'}
      </Tag>
    )
  },
}

export const promotionStageCompletedDate: ColumnInterface<PromotionStageInterface> = {
  type: CellTypes.insert,
  idPoint: 'completed_date_time',
  dataPoint: 'completed_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Completed on',
  insert: ({ data }) =>
    data.completed_date_time ? formatDate(data.completed_date_time) : '-',
}

export const getPromotionStageOutcomeColumn = (
  currentStageIndex: number[],
): ColumnInterface<PromotionStageInterface> => ({
  type: CellTypes.insert,
  idPoint: 'outcome__id',
  dataPoint: 'outcome__name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Outcome',
  colors: data => getStatusColor(data.outcome.id),
  insert: ({ children, parentIndexes }) => {
    if (parentIndexes[0] > currentStageIndex[currentStageIndex.length - 1]) {
      return <Text color="grey-tone-50">-</Text>
    }
    return children
  },
})

export type PromotionActionsOptions = {
  nomination: PromotionNominationInterface
  nominatedEmployee: { id: number; full_name: string }
  refreshData: () => void
  stagesSummary: StagesSummary
  onBeforeViewClick?: () => void
}

export const getPromotionStageActionsColumn = ({
  nominatedEmployee,
  nomination,
  refreshData,
  stagesSummary,
  onBeforeViewClick,
}: PromotionActionsOptions): ColumnInterface<PromotionStageInterface> => ({
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data, parentIndexes }) => {
    if (data.stage_type.id === PromotionStage.Initial) {
      return (
        <TextButton
          onClick={() => {
            onBeforeViewClick?.()
            navigateTo(
              pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM_VIEW, {
                id: nomination.id,
                employeeId: nominatedEmployee.id,
              }),
              {
                initialValues: nomination,
              },
            )
          }}
        >
          View
        </TextButton>
      )
    }
    const isNominationInReview = nomination.status === Statuses.in_review
    const isCurrentStage =
      stagesSummary.currentStagesIndex.includes(parentIndexes[0]) && isNominationInReview

    const permissions = data.field_options?.permissions
    const canDelegate = permissions?.includes?.(PermissionTypes.DelegatePromotionStage)
    const canView = isCurrentStage
      ? permissions?.includes?.(PermissionTypes.ChangePromotionStage) &&
        isNominationInReview
      : permissions?.includes?.(PermissionTypes.ViewPromotionStage)

    const showDelegate =
      canDelegate &&
      isCurrentStage &&
      isNominationInReview &&
      data.outcome.id === Statuses.pending

    const showView =
      canView &&
      parentIndexes[0] <=
        stagesSummary.currentStagesIndex[stagesSummary.currentStagesIndex.length - 1] &&
      data.stage_type.id !== PromotionStage.Benchmark &&
      data.outcome.id !== Statuses.skipped

    const showReset =
      permissions?.includes(PermissionTypes.ResetPromotionStage) &&
      (data.outcome.id === Statuses.rejected || data.outcome.id === Statuses.accepted)

    if (!showDelegate && !showView && !showReset) {
      return '-'
    }
    return (
      <HStack space="s-8">
        {showView && (
          <TextButton
            onClick={() => {
              onBeforeViewClick?.()
              navigateTo(
                pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.STAGE, {
                  id: nomination.id,
                  employeeId: nominatedEmployee.id,
                  stageId: data.id,
                }),
              )
            }}
          >
            {isCurrentStage && data.outcome.id === Statuses.pending ? 'Review' : 'View'}
          </TextButton>
        )}
        {showDelegate && (
          <DelegateButton
            nominationId={nomination.id}
            stage={data}
            nominatedEmployee={nominatedEmployee}
            variant="text"
            onSuccess={refreshData}
          />
        )}
        {showReset && (
          <ResetStageButton
            nominationId={String(nomination.id)}
            employeeId={String(nominatedEmployee.id)}
            stageId={String(data.id)}
            onSuccess={refreshData}
          />
        )}
      </HStack>
    )
  },
})
