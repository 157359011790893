import React from 'react'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { OptionInterface } from '@src/interfaces/selectors'

type RightToWorkInputProps = {
  disabled?: boolean
  error?: string | number
  name: string
  optional?: boolean
  rightToWork?: string | number
  onChangeRightToWork: (rightToWork: string | number) => void
}

export const useGetRightToWorkOptions = (rightToWork?: string | number) => {
  const { data } = useGetSelectors(selectorKeys.interview_feedback_right_to_work_choices)
  const options = data ?? []
  const value = rightToWork ? options.find(({ id }) => id === rightToWork) : undefined
  return {
    options,
    value,
  }
}

export const RightToWorkInput = ({
  disabled,
  error,
  name,
  optional,
  rightToWork,
  onChangeRightToWork,
}: RightToWorkInputProps) => {
  const { options, value } = useGetRightToWorkOptions(rightToWork)
  const props = {
    'data-name': name,
    'aria-invalid': !!error,
    hasError: !!error,
    message: error,
    disabled,
  }
  return (
    <RadioSelectInput<OptionInterface>
      options={options.map(o => ({
        label: o.name,
        value: o,
      }))}
      label={`Right to work${optional ? ' (optional)' : ''}`}
      value={value}
      onChange={v => {
        if (v) {
          onChangeRightToWork(v.id)
        }
      }}
      {...props}
    />
  )
}

export default RightToWorkInput
