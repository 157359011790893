import React from 'react'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { organisationSettings, APPROVAL_PROCESS_GROUPS_API } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageBody } from '@components/Page/PageBody'
import { Group, VStack } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'

const DepartmentsSettings = () => {
  const queryClient = useQueryClient()

  const { values } = useLapeContext<OrganisationSettingsInterface>()

  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  const karmaHidden = !values.karma?.visible

  return (
    <>
      <PageBody>
        <VStack mt="s-24" space="s-16">
          <Group>
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Leadership functionality',
                description:
                  'Enables managing who holds the key positions in the department and displaying this to other users',
              }}
              name="enable_leadership"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Budget Management functionality',
                description:
                  'Allows tracking department spending and compensation management for the talent',
              }}
              name="enable_budget_management"
              disabled={disableEdit}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Karma functionality',
                description:
                  'Allows measuring the impact of the findings and risks on the company by using Karma points',
              }}
              name="karma.enabled"
              disabled={disableEdit}
              hidden={karmaHidden}
            />
          </Group>

          <ApprovalSteps
            title="Enable Departments approval"
            description="Whenever a new department is created or an existing one is changed it asks for approval"
            switchField="enable_departments_approvals"
            approvalProcess="departments_approval_steps"
            entity="department"
            disabled={disableEdit}
            formRef={values}
          />
        </VStack>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.ORGANISATION_SETTINGS)
            queryClient.invalidateQueries(APPROVAL_PROCESS_GROUPS_API)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}

const routes = [
  {
    title: 'General',
    path: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    url: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
    canView: [
      PermissionTypes.ViewOrganisationPreferences,
      PermissionTypes.ChangeOrganisationPreferences,
    ],
    component: DepartmentsSettings,
  },
]

export const Departments = () => {
  return (
    <SettingsForm
      routes={routes}
      api={organisationSettings}
      title="Departments settings"
      subtitle="Settings that apply to all departments"
    />
  )
}
