import React, { useEffect, useState } from 'react'
import {
  Box,
  Flex,
  InputGroup,
  Checkbox,
  Radio,
  Text,
  Input,
  VStack,
  ActionButton,
} from '@revolut/ui-kit'
import { get, set } from 'lodash'

import { useLapeContext } from '@src/features/Form/LapeForm'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'
import { PerformanceScorecardOption } from '@src/interfaces/settings'

interface OptionSectionProps {
  value?: PerformanceScorecardOption[]
  previewMode?: boolean
  onChange?: (val: PerformanceScorecardOption[]) => void
  type: 'radio' | 'checkbox'
  optionsKey?: string
}

export const OptionSection = ({
  value,
  previewMode,
  onChange,
  type,
  optionsKey,
}: OptionSectionProps) => {
  const { errors } = useLapeContext()
  const [activeInputIdx, setActiveInputIdx] = useState<number | null>(null)

  useEffect(() => {
    if (!value?.length) {
      handleAddChange()
    }
  }, [type])

  const handleAddChange = () => {
    const newValues: PerformanceScorecardOption[] = [...(value || []), { text: '' }]
    setActiveInputIdx(newValues.length - 1)
    onChange?.(newValues)
  }

  const handleDelete = (id: number) => {
    onChange?.(value?.filter((_, i) => i !== id) || [])
  }

  const handleExistingValuesChange = (val: string, id: number) => {
    const newValues = [...(value || [])]
    newValues[id].text = val
    onChange?.(newValues)
  }

  const onInputKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.code === 'Enter') {
      handleAddChange()
    }
  }

  const iconRouter = () => {
    switch (type) {
      case 'checkbox':
        return <Checkbox tabIndex={-1} checked={false} />
      case 'radio':
        return <Radio tabIndex={-1} checked={false} />
      default:
        return null
    }
  }

  if (previewMode) {
    return (
      <VStack space="s-16">
        {value?.map((val, id) => {
          const error = get(errors, `${optionsKey}.${id}.text`)

          return (
            <React.Fragment key={id}>
              <Flex alignItems="center" data-name={`${optionsKey}.${id}.text`}>
                <Box mr="s-16">{iconRouter()}</Box>
                <Flex flex="1" justifyContent="space-between">
                  <Text use="div" fontSize="primary">
                    {val.text}
                  </Text>
                </Flex>
              </Flex>
              <BottomInputMessage hasError message={error} />
            </React.Fragment>
          )
        })}
      </VStack>
    )
  }

  return (
    <InputGroup>
      {value?.map((val, id) => {
        const error = get(errors, `${optionsKey}.${id}.text`)
        return (
          <Box key={id}>
            <InputGroup variant="horizontal">
              <Input
                value={val.text}
                placeholder="Type here..."
                onChange={e => {
                  handleExistingValuesChange(e.currentTarget.value, id)
                  set(errors, `${optionsKey}.${id}.text`, undefined)
                }}
                onKeyDown={onInputKeyDown}
                autoFocus={id === activeInputIdx}
                data-name={`${optionsKey}.${id}.text`}
                renderPrefix={iconRouter}
                errorMessage={error}
                aria-invalid={!!error}
              />
              <Flex alignItems="center" maxHeight={56}>
                <ActionButton
                  ml="s-8"
                  iconOnly
                  onClick={() => handleDelete(id)}
                  useIcon="Minus"
                  disabled={value.length === 1}
                />
              </Flex>
            </InputGroup>
          </Box>
        )
      })}
      <ActionButton onClick={handleAddChange}>Add</ActionButton>
    </InputGroup>
  )
}
