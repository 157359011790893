import React from 'react'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'
import { API } from '@src/constants/api'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const Bonus = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeTotalCompensationPreferences,
  )

  return (
    <>
      <PageBody mt="s-32">
        <LapeMultiInput
          disabled={disableEdit}
          name="bonus_categories"
          title="What are the reasons for granting bonuses?"
          valueFieldName={undefined}
          withCell
        />
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.COMPENSATION_SETTINGS)
          }}
          successText="Settings saved successfully"
          useValidator
        />
      </PageActions>
    </>
  )
}
