import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import React, { useMemo } from 'react'
import { useCareersApplicationSettings } from '@src/api/recruitment/careers'
import uniqBy from 'lodash/uniqBy'
import { OptionInterface } from '@src/interfaces/selectors'
import { CareersPosition, GeneralSectionQuestionsKeys } from '@src/interfaces/careers'

type Props = {
  locked?: boolean
  position?: CareersPosition
  countries?: OptionInterface[]
}

export const useCareersGeneralSectionFields = ({
  locked,
  position,
  countries,
}: Props) => {
  const { data: applicationSettings } = useCareersApplicationSettings()

  const preferredLocations = useMemo(
    () =>
      uniqBy(position?.locations, 'name').map(location => ({
        key: location.name,
        label: location.name,
        value: {
          id: location.name,
          name: location.name,
        },
      })) || [],
    [position?.locations],
  )

  const fields: {
    key: GeneralSectionQuestionsKeys
    node: React.ReactNode
    required: boolean
  }[] = [
    {
      key: GeneralSectionQuestionsKeys.full_name,
      required: true,
      node: (
        <LapeNewInput
          key={GeneralSectionQuestionsKeys.full_name}
          name={GeneralSectionQuestionsKeys.full_name}
          label="Full name"
          required
          disabled={locked}
        />
      ),
    },
    {
      key: GeneralSectionQuestionsKeys.email,
      required: true,
      node: (
        <LapeNewInput
          key={GeneralSectionQuestionsKeys.email}
          name={GeneralSectionQuestionsKeys.email}
          label="Email"
          required
          disabled={locked}
        />
      ),
    },
  ]

  if (applicationSettings?.enable_phone_number_field) {
    const required = !applicationSettings.is_phone_number_optional
    fields.push({
      key: GeneralSectionQuestionsKeys.phone,
      required,
      node: (
        <LapeNewInput
          key={GeneralSectionQuestionsKeys.phone}
          name={GeneralSectionQuestionsKeys.phone}
          label="Phone"
          required={required}
          disabled={locked}
        />
      ),
    })
  }

  if (applicationSettings?.enable_current_company_field) {
    const required = !applicationSettings.is_current_company_optional
    fields.push({
      key: GeneralSectionQuestionsKeys.current_company,
      required,
      node: (
        <LapeNewInput
          key={GeneralSectionQuestionsKeys.current_company}
          name={GeneralSectionQuestionsKeys.current_company}
          label="Current company"
          required={!applicationSettings.is_current_company_optional}
          disabled={locked}
        />
      ),
    })
  }

  fields.push({
    key: GeneralSectionQuestionsKeys.country,
    required: true,
    node: (
      <LapeRadioSelectInput
        key={GeneralSectionQuestionsKeys.country}
        label="Current country"
        name={GeneralSectionQuestionsKeys.country}
        options={countries?.map(value => ({
          label: value.name,
          value,
        }))}
        disabled={locked}
      />
    ),
  })

  if (applicationSettings?.enable_preferred_work_location_field) {
    const required = !applicationSettings.is_preferred_work_location_optional
    fields.push({
      key: GeneralSectionQuestionsKeys.preferred_locations,
      required,
      node: (
        <LapeNewMultiSelect
          key={GeneralSectionQuestionsKeys.preferred_locations}
          placeholder="Preferred work locations"
          name={GeneralSectionQuestionsKeys.preferred_locations}
          options={preferredLocations}
          required={required}
          disabled={locked}
        />
      ),
    })
  }

  return fields
}
