import { Box, BoxProps, Token, VStack, chain, Text, Link } from '@revolut/ui-kit'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { useGetCompanyPreferences } from '@src/api/settings'
import { BaseChartInner } from '@src/components/Charts/BaseChart/BaseChartInner'
import QualityChecks from '@src/components/ColumnInserts/QualityScore/QualityChecks'
import QualityScore from '@src/components/ColumnInserts/QualityScore/QualityScore'
import { Status } from '@src/components/CommonSC/General'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { KPITypes } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ValueTargetsTable from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/ValueTargetsTable'
import { SummarySubheader, SummaryTableContainer } from '@src/features/Summary/common'
import { KpiInterface } from '@src/interfaces/kpis'
import {
  TargetsApprovalsContext,
  TargetsApprovalsValue,
} from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import upperFirst from 'lodash/upperFirst'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

const RawStatus = styled(Status)`
  text-transform: none;
`
const WordWrapCss = css`
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
`

const BorderedBox = styled(Box)`
  border: 1px solid ${Token.color.greyTone5};
  border-radius: ${Token.radius.r12};
`
const StepWrapper = (props: BoxProps) => (
  <Box css={WordWrapCss} bg={Token.color.widgetBackground} radius="widget" {...props} />
)
const useKpiApprovalEnabled = (kpi: KpiInterface) => {
  const { data: settings } = useGetPerformanceSettings()

  switch (kpi.type) {
    case KPITypes.employee_kpi:
      return !!settings?.enable_employee_kpi_approvals
    case KPITypes.team_kpi:
      return !!settings?.enable_team_kpi_approvals
    case KPITypes.department_kpi:
      return !!settings?.enable_department_kpi_approvals
    case KPITypes.company_kpi:
      return !!settings?.enable_company_kpi_approvals
    default:
      return false
  }
}

const useOrgUnitName = (kpi: KpiInterface) => {
  const { data: settings } = useGetCompanyPreferences()

  switch (kpi.type) {
    case KPITypes.employee_kpi:
      return kpi.owner.display_name
    case KPITypes.team_kpi:
      return kpi.team?.name
    case KPITypes.department_kpi:
      return kpi.department?.name
    case KPITypes.company_kpi:
    case KPITypes.company_goal:
      return settings?.brand_name
    default:
      return '-'
  }
}

export const SidePanel = () => {
  const { values } = useLapeContext<KpiInterface>()
  const approvalsEnabled = useKpiApprovalEnabled(values)
  const orgUnitName = useOrgUnitName(values)
  const { approvalFlow, status } = useContext<TargetsApprovalsValue>(
    TargetsApprovalsContext,
  )

  const title = chain(
    values.name,
    status ? (
      <RawStatus status={values.status}>
        {upperFirst(values.status).replaceAll('_', ' ')}
      </RawStatus>
    ) : null,
  )

  return (
    <VStack width="100%" space="s-24">
      <VStack>
        <Text variant="h4" fontWeight={700}>
          {title}
        </Text>
        {!!values.owner && (
          <Text variant="caption" color={Token.color.greyTone50}>
            {values.owner.display_name}
          </Text>
        )}
      </VStack>

      {approvalsEnabled && approvalFlow && (
        <BorderedBox
          border={`1px solid ${Token.color.greyTone5}`}
          borderRadius={Token.radius.r12}
        >
          {approvalFlow}
        </BorderedBox>
      )}
      <VStack>
        <SummarySubheader>General Details</SummarySubheader>
        <BorderedBox>
          <FormPreview<KpiInterface> data={values} customHeader={() => null}>
            {!values.is_employee && (
              <FormPreview.Item<KpiInterface>
                field=""
                title="Organisation unit"
                insert={() => orgUnitName}
              />
            )}
            <FormPreview.Item<KpiInterface>
              field="owner"
              title={values.is_employee ? 'Organisation unit' : 'Owner'}
              type="employee"
            />
            {values.is_inherited && (
              <FormPreview.Details<KpiInterface> field="parent.name" title="Parent" />
            )}
            <FormPreview.Item<KpiInterface> field="unit" title="Unit" />

            <FormPreview.Details<KpiInterface> field="description" title="Description" />
            {values.quality_check?.checks &&
              values.quality_check?.checks?.length !== 0 && (
                <FormPreview.CollapsibleDescription
                  title="Quality"
                  leftInfo={<QualityScore value={values.quality_score} />}
                  content={<QualityChecks data={values.quality_check.checks} />}
                />
              )}
            {values.sql_query_db?.id === 'looker' && values.look_url ? (
              <FormPreview.Item<KpiInterface>
                field="look_url"
                title="Look URL"
                insert={({ look_url }) => (
                  <Link href={look_url!} target="_blank" rel="noreferrer noopener">
                    {look_url}
                  </Link>
                )}
              />
            ) : null}
          </FormPreview>
        </BorderedBox>
      </VStack>
      <VStack>
        <SummarySubheader>Targets</SummarySubheader>
        <SummaryTableContainer>
          <ValueTargetsTable readonly simpleMode />
        </SummaryTableContainer>
      </VStack>
      <VStack>
        <SummarySubheader>Progress Chart</SummarySubheader>
        <StepWrapper height={{ all: 306, md: 360 }}>
          <BaseChartInner fetchData={getKpiPerformanceGraph} id={values.id} />
        </StepWrapper>
      </VStack>
    </VStack>
  )
}
