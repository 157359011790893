import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'
import { Token } from '@revolut/ui-kit'

export const PayrollIntro = () => {
  return (
    <>
      <OnboardingIntro
        videoUrl="/intros/Intro%20-%20Payroll.mp4"
        videoPosterUrl="/intros/Intro%20-%20Payroll.png"
      >
        <OnboardingIntroTips
          title={
            <FormattedMessage
              id="onboardingV2.payroll.intro.tips.title"
              defaultMessage="Handle all payroll information in one place"
            />
          }
          text={
            <FormattedMessage
              id="onboardingV2.payroll.intro.tips.text"
              defaultMessage="From salaries to bonuses, handle everything that impacts payroll in one central place"
            />
          }
          items={[
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.define.title"
                  defaultMessage="Define your payroll cycles"
                />
              ),
              icon: 'Form',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.define.text"
                  defaultMessage="Configure how your payroll works:<ul><li>frequency of payments</li><li>cut-off date (deadline for changes in records)</li><li>key payroll timelines</li><li>and more</li></ul>"
                  values={{
                    ul: chunks => <ul>{chunks}</ul>,
                    li: chunks => (
                      <li style={{ marginLeft: Token.space.s16 }}>{chunks}</li>
                    ),
                  }}
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.keepTrack.title"
                  defaultMessage="Keep track of all changes in one place"
                />
              ),
              icon: 'People',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.keepTrack.text"
                  defaultMessage="Every time there is a change that is relevant to payroll, you can track this in the payroll cycle screen, making sure that you pay your employees correctly."
                />
              ),
            },
            {
              title: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.export.title"
                  defaultMessage="Export your change report"
                />
              ),
              icon: 'BalanceSheet',
              text: (
                <FormattedMessage
                  id="onboardingV2.payroll.intro.tips.export.text"
                  defaultMessage="When the cycle is nearing it’s end, download the change report and send it to your payroll processor."
                />
              ),
            },
          ]}
        />
      </OnboardingIntro>
    </>
  )
}
