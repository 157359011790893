import React, { useEffect, useState } from 'react'
import { IconButton, Image, Item, ItemProps } from '@revolut/ui-kit'
import { CrossSmall } from '@revolut/icons'
import minDate from 'date-fns/min'
import format from 'date-fns/format'
import useKPIWarning from '@src/hooks/UseKPIWarning'
import { COOKIE } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export interface ImportantKpiBannerProps extends ItemProps {
  disabled?: boolean
}

/*
  NB: please make the banner based on ui-kit's Item a generic component if you need
  to use the same one anywhere else. Don't copy-paste.
 */
const ImportantKpiBanner = ({ disabled = false, ...props }: ImportantKpiBannerProps) => {
  const { showBanner, reviewCycleData: cycle } = useKPIWarning()
  const [isClosed, setIsClosed] = useState<boolean>(false)
  const [date, setDate] = useState<string>()

  useEffect(() => {
    if (cycle) {
      const min = minDate([
        new Date(cycle.department_kpi_period_end_day!),
        new Date(cycle.team_kpi_period_end_day!),
      ])

      setDate(format(min, 'do MMMM'))
    }
  }, [cycle])

  if (isClosed || !showBanner || disabled) {
    return null
  }

  return (
    <Item {...props}>
      <Item.Avatar>
        <Image
          src="https://assets.revolut.com/media/business/illustrations-repository/alert.png"
          size={40}
          css=""
        />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>Deadline for KPI submission</Item.Title>
        <Item.Description>
          Please submit your kpis {date ? `by ${date}` : 'before the deadline'} to avoid
          getting a bonus deduction
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <IconButton
            size={12}
            useIcon={CrossSmall}
            onClick={() => {
              setIsClosed(true)
              workspaceLocalStorage.setItem(COOKIE.KPI_IMPORTANT_INFO_SEEN, 'true')
            }}
            color="grey-tone-50"
          />
        </Item.Value>
      </Item.Side>
    </Item>
  )
}

export default ImportantKpiBanner
