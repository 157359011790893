import type { Token } from '@revolut/ui-kit'
import { GenericTimeSeriesInterface } from '@src/interfaces/totalCompensation'
import { formatMoneyMillions } from '@src/utils/format'

type BaseColor = keyof typeof Token.colorChannel
const CHART_COLORS: BaseColor[] = [
  'green',
  'terracotta',
  'orange',
  'yellow',
  'deepPink',
  'lightGreen',
  'lightBlue',
  'indigo',
  'brown',
  'red',
]

export const getColorByIndex = (id: number) => {
  return CHART_COLORS[id % CHART_COLORS.length]
}

export const valueAccessor = (
  entry: GenericTimeSeriesInterface,
  dataKey: string,
  dataKeys: { value: unknown }[],
) => {
  const lastKey = dataKeys[dataKeys.length - 1].value

  if (dataKey !== lastKey) {
    return null
  }

  return formatMoneyMillions(Number(entry.total), entry.currency.iso_code)
}

export const useBarProjectedData = <T extends GenericTimeSeriesInterface>(data?: T[]) => {
  const projectedDataIdx: number[] = []

  data?.forEach((value, i) => {
    if (value.year >= new Date().getFullYear()) {
      projectedDataIdx.push(i)
    }
  })

  return projectedDataIdx
}
