import React from 'react'
import { SpecialisationOption } from '@src/interfaces/selectors'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'

interface Props {
  value: SpecialisationOption
  options: SpecialisationOption[]
  onChange: (value?: SpecialisationOption) => void
}

export const SpecialisationSelector = ({ value, options, onChange }: Props) => {
  return (
    <ButtonFilter<SpecialisationOption>
      value={[value]}
      variant="inactive"
      type={FilterSelectType.SingleSelect}
      title={value.name}
      selector={() => Promise.resolve({ options })}
      onChange={([singleSelectedValue]) => onChange(singleSelectedValue)}
    />
  )
}
