import React from 'react'

import { APPLICATIONS } from '@src/constants/hub'
import { HubAppWithOnboardingScreen } from '@src/features/HubApp/HubAppWithOnboardingScreen'

const PerformanceReviews = () => {
  return (
    <HubAppWithOnboardingScreen category="reviews" app={APPLICATIONS.performanceReview} />
  )
}

export default PerformanceReviews
