import {
  Cell,
  VStack,
  Text,
  Token,
  HStack,
  TabBar,
  useTooltip,
  Icon,
  Tooltip,
  Box,
} from '@revolut/ui-kit'
import React, { useEffect, useMemo, useState } from 'react'
import { ValuesTab } from './Values'
import { SkillsTab } from './SkillsTab'
import { PerformanceScorecardSectionInterface } from '@src/interfaces/settings'
import { Deliverables } from './Deliverables'
import { SummaryTab } from './Summary'

export const ScorecardPreview = ({
  showSkills,
  showValues,
}: {
  showSkills: boolean
  showValues: boolean
  additionalSections?: PerformanceScorecardSectionInterface[]
}) => {
  const tabs = useMemo(
    () => [
      {
        title: 'Deliverables',
        shown: true,
        component: <Deliverables />,
      },
      {
        title: 'Skills',
        shown: showSkills,
        component: <SkillsTab />,
      },
      {
        title: 'Values',
        shown: showValues,
        component: <ValuesTab />,
      },
      {
        title: 'Summary',
        shown: true,
        component: <SummaryTab />,
      },
    ],
    [showSkills, showValues],
  )
  const [selectedTab, setSelectedTab] = useState(tabs[0])

  useEffect(() => {
    const isHiddenSkillsSelected = !showSkills && selectedTab.title === 'Skills'
    const isHiddenValuesSelected = !showValues && selectedTab.title === 'Values'
    if (isHiddenSkillsSelected || isHiddenValuesSelected) {
      setSelectedTab(tabs[0])
    }
  }, [showSkills, showValues, selectedTab])
  const tooltip = useTooltip()

  const deliverablesMinWidth = 400

  return (
    <Cell style={{ width: '100%', display: 'flex', flex: '3' }}>
      <VStack space="s-12" width="100%" alignSelf="flex-start">
        <HStack space="s-8" align="center">
          <Text variant="h6" color={Token.color.greyTone50}>
            Scorecard preview
          </Text>
          <Icon
            name="16/QuestionOutline"
            {...tooltip.getAnchorProps()}
            size={18}
            color={Token.color.greyTone50}
          />
          <Tooltip {...tooltip.getTargetProps()} maxWidth={250}>
            This is an example of how the review scorecard will look when capturing
            performance feedback
          </Tooltip>
        </HStack>
        <TabBar
          behaviour="scroll"
          variant="segmented fit"
          value={selectedTab.title}
          onChange={tab => {
            setSelectedTab(tabs.find(t => t.title === tab)!)
          }}
        >
          {tabs
            .filter(({ shown }) => shown)
            .map(tab => (
              <TabBar.Item to={tab.title} key={tab.title}>
                {tab.title}
              </TabBar.Item>
            ))}
        </TabBar>
        <Box minWidth={deliverablesMinWidth}>{selectedTab.component}</Box>
      </VStack>
    </Cell>
  )
}
