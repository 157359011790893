import React from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Details from './Details'
import AffectedCandidates from './AffectedCandidates'
import { automationRuleRequest } from '@src/api/jobPostings/automation'
import { Route, Switch, useParams } from 'react-router-dom'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AutomationInterface } from '@src/interfaces/jobPostings/automation'
import { Box, chain } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import upperFirst from 'lodash/upperFirst'
import { Status } from '@components/CommonSC/General'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'

const JobPostingAutomationRule = () => {
  const params = useParams<{ id?: string }>()
  const { values } = useLapeContext<AutomationInterface>()
  const backUrl = ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS

  const tabs = [
    {
      title: 'Details',
      path: ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.DETAILS, params),
      component: Details,
      canView: true,
    },
    {
      title: 'Affected candidates',
      path: ROUTES.FORMS.JOB_POSTING_AUTOMATION.CANDIDATES,
      to: pathToUrl(ROUTES.FORMS.JOB_POSTING_AUTOMATION.CANDIDATES, params),
      component: AffectedCandidates,
      quickSummary: <QuickSummaryCount count={values.candidates?.length} />,
      canView: !!params.id,
    },
  ].filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={
          values.id
            ? chain(
                values.name,
                values.status && (
                  <Status status={values.status.id}>
                    {upperFirst(values.status.name)}
                  </Status>
                ),
              )
            : 'Create new automation rule'
        }
        backUrl={backUrl}
      >
        {tabs.length > 1 && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={automationRuleRequest}>
    <JobPostingAutomationRule />
  </Form>
))
