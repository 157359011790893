import { GetRequestInterface, RequestInterface, Statuses } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { EmployeeInterface } from '@src/interfaces/employees'
import { HistoryTooltipData, HistoryTypes } from '@components/Stepper/HistoryTooltip'
import { ChangelogApi, FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { differenceInDays, format, startOfToday } from 'date-fns'
import { KpiInterface } from '@src/interfaces/kpis'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { useFetch } from '@src/utils/reactQuery'

export const changelogEmployeeRequests = (
  employeeId: number,
): RequestInterface<ChangelogInterface<EmployeeInterface>> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}/${id}`, undefined),
  patchItem: async (data, id) =>
    api.patch(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}/${id}`, data, undefined),
  deleteItem: async id =>
    api.delete(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}/${id}`, undefined),
  postItem: async data =>
    api.post(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}`, data, undefined),
})

export const fieldChangelogEmployeeRequests = (
  employeeId: number,
): RequestInterface<ChangelogInterface<EmployeeInterface>> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  patchItem: async (data, kpiId) =>
    api.patch(
      `${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}/${kpiId}`,
      data,
      undefined,
    ),
  deleteItem: async id =>
    api.delete(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  postItem: async data =>
    api.post(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}`, data, undefined),
})

export const useGetEmployeeChangelogFields = (
  employeeId: string | number | undefined,
  filters?: FilterByInterface[],
  enabled?: boolean,
) =>
  useFetch<GetRequestInterface<ChangelogInterface<EmployeeInterface>>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}${API.CHANGELOG_FIELDS}` : null,
    undefined,
    filters ? { params: filterSortPageIntoQuery(undefined, filters, 1) } : undefined,
    undefined,
    { enabled },
  )

export const optimizedSeniorityChangelogRequests = (
  employeeId: number,
): RequestInterface<ChangelogInterface<EmployeeInterface>> => ({
  ...fieldChangelogEmployeeRequests(employeeId),
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/seniorityHistory`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const optimizedSpecialisationHistoryRequests = (
  employeeId: number,
): RequestInterface<ChangelogInterface<EmployeeInterface>> => ({
  ...fieldChangelogEmployeeRequests(employeeId),
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/specialisationHistory`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const salaryChangelogRequests = (
  employeeId: number,
  reviewId: number,
): RequestInterface<ChangelogInterface<EmployeeInterface>> => ({
  ...fieldChangelogEmployeeRequests(employeeId),
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEE_COMPENSATION_REVIEWS}/${reviewId}/salaryChangelog`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const changelogKPIRequests: RequestInterface<ChangelogInterface<KpiInterface>> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.KPIS}${API.CHANGELOG}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.KPIS}${API.CHANGELOG}/${id}`, undefined),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.KPIS}${API.CHANGELOG}/${kpiId}`, data, undefined),
  deleteItem: async id => api.delete(`${API.KPIS}${API.CHANGELOG}/${id}`, undefined),
  postItem: async data => api.post(`${API.KPIS}${API.CHANGELOG}`, data, undefined),
}

export const fieldChangelogKPIRequests: RequestInterface<
  ChangelogInterface<KpiInterface>
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.KPIS}${API.CHANGELOG_FIELDS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.KPIS}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  patchItem: async (data, kpiId) =>
    api.patch(`${API.KPIS}${API.CHANGELOG_FIELDS}/${kpiId}`, data, undefined),
  deleteItem: async id =>
    api.delete(`${API.KPIS}${API.CHANGELOG_FIELDS}/${id}`, undefined),
  postItem: async data => api.post(`${API.KPIS}${API.CHANGELOG_FIELDS}`, data, undefined),
}

export const changelogRequisitionRequests: RequestInterface<
  ChangelogInterface<RequisitionInterface>
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.REQUISITIONS}${API.CHANGELOG}`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id =>
    api.get(`${API.REQUISITIONS}${API.CHANGELOG}/${id}`, undefined, 'v2'),
  patchItem: async (data, id) =>
    api.patch(`${API.REQUISITIONS}${API.CHANGELOG}/${id}`, data, undefined, 'v2'),
  deleteItem: async id =>
    api.delete(`${API.REQUISITIONS}${API.CHANGELOG}/${id}`, undefined, 'v2'),
  postItem: async data =>
    api.post(`${API.REQUISITIONS}${API.CHANGELOG}`, data, undefined, 'v2'),
}

export const fieldChangelogRequisitionRequests: RequestInterface<
  ChangelogInterface<RequisitionInterface>
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.REQUISITIONS}${API.CHANGELOG_FIELDS}`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getItem: async id =>
    api.get(`${API.REQUISITIONS}${API.CHANGELOG_FIELDS}/${id}`, undefined, 'v2'),
  patchItem: async (data, id) =>
    api.patch(`${API.REQUISITIONS}${API.CHANGELOG_FIELDS}/${id}`, data, undefined, 'v2'),
  deleteItem: async id =>
    api.delete(`${API.REQUISITIONS}${API.CHANGELOG_FIELDS}/${id}`, undefined, 'v2'),
  postItem: async data =>
    api.post(`${API.REQUISITIONS}${API.CHANGELOG_FIELDS}`, data, undefined, 'v2'),
}

export const changelogRoadmapRequests: RequestInterface<
  ChangelogInterface<RequisitionInterface>
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ROADMAPS}${API.CHANGELOG}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.ROADMAPS}${API.CHANGELOG}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.ROADMAPS}${API.CHANGELOG}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ROADMAPS}${API.CHANGELOG}/${id}`),
  postItem: async data => api.post(`${API.ROADMAPS}${API.CHANGELOG}`, data),
}

export const fieldChangelogRoadmapRequests: RequestInterface<
  ChangelogInterface<RequisitionInterface>
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.ROADMAPS}${API.CHANGELOG_FIELDS}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.ROADMAPS}${API.CHANGELOG_FIELDS}/${id}`),
  patchItem: async (data, id) =>
    api.patch(`${API.ROADMAPS}${API.CHANGELOG_FIELDS}/${id}`, data),
  deleteItem: async id => api.delete(`${API.ROADMAPS}${API.CHANGELOG_FIELDS}/${id}`),
  postItem: async data => api.post(`${API.ROADMAPS}${API.CHANGELOG_FIELDS}`, data),
}

export const cancelEmployeeFieldsRequest = (id: number, employeeId: number) =>
  api.patch(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}/${id}/cancel`)

export const cancelEmployeeFieldRequest = (
  id: string,
  fieldId: number,
  employeeId: number,
) =>
  api.patch(`${API.EMPLOYEES}/${employeeId}${API.CHANGELOG}/${id}/cancelField/${fieldId}`)

export const getPendingKpiChangesRequest = (id: number) =>
  api.get<ChangelogInterface<KpiInterface>>(`${API.KPIS}/${id}${API.CHANGELOG}`)

export const getPendingRequisitionChangesRequest = (id: number) =>
  api.get<ChangelogInterface<RequisitionInterface>>(
    `${API.REQUISITIONS}/${id}${API.CHANGELOG}`,
    undefined,
    'v2',
  )

export const checkEmployeeConflictingFields = (
  id: number | string,
  data: Partial<EmployeeInterface>,
) => api.post(`${API.EMPLOYEES}/${id}/checkConflicts`, data)

export const getHistoryRequest =
  (
    values: any,
    fieldName: string,
    changelogApi: ChangelogApi,
    alwaysMarkFirstAsCurrent?: boolean,
  ) =>
  async (): Promise<HistoryTooltipData[]> => {
    const result = await changelogApi.field.getItems({
      filters: [
        {
          columnName: 'target__id',
          filters: [
            {
              id: values.id,
              name: values.full_name,
            },
          ],
        },

        {
          columnName: 'status',
          filters: [
            {
              id: 'pending',
              name: 'pending',
            },
            {
              id: 'completed',
              name: 'completed',
            },
          ],
        },
        {
          columnName: 'field_name',
          filters: [
            {
              id: fieldName,
              name: fieldName,
            },
          ],
        },
      ],

      sortBy: [
        {
          direction: SORT_DIRECTION.ASC,
          sortBy: 'effective_date_time',
        },
      ],
    })

    const FORMAT = 'd MMM yyyy'

    let currentFlag = false

    return (
      result?.data?.results?.slice(0, 4)?.map(c => {
        if (c.status?.id === Statuses.pending) {
          if (c.effective_date_time) {
            const isChangeTimePassed =
              differenceInDays(new Date(c.effective_date_time), startOfToday()) <= 0

            return {
              type: HistoryTypes.Future,
              name: `${c.field_display_value} ${
                isChangeTimePassed ? 'on' : 'scheduled for'
              } ${format(new Date(c.effective_date_time), FORMAT)} by ${
                c.created_by.display_name
              }`,
            }
          }
          return {
            type: HistoryTypes.Future,
            name: `${c.field_display_value} on ${format(
              new Date(c.updated_date_time),
              FORMAT,
            )} (pending)`,
          }
        }

        if (
          !currentFlag &&
          (c.field_to_change?.[c.field_name] === values[c.field_name] ||
            c.field_to_change?.[c.field_name]?.id === values[c.field_name]?.id ||
            alwaysMarkFirstAsCurrent) &&
          c.status?.id === Statuses.completed
        ) {
          currentFlag = true
          return {
            type: HistoryTypes.Current,
            name: `${c.field_display_value} on ${format(
              new Date(c.effective_date_time),
              FORMAT,
            )} by ${c.created_by.display_name} (current)`,
          }
        }

        return {
          type: HistoryTypes.Past,
          name: `${c.field_display_value} on ${format(
            new Date(c.effective_date_time),

            FORMAT,
          )} by ${c.created_by.display_name}`,
        }
      }) || []
    )
  }
