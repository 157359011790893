import React, { useMemo } from 'react'
import isNil from 'lodash/isNil'
import { KpiInterface, KPIPerformanceTypes } from '@src/interfaces/kpis'
import { renderParentSelectorOption } from '@src/utils/kpi'
import { Statuses } from '@src/interfaces'
import { getKPIsParentSelector, getKPIsSelector } from '@src/api/kpis'
import { rolesRequests } from '@src/api/roles'
import { companyGoalRequests } from '@src/api/companyGoals'
import { RadioSelectInputProps } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { KPITypes } from '@src/constants/table'
import { functionsRequests } from '@src/api/functions'

interface Props extends RadioSelectInputProps<KpiInterface> {
  kpi: KpiInterface
  cycleOffset?: number | string
}

const getCommonFilters = (cycleOffset: number | string) => [
  {
    filters: [{ name: KPIPerformanceTypes.business, id: KPIPerformanceTypes.business }],
    columnName: 'kpi_performance_type',
  },
  {
    filters: [{ name: `${cycleOffset}`, id: `${cycleOffset}` }],
    columnName: 'review_cycle__offset',
  },
  {
    filters: [{ name: Statuses.active, id: Statuses.active }],
    columnName: 'status',
  },
]

const getRoleParentSelector =
  (roleId: number, cycleOffset: number | string) => async () => {
    const { data: role } = await rolesRequests.getItem(roleId)
    const filters = [
      ...getCommonFilters(cycleOffset),
      {
        filters: [{ name: `${role?.function?.id}`, id: role.function.id }],
        columnName: 'function__id',
      },
    ]
    const result = await getKPIsSelector({ filters })
    return result.data
  }

const getFunctionParentSelector =
  (functionId: number, cycleOffset: number | string) => async () => {
    const { data: functionData } = await functionsRequests.getItem(functionId)
    const filters = [
      ...getCommonFilters(cycleOffset),
      {
        filters: [{ name: `${functionData.id}`, id: functionData.id }],
        columnName: 'function__id',
      },
    ]
    const result = await getKPIsSelector({ filters })
    return result.data
  }

const getEntityParentSelector =
  (cycleOffset: number | string, type: KPITypes, entityId: number) => async () => {
    const result = await getKPIsParentSelector(cycleOffset, type, entityId)
    return result.data
  }

const getCompanyGoalsSelector = (cycleOffset: number | string) => async () => {
  const result = await companyGoalRequests.getItems({
    filters: getCommonFilters(cycleOffset),
  })
  return result.data.results
}

export const getKPIParentSelector = (
  values: KpiInterface,
  cycleOffset?: number | string,
) => {
  if (isNil(cycleOffset)) {
    return undefined
  }
  if (values.is_global || values.is_company) {
    return getCompanyGoalsSelector(cycleOffset)
  }

  let entityId
  if (values.is_employee) {
    entityId = values.owner.id
  } else {
    entityId = values.department?.id || values.team?.id
  }

  if (entityId) {
    return getEntityParentSelector(cycleOffset, values.kpi_type.id, entityId)
  }

  const roleId = values.role?.id
  if (roleId) {
    return getRoleParentSelector(roleId, cycleOffset)
  }
  const functionId = values.function?.id
  if (functionId) {
    return getFunctionParentSelector(functionId, cycleOffset)
  }

  return undefined
}

const ParentSelector = ({ kpi, cycleOffset, ...props }: Props) => {
  const selector = useMemo(
    () => getKPIParentSelector(kpi, cycleOffset),
    [kpi, cycleOffset],
  )

  if (kpi.function) {
    return null
  }

  return (
    <LapeRadioSelectInput<KpiInterface>
      loading={isNil(cycleOffset)}
      label={kpi.is_company ? 'Company Goal' : 'Parent KPI'}
      selector={selector}
      message="The parent KPI is the higher level metric which your KPI impacts"
      {...props}
      name="parent_kpi"
    >
      {kpi.is_company ? undefined : renderParentSelectorOption}
    </LapeRadioSelectInput>
  )
}

export default ParentSelector
