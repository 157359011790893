import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PaymentDetails } from './PaymentDetails'
import { Invoices } from './Invoices/Invoices'

export const SubscriptionAndPayments = () => {
  return (
    <PageWrapper>
      <PageHeader title="Subscription And Payments" backUrl={ROUTES.SETTINGS.ALL} />

      <TabBarNavigation
        tabs={[
          {
            title: 'Payment details',
            path: ROUTES.PLANS.PAYMENT_DETAILS,
            to: ROUTES.PLANS.PAYMENT_DETAILS,
          },
          {
            title: 'Invoices',
            path: ROUTES.PLANS.INVOICES,
            to: ROUTES.PLANS.INVOICES,
          },
        ]}
        mb="s-16"
      />

      <Switch>
        <Route exact path={ROUTES.PLANS.PAYMENT_DETAILS}>
          <PaymentDetails />
        </Route>
        <Route exact path={ROUTES.PLANS.INVOICES}>
          <Invoices />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
