import { ReviewCategory } from '@src/interfaces/performance'
import React from 'react'
import { TabBar } from '@revolut/ui-kit'

interface Props {
  onClick: (category: ReviewCategory) => void
  category?: ReviewCategory
}

export const CategoryFilter = ({ onClick, category }: Props) => {
  return (
    <TabBar>
      <TabBar.Item
        aria-selected={category === ReviewCategory.Performance}
        onClick={() => {
          onClick(ReviewCategory.Performance)
        }}
      >
        Performance
      </TabBar.Item>
      <TabBar.Item
        aria-selected={category === ReviewCategory.Upwards}
        onClick={() => {
          onClick(ReviewCategory.Upwards)
        }}
      >
        Upwards review
      </TabBar.Item>
    </TabBar>
  )
}

export default CategoryFilter
