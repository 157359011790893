import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'

export const successNotification = (message: string, backUrl?: string) => {
  pushNotification({
    value: message,
    duration: SUCCESS_DEFAULT_DURATION,
    type: NotificationTypes.success,
    backUrl,
  })
}
