import { Statuses } from '@src/interfaces'
import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const InterviewScorecardTemplateSaveToDraftButton = () => {
  const { values, submit } = useLapeContext<InterviewScorecardTemplateInterface>()

  if (values.status === Statuses.completed) {
    return null
  }

  const onSaveDraft = () => {
    values.status = Statuses.draft

    return submit().then(result => {
      navigateReplace(
        pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
          id: result.id,
        }),
      )
    })
  }

  return (
    <NewSaveButtonWithPopup
      variant="secondary"
      successText="Saved to drafts"
      onClick={onSaveDraft}
    >
      Save to Drafts
    </NewSaveButtonWithPopup>
  )
}

export default connect(InterviewScorecardTemplateSaveToDraftButton)
