import React from 'react'
import styled from 'styled-components'
import { Box, BoxProps } from '@revolut/ui-kit'

const GridInner = styled(Box)<GridProps>`
  display: grid;
  grid-gap: ${props => (props.gap ? `${props.gap}px` : 'none')};
  grid-auto-flow: ${props => props.flow || 'row'};
  align-content: ${props => props.alignContent || 'initial'};
  align-items: ${props => props.alignItems || 'initial'};
  justify-content: ${props => props.justifyContent || 'initial'};
  justify-items: ${props => props.justifyItems || 'initial'};
`

interface GridProps {
  gap?: number
  flow?: 'column' | 'row'
  alignContent?: 'start' | 'center' | 'end'
  alignItems?: 'start' | 'center' | 'end' | 'stretch'
  justifyContent?: 'start' | 'center' | 'end' | 'space-between'
  justifyItems?: 'start' | 'center' | 'end'
}

interface Props extends Omit<BoxProps, 'theme'>, GridProps {}

export const Grid = (props: Props) => <GridInner {...props} />
