import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import React from 'react'
import PermissionTransferButton, {
  PermissionTransferButtonProps,
  PermissionTransferButtonValuesInterface,
} from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'

interface PermissionTransferFormButtonProps<T>
  extends Omit<PermissionTransferButtonProps<T>, 'values'> {
  path: string
}

const PermissionTransferFormButton = <T extends {}>(
  props: PermissionTransferFormButtonProps<T>,
) => {
  const { values } = useLapeContext<PermissionTransferButtonValuesInterface>()
  return <PermissionTransferButton {...props} values={values} />
}

export default connect(PermissionTransferFormButton)
