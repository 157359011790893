import React, { useMemo } from 'react'
import { Box, Text, Token, VStack } from '@revolut/ui-kit'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import {
  InterviewResult,
  InterviewFeedbackLevelsPoints,
  InterviewFeedbackSenioritySectionInterface,
} from '@src/interfaces/interviewTool'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import { PerformanceRating } from '@src/interfaces/performance'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { ResultHireSection } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/ResultHireSection'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SectionHeader'
import { RadioItem } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/RadioItem'

export interface ResultLevelPoints {
  basic?: number | null
  intermediate?: number | null
  advanced?: number | null
  expert?: number | null
}

export const getResultLevelPoints = (
  values?: InterviewFeedbackLevelsPoints,
): ResultLevelPoints | null =>
  values
    ? {
        basic: values.basic_points,
        intermediate: values.intermediate_points,
        advanced: values.advanced_points,
        expert: values.expert_points,
      }
    : null

type Props = {
  seniorities?: InterviewFeedbackSenioritySectionInterface
  type?: InterviewScorecardType
  levelsPoints?: InterviewFeedbackLevelsPoints
  suggestedLevel?: PerformanceRating
  isViewMode?: boolean
  deviationJustificationRequired?: boolean
  suggestedResult?: InterviewResult
}

export const ResultEvaluationSection = ({
  suggestedLevel,
  suggestedResult,
  type,
  seniorities,
  isViewMode,
  levelsPoints,
  deviationJustificationRequired,
}: Props) => {
  const { options, asyncState } = useFetchOptions<RadioOption>(
    selectorKeys.interview_feedback_result_choices,
  )

  const disabled = asyncState !== 'ready' || isViewMode
  const levels = getResultLevelPoints(levelsPoints)
  const seniorityOptions = useMemo(() => {
    const items: RadioOption[] = seniorities?.options?.length
      ? [
          ...(seniorities?.options.map(o => ({
            label: o.seniority.name,
            value: o,
          })) || []),
          {
            label: 'Can’t say',
            value: null,
          },
        ]
      : []

    return items
  }, [seniorities])

  const levelsOptions = useMemo<RadioOption[] | null>(() => {
    if (!levels) {
      return null
    }

    return [
      {
        label: `Poor${
          Number.isFinite(levels.basic) ? ` (below ${levels.basic} points)` : ''
        }`,
        value: PerformanceRating.poor,
      },
      {
        label: `Basic${Number.isFinite(levels.basic) ? ` (${levels.basic} points)` : ''}`,
        value: PerformanceRating.basic,
      },
      {
        label: `Intermediate${
          Number.isFinite(levels.intermediate) ? ` (${levels.intermediate} points)` : ''
        }`,
        value: PerformanceRating.intermediate,
      },
      {
        label: `Advanced${
          Number.isFinite(levels.advanced) ? ` (${levels.advanced} points)` : ''
        }`,
        value: PerformanceRating.advanced,
      },
      {
        label: `Expert${
          Number.isFinite(levels.expert) ? ` (${levels.expert} points)` : ''
        }`,
        value: PerformanceRating.expert,
      },
    ]
  }, [levels])

  return (
    <>
      <ResultHireSection
        disabled={disabled}
        options={options}
        suggestedResult={suggestedResult}
        suggestedLevel={suggestedLevel}
      />
      {levelsOptions && (
        <>
          {type === InterviewScorecardType.General && (
            <Box>
              <SectionHeader title="What is the candidate’s level according to your evaluation?" />

              <LapeNewRadioButtons
                name="rating"
                options={levelsOptions}
                readOnly={disabled}
                renderRadio={(radio, { value }) => (
                  <RadioItem
                    value={value}
                    radio={radio}
                    recommendedValue={suggestedLevel}
                  />
                )}
              />
            </Box>
          )}
          {!!seniorityOptions.length && (
            <Box>
              <SectionHeader title="What is the candidate’s seniority according to your evaluation?" />

              <LapeNewRadioButtons
                name="scorecard.seniority_section.answer"
                options={seniorityOptions}
                readOnly={disabled}
                renderRadio={radio => <Box py="s-8">{radio}</Box>}
              />
            </Box>
          )}
        </>
      )}

      {deviationJustificationRequired && (
        <VStack gap="s-8">
          <Text variant="body2" color={Token.color.warning}>
            It is required to provide a justification when deviating from system
            recommendation
          </Text>
          <LapeNewTextArea
            name="deviation_justification"
            label="Why did you deviate from system recommendations"
            rows={3}
            readOnly={disabled}
            required
            data-testid="deviation_justification"
          />
        </VStack>
      )}
    </>
  )
}
