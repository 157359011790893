import React, { useState } from 'react'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { CreateEntityInterface } from '@src/interfaces/enitities'
import { createEntity } from '@src/api/entities'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'

interface CreateEntityPopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
}
export const CreateEntityPopup = ({
  open,
  onSuccess,
  onClose,
}: CreateEntityPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Add new entity</Header.Title>
      </Header>

      <LapeForm<CreateEntityInterface>
        onSubmit={form => {
          setPending(true)

          return createEntity(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateEntityForm />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

const CreateEntityForm = () => {
  const { isSubmitting, submit } = useLapeContext<CreateEntityInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="registration_country"
          label="Registration country"
          selector={selectorKeys.countries}
        />
        <LapeNewInput name="registration_number" label="Registration number" required />
        <LapeNewTextArea name="description" label="Description" rows={4} />
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create entity</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
