import React from 'react'
import { OnboardingIntro } from '@components/OnboardingIntro/OnboardingIntro'
import { OnboardingIntroTips } from '@components/OnboardingIntro/OnboardingIntroTips'
import { FormattedMessage } from 'react-intl'

export const TimeManagementIntro = () => {
  return (
    <OnboardingIntro
      videoUrl="/intros/Intro%20-%20Time%20Off.mp4"
      videoPosterUrl="/intros/Intro%20-%20Time%20Off.png"
    >
      <OnboardingIntroTips
        title={
          <FormattedMessage
            id="onboardingV2.timeManagement.intro.tips.title"
            defaultMessage="Create time off policies that match your needs"
          />
        }
        text={
          <FormattedMessage
            id="onboardingV2.timeManagement.intro.tips.text"
            defaultMessage="Configure time off to suit legal requirements or your company’s HR policies. Assign time off policies and adjust balances for accuracy."
          />
        }
        items={[
          {
            title: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.create.title"
                defaultMessage="Create time off policies"
              />
            ),
            icon: 'Document',
            text: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.create.text"
                defaultMessage="Create policies which define how much leave an employee earns, who needs to approve their time off and more."
              />
            ),
          },
          {
            title: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.assign.title"
                defaultMessage="Assign policies automatically or manually"
              />
            ),
            icon: 'People',
            text: (
              <FormattedMessage
                id="onboardingV2.timeManagement.intro.tips.assign.text"
                defaultMessage="After a policy is created, decide who this policy is assigned to by using filters or groups. For example assign an Annual leave policy to everyone in the UK who is a senior. From there on out, anyone who fits this criteria will be assigned the policy."
              />
            ),
          },
        ]}
      />
    </OnboardingIntro>
  )
}
