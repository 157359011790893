import React from 'react'
import { useCustomHiringProcessFlag } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/utils'
import HiringProcess from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcess'
import { HiringProcessesTable } from '@src/pages/Forms/SpecialisationForm/HiringProcesses/HiringProcessesTable'

export const HiringProcessesPage = () => {
  const customHiringProcesses = useCustomHiringProcessFlag()
  if (!customHiringProcesses) {
    return <HiringProcess />
  }
  return <HiringProcessesTable />
}
