import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import { ChangeScorecardInterface, SingleChoice } from '@src/interfaces/performance'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { Link } from 'react-router-dom'
import { Box, Text, Token } from '@revolut/ui-kit'
import { PerformanceRatingTitle } from '@src/constants/performance'

const Container = styled.div`
  margin-top: 20px;
  display: grid;
  grid-row-gap: 20px;
`

const Option = styled.div`
  position: relative;
  width: 100%;
`

const Amount = styled.div`
  position: absolute;
  font-size: 12px;
  left: -11px;
  top: 3px;
`

const TooltipReviewer = styled(Link)`
  color: ${Token.color.background};
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

const TooltipContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 4px;
  padding: 11px;
  font-size: 11px;
  color: ${Token.color.background};
`

const TooltipTitle = styled.div`
  font-weight: bold;
  text-transform: capitalize;
`

const TextCss = css`
  text-transform: none;
`

interface ScorecardRadioSectionProps {
  content: SingleChoice
  viewMode?: boolean
  cardIndex: number
  sectionIndex: number
  onChange?: (params: ChangeScorecardInterface) => void
  showLimitedOptions?: boolean
}

interface ReviewerListInterface {
  review: { reviewer: { id: number; display_name?: string }; checkpointNum?: number }
}

export const RadioTooltip = ({
  children,
  reviewerList,
}: {
  children: string
  reviewerList: ReviewerListInterface[]
}) => {
  return (
    <TooltipContainer>
      <TooltipTitle>{children}</TooltipTitle>
      {reviewerList.map(review => {
        const checkpointNum = review.review.checkpointNum
        return (
          <TooltipReviewer
            key={review.review.reviewer.id}
            to={getLocationDescriptor(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: review.review.reviewer.id,
              }),
            )}
          >
            {review.review.reviewer.display_name}
            {!!checkpointNum && ` (CP${checkpointNum})`}
          </TooltipReviewer>
        )
      })}
    </TooltipContainer>
  )
}

const ScorecardRadioSection = ({
  content,
  cardIndex,
  sectionIndex,
  onChange,
  viewMode,
  showLimitedOptions = false,
}: ScorecardRadioSectionProps) => {
  const amountRefs = useRef<HTMLSpanElement[]>([])
  // TODO: this is a hack around using both old and new scorecards UI in one review cycle
  //  will be removed once new scorecards are released for everyone
  const options = showLimitedOptions
    ? content.options.filter(option =>
        ['dont_know', 'basic', 'intermediate'].includes(option.key),
      )
    : content.options

  return (
    <Container>
      {options.map((option, optionIndex) => {
        const reviews = viewMode
          ? content.values?.filter(val => val.value === option.key) || []
          : []
        return (
          <Tooltip
            hide={!reviews.length}
            body={<RadioTooltip reviewerList={reviews}>{option.key}</RadioTooltip>}
            placement="top"
            anchor={amountRefs.current?.[optionIndex] as HTMLElement}
            key={optionIndex}
          >
            <Option>
              {reviews.length ? (
                <Amount>
                  <span
                    ref={ref => {
                      if (ref) {
                        amountRefs.current[optionIndex] = ref
                      }
                    }}
                  >
                    {reviews.length}
                  </span>
                </Amount>
              ) : null}
              <RadioButton
                label={
                  <Box ml="s-16">
                    <Text use="div" color="foreground" fontWeight={500} lineHeight={1.6}>
                      {PerformanceRatingTitle[option.key]}
                    </Text>
                    <Text use="div" color="grey-tone-50" lineHeight={1.6} css={TextCss}>
                      {option.text}
                    </Text>
                  </Box>
                }
                checked={
                  viewMode
                    ? !!content.values?.some(val => option.key === val.value)
                    : option.key === content.value
                }
                onChange={() => {
                  onChange &&
                    onChange({ cardIndex, sectionIndex, optionIndex, value: option.key })
                }}
                name={`radio_${sectionIndex}_${cardIndex}_${optionIndex}`}
                alignTop
              />
            </Option>
          </Tooltip>
        )
      })}
    </Container>
  )
}

export default ScorecardRadioSection
