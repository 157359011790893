import { action } from 'typesafe-actions'
import { AuthActionTypes, FeatureFlags, PermissionTypes } from './types'
import { EmployeeInterface } from '@src/interfaces/employees'
import { SubscriptionState } from '@src/interfaces/auth'

export const setApiTokenAction = (token: string) =>
  action(AuthActionTypes.SET_API_TOKEN, token)
export const testLogin = (token: string) => action(AuthActionTypes.TEST_LOGIN, token)
export const getLoginUrlAction = () => action(AuthActionTypes.GET_LOGIN_URL)
export const setLoginUrlAction = (loginUrl: string) =>
  action(AuthActionTypes.SET_LOGIN_URL, loginUrl)
export const verifyApiTokenAction = () => action(AuthActionTypes.VERIFY_API_TOKEN)
export const logOutAction = () => action(AuthActionTypes.LOG_OUT)
export const logInAction = () => action(AuthActionTypes.LOG_IN)
export const setUserAction = (employee: EmployeeInterface) =>
  action(AuthActionTypes.SET_USER, employee)
export const setSubscriptionStateAction = (state?: SubscriptionState) =>
  action(AuthActionTypes.SET_SUBSCRIPTION_STATE, state)
export const setAuthenticatedAction = (status: boolean) =>
  action(AuthActionTypes.SET_AUTHENTICATED, status)
export const setPermissionsAction = (permissions: PermissionTypes[]) =>
  action(AuthActionTypes.SET_PERMISSIONS, permissions)
export const setFeatureFlagsAction = (featureFlags: FeatureFlags[]) =>
  action(AuthActionTypes.SET_FEATURE_FLAGS, featureFlags)
