import React from 'react'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import {
  KPIsCardTable,
  KPIsCardTableProps,
} from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'
import { EmployeeGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/EmployeeGoalsCardTable'

export const IndividualGoalsList = ({ ...props }: KPIsCardTableProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)

  return goalsEnabled ? (
    <EmployeeGoalsCardTable {...props} />
  ) : (
    <KPIsCardTable {...props} />
  )
}
