import React from 'react'
import { getOneToOneCommentsApi } from '@src/api/meetings'
import ChatSidebar from '@components/Chat/ChatSidebar'
import { ChatMessageType } from '@components/Chat/common'

interface Props {
  meetingId: number
  onClose: () => void
}

const MeetingComments = ({ meetingId, onClose }: Props) => {
  const { useGetComments, ...api } = getOneToOneCommentsApi(meetingId)
  const comments = useGetComments()

  return (
    <ChatSidebar
      disableTodolistFeature
      isOpen
      onClose={onClose}
      data={comments.data?.results || null}
      isLoading={comments.isLoading}
      refetch={comments.refetch}
      onAddMessage={api.addComment}
      onEdit={api.editComment}
      onArchive={api.archiveComment}
      type={ChatMessageType.Comment}
      onResolve={() => Promise.resolve()}
    />
  )
}

export default MeetingComments
