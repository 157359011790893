import React from 'react'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { getPrefilledPlaceholdersEmail } from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendEngagementEmailInterface } from '@src/interfaces/CRM'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'

type EmailFormProps = {
  actions: React.ReactNode
}

const EmailForm = ({ actions }: EmailFormProps) => {
  useLapeContext<SendEngagementEmailInterface>()
  return (
    <SendEmailCommon
      actions={actions}
      insideSidebar
      fetchEmail={(templateId: number) => {
        return getPrefilledPlaceholdersEmail(templateId)
      }}
      emailFormProps={{
        beforeEditor: (
          <LapeNewSwitch
            name="add_booking_link"
            itemTypeProps={{
              title: 'Add booking link',
              description:
                'You can add a link for the candidate to book a call with you. After booking, candidate would be automatically unarchived in the latest main interview round. The type of the call would be “Catch-up call”',
            }}
          />
        ),
      }}
    />
  )
}

export default EmailForm
