import React from 'react'
import { Widget, Placeholder, BoxProps } from '@revolut/ui-kit'
import SuccessImage from '@src/assets/3D/Success.png'
import PendingImage from '@src/assets/3D/NewtonCradle.png'
import FailedImage from '@src/assets/3D/Alert.png'
import PlannedImage from '@src/assets/3D/Calendar.png'

interface Props extends BoxProps {
  type?: 'success' | 'error' | 'pending' | 'planned'
  title?: string
  text?: React.ReactNode
  actions?: React.ReactNode
}

const SuccessWidget = ({ type = 'success', title, text, actions, ...rest }: Props) => {
  let imageUrl = ''
  switch (type) {
    case 'error':
      imageUrl = FailedImage
      break
    case 'pending':
      imageUrl = PendingImage
      break
    case 'planned':
      imageUrl = PlannedImage
      break
    case 'success':
    default:
      imageUrl = SuccessImage
  }

  return (
    <Widget maxWidth={400} p="36px" {...rest}>
      <Placeholder>
        <Placeholder.Image src={imageUrl} />
        {title && <Placeholder.Title>{title}</Placeholder.Title>}
        {text && <Placeholder.Description>{text}</Placeholder.Description>}
        {actions && actions}
      </Placeholder>
    </Widget>
  )
}

export default SuccessWidget
