import React from 'react'
import { chain, InputGroup, Text } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { KeyPersonResponsibilityInterface } from '@src/interfaces/keyPerson'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useKeyPersonCategories } from '@src/api/keyPerson'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { getResponsibilityStatusColor } from '@src/apps/People/KeyPersons/helpers'

export const EditResponsibility = () => {
  const { initialValues, values } = useLapeContext<KeyPersonResponsibilityInterface>()
  const { data: categoriesOptions, isLoading: isCategoriesOptionsLoading } =
    useKeyPersonCategories()

  return (
    <>
      <PageHeader
        title={values.id ? 'Edit responsibility' : 'Create responsibility'}
        subtitle={
          values.id
            ? chain(
                initialValues.name,
                <Text color={getResponsibilityStatusColor(values.status?.id)}>
                  {values.status?.name}
                </Text>,
              )
            : undefined
        }
        backUrl={
          values.id
            ? pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW, {
                id: String(values.id),
              })
            : ROUTES.APPS.KEY_PERSONS.RESPONSIBILITIES_TABLE
        }
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Basic" />
          <InputGroup>
            <LapeNewInput required name="name" label="Name" />
            <LapeRadioSelectInput
              name="category"
              label="Category"
              loading={isCategoriesOptionsLoading}
              selector={() =>
                Promise.resolve(categoriesOptions?.results as OptionInterface[])
              }
            />
            <LapeNewTextArea name="description" label="Description" rows={3} />
          </InputGroup>

          <NewStepperTitle title="Succession" />
          <InputGroup>
            <LapeRadioSelectInput
              name="succession_plan"
              label="Does this responsibility require succession plan?"
              options={[
                { label: 'Optional', value: { id: 'optional', name: 'Optional' } },
                { label: 'Mandatory', value: { id: 'mandatory', name: 'Mandatory' } },
              ]}
            />
          </InputGroup>

          <NewStepperTitle title="Advanced settings" />
          <InputGroup>
            <LapeNewMultiSelect
              required
              name="required_for_entities"
              placeholder="Required for"
              selector={selectorKeys.entity}
            />
            <LapeNewMultiSelect
              required
              name="expected_for_specialisations"
              placeholder="Expected for"
              selector={selectorKeys.specialisations}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          previewUrl={ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW}
          successText={`Responsibility successfully ${values.id ? 'updated' : 'created'}`}
        />
      </PageActions>
    </>
  )
}
