import React from 'react'
import { Box, Checkbox, Item, Tag, Text, VStack } from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

interface Props {
  action: GrowthPlanActionInterface
  disabled: boolean
  onClick: VoidFunction
}

export const ActionItem = ({ action, disabled, onClick }: Props) => {
  return (
    <Item use="label" disabled={disabled} onClick={onClick}>
      <Item.Prefix>
        <Checkbox value={action.id} checked={action.is_completed} disabled={disabled} />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>
          <VStack>
            {action.description.split('\n').map((line, index) => (
              <Text key={index}>{line.trim()}</Text>
            ))}
          </VStack>
        </Item.Title>
        {action.object_id && action.content_object && (
          <Box mt="s-8">
            <Tag useIcon="Palette">{action.content_object.name}</Tag>
          </Box>
        )}
      </Item.Content>
    </Item>
  )
}
