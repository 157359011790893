import React from 'react'
import { useSelector } from 'react-redux'
import { FilterButton } from '@revolut/ui-kit'

import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { getOnboardingQueue, getOnboardingQueueStats } from '@src/api/onboardingEmployees'
import { useGetSelectors } from '@src/api/selectors'
import { useGetLifecycleSettings } from '@src/api/settings'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { LOCAL_STORAGE, selectorKeys } from '@src/constants/api'
import {
  onboardingQueueEmployeeColumn,
  onboardingQueueEmployeeStatusColumn,
  onboardingQueueHrManagerColumn,
  onboardingQueueLocationColumn,
  onboardingQueueRoleColumn,
  onboardingQueueSeniorityColumn,
  onboardingQueueStageColumn,
  onboardingQueueStartDateColumn,
  onboardingQueueStatusColumn,
  onboardingQueueTeamColumn,
  onboardingQueueTimeLeftColumn,
  onboardingQueueTotalSLAColumn,
} from '@src/constants/columns/onboarding'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { RowInterface } from '@src/interfaces/data'
import {
  OnboardingQueueInterface,
  OnboardingQueueStatsInterface,
} from '@src/interfaces/onboarding'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'

const Row: RowInterface<OnboardingQueueInterface> = {
  linkToForm: ({ id, employee }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.START, { id, employeeId: employee.id }),
    ),
  cells: [
    {
      ...onboardingQueueEmployeeColumn,
      width: 200,
    },
    {
      ...onboardingQueueSeniorityColumn,
      width: 100,
    },
    {
      ...onboardingQueueRoleColumn,
      width: 200,
    },
    {
      ...onboardingQueueTeamColumn,
      width: 200,
    },
    {
      ...onboardingQueueLocationColumn,
      width: 120,
    },
    {
      ...onboardingQueueEmployeeStatusColumn,
      width: 120,
    },
    {
      ...onboardingQueueStageColumn,
      width: 120,
    },
    {
      ...onboardingQueueTimeLeftColumn,
      width: 120,
    },
    {
      ...onboardingQueueTotalSLAColumn,
      width: 120,
    },
    {
      ...onboardingQueueStartDateColumn,
      width: 140,
    },
    {
      ...onboardingQueueStatusColumn,
      width: 180,
    },
    {
      ...onboardingQueueHrManagerColumn,
      width: 200,
    },
  ],
}

const OnboardingEmployeesTable = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_ONBOARDING_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const { data: lifecycleSettings } = useGetLifecycleSettings()

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'hr_manager__id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = [
      {
        columnName: 'status',
        filters: [
          {
            id: 'to_do',
            name: 'To do',
          },
          {
            id: 'in_progress',
            name: 'In progress',
          },
        ],
        nonResettable: true,
      },
    ]

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable<OnboardingQueueInterface, OnboardingQueueStatsInterface>(
    {
      getItems: getOnboardingQueue,
      getStats: getOnboardingQueueStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  const { data: stages } = useGetSelectors<OptionInterface & { order: number }>(
    selectorKeys.hr_onboarding_stages,
  )

  const statFilters = stages?.length
    ? [
        {
          title: 'Total',
          id: 'total',
          value: table.stats?.total,
        },
        ...stages
          .sort((a, b) => a.order - b.order)
          .map(stage => {
            return {
              title: stage.name,
              id: stage.id.toString(),
              value: table.stats?.onboarding_stages?.[stage.id.toString()] || 0,
            }
          }),
      ]
    : []

  const currentStatFilter = () => {
    const currentStage = table.filterBy.find(
      filter => filter.columnName === 'current_stage__stage__id',
    )

    return currentStage?.filters[0]?.id.toString() || 'total'
  }

  const setStatFilter = (id: string) => {
    if (id === currentStatFilter() || id === 'total') {
      table.onFilterChange({
        columnName: 'current_stage__stage__id',
        filters: [],
        nonResettable: false,
      })

      return
    }

    const stageName = stages?.find(stage => stage.id.toString() === id)?.name || ''

    table.onFilterChange({
      columnName: 'current_stage__stage__id',
      filters: [
        {
          id,
          name: stageName,
        },
      ],
      nonResettable: false,
    })
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters
          selectedFilter={currentStatFilter()}
          filters={statFilters}
          onClick={setStatFilter}
        />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search for onboarding employees"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          My hires
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.Onboarding}
          useWindowScroll
          dataType="Employee"
          row={Row}
          {...table}
          noDataMessage="Pending employees will appear here."
          hiddenCells={{
            [onboardingQueueTotalSLAColumn.idPoint]:
              !lifecycleSettings?.enable_sla_tracking,
            [onboardingQueueTimeLeftColumn.idPoint]:
              !lifecycleSettings?.enable_sla_tracking,
          }}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default OnboardingEmployeesTable
