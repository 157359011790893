import { Color } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { OffboardingDashboardInterface } from '@src/interfaces/offboarding'

export const useOffboardingParams = () => useParams<{ employeeId: string }>()

export interface OffboardingScreenProps {
  data: OffboardingDashboardInterface
  refreshDashboard: () => void
}

export const taskStatusToColor = {
  completed: Color.GREEN,
  pending: Color.ORANGE,
}
