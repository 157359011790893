import { filterSortPageIntoQuery } from '@src/utils/table'
import { OptionInterface } from '@src/interfaces/selectors'
import { FiltersState } from '@components/HelpCenter/hooks'
import { FilterByInterface, FilterOption } from '@src/interfaces/data'
import { SidebarView } from './types'

const DEFAULT_COUNTRIES_FILTER: FilterOption[] = [{ id: 'global', name: 'global' }]
const DEFAULT_COLUMNS_FILTERS: FilterByInterface[] = [
  {
    columnName: 'status',
    filters: [{ id: 'active', name: 'active' }],
  },
]

export const isInstantSearch = ({
  filtersState,
  sidebarView,
}: {
  filtersState: FiltersState
  sidebarView: SidebarView
}) => {
  const instantSearchViews: SidebarView[] = ['categories', 'topics']
  return !!filtersState.search.value && instantSearchViews.includes(sidebarView)
}

export const searchFiltersToParams = ({
  sidebarView,
  filtersState,
  country,
}: {
  sidebarView: SidebarView
  filtersState: FiltersState
  country?: OptionInterface
}) => {
  const filters = [...DEFAULT_COLUMNS_FILTERS]

  if (filtersState.search.value) {
    filters.push({
      columnName: 'search',
      filters: [{ id: filtersState.search.value, name: filtersState.search.value }],
    })
  }

  if (filtersState.category.value) {
    filters.push({
      columnName: 'category_id',
      filters: [
        {
          id: String(filtersState.category.value.id),
          name: String(filtersState.category.value.id),
        },
      ],
    })
  }

  if (filtersState.topic.value) {
    filters.push({
      columnName: 'topic_id',
      filters: [
        {
          id: String(filtersState.topic.value.id),
          name: String(filtersState.topic.value.id),
        },
      ],
    })
  }

  const shouldFilterByStatus = sidebarView === 'categories' || sidebarView === 'topics'

  if (shouldFilterByStatus) {
    filters.push({
      columnName: 'status',
      filters: [{ id: 'active', name: 'active' }],
    })
  }

  const isQuestionsList =
    isInstantSearch({ filtersState, sidebarView }) || sidebarView === 'questions'
  const shouldFilterByCountry =
    !!country?.id && (isQuestionsList || sidebarView === 'topics')

  if (shouldFilterByCountry) {
    filters.push({
      columnName: 'country__id',
      filters: [
        { id: country.id, name: String(country.id) },
        ...DEFAULT_COUNTRIES_FILTER,
      ],
    })
  }

  return filterSortPageIntoQuery(undefined, filters)
}

export const topicsFiltersParams = ({
  filtersState,
  country,
}: {
  filtersState: FiltersState
  country?: OptionInterface
}) => {
  const filters = [...DEFAULT_COLUMNS_FILTERS]

  if (filtersState.category.value) {
    filters.push({
      columnName: 'category_id',
      filters: [
        {
          id: String(filtersState.category.value.id),
          name: String(filtersState.category.value.id),
        },
      ],
    })
  }
  if (country) {
    filters.push({
      columnName: 'country__id',
      filters: [
        { id: country.id, name: String(country.id) },
        ...DEFAULT_COUNTRIES_FILTER,
      ],
    })
  }

  return filterSortPageIntoQuery(undefined, filters)
}
