import React from 'react'
import { Widget, Text, VStack, Token } from '@revolut/ui-kit'
import { Video } from '@components/Video/Video'

export const TimeOffIntro = () => {
  return (
    <VStack gap="s-16">
      <Video
        poster="/intros/Intro%20-%20Time%20Off.png"
        url="/intros/Intro%20-%20Time%20Off.mov"
      />
      <Widget p="s-16">
        <VStack space="s-8">
          <Text variant="primary">Introducing Time off policies</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            Welcome to Revolut People's innovative time off policy functionality, designed
            to empower both employees and businesses in today's global work environment.
            We understand that in this interconnected world, your team members could be
            working from different corners of the globe, and that's why our policies are
            crafted to create a seamless experience regardless of location.
          </Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            Here you’ll define how many days off, policies, work regimes and more are
            required for all your employees. We’ll guide you along the way to make the
            process smooth so whether you're setting up your first HR solution or
            migrating from an old one you can ensure your time off is setup correctly from
            day 1.
          </Text>
        </VStack>
      </Widget>
    </VStack>
  )
}
