import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '../components/OnboardingChecklistContent'
import { skillsAndValuesConfig } from '../common/constants'
import { SkillsIntro } from './SkillsIntro'
import { SkillsApproval } from './SkillsApproval'
import { skillSettings, useGetSkillsSettings } from '@src/api/settings'
import { SkillsPreview } from './SkillsPreview'
import { ValuesDefinition } from './ValuesDefinition'
import { ValuesPreview } from './ValuesPreview'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { API } from '@src/constants/api'

const ValuesOrSkillsPreviw = () => {
  const { data: settings, isLoading } = useGetSkillsSettings()

  if (isLoading) {
    return <PageLoading />
  }
  return settings?.behaviours_assessment_enabled ? (
    <ValuesPreview />
  ) : (
    <SkillsPreview asValue />
  )
}

export const SkillsAndValues = () => {
  const form = {
    api: skillSettings,
    invalidateQueries: [API.SKILL_SETTINGS],
  }

  const routes = [
    {
      title: 'Skills intro',
      path: ROUTES.ONBOARDING_CHECKLIST.SKILLS.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST.SKILLS.INTRO,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: SkillsIntro,
    },
    {
      title: 'Skills approval',
      path: ROUTES.ONBOARDING_CHECKLIST.SKILLS.APPROVALS,
      url: ROUTES.ONBOARDING_CHECKLIST.SKILLS.APPROVALS,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: SkillsApproval,
      form,
    },
    {
      title: 'Skills preview',
      path: ROUTES.ONBOARDING_CHECKLIST.SKILLS.PREVIEW,
      url: ROUTES.ONBOARDING_CHECKLIST.SKILLS.PREVIEW,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: SkillsPreview,
      isWide: true,
    },
    {
      title: 'Values intro',
      path: ROUTES.ONBOARDING_CHECKLIST.VALUES.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST.VALUES.INTRO,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: SkillsIntro,
    },
    {
      title: 'Values definition',
      path: ROUTES.ONBOARDING_CHECKLIST.VALUES.DEFINITION,
      url: ROUTES.ONBOARDING_CHECKLIST.VALUES.DEFINITION,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: ValuesDefinition,
      isWide: true,
      form,
    },
    {
      title: 'Values preview',
      path: ROUTES.ONBOARDING_CHECKLIST.VALUES.PREVIEW,
      url: ROUTES.ONBOARDING_CHECKLIST.VALUES.PREVIEW,
      canView: [PermissionTypes.ViewSkillsPreferences],
      component: ValuesOrSkillsPreviw,
      isWide: true,
    },
  ]
  return <OnboardingChecklistContent config={skillsAndValuesConfig} routes={routes} />
}
