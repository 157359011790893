import Cookies from 'js-cookie'

import { COOKIE } from '@src/constants/api'
import { getTenantSubdomain } from '@src/utils'
import { cookiesApi } from '@src/utils/cookies'

export const disableSandboxMode = () => {
  const workspace = getTenantSubdomain('SandboxWarningBar')

  if (workspace && Cookies.get(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`)) {
    cookiesApi.remove(`${COOKIE.SANDBOX_MODE_ENABLED}_${workspace}`)
  } else {
    cookiesApi.remove(COOKIE.SANDBOX_MODE_ENABLED)
  }

  window.location.reload()
}
