import { Button, ButtonProps, Flex } from '@revolut/ui-kit'
import React, { PropsWithChildren } from 'react'

export const SideAction = ({ children, ...props }: PropsWithChildren<ButtonProps>) => {
  return (
    <Flex justifyContent="center" alignItems="center" width="100%">
      <Button elevated width="350px" {...props}>
        {children}
      </Button>
    </Flex>
  )
}
