import React from 'react'
import { Item } from '@revolut/ui-kit'

export const PerformanceIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>Introducing Performance App</Item.Title>
        <Item.Description>
          Our Performance Management Tool was designed to simplify and elevate your
          performance management experience. It's the perfect tool for HR, managers, and
          employees to work together and reach company and personal performance objectives
          with ease.
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
