import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys, selectorsWithAvatar } from '@src/constants/api'
import {
  CheckboxSelect,
  CheckboxSelectProps,
  RadioSelect,
  SelectOptionType,
  rem,
} from '@revolut/ui-kit'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { ApiVersion } from '@src/interfaces'
import { UseGetSelectorsQueryOptions } from '@src/api/selectors'
import { matchSorter } from 'match-sorter'
import { OptionWithAvatar } from '@components/Inputs/partials/OptionWithAvatar/OptionWithAvatar'

export enum FilterSelectType {
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
}

export type FilterInputSelectorType<T> = selectorKeys | (() => Promise<{ options: T[] }>)

export interface FilterInputProps<T = OptionInterface>
  extends Pick<
    CheckboxSelectProps<T>,
    'anchorRef' | 'open' | 'children' | 'width' | 'disabled' | 'searchable' | 'placement'
  > {
  /** Array of selected items */
  value?: T[]
  /** Callback for when you select an item */
  onChange: (option: T[]) => void
  /** Selector id to request items for the list */
  selector: FilterInputSelectorType<T>
  /** Type of input  */
  type?: FilterSelectType
  /** On dropdown close */
  onClose: () => void
  useQuery?: boolean
  renderOption?: (option: SelectOptionType<T>) => React.ReactNode
  labelPath?: string
  apiVersion?: ApiVersion
  useQueryOptions?: UseGetSelectorsQueryOptions<T>
}

const FilterSelect = <
  T extends {
    id: number | string
    name: string
    avatar?: string | null
  } = OptionInterface,
>({
  selector,
  onChange,
  value: initialValue = [],
  anchorRef,
  open,
  onClose,
  type = FilterSelectType.MultiSelect,
  useQuery,
  renderOption,
  labelPath,
  apiVersion,
  useQueryOptions,
  ...rest
}: FilterInputProps<T>) => {
  const [value, setValue] = useState<T[] | undefined>()
  const { options, asyncState } = useFetchOptions<T>(
    selector,
    useQuery,
    labelPath,
    apiVersion,
    useQueryOptions,
  )
  const isSingleSelect = type === FilterSelectType.SingleSelect

  // Apply initial value. Ui-kit compares option values by reference, so initial value doesn't work after re-fetch
  // TODO: remove this useEffect when ui-kit guys fix it and put initialValue into [value, setValue] useState instead
  useEffect(() => {
    if (!options || options.length < 1) {
      return
    }

    if (options.length === initialValue?.length) {
      setValue(options.map(option => option.value))
    } else {
      const initialSelection: T[] = []

      initialValue?.forEach(selectedOption => {
        const foundOption = options.find(opt => {
          return String(selectedOption.id) === String(opt.value.id)
        })

        if (foundOption) {
          initialSelection.push(foundOption.value)
        }
      })
      setValue(initialSelection)
    }
  }, [options])

  const renderOptionWithAvatar = useCallback((option: SelectOptionType<T>) => {
    return <OptionWithAvatar option={option} />
  }, [])

  const withAvatar = useMemo(
    () => typeof selector === 'string' && selectorsWithAvatar.includes(selector),
    [selector],
  )

  const renderFunc = useMemo(
    () => (withAvatar && !renderOption ? renderOptionWithAvatar : renderOption),
    [withAvatar, renderOptionWithAvatar, renderOption],
  )

  if (isSingleSelect) {
    return (
      <RadioSelect
        anchorRef={anchorRef}
        open={open}
        onClose={onClose}
        options={options}
        value={value?.[0]}
        onChange={selected => {
          const newValue = selected ? [selected] : []
          setValue(newValue)
          onChange(newValue)
        }}
        labelList="Options"
        loadingState={asyncState}
        searchable
        searchOptions={{ threshold: matchSorter.rankings.MATCHES }}
        {...(withAvatar ? { minWidth: rem(312) } : {})}
        {...rest}
      >
        {renderFunc}
      </RadioSelect>
    )
  }

  return (
    <CheckboxSelect
      anchorRef={anchorRef}
      open={open}
      onClose={onClose}
      options={options}
      value={value}
      onChange={selected => {
        setValue(selected)
        onChange(selected)
      }}
      labelList="Options"
      loadingState={asyncState}
      type="confirm"
      searchable
      searchOptions={{ threshold: matchSorter.rankings.MATCHES }}
      {...(withAvatar ? { minWidth: rem(312) } : {})}
      {...rest}
    >
      {renderFunc}
    </CheckboxSelect>
  )
}

export default FilterSelect
