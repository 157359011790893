import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import PromotionNominationPreview from '@src/pages/Forms/PromotionNominationForm/Preview'
import PromotionNominationForm from '@src/pages/Forms/PromotionNominationForm/General'
import PromotionStageReview from '@src/pages/Forms/PromotionNominationForm/StageReview'
import { Route, Switch, useParams } from 'react-router-dom'
import { PromotionParams } from '@src/pages/Forms/PromotionNominationForm/common/common'
import { useGetEmployee } from '@src/api/employees'
import PageLoading from '@components/PageLoading/PageLoading'
import { PerformanceProvider } from './common/EligibilityAndPerformance/PerformanceContext'

const getTabs = (params: PromotionParams) => [
  {
    title: 'Review',
    path: ROUTES.FORMS.PROMOTION_NOMINATION.STAGE,
    to: pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.STAGE, params),
    component: PromotionStageReview,
  },
  {
    title: 'Preview',
    path: ROUTES.FORMS.PROMOTION_NOMINATION.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.PREVIEW, params),
    component: PromotionNominationPreview,
  },
  {
    title: 'New',
    path: ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM,
    to: pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM, params),
    component: PromotionNominationForm,
    editMode: true,
  },
  {
    title: 'View',
    path: ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM_VIEW,
    to: pathToUrl(ROUTES.FORMS.PROMOTION_NOMINATION.MANAGER_FORM_VIEW, params),
    component: PromotionNominationForm,
    editMode: false,
  },
]

const PromotionNomination = () => {
  const params = useParams<PromotionParams>()
  const tabs = getTabs(params)
  const { data: employee } = useGetEmployee(params.employeeId)

  if (!employee) {
    return <PageLoading />
  }

  return (
    <Switch>
      {tabs.map(tab => (
        <Route exact path={tab.path} key={tab.path}>
          <PerformanceProvider>
            <tab.component employee={employee} editMode={tab.editMode} />
          </PerformanceProvider>
        </Route>
      ))}
    </Switch>
  )
}

export default PromotionNomination
