import { useState } from 'react'

import { getActivationUrl } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'

export const useEmployeeActivationLinkAction = ({
  employee,
}: {
  employee: EmployeeInterface | undefined
}) => {
  const [link, setLink] = useState<string>()
  const [pending, setPending] = useState(false)
  const [showPopup, setShowPopup] = useState(false)

  const onClick = async () => {
    if (!employee?.id) {
      return
    }
    try {
      setShowPopup(true)
      setPending(true)
      setLink(undefined)
      const res = await getActivationUrl(employee.id)
      setLink(res.data.activation_url)
    } finally {
      setPending(false)
    }
  }

  return {
    link,
    onClick,
    pending,
    showPopup,
    setShowPopup,
  }
}
