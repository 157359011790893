import React from 'react'
import { Box, Flex, Link, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'
import { COOKIES_POLICY } from '@src/constants/externalLinks'

const MainCss = css`
  ol,
  ul {
    counter-reset: item;
    padding-left: 16px;
    list-style-position: outside;
  }
  ol > li {
    display: block;
    padding: 4px 0;
  }
  ol > li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
    padding-right: 10px;
  }
`

export const PrivacyNotice = () => {
  return (
    <Flex justifyContent="center" p="s-32">
      <Box maxWidth={1000} css={MainCss} color={Token.color.foreground}>
        <h1>Revolut People</h1>
        <h2>Privacy Notice</h2>
        <p>Effective date: 25 June 2024</p>
        <ol>
          <li>
            <strong>Introduction</strong>
            <p>
              We’re committed to protecting and respecting your privacy. <br />
              We will:
            </p>
            <ul>
              <li>always keep your personal data safe and private</li>
              <li>never sell your personal data</li>
              <li>allow you to manage your marketing choices at any time</li>
            </ul>

            <p>
              This privacy notice sets out how we will handle your personal data when you
              use our website, our services or otherwise interact with us.
            </p>
            <p>
              This notice only applies to personal data. It does not apply to company
              information.
            </p>
            <p>
              If you are an employee of a company that has a Revolut People account, and
              your employer has invited you to use Revolut People, then your employer is
              the ‘controller’ of your personal data and we act as a ‘data processor’.
              This means that your employer is primarily responsible for your personal
              data under data protection laws. As such, please contact your employer if
              you have any questions about how your personal data is processed by Revolut
              People on its behalf.
            </p>
          </li>

          <li>
            <strong>About us</strong>
            <p>
              Revolut Ltd. (“<strong>we</strong>”, “<strong>us</strong>” or “
              <strong>our</strong>”) is the company responsible for collecting, and using,
              your data. <br />
              <br /> If you have concerns about how we use your personal data, you can
              contact us at <Link href="mailto:dpo@revolut.com">dpo@revolut.com</Link>.
            </p>
          </li>

          <li>
            <strong>What personal data do you collect about me?</strong>
            <p>You may provide us with personal data when you:</p>
            <ul>
              <li>fill in any forms</li>
              <li>correspond with us</li>
              <li>respond to any of our surveys</li>
              <li>open a Revolut People account</li>
              <li>use our products or services</li>
              <li>speak with a member of our social media or customer support teams</li>
              <li>contact us for other reasons</li>
            </ul>
            <br />
            <p>
              <u>Information we collect directly</u>
            </p>
            <p>We will collect the following information:</p>
            <ul>
              <li>your name</li>
              <li>your email address</li>
              <li>your country</li>
              <li>your phone number</li>
              <li>any other information you provide us</li>
            </ul>
            <p>
              <u>Information we obtain from third parties</u>
            </p>
            <p>
              We may also collect your personal data through third-party services and
              organisations. For example, if you use our website or services through a
              third-party application or a third-party login service, we may collect
              information about you from that third party that you have made available via
              your privacy settings.
            </p>
            <p>
              Moreover, if you choose to integrate your Revolut People account with
              third-party services and organisations (including, but not limited to Google
              Workspace, Microsoft etc)., we will obtain certain information from such
              third-party services and organisations. Depending on the specific features
              you use, we may obtain information about your or your users’ emails (such as
              email addresses, names and surnames) in order to sync emails and email
              threads as well to send emails directly from Revolut People. If you choose
              an integration with third-party calendar services, we will store your userID
              for that calendar and the authorization token to give you the ability to
              store, view and edit data from your calendars, and autocomplete contacts
              when you create events.
            </p>
            <p>
              Revolut People are obtaining the above-mentioned information through third
              party integrations so we could auto-complete contact data to set up your
              organisational structure and send invites.
            </p>
            <p>
              Revolut People will process this data independently from third-party
              services and organisations and will not use such data for any other purpose
              than described above.
            </p>
            <p>
              <u>Information we collect automatically</u>
            </p>

            <p>Whenever you use our website, we collect the following information:</p>
            <ul>
              <li>
                technical information, including the internet protocol (IP) address used
                to connect your computer to the internet, your login information, the
                browser type and version, the time zone setting, the operating system and
                platform, the type of device you use, a unique device identifier (for
                example, your device's IMEI number, the MAC address of the device's
                wireless network interface, or the mobile phone number used by the
                device), mobile network information, your mobile operating system and the
                type of mobile browser you use
              </li>
              <li>
                information about your visit, including the links you’ve clicked on,
                through and from our website or app (including date and time), services
                you viewed or searched for, page response times, download errors, length
                of visits to certain pages, page interaction information (such as
                scrolling and clicks), and methods used to browse away from the page
              </li>
            </ul>
            <br />
            <p>
              We collect some of this information from you through cookies and other
              similar technologies. For more information about our use of cookies, please
              see our Cookies Policy (accessible <Link href={COOKIES_POLICY}>here</Link>
              ).
            </p>
          </li>
          <li>
            <strong>How do you use my personal data?</strong>
            <p>
              We have explained the purposes for which we use your personal data, as well
              as our lawful bases for doing so, in the table below.
            </p>

            <table>
              <thead>
                <th>
                  <strong>What we use your personal data for</strong>
                </th>
                <th>
                  <strong>Our legal basis for using your personal data</strong>
                </th>
              </thead>
              <tbody>
                <tr>
                  <td>To respond to your queries</td>
                  <td>Legitimate interests (to maintain proper customer relations).</td>
                </tr>
                <tr>
                  <td>To send you updates about Revolut People</td>
                  <td>
                    Legitimate interests (to keep you up to date with Revolut People
                    product and launch).
                  </td>
                </tr>
                <tr>
                  <td>
                    To provide you with our products and services (including, for example,
                    to manage and administer our relationship with you)
                  </td>
                  <td>Keeping to contracts and agreements</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <strong>What are my rights?</strong>
            <p>You have the following rights in relation to your personal data:</p>
            <ul>
              <li>the right to access personal data which we hold about you</li>
              <li>
                the right to ask us to correct your personal data if you think it's wrong
              </li>
              <li>
                the right to ask us to delete your personal data in certain circumstances
              </li>
              <li>
                the right to object to us processing your personal data on the basis of
                our legitimate interests{' '}
              </li>
              <li>
                the right to ask us to restrict how we use your personal data in certain
                circumstances
              </li>
              <li>
                the right to ask us to transfer personal data to you or another company in
                a structured, commonly used, machine-readable format in certain
                circumstances
              </li>
              <li>
                the right to withdraw your consent (where you have previously given us
                this)
              </li>
            </ul>
            <br />
            <p>
              Please note that not all of these rights are absolute and we may not be
              required to comply with your request where exemptions apply under applicable
              data protection laws.
            </p>
            <p>
              You can exercise any of your rights as described in this notice and under
              data protection laws by contacting Revolut’s Data Protection Officer through{' '}
              <Link href="mailto:dpo@revolut.com">dpo@revolut.com</Link>.
            </p>
            <p>
              You also have the right to complain to your local data protection regulator
              if you are unhappy with the way we handled your data.
            </p>
          </li>
          <li>
            <strong>Do you share my personal data with anyone else?</strong>

            <p>
              We may share your personal data with our third party service providers, such
              as our data hosting providers and communications service providers to the
              extent necessary to achieve the purposes set out above.
            </p>
          </li>
          <li>
            <strong>Do you transfer my personal data outside the UK and EEA?</strong>

            <p>
              Revolut is located in the United Kingdom and our main data hosting
              facilities are located in the United Kingdom (“<strong>UK</strong>”) and
              European Union (“<strong>EU</strong>”). However, it’s possible we may need
              to transfer your personal data internationally to achieve the purposes set
              out in section 4 (How do you use my personal data?) above. If we transfer
              your personal data outside the EU or UK, please rest assured that we will
              always take all necessary steps to make sure that your personal data is
              protected. This includes entering into Standard Contractual Clauses with
              third party recipients of your data or transferring it to third parties in
              countries which are recognised as having an adequate level of protection for
              personal data. If you would like more information, please contact us by
              sending an email to{' '}
              <Link href="mailto:dpo@revolut.com">dpo@revolut.com</Link>.
            </p>
          </li>
          <li>
            <strong>How long will you keep my personal data for?</strong>

            <p>
              We will keep your personal data for as long as necessary to achieve the
              purposes set out in section 4 (How do you use my personal data?) above.
              However, we may need to retain your personal data for a longer period to the
              extent required by applicable laws.{' '}
            </p>
          </li>

          <li>
            <strong>How do you protect my personal data?</strong>

            <p>
              We recognise the importance of protecting and managing your personal data.
              Any personal data we process, including sensitive data, will be treated with
              the utmost care and security.
            </p>
            <p>
              We use a variety of physical and technical measures to:
              <ul>
                <li>keep your personal data safe </li>
                <li>prevent unauthorised access to your personal data</li>
                <li>make sure your personal data is not improperly used or disclosed.</li>
              </ul>
            </p>
            <p>
              Electronic data and databases are stored on secure computer systems with
              control over access to information using both physical and electronic means.
              Our staff receives data protection and information security training. We
              have detailed security and data protection policies which staff are required
              to follow when they handle personal data.
            </p>
            <p>
              While we take all reasonable steps to ensure that your personal data will be
              kept secure from unauthorised access, we cannot guarantee it will be secure
              during transmission by you to our website or other services. We use HTTPS
              (HTTP Secure), where the communication protocol is encrypted through
              Transport Layer Security for secure communication over networks, for all our
              web services.
            </p>
          </li>

          <li>
            <strong>Updates</strong>

            <p>
              We may update this privacy notice from time to time. Any changes will be
              posted on this page. If we make any material changes to this privacy notice,
              we may notify you by email before the change becomes effective.
            </p>
          </li>
        </ol>
      </Box>
    </Flex>
  )
}
