import axios, { AxiosPromise } from 'axios'

import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  ChangeRequest,
  EmployeeChangeRequestInterface,
  EmployeeChangeRequestSetSubmitInterface,
} from '@src/interfaces/employeeChangeRequest'
import { api, apiWithoutHandling, getConfig, handleError } from '.'
import { getCommentsAPI } from './comments'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const employeeChangeRequest: RequestInterfaceNew<EmployeeChangeRequestInterface> =
  {
    get: async ({ id, employeeId }) =>
      api.get(`${API.EMPLOYEES}/${employeeId}/changeRequestSets/${id}`),
    update: async (data, { id, employeeId }) =>
      api.patch(`${API.EMPLOYEES}/${employeeId}/changeRequestSets/${id}`, data),
    submit: async (data, { employeeId }) =>
      api.post(`${API.EMPLOYEES}/${employeeId}/changeRequestSets`, data),
  }

export const getEmployeeChangeRequests =
  (id: number | string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ChangeRequest>> =>
    api.get(`${API.EMPLOYEES}/${id}/changeRequests`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const generateChangeRequestPreview = (
  employeeId: number,
  data: EmployeeChangeRequestSetSubmitInterface,
) =>
  apiWithoutHandling.post<EmployeeChangeRequestInterface>(
    `${API.EMPLOYEES}/${employeeId}/changeRequestSets/preview`,
    data,
  )

export const getEmployeeChangeRequestsCommentsAPI = (requestId: string | number) =>
  getCommentsAPI({
    baseUrl: `/changeRequestSets/${requestId}/comments`,
  })

export const approveChangeRequest = (
  employeeId: string | number,
  requestId: string | number,
) =>
  api.post<ChangeRequest>(
    `${API.EMPLOYEES}/${employeeId}/changeRequests/${requestId}/approve`,
  )

export const rejectChangeRequest = (
  employeeId: string | number,
  requestId: string | number,
) =>
  api.post<ChangeRequest>(
    `${API.EMPLOYEES}/${employeeId}/changeRequests/${requestId}/reject`,
  )

export const getChangeRequestDetails = (url: string) =>
  axios.get<ChangeRequest>(url, getConfig())

export const approveOrRejectFromDashboard = (url: string) =>
  axios.post(url, {}, getConfig()).catch(handleError)

export const approveAll = (employeeId: string | number, requestId: string | number) =>
  api.post<EmployeeChangeRequestInterface>(
    `${API.EMPLOYEES}/${employeeId}/changeRequestSets/${requestId}/approve_all`,
  )

export const rejectAll = (employeeId: string | number, requestId: string | number) =>
  api.post<EmployeeChangeRequestInterface>(
    `${API.EMPLOYEES}/${employeeId}/changeRequestSets/${requestId}/reject_all`,
  )
