import { useState } from 'react'

import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import { userRequest } from '@src/api/accessManagement'

export const useEmployeeAssignedPermissionsAction = () => {
  const [selectedUser, setSelectedUser] = useState<UserAccessManagementInterface>()

  const onClick = async (userId: string) => {
    const { data: accessManagementUser } = await userRequest.get({
      id: userId,
    })
    setSelectedUser(accessManagementUser)
  }

  return {
    selectedUser,
    setSelectedUser,
    onClick,
  }
}
