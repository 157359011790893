import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextAreaField from '@components/Inputs/TextAreaField/TextAreaField'
import { DeliverablesOverallFeedbackInterface } from '@src/interfaces/performance'
import { ListType } from '@src/utils'
import BottomText from '@components/Inputs/partials/BottomText'

interface Props {
  feedback: DeliverablesOverallFeedbackInterface
  onChange: (feedback: DeliverablesOverallFeedbackInterface) => void
  errors?: DeliverablesOverallFeedbackInterface
}

const Container = styled.div`
  border-radius: 8px;
  padding: 16px 16px 12px;
  box-sizing: border-box;
`

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 2px;
`

const Block = styled.div`
  margin-bottom: 8px;
`

const TextArea = styled(TextAreaField)`
  border: 0;
  background-color: transparent;
  margin-left: -8px;
`

const DeliverablesCommentsBox = ({ feedback, onChange, errors }: Props) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    // hack: scrollHeight is 0 when TextArea is rendered before element is mounted in portal
    setIsMounted(true)
  }, [])

  if (!isMounted) {
    return null
  }

  const getCommentValue = (value: string) => {
    return value.length ? value.split('\n') : []
  }

  const renderError = (field: 'complexity' | 'speed' | 'quality') => {
    if (!errors?.[field] || feedback[field].length) {
      return null
    }
    return <BottomText error={`Please add comment for ${field}`} />
  }

  return (
    <Container>
      <Block>
        <Title>Overall complexity:</Title>
        <TextArea
          value={feedback?.complexity.join('\n')}
          onChange={value => {
            onChange({ ...feedback, complexity: getCommentValue(value) })
          }}
          listType={ListType.letters}
        />
        {renderError('complexity')}
      </Block>
      <Block>
        <Title>Overall speed:</Title>
        <TextArea
          value={feedback?.speed.join('\n')}
          onChange={value => {
            onChange({ ...feedback, speed: getCommentValue(value) })
          }}
          listType={ListType.letters}
        />
        {renderError('speed')}
      </Block>
      <Block>
        <Title>Overall quality:</Title>
        <TextArea
          value={feedback?.quality.join('\n')}
          onChange={value => {
            onChange({ ...feedback, quality: getCommentValue(value) })
          }}
          listType={ListType.letters}
        />
        {renderError('quality')}
      </Block>
    </Container>
  )
}

export default DeliverablesCommentsBox
