import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ActionButton, Box, Subheader } from '@revolut/ui-kit'
import { AvailableInterviewerSlot } from '@src/interfaces/interviewTool'
import { OptionInterface } from '@src/interfaces/selectors'
import { getAvailableInterviewSlots } from '@src/api/recruitment/interviewerScheduling'
import { utcToZonedTime } from 'date-fns-tz'
import { addWeeks, formatISO } from 'date-fns'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import ErrorBoundary from '@src/features/ErrorBoundary/ErrorBoundary'
import axios, { CancelTokenSource } from 'axios'
import { ExtensionApiHandlerContext } from '@src/utils/extension'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { getTimeZoneId } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import SlotsSelector from '@src/pages/Forms/Candidate/ScheduleSidebar/SlotsSelector'

type Props = {
  roundId: number
  onChangeSlots: (slots: AvailableInterviewerSlot[]) => void
  dateFilter?: OptionInterface
  interviewersFilter?: OptionInterface[]
  setInterviewersFilter: (options?: OptionInterface[]) => void
  setDateFilter: (options?: OptionInterface) => void
  onClose: () => void
  selectedSlots: AvailableInterviewerSlot[]
  isPrepCall: boolean
}

const EditSlotsDate = ({
  setInterviewersFilter,
  interviewersFilter,
  roundId,
  onChangeSlots,
  dateFilter,
  selectedSlots,
  setDateFilter,
  onClose,
  isPrepCall,
}: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const axiosCancelToken = useRef<CancelTokenSource>()
  const [totalSlotsCount, setTotalSlotsCount] = useState<number | null>(null)
  const [slots, setSlots] = useState<AvailableInterviewerSlot[]>([])
  const [isLoadingSlots, setLoadingSlots] = useState(false)

  const timeZoneId = getTimeZoneId(values.scheduling_timezone)

  const dateRange = useMemo(() => {
    const timeZonedDate = utcToZonedTime(new Date(), timeZoneId)
    return [
      formatISO(timeZonedDate, { representation: 'date' }),
      formatISO(addWeeks(timeZonedDate, dateFilter ? +dateFilter.id : 2), {
        representation: 'date',
      }),
    ]
  }, [dateFilter, timeZoneId])

  useEffect(() => {
    setSlots([])
    setTotalSlotsCount(null)
    onLoadMore(null, [])
  }, [
    values.interview_stage?.id,
    roundId,
    dateFilter,
    interviewersFilter,
    values.duration,
    values.duration_unit,
  ])

  const onLoadMore = async (
    total: number | null = totalSlotsCount,
    localSlots: AvailableInterviewerSlot[] = slots,
  ) => {
    if (total !== null && localSlots.length === total) {
      return
    }

    if (axiosCancelToken.current !== undefined) {
      axiosCancelToken.current.cancel('Operation canceled due to new request.')
    }
    axiosCancelToken.current = axios.CancelToken.source()

    setLoadingSlots(true)

    try {
      const resp = await getAvailableInterviewSlots({
        stageId: values.interview_stage?.id,
        roundId,
        dateRange,
        interviewers: interviewersFilter,
        duration: values.duration,
        durationUnit: values.duration_unit?.id,
        isAdhoc: isPrepCall,
        cancelToken: axiosCancelToken.current,
        apiHandler,
      })

      setTotalSlotsCount(resp.data.count)
      setSlots([...localSlots, ...resp.data.results])
      setLoadingSlots(false)
    } catch (e) {
      if (e?.message !== 'cancelPrevQuery') {
        setLoadingSlots(false)
      }
    }
  }

  return (
    <ErrorBoundary>
      <Box>
        <LapeNewMultiSelect<EmployeeOptionInterface>
          name="additional_interviewers"
          placeholder="Additional interviewers"
          selector={selectorKeys.employee}
          variant="grey"
        />
        <Subheader variant="nested">
          <Subheader.Title>Showing lead interviewer slots</Subheader.Title>
        </Subheader>
        <SlotsSelector
          dateFilter={dateFilter}
          interviewerFilter={interviewersFilter}
          isPrepCall={isPrepCall}
          loadingSlots={isLoadingSlots}
          availableSlots={slots}
          selectedSlots={selectedSlots}
          slotInterviewerFilterProps={{
            stageId: values.interview_stage?.id,
            roundId,
          }}
          warningContent={
            <ActionButton onClick={onClose}>Schedule manually</ActionButton>
          }
          timeZoneId={timeZoneId}
          onInterviewerFilterChange={newInterviewersFilter => {
            setInterviewersFilter(newInterviewersFilter)
          }}
          onDateFilterChange={newDateFilter => {
            setDateFilter(newDateFilter)
          }}
          onSlotChange={newSelectedSlots => {
            onChangeSlots(newSelectedSlots)
          }}
        />
      </Box>
    </ErrorBoundary>
  )
}

export default EditSlotsDate
