import { Cell, VStack, Text, Token, Box, HStack, Icon, Spacer } from '@revolut/ui-kit'
import { useGetRoadmap } from '@src/api/roadmaps'
import RoadmapProgress from '@src/components/ColumnInserts/RoadmapProgress/RoadmapProgress'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import {
  getRoadmapDueDateColor,
  getRoadmapPriorityColor,
  getRoadmapStatusColor,
} from '@src/constants/columns/roadmap'
import { SummarySubheader } from '@src/features/Summary/common'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import React from 'react'
import styled from 'styled-components'

const BorderedBox = styled(Box)`
  border: 1px solid ${Token.color.greyTone5};
  border-radius: ${Token.radius.r12};
`
export const SidePreview = ({ roadmap }: { roadmap: RoadmapInterface }) => {
  const { data } = useGetRoadmap(roadmap.id)
  return (
    <Cell>
      <VStack width="100%">
        <Text variant="h5">{roadmap.name}</Text>
        <Spacer height="s-8" />
        <HStack space="s-8" color={Token.color.greyTone50} align="center">
          <Icon name="Services" size={14} />
          <Text variant="caption">{roadmap.team?.name}</Text>
        </HStack>
        <Spacer height="s-24" />
        <SummarySubheader>General Details</SummarySubheader>
        <BorderedBox>
          <FormPreview<RoadmapInterface> data={data} customHeader={() => null}>
            <FormPreview.Item field="owner.display_name" title="Owner" />
            <FormPreview.Item
              field="priority"
              title="Priority"
              color={getRoadmapPriorityColor}
            />
            <FormPreview.Item field="start_date" title="Start Date" type="date" />
            <FormPreview.Item
              color={getRoadmapDueDateColor}
              field="due_date"
              title="Due Date"
              type="date"
            />
          </FormPreview>
        </BorderedBox>
        <Spacer height="s-24" />
        <SummarySubheader>Progress</SummarySubheader>
        <BorderedBox>
          <FormPreview<RoadmapInterface> customHeader={() => null} data={data}>
            <FormPreview.Item<RoadmapInterface>
              field="status"
              title="Status"
              color={v => getRoadmapStatusColor(v).color}
            />
            <FormPreview.Item<RoadmapInterface>
              field="progress"
              title="Progress"
              insert={d => (
                <HStack width={150} space="s-16">
                  <RoadmapProgress {...d} />
                  <Text color={getRoadmapStatusColor(d).color}>{d.done}%</Text>
                </HStack>
              )}
            />
            <FormPreview.Item
              field="resolution_date_time"
              title="Resolved on"
              type="date"
            />
          </FormPreview>
        </BorderedBox>
      </VStack>
    </Cell>
  )
}
