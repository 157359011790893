import { useMemo } from 'react'
import format from 'date-fns/format'

import { RowInterface } from '@src/interfaces/data'
import { EmployeeShiftColumn } from '@src/constants/columns/attendance'
import { dateFilterFormat } from './MonthToggle'

export const useTableRow = (
  ROW: RowInterface<EmployeeShiftColumn>,
  expandToday: boolean,
) =>
  useMemo(
    () => ({
      ...ROW,
      isOpen: (row: EmployeeShiftColumn) =>
        !('children_type' in row)
          ? false
          : expandToday && row.date === format(new Date(), dateFilterFormat),
    }),
    [expandToday],
  )
