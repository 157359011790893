import { OrgUnitGoalsStats } from '@src/interfaces/goals'
import {
  Action,
  Avatar,
  Box,
  IconName,
  Item,
  ItemSkeleton,
  Link,
  Status,
} from '@revolut/ui-kit'
import React from 'react'
import pluralize from 'pluralize'
import { Context } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Sidebar/Goals/List'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface BaseItemProps extends OrgUnitGoalsStats {
  iconName: IconName
  titleLink: string
  side?: React.ReactNode
}

const BaseItem = ({
  id,
  name,
  owner,
  iconName,
  titleLink,
  side: Side,
}: BaseItemProps) => (
  <Box key={id} pb="s-16">
    <Item>
      <Item.Avatar>
        <Avatar useIcon={iconName} />
      </Item.Avatar>
      <Item.Content>
        {name && (
          <Link use={InternalLink} to={titleLink}>
            <Item.Title>{name}</Item.Title>
          </Link>
        )}
        {owner && (
          <Item.Description>
            <Status useIcon="Profile">{owner.name}</Status>
          </Item.Description>
        )}
      </Item.Content>
      {Side && <Item.Side>{Side}</Item.Side>}
    </Item>
  </Box>
)

export const renderWithoutGoalsItem = <T extends OrgUnitGoalsStats, C extends Context>(
  _index: number,
  props: T,
  { iconName, makeTitleLink }: C,
) => <BaseItem {...props} iconName={iconName} titleLink={makeTitleLink(props.id)} />

export const renderWithGoalsItem = <T extends OrgUnitGoalsStats, C extends Context>(
  _index: number,
  props: T,
  { iconName, onActionClick, makeTitleLink }: C,
) => (
  <BaseItem
    {...props}
    iconName={iconName}
    titleLink={makeTitleLink(props.id)}
    side={
      <Action onClick={() => onActionClick(props.id)}>
        {pluralize('goal', props.goals_count, true)}
      </Action>
    }
  />
)

export const ListItemsSkeleton = () => (
  <>
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
    <ItemSkeleton />
  </>
)
