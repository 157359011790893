import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useApproveGrowthPlan } from '@src/api/growthPlans'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  growthPlan: GrowthPlanInterface
  onComplete: VoidFunction
}

export const ApproveAction = ({ growthPlan, onComplete }: Props) => {
  const { mutateAsync: approveGrowthPlan, isLoading } = useApproveGrowthPlan(
    growthPlan.id,
  )

  const showStatusPopup = useShowStatusPopup()

  const handleClick = async () => {
    try {
      await approveGrowthPlan()
      onComplete()
      showStatusPopup({ title: 'Growth plan was successfully approved' })
    } catch (err) {
      showStatusPopup({
        title: 'Failed to approve growth plan',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    }
  }

  return (
    <MoreBar.Action
      variant="accent"
      useIcon="Check"
      pending={isLoading}
      onClick={handleClick}
    >
      Approve plan
    </MoreBar.Action>
  )
}
