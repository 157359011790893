import React, { createContext, useContext, useMemo, useState } from 'react'

import { useAvailableSubscriptionPlans } from '@src/api/plans'
import {
  AvailableSubscriptionPlanInterface,
  MerchantApiConfigInterface,
  MerchantApiOrder,
} from '@src/interfaces/plans'
import { CheckoutWidgetType } from './CheckoutWidget'

export type OrderAndConfig = {
  order: MerchantApiOrder
  config: MerchantApiConfigInterface
  type: CheckoutWidgetType
}

interface SubscriptionPlanContextInterface {
  selectedPlan: AvailableSubscriptionPlanInterface | undefined
  setSelectedPlan: React.Dispatch<
    React.SetStateAction<AvailableSubscriptionPlanInterface | undefined>
  >
  orderAndConfig: OrderAndConfig | undefined
  setOrderAndConfig: React.Dispatch<React.SetStateAction<OrderAndConfig | undefined>>
}

const SubscriptionPlanContext = createContext<SubscriptionPlanContextInterface | null>(
  null,
)

export const useSubscriptionPlanContext = () => {
  const context = useContext(SubscriptionPlanContext)
  if (context == null) {
    throw new Error(
      `useSubscriptionPlanContext must be used within a SubscriptionPlanProvider`,
    )
  }
  return context
}

export const SubscriptionPlanProvider: React.FC = ({ children }) => {
  const { plans } = useAvailableSubscriptionPlans()

  const [selectedPlan, setSelectedPlan] = useState<AvailableSubscriptionPlanInterface>()
  const [orderAndConfig, setOrderAndConfig] = useState<OrderAndConfig>()

  const contextValue = useMemo(
    () => ({ selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig }),
    [selectedPlan, setSelectedPlan, orderAndConfig, setOrderAndConfig],
  )

  if (plans?.length && !selectedPlan) {
    setSelectedPlan(plans[0])
  }

  return (
    <SubscriptionPlanContext.Provider value={contextValue}>
      {children}
    </SubscriptionPlanContext.Provider>
  )
}
