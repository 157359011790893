import React from 'react'
import { Button, Header, Popup } from '@revolut/ui-kit'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import {
  getSelectEventDescription,
  getTitle,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/SchedulePopup/constants'

interface Props {
  meeting: OneToOneMeeting
  isPending: boolean
  handleConfirm: VoidFunction
  handleCancel: VoidFunction
}

export const ConfirmEventBody = ({
  meeting,
  isPending,
  handleConfirm,
  handleCancel,
}: Props) => {
  const title = getTitle(meeting.employee)
  const description = getSelectEventDescription(meeting.employee)

  return (
    <>
      <Header variant="bottom-sheet">
        <Header.Title>{title}</Header.Title>
        <Header.Description>{description}</Header.Description>
      </Header>
      <Popup.Actions>
        <Button onClick={handleConfirm} pending={isPending}>
          Confirm
        </Button>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
      </Popup.Actions>
    </>
  )
}
