import SideBar from '@components/SideBar/SideBar'
import React from 'react'

interface TimelineSidebarLoaderProps {
  onClose: () => void
}

const TimelineSidebarLoader = ({ onClose }: TimelineSidebarLoaderProps) => {
  return (
    <SideBar isOpen onClose={onClose} variant="wide" loading>
      <></>
    </SideBar>
  )
}

export default TimelineSidebarLoader
