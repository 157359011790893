import React from 'react'
import { Link } from '@revolut/ui-kit'
import { z } from 'zod'
import { lape } from 'lape'

import { VerificationCodeForm } from '@src/pages/Login/common'
import { cookiesApi } from '@src/utils/cookies'
import { COOKIE } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES, ROUTES } from '@src/constants/routes'

interface SignupVerificationFormProps {
  token: string
}

export const SignupVerificationForm = ({ token }: SignupVerificationFormProps) => (
  <VerificationCodeForm
    token={token}
    onSuccess={() => {
      setSignupStateCookie('company_details')
      navigateTo(pathToUrl(ROUTES.SIGNUP.COMPANY_DETAILS))
    }}
    emailOnly
  />
)

interface SignUpCookie {
  state: 'email_confirmation' | 'company_details' | 'waiting_list' | 'suspended_or_closed'
  token?: string | undefined
  tenantId?: string | undefined
}

const signupStateCookieSchema = z.object({
  state: z.enum([
    'email_confirmation',
    'company_details',
    'waiting_list',
    'suspended_or_closed',
  ]),
  token: z.string().optional(),
  tenantId: z.string().optional(),
})

export const signupState = lape<{ cookie: SignUpCookie | null }>({
  cookie: getSignupStateCookie(),
})

export function setSignupStateCookie(
  state:
    | 'email_confirmation'
    | 'company_details'
    | 'waiting_list'
    | 'suspended_or_closed',
  token?: string | null,
  tenantId?: string | null,
) {
  cookiesApi.set(
    COOKIE.SIGNUP_STATE,
    JSON.stringify({
      token: token || undefined,
      state,
      tenantId: tenantId || undefined,
    }),
    { expires: 1 },
  )

  signupState.cookie = getSignupStateCookie()
}

export function getSignupStateCookie() {
  const cookie = cookiesApi.get(COOKIE.SIGNUP_STATE)

  if (!cookie) {
    return null
  }

  const validationResult = signupStateCookieSchema.safeParse(JSON.parse(cookie))

  if (!validationResult.success) {
    removeSignupStateCookie()
    return null
  }

  return validationResult.data
}

export function removeSignupStateCookie() {
  cookiesApi.remove(COOKIE.SIGNUP_STATE)
  signupState.cookie = null
}

export const TermsAndConditionsDisclaimer = () => (
  <>
    By continuing you confirm that you have read and agree to the Revolut People{' '}
    <Link href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)} target="_blank">
      Terms and Conditions
    </Link>{' '}
    and{' '}
    <Link href={pathToUrl(PUBLIC_ROUTES.PRIVACY_NOTICE)} target="_blank">
      Privacy Notice
    </Link>
    .
  </>
)
