import React from 'react'

import {
  Toggle,
  Token,
  ButtonBase,
  Box,
  TransitionCollapse,
  Icon,
  useMatchMedia,
} from '@revolut/ui-kit'
import styled from 'styled-components'

import { Text } from '@src/pages/Landing/components/Text'

import type { FAQBlockContentItem } from './types'

const FAQBlockItemButtonExpand = styled(ButtonBase)({
  display: 'flex',
  flexDirection: 'row',
  gap: 's-16',
  justifyContent: 'space-between',
  width: '100%',
  padding: `${Token.space.s16} 0`,
  [`@media ${Token.media.lg}`]: { padding: `${Token.space.s24} 0` },
  [`@media ${Token.media.xxl}`]: { padding: `${Token.space.s40} 0` },
})

const FAQBlockItemDescription = styled(Box)({
  color: Token.color.greyTone50,
  whiteSpace: 'pre-wrap',
  marginBottom: Token.space.s16,
  [`@media ${Token.media.lg}`]: { marginBottom: Token.space.s24 },
  [`@media ${Token.media.xxl}`]: { marginBottom: Token.space.s40 },
})

export const FAQBlockItem = ({ title, description }: FAQBlockContentItem) => {
  const isLgBreakpoint = useMatchMedia(Token.media.lg)
  const hasContent = Boolean(description)

  return (
    <Toggle>
      {({ state, toggle }) => (
        <Box
          borderBottom="1px solid"
          borderBottomColor="grey-50"
          mb={{ all: 's-16', lg: 's-24', xxl: 's-40' }}
          use="li"
        >
          <FAQBlockItemButtonExpand onClick={() => toggle()}>
            <Text use="h5" variant="h5">
              {title}
            </Text>
            {hasContent && (
              <Box flex="0 0">
                <Icon
                  name={state ? 'MinusCircle' : 'PlusCircle'}
                  size={isLgBreakpoint ? 32 : 24}
                  color={state ? 'grey-50' : 'foreground'}
                />
              </Box>
            )}
          </FAQBlockItemButtonExpand>
          {hasContent && (
            <TransitionCollapse in={state} unmountOnExit={false}>
              <FAQBlockItemDescription hide={!state}>
                <Text
                  // @ts-expect-error Custom theme for this LandingPage is used. TODO: REVPI-28 Migrate LandingPage to standard UI Kit
                  variant="subtitle1"
                >
                  {description}
                </Text>
              </FAQBlockItemDescription>
            </TransitionCollapse>
          )}
        </Box>
      )}
    </Toggle>
  )
}
