import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { settingsModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/SettingsModel'

// line manager does not exist as a setting, it is always "switched on"
type LineManagerReviewKey = 'enable_line_manager_reviews'
type ReviewKeys = keyof Pick<
  ReviewCyclesInterface,
  | 'enable_self_reviews'
  | 'enable_functional_manager_reviews'
  | 'enable_peer_reviews'
  | 'enable_upwards_reviews'
>

const reviewKeyToLabelMap: Record<ReviewKeys | LineManagerReviewKey, string> = {
  enable_self_reviews: 'Self',
  enable_line_manager_reviews: 'Line manager',
  enable_functional_manager_reviews: 'Functional manager',
  enable_peer_reviews: 'Peer',
  enable_upwards_reviews: 'Upwards',
}

export const makeReviewTypesDescription = (
  cycle: ReviewCyclesInterface,
  settings: PerformanceSettingsInterface,
): string =>
  Object.entries(reviewKeyToLabelMap)
    .reduce<string[]>((labels, [key, label]) => {
      if (
        !settings ||
        (key === 'enable_functional_manager_reviews' &&
          !settingsModel.hasFunctionalManagement(settings))
      ) {
        return labels
      }

      if (key === 'enable_line_manager_reviews') {
        labels.push(label)
      } else if (cycle[key as ReviewKeys]) {
        labels.push(label)
      }

      return labels
    }, [])
    .join(', ')
