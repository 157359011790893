import React from 'react'
import { Button, StatusPopup } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

interface Props {
  onClose: VoidFunction
  isOnboarding?: boolean
}

// need to be a render function not component as <StatusPopup requires to be in the root
export const renderFinishPopup = ({ onClose, isOnboarding = false }: Props) => {
  return (
    <StatusPopup variant="success-result" onClose={onClose}>
      <StatusPopup.Title>Congratulations!</StatusPopup.Title>
      <StatusPopup.Description>
        You've set up your{isOnboarding ? ' first ' : ' '}survey
      </StatusPopup.Description>
      <StatusPopup.Actions>
        <Button onClick={onClose}>
          <FormattedMessage
            id="onboardingV2.general.goToDashboard"
            defaultMessage="Go to Dashboard"
          />
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}
