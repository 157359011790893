import React from 'react'
import { useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'
import { Avatar, Header, Item, Text, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { getOnboardingV2Documents } from '@src/api/onboardingV2'
import { ROUTES } from '@src/constants/routes'
import {
  DocumentSources,
  DocumentStatuses,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { pathToUrl } from '@src/utils/router'
import { TabProps } from '../common/interface'
import {
  getDocumentLink,
  getDocumentStatusColor,
  getDocumentStatusToIcon,
} from '../common/utils'
import { BackButton, NextButton } from '../components/Buttons'

export const Documents = ({ isPreview, onComplete, prevRoute }: TabProps) => {
  const params = useParams<{ id: string; onboardingId: string }>()

  const navigateToPrevStep = () => {
    if (prevRoute) {
      navigateTo(prevRoute)
    }
  }

  const navigateToNextStep = () => {
    navigateTo(pathToUrl(ROUTES.ONBOARDING_V2.SUMMARY, params))
    onComplete?.()
  }

  return (
    <>
      <PageBody>
        <Header mb="-s-12" variant="main">
          <Header.Title color={Token.color.foreground}>Documents</Header.Title>
          <Header.Subtitle>
            Almost there! Please find below a list of documents that need to be uploaded
            or signed before we can start your employment.
          </Header.Subtitle>
        </Header>
        <Text
          color={Token.color.greyTone50}
          display="inline-block"
          mb="s-24"
          textStyle="caption"
        >
          Please note that the document status may take a few minutes to update after
          signing.
        </Text>

        <TablePreview<EmployeeDocumentListItemInterface>
          api={{
            getItems: getOnboardingV2Documents(params.onboardingId),
          }}
          disable={isPreview}
          emptyState={{
            icon: 'DocumentsPair',
            title: 'No documents',
          }}
          row={document => {
            const pendingDocusign =
              document.source === DocumentSources.Docusign &&
              document.status === DocumentStatuses.pending
            return (
              <Item
                key={document.id}
                onClick={() => getDocumentLink(document)}
                variant="disclosure"
                use="button"
              >
                <Item.Avatar>
                  <Avatar useIcon="DocumentsPair">
                    <Avatar.Badge
                      bg={getDocumentStatusColor(document.status)}
                      useIcon={getDocumentStatusToIcon(document.status)}
                    />
                  </Avatar>
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>{document.document_name}</Item.Title>
                  <Item.Description>
                    {pendingDocusign ? (
                      <Text>Document was sent to your email address</Text>
                    ) : (
                      <Text
                        color={getDocumentStatusColor(document.status)}
                        fontWeight="normal"
                      >
                        {startCase(document.status)}
                      </Text>
                    )}
                  </Item.Description>
                </Item.Content>
              </Item>
            )
          }}
        />
      </PageBody>

      <PageActions alignSelf="center" maxWidthMd={Token.breakpoint.sm}>
        <BackButton onClick={navigateToPrevStep} />
        <NextButton afterSubmit={navigateToNextStep} />
      </PageActions>
    </>
  )
}
