import { Item, Avatar, IconButton, Token } from '@revolut/ui-kit'
import { Lightbulb, Cross, IconComponentType } from '@revolut/icons'
import React, { useState } from 'react'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

type MessageProps = {
  color?: string
  description?: string
  icon?: IconComponentType
  title: string
  storageKey?: string
}

export const Message = ({
  color = Token.color.blue,
  description = 'Feel free to take a look and make any adjustments to suit your preferences.',
  icon = Lightbulb,
  title,
  storageKey,
}: MessageProps) => {
  const [hidden, setHidden] = useState(
    storageKey ? workspaceLocalStorage.getItem(storageKey) === 'TRUE' : false,
  )

  if (hidden) {
    return null
  }

  const handleHide = () => {
    if (storageKey) {
      workspaceLocalStorage.setItem(storageKey, 'TRUE')
    }
    setHidden(true)
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={icon} color={color} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <IconButton useIcon={Cross} color={Token.color.greyTone50} onClick={handleHide} />
      </Item.Side>
    </Item>
  )
}

export default Message

export const ApprovalsMessage = () => {
  return <Message title="We have created approval steps for you." />
}
