import React, { useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { Button, Grid } from '@revolut/ui-kit'

import { DocumentsUploadFiles } from '@src/pages/Forms/ImportData/Documents/DocumentsUploadFiles'
import { DocumentsSession } from '@src/pages/Forms/ImportData/Documents/DocumentsSession'
import { ROUTES } from '@src/constants/routes'
import OnboardingActions from '../components/OnboardingActions'
import { documentsConfig } from '../common/constants'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'

export const DocumentsUpload = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.IMPORT} exact>
        <DocumentsUploadFiles
          customActions={(continueDisabled, sessionId) => (
            <OnboardingActions
              config={documentsConfig}
              currentStep="Import documents"
              isLastStep={!sessionId}
              disableBack={false}
              pendingBack={false}
              disableNext={!!sessionId && continueDisabled}
              pendingNext={!!sessionId && continueDisabled}
              nextRoute={
                sessionId
                  ? pathToUrl(ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.SESSION, {
                      id: sessionId,
                    })
                  : ''
              }
              previousRoute={ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.INTRO}
              updateSteps={!sessionId}
              isForm={false}
              nextButtonLabel={sessionId ? 'Review' : 'Skip'}
            />
          )}
        />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.SESSION} exact>
        <DocumentsSession
          customSuccessActions={
            <OnboardingActions
              config={documentsConfig}
              currentStep="Import documents"
              isLastStep
              disableBack={false}
              pendingBack={false}
              disableNext={false}
              pendingNext={false}
              nextRoute=""
              previousRoute={ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.IMPORT}
              updateSteps
              isForm={false}
            />
          }
          customActions={props => <CustomActions {...props} />}
        />
      </Route>
    </Switch>
  )
}

interface CustomActionsProps {
  refetch: () => void
  disabled: boolean
  onSubmit: () => Promise<any>
}

const CustomActions = ({ refetch, disabled, onSubmit }: CustomActionsProps) => {
  const [pending, setPending] = useState(false)

  const onSubmitClick = async () => {
    setPending(true)

    try {
      await onSubmit()
      refetch()
    } finally {
      setPending(false)
    }
  }

  return (
    <PageActions>
      <Grid columns={2} gap="s-8" maxWidth={375}>
        <Button
          variant="secondary"
          onClick={() => {
            goBack(pathToUrl(ROUTES.ONBOARDING_CHECKLIST.DOCUMENTS.UPLOAD.IMPORT))
          }}
        >
          Back
        </Button>
        <Button onClick={onSubmitClick} pending={pending} disabled={disabled} elevated>
          Upload data
        </Button>
      </Grid>
    </PageActions>
  )
}
