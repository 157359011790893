import React from 'react'
import Tooltip from '@components/Tooltip/Tooltip'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import { InfoOutline } from '@revolut/icons'
import { Box, Link } from '@revolut/ui-kit'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { REVIEW_DELIVERABLES } from '@src/constants/externalLinks'

const IndividualContributorTooltip = () => {
  return (
    <Tooltip
      placement="left"
      body={
        <TooltipContainer>
          The delivery rating is determined with a weighted average calculation.{' '}
          <HideIfCommercial>
            Read more about it{' '}
            <Link
              href={REVIEW_DELIVERABLES}
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              textDecoration="underlined"
            >
              here
            </Link>
          </HideIfCommercial>
        </TooltipContainer>
      }
    >
      <Box ml="s-8">
        <InfoOutline size={18} />
      </Box>
    </Tooltip>
  )
}

export default IndividualContributorTooltip
