import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import Loader from '@src/components/CommonSC/Loader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { BenefitsInterface } from '@src/interfaces/benefits'
import General from './General'
import Preview from './Preview'
import { Package, NewPackage } from './Package'
import { benefitsRequests } from '@src/api/benefits'

const Benefits = () => {
  const { values } = useLapeContext<BenefitsInterface>()
  const isPackageRoute = !!useRouteMatch([
    ROUTES.FORMS.BENEFITS.PACKAGE_PREVIEW,
    ROUTES.FORMS.BENEFITS.PACKAGE_EDIT,
  ])
  const isNewPackageRoute = !!useRouteMatch(ROUTES.FORMS.BENEFITS.PACKAGE_NEW)

  if (!values) {
    return <Loader />
  }

  if (isPackageRoute) {
    return <Package />
  }

  if (isNewPackageRoute) {
    return <NewPackage />
  }

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.BENEFITS.PREVIEW}>
          <Preview />
        </Route>
        <Route exact path={ROUTES.FORMS.BENEFITS.GENERAL}>
          <General />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={benefitsRequests} disableLocalStorageCaching>
    <Benefits />
  </Form>
))
