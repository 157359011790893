import React, { useCallback, useState } from 'react'
import debounce from 'lodash/debounce'
import { Box, ItemSkeleton, Search, StatusWidget, VStack } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import {
  AttachedTemplateInterface,
  DocumentsTemplateTypeId,
} from '@src/interfaces/documentsTemplates'
import SideBar from '@components/SideBar/SideBar'
import { useGetDocumentsTemplates } from '@src/api/documentsTemplates'
import useTabBarSwitcher from '@src/features/TabBarSwitcher/useTabBarSwitcher'
import { NewTemplateForm } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar/NewTemplateForm'
import { TemplateItem } from '../TemplateItem'

enum Tabs {
  SearchExisting = 'Search existing',
  CreateNew = 'New request template',
}

type Props = {
  isOpen: boolean
  onClose: () => void
  attachedTemplates: IdAndName[]
  onAddTemplate: (template: AttachedTemplateInterface) => void
  filterByTypes?: DocumentsTemplateTypeId[]
  allowCreate?: boolean
}
export const SelectTemplatesSidebar = ({
  isOpen,
  onClose,
  attachedTemplates = [],
  onAddTemplate,
  filterByTypes,
  allowCreate = true,
}: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearchValue, setDebouncedSearchValue] = useState('')

  const { data: templatesData, isLoading } = useGetDocumentsTemplates(
    debouncedSearchValue,
    filterByTypes,
  )
  const filteredTemplatesOptions =
    templatesData?.results.filter(
      template =>
        !attachedTemplates.some(attachedTemplate => attachedTemplate.id === template.id),
    ) || []

  const { tabBar, currentTab } = useTabBarSwitcher({
    tabs: [Tabs.SearchExisting, Tabs.CreateNew],
    highlightSelected: false,
  })

  const updateDebouncedSearchValue = useCallback(
    debounce(newValue => {
      setDebouncedSearchValue(newValue)
    }, 300),
    [],
  )

  return (
    <SideBar variant="wide" title="Add templates" isOpen={isOpen} onClose={onClose}>
      {allowCreate && <Box mb="s-24">{tabBar}</Box>}
      {currentTab === Tabs.SearchExisting && (
        <VStack space="s-16">
          <Search
            value={searchValue}
            onChange={newValue => {
              setSearchValue(newValue)
              updateDebouncedSearchValue(newValue)
            }}
            placeholder="Search templates by name"
          />
          {isLoading ? (
            <>
              <ItemSkeleton />
              <ItemSkeleton />
              <ItemSkeleton />
            </>
          ) : filteredTemplatesOptions.length ? (
            filteredTemplatesOptions.map(template => (
              <TemplateItem
                key={template.id}
                template={template}
                handleAdd={onAddTemplate}
              />
            ))
          ) : (
            <StatusWidget>
              <StatusWidget.Image image="https://assets.revolut.com/assets/3d-images-v2/3D086.png" />
              <StatusWidget.Title>No templates found</StatusWidget.Title>
              <StatusWidget.Action onClick={() => setSearchValue('')}>
                Clear search
              </StatusWidget.Action>
            </StatusWidget>
          )}
        </VStack>
      )}
      {currentTab === Tabs.CreateNew && (
        <NewTemplateForm
          onCreated={newTemplate => {
            onAddTemplate(newTemplate)
            onClose()
          }}
        />
      )}
    </SideBar>
  )
}
