import React from 'react'
import { BaseHeaderProps } from '@src/pages/Forms/AssignKPIs/common'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useGetDepartment } from '@src/api/department'
import { Link } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

const DepartmentHeader = ({ id }: BaseHeaderProps) => {
  const { data: department } = useGetDepartment(id)
  return (
    <PageHeader
      title="Assign department KPIs"
      backUrl="/"
      subtitle={
        <Link
          to={pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, { id })}
          use={InternalLink}
          target="_blank"
          color="inherit"
        >
          {department?.name}
        </Link>
      }
    />
  )
}

export default DepartmentHeader
