import { useGetTeamGoalsStats } from '@src/api/teams'
import { useGetDepartmentGoalsStats } from '@src/api/department'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { GoalsOwner } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/GoalsModel'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

const goalsOwnerToFetcherMap: Record<
  GoalsOwner,
  typeof useGetTeamGoalsStats | typeof useGetDepartmentGoalsStats
> = {
  [GoalsOwner.Team]: useGetTeamGoalsStats,
  [GoalsOwner.Department]: useGetDepartmentGoalsStats,
}

interface GoalsStats {
  totalCount: number
  isLoading: boolean
  isError: boolean
}

export const useGoalsStats = (
  cycle: ReviewCyclesInterface,
  goalsOwner: GoalsOwner,
): GoalsStats => {
  const useFetcher = goalsOwnerToFetcherMap[goalsOwner]
  const goalsSourceId = cycleModel.isTest(cycle)
    ? cycleModel.getParentCycleId(cycle)
    : cycle.id

  const { data, isLoading, isError } = useFetcher(goalsSourceId, true)

  return {
    totalCount: data?.count ?? 0,
    isLoading,
    isError,
  }
}
