import React, { useState } from 'react'
import {
  Button,
  Color,
  Image,
  Text,
  VStack,
  useStatusPopup,
  StatusPopup,
  Link,
} from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { reactivateAccount, useAccountClosureInfo } from '@src/api/plans'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { formatDate } from '@src/utils/format'
import { SUPPORT_REVOLUT_PEOPLE } from '@src/constants/emails'
import { dispatch } from '@src/utils/store'
import { setSubscriptionStateAction } from '@src/store/auth/actions'

export const ScheduledForClosure = () => {
  const { data, refetch: refetchAccountClosureInfo } = useAccountClosureInfo()

  const statusPopup = useStatusPopup()

  const [reactivatePending, setReactivatePending] = useState(false)

  if (!data) {
    return <PageLoading />
  }

  const closeDate = formatDate(data.close_date, 'd MMMM yyyy')

  const onReactivateAccount = () => {
    setReactivatePending(true)

    reactivateAccount()
      .then(() => {
        dispatch(setSubscriptionStateAction(undefined))
        setReactivatePending(false)
        refetchAccountClosureInfo()
        statusPopup.show(
          <StatusPopup
            variant="success"
            onClose={() => {
              navigateReplace(ROUTES.MAIN)
            }}
          >
            <StatusPopup.Title>Account reactivated</StatusPopup.Title>
          </StatusPopup>,
        )
      })
      .catch(() => {
        setReactivatePending(false)
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Failed to reactivate account</StatusPopup.Title>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated>
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Your account is scheduled to be closed"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <PageBody alignItems="center">
        <VStack space="s-16" color={Color.GREY_TONE_50}>
          <Text>
            We hope you enjoyed your time with us, however your account is scheduled to be
            closed.
          </Text>
          <Text>
            Your Current Revolut People subscription plan is still valid until{' '}
            <b>{closeDate}</b>. During that time you can use the platform and download all
            the necessary data. However, you will not be able to add any new employees to
            the system.
          </Text>
          <Text>
            After <b>{closeDate}</b> we will close your account, and you will be able to
            only download your employee data backup file during 30 days.
          </Text>
          <Text>
            If you feel that this was a mistake or you have other questions, feel free to
            contact us via our{' '}
            <Link href={`mailto:${SUPPORT_REVOLUT_PEOPLE}`}>support</Link>
          </Text>
        </VStack>

        <Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D009.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D009@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D009@3x.png',
          }}
          maxWidth={300}
        />
      </PageBody>

      <PageActions>
        <VStack space="s-16">
          <Button onClick={onReactivateAccount} pending={reactivatePending}>
            Reactivate account
          </Button>
          <Button onClick={() => navigateTo(ROUTES.MAIN)} variant="secondary">
            Back to dashboard
          </Button>
        </VStack>
      </PageActions>
    </PageWrapper>
  )
}
