import { ReviewCategory, ReviewInterface } from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useGetPerformanceReviewSummary } from '@src/api/performanceReview'
import { useEffect, useState } from 'react'
import { FilterByInterface } from '@src/interfaces/data'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'

export const useFetchPerformanceSummary = (
  cycleId?: string,
  employeeId?: number,
  category?: ReviewCategory | ReviewCycleCategory,
  initialFilters: FilterByInterface[] = [],
  nominationId?: number | string,
) => {
  const [reviewers, setReviewers] = useState<OptionInterface[]>()
  const [checkpoints, setCheckpoints] = useState<OptionInterface[]>()
  const [reviews, setReviews] = useState<ReviewInterface[]>([])
  const [shouldUpdateReviews, setShouldUpdateReviews] = useState(true)

  const filters = [
    {
      filters:
        reviewers?.map(filter => ({
          name: `${filter.id}`,
          id: `${filter.id}`,
        })) || [],
      columnName: category === ReviewCategory.Upwards ? 'id' : 'reviewer__id',
    },
    {
      filters:
        checkpoints?.map(filter => ({
          name: `${filter.id}`,
          id: `${filter.id}`,
        })) || [],
      columnName:
        category === ReviewCategory.PIP_V2
          ? 'performance_checkpoint__number'
          : 'probation_checkpoint__number',
    },
    ...initialFilters,
  ]

  const context = useGetPerformanceReviewSummary(
    cycleId,
    employeeId,
    category,
    filters,
    nominationId,
  )

  useEffect(() => {
    if (context && shouldUpdateReviews) {
      setReviews(context.data?.reviews || [])
    }
  }, [context && context.data?.reviews])

  const refresh = (
    selectedReviewers?: OptionInterface[],
    selectedCheckpoints?: OptionInterface[],
    updateReviews: boolean = true,
  ) => {
    setCheckpoints(selectedCheckpoints)
    setReviewers(selectedReviewers)
    setShouldUpdateReviews(updateReviews)
  }

  const resetReviwersFilters = () => {
    setReviewers([])
  }

  return {
    ...context,
    reviews,
    refresh,
    resetReviwersFilters,
  }
}
