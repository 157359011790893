import React, { useEffect, useState } from 'react'
import { connect, useLape } from 'lape'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import pick from 'lodash/pick'
import forEach from 'lodash/forEach'
import { KpiGoals, KpiInterface, KpiReviewCycle, KpiTargets } from '@src/interfaces/kpis'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import SideBar from '@components/SideBar/SideBar'
import CycleSelector from '@src/features/FormTabs/Kpi/KPITargets/common/CycleSelector'
import { Button, Input, InputGroup, Side } from '@revolut/ui-kit'
import { roundFloat } from '@src/utils/numbers'
import { strategyLabelsMap } from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { probationCycleCategory } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'

interface Props {
  isOpen: boolean
  targetIndex: number | null
  onSubmit: (target: KpiTargets) => void
  onClose: () => void
  defaultCycle: ReviewCyclesInterface | KpiReviewCycle
  availableCycles: ReviewCyclesInterface[]
  canEditCycle: boolean
  dynamicTargets?: boolean
}

enum Field {
  kpi_goal = 'kpi_goal',
  target = 'target',
  initial_value = 'initial_value',
  parent_kpi = 'parent_kpi',
  weight = 'weight',
  owner = 'owner',
}

const getDefaultValues = (
  kpi: KpiInterface,
  thisTarget: KpiTargets | null,
  defaultCycle: ReviewCyclesInterface | KpiReviewCycle,
  dynamicTargets: boolean,
): KpiTargets => {
  if (thisTarget) {
    // return a copy to not modify the original
    return {
      ...thisTarget,
      review_cycle: thisTarget.is_probation
        ? ({
            id: 0,
            name: 'Probation',
            category: probationCycleCategory,
          } as KpiReviewCycle)
        : thisTarget.review_cycle,
    }
  }
  const numTargets = kpi.targets.length
  const lastTarget = numTargets ? kpi.targets[numTargets - 1] : null

  const canBeTopdown = !!kpi.team || kpi.department

  return {
    initial_value: dynamicTargets || !lastTarget?.target ? null : lastTarget?.target,
    target: null,
    parent_kpi: null,
    owner: canBeTopdown ? kpi.owner : undefined,
    is_top_down: false,
    kpi_goal: lastTarget?.kpi_goal || KpiGoals.increase,
    review_cycle: defaultCycle,
    is_probation: defaultCycle.category === probationCycleCategory,
  }
}

const errorsByStrategy = {
  [KpiGoals.decrease]:
    'Initial value should be greater than target value if strategy is "decrease"',
  [KpiGoals.increase]:
    'Target value should be greater than initial value if strategy is "increase"',
  [KpiGoals.keep_target]:
    'Upper limit should be greater than lower limit if strategy is "maintain"',
}

interface ErrorsByFiled {
  [Field.kpi_goal]?: string
  [Field.initial_value]?: string
  [Field.target]?: string
  [Field.parent_kpi]?: string
  [Field.weight]?: string
}

const validateForStrategy = (values: KpiTargets): string | null => {
  let isValid = true

  if (isNumber(values.initial_value) && isNumber(values.target)) {
    switch (values.kpi_goal) {
      case KpiGoals.increase:
      case KpiGoals.keep_target:
        isValid = values.initial_value < values.target
        break
      case KpiGoals.decrease:
        isValid = values.initial_value > values.target
        break
      default:
        isValid = true
        break
    }
  }
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  return isValid ? null : errorsByStrategy[values.kpi_goal]
}

const validateForMissing = (
  values: KpiTargets,
  kpi: KpiInterface,
  targetIndex: number | null,
  dynamicTargets?: boolean,
): ErrorsByFiled => {
  const allTargets = kpi.targets

  const requiredMessage = 'This field is required'
  const isFirstTarget =
    targetIndex === 0 || (targetIndex === null && allTargets.length < 1)

  const fieldsArr = [
    Field.kpi_goal,
    ...(!dynamicTargets ? [Field.target, Field.initial_value] : []),
  ]
  let result: ErrorsByFiled = {}

  forEach(fieldsArr, field => {
    const value: unknown = values[field]
    const noValue = isNil(value) || value === ''

    if (noValue && isFirstTarget) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      result[field] = requiredMessage
    } else if (noValue && field === Field.kpi_goal) {
      result[field] = requiredMessage
    }
  })

  if (values.weight && values.weight > 100) {
    result[Field.weight] = 'Weight cannot be higher than 100'
  }

  return result
}

const TemplateTargetForm = ({
  isOpen,
  targetIndex,
  onSubmit,
  onClose,
  availableCycles,
  defaultCycle,
  canEditCycle,
  dynamicTargets,
}: Props) => {
  const kpiForm = useLapeContext<KpiInterface>()
  const { values: kpi } = kpiForm

  const thisTarget = targetIndex !== null ? kpi.targets[targetIndex] : null
  const disabled =
    thisTarget?.review_cycle?.status === ReviewCycleStatus.closed || kpiForm.disabled

  const [errors, setErrors] = useState<ErrorsByFiled>({})

  const state = useLape<{ values: KpiTargets }>({
    values: getDefaultValues(kpi, thisTarget, defaultCycle, !!dynamicTargets),
  })
  const { values } = state
  const isInMaintainMode = values.kpi_goal === KpiGoals.keep_target

  const readonlyFields = new Set(kpi.field_options?.read_only || [])

  const { options: kpiGoalOptions } = useFetchOptions<IdAndName<KpiGoals>>(
    selectorKeys.kpi_goals,
  )
  const noKpiGoalOption =
    !!kpiGoalOptions.length &&
    !!values.kpi_goal &&
    !kpiGoalOptions.find(opt => opt.value.id === values.kpi_goal)

  useEffect(() => {
    if (isOpen) {
      state.values = getDefaultValues(kpi, thisTarget, defaultCycle, !!dynamicTargets)
    }
  }, [isOpen, thisTarget, defaultCycle])

  useEffect(() => {
    // revalidate only if there are errors from the last submit attempt
    if (!isEmpty(errors)) {
      setErrors(validate())
    }
  }, [
    values.target,
    values.initial_value,
    values.kpi_goal,
    values.parent_kpi,
    values.weight,
  ])

  const checkDisabled = (field: Field) => {
    return disabled || readonlyFields.has(`targets[${targetIndex}]__${field}`)
  }

  const validate = (): ErrorsByFiled => {
    const errs = validateForMissing(values, kpi, targetIndex, dynamicTargets)
    const strategyError = validateForStrategy(values)

    if (strategyError) {
      errs.target = strategyError
    }
    return errs
  }

  const calcProgress = (): number => {
    if (
      !kpi.current_progress ||
      !isNumber(values.target) ||
      !isNumber(values.initial_value)
    ) {
      return 0
    }
    if (isInMaintainMode) {
      if (
        kpi.current_progress >= values.initial_value &&
        kpi.current_progress <= values.target
      ) {
        return 1
      }

      const closestLimit =
        kpi.current_progress > values.initial_value ? values.target : values.initial_value

      const diff = Math.abs(closestLimit - kpi.current_progress)

      const raw = 1 - diff / closestLimit
      const calibrated = Math.max(raw, 0)

      return roundFloat(calibrated, 2)
    }
    const raw =
      Math.max(kpi.current_progress - values.initial_value, 0) /
      Math.abs(values.target - values.initial_value)

    const calibrated = Math.min(1.5, raw)

    return roundFloat(calibrated, 2)
  }

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      data-testid="value_targets_form"
      title="Set metrics"
    >
      <InputGroup>
        <CycleSelector
          disabled={disabled || !canEditCycle}
          value={values.employee_cycle || values.review_cycle}
          reviewCycles={availableCycles}
          onSelect={cycle => {
            if (cycle.category === probationCycleCategory) {
              values.review_cycle = cycle as KpiReviewCycle
              values.is_probation = true
            } else {
              values.review_cycle = cycle as KpiReviewCycle
              values.employee_cycle = undefined
              values.is_probation = false
            }
          }}
        />
        <RadioSelectInput
          disabled={checkDisabled(Field.kpi_goal)}
          clearable={false}
          value={
            values.kpi_goal
              ? { id: values.kpi_goal, name: strategyLabelsMap[values.kpi_goal] }
              : null
          }
          options={kpiGoalOptions}
          label="Strategy"
          hasError={!!errors.kpi_goal || noKpiGoalOption}
          message={
            errors.kpi_goal || noKpiGoalOption
              ? 'KPI strategy needs to be changed'
              : undefined
          }
          onChange={option => {
            if (option) {
              values.kpi_goal = option.id
            }
          }}
        />
        <Input
          disabled={checkDisabled(Field.initial_value) || dynamicTargets}
          type="number"
          value={isNumber(values.initial_value) ? values.initial_value : ''}
          label={isInMaintainMode ? 'Lower limit' : 'Initial value'}
          aria-invalid={!!errors.initial_value}
          message={errors.initial_value}
          onChange={e => {
            const value = Number(e.currentTarget.value)
            values.initial_value =
              isNaN(value) || e.currentTarget.value === '' ? null : value
          }}
        />
        <Input
          disabled={checkDisabled(Field.target) || dynamicTargets}
          type="number"
          value={isNumber(values.target) ? values.target : ''}
          label={isInMaintainMode ? 'Upper limit' : 'Target'}
          aria-invalid={!!errors.target}
          message={errors.target}
          onChange={e => {
            const value = Number(e.currentTarget.value)
            values.target = isNaN(value) || e.currentTarget.value === '' ? null : value
          }}
        />
        <Input
          disabled={checkDisabled(Field.weight)}
          type="number"
          aria-invalid={!!errors.weight}
          message={errors.weight}
          value={values.weight || ''}
          label="Weight (optional)"
          onChange={e => {
            values.weight = Number(e.currentTarget.value)
          }}
        />
      </InputGroup>
      {!disabled && (
        <Side.Actions>
          <Button
            onClick={() => {
              const errs = validate()

              isEmpty(errs)
                ? onSubmit({
                    ...values,
                    progress: calcProgress(),
                    review_cycle: values.is_probation
                      ? undefined
                      : (pick(values.review_cycle, [
                          'id',
                          'name',
                          'offset',
                          'status',
                          'category',
                        ]) as KpiReviewCycle),
                  })
                : setErrors(errs)
            }}
          >
            Save changes
          </Button>
        </Side.Actions>
      )}
    </SideBar>
  )
}

export default connect(TemplateTargetForm)
