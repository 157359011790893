import React from 'react'
import { matchPath, Route, Switch, useLocation, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { dashboardRequestsNew } from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { NextButton } from './components/NextButton'
import { SubmitButton } from './components/SubmitButton'
import { DashboardForm } from './DashboardForm'
import { PreviewForm } from './PreviewForm'
import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Token } from '@revolut/ui-kit'

const Dashboard = connect(() => {
  const { values } = useLapeContext<AnalyticsDashboardInterface>()
  const params = useParams<{ id?: string }>()
  const location = useLocation()

  const tabs = [
    {
      title: 'Dashboard details',
      path: ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS, params),
      component: DashboardForm,
    },
    {
      title: 'Preview & Approve',
      path: ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.PREVIEW, params),
      component: PreviewForm,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.FORMS.COMPANY.ANALYTICS_DASHBOARDS}
        title={values.name || 'Set up Dashboard'}
      >
        <TabBarNavigation mb="s-16" tabs={tabs} />
      </PageHeader>
      <Switch>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS}>
          <DashboardForm />
        </Route>
        <Route exact path={ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.PREVIEW}>
          <PreviewForm />
        </Route>
      </Switch>
      <PageActions
        maxWidth={
          matchPath(location.pathname, ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.PREVIEW)
            ? Token.breakpoint.xxl
            : undefined
        }
      >
        <NextButton tabs={tabs} />
        <SubmitButton tabs={tabs} />
      </PageActions>
    </PageWrapper>
  )
})

const DashboardEditForm = () => {
  return (
    <OrgEntityProvider>
      <Form
        api={dashboardRequestsNew}
        localStorageProps={{
          followUrlTransitions: true,
          removeDataAfterTransitionFromUrls: [
            pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.DETAILS),
            pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD.PREVIEW),
          ],
        }}
      >
        <Dashboard />
      </Form>
    </OrgEntityProvider>
  )
}

export default connect(DashboardEditForm)
