import { GetRequestInterface, RequestInterfaceNew, IdAndName } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  NotificationTemplateInterface,
  NotificationSendingHistoryInterface,
  NotificationLogsInterface,
} from '@src/interfaces/notificationTemplate'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { usePost, useDelete, useUpdate } from '@src/utils/reactQuery'
import { TestQueryResponse } from '@src/features/AudienceSelection/SQLQuery'

export const getNotificationList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<NotificationTemplateInterface>
> =>
  api.get(API.NOTIFICATION_TEMPALTE, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const notificationTemplateRequests: RequestInterfaceNew<NotificationTemplateInterface> =
  {
    get: async ({ id }) => api.get(`${API.NOTIFICATION_TEMPALTE}/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.NOTIFICATION_TEMPALTE}/${id}`, data),
    submit: async data => apiWithoutHandling.post(API.NOTIFICATION_TEMPALTE, data),
    delete: async ({ id }) => api.delete(`${API.NOTIFICATION_TEMPALTE}/${id}`),
  }

export const sendNotification = (notification: NotificationTemplateInterface) =>
  api.post(
    `${API.NOTIFICATION_TEMPALTE}/${notification.id}/sendNotification`,
    notification,
  )

export const testNotificationTemplate = (notification: NotificationTemplateInterface) =>
  api.post(`${API.NOTIFICATION_TEMPALTE}/testTemplate`, notification)

export const sendTestNotification = (notification: NotificationTemplateInterface) =>
  api.post(`${API.NOTIFICATION_TEMPALTE}/${notification.id}/sendTest`, notification)

export const testNotificationSqlQuery = (
  notification: NotificationTemplateInterface,
): AxiosPromise<TestQueryResponse> =>
  apiWithoutHandling.post(`${API.NOTIFICATION_TEMPALTE}/testQuery`, notification)

export const useNotificationSqlQuery = () =>
  usePost<TestQueryResponse, TestQueryResponse, NotificationTemplateInterface>(
    `${API.NOTIFICATION_TEMPALTE}/testQuery`,
    'v1',
    undefined,
    undefined,
    true,
  )

export const getNotificationSendingHistory =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<NotificationSendingHistoryInterface>
  > => {
    return api.get(`${API.NOTIFICATION_TEMPALTE}/${id}/requests`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const getNotificationLogs =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<NotificationLogsInterface>
  > => {
    return api.get(`${API.NOTIFICATION_TEMPALTE}/${id}/requestMessageLogs`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const getNotificationPlaceholders = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<IdAndName>> =>
  api.get(`${API.NOTIFICATION_TEMPALTE}/placeholders`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useNotificationRequestDelete = (id: number) =>
  useDelete(`${API.NOTIFICATION_TEMPALTE}/requests/${id}`)

export const useNotificationRequestRetry = (id: number) =>
  useUpdate(
    `${API.NOTIFICATION_TEMPALTE}/requests/${id}/retry`,
    undefined,
    undefined,
    true,
  )
