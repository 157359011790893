import { useMemo } from 'react'
import { useQueriesOptions } from '@src/api/dataAnalytics'

const filtersParams = [
  {
    filters: [{ id: 'active', name: `active` }],
    columnName: 'status',
  },
]

export const useQueryOptions = () => {
  const queriesData = useQueriesOptions(filtersParams)

  const queriesOptions = useMemo(
    () =>
      queriesData ? queriesData.map(query => ({ label: query.name, value: query })) : [],
    [queriesData],
  )

  return { queriesOptions }
}
