import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { api } from './index'
import { API } from '@src/constants/api'
import { RequestInterface, TableRequestInterface } from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { EmployeeInterface, EmployeeOptionInterface } from '@src/interfaces/employees'
import {
  DataOwnershipInterface,
  VendorOwnershipInterface,
} from '@src/interfaces/ownership'
import { RequisitionInterface } from '@src/interfaces/requisitions'

export const departmentOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; owner: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/departmentOwnershipTransfer`, ownership)

export const teamOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; team_owner: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/teamOwnershipTransfer`, ownership)

export const directReportsOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; line_manager: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/directReportsTransfer`, ownership)

export const functionalReportsOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; quality_control: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/functionalReportsTransfer`, ownership)

export const getDepartmentOwnership = (
  employeeId: number,
): Pick<RequestInterface<DepartmentInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/departmentOwnershipTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getTeamOwnership = (
  employeeId: number,
): Pick<RequestInterface<TeamInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/teamOwnershipTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getFunctionalReportsOwnership = (
  employeeId: number,
): Pick<RequestInterface<EmployeeInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/functionalReportsTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getDirectReportsOwnership = (
  employeeId: number,
): Pick<RequestInterface<EmployeeInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/directReportsTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getTeamOwnershipPayload = (
  employeeId: number,
  owner: EmployeeOptionInterface,
) => {
  return getTeamOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(team => ({
        id: team.id,
        team_owner: owner.id,
      })),
    )
}

export const getDepartmentOwnershipPayload = (
  employeeId: number,
  owner: EmployeeOptionInterface,
) => {
  return getDepartmentOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(department => ({
        id: department.id,
        owner: owner.id,
      })),
    )
}

export const getDirectReportsOwnershipPayload = (
  employeeId: number,
  owner: EmployeeOptionInterface,
) => {
  return getDirectReportsOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        line_manager: owner.id,
      })),
    )
}

export const getFunctionalReportsOwnershipPayload = (
  employeeId: number,
  owner: EmployeeOptionInterface,
) => {
  return getFunctionalReportsOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        quality_control: owner.id,
      })),
    )
}

export const getRequisitionsRecruiterOwnership = (
  employeeId: number,
): Pick<RequestInterface<RequisitionInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/requisitionRecruiterTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getRequisitionsManagerOwnership = (
  employeeId: number,
): Pick<RequestInterface<RequisitionInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/requisitionLineManagerTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getRequisitionsRecruiterOwnershipPayload = (
  employeeId: number,
  recruiter: EmployeeOptionInterface,
) => {
  return getRequisitionsRecruiterOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(team => ({
        id: team.id,
        recruiter: recruiter.id,
      })),
    )
}

export const getRequisitionsManagerOwnershipPayload = (
  employeeId: number,
  recruiter: EmployeeOptionInterface,
) => {
  return getRequisitionsManagerOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(team => ({
        id: team.id,
        line_manager: recruiter.id,
      })),
    )
}

export const requisitionRecruiterOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; recruiter: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/requisitionRecruiterTransfer`, ownership)

export const requisitionManagerOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; line_manager: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/requisitionLineManagerTransfer`, ownership)

export const getVendorOwnership = (
  employeeId: number,
): Pick<RequestInterface<VendorOwnershipInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/vendorsOwnership`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getDataOwnership = (
  employeeId: number,
): Pick<RequestInterface<DataOwnershipInterface>, 'getItems'> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/dataOwnership`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getJobPostingsRecruiterOwnership = (
  employeeId: number,
): TableRequestInterface<EmployeeInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/jobPostingRecruiterTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getJobPostingsRecruiterOwnershipPayload = (
  employeeId: number,
  recruiter: EmployeeOptionInterface,
) => {
  return getJobPostingsRecruiterOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        recruiter: recruiter.id,
      })),
    )
}

export const jobPostingsRecruiterOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; recruiter: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/jobPostingRecruiterTransfer`, ownership)

export const getCandidatesRecruiterOwnership = (
  employeeId: number,
): TableRequestInterface<EmployeeInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/interviewRoundRecruiterTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getCandidatesRecruiterOwnershipPayload = (
  employeeId: number,
  recruiter: EmployeeOptionInterface,
) => {
  return getCandidatesRecruiterOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        recruiter: recruiter.id,
      })),
    )
}

export const candidatesRecruiterOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; recruiter: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/interviewRoundRecruiterTransfer`, ownership)

export const getCandidatesHiringManagerOwnership = (
  employeeId: number,
): TableRequestInterface<EmployeeInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/interviewRoundHiringManagerTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getCandidatesHiringManagerOwnershipPayload = (
  employeeId: number,
  hiring_manager: EmployeeOptionInterface,
) => {
  return getCandidatesHiringManagerOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        hiring_manager: hiring_manager.id,
      })),
    )
}

export const candidatesHiringManagerOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; hiring_manager: number | string }[],
) =>
  api.put(`${API.EMPLOYEES}/${employeeId}/interviewRoundHiringManagerTransfer`, ownership)

export const getOffersOwnership = (
  employeeId: number,
): TableRequestInterface<EmployeeInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/offerFormOwnerTransfer`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const getOffersOwnershipPayload = (
  employeeId: number,
  owner: EmployeeOptionInterface,
) => {
  return getOffersOwnership(employeeId)
    .getItems({})
    .then(result =>
      result.data.results.map(employee => ({
        id: employee.id,
        owner: owner.id,
      })),
    )
}

export const offersOwnershipTransfer = (
  employeeId: number,
  ownership: { id: number | string; owner: number | string }[],
) => api.put(`${API.EMPLOYEES}/${employeeId}/offerFormOwnerTransfer`, ownership)
