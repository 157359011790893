import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, ActionButtonSkeleton } from '@revolut/ui-kit'

import { useOpenNewTab } from '@src/actions/RouterActions'
import {
  enableDeelIntegration,
  useGetDeelIntegrationPreferences,
} from '@src/api/settings'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const EnableButton = () => {
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()
  const permissions = useSelector(selectPermissions)

  const disabled = !permissions.includes(PermissionTypes.ChangeDeelIntegration)

  const [actionPending, setActionPending] = useState(false)

  const { data: preferencesData, isLoading: isPreferencesDataLoading } =
    useGetDeelIntegrationPreferences()
  const openNewTab = useOpenNewTab()

  if (isPreferencesDataLoading) {
    return <ActionButtonSkeleton />
  }
  if (preferencesData?.enabled) {
    return (
      <ActionButton
        useIcon="Unlink"
        variant="negative"
        pending={actionPending}
        onClick={() => {
          confirmationPopup.show({
            label: 'Are you sure?',
            body: 'After disabling this integration you won’t be able to use Deel platform for your payroll needs',
            onConfirm: async () => {
              try {
                throw new Error('This action is not allowed yet')
              } catch (error) {
                showStatusPopup({
                  title: 'Could not disable Deel integration',
                  description: getStringMessageFromError(error),
                  status: 'error',
                })
              }
            },
          })
        }}
        disabled
      >
        Disable
      </ActionButton>
    )
  }
  return (
    <ActionButton
      useIcon="Link"
      pending={actionPending}
      disabled={disabled}
      variant="accent"
      onClick={async () => {
        try {
          setActionPending(true)
          const res = await enableDeelIntegration()
          if (res.data.oauth_url) {
            openNewTab(res.data.oauth_url)
          } else {
            throw new Error('Redirect url was not found')
          }
        } catch (error) {
          showStatusPopup({
            title: 'Could not enable Deel integration',
            description: getStringMessageFromError(error),
            status: 'error',
          })
        } finally {
          setActionPending(false)
        }
      }}
    >
      Enable
    </ActionButton>
  )
}
