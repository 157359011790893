import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { PlaybookInterface } from '@src/interfaces/playbooks'
import Rating from '@src/components/ColumnInserts/Rating/Rating'
import Url from '@src/components/ColumnInserts/Url/Url'

export const playbookTitleColumn: ColumnInterface<PlaybookInterface> = {
  type: CellTypes.insert,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Playbook title',
  insert: ({ data, children }) => <Url href={data.page_url}>{children}</Url>,
}

export const playbookLastUpdatedColumn: ColumnInterface<PlaybookInterface> = {
  type: CellTypes.date,
  idPoint: 'last_updated',
  dataPoint: 'last_updated',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last updated',
}

export const playbookRatingColumn: ColumnInterface<PlaybookInterface> = {
  type: CellTypes.insert,
  idPoint: 'average_rating',
  dataPoint: 'average_rating',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Playbook rating',
  insert: ({ data }) => {
    if (data.average_rating === null) {
      return '-'
    }
    return <Rating score={data.average_rating} />
  },
}
