import { useLocation } from 'react-router-dom'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useEffect } from 'react'

// we need to understand from which page users came from, we cannot use location.state.history,
// because user can open pages in a new tab (from tables for example) and document.referrer works unstable as well
export const useSaveLocationHistory = () => {
  const location = useLocation()
  const [savedLocationHistory, setSavedLocationHistory] = useLocalStorage<string[]>(
    LOCAL_STORAGE.LOCATION_HISTORY,
    [],
  )

  useEffect(() => {
    if (!savedLocationHistory) {
      setSavedLocationHistory([location.pathname])
    } else {
      setSavedLocationHistory(
        [location.pathname + location.search, ...savedLocationHistory].slice(0, 3),
      )
    }
  }, [location.pathname, location.search])
}
