import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Groups } from '@src/pages/Settings/Groups/Groups'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { employeeSettings } from '@src/api/settings'

const routes = [
  {
    title: 'Group app settings',
    description: 'All settings for employee groups',
    path: ROUTES.SETTINGS.GROUPS,
    url: ROUTES.SETTINGS.GROUPS,
    canView: [
      PermissionTypes.ViewEmployeePreferences,
      PermissionTypes.ChangeEmployeePreferences,
    ],
    component: Groups,
  },
]

export const GroupsSettings = () => {
  return <SettingsForm routes={routes} api={employeeSettings} />
}
