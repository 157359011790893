import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import { selectorKeys } from '@src/constants/api'
import Table from '@components/TableV2/Table'

export const groupNameColumn: ColumnInterface<GroupAccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  title: 'Group',
  selectorsKey: selectorKeys.groups,
}

export const descriptionColumn: ColumnInterface<GroupAccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'settings.description',
  dataPoint: 'settings.description',
  sortKey: 'settings__description',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const permissionsCountColumn: ColumnInterface<GroupAccessManagementInterface> = {
  type: CellTypes.text,
  idPoint: 'settings.permissions_count',
  dataPoint: 'settings.permissions_count',
  sortKey: 'settings__permissions_count',
  filterKey: 'settings__permissions_count',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Permissions count',
}

export const ownerColumn: ColumnInterface<GroupAccessManagementInterface> = {
  type: CellTypes.insert,
  idPoint: 'settings.owner',
  dataPoint: 'settings.owner',
  sortKey: 'settings__owner__full_name',
  filterKey: 'settings__owner_id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.settings?.owner} />,
}

export const certificationExpirationDateColumn: ColumnInterface<GroupAccessManagementInterface> =
  {
    type: CellTypes.date,
    idPoint: 'settings.certification_expiration_date_time',
    dataPoint: 'settings.certification_expiration_date_time',
    sortKey: 'settings__certification_expiration_date_time',
    filterKey: 'settings__certification_expiration_date_time',
    filterType: FilterType.date,
    selectorsKey: selectorKeys.none,
    title: 'Certification expiration date',
  }
