import { IdContractTypes } from '@src/interfaces/contracts'
import { EmployeeType } from '@src/interfaces/reviewCycles'

export interface ProbationTemplateInterface {
  id: number
  name: string
  num_checkpoints: number
  amount_of_months: number
  policy_document_link?: string
  has_committee_decision?: boolean
  timelines: ProbationTemplateTimeline[]
  status: 'enabled' | 'disabled'
  total_weeks: number
  rules: ProbationTemplateRulesInterface
  allow_extension: boolean
}

export enum ProbationTemplateRules {
  seniority_id = 'seniority_id',
  team_id = 'team_id',
  team__department_id = 'team__department_id',
  specialisation_id = 'specialisation_id',
  position_id = 'position_id',
  position__function_id = 'position__function_id',
  employee_type = 'employee_type',
  contract_type = 'contract_type',
  location_id = 'location_id',
  entity_id = 'entity_id',
}

export const ProbationTemplateRulesNames = {
  [ProbationTemplateRules.seniority_id]: 'Seniority',
  [ProbationTemplateRules.team_id]: 'Teams',
  [ProbationTemplateRules.team__department_id]: 'Departments',
  [ProbationTemplateRules.specialisation_id]: 'Specialisations',
  [ProbationTemplateRules.position_id]: 'Roles',
  [ProbationTemplateRules.position__function_id]: 'Functions',
  [ProbationTemplateRules.employee_type]: 'Employee type',
  [ProbationTemplateRules.contract_type]: 'Contract type',
  [ProbationTemplateRules.location_id]: 'Location',
  [ProbationTemplateRules.entity_id]: 'Entity',
}

export interface ProbationTemplateRuleInterface {
  id: number | EmployeeType | IdContractTypes
  name: string
}

export interface ProbationTemplateRulesInterface {
  seniority_id?: ProbationTemplateRuleInterface[]
  team_id?: ProbationTemplateRuleInterface[]
  team__department_id?: ProbationTemplateRuleInterface[]
  specialisation_id?: ProbationTemplateRuleInterface[]
  position_id?: ProbationTemplateRuleInterface[]
  position__function_id?: ProbationTemplateRuleInterface[]
  employee_type?: ProbationTemplateRuleInterface[]
  contract_type?: ProbationTemplateRuleInterface[]
  location_id?: ProbationTemplateRuleInterface[]
  entity_id?: ProbationTemplateRuleInterface[]
}

export interface ProbationTemplateTimeline {
  id?: number
  timeline_type: ProbationTemplateTimelineType
  weeks_due_date: number
  duration: number
  reference: ProbationTemplateTimelineReference
  probation_template: number
}

export enum ProbationTemplateTimelineType {
  goals = 'goals',
  review = 'review',
  decision = 'decision',
}

export enum ProbationTemplateTimelineReference {
  AfterJoining = 'after_joining',
  BeforeEnd = 'before_end',
}

export interface ProbationTemplatePreviewRequest {
  goals_setting_week_after_start: number
  final_decision_week_before_end: number
  num_checkpoints: number
  months: number
}

export interface ProbationTemplatePreviewResponse
  extends ProbationTemplatePreviewRequest {
  checkpoints_timeline: ProbationTemplateTimeline[]
  maximum_checkpoints: number
  total_weeks: number
}
