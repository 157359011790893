import React from 'react'
import { CompensationBandInterface } from '@src/interfaces/benchmarks'
import CompensationBandBase from '@components/LocationCompensationBand/CompensationBandBase'

type Props = {
  bands?: CompensationBandInterface[]
}

const PostingCompensationBand = ({ bands }: Props) => {
  return (
    <CompensationBandBase
      text="To comply with legal requirements of the below locations, compensation bands should be displayed. Kindly reach out to the compensation team to provide you with compensation bands for selected specialisation and locations. Posting can only be published if compensation bands have been added for the following locations:"
      bands={bands}
    />
  )
}

export default PostingCompensationBand
