import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportRequisitionsDataInterface } from '@src/interfaces/importRequisitions'
import { selectorKeys } from '../api'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportCell } from '@src/constants/columns/bulkDataImport'

export type ImportRequisitionsCellOptions = {
  employeeOptions: SelectOptionItemType<EmployeeEmails>[]
}

export const importRequisitionsRequisitionTitleColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'requisition_title',
  dataPoint: 'requisition_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => <ImportCell field="requisition_title" data={data} />,
}

export const importRequisitionsSpecialisationColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Role specialisation',
  insert: ({ data }) => <ImportCell field="specialisation" data={data} />,
}

export const importRequisitionsSeniorityMinColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'seniority_min',
  dataPoint: 'seniority_min',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Lowest seniority',
  insert: ({ data }) => <ImportCell field="seniority_min" data={data} />,
}

export const importRequisitionsSeniorityMaxColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'seniority_max',
  dataPoint: 'seniority_max',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Highest seniority',
  insert: ({ data }) => <ImportCell field="seniority_max" data={data} />,
}

export const importRequisitionsTeamColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'team',
  dataPoint: 'team',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Team',
  insert: ({ data }) => <ImportCell field="team" data={data} />,
}

export const importRequisitionsLocationsColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'locations',
  dataPoint: 'locations',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Locations',
  insert: ({ data }) => <ImportCell field="locations" data={data} />,
}

export const importRequisitionsLineManagerColumn = (
  options: ImportRequisitionsCellOptions,
): ColumnInterface<ImportInterface<ImportRequisitionsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'line_manager',
  dataPoint: 'line_manager',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Line manager',
  insert: ({ data }) => (
    <ImportCell
      field="line_manager"
      data={data}
      options={options?.employeeOptions}
      type="employee"
    />
  ),
})

export const importRequisitionsHeadcountColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'headcount',
  dataPoint: 'headcount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Positions to fill',
  insert: ({ data }) => <ImportCell field="headcount" data={data} />,
}

export const importRequisitionsRecruiterColumn = (
  options: ImportRequisitionsCellOptions,
): ColumnInterface<ImportInterface<ImportRequisitionsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'recruiter',
  dataPoint: 'recruiter',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Recruiter',
  insert: ({ data }) => (
    <ImportCell
      field="recruiter"
      data={data}
      options={options?.employeeOptions}
      type="employee"
    />
  ),
})

export const importRequisitionsPotentialStartDateColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'potential_start_date',
  dataPoint: 'potential_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Potential start date',
  insert: ({ data }) => (
    <ImportCell field="potential_start_date" data={data} type="date" />
  ),
}

export const importRequisitionsDescriptionColumn: ColumnInterface<
  ImportInterface<ImportRequisitionsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
  insert: ({ data }) => <ImportCell field="description" data={data} />,
}
