import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import React from 'react'
import { TimelineEventInterface } from '@src/interfaces/timeline'
import TimelineCommentsSidebar from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineCommentsSidebar'
import TimelineEmailSidebar from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineEmailSidebar'
import TimelineScorecardSidebar from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineScorecardSidebar'
import TimelineScheduleSidebar from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineScheduleSidebar'
import { CandidateInterface } from '@src/interfaces/interviewTool'

interface TimelineSidebarProps {
  candidate: CandidateInterface
  event?: TimelineEventInterface
  onClose: () => void
}

const TimelineSidebar = ({ candidate, event, onClose }: TimelineSidebarProps) => {
  if (!event) {
    return null
  }

  return (
    <>
      {event.category === 'file_added' && (
        <PreviewDocumentSidebar
          file={{ id: event.object_info.file_id, name: event.object_info.file_name }}
          onClose={onClose}
        />
      )}
      {event.category === 'comment_added' && (
        <TimelineCommentsSidebar onClose={onClose} roundId={event.object_info.round_id} />
      )}
      {event.category === 'scorecard_submitted' && (
        <TimelineScorecardSidebar
          onClose={onClose}
          scorecardId={event.object_info.scorecard_id}
          roundId={event.object_info.round_id}
        />
      )}
      {event.category === 'interview_scheduled' && (
        <TimelineScheduleSidebar
          onClose={onClose}
          candidate={candidate}
          roundId={event.object_info.round_id}
          stageId={event.object_info.stage_id}
          isPrepCall={event.object_info.is_adhoc}
          interviewId={event.object_id}
        />
      )}
      {event.category === 'email_thread' && (
        <TimelineEmailSidebar
          onClose={onClose}
          candidateId={event.object_info.candidate_id}
          threadId={event.object_info.thread_id}
          emailId={event.object_info.email_id}
        />
      )}
    </>
  )
}

export default TimelineSidebar
