import React from 'react'
import { Box, Color, HStack, Text, Widget } from '@revolut/ui-kit'
import styled from 'styled-components'
import { formatPercentage } from '@src/utils/format'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import SummaryTable from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SummaryCard/SummaryTable'
import { InterviewFeedbackSummaryInterface } from '@src/interfaces/interviewTool'
import { PerformanceRatingTitle } from '@src/constants/performance'
import Tooltip from '@components/Tooltip/Tooltip'
import { InfoOutline } from '@revolut/icons'

const Container = styled.div`
  display: grid;
  grid-column-gap: 16px;
`

type Props = {
  points: number
  titleIndexNumber: number
  onlyTotal?: boolean
  passScore: number | null
  maxScore: number | null
  type?: InterviewScorecardType
  summary?: InterviewFeedbackSummaryInterface
}

const SummaryCard = ({
  points,
  titleIndexNumber,
  onlyTotal,
  passScore,
  maxScore,
  type,
  summary,
}: Props) => {
  if (onlyTotal) {
    return (
      <Box padding="s-16" radius="widget" backgroundColor="action-background" mb="s-16">
        <Text use="div" variant="primary" mb="s-16" fontSize="h5">
          {titleIndexNumber}. Summary
        </Text>
        <Text fontSize="caption" use="div" color="foreground">
          Total Score
        </Text>
        <Text fontSize="h5" use="div" color="foreground">
          {maxScore !== undefined ? maxScore : '-'}
        </Text>
      </Box>
    )
  }

  const totalPoints = maxScore ? (
    <>
      {points}/{maxScore}{' '}
      <Text color="grey-tone-50">({formatPercentage(points / maxScore)})</Text>
    </>
  ) : (
    '-'
  )

  return (
    <Widget p="s-16" mb="s-16">
      <Text use="div" variant="primary" mb="s-16" fontSize="h5">
        {titleIndexNumber}. Summary
      </Text>
      <SummaryTable data={summary?.skill_ratings} />
      <Box padding="s-16" radius="widget" backgroundColor="action-background">
        {(type === 'cv_screening' && (
          <Container style={{ gridTemplateColumns: 'auto auto' }}>
            <Text variant="h6">Total score</Text>
            <Text variant="h6">Pass score</Text>
            <Text variant="h5">{totalPoints}</Text>
            <Text variant="h5" color="grey-tone-50">
              {maxScore && passScore ? formatPercentage(passScore / maxScore) : '-'}
            </Text>
          </Container>
        )) ||
          (type === 'general' && (
            <Container style={{ gridTemplateColumns: 'auto auto' }}>
              <Text variant="h6">Total score</Text>
              <Text variant="h6">Suggested level</Text>
              <Text variant="h5">{totalPoints}</Text>
              <Text variant="h5">
                {summary?.suggested_level
                  ? PerformanceRatingTitle[summary?.suggested_level]
                  : '-'}
              </Text>
            </Container>
          )) || (
            <Container style={{ gridTemplateColumns: 'auto auto' }}>
              <Text variant="h6">
                <HStack gap="s-8" align="center">
                  Expected level
                  <Tooltip
                    placement="top"
                    text="This is the expected skill level based on candidate’s expected seniority and competency matrix"
                  >
                    <InfoOutline size={16} color={Color.GREY_TONE_50} />
                  </Tooltip>
                </HStack>
              </Text>
              <Text variant="h6">
                <HStack gap="s-8" align="center">
                  Suggested level
                  <Tooltip
                    placement="top"
                    text="This is based on the skill assessment completed above"
                  >
                    <InfoOutline size={16} color={Color.GREY_TONE_50} />
                  </Tooltip>
                </HStack>
              </Text>
              <Text variant="h5" color="grey-tone-50">
                {summary?.expected_level
                  ? PerformanceRatingTitle[summary?.expected_level]
                  : '-'}
              </Text>
              <Text variant="h5">
                {summary?.suggested_level
                  ? PerformanceRatingTitle[summary?.suggested_level]
                  : '-'}
              </Text>
            </Container>
          )}
      </Box>
    </Widget>
  )
}

export default SummaryCard
