import React from 'react'
import { Box, Group, ItemSkeleton, MoreBarSkeleton, Skeleton } from '@revolut/ui-kit'

const SidebarSkeleton = () => {
  return (
    <Box mt="s-16" data-testid="summary-sidebar-skeleton">
      <MoreBarSkeleton />
      <Box my="s-32">
        <Group>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </Group>
      </Box>
      <Skeleton height={120} borderRadius={8} my="s-16" />
      <Skeleton height={120} borderRadius={8} my="s-16" />
      <Skeleton height={120} borderRadius={8} my="s-16" />
    </Box>
  )
}

export default SidebarSkeleton
