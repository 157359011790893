import React, { useEffect } from 'react'

import { selectorKeys } from '@src/constants/api'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { FunctionInterface, FunctionType } from '@src/interfaces/functions'
import { CommunicationInputs } from '@src/features/CommunicationGroups/CommunicationInputs'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import { functionsRequestsNew } from '@src/api/functions'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type Props = {
  data?: FunctionInterface
}
const Settings = ({ data }: Props) => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: settings } = useGetOrganisationSettings()
  const user = useSelector(selectUser)
  const viewSalariesEnabled = !!settings?.enable_role_specialisation_owners_view_salaries
  const roleOrSpecialisationApprovalsEnabled =
    !!settings?.enable_roles_approvals || !!settings?.enable_specialisations_approvals

  const showExecutiveToggle = !!settings?.enable_executive_functions
  const showCostControlAssignment = !!settings?.enable_cost_control_assignment
  const advancedSettingsShown =
    viewSalariesEnabled || roleOrSpecialisationApprovalsEnabled || showExecutiveToggle

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id }
    }
  }, [])

  useEffect(() => {
    values.executive =
      typeof values.executive === 'undefined'
        ? values.function_type === FunctionType.executive
        : values.executive
  }, [])

  const updateFunctionType = (isExecutive: boolean) => {
    values.function_type = isExecutive ? FunctionType.executive : FunctionType.generic
  }

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewInput label="Name" name="name" required />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
            {showCostControlAssignment && (
              <LapeRadioSelectInput
                name="cost_control"
                label="Cost Control"
                selector={selectorKeys.employee}
              />
            )}
            <LapeNewInput label="Link to playbooks" name="documentation_link" required />
            {values.id && (
              <LapeRadioSelectInput
                name="delegated_requisition_approver"
                label="Requisition approver"
                selector={selectorKeys.employee}
                clearable
                message="By default the owner of the function is the approver for requisition. You can select employee, who’ll be the approver instead of the function owner"
              />
            )}
            {values.id && (
              <LapeRadioSelectInput
                name="delegated_offer_approver"
                label="Offer approver"
                selector={selectorKeys.employee}
                clearable
                message="By default the owner of the function is the approver for offers. You can select employee, who’ll be the approver instead of the function owner"
              />
            )}
          </InputGroup>

          <NewStepperTitle
            title="Mission"
            subtitle="A short summary explaining the goals and the scope of the function."
          />
          <InputGroup>
            <LapeNewTextArea label="Mission" name="mission" required rows={3} />
          </InputGroup>
          {advancedSettingsShown && (
            <InputGroup>
              <NewStepperTitle title="Advanced settings" />
              {viewSalariesEnabled && (
                <LapeNewSwitch
                  name="role_specialisation_owners_can_view_salaries"
                  itemTypeProps={{
                    title: 'Role / specialisation owners can view salaries',
                  }}
                />
              )}
              {roleOrSpecialisationApprovalsEnabled && (
                <LapeNewSwitch
                  name="approvals_required"
                  itemTypeProps={{
                    title:
                      'Enable / disable approvals when editing roles / specialisations',
                  }}
                />
              )}
              {showExecutiveToggle && (
                <LapeNewSwitch
                  name="executive"
                  onAfterChange={updateFunctionType}
                  itemTypeProps={{
                    title:
                      'Mark this function as executive - allow director+ seniorities',
                  }}
                />
              )}
            </InputGroup>
          )}

          {data?.communication_group && (
            <>
              <NewStepperTitle
                title="Communication"
                subtitle="Communication groups are created automatically for each team, department and function. You can also choose to manually link custom communication groups which are relevant to your team’s work."
              />
              <CommunicationInputs group={data.communication_group} />
            </>
          )}
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={({ id }) => {
            navigateReplace(pathToUrl(ROUTES.FORMS.FUNCTION.SUMMARY, { id: String(id) }))
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default (props: Props) => (
  <Form api={functionsRequestsNew}>
    <Settings {...props} />
  </Form>
)
