import React, { useEffect, useRef, useState } from 'react'
import {
  Button,
  Item,
  Switch,
  TextWidget,
  StatusPopup,
  useStatusPopup,
  Avatar,
  useTooltip,
  Tooltip,
  Cell,
  VStack,
  Text,
  Token,
  ActionButton,
} from '@revolut/ui-kit'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { enableTestCycle, resetTestCycleData } from '@src/api/reviewCycles'
import {
  hasTestCycleCookie,
  useGetCanEnableTestCycleSandbox,
} from '@src/utils/reviewCycles'
import { ArrowExchange, RepairTool } from '@revolut/icons'
import { SandboxInvitationsForm } from './SandboxInvitationsForm'
import { useSandboxStatus } from '@src/api/sandbox'
import { disableSandboxMode } from '../TestingCycleWarningBar/common'

const TestingForm = () => {
  const testCycleCookie = hasTestCycleCookie()
  const [checked, setChecked] = useState(testCycleCookie)
  const [sandboxStatusCheck, setSandboxStatusCheck] = useState<
    'none' | 'reset' | 'enable'
  >('none')
  const statusPopup = useStatusPopup()
  const resetTooltip = useTooltip()
  const resetRef = useRef(null)

  const canEnableSandbox = useGetCanEnableTestCycleSandbox()
  const sandboxStatus = useSandboxStatus(sandboxStatusCheck !== 'none')

  useEffect(() => {
    if (sandboxStatus?.sandbox_db_ready) {
      setSandboxStatusCheck('none')
      showSuccess(
        sandboxStatusCheck === 'enable'
          ? 'Sandbox mode enabled'
          : 'Resetting test data is completed',
        () => {
          window.location.reload()
        },
      )
    }
  }, [sandboxStatus])

  if (!canEnableSandbox) {
    return null
  }

  const showLoading = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showError = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showSuccess = (text: string, callback?: () => void) => {
    statusPopup.show(
      <StatusPopup variant="success-result" onClose={callback}>
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const toggleTestSetting = async () => {
    showLoading(`${checked ? 'Enabling' : 'Disabling'} sandbox mode`)
    try {
      if (checked) {
        await enableTestCycle(checked)
        setSandboxStatusCheck('enable')
      } else {
        showSuccess('Successfully disabled Sandbox mode', disableSandboxMode)
      }
    } catch {
      showError(`Could not ${checked ? 'enable' : 'disable'} Sandbox mode`)
    }
  }

  const onResetData = async () => {
    if (testCycleCookie) {
      showLoading('Resetting test data')
      try {
        await resetTestCycleData()
        setSandboxStatusCheck('reset')
      } catch {
        showError(`Could not reset test data`)
      }
    }
  }

  const onSaveClick = () => {
    statusPopup.show(
      <StatusPopup variant="warning">
        <StatusPopup.Title>
          Are you sure you want to {checked ? 'enable' : 'disable'} Sandbox mode?
        </StatusPopup.Title>
        {checked && (
          <StatusPopup.Description>
            Please allow up to 5 minutes for the test data to generate. You may experience
            some errors during this time
          </StatusPopup.Description>
        )}
        <StatusPopup.Actions>
          <Button elevated onClick={() => toggleTestSetting()}>
            Continue
          </Button>
          <Button variant="secondary" onClick={() => statusPopup.hide()}>
            Cancel
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  return (
    <>
      <PageBody>
        <VStack space="s-16">
          <TextWidget>
            <TextWidget.Title>Test data</TextWidget.Title>
            <TextWidget.Content color="grey-tone-50">
              Enabling the sandbox mode will generate a sandbox dataset in parallel with
              the live system data. With this mode active, users invited to participate
              below will interact with the platform using this sandbox data only, to
              perform demonstrations and test activities, preserving the live data.
              <br />
              <br />
              After enabling, please allow a minute for the system to create the dataset.
              After disabling, all sandbox data will be removed after 24 hrs.
            </TextWidget.Content>
          </TextWidget>

          <Cell>
            <VStack space="s-16" width="100%">
              <Text variant="h6" color={Token.color.greyTone50}>
                Add users to participate in demo
              </Text>
              <Form disableLocalStorageCaching>
                <SandboxInvitationsForm />
              </Form>
            </VStack>
          </Cell>

          <Item use="label" py="s-16">
            <Item.Avatar>
              <Avatar useIcon={RepairTool} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Enable Sandbox Mode</Item.Title>
              <Item.Description>
                Sandbox mode enables a trial environment that is pre-loaded with dummy
                data, allowing you to freely test features and functionalities without
                impacting your real-world data. Please note, the Sandbox Demo Mode offers
                a limited view of our full capabilities and is designed for exploration
                purposes only. Any changes made in this mode are not saved or transferable
                to live accounts.
              </Item.Description>
            </Item.Content>
            <Item.Side>
              <Switch onChange={() => setChecked(!checked)} checked={checked} />
            </Item.Side>
          </Item>

          <ActionButton
            useIcon={ArrowExchange}
            onClick={onResetData}
            aria-disabled={!testCycleCookie}
            {...resetTooltip.getAnchorProps()}
            ref={resetRef}
          >
            Reset all test data
            {!testCycleCookie && (
              <Tooltip
                {...resetTooltip.getTargetProps()}
                anchorRef={resetRef}
                maxWidth="210px"
              >
                You can only reset data when Demo Mode is enabled
              </Tooltip>
            )}
          </ActionButton>
        </VStack>
      </PageBody>

      <PageActions>
        {testCycleCookie !== checked && (
          <Button elevated onClick={onSaveClick}>
            Save
          </Button>
        )}
      </PageActions>
    </>
  )
}

export const Sandbox = connect(TestingForm)
