import React from 'react'

import { useGetReviewCycleByOffset } from '@src/api/reviewCycles'

import { Scorecard } from '@src/pages/Performance/Scorecard/Scorecard'

export const PerformanceReviewScorecard = () => {
  const { data: cycle } = useGetReviewCycleByOffset(0)

  return <Scorecard cycle={cycle} />
}
