import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Flex, VStack } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import JobDescription from '@src/pages/Forms/JobPosting/Components/JobPosting'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useGetJobDescriptionApprovals } from '@src/api/jobPosting'
import RecruitersAssigned from '@src/pages/Forms/JobPosting/Components/RecruitersAssigned'
import { useGetJobPostingSettings } from '@src/api/settings'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import RequisitionsOverview from '@src/pages/Forms/JobPosting/Requisitions/RequisitionsOverview'
import ApplicationFormOverview from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormOverview'
import CandidatesPipelineOverview from '@src/pages/Forms/JobPosting/Preview/CandidatesPipelineOverview'
import ApprovalFlowWidget from '@src/features/ApprovalFlow/ApprovalFlowWidget'
import HiringStagesOverview from '@src/pages/Forms/JobPosting/Preview/HiringStagesOverview'
import { useSelector } from 'react-redux'
import { FeatureFlags } from '@src/store/auth/types'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { JobBoards } from '@src/pages/Forms/JobPosting/JobBoards/JobBoards'

type PreviewProps = {
  isApproving?: boolean
}

const Preview = ({ isApproving = false }: PreviewProps) => {
  const { values, loading: loadingValues } = useLapeContext<JobPostingInterface>()
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const featureFlags = useSelector(selectFeatureFlags)
  const customHiringProcesses = featureFlags.includes(FeatureFlags.CustomHiringProcesses)

  const enableApprovals = jobPostingSettings?.enable_approvals
  const { data: approvalSteps, isRefetching: isApprovalLoading } =
    useGetJobDescriptionApprovals(enableApprovals ? values?.id : undefined)

  if (loadingValues) {
    return (
      <Flex>
        <Loader />
      </Flex>
    )
  }

  return (
    <VStack gap="s-16" mt="s-8">
      <TwoColumnsLayout
        left={
          <>
            {values.id && enableApprovals && (
              <ApprovalFlowWidget
                isLoading={isApprovalLoading || isApproving}
                steps={approvalSteps || null}
              />
            )}
            <JobDescription
              id={values.id}
              locations={values.locations}
              employmentType={values.employment_type?.name}
            />
            <RecruitersAssigned
              data={values.recruiter_locations}
              coordinator={values.coordinator}
            />
          </>
        }
        right={
          <>
            <JobBoards />
            <RequisitionsOverview />
            <ApplicationFormOverview />
            <CandidatesPipelineOverview />
            {customHiringProcesses && <HiringStagesOverview />}
          </>
        }
      />
    </VStack>
  )
}

export default connect(Preview)
