import React, { useState } from 'react'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { useGetCandidatePerformanceProfile } from '@src/api/recruitment/interviews'
import { Avatar, Item, VStack } from '@revolut/ui-kit'
import { PerformanceSections } from '@src/pages/Forms/Candidate/Performance/Summary/PerformanceSections'
import { OverallImpressions } from '@src/pages/Forms/Candidate/Performance/Summary/OverallImpressions'
import { FilterBar } from '@src/pages/Forms/Candidate/Performance/Summary/FilterBar'
import { useFetchInterviewStages } from '@src/pages/Forms/Candidate/InterviewProgress/useFetchStagesTable'

type PerformanceSummaryProps = {
  compact?: boolean
  round?: InterviewRoundInterface
  onViewScorecard: (feedbackIds: number[]) => void
  onViewTest: (stage: InterviewStageWithoutRoundInterface) => void
}

export const PerformanceSummary = ({
  compact,
  round,
  onViewScorecard,
  onViewTest,
}: PerformanceSummaryProps) => {
  const { data: interviewStages } = useFetchInterviewStages(round?.id)
  const [interviewersFilter, setInterviewersFilter] = useState<number[]>([])
  const { data, isLoading } = useGetCandidatePerformanceProfile(
    round?.id,
    interviewersFilter,
  )
  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="BarChart" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Performance summary</Item.Title>
        </Item.Content>
      </Item>
      <VStack>
        <FilterBar
          interviewersFilter={interviewersFilter}
          round={round}
          onChangeInterviewersFilter={filter => {
            setInterviewersFilter(filter)
          }}
        />
        <VStack gap="s-16">
          <PerformanceSections
            performanceRoundSummary={data}
            loading={isLoading}
            onViewScorecard={onViewScorecard}
            onViewTest={stageId => {
              const stage = interviewStages.find(({ id }) => id === stageId)

              if (stage) {
                onViewTest(stage)
              }
            }}
          />
          <OverallImpressions
            compact={compact}
            round={round}
            onViewScorecard={onViewScorecard}
          />
        </VStack>
      </VStack>
    </>
  )
}
