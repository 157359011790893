import React from 'react'
import { Text } from '@revolut/ui-kit'

import step_1 from '@src/assets/revbets/step_1.png'
import step_2 from '@src/assets/revbets/step_2.png'
import step_3 from '@src/assets/revbets/step_3.png'
import step_4 from '@src/assets/revbets/step_4.png'
import step_5 from '@src/assets/revbets/step_5.png'
import in_1 from '@src/assets/revbets/in_1.svg'
import in_2 from '@src/assets/revbets/in_2.svg'
import in_3 from '@src/assets/revbets/in_3.svg'
import in_4 from '@src/assets/revbets/in_4.svg'
import in_5 from '@src/assets/revbets/in_5.svg'

export const SD_LINK =
  'https://revolut.atlassian.net/servicedesk/customer/portal/1668/group/2370/create/22977'

const PLAYBOOK_LINK =
  'https://revolut.atlassian.net/wiki/spaces/CORE/pages/2883455583/WIP+New+Bets+Playbook'

const REWARDS_RECOGNITION_LINK =
  'https://revolut.atlassian.net/wiki/spaces/TD/pages/3567911388/RevBets+Rewards+Recognition+Programme'

export const STEPS = [
  {
    image: step_1,
    title: 'Submit idea',
    description: () => (
      <Text variant="secondary" color="#D4CFE4">
        Submit New Bet Idea to the{' '}
        <Text
          use="a"
          href={SD_LINK}
          target="_blank"
          textDecoration="underline"
          rel="noreferrer"
        >
          RevBets service desk
        </Text>
        . We are happy to hear you out and help with further scoping
      </Text>
    ),
  },
  {
    image: step_2,
    title: 'Validate your idea',
    description: () => (
      <Text variant="secondary" color="#D4CFE4">
        Promising ideas will get support from Product Strategy team to strengthen business
        case:
        <ul style={{ paddingLeft: '1.5rem' }}>
          <li>Market research</li>
          <li>Competitor analysis</li>
          <li>Demand Validation</li>
          <li>Opportunity sizing</li>
        </ul>
      </Text>
    ),
  },
  {
    image: step_3,
    title: 'Get it approved',
    description: () => (
      <Text variant="secondary" color="#D4CFE4">
        Present on <b>Project More: New Bets</b> call in front of founders and other
        senior executives and get same day approval to run bet
      </Text>
    ),
  },
  {
    image: step_4,
    title: 'Build',
    description: () => (
      <Text variant="secondary" color="#D4CFE4">
        Follow{' '}
        <Text
          use="a"
          href={PLAYBOOK_LINK}
          target="_blank"
          textDecoration="underline"
          rel="noreferrer"
        >
          New Bets Playbook
        </Text>{' '}
        and build your product. CEO office will help you assemble a team and set you up
        for success
      </Text>
    ),
  },
  {
    image: step_5,
    title: 'Launch',
    description: () => (
      <Text variant="secondary" color="#D4CFE4">
        Launch your product and drive adoption. See{' '}
        <Text
          use="a"
          href={PLAYBOOK_LINK}
          target="_blank"
          textDecoration="underline"
          rel="noreferrer"
        >
          New Bets Playbook
        </Text>{' '}
        for the best practices in go-to-market.
      </Text>
    ),
  },
]

export const BENEFITS_1 = [
  { image: in_1, description: 'Work on your own idea' },
  {
    image: in_2,
    description: (
      <>
        Get{' '}
        <Text
          use="a"
          href={REWARDS_RECOGNITION_LINK}
          target="_blank"
          textDecoration="underline"
          rel="noreferrer"
        >
          rewarded and recognised
        </Text>{' '}
        for novel business ideas and product feature improvements
      </>
    ),
  },
  { image: in_3, description: 'Build something from scratch' },
]

export const BENEFITS_2 = [
  {
    image: in_4,
    description:
      'Stand a chance to lead product teams, when successful bets become independent departments.',
  },
  {
    image: in_5,
    description: 'Develop your entrepreneurial skills',
  },
]
