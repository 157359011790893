import React from 'react'
import { Box } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { API } from '@src/constants/api'
import { useGetMatrixValidatorAndSenioritiesForSpecialisation } from '@src/features/CompetencyMatrixTable/utils'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import PostingsViewer from '@src/pages/Forms/SpecialisationForm/Postings/PostingsViewer'
import { useNextSpecialisationStepButtonLink } from '@src/pages/Forms/SpecialisationForm/hooks'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'

const CURRENT_STEP = 'postings'

const Postings = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const nextLink = useNextSpecialisationStepButtonLink(CURRENT_STEP)
  const { matrixValidated } = useGetMatrixValidatorAndSenioritiesForSpecialisation(values)

  return (
    <>
      <PageBody maxWidth="100%">
        <Box pb="36px">
          <PostingsViewer />
        </Box>
      </PageBody>
      <PageActions>
        {values.status === Statuses.draft && (
          <RoleSaveDraftButton
            title="specialisation"
            pathInLocalStorage={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.GENERAL, {})}
            pathAfterSave={ROUTES.FORMS.SPECIALISATIONS.POSTINGS}
            isNew
            notification={{
              updateMsg: 'Specialisations draft successfully updated.',
              createMsg: 'Specialisations draft successfully created.',
            }}
          />
        )}
        <ContinueRoleButton
          api={API.SPECIALISATIONS}
          type="specialisation"
          step={CURRENT_STEP}
          to={nextLink}
          disabled={!matrixValidated}
        />
      </PageActions>
    </>
  )
}

export default Postings
