import React, { useState, useCallback } from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import { Box, InputGroup } from '@revolut/ui-kit'
import debounce from 'lodash/debounce'

import Form from '@src/features/Form/Form'
import {
  employeeDefaultWorkingHoursRequests,
  useDefaultWorkingHoursRequirements,
} from '@src/api/attendance'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeesDefaultHours } from '@src/interfaces/attendance'
import BottomInputMessage from '@src/components/BottomInputMessage/BottomInputMessage'
import { navigateReplace } from '@src/actions/RouterActions'
import { RequirementsWidget } from './RequirementsWidget'

export const DEBOUNCE_WAIT = 750

const DefaultWorkingHours = () => {
  const params = useParams<{ id: string; new?: string }>()

  const { apiErrors, values } = useLapeContext<EmployeesDefaultHours & { time: any }>()

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.SCHEDULE, { id: params.id })

  const [start, setStart] = useState<string | undefined | null>(values.default_start_time)
  const [end, setEnd] = useState<string | undefined | null>(values.default_end_time)

  const requirements = useDefaultWorkingHoursRequirements(params.id, {
    start_time: start,
    end_time: end,
  })

  const debouncedSetStart = useCallback(debounce(setStart, DEBOUNCE_WAIT), [])
  const debouncedSetEnd = useCallback(debounce(setEnd, DEBOUNCE_WAIT), [])

  const isNew = !!params.new
  const sharedInputError = apiErrors.time
  const requirementsValid = !!(
    requirements.data &&
    Object.values(requirements.data).every(requirement => requirement.valid)
  )

  const clearSharedError = () => {
    apiErrors.time = undefined
  }

  return (
    <PageWrapper>
      <PageHeader
        backUrl={backUrl}
        title={isNew ? 'Set default working hours' : 'Edit default working hours'}
        subtitle={
          <Box maxWidth={{ all: '100%', md: '720px' }} style={{ whiteSpace: 'initial' }}>
            {isNew
              ? `This will be populated in you schedule for the following months. You will still be able to change it in bulk for next months or edit each day's shift to reflect your actual working hours.`
              : `Please note that this change will be reflected only for the upcoming months. If you wish to change particular shifts in the current month, please edit them one by one in the month's schedule.`}
          </Box>
        }
      />

      <PageBody>
        <InputGroup variant="horizontal" data-name="time">
          <LapeNewInput
            name="default_start_time"
            label="Start time"
            required
            hasError={!!sharedInputError}
            type="time"
            onAfterChange={val => {
              clearSharedError()
              debouncedSetStart(val)
            }}
          />
          <LapeNewInput
            name="default_end_time"
            label="End time"
            required
            hasError={!!sharedInputError}
            type="time"
            onAfterChange={val => {
              clearSharedError()
              debouncedSetEnd(val)
            }}
          />
        </InputGroup>
        <BottomInputMessage hasError={!!sharedInputError} message={sharedInputError} />

        <RequirementsWidget requirements={requirements} valid={requirementsValid} />
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText={
            isNew
              ? 'Default working hours were successfully set'
              : 'Default working hours were successfully changed'
          }
          onAfterSubmit={() => {
            if (isNew) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.DEFAULT_WORKING_HOURS.GENERAL, { id: params.id }),
              )
            }
          }}
          useValidator
          disabled={!requirementsValid}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeeDefaultWorkingHoursRequests}>
    <DefaultWorkingHours />
  </Form>
))
