import { AxiosPromise, AxiosResponse } from 'axios'
import { FetchDataQueryInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew, Statuses } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  EmployeeEmailPlaceholderInterface,
  EmployeeEmailTemplateInterface,
  EmployeeEmailThreadInterface,
  SendEmployeeEmailInterface,
} from '@src/interfaces/employeeEmails'
import { FileInterface } from '@src/interfaces/files'
import { uploadFile } from '@src/api/files'
import { isFile } from '@src/utils/files'
import { useFetch } from '@src/utils/reactQuery'

export const getEmployeeEmailTemplates = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeEmailTemplateInterface>
> =>
  api.get(API.EMPLOYEE_EMAIL_TEMPLATES, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const employeeEmailTemplatesRequests: RequestInterfaceNew<EmployeeEmailTemplateInterface> =
  {
    get: async ({ id }) => api.get(`${API.EMPLOYEE_EMAIL_TEMPLATES}/${id}`),
    update: async (data, { id }) => {
      const { attachments, ...form } = data

      let files: AxiosResponse<FileInterface>[] | undefined
      if (attachments) {
        files = await Promise.all(
          attachments.map(file =>
            isFile(file)
              ? uploadFile(file, 'employee_email_attachment', true)
              : new Promise<AxiosResponse<FileInterface>>(resolve => {
                  resolve({ data: file } as AxiosResponse<FileInterface>)
                }),
          ),
        )
      }

      return apiWithoutHandling.patch(`${API.EMPLOYEE_EMAIL_TEMPLATES}/${id}`, {
        ...form,
        ...(attachments || attachments === null
          ? {
              attachments: files?.map(file => ({
                id: file?.data?.id,
              })),
            }
          : {}),
      })
    },
    submit: async data => {
      let files: AxiosResponse<FileInterface>[] | undefined
      if (data.attachments) {
        files = await Promise.all(
          data.attachments.map(file =>
            uploadFile(file as File, 'employee_email_attachment', true),
          ),
        )
      }

      return apiWithoutHandling.post(`${API.EMPLOYEE_EMAIL_TEMPLATES}`, {
        ...data,
        attachments:
          files?.map(file => ({
            id: file?.data?.id,
          })) || [],
      })
    },
  }

export const getEmployeeEmailTemplatesPlaceholders = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeEmailPlaceholderInterface>
> =>
  api.get(API.EMPLOYEE_EMAIL_PLACEHOLDERS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const changeEmployeeEmailTemplateStatus = (
  id: string | number,
  status: Statuses.archived | Statuses.active,
) => api.patch(`${API.EMPLOYEE_EMAIL_TEMPLATES}/${id}`, { status: { id: status } })

export const useEmployeeEmailThreads = (id?: number | string) =>
  useFetch<GetRequestInterface<EmployeeEmailThreadInterface>>(
    id ? `${API.EMPLOYEES}/${id}/threads` : null,
    'v1',
    {
      params: filterSortPageIntoQuery([
        {
          sortBy: 'latest_sent_date_time',
          direction: SORT_DIRECTION.ASC,
        },
      ]),
    },
  )

export const useEmployeeEmailThread = (
  employeeId?: number | string,
  id?: number | string,
) =>
  useFetch<EmployeeEmailThreadInterface>(
    id ? `${API.EMPLOYEES}/${employeeId}/threads/${id}` : null,
  )

export const syncEmployeeEmails = (id: number | string) =>
  api.post(`${API.EMPLOYEES}/${id}/threads/importEmails`)

export const employeeEmailsRequests: RequestInterfaceNew<SendEmployeeEmailInterface> = {
  get: async ({ employeeId }) => api.get(`${API.EMPLOYEES}/${employeeId}/emails`),
  update: async (data, { employeeId }) =>
    apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}/emails`, data),
  submit: async (data, { employeeId, emailId }) => {
    let files: AxiosResponse<FileInterface>[] | undefined
    if (data.attachments) {
      files = await Promise.all(
        data.attachments.map(file =>
          isFile(file)
            ? uploadFile(file, 'employee_email_attachment', true)
            : new Promise<AxiosResponse<FileInterface>>(resolve => {
                resolve({ data: file } as AxiosResponse<FileInterface>)
              }),
        ),
      )
    }

    return apiWithoutHandling.post<SendEmployeeEmailInterface>(
      `${API.EMPLOYEES}/${employeeId}/emails`,
      {
        ...data,
        attachments:
          files?.map(file => ({
            id: file?.data?.id,
          })) || [],
        reply_email: emailId
          ? {
              id: emailId,
            }
          : null,
      } as SendEmployeeEmailInterface,
    )
  },
}

export const getPrefilledTemplate = (templateId: number, employeeId: number) =>
  api.get<SendEmployeeEmailInterface>(
    `${API.EMPLOYEE_EMAIL_TEMPLATES}/${templateId}/prefilledTemplate`,
    {
      params: {
        employee_id: employeeId,
      },
    },
  )

export const cancelEmployeeEmail = (employeeId: number, emailId: number) =>
  apiWithoutHandling.patch(`${API.EMPLOYEES}/${employeeId}/emails/${emailId}/cancel`)
