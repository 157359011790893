import React from 'react'
import { OverallProgressCircle } from './SummaryHeader/OverallProgressCircle'
import {
  Box,
  Flex,
  Separator,
  Text,
  ProgressCircle,
  VStack,
  Token,
} from '@revolut/ui-kit'
import { gradeSettings } from '@src/pages/Forms/DepartmentForm/Performance/utils'
import { css } from 'styled-components'
import { ProgressStat } from '@src/pages/Forms/DepartmentForm/Performance/SummaryHeader/ProgressStat'
import { HeaderSummarySkeleton } from '@src/pages/Forms/DepartmentForm/Performance/SummaryHeader/HeaderSummarySkeleton'
import { formatPercentage } from '@src/utils/format'
import {
  PerformanceSummaryData,
  CycleType,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface PerformanceSummaryProps {
  summaryStats?: PerformanceSummaryData
  cycleType?: CycleType
}
const ProgressCircleTextCss = css`
  font-size: 14px;
`

export const PerformanceSummaryHeader = ({
  summaryStats,
  cycleType,
}: PerformanceSummaryProps) => {
  if (!summaryStats) {
    return <HeaderSummarySkeleton />
  }

  const {
    display_name,
    overall_score,
    goal_progress,
    roadmap_progress,
    karma_percentage,
    estimated_bonus_impact,
    bad_karma,
    good_karma,
    total_karma,
    total_max_karma,
  } = summaryStats
  const karmaTooltipText = (
    <Box>
      <ul style={{ listStyleType: 'none' }}>
        <li>Total karma: {total_karma}</li>
        <li>
          Good karma: <span style={{ color: Token.color.success }}>{good_karma}</span>
        </li>
        <li>
          Bad karma: <span style={{ color: Token.color.danger }}>{bad_karma} </span>
        </li>
        <li>Max karma: {total_max_karma}</li>
      </ul>
    </Box>
  )

  const karmaBonusImpactTooltipText = (
    <Box>
      Karma Bonus Impact is estimated based on cumulative Karma score for the year with
      Default multiplier as "High". Final Bonus impact of Karma is published at the end of
      the year after after year-end adjustments
    </Box>
  )

  return (
    <>
      {summaryStats && (
        <Flex p="s-16" style={{ flexGrow: 1 }} gap="s-16">
          <OverallProgressCircle
            periodName={display_name}
            grade={overall_score.grade}
            percent={overall_score.percent}
          />
          <VStack>
            <Text py="s-12" pl="s-12" variant="emphasis2" color={Token.color.greyTone50}>
              Performance Indicators
            </Text>

            <Flex
              alignItems="center"
              flexDirection="row"
              columnGap="s-16"
              flexWrap="wrap"
            >
              <Flex gap="s-16" p="s-16" alignItems="center">
                <ProgressCircle
                  strokeWidth={4}
                  size={44}
                  value={goal_progress.percent}
                  color={gradeSettings?.[goal_progress.grade]?.color}
                  bg={Token.color.greyTone5}
                >
                  <ProgressCircle.Text css={ProgressCircleTextCss}>
                    {formatPercentage(goal_progress.percent)}
                  </ProgressCircle.Text>
                </ProgressCircle>
                <Text variant="emphasis1">Goals</Text>
              </Flex>

              <Flex gap="s-16" p="s-16" alignItems="center">
                <ProgressCircle
                  strokeWidth={4}
                  size={44}
                  value={roadmap_progress.percent}
                  color={gradeSettings?.[roadmap_progress.grade]?.color}
                  bg={Token.color.greyTone5}
                >
                  <ProgressCircle.Text css={ProgressCircleTextCss}>
                    {formatPercentage(roadmap_progress.percent)}
                  </ProgressCircle.Text>
                </ProgressCircle>
                <Text variant="emphasis1">Roadmap</Text>
              </Flex>

              <Box height={60} display={{ all: 'none', lg: 'block' }}>
                <Separator orientation="vertical" />
              </Box>

              <ProgressStat
                grade={karma_percentage.grade}
                percent={karma_percentage.percent}
                statName="Karma"
                tooltipText={karmaTooltipText}
              />
              {cycleType === 'review_cycle' ? null : (
                <ProgressStat
                  grade={estimated_bonus_impact.grade}
                  percent={estimated_bonus_impact.percent}
                  statName="Estimated Karma Bonus Impact"
                  tooltipText={karmaBonusImpactTooltipText}
                />
              )}
            </Flex>
          </VStack>
        </Flex>
      )}
    </>
  )
}
