import { AxiosPromise } from 'axios'
import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  OffboardingAccessRetentionInterface,
  OffboardingDashboardInterface,
  OffboardingPaymentsInterface,
  OffboardingQueueInterface,
  OffboardingQueueStatsInterface,
  OffboardingTicketsInterface,
  OffboardingTicketsStatsInterface,
} from '@src/interfaces/offboarding'
import { useFetch } from '@src/utils/reactQuery'
import { api, apiWithoutHandling } from '.'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from './comments'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const useGetOffboardingDashboard = (employeeId: string | number) =>
  useFetch<OffboardingDashboardInterface>(
    `${API.EMPLOYEES}/${employeeId}/termination/dashboard`,
    'v2',
    undefined,
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )

export const offboardingPaymentsRequests: RequestInterfaceNew<OffboardingPaymentsInterface> =
  {
    get: ({ id }) =>
      api.get(`${API.EMPLOYEES}/${id}/termination/payments`, undefined, 'v2'),
    update: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${id}/termination/payments`,
        data,
        undefined,
        'v2',
      ),
    submit: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${id}/termination/payments`,
        data,
        undefined,
        'v2',
      ),
  }

export const offboardingAccessRetentionRequests: RequestInterfaceNew<OffboardingAccessRetentionInterface> =
  {
    get: ({ id }) =>
      api.get(`${API.EMPLOYEES}/${id}/termination/accessRetention`, undefined, 'v2'),
    update: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${id}/termination/accessRetention`,
        data,
        undefined,
        'v2',
      ),
    submit: async (data, { id }) =>
      apiWithoutHandling.put(
        `${API.EMPLOYEES}/${id}/termination/accessRetention`,
        data,
        undefined,
        'v2',
      ),
  }

export const useAccessRetentionApprovals = (employeeId: string) =>
  useFetch<ApprovalFlowResponse>(
    `${API.EMPLOYEES}/${employeeId}/termination/accessRetention/approvals`,
    'v2',
  )

export const approveAccessRetentionRequest = (employeeId: string) =>
  api.post<OffboardingAccessRetentionInterface>(
    `${API.EMPLOYEES}/${employeeId}/termination/accessRetention/approve`,
    undefined,
    undefined,
    'v2',
  )

export const rejectAccessRetentionRequest = (
  employeeId: string,
  rejection_comment: string,
) =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}/termination/accessRetention/reject`,
    {
      rejection_comment,
    },
    undefined,
    'v2',
  )

export const createAccessRetentionRequest = (employeeId: string) =>
  api.post<OffboardingAccessRetentionInterface>(
    `${API.EMPLOYEES}/${employeeId}/termination/accessRetention`,
    undefined,
    undefined,
    'v2',
  )

export const getAccessRetentionRequestCommentsAPI = (employeeId: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/termination/accessRetention/comments`,
    apiVersion: 'v2',
  })

export const getOffboardingDashboardCommentsAPI = (employeeId: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/termination/comments`,
    apiVersion: 'v2',
  })

export const getOffboardingQueue = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<OffboardingQueueInterface>
> =>
  api.get(
    API.OFFBOARDING_EMPLOYEES,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getOffboardingQueueStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<OffboardingQueueStatsInterface> =>
  api.get(
    `${API.OFFBOARDING_EMPLOYEES}/stats`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const updateOffboardingHRManager = (
  employeeId: string,
  hr_manager: EmployeeOptionInterface,
) =>
  api.patch<OffboardingDashboardInterface>(
    `${API.EMPLOYEES}/${employeeId}/termination/dashboard`,
    { hr_manager },
    undefined,
    'v2',
  )

export const getOffboardingTickets =
  (employeeId: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<OffboardingTicketsInterface>
  > =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}/termination/offboardingTickets`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    )

export const getOffboardingTicketsStats =
  (employeeId: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<OffboardingTicketsStatsInterface> =>
    api.get(
      `${API.EMPLOYEES}/${employeeId}/termination/offboardingTickets/stats`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    )

export const completeTermination = (employeeId: string) =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}/termination/complete`,
    undefined,
    undefined,
    'v2',
  )

export const useHrManagers = (employeeId: string) =>
  useFetch<{ options: EmployeeOptionInterface[] }>(
    `${API.EMPLOYEES}/${employeeId}/hrManagers`,
  )

export const cancelTermination = (employeeId: string, reason: string) =>
  api.post(
    `${API.EMPLOYEES}/${employeeId}/termination/cancel`,
    {
      reason,
    },
    undefined,
    'v2',
  )
