import React from 'react'
import { Box, MoreBar, TableWidget } from '@revolut/ui-kit'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  dynamicGroupsGroupNameColumn,
  dynamicGroupsMemberCountColumn,
} from '@src/constants/columns/dynamicGroups'
import { useTable } from '@src/components/Table/hooks'
import { getDynamicGroups } from '@src/api/dynamicGroups'
import { TableNames } from '@src/constants/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Message from '../components/Message'

const row: RowInterface<DynamicGroupInerface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id: data.id })),
  cells: [
    {
      ...dynamicGroupsGroupNameColumn,
      width: 240,
    },
    {
      ...dynamicGroupsMemberCountColumn,
      width: 240,
    },
  ],
}

export const ManageEligibilityGroups = () => {
  const table = useTable({ getItems: getDynamicGroups })

  return (
    <PageWrapper>
      <PageHeader
        title="Manage groups"
        backUrl={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICIES}
      />

      <PageBody>
        <Box mb="s-16">
          <Message
            title="We suggested groups just for you"
            description="Based on your employees country of residence we have added groups for you. Adjust, edit or delete them to your needs."
          />
        </Box>

        <TableWidget>
          <TableWidget.Actions>
            <MoreBar>
              <MoreBar.Action
                useIcon="Plus"
                use={InternalLink}
                to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL)}
              >
                Add group
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              {...table}
              row={row}
              name={TableNames.ManageDynamicGroups}
              useWindowScroll
              dataType="Group"
              noDataMessage="Groups will appear here."
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </PageWrapper>
  )
}
