import { IdAndName } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { FinalGrade } from '@src/interfaces/performance'

export enum LeadershipStatus {
  Active = 'active',
  Unassigned = 'unassigned',
  Hired = 'hired',
  Terminated = 'terminated',
}

export interface LeadershipPositionInterface {
  id: number
  department: IdAndName
  employee: EmployeeOptionInterface | null
  leadership_position: string
  latest_calibrated_grade: FinalGrade
  annual_expense: number
  expense_currency: string
  status: LeadershipStatus
  line_manager?: EmployeeOptionInterface
  quality_control?: EmployeeOptionInterface
  specialisation?: IdAndName
  seniority?: SeniorityInterface
  location?: IdAndName
}

export interface LeadershipStats {
  neutral_percentage: number
  unsatisfactory_percentage: number
  below_expectations_percentage: number
  meets_expectations_percentage: number
  above_expectations_percentage: number
  exceptional_percentage: number
  unsatisfactory_and_below_expectations_percentage: number
  above_expectations_and_exceptional_percentage: number
  nips: number
  occupied_positions: number
  unassigned_positions: number
}
