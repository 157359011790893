import { VStack, Text, Token, TextArea, Spacer, Popup, Button } from '@revolut/ui-kit'
import React, { useState } from 'react'

export const RequestChange = ({
  onCancel,
  onSubmit,
}: {
  onCancel: () => void
  onSubmit: (comment: string) => void
}) => {
  const [comment, setComment] = useState<string>()

  return (
    <VStack>
      <Text variant="h5">Request change</Text>
      <Spacer height="s-8" />
      <Text variant="caption" color={Token.color.greyTone50}>
        Please comment on what needs changing and why.
      </Text>
      <Spacer height="s-24" />
      <TextArea
        value={comment}
        onChange={ev => setComment((ev.target as HTMLTextAreaElement).value)}
        rows={3}
        label="Update summary"
      />
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="primary"
          elevated
          disabled={!comment}
          onClick={() => onSubmit(comment!)}
        >
          Submit
        </Button>
      </Popup.Actions>
    </VStack>
  )
}
