import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { Button, Header, InputGroup, Popup, TextArea } from '@revolut/ui-kit'

interface Props {
  value?: string
  onClose: () => void
  open?: boolean
  onSubmit: (value?: string) => void
  placeholder?: string
  title: string
}

const CommentDialog = ({
  title,
  value,
  onClose,
  open,
  onSubmit,
  placeholder = 'Add the reasoning behind your rating',
}: Props) => {
  const fieldElement = useRef<HTMLTextAreaElement | null>(null)
  const [text, setText] = useState(value)

  const handleClose = () => {
    setText(value)
    onClose()
  }

  useEffect(() => {
    if (open && fieldElement?.current) {
      fieldElement.current.focus()
    }
  }, [open])

  useEffect(() => {
    setText(value)
  }, [value, open])

  return (
    <Popup variant="modal-view" open={open} onClose={handleClose}>
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{title}</Header.Title>
      </Header>
      <InputGroup>
        <TextArea
          value={text}
          defaultValue={value}
          label={placeholder}
          rows={4}
          onChange={(e: FormEvent<HTMLTextAreaElement>) => setText(e.currentTarget.value)}
          ref={fieldElement}
          data-testid="add-comment-textarea"
        />
      </InputGroup>
      <Popup.Actions horizontal>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>
        <Button disabled={!text} onClick={() => onSubmit(text)}>
          Submit
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default CommentDialog
