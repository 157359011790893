import {
  ActionWidget,
  ActionButton,
  useStatusPopup,
  StatusPopup,
  useToggle,
} from '@revolut/ui-kit'
import React, { useState } from 'react'
import { TicketFullInterface } from '@src/interfaces/tickets'
import { closeTicket, getTicketsCommentsAPI, reopenTicket } from '@src/api/tickets'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { TicketFeedbackRejectPopup } from './TicketFeedbackRejectPopup'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface TicketFeedbackProps {
  ticket: TicketFullInterface
  refetch: () => void
  openRatePopup: () => void
}

export const TicketFeedback = ({
  ticket,
  refetch,
  openRatePopup,
}: TicketFeedbackProps) => {
  const [closeTicketPending, setCloseTicketPending] = useState(false)
  const [reopenPopupOpen, toggleReopenPopupOpen] = useToggle()
  const commentsApi = getTicketsCommentsAPI(Number(ticket.id))
  const { refetch: refetchComments } = commentsApi.useGetComments()
  const currentUser = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  if (
    ticket.current_status.status !== 'fixed' ||
    currentUser.user_uuid !== ticket.employee_uuid
  ) {
    return null
  }

  const handleNoAction = async (data: { reason: string }) => {
    return reopenTicket(ticket.id, data)
      .then(() => {
        toggleReopenPopupOpen()
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="success">
            <StatusPopup.Title>Thank you for feedback</StatusPopup.Title>
            <StatusPopup.Description>
              We have reopened your issue.
            </StatusPopup.Description>
          </StatusPopup>,
        )
        return { reason: '' }
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="error">
            <StatusPopup.Title>Failed to reopen the issue</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
        return { reason: '' }
      })
      .finally(() => {
        refetch()
        refetchComments()
      })
  }

  const handleYesAction = () => {
    setCloseTicketPending(true)

    closeTicket(ticket.id)
      .then(() => {
        openRatePopup()
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup onClose={statusPopup.hide} variant="error">
            <StatusPopup.Title>Failed to close the issue</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setCloseTicketPending(false)
        refetch()
      })
  }

  return (
    <>
      <ActionWidget>
        <ActionWidget.Title>Did we fix your issue?</ActionWidget.Title>
        <ActionWidget.Content>
          Help us to improve by leaving your feedback
        </ActionWidget.Content>
        <ActionWidget.Actions>
          <ActionButton
            useIcon="SocialLike"
            onClick={handleYesAction}
            pending={closeTicketPending}
          >
            Yes
          </ActionButton>
          <ActionButton useIcon="SocialDislike" onClick={() => toggleReopenPopupOpen()}>
            No
          </ActionButton>
        </ActionWidget.Actions>
      </ActionWidget>
      <TicketFeedbackRejectPopup
        open={reopenPopupOpen}
        onClose={toggleReopenPopupOpen}
        onSubmit={handleNoAction}
      />
    </>
  )
}
