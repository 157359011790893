import { Color, Text } from '@revolut/ui-kit'
import React from 'react'

interface CodeProps {
  children: React.ReactNode
}

const Code = ({ children }: CodeProps) => {
  return (
    <Text bg={Color.GREY_TONE_5} whiteSpace="pre-wrap">
      <code>{children}</code>
    </Text>
  )
}

export default Code
