import React from 'react'
import { Box, Bullet, Flex, Link, Text, Widget } from '@revolut/ui-kit'
import { connect } from 'lape'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { DEFINING_ROLE_MISSION, DEFINING_ROLE_GOALS } from '@src/constants/externalLinks'

interface GeneralExamplesProps {
  hideInstructions?: boolean
}

const GeneralExamples = ({ hideInstructions }: GeneralExamplesProps) => {
  return (
    <Box>
      {!hideInstructions && (
        <>
          <Text mb="s-16" color="grey-tone-50" use="div">
            Instructions
          </Text>
          <Widget mb="s-32" p="s-16">
            <Box>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  1
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Define a brief role name
                </Text>
              </Flex>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  2
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Role owner should belong to the same function as the role
                </Text>
              </Flex>
              <Flex alignItems="flex-start">
                <Bullet style={{ flexShrink: 0 }} m="s-8">
                  3
                </Bullet>
                <Text use="div" mt="s-12" ml="5px">
                  Write a short summary of the job’s core purpose so everybody (employees
                  and prospective candidates) understands why we need it.{' '}
                  <HideIfCommercial>
                    <Link
                      target="_blank"
                      rel="noreferrer noopener"
                      href={DEFINING_ROLE_MISSION}
                    >
                      How to define role mission?
                    </Link>
                  </HideIfCommercial>
                </Text>
              </Flex>
              <HideIfCommercial>
                <Flex alignItems="flex-start">
                  <Bullet style={{ flexShrink: 0 }} m="s-8">
                    4
                  </Bullet>
                  <Text use="div" mt="s-12" ml="5px">
                    Read here{' '}
                    <Link
                      target="_blank"
                      rel="noreferrer noopener"
                      href={DEFINING_ROLE_GOALS}
                    >
                      how to define goals
                    </Link>
                  </Text>
                </Flex>
              </HideIfCommercial>
            </Box>
          </Widget>
        </>
      )}
    </Box>
  )
}

export default connect(GeneralExamples)
