import React, { PropsWithChildren } from 'react'
import { interviewScorecardTemplateFormRequests } from '@src/api/interviewScorecardTemplate'
import { validator } from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import Form from '@src/features/Form/Form'

type ScorecardFormWrapperProps = {
  id?: number
}

export const ScorecardFormWrapper = ({
  id,
  children,
}: PropsWithChildren<ScorecardFormWrapperProps>) => {
  return (
    <Form
      api={interviewScorecardTemplateFormRequests}
      validator={validator}
      forceParams={{ id: id?.toString() }}
    >
      {children}
    </Form>
  )
}
