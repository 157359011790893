import React, { useEffect, useRef } from 'react'
import {
  Action,
  Box,
  Color,
  Flex,
  HStack,
  Text,
  Tooltip,
  useTooltip,
  Widget,
} from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import SectionPreviewNotes from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionPreviewNotes'
import { formatTime } from '@src/utils/format'
import { QuestionOutline } from '@revolut/icons'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SectionHeader'

type Props = {
  children: React.ReactNode
  index: number
  title: string | React.ReactNode
  subtitle?: string | React.ReactNode
  notesName?: string
  points?: number
  isViewMode?: boolean
  isOptional?: boolean
  onAddTimeCode?: (time: string) => void
}

export const Section = ({
  children,
  index,
  title,
  subtitle,
  notesName,
  points,
  isViewMode,
  isOptional,
  onAddTimeCode,
}: Props) => {
  const { sendAnalyticsEvent } = useAnalytics()
  const tooltip = useTooltip()
  const notesRef = useRef<HTMLTextAreaElement>(null)

  const onHandleAddTimeCode = () => {
    onAddTimeCode?.(`[${formatTime()}]`)
  }

  const onKeydown = (e: KeyboardEvent) => {
    const focused = document.activeElement === notesRef.current
    if (focused && e.composed && e.ctrlKey && e.shiftKey && e.code === 'KeyC') {
      sendAnalyticsEvent(AnalyticsEvents.press_shortcut_add_time_code_interview)
      onHandleAddTimeCode()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeydown)
    return () => {
      document.removeEventListener('keydown', onKeydown)
    }
  }, [])

  return (
    <Widget p="s-16">
      <SectionHeader
        title={
          <Flex justifyContent="space-between">
            <Text variant="h5" use="div">
              {title} {isOptional && <Text color="grey-tone-50"> (optional)</Text>}
            </Text>
            {isViewMode && points !== undefined && (
              <Text use="div" whiteSpace="nowrap">
                {points} points
              </Text>
            )}
          </Flex>
        }
        subtitle={subtitle}
      />
      {children}
      {!isViewMode && points !== undefined && (
        <Flex justifyContent="space-between" mt="s-20">
          <Text fontWeight={500} color="grey-tone-50">
            Total points
          </Text>
          <Text
            fontWeight={500}
            data-testid={`points-${index}`}
            color={points < 0 ? 'red' : 'grey-tone-50'}
          >
            {points}
          </Text>
        </Flex>
      )}
      {notesName && !isViewMode && (
        <Box mt="s-24">
          {onAddTimeCode && (
            <HStack gap="s-4" pb="s-4" align="center">
              <Tooltip {...tooltip.getTargetProps()}>
                Or focus on the field and press Shift + Ctrl + C
              </Tooltip>
              <Action
                onClick={() => {
                  onHandleAddTimeCode()
                  sendAnalyticsEvent(AnalyticsEvents.click_add_time_code_interview)
                }}
              >
                Add time code
              </Action>
              <QuestionOutline
                size={14}
                color={Color.GREY_TONE_50}
                {...tooltip.getAnchorProps()}
              />
            </HStack>
          )}
          <LapeNewTextArea
            name={notesName}
            label="Notes"
            readOnly={isViewMode}
            data-testid={notesName}
            elementRef={notesRef}
          />
        </Box>
      )}
      {isViewMode && notesName && <SectionPreviewNotes notesName={notesName} />}
    </Widget>
  )
}
