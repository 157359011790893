import React from 'react'
import { Flex, Link } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { TimeOffPolicyCategory } from '@src/features/TimeOff/TimeOffPolicyCategory'

export const PolicyNameItem = () => {
  const { values } = useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  return (
    <FormPreview.Item
      title="Policy name"
      insert={() => (
        <Flex alignItems="center" gap="s-8">
          {values.policy.name}
          {values.policy.details_url ? (
            <Link
              href={values.policy.details_url}
              target="_blank"
              rel="noreferrer noopener"
            >
              <LinkExternal />
            </Link>
          ) : null}
        </Flex>
      )}
    />
  )
}

export const PolicyCategoryItem = () => {
  return (
    <FormPreview.Item<EmployeeTimeOffPolicyAssignmentInterface>
      title="Category"
      insert={data => <TimeOffPolicyCategory category={data.policy.category} />}
    />
  )
}

export const StartDateItem = () => {
  return <FormPreview.Item field="start_date" title="Start date" type="date" />
}

export const PreviewItems = () => {
  return (
    <>
      <PolicyNameItem />
      <PolicyCategoryItem />
      <FormPreview.Item field="policy.paid.name" title="Paid or unpaid" />
      <FormPreview.Divider />
      <StartDateItem />
      <FormPreview.Item field="end_date" title="End date" type="date" />
      <FormPreview.Divider />
      <FormPreview.Item field="creation_date_time" title="Assigned on" type="date" />
      <FormPreview.Item<EmployeeTimeOffPolicyAssignmentInterface>
        field="assigned_by.name"
        title="Assigned by"
        to={policy =>
          pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
            id: policy.assigned_by?.id,
          })
        }
      />
    </>
  )
}

interface Params {
  employeeId: string
  id?: string
}

export const usePolicyAssignmentParams = () => useParams<Params>()

export const getExitUrl = (employeeId: string) =>
  pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES, {
    id: employeeId,
  })

export const HeaderSubtitle = () => {
  const { values } = useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  return values?.employee ? (
    <UserWithAvatar {...values.employee} asText mb="s-16" />
  ) : null
}
