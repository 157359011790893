import React from 'react'
import { MatrixColumnCellInterface } from '@components/Table/MatrixTable'
import {
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiRatingColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { RatingLabelTypes } from '@src/features/EmployeePerformance/RatingLabel'
import { Flex } from '@revolut/ui-kit'
import { ratingToColor } from '@src/utils/performance'
import { KpiInterface } from '@src/interfaces/kpis'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ReviewCategory } from '@src/interfaces/performance'
import { InternalLink } from '@components/InternalLink/InternalLink'
import RatingTooltip from '../Common/RatingTooltip'
import AdjustableMatrixTable from '@components/Table/AdjustableMatrixTable'

const rows = (
  employeeId: number,
  id: string | number,
  category: ReviewCategory,
  isView?: boolean,
  wide?: boolean,
) => ({
  cells: [
    {
      ...kpiGenericNameColumn,
      width: wide ? 140 : 122,
      title: 'Name',
    },
    {
      ...kpiWeightColumn,
      width: 78,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...kpiRatingColumn,
      insert: ({ data }) => {
        let link

        switch (category) {
          case ReviewCategory.Performance:
            link = isView
              ? ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL
              : ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL
            break

          case ReviewCategory.Probation:
            link = isView
              ? ROUTES.FORMS.PROBATION_REVIEW_VIEW.DELIVERABLES
              : ROUTES.FORMS.PROBATION_REVIEW.DELIVERABLES
            break
          default:
            link = ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL
        }

        return (
          <Flex width="100%">
            <InternalLink to={pathToUrl(link, { employeeId, id })}>
              <RatingTooltip
                ratings={[]}
                totalRating={data.rating!}
                placement="top"
                type={RatingLabelTypes.InverseUnderline}
              />
            </InternalLink>
          </Flex>
        )
      },
      width: wide ? 155 : 140,
      background: data => ratingToColor(data.rating),
    },
  ] as MatrixColumnCellInterface<Partial<KpiInterface>>[],
})

type Props = {
  data: Partial<KpiInterface>[]
  employeeId: number
  id: string | number
  isView?: boolean
  category: ReviewCategory
  wide?: boolean
}

const KpiCard = ({ data, employeeId, id, isView, category, wide = true }: Props) => {
  return (
    <AdjustableMatrixTable
      rows={rows(employeeId, id, category, isView, wide)}
      data={data}
      noDataMessage="No KPI defined"
      autoResize
    />
  )
}

export default KpiCard
