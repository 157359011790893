import React, { useState } from 'react'

import { PageBody } from '@src/components/Page/PageBody'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { TimeOffBalancesUploadFile } from './TimeOffBalances/TimeOffBalancesUploadFile'
import { TimeOffRequestsUploadFile } from './TimeOffRequests/TimeOffRequestsUploadFile'

interface ImportTimeOffDataProps {
  afterUploadBalancesUrl?: string
  afterUploadRequestsUrl?: string
}

export const ImportTimeOffData = ({
  afterUploadBalancesUrl,
  afterUploadRequestsUrl,
}: ImportTimeOffDataProps) => {
  const [isSimpleBalanceAdjustmentMode, setIsSimpleBalanceAdjustmentMode] = useState(true)

  return (
    <PageBody>
      <BooleanRadioSwitch
        variant="horizontal"
        value={isSimpleBalanceAdjustmentMode}
        onChange={setIsSimpleBalanceAdjustmentMode}
        yesLabel="Simple balance adjustment"
        yesDescription="Update the current employee balance by removing and adding days"
        noLabel="Import time off requests"
        noDescription="Accurately import time off data from the past, present, and future."
      />

      {isSimpleBalanceAdjustmentMode ? (
        <TimeOffBalancesUploadFile afterUploadUrl={afterUploadBalancesUrl} />
      ) : (
        <TimeOffRequestsUploadFile afterUploadUrl={afterUploadRequestsUrl} />
      )}
    </PageBody>
  )
}
