import { useGetCompanyValues } from '@src/api/cultureValues'
import { useGetSkills } from '@src/api/skills'
import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { NavigationConfigInterface } from '@src/components/StatNavigation/types'
import { ROUTES } from '@src/constants/routes'
import { FilterByInterface } from '@src/interfaces/data'
import { PermissionTypes } from '@src/store/auth/types'
import React from 'react'

const navConfig: NavigationConfigInterface<{ skills: number; values: number }>[] = [
  {
    key: 'skills',
    label: 'Skills',
    to: ROUTES.PERFORMANCE.SKILLS,
    canView: { permissions: [PermissionTypes.ViewSkillset] },
  },
  {
    key: 'values',
    label: 'Values',
    to: ROUTES.PERFORMANCE.VALUES,
    canView: { permissions: [PermissionTypes.ViewCompanyValue] },
  },
]

interface Props {
  skillsTableFilters?: FilterByInterface[]
  valuesTableFilters?: FilterByInterface[]
}

export const SkillsAndValuesStatNavigation = ({
  skillsTableFilters,
  valuesTableFilters,
}: Props) => {
  const { data: skills, isLoading: skillsLoading } = useGetSkills({
    filters: skillsTableFilters,
  })

  const { data: values, isLoading: valuesLoading } = useGetCompanyValues({
    filters: valuesTableFilters,
  })
  const statNavigationApi = () => {
    return {
      isLoading: skillsLoading || valuesLoading,
      data: { skills: skills?.count || 0, values: values?.count || 0 },
    }
  }
  return <StatNavigation api={statNavigationApi} config={navConfig} />
}
