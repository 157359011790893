import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes } from '@src/interfaces/data'
import { ImportBandsV2Interface } from '@src/interfaces/importBandsV2'
import { TableCellInputType } from '@src/components/Inputs/TableCellInput/TableCellInput'
import {
  GenericEditableTableColumn,
  RadioSelectInputCell,
  TextCell,
} from '@src/features/GenericEditableTable/components'

type ImportBandsV2Column = GenericEditableTableColumn<ImportBandsV2Interface>

export const importBandsV2SpecialisationColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisation',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="specialisation"
      onChange={onChange}
      selector={selectorKeys.specialisations}
      selectorField="name"
    />
  ),
})

export const importBandsV2SeniorityColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="seniority"
      onChange={onChange}
      selector={selectorKeys.seniority}
      selectorField="name"
    />
  ),
})

export const importBandsV2SeniorityLevelColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'seniority_level',
  dataPoint: 'seniority_level',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.seniority_sublevels,
  title: 'Seniority Level',
  insert: ({ data }) => (
    <RadioSelectInputCell
      clearable
      data={data}
      field="seniority_level"
      onChange={onChange}
      selector={selectorKeys.seniority_sublevels}
      selectorField="name"
    />
  ),
})

export const importBandsV2LocationColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.location,
  title: 'Location',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="location"
      onChange={onChange}
      selector={selectorKeys.location}
      selectorField="name"
    />
  ),
})

export const importBandsV2BenchmarkTypeColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'benchmark_type',
  dataPoint: 'benchmark_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.benchmark_types,
  title: 'Benchmark Type',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="benchmark_type"
      onChange={onChange}
      selector={selectorKeys.benchmark_types}
      selectorField="name"
    />
  ),
})

export const importBandsV2CurrencyISOCodeColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'currency_iso_code',
  dataPoint: 'currency_iso_code',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.currencies,
  title: 'Currency ISO Code',
  insert: ({ data }) => (
    <RadioSelectInputCell
      data={data}
      field="currency_iso_code"
      onChange={onChange}
      selector={selectorKeys.currencies}
      selectorField="iso_code"
    />
  ),
})

export const importBandsV2LowerBandColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'lower_band',
  dataPoint: 'lower_band',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Lower Band',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="lower_band"
      onChange={onChange}
      type={TableCellInputType.positiveFloat}
    />
  ),
})

export const importBandsV2UpperBandColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'upper_band',
  dataPoint: 'upper_band',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Upper Band',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="upper_band"
      onChange={onChange}
      type={TableCellInputType.positiveFloat}
    />
  ),
})

export const importBandsV2OnTargetBonusColumn: ImportBandsV2Column = onChange => ({
  type: CellTypes.insert,
  idPoint: 'on_target_bonus_percent',
  dataPoint: 'on_target_bonus_percent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'On Target Bonus',
  insert: ({ data }) => (
    <TextCell
      data={data}
      field="on_target_bonus_percent"
      onChange={onChange}
      suffix="%"
      type={TableCellInputType.positiveFloat}
    />
  ),
})
