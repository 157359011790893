import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery, groupFiltersIntoURLQuery } from '@src/utils/table'
import {
  AssignGroupAccessManagementInterface,
  GroupAccessManagementInterface,
  ServiceAccountInterface,
  PermissionInterface,
  PermissionManagementInterface,
  UserAccessManagementInterface,
  UserStats,
  GroupStats,
  AssignGroupsAccessManagementInterface,
  AssignedGroup,
  GroupAccessRequestInterface,
} from '@src/interfaces/accessManagement'
import { useFetch } from '@src/utils/reactQuery'
import { TestQueryData } from '@src/features/AudienceSelection/SQLQuery'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { FilterByInterface } from '@src/interfaces/data'
import { ApprovalFlowStep } from '@src/interfaces/approvalFlow'

export const usersRequests: TableRequestInterface<UserAccessManagementInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `/users`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
}

export const useUsersSelector = (filters: FilterByInterface[]) => {
  const result = useFetch<
    GetRequestInterface<{ id: number; employee: EmployeeOptionInterface }>
  >(`/users`, 'v2', {
    params: {
      page_size: 100000,
      fields: 'employee,id',
      ...groupFiltersIntoURLQuery(filters),
    },
  })

  return result.data?.results
}

export const useGetUserStats = (id?: number | string) =>
  useFetch<UserStats>(id ? `/users/${id}/stats` : null, 'v2')

export const useGetGroupStats = (id?: number | string) =>
  useFetch<GroupStats>(id ? `/groups/${id}/stats` : null)

export const groupUserGroupsRequests = (
  id: string,
): TableRequestInterface<AssignGroupAccessManagementInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/groups/${id}/userGroups`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const userUserGroupsRequests = (
  id: string,
): TableRequestInterface<AssignGroupAccessManagementInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/users/${id}/userGroups`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const groupsRequests: TableRequestInterface<GroupAccessManagementInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/groups`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const userRequest: RequestInterfaceNew<UserAccessManagementInterface> = {
  get: async ({ id }) => api.get(`/users/${id}`, undefined, 'v2'),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/users/${id}`, data, undefined, 'v2'),
  submit: async (data, { id }) =>
    apiWithoutHandling.post(`/users/${id}`, data, undefined, 'v2'),
  delete: async ({ id }) => api.delete(`/users/${id}`, undefined, 'v2'),
}

export const assignUserGroupRequest: RequestInterfaceNew<AssignGroupAccessManagementInterface> =
  {
    get: async ({ id }) => api.get(`/groups/${id}`, undefined, 'v2'),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/groups/${id}`, data, undefined, 'v2'),
    submit: async (data, { groupId }) =>
      apiWithoutHandling.post(`/groups/${groupId}/userGroups`, data),
    delete: async ({ id }) => api.delete(`/groups/${id}`, undefined, 'v2'),
  }

export const assignUserGroupsRequest: RequestInterfaceNew<AssignGroupsAccessManagementInterface> =
  {
    get: async ({ id }) => api.get(`/groups/createBulk/${id}`, undefined, 'v2'),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/groups/createBulk/${id}`, data, undefined, 'v2'),
    submit: async data =>
      apiWithoutHandling.post(`/groups/createBulk`, data, undefined, 'v2'),
  }

export const groupAccessRequest: RequestInterfaceNew<GroupAccessRequestInterface> = {
  get: async ({ id }) => api.get(`/groupAccessRequests/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/groupAccessRequests/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/groupAccessRequests`, data),
}

export const useGetGroupAccessApprovals = (id?: number) => {
  return useFetch<ApprovalFlowStep[]>(id ? `/groupAccessRequests/${id}/approvals` : null)
}

export const approveGroupAccess = (id: number) =>
  api.post(`/groupAccessRequests/${id}/approve`)

export const rejectGroupAccess = (id: number, rejection_comment: string) =>
  api.post(`/groupAccessRequests/${id}/reject`, { rejection_comment })

export const groupRequest: RequestInterfaceNew<GroupAccessManagementInterface> = {
  get: async ({ id }) => api.get(`/groups/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`/groups/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/groups`, data),
  delete: async ({ id }) => api.delete(`/groups/${id}`),
}

export const serviceAccountRequest: RequestInterfaceNew<ServiceAccountInterface> = {
  get: async ({ id }) => api.get(`/serviceAccounts/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/serviceAccounts/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/serviceAccounts`, data),
  delete: async ({ id }) => api.delete(`/serviceAccounts/${id}`),
}

export const permissionsManagementRequests: TableRequestInterface<PermissionManagementInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(
        `/permissions`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v2',
      ),
  }

export const permissionsRequests: TableRequestInterface<PermissionInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `/permissions`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
}

export const permissionRequest: RequestInterfaceNew<PermissionInterface> = {
  get: async ({ id }) => api.get(`/permissions/${id}`),
  update: async (data, { id }) => apiWithoutHandling.patch(`/permissions/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/permissions`, data),
  delete: async ({ id }) => api.delete(`/permissions/${id}`),
}

export const assignUserPermissionRequest: RequestInterfaceNew<PermissionManagementInterface> =
  {
    get: async ({ id }) => api.get(`/permissions/${id}`, undefined, 'v2'),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`/permissions/${id}`, data, undefined, 'v2'),
    submit: async data => apiWithoutHandling.post(`/permissions`, data, undefined, 'v2'),
    delete: async ({ id }) => api.delete(`/permissions/${id}`, undefined, 'v2'),
  }

export const revokePermission = (id: number) =>
  api.post(`/permissions/${id}/revoke`, undefined, undefined, 'v2')

export const revokeGroup = (id: number) =>
  api.post(`/groups/${id}/revoke`, undefined, undefined, 'v2')

export const useGetAssignedGroups = (id?: number | string) =>
  useFetch<AssignedGroup[]>(id ? `/users/${id}/assignedGroups` : null, 'v2')

export const runQuery = (
  sql_query?: string | null,
  sql_query_db?: IdAndName<string> | null,
) =>
  apiWithoutHandling
    .post<TestQueryData>(`/groups/runQuery`, {
      sql_query,
      sql_query_db,
    })
    .then(response => ({
      ...response,
      data: { data: response.data },
    }))

export const updateMembers = (id: number | string) => api.post(`/groups/${id}/employees`)
