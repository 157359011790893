import React from 'react'
import {
  BarSkeleton,
  Box,
  DetailsCellSkeleton,
  Group,
  Spacer,
  TileSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'

import SideBar from '@components/SideBar/SideBar'
import {
  useGetDocusignInfo,
  useGetEmbeddedDocumentInfo,
  useGetUploadedDocument,
} from '@src/api/documents'
import { EmployeeDocumentListItemInterface } from '@src/interfaces/documents'

import { DocuSignPreview } from './DocuSignPreview'
import { EmbeddedPreview } from './EmbeddedPreview'
import { UploadPreview } from './UploadPreview'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'

const PREVIEW_CONTAINER_HEIGHT = 320

const Skeleton = () => (
  <VStack space="s-16">
    <BarSkeleton />
    <Box
      height={PREVIEW_CONTAINER_HEIGHT}
      bg={Token.color.background}
      radius={Token.radius.widget}
    >
      <TileSkeleton />
    </Box>
    <Spacer />
    <Group>
      <DetailsCellSkeleton />
      <DetailsCellSkeleton />
      <DetailsCellSkeleton />
    </Group>
  </VStack>
)

type Props = {
  onClose: VoidFunction
  documentListItem?: EmployeeDocumentListItemInterface
}
export const GenericDocumentSidebar = ({ documentListItem, onClose }: Props) => {
  const documentSource = documentListItem?.source
  const isFromUpload = documentSource === 'upload' || documentSource === 'request'

  const { data: embeddedDocument, isLoading: isLoadingEmbeddedDocument } =
    useGetEmbeddedDocumentInfo(
      documentSource === 'embedded' ? documentListItem?.recipient_id : undefined,
    )
  const { data: docusignDocument, isLoading: isLoadingDocusignDocument } =
    useGetDocusignInfo(
      documentSource === 'docusign' ? documentListItem?.recipient_id : undefined,
    )
  const { data: uploadedDocument, isLoading: isLoadingUploadedDocument } =
    useGetUploadedDocument(
      documentListItem?.employee.id,
      isFromUpload && documentListItem?.recipient_id
        ? Number(documentListItem.recipient_id)
        : undefined,
    )

  const renderSidebarContent = () => {
    if (!documentListItem) {
      return <Skeleton />
    }
    if (documentSource === 'embedded') {
      return !embeddedDocument || isLoadingEmbeddedDocument ? (
        <Skeleton />
      ) : (
        <EmbeddedPreview data={embeddedDocument} documentListItem={documentListItem} />
      )
    }
    if (documentSource === 'docusign') {
      return !docusignDocument || isLoadingDocusignDocument ? (
        <Skeleton />
      ) : (
        <DocuSignPreview data={docusignDocument} documentListItem={documentListItem} />
      )
    }
    if (documentSource === 'upload' || documentSource === 'request') {
      return !uploadedDocument || isLoadingUploadedDocument ? (
        <Skeleton />
      ) : (
        <UploadPreview
          data={uploadedDocument}
          documentListItem={documentListItem}
          onClose={onClose}
          previewContainerHeight={PREVIEW_CONTAINER_HEIGHT}
        />
      )
    }
    return null
  }

  return (
    <SideBar
      title={documentListItem?.document_name}
      subtitle={
        documentListItem?.employee ? (
          <EmployeeUserWithAvatar {...documentListItem.employee} />
        ) : undefined
      }
      isOpen={!!documentListItem}
      onClose={onClose}
      sideProps={{ resizable: true }}
    >
      {renderSidebarContent()}
    </SideBar>
  )
}
