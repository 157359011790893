import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import Loader from '@src/components/CommonSC/Loader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import General from './General'
import { userRequest } from '@src/api/accessManagement'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { Route } from 'react-router'
import { Switch } from 'react-router-dom'
import Groups from '@src/pages/Forms/AccessManagementUser/Groups'

const User = () => {
  const { values } = useLapeContext<UserAccessManagementInterface>()

  if (!values) {
    return <Loader />
  }

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title="User details"
        backUrl={ROUTES.ADMIN.ACCESS_MANAGEMENT.USERS}
        subtitle={
          values.user_type === 'employee' ? (
            <UserWithAvatar {...values.employee} />
          ) : (
            values.email
          )
        }
      />
      <Switch>
        <Route path={ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GENERAL}>
          <General />
        </Route>
        <Route path={ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS}>
          <Groups />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={userRequest}>
    <User />
  </Form>
))
