import React from 'react'
import { MeetingTemplateInterface } from '@src/interfaces/meetings'
import {
  ActionButton,
  Avatar,
  chain,
  Group,
  Item,
  ItemSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { PreviewSectionHeader } from './PreviewSectionHeader'
import { MeetingTemplateEditStep } from '../interfaces'
import pluralize from 'pluralize'
import { mapAudienceIcon } from '../helpers'

interface Props {
  data?: MeetingTemplateInterface
  isLoading: boolean
  onEdit: (step: MeetingTemplateEditStep) => void
}

const Skeleton = () => {
  return (
    <Group p="s-16">
      <ItemSkeleton />
      <ItemSkeleton />
    </Group>
  )
}

export const PreviewAudienceSection = ({ data, isLoading, onEdit }: Props) => {
  if (isLoading) {
    return <Skeleton />
  }

  if (!data?.audiences.length) {
    return null
  }

  return (
    <Widget p="s-16">
      <PreviewSectionHeader
        title={
          data?.audiences?.length ? chain('Audience', data?.audiences.length) : 'Audience'
        }
        subtitle="Who should this template apply to?"
        iconName="People"
        actionElement={
          <ActionButton onClick={() => onEdit('audience')}>Edit</ActionButton>
        }
      />
      <VStack pt="s-16" gap="s-8">
        {data?.audiences?.map(audience => (
          <Item
            key={audience.id}
            style={{
              border: `1px solid ${Token.color.greyTone10}`,
              backgroundColor: Token.color.widgetBackground,
            }}
          >
            <Item.Avatar>
              <Avatar useIcon={mapAudienceIcon(audience.audience_type.id)} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{audience.name}</Item.Title>
              <Item.Description>
                <Item.Description>
                  {chain(
                    audience.audience_type.name,
                    `${pluralize('member', audience.headcount, true)}`,
                  )}
                </Item.Description>
              </Item.Description>
            </Item.Content>
          </Item>
        ))}
      </VStack>
    </Widget>
  )
}
