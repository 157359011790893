import { Button, copyToClipboard, StatusPopup } from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'
import React, { useState } from 'react'
import { pathToFullUrlWithActiveWorkspace } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

type Props = {
  interviewId: number
  onClose: () => void
}

const GenerateLinkSuccessPopup = ({ interviewId, onClose }: Props) => {
  const [isCopied, setCopied] = useState(false)
  const link = pathToFullUrlWithActiveWorkspace(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, {
    id: interviewId,
  })

  return (
    <StatusPopup variant="success-result" open onClose={onClose}>
      <StatusPopup.Title>Interview link generated</StatusPopup.Title>
      <StatusPopup.Description>{link}</StatusPopup.Description>
      <StatusPopup.Actions>
        <Button
          variant="secondary"
          useIcon={Copy}
          onClick={() => {
            copyToClipboard(link).then(() => setCopied(true))
          }}
        >
          {isCopied ? 'Copied' : 'Copy link'}
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}

export default GenerateLinkSuccessPopup
