import React from 'react'
import { Text, Flex, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { Warning } from '@revolut/icons'
import Tooltip from '@src/components/Tooltip/Tooltip'

interface CellWithErrorProps {
  data: any
  prop: keyof Omit<any, 'errors'>
}

const CellWithError: React.FC<CellWithErrorProps> = ({ prop, data, children }) => {
  const errors = data.errors?.[prop]
  const fallbackValue = data[prop]
  const hasError = !!errors?.length

  return (
    <Flex>
      <Text color={hasError ? Token.color.red : Token.color.foreground}>
        {children || fallbackValue}
      </Text>
    </Flex>
  )
}

const generateColumn = (title: string, id: string): ColumnInterface<any> => ({
  type: CellTypes.text,
  idPoint: id,
  dataPoint: id,
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title,
  insert: ({ data }) => <CellWithError data={data} prop={id} />,
})

const errors: ColumnInterface<any> = {
  type: CellTypes.insert,
  idPoint: 'errors',
  dataPoint: 'errors',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => {
    if (data.errors && Object.keys(data.errors).length) {
      return (
        <Tooltip
          placement="right"
          title="Errors:"
          text={Object.keys(data.errors)
            .map(key => `${key}: ${data.errors[key]}`)
            .join('; ')}
        >
          <Warning color="red" />
        </Tooltip>
      )
    }
    return ''
  },
  selectorsKey: selectorKeys.changelog_item_statuses,
  title: '',
}

const columnKeys = [
  'Employee ID',
  'Employee Name',
  'Payroll Element',
  'Amount',
  'Currency',
]

export const columns = [
  { ...errors, width: 20 },
  ...columnKeys.map(key => ({
    ...generateColumn(key, key),
    width: 100,
  })),
]
