import React, { useState } from 'react'
import { Box, Flex, TransitionCollapse, Color } from '@revolut/ui-kit'

import { FeedbackCard } from '@src/features/Feedback/FeedbackCard'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { submitOnboardingAnswer } from '@src/api/engagement'

interface FeedbackProps {
  showQuestion: boolean
  onQuestionAnswered: () => void
}

export const Feedback = ({ showQuestion, onQuestionAnswered }: FeedbackProps) => {
  const [questionScore, setQuestionScore] = useState<number | null>(null)
  const [questionText, setQuestionText] = useState<string>()
  const [questionVisible, setQuestionVisible] = useState(true)
  const [questionPending, setQuestionPending] = useState(false)

  if (!showQuestion) {
    return null
  }

  const onSubmitAnswer = async () => {
    if (questionScore != null) {
      setQuestionPending(true)
      try {
        await submitOnboardingAnswer({
          score: questionScore * 0.2,
          text: questionText,
          topic: { id: 'onboarding' },
          status: { id: 'completed' },
        })
        pushNotification({
          value: 'Thanks for your feedback!',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      } finally {
        setQuestionPending(false)
        setQuestionVisible(false)
      }
    }
  }

  return (
    <TransitionCollapse in={questionVisible} onExited={onQuestionAnswered}>
      <Box mt={{ all: '80px', lg: '160px' }}>
        <Flex
          flexDirection="column"
          p="s-24"
          borderRadius={24}
          alignItems="center"
          bg={Color.BACKGROUND}
          maxWidth={1000}
        >
          <FeedbackCard
            title="How was your onboarding experience?"
            placeholder="Add any additional feedback (optional)"
            score={questionScore}
            onScoreChange={setQuestionScore}
            text={questionText}
            onTextChange={setQuestionText}
            onSubmit={onSubmitAnswer}
            pending={questionPending}
          />
        </Flex>
      </Box>
    </TransitionCollapse>
  )
}
