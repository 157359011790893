import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import PageLoading from '@components/PageLoading/PageLoading'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import UpwardsReview from '.'
import { FormTitleObsolete } from '@src/pages/EmployeeProfile/Preview/components/FormTitleObsolete'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import Page404 from '@src/pages/Page404/Page404'
import { PageWrapper } from '@components/Page/Page'
import { Box } from '@revolut/ui-kit'

const backUrl = ROUTES.PEOPLE.EMPLOYEES

export const UpwardsReviewPage = () => {
  const params = useParams<{ id?: string }>()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<EmployeeInterface>()

  useEffect(() => {
    if (params.id && !isNaN(parseFloat(params.id))) {
      setLoading(true)
      getEmployeeWithManagerFilter(params.id, 'line_manager__id')
        .then(res => {
          setData(res.data)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [params.id])

  if (loading) {
    return <PageLoading />
  }
  if (!data) {
    return <Page404 />
  }

  return (
    <PageWrapper>
      <PageHeader title={<FormTitleObsolete data={data} />} backUrl={backUrl} />
      <Box mt="-s-24">
        <UpwardsReview data={data} />
      </Box>
    </PageWrapper>
  )
}
