import React from 'react'
import { Widget, Item, Token, List, Tag, Image } from '@revolut/ui-kit'
import { FormattedMessage } from 'react-intl'

interface Props {
  title?: string
  isRecommended?: boolean
  description?: string
  keyValues?: string[]
}
export const EngagementIntroStartWidget = ({
  title,
  description,
  keyValues,
  isRecommended,
}: Props) => {
  return (
    <Widget p="s-16">
      <Item p="s-0" pb="s-16">
        <Item.Content>
          <Item.Title>
            <FormattedMessage
              id="onboardingV2.engagement.firstSurvey.title"
              defaultMessage="Start with your first survey"
            />
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="onboardingV2.engagement.firstSurvey.subtitle"
              defaultMessage="We recommend to measure your engagement metrics with the following survey template"
            />
          </Item.Description>
        </Item.Content>
        {/* @TODO allow selecing different templates 
        https://revolut.atlassian.net/browse/REVC-6381 */}
        {/* <Item.Side>
          <ActionButton>Change</ActionButton>
        </Item.Side> */}
      </Item>
      <Item style={{ border: '1px solid', borderColor: Token.color.greyTone10 }}>
        <Item.Avatar>
          <Image src="https://assets.revolut.com/assets/3d-images-v2/3D332.png" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{title}</Item.Title>
          <Item.Description use="div">
            {description}
            <List variant="compact" color={Token.color.greyTone50} pt="s-8">
              {keyValues?.map(value => (
                <List.Item useIcon="Dot" key={value}>
                  {value}
                </List.Item>
              ))}
            </List>
          </Item.Description>
        </Item.Content>
        <Item.Side style={{ justifyContent: 'flex-start' }}>
          {isRecommended && (
            <Tag variant="outlined" useIcon="Cleaning">
              <FormattedMessage id="common.recommended" defaultMessage="Recommended" />
            </Tag>
          )}
        </Item.Side>
      </Item>
    </Widget>
  )
}
