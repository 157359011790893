import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Box, VStack, Flex } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const RequisitionsSettings = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeRequisitionPreferences)
  return (
    <VStack gap="s-16">
      <Box>
        <SectionTitle
          title="Do you want to use confidential requisitions?"
          subtitle="If enabled, allows to create confidential requisitions, intended for highly sensitive or classified positions within the organisation. By default, only the requisition creator can view a confidential requisition. Access for others can be granted as necessary."
        />
        <LapeBooleanRadioSwitch
          name="enable_confidential_button"
          variant="horizontal"
          disabled={disableEdit}
          yesDescription="I want to use this feature for highly sensitive or classified positions"
          noLabel={
            <Flex>
              No
              <Recommended />
            </Flex>
          }
          noDescription="I'm not anticipating any highly sensitive hiring at the moment. I'll set up the feature later on"
        />
      </Box>
    </VStack>
  )
}

export default RequisitionsSettings
