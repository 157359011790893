import React, { useRef, useState } from 'react'
import { ActionMenu, MoreBar } from '@revolut/ui-kit'
import { StageType } from '@src/interfaces/hiringProccess'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { FormattedMessage } from 'react-intl'

type AddStageButtonProps = {
  onClick: (stageType: OptionInterface) => void
}

export const AddStageButton = ({ onClick }: AddStageButtonProps) => {
  const { data } = useGetSelectors(selectorKeys.hiring_stage_types)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)
  const handleClick = (stageType: StageType) => {
    const stage = data?.find(({ id }) => id === stageType)
    if (stage) {
      onClick(stage)
    }
  }
  return (
    <>
      <MoreBar.Action
        ref={anchorRef}
        useIcon="ChevronDown"
        onClick={() => {
          setOpen(!open)
        }}
      >
        <FormattedMessage
          id="onboarding.interviews.addStage"
          defaultMessage="Add stage"
        />
      </MoreBar.Action>
      <ActionMenu open={open} anchorRef={anchorRef} onClose={() => setOpen(false)}>
        <ActionMenu.Group>
          <ActionMenu.Item
            onClick={() => {
              handleClick('skills_round')
            }}
          >
            <ActionMenu.Title>Skills stage</ActionMenu.Title>
            <ActionMenu.Description>
              Technical interview to asses candidate skills
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('screen_call')
            }}
          >
            <ActionMenu.Title>Screen call stage</ActionMenu.Title>
            <ActionMenu.Description>
              Face-to-face interview with the candidate
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('home_assessment')
            }}
          >
            <ActionMenu.Title>Home task stage</ActionMenu.Title>
            <ActionMenu.Description>
              Send candidate home task. Review and assess Technical
            </ActionMenu.Description>
          </ActionMenu.Item>
          <ActionMenu.Item
            onClick={() => {
              handleClick('final_round')
            }}
          >
            <ActionMenu.Title>Final stage</ActionMenu.Title>
            <ActionMenu.Description>
              Interview with hiring manager and/or team member(s)
            </ActionMenu.Description>
          </ActionMenu.Item>
        </ActionMenu.Group>
      </ActionMenu>
    </>
  )
}
