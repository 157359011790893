import React from 'react'
import { notReachable } from '@src/utils/notReachable'
import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
  ReviewCycleStage,
} from '@src/interfaces/reviewCycles'
import { ReviewActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/ReviewActions'
import { CalibrationActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/CalibrationActions'
import { GoalsActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/GoalsActions'
import { PublishActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Actions/PublishActions'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'
import { timelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'

interface Props {
  cycle: ReviewCyclesInterface
  stages: ReviewCycleStage[]
  eligibleGroups: EligibleGroupInterface[]
  stage: ReviewCycleStage
}

export const StageActions = ({ cycle, stages, eligibleGroups, stage }: Props) => {
  const canSwitchNext =
    cycleModel.isManual(cycle) && cycleModel.getCurrentStage(cycle) === stage
  const canSwitchPrevious =
    cycleModel.isManual(cycle) &&
    (timelineModel.getPreviousStage(cycle, stages) === stage ||
      stage === ReviewCycleStage.Completed)

  switch (stage) {
    case ReviewCycleStage.Review:
      return (
        <ReviewActions
          cycle={cycle}
          stages={stages}
          stage={stage}
          eligibleGroups={eligibleGroups}
          canSwitchNext={canSwitchNext}
          canSwitchPrevious={canSwitchPrevious}
        />
      )
    case ReviewCycleStage.Calibration:
      return (
        <CalibrationActions
          cycle={cycle}
          stages={stages}
          stage={stage}
          canSwitchNext={canSwitchNext}
          canSwitchPrevious={canSwitchPrevious}
        />
      )
    case ReviewCycleStage.TeamGoals:
    case ReviewCycleStage.DepartmentGoals:
      return (
        <GoalsActions
          cycle={cycle}
          stages={stages}
          stage={stage}
          canSwitchNext={canSwitchNext}
          canSwitchPrevious={canSwitchPrevious}
        />
      )
    case ReviewCycleStage.ManagersPublish:
    case ReviewCycleStage.EmployeesPublish:
    case ReviewCycleStage.Completed:
      return (
        <PublishActions
          cycle={cycle}
          stages={stages}
          stage={stage}
          canSwitchNext={canSwitchNext}
          canSwitchPrevious={canSwitchPrevious}
        />
      )
    default:
      return notReachable(stage)
  }
}
