import React from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, Flex } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { EmployeeInterface } from '@src/interfaces/employees'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getEmployeeLabels } from '@src/api/labels'
import { LabelInterface } from '@src/interfaces/labels'
import {
  labelAddedByColumn,
  labelAssignedOnColumn,
  labelBadgeColumn,
  labelDescriptionColumn,
  labelModeColumn,
  labelNameColumn,
  labelStatusColumn,
  labelVisibilityColumn,
} from '@src/constants/columns/label'
import { canAssignLabel } from '@src/store/auth/selectors'
import { Statuses } from '@src/interfaces'
import { TableNames } from '@src/constants/table'

interface Props {
  data: EmployeeInterface
}

const ROW = (employeeId: number): RowInterface<LabelInterface> => ({
  linkToForm: data =>
    data.label_assignment
      ? navigateTo(
          pathToUrl(ROUTES.FORMS.LABEL.GENERAL, {
            id: data.label_assignment.id,
            employeeId,
          }),
        )
      : undefined,
  cells: [
    {
      ...labelNameColumn,
      width: 200,
    },
    {
      ...labelBadgeColumn,
      width: 100,
    },
    {
      ...labelDescriptionColumn,
      width: 350,
    },
    {
      ...labelAddedByColumn,
      width: 140,
    },
    {
      ...labelModeColumn,
      width: 100,
    },
    {
      ...labelAssignedOnColumn,
      width: 140,
    },
    {
      ...labelVisibilityColumn,
      width: 140,
    },
    {
      ...labelStatusColumn,
      width: 140,
    },
  ],
})

export const Labels = ({ data }: Props) => {
  const initialFilter = [
    {
      filters: [{ name: Statuses.active, id: Statuses.active }],
      columnName: 'status',
    },
  ]

  const table = useTable(
    {
      getItems: params => getEmployeeLabels(data.id, params),
    },
    initialFilter,
  )

  const canAssignLabels = useSelector(canAssignLabel)

  const onAssignLabelClick = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.LABEL.GENERAL, { employeeId: data.id }))
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        {canAssignLabels && (
          <Flex mb="s-16">
            <ActionButton onClick={onAssignLabelClick} mr="s-16" useIcon="Plus">
              Assign label
            </ActionButton>
          </Flex>
        )}
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.EmployeeLabels}
          useWindowScroll
          dataType="Label"
          row={ROW(data.id)}
          {...table}
          noDataMessage="All employee labels will appear here."
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
