import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@src/utils/queryParamsHooks'

enum QueryParams {
  OpenSidebar = 'openSidebar',
  BonusId = 'bonusId',
}

export const useBonusSidebar = () => {
  const { query, changeQueryParam, deleteQueryParam } = useQuery()

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [bonusId, setBonusId] = useState<string>()

  useEffect(() => {
    setBonusId(query[QueryParams.BonusId])
    setIsOpen(!!query[QueryParams.OpenSidebar])
  }, [query])

  const openBonusSidebar = useCallback((newBonusId?: number) => {
    changeQueryParam(QueryParams.OpenSidebar, 'true')

    if (newBonusId) {
      changeQueryParam(QueryParams.BonusId, String(newBonusId))
    }
  }, [])

  const closeBonusSidebar = useCallback(() => {
    deleteQueryParam(QueryParams.BonusId)
    deleteQueryParam(QueryParams.OpenSidebar)
  }, [])

  return { isOpen, bonusId, openBonusSidebar, closeBonusSidebar }
}
