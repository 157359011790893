import { Statuses } from '@src/interfaces'
import DateDistance, {
  DoneDateDistance,
} from '@components/ColumnInserts/DateDistance/DateDistance'
import { formatDistance } from 'date-fns'
import React from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { NotificationsInterface } from '@src/interfaces/notifications'

const CustomTooltip = styled(Tooltip)`
  display: block;
  width: fit-content;
`

interface TodoDeadlineProps {
  data: NotificationsInterface
}

const TodoDeadline = ({ data }: TodoDeadlineProps) => {
  if (data.status === Statuses.completed) {
    return (
      <DoneDateDistance
        deadlineDate={data?.deadline}
        doneDate={data.completed_date_time}
      />
    )
  }
  if (!data.action_date_time) {
    return <DateDistance date={data?.deadline} />
  }
  return (
    <CustomTooltip
      placement="top"
      text={`Received ${formatDistance(new Date(data.action_date_time), new Date(), {
        addSuffix: true,
      })}`}
    >
      <DateDistance date={data?.deadline} />
    </CustomTooltip>
  )
}

export default TodoDeadline
