import React from 'react'
import { Avatar, Item, VStack } from '@revolut/ui-kit'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeHTMLEditor from '@components/Inputs/LapeFields/LapeHTMLEditor'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { SeniorityProgression } from '@src/features/GrowthPlans/GrowthPlan/components/SeniorityProgression'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'

export const GeneralInfoWidget = () => {
  const { values } = useLapeContext<GrowthPlanInterface>()

  return (
    <Item>
      <Item.Content>
        <Item.Title>General</Item.Title>
        <Item.Description>
          Define the name, write an instruction and select the owner
        </Item.Description>
        <VStack space="s-16" mt="s-16">
          <LapeRadioSelectInput
            name="target_specialisation"
            label="Target Specialisation"
            selector={selectorKeys.approved_pending_specialisations}
            // TODO: spec switching will be implemented in a separate ticket in scope of https://revolut.atlassian.net/browse/REVC-7151
            disabled
          />
          <Item>
            <Item.Avatar>
              <Avatar useIcon="Profile" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>
                <SeniorityProgression
                  values={[
                    values.current_seniority?.name,
                    values.target_seniority?.name,
                  ].filter(Boolean)}
                />
              </Item.Title>
              <Item.Description>Seniority</Item.Description>
            </Item.Content>
          </Item>
          <LapeDatePickerInput
            name="deadline"
            label="Select Due date"
            description="This is the date by which you want the target to be achieved."
            required
          />
          <LapeHTMLEditor
            name="description"
            placeholder="Description"
            message="Optional. Briefly outline the purpose of this growth plan, including key areas of development and the expected outcomes."
            hideOptional
            height={150}
          />
        </VStack>
      </Item.Content>
    </Item>
  )
}
