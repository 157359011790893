import { RowInterface } from '@src/interfaces/data'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import {
  employeeRelationColumn,
  gradeColumn,
  ownerColumn,
  statusColumn,
  targetRoleColumn,
  targetSeniorityColumn,
} from '@src/features/GrowthPlans/constants/columns'

export const getRow = (): RowInterface<GrowthPlanRelationInterface> => ({
  cells: [
    { ...employeeRelationColumn, width: 300 },
    { ...gradeColumn, width: 150 },
    { ...targetRoleColumn, width: 200 },
    { ...targetSeniorityColumn, width: 150 },
    { ...ownerColumn, width: 100 },
    { ...statusColumn, width: 100 },
  ],
})
