import React, { useEffect, useMemo } from 'react'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { selectorKeys } from '@src/constants/api'
import { ActionWidget, DetailsCell, VStack, InputGroup, List } from '@revolut/ui-kit'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { useGetSelectors } from '@src/api/selectors'
import { PageActions } from '@components/Page/PageActions'
import { IdAndName } from '@src/interfaces'
import { useParams } from 'react-router-dom'
import { Info } from '@revolut/icons'
import { GDPRField, GDPRPolicy } from '@src/interfaces/dataRetentionSettings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

const GDPR_FIELDS: GDPRField[] = [
  'employment',
  'personal',
  'benefits',
  'time_attendance',
  // TODO: temporarily hidden, uncomment when BE is ready - https://revolut.atlassian.net/browse/RHR-2703
  // 'training',
  // 'payroll',
]

const FIELD_NAMES = {
  employment: 'Employment',
  personal: 'Personal',
  benefits: 'Benefits',
  time_attendance: 'Time & Attendance',
  training: 'Training',
  payroll: 'Payroll',
}

export const FIELD_DETAILS = {
  employment:
    'Basic details, contracts, right to work, screenenings, key persons, bonuses, salary sacrifice, manual label assignments, Jira issues, terminations',
  personal: 'Personal details, diversity, linked accounts, documents',
  benefits: 'Benefits enrolments, documents, dependents',
  time_attendance:
    'Balances, balance transactions, requests, documents, policy assignments, attendance schedules, shifts, attendance compensation, policy assignments',
  training: 'Training enrolments, certifications',
  payroll: 'Pay cycles, documents',
}

type Params = {
  id?: string
  countryId?: string
}

const GDPRRetentionSettingsForm = () => {
  const { values } = useLapeContext<GDPRPolicy>()
  const { id, countryId } = useParams<Params>()

  const { data: countries } = useGetSelectors<IdAndName<number>>(
    selectorKeys.gdpr_countries,
  )

  const selectedCountry = useMemo(() => {
    return countries?.find(country => country.id === Number(countryId))
  }, [countries, countryId])

  const backUrl = ROUTES.APPS.DATA_RETENTION.EMPLOYMENT
  const title = 'Set data retention policy'

  useEffect(() => {
    if (!values.country) {
      values.country = { id: Number(countryId) }
    }

    if (values.sections?.length > 0) {
      return
    }
    values.sections = GDPR_FIELDS.map((field, index) => ({
      order: index,
      category: {
        id: field,
        name: FIELD_NAMES[field],
      },
    }))
  }, [])

  return (
    <>
      <PageHeader
        pb="s-8"
        title={title}
        subtitle={selectedCountry?.name}
        backUrl={backUrl}
      />
      <PageBody>
        <VStack gap="s-16">
          <ActionWidget>
            <ActionWidget.Title>
              Philosophy/Business Justification adopted in the Retention Schedule
            </ActionWidget.Title>
            <ActionWidget.Content>
              <List variant="compact">
                {!!id && (
                  <List.Item useIcon={Info} color="orange">
                    Please be extremely careful when updating data retention settings.
                    Changes to these settings can potentially trigger immediate and
                    irreversible anonymisation of personal data for many employees.
                  </List.Item>
                )}
                <List.Item useIcon={Info} color="grey-tone-50">
                  Please check the local data retention laws to define the retention
                  period. When in doubt please check with the legal team.
                </List.Item>
              </List>
            </ActionWidget.Content>
          </ActionWidget>
          <LapeRadioSelectInput
            mt="s-16"
            name="owner"
            label="Policy owner"
            selector={selectorKeys.all_employees}
          />
        </VStack>
        <AutoStepper>
          {GDPR_FIELDS.map((field, fieldIndex) => (
            <React.Fragment key={field}>
              <NewStepperTitle title={FIELD_NAMES[field]} />
              <DetailsCell mb="s-16">
                <DetailsCell.Title alignSelf="center">Data points</DetailsCell.Title>
                <DetailsCell.Content>{FIELD_DETAILS[field]}</DetailsCell.Content>
              </DetailsCell>
              <InputGroup data-testid={`retention-period-${field}`}>
                <LapeRadioSelectInput
                  name={`sections.${fieldIndex}.trigger_event`}
                  label="Triggering Event"
                  message="Date when the retention period will start counting"
                  selector={selectorKeys.gdpr_triggering_events}
                  searchable={false}
                  mb="s-6"
                />
                <InputGroup variant="horizontal">
                  <LapeNewInput
                    name={`sections.${fieldIndex}.retention_period`}
                    label="Retention Period"
                    type="number"
                    required
                  />
                  <LapeRadioSelectInput
                    name={`sections.${fieldIndex}.time_unit`}
                    label="Time Unit"
                    selector={selectorKeys.gdpr_time_units}
                    searchable={false}
                  />
                </InputGroup>
              </InputGroup>
            </React.Fragment>
          ))}
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Data retention policy successfully set"
          previewUrl={ROUTES.FORMS.DATA_RETENTION.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default GDPRRetentionSettingsForm
