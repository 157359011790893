import React from 'react'
import { Tag, Box, BoxProps, Flex, TagVariant, TagProps } from '@revolut/ui-kit'

interface TagInterface {
  id: string | number
  name: string
  link?: string
}

export interface TagsProps extends Omit<BoxProps, 'color'> {
  title?: string
  tags: TagInterface[]
  variant?: TagVariant
  color?: TagProps['color']
}

const Tags = ({ title, tags, variant = 'faded', color, ...props }: TagsProps) => {
  return (
    <Box {...props}>
      <Flex gap="s-8" flexWrap="wrap">
        {tags.map((tag, i) => (
          <Tag
            key={tag.id}
            variant={variant}
            use={tag.link ? 'a' : undefined}
            href={tag.link || undefined}
            target={tag.link ? '_blank' : undefined}
            color={color}
          >
            {i === 0 && title ? `${title}: ${tag.name}` : tag.name}
          </Tag>
        ))}
      </Flex>
    </Box>
  )
}

export default Tags
