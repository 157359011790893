import React from 'react'
import { Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { seniorityRequests } from '@src/api/seniority'
import { PageWrapper } from '@src/components/Page/Page'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SeniorityInterface } from '@src/interfaces/seniority'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { DeleteButtonLape } from '../FormButtons'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import SettingsButtons from '@src/features/SettingsButtons'

const General = () => {
  const { values } = useLapeContext<SeniorityInterface>()

  const backUrl = ROUTES.APPS.POSITIONS.SENIORITIES

  return (
    <PageWrapper>
      <PageHeader title={values.name} backUrl={backUrl} />
      <PageBody>
        <Box>
          {values.id && (
            <SettingsButtons mb="s-12">
              <DeleteButtonLape
                data={values}
                backUrl={backUrl}
                deleteApi={seniorityRequests.delete!}
                title="Seniority"
              />
            </SettingsButtons>
          )}

          <LapeNewInput name="name" label="Name" required />
        </Box>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="Seniority saved successfully"
          afterSubmitUrl={backUrl}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={seniorityRequests}>
    <General />
  </Form>
))
