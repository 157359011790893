import React from 'react'
import { ErrorWidget } from '@revolut/ui-kit'

export const NoHeliosPermissions = () => (
  <ErrorWidget>
    <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
    <ErrorWidget.Title>No Access</ErrorWidget.Title>
    <ErrorWidget.Description>
      You need Helios + SQL permissions to access this tab.
    </ErrorWidget.Description>
  </ErrorWidget>
)
