import React from 'react'
import { HStack, Tag, Text } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { WorkScheduleListItemInterface } from '@src/interfaces/workSchedule'
import { selectorKeys } from '../api'
import {
  getWorkingHoursNode,
  getWorkingDaysString,
} from '@src/apps/People/TimeManagement/WorkSchedule/helpers'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const timeOffWorkScheduleNameColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.work_schedules,
    title: 'Name',
    insert: ({ data }) => (
      <HStack space="s-8" align="center">
        <Text>{data.name || '-'}</Text>
        {data.is_default_schedule ? <Tag variant="status">default</Tag> : null}
      </HStack>
    ),
  }

export const timeOffWorkScheduleMembersColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'members',
    dataPoint: 'members',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Members',
    insert: ({ data }) => <Text>{data.assigned_count}</Text>,
  }

export const timeOffWorkScheduleDaysWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'working_days',
    dataPoint: 'working_days',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Days worked',
    insert: ({ data }) => <Text>{getWorkingDaysString(data.working_days)}</Text>,
  }

export const timeOffWorkScheduleHoursWorkedColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'work_start_time',
    dataPoint: 'work_start_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Hours worked',
    insert: ({ data }) => <Text>{getWorkingHoursNode(data.working_days)}</Text>,
  }

export const timeOffWorkScheduleStatusColumn: ColumnInterface<WorkScheduleListItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.work_schedule_statuses,
    title: 'Status',
    insert: ({ data }) => {
      let variant: ColorTagVariant = 'default'

      if (data.status.id === 'active') {
        variant = 'success'
      }
      return <Table.StatusCell status={data.status.id} variant={variant} />
    },
  }
