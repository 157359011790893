import React, { useState } from 'react'
import { ActionButton, DetailsCell, Group, Subheader } from '@revolut/ui-kit'
import SideBar from '@components/SideBar/SideBar'
import { PayCyclePaymentInterface } from '@src/interfaces/payroll'
import { capitalize, lowerCase } from 'lodash'
import { formatDate, formatMoneyWithCode } from '@src/utils/format'
import { Statuses } from '@src/interfaces'
import { getStatusColor } from '@src/components/CommonSC/General'
import { deletePayment } from '@src/api/payroll'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { PaymentPreviewItem } from '@src/components/Payments/PaymentPreviewItem'

type Props = {
  onExit: () => void
  onAfterDelete: () => void
  data?: PayCyclePaymentInterface | null
}

const PreviewPaymentSidebar = ({ onExit, onAfterDelete, data }: Props) => {
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const onDelete = async () => {
    setIsDeleting(true)
    try {
      await deletePayment(`${data?.id}`)
    } finally {
      setIsDeleting(false)
    }
    onExit()
    onAfterDelete()
    setIsConfirmPopupOpen(false)
    setIsDeleting(false)
  }

  return (
    <>
      <SideBar isOpen={!!data} onClose={onExit} title="Transaction details">
        <ActionButton
          variant="negative"
          useIcon="Delete"
          onClick={() => setIsConfirmPopupOpen(true)}
        >
          Delete
        </ActionButton>
        <Subheader variant="nested">
          <Subheader.Title>Transaction details</Subheader.Title>
        </Subheader>
        <Group>
          <DetailsCell>
            <DetailsCell.Title>Settlement profile</DetailsCell.Title>
            <DetailsCell.Content>
              {data?.payroll_element?.settlement_profile}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Type</DetailsCell.Title>
            <DetailsCell.Content>
              {capitalize(data?.payroll_settlement?.payment_type || '-')}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Date</DetailsCell.Title>
            <DetailsCell.Content>
              {data?.payroll_settlement?.expected_settlement_date
                ? formatDate(data.payroll_settlement.expected_settlement_date)
                : '-'}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Reference</DetailsCell.Title>
            <DetailsCell.Content>
              {data?.payroll_settlement?.reference || '-'}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Amount</DetailsCell.Title>
            <DetailsCell.Content>
              {formatMoneyWithCode(data?.amount, data?.currency)}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Status</DetailsCell.Title>
            <DetailsCell.Content
              color={getStatusColor(
                data?.payroll_settlement?.status.id || Statuses.closed,
              )}
            >
              {data?.payroll_settlement?.status?.name || 'Unreconciled'}
            </DetailsCell.Content>
          </DetailsCell>
          <DetailsCell>
            <DetailsCell.Title>Details</DetailsCell.Title>
            <DetailsCell.Content>
              {data?.payroll_settlement?.description || '-'}
            </DetailsCell.Content>
          </DetailsCell>
        </Group>
        {!!data?.payroll_settlement?.payroll_payments?.length && (
          <>
            <Subheader variant="nested">
              <Subheader.Title>Corresponding charges</Subheader.Title>
            </Subheader>
            <Group>
              {data?.payroll_settlement?.payroll_payments?.map(id => (
                <PaymentPreviewItem key={id} id={id} />
              ))}
            </Group>
          </>
        )}
      </SideBar>
      <ConfirmationDialog
        open={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        label="Are you sure you want to delete this payment?"
        body={`Continue to delete this ${
          lowerCase(data?.payroll_settlement?.status?.name) || 'unreconciled'
        } transaction of ${formatMoneyWithCode(data?.amount, data?.currency)}`}
        onConfirm={onDelete}
        onReject={() => setIsConfirmPopupOpen(false)}
        onExited={() => setIsConfirmPopupOpen(false)}
        loading={isDeleting}
        yesMessage="Delete"
        noMessage="Cancel"
      />
    </>
  )
}

export default PreviewPaymentSidebar
