import { formatPercentage } from '@src/utils/format'

const diff = 0.005
const min = 0
const max = 1
const precision = 2

const outOfRangeLabel = 'N/A'

export const formatPrecisePercentage = (
  value: number,
  fractionDigits: number = precision,
  preciseDiff: number = diff,
  minLimit: number = min,
  maxLimit: number = max,
): string => {
  if (!Number.isFinite(value)) {
    return outOfRangeLabel
  }

  if (value < minLimit || value > maxLimit) {
    return outOfRangeLabel
  }

  if (value < min + preciseDiff || value > max - preciseDiff) {
    return formatPercentage(value, fractionDigits)
  }

  return formatPercentage(value)
}
