import React from 'react'
import { VStack } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { BenefitsWidget } from './Components/BenefitsWidget'
import { CompensationWidget } from './Components/CompensationWidget'

type Props = {
  compensationParams: CompensationParams
  data: EmployeeInterface
}

export const CompensationLayoutTabV2 = ({ compensationParams, data }: Props) => {
  return (
    <VStack gap="s-16">
      <CompensationWidget compensationParams={compensationParams} id={data.id} />
      <BenefitsWidget id={data.id} />
    </VStack>
  )
}
