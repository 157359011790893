import { Avatar, Item } from '@revolut/ui-kit'
import {
  AccountActions,
  Archive,
  ArrowThinRight,
  Calendar,
  CalendarDate,
  Document,
  Envelope,
  Form,
  Message,
  SocialDislike,
  SocialLike,
  Unarchive,
} from '@revolut/icons'
import React from 'react'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { TimeLineEventCategory } from '@src/interfaces/timeline'
import { EmployeeBasicInterface } from '@src/interfaces/employees'

interface EmailThreadItemAvatarProps {
  user: EmployeeBasicInterface | null
  personName: string
  category: TimeLineEventCategory
  recommendation?: string
}

const getIcon = (category: TimeLineEventCategory, recommendation?: string) => {
  switch (category) {
    case 'scorecard_submitted': {
      switch (recommendation?.toLowerCase()) {
        case 'hire':
        case 'strong hire':
          return SocialLike

        case 'no hire':
        case 'strong no hire':
          return SocialDislike

        default:
          return SocialLike
      }
    }
    case 'changed_candidate_stage':
      return ArrowThinRight
    case 'candidate_archived':
      return Archive
    case 'candidate_unarchived':
      return Unarchive
    case 'email_thread':
      return Envelope
    case 'comment_added':
      return Message
    case 'file_added':
      return Document
    case 'form_filled':
      return Form
    case 'interview_scheduled':
      return CalendarDate
    case 'interview_cancelled':
      return Calendar

    default:
      return AccountActions
  }
}

const TimelineAvatar = ({
  user,
  personName,
  category,
  recommendation,
}: EmailThreadItemAvatarProps) => {
  const initials = getInitials(user ? user.full_name : personName)
  return (
    <Item.Avatar>
      <Avatar image={getAvatarUrl(user?.avatar)}>
        {!user?.avatar && initials}
        <Avatar.Badge useIcon={getIcon(category, recommendation)} bg="deep-grey" />
      </Avatar>
    </Item.Avatar>
  )
}

export default TimelineAvatar
