import { MoreBar } from '@revolut/ui-kit'
import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { useGetSelectors } from '@src/api/selectors'
import { useGetDocumentsSettings } from '@src/api/settings'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { EmployeeInterface } from '@src/interfaces/employees'
import { selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { hasDocumentManagerPermissions } from '../common'
import { RequestDocumentButton } from './RequestDocumentButton'

type Props = {
  data: EmployeeInterface
  showSecondary?: boolean
}
export const ActionButtons = ({ data, showSecondary }: Props) => {
  const isCommercial = useIsCommercial()
  const user = useSelector(selectUser)
  const { data: documentsSettings } = useGetDocumentsSettings()

  const { categoryId } = useParams<{ categoryId: string }>()
  const { data: documentCategories, isLoading: isLoadingCategories } = useGetSelectors(
    selectorKeys.document_categories,
  )
  const selectedCategory = documentCategories?.find(
    category => String(category.id) === categoryId,
  )

  const canAddDocuments = !!data?.field_options?.permissions?.includes(
    PermissionTypes.UploadDocuments,
  )
  const handleAddDocument = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: data.id }), {
      initialValues: {
        employee: { id: data.id, name: data.full_name },
        issuer: { id: user.id, name: user.full_name },
        category: selectedCategory,
      },
    })
  }

  const showRequestGeneratedDocuments = documentsSettings?.enable_document_requests
  const showGenerateByRule =
    !isCommercial &&
    documentsSettings?.enable_document_generation_from_templates &&
    hasDocumentManagerPermissions(data)

  const handleGenerateByRule = () => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, { employeeId: data.id }),
      {
        initialValues: {
          generated_by: { id: user.id, name: user.full_name },
        },
      },
    )
  }

  return (
    <Table.Widget.MoreBar maxCount={1}>
      {canAddDocuments && (
        <MoreBar.Action
          useIcon="Plus"
          variant="accent"
          onClick={handleAddDocument}
          disabled={isLoadingCategories}
        >
          Add document
        </MoreBar.Action>
      )}
      {showSecondary && (
        <>
          {showRequestGeneratedDocuments && <RequestDocumentButton data={data} />}
          {showGenerateByRule && (
            <MoreBar.Action useIcon="Document" onClick={handleGenerateByRule}>
              Generate
            </MoreBar.Action>
          )}
        </>
      )}
      <Table.ColumnsSettingsButton />
    </Table.Widget.MoreBar>
  )
}
