import React, { useState } from 'react'
import { InputGroup, Subheader, Input, Box, Token } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { css } from 'styled-components'

import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { CompanyCompensationReviewsFormInterface } from '@src/interfaces/compensation'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  getDateFromString,
  getTimeFromString,
  localDateToUtc,
} from '@src/utils/timezones'
import { HideInputCalendarIndicatorCss } from '@src/components/Inputs/LapeFields/helpers'

const SubheaderMarginCss = css`
  margin-top: ${Token.space.s16};
`

const TimeInputCss = css`
  flex: 1;
  ${HideInputCalendarIndicatorCss}
`

interface DateTimeInputProps {
  name:
    | 'currency_freeze_date_time'
    | 'preparation_end_date_time'
    | 'preparation_start_date_time'
    | 'planning_start_date_time'
    | 'planning_end_date_time'
    | 'review_start_date_time'
    | 'review_end_date_time'
    | 'alignment_start_date_time'
    | 'alignment_end_date_time'
    | 'communication_date_time'
    | 'payroll_deadline'
  datePlaceholder: string
}

const DEFAULT_TIME = '00:00'

const DateTimeInput = ({ name, datePlaceholder }: DateTimeInputProps) => {
  const { values, apiErrors } = useLapeContext<CompanyCompensationReviewsFormInterface>()

  const [initialTime, setInitialTime] = useState('')

  const field = values[name]

  const inputError = apiErrors[name]

  const onDateValueChange = (value: Date | null) => {
    if (value) {
      const newDate = localDateToUtc(value)
      const time = field ? getTimeFromString(field) : initialTime || DEFAULT_TIME
      values[name] = `${getDateFromString(newDate)}T${time}Z`
    }
  }

  const onTimeValueChange = (event: React.FormEvent<HTMLInputElement>) => {
    clearError()

    const value = event.currentTarget.value

    if (field) {
      values[name] = `${getDateFromString(field)}T${value || DEFAULT_TIME}Z`
    } else {
      setInitialTime(value)
    }
  }

  const clearError = () => {
    apiErrors[name] = undefined
  }

  return (
    <Box>
      <InputGroup variant="horizontal" data-testid={name}>
        <LapeDatePickerInput
          name={name}
          label={datePlaceholder}
          onChange={onDateValueChange}
          required
          clearable={false}
          onAfterChange={clearError}
        />
        <Input
          value={field ? getTimeFromString(field) : initialTime}
          onChange={onTimeValueChange}
          label="Time"
          type="time"
          aria-invalid={!!inputError}
          css={TimeInputCss}
        />
      </InputGroup>
    </Box>
  )
}

export const General = () => {
  const params = useParams<{ id?: string }>()

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General information" />
          <InputGroup>
            <LapeNewInput name="year" label="Year" required />
            <InputGroup variant="horizontal">
              <LapeDatePickerInput
                name="start_date_time"
                label="Cycle start date"
                required
              />
              <LapeDatePickerInput name="end_date_time" label="Cycle end date" required />
            </InputGroup>
          </InputGroup>

          <NewStepperTitle title="Review cycle key dates" />
          <DateTimeInput
            name="currency_freeze_date_time"
            datePlaceholder="Currency freeze date"
          />

          <Subheader variant="nested" css={SubheaderMarginCss}>
            <Subheader.Title>Cycle preparation</Subheader.Title>
          </Subheader>
          <InputGroup>
            <DateTimeInput
              name="preparation_start_date_time"
              datePlaceholder="Preparation start date"
            />
            <DateTimeInput
              name="preparation_end_date_time"
              datePlaceholder="Preparation end date"
            />
          </InputGroup>

          <Subheader variant="nested" css={SubheaderMarginCss}>
            <Subheader.Title>Planning</Subheader.Title>
          </Subheader>
          <InputGroup>
            <DateTimeInput
              name="planning_start_date_time"
              datePlaceholder="Planning start date"
            />
            <DateTimeInput
              name="planning_end_date_time"
              datePlaceholder="Planning end date"
            />
          </InputGroup>

          <Subheader variant="nested" css={SubheaderMarginCss}>
            <Subheader.Title>Review</Subheader.Title>
          </Subheader>
          <InputGroup>
            <DateTimeInput
              name="review_start_date_time"
              datePlaceholder="Review start date"
            />
            <DateTimeInput
              name="review_end_date_time"
              datePlaceholder="Review end date"
            />
          </InputGroup>

          <Subheader variant="nested" css={SubheaderMarginCss}>
            <Subheader.Title>Alignment</Subheader.Title>
          </Subheader>
          <InputGroup>
            <DateTimeInput
              name="alignment_start_date_time"
              datePlaceholder="Alignment start date"
            />
            <DateTimeInput
              name="alignment_end_date_time"
              datePlaceholder="Alignment end date"
            />
          </InputGroup>

          <Subheader variant="nested" css={SubheaderMarginCss}>
            <Subheader.Title>Finalizing</Subheader.Title>
          </Subheader>
          <InputGroup>
            <DateTimeInput
              name="communication_date_time"
              datePlaceholder="Communication date"
            />
            <DateTimeInput name="payroll_deadline" datePlaceholder="Payroll deadline" />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Compensation review successfully saved"
          onAfterSubmit={res => {
            const backUrl = pathToUrl(
              ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.PREVIEW,
              {
                id: res.id,
              },
            )
            if (params.id) {
              goBack(backUrl)
            } else {
              navigateReplace(backUrl)
            }
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
