import React from 'react'
import { MouseEventHandler, ReactNode, useMemo, useState } from 'react'
import { createChain, Text, TextButton, Flex, FlexProps, Token } from '@revolut/ui-kit'

interface CareersPositionWidgetLocationsProps extends FlexProps {
  locations: string[]
  children: ReactNode
}

const VISIBLE_COUNT = 8

const CareersPositionLocations = ({
  locations,
  children,
  ...rest
}: CareersPositionWidgetLocationsProps) => {
  const [showAll, setShowAll] = useState(false)
  const uniqLocations = useMemo(() => Array.from(new Set(locations)), [locations])

  const count = uniqLocations.length
  const visibleLocations = showAll ? uniqLocations : uniqLocations.slice(0, VISIBLE_COUNT)

  const handleClick: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault()
    setShowAll(true)
  }

  return (
    <Flex alignItems="flex-start" color={Token.color.greyTone50} {...rest}>
      {children}
      <Flex flexWrap="wrap" alignItems="center" ml="s-6">
        {createChain(
          <Text use="div" mx="s-4" color={Token.color.greyTone20}>
            |
          </Text>,
        )(visibleLocations)}
        {!showAll && count > VISIBLE_COUNT && (
          <TextButton ml="s-8" onClick={handleClick}>
            <Text letterSpacing="0.016em">+{count - VISIBLE_COUNT}</Text>
          </TextButton>
        )}
      </Flex>
    </Flex>
  )
}

export default CareersPositionLocations
