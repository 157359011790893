import React, { useState } from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '../components/OnboardingChecklistContent'
import { kpiConfig } from '../common/constants'
import { Intro } from './Intro'
import { Types } from './Types'
import { Hierarchy } from './Hierarchy'
import { DataToolsIntegration } from './DataToolsIntegration'
import { Preview } from './Preview/Preview'
import { performanceSettings } from '@src/api/performanceSettings'
import { connectionRequestsNew } from '@src/api/dataAnalytics'
import { API } from '@src/constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export const KPI = () => {
  const form = { api: performanceSettings }
  const lsKey = 'kpi_onboarding_data_tool_integration'
  const savedValue = workspaceLocalStorage.getItem(lsKey)
  const [dataToolIntegrationEnabled, setDataToolIntegrationEnabled] = useState(
    typeof savedValue === 'string' ? savedValue === 'true' : true,
  )

  const toggleDataToolIntegrationEnabled = (value: boolean) => {
    setDataToolIntegrationEnabled(value)
    localStorage.setItem(lsKey, value ? 'true' : 'false')
  }

  const routes = [
    {
      title: 'Intro',
      path: ROUTES.ONBOARDING_CHECKLIST.KPI.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST.KPI.INTRO,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Intro,
    },
    {
      title: 'Types',
      path: ROUTES.ONBOARDING_CHECKLIST.KPI.TYPES,
      url: ROUTES.ONBOARDING_CHECKLIST.KPI.TYPES,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Types,
      form,
    },
    {
      title: 'Hierarchy',
      path: ROUTES.ONBOARDING_CHECKLIST.KPI.HIERARCHY,
      url: ROUTES.ONBOARDING_CHECKLIST.KPI.HIERARCHY,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Hierarchy,
      form: {
        ...form,
        invalidateQueries: [API.PERFORMANCE_SETTINGS],
      },
    },
    {
      title: 'BI Integration',
      path: ROUTES.ONBOARDING_CHECKLIST.KPI.BI_INTEGRATION,
      url: ROUTES.ONBOARDING_CHECKLIST.KPI.BI_INTEGRATION,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: () => (
        <DataToolsIntegration
          enabled={dataToolIntegrationEnabled}
          onChange={toggleDataToolIntegrationEnabled}
        />
      ),
      form: dataToolIntegrationEnabled
        ? {
            api: connectionRequestsNew,
            forceParams: undefined,
          }
        : undefined,
    },
    {
      title: 'Preview',
      path: ROUTES.ONBOARDING_CHECKLIST.KPI.PREVIEW,
      url: ROUTES.ONBOARDING_CHECKLIST.KPI.PREVIEW,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Preview,
      isWide: true,
    },
  ]
  return <OnboardingChecklistContent config={kpiConfig} routes={routes} />
}
