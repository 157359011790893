import { Avatar, HStack, IconName, Token, VStack } from '@revolut/ui-kit'
import SectionTitle from '../components/SectionTitle'
import React from 'react'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { ScorecardPreview } from './ScoreCardPreview/ScorecardPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { ScorecardQuestionsWidget } from '../../Performance/Scorecard/CustomQuestions/ScorecardQuestions'
import { RecommendedLabel } from '../components/Recommended'
import { GroupWithRequired } from '@components/RequiredItem/RequiredItem'

export const PerformanceScorecard = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const renderIcon = (name: IconName) => (
    <Avatar size={40} color={Token.color.blue} useIcon={name} />
  )
  const additionalQuestionsField = 'enable_custom_scorecard_sections'
  const showCustomQuestions = !!values[additionalQuestionsField]

  return (
    <HStack space="s-16">
      <VStack flex={4} use="div">
        <SectionTitle
          title="What do you want to assess in addition to deliverables?"
          subtitle="An assessment for deliverables is required, however you can disable the review for skills and cultural values"
        />
        <GroupWithRequired
          requiredItems={[
            {
              icon: 'Target',
              title: 'Goals and deliverables',
              description:
                'Review the progress of an employee’s goals and the completion of their deliverables',
            },
          ]}
        >
          <LapeNewSwitch
            itemTypeProps={{
              avatar: renderIcon('IndustrialGear'),
              title: <RecommendedLabel label="Skill competencies" />,
              description: 'Assess employee technical skills',
            }}
            name="enable_skill_assessment"
          />
          <LapeNewSwitch
            itemTypeProps={{
              avatar: renderIcon('Heart'),
              title: <RecommendedLabel label="Alignment on Cultural Values" />,
              description: 'Review employee cultural alignment',
            }}
            name="enable_values"
          />
          <LapeNewSwitch
            itemTypeProps={{
              avatar: renderIcon('HelpChat'),
              title: 'Custom questions',
              description: 'Create questions specific to your organisation',
            }}
            name={additionalQuestionsField}
          />
        </GroupWithRequired>
        {showCustomQuestions && (
          <>
            <SectionTitle
              title="Create your custom questions here"
              subtitle="You can skip this step now and add them later"
            />
            <ScorecardQuestionsWidget />
          </>
        )}
      </VStack>
      <ScorecardPreview
        showSkills={values.enable_skill_assessment}
        showValues={values.enable_values}
      />
    </HStack>
  )
}
