import React from 'react'
import { Image, Item, ItemSkeleton, ActionButton } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetEmployeeChangelogFields } from '@src/api/changelog'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { canViewChangelog } from '../ProfileSummary/common'

type Props = {
  employee: EmployeeInterface
}
export const PendingChangesWarning = ({ employee }: Props) => {
  const filterByStatus = 'pending,approved'

  const { data: pendingChangesData, isLoading } = useGetEmployeeChangelogFields(
    employee.id,
    [{ columnName: 'status', filters: [{ id: filterByStatus, name: filterByStatus }] }],
    canViewChangelog(employee),
  )

  if (isLoading) {
    return <ItemSkeleton />
  }
  if (pendingChangesData?.results.length) {
    return (
      <Item>
        <Item.Avatar>
          <Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-business/3D304.webp',
            }}
          />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Change request in progress</Item.Title>
          <Item.Description>
            Some information will update upon approval or on the effective date
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            // @ts-expect-error ui-kit does not correctly infer `to` prop type by passed `use` component value
            to={{
              pathname: pathToUrl(ROUTES.FORMS.EMPLOYEE.CHANGELOG, { id: employee.id }),
              search: `?status=${filterByStatus}`,
            }}
          >
            Pending requests
          </ActionButton>
        </Item.Side>
      </Item>
    )
  }
  return null
}
