import React, { useMemo } from 'react'
import {
  Group,
  HStack,
  Separator,
  Subheader,
  Tag,
  Token,
  VStack,
  Link as UIKitLink,
  Icon,
  Text,
  Flex,
  Box,
  Copyable,
  useTooltip,
  Tooltip,
  Ellipsis,
  Action,
} from '@revolut/ui-kit'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { formatDateTime } from '@src/utils/format'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { CountryFlag } from '@components/Inputs/LapeFields/LapePhoneInput'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { navigateTo } from '@src/actions/RouterActions'
import { formatDistanceToNow } from 'date-fns'
import ChangeRound from '@src/pages/Forms/Candidate/InterviewProgress/components/ChangeRound/ChangeRound'
import OpenInNewTab from '@components/OpenInNewTab/OpenInNewTab'
import { getLinkedinUrl } from '@src/utils/hiring'
import TextWithMoreCount from '@components/TextWithMoreCount/TextWithMoreCount'
import { useGetFullInterviewRounds } from '@src/api/recruitment/interviews'
import CandidateTags from '@src/features/InterviewTool/CandidateTags'
import { CandidateMatchSidebarOpportunities } from '@src/features/CommonCandidatesTable/CandidateMatchSidebarOpportunities'
import { useShowCandidateMatch } from '@src/features/CommonCandidatesTable/useShowCandidateMatch'

type Props = {
  candidate: CandidateInterface
  round: InterviewRoundInterface
}

const CvScreeningSide = ({ candidate, round }: Props) => {
  const seenTooltip = useTooltip()
  const showCandidateMatch = useShowCandidateMatch()
  const { data: originSelector } = useGetSelectors(
    round?.origin ? selectorKeys.candidate_origin_choices : null,
  )
  const { data: allRounds, isLoading: isAllRoundsLoading } = useGetFullInterviewRounds(
    candidate.id,
  )
  const origin = useMemo(
    () => originSelector?.find(item => item.id === round?.origin)?.name,
    [originSelector, round],
  )
  const linkedIn = getLinkedinUrl(candidate.links)
  const links = candidate.links?.filter(link => link !== linkedIn)
  const activeJobPosting = allRounds?.find(item => item.id === round.id)
    ?.application_forms?.[0]?.job_posting

  const appliedLabel = useMemo(() => {
    switch (round.origin) {
      case 'application':
        return 'Applied'

      case 'referral':
        return 'Referred'

      default:
        return 'Sourced'
    }
  }, [round.origin])

  return (
    <VStack gap="s-8" width="100%" maxWidth={408}>
      <Group>
        <Subheader variant="default">
          <Subheader.Title>
            <UIKitLink
              use={Link}
              to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidate.id })}
              target="_blank"
              color="inherit"
            >
              <HStack gap="s-8" align="center">
                {candidate.full_name} <Icon name="LinkExternal" size={18} />
              </HStack>
            </UIKitLink>
          </Subheader.Title>
          {origin && (
            <Subheader.Side>
              <Tag useIcon="Document" variant="outlined" color={Token.color.deepGrey}>
                {origin}
              </Tag>
            </Subheader.Side>
          )}
        </Subheader>
        <Separator />
        <FormPreview data={candidate}>
          {!isAllRoundsLoading && (
            <>
              {activeJobPosting ? (
                <FormPreview.Item
                  title="Job posting"
                  insert={() => activeJobPosting.name}
                  to={() =>
                    pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
                      id: activeJobPosting.id,
                    })
                  }
                />
              ) : (
                <FormPreview.Item
                  title="Specialisation"
                  insert={() => round.specialisation.name}
                  to={() =>
                    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                      id: round.specialisation.id,
                    })
                  }
                />
              )}
            </>
          )}

          <FormPreview.Item
            title="Last seen"
            insert={() =>
              round.cv_seen_at ? (
                <HStack gap="s-4">
                  <Text {...seenTooltip.getAnchorProps()} style={{ flexShrink: 0 }}>
                    {formatDistanceToNow(new Date(round.cv_seen_at))} ago
                  </Text>
                  <Tooltip {...seenTooltip.getTargetProps()}>
                    {formatDateTime(round.cv_seen_at)}
                  </Tooltip>

                  {round.cv_seen_by && (
                    <HStack gap="s-8" style={{ flexShrink: 0 }}>
                      <Text>by</Text>
                      <UserWithAvatar compact {...round.cv_seen_by} />
                    </HStack>
                  )}
                </HStack>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item
            title={appliedLabel}
            insert={() =>
              round?.specialisation_latest_application_date_time
                ? formatDateTime(round.specialisation_latest_application_date_time)
                : '-'
            }
          />
          <FormPreview.Item
            title="Current country"
            insert={() =>
              candidate.country ? (
                <HStack gap="s-8">
                  <CountryFlag countryCode={candidate.country.country_code} />{' '}
                  <Text>{candidate.country.name}</Text>
                </HStack>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item
            title="Preferred work locations"
            insert={() =>
              candidate.preferred_locations?.length ? (
                <TextWithMoreCount
                  items={candidate.preferred_locations}
                  visibleCount={2}
                  expandable
                />
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item<CandidateInterface>
            title="Recruiter assigned"
            insert={resp =>
              resp.recruiter ? <UserWithAvatar {...resp.recruiter} /> : '-'
            }
          />
        </FormPreview>
      </Group>

      <CandidateTags candidate={candidate} />

      {showCandidateMatch && (
        <Box>
          <Subheader variant="nested">
            <Subheader.Title>CV score</Subheader.Title>
          </Subheader>
          <CandidateMatchSidebarOpportunities candidateId={candidate.id} />
        </Box>
      )}

      <WorkExperienceInfo
        data={candidate.work_experiences}
        years={candidate.years_of_experience}
      />

      <EducationInfo data={candidate.educations} />

      <FormPreview
        data={candidate}
        title="Personal details"
        // @TODO: open in current page or open in a new tab with opened sidebar
        onEdit={() =>
          navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidate.id }))
        }
      >
        <Group>
          <FormPreview.Item<CandidateInterface>
            title="Email"
            insert={data =>
              data.email ? (
                <Flex alignItems="center">
                  <Box mr="10px">
                    <Copyable
                      value={data.email}
                      labelButtonCopy="Copy"
                      onCopy={() => {
                        pushNotification({
                          value: 'Email copied to clipboard',
                          duration: SUCCESS_DEFAULT_DURATION,
                          type: NotificationTypes.success,
                        })
                      }}
                    />
                  </Box>
                  <Ellipsis>{data.email}</Ellipsis>
                </Flex>
              ) : (
                '-'
              )
            }
          />

          {linkedIn && (
            <FormPreview.Item
              title="Linkedin"
              insert={() => (
                <UIKitLink use="a" href={linkedIn} target="_blank">
                  <Action useIcon="LogoLinkedIn">Open in new tab</Action>
                </UIKitLink>
              )}
            />
          )}

          {!!links?.length && (
            <FormPreview.Item
              title="Links"
              insert={() => links.map(link => <OpenInNewTab key={link} link={link} />)}
            />
          )}
        </Group>
      </FormPreview>

      <ChangeRound candidateId={candidate.id} activeRoundId={round.id} readOnly />
    </VStack>
  )
}

export default CvScreeningSide
