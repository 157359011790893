import React from 'react'
import { connect } from 'lape'
import { Box, Button, List, Popup, Text } from '@revolut/ui-kit'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { formatStepName } from '../ApprovalFlow/ApprovalFlow'
import { useLapeContext } from '../Form/LapeForm'
import { RoleInterface } from '@src/interfaces/roles'

interface Props {
  onClose: () => void
  onSubmit: () => void
  type: 'role' | 'specialisation'
  loading?: boolean
}

const LapeSubmitPopups = ({ onClose, onSubmit, type, loading }: Props) => {
  const { values } = useLapeContext<RoleInterface>()
  const popupRouter = () => {
    return (
      <>
        <Box>
          <Text variant="h5" use="h5">
            Submit {type} for approval?
          </Text>
        </Box>
        <Box mt="s-12">
          <Text variant="h6" use="h6">
            The {type} definition will be reviewed by:
          </Text>
          <List variant="compact">
            {values.approval_flow_status?.approval_steps?.map?.((step, idx) => {
              return (
                <List.Item key={idx}>
                  <Text use="span">{formatStepName(step.approval_step || '')}</Text>
                  <Text ml="s-12">({step.expected_approver?.display_name})</Text>
                </List.Item>
              )
            })}
          </List>
        </Box>
        <Text use="p" mt="s-12">
          Please ensure the quality of the {type} definition that you have provided.
        </Text>
        <Box mt="s-20">
          <LapeNewTextArea
            name="description"
            label={`Enter why you need this ${type}`}
            required
            rows={2}
          />
        </Box>
        <Popup.Actions>
          <Button
            elevated
            disabled={!values.description}
            pending={loading}
            onClick={onSubmit}
          >
            Submit for approval
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
        </Popup.Actions>
      </>
    )
  }

  return (
    <Popup variant="modal-view" open onClose={onClose}>
      {popupRouter()}
    </Popup>
  )
}

export default connect(LapeSubmitPopups)
