import { useEffect, useRef, useState } from 'react'
import { getReportRun, getReportRunResultExport, reportRun } from '@src/api/dataAnalytics'
import {
  ReportInterface,
  RunReportResponseInterface,
  QueryRunStatuses,
} from '@src/interfaces/dataAnalytics'
import { useTableReturnType } from '@src/components/Table/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pushError } from '@src/store/notifications/actions'
import { saveFile } from '@src/utils'
import { ExecutionParameters } from '../components/Popup'
import { RUN_REPORT_TIMEOUT } from '../constants'
import { isExecuted, isRunning } from '../utils'

interface Props {
  latestSuccessRun?: RunReportResponseInterface
  setPopupOpen: (isOpen: boolean) => void
  table: useTableReturnType<RunReportResponseInterface, {}>
}

export const useActions = ({ latestSuccessRun, setPopupOpen, table }: Props) => {
  const { values } = useLapeContext<ReportInterface>()
  const runTimerId = useRef<ReturnType<typeof setTimeout>>()

  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [executionParameters, setExecutionParameters] = useState<ExecutionParameters>({})
  const [runStatus, setRunStatus] = useState<QueryRunStatuses>()
  const [runId, setRunId] = useState<number | null>(null)

  useEffect(() => {
    return () => {
      if (runTimerId.current) {
        clearTimeout(runTimerId.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isRunning(runStatus)) {
      scheduleGetReportRun()
    }

    if (isExecuted(runStatus)) {
      table.refresh()
    }
  }, [runStatus])

  const scheduleGetReportRun = () => {
    runTimerId.current = setTimeout(async () => {
      setRunStatus(undefined)

      try {
        const result = await getReportRun(values.id, runId!)
        setRunStatus(result.data.status)
        setIsLoading(isRunning(result.data.status))
      } catch (e) {
        setRunStatus(QueryRunStatuses.Error)
        setIsLoading(false)
      }
    }, RUN_REPORT_TIMEOUT)
  }

  const runReport = async () => {
    setIsLoading(true)
    setPopupOpen(false)
    setRunStatus(undefined)

    try {
      const result = await reportRun(values.id, executionParameters)
      setRunId(result.data.id)
      setRunStatus(result.data.status)
      setIsLoading(isRunning(result.data.status))
    } catch (e) {
      setRunStatus(QueryRunStatuses.Error)
      setIsLoading(false)
    }
  }

  const downloadReport = async () => {
    setIsDownloadLoading(true)

    const reportRunId = latestSuccessRun?.id || runId
    try {
      const resp = await getReportRunResultExport(values.id, reportRunId!)
      if (resp.data.url) {
        saveFile(resp.data.url, `${values.name}`)
      }
    } catch (e) {
      pushError({ error: e })
    } finally {
      setIsDownloadLoading(false)
    }
  }

  return {
    executionParameters,
    setExecutionParameters,
    isDownloadLoading,
    isLoading,
    runId,
    runStatus,
    runReport,
    downloadReport,
  }
}
