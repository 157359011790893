import SideBar from '@src/components/SideBar/SideBar'
import React from 'react'
import { Cell, Flex, VStack, Text, Box, ActionButton, Icon, Token } from '@revolut/ui-kit'
import { KPI_CHECKLIST_PLAYBOOK } from '@src/constants/externalLinks'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

interface Props {
  isOpen: boolean
  onClose: () => void
}

const QualityCheckSidebar = ({ isOpen, onClose }: Props) => {
  return (
    <SideBar isOpen={isOpen} onClose={onClose} title="Quality checklist">
      <HideIfCommercial>
        <Flex pb="s-24">
          <ActionButton
            useIcon="LinkExternal"
            use="a"
            href={KPI_CHECKLIST_PLAYBOOK}
            target="_blank"
          >
            View playbook
          </ActionButton>
        </Flex>
      </HideIfCommercial>
      <Cell display="block">
        <Text use="p" variant="primary" pb="s-24">
          KPIs are evaluated against 10 checks to determine quality and whether it meets
          specific aspects of the KPI standards.
        </Text>
        <VStack space="s-16">
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                KPI has an adequate description
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              The KPI description should be clear, informative and easily understood by
              anyone at Revolut.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Initial value is close to previous result
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              KPIs with targets across multiple cycles should ensure continuity with the
              previous result.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Is stable
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              KPI should be tracking a trend, such as a moving average, rather than short
              period variations.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Is updated periodically
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              Results should update on a frequent, periodic basis, not just once at the
              end of the cycle.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Queries return timeseries data
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              Any SQL queries should return the result against a timeseries (e.g. daily),
              not just a single value.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Has n months history
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              The KPI should return at least 1 year of historic values with continuity.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                KPI target is ambitious
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              Target values should be set a suitable distance from the initial value,
              before the cycle starts.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                Queries execute in reasonable time
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              SQL queries should return their results in under 30 seconds.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                KPI weight is greater than zero
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              KPIs must be a weight greater than zero assigned to them.
            </Text>
          </Box>
          <Box>
            <Flex pb="s-8" alignItems="center">
              <Icon name="Check" color={Token.color.green} size={16} />
              <Text pl="s-12" use="p" variant="h6">
                The initial and target values are different
              </Text>
            </Flex>
            <Text use="p" variant="caption">
              Targets should be distinct from the initial value and align with the
              measurement direction.
            </Text>
          </Box>
        </VStack>
      </Cell>
    </SideBar>
  )
}

export default QualityCheckSidebar
