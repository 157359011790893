import * as React from 'react'
import { FormEvent, Ref } from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { Box, Flex, TextArea, TextAreaProps } from '@revolut/ui-kit'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useNewChangelogProps } from '@src/utils/form'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { obfuscateNonPrintableCharacters } from '@src/utils/string'

interface Props extends TextAreaProps {
  name: string
  /** Marks field as optional otherwise */
  required?: boolean
  /** Hides the optional text for display only fields */
  hideOptional?: boolean
  hasError?: boolean
  /* forwardRef does not work properly with connect()  */
  elementRef?: Ref<HTMLTextAreaElement> | null
  onAfterChange?: (value?: string) => void
  maxCharacters?: number
}

const LapeNewTextArea = ({
  name,
  hideOptional,
  elementRef,
  onAfterChange = () => {},
  maxCharacters,
  message,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const changelog = useNewChangelogProps(name)
  const { value, onChange, hidden } = lapeProps

  if (hidden) {
    return null
  }

  return (
    <TextArea
      onChange={(e: FormEvent<HTMLTextAreaElement>) => {
        if (maxCharacters !== undefined && e.currentTarget.value.length > maxCharacters) {
          return
        }

        const newVal = obfuscateNonPrintableCharacters(e.currentTarget.value)
        onChange(newVal)
        onAfterChange(newVal)
      }}
      value={value || null}
      {...props}
      ref={elementRef}
      {...getCommonInputProps(
        {
          ...props,
          hideOptional,
          name,
          message:
            maxCharacters === undefined ? (
              message
            ) : (
              <Flex justifyContent="space-between">
                <span>{message}</span>
                <span>
                  {value?.length || 0}/{maxCharacters}
                </span>
              </Flex>
            ),
        },
        lapeProps,
        !!formValidator?.validated,
      )}
      renderAction={(...args) => (
        <>
          {props.renderAction && <Box mr="s-8">{props.renderAction(...args)}</Box>}
          {changelog.renderAction && changelog.renderAction()}
        </>
      )}
    />
  )
}

export default connect(LapeNewTextArea)
