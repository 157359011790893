import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { resignationReasonsFormRequests } from '@src/api/employeeResignation'
import LetterAttachment from './General'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'

type Props = {
  resignation?: EmployeeResignationInterface
}
const LetterAttachmentForm = connect(({ resignation }: Props) => (
  <Form api={resignationReasonsFormRequests}>
    <LetterAttachment resignation={resignation} />
  </Form>
))

export default LetterAttachmentForm
