import React, { useMemo, useState } from 'react'
import { SpecialisationHiringProcess } from '@src/interfaces/hiringProccess'
import { useTable } from '@src/components/Table/hooks'
import {
  hiringProcessesTableRequests,
  useGetSpecialisationHiringProcess,
} from '@src/api/hiringProcess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import {
  BottomSheet,
  Box,
  Button,
  Description,
  Header,
  Icon,
  MoreBar,
  TableWidget,
  VStack,
} from '@revolut/ui-kit'
import { HiringProcessTable } from '@src/pages/Forms/SpecialisationForm/HiringProcess/HiringProcessTable'
import { CloneHiringProcess } from './CloneHiringProcess'
import { useParams, useRouteMatch } from 'react-router-dom'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

type HiringProcessParams = {
  subtab: string
}

export const HiringProcessesTable = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const params = useParams<HiringProcessParams>()
  const hiringProcessIdParam = params.subtab
  const [showNote, setShowNote] = useState(false)
  const isPreviewTab = !!useRouteMatch(ROUTES.FORMS.SPECIALISATIONS.PREVIEW)
  const basePath = isPreviewTab
    ? ROUTES.FORMS.SPECIALISATIONS.PREVIEW
    : ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS
  const { data: hiringProcess, isLoading } = useGetSpecialisationHiringProcess(
    values.id,
    hiringProcessIdParam,
  )
  const table = useTable<SpecialisationHiringProcess>(
    hiringProcessesTableRequests(values.id),
    [
      {
        columnName: 'status',
        filters: [{ id: 'active', name: 'active' }],
      },
    ],
    undefined,
    { disableQuery: true },
  )

  const tabs = useMemo(() => {
    return table.data.map(({ id, name, is_default }) => {
      const path = pathToUrl(basePath, {
        ...params,
        subtab: id,
      })
      return {
        icon: is_default ? <Icon name="StarFilled" size={16} /> : undefined,
        path,
        title: name,
        to: path,
      }
    })
  }, [table.data])
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeHiringProcess)
  const handleEdit = () => {
    if (hiringProcess?.id) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL, {
          hiringProcessId: hiringProcess.id,
          specialisationId: values.id,
        }),
      )
    }
  }
  if (!hiringProcessIdParam) {
    return (
      <InternalRedirect
        to={pathToUrl(basePath, {
          ...params,
          subtab: values.default_hiring_process.id,
        })}
      />
    )
  }
  return (
    <>
      {showNote && (
        <BottomSheet
          open
          onClose={() => {
            setShowNote(false)
          }}
        >
          <Header>
            <Header.Title>Note</Header.Title>
          </Header>
          <Description>{hiringProcess?.description}</Description>
          <BottomSheet.Actions>
            <Button
              variant="secondary"
              onClick={() => {
                setShowNote(false)
              }}
            >
              Close
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
      )}
      <VStack gap="s-8" pt="s-8">
        <Box px="s-8">
          <TabBarNavigation tabs={tabs} behaviour="scroll" fullWidth />
        </Box>
        <TableWidget>
          <TableWidget.Actions>
            <MoreBar>
              <CloneHiringProcess
                hiringProcess={values.default_hiring_process}
                name={values.name}
                variant="more-bar"
              />
              {canEdit && (
                <MoreBar.Action useIcon="Pencil" onClick={handleEdit} pending={isLoading}>
                  Edit process
                </MoreBar.Action>
              )}
              <MoreBar.Action
                useIcon="Sticker"
                onClick={() => {
                  setShowNote(!showNote)
                }}
                pending={isLoading}
              >
                See note
              </MoreBar.Action>
            </MoreBar>
          </TableWidget.Actions>
          <TableWidget.Table>
            <HiringProcessTable
              hiringProcessRounds={hiringProcess?.hiring_process_stages}
              loading={isLoading}
            />
          </TableWidget.Table>
        </TableWidget>
      </VStack>
    </>
  )
}
