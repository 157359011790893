import React, { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash/debounce'
import { navigateTo } from '@src/actions/RouterActions'
import { addRoadmaps, getUnassignedRoadmaps } from '@src/api/roadmaps'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { UnassignedRoadmapInterface } from '@src/interfaces/roadmaps'
import SidebarMultiselect from '@components/Inputs/SidebarMultiselect/SidebarMultiselect'
import { chain, IconName, Text } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { IssueTypes, ItemType } from '@src/interfaces/deliverables'
import { EmptySearchDescription } from './EmptySearchDescription'
import { InitialSearchDescription } from './InitialSearchDescription'
import { Tags } from './Tags'
import { SidebarOption } from '@src/components/Inputs/SidebarMultiselect/types'

interface StructureProps {
  entityType: EntityTypes
  id: number
  isOpen: boolean
  onAfterSubmit: () => void
  onClose: () => void
  reviewCycle: ReviewCyclesInterface | null
  tags?: string[]
}

export const issueIconsByType: Record<IssueTypes, IconName> = {
  [IssueTypes.Epic]: 'JiraEpic|image',
  [IssueTypes.Bug]: 'JiraBug|image',
  [IssueTypes.Story]: 'JiraStory|image',
  [IssueTypes.Task]: 'JiraTask|image',
  [IssueTypes.Subtask]: 'JiraSubtask|image',
  [IssueTypes.Other]: 'JiraOther|image',
  [IssueTypes.SCA]: 'JiraOther|image',
}

export const parseUnassignedRoadmaps = (
  roadmaps: UnassignedRoadmapInterface[],
): SidebarOption<UnassignedRoadmapInterface>[] => {
  return roadmaps.map(roadmap => {
    return {
      id: roadmap.id,
      link: {
        name: roadmap.key,
        href: roadmap.epic_url,
      },
      name: roadmap.epic_name,
      disabled: roadmap.issue_type !== IssueTypes.Epic,
      icon: roadmap.issue_type ? issueIconsByType[roadmap.issue_type] : undefined,
    }
  })
}

const parseKeys = (roadmaps: SidebarOption[]) => {
  return roadmaps.map(option => option?.link?.name || '')
}

const getUrl = (entityType: EntityTypes, params: { id: number }): string => {
  switch (entityType) {
    case EntityTypes.department:
      return pathToUrl(ROUTES.FORMS.DEPARTMENT.SETTINGS, params)
    case EntityTypes.team:
      return pathToUrl(ROUTES.FORMS.TEAM.SETTINGS, params)
    default:
      return ''
  }
}

export const AddRoadmapForm = ({
  entityType,
  id,
  isOpen,
  reviewCycle,
  onAfterSubmit,
  onClose,
  tags,
}: StructureProps) => {
  const [isSearchPending, setIsSearchPending] = useState<boolean>(false)
  const [isSubmitPending, setIsSubmitPending] = useState<boolean>(false)
  const [options, setOptions] = useState<SidebarOption[]>([])

  useEffect(() => {
    if (!isOpen) {
      setOptions([])
    }
  }, [isOpen])

  const fetchOptions = useMemo(
    () =>
      debounce(async (search: string) => {
        const request = getUnassignedRoadmaps(entityType, id, search).then(
          resp => resp.data.results,
        )

        try {
          const roadmaps = await request
          setOptions(parseUnassignedRoadmaps(roadmaps))
        } finally {
          setIsSearchPending(false)
        }
      }, 1000),
    [entityType, id],
  )

  const descriptionContent = (
    <Text color="grey-tone-50" mb="s-8">{`Epics will be added to the ${
      reviewCycle?.name || ''
    } roadmap`}</Text>
  )

  return (
    <SidebarMultiselect<UnassignedRoadmapInterface>
      dataType="epics"
      description={descriptionContent}
      emptySearchMessage={chain('Showing epics', options.length)}
      emptySearchDescription={<EmptySearchDescription />}
      initialSearchDescription={<InitialSearchDescription />}
      isOpen={isOpen}
      onClose={onClose}
      onSearch={query => {
        if (query) {
          setIsSearchPending(true)
          fetchOptions(query)
        } else {
          setOptions([])
        }
      }}
      onSubmit={selectedOptions => {
        setIsSubmitPending(true)

        const keys = parseKeys(selectedOptions)
        addRoadmaps({
          entityType,
          id,
          keys,
          review_cycle: reviewCycle?.id,
          item_type: ItemType.Jira,
        })
          .then(() => {
            onAfterSubmit?.()
            onClose?.()
          })
          .finally(() => setIsSubmitPending(false))
      }}
      options={options}
      optionsPending={isSearchPending}
      submitPending={isSubmitPending}
      submitButtonTitle="Add roadmap"
      subtitle={
        entityType === EntityTypes.employee ? null : (
          <Tags tags={tags} onEditClick={() => navigateTo(getUrl(entityType, { id }))} />
        )
      }
      title="Add roadmap"
    />
  )
}
