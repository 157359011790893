import React from 'react'
import { Flex, Box } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Switch, Route } from 'react-router-dom'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'
import { HomeSectionId, Subtab } from '@src/interfaces/tabPinning'
import { usePermissionChecks } from '@components/PageWithTabs/usePermissionChecks'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { useIsNewTable } from '@components/TableV2/hooks'

interface PageWithTabsProps {
  tabs: Subtab[]
  id?: HomeSectionId | 'dashboard'
  hideNavigation?: boolean
}

export const PageWithTabs = ({ id, tabs, hideNavigation = false }: PageWithTabsProps) => {
  const { checkPermissions, isLoading } = usePermissionChecks()
  const isNewTable = useIsNewTable()

  if (isLoading) {
    return null
  }

  const filteredTabs = tabs.filter(tab => checkPermissions(tab))

  if (filteredTabs.length === 0) {
    return null
  }

  const onboardingAppCategory = (() => {
    switch (id) {
      case HomeSectionId.jobs:
        return 'jobs'
      case HomeSectionId.teams:
        return 'teams'
      case HomeSectionId.roles:
        return 'roles'
      case HomeSectionId.kpis:
        return 'goals'
      default:
        return null
    }
  })()

  const showNavigation = (!isNewTable || !hideNavigation) && filteredTabs.length > 1

  return (
    <OnboardingAppScreen category={onboardingAppCategory}>
      <Box radius="widget" pt={showNavigation ? 's-16' : 0}>
        <Flex flexDirection="column" width="100%">
          {showNavigation ? (
            <Flex alignItems="flex-start" mb="s-8" px="s-16">
              <TabBarNavigation isSubtab tabs={filteredTabs} />
            </Flex>
          ) : null}
          <Switch>
            {filteredTabs.map(tab => (
              <Route exact path={tab.path} key={tab.path}>
                <tab.component />
              </Route>
            ))}
            {filteredTabs[0].path && <InternalRedirect to={filteredTabs[0].path} />}
          </Switch>
        </Flex>
      </Box>
    </OnboardingAppScreen>
  )
}
