import React, { useEffect, useRef, useState } from 'react'
import { ActionButton, Avatar, Item, useToggle, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import {
  GrowthPlanActionContentObjectInterface,
  GrowthPlanActionContentTypeInterface,
  GrowthPlanActionInterface,
  GrowthPlanInterface,
} from '@src/interfaces/growthPlans'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'
import { ActionItem } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/ActionItems/ActionItem'
import { ConnectMetricPopup } from '@src/features/GrowthPlans/GrowthPlan/components/MetricsConnector/ConnectMetricPopup'
import { ContentTypeOption } from '@src/interfaces/selectors'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { CompetencyMatrixSkill } from '@src/interfaces/roles'

const actionsFieldName = 'actions'

export const ActionItemsWidget = () => {
  const { values } = useLapeContext<GrowthPlanInterface>()
  const actionsField = useLapeField(actionsFieldName)

  const [shouldScrollToBottom, setShouldScrollToBottom] = useState<boolean>(false)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const [isConnectMetricPopupOpen, connectMetricPopupToggler] = useToggle()
  const [selectedActionIndex, setSelectedActionIndex] = useState<number>()

  useEffect(() => {
    if (!shouldScrollToBottom || !buttonRef.current) {
      return
    }

    buttonRef.current.scrollIntoView({ behavior: 'smooth' })
    setShouldScrollToBottom(false)
  }, [shouldScrollToBottom, buttonRef.current])

  const makeChangeHandler = (index: number) => (value: string) => {
    values.actions[index].description = value
  }

  const makeDeleteHandler = (index: number) => () => {
    values.actions.splice(index, 1)

    if (index === selectedActionIndex) {
      setSelectedActionIndex(undefined)
    }
  }

  const handleAdd = () => {
    values.actions.push({ description: '' } as GrowthPlanActionInterface)
    setShouldScrollToBottom(true)
  }

  const makeConnectMetricPopupHandler = (index: number) => () => {
    setSelectedActionIndex(index)
    connectMetricPopupToggler.on()
  }

  const handleConnectMetric = (
    contentOption: ContentTypeOption<'skillset' | 'companyvalue'>,
    metric: CompetencyMatrixSkill | CultureValueInterface,
  ) => {
    if (selectedActionIndex === undefined) {
      return
    }

    values.actions[selectedActionIndex].object_id = metric.id
    values.actions[selectedActionIndex].content_type = {
      id: Number(contentOption.id),
    } as GrowthPlanActionContentTypeInterface
    values.actions[selectedActionIndex].content_object = {
      id: metric.id,
      name: metric.name,
    } as GrowthPlanActionContentObjectInterface

    connectMetricPopupToggler.off()
  }

  const makeDisconnectMetricHandler = (index: number) => () => {
    values.actions[index].object_id = null
    values.actions[index].content_type = null
    values.actions[index].content_object = null

    connectMetricPopupToggler.off()
  }

  const canAddActionItem =
    values.actions.length === 0 ||
    values.actions.every(({ description }) => !!description)

  return (
    <Widget>
      <Item style={transparentThemeBackgroundOverrides}>
        <Item.Avatar>
          <Avatar useIcon="Questionnaire" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Growth plan actions</Item.Title>
          <Item.Description>
            To-do items for employee to successfully complete growth plan
          </Item.Description>
        </Item.Content>
      </Item>
      <VStack p="s-16" space="s-16" data-name="actions" use="li">
        {values.actions.map((action, index) => (
          <ActionItem
            key={action.id}
            action={action}
            hasError={actionsField.error && !action.description}
            errorMessage={
              actionsField.error && !action.description ? 'Cannot be empty' : undefined
            }
            onChange={makeChangeHandler(index)}
            onDelete={makeDeleteHandler(index)}
            onConnectMetric={makeConnectMetricPopupHandler(index)}
            onDisconnectMetric={makeDisconnectMetricHandler(index)}
            dataName={`${actionsFieldName}.${index}.description`}
          />
        ))}
        <ActionButton
          useIcon="Plus"
          disabled={!canAddActionItem}
          onClick={handleAdd}
          ref={buttonRef}
        >
          Add to-do
        </ActionButton>
      </VStack>
      <ConnectMetricPopup
        growthPlan={values}
        isOpen={isConnectMetricPopupOpen}
        onClose={connectMetricPopupToggler.off}
        onConnect={handleConnectMetric}
      />
    </Widget>
  )
}
