import React from 'react'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { CommonActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/CommonActions'
import { MoreBar } from '@revolut/ui-kit'
import { useStartReviewCycle } from '@src/api/reviewCycles'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { DeleteCycleAction } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/DeleteCycleAction'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'

interface Props {
  cycle: ReviewCyclesInterface
}

export const PlannedCycleActions = ({ cycle }: Props) => {
  const { id, name } = cycle

  const { mutateAsync: startCycle, isLoading } = useStartReviewCycle(id)
  const permissions = useSelector(selectPermissions)
  const showStatusPopup = useShowStatusPopup()

  const handleStartCycle = async () => {
    await startCycle()
    showStatusPopup({ title: `${name} cycle is successfully started` })
  }

  const canDelete =
    permissions.includes(PermissionTypes.DeleteReviewCycles) &&
    cycleModel.isDeletable(cycle)

  return (
    <MoreBar maxCount={3} aria-label="action panel">
      <MoreBar.Action useIcon="Play" onClick={handleStartCycle} pending={isLoading}>
        Start Cycle
      </MoreBar.Action>
      <CommonActions cycle={cycle} />
      {canDelete && <DeleteCycleAction cycle={cycle} />}
    </MoreBar>
  )
}
