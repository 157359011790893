import { useState } from 'react'

import { anonymiseEmployeeData } from '@src/api/employees'

type UseAnonymiseProfileActionParams = {
  employeeId: number
  refreshData: () => void
}
export const useAnonymiseProfileAction = ({
  employeeId,
  refreshData,
}: UseAnonymiseProfileActionParams) => {
  const [pending, setPending] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)

  const onConfirm = async () => {
    try {
      setPending(true)
      await anonymiseEmployeeData(employeeId)
      refreshData()
    } finally {
      setShowConfirm(false)
      setPending(false)
    }
  }

  return {
    pending,
    showConfirm,
    setShowConfirm,
    onConfirm,
  }
}
