import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import { MoreBar, MoreBarProps } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'

const StyledMoreBar = styled(MoreBar)`
  > * {
    direction: ltr;
  }
`

export const TableMoreBar = ({
  children,
  style,
  maxCount,
  ...props
}: PropsWithChildren<MoreBarProps>) => {
  const isNewTable = useIsNewTable()
  if (!isNewTable) {
    return (
      <MoreBar style={style} maxCount={maxCount} {...props}>
        {children}
      </MoreBar>
    )
  }
  return (
    <StyledMoreBar
      maxCount={maxCount || 3}
      style={{ ...style, direction: 'rtl' }}
      {...props}
    >
      {children}
    </StyledMoreBar>
  )
}
