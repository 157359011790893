import Form from '@src/features/Form/Form'
import { docuSignPreferencesRequests } from '@src/api/integrations'
import React from 'react'
import { DocuSignForm } from '@src/pages/Hub/Integrations/DocuSign/DocuSign'

const DocuSignIntegration = () => {
  return (
    <Form api={docuSignPreferencesRequests} forceParams={{ id: '1' }}>
      <DocuSignForm />
    </Form>
  )
}

export default DocuSignIntegration
