import { HierarchyTransition } from '@components/Hierarchy/HierarchyTree'
import { css } from 'styled-components'
import { CreateLink } from '@components/Hierarchy/utils'
import { HierarchyPointLink } from 'd3-hierarchy'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { Selection, EnterElement, BaseType } from 'd3-selection'
import { Token } from '@revolut/ui-kit'

const Link = css`
  fill: none;
`

export const addHierarchyLink = (
  selection: Selection<
    EnterElement,
    HierarchyPointLink<HierarchyNodeInterface>,
    SVGGElement,
    HierarchyNodeInterface
  >,
  t: HierarchyTransition,
) => {
  selection
    .insert('path', ':first-child')
    .sort(d => {
      if (d.source.data?.isBranch && d.target.data?.isBranch) {
        return 1
      }
      return -1
    })
    .attr('stroke-width', d => {
      if (d.source.data?.isBranch && d.target.data?.isBranch) {
        return `3px`
      }
      return `1px`
    })
    .attr('class', 'Link')
    .attr('style', Link.join(''))
    .attr('d', CreateLink)
    .attr('stroke', d => {
      if (d.source.data?.isBranch && d.target.data?.isBranch) {
        return Token.color.warning
      }
      return '#ced5db'
    })
    .call(d => {
      const maxLength = d.nodes().reduce((prev, curr) => {
        const currentLength = curr?.getTotalLength() || 0
        return prev > currentLength ? prev : currentLength
      }, 0)

      return d
        .attr('stroke-dasharray', `${maxLength} ${maxLength}`)
        .attr('stroke-dashoffset', maxLength)
        .transition(t)
        .attr('stroke-dashoffset', 0)
    })
}

export const updateHierarchyLink = (
  selection: Selection<
    BaseType,
    HierarchyPointLink<HierarchyNodeInterface>,
    SVGGElement,
    HierarchyNodeInterface
  >,
  t: HierarchyTransition,
) => {
  selection
    .attr('style', Link.join(''))
    .transition(t)
    .attr('d', CreateLink)
    .attr('stroke-dasharray', `none`)
}
