import { ROUTES } from '@src/constants/routes'

export enum CXCategory {
  Complaint = 'complaint',
  TrustPilot = 'trust_pilot',
  Support = 'support',
  Csat = 'csat',
  NPS = 'nps',
}

export const CXCategoryTitles = {
  [CXCategory.Complaint]: 'Complaints',
  [CXCategory.Csat]: 'Support NPS',
  [CXCategory.Support]: 'Net Contacts',
  [CXCategory.TrustPilot]: 'TrustPilot Detractors',
  [CXCategory.NPS]: 'Product NPS',
}

export const CXTeamCategoryPaths = {
  [CXCategory.Complaint]: ROUTES.FORMS.TEAM.CX.COMPLAINTS,
  [CXCategory.Csat]: ROUTES.FORMS.TEAM.CX.CSAT_DETRACTORS,
  [CXCategory.Support]: ROUTES.FORMS.TEAM.CX.SUPPORT_LOAD,
  [CXCategory.TrustPilot]: ROUTES.FORMS.TEAM.CX.TRUSTPILOT,
  [CXCategory.NPS]: ROUTES.FORMS.TEAM.CX.NPS,
}

export const CXDepartmentCategoryPaths = {
  [CXCategory.Complaint]: ROUTES.FORMS.DEPARTMENT.CX.COMPLAINTS,
  [CXCategory.Csat]: ROUTES.FORMS.DEPARTMENT.CX.CSAT_DETRACTORS,
  [CXCategory.Support]: ROUTES.FORMS.DEPARTMENT.CX.SUPPORT_LOAD,
  [CXCategory.TrustPilot]: ROUTES.FORMS.DEPARTMENT.CX.TRUSTPILOT,
  [CXCategory.NPS]: ROUTES.FORMS.DEPARTMENT.CX.NPS,
}

export enum CXCategoryStatus {
  Good = 'good',
  Poor = 'poor',
}

export enum CXValueType {
  Numeric = 'numeric',
  Percentage = 'percentage',
}

export interface CXSummaryInterface {
  category: CXCategory
  last_period_value: number
  status: CXCategoryStatus
  trend: number
  twenty_eight_day_average: number
  two_periods_ago_value: number
  value_type: CXValueType
}

export enum SupportLoadBreakdownBy {
  Tag = 'tag',
  Category = 'category',
}
