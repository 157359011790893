import React from 'react'
import { Flex, Item, ItemTitleProps } from '@revolut/ui-kit'

type StageItemLayoutProps = {
  id: string
  avatar: React.ReactNode
  description?: React.ReactNode
  disabled?: boolean
  rating?: React.ReactNode
  recommendation?: React.ReactNode
  title: React.ReactNode
  titleProps?: ItemTitleProps
  onClick?: () => void
}

export const StageItemLayout = ({
  id,
  avatar,
  description,
  disabled = false,
  rating,
  recommendation,
  title,
  titleProps = {},
  onClick,
}: StageItemLayoutProps) => {
  return (
    <Item
      data-testid={id}
      pl="s-56"
      use={!disabled && onClick ? 'label' : undefined}
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
    >
      <Item.Avatar>{avatar}</Item.Avatar>
      <Item.Content>
        <Item.Title {...titleProps}>{title}</Item.Title>
        <Item.Description>{description}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Flex alignItems="center" justifyContent="space-between" gap="s-8">
          {recommendation}
          {rating}
        </Flex>
      </Item.Side>
    </Item>
  )
}
