import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { API } from '@src/constants/api'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { LeadershipPositionInterface, LeadershipStats } from '@src/interfaces/leadership'

const getBaseUrl = (departmentId: number) => {
  return `${API.DEPARTMENT}/${departmentId}${API.LEADERSHIP_POSITIONS}`
}

export const getLeadershipRequestsNew = (
  departmentId: number,
): RequestInterfaceNew<LeadershipPositionInterface> => ({
  get: async ({ id }) => api.get(`${getBaseUrl(departmentId)}/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${getBaseUrl(departmentId)}/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${getBaseUrl(departmentId)}`, data),
  delete: async ({ id }) => api.delete(`${getBaseUrl(departmentId)}/${id}`),
})

export const getDepartmentLeadershipPositions = (
  departmentId: number,
): RequestInterface<LeadershipPositionInterface>['getItems'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get<GetRequestInterface<LeadershipPositionInterface>>(getBaseUrl(departmentId), {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
}

export const getDepartmentLeadershipStats = (
  departmentId: number,
): RequestInterface<LeadershipPositionInterface, LeadershipStats>['getStats'] => {
  return ({ sortBy, filters, page }: FetchDataQueryInterface) =>
    api.get(`${getBaseUrl(departmentId)}/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
}
