import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import {
  onboardingContractsEndDateColumn,
  onboardingContractsStartDateColumn,
  onboardingContractsCompanyEntityColumn,
  onboardingContractsContractTermColumn,
  onboardingContractsContractTypeColumn,
  onboardingContractsEmailColumn,
  onboardingContractsFullTimeEquivalentColumn,
  onboardingContractsLocationColumn,
  onboardingContractsNoticePeriodAfterProbationColumn,
  onboardingContractsNoticePeriodAfterProbationUnitColumn,
  onboardingContractsNoticePeriodDuringProbationColumn,
  onboardingContractsNoticePeriodDuringProbationUnitColumn,
  onboardingContractsSalaryAmountColumn,
  onboardingContractsSalaryCurrencyColumn,
  onboardingContractsSalaryPaymentFrequencyColumn,
  onboardingContractsSeniorityColumn,
  onboardingContractsSpecialisationColumn,
  onboardingContractsSpecialisationSenioritySublevelColumn,
  onboardingContractsStatusColumn,
  onboardingContractsTitleColumn,
  onboardingContractsWeeklyWorkingHoursColumn,
  onboardingContractsSalaryTimeUnitColumn,
  onboardingContractsContractPositionColumn,
} from '@src/constants/columns/contractsV2'

export const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportContractsDataInterface>> => ({
  cells: [
    {
      ...onboardingContractsEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsContractTypeColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsContractTermColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsContractPositionColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsStartDateColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsEndDateColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsLocationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsCompanyEntityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryAmountColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryCurrencyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryTimeUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSalaryPaymentFrequencyColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSpecialisationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSeniorityColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsSpecialisationSenioritySublevelColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsTitleColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsWeeklyWorkingHoursColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsFullTimeEquivalentColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodDuringProbationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodDuringProbationUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodAfterProbationColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsNoticePeriodAfterProbationUnitColumn(options.onChange),
      width: 200,
    },
    {
      ...onboardingContractsStatusColumn(options.onChange),
      width: 200,
    },
  ],
})

export const mandatoryBulkSessionFields = [
  'email',
  'contract_type',
  'entity',
  'salary_amount',
  'salary_currency',
  'salary_time_unit',
  'salary_payment_frequency',
]
export const optionalBulkSessionFields = [
  'contract_term',
  'contract_start_date',
  'contract_end_date',
  'position',
  'location',
  'specialisation',
  'seniority',
  'subseniority',
  'notice_period_during_probation',
  'notice_period_during_probation_unit',
  'notice_period_after_probation',
  'notice_period_after_probation_unit',
  'weekly_working_hours',
  'full_time_equivalent',
  'contract_status',
  'job_title',
]

export const bulkFieldsDependencies = {
  contract_type: ['contract_type', 'contract_term'],
  contract_term: ['contract_type', 'contract_term'],
  specialisation: ['specialisation', 'seniority'],
  seniority: ['specialisation', 'seniority'],
  location: ['location'],
}
