import React, { useState } from 'react'
import { Box, Button, MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  subscriptionInvoicesDateColumn,
  subscriptionInvoicesOrderNumberColumn,
  subscriptionInvoicesPeriodColumn,
  subscriptionInvoicesStatusColumn,
  subscriptionInvoicesTotalPriceColumn,
} from '@src/constants/columns/plans'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@src/components/TableV2/hooks'
import { tenantInvoicesRequests } from '@src/api/tenants'
import { accountInvoiceActionColumn } from '@src/constants/columns/settings'
import { createMissingSubscriptionInvoice } from '@src/api/plans'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

export const Invoices = () => {
  const params = useParams<{ id: string }>()

  const [createMissingInvoicePending, setCreateMissingInvoicePending] = useState(false)

  const statusPopup = useStatusPopup()

  const table = useTable(
    tenantInvoicesRequests(params.id),
    undefined,
    subscriptionInvoicesDateColumn.sortKey
      ? [
          {
            sortBy: subscriptionInvoicesDateColumn.sortKey,
            direction: SORT_DIRECTION.ASC,
          },
        ]
      : undefined,
  )

  const row: RowInterface<SubscriptionInvoiceInterface> = {
    linkToForm: data =>
      navigateTo(
        pathToUrl(ROUTES.SETTINGS.ACCOUNTS.INVOICE, {
          id: params.id,
          invoiceId: data.id,
        }),
      ),
    cells: [
      {
        ...subscriptionInvoicesPeriodColumn,
        width: 150,
      },
      {
        ...subscriptionInvoicesDateColumn,
        width: 100,
      },
      {
        ...subscriptionInvoicesOrderNumberColumn,
        width: 400,
      },
      {
        ...subscriptionInvoicesStatusColumn,
        width: 100,
      },
      {
        ...subscriptionInvoicesTotalPriceColumn,
        width: 100,
      },
      {
        ...accountInvoiceActionColumn(params.id),
        width: 100,
      },
    ],
  }

  const onCreateMissingInvoice = () => {
    setCreateMissingInvoicePending(true)

    createMissingSubscriptionInvoice(params.id)
      .then(response => {
        if (response.status === 201) {
          statusPopup.show(
            <StatusPopup variant="success">
              <StatusPopup.Title>Success</StatusPopup.Title>
            </StatusPopup>,
          )
        } else {
          statusPopup.show(
            <StatusPopup variant="warning">
              <StatusPopup.Title>
                Invoice wasn't created. There are no missing invoices
              </StatusPopup.Title>
              <StatusPopup.Actions>
                <Button onClick={statusPopup.hide} variant="secondary">
                  Close
                </Button>
              </StatusPopup.Actions>
            </StatusPopup>,
          )
        }

        table.refresh()
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to create an invoice</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
      .finally(() => {
        setCreateMissingInvoicePending(false)
      })
  }

  return (
    <Box mt="s-16">
      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              onClick={onCreateMissingInvoice}
              pending={createMissingInvoicePending}
            >
              Create missing invoice
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable
            name={TableNames.AccountInvoices}
            {...table}
            row={row}
            useWindowScroll
            dataType="Invoice"
            emptyState={<EmptyTableRaw title="All invoices will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </Box>
  )
}
