import React from 'react'
import { SelectOptionItemType } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { selectorKeys } from '@src/constants/api'
import { ImportCell } from '@src/constants/columns/bulkDataImport'
import { ImportContractsDataInterface } from '@src/interfaces/importContracts'
import { ContractTypes } from '@src/interfaces/employees'
import { IdAndName } from '@src/interfaces'

export type ImportContractsCellOptions = {
  contractTypeOptions: SelectOptionItemType<IdAndName<ContractTypes>>[]
  contractTermOptions: SelectOptionItemType<IdAndName<string>>[]
  salaryTimeUnitOptions: SelectOptionItemType<IdAndName<string>>[]
  salaryPaymentFrequencyOptions: SelectOptionItemType<IdAndName<string>>[]
  signOnBonusTypeOptions: SelectOptionItemType<IdAndName<string>>[]
  noticePeriodTimeUnitOptions: SelectOptionItemType<IdAndName<string>>[]
  contractStatusOptions: SelectOptionItemType<IdAndName<string>>[]
}

export const importContractsEmailColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => <ImportCell field="email" data={data} />,
}

export const importContractsContractTypeColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'contract_type',
  dataPoint: 'contract_type',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract type',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="contract_type"
      data={data}
      options={options.contractTypeOptions}
    />
  ),
})

export const importContractsContractTermColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'contract_term',
  dataPoint: 'contract_term',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Contract term',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="contract_term"
      data={data}
      options={options.contractTermOptions}
    />
  ),
})

export const importContractsStartDateColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'contract_start_date',
  dataPoint: 'contract_start_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Start date',
  insert: ({ data }) => (
    <ImportCell type="date-no-timezone" field="contract_start_date" data={data} />
  ),
}

export const importContractsEndDateColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'contract_end_date',
  dataPoint: 'contract_end_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'End date',
  insert: ({ data }) => (
    <ImportCell type="date-no-timezone" field="contract_end_date" data={data} />
  ),
}

export const importContractsLocationColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'location',
  dataPoint: 'location',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Location',
  insert: ({ data }) => <ImportCell field="location" data={data} />,
}

export const importContractsCompanyEntityColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'entity',
  dataPoint: 'entity',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Company entity',
  insert: ({ data }) => <ImportCell field="entity" data={data} />,
}

export const importContractsSalaryAmountColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'salary_amount',
  dataPoint: 'salary_amount',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary amount',
  insert: ({ data }) => <ImportCell field="salary_amount" data={data} />,
}

export const importContractsSalaryCurrencyColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'salary_currency',
  dataPoint: 'salary_currency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary currency',
  insert: ({ data }) => <ImportCell field="salary_currency" data={data} />,
}

export const importContractsSalaryTimeUnitColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'salary_time_unit',
  dataPoint: 'salary_time_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary time unit',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="salary_time_unit"
      data={data}
      options={options.salaryTimeUnitOptions}
    />
  ),
})

export const importContractsSalaryPaymentFrequencyColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'salary_payment_frequency',
  dataPoint: 'salary_payment_frequency',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Salary payment frequency',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="salary_payment_frequency"
      data={data}
      options={options.salaryPaymentFrequencyOptions}
    />
  ),
})

export const importContractsSpecialisationColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'specialisation',
  dataPoint: 'specialisation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Specialisation',
  insert: ({ data }) => <ImportCell field="specialisation" data={data} />,
}

export const importContractsSeniorityColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'seniority',
  dataPoint: 'seniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority',
  insert: ({ data }) => <ImportCell field="seniority" data={data} />,
}

export const importContractsSpecialisationSenioritySublevelColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'subseniority',
  dataPoint: 'subseniority',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Seniority sublevel',
  insert: ({ data }) => <ImportCell field="subseniority" data={data} />,
}

export const importContractsTitleColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'job_title',
  dataPoint: 'job_title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Title',
  insert: ({ data }) => <ImportCell field="job_title" data={data} />,
}

export const importContractsWeeklyWorkingHoursColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'weekly_working_hours',
  dataPoint: 'weekly_working_hours',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Weekly working hours',
  insert: ({ data }) => <ImportCell field="weekly_working_hours" data={data} />,
}

export const importContractsFullTimeEquivalentColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'full_time_equivalent',
  dataPoint: 'full_time_equivalent',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Full time equivalent',
  insert: ({ data }) => <ImportCell field="full_time_equivalent" data={data} />,
}

export const importContractsNoticePeriodDuringProbationColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'notice_period_during_probation',
  dataPoint: 'notice_period_during_probation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period during probation',
  insert: ({ data }) => <ImportCell field="notice_period_during_probation" data={data} />,
}

export const importContractsNoticePeriodDuringProbationUnitColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'notice_period_during_probation_unit',
  dataPoint: 'notice_period_during_probation_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period during probation unit',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="notice_period_during_probation_unit"
      data={data}
      options={options.noticePeriodTimeUnitOptions}
    />
  ),
})

export const importContractsNoticePeriodAfterProbationColumn: ColumnInterface<
  ImportInterface<ImportContractsDataInterface>
> = {
  type: CellTypes.insert,
  idPoint: 'notice_period_after_probation',
  dataPoint: 'notice_period_after_probation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period after probation',
  insert: ({ data }) => <ImportCell field="notice_period_after_probation" data={data} />,
}

export const importContractsNoticePeriodAfterProbationUnitColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'notice_period_after_probation_unit',
  dataPoint: 'notice_period_after_probation_unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Notice period after probation unit',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="notice_period_after_probation_unit"
      data={data}
      options={options.noticePeriodTimeUnitOptions}
    />
  ),
})

export const importContractsStatusColumn = (
  options: ImportContractsCellOptions,
): ColumnInterface<ImportInterface<ImportContractsDataInterface>> => ({
  type: CellTypes.insert,
  idPoint: 'contract_status',
  dataPoint: 'contract_status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => (
    <ImportCell
      type="option"
      field="contract_status"
      data={data}
      options={options.contractStatusOptions}
    />
  ),
})
