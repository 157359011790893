import React from 'react'
import styled from 'styled-components'
import Tooltip from '../../Tooltip/Tooltip'
import { RiskStatsInterface } from '@src/interfaces/risk'
import { chain, Token } from '@revolut/ui-kit'
import { TooltipContainer } from '@components/CommonSC/Tooltip'
import QuickSummaryCount from '@components/QuickSummary/QuickSummaryCount'
import { useIsNewTable } from '@components/TableV2/hooks'

const ContainerTooltip = styled(Tooltip)`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

interface DataInterface {
  kris_above_level_1?: number
  kris_above_level_2?: number
  kris_above_level_3?: number
}

interface Props {
  data: DataInterface
}

export const statsToKri = (stats?: RiskStatsInterface) =>
  stats
    ? {
        kris_above_level_1: stats.low,
        kris_above_level_2: stats.medium,
        kris_above_level_3: stats.high,
      }
    : {}

const RiskCircles = ({ data }: Props) => {
  const isNewTable = useIsNewTable()

  if (isNewTable) {
    return (
      <>
        {(data.kris_above_level_1 || 0) +
          (data.kris_above_level_2 || 0) +
          (data.kris_above_level_3 || 0)}
      </>
    )
  }

  if (
    data.kris_above_level_1 === 0 &&
    data.kris_above_level_2 === 0 &&
    data.kris_above_level_3 === 0
  ) {
    return <ColoredText color={Token.color.greyTone20}>0</ColoredText>
  }

  return (
    <ContainerTooltip
      body={
        <TooltipContainer>
          <b>Red</b> - Can have serious financial, reputational or regulatory
          implications. Discussed by the Executive Risk Committee and/or Board.
          <br />
          <br />
          <b>Orange</b> - Discussed by Executive Risk Committee or one of its
          sub-committees.
        </TooltipContainer>
      }
      placement="bottom"
    >
      {chain(
        data.kris_above_level_3 ? (
          <QuickSummaryCount color={Token.color.red} count={data.kris_above_level_3} />
        ) : undefined,
        data.kris_above_level_2 ? (
          <QuickSummaryCount
            color={Token.color.warning}
            count={data.kris_above_level_2}
          />
        ) : undefined,
        data.kris_above_level_1 ? (
          <QuickSummaryCount color={Token.color.green} count={data.kris_above_level_1} />
        ) : undefined,
      )}
    </ContainerTooltip>
  )
}

export default RiskCircles
