import React from 'react'

import Table from '@components/TableV2/Table'
import { getResponsibilityAssignmentStatusColor } from '@src/apps/People/KeyPersons/helpers'
import { Grid } from '@src/components/CommonSC/Grid'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'

export const keyPersonEmployeeResponsibilitiesNameColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'key_person_type.id',
    dataPoint: 'key_person_type.name',
    sortKey: 'key_person_type',
    filterKey: 'key_person_type',
    selectorsKey: selectorKeys.key_risk_person_types,
    title: 'Responsibility',
  }

export const keyPersonEmployeeResponsibilitiesEntityColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'entity.id',
    dataPoint: 'entity.name',
    sortKey: 'entity__name',
    filterKey: 'entity__id',
    selectorsKey: selectorKeys.entity,
    title: 'Legal entity',
  }

export const keyPersonEmployeeResponsibilitiesStartDateColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.date,
    idPoint: 'start_date',
    dataPoint: 'start_date',
    sortKey: 'start_date',
    filterKey: 'start_date',
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const keyPersonEmployeeResponsibilitiesEndDateColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.date,
    idPoint: 'end_date',
    dataPoint: 'end_date',
    sortKey: 'end_date',
    filterKey: 'end_date',
    selectorsKey: selectorKeys.none,
    title: 'End date',
  }

export const keyPersonEmployeeResponsibilitiesSuccessorsColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'successors',
    dataPoint: 'successors',
    sortKey: 'successors',
    filterKey: 'successors',
    selectorsKey: selectorKeys.employee,
    title: 'Successor(s)',
    insert: ({ data }) => {
      return (
        <Grid flow="column" gap={10} justifyContent="start">
          {data?.successors?.map(successor => (
            <UserWithAvatar
              key={successor.id}
              id={successor.id}
              name={successor.full_name}
              avatar={successor.avatar}
            />
          ))}
        </Grid>
      )
    },
  }

export const keyPersonEmployeeResponsibilitiesStatusColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    colors: data => getResponsibilityAssignmentStatusColor(data.assignment_status?.id),
    idPoint: 'assignment_status.id',
    dataPoint: 'assignment_status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.key_risk_profile_statuses,
    title: 'Status',
    insert: ({ data }) => {
      let variant: ColorTagVariant

      switch (data.assignment_status?.id) {
        case 'active':
          variant = 'success'
          break
        case 'pending_start':
          variant = 'warning'
          break
        case 'expired':
        case 'unassigned':
          variant = 'danger'
          break
        case 'archived':
          variant = 'neutral'
          break
        default:
          variant = 'default'
          break
      }

      return data.assignment_status ? (
        <Table.StatusCell status={data.assignment_status.name} variant={variant} />
      ) : (
        '-'
      )
    },
  }

export const keyPersonEmployeeResponsibilitiesRoleTypeColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.text,
    idPoint: 'key_person_type.role_type.id',
    dataPoint: 'key_person_type.role_type.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Role type',
  }

export const keyPersonEmployeeResponsibilitiesActionsColumn: ColumnInterface<KeyPersonResponsibilityAssignmentInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'id',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
