import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { locationsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { LocationsIntro } from '@src/pages/OnboardingChecklist/Locations/LocationsIntro'
import LocationsLegal from '@src/pages/OnboardingChecklist/Locations/LocationsLegal'
import LocationsLocations from '@src/pages/OnboardingChecklist/Locations/LocationsLocations'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.INTRO,
    canView: [PermissionTypes.ViewOrganisationPreferences], // TODO: not sure what permission to use
    component: LocationsIntro,
  },
  {
    title: 'Legal Entities',
    path: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.LEGAL,
    url: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.LEGAL,
    canView: [PermissionTypes.ViewOrganisationPreferences], // TODO: not sure what permission to use
    component: LocationsLegal,
    isWide: true,
  },
  {
    title: 'Locations',
    path: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.LOCATIONS,
    url: ROUTES.ONBOARDING_CHECKLIST.LOCATIONS.LOCATIONS,
    canView: [PermissionTypes.ViewOrganisationPreferences], // TODO: not sure what permission to use
    component: LocationsLocations,
    isWide: true,
  },
]

const Locations = () => {
  return <OnboardingChecklistContent config={locationsConfig} routes={routes} />
}

export default Locations
