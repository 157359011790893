import React, { useEffect, useState } from 'react'
import {
  InputGroup,
  Button,
  Input,
  Box,
  Group,
  Subheader,
  StatusPopup,
  MoreBar,
  Text,
  VStack,
  Token,
  ActionButton,
} from '@revolut/ui-kit'
import { Route, Switch, useParams } from 'react-router-dom'
import { useLape } from 'lape'
import styled from 'styled-components'
import diff from 'lodash/difference'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { BenefitPackage, BenefitsInterface } from '@src/interfaces/benefits'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { selectorKeys } from '@src/constants/api'
import NewMultiSelect from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { deleteBenefitPackage, updateBenefitPackage } from '@src/api/benefits'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  EmployeeContributionPreviewItem,
  PackageNotFoundPage,
  EmployerContributionPreviewItem,
  DependantsNumberPreviewItem,
  AdditionalInfoDocuments,
} from './common'
import FileUploader from '@src/components/Inputs/FileUploader/FileUploader'
import UploadedFile from '@src/components/Inputs/FileUploader/UploadedFile'
import { FileGroup } from '@src/components/Inputs/FileUploader/FileGroup'
import { PermissionTypes } from '@src/store/auth/types'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import SettingsButtons from '@src/features/SettingsButtons'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { YesOrNoRadioSelect } from '@components/Inputs/YesNoRadioSelect/YesNoRadioSelect'
import { useGetEmployeeSettings } from '@src/api/settings'
import pluralize from 'pluralize'

const StyledSubheader = styled(Subheader)`
  margin-top: ${Token.space.s16};
`

interface CommonProps {
  data: BenefitPackage
}

const relationshipKey = 'relationship'

const PackageForm = ({ data }: CommonProps) => {
  const { values } = useLapeContext<BenefitsInterface>()

  const { data: settings } = useGetEmployeeSettings()

  useEffect(() => {
    if (data.dependants_allowed && data.dependant_global_fields?.length === 0) {
      data.dependant_global_fields = [
        { id: 'first_name', name: 'First name' },
        { id: 'last_name', name: 'Last name' },
        { id: relationshipKey, name: 'Relationship' },
      ]
    }
    if (!data.dependants_allowed && data.dependant_global_fields?.length > 0) {
      data.dependant_global_fields = []
    }
  }, [data.dependants_allowed])

  const isPercentOfQualifyingEarnings =
    values.contribution_method?.id === 'percentage_of_qualifying_earnings'
  const isPercentOfSalary = values.contribution_method?.id === 'percentage_of_salary'
  const isOtherType = values.contribution_method?.id === 'other'

  const currencyField = isOtherType ? null : (
    <Box flex="1">
      <Input
        value={
          isPercentOfSalary || isPercentOfQualifyingEarnings
            ? '%'
            : values.contribution_currency?.iso_code
        }
        disabled
      />
    </Box>
  )

  const relationshipIncluded = data.dependant_global_fields?.some(
    option => option.id === relationshipKey,
  )

  return (
    <>
      <InputGroup>
        <Input
          label="Package name"
          value={data.name}
          onChange={e => {
            data.name = e.currentTarget.value
          }}
        />
        <Input
          label="Package cover"
          value={data.cover}
          onChange={e => {
            data.cover = e.currentTarget.value
          }}
        />
        {values.employee_can_edit_contribution ? (
          <>
            <InputGroup variant="horizontal">
              <Box flex="3">
                <Input
                  label="Employee contribution - minimum value"
                  value={data.employee_min_contribution || ''}
                  onChange={e => {
                    data.employee_min_contribution = e.currentTarget.value
                  }}
                />
              </Box>
              {currencyField}
            </InputGroup>
            <InputGroup variant="horizontal">
              <Box flex="3">
                <Input
                  label="Employee contribution - maximum value"
                  value={data.employee_max_contribution || ''}
                  onChange={e => {
                    data.employee_max_contribution = e.currentTarget.value
                  }}
                />
              </Box>
              {currencyField}
            </InputGroup>
          </>
        ) : null}
        {!values.employee_can_edit_contribution ? (
          <InputGroup variant="horizontal">
            <Box flex="3">
              <Input
                label="Employee contribution (optional)"
                value={data.employee_contribution || ''}
                onChange={e => {
                  data.employee_contribution = e.currentTarget.value
                }}
              />
            </Box>
            {currencyField}
          </InputGroup>
        ) : null}
        <InputGroup variant="horizontal">
          <Box flex="3">
            <Input
              label="Employer contribution"
              value={data.employer_contribution}
              onChange={e => {
                data.employer_contribution = e.currentTarget.value
              }}
            />
          </Box>
          {currencyField}
        </InputGroup>
        <RadioSelectInput
          label="Enrolment method"
          selector={selectorKeys.benefits_package_enrolment_methods}
          onChange={option => {
            data.enrolment_method = option
          }}
          value={data.enrolment_method}
        />
        {data.enrolment_method?.id === 'automatic' && (
          <VStack mb="s-6">
            <InputGroup variant="horizontal">
              <Box flex="3">
                <Input
                  label="Opting out timeframe"
                  type="number"
                  value={data.days_automatic_enrolment || ''}
                  onChange={e => {
                    data.days_automatic_enrolment = e.currentTarget.value
                  }}
                />
              </Box>
              <Box flex="1">
                <Input value="Days" label="Time unit" disabled />
              </Box>
            </InputGroup>
            <Box ml="s-16" mt="s-4">
              <Text variant="small" color="gray">
                Specify what is the timeframe for employee to opt out, before the benefit
                automatically turns to ‘Active’.
              </Text>
            </Box>
          </VStack>
        )}
        <YesOrNoRadioSelect
          label="Dependants allowed"
          onChange={option => {
            if (option !== null) {
              data.dependants_allowed = option
            }
          }}
          value={data.dependants_allowed}
        />
      </InputGroup>

      {data.dependants_allowed ? (
        <>
          <StyledSubheader variant="nested">
            <Subheader.Title>Dependants enrolment settings</Subheader.Title>
          </StyledSubheader>
          <InputGroup>
            <InputGroup variant="horizontal">
              <Input
                label="Min. dependants"
                value={data.min_dependants || ''}
                onChange={e => {
                  data.min_dependants = e.currentTarget.value
                }}
              />
              <Input
                label="Max. dependants"
                value={data.max_dependants || ''}
                onChange={e => {
                  data.max_dependants = e.currentTarget.value
                }}
              />
            </InputGroup>
            <NewMultiSelect
              placeholder="Global fields"
              selector={selectorKeys.global_fields}
              value={data.dependant_global_fields.map(value => ({
                value,
                label: value.name,
              }))}
              onChange={options => {
                data.dependant_global_fields = options.map(option => option.value)

                if (
                  data.dependant_global_fields.filter(
                    option => option.id === relationshipKey,
                  ).length === 0
                ) {
                  data.restricted_relationship = false
                  data.types_of_relationship = []
                }
              }}
            />
            {!!settings?.enable_custom_fields && (
              <NewMultiSelect
                placeholder="Custom fields"
                selector={selectorKeys.benefits_custom_fields}
                value={data.dependant_custom_fields.map(value => ({
                  value,
                  label: value.name,
                }))}
                onChange={options => {
                  data.dependant_custom_fields = options.map(option => option.value)
                }}
              />
            )}
            {relationshipIncluded ? (
              <YesOrNoRadioSelect
                label="Restricted dependant type (relationship)"
                onChange={option => {
                  if (option !== null) {
                    data.restricted_relationship = option
                  }
                }}
                value={data.restricted_relationship}
              />
            ) : null}
            {data.restricted_relationship && relationshipIncluded ? (
              <NewMultiSelect
                placeholder="Allowed relationship types"
                selector={selectorKeys.benefits_package_relationship_types}
                value={data.types_of_relationship.map(value => ({
                  value,
                  label: value.name,
                }))}
                onChange={options => {
                  data.types_of_relationship = options.map(option => option.value)
                }}
              />
            ) : null}
          </InputGroup>
        </>
      ) : null}

      <StyledSubheader variant="nested">
        <Subheader.Title>Additional information</Subheader.Title>
      </StyledSubheader>
      <InputGroup>
        <FileUploader
          value={data.files}
          onChange={event => {
            if (Array.isArray(event)) {
              data.files = event
            }
            if (event == null) {
              data.files = []
            }
          }}
          multiple
        />
        {data.additional_information?.length > 0 ? (
          <FileGroup>
            {data.additional_information.map(document => (
              <UploadedFile
                title={document.name}
                onDelete={() => {
                  data.additional_information = data.additional_information.filter(
                    doc => doc.id !== document.id,
                  )
                }}
                size={document.size}
                key={document.id}
              />
            ))}
          </FileGroup>
        ) : null}
      </InputGroup>
    </>
  )
}

export const NewPackage = () => {
  const { values, initialValues, reset } = useLapeContext<BenefitsInterface>()
  const { query } = useQuery()
  const packageIndex = query.packageIndex ? +query.packageIndex : undefined

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [newPackageId, setNewPackageId] = useState<number>()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const isModifyingExisting = packageIndex != null && values.packages[packageIndex]

  const formData = useLape<BenefitPackage>(
    isModifyingExisting
      ? { ...values.packages[packageIndex] }
      : {
          additional_information: [],
          cover: '',
          dependant_custom_fields: [],
          dependant_global_fields: [],
          enrolment_method: null,
          dependants_allowed: null,
          employee_contribution: null,
          employer_contribution: '',
          max_dependants: null,
          min_dependants: null,
          employee_min_contribution: null,
          employee_max_contribution: null,
          types_of_relationship: [],
          restricted_relationship: false,
          name: '',
          files: [],
          days_automatic_enrolment: null,
        },
  )

  const backUrl = pathToUrl(ROUTES.FORMS.BENEFITS.GENERAL, { id: values.id })

  const onSubmitForNewBenefit = () => {
    if (isModifyingExisting) {
      values.packages[packageIndex] = formData
    } else {
      values.packages.push(formData)
    }
    goBack(backUrl)
  }

  const onSubmitForExistingBenefit = () => {
    setIsSubmitting(true)

    updateBenefitPackage({
      ...initialValues,
      packages: [...initialValues.packages!, formData],
    } as BenefitsInterface)
      .then(response => {
        setSuccessPopupOpen(true)
        setNewPackageId(
          diff(
            response.data.packages.map(p => p.id),
            initialValues.packages!.map(p => p.id),
          )[0],
        )
        reset(response.data)
        formData.files = []
      })
      .finally(() => setIsSubmitting(false))
  }

  const onDelete = () => {
    values.packages = values.packages.filter((_, index) => index !== packageIndex)
    goBack(backUrl)
  }

  return (
    <>
      <PageWrapper>
        <PageHeader pb="s-8" title="Add package" backUrl={backUrl} />

        {isModifyingExisting ? (
          <ActionButton onClick={onDelete} mb="s-24" variant="negative" useIcon="Delete">
            Delete
          </ActionButton>
        ) : null}

        <PageBody>
          <PackageForm data={formData} />
        </PageBody>

        <PageActions>
          {values.id ? (
            <Button onClick={onSubmitForExistingBenefit} pending={isSubmitting}>
              Add package
            </Button>
          ) : (
            <Button onClick={onSubmitForNewBenefit}>Add package</Button>
          )}
        </PageActions>
      </PageWrapper>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)

          if (newPackageId) {
            navigateReplace(
              pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_PREVIEW, {
                id: values.id,
                packageId: newPackageId,
              }),
            )
          }
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Package added</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

const PackagePreview = ({ data }: CommonProps) => {
  const { values } = useLapeContext<BenefitsInterface>()

  const { data: settings } = useGetEmployeeSettings()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const relationshipIncluded = data.dependant_global_fields?.some(
    option => option.id === relationshipKey,
  )

  return (
    <PageBody>
      <FormPreview data={data}>
        <Group>
          <FormPreview.Item title="Package name" field="name" />
          <FormPreview.Item title="Package cover" field="cover" />
          <EmployeeContributionPreviewItem benefit={values} benefitPackage={data} />
          <EmployerContributionPreviewItem benefit={values} benefitPackage={data} />
          <FormPreview.Item title="Enrolment method" field="enrolment_method.name" />
          {data?.enrolment_method?.id === 'automatic' && (
            <FormPreview.Item
              title="Timeframe to opt out"
              insert={() => pluralize('day', Number(data.days_automatic_enrolment), true)}
            />
          )}
          <FormPreview.Item
            title="Dependants allowed"
            field="dependants_allowed"
            type="boolean"
          />
        </Group>
      </FormPreview>

      {data.dependants_allowed ? (
        <Box mt="s-16">
          <FormPreview title="Dependants' enrolment settings" data={data}>
            <Group>
              <DependantsNumberPreviewItem benefitPackage={data} />
              <FormPreview.Item<BenefitPackage>
                title="Global fields"
                insert={benefitPackage => (
                  <>
                    {benefitPackage.dependant_global_fields.map(p => (
                      <React.Fragment key={p.id}>
                        {p.name}
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                )}
              />
              <FormPreview.Item<BenefitPackage>
                title="Custom fields"
                insert={benefitPackage => (
                  <>
                    {benefitPackage.dependant_custom_fields.map(p => (
                      <React.Fragment key={p.id}>
                        {p.name}
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                )}
                hidden={!settings?.enable_custom_fields}
              />
              <FormPreview.Item
                title="Restricted dependant type (relationship)"
                field="restricted_relationship"
                type="boolean"
              />
              {relationshipIncluded && data.restricted_relationship ? (
                <FormPreview.Item<BenefitPackage>
                  title="Allowed relationship types"
                  insert={benefitPackage => (
                    <>
                      {benefitPackage.types_of_relationship.map(p => (
                        <React.Fragment key={p.id}>
                          {p.name}
                          <br />
                        </React.Fragment>
                      ))}
                    </>
                  )}
                />
              ) : null}
            </Group>
          </FormPreview>
        </Box>
      ) : null}

      {data.additional_information?.length > 0 ? (
        <Box mt="s-16">
          <FormPreview title="Additional information">
            <AdditionalInfoDocuments benefitPackage={data} />
          </FormPreview>
        </Box>
      ) : null}
    </PageBody>
  )
}

const PackageEdit = (props: CommonProps) => {
  const formData = useLape({ ...props.data })
  const { initialValues, reset } = useLapeContext<BenefitsInterface>()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)

  const onSaveChanges = () => {
    setIsSubmitting(true)

    updateBenefitPackage({
      ...initialValues,
      packages: initialValues.packages!.map(p => (p.id === formData.id ? formData : p)),
    } as BenefitsInterface)
      .then(response => {
        setSuccessPopupOpen(true)
        reset(response.data)
        formData.files = []
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <>
      <PageBody>
        <PackageForm data={formData} />
      </PageBody>

      <PageActions>
        <Button onClick={onSaveChanges} pending={isSubmitting}>
          Save changes
        </Button>
      </PageActions>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          navigateReplace(
            pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_PREVIEW, {
              id: initialValues.id,
              packageId: formData.id,
            }),
          )
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>Package updated</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

interface PackageRouteParams {
  id?: string
  packageId?: string
  packageTab?: 'preview' | 'edit'
}

export const Package = () => {
  const { values, initialValues, reset } = useLapeContext<BenefitsInterface>()
  const params = useParams<PackageRouteParams>()

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const backUrl = pathToUrl(ROUTES.FORMS.BENEFITS.GENERAL, { id: values.id })

  const benefitPackage = values.packages.find(p => p.id === +params.packageId!)
  const canChange = values.field_options?.permissions?.includes(
    PermissionTypes.ChangeBenefitsTemplate,
  )

  if (!benefitPackage) {
    return <PackageNotFoundPage backUrl={backUrl} />
  }

  const onDeletePackage = () => {
    if (params.packageId) {
      setDeletePending(true)
      deleteBenefitPackage(+params.packageId, initialValues)
        .then(response => {
          reset(response.data)
          goBack(backUrl)
        })
        .catch(() => setDeletePending(false))
    }
  }

  return (
    <>
      <PageWrapper>
        <PageHeader pb="s-8" title={benefitPackage.name} backUrl={backUrl} />

        <Switch>
          <Route exact path={ROUTES.FORMS.BENEFITS.PACKAGE_PREVIEW}>
            {canChange ? (
              <SettingsButtons mb="s-32">
                <EditButton
                  route={pathToUrl(ROUTES.FORMS.BENEFITS.PACKAGE_EDIT, params)}
                />
                <MoreBar.Action
                  onClick={() => setDeleteConfirmationOpen(true)}
                  variant="negative"
                  useIcon="Delete"
                >
                  Delete
                </MoreBar.Action>
              </SettingsButtons>
            ) : null}
            <PackagePreview data={benefitPackage} />
          </Route>
          <Route exact path={ROUTES.FORMS.BENEFITS.PACKAGE_EDIT}>
            <PackageEdit data={benefitPackage} />
          </Route>
        </Switch>
      </PageWrapper>

      <ConfirmationDialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onConfirm={onDeletePackage}
        loading={deletePending}
        onReject={() => setDeleteConfirmationOpen(false)}
        yesMessage="Delete"
        noMessage="Cancel"
        body="Are you sure you want to delete this package?"
      />
    </>
  )
}
