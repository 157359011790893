import React from 'react'
import { IndeedPosting } from '@src/interfaces/jobPosting'
import { HStack, Icon, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { upperFirst } from 'lodash'
import {
  isRejected,
  isPending,
  isPublished,
} from '@src/pages/Forms/JobPosting/IndeedPostings/components/utils'

interface IndeedStatusProps {
  data: IndeedPosting
}

export const IndeedStatus = ({ data }: IndeedStatusProps) => {
  const tooltip = useTooltip()
  let descriptionColor = Token.color.greyTone50
  if (isRejected(data)) {
    descriptionColor = Token.color.red
  }
  if (isPending(data)) {
    descriptionColor = Token.color.orange
  }
  if (isPublished(data)) {
    descriptionColor = Token.color.green
  }
  return (
    <HStack align="center" gap="s-8">
      <Text color={descriptionColor}>{upperFirst(data.status)}</Text>
      {isPending(data) && (
        <>
          <Icon name="InfoOutline" size={16} {...tooltip.getAnchorProps()} />
          <Tooltip {...tooltip.getTargetProps()}>
            This posting is under review. It may take up to 6 hours to appear on Indeed
            after publishing
          </Tooltip>
        </>
      )}
    </HStack>
  )
}
