import React from 'react'
import SectionTitle from '../components/SectionTitle'
import { Spacer, VStack } from '@revolut/ui-kit'
import { BooleanRadioSwitch } from '../components/RadioSwitch'
import { RecommendedLabel } from '../components/Recommended'
import { ConnectionForm } from '@src/pages/Forms/ConnectionForm/ConnectionForm'

export const DataToolsIntegration = ({
  enabled,
  onChange,
}: {
  enabled: boolean
  onChange: (val: boolean) => void
}) => {
  return (
    <VStack>
      <SectionTitle
        title="Do you use any BI data tools that you want to integrate with KPIs?"
        subtitle="If you have existing BI tools for data analysis and handling, these can be integrated and used as data sources for KPIs"
      />
      <BooleanRadioSwitch
        variant="horizontal"
        value={enabled}
        onChange={onChange}
        yesLabel={<RecommendedLabel label="Yes" />}
        yesDescription="I want to connect BI tools like Looker for advanced analytics"
        noDescription="I would like to manage KPIs without integrating BI tools at the moment"
      />
      {enabled && (
        <>
          <Spacer height="s-16" />
          <SectionTitle
            title="Setup connection"
            subtitle="Please enter the connection details"
          />
          <ConnectionForm
            readOnly={false}
            filterOptions={options =>
              options.filter(option => option.value.id === 'looker')
            }
          />
        </>
      )}
    </VStack>
  )
}
