import React from 'react'
import {
  columnNameTableColumn,
  columnDescriptionTableColumn,
} from '@src/constants/columns/dataAnalytics'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { QueryColumnInterface } from '@src/interfaces/dataAnalytics'
import Table from '@src/components/TableV2/Table'
import { TableNames } from '@src/constants/table'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
  SelectionControls,
} from '@src/components/TableV2/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'

export interface TableColumnInterface extends QueryColumnInterface {
  id: number
}

interface Props {
  handleChange: (
    tableWrapperState: SelectTableWrapperOnChangeData<TableColumnInterface>,
  ) => void
  handleControlsLoaded: (
    selectionControls: SelectionControls<TableColumnInterface>,
  ) => void
  tableData: TableColumnInterface[]
}

const tableFilters: FilterByInterface[] = []

const tableRows: RowInterface<TableColumnInterface> = {
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...columnNameTableColumn,
      width: 150,
    },
    {
      ...columnDescriptionTableColumn,
      width: 200,
    },
  ],
}

export const ColumnsTable = ({
  handleChange,
  handleControlsLoaded,
  tableData,
}: Props) => {
  return (
    <Table.Widget>
      <Table.Widget.Table>
        <SelectTableWrapper
          enabled
          filters={tableFilters}
          onChange={handleChange}
          onControlsLoaded={handleControlsLoaded}
          tableDataLength={tableData?.length}
        >
          <AdjustableTable
            count={tableData?.length}
            data={tableData}
            dataType="Report columns"
            enableSettings={false}
            name={TableNames.ReportingAppReportsFormatColumns}
            noDataMessage="No columns for this query"
            row={tableRows}
          />
        </SelectTableWrapper>
      </Table.Widget.Table>
    </Table.Widget>
  )
}
