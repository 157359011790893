import { useEffect } from 'react'
import { useCookieConsentContext } from '@src/pages/Landing/hooks/useCookieConsentContext'
import { cookiesApi } from '@src/utils/cookies'

export function useAnalyticsCookiesCleanup(cookiesToCleanup?: string[]) {
  const { isAnalyticsEnabled } = useCookieConsentContext()

  useEffect(() => {
    !isAnalyticsEnabled && cookiesToCleanup?.forEach(cookie => cookiesApi.remove(cookie))
  }, [isAnalyticsEnabled, cookiesToCleanup])
}
