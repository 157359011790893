import { HubAppType } from '@src/interfaces/hub'
import { PermissionTypes } from '@src/store/auth/types'

// get first "tab" or app (if it doesn't have tabs) which user has permissions to view
export const getAppOrTab = (permissions: PermissionTypes[], app: HubAppType) => {
  return 'tabs' in app
    ? Object.values(app.tabs).find(tab =>
        tab.canView
          ? tab.canView?.some(permission => permissions.includes(permission))
          : true,
      )
    : [app].find(tab =>
        tab.canView
          ? tab.canView?.some(permission => permissions.includes(permission))
          : true,
      )
}
