import React, { useEffect, useMemo, useState } from 'react'
import { ActionButton } from '@revolut/ui-kit'
import RadioSelectInput, {
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReferCandidateInterface } from '@src/interfaces/referCandidate'
import { connect } from 'lape'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'

type Props = {
  selectedMainPosting: JobPostingLocationTypeInterface | null
  onChangeSelectedMainPosting: (value: JobPostingLocationTypeInterface | null) => void
  selectedSecondaryPostings: RadioSelectOption<JobPostingLocationTypeInterface>[]
  onChangeSelectedSecondaryPostings?: (
    value: RadioSelectOption<JobPostingLocationTypeInterface>[],
  ) => void
  noSideBar?: boolean
}

const NewCandidatePostingSelect = ({
  selectedMainPosting,
  onChangeSelectedMainPosting,
  selectedSecondaryPostings,
  onChangeSelectedSecondaryPostings,
  noSideBar,
}: Props) => {
  const { errors } = useLapeContext<ReferCandidateInterface>()
  const { data: postingsOptions } = useGetSelectors<JobPostingLocationTypeInterface>(
    selectorKeys.job_postings_locations_type,
  )

  const [openSidebar, setOpenSidebar] = useState(false)

  const mainPostingsOptions = useMemo(() => {
    return (
      postingsOptions
        ?.filter(item => !selectedSecondaryPostings.some(e => e.value.id === item.id))
        ?.map(item => ({
          label: item.name,
          value: item,
        })) || []
    )
  }, [postingsOptions, selectedSecondaryPostings])

  const secondaryPostingsOptions = useMemo(() => {
    return (
      postingsOptions
        ?.filter(item => item.id !== selectedMainPosting?.id)
        ?.map(item => ({
          label: item.name,
          value: item,
        })) || []
    )
  }, [postingsOptions, selectedMainPosting])

  const postingIds = useMemo(() => {
    const ids = []

    if (selectedMainPosting?.id !== undefined) {
      ids.push(selectedMainPosting.id)
    }

    if (selectedSecondaryPostings) {
      ids.push(...selectedSecondaryPostings.map(item => item.value.id))
    }

    return ids
  }, [selectedMainPosting, selectedSecondaryPostings])

  useEffect(() => {
    if (noSideBar) {
      return
    }
    setOpenSidebar(!!postingIds.length)
  }, [postingIds])

  return (
    <>
      {!!postingIds.length && !noSideBar && (
        <ChangeJobPostingSidebar
          onClose={() => setOpenSidebar(false)}
          selectedJobPostingId={postingIds[0]}
          postingIds={postingIds}
          isOpen={openSidebar}
          isSelector={false}
          title="Selected postings"
          subtitle=""
        />
      )}

      <RadioSelectInput<JobPostingLocationTypeInterface>
        label="Select main posting"
        options={mainPostingsOptions}
        value={selectedMainPosting}
        onChange={option => {
          onChangeSelectedMainPosting(option || null)
          errors.posting_id = undefined
        }}
        message={errors.posting_id}
        hasError={!!errors.posting_id}
        inputProps={{
          'data-name': 'posting_id',
        }}
      >
        {renderOption}
      </RadioSelectInput>

      {onChangeSelectedSecondaryPostings && (
        <NewMultiSelect
          placeholder="Select additional postings (optional)"
          options={secondaryPostingsOptions}
          onChange={val => {
            onChangeSelectedSecondaryPostings(val)
          }}
          value={selectedSecondaryPostings}
        >
          {renderOption}
        </NewMultiSelect>
      )}

      {!!postingIds.length && !noSideBar && (
        <ActionButton onClick={() => setOpenSidebar(true)}>
          View selected posting details
        </ActionButton>
      )}
    </>
  )
}

export default connect(NewCandidatePostingSelect)
