import { useMemo } from 'react'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useGetSpecialisationSeniorities } from '@src/api/specialisations'
import {
  RadioSelectInputProps,
  RadioSelectOption,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export type SeniorityOption = RadioSelectOption<SeniorityInterface>

export interface UseSenioritySelectorReturnType {
  isSeniorityValid: boolean
  autocompleteProps: Partial<RadioSelectInputProps<SeniorityInterface>>
}

export const checkSeniorityRange = (
  seniority: SeniorityInterface | null,
  availableSeniorities: SeniorityInterface[],
): boolean => {
  if (availableSeniorities.length < 1 || !seniority) {
    return true
  }
  const maxIndex = availableSeniorities.length - 1

  const minSeniorityLevel = availableSeniorities[0].level
  const maxSeniorityLevel = availableSeniorities[maxIndex].level

  return seniority.level >= minSeniorityLevel && seniority.level <= maxSeniorityLevel
}

const useSenioritySelector = (
  specialisationId: string | number | null,
  selectedSeniority: SeniorityInterface | null,
): UseSenioritySelectorReturnType => {
  const seniorities = useGetSpecialisationSeniorities(specialisationId)

  const options: SeniorityOption[] = useMemo(() => {
    if (!seniorities.data) {
      return []
    }
    return seniorities.data.map(seniority => ({
      label: seniority.name,
      value: seniority,
    }))
  }, [seniorities.data])

  const isSeniorityValid =
    !seniorities.isLoading && seniorities.data && selectedSeniority
      ? checkSeniorityRange(selectedSeniority, seniorities.data)
      : true

  return {
    isSeniorityValid,
    autocompleteProps: {
      options,
      loading: seniorities.isLoading,
      hasError: seniorities.isError,
    },
  }
}

export default useSenioritySelector
