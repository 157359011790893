import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { selectAuth } from '@src/store/auth/selectors'
import { logOutAction } from '@src/store/auth/actions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const MyProfile = () => {
  const auth = useSelector(selectAuth)
  const dispatch = useDispatch()

  const isAuthenticated = auth.authenticated

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(logOutAction())
    }
  }, [isAuthenticated])

  return isAuthenticated ? (
    <InternalRedirect
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: auth.user.id })}
    />
  ) : null
}

export default MyProfile
