import React, { FormEvent, useState } from 'react'
import { Button, InputGroup, Popup, Text, TextArea, Header } from '@revolut/ui-kit'
import { Cell } from '@revolut/ui-kit'

type Props = {
  onSubmit: (comment?: string) => void
  onClose: () => void
  comment?: string | null
  disabled?: boolean
}

const AddCalibrationGradePopup = ({ onClose, onSubmit, comment, disabled }: Props) => {
  const [commentValue, setComment] = useState<string | undefined>(comment || undefined)

  return (
    <Popup variant="modal-view" onClose={onClose} open>
      <Header variant="item">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>{disabled ? 'Comment' : 'Add comment'}</Header.Title>
      </Header>
      <InputGroup>
        {disabled ? (
          <Cell>
            <Text>{commentValue}</Text>
          </Cell>
        ) : (
          <TextArea
            value={commentValue}
            label="Comment (optional)"
            rows={4}
            onChange={(e: FormEvent<HTMLTextAreaElement>) =>
              setComment(e.currentTarget.value)
            }
          />
        )}
      </InputGroup>

      <Popup.Actions horizontal>
        {disabled ? (
          <Button onClick={onClose} variant="secondary">
            Close
          </Button>
        ) : (
          <>
            <Button onClick={onClose} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => onSubmit(commentValue)}>Submit</Button>
          </>
        )}
      </Popup.Actions>
    </Popup>
  )
}

export default AddCalibrationGradePopup
