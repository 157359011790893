import { InputGroup } from '@revolut/ui-kit'
import React, { Fragment, useEffect, useMemo } from 'react'
import {
  CareersApplicationInterface,
  CareersQuestion,
  CareersQuestionCheckbox,
} from '@src/interfaces/careers'
import CareersApplicationQuestionTitle from '@src/pages/Careers/CareersApplication/CareersApplicationQuestionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import set from 'lodash/set'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import produce from 'immer'
import get from 'lodash/get'
import { connect } from 'lape'
import { useCustomSectionsContext } from '@src/pages/Careers/CareersApplication/components/CustomSectionsContext'
import omit from 'lodash/omit'

interface CareersApplicationWidgetCustomQuestionsProps {
  sectionId: number
  questions: CareersQuestion[]
}

const CareersApplicationCustomQuestions = ({
  sectionId,
  questions,
}: CareersApplicationWidgetCustomQuestionsProps) => {
  const { values, errors } = useLapeContext<CareersApplicationInterface>()
  const sectionsContext = useCustomSectionsContext()

  const preferredLocations = values.preferred_locations

  const filteredQuestions = useMemo(
    () =>
      questions
        .map((entry, index) => ({
          question: entry,
          index,
        }))
        .filter(
          ({ question: { locations } }) =>
            locations.length === 0 ||
            locations.some(location =>
              preferredLocations?.some(prefLocation => prefLocation.id === location),
            ),
        ),
    [questions, preferredLocations],
  )

  useEffect(() => {
    sectionsContext.onChange(values.additional_questions?.sections)
  }, [])

  const customFieldBuilder = (question: CareersQuestion, idx: number) => {
    const commonProps = {
      name: `additional_questions.sections.${sectionId}.questions.${idx}.answer`,
      'data-testid': `additional_questions.sections.${sectionId}.questions.${idx}.answer`,
      required: !question.optional,
      onAfterChange: () => {
        // we need to observe values.additional_questions.sections changes in another component, but Lape does not trigger re-rendering when we mutate
        // nested fields for some reason, this is a hack to avoid it
        sectionsContext.onChange(values.additional_questions?.sections)
      },
    }

    switch (question.internal_data_type) {
      case 'textarea_application_form_question':
        return (
          <>
            <CareersApplicationQuestionTitle
              title={`${idx + 1}. ${question.title}`}
              subtitle={question.subtitle}
              optional={question.optional}
            />
            <LapeNewTextArea
              {...commonProps}
              label={question.placeholder}
              aria-label={question.title}
            />
          </>
        )

      case 'text_application_form_question':
        return (
          <>
            <CareersApplicationQuestionTitle
              title={`${idx + 1}. ${question.title}`}
              subtitle={question.subtitle}
              optional={question.optional}
            />
            <LapeNewInput
              {...commonProps}
              label={question.placeholder}
              aria-label={question.title}
            />
          </>
        )

      case 'checkbox_application_form_question': {
        const errPath = `additional_questions.sections.${sectionId}.questions.${idx}.options.0.answer`
        const err = get(errors, errPath)

        return (
          <>
            <CareersApplicationQuestionTitle
              title={`${idx + 1}. ${question.title}`}
              subtitle={question.subtitle}
              optional={question.optional}
            />
            <NewMultiSelect
              {...omit(commonProps, 'onAfterChange')}
              options={question.options.map(option => ({
                label: option.option,
                value: {
                  ...option,
                  name: option.option,
                  id: option.option,
                },
              }))}
              value={(
                values.additional_questions?.sections?.[sectionId]?.questions?.[
                  idx
                ] as CareersQuestionCheckbox
              )?.options
                ?.filter(option => option.answer)
                .map(option => ({
                  label: option.option,
                  value: {
                    ...option,
                    name: option.option,
                    id: option.option,
                  },
                }))}
              onChange={selectedOptions => {
                set(errors, errPath, null)
                set(
                  values,
                  `additional_questions.sections.${sectionId}.questions.${idx}.options`,
                  produce(question.options, options => {
                    options.forEach(option => {
                      option.answer = false

                      if (
                        selectedOptions?.some(
                          selectedOption =>
                            selectedOption.value.internal_data_id ===
                            option.internal_data_id,
                        )
                      ) {
                        option.answer = true
                      }
                    })
                  }),
                )

                commonProps.onAfterChange()
              }}
              data-name={errPath}
              hasError={!!err}
              message={typeof err === 'string' ? err : undefined}
              aria-label={question.title}
            />
          </>
        )
      }

      case 'option_application_form_question':
        return (
          <>
            <CareersApplicationQuestionTitle
              title={`${idx + 1}. ${question.title}`}
              subtitle={question.subtitle}
              optional={question.optional}
            />
            <LapeNewRadioButtons
              {...commonProps}
              options={question.options.map(option => ({
                label: option.option,
                value: {
                  ...option,
                  id: option.option,
                },
              }))}
            />
          </>
        )

      case 'dropdown_application_form_question':
        return (
          <>
            <CareersApplicationQuestionTitle
              title={`${idx + 1}. ${question.title}`}
              subtitle={question.subtitle}
              optional={question.optional}
            />
            <LapeRadioSelectInput
              {...commonProps}
              label={question.title}
              options={
                question.options.map(option => ({
                  key: option.option,
                  label: option.option,
                  value: {
                    ...option,
                    id: option.option,
                    name: option.option,
                  },
                })) || []
              }
              searchable={question.options.length > 14}
              inputProps={{
                'data-testid': commonProps['data-testid'],
              }}
            />
          </>
        )

      default:
        return null
    }
  }

  return (
    <InputGroup>
      {filteredQuestions.map(({ question, index }) => (
        <Fragment key={question.internal_data_id}>
          {customFieldBuilder(question, index)}
        </Fragment>
      ))}
    </InputGroup>
  )
}

export default connect(CareersApplicationCustomQuestions)
