import SideBar from '@src/components/SideBar/SideBar'
import React, { useState } from 'react'
import { Avatar, Group, Item, Search, Text, VStack } from '@revolut/ui-kit'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { getInitials, getAvatarUrl } from '@src/utils/employees'

type SelectedEmployeesSidebarProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedIds: number[]
}

const SelectedEmployeesSidebar = ({
  isOpen,
  setIsOpen,
  selectedIds,
}: SelectedEmployeesSidebarProps) => {
  const [search, setSearch] = useState('')

  const { data: employees } = useGetSelectors(selectorKeys.all_employees)

  const selectedEmployees =
    employees?.filter(
      employee =>
        selectedIds.includes(Number(employee.id)) &&
        employee.name.toLowerCase().includes(search.toLowerCase()),
    ) || []

  const pendingEmployeesSelected = selectedIds.length - selectedEmployees.length

  return (
    <SideBar
      title={`${selectedIds.length} Employees selected`}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <VStack gap="s-16">
        <Text color="grey" size="small">
          All selected employees will be subject to change during the bulk edit operation.
        </Text>
        <Search placeholder="Search" value={search} onChange={setSearch} />
        <Group>
          {selectedEmployees?.map(employee => (
            <Item key={employee.id}>
              <Item.Avatar>
                <Avatar image={getAvatarUrl(employee.avatar)}>
                  {!employee.avatar && getInitials(employee.name || '')}
                </Avatar>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{employee.name}</Item.Title>
              </Item.Content>
            </Item>
          ))}
        </Group>
        {!!pendingEmployeesSelected && !search && (
          <Text color="grey" size="small">
            + {pendingEmployeesSelected} pending employees
          </Text>
        )}
      </VStack>
    </SideBar>
  )
}

export default SelectedEmployeesSidebar
