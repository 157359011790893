import { MoreBar, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { bulkApproveGoals } from '@src/api/goals'
import { EntityTypes } from '@src/constants/api'
import {
  OrgEntityInterface,
  useOrgEntity,
} from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsInterface } from '@src/interfaces/goals'
import { PermissionTypes } from '@src/store/auth/types'
import React, { ReactNode, useState } from 'react'
import * as Sentry from '@sentry/react'
import pluralize from 'pluralize'

interface BulkGoalApproveActionProps {
  goals: Array<{ id: GoalsInterface['id'] }>
  onApproved: () => void
  title?: ReactNode
}

const getCanBulkApprove = (entity: OrgEntityInterface) => {
  if (
    entity.data.field_options?.permissions?.includes(PermissionTypes.CanBulkApproveGoals)
  ) {
    return true
  }
  // below is legacy, remove once BE ships improvement
  switch (entity.type) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
      return entity.data.field_options?.permissions?.includes(
        PermissionTypes.CanApproveCompanyGoalsInBulk,
      )
    case EntityTypes.department:
      return entity.data.field_options?.permissions?.includes(
        PermissionTypes.CanApproveDepartmentGoalsInBulk,
      )

    case EntityTypes.team:
    case EntityTypes.teams:
      return entity.data.field_options?.permissions?.includes(
        PermissionTypes.CanApproveTeamGoalsInBulk,
      )
    case EntityTypes.employee:
    case EntityTypes.employees:
      return entity.data.field_options?.permissions?.includes(
        PermissionTypes.CanApproveEmployeeGoalInBulk,
      )
    default:
      return false
  }
}
export const BulkGoalApproveAction = ({
  goals,
  onApproved,
  title,
}: BulkGoalApproveActionProps) => {
  const { entity } = useOrgEntity()
  const [pending, setPending] = useState(false)
  const statusPopup = useStatusPopup()
  const dict = {
    BULK_APPROVE_LABEL: `Approve ${pluralize('goal', goals.length, true)}`,
    BULK_APPROVE_SUCCESS_MESSAGE: 'All goals approved',
    BULK_APPROVE_FAIL_MESSAGE: 'Failed to approve all goals',
  }

  const canApprove = entity ? getCanBulkApprove(entity) : false
  const doBulkApprove = async () => {
    setPending(true)
    try {
      await bulkApproveGoals(goals)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>{dict.BULK_APPROVE_SUCCESS_MESSAGE}</StatusPopup.Title>
        </StatusPopup>,
      )
      onApproved()
    } catch (err) {
      Sentry.captureException(err)
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>{dict.BULK_APPROVE_FAIL_MESSAGE}</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setPending(false)
    }
  }
  return canApprove && goals.length ? (
    <MoreBar.Action
      useIcon="Check"
      variant="accent"
      pending={pending}
      onClick={doBulkApprove}
    >
      {title || dict.BULK_APPROVE_LABEL}
    </MoreBar.Action>
  ) : null
}
