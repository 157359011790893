import React from 'react'
import { Box, Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import Form from '@src/features/Form/Form'
import { helpCenterSettings } from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useQueryClient } from 'react-query'

const GeneralForm = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeHelpCenterPreferences)
  const queryClient = useQueryClient()

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <Box mt="s-24">
            <Group>
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable help section',
                }}
                name="help_section_enabled"
                disabled={disableEdit}
              />
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Enable chatbot',
                }}
                name="chatbot_enabled"
                disabled={disableEdit}
              />
            </Group>
          </Box>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText="Settings saved"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.HELP_CENTER_SETTINGS)
          }}
        />
      </PageActions>
    </>
  )
}

export const General = connect(() => (
  /** id: 1 is hardcoded on the BE */
  <Form api={helpCenterSettings} forceParams={{ id: '1' }}>
    <GeneralForm />
  </Form>
))
