import React from 'react'
import type { ReactNode } from 'react'

import type { BoxProps } from '@revolut/ui-kit'
import { Box, Token } from '@revolut/ui-kit'
import styled from 'styled-components'

const TOOLTIP_RADIUS = 10
const TOOLTIP_MIN_WIDTH = 140
const TOOLTIP_OPACITY = 0.9

type LightBackgroundTooltipProps = BoxProps & {
  children: ReactNode
}

// TODO: REVCOR-3046 revisit after ui-kit update
const StyledBox = styled(Box)`
  > span > div > * {
    color: ${Token.color.background} !important;
  }
`

export const LightBackgroundTooltip = ({
  children,
  ...rest
}: LightBackgroundTooltipProps) => (
  <StyledBox
    p="s-8"
    opacity={TOOLTIP_OPACITY}
    bg={Token.color.foreground}
    borderRadius={TOOLTIP_RADIUS}
    minWidth={TOOLTIP_MIN_WIDTH}
    {...rest}
  >
    {children}
  </StyledBox>
)
