import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import {
  EmployeeResignationInterface,
  ReasonsInterface,
  ResignationApprovalInterface,
  ResignationApprovalResponse,
  ResignationsStats,
} from '@src/interfaces/employeeResignation'
import { FileInterface } from '@src/interfaces/files'
import { isFile } from '@src/utils/files'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise, AxiosResponse } from 'axios'
import { api, apiWithoutHandling } from '.'
import { getCommentsAPI } from './comments'
import { uploadFile } from './files'

export const resignationReasonsFormRequests: RequestInterfaceNew<EmployeeResignationInterface> =
  {
    get: async ({ id, employeeId }) =>
      api.get(`${API.EMPLOYEES}/${employeeId}/resignations/${id}`),
    update: async (data, { id, employeeId }) => {
      let notice_file: AxiosResponse<FileInterface> | undefined

      if (data.notice_file) {
        const isPrivate = true
        notice_file = isFile(data.notice_file)
          ? await uploadFile(data.notice_file, 'resignations', isPrivate)
          : await new Promise<AxiosResponse<FileInterface>>(resolve => {
              resolve({ data: data.notice_file } as AxiosResponse<FileInterface>)
            })
      }
      return apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}/resignations/${id}`,
        {
          ...data,
          ...(notice_file?.data?.id
            ? { notice_file: { id: notice_file.data.id } }
            : null),
        },
      )
    },
    submit: async (data, { employeeId }) =>
      apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/resignations`, data),
  }

export const resignationReasonsRequests: RequestInterfaceNew<ReasonsInterface> = {
  get: async () => api.get(API.RESIGNATION_REASONS),
  update: async data => apiWithoutHandling.patch(API.RESIGNATION_REASONS, data),
  submit: async data => apiWithoutHandling.post(API.RESIGNATION_REASONS, data),
}

export const getResignationsList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeResignationInterface>
> =>
  api.get(API.RESIGNATIONS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getEmployeeResignation = (
  employeeId: string,
  resignationId: string,
): AxiosPromise<EmployeeResignationInterface> =>
  api.get(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}`)

export const useResignation = (employeeId: string, id: string) =>
  useFetch<EmployeeResignationInterface>(
    `${API.EMPLOYEES}/${employeeId}/resignations/${id}`,
  )

export const getEmployeeResignationApprovals = (
  employeeId: string,
  resignationId: string,
): AxiosPromise<ResignationApprovalInterface[]> =>
  api.get(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/approvals`)

export const useResignationsStats = () =>
  useFetch<ResignationsStats>(API.RESIGNATIONS_STATS)

export const useResignationApprovals = (employeeId: string, id: number) =>
  useFetch<ResignationApprovalResponse>(
    `${API.EMPLOYEES}/${employeeId}/resignations/${id}/approvals`,
  )

export const useResignationNextStepsList = () =>
  useFetch<string[]>(API.RESIGNATION_NEXT_STEPS)

export const approveResignationFile = (
  employeeId: string,
  resignationId: string,
): AxiosPromise<EmployeeResignationInterface> =>
  api.post(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/approve`)

export const rejectResignationFile = (
  data: { rejection_reason: string },
  { employeeId, resignationId }: { employeeId: string; resignationId: string },
): AxiosPromise<EmployeeResignationInterface> =>
  api.post(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/reject`, data)

export const cancelResignation = (
  data: Partial<ResignationApprovalInterface>,
  { employeeId, resignationId }: { employeeId: string; resignationId: string },
): AxiosPromise<EmployeeResignationInterface> =>
  api.post(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/cancel`, data)

export const requestResignationFile = (
  employeeId: string,
  resignationId: string,
): AxiosPromise<ResignationApprovalInterface> =>
  api.post(`${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/request`)

export const getResignationCommentsAPI = (employeeId: string, resignationId: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/resignations/${resignationId}/comments`,
  })
