import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { testEngagementSurvey } from '@src/api/engagement'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'
import React, { useState } from 'react'

export const useTestSurvey = ({
  surveyId,
  preSubmit,
}: {
  surveyId: string | number
  preSubmit: boolean
}) => {
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()
  const { submit } = useLapeContext()
  const runTestSurvey = async () => {
    setIsPending(true)
    try {
      // need to save the form with current questions before we can preview
      if (preSubmit) {
        await submit()
      }

      const { data } = await testEngagementSurvey(Number(surveyId))
      navigateTo(
        `${pathToUrl(ROUTES.ENGAGEMENT_PAGE, undefined, {
          survey_id: String(data?.id),
          is_test: 'true',
        })}`,
      )
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't start test survey</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }
  return { runTestSurvey, isPending }
}
