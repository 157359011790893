import React from 'react'
import { Text, Token, VStack, Widget } from '@revolut/ui-kit'

export const PayrollIntro = () => {
  return (
    <Widget p="s-16">
      <VStack space="s-8">
        <Text variant="primary">How to setup Payroll?</Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          Our payroll feature simplifies the setup of your payroll process. It enables you
          to effortlessly gather essential payroll information. With this data at your
          fingertips, you can seamlessly collaborate with a payroll provider to ensure
          timely and accurate salary payments for your employees, guaranteeing the
          integrity of your payroll data.
        </Text>
      </VStack>
    </Widget>
  )
}
