import React from 'react'
import { ROUTES } from '@src/constants/routes'
import FunctionTalent from '@src/pages/Forms/CommonTalentTab/Talent'
import FunctionTalentPerformance from '@src/pages/Forms/CommonTalentTab/TalentPerformance'
import { Route, Switch, useParams } from 'react-router-dom'
import { TalentType } from '@src/interfaces/talent/talent'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { FunctionInterface } from '@src/interfaces/functions'
import { PermissionTypes } from '@src/store/auth/types'
import TalentPromotionNominations from '@src/pages/Forms/CommonTalentTab/TalentPromotionNominations'
import { useGlobalSettings } from '@src/api/settings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { DropdownNavigation } from '@src/components/DropdownNavigation/DropdownNavigation'

const Talent = ({ talentType = TalentType.Function }: { talentType?: TalentType }) => {
  const params = useParams<{ id: string }>()
  const isNewTable = useIsNewTable()
  const {
    settings: { promotions_enabled },
  } = useGlobalSettings()
  const { values } = useLapeContext<FunctionInterface>()

  const subTabs = [
    {
      path: ROUTES.FORMS.FUNCTION.TALENT.GENERAL,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.TALENT.GENERAL, params),
      title: 'General',
      component: FunctionTalent,
      canView: true,
    },
    {
      path: ROUTES.FORMS.FUNCTION.TALENT.PERFORMANCE,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.TALENT.PERFORMANCE, params),
      title: 'Performance',
      component: FunctionTalentPerformance,
      canView: values.field_options.permissions?.includes(
        PermissionTypes.ViewEmployeePerformance,
      ),
    },
    {
      path: ROUTES.FORMS.FUNCTION.TALENT.PROMOTION_NOMINATIONS,
      to: pathToUrl(ROUTES.FORMS.FUNCTION.TALENT.PROMOTION_NOMINATIONS, params),
      title: 'Promotion nominations',
      component: TalentPromotionNominations,
      canView:
        promotions_enabled &&
        values.field_options.permissions?.includes(
          PermissionTypes.ViewPromotionNomination,
        ),
    },
  ]

  const filteredTabs = subTabs.filter(tab => tab.canView)

  const navigation = (
    <Flex alignItems="flex-start" mb="s-24">
      <TabBarNavigation isSubtab tabs={filteredTabs} />
    </Flex>
  )

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Switch>
        {filteredTabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component
              data={values}
              talentType={talentType}
              navigation={
                isNewTable ? <DropdownNavigation options={filteredTabs} /> : navigation
              }
            />
          </Route>
        ))}
        {filteredTabs?.[0]?.path && <InternalRedirect to={filteredTabs?.[0]?.path} />}
      </Switch>
    </Flex>
  )
}

export default Talent
