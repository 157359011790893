import React, { useEffect } from 'react'
import { connect } from 'lape'
import { Flex, InputGroup } from '@revolut/ui-kit'
import { compensationBonusFormRequests } from '@src/api/compensation'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import Form from '../Form/Form'
import LapeNewDatePicker from '@components/Inputs/LapeFields/LapeNewDatePicker'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const BONUS_TYPE = {
  id: 'cash' as const,
  name: 'Cash',
}

type Props = {
  id?: number
  setEmployeeId: (id: number | undefined) => void
  onAfterSubmit: () => void
}
const EditBonusForm = ({ id, setEmployeeId, onAfterSubmit }: Props) => {
  const { values } = useLapeContext<CompensationBonusInterface>()

  useEffect(() => {
    if (id) {
      setEmployeeId(values.employee.id)
    }

    if (!values.bonus_type) {
      values.bonus_type = BONUS_TYPE
    }
  }, [])

  return (
    <Flex flex="1 0" flexDirection="column" justifyContent="space-between">
      <InputGroup>
        {!id && (
          <LapeRadioSelectInput
            required
            name="employee"
            label="Employee"
            selector={selectorKeys.employee}
          />
        )}
        <LapeRadioSelectInput
          required
          name="bonus_category"
          label="Reason"
          selector={selectorKeys.employee_bonus_categories}
        />
        <LapeNewInput required name="bonus_cycle" label="Period" />
        <InputGroup variant="horizontal">
          <LapeNewInput required name="bonus_amount" label="Value" />
          <LapeRadioSelectInput
            required
            name="bonus_currency"
            label="Currency"
            selector={selectorKeys.currencies}
          />
        </InputGroup>
        <LapeRadioSelectInput
          required
          name="taxability"
          label="Tax"
          selector={selectorKeys.employee_bonus_taxabilities}
        />
        <LapeNewDatePicker
          required
          name="bonus_date"
          label="Effective date"
          message="Please note the bonus only be visible in the employee's profile once the effective date has passed"
        />
        <LapeNewTextArea
          required
          rows={2}
          name="details"
          label="Details"
          message="Please provide a justification"
        />
      </InputGroup>
      <NewSaveButtonWithPopup onAfterSubmit={onAfterSubmit} useValidator>
        Submit
      </NewSaveButtonWithPopup>
    </Flex>
  )
}

export const SidebarContent = connect((props: Props) => (
  <Form
    api={compensationBonusFormRequests}
    forceParams={{ id: props.id ? String(props.id) : undefined }}
  >
    <EditBonusForm {...props} />
  </Form>
))
