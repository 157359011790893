import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import {
  getJobPostingsRecruiterOwnership,
  jobPostingsRecruiterOwnershipTransfer,
} from '@src/api/ownership'
import {
  jobDescriptionGenericNameColumn,
  jobDescriptionPublishedStatusColumn,
  jobDescriptionRecruiterColumn,
  jobDescriptionSpecialisationColumn,
  jobDescriptionStatusColumn,
} from '@src/constants/columns/jobDescription'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const JobPostingsRecruiterOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    jobPostingsRecruiterOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        recruiter: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getJobPostingsRecruiterOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer job postings to a new recruiter"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Job postings" val={stats?.recruiter_job_posting_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                { ...jobDescriptionGenericNameColumn, width: 250 },
                { ...jobDescriptionSpecialisationColumn, width: 250 },
                { ...jobDescriptionRecruiterColumn, width: 200 },
                { ...jobDescriptionPublishedStatusColumn, width: 250 },
                { ...jobDescriptionStatusColumn, width: 250 },
              ]}
              noDataMessage="Job postings ownership will appear here"
              ownerColTitle="New recruiter"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default JobPostingsRecruiterOwnership
