import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { ExportRequest, GetRequestInterface } from '@src/interfaces'
import {
  CalibratedGradeInterface,
  CalibratedGradeStatsInterface,
  UploadStatus,
} from '@src/interfaces/supportTool'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FinalGrade } from '@src/interfaces/performance'

export interface UpdateGradesRequestData
  extends Pick<
    CalibratedGradeInterface,
    | 'deliverables_rating_label'
    | 'functional_skills_label'
    | 'absolute_rating_label'
    | 'performance_team_comment'
  > {
  display_grade?: FinalGrade
  department_owner_grade_suggestion?: FinalGrade | null
  function_owner_grade_suggestion?: FinalGrade | null
}

export const getCalibratedGrades = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<CalibratedGradeInterface>
> =>
  api.get(
    `${API.SUPPORT_TOOL}/calibratedGrades`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getCalibratedGradesExport: ExportRequest = (exportType, filterQuery) =>
  api.get(
    `${API.SUPPORT_TOOL}/calibratedGrades/${exportType}`,
    { params: filterQuery, responseType: 'blob' },
    'v2',
  )

export const getCalibratedGradesStats = ({
  filters,
}: FetchDataQueryInterface): AxiosPromise<CalibratedGradeStatsInterface> =>
  api.get(
    `${API.SUPPORT_TOOL}/calibratedGrades/stats`,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const updateGrade = (id: number, data: UpdateGradesRequestData) =>
  api.patch(`${API.SUPPORT_TOOL}/calibratedGrades/${id}`, data, undefined, 'v2')

export const getUploadStatus = () => (): AxiosPromise<UploadStatus> =>
  api.get(`${API.SUPPORT_TOOL}/calibratedGrades/uploadStatus`, undefined, 'v2')

export const uploadGrades = async (file: File) => {
  const formData = new FormData()
  formData.append('grades_file', file, file.name)

  return api.post(
    `${API.SUPPORT_TOOL}/calibratedGrades/upload`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Content-Disposition': `attachment`,
      },
    },
    'v2',
  )
}

export const syncDisplayGrades = (id: number | string, data: number[]) =>
  api.post(
    `${API.SUPPORT_TOOL}/calibratedGrades/${id}/recalculateGrades`,
    { calculated_grades: data },
    undefined,
    'v2',
  )

export const syncEligibilityEmployees = (cycleId: number | string) =>
  apiWithoutHandling.post(
    `${API.SUPPORT_TOOL}/eligibilityGrades/${cycleId}/sync`,
    undefined,
    undefined,
    'v2',
  )
