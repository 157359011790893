import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import LapeForm from '@src/features/Form/LapeForm'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import { TabsInterface } from '@src/interfaces/data'
import PerformanceViewContent from '@src/pages/Forms/EmployeePerformance/components/PerformanceView/PerformanceViewContent'
import PageLoading from '@components/PageLoading/PageLoading'

type Props = {
  category: ReviewCategory
  getTabs: (
    params: any,
    isManagerReview: boolean,
  ) => TabsInterface<ReviewSummaryInterface>[]
}

const PerformanceView = ({ category, getTabs }: Props) => {
  const params = useParams<{ id: string; employeeId: string }>()
  const [initialValues, setInitialValues] = useState<ReviewSummaryInterface>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const fetchSummary = async () => {
      const result = await getPerformanceReviewSummary(
        params.id,
        +params.employeeId,
        category,
      )

      setInitialValues(result.data)
      setLoading(false)
    }

    fetchSummary()
  }, [])

  if (loading) {
    return <PageLoading />
  }

  if (!initialValues) {
    return null
  }

  return (
    <LapeForm
      onSubmit={() => {
        return Promise.resolve({})
      }}
      validation={{}}
      initialValues={initialValues}
    >
      <PerformanceViewContent category={category} getTabs={getTabs} />
    </LapeForm>
  )
}
export default connect(PerformanceView)
