import React from 'react'
import { Grid, Text, Token, VStack, Widget } from '@revolut/ui-kit'

type OverviewHeaderProps = {
  overallGrade?: React.ReactNode
  results: React.ReactNode
  resultsLabel: React.ReactNode
}

export const OverviewHeader = ({
  overallGrade,
  results,
  resultsLabel,
}: OverviewHeaderProps) => {
  return (
    <Widget p="s-16">
      <Grid
        columns={{ all: 1, lg: 'min-content 1fr' }}
        columnGap="s-48"
        rowGap="s-16"
        placeItems={{ lg: 'center start' }}
      >
        {overallGrade}
        <VStack width="100%">
          <Text color={Token.color.greyTone50} variant="emphasis2" mb="s-16">
            {resultsLabel}
          </Text>
          <Grid columns={{ all: 1, lg: 3 }} width="100" gap="s-16">
            {results}
          </Grid>
        </VStack>
      </Grid>
    </Widget>
  )
}
