import styled from 'styled-components'
import { Box } from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import React from 'react'

interface HTMLContentProps {
  html: string
}

const TextContent = styled(Box)`
  p {
    margin: 0;
  }
  ol,
  ul {
    padding: 0 0 0 40px;
    margin: 0;
  }
`

const HTMLContent = ({ html }: HTMLContentProps) => {
  return (
    <TextContent
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(html),
      }}
    />
  )
}

export default HTMLContent
