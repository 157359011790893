import React from 'react'
import { Box, Group, Link, Text, VStack, Widget } from '@revolut/ui-kit'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillInterface, SkillTypeTitle } from '@src/interfaces/skills'
import { SummarySubheader } from '@src/features/Summary/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { StyledLI, StyledOL, StyledUL } from '@components/CommonSC/General'
import { Statuses } from '@src/interfaces'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { DEFINING_SKILLS } from '@src/constants/externalLinks'
import { formatDate, formatTimeAgo } from '@src/utils/format'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import useApproval from '@src/features/ApprovalFlow/useApproval'
import { useGetSkillApprovals } from '@src/api/skills'

const Preview = () => {
  const { values, initialValues } = useLapeContext<SkillInterface>()

  const isPendingApproval = [
    Statuses.pending,
    Statuses.pending_reapproval,
    Statuses.expired,
  ].includes(initialValues.status || values.status)

  const isInReapproval = [Statuses.pending_reapproval, Statuses.expired].includes(
    initialValues.status || values.status,
  )

  const { approvalFlow } = useApproval({
    data: values,
    useGetApprovals: useGetSkillApprovals,
    isPendingApproval,
    noReject: isInReapproval,
    approveButtonActionText: isInReapproval ? 'Mark as reviewed' : undefined,
  })

  return (
    <PageBody>
      {initialValues.status === Statuses.pending_reapproval &&
        values.valid_until &&
        values.approval_date_time && (
          <ActionWidget
            mb="s-16"
            title={
              <>
                Review and re-approve of this skill is due by{' '}
                {formatDate(values.valid_until)}
              </>
            }
            text={
              <>
                This skill was last reviewed on {formatDate(values.approval_date_time)}.
                Please review whether the skill description and mastery are still valid
                and of good quality.{' '}
                <HideIfCommercial>
                  To know how to review a skill{' '}
                  <Link href={DEFINING_SKILLS} target="_blank">
                    click here
                  </Link>
                  .
                </HideIfCommercial>
              </>
            }
          />
        )}
      {initialValues.status === Statuses.expired && values.approval_date_time && (
        <ActionWidget
          mb="s-16"
          title={<>Last reviewed {formatTimeAgo(values.approval_date_time)}</>}
          text={
            <>
              This skill was last reviewed on {formatDate(values.approval_date_time)}.
              Please review whether the skill matrices are still valid and of good
              quality.{' '}
              <HideIfCommercial>
                To know how to review a skill{' '}
                <Link href={DEFINING_SKILLS} target="_blank">
                  click here
                </Link>
                .
              </HideIfCommercial>
            </>
          }
        />
      )}
      <Box mb="s-16">{approvalFlow}</Box>
      {initialValues.status === Statuses.pending && (
        <Widget p="s-16" mb="s-16">
          <Text use="p" color="grey-tone-50" variant="caption" fontWeight={500} mb="s-8">
            Approval checklist
          </Text>
          <Text use="p">
            Please review the skill contents against the following quality checks:
            <StyledUL indicatorColor="grey-tone-50">
              <StyledLI>
                Clear, concise, objective language is used in mastery level definitions.
              </StyledLI>
              <StyledLI>
                Skill description is logical and explains how to review the skill.
              </StyledLI>
              <StyledLI>
                The skill is unique and complimentary to the existing list.
              </StyledLI>
              <StyledLI>
                There are at least 2 criteria given per mastery level. Ideally
                'Intermediate' level has at least 3.
              </StyledLI>
              <StyledLI>
                High quality playbook is attached, explaining what the skill is, how it is
                used, why it is needed, associated methods and techniques, and resources
                on how to improve.
              </StyledLI>
            </StyledUL>
          </Text>
        </Widget>
      )}
      <FormPreview<SkillInterface> data={values}>
        <Group>
          <FormPreview.Details field="description" title="Skill description" />
          <FormPreview.Item<SkillInterface>
            field="playbook_url"
            title="Playbook link"
            insert={data =>
              data.playbook_url ? (
                <Link target="_blank" href={data.playbook_url}>
                  Open
                </Link>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item<SkillInterface> title="Owner" type="employee" field="owner" />
          <FormPreview.Item<SkillInterface>
            title="Category"
            insert={data => SkillTypeTitle[data.skill_type]}
          />
          <FormPreview.Item<SkillInterface> title="Function" field="function.name" />
        </Group>
      </FormPreview>
      <Box pt="s-16">
        <SummarySubheader
          formUrl={pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id: values.id })}
        >
          Skill mastery
        </SummarySubheader>
        <Widget p="s-16">
          <VStack space="s-16">
            {values.mastery_level_definition_poor && (
              <Box>
                <Text variant="caption">Poor</Text>
                <StyledOL type="a" indicatorColor="grey-tone-50">
                  {values.mastery_level_definition_poor.map(str => (
                    <li key={str}>
                      <Text variant="caption">{str}</Text>
                    </li>
                  ))}
                </StyledOL>
              </Box>
            )}
            {values.mastery_level_definition_basic && (
              <Box>
                <Text variant="caption">Basic</Text>
                <StyledOL type="a" indicatorColor="grey-tone-50">
                  {values.mastery_level_definition_basic.map(str => (
                    <li key={str}>
                      <Text variant="caption">{str}</Text>
                    </li>
                  ))}
                </StyledOL>
              </Box>
            )}
            {values.mastery_level_definition_intermediate && (
              <Box>
                <Text variant="caption">Intermediate</Text>
                <StyledOL type="a" indicatorColor="grey-tone-50">
                  {values.mastery_level_definition_intermediate.map(str => (
                    <li key={str}>
                      <Text variant="caption">{str}</Text>
                    </li>
                  ))}
                </StyledOL>
              </Box>
            )}
            {values.mastery_level_definition_advanced && (
              <Box>
                <Text variant="caption">Advanced</Text>
                <StyledOL type="a" indicatorColor="grey-tone-50">
                  {values.mastery_level_definition_advanced.map(str => (
                    <li key={str}>
                      <Text variant="caption">{str}</Text>
                    </li>
                  ))}
                </StyledOL>
              </Box>
            )}
            {values.mastery_level_definition_expert && (
              <Box>
                <Text variant="caption">Expert</Text>
                <StyledOL type="a" indicatorColor="grey-tone-50">
                  {values.mastery_level_definition_expert.map(str => (
                    <li key={str}>
                      <Text variant="caption">{str}</Text>
                    </li>
                  ))}
                </StyledOL>
              </Box>
            )}
          </VStack>
        </Widget>
      </Box>
    </PageBody>
  )
}

export default Preview
