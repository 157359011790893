import React from 'react'
import { Box, Button, DetailsCellSkeleton, Header, Popup } from '@revolut/ui-kit'
import { useGetCandidateFormsApplicationSections } from '@src/api/hiringProcess'
import CandidateApplicationFormSection from '@src/features/CandidateFormPreview/CandidateApplicationFormSection'

type Props = {
  onClose: () => void
  candidateId: number
}

export const CVScreeningApplicationQuestions = ({ onClose, candidateId }: Props) => {
  const { applicationFormSections, isLoading } =
    useGetCandidateFormsApplicationSections(candidateId)

  if (isLoading) {
    return <DetailsCellSkeleton />
  }

  return (
    <Popup size="md" open onClose={onClose}>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Application</Header.Title>
        <Header.Description>
          Candidate answers gathered during application process
        </Header.Description>
      </Header>

      <Box>
        {applicationFormSections.map((sections, i) => (
          <Box key={i} mb="s-32">
            {sections.map(section => (
              <CandidateApplicationFormSection
                key={section.internal_data_id}
                section={section}
              />
            ))}
          </Box>
        ))}
      </Box>

      <Popup.Actions>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
