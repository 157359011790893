import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { TeamInterface } from '@src/interfaces/teams'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetTeamsStats = (
  filters?: FilterByInterface[],
  sortBy?: SortByInterface[],
) => {
  const result = useFetch<GetRequestInterface<TeamInterface>>(API.TEAMS, undefined, {
    params: {
      ...filterSortPageIntoQuery(sortBy, filters),
      page_size: 1000,
    },
  })

  const errorRows = result.data?.results.filter(team => team.team_owner == null)

  return {
    isLoading: result.isLoading,
    errors: errorRows?.length,
    errorRows,
    total: result.data?.count,
    refetch: result.refetch,
  }
}
