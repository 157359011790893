import React from 'react'
import { ActionButton, Avatar, HStack, Item } from '@revolut/ui-kit'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { formatDateTime } from '@src/utils/format'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { Link } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  title: string
  owner?: EmployeeOptionInterface
  date: string
  onOpen: () => void
  onDelete: () => void
  disabled?: boolean
}

const DocumentItem = ({ title, owner, date, onOpen, onDelete, disabled }: Props) => {
  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon="Document" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>
          Uploaded by{' '}
          {owner ? (
            <Link
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PREVIEW, { id: owner.id })}
              use={InternalLink}
              target="_blank"
              color="inherit"
            >
              {owner.display_name}
            </Link>
          ) : (
            'N/A'
          )}{' '}
          at {formatDateTime(date)}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <HStack gap="s-8">
          <ActionButton onClick={onOpen}>Open</ActionButton>
          <ActionButton onClick={onDelete} useIcon="Delete" disabled={disabled}>
            Delete
          </ActionButton>
        </HStack>
      </Item.Side>
    </Item>
  )
}

export default DocumentItem
