import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Upload } from './Upload'
import { ROUTES } from '@src/constants/routes'
import { ImportBalanceAdjustment } from './ImportBalanceAdjustment'

// TODO: use this
export const BalanceAdjustments = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.IMPORT} exact>
        <ImportBalanceAdjustment />
      </Route>
      <Route
        path={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.REQUESTS}
        exact
      >
        <Upload type="requests" />
      </Route>
      <Route
        path={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.BALANCES}
        exact
      >
        <Upload type="balances" />
      </Route>
    </Switch>
  )
}
