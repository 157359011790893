import React from 'react'

import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import {
  AllScreeningChecksInterface,
  ScreeningCheckStatuses,
} from '@src/interfaces/screeningChecksInterface'
import { getScreeningStatusColor } from '@src/pages/Forms/ScreeningForm/common'
import { ColorTagVariant } from '@src/components/ColorTag/ColorTag'
import { Tag, Token } from '@revolut/ui-kit'

export const screeningChecksEmployeeColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.full_name',
    sortKey: 'employee__full_name',
    filterKey: 'employee__id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <Table.EmployeeCell employee={data.employee} />,
  }

export const screeningChecksNameColumn: ColumnInterface<AllScreeningChecksInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'screening_name',
  sortKey: 'screening_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Screening check',
}

export const screeningChecksSourceColumn: ColumnInterface<AllScreeningChecksInterface> = {
  type: CellTypes.insert,
  idPoint: 'type.id',
  dataPoint: 'type.name',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.all_screening_check_types,
  title: 'Source',
  insert: ({ data }) => (
    <Tag variant="faded" bg={Token.color.greyTone8} color={Token.color.foreground}>
      {data.type.name}
    </Tag>
  ),
}

export const screeningChecksCreatedByColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'triggered_by.id',
    dataPoint: 'triggered_by.id',
    sortKey: 'triggered_by__full_name',
    filterKey: 'triggered_by__id',
    selectorsKey: selectorKeys.employee,
    title: 'Created by',
    insert: ({ data }) => <Table.EmployeeCell employee={data.triggered_by} />,
  }

export const screeningChecksApproverColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'approver.id',
    dataPoint: 'approver.id',
    sortKey: 'approver__full_name',
    filterKey: 'approver__id',
    selectorsKey: selectorKeys.employee,
    title: 'Approver',
    insert: ({ data }) => <Table.EmployeeCell employee={data.approver} />,
  }

export const screeningChecksCreatedOnColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Created on',
  }

export const screeningChecksLastUpdateColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.date,
    idPoint: 'update_date_time',
    dataPoint: 'update_date_time',
    sortKey: 'update_date_time',
    filterKey: 'update_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Last update',
  }

export const screeningChecksCompletedOnColumn: ColumnInterface<AllScreeningChecksInterface> =
  {
    type: CellTypes.date,
    idPoint: 'completion_date_time',
    dataPoint: 'completion_date_time',
    sortKey: 'completion_date_time',
    filterKey: 'completion_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Completed on',
  }

export const screeningChecksStatusColumn: ColumnInterface<AllScreeningChecksInterface> = {
  type: CellTypes.insert,
  colors: data => getScreeningStatusColor(data.status?.id),
  idPoint: 'status_category.id',
  dataPoint: 'status_category.name',
  sortKey: 'status_category',
  filterKey: 'status_category',
  selectorsKey: selectorKeys.screening_status_categories,
  title: 'Status',
  insert: ({ data }) => {
    let variant: ColorTagVariant

    switch (data.status?.id) {
      case ScreeningCheckStatuses.pending:
      case ScreeningCheckStatuses.pendingApplicant:
      case ScreeningCheckStatuses.pendingRequestor:
      case ScreeningCheckStatuses.requiresAdjudication:
      case ScreeningCheckStatuses.needsAdjudication:
        variant = 'warning'
        break
      case ScreeningCheckStatuses.completed:
      case ScreeningCheckStatuses.Completed:
        variant = 'success'
        break
      case ScreeningCheckStatuses.cancelled:
      case ScreeningCheckStatuses.inviteExpired:
      case ScreeningCheckStatuses.expired:
        variant = 'success'
        break
      case ScreeningCheckStatuses.failed:
        variant = 'danger'
        break
      default:
        variant = 'default'
        break
    }

    return data.status ? (
      <Table.StatusCell status={data.status.name} variant={variant} />
    ) : (
      '-'
    )
  },
}
