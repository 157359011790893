import React from 'react'
import Form from '@src/features/Form/Form'
import { assignUserGroupRequest } from '@src/api/accessManagement'
import AssignUserToPermissionGroup from '@src/pages/Forms/AssignUserToPermissionGroup/AssignUserToPermissionGroup'

const Index = () => {
  return (
    <Form api={assignUserGroupRequest}>
      <AssignUserToPermissionGroup />
    </Form>
  )
}

export default Index
