import React from 'react'
import SectionTitle from '../../components/SectionTitle'
import { valueExample } from './example'
import { Details, HStack, Text, Token } from '@revolut/ui-kit'
import AssessBehaviourButtons from '@src/components/AssessButtons/AssessBehaviourButtons'
import { ValueBasedCardInterface } from '@src/interfaces/performance'

export const ValuesTab = ({ values }: { values?: ValueBasedCardInterface }) => {
  const { name, description, sections } = values || valueExample
  const indexToChar: Record<number, string> = {
    0: 'a.',
    1: 'b.',
    2: 'c.',
  }
  return (
    <>
      <SectionTitle title={name} subtitle={description} />
      {sections.map((section, idx) => (
        <Details key={idx}>
          <Details.Title>
            <HStack space="s-4">
              <Text color={Token.color.greyTone50} variant="secondary" use="div">
                {indexToChar[idx]}
              </Text>
              <Text use="div" color={Token.color.foreground} variant="secondary">
                {section.text}
              </Text>
            </HStack>
          </Details.Title>
          <Details.Content>
            <AssessBehaviourButtons
              checked={[]}
              justificationsCount={0}
              onChange={() => {}}
              superpowersCount={1}
            />
          </Details.Content>
          <Details.Note variant="caption" color={Token.color.greyTone50}>
            {section.description}
          </Details.Note>
        </Details>
      ))}
    </>
  )
}
