import React, { useMemo } from 'react'
import ReviewTimeline, {
  getStagesSummary,
} from '@src/pages/Forms/PromotionNominationForm/common/ReviewTimeline'
import SideBar from '@components/SideBar/SideBar'
import { useTable } from '@components/Table/hooks'
import { getPromotionStagesRequest } from '@src/api/promotions'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import { EmployeeInterface } from '@src/interfaces/employees'
import { Subheader } from '@revolut/ui-kit'

interface Props {
  isOpen: boolean
  onClose: () => void
  nomination: PromotionNominationInterface
  employee: EmployeeInterface
}

const SummarySidebar = ({ isOpen, onClose, nomination, employee }: Props) => {
  const stagesTable = useTable({ getItems: getPromotionStagesRequest(nomination.id) })
  const stagesSummary = useMemo(
    () => getStagesSummary(nomination?.current_stages, stagesTable.data),
    [stagesTable.data, nomination?.current_stages],
  )

  return (
    <SideBar title="Nomination summary" isOpen={isOpen} onClose={onClose} variant="wide">
      <Subheader variant="nested">
        <Subheader.Title>Promotion timeline</Subheader.Title>
      </Subheader>
      <ReviewTimeline
        table={stagesTable}
        stagesSummary={stagesSummary}
        refreshData={stagesTable.refresh}
        nominatedEmployee={employee}
        nomination={nomination}
        onBeforeViewClick={onClose}
      />
    </SideBar>
  )
}

export default SummarySidebar
