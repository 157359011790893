import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { Route } from 'react-router-dom'
import { List } from '@src/pages/Settings/Jobs/List'

export const JobsSettings = () => {
  return (
    <PageWrapper>
      <Route exact path={ROUTES.SETTINGS.JOBS.LIST}>
        <List />
      </Route>
    </PageWrapper>
  )
}
