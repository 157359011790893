import React from 'react'
import Icon from '../../Icon/Icon'
import styled from 'styled-components'
import { KPITemplateInterface } from '@src/interfaces/KpiTemplates'
import { Token } from '@revolut/ui-kit'

export interface Props {
  data: KPITemplateInterface
}

const Warning = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  color: ${Token.color.red};
`
const Checkmark = styled.div`
  display: grid;
  align-items: center;
  justify-content: start;
  color: ${Token.color.green};
`

const KpiEnforce = ({ data }: Props) => {
  if (data.targets_strategy?.id !== 'enforce') {
    return (
      <Warning>
        <Icon type="Close" size="tiny" />
      </Warning>
    )
  }
  return (
    <Checkmark>
      <Icon type="Check" size="tiny" />
    </Checkmark>
  )
}

export default KpiEnforce
