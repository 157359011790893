import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '../components/OnboardingChecklistContent'
import { performanceConfig } from '../common/constants'
import { PerformanceIntro } from './PerformanceIntro'
import { PerformanceFrequency } from './PerformanceFrequency'
import { PerformanceTimeline } from './PerformanceTimeline'
import { PerfrormanceCycles } from './PerformanceCycles'
import { PerformanceReviewers } from './PerformanceReviewers'
import { PerformanceEligibility } from './PerformanceEligibility'
import { PerformanceGradeCalculation } from './PerformanceGradeCalculation'
import { PerformanceScorecard } from './PerformanceScorecards'
import { PerformanceLegacy } from './PerformanceLegacy'
import { performanceSettings } from '@src/api/performanceSettings'

const form = {
  api: performanceSettings,
}

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.INTRO,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceIntro,
  },
  {
    title: 'Frequency',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.FREQUENCY,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.FREQUENCY,
    canView: [PermissionTypes.ViewPerformancePreferences],
    form,
    component: PerformanceFrequency,
  },
  {
    title: 'Timeline steps',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.TIMELINE,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.TIMELINE,
    canView: [PermissionTypes.ViewPerformancePreferences],
    form,
    isWide: true,
    component: PerformanceTimeline,
  },
  {
    title: 'Cycles preview',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.CYCLES,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.CYCLES,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerfrormanceCycles,
    isWide: true,
  },
  {
    title: 'Reviewers',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.REVIEWERS,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.REVIEWERS,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceReviewers,
    form,
  },
  {
    title: 'Eligibility',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.ELIGIBILITY,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.ELIGIBILITY,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceEligibility,
    form,
  },
  {
    title: 'Scorecards',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.SCORECARDS,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.SCORECARDS,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceScorecard,
    isWide: true,
    form,
  },
  {
    title: 'Grade calculation',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.GRADES,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.GRADES,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceGradeCalculation,
    form,
  },
  {
    title: 'Past performance',
    path: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.PERFORMANCE.UPLOAD.IMPORT,
    canView: [PermissionTypes.ViewPerformancePreferences],
    component: PerformanceLegacy,
    hideActions: true,
    hidePageBody: true,
  },
]

const Performance = () => {
  return <OnboardingChecklistContent config={performanceConfig} routes={routes} />
}

export default Performance
