import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  Box,
  Text,
  Bullet,
  Flex,
  BoxProps,
  BulletVariant,
  Token,
  HStack,
  VStack,
} from '@revolut/ui-kit'
import {
  AutoStepperContext,
  autoStepperTitleProps,
  getStepNumber,
} from '@components/Stepper/AutoStepper'
import { formatListLetters } from '@src/utils/format'

export enum NewStepperTitleType {
  compact = 'compact',
  default = 'default',
  coreUI = 'coreUI',
}

interface NewStepperTitleProps extends Omit<BoxProps, 'title'> {
  title: React.ReactNode
  subtitle?: React.ReactNode
  rightContent?: React.ReactNode
  bullet?: React.ReactNode
  noAutoStep?: boolean
  type?: NewStepperTitleType
  variant?: 'number' | 'letter'
  bulletVariant?: BulletVariant
}

const NewStepperTitle = ({
  title,
  subtitle,
  rightContent,
  type = NewStepperTitleType.default,
  noAutoStep,
  variant = 'number',
  bullet,
  bulletVariant = 'outlined',
  ...boxProps
}: NewStepperTitleProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const { stepsUpdated, forceUpdate } = useContext(AutoStepperContext)
  const [stepNumber, setStepNumber] = useState<number>(0)

  useEffect(() => {
    // If auto stepper context is empty, do nothing
    if (stepsUpdated === undefined) {
      return
    }

    if (ref.current && (stepsUpdated || !stepNumber)) {
      const step = getStepNumber(ref.current)
      if (step) {
        setStepNumber(step)
      } else {
        forceUpdate()
      }
    }
  }, [stepsUpdated, ref.current])

  const defaultBullet =
    stepNumber && !noAutoStep ? (
      <Bullet
        mr="0.5em"
        use="span"
        variant={bulletVariant}
        color={bulletVariant === 'outlined' ? Token.color.greyTone50 : undefined}
      >
        {variant === 'number'
          ? stepNumber
          : formatListLetters(stepNumber - 1).toUpperCase()}
      </Bullet>
    ) : null

  if (type === NewStepperTitleType.compact) {
    return (
      <Box mb="s-16" mt="s-24" {...boxProps}>
        <Flex>
          <Text fontWeight="500" use="div" fontSize="caption" color="grey-tone-50">
            {title}
          </Text>
          {rightContent}
        </Flex>
        {subtitle && (
          <Text use="div" mt="5px" fontSize="caption" color="grey-tone-50">
            {subtitle}
          </Text>
        )}
      </Box>
    )
  }
  if (type === NewStepperTitleType.coreUI) {
    return (
      <Box px="s-16" py="s-12" {...boxProps} {...autoStepperTitleProps} ref={ref}>
        <HStack space="s-16">
          {bullet || defaultBullet}
          <VStack>
            <Text variant="emphasis1">{title}</Text>
            {subtitle && (
              <Text variant="body2" color={Token.color.greyTone50}>
                {subtitle}
              </Text>
            )}
          </VStack>
        </HStack>
      </Box>
    )
  }
  return (
    <Box mt="s-32" mb="s-20" ref={ref} {...boxProps} {...autoStepperTitleProps}>
      <Flex alignItems="center" justifyContent="space-between">
        <Text fontWeight="500" use="div" fontSize="h5" color="foreground">
          <Flex alignItems="center">
            {bullet || defaultBullet}
            {title}
          </Flex>
        </Text>
        {rightContent}
      </Flex>
      {subtitle && (
        <Text use="div" mt="s-8" fontSize="caption" color="grey-tone-50">
          {subtitle}
        </Text>
      )}
    </Box>
  )
}

export default NewStepperTitle
