import { EmployeeEmailDirection } from '@src/interfaces/employeeEmails'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { EmailDirection } from '@src/interfaces/hiringProccess'
import { getInitials } from '@src/utils/employees'

interface GetSenderDetailsData {
  sender_employee?: EmployeeOptionInterface
  direction: EmployeeEmailDirection | EmailDirection
  sender_email?: string
  sender_name?: string
  recipient_email: string
  recipient_name?: string
}

export const getSenderDetails = (data: GetSenderDetailsData) => {
  if (data.sender_employee) {
    return {
      name: data.sender_employee.display_name,
      initials: getInitials(data.sender_employee?.display_name || ''),
      email: data.sender_email,
      avatar: data.sender_employee.avatar,
    }
  }

  const name = data.direction === 'sent' ? data.sender_name : data.recipient_name
  const emailAddress =
    data.direction === 'sent' ? data.sender_email : data.recipient_email

  return {
    name,
    initials: getInitials(name || emailAddress || ''),
    email: emailAddress,
    avatar: undefined,
  }
}
