import { FieldOptions, IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { FileInterface } from './files'
import { ApprovalStatuses } from './approvalFlow'

export enum KeyPersonStatus {
  active = 'active',
  approved = 'approved',
  pending_completion = 'pending_completion',
  expired = 'expired',
  cancelled = 'cancelled',
  pending_approval = 'pending_approval',
  rejected = 'rejected',
  archived = 'archived',
}

export interface KeyPersonType {
  id: number
  name: string
  slug: string
  role_type: IdAndName<'risk' | 'compliance'>
  status: IdAndName<KeyPersonStatus>
}

export type KeyPersonResponsibilityStatus = 'active' | 'archived'

export interface KeyPersonResponsibilityInterface {
  id: number
  category: IdAndName<string>
  description: string
  expected_for_specialisations: IdAndName<string>[]
  field_options: FieldOptions
  file_media_type: string
  file_name: string
  name: string
  required_for_entities: IdAndName<string>[]
  role_type: IdAndName<string>
  scope: IdAndName<string>
  status: IdAndName<KeyPersonResponsibilityStatus>
  succession_plan: IdAndName<'optional' | 'mandatory'>
  succession_plan_attachment: File
}

export interface KeyPersonRolesStatsInterface {
  total: number
  active: number
  expired: number
  pending_start: number
  archived: number
}

export interface KeyPersonResponsibilitiesStatsInterface {
  total: number
  active: number
  archived: number
}

export type KeyPersonResponsibilityAssignmentStatus =
  | 'active'
  | 'unassigned'
  | 'archived'
  | 'pending_start'
  | 'expired'

export interface KeyPersonResponsibilityAssignmentInterface {
  id: number
  employee?: EmployeeOptionInterface
  assignment_period: IdAndName<'interim' | 'permanent'>
  assignment_status: IdAndName<KeyPersonResponsibilityAssignmentStatus> | null
  key_person_type: {
    id: number
    name: string
    description: string
  }
  start_date: string | null
  end_date: string
  succession_page_reverse_link: string
  successors: KeyPersonSuccessorInterface[]
  entity: IdAndName
  field_options: FieldOptions
}

export interface KeyPersonResponsibilityAssignmentsStatsInterface {
  active: number
  archived: number
  expired: number
  pending_start: number
  total: number
  unassigned: number
}

export type KeyPersonSuccessionPlanStatus =
  | 'approved'
  | 'expired'
  | 'rejected'
  | 'pending_completion'
  | 'pending_approval'
  | 'complete'
  | 'archived'

export interface KeyPersonSuccessorInterface extends EmployeeOptionInterface {
  specialisation: Pick<
    SpecialisationInterface,
    'id' | 'name' | 'role' | 'owner' | 'status'
  >
}

export type SuccessionStrategy =
  | 'successors_required'
  | 'successors_not_yet_identified'
  | 'successors_not_required'

export interface KeyRiskProfileSuccessionPlanConfigInterface {
  id?: number
  key_risk_profile_succession_plan: {
    id: number
  }
  key_risk_profile: {
    id: number
  }
  succession_strategy: IdAndName<SuccessionStrategy> | null
  successors: KeyPersonSuccessorInterface[]
  successors_order: number[]
}

export interface KeyPersonSuccessionPlanInterface {
  id: number
  employee: EmployeeOptionInterface
  employee_responsibilities_count: string
  file_media_type: string
  file_name: string
  is_mandatory: boolean
  notes: string
  status: IdAndName<KeyPersonSuccessionPlanStatus>
  approval_status: IdAndName<ApprovalStatuses>
  approval_step: IdAndName<string>
  approver: EmployeeOptionInterface | null
  succession_plan_link: string
  succession_plan_type: IdAndName<'optional' | 'mandatory'>
  key_risk_profile_succession_plan_config: KeyRiskProfileSuccessionPlanConfigInterface[]
  update_date_time: string
  valid_until_date_time: string
  expired_days_ago: string
  succession_plan_attachment?: File
  field_options: FieldOptions
}

export interface KeyPersonSuccessionPlansStatsInterface {
  approved: number
  archived: number
  expired: number
  pending_approval: number
  pending_completion: number
  rejected: number
  total: number
}

export interface KeyPersonSuccessionPlanFileInterface {
  id: number
  file: FileInterface
  succession_plan: Partial<KeyPersonSuccessionPlanInterface>
}
