import React, { useEffect, useMemo } from 'react'

import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { roleManagerColumn } from '@src/constants/columns/employee'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/TableV2/hooks'
import { nipsColumn } from '@src/constants/columns/department'
import { TableNames } from '@src/constants/table'
import { RoleInterface } from '@src/interfaces/roles'
import { rolesRequests } from '@src/api/roles'
import { Statuses } from '@src/interfaces'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import {
  roleGenericNameColumn,
  roleFunctionColumn,
  roleHeadcountColumn,
  roleRequisitionsColumn,
  roleStatusColumn,
} from '@src/constants/columns/role'
import { useGetOrganisationSettings } from '@src/api/settings'
import { TableProps } from '@src/pages/Forms/CustomFieldsForm/FilterTable/types'
import { filterChangeHandler } from '@src/pages/Forms/CustomFieldsForm/FilterTable/common'

interface RolesFilterTableProps extends TableProps {
  filter?: FilterByInterface[]
}

export const RolesFilterTable = ({
  isPreview,
  filter,
  onFilterChange,
}: RolesFilterTableProps) => {
  const { data: settings } = useGetOrganisationSettings()

  const tableOptions = {
    disableQuery: true,
  }

  const table = useTable<RoleInterface>(rolesRequests, filter, [], tableOptions)

  const row = useMemo((): RowInterface<RoleInterface> => {
    return {
      linkToForm: ({ id, status }) => {
        if (status === Statuses.draft) {
          return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.GENERAL, { id }))
        }
        return navigateTo(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, { id }))
      },
      cells: [
        {
          ...roleGenericNameColumn,
          width: 220,
        },
        {
          ...roleManagerColumn,
          width: 200,
        },
        {
          ...roleFunctionColumn,
          width: 150,
        },
        {
          ...roleHeadcountColumn,
          width: 100,
        },
        {
          ...roleRequisitionsColumn,
          width: 110,
        },
        {
          ...nipsColumn,
          width: 100,
        },
        settings?.enable_roles_approvals
          ? {
              ...roleStatusColumn,
              width: 130,
            }
          : null,
      ].filter(Boolean),
    }
  }, [settings])

  useEffect(() => {
    onFilterChange?.(filterChangeHandler(table.filterBy))
  }, [table.filterBy])

  return (
    <AdjustableTable
      name={TableNames.DepartmentsFilter}
      row={row}
      {...table}
      noReset={isPreview}
      disabledFiltering={isPreview}
      useWindowScroll
      onFilterChange={f => table?.onFilterChange(f, false)}
    />
  )
}
