import React, { useState } from 'react'
import { Route, Switch, useLocation, useParams } from 'react-router-dom'
import { connect } from 'lape'

import { BarSkeleton, Box, TextSkeleton } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { contractRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import { ContractForm } from './Form/ContractForm'
import { ContractPreview } from './Preview/ContractPreview'
import { ContractChangelog } from './Changelog/ContractChangelog'
import { pathToUrl } from '@src/utils/router'
import { PageHeader, PageHeaderRouterAction } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@components/Page/Page'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { BulkReviewBar } from '@src/pages/Forms/EmployeeContracts/components/BulkReviewBar/BulkReviewBar'
import {
  BulkReviewContractsProps,
  BulkReviewLocationProps,
} from '@src/pages/Forms/EmployeeContracts/components/BulkReviewBar/types'
import { EmployeeContractsInterface } from '@src/interfaces/employees'
import { useContractHeaderProps } from './hooks'
import { LapeFormInterface } from '@src/features/Form/LapeForm'

const EmployeeContracts = () => {
  const params = useParams<{ id?: string; employeeId: string }>()
  const location = useLocation<BulkReviewLocationProps>()

  const [form, setForm] = useState<LapeFormInterface<EmployeeContractsInterface>>()
  // used to force page to rerender after form data updating
  const [, setContractStatus] = useState<string>()

  const [reviewInBulk] = useState<BulkReviewContractsProps | undefined>(
    location.state?.reviewInBulk,
  )
  const [reviewIdx, setReviewIdx] = useState<number>(0)

  const { title, backUrl, isEdit } = useContractHeaderProps(form)

  const isBulkReview = !!reviewInBulk?.length
  const navigateBackToContractsTable = isBulkReview && !isEdit

  const isLoading = form?.loading ?? true

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, params),
      component: ContractPreview,
    },
    {
      title: 'Changelog',
      path: ROUTES.FORMS.EMPLOYEE_CONTRACT.CHANGELOG,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.CHANGELOG, params),
      component: ContractChangelog,
    },
  ]

  const onStatusUpdate = (data: EmployeeContractsInterface) => {
    form?.reset(data)
    setContractStatus(data.contract_status.id)
  }

  return (
    <PageWrapper>
      <PageHeader
        title={isLoading ? <TextSkeleton height={32} width={320} /> : title}
        backUrl={
          navigateBackToContractsTable
            ? `${pathToUrl(ROUTES.APPS.CONTRACTS.ALL)}${location.search}`
            : backUrl
        }
        subtitle={
          isLoading ? (
            <TextSkeleton height={24} width={128} />
          ) : (
            <EmployeeUserWithAvatar id={params.employeeId} />
          )
        }
        hideGlobalSearch={isBulkReview}
        routerAction={
          navigateBackToContractsTable
            ? PageHeaderRouterAction.navigate
            : PageHeaderRouterAction.goBack
        }
        renderAbove={() => (
          <BulkReviewBar
            form={form}
            setForm={setForm}
            reviewInBulk={reviewInBulk}
            reviewIdx={reviewIdx}
            setReviewIdx={setReviewIdx}
            onStatusUpdate={onStatusUpdate}
          />
        )}
      >
        {!isEdit && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </PageHeader>
      {isLoading && <BarSkeleton mt="s-12" />}
      <Form api={contractRequests}>
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component
                isBulkReview={isBulkReview}
                setForm={setForm}
                onStatusUpdate={onStatusUpdate}
              />
            </Route>
          ))}
          <Route exact path={ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL}>
            <ContractForm setForm={setForm} />
          </Route>
        </Switch>
      </Form>
    </PageWrapper>
  )
}

export default connect(EmployeeContracts)
