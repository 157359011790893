import React from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  candidateCVScoreColumn,
  candidateCVScoreDescriptionColumn,
  candidateCVScoreTypeColumn,
} from '@src/constants/columns/candidateCVScore'
import {
  CandidateScoringExplanationInterface,
  CandidateScoringExplanationTableInterface,
} from '@src/interfaces/interviewTool'
import isNumber from 'lodash/isNumber'

export const checkValidArchetype = (
  data?: CandidateScoringExplanationInterface,
): data is CandidateScoringExplanationInterface<number> => {
  return Boolean(
    data &&
      isNumber(data.degree_score.matching_score) &&
      isNumber(data.university_score.matching_score) &&
      isNumber(data.company_score.matching_score) &&
      isNumber(data.job_title_score.matching_score),
  )
}

const Row: RowInterface<CandidateScoringExplanationTableInterface> = {
  cells: [
    { ...candidateCVScoreColumn, width: 50 },
    { ...candidateCVScoreTypeColumn, width: 120 },
    { ...candidateCVScoreDescriptionColumn, width: 130 },
  ],
}

type Props = {
  data: CandidateScoringExplanationInterface
}

export const CandidateMatchScoreTable = ({ data }: Props) => {
  if (!checkValidArchetype(data)) {
    return null
  }

  const tableData: CandidateScoringExplanationTableInterface[] = [
    {
      type: 'Company',
      score: data.company_score.matching_score,
      matches: !!data.company_score.matching_values.length,
    },
    {
      type: 'Role',
      score: data.job_title_score.matching_score,
      matches: !!data.job_title_score.matching_values.length,
    },
    {
      type: 'University',
      score: data.university_score.matching_score,
      matches: !!data.university_score.matching_values.length,
    },
    {
      type: 'Degree',
      score: data.degree_score.matching_score,
      matches: !!data.degree_score.matching_values.length,
    },
  ]
  return (
    <AdjustableTable<CandidateScoringExplanationTableInterface>
      name={TableNames.CandidateScoreExplanation}
      row={Row}
      hideHeader
      data={tableData}
      count={tableData.length}
      mainColumnIndex={1}
    />
  )
}
