import React from 'react'
import { InterviewStageWithoutRoundInterface } from '@src/interfaces/interviewTool'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { Box, Icon, Token, Tooltip, useTooltip } from '@revolut/ui-kit'

type AutomatedStageIndicatorProps = {
  stage: InterviewStageWithoutRoundInterface
}

export const AutomatedStageIndicator = ({ stage }: AutomatedStageIndicatorProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const automaticInterviewScheduling = featureFlags?.includes(
    FeatureFlags.AutomaticInterviewScheduling,
  )
  const tooltip = useTooltip()
  const hasAutomation =
    automaticInterviewScheduling &&
    stage?.automation_status &&
    !stage?.automation_disabled

  if (!hasAutomation) {
    return null
  }
  return (
    <Box>
      <Icon
        {...tooltip.getAnchorProps()}
        name="20/AutoExchange"
        color={Token.color.deepGrey}
      />
      <Tooltip {...tooltip.getTargetProps()}>
        This hiring stage has scheduling automation
      </Tooltip>
    </Box>
  )
}
