import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { Route, Switch } from 'react-router-dom'
import { CustomQuestionsForm } from './CustomQuestions/CustomQuestionsForm'
import { Settings } from './Settings'
import { CustomQuestionsGeneralForm } from './CustomQuestions/CustomQuestionsGeneralForm'

export const ScorecardSetupSwitch = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.PERFORMANCE.SCORECARD_SETUP.CUSTOM_QUESTIONS_GENERAL_FORM}
      >
        <CustomQuestionsGeneralForm />
      </Route>
      <Route exact path={ROUTES.PERFORMANCE.SCORECARD_SETUP.CUSTOM_QUESTIONS_FORM}>
        <CustomQuestionsForm />
      </Route>
      <Route exact path={ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.ANY}>
        <Settings />
      </Route>
    </Switch>
  )
}
