import React, { useEffect, useMemo } from 'react'
import get from 'lodash/get'
import pluralize from 'pluralize'
import { useParams } from 'react-router-dom'
import {
  ActionButton,
  Color,
  MoreBar,
  Text,
  TextButton,
  Token,
  useMoneyFormat,
} from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { getPayCycleEmployeeReportsTableRequests } from '@src/api/payrollV2'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { StatsConfig, useSelectableTableStats } from '@src/components/StatFilters/hooks'
import { StatFilters } from '@src/components/StatFilters/StatFilters'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { useTable } from '@src/components/Table/hooks'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import Tooltip from '@src/components/Tooltip/Tooltip'
import {
  payCycleReportsActionsColumn,
  payCycleReportsChangesColumn,
  payCycleReportsDepartmentColumn,
  payCycleReportsEmployeeNameColumn,
  payCycleReportsGrossSalaryColumn,
  payCycleReportsRoleColumn,
  payCycleReportsStatusColumn,
} from '@src/constants/columns/payCycleReportsV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { AsyncReportExportButton } from '@src/features/PayrollExportReport'
import { RowInterface } from '@src/interfaces/data'
import {
  PayCycleReportStatsInterface,
  PayCycleReportTableItemInterface,
} from '@src/interfaces/payrollV2'
import { pathToUrl } from '@src/utils/router'
import { CommonTabProps, CycleOption } from '../common'

const getRow = (
  onRowClick: (id: number) => void,
  moneyFormat: ReturnType<typeof useMoneyFormat>,
): RowInterface<PayCycleReportTableItemInterface> => ({
  linkToForm: rowData => onRowClick(rowData.id),
  cells: [
    {
      ...payCycleReportsEmployeeNameColumn,
      width: 360,
    },
    {
      ...payCycleReportsGrossSalaryColumn,
      width: 100,
      insert: ({ data }) =>
        data.salary_amount ? (
          <Text>
            {moneyFormat({ amount: data.salary_amount, currency: data.salary_currency })}
          </Text>
        ) : (
          '-'
        ),
    },
    {
      ...payCycleReportsRoleColumn,
      width: 150,
    },
    {
      ...payCycleReportsDepartmentColumn,
      width: 150,
    },
    {
      ...payCycleReportsChangesColumn,
      width: 100,
    },
    {
      ...payCycleReportsStatusColumn,
      width: 100,
    },
    {
      ...payCycleReportsActionsColumn,
      width: 200,
      insert: ({ data }) => {
        return (
          <TextButton
            onClick={() => {
              onRowClick(data.id)
            }}
          >
            Preview details
          </TextButton>
        )
      },
    },
  ],
})

const getOriginalProviderId = (payGroup: CycleOption) => {
  // is needed to avoid TS complaining about inconsistent interfaces
  // since BE API returns data in different format than it accepts (IdAndName on submit / plain id on fetch)
  return get(payGroup, 'pay_group.payroll_provider')
}

const getExportProviderId = (payGroup: CycleOption) => {
  const providerId = getOriginalProviderId(payGroup)

  if (['adp_c2', 'adp_g2'].includes(providerId)) {
    return providerId
  }
  return 'revolut'
}

const successOnZero = (
  count: number | undefined,
  defaultColor: Color = Token.color.greyTone50,
) => (count === 0 ? Token.color.success : defaultColor)

const getStatsConfig = (
  stats: PayCycleReportStatsInterface | undefined,
): StatsConfig<PayCycleReportStatsInterface> => [
  {
    key: 'total_reports',
    title: 'Total',
    filterKey: 'total_reports',
    color: Token.color.greyTone50,
  },
  {
    key: 'total_changes',
    title: pluralize('Change', stats?.total_changes),
    filterKey: 'total_changes',
    color: Token.color.greyTone50,
  },
  {
    key: 'total_issues',
    title: pluralize('Issue', stats?.total_issues),
    filterKey: 'total_issues',
    color: successOnZero(stats?.total_issues),
  },
  {
    key: 'total_criticals',
    title: 'Critical',
    filterKey: 'total_criticals',
    color: successOnZero(stats?.total_criticals, Token.color.danger),
  },
  {
    key: 'total_warnings',
    title: pluralize('Warning', stats?.total_warnings),
    filterKey: 'total_warnings',
    color: successOnZero(stats?.total_warnings, Token.color.warning),
  },
  {
    key: 'total_approved',
    title: 'Approved',
    filterKey: 'total_approved',
    color: stats?.total_approved === 0 ? Token.color.greyTone50 : Token.color.success,
  },
]

type Props = CommonTabProps & {
  onClickReport: (id: number) => void
}
export const PayCycleEmployeeReportsTable = ({ selectedCycle, onClickReport }: Props) => {
  const params = useParams<{ id: string }>()
  const cycleId = selectedCycle?.id || params.id

  const moneyFormat = useMoneyFormat()
  const table = useTable<PayCycleReportTableItemInterface, PayCycleReportStatsInterface>(
    getPayCycleEmployeeReportsTableRequests(cycleId),
  )

  useEffect(() => {
    table.refresh()
  }, [cycleId])

  const statsConfig = useMemo(() => getStatsConfig(table.stats), [table.stats])
  const statFiltersProps = useSelectableTableStats<
    PayCycleReportTableItemInterface,
    PayCycleReportStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
    totalKey: 'total',
  })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters {...statFiltersProps} />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {selectedCycle ? (
            <>
              <AsyncReportExportButton
                payCycleId={selectedCycle.id}
                payrollProvider={getExportProviderId(selectedCycle)}
                isDeel={getOriginalProviderId(selectedCycle) === 'deel'}
              />
              <HideIfCommercial>
                <MoreBar.Action
                  useIcon="BalanceSheet"
                  onClick={() =>
                    navigateTo(
                      pathToUrl(
                        ROUTES.APPS.PAYROLL.PAY_CYCLES_DOWNLOAD_REPORTS_TABLE.DOWNLOAD,
                        { id: selectedCycle.id },
                      ),
                    )
                  }
                >
                  Download reports
                </MoreBar.Action>
              </HideIfCommercial>
            </>
          ) : (
            <Tooltip
              placement="left"
              text="Select an active cycle in the page header to enable downloading"
            >
              <ActionButton useIcon="Download" disabled>
                Download reports
              </ActionButton>
            </Tooltip>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.PayCycleEmployeeReports}
          row={getRow(onClickReport, moneyFormat)}
          useWindowScroll
          emptyState={<EmptyTableRaw title="No employee reports for this cycle" />}
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
