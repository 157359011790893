import {
  roadmapDueDate,
  roadmapEpicUrlColumn,
  roadmapGenericNameColumn,
  roadmapJiraLabelColumn,
  roadmapPriorityColumn,
  roadmapProgressColumn,
  roadmapStartDate,
  roadmapStatusColumn,
  getDeleteRoadmapColumn,
} from '@src/constants/columns/roadmap'
import { RowInterface } from '@src/interfaces/data'
import { RoadmapInterface } from '@src/interfaces/roadmaps'

export const readOnlyRows: RowInterface<RoadmapInterface> = {
  cells: [
    {
      ...roadmapGenericNameColumn,
    },
    {
      ...roadmapPriorityColumn,
      width: 100,
    },
    {
      ...roadmapProgressColumn,
      width: 170,
    },
    {
      ...roadmapStatusColumn,
      width: 120,
    },
    {
      ...roadmapStartDate,
      width: 130,
    },
    {
      ...roadmapDueDate,
      width: 130,
    },
    {
      ...roadmapJiraLabelColumn,
      width: 130,
    },
    {
      ...roadmapEpicUrlColumn,
      width: 120,
    },
  ],
}

export const getEditableRows = (
  handleDelete: (id: number | string) => Promise<void>,
): RowInterface<RoadmapInterface> => {
  return {
    cells: [
      ...readOnlyRows.cells,
      {
        ...getDeleteRoadmapColumn({ handleDelete }),
        width: 70,
      },
    ],
  }
}
