import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { KarmaInterface } from '@src/interfaces/karma'
import { formatNumber, formatPercentage } from '@src/utils/format'
import { A, ColoredText } from '@components/CommonSC/General'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Color, Text, Token } from '@revolut/ui-kit'
import isNil from 'lodash/isNil'

export const karmaCountColumn: ColumnInterface<KarmaInterface | DepartmentInterface> = {
  type: CellTypes.insert,
  idPoint: 'karma',
  dataPoint: 'karma',
  sortKey: 'karma',
  filterKey: 'karma',
  filterType: FilterType.range,
  insert: ({ data }) => {
    let color: Color = Token.color.greyTone20
    if (data.karma < 0) {
      color = Token.color.red
    }
    if (data.karma > 0) {
      color = Token.color.green
    }
    return <ColoredText color={color}>{formatNumber(data.karma)}</ColoredText>
  },
  selectorsKey: selectorKeys.none,
  title: 'Karma',
}

export const karmaPercentColumn: ColumnInterface<KarmaInterface | DepartmentInterface> = {
  type: CellTypes.insert,
  idPoint: 'karma_percentage',
  dataPoint: 'karma_percentage',
  sortKey: 'karma_percentage',
  filterKey: 'karma_percentage',
  filterType: FilterType.percentRange,
  insert: ({ data }) => {
    let color: Color = Token.color.green
    if (isNil(data.karma_percentage)) {
      color = Token.color.greyTone20
    } else if (data.karma_percentage < 0.5) {
      color = Token.color.red
    }
    return (
      <Text color={color}>
        {data?.karma_percentage ? formatPercentage(data?.karma_percentage) : '-'}
      </Text>
    )
  },
  selectorsKey: selectorKeys.none,
  title: 'Karma',
}

export const karmaTeamColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'team__name',
  filterKey: 'team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const karmaOwnerColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.text,
  idPoint: 'responsible_name',
  dataPoint: 'responsible_name',
  sortKey: 'responsible_name',
  filterKey: null,
  selectorsKey: selectorKeys.employee,
  title: 'Responsible',
}

export const karmaSummaryColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.text,
  idPoint: 'summary',
  dataPoint: 'summary',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
}

export const karmaCreatedAtColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.date,
  idPoint: 'issue_date_time',
  dataPoint: 'issue_date_time',
  sortKey: 'issue_date_time',
  filterKey: 'issue_date_time',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Issue Created at',
}

export const karmaIssueIdColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.insert,
  idPoint: 'issue_id',
  dataPoint: 'issue_id',
  sortKey: 'issue_id',
  filterKey: null,
  insert: ({ data }) => (
    <A href={data.issue_url} target="_blank">
      {data.issue_id}
    </A>
  ),
  selectorsKey: selectorKeys.none,
  title: 'Issue Id',
}

export const karmaSourceColumn: ColumnInterface<KarmaInterface> = {
  type: CellTypes.text,
  idPoint: 'source',
  dataPoint: 'source',
  sortKey: 'source',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Source',
}
