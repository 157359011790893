import { Grid } from '@components/CommonSC/Grid'
import React from 'react'
import ThumbsButton from '@components/ThumbsButtons/ThumbsButton'
import { ThumbsOptions } from '@src/interfaces/interviewTool'
import { useLapeField } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { ExclamationMarkOutline } from '@revolut/icons'
import { Absolute } from '@revolut/ui-kit'

interface Props {
  name: string
  readOnly?: boolean
}

const ThumbsButtons = ({ name, readOnly }: Props) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, hidden, disabled } = lapeProps
  const formValidator = useFormValidator()

  if (hidden) {
    return null
  }

  const onClick = (variant: ThumbsOptions) => {
    onChange(variant === value ? '' : variant)
  }

  const commonProps = getCommonInputProps({ name }, lapeProps, !!formValidator?.validated)

  return (
    <Grid
      flow="column"
      gap={8}
      data-name={commonProps['data-name']}
      style={{ position: 'relative' }}
    >
      <ThumbsButton
        value={ThumbsOptions.Yes}
        onClick={onClick}
        selected={value === ThumbsOptions.Yes}
        disabled={readOnly || disabled}
        data-testid={`thumb-${ThumbsOptions.Yes}`}
      />
      <ThumbsButton
        value={ThumbsOptions.YesWithHelp}
        onClick={onClick}
        selected={value === ThumbsOptions.YesWithHelp}
        disabled={readOnly || disabled}
        data-testid={`thumb-${ThumbsOptions.YesWithHelp}`}
      />
      <ThumbsButton
        value={ThumbsOptions.No}
        onClick={onClick}
        selected={value === ThumbsOptions.No}
        disabled={readOnly || disabled}
        data-testid={`thumb-${ThumbsOptions.No}`}
      />
      {commonProps.hasError && (
        <Absolute right="-s-48" top="s-6">
          <ExclamationMarkOutline color="red" size={16} />
        </Absolute>
      )}
    </Grid>
  )
}

export default ThumbsButtons
