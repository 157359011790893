import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { FinalGradeInterface } from '@src/interfaces/performance'
import { getColorsAndGrade, getInverseColorsAndGrade } from '@src/utils/grades'

type Props = {
  grade: FinalGradeInterface | null
  inverse?: boolean
  className?: string
  withPending?: boolean
}

export const PerformanceGradeRevamped = ({
  grade,
  inverse,
  className,
  withPending,
}: Props) => {
  const data = inverse ? getInverseColorsAndGrade(grade) : getColorsAndGrade(grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Token.color.greyTone20}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text className={className} backgroundColor={data.background} color={data.color}>
      {grade?.label}
    </Text>
  )
}
