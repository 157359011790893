import { VStack, Box } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import SectionTitle from '../components/SectionTitle'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { TimelinePreviewSectionInterface, WithTimelinePreview } from './common'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { RecommendedLabel } from '../components/Recommended'

export const PerformanceTimeline = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const sections = useMemo<TimelinePreviewSectionInterface[]>(
    () =>
      [
        {
          label: 'Goal setting',
          description: '31 days',
          state: 'done',
          setting: 'enable_kpi_setting_dates' as keyof PerformanceSettingsInterface,
        },
        {
          label: 'Performance review assessment',
          description: '17 days',
          state: 'pending',
        },
        {
          label: 'Calibration',
          description: '5 days',
          setting: 'enable_calibration' as keyof PerformanceSettingsInterface,
        },
        {
          label: 'Publish results to managers',
          description: '1 day',
          setting:
            'publish_grades_to_managers_before_employees' as keyof PerformanceSettingsInterface,
        },
        { label: 'Publish results to employees', description: '1 day' },
      ]
        .map(({ setting, ...section }) =>
          setting ? values[setting] && section : section,
        )
        .filter(Boolean),
    [
      values.enable_calibration,
      values.enable_kpi_setting_dates,
      values.publish_grades_to_managers_before_employees,
    ],
  )
  return (
    <WithTimelinePreview sections={sections}>
      <VStack gap="s-16">
        <Box>
          <SectionTitle
            title="Do you want a define a fixed goal setting period for your organisation?"
            subtitle="If enabled, goal setting dates will be defined prior to the cycle. Otherwise goals can be set at any time."
          />
          <LapeBooleanRadioSwitch
            yesLabel={<RecommendedLabel label="Yes" />}
            highlightSelected
            variant="horizontal"
            name="enable_kpi_setting_dates"
            yesDescription="I want to establish a specific period for setting goals"
            noDescription="I would like to allow flexibility in goal-setting periods"
          />
        </Box>
        <Box>
          <SectionTitle
            title="Do you want to calibrate the performance results prior to publishing?"
            subtitle="If enabled, calibration of final performance grades can occur at department and function level by their owners."
          />
          <LapeBooleanRadioSwitch
            yesLabel={<RecommendedLabel label="Yes" />}
            highlightSelected
            variant="horizontal"
            name="enable_calibration"
            yesDescription="I want to calibrate the performance results for accuracy before publishing"
            noDescription="I would like to publish the performance results without calibration"
          />
        </Box>
        <Box>
          <SectionTitle
            title="Do you want to publish the performance results to managers before all employees?"
            subtitle="If enabled, final grades will be available to the managers of reports, and published to reports on a separate date."
          />
          <LapeBooleanRadioSwitch
            yesLabel={<RecommendedLabel label="Yes" />}
            highlightSelected
            variant="horizontal"
            name="publish_grades_to_managers_before_employees"
            yesDescription="I want to share performance results with managers first"
            noDescription="I want to share performance results for everyone at the same time"
          />
        </Box>
      </VStack>
    </WithTimelinePreview>
  )
}
