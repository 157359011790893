import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

// Left until full cleanup in case of urgent need to switch back to the old view
export const useIsNewLayout = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  return !featureFlags.includes(FeatureFlags.EmployeeProfileOldLayout)
}
