import React from 'react'
import { Box, Token, VStack, Text, Icon, Badge, Flex, opacity } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { NameIdInterface } from '@src/interfaces/employees'

export const NoPersonalGoalsBanner = ({
  team,
  isPerformanceBanner,
}: {
  team?: NameIdInterface
  isPerformanceBanner?: boolean
}) => {
  const teamName = team?.name ? (
    <Badge
      size={24}
      bg={Token.color.greyTone2}
      mt="s-8"
      maxWidth="fit-content"
      style={{ textDecoration: 'none' }}
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
        id: team.id,
      })}
      target="_blank"
    >
      <Flex gap="s-4" alignItems="center" mx="s-4">
        <Icon name="People" size={12} color={Token.color.foreground} />
        <Text
          color={opacity(Token.colorChannel.foreground, 0.8)}
          variant="tiny"
          fontWeight={600}
        >
          {team.name}
        </Text>
      </Flex>
    </Badge>
  ) : null

  return (
    <Box px={isPerformanceBanner ? 's-16' : ''}>
      <Box p="s-16" border={`1px solid ${Token.color.greyTone10}`} borderRadius={16}>
        <VStack>
          <Text variant="primary">This employee has not been assigned any goals</Text>
          <Text variant="secondary" color={Token.color.greyTone50}>
            {isPerformanceBanner
              ? "Assessment is based on their contribution to the Team's Goals."
              : "Please provide your assessment based on their contribution to the Team's Goals."}
          </Text>
          {teamName}
        </VStack>
      </Box>
    </Box>
  )
}
