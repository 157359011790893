import React from 'react'
import { useSelector } from 'react-redux'

import { DepartmentCompensationReviewInterface } from '@src/interfaces/compensation'
import {
  departmentCompensationBonusColumn,
  departmentCompensationBudgetColumn,
  departmentCompensationPoolColumn,
  departmentCompensationHeadcountColumn,
  departmentCompensationOwnerColumn,
  departmentCompensationTypeColumn,
  departmentCompensationUploadActionColumn,
  departmentCompensationCommentsColumn,
} from '@src/constants/columns/compensation'
import { RowInterface } from '@src/interfaces/data'
import { BudgetTable } from './BudgetTable'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

const getRow =
  (canImport: boolean) =>
  (currency: string): RowInterface<DepartmentCompensationReviewInterface> => {
    return {
      cells: [
        {
          ...departmentCompensationPoolColumn,
          width: 200,
        },
        {
          ...departmentCompensationTypeColumn,
          width: 120,
        },
        {
          ...departmentCompensationOwnerColumn,
          width: 140,
        },
        {
          ...departmentCompensationHeadcountColumn,
          width: 100,
        },
        {
          ...departmentCompensationBudgetColumn(currency),
          width: 150,
        },
        {
          ...departmentCompensationBonusColumn(currency),
          width: 150,
        },
        {
          ...departmentCompensationCommentsColumn,
          width: 100,
        },
        canImport
          ? {
              ...departmentCompensationUploadActionColumn,
              width: 100,
            }
          : null,
      ].filter(Boolean),
    } as RowInterface<DepartmentCompensationReviewInterface>
  }

export const Compensation = () => {
  const permissions = useSelector(selectPermissions)

  const canImport = permissions.includes(
    PermissionTypes.AddEmployeeCompensationReviewBulkUploadSession,
  )

  return <BudgetTable getRow={getRow(canImport)} />
}

export default Compensation
