import { Badge, Box, Group, Subheader, Text, Token } from '@revolut/ui-kit'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import React from 'react'

interface Props {
  hasFunctionalManagement: boolean
}

export const ReviewTypes = ({ hasFunctionalManagement }: Props) => {
  return (
    <>
      <Box>
        <Subheader>
          <Subheader.Title>Review types</Subheader.Title>
        </Subheader>
        <Text variant="caption" color={Token.color.greyTone50}>
          Based on your selection, we will create a separate Scorecard for each review
          type.
        </Text>
      </Box>
      <Group>
        <LapeNewSwitch
          itemTypeProps={{
            title: <RequiredLabel title="Self review" />,
            description:
              'Allows the employee to submit a review of their own performance and behaviours',
            image: {
              default: `https://assets.revolut.com/assets/3d-images-v2/3D259.png`,
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
            },
          }}
          checked
          disabled
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: <RequiredLabel title="Line manager review" />,
            description:
              'Review feedback from line managers are the foundation of the performance result',
            image: {
              default: `https://assets.revolut.com/assets/3d-images-v2/3D183.png`,
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D183@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D183@3x.png',
            },
          }}
          checked
          disabled
        />
        {hasFunctionalManagement && (
          <LapeNewSwitch
            itemTypeProps={{
              title: 'Functional manager review',
              description:
                'A functional manager(FM) is a person with the same skills but a higher seniority compared to the person who is being mentored. When enabled the FM will have to review the mentee during all types of performance cycles.',
              image: {
                default: `https://assets.revolut.com/assets/3d-images-v2/3D261.png`,
                '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D261@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D261@3x.png',
              },
            }}
            name="enable_functional_manager_reviews"
          />
        )}
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Upwards reviews',
            description:
              'Direct and functional reports are allowed to submit anonymous reviews of their managers',
            image: {
              default: `https://assets.revolut.com/assets/3d-images-v2/3D266.png`,
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D266@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D266@3x.png',
            },
          }}
          name="enable_upwards_reviews"
        />
        <LapeNewSwitch
          itemTypeProps={{
            title: 'Peer reviews',
            description: 'Allow peers to review each other',
            image: {
              default: `https://assets.revolut.com/assets/3d-images-v2/3D259.png`,
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D259@3x.png',
            },
          }}
          name="enable_peer_reviews"
        />
      </Group>
    </>
  )
}

interface RequiredLabelProps {
  title: string
}

const RequiredLabel = ({ title }: RequiredLabelProps) => (
  <>
    <Text>{title}</Text>
    <Badge
      useIcon="ChangePasscode"
      ml="s-12"
      bg={Token.color.foreground}
      color={Token.color.background}
    >
      <Text pl="s-4">Required</Text>
    </Badge>
  </>
)
