import { Absolute, Text, Color } from '@revolut/ui-kit'
import React from 'react'

interface PlaceholderProps {
  text?: string
}

export const Placeholder = ({ text }: PlaceholderProps) => {
  return (
    <Absolute top={12} left={12} css={{ pointerEvents: 'none', userSelect: 'none' }}>
      <Text fontSize="16px" color={Color.GREY_TONE_50}>
        {text || 'Enter some text...'}
      </Text>
    </Absolute>
  )
}
