import { useGetPerformanceSelector } from '@src/api/performance'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetCycleSelector } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { useMemo } from 'react'

export const useGetCyclesSelector = (entity: OrgEntityInterface) => {
  const { data: cycles } = useGetPerformanceSelector(entity.data.id)
  const cycleSelector = useGetCycleSelector(cycles)
  const initialCycle = cycles?.find(item => item.offset === 0)

  return useMemo(
    () => ({
      initialCycle,
      cycleSelector,
      getCycleById: (id: string) =>
        cycles?.find(cycle => String(cycle.id) === String(id)),
    }),
    [initialCycle, cycleSelector, cycles],
  )
}
