import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  CXComplaintsInterface,
  CXCsatDetractorsInterface,
  CXSupportLoadInterface,
} from '@src/interfaces/businessDebts'
import { AuditBugCountsInterface, AuditInterface } from '@src/interfaces/audit'
import { API } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'

export const getCXComplaints = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<CXComplaintsInterface>> =>
  api.get(`/cx/complaints`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getCXCsatDetractors = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<CXCsatDetractorsInterface>
> =>
  api.get(`/cx/csatDetractors`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getCXSupportLoad = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<CXSupportLoadInterface>> =>
  api.get(`/cx/supportLoad`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getDepartmentBugTickets =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${API.DEPARTMENT}/${id}/cxIssues`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useDepartmentBugCountsTickets = (id: number | undefined) => {
  return useFetch<AuditBugCountsInterface>(
    id ? `${API.DEPARTMENT}/${id}/cxIssues/counts` : null,
    'v1',
    undefined,
    true,
  )
}

export const getTeamBugTickets =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${API.TEAMS}/${id}/cxIssues`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useTeamBugCountsTickets = (id: number | undefined) => {
  return useFetch<AuditBugCountsInterface>(
    id ? `${API.TEAMS}/${id}/cxIssues/counts` : null,
    'v1',
    undefined,
    true,
  )
}

export const getDepartmentCXBugTickets =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${API.DEPARTMENT}/${id}/cxBugs`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useDepartmentCXBugCountsTickets = (id: number | undefined) => {
  return useFetch<AuditBugCountsInterface>(
    id ? `${API.DEPARTMENT}/${id}/cxBugs/counts` : null,
    'v1',
    undefined,
    true,
  )
}

export const getTeamCXBugTickets =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${API.TEAMS}/${id}/cxBugs`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useTeamCXBugCountsTickets = (id: number | undefined) => {
  return useFetch<AuditBugCountsInterface>(
    id ? `${API.TEAMS}/${id}/cxBugs/counts` : null,
    'v1',
    undefined,
    true,
  )
}
