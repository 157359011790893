import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'

export const getMarkActionSuccessMessage = (action: GrowthPlanActionInterface) =>
  `Successfully ${action.is_completed ? 'unmarked' : 'completed'} action`

export const getMarkActionErrorMessage = (action: GrowthPlanActionInterface) =>
  `Failed to ${action.is_completed ? 'unmark' : 'complete'} action`

export const getPopupTitle = (action: GrowthPlanActionInterface) =>
  action.is_completed ? 'Unmark action' : 'Mark as done'

export const getPopupDescription = (action: GrowthPlanActionInterface) =>
  action.is_completed
    ? 'Please provide supporting evidence or documentation for unmarking to-do item. This may include a brief explanation, links to relevant resources, or documents'
    : 'Please provide supporting evidence or documentation for completed to-do item. This may include a brief explanation, links to relevant resources, or documents'

export const getPopupConfirmLabel = (action: GrowthPlanActionInterface) =>
  action.is_completed ? 'Unmark' : 'Mark as done'
