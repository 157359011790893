import React, { useState } from 'react'
import HTMLEditor, { DEFAULT_MODULES } from '@components/HTMLEditor/HTMLEditor'
import {
  Box,
  Ellipsis,
  Flex,
  HStack,
  IconButton,
  Input,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'
import DOMPurify from 'dompurify'
import { css } from 'styled-components'
import { MetricsGridHandle } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/components/MetricsGrid'

interface TextItemProps {
  id: string
  html?: string
  header?: string
  updateData: (id: string, content: string, header: string) => void
  parentRef: React.RefObject<MetricsGridHandle>
}

const sectionCss = css`
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${Token.color.foreground};
  }

  p {
    margin: 16px 0;
  }

  ol,
  ul {
    padding: 0 0 0 20px;
    margin: 0;
  }
`

export const TextItem = ({
  id,
  html,
  updateData,
  parentRef,
  header = '',
}: TextItemProps) => {
  const [isEditing, setIsEditing] = useState(true)

  return (
    <Box overflow="auto" height="100%">
      <Flex alignItems="center" pb="s-8">
        {isEditing ? (
          <Input
            style={{ flexGrow: 1 }}
            label="Header (optional)"
            value={header}
            onChange={e => {
              const v = e.currentTarget.value
              if (v !== header) {
                updateData(id, v || '', html || '')
              }
            }}
          />
        ) : (
          <Ellipsis style={{ flexGrow: 1 }}>
            <Text variant="tile" use="div" textAlign="center">
              {header}
            </Text>
          </Ellipsis>
        )}
        <HStack align="center" space="s-16" width={85} ml="s-12">
          {isEditing ? (
            <TextButton onClick={() => setIsEditing(false)}>Done</TextButton>
          ) : (
            <IconButton
              onClick={() => setIsEditing(true)}
              useIcon="Pencil"
              aria-label="Edit text card"
              color={Token.color.accent}
              size={16}
              tapArea={32}
            />
          )}
          <IconButton
            onClick={() => {
              parentRef.current?.remove(id)
            }}
            useIcon="Delete"
            aria-label="Delete text card"
            color={Token.color.accent}
            size={16}
            tapArea={32}
          />
        </HStack>
      </Flex>
      <Box height="calc(100% - 32px)">
        {isEditing ? (
          <HTMLEditor
            value={html}
            placeholder="Dashboard description"
            onChange={v => {
              if (v !== html) {
                updateData(id, header || '', v || '')
              }
            }}
            fontSize="14px"
            modules={DEFAULT_MODULES}
            greyBg
            height={180}
          />
        ) : (
          <Text
            css={sectionCss}
            use="pre"
            textAlign="justify"
            whiteSpace="pre-wrap"
            variant="caption"
            color={Token.color.foreground}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(html || ''),
            }}
          />
        )}
      </Box>
    </Box>
  )
}
