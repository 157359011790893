import { ContentBlockLayout } from '@src/pages/Landing/components/ContentBlockLayout'
import { Box, Flex } from '@revolut/ui-kit'
import { FooterLink } from '@src/pages/Landing/components/FooterLink'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { Text } from '@src/pages/Landing/components/Text'
import React from 'react'

export const Footer = () => {
  return (
    <ContentBlockLayout>
      <Box borderTop="1px solid" borderTopColor="white-10" pt="s-32">
        <Flex flexWrap="wrap" columnGap="s-32" rowGap="s-8" pb="s-32">
          <FooterLink
            href={pathToUrl(PUBLIC_ROUTES.TERMS_AND_CONDITIONS)}
            target="_blank"
          >
            Website terms
          </FooterLink>
          <FooterLink href={PUBLIC_ROUTES.COMPLAINTS} target="_blank">
            Complaints
          </FooterLink>
          <FooterLink href={PUBLIC_ROUTES.PRIVACY_NOTICE} target="_blank">
            Privacy
          </FooterLink>
          <FooterLink
            href="https://www.revolut.com/legal/modern-slavery-statement#:~:text=We%20oppose%20all%20forms%20of,sex%20trafficking%2C%20and%20unpaid%20labour./"
            target="_blank"
          >
            UK Modern Slavery Policy
          </FooterLink>
        </Flex>
        <Text color="grey-tone-50" variant="small">
          © Revolut Ltd 2024
          <br />
          <br />
          If you would like to find out more about which Revolut entity you receive
          services from, or if you have any other questions, please reach out to us via
          the in-app chat in the Revolut app. Revolut Ltd (No. 08804411) is authorised by
          the Financial Conduct Authority under the Electronic Money Regulations 2011
          (Firm Reference 900562). Registered address: 7 Westferry Circus, Canary Wharf,
          London, England, E14 4HD. Insurance related-products are arranged by Revolut
          Travel Ltd which is authorised by the Financial Conduct Authority to undertake
          insurance distribution activities (FCA No: 780586) and by Revolut Ltd, an
          Appointed Representative of Revolut Travel Ltd in relation to insurance
          distribution activities. Trading and investment products are provided by Revolut
          Trading Ltd (No. 832790) is wholly owned subsidiary of Revolut Ltd and is an
          appointed representative of Resolution Compliance Ltd which is authorised and
          regulated by the Financial Conduct Authority.
          <br />
          <br />
          We are also registered with the Financial Conduct Authority to offer
          cryptocurrency services under the Money Laundering, Terrorist Financing and
          Transfer of Funds (Information on the Payer) Regulations 2017.
          <br />
          <br />
          Revolut's commodities service is not regulated by the FCA.
        </Text>
      </Box>
    </ContentBlockLayout>
  )
}
