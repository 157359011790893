import React from 'react'
import { StatusWidget } from '@revolut/ui-kit'

export const StepCompletedWidget = () => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D151A.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@3x.png',
        }}
      />
      <StatusWidget.Title>Step completed</StatusWidget.Title>
    </StatusWidget>
  )
}
