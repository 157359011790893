import React, { useState } from 'react'
import { HireInterface } from '@src/interfaces/requisitions'
import { IdStatuses } from '@src/interfaces/employees'
import { Button, TextButton, Token, useStatusPopup } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { FormattedMessage } from 'react-intl'
import { cancelRequisitionHire } from '@src/api/requisitions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

type CancelHireActionProps = {
  hire: HireInterface
  onRefresh: () => void
}

export const CancelHireAction = ({ hire, onRefresh }: CancelHireActionProps) => {
  const [pending, setPending] = useState(false)
  const statusPopup = useStatusPopup()
  const showStatusPopup = useShowStatusPopup()
  const showCancelHire = [IdStatuses.hired, IdStatuses.pending].includes(hire.status)
  if (!showCancelHire) {
    return null
  }
  const showSuccessPopup = () => {
    showStatusPopup({
      title: <FormattedMessage id="common.success" defaultMessage="Success" />,
      description: (
        <FormattedMessage
          id="recruitment.hires.cancelHire.successDescription"
          defaultMessage="Candidate removed from this requisition"
        />
      ),
    })
  }
  const showErrorPopup = (description?: string) => {
    showStatusPopup({
      title: (
        <FormattedMessage
          id="recruitment.hires.cancelHire.errorTitle"
          defaultMessage="There was an error"
        />
      ),
      description: description || <FormattedMessage id="label.failed-state" />,
      status: 'error',
    })
  }
  const handleCancelHireAction = async () => {
    setPending(true)
    try {
      await cancelRequisitionHire(hire.id, hire.requisition.id)
      showSuccessPopup()
    } catch (e) {
      showErrorPopup(getStringMessageFromError(e))
    } finally {
      setPending(false)
      onRefresh()
    }
  }
  const showConfirmationPopup = () => {
    showStatusPopup({
      title: <FormattedMessage id="common.areYouSure" defaultMessage="Are you sure?" />,
      description: (
        <FormattedMessage
          id="recruitment.hires.cancelHire.confirmation"
          defaultMessage="This action will remove this candidate from the requisition and free up the headcount. Please make sure that the offer was cancelled and that the candidate won’t be joining the company under the current requisition"
        />
      ),
      actions: (
        <>
          <Button variant="secondary" onClick={statusPopup.hide}>
            Back
          </Button>
          <Button onClick={handleCancelHireAction} aria-label="Confirm cancel hire">
            Cancel hire
          </Button>
        </>
      ),
      status: 'warning',
    })
  }

  return (
    <>
      <TextButton
        color={Token.color.blue}
        disabled={pending}
        onClick={e => {
          e.stopPropagation()
          showConfirmationPopup()
        }}
      >
        Cancel hire
      </TextButton>
    </>
  )
}
