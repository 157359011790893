import React from 'react'
import { MoreBar, Text } from '@revolut/ui-kit'
import PermissionTransferButton from '@src/pages/PermissionTransfer/Button/PermissionTransferButton'
import { ROUTES } from '@src/constants/routes'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { CandidateInterface } from '@src/interfaces/interviewTool'

type Props = {
  candidate: CandidateInterface
}

const ConfidentialCandidateWidget = ({ candidate }: Props) => {
  return (
    <ActionWidget
      avatarColor="grey-tone-50"
      title="This is a confidential candidate"
      text={
        <>
          <Text mb="s-8" use="div">
            Only limited users will have access to this candidate. To share this profile
            with someone else, use manage permissions flow.
          </Text>
        </>
      }
    >
      <MoreBar>
        <PermissionTransferButton
          values={candidate}
          path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CANDIDATE}
        />
      </MoreBar>
    </ActionWidget>
  )
}

export default ConfidentialCandidateWidget
