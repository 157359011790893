import React from 'react'
import { Box, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { formatMoney } from '@src/utils/format'

interface TooltipValueInterface {
  color?: string
  label: string
  value: string
}

export const BarChartTooltip = (
  props: any,
  dataKeys?: DataKey<any>[],
  colors?: string[],
) => {
  const values = dataKeys?.reduce(
    (acc: TooltipValueInterface[], dataKey, currentIndex) => {
      if (Number(props[dataKey.value])) {
        acc.push({
          color: colors?.[currentIndex],
          label: dataKey.label,
          value: props[dataKey.value],
        })
      }
      return acc
    },
    [],
  )

  return (
    <VStack space="s-4">
      {props.total && (
        <Flex justifyContent="space-between">
          <Text color={Token.color.background} pr="s-16" variant="emphasis2">
            {props.year}
          </Text>
          <Text color={Token.color.background} variant="emphasis2">
            {formatMoney(Number(props.total), props.currency.iso_code)}
          </Text>
        </Flex>
      )}

      {dataKeys &&
        values?.map((value, id) => (
          <Flex key={id} alignItems="center" justifyContent="flex-start" gap="s-6">
            {value?.color ? (
              <Box
                bg={value.color}
                border={`1px solid ${Token.color.greyTone50}`}
                borderRadius={Token.radius.r2}
                height="s-12"
                width="s-12"
              />
            ) : null}
            <Text color={Token.color.background} variant="body3" use="div">
              {value.label}:
            </Text>
            <Text color={Token.color.background} ml="auto" variant="body3" use="div">
              {formatMoney(Number(value.value), props.currency.iso_code)}
            </Text>
          </Flex>
        ))}
    </VStack>
  )
}
