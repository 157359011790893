import React from 'react'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

type Props = {
  flags?: FeatureFlags[]
  children: React.ReactNode
}

export const RequiresFeatureFlagWrapper = ({ flags, children }: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)

  // we show the children if flags - undefined or it includes at least 1 flag
  if (!flags || flags.some(flag => featureFlags?.includes(flag))) {
    return <>{children}</>
  }

  return null
}
