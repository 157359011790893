import React from 'react'
import { Table, Token, Text } from '@revolut/ui-kit'
import { GradesMapInterface } from '@src/utils/grades'

const TableCell = ({
  value,
  color,
  background,
}: {
  value: string
  color?: string
  background?: string
}) => {
  const getColor = () => {
    switch (value) {
      case 'Unsatisfactory':
      case 'Avg -':
        return Token.color.danger
      case 'Strong':
      case 'Exceptional':
        return Token.color.success
      default:
        return Token.color.foreground
    }
  }
  return (
    <Table.Cell bg={background} borderColor={background}>
      <Text
        variant="small"
        color={color || getColor()}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
      >
        {value}
      </Text>
    </Table.Cell>
  )
}

type CellProps = { row: { values: typeof data[0] } }

export const columns = [
  {
    Header: '',
    accessor: 'hofGrade',
    width: 70,
    Cell: ({ row }: CellProps) => (
      <TableCell
        value={row.values.hofGrade}
        color={Token.color.foreground}
        background={Token.color.greyTone5}
      />
    ),
  },
  {
    Header: 'Unsatisfactory',
    accessor: 'unsatisfactory',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.unsatisfactory} />,
    width: 70,
  },
  {
    Header: 'Developing',
    accessor: 'averageMinus',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.averageMinus} />,
    width: 70,
  },
  {
    Header: 'Performing',
    accessor: 'averagePlus',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.averagePlus} />,
    width: 70,
  },
  {
    Header: 'Exceeding',
    accessor: 'strong',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.strong} />,
    width: 70,
  },
  {
    Header: 'Exceptional',
    accessor: 'exception',
    Cell: ({ row }: CellProps) => <TableCell value={row.values.exception} />,
    width: 70,
  },
]

export const data = [
  {
    hofGrade: 'Unsatisfactory',
    unsatisfactory: 'Unsatisfactory',
    averageMinus: 'Avg -',
    averagePlus: 'Avg -',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Average -',
    unsatisfactory: 'Unsatisfactory',
    averageMinus: 'Avg -',
    averagePlus: 'Avg +',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Average +',
    unsatisfactory: 'Avg -',
    averageMinus: 'Avg -',
    averagePlus: 'Avg +',
    strong: 'Avg +',
    exception: 'Avg +',
  },
  {
    hofGrade: 'Strong',
    unsatisfactory: 'Avg -',
    averageMinus: 'Avg -',
    averagePlus: 'Strong',
    strong: 'Strong',
    exception: 'Strong',
  },
  {
    hofGrade: 'Exceptional',
    unsatisfactory: 'Avg -',
    averageMinus: 'Avg -',
    averagePlus: 'Strong',
    strong: 'Strong',
    exception: 'Exceptional',
  },
]

export const getData = (gradesMap: GradesMapInterface) => [
  {
    hofGrade: gradesMap.unsatisfactory,
    unsatisfactory: gradesMap.unsatisfactory,
    averageMinus: gradesMap.average_minus,
    averagePlus: gradesMap.average_minus,
    strong: gradesMap.average_minus,
    exception: gradesMap.average_minus,
  },
  {
    hofGrade: gradesMap.average_minus,
    unsatisfactory: gradesMap.unsatisfactory,
    averageMinus: gradesMap.average_minus,
    averagePlus: gradesMap.average_minus,
    strong: gradesMap.average_minus,
    exception: gradesMap.average_minus,
  },
  {
    hofGrade: gradesMap.average_plus,
    unsatisfactory: gradesMap.unsatisfactory,
    averageMinus: gradesMap.average_plus,
    averagePlus: gradesMap.average_plus,
    strong: gradesMap.average_plus,
    exception: gradesMap.average_plus,
  },
  {
    hofGrade: gradesMap.strong,
    unsatisfactory: gradesMap.unsatisfactory,
    averageMinus: gradesMap.strong,
    averagePlus: gradesMap.strong,
    strong: gradesMap.strong,
    exception: gradesMap.strong,
  },
  {
    hofGrade: gradesMap.exceptional,
    unsatisfactory: gradesMap.unsatisfactory,
    averageMinus: gradesMap.exceptional,
    averagePlus: gradesMap.exceptional,
    strong: gradesMap.exceptional,
    exception: gradesMap.exceptional,
  },
]
