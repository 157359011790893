import React from 'react'
import { Flex, Icon, Text, Token } from '@revolut/ui-kit'

import Tooltip from '@components/Tooltip/Tooltip'

interface Props {
  children: React.ReactNode
  error?: string
}

const KPINameWithSQLError = ({ children, error }: Props) => {
  if (error) {
    return (
      <Tooltip placement="top" text={error}>
        <Flex alignItems="center" width="100%">
          <Flex flex="0 0 auto">
            <Icon name="InfoOutline" size={14} color={Token.color.red} />
          </Flex>
          <Text pl="s-4">{children}</Text>
        </Flex>
      </Tooltip>
    )
  }
  return <>{children}</>
}

export default KPINameWithSQLError
