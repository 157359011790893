import React from 'react'
import CommentsSection from '@src/features/Comments/CommentsSection'
import { getCandidateComments } from '@src/api/hiringProcess'

export type CommentsProps = {
  roundId?: number
  refreshStats: () => void
  onClickSeeAll?: () => void
}

export const Comments = ({ roundId, refreshStats, onClickSeeAll }: CommentsProps) => {
  if (!roundId) {
    return null
  }

  const commentsApi = getCandidateComments(roundId)
  return (
    <CommentsSection
      onRefetch={refreshStats}
      api={commentsApi}
      onSideOpen={onClickSeeAll}
    />
  )
}
