import React from 'react'
import { TimelineEventInterface } from '@src/interfaces/timeline'
import ChatMessagePreview from '@components/ColumnInserts/ChatMessagePreview/ChatMessagePreview'
import { Box, Text, Token } from '@revolut/ui-kit'
import { css } from 'styled-components'

type Props = {
  event: TimelineEventInterface
}

const quillCss = css`
  .ql-editor {
    font-size: 14px;
    color: ${Token.color.greyTone50};
  }
`

const TimelineItemContent = ({ event }: Props) => {
  switch (event.category) {
    case 'comment_added':
      return (
        <Box css={quillCss}>
          <ChatMessagePreview
            text={event.summary}
            taggedEmployees={event.object_info.tagged_employees}
          />
        </Box>
      )

    default:
      return (
        <Text use="div" whiteSpace="pre-line">
          {event.summary}
        </Text>
      )
  }
}

export default TimelineItemContent
