import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { navigateReplace } from '@src/actions/RouterActions'
import { successNotification } from '@src/actions/NotificationActions'
import { pathToUrl } from '@src/utils/router'
import LapeSubmitPopups from '@src/features/Popups/LapeSubmitPopups'
import Button from '@components/Button/Button'
import { SumbitButtonWrapper } from '@src/pages/Forms/FormButtons'
import { RoleInterface } from '@src/interfaces/roles'
import { Button as NewButton } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface RoleSubmitButtonProps {
  isNew?: boolean
  notification?: {
    path?: string
    createMsg: string
    updateMsg: string
  }
}
const RoleSubmitButton = ({ notification, isNew }: RoleSubmitButtonProps) => {
  const { values, valid, submit, isSubmitting } = useLapeContext<RoleInterface>()

  const permissions = useSelector(selectPermissions)
  const canSubmit = permissions?.includes(PermissionTypes.AddRoles)
  const params = useParams()
  const [openPopup, setOpenPopup] = useState(false)

  const isExistingData = !!values.id

  const onSubmitDialog = () => {
    const isDraft = values.status === Statuses.draft
    const prevStatus = values.status
    if (isDraft) {
      values.status = Statuses.pending
    }

    submit()
      .then(() => {
        workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, {}))
        navigateReplace(pathToUrl(ROUTES.FORMS.ROLE.PREVIEW, params))
        if (notification) {
          successNotification(
            isExistingData ? notification.updateMsg : notification.createMsg,
          )
        }
      })
      .catch(() => {
        values.status = prevStatus
      })
  }

  const handleSubmitClick = () => {
    setOpenPopup(true)
  }

  if (values.id && values.status !== Statuses.draft) {
    return null
  }

  return (
    <>
      {openPopup && (
        <LapeSubmitPopups
          onClose={() => setOpenPopup(false)}
          onSubmit={onSubmitDialog}
          type="role"
        />
      )}
      {isNew ? (
        <NewButton
          onClick={handleSubmitClick}
          width={167}
          pending={isSubmitting}
          disabled={!valid || !canSubmit}
        >
          Submit
        </NewButton>
      ) : (
        <SumbitButtonWrapper>
          <Button
            onClick={handleSubmitClick}
            loading={isSubmitting}
            disabled={!valid || !canSubmit}
          >
            Submit
          </Button>
        </SumbitButtonWrapper>
      )}
    </>
  )
}

export default connect(RoleSubmitButton)
