import React from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ScreeningSettingsInterface } from '@src/interfaces/settings'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import useIsCommercial from '@src/hooks/useIsCommercial'

export const Screening = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<ScreeningSettingsInterface>()
  const isCommercial = useIsCommercial()

  const disableEdit = !permissions.includes(PermissionTypes.ChangeScreeningPreferences)

  return (
    <>
      <PageBody mb="s-64">
        <Group>
          <LapeNewSwitch
            name="enabled"
            disabled={disableEdit}
            itemTypeProps={{
              title: 'Enable Screening',
              description: `Enable the Screening feature. Allows setting manual${
                isCommercial ? '' : ' and automatic'
              } checks.`,
            }}
          />
          {values.enabled && (
            <>
              <LapeNewSwitch
                name="manual_check_enabled"
                disabled={disableEdit}
                itemTypeProps={{
                  title: 'Enable manual checks',
                  description: 'Enable manual screening checks',
                }}
              />
              <HideIfCommercial>
                <LapeNewSwitch
                  name="automatic_check_enabled"
                  disabled={disableEdit}
                  title="Enable automatic checks"
                  itemTypeProps={{
                    title: 'Enable automatic checks',
                    description: 'Enable automatic screening checks.',
                  }}
                />
              </HideIfCommercial>
            </>
          )}
        </Group>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.SCREENING_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
