import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { HealthInterface } from '@src/interfaces/health'

export const useGetHealth = (enabled = true) =>
  /** `withoutHandling` param is set to `true` because there is a bug on the endpoint, this is to prevent user from being logged out if it fails with 403 */
  useFetch<HealthInterface>(API.HEALTH, undefined, undefined, true, {
    enabled,
    cacheTime: 1000 * 60 * 30,
    staleTime: 1000 * 60 * 60,
    refetchOnWindowFocus: false,
  })
