import React from 'react'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { Box, Cell, Color, Flex, Text } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { decl } from '@src/utils/string'
import { Grid } from '@components/CommonSC/Grid'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { getTimeZoneLabel } from '@src/utils/timezones'

export interface DaySlotGroupItemInterface {
  id: string
  event_start_datetime: string
  employee?: EmployeeOptionInterface
}

export interface DaySlotGroupInterface {
  id: string
  label: string
  items: DaySlotGroupItemInterface[]
}

type Props = {
  day: DaySlotGroupInterface
  onChange: (selectedSlots: string[]) => void
  selectedSlotIds: string[]
  timeZone: string
  disabled?: boolean
  isDayChecked: boolean
}

const DaySlotsGroup = ({
  day,
  selectedSlotIds,
  onChange,
  timeZone,
  disabled,
  isDayChecked,
}: Props) => {
  return (
    <Cell mt="s-16">
      <Grid flow="row" gap={16} width="100%">
        <Box>
          <Flex justifyContent="space-between">
            <NewCheckbox
              label={day.label}
              labelProps={{ fontWeight: 500, fontSize: 'primary' }}
              onChange={e => {
                const checked = e.target.checked
                const ids = day.items.map(item => item.id)

                if (checked) {
                  onChange([...selectedSlotIds, ...ids])
                } else {
                  onChange(selectedSlotIds.filter(id => !ids.includes(id)))
                }
              }}
              checked={isDayChecked}
              aria-label={`day-checkbox-${day.label}`}
              disabled={disabled}
            />
            <Text color={Color.GREY_TONE_50} aria-label={`slot-count-${day.label}`}>
              {day.items.length} {decl(day.items.length, 'slot', 'slots')}
            </Text>
          </Flex>
        </Box>

        {day.items.map(slot => (
          <Box key={slot.id}>
            <Flex justifyContent="space-between" aria-label={`slot-row-${slot.id}`}>
              <NewCheckbox
                label={getTimeZoneLabel(slot.event_start_datetime, timeZone)}
                onChange={e => {
                  const checked = e.target.checked

                  if (checked) {
                    onChange([...selectedSlotIds, slot.id])
                  } else {
                    onChange(selectedSlotIds.filter(id => id !== slot.id))
                  }
                }}
                checked={!!selectedSlotIds.find(id => id === slot.id)}
                aria-label={`slot-checkbox-${slot.id}`}
                disabled={disabled}
              />
              {slot.employee && (
                <UserWithAvatar
                  id={slot.employee.id}
                  full_name={slot.employee.full_name}
                  avatar={slot.employee.avatar}
                  tooltipPlacement="left"
                  compact
                />
              )}
            </Flex>
          </Box>
        ))}
      </Grid>
    </Cell>
  )
}

export default DaySlotsGroup
