import React from 'react'
import Form from '@src/features/Form/Form'
import CareersApplicationComponent from '@src/pages/Careers/CareersApplication/CareersApplication'
import { careersApplicationRequest } from '@src/api/recruitment/careers'
import { connect } from 'lape'
import { CustomSectionsContextProvider } from '@src/pages/Careers/CareersApplication/components/CustomSectionsContext'

const CareersApplication = () => {
  return (
    <Form api={careersApplicationRequest} isExistingForm={false}>
      <CustomSectionsContextProvider>
        <CareersApplicationComponent />
      </CustomSectionsContextProvider>
    </Form>
  )
}

export default connect(CareersApplication)
