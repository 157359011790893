import React, { useEffect, useState } from 'react'
import format from 'date-fns/format'
import { FilterOption, FilterType } from '@src/interfaces/data'
import { ActionButton, Calendar } from '@revolut/ui-kit'
import { Dropdown, DropdownProps } from '@revolut/ui-kit'
import NewGrid from '@components/CommonSC/NewGrid'

type DateValue = Date | null

export interface FilterSingleDateProps
  extends Pick<DropdownProps, 'anchorRef' | 'fitInAnchor'> {
  open: boolean
  onClose: () => void
  onChange: (options: FilterOption[]) => void
  value?: FilterOption[]
  type?: FilterType
}

const convertFilterToDate = (filters: FilterOption[]): DateValue => {
  const date = filters?.[0]?.id

  if (!date) {
    return null
  }

  return date ? new Date(date) : null
}

export const FilterSingleDate = ({
  open,
  onChange,
  onClose,
  anchorRef,
  value = [],
  type = FilterType.date,
  fitInAnchor,
}: FilterSingleDateProps) => {
  const [dateValue, setDateValue] = useState<DateValue>()

  useEffect(() => {
    setDateValue(convertFilterToDate(value))
  }, [value[0]])

  const onCalendarChange = (selectedDate: DateValue) => {
    setDateValue(selectedDate)
  }

  const onClear = () => {
    onChange([])
    onClose()
  }

  const onApply = () => {
    const filters: FilterOption[] = []

    if (!dateValue) {
      onChange([])
      return
    }

    const formattedDate = new Date(dateValue)
    formattedDate.setUTCHours(0, 0, 0, 0)
    const filterFrom =
      type === FilterType.date
        ? formattedDate.toISOString()
        : format(formattedDate, 'yyyy-MM-dd')

    filters.push({
      id: filterFrom,
      name: `${filterFrom}`,
    })

    onChange(filters)
  }

  return (
    <Dropdown
      open={open}
      anchorRef={anchorRef}
      onClose={onClose}
      width={fitInAnchor ? undefined : 360}
      maxHeight={null}
      fitInAnchor={fitInAnchor}
    >
      <Dropdown.Group sticky="top">
        <Calendar
          variant="date"
          labelButtonClear="Clear"
          labelEmptyValue="Select range"
          labelPrev="Previous"
          labelNext="Next"
          value={dateValue}
          onChange={onCalendarChange}
        />
      </Dropdown.Group>
      <Dropdown.Group sticky="bottom" p="s-16">
        <NewGrid gridAutoFlow="column" gridColumnGap="1rem">
          <ActionButton width="100%" onClick={onClear}>
            Clear
          </ActionButton>
          <ActionButton variant="accent" width="100%" onClick={onApply}>
            Apply
          </ActionButton>
        </NewGrid>
      </Dropdown.Group>
    </Dropdown>
  )
}
