import { OptionInterface } from '@src/interfaces/selectors'
import { useFetch } from '@src/utils/reactQuery'
import { ApiHandlerInterface, GetRequestData, GetRequestInterface } from '@src/interfaces'
import {
  AvailableInterviewerSlot,
  CandidateCancelledInterviewInterface,
  EligibleInterviewerInterface,
  PotentialInterviewerSlots,
  SchedulingCalendarInterface,
  SchedulingEmailPreviewInterface,
  SchedulingInterviewInterface,
  SchedulingType,
  SendInviteInterface,
} from '@src/interfaces/interviewTool'
import { API } from '@src/constants/api'
import axios, { AxiosPromise, AxiosResponse, CancelTokenSource } from 'axios'
import { api, apiWithoutHandling, handleError } from '@src/api'
import { IdInterface } from '@components/Inputs/NewCheckboxGroup/NewCheckboxGroup'
import { FileInterface } from '@src/interfaces/files'
import { uploadFile } from '@src/api/files'
import { isFile } from '@src/utils/files'

type AvailableInterviewerSlotRequestArguments = {
  stageId?: number
  roundId?: number
  interviewId?: number
  dateRange?: string[]
  interviewers?: OptionInterface[]
  duration?: number
  durationUnit?: string
  onlySentSlots?: boolean
  isAdhoc?: boolean
  cancelToken?: CancelTokenSource
  apiHandler?: ApiHandlerInterface
}

export const useGetAvailableInterviewSlots = ({
  stageId,
  roundId,
  interviewId,
  dateRange,
  interviewers,
  duration,
  durationUnit,
  onlySentSlots,
  isAdhoc,
}: AvailableInterviewerSlotRequestArguments) =>
  useFetch<{ results: AvailableInterviewerSlot[] }>(
    roundId && stageId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/availableInterviewSlots`
      : null,
    undefined,
    {
      params: {
        employee: interviewers?.map(item => item.id).join(','),
        event_start_datetime: dateRange?.join(','),
        duration,
        duration_unit: durationUnit,
        sent: onlySentSlots,
        interview_scheduling_id: interviewId,
        disable_pagination: true,
        is_adhoc: isAdhoc ? true : undefined,
      },
    },
    true,
    {
      refetchOnWindowFocus: false,
    },
    undefined,
    true,
  )

export const getAvailableInterviewSlots = ({
  stageId,
  roundId,
  interviewId,
  dateRange,
  interviewers,
  duration,
  durationUnit,
  onlySentSlots,
  isAdhoc,
  cancelToken,
  apiHandler = apiWithoutHandling,
}: AvailableInterviewerSlotRequestArguments) =>
  apiHandler
    .get<GetRequestInterface<AvailableInterviewerSlot>>(
      `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/availableInterviewSlots`,
      {
        params: {
          employee: interviewers?.map(item => item.id).join(','),
          event_start_datetime: dateRange?.join(','),
          duration,
          duration_unit: durationUnit,
          sent: onlySentSlots,
          interview_scheduling_id: interviewId,
          disable_pagination: true,
          is_adhoc: isAdhoc ? true : undefined,
        },
        cancelToken: cancelToken?.token,
      },
    )
    .catch(e => {
      if (axios.isCancel(e)) {
        throw new Error('cancelPrevQuery')
      }

      handleError(e)
      throw e
    })

export const useGetCustomPotentialInterviewSlots = (
  id?: number,
  roundId?: number,
  interviewers?: IdInterface[],
  requireAll?: boolean,
  duration?: number,
  durationUnit?: string,
) => {
  return useFetch<PotentialInterviewerSlots[]>(
    id && roundId && interviewers?.length
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/customPotentialInterviewSlots`
      : null,
    undefined,
    {
      params: {
        interviewer_ids: interviewers?.map(item => item.id).join(','),
        require_everyone_availability: requireAll,
        duration,
        duration_unit: durationUnit,
      },
    },
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const getSchedulingEmailPreview = (
  id: number,
  roundId?: number,
  type?: SchedulingType,
  customDate?: string,
  timeZone?: string,
  templateId?: string | number,
  duration?: number,
  durationUnit?: string,
  apiHandler = api,
) =>
  apiHandler.get<SchedulingEmailPreviewInterface>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/schedulingEmailPreview`,
    {
      params: {
        scheduling_type: type,
        event_date_time: customDate,
        scheduling_timezone: timeZone,
        email_template_id: templateId,
        duration,
        duration_unit: durationUnit,
      },
    },
  )

export const useGetSchedulingInterview = (
  id?: number,
  roundId?: number,
  isPrepCall?: boolean,
  interviewId?: number,
) => {
  let url = null

  if (isPrepCall && roundId) {
    url = `${API.INTERVIEWS}/rounds/${roundId}/adhocInterviews`
  } else if (id && roundId) {
    url = `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/interviewScheduling`
  }

  const context = useFetch<SchedulingInterviewInterface[]>(url)

  return {
    ...context,
    data: interviewId
      ? context.data?.find(item => item.id === interviewId)
      : context.data?.[0],
  }
}

export const useGetSchedulingCalendars = (roundId?: number) => {
  const context = useFetch<GetRequestData<SchedulingCalendarInterface>>(
    roundId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/availableCalendars`
      : null,
  )

  return { ...context, data: context.data?.results }
}

export const useGetPrepCallInterviews = (roundId?: number) =>
  useFetch<SchedulingInterviewInterface[]>(
    roundId ? `${API.INTERVIEWS}/rounds/${roundId}/adhocInterviews` : null,
  )

export const getSchedulingInterview = (
  stageId: number,
  roundId: number,
  apiHandler = api,
) =>
  apiHandler.get<SchedulingInterviewInterface[]>(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling`,
  )

export const useGetCandidateCancelledStages = (roundId?: number) =>
  useFetch<CandidateCancelledInterviewInterface[]>(
    roundId ? `${API.INTERVIEWS}/rounds/${roundId}/candidateCancelledInterviews` : null,
  )

export const sendInterviewInvite = async (
  id: number,
  roundId: number,
  data: SendInviteInterface,
  apiHandler?: ApiHandlerInterface,
): Promise<AxiosPromise<SendInviteInterface>> => {
  let files: AxiosResponse<FileInterface>[] | undefined
  if (data.attachments) {
    files = await Promise.all(
      data.attachments.map(file =>
        isFile(file)
          ? uploadFile(file, 'candidate_email', undefined, apiHandler)
          : new Promise<AxiosResponse<FileInterface>>(resolve => {
              resolve({ data: file } as AxiosResponse<FileInterface>)
            }),
      ),
    )
  }

  return (apiHandler || apiWithoutHandling).post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/interviewScheduling`,
    {
      ...data,
      attachments:
        files?.map(file => ({
          id: file?.data?.id,
        })) || [],
    },
  )
}

export const editInterview = (
  stageId: number,
  roundId: number,
  id: number,
  data: Partial<SendInviteInterface>,
  apiHandler = api,
) =>
  apiHandler.patch(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling/${id}`,
    data,
  )
export const useGetEligibleInterviewers = (
  id?: number,
  roundId?: number,
  is_adhoc?: boolean,
  availability_time_range?: string,
) => {
  const context = useFetch<GetRequestInterface<EligibleInterviewerInterface>>(
    id && roundId
      ? `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${id}/eligibleInterviewers`
      : null,
    undefined,
    {
      params: {
        is_adhoc,
        availability_time_range,
      },
    },
  )

  return { ...context, data: context.data?.results }
}

export const cancelScheduledInterview = async (
  roundId: number,
  stageId: number,
  interviewId: number,
  apiHandler = api,
) =>
  apiHandler.post(
    `${API.INTERVIEWS}/rounds/${roundId}/interviewStages/${stageId}/interviewScheduling/${interviewId}/cancel`,
  )
