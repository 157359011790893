import React, { useState } from 'react'
import {
  Button,
  Item,
  MoreBar,
  StatusPopup,
  TableWidget,
  Token,
  useStatusPopup,
  VStack,
} from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import {
  applyProbationTemplateToEmployees,
  getEligibleEmployeesRequests,
} from '@src/api/probationTemplate'
import { useTable } from '@components/Table/hooks'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { getSelectCellConfig } from '@components/Table/AdvancedCells/SelectCell/SelectCell'
import { PageBody } from '@components/Page/PageBody'
import { Check, InfoOutline } from '@revolut/icons'
import {
  employeeNameColumn,
  employeeStatusColumn,
  employeeTypeColumn,
  lineManagerColumn,
  qualityControlRevolutersColumn,
} from '@src/constants/columns/employee'
import { departmentNameRevolutersColumn } from '@src/constants/columns/department'
import { teamNameColumn } from '@src/constants/columns/team'
import { specialisationRoleNameColumn } from '@src/constants/columns/role'
import { seniorityNameRevolutersColumn } from '@src/constants/columns/seniority'
import { RevolutersInterface } from '@src/interfaces/revoluters'
import { locationNameColumn } from '@src/constants/columns/location'
import { startedAtColumn } from '@src/constants/columns/dates'
import { IdAndName } from '@src/interfaces'

const getRow = (employeesOptions: IdAndName[]): RowInterface<RevolutersInterface> => ({
  cells: [
    { ...getSelectCellConfig() },
    {
      ...employeeNameColumn,
      width: 200,
      selectorsKey: async () => ({ options: employeesOptions }),
    },
    { ...employeeTypeColumn, width: 110 },
    { ...departmentNameRevolutersColumn, width: 160 },
    { ...teamNameColumn, width: 220 },
    { ...specialisationRoleNameColumn, width: 200 },
    { ...seniorityNameRevolutersColumn, width: 110 },
    { ...lineManagerColumn, width: 200 },
    { ...qualityControlRevolutersColumn, width: 200 },
    { ...locationNameColumn, width: 120 },
    { ...startedAtColumn, width: 120 },
    { ...employeeStatusColumn, width: 120 },
  ],
})

const getFiltersToApply = (
  tableFilters: FilterByInterface[],
  selectedUsers: IdAndName[],
) => {
  const filters = tableFilters.map(filter => {
    if (filter.columnName === 'id') {
      return selectedUsers.length
        ? {
            columnName: 'id',
            filters: [...selectedUsers],
          }
        : filter
    }
    return filter
  })
  if (!filters.find(filter => filter.columnName === 'id') && selectedUsers.length) {
    filters.push({
      columnName: 'id',
      filters: [...selectedUsers],
    })
  }

  return filters
}

const ApplyTable = ({ templateId }: { templateId: number }) => {
  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<RevolutersInterface>>()
  const statusPopup = useStatusPopup()
  const initialSortBy = [
    {
      sortBy: 'joining_date_time',
      direction: SORT_DIRECTION.ASC,
    },
  ]

  const table = useTable(
    getEligibleEmployeesRequests(templateId),
    undefined,
    initialSortBy,
  )

  const selectedUsers =
    selectedData?.selectedRowsData.map(el => ({ id: el.id, name: el.full_name })) || []
  const disabledBulkAction =
    !selectedData?.selectedRowsData.length && !selectedData?.isAllSelected
  const employeesOptions = table.data.map(el => ({ id: el.id, name: el.full_name }))

  const onClosePopup = () => {
    statusPopup.hide()
    table.refresh()
  }

  const showPending = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Operation in progress...</StatusPopup.Title>
      </StatusPopup>,
    )
  }
  const showSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result" onClose={onClosePopup}>
        <StatusPopup.Title>
          Successfully queued the task. Applying probation template to selected employees
          will be processed in the background.
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={onClosePopup}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }
  const showError = () => {
    statusPopup.show(
      <StatusPopup variant="error" onClose={onClosePopup}>
        <StatusPopup.Title>
          Something went wrong. Please try again later
        </StatusPopup.Title>
        <StatusPopup.Actions>
          <Button elevated onClick={onClosePopup}>
            Close
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const handleBulkAction = async () => {
    showPending()
    try {
      const filtersToApply = getFiltersToApply(table.filterBy, selectedUsers)
      await applyProbationTemplateToEmployees(templateId, filtersToApply)
      showSuccess()
    } catch {
      showError()
    }
  }

  return (
    <TableWidget>
      <TableWidget.Actions>
        <MoreBar>
          <MoreBar.Action
            useIcon={Check}
            disabled={disabledBulkAction}
            onClick={handleBulkAction}
          >
            Apply probation template
          </MoreBar.Action>
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <SelectTableWrapper
          enabled
          onChange={setSelectedData}
          filters={table.filterBy}
          tableDataLength={table.data.length}
        >
          <AdjustableTable
            {...table}
            dataType="Employees"
            name={TableNames.ProbationTemplateEligibleEmployees}
            noDataMessage="List of employees will appear here."
            row={getRow(employeesOptions)}
            useWindowScroll
          />
        </SelectTableWrapper>
      </TableWidget.Table>
    </TableWidget>
  )
}

export const Apply = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  return (
    <PageBody maxWidth="100%">
      <SectionTitle
        title="Should we apply this probation cycle now to any of your active employees?"
        subtitle="You can also create probation cycles one by one at any time from inside an employee’s profile."
      />
      <VStack gap="s-16">
        <Item>
          <Item.Content>
            <Item.Title pb="s-8">
              Applying probation templates to existing employees
            </Item.Title>
            <Item.Description>
              If a probation template is applied to an employee who has already started,
              their probation cycle will start from their joining date for the duration
              specified in the template. This means you may enter into the cycle mid-way
              through it’s completion. Checkpoints already in the past will be expired,
              however future checkpoints will continue as normal.
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Item.Value>
              <InfoOutline color={Token.color.red} />
            </Item.Value>
          </Item.Side>
        </Item>
        {values.id && <ApplyTable templateId={values.id} />}
      </VStack>
    </PageBody>
  )
}
