import React, { useRef } from 'react'
import { Box, Header, MoreBar } from '@revolut/ui-kit'
import { useGetCV } from '@src/api/recruitment/interviews'
import { LinkExternal } from '@revolut/icons'
import SideBar from '@components/SideBar/SideBar'
import { getPreviewLink } from '@src/utils/files'
import { FileInterface } from '@src/interfaces/files'
import CVPreview from '@src/features/CVPreviewSidebar/CVPreview'

export type CVFileType = Pick<FileInterface, 'url' | 'extension'>

type Props = {
  id?: number
  isOpen: boolean
  onClose: () => void
  file?: CVFileType
}

const CVPreviewSidebar = ({ id, isOpen, onClose, file }: Props) => {
  const { data } = useGetCV(isOpen && id ? id : null)
  const ref = useRef<HTMLDivElement | null>(null)

  const url = data ? data.resume.url : file?.url
  const extension = data ? data.resume.extension : file?.extension
  const link = getPreviewLink(url, extension)

  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      variant="wide"
      height="100%"
      sideProps={{ resizable: true }}
      title="CV"
      headerContent={
        <Header.Bar>
          {link && (
            <MoreBar>
              <MoreBar.Action
                use="a"
                href={link}
                target="_blank"
                rel="noopener norefferer"
                useIcon={LinkExternal}
              >
                Open in new tab
              </MoreBar.Action>
            </MoreBar>
          )}
        </Header.Bar>
      }
    >
      <Box
        ref={ref}
        style={{
          height: 'calc(100vh - 230px)',
        }}
      >
        <CVPreview id={isOpen && id ? id : null} file={file} />
      </Box>
    </SideBar>
  )
}

export default CVPreviewSidebar
