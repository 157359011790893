import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import Preview from './Preview'
import General from './General'
import { PageWrapper } from '@src/components/Page/Page'
import { employeeEmailTemplatesRequests } from '@src/api/employeeEmails'

const EmailTemplate = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.PREVIEW}>
          <Preview />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeeEmailTemplatesRequests}>
    <EmailTemplate />
  </Form>
))
