import React, { useMemo } from 'react'
import { Absolute, Image } from '@revolut/ui-kit'
import christmasImg from '@src/assets/images/event/christmas.png'
import { isBefore, isAfter, endOfDay, startOfDay } from 'date-fns'

export const SideBarEvent = () => {
  const events = useMemo(() => {
    const currentYear = new Date().getFullYear()
    return [
      {
        name: 'christmas',
        start: startOfDay(new Date(`${currentYear}-12-21`)),
        end: endOfDay(new Date(`${currentYear}-12-31`)),
      },
      {
        name: 'christmas',
        start: startOfDay(new Date(`${currentYear}-01-01`)),
        end: endOfDay(new Date(`${currentYear}-01-01`)),
      },
    ]
  }, [])

  const currentDate = new Date()
  const currentEvent = events.find(
    event => isAfter(currentDate, event.start) && isBefore(currentDate, event.end),
  )

  if (!currentEvent) {
    return null
  }

  if (currentEvent.name === 'christmas') {
    return (
      <Absolute left={-8} top={-16}>
        <Image image={christmasImg} alt="Happy Holidays" width={32} maxWidth="unset" />
      </Absolute>
    )
  }

  return null
}
