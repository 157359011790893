import { Avatar, Banner } from '@revolut/ui-kit'
import React from 'react'

export const PoliciesUpdatingWarning = () => (
  <Banner>
    <Banner.Avatar>
      <Avatar variant="brand" useIcon="Time" size={56} />
    </Banner.Avatar>
    <Banner.Content>
      <Banner.Title>Creating assignments</Banner.Title>
      <Banner.Description>
        The assingments for this policy are being created. This can take up to 5 minutes.
      </Banner.Description>
    </Banner.Content>
  </Banner>
)
