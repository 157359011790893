import { Flex, Icon, Token, Text } from '@revolut/ui-kit'
import React from 'react'

export const InitialSearchDescription = () => (
  <Flex
    width="100%"
    height="120px"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    gap="s-12"
  >
    <Icon name="Search" size={24} color={Token.color.greyTone50} />
    <Text color={Token.color.greyTone50}>Use the search bar to find your Jira epics</Text>
  </Flex>
)
