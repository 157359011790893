import React, { useMemo, useState } from 'react'
import { Color, MoreBar } from '@revolut/ui-kit'
import {
  cancelScheduledInterview,
  useGetPrepCallInterviews,
} from '@src/api/recruitment/interviewerScheduling'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { getCurrentTimezone, getTimeZoneLabel } from '@src/utils/timezones'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { InterviewStatuses } from '@src/interfaces/interviewTool'
import isBefore from 'date-fns/isBefore'
import add from 'date-fns/add'

type Props = {
  roundId?: number
  onView: (id: number, stageId: number) => void
}

const VISIBLE_INTERVIEW_STATUSES: InterviewStatuses[] = [
  InterviewStatuses.interview_scheduled,
  InterviewStatuses.pending_candidate_response,
]

const PrepCallInterviewNotifications = ({ roundId, onView }: Props) => {
  const [pending, setPending] = useState(false)
  const [interviewToCancel, setInterviewToCancel] = useState<{
    stageId: number
    interviewId: number
  }>()
  const { data, refetch } = useGetPrepCallInterviews(roundId)

  const interviews = useMemo(
    () =>
      data?.filter(item => {
        if (
          item.adhoc_scheduling_status &&
          VISIBLE_INTERVIEW_STATUSES.includes(item.adhoc_scheduling_status)
        ) {
          if (!item.event_date_time) {
            return true
          }

          // hide expired interviews
          return isBefore(
            new Date(),
            add(new Date(item.event_date_time), {
              minutes: item.duration_unit === 'minutes' ? item.duration : undefined,
              hours: item.duration_unit === 'hours' ? item.duration : undefined,
              days: item.duration_unit === 'days' ? item.duration : undefined,
            }),
          )
        }

        return false
      }),
    [data],
  )

  const onCancel = async () => {
    if (!interviewToCancel) {
      return
    }

    setPending(true)

    try {
      await cancelScheduledInterview(
        roundId!,
        interviewToCancel.stageId,
        interviewToCancel.interviewId,
      )
      refetch()
      setInterviewToCancel(undefined)
    } finally {
      setPending(false)
    }
  }

  if (!interviews?.length) {
    return null
  }

  return (
    <>
      <ConfirmationDialog
        open={!!interviewToCancel}
        onClose={() => setInterviewToCancel(undefined)}
        onConfirm={onCancel}
        loading={pending}
        submitDisabled={pending}
        onReject={() => setInterviewToCancel(undefined)}
        label="Cancel this catch-up call?"
        yesMessage="Yes"
        noMessage="Close"
      />
      {interviews.map(interview => {
        const currentTimezoneId = getCurrentTimezone()
        const timeZoneId = interview?.scheduling_timezone
          ? String(interview.scheduling_timezone.id)
          : currentTimezoneId
        const timeZoneName = interview?.scheduling_timezone
          ? String(interview.scheduling_timezone.name)
          : currentTimezoneId
        let title = 'Catch-up call invite sent to candidate'

        if (interview.event_date_time) {
          title = `Catch-up call scheduled: ${getTimeZoneLabel(
            interview.event_date_time!,
            timeZoneId,
            'd MMM yyyy hh:mm aa',
          )} (${timeZoneName})`
        }

        return (
          <ActionWidget
            key={interview.id}
            title={title}
            text={interview.summary}
            avatarColor={Color.BLUE}
          >
            <MoreBar>
              <MoreBar.Action
                disabled={pending}
                onClick={() => onView(interview.id!, interview.interview_stage.id)}
              >
                View details
              </MoreBar.Action>
              <MoreBar.Action
                disabled={pending}
                onClick={() =>
                  setInterviewToCancel({
                    stageId: interview.interview_stage.id,
                    interviewId: interview.id!,
                  })
                }
                variant="negative"
              >
                Cancel
              </MoreBar.Action>
            </MoreBar>
          </ActionWidget>
        )
      })}
    </>
  )
}

export default PrepCallInterviewNotifications
