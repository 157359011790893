import React from 'react'
import { Color, HStack, StatusWidget, Text } from '@revolut/ui-kit'
import { ArrowThinLeft, Chat } from '@revolut/icons'

type Props = {
  onGoToAllCategoriesClick: () => void
  onChatWithUsClick: () => void
}
export const EmptySearchResults = ({
  onGoToAllCategoriesClick,
  onChatWithUsClick,
}: Props) => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        src="https://assets.revolut.com/assets/3d-images/3D086.png"
        srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
      />
      <StatusWidget.Description>
        No results were found for this search
      </StatusWidget.Description>
      <StatusWidget.Action onClick={onGoToAllCategoriesClick}>
        <HStack space="s-8" align="center">
          <ArrowThinLeft size={15} color={Color.BLUE} />
          <Text>Go to all categories</Text>
        </HStack>
      </StatusWidget.Action>
      <StatusWidget.Action onClick={onChatWithUsClick}>
        <HStack space="s-8" align="center">
          <Chat size={15} color={Color.BLUE} />
          <Text>Chat with us</Text>
        </HStack>
      </StatusWidget.Action>
    </StatusWidget>
  )
}
