import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { BulkEditItemsResponseResult } from '@src/interfaces/bulkEdit'
import { Check, Cross, Loading } from '@revolut/icons'
import React from 'react'
import { Box, Tooltip, useTooltip, Text } from '@revolut/ui-kit'
import { EmployeeBasicInterface } from '@src/interfaces/employees'
import Table from '@components/TableV2/Table'

const EmployeePreviewCell = ({ employee }: { employee: EmployeeBasicInterface }) => {
  return <Table.EmployeeCell employee={employee} size={40} />
}

export const employeePreview: ColumnInterface<BulkEditItemsResponseResult> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  title: 'Employee',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => (
    <EmployeePreviewCell employee={data.object.employee || data.object} />
  ),
}

export const changedField: ColumnInterface<BulkEditItemsResponseResult> = {
  type: CellTypes.text,
  idPoint: 'field.id',
  dataPoint: 'field.name',
  title: 'Changed field',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
}

export const oldValue: ColumnInterface<BulkEditItemsResponseResult> = {
  type: CellTypes.insert,
  idPoint: 'old_value.id',
  dataPoint: 'old_value.name',
  title: 'Old value',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => <Text>{data.old_value?.name || data.old_value?.full_name}</Text>,
}

export const newValue: ColumnInterface<BulkEditItemsResponseResult> = {
  type: CellTypes.insert,
  idPoint: 'new_value.id',
  dataPoint: 'new_value.name',
  title: 'New value',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => <Text>{data.new_value?.name || data.new_value?.full_name}</Text>,
}

const Icon = ({ data }: { data: BulkEditItemsResponseResult }) => {
  if (data.status.id === 'invalid' || data.status.id === 'failed') {
    return <Cross size={16} color="red" />
  }

  if (data.status.id === 'valid' || data.status.id === 'completed') {
    return <Check size={16} color="green" />
  }

  if (data.status.id === 'in_progress') {
    return <Loading size={16} color="orange" />
  }

  return null
}

const IconWithTooltip = ({ data }: { data: BulkEditItemsResponseResult }) => {
  const tooltip = useTooltip()

  return (
    <>
      <Box {...tooltip.getAnchorProps()}>
        <Icon data={data} />
      </Box>
      <Tooltip {...tooltip.getTargetProps()}>{data.error || data.status.name}</Tooltip>
    </>
  )
}

export const validation: ColumnInterface<BulkEditItemsResponseResult> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  title: 'Validation',
  selectorsKey: selectorKeys.none,
  sortKey: null,
  filterKey: 'validation',
  insert: ({ data }) => {
    return <IconWithTooltip data={data} />
  },
}
