import React from 'react'
import { Header, Layout, Image, Flex, Button } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const TestingPeriodEnded = () => {
  const permissions = useSelector(selectPermissions)
  const canManageSubscriptionPlans = permissions.includes(
    PermissionTypes.ManageSubscriptionPlans,
  )

  return (
    <Layout>
      <Layout.Main>
        <Header variant="item">
          <Header.Title>Your testing period has ended</Header.Title>
          <Header.Description>
            Provide your billing details to continue using Revolut People
          </Header.Description>
        </Header>

        <Flex justifyContent="center">
          <Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D020.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D020@3x.png',
            }}
            maxWidth={300}
          />
        </Flex>
      </Layout.Main>

      {canManageSubscriptionPlans ? (
        <Layout.Actions>
          <Button onClick={() => navigateTo(ROUTES.PLANS.SELECT_PLAN)}>
            Upgrade Plan
          </Button>
        </Layout.Actions>
      ) : null}
    </Layout>
  )
}
