import React, { useEffect, useState } from 'react'
import {
  Avatar,
  AvatarSkeleton,
  Box,
  Color,
  Flex,
  LineClamp,
  Text,
  Widget,
} from '@revolut/ui-kit'

import { EmployeeBadge } from '@src/interfaces/employees'
import { useGetEmployeeSettings } from '@src/api/settings'
import { getEmployeeBadges } from '@src/api/employees'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'

const BadgeContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(auto-fill, 84px);
  gap: 8px;
  text-align: center;
`

type Props = {
  id: number
}
export const EmployeeBadges = ({ id }: Props) => {
  const [badges, setBadges] = useState<EmployeeBadge[]>([])
  const [badgesLoading, setBadgesLoading] = useState(false)
  const { data: employeeSettings } = useGetEmployeeSettings()

  useEffect(() => {
    if (employeeSettings?.enable_dynamic_groups_badges) {
      setBadgesLoading(true)
      getEmployeeBadges(id)
        .then(res => {
          setBadges(res.data.results)
        })
        .finally(() => {
          setBadgesLoading(false)
        })
    }
  }, [id, employeeSettings?.enable_dynamic_groups_badges])

  return badgesLoading || badges.length ? (
    <Widget p="s-16" my="s-16">
      {badgesLoading ? (
        <>
          {badgesLoading && <AvatarSkeleton mb="s-8" />}
          <Text variant="small" color={Color.GREY_TONE_50}>
            Badges will appear here
          </Text>
        </>
      ) : (
        <BadgeContainer>
          {badges.map(badge => (
            <Box key={badge.id} data-testid={`badge-${badge.name}`}>
              <Tooltip text={badge.name} placement="bottom">
                <Flex flexDirection="column" alignItems="center">
                  <Avatar image={badge.employee_badge.url} mb="s-8" />
                  <LineClamp max={2}>
                    <Text fontSize="small">{badge.name}</Text>
                  </LineClamp>
                </Flex>
              </Tooltip>
            </Box>
          ))}
        </BadgeContainer>
      )}
    </Widget>
  ) : null
}
