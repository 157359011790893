import { RoleStep } from '@src/interfaces/roles'
import { useGetHiringProcessSettings, useGlobalSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'

export const useNextRoleStepButtonLink = (currentStep: RoleStep) => {
  const {
    settings: { candidates_enabled },
  } = useGlobalSettings()
  const { data: hiringProcessSettings } = useGetHiringProcessSettings()

  switch (currentStep) {
    case 'general':
      return ROUTES.FORMS.ROLE.COMPETENCY_MATRIX

    case 'competency_matrix':
      return candidates_enabled &&
        hiringProcessSettings?.enable_role_level_process_definition
        ? ROUTES.FORMS.ROLE.HIRING_PROCESS
        : ROUTES.FORMS.ROLE.PREVIEW

    case 'hiring_process':
      return ROUTES.FORMS.ROLE.PREVIEW

    default:
      return ROUTES.FORMS.ROLE.GENERAL
  }
}
