import { SkillLevels } from '@src/interfaces/roles'
import { Token } from '@revolut/ui-kit'

const colors = {
  None: Token.color.greyTone2,
  Intermediate: Token.color.green_10,
  Advanced: Token.color.green_40,
  Expert: Token.color.green_80,
}

export const competencyToColor = (competency: SkillLevels) => {
  switch (competency) {
    case SkillLevels.None:
      return colors.None

    case SkillLevels.Intermediate:
      return colors.Intermediate

    case SkillLevels.Advanced:
      return colors.Advanced

    case SkillLevels.Expert:
      return colors.Expert

    default:
      return null
  }
}

export const getNormalizedSeniorityName = (name: string) => {
  const normalizedNames = {
    Director: 'Dir',
    'Senior Director': 'SR Dir',
    'Vice President': 'VP',
    'Senior Vice President': 'SVP',
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  return normalizedNames[name] || name
}
