import React, { useState } from 'react'
import CommentsPopup from '@components/ColumnInserts/Justification/CommentsPopup'
import {
  CommentsPopupDataInterface,
  PerformanceReviewTypes,
  PreviousDeliverableJustification,
  PreviousReviewInterface,
  PreviousSkillJustification,
  ReviewInterface,
} from '@src/interfaces/performance'
import { ActionButton, Box, TextButton } from '@revolut/ui-kit'
import { IdStatuses } from '@src/interfaces/employees'

interface Props {
  justifications?: PreviousDeliverableJustification[] | PreviousSkillJustification[]
  previousReviews?: PreviousReviewInterface[]
  type: PerformanceReviewTypes
  title: React.ReactNode
  variant?: 'accent' | 'text'
}

const PreviousDeliverablesJustifications = ({
  justifications,
  previousReviews,
  type,
  title,
  variant,
}: Props) => {
  const [open, setOpen] = useState(false)

  if (!justifications?.length || !previousReviews?.length) {
    return null
  }

  const getCycleName = (reviewId: number) => {
    const review = previousReviews.find(reviewItem => reviewItem.review.id === reviewId)
    return review?.cycle?.name
  }

  const data = {
    justifications: [],
  } as CommentsPopupDataInterface

  if (type === PerformanceReviewTypes.deliverables) {
    data.justifications = (justifications as PreviousDeliverableJustification[])
      .filter(item => item.value?.length)
      .map(item => ({
        value: item.value || [],
        review: {
          reviewer: {
            display_name: getCycleName(item.review_id),
          } as {
            display_name: string
            full_name: string
            status: IdStatuses
            id: number
          },
        } as ReviewInterface,
      }))
  } else {
    data.justifications = (justifications as PreviousSkillJustification[]).map(item => ({
      value: item.value
        ? [
            {
              comment: item.value,
              reference_url: null,
            },
          ]
        : [],
      review: {
        reviewer: {
          display_name: getCycleName(item.review_id),
        } as {
          display_name: string
          full_name: string
          status: IdStatuses
          id: number
        },
      } as ReviewInterface,
    }))
  }

  if (!data.justifications.length) {
    return null
  }
  const onClick = () => setOpen(true)

  return (
    <Box>
      <CommentsPopup
        isSelfComments
        data={data}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      {variant === 'text' ? (
        <TextButton onClick={onClick}>{title}</TextButton>
      ) : (
        <ActionButton onClick={onClick} variant={variant}>
          {title}
        </ActionButton>
      )}
    </Box>
  )
}

export default PreviousDeliverablesJustifications
