import React from 'react'
import { Box, createChain, Flex, Text } from '@revolut/ui-kit'
import { LocationInterface } from '@src/interfaces/requisitions'
import { getLocationColor } from '../General/General'

interface PreviewLocationsProps {
  specialisationId?: number | string
  selectedLocations: LocationInterface[]
  highRiskLocations: Set<string>
  mediumRiskLocations: Set<string>
}

const PreviewLocations = ({
  specialisationId,
  selectedLocations,
  highRiskLocations,
  mediumRiskLocations,
}: PreviewLocationsProps) => {
  const locationsList = selectedLocations.map(location => (
    <Text
      key={location.id}
      color={getLocationColor(location.name, highRiskLocations, mediumRiskLocations)}
    >
      {location.name}
    </Text>
  ))

  if (!specialisationId) {
    return null
  }

  return (
    <Flex>
      <Box>
        {createChain(<Text whiteSpace="pre">, </Text>)(
          selectedLocations?.length ? locationsList : ['All'],
        )}
      </Box>
    </Flex>
  )
}

export default PreviewLocations
