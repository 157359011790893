import React, { useState } from 'react'
import { Box, Flex, Icon, Item, Token, Tooltip, useTooltip } from '@revolut/ui-kit'
import { disableIndeedIntegration, useGetJobPostingSettings } from '@src/api/settings'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { FormattedMessage } from 'react-intl'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useConfirmationDialog } from '@src/features/Popups/ConfirmationDialogProvider'

interface DisconnectIndeedProps {
  onRefresh: () => void
}

export const DisconnectIndeed = ({ onRefresh }: DisconnectIndeedProps) => {
  const { data: jobPostingSettings } = useGetJobPostingSettings()
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const confirmationPopup = useConfirmationDialog()
  const tooltip = useTooltip()
  const handleDisconnectIndeed = () => {
    confirmationPopup.show({
      label: 'Are you sure?',
      body: 'After disabling this integration you won’t be able to use Indeed',
      onConfirm: async () => {
        setLoading(true)
        try {
          await disableIndeedIntegration()
          showStatusPopup({
            title: (
              <FormattedMessage
                id="integrations.indeed.disconnect.success.title"
                defaultMessage="Indeed integration disabled"
              />
            ),
          })
          onRefresh()
        } catch (e) {
          showStatusPopup({
            status: 'error',
            title: getStringMessageFromError(e),
          })
        } finally {
          setLoading(false)
        }
      },
    })
  }
  const disabled = !!jobPostingSettings?.enable_indeed_integration || loading
  return (
    <Box {...tooltip.getAnchorProps()}>
      <Item
        color={Token.color.danger}
        aria-disabled={disabled}
        py="s-8"
        use="label"
        onClick={() => {
          if (!disabled) {
            handleDisconnectIndeed()
          }
        }}
      >
        <Item.Content>
          <Flex gap="s-16" py="s-8">
            <Icon name="Unlink" />
            <Item.Title>Disconnect Indeed</Item.Title>
          </Flex>
        </Item.Content>
      </Item>
      {!!jobPostingSettings?.enable_indeed_integration && (
        <Tooltip {...tooltip.getTargetProps()}>
          The setting "Post my jobs on Indeed" is still enabled.
          <br />
          Please turn this off before disconnecting the integration.
        </Tooltip>
      )}
    </Box>
  )
}
