import React, { useMemo } from 'react'

import { CheckpointType, ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Queries } from '@src/constants/api'
import Filter, { QuickFilter } from './Filter'
import { chain } from '@revolut/ui-kit'
import { OptionInterface } from './common'

interface Props {
  checkpoints?: ProbationCheckpoint[]
  onFilterChange?: (options: OptionInterface[] | undefined) => void
  prefix?: string
  isClearable?: boolean
  showFilters?: boolean
  className?: string
  labelDropdown?: React.ReactNode
}

const CheckpointsFilter = ({ checkpoints, ...rest }: Props) => {
  const options = useMemo(() => {
    if (!checkpoints) {
      return []
    }
    return checkpoints.reduce((acc: OptionInterface[], checkpoint) => {
      if (checkpoint.checkpoint_type === CheckpointType.Review) {
        acc.push({
          id: checkpoint.checkpoint_number,
          name: `CP${checkpoint.checkpoint_number}`,
        })
      }
      return acc
    }, [])
  }, [checkpoints])

  const quickFilters = useMemo(
    () =>
      options.reduce((acc: QuickFilter[], option) => {
        acc.push({
          key: `${option.id}`,
          name: option.name,
          filters: [+option.id],
        })
        return acc
      }, []),
    [checkpoints],
  )

  return (
    <Filter
      labelDropdown={chain('Checkpoints', 'All')}
      options={options}
      quickFilters={quickFilters}
      filterKey={Queries.CheckpointNumber}
      {...rest}
    />
  )
}

export default CheckpointsFilter
