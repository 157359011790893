import React from 'react'
import { Box, Text, Color, Input } from '@revolut/ui-kit'

import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

interface ShiftRejectDialiogProps {
  open: boolean
  isPending: boolean
  onReject: () => void
  onClose: () => void
  comment: string
  setComment: (val: string) => void
}

export const ShiftRejectDialiog = ({
  open,
  isPending,
  onReject,
  onClose,
  comment,
  setComment,
}: ShiftRejectDialiogProps) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={onReject}
      loading={isPending}
      onReject={onClose}
      label="Please enter rejection reason"
      body={
        <Box>
          <Text mb="s-16" display="inline-block" color={Color.GREY_TONE_50}>
            Kindly let the employee know why you are rejecting this shift
          </Text>
          <Input
            label="Add reason here"
            value={comment}
            onChange={e => setComment(e.currentTarget.value)}
          />
        </Box>
      }
      yesMessage="Reject"
      noMessage="Go back"
    />
  )
}
