import React, { Dispatch, SetStateAction } from 'react'
import { ActionButton, Avatar, Flex, Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { PerformanceSummarySection } from './PerformanceSummarySection'
import { ProbationSummarySection } from './ProbationSummarySection'
import { SummaryGoalsObsoleteReview } from '../../Preview/Performance/Summary/SummaryGoalsObsoleteReview'
import { ObsoletePerformanceReviewCollapsable } from './ObsoletePerformanceReviewCollapsable'
import { PerformanceSelector } from '@src/interfaces/performance'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'

interface Props {
  isNewFlow: boolean
  selectedPeriod: PerformanceSelector
  performanceLink?: string
  feedback?: AnytimeFeedbackListInterface
  data: EmployeeInterface
  refetchFeedbackList: () => void
  stats?: EmployeeStats
  setPerformanceLink: Dispatch<SetStateAction<string | undefined>>
  periodBeforeOngoing?: PerformanceSelector
}

export const PerformanceSummary = ({
  isNewFlow,
  selectedPeriod,
  performanceLink,
  feedback,
  data,
  refetchFeedbackList,
  stats,
  setPerformanceLink,
  periodBeforeOngoing,
}: Props) => {
  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="s-16">
          <Avatar useIcon="BarChart" />
          <Text variant="emphasis1">Performance summary</Text>
        </Flex>
        {performanceLink && (
          <ActionButton onClick={() => navigateTo(performanceLink)}>
            Detail View
          </ActionButton>
        )}
      </Flex>
      {!isNewFlow && selectedPeriod && (
        <>
          <SummaryGoalsObsoleteReview
            category={selectedPeriod.category}
            employeeId={data.id}
            reviewCycleId={selectedPeriod.id}
          />
          <ObsoletePerformanceReviewCollapsable
            data={data}
            selectedPeriod={selectedPeriod}
            feedback={feedback}
            refetchFeedbackList={refetchFeedbackList}
          />
        </>
      )}
      {selectedPeriod.category === ReviewCycleCategory.Performance ? (
        <PerformanceSummarySection
          data={data}
          stats={stats}
          selectedPeriod={selectedPeriod}
          periodBeforeOngoing={periodBeforeOngoing}
          setPerformanceLink={setPerformanceLink}
          feedback={feedback}
          refetchFeedbackList={refetchFeedbackList}
        />
      ) : (
        <ProbationSummarySection
          data={data}
          selectedPeriod={selectedPeriod}
          setPerformanceLink={setPerformanceLink}
          feedback={feedback}
          refetchFeedbackList={refetchFeedbackList}
        />
      )}
    </>
  )
}
