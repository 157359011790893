import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { General } from '@src/pages/Forms/ReferralBonus/General'
import { ReferralBonusTable } from '@src/pages/Forms/ReferralBonus/ReferralBonusTable'

export const ReferralBonus = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.REFERRAL_BONUSES.ALL}>
        <ReferralBonusTable />
      </Route>
      <Route exact path={ROUTES.FORMS.REFERRAL_BONUSES.GENERAL}>
        <General />
      </Route>
    </Switch>
  )
}
