import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@components/InternalLink/InternalLink'
import React from 'react'
import MoreBarButton from '@src/features/SettingsButtons/MoreBarButton'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

interface SettingsButtonProps {
  path: string
  canView?: PermissionTypes[]
}

export const SettingsButton = ({ path, canView }: SettingsButtonProps) => {
  const permissions = useSelector(selectPermissions)
  const hasPermission = canView
    ? canView.some(permission => permissions.includes(permission))
    : true

  if (!hasPermission) {
    return null
  }

  return (
    <MoreBarButton to={pathToUrl(path)} use={InternalLink} useIcon="Gear">
      Settings
    </MoreBarButton>
  )
}
