import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import pluralize from 'pluralize'

import {
  Box,
  Cell,
  chain,
  Ellipsis,
  ExpandableCell,
  Flex,
  Group,
  List,
  MoreBar,
  Text,
} from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FaqInterface } from '@src/interfaces/faq'
import { faqStatusToColor } from '@src/apps/General/HelpCenter/helpers'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EditorView } from '@components/Editor/EditorView'
import { ArchiveButton, EditButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'
import { pathToUrl } from '@src/utils/router'
import { selectPermissions } from '@src/store/auth/selectors'

export const HelpCenterQuestionPreview = () => {
  const permissions = useSelector(selectPermissions)
  const params = useParams<{ id: string }>()
  const { initialValues } = useLapeContext<FaqInterface>()

  const canEdit = permissions.includes(PermissionTypes.ChangeFaq)

  return (
    <>
      <PageHeader
        backUrl={ROUTES.APPS.HELP_CENTER.FAQ_TABLE}
        title={
          <Flex alignItems="center">
            {chain(
              <Box display="inline-block" maxWidth={550}>
                <Ellipsis tooltip="always">{initialValues.title}</Ellipsis>
              </Box>,
              initialValues.status ? (
                <Text color={faqStatusToColor(initialValues.status.id)}>
                  {initialValues.status?.name}
                </Text>
              ) : null,
            )}
          </Flex>
        }
      />
      <PageBody>
        <MoreBar>
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.APPS.HELP_CENTER.QUESTION.EDIT, params)}
          />
          <ArchiveButton
            isVisible={canEdit}
            globalPermissions={[PermissionTypes.ChangeFaq]}
            statusFieldName="status.id"
            unArchiveStatus={Statuses.active}
          />
        </MoreBar>
        <FormPreview title="General" data={initialValues}>
          <Group>
            <FormPreview.Details title="Question title" field="title" />
            <FormPreview.Item title="Topic" field="topic.title" />
            <FormPreview.Item<FaqInterface>
              title="Maintainer"
              field="maintainer.id"
              insert={() => <UserWithAvatar {...initialValues.maintainer} />}
            />
            <FormPreview.Item<FaqInterface>
              title="Countries"
              field="countries"
              insert={() => (
                <Text>
                  {initialValues.countries?.length
                    ? initialValues.countries.map(country => country.name).join(', ')
                    : '-'}
                </Text>
              )}
            />
            <ExpandableCell>
              <ExpandableCell.Title>Training phrases</ExpandableCell.Title>
              <ExpandableCell.Content>
                {pluralize('phrase', initialValues.training_phrases?.length, true)}
              </ExpandableCell.Content>
              {!!initialValues.training_phrases?.length && (
                <ExpandableCell.Note>
                  <List variant="compact">
                    {initialValues.training_phrases.map(phrase => (
                      <List.Item key={phrase} useIcon="RobotoRegular">
                        {phrase}
                      </List.Item>
                    ))}
                  </List>
                </ExpandableCell.Note>
              )}
            </ExpandableCell>
          </Group>
        </FormPreview>
        <FormPreview title="Content" data={initialValues}>
          <Cell>
            <EditorView html={initialValues.content || ''} />
          </Cell>
        </FormPreview>
      </PageBody>
    </>
  )
}
