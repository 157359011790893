import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar, chain, Item, Token } from '@revolut/ui-kit'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { TablePreview } from '@components/TablePreview/TablePreview'
import { getEmployeeContracts } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { getContractStatusColor } from '@src/constants/columns/contracts'
import { formatMoney } from '@src/utils/format'
import { formatDateWithFallback } from '../common'
import { CommonPreviewProps } from './types'

export const Contracts = ({
  employeeId,
  hideTableRoute,
  detailsTitle,
}: CommonPreviewProps & { detailsTitle?: string }) => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeContract)

  return (
    <TablePreview
      title="Contracts"
      api={{ getItems: getEmployeeContracts(employeeId) }}
      tableRoute={
        hideTableRoute
          ? undefined
          : pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CONTRACT, { id: employeeId })
      }
      detailsTitle={detailsTitle}
      createNewRoute={
        canAdd
          ? pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, {
              employeeId,
            })
          : undefined
      }
      filterByInitial={[
        {
          filters: [
            { id: 'active', name: 'active' },
            { id: 'pending', name: 'pending' },
            { id: 'pending_approval', name: 'pending_approval' },
            { id: 'pending_completion', name: 'pending_completion' },
          ],
          columnName: 'contract_status',
        },
      ]}
      emptyState={{
        title: 'No contracts added',
        icon: 'Document',
      }}
      row={contractData => {
        return (
          <Item
            use="button"
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.PREVIEW, {
                  employeeId,
                  id: contractData.id,
                }),
              )
            }
            key={contractData.id}
          >
            <Item.Avatar>
              <Avatar useIcon="Document" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{`${formatDateWithFallback(
                contractData.start_date,
              )} - ${formatDateWithFallback(
                contractData.end_date,
                'Present',
              )}`}</Item.Title>
              <Item.Description>
                {chain(contractData.title, contractData.contract_type?.name)}
              </Item.Description>
            </Item.Content>
            <Item.Side>
              {contractData.contract_status && (
                <Item.Value
                  color={getContractStatusColor(contractData.contract_status.id)}
                >
                  {contractData.contract_status.name}
                </Item.Value>
              )}
              <Item.Value color={Token.color.greyTone50} fontSize="caption">
                {contractData.annual_salary && contractData.salary_currency
                  ? `${formatMoney(
                      Number(contractData.annual_salary),
                      contractData.salary_currency.iso_code,
                    )} annual`
                  : '-'}
              </Item.Value>
            </Item.Side>
          </Item>
        )
      }}
    />
  )
}
