import React, { useRef } from 'react'
import { Flex, Text, Token, VStack } from '@revolut/ui-kit'
import {
  KeeperQuestionInterface,
  ManagerRecommendationInterface,
  ReviewDataInterface,
  ReviewerRelation,
  ReviewInterface,
  ReviewSummaryDataInterface,
  ReviewViewDataInterface,
} from '@src/interfaces/performance'
import { RecommendationTypes } from '@components/ScorecardRecommendation/ScorecardRecommendation'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { BarRaiserBanner } from '@src/pages/Forms/EmployeePerformanceLayout/components/summary/BarRaiserBanner'
import { BarRaiserSummaryJustification } from './BarRaiserSummaryJustification'
import { getRowRecommendationExtraSection, getRow } from './barRaiserRows'
import { OptionInterface } from '@src/interfaces/selectors'

interface Props {
  data: ReviewSummaryDataInterface | ReviewDataInterface
  completedReviews?: boolean
  hideBanner?: boolean
  checkpointFilters?: OptionInterface[]
}

export interface RecommendationQuestionReviewInterface {
  recommendation: ManagerRecommendationInterface
  type: RecommendationTypes
}

export interface RecommendationQuestionInterface extends KeeperQuestionInterface {
  reviews: RecommendationQuestionReviewInterface[] | null
  questionIdx: number
}

const getRecommendations = (
  data: ReviewSummaryDataInterface | ReviewViewDataInterface,
) => {
  const lmRecommendation = data.line_manager_extra_section
  const fmRecommendation = data.functional_manager_extra_section
  const peerRecommendations = data.peer_extra_sections
  const probationPipRecommendation = data.recommendation_extra_sections

  if (
    !lmRecommendation &&
    !fmRecommendation &&
    !peerRecommendations?.length &&
    !probationPipRecommendation?.length
  ) {
    return null
  }

  return [
    ...(lmRecommendation
      ? [
          {
            recommendation: lmRecommendation,
            type: RecommendationTypes.LM,
          },
        ]
      : []),
    ...(fmRecommendation
      ? [
          {
            recommendation: fmRecommendation,
            type: RecommendationTypes.FM,
          },
        ]
      : []),
    ...(peerRecommendations
      ? peerRecommendations.map(item => ({
          recommendation: item,
          type: RecommendationTypes.PEER,
        }))
      : []),
    ...(probationPipRecommendation
      ? probationPipRecommendation.map(item => ({
          recommendation: item,
          type: RecommendationTypes.PIP,
        }))
      : []),
  ]
}

export const BarRaiserSummary = ({
  data,
  completedReviews = false,
  hideBanner,
  checkpointFilters,
}: Props) => {
  const barRaiserRef = useRef<HTMLButtonElement>(null)
  const recommendations = getRecommendations(data)
  if (!recommendations?.length) {
    return null
  }

  const isRecommendationExtraSection = !!data.recommendation_extra_sections?.length

  const getJustification = (
    recommendation: ManagerRecommendationInterface,
    relation: ReviewerRelation,
  ) => {
    if (
      !recommendation?.employee_project_performance.justification ||
      !recommendation?.reviewer
    ) {
      return null
    }

    return {
      value: recommendation.employee_project_performance.justification,
      review: {
        ...recommendation,
        reviewer_relation: relation,
      } as unknown as ReviewInterface,
    }
  }

  const fmJustification = data.functional_manager_extra_section
    ? getJustification(
        data.functional_manager_extra_section,
        ReviewerRelation.FunctionalManager,
      )
    : null
  const lmJustification = data.line_manager_extra_section
    ? getJustification(data.line_manager_extra_section, ReviewerRelation.LineManager)
    : null

  const justifications = [lmJustification, fmJustification].filter(Boolean)

  const tableData = isRecommendationExtraSection
    ? []
    : recommendations[0].recommendation.keeper_test_section.questions.map(
        (question, idx) => ({
          ...question,
          reviews: recommendations,
          questionIdx: idx,
        }),
      )

  const tableDataRecommendationExtraSection = isRecommendationExtraSection
    ? recommendations[0].recommendation.recommendation?.map((question, idx) => ({
        ...question,
        name: question.title,
        reviews: data.recommendation_extra_sections,
        questionIdx: idx,
      })) || []
    : []

  return (
    <VStack space="s-16" ref={barRaiserRef}>
      {!hideBanner && <BarRaiserBanner />}
      <Text variant="h6" color={Token.color.greyTone50}>
        Bar raiser
      </Text>
      {(tableData?.length || tableDataRecommendationExtraSection) && (
        <>
          <Flex style={{ position: 'relative' }} flex="1 0">
            {isRecommendationExtraSection ? (
              <AdjustableTable
                rowHeight="large"
                name={TableNames.PerformanceSummaryBarRaiser}
                row={getRowRecommendationExtraSection(checkpointFilters)}
                count={tableDataRecommendationExtraSection.length}
                data={tableDataRecommendationExtraSection}
                hideCountAndButtonSection
              />
            ) : (
              <AdjustableTable
                name={TableNames.PerformanceSummaryBarRaiser}
                row={getRow(completedReviews)}
                count={tableData.length}
                data={tableData}
                hideCountAndButtonSection
              />
            )}
          </Flex>
          <BarRaiserSummaryJustification justifications={justifications} />
        </>
      )}
    </VStack>
  )
}
