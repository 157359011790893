import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import {
  addNewInterviewRoundRequest,
  useGetCandidate,
} from '@src/api/recruitment/interviews'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useParams } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { renderOption } from '@src/components/JobPostingOption/JobPostingOption'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { API, selectorKeys } from '@src/constants/api'
import { InputGroup, Group } from '@revolut/ui-kit'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  AddInterviewRoundInterface,
  CandidateInterface,
} from '@src/interfaces/interviewTool'
import ChangeJobPostingSidebar from '@src/pages/Forms/JobPosting/Components/ChangeJobPostingSidebar'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import CandidateFreezingPeriodWarning from '@components/CandidateFreezingPeriodWarning/CandidateFreezingPeriodWarning'
import { useQueryClient } from 'react-query'

const AddInterviewRound = () => {
  const user = useSelector(selectUser)
  const queryClient = useQueryClient()
  const { values } = useLapeContext<AddInterviewRoundInterface>()
  const [openSidebar, setOpenSidebar] = useState(false)
  const { candidateId } = useParams<{ candidateId: string }>()
  const { data: candidate } = useGetCandidate(candidateId)

  const backUrl = pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
    id: candidateId,
  })

  useEffect(() => {
    if (values.job_posting?.id) {
      setOpenSidebar(true)
    }
  }, [values.job_posting])

  useEffect(() => {
    if (!values.created_by) {
      values.created_by = {
        id: user.id,
        name: user.display_name,
      }
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader title="Consider for new job posting" backUrl={backUrl} />
      <PageBody>
        <InputGroup>
          <CandidateFreezingPeriodWarning
            specialisation={values.job_posting?.specialisation}
            candidateId={candidateId}
          />
          <FormPreview data={candidate}>
            <Group>
              <FormPreview.Item<CandidateInterface>
                title="Candidate"
                field="full_name"
                to={data =>
                  pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                    id: data.id,
                  })
                }
              />
              <FormPreview.Item title="Candidate email" field="email" />
            </Group>
          </FormPreview>
          <LapeRadioSelectInput<JobPostingLocationTypeInterface>
            name="job_posting"
            label="Select job posting"
            selector={selectorKeys.job_postings_locations_type}
          >
            {renderOption}
          </LapeRadioSelectInput>
          <LapeRadioSelectInput
            name="created_by"
            label="Sourced by"
            selector={selectorKeys.employee}
          />
        </InputGroup>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          successText="New job posting added"
          useValidator
          afterSubmitUrl={backUrl}
          onAfterSubmit={() => {
            queryClient.invalidateQueries(
              pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }),
            )
          }}
        />
      </PageActions>
      {values.job_posting?.id && (
        <ChangeJobPostingSidebar
          onClose={() => setOpenSidebar(false)}
          selectedJobPostingId={+values.job_posting.id}
          postingIds={[+values.job_posting.id]}
          isOpen={openSidebar}
          isSelector={false}
          title="Selected posting"
          subtitle=""
        />
      )}
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={addNewInterviewRoundRequest}>
    <AddInterviewRound />
  </Form>
))
