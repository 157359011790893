import React, { useState } from 'react'
import {
  MoreBar,
  StatusPopup,
  TableWidget,
  VStack,
  textChain,
  useStatusPopup,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { Plus } from '@revolut/icons'
import { produce } from 'immer'

import { ROUTES } from '@src/constants/routes'
import { useTable } from '@src/components/Table/hooks'
import {
  timeOffPolicyTempAssignments,
  getTimeOffPolicies,
  timeOffPoliciesRequests,
} from '@src/api/timeOff'
import { IdAndName } from '@src/interfaces'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import SelectTableWrapper, {
  SelectTableWrapperOnChangeData,
} from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { getSelectCellConfig } from '@src/components/Table/AdvancedCells/SelectCell/SelectCell'
import {
  timeOffPolicyEditableBalanceColumn,
  timeOffPolicyNameColumn,
} from '@src/constants/columns/timeOff'
import { RowInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  CreateTempPolicyAssignmentInterface,
  TimeOffPolicyInterface,
  TimeOffPolicyTableInterface,
} from '@src/interfaces/timeOff'
import { useGetSelectors } from '@src/api/selectors'
import {
  manageTimeOffPoliciesActionColumn,
  manageTimeOffPoliciesGroupColumn,
} from '@src/constants/columns/manageTimeOffPolicies'
import Message from '../../components/Message'
import { TitleAndSubtitle } from '@src/components/TitleAndSubtitle'
import OnboardingActions from '../../components/OnboardingActions'
import { timeOffConfig } from '../../common/constants'
import { StepCompletedWidget } from '../../components/StepCompletedWidget'
import { useGetOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'
import { PageBody } from '@src/components/Page/PageBody'
import { getStringMessageFromError } from '@src/store/notifications/actions'

const getRow = (
  canEdit: boolean,
  groups: IdAndName[],
  onGroupChange: (row: TimeOffPolicyTableInterface, option: IdAndName | null) => void,
  onEditBalanceSubmitSuccess: () => void,
): RowInterface<TimeOffPolicyTableInterface> => ({
  cells: [
    {
      ...getSelectCellConfig(),
    },
    {
      ...timeOffPolicyNameColumn,
      width: 200,
    },
    {
      ...manageTimeOffPoliciesGroupColumn(groups, onGroupChange),
      width: 200,
    },
    {
      ...timeOffPolicyEditableBalanceColumn(onEditBalanceSubmitSuccess),
      width: 130,
    },
    {
      ...manageTimeOffPoliciesActionColumn(canEdit),
      width: 80,
    },
  ],
})

export const ManageTimeOffPolicies = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffPolicies)
  const canEdit = permissions.includes(PermissionTypes.ChangeTimeOffPolicy)

  const checkpoint = useGetOnboardingCheckpointCategory('timeOff')

  const statusPopup = useStatusPopup()

  const [selectedData, setSelectedData] =
    useState<SelectTableWrapperOnChangeData<TimeOffPolicyInterface>>()
  const [policyUpdatePending, setPolicyUpdatePending] = useState(false)

  const table = useTable({ getItems: getTimeOffPolicies })

  const { data: groups = [] } = useGetSelectors<IdAndName>(selectorKeys.dynamic_groups)

  const updateGroupInTable = (
    row: TimeOffPolicyTableInterface,
    group: IdAndName | null,
  ) => {
    table.setData(
      produce(table.data, draft =>
        draft.map(r => (r.id === row.id ? { ...row, group } : r)),
      ),
    )
  }

  const onChangePolicyGroup = (
    row: TimeOffPolicyTableInterface,
    group: IdAndName | null,
  ) => {
    if (group) {
      setPolicyUpdatePending(true)
      timeOffPoliciesRequests
        .update({ group }, { id: `${row.id}` })
        .then(response => updateGroupInTable(row, response.data.group))
        .catch(error => {
          statusPopup.show(
            <StatusPopup variant="error">
              <StatusPopup.Title>Failed to change policy group</StatusPopup.Title>
              <StatusPopup.Description>
                {getStringMessageFromError(error)}
              </StatusPopup.Description>
            </StatusPopup>,
          )
        })
        .finally(() => setPolicyUpdatePending(false))
    }
  }

  const selectedPolicies = (() => {
    if (selectedData?.selectedRowsData.length) {
      return selectedData.selectedRowsData
        .map(policy => table.data.find(p => p.id === policy.id))
        .filter(policy => policy && !!policy.group)
        .map(policy => ({
          policy,
          groups: [policy!.group],
        }))
    }
    if (selectedData?.isAllSelected) {
      return table.data
        .filter(policy => !selectedData.excludeListIds.has(`${policy.id}`))
        .filter(policy => !!policy.group)
        .map(policy => ({ policy, groups: [policy.group] }))
    }
    return []
  })() as CreateTempPolicyAssignmentInterface[]

  const stepCompleted =
    checkpoint.data?.state.id === 'completed' ||
    checkpoint.data?.current_step === 'Policy assignments'

  const assignPolicies = async () => {
    return timeOffPolicyTempAssignments(selectedPolicies)
  }

  return (
    <>
      {stepCompleted ? (
        <PageBody>
          <StepCompletedWidget />
        </PageBody>
      ) : (
        <PageBody>
          <VStack space="s-16">
            <TitleAndSubtitle
              title="Select the policies you want to apply to each of your groups"
              subtitle="Assign the corresponding policies to each of your groups."
            />

            <Message
              title="We suggested policies just for you"
              description="These are the policies most businesses need"
            />

            <TableWidget>
              {canAdd ? (
                <TableWidget.Actions>
                  <MoreBar>
                    <MoreBar.Action
                      use={InternalLink}
                      to={pathToUrl(ROUTES.FORMS.TIME_OFF_POLICY.EDIT.BASICS)}
                      useIcon={Plus}
                    >
                      Create new policy
                    </MoreBar.Action>
                    <MoreBar.Action
                      use={InternalLink}
                      to={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.ELIGIBILITY_GROUPS}
                      useIcon="Pencil"
                    >
                      Manage groups
                    </MoreBar.Action>
                  </MoreBar>
                </TableWidget.Actions>
              ) : null}

              <TableWidget.Table>
                <SelectTableWrapper
                  enabled
                  onChange={setSelectedData}
                  filters={table.filterBy}
                  tableDataLength={table.data.length}
                >
                  <AdjustableTable
                    name={TableNames.ManageTimeOffPolicies}
                    {...table}
                    useWindowScroll
                    dataType="policy"
                    row={getRow(canEdit, groups, onChangePolicyGroup, table.refresh)}
                    loading={table.loading || policyUpdatePending}
                    hideCountAndButtonSection
                  />
                </SelectTableWrapper>
              </TableWidget.Table>
            </TableWidget>
          </VStack>
        </PageBody>
      )}

      <OnboardingActions
        config={timeOffConfig}
        currentStep="Policy configuration"
        isLastStep={false}
        disableBack={false}
        pendingBack={false}
        disableNext={stepCompleted ? false : !selectedPolicies.length}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.POLICY_ASSIGNMENTS}
        previousRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.INTRO}
        updateSteps={!stepCompleted}
        isForm={false}
        onBeforeSubmit={stepCompleted ? undefined : assignPolicies}
        nextButtonLabel={
          stepCompleted ? 'Next' : textChain('Next', selectedPolicies.length)
        }
      />
    </>
  )
}
