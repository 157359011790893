import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { RowInterface } from '@src/interfaces/data'
import {
  actionsColumn,
  bonusCreatedByColumn,
  bonusCreatedOnColumn,
  bonusDateColumn,
  bonusGrantTypeColumn,
  bonusPeriodColumn,
  bonusReasonColumn,
  bonusTaxabilityColumn,
  bonusUpdatedByColumn,
  bonusUpdatedOnColumn,
  bonusValueColumn,
} from '@src/constants/columns/rewards'
import { getEmployeeBonuses } from '@src/api/importData'
import { CompensationBonusInterface } from '@src/interfaces/compensation'
import Stat from '@src/components/Stat/Stat'
import {
  compensationBonusesDetailsColumn,
  compensationBonusesEmployeeColumn,
} from '@src/constants/columns/importBonuses'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { EditBonusSidebar } from '@src/features/EditBonusSidebar'
import { useBonusSidebar } from '@src/features/EditBonusSidebar/hooks'
import { BonusActions } from '@components/ColumnInserts/BonusActions/BonusActions'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable, useTable } from '@components/TableV2/hooks'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'

const getRows: (
  refresh: () => void,
) => RowInterface<CompensationBonusInterface> = refresh => ({
  cells: [
    {
      ...compensationBonusesEmployeeColumn,
      width: 200,
    },
    {
      ...bonusDateColumn,
      width: 80,
    },
    {
      ...bonusReasonColumn,
      width: 240,
    },
    {
      ...bonusPeriodColumn,
      width: 80,
    },
    {
      ...bonusValueColumn,
      width: 80,
    },
    {
      ...bonusGrantTypeColumn,
      width: 80,
    },
    {
      ...bonusTaxabilityColumn,
      width: 80,
    },
    {
      ...bonusCreatedOnColumn,
      width: 100,
    },
    {
      ...bonusCreatedByColumn,
      width: 150,
    },
    {
      ...bonusUpdatedOnColumn,
      width: 100,
    },
    {
      ...bonusUpdatedByColumn,
      width: 150,
    },
    {
      ...compensationBonusesDetailsColumn,
      width: 240,
    },
    {
      ...actionsColumn,
      width: 180,
      insert: ({ data }) => <BonusActions id={data.id} onDelete={refresh} />,
    },
  ],
})

export const Bonuses = () => {
  const isNewTable = useIsNewTable()
  const permissions = useSelector(selectPermissions)

  const table = useTable<CompensationBonusInterface>({
    getItems: getEmployeeBonuses,
  })

  const { openBonusSidebar, closeBonusSidebar } = useBonusSidebar()

  const canImport = permissions.includes(
    PermissionTypes.AddEmployeeBonusBulkUploadSession,
  )
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeBonus)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Bonuses" val={table?.loading ? undefined : table?.count} mb="s-16" />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar maxCount={3}>
          {canAdd && (
            <PrimaryAction useIcon="Plus" onClick={() => openBonusSidebar()}>
              Add new
            </PrimaryAction>
          )}
          {canImport && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE)}
              useIcon="ShareIOs"
            >
              Import data
            </MoreBar.Action>
          )}
          <SettingsButton
            canView={[PermissionTypes.ViewTotalCompensationPreferences]}
            path={ROUTES.SETTINGS.COMPENSATION.GENERAL}
          />
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>

      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.CompensationBonuses}
          useWindowScroll
          row={getRows(table.refresh)}
          {...table}
          noDataMessage="Bonuses will appear here."
          hideCount
          tableSettings={
            isNewTable
              ? {
                  visible: [],
                  hidden: [
                    bonusGrantTypeColumn.title,
                    bonusTaxabilityColumn.title,
                    bonusCreatedOnColumn.title,
                    bonusUpdatedOnColumn.title,
                    bonusUpdatedByColumn.title,
                    compensationBonusesDetailsColumn.title,
                  ],
                }
              : undefined
          }
        />
      </Table.Widget.Table>
      <EditBonusSidebar
        onAfterSubmit={() => {
          closeBonusSidebar()
          table.refresh()
        }}
      />
    </Table.Widget>
  )
}
