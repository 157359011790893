import React, { useRef } from 'react'

export const useKeyUp = (handler: (e: KeyboardEvent) => void) => {
  const abortControllerRef = useRef<AbortController>()

  React.useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
    const newController = new AbortController()

    window.document.addEventListener('keyup', handler, {
      signal: newController.signal,
    })
    abortControllerRef.current = newController
    return () => {
      newController.abort()
    }
  }, [handler])
}
