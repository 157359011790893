import { AxiosPromise } from 'axios'
import { API } from '@src/constants/api'
import { api } from '@src/api/index'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { ProbationInterface, ProbationStatisticsDTO } from '@src/interfaces/probation'
import { ProbationCycleSettingsInterface } from '@src/interfaces/reviewCycles'

export const getProbationItems = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<ProbationInterface>> =>
  api.get(
    API.PROBATION,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getProbationStatistics = async () =>
  api.get<ProbationStatisticsDTO>(`${API.PROBATION_STATS}`, undefined, 'v2')

export const regenerateProbationScorecards = (cycleId: string) =>
  api.post(`${API.PROBATION_SCORECARDS}/${cycleId}/regenerate`, {}, undefined, 'v2')

export const createProbationCycle = (employeeId: number) =>
  api.post(`/probation/probationCycle/${employeeId}/create`, {}, undefined, 'v2')

export const closeProbationCycle = (cycleId: string) =>
  api.post(`${API.PROBATION_CYCLE}/${cycleId}/close`, {}, undefined, 'v2')

export const deleteProbationCycle = (cycleId: string) =>
  api.delete(`${API.EMPLOYEE_PERFORMANCE_CYCLES}/${cycleId}`, {}, 'v2')

export const probationCycleSettingsRequests: RequestInterfaceNew<ProbationCycleSettingsInterface> =
  {
    get: async ({ id }) => {
      return api.get(`${API.PROBATION_CYCLE}/${id}`, {}, 'v2')
    },
    update: async (data, { id }) => {
      return api.put(`${API.PROBATION_CYCLE}/${id}`, data, {}, 'v2')
    },
    submit: async (data, { id }) => {
      return api.post(`${API.PROBATION_CYCLE}/${id}`, data, {}, 'v2')
    },
  }
