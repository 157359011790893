import { useFetch } from '@src/utils/reactQuery'

export const useSandboxStatus = (enabled: boolean = false) => {
  const result = useFetch<{ sandbox_db_ready: boolean }>(
    `/sandboxStatus`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: data => (data?.sandbox_db_ready ? false : 1000),
      enabled,
    },
  )

  return result.data
}
