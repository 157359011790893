import React from 'react'
import { Box, Bullet, Color, Flex, Link, Text } from '@revolut/ui-kit'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { format } from 'date-fns'
import { PerformanceSelectorReview } from '@src/interfaces/performance'
import { Widget } from '@revolut/ui-kit'

type Props = {
  category: ReviewCycleCategory
  reviews: PerformanceSelectorReview[]
}

const KeyDates = ({ category, reviews }: Props) => {
  const steps = ['First', 'Second', 'Third']

  return (
    <Box mt="s-48">
      <Text color={Color.GREY_TONE_50} fontWeight={500}>
        Key dates
      </Text>
      <Widget p="s-16" mt="s-16">
        {reviews.map((review, i) => (
          <Flex alignItems="center" key={review.submit_date}>
            <Bullet>{i + 1}</Bullet>{' '}
            <Text p="s-16" use="div" fontSize="primary">
              {steps[i]} checkpoint: {format(new Date(review.submit_date!), 'd MMM yyyy')}
            </Text>
          </Flex>
        ))}
        {category === ReviewCycleCategory.Probation && (
          <Text pt="s-16" pb="s-16" use="div">
            More about the probation process{' '}
            <Link
              href="https://revolut.atlassian.net/wiki/spaces/REV/pages/1470902617/Probation+Guideline+for+new+joiners+in+London"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </Link>
          </Text>
        )}
        {category === ReviewCycleCategory.PIP && (
          <Text pt="s-16" pb="s-16" use="div">
            More about the performance improvement plan process{' '}
            <Link
              href="https://revolut.atlassian.net/wiki/x/YAJaTQ"
              target="_blank"
              rel="noopener noreferrer"
            >
              here.
            </Link>
          </Text>
        )}
      </Widget>
    </Box>
  )
}

export default KeyDates
