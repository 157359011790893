import React from 'react'
import { CheckSuccess, RadiobuttonOff } from '@revolut/icons'
import { Box, Token } from '@revolut/ui-kit'
import Icon from '@components/Icon/Icon'

interface ValidationPreProps {
  isVisible?: boolean
  isValid?: boolean
  isPending?: boolean
}

const ValidationPre = ({ isVisible, isValid, isPending }: ValidationPreProps) => {
  if (!isVisible) {
    return null
  }
  return (
    <Box mr="s-8" mb="s-2" height="100%">
      {isValid ? (
        <>
          {isPending ? (
            <Icon type="Time" color={Token.color.warning} size="tiny" />
          ) : (
            <CheckSuccess color="success" size={15} />
          )}
        </>
      ) : (
        <RadiobuttonOff size={15} />
      )}
    </Box>
  )
}

export default ValidationPre
