import React, { useEffect } from 'react'
import { Group } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useQueryClient } from 'react-query'

import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import { API } from '@src/constants/api'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillSettingsInterface } from '@src/interfaces/settings'

export const Skills = () => {
  const { values } = useLapeContext<SkillSettingsInterface>()
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)

  const disableEdit = !permissions.includes(PermissionTypes.ChangeSkillsPreferences)

  useEffect(() => {
    if (!values.behaviours_assessment_enabled) {
      values.company_values_validation_enabled = false
    }
  }, [values.behaviours_assessment_enabled])

  return (
    <>
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle
            title="General"
            subtitle="All settings for skills, values and behaviours"
          />

          <Group>
            <ApprovalSteps
              title="Enable Skills approval"
              description="Ask for approval whenever a skill is created or changed."
              switchField="skills_approvals_enabled"
              approvalProcess="skills_approvals"
              entity="skill"
              disabled={disableEdit}
              formRef={values}
            />
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Assess values based on behaviours',
                description:
                  'By default the values are assessed in the same way as the skills, using the competencies. By enabling this values will be assessed using the behavioral questions.',
              }}
              name="behaviours_assessment_enabled"
              disabled={disableEdit}
            />
            {values.behaviours_assessment_enabled && (
              <LapeNewSwitch
                itemTypeProps={{
                  title: 'Limit the number of min/max allowed culture ratings',
                  description:
                    'Enabling this will limit the minimum number of improvement focus ratings allowed to 2, and the maximum number of superpower ratings allowed to 3, in the culture fit assessment.',
                }}
                name="company_values_validation_enabled"
                disabled={disableEdit}
              />
            )}
          </Group>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.SKILL_SETTINGS)
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
