import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { payrollSettingsRequests } from '@src/api/settings'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { PayGroups } from './PayGroups'
import { General } from './General'

const routes = [
  {
    url: ROUTES.SETTINGS.PAYROLL.GENERAL,
    path: ROUTES.SETTINGS.PAYROLL.GENERAL,
    component: General,
    title: 'General',
    canView: [PermissionTypes.ViewPayrollPreferences],
  },
  {
    url: ROUTES.SETTINGS.PAYROLL.CONFIGURATION,
    path: ROUTES.SETTINGS.PAYROLL.CONFIGURATION,
    component: PayGroups,
    title: 'Pay groups',
    canView: [PermissionTypes.ViewPaygroup],
  },
]

export const PayrollSettings = () => {
  return (
    <SettingsForm
      api={payrollSettingsRequests}
      routes={routes}
      title="Payroll app settings"
      subtitle="Settings for the payroll functionalities"
    />
  )
}
