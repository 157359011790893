import React from 'react'
import { Circle, Color, HStack, Text } from '@revolut/ui-kit'
import { iconWhitelist } from './iconWhitelist'

interface CategoryIconProps {
  name?: string | null
  size?: number
  iconSize?: number
  iconColor?: Color
  showIconName?: boolean
}

export const CategoryIcon = ({
  name,
  size = 30,
  iconSize = 20,
  iconColor = Color.BLUE,
  showIconName,
}: CategoryIconProps) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const Icon = name ? iconWhitelist[name] : null

  return (
    <HStack space="s-16" align="center">
      <Circle bg={Color.BLUE_10} size={size}>
        {Icon ? <Icon size={iconSize} color={iconColor} variant="24" /> : null}
      </Circle>
      {showIconName ? <Text>{name}</Text> : null}
    </HStack>
  )
}
