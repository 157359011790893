import React, { useContext, useEffect, useState } from 'react'
import { Box, Flex, Skeleton, TabBar, Text, Token, Widget } from '@revolut/ui-kit'
import { ResponsiveBar } from '@nivo/bar'
import { RecruitmentAnalytics } from '@src/interfaces/specialisations'
import { getRecruitmentAnalytics } from '@src/api/specialisations'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import styled from 'styled-components'
import { isNil } from 'lodash'
import { formatDate, formatDateDistance } from '@src/utils/format'
import { decl } from '@src/utils/string'
import { BarChart } from '@revolut/icons'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import { ChartInsideTooltipContext } from '@components/Charts/ChartInsideTooltip/ChartInsideTooltip'

type Props = {
  requisitionId?: number
  specialisationId?: number
  headcountBefore?: number
  tthDate?: string
  chartHeight?: string
  hideStats?: boolean
}

enum Tabs {
  TimeTab = 'Time',
  PipelineTab = 'Pipeline',
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`

const Pipeline = ({
  requisitionId,
  specialisationId,
  headcountBefore,
  tthDate,
  chartHeight,
  hideStats = false,
}: Props) => {
  const chartContext = useContext(ChartInsideTooltipContext)
  const [loading, setLoading] = useState(true)
  const [tab, setTab] = useState(Tabs.TimeTab)
  const [data, setData] = useState<RecruitmentAnalytics>()

  useEffect(() => {
    if (!specialisationId) {
      return
    }
    setLoading(true)
    getRecruitmentAnalytics(specialisationId)
      .then(res => {
        setData(res.data)
      })
      .finally(() => setLoading(false))
  }, [specialisationId])

  if (!specialisationId) {
    return null
  }

  if (loading) {
    return (
      <Widget p="s-16" height={chartHeight ? undefined : '100%'}>
        <Skeleton height={32} mb="s-32" />
        <Skeleton height={chartHeight || 'calc(100% - 70px)'} radius="card" />
      </Widget>
    )
  }

  const noData =
    tab === Tabs.TimeTab && !data?.duration_by_stage.some(s => s.duration !== null)

  return (
    <Widget p="s-16" height={chartHeight ? undefined : '100%'}>
      <TabBar variant="segmented">
        <TabBar.Item
          onClick={() => setTab(Tabs.TimeTab)}
          aria-selected={tab === Tabs.TimeTab}
        >
          Time to hire
        </TabBar.Item>
        <TabBar.Item
          onClick={() => setTab(Tabs.PipelineTab)}
          aria-selected={tab === Tabs.PipelineTab}
        >
          Candidate pipeline
        </TabBar.Item>
      </TabBar>
      {!hideStats && (
        <Box mt="s-16" mb="s-16">
          <Text use="div" fontSize="small" color="grey-35">
            <Grid>
              <>
                <span>Headcount before</span>
                <span> : {!isNil(headcountBefore) ? headcountBefore : '-'}</span>
              </>
              <>
                <span>Estimated time to fill</span>
                <span>
                  :
                  {tthDate
                    ? ` ${formatDateDistance(new Date(tthDate))} (${formatDate(tthDate)})`
                    : ' -'}
                </span>
              </>
              <>
                <span>Pipeline AVG time</span>
                <span>
                  :
                  {data?.average_duration
                    ? ` ${data.average_duration} ${decl(
                        data.average_duration,
                        'day',
                        'days',
                      )}`
                    : ' -'}
                </span>
              </>
            </Grid>
          </Text>
          {data?.candidate_count && (
            <InternalUIKitLink
              // @ts-expect-error object works fine here, but UI kit expects string
              to={pathToUrl(ROUTES.FORMS.REQUISITION.CANDIDATES, {
                id: requisitionId,
              })}
              fontSize="small"
            >
              Show candidates ({data.candidate_count})
            </InternalUIKitLink>
          )}
        </Box>
      )}
      <Text fontWeight={500} fontSize="small" pt="s-16" use="div">
        {tab === Tabs.TimeTab ? 'Days at each round' : 'Number of candidates in pipeline'}
      </Text>
      <Flex
        height={chartHeight || 'calc(100% - 200px)'}
        onClick={chartContext?.onChartClick}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        {noData && (
          <>
            <BarChart color="grey-tone-50" />
            <Text color="grey-tone-50">No data to display</Text>
          </>
        )}
        {data && !noData && (
          <ResponsiveBar
            data={
              tab === Tabs.TimeTab ? data.duration_by_stage : data.candidates_by_stage
            }
            keys={tab === Tabs.TimeTab ? ['duration'] : ['candidates']}
            indexBy="stage"
            enableLabel
            labelFormat={
              labelValue =>
                (
                  <tspan y={-8} fill={Token.color.greyTone50}>
                    {labelValue}
                  </tspan>
                ) as any as string // hack: nivo doesn't support labels above the bars
            }
            margin={{ top: 20, right: 40, bottom: 85, left: 0 }}
            padding={0.85}
            colors={[Token.color.blue]}
            theme={{
              axis: {
                ticks: {
                  line: {
                    stroke: Token.color.greyTone50,
                  },
                  text: {
                    fill: Token.color.greyTone50,
                  },
                },
              },
              grid: {
                line: {
                  strokeWidth: 0.5,
                },
              },
              tooltip: {
                container: {
                  background: '#333',
                  padding: '10px 15px',
                  borderRadius: 4,
                },
              },
            }}
            axisTop={null}
            axisLeft={null}
            axisBottom={{
              tickPadding: 8,
              tickSize: 0,
              tickRotation: 50,
            }}
            axisRight={{
              tickValues: 5,
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              format: (d: any) => parseFloat(d as string),
            }}
            gridYValues={5}
            tooltip={({ value }: any) => <Text color="background">{value}</Text>}
            legends={[
              {
                dataFrom: 'keys',
                anchor: 'top-right',
                direction: 'column',
                justify: false,
                translateX: 190,
                translateY: 0,
                itemsSpacing: 10,
                itemWidth: 100,
                itemHeight: 15,
                itemTextColor: Token.color.greyTone50,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]}
            animate={false}
            motionStiffness={90}
            motionDamping={15}
          />
        )}
      </Flex>
    </Widget>
  )
}

export default Pipeline
