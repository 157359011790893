import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { useGetJobDescriptionOfRequisition } from '@src/api/requisitions'
import { Group, ItemSkeleton } from '@revolut/ui-kit'
import StartHiring from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/StartHiring'
import Overview from '@src/pages/Forms/RequisitionForm/Preview/JobPostingWidget/Overview'

const JobPostingLayout = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const {
    data: jobPosting,
    isLoading: loadingJobPosting,
    refetch: refetchJobPosting,
  } = useGetJobDescriptionOfRequisition(values.id)

  const handleRefreshJobPosting = async () => {
    await refetchJobPosting()
  }

  if (loadingJobPosting) {
    return (
      <Group>
        <ItemSkeleton />
      </Group>
    )
  }
  if (!loadingJobPosting && !jobPosting) {
    return <StartHiring onRefresh={handleRefreshJobPosting} />
  }
  return (
    <Overview jobPosting={jobPosting?.job_posting!} onRefresh={handleRefreshJobPosting} />
  )
}

export default JobPostingLayout
