import React from 'react'
import { Item, List, Spacer, Text, Token, VStack } from '@revolut/ui-kit'
import { Dot } from '@revolut/icons'
import { Video } from '@components/Video/Video'

const JobPostingsIntro = () => {
  return (
    <VStack gap="s-16">
      <Video
        url="/intros/Intro%20-%20Postings.mp4"
        poster="/intros/Intro%20-%20Postings.png"
      />
      <Item>
        <Item.Content>
          <Item.Title>What are job postings?</Item.Title>
          <Item.Description>
            <Text use="p">
              Dive into <Text fontWeight={500}>Revolut People Recruitment</Text> app with
              our <Text fontWeight={500}>job postings</Text> feature, your one-stop place
              for showcasing the best of what your organisation has to offer and what it
              expects from potential candidates!
            </Text>
            <Spacer height={16} />
            <Text use="p">
              Each posting serves as a concise snapshot of a role, highlighting key
              elements like job title, company info, role overview, responsibilities, and
              the required skills. Once it’s live, it’s accessible both internally and on
              the Careers Page provided with the product.
            </Text>
            <Spacer height={16} />
            <Text use="p">Job postings are serving multiple purposes:</Text>
            <List variant="compact">
              <List.Item useIcon={Dot} color={Token.color.greyTone50}>
                Talent Magnet: A well-crafted posting can capture attention and spark
                interest among top talents, getting them to consider hopping aboard.
              </List.Item>
              <List.Item useIcon={Dot} color={Token.color.greyTone50}>
                Inside Look: Each posting offers a clear glimpse into your organisation
                and the specifics of the role, ensuring candidates have a comprehensive
                understanding of what they’re stepping into.
              </List.Item>
              <List.Item useIcon={Dot} color={Token.color.greyTone50}>
                Seamless Tracking: Every posting serves as an entry point to the ATS. From
                hitting ‘Apply’ to getting hired, each stage of a candidate’s journey is
                meticulously tracked within the product.
              </List.Item>
            </List>
            <Spacer height={16} />
            <Text use="p">
              You’ll have the chance to fine-tune how postings are handled in-app and
              tailor the application forms that candidates will navigate through! Stay
              tuned!
            </Text>
          </Item.Description>
        </Item.Content>
      </Item>
    </VStack>
  )
}

export default JobPostingsIntro
