import React from 'react'
import { Box, chain, Text, TextSkeleton, Tooltip, useTooltip } from '@revolut/ui-kit'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import {
  useGradesDistribution,
  Grade,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useGradesDistribution'
import { formatPercentage } from '@src/utils/format'

interface Props {
  cycle: ReviewCyclesInterface
}

export const GradesDistribution = ({ cycle }: Props) => {
  const { grades, isLoading } = useGradesDistribution(cycle)

  if (!cycle.grade_distribution) {
    return null
  }

  if (isLoading) {
    return <TextSkeleton width={200} />
  }

  return (
    <Box style={{ cursor: 'default' }}>
      {chain(grades.map(grade => <GradeItem key={grade.name} {...grade} />))}
    </Box>
  )
}

const GradeItem = ({ name, value }: Grade) => {
  const tooltip = useTooltip()

  return (
    <>
      <Text {...tooltip.getAnchorProps()}>{formatPercentage(value)}</Text>
      <Tooltip {...tooltip.getTargetProps()}>{name}</Tooltip>
    </>
  )
}
