import React, { useState } from 'react'
import { AxiosPromise } from 'axios'
import { format } from 'date-fns'
import get from 'lodash/get'
import { HStack, Icon, SelectOptionItemType, Text, Token } from '@revolut/ui-kit'

import { TableActionButton } from '@src/components/Button/TableActionButton'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { selectorKeys } from '@src/constants/api'
import { ImportState } from '@src/features/BulkDataImport/ImportState'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { IdAndName } from '@src/interfaces'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { getDateFromString, utcToLocalDate } from '@src/utils/timezones'

export const importStateColumn: ColumnInterface<ImportInterface<{}>> = {
  type: CellTypes.insert,
  idPoint: 'state.id',
  dataPoint: 'state.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'State',
  insert: ({ data }) => <ImportState data={data} />,
}

interface DeleteRowActionProps {
  onDelete: () => AxiosPromise<any>
  onDeleteSuccess: () => void
}

const DeleteRowAction = ({ onDelete, onDeleteSuccess }: DeleteRowActionProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [deletePending, setDeletePending] = useState(false)

  const onDeleteConfirm = async () => {
    setDeletePending(true)

    try {
      await onDelete()
      onDeleteSuccess()
      setConfirmationOpen(false)
    } finally {
      setDeletePending(false)
    }
  }

  return (
    <>
      <TableActionButton
        onClick={e => {
          e.stopPropagation()
          setConfirmationOpen(true)
        }}
        color="red"
      >
        Delete
      </TableActionButton>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        onConfirm={onDeleteConfirm}
        loading={deletePending}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Confirm"
        noMessage="Cancel"
        body="Are you sure you want to delete this row?"
      />
    </>
  )
}

export const createImportActionsColumn = <T,>(
  sessionId: number,
  onEdit: (id: number) => void,
  onDelete: (sessionId: number, dataId: number) => AxiosPromise<any>,
  onDeleteSuccess: () => void,
) => {
  const importActionsColumn: ColumnInterface<ImportInterface<T>> = {
    type: CellTypes.insert,
    idPoint: 'action',
    dataPoint: 'action',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
    insert: ({ data }) => {
      return (
        <HStack space="s-16">
          <TableActionButton onClick={() => onEdit(data.id)}>Edit</TableActionButton>
          <DeleteRowAction
            onDelete={() => onDelete(sessionId, data.id)}
            onDeleteSuccess={onDeleteSuccess}
          />
        </HStack>
      )
    },
  }
  return importActionsColumn
}

type ImportCellTypes =
  | {
      type: 'employee'
      options?: SelectOptionItemType<
        IdAndName<string | number> & { full_name?: string }
      >[]
    }
  | {
      type?: 'date' | 'date-no-timezone' | 'option'
      options?: SelectOptionItemType<IdAndName<string | number>>[]
    }

type ImportCellProps<T> = {
  data: ImportInterface<T>
  field: string
  renderDisplayValue?: (
    value: string,
    errors: string[],
    hasError: boolean,
    data: ImportInterface<T>,
  ) => React.ReactNode
} & ImportCellTypes

export const ImportCell = <T,>({
  data,
  field,
  type,
  options,
  renderDisplayValue,
}: ImportCellProps<T>) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const errors = data.errors?.[field]
  const value = get(data.data, field)
  const hasError = !!errors?.length

  const displayValue = () => {
    if (renderDisplayValue) {
      return renderDisplayValue(value, errors, hasError, data) ?? '-'
    }
    const isDateType = type && ['date', 'date-no-timezone'].includes(type)

    if (value && isDateType) {
      try {
        return format(
          utcToLocalDate(type === 'date-no-timezone' ? getDateFromString(value) : value),
          'd MMM yyyy',
        )
      } catch {
        // showing invalid date as is
        return value
      }
    }
    if (value && options && type === 'employee') {
      return options.find(opt => opt.value.name === value)?.value.full_name || value
    }
    if (value && options && type === 'option') {
      return options.find(opt => opt.value.id === value)?.label || value
    }
    if (value) {
      return value
    }
    return '-'
  }

  return (
    <Tooltip text={errors?.join('\n')} placement="top" hide={!hasError} noArrow>
      <HStack width="100%" space="s-8">
        <Text color={hasError ? Token.color.red : undefined}>{displayValue()}</Text>
        {hasError ? (
          <Icon
            name="ExclamationTriangle"
            color={Token.color.red}
            size={18}
            aria-label={`${field} field error`}
          />
        ) : null}
      </HStack>
    </Tooltip>
  )
}

export const importExtraFieldColumn = <T,>(
  field: string,
): ColumnInterface<ImportInterface<T>> => ({
  type: CellTypes.insert,
  idPoint: field,
  dataPoint: field,
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: field,
  insert: ({ data }) => <ImportCell field={field} data={data} />,
})
