import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { Ticket } from '@src/pages/Tickets/Ticket/Ticket'
import { Tickets as TicketsList } from '@src/pages/Tickets/Tickets/Tickets'

export const Tickets = () => {
  return (
    <Switch>
      <Route path={ROUTES.TICKETS.ALL} exact>
        <TicketsList />
      </Route>
      <Route path={ROUTES.TICKETS.DETAILS} exact>
        <Ticket />
      </Route>
    </Switch>
  )
}
