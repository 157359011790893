import React, { useEffect } from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewerRelation,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import * as yup from 'yup'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import DeliverablesHelp from '@src/pages/Forms/EmployeePerformance/components/HelpSections/DeliverablesHelp'
import { Statuses } from '@src/interfaces'

export const validator = {
  review_data: yup.object().shape({
    deliverables: yup.object().shape({
      skipped_section_justification: yup
        .string()
        .test('no-empty', 'Justification should not be empty', text =>
          typeof text?.trim() === 'string' ? text.trim().length > 0 : true,
        ),
      cards: yup.array().when('skipped_section_justification', {
        is: undefined,
        then: yup.array().of(
          yup.object().shape({
            justifications: yup
              .array()
              .of(
                yup.object().shape({
                  reference_url: yup
                    .string()
                    .url(
                      'Link should be a valid url (e.g. https://www.figma.com/file/..., https://revolut.atlassian.net/browse/PERF-...)',
                    )
                    .nullable(),
                }),
              )
              .nullable(),
            sections: yup.array().of(
              yup.object().shape({
                value: yup.string().required(),
              }),
            ),
          }),
        ),
        otherwise: yup.array(),
      }),
      justifications: yup
        .array()
        .of(
          yup.object().shape({
            reference_url: yup
              .string()
              .url(
                'Link should be a valid url (e.g. https://www.figma.com/file/..., https://revolut.atlassian.net/browse/PERF-...)',
              )
              .nullable(),
          }),
        )
        .nullable(),
    }),
  }),
}

const Deliverables = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors, submitFailed, fields, change } = form
  const cards = values?.review_data?.deliverables?.cards

  useEffect(() => {
    if (values.review_data.prefilled && values.status !== Statuses.completed) {
      Object.values(fields).forEach(state => {
        state.touched = true
      })
      form.submitFailed = true
      return
    }

    // We should reset submitFailed state when we enter this page to avoid showing validation errors
    if (submitFailed) {
      form.submitFailed = false
    }
  }, [])

  if (!cards) {
    return null
  }

  const onSkip = (value?: string) => {
    change('review_data.deliverables.skipped_section_justification', value)
  }

  const onChange = ({ cardIndex, sectionIndex, value }: ChangeScorecardInterface) => {
    const path = `review_data.deliverables.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.value`, value)
  }

  if (values.reviewed_employee_type?.id === 'individual_contributor') {
    const complexityCard = cards.find(card => card.criteria_key === 'complexity')

    if (complexityCard) {
      complexityCard.titleTooltip =
        'The Complexity component is capped at Intermediate for Individual Contributors'
    }
  }

  return (
    <ScorecardGeneral
      onChange={onChange}
      cards={cards}
      allowSkip={
        values.category === ReviewCategory.Performance &&
        values.reviewer_relation !== ReviewerRelation.LineManager
      }
      onSkip={onSkip}
      justification={values?.review_data?.deliverables?.skipped_section_justification}
      title="Deliverables"
      type={PerformanceReviewTypes.deliverables}
      errors={
        submitFailed
          ? (errors.review_data?.deliverables?.cards as ScorecardError[])
          : null
      }
      touched={Object.values(fields).some(state => state.touched)}
      previousReviews={values?.review_data?.previous_reviews_by_cycle}
      helpSection={<DeliverablesHelp />}
      category={values.category}
      hideJustification
    />
  )
}

export default connect(Deliverables)
