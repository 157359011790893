import { useParams } from 'react-router-dom'
import {
  Canceled,
  Check,
  CrossCircle,
  Question,
  SandWatch,
  StatusClockArrows,
} from '@revolut/icons'
import {
  HirerightAdjudicationStatus,
  ScreeningCheckStatuses,
} from '@src/interfaces/screeningChecksInterface'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import React from 'react'
import { Token } from '@revolut/ui-kit'

export const useRouteParams = () => useParams<{ id: any; employeeId: string }>()

export const getScreeningStatusColor = (status?: ScreeningCheckStatuses) => {
  switch (status) {
    case ScreeningCheckStatuses.pending:
    case ScreeningCheckStatuses.pendingApplicant:
    case ScreeningCheckStatuses.requiresAdjudication:
    case ScreeningCheckStatuses.sentToApplicant:
    case ScreeningCheckStatuses.needsAdjudication:
      return Token.color.orange
    case ScreeningCheckStatuses.inProgress:
    case ScreeningCheckStatuses.inviteExpired:
      return Token.color.greyTone50
    case ScreeningCheckStatuses.cancelled:
      return Token.color.deepGrey
    case ScreeningCheckStatuses.progress:
    case ScreeningCheckStatuses.completed:
    case ScreeningCheckStatuses.Completed:
      return Token.color.green
    case ScreeningCheckStatuses.failed:
      return Token.color.red
    default:
      return Token.color.greyTone20
  }
}

export const getHirerightAdjudicationStatusColor = (
  status?: HirerightAdjudicationStatus,
) => {
  switch (status) {
    case HirerightAdjudicationStatus.meetsCompanyStandards:
      return Token.color.green
    case HirerightAdjudicationStatus.clientReviewRequired:
    case HirerightAdjudicationStatus.pendingPotentialConflict:
      return Token.color.orange
    case HirerightAdjudicationStatus.customStatus:
      return Token.color.greyTone50
    case HirerightAdjudicationStatus.doesntMeetCompanyStandards:
    case HirerightAdjudicationStatus.canceled:
      return Token.color.red
    default:
      return Token.color.foreground
  }
}

export const screeningStatusToIcon = (status?: ScreeningCheckStatuses) => {
  switch (status) {
    case ScreeningCheckStatuses.pending:
    case ScreeningCheckStatuses.pendingApplicant:
    case ScreeningCheckStatuses.sentToApplicant:
    case ScreeningCheckStatuses.inviteExpired:
      return SandWatch
    case ScreeningCheckStatuses.inProgress:
    case ScreeningCheckStatuses.requiresAdjudication:
      return StatusClockArrows
    case ScreeningCheckStatuses.completed:
    case ScreeningCheckStatuses.Completed:
      return Check
    case ScreeningCheckStatuses.cancelled:
      return Canceled
    case ScreeningCheckStatuses.failed:
      return CrossCircle
    default:
      return Question
  }
}

export const backUrl = (employeeId: string) =>
  pathToUrl(ROUTES.FORMS.SCREENING.SELECT, employeeId ? { id: employeeId } : undefined)

export const subtitle = (employeeId: string) =>
  employeeId ? <EmployeeUserWithAvatar id={employeeId} /> : undefined
