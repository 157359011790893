import { RowInterface } from '@src/interfaces/data'
import { KpiInterface, KPIWeightModes } from '@src/interfaces/kpis'
import {
  editableKpiWeightColumn,
  kpiCurrentValueColumn,
  kpiEntityColumn,
  kpiGenericNameColumn,
  kpiInitialValueColumn,
  kpiLevelColumn,
  kpiParentColumn,
  kpiParentOwnerColumn,
  kpiPerformanceColumn,
  kpiPerformanceTypeColumn,
  kpiStatusColumn,
  kpiTargetColumn,
  kpiUnitColumn,
  kpiUpdateTypeColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { EntityTypes } from '@src/constants/api'
import { EditableRowInterface } from '@components/Table/EditableTable/EditableTable'
import { getDeleteActionColumn } from '@src/constants/columns/common'

const employeeCells: RowInterface<KpiInterface>['cells'] = [
  {
    ...kpiLevelColumn,
    width: 120,
  },
  {
    ...kpiEntityColumn,
    width: 200,
  },
]

const teamAndDepartmentCells: RowInterface<KpiInterface>['cells'] = [
  {
    ...kpiPerformanceTypeColumn,
    width: 115,
  },
]

export const getReadonlyRow = (entityType: EntityTypes): RowInterface<KpiInterface> => {
  const isEmployee = entityType === EntityTypes.employee
  const entitySpecificCells = isEmployee ? employeeCells : teamAndDepartmentCells

  return {
    cells: [
      {
        ...kpiGenericNameColumn,
      },
      {
        ...kpiPerformanceColumn,
        width: 130,
      },
      ...entitySpecificCells,
      {
        ...kpiInitialValueColumn,
        width: 70,
      },
      {
        ...kpiCurrentValueColumn,
        width: 70,
      },
      {
        ...kpiTargetColumn,
        width: 70,
      },
      {
        ...kpiUnitColumn,
        width: 70,
      },
      {
        ...kpiParentColumn,
        width: 80,
      },
      {
        ...(isEmployee ? kpiParentOwnerColumn : ownerNameWithAvatarColumn),
        width: 200,
      },
      {
        ...kpiUpdateTypeColumn,
        width: 120,
      },
      {
        ...kpiStatusColumn,
        width: 80,
      },
    ],
  }
}

export const getEditableRow = (
  handleDelete: (id: number | string) => Promise<void>,
  entityType: EntityTypes,
  weightMode: KPIWeightModes,
): EditableRowInterface<KpiInterface> => {
  const isEmployee = entityType === EntityTypes.employee
  const entitySpecificCells = isEmployee ? employeeCells : teamAndDepartmentCells

  return {
    cells: [
      {
        ...kpiGenericNameColumn,
      },
      {
        ...(weightMode === KPIWeightModes.auto
          ? kpiWeightColumn
          : editableKpiWeightColumn),
        width: 120,
      },
      {
        ...kpiPerformanceColumn,
        width: 130,
      },
      ...entitySpecificCells,
      {
        ...kpiInitialValueColumn,
        width: 70,
      },
      {
        ...kpiCurrentValueColumn,
        width: 70,
      },
      {
        ...kpiTargetColumn,
        width: 70,
      },
      {
        ...kpiUnitColumn,
        width: 70,
      },
      {
        ...kpiParentColumn,
        width: 80,
      },
      {
        ...(isEmployee ? kpiParentOwnerColumn : ownerNameWithAvatarColumn),
        width: 200,
      },
      {
        ...kpiUpdateTypeColumn,
        width: 120,
      },
      {
        ...kpiStatusColumn,
        width: 80,
      },
      {
        ...getDeleteActionColumn({ handleDelete }),
        width: 70,
      },
    ],
  }
}
