import React, { useEffect } from 'react'
import { InputGroup, Side, Subheader } from '@revolut/ui-kit'

import {
  ContractorInterface,
  EmployeeInterface,
  EmployeeType,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
} from '@src/interfaces/employees'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'

import { UpdateButton } from '../common'
import { useGetEmployeeSettings } from '@src/api/settings'
import { useEmployeeDetailsOnAfterSubmit } from '../hooks'

export interface Props {
  data: EmployeeInterface
  refreshData?: () => void
  type: EmployeeType
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
}

const Name = ({ dynamicGroups, type, data, refreshData, isSidebarContent }: Props) => {
  const form = useLapeContext<EmployeeInterface | ContractorInterface>()
  const { values } = form

  const { data: settings } = useGetEmployeeSettings()

  useEffect(() => {
    if (!values.id && values.first_name && values.last_name) {
      values.display_name = `${values.first_name} ${values.last_name}`
    }
  }, [values.first_name, values.last_name])

  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperSectionCustomFields
            sectionId={SectionOptions.EmployeeProfile}
            subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.BASIC_INFO}
            dynamicGroups={dynamicGroups}
          >
            {values.id && !!settings?.enable_show_employee_id && (
              <Subheader variant="nested">
                <Subheader.Title>{`Employee ID: ${values.id}`}</Subheader.Title>
              </Subheader>
            )}
            <NewStepperTitle title="Legal names" mt="s-8" />
            <InputGroup>
              <LapeNewInput
                name="first_name"
                label="Legal first name"
                message="Your first name as it appears on your identification documents"
                required
              />
              <LapeNewInput
                name="middle_name"
                label="Middle name"
                message="Your middle name as it appears on your identification documents"
              />
              <LapeNewInput
                name="last_name"
                label="Legal last name"
                message="Your last name as it appears on your identification documents"
                required
              />
            </InputGroup>

            <NewStepperTitle title="Preferred name" />
            <LapeNewInput
              name="display_name"
              label="Preferred name"
              message="The name you prefer to be called e. g. your legal first name is Jonathan, but your friends call you Jon"
            />

            <NewStepperTitle title="Work email" />
            <LapeNewInput name="email" label="Work email" required />
          </NewStepperSectionCustomFields>
        </AutoStepper>
      </PageBody>
      <ActionsWrapper>
        <UpdateButton
          form={form}
          employeeTypeChanged={type !== data?.employee_type}
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </>
  )
}

export default Name
