import React from 'react'
import { selectorKeys } from '@src/constants/api'
import FilterButtonRadioSelect from '@src/components/FilterButtonRadioSelect/FilterButtonRadioSelect'
import { useGetSelectors } from '@src/api/selectors'

type SpecialisationFilterButtonProps = {
  specialisationId?: number | string
  onChangeSpecialisation: (specialisationId?: number | string) => void
}

export const SpecialisationFilterButton = ({
  specialisationId,
  onChangeSpecialisation,
}: SpecialisationFilterButtonProps) => {
  const { data, isLoading } = useGetSelectors(selectorKeys.specialisations)
  const options = data ?? []
  const value = options?.find(opt => opt.id === specialisationId)
  return (
    <FilterButtonRadioSelect
      clearable
      disabled={isLoading}
      label="Specialisation"
      options={options}
      searchable
      value={value}
      onChange={opt => {
        onChangeSpecialisation(opt?.id)
      }}
    />
  )
}
