import { getEmployeeRoadmaps, getRoadmaps } from '@src/api/roadmaps'
import { useGetRoadmapSettings } from '@src/api/settings'
import LapeNewMultiSelect, {
  LapeNewMultiSelectProps,
} from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  getFilters,
  parseRoadmapToEpic,
} from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/RoadmapTargetForm'
import { EpicOption, KpiInterface } from '@src/interfaces/kpis'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { renderDeliverablesSelectorOption } from '@src/utils/kpi'
import React, { useEffect, useMemo, useState } from 'react'

export const SearchEpicsField = (
  multiselectOverride: Partial<LapeNewMultiSelectProps<EpicOption>>,
) => {
  const { values } = useLapeContext<KpiInterface>()
  const [roadmaps, setRoadmaps] = useState<RoadmapInterface[]>([])
  const [search, setSearch] = useState<string>('')
  const { data: roadmapSettings } = useGetRoadmapSettings()
  const [deliverablesLoading, setDeliverablesLoading] = useState(false)

  const deliverablesOptions = useMemo(
    () =>
      roadmaps.map(roadmap => ({
        value: parseRoadmapToEpic(roadmap),
        label: roadmap.name,
      })),
    [roadmaps],
  )
  const fetchDeliverablesOptions = useMemo(
    () => ({ sortBy: [], filters: getFilters(values, search) }),
    [values, search],
  )
  useEffect(() => {
    if (roadmapSettings?.enabled) {
      setDeliverablesLoading(true)
      const roadmapsPromise = values.is_employee
        ? getEmployeeRoadmaps(fetchDeliverablesOptions)
        : getRoadmaps(fetchDeliverablesOptions)
      roadmapsPromise.then(res => {
        setRoadmaps(res.data)
        setDeliverablesLoading(false)
      })
    }
  }, [roadmapSettings, fetchDeliverablesOptions])

  return (
    <LapeNewMultiSelect<EpicOption>
      name="epics"
      placeholder="Deliverables"
      options={deliverablesOptions}
      disabled={deliverablesLoading}
      onSearch={str => setSearch(str)}
      required
      onAfterChange={options => {
        if (options) {
          values.target_epics[0].epics = options.map(option => option.value)
        }
      }}
      {...multiselectOverride}
    >
      {option => renderDeliverablesSelectorOption(option, values.is_employee)}
    </LapeNewMultiSelect>
  )
}
