import React, { KeyboardEvent, useState } from 'react'
import {
  Button,
  Flex,
  Input,
  Box,
  Subheader,
  Widget,
  Text,
  TextArea,
} from '@revolut/ui-kit'
import { Minus, Plus } from '@revolut/icons'
import BottomInputMessage from '@components/BottomInputMessage/BottomInputMessage'

export const DEFAULT_MULTI_INPUT_PLACEHOLDER = 'Type and press enter'

export interface MultiInputProps<T> {
  value?: (T | string)[]
  title?: string
  valueFieldName?: string | null
  onChange?: (val: (T | string)[]) => void
  hasError?: boolean
  message?: React.ReactNode
  name?: string
  description?: string
  withCell?: boolean
  useTextArea?: boolean
  preFillWith?: string
  label?: string
  disabled?: boolean
  maxLength?: number
  /** Pass error messages to individual inputs in the list */
  inputErrors?: (string | null)[]
  /** To split the pasted value by whitespace into multiple values */
  splitOnPaste?: boolean
}

const MultiInput = <T extends {} = string>({
  value,
  onChange,
  valueFieldName,
  title,
  hasError,
  message,
  withCell,
  description,
  useTextArea,
  preFillWith,
  label,
  disabled,
  maxLength,
  inputErrors,
  splitOnPaste,
  ...props
}: MultiInputProps<T>) => {
  const [newVal, setNewValue] = useState(preFillWith || '')
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const name = props['data-name']

  const getNewInputValues = (multiple?: string[]) => {
    const valuesToInsert = multiple || [newVal]

    const res = [
      ...(value || []),
      ...valuesToInsert.map(val =>
        valueFieldName ? ({ [valueFieldName]: val } as T) : val,
      ),
    ]
    return res
  }

  const addNewRow = (multiple?: string[]) => {
    const newValues = getNewInputValues(multiple)
    setNewValue(preFillWith || '')
    onChange?.(newValues.filter(val => val !== (preFillWith || '')))
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && newVal) {
      addNewRow()
    }
  }

  const handleBlur = () => {
    if (newVal && newVal !== (preFillWith || '')) {
      addNewRow()
    }
  }

  const handlePlusClick = () => {
    addNewRow()
  }

  const handleDelete = (id: number) => {
    onChange?.(value?.filter((_, i) => i !== id) || [])
  }

  const handleExistingValuesChange = (val: string, id: number) => {
    const newValues = [...(value || [])]
    if (valueFieldName) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      newValues[id][valueFieldName] = val
    } else {
      newValues[id] = val
    }
    onChange?.(newValues)
  }

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (splitOnPaste) {
      e.preventDefault()
      addNewRow(`${newVal}${e.clipboardData.getData('Text')}`.split(/\s+/))
    }
  }

  const content = (
    <Box data-name={name}>
      {title && !withCell && (
        <Subheader variant="nested">
          <Subheader.Title>{title}</Subheader.Title>
        </Subheader>
      )}
      {title && withCell && (
        <Box>
          <Text use="p" mb={description ? 's-8' : 's-16'} variant="primary">
            {title}
          </Text>
          {description && (
            <Text use="p" mb="s-16" variant="caption" color="grey-tone-50">
              {description}
            </Text>
          )}
        </Box>
      )}
      {value?.map((val, id) => {
        const inputError = inputErrors?.[id]
          ? { 'aria-invalid': true, message: inputErrors[id] }
          : {}

        return (
          <Flex
            justifyContent="stretch"
            alignItems="center"
            key={id}
            mb={id === value.length - 1 ? '16px' : '8px'}
          >
            {useTextArea ? (
              <TextArea
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                value={valueFieldName ? val[valueFieldName] : (val as string)}
                onChange={e => handleExistingValuesChange(e.currentTarget.value, id)}
                data-testid={`${name}-${id}`}
                aria-label={`${label || DEFAULT_MULTI_INPUT_PLACEHOLDER} ${id + 1}`}
                label={label}
                disabled={disabled}
                data-name={`${name}.${id}`}
                {...inputError}
              />
            ) : (
              <Input
                /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
                value={valueFieldName ? val[valueFieldName] : (val as string)}
                placeholder={label || DEFAULT_MULTI_INPUT_PLACEHOLDER}
                onChange={e => handleExistingValuesChange(e.currentTarget.value, id)}
                onKeyDown={handleKeyDown}
                data-testid={`${name}-${id}`}
                aria-label={`${label || DEFAULT_MULTI_INPUT_PLACEHOLDER} ${id + 1}`}
                disabled={disabled}
                data-name={`${name}.${id}`}
                {...inputError}
              />
            )}
            <Button
              ml="s-8"
              radius="button-sm"
              onClick={() => handleDelete(id)}
              data-testid={`${name}-remove-${id}`}
              variant="secondary"
              height={36}
              width={36}
              useIcon={Minus}
              disabled={disabled}
            />
          </Flex>
        )
      })}
      <Flex justifyContent="stretch" alignItems="center">
        {useTextArea ? (
          <TextArea
            value={newVal}
            onBlur={handleBlur}
            onChange={e => setNewValue(e.currentTarget.value)}
            data-testid={`${name}-new`}
            aria-label={`${label || DEFAULT_MULTI_INPUT_PLACEHOLDER} new`}
            label={label}
            disabled={disabled}
            maxLength={maxLength}
            onPaste={onPaste}
          />
        ) : (
          <Input
            value={newVal}
            onBlur={handleBlur}
            placeholder={label || DEFAULT_MULTI_INPUT_PLACEHOLDER}
            onChange={e => setNewValue(e.currentTarget.value)}
            onKeyDown={handleKeyDown}
            data-testid={`${name}-new`}
            aria-label={`${label || DEFAULT_MULTI_INPUT_PLACEHOLDER} new`}
            disabled={disabled}
            maxLength={maxLength}
            onPaste={onPaste}
          />
        )}
        <Button
          ml="s-8"
          radius="button-sm"
          onClick={handlePlusClick}
          data-testid={`${name}-add`}
          variant="secondary"
          height={36}
          width={36}
          useIcon={Plus}
          disabled={disabled}
        />
      </Flex>
      <BottomInputMessage hasError={hasError} message={message} />
    </Box>
  )

  return withCell ? <Widget p="s-16">{content}</Widget> : content
}

export default MultiInput
