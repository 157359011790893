import React from 'react'
import {
  Text,
  Absolute,
  Avatar,
  ElevationLevel,
  Flex,
  Relative,
  Box,
  Token,
} from '@revolut/ui-kit'

import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { OptionInterface } from '@src/interfaces/selectors'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { AvatarType } from '@src/interfaces/employees'

type Props = {
  id: number
  avatar: AvatarType
  name: string
  position: OptionInterface
}
export const Node = ({ id, name, avatar, position }: Props) => {
  const { theme } = useAppTheme()

  const boxWidth = 200
  const avatarSize = 56
  const avatarTop = avatarSize / -2
  const avatarLeft = boxWidth / 2 + avatarTop

  return (
    <Relative>
      <Absolute top={avatarTop} left={avatarLeft}>
        <InternalLink
          style={{ textDecoration: 'none' }}
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })}
        >
          <Avatar size={avatarSize} image={getAvatarUrl(avatar)}>
            {!avatar && getInitials(name || '')}
          </Avatar>
        </InternalLink>
      </Absolute>
      <Box
        width={boxWidth}
        radius={Token.radius.r8}
        elevation={ElevationLevel.LEVEL_2}
        style={{ textAlign: 'center' }}
        bg={theme === 'dark' ? Token.color.greyTone8 : Token.color.background}
      >
        <Box width="100%" pb="s-12" style={{ paddingTop: avatarSize / 2 + 8 }}>
          <Flex flex={1} width="100%" flexDirection="column" alignItems="center">
            {name && (
              <InternalLink
                style={{ textDecoration: 'none' }}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })}
              >
                <Text color={Token.color.foreground} use="div">
                  {name}
                </Text>
              </InternalLink>
            )}
            {position && (
              <Text use="div" variant="small" color={Token.color.greyTone50}>
                {position.name}
              </Text>
            )}
          </Flex>
        </Box>
      </Box>
    </Relative>
  )
}
