import { all, put, takeEvery, call } from 'redux-saga/effects'
import { FeedbackActionsTypes, FeedbackSubmitInterface } from './types'
import { postFeedbackRequest } from '../../api/user'
import { setFeedbackLoading, setFeedbackOpen } from './actions'
import { pushNotification } from '../notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '../../constants/notifications'
import { NotificationTypes } from '../notifications/types'
import { LOCAL_STORAGE } from '../../constants/api'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

function* handleCloseFeedback() {
  yield call(postFeedbackRequest, {
    status: { id: 'rejected' },
    topic: { id: 'platform' },
  })
  workspaceLocalStorage.setItem(LOCAL_STORAGE.FEEDBACK_DATE, new Date().toISOString())
}

function* handleSubmitFeedback(action: { payload: FeedbackSubmitInterface }) {
  try {
    yield put(setFeedbackLoading(true))
    yield call(postFeedbackRequest, action.payload)
    yield put(setFeedbackOpen(false))
    pushNotification({
      value: 'Thank you for your feedback!',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
    workspaceLocalStorage.setItem(LOCAL_STORAGE.FEEDBACK_DATE, new Date().toISOString())
  } catch (e) {
    console.error(e)
  } finally {
    yield put(setFeedbackLoading(false))
  }
}

export function* feedbackSaga() {
  yield all([
    takeEvery<any>(FeedbackActionsTypes.CLOSE_FEEDBACK, handleCloseFeedback),
    takeEvery<any>(FeedbackActionsTypes.SUBMIT_FEEDBACK, handleSubmitFeedback),
  ])
}
