import {
  getBudgetsForDepartmentGraph,
  getBudgetsForFunctionsGraph,
  getBudgetsForRolesGraph,
  getBudgetsForSpecialisationsGraph,
  getBudgetsGraph,
} from '@src/api/budgets'
import { roleTalentTabStats } from '@src/api/roles'
import { getTalentStats } from '@src/api/talent'
import {
  getNipsForDepartmentGraph,
  getNipsForFunctionsGraph,
  getNipsForRolesGraph,
  getNipsForSpecialisationsGraph,
  getNipsForTeamGraph,
  getNipsForFunctionalManagerGraph,
  getNipsForLineManagerGraph,
} from '@src/api/nips'
import { functionTalentTabStats } from '@src/api/functions'
import { LOOKER_DASHBOARD_DEPARTMENT_LINK } from '@src/constants/externalLinks'
import { specialisationTalentTabStats } from '@src/api/specialisations'

export enum TalentType {
  Function = 'function',
  Specialisation = 'specialisation',
  Role = 'role',
  Team = 'team',
  Department = 'department',
  Company = 'company',
  DirectReport = 'direct-report',
  FunctionalReport = 'functional-report',
}

export const getTalentTypes = (id: number, isPerformance?: boolean) => ({
  [TalentType.Function]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: isPerformance ? 'function__id' : 'position__function__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => functionTalentTabStats(`${id}`),
    statLink: undefined,
    budgetsGraphRequest: getBudgetsForFunctionsGraph,
    nipsGraphRequest: getNipsForFunctionsGraph,
  },
  [TalentType.Role]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: isPerformance ? 'role__id' : 'position__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => roleTalentTabStats(`${id}`),
    statLink: undefined,
    budgetsGraphRequest: getBudgetsForRolesGraph,
    nipsGraphRequest: getNipsForRolesGraph,
  },
  [TalentType.Specialisation]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: 'specialisation__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => specialisationTalentTabStats(`${id}`),
    statLink: undefined,
    budgetsGraphRequest: getBudgetsForSpecialisationsGraph,
    nipsGraphRequest: getNipsForSpecialisationsGraph,
  },
  [TalentType.Department]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: isPerformance ? 'department__id' : 'team__department__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () =>
      getTalentStats(isPerformance ? 'department__id' : 'team__department__id', `${id}`),
    statLink: LOOKER_DASHBOARD_DEPARTMENT_LINK,
    budgetsGraphRequest: getBudgetsForDepartmentGraph,
    nipsGraphRequest: getNipsForDepartmentGraph,
  },
  [TalentType.Team]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: 'team__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => getTalentStats('team__id', `${id}`),
    statLink: undefined,
    budgetsGraphRequest: getBudgetsGraph,
    nipsGraphRequest: getNipsForTeamGraph,
  },
  [TalentType.Company]: {
    filter: undefined,
    stat: undefined,
    statLink: undefined,
    budgetsGraphRequest: undefined,
    nipsGraphRequest: undefined,
  },
  [TalentType.DirectReport]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: 'line_manager__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => getTalentStats('line_manager__id', `${id}`),
    statLink: undefined,
    budgetsGraphRequest: undefined,
    nipsGraphRequest: getNipsForLineManagerGraph,
  },
  [TalentType.FunctionalReport]: {
    filter: {
      filters: [{ name: `${id}`, id }],
      columnName: 'quality_control__id',
      nonResettable: true,
      nonInheritable: true,
    },
    stat: async () => getTalentStats('quality_control__id', `${id}`),
    statLink: undefined,
    budgetsGraphRequest: undefined,
    nipsGraphRequest: getNipsForFunctionalManagerGraph,
  },
})
