import { useSelector } from 'react-redux'
import { selectPermissions, selectFeatureFlags } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { useCallback } from 'react'
import { GlobalSettings, PermissionTypes, FeatureFlags } from '@src/store/auth/types'

interface PermissionsInterface {
  globalSetting?: GlobalSettings
  permission?: PermissionTypes
  featureFlag?: FeatureFlags
}

export const usePermissionChecks = () => {
  const permissions = useSelector(selectPermissions)
  const featureFlags = useSelector(selectFeatureFlags)
  const globalSettings = useGlobalSettings()

  const isLoading = globalSettings.isLoading

  const checkPermissions = useCallback(
    (tab: PermissionsInterface) => {
      const hasPermission = tab.permission ? permissions.includes(tab.permission) : true
      const hasFeatureFlag = tab.featureFlag
        ? featureFlags.includes(tab.featureFlag)
        : true

      const settingEnabled = tab.globalSetting
        ? !!globalSettings.settings[tab.globalSetting]
        : true

      return hasPermission && hasFeatureFlag && settingEnabled
    },
    [permissions, featureFlags, globalSettings, isLoading],
  )

  return {
    checkPermissions,
    isLoading,
  }
}
