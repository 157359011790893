import { StatusWidget } from '@revolut/ui-kit'
import React from 'react'
import { MeetingWithCompanionDetail } from '@src/interfaces/meetings'
import { submitCalendarTracking } from '@src/api/meetings'
import Cookies from 'js-cookie'
import { COOKIE } from '@src/constants/api'
import { CREATE_ONE_TO_ONE_MEETING } from '@src/constants/externalLinks'
import { cookiesApi } from '@src/utils/cookies'

export interface MeetingsWithPageParams {
  employeeId: string
  companionId: string
}

export interface CommonProps {
  data: MeetingWithCompanionDetail
}

export const getStatColor = (value?: number | string | null) => {
  return value && value > 7 ? 'error' : 'inherit'
}

export const onCreateGoogleEvent = (link?: string | null) => {
  const shouldSync = ((): boolean => {
    const lastSyncCookie = Cookies.get(COOKIE.LAST_CALENDAR_SYNC)
    if (!lastSyncCookie) {
      return true
    }
    const lastSyncDate = Number(new Date(lastSyncCookie))
    const now = Number(new Date())
    if (isNaN(lastSyncDate)) {
      return true
    }
    return now - lastSyncDate >= 1000 * 60
  })()

  if (shouldSync) {
    submitCalendarTracking().then(() =>
      cookiesApi.set(COOKIE.LAST_CALENDAR_SYNC, new Date().toUTCString()),
    )
  }

  window.open(link || `${CREATE_ONE_TO_ONE_MEETING}+Meeting`, '_blank')
}

export const MeetingsTableEmptyState = () => {
  return (
    <StatusWidget>
      <StatusWidget.Image
        src="https://assets.revolut.com/assets/3d-images/3D036.png"
        srcSet="https://assets.revolut.com/assets/3d-images/3D036@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D036@3x.png 3x"
      />
      <StatusWidget.Title>1:1 meetings not found</StatusWidget.Title>
      <StatusWidget.Description>
        It is mandatory to setup weekly 1:1 reviews.
        <br />
        They should be created in Google Calendar, and tracked here in Revoluters.
      </StatusWidget.Description>
    </StatusWidget>
  )
}
