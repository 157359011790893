import React from 'react'
import { format } from 'date-fns'
import { Box, HStack, Text, Token, VStack } from '@revolut/ui-kit'

import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import { CommandText, commandToIcon, MessageInterface } from './common'

type MessageProps = {
  message: MessageInterface
  onClick?: () => void
}

const UserMessage = ({ message }: Omit<MessageProps, 'onClick'>) => {
  return (
    <Box
      bg={Token.color.widgetBackground}
      p="s-12"
      radius={Token.radius.widget}
      maxWidth={400}
      minWidth={100}
      alignSelf="flex-end"
    >
      <VStack space="s-4">
        <Text
          fontSize="15px"
          color={Token.color.foreground}
          style={{
            wordBreak: 'break-word',
          }}
        >
          {message.text}
        </Text>
        <Text use="div" alignSelf="flex-end" fontSize="10px">
          {format(new Date(message.time), 'HH:mm')}
        </Text>
      </VStack>
    </Box>
  )
}

const BotMessage = ({ message }: Omit<MessageProps, 'onClick'>) => {
  return (
    <Box
      bg={Token.color.widgetBackground}
      p="s-16"
      pb="s-12"
      radius={Token.radius.widget}
      maxWidth={400}
    >
      <VStack space="s-4">
        <HTMLEditor
          value={message.text}
          readOnly
          transparentBg
          height="auto"
          onChange={() => {}}
          nonResizable
          noPadding
          fontSize="15px"
        />
        <Text use="div" alignSelf="flex-end" fontSize="10px">
          {format(new Date(message.time), 'HH:mm')}
        </Text>
      </VStack>
    </Box>
  )
}

const SuggestionMessage = ({ message, onClick }: MessageProps) => {
  return (
    <Box
      bg={Token.color.widgetBackground}
      borderColor={Token.color.blue}
      borderWidth={1}
      borderStyle="solid"
      p="s-12"
      radius={Token.radius.widget}
      maxWidth={400}
      alignSelf="flex-end"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      aria-label={`suggestion: ${message.text}`}
    >
      <Text
        fontSize="15px"
        color={Token.color.blue}
        style={{
          wordBreak: 'break-word',
        }}
      >
        {message.text}
      </Text>
    </Box>
  )
}

const CommandMessage = ({ message, onClick }: MessageProps) => {
  return (
    <Box
      bg={Token.color.widgetBackground}
      borderColor={Token.color.blue}
      borderWidth={1}
      borderStyle="solid"
      p="s-12"
      radius={Token.radius.widget}
      maxWidth={400}
      minWidth={100}
      alignSelf="flex-end"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
      aria-label={`command: ${message.text}`}
    >
      <HStack space="s-8" align="center">
        {commandToIcon(message.text as CommandText)}
        <Text
          fontSize="15px"
          color={Token.color.blue}
          style={{
            wordBreak: 'break-word',
          }}
        >
          {message.text}
        </Text>
      </HStack>
    </Box>
  )
}

export const Message = ({ message, onClick }: MessageProps) => {
  if (message.from === 'user') {
    return <UserMessage message={message} />
  }
  if (message.from === 'chatbot') {
    return <BotMessage message={message} />
  }
  if (message.from === 'suggestion') {
    return <SuggestionMessage message={message} onClick={onClick} />
  }
  if (message.from === 'command') {
    return <CommandMessage message={message} onClick={onClick} />
  }
  return null
}
