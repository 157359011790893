import React, { useState } from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import { successNotification } from '@src/actions/NotificationActions'
import { pathToUrl } from '@src/utils/router'
import LapeSubmitPopups from '@src/features/Popups/LapeSubmitPopups'
import LapeDescriptionDialog from '@src/features/Popups/LapeDescriptionDialog'
import { SumbitButtonWrapper } from '@src/pages/Forms/FormButtons'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { Button } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { navigateReplace } from '@src/actions/RouterActions'
import { useParams } from 'react-router-dom'
import { Statuses } from '@src/interfaces'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface SpecialisationSubmitButtonProps {
  isNew?: boolean
  notification?: {
    path?: string
    createMsg: string
    updateMsg: string
  }
  dialog: {
    title: string
    placeholder: string
    fieldName: string
  }
}
const SpecialisationSubmitButton = ({
  notification,
  isNew,
  dialog,
}: SpecialisationSubmitButtonProps) => {
  const { values, valid, submit, isSubmitting } =
    useLapeContext<SpecialisationInterface>()

  const permission = useSelector(selectPermissions)
  const canSubmit = permission.includes(PermissionTypes.AddSpecialisation)
  const params = useParams()

  const [openDialog, setOpenDialog] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)

  const isExistingData = !!values.id

  const onSubmitDialog = () => {
    const isDraft = values.status === Statuses.draft
    const prevStatus = values.status
    if (isDraft) {
      values.status = Statuses.pending
    }

    submit()
      .then(() => {
        workspaceLocalStorage.removeItem(
          pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {}),
        )
        navigateReplace(pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, params))
        if (notification) {
          successNotification(
            isExistingData ? notification.updateMsg : notification.createMsg,
          )
        }
      })
      .catch(() => {
        values.status = prevStatus
      })
  }

  const handleSubmitClick = () => {
    setOpenPopup(true)
  }

  if (values.id && values.status !== Statuses.draft) {
    return null
  }

  return (
    <>
      {openPopup && (
        <LapeSubmitPopups
          onClose={() => setOpenPopup(false)}
          onSubmit={onSubmitDialog}
          type="specialisation"
        />
      )}
      {openDialog && (
        <LapeDescriptionDialog
          loading={isSubmitting}
          onSubmit={onSubmitDialog}
          onClose={() => setOpenDialog(false)}
          data={dialog}
        />
      )}
      {isNew ? (
        <Button
          onClick={handleSubmitClick}
          pending={isSubmitting}
          disabled={!valid || !canSubmit}
          elevated
        >
          Submit
        </Button>
      ) : (
        <SumbitButtonWrapper>
          <Button
            onClick={handleSubmitClick}
            pending={isSubmitting}
            disabled={!valid || !canSubmit}
            elevated
          >
            Submit
          </Button>
        </SumbitButtonWrapper>
      )}
    </>
  )
}

export default connect(SpecialisationSubmitButton)
