import React from 'react'
import { Text, TextProps, Token } from '@revolut/ui-kit'
import { KeeperGrade, KeeperGradeToString } from '@src/interfaces/performance'

interface Props extends TextProps {
  grade?: KeeperGrade | null
  withPending?: boolean
  inverse?: boolean
  className?: string
}

const getColorsAndGrade = (grade?: KeeperGrade | null) => {
  switch (grade) {
    case KeeperGrade.No:
      return {
        color: Token.color.background,
        background: Token.color.red,
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.ProbablyNo:
      return {
        color: Token.color.foreground,
        background: Token.color.greyTone50,
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Yes:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.StrongYes:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.YesAtAnyCost:
      return {
        color: Token.color.background,
        background: Token.color.teal,
        grade: KeeperGradeToString[grade],
      }
    default:
      return null
  }
}

const getInverseColorsAndGrade = (grade?: KeeperGrade | null) => {
  switch (grade) {
    case KeeperGrade.No:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.ProbablyNo:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Yes:
      return {
        color: Token.color.foreground,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.StrongYes:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.YesAtAnyCost:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Pass:
      return {
        color: Token.color.green,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    case KeeperGrade.Fail:
      return {
        color: Token.color.red,
        background: 'transparent',
        grade: KeeperGradeToString[grade],
      }
    default:
      return null
  }
}

const KeeperGradeLabel = ({
  grade,
  withPending,
  inverse,
  className,
  ...props
}: Props) => {
  const data = inverse ? getInverseColorsAndGrade(grade) : getColorsAndGrade(grade)

  if (!data) {
    if (withPending) {
      return (
        <Text className={className} color={Token.color.greyTone20} {...props}>
          Pending
        </Text>
      )
    }

    return null
  }

  return (
    <Text
      className={className}
      backgroundColor={data.background}
      color={data.color}
      {...props}
    >
      {data.grade}
    </Text>
  )
}

export default KeeperGradeLabel
