import React from 'react'
import { Box, Flex, Text, Widget } from '@revolut/ui-kit'
import styled from 'styled-components'
import { BoxInnerProps } from '@revolut/ui-kit/types/dist/components/Box/Box'

interface SectionProps extends BoxInnerProps {
  children: React.ReactNode
}
type TitleProps = {
  actions?: React.ReactNode
  children: React.ReactNode
  subtitle?: string
}
type ItemProps = {
  children: React.ReactNode
}
type ContentProps = {
  children: React.ReactNode
}

const StyledTitle = styled(Text)<{ indexed?: boolean }>`
  :before {
    counter-increment: section;
    content: ${props => (props.indexed ? `counter(section) '. '` : '')};
  }
`

const StyledItem = styled(Box)`
  :not(:last-child) {
    margin-bottom: 40px;
  }
`

const Section = ({ children, ...props }: SectionProps) => {
  return (
    <Widget p="s-16" css={{ counterReset: 'section' }} {...props}>
      {children}
    </Widget>
  )
}

interface InternalTitleProps extends TitleProps {
  indexed?: boolean
}

const Title = ({ children, subtitle, actions, indexed }: InternalTitleProps) => {
  return (
    <Box mb="s-16">
      <Flex justifyContent="space-between" alignItems="center">
        <StyledTitle indexed={indexed} use="div" variant="primary" fontSize="h5">
          {children}
        </StyledTitle>
        {actions}
      </Flex>

      {subtitle && (
        <Text use="div" variant="secondary" color="grey-tone-50">
          {subtitle}
        </Text>
      )}
    </Box>
  )
}

Section.Item = ({ children }: ItemProps) => {
  return <StyledItem>{children}</StyledItem>
}

Section.Title = Title

Section.IndexedTitle = (props: TitleProps) => {
  return <Title {...props} indexed />
}

Section.Content = ({ children }: ContentProps) => {
  return <Box>{children}</Box>
}

export default Section
