import React from 'react'
import Form from '@src/features/Form/Form'
import { assignUserGroupsRequest } from '@src/api/accessManagement'
import AssignUserToPermissionGroups from '@src/pages/Forms/AssignUserToPermissionGroups/AssignUserToPermissionGroups'

const Index = () => {
  return (
    <Form api={assignUserGroupsRequest}>
      <AssignUserToPermissionGroups />
    </Form>
  )
}

export default Index
