import type {
  PublicContentItem,
  MeetingNotesFeedback,
  PrivateContentItem,
} from '@src/interfaces/meetings'

export const getContentItemsByRole = <Role extends PublicContentItem['role']>(
  meetingNotesDetails: MeetingNotesFeedback | undefined,
  role: Role,
): Extract<PublicContentItem, { role: Role }> | undefined => {
  const contentItems = meetingNotesDetails?.content?.find(item => item.role === role)
  if (!contentItems) {
    return undefined
  }

  return contentItems as Extract<PublicContentItem, { role: Role }>
}

export const getPrivateContentItemsByRole = <Role extends PrivateContentItem['role']>(
  meetingNotesDetails: MeetingNotesFeedback | undefined,
  role: Role,
): Extract<PrivateContentItem, { role: Role }> | undefined => {
  const contentItems = meetingNotesDetails?.private_content?.find(
    item => item.role === role,
  )
  if (!contentItems) {
    return undefined
  }

  return contentItems as Extract<PrivateContentItem, { role: Role }>
}
