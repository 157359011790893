import { SandboxInvitation } from '@src/interfaces/reviewCycles'
import React, { useState } from 'react'
import { Flex, StatusPopup, Token, useStatusPopup } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { deleteSandboxEmployee, sandboxEmployeeInvitations } from '@src/api/reviewCycles'
import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import { ChevronDown, Delete } from '@revolut/icons'

export const InvitationActions = ({
  invitation,
  groups,
  onAfterChange,
}: {
  invitation: SandboxInvitation
  groups: IdAndName[]
  onAfterChange: () => void
}) => {
  const [selectedGrade, setSelectedGrade] = useState(invitation.permission_group)
  const [pending, setPending] = useState(false)
  const statusPopup = useStatusPopup()
  const groupName = groups.find(el => el.id === invitation.permission_group?.id)?.name

  const showError = (text: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const onChange = async (option: IdAndName) => {
    if (option) {
      setPending(true)
      const currentValue = invitation.permission_group
      try {
        setSelectedGrade(option)
        await sandboxEmployeeInvitations.update(
          { permission_group: option },
          { id: String(invitation.id) },
        )
        onAfterChange()
      } catch {
        showError('Could not update group')
        setSelectedGrade(currentValue)
      } finally {
        setPending(false)
      }
    }
  }

  const onDelete = async () => {
    if (invitation.id) {
      setPending(true)
      try {
        await deleteSandboxEmployee(invitation.id)
        onAfterChange()
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Successfully deleted invitation</StatusPopup.Title>
          </StatusPopup>,
        )
      } catch {
        showError('Could not delete invitation')
      } finally {
        setPending(false)
      }
    }
  }

  return (
    <Flex alignItems="center">
      <DropdownButton
        value={selectedGrade?.id}
        options={groups}
        onSelect={onChange}
        pending={pending}
        useEndIcon={undefined}
        useIcon={ChevronDown}
        useTextButton
      >
        {groupName}
      </DropdownButton>
      {!pending && (
        <Delete size={16} color={Token.color.blue} onClick={onDelete} cursor="pointer" />
      )}
    </Flex>
  )
}
