import React from 'react'

import Form from '@src/features/Form/Form'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequestsNew } from '@src/api/employees'

import PipForm from './General'

interface Props {
  data: EmployeeInterface
}

const Pip = (props: Props) => {
  return (
    <Form api={employeesRequestsNew}>
      <PipForm {...props} />
    </Form>
  )
}

export default Pip
