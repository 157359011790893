import React, { useEffect, useRef, useState } from 'react'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Dropdown, Item, MoreBar, Text, Token } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  hiringProcessActionColumn,
  hiringProcessDurationColumn,
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSeniorityColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
  hiringProcessTypeColumn,
} from '@src/constants/columns/hiringProcess'
import { useOrdering } from '@components/Table/hooks'
import { API, selectorKeys } from '@src/constants/api'
import { dragIconColumn } from '@src/constants/columns/ordering'
import { getSelectors } from '@src/api/selectors'
import { OptionInterface } from '@src/interfaces/selectors'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import RoleSaveDraftButton from '@src/pages/Forms/RoleForm/Buttons/RoleSaveDraftButton'
import { history, pathToUrl } from '@src/utils/router'
import { formHiringProcessStagesRoleRequest } from '@src/api/hiringProcess'
import ContinueRoleButton from '@src/pages/Forms/RoleForm/Buttons/ContintueRoleButton/ContinueRoleButton'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import HiringStagePreviewForm from '@src/pages/Forms/RoleForm/HiringProcess/HiringStagePreviewForm'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { Link, useLocation } from 'react-router-dom'
import { updateStagesAfterDelete } from '@src/utils/hiring'
import HiringStageDeleteButton from '@src/pages/Forms/RoleForm/HiringProcess/HiringStageDeleteButton'
import { handleError } from '@src/api'
import { TableNames } from '@src/constants/table'
import { useNextRoleStepButtonLink } from '@src/pages/Forms/RoleForm/hooks'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const Row = (
  handleDelete: (data: HiringProcessInterface) => Promise<void>,
): RowInterface<HiringProcessInterface> => ({
  cells: [
    dragIconColumn,
    {
      ...hiringProcessTitleWithLockColumn,
      width: 185,
    },
    {
      ...hiringProcessTypeColumn,
      width: 125,
    },
    {
      ...hiringProcessDurationColumn,
      width: 100,
    },
    {
      ...hiringProcessSeniorityColumn,
      width: 106,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 200,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 90,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 139,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 240,
    },
    {
      ...hiringProcessActionColumn,
      width: 100,
      insert: ({ data }) => (
        <HiringStageDeleteButton onConfirm={() => handleDelete(data)} />
      ),
    },
  ],
})

const CURRENT_STEP = 'hiring_process'

const HiringProcess = () => {
  const location = useLocation<{ stageId?: number } | undefined>()
  const [openDropdown, setDropdown] = useState(false)
  const [selectedStageId, setSelectedStageId] = useState<number | undefined>(
    location.state?.stageId,
  )
  const [hiringStages, setHiringStages] = useState<OptionInterface[]>([])
  const ref = useRef<HTMLButtonElement | null>(null)
  const context = useLapeContext<RoleInterface>()
  const { values, errors } = context
  const changeOrder = (d: HiringProcessInterface[]) => {
    values.hiring_process_rounds = d
  }
  const ordering = useOrdering(values?.hiring_process_rounds, changeOrder)
  const nextLink = useNextRoleStepButtonLink(CURRENT_STEP)

  useEffect(() => {
    fetchHiringStages()
    history.replace({ state: { ...location.state, stageId: undefined } })
  }, [])

  const fetchHiringStages = async () => {
    const result = await getSelectors(selectorKeys.hiring_stages)
    if (result?.data?.options) {
      setHiringStages(result?.data?.options)
    }
  }

  const handleDelete = async (stage: HiringProcessInterface) => {
    try {
      await formHiringProcessStagesRoleRequest.update(
        {
          status: Statuses.archived,
        },
        {
          id: String(stage.id),
        },
      )
      updateStagesAfterDelete(context, stage.id)
    } catch (e) {
      handleError(e)
    }
  }

  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      setSelectedStageId(data.id)
    }
  }

  return (
    <>
      <PageBody maxWidth="100%">
        <HiringStagePreviewForm
          id={selectedStageId}
          onClose={() => setSelectedStageId(undefined)}
        />
        <Table.Widget>
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <Dropdown
                open={openDropdown}
                anchorRef={ref}
                autoClose
                onClose={() => setDropdown(false)}
              >
                <Item
                  color={Token.color.accent}
                  // @ts-expect-error object works fine here, but UI kit expects string
                  to={getLocationDescriptor(pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE), {
                    role: { id: values.id, name: values.name },
                  })}
                  use={Link}
                >
                  <Text color={Token.color.accent}>+ Add new</Text>
                </Item>
                {hiringStages.map(stage => {
                  return (
                    <Item
                      key={stage.id}
                      // @ts-expect-error object works fine here, but UI kit expects string
                      to={getLocationDescriptor(
                        pathToUrl(ROUTES.FORMS.HIRING_STAGES.ROLE),
                        {
                          role: { id: values.id, name: values.name },
                          companyHiringStageId: stage.id,
                        },
                      )}
                      use={Link}
                    >
                      {stage.name}
                    </Item>
                  )
                })}
              </Dropdown>
              <MoreBar.Action
                onClick={() => setDropdown(!openDropdown)}
                ref={ref}
                useIcon="ChevronDown"
              >
                Add a round
              </MoreBar.Action>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable<HiringProcessInterface>
              name={TableNames.HiringProcess}
              dataType="Hiring stage"
              useWindowScroll
              row={Row(handleDelete)}
              cellErrors={errors?.hiring_process_rounds}
              data={values?.hiring_process_rounds}
              count={values?.hiring_process_rounds?.length || 0}
              orderingMode
              mainColumnIndex={1}
              disabledFiltering
              {...ordering}
              onRowClick={handleRowEdit}
              emptyState={<EmptyTableRaw title="Hiring stages will appear here" />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      <PageActions>
        {values.status === Statuses.draft && (
          <RoleSaveDraftButton
            title="role"
            pathInLocalStorage={pathToUrl(ROUTES.FORMS.ROLE.GENERAL, {})}
            pathAfterSave={ROUTES.FORMS.ROLE.HIRING_PROCESS}
            isNew
            notification={{
              updateMsg: 'Role draft successfully updated.',
              createMsg: 'Role draft successfully created.',
            }}
          />
        )}
        <ContinueRoleButton
          api={API.ROLES}
          type="role"
          step={CURRENT_STEP}
          to={nextLink}
          disabled={!values?.hiring_process_rounds?.length}
        />
      </PageActions>
    </>
  )
}

export default HiringProcess
