import React, { useEffect } from 'react'
import {
  DeliverableJustificationPreview,
  performanceRatingToFinalGrade,
  Ratings,
  ReviewDataInterface,
  ReviewScorecardInterface,
  ReviewSummaryDataInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import {
  Badge,
  Flex,
  HStack,
  InputGroup,
  Item,
  Text,
  Token,
  Tooltip,
  useTooltip,
  VStack,
} from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { get, lowerCase } from 'lodash'
import { RadioButton } from '@components/Inputs/RadioButtons/RadioButtons'
import {
  CardContentTypes,
  cardHasMissingValues,
  CommonSectionProps,
  getJustificationMessage,
  getRoundedRating,
  CommonGradeOption,
  DeliverableGradeOption,
  SelectedFieldInterface,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import BottomText from '@components/Inputs/partials/BottomText'
import { ExceedingExpectationTooltip } from '@src/pages/Forms/EmployeePerformanceLayout/components/ExceedingExpectationTooltip'
import { DeliverablesJustificationsView } from '@components/ScorecardGeneral/DeliverablesJustificationsView'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { GradesMapInterface } from '@src/utils/grades'
import isNumber from 'lodash/isNumber'

interface DeliverablesGradeProps {
  isViewMode: boolean
  isChecked: boolean
  ratings: Ratings[]
  option: CommonGradeOption
  onChange: () => void
  gradesMap: GradesMapInterface
}

export const DeliverablesGrade = ({
  isViewMode,
  isChecked,
  ratings,
  option,
  onChange,
  gradesMap,
}: DeliverablesGradeProps) => {
  const tooltip = useTooltip()

  const { aboveExp, belowExp } = option
  const differFromExp = aboveExp || belowExp

  return (
    <Item
      style={{ backgroundColor: 'transparent' }}
      use="label"
      aria-checked={isChecked}
      data-testid={`deliverables-${lowerCase(option.text)}`}
    >
      <Item.Prefix>
        <RadioButton
          checked={isChecked}
          disabled={isViewMode}
          disableLabel={false}
          onChange={onChange}
        />
      </Item.Prefix>

      <Item.Content>
        <Item.Title>
          <HStack gap="s-4" align="center">
            <Text>{option.text}</Text>
            {!!ratings.length && isViewMode && (
              <Badge
                minWidth="s-20"
                height="s-16"
                backgroundColor={Token.color.deepGrey}
                style={{ fontWeight: 500 }}
                {...tooltip.getAnchorProps()}
              >
                {ratings.length}
                <Tooltip {...tooltip.getTargetProps()} maxWidth={200} placement="top">
                  <VStack>
                    <Text variant="small" fontWeight="bold">
                      {gradesMap[performanceRatingToFinalGrade(ratings[0].value)]}
                    </Text>
                    {ratings.map((rating: Ratings) => (
                      <Text key={rating.value} variant="small">
                        {rating.review?.reviewer?.full_name}
                      </Text>
                    ))}
                  </VStack>
                </Tooltip>
              </Badge>
            )}
          </HStack>
        </Item.Title>
        <Item.Description>
          {!!option.description?.length && (
            <VStack>
              {option.description.map((item, index) => (
                <Text key={index} variant="caption" color={Token.color.greyTone50}>
                  {item}
                </Text>
              ))}
            </VStack>
          )}
        </Item.Description>
      </Item.Content>
      <Item.Side>
        {!!differFromExp && isChecked && <ExceedingExpectationTooltip grade={option} />}
      </Item.Side>
    </Item>
  )
}

interface DeliverablesProps extends CommonSectionProps {
  deliverablesGrades: DeliverableGradeOption[]
  justificationOnly?: boolean
  showJustificationError?: boolean
  onSelectGrade?: (
    reviewData: ReviewDataInterface | ReviewSummaryDataInterface,
    grade: DeliverableGradeOption,
  ) => void
  gradesMap: GradesMapInterface
  selectedField: SelectedFieldInterface
  isSegmentedDeliverables: boolean
}

export const Deliverables = connect(
  ({
    reviewData,
    isViewMode = false,
    deliverablesGrades,
    justificationOnly = false,
    showJustificationError,
    onSelectGrade,
    gradesMap,
    selectedField,
    isSegmentedDeliverables,
  }: DeliverablesProps) => {
    const { errors, submitFailed } = useLapeContext<ReviewScorecardInterface>()
    const deliverables = reviewData?.deliverables

    useEffect(() => {
      if (deliverables && !deliverables.justifications?.length && !isViewMode) {
        deliverables.justifications = [{ comment: null, reference_url: null }]
      }
    }, [deliverables])

    if (!reviewData) {
      return null
    }

    const renderError = () => {
      if (!submitFailed) {
        return null
      }

      const hasErrors =
        errors.review_data?.deliverables?.cards?.[0]?.sections &&
        cardHasMissingValues(
          CardContentTypes.DELIVERABLES,
          reviewData?.deliverables?.cards?.[0],
        )

      if (!hasErrors) {
        return null
      }

      return <BottomText error="Please select one of the options" />
    }

    const getFieldDetails = (option: DeliverableGradeOption) => {
      const ratingValue = get(
        reviewData,
        `deliverables.cards.${selectedField.cardIndex}.sections.0.value`,
      )
      const isChecked =
        deliverablesGrades.find(item => item.key === ratingValue)?.key === option.key

      return { isChecked, ratings: [] }
    }
    const getViewFieldDetails = (option: DeliverableGradeOption) => {
      const field = isSegmentedDeliverables
        ? `deliverables.cards.${selectedField.cardIndex}.ratings`
        : 'deliverables.ratings'
      const ratings =
        get(reviewData, field)?.filter((rating: Ratings) => {
          return getRoundedRating(rating.value) === option.key
        }) || []
      const isChecked = !!ratings.length

      return { isChecked, ratings }
    }

    const firstCard =
      reviewData?.deliverables?.cards?.[selectedField.cardIndex || 0]?.sections?.[0]
    const selectedRating = firstCard && 'value' in firstCard ? firstCard.value : undefined
    const selectedGrade = deliverablesGrades.find(item => item.key === selectedRating)
    const justificationMessage = getJustificationMessage(false, selectedGrade)

    const cardIndex = selectedField.cardIndex
    const justifications =
      isSegmentedDeliverables && isNumber(cardIndex)
        ? (reviewData?.deliverables?.cards?.[cardIndex]
            ?.justifications as DeliverableJustificationPreview[])
        : (reviewData?.deliverables?.justifications as DeliverableJustificationPreview[])

    return (
      <VStack space="s-16" data-testid="deliverables-section">
        {!justificationOnly && (
          <>
            {renderError()}
            <Flex
              backgroundColor={Token.color.greyTone2}
              borderRadius={Token.radius.r16}
              style={{ overflow: 'hidden' }}
            >
              <InputGroup style={{ width: '100%', gap: 0 }}>
                {deliverablesGrades.map((option, optionIdx) => {
                  const { isChecked, ratings } = isViewMode
                    ? getViewFieldDetails(option)
                    : getFieldDetails(option)
                  return (
                    <DeliverablesGrade
                      key={optionIdx}
                      isViewMode={isViewMode}
                      isChecked={isChecked}
                      ratings={ratings}
                      option={option}
                      onChange={() => onSelectGrade?.(reviewData, option)}
                      gradesMap={gradesMap}
                    />
                  )
                })}
              </InputGroup>
            </Flex>
          </>
        )}
        {isViewMode ? (
          <>
            {!!justifications?.length && (
              <DeliverablesJustificationsView
                isWidget={false}
                viewJustifications={justifications}
              />
            )}
          </>
        ) : (
          <>
            <LapeNewTextArea
              rows={2}
              name={
                isSegmentedDeliverables
                  ? `review_data.deliverables.cards.${selectedField.cardIndex}.justifications.0.comment`
                  : `review_data.deliverables.justifications.${selectedField.cardIndex}.comment`
              }
              label={justificationMessage}
              hasError={showJustificationError}
              hideOptional
            />
            {showJustificationError && <BottomText error="Please add justification" />}
          </>
        )}
      </VStack>
    )
  },
)
