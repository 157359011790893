import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeeColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsOwnershipTransferRequests } from '@src/api/notifications'

const OwnershipTransfer = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 370,
      },
      {
        ...notificationsEmployeeColumn,
        width: 200,
      },
      {
        ...notificationsReceivedColumn,
        width: 130,
      },
      {
        ...notificationsDeadline,
        width: 120,
      },
      {
        ...notificationsPriorityColumn,
        width: 90,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsOwnershipTransferRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default OwnershipTransfer
