import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { Switch, Route } from 'react-router-dom'
import SkillsTable from '@src/pages/Performance/Skills/SkillsTable'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import CultureValuesTable from '@src/pages/Performance/CultureValues/CultureValuesTable'
import { pathToUrl } from '@src/utils/router'

const Skills = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Skills & Values"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.GENERAL)}
      />
      <Switch>
        <Route path={ROUTES.PERFORMANCE.SKILLS}>
          <SkillsTable />
        </Route>
        <Route path={ROUTES.PERFORMANCE.VALUES}>
          <CultureValuesTable />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default Skills
