import { Route, Switch, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { RequestInterfaceNew, TableRequestInterface } from '@src/interfaces'
import { PermissionsInterface } from '@src/interfaces/permissions'
import {
  candidatePermissionsRequests,
  candidatePermissionsRequestsNew,
  departmentPermissionsRequests,
  departmentPermissionsRequestsNew,
  functionPermissionsRequests,
  functionPermissionsRequestsNew,
  rolePermissionsRequests,
  rolePermissionsRequestsNew,
  specialisationPermissionsRequests,
  specialisationPermissionsRequestsNew,
  teamPermissionsRequests,
  teamPermissionsRequestsNew,
  requisitionPermissionsRequests,
  requisitionPermissionsRequestsNew,
  connectionPermissionsRequests,
  connectionPermissionsRequestsNew,
  queryPermissionsRequests,
  queryPermissionsRequestsNew,
  dashboardPermissionsRequests,
  dashboardPermissionsRequestsNew,
} from '@src/api/permissionManagement'
import PermissionTransferTable from '@src/pages/PermissionTransfer/Table/PermissionTransferTable'
import PermissionTransferFormRouter from '@src/pages/PermissionTransfer/Form/PermissionTransferForm'

export type EntityName =
  | 'Department'
  | 'Role'
  | 'Function'
  | 'Team'
  | 'Specialisation'
  | 'Candidate'
  | 'Requisition'

export interface PermissionTransferProps {
  selector: selectorKeys
  tableApi: TableRequestInterface<PermissionsInterface>
  formApi: RequestInterfaceNew<PermissionsInterface>
  backPath: string
  entityName: string
}

const PermissionTransfer = (props: PermissionTransferProps) => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.TABLE}
        render={() => <PermissionTransferTable {...props} />}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.FORM}
        render={() => <PermissionTransferFormRouter {...props} />}
      />
    </Switch>
  )
}

const PermissionTransferRouter = () => {
  const { entityId } = useParams<{ entityId: string }>()
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.DEPARTMENT}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.department_permissions}
            tableApi={departmentPermissionsRequests(entityId)}
            formApi={departmentPermissionsRequestsNew(entityId)}
            entityName="Department"
            backPath={ROUTES.FORMS.DEPARTMENT.SETTINGS}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.ROLE}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.role_permissions}
            tableApi={rolePermissionsRequests(entityId)}
            formApi={rolePermissionsRequestsNew(entityId)}
            entityName="Role"
            backPath={ROUTES.FORMS.ROLE.GENERAL}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.FUNCTION}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.function_permissions}
            tableApi={functionPermissionsRequests(entityId)}
            formApi={functionPermissionsRequestsNew(entityId)}
            entityName="Function"
            backPath={ROUTES.FORMS.FUNCTION.SETTINGS}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.TEAM}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.team_permissions}
            tableApi={teamPermissionsRequests(entityId)}
            formApi={teamPermissionsRequestsNew(entityId)}
            entityName="Team"
            backPath={ROUTES.FORMS.TEAM.SETTINGS}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.SPECIALISATION}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.specialisation_permissions}
            tableApi={specialisationPermissionsRequests(entityId)}
            formApi={specialisationPermissionsRequestsNew(entityId)}
            entityName="Specialisation"
            backPath={ROUTES.FORMS.SPECIALISATIONS.GENERAL}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CANDIDATE}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.candidate_permissions}
            tableApi={candidatePermissionsRequests(entityId)}
            formApi={candidatePermissionsRequestsNew(entityId)}
            entityName="Candidate"
            backPath={ROUTES.FORMS.CANDIDATE.SUMMARY}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.REQUISITION}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.requisition_permissions}
            tableApi={requisitionPermissionsRequests(entityId)}
            formApi={requisitionPermissionsRequestsNew(entityId)}
            entityName="Requisition"
            backPath={ROUTES.FORMS.REQUISITION.ROLE}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.CONNECTION}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.connection_permissions}
            tableApi={connectionPermissionsRequests(entityId)}
            formApi={connectionPermissionsRequestsNew(entityId)}
            entityName="Connection"
            backPath={ROUTES.FORMS.DATA_ANALYTICS_CONNECTION.PERMISSIONS}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.QUERY}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.query_permissions}
            tableApi={queryPermissionsRequests(entityId)}
            formApi={queryPermissionsRequestsNew(entityId)}
            entityName="Query"
            backPath={ROUTES.FORMS.QUERY.PERMISSIONS}
          />
        )}
      />
      <Route
        exact
        path={ROUTES.FORMS.PERMISSION_MANAGEMENT.ENTITIES.DASHBOARD}
        render={() => (
          <PermissionTransfer
            selector={selectorKeys.dashboard_permissions}
            tableApi={dashboardPermissionsRequests(entityId)}
            formApi={dashboardPermissionsRequestsNew(entityId)}
            entityName="Dashboard"
            backPath={ROUTES.FORMS.DATA_ANALYTICS_DASHBOARD_PERMISSIONS}
          />
        )}
      />
    </Switch>
  )
}

export default PermissionTransferRouter
