import React from 'react'
import { IconButton } from '@revolut/ui-kit'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { selectorKeys } from '@src/constants/api'

export type RemoveConnectedRequisitionHandler = (id: number | string) => void

export const createConnectedRequisitionActionColumn = (
  onRemoveConnectedRequisition: RemoveConnectedRequisitionHandler,
): ColumnInterface<RequisitionInterface> => ({
  dataPoint: 'actions',
  filterKey: null,
  idPoint: 'actions',
  insert: ({ data }) => (
    <IconButton
      useIcon="Delete"
      onClick={() => {
        onRemoveConnectedRequisition(data.id)
      }}
    />
  ),
  selectorsKey: selectorKeys.none,
  sortKey: null,
  title: 'Actions',
  type: CellTypes.insert,
})
