import React from 'react'
import { Avatar, AvatarBadgeProps, AvatarSize } from '@revolut/ui-kit'
import { getAvatarUrl, getInitials } from '@src/utils/employees'
import { AvatarType, IdStatuses } from '@src/interfaces/employees'
import { Color } from '@revolut/ui-kit/types/dist/constants'

export type UserAvatarProps = {
  avatar?: AvatarType
  avatarUrlFunc?: (id: string) => string
  badgeProps?: AvatarBadgeProps
  full_name?: string
  id?: string | number
  name?: string
  size?: AvatarSize
  status?: IdStatuses
  uuid?: string
  progress?: number
  progressColor?: Color
}

export const UserAvatar = ({
  avatar,
  full_name,
  name,
  size = 24,
  status,
  uuid,
  badgeProps,
  progress,
  progressColor,
}: UserAvatarProps) => {
  const isTerminated = status === IdStatuses.terminated
  const nameString = full_name || name
  if (!nameString) {
    return null
  }
  const initials = getInitials(nameString)
  return (
    <Avatar
      uuid={uuid}
      size={size}
      image={getAvatarUrl(avatar)}
      opacity={isTerminated ? 0.5 : 1}
      progress={progress}
      progressColor={progressColor}
    >
      {!avatar && initials}
      {badgeProps && <Avatar.Badge {...badgeProps} />}
    </Avatar>
  )
}
