import React, { useState } from 'react'
import { connect } from 'lape'
import { reviewCyclesRequestsNew } from '@src/api/reviewCycles'
import Form from '@src/features/Form/Form'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { InputGroup } from '@revolut/ui-kit'

const AdditionalSettingsToggles = connect(() => {
  const { values, submit } = useLapeContext<ReviewCyclesInterface>()
  const [syncDisabled, setSyncDisabled] = useState(false)
  const [scorecardsDisabled, setScorecardsDisabled] = useState(false)

  const handleSync = async () => {
    setSyncDisabled(true)
    try {
      values.enabled_eligible_updates = !values.enabled_eligible_updates
      await submit()
    } finally {
      setSyncDisabled(false)
    }
  }

  const handleScorecards = async () => {
    setScorecardsDisabled(true)
    try {
      values.enabled_scorecards_generations = !values.enabled_scorecards_generations
      await submit()
    } finally {
      setScorecardsDisabled(false)
    }
  }

  return (
    <InputGroup>
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Enable employee data sync',
          description:
            'Keeps the employee data used in the performance review synchronised with their profile data until scorecards are created. Disabling this will freeze the data for the performance review only.',
        }}
        name="enabled_eligible_updates"
        disabled={syncDisabled}
        onChange={handleSync}
      />
      <LapeNewSwitch
        itemTypeProps={{
          title: 'Enable auto scorecard generation tasks',
          description:
            'Enabling this setting will allow the system to automatically process the generation of performance review scorecards prior to the start of the feedback period. This includes a sync task for employee data, as well as employee timeline items.',
        }}
        name="enabled_scorecards_generations"
        disabled={scorecardsDisabled}
        onChange={handleScorecards}
      />
    </InputGroup>
  )
})

const DataSyncForm = () => {
  return (
    <Form api={reviewCyclesRequestsNew} disableDataCleanup>
      <AdditionalSettingsToggles />
    </Form>
  )
}

export default DataSyncForm
