import { css } from 'styled-components'
import { Token } from '@revolut/ui-kit'

import {
  AddSelection,
  HierarchyConsts,
  HierarchyTransition,
  UpdateSelection,
} from '@components/Hierarchy/HierarchyTree'

const Text = css`
  fill: ${Token.color.white};
  font-size: 8px;
`

const GapBetweenCircleAndNode = 5

export const addNumberOfSubordinates = (
  selection: AddSelection,
  t: HierarchyTransition,
) => {
  /* Circle */
  selection
    .append('circle')
    .attr('class', 'SubordinatesCircle')
    .attr('r', HierarchyConsts.subordinatesCircleRadius)
    .attr('opacity', d => {
      if (d.data?.reports_count) {
        return '1'
      }

      return '0'
    })
    .attr('fill', d => {
      if (d.data?.reports?.length) {
        return Token.color.greyTone20
      }
      return Token.color.blue
    })
    .attr('transform', d => {
      return `translate(${d?.parent?.x ?? d.x}, ${
        (d?.parent?.y ?? d.y) +
        HierarchyConsts.subordinatesCircleRadius +
        GapBetweenCircleAndNode
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x}, ${
          d.y + HierarchyConsts.subordinatesCircleRadius + GapBetweenCircleAndNode
        })`
      }),
    )

  /* Text */
  selection
    .append('text')
    .text(d => d.data.reports_count)
    .attr('width', HierarchyConsts.subordinatesCircleRadius * 2)
    .attr('text-anchor', 'middle')
    .attr('dominant-baseline', 'middle')
    .attr('style', Text.join(''))
    .attr('class', 'SubordinatesText')
    .attr('opacity', d => {
      if (d.data?.reports_count) {
        return '1'
      }

      return '0'
    })
    .attr('transform', d => {
      return `translate(${d?.parent?.x ?? d.x}, ${
        (d?.parent?.y ?? d.y) +
        HierarchyConsts.subordinatesCircleRadius +
        GapBetweenCircleAndNode
      })`
    })
    .call(call =>
      call.transition(t).attr('transform', d => {
        return `translate(${d.x}, ${
          d.y + HierarchyConsts.subordinatesCircleRadius + GapBetweenCircleAndNode
        })`
      }),
    )
}

export const updateNumberOfSubordinates = (
  selection: UpdateSelection,
  t: HierarchyTransition,
) => {
  /* Circle */
  selection
    .select('.SubordinatesCircle')
    .transition(t)
    .attr('fill', d => {
      if (d.data?.reports?.length) {
        return Token.color.greyTone20
      }
      return Token.color.blue
    })
    .attr('transform', d => {
      return `translate(${d.x}, ${
        d.y + HierarchyConsts.subordinatesCircleRadius + GapBetweenCircleAndNode
      })`
    })

  /* Text */
  selection
    .select('.SubordinatesText')
    .transition(t)
    .attr('transform', d => {
      return `translate(${d.x}, ${
        d.y + HierarchyConsts.subordinatesCircleRadius + GapBetweenCircleAndNode
      })`
    })
}
