import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, Token } from '@revolut/ui-kit'

import { StatsConfig, useSelectableTableStats } from '@components/StatFilters/hooks'
import { StatFilters } from '@components/StatFilters/StatFilters'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { keyPersonsResponsibilitiesTableRequests } from '@src/api/keyPerson'
import {
  keyPersonResponsibilityCategoryColumn,
  keyPersonResponsibilityNameColumn,
  keyPersonResponsibilityRequiredForColumn,
  keyPersonResponsibilityStatusColumn,
  keyPersonResponsibilitySuccessionColumn,
} from '@src/constants/columns/keyPersonsResponsibilities'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import {
  KeyPersonResponsibilitiesStatsInterface,
  KeyPersonResponsibilityInterface,
} from '@src/interfaces/keyPerson'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const ROW: RowInterface<KeyPersonResponsibilityInterface> = {
  cells: [
    {
      ...keyPersonResponsibilityNameColumn,
      width: 300,
    },
    {
      ...keyPersonResponsibilityCategoryColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilityRequiredForColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilitySuccessionColumn,
      width: 200,
    },
    {
      ...keyPersonResponsibilityStatusColumn,
      width: 160,
    },
  ],
}

const statsConfig: StatsConfig<KeyPersonResponsibilitiesStatsInterface> = [
  {
    key: 'total',
    title: 'Total',
    filterKey: 'total',
    color: Token.color.foreground,
  },
  {
    key: 'active',
    title: 'Active',
    filterKey: 'active',
    color: Token.color.green,
  },
  {
    key: 'archived',
    title: 'Archived',
    filterKey: 'archived',
    color: Token.color.greyTone50,
  },
]

export const KeyPersonsResponsibilitiesTable = () => {
  const permissions = useSelector(selectPermissions)
  const table = useTable<
    KeyPersonResponsibilityInterface,
    KeyPersonResponsibilitiesStatsInterface
  >(keyPersonsResponsibilitiesTableRequests, [
    { columnName: 'active_stats', filters: [{ id: 'active', name: 'active' }] },
  ])
  const canAdd = permissions.includes(PermissionTypes.AddKeyPersonType)

  const statFiltersProps = useSelectableTableStats<
    KeyPersonResponsibilityInterface,
    KeyPersonResponsibilitiesStatsInterface
  >({
    table,
    statsConfig,
    columnName: 'active_stats',
  })

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <StatFilters {...statFiltersProps} />
      </Table.Widget.Info>
      {canAdd && (
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <MoreBar.Action
              onClick={() => {
                navigateTo(pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.EDIT))
              }}
              useIcon="Plus"
            >
              Add responsibility
            </MoreBar.Action>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
      )}
      <Table.Widget.Table>
        <AdjustableTable<
          KeyPersonResponsibilityInterface,
          KeyPersonResponsibilitiesStatsInterface
        >
          hideCount
          name={TableNames.KeyPersonsResponsibilities}
          useWindowScroll
          row={ROW}
          {...table}
          onRowClick={(row: { id: number }) => {
            navigateTo(
              pathToUrl(ROUTES.APPS.KEY_PERSONS.RESPONSIBILITY.PREVIEW, {
                id: row.id,
              }),
            )
          }}
          noDataMessage="All responsibilities will appear here"
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
