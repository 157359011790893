import React from 'react'
import styled from 'styled-components'

import { IconProps, Icon } from '@revolut/ui-kit'

type ShevronToggleProps = IconProps & {
  isOpen: boolean
}

const ShevronToggle = styled(() => <Icon name="ChevronDown" />)<ShevronToggleProps>`
  transform: rotate(${props => (props.isOpen ? '0' : '-90deg')});
  transition: transform 0.6s;
  &:hover,
  &:active {
    cursor: pointer;
  }
`

export default ShevronToggle
