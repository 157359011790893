import React from 'react'
import { ActionButton, Avatar, Group, Item, Token, chain } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TablePreview } from '@components/TablePreview/TablePreview'
import { getRightsToWork } from '@src/api/employees'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { getRightToWorkStatusColor } from '@src/constants/columns/rightToWork'
import { formatDateWithFallback } from '../common'
import { CommonPreviewProps } from './types'

export const RightToWork = ({ employeeId, hideTableRoute }: CommonPreviewProps) => {
  return (
    <Group>
      <Item>
        <Item.Content>
          <Item.Title color={Token.color.greyTone50}>Right to work</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, { employeeId })}
            use={InternalLink}
            useIcon="Plus"
          >
            Add new
          </ActionButton>
        </Item.Side>
      </Item>
      <TablePreview
        api={{ getItems: getRightsToWork(employeeId) }}
        tableRoute={
          hideTableRoute
            ? undefined
            : pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.RIGHT_TO_WORK, { id: employeeId })
        }
        emptyState={{
          title: 'No right to work item added',
          icon: 'Passport',
        }}
        row={rightToWork => {
          return (
            <Item
              use="button"
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE_RIGHT_TO_WORK.GENERAL, {
                    employeeId,
                    id: rightToWork.id,
                  }),
                )
              }
              key={rightToWork.id}
            >
              <Item.Avatar>
                <Avatar useIcon="Passport" />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>
                  {chain(rightToWork.country?.name, rightToWork.document_type?.name)}
                </Item.Title>
                <Item.Description>
                  Verified on{' '}
                  {formatDateWithFallback(rightToWork.last_verified_on_date, '-')}
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <Item.Value color={getRightToWorkStatusColor(rightToWork)}>
                  {rightToWork.status.name}
                </Item.Value>
              </Item.Side>
            </Item>
          )
        }}
      />
    </Group>
  )
}
