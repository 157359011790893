import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PreviewDocumentsBulkRequest } from '@src/apps/People/Documents/BulkRequest/Preview'
import { EditDocumentsBulkRequest } from '@src/apps/People/Documents/BulkRequest/Edit'

export const DocumentsBulkRequest = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.PREVIEW}>
        <PreviewDocumentsBulkRequest />
      </Route>
      <Route path={ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.ANY}>
        <EditDocumentsBulkRequest />
      </Route>
    </Switch>
  )
}
