import { goBack } from '@src/actions/RouterActions'
import React from 'react'
import {
  MoreBar,
  useStatusPopup,
  StatusPopup,
  useTooltip,
  Tooltip as UIKitTooltip,
} from '@revolut/ui-kit'
import { StatusCheck } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { UNKNOWN_ERROR } from '@src/pages/Forms/QueryForm/constants'
import { MutationResult } from '@src/features/SettingsButtons/types'

interface ApproveButtonProps extends SettingsButtonsInstanceProps {
  data: {
    field_options: FieldOptions
    id: number
  }
  buttonActionText?: string
  afterSubmitUrl?: string
  withPreApproval?: boolean
  statusToSet?: Statuses
  statusFieldName?: string
  disabled?: boolean
  tooltip?: string
  api: () => MutationResult
  onBeforeSubmit?: () => void
  onAfterSubmit?: () => void
}

export const ApproveButton = ({
  data,
  afterSubmitUrl,
  withPreApproval,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Approve,
  statusToSet,
  buttonActionText,
  statusFieldName = 'status',
  disabled,
  tooltip,
  api,
  onBeforeSubmit,
  onAfterSubmit,
}: ApproveButtonProps) => {
  const statusPopup = useStatusPopup()
  const tooltipProps = useTooltip()
  const { mutateAsync: updateEntity, isLoading } = api()
  const preapproving =
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    withPreApproval && data[statusFieldName] === Statuses.pending

  const permissions = useSelector(selectPermissions)

  const canApprove =
    data.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const approve = async () => {
    const defaultStatus = preapproving ? Statuses.preapproved : Statuses.approved

    statusPopup.show(
      <StatusPopup variant="loading">
        <StatusPopup.Title>Approving...</StatusPopup.Title>
      </StatusPopup>,
    )

    try {
      onBeforeSubmit?.()
      await updateEntity([data.id, { [statusFieldName]: statusToSet || defaultStatus }])

      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Approved successfully</StatusPopup.Title>
        </StatusPopup>,
      )

      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>{error?.data?.message || UNKNOWN_ERROR}</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      onAfterSubmit?.()
    }
  }

  const defaultTextAction = preapproving ? 'Pre-approve' : 'Approve'
  const textAction = buttonActionText || defaultTextAction
  const isShown = isVisible && canApprove

  return isShown ? (
    <MoreBar.Action
      useIcon={StatusCheck}
      pending={isLoading}
      onClick={() => {
        if (!disabled) {
          approve()
        }
      }}
      data-testid="approve-btn"
      aria-disabled={disabled}
      {...tooltipProps.getAnchorProps()}
    >
      {textAction}
      {tooltip && (
        <UIKitTooltip {...tooltipProps.getTargetProps()} width={200}>
          {tooltip}
        </UIKitTooltip>
      )}
    </MoreBar.Action>
  ) : null
}
