import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrganisationSettingsInterface } from '@src/interfaces/settings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { VStack } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'
import { ApprovalsMessage } from '@src/pages/OnboardingChecklist/components/Message'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { RecommendedLabel } from '../components/Recommended'

const RolesApprovals = () => {
  const { values } = useLapeContext<OrganisationSettingsInterface>()
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  return (
    <VStack gap="s-24">
      <VStack gap="s-16">
        <SectionTitle title="Do you require approvals when creating a new role?" />

        <LapeBooleanRadioSwitch
          name="enable_roles_approvals"
          variant="horizontal"
          disabled={disableEdit}
          noLabel={<RecommendedLabel label="No" />}
          yesDescription="I want set up approval process for roles by assigning employees or employee group to approve roles when created"
          noDescription="I do not need approval process when creating roles at the moment, and will decide it later"
        />

        {values.enable_roles_approvals && <ApprovalsMessage />}

        <ApprovalSteps
          switchField="enable_roles_approvals"
          approvalProcess="roles_approval_steps"
          entity="role"
          hideSwitch
          disabled={disableEdit}
          formRef={values}
        />
      </VStack>

      <VStack gap="s-16">
        <SectionTitle title="Do you require approvals when creating a new specialisation?" />

        <LapeBooleanRadioSwitch
          name="enable_specialisations_approvals"
          variant="horizontal"
          disabled={disableEdit}
          noLabel={<RecommendedLabel label="No" />}
          yesDescription="I want set up approval process for specialisations by assigning employees or employee group to approve specialisations when created"
          noDescription="I do not need approval process when creating specialisations at the moment, and will decide it later"
        />

        {values.enable_specialisations_approvals && <ApprovalsMessage />}

        <ApprovalSteps
          switchField="enable_specialisations_approvals"
          approvalProcess="specialisations_approval_steps"
          entity="specialisation"
          hideSwitch
          disabled={disableEdit}
          formRef={values}
        />
      </VStack>
    </VStack>
  )
}

export default RolesApprovals
