import { DayOption } from './common'

export const daysOptions: DayOption[] = [
  { id: 'monday' as const, name: 'Monday' },
  { id: 'tuesday' as const, name: 'Tuesday' },
  { id: 'wednesday' as const, name: 'Wednesday' },
  { id: 'thursday' as const, name: 'Thursday' },
  { id: 'friday' as const, name: 'Friday' },
  { id: 'saturday' as const, name: 'Saturday' },
  { id: 'sunday' as const, name: 'Sunday' },
]
