import React from 'react'
import PreviewNotes from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/PreviewNotes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import get from 'lodash/get'

type Props = {
  notesName?: string
}

const SectionPreviewNotes = ({ notesName }: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const notes = notesName ? get(values, notesName) : null

  if (notes) {
    return <PreviewNotes text={notes} />
  }

  return null
}

export default SectionPreviewNotes
