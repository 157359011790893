import { RequestInterfaceNew, Statuses } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  InterviewFeedbackStatus,
  InterviewResult,
  InterviewFeedbackInterface,
  InterviewFeedbackSummaryInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { AxiosPromise } from 'axios'
import {
  transformInterviewToPerformanceScorecard,
  transformPerformanceToInterviewScorecard,
} from '@src/utils/interview'
import { InterviewScorecardTemplateSectionInterface } from '@src/interfaces/interviewScorecardTemplates'

export const patchResultScorecard = (id: number, result: InterviewResult) =>
  apiWithoutHandling.patch(`${API.INTERVIEWS}/interviewFeedbacks/${id}`, {
    result,
    status: InterviewFeedbackStatus.completed,
  } as Partial<InterviewFeedbackInterface>)

export const generateAndPatchResultScorecard = async (
  id: number,
  userId: number,
  result: InterviewResult,
) => {
  const resp = await createFeedback(id, {
    id: userId,
  } as InterviewToolInterviewer)

  const scorecard = resp.data

  if (scorecard) {
    return patchResultScorecard(scorecard.id, result)
  }

  return Promise.reject(new Error('No scorecard'))
}

export const interviewFeedbackFormRequests: RequestInterfaceNew<InterviewFeedbackInterface> =
  {
    get: async ({ id, subtab }) => {
      const resp = await api.get<InterviewFeedbackInterface>(
        `${API.INTERVIEWS}/interviewFeedbacks/${subtab || id}`,
      )

      return { ...resp, data: transformInterviewToPerformanceScorecard(resp.data) }
    },
    update: async (_, { id }, data) => {
      const resp = await apiWithoutHandling.put(
        `${API.INTERVIEWS}/interviewFeedbacks/${id}`,
        data ? transformPerformanceToInterviewScorecard(data) : {},
      )

      return { ...resp, data: transformInterviewToPerformanceScorecard(resp.data) }
    },
    submit: async data =>
      apiWithoutHandling.post(API.INTERVIEWS, {
        ...data,
        status: Statuses.completed,
      }),
  }

export const createFeedback = (id: number, interviewer: InterviewToolInterviewer) =>
  api.post(`${API.INTERVIEWS}/interviewFeedbacks`, {
    interview_stage: { id },
    interviewer,
  })

export const getDeliverySectionDefault = () =>
  api.get<Partial<InterviewScorecardTemplateSectionInterface>>(
    `${API.HIRING_PROCESS}/interviewScorecardTemplates/deliverySectionDefault`,
  )

export const generateSummary = (
  id: number,
  data: InterviewFeedbackInterface,
): AxiosPromise<InterviewFeedbackSummaryInterface> =>
  apiWithoutHandling.post(
    `${API.INTERVIEWS}/interviewFeedbacks/${id}/scorecardSummary`,
    transformPerformanceToInterviewScorecard(data),
  )
