import { Header, Popup } from '@revolut/ui-kit'
import { useGetEmployee } from '@src/api/employees'
import { roadmapsRequestsNew } from '@src/api/roadmaps'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { TeamInterface } from '@src/interfaces/teams'
import { RoadmapForm } from '@src/pages/Forms/RoadmapForm/RoadmapForm'
import { selectUser } from '@src/store/auth/selectors'
import React from 'react'
import { useSelector } from 'react-redux'

export const NewRoadmapPopup = ({
  isOpen,
  onClose,
  onAfterSubmit,
  team,
  reviewCycle,
}: {
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: () => void
  team: TeamInterface
  reviewCycle: ReviewCyclesInterface
}) => {
  const user = useSelector(selectUser)
  const employee = useGetEmployee(user.id)

  return (
    <Popup open={isOpen} onClose={onClose}>
      <Header displayMode="inline">
        <Header.CloseButton aria-label="close" />
        <Header.Title>Create roadmap</Header.Title>
      </Header>
      <Form
        initialValues={{
          team,
          review_cycle: reviewCycle,
          owner: employee.data
            ? (employee.data as unknown as EmployeeOptionInterface)
            : undefined,
        }}
        forceParams={{ id: undefined }}
        api={roadmapsRequestsNew}
      >
        <RoadmapForm
          disabledFields={{
            review_cycle:
              'Review cycle prefilled and disabled only in the onboarding flow in order to reflect the table filter.',
          }}
        />
        <Popup.Actions>
          <NewSaveButtonWithPopup
            useValidator
            successText="Roadmap created"
            onAfterSubmit={() => {
              onAfterSubmit()
              onClose()
            }}
          >
            Submit
          </NewSaveButtonWithPopup>
        </Popup.Actions>
      </Form>
    </Popup>
  )
}
