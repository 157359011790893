import { Avatar, Group, Icon, Item, ItemSkeleton, Token } from '@revolut/ui-kit'
import React from 'react'
import { getPeriodCategoryBadgeContent } from './utils'
import { PerformanceSelector } from '@src/interfaces/performance'

interface Props {
  setSelectedPeriod: (target: PerformanceSelector | undefined) => void
  performanceSelector: PerformanceSelector[] | undefined
  selectedPeriod: PerformanceSelector | undefined
}

export const PerformanceCyclesNavigation = ({
  setSelectedPeriod,
  performanceSelector,
  selectedPeriod,
}: Props) => {
  return (
    <Group>
      {!performanceSelector?.length && (
        <>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </>
      )}
      {performanceSelector?.map(item => (
        <Item
          key={item.id}
          use="button"
          variant="compact"
          aria-pressed={selectedPeriod?.id === item.id}
          onClick={() => {
            setSelectedPeriod(item)
          }}
        >
          <Item.Avatar>
            <Avatar color={Token.color.foreground} size={40} textStyle="emphasis1">
              {selectedPeriod?.id === item.id && (
                <Avatar.Badge
                  bg={Token.color.foreground}
                  position="bottom-right"
                  useIcon={<Icon name="Check" size={12} />}
                  size={16}
                />
              )}
              {getPeriodCategoryBadgeContent(item.category, item.name)}
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Description>{item.name}</Item.Description>
          </Item.Content>
        </Item>
      ))}
    </Group>
  )
}
