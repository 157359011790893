import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { ColumnCellInterface } from '@src/interfaces/data'
import { candidateBulkSnoozeRequests } from '@src/api/hiringProcess'
import { CandidateBulkSnoozeInterface } from '@src/interfaces/hiringProccess'
import {
  bulkActionsSnoozeUntilColumn,
  bulkActionsSnoozeNotifyUsersColumn,
  bulkActionsSnoozeCommentColumn,
} from '@src/constants/columns/bulkActions'

const columns: ColumnCellInterface<CandidateBulkSnoozeInterface>[] = [
  {
    ...bulkActionsSnoozeUntilColumn,
    width: 140,
  },
  {
    ...bulkActionsSnoozeNotifyUsersColumn,
    width: 140,
  },
  {
    ...bulkActionsSnoozeCommentColumn,
    width: 140,
  },
]

const CandidateBulkSnooze = () => {
  return (
    <BulkActions<CandidateBulkSnoozeInterface>
      tableRequest={candidateBulkSnoozeRequests}
      columns={columns}
      dataType="Candidate snooze bulk actions"
    />
  )
}

export default CandidateBulkSnooze
