import React, { useEffect, useState } from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import { connect } from 'lape'
import {
  Box,
  chain,
  Group,
  HStack,
  Input,
  MoreBar,
  Subheader,
  Text,
  TextButton,
  Token,
} from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  DocumentStatuses,
  DocumentUploadRequestInterface,
} from '@src/interfaces/documents'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import {
  approveDocumentUpload,
  deleteDocumentUploadFile,
  getDocumentsCommentsAPI,
  newDocumentRequests,
  rejectDocumentUpload,
  useDocumentsRequestApprovals,
} from '@src/api/documents'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import LapeFileUploader from '@src/components/Inputs/LapeFields/LapeFileUploader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import SettingsButtons, {
  ApproveButton,
  DeleteButton,
} from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import SideBar from '@src/components/SideBar/SideBar'
import { PermissionTypes } from '@src/store/auth/types'
import {
  documentUploadRequestActionsColumn,
  documentUploadRequestFileNameColumn,
  documentUploadRequestUploadedByColumn,
  documentUploadRequestUploadedOnColumn,
  getDocumentStatusColor,
} from '@src/constants/columns/documents'
import { downloadTemplateFileAsBlob } from '@src/api/documentsTemplates'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { AttachmentSidebarContents } from './common'
import { DocumentPreview } from '@src/features/DocumentSidebar/DocumentPreview'
import { TableNames } from '@src/constants/table'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'

type Modals =
  | 'deleteAttachment'
  | 'rejectDialog'
  | 'attachmentPreview'
  | 'example'
  | 'comments'

const DocumentUpload = () => {
  const { values, reset } = useLapeContext<DocumentUploadRequestInterface>()
  const params = useParams<{ id: string; employeeId: string }>()

  const [isRejectPending, setIsRejectPending] = useState(false)
  const [attachmentDeletePending, setAttachmentDeletePending] = useState(false)
  const [rejectComment, setRejectComment] = useState('')
  const [example, setExample] = useState<string | null>(null)

  const [openModal, setOpenModal] = useState<Modals | false>(false)

  const isOnboardingUser = !!useRouteMatch([
    ROUTES.ONBOARDING.ANY,
    ROUTES.ONBOARDING_V2.ANY,
  ])

  useEffect(() => {
    if (values.template_file_name && values.document_template?.id) {
      downloadTemplateFileAsBlob(values.document_template.id).then(response => {
        setExample(response)
        setOpenModal('example')
      })
    }
  }, [])

  const canApproveOrReject = values.field_options?.permissions?.includes(
    PermissionTypes.ApproveUploadedDocuments,
  )

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useDocumentsRequestApprovals(
    params.employeeId,
    values.id,
    !canApproveOrReject || isOnboardingUser,
  )

  const commentsApi = getDocumentsCommentsAPI(values.id)
  const {
    data: commentsData,
    isLoading: commentsLoading,
    refetch: commentsRefetch,
  } = commentsApi.useGetComments()
  const getComments = commentsApi.useGetComments(false)

  const onAttachmentDelete = () => {
    setAttachmentDeletePending(true)

    deleteDocumentUploadFile(values.id)
      .then(() => {
        refetchApproval()
        newDocumentRequests.get(params).then(response => reset(response.data))
        setOpenModal(false)
      })
      .finally(() => setAttachmentDeletePending(false))
  }

  const attachmentRow: RowInterface<DocumentUploadRequestInterface> = {
    cells: [
      {
        ...documentUploadRequestFileNameColumn,
        width: 240,
      },
      {
        ...documentUploadRequestUploadedByColumn,
        width: 160,
      },
      {
        ...documentUploadRequestUploadedOnColumn,
        width: 160,
      },
      {
        ...documentUploadRequestActionsColumn,
        width: 180,
        insert: () => {
          return (
            <HStack space="s-8">
              <TextButton
                onClick={() => setOpenModal('attachmentPreview')}
                textStyle="h6"
                pl={0}
              >
                Preview
              </TextButton>
              {values.status.id !== DocumentStatuses.completed ? (
                <TextButton
                  onClick={() => setOpenModal('deleteAttachment')}
                  color={Token.color.red}
                  textStyle="h6"
                  disabled={attachmentDeletePending}
                >
                  Delete
                </TextButton>
              ) : null}
            </HStack>
          )
        },
      },
    ],
  }

  const onApprove = async () => {
    const response = await approveDocumentUpload(params.employeeId, values.id)
    reset(response.data)
  }

  const handleReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectDocumentUpload(
        params.employeeId,
        values.id,
        rejectComment ? { rejection_comment: rejectComment } : undefined,
      )
      reset(response.data)
    } finally {
      setIsRejectPending(false)
      setRejectComment('')
      setOpenModal(false)
      refetchApproval()
      getComments.refetch()
    }
  }

  const isPendingApproval = values.status?.id === 'pending_approval'
  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.DOCUMENTS, { id: params.employeeId })

  const isEmployeeOnboarding = !!useRouteMatch([
    ROUTES.ONBOARDING.ANY,
    ROUTES.ONBOARDING_V2.ANY,
  ])
  const canReject = !isEmployeeOnboarding && canApproveOrReject && isPendingApproval
  const canDelete = !isEmployeeOnboarding
  const canViewComments = !isEmployeeOnboarding

  return (
    <>
      <PageWrapper>
        <PageHeader
          pb="s-8"
          title={chain(
            values.name,
            <Text color={getDocumentStatusColor(values.status.id)}>
              {values.status.name}
            </Text>,
          )}
          subtitle={
            isOnboardingUser ? (
              values.status.id === DocumentStatuses.completed ? (
                ''
              ) : (
                'Please upload required documents'
              )
            ) : (
              <UserWithAvatar {...values.employee} asText mb="s-16" />
            )
          }
          backUrl={backUrl}
          hideGlobalSearch={isEmployeeOnboarding}
        />

        <PageBody>
          <SettingsButtons>
            <ApproveButton
              submit={onApprove}
              onSubmitFailed={handleError}
              statusFieldName="approval_status"
              onAfterSubmit={refetchApproval}
              isVisible={canApproveOrReject && isPendingApproval}
            />
            {canReject && (
              <MoreBar.Action
                onClick={() => setOpenModal('rejectDialog')}
                useIcon="CrossSmall"
                variant="negative"
                pending={isRejectPending}
                disabled={isRejectPending}
              >
                Reject
              </MoreBar.Action>
            )}
            <DeleteButton
              title="Document"
              deleteApi={newDocumentRequests.delete!}
              backUrl={backUrl}
              isVisible={canDelete}
            />
            {values.template_file_name ? (
              <MoreBar.Action onClick={() => setOpenModal('example')} useIcon="Document">
                Example
              </MoreBar.Action>
            ) : null}
            {canViewComments && (
              <MoreBar.Action
                onClick={() => setOpenModal('comments')}
                useIcon="Chat"
                pending={commentsLoading}
              >
                Comments
              </MoreBar.Action>
            )}
          </SettingsButtons>

          {!!values.instructions && (
            <Box py="s-8">
              <Subheader variant="nested">
                <Subheader.Title>Instructions</Subheader.Title>
              </Subheader>
              <HTMLEditor
                value={values.instructions}
                readOnly
                height="auto"
                onChange={() => {}}
                nonResizable
                fontSize="14px"
              />
            </Box>
          )}

          {values.status.id === DocumentStatuses.completed ? null : (
            <>
              <Subheader variant="nested">
                <Subheader.Title>Upload</Subheader.Title>
              </Subheader>
              <LapeFileUploader name="file" />
            </>
          )}

          {isOnboardingUser ? null : (
            <Box mt="s-16">
              <FormPreview title="Details" data={values}>
                <Group>
                  <FormPreview.Item<DocumentUploadRequestInterface>
                    title="Requested by"
                    field="issuer.name"
                    to={document =>
                      pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                        id: document.issuer.id,
                      })
                    }
                  />
                  <FormPreview.Item
                    title="Requested on"
                    field="issue_date_time"
                    type="date"
                  />
                  <FormPreview.Item title="Document category" field="category.name" />
                  {values.status.id !== DocumentStatuses.completed &&
                  (approvalSteps === undefined || approvalSteps?.length) ? (
                    <ApprovalFlow
                      isLoading={isApprovalLoading}
                      steps={approvalSteps || null}
                      onViewRejectionReasonClick={() => {
                        setOpenModal('comments')
                      }}
                    />
                  ) : null}
                </Group>
              </FormPreview>
            </Box>
          )}

          <Box my="s-16">
            <Subheader variant="nested">
              <Subheader.Title>Attachments</Subheader.Title>
            </Subheader>
            <AdjustableTable
              name={TableNames.UploadedDocuments}
              data={values.file_name ? [values] : []}
              row={attachmentRow}
              count={values.file_name ? 1 : 0}
              noDataMessage="All uploaded documents will appear here."
            />
          </Box>
        </PageBody>

        <PageActions>
          <NewSaveButtonWithPopup useValidator />
        </PageActions>
      </PageWrapper>

      <ConfirmationDialog
        open={openModal === 'rejectDialog'}
        onClose={() => setOpenModal(false)}
        onConfirm={handleReject}
        loading={isRejectPending}
        onReject={() => setOpenModal(false)}
        label="Reject comment"
        body={
          <Input
            placeholder="Reject comment (optional)"
            value={rejectComment}
            onChange={e => setRejectComment(e.currentTarget.value)}
          />
        }
        yesMessage="Confirm"
        noMessage="Cancel"
      />

      <ConfirmationDialog
        open={openModal === 'deleteAttachment'}
        onClose={() => setOpenModal(false)}
        onConfirm={onAttachmentDelete}
        loading={attachmentDeletePending}
        onReject={() => setOpenModal(false)}
        label="Deleting attachment"
        body="Are you sure you want to delete this attachment?"
        yesMessage="Confirm"
        noMessage="Cancel"
      />

      <SideBar
        title="Example"
        isOpen={openModal === 'example'}
        onClose={() => setOpenModal(false)}
        sideProps={{ resizable: true }}
      >
        <AttachmentSidebarContents
          file={example}
          file_media_type={values.template_file_media_type}
          file_name={values.template_file_name}
        />
      </SideBar>

      <SideBar
        title="Attachment"
        variant="wide"
        isOpen={openModal === 'attachmentPreview'}
        onClose={() => setOpenModal(false)}
        sideProps={{ resizable: true }}
      >
        <DocumentPreview id={values.id} document={values} />
      </SideBar>

      {canViewComments && (
        <ChatSidebar
          type={ChatMessageType.Comment}
          disableTodolistFeature
          data={commentsData?.results || []}
          refetch={commentsRefetch}
          isOpen={openModal === 'comments'}
          onClose={() => setOpenModal(false)}
          onEdit={commentsApi.editComment}
          onArchive={commentsApi.archiveComment}
          onAddMessage={commentsApi.addComment}
          onResolve={commentsApi.resolveComment}
          isLoading={commentsLoading}
        />
      )}
    </>
  )
}

export default connect(() => (
  <Form api={newDocumentRequests}>
    <DocumentUpload />
  </Form>
))
