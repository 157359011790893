import { EntityTypes } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OrgEntityProvider } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalsInterface } from '@src/interfaces/goals'
import React, { PropsWithChildren } from 'react'

export const EntityFetcher = ({ children }: PropsWithChildren<{}>) => {
  const { values } = useLapeContext<GoalsInterface>()

  return (
    <OrgEntityProvider
      entityType={values.content_type?.model as EntityTypes}
      isCompany={values.is_company}
      entityId={String(values.content_object?.id)}
    >
      {children}
    </OrgEntityProvider>
  )
}
