import React, { ReactChild, useEffect, useRef, useState } from 'react'
import { CellTypes, ColumnCellInterface, FilterByInterface } from '@src/interfaces/data'
import styled, { css } from 'styled-components'
import get from 'lodash/get'
import { format } from 'date-fns'
import Icon from '../Icon/Icon'
import Tooltip from '../Tooltip/Tooltip'
import { Box, Link as A, Token, opacity, Flex } from '@revolut/ui-kit'
import { Link as LinkIcon } from '@revolut/icons'
import LongNumberCell from '@components/Table/AdvancedCells/LongNumberCell/LongNumberCell'
import TableCellLink from '@components/TableCellLink/TableCellLink'
import { RowHeight, TableTypes } from '@src/interfaces/table'
import { utcToLocalDate } from '@src/utils/timezones'
import { defaultTheme } from '@src/styles/theme'
import { getCellUrl } from '@components/TableV2/utils'

export interface CellProps<T> {
  cell: ColumnCellInterface<T>
  dataPath: string
  idPath: string
  data: any
  disabled?: boolean
  children: string
  error?: string
  reduceSize: number
  noAutoResize?: boolean
  onFilterChange?: (filters: FilterByInterface) => void
  type: TableTypes
  parentIndexes: number[]
  prefixContent?: React.ReactNode
  rowHeight: RowHeight
}

const CellCoverHoverCss = (reduceSize?: number) => css`
  &:hover {
    position: absolute;
    top: 0;
    left: ${reduceSize || 0}px;
    height: 100%;
    flex-grow: 1;
    display: grid;
    align-items: center;
    overflow: visible;
    background-color: inherit;
    z-index: ${defaultTheme.zIndex.aboveMain};
  }
`

export const cellWrapperRole = 'cell-wrapper'

const CellWrapperMatrixCss = css`
  border-right: 1px solid ${opacity(Token.colorChannel.black, 0.1)};
  min-height: 49px;
`

const DateTimeContainer = styled.div`
  white-space: nowrap;
`

const Gray50 = styled.span`
  color: ${Token.color.greyTone50};
`

const CellWrapper = styled.div<{
  width?: number
  type: TableTypes
  isError?: boolean
  background?: string
}>`
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 0;
  position: relative;
  max-width: ${props => (props.width ? `${props.width}px` : 'unset')};
  min-width: ${props => (props.width ? `${props.width}px` : 'unset')};
  width: ${props => (props.width ? `${props.width}px` : 'unset')};
  ${({ type }) => type === TableTypes.Matrix && CellWrapperMatrixCss};
  background-color: ${props => {
    if (props.isError) {
      return Token.color.red_40
    }
    return props.background || 'inherit'
  }};

  &:hover {
    background-color: ${props => props.background || 'inherit'};
  }
`

const MaskedWrapper = styled.span<{ type: TableTypes }>`
  display: flex;
  color: ${Token.color.greyTone20};
  ${({ type }) => type === TableTypes.Matrix && 'padding-left: 16px;'};
`

const CellCoverMatrixCss = css`
  padding: 0;
  overflow: initial;
`

const cellCoverFontSize = {
  small: 13,
  medium: 13,
  large: 14,
}

const cellCoverLineHeight = {
  small: 16,
  medium: 16,
  large: 22,
}

const transparentBgImage = css`
  background-image: linear-gradient(
      90deg,
      var(--rui-table-row-bg, transparent),
      var(--rui-table-row-bg, transparent)
    ),
    linear-gradient(90deg, var(--rui-color-grey-tone-2), var(--rui-color-grey-tone-2));
`

const cellWrapperBox = css`
  display: contents;

  [data-theme-transparent='true'] & {
    display: block;
    background: ${Token.color.groupedBackground};
  }
`

const cellWrapperFlex = css`
  display: contents;

  [data-theme-transparent='true'] & {
    display: flex;
    ${transparentBgImage};
  }
`

const CellCover = styled.div<{
  disabled?: string
  color?: string
  type: TableTypes
  reduceSize: number
  notHoverable?: boolean
  rowHeight: RowHeight
  padding?: string
}>`
  font-size: ${({ rowHeight }) => `${cellCoverFontSize[rowHeight]}px`};
  line-height: ${({ rowHeight }) => `${cellCoverLineHeight[rowHeight]}px`};
  padding: ${({ padding }) => padding || '0 8px 0 16px'};
  min-width: ${({ reduceSize }) =>
    reduceSize ? `calc(100% - ${reduceSize}px)` : '100%'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props =>
    props.disabled ? Token.color.greyTone50 : props.color || Token.color.foreground};
  ${({ type }) => type === TableTypes.Matrix && CellCoverMatrixCss};
  ${({ notHoverable, reduceSize }) => !notHoverable && CellCoverHoverCss(reduceSize)};

  [data-theme-transparent='true'] & {
    background: ${Token.color.groupedBackground};
    height: 100%;
    display: flex;
    align-items: center;
    ${transparentBgImage};
  }
`

const FilterByButton = styled.div<{ filterHover: boolean; textAlign: 'left' | 'right' }>`
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: ${props => (props.filterHover ? 'underline' : 'none')};
  text-align: ${props => props.textAlign};
`

const TextPlaceholder = styled.span``

const uppercase = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

export interface CellContentProps<T> {
  cell: ColumnCellInterface<T>
  dataPath: string
  idPath: string
  data: any
  children: string
  parentIndexes: number[]
}

export const CellContentByType = <T extends {}>({
  cell,
  data,
  children,
  dataPath,
  idPath,
  parentIndexes,
}: CellContentProps<T>) => {
  let content: React.ReactNode
  switch (cell.type) {
    case CellTypes.date:
      if (children !== '-') {
        content = format(new Date(children), 'd MMM yyyy')
      } else {
        content = children
      }
      break
    case CellTypes.dateInUtc:
      if (children !== '-') {
        content = format(utcToLocalDate(children), 'd MMM yyyy')
      } else {
        content = children
      }
      break
    case CellTypes.dateTime:
      if (children !== '-') {
        content = (
          <DateTimeContainer>
            {format(new Date(children), 'd MMM yyyy')}{' '}
            <Gray50>{format(new Date(children), 'HH:mm OOO')}</Gray50>
          </DateTimeContainer>
        )
      } else {
        content = children
      }
      break
    case CellTypes.insert:
      if (cell.insert) {
        const url = getCellUrl(cell, data)
        content = cell.insert({ data, children, dataPath, idPath, parentIndexes, url })
      }
      break
    case CellTypes.link:
      content =
        children !== '-' ? (
          <A
            onClick={e => e.stopPropagation()}
            href={children}
            target="_blank"
            display="block"
            width={16}
            rel="noreferrer noopener"
          >
            <LinkIcon hoverColor="blue" color="grey-tone-50" size={16} />
          </A>
        ) : (
          children
        )
      break
    case CellTypes.text:
      content = typeof children === 'string' ? uppercase(children) : children
      break
    case CellTypes.longNumber:
      content = <LongNumberCell value={data} />
      break
  }

  return <>{content}</>
}

export const Cell = <T extends {}>({
  cell,
  data,
  dataPath,
  idPath,
  error,
  children,
  disabled,
  onFilterChange,
  reduceSize,
  type,
  parentIndexes,
  prefixContent,
  rowHeight,
}: CellProps<T>) => {
  let content: React.ReactChild | null
  const [filterHover, setFilterHover] = useState(false)
  const [unmasked, setUnmasked] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    if (unmasked && cell.masked) {
      setUnmasked(false)
    }
  }, [cell.masked])

  content = (
    <CellContentByType
      cell={cell}
      data={data}
      dataPath={dataPath}
      idPath={idPath}
      parentIndexes={parentIndexes}
    >
      {children}
    </CellContentByType>
  )

  if (!unmasked && cell.masked) {
    content = (
      <MaskedWrapper
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          setUnmasked(true)
        }}
        type={type}
      >
        <Tooltip placement="top" text="Click to see hidden information">
          <Icon type="EyeCrossed" size="small" />
        </Tooltip>
      </MaskedWrapper>
    )
  }

  const handleFilteringBy = () => {
    const dataId = get(data, cell.idPoint)
    if (!cell.filterType && cell.filterKey) {
      onFilterChange?.({
        columnName: cell.filterKey,
        filters: [
          {
            id: dataId,
            name: dataId,
          },
        ],
      })
    }
  }

  const renderFilterByButton = (cnt: ReactChild | null) => {
    return (
      <TextPlaceholder>
        <FilterByButton
          filterHover={filterHover}
          onClick={e => {
            if (e.altKey && cell.type === CellTypes.text) {
              handleFilteringBy()
              e.preventDefault()
              e.stopPropagation()
            }
          }}
          onMouseMove={e => {
            if (e.altKey && cell.type === CellTypes.text && !filterHover) {
              setFilterHover(true)
            }
          }}
          onMouseLeave={() => {
            if (filterHover) {
              setFilterHover(false)
            }
          }}
          textAlign={cell.textAlign || 'left'}
        >
          {cnt}
        </FilterByButton>
      </TextPlaceholder>
    )
  }

  const renderContent = () => {
    const url = getCellUrl(cell, data)

    if (url) {
      return <TableCellLink to={url}>{renderFilterByButton(content)}</TableCellLink>
    }
    return renderFilterByButton(content)
  }

  const bg = !unmasked && cell.masked ? undefined : cell.background?.(data)

  return (
    <CellWrapper
      role={cellWrapperRole}
      data-testid="cell-wrapper"
      data-column-title={cell.title}
      width={cell.width + (cell.resizeWidth || 0)}
      type={type}
      isError={!!error}
      ref={ref}
      css={cell.wrapperCss?.(data)}
      background={bg}
    >
      <Box height="100%" css={cellWrapperBox}>
        <Flex height="100%" alignItems="center" bg={bg} css={cellWrapperFlex}>
          {prefixContent}
          {error && ref.current && (
            <Tooltip
              placement="bottom"
              forceShow
              anchor={ref.current}
              body={
                <Box minWidth={150} color="background" p="s-12">
                  {error}
                </Box>
              }
            />
          )}
        </Flex>
      </Box>
      <CellCover
        disabled={disabled ? 'disabled' : ''}
        color={cell.colors && cell.colors(data)}
        type={type}
        reduceSize={reduceSize}
        notHoverable={cell.notHoverable}
        rowHeight={rowHeight}
        padding={cell.padding}
        data-cell-cover
      >
        {renderContent()}
      </CellCover>
    </CellWrapper>
  )
}
