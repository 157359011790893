import React from 'react'
import { Input, InputGroup } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'

export const General = () => {
  const { values } = useLapeContext<BudgetPoolInterface>()
  const params = useParams<{ id?: string }>()

  return (
    <>
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewInput
              name="name"
              label="Name"
              required
              message={`This will be the display name of the custom budget pool e.g. “Risk & Compliance Regulated Function”`}
            />
            <Input
              label="Type"
              value={values.pool_type?.name || 'Custom'}
              disabled
              message="You can create custom budget pools on top of all the departments budget pools which are created by default."
            />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
              message="This is the person who will be permitted to view, modify and submit the compensation change recommendations for this custom budget pool."
            />
          </InputGroup>

          <NewStepperTitle title="Members" />
          <InputGroup>
            <ActionWidget
              title="Employees in custom budget pools will not appear in their department pool"
              text="Please also note that employees can only be part of a single custom budget pool. In case an employee is accidentally defined to be part of multiple budget pools at the same time, it will not be possible to edit either pools until they are removed from all but one of the budget pools."
            />
            <LapeNewMultiSelect
              name="dynamic_groups"
              placeholder="Eligibility groups"
              selector={selectorKeys.dynamic_groups}
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Budget pool successfully saved"
          onAfterSubmit={res => {
            const backUrl = pathToUrl(ROUTES.FORMS.BUDGET_POOL.PREVIEW, {
              id: res.id,
            })
            if (params.id) {
              goBack(backUrl)
            } else {
              navigateReplace(backUrl)
            }
          }}
          useValidator
        />
      </PageActions>
    </>
  )
}
