import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'

type Props = {
  title: string
  data: EmployeeInterface
}
export const EmployeePageHeader = ({ title, data }: Props) => {
  return (
    <PageHeader
      title={title}
      subtitle={<UserWithAvatar {...data} status={data.status.id as IdStatuses} asText />}
      backUrl={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: String(data.id) })}
    />
  )
}
