import React from 'react'
import { DataPoint, Flex, TableWidget } from '@revolut/ui-kit'

import { useTable } from '@components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface, RelevantKpiInterface } from '@src/interfaces/kpis'
import { relevantKpisRequests } from '@src/api/kpis'
import { Statuses } from '@src/interfaces'
import {
  getAssignActionColumn,
  relevantCategoryColumn,
  relevantKPICalibratedPerformanceColumn,
  relevantKpiCurrentValueColumn,
  relevantKPIEntityColumn,
  relevantKpiGenericNameColumn,
  relevantKpiInitialValueColumn,
  relevantKpiOwnerNameColumn,
  relevantKpiParentColumn,
  relevantKpiPerformanceColumn,
  relevantKpiStatusColumn,
  relevantKpiTargetColumn,
  relevantKpiWeight,
} from '@src/constants/columns/relevantKpi'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { EmployeeInterface } from '@src/interfaces/employees'
import AdjustableTable from '@components/Table/AdjustableTable'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import { TableNames } from '@src/constants/table'
import { onKPITableRowClick } from './utils'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'

interface StructureProps {
  data: EmployeeInterface
  navigation?: React.ReactElement
}

const getRow = (
  employee: EmployeeInterface,
): RowInterface<KpiInterface | RelevantKpiInterface> => ({
  disabled: data => (data as KpiInterface).target_status === Statuses.archived,
  noChildrenRequest: true,
  isChildrenOpener: (data: KpiInterface | RelevantKpiInterface) => 'children' in data,
  cells: [
    {
      ...relevantKpiGenericNameColumn,
      width: 270,
    },
    {
      ...relevantKpiWeight,
      width: 80,
    },
    {
      ...relevantKpiPerformanceColumn,
      width: 100,
    },
    {
      ...relevantKPICalibratedPerformanceColumn,
      width: 130,
    },
    {
      ...relevantKPIEntityColumn,
      width: 130,
    },
    {
      ...relevantKpiInitialValueColumn,
      width: 80,
    },
    {
      ...relevantKpiCurrentValueColumn,
      width: 90,
    },
    {
      ...relevantKpiTargetColumn,
      width: 80,
    },
    {
      ...relevantKpiParentColumn,
      width: 60,
    },
    {
      ...relevantKpiOwnerNameColumn,
      width: 195,
    },
    {
      ...relevantCategoryColumn,
      width: 100,
    },
    {
      ...relevantKpiStatusColumn,
      width: 70,
    },
    {
      ...getAssignActionColumn(employee),
      width: 80,
    },
  ],
})

const RelevantKPI = ({ data, navigation }: StructureProps) => {
  const isNewLayout = useIsNewLayout()

  const initialFilter = [
    {
      filters: [
        { name: Statuses.active, id: Statuses.active },
        { name: Statuses.draft, id: Statuses.draft },
        { name: 'new', id: 'new' },
      ],
      columnName: 'status',
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
  ]

  const table = useTable<RelevantKpiInterface>(
    relevantKpisRequests(data.id),
    initialFilter,
  )

  const handleRowClick = (
    row: KpiInterface | RelevantKpiInterface,
    parentIndexes: number[],
  ) => {
    if ('id' in row && parentIndexes.length !== 1) {
      onKPITableRowClick(row as KpiInterface)
    }
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <DataPoint>
          {isNewLayout ? (
            <PerformanceLayoutCycleFilter
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          ) : (
            <CycleFilter
              type={CycleFilterType.NewUI}
              filterInputType={FilterSelectType.SingleSelect}
              onFilterChange={table.onFilterChange}
              columnName="review_cycle__offset"
              filter={table.filterBy}
            />
          )}
        </DataPoint>
      </TableWidget.Info>
      {navigation && (
        <TableWidget.Search>
          <Flex justifyContent="flex-end">{navigation}</Flex>
        </TableWidget.Search>
      )}
      <TableWidget.Table>
        <AdjustableTable<RelevantKpiInterface>
          name={TableNames.EmployeeRelevantKPIs}
          useWindowScroll
          row={getRow(data)}
          {...table}
          noDataMessage="Please add your relevant KPIs."
          onRowClick={handleRowClick}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default RelevantKPI
