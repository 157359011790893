import { useCallback, useEffect } from 'react'

import { useQuery } from '@src/utils/queryParamsHooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getCopyDefaultField } from '@src/utils/setDefaultField'

export type CommonTabsProps = {
  nextTabPath: string
}

export const useCopyEmptyFields = <T extends {}>(
  sourceValues: T | undefined,
  fieldsToCopy: Array<keyof T>,
) => {
  const { query } = useQuery()
  const { values } = useLapeContext<T>()

  const copyDefaultField = useCallback(getCopyDefaultField<T>(values, sourceValues), [
    sourceValues,
  ])

  useEffect(() => {
    fieldsToCopy.forEach(copyDefaultField)
  }, [query.copyFrom, copyDefaultField])
}
