import React from 'react'
import { HStack, Icon, IconName, Text, Token, Tooltip, useTooltip } from '@revolut/ui-kit'

export type LinkedInPostingStatusProps = {
  description: string
  descriptionColor?: string
  iconColor?: string
  iconName?: IconName
  tooltipLabel?: React.ReactNode
}

export const LinkedInPostingStatus = ({
  description,
  descriptionColor = Token.color.greyTone50,
  iconColor,
  iconName,
  tooltipLabel,
}: LinkedInPostingStatusProps) => {
  const tooltip = useTooltip()
  return (
    <HStack align="center" gap="s-8">
      <Text color={descriptionColor}>{description}</Text>
      <Icon color={iconColor} name={iconName} size={16} {...tooltip.getAnchorProps()} />
      {tooltipLabel && (
        <Tooltip {...tooltip.getTargetProps()} maxWidth="640px">
          {tooltipLabel}
        </Tooltip>
      )}
    </HStack>
  )
}
