import { GetRequestInterface, RequestInterface } from '../interfaces'
import { api } from './index'
import { API } from '../constants/api'
import { filterSortPageIntoQuery } from '../utils/table'
import {
  FeedbackInterface,
  FeedbackLabelInterface,
  FeedbackTopic,
} from '../store/feedback/types'
import { useFetch } from '@src/utils/reactQuery'

export const feedbackRequests: RequestInterface<FeedbackInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(API.FEEDBACK, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.FEEDBACK}/${id}`),
  patchItem: async (data, id) => api.patch(`${API.FEEDBACK}/${id}`, data),
  deleteItem: async id => api.delete(`${API.FEEDBACK}/${id}`),
  postItem: async data => api.post(API.FEEDBACK, data),
  getSelectors: name =>
    api.get(`${API.FEEDBACK}/_selectors`, {
      params: {
        name,
      },
    }),
}

export const useGetFeedback = (id?: number) =>
  useFetch<FeedbackInterface>(id ? `${API.FEEDBACK}/${id}` : null)

export const useGetFeedbackLabels = (
  topic: FeedbackTopic,
  score: number | null,
  enabled?: boolean,
) => {
  const result = useFetch<GetRequestInterface<FeedbackLabelInterface>>(
    `/feedbackLabels`,
    'v1',
    { params: { topic } },
    true,
    { enabled },
  )
  return result.data?.results?.filter(
    label => label.score === score && label.topic === topic,
  )
}
