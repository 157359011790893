import * as React from 'react'
import {
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
} from '@src/interfaces/performance'
import { EmployeeInterface } from '@src/interfaces/employees'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useState } from 'react'
import { COOKIE } from '@src/constants/api'
import { checkRejectAvailability } from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { Popup, Header, Text, Button } from '@revolut/ui-kit'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface Props {
  request: PerfReviewRequestFeedbackInterface
  performanceCycle: PerformanceSelector
  employee: EmployeeInterface
  onReject: () => void
}

const RejectReviewPopup = ({ onReject, performanceCycle, employee, request }: Props) => {
  const [showPopup, setShowPopup] = useState(true)

  const dismissedList = JSON.parse(
    workspaceLocalStorage.getItem(COOKIE.PERFORMANCE_REJECT_DISMISSED) || '[]',
  )

  const handleAdd = () => {
    dismissedList.push(request.id)
    workspaceLocalStorage.setItem(
      COOKIE.PERFORMANCE_REJECT_DISMISSED,
      JSON.stringify(dismissedList),
    )

    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_PERFORMANCE.GENERAL, {
        employeeId: employee.id,
        id: request.id,
      }),
    )
  }

  const canReject = checkRejectAvailability(request)

  if (!canReject || !showPopup || dismissedList.includes(request.id)) {
    return null
  }

  return (
    <Popup open onClose={() => setShowPopup(false)} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>
          {performanceCycle.name} review for {employee.display_name}
        </Header.Title>
      </Header>
      <Text use="p" variant="caption" color="grey-tone-50">
        You are requested to review {employee.first_name}’s performance during{' '}
        {performanceCycle.name}. <br />
        <br />
        If you have insufficient knowledge to review this employee, you can reject this
        request.
      </Text>
      <Popup.Actions>
        <Button variant="secondary" onClick={onReject}>
          Reject Review Request
        </Button>
        <Button onClick={handleAdd}>Add review</Button>
      </Popup.Actions>
    </Popup>
  )
}

export default RejectReviewPopup
