import React from 'react'
import { Color, Text, VStack } from '@revolut/ui-kit'

import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

type Props = {
  open: boolean
  onClose: () => void
  pending: boolean
  onConfirm: () => void
  onReject: () => void
}
export const AnonymiseProfileConfirmation = ({
  open,
  onClose,
  onConfirm,
  pending,
  onReject,
}: Props) => {
  return (
    <ConfirmationDialog
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      loading={pending}
      onReject={onReject}
      label="This manual trigger is for testing purposes only."
      body={
        <VStack gap="s-6">
          <Text>Are you sure you want to proceed?</Text>
          <Text>
            This will anonymise all data objects linked to this employee and it will
            scramble any field containing personally identifiable information.
          </Text>
          <Text color={Color.RED}>
            This action is not reversible. Please proceed with caution.
          </Text>
        </VStack>
      }
    />
  )
}
