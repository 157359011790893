import React from 'react'

import { APPLICATIONS } from '@src/constants/hub'
import { HubAppWithOnboardingScreen } from '@src/features/HubApp/HubAppWithOnboardingScreen'

const Teams = () => {
  return <HubAppWithOnboardingScreen category="teams" app={APPLICATIONS.teams} />
}

export default Teams
