import React, { useEffect } from 'react'
import { HStack, Text, ActionButton, Icon, ThemeProvider } from '@revolut/ui-kit'
import { differenceInDays } from 'date-fns'
import pluralize from 'pluralize'

import { useCurrentTenantInfo } from '@src/api/tenants'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { BannerRenderer, useBanner } from '../UIKitWithThemeProvider/BannerProvider'

const BANNER_KEY = 'demo-mode-banner'

export const DemoModeWarningBar = () => {
  const tenantInfo = useCurrentTenantInfo()
  const { show, hide } = useBanner()

  useEffect(() => {
    if (tenantInfo.isDemoMode) {
      show(BANNER_KEY)
    } else {
      hide(BANNER_KEY)
    }
  }, [tenantInfo.isDemoMode])

  const endOfTrial = tenantInfo.demo_mode_expiration_date_time

  const daysLeftInTrial = endOfTrial
    ? differenceInDays(new Date(endOfTrial), new Date())
    : null

  return (
    <BannerRenderer bannerKey={BANNER_KEY} justifyContent="flex-end">
      <HStack space="s-16" align="center">
        <Text>
          {daysLeftInTrial != null ? (
            <HStack align="center" space="s-4">
              <Icon name="TimeOutline" size={14} />
              {daysLeftInTrial > 0 ? (
                <Text>
                  {pluralize('day', daysLeftInTrial, true)} left in testing mode
                </Text>
              ) : (
                <Text>Last day left in testing mode</Text>
              )}
            </HStack>
          ) : (
            'Demo mode is on'
          )}
        </Text>
        <ThemeProvider mode="light">
          <ActionButton
            onClick={() => navigateTo(ROUTES.PLANS.SELECT_PLAN)}
            useIcon="ArrowUpgrade"
            variant="white"
            size="xs"
          >
            Upgrade now
          </ActionButton>
        </ThemeProvider>
      </HStack>
    </BannerRenderer>
  )
}
