import { PageBody } from '@src/components/Page/PageBody'
import React from 'react'
import Navigation from './Navigation'
import { selectorKeys } from '@src/constants/api'
import { postEmployeesBulkEdit } from '@src/api/employeesBulkEdit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { BulkEditData } from '@src/interfaces/bulkEdit'
import { useLocation } from 'react-router-dom'
import SelectFields from '@src/components/BulkEdit/SelectFields'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const FIELDS = [
  {
    id: 'team',
    name: 'Team',
    selector: selectorKeys.team,
  },
  {
    id: 'line_manager',
    name: 'Line Manager',
    selector: selectorKeys.all_employees,
  },
  {
    id: 'quality_control',
    name: 'Functional Manager',
    selector: selectorKeys.all_employees,
  },
  {
    id: 'mentor',
    name: 'Buddy',
    selector: selectorKeys.all_employees,
  },
]

const SelectFieldsPage = () => {
  const { state: bulkEditData } = useLocation<BulkEditData>()

  if (
    !bulkEditData ||
    !bulkEditData.selectedIds ||
    bulkEditData.selectedIds.length === 0
  ) {
    return <InternalRedirect to={pathToUrl(ROUTES.FORMS.BULK_EDIT_EMPLOYEES.SELECT)} />
  }

  return (
    <PageBody pb="s-32">
      <Navigation selectedIds={bulkEditData?.selectedIds} />
      <SelectFields
        fields={FIELDS}
        createBulkEdit={postEmployeesBulkEdit}
        nextStepRoute={ROUTES.FORMS.BULK_EDIT_EMPLOYEES.REVIEW}
      />
    </PageBody>
  )
}

export default SelectFieldsPage
