import React, { MouseEvent } from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  EmployeeRelation,
  MeetingCompanionInterface,
  MeetingInterface,
  MeetingStatus,
} from '@src/interfaces/meetings'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { differenceInDays, formatDuration } from 'date-fns'
import { formatDateTime, getDatesDuration } from '@src/utils/format'
import { TextButton, Link, HStack, Token } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { employeesRequests } from '@src/api/employees'
import { getCreateNewMeetingLink } from '@src/utils/oneToOnes'
import { onCreateGoogleEvent } from '@src/pages/Forms/MeetingsWith/common'

export const meetingsEmployeeNameColumn: ColumnInterface<
  MeetingCompanionInterface | MeetingInterface
> = {
  type: CellTypes.insert,
  idPoint: 'employee__id',
  dataPoint: 'employee__full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.employee,
  insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  title: 'Meeting with',
}

export const meetingsRelationColumn: ColumnInterface<MeetingCompanionInterface> = {
  type: CellTypes.text,
  idPoint: 'employee_relation',
  dataPoint: 'employee_relation',
  sortKey: 'employee_relation',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Relationship with you',
}

export const meetingsNextDateColumn: ColumnInterface<MeetingCompanionInterface> = {
  type: CellTypes.insert,
  idPoint: 'next_meeting_date',
  dataPoint: 'next_meeting_date',
  sortKey: 'next_meeting_date',
  filterKey: 'next_meeting_date',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  colors: data => {
    if (!data.next_meeting_date) {
      return Token.color.greyTone50
    }
    const daysUntilNext = differenceInDays(new Date(data.next_meeting_date), new Date())

    if (daysUntilNext > 7) {
      return Token.color.warning
    }
    return 'inherit'
  },
  title: 'Next meeting',
  insert: ({ data }) => {
    if (!data.next_meeting_date) {
      return 'Unscheduled'
    }
    return formatDateTime(data.next_meeting_date)
  },
}

export const meetingsDaysSinceLastColumn: ColumnInterface<MeetingCompanionInterface> = {
  type: CellTypes.text,
  idPoint: 'days_since_last_meeting',
  dataPoint: 'days_since_last_meeting',
  sortKey: 'days_since_last_meeting',
  filterKey: 'days_since_last_meeting',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  colors: data => {
    if (!data.days_since_last_meeting) {
      return Token.color.greyTone50
    }
    if (data.days_since_last_meeting && data.days_since_last_meeting > 7) {
      return Token.color.warning
    }
    return 'inherit'
  },
  title: 'Days since last meeting',
}

export const getMeetingsActionColumn = (
  employee: EmployeeInterface,
  onMakeNotes: (data: MeetingCompanionInterface) => void,
): ColumnInterface<MeetingCompanionInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    const onCreateNewMeetingClick = async (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation()
      const { data: companion } = await employeesRequests.getItem(data.id)
      const isManager =
        data.employee_relation === EmployeeRelation.LineManager ||
        data.employee_relation === EmployeeRelation.FunctionalManager
      const link = getCreateNewMeetingLink(
        isManager ? employee : companion,
        isManager ? companion : employee,
      )
      onCreateGoogleEvent(link)
    }
    return (
      <HStack space="s-12">
        {data.next_meeting_date && (
          <TextButton
            onClick={e => {
              e.stopPropagation()
              onMakeNotes(data)
            }}
          >
            Make notes
          </TextButton>
        )}
        <TextButton onClick={onCreateNewMeetingClick}>Create meeting</TextButton>
      </HStack>
    )
  },
})

export const meetingDateColumn: ColumnInterface<MeetingInterface> = {
  type: CellTypes.insert,
  idPoint: 'start',
  dataPoint: 'start',
  sortKey: 'start',
  filterKey: 'start',
  filterType: FilterType.date,
  selectorsKey: selectorKeys.none,
  title: 'Meeting date',
  insert: ({ data }) => formatDateTime(data.start),
}

export const meetingDurationColumn: ColumnInterface<MeetingInterface> = {
  type: CellTypes.insert,
  idPoint: 'end',
  dataPoint: 'end',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Duration',
  insert: ({ data }) => {
    const duration = getDatesDuration(data.start, data.end)
    return formatDuration(duration, { format: ['hours', 'minutes'], delimiter: ', ' })
  },
}

export const meetingStatusColumn: ColumnInterface<MeetingInterface> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  colors: data => {
    if (data.status === MeetingStatus.Scheduled) {
      return Token.color.success
    }
    return 'inherit'
  },
}

export const meetingLinkColumn: ColumnInterface<MeetingInterface> = {
  type: CellTypes.insert,
  idPoint: 'link',
  dataPoint: 'link',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Meeting details',
  insert: ({ data }) => {
    return (
      <Link href={data.link} target="_blank">
        Open google event
      </Link>
    )
  },
}

export const getMeetingsNotesColumn = (
  onViewNotes: (meetingId: number) => void,
): ColumnInterface<MeetingInterface> => ({
  type: CellTypes.insert,
  idPoint: 'action',
  dataPoint: 'action',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Action',
  insert: ({ data }) => {
    return (
      <TextButton
        onClick={e => {
          e.stopPropagation()
          onViewNotes(data.id)
        }}
      >
        Notes
      </TextButton>
    )
  },
})
