import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { CreateTeamSimpleInterface } from '@src/interfaces/teams'
import { createTeamSimple } from '@src/api/teams'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import { selectUser } from '@src/store/auth/selectors'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { createNewKey } from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { CreateDepartmentPopup } from './CreateDepartment'

interface CreateTeamPopupProps {
  open: boolean
  onSuccess: (entity: IdAndName) => void
  onClose: () => void
  showDepartmentField?: boolean
}
export const CreateTeamPopup = ({
  open,
  onSuccess,
  onClose,
  showDepartmentField,
}: CreateTeamPopupProps) => {
  const [pending, setPending] = useState(false)

  return (
    <BottomSheet open={open} onClose={onClose} preventUserClose={pending}>
      <Header>
        <Header.Title>Create new team</Header.Title>
      </Header>

      <LapeForm<CreateTeamSimpleInterface>
        onSubmit={form => {
          setPending(true)

          return createTeamSimple(form.values)
            .then(response => {
              onSuccess({ id: response.data.id, name: response.data.name })
              return form.values
            })
            .finally(() => {
              setPending(false)
            })
        }}
      >
        <FormValidatorProvider>
          <CreateTeamForm showDepartmentField={showDepartmentField} />
        </FormValidatorProvider>
      </LapeForm>
    </BottomSheet>
  )
}

interface CreateTeamFormProps {
  showDepartmentField?: boolean
}
const CreateTeamForm = ({ showDepartmentField }: CreateTeamFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<CreateTeamSimpleInterface>()
  const { validate } = useSafeFormValidator()

  const statusPopup = useStatusPopup()

  const currentUser = useSelector(selectUser)
  const departments = useFetchOptions<IdAndName>(selectorKeys.department)
  const mainDepartment = departments.options?.find(d => d.value.id === 1)

  const [createNewDepartmentState, setCreateNewDepartmentState] = useState<{
    onChangeAction: (entity: IdAndName) => void
  }>()

  useEffect(() => {
    values.team_owner = { id: currentUser.id, name: currentUser.full_name }
  }, [])

  useEffect(() => {
    if (showDepartmentField && !values.department && mainDepartment) {
      values.department = mainDepartment.value
    }
  }, [showDepartmentField, mainDepartment])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="Name" required />
        <LapeRadioSelectInput
          name="team_owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
        {showDepartmentField && (
          <LapeRadioSelectInput
            name="department"
            label="Department"
            selector={selectorKeys.department}
            required
            showCreateNewButton
            onChange={option => {
              if (option?.id === createNewKey) {
                setCreateNewDepartmentState({
                  onChangeAction: entity => {
                    values.department = entity
                  },
                })
              } else if (option) {
                values.department = option as IdAndName
              }
            }}
            allowSetValueToCurrentOption
          />
        )}
      </InputGroup>

      <BottomSheet.Actions>
        <Button
          onClick={validate(
            () => submit(),
            error => {
              statusPopup.show(
                <StatusPopup variant="error">
                  <StatusPopup.Title>Failed to create a team</StatusPopup.Title>
                  <StatusPopup.Description>
                    {getStringMessageFromError(error)}
                  </StatusPopup.Description>
                </StatusPopup>,
              )
            },
          )}
          pending={isSubmitting}
          elevated
        >
          Confirm
        </Button>
      </BottomSheet.Actions>

      <CreateDepartmentPopup
        open={!!createNewDepartmentState}
        onSuccess={entity => {
          createNewDepartmentState?.onChangeAction(entity)
          setCreateNewDepartmentState(undefined)
        }}
        onClose={() => setCreateNewDepartmentState(undefined)}
      />
    </>
  )
}
