import { Box, createChain, Grid, Text } from '@revolut/ui-kit'
import React from 'react'

interface PasswordStrengthProps {
  password?: string
}

const colors = ['red', 'orange', 'yellow', 'lime', 'green']

export const getStrengthAndErrors = (password: PasswordStrengthProps['password']) => {
  if (!password) {
    return null
  }
  let strength = 0
  let errors = []

  if (password.length >= 9) {
    strength += 1
  } else {
    errors.push('Password should be at least 9 symbols long.')
  }

  if (/[A-Z]/.test(password)) {
    strength += 1
  } else {
    errors.push('Password should contain at least one upper case letter.')
  }

  if (/[a-z]/.test(password)) {
    strength += 1
  } else {
    errors.push('Password should contain at least one lower case letter.')
  }

  if (/\d/.test(password)) {
    strength += 1
  } else {
    errors.push('Password should contain at least one number.')
  }

  // special chars
  if (/[^A-Za-z\d]/.test(password)) {
    strength += 1
  } else {
    errors.push('Password should contain at least one special symbol.')
  }

  return { strength, errors }
}

const PasswordStrength = ({ password }: PasswordStrengthProps) => {
  const strengthAndErrors = getStrengthAndErrors(password)

  if (!strengthAndErrors) {
    return null
  }

  const { strength, errors } = strengthAndErrors

  return (
    <>
      <Grid columns={5} gap="s-4" mt="s-6">
        {[...Array(strength)].map((_, id) => (
          <Box bg={colors[strength - 1]} height="s-6" radius="inner" key={id} />
        ))}
      </Grid>
      <Text pt="s-2">{createChain(<br />)(errors)}</Text>
    </>
  )
}

export default PasswordStrength
