import React from 'react'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import {
  Dots,
  Flex,
  FilterButton,
  chain,
  HStack,
  Highlights,
  DataPoint,
  DataPointSkeleton,
} from '@revolut/ui-kit'
import { Route, Switch, useLocation, matchPath } from 'react-router-dom'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { useIsNewTable } from '@components/TableV2/hooks'
import { CompanyNavigation } from '@src/pages/Organisation/components/CompanyNavigation/CompanyNavigation'
import Table from '@src/components/TableV2/Table'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { DataPointLink } from '@components/StatNavigation/StatNavigation'

interface RiskProps {
  tabs: TabBarTableNavigationInterface[]
  wrapNavigation?: boolean
  isCompany?: boolean
}

const RiskTab = ({ tabs, wrapNavigation = false, isCompany = false }: RiskProps) => {
  const isNewTable = useIsNewTable()
  const location = useLocation()

  const statsLoaded = tabs.every(tab => !!tab.quickSummary)

  return (
    <Flex flexDirection="column" minHeight={0}>
      {!isNewTable && !wrapNavigation ? (
        <Flex alignItems="flex-start" mb="s-24">
          {statsLoaded ? <TabBarNavigation isSubtab tabs={tabs} /> : <Dots height={44} />}
        </Flex>
      ) : null}

      <Switch>
        <Table.Widget>
          {isNewTable ? (
            isCompany ? (
              <>
                <Table.Widget.Info>
                  <CompanyNavigation />
                </Table.Widget.Info>

                <Table.Widget.Filters>
                  {tabs.map(tab => (
                    <FilterButton
                      key={String(tab.title)}
                      use={InternalLink}
                      to={String(tab.to)}
                      active={
                        !!matchPath(location.pathname, {
                          path: tab.path,
                          exact: true,
                        })
                      }
                    >
                      <HStack>{chain(tab.title, tab.quickSummary)}</HStack>
                    </FilterButton>
                  ))}
                </Table.Widget.Filters>
              </>
            ) : (
              <Table.Widget.Info>
                <Highlights variant="tabs">
                  {tabs.map(tab => (
                    <DataPointLink
                      to={String(tab.to)}
                      path={String(tab.path)}
                      key={String(tab.path)}
                    >
                      {tab.quickSummary ? (
                        <DataPoint.Value>{tab.quickSummary}</DataPoint.Value>
                      ) : (
                        <DataPointSkeleton.Value />
                      )}
                      <DataPoint.Label>{tab.title}</DataPoint.Label>
                    </DataPointLink>
                  ))}
                </Highlights>
              </Table.Widget.Info>
            )
          ) : null}

          {!isNewTable && wrapNavigation ? (
            <Flex alignItems="flex-start" mb="s-24" pl={isNewTable ? 's-16' : undefined}>
              {statsLoaded ? (
                <TabBarNavigation isSubtab tabs={tabs} />
              ) : (
                <Dots height={44} />
              )}
            </Flex>
          ) : null}
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.url}>
              {tab.component}
            </Route>
          ))}
        </Table.Widget>
      </Switch>
    </Flex>
  )
}

export default RiskTab
