import React from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const ManageInterviewingScorecardsAction = () => {
  const permissions = useSelector(selectPermissions)
  const canView = permissions.includes(PermissionTypes.ViewInterviewScorecardTemplate)
  if (!canView) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="Questionnaire"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATES)}
    >
      Manage interviewing scorecards
    </MoreBar.Action>
  )
}
