import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import Dashboard from './General'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'

type Props = {
  resignation?: EmployeeResignationInterface
  getResignation: () => Promise<void>
  approval?: ResignationApprovalInterface
  getApprovals: () => Promise<void>
}
export default connect(
  ({ resignation, getResignation, approval, getApprovals }: Props) => (
    <Form>
      <Dashboard
        resignation={resignation}
        getResignation={getResignation}
        approval={approval}
        getApprovals={getApprovals}
      />
    </Form>
  ),
)
