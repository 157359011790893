import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, MoreBar } from '@revolut/ui-kit'
import { LogoutDoor } from '@revolut/icons'

import { selectUser } from '@src/store/auth/selectors'
import { useGlobalSettings } from '@src/api/settings'
import { useResignationPermissions } from '@src/pages/Forms/EmployeeResignation/hooks'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { getEmployeeResignation } from '@src/api/employeeResignation'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeButtonProps } from '../types'

export const SubmitResignation = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const user = useSelector(selectUser)
  const {
    settings: { resignations_enabled },
  } = useGlobalSettings()
  const { canAdd } = useResignationPermissions()
  const canCreateResignation = resignations_enabled && canAdd
  const [resignation, setResignation] = useState<EmployeeResignationInterface>()

  const isThisUser = data?.id && data.id === user?.id
  const hasResignation = isThisUser && user?.resignation

  useEffect(() => {
    if (hasResignation) {
      const getResignation = async () => {
        const { data: employeeResignation } = await getEmployeeResignation(
          String(user.id),
          String(user.resignation),
        )
        setResignation(employeeResignation)
      }
      getResignation()
    }
  }, [hasResignation])

  if (!isThisUser || !canCreateResignation || user?.status?.id !== 'active') {
    return null
  }
  const isRegrettableUser = !!resignation?.is_regrettable
  const hasUnfinishedResignation = resignation?.employee_next_steps === null
  const shouldContinueUnfinishedForm = !isRegrettableUser && hasUnfinishedResignation

  let formUrl

  if (shouldContinueUnfinishedForm) {
    formUrl = getLocationDescriptor(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS, {
        employeeId: user.id,
        id: user?.resignation,
      }),
    )
  } else if (!hasResignation) {
    formUrl = getLocationDescriptor(
      pathToUrl(ROUTES.FORMS.EMPLOYEE_RESIGNATION.REASONS, {
        employeeId: user.id,
      }),
    )
  }

  if (!formUrl) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use={InternalLink}
        // @ts-expect-error object works fine here, but UI kit expects string
        to={formUrl}
        useIcon={LogoutDoor}
      >
        Submit Resignation
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      use={InternalLink}
      // @ts-expect-error object works fine here, but UI kit expects string
      to={formUrl}
      useIcon={LogoutDoor}
    >
      Submit Resignation
    </MoreBar.Action>
  )
}
