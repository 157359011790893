import { ImageProps } from '@revolut/ui-kit'

interface Props extends Pick<ImageProps, 'image'> {
  title: string
  description: string
  confirmLabel: string
  cancelLabel: string
}

export const generateScorecardsPopupProps: Props = {
  title: 'Review eligible employees',
  description: 'Scorecards for these employees will be generated',
  confirmLabel: 'Generate scorecards',
  cancelLabel: 'Cancel',
  image: {
    default: `https://assets.revolut.com/assets/3d-images-v2/3D262.png`,
    '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@2x.png',
    '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@3x.png',
  },
}
