import sortBy from 'lodash/sortBy'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { FinalGrade } from '@src/interfaces/performance'

export interface Grade {
  id: FinalGrade
  name: string
  value: number
}

interface GradesDistribution {
  grades: Grade[]
  isLoading: boolean
}

export const useGradesDistribution = (
  cycle: ReviewCyclesInterface,
): GradesDistribution => {
  const { grade_distribution: gradeDistribution } = cycle
  const { gradesMap, isLoading } = useGetReviewGradesMap()

  if (!gradeDistribution || !Object.keys(gradesMap).length) {
    return {
      grades: [],
      isLoading,
    }
  }

  const grades = orderById(
    (Object.entries(gradesMap) as [FinalGrade, string][]).reduce<Grade[]>(
      (distribution, [id, name]) => {
        if (Object.hasOwn(gradeDistribution, id)) {
          distribution.push({
            id,
            name,
            value: gradeDistribution[id],
          })
        }

        return distribution
      },
      [],
    ),
  )

  return {
    grades,
    isLoading,
  }
}

const idsOrder = [
  FinalGrade.Exceptional,
  FinalGrade.Strong,
  FinalGrade.AveragePlus,
  FinalGrade.AverageMinus,
  FinalGrade.Poor,
]
const orderById = (grades: Grade[]) => sortBy(grades, grade => idsOrder.indexOf(grade.id))
