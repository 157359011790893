import React from 'react'
import { useGetCandidateSettings } from '@src/api/settings'

const HideIfGmailDisabled: React.FC = ({ children }) => {
  const { data } = useGetCandidateSettings()

  if (!data?.enable_email_suite) {
    return null
  }

  return <>{children}</>
}

export default HideIfGmailDisabled
