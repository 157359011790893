import React from 'react'
import { useGetTickets } from '@src/api/tickets'
import { Group, ItemSkeleton, StatusWidget, Text, VStack } from '@revolut/ui-kit'
import { BugReportTicket } from '@components/HelpCenter/components/BugReportTicket'
import { TicketStatus, ticketStatuses } from '@src/interfaces/tickets'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'

const PageLayout: React.FC = ({ children }) => {
  return (
    <PageWrapper>
      <PageHeader title="All issues" backUrl={ROUTES.MAIN} />
      <PageBody>{children}</PageBody>
    </PageWrapper>
  )
}

export const Tickets = () => {
  const archivedStatus: TicketStatus = 'closed'
  const activeStatuses = ticketStatuses
    .filter(status => status !== archivedStatus)
    .map(status => ({ id: status, name: status }))

  const { data: activeTickets, isLoading: isLoadingActiveTickets } = useGetTickets([
    {
      filters: activeStatuses,
      columnName: 'current_status_name',
    },
  ])

  const archivedStatuses = ticketStatuses
    .filter(status => status === archivedStatus)
    .map(status => ({ id: status, name: status }))

  const { data: archivedTickets, isLoading: isLoadingArchivedTickets } = useGetTickets([
    {
      filters: archivedStatuses,
      columnName: 'current_status_name',
    },
  ])

  if (isLoadingActiveTickets || isLoadingArchivedTickets) {
    return (
      <PageLayout>
        <Group>
          <ItemSkeleton />
          <ItemSkeleton />
          <ItemSkeleton />
        </Group>
      </PageLayout>
    )
  }

  if (
    (!activeTickets && !archivedTickets) ||
    (activeTickets?.count === 0 && archivedTickets?.count === 0)
  ) {
    return (
      <PageLayout>
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D023.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D023@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D023@3x.png',
            }}
          />
          <StatusWidget.Title>Your bug requests will appear here</StatusWidget.Title>
        </StatusWidget>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      {activeTickets && activeTickets?.count > 0 ? (
        <VStack space="s-8">
          <Text variant="primary" use="div" pb="s-8">
            Active issues
          </Text>
          <Group>
            {activeTickets.results.map(ticket => (
              <BugReportTicket ticket={ticket} key={ticket.id} />
            ))}
          </Group>
        </VStack>
      ) : null}
      {archivedTickets && archivedTickets.count > 0 ? (
        <VStack space="s-8" mt="s-16">
          <Text variant="primary" use="div" pb="s-8">
            Archived
          </Text>
          <Group>
            {archivedTickets.results.map(ticket => (
              <BugReportTicket ticket={ticket} key={ticket.id} iconColor="grey-tone-50" />
            ))}
          </Group>
        </VStack>
      ) : null}
    </PageLayout>
  )
}
