import React, { useRef, useState } from 'react'
import {
  Box,
  chain,
  Color,
  Input,
  MoreBar,
  Text,
  TextWidget,
  Group,
} from '@revolut/ui-kit'
import { connect } from 'lape'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OffboardingScreenProps, useOffboardingParams } from './common'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import {
  approveAccessRetentionRequest,
  getAccessRetentionRequestCommentsAPI,
  offboardingAccessRetentionRequests,
  rejectAccessRetentionRequest,
  useAccessRetentionApprovals,
} from '@src/api/offboarding'
import { getStatusThemeColor } from '@src/components/CommonSC/General'
import ApprovalFlow from '@src/features/ApprovalFlow/ApprovalFlow'
import SettingsButtons, { ApproveButton } from '@src/features/SettingsButtons'
import { handleError } from '@src/api'
import { CrossSmall } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import CommentsSection from '@src/features/Comments/CommentsSection'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { OffboardingAccessRetentionInterface } from '@src/interfaces/offboarding'
import { EntityPermissions } from '@src/store/auth/types'

const AccessRetention = ({ refreshDashboard }: OffboardingScreenProps) => {
  const [isApprovePending, setIsApprovePending] = useState<boolean>(false)
  const [isRejectPending, setIsRejectPending] = useState<boolean>(false)
  const [rejectionComment, setRejectionComment] = useState('')
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false)

  const { values, reset } = useLapeContext<OffboardingAccessRetentionInterface>()

  const params = useOffboardingParams()

  const setCommentsSidebarOpen = useRef((_: boolean) => {})

  const backUrl = pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, params)

  const commentsApi = getAccessRetentionRequestCommentsAPI(params.employeeId)
  const getComments = commentsApi.useGetComments(false)

  const {
    data: approvalSteps,
    isRefetching: isApprovalLoading,
    refetch: refetchApproval,
  } = useAccessRetentionApprovals(params.employeeId)

  const onApprove = async () => {
    const response = await approveAccessRetentionRequest(params.employeeId)
    reset(response.data)
    refreshDashboard()
  }

  const onReject = async () => {
    setIsRejectPending(true)

    try {
      const response = await rejectAccessRetentionRequest(
        params.employeeId,
        rejectionComment,
      )
      reset(response.data)
      refreshDashboard()
    } finally {
      setRejectionComment('')
      setRejectDialogOpen(false)
      refetchApproval()
      setIsRejectPending(false)
      getComments.refetch()
    }
  }

  const isPending = values.approval_status.id === 'pending'
  const canApprove =
    values.field_options?.actions?.includes(EntityPermissions.Approve) && isPending
  const canReject =
    values.field_options?.actions?.includes(EntityPermissions.Reject) && isPending
  const canPerformAnyAction = canApprove || canReject

  const anyPending = isApprovePending || isRejectPending

  return (
    <>
      <PageWrapper>
        <PageHeader
          title={chain(
            'Access retention',
            <Text color={getStatusThemeColor(values.approval_status.id)}>
              {values.approval_status.name}
            </Text>,
          )}
          backUrl={backUrl}
          subtitle={<UserWithAvatar {...values.employee} asText />}
        />

        <PageBody>
          {canPerformAnyAction ? (
            <>
              <SettingsButtons mb="s-16">
                <ApproveButton
                  submit={onApprove}
                  onBeforeSubmit={() => setIsApprovePending(true)}
                  onAfterSubmit={() => {
                    setIsApprovePending(false)
                    refetchApproval()
                  }}
                  onSubmitFailed={handleError}
                  statusFieldName="approval_status"
                  isVisible={canApprove}
                />
                {canReject && (
                  <MoreBar.Action
                    onClick={() => setRejectDialogOpen(true)}
                    useIcon={CrossSmall}
                    variant="negative"
                    pending={isRejectPending}
                    disabled={anyPending}
                  >
                    Reject
                  </MoreBar.Action>
                )}
              </SettingsButtons>
            </>
          ) : null}

          {approvalSteps === undefined || approvalSteps?.length ? (
            <ApprovalFlow
              isLoading={isApprovalLoading || isApprovePending}
              steps={approvalSteps || null}
              onViewRejectionReasonClick={() => {
                setCommentsSidebarOpen.current(true)
              }}
            />
          ) : null}

          <Box mt="s-16">
            <TextWidget>
              <TextWidget.Title>
                System access retention was requested for this employee
              </TextWidget.Title>
              <TextWidget.Content>
                This request should be approved only if there is a severe business need
                for the employee to retain system access during gardening leave. For more
                information reach offboarding HR manager or check system access policy.
              </TextWidget.Content>
            </TextWidget>
          </Box>

          <Box mt="s-16">
            <FormPreview title="Details" data={values}>
              <Group>
                <FormPreview.Item title="Requested on" field="requested_on" type="date" />
                <FormPreview.Item
                  title="Requested by"
                  field="requested_by"
                  type="employee"
                />
              </Group>
            </FormPreview>
          </Box>

          <Box mt="s-16">
            <CommentsSection
              api={commentsApi}
              setSidebarOpen={setCommentsSidebarOpen}
              disableTodolistFeature
            />
          </Box>
        </PageBody>
      </PageWrapper>

      <ConfirmationDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onConfirm={onReject}
        loading={isRejectPending}
        onReject={() => setRejectDialogOpen(false)}
        label="Please enter rejection reason"
        body={
          <Box>
            <Text mb="s-16" display="inline-block" color={Color.GREY_TONE_50}>
              Kindly inform why you are rejecting this request
            </Text>
            <Input
              label="Add reason here"
              value={rejectionComment}
              onChange={e => setRejectionComment(e.currentTarget.value)}
            />
          </Box>
        }
        yesMessage="Reject"
        noMessage="Go back"
      />
    </>
  )
}

export default connect((props: OffboardingScreenProps) => (
  <Form
    api={offboardingAccessRetentionRequests}
    forceParams={{ id: String(props.data.employee.id) }}
  >
    <AccessRetention {...props} />
  </Form>
))
