import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { Statuses } from '@src/interfaces'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import DOMPurify from 'dompurify'
import Table from '@components/TableV2/Table'
import React from 'react'
import { ColorTagVariant } from '@components/ColorTag/ColorTag'

export const interviewScorecardTemplateNameColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.interview_scorecard_templates,
    title: 'Name',
  }

export const interviewScorecardTemplateObjectiveColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'interview_objective',
    dataPoint: 'interview_objective',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Interview Objective',
    insert: ({ data }) =>
      data.interview_objective
        ? DOMPurify.sanitize(data.interview_objective, {
            ALLOWED_TAGS: [],
          })
        : '-',
  }

const getTemplateStatusVariant = (status: Statuses): ColorTagVariant => {
  switch (status) {
    case Statuses.completed:
      return 'success'
    case Statuses.draft:
      return 'neutral'
    default:
      return 'outlined'
  }
}

export const interviewScorecardTemplateStatusColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status',
    dataPoint: 'status',
    filterKey: 'status',
    sortKey: 'status',
    selectorsKey: selectorKeys.interview_scorecard_template_statuses,
    title: 'Status',
    insert: ({ data }) =>
      data.status ? (
        <Table.StatusCell
          status={data.status}
          variant={getTemplateStatusVariant(data.status)}
        />
      ) : (
        '-'
      ),
  }

export const interviewScorecardTemplateActionColumn: ColumnInterface<InterviewScorecardTemplateInterface> =
  {
    type: CellTypes.insert,
    dataPoint: 'action',
    idPoint: 'action',
    sortKey: null,
    notHoverable: true,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
  }
