import React, { createContext, useContext, useMemo, useState } from 'react'
import { Fixed, Token } from '@revolut/ui-kit'

type BannerContextValue = {
  show: (key: string) => void
  hide: (key: string) => void
  visibleBanner: string | null
  visible: boolean
}

const BannerContext = createContext<BannerContextValue | null>(null)

export const useBanner = () => {
  const context = useContext(BannerContext)

  if (context == null) {
    throw new Error(`useBanner must be used within BannerProvider`)
  }

  return context
}

export const BannerProvider: React.FC = ({ children }) => {
  const [bannerState, setBannerState] = useState<string[]>([])

  const show = (key: string) => {
    setBannerState(prev => [key, ...prev.filter(k => k !== key)])
  }

  const hide = (key: string) => {
    setBannerState(prev => prev.filter(k => k !== key))
  }

  const value = useMemo(
    () => ({
      show,
      hide,
      visibleBanner: bannerState[0] || null,
      visible: bannerState.length > 0,
    }),
    [bannerState, hide, show],
  )

  return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
}

interface BannerRendererProps {
  bannerKey: string
  backgroundColor?: string
  color?: string
  justifyContent?: 'center' | 'flex-start' | 'flex-end'
}

export const BannerRenderer: React.FC<BannerRendererProps> = ({
  bannerKey,
  backgroundColor = Token.color.accent,
  color = Token.color.white,
  justifyContent = 'center',
  children,
}) => {
  const bannerContext = useBanner()

  if (bannerContext.visibleBanner === bannerKey) {
    return (
      <Fixed
        top={0}
        left={0}
        right={0}
        height={44}
        bg={backgroundColor}
        color={color}
        display="flex"
        style={{ alignItems: 'center', justifyContent }}
        pr="s-40"
        zIndex={1}
      >
        {children}
      </Fixed>
    )
  }

  return null
}
