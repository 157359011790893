import React from 'react'
import { employeeAboutRequests } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import AboutForm, { Props } from './General'

const About = (props: Props) => {
  return (
    <Form api={employeeAboutRequests}>
      <AboutForm {...props} />
    </Form>
  )
}

export default About
