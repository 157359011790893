import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import isUndefined from 'lodash/isUndefined'

import { defaultTheme } from '@src/styles/theme'
import { Modal } from '../Modal'

const Container = styled.div<{
  top?: number
  left?: number
  right?: number
  width?: number
}>`
  position: absolute;
  box-shadow: 0px 6px 20px rgba(25, 28, 31, 0.1), 0px 0px 8px rgba(25, 28, 31, 0.1);
  border-radius: 4px;
  background-color: #fff;
  z-index: ${defaultTheme.zIndex.popup};
  min-width: 150px;
  animation: show ease 200ms 1 forwards;
  transform: translateY(10px);
  top: ${props => (isUndefined(props.top) ? 'unset' : `${props.top}px`)};
  left: ${props => (isUndefined(props.left) ? 'unset' : `${props.left}px`)};
  right: ${props => (isUndefined(props.right) ? 'unset' : `${props.right}px`)};
  width: ${props => (isUndefined(props.width) ? 'unset' : `${props.width}px`)};

  @keyframes show {
    0% {
      transform: translateY(10px);
    }

    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const Curtain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

interface TooltipInterface {
  className?: string
  /** List of items for menu */
  children: React.ReactNode
  /** Callback for when you click outside */
  onClose: () => void
  anchor: Element
  align?: 'right' | 'left'
}

interface PositionInterface {
  top: number
  left: number
  width?: number
  height: number
}

function Menu({ className, children, onClose, anchor, align }: TooltipInterface) {
  const container = useRef<HTMLDivElement>(null)

  const anchorBox = anchor.getBoundingClientRect()
  const [position, setPosition] = useState<PositionInterface>({
    top: anchorBox.top + anchorBox.height + 8 + window.scrollY,
    left: anchorBox.left + anchorBox.width + window.scrollX,
    height: anchorBox.height,
  })

  useEffect(() => {
    const contentRect = container.current?.getBoundingClientRect()

    if (contentRect) {
      const result: PositionInterface = {
        ...position,
        left: position.left - (align === 'left' ? anchorBox.width : contentRect.width),
        width: contentRect.width,
      }

      setPosition(result)
    }
  }, [])

  return (
    <Modal>
      <Curtain onClick={onClose}>
        <Container
          ref={container}
          className={className}
          {...position}
          onClick={e => e.stopPropagation()}
        >
          {children}
        </Container>
      </Curtain>
    </Modal>
  )
}

export default Menu
