import React, { PropsWithChildren } from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { probationTemplateRequests } from '@src/api/probationTemplate'
import ProbationTemplateGeneral from './General'

export const ProbationTemplateForm = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Form api={probationTemplateRequests}>
      {children || (
        <PageWrapper>
          <ProbationTemplateGeneral />
        </PageWrapper>
      )}
    </Form>
  )
}

export default connect(({ children }: PropsWithChildren<{}>) => {
  return <ProbationTemplateForm>{children}</ProbationTemplateForm>
})
