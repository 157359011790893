import { Side, Spacer } from '@revolut/ui-kit'
import { roadmapsRequestsNew } from '@src/api/roadmaps'
import SideBar from '@src/components/SideBar/SideBar'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import {
  RoadmapForm,
  RoadmapProgressForm,
} from '@src/pages/Forms/RoadmapForm/RoadmapForm'
import React from 'react'

export const RoadmapSidebarForm = ({
  onClose,
  roadmapId,
  onSuccess,
}: {
  onClose: () => void
  onSuccess: () => void
  roadmapId?: number
}) => {
  if (!roadmapId) {
    return null
  }

  return (
    <SideBar useLayout onClose={onClose} title="Edit roadmap" isOpen>
      <Form
        forceParams={{ id: String(roadmapId) }}
        api={roadmapsRequestsNew}
        disableLocalStorageCaching
        useLocalStorageCaching={false}
      >
        <RoadmapForm disabledFields={{ owner: 'owner is prefilled' }} />
        <Spacer height="s-16" />
        <RoadmapProgressForm />
        <Side.Actions>
          <NewSaveButtonWithPopup onAfterSubmit={onSuccess} useValidator />
        </Side.Actions>
      </Form>
    </SideBar>
  )
}
