import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { FeatureFlags } from '@src/store/auth/types'

export const checkIfPromotionFeatureEnabled = (
  featureFlags: FeatureFlags[],
  settingEnabled: boolean | undefined,
): boolean => {
  if (!settingEnabled) {
    return false
  }
  // In the first 5 minutes after enabling the feature, feature flags still contain the old value,
  // so I cash on the client
  const cashedValue = workspaceLocalStorage.getItem(FeatureFlags.PromotionNomination)
  if (cashedValue) {
    return cashedValue === 'true'
  }
  return featureFlags.includes(FeatureFlags.PromotionNomination)
}
