import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { successNotification } from '@src/actions/NotificationActions'
import React, { useState } from 'react'
import { DraftTooltip, StyledDraftBtn } from '@src/pages/Forms/FormButtons'
import { RoleInterface } from '@src/interfaces/roles'
import { Button } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { Statuses } from '@src/interfaces'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

interface RoleSaveDraftButtonProps {
  title: string
  isNew?: boolean
  pathAfterSave: string
  pathInLocalStorage: string
  notification?: {
    path?: string
    createMsg: string
    updateMsg: string
  }
}

const RoleSaveDraftButton = ({
  title,
  isNew,
  pathInLocalStorage,
  pathAfterSave,
  notification,
}: RoleSaveDraftButtonProps) => {
  const { values, submit } = useLapeContext<RoleInterface>()
  const formValidator = useFormValidator()
  const [pending, setPending] = useState(false)
  const isExistingData = !!values.id

  if (values.id && values.status !== Statuses.draft) {
    return null
  }

  const onSaveDraft = () => {
    values.status = Statuses.draft
    setPending(true)

    return submit()
      .then(data => {
        workspaceLocalStorage.removeItem(pathInLocalStorage)
        navigateReplace(pathToUrl(pathAfterSave, { id: data.id }))
        if (notification) {
          successNotification(
            isExistingData ? notification.updateMsg : notification.createMsg,
          )
        }
      })
      .finally(() => {
        setPending(false)
      })
  }

  const onSubmit = formValidator?.validate
    ? formValidator.validate(onSaveDraft)
    : onSaveDraft

  const btnDisabled = !values.name

  if (isNew) {
    return (
      <Button pending={pending} variant="secondary" elevated onClick={onSubmit}>
        Save to Drafts
      </Button>
    )
  }

  const button = (
    <StyledDraftBtn type="button" onClick={onSubmit} disabled={btnDisabled}>
      Save to Drafts
    </StyledDraftBtn>
  )

  return btnDisabled ? (
    <DraftTooltip
      title={`${title} name is required to save to drafts.`}
      placement="bottom"
    >
      {button}
    </DraftTooltip>
  ) : (
    button
  )
}

export default connect(RoleSaveDraftButton)
