import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormattedMessage } from 'react-intl'

export const InterviewAvailabilityAction = () => {
  return (
    <MoreBar.Action
      use={InternalLink}
      useIcon="CalendarWeek"
      to={pathToUrl(ROUTES.FORMS.INTERVIEW_AVAILABILITY)}
    >
      <FormattedMessage
        id="recruitment.interviewAvailability.actions.interviewAvailability"
        defaultMessage="Interview availability"
      />
    </MoreBar.Action>
  )
}
