import React, { useState } from 'react'
import * as Sentry from '@sentry/react'

import { ExportTypes } from '@src/constants/export'
import { saveFileByBlob } from '@src/utils'
import { ExportRequest } from '@src/interfaces'
import {
  Button,
  MoreBar,
  StatusPopup,
  Tooltip,
  TooltipState,
  useStatusPopup,
  useTooltip,
} from '@revolut/ui-kit'
import { Download } from '@revolut/icons'

export interface ExportMenuProps {
  fileName: string
  filterQuery?: Object
  request?: ExportRequest
  supportedTypes?: ExportTypes[]
  isDisabled?: boolean
  tooltip?: string
}

const ExportMenu = ({
  filterQuery,
  request,
  fileName,
  supportedTypes,
  isDisabled,
  tooltip,
}: ExportMenuProps) => {
  const [loadingCSV, setLoadingCSV] = useState(false)
  const [loadingXLSX, setLoadingXLSX] = useState(false)

  const csvTooltip = useTooltip()
  const xlsxTooltip = useTooltip()
  const statusPopup = useStatusPopup()

  if (!request) {
    return null
  }

  const renderTooltip = (tooltipProps: TooltipState<HTMLButtonElement>) => {
    return (
      <Tooltip
        {...tooltipProps.getTargetProps()}
        style={{ textAlign: 'center' }}
        placement="bottom-start"
        width={200}
      >
        {tooltip}
      </Tooltip>
    )
  }

  const onClickExportItem = async (exportType?: ExportTypes) => {
    if (loadingCSV || loadingXLSX || isDisabled) {
      return
    }
    const setLoading = exportType === ExportTypes.csv ? setLoadingCSV : setLoadingXLSX

    setLoading(true)
    let userErrorDescription
    const unknowErrorMessage =
      'Something went wrong. Please, refresh the page or try again later.'

    try {
      const { data, headers } = await request(exportType as ExportTypes, filterQuery)
      saveFileByBlob(data, `${fileName}.${exportType}`, headers['content-type'])
    } catch (requestErr) {
      try {
        const errDataString = await requestErr.response.data.text()
        const errDataObject = JSON.parse(errDataString)
        const errString = errDataObject.detail

        if (errString) {
          userErrorDescription = errString
        } else {
          Sentry.captureException(requestErr)
          userErrorDescription = unknowErrorMessage
        }
      } catch (unknownErr) {
        Sentry.captureException(unknownErr)
        userErrorDescription = unknowErrorMessage
      }
    } finally {
      setLoading(false)

      if (userErrorDescription) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Could not export file</StatusPopup.Title>
            <StatusPopup.Description>{userErrorDescription}</StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide}>Close</Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
        userErrorDescription = undefined
      } else {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>File was successfully exported</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
  }

  return (
    <>
      {(!supportedTypes || supportedTypes.includes(ExportTypes.csv)) && (
        <MoreBar.Action
          useIcon={Download}
          onClick={() => onClickExportItem(ExportTypes.csv)}
          aria-disabled={loadingCSV || isDisabled}
          pending={loadingCSV}
          {...csvTooltip.getAnchorProps()}
        >
          Download CSV
          {tooltip && renderTooltip(csvTooltip)}
        </MoreBar.Action>
      )}
      {(!supportedTypes || supportedTypes.includes(ExportTypes.xlsx)) && (
        <MoreBar.Action
          useIcon={Download}
          onClick={() => onClickExportItem(ExportTypes.xlsx)}
          aria-disabled={loadingXLSX || isDisabled}
          pending={loadingXLSX}
          {...xlsxTooltip.getAnchorProps()}
        >
          Download XLSX
          {tooltip && renderTooltip(xlsxTooltip)}
        </MoreBar.Action>
      )}
    </>
  )
}

export default ExportMenu
