import { Tag, Text, Flex } from '@revolut/ui-kit'
import React, { ReactNode } from 'react'

export const RecommendedLabel = ({
  label,
  isRecommended = true,
}: {
  label: ReactNode
  isRecommended?: boolean
}) => (
  <Flex justifyContent="space-between">
    <Text>{label}</Text>
    {isRecommended && <Recommended />}
  </Flex>
)

export const Recommended = () => {
  return (
    <Tag variant="outlined" useIcon="Cleaning">
      Recommended
    </Tag>
  )
}
