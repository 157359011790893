import React from 'react'
import { Flex, MoreBar } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import { PageBody } from '@components/Page/PageBody'
import EmployeeEmailThread from '@src/features/EmailThread/EmailThread'
import CancelEmail from '@src/pages/Forms/SendEmployeeEmail/View/CancelEmail'
import { useEmployeeEmailThread } from '@src/api/employeeEmails'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const View = () => {
  const params = useParams<{ employeeId?: string; threadId?: string; emailId?: string }>()

  const { data: thread } = useEmployeeEmailThread(params.employeeId, params.threadId)

  if (!thread || !params.employeeId) {
    return null
  }

  return (
    <PageBody>
      {thread.emails[0].status.id === 'scheduled' && (
        <Flex mb="s-16">
          <MoreBar>
            <CancelEmail employeeId={+params.employeeId} thread={thread} />
          </MoreBar>
        </Flex>
      )}
      <EmployeeEmailThread
        emails={thread.emails}
        replyUrl={pathToUrl(ROUTES.FORMS.SEND_EMPLOYEE_EMAIL.EMPLOYEE, {
          threadId: params.threadId,
          employeeId: params.employeeId,
          emailId: params.emailId,
        })}
      />
    </PageBody>
  )
}

export default View
