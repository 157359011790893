import {
  ApprovalFlowStep,
  ApprovalStatuses,
  LegacyApprovalStep,
} from '@src/interfaces/approvalFlow'

export const parseLegacyApprovalSteps = (
  steps: LegacyApprovalStep[],
): ApprovalFlowStep[] => {
  return steps.map(step => {
    const status =
      !step.actual_approver && step.approval_status !== ApprovalStatuses.Pending
        ? ApprovalStatuses.Skipped
        : step.approval_status

    return {
      approval_status: status,
      approval_step: step.approval_step || '',
      approver: step.actual_approver,
      expected_approver: step.expected_approver,
      approval_date_time: step.approval_datetime,
      rejection_comment: step.rejection_comment,
    }
  })
}
