import { IconName } from '@revolut/ui-kit'
import { AudienceIdType } from '@src/components/AddAudienceSidebar/interfaces'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { notReachable } from '@src/utils/notReachable'

export const mapAudienceIcon = (id: AudienceIdType): IconName => {
  switch (id) {
    case 'department':
      return 'Bank'
    case 'seniority':
      return 'Trophy'
    case 'role':
      return 'Profile'
    case 'specialisation':
      return 'IndustrialGear'
    case 'function':
      return 'RepairTool'
    case 'team':
    case 'dynamic_group':
    default:
      return 'People'
  }
}

export const isStepDone = (
  step: EngagementEditStep,
  values?: Partial<EngagementSurveyInterface>,
) => {
  switch (step) {
    case 'audience':
      return !!values?.audiences?.length
    case 'basic':
      return !!(values?.title && values?.owner && values?.sent_every?.id)
    case 'notifications':
      return !!(values?.display_name && values?.description && values?.campaign)
    case 'questions':
      return !!values?.survey_questions?.length
    case 'review':
      return !!(
        values?.title &&
        values?.owner &&
        values?.sent_every?.id &&
        values?.display_name &&
        values?.description &&
        values?.campaign &&
        values?.survey_questions?.length
      )
    default:
      return false
  }
}

// It's needed to prevent creating recurring survey that will run longer than the round as the BE doesn't support
// multiple rounds running at the same time
export const getMaxDeadlineDays = (
  sent_every: EngagementSurveyInterface['sent_every'],
) => {
  if (!sent_every?.id) {
    return undefined
  }
  switch (sent_every.id) {
    case 'manual':
      return undefined
    case 'month':
      return 30
    case 'quarter':
      return 90
    case 'two_weeks':
      return 14
    case 'week':
      return 7
    case 'year':
      return 365
    default:
      return notReachable(sent_every.id)
  }
}
