export enum Tabs {
  Details = 'Pay group details',
  Schedule = 'Pay cycle schedule',
}

export enum DetailsTabInputName {
  Name = 'name',
  Country = 'country',
  Entity = 'company_entity',
  Currency = 'country.national_currency',
  Owner = 'owner',
  Provider = 'payroll_provider',
}

export const detailsTabInputsNames = [
  DetailsTabInputName.Name,
  DetailsTabInputName.Country,
  DetailsTabInputName.Entity,
  DetailsTabInputName.Currency,
  DetailsTabInputName.Owner,
  DetailsTabInputName.Provider,
]

// TODO: remove forced options after BE fix
export const PaymentCategoriesOptions = [
  {
    label: 'Gross pay',
    value: { id: 'gross_pay', name: 'Gross pay' },
  },
  {
    label: 'Net pay',
    value: { id: 'net_pay', name: 'Net pay' },
  },
  {
    label: 'Deduction',
    value: { id: 'deduction', name: 'Deduction' },
  },
  {
    label: 'Employer contribution',
    value: { id: 'employer_contribution', name: 'Employer contribution' },
  },
]
