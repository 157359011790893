import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsDeadline,
  notificationsPromotionStageColumn,
  notificationsPriorityColumn,
  notificationsTextColumn,
  notificationsEmployeeColumn,
} from '@src/constants/columns/notifications'
import { notificationsPromotion } from '@src/api/notifications'

const Promotion = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationsEmployeeColumn,
        width: 200,
      },
      {
        ...notificationsTextColumn,
        width: 300,
      },
      {
        ...notificationsPromotionStageColumn,
        width: 150,
      },
      {
        ...notificationsDeadline,
        width: 150,
      },
      {
        ...notificationsPriorityColumn,
        width: 150,
      },
      {
        ...notificationsActionColumn,
        width: 140,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsPromotion(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default Promotion
