import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { candidateBulkEmailsRequests } from '@src/api/hiringProcess'
import { CandidateBulkEmailsInterface } from '@src/interfaces/hiringProccess'
import { ColumnCellInterface } from '@src/interfaces/data'
import {
  bulkActionsEmailBodyColumn,
  bulkActionsEmailSubjectColumn,
  bulkActionsEmailTemplateColumn,
} from '@src/constants/columns/bulkActions'
import DOMPurify from 'dompurify'

const columns: ColumnCellInterface<CandidateBulkEmailsInterface>[] = [
  {
    ...bulkActionsEmailSubjectColumn,
    width: 140,
  },
  {
    ...bulkActionsEmailBodyColumn,
    width: 140,
    insert: ({ data }) =>
      data.email_body
        ? DOMPurify.sanitize(data.email_body, {
            ALLOWED_TAGS: [],
          })
        : '-',
  },
  {
    ...bulkActionsEmailTemplateColumn,
    width: 140,
  },
]

const CandidateBulkEmails = () => {
  return (
    <BulkActions<CandidateBulkEmailsInterface>
      tableRequest={candidateBulkEmailsRequests}
      columns={columns}
      dataType="Candidate email bulk actions"
    />
  )
}

export default CandidateBulkEmails
