import React, { useRef } from 'react'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Card } from '@src/pages/Forms/EmployeePerformanceLayout/Card'
import { Palette } from '@revolut/icons'
import {
  CardContentTypes,
  cultureOptionsNewGrades,
  getFinalGradeTitleFromRating,
  getRoundedRating,
  ratingOptions,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { get } from 'lodash'
import { SkillsView } from '@src/pages/Forms/EmployeePerformanceViewLayout/Sections/SkillsView'
import {
  getGradesWithExpectations,
  getViewGradesWithExpectations,
} from '@src/pages/Forms/EmployeePerformanceLayout/Cards/utils'
import { getHideFinalRating } from '@src/pages/Forms/EmployeePerformanceViewLayout/utils'

export const SkillsCardView = connect(() => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const ref = useRef<HTMLDivElement>(null)

  const canViewSkills =
    !!values?.summary?.functional_skills || !!values?.summary?.manager_values

  if (!canViewSkills) {
    return null
  }

  const hasManagerValues = !!values.summary?.manager_values
  const gradesOptions = getGradesWithExpectations(ratingOptions, null)

  const managerValuesCards = values.summary?.manager_values?.cards || []
  const managerValuesFields =
    managerValuesCards.map((card, ind) => ({
      field: `summary.manager_values.cards.${ind}`,
      title: card.name,
      grades: getViewGradesWithExpectations(cultureOptionsNewGrades),
      gradeRecommendation: null,
      cardIndex: ind,
      cardJustification: null,
    })) || []
  const functionalSkillsCards = values.summary?.functional_skills?.cards || []
  const functionalSkillsFields = functionalSkillsCards.map((card, ind) => ({
    field: `summary.functional_skills.cards.${ind}`,
    title: card.name,
    grades: gradesOptions,
    gradeRecommendation: card.rating_expectation || null,
    cardIndex: ind,
    cardJustification: null,
  }))
  const skillsFields = hasManagerValues ? managerValuesFields : functionalSkillsFields

  const hideFinalRating = getHideFinalRating(values)
  const finalRating = hideFinalRating
    ? undefined
    : getFinalGradeTitleFromRating(
        values.grade_label_mapping,
        hasManagerValues
          ? values.summary?.manager_values?.rating
          : values.summary?.functional_skills?.rating,
      )

  return (
    <Card
      data={values}
      renderExpandedContent={expContentField => (
        <SkillsView selectedField={expContentField} skillsMissingJustification={null} />
      )}
      type={CardContentTypes.SKILLS}
      title="Skills"
      icon={Palette}
      fields={skillsFields}
      isGradeSelectedRule={(field, grade) => {
        const ratingValue = get(values, field)?.rating
        if (!ratingValue) {
          return false
        }
        return hasManagerValues
          ? ratingValue === grade.key
          : getRoundedRating(ratingValue) === grade.key
      }}
      headerRef={ref}
      justification={
        values.summary?.manager_values?.skipped_section_justifications ||
        values.summary?.functional_skills?.skipped_section_justifications
      }
      finalRating={finalRating}
      isViewMode
    />
  )
})
