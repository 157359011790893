import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { getStatusColor } from '@src/components/CommonSC/General'
import {
  Group,
  Text,
  Color,
  VStack,
  DetailsCell,
  HStack,
  Widget,
  MoreBar,
  TextButton,
} from '@revolut/ui-kit'
import { Archive, ChevronRight, Pencil, Unarchive } from '@revolut/icons'
import Icon from '@src/components/Icon/Icon'
import Stat from '@src/components/Stat/Stat'
import { slackChannelNameToUrl } from '@src/features/CommunicationGroups/helpers'
import { pathToUrl } from '@src/utils/router'
import { CopyButton } from '@src/features/SettingsButtons'
import {
  archiveCommunicationGroup,
  unarchiveCommunicationGroup,
} from '@src/api/communicationGroups'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetCommunicationSettings } from '@src/api/settings'

export const CommunicationGroup = () => {
  const { values, reset } = useLapeContext<CommunicationGroupInterface>()
  const permissions = useSelector(selectPermissions)

  const [isArchiveActionPending, setIsArchiveActionPending] = useState<boolean>(false)
  const [isUnarchiveActionPending, setIsUnarchiveActionPending] = useState<boolean>(false)

  const { data: communicationSettings } = useGetCommunicationSettings()
  const enableSlackChannel =
    !!communicationSettings?.enable_automatic_slack_channel_and_group_creation

  const canEdit = permissions?.includes(PermissionTypes.AddCommunicationGroup)
  if (!values.id) {
    return null
  }
  return (
    <>
      <PageHeader
        title={values.name}
        subtitle="Communication group"
        backUrl={ROUTES.APPS.COMMUNICATION.GROUPS}
      />
      <PageBody>
        <Widget p="s-16">
          <VStack space="s-16">
            <Stat label="Members" val={values.members_count} />
            {canEdit && (
              <MoreBar>
                <MoreBar.Action
                  useIcon={Pencil}
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT, {
                    id: values.id,
                  })}
                >
                  Edit
                </MoreBar.Action>
                <CopyButton
                  afterSubmitUrl={pathToUrl(ROUTES.FORMS.COMMUNICATION_GROUPS.EDIT, {})}
                />
                {values.status.id === 'archived' ? (
                  <MoreBar.Action
                    useIcon={Unarchive}
                    pending={isArchiveActionPending}
                    onClick={async () => {
                      setIsArchiveActionPending(true)
                      try {
                        const { data } = await unarchiveCommunicationGroup(values.id)
                        reset(data)
                      } finally {
                        setIsArchiveActionPending(false)
                      }
                    }}
                  >
                    Unarchive
                  </MoreBar.Action>
                ) : (
                  <MoreBar.Action
                    useIcon={Archive}
                    variant="negative"
                    pending={isUnarchiveActionPending}
                    onClick={async () => {
                      setIsUnarchiveActionPending(true)
                      try {
                        const { data } = await archiveCommunicationGroup(values.id)
                        reset(data)
                      } finally {
                        setIsUnarchiveActionPending(false)
                      }
                    }}
                  >
                    Archive
                  </MoreBar.Action>
                )}
              </MoreBar>
            )}
          </VStack>
        </Widget>
        <VStack space="s-16">
          <FormPreview<CommunicationGroupInterface> data={values} title="Details">
            <Group>
              <FormPreview.Item title="Name" field="name" />
              <FormPreview.Item title="Owner" field="owner" type="employee" />
              <FormPreview.Item<CommunicationGroupInterface>
                title="Status"
                field="status.name"
                color={({ status }) => getStatusColor(status.id)}
              />
            </Group>
          </FormPreview>
          <FormPreview<CommunicationGroupInterface> data={values} title="Audience">
            <Group>
              <FormPreview.Item title="Type" field="audience_type.name" />
              {values.audience_type.id === 'team' && (
                <FormPreview.Item title={values.audience_type.name} field="team.name" />
              )}
              {values.audience_type.id === 'function' && (
                <FormPreview.Item
                  title={values.audience_type.name}
                  field="function.name"
                />
              )}
              {values.audience_type.id === 'department' && (
                <FormPreview.Item
                  title={values.audience_type.name}
                  field="department.name"
                />
              )}
              {values.audience_type.id === 'dynamic_group' && (
                <FormPreview.Item<CommunicationGroupInterface>
                  title="Dynamic groups"
                  insert={({ dynamic_groups }) => {
                    if (!dynamic_groups.length) {
                      return '-'
                    }
                    return dynamic_groups.map(({ id, name }, idx) => (
                      <TextButton
                        key={id}
                        use={InternalLink}
                        to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, { id })}
                        ml="s-4"
                      >
                        {name}
                        {dynamic_groups.length > 1 &&
                          idx < dynamic_groups.length - 1 &&
                          ','}
                      </TextButton>
                    ))
                  }}
                />
              )}
              <FormPreview.Details title="Description" field="description" />
            </Group>
          </FormPreview>
          {enableSlackChannel && (
            <FormPreview<CommunicationGroupInterface> data={values} title="Channels">
              <VStack space="s-8">
                {values.slack_channel_name ? (
                  <DetailsCell
                    use="button"
                    onClick={() => {
                      if (window) {
                        const tab = window.open(
                          slackChannelNameToUrl(values.slack_channel_name),
                          '_blank',
                        )
                        tab?.focus()
                      }
                    }}
                  >
                    <DetailsCell.Title>
                      <HStack align="center" space="s-12">
                        <Icon type="SlackMono" size="small" color={Color.BLUE} />
                        <Text variant="secondary" color={Color.FOREGROUND}>
                          Slack
                        </Text>
                      </HStack>
                    </DetailsCell.Title>
                    <DetailsCell.Content>
                      <HStack>
                        {values.slack_channel_name}
                        <ChevronRight color={Color.GREY_TONE_50} />
                      </HStack>
                    </DetailsCell.Content>
                  </DetailsCell>
                ) : (
                  <DetailsCell use="button" disabled>
                    <DetailsCell.Title>
                      <HStack align="center" space="s-12">
                        <Icon type="SlackMono" size="small" color={Color.GREY_20} />
                        <Text variant="secondary" color={Color.FOREGROUND}>
                          Slack
                        </Text>
                      </HStack>
                    </DetailsCell.Title>
                  </DetailsCell>
                )}
                {/* // TODO: uncomment when backend API is ready
              <DetailsCell use="button" disabled>
                <DetailsCell.Title>
                  <HStack align="center" space="s-12">
                    <Envelope color={Color.GREY_20} size="small" />
                    <Text variant="secondary" color={Color.FOREGROUND}>
                      Email
                    </Text>
                  </HStack>
                </DetailsCell.Title>
              </DetailsCell>
              <DetailsCell use="button" disabled>
                <DetailsCell.Title>
                  <HStack align="center" space="s-12">
                    <Megaphone color={Color.GREY_20} size="small" />
                    <Text variant="secondary" color={Color.FOREGROUND}>
                      News
                    </Text>
                  </HStack>
                </DetailsCell.Title>
              </DetailsCell> */}
              </VStack>
            </FormPreview>
          )}
          {/* // TODO: uncomment when backend API is ready
          <FormPreview<CommunicationGroupInterface> data={values} title="Audience">
            <Group>
              <FormPreview.Item
                title="Make slack channel and news feed private (users will need to request to join)"
                field="private_channels"
                type="boolean"
              />
              <FormPreview.Item
                title="Allow people to join manually"
                field="join_manually"
                type="boolean"
              />
            </Group>
          </FormPreview> */}
        </VStack>
      </PageBody>
    </>
  )
}
