import { VisuallyHidden } from '@revolut/ui-kit'
import { useGetPerformanceSelector } from '@src/api/performance'
import { useGetReviewCycles } from '@src/api/reviewCycles'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeField } from '@src/features/Form/LapeForm'
import CycleSelector, {
  CycleSelectorProps,
} from '@src/features/FormTabs/Kpi/KPITargets/common/CycleSelector'
import { getGenericCyclesInfo } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { ReviewCycleStatus, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import React, { useState } from 'react'

export const TargetCycleSelector = ({
  onSelect,
  isEmployee,
  ownerId,
  initialCycle,
  ...selectorPropsOverride
}: {
  onSelect?: (cycle: ReviewCyclesInterface) => void
  isEmployee: boolean
  ownerId?: number
  initialCycle?: ReviewCyclesInterface
} & Partial<CycleSelectorProps>) => {
  const { data: employeeAvailableCycles } = useGetPerformanceSelector(ownerId)
  const { data: reviewCyclesData } = useGetReviewCycles()
  const [targetCycle, setTargetCycle] = useState<ReviewCyclesInterface | undefined>(
    initialCycle,
  )
  const getAvailableCyclesInfo = (targetIndex: number | null) => {
    const reviewCycles = reviewCyclesData?.results || []
    return getGenericCyclesInfo(reviewCycles, {
      targetIndex,
      allTargets: [],
    })
  }

  const { availableCycles } = getAvailableCyclesInfo(null)
  const employeeFilteredCycles = employeeAvailableCycles?.filter(
    cycle => cycle.status !== ReviewCycleStatus.closed,
  )
  const cycles = isEmployee ? employeeFilteredCycles || availableCycles : availableCycles

  const detailField = useLapeField('detail')
  const cycleError =
    typeof detailField?.apiError === 'string' &&
    detailField?.apiError.includes('review cycle')
      ? detailField?.apiError
      : undefined

  const onChange = (cycle: ReviewCyclesInterface) => {
    onSelect && onSelect(cycle)
    setTargetCycle(cycle)
    detailField.cleanErrors()
  }

  return (
    <>
      <VisuallyHidden>
        <LapeNewInput name="detail" />
      </VisuallyHidden>
      <CycleSelector
        value={targetCycle}
        reviewCycles={cycles}
        onSelect={onChange}
        hasError={!!cycleError}
        message={cycleError}
        {...selectorPropsOverride}
      />
    </>
  )
}
