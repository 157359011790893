import React from 'react'
import { Icon, Token } from '@revolut/ui-kit'
import { PreviewChangelog } from './changelog'
import { getValueWithChangelog } from './utils'

type Props<T> = {
  field?: string
  values: T
  changelog?: PreviewChangelog<T>
}
export const IsRequiredNoticeIcon = <T,>({ field, values, changelog }: Props<T>) => {
  if (getValueWithChangelog({ values, changelog, field })) {
    return null
  }
  return <Icon name="ExclamationMark" color={Token.color.orange} size={16} />
}
