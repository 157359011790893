import React, { useContext } from 'react'
import { Avatar, AvatarSize, HStack, Text } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { getCountryFlagImageByCode } from '@src/utils/getCountryFlagImageByCode'
import { useIsNewTable } from '../hooks'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'
import { getAdjustedAvatarSize } from '@components/TableV2/utils'

interface CountryCellProps {
  country: IdAndName & {
    country_code?: string
  }
  size?: AvatarSize
}

export const CountryCell = ({ country, size }: CountryCellProps) => {
  const isNewTable = useIsNewTable()
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  const adjustedSize = getAdjustedAvatarSize(size || 40, density)

  return (
    <HStack align="center" space="s-12">
      {!!country.country_code && (
        <Avatar
          size={isNewTable ? adjustedSize : 24}
          variant="brand"
          image={getCountryFlagImageByCode(country.country_code)}
        />
      )}
      <Text>{country.name}</Text>
    </HStack>
  )
}
