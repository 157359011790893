import React from 'react'
import { Button, Grid } from '@revolut/ui-kit'

import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ImportFieldType, ImportInterface } from '@src/interfaces/bulkDataImport'
import OnboardingActions from '@src/pages/OnboardingChecklist/components/OnboardingActions'
import { OnboardingChecklistSectionConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { BulkDataImportSession } from '@src/features/BulkDataImport/BulkDataImportSession'

export interface UploadSessionProps<T> {
  apiEndpoint: string
  config: OnboardingChecklistSectionConfig
  currentStep: string
  previousRoute: string
  tableName: TableNames
  row: Partial<RowInterface<ImportInterface<T>>>
  fields: ImportFieldType<T>[]
  onBeforeSubmit?: () => Promise<void>
}

export const UploadSession = <T,>({
  config,
  row,
  fields,
  apiEndpoint,
  tableName,
  previousRoute,
  currentStep,
  onBeforeSubmit,
}: UploadSessionProps<T>) => {
  return (
    <BulkDataImportSession
      row={row}
      apiEndpoint={apiEndpoint}
      tableName={tableName}
      fields={fields}
      actions={({ onSubmit, submitPending, sessionData, disableActions }) => {
        return sessionData.state.id === 'ready_for_review' ? (
          <PageActions>
            <Grid columns={2} gap="s-8" maxWidth={375}>
              <Button
                variant="secondary"
                onClick={() => {
                  goBack(pathToUrl(previousRoute))
                }}
              >
                Back
              </Button>
              <Button
                onClick={onSubmit}
                pending={submitPending}
                disabled={disableActions}
                elevated
              >
                Upload data
              </Button>
            </Grid>
          </PageActions>
        ) : (
          <OnboardingActions
            config={config}
            currentStep={currentStep}
            isLastStep
            disableBack={false}
            pendingBack={false}
            disableNext={submitPending}
            pendingNext={disableActions}
            nextRoute=""
            previousRoute={previousRoute}
            updateSteps
            isForm={false}
            onBeforeSubmit={onBeforeSubmit}
          />
        )
      }}
    />
  )
}
