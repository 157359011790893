import { lape } from 'lape'

export interface MainHeader {
  height: number
}

const defaultState: MainHeader = {
  height: 0,
}

export default lape(defaultState)
