import { NavigationConfigInterface } from '@src/components/StatNavigation/types'
import { ROUTES } from '@src/constants/routes'
import { GlobalSettings } from '@src/store/auth/types'

export const reviewStatNavConfig: NavigationConfigInterface<{
  performance: number
  probation: number
  pip: number
}>[] = [
  {
    key: 'performance',
    label: 'Performance',
    to: ROUTES.PERFORMANCE.REVIEWS.GENERAL,
  },
  {
    key: 'probation',
    label: 'Probation',
    to: ROUTES.PERFORMANCE.REVIEWS.PROBATION,
    canView: { globalSettings: [GlobalSettings.ProbationEnabled] },
  },
  {
    key: 'pip',
    label: 'PIP',
    to: ROUTES.PERFORMANCE.REVIEWS.PIP,
    canView: { globalSettings: [GlobalSettings.PipEnabled] },
  },
]
