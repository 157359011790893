import React from 'react'
import { createChain, Flex, Icon, Token } from '@revolut/ui-kit'

interface Props {
  values: string[]
}

export const SeniorityProgression = ({ values }: Props) => (
  <Flex gap="s-4" alignItems="center" color={Token.color.foreground}>
    {createChain(<Icon name="ArrowThinRight" size={16} />)(...new Set(values))}
  </Flex>
)
