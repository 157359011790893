import React from 'react'
import { createFeedback } from '@src/api/recruitment/interviewFeedback'
import { InterviewToolInterviewer } from '@src/interfaces/interviewTool'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import MenuAction, { MenuActionType } from '@components/MenuAction/MenuAction'
import { useOpenNewTab } from '@src/actions/RouterActions'

type Props = {
  stageId: number
  loading: boolean
  setLoading: (isLoading: boolean) => void
  onProceed?: () => void
  menuType: MenuActionType
  edit: boolean
}

const AddFeedbackButton = ({
  stageId,
  loading,
  setLoading,
  onProceed,
  menuType,
  edit,
}: Props) => {
  const user = useSelector(selectUser)
  const openNewTab = useOpenNewTab()

  const navigateToScorecard = async () => {
    setLoading(true)

    try {
      const resp = await createFeedback(stageId, {
        id: user.id,
      } as InterviewToolInterviewer)

      const scorecard = resp.data

      if (scorecard) {
        openNewTab(
          pathToUrl(ROUTES.FORMS.INTERVIEW_FEEDBACK.GENERAL, { id: scorecard.id }),
        )
      }

      onProceed?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <MenuAction
      menuType={menuType}
      props={{
        disabled: loading,
        use: 'button',
        onClick: navigateToScorecard,
      }}
    >
      {edit ? 'Edit' : 'Add'} feedback
    </MenuAction>
  )
}

export default AddFeedbackButton
