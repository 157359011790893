import * as React from 'react'
import styled from 'styled-components'
import { Text, Flex, Token } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'

const BottomInfo = styled.div`
  padding: 2px 16px;
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: ${Token.color.greyTone50};
  max-width: fit-content;
`

export interface BottomProps {
  error?: string
  bottomInfo?: React.ReactNode
}

const BottomText = ({ bottomInfo, error }: BottomProps) => (
  <>
    {bottomInfo && <BottomInfo>{bottomInfo}</BottomInfo>}
    {error && (
      <Flex pl="1rem" pt="s-8" alignItems="center">
        <Text pr="0.5rem">
          <ExclamationMarkOutline color={Token.color.red} size={12} />
        </Text>
        <Text color={Token.color.red} variant="small">
          {error}
        </Text>
      </Flex>
    )}
  </>
)

export default BottomText
