import React from 'react'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'

export const Engagement = ({ data }: { data: DepartmentInterface }) => {
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Departments,
        id: data.id,
        permissions: data.field_options.permissions,
      }}
    />
  )
}
