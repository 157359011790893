import { ProbationResults } from '@src/interfaces/probationReview'
import React from 'react'
import { Color, Box } from '@revolut/ui-kit'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'

interface Props {
  disabled?: boolean
  isPIP?: boolean
}

export const probationDecisionOptions = [
  {
    value: ProbationResults.Passed,
    label: 'Pass',
  },
  {
    value: ProbationResults.ChangeSeniority,
    label: 'Pass, with lower seniority',
  },
  {
    value: ProbationResults.Extend,
    label: 'Extend',
  },
  {
    value: ProbationResults.Failed,
    label: 'Fail',
  },
]

export const pipDecisionOptions = [
  {
    value: ProbationResults.Passed,
    label: 'Pass',
  },
  {
    value: ProbationResults.Failed,
    label: 'Fail',
  },
]

const ProbationDecision = ({ disabled, isPIP = false }: Props) => {
  return (
    <LapeNewRadioButtons
      name="result"
      options={isPIP ? pipDecisionOptions : probationDecisionOptions}
      disabled={disabled}
      labelProps={option =>
        option.value === ProbationResults.Failed ? { color: Color.RED } : {}
      }
      renderRadio={option => <Box py="s-8">{option}</Box>}
    />
  )
}

export default ProbationDecision
