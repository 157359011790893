import React from 'react'
import { ClickUpSelectOption } from './ClickupSelectOption'
import { Token, Widget, Caption, Spacer, VStack, Text } from '@revolut/ui-kit'
import { EpicOption } from '@src/interfaces/kpis'

interface Props {
  tasks: Pick<EpicOption, 'key' | 'url' | 'name'>[]
  error?: string
  onDelete: (key: string | null) => void
}

export const ClickupSelectedTaskWidget = ({ tasks, onDelete, error }: Props) => {
  if (!tasks.length && !error) {
    return null
  }
  return (
    <Widget p="s-12" style={{ border: `1px solid ${Token.color.greyTone10}` }}>
      <Caption color={Token.color.greyTone50}>Tasks</Caption>
      <Spacer height="s-12" />
      <VStack space="s-12">
        {tasks.map(task => (
          <ClickUpSelectOption
            key={task.key}
            url={task.url}
            taskId={task.key || undefined}
            onDelete={() => {
              onDelete(task.key)
            }}
            label={task.name}
          />
        ))}
        {error && (
          <Text variant="body3" color={Token.color.danger}>
            {error}
          </Text>
        )}
      </VStack>
    </Widget>
  )
}
