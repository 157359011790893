import React from 'react'
import { useTable } from '@components/Table/hooks'
import { KpiInterface } from '@src/interfaces/kpis'
import { getPercentColor } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { FilterByInterface, Stats } from '@src/interfaces/data'
import { useSelector } from 'react-redux'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatPercentage } from '@src/utils/format'
import { CompanyInterface } from '@src/interfaces/company'
import { companyKpiRequests } from '@src/api/kpis'
import { MoreBar, TableWidget, Text } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { CompanyKPIRow } from '@src/pages/Forms/Company/KPI/common'
import { onKPITableRowClick } from '@src/pages/EmployeeProfile/Preview/KPI/utils'
import { Statuses } from '@src/interfaces'
import { TableNames } from '@src/constants/table'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import {
  canAddDepartmentKpi,
  selectFeatureFlags,
  selectUser,
} from '@src/store/auth/selectors'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import { SettingsButton } from '@src/features/SettingsButtons/SettingsButton/SettingsButton'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'

interface StructureProps {
  data: CompanyInterface
}

const Company = ({ data }: StructureProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const initialFilter: FilterByInterface[] = [
    {
      filters: [
        { name: Statuses.approved, id: Statuses.approved },
        { name: Statuses.pending, id: Statuses.pending },
        { name: Statuses.future, id: Statuses.future },
        { name: Statuses.completed, id: Statuses.completed },
        { name: Statuses.requires_changes, id: Statuses.requires_changes },
      ],
      columnName: 'target_status',
      nonResettable: true,
    },
    {
      filters: [{ name: `True`, id: 'True' }],
      columnName: 'is_company',
      nonResettable: true,
      nonInheritable: true,
    },
    {
      filters: [{ name: `0`, id: 0 }],
      columnName: 'review_cycle__offset',
      nonResettable: true,
    },
    {
      columnName: 'goals_related',
      filters: [
        { name: goalsEnabled ? 'true' : 'false', id: goalsEnabled ? 'true' : 'false' },
      ],
      nonResettable: true,
    },
  ]
  const companyTable = useTable<KpiInterface, Stats>(companyKpiRequests, initialFilter)

  const canAdd = useSelector(canAddDepartmentKpi)
  const user = useSelector(selectUser)

  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.COMPANY_GOALS.GENERAL, {}), {
      initialValues: getKPIFormInitialValues(user, {
        department: { name: data.name, id: data.id },
      }),
    })
  }

  return (
    <TableWidget>
      <TableWidget.Info>
        <Stat
          label="Progress"
          val={
            companyTable?.stats?.avg_progress !== undefined ? (
              <Text
                use="div"
                mr="5px"
                color={getPercentColor(companyTable.stats.avg_progress * 100)}
              >
                {formatPercentage(companyTable.stats.avg_progress)}
              </Text>
            ) : undefined
          }
          mr="s-32"
        />
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={companyTable.onFilterChange}
          columnName="review_cycle__offset"
          filter={companyTable.filterBy}
        />
      </TableWidget.Info>
      <TableWidget.Actions>
        <MoreBar>
          {canAdd && (
            <MoreBar.Action onClick={handleNewRow} useIcon="Plus">
              Add New KPI
            </MoreBar.Action>
          )}
          <SettingsButton
            path={ROUTES.SETTINGS.KPI}
            canView={[
              PermissionTypes.ViewPerformancePreferences,
              PermissionTypes.ChangePerformancePreferences,
            ]}
          />
        </MoreBar>
      </TableWidget.Actions>
      <TableWidget.Table>
        <AdjustableTable<KpiInterface>
          name={TableNames.CompanyKPI}
          useWindowScroll
          dataType="KPI"
          row={CompanyKPIRow(data.name)}
          onRowClick={onKPITableRowClick}
          noDataMessage="Please add your department KPIs to see them here."
          {...companyTable}
        />
      </TableWidget.Table>
    </TableWidget>
  )
}

export default Company
