import React, { useState } from 'react'
import { Button, Token } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import { OnboardingAppScreen } from '@src/pages/OnboardingChecklistV2/components/OnboardingAppScreen'
import { PageActions } from '@components/Page/PageActions'
import { EmployeeTable } from './EmployeeTable'

const EmployeesBase = () => {
  const [isEditing, setIsEditing] = useState(false)

  if (isEditing) {
    return (
      <>
        <EditableEmployeesTableDetailed
          statsView="default"
          sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SESSION}
        />
        <PageActions maxWidthMd={Token.breakpoint.xl}>
          <Button mt="s-32" onClick={() => setIsEditing(false)}>
            Submit
          </Button>
        </PageActions>
      </>
    )
  }
  return <EmployeeTable onClickBulkEdit={() => setIsEditing(true)} />
}

export const Employees = () => {
  return (
    <OnboardingAppScreen category="employeeRecords">
      <EmployeesBase />
    </OnboardingAppScreen>
  )
}
