import React, { useState } from 'react'
import styled from 'styled-components'
import Icon, { IconSizes } from '@components/Icon/Icon'
import Tooltip, { placement as Placement } from '@components/Tooltip/Tooltip'
import Loader from '@components/CommonSC/Loader'
import { Token } from '@revolut/ui-kit'

const Container = styled.div`
  color: inherit;
`

export enum HistoryTypes {
  Current = 'current',
  Future = 'future',
  Past = 'past',
}

const TimeIcon = styled(Icon)<{ color: string }>`
  color: ${props => props.color};
`

export const TooltipBodyContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-row-gap: 10px;
  color: ${Token.color.background};
  line-height: 18px;
  font-size: 11px;
  padding: 8px 12px;
`

export const TooltipLoaderContainer = styled(TooltipBodyContainer)`
  width: 100px;
`

export const NoChangesMessage = styled.div`
  white-space: nowrap;
`

export const HistoryItemContainer = styled.div<{ type: HistoryTypes }>`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 7px;
  white-space: nowrap;
  justify-content: start;
  align-items: center;
  color: ${props => {
    switch (props.type) {
      case HistoryTypes.Future:
        return '#F7930D'
      case HistoryTypes.Current:
        return Token.color.background
      default:
        return Token.color.greyTone50
    }
  }};
`

export const HistoryItemText = styled.div`
  text-transform: capitalize;
`

export const DotContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
`

export const Dot = styled.div<{ type: HistoryTypes }>`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: ${props => {
    switch (props.type) {
      case HistoryTypes.Future:
        return '#F7930D'
      case HistoryTypes.Current:
        return '#00BE90'
      default:
        return '#3e4348'
    }
  }};
`

export const Line = styled.div`
  position: absolute;
  width: 1px;
  height: 8px;
  opacity: 0.5;
  background-color: ${Token.color.greyTone50};
  bottom: -8px;
  left: 2px;
`

export interface HistoryTooltipData {
  type: HistoryTypes
  name: string
}

interface HistoryTooltipProps {
  isNew?: boolean
  className?: string
  color: string
  request: () => Promise<HistoryTooltipData[]>
  children?: React.ReactNode
  iconSize?: IconSizes
  placement?: Placement
}

export const renderHistoryBulletPoint = (type: HistoryTypes, last: boolean) => {
  return (
    <DotContainer>
      <Dot type={type} />
      {!last && <Line />}
    </DotContainer>
  )
}

const HistoryTooltip = ({
  className,
  color,
  request,
  isNew,
  iconSize,
  placement,
  children,
}: HistoryTooltipProps) => {
  const [loading, setLoading] = useState(false)
  const [history, setHistory] = useState<HistoryTooltipData[]>()

  const renderTooltipBody = () => {
    if (loading) {
      return (
        <TooltipLoaderContainer>
          <Loader size="tiny" />
        </TooltipLoaderContainer>
      )
    }

    if (!history?.length) {
      return (
        <TooltipBodyContainer>
          <NoChangesMessage>
            There is no changelog history for this field.
          </NoChangesMessage>
        </TooltipBodyContainer>
      )
    }

    return (
      <TooltipBodyContainer>
        {history?.map((item, id) => {
          if (id === 3) {
            return (
              <HistoryItemContainer type={HistoryTypes.Past}>
                {renderHistoryBulletPoint(HistoryTypes.Past, true)}
                <HistoryItemText>...</HistoryItemText>
              </HistoryItemContainer>
            )
          }
          return (
            <HistoryItemContainer type={item.type} key={id}>
              {renderHistoryBulletPoint(item.type, id === history?.length - 1)}
              <HistoryItemText>{item.name}</HistoryItemText>
            </HistoryItemContainer>
          )
        })}
      </TooltipBodyContainer>
    )
  }

  const handleMouseIn = async () => {
    try {
      setLoading(true)
      const result = await request()

      if (result) {
        setHistory(result)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container className={className}>
      <Tooltip
        onHover={handleMouseIn}
        placement={placement || 'right'}
        body={renderTooltipBody()}
      >
        {children || (
          <TimeIcon
            color={color}
            size={iconSize || (isNew ? 'medium' : 'tiny')}
            type="Time"
          />
        )}
      </Tooltip>
    </Container>
  )
}

export default HistoryTooltip
