import React, { useRef } from 'react'
import {
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
  Button,
  Flex,
  Item,
  Avatar,
  Token,
  Text,
  Footnote,
  VStack,
} from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { connect } from 'lape'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'
import { CredentialsLoginInterface } from '@src/interfaces/auth'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { TenantAdminInterface } from '@src/interfaces/tenants'
import { arrayErrorsToFormError } from '@src/utils/form'
import { navigateReplace } from '@src/actions/RouterActions'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { TermsAndConditionsDisclaimer, setSignupStateCookie } from '../common'
import { ReCaptcha } from '@components/ReCaptcha/ReCaptcha'
import { ReCaptchaHandle } from '@src/interfaces/reCaptcha'
import { useGetGoogleSignupUrl } from '@src/api/signup'
import { env, Environments } from '@src/constants/api'
import { isWorkspacesEnabled } from '@src/utils'

interface CreateAccountFormProps {
  onSuccess: (data: CredentialsLoginInterface) => void
}

export const CreateAccountForm = connect(({ onSuccess }: CreateAccountFormProps) => {
  const { values, errors, submit } = useLapeContext<TenantAdminInterface>()
  const captchaRef = useRef<ReCaptchaHandle>(null)

  const statusPopup = useStatusPopup()

  const signupUrl = useGetGoogleSignupUrl()

  const showAccountAlreadyExistsPopup = (message: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>{message}</StatusPopup.Title>
        <StatusPopup.Actions>
          <Button
            onClick={() => {
              statusPopup.hide()
              navigateReplace(isWorkspacesEnabled() ? WORKSPACES.MAIN : ROUTES.LOGIN.MAIN)
            }}
            elevated
          >
            Login
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const onSubmit = async () => {
    const token = await captchaRef.current?.verify()
    if (token) {
      values.captcha = token
    }
    return submit()
  }

  return (
    <>
      <Header variant="main" pt="s-72">
        <Header.Title>Sign up to Revolut People</Header.Title>
        <Header.Description>
          Please use your corporate email to sign up to the waitlist
        </Header.Description>
      </Header>

      <Item
        onClick={() => window.open(signupUrl, `_self`)}
        use="button"
        type="button"
        variant="disclosure"
        disabled={!signupUrl}
      >
        <Item.Avatar>
          <Avatar useIcon="LogoGoogle|image" bg={Token.color.widgetBackground} />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Signup with Google</Item.Title>
        </Item.Content>
      </Item>

      <Flex justifyContent="center" my="s-16">
        <Text color={Token.color.greyTone50} variant="small">
          OR
        </Text>
      </Flex>

      <InputGroup>
        <LapeNewInput name="full_name" label="Full name" required />
        <LapePhoneInput
          prefixName="phone_country_code"
          phoneName="phone_number_short"
          apiVersion="v2"
        />
        <LapeNewInput name="email" label="Work email" required />
        <Flex alignItems="center" flexDirection="column" mt="s-16">
          <ReCaptcha
            onVerify={value => {
              values.captcha = env === Environments.local ? 'skip' : value
            }}
            name="captcha"
            error={errors?.captcha}
            ref={captchaRef}
          />
        </Flex>
      </InputGroup>

      <PageActions>
        <VStack maxWidth={375} space="s-16">
          <Footnote>
            <TermsAndConditionsDisclaimer />
          </Footnote>
          <NewSaveButtonWithPopup
            onClick={onSubmit}
            onAfterSubmit={data => {
              setSignupStateCookie(
                'email_confirmation',
                (data as CredentialsLoginInterface).token,
              )
              onSuccess(data as CredentialsLoginInterface)
            }}
            onSubmitError={error => {
              const errorsList = arrayErrorsToFormError<{ email?: string }>(
                error?.response?.data,
              )
              const emailError = errorsList.email
              if (
                emailError?.includes('This email can’t be used to create the account!')
              ) {
                showAccountAlreadyExistsPopup(emailError)
              }
              captchaRef.current?.reset()
            }}
            useValidator
            noPopup
            type="submit"
          >
            Sign up
          </NewSaveButtonWithPopup>
        </VStack>
      </PageActions>
    </>
  )
})
