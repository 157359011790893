import React from 'react'
import { Text } from '@revolut/ui-kit'
import { useIsNewTable } from '@components/TableV2/hooks'
import { ColorTag } from '@components/ColorTag/ColorTag'

type Props = {
  seniority?: string | { min?: string; max?: string }
}

export const SeniorityCell = ({ seniority: initialSeniority }: Props) => {
  const isNewTable = useIsNewTable()
  let seniority

  if (!initialSeniority) {
    return <>-</>
  }

  if (typeof initialSeniority !== 'string') {
    if (!initialSeniority.min && !initialSeniority.max) {
      return <>-</>
    }

    if (
      initialSeniority.min &&
      initialSeniority.max &&
      initialSeniority.min !== initialSeniority.max
    ) {
      seniority = `${initialSeniority.min} - ${initialSeniority.max}`
    } else {
      seniority = initialSeniority.min || initialSeniority.max
    }
  } else {
    seniority = initialSeniority
  }

  if (!isNewTable) {
    return <Text>{seniority}</Text>
  }

  return (
    <ColorTag icon="Profile" variant="neutral">
      {seniority}
    </ColorTag>
  )
}
