import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'

import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeButtonProps } from '../types'
import { useCanViewChangePassword } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'

export const ChangePassword = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = useCanViewChangePassword({ data })

  if (!canView) {
    return null
  }

  const icon = <Icon name="Key" size={16} />
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use={InternalLink}
        useIcon={icon}
        to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD, { id: data.id })}
      >
        Change password
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      use={InternalLink}
      useIcon={icon}
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.CHANGE_PASSWORD, { id: data.id })}
    >
      Change password
    </MoreBar.Action>
  )
}
