import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { BookingLinksTable } from './BookingLinksTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const BookingLinksPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Booking links"
        backUrl={pathToUrl(ROUTES.RECRUITMENT.CANDIDATES)}
      />
      <PageBody maxWidth="100%">
        <BookingLinksTable />
      </PageBody>
    </PageWrapper>
  )
}
