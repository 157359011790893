import React from 'react'
import AddBubbles from '@src/assets/3D/AddBubbles@2x.png'
import { ErrorWidget } from '@revolut/ui-kit'

const NoDataWidget = ({ text }: { text: string }) => {
  return (
    <ErrorWidget>
      <ErrorWidget.Image src={AddBubbles} />
      <ErrorWidget.Title>{text}</ErrorWidget.Title>
    </ErrorWidget>
  )
}

export default NoDataWidget
