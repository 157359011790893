import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { WorkScheduleCustomApproversList } from './List'
import { EditWorkScheduleCustomApprovers } from './Edit'
import { PreviewWorkScheduleCustomApprovers } from './Preview'

export const WorkScheduleCustomApprovers = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.LIST}>
        <WorkScheduleCustomApproversList />
      </Route>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.EDIT.ANY}>
        <EditWorkScheduleCustomApprovers />
      </Route>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES_CUSTOM_APPROVERS.PREVIEW}>
        <PreviewWorkScheduleCustomApprovers />
      </Route>
    </Switch>
  )
}
