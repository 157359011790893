import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'lape'
import { Box, Subheader, Text, Token, VStack } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { documentTemplatesFormRequest } from '@src/api/documentsTemplates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ESignatureTemplateEditor } from '@src/features/ESignatureTemplateEditor'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { useValidationErrors } from '@src/features/ESignatureTemplateEditor/common'
import { PageActions } from '@components/Page/PageActions'
import { AxiosError } from 'axios'

const TemplatesEditorFormContent = connect(
  ({ renderNextTabButton }: Pick<Props, 'renderNextTabButton'>) => {
    const { values, submit, dirty } = useLapeContext<DocumentsTemplatesInterface>()
    const errors = useValidationErrors(values)

    const onSaveTemplate = () => {
      if (!dirty) {
        return Promise.resolve()
      }
      errors.reset()
      return submit()
    }

    return (
      <VStack mt="s-8" space="s-24">
        <Box>
          <Subheader>
            <Subheader.Title>
              <FormattedMessage
                id="documents.bulkrequest.templates.editor.title"
                defaultMessage="Review your templates"
              />
            </Subheader.Title>
          </Subheader>
          <Text variant="caption" color={Token.color.greyTone50}>
            <FormattedMessage
              id="documents.bulkrequest.templates.editor.subtitle"
              defaultMessage="Any settings changes you do here will be applied to the original template as well"
            />
          </Text>
        </Box>
        <ESignatureTemplateEditor
          initPreview
          showEditToggle
          values={values}
          errors={errors}
        />
        <PageActions>{renderNextTabButton(onSaveTemplate, errors.parse)}</PageActions>
      </VStack>
    )
  },
)

type Props = {
  templateId: number | string
  renderNextTabButton: (
    onSaveTemplate: () => Promise<DocumentsTemplatesInterface> | Promise<void>,
    handleErrors: (err: AxiosError) => void,
  ) => React.ReactNode
}
export const TemplatesEditor = ({ templateId, renderNextTabButton }: Props) => (
  <Form
    api={documentTemplatesFormRequest}
    forceParams={{ id: templateId ? String(templateId) : undefined }}
    disableLocalStorageCaching
  >
    <TemplatesEditorFormContent renderNextTabButton={renderNextTabButton} />
  </Form>
)
