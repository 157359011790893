import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Item,
  ItemSkeleton,
  Text,
  Token,
  TransitionCollapse,
  Widget,
  chain,
} from '@revolut/ui-kit'
import {
  ApprovalFlowContent,
  ApprovalFlowProps,
  StepInfo,
  formatStepName,
  getCurrentStepInfo,
} from '@src/features/ApprovalFlow/ApprovalFlow'
import { ApprovalFlowStep, ApprovalStatuses } from '@src/interfaces/approvalFlow'
import ShevronToggle from '@src/components/Atoms/ShevronToggle'
import isEmpty from 'lodash/isEmpty'
import upperFirst from 'lodash/upperFirst'
import { removeUnderscore } from '@src/utils/string'

type ApprovalStatusProps = {
  status: ApprovalStatuses
}

const ApprovalStatusAvatar = ({ status }: ApprovalStatusProps) => {
  switch (status) {
    case ApprovalStatuses.Approved:
    case ApprovalStatuses.Skipped:
      return <Avatar color={Token.color.green} useIcon="Check" />
    case ApprovalStatuses.Pending:
    case ApprovalStatuses.PendingReApproval:
      return <Avatar color={Token.color.orange} useIcon="16/SandWatch" />
    case ApprovalStatuses.Rejected:
    case ApprovalStatuses.Canceled:
    case ApprovalStatuses.Cancelled:
      return <Avatar color={Token.color.red} useIcon="Cross" />
    case ApprovalStatuses.RequiresChanges:
    case ApprovalStatuses.NoStatus:
    default: {
      return <Avatar useIcon="Question" />
    }
  }
}

const ApprovalStatusTitle = ({ status }: ApprovalStatusProps) => {
  switch (status) {
    case ApprovalStatuses.Approved:
    case ApprovalStatuses.Skipped:
      return <Item.Title color={Token.color.green}>Approved</Item.Title>
    case ApprovalStatuses.Pending:
      return <Item.Title color={Token.color.orange}>Pending approval</Item.Title>
    case ApprovalStatuses.Rejected:
      return <Item.Title color={Token.color.red}>Rejected</Item.Title>
    case ApprovalStatuses.Canceled:
    case ApprovalStatuses.Cancelled:
    case ApprovalStatuses.PendingReApproval:
    case ApprovalStatuses.NoStatus:
    case ApprovalStatuses.RequiresChanges:
    default: {
      return <Item.Title>{upperFirst(removeUnderscore(status))}</Item.Title>
    }
  }
}

type ApprovalStatusDescriptionProps = {
  steps: ApprovalFlowStep[]
  currentStep: StepInfo
}

const ApprovalStatusDescription = ({
  steps,
  currentStep,
}: ApprovalStatusDescriptionProps) => {
  const numSteps = steps.length
  let formattedStepName = formatStepName(steps[currentStep.index].approval_step)
  let stepName
  if (
    currentStep.status === ApprovalStatuses.Approved ||
    (currentStep.status === ApprovalStatuses.Skipped &&
      currentStep.index === numSteps - 1)
  ) {
    stepName = chain(`${numSteps}/${numSteps}`, upperFirst(ApprovalStatuses.Approved))
  }
  if (
    currentStep.status === ApprovalStatuses.Pending ||
    currentStep.status === ApprovalStatuses.RequiresChanges
  ) {
    stepName = chain(
      `${currentStep.index + 1}/${numSteps}`,
      `${upperFirst(ApprovalStatuses.Pending)} with ${formattedStepName}`,
    )
  }
  if (currentStep.status === ApprovalStatuses.Rejected) {
    stepName = chain(
      `${currentStep.index + 1}/${numSteps}`,
      `${upperFirst(ApprovalStatuses.Rejected)} by ${formattedStepName}`,
    )
  }
  if (!stepName) {
    return null
  }
  return <Text fontWeight={500}>{stepName}</Text>
}

const ApprovalFlowWidget = ({
  steps,
  isLoading,
  openByDefault = false,
  onViewRejectionReasonClick,
}: ApprovalFlowProps) => {
  const [open, setOpen] = useState(openByDefault)
  if (isLoading || !steps) {
    return <ItemSkeleton />
  }
  // Sometimes BE returns {} instead of [] when the data is missing in the test envs ¯\_(ツ)_/¯
  if (isEmpty(steps)) {
    return null
  }
  const currentStep = getCurrentStepInfo(steps)
  return (
    <Widget>
      <Item
        use="button"
        onClick={event => {
          event.preventDefault()
          setOpen(!open)
        }}
      >
        <Item.Avatar>
          <ApprovalStatusAvatar status={currentStep.status} />
        </Item.Avatar>
        <Item.Content>
          <ApprovalStatusTitle status={currentStep.status} />
          <Item.Description>
            <ApprovalStatusDescription steps={steps} currentStep={currentStep} />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ShevronToggle isOpen={open} />
        </Item.Side>
      </Item>
      <TransitionCollapse in={open}>
        <Box mt="-s-16" px="s-16" pb="s-16">
          <ApprovalFlowContent
            currentStep={currentStep}
            steps={steps}
            onViewRejectionReasonClick={onViewRejectionReasonClick}
          />
        </Box>
      </TransitionCollapse>
    </Widget>
  )
}

export default ApprovalFlowWidget
