import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'

export const emailTemplateNameColumn: ColumnInterface<EmailTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.email_templates,
  title: 'Template name',
}

export const emailTemplateSubjectColumn: ColumnInterface<EmailTemplateInterface> = {
  type: CellTypes.text,
  idPoint: 'subject',
  dataPoint: 'subject',
  sortKey: 'subject',
  filterKey: 'id',
  selectorsKey: selectorKeys.email_template_subjects,
  title: 'Subject',
}

export const emailTemplateOwnerColumn: ColumnInterface<EmailTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner',
  filterKey: 'owner',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

export const emailTemplateCreatedDateColumn: ColumnInterface<EmailTemplateInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const emailTemplateStatusColumn: ColumnInterface<EmailTemplateInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.email_template_statuses,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status}
      variant={getDefaultStatusVariant(data.status)}
    />
  ),
}
