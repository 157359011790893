import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import NewStepperSectionCustomFields from '@components/Stepper/NewStepperSectionCustomFields'
import { EMPLOYEE_PROFILE_SUB_SECTIONS } from '@src/interfaces/employees'
import { DynamicGroupIDs, SectionOptions } from '@src/interfaces/customFields'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'
import { Side } from '@revolut/ui-kit'

export type Props = {
  dynamicGroups: DynamicGroupIDs
  isSidebarContent?: boolean
}
const Identification = ({ dynamicGroups, isSidebarContent }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit()
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <PageBody>
      <NewStepperSectionCustomFields
        sectionId={SectionOptions.EmployeeProfile}
        subSectionId={EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS}
        dynamicGroups={dynamicGroups}
        customFieldsOnly
      />
      <ActionsWrapper>
        <NewSaveButtonWithPopup
          useValidator
          successText="Information was successfully updated"
          onAfterSubmit={onAfterSubmit}
        />
      </ActionsWrapper>
    </PageBody>
  )
}

export default Identification
