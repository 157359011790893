import React from 'react'
import { BottomSheet, Header, InputGroup } from '@revolut/ui-kit'
import { timeOffCategoriesRequests } from '@src/api/timeOff'
import { TimeOffCategoryInterface } from '@src/interfaces/timeOff'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

interface CategoryPopupProps {
  id?: string
  onClose: () => void
  onSuccess: (data: TimeOffCategoryInterface) => void
}

const CategoryPopupContent = ({
  onAfterSubmit,
}: {
  onAfterSubmit: (data: TimeOffCategoryInterface) => void
}) => {
  const { values } = useLapeContext<TimeOffCategoryInterface>()

  return (
    <>
      <Header>
        <Header.Title>{values.id ? `Edit category` : 'Create new category'}</Header.Title>
      </Header>
      <InputGroup>
        <LapeNewInput label="Name" name="name" required />
        <LapeNewTextArea label="Description" name="description" rows={3} />
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          noPopup
          onAfterSubmit={onAfterSubmit}
          useValidator
        >
          Save
        </NewSaveButtonWithPopup>
      </BottomSheet.Actions>
    </>
  )
}

export const CategoryPopup = ({ id, onClose, onSuccess }: CategoryPopupProps) => {
  return (
    <BottomSheet open={!!id} onClose={onClose}>
      <Form
        api={timeOffCategoriesRequests}
        forceParams={{
          id: id === 'new' ? undefined : id,
        }}
      >
        <CategoryPopupContent onAfterSubmit={onSuccess} />
      </Form>
    </BottomSheet>
  )
}
