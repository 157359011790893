import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'

import React from 'react'
import { EligibleEmployees } from '@src/pages/Forms/ReviewCycle/EligibleEmployeesForm'
import { EditEligibleGroups } from '@src/pages/Forms/ReviewCycle/ReviewCycle/EligibleEmployees/EditEligibleGroups/EditEligibleGroups'

export const EditEligibleGroupsPage = () => {
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()

  if (!isTestCycleEnabled) {
    return <EligibleEmployees />
  }

  return <EditEligibleGroups />
}
