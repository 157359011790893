import React from 'react'
import { Item, Text, VStack } from '@revolut/ui-kit'
import { Video } from '@components/Video/Video'

export const EmployeesIntro = () => {
  return (
    <VStack gap="s-16">
      <Video
        poster="/intros/Intro%20-%20Employees.png"
        url="/intros/Intro%20-%20Employees.mov"
      />
      <Item>
        <Item.Content>
          <Item.Title>How to upload your employee data?</Item.Title>
          <Item.Description>
            <VStack gap="s-16" mt="s-8">
              <Text>
                At this step we will guide you through the initial steps of our user
                onboarding process. If you're seeking guidance on how to seamlessly upload
                your employees data, you've come to the right place.
              </Text>
              <Text>
                To commence, access the 'Employee Data Upload' section on your dashboard.
                Here, you can conveniently upload your employee information using a CSV or
                Excel file. Our tool is designed to intelligently align columns with the
                appropriate fields, ensuring accurate and efficient data migration. Once
                imported, you'll gain a comprehensive overview of your team's information
                within a centralized hub. Moreover, you can effortlessly update this data
                whenever changes transpire, guaranteeing the ongoing accuracy of your
                records.
              </Text>
              <Text>
                Let's embark on this journey together and simplify your employee
                management processes. From initial configuration to continuous updates,
                our HRM tool is poised to support you at every juncture.
              </Text>
            </VStack>
          </Item.Description>
        </Item.Content>
      </Item>
    </VStack>
  )
}
