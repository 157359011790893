import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import Preview from './Preview'
import General from './General'
import { PageWrapper } from '@src/components/Page/Page'
import { emailTemplatesRequests } from '@src/api/emailTemplates'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'

const EmailTemplate = () => {
  const permissions = useSelector(selectPermissions)

  return (
    <PageWrapper>
      <Switch>
        {permissions.includes(PermissionTypes.ViewEmailTemplate) && (
          <Route exact path={ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW}>
            <Preview />
          </Route>
        )}
        {permissions.includes(PermissionTypes.AddEmailTemplate) && (
          <Route exact path={ROUTES.FORMS.EMAIL_TEMPLATE.GENERAL}>
            <General />
          </Route>
        )}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={emailTemplatesRequests}>
    <EmailTemplate />
  </Form>
))
