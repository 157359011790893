import React, { useState } from 'react'
import PositionForm, { Props } from './General'
import Form from '@src/features/Form/Form'
import { contractorsRequests, employeesRequestsNew } from '@src/api/employees'
import { EmployeeType } from '@src/interfaces/employees'

const Position = (props: Omit<Props, 'type' | 'onEmployeeTypeChange'>) => {
  const [type, setType] = useState<EmployeeType>(props.data.employee_type)

  return props.data.employee_type === 'external' ? (
    <Form api={contractorsRequests}>
      <PositionForm {...props} onEmployeeTypeChange={setType} type={type} />
    </Form>
  ) : (
    <Form api={employeesRequestsNew}>
      <PositionForm {...props} onEmployeeTypeChange={setType} type={type} />
    </Form>
  )
}

export default Position
