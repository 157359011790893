import React from 'react'
import {
  Box,
  Subheader,
  Checkbox,
  Group,
  Item,
  CheckboxGroup,
  SubheaderSkeleton,
  ItemSkeleton,
  Skeleton,
  Token,
  VStack,
  Avatar,
  Portal,
  TextSkeleton,
  TextButton,
} from '@revolut/ui-kit'
import { capitalize, groupBy, upperFirst } from 'lodash'

import { times } from '@src/utils/times'
import { AccountsSettingsInterface, AccountStatus } from '@src/interfaces/settings'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import {
  OnboardingChecklistSectionConfig,
  onboardingCheckpointsOrder,
  onboardingCheckpointsSectionConfig,
} from '@src/pages/OnboardingChecklist/common/constants'
import {
  OnboardingCheckpointSelectorInterface,
  TenantOnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklist'
import { getIconProps } from '@src/pages/Hub/utils'
import { OnboardingChecklistStateBadge } from '@src/pages/OnboardingChecklist/OnboardingChecklistLandingPage/OnboardingChecklistSection'
import SideBar from '@src/components/SideBar/SideBar'

export const getAccountStatusColor = (status: AccountStatus) => {
  switch (status) {
    case 'active':
      return Token.color.success
    case 'closed':
      return Token.color.greyTone50
    case 'pending':
    case 'preparing':
    case 'waiting_list':
      return Token.color.warning
    case 'setup_failed':
    case 'suspended':
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

export const getAccountStatusTitle = (status: AccountStatus) =>
  upperFirst(status).replace(/_/g, ' ')

export const formatAdminName = (data: AccountsSettingsInterface) =>
  `${data.admin.first_name} ${
    data.admin.middle_name ? `${data.admin.middle_name} ` : ''
  } ${data.admin.last_name}`

export const useTenantFeatures = (enabled: boolean = true) => {
  const { data: features = [], isLoading } = useGetSelectors<IdAndName<string>>(
    enabled ? selectorKeys.account_features : null,
  )
  const sections = groupBy(features, 'category.name')

  return { features, isLoading, sections }
}

interface TenantFeaturesCheckboxGroupProps {
  onChange: (value: string[]) => void
  value: string[]
}

export const TenantFeaturesCheckboxGroup = ({
  onChange,
  value,
}: TenantFeaturesCheckboxGroupProps) => {
  const { sections, isLoading } = useTenantFeatures()

  if (isLoading) {
    return (
      <>
        <SubheaderSkeleton>
          <SubheaderSkeleton.Title />
        </SubheaderSkeleton>
        <Group>
          {times(
            index => (
              <ItemSkeleton
                useIcon={<Skeleton size={24} radius={Token.radius.r8} />}
                key={index}
              >
                <ItemSkeleton.Content>
                  <ItemSkeleton.Title />
                </ItemSkeleton.Content>
              </ItemSkeleton>
            ),
            3,
          )}
        </Group>
      </>
    )
  }

  return (
    <CheckboxGroup<string> onChange={onChange} value={value}>
      {group => (
        <>
          {Object.entries(sections).map(([section, options]) => (
            <Box mb="s-16" key={section}>
              <Subheader>
                <Subheader.Title>{capitalize(section)}</Subheader.Title>
              </Subheader>

              <Group>
                {options.map(option => (
                  <Item use="label" key={option.id}>
                    <Item.Prefix>
                      <Checkbox
                        {...group.getInputProps({ value: option.id })}
                        aria-labelledby="option-title"
                      />
                    </Item.Prefix>
                    <Item.Content>
                      <Item.Title id="option-title">{option.name}</Item.Title>
                    </Item.Content>
                  </Item>
                ))}
              </Group>
            </Box>
          ))}
        </>
      )}
    </CheckboxGroup>
  )
}

interface TenantOnboardingChecklistSectionProps {
  title: string
  config: OnboardingChecklistSectionConfig[]
  progress?: TenantOnboardingCheckpointInterface[]
}

const TenantOnboardingChecklistSection = ({
  title,
  config,
  progress,
}: TenantOnboardingChecklistSectionProps) => {
  const sortedSections = config.sort(
    (a, b) =>
      onboardingCheckpointsOrder.findIndex(c => c.category === a.category) -
      onboardingCheckpointsOrder.findIndex(c => c.category === b.category),
  )

  return (
    <Box>
      <Subheader>
        <Subheader.Title>{title}</Subheader.Title>
      </Subheader>
      <VStack gap="s-16">
        {sortedSections.map(c => {
          const checkpoint = progress?.find(p => p.category === c.category)
          return (
            <Item key={c.category}>
              <Item.Avatar>
                {c.customIcon ? c.customIcon : null}
                {c.icon ? <Avatar useIcon={c.icon} /> : null}
                {(c.image && c.bg) || c.customImage ? (
                  <Avatar
                    variant="brand"
                    {...(c.image && c.bg && getIconProps(c.image, c.bg, 40))}
                    size={40}
                    {...c.customImage}
                  >
                    <OnboardingChecklistStateBadge state={checkpoint?.state} />
                  </Avatar>
                ) : null}
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{c.title}</Item.Title>
                {c.description ? (
                  <Item.Description>{c.description}</Item.Description>
                ) : null}
              </Item.Content>
            </Item>
          )
        })}
      </VStack>
    </Box>
  )
}

interface TenantOnboardingSidebarProps {
  progress?: TenantOnboardingCheckpointInterface[]
  open: boolean
  onClose: () => void
}

export const TenantOnboardingSidebar = ({
  progress,
  open,
  onClose,
}: TenantOnboardingSidebarProps) => {
  return (
    <Portal>
      <SideBar title="Onboarding progress" onClose={onClose} isOpen={open} variant="wide">
        <VStack space="s-16">
          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.initialSetup.title}
            config={onboardingCheckpointsSectionConfig.initialSetup.config}
            progress={progress}
          />

          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.organisation.title}
            config={onboardingCheckpointsSectionConfig.organisation.config}
            progress={progress}
          />

          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.people.title}
            config={onboardingCheckpointsSectionConfig.people.config}
            progress={progress}
          />

          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.performance.title}
            config={onboardingCheckpointsSectionConfig.performance.config}
            progress={progress}
          />

          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.recruitment.title}
            config={onboardingCheckpointsSectionConfig.recruitment.config}
            progress={progress}
          />

          <TenantOnboardingChecklistSection
            title={onboardingCheckpointsSectionConfig.finish.title}
            config={onboardingCheckpointsSectionConfig.finish.config}
            progress={progress}
          />
        </VStack>
      </SideBar>
    </Portal>
  )
}

interface TenantOnboardingProgressProps {
  onSelectProgress: (progress?: TenantOnboardingCheckpointInterface[]) => void
  checkpointsSelector?: OnboardingCheckpointSelectorInterface[]
  progress?: TenantOnboardingCheckpointInterface[]
}

export const TenantOnboardingProgress = ({
  onSelectProgress,
  checkpointsSelector,
  progress,
}: TenantOnboardingProgressProps) => {
  if (!checkpointsSelector) {
    return <TextSkeleton minHeight={20} minWidth={80} />
  }

  if (!progress) {
    return <>-</>
  }

  const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    onSelectProgress(progress)
  }

  const finishStepCompleted =
    progress.find(checkpoint => checkpoint.category === 'finish')?.state === 'completed'

  if (finishStepCompleted) {
    return (
      <TextButton onClick={onClick} color={Token.color.success}>
        Completed
      </TextButton>
    )
  }

  const completedSteps = progress.filter(
    checkpoint => checkpoint.state === 'completed',
  ).length

  return (
    <TextButton onClick={onClick}>
      {completedSteps}/{checkpointsSelector.length}
    </TextButton>
  )
}
