import React from 'react'
import { Text, Color } from '@revolut/ui-kit'

type Props = {
  passed?: boolean
}

const OnlineTestResultStatus = ({ passed }: Props) => {
  switch (passed) {
    case true:
      return <Text color={Color.GREEN}>Pass</Text>

    case false:
      return <Text color={Color.RED}>Fail</Text>

    default:
      return <Text color={Color.GREY_TONE_20}>Awaiting assessment</Text>
  }
}

export default OnlineTestResultStatus
