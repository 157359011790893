import React from 'react'
import { Button, Grid, useStatusPopup, StatusPopup } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AccountsSettingsInterface } from '@src/interfaces/settings'
import { TenantFeaturesCheckboxGroup, useTenantFeatures } from '../common'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const Features = () => {
  const { initialValues, values, reset, dirty, submit } =
    useLapeContext<AccountsSettingsInterface>()

  const statusPopup = useStatusPopup()

  const { features } = useTenantFeatures()

  const onCancel = () => {
    reset(initialValues as AccountsSettingsInterface)
  }

  const onConfirm = () => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Updating account features</StatusPopup.Title>
        <StatusPopup.Description>
          This should take a couple of seconds
        </StatusPopup.Description>
      </StatusPopup>,
    )

    submit()
      .then(() => {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Success</StatusPopup.Title>
            <StatusPopup.Description>
              Account features for {values.company_name} updated
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
      .catch(error => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to update account features</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <>
      <PageBody>
        <TenantFeaturesCheckboxGroup
          onChange={value => {
            values.features = value
              .map(feature => features.find(f => f.id === feature))
              .filter(Boolean)
          }}
          value={values.features.map(f => f.id)}
        />
      </PageBody>

      {dirty ? (
        <PageActions>
          <Grid columns={2} gap="s-8" maxWidth={375}>
            <Button onClick={onCancel} variant="secondary">
              Cancel
            </Button>
            <Button onClick={onConfirm}>Confirm</Button>
          </Grid>
        </PageActions>
      ) : null}
    </>
  )
}
