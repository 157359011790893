import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { Color } from '@revolut/ui-kit'

export enum MeetingTrackerCategory {
  ad_hoc = 'ad_hoc',
  daily = 'daily',
  business_review = 'business_review',
  product_review = 'product_review',
  project_review = 'project_review',
  one_to_one = 'one_to_one',
}

export const MeetingTrackerCategoryTitles = {
  [MeetingTrackerCategory.ad_hoc]: 'Ad-hoc',
  [MeetingTrackerCategory.daily]: 'Daily stand up',
  [MeetingTrackerCategory.business_review]: 'Business review',
  [MeetingTrackerCategory.product_review]: 'Product review',
  [MeetingTrackerCategory.project_review]: 'Project review',
  [MeetingTrackerCategory.one_to_one]: '1:1',
}

export const MeetingTrackerCategoryColors = {
  [MeetingTrackerCategory.ad_hoc]: Color.ORANGE,
  [MeetingTrackerCategory.daily]: Color.BLUE,
  [MeetingTrackerCategory.business_review]: Color.RED,
  [MeetingTrackerCategory.product_review]: Color.PURPLE,
  [MeetingTrackerCategory.project_review]: Color.GREEN,
  [MeetingTrackerCategory.one_to_one]: Color.GREY_TONE_50,
}

export interface MeetingTrackerInterface {
  agenda: string
  attached_notes: string[]
  attendees: EmployeeOptionInterface[]
  category: MeetingTrackerCategory | null
  duration: number
  dates?: string[]
  description: string
  end: string
  feedback_description: string
  feedback_event_link: string
  feedback_link: string
  feedback_title: string
  id?: number
  link: string
  object_id: number
  organizer: EmployeeOptionInterface
  can_edit_category?: boolean
  can_rate_meeting?: boolean
  parent_event_id?: string
  rating: number | null
  recurrence: string
  start: string
  title: string
  cost: number | null
}

export enum meetingRecurrence {
  Daily = 'Daily',
  Weekly = 'Weekly',
  BiWeekly = 'Bi-Weekly',
  Monthly = 'Monthly',
  Single = 'Single event',
  Custom = 'Custom',
}

export enum rruleRecurrence {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum rruleWeekday {
  MO = 'MO',
  TU = 'TU',
  WE = 'WE',
  TH = 'TH',
  FR = 'FR',
}

export interface rruleInterface {
  FREQ?: rruleRecurrence
  BYDAY?: rruleWeekday[]
  INTERVAL?: string
}

export enum MeetingTrackerEntity {
  employee = 'employee',
  team = 'team',
  department = 'department',
}

export enum MeetingQuality {
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
}
