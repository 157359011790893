import React from 'react'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Group, Item, Avatar, ActionButton, Box } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import {
  hiringProcessEligibleInterviewsLinkColumn,
  hiringProcessPlaybookColumn,
  hiringProcessScorecardLinkColumn,
  hiringProcessSkillsColumn,
  hiringProcessTitleWithLockColumn,
} from '@src/constants/columns/hiringProcess'
import { TableNames } from '@src/constants/table'
import { useQuery } from '@src/utils/queryParamsHooks'
import { Queries } from '@src/constants/api'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useIsNewTable } from '@components/TableV2/hooks'

export const HiringProcessRow: RowInterface<HiringProcessInterface> = {
  cells: [
    {
      ...hiringProcessTitleWithLockColumn,
      width: 188,
    },
    {
      ...hiringProcessSkillsColumn,
      width: 144,
    },
    {
      ...hiringProcessPlaybookColumn,
      width: 100,
    },
    {
      ...hiringProcessScorecardLinkColumn,
      width: 134,
    },
    {
      ...hiringProcessEligibleInterviewsLinkColumn,
      width: 154,
    },
  ],
}

export const HiringProcessWidget = () => {
  const isNewTable = useIsNewTable()
  const { values } = useLapeContext<SpecialisationInterface>()
  const { changeQueryParam } = useQuery()
  const handleRowEdit = (data: HiringProcessInterface) => {
    if (data.id) {
      changeQueryParam(Queries.StageId, `${data.id}`)
    }
  }
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="TurboTransfer" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Hiring process</Item.Title>
          <Item.Description>
            Process to evaluate candidates for this specialisation
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.SPECIALISATIONS.HIRING_PROCESS, {
                  id: values.id,
                }),
              )
            }
          >
            Edit
          </ActionButton>
        </Item.Side>
      </Item>
      <Box px={isNewTable ? 0 : 's-16'} pb={isNewTable ? 0 : 's-16'}>
        <AdjustableTable<HiringProcessInterface>
          name={TableNames.SpecialisationHiringProcess}
          dataType="Hiring stage"
          row={HiringProcessRow}
          data={values?.hiring_process_rounds}
          count={values?.hiring_process_rounds?.length || 0}
          onRowClick={handleRowEdit}
          noDataMessage="Hiring stages will appear here."
        />
      </Box>
    </Group>
  )
}
