import { Button } from '@src/pages/Landing/components/Button'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import React from 'react'

export const BookDemoButton = () => {
  const { sendAnalyticsEvent } = useAnalytics()

  return (
    <Button
      variant="primary"
      use={InternalLink}
      to={pathToUrl(ROUTES.CONTACT_US)}
      onClick={() => {
        sendAnalyticsEvent(AnalyticsEvents.click_book_a_demo)
      }}
    >
      Book a demo
    </Button>
  )
}
