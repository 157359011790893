import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { connect } from 'lape'
import { InputGroup } from '@revolut/ui-kit'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { PageBody } from '@src/components/Page/PageBody'
import { AnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { CustomOption, OptionInterface } from './components/CustomOption'

export interface EntityData {
  entityId: number
  entityName: string
  entityType: EntityTypes
  goalId?: number
}

const variantContentMap = {
  looker: {
    content_url: {
      label: 'Looker URL',
      description: 'For example: https://revolut.cloud.looker.com/dashboards/24434',
    },
  },
  tableau: {
    content_url: {
      label: 'Tableau URL',
      description:
        'For example: https://test.tableau.com/#/site/dummy-site/views/DummyView/DummyView',
    },
  },
} as const

export const DashboardForm = connect(
  ({ variant = 'looker' }: { variant?: 'looker' | 'tableau' }) => {
    const featureFlags = useSelector(selectFeatureFlags)
    const location = useLocation<EntityData>()
    const user = useSelector(selectUser)

    const { values } = useLapeContext<AnalyticsDashboardInterface>()
    const canAddFolderAndModel = !featureFlags.includes(FeatureFlags.CommercialProduct)

    useEffect(() => {
      if (!values.owner) {
        values.owner = { id: user.id, full_name: user.full_name }
      }

      if (!values.id && location.state?.entityType === EntityTypes.department) {
        values.related_departments = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }

      if (
        !values.id &&
        (location.state?.entityType === EntityTypes.employees ||
          location.state?.entityType === EntityTypes.employee)
      ) {
        values.related_employees = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }

      if (
        !values.id &&
        (location.state?.entityType === EntityTypes.teams ||
          location.state?.entityType === EntityTypes.team)
      ) {
        values.related_teams = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }

      if (
        !values.id &&
        (location.state?.entityType === EntityTypes.roles ||
          location.state?.entityType === EntityTypes.role)
      ) {
        values.related_roles = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }

      if (
        !values.id &&
        (location.state?.entityType === EntityTypes.specialisations ||
          location.state?.entityType === EntityTypes.specialisation)
      ) {
        values.related_specialisations = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }
      if (
        !values.id &&
        (location.state?.entityType === EntityTypes.functions ||
          location.state?.entityType === EntityTypes.function)
      ) {
        values.related_functions = [
          { id: location.state.entityId, name: location.state.entityName },
        ]
      }

      if (!values.id && location.state?.goalId) {
        values.related_goals = [{ id: location.state?.goalId }]
      }
    }, [])

    return (
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Dashboard details" />
          <InputGroup>
            <LapeNewInput
              label={variantContentMap[variant].content_url.label}
              description={variantContentMap[variant].content_url.description}
              name="content_url"
              required
            />
            <LapeNewInput
              label="Dashboard display name"
              message="Dashboard name will be visible under analytics tab for selected viewers"
              name="name"
              required
            />
            <LapeNewMultiSelect<OptionInterface>
              matchSorterKeys={[
                'label',
                'value.id',
                'value.department.name',
                'value.team.name',
              ]}
              message="Select the metrics that are related to this dashboard"
              name="related_kpis"
              placeholder="Link related metrics"
              selector={selectorKeys.organisational_kpis}
            >
              {option => <CustomOption label={option.label} value={option.value} />}
            </LapeNewMultiSelect>
            <LapeNewTextArea
              label="Description"
              message="Describe main goal of the dashboard"
              name="description"
              rows={3}
            />
            {canAddFolderAndModel && variant === 'looker' && (
              <>
                <LapeRadioSelectInput
                  label="Folder Access needed"
                  message="Provide the folder which a user needs access to access this dashboard"
                  name="folder"
                  optional
                  selector={selectorKeys.looker_folders}
                />
                <LapeNewMultiSelect
                  message="Provide the models which a user needs access to access this dashboard"
                  name="lookml_models"
                  placeholder="Model Access needed"
                  selector={selectorKeys.looker_lookml_models}
                />
              </>
            )}
            <LapeRadioSelectInput
              label="Owner"
              name="owner"
              selector={selectorKeys.employee}
              required
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>
    )
  },
)
