import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { EmployeeEmails } from '@src/interfaces/selectors'

export const useOptions = () => {
  const { data: employees = [] } = useGetSelectors<EmployeeEmails>(
    selectorKeys.employee_emails,
  )
  const { data: policies = [] } = useGetSelectors<IdAndName<string>>(
    selectorKeys.active_time_off_policies,
  )

  return { employees, policies }
}
