import * as React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { NewSubmitDialog } from '@src/features/Popups/NewSubmitDialog'
import { Box, Text } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

export interface DescriptionDialogPropsInterface {
  isOpened?: boolean
  onClose: () => void
  loading?: boolean
  onSubmit: () => void
  data: {
    title: string
    description?: string
    placeholder: string
    fieldName: string
    useConfirmationDialog?: boolean
  }
}

const LapeDescriptionDialog = ({
  isOpened = true,
  onClose,
  onSubmit,
  loading,
  data,
}: DescriptionDialogPropsInterface) => {
  const { values, reset } = useLapeContext()

  const handleClose = () => {
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    values[data.fieldName] = undefined
    reset(values)
    onClose()
  }

  if (data.useConfirmationDialog) {
    return (
      <ConfirmationDialog
        open={isOpened}
        onClose={onClose}
        onConfirm={onSubmit}
        onReject={onClose}
        loading={loading}
        label={data.title}
        yesMessage="Confirm"
        noMessage="Go back"
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        submitDisabled={!values[data.fieldName]}
        body={
          <>
            <Text use="p" variant="caption" color="grey-tone-50">
              {data.description}
            </Text>
            <Box mt="s-16">
              <LapeNewInput required name={data.fieldName} label={data.placeholder} />
            </Box>
          </>
        }
      />
    )
  }

  return isOpened ? (
    <NewSubmitDialog
      loading={loading}
      onSubmit={onSubmit}
      title={data.title}
      open
      onClose={handleClose}
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      disabled={!values[data.fieldName]}
    >
      <LapeNewTextArea name={data.fieldName} required rows={2} label={data.placeholder} />
    </NewSubmitDialog>
  ) : null
}

export default connect(LapeDescriptionDialog)
