import React, { useState } from 'react'
import { MoreBar, StatusPopup } from '@revolut/ui-kit'
import { addPromotionComment, cancelPromotionNomination } from '@src/api/promotions'
import PopupWithComment from '@src/pages/Forms/PromotionNominationForm/common/PopupWithComment'
import { CommentsAPIInterface } from '@src/interfaces/chat'

interface Props {
  nominationId: number
  employeeId: number
  onSuccess: () => void
  api: CommentsAPIInterface
}

const CancelButton = ({ nominationId, employeeId, onSuccess, api }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { refetch } = api.useGetComments(true)

  const cancel = async (comment: string) => {
    setIsSubmitting(true)
    try {
      await cancelPromotionNomination(employeeId, nominationId)
      if (comment.length) {
        await addPromotionComment(nominationId, comment)
      }
      onSuccess()
      setShowSuccessPopup(true)
    } finally {
      refetch()
      setIsSubmitting(false)
      setIsPopupOpen(false)
    }
  }

  const onSuccessPopupClose = () => {
    setShowSuccessPopup(false)
  }
  return (
    <>
      <MoreBar.Action pending={isSubmitting} onClick={() => setIsPopupOpen(true)}>
        Cancel nomination
      </MoreBar.Action>

      <PopupWithComment
        title="Cancel nomination"
        subtitle="Please comment on the reason for this action. This will be added to the nomination comments."
        isOpen={isPopupOpen}
        onSubmit={cancel}
        onClose={() => setIsPopupOpen(false)}
        isSubmitting={isSubmitting}
      />

      <StatusPopup
        variant="success"
        open={showSuccessPopup}
        onClose={onSuccessPopupClose}
      >
        <StatusPopup.Title>Promotion nomination canceled</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default CancelButton
