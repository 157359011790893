import { PaygroupInterface, PayrollElementInterface } from '@src/interfaces/payroll'

export const TEMP_PREFIX = 'temp_'

export const formatPayrollElementsOnLoad = (payrollElements: PayrollElementInterface[]) =>
  payrollElements
    .map(elt => ({
      ...elt,
      id: elt.id || `${TEMP_PREFIX} ${Math.floor(Math.random() * 1000000)}`,
    }))
    .filter(Boolean) as unknown as PayrollElementInterface[]

export const formatValuesBeforeSubmit = (values: PaygroupInterface) => {
  if (values.payroll_elements?.length) {
    values.payroll_elements = values.payroll_elements
      .map(elt => {
        if (elt.category) {
          if (`${elt.id}`.startsWith(TEMP_PREFIX)) {
            const { id, ...rest } = elt
            return rest
          }
          return elt
        }
        return false
      })
      .filter(Boolean) as unknown as PayrollElementInterface[]
  }
}

export const formatValuesOnLoad = (values: PaygroupInterface) => {
  if (!values.payroll_elements) {
    values.payroll_elements = []
  } else {
    values.payroll_elements = formatPayrollElementsOnLoad(values.payroll_elements)
  }
}
