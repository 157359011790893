import React from 'react'
import { connect } from 'lape'
import ScorecardForm from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/InterviewScorecardTemplateForm'
import { useQuery } from '@src/utils/queryParamsHooks'
import { LOCAL_STORAGE } from '@src/constants/api'
import { FormattedMessage } from 'react-intl'
import { useParams } from 'react-router-dom'
import { useCreateOptionInNewTab } from '@src/utils/useCreateOptionInNewTab'

const General = () => {
  const params = useParams<{ id?: string }>()
  const { query } = useQuery<{ ref?: 'onboarding' | 'fill-scorecard-after-submit' }>()
  const fromOnboarding = query?.ref === 'onboarding'
  const fillScorecardAfterSubmit = query?.ref === 'fill-scorecard-after-submit'
  const { keepOptionIdInLocalStorage, showStatusPopup } = useCreateOptionInNewTab(
    LOCAL_STORAGE.HIRING_STAGE_CREATED_SCORECARD,
  )

  return (
    <ScorecardForm
      noPopup={fromOnboarding || fillScorecardAfterSubmit}
      onSubmit={
        fromOnboarding || fillScorecardAfterSubmit
          ? result => {
              const title = params.id ? (
                <FormattedMessage
                  id="recruitment.interviewScorecardTemplate.onboarding.successUpdate.title"
                  defaultMessage="Scorecard updated"
                />
              ) : (
                <FormattedMessage
                  id="recruitment.interviewScorecardTemplate.onboarding.successCreate.title"
                  defaultMessage="New scorecard created"
                />
              )
              const description = fromOnboarding ? (
                <FormattedMessage
                  id="recruitment.interviewScorecardTemplate.onboarding.success.description"
                  defaultMessage="Please close this tab and return back to the onboarding setup page."
                />
              ) : (
                <FormattedMessage
                  id="recruitment.interviewScorecardTemplate.success.description"
                  defaultMessage="Please close this tab and return back to the stage form."
                />
              )
              keepOptionIdInLocalStorage(result.id)
              showStatusPopup(title, description)
            }
          : undefined
      }
    />
  )
}

export default connect(General)
