import React, { Fragment } from 'react'
import { Box, Skeleton } from '@revolut/ui-kit'

const TextBlocksSkeleton = () => {
  return (
    <Box>
      {new Array(3).fill(0).map((_, i) => (
        <Fragment key={i}>
          <Skeleton mb="s-16" height={16} width={120} />
          <Skeleton mb="s-32" height={120} borderRadius={8} />
        </Fragment>
      ))}
    </Box>
  )
}

export default TextBlocksSkeleton
