import Form from '@src/features/Form/Form'
import React from 'react'
import MetabaseUser from '@src/pages/Forms/AccessRequestsForm/MetabseUser/MetabaseUser'
import { formAccessRequest } from '@src/api/accessRequests'

const MetabaseUserRouter = () => {
  return (
    <Form api={formAccessRequest}>
      <MetabaseUser />
    </Form>
  )
}

export default MetabaseUserRouter
