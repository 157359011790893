import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

export const onCopyFieldNotification = (field: string) => {
  pushNotification({
    duration: SUCCESS_DEFAULT_DURATION,
    type: NotificationTypes.success,
    value: `${field} copied`,
  })
}
