import React from 'react'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import ArchivingCandidateSidebar from '@components/ArchivingCandidateSidebar/ArchivingCandidateSidebar'

type Props = {
  candidate: CandidateInterface
  onClose: () => void
  onLoading: (loading: boolean) => void
  onSuccess: () => void
}

export const CVScreeningArchive = ({
  onClose,
  onLoading,
  candidate,
  onSuccess,
}: Props) => {
  return (
    <ArchivingCandidateSidebar
      candidate={candidate}
      open
      onClose={onClose}
      onLoading={onLoading}
      onAfterArchive={onSuccess}
      noSuccessPopup
      showInvalidPlaceholdersWarning
    />
  )
}
