import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  DeliverableJustificationValue,
  ReviewCategory,
  ReviewDataInterface,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import styled from 'styled-components'
import { postPerformanceReviewForSummary } from '@src/api/performanceReview'
import Icon from '@components/Icon/Icon'
import * as yup from 'yup'
import produce from 'immer'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { overallFeedbackValidation } from '@src/utils/performance'
import { SCROLL_ERROR_HASH } from '@src/constants/performance'
import { MoreBar } from '@revolut/ui-kit'
import PreviousOverallFeedback from '@components/ScorecardGeneral/PreviousOverallFeedback'
import { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import AutoStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLape } from 'lape'
import ProbationEditSummarySidebar from '@src/pages/Forms/Probation/ProbationEditSummarySidebar'

const Container = styled.div`
  width: 100%;
  margin: 0 120px 0 0;
  grid-column-gap: 32px;
  display: grid;
  grid-template-columns: 1fr 566px;
`

const FeedbackWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 596px;
`

const Loader = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
`

const Sticky = styled.div`
  position: sticky;
  top: 48px;
`

const LeftWrapper = styled.div``

export const validator = {
  review_data: yup.object().shape({
    overall_feedback: yup
      .object()
      .shape<any>({
        pros: yup.array().of(yup.string()),
        cons: yup.array().of(yup.string()),
      })
      .test(
        'overall_feedback',
        'Overall feedback is obligatory. You cannot submit review without it.',
        overallFeedbackValidation,
      ),
  }),
}

const Summary = () => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, errors, change } = form
  const state = useLape<{ data?: ReviewDataInterface }>({
    data: undefined,
  })
  const [prosElement, setProsElement] = useState<HTMLTextAreaElement | null>(null)
  const [isOpenSidebar, setIsOpenSidebar] = useState(true)
  const { hash } = useLocation()

  const fetchSummary = async () => {
    const schema = yup.string().url()

    let cleanValues = values

    if (values.category === ReviewCategory.Performance) {
      cleanValues = produce(values, draft => {
        draft.review_data.deliverables!.cards!.map(card =>
          (card?.justifications as DeliverableJustificationValue[])?.map(
            justification => {
              if (
                justification.reference_url &&
                !schema.isValidSync(justification.reference_url)
              ) {
                justification.reference_url = null
              }
              return justification
            },
          ),
        )
      })
    }

    // TODO: this should not be required
    // @ts-ignore
    cleanValues.review_data.deliverables = null

    const result = await postPerformanceReviewForSummary(cleanValues, values.category)
    state.data = result.data
  }

  useEffect(() => {
    fetchSummary()

    if (!values.review_data.overall_feedback) {
      change('review_data.overall_feedback', {
        pros: [],
        cons: [],
      })
    }

    if (values.visible_to_reviewee === null) {
      change('visible_to_reviewee', false)
    }
  }, [])

  useEffect(() => {
    if (hash === SCROLL_ERROR_HASH && errors.review_data?.overall_feedback) {
      prosElement && prosElement.focus()
    }
  }, [hash])

  if (!state.data) {
    return (
      <Loader>
        <Icon type="Spinner" />
      </Loader>
    )
  }

  return (
    <Container>
      <LeftWrapper>
        <Sticky>
          <MoreBar>
            <MoreBar.Action
              onClick={() => {
                setIsOpenSidebar(true)
              }}
            >
              Open summary sidebar
            </MoreBar.Action>
          </MoreBar>
          <FeedbackWrapper>
            <AutoStepper width={628}>
              <AutoStepperTitle title="Overall feedback" variant="letter" />
            </AutoStepper>
            <OverallFeedback
              pros={values.review_data.overall_feedback?.pros}
              cons={values.review_data.overall_feedback?.cons}
              onChangePros={val => {
                change('review_data.overall_feedback.pros', val.split('\n'))
              }}
              onChangeCons={val => {
                change('review_data.overall_feedback.cons', val.split('\n'))
              }}
              onGetProsElement={elem => {
                setProsElement(elem)
              }}
            />
            <PreviousOverallFeedback
              data={values.review_data.previous_reviews_by_cycle}
            />
          </FeedbackWrapper>
        </Sticky>
      </LeftWrapper>
      <ProbationEditSummarySidebar
        data={state.data}
        isOpen={isOpenSidebar}
        onClose={() => {
          setIsOpenSidebar(false)
        }}
      />
    </Container>
  )
}

export default Summary
