import React, { useState } from 'react'
import { Flex, Group, IconButton, Item, Text, Token } from '@revolut/ui-kit'
import { FilterSettingsInterface } from '../../hooks/useTableSettings'
import {
  ReportColumnsTableInterface,
  ReportFilterInterface,
} from '@src/interfaces/dataAnalytics'
import { HeaderCellFilter } from '@components/Table/HeaderCellFilter'
import { FilterType, CellTypes } from '@src/interfaces/data'
import { getColumnAttributes } from '@src/pages/Forms/DataAnalyticsReportViewForm/utils'
import { getReportColumnValues } from '@src/api/dataAnalytics'
import { selectorKeys } from '@src/constants/api'

interface Props {
  setTableSettings: React.Dispatch<React.SetStateAction<FilterSettingsInterface>>
  tableSettings: FilterSettingsInterface
  visibleColumns: ReportColumnsTableInterface[]
  runId?: number
  queryId?: number
}

interface FilterColumnsSettingsItemProps {
  item: ReportFilterInterface
  column: ReportColumnsTableInterface
  onRemove: (item: ReportFilterInterface) => void
  onChange: (item: ReportFilterInterface) => void
  runId?: number
  queryId?: number
}

const FilterColumnsSettingsItem = ({
  item,
  column,
  onRemove,
  onChange,
  runId,
  queryId,
}: FilterColumnsSettingsItemProps) => {
  const [open, setOpen] = useState(false)

  const attributes = getColumnAttributes(column, undefined, item.column_name)
  const defaultValue = item.default_value ? JSON.parse(item.default_value) : undefined

  return (
    <Item>
      <Item.Prefix>
        <IconButton
          aria-label="Remove"
          color={Token.color.red}
          onClick={() => onRemove(item)}
          size={16}
          useIcon="16/MinusCircle"
        />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>{column.alias || column.name}</Item.Title>
      </Item.Content>
      <Item.Side>
        <IconButton
          aria-label="Default filters"
          color={
            defaultValue && defaultValue.length > 0
              ? Token.color.blue
              : Token.color.greyTone20
          }
          size={16}
          useIcon="Filter"
          onClick={() => setOpen(true)}
        />
        {open ? (
          <HeaderCellFilter
            filterType={attributes?.filterType || FilterType.text}
            cellType={attributes?.type || CellTypes.text}
            onChange={e => {
              onChange({ ...item, default_value: JSON.stringify(e) })
              setOpen(false)
            }}
            onClose={() => setOpen(false)}
            open={open}
            selectorsKey={
              attributes?.filterType === FilterType.selector && !!queryId && !!runId
                ? () => getReportColumnValues(queryId, runId, item.column_name)
                : selectorKeys.none
            }
            value={defaultValue}
          />
        ) : null}
      </Item.Side>
    </Item>
  )
}

export const FilterColumnsSettings = ({
  setTableSettings,
  tableSettings,
  visibleColumns,
  runId,
  queryId,
}: Props) => {
  const handleAdd = (item: ReportFilterInterface) => {
    setTableSettings(current => ({
      visible: [...current.visible, item],
      hidden: current.hidden.filter(t => {
        return t !== item
      }),
    }))
  }

  const handleRemove = (item: ReportFilterInterface) => {
    setTableSettings(current => ({
      visible: current.visible.filter(t => {
        return t !== item
      }),
      hidden: [...current.hidden, item],
    }))
  }

  const handleChange = (item: ReportFilterInterface) => {
    setTableSettings(current => ({
      visible: current.visible.map(record =>
        record.column_name === item.column_name ? item : record,
      ),
      hidden: current.hidden,
    }))
  }

  return (
    <Flex flexDirection="column" gap="s-16">
      <Text>Select columns which can be filtered</Text>
      <Group>
        {tableSettings.visible.map(item => {
          const column = visibleColumns.find(vCol => vCol.name === item.column_name)
          if (!column) {
            return null
          }
          return (
            <FilterColumnsSettingsItem
              item={item}
              onRemove={handleRemove}
              onChange={handleChange}
              key={item.column_name}
              column={column}
              runId={runId}
              queryId={queryId}
            />
          )
        })}
      </Group>

      {tableSettings.hidden.length > 0 ? (
        <Text>Select columns below to use for table filtering</Text>
      ) : null}
      <Group>
        {tableSettings.hidden.map(item => {
          const column = visibleColumns.find(vCol => vCol.name === item.column_name)
          if (!column) {
            return null
          }
          return (
            <Item key={item.column_name}>
              <Item.Prefix>
                <IconButton
                  aria-label="Add"
                  color={Token.color.blue}
                  onClick={() => handleAdd(item)}
                  size={16}
                  useIcon="16/PlusCircle"
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title>{column.alias || column.name}</Item.Title>
              </Item.Content>
            </Item>
          )
        })}
      </Group>
    </Flex>
  )
}
