import React, { useRef } from 'react'
import HubAppIcon from '@src/features/HubAppIcon/HubAppIcon'
import { AppsWidget } from '@revolut/ui-kit'
import { HubAppType } from '@src/interfaces/hub'
import GlobalSearchSection from '../GlobalSearchSection/GlobalSearchSection'
import { useIsOverflow } from '@src/hooks/useIsOverflow'

interface GlobalSearchAppResultsProps {
  apps: HubAppType[]
  onItemClick: () => void
  onShowMore: () => void
  limitResults?: boolean
  isCoreNavigation: boolean
}

const GlobalSearchAppResults = ({
  apps,
  onItemClick,
  onShowMore,
  limitResults = true,
  isCoreNavigation,
}: GlobalSearchAppResultsProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const isOverflow = useIsOverflow(ref)

  if (!apps.length) {
    return null
  }

  return (
    <GlobalSearchSection
      name="App"
      showMore={isOverflow ? onShowMore : undefined}
      isCoreNavigation={isCoreNavigation}
    >
      <AppsWidget
        size={60}
        px="s-8"
        ref={ref}
        autoRows={limitResults ? 0 : undefined}
        rowGap={limitResults ? undefined : 's-24'}
        mb={limitResults ? 0 : '-s-24'}
      >
        {apps.map(app => (
          <HubAppIcon app={app} size={60} key={app.id} onClick={onItemClick} />
        ))}
      </AppsWidget>
    </GlobalSearchSection>
  )
}

export default GlobalSearchAppResults
