import React from 'react'
import { EyeHide, EyeShow, LockClosed } from '@revolut/icons'
import {
  Flex,
  Hideable,
  IconButton,
  Provider,
  Text,
  Toggle,
  Token,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { Currency } from '@src/interfaces/selectors'
import { getFormattedMoney } from '@src/pages/Forms/OfferCreation/OfferChecklist'

type SalaryDetailsProps = {
  canView: boolean
  convertedCurrency?: Currency
  convertedSalary?: number
  localCurrency?: Currency
  localSalary?: number
  additionalCommentary?: string
  candidateDeclinedToDisclose?: boolean
}

const SalaryDetails = ({
  canView,
  convertedCurrency,
  convertedSalary,
  localCurrency,
  localSalary,
  additionalCommentary,
  candidateDeclinedToDisclose,
}: SalaryDetailsProps) => {
  const tooltip = useTooltip()
  if (!canView) {
    return (
      <>
        <LockClosed size={16} {...tooltip.getAnchorProps()} />
        <Tooltip {...tooltip.getTargetProps()}>
          You don't have access to this information
        </Tooltip>
      </>
    )
  }
  const getValue = () => {
    if (candidateDeclinedToDisclose) {
      return 'N/A'
    }
    if (convertedSalary) {
      return getFormattedMoney(
        convertedSalary,
        convertedCurrency?.iso_code ?? 'GBP',
        localSalary,
        localCurrency?.iso_code ?? 'GBP',
      )
    }
    return getFormattedMoney(localSalary, localCurrency?.iso_code ?? 'GBP')
  }
  return (
    <Toggle defaultState>
      {toggle => (
        <Provider blurHideable={toggle.state}>
          <Flex flexDirection="column" gap="s-4">
            <Flex alignItems="center" justifyContent="flex-end" gap="s-4">
              <Hideable>
                {blurHideable => (blurHideable ? '-------' : getValue())}
              </Hideable>
              <IconButton
                {...tooltip.getAnchorProps()}
                size={16}
                useIcon={toggle.state ? EyeShow : EyeHide}
                onClick={() => toggle()}
              />
              <Tooltip {...tooltip.getTargetProps()}>
                Click to {toggle.state ? 'show' : 'hide'} salary
              </Tooltip>
            </Flex>
            {!toggle.state && candidateDeclinedToDisclose && (
              <Text variant="caption" color={Token.color.grey50}>
                Candidate declined to provide information
              </Text>
            )}
            {!toggle.state && additionalCommentary && (
              <Text variant="caption" color={Token.color.grey50}>
                {additionalCommentary}
              </Text>
            )}
          </Flex>
        </Provider>
      )}
    </Toggle>
  )
}

export default SalaryDetails
