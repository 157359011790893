import React from 'react'
import { connect } from 'lape'
import { RowInterface } from '@src/interfaces/data'
import { KpiInterface, KpiRelatedDashboardsInterface } from '@src/interfaces/kpis'
import { navigateTo } from '@src/actions/RouterActions'
import {
  kpiRelatedDashboardsActionsColumn,
  kpiRelatedDashboardsNameColumn,
} from '@src/constants/columns/kpi'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

const row: RowInterface<KpiRelatedDashboardsInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD, {
        id,
      }),
    ),
  cells: [
    {
      ...kpiRelatedDashboardsNameColumn,
      width: 250,
    },
    {
      ...kpiRelatedDashboardsActionsColumn,
      width: 50,
    },
  ],
}

export const AnalyticsDashbordsTable = connect(() => {
  const { values } = useLapeContext<KpiInterface>()

  return (
    <AdjustableTable
      count={values.related_dashboards?.length}
      data={values.related_dashboards}
      emptyState={<EmptyTableRaw title="No related dashboards" />}
      hideCount
      name={TableNames.AnalyticsDashboards}
      row={row}
    />
  )
})
