import React from 'react'
import { H5, Text, VStack } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

type Props = {
  open: boolean
  loading: boolean
  onClose: () => void
  onReject: () => void
  onConfirm: () => Promise<void>
  transferToEmployee: EmployeeOptionInterface | undefined
  setTransferToEmployee: (employee: EmployeeOptionInterface) => void
}
export const TransferResponsibilityConfirmation = ({
  open,
  loading,
  onClose,
  onConfirm,
  onReject,
  transferToEmployee,
  setTransferToEmployee,
}: Props) => {
  return (
    <ConfirmationDialog
      showHeader={false}
      open={open}
      onClose={onClose}
      onReject={onReject}
      loading={loading}
      onConfirm={onConfirm}
      body={
        <VStack space="s-16">
          <H5>Select an employee to transfer responsibility assignment</H5>
          <Text>
            New responsibility assignment with copied data will be created for selected
            employee
          </Text>
          <Text>Old assignment will be archived</Text>
          <RadioSelectInput
            selector={selectorKeys.employee}
            label="Transfer to employee"
            value={transferToEmployee}
            onChange={(value: EmployeeOptionInterface | null) => {
              if (value) {
                setTransferToEmployee(value)
              }
            }}
          />
        </VStack>
      }
      yesMessage="Confirm"
      noMessage="Go back"
    />
  )
}
