import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { VStack, Text, Color } from '@revolut/ui-kit'
import isArray from 'lodash/isArray'

import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import {
  departmentBudgetUploadTemplateURL,
  importDepartmentBudget,
} from '@src/api/importData'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { DepartmentBudgetRouteProps } from './common'

export const Upload = ({ name }: DepartmentBudgetRouteProps) => {
  const params = useParams<{ id: string }>()
  const [importPending, setImportPending] = useState<boolean>()

  const onFileUpload = (file: File) => {
    setImportPending(true)

    importDepartmentBudget(params.id, file)
      .then(response => {
        navigateTo(
          pathToUrl(ROUTES.FORMS.IMPORT_DATA.DEPARTMENT_BUDGET.REVIEW_DATA, {
            id: params.id,
            sessionId: response.data.id,
          }),
        )
      })
      .catch(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    window.open(departmentBudgetUploadTemplateURL, '_blank')
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Import budget data"
        backUrl={ROUTES.FORMS.COMPANY.COMPENSATION}
        subtitle={
          <VStack>
            <Text
              variant="primary"
              color={Color.FOREGROUND}
              display="inline-block"
              py="s-4"
            >
              {name}
            </Text>
            <Text>
              Please upload CSV/XLS attachment to successfully upload compensation data to
              the system.
            </Text>
          </VStack>
        }
        mb="28px"
      />
      <PageBody>
        <ImportFile
          importPending={importPending}
          onDownloadTemplate={onDownloadTemplate}
          supportedFormats={['CSV', 'XLS']}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </PageBody>
    </PageWrapper>
  )
}
