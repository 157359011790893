import React, { useEffect } from 'react'
import { InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PermissionManagementInterface } from '@src/interfaces/accessManagement'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useGetAccessManagementSettings } from '@src/api/settings'

type Props = {
  isNarrow?: boolean
}

export const AssignUserToPermissionInputs = ({ isNarrow }: Props) => {
  const { values } = useLapeContext<PermissionManagementInterface>()
  const { data: settings } = useGetAccessManagementSettings()

  useEffect(() => {
    if (!values.start_date_time) {
      values.start_date_time = new Date().toISOString()
    }
    if (!values.end_date_time) {
      values.end_date_time = null
    }
  }, [])

  return (
    <InputGroup>
      <LapeRadioSelectInput
        name="permission"
        label="Permission"
        selector={selectorKeys.permissions}
      />
      {settings?.enable_limited_time_access ? (
        <InputGroup variant={isNarrow ? 'vertical' : 'horizontal'}>
          <LapeDatePickerInput
            disabledDays={{ before: new Date() }}
            defaultValue={new Date()}
            required
            name="start_date_time"
            label="From Date"
          />
          <LapeDatePickerInput
            disabledDays={
              values.start_date_time
                ? { before: new Date(values.start_date_time) }
                : undefined
            }
            required
            name="end_date_time"
            label="To Date"
          />
        </InputGroup>
      ) : null}
      <LapeNewTextArea name="reason" label="Notes" rows={2} required />
    </InputGroup>
  )
}
