import React, { useEffect, useState } from 'react'
import startCase from 'lodash/startCase'

import { Color, Flex, HStack, Icon, IconName, TabBar, Text, Token } from '@revolut/ui-kit'
import Tooltip, { placement } from '@components/Tooltip/Tooltip'

export interface TabSwitcherOptions<Tab extends string = string> {
  tabs?: Tab[]
  useIcons?: { [tabName: string]: IconName }
  defaultTab?: Tab
  highlightSelected?: boolean
  capitalize?: boolean
  onTabChange?: (tab: Tab) => void
  tooltips?: Partial<{ [key in Tab]: { placement: placement; text: string } }>
  isFit?: boolean
}

const useTabBarSwitcher = <Tab extends string = string>({
  tabs,
  useIcons,
  defaultTab,
  highlightSelected = true,
  capitalize = false,
  onTabChange,
  tooltips,
  isFit,
}: TabSwitcherOptions<Tab>) => {
  const [currentTab, setCurrentTab] = useState<Tab | undefined>(defaultTab || tabs?.[0])
  const colorWhenSelected: Color = highlightSelected
    ? 'light-blue'
    : Token.color.foreground

  useEffect(() => {
    if (defaultTab) {
      setCurrentTab(defaultTab)
    }
  }, [defaultTab])

  const tabBar = tabs ? (
    <TabBar variant={isFit ? 'segmented fit' : 'segmented'} behaviour="scroll">
      {tabs.map(tab => {
        const isSelected = tab === currentTab
        const color: Color = isSelected ? colorWhenSelected : 'grey-tone-50'
        const icon = useIcons?.[tab] ? (
          <Icon name={useIcons[tab]} size={14} color={color} />
        ) : null
        return (
          <TabBar.Item
            key={tab}
            aria-selected={isSelected}
            aria-label={`Tab bar ${tab}`}
            data-testid={`tab_bar_${tab}`}
            onClick={() => {
              onTabChange?.(tab)
              setCurrentTab(tab)
            }}
          >
            <Flex px="18px">
              {icon || (
                <HStack space="s-8">
                  <Text color={color}>{capitalize ? startCase(tab) : tab}</Text>
                  {tooltips?.[tab] && (
                    <Tooltip
                      placement={tooltips[tab]?.placement || 'top'}
                      text={tooltips[tab]?.text}
                    >
                      <Icon name="InfoOutline" size={15} color={Token.color.greyTone50} />
                    </Tooltip>
                  )}
                </HStack>
              )}
            </Flex>
          </TabBar.Item>
        )
      })}
    </TabBar>
  ) : null

  return { tabBar, currentTab, setCurrentTab }
}

export default useTabBarSwitcher
