import React, { useState } from 'react'
import { Button, Cell, Checkbox, Header, Popup, VStack } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export type BulkRequestOptionsQueryParams = {
  esignature?: 'true'
  request?: 'true'
}

export const isESignatureTemplatesEnabled = (query: BulkRequestOptionsQueryParams) =>
  query.esignature === 'true'
export const isRequestTemplatesEnabled = (query: BulkRequestOptionsQueryParams) =>
  query.request === 'true'

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const SelectBulkRequestOptionsPopup = ({ isOpen, onClose }: Props) => {
  const [enableRequest, setEnableRequest] = useState(false)
  const [enableESignature, setEnableESignature] = useState(false)

  const queryParams: BulkRequestOptionsQueryParams = {
    ...(enableRequest ? { request: 'true' } : undefined),
    ...(enableESignature ? { esignature: 'true' } : undefined),
  }

  return (
    <Popup open={isOpen} onClose={onClose} variant="bottom-sheet">
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Create a new bulk request</Header.Title>
        <Header.Description>
          Select which types of documents do you want to request
        </Header.Description>
      </Header>
      <VStack space="s-16">
        <Cell>
          <Checkbox
            checked={enableRequest}
            onChange={e => setEnableRequest(e.currentTarget.checked)}
          >
            <Checkbox.Label>Employees will upload documents</Checkbox.Label>
            <Checkbox.Description>e.g. Passports, Proof of Address.</Checkbox.Description>
          </Checkbox>
        </Cell>
        <Cell>
          <Checkbox
            checked={enableESignature}
            onChange={e => setEnableESignature(e.currentTarget.checked)}
          >
            <Checkbox.Label>Employees will sign documents</Checkbox.Label>
            <Checkbox.Description>
              e.g. Contracts, Promotion letters, Offers.
            </Checkbox.Description>
          </Checkbox>
        </Cell>
      </VStack>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          elevated
          disabled={!enableRequest && !enableESignature}
          onClick={() =>
            navigateTo(
              pathToUrl(
                ROUTES.APPS.DOCUMENTS.BULK_REQUEST.EDIT.BASICS,
                undefined,
                queryParams,
              ),
            )
          }
        >
          Next
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
