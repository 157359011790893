import React, { useEffect } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesScorecardSectionInterface } from '@src/interfaces/reviewCycles'
import { InputGroup, Flex, Box, ActionButton } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import { Props } from './AdditionalScorecardsSidebar'
import { useParams } from 'react-router'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { EditScorecardQuestion } from './EditScorecardQuestion'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { pushError } from '@src/store/notifications/actions'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'

export const AdditionalScorecardsSidebarForm = ({ handleClose, refetch }: Props) => {
  const { values } = useLapeContext<ReviewCyclesScorecardSectionInterface>()
  const params = useParams<{ id: string }>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const disabled = !values.title || !values.show_for?.length

  useEffect(() => {
    if (!values.cycle) {
      values.cycle = Number(params.id)
    }
    if (!values.questions) {
      values.questions = []
    }
  }, [])

  const fetchState = useFetchOptions<{ id: string }>(
    selectorKeys.custom_scorecard_sections_show_options,
  )
  const eligibleOptions = performanceSettings?.enable_functional_management
    ? [
        'performance_scorecards',
        'performance_fm_scorecards',
        'performance_lm_scorecards',
        'performance_peer_scorecards',
        'performance_self_scorecards',
      ]
    : [
        'performance_scorecards',
        'performance_lm_scorecards',
        'performance_peer_scorecards',
        'performance_self_scorecards',
      ]
  const filteredOptions = fetchState.options.filter(option =>
    eligibleOptions.includes(option.value.id),
  )
  const { asyncState: fetchAsyncState } = fetchState

  const onClickAddQuestion = () => {
    values.questions.push({ question_type: null, options: [] })
  }

  return (
    <Flex flexDirection="column" justifyContent="space-between" height="100%">
      <InputGroup>
        <LapeNewInput name="title" label="Section title" required />
        <LapeNewInput name="description" label="Section description" />
        <LapeNewMultiSelect
          placeholder="Show for"
          name="show_for"
          required
          options={filteredOptions}
          forceAsyncState={fetchAsyncState}
        />
      </InputGroup>
      {values.questions?.map((_, questionIdx) => (
        <EditScorecardQuestion key={questionIdx} questionIdx={questionIdx} />
      ))}
      <Box>
        <ActionButton
          useIcon="Plus"
          onClick={onClickAddQuestion}
          mt="s-16"
          mb="s-40"
          style={{ lineHeight: '36px' }}
        >
          Add question
        </ActionButton>
      </Box>
      <PageActions>
        <NewSaveButtonWithPopup
          disabled={disabled}
          tooltipText={
            disabled ? 'Please add section title and show for tags' : undefined
          }
          successText={`Additional questions ${values.id ? 'updated' : 'created'}`}
          onSubmitError={error => pushError({ error })}
          onAfterSubmit={() => {
            refetch()
            handleClose()
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </Flex>
  )
}
