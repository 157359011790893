import React from 'react'
import { Text, Widget } from '@revolut/ui-kit'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'

type Props = {
  scorecard?: InterviewFeedbackInterface
}

const Notes = ({ scorecard }: Props) => {
  return (
    <Widget p="s-16" mb="s-32">
      <Text use="div" variant="primary" pb="s-8">
        Notes from interviewer
      </Text>
      <>
        <Text use="div" variant="caption" fontWeight={500}>
          Overall impression:
        </Text>
        <Text use="div" variant="caption" whiteSpace="break-spaces">
          {scorecard?.overall_impressions}
        </Text>
      </>
    </Widget>
  )
}

export default Notes
