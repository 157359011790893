import React from 'react'
import { Contact, abbreviate } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import { EmployeeEventType } from '@src/interfaces/employeeEvents'
import { employeeEventToColor, employeeEventToIcon } from './common'

interface EmployeeEventAvatarProps {
  eventType: IdAndName<EmployeeEventType>
  avatar: string | null
  fullName: string
  showTitle: boolean
  onClick?: () => void
}

export const EmployeeEventAvatar = ({
  eventType,
  avatar,
  fullName,
  showTitle,
  onClick,
}: EmployeeEventAvatarProps) => {
  return (
    <Contact
      onClick={onClick}
      image={avatar}
      label={abbreviate(fullName)}
      progress={1}
      progressColor={employeeEventToColor[eventType.id]}
      style={onClick ? {} : { pointerEvents: 'none' }}
    >
      <Contact.Badge
        useIcon={employeeEventToIcon[eventType.id]}
        bg={employeeEventToColor[eventType.id]}
      />
      {showTitle ? <Contact.Title>{fullName}</Contact.Title> : null}
    </Contact>
  )
}
