import React, { useEffect, useState } from 'react'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { KpiInterface } from '@src/interfaces/kpis'

import { TargetCell } from './TargetCell'
import { useFormObserver } from '../FormObserverProvider'
import { GoalMetricForm } from '../../GoalMetricForm/GoalMetricForm'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { VStack } from '@revolut/ui-kit'

const goalTargetToKpi = (target: GoalKpiDetails): KpiInterface => ({
  ...target,
  status: target.status?.id,
})

export const GoalMetricsItems = ({
  metrics,
  hasDeletePermission,
  onCopy,
  owner,
}: {
  metrics: GoalKpiDetails[]
  hasDeletePermission: boolean
  onCopy: (position: number | undefined) => void
  owner?: EmployeeOptionInterface
}) => {
  const [expandedId, setExpandedId] = useState<number | undefined>(
    metrics.at(-1)?.tempId || metrics.at(-1)?.id,
  )
  const { getFormById } = useFormObserver()

  useEffect(() => {
    // open last item when item is added or deleted
    setExpandedId(metrics.at(-1)?.tempId || metrics.at(-1)?.id)
  }, [metrics.length])

  const { data: performanceSettings } = useGetPerformanceSettings()
  const isMultipleGoalsTargetsEnabled =
    performanceSettings?.enable_multiple_goal_targets_per_cycle

  return (
    <VStack space="s-16">
      {metrics.map((metric, index) => {
        const id = metric.id || metric.tempId
        return (
          <TargetCell
            // when metric is saved on the BE we should check for permission but allow users to delete
            // metrics while on creation phase (not yet submitted, only with tempId)
            allowDelete={(metric.id ? hasDeletePermission : true) && metrics.length > 1}
            target={metric}
            index={index + 1}
            key={id}
            expanded={expandedId === id}
            onToggle={() => setExpandedId(prev => (prev !== id ? id : undefined))}
            onCopy={isMultipleGoalsTargetsEnabled ? () => onCopy(id) : undefined}
          >
            <GoalMetricForm
              owner={owner}
              initialValues={
                getFormById(metric.id || metric.tempId)?.().form.values ||
                goalTargetToKpi(metric)
              }
              initialCycle={
                metric.targets.at(0)?.employee_cycle || metric.targets.at(0)?.review_cycle
              }
            />
          </TargetCell>
        )
      })}
    </VStack>
  )
}
