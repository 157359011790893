import { List, Text, Box, Token } from '@revolut/ui-kit'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { PERFORMANCE_SERVICE_DESK_LINK } from '@src/constants/externalLinks'
import React from 'react'

export const EmptySearchDescription = () => (
  <>
    <Box color={Token.color.greyTone50}>
      <Text>Can't find the epic you are looking for?</Text>
      <List color={Token.color.greyTone50} variant="compact">
        <List.Item>
          1. Make sure your <Text fontWeight="bold">Jira Project</Text> is defined above
        </List.Item>
        <List.Item>
          2. Use the <Text fontWeight="bold">Refresh Now</Text> button to find any
          recently created epics.
        </List.Item>
      </List>
    </Box>
    <HideIfCommercial>
      <Box color={Token.color.greyTone50}>
        <Text>Still having problems?</Text>
        <Text use="p">
          Reach out to us on the{' '}
          <Text
            color={Token.color.blue}
            href={PERFORMANCE_SERVICE_DESK_LINK}
            target="_blank"
            textDecoration="none"
            use="a"
          >
            Performance service desk
          </Text>
          .
        </Text>
      </Box>
    </HideIfCommercial>
  </>
)
