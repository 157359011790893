import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetSeniorityList } from '@src/api/seniority'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { employeeModel } from '@src/features/GrowthPlans/GrowthPlan/models/employeeModel'
import { Statuses } from '@src/interfaces'

interface Props {
  data: Partial<GrowthPlanInterface>
  isLoading: boolean
}

export const useDraftGrowthPlan = (employee: EmployeeInterface): Props => {
  const { data: seniorityList, isLoading } = useGetSeniorityList({
    sortBy: [{ sortBy: 'level', direction: SORT_DIRECTION.DESC }],
  })

  const currentSpecialisation = employeeModel.getCurrentSpecialisation(employee)
  const currentSeniority = employeeModel.getCurrentSeniority(employee)

  const employeeSpecialisation = currentSpecialisation
    ? { id: Number(currentSpecialisation.id), name: currentSpecialisation.name }
    : null
  const nextSeniority =
    employeeModel.getNextSeniority(employee, seniorityList?.results ?? []) ||
    currentSeniority

  const draftGrowthPlan: Partial<GrowthPlanInterface> = {
    employee: { id: employee.id },
    ...(employeeSpecialisation
      ? {
          current_specialisation: employeeSpecialisation,
          target_specialisation: employeeSpecialisation,
        }
      : {}),
    ...(currentSeniority
      ? { current_seniority: { id: currentSeniority.id, name: currentSeniority.name } }
      : {}),
    ...(nextSeniority
      ? {
          target_seniority: {
            id: nextSeniority.id,
            name: nextSeniority.name,
          },
        }
      : {}),
    status: Statuses.draft,
    actions: [],
  }

  return {
    data: draftGrowthPlan,
    isLoading,
  }
}
