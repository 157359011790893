import React from 'react'
import { useGetCompany } from '@src/api/company'
import Loader from '@components/CommonSC/Loader'
import GoalsTable from '@src/pages/Forms/Company/KPI/Goals'

export const Goals = () => {
  const { data, isLoading } = useGetCompany()

  if (isLoading || !data) {
    return <Loader />
  }

  return <GoalsTable data={data} />
}
