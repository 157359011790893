import React from 'react'

import { Color, HorizontalBarChart, Text, VStack, Widget } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { EngagementResultsOpenEndedDistribution } from '@src/interfaces/engagement'
import { getTotalAnswersFromOpenEnded } from '../helpers'

type Props = {
  distribution: EngagementResultsOpenEndedDistribution
}
export const Distribution = ({ distribution }: Props) => {
  const total = getTotalAnswersFromOpenEnded(distribution)

  return (
    <Widget p="s-16" width={440}>
      <VStack space="s-16">
        <Text variant="caption">Distribution</Text>
        <HorizontalBarChart
          data={Object.entries(distribution).map(([key, value]) => ({
            labelLeft: key,
            value: Math.round((value / total) * 100),
            labelRight: `${formatPercentage(value / total, 2)}`,
            color: Color.BLUE,
          }))}
          role="img"
          aria-label="Distribution bar chart"
        />
      </VStack>
    </Widget>
  )
}
