import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { SubtabStatus } from '@src/interfaces/data'
import {
  DocumentSources,
  DocumentStatuses,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { PreTitle } from '@src/features/TabBarNavigation/PreTitle'
import { pathToUrl } from '@src/utils/router'
import { OnboardingSteps, StepInfoInterface } from './interface'

export const getDocumentLink = (document: EmployeeDocumentListItemInterface) => {
  switch (document.source) {
    case DocumentSources.Docusign:
      return navigateTo(
        pathToUrl(ROUTES.ONBOARDING.DOCUMENT_DOCUSIGN, {
          id: document.recipient_id,
          employeeId: document.employee.id,
        }),
      )
    case DocumentSources.Embedded:
      return navigateTo(
        pathToUrl(ROUTES.ONBOARDING.DOCUMENT_EMBEDDED, {
          id: document.recipient_id,
          employeeId: document.employee.id,
        }),
      )
    case DocumentSources.Request:
    case DocumentSources.Upload:
      return navigateTo(
        pathToUrl(ROUTES.ONBOARDING.DOCUMENT_UPLOAD_REQUEST, {
          id: document.recipient_id,
          employeeId: document.employee.id,
        }),
      )
    default:
      return '/'
  }
}

export const getDocumentStatusColor = (status: DocumentStatuses) => {
  switch (status) {
    case DocumentStatuses.pending:
    case DocumentStatuses.pending_upload:
      return Token.color.orange
    case DocumentStatuses.pending_approval:
      return Token.color.blue
    case DocumentStatuses.completed:
      return Token.color.green
    default:
      return Token.color.foreground
  }
}

export const getDocumentStatusToIcon = (status: DocumentStatuses) => {
  switch (status) {
    case DocumentStatuses.pending:
    case DocumentStatuses.pending_upload:
      return '16/StatusClockArrows'
    case DocumentStatuses.pending_approval:
      return 'Bell'
    default:
      return 'Check'
  }
}

export const areAllStepsComplete = (
  isPreviewEnabled: boolean,
  stepsInfo: StepInfoInterface[],
) => {
  if (isPreviewEnabled) {
    return false
  }
  return stepsInfo.every(step =>
    step.type !== OnboardingSteps.summary ? step.isCompleted : true,
  )
}

export const getStatus = (
  isPreviewEnabled: boolean,
  stepsInfo: StepInfoInterface[],
  type: OnboardingSteps,
) => {
  if (isPreviewEnabled) {
    return SubtabStatus.Pending
  }

  const step = stepsInfo.find(current => current.type === type)
  return step?.isCompleted ? SubtabStatus.Completed : SubtabStatus.Pending
}

export const getStepColor = (
  isPreviewEnabled: boolean,
  stepsInfo: StepInfoInterface[],
  type: OnboardingSteps,
) => {
  if (getStatus(isPreviewEnabled, stepsInfo, type) === SubtabStatus.Completed) {
    return Token.color.green
  }

  const step = stepsInfo.find(current => current.type === type)
  if (step?.isFocused) {
    return Token.color.blue
  }
  const indexOfFocussedTab = stepsInfo.findIndex(s => s.isFocused)
  const indexOfTab = stepsInfo.findIndex(s => s.type === type)

  if (indexOfFocussedTab != null && indexOfTab < indexOfFocussedTab) {
    return Token.color.orange
  }

  return Token.color.greyTone20
}

export const getTitle = (title: string) => {
  return <Text>{title}</Text>
}

export const getPreTitle = (
  isPreviewEnabled: boolean,
  stepsInfo: StepInfoInterface[],
  type: OnboardingSteps,
) => {
  const status = getStatus(isPreviewEnabled, stepsInfo, type)

  return <PreTitle complete={status === SubtabStatus.Completed} />
}
