import React from 'react'
import { usePublicGlobalSettings } from '@src/api/settings'

interface HideIfCommercialProps {
  children: React.ReactNode
}

const HideIfCommercial = ({ children }: HideIfCommercialProps) => {
  const {
    settings: { commercial_product_disabled },
  } = usePublicGlobalSettings()

  if (!commercial_product_disabled) {
    return null
  }

  return <>{children}</>
}

export default HideIfCommercial
