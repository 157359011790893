import { ConfigurableField } from '../helpers'

export const getAddFieldButtonProps = (
  entityName: string,
  field: ConfigurableField,
  addedFields: ConfigurableField[],
) => {
  if (entityName === 'contract' && field.column_name === 'subseniority') {
    return {
      disabled: !addedFields.some(f => f.column_name === 'seniority'),
    }
  }
  return { disabled: false }
}
