import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { verifyApiTokenAction } from '@src/store/auth/actions'
import { selectAuthenticated } from '@src/store/auth/selectors'
import { goBack } from '@src/actions/RouterActions'
import PageLoading from '@components/PageLoading/PageLoading'

const LoginRedirect = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectAuthenticated)

  useEffect(() => {
    if (isLoggedIn && window.opener?.location) {
      /** Handle case when user has multiple login tabs opened, he logs into one, then opens the other login tab and tries to login again */
      window.opener.location.reload?.()
      window.close()
    } else if (isLoggedIn) {
      goBack(ROUTES.MAIN)
    } else {
      dispatch(verifyApiTokenAction())
    }
  }, [isLoggedIn])

  return (
    <Box height="100vh" width="100vw">
      <PageLoading />
    </Box>
  )
}

export default LoginRedirect
