import React from 'react'
import { Link, StatusPopup, Text } from '@revolut/ui-kit'
import { CONTACT_PERFORMANCE_TEAM_LINK } from '@src/constants/externalLinks'
import { ERRORS } from '@src/constants/notifications'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'

type Props = {
  open: boolean
  onClose: () => void
  title?: string
}

export const AddReviewErrorPopup = ({ open, onClose, title }: Props) => {
  return (
    <StatusPopup
      open={open}
      // @ts-expect-error
      labelButtonClose="Close error popup"
      onClose={onClose}
      variant="error"
    >
      <StatusPopup.Title>{title || ERRORS.UNKNOWN}</StatusPopup.Title>
      <StatusPopup.Description>
        <Text mb="s-4" use="div">
          This could be due to joining after the start of the quarter, an ongoing
          probation or PIP cycle, or their contract type in their profile.
        </Text>
        <HideIfCommercial>
          <Text mb="s-4" use="div">
            If you think that they should be eligible, please raise a ticket on the{' '}
            <Link href={CONTACT_PERFORMANCE_TEAM_LINK} target="_blank">
              Performance Service Desk
            </Link>
          </Text>
        </HideIfCommercial>
      </StatusPopup.Description>
    </StatusPopup>
  )
}
