import React from 'react'
import { Avatar, Cell, Item, VStack, ItemSkeleton, Token, Switch } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import {
  mapAccountStatusToProps,
  useShowOfficeSuitePermissionsError,
} from '@src/utils/linkedAccounts'
import {
  employeeSettingsRequests,
  useGetEmployeePersonalSettings,
} from '@src/api/employees'
import { Statuses } from '@src/interfaces'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { PRODUCT_NAME } from '@src/constants/product'
import { askMicrosoftEmailPermissions } from '@src/api/officeSuiteProvider'

export const MicrosoftAccountItem = () => {
  const { id: employeeId } = useParams<{ id: string }>()

  const {
    data: settings,
    isLoading: isSettingsLoading,
    refetch: refetchSettings,
  } = useGetEmployeePersonalSettings()

  const hidden = settings?.office_suite_provider !== 'microsoft'

  useShowOfficeSuitePermissionsError()

  if (hidden) {
    return null
  }

  const isEmailIntegrationEnabled = !!settings?.email_integration_enabled
  const { statusText, statusIcon, color } = mapAccountStatusToProps(
    isEmailIntegrationEnabled ? Statuses.linked : Statuses.pending,
  )

  const handleClick = async () => {
    if (isEmailIntegrationEnabled) {
      await employeeSettingsRequests.update(
        {
          ...settings,
          email_integration_enabled: false,
        },
        { id: employeeId },
      )
      refetchSettings()
    } else {
      await askMicrosoftEmailPermissions('linked_accounts')
    }
  }

  if (isSettingsLoading) {
    return <ItemSkeleton />
  }
  return (
    <Cell p={0}>
      <VStack flex="1 0">
        {!isEmailIntegrationEnabled && (
          <ActionWidget
            title="Why do I need to sync mail?"
            text={`To allow sending emails from ${PRODUCT_NAME} and sync replies from candidates to ${PRODUCT_NAME}, please enable this permission`}
            avatarColor={Token.color.greyTone20}
            bg="transparent" // TODO: REVCOR-3046 revisit after ui-kit update
          />
        )}
        <Item
          use="button"
          onClick={handleClick}
          style={{ backgroundColor: 'transparent' }} // TODO: REVCOR-3046 revisit after ui-kit update
        >
          <Item.Avatar>
            <Avatar useIcon="LogoMicrosoft|image">
              <Avatar.Badge useIcon={statusIcon} color="background" bg={color} />
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Microsoft Email</Item.Title>
            <Item.Description color={color}>{statusText}</Item.Description>
          </Item.Content>
          <Item.Side>
            <Switch
              onChange={e => {
                e.stopPropagation()
                handleClick()
              }}
              checked={isEmailIntegrationEnabled}
            />
          </Item.Side>
        </Item>
      </VStack>
    </Cell>
  )
}
