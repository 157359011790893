import React from 'react'

import { Box, VStack } from '@revolut/ui-kit'
import styled from 'styled-components'

import { FAQBlockItem } from './FAQBlockItem'
import { FAQBlockContentItem } from '@src/pages/Landing/components/FAQBlock/types'

const FAQBlockItems = styled(Box)({ listStyle: 'none' })

interface FAQBlockProps {
  items?: FAQBlockContentItem[]
}

export const FAQBlock = ({ items }: FAQBlockProps) => (
  <VStack space={{ all: 's-16', md: 's-24', xxl: 's-32' }}>
    {items && items.length > 0 && (
      <FAQBlockItems use="ul">
        {items.map(item => (
          <FAQBlockItem key={item.title} {...item} />
        ))}
      </FAQBlockItems>
    )}
  </VStack>
)
