import React from 'react'
import { connect } from 'lape'
import { Route, Switch } from 'react-router-dom'
import Form from '@src/features/Form/Form'
import { hirerightScreeningRequests } from '@src/api/screening'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { HirerightScreeningForm } from './General'
import { HirerightScreeningPreview } from './Preview'

const HirerightScreening = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW}>
          <HirerightScreeningPreview />
        </Route>
        <Route exact path={ROUTES.FORMS.SCREENING.HIRERIGHT.GENERAL}>
          <HirerightScreeningForm />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={hirerightScreeningRequests} disableLocalStorageCaching>
    <HirerightScreening />
  </Form>
))
