import {
  BottomSheet,
  Box,
  Button,
  Header,
  TextArea,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import React, { FormEvent } from 'react'
import { obfuscateNonPrintableCharacters } from '@src/utils/string'

interface Props {
  isOpen: boolean
  isPending: boolean
  isDisabled: boolean
  title: string
  description: string
  comment?: string
  confirmLabel: string
  disabledTooltipText: string
  handleConfirm: VoidFunction
  handleCancel: VoidFunction
  handleChangeComment: (value: string) => void
}

export const MarkActionItemPopup = ({
  title,
  description,
  confirmLabel,
  disabledTooltipText,
  isOpen,
  isDisabled,
  isPending,
  comment,
  handleCancel,
  handleConfirm,
  handleChangeComment,
}: Props) => {
  const confirmationDisabledTooltip = useTooltip()

  return (
    <BottomSheet open={isOpen}>
      <Header>
        <Header.Title>{title}</Header.Title>
      </Header>
      <BottomSheet.Description>{description}</BottomSheet.Description>
      <Box pt="s-16">
        <TextArea
          value={comment}
          label="Comment"
          maxRows={5}
          onChange={({ currentTarget }: FormEvent<HTMLTextAreaElement>) =>
            handleChangeComment(obfuscateNonPrintableCharacters(currentTarget.value))
          }
        />
      </Box>
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          pending={isPending}
          aria-disabled={isDisabled}
          onClick={handleConfirm}
          {...confirmationDisabledTooltip.getAnchorProps()}
          style={isDisabled ? { cursor: 'not-allowed' } : {}}
        >
          {confirmLabel}
        </Button>
      </BottomSheet.Actions>
      {isDisabled && (
        <Tooltip {...confirmationDisabledTooltip.getTargetProps()} width={200}>
          {disabledTooltipText}
        </Tooltip>
      )}
    </BottomSheet>
  )
}
