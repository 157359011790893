import React, { PropsWithChildren } from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Color, Text } from '@revolut/ui-kit'
import LazyLoadPlaceholder from '@components/LazyLoadPlaceholder/LazyLoadPlaceholder'

type Props = {
  onReach?: () => void
}

const ExpectingMoreSlotsWidget = ({ children, onReach }: PropsWithChildren<Props>) => {
  return (
    <>
      {onReach && <LazyLoadPlaceholder onReach={onReach} />}
      <ActionWidget
        mt="s-16"
        title="Expecting more slots?"
        avatarColor={Color.GREY_TONE_50}
        text={
          <Text>
            Eligible interviewers either have not marked any slots, or no slots are
            available for the window that you have selected. <br />
            <br />
            To mark slots, interviewers need to create a recurring event in their calendar
            with the string “<Text color={Color.GREEN}>[interview-slot]</Text>” added
            either in the subject or description of the event.
          </Text>
        }
      >
        {children}
      </ActionWidget>
    </>
  )
}

export default ExpectingMoreSlotsWidget
