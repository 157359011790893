import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { CRMInterface } from '@src/interfaces/CRM'
import { ReEngagementStatuses } from '@src/interfaces/interviewTool'
import { selectorKeys } from '../api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getFormattedMoney } from '@src/pages/Forms/OfferCreation/OfferChecklist'
import { TagBar, Tag } from '@revolut/ui-kit'
import { CandidateNameCell } from '@src/constants/columns/candidates'
import { CountryCell } from '@src/components/TableV2/Cells/CountryCell'
import Table from '@src/components/TableV2/Table'

type CRMColumn = ColumnInterface<CRMInterface>

export const crmNameColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'candidate.id',
  dataPoint: 'candidate.full_name',
  sortKey: 'candidate',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
  insert: ({ data }) => {
    return <CandidateNameCell id={data.candidate.id} name={data.candidate.full_name} />
  },
}

export const crmSpecialisationColumn: CRMColumn = {
  type: CellTypes.text,
  idPoint: 'specialisation.name',
  dataPoint: 'specialisation.name',
  sortKey: 'specialisation__name',
  filterKey: 'specialisation',
  selectorsKey: selectorKeys.specialisations,
  title: 'Specialisation',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
      id: data.specialisation?.id,
    }),
}

export const crmSeniorityColumn: CRMColumn = {
  type: CellTypes.text,
  idPoint: 'seniority.name',
  dataPoint: 'seniority.name',
  sortKey: 'seniority__level',
  filterKey: 'seniority',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const crmTagsColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'tags',
  dataPoint: 'tags',
  sortKey: null,
  filterKey: 'candidate__tags',
  selectorsKey: selectorKeys.candidate_tags,
  title: 'Tags',
  notHoverable: true,
  insert: ({ data }) => {
    if (!data.candidate.tags?.length) {
      return null
    }
    return (
      <TagBar>
        {data.candidate.tags.map(tag => (
          <Tag key={tag.id} variant="faded">
            {tag.name}
          </Tag>
        ))}
      </TagBar>
    )
  },
}

export const crmCurrentCompanyColumn: CRMColumn = {
  type: CellTypes.text,
  idPoint: 'candidate.headline',
  dataPoint: 'candidate.headline',
  sortKey: 'candidate.headline',
  filterKey: 'companies',
  filterType: FilterType.selector,
  selectorsKey: selectorKeys.work_experience_companies,
  title: 'Current company',
}

export const crmYearsOfExperienceColumn: CRMColumn = {
  type: CellTypes.text,
  idPoint: 'candidate.years_of_experience',
  dataPoint: 'candidate.years_of_experience',
  sortKey: 'candidate.years_of_experience',
  filterKey: 'candidate__years_of_experience',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Years of experience',
  textAlign: 'right',
}

export const crmPreferredLocationsColumn: CRMColumn = {
  type: CellTypes.text,
  idPoint: 'preferred_location',
  dataPoint: 'preferred_location.name',
  sortKey: 'preferred_location',
  filterKey: 'preferred_location',
  selectorsKey: selectorKeys.location,
  title: 'Preferred locations',
}

export const crmCurrentCountryColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'candidate.country.name',
  dataPoint: 'candidate.country.name',
  sortKey: 'candidate.country.name',
  filterKey: 'candidate__country',
  filterType: FilterType.selector,
  selectorsKey: selectorKeys.countries,
  title: 'Current country',
  insert: ({ data }) => {
    if (data.candidate.country) {
      return <CountryCell country={data.candidate.country} />
    }
    return '-'
  },
}

export const crmStatusColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'state',
  dataPoint: 'state',
  sortKey: 'state',
  filterKey: 'state',
  selectorsKey: selectorKeys.interview_round_states,
  title: 'Status',
  insert: ({ data }) => {
    return <Table.StatusCell variant="neutral" status={data.state} />
  },
}

export const crmArchivalReasonColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'archived_reason',
  dataPoint: 'archived_reason',
  sortKey: 'archived_reason',
  filterKey: 'archived_reason',
  selectorsKey: selectorKeys.interview_round_archived_reasons,
  title: 'Archival reason',
  insert: ({ data }) => (data.archived_reason ? data.archived_reason : '-'),
}

export const crmDesiredSalaryColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'converted_expected_salary',
  dataPoint: 'converted_expected_salary',
  sortKey: 'converted_expected_salary',
  filterKey: 'converted_expected_salary',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Desired salary',
  insert: ({ data }) => {
    if (typeof data.converted_expected_salary !== 'number') {
      return '-'
    }
    return getFormattedMoney(
      data?.converted_expected_salary,
      data?.converted_currency?.iso_code ?? 'GBP',
      data?.local_expected_salary,
      data?.local_currency?.iso_code ?? 'GBP',
    )
  },
  textAlign: 'right',
}

export const crmLastEngagementDateColumn: CRMColumn = {
  type: CellTypes.date,
  idPoint: 'latest_activity_date_time',
  dataPoint: 'latest_activity_date_time',
  sortKey: 'latest_activity_date_time',
  filterKey: 'latest_activity_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Last Engagement date',
}

export const crmArchivalDateColumn: CRMColumn = {
  type: CellTypes.date,
  idPoint: 'archived_date_time',
  dataPoint: 'archived_date_time',
  sortKey: 'archived_date_time',
  filterKey: 'archived_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Archival date',
}

export const crmReEngagementStatusColumn: CRMColumn = {
  type: CellTypes.insert,
  idPoint: 'candidate.re_engagement_status',
  dataPoint: 'candidate.re_engagement_status',
  sortKey: 'candidate__re_engagement_status',
  filterKey: 'candidate__re_engagement_status',
  selectorsKey: selectorKeys.candidate_re_engagement_statuses,
  title: 'Re-engagement status',
  insert: ({ data }) => {
    const status = data.candidate.re_engagement_status
    if (!status) {
      return null
    }
    return (
      <Table.StatusCell
        variant={status === ReEngagementStatuses.not_engaged ? 'neutral' : 'success'}
        status={status}
      />
    )
  },
}

export const crmReEngagementDateColumn: CRMColumn = {
  type: CellTypes.date,
  idPoint: 'candidate.re_engagement_date_time',
  dataPoint: 'candidate.re_engagement_date_time',
  sortKey: 'candidate__re_engagement_date_time',
  filterKey: 'candidate__re_engagement_date_time',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.date,
  title: 'Re-engagement date',
}
