import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'
import { selectorKeys } from '@src/constants/api'

export const sdCategoryNameColumn: ColumnInterface<ServiceDeskCategoryInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const sdCategoryFaqCategoryNameColumn: ColumnInterface<ServiceDeskCategoryInterface> =
  {
    type: CellTypes.text,
    idPoint: 'faq_category.id',
    dataPoint: 'faq_category.name',
    sortKey: 'faq_category__name',
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'FAQ Category Name',
  }

export const sdCategorySubcategoriesCountColumn: ColumnInterface<ServiceDeskCategoryInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'subcategories',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Subcategories',
    insert: ({ data }) => data?.subcategories?.length || 0,
  }

export const sdCategoryLinkColumn: ColumnInterface<ServiceDeskCategoryInterface> = {
  type: CellTypes.link,
  idPoint: 'id',
  dataPoint: 'link',
  sortKey: 'link',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Link',
}
