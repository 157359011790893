import React from 'react'
import { useQueryClient } from 'react-query'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { teamsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import {
  companyPreferencesWithInvalidation,
  organisationSettings,
  useGetCompanyPreferences,
} from '@src/api/settings'
import TeamsIntro from '@src/pages/OnboardingChecklist/Teams/TeamsIntro'
import TeamsOrganisation from '@src/pages/OnboardingChecklist/Teams/TeamsOrganisation'
import TeamsApprovals from '@src/pages/OnboardingChecklist/Teams/TeamsApprovals'
import TeamsSettings from '@src/pages/OnboardingChecklist/Teams/TeamsSettings'
import TeamsManageDepartments from '@src/pages/OnboardingChecklist/Teams/TeamsManageDepartments'
import TeamsManageTeams from '@src/pages/OnboardingChecklist/Teams/TeamsManageTeams'
import PageLoading from '@src/components/PageLoading/PageLoading'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.INTRO,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsIntro,
  },
  {
    title: 'Organisation structure',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.ORGANISATION,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.ORGANISATION,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsOrganisation,
  },
  {
    title: 'Approval process',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.APPROVALS,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.APPROVALS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsApprovals,
    form: {
      api: organisationSettings,
    },
  },
  {
    title: 'Settings',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.SETTINGS,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.SETTINGS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsSettings,
    form: {
      api: organisationSettings,
    },
    enabledWithDepartments: true,
  },
  {
    title: 'Manage Departments',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.MANAGE_DEPARTMENTS,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.MANAGE_DEPARTMENTS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsManageDepartments,
    isWide: true,
    enabledWithDepartments: true,
  },
  {
    title: 'Manage Teams',
    path: ROUTES.ONBOARDING_CHECKLIST.TEAMS.MANAGE_TEAMS,
    url: ROUTES.ONBOARDING_CHECKLIST.TEAMS.MANAGE_TEAMS,
    canView: [PermissionTypes.ViewOrganisationPreferences],
    component: TeamsManageTeams,
    isWide: true,
  },
]

const Teams = () => {
  const queryClient = useQueryClient()
  const { data } = useGetCompanyPreferences()

  if (!data) {
    return <PageLoading />
  }

  const filteredRoutes = routes
    .filter(route => (route.enabledWithDepartments ? data.enable_departments : true))
    .map(route => ({
      ...route,
      ...(route.path === ROUTES.ONBOARDING_CHECKLIST.TEAMS.ORGANISATION
        ? { form: { api: companyPreferencesWithInvalidation(queryClient) } }
        : {}),
    }))

  return <OnboardingChecklistContent config={teamsConfig} routes={filteredRoutes} />
}

export default Teams
