import React from 'react'
import {
  Text,
  TextSkeleton,
  TextWidget,
  TextWidgetSkeleton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import HTMLContent from '@components/HTMLContent/HTMLContent'
import { Video as VideoGenericPlayer } from '@components/Video/Video'
import { JoiningDateDetails } from './JoiningDateDetails'

interface StartPageViewProps {
  joiningDateTime?: string
  isLoading?: boolean
  isLoadingBasicInfo?: boolean
  title?: string | null
  text?: string | null
  name?: string
  videoUrl?: string | null
}

export const StartPageView = ({
  joiningDateTime,
  isLoading,
  isLoadingBasicInfo,
  name,
  title,
  text,
  videoUrl,
}: StartPageViewProps) => {
  if (isLoading) {
    return (
      <VStack gap="s-32" pb="s-24" pt="s-12">
        <TextSkeleton variant="h1" />
        <TextWidgetSkeleton />
        <JoiningDateDetails
          joiningDateTime={joiningDateTime}
          isLoading={isLoadingBasicInfo}
        />
      </VStack>
    )
  }

  return (
    <VStack gap="s-32" pb="s-24" pt="s-12">
      {title && (
        <Text color={Token.color.foreground} variant="h1">
          {title}
        </Text>
      )}
      <VideoGenericPlayer url={videoUrl} />
      {text && (
        <TextWidget>
          {isLoadingBasicInfo ? (
            <TextWidgetSkeleton.Title />
          ) : name ? (
            <TextWidget.Title>Dear {name}</TextWidget.Title>
          ) : null}
          <TextWidget.Content>
            <HTMLContent html={text} />
          </TextWidget.Content>
        </TextWidget>
      )}
      <JoiningDateDetails
        joiningDateTime={joiningDateTime}
        isLoading={isLoadingBasicInfo}
      />
    </VStack>
  )
}
