import { setIn } from 'final-form'
import isNil from 'lodash/isNil'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import * as yup from 'yup'
import { ObjectSchema, ValidationError } from 'yup'
import { OptionInterface } from '../interfaces/selectors'
import { ChangelogApi, ValidatorInterface } from '../interfaces/data'
import transform from 'lodash/transform'
import isEqual from 'lodash/isEqual'
import { FieldMetaState } from 'react-final-form'
import { useQuery } from '@src/utils/queryParamsHooks'
import * as React from 'react'
import { useEffect } from 'react'
import { getHistoryRequest } from '@src/api/changelog'
import HistoryTooltip from '@components/Stepper/HistoryTooltip'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FieldOptions } from '@src/interfaces'
import { useLape } from 'lape'
import { FormError, useLapeContext } from '@src/features/Form/LapeForm'
import mergeWith from 'lodash/mergeWith'
import merge from 'lodash/merge'
import { Box, Token } from '@revolut/ui-kit'
import { placement } from '@components/Tooltip/Tooltip'

export const required = (value: string | number | OptionInterface) =>
  !isNil(value) && value !== '' && value.toString().trim() ? undefined : 'Required'

export const arrayErrorsToFormError = <T,>(errors: any): FormError<T> => {
  if (errors == null || !isObject(errors)) {
    return {}
  }
  return parseFormErrors(errors) as FormError<T>
}

type ParsedFormError = string | ParsedFormError[] | { [key: string]: ParsedFormError }

// backend returns a { field: (object|string)[] | object}, we need to keep the structure, but flatten the bottom level string[] to string
export const parseFormErrors = (errors: any): ParsedFormError => {
  if (isArray(errors)) {
    if (typeof errors[0] === 'string') {
      return errors.join(' and ')
    }
    return errors.map(parseFormErrors)
  }

  if (isObject(errors)) {
    return mapValues(errors as Record<string, any>, parseFormErrors)
  }

  return errors
}

export const errorToFormError = (e: any) => {
  return arrayErrorsToFormError(e?.response?.data)
}

export const tabToSchema = (tab: ValidatorInterface) => {
  return tab?.validator ? yup.object().shape(tab.validator as any) : yup.object()
}

export const validateTabs = async (values: object, tabs: ValidatorInterface[]) => {
  return Promise.all(
    tabs.map(async tab => {
      try {
        await tabToSchema(tab).validate(values, { abortEarly: false })
      } catch (e) {
        return e.inner.reduce((errors: object, error: ValidationError) => {
          return setIn(errors, error.path!, error.message)
        }, {})
      }
      return undefined
    }),
  ).then(errors => errors.filter(a => a).reduce((acc, val) => merge(acc, val), {}))
}

export const validateSync = (values: object, schema: ObjectSchema<any>) => {
  try {
    schema.validateSync(values)
  } catch (e) {
    return e.inner.reduce((errors: object, error: ValidationError) => {
      return setIn(errors, error.path!, error.message)
    }, {})
  }
  return undefined
}

export function difference(object: any, base: any) {
  const deleting = transform(base, (result: object, value, key) => {
    if (value && object[key] === undefined) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      result[key] = null
    }
  })

  const diff = transform(object, (result: object, value, key) => {
    if (!isEqual(value, base[key])) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      result[key] = value
    }
  })

  return merge({}, diff, deleting)
}

export function generateError(
  name: string,
  meta: FieldMetaState<any>,
  error?: string,
): string | undefined {
  if (!meta.touched) {
    return undefined
  }
  return error || meta.error?.replace(name, 'This') || meta.submitError
}

export const useChangelog = (changelogApi?: ChangelogApi) => {
  const { query } = useQuery()

  const state = useLape<{ data?: object; loading: boolean }>({
    data: undefined,
    loading: true,
  })

  useEffect(() => {
    if (!changelogApi) {
      state.loading = false
      return
    }
    if (query.changes) {
      state.loading = true

      fetchChanges()
      return
    }
    if (query.change) {
      state.loading = true

      fetchChange()
      return
    }
    state.data = undefined
    state.loading = false
  }, [query.changes, query.change, changelogApi])

  const fetchChanges = async () => {
    try {
      const result = await changelogApi!.form.getItem(query.changes)

      if (result.data) {
        state.data = result.data.fields_to_change
      }
    } finally {
      state.loading = false
    }
  }

  const fetchChange = async () => {
    try {
      const result = await changelogApi!.field.getItem(query.change)

      if (result.data) {
        state.data = result.data.field_to_change
      }
    } finally {
      state.loading = false
    }
  }

  return state
}

export const useChangelogApi = () => {
  const form = useLapeContext<{ field_options: FieldOptions }>()
  const { query, deleteQueryParam } = useQuery()

  const changelogActive = !!(query.changes || query.change)

  const clearHistory = () => {
    deleteQueryParam('changes')
    deleteQueryParam('change')
    form.changelog = undefined
    form.values = form.initialValues as { field_options: FieldOptions }
    form.disabled = false
  }

  return {
    changelogActive,
    clearHistory,
  }
}

export const useChangelogProps = (name: string) => {
  const { values, changelog, changelogApi } = useLapeContext<{
    field_options: FieldOptions
  }>()
  const { changelogActive } = useChangelogApi()

  const hasChangelog = !!changelog
  const hasChanged = get(changelog, name) !== undefined

  if (hasChangelog && hasChanged && changelogApi) {
    return {
      outlineColor: '#F7930D',
      topInfo: (
        <HistoryTooltip
          color="#F7930D"
          request={getHistoryRequest(values as EmployeeInterface, name, changelogApi)}
        />
      ),
    }
  }
  if (changelogActive && changelogApi) {
    return {
      topInfo: (
        <HistoryTooltip
          color={Token.color.greyTone20}
          request={getHistoryRequest(values as EmployeeInterface, name, changelogApi)}
        />
      ),
    }
  }

  return {}
}

export const useNewChangelogProps = (name: string, tooltipPlacement?: placement) => {
  const { values, changelog, changelogApi } = useLapeContext<{
    field_options: FieldOptions
  }>()
  const { changelogActive } = useChangelogApi()

  const hasChangelog = !!changelog
  const hasChanged = get(changelog, name) !== undefined

  if (hasChangelog && hasChanged && changelogApi) {
    return {
      renderAction: () => (
        <Box mr="s-8">
          <HistoryTooltip
            isNew
            color={Token.color.warning}
            request={getHistoryRequest(values as EmployeeInterface, name, changelogApi)}
            placement={tooltipPlacement}
          />
        </Box>
      ),
    }
  }
  if (changelogActive && changelogApi) {
    return {
      renderAction: () => (
        <Box mr="s-8">
          <HistoryTooltip
            isNew
            color={Token.color.lightBlue_60}
            request={getHistoryRequest(values as EmployeeInterface, name, changelogApi)}
            placement={tooltipPlacement}
          />
        </Box>
      ),
    }
  }

  return {}
}

export const mergeFormValues = <T extends {}>(values: T, changelog?: Partial<T>) => {
  if (!changelog) {
    return values
  }

  function customizer(objValue: any, srcValue: any) {
    if (Array.isArray(objValue) && Array.isArray(srcValue)) {
      return srcValue.map((src, i) => ({
        ...objValue[i],
        ...src,
      }))
    }
    return undefined
  }

  return mergeWith({}, values, changelog, customizer)
}
