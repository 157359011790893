import React from 'react'
import { Flex, IconButton, Text, Token } from '@revolut/ui-kit'
import { formatPeriod } from '@src/utils/format'
import { addWeeks, endOfWeek, formatISO, startOfWeek } from 'date-fns'

export type NavigationWeek = {
  start: string
  end: string
}

type NavigateWeekProps = NavigationWeek & {
  onNavigate: (week: NavigationWeek) => void
}

export const getCurrentWeek = (options?: {
  locale?: Locale
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6
}): NavigationWeek => {
  return {
    start: formatISO(startOfWeek(new Date(), options)),
    end: formatISO(endOfWeek(new Date(), options)),
  }
}

export const NavigateWeek = ({ start, end, onNavigate }: NavigateWeekProps) => {
  const handleChangeWeek = (direction: 'next' | 'previous') => {
    const offset = direction === 'next' ? 1 : -1
    const newStart = formatISO(addWeeks(new Date(start), offset))
    const newEnd = formatISO(addWeeks(new Date(end), offset))
    onNavigate({
      start: newStart,
      end: newEnd,
    })
  }
  return (
    <Flex alignItems="center">
      <IconButton
        aria-label="Navigate to current week"
        useIcon="CalendarDate"
        color={Token.color.greyTone50}
        size={16}
        onClick={() => onNavigate(getCurrentWeek())}
      />
      <Text variant="secondary" fontWeight={500} ml="s-8" mr="s-16" minWidth="170px">
        {formatPeriod(start, end)}
      </Text>
      <Flex alignItems="center" gap="s-16">
        <IconButton
          aria-label="Navigate to previous week"
          color={Token.color.greyTone50}
          useIcon="NavigationBack"
          size={16}
          onClick={() => handleChangeWeek('previous')}
        />
        <IconButton
          aria-label="Navigate to next week"
          color={Token.color.greyTone50}
          useIcon="NavigationForward"
          size={16}
          onClick={() => handleChangeWeek('next')}
        />
      </Flex>
    </Flex>
  )
}
