import React, { useCallback, useState } from 'react'
import { fetchIds } from '@src/api/bulkEdit'
import { FilterByInterface } from '@src/interfaces/data'
import { SelectTableWrapperOnChangeData } from '@src/components/Table/AdvancedCells/SelectCell/SelectTableWrapper'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useTableReturnType } from '@components/Table/hooks'

export const getBulkIds = async <T extends { id: number }>(
  apiEndpoint: string,
  filters: FilterByInterface[],
  tableData: T[],
  selectedData?: SelectTableWrapperOnChangeData<T>,
) => {
  const { data } = await fetchIds(apiEndpoint, filters)

  let bulkIds: number[] = []

  if (selectedData?.selectedRowsIds.size) {
    bulkIds = Array.from(selectedData?.selectedRowsIds).map(id => Number(id))
  } else if (selectedData?.isAllSelected && data?.ids) {
    bulkIds = data.ids.filter(unit => !selectedData.excludeListIds.has(String(unit)))
  } else if (selectedData?.isAllSelected && !data?.ids) {
    bulkIds = tableData
      .filter(unit => !selectedData.excludeListIds.has(String(unit.id)))
      .map(({ id }) => Number(id))
  }

  return { bulkIds, limited: !data.all_returned }
}

export const useFetchBulkIds = <T extends { id: number }>(
  apiEndpoint: string,
  table: useTableReturnType<T, unknown>,
  selectedData?: SelectTableWrapperOnChangeData<T>,
) => {
  const [bulkIds, setBulkIds] = useState<number[]>([])
  const statusPopup = useStatusPopup()

  const fetchBulkIds = useCallback(async () => {
    const { bulkIds: ids, limited } = await getBulkIds<T>(
      apiEndpoint,
      table.filterBy,
      table.data,
      selectedData,
    )

    setBulkIds(ids)

    // an explanation why we have a limit: https://revolut.atlassian.net/browse/PERF-4728
    if (limited) {
      statusPopup.show(
        <StatusPopup variant="warning">
          <StatusPopup.Title>
            Your choice will be limited by {ids.length} items
          </StatusPopup.Title>
          <StatusPopup.Description>
            Currently we have a limit of items that you could perform a bulk action
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button variant="secondary" onClick={() => statusPopup.hide()}>
              Continue
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }
  }, [table.filterBy, table.data, selectedData])

  return { bulkIds, fetchBulkIds }
}
