import React, { FormEvent, useState } from 'react'
import {
  BottomSheet,
  Box,
  Button,
  Header,
  MoreBar,
  TextArea,
  Tooltip,
  useToggle,
  useTooltip,
} from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useRejectGrowthPlan } from '@src/api/growthPlans'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { obfuscateNonPrintableCharacters } from '@src/utils/string'

interface Props {
  growthPlan: GrowthPlanInterface
  onComplete: VoidFunction
}

export const RejectAction = ({ growthPlan, onComplete }: Props) => {
  const { mutateAsync: rejectGrowthPlan, isLoading } = useRejectGrowthPlan(growthPlan.id)

  const [comment, setComment] = useState<string>()
  const [isConfirmationPopupOpened, confirmationPopupToggler] = useToggle()
  const showStatusPopup = useShowStatusPopup()
  const confirmationDisabledTooltip = useTooltip()

  const handleConfirm = async () => {
    try {
      if (!comment) {
        return
      }

      await rejectGrowthPlan({ comment })
      onComplete()
      showStatusPopup({ title: 'Changes were requested successfully' })
    } catch (err) {
      showStatusPopup({
        title: 'Failed to request changes',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    } finally {
      confirmationPopupToggler.off()
      setComment(undefined)
    }
  }

  const handleCancel = () => {
    confirmationPopupToggler.off()
    setComment(undefined)
  }

  return (
    <>
      <MoreBar.Action
        variant="negative"
        useIcon="Cross"
        pending={isLoading}
        onClick={confirmationPopupToggler.on}
      >
        Request changes
      </MoreBar.Action>
      <BottomSheet open={isConfirmationPopupOpened}>
        <Header>
          <Header.Title>Please comment on what needs changing and why</Header.Title>
        </Header>
        <Box pt="s-16">
          <TextArea
            value={comment}
            label="Comment"
            maxRows={3}
            onChange={({ currentTarget }: FormEvent<HTMLTextAreaElement>) =>
              setComment(obfuscateNonPrintableCharacters(currentTarget.value))
            }
          />
        </Box>
        <BottomSheet.Actions horizontal>
          <Button variant="secondary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            pending={isLoading}
            aria-disabled={!comment}
            onClick={handleConfirm}
            {...confirmationDisabledTooltip.getAnchorProps()}
            style={!comment ? { cursor: 'not-allowed' } : {}}
          >
            Request changes
          </Button>
        </BottomSheet.Actions>
      </BottomSheet>
      {!comment && (
        <Tooltip {...confirmationDisabledTooltip.getTargetProps()} width={200}>
          Leaving a comment is mandatory to request changes
        </Tooltip>
      )}
    </>
  )
}
