import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { IndeedPosting } from '@src/interfaces/jobPosting'
import { selectorKeys } from '@src/constants/api'
import { IndeedStatus } from '@src/pages/Forms/JobPosting/IndeedPostings/components/IndeedStatus'

type IndeedPostingColumn = ColumnInterface<IndeedPosting>

export const indeedPostingLocation: IndeedPostingColumn = {
  type: CellTypes.text,
  idPoint: 'location.location_name',
  dataPoint: 'location.location_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Indeed location',
}

export const indeedPostingStatus: IndeedPostingColumn = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: IndeedStatus,
}
