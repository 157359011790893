import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Flex } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { SecuritySettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { finishConfig } from '@src/pages/OnboardingChecklist/common/constants'
import OnboardingActions from '@src/pages/OnboardingChecklist/components/OnboardingActions'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const Settings = () => {
  const { initialValues, dirty } = useLapeContext<SecuritySettingsInterface>()
  const [twoFactorTurnedOn, setTwoFactorTurnedOn] = useState(false)

  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.ChangeSecuritySettings)

  return (
    <>
      <Flex flexDirection="column" gap="s-24">
        <SectionTitle
          title="Do you want to enable 2-factor authentification?"
          subtitle="Your employees will be asked to create a 2-Step Login via SMS messages."
        />
        <LapeBooleanRadioSwitch
          onAfterChange={enableTwoFA => {
            setTwoFactorTurnedOn(
              !initialValues.enable_two_factor_authentication && enableTwoFA,
            )
          }}
          disabled={!canEdit}
          name="enable_two_factor_authentication"
          variant="horizontal"
          yesLabel={
            <Flex>
              Yes
              <Recommended />
            </Flex>
          }
          yesDescription="I want my emloyees to be asked to create 2-Step Login via SMS messages"
          noDescription="I don't want to set up "
        />
        <HideIfCommercial>
          <SectionTitle
            title="Do you want to allow users to log in with Password?"
            subtitle="If active, users can log in with their email address and password."
          />
          <LapeBooleanRadioSwitch
            disabled={!canEdit}
            name="enable_password_authentication"
            variant="horizontal"
            yesLabel={
              <Flex>
                Yes
                <Recommended />
              </Flex>
            }
            yesDescription="I want to enable users to log in with email address and password."
            noDescription="I want users to log in via Google Single Sign on."
          />
        </HideIfCommercial>
      </Flex>

      <OnboardingActions
        confirmationDialogue={
          twoFactorTurnedOn
            ? {
                body: 'Enabling 2 factor authentication for the first time will require you to re-login. Do you want to proceed?',
                label: 'Enabling 2-Factor authentication',
                yesMessage: 'Confirm',
                noMessage: 'Cancel',
                variant: 'compact',
              }
            : undefined
        }
        config={finishConfig}
        currentStep="Security Settings"
        isForm={!!dirty}
        isLastStep={false}
        updateSteps
        disableBack={false}
        disableNext={false}
        pendingBack={false}
        pendingNext={false}
        nextRoute={ROUTES.ONBOARDING_CHECKLIST.FINISH.INTEGRATIONS}
        previousRoute=""
      />
    </>
  )
}
