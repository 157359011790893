import { useTableReturnType } from '@src/components/Table/hooks'
import { Stats } from '@src/interfaces/data'

type UseFilterToggleParams<T, S = Stats> = {
  table: useTableReturnType<T, S>
  columnName: string
  valueName: string
}
export const useFilterToggle = <T, S>({
  table,
  columnName,
  valueName,
}: UseFilterToggleParams<T, S>) => {
  const filtersByWarnings =
    table.filterBy.find(filterBy => filterBy.columnName === columnName)?.filters || []
  const isActive = filtersByWarnings.some(filter => filter.id === valueName)
  const toggle = () => {
    table.onFilterChange(
      isActive
        ? { columnName, filters: [] }
        : {
            columnName,
            filters: [{ id: valueName, name: valueName }],
          },
    )
  }
  return { toggle, isActive }
}
