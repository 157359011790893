import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { DashboardTileItem } from '@src/interfaces/dashboard'
import { useFetch } from '@src/utils/reactQuery'

export const useDashboardTiles = () =>
  useFetch<GetRequestInterface<DashboardTileItem>>(
    `${API.EMPLOYEES}/tiles`,
    undefined,
    undefined,
    true,
  )
