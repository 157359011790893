import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import { Box } from '@revolut/ui-kit'
import {
  FinalGradeInterface,
  PerformanceSelector,
  PerfReviewRequestFeedbackInterface,
  RequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import { ProbationLayoutTimeline } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/ProbationLayoutTimeline'
import { useGetProbationCheckpoints } from '@src/api/probationReview'
import {
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '@src/pages/EmployeeProfile/Preview/Performance/Common/utils'
import { useGetPipCheckpoints } from '@src/api/pip'
import { useGetPeriodTypes } from '@src/utils/performance'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  setCheckpoints?: (checkpoints: ProbationCheckpoints) => void
  performanceLink?: string
  finalGrade?: FinalGradeInterface
}

export const ProbationPipTimeline = ({
  data,
  selectedPeriod,
  performanceLink,
  finalGrade,
  setCheckpoints,
}: Props) => {
  const cycleId = selectedPeriod?.id
  const { isNewProbation, isPIPv2, isNewFlow } = useGetPeriodTypes(selectedPeriod)

  const { data: checkpoints } = useGetProbationCheckpoints(
    isNewProbation ? data.id : null,
    cycleId !== undefined ? String(cycleId) : null,
  )
  const { data: pipCheckpoints } = useGetPipCheckpoints(
    isPIPv2 ? data.id : null,
    cycleId !== undefined ? String(cycleId) : null,
  )

  if (isNewProbation && checkpoints && setCheckpoints) {
    setCheckpoints(checkpoints)
  }
  if (isPIPv2 && pipCheckpoints && setCheckpoints) {
    setCheckpoints(pipCheckpoints)
  }

  const requestsContext = useFetcherPerformanceRequests({
    category: selectedPeriod.category,
    isNewFlow,
    id: data.id,
    performanceCycle: selectedPeriod,
  })

  const { data: requestsData } = requestsContext

  const requests = isNewFlow
    ? (requestsData as NewFlowRequestsResponse | undefined)?.results
    : (requestsData as RequestFeedbackInterface[] | undefined)

  return (
    <Box>
      {isNewProbation && checkpoints && cycleId !== undefined && (
        <ProbationLayoutTimeline
          selectedPeriod={selectedPeriod}
          data={data}
          finalGrade={finalGrade}
          checkpoints={checkpoints}
          requests={requests as PerfReviewRequestFeedbackInterface[] | undefined}
          endDate={selectedPeriod!.end_date_time}
          employeeId={data.id}
          cycleId={String(cycleId)}
          reviewCategory={ReviewCategory.Probation}
          performanceLink={performanceLink}
        />
      )}
      {pipCheckpoints && (
        <ProbationLayoutTimeline
          selectedPeriod={selectedPeriod}
          data={data}
          finalGrade={finalGrade}
          checkpoints={pipCheckpoints}
          cycleId={String(cycleId)}
          employeeId={data.id}
          endDate={selectedPeriod!.end_date_time}
          requests={requests as PerfReviewRequestFeedbackInterface[] | undefined}
          reviewCategory={ReviewCategory.PIP_V2}
          performanceLink={performanceLink}
        />
      )}
    </Box>
  )
}
