import React from 'react'
import { QualityCheckInterface } from '@src/interfaces/kpis'
import { Box, Caption, Flex, Icon, Token } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'
import QualityLink from '@components/ColumnInserts/QualityScore/QualityLink'

interface QualityChecksProps {
  data: QualityCheckInterface[]
}

const QualityCheck = ({ data }: QualityChecksProps) => {
  return (
    <Box>
      <Grid gap={24}>
        {data.map(quality => {
          return (
            <Flex
              key={quality.key}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {quality.status ? (
                <Icon name="Check" size={16} color={Token.color.green} />
              ) : (
                <Icon name="Cross" size={16} color={Token.color.red} />
              )}
              <Caption pl="s-12">{quality.name}</Caption>
              <Caption flex="1" textAlign="right">
                {quality.reason}
              </Caption>
              <Box ml="s-8">
                <QualityLink id={quality.key} />
              </Box>
            </Flex>
          )
        })}
      </Grid>
    </Box>
  )
}

export default QualityCheck
