import React, { createContext, useContext, useEffect, useMemo } from 'react'
import { RootProvider, useMatchMedia } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import '@revolut/ui-kit/styles.css'

import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { FeatureFlags, LocalStorageKeys } from '@src/store/auth/types'
import { useRouteMatch } from 'react-router-dom'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { BannerProvider } from './BannerProvider'
import { useTopNav } from '@src/features/TopNav/useTopNav'
import { selectFeatureFlags } from '@src/store/auth/selectors'

type ThemeMode = 'light' | 'dark'

export type ThemeSetting = ThemeMode | 'transparent' | 'auto'

type ThemeContextValue = {
  themeSettingValue: ThemeSetting
  theme: ThemeMode
  setTheme: (value: ThemeSetting) => void
  loginTheme: ThemeMode
  setLoginTheme: (value: 'light' | 'dark') => void
  setTopNavEnabled: (value: boolean) => void
}

const ThemeContext = createContext<ThemeContextValue | null>(null)

export const useAppTheme = () => {
  const context = useContext(ThemeContext)
  if (context === null) {
    throw new Error('useAppTheme must be used within an UIKitWithThemeProvider')
  }
  return context
}

export const UIKitWithThemeProvider: React.FC = ({ children }) => {
  const { topNav, setEnabled } = useTopNav()
  const featureFlags = useSelector(selectFeatureFlags)

  const [themeSettingValue, setThemeSettingValue] = useLocalStorage<ThemeSetting>(
    LocalStorageKeys.THEME,
    'light',
    false,
  )
  const [loginTheme, setLoginTheme] = useLocalStorage<'light' | 'dark'>(
    LocalStorageKeys.LOGIN_THEME,
    'dark',
  )
  const [transparentModeTurnedOn, setTransparentModeTurnedOn] = useLocalStorage<boolean>(
    LocalStorageKeys.TRANSPARENT_MODE_TURNED_ON,
    false,
  )

  // TODO: https://revolut.atlassian.net/browse/REVCOR-3195 remove `transparent_mode` feature flag and this effect
  useEffect(() => {
    if (featureFlags?.includes(FeatureFlags.TransparentMode)) {
      if (!transparentModeTurnedOn && themeSettingValue === 'dark') {
        setThemeSettingValue('transparent')
      }
      setTransparentModeTurnedOn(true)
    }
  }, [featureFlags, transparentModeTurnedOn])

  const prefersDarkTheme = useMatchMedia(
    themeSettingValue === 'auto' ? '(prefers-color-scheme: dark)' : null,
  )

  const isLoginThemeRoute = !!useRouteMatch({
    path: [
      ROUTES.LOGIN.MAIN,
      ROUTES.SIGNUP.MAIN,
      WORKSPACES.ANY,
      ROUTES.TWO_FACTOR_LOGIN,
    ],
  })

  const contextValue = useMemo<ThemeContextValue>(() => {
    return {
      themeSettingValue,
      theme:
        (themeSettingValue === 'auto' && prefersDarkTheme) ||
        themeSettingValue === 'transparent' ||
        themeSettingValue === 'dark'
          ? 'dark'
          : 'light',
      setTheme: (mode: ThemeSetting) => {
        setThemeSettingValue(mode)
      },
      loginTheme,
      setLoginTheme: (mode: 'light' | 'dark') => {
        setLoginTheme(mode)
      },
      setTopNavEnabled: setEnabled,
    }
  }, [
    themeSettingValue,
    setThemeSettingValue,
    setLoginTheme,
    loginTheme,
    prefersDarkTheme,
    setEnabled,
  ])

  const background = useMemo(() => {
    return contextValue.themeSettingValue === 'transparent' && !isLoginThemeRoute
      ? { hue: 231, saturation: 100 }
      : undefined
  }, [contextValue.themeSettingValue, isLoginThemeRoute])

  return (
    <ThemeContext.Provider value={contextValue}>
      <RootProvider
        topNav={topNav}
        mode={isLoginThemeRoute ? loginTheme : contextValue.theme}
        background={background}
      >
        <BannerProvider>{children}</BannerProvider>
      </RootProvider>
    </ThemeContext.Provider>
  )
}
