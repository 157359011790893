import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { ReviewCyclesScorecardSectionInterface } from '@src/interfaces/reviewCycles'
import { AdditionalScorecardsSidebarForm } from './AdditionalScorecardsSidebarForm'

export type Props = {
  isOpen: boolean
  handleClose: () => void
  scorecardData?: ReviewCyclesScorecardSectionInterface
  refetch: () => void
}

export const AdditionalScorecardsSidebar = (props: Props) => {
  const title = props.scorecardData?.id ? 'Edit section' : 'Create new section'

  return (
    <SideBar
      variant="wide"
      title={title}
      onClose={props.handleClose}
      isOpen={props.isOpen}
    >
      <AdditionalScorecardsSidebarForm {...props} />
    </SideBar>
  )
}
