import { matchPath, useLocation } from 'react-router-dom'
import { PUBLIC_ROUTES } from '@src/constants/routes'

export const useIsCareers = () => {
  const location = useLocation()

  return !!matchPath(location.pathname, {
    path: PUBLIC_ROUTES.CAREERS.ANY,
    exact: true,
  })
}
