import React from 'react'
import { IconButton, Action, Color, HStack } from '@revolut/ui-kit'
import { ArrowThinDown, ArrowThinUp } from '@revolut/icons'

import {
  PerformanceScorecardQuestionInterface,
  PerformanceScorecardSectionInterface,
} from '@src/interfaces/settings'

type SectionControlsProps = {
  id: number
  onDelete: () => void
  onEdit?: () => void
  onCopy: () => void
  sections?:
    | PerformanceScorecardQuestionInterface[]
    | PerformanceScorecardSectionInterface[]
}

type Direction = 'up' | 'down'

export const SectionControls = ({
  id,
  onDelete,
  onEdit,
  onCopy,
  sections,
}: SectionControlsProps) => {
  const handleMove = (direction: Direction) => () => {
    const offset = direction === 'down' ? 1 : -1

    if (sections?.[id] && sections?.[id + offset]) {
      ;[sections[id], sections[id + offset]] = [sections[id + offset], sections[id]]
    }
  }

  const preventDefaultAndCallAction =
    (action: () => void) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault()
      e.stopPropagation()
      action()
    }

  return (
    <HStack space="s-8" pl="s-8">
      <Action onClick={preventDefaultAndCallAction(onCopy)}>Copy</Action>
      {onEdit && <Action onClick={preventDefaultAndCallAction(onEdit)}>Edit</Action>}
      <Action onClick={preventDefaultAndCallAction(onDelete)}>Delete</Action>
      {id > 0 && (
        <IconButton
          useIcon={ArrowThinUp}
          aria-label="Up"
          size={20}
          color={Color.BLUE}
          onClick={preventDefaultAndCallAction(handleMove('up'))}
        />
      )}
      {id + 1 < (sections?.length || 0) && (
        <IconButton
          useIcon={ArrowThinDown}
          aria-label="Down"
          size={20}
          color={Color.BLUE}
          onClick={preventDefaultAndCallAction(handleMove('down'))}
        />
      )}
    </HStack>
  )
}
