import React from 'react'
import { Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EmployeeItem } from '@src/features/GrowthPlans/GrowthPlan/components/EmployeeItem'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { EditGrowthPlanFormContent } from '@src/features/GrowthPlans/GrowthPlan/components/EditGrowthPlan/EditGrowthPlanFormContent'
import { PageActions } from '@components/Page/PageActions'
import { PageWrapper } from '@components/Page/Page'
import { EmployeeInterface } from '@src/interfaces/employees'

interface Props {
  title: string
  backUrl: string
  employee: EmployeeInterface
  actions: React.ReactNode
  isLoading: boolean
}

export const EditGrowthPlanLayout = connect(
  ({ title, employee, isLoading, backUrl, actions }: Props) => (
    <PageWrapper fullWidth>
      <PageHeader title={title} backUrl={backUrl} />
      <EmployeeItem employee={employee} isLoading={isLoading} />
      <TwoColumnsLayout
        left={
          <>
            <Box mt="s-16">
              <EditGrowthPlanFormContent employee={employee} />
            </Box>
            <PageActions>{actions}</PageActions>
          </>
        }
        right={null}
      />
    </PageWrapper>
  ),
)
