import React from 'react'
import { FormattedMessage } from 'react-intl'

import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '../common/config'

type OrganisationSettingsItemId = 'organisationTeams' | 'organisationDepartments'

export const organisationSettingsLinkConfig: SettingsLinkItemConfig<OrganisationSettingsItemId>[] =
  [
    {
      id: 'organisationTeams',
      section: 'Organisation settings',
      icon: 'Bank',
      title: 'Teams',
      to: ROUTES.SETTINGS.ORGANISATION.TEAMS,
      subtitle: (
        <FormattedMessage
          id="settings.organisation.teams"
          defaultMessage="Settings that apply to all teams"
        />
      ),
      permissions: [PermissionTypes.ViewOrganisationPreferences],
    },
    {
      id: 'organisationDepartments',
      section: 'Organisation settings',
      icon: 'Services',
      title: 'Departments',
      to: ROUTES.SETTINGS.ORGANISATION.DEPARTMENTS,
      subtitle: (
        <FormattedMessage
          id="settings.organisation.departments"
          defaultMessage="Settings that apply to all departments"
        />
      ),
      permissions: [PermissionTypes.ViewOrganisationPreferences],
    },
  ]
