import { EmployeeOptionInterface } from './employees'
import { FieldOptions, IdAndName, Statuses } from './index'
import { CurrencyColors } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import { RiskScores } from '@src/interfaces/risk'
import { OptionInterface } from '@src/interfaces/selectors'
import { CulturePercentile } from './culture'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { OrgUnitGoalsStats, GoalWeightMode } from './goals'
import { IconName } from '@revolut/ui-kit'

export interface DepartmentInterface {
  id: number
  owner?: EmployeeOptionInterface
  kpi_committee?: EmployeeOptionInterface
  kpi_champions?: EmployeeOptionInterface[]
  name: string
  icon: IconName | null
  avatar: string | null
  remit: string
  nips?: number
  business_debt_extra_allowance: number
  business_debt_target: number
  karma_percentage?: number
  business_debt_currency: string
  business_debt_percent: number
  business_debt_average_value: number
  headcount: number
  teams_count: number
  risk_composite_index?: OptionInterface
  kpi_performance_percent: number
  kris_above_level_1: number
  kris_above_level_2: number
  kris_above_level_3: number
  kpi_weight_mode?: number
  performance: number
  quality_control: EmployeeOptionInterface
  reports_to: EmployeeOptionInterface
  requisition_count: number
  nps: number
  requisition_remaining_headcount: number
  leadership_positions_count: number
  unassigned_leadership_positions_count: number
  roadmap_progress_percent: number
  roadmap_updated_date_time: string
  audits_critical: number
  budget_currency: string
  budget_color: CurrencyColors
  total_current_spent: number
  audits_trivial: number
  audits_recommended: number
  audits_exceptional: number
  culture_enps: number
  culture_values: number
  culture_compliance: number
  culture_manager_enps: number
  culture_job_satisfaction: number
  culture_manager_enps_percentile: CulturePercentile
  culture_job_satisfaction_percentile: CulturePercentile
  culture_compliance_percentile: CulturePercentile
  culture_enps_percentile: CulturePercentile
  culture_values_percentile: CulturePercentile
  risk_score: RiskScores
  risk_score_reason: string
  karma: number
  time_off_request_pending_count: number | null
  time_off_request_in_progress_count: number | null
  time_off_request_upcoming_count: number | null
  cx_complaints_last_30_days: number
  cx_csat_detractors_last_30_days: number
  cx_csat_detractors_rated_1: number
  cx_csat_detractors_rated_2: number
  cx_csat_detractors_rated_3: number
  cx_support_load_last_30_days: number
  cx_trustpilot_last_30_days: number
  settings: {
    id: string | number
    team_owners_can_view_salaries: boolean
  }
  status: Statuses
  approval_status: ApprovalStatuses
  field_options: FieldOptions
  communication_group: CommunicationGroupInterface | null
  linked_communication_groups: CommunicationGroupInterface[] | null
  jira_projects: string[] | null
  performance_dashboard_link: string | null
  average_score?: number | null
  dashboard_count: number
  goal_weight_mode?: IdAndName<GoalWeightMode, string>
  goals_progress_percent?: number
}

export interface CreateDepartmentInterface {
  name: string
  owner: IdAndName
  mission?: string
}

export interface DepartmentGoalsStats extends OrgUnitGoalsStats {}

export interface SlackDataInterface {
  id: number
  audience_type: string
  slack_user_group: {
    id: number
    name: string
    handle: string
    status: ConnectionStatuses
    slack_user_group_id: string | null
  }
  slack_channel: {
    id: number
    name: string
    is_private: boolean
  } | null
  function: {
    id: number
    name: string
  } | null
  is_primary_group: boolean
}

export interface ExternalChannelItemInterface {
  name: string
  handle: string
  href?: string
  status?: ConnectionStatuses
  id: number
  type: ConnectionType
  is_primary_group: boolean
}

export enum ConnectionStatuses {
  Enabled = 'enabled',
  Disabled = 'disabled',
  LinkError = 'link_error',
}

export enum ConnectionType {
  Channel = 'channel',
  Group = 'group',
}
