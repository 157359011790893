import React from 'react'

import { lifecycleSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { lifecycleConfig } from '@src/pages/OnboardingChecklist/common/constants'
import { LifecycleIntro } from './Intro'
import { LifecycleWelcome } from './Welcome'
import { LifecycleHrContact } from './HrContact'
import { LifecycleRequiredInfo } from './RequiredInfo'
import { LifecycleCompliance } from './Compliance'

const routes = [
  {
    title: 'Intro',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.INTRO,
    canView: [PermissionTypes.ViewLifecycleSettings],
    component: LifecycleIntro,
  },
  {
    title: 'Welcome page',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.WELCOME,
    url: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.WELCOME,
    canView: [PermissionTypes.ViewLifecycleSettings],
    component: LifecycleWelcome,
    form: {
      api: lifecycleSettings,
    },
  },
  {
    title: 'HR-contact',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.HR_CONTACT,
    url: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.HR_CONTACT,
    canView: [PermissionTypes.ViewLifecycleSettings],
    component: LifecycleHrContact,
    form: {
      api: lifecycleSettings,
    },
  },
  {
    title: 'Required info',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.REQUIRED_INFO,
    url: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.REQUIRED_INFO,
    canView: [PermissionTypes.ViewLifecycleSettings],
    component: LifecycleRequiredInfo,
    form: {
      api: lifecycleSettings,
    },
  },
  {
    title: 'Compliance',
    subtitle: '',
    path: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.COMPLIANCE,
    url: ROUTES.ONBOARDING_CHECKLIST.LIFECYCLE.COMPLIANCE,
    canView: [PermissionTypes.ViewLifecycleSettings],
    component: LifecycleCompliance,
    form: {
      api: lifecycleSettings,
    },
  },
]

export const Lifecycle = () => {
  return <OnboardingChecklistContent config={lifecycleConfig} routes={routes} />
}
