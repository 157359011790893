import React from 'react'
import { Group, DetailsCellSkeleton } from '@revolut/ui-kit'
import { EmployeeResignationInterface } from '@src/interfaces/employeeResignation'
import { PageBody } from '@src/components/Page/PageBody'
import { ReasonsPreview } from '../../Components/ReasonsPreview'
import { useResignationPermissions } from '../../hooks'

type Props = {
  resignation?: EmployeeResignationInterface
}
const ResignationReasonsPreview = ({ resignation }: Props) => {
  const { canView } = useResignationPermissions(resignation)

  return (
    <PageBody>
      <Group>
        {resignation ? (
          <>{canView && <ReasonsPreview resignation={resignation} />}</>
        ) : (
          <>
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
          </>
        )}
      </Group>
    </PageBody>
  )
}

export default ResignationReasonsPreview
