import React, { useEffect, useState } from 'react'
import {
  Flex,
  Icon,
  Text,
  Token,
  VStack,
  TransitionCollapse,
  TextSkeleton,
} from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { GradeAndPercent } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface QuarterTableWrapperProps {
  children: React.ReactNode
  isInitiallyCollapsed: boolean
  cycleStat?: GradeAndPercent | null
  cycleName?: string
}
const QuarterTableWrapper = ({
  children,
  isInitiallyCollapsed,
  cycleStat,
  cycleName,
}: QuarterTableWrapperProps) => {
  const [isCollapsed, setIsCollapsed] = useState(isInitiallyCollapsed)
  const onCollapse = () => {
    setIsCollapsed(!isCollapsed)
  }

  useEffect(() => {
    setIsCollapsed(isInitiallyCollapsed)
  }, [cycleStat])

  return (
    <VStack space="s-16" bg={Token.color.widgetBackground}>
      <Flex
        role="button"
        style={{ cursor: 'pointer' }}
        onClick={onCollapse}
        paddingX="s-16"
        paddingY="s-12"
        justifyContent="space-between"
        alignItems="center"
        data-testid="collapsable-table-wrapper"
      >
        <Flex gap="s-16" alignItems="center">
          <Icon
            color={Token.color.greyTone50}
            name={isCollapsed ? 'ChevronDown' : 'ChevronRight'}
            size={24}
          />
          {cycleName ?? <TextSkeleton size={12} />}
        </Flex>
        <Flex>
          {cycleStat?.grade && (
            <Text color={Token.color.greyTone50}>
              {formatPercentage(cycleStat?.percent || 0)}
            </Text>
          )}
        </Flex>
      </Flex>
      <TransitionCollapse in={isCollapsed}>{children}</TransitionCollapse>
    </VStack>
  )
}

export default QuarterTableWrapper
