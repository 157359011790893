import { useParams } from 'react-router'
import React from 'react'
import { useTriggerCycleSync } from '@src/api/supportTool/eligibleEmployees'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { Button, MoreBar, useStatusPopup } from '@revolut/ui-kit'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const EligibleGroupActions = () => {
  const params = useParams<{ id: string }>()
  const { mutateAsync: triggerCycleSync, isLoading } = useTriggerCycleSync()
  const showStatusPopup = useShowStatusPopup()
  const { hide } = useStatusPopup()

  const handleSync = async () => {
    try {
      await triggerCycleSync({ cycle_id: params.id })
      showStatusPopup({ title: 'Employee data synchronised' })
    } catch (err) {
      showStatusPopup({
        status: 'error',
        title: 'Failed to synchronize employee data',
        description: getStringMessageFromError(err, 'Something went wrong'),
        actions: <Button onClick={hide}>Go back</Button>,
      })
    }
  }

  return (
    <MoreBar>
      <MoreBar.Action useIcon="ArrowExchange" onClick={handleSync} pending={isLoading}>
        Sync eligibility now
      </MoreBar.Action>
    </MoreBar>
  )
}
