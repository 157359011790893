import { EligibilityCheckItem } from '@src/interfaces/promotions'

export const defaultPassedEligibilityCheckItem: EligibilityCheckItem = {
  passed: true,
  eligibility_criteria: {
    id: 1,
    name: 'Is eligible for promotion',
    description: '',
  },
}

export const defaultMissedEligibilityCheckItem: EligibilityCheckItem = {
  passed: false,
  eligibility_criteria: {
    id: 1,
    name: 'Is not eligible for promotion',
    description: '',
  },
}
