import React from 'react'

import { useCurrentTenantInfo } from '@src/api/tenants'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import { Token } from '@revolut/ui-kit'

export const DemoModeBanner = () => {
  const { isDemoMode } = useCurrentTenantInfo()

  if (isDemoMode) {
    return (
      <ActionWidget
        title="Employees invited in demo mode won’t be available in the real account after onboarding, they will need to be re-invited"
        mb="s-16"
        avatarColor={Token.color.warning}
      />
    )
  }

  return null
}
