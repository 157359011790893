import React, { useMemo, useState } from 'react'
import { Box, TextSkeleton, Token, useMatchMedia } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import Notifications from '@src/features/Notifications/Notifications'
import { GlobalSearch } from '@src/features/MainHeader/GlobalSearch'
import { UserProfile } from '@src/features/TopNav/UserProfile'
import { useBanner } from '@src/features/UIKitWithThemeProvider/BannerProvider'
import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'
import { selectAuthenticated, selectFeatureFlags } from '@src/store/auth/selectors'
import { TopNavAppSwitcher } from './TopNavAppSwitcher'
import { TopNavCompanyAvatar } from '@src/features/TopNav/TopNavCompanyAvatar'
import { useGetCompanyPreferences } from '@src/api/settings'
import { FeatureFlags } from '@src/store/auth/types'

const PositionOffsetWrapper: React.FC<{ noOffset?: boolean }> = ({
  noOffset,
  children,
}) => {
  const { visible } = useBanner()

  if (!visible || noOffset) {
    return <>{children}</>
  }

  return (
    <Box
      style={{
        position: 'relative',
        top: 28,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  )
}

export const useTopNav = () => {
  const [enabled, setEnabled] = useState(false)
  const mdMedia = useMatchMedia(Token.media.md)
  const authenticated = useSelector(selectAuthenticated)
  const isCoreNavigation = useCoreNavigation()

  const shouldRender = enabled && isCoreNavigation && authenticated
  const featureFlags = useSelector(selectFeatureFlags)
  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)
  const { data: companyPreferences, isLoading } = useGetCompanyPreferences(shouldRender)
  const enableBranding = !!companyPreferences?.enable_branding

  const getCompanyName = () => {
    if (!isCommercial) {
      return 'People'
    }
    if (isLoading) {
      return <TextSkeleton />
    }
    if (enableBranding) {
      return companyPreferences?.brand_name
    }
    return 'People'
  }

  const topNav = useMemo(() => {
    if (shouldRender) {
      return {
        title: getCompanyName(),
        logo: (
          <PositionOffsetWrapper noOffset={mdMedia}>
            <TopNavCompanyAvatar />
          </PositionOffsetWrapper>
        ),
        profile: (
          <PositionOffsetWrapper>
            <UserProfile />
          </PositionOffsetWrapper>
        ),
        appSwitcher: (
          <PositionOffsetWrapper>
            <TopNavAppSwitcher />
          </PositionOffsetWrapper>
        ),
        globalSearch: (
          <PositionOffsetWrapper>
            <GlobalSearch />
          </PositionOffsetWrapper>
        ),
        notifications: (
          <PositionOffsetWrapper>
            <Notifications />
          </PositionOffsetWrapper>
        ),
      }
    }

    return undefined
  }, [shouldRender, mdMedia, enableBranding, isCommercial, isLoading])

  return {
    topNav,
    setEnabled,
  }
}
