import React, { useEffect, useState } from 'react'
import {
  Ellipsis,
  FilterButton,
  Flex,
  Icon,
  RadioSelect,
  Text,
  Token,
  useDropdown,
} from '@revolut/ui-kit'
import capitalize from 'lodash/capitalize'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'
import { OptionItem } from './OptionItem'
import {
  Cycle,
  CycleOption,
} from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

interface Props {
  options: CycleOption[]
  label?: string
  onChangeCallback?: (val: Cycle) => void
}

export const YearSelectButton = ({
  options,
  label = 'Performance cycle',
  onChangeCallback,
}: Props) => {
  const dropdown = useDropdown()
  const [selectedPeriod, setSelectedOption] = useState<Cycle>(options[0].value)

  useEffect(() => {
    if (selectedPeriod) {
      onChangeCallback && onChangeCallback(selectedPeriod)
    }
  }, [selectedPeriod?.id])

  const { title: tagTitle } = getCycleOffsetTitleAndColor(
    selectedPeriod.review_cycle_offsets[0],
  )

  return (
    <>
      <FilterButton
        useIcon="Calendar"
        aria-haspopup="listbox"
        aria-expanded={dropdown.state}
        {...dropdown.getAnchorProps()}
        style={{ background: Token.color.actionBackgroundNeutral }}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{selectedPeriod?.name || 'Select cycle'}</Text>
          </Ellipsis>
          <Text variant="emphasis2" color={Token.color.greyTone50}>
            {capitalize(tagTitle)}
          </Text>
          <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        label={label}
        options={options}
        value={selectedPeriod}
        onChange={val => {
          if (val) {
            setSelectedOption(val)
            onChangeCallback && onChangeCallback(val)
            dropdown.toggle()
          }
        }}
        labelList="Cycles"
      >
        {option => <OptionItem label={option.label} value={option.value} />}
      </RadioSelect>
    </>
  )
}
