import React from 'react'
import { Statuses } from '@src/interfaces'
import Table from '@components/TableV2/Table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/TableV2/hooks'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import {
  getGrowthPlansRelations,
  GrowthPlansRelationsQueryFilterKeys,
  GrowthPlansRelationsQueryKeys,
} from '@src/api/growthPlans'
import { GrowthPlanRelationInterface } from '@src/interfaces/growthPlans'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PerformanceLayoutSubTabs } from '@src/pages/EmployeeProfile/Layout/Performance/LayoutTab'
import { getRow } from '@src/features/GrowthPlans/PerfTeamPlans/Row'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { FilterButton } from '@revolut/ui-kit'

const row = getRow()

const initialFilterBy = {
  filters: [
    { id: Statuses.pending, name: Statuses.pending },
    { id: Statuses.approved, name: Statuses.approved },
    { id: Statuses.requires_changes, name: Statuses.requires_changes },
  ],
  columnName: GrowthPlansRelationsQueryKeys.Status,
  nonResettable: true,
  nonInheritable: true,
}

export const PerfTeamPlansTable = () => {
  const currentUser = useSelector(selectUser)
  const table = useTable({ getItems: getGrowthPlansRelations }, [initialFilterBy])

  const filtersByOwner =
    table.filterBy.find(
      filterBy => filterBy.columnName === GrowthPlansRelationsQueryFilterKeys.OwnerId,
    )?.filters || []
  const isFilteredByMyPlans = filtersByOwner.some(filter => filter.id === currentUser.id)

  const toggleFilterByOwner = () => {
    table.onFilterChange({
      columnName: GrowthPlansRelationsQueryFilterKeys.OwnerId,
      filters: isFilteredByMyPlans
        ? []
        : [{ name: currentUser.display_name, id: currentUser.id }],
    })
  }

  const onRowClick = ({ id: relationId }: GrowthPlanRelationInterface) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.PERFORMANCE.ANY, {
        id: relationId,
        subtab: PerformanceLayoutSubTabs.Growth,
      }),
    )
  }

  return (
    <Table.Widget>
      <Table.Widget.Filters>
        <Table.Search
          placeholder="Search by employee name"
          onFilter={table.onFilterChange}
          variant="compact"
        />
        <FilterButton active={isFilteredByMyPlans} onClick={toggleFilterByOwner}>
          My plans
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable<GrowthPlanRelationInterface>
          name={TableNames.PerfTeamGrowthPlans}
          row={row}
          hideCount
          {...table}
          emptyState={<EmptyTableRaw title="Growth plans will appear here." />}
          onRowClick={onRowClick}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
