import AvatarUploadPopup from '@components/AvatarUploadPopup/AvatarUploadPopup'
import React from 'react'
import { StatusPopup, useStatusPopup, IconName } from '@revolut/ui-kit'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { MutationResult } from '@src/features/SettingsButtons/types'
import { useUpdateEntityAvatar, useDeleteEntityAvatar } from '@src/api/avatar'

interface UserAvatarUploadProps<T> {
  open: boolean
  onClose: () => void
  onSuccess?: (data?: { avatar?: string | null; icon?: IconName | null }) => void
  api: () => MutationResult<T>
  apiUrl?: string
  id: number
  icon?: IconName | null
}

export const EntityAvatarUpload = <T extends {}>({
  open,
  onClose,
  onSuccess,
  api,
  apiUrl,
  id,
  icon,
}: UserAvatarUploadProps<T>) => {
  const statusPopup = useStatusPopup()
  const { mutateAsync: updateEntity } = api()
  const { mutateAsync: deleteAvatar } = useDeleteEntityAvatar(id, apiUrl)
  const { mutateAsync: uploadAvatar } = useUpdateEntityAvatar(id, apiUrl)

  const onSaveIcon = async (iconName: IconName) => {
    try {
      const resp = await updateEntity([id, { icon: iconName }])
      onSuccess?.(resp.data)
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon was set</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to set icon',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onSavePhoto = async (file: File) => {
    try {
      const { data } = await uploadAvatar(file)
      onSuccess?.({ avatar: data.url })
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon was set</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to upload icon file',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  const onRemovePhoto = async () => {
    try {
      await deleteAvatar(undefined)
      const resp = await updateEntity([id, { icon: null }])

      onSuccess?.(resp.data)
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Icon removed</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch {
      pushNotification({
        type: NotificationTypes.error,
        value: 'Failed to remove icon',
        duration: ERROR_DEFAULT_DURATION,
      })
    }
  }

  return (
    <AvatarUploadPopup
      open={open}
      onClose={onClose}
      onSave={onSavePhoto}
      onSaveIcon={onSaveIcon}
      onRemove={onRemovePhoto}
      title="Set icon"
      minHeight={80}
      minWidth={80}
      withIcons
      withFile={!!apiUrl}
      icon={icon}
    />
  )
}
