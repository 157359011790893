import React from 'react'
import {
  Ellipsis,
  FilterButton,
  Flex,
  useDropdown,
  Text,
  Icon,
  Dropdown,
} from '@revolut/ui-kit'
import { matchPath, useLocation } from 'react-router-dom'
import { InternalLink } from '../InternalLink/InternalLink'

interface Props {
  options: { title: string; to: string; path: string }[]
}

export const DropdownNavigation = ({ options }: Props) => {
  const dropdown = useDropdown()
  const location = useLocation()
  const currentOption = options.find(({ path }) =>
    matchPath(location.pathname, { path, exact: true }),
  )
  return (
    <>
      <FilterButton
        aria-haspopup="listbox"
        aria-expanded={dropdown.state}
        {...dropdown.getAnchorProps()}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          <Ellipsis>
            <Text variant="emphasis2">{currentOption?.title}</Text>
          </Ellipsis>

          <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <Dropdown {...dropdown.getTargetProps()}>
        {options.map(option => (
          <Dropdown.Item key={option.path} use={InternalLink} to={option.to}>
            {option.title}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
