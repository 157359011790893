import React from 'react'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import Candidate from '@src/pages/Forms/SendEmail/Candidate/index'
import View from '@src/pages/Forms/SendEmail/View/View'
import { useGetCandidateEmailThread } from '@src/api/hiringProcess'
import { pathToUrl } from '@src/utils/router'
import { Text } from '@revolut/ui-kit'
import { format } from 'date-fns'
import CandidateData from '@src/pages/Forms/SendEmail/CandidateData/CandidateData'

const SendEmail = () => {
  const params =
    useParams<{ candidateId?: string; threadId?: string; emailId?: string }>()
  const isSendingMode = useRouteMatch(ROUTES.FORMS.SEND_EMAIL.CANDIDATE)
  const isPersonalData = useRouteMatch(ROUTES.FORMS.SEND_EMAIL.CANDIDATE_DATA)
  const backUrl = pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, {
    id: params.candidateId,
  })

  const { data, isLoading } = useGetCandidateEmailThread(
    params.candidateId,
    params.threadId,
  )

  let title = isPersonalData ? 'Send personal data' : 'Send email'

  if (data?.subject) {
    title = isSendingMode ? 'Send reply' : data.subject
  }

  const getSubtitle = () => {
    const firstEmail = data?.candidate_emails[0]
    if (
      !isSendingMode &&
      firstEmail?.custom_sending_datetime &&
      firstEmail?.status === 'scheduled'
    ) {
      return (
        <Text use="div" color="orange">
          Email scheduled to be sent on{' '}
          {format(new Date(firstEmail.custom_sending_datetime), 'd MMM Y, hh:mm aaa')}
        </Text>
      )
    }
    return undefined
  }

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={title}
        backUrl={backUrl}
        isLoading={isLoading}
        subtitle={getSubtitle()}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.SEND_EMAIL.CANDIDATE}>
          <Candidate />
        </Route>
        <Route exact path={ROUTES.FORMS.SEND_EMAIL.CANDIDATE_DATA}>
          <CandidateData />
        </Route>
        <Route exact path={ROUTES.FORMS.SEND_EMAIL.VIEW}>
          <View />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(SendEmail)
