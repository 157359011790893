import React from 'react'
import { Box, StatusWidget, Token } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { useGetTeam } from '@src/api/teams'
import Table from '@src/components/TableV2/Table'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

import { TeamCalendarView } from '../TimeManagement/CalendarsWidget/TeamCalendarView'
import { VisibilityWrapper } from './VisibilityWrapper'

type Props = {
  data: EmployeeInterface | undefined
}
export const TeamCalendar = ({ data }: Props) => {
  const { data: teamData } = useGetTeam(data?.team?.id)
  const canViewTeamRequestsPolicy = !!teamData?.field_options.permissions?.includes(
    PermissionTypes.ViewTimeOffRequestPolicy,
  )

  return data?.team?.id ? (
    <VisibilityWrapper
      canView={!!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOff)}
      checkBySettings={{ timeOff: settings => !!settings?.enabled }}
    >
      <Table.Widget>
        <TeamCalendarView
          data={data}
          period="Month"
          showTeamViewButton={false}
          showThisMonthButton={false}
          canViewPolicy={canViewTeamRequestsPolicy}
          canViewSchedule={false}
          useFiltersFromQuery
        />
      </Table.Widget>
    </VisibilityWrapper>
  ) : (
    <VisibilityWrapper
      canView={!!data?.field_options?.permissions?.includes(PermissionTypes.ViewTimeOff)}
      checkBySettings={{ timeOff: settings => !!settings?.enabled }}
    >
      <Box maxWidth={Token.breakpoint.lg}>
        <StatusWidget>
          <StatusWidget.Image
            image={{
              default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
              '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
              '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
            }}
          />
          <StatusWidget.Title>
            This employee is not assigned to any team
          </StatusWidget.Title>
          <StatusWidget.Description>
            Assign employee to a team and refresh this page to see the results
          </StatusWidget.Description>
          <StatusWidget.Action
            onClick={() => {
              if (!data?.id) {
                return
              }
              navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data.id }))
            }}
          >
            Go to profile
          </StatusWidget.Action>
        </StatusWidget>
      </Box>
    </VisibilityWrapper>
  )
}
