import React, { useEffect } from 'react'
import { connect } from 'lape'
import { InputGroup } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OffboardingScreenProps, useOffboardingParams } from './common'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { offboardingPaymentsRequests } from '@src/api/offboarding'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { goBack } from '@src/actions/RouterActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OffboardingPaymentsInterface } from '@src/interfaces/offboarding'

const defaultPaymentsData = {
  lump_sum_payment_type: {
    id: 'tax_free',
    name: 'Tax Free',
  },
  lump_sum_time_unit: {
    id: 'days',
    name: 'Days',
  },
  lump_sum_time_units_number: '0',
  piloh_time_unit: {
    id: 'days',
    name: 'Days',
  },
  piloh_time_units_number: '0',
  pilon_time_unit: {
    id: 'days',
    name: 'Days',
  },
  pilon_time_units_number: '0',
}

const Payments = ({ data, refreshDashboard }: OffboardingScreenProps) => {
  const params = useOffboardingParams()

  const { values } = useLapeContext<OffboardingPaymentsInterface>()

  useEffect(() => {
    for (const [key, value] of Object.entries(defaultPaymentsData)) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      if (value && !values[key]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        values[key] = value
      }
    }
  }, [])

  return (
    <PageWrapper>
      <PageHeader
        title="Termination payments"
        backUrl={pathToUrl(ROUTES.FORMS.OFFBOARDING.DASHBOARD, params)}
        subtitle={<UserWithAvatar {...data.employee} asText />}
      />

      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Pay in lieu of holidays (PILOH)" />
          <InputGroup variant="horizontal" data-testid="Pay in lieu of holidays (PILOH)">
            <LapeNewInput
              name="piloh_time_units_number"
              label="Number of units"
              required
            />
            <LapeRadioSelectInput
              name="piloh_time_unit"
              label="Unit"
              selector={selectorKeys.termination_payment_time_units}
            />
          </InputGroup>

          <NewStepperTitle title="Pay in lieu of notice (PILON)" />
          <InputGroup variant="horizontal" data-testid="Pay in lieu of notice (PILON)">
            <LapeNewInput name="pilon_time_units_number" label="Number of units" />
            <LapeRadioSelectInput
              name="pilon_time_unit"
              label="Unit"
              selector={selectorKeys.termination_payment_time_units}
            />
          </InputGroup>

          <NewStepperTitle title="Lump sum / Severance pay" />
          <InputGroup data-testid="Lump sum / Severance pay">
            <InputGroup variant="horizontal">
              <LapeNewInput
                name="lump_sum_time_units_number"
                label="Number of units"
                required
              />
              <LapeRadioSelectInput
                name="lump_sum_time_unit"
                label="Unit"
                selector={selectorKeys.termination_payment_time_units}
              />
            </InputGroup>
            <LapeRadioSelectInput
              name="lump_sum_payment_type"
              label="Payment type"
              selector={selectorKeys.termination_lump_payment_types}
            />
          </InputGroup>

          <NewStepperTitle title="Additional payments" />
          <InputGroup data-testid="Additional payments">
            <InputGroup variant="horizontal">
              <LapeNewInput
                name="additional_payment_amount"
                label="Number of units"
                required
              />
              <LapeRadioSelectInput
                name="additional_payment_currency"
                label="Currency"
                selector={selectorKeys.currencies}
              />
            </InputGroup>
            <LapeRadioSelectInput
              name="additional_payment_type"
              label="Payment type"
              selector={selectorKeys.termination_additional_payment_types}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            refreshDashboard()
            goBack()
          }}
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect((props: OffboardingScreenProps) => (
  <Form
    api={offboardingPaymentsRequests}
    forceParams={{ id: String(props.data.employee.id) }}
  >
    <Payments {...props} />
  </Form>
))
