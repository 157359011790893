import React from 'react'
import { Text, Widget } from '@revolut/ui-kit'

type Props = {
  title: React.ReactNode
  children: React.ReactNode
}

export const OnboardingIntroTextWidget = ({ title, children }: Props) => {
  return (
    <Widget p="s-16">
      <Text variant="h5" display="block" mb="s-8">
        {title}
      </Text>
      {children}
    </Widget>
  )
}
