import React, { useState } from 'react'
import { PageBody } from '@components/Page/PageBody'
import { TimelineEventInterface } from '@src/interfaces/timeline'
import TimelineSidebar from '@src/pages/Forms/Candidate/Timeline/TimelineSidebar'
import TimelineContent from '@src/pages/Forms/Candidate/Timeline/TimelineContent'
import { CandidateInterface } from '@src/interfaces/interviewTool'

type Props = {
  candidate: CandidateInterface
  roundId?: number
}

const Timeline = ({ candidate, roundId }: Props) => {
  const [selectedEvent, setSelectedEvent] = useState<TimelineEventInterface>()

  return (
    <PageBody>
      <TimelineContent roundId={roundId} setSelectedEvent={setSelectedEvent} />
      <TimelineSidebar
        candidate={candidate}
        event={selectedEvent}
        onClose={() => setSelectedEvent(undefined)}
      />
    </PageBody>
  )
}

export default Timeline
