import React from 'react'
import { Banner, BannerProps, Token } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import { useGetBanners } from '@src/api/banners'
import { BannerSeverity } from '@src/interfaces/banners'
import isEmpty from 'lodash/isEmpty'

interface SystemStatusBannerProps extends BannerProps {}

export const mapSeverityToColor = (severity: BannerSeverity) => {
  switch (severity) {
    case BannerSeverity.info:
      return Token.color.blue
    case BannerSeverity.warning:
    case BannerSeverity.major:
      return Token.color.warning
    case BannerSeverity.critical:
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

export const SystemStatusBanner = (props: SystemStatusBannerProps) => {
  const { data, isLoading } = useGetBanners()

  if (isLoading || !data || isEmpty(data)) {
    return null
  }

  return (
    <Banner {...props}>
      <Banner.Avatar>
        <ExclamationTriangle size={56} color={mapSeverityToColor(data.severity)} />
      </Banner.Avatar>
      <Banner.Content>
        <Banner.Title>{data.title}</Banner.Title>
        <Banner.Description>{data.message}</Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
