import React, { useState } from 'react'
import {
  Carousel,
  Flex,
  Grid,
  Box,
  VStack,
  Image,
  Token,
  AspectRatio,
  useMatchMedia,
} from '@revolut/ui-kit'

import { Text } from '@src/pages/Landing/components/Text'
import { PageIndicator } from '@src/pages/Landing/components/PageIndicator'
import {
  LOGO_TECHSCALE_IMG,
  STORY_TECHSCALE_IMG,
  // LOGO_WHEELY_IMG,
  // STORY_WHEELY_IMG,
} from '@src/pages/Landing/constants'
import { CustomerStoryInterface } from '@src/pages/Landing/components/types'

const stories = [
  // {
  //   company: 'Wheely',
  //   logo: LOGO_WHEELY_IMG,
  //   image: STORY_WHEELY_IMG,
  //   quote:
  //     '“Since moving to Revolut People we have seen a 100% increase in business units defining and agreeing their goals.”',
  //   quoteBy: 'Maria Lanshakova, Senior HR Business Partner · Wheely',
  // },
  {
    company: 'TechScale',
    logo: LOGO_TECHSCALE_IMG,
    image: STORY_TECHSCALE_IMG,
    quote:
      '“Despite being a relatively small business, we felt really well taken care of. The team helped work around our challenges and we’ve already seen the average number of our applicants grow by 180% through Revolut People’s LinkedIn job posting integration.”',
    quoteBy: 'Nikolaj Gaba, CMO & Partner · TechScale',
  },
]

const CustomerStory = ({ story }: { story: CustomerStoryInterface }) => {
  return (
    <Grid columns={{ all: 1, md: 2 }} gap="s-32">
      <Box alignSelf="center" order={{ all: 2, md: 1 }}>
        <AspectRatio ratio={484 / 420} maxWidth={484} maxHeight={420} margin="0 auto">
          <Image src={story.image} alt={story.company} radius="r24" scale="contain" />
        </AspectRatio>
      </Box>
      <VStack gap="s-16" alignSelf="center" order={{ all: 1, md: 2 }}>
        <Image src={story.logo} size={64} alt={story.company} />
        <Text variant="h6" color={Token.color.greyTone50}>
          Customer story
        </Text>
        <Text variant="h5">{story.quote}</Text>
        <Text variant="primary">{story.quoteBy}</Text>
      </VStack>
    </Grid>
  )
}

export const CustomerStories = () => {
  const isTablet = useMatchMedia(Token.media.md)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  if (isTablet) {
    return (
      <>
        <Carousel
          aria-label="Slide change handler"
          index={currentSlideIndex}
          onIndexChange={setCurrentSlideIndex}
        >
          {stories.map(story => (
            <Carousel.Item key={story.company} width={1}>
              <CustomerStory story={story} />
            </Carousel.Item>
          ))}
        </Carousel>
        {stories.length > 1 ? (
          <Flex mt="s-16" justifyContent="center">
            <PageIndicator
              mt={{ all: 's-16', md: 's-24', xxl: 's-32' }}
              current={currentSlideIndex}
              total={stories.length}
              onClick={setCurrentSlideIndex}
            />
          </Flex>
        ) : null}
      </>
    )
  }

  return <CustomerStory story={stories[0]} />
}
