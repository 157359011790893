import React, { useState } from 'react'
import { Button, Popup, Text, Box } from '@revolut/ui-kit'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'

type Props = {
  onSubmit: (agendaValue: string) => void
  onClose: () => void
  agenda: string
}

const EDITOR_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link'],
    ['clean'],
  ],
}

export const MeetingAgendaPopup = ({ onClose, onSubmit, agenda }: Props) => {
  const [agendaValue, setAgendaValue] = useState<string>(agenda)

  return (
    <Popup onClose={onClose} open size="md">
      <Text variant="h4" fontWeight={600}>
        Edit agenda
      </Text>
      <Box mt="s-8">
        <HTMLEditor
          placeholder="Agenda"
          onChange={(val?: string) => {
            setAgendaValue(val || '')
          }}
          value={agendaValue}
          modules={EDITOR_MODULES}
          height={210}
          addMarginToParagraphs
        />
      </Box>

      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSubmit(agendaValue)}>Save</Button>
      </Popup.Actions>
    </Popup>
  )
}
