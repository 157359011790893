import React from 'react'
import { Tag, Token } from '@revolut/ui-kit'
import { StageTypeInterface } from '@src/interfaces/hiringProccess'

export const getStageTypeIcon = (stageType: StageTypeInterface) => {
  switch (stageType.id) {
    case 'cv_screening':
    case 'screen_call':
      return 'Search'
    case 'home_assessment':
    case 'online_test':
    case 'skills_round':
      return 'RepairTool'
    case 'final_round':
      return 'Trophy'
    default:
      return null
  }
}

export const getStageTypeLabel = (stageType: StageTypeInterface) => {
  switch (stageType.id) {
    case 'cv_screening':
    case 'screen_call':
      return 'Screening'
    case 'skills_round':
      return 'Skills'
    case 'home_assessment':
    case 'online_test':
    case 'final_round':
    default:
      return stageType.name
  }
}

type HiringStageProps = {
  stageType: StageTypeInterface
}

export const HiringStageTypeTag = ({ stageType }: HiringStageProps) => {
  return (
    <Tag
      color={Token.color.greyTone50}
      useIcon={getStageTypeIcon(stageType)}
      variant="outlined"
    >
      {getStageTypeLabel(stageType)}
    </Tag>
  )
}

export default HiringStageTypeTag
