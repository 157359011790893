import React from 'react'

import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { ROUTES } from '@src/constants/routes'
import { useQuery } from '@src/utils/queryParamsHooks'
import { LocationDescriptor, LocationState } from 'history'

export const IAPRedirect = () => {
  const { query } = useQuery<{ redirect_url?: string }>()

  let to: LocationDescriptor<LocationState>

  if (query.redirect_url) {
    const [pathname, search] = decodeURIComponent(query.redirect_url).split('?')
    // if not passed explicitly, react-router doesn't recognize "?" url separator
    to = { pathname, ...(search ? { search: `?${search}` } : null) }
  } else {
    to = ROUTES.MAIN
  }
  return <InternalRedirect to={to} />
}
