import { VisuallyHidden } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext, useLapeField } from '@src/features/Form/LapeForm'
import {
  EpicOption,
  KpiInterface,
  KpiTargetEpics,
  UpdateTypes,
} from '@src/interfaces/kpis'
import { ReviewCycleCategory, ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { JiraSearchWidget } from '@src/pages/OnboardingChecklist/Roadmaps/JiraSearch'
import React, { useMemo } from 'react'
import { TargetFormFields } from '../SidebarForms/TargetFormFields'
import toString from 'lodash/toString'
import { ManualForm } from './ManualForm'
import { FormType } from './types'
import { ClickupTasks } from '@src/pages/OnboardingChecklist/Roadmaps/ClickupTasks'
import { LookerForm } from './LookerForm'
import { TableauForm } from './TableauForm'
import { uniqBy } from 'lodash'
import { SalesforceForm } from './SalesforceForm'

export const TargetFieldsByType = ({
  type,
  cycle,
  variant = 'new',
}: {
  type?: UpdateTypes
  cycle?: ReviewCyclesInterface
  variant?: FormType
}) => {
  const form = useLapeContext<KpiInterface>()
  const { values, apiErrors } = form
  const targetEpicsField = useLapeField('target_epics.0.epics')

  const epics = useMemo(() => {
    return values.target_epics?.length
      ? values.target_epics[0].epics.reduce((acc, epicOption) => {
          if (epicOption.key) {
            return {
              ...acc,
              [epicOption.key]: {
                display_name: epicOption.name,
                epic_name: epicOption.name,
                epic_url: epicOption.url,
                id: epicOption.id,
                issue_type: 'Epic', // only epics could be selected
                key: epicOption.key,
              },
            }
          }
          return acc
        }, {})
      : undefined
  }, [values.target_epics?.length])

  switch (type) {
    case UpdateTypes.roadmap:
      return (
        <>
          <VisuallyHidden>
            <LapeNewInput name="target_epics.0.epics" />
          </VisuallyHidden>
          <JiraSearchWidget
            description={targetEpicsField?.error && toString(targetEpicsField.error)}
            invalid={!!targetEpicsField?.error}
            forceSelected={epics}
            onSelectionChange={selected => {
              if (!values.target_epics) {
                values.target_epics = []
              }

              const target: KpiTargetEpics = values.target_epics[0] || {}
              target.epics = Object.values(selected).map(
                epic =>
                  ({
                    key: epic.key,
                    name: epic.epic_name,
                    owner: epic.owner,
                    url: epic.epic_url,
                    review_cycle:
                      cycle?.category === ReviewCycleCategory.Performance
                        ? cycle
                        : undefined,
                  } as EpicOption),
              )
              values.target_epics[0] = target
            }}
          />
        </>
      )
    case UpdateTypes.clickup:
      return (
        <>
          <VisuallyHidden>
            <LapeNewInput name="target_epics.0.epics" />
          </VisuallyHidden>
          <ClickupTasks
            error={
              apiErrors.target_epics?.at(0)?.epics
                ? String(apiErrors.target_epics?.at(0)?.epics)
                : undefined
            }
            onDelete={(key: string | null) => {
              values.target_epics[0].epics = values.target_epics[0].epics.filter(
                epic => epic.key !== key,
              )
            }}
            addedTasks={values.target_epics?.at(0)?.epics}
            onSelectionChange={selectedOptions => {
              const mappedOptions: EpicOption[] = selectedOptions.map(
                option =>
                  ({
                    key: option.key,
                    name: option.name,
                    url: option.url,
                    owner: { id: values.owner.id, name: values.owner.name },
                    review_cycle:
                      values.target_epics?.[0].review_cycle ||
                      values.targets?.[0].review_cycle,
                  } as EpicOption),
              )
              if (values.target_epics?.at(0) && values.target_epics[0].epics) {
                values.target_epics[0].epics = uniqBy(
                  [...values.target_epics[0].epics, ...mappedOptions],
                  'key',
                )
              } else {
                values.target_epics = [
                  // @ts-expect-error
                  // ignoring errors as interface expects some default values and in form we do not have it at this point
                  {
                    epics: mappedOptions,
                    review_cycle: values.targets?.[0].review_cycle,
                    employee_cycle: values.targets?.[0].employee_cycle,
                  },
                ]
              }
            }}
          />
        </>
      )
    case UpdateTypes.manual:
      return <ManualForm variant={variant} />
    case UpdateTypes.looker:
      return <LookerForm />
    case UpdateTypes.sql:
      return (
        <TargetFormFields
          variant={variant}
          hideLooker
          codeEditorProps={{
            responsive: true,
            width: '100%',
            height: 350,
            wrapResponsive: true,
          }}
        />
      )
    case UpdateTypes.tableau:
      return <TableauForm />
    case UpdateTypes.salesforce:
      return <SalesforceForm />
    default:
      return null
  }
}
