import { Reducer } from 'redux'
import { PerformanceActionTypes, PerformanceState } from './types'
import { produce } from 'immer'

const initialState: PerformanceState = {
  isGradesUploading: false,
}

const PerformanceReducer: Reducer<PerformanceState> = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case PerformanceActionTypes.START_UPLOAD:
        draft.isGradesUploading = true
        break
      case PerformanceActionTypes.FINISH_UPLOAD:
        draft.isGradesUploading = false
        break
      default:
        break
    }
  })
}

export default PerformanceReducer
