import React from 'react'
import { Avatar, IconName, Item, VStack, Widget } from '@revolut/ui-kit'

interface Props {
  iconName: IconName
  title: string
  description?: string
  children: React.ReactNode
}

export const InputGroupWidget = ({ iconName, title, description, children }: Props) => (
  <Widget>
    <Item>
      <Item.Avatar>
        <Avatar useIcon={iconName} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
    </Item>
    <VStack mx="s-20" mb="s-20" space="s-16">
      {children}
    </VStack>
  </Widget>
)
