import React, { useState } from 'react'

import { contractorsRequests, employeesRequestsNew } from '@src/api/employees'
import Form from '@src/features/Form/Form'
import { EmployeeType } from '@src/interfaces/employees'
import { CreateEmployeeForm } from './General'

export const CreateEmployee = () => {
  const [type, setType] = useState<EmployeeType>('internal')

  return type === 'external' ? (
    <Form api={contractorsRequests}>
      <CreateEmployeeForm onEmployeeTypeChange={setType} type={type} />
    </Form>
  ) : (
    <Form api={employeesRequestsNew}>
      <CreateEmployeeForm onEmployeeTypeChange={setType} type={type} />
    </Form>
  )
}
