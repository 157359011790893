import { FieldOptions } from '.'
import { EmployeeOptionInterface } from './employees'
import { ImportSessionStatus } from '@src/interfaces/importData'

export enum ImportAction {
  Terminate = 'Terminate',
  Update = 'Update',
  Create = 'Create',
}

export interface ExternalEmployeeInterface {
  email: string
  status: string
  first_name: string
  last_name: string
  line_manager: string
  location: string
  specialisation: string
  job_title: string
  team: string
  seniority: string
  joining_date_time: string
  termination_date_time: string
  errors: Record<string, string[] | undefined>
  action: ImportAction
  vendor_name?: string
}

export interface ImportExternalEmployeesMetadata {
  id: number
  created_by: EmployeeOptionInterface
  status: { id: ImportSessionStatus; name: string }
  employees_to_activate_count: number
  employees_to_create_count: number
  employees_to_update_count: number
  employees_to_terminate_count: number
  total_employees_count: number
  invalid_employees_count: number
  field_options: FieldOptions
}

export interface ImportExternalEmployeesInterface
  extends ImportExternalEmployeesMetadata {
  results: ExternalEmployeeInterface[]
}
