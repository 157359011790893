import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { useQuery } from '@src/utils/queryParamsHooks'
import { pathToUrl } from '@src/utils/router'
import HubApp from '@src/features/HubApp/HubApp'
import { APPLICATIONS, globalSettingsAppTabsFilter } from '@src/constants/hub'
import { useGetEmployeeSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { API } from '@src/constants/api'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EditableContractsBulkSession } from '@src/features/EditableContractsTable/BulkSession'
import { IS_EMPLOYEE_UPDATE_QUERY_KEY } from '@src/features/EditableEmployeesTable/common/constants'
import { EmployeesBulkSessionDynamic } from '@src/features/EditableEmployeesTable/TableDynamic/BulkSession'
import { EmployeesBulkSessionDetailed } from '@src/features/EditableEmployeesTable/TableDetailed/BulkSession'
import { SelectBulkUploadFlow } from './BulkUpload/SelectBulkUploadFlow'

const Employees = () => {
  const { query } = useQuery()
  const isUpdatingExistingEmployees = !!query[IS_EMPLOYEE_UPDATE_QUERY_KEY]

  const { data: employeeSettings } = useGetEmployeeSettings()
  const tabs = APPLICATIONS.employees.tabs.filter(tab =>
    globalSettingsAppTabsFilter(tab.homeSectionId, { employeeSettings }),
  )
  const employeesUpdateFallbackTitle = 'Update existing employees’ information'

  return (
    <Switch>
      <Route path={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.ANY}>
        <Route
          path={[
            ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.IMPORT,
            ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SESSION,
          ]}
        >
          <EmployeesBulkSessionDetailed
            isUpdatingExistingEmployees
            importRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.IMPORT}
            sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.SESSION}
            anyRoute={ROUTES.APPS.EMPLOYEES.BULK_UPDATE_EMPLOYEES.ANY}
            onAfterConfirmRoute={ROUTES.PEOPLE.EMPLOYEES}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={title => (
              <PageHeader
                title={title || employeesUpdateFallbackTitle}
                backUrl={pathToUrl(ROUTES.PEOPLE.EMPLOYEES)}
              />
            )}
          />
        </Route>
      </Route>
      <Route path={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.ANY}>
        <Route path={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.SELECT_FLOW}>
          <SelectBulkUploadFlow
            importEmployeesRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.IMPORT}
            importContractsRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.IMPORT}
          />
        </Route>
        <Route
          path={[
            ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.IMPORT,
            ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.SESSION,
          ]}
        >
          <EmployeesBulkSessionDynamic
            isUpdatingExistingEmployees={isUpdatingExistingEmployees}
            importRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.IMPORT}
            sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.SESSION}
            anyRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_EMPLOYEES.ANY}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            onAfterConfirmRoute={ROUTES.PEOPLE.EMPLOYEES}
            getHeader={title => {
              const fallbackTitle = isUpdatingExistingEmployees
                ? employeesUpdateFallbackTitle
                : 'Create new employees'
              return (
                <PageHeader
                  title={title || fallbackTitle}
                  backUrl={pathToUrl(ROUTES.PEOPLE.EMPLOYEES)}
                />
              )
            }}
          />
        </Route>
      </Route>
      <Route path={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.ANY}>
        <Route
          path={[
            ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.IMPORT,
            ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.SESSION,
          ]}
        >
          <EditableContractsBulkSession
            importRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.IMPORT}
            sessionRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.SESSION}
            anyRoute={ROUTES.APPS.EMPLOYEES.BULK_UPLOAD_CONTRACTS.ANY}
            apiEndpoint={API.CONTRACTS_UPLOAD}
            onAfterConfirmRoute={ROUTES.PEOPLE.EMPLOYEES}
            renderHeader={() => (
              <PageHeader
                title="Create new contracts"
                backUrl={pathToUrl(ROUTES.PEOPLE.EMPLOYEES)}
              />
            )}
          />
        </Route>
      </Route>
      <Route>
        <HubApp app={{ ...APPLICATIONS.employees, tabs }} />
      </Route>
    </Switch>
  )
}

export default Employees
