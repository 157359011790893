import React from 'react'
import { ROUTES } from '@src/constants/routes'
import SlackIntegration from '@src/pages/Hub/Integrations/Slack'

import { Route, Switch } from 'react-router-dom'

export const Integrations = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.ANY}>
        <SlackIntegration />
      </Route>
    </Switch>
  )
}
