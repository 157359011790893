import { MouseEvent } from 'react'

interface DragArgs {
  selectedIds: (number | string)[]
  source: number
  destination: number
  ids: (number | string)[]
}

const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const reorderSingleDrag = ({ ids, source, destination }: DragArgs) => {
  const reordered = reorder(ids, source, destination)

  return reordered
}

const reorderMultiDrag = ({ selectedIds, destination, ids }: DragArgs) => {
  const base = ids.filter(id => !selectedIds.includes(id))
  base.splice(destination, 0, ...selectedIds)

  return base
}

export const multiDragReorder = (args: DragArgs) => {
  if (args.selectedIds.length > 1) {
    return reorderMultiDrag(args)
  }
  return reorderSingleDrag(args)
}

export const multiSelect = (
  ids: (number | string)[],
  selectedIds: (number | string)[],
  newId: number | string,
) => {
  // Nothing selected
  if (!selectedIds.length) {
    return [newId]
  }

  const indexOfNew = ids.indexOf(newId)

  const lastSelected = selectedIds[selectedIds.length - 1]
  const indexOfLast = ids.indexOf(lastSelected)

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null
  }

  const isSelectingForwards: boolean = indexOfNew > indexOfLast
  const start: number = isSelectingForwards ? indexOfLast : indexOfNew
  const end: number = isSelectingForwards ? indexOfNew : indexOfLast

  const inBetween = ids.slice(start, end + 1)

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected

  const toAdd = inBetween.filter((taskId): boolean => {
    // if already selected: then no need to select it again
    if (selectedIds.includes(taskId)) {
      return false
    }
    return true
  })

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse()
  const combined = [...selectedIds, ...sorted]

  return combined
}

// Determines if the platform specific toggle selection in group key was used
export const wasToggleInSelectionGroupKeyUsed = (event: MouseEvent | KeyboardEvent) => {
  const isUsingWindows = navigator.platform.indexOf('Win') >= 0
  return isUsingWindows ? event.ctrlKey : event.metaKey
}

// Determines if the multiSelect key was used
export const wasMultiSelectKeyUsed = (event: MouseEvent | KeyboardEvent) => event.shiftKey
