import styled from 'styled-components'
import Icon, { IconSizes } from '../Icon/Icon'
import React from 'react'
import { Token } from '@revolut/ui-kit'

const Wrapper = styled.div`
  display: grid;
  align-content: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`

const Loader = (props: { size?: IconSizes; className?: string }) => {
  return (
    <Wrapper className={props.className} data-testid="loader">
      <Icon type="Spinner" size={props.size || 'massive'} color={Token.color.blue} />
    </Wrapper>
  )
}

export default Loader
