import React, { useMemo } from 'react'
import capitalize from 'lodash/capitalize'
import {
  Box,
  Flex,
  Tag,
  Text,
  Token,
  VStack,
  FilterButton,
  Ellipsis,
  Icon,
  useDropdown,
  RadioSelect,
  TextSkeleton,
} from '@revolut/ui-kit'
import { PayCycleInterface, PayCycleStatus } from '@src/interfaces/payroll'
import { formatPeriod } from '@src/utils/format'

interface Props {
  cycles?: PayCycleInterface[]
  isLoading: boolean
  onChange: (selector?: PayCycleInterface) => void
  selectedCycleId: number
}

const getTitleAndColor = (status?: PayCycleStatus) => {
  if (status === 'open') {
    return {
      title: 'current',
      color: Token.color.green,
      bgColor: Token.color.green_20,
    }
  }

  if (status === 'scheduled') {
    return {
      title: 'upcoming',
      color: Token.color.greyTone50,
      bgColor: Token.color.greyTone10,
    }
  }

  if (status === 'closed' || status === 'pending_closure') {
    return {
      title: 'history',
      color: Token.color.warning,
      bgColor: Token.color.orange_20,
    }
  }

  return { title: '', color: '', bgColor: '' }
}

export const PayCycleSelect = ({
  cycles = [],
  isLoading,
  onChange,
  selectedCycleId,
}: Props) => {
  const dropdown = useDropdown()
  const options = useMemo(
    () =>
      cycles.map(option => {
        const period = formatPeriod(
          new Date(option.start_date),
          new Date(option.end_date),
        )
        return {
          key: option.id,
          label: period,
          value: { ...option, name: period },
        }
      }),
    [cycles],
  )

  const selectedPeriod = useMemo(() => {
    return options.filter(option => option?.value.id === selectedCycleId)[0]?.value
  }, [options, selectedCycleId])

  const { title } = getTitleAndColor(selectedPeriod?.status.id)

  return (
    <Box>
      <FilterButton
        useIcon="Calendar"
        aria-haspopup="listbox"
        aria-expanded={dropdown.state}
        pending={isLoading}
        {...dropdown.getAnchorProps()}
      >
        <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
          {selectedPeriod ? (
            <>
              <Ellipsis>
                <Text variant="emphasis2">
                  {formatPeriod(
                    new Date(selectedPeriod.start_date),
                    new Date(selectedPeriod.end_date),
                  ) || 'Select cycle'}
                </Text>
              </Ellipsis>
              <Text variant="emphasis2" color={Token.color.greyTone50}>
                {capitalize(title)}
              </Text>
            </>
          ) : (
            <TextSkeleton size={8} />
          )}
          <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
        </Flex>
      </FilterButton>
      <RadioSelect
        {...dropdown.getTargetProps()}
        label="Payroll cycle"
        options={options}
        value={selectedPeriod}
        onChange={val => {
          if (val) {
            onChange(cycles?.find(item => item.id === val.id))
            dropdown.toggle()
          }
        }}
        indicatorStyle="highlight"
        labelList="Payroll cycles"
        searchable
      >
        {option => {
          const {
            bgColor: optionBgColor,
            color: optionColor,
            title: optionTitle,
          } = getTitleAndColor(option.value.status.id)
          return (
            <VStack gap="s-4">
              <Text>{option.label}</Text>
              <Tag bg={optionBgColor} color={optionColor} variant="faded">
                {optionTitle}
              </Tag>
            </VStack>
          )
        }}
      </RadioSelect>
    </Box>
  )
}
