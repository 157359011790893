import React from 'react'
import { ROUTES } from '@src/constants/routes'
import CulturalSkills from '@src/pages/Forms/Company/Values/CulturalSkills'
import { Route, Switch } from 'react-router-dom'
import { Flex } from '@revolut/ui-kit'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import CompetencyMatrix from '@src/pages/Forms/Company/Values/CompetencyMatrix'
import { CulturalSkillInterface } from '@src/interfaces/skills'
import { useTable } from '@components/Table/hooks'
import { culturalSkillsRequests } from '@src/api/skills'

const tabs = [
  {
    title: 'Values',
    path: ROUTES.FORMS.COMPANY.VALUES.CULTURAL_SKILLS,
    to: ROUTES.FORMS.COMPANY.VALUES.CULTURAL_SKILLS,
    component: CulturalSkills,
  },
  {
    title: 'Competency matrix',
    path: ROUTES.FORMS.COMPANY.VALUES.COMPETENCY_MATRIX,
    to: ROUTES.FORMS.COMPANY.VALUES.COMPETENCY_MATRIX,
    component: CompetencyMatrix,
  },
]

const CompanyValues = () => {
  const culturalSkillsTable = useTable<CulturalSkillInterface>(culturalSkillsRequests)

  return (
    <Flex flexDirection="column" minHeight={0}>
      <Flex alignItems="flex-start" mb="s-24">
        <TabBarNavigation isSubtab tabs={tabs} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component table={culturalSkillsTable} />
          </Route>
        ))}
      </Switch>
    </Flex>
  )
}

export default CompanyValues
