import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { Retry } from '@revolut/icons'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import { Group, MoreBar, Subheader, VStack } from '@revolut/ui-kit'
import SettingsButtons, { EditButton, ArchiveButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { updateMembers } from '@src/api/accessManagement'
import { getLastUpdatedInfo } from './common'
import { EntityPermissions } from '@src/store/auth/types'
import { Statuses } from '@src/interfaces'

const Details = () => {
  const queryClient = useQueryClient()

  const { initialValues, values, reset } =
    useLapeContext<GroupAccessManagementInterface>()

  const [updatePending, setUpdatePending] = useState(false)

  const assignGroupsAutomatically = !!initialValues.settings?.audience_selection_type

  const onUpdateGroup = () => {
    if (!initialValues.id) {
      return
    }

    setUpdatePending(true)

    updateMembers(initialValues.id)
      .then(() => {
        reset({
          ...initialValues,
          settings: {
            ...initialValues.settings,
            audience_update_date_time: new Date().toISOString(),
          },
        } as GroupAccessManagementInterface)
        queryClient.invalidateQueries(`/groups/${initialValues.id}/stats`)
        pushNotification({
          value: 'Group membership updated',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
      })
      .finally(() => setUpdatePending(false))
  }

  return (
    <>
      <PageBody>
        <SettingsButtons mb="s-24">
          <EditButton
            route={pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.GENERAL, {
              id: initialValues.id,
            })}
          />
          <ArchiveButton
            isVisible={values.settings?.status === Statuses.active}
            entityPermissions={EntityPermissions.Delete}
            unArchiveStatus={Statuses.active}
            statusFieldName="settings.status"
            showDialog
          />
          {assignGroupsAutomatically && (
            <MoreBar.Action
              onClick={onUpdateGroup}
              pending={updatePending}
              useIcon={Retry}
            >
              Update members
            </MoreBar.Action>
          )}
        </SettingsButtons>

        <FormPreview title="Group details" data={initialValues}>
          <Group>
            <FormPreview.Item title="Name" field="name" />
            <FormPreview.Item<GroupAccessManagementInterface>
              title="Permissions"
              insert={data => data.permissions?.map(item => item.name)?.join(', ')}
            />
            <FormPreview.Item title="Owner" field="settings.owner" type="employee" />
            <FormPreview.Item
              title="Certification expiration date"
              field="settings.certification_expiration_date_time"
              type="date"
            />
            <FormPreview.Item title="Description" field="settings.description" />
          </Group>
        </FormPreview>

        <FormPreview
          customHeader={() => (
            <Subheader variant="nested">
              <Subheader.Title>Group rules</Subheader.Title>
              <Subheader.Side>
                {getLastUpdatedInfo(initialValues.settings?.audience_update_date_time)}
              </Subheader.Side>
            </Subheader>
          )}
          data={initialValues}
        >
          <VStack space="s-16">
            <Group>
              <FormPreview.Item
                title="Assign group automatically"
                insert={() => (assignGroupsAutomatically ? 'Yes' : 'No')}
              />
              {assignGroupsAutomatically && (
                <FormPreview.Item
                  title="Audience selection"
                  field="settings.audience_selection_type.name"
                />
              )}
            </Group>
          </VStack>
        </FormPreview>
      </PageBody>
    </>
  )
}

export default Details
