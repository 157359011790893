import React, { useState } from 'react'

import {
  Button,
  Flex,
  Stars,
  Text,
  TextArea,
  Box,
  useStatusPopup,
  StatusPopup,
} from '@revolut/ui-kit'
import { feedbackRequests } from '@src/api/feedback'
import { FeedbackSubmitInterface } from '@src/store/feedback/types'

type Props = {
  handleClose: () => void
}
export const RateExperienceSidebarContent = ({ handleClose }: Props) => {
  const [pending, setPending] = useState(false)
  const [score, setScore] = useState<number | null>(null)
  const [text, setText] = useState<string>()

  const statusPopup = useStatusPopup()

  return (
    <Flex flexDirection="column" alignItems="center">
      <Text variant="h1" use="h1" textAlign="center">
        Rate your experience with Help Center
      </Text>
      <Box my="s-24">
        <Stars value={score} onChange={setScore} size={32} disabled={pending} />
      </Box>
      <TextArea
        value={text}
        onChange={e => setText(e.currentTarget.value)}
        label="Leave your review..."
        rows={3}
        disabled={pending}
      />
      <Button
        onClick={async () => {
          if (score === null) {
            return
          }
          try {
            setPending(true)
            await feedbackRequests.postItem({
              score: score * 0.2,
              text,
              status: { id: 'completed' },
              topic: { id: 'help_center' },
            } as FeedbackSubmitInterface)

            handleClose()
            statusPopup.show(
              <StatusPopup variant="success">
                <StatusPopup.Title>
                  Your feedback was successfully submitted
                </StatusPopup.Title>
              </StatusPopup>,
            )
          } catch (e) {
            setPending(false)
          }
        }}
        disabled={!score}
        pending={pending}
        mt="s-48"
        maxWidth={343}
        elevated
      >
        Submit
      </Button>
    </Flex>
  )
}
