import React, { useState } from 'react'
import {
  Text,
  VStack,
  HStack,
  Details,
  DetailsCell,
  DetailsCellSkeleton,
  TextButton,
  chain,
} from '@revolut/ui-kit'

import { CommentsAPIInterface } from '@src/interfaces/chat'
import ChatMessage, { ChatMessageProps } from './ChatMessage'
import { ChatMessageType } from './common'
import ChatTagsManager from './ChatTagsManager'
import ChatTextEditor, { ChatTextEditorVariant } from './ChatTextEditor/ChatTextEditor'
import { InfoIconWithTooltip } from '@components/Icon/InfoIconWithTooltip'
import { ChatPopup } from './ChatPopup'

export interface ChatWidgetProps {
  commentsApi: CommentsAPIInterface
  canEdit: boolean
  prefix?: string
  disableTodolistFeature?: boolean
  title?: React.ReactNode
  showAll?: boolean
  keepResponse?: boolean
}

export const ChatWidget = (props: ChatWidgetProps) => {
  const { commentsApi, prefix, canEdit, disableTodolistFeature = true } = props
  const { data: commentsData, isLoading, refetch } = commentsApi.useGetComments()
  const [allShown, setAllShown] = useState(false)

  const commonChatProps: Pick<
    ChatMessageProps,
    | 'type'
    | 'onEdit'
    | 'onArchive'
    | 'refetch'
    | 'canEdit'
    | 'disableTodolistFeature'
    | 'onResolve'
  > & { disableTodolistFeature: boolean } = {
    type: ChatMessageType.Comment,
    onEdit: commentsApi.editComment,
    onArchive: commentsApi.archiveComment,
    onResolve: commentsApi.resolveComment,
    refetch,
    canEdit,
    disableTodolistFeature,
  }

  const commentsCount = commentsData?.results.length || 0

  return (
    <ChatTagsManager>
      {isLoading ? (
        <DetailsCellSkeleton />
      ) : (
        <>
          <DetailsCell pt="s-16" pb="s-16">
            <DetailsCell.Title>
              <HStack space="s-8" align="center">
                <Text>{chain('Comments', commentsCount)}</Text>
                <InfoIconWithTooltip
                  size={16}
                  tooltipProps={{ maxWidth: '280px' }}
                  content="These comments will be visible to managers of this user, as well as their calibrators and admins. Comments are discoverable items."
                />
              </HStack>
            </DetailsCell.Title>
            {commentsCount > 1 ? (
              <DetailsCell.Content>
                <TextButton onClick={() => setAllShown(true)}>See all</TextButton>
              </DetailsCell.Content>
            ) : null}
            <Details.Note mt="s-12">
              <VStack space="s-16">
                {commentsCount ? (
                  <ChatMessage
                    {...commonChatProps}
                    key={commentsData?.results[0].id}
                    message={commentsData?.results[0]!}
                    bg="grey-tone-2"
                    disableTodolistFeature={disableTodolistFeature}
                  />
                ) : null}
                <ChatTextEditor
                  disableTodolistFeature={disableTodolistFeature}
                  variant={ChatTextEditorVariant.Input}
                  onSubmit={(msgBody, createTask) => {
                    const message = prefix ? `${prefix} ${msgBody}` : msgBody
                    return commentsApi
                      .addComment(message, createTask)
                      .then(() => refetch())
                  }}
                />
              </VStack>
            </Details.Note>
          </DetailsCell>
          <ChatPopup
            messages={commentsData?.results || []}
            refetch={refetch}
            isLoading={isLoading}
            onClose={() => setAllShown(false)}
            open={allShown}
            canEdit={canEdit}
            disableTodolistFeature
            commentsApi={commentsApi}
          />
        </>
      )}
    </ChatTagsManager>
  )
}
