import React from 'react'
import { Text, Color } from '@revolut/ui-kit'

import { TimeOffCategoryInterface } from '@src/interfaces/timeOff'

interface TimeOffPolicyCategoryProps {
  category?: TimeOffCategoryInterface
}

export const TimeOffPolicyCategory = ({ category }: TimeOffPolicyCategoryProps) => {
  if (!category) {
    return <>-</>
  }
  if (category.is_archived) {
    return (
      <Text>
        {category.name} <Text color={Color.RED}>(Archived)</Text>
      </Text>
    )
  }
  return <>{category.name}</>
}
