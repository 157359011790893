import React from 'react'
import { Box, Flex, Widget } from '@revolut/ui-kit'

import { CalibratedGradeInterface } from '@src/interfaces/performance'
import Grade from './Grade'
import GradesInfo from './GradesInfo'

type Props = {
  calibratedGrade?: CalibratedGradeInterface | null
}

const SidebarCalibratedGrade = ({ calibratedGrade }: Props) => {
  if (!calibratedGrade?.grade_calibrated) {
    return null
  }

  return (
    <Widget p="s-16" mt="s-16" data-testid="calibrated-grade">
      <Flex mt="s-4" justifyContent="flex-start">
        <Grade value={calibratedGrade} />
      </Flex>
      <Box mt="s-16">
        <GradesInfo />
      </Box>
    </Widget>
  )
}

export default SidebarCalibratedGrade
