import React, { useMemo } from 'react'
import SectionHeader from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/SectionHeader'
import { Box, Text, Widget } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import ResultHireSection from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/ResultHireSection'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import { OptionInterface } from '@src/interfaces/selectors'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { InterviewRoundSummaryInterface } from '@src/interfaces/interviewTool'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { performanceToString } from '@src/features/Scorecard/constants'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

type Props = {
  summary?: InterviewRoundSummaryInterface
  isViewMode?: boolean
}

const HiringPanelForm = ({ summary, isViewMode = false }: Props) => {
  const { options, asyncState } = useFetchOptions<RadioOption>(
    selectorKeys.interview_feedback_result_choices,
  )

  const seniorityOptions = useMemo(() => {
    const items: RadioOption[] = [
      ...(summary?.seniorities?.map(o => ({
        label: `${o.name} (${performanceToString(o.suggested_grade)})`,
        value: o,
      })) || []),
    ]

    return items
  }, [summary?.seniorities])

  if (!summary) {
    return null
  }

  return (
    <Widget p="s-16">
      <Box mb="s-32">
        <SectionHeader title="1. What is the candidate’s seniority according to your evaluation?" />
        <LapeNewRadioButtons
          name="evaluated_seniority"
          options={seniorityOptions}
          readOnly={isViewMode}
          renderRadio={radio => <Box py="s-8">{radio}</Box>}
        />
      </Box>
      <Box mb="s-32">
        <ResultHireSection
          disabled={asyncState !== 'ready' || isViewMode}
          options={options}
          titleIndexNumber={2}
          type={InterviewScorecardType.HiringPanel}
        />
      </Box>
      <Box mb="s-32">
        <SectionHeader
          title={
            <>
              <Text color="foreground">
                3. Select the requisition you recommend this candidate for
              </Text>
              <Text color="grey-tone-50"> (Optional)</Text>
            </>
          }
        />
        <LapeRadioSelectInput<OptionInterface>
          name="recommended_requisition"
          label="Select"
          selector={selectorKeys.requisitions}
          disabled={isViewMode}
        />
      </Box>
      <SectionHeader title="4. Justification for your decisions" />
      <LapeNewTextArea
        name="overall_impressions"
        label="Enter why you selected a seniority and recommendation"
        rows={3}
        readOnly={isViewMode}
        required
      />
    </Widget>
  )
}

export default HiringPanelForm
