import React from 'react'
import { HorizontalBarChart, Text, VStack, Widget } from '@revolut/ui-kit'
import {
  mapScoreToColor,
  NON_ANSWERED_KEY,
  SCORE_KEYS,
} from '@src/apps/People/Engagement/Results/components/constants'
import { formatPercentage } from '@src/utils/format'
import { EngagementResultsScoresInterface } from '@src/interfaces/engagement'
import { getTotalAnswers } from '@src/apps/People/Engagement/Results/components/DistributionChart/helpers'

type Props = {
  scores: EngagementResultsScoresInterface
  unanswered: number
}
export const Distribution = ({ unanswered, scores }: Props) => {
  const total = getTotalAnswers({ scores, unanswered })

  return (
    <Widget p="s-16" width={350}>
      <VStack space="s-16">
        <Text variant="caption">Scores distribution</Text>
        <HorizontalBarChart
          data={SCORE_KEYS.map(scoreKey => {
            const scoreValue =
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              scoreKey === NON_ANSWERED_KEY ? unanswered : scores[scoreKey]
            return {
              labelLeft: String(scoreKey),
              value: Math.round((scoreValue / total) * 100),
              labelRight: `${formatPercentage(scoreValue / total, 2)}`,
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              color: mapScoreToColor[scoreKey],
            }
          })}
          role="img"
          aria-label="Distribution bar chart"
        />
      </VStack>
    </Widget>
  )
}
