import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useRemindToNominateByGrowthPlan } from '@src/api/growthPlans'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface Props {
  growthPlan: GrowthPlanInterface
}

export const RemindToNominateAction = ({ growthPlan }: Props) => {
  const { mutateAsync: remindToNominate, isLoading } = useRemindToNominateByGrowthPlan(
    growthPlan.id,
  )

  const showStatusPopup = useShowStatusPopup()

  const handleClick = async () => {
    try {
      await remindToNominate()
      showStatusPopup({ title: 'A reminder was successfully scheduled' })
    } catch (err) {
      showStatusPopup({
        title: 'Failed to schedule a reminder',
        description: getStringMessageFromError(err),
        status: 'error',
      })
    }
  }

  return (
    <MoreBar.Action
      variant="accent"
      useIcon="Trophy"
      pending={isLoading}
      onClick={handleClick}
    >
      Remind me to nominate
    </MoreBar.Action>
  )
}
