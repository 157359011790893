import React from 'react'

import { BudgetDistributionProvider } from '@src/features/BudgetDistribution/BudgetDistribution'
import {
  getFunctionCompensationReviews,
  useFunctionCompensationYearOptions,
} from '@src/api/compensation'
import { TeamAndFunctionBudgetTable } from '@src/features/BudgetDistribution/TeamAndFunctionBudgetTable'

export default () => (
  <BudgetDistributionProvider
    api={getFunctionCompensationReviews}
    optionsApi={useFunctionCompensationYearOptions}
  >
    <TeamAndFunctionBudgetTable />
  </BudgetDistributionProvider>
)
