import { BottomSheet, Button, Header } from '@revolut/ui-kit'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import React from 'react'
import { string } from 'yup'

interface FormValues {
  reason: string
}

const RejectFixForm = ({ closePopup }: { closePopup: VoidFunction }) => {
  const { isSubmitting, errors, submit, dirty, hasSubmitted, apiErrors } =
    useLapeContext<FormValues>()
  return (
    <>
      <LapeNewTextArea
        label="Reason"
        required
        name="reason"
        rows={3}
        hasError={((dirty || hasSubmitted) && !!errors.reason) || !!apiErrors.reason}
        message={((dirty || hasSubmitted) && errors.reason) || apiErrors.reason}
      />
      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={() => closePopup()} disabled={isSubmitting}>
          Cancel
        </Button>
        <Button onClick={() => submit()} pending={isSubmitting} elevated>
          Submit
        </Button>
      </BottomSheet.Actions>
    </>
  )
}

interface Props {
  open: boolean
  onClose: VoidFunction
  onSubmit: (data: FormValues) => Promise<FormValues>
}

export const TicketFeedbackRejectPopup = ({ open, onClose, onSubmit }: Props) => {
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Reject fix</Header.Title>
        <Header.Subtitle>Provide reason why this issue is not fixed</Header.Subtitle>
      </Header>
      <LapeForm<FormValues>
        validation={{ reason: string().required() }}
        onSubmit={form => {
          if (!form.valid) {
            return Promise.reject()
          }
          return onSubmit({ reason: form.values.reason })
        }}
      >
        <RejectFixForm closePopup={onClose} />
      </LapeForm>
    </BottomSheet>
  )
}
