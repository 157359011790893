import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { PayGroupPreview } from './Preview'
import { PayGroupEdit } from './General'

export const Paygroup = () => (
  <Switch>
    <Route path={ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW}>
      <PayGroupPreview />
    </Route>
    <Route path={ROUTES.APPS.PAYROLL.PAY_GROUP.EDIT}>
      <PayGroupEdit />
    </Route>
  </Switch>
)
