import React from 'react'

import { TableActionButton } from '@components/Button/TableActionButton'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@components/TableV2/Table'
import { navigateTo } from '@src/actions/RouterActions'
import { getAllKeyPersonResponsibilitiesAssignments } from '@src/api/keyPerson'
import {
  keyPersonEmployeeResponsibilitiesActionsColumn,
  keyPersonEmployeeResponsibilitiesEndDateColumn,
  keyPersonEmployeeResponsibilitiesEntityColumn,
  keyPersonEmployeeResponsibilitiesNameColumn,
  keyPersonEmployeeResponsibilitiesRoleTypeColumn,
  keyPersonEmployeeResponsibilitiesStartDateColumn,
  keyPersonEmployeeResponsibilitiesStatusColumn,
  keyPersonEmployeeResponsibilitiesSuccessorsColumn,
} from '@src/constants/columns/keyPersonEmployeeResponsibilities'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import { pathToUrl } from '@src/utils/router'

interface Props {
  data: EmployeeInterface
}
const ROW: RowInterface<KeyPersonResponsibilityAssignmentInterface> = {
  cells: [
    {
      ...keyPersonEmployeeResponsibilitiesNameColumn,
      width: 200,
    },
    {
      ...keyPersonEmployeeResponsibilitiesEntityColumn,
      width: 150,
    },
    {
      ...keyPersonEmployeeResponsibilitiesStartDateColumn,
      width: 150,
    },
    {
      ...keyPersonEmployeeResponsibilitiesEndDateColumn,
      width: 150,
    },
    {
      ...keyPersonEmployeeResponsibilitiesSuccessorsColumn,
      width: 350,
    },
    {
      ...keyPersonEmployeeResponsibilitiesRoleTypeColumn,
      width: 200,
    },
    {
      ...keyPersonEmployeeResponsibilitiesStatusColumn,
      width: 200,
    },
    {
      ...keyPersonEmployeeResponsibilitiesActionsColumn,
      width: 180,
      insert: ({ data }) => (
        <TableActionButton
          onClick={() => {
            navigateTo(
              pathToUrl(
                ROUTES.FORMS.EMPLOYEE.GENERAL.KEY_PERSON_RESPONSIBILITY_CHANGELOG,
                { id: String(data.employee?.id), responsibilityId: String(data.id) },
              ),
            )
          }}
        >
          View changelog
        </TableActionButton>
      ),
    },
  ],
}

export const KeyPersonResponsibilities = ({ data }: Props) => {
  const table = useTable<KeyPersonResponsibilityAssignmentInterface>({
    getItems: getAllKeyPersonResponsibilitiesAssignments(data.id),
  })

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<KeyPersonResponsibilityAssignmentInterface>
          name={TableNames.KeyPersonEmployeeResponsibilities}
          useWindowScroll
          dataType="Responsibility"
          row={ROW}
          {...table}
          noDataMessage="Employee has no assigned responsibilities"
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
