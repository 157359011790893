import { TabBarTableNavigationProps } from '@src/features/TabBarNavigation/TabBarNavigation'
import { ConfigurableTabBarNavigationInterface } from '@src/features/TabBarNavigationEditable/types'
import { TabBarNavigationNames } from '@src/constants/tabBarNavigation'
import { LocationDescriptor, LocationState } from 'history'
import React from 'react'
import {
  useTabBarSettings,
  getTabBarBySettings,
} from '@src/features/TabBarNavigationEditable/common'
import { InternalRedirect } from '@components/InternalLink/InternalRedirect'

type Props = Omit<TabBarTableNavigationProps, 'tabs'> & {
  tabs: ConfigurableTabBarNavigationInterface[]
  name: TabBarNavigationNames
}

export const RedirectToFirstTab = ({ tabs, name }: Props) => {
  const storedSettings = useTabBarSettings(name)
  const defaultSettings = {
    reordered: tabs?.map(tab => tab.key) || [],
  }
  const settings = storedSettings || defaultSettings
  const reorderedTabs = getTabBarBySettings(name, settings, tabs)

  return (
    <InternalRedirect to={reorderedTabs[0].to as LocationDescriptor<LocationState>} />
  )
}
