import { Box, Flex, Text, Widget, Spinner, Grid } from '@revolut/ui-kit'
import React from 'react'
import { Target, Flag } from '@revolut/icons'
import {
  ProbationTemplateTimeline,
  ProbationTemplateTimelineType,
} from '@src/interfaces/probationTemplate'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'
import { formatToOrdinal } from '@src/utils/format'

export const PointWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  right: 13px;
  top: 2px;
  transform: translateY(-50%);
  cursor: default;
`

const Timeline = styled(Grid)<{ weeks: number }>`
  grid-template-columns: ${props => `repeat(${props.weeks}, minmax(0, 1fr))`};
  grid-template-rows: auto;
`

const IconWrapper = styled.div`
  transition: all 0.3s;
  &:hover {
    transform: scale(1.2);
  }
`

const StepWrapper = ({
  children,
  text,
}: {
  children?: React.ReactElement
  text: string
}) => {
  return (
    <Flex justifyContent="center" mx="s-2" width="25px" backgroundColor="background">
      <Tooltip text={text} placement="top">
        <IconWrapper>{children}</IconWrapper>
      </Tooltip>
    </Flex>
  )
}

const getTimelineStepIcon = (
  step: ProbationTemplateTimelineType,
  week: number,
  checkpointIndex?: number,
) => {
  const tooltipText = `${formatToOrdinal(week)} week`
  switch (step) {
    case ProbationTemplateTimelineType.goals:
      return (
        <StepWrapper text={tooltipText}>
          <Target color="deep-grey" />
        </StepWrapper>
      )
    case ProbationTemplateTimelineType.review:
      return (
        <StepWrapper text={tooltipText}>
          <Box
            backgroundColor="deep-grey"
            color="background"
            width="20px"
            height="20px"
            borderRadius="50%"
          >
            <Flex justifyContent="center">{checkpointIndex}</Flex>
          </Box>
        </StepWrapper>
      )
    case ProbationTemplateTimelineType.decision:
      return (
        <StepWrapper text={tooltipText}>
          <Flag color="deep-grey" />
        </StepWrapper>
      )
    default:
      return null
  }
}

export const TimelinePreview = ({
  timelines,
  totalWeeks,
  isLoading = false,
}: {
  timelines: ProbationTemplateTimeline[]
  totalWeeks: number
  isLoading?: boolean
}) => {
  const points: { [key: number]: ProbationTemplateTimelineType } = {}
  timelines.forEach(el => {
    let weeksNum = el.weeks_due_date
    if (el.timeline_type === ProbationTemplateTimelineType.decision) {
      weeksNum = totalWeeks - weeksNum
    }

    points[weeksNum] = el.timeline_type
  })

  let checkpointInd = 1

  return (
    <Widget p="s-16">
      <Flex justifyContent="space-between">
        <Text variant="primary">Timeline preview</Text>
        {totalWeeks && (
          <Text variant="small" color="grey-tone-50">
            Total weeks: {totalWeeks}
          </Text>
        )}
      </Flex>

      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" height="59px">
          <Spinner />
        </Flex>
      ) : (
        <>
          <Timeline mt="s-32" weeks={totalWeeks}>
            {[...Array(totalWeeks).keys()].map(ind => {
              if (points[ind]) {
                const currentCheckpointNum = checkpointInd
                if (points[ind] === ProbationTemplateTimelineType.review) {
                  checkpointInd += 1
                }
                return (
                  <Box height="4px" backgroundColor="grey-tone-10" key={ind}>
                    <Flex alignItems="center">
                      <PointWrapper>
                        {getTimelineStepIcon(points[ind], ind, currentCheckpointNum)}
                      </PointWrapper>
                    </Flex>
                  </Box>
                )
              }
              return <Box height="4px" backgroundColor="grey-tone-10" key={ind} />
            })}
          </Timeline>

          <Flex justifyContent="space-between" mt="s-16">
            <Text variant="small" color="grey-tone-50">
              Joining date
            </Text>
            <Text variant="small" color="grey-tone-50">
              Probation end
            </Text>
          </Flex>
        </>
      )}
    </Widget>
  )
}
