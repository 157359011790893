import reduce from 'lodash/reduce'
import {
  KPIEpicsTargetRow,
  KpiTargetEpics,
  EpicProgress,
  EpicOption,
} from '@src/interfaces/kpis'

const calculateProgressForCycle = (targetEpic: KpiTargetEpics): EpicProgress => {
  const progress: EpicProgress = {
    todo: 0,
    progress: 0,
    done: 0,
  }
  targetEpic.epics.forEach(epic => {
    progress.todo += epic.todo
    progress.progress += epic.progress
    progress.done += epic.done
  })
  const numEpics = targetEpic.epics.length

  return reduce(
    progress,
    (result, value, key) => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      result[key] = value / numEpics
      return result
    },
    {} as EpicProgress,
  )
}

const getCycleStatus = ({ progress, todo, done }: EpicProgress): string => {
  if (progress > 0) {
    return 'In Progress'
  }
  if (done === 100 && todo === 0) {
    return 'Done'
  }
  return 'To Do'
}

export const parseTargetEpicsForTable = (
  targetEpics: KpiTargetEpics[],
): (KPIEpicsTargetRow | EpicOption)[] => {
  return targetEpics.map(target => {
    const targetProgress = calculateProgressForCycle(target)
    const targetKey = target.review_cycle
      ? target.review_cycle?.id
      : target.employee_cycle?.id

    return {
      ...target,
      ...targetProgress,
      uniqueKey: String(targetKey),
      children: target.epics.map(epic => ({
        ...epic,
        uniqueKey: `${targetKey}_${epic.id}`,
      })),
      jira_status: getCycleStatus(targetProgress),
    }
  })
}
