import React from 'react'
import styled from 'styled-components'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { defaultTheme } from '@src/styles/theme'

interface StickyContainerWrapperProps {
  sticky?: boolean
  maxWidth?: number
  isCenter?: boolean
}

const StickyContainerWrapper = styled.div<StickyContainerWrapperProps>`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 16px;
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : undefined)};
  justify-content: ${({ isCenter }) => (isCenter ? 'center' : 'flex-end')};
  height: 0;
  padding-right: ${({ isCenter }) => (isCenter ? '0' : '32px')};
  width: 100%;
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  margin-top: ${props => (props.sticky ? '0' : '64px')};
  bottom: 64px;
  z-index: ${props => (props.sticky ? defaultTheme.zIndex.popup : '1')};
`

interface Props extends StickyContainerWrapperProps {
  children: React.ReactNode
  className?: string
}

const StickyContainer = ({ children, className, ...props }: Props) => {
  const { values } = useLapeContext<{
    id?: number
  }>()
  const isExistingData = !!values?.id

  return (
    <StickyContainerWrapper className={className} sticky={isExistingData} {...props}>
      {children}
    </StickyContainerWrapper>
  )
}

export default connect(StickyContainer)
