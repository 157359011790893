import { useEffect } from 'react'

export const useSetDocumentTitle = (
  title: string = 'Revolut People: All-in-one HR, Performance & Recruitment',
  section?: string,
) => {
  useEffect(() => {
    document.title = section ? `${title} | ${section}` : title
  }, [title, section])
}
