import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { CompanyGoalsWeightsInterface } from '@src/interfaces/companyGoals'
import { LapeFormProps } from '@src/features/Form/LapeForm'

export const companyGoalsSubmitRequest: LapeFormProps<CompanyGoalsWeightsInterface>['onSubmit'] =
  form => {
    return api
      .post(API.COMPANY_GOALS_WEIGHTS, form.values)
      .then(response => response.data)
  }
