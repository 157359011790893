import React from 'react'
import styled from 'styled-components'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { CellTypes, RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import { selectorKeys } from '@src/constants/api'
import {
  notificationDescription,
  notificationsActionColumn,
  notificationsDeadline,
  notificationsEmployeeColumn,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import { notificationsKpisRequests } from '@src/api/notifications'
import { formatNumberMillions } from '@src/utils/format'

const Capitalize = styled.div`
  text-transform: capitalize;
`

const KPIs = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  const RowNotifications: RowInterface<NotificationsInterface> = {
    cells: [
      {
        ...notificationDescription(employeeId),
        width: 200,
      },
      {
        type: CellTypes.insert,
        idPoint: 'kpi_type',
        dataPoint: 'kpi_type',
        sortKey: 'kpi_type',
        filterKey: 'kpi_type',
        selectorsKey: selectorKeys.kpi_types,
        title: 'Type',
        insert: ({ data }) => {
          return <Capitalize>{data.kpi_type?.replace(/_/g, ' ')}</Capitalize>
        },
        width: 140,
      },
      {
        type: CellTypes.insert,
        idPoint: 'initial',
        dataPoint: 'initial',
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.none,
        title: 'Initial',
        insert: ({ data }) => {
          return formatNumberMillions(data.initial)
        },
        width: 100,
      },
      {
        type: CellTypes.insert,
        idPoint: 'target',
        dataPoint: 'target',
        sortKey: null,
        filterKey: null,
        selectorsKey: selectorKeys.none,
        title: 'Target',
        insert: ({ data }) => {
          return formatNumberMillions(data.target)
        },
        width: 100,
      },
      {
        ...notificationsEmployeeColumn,
        width: 230,
      },
      {
        ...notificationsReceivedColumn,
        width: 160,
      },
      {
        ...notificationsDeadline,
        width: 150,
      },
      {
        ...notificationsPriorityColumn,
        width: 120,
      },
      {
        ...notificationsActionColumn,
        width: 200,
      },
    ],
  }

  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsKpisRequests(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default KPIs
