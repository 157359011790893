import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ActionBar, FilterButton } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { PermissionTransferProps } from '@src/pages/PermissionTransfer'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { CellTypes, RowInterface, Stats } from '@src/interfaces/data'
import { useTable } from '@components/TableV2/hooks'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import {
  permissionsEmployeeNameColumn,
  permissionsNotesColumn,
  permissionsPermissionColumn,
  permissionsStatusColumn,
  permissionsValidFromColumn,
  permissionsValidTillColumn,
} from '@src/constants/columns/permissions'
import { selectorKeys } from '@src/constants/api'
import PermissionTransferRevokeDialogue from '@src/pages/PermissionTransfer/Dialogue/PermissionTransferRevokeDialogue'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface PermissionManagerTableProps extends PermissionTransferProps {}

const ROW: RowInterface<PermissionsInterface> = {
  cells: [
    {
      ...permissionsEmployeeNameColumn,
      width: 300,
    },
    {
      ...permissionsPermissionColumn,
      width: 250,
    },
    {
      ...permissionsStatusColumn,
      width: 200,
    },
    {
      ...permissionsValidFromColumn,
      width: 200,
    },
    {
      ...permissionsValidTillColumn,
      width: 150,
    },
    {
      ...permissionsNotesColumn,
      width: 200,
    },
  ],
}

const defaultPermissionFilters = [
  { name: 'Active', id: 'active' },
  { name: 'Upcoming', id: 'upcoming' },
]

const PermissionTransferTable = ({
  entityName,
  backPath,
  tableApi,
  formApi,
  selector,
}: PermissionManagerTableProps) => {
  const params = useParams<{ entityId: string }>()
  const [revokingData, setRevokingData] = useState<PermissionsInterface | boolean>()
  const table = useTable<PermissionsInterface, Stats, { entity?: { name: string } }>(
    tableApi,
    [
      {
        columnName: 'status',
        filters: defaultPermissionFilters,
      },
    ],
  )
  const [showExpired, setShowExpired] = useState(false)
  const backUrl = pathToUrl(backPath, { id: params.entityId })

  ROW.cells[1].selectorsKey = selector
  ROW.cells[5] = {
    type: CellTypes.insert,
    idPoint: '',
    dataPoint: '',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    insert: ({ data }) => {
      return (
        <ActionBar>
          <ActionBar.Item>Edit</ActionBar.Item>
          {data.status?.id !== 'expired' && (
            <ActionBar.Item
              color="red"
              onClick={e => {
                e.stopPropagation()
                setRevokingData(data)
              }}
            >
              Revoke
            </ActionBar.Item>
          )}
        </ActionBar>
      )
    },
    title: 'Action',
    width: 150,
  }

  const handleRowEdit = (row: { id: number }) => {
    navigateTo(
      pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.FORM, { ...params, id: row.id }),
    )
  }

  const handleShowExpiredSwitch = () => {
    if (showExpired) {
      table.resetFiltersAndSorting(
        [
          {
            filters: defaultPermissionFilters,
            columnName: 'status',
            nonResettable: true,
          },
          ...table.filterBy,
        ],
        table.sortBy,
      )
      setShowExpired(false)
    } else {
      table.resetFiltersAndSorting(
        [
          {
            filters: [],
            columnName: 'status',
            nonResettable: true,
          },
          ...table.filterBy,
        ],
        table.sortBy,
      )
      setShowExpired(true)
    }
  }

  return (
    <PageWrapper>
      <PermissionTransferRevokeDialogue
        permission={(revokingData || undefined) as PermissionsInterface}
        revoke={id => formApi?.delete?.({ id }, params)}
        isOpen={!!revokingData}
        setOpen={setRevokingData}
        onAfterSubmit={() => {
          setRevokingData(false)
          table.refresh()
        }}
      />
      <PageHeader
        title="Manage permissions"
        subtitle={`${entityName}: ${table.metadata?.entity?.name || ''}`}
        backUrl={backUrl}
      />

      <Table.Widget>
        <Table.Widget.Filters>
          <FilterButton onClick={handleShowExpiredSwitch} active={showExpired}>
            Show expired permissions
          </FilterButton>
        </Table.Widget.Filters>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.FORM, params)}
            >
              Assign Permissions
            </PrimaryAction>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<PermissionsInterface>
            name={TableNames.PermissionTransfer}
            useWindowScroll
            dataType="Permission"
            row={ROW}
            {...table}
            onRowClick={handleRowEdit}
            emptyState={<EmptyTableRaw title="Permissions will appear here" />}
          />
        </Table.Widget.Table>
      </Table.Widget>
    </PageWrapper>
  )
}

export default PermissionTransferTable
