import React from 'react'
import { connect } from 'lape'
import { Route, Switch } from 'react-router-dom'

import { documentTemplatesFormRequest } from '@src/api/documentsTemplates'
import Form from '@src/features/Form/Form'
import { PageWrapper } from '@src/components/Page/Page'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { ROUTES } from '@src/constants/routes'

import General from './General'
import Preview from './Preview'
import { PreviewTemplateInEditor } from '@src/pages/Forms/DocumentsTemplate/PreviewTemplateInEditor'

export const fileSectionName = (template: Partial<DocumentsTemplatesInterface>) => {
  if (template.template_type?.id === 'request') {
    return 'Example'
  }
  if (template.template_type?.id === 'generate') {
    return 'Template'
  }
  if (template.template_type?.id === 'esignature') {
    return 'E-signature template configuration'
  }
  return ''
}

const DocumentTemplate = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.DOCUMENT_TEMPLATE.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW}>
          <Preview />
        </Route>
        <Route exact path={ROUTES.FORMS.DOCUMENT_TEMPLATE.PREVIEW_IN_EDITOR}>
          <PreviewTemplateInEditor />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={documentTemplatesFormRequest}>
    <DocumentTemplate />
  </Form>
))
