import React from 'react'
import { Link } from '@revolut/ui-kit'

import { MOVERS_PROCESS_LINK } from '@src/constants/externalLinks'

export const accessWillBeResetMessage = (
  <>
    The changes you have made include changes in team, role or location therefore the
    employee’s access to internal systems will be reset to default, unless specified
    otherwise in the{' '}
    <Link href={MOVERS_PROCESS_LINK} target="_blank" rel="noopener noreferrer">
      movers process
    </Link>
  </>
)
