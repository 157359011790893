import React, { useState } from 'react'
import capitalize from 'lodash/capitalize'
import { Group, MoreBar, StatusWidget, Text, VStack } from '@revolut/ui-kit'

import { getStatusColor } from '@components/CommonSC/General'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { downloadDocusignEmbedded } from '@src/api/documents'
import { ROUTES } from '@src/constants/routes'
import {
  DocusignDocumentInterface,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { PermissionTypes } from '@src/store/auth/types'
import { formatDate } from '@src/utils/format'
import { pathToUrl } from '@src/utils/router'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

type Props = {
  data: DocusignDocumentInterface
  documentListItem: EmployeeDocumentListItemInterface
}
export const DocuSignPreview = ({ data, documentListItem }: Props) => {
  const [isDownloadPending, setIsDownloadPending] = useState(false)

  const canDownload =
    data?.status === 'completed' &&
    data?.field_options?.permissions?.includes?.(
      PermissionTypes.DownloadDocusignenveloperecipients,
    )

  const showStatusPopup = useShowStatusPopup()

  return (
    <VStack space="s-16">
      <MoreBar>
        {canDownload && (
          <MoreBar.Action
            onClick={async () => {
              try {
                setIsDownloadPending(true)
                await downloadDocusignEmbedded(data?.envelope?.id)
              } catch (e) {
                showStatusPopup({
                  title: 'Failed to download file',
                  description: 'Something went wrong. Please try again later.',
                  status: 'error',
                })
              } finally {
                setIsDownloadPending(false)
              }
            }}
            useIcon="Download"
            pending={isDownloadPending}
          >
            Download
          </MoreBar.Action>
        )}
        <MoreBar.Action
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.DOCUMENT_DOCUSIGN.GENERAL, {
            id: documentListItem.recipient_id,
            employeeId: documentListItem.employee.id,
          })}
          useIcon="ArrowThinRight"
        >
          Go to details page
        </MoreBar.Action>
      </MoreBar>
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D310.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D310@3x.png',
          }}
        />
        <StatusWidget.Title>This document can't be previewed</StatusWidget.Title>
      </StatusWidget>
      <FormPreview data={data}>
        <Group>
          <FormPreview.Item<DocusignDocumentInterface>
            title="Status"
            field="status"
            insert={d =>
              d.status ? (
                <Text color={getStatusColor(d.status)}>{capitalize(d.status)}</Text>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item<DocusignDocumentInterface>
            title="Issued by"
            field="sender.name"
            to={d =>
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                id: d.sender.id,
              })
            }
          />
          <FormPreview.Item title="Sent date" field="sent_date_time" type="date" />
          <FormPreview.Item<DocusignDocumentInterface>
            title="Signed on"
            field="sent_date_time"
            insert={d => {
              if (d.signed_date_time) {
                return formatDate(d.signed_date_time)
              }
              return 'Not signed yet'
            }}
          />
        </Group>
      </FormPreview>
    </VStack>
  )
}
