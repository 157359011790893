import { IconName, SelectOptionItemType, Token } from '@revolut/ui-kit'
import { Id } from '@src/interfaces'
import {
  InterviewFeedbackInterface,
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { StageItemRecommendationProps } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemRecommendation'
import { formatSnakeCase } from '@src/utils/string'

export type NewOption = Id<'new'>
export type OptionType = InterviewRoundInterface | NewOption
export type SelectOptionType = SelectOptionItemType<OptionType>

export const isNewOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<NewOption> => String(option.key) === 'new'

export const isInterviewRoundSelectOption = (
  option: SelectOptionType,
): option is SelectOptionItemType<InterviewRoundInterface> => String(option.key) !== 'new'

export const isInterviewRound = (option: OptionType): option is InterviewRoundInterface =>
  'specialisation' in option

export const isArchivedOpportunityOption = (option?: OptionType) =>
  !!(option && 'archived_reason' in option && !!option.archived_reason)

const candidateSchedulingStatuses = ['pending_candidate_response', 'scheduling_expired']
export const isCandidateSchedulingStatuses = (
  stage: InterviewStageWithoutRoundInterface,
) => {
  return candidateSchedulingStatuses.includes(stage.scheduling_status)
}

export const isOnlineTestStage = (stage: InterviewStageWithoutRoundInterface) => {
  return stage.online_test && stage.online_test_result
}

export const getOnlineTestStageRecommendationProps = (
  stage: InterviewStageWithoutRoundInterface,
): StageItemRecommendationProps => {
  if (!stage.online_test_result || stage.online_test_result?.is_passed === null) {
    return {
      color: Token.color.greyTone50,
      text: 'n/a',
    }
  }
  if (stage.online_test_result?.is_passed) {
    return {
      color: Token.color.success,
      iconName: 'SocialLike',
      text: 'Pass',
    }
  }
  return {
    color: Token.color.red,
    iconName: 'SocialDislike',
    text: 'Fail',
  }
}

export const getFeedbackRecommendationProps = (
  feedback: InterviewFeedbackInterface,
): StageItemRecommendationProps => {
  const { result, status } = feedback
  let iconName: undefined | IconName
  let color = Token.color.greyTone50
  let text = 'n/a'
  if (status === 'rejected') {
    color = Token.color.greyTone50
    text = 'n/a'
  } else if (status === 'completed') {
    switch (result) {
      case 'hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'strong_hire':
        iconName = 'SocialLike'
        color = Token.color.success
        break
      case 'no_hire':
        iconName = 'SocialDislike'
        color = Token.color.danger
        break
      case 'strong_no_hire':
        iconName = 'SocialDislike'
        color = Token.color.danger
        break
      default:
        break
    }
    text = result ? formatSnakeCase(result) : ''
  }
  return {
    color,
    iconName,
    text,
  }
}
