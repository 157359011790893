import React from 'react'
import styled from 'styled-components'
import { Token, Link } from '@revolut/ui-kit'

import { Text } from './Text'

export const StyledLink = styled(Link)({
  color: Token.color.greyTone50,
  transition: 'color 0.15s',
  '&:hover': { color: Token.color.white },
  '&:active': { color: Token.color.white_80 },
})

export const FooterLink: React.FC<{ href?: string; target?: string }> = props => {
  return <Text use={StyledLink} variant="small" {...props} />
}
