import React from 'react'

import Table from '@components/TableV2/Table'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { DocumentsCategoriesInterface } from '@src/interfaces/documentsCategories'

export const documentCategoryName: ColumnInterface<DocumentsCategoriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.document_categories,
  title: 'Name',
  insert: ({ data }) => (
    <Table.EntityCell useIcon="Document">{data.name}</Table.EntityCell>
  ),
}
