import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import SenioritiesTable from './SenioritiesTable'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'

export const SenioritiesPage = () => {
  return (
    <PageBody>
      <PageHeader title="Seniorities" backUrl={ROUTES.ORGANISATION.ROLES.ROLES} />
      <PageWrapper>
        <SenioritiesTable />
      </PageWrapper>
    </PageBody>
  )
}
