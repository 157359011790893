import { InterviewAvailabilityStatus as InterviewAvailabilityStatusType } from '@src/interfaces/interviewAvailability'

export const getInterviewAvailabilityStatusText = (
  status: InterviewAvailabilityStatusType,
) => {
  switch (status) {
    case 'booked':
      return 'Busy'
    case 'pending':
      return 'Available'
    case 'holiday':
      return 'Holiday'
    default:
      return ''
  }
}
