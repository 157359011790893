import React from 'react'
import { Avatar } from '@revolut/ui-kit'
import { iconsMap } from '@src/features/Notifications/icons'
import { AvatarSize } from '@revolut/ui-kit/types/dist/components/Avatar/constants'
import { Bell } from '@revolut/icons'

interface NotificationsIconProps {
  name?: string | null
  unread?: boolean
  size?: AvatarSize
}

export const NotificationsIcon = ({
  name,
  unread = false,
  size = 40,
}: NotificationsIconProps) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  const icon = name ? iconsMap[name] : null

  return (
    <Avatar useIcon={icon?.icon ?? Bell} size={size} color={icon?.color ?? 'blue'}>
      {unread ? <Avatar.Badge position="top-right" variant="dot" /> : null}
    </Avatar>
  )
}
