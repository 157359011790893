import React from 'react'
import CVPreview from '@src/features/CVPreviewSidebar/CVPreview'
import { Action, Box, Group, Icon, Item, Subheader, Token, VStack } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetPathWithWorkspace } from '@src/actions/RouterActions'

type Props = {
  linkedIn?: string
  candidateId?: number
  canViewCandidate: boolean
}

export const InterviewFeedbackSideCV = ({
  linkedIn,
  candidateId,
  canViewCandidate,
}: Props) => {
  const getPathWithWorkspace = useGetPathWithWorkspace()

  return (
    <VStack gap="s-16">
      {(linkedIn || canViewCandidate) && (
        <Group>
          {linkedIn && (
            <Item
              use="a"
              useIcon="LogoLinkedIn"
              href={linkedIn}
              target="_blank"
              aria-label="LinkedIn profile"
            >
              <Item.Content>
                <Item.Title>LinkedIn profile</Item.Title>
              </Item.Content>
              <Item.Side>
                <Icon name="LinkExternal" color={Token.color.blue} />
              </Item.Side>
            </Item>
          )}
          {canViewCandidate && (
            <Item
              use="a"
              useIcon="Profile"
              href={getPathWithWorkspace(
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: candidateId }),
              )}
              target="_blank"
              aria-label="View candidate profile"
            >
              <Item.Content>
                <Item.Title>View candidate profile</Item.Title>
              </Item.Content>
              <Item.Side>
                <Icon name="LinkExternal" color={Token.color.blue} />
              </Item.Side>
            </Item>
          )}
        </Group>
      )}

      <Box>
        <CVPreview
          renderHeader={src => (
            <Subheader variant="default">
              <Subheader.Title>Candidate CV</Subheader.Title>
              <Subheader.Side>
                <Action use="a" href={src} target="_blank" rel="noopener noreferrer">
                  Open in new tab
                </Action>
              </Subheader.Side>
            </Subheader>
          )}
          height="calc(100vh - 130px)"
          id={candidateId}
        />
      </Box>
    </VStack>
  )
}
