import cronstrue from 'cronstrue'
import { ReportInterface } from '@src/interfaces/dataAnalytics'

export const getScheduleMessage = (values: ReportInterface) => {
  if (!values.schedule) {
    return null
  }

  let message = ''

  try {
    message = cronstrue.toString(values.schedule)
  } catch {
    message = 'Invalid cron value'
  }

  return message
}

export const getDefaultSchedule = () => {
  const randomMinute = Math.floor(Math.random() * 60)
  const randomHour = Math.floor(Math.random() * 24)
  const randomDay = Math.floor(Math.random() * 7)

  return `${randomMinute} ${randomHour} * * ${randomDay}`
}
