import React, { useState } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import isArray from 'lodash/isArray'
import { importKPIs, kpiUploadTemplateURL } from '@src/api/importData'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const Upload = () => {
  const [importPending, setImportPending] = useState<boolean>()

  const onFileUpload = (file: File) => {
    setImportPending(true)

    importKPIs(file)
      .then(response => {
        const url = pathToUrl(ROUTES.FORMS.IMPORT_DATA.KPIS.REVIEW_DATA, {
          id: response.data.id,
        })
        navigateTo(url)
      })
      .finally(() => setImportPending(false))
  }

  const onDownloadTemplate = () => {
    window.open(kpiUploadTemplateURL, '_blank')
  }

  return (
    <PageWrapper>
      <PageHeader title="Upload KPIs" backUrl={ROUTES.PEOPLE.EMPLOYEES} mb="28px" />
      <PageBody>
        <ImportFile
          importPending={importPending}
          onDownloadTemplate={onDownloadTemplate}
          supportedFormats={['CSV']}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else if (file) {
              onFileUpload(file)
            }
          }}
        />
      </PageBody>
    </PageWrapper>
  )
}
export default Upload
