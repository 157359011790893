import React, { useState } from 'react'
import { Token } from '@revolut/ui-kit'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import {
  EmployeeResignationInterface,
  ResignationApprovalInterface,
} from '@src/interfaces/employeeResignation'
import {
  requestResignationFile,
  resignationReasonsFormRequests,
} from '@src/api/employeeResignation'
import { SubmitPopup } from '../Components/SubmitPopup'
import { getInfoDataFromResignation } from './getInfoDataFromResignation'

type Props = {
  resignation: EmployeeResignationInterface
  getResignation: () => Promise<void>
  approval?: ResignationApprovalInterface
  getApprovals: () => Promise<void>
}
export const NextStepInfo = ({
  resignation,
  getResignation,
  approval,
  getApprovals,
}: Props) => {
  const [isSubmitNoticeRequestPopupShown, setIsSubmitNoticeRequestPopupShown] =
    useState<boolean>(false)
  const [isSubmitNoticeRequestPending, setIsSubmitNoticeRequestPending] =
    useState<boolean>(false)

  const onSubmitResignationDate = async (date: string) => {
    await resignationReasonsFormRequests.update(
      { resignation_date: date },
      { id: String(resignation.id), employeeId: String(resignation.employee.id) },
    )
  }

  const infoData = getInfoDataFromResignation({
    resignation,
    approval,
    onRequestNoticeClick: () => setIsSubmitNoticeRequestPopupShown(true),
    onSubmitResignationDate,
  })
  if (!infoData) {
    return null
  }
  const { title, text, actions } = infoData

  return (
    <>
      <ActionWidget title={title} text={text} avatarColor={Token.color.greyTone50}>
        {actions}
      </ActionWidget>

      <SubmitPopup
        open={isSubmitNoticeRequestPopupShown}
        pending={isSubmitNoticeRequestPending}
        title="Are you sure you want to request notice from employee?"
        subtitle="Once that’s done, employee will be notified to submit resignation letter and complete resignation form – this will trigger start of notice period."
        onClose={() => setIsSubmitNoticeRequestPopupShown(false)}
        onSubmit={async () => {
          setIsSubmitNoticeRequestPending(true)
          try {
            await requestResignationFile(
              String(resignation.employee.id),
              String(resignation.id),
            )
            await getResignation()
            await getApprovals()
          } finally {
            setIsSubmitNoticeRequestPending(false)
            setIsSubmitNoticeRequestPopupShown(false)
          }
        }}
      />
    </>
  )
}
