import { EmployeeInterface } from '@src/interfaces/employees'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'

interface EmployeeModel {
  getCurrentSeniority: (employee: EmployeeInterface) => SeniorityInterface | null
  getCurrentSeniorityName: (employee: EmployeeInterface) => string | undefined
  getCurrentSenioritySubLevelName: (employee: EmployeeInterface) => string | undefined
  getNextSeniorityName: (
    employee: EmployeeInterface,
    orderedSeniorityList: SeniorityInterface[],
  ) => string | undefined
  getNextSeniority: (
    employee: EmployeeInterface,
    orderedSeniorityList: SeniorityInterface[],
  ) => SeniorityInterface | null
  getCurrentSpecialisation: (employee: EmployeeInterface) => OptionInterface | null
  getCurrentSpecialisationName: (employee: EmployeeInterface) => string | undefined
}

const getCurrentSeniority = (employee: EmployeeInterface): SeniorityInterface | null =>
  employee.seniority ?? null

const getCurrentSeniorityName = (employee: EmployeeInterface): string | undefined =>
  employee.seniority?.name

const getCurrentSenioritySubLevelName = (
  employee: EmployeeInterface,
): string | undefined => employee.specialisation_seniority_sublevel?.name

const getNextSeniority = (
  employee: EmployeeInterface,
  orderedSeniorityList: SeniorityInterface[],
): SeniorityInterface | null => {
  if (employee.seniority === undefined) {
    return null
  }

  const currentSeniorityIdx = orderedSeniorityList.findIndex(
    ({ id }) => id === employee.seniority!.id,
  )

  return orderedSeniorityList[currentSeniorityIdx + 1] ?? null
}

const getNextSeniorityName = (
  employee: EmployeeInterface,
  orderedSeniorityList: SeniorityInterface[],
): string | undefined => {
  return getNextSeniority(employee, orderedSeniorityList)?.name
}

const getCurrentSpecialisation = (employee: EmployeeInterface): OptionInterface | null =>
  employee.specialisation

const getCurrentSpecialisationName = (employee: EmployeeInterface): string | undefined =>
  getCurrentSpecialisation(employee)?.name

const createEmployeeModel = (): EmployeeModel => {
  return {
    getCurrentSeniority,
    getCurrentSeniorityName,
    getCurrentSenioritySubLevelName,
    getNextSeniority,
    getNextSeniorityName,
    getCurrentSpecialisation,
    getCurrentSpecialisationName,
  }
}

export const employeeModel = createEmployeeModel()
