import React, { useEffect, useRef, useState } from 'react'
import { Button, BottomSheet, Header, Input } from '@revolut/ui-kit'

import { ESignatureTemplateInterface } from '@src/interfaces/documentsTemplates'
import {
  FieldActionsInterface,
  getFieldDataByIndexedKey,
  getFieldInfoFromIndexedKey,
  getFieldName,
  IndexedFieldKey,
  removeField,
  SCROLL_MARGIN,
} from './common'

export const useFieldActionsPopup = (
  values: ESignatureTemplateInterface,
  selectedFieldKey: IndexedFieldKey | undefined,
) => {
  const [fieldId, setFieldId] = useState<IndexedFieldKey>()
  const [fieldNewName, setFieldNewName] = useState<string>()
  const [activePopup, setActivePopup] = useState<'rename' | 'delete'>()
  const [keyPressed, setKeyPressed] = useState<string>()

  const showRename = (id: IndexedFieldKey) => {
    setFieldId(id)
    setActivePopup('rename')
  }
  const showDelete = (id?: IndexedFieldKey) => {
    setFieldId(id || selectedFieldKey)
    setActivePopup('delete')
  }
  const hide = () => {
    setActivePopup(undefined)
    setFieldNewName(undefined)
  }

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      setKeyPressed(e.key)
    }
    const onKeyUp = () => {
      setKeyPressed(undefined)
    }
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('keyup', onKeyUp)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('keyup', onKeyUp)
    }
  }, [])

  useEffect(() => {
    const isFieldAreaOrSettingsItemFocused = ['DIV', 'BUTTON'].includes(
      document.activeElement?.tagName || '',
    )
    if (isFieldAreaOrSettingsItemFocused && keyPressed === 'Backspace') {
      showDelete(selectedFieldKey)
    }
  }, [keyPressed])

  if (!fieldId) {
    return {
      component: null,
      showRename,
      showDelete,
      hide,
    }
  }
  const { type, idx } = getFieldInfoFromIndexedKey(fieldId)
  const fieldData = getFieldDataByIndexedKey(fieldId, values)
  const fieldName = getFieldName(fieldData, type, idx)

  return {
    component: (
      <>
        <BottomSheet open={activePopup === 'rename'} onClose={hide}>
          <Header>
            <Header.Title>Rename {fieldName}</Header.Title>
          </Header>
          <Input
            label="Enter new name"
            value={fieldNewName ?? fieldName}
            onChange={e => {
              setFieldNewName(e.currentTarget.value)
            }}
          />
          <BottomSheet.Actions horizontal>
            <Button onClick={hide} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (fieldNewName) {
                  fieldData.name = fieldNewName
                }
                hide()
              }}
              disabled={!fieldNewName}
              elevated
            >
              Confirm
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
        <BottomSheet open={activePopup === 'delete'} onClose={hide}>
          <Header>
            <Header.Title>Are you sure you want to delete {fieldName}?</Header.Title>
          </Header>
          <BottomSheet.Actions horizontal>
            <Button onClick={hide} variant="secondary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                removeField(fieldId, values)
                hide()
              }}
              elevated
            >
              Confirm
            </Button>
          </BottomSheet.Actions>
        </BottomSheet>
      </>
    ),
    showRename,
    showDelete,
    hide,
  }
}

type VisibleArea = { top: number; bottom: number; left: number; right: number }

export type EditorViewportParams = {
  toolbarYOverlap: number
  containerRefs: {
    doc: React.RefObject<HTMLDivElement>
    toolbar: React.RefObject<HTMLDivElement>
  }
  visibleArea: VisibleArea
}
export const useEditorViewport = (): EditorViewportParams => {
  const [toolbarYOverlap, setToolbarYOverlap] = useState(0)

  const documentContainerRef = useRef<HTMLDivElement>(null)
  const toolbarContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onScrollEnd = () => {
      const docRect = documentContainerRef.current?.getBoundingClientRect()
      const toolbarRect = toolbarContainerRef.current?.getBoundingClientRect()

      if (!docRect || !toolbarRect) {
        return
      }
      setToolbarYOverlap(toolbarRect.bottom - docRect.top)
    }
    window.addEventListener('scrollend', onScrollEnd)

    return () => {
      window.removeEventListener('scrollend', onScrollEnd)
    }
  }, [])

  const top = toolbarContainerRef.current?.getBoundingClientRect().bottom || 0
  const bottom = documentContainerRef.current?.getBoundingClientRect().bottom || 0
  const left = documentContainerRef.current?.getBoundingClientRect().left || 0
  const right = documentContainerRef.current?.getBoundingClientRect().right || 0

  return {
    toolbarYOverlap,
    containerRefs: { doc: documentContainerRef, toolbar: toolbarContainerRef },
    visibleArea: {
      top,
      bottom: bottom > window.innerHeight ? window.innerHeight : bottom,
      left,
      right,
    },
  }
}

export const useScrollFieldAreaIntoView = (
  {
    isSelected,
    noScrollIntoView,
  }: Pick<FieldActionsInterface, 'isSelected' | 'noScrollIntoView'>,
  editorViewport: EditorViewportParams,
) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isSelected || noScrollIntoView || !ref.current) {
      return
    }
    const areaRect = ref.current.getBoundingClientRect()
    const { visibleArea, containerRefs } = editorViewport

    const leftPosOverflow = visibleArea.left - areaRect.left
    const rightPosOverflow = areaRect.right - visibleArea.right
    const topPosOverflow = visibleArea.top - areaRect.top
    const bottomPosOverflow = areaRect.bottom - visibleArea.bottom

    window.scrollBy({
      left: 0,
      top:
        topPosOverflow + SCROLL_MARGIN > 0
          ? -1 * (topPosOverflow + SCROLL_MARGIN)
          : bottomPosOverflow + SCROLL_MARGIN > 0
          ? bottomPosOverflow + SCROLL_MARGIN * 2
          : 0,
      behavior: 'smooth',
    })

    containerRefs.doc.current?.scrollBy({
      top: 0,
      left:
        leftPosOverflow + SCROLL_MARGIN > 0
          ? -1 * (leftPosOverflow + SCROLL_MARGIN)
          : rightPosOverflow + SCROLL_MARGIN > 0
          ? rightPosOverflow + SCROLL_MARGIN
          : 0,
      behavior: 'smooth',
    })
  }, [isSelected])

  return ref
}

export const useScrollFieldSettingsIntoView = ({
  isSelected,
  noScrollIntoView,
}: Pick<FieldActionsInterface, 'isSelected' | 'noScrollIntoView'>) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!isSelected || noScrollIntoView) {
      return
    }
    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }, [isSelected, noScrollIntoView])

  return ref
}
