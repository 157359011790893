import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { connect } from 'lape'
import EligiblePeopleEditComponent from '@src/pages/Forms/EligiblePeopleEdit/EligiblePeopleEdit'
import { formEligibleEmployeesRequest } from '@src/api/supportTool/eligibleEmployees'
import Form from '@src/features/Form/Form'

const EligiblePeopleEdit = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.ELIGIBLE_PEOPLE_EDIT.GENERAL}>
        <Form api={formEligibleEmployeesRequest}>
          <EligiblePeopleEditComponent />
        </Form>
      </Route>
    </Switch>
  )
}

export default connect(EligiblePeopleEdit)
