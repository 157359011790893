import { RequestInterfaceNew, Statuses, TableRequestInterface } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  AutomationInterface,
  AutomationListInterface,
} from '@src/interfaces/jobPostings/automation'
import { ApplicationFormSectionInterface } from '@src/interfaces/applicationForm'

export const automationRequests = (): TableRequestInterface<AutomationListInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`/automationRules`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const automationRuleRequest: RequestInterfaceNew<AutomationInterface> = {
  get: async ({ id }) => api.get(`/automationRules/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`/automationRules/${id}`, data),
  submit: async data => apiWithoutHandling.post(`/automationRules`, data),
  delete: async ({ id }) => api.delete(`/automationRules/${id}`),
}

export const activateRule = (id: number, isActivated: boolean) =>
  automationRuleRequest.update(
    {
      status: {
        id: isActivated ? Statuses.active : Statuses.inactive,
      },
    },
    {
      id: String(id),
    },
  )

export const getGeneralSections = (jobPostingId: number) =>
  api.get<ApplicationFormSectionInterface[]>(
    `/jobPostings/${jobPostingId}/generalSections`,
  )
