import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Flex,
  Text,
  Widget,
  chain,
  Tooltip as UIKitTooltip,
  useTooltip,
  MoreButton,
  Tag,
  HStack,
  Token,
  Icon,
  TooltipState,
} from '@revolut/ui-kit'
import {
  AnytimeFeedbackInterface,
  AnytimeFeedbackRating,
  AnytimeFeedbackVisibility,
  FeedbackCategory,
  FeedbackCategoryTitles,
} from '@src/interfaces/anytimeFeedback'
import Tooltip from '@components/Tooltip/Tooltip'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { deleteAnytimeFeedback } from '@src/api/anytimeFeedback'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { PermissionTypes } from '@src/store/auth/types'
import { formatDateTime } from '@src/utils/format'
import isNil from 'lodash/isNil'
import { formatRelative } from 'date-fns'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EmployeeInterface } from '@src/interfaces/employees'
import differenceInDays from 'date-fns/differenceInDays'
import useShowMoreDescription from './useShowMoreDescription'

type Props = {
  feedback: AnytimeFeedbackInterface
  refreshFeedbackCard: () => void
  employeeData: EmployeeInterface
  type?: FeedbackCategory
  withBorder?: boolean
}

export const AnytimeFeedbackCard = ({
  feedback,
  refreshFeedbackCard,
  employeeData,
  type = FeedbackCategory.Anytime,
  withBorder = false,
}: Props) => {
  const [deletePending, setDeletePending] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const desciption = useShowMoreDescription(feedback.body)
  const feedbackReporter = feedback.reporter
  const feedbackReporterInitials =
    feedbackReporter?.full_name && getInitials(feedbackReporter.full_name)
  const tooltip = useTooltip()
  const canEditFeedback = !!feedback?.field_options?.permissions?.includes(
    PermissionTypes.CanEditFeedback,
  )
  const formPath =
    type === FeedbackCategory.Anytime
      ? ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME
      : ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE

  const editedAt = !isNil(feedback.update_date_time)
    ? formatRelative(new Date(feedback.update_date_time), new Date())
    : null

  // TODO: https://revolut.atlassian.net/browse/REVC-6274 - release once BE ready
  // replace 14 days hardcode with can_edit field check
  // const editDisabled = type === FeedbackCategory.Anytime && !feedback.can_edit
  const anytimeFeedbackEditingDeadline = 14
  const editDisabled =
    type === FeedbackCategory.Anytime &&
    differenceInDays(new Date(), new Date(feedback.creation_date_time)) >
      anytimeFeedbackEditingDeadline
  const deleteTooltip = useTooltip()
  const editTooltip = useTooltip()

  const feedbackIcons = {
    [AnytimeFeedbackRating.Superpower]: (
      <Icon name="SocialLike" color={Token.color.green} size={16} />
    ),
    [AnytimeFeedbackRating.Performing]: (
      <Icon name="LikeDislike" color={Token.color.greyTone50} size={16} />
    ),
    [AnytimeFeedbackRating.ImprovementArea]: (
      <Icon name="SocialDislike" color={Token.color.orange} size={16} />
    ),
  }

  const getRelationship = () => {
    if (feedbackReporter.id === employeeData.line_manager?.id) {
      return 'LM'
    }
    if (feedbackReporter.id === employeeData.quality_control?.id) {
      return 'FM'
    }
    return 'peer'
  }

  const handleDeleteFeedback = async () => {
    try {
      setDeletePending(true)
      await deleteAnytimeFeedback(feedback.employee.id, feedback.id)
      pushNotification({
        value: 'Feedback was deleted',
        duration: SUCCESS_DEFAULT_DURATION,
        type: NotificationTypes.success,
      })
      refreshFeedbackCard()
    } finally {
      setDeletePending(false)
      setShowDeletePopup(false)
    }
  }

  const renderButtonTooltip = (
    buttonTooltip: TooltipState<unknown>,
    action: 'edit' | 'delete',
  ) => {
    return (
      <UIKitTooltip
        {...buttonTooltip.getTargetProps()}
        placement="left-start"
        width={250}
      >
        Feedback was created more than 14 days ago and cannot be{' '}
        {action === 'edit' ? 'edited' : 'deleted'}
      </UIKitTooltip>
    )
  }

  return (
    <>
      {feedback && (
        <>
          <Box
            mb="s-16"
            border={withBorder ? `1px solid ${Token.color.greyTone10}` : undefined}
            borderRadius={16}
          >
            <Widget p="s-16">
              <Flex justifyContent="space-between" alignItems="center">
                <Flex>
                  <Flex flex={1} height={40} alignItems="center" gap="s-8" mr="s-16">
                    {feedbackReporterInitials && (
                      <Avatar image={getAvatarUrl(feedbackReporter?.avatar)} size={40}>
                        {feedbackReporterInitials}
                      </Avatar>
                    )}
                  </Flex>
                  <Flex flexDirection="column">
                    <Flex alignItems="center" style={{ fontWeight: 500 }}>
                      {chain(
                        `${feedbackReporter?.full_name} (${getRelationship()})`,
                        feedbackIcons[feedback.rating],
                      )}
                    </Flex>
                    <Flex color="grey-tone-50">
                      {chain(
                        formatDateTime(feedback.creation_date_time),
                        feedback.updated_by && editedAt && (
                          <HStack gap="s-4">
                            <Text>Edited</Text>
                            <Tooltip placement="top" text={editedAt}>
                              <Icon name="InfoOutline" size={12} />
                            </Tooltip>
                          </HStack>
                        ),
                      )}
                    </Flex>
                  </Flex>
                </Flex>
                <Flex alignItems="center">
                  <HStack space="s-8" mr="s-12">
                    <Tag variant="outlined" color={Token.color.deepGrey}>
                      {FeedbackCategoryTitles[feedback.category]}
                    </Tag>
                    {!!feedback.labels?.length && (
                      <Tag
                        variant="outlined"
                        color="deep-grey"
                        style={{ cursor: 'pointer' }}
                        {...tooltip.getAnchorProps()}
                      >
                        +{feedback.labels.length}
                        <UIKitTooltip
                          {...tooltip.getTargetProps()}
                          placement="bottom-start"
                          width="100"
                        >
                          <Box style={{ whiteSpace: 'break-spaces' }}>
                            {feedback.labels.join('\n')}
                          </Box>
                        </UIKitTooltip>
                      </Tag>
                    )}
                  </HStack>
                  {canEditFeedback && (
                    <MoreButton
                      variant="icon"
                      label="More actions"
                      useIcon="Ellipsis"
                      color={Token.color.greyTone50}
                    >
                      <MoreButton.Action
                        useIcon="Pencil"
                        disabled={!feedback.can_edit}
                        onClick={() => {
                          if (!editDisabled) {
                            navigateTo(
                              pathToUrl(formPath, {
                                userId: feedback.employee.id,
                                id: feedback.id,
                              }),
                            )
                          }
                        }}
                        aria-disabled={editDisabled}
                        {...editTooltip.getAnchorProps()}
                      >
                        Edit Feedback
                        {editDisabled && renderButtonTooltip(editTooltip, 'edit')}
                      </MoreButton.Action>
                      <MoreButton.Action
                        disabled={!feedback.can_edit}
                        useIcon="Delete"
                        onClick={() => {
                          if (!editDisabled) {
                            setShowDeletePopup(true)
                          }
                        }}
                        aria-disabled={editDisabled}
                        {...deleteTooltip.getAnchorProps()}
                      >
                        Delete Feedback
                        {editDisabled && renderButtonTooltip(deleteTooltip, 'delete')}
                      </MoreButton.Action>
                    </MoreButton>
                  )}
                </Flex>
              </Flex>
              {desciption}
              {feedback.visibility === AnytimeFeedbackVisibility.ManagersOnly && (
                <Text display="flex" color="grey-tone-50" fontSize="small" mt="s-8">
                  Visible to managers only
                </Text>
              )}
            </Widget>
          </Box>
          <ConfirmationDialog
            open={showDeletePopup}
            onClose={() => setShowDeletePopup(false)}
            onConfirm={handleDeleteFeedback}
            onReject={() => setShowDeletePopup(false)}
            label="Delete this feedback?"
            body="Deleting a feedback will permanently remove it from employee’s profile"
            loading={deletePending}
            submitDisabled={deletePending}
            yesMessage="Delete"
            noMessage="Cancel"
          />
        </>
      )}
    </>
  )
}
