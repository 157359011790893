import React, { useEffect, useState } from 'react'
import * as pdfJsLib from 'pdfjs-dist'

type UseScaleParams = {
  initValue?: number
  valueDivider?: number
  max?: number
  min?: number
  step?: number
}
export type PdfPreviewScale = {
  value: number
  adjustedValue: number
  reset: () => void
  up: () => void
  upDisabled: boolean
  down: () => void
  downDisabled: boolean
}
export const useScale = ({
  initValue = 1,
  valueDivider = 1,
  max = 3,
  min = 0.2,
  step = 0.1,
}: UseScaleParams): PdfPreviewScale => {
  const [value, setValue] = useState(initValue)

  const reset = () => setValue(initValue)

  const upDisabled = value >= max
  const up = () => {
    if (!upDisabled) {
      setValue(value + step)
    }
  }

  const downDisabled = value <= min
  const down = () => {
    if (!downDisabled) {
      setValue(value - step)
    }
  }

  return {
    value,
    adjustedValue: value / valueDivider,
    reset,
    up,
    upDisabled,
    down,
    downDisabled,
  }
}

type UsePaginationParams = {
  initValue?: number
}
export type PdfPreviewPagination = {
  current: number
  prev: () => void
  prevDisabled: boolean
  next: () => void
  nextDisabled: boolean
  total: number | undefined
  setTotal: (n: number) => void
  setCurrent: (n: number) => void
}
export const usePagination = ({
  initValue = 1,
}: UsePaginationParams): PdfPreviewPagination => {
  const [total, setTotal] = useState<number>()
  const [current, setCurrent] = useState(initValue)

  const nextDisabled = !total || current === total
  const next = () => {
    if (!nextDisabled) {
      setCurrent(current + 1)
    }
  }

  const prevDisabled = current === 1
  const prev = () => {
    if (!prevDisabled) {
      setCurrent(current - 1)
    }
  }
  return {
    current,
    prev,
    prevDisabled,
    next,
    nextDisabled,
    total,
    setTotal,
    setCurrent,
  }
}

type RenderContext = {
  canvasContext: Object
  viewport: pdfJsLib.PageViewport
}

const renderDocPage = ({
  document,
  canvas,
  params,
  onStartRendering,
  onMounted,
}: {
  document: pdfJsLib.PDFDocumentProxy
  canvas: HTMLCanvasElement | null
  params: {
    parentWidth?: number
    pageNum: number
    scale: number
    scaleDivider: number
  }
  onStartRendering?: (renderCtx: RenderContext) => void
  onMounted?: () => void
}) =>
  document
    .getPage(params.pageNum)
    .then(page => {
      if (!canvas) {
        return Promise.reject(new Error('Rendering canvas is not defined'))
      }
      const adjustedScale = params.scale / params.scaleDivider

      const viewport = page.getViewport({
        scale: params.parentWidth
          ? params.parentWidth / page.getViewport({ scale: 1 / adjustedScale }).width
          : adjustedScale,
      })
      canvas.width = viewport.width
      canvas.height = viewport.height

      const renderCtx = {
        canvasContext: canvas.getContext('2d') as Object,
        viewport,
      }
      onStartRendering?.(renderCtx)

      return page.render(renderCtx).promise
    })
    .then(() => {
      onMounted?.()
    })

type UsePdfPreviewParams = {
  url: string | undefined
  canvasRef: React.RefObject<HTMLCanvasElement>
  parentWidth?: number
  scale?: number
  scaleDivider?: number
  pageNum?: number
  onLoadDocument?: (pdfDocument: pdfJsLib.PDFDocumentProxy) => void
  onStartRendering?: (renderCtx: {
    canvasContext: Object
    viewport: pdfJsLib.PageViewport
  }) => void
  onError?: (e: Error) => void
}
export const useRenderPdf = ({
  url,
  canvasRef,
  parentWidth,
  scale = 1,
  scaleDivider = 1,
  pageNum = 1,
  onLoadDocument,
  onStartRendering,
  onError,
}: UsePdfPreviewParams) => {
  const [document, setDocument] = useState<pdfJsLib.PDFDocumentProxy>()
  const [isMounted, setIsMounted] = useState(false)
  const [pending, setPending] = useState(true)
  const [isRendering, setIsRendering] = useState(false)

  useEffect(() => {
    if (!url) {
      return
    }
    setPending(true)
    setIsMounted(false)

    pdfJsLib
      .getDocument(url)
      .promise.then(pdfDocument => {
        onLoadDocument?.(pdfDocument)
        setDocument(pdfDocument)
      })
      .catch(e => onError?.(e))
  }, [url])

  useEffect(() => {
    if (isRendering || !document) {
      return
    }
    setPending(true)
    setIsRendering(true)

    renderDocPage({
      document,
      canvas: canvasRef.current,
      params: { parentWidth, pageNum, scale, scaleDivider },
      onStartRendering,
      onMounted: () => {
        setIsMounted(true)
      },
    })
      .catch(e => onError?.(e))
      .finally(() => {
        setIsRendering(false)
        setPending(false)
      })
  }, [document, parentWidth, scale, pageNum, canvasRef])

  return { pending, isMounted }
}
