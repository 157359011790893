import React from 'react'
import { Box, Color, List, Skeleton, Text } from '@revolut/ui-kit'
import { Check, CheckSuccess, CrossCircle, CrossSmall } from '@revolut/icons'

import { UseFetchResult } from '@src/interfaces'
import { DefaultWorkingHourRequirementsResponse } from '@src/interfaces/attendance'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'

interface RequirementsWidgetProps {
  requirements: UseFetchResult<DefaultWorkingHourRequirementsResponse>
  valid: boolean
}

export const RequirementsWidget = ({ requirements, valid }: RequirementsWidgetProps) => {
  if (requirements.isError) {
    return null
  }

  if (requirements.isLoading) {
    return (
      <ActionWidget
        title={<Skeleton width={72} />}
        text={<Skeleton width={160} />}
        avatar={
          <Box width={43}>
            <Skeleton />
          </Box>
        }
        mt="s-16"
      />
    )
  }

  if (requirements.data) {
    const data = Object.values(requirements.data)
    return (
      <ActionWidget
        title="Schedule requirements"
        text={
          <Box>
            <Text color={Color.FOREGROUND}>
              Default working hours must meet the following schedule requirements:
            </Text>
            <List variant="compact" mt="s-8">
              {data.map(item => (
                <List.Item
                  useIcon={
                    item.valid ? (
                      <Check data-testid="valid" color={Color.GREEN} />
                    ) : (
                      <CrossSmall data-testid="invalid" color={Color.RED} />
                    )
                  }
                  key={item.message}
                >
                  {item.message}
                </List.Item>
              ))}
            </List>
          </Box>
        }
        avatar={
          valid ? (
            <CheckSuccess color={Color.GREEN} data-testid="status:valid" />
          ) : (
            <CrossCircle color={Color.RED} data-testid="status:invalid" />
          )
        }
        mt="s-16"
      />
    )
  }

  return null
}
