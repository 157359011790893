import React from 'react'
import { ChatMessageProps } from '@components/Chat/ChatMessage'
import { ChatMessageInterface } from '@src/interfaces/chat'
import { ChatMessagesListProps } from '@components/Chat/ChatMessagesList'
import { ChatTextEditorProps } from '@components/Chat/ChatTextEditor/ChatTextEditor'

export interface ChatProps extends Omit<ChatMessageProps, 'message'> {
  data: ChatMessageInterface[] | null
  isLoading: boolean
  refetch: () => Promise<any>
  loadNextPage?: () => void
  onAddMessage: ChatTextEditorProps['onSubmit']
  onEdit: ChatMessagesListProps['onEdit']
  onArchive: ChatMessagesListProps['onArchive']
  type: ChatMessageType
}

export enum ChatMessageType {
  Comment = 'Comment',
  Message = 'Message',
}

export const checkChatSubmitKey = (e: React.KeyboardEvent<unknown>) => {
  return e.code === 'Enter' && !e.shiftKey && !e.altKey
}
