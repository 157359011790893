import React, { useContext, useState } from 'react'
import SchedulingActionButton, {
  SchedulingActionButtonProps,
} from '@src/pages/Forms/Candidate/ScheduleSidebar/Buttons/SchedulingActionButton'
import {
  cancelScheduledInterview,
  getSchedulingInterview,
} from '@src/api/recruitment/interviewerScheduling'
import { ExtensionApiHandlerContext } from '@src/utils/extension'

interface Props
  extends Pick<SchedulingActionButtonProps, 'menuType' | 'disabled' | 'onProceed'> {
  roundId: number
  stageId: number
  interviewId?: number
}

const CancelInterviewButton = ({
  menuType,
  disabled,
  onProceed,
  roundId,
  stageId,
  interviewId,
}: Props) => {
  const apiHandler = useContext(ExtensionApiHandlerContext)
  const [loading, setLoading] = useState(false)
  const onClick = async () => {
    setLoading(true)

    let id = interviewId

    if (!id) {
      const resp = await getSchedulingInterview(stageId, roundId, apiHandler)
      id = resp.data[0].id!
    }

    try {
      await cancelScheduledInterview(roundId, stageId, id, apiHandler)
      await onProceed()
    } finally {
      setLoading(false)
    }
  }

  return (
    <SchedulingActionButton
      label="Cancel interview"
      title="Cancel interview?"
      description="This will remove any calendar events and notify interviewer (and candidate if they are involved) about the cancellation."
      onProceed={onClick}
      disabled={disabled}
      menuType={menuType}
      loading={loading}
    />
  )
}

export default CancelInterviewButton
