import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { engagementDriverFormRequests } from '@src/api/engagement'
import Form from '@src/features/Form/Form'
import { DriverForm } from './General'
import { DriverPreview } from './Preview'

export const EngagementDriver = connect(() => (
  <Form api={engagementDriverFormRequests}>
    <Switch>
      <Route path={ROUTES.APPS.ENGAGEMENT.DRIVER.EDIT}>
        <DriverForm />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.DRIVER.PREVIEW}>
        <DriverPreview />
      </Route>
    </Switch>
  </Form>
))
