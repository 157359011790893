import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Group, MoreBar } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { UserAccessManagementInterface } from '@src/interfaces/accessManagement'
import SettingsButtons from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { useGetEmployee } from '@src/api/employees'
import { IdStatuses } from '@src/interfaces/employees'
import { GetActivationLink } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/GetActivationLink'
import { GenerateSecretPopup } from '@src/pages/Forms/AccessManagementUser/components/GenerateSecretPopup/GenerateSecretPopup'

const General = () => {
  const SECRET_KEY_ID = 'secretkey'
  const { id } = useParams<{ id: string }>()

  const { values } = useLapeContext<UserAccessManagementInterface>()
  const permissions = useSelector(selectPermissions)
  const [showSecretKeyPopup, setShowSecretKeyPopup] = useState(false)
  const { data: employee } = useGetEmployee(
    values.employee?.id && values.employee?.status === IdStatuses.onboarding
      ? values.employee.id
      : undefined,
  )
  const canEdit =
    values.user_type === 'service' &&
    permissions.includes(PermissionTypes.AddServiceAccount)

  const canManageGroups = permissions?.includes(PermissionTypes.ViewUserGroups)
  return (
    <PageBody>
      <SettingsButtons mb="s-16">
        {employee ? <GetActivationLink data={employee} /> : null}
        {canManageGroups && (
          <MoreBar.Action
            useIcon="Plus"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.GROUPS, {
              id,
            })}
          >
            Manage groups
          </MoreBar.Action>
        )}
        {canEdit && (
          <MoreBar.Action
            useIcon="Pencil"
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.ADD_SERVICE_ACCOUNT.GENERAL, {
              id: values.service_account.id,
            })}
          >
            Edit
          </MoreBar.Action>
        )}
        {values.user_type !== 'employee' &&
          values.service_account?.auth_method?.id === SECRET_KEY_ID && (
            <>
              <MoreBar.Action onClick={() => setShowSecretKeyPopup(true)}>
                Generate secret key
              </MoreBar.Action>
              <GenerateSecretPopup
                open={showSecretKeyPopup}
                serviceAccountId={values.service_account.id}
                onClose={() => setShowSecretKeyPopup(false)}
              />
            </>
          )}
      </SettingsButtons>
      <FormPreview data={values}>
        <Group>
          {values.user_type === 'employee' ? (
            <FormPreview.Item title="Name" field="employee.name" />
          ) : (
            <FormPreview.Item title="Name" field="email" />
          )}
          <FormPreview.Item title="Status" field="status.name" />
          <FormPreview.Item title="Type" field="user_type" type="capitalized" />
          {values.user_type !== 'employee' && (
            <FormPreview.Item
              title="Authentication method"
              field="service_account.auth_method.name"
              type="capitalized"
            />
          )}
          <FormPreview.Item title="Email" field="email" />
          <FormPreview.Item title="Last login" field="last_login" type="dateTime" />
          {values.user_type === 'employee' ? (
            <FormPreview.Item title="Employee profile" field="employee" type="employee" />
          ) : (
            <FormPreview.Item
              title="Owner profile"
              field="service_account.owner"
              type="employee"
            />
          )}
        </Group>
      </FormPreview>
    </PageBody>
  )
}

export default General
