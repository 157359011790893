import React, { useState } from 'react'
import { MoreBar } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { changeConfidentiality } from '@src/api/requisitions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const ChangeConfidentiality = () => {
  const [loading, setLoading] = useState(false)
  const { values } = useLapeContext<RequisitionInterface>()
  const showStatusPopup = useShowStatusPopup()
  const handleChangeConfidentiality = async () => {
    setLoading(true)
    try {
      const newIsConfidential = !values.is_confidential
      await changeConfidentiality(values.id, newIsConfidential)
      values.is_confidential = newIsConfidential
      showStatusPopup({
        title: `Requisition is now ${newIsConfidential ? '' : 'non-'}confidential`,
      })
    } catch (e) {
      showStatusPopup({
        title: `Something went wrong`,
        status: 'error',
        description: getStringMessageFromError(e),
      })
    } finally {
      setLoading(false)
    }
  }
  return (
    <MoreBar.Action
      useIcon="Anonymous"
      onClick={() => handleChangeConfidentiality()}
      pending={loading}
    >
      Make {values.is_confidential ? 'non-' : ''}confidential
    </MoreBar.Action>
  )
}
