import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { Color, MoreBar } from '@revolut/ui-kit'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Questionnaire } from '@revolut/icons'
import {
  clearCVScreeningSession,
  navigateToCVScreening,
  setCVScreeningSession,
} from '@src/pages/Forms/CVScreening/utils'

type Props = {
  candidate: CandidateInterface
  round?: InterviewRoundInterface
}

const CVScreeningNotification = ({ candidate, round }: Props) => {
  if (
    round?.latest_interview_stage?.interview_type === 'cv_screening' &&
    round?.latest_interview_stage?.scheduling_status === 'pending_cv_screening'
  ) {
    return (
      <ActionWidget
        title="CV screening is pending"
        text="Please screen the candidate"
        avatarColor={Color.BLUE}
      >
        <MoreBar>
          <MoreBar.Action
            useIcon={Questionnaire}
            onClick={() => {
              clearCVScreeningSession()
              setCVScreeningSession({
                candidates: [{ id: candidate.id, name: candidate.full_name }],
              })
              navigateToCVScreening(candidate.id)
            }}
          >
            Screen CV
          </MoreBar.Action>
        </MoreBar>
      </ActionWidget>
    )
  }

  return null
}

export default CVScreeningNotification
