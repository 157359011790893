import React from 'react'
import { Text, Token, VStack, Widget } from '@revolut/ui-kit'

export const LifecycleIntro = () => {
  return (
    <Widget p="s-16">
      <VStack space="s-8">
        <Text variant="primary">What is the Lifecycle feature?</Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          Welcome to our lifecycle feature. Lifecycle controls 2 main things, how you
          onboard and how you offboard employees.
        </Text>
        <Text variant="caption" color={Token.color.greyTone50}>
          In this section you’ll be defining things like the onboarding portal, onboarding
          panel, which information you want to collect from new employees and much more.
        </Text>
      </VStack>
    </Widget>
  )
}
