import React from 'react'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { FunctionInterface } from '@src/interfaces/functions'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const Engagement = () => {
  const { values } = useLapeContext<FunctionInterface>()

  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Functions,
        id: values.id,
        permissions: values.field_options.permissions,
      }}
    />
  )
}
