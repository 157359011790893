import React, { useState } from 'react'
import { Box, Item, MoreBar, StatusPopup } from '@revolut/ui-kit'
import { Plus } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import {
  CompleteStageButton,
  DocumentsPreview,
  findProcessStage,
  SendFollowUpEmailButton,
  SLAProgress,
  TabProps,
  useRouteParams,
} from './common'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { updateEmployeeStatus } from '@src/api/employees'
import { EmployeeStatusTransitionsInterface, IdStatuses } from '@src/interfaces/employees'
import { selectUser } from '@src/store/auth/selectors'
import { completeStage } from '@src/api/onboardingEmployees'
import { useGetDocumentsSettings } from '@src/api/settings'
import { ProcessStages } from '@src/interfaces/onboarding'
import { Contracts } from '../../EmployeeProfile/Preview/ProfileSummary/components/Contracts'

export const ContractStage = (props: TabProps) => {
  const { data, stage, updateStage, setOpenedSidebar } = props
  const [hiredStatusPending, setHiredStatusPending] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const { data: documentsSettings } = useGetDocumentsSettings()

  const screeningStageExists = !!findProcessStage(data, ProcessStages.Screening)
  const rightToWorkStageExists = !!findProcessStage(data, ProcessStages.RightToWork)

  const params = useRouteParams()

  const user = useSelector(selectUser)

  const onMarkAsHired = async () => {
    setHiredStatusPending(true)

    try {
      if (
        data.employee.status.id !== IdStatuses.hired &&
        data.employee.status.id !== IdStatuses.active
      ) {
        const statusResponse = await updateEmployeeStatus(data.employee.id, {
          status: { id: IdStatuses.hired },
        } as EmployeeStatusTransitionsInterface)
        props.updateEmployeeStatus(statusResponse.data.status)
      }
      if (stage.status.id !== 'completed') {
        const stageResponse = await completeStage(data.employee.id, stage.id)
        updateStage(stageResponse.data)
      }
      setSuccessPopupOpen(true)
    } catch {
      setHiredStatusPending(false)
    }
  }

  const allStepsCompleted = stage.process_stage_steps.every(
    step => step.status.id === 'completed',
  )

  return (
    <>
      <PageBody mt="s-16">
        <SLAProgress
          elapsedSla={stage.sla}
          sla={stage.stage.sla_in_hours}
          caption="Issue contract, generate documents, sent them to be signed by HR manager and then to the candidate."
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        <Contracts employeeId={data.employee.id} hideTableRoute />

        <Box mt="s-24">
          <DocumentsPreview
            employee={data.employee}
            type="generated"
            suffix={
              <Item>
                <MoreBar>
                  {documentsSettings?.enable_docusign_integration ? (
                    <MoreBar.Action
                      onClick={() =>
                        navigateTo(
                          pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, {
                            employeeId: data.employee.id,
                          }),
                          {
                            initialValues: {
                              generated_by: { id: user.id, name: user.full_name },
                            },
                          },
                        )
                      }
                      useIcon={Plus}
                    >
                      Add new
                    </MoreBar.Action>
                  ) : null}
                  <SendFollowUpEmailButton
                    onClick={() => setOpenedSidebar('send-follow-up-email')}
                  />
                </MoreBar>
              </Item>
            }
          />
        </Box>
      </PageBody>

      <PageActions>
        <CompleteStageButton
          onClick={onMarkAsHired}
          pending={hiredStatusPending}
          footnote={`By completing this step, candidate's status will be changed to 'Hired'`}
          disabled={!allStepsCompleted}
          {...props}
        />
      </PageActions>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          const nextTab = (() => {
            if (screeningStageExists) {
              return ROUTES.FORMS.ONBOARDING_TIMELINE.SCREENING
            }
            if (rightToWorkStageExists) {
              return ROUTES.FORMS.ONBOARDING_TIMELINE.RIGHT_TO_WORK
            }
            return ROUTES.FORMS.ONBOARDING_TIMELINE.FINISH
          })()
          navigateReplace(pathToUrl(nextTab, params))
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>
          Candidate's status successfully changed to 'Hired'
        </StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
