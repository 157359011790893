import * as React from 'react'
import { Scorecard } from './Scorecard'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { useGetReviewCycle } from '@src/api/reviewCycles'
import PageLoading from '@components/PageLoading/PageLoading'
import { useParams } from 'react-router'
import { Token } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'

export const ScorecardPage = () => {
  const params = useParams<{ id: string }>()

  const { data: cycle, isLoading } = useGetReviewCycle(params.id)

  if (isLoading) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader title="Setup scorecard" backUrl={ROUTES.PERFORMANCE.REVIEWS_CYCLES}>
        <PageBody maxWidthMd={Token.breakpoint.lg}>
          <Scorecard cycle={cycle} />
        </PageBody>
      </PageHeader>
    </PageWrapper>
  )
}
