import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { patchJobDescriptionOfRequisition } from '@src/api/requisitions'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import JobPostingPopup from '@src/features/JobPostingFlow/components/JobPostingPopup'

type ChangeJobPostingProps = {
  onClose: () => void
  onRefresh: () => Promise<void>
}

const ChangeJobPosting = ({ onClose, onRefresh }: ChangeJobPostingProps) => {
  const { values } = useLapeContext<RequisitionInterface>()
  const statusPopup = useStatusPopup()
  const handleJobPostingChange = async (jobPosting?: JobPostingInterface) => {
    onClose()
    if (jobPosting?.id) {
      const result = await patchJobDescriptionOfRequisition(values?.id, {
        job_posting: { id: jobPosting.id },
      })

      if (result.data) {
        await onRefresh()
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Job posting has changed</StatusPopup.Title>
          </StatusPopup>,
        )
      }
    }
  }
  return (
    <JobPostingPopup
      confirmLabel="Connect posting"
      specialisationId={values?.specialisation?.id!}
      onClose={() => {
        onClose()
      }}
      onConfirm={handleJobPostingChange}
    />
  )
}
export default ChangeJobPosting
