import React from 'react'
import { DetailsCellSkeleton, Group, Item, Avatar, VStack } from '@revolut/ui-kit'

const CandidateDataSkeleton = () => {
  return (
    <VStack gap="s-16">
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Personal details</Item.Title>
          </Item.Content>
        </Item>
        {new Array(4).fill(0).map((_, idx) => (
          <DetailsCellSkeleton key={idx} />
        ))}
      </Group>
      <Group>
        <Item>
          <Item.Avatar>
            <Avatar useIcon="Microphone" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Interview details</Item.Title>
          </Item.Content>
        </Item>
        {new Array(8).fill(0).map((_, idx) => (
          <DetailsCellSkeleton key={idx} />
        ))}
      </Group>
    </VStack>
  )
}

export default CandidateDataSkeleton
