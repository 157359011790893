import { IdAndName } from '@src/interfaces/index'
import { EmployeeType } from '@src/interfaces/employees'

export interface ContractsStatsInterface {
  active: number
  expired: number
  expiring_soon: number
  inactive: number
  pending: number
  pending_approval: number
  pending_completion: number
  rejected: number
  starting_soon: number
  starting_soon_and_not_finalised: number
  terminated: number
  total: number
}

export interface ContractTypeInterface {
  id: string
  name: string
  description: string
  employee_type: IdAndName<EmployeeType>
}

export enum IdContractTypes {
  full_time = 'full_time',
  part_time = 'part_time',
  zero_hour_contractor = 'zero_hour_contractor',
  consultant = 'consultant',
  board_advisor = 'board_advisor',
  non_executive_director = 'non_executive_director',
  intern = 'intern',
  employee_through_peo = 'employee_through_peo',
  temporary_peo_employee = 'temporary_peo_employee',
  temporary_contractor = 'temporary_contractor',
  self_employed_contractor = 'self_employed_contractor',
  contractor = 'contractor',
  agency_worker = 'agency_worker',
  outsourced_agent = 'outsourced_agent',
  investor = 'investor',
  secondee = 'secondee',
  third_party = 'third_party',
}
