import { TextButton } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import SelectedEmployeesSidebar from './SelectedEmployeesSidebar'
import ProgressTabBar from '@src/components/BulkEdit/ProgressTabBar'

type ProgressTabBarProps = {
  checkAll?: boolean
  selectedIds: number[]
}

const TABS = [
  {
    title: 'Select & edit',
    route: ROUTES.FORMS.BULK_EDIT_EMPLOYEES.EDIT,
  },
  {
    title: 'Review changes',
    route: ROUTES.FORMS.BULK_EDIT_EMPLOYEES.REVIEW,
  },
  {
    title: 'Track progress',
    route: ROUTES.FORMS.BULK_EDIT_EMPLOYEES.TRACK,
  },
]

const Navigation = ({ checkAll, selectedIds }: ProgressTabBarProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const { id } = useParams<{ id?: string }>()

  const canNavigate = id !== undefined

  return (
    <>
      <TextButton color="blue" fontWeight="bold" onClick={() => setIsSidebarOpen(true)}>
        {selectedIds.length} Employees Selected
      </TextButton>
      <ProgressTabBar tabs={TABS} canNavigate={canNavigate} checkAll={checkAll} />
      <SelectedEmployeesSidebar
        isOpen={isSidebarOpen}
        setIsOpen={setIsSidebarOpen}
        selectedIds={selectedIds}
      />
    </>
  )
}

export default Navigation
