import React from 'react'

import { Box, Flex, HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { EngagementResultsOpenEndedDistribution } from '@src/interfaces/engagement'
import { getTotalAnswersFromOpenEnded } from '../helpers'

type Props = {
  distribution: EngagementResultsOpenEndedDistribution
}
export const Summary = ({ distribution }: Props) => {
  const total = getTotalAnswersFromOpenEnded(distribution)

  return (
    <Box p="s-4" minWidth={200}>
      <VStack>
        {Object.entries(distribution).map(([key, value]) => (
          <Flex key={key} justifyContent="space-between" gap="s-16">
            <HStack space="s-8" align="center">
              <Icon name="CheckSuccess" size={15} color={Token.color.greyTone20} />
              <Text>{key}</Text>
            </HStack>
            <Text>
              {value} ({formatPercentage(value / total)})
            </Text>
          </Flex>
        ))}
      </VStack>
    </Box>
  )
}
