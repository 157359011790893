import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Label } from '@components/Label/Label'
import { TeamInterface } from '@src/interfaces/teams'

type Props = {
  title: string
  data: TeamInterface
}
export const TeamPageHeader = ({ title, data }: Props) => {
  return (
    <PageHeader
      title={title}
      subtitle={
        <Label
          name={data.name}
          icon="Profile"
          url={pathToUrl(ROUTES.FORMS.TEAM.KPI, {
            id: data.id,
          })}
        />
      }
      backUrl={pathToUrl(ROUTES.FORMS.TEAM.KPI, { id: String(data.id) })}
    />
  )
}
