import { Popup, Box } from '@revolut/ui-kit'
import React from 'react'

interface Props {
  open: boolean
  onClose: VoidFunction
  src: string
}

export const TableauPreviewPopup = ({ open, onClose, src }: Props) => {
  return (
    <Popup open={open} size="lg" onClose={onClose}>
      <Box height="calc(100vh - 150px)" borderRadius="r16" overflow="hidden">
        <tableau-viz id="tableauViz" src={src} toolbar="bottom" hide-tabs height="100%" />
      </Box>
    </Popup>
  )
}
