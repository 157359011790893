import React, { useMemo } from 'react'

import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { Statuses } from '@src/interfaces'
import {
  FinalGrades,
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
} from '@src/interfaces/performance'
import {
  CheckpointReviewers,
  GradedRequestInterface,
} from '@src/pages/Forms/Overview/Checkpoint/CheckpointReviewers'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalUIKitLink } from '@components/InternalLink/InternalLink'
import GenericTimelineStep from './GenericTimelineStep'

type Props = {
  checkpoint: ProbationCheckpoint
  requests?: PerfReviewRequestFeedbackInterface[]
  grades?: FinalGrades[]
  loading?: boolean
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
}

const num = ['First', 'Second', 'Third']

const TimelineCheckpoint = ({
  checkpoint,
  requests,
  grades,
  loading,
  employeeId,
  cycleId,
  reviewCategory,
}: Props) => {
  const reviews: GradedRequestInterface[] | undefined = useMemo(
    () =>
      requests
        ?.filter(
          item => item.probation_checkpoint?.number === checkpoint.checkpoint_number,
        )
        .map(item => {
          const gradeValue = grades?.find(rating => rating.review.id === item.id)

          return {
            ...item,
            grade: gradeValue?.value,
            updated_date:
              item.status === Statuses.completed ? item.updated_date : undefined,
          }
        }),
    [checkpoint, grades, requests],
  )

  const checkpointUrl = {
    [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_OVERVIEW.CHECKPOINT,
    [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP_OVERVIEW.CHECKPOINT,
  }

  return (
    <GenericTimelineStep
      title={`${
        num[checkpoint.checkpoint_number - 1] || checkpoint.checkpoint_number
      } checkpoint`}
      checkpointDate={checkpoint.checkpoint_date_time}
      checkpointNumber={checkpoint.checkpoint_number}
    >
      <CheckpointReviewers
        cycleId={cycleId}
        employeeId={employeeId}
        reviews={reviews}
        loading={loading}
      />
      <InternalUIKitLink
        variant="h6"
        // @ts-expect-error object works fine here, but UI kit expects string
        to={pathToUrl(checkpointUrl[reviewCategory], {
          employeeId,
          cycleId,
          id: checkpoint.checkpoint_number,
        })}
      >
        More Details
      </InternalUIKitLink>
    </GenericTimelineStep>
  )
}

export default TimelineCheckpoint
