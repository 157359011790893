import React, { useState } from 'react'
import { Calendar, Input, InputGroup, Popup } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { CustomDate, parseDay } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getDate } from '@src/utils/timezones'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { css } from 'styled-components'
import { connect } from 'lape'

const TimeInputCss = css`
  input::-webkit-calendar-picker-indicator {
    position: relative;
    top: -8px;
  }
`

type Props = {
  onChange: (newCustomDate: CustomDate) => void
}

export const EditDateTime = connect(({ onChange }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const [isOpen, setOpen] = useState(false)

  return (
    <InputGroup variant="horizontal">
      <Input
        readOnly
        label="Date"
        onClick={() => setOpen(true)}
        value={
          values.custom_date?.day ? formatDate(parseDay(values.custom_date.day)) : ''
        }
        data-testid="day-input"
      />
      <Input
        label="Time"
        placeholder="--:--"
        type="time"
        value={values.custom_date?.time || ''}
        errorMessage={values.custom_date?.timeError}
        aria-invalid={!!values.custom_date?.timeError}
        onChange={e => {
          onChange({
            ...values.custom_date,
            time: e.currentTarget.value,
          })
        }}
        data-testid="time-input"
        css={TimeInputCss}
      />
      <Popup open={isOpen} onClose={() => setOpen(false)} variant="bottom-sheet">
        <Calendar
          value={values.custom_date?.day ? parseDay(values.custom_date.day) : undefined}
          onChange={value => {
            onChange({
              ...values.custom_date,
              day: value ? getDate(value) : undefined,
            })
            setOpen(false)
          }}
          labelEmptyValue="Select date"
          labelButtonClear="Clear"
        />
      </Popup>
    </InputGroup>
  )
})
