import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { Route, Switch } from 'react-router-dom'
import { API } from '@src/constants/api'
import { BulkDataImportUploadFile } from '@src/features/BulkDataImport/BulkDataImportUploadFile'
import { CandidatesSession } from '@src/pages/Forms/ImportData/Candidates/CandidatesSession'

export const ImportCandidates = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Import wizard"
        subtitle="Follow our guidelines to import the data at once"
        backUrl={ROUTES.RECRUITMENT.CANDIDATES}
      />

      <Switch>
        <Route path={ROUTES.FORMS.IMPORT_DATA.CANDIDATES.UPLOAD_FILE} exact>
          <PageBody>
            <BulkDataImportUploadFile
              category="candidate_bulk_upload"
              apiEndpoint={API.CANDIDATES_UPLOAD}
              name="candidate"
              nextRoute={ROUTES.FORMS.IMPORT_DATA.CANDIDATES.SESSION}
            />
          </PageBody>
        </Route>
        <Route path={ROUTES.FORMS.IMPORT_DATA.CANDIDATES.SESSION} exact>
          <CandidatesSession />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
