import React, { useEffect } from 'react'
import { CareersPositionLocation } from '@src/interfaces/careers'
import upperFirst from 'lodash/upperFirst'
import { useQuery } from '@src/utils/queryParamsHooks'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  locations: CareersPositionLocation[]
  onChange: (value?: CareersPositionLocation) => void
  value?: CareersPositionLocation
}

const CareersLocationFilter = ({ locations, onChange, value }: Props) => {
  const { query, changeQueryParam } = useQuery()

  useEffect(() => {
    const city = query.city
    if (!city) {
      onChange(undefined)
      return
    }

    if (city && typeof city === 'string') {
      onChange(locations.find(({ name }) => name === city.replace(/_/g, ' ')))
    }
  }, [query.city, onChange, locations])

  const handleChange = (location?: CareersPositionLocation) => {
    changeQueryParam('city', location?.name || '')
  }

  return (
    <RadioSelectInput
      label="Location"
      options={locations.map(location => ({
        key: location.name,
        label: upperFirst(`${location.name} ${location.country ? location.country : ''}`),
        value: location,
      }))}
      value={value}
      onChange={val => handleChange(val || undefined)}
      clearable
    />
  )
}

export default CareersLocationFilter
