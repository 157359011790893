import ChatSidebar from '@components/Chat/ChatSidebar'
import { ChatMessageType } from '@components/Chat/common'
import React from 'react'
import { getCandidateComments } from '@src/api/hiringProcess'

interface TimelineCommentsSidebarProps {
  roundId: number
  onClose: () => void
}

const TimelineCommentsSidebar = ({ onClose, roundId }: TimelineCommentsSidebarProps) => {
  const api = getCandidateComments(roundId)

  const { data, isLoading, refetch } = api.useGetComments()

  return (
    <ChatSidebar
      type={ChatMessageType.Comment}
      isOpen
      onClose={onClose}
      data={data?.results || []}
      isLoading={isLoading}
      refetch={refetch}
      onAddMessage={api.addComment}
      onEdit={api.editComment}
      onArchive={api.archiveComment}
      onResolve={api.resolveComment}
      disableTodolistFeature
    />
  )
}

export default TimelineCommentsSidebar
