import React, { ReactNode } from 'react'
import { Color, Details, HStack, Text, Tooltip, useTooltip } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'

/*
If current seniority sublevel = 1, then "Exceptional track"
If current seniority sublevel = 2, then "Fast track"
If current seniority sublevel = 3, then "Standard track"
*/

export interface ProgressionTrackProps {
  sublevel?: number
  promotionTrack?: string
}

export const PromotionTrack = ({ sublevel, promotionTrack }: ProgressionTrackProps) => {
  const tooltip = useTooltip()
  const level =
    promotionTrack ||
    {
      1: 'exceptional',
      2: 'fast',
      3: 'normal',
    }[sublevel || 0]
  const tooltips: { [level: string]: ReactNode } = {
    exceptional: (
      <Text>
        Exceptional track denotes that this
        <br />
        employee is seniority level I, and you
        <br />
        should consider whether they are ready
        <br />
        for promotion prior to any progression.
      </Text>
    ),
    fast: (
      <Text>
        Fast track denotes that this employee is
        <br />
        seniority level II, and you should consider
        <br />
        whether they are ready for promotion
        <br />
        prior to progressing through all levels.
        <br />
        Employees eligible for progression
        <br />
        to level II may also be considered forfast track.
      </Text>
    ),
    normal: (
      <Text>
        Standard track denotes that this
        <br />
        employee is seniority level III, and you
        <br />
        should consider whether they are now
        <br />
        ready for promotion.
        <br />
        Employees eligible for progression to
        <br />
        level III may also be considered for
        <br />
        standard track.
      </Text>
    ),
  }
  const title =
    level &&
    {
      exceptional: 'Exceptional',
      fast: 'Fast',
      normal: 'Standard',
    }[level]
  return level ? (
    <Details color={Color.GREY_TONE_50}>
      <Details.Title fontSize="14px" fontWeight={500}>
        Promotion Track
      </Details.Title>
      <Details.Content>
        <HStack space="s-8">
          <Text fontWeight={400} fontSize="14px">
            {`${title} Track`}
          </Text>
          <InfoOutline
            size={16}
            color={Color.GREY_TONE_50}
            {...tooltip.getAnchorProps()}
          />
          <Tooltip {...tooltip.getTargetProps()}>
            <Text>{tooltips[level]}</Text>
          </Tooltip>
        </HStack>
      </Details.Content>
    </Details>
  ) : null
}
