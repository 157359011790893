import React from 'react'
import { Color, TextButton, Flex, Box } from '@revolut/ui-kit'
import { Message } from '@revolut/icons'
import Tooltip from '@components/Tooltip/Tooltip'
import styled from 'styled-components'

const TooltipBtn = styled(Tooltip)`
  justify-content: flex-start;
  width: 50px;
`

type Props = {
  onOpen: () => void
  comment?: string | null
  canChange?: boolean
}

const CalibrationAction = ({ comment, canChange, onOpen }: Props) => {
  let btn: string | undefined = comment ? 'Edit' : 'Add'

  if (!canChange) {
    btn = comment ? 'View' : undefined
  }

  if (!btn) {
    return null
  }

  // we need stopPropagation here to prevent click event capturing on Row
  return (
    <div onClick={e => e.stopPropagation()}>
      <TooltipBtn placement="top" text={comment} hide={!comment} useFormatting>
        <TextButton onClick={onOpen}>
          <Flex alignItems="center">
            {btn}
            {comment && (
              <Box ml="s-6">
                <Message size={12} color={Color.BLUE} />
              </Box>
            )}
          </Flex>
        </TextButton>
      </TooltipBtn>
    </div>
  )
}

export default CalibrationAction
