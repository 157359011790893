import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Text, Token } from '@revolut/ui-kit'

import HierarchyTree from '@components/Hierarchy/HierarchyTree'
import { useOpenNewTab } from '@src/actions/RouterActions'
import {
  getDirectHierarchyEmployee,
  useGetEmployeeDirectHierarchyUpward,
} from '@src/api/functions'
import { ROUTES } from '@src/constants/routes'
import { HierarchyNodeInterface } from '@src/interfaces/hierarchy'
import { selectUser } from '@src/store/auth/selectors'
import { useGetReviewGradesMap } from '@src/utils/grades'
import { pathToUrl } from '@src/utils/router'

export const Hiearchy = () => {
  const user = useSelector(selectUser)
  const { data: hierarchyData, isLoading } = useGetEmployeeDirectHierarchyUpward(user.id)
  const { gradesMap, isLoading: isGradesMapLoading } = useGetReviewGradesMap()

  const onChildrenRequest = async (node: HierarchyNodeInterface) => {
    const result = await getDirectHierarchyEmployee(node.id, false)
    return result.data.reports || []
  }
  const newTabTo = useOpenNewTab()

  const handleTitleClick = (node: HierarchyNodeInterface) =>
    newTabTo(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: node.id }))

  const showTree = Boolean(isLoading || hierarchyData?.employees?.[0])

  return (
    <Flex flex={1}>
      {showTree ? (
        <HierarchyTree
          loading={isLoading || isGradesMapLoading}
          onChildrenRequest={onChildrenRequest}
          onNodeTitleClick={handleTitleClick}
          rootNodeData={hierarchyData?.employees?.[0]}
          gradesMap={gradesMap}
        />
      ) : (
        <Text color={Token.color.greyTone50}>There is no hierarchy information</Text>
      )}
    </Flex>
  )
}
