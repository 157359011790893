import get from 'lodash/get'
import { PreviewChangelog } from './changelog'

export const getValueWithChangelog = <T>({
  values,
  changelog,
  field,
}: {
  values?: T
  changelog?: PreviewChangelog<T>
  field?: string
}) => {
  if (!field) {
    return null
  }
  const previousValues = !changelog?.showPendingChanges && changelog?.previousValues

  return get(previousValues, field) ?? get(values, field) ?? null
}
