import React from 'react'
import { useSelector } from 'react-redux'
import { ActionButton, Button, Flex } from '@revolut/ui-kit'
import { format, isBefore } from 'date-fns'

import { Statuses } from '@src/interfaces'
import { RequestFeedbackInterface } from '@src/interfaces/performance'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectUser } from '@src/store/auth/selectors'
import { getReviewer } from '../Common/utils'

type Props = {
  request: RequestFeedbackInterface
  setFilter: (filter: OptionInterface) => void
}

const PipActions = ({ request, setFilter }: Props) => {
  const user = useSelector(selectUser)

  const onClickEdit = () => {
    navigateTo(
      `${pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
        id: request.reviewed_person.id,
      })}?cycle_id=${request.pip_cycle?.id}&request_id=${request.id}`,
    )
  }

  const onClickOpen = () => {
    const reviewer = getReviewer(request, false)
    if (request.created_date && request.id) {
      setFilter({
        name: `${reviewer.full_name} - ${format(
          new Date(request.created_date),
          'd MMM yyyy hh:mm a',
        )}`,
        id: request.id,
      })
    }
  }

  if (request.reviewed_by?.id !== user.id || !request.pip_cycle) {
    return null
  }

  const canEdit = isBefore(new Date(), new Date(request.pip_cycle.end_date_time!))

  return (
    <Flex justifyContent="flex-end">
      {(request.status === Statuses.pending || request.status === Statuses.draft) &&
        canEdit && <Button onClick={onClickEdit}>Edit review</Button>}
      {request.status === Statuses.completed && (
        <ActionButton onClick={onClickOpen}>Open review</ActionButton>
      )}
    </Flex>
  )
}

export default PipActions
