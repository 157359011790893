import { RadioSelectInputProps } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { chain, Text, Token } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import { ProbationTemplateOptionInterface } from '@src/interfaces/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTemplatePreviewRequest,
  ProbationTemplatePreviewResponse,
} from '@src/interfaces/probationTemplate'
import { getProbationTemplatePreview } from '@src/api/probationTemplate'
import pluralize from 'pluralize'

export const renderTemplatesSelectorOption: RadioSelectInputProps<ProbationTemplateOptionInterface>['children'] =
  item => {
    const statusColor = item.value.is_eligible ? Token.color.green : Token.color.red

    return (
      <Text>
        {chain(
          item.value.name,
          <Text color={statusColor}>
            {item.value.is_eligible ? 'Eligible' : 'Ineligible'}
          </Text>,
        )}
      </Text>
    )
  }

interface OptionsInterface {
  min: number
  max: number
}

export const getCheckpointsOptions = ({ min, max }: OptionsInterface) => {
  const options = []
  for (let i = min; i <= max; i++) {
    options.push({
      value: {
        name: String(i),
        id: i,
      },
      label: String(i),
    })
  }
  return options
}

export const getWeeksOptions = ({ min, max }: OptionsInterface) => {
  const options = []
  for (let i = min; i <= max; i++) {
    const option = pluralize('week', i, true)
    options.push({
      value: {
        name: option,
        id: i,
      },
      label: option,
    })
  }
  return options
}

export const useGetProbationTemplateData = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  const [previewData, setPreviewData] = useState<ProbationTemplatePreviewResponse>()
  const [loading, setLoading] = useState(false)
  const [weeksOptions, setWeeksOptions] = useState(getWeeksOptions({ min: 1, max: 4 }))

  const lastTimelineInd = values.timelines?.length - 1
  const defaultGoalsWeekNum = 2
  const defaultFinalDecisionWeekNum = 1
  const defaultCheckpointsNum = 3
  const defaultMonthsNum = 3
  const defaultPreviewData = {
    goals_setting_week_after_start:
      values.timelines?.[0].weeks_due_date || defaultGoalsWeekNum,
    final_decision_week_before_end:
      values.timelines?.[lastTimelineInd].weeks_due_date || defaultFinalDecisionWeekNum,
    num_checkpoints: values.num_checkpoints || defaultCheckpointsNum,
    months: values.amount_of_months || defaultMonthsNum,
  }

  useEffect(() => {
    getProbationTemplatePreview(defaultPreviewData).then(res => {
      setPreviewData(res.data)
      if (!values.timelines) {
        values.timelines = res.data.checkpoints_timeline
      }
      if (!values.amount_of_months) {
        values.amount_of_months = res.data.months
      }
      if (!values.num_checkpoints) {
        values.num_checkpoints = res.data.num_checkpoints
      }
      if (!values.rules) {
        values.rules = {}
      }
      if (!values.allow_extension) {
        values.allow_extension = false
      }
    })
  }, [])

  const recalculatePreview = async (body: ProbationTemplatePreviewRequest) => {
    setLoading(true)
    try {
      const { data } = await getProbationTemplatePreview(body)
      setPreviewData(data)
      values.timelines = data.checkpoints_timeline.map(el => ({
        ...el,
        probation_template: values.id,
      }))
      if (data.maximum_checkpoints < values.num_checkpoints) {
        values.num_checkpoints = data.maximum_checkpoints
        recalculatePreview({
          ...previewData,
          months: data.months,
          num_checkpoints: data.maximum_checkpoints,
        } as ProbationTemplatePreviewRequest)
      }
    } finally {
      setLoading(false)
    }
  }

  const minProbationDurationMonths = 1
  const maxProbationDurationMonths = 12
  const monthsOptions = getCheckpointsOptions({
    min: minProbationDurationMonths,
    max: maxProbationDurationMonths,
  })
  const defaultMaxCheckpoints = 12
  const numberOfCheckpointsOptions = getCheckpointsOptions({
    min: 0,
    max: previewData?.maximum_checkpoints || defaultMaxCheckpoints,
  })

  const onMonthsChange = (option: { id: number } | null) => {
    if (option) {
      if (option.id === 1 || option.id === 2) {
        setWeeksOptions(getWeeksOptions({ min: 1, max: option.id }))
        values.timelines[0].weeks_due_date = 1
        values.timelines[lastTimelineInd].weeks_due_date = option.id
        if (previewData) {
          previewData.checkpoints_timeline[0].weeks_due_date = 1
          previewData.goals_setting_week_after_start = 1
          previewData.checkpoints_timeline[lastTimelineInd].weeks_due_date = option.id
          previewData.final_decision_week_before_end = option.id
        }
      } else {
        setWeeksOptions(getWeeksOptions({ min: 1, max: 4 }))
      }
      values.amount_of_months = option.id
      recalculatePreview({
        ...previewData,
        months: option.id,
      } as ProbationTemplatePreviewRequest)
    }
  }

  return {
    previewData,
    lastTimelineInd,
    monthsOptions,
    weeksOptions,
    numberOfCheckpointsOptions,
    onMonthsChange,
    recalculatePreview,
    loading,
  }
}
