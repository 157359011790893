import React from 'react'
import { Avatar, HStack, Text, Widget } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { InterviewScorecardType } from '@src/interfaces/interviewScorecardTemplates'
import { InterviewFeedbackSummaryInterface } from '@src/interfaces/interviewTool'
import { PerformanceRatingTitle } from '@src/constants/performance'
import { SummaryItem } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SummaryCard/SummaryItem'
import { SummaryItemProgress } from '@src/pages/Forms/InterviewScorecardTemplate/PreviewV2/components/SummaryCard/SummaryItemProgress'

type Props = {
  points: number
  onlyTotal?: boolean
  passScore: number | null
  maxScore: number | null
  type?: InterviewScorecardType
  summary?: InterviewFeedbackSummaryInterface
}

export const SummaryCard = ({
  points,
  onlyTotal,
  passScore,
  maxScore,
  type,
  summary,
}: Props) => {
  const title = (
    <HStack gap="s-8" align="center" mb="s-16">
      <Avatar useIcon="Document" />
      <Text variant="emphasis1">Summary</Text>
    </HStack>
  )

  const renderContent = () => {
    if (onlyTotal) {
      return (
        <SummaryItem
          title="Total score"
          value={maxScore !== undefined && maxScore !== null ? String(maxScore) : '-'}
        />
      )
    }

    switch (type) {
      case 'cv_screening':
        return (
          <>
            <SummaryItemProgress points={points} maxScore={maxScore} />
            <SummaryItem
              title="Pass score"
              value={maxScore && passScore ? formatPercentage(passScore / maxScore) : '-'}
            />
          </>
        )

      case 'general':
        return (
          <>
            <SummaryItemProgress points={points} maxScore={maxScore} />
            <SummaryItem
              title="Suggested level"
              value={
                summary?.suggested_level
                  ? PerformanceRatingTitle[summary?.suggested_level]
                  : '-'
              }
            />
          </>
        )

      default:
        return (
          <>
            <SummaryItem
              title="Expected level"
              value={
                summary?.expected_level
                  ? PerformanceRatingTitle[summary?.expected_level]
                  : '-'
              }
            />
            <SummaryItem
              title="Suggested level"
              value={
                summary?.suggested_level
                  ? PerformanceRatingTitle[summary?.suggested_level]
                  : '-'
              }
            />
          </>
        )
    }
  }

  return (
    <Widget p="s-16">
      {title}
      <HStack space="s-16">{renderContent()}</HStack>
    </Widget>
  )
}
