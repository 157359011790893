import React from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@components/Page/PageBody'
import LapeForm, { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import PasswordStrength, {
  getStrengthAndErrors,
} from '@components/PasswordStrength/PasswordStrength'
import { NonFieldErrors, PASSWORDS_DONT_MATCH_ERROR } from '@src/pages/Login/common'
import { ChangePasswordInterface } from '@src/interfaces/auth'
import { changePassword } from '@src/api/login'
import { arrayErrorsToFormError } from '@src/utils/form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

const ChangePassword = connect(() => {
  const params = useParams()

  const { apiErrors, values } = useLapeContext<ChangePasswordInterface>()

  const nonFieldErrors = Object.entries(apiErrors)
    .filter(
      ([key]) =>
        key !== 'password' && key !== 'retype_password' && key !== 'current_password',
    )
    .map(([, value]) => value)

  const someFieldsEmpty =
    !values.password || !values.current_password || !values.retype_password
  const passwordsDontMatch =
    !!values.password &&
    !!values.retype_password &&
    values.password !== values.retype_password
  const passwordErrors = !!getStrengthAndErrors(values.password)?.errors.length

  const submitDisabled = someFieldsEmpty || passwordsDontMatch || passwordErrors

  return (
    <PageBody>
      <InputGroup>
        <LapeNewInput
          name="current_password"
          type="password"
          label="Current password"
          hasError={!!apiErrors.current_password}
          message={apiErrors.current_password}
          required
        />
        <LapeNewInput
          name="password"
          type="password"
          label="New password"
          hasError={!!apiErrors.password}
          message={apiErrors.password || <PasswordStrength password={values.password} />}
          required
        />
        <LapeNewInput
          name="retype_password"
          type="password"
          label="Retype password"
          hasError={!!apiErrors.retype_password || passwordsDontMatch}
          message={
            apiErrors.retype_password ||
            (passwordsDontMatch && PASSWORDS_DONT_MATCH_ERROR)
          }
          required
        />
        <NonFieldErrors nonFieldErrors={nonFieldErrors} />
      </InputGroup>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Password successfully changed"
          onAfterSubmit={() => goBack(pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, params))}
          errorHandler={error => {
            Sentry.captureException(error)
          }}
          disabled={submitDisabled}
        >
          Change password
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageBody>
  )
})

export default () => {
  const handleChangePassword = (form: LapeFormInterface<ChangePasswordInterface>) => {
    return changePassword(form.values)
      .then(({ data }) => data)
      .catch(error => {
        const errors = arrayErrorsToFormError(error?.response?.data)
        form.apiErrors = errors
        throw error
      })
  }

  return (
    <LapeForm onSubmit={handleChangePassword}>
      <ChangePassword />
    </LapeForm>
  )
}
