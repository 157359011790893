import React, { useState } from 'react'
import { Dropdown, MoreBar } from '@revolut/ui-kit'
import { Envelope } from '@revolut/icons'

import { sendActivationEmail } from '@src/api/login'
import { successNotification } from '@src/actions/NotificationActions'
import { useCanViewResendActivationEmail } from '../common'
import { EmployeeButtonProps } from '../types'

export const ResendActivationEmail = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const [pending, setPending] = useState(false)
  const canView = useCanViewResendActivationEmail({ data })

  if (!canView) {
    return null
  }

  const onClick = async () => {
    try {
      setPending(true)
      await sendActivationEmail(data.id)
      successNotification('Email successfully sent')
    } finally {
      setPending(false)
    }
  }

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={Envelope} onClick={onClick} disabled={pending}>
        Send activation email
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      onClick={onClick}
      pending={pending}
      disabled={pending}
      useIcon={Envelope}
    >
      Send activation email
    </MoreBar.Action>
  )
}
