import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { VStack, Flex } from '@revolut/ui-kit'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const TeamsSettings = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeOrganisationPreferences)
  return (
    <VStack gap="s-16">
      <SectionTitle title="Do you want to be able to assign employees to Department leadership positions?" />

      <LapeBooleanRadioSwitch
        name="enable_leadership"
        variant="horizontal"
        disabled={disableEdit}
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to assign employees to departments leadership positions, such as Head of Product, Head of Design, etc and display this information to others"
        noDescription="I do not need to define key positions right now, and will decide it later"
      />
    </VStack>
  )
}

export default TeamsSettings
