import React, { useCallback, useEffect, useState } from 'react'
import { BoxProps, Search, SearchVariant, Box } from '@revolut/ui-kit'
import { FilterByInterface } from '@src/interfaces/data'
import debounce from 'lodash/debounce'
import { useQuery } from '@src/utils/queryParamsHooks'

export type OnFilterHandler = (
  filter: FilterByInterface,
  resetDefaultFilters?: boolean,
  forceReset?: boolean,
) => void

export interface SearchInputProps extends BoxProps {
  placeholder?: string
  onFilter: OnFilterHandler
  columnName?: string
  variant?: SearchVariant
  disabled?: boolean
  width?: number | string
  minWidth?: number | string
  maxWidth?: number | string
}

export const SearchInput = ({
  columnName = 'search',
  disabled = false,
  placeholder = 'Search',
  variant = 'default',
  width,
  minWidth,
  maxWidth,
  onFilter,
}: SearchInputProps) => {
  const [val, setVal] = useState('')
  const { query } = useQuery()
  const searchThrottled = useCallback(
    debounce(
      (q: string) => {
        return onFilter(
          {
            columnName,
            filters: q
              ? [
                  {
                    id: q,
                    name: q,
                  },
                ]
              : [],
          },
          true,
          true,
        )
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  )

  useEffect(() => {
    if (query?.search) {
      setVal(query.search)
    }
  }, [])

  if (variant === 'default') {
    return (
      <Box width={width} minWidth={minWidth} maxWidth={maxWidth}>
        <Search
          onChange={q => {
            setVal(q)
            searchThrottled(q)
          }}
          value={val}
          placeholder={placeholder}
          disabled={disabled}
        />
      </Box>
    )
  }

  return (
    <Search
      onChange={q => {
        setVal(q)
        searchThrottled(q)
      }}
      value={val}
      placeholder={placeholder}
      variant={variant}
      disabled={disabled}
    />
  )
}
