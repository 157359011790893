import { matchPath } from 'react-router-dom'
import { history } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { captureException } from '@sentry/react'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { pick } from 'lodash'
import { GoalKpiDetails } from '@src/interfaces/goals'
import { DeepPartial } from 'redux'

export const isGenericNewGoalPath = () =>
  !!matchPath(history.location.pathname, [
    ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.FORM,
    ROUTES.FORMS.GOAL.CREATE.FORM,
  ])

export const getCache = <T>(key: string): Partial<T> | null => {
  try {
    const rawCache = workspaceLocalStorage.getItem(key)
    return rawCache ? JSON.parse(rawCache) : null
  } catch (err) {
    captureException(err)
    return null
  }
}

export const setCache = <T>(key: string, value: Partial<T>): void => {
  try {
    workspaceLocalStorage.setItem(key, JSON.stringify(value))
  } catch (err) {
    captureException(err)
  }
}

export const getMetricRelevantFormData = (metric: DeepPartial<GoalKpiDetails>) =>
  pick(
    metric,
    'id',
    'tempId',
    'name',
    'targets',
    'target_epics',
    'goal',
    'owner',
    'is_company',
    'department',
    'team',
    'look_url',
    'sql_query',
    'sql_query_db',
    'connection',
    'unit',
    'is_employee',
    'employee',
    'update_type',
    'current_progress',
    'means_of_measure',
    'todo_items',
    'counted_sql_column',
    'counted_sql_date_column',
    'salesforce_report',
  )
