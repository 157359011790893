import { RefObject, useState } from 'react'
import { AxiosError } from 'axios'

export const useHandleEligibilityError = (targetRef: RefObject<HTMLDivElement>) => {
  const [error, setError] = useState<string>()

  return {
    error,
    setError,
    errorHandler: (e: AxiosError) => {
      const isEligibilityGroupError =
        e.response?.status === 400 && e.response.data.eligibility_groups?.length
      if (isEligibilityGroupError) {
        setError(e.response!.data.eligibility_groups[0])
        targetRef.current?.scrollIntoView({ behavior: 'smooth' })
      } else {
        throw e
      }
    },
  }
}
