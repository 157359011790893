import React, { useCallback, useMemo, useRef } from 'react'
import { PageWrapper } from '@components/Page/Page'
import { useGetHealth } from '@src/api/health'
import {
  PlatformStatusWidget,
  statusToColor,
} from '@src/pages/PlatformStatus/PlatformStatusWidget'
import {
  Absolute,
  Box,
  chain,
  Flex,
  Grid,
  MoreBar,
  Subheader,
  Text,
  TextWidget,
  useVirtualViewport,
} from '@revolut/ui-kit'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { Delete, Retry, Warning } from '@revolut/icons'
import isEmpty from 'lodash/isEmpty'
import { pathToUrl } from '@src/utils/router'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useDeleteBanners } from '@src/api/banners'
import { InternalLink } from '@components/InternalLink/InternalLink'

const PlatformStatus = () => {
  const { data, isLoading, refetch } = useGetHealth()
  const { mutateAsync: deleteBanners, isLoading: isLoadingDelete } = useDeleteBanners()
  const permissions = useSelector(selectPermissions)
  const parentRef = useRef<HTMLDivElement>(null)
  const releases = useMemo(() => data?.releases.slice().reverse(), [data?.releases])
  const estimateSize = useCallback(() => 150, [])
  const keyExtractor = useCallback(index => releases?.[index].version || '', [releases])

  const list = useVirtualViewport({
    parentRef,
    size: releases?.length || 0,
    estimateSize,
    keyExtractor,
    overscan: 5,
  })

  const canManageBanners = permissions?.includes(PermissionTypes.CanManageBanners)

  return (
    <PageWrapper pt="s-8" pb="s-24">
      <PageHeader
        title={chain(
          'Platform status',
          <Text color="grey-tone-50">{data?.version}</Text>,
          data?.status ? (
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            <Text color={statusToColor[data?.status]}>{data?.status}</Text>
          ) : null,
        )}
        backUrl={ROUTES.MAIN}
        isLoading={isLoading}
      />
      <PageBody>
        <Flex mb="s-32">
          <MoreBar>
            <MoreBar.Action
              useIcon={Retry}
              onClick={() => refetch()}
              disabled={isLoading}
            >
              Refresh
            </MoreBar.Action>
            {canManageBanners && (
              <>
                <MoreBar.Action
                  useIcon={Warning}
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.BANNER.GENERAL)}
                >
                  Create banner
                </MoreBar.Action>
                <MoreBar.Action
                  useIcon={Delete}
                  onClick={() => deleteBanners(undefined)}
                  disabled={isLoadingDelete}
                >
                  Remove banner
                </MoreBar.Action>
              </>
            )}
          </MoreBar>
        </Flex>

        <Grid columns={{ all: 1, md: 3 }} gap="s-16" mb="s-32">
          <PlatformStatusWidget title="Database" data={data?.db_health} />
          <PlatformStatusWidget title="Async" data={data?.async_health} />
          <PlatformStatusWidget title="Caching" data={data?.caching_health} />
        </Grid>

        <Subheader variant="nested">
          <Subheader.Title>Changelog</Subheader.Title>
        </Subheader>
        <Box
          ref={parentRef}
          style={{
            position: 'relative',
            height: `${list.totalSize}px`,
          }}
        >
          {releases &&
            list.virtualItems.map(virtual => {
              const release = releases[virtual.index]
              return (
                <Absolute
                  key={keyExtractor(virtual.index)}
                  ref={virtual.measureRef}
                  style={{
                    width: '100%',
                    minHeight: `${virtual.size}px`,
                    transform: `translateY(${virtual.start}px)`,
                  }}
                  pb="s-16"
                >
                  <TextWidget>
                    <TextWidget.Title>{release.version}</TextWidget.Title>
                    <TextWidget.Content>
                      {!isEmpty(release.features) && release.features?.[0] !== null ? (
                        <>
                          <Text use="div" fontWeight={500} py="s-8">
                            Features:
                          </Text>
                          <ul style={{ margin: 0, paddingInlineStart: 24 }}>
                            {release.features.map((feature, num) =>
                              feature ? <li key={num}>{feature}</li> : null,
                            )}
                          </ul>
                        </>
                      ) : null}
                      {!isEmpty(release.fixes) && release.fixes?.[0] !== null ? (
                        <>
                          <Text use="div" fontWeight={500} my="s-8">
                            Fixes:
                          </Text>
                          <ul style={{ margin: 0, paddingInlineStart: 24 }}>
                            {release.fixes.map((fix, num) =>
                              fix ? <li key={num}>{fix}</li> : null,
                            )}
                          </ul>
                        </>
                      ) : null}
                    </TextWidget.Content>
                  </TextWidget>
                </Absolute>
              )
            })}
        </Box>
      </PageBody>
    </PageWrapper>
  )
}

export default PlatformStatus
