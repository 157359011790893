import { Item, ItemProps, Text } from '@revolut/ui-kit'
import React, { ReactNode, PropsWithChildren } from 'react'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

export const ItemInput = ({
  title,
  description,
  children,
  ...itemProps
}: PropsWithChildren<{ title: ReactNode; description?: ReactNode } & ItemProps>) => {
  return (
    <Item p="s-4" {...itemProps} style={transparentThemeBackgroundOverrides}>
      <Item.Content>
        <Item.Title>
          <Text variant="h6">{title}</Text>
        </Item.Title>
        {description && <Item.Description>{description}</Item.Description>}
      </Item.Content>
      <Item.Side style={{ textAlign: 'left' }}>{children}</Item.Side>
    </Item>
  )
}
