import React, { useState } from 'react'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import {
  DragAndDrop,
  Icon,
  Item,
  ItemSkeleton,
  MoreButton,
  Tooltip,
  VStack,
  useTooltip,
} from '@revolut/ui-kit'
import HiringStageDetails from '@src/components/HiringStages/HiringStageDetails'
import { move } from '@src/utils/move'

type HiringProcessRoundDetailsProps = {
  disableDelete?: boolean
  disableDeleteLabel?: string
  disableSort?: boolean
  hiringProcessRound: HiringProcessInterface
  loading?: boolean
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  onDeleteRound?: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound?: (hiringProcessRound: HiringProcessInterface) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
}

const HiringProcessRoundDetails = ({
  disableDelete,
  disableDeleteLabel,
  disableSort = false,
  hiringProcessRound,
  loading = false,
  sortable,
  onDeleteRound,
  onEditRound,
  onShowRoundDetails,
}: HiringProcessRoundDetailsProps) => {
  const tooltip = useTooltip()
  if (loading) {
    return <ItemSkeleton />
  }
  return (
    <Item
      use={onShowRoundDetails ? 'label' : undefined}
      onClick={e => {
        e.preventDefault()
        onShowRoundDetails?.(hiringProcessRound)
      }}
      {...(disableSort
        ? {}
        : {
            ref: sortable?.setNodeRef,
            style: sortable
              ? {
                  transform: sortable.transform
                    ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                    : undefined,
                  transition: sortable.transition || 'none',
                  opacity: sortable.isDragging ? 0 : undefined,
                }
              : undefined,
          })}
    >
      {disableSort ? null : (
        <Item.Avatar {...sortable?.attributes} {...sortable?.listeners}>
          <Icon name="Drag" />
        </Item.Avatar>
      )}
      <Item.Content>
        <Item.Title>
          {hiringProcessRound.title || hiringProcessRound.company_hiring_stage?.name}
        </Item.Title>
        <Item.Description>
          <HiringStageDetails hiringStage={hiringProcessRound} />
        </Item.Description>
      </Item.Content>
      {!onEditRound && !onDeleteRound ? null : (
        <Item.Side>
          <Item.Value>
            <MoreButton
              variant="icon"
              label="More actions"
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {onEditRound && (
                <MoreButton.Action
                  useIcon="Pencil"
                  onClick={e => {
                    e.stopPropagation()
                    onEditRound?.(hiringProcessRound)
                  }}
                >
                  Edit stage
                </MoreButton.Action>
              )}
              {onDeleteRound && (
                <MoreButton.Action
                  aria-disabled={disableDelete}
                  useIcon="Delete"
                  onClick={e => {
                    e.stopPropagation()
                    if (!disableDelete) {
                      onDeleteRound?.(hiringProcessRound)
                    }
                  }}
                  {...tooltip.getAnchorProps()}
                >
                  Remove stage
                  {disableDelete && disableDeleteLabel ? (
                    <Tooltip placement="bottom" {...tooltip.getTargetProps()}>
                      {disableDeleteLabel}
                    </Tooltip>
                  ) : null}
                </MoreButton.Action>
              )}
            </MoreButton>
          </Item.Value>
        </Item.Side>
      )}
    </Item>
  )
}

type HiringProcessDetailsProps = {
  hiringProcessRounds: HiringProcessInterface[]
  loading?: boolean
  onDeleteRound?: (hiringProcessRound: HiringProcessInterface) => void
  onEditRound?: (hiringProcessRound: HiringProcessInterface) => void
  onOrderChange?: (newHiringProcessRounds: HiringProcessInterface[]) => void
  onShowRoundDetails?: (hiringProcessRound: HiringProcessInterface) => void
}

const HiringProcessDetails = ({
  hiringProcessRounds,
  loading = false,
  onDeleteRound,
  onEditRound,
  onOrderChange,
  onShowRoundDetails,
}: HiringProcessDetailsProps) => {
  const [activeId, setActiveId] = useState<string | number | null>(null)
  const activeHiringProcessIndex =
    activeId !== null
      ? hiringProcessRounds.findIndex(({ id }) => String(id) === activeId)
      : -1
  const activeHiringProcess =
    activeHiringProcessIndex >= 0
      ? hiringProcessRounds[activeHiringProcessIndex]
      : undefined
  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      onOrderChange?.(move(hiringProcessRounds, startIndex, endIndex))
    }
    setActiveId(null)
  }
  const disableCVScreeningDelete =
    hiringProcessRounds.filter(({ stage_type }) => stage_type.id === 'cv_screening')
      .length === 1
  return (
    <VStack gap="s-8">
      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveId(event.active.id)
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveId(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={hiringProcessRounds.map(({ id }) => String(id))}
        >
          {sortable => {
            const currentProcessIndex = hiringProcessRounds.findIndex(
              ({ id }) => String(id) === sortable.id,
            )
            const currentProcess = hiringProcessRounds[currentProcessIndex]
            return (
              <HiringProcessRoundDetails
                key={currentProcess.id}
                disableDelete={
                  currentProcess.stage_type.id === 'cv_screening' &&
                  disableCVScreeningDelete
                }
                disableDeleteLabel="Cannot delete cv screening stage"
                disableSort={!onOrderChange}
                hiringProcessRound={currentProcess}
                loading={loading}
                sortable={sortable}
                onDeleteRound={onDeleteRound}
                onEditRound={onEditRound}
                onShowRoundDetails={onShowRoundDetails}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeHiringProcess && (
            <>
              <HiringProcessRoundDetails
                key={activeHiringProcess.id}
                disableDelete
                disableSort={false}
                hiringProcessRound={activeHiringProcess}
                onDeleteRound={onDeleteRound}
                onEditRound={onEditRound}
              />
            </>
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
    </VStack>
  )
}

export default HiringProcessDetails
