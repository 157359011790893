import React from 'react'
import DiversityForm, { validator, Props } from './General'
import Form from '@src/features/Form/Form'
import { diversityRequests } from '@src/api/employees'

const Diversity = (props: Props) => {
  return (
    <Form api={diversityRequests} validator={validator}>
      <DiversityForm {...props} />
    </Form>
  )
}

export default Diversity
