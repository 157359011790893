import React from 'react'
import { DetailsCell, DetailsCellSkeleton, Icon, Text, Token } from '@revolut/ui-kit'

interface Props {
  joiningDateTime: string | undefined
  isLoading?: boolean
}
export const JoiningDateDetails = ({ joiningDateTime, isLoading }: Props) => {
  if (isLoading) {
    return <DetailsCellSkeleton />
  }
  if (!joiningDateTime) {
    return null
  }
  return (
    <DetailsCell>
      <DetailsCell.Title alignSelf="center">
        <Text color={Token.color.foreground} variant="primary">
          Your expected joining date as per our records is {joiningDateTime}
        </Text>
      </DetailsCell.Title>
      <DetailsCell.Content alignSelf="center">
        <Icon color={Token.color.blue} name="CalendarDate" />
      </DetailsCell.Content>
    </DetailsCell>
  )
}
