import React from 'react'
import { useGetEmployeePersonalSettings } from '@src/api/employees'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { ItemSkeleton, Link as UIKitLink } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { Link } from 'react-router-dom'

export const OfficeSuiteProviderNotSelected = () => {
  const { data: employeeSettings, isLoading } = useGetEmployeePersonalSettings()

  if (isLoading) {
    return <ItemSkeleton />
  }
  if (employeeSettings?.office_suite_provider) {
    return null
  }

  return (
    <ActionWidget
      title="Workspace provider not selected"
      text={
        <>
          Please contact your company administrator and ask them to choose the office
          suite provider (Google Workspace or Microsoft365) in{' '}
          <UIKitLink use={Link} to={ROUTES.FEATURES.INTEGRATIONS}>
            Integrations settings
          </UIKitLink>{' '}
          settings. Once the provider is selected by the administrator, you will be able
          to connect your Revolut People account to work email and calendar applications.
        </>
      }
    />
  )
}
