import React, { useState } from 'react'
import { FilterButton } from '@revolut/ui-kit'
import { useGetConfidentialRequisitionPermissions } from '@src/api/requisitions'

export const getConfidentialFilter = (isConfidential?: boolean) => ({
  filters:
    isConfidential === undefined
      ? []
      : isConfidential
      ? [
          {
            name: 'True',
            id: 'True',
          },
        ]
      : [
          {
            name: 'False',
            id: 'False',
          },
        ],
  columnName: 'is_confidential',
  disableQueryParam: true,
  nonResettable: true,
})

type ShowConfidentialFilterProps = {
  disabled: boolean
  onClick: (show: boolean) => void
}

const ShowConfidentialFilter = ({ disabled, onClick }: ShowConfidentialFilterProps) => {
  const [showConfidential, setShowConfidential] = useState(false)
  const { data } = useGetConfidentialRequisitionPermissions()
  if (!data?.display_confidential_button) {
    return null
  }
  return (
    <FilterButton
      disabled={disabled}
      onClick={() => {
        onClick(!showConfidential)
        setShowConfidential(!showConfidential)
      }}
      active={showConfidential}
    >
      Show confidential
    </FilterButton>
  )
}

export default ShowConfidentialFilter
