import React from 'react'
import DocumentsForm from './General'
import Form from '@src/features/Form/Form'
import { newDocumentRequests } from '@src/api/documents'
import { connect } from 'lape'

export default connect(() => {
  return (
    <Form api={newDocumentRequests}>
      <DocumentsForm />
    </Form>
  )
})
