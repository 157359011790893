import React, { useEffect, useMemo, useState } from 'react'
import {
  Color,
  InputGroup,
  ItemSkeleton,
  MoreBar,
  Sticky,
  Text,
  Token,
} from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeMultiInput from '@src/components/Inputs/LapeFields/LapeMultiInput'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import AutoStepper from '@components/Stepper/AutoStepper'
import { connect, useLape } from 'lape'
import {
  JobPostingLocationInterface,
  JobPostingLocationTypeInterface,
} from '@src/interfaces/jobPosting'
import { NewCandidateInterface } from '@src/interfaces/newCandidate'
import uniqBy from 'lodash/uniqBy'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import { OptionInterface } from '@src/interfaces/selectors'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewCandidatePostingSelect from '@src/features/NewCandidatePostingSelect/NewCandidatePostingSelect'
import { useGetJobPostings } from '@src/api/jobPosting'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import ExistingCandidatesSidebar from '@components/ExistingCandidatesSidebar/ExistingCandidatesSidebar'
import { ExclamationTriangle } from '@revolut/icons'
import pluralize from 'pluralize'
import mainHeaderState from '@src/features/MainHeader/MainHeaderState'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { RequisitionSelectorInterface } from '@src/interfaces/requisitions'
import ConfidentialRequisitionSelect from '@src/pages/Forms/NewCandidate/ConfidentialRequisitionSelect'
import CandidateFreezingPeriodWarning from '@components/CandidateFreezingPeriodWarning/CandidateFreezingPeriodWarning'
import { useFetchExistingCandidates } from '@src/pages/Forms/NewCandidate/hooks'
import { requisitionsRequests } from '@src/api/requisitions'
import { defaultTheme } from '@src/styles/theme'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

// we load a massive pdf-dist library there
const UploadCVComponent = React.lazy(() => import('./UploadCV'))

const General = () => {
  const { sendAnalyticsEvent } = useAnalytics()
  const { values, submit } = useLapeContext<NewCandidateInterface>()
  const [openExistingCandidates, setOpenExistingCandidates] = useState(false)

  const state = useLape<{
    selectedLocations: RadioSelectOption<JobPostingLocationInterface>[]
    selectedMainPosting: JobPostingLocationTypeInterface | null
  }>({
    selectedMainPosting: null,
    selectedLocations: [],
  })

  const [loadingSpecialisation, setLoadingSpecialisation] = useState(false)

  const { existingCandidates } = useFetchExistingCandidates(
    values.full_name,
    values.email,
    values.phone,
    values.links,
  )

  useEffect(() => {
    if (openExistingCandidates && !existingCandidates?.length) {
      setOpenExistingCandidates(false)
    }
  }, [existingCandidates])

  const user = useSelector(selectUser)

  const postingIds = useMemo(() => {
    const result = []

    if (state.selectedMainPosting) {
      result.push(state.selectedMainPosting.id)
    }

    return result
  }, [state.selectedMainPosting])

  const { data: postings } = useGetJobPostings(
    [
      {
        columnName: 'id',
        filters: postingIds.map(item => ({
          id: item,
          name: String(item),
        })),
      },
    ],
    !!postingIds?.length,
  )

  useEffect(() => {
    const mainPostingId = state.selectedMainPosting?.id

    if (postings?.length && mainPostingId) {
      const recruiter = postings.find(item => item.id === mainPostingId)?.recruiter

      if (recruiter) {
        values.recruiter = {
          id: recruiter.id,
          name: recruiter.display_name,
        }
      }
    }
  }, [postings, state.selectedMainPosting])

  useEffect(() => {
    // We can't save file to LocalStorage that is why I remove it if the data comes from there.
    if (values.resume && !values.resume.name) {
      values.resume = undefined
    }

    if (!values.created_by) {
      values.created_by = {
        id: user.id,
        name: user.full_name,
      }
    }
  }, [])

  const locationOptions = useMemo(
    () =>
      uniqBy(state.selectedMainPosting?.locations || [], 'id').map(item => ({
        label: item.name,
        value: item,
      })),
    [state.selectedMainPosting],
  )

  const onSubmit = () => {
    values.posting_id =
      state.selectedMainPosting?.id !== undefined
        ? String(state.selectedMainPosting.id)
        : undefined
    values.preferred_locations = state.selectedLocations.map(item => item.value.name)

    return submit()
  }

  const showStatusPopup = useShowStatusPopup()

  const handleConfidentialRequisitionChange = async (
    requisitionSelector: RequisitionSelectorInterface | null,
  ) => {
    if (requisitionSelector) {
      values.requisition = {
        id: requisitionSelector.id,
        requisition_title: requisitionSelector.requisition_title,
      }
      setLoadingSpecialisation(true)
      try {
        const res = await requisitionsRequests.getItem(requisitionSelector.id!)

        if (!res.data.specialisation) {
          showStatusPopup({
            status: 'error',
            title: 'Could not load specialisation',
          })
        } else {
          values.specialisation = {
            id: res.data.specialisation.id,
            name: res.data.specialisation.name,
            status: res.data.specialisation.status,
          }
        }
      } finally {
        setLoadingSpecialisation(false)
      }
    }
  }

  return (
    <>
      <PageBody>
        {!!existingCandidates?.length && (
          <Sticky
            top={mainHeaderState.height}
            bg={Token.color.layoutBackground}
            zIndex={defaultTheme.zIndex.aboveMain}
            pb="s-8"
          >
            <ActionWidget
              title="This candidate may be already in the system"
              text={
                <>
                  Please review similar{' '}
                  {pluralize('profile', existingCandidates.length, true)} found. <br />
                  In case none of these matches, continue with adding a new candidate.
                </>
              }
              avatar={<ExclamationTriangle color={Color.BLUE} />}
            >
              <MoreBar>
                <MoreBar.Action
                  onClick={() => {
                    setOpenExistingCandidates(true)
                    sendAnalyticsEvent(AnalyticsEvents.click_show_existing_candidates)
                  }}
                >
                  Show existing candidates
                </MoreBar.Action>
              </MoreBar>
            </ActionWidget>
          </Sticky>
        )}
        <InputGroup>
          <React.Suspense fallback={<ItemSkeleton />}>
            <UploadCVComponent name="resume.id" />
          </React.Suspense>
          <CandidateFreezingPeriodWarning
            specialisation={state.selectedMainPosting?.specialisation}
            existingCandidates={existingCandidates}
            showOpenProfileButton
          />
          {values.is_confidential ? (
            <>
              <LapeRadioSelectInput
                name="specialisation"
                label="Specialisation"
                loading={loadingSpecialisation}
                selector={selectorKeys.specialisations}
              />
              <ConfidentialRequisitionSelect
                requisition={values.requisition}
                onChange={handleConfidentialRequisitionChange}
              />
            </>
          ) : (
            <NewCandidatePostingSelect
              selectedSecondaryPostings={[]}
              selectedMainPosting={state.selectedMainPosting}
              onChangeSelectedMainPosting={val => {
                state.selectedMainPosting = val
              }}
            />
          )}
        </InputGroup>

        <AutoStepper>
          <NewStepperTitle title="General information" />
          <InputGroup>
            <LapeNewSwitch
              name="is_confidential"
              itemTypeProps={{ title: 'This is a confidential candidate' }}
              onAfterChange={isConfidential => {
                if (!isConfidential) {
                  values.specialisation = undefined
                  values.requisition = undefined
                }
              }}
            />
            {values.is_confidential && (
              <ActionWidget
                avatarColor="grey-tone-50"
                title="What does confidential candidate mean?"
                text={
                  <Text color="foreground">
                    <ul style={{ margin: 0, paddingInlineStart: 24 }}>
                      <li>
                        Only the recruiter and sourcer will be able to view the
                        candidate's profile
                      </li>
                      <li>
                        Recruiter will have the option to provide the access of
                        candidate's profile to other people (if necessary)
                      </li>
                    </ul>
                  </Text>
                }
              />
            )}

            <LapeNewInput name="full_name" label="Candidate full name" required />
            <LapeNewInput name="email" label="Email" required />
            <LapeNewInput
              name="phone"
              label="Mobile"
              message="Please provide a mobile number in the international format (including country code, e.g +999999999)."
            />
            <LapeNewInput name="current_company" label="Current company" />
            <LapeRadioSelectInput<OptionInterface>
              name="country"
              label="Current country"
              selector={selectorKeys.countries}
            />
          </InputGroup>

          <NewStepperTitle title="Work details" />
          <InputGroup>
            <LapeMultiInput
              title="Add additional link eg LinkedIn, Github, portfolio, website etc (optional)"
              name="links"
              valueFieldName={undefined}
            />
          </InputGroup>

          <NewStepperTitle title="Recommended seniority" />
          <InputGroup>
            <LapeRadioSelectInput
              name="seniority"
              label="Seniority"
              selector={selectorKeys.seniority}
              optional
            />
            <NewMultiSelect
              placeholder="Preferred work locations (optional)"
              options={locationOptions}
              value={state.selectedLocations}
              onChange={val => {
                state.selectedLocations = val
              }}
            />
            <LapeRadioSelectInput
              name="recruiter"
              label="Recruiter"
              selector={selectorKeys.employee}
            />
            <HideIfCommercial>
              <LapeRadioSelectInput
                name="coordinator"
                label="Coordinator"
                required={false}
                selector={selectorKeys.employee}
                clearable
              />
            </HideIfCommercial>
            <LapeRadioSelectInput
              name="created_by"
              label="Sourced by"
              selector={selectorKeys.employee}
            />
          </InputGroup>

          <NewStepperTitle title="Notes" />
          <InputGroup>
            <LapeNewTextArea
              name="notes"
              label="Add any additional comments here"
              rows={3}
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup<NewCandidateInterface>
          successText="New candidate saved successfully"
          onAfterSubmit={resp => {
            if (resp.candidate?.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                  id: resp.candidate.id,
                }),
              )
              return
            }

            navigateReplace(pathToUrl(ROUTES.RECRUITMENT.CANDIDATES))
          }}
          useValidator
          onClick={onSubmit}
        />
      </PageActions>
      <ExistingCandidatesSidebar
        onClose={() => setOpenExistingCandidates(false)}
        isOpen={openExistingCandidates}
        items={existingCandidates}
      />
    </>
  )
}

export default connect(General)
