import React, { useMemo, useState } from 'react'
import {
  ActionButton,
  RadioSelect,
  SelectOptionItemType,
  useDropdown,
} from '@revolut/ui-kit'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { RowInterface, SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { skillSpecialisationExport, skillSpecialisationsRequests } from '@src/api/skills'
import { useParams } from 'react-router-dom'
import { SkillSpecialisationInterface } from '@src/interfaces/skills'
import { useGetSeniorityList } from '@src/api/seniority'
import { SeniorityInterface } from '@src/interfaces/seniority'
import PageLoading from '@components/PageLoading/PageLoading'
import {
  getSkillCompetencyColumn,
  skillSpecialisationFunctionColumn,
  skillSpecialisationHeadcountColumn,
  skillSpecialisationNameColumn,
} from '@src/constants/columns/skill'
import { ExportTypes } from '@src/constants/export'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

type SeniorityRange = [number, number]

interface SeniorityRangeSelectProps {
  onSelect: (range: SeniorityRange) => void
  options: SelectOptionItemType<SeniorityRange>[]
}

const regularRange: SeniorityRange = [0, 4]
const executiveRange: SeniorityRange = [5, 9]
const getRangeOptions = (
  seniorities: SeniorityInterface[],
): SelectOptionItemType<SeniorityRange>[] => {
  const findRangeLabels = (
    range: SeniorityRange,
  ): { lowest: string | undefined; highest: string | undefined } => {
    return {
      lowest: seniorities.find(sen => sen.level === range[0])?.name,
      highest: seniorities.find(sen => sen.level === range[1])?.name,
    }
  }
  const getFormattedLabel = (lowest: string, highest: string) => {
    return `${lowest} - ${highest}`
  }
  const regular = findRangeLabels(regularRange)
  const executive = findRangeLabels(executiveRange)

  return [
    {
      value: regularRange,
      key: 'regular',
      label: getFormattedLabel(regular.lowest || 'Graduate', regular.highest || 'Lead'),
    },
    {
      value: executiveRange,
      key: 'executive',
      label: getFormattedLabel(
        executive.lowest || 'Director',
        executive.highest || 'Chief',
      ),
    },
  ]
}

const getRow = (
  seniorities: SeniorityInterface[],
  range: SeniorityRange,
): RowInterface<SkillSpecialisationInterface> => ({
  cells: [
    {
      ...skillSpecialisationNameColumn,
      width: 200,
    },
    {
      ...skillSpecialisationFunctionColumn,
      width: 150,
    },
    {
      ...skillSpecialisationHeadcountColumn,
      width: 120,
    },
    ...seniorities
      .filter(({ level }) => level >= range[0] && level <= range[1])
      .map(seniority => ({ ...getSkillCompetencyColumn(seniority), width: 120 })),
  ],
})

const defaultSorting: SortByInterface[] = [
  { direction: SORT_DIRECTION.DESC, sortBy: 'name' },
]

const SkillSpecialisations = () => {
  const { id } = useParams<{ id: string }>()
  const table = useTable<SkillSpecialisationInterface>(
    skillSpecialisationsRequests(+id),
    [],
    defaultSorting,
  )

  const [seniorityRange, setSeniorityRange] = useState<SeniorityRange>(regularRange)
  const seniorityList = useGetSeniorityList()
  const seniorities = seniorityList.data?.results

  const row = useMemo(
    () => seniorities && getRow(seniorities, seniorityRange),
    [seniorities, seniorityRange],
  )

  const rangeOptions = useMemo(
    () => seniorities && getRangeOptions(seniorities),
    [seniorities],
  )

  if (!row || !rangeOptions) {
    return <PageLoading />
  }

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <ExportMenu
            fileName="Specialisations"
            request={skillSpecialisationExport(+id)}
            supportedTypes={[ExportTypes.csv]}
          />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Filters>
        <SeniorityRangeSelect
          onSelect={range => setSeniorityRange(range)}
          options={rangeOptions}
        />
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable<SkillSpecialisationInterface>
          name={TableNames.SkillSpecialisations}
          row={row}
          hideCount
          {...table}
          loading={seniorityList.isLoading || table.loading}
          useWindowScroll
          dataType="Specialisation"
          emptyState={<EmptyTableRaw title="No specialisations found" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

const SeniorityRangeSelect = ({ onSelect, options }: SeniorityRangeSelectProps) => {
  const dropdown = useDropdown()
  const targetProps = dropdown.getTargetProps<HTMLElement>()
  const [option, setOption] = useState<SelectOptionItemType<SeniorityRange>>(options[0])

  return (
    <>
      <ActionButton useIcon="Filter" {...dropdown.getAnchorProps()} variant="accent">
        {option.label}
      </ActionButton>
      <RadioSelect<SeniorityRange>
        {...targetProps}
        onChange={val => {
          if (val) {
            setOption(options.find(opt => opt.value[0] === val[0]) || options[0])
            onSelect(val)
          }
          targetProps?.onClose()
        }}
        options={options}
        value={option.value}
        labelList="Seniority range options"
      />
    </>
  )
}

export default SkillSpecialisations
