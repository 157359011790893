import React, { useCallback, useState } from 'react'
import {
  ActionButton,
  DragAndDrop,
  Flex,
  Group,
  IconButton,
  Item,
  Text,
  Token,
} from '@revolut/ui-kit'

import { move } from '@src/utils/move'
import { SortByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { SortSettingsInterface } from '../../hooks/useTableSettings'

interface Props {
  setTableSettings: React.Dispatch<React.SetStateAction<SortSettingsInterface>>
  tableSettings: SortSettingsInterface
}

export const SortColumnsSettings = ({ setTableSettings, tableSettings }: Props) => {
  const [activeId, setActiveId] = useState(null)
  const activeItem = activeId
    ? tableSettings.visible.find(item => item.sortBy === activeId)
    : null

  const onDragCancel = useCallback(() => setActiveId(null), [])
  const onDragStart = useCallback(event => setActiveId(event.active.id), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setTableSettings(current => ({
        ...current,
        visible:
          startIndex === endIndex
            ? current.visible
            : move(current.visible, startIndex, endIndex),
      }))
    }

    setActiveId(null)
  }, [])

  const handleAdd = (item: SortByInterface) => {
    setTableSettings(current => ({
      visible: [...current.visible, item],
      hidden: current.hidden.filter(t => {
        return t !== item
      }),
    }))
  }

  const handleRemove = (item: SortByInterface) => {
    setTableSettings(current => ({
      visible: current.visible.filter(t => {
        return t !== item
      }),
      hidden: [...current.hidden, item],
    }))
  }

  const handleSort = (item: SortByInterface) => {
    setTableSettings(current => ({
      ...current,
      visible: current.visible.map(t =>
        t.sortBy === item.sortBy
          ? {
              ...t,
              direction:
                t.direction === SORT_DIRECTION.ASC
                  ? SORT_DIRECTION.DESC
                  : SORT_DIRECTION.ASC,
            }
          : t,
      ),
    }))
  }

  return (
    <Flex flexDirection="column" gap="s-16">
      <Text>
        The sorting order of the columns will be top to bottom with the first column
        denoting the first table sort and any subsequent column sorting rules to sort
        within the preceding groups
      </Text>
      <Group>
        <DragAndDrop.Provider
          onDragCancel={onDragCancel}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <DragAndDrop.Sortable
            id="sortable-table-preferences"
            items={tableSettings.visible.map(item => item.sortBy)}
          >
            {sortable => {
              const sortableItem = tableSettings.visible.find(
                item => item.sortBy === sortable.id,
              )

              return sortableItem ? (
                <Item
                  ref={sortable.setNodeRef}
                  style={{
                    opacity: sortable.isDragging ? 0 : undefined,
                    transform: sortable.transform
                      ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                      : undefined,
                    transition: sortable.transition || 'none',
                  }}
                >
                  <Item.Prefix>
                    <IconButton
                      aria-label="Remove"
                      color={Token.color.red}
                      onClick={() => handleRemove(sortableItem)}
                      size={16}
                      useIcon="MinusCircle"
                    />
                  </Item.Prefix>
                  <Item.Content>
                    <Item.Title>{sortableItem.sortBy}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Flex alignItems="center" gap="s-12">
                      <ActionButton
                        aria-label="Sort change"
                        onClick={() => handleSort(sortableItem)}
                        size="xs"
                        useIcon={
                          sortableItem.direction === SORT_DIRECTION.ASC
                            ? 'ArrowDown'
                            : 'ArrowUp'
                        }
                      >
                        {sortableItem.direction === SORT_DIRECTION.ASC
                          ? 'Descending'
                          : 'Ascending'}
                      </ActionButton>
                      <IconButton
                        aria-label="Drag"
                        color={Token.color.greyTone20}
                        size={16}
                        useIcon="Drag"
                        {...sortable.attributes}
                        {...sortable.listeners}
                      />
                    </Flex>
                  </Item.Side>
                </Item>
              ) : null
            }}
          </DragAndDrop.Sortable>
          <DragAndDrop.DragOverlay>
            {activeItem && (
              <Item>
                <Item.Prefix>
                  <IconButton
                    aria-label="Remove"
                    color={Token.color.red}
                    size={16}
                    useIcon="MinusCircle"
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{activeItem.sortBy}</Item.Title>
                </Item.Content>
                <Item.Side>
                  <Flex alignItems="center" gap="s-12">
                    <ActionButton
                      size="xs"
                      useIcon={
                        activeItem.direction === SORT_DIRECTION.ASC
                          ? 'ArrowUp'
                          : 'ArrowDown'
                      }
                    >
                      {activeItem.direction === SORT_DIRECTION.ASC
                        ? 'Ascending'
                        : 'Descending'}
                    </ActionButton>
                    <IconButton
                      aria-label="Drag"
                      color={Token.color.greyTone20}
                      size={16}
                      useIcon="Drag"
                    />
                  </Flex>
                </Item.Side>
              </Item>
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
      </Group>

      <Text>Select columns below to use for table sorting</Text>
      <Group>
        {tableSettings.hidden.map(item => {
          return (
            <Item key={item.sortBy}>
              <Item.Prefix>
                <IconButton
                  aria-label="Add"
                  color={Token.color.blue}
                  onClick={() => handleAdd(item)}
                  size={16}
                  useIcon="PlusCircle"
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title>{item.sortBy}</Item.Title>
              </Item.Content>
            </Item>
          )
        })}
      </Group>
    </Flex>
  )
}
