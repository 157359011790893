import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { AutomationListInterface } from '@src/interfaces/jobPostings/automation'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'
import React from 'react'

export const automationNameColumn: ColumnInterface<AutomationListInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Automation name',
}

export const automationCandidatesColumn: ColumnInterface<AutomationListInterface> = {
  type: CellTypes.text,
  idPoint: 'affected_candidates_count',
  dataPoint: 'affected_candidates_count',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Candidates affected',
}

export const automationCreatedColumn: ColumnInterface<AutomationListInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const automationUpdatedColumn: ColumnInterface<AutomationListInterface> = {
  type: CellTypes.date,
  idPoint: 'updated_date_time',
  dataPoint: 'updated_date_time',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Updated on',
}

export const automationStatusColumn: ColumnInterface<AutomationListInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) =>
    data.status ? (
      <Table.StatusCell
        status={data.status.name || data.status.id}
        variant={getDefaultStatusVariant(data.status.id)}
      />
    ) : (
      '-'
    ),
}
