import { AppIconSize, Color } from '@revolut/ui-kit'

export const getIconProps = (
  image: string,
  bg: Color | string,
  size: AppIconSize,
  type: 'png' | 'jpg' = 'jpg',
) => ({
  bg,
  size,
  image: getIconImage(image, size, type),
})

export const getIconImage = (
  image: string,
  size: AppIconSize,
  type: 'png' | 'jpg' = 'jpg',
) => ({
  default: `https://assets.revolut.com/revoluters/hub-icons/${image}-${size}.${type}`,
  '2x': `https://assets.revolut.com/revoluters/hub-icons/${image}-${size}@2x.${type}`,
})

export const getServerLabel = (url: string) => {
  return new URL(url).hostname.substring(0, 1)
}
