import React, { useEffect, useState } from 'react'
import { ActionWidget, MoreBar, Portal, Skeleton, Widget } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import SideBar from '@src/components/SideBar/SideBar'
import { useGetOfferTemplateHTML } from '@src/api/offerTemplates'
import StyledHTMLLetter from '@src/components/StyledHTMLLetter'

type Props = {
  id?: number | string
  message: string
  preview: boolean
}

const OfferTemplatePreview = ({ id, message, preview }: Props) => {
  const [openOfferTemplatePreviewSidebar, setOpenOfferTemplatePreviewSidebar] =
    useState(false)
  const [stateId, setStateId] = useState(id)

  if (stateId !== id) {
    setStateId(id)
  }

  const {
    data: offerTemplateHTML,
    isError,
    isLoading,
    refetch,
  } = useGetOfferTemplateHTML(stateId)

  useEffect(() => {
    if (preview && openOfferTemplatePreviewSidebar) {
      refetch()
    }
  }, [stateId])

  return (
    <>
      <MoreBar.Action
        onClick={() => {
          setOpenOfferTemplatePreviewSidebar(!openOfferTemplatePreviewSidebar)
          if (preview && !offerTemplateHTML) {
            refetch()
          }
        }}
      >
        Preview offer template
      </MoreBar.Action>
      <Portal>
        <SideBar
          variant="wide"
          title="Offer template preview"
          isOpen={openOfferTemplatePreviewSidebar}
          onClose={() => setOpenOfferTemplatePreviewSidebar(false)}
          sideProps={{
            resizable: true,
          }}
        >
          {isError && (
            <ActionWidget>
              <ActionWidget.Title>Something went wrong</ActionWidget.Title>
              <ActionWidget.Avatar>
                <InfoOutline color="blue" />
              </ActionWidget.Avatar>
              <ActionWidget.Content>Can't preview offer template</ActionWidget.Content>
            </ActionWidget>
          )}
          {!preview && (
            <ActionWidget>
              <ActionWidget.Title>{message}</ActionWidget.Title>
              <ActionWidget.Avatar>
                <InfoOutline color="blue" />
              </ActionWidget.Avatar>
            </ActionWidget>
          )}
          {preview && (
            <>
              {isLoading && (
                <Widget p="s-16">
                  <Skeleton />
                </Widget>
              )}
              {!isLoading && offerTemplateHTML && (
                <StyledHTMLLetter content={offerTemplateHTML.offer_template_html} />
              )}
            </>
          )}
        </SideBar>
      </Portal>
    </>
  )
}

export default OfferTemplatePreview
