import React from 'react'
import { connect } from 'lape'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'

export const Actions = connect(({ backPath }: { backPath: string }) => {
  return (
    <PageActions>
      <NewSaveButtonWithPopup useValidator afterSubmitUrl={backPath} />
    </PageActions>
  )
})
