import React, { useEffect, useState } from 'react'
import { connect } from 'lape'

import { Box, H5, InputGroup, MoreBar, Side } from '@revolut/ui-kit'
import { selectorKeys } from '@src/constants/api'
import SideBar from '@components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import {
  responsibilityAssignmentFormRequests,
  transferResponsibilityAssignment,
} from '@src/api/keyPerson'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KeyPersonResponsibilityAssignmentInterface } from '@src/interfaces/keyPerson'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { PermissionTypes } from '@src/store/auth/types'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { TransferResponsibilityConfirmation } from './TransferResponsibilityConfirmation'

const FormContent = ({
  onClose,
  onUpdateResponsibility,
  employeeId,
}: Omit<Props, 'isOpen'>) => {
  const { initialValues, values } =
    useLapeContext<KeyPersonResponsibilityAssignmentInterface>()

  const [isDeleteConfirmationOpened, setIsDeleteConfirmationOpened] = useState(false)
  const [isDeletePending, setIsDeletePending] = useState(false)

  const [submitTransferPopupOpen, setSubmitTransferPopupOpen] = useState(false)
  const [transferToEmployee, setTransferToEmployee] = useState<EmployeeOptionInterface>()
  const [isTransferPending, setIsTransferPending] = useState(false)

  useEffect(() => {
    if (employeeId) {
      values.employee = { id: employeeId }
    }
  }, [])

  const canDelete = values.field_options?.permissions?.includes(
    PermissionTypes.DeleteKeyRiskProfile,
  )
  const showDelete = canDelete && !!initialValues.employee
  const canTransfer = values.field_options?.permissions?.includes(
    PermissionTypes.TransferKeyRiskProfile,
  )
  const showTransfer = canTransfer && !!initialValues.employee

  return (
    <>
      <Box mb={showTransfer || showDelete ? 's-16' : undefined}>
        <MoreBar>
          {showDelete ? (
            <MoreBar.Action
              variant="negative"
              useIcon="Delete"
              onClick={() => setIsDeleteConfirmationOpened(true)}
            >
              Delete
            </MoreBar.Action>
          ) : null}
          {showTransfer ? (
            <MoreBar.Action
              useIcon="ArrowRightLeft"
              onClick={() => setSubmitTransferPopupOpen(true)}
            >
              Transfer
            </MoreBar.Action>
          ) : null}
        </MoreBar>
      </Box>
      <InputGroup>
        {!employeeId && (
          <LapeRadioSelectInput
            name="employee"
            label="Employee"
            selector={selectorKeys.employee}
            disabled={!!employeeId}
          />
        )}
        <LapeRadioSelectInput
          name="key_person_type"
          label="Responsibility"
          selector={selectorKeys.key_person_types_name}
        />
        <LapeRadioSelectInput
          name="entity"
          label="Entity"
          selector={selectorKeys.entity}
        />
        <LapeDatePickerInput name="start_date" label="Start date" />
        <LapeDatePickerInput name="end_date" label="End date" />
        <LapeRadioSelectInput
          name="assignment_period"
          label="Period"
          selector={selectorKeys.key_risk_profile_assignment_period}
        />
        <LapeNewTextArea name="notes" label="Description" rows={3} />
      </InputGroup>
      <Side.Actions>
        <NewSaveButtonWithPopup
          useValidator
          onAfterSubmit={() => {
            onUpdateResponsibility()
            onClose()
          }}
          successText={
            values.id
              ? 'Assignment was successfully updated'
              : 'Responsibility was successfully assigned'
          }
        >
          {values.id ? 'Save changes' : 'Submit'}
        </NewSaveButtonWithPopup>
      </Side.Actions>
      <ConfirmationDialog
        showHeader={false}
        body={<H5>Are you sure you want to delete the assignment?</H5>}
        open={isDeleteConfirmationOpened}
        loading={isDeletePending}
        onClose={() => {
          setIsDeleteConfirmationOpened(false)
        }}
        onReject={() => {
          setIsDeleteConfirmationOpened(false)
        }}
        onConfirm={async () => {
          try {
            setIsDeletePending(true)

            await responsibilityAssignmentFormRequests.delete?.({ id: values.id }, {})
            onUpdateResponsibility()
            onClose()
          } finally {
            setIsDeletePending(false)
            setIsDeleteConfirmationOpened(false)
          }
        }}
        yesMessage="Confirm"
        noMessage="Cancel"
      />
      <TransferResponsibilityConfirmation
        open={submitTransferPopupOpen}
        onClose={() => setSubmitTransferPopupOpen(false)}
        onReject={() => setSubmitTransferPopupOpen(false)}
        loading={isTransferPending}
        transferToEmployee={transferToEmployee}
        setTransferToEmployee={setTransferToEmployee}
        onConfirm={async () => {
          if (!transferToEmployee?.id) {
            return
          }
          try {
            setIsTransferPending(true)
            await transferResponsibilityAssignment(values.id, transferToEmployee.id)
            onUpdateResponsibility()
            setSubmitTransferPopupOpen(false)
          } finally {
            setIsTransferPending(false)
          }
        }}
      />
    </>
  )
}

const SidebarContent = connect(
  ({ assignmentId, ...restProps }: Omit<Props, 'isOpen'>) => {
    return (
      <Form
        disableLocalStorageCaching
        forceParams={{ id: assignmentId ? String(assignmentId) : undefined }}
        api={responsibilityAssignmentFormRequests}
      >
        <FormContent {...restProps} />
      </Form>
    )
  },
)

type Props = {
  isOpen: boolean
  onClose: () => void
  onUpdateResponsibility: () => void
  assignmentId?: number
  employeeId?: number
}
export const ResponsibilityAssignmentSidebar = ({
  assignmentId,
  isOpen,
  onClose,
  onUpdateResponsibility,
  employeeId,
}: Props) => {
  return (
    <SideBar
      isOpen={isOpen}
      onClose={onClose}
      title={assignmentId ? 'Edit assignment' : 'Create assignment'}
      subtitle={employeeId ? <EmployeeUserWithAvatar id={employeeId} /> : undefined}
    >
      <SidebarContent
        assignmentId={assignmentId}
        onClose={onClose}
        onUpdateResponsibility={onUpdateResponsibility}
        employeeId={employeeId}
      />
    </SideBar>
  )
}
