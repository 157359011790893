import React from 'react'
import { Dropdown, Icon, MoreBar } from '@revolut/ui-kit'

import { AssignGroupsSidebar } from '@src/pages/Admin/AccessManagement/Users/AssignGroupsSidebar'
import { useCanViewAssignPermissionGroup } from '../common'
import { EmployeeButtonProps } from '../types'
import { useEmployeeAssignedPermissionsAction } from './hooks'

type PermissionsActionProps = {
  onClick: () => void
  isDropdownItem?: boolean
}
export const PermissionGroupsAction = ({
  isDropdownItem,
  onClick,
}: PermissionsActionProps) => {
  const canView = useCanViewAssignPermissionGroup()

  if (!canView) {
    return null
  }
  const icon = <Icon name="16/LockClosed" size={16} />

  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" useIcon={icon} onClick={onClick}>
        Manage permissions
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action useIcon={icon} onClick={onClick}>
      Manage permissions
    </MoreBar.Action>
  )
}

export const AssignPermissions = ({ data }: EmployeeButtonProps) => {
  const { onClick, selectedUser, setSelectedUser } =
    useEmployeeAssignedPermissionsAction()

  if (!data.user_id) {
    return null
  }
  return (
    <>
      <PermissionGroupsAction onClick={() => onClick(String(data.user_id))} />
      <AssignGroupsSidebar
        selectedUser={selectedUser}
        onClose={() => setSelectedUser(undefined)}
      />
    </>
  )
}
