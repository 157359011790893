import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import { RequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { DocumentsCollectionsInterface } from '@src/interfaces/documentsCollections'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const documentsCollectionsRequests: RequestInterface<DocumentsCollectionsInterface> =
  {
    getItems: async ({ sortBy, filters, page }) =>
      api.get(`${API.DOCUMENTS}/collections`, {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      }),
    getItem: async id => api.get(`${API.DOCUMENTS}/collections/${id}`),
    patchItem: async (data, id) => api.patch(`${API.DOCUMENTS}/collections/${id}`, data),
    postItem: async data => api.post(`${API.DOCUMENTS}/collections`, data),
    deleteItem: async id => api.delete(`${API.DOCUMENTS}/collections/${id}`),
  }

export const formDocumentsCollectionsRequests: RequestInterfaceNew<DocumentsCollectionsInterface> =
  {
    get: async ({ id }) => api.get(`${API.DOCUMENTS}/collections/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUMENTS}/collections/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.DOCUMENTS}/collections`, data),
    delete: async ({ id }) => api.delete(`${API.DOCUMENTS}/collections/${id}`),
  }

export const useGetDocumentCollections = (id?: number | string) =>
  useFetch<DocumentsCollectionsInterface>(
    id ? `${API.DOCUMENTS}/collections/${id}` : null,
  )
