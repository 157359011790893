import React from 'react'
import {
  getFeedbackRecommendationProps,
  getOnlineTestStageRecommendationProps,
  isCandidateSchedulingStatuses,
  isOnlineTestStage,
} from '@src/pages/Forms/Candidate/StagesWidget/utils'
import {
  CandidateInterface,
  InterviewStageWithoutRoundInterface,
  StagesWidgetOnClickHandler,
} from '@src/interfaces/interviewTool'
import { Avatar, Text, Token, VStack } from '@revolut/ui-kit'
import { StageItemLayout } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemLayout'
import { getInitials } from '@src/utils/employees'
import { formatTimeAgo } from '@src/utils/format'
import { StageItemInfo } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemInfo'
import { StageItemRecommendation } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemRecommendation'
import { StageItemRating } from '@src/pages/Forms/Candidate/StagesWidget/components/StageItemRating'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import UserName from '@src/components/UserWithAvatar/UserName'
import { upperFirst } from 'lodash'
import { convertOperators } from '@src/pages/Forms/Candidate/Performance/utils'
import { PerformanceRating } from '@src/interfaces/performance'

type StageItemsProps = {
  candidate: CandidateInterface
  disable: boolean
  stage: InterviewStageWithoutRoundInterface
  onClick?: StagesWidgetOnClickHandler
}

export const StageItems = ({ candidate, stage, onClick }: StageItemsProps) => {
  if (isCandidateSchedulingStatuses(stage)) {
    return (
      <StageItemLayout
        id={`Scheduling of ${stage.title}`}
        avatar={<Avatar size={40}>{getInitials(candidate.full_name)}</Avatar>}
        disabled
        description={
          stage.updated_date_time ? (
            <Text variant="body2">
              Interview slots sent {formatTimeAgo(stage.updated_date_time)}
            </Text>
          ) : null
        }
        title={
          stage.scheduling_status === 'pending_candidate_response'
            ? `Pending ${candidate.full_name} response`
            : stage.scheduling_status === 'scheduling_expired'
            ? 'Interview slots expired'
            : null
        }
        titleProps={{ color: Token.color.orange }}
        onClick={() => {
          onClick?.(stage, undefined, stage.interview_type)
        }}
      />
    )
  }
  if (isOnlineTestStage(stage)) {
    return (
      <StageItemLayout
        id={`Online test of ${stage.title}`}
        avatar={<Avatar size={40} useIcon="Laptop" />}
        disabled
        description={<StageItemInfo stage={stage} />}
        title={stage.online_test?.name}
        recommendation={
          <StageItemRecommendation {...getOnlineTestStageRecommendationProps(stage)} />
        }
        rating={
          <StageItemRating
            rating={
              stage.online_test_result?.percentage_score
                ? `${stage.online_test_result?.percentage_score}%`
                : ''
            }
          />
        }
        onClick={() => {
          onClick?.(stage, undefined, stage.interview_type)
        }}
      />
    )
  }
  return (
    <VStack>
      {stage.interview_feedbacks.map((feedback, index) => {
        const { interviewer = {} } = feedback
        let rating: PerformanceRating | null = feedback.rating || null

        if (!rating) {
          rating = feedback.recommended_rating
        }
        return (
          <StageItemLayout
            key={feedback.id}
            id={`Feedback ${index} of ${stage.title}`}
            avatar={<UserAvatar {...interviewer} size={40} />}
            description={<StageItemInfo feedback={feedback} stage={stage} />}
            title={<UserName {...interviewer} ellipsisProps={{ maxWidth: '200px' }} />}
            recommendation={
              <StageItemRecommendation {...getFeedbackRecommendationProps(feedback)} />
            }
            rating={
              <StageItemRating
                rating={rating ? upperFirst(convertOperators(rating)) : ''}
              />
            }
            onClick={() => {
              onClick?.(feedback, undefined, stage.interview_type)
            }}
          />
        )
      })}
    </VStack>
  )
}
