import { ActionButton, Bar, Ellipsis, InputGroup, Text } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import React, { useEffect, useState } from 'react'
import { FetchColumn } from '../SidebarForms/FetchColumn'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { UnitOfMeasure } from '../SidebarForms/components/UnitOfMeasure'
import { ProgressDirection } from '../SidebarForms/components/ProgressDirection'
import { InitTargetValues } from '../SidebarForms/components/InitTargetValues'
import { TableauPreviewPopup } from './TableauPreviewPopup'
import { tableauValidator } from './helpers'

export const TableauForm = () => {
  const { values } = useLapeContext<KpiInterface>()
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (values.tableau_view_url) {
      tableauValidator
        .parseAsync(values.tableau_view_url)
        .then(() => {
          setIsValid(true)
        })
        .catch(() => {
          setIsValid(false)
        })
    }
  }, [values.tableau_view_url])

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="tableau_view_url"
          label="View URL"
          required
          description={
            <Text>
              Provide the view URL you want to connect to eg:{' '}
              <Ellipsis>
                <Text whiteSpace="nowrap">
                  https://my-server.tableau.com/#/site/company4003a789b9/views/EmailPerformancebyCampaign/EmailPerformancebyCampaign
                </Text>
              </Ellipsis>
            </Text>
          }
        />

        {isValid ? (
          <>
            <FetchColumn type="tableau" variant="date" />
            <FetchColumn type="tableau" variant="target" />
            <UnitOfMeasure />
            <ProgressDirection />
            <InputGroup variant="horizontal">
              <InitTargetValues />
            </InputGroup>
          </>
        ) : null}

        <Bar>
          <ActionButton
            onClick={() => setIsPreviewOpen(true)}
            disabled={!isValid}
            useIcon="EyeOutline"
          >
            Preview data
          </ActionButton>
        </Bar>
      </InputGroup>
      {!!values.tableau_view_url && (
        <TableauPreviewPopup
          open={isPreviewOpen}
          src={values.tableau_view_url}
          onClose={() => setIsPreviewOpen(false)}
        />
      )}
    </>
  )
}
