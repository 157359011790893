import React, { useState } from 'react'
import {
  Header,
  MoreBar,
  StatusPopup,
  useStatusPopup,
  Button,
  BottomSheet,
  Description,
  VStack,
} from '@revolut/ui-kit'
import { ArrowBulk } from '@revolut/icons'
import { TalentPerformanceInterface } from '@src/interfaces/talent/performance'
import pluralize from 'pluralize'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import RadioSelectInput from '@src/components/Inputs/RadioSelectInput/RadioSelectInput'
import { selectorKeys } from '@src/constants/api'

interface ConfirmPopupProps {
  onSubmit: (employee: EmployeeOptionInterface) => void
  title: string
  excludedEmployees?: number[]
  open: boolean
  onClose: () => void
}

const ConfirmPopup = (props: ConfirmPopupProps) => {
  const { title, onSubmit, excludedEmployees, open, onClose } = props
  const [employee, setEmployee] = useState<EmployeeOptionInterface | null>()
  const employeeFilter = excludedEmployees
    ? (option: EmployeeOptionInterface) => {
        return !excludedEmployees.includes(Number(option.id))
      }
    : undefined
  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>{title}</Header.Title>
      </Header>
      <VStack space="s-16">
        <Description>
          Please select the person you wish to delegate these calibrations to. They will
          be able to view the reviews of employees and complete the delegated calibration.
        </Description>
        <RadioSelectInput
          value={employee}
          selector={selectorKeys.employee}
          inputProps={{ label: 'Select a calibrator' }}
          filter={employeeFilter}
          onChange={setEmployee}
        />
      </VStack>

      <BottomSheet.Actions horizontal>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          disabled={!employee}
          onClick={() => {
            onSubmit(employee!)
          }}
        >
          Submit
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}

export interface DelegateCalibrationProps {
  talents?: TalentPerformanceInterface[]
  disabled?: boolean
  delegate: (props: {
    calibratorId: number
    talents?: TalentPerformanceInterface[]
  }) => void
}

export const DelegateCalibrationMoreAction = (props: DelegateCalibrationProps) => {
  const { talents, delegate, disabled } = props
  const [confirmShown, showConfirm] = useState(false)
  const statusPopup = useStatusPopup()

  const talentsCountText = talents?.length
    ? pluralize('employee', talents.length, true)
    : 'All employees'

  const getConfirmTitle = () => {
    if (talents && talents.length === 1) {
      return `Delegate calibration of ${talents[0].employee.display_name}?`
    }
    if (talents && talents.length > 1) {
      return `Delegate calibration of ${pluralize('employee', talents.length, true)}?`
    }
    return 'Delegate calibration of all selected?'
  }

  const submit = async (employee: EmployeeOptionInterface) => {
    try {
      statusPopup.show(<StatusPopup variant="loading" />)
      await delegate({ calibratorId: employee.id, talents })
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>{talentsCountText} successfully delegated</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (err) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to delegate</StatusPopup.Title>
          <StatusPopup.Description>
            {err.message || 'Something went wrong'}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }
  return (
    <>
      <MoreBar.Action
        useIcon={ArrowBulk}
        onClick={() => showConfirm(true)}
        disabled={disabled}
      >
        Delegate
      </MoreBar.Action>
      {confirmShown && (
        <ConfirmPopup
          excludedEmployees={
            talents ? talents.map(({ employee }) => employee.id) : undefined
          }
          title={getConfirmTitle()}
          open={confirmShown}
          onClose={() => showConfirm(false)}
          onSubmit={employee => {
            showConfirm(false)
            submit(employee)
          }}
        />
      )}
    </>
  )
}
