import { OnboardingCheckpointCategory } from '@src/interfaces/onboardingChecklistV2'

export const firstStepsCategories: OnboardingCheckpointCategory[] = [
  'paymentMethod',
  'importEmployees',
  'teams',
  'roles',
]
export const performanceCategories: OnboardingCheckpointCategory[] = [
  'goals',
  'reviews',
  'engagement',
]
export const recruitmentCategories: OnboardingCheckpointCategory[] = [
  'jobs',
  'candidates',
  'interviews',
]
export const hrCategories: OnboardingCheckpointCategory[] = [
  'employeeRecords',
  'timeManagement',
  'documents',
  'payroll',
]
export const finalStepsCategories: OnboardingCheckpointCategory[] = ['finish']
