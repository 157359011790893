import { Box, Bullet, Flex, Group, Item, Subheader, Text } from '@revolut/ui-kit'
import React from 'react'

type Props = {
  sections: React.ReactNode[]
}

const HelpSections = ({ sections }: Props) => {
  return (
    <Box pb="s-16">
      <Subheader variant="nested">
        <Subheader.Title>Instructions</Subheader.Title>
      </Subheader>
      <Group>
        {sections.map((section, index) => (
          <Item key={index}>
            <Flex alignItems="flex-start">
              <Bullet style={{ flexShrink: 0 }} mr="s-16">
                {index + 1}
              </Bullet>
              <Text fontSize="primary">{section}</Text>
            </Flex>
          </Item>
        ))}
      </Group>
    </Box>
  )
}

export default HelpSections
