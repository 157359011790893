import {
  EligibleGroupInterface,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { Virtuoso } from 'react-virtuoso'
import React, { RefObject } from 'react'

import { useNavigation } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useNavigation'
import {
  EligibilityGroupItem,
  EligibleGroupItemContext,
  renderEligibilityGroupItem,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/Timeline/StageWidget/Popups/components/EligibilityList/ListItems'
import { useScrollParent } from '@src/pages/Forms/ReviewCycle/ReviewCycle/hooks/useScrollParent'
import { Group } from '@revolut/ui-kit'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

interface Props {
  cycle: ReviewCyclesInterface
  eligibleGroups: EligibleGroupInterface[]
  scrollRef?: RefObject<HTMLDivElement>
}

const virtualizationThreshold = 10

export const PopupBody = ({ cycle, eligibleGroups, scrollRef }: Props) => {
  const { toEditEligibleEmployees } = useNavigation()
  const scrollParent = useScrollParent<HTMLDivElement>(scrollRef)

  const onItemActionClick = () => toEditEligibleEmployees(cycle)()

  if (eligibleGroups.length < virtualizationThreshold) {
    return (
      <Group style={transparentThemeBackgroundOverrides}>
        {eligibleGroups.map(group => (
          <EligibilityGroupItem
            key={group.id}
            {...group}
            onItemActionClick={onItemActionClick}
          />
        ))}
      </Group>
    )
  }

  return (
    <Virtuoso<EligibleGroupInterface, EligibleGroupItemContext>
      overscan={10}
      height={500}
      data={eligibleGroups}
      itemContent={(index, props) =>
        renderEligibilityGroupItem(index, props, {
          onItemActionClick,
        })
      }
      customScrollParent={scrollParent}
    />
  )
}
