import { ReactComponent as Plus } from '@src/assets/icons/Plus.svg'
import { ReactComponent as Alarm } from '@src/assets/icons/Alarm.svg'
import { ReactComponent as Archive } from '@src/assets/icons/Archive.svg'
import { ReactComponent as ArrowBack } from '@src/assets/icons/ArrowBack.svg'
import { ReactComponent as Drag } from '@src/assets/icons/Drag.svg'
import { ReactComponent as ZoomIn } from '@src/assets/icons/ZoomIn.svg'
import { ReactComponent as ZoomOut } from '@src/assets/icons/ZoomOut.svg'
import { ReactComponent as ArrowBackLong } from '@src/assets/icons/ArrowBackLong.svg'
import { ReactComponent as ArrowDown } from '@src/assets/icons/ArrowDown.svg'
import { ReactComponent as Repeat } from '@src/assets/icons/Repeat.svg'
import { ReactComponent as ArrowDownLong } from '@src/assets/icons/ArrowDownLong.svg'
import { ReactComponent as ArrowForward } from '@src/assets/icons/ArrowForward.svg'
import { ReactComponent as ArrowForwardLong } from '@src/assets/icons/ArrowForwardLong.svg'
import { ReactComponent as ArrowUp } from '@src/assets/icons/ArrowUp.svg'
import { ReactComponent as ArrowUpLong } from '@src/assets/icons/ArrowUpLong.svg'
import { ReactComponent as BugReport } from '@src/assets/icons/BugReport.svg'
import { ReactComponent as Calendar } from '@src/assets/icons/Calendar.svg'
import { ReactComponent as Check } from '@src/assets/icons/Check.svg'
import { ReactComponent as Rocket } from '@src/assets/icons/Rocket.svg'
import { ReactComponent as CheckCircle } from '@src/assets/icons/CheckCircle.svg'
import { ReactComponent as Circle } from '@src/assets/icons/Circle.svg'
import { ReactComponent as CircleEmpty } from '@src/assets/icons/CircleEmpty.svg'
import { ReactComponent as Clipboard } from '@src/assets/icons/Clipboard.svg'
import { ReactComponent as Close } from '@src/assets/icons/Close.svg'
import { ReactComponent as Dot } from '@src/assets/icons/Dot.svg'
import { ReactComponent as PhoneRinging } from '@src/assets/icons/PhoneRinging.svg'
import { ReactComponent as Phone } from '@src/assets/icons/Phone.svg'
import { ReactComponent as PlayBack } from '@src/assets/icons/PlayBack.svg'
import { ReactComponent as PlayForward } from '@src/assets/icons/PlayForward.svg'
import { ReactComponent as Feedback } from '@src/assets/icons/Feedback.svg'
import { ReactComponent as Goal } from '@src/assets/icons/Goal.svg'
import { ReactComponent as CloseCircle } from '@src/assets/icons/CloseCircle.svg'
import { ReactComponent as Maximize } from '@src/assets/icons/Maximize.svg'
import { ReactComponent as Minus } from '@src/assets/icons/Minus.svg'
import { ReactComponent as Delete } from '@src/assets/icons/Delete.svg'
import { ReactComponent as Copy } from '@src/assets/icons/Copy.svg'
import { ReactComponent as Edit } from '@src/assets/icons/Edit.svg'
import { ReactComponent as WarningFilled } from '@src/assets/icons/WarningFilled.svg'
import { ReactComponent as WarningOutlined } from '@src/assets/icons/WarningOutlined.svg'
import { ReactComponent as ID } from '@src/assets/icons/ID.svg'
import { ReactComponent as ExitToApp } from '@src/assets/icons/ExitToApp.svg'
import { ReactComponent as FilterList } from '@src/assets/icons/FilterList.svg'
import { ReactComponent as Profile } from '@src/assets/icons/Profile.svg'
import { ReactComponent as Google } from '@src/assets/icons/Google.svg'
import { ReactComponent as GoogleCalendar } from '@src/assets/icons/GoogleCalendar.svg'
import { ReactComponent as GoogleWhite } from '@src/assets/icons/GoogleWhite.svg'
import { ReactComponent as Graph } from '@src/assets/icons/Graph.svg'
import { ReactComponent as HelpOutline } from '@src/assets/icons/HelpOutline.svg'
import { ReactComponent as Link } from '@src/assets/icons/Link.svg'
import { ReactComponent as LinkPlane } from '@src/assets/icons/LinkPlane.svg'
import { ReactComponent as Info } from '@src/assets/icons/Info.svg'
import { ReactComponent as Jira } from '@src/assets/icons/Jira.svg'
import { ReactComponent as Menu } from '@src/assets/icons/Menu.svg'
import { ReactComponent as LinkExternal } from '@src/assets/icons/LinkExternal.svg'
import { ReactComponent as LinkExternalArrow } from '@src/assets/icons/LinkExternalArrow.svg'
import { ReactComponent as Save } from '@src/assets/icons/Save.svg'
import { ReactComponent as Search } from '@src/assets/icons/Search.svg'
import { ReactComponent as Spinner } from '@src/assets/icons/Spinner.svg'
import { ReactComponent as Star } from '@src/assets/icons/Star.svg'
import { ReactComponent as StarOutlined } from '@src/assets/icons/StarOutlined.svg'
import { ReactComponent as ThumbsDown } from '@src/assets/icons/ThumbsDown.svg'
import { ReactComponent as ThumbsDown2x } from '@src/assets/icons/ThumbsDown2x.svg'
import { ReactComponent as ThumbsUp } from '@src/assets/icons/ThumbsUp.svg'
import { ReactComponent as ThumbsUp2x } from '@src/assets/icons/ThumbsUp2x.svg'
import { ReactComponent as ThumbsDownCircle } from '@src/assets/icons/ThumbsDownCircle.svg'
import { ReactComponent as ThumbsUpCircle } from '@src/assets/icons/ThumbsUpCircle.svg'
import { ReactComponent as ThumbsUp2xCircle } from '@src/assets/icons/ThumbsUp2xCircle.svg'
import { ReactComponent as Time } from '@src/assets/icons/Time.svg'
import { ReactComponent as TrendDown } from '@src/assets/icons/TrendDown.svg'
import { ReactComponent as TrendStable } from '@src/assets/icons/TrendStable.svg'
import { ReactComponent as TrendUp } from '@src/assets/icons/TrendUp.svg'
import { ReactComponent as WarningTriangle } from '@src/assets/icons/WarningTriangle.svg'
import { ReactComponent as Mail } from '@src/assets/icons/Mail.svg'
import { ReactComponent as Hibob } from '@src/assets/icons/Hibob.svg'
import { ReactComponent as Unarchive } from '@src/assets/icons/Unarchive.svg'
import { ReactComponent as Excel } from '@src/assets/icons/Excel.svg'
import { ReactComponent as Eye } from '@src/assets/icons/Eye.svg'
import { ReactComponent as EyeCrossed } from '@src/assets/icons/EyeCrossed.svg'
import { ReactComponent as Lock } from '@src/assets/icons/Lock.svg'
import { ReactComponent as LockOpen } from '@src/assets/icons/LockOpen.svg'
import { ReactComponent as LockOutlined } from '@src/assets/icons/LockOutlined.svg'
import { ReactComponent as JiraBug } from '@src/assets/icons/JiraBug.svg'
import { ReactComponent as JiraEpic } from '@src/assets/icons/JiraEpic.svg'
import { ReactComponent as JiraOther } from '@src/assets/icons/JiraOther.svg'
import { ReactComponent as JiraStory } from '@src/assets/icons/JiraStory.svg'
import { ReactComponent as JiraSubtask } from '@src/assets/icons/JiraSubtask.svg'
import { ReactComponent as JiraTask } from '@src/assets/icons/JiraTask.svg'
import { ReactComponent as Document } from '@src/assets/icons/Document.svg'
import { ReactComponent as LinkedIn } from '@src/assets/icons/LinkedIn.svg'
import { ReactComponent as Notepad } from '@src/assets/icons/Notepad.svg'
import { ReactComponent as HelpFilled } from '@src/assets/icons/HelpFilled.svg'
import { ReactComponent as Bold } from '@src/assets/icons/Bold.svg'
import { ReactComponent as Italic } from '@src/assets/icons/Italic.svg'
import { ReactComponent as Underline } from '@src/assets/icons/Underline.svg'
import { ReactComponent as Ol } from '@src/assets/icons/Ol.svg'
import { ReactComponent as Ul } from '@src/assets/icons/Ul.svg'
import { ReactComponent as Heading } from '@src/assets/icons/Heading.svg'
import { ReactComponent as Slack } from '@src/assets/icons/Slack.svg'
import { ReactComponent as SlackMono } from '@src/assets/icons/SlackMono.svg'
import { ReactComponent as PrideBame } from '@src/assets/icons/PrideBame.svg'
import { ReactComponent as PrideParents } from '@src/assets/icons/PrideParents.svg'
import { ReactComponent as PrideWellbeing } from '@src/assets/icons/PrideWellbeing.svg'
import { ReactComponent as PrideWomen } from '@src/assets/icons/PrideWomen.svg'
import { ReactComponent as CF } from '@src/assets/icons/CF.svg'
import { ReactComponent as Skip } from '@src/assets/icons/Skip.svg'
import { ReactComponent as TableLarge } from '@src/assets/icons/TableLarge.svg'
import { ReactComponent as TableMedium } from '@src/assets/icons/TableMedium.svg'
import { ReactComponent as TableSmall } from '@src/assets/icons/TableSmall.svg'
import { ReactComponent as HireRight } from '@src/assets/icons/HireRight.svg'
import { ReactComponent as TextLeft } from '@src/assets/icons/TextLeft.svg'
import { ReactComponent as TextCenter } from '@src/assets/icons/TextCenter.svg'
import { ReactComponent as TextRight } from '@src/assets/icons/TextRight.svg'
import { ReactComponent as Justify } from '@src/assets/icons/Justify.svg'
import { ReactComponent as Outdent } from '@src/assets/icons/Outdent.svg'
import { ReactComponent as Indent } from '@src/assets/icons/Indent.svg'

const icons = {
  Plus,
  Alarm,
  Archive,
  ArrowBack,
  ArrowBackLong,
  ArrowForward,
  ArrowForwardLong,
  ArrowUp,
  ArrowUpLong,
  ArrowDown,
  ArrowDownLong,
  Document,
  Dot,
  Hibob,
  Phone,
  PhoneRinging,
  PlayBack,
  PlayForward,
  BugReport,
  ID,
  Calendar,
  Profile,
  Link,
  Check,
  CheckCircle,
  Circle,
  CircleEmpty,
  Clipboard,
  Mail,
  Minus,
  Notepad,
  Close,
  CloseCircle,
  ZoomOut,
  EyeCrossed,
  Delete,
  Copy,
  Edit,
  Excel,
  ExitToApp,
  Eye,
  FilterList,
  Google,
  GoogleCalendar,
  Graph,
  HelpOutline,
  HelpFilled,
  Drag,
  Info,
  Jira,
  GoogleWhite,
  ZoomIn,
  LinkPlane,
  Rocket,
  WarningFilled,
  WarningOutlined,
  WarningTriangle,
  Goal,
  Menu,
  LinkExternal,
  LinkExternalArrow,
  Lock,
  LockOpen,
  LockOutlined,
  Feedback,
  Maximize,
  Save,
  Search,
  Spinner,
  Star,
  StarOutlined,
  ThumbsDown,
  ThumbsDown2x,
  ThumbsUp,
  ThumbsUp2x,
  ThumbsDownCircle,
  ThumbsUpCircle,
  ThumbsUp2xCircle,
  Time,
  TrendDown,
  TrendStable,
  TrendUp,
  Repeat,
  Unarchive,
  JiraBug,
  JiraEpic,
  JiraOther,
  JiraStory,
  JiraTask,
  JiraSubtask,
  LinkedIn,
  Bold,
  Italic,
  Underline,
  Ol,
  Ul,
  Heading,
  Slack,
  SlackMono,
  PrideBame,
  PrideParents,
  PrideWellbeing,
  PrideWomen,
  CF,
  Skip,
  TableLarge,
  TableMedium,
  TableSmall,
  HireRight,
  TextLeft,
  TextCenter,
  TextRight,
  Justify,
  Indent,
  Outdent,
} as const

export default icons
