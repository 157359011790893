import { InputGroup } from '@revolut/ui-kit'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { OptionInterface } from '@src/interfaces/selectors'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  CardTypes,
  PerformanceReviewTypes,
  ReviewCategory,
  SectionType,
  SkillCardInterface,
} from '@src/interfaces/performance'
import { InterviewScorecardTemplateSectionInterface } from '@src/interfaces/interviewScorecardTemplates'
import NewMultiSelect from '@components/Inputs/NewMultiSelect/NewMultiSelect'
import MultiInput from '@components/Inputs/MultiInput/MultiInput'
import { FormError } from '@src/features/Form/LapeForm'

const StyledMultiInput = styled(MultiInput)`
  padding: 0;
`

type Props = {
  id: number
  possibleSkills?: OptionInterface[]
  skillsDisabled?: boolean
  previewMode?: boolean
  section: InterviewScorecardTemplateSectionInterface
  onChange?: (scorecard: InterviewScorecardTemplateSectionInterface) => void
  errors?: FormError<InterviewScorecardTemplateSectionInterface>
  sectionsFieldName?: string
}

const getCard = (
  section: InterviewScorecardTemplateSectionInterface,
): SkillCardInterface => ({
  type: CardTypes.skillCard,
  sections: [
    {
      level_key: 'Basic',
      type: SectionType.CriteriasAssessment,
      items: section.basic_level_questions?.map(item => ({ text: item })),
    },
    {
      level_key: 'Intermediate',
      type: SectionType.CriteriasAssessment,
      items: section.intermediate_level_questions?.map(item => ({
        text: item,
      })),
    },
    {
      level_key: 'Advanced',
      type: SectionType.CriteriasAssessment,
      items: section.advanced_level_questions?.map(item => ({
        text: item,
      })),
    },
    {
      level_key: 'Expert',
      type: SectionType.CriteriasAssessment,
      items: section.expert_level_questions?.map(item => ({ text: item })),
    },
  ],
  name: section.title || '',
  sub_title: section.subtitle,
  rating_expectation: null,
})

export const LevelBasedInputs = ({
  id,
  possibleSkills = [],
  skillsDisabled,
  previewMode,
  section,
  onChange,
  sectionsFieldName,
  errors,
}: Props) => {
  const card = useMemo(() => getCard(section), [section])

  if (previewMode) {
    return (
      <ScorecardSection
        mx="-s-16"
        mt="-s-32"
        mb="-s-16"
        skillId="0"
        cardIndex={id}
        onChange={() => {}}
        onSetResultLoading={() => {}}
        card={card}
        type={PerformanceReviewTypes.interviewScorecard}
        category={ReviewCategory.Performance}
        hideResult
        hideJustification
        hideCounts
        viewMode
        noWrapWidget
      />
    )
  }

  return (
    <InputGroup>
      <NewMultiSelect
        data-name={`${sectionsFieldName}[${id}].skills`}
        options={possibleSkills.map(item => ({
          label: item.name,
          value: item,
        }))}
        placeholder="Skills assessed"
        hasError={!!errors?.skills}
        message={errors?.skills}
        variant="grey"
        disabled={skillsDisabled}
        value={section.skills?.map(item => ({
          label: item.name,
          value: item,
        }))}
        onChange={options => {
          onChange?.({
            ...section,
            skills: options?.map(item => item.value) || [],
          })
        }}
        required
      />
      <StyledMultiInput
        title="Basic"
        valueFieldName={undefined}
        value={section.basic_level_questions}
        onChange={val => {
          onChange?.({
            ...section,
            basic_level_questions: val ? ([...val] as string[]) : [],
          })
        }}
        data-name={`${sectionsFieldName}[${id}].basic_level_questions`}
        hasError={!!errors?.basic_level_questions}
        message={errors?.basic_level_questions}
      />
      <StyledMultiInput
        title="Intermediate"
        valueFieldName={undefined}
        value={section.intermediate_level_questions}
        onChange={val => {
          onChange?.({
            ...section,
            intermediate_level_questions: val ? ([...val] as string[]) : [],
          })
        }}
        data-name={`${sectionsFieldName}[${id}].intermediate_level_questions`}
        hasError={!!errors?.intermediate_level_questions}
        message={errors?.intermediate_level_questions}
      />
      <StyledMultiInput
        title="Advanced"
        valueFieldName={undefined}
        value={section.advanced_level_questions}
        onChange={val => {
          onChange?.({
            ...section,
            advanced_level_questions: val ? ([...val] as string[]) : [],
          })
        }}
        data-name={`${sectionsFieldName}[${id}].advanced_level_questions`}
        hasError={!!errors?.advanced_level_questions}
        message={errors?.advanced_level_questions}
      />
      <StyledMultiInput
        title="Expert"
        valueFieldName={undefined}
        value={section.expert_level_questions}
        onChange={val => {
          onChange?.({
            ...section,
            expert_level_questions: val ? ([...val] as string[]) : [],
          })
        }}
        data-name={`${sectionsFieldName}[${id}].expert_level_questions`}
        hasError={!!errors?.expert_level_questions}
        message={errors?.expert_level_questions}
      />
    </InputGroup>
  )
}
