import { Action, Flex, Skeleton, Token } from '@revolut/ui-kit'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import React from 'react'
import { useGetHealth } from '@src/api/health'

interface AppVersionProps {
  onClick?: () => void
}

const AppVersion = ({ onClick }: AppVersionProps) => {
  const { data, isLoading } = useGetHealth()
  return (
    <>
      {isLoading ? (
        <Skeleton width={200} />
      ) : (
        <Flex
          alignItems="center"
          justifyContent="center"
          style={{ fontSize: Token.fontSize.small }}
        >
          <Action
            use={InternalLink}
            to={pathToUrl(ROUTES.PLATFORM_STATUS)}
            style={{ fontSize: Token.fontSize.small }}
            onClick={onClick}
          >
            App version {data?.version}
          </Action>
        </Flex>
      )}
    </>
  )
}

export default AppVersion
