import React from 'react'
import { Box, Flex, InputGroup, Widget } from '@revolut/ui-kit'
import { DropdownButton } from '@components/DropdownButton/DropdownButton'
import {
  ApplicationFormEnumQuestionIds,
  ApplicationFormEnumQuestionOptions,
  ApplicationFormQuestionType,
} from '@src/interfaces/applicationForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewScorecardSectionQuestionType } from '@src/interfaces/interviewScorecardTemplates'
import produce from 'immer'
import { ApplicationFormSettingsInterface } from '@src/interfaces/settings'
import ApplicationFormQuestionContent from '@src/pages/Forms/JobPosting/ApplicationForm/ApplicationFormQuestionContent'
import { OptionInterface } from '@src/interfaces/selectors'
import ScorecardSectionControls from '@src/pages/Forms/InterviewScorecardTemplate/InterviewScorecardTemplateForm/ScorecardSectionControls'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { connect } from 'lape'
import { HStack } from '@revolut/ui-kit'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { selectorKeys } from '@src/constants/api'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { selectPermissions } from '@src/store/auth/selectors'

type Props = {
  sectionIdx: number
  questionIdx: number
}

const ApplicationFormEditQuestion = ({ sectionIdx, questionIdx }: Props) => {
  const { values } = useLapeContext<ApplicationFormSettingsInterface>()
  const question = values.global_sections![sectionIdx]?.questions[questionIdx]
  const type = question.question_type.id
  const permissions = useSelector(selectPermissions)
  const disableAdd = !permissions.includes(PermissionTypes.AddApplicationFormPreferences)
  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeApplicationFormPreferences,
  )
  const disableDelete = !permissions.includes(
    PermissionTypes.DeleteApplicationFormPreferences,
  )

  const onChangeQuestions = (
    options: InterviewScorecardSectionQuestionType<number>[],
  ) => {
    // to be able to track changes
    values.global_sections = produce(values.global_sections!, draft => {
      draft[sectionIdx].questions[questionIdx].options = options
    })
  }

  const onChangeType = (option: OptionInterface) => {
    if (
      option.id === ApplicationFormEnumQuestionIds.Checkbox ||
      option.id === ApplicationFormEnumQuestionIds.Option
    ) {
      question.options = []
    } else {
      question.options = undefined
    }
    question.placeholder_text =
      option.id === ApplicationFormEnumQuestionIds.Dropdown ? 'Select one' : undefined
    question.question_type = option as ApplicationFormQuestionType
  }

  const handleDelete = () => {
    values.global_sections[sectionIdx].questions = values.global_sections![
      sectionIdx
    ].questions.filter((_, i) => i !== questionIdx)
  }

  const handleCopy = () => {
    values.global_sections[sectionIdx].questions.splice(questionIdx + 1, 0, {
      ...question,
      options: question.options?.map(option => ({
        ...option,
        id: undefined,
      })),
      id: undefined,
    })
  }

  return (
    <Widget p="s-16" mt="s-16" data-testid={`question_${sectionIdx}_${questionIdx}`}>
      <Flex justifyContent="space-between" alignItems="center" mb="s-16">
        <Flex>
          <HStack space="s-8">
            <DropdownButton
              options={ApplicationFormEnumQuestionOptions}
              onSelect={onChangeType}
              data-testid="type-question-btn"
              disabled={disableEdit}
            >
              Type: {type || 'none'}
            </DropdownButton>
            <SwitchButton
              checked={question.optional}
              onClick={() => {
                question.optional = !question.optional
              }}
              useMoreBar={false}
              disabled={disableEdit}
            >
              Optional
            </SwitchButton>
          </HStack>
        </Flex>

        <ScorecardSectionControls
          id={questionIdx}
          onDelete={handleDelete}
          onCopy={handleCopy}
          sections={values.global_sections[sectionIdx].questions}
          disableDelete={disableDelete}
          disableEdit={disableEdit}
          disableCopy={disableAdd}
        />
      </Flex>

      <Box mb="s-16">
        <InputGroup>
          <LapeNewInput
            label="Question"
            name={`global_sections.${sectionIdx}.questions.${questionIdx}.title`}
            required
            disabled={disableEdit}
          />
          <LapeNewInput
            label="Subtitle"
            name={`global_sections.${sectionIdx}.questions.${questionIdx}.subtitle`}
            disabled={disableEdit}
          />
          <LapeNewMultiSelect
            placeholder="Location setting"
            name={`global_sections.${sectionIdx}.questions.${questionIdx}.locations`}
            selector={selectorKeys.location}
            disabled={disableEdit}
          />
        </InputGroup>
      </Box>

      <ApplicationFormQuestionContent
        options={question?.options}
        placeholder={question?.placeholder_text}
        type={type}
        onChangePlaceholder={val => {
          values.global_sections[sectionIdx].questions[questionIdx].placeholder_text = val
        }}
        onChangeQuestions={onChangeQuestions}
        optionsKey={`global_sections.${sectionIdx}.questions.${questionIdx}.options`}
        disableEdit={disableEdit}
        disableDelete={disableDelete}
      />
    </Widget>
  )
}

export default connect(ApplicationFormEditQuestion)
