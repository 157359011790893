import React from 'react'
import ScorecardSection from '@components/ScorecardGeneral/ScorecardSection'
import {
  ChangeScorecardInterface,
  PerformanceReviewTypes,
  ReviewScorecardInterface,
  ScorecardError,
} from '@src/interfaces/performance'
import { assessButtonsValidation, getNormalizedCards } from '@src/utils/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ScorecardGeneralProps } from '@components/ScorecardGeneral/ScorecardGeneral'
import * as yup from 'yup'

interface Props
  extends Pick<ScorecardGeneralProps, 'disabled' | 'viewMode' | 'touched' | 'category'> {}

export const validator = {
  manager_skills: yup
    .object()
    .when('functional_skills.skipped_section_justification', {
      is: undefined,
      then: yup
        .object()
        .shape({
          cards: yup.array().of(
            yup.object().shape({
              sections: yup.array().of(
                yup
                  .object()
                  .shape<any>({
                    items: yup.array().of(
                      yup.object().shape({
                        value: yup.string().nullable(),
                      }),
                    ),
                  })
                  .test(
                    'assess-buttons',
                    'Please complete Skills before submitting.',
                    assessButtonsValidation as any,
                  ),
              ),
            }),
          ),
        })
        .nullable(),
      otherwise: yup.object().nullable(),
    })
    .nullable(),
}

const ManagerSkills = ({ disabled, viewMode, touched, category }: Props) => {
  const form = useLapeContext<ReviewScorecardInterface>()
  const { values, submitFailed, errors, change } = form

  const cards = getNormalizedCards(values.review_data?.manager_skills?.cards || [])

  const allErrors = submitFailed
    ? (errors?.review_data?.manager_skills?.cards as ScorecardError[])
    : undefined

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.manager_skills.cards.${cardIndex}`
    change(`${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)
  }

  return (
    <>
      {cards.map((card, cardIndex) => (
        <ScorecardSection
          skillId={`${card.skill_id || card.criteria_key}`}
          cardIndex={cardIndex}
          key={`${card.skill_id || card.criteria_key}`}
          disabled={disabled}
          type={PerformanceReviewTypes.managerSkills}
          category={category}
          onChange={onChange}
          viewMode={viewMode}
          errors={allErrors?.[cardIndex]}
          sectionErrors={allErrors}
          touched={touched}
          // previousReviews: there are no them in the BE
          previousReviews={undefined}
          card={card}
          onSetResultLoading={() => {}}
          hideResult
          titleTooltip="This skill is added due to the employee being a people manager"
        />
      ))}
    </>
  )
}

export default ManagerSkills
