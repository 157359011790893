import React, { useState } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Box, Token } from '@revolut/ui-kit'
import { cultureValuesRequestsNew, useUpdateValue } from '@src/api/cultureValues'
import { ROUTES } from '@src/constants/routes'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Form from '@src/features/Form/Form'
import General from '@src/pages/Forms/CultureValueForm/General'
import Preview from '@src/pages/Forms/CultureValueForm/Preview'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { pathToUrl } from '@src/utils/router'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { SKILLS_DEFAULT_ICON } from '@src/constants/common'
import SettingsButtons from '@src/features/SettingsButtons'
import SwitchButton from '@components/SwitchButton/SwitchButton'
import { handleError } from '@src/api'
import { API } from '@src/constants/api'

const backUrl = ROUTES.FUNCTION.SKILLS

const getTabs = (id: string) => [
  {
    title: 'Preview',
    path: ROUTES.FORMS.VALUE.PREVIEW,
    to: pathToUrl(ROUTES.FORMS.VALUE.PREVIEW, { id }),
    component: Preview,
    canView: !!id,
  },
  {
    title: 'Settings',
    path: ROUTES.FORMS.VALUE.GENERAL,
    to: pathToUrl(ROUTES.FORMS.VALUE.GENERAL, { id }),
    component: General,
    canView: true,
  },
]

const ValueForm = connect(() => {
  const { id } = useParams<{ id: string }>()
  const { values, reset } = useLapeContext<CultureValueInterface>()
  const [isDisabled, setIsDisabled] = useState(false)
  const [isChecked, setIsChecked] = useState(values.is_enabled)

  const tabs = getTabs(id).filter(tab => tab.canView)

  const onSwitchButton = async () => {
    setIsDisabled(true)

    try {
      const response = await cultureValuesRequestsNew.update(
        {},
        { id: String(values.id) },
        {
          ...values,
          is_enabled: !isChecked,
        },
      )

      reset(response.data)
      setIsChecked(!isChecked)
    } catch (e) {
      handleError(e)
    } finally {
      setIsDisabled(false)
    }
  }

  return (
    <PageWrapper>
      <PageHeader
        backUrl={backUrl}
        title={
          <PageHeader.Title
            maxWidth={{ all: '100%', lg: Token.breakpoint.xl }}
            title={values.id ? values.name : 'Create a new value'}
            avatar={
              <EntityAvatar
                data={values}
                defaultIcon={SKILLS_DEFAULT_ICON}
                api={useUpdateValue}
                apiUrl={`${API.VALUES}${API.COMPANY}`}
                canEdit
                onSuccess={data => {
                  values.icon = data?.icon || null
                  values.avatar = data?.avatar || null
                }}
              />
            }
            labels={
              <PageHeader.LabelsBar>
                {values.id && (
                  <PageHeader.Label
                    useTag
                    color={
                      values.is_enabled ? Token.color.success : Token.color.greyTone50
                    }
                  >
                    {values.is_enabled ? 'Enabled' : 'Disabled'}
                  </PageHeader.Label>
                )}
                <PageHeader.Label useTag icon="RepairTool">
                  Value
                </PageHeader.Label>
              </PageHeader.LabelsBar>
            }
            actions={
              values.id ? (
                <SettingsButtons>
                  <SwitchButton
                    checked={isChecked}
                    disabled={isDisabled}
                    onClick={onSwitchButton}
                  >
                    {`${isChecked ? `Disable` : `Enable`} this value`}
                  </SwitchButton>
                </SettingsButtons>
              ) : undefined
            }
          />
        }
      >
        <Box>
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact key={tab.path} path={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
})

const PageCultureValue = () => {
  return (
    <Form api={cultureValuesRequestsNew}>
      <ValueForm />
    </Form>
  )
}

export default connect(PageCultureValue)
