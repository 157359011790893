import React from 'react'
import { ActionWidget, Box, Flex } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  PerformanceReviewTypes,
  ReviewScorecardInterface,
} from '@src/interfaces/performance'
import { Statuses } from '@src/interfaces'

type Props = {
  type: PerformanceReviewTypes
}

const PrefillNotification = ({ type }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()

  if (
    values.status === Statuses.completed ||
    !values.review_data?.prefilled ||
    // prefilling disabled on the BE for deliverables, PERF-2294
    type === PerformanceReviewTypes.deliverables
  ) {
    return null
  }

  return (
    <Box mt="s-24">
      <ActionWidget>
        <ActionWidget.Title>
          This scorecard has been prefilled with the content from your last review
        </ActionWidget.Title>
        <ActionWidget.Content>
          <Flex alignItems="center">
            Questions which are new or were unable to be prefilled have been marked with
            <Box display="inline-block" ml="s-2">
              <ExclamationMarkOutline color="red" size={14} />
            </Box>
          </Flex>
        </ActionWidget.Content>
      </ActionWidget>
    </Box>
  )
}

export default PrefillNotification
