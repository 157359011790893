import React from 'react'
import Form from '@src/features/Form/Form'
import { assignUserPermissionRequest } from '@src/api/accessManagement'
import AssignUserToPermission from '@src/pages/Forms/AssignUserToPermission/AssignUserToPermission'

const Index = () => {
  return (
    <Form api={assignUserPermissionRequest}>
      <AssignUserToPermission />
    </Form>
  )
}

export default Index
