import React, { MouseEvent, useEffect } from 'react'
import { DraggableSyntheticListeners } from '@dnd-kit/core'
import { Transform } from '@dnd-kit/utilities'
import styled, { CSSObject } from 'styled-components'

const RowWrapper = styled.div``

export interface Props {
  dragOverlay?: boolean
  transform?: Transform | null
  listeners?: DraggableSyntheticListeners
  style?: React.CSSProperties
  transition?: string | null
  wrapperStyle?: React.CSSProperties
  onClick?: (e: MouseEvent<HTMLDivElement>) => void
  children: React.ReactNode
}

const SortableRowContent = React.memo(
  React.forwardRef<HTMLDivElement, Props>(
    (
      {
        dragOverlay,
        listeners,
        style,
        transition,
        transform,
        children,
        onClick,
        ...props
      },
      ref,
    ) => {
      useEffect(() => {
        if (dragOverlay) {
          document.body.style.cursor = 'grabbing'
        }

        return () => {
          document.body.style.cursor = ''
        }
      }, [dragOverlay])

      const customStyle: CSSObject = {
        // hack to avoid glitching effect for small screen sizes, this doesn't affect functionality
        // https://github.com/clauderic/dnd-kit/issues/23
        width: '0px',
      }

      // for performance reasons I don't put it as a Styled component prop
      if (transform) {
        customStyle.transform = `translateX(${Math.round(
          transform.x,
        )}px) translateY(${Math.round(transform.y)}px)`
      }

      if (transition) {
        customStyle.transition = transition
      }

      return (
        <RowWrapper
          ref={ref}
          style={customStyle}
          onClick={onClick}
          {...listeners}
          {...props}
        >
          {children}
        </RowWrapper>
      )
    },
  ),
)

export default SortableRowContent
