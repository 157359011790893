import React, { useState } from 'react'
import format from 'date-fns/format'

import { EmployeeShiftColumn } from '@src/constants/columns/attendance'
import { useTable } from '@src/components/Table/hooks'
import { getTeamScheduleApprovalInfo } from '@src/api/attendance'
import {
  dateFilterFormat,
  getInitialMonthFilter,
} from '@src/features/Attendance/MonthToggle'
import { MonthSelect } from '@src/components/MonthSelect/MonthSelect'
import SwitchButton from '@src/components/SwitchButton/SwitchButton'
import { TeamInterface } from '@src/interfaces/teams'
import { useTableRow } from '@src/features/Attendance/useTableRow'
import { ROW, ScheduleTable } from '@src/features/Attendance/ScheduleTable'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { Flex } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'

interface TeamScheduleProps {
  data: TeamInterface
  navigation?: React.ReactElement
}

const TeamSchedule = ({ data, navigation }: TeamScheduleProps) => {
  const currentDate = getInitialMonthFilter()

  const isNewTable = useIsNewTable()
  const [expandToday, setExpandToday] = useState(false)

  const table = useTable<EmployeeShiftColumn>(
    { getItems: getTeamScheduleApprovalInfo(data.id) },
    [
      {
        columnName: 'date',
        filters: [{ id: currentDate, name: currentDate }],
        nonResettable: true,
      },
      {
        columnName: 'approval',
        filters: [
          { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
          { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
          { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
        ],
      },
    ],
  )

  const selectedDate =
    table.filterBy.find(col => col.columnName === 'date')?.filters[0].id || currentDate

  const tableRow = useTableRow(ROW, expandToday)

  return (
    <Table.Widget>
      <Table.Widget.Info>
        {!isNewTable && navigation}
        {isNewTable && (
          <Flex mb={!isNewTable ? 's-24' : 0} gap="s-32">
            <Stat label="Total" val={table.count} />
          </Flex>
        )}
      </Table.Widget.Info>
      <Table.Widget.Filters>
        {isNewTable && navigation}
        <MonthSelect
          value={selectedDate}
          onChange={date => {
            if (date) {
              const newMonth = format(date, dateFilterFormat)
              table.onFilterChange(
                {
                  columnName: 'date',
                  filters: [{ id: newMonth, name: newMonth }],
                  nonResettable: true,
                },
                false,
              )
            }
          }}
        />
      </Table.Widget.Filters>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          <SwitchButton
            checked={expandToday}
            onClick={() => setExpandToday(!expandToday)}
          >
            Expand today
          </SwitchButton>
          <Table.ColumnsSettingsButton />
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <ScheduleTable table={table} row={tableRow} teamId={data.id} />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default TeamSchedule
