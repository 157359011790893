import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'lape'
import { Text } from '@revolut/ui-kit'
import {
  EpicOption,
  KpiInterface,
  KpiReviewCycle,
  KpiTargetEpics,
} from '@src/interfaces/kpis'
import CycleSelector, {
  cycleStatusText,
} from '@src/features/FormTabs/Kpi/KPITargets/common/CycleSelector'
import { useGetEmployeeRoadmaps, useGetRoadmaps } from '@src/api/roadmaps'
import { FilterByInterface } from '@src/interfaces/data'
import { RoadmapInterface, RoadmapPriorities } from '@src/interfaces/roadmaps'
import {
  ReviewCycleCategory,
  ReviewCyclesInterface,
  ReviewCycleStatus,
} from '@src/interfaces/reviewCycles'
import { NameIdInterface } from '@src/interfaces/employees'
import { InputGroup } from '@revolut/ui-kit'
import ParentSelector from '@src/features/FormTabs/Kpi/KPITargets/common/ParentSelector'
import { selectorKeys } from '@src/constants/api'
import pick from 'lodash/pick'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { KPIRoadmapTargetLocationState } from '@src/features/FormTabs/Kpi/KPITargets/common/types'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { successNotification } from '@src/actions/NotificationActions'
import { goBack } from '@src/actions/RouterActions'
import { kpiTargetsRequestsNew } from '@src/api/kpis'
import useKPISidebars from '@src/pages/Forms/KpiForm/common/useKPISidebars'
import SettingsButtons from '@src/features/SettingsButtons'
import { useGetPerformanceSelector } from '@src/api/performance'
import { KPITypes } from '@src/constants/table'
import { probationCycleCategory } from '@src/features/FormTabs/Kpi/KPITargets/common/useAvailableCycles'
import { renderDeliverablesSelectorOption } from '@src/utils/kpi'

const getTypeSpecificFilters = (kpi: KpiInterface): FilterByInterface[] => {
  if (kpi.is_company) {
    return [
      {
        filters: [
          { name: RoadmapPriorities.COMPANY, id: RoadmapPriorities.COMPANY as any },
          {
            name: RoadmapPriorities.TRANSFORMATION,
            id: RoadmapPriorities.TRANSFORMATION as any,
          },
        ],
        columnName: 'priority',
      },
    ]
  }
  if (kpi.team) {
    return [
      {
        filters: [{ name: `${kpi.team.id}`, id: kpi.team.id }],
        columnName: 'team_id',
      },
    ]
  }
  if (kpi.is_employee) {
    return [
      {
        filters: [{ name: `${kpi.owner?.id}`, id: kpi.owner?.id }],
        columnName: 'employee_id',
      },
    ]
  }
  if (kpi.department) {
    return [
      {
        filters: [{ name: `${kpi.department?.id}`, id: kpi.department?.id }],
        columnName: 'team_department_or_department',
      },
    ]
  }
  if (kpi.function) {
    return [
      {
        filters: [{ name: `${kpi.function.id}`, id: kpi.function.id }],
        columnName: 'function__id',
      },
    ]
  }
  if (kpi.role) {
    return [
      {
        filters: [{ name: `${kpi.role.id}`, id: kpi.role.id }],
        columnName: 'role__id',
      },
    ]
  }
  return []
}

export const getFilters = (kpi: KpiInterface, search: string): FilterByInterface[] => {
  return [
    ...getTypeSpecificFilters(kpi),
    ...(search
      ? [
          {
            filters: [{ name: search, id: search }],
            columnName: 'search',
          },
        ]
      : []),
    {
      filters: [
        { name: ReviewCycleStatus.ongoing, id: ReviewCycleStatus.ongoing },
        { name: ReviewCycleStatus.planned, id: ReviewCycleStatus.planned },
      ],
      columnName: 'review_cycle__status',
    },
  ]
}

const DROPDOWN_ITEM_HEIGHT = 68

// TODO: Remove this when BE is fixed
export const transformCycle = (
  reviewCycle: ReviewCyclesInterface | KpiReviewCycle,
): KpiReviewCycle => {
  return pick(reviewCycle, ['id', 'name', 'offset', 'category', 'status'])
}

export const parseRoadmapToEpic = (roadmap: RoadmapInterface): EpicOption =>
  ({
    id: roadmap.id,
    key: roadmap.key || null,
    name: roadmap.name,
    url: roadmap.epic_url,
    owner: roadmap.owner as NameIdInterface,
    due_date: roadmap.due_date,
    progress: roadmap.progress,
    done: roadmap.done,
    todo: roadmap.todo,
    unitName: roadmap.organisational_unit?.name,
    type: roadmap.type,
  } as EpicOption)

export const getInitialValuesRoadmapTarget = (
  kpi: KpiInterface,
  defaultCycle?: KpiReviewCycle,
): Partial<KpiTargetEpics> => {
  const canBeTopDown = !!kpi.team || !!kpi.department
  return {
    kpi: { id: kpi.id },
    epics: [],
    parent_kpi: null,
    is_top_down: false,
    review_cycle: defaultCycle ? transformCycle(defaultCycle) : undefined,
    owner: canBeTopDown ? kpi.owner : undefined,
  }
}

interface Props {
  locationState: KPIRoadmapTargetLocationState
}

const RoadmapTargetForm = connect(({ locationState }: Props) => {
  const { kpi, defaultCycle, canEditCycle, availableCycles, backUrl, readonly } =
    locationState
  const form = useLapeContext<KpiTargetEpics>()
  const { values, initialValues } = form
  const { sidebars, buttons } = useKPISidebars()

  const [search, setSearch] = useState<string>('')

  const fetchOptions = useMemo(
    () => ({ sortBy: [], filters: getFilters(kpi, search) }),
    [kpi, search],
  )

  const { data: roadmaps = [], isLoading } = kpi.is_employee
    ? useGetEmployeeRoadmaps(fetchOptions)
    : useGetRoadmaps(fetchOptions)
  const options = useMemo(
    () =>
      roadmaps.map(roadmap => ({
        value: parseRoadmapToEpic(roadmap),
        label: roadmap.name,
      })),
    [roadmaps],
  )

  useEffect(() => {
    if (readonly || initialValues?.review_cycle?.status === ReviewCycleStatus.closed) {
      form.disabled = true
    } else {
      form.disabled = false
    }
  }, [readonly])

  const { data: employeeAvailableCycles } = useGetPerformanceSelector(kpi.owner.id)

  const getAvailableCycles = () => {
    if (kpi.type === KPITypes.employee_kpi) {
      return employeeAvailableCycles || availableCycles
    }
    return availableCycles
  }

  return (
    <>
      {sidebars}
      <PageWrapper>
        <PageHeader
          backUrlLocationState={locationState.kpiLocationState}
          title="Set deliverables"
          subtitle={
            values.review_cycle?.status && (
              <Text fontSize="primary" color="foreground">
                {cycleStatusText[values.review_cycle.status]}
              </Text>
            )
          }
          backUrl={backUrl || pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpi.id })}
          variant="narrow"
        />
        <PageBody>
          <SettingsButtons mb="s-16">{buttons}</SettingsButtons>
          <InputGroup>
            <CycleSelector
              disabled={!canEditCycle || form.disabled}
              value={values.employee_cycle || values.review_cycle || defaultCycle}
              reviewCycles={getAvailableCycles()}
              onSelect={cycle => {
                if (
                  cycle.category === probationCycleCategory ||
                  cycle.category === ReviewCycleCategory.PIP
                ) {
                  values.employee_cycle = {
                    id: String(cycle.id),
                    name: cycle.name,
                  } as KpiReviewCycle
                  values.is_probation = undefined
                } else {
                  values.review_cycle = transformCycle(cycle)
                  values.employee_cycle = undefined
                  values.is_probation = false
                }
              }}
            />
            <ParentSelector
              kpi={kpi}
              cycleOffset={values.employee_cycle ? '0,-1' : values.review_cycle?.offset}
            />
            <LapeNewMultiSelect<EpicOption>
              name="epics"
              placeholder="Deliverables"
              options={options}
              disabled={isLoading}
              onSearch={str => setSearch(str)}
              required
              dropdownItemHeight={DROPDOWN_ITEM_HEIGHT}
            >
              {option => renderDeliverablesSelectorOption(option, kpi.is_employee)}
            </LapeNewMultiSelect>
            {(!!kpi.team || !!kpi.department) && (
              <LapeSingleCheckbox
                name="is_top_down"
                label="Mark as top down"
                description="
                Assign point of contact at target level"
              />
            )}
            {values.is_top_down && (
              <LapeRadioSelectInput<KpiInterface['owner']>
                name="owner"
                label="Owner"
                selector={selectorKeys.employee}
                clearable={false}
              />
            )}
          </InputGroup>
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            noPopup
            useValidator
            disabled={!values.review_cycle}
            onAfterSubmit={() => {
              goBack(
                pathToUrl(ROUTES.FORMS.KPI.GENERAL, { id: kpi.id }),
                locationState.kpiLocationState,
                true,
              )
              successNotification('Target saved')
            }}
          >
            Save Deliverables
          </NewSaveButtonWithPopup>
        </PageActions>
      </PageWrapper>
    </>
  )
})

export default (props: Props) => {
  return (
    <Form api={kpiTargetsRequestsNew}>
      <RoadmapTargetForm {...props} />
    </Form>
  )
}
