import React from 'react'
import { HStack, Icon, IconName, Text } from '@revolut/ui-kit'

export type StageItemRecommendationProps = {
  color: string
  iconName?: IconName
  text: string
}

export const StageItemRecommendation = ({
  color,
  iconName,
  text,
}: StageItemRecommendationProps) => {
  return (
    <HStack data-testid="Recommendation" align="center" gap="s-4" minWidth="100px">
      {iconName && <Icon color={color} name={iconName} size={16} />}
      <Text color={color} variant="body1" style={{ whiteSpace: 'nowrap' }}>
        {text}
      </Text>
    </HStack>
  )
}
