import React from 'react'
import { Popup, Header, Text, Button } from '@revolut/ui-kit'

type Props = {
  open: boolean
  loading: boolean
  onClose: () => void
  onConfirm: () => void
}

const MrtPopup = ({ open, onClose, onConfirm, loading }: Props) => {
  return (
    <Popup open={open} onClose={onClose} variant="bottom-sheet">
      <Header variant="bottom-sheet">
        <Header.Title>
          I have completed the MRT questionnaire for this requisition
        </Header.Title>
      </Header>
      <Text use="p" variant="caption" color="grey-tone-50">
        Regulation requires completion of the MRT questionnaire. Non completion will lead
        to delays in the hiring process
      </Text>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          elevated
          onClick={onConfirm}
          pending={loading}
          disabled={loading}
          data-testid="mrt-popup-confirm-btn"
        >
          Confirm
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default MrtPopup
