import { AxiosPromise, AxiosResponse } from 'axios'

import { FetchDataQueryInterface } from '@src/interfaces/data'
import { ExportRequest, GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'
import {
  DefaultWorkingHourRequirementsResponse,
  EmployeeShiftApprovalInfoResponse,
  EmployeeShiftApprovalInfoData,
  EmployeeShiftDetails,
  EmployeesDefaultHours,
  EmployeeShiftDetailsInterface,
  EmployeeShiftsStatsInterface,
  SchedulePolicyShiftInterface,
  ScheduleShiftInterface,
  SchedulingPolicyInterface,
  SchedulingPolicyTableInterface,
  ShiftOverviewInterface,
  EmployeeShiftChildrenType,
  ScheduleShiftSummaryInterface,
  ScheduleShiftStatsInterface,
  ScheduleShiftBreakdownInterface,
  ScheduleShiftBreakdownStatsInterface,
} from '@src/interfaces/attendance'
import { useFetch } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { getCommentsAPI } from './comments'
import { ChangelogInterface } from '@src/interfaces/changelog'

export const getSchedulingPolicies = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<SchedulingPolicyTableInterface>
> =>
  api.get(`${API.ATTENDANCE_TRACKING}/schedulingPolicies`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const archivePolicy = (id: string | number) =>
  api.post<SchedulingPolicyInterface>(
    `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}/archive`,
  )

export const unarchivePolicy = (id: string | number) =>
  api.post<SchedulingPolicyInterface>(
    `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}/unarchive`,
  )

export const deletePolicy = (id: string | number) =>
  api.delete(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}`)

export const schedulingPoliciesRequests: RequestInterfaceNew<SchedulingPolicyInterface> =
  {
    get: async ({ id }) => api.get(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(
        `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}`,
        data,
      ),
    submit: async data =>
      apiWithoutHandling.post(`${API.ATTENDANCE_TRACKING}/schedulingPolicies`, data),
    delete: async ({ id }) =>
      api.delete(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}`),
  }

export const completeSchedulingPolicyConfiguration = (
  policy: SchedulingPolicyInterface,
) => api.post(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policy.id}/complete`)

export const getScheduleShifts =
  (id: string | number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<SchedulePolicyShiftInterface>
  > =>
    api.get(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${id}/shifts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const schedulingPolicyShiftsRequests: RequestInterfaceNew<SchedulePolicyShiftInterface> =
  {
    get: async ({ id, policyId }) =>
      api.get(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}`),
    update: async (data, { id, policyId }) =>
      apiWithoutHandling.patch(
        `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}`,
        data,
      ),
    submit: async (data, { policyId }) =>
      apiWithoutHandling.post(
        `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts`,
        data,
      ),
    delete: async (_, { id, policyId }) =>
      api.delete(
        `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}`,
      ),
  }

export const archivePolicyShift = (policyId: string | number, id: string | number) =>
  api.post<SchedulePolicyShiftInterface>(
    `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}/archive`,
  )

export const unarchivePolicyShift = (policyId: string | number, id: string | number) =>
  api.post<SchedulePolicyShiftInterface>(
    `${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}/unarchive`,
  )

export const deletePolicyShift = (policyId: string | number, id: string | number) =>
  api.delete(`${API.ATTENDANCE_TRACKING}/schedulingPolicies/${policyId}/shifts/${id}`)

export const useEmployeesDefaultHours = (id?: string | number) =>
  useFetch<EmployeesDefaultHours>(
    id ? `${API.EMPLOYEES}/${id}/schedule/defaultHours` : null,
    undefined,
    undefined,
    true,
  )

export const employeeDefaultWorkingHoursRequests: RequestInterfaceNew<EmployeesDefaultHours> =
  {
    get: async ({ id }) => api.get(`${API.EMPLOYEES}/${id}/schedule/defaultHours`),
    update: async (_, { id }, data) =>
      apiWithoutHandling.patch(`${API.EMPLOYEES}/${id}/schedule/defaultHours`, data),
    submit: async (data, { id }) =>
      apiWithoutHandling.post(`${API.EMPLOYEES}/${id}/schedule/defaultHours`, data),
  }

interface DefaultWorkingHoursRequirementsParams {
  start_time?: string | null
  end_time?: string | null
}

export const useDefaultWorkingHoursRequirements = (
  employeeId: string | number,
  params: DefaultWorkingHoursRequirementsParams,
) =>
  useFetch<DefaultWorkingHourRequirementsResponse>(
    params.start_time && params.end_time
      ? `${API.EMPLOYEES}/${employeeId}/schedule/defaultHoursRequirements`
      : null,
    undefined,
    { params },
  )

export const useDaySummary = (employeeId: string | number, date?: string) =>
  useFetch<EmployeeShiftDetailsInterface>(
    date ? `${API.EMPLOYEES}/${employeeId}/schedule/daySummary` : null,
    undefined,
    { params: { date } },
  )

export const getEmployeeShifts =
  (employeeId: string | number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeShiftDetailsInterface, EmployeeShiftsStatsInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/schedule/shifts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const scheduleShiftsRequests = (
  endpoint: 'shifts' | 'breaks',
): RequestInterfaceNew<ScheduleShiftInterface> => ({
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}/schedule/${endpoint}/${id}`),
  update: async (data, { id, employeeId }) =>
    apiWithoutHandling.patch(
      `${API.EMPLOYEES}/${employeeId}/schedule/${endpoint}/${id}`,
      data,
    ),
  submit: async (data, { employeeId }) =>
    apiWithoutHandling.post(`${API.EMPLOYEES}/${employeeId}/schedule/${endpoint}`, data),
  delete: async (_, { id, employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}/schedule/${endpoint}/${id}`),
})

export const getScheduleChangelog =
  (employeeId: string, id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ChangelogInterface<ScheduleShiftInterface>>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/schedule/shifts/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const deleteShift = (
  employeeId: string | number,
  id: string | number,
  endpoint: 'shifts' | 'breaks',
) => api.delete(`${API.EMPLOYEES}/${employeeId}/schedule/${endpoint}/${id}`)

interface UseShiftOverviewParams {
  date?: string
  start_time?: string | null
  end_time?: string | null
  id?: number
}

export const useShiftOverview = (
  employeeId: string | number,
  params: UseShiftOverviewParams,
) =>
  useFetch<ShiftOverviewInterface>(
    params.date && params.start_time && params.end_time
      ? `${API.EMPLOYEES}/${employeeId}/schedule/shiftOverview`
      : null,
    undefined,
    { params },
  )

export const useShiftApprovals = (employeeId: string, id: string) =>
  useFetch<ApprovalFlowResponse>(
    `${API.EMPLOYEES}/${employeeId}/schedule/shifts/${id}/approvals`,
  )

export const approveShift = (employeeId: string | number, id: string | number) =>
  api.post<ScheduleShiftInterface>(
    `${API.EMPLOYEES}/${employeeId}/schedule/shifts/${id}/approve`,
  )

export const rejectShift = (
  employeeId: string | number,
  id: string | number,
  rejection_comment: string,
) =>
  api.post<ScheduleShiftInterface>(
    `${API.EMPLOYEES}/${employeeId}/schedule/shifts/${id}/reject`,
    {
      rejection_comment,
    },
  )

export const getShiftCommentsAPI = (employeeId: string, id: string) =>
  getCommentsAPI({
    baseUrl: `${API.EMPLOYEES}/${employeeId}/schedule/shifts/${id}/comments`,
  })

const transformApprovalInfoResponse =
  (children_type: EmployeeShiftChildrenType) =>
  (data: AxiosResponse<EmployeeShiftApprovalInfoResponse[]>) => ({
    ...data,
    data: {
      results: data.data.map(row => ({
        ...row,
        children_type,
        /** Having `children: [null]` will render loading row while data is being fetched */
        children: [null],
        /** After filters change, this will force rerender of row, because we don't want to keep the row unwrapped, or else it breaks the table */
        renderKey: Math.random(),
      })),
    } as GetRequestInterface<EmployeeShiftApprovalInfoData>,
  })

export const getDirectReportsApprovalInfo = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeShiftApprovalInfoData>
> =>
  apiWithoutHandling
    .get<EmployeeShiftApprovalInfoResponse[]>(
      `${API.ATTENDANCE_TRACKING}/directReports/approvalsInfo`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
    )
    .then(transformApprovalInfoResponse(EmployeeShiftChildrenType.DirectReportShifts))

const transformEmployeeShiftDetailsResponse = (
  data: AxiosResponse<EmployeeShiftDetails>,
) => ({
  ...data,
  data: {
    results: data.data.summaries,
  } as GetRequestInterface<EmployeeShiftDetailsInterface>,
})

export const getDirectReportsShifts = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeShiftDetailsInterface>
> =>
  apiWithoutHandling
    .get<EmployeeShiftDetails>(`${API.ATTENDANCE_TRACKING}/directReports/shifts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
    .then(transformEmployeeShiftDetailsResponse)

export const getTeamScheduleApprovalInfo =
  (id: string | number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeShiftApprovalInfoData>
  > =>
    apiWithoutHandling
      .get<EmployeeShiftApprovalInfoResponse[]>(
        `${API.TEAMS}/${id}/schedule/approvalsInfo`,
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
      )
      .then(transformApprovalInfoResponse(EmployeeShiftChildrenType.TeamShifts))

export const getTeamScheduleShifts = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeShiftDetailsInterface>
> => {
  const id = filters?.find(filter => filter.columnName === 'id')?.filters[0].id
  return apiWithoutHandling
    .get<EmployeeShiftDetails>(`${API.TEAMS}/${id}/schedule/shifts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
    .then(transformEmployeeShiftDetailsResponse)
}

export const getDepartmentScheduleShifts =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ScheduleShiftSummaryInterface>
  > =>
    apiWithoutHandling.get(`${API.DEPARTMENT}/${id}/schedule/shifts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getDepartmentScheduleShiftsStats =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<ScheduleShiftStatsInterface> =>
    apiWithoutHandling.get(`${API.DEPARTMENT}/${id}/schedule/shifts/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getScheduleShiftSummary = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<ScheduleShiftSummaryInterface>
> =>
  apiWithoutHandling.get(`${API.ATTENDANCE_TRACKING}/shifts`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getScheduleShiftSummaryStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<ScheduleShiftStatsInterface> =>
  apiWithoutHandling.get(`${API.ATTENDANCE_TRACKING}/shifts/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getShiftSummaryExport: ExportRequest = (exportType, filterQuery) =>
  api.get(`${API.ATTENDANCE_TRACKING}/shifts/${exportType}`, {
    params: filterQuery,
    responseType: 'blob',
  })

export const getScheduleShiftBreakdown = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<ScheduleShiftBreakdownInterface>
> =>
  apiWithoutHandling.get(`${API.ATTENDANCE_TRACKING}/breakdowns`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getScheduleShiftBreakdownStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<ScheduleShiftBreakdownStatsInterface> =>
  apiWithoutHandling.get(`${API.ATTENDANCE_TRACKING}/breakdowns/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getShiftBreakdownExport: ExportRequest = (exportType, filterQuery) =>
  api.get(`${API.ATTENDANCE_TRACKING}/breakdowns/${exportType}`, {
    params: filterQuery,
    responseType: 'blob',
  })
