import { ValueBasedCardInterface } from '@src/interfaces/performance'
import { SkillInterface } from '@src/interfaces/skills'

export const skillExample = {
  id: 1,
  name: 'Problem Solving',
  mastery_level_definition_basic: [
    'has common sense: quickly identifies incorrect statements or assumptions',
    'is a logical thinker: breaks down & solves problems based on first principles, data and cause-effect relationships',
  ],
  mastery_level_definition_intermediate: [
    'is a critical thinker: identifies mistakes in seemingly correct statements or assumptions',
    'is a deep thinker: dives deep into complex matters until ground 0',
  ],
  mastery_level_definition_advanced: [
    'is able to connect the dots: synthesizes solution through seemingly unrelated objects',
    'Is a creative thinker: approaches solutions indirectly and creatively',
  ],
  mastery_level_definition_expert: [
    'is an abstract thinker: accurately describes, models and solves complex problems through advanced scientific methods',
  ],
} as unknown as SkillInterface // just an example, doesn't need to fully satisfy type

export const valueExample = {
  type: 'company-value-card',
  value_id: 1,
  name: 'Customer First',
  description:
    'We believe that everything we do should solve our customers’ needs. To create awe and inspire, we pay attention to every single detail.',
  sections: [
    {
      behaviour_id: 4,
      text: 'Put customers first',
      description:
        'Put yourself in the shoes of the customer (external or internal) and understand how they are using the product or process.',
      value: null,
      justification: null,
      previous_values: null,
    },
    {
      behaviour_id: 5,
      text: 'Keep it simple',
      description:
        'Simplify everything – minimise any friction for the customer. Save time for your customers, your manager, and your stakeholders.',
      value: null,
      justification: null,
    },
    {
      behaviour_id: 37,
      text: 'Personalise your approach',
      description:
        'Be empathetic towards your customer. Tailor your approach to best suit their needs and deliver wow in all scenarios.',
      value: null,
      justification: null,
      previous_values: null,
    },
  ],
  justification: null,
  rating: 'dont_know',
} as ValueBasedCardInterface // just an example, doesn't need to fully satisfy type

export const chartData = {
  progress_history: [
    {
      progress_datetime: '2022-12-31T00:00:00',
      progress: 0.38537934321306605,
      estimated: null,
      progress_datetime_label: '2022-12-31T00:00:00',
    },
    {
      progress_datetime: '2023-01-31T00:00:00',
      progress: 0.3794428361168214,
      estimated: null,
      progress_datetime_label: '2023-01-31T00:00:00',
    },
    {
      progress_datetime: '2023-02-28T00:00:00',
      progress: 0.48725295131980373,
      estimated: null,
      progress_datetime_label: '2023-02-28T00:00:00',
    },
    {
      progress_datetime: '2023-03-31T00:00:00',
      progress: 1.0119477361364548,
      estimated: null,
      progress_datetime_label: '2023-03-31T00:00:00',
    },
    {
      progress_datetime: '2023-04-30T00:00:00',
      progress: 0.0,
      estimated: null,
      progress_datetime_label: '2023-04-30T00:00:00',
    },
    {
      progress_datetime: '2023-05-31T00:00:00',
      progress: 0.375,
      estimated: null,
      progress_datetime_label: '2023-05-31T00:00:00',
    },
    {
      progress_datetime: '2023-06-30T00:00:00',
      progress: 0.5383726905656283,
      estimated: null,
      progress_datetime_label: '2023-06-30T00:00:00',
    },
    {
      progress_datetime: '2023-07-31T00:00:00',
      progress: 0.026890816114231865,
      estimated: null,
      progress_datetime_label: '2023-07-31T00:00:00',
    },
    {
      progress_datetime: '2023-08-31T00:00:00',
      progress: 0.08362744511575046,
      estimated: null,
      progress_datetime_label: '2023-08-31T00:00:00',
    },
    {
      progress_datetime: '2023-09-30T00:00:00',
      progress: 0.634280289671959,
      estimated: null,
      progress_datetime_label: '2023-09-30T00:00:00',
    },
    {
      progress_datetime: '2023-10-31T00:00:00',
      progress: 0.19567433872888723,
      estimated: null,
      progress_datetime_label: '2023-10-31T00:00:00',
    },
    {
      progress_datetime: '2023-11-02T00:00:00',
      progress: 0.21615051554995787,
      estimated: null,
      progress_datetime_label: '2023-11-02T00:00:00',
    },
    {
      progress_datetime: '2023-12-01T00:00:00',
      progress: null,
      estimated: null,
      progress_datetime_label: '2023-12-01T00:00:00',
    },
  ],
}

export const deliverables = [
  {
    title: 'Complexity',
    default: "Don't know",
    options: [
      {
        rating: "Don't know",
        description: 'Insufficient data to assess ticket complexity for this employee',
      },
      {
        rating: 'Basic',
        description: 'Delivers routine tasks under BAU',
      },
      {
        rating: 'Intermediate',
        description: 'Completes unique tasks with limited internal comparisons',
      },
    ],
  },
  {
    title: 'Speed',
    default: 'Advanced',
    options: [
      {
        rating: "Don't know",
        description: 'Insufficient data to assess task speed for this employee',
      },
      {
        rating: 'Poor',
        description: 'Misses deadlines, delivers late',
      },
      {
        rating: 'Basic',
        description: 'Completes tasks on time, rarely misses deadlines',
      },
      {
        rating: 'Intermediate',
        description: 'Always meets deadlines, often beats them',
      },
      {
        rating: 'Advanced',
        description: 'Delivers very fast, beats deadlines most of the time.',
      },
      {
        rating: 'Expert',
        description:
          'Constantly beats deadlines, most of the time by unbelievable margins',
      },
    ],
  },
  {
    title: 'Quality',
    default: 'Poor',
    options: [
      {
        rating: "Don't know",
        description:
          'Insufficient data to assess the quality of the tasks for this employee',
      },
      {
        rating: 'Poor',
        description: 'The quality is unacceptably low',
      },
      {
        rating: 'Basic',
        description: 'A good standard, sometimes require several iterations',
      },
      {
        rating: 'Intermediate',
        description: 'A high standard, typically require only 1 iteration',
      },
      {
        rating: 'Advanced',
        description:
          'Always delivered to a high standard without requiring of iterations',
      },
      {
        rating: 'Expert',
        description: 'Consistently delivered to the highest possible standards',
      },
    ],
  },
]
