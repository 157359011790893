import React, { FormEvent } from 'react'
import {
  ActionButton,
  HStack,
  Icon,
  Spacer,
  Tag,
  Text,
  TextArea,
  TextButton,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { GrowthPlanActionInterface } from '@src/interfaces/growthPlans'
import { obfuscateNonPrintableCharacters } from '@src/utils/string'

interface Props {
  action: GrowthPlanActionInterface
  hasError: boolean
  errorMessage?: string
  onChange: (value: string) => void
  onDelete: VoidFunction
  onConnectMetric: VoidFunction
  onDisconnectMetric: VoidFunction
  dataName: string
}

export const ActionItem = ({
  action,
  hasError,
  errorMessage,
  onChange,
  onDelete,
  onConnectMetric,
  onDisconnectMetric,
  dataName,
}: Props) => {
  return (
    <VStack
      p="s-16"
      space="s-16"
      borderRadius="r16"
      border={`1px solid ${Token.color.grey50_20}`}
    >
      <HStack space="s-8" align="center">
        <Tag>
          <TextButton color={Token.color.white} onClick={onConnectMetric}>
            <HStack p="s-4" align="center" space="s-6">
              <Icon name={action.object_id ? 'Pencil' : 'Plus'} size={12} />
              {!action.object_id && <Text>Connect skill or value</Text>}
            </HStack>
          </TextButton>
        </Tag>
        {action.object_id && action.content_object && (
          <Tag variant="faded" useIcon="Palette">
            <HStack p="s-4" align="center" space="s-6">
              {action.content_object.name}
              <TextButton color={Token.color.white} onClick={onDisconnectMetric}>
                <Icon name="Cross" size={12} />
              </TextButton>
            </HStack>
          </Tag>
        )}
        <Spacer />
        <ActionButton useIcon="Delete" iconOnly onClick={onDelete}>
          Delete
        </ActionButton>
      </HStack>
      <TextArea
        label="To-do"
        maxRows={3}
        value={action.description}
        invalid={hasError}
        errorMessage={errorMessage}
        onChange={({ currentTarget }: FormEvent<HTMLTextAreaElement>) =>
          onChange(obfuscateNonPrintableCharacters(currentTarget.value))
        }
        data-name={dataName}
      />
    </VStack>
  )
}
