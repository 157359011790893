import React from 'react'
import { useParams, Switch, Route } from 'react-router-dom'
import { Flex, Token } from '@revolut/ui-kit'

import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Colored } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'

import { EmployeeTimeOffPolicies } from './EmployeeTimeOffPolicies'
import EmployeeTimeOffRequests from './EmployeeTimeOffRequests'
import EmployeeTimeOffBalances from './EmployeeTimeOffBalances'
import {
  canViewBalances,
  canViewPolicyAssignments,
  canViewTimeOffRequests,
} from '../ProfileSummary/common'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
}

export const TimeOff = (props: Props) => {
  const params = useParams<{ id: string }>()

  const tabs = [
    {
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.BALANCES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.BALANCES, params),
      title: 'Balances',
      component: EmployeeTimeOffBalances,
      canView: canViewBalances(props.data),
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.REQUESTS,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.REQUESTS, params),
      title: 'Requests',
      component: EmployeeTimeOffRequests,
      canView: canViewTimeOffRequests(props.data),
      quickSummary: props.stats?.pending_time_off_request_count ? (
        <Colored color={Token.color.warning}>
          {props.stats.pending_time_off_request_count}
        </Colored>
      ) : null,
    },
    {
      path: ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.POLICIES, params),
      title: 'Policies',
      component: EmployeeTimeOffPolicies,
      canView: canViewPolicyAssignments(props.data),
    },
  ].filter(tab => tab.canView)

  return (
    <Flex flexDirection="column" flex={1} minHeight={0}>
      <Flex alignItems="flex-start" mb="s-8">
        <TabBarNavigation isSubtab tabs={tabs} />
      </Flex>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component {...props} />
          </Route>
        ))}
        {tabs[0] ? <InternalRedirect to={tabs[0].path} /> : null}
      </Switch>
    </Flex>
  )
}
