import React from 'react'

import {
  getDepartmentEmpoyeeCompensationReviews,
  useDepartmentCompensationYearOptions,
} from '@src/api/compensation'
import { BudgetDistributionProvider } from '@src/features/BudgetDistribution/BudgetDistribution'
import { BudgetDistributionTable } from '@src/features/BudgetDistribution/BudgetDistributionTable'

interface DistributionProps {
  navigation?: React.ReactElement
}

export default (props: DistributionProps) => {
  return (
    <BudgetDistributionProvider
      api={getDepartmentEmpoyeeCompensationReviews}
      optionsApi={useDepartmentCompensationYearOptions}
    >
      <BudgetDistributionTable {...props} />
    </BudgetDistributionProvider>
  )
}
