import React from 'react'
import { Item, Text, Token, Avatar } from '@revolut/ui-kit'
import { LinkExternal } from '@revolut/icons'
import { REVOLUT_PEOPLE_DOCUMENTATION } from '@src/constants/externalLinks'

export const DocumentationCategory = () => {
  return (
    <Item padding="s-16" use="a" href={REVOLUT_PEOPLE_DOCUMENTATION} target="_blank">
      <Item.Avatar mr="s-16">
        <Avatar useIcon="DocumentRevolut" />
      </Item.Avatar>
      <Item.Content>
        <Text>Documentation</Text>
      </Item.Content>
      <Item.Side>
        <LinkExternal color={Token.color.greyTone50} />
      </Item.Side>
    </Item>
  )
}
