import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { Lifecycle } from '@src/pages/Settings/Lifecycle/Lifecycle'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { lifecycleSettings } from '@src/api/settings'

const routes = [
  {
    title: 'Lifecycle app settings',
    description: 'All settings for onboarding and offboarding',
    path: ROUTES.SETTINGS.LIFECYCLE,
    url: ROUTES.SETTINGS.LIFECYCLE,
    canView: [
      PermissionTypes.ViewLifecycleSettings,
      PermissionTypes.ChangeLifecycleSettings,
    ],
    component: Lifecycle,
  },
]

export const LifecycleSettings = () => {
  return <SettingsForm routes={routes} api={lifecycleSettings} />
}
