import React from 'react'
import { ItemSkeleton } from '@revolut/ui-kit'

export const NewSectionCardSkeleton = () => {
  return (
    <ItemSkeleton>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content>
        <ItemSkeleton.Title />
      </ItemSkeleton.Content>
    </ItemSkeleton>
  )
}
