import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { useGetCalibrationStats } from '@src/api/reviewCycles'

interface PublishStats {
  totalCount: number
  completedCount: number
  isLoading: boolean
}

export const usePublishStats = (cycle: ReviewCyclesInterface): PublishStats => {
  const { data: stats, isLoading } = useGetCalibrationStats(cycle.id)
  const completedCount = stats
    ? Number(stats.unsatisfactory) +
      Number(stats.average_minus) +
      Number(stats.average_plus) +
      Number(stats.strong) +
      Number(stats.exceptional)
    : 0

  const totalCount = stats ? completedCount + Number(stats.unassigned) : 0

  return {
    totalCount,
    completedCount,
    isLoading,
  }
}
