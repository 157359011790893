import React, { useMemo, useEffect, useRef, useState } from 'react'
import toString from 'lodash/toString'
import { Box, BoxProps } from '@revolut/ui-kit'

const stepperAttribute = 'data-stepper'
const titleValue = 'title'
const titleQuery = `[${stepperAttribute}="${titleValue}"]`

export const autoStepperTitleProps = {
  [stepperAttribute]: titleValue,
}

type AutoStepperContextType = {
  stepsUpdated?: boolean
  forceUpdate: () => void
}

export const AutoStepperContext = React.createContext<AutoStepperContextType>({
  stepsUpdated: undefined,
  forceUpdate() {},
})

export const getStepNumber = (elm: HTMLElement) => {
  return parseFloat(elm.dataset.stepNumber || '0')
}

interface Props extends BoxProps {}

const AutoStepper = ({ children, ...props }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const [stepsUpdated, setStepsUpdated] = useState(false)
  const [forcedUpdate, setForcedUpdate] = useState(false)
  const [prevNumTitles, setPrevNumTitles] = useState(0)

  // Wrap with memo to avoid unnecessary rerenders of context consumers
  const contextValue = useMemo(
    () => ({
      stepsUpdated,
      forceUpdate() {
        setForcedUpdate(true)
      },
    }),
    [stepsUpdated],
  )

  useEffect(() => {
    const onShouldUpdate = () => {
      setStepsUpdated(false)
    }

    if (ref.current) {
      const titles = ref.current.querySelectorAll(titleQuery)
      if (titles.length === prevNumTitles && !forcedUpdate) {
        return onShouldUpdate
      }

      titles.forEach((title, index) => {
        title.setAttribute('data-step-number', toString(index + 1))
      })
      setStepsUpdated(true)
      setPrevNumTitles(titles.length)
      setForcedUpdate(false)
    }
    return onShouldUpdate
  })

  return (
    <AutoStepperContext.Provider value={contextValue}>
      <Box {...props} use="div" ref={ref}>
        {children}
      </Box>
    </AutoStepperContext.Provider>
  )
}

export default AutoStepper
