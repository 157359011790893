import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { EntityInterface, ContractingCountryInterface } from '@src/interfaces/enitities'
import Table from '@components/TableV2/Table'
import React from 'react'

export const entityGenericNameColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.entity,
  title: 'Name',
}

export const entityRegistrationCountryColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.text,
  idPoint: 'registration_country.id',
  dataPoint: 'registration_country.name',
  sortKey: 'registration_country',
  filterKey: 'registration_country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Registration country',
}

export const entityRegistrationNumberColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.text,
  idPoint: 'registration_number',
  dataPoint: 'registration_number',
  sortKey: 'registration_number',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Registration no',
}

export const entityPaygroupCountriesColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.insert,
  idPoint: 'paygroup_countries',
  dataPoint: 'paygroup_countries',
  sortKey: 'pay_groups__country__name',
  filterKey: 'pay_groups__country__id',
  selectorsKey: selectorKeys.countries,
  title: 'Paygroup countries',
  insert: ({ data }) =>
    data.paygroup_countries?.map(country => country.name).join(', ') || '-',
}

export const entityContractingCountriesColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.insert,
  idPoint: 'contracting_countries',
  dataPoint: 'contracting_countries',
  sortKey: 'contracting_countries__country__name',
  filterKey: 'contracting_countries__country__id',
  selectorsKey: selectorKeys.countries,
  title: 'External hiring countries',
  insert: ({ data }) =>
    data.contracting_countries?.map(country => country.name).join(', ') || '-',
}

export const entityStatusColumn: ColumnInterface<EntityInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.entities_status,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={data.status.id === 'active' ? 'success' : 'neutral'}
    />
  ),
}

export const regionHiringCountryColumn: ColumnInterface<ContractingCountryInterface> = {
  type: CellTypes.text,
  idPoint: 'country.id',
  dataPoint: 'country.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Hiring country',
}
