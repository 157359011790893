import React from 'react'
import EmailThread from '@src/features/EmailThread/EmailThread'
import { useGetCandidateEmailThread } from '@src/api/hiringProcess'
import SideBar from '@components/SideBar/SideBar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

interface TimelineEmailSidebarProps {
  candidateId: number
  threadId: number
  emailId: number
  onClose: () => void
}

const TimelineEmailSidebar = ({
  onClose,
  candidateId,
  emailId,
  threadId,
}: TimelineEmailSidebarProps) => {
  const { data: thread } = useGetCandidateEmailThread(candidateId, threadId)

  if (!thread) {
    return null
  }

  return (
    <SideBar isOpen title={thread.subject} onClose={onClose} variant="wide">
      <EmailThread
        emails={thread.candidate_emails}
        replyUrl={pathToUrl(ROUTES.FORMS.SEND_EMAIL.CANDIDATE, {
          threadId,
          candidateId,
          emailId,
        })}
        emailId={emailId}
      />
    </SideBar>
  )
}

export default TimelineEmailSidebar
