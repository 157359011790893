import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { CellTypes, RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationDescription,
  notificationsDeadline,
  notificationsPriorityColumn,
  notificationsReceivedColumn,
} from '@src/constants/columns/notifications'
import {
  notificationsGoalsToApprove,
  notificationsGoalsToCalibrate,
  notificationsGoalsToSet,
} from '@src/api/notifications'
import { selectorKeys } from '@src/constants/api'

const rows = (employeeId?: number): RowInterface<NotificationsInterface> => ({
  cells: [
    {
      ...notificationDescription(employeeId),
      width: 300,
    },
    {
      ...notificationsReceivedColumn,
      width: 160,
    },
    {
      ...notificationsDeadline,
      width: 150,
    },
    {
      ...notificationsPriorityColumn,
      width: 120,
    },
    {
      type: CellTypes.insert,
      idPoint: 'category',
      dataPoint: 'category',
      sortKey: null,
      filterKey: null,
      selectorsKey: selectorKeys.none,
      noResize: true,
      title: 'Actions',
      width: 200,
    },
  ],
})

export const NotificationsGoalsToSet = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={rows(employeeId)}
      request={notificationsGoalsToSet(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export const NotificationsGoalsToApprove = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={rows(employeeId)}
      request={notificationsGoalsToApprove(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export const NotificationsGoalsToCalibrate = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={rows(employeeId)}
      request={notificationsGoalsToCalibrate(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}
