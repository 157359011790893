import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import {
  DocumentsRuleInterface,
  SendRuleDocuments,
  SentRequestsInterface,
} from '@src/interfaces/documentsRule'
import { Statuses } from '@src/interfaces'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getStatusColor } from '@src/components/CommonSC/General'
import { getDocumentStatusColor } from './documents'
import { Token } from '@revolut/ui-kit'

const NamesContainer = styled.span``
const NamesAnchor = styled(Link)`
  text-decoration: none;
  color: ${Token.color.foreground};

  &:hover {
    text-decoration: underline;
  }
`

export const documentsRulesName: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'id',
  selectorsKey: selectorKeys.document_template_rules,
  title: 'Name',
}

export const documentsRulesType: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'rule_type.id',
  dataPoint: 'rule_type.name',
  sortKey: 'rule_type',
  filterKey: 'rule_type',
  selectorsKey: selectorKeys.document_rule_types,
  title: 'Rule type',
}

export const documentsRulesTemplateType: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'template_type.id',
  dataPoint: 'template_type.name',
  sortKey: 'document_template__template_type',
  filterKey: 'document_template__template_type',
  selectorsKey: selectorKeys.document_template_types,
  title: 'Template type',
}

export const documentsRulesScheduleType: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'schedule_type.id',
  dataPoint: 'schedule_type.name',
  sortKey: 'schedule_type',
  filterKey: 'schedule_type',
  selectorsKey: selectorKeys.document_rule_schedule_types,
  title: 'Schedule',
}

export const documentsRulesOwner: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Owner',
  insert: ({ data }) => <UserWithAvatar {...data.owner} />,
}

export const documentsRulesCreated: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Created on',
}

export const documentsRulesStatus: ColumnInterface<DocumentsRuleInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.document_rule_statuses,
  title: 'Status',
  colors: data => getStatusColor(data.status.id),
}

export const documentsRulesSentName: ColumnInterface<SendRuleDocuments> = {
  type: CellTypes.text,
  idPoint: 'document_name',
  dataPoint: 'document_name',
  sortKey: 'document_name',
  filterKey: 'document_name',
  selectorsKey: selectorKeys.none,
  title: 'Document Name',
}

export const documentsRulesSentRecipient: ColumnInterface<SendRuleDocuments> = {
  type: CellTypes.insert,
  idPoint: 'document_name',
  dataPoint: 'document_name',
  sortKey: 'document_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return (
      <NamesContainer>
        {data.recipient_view_rows?.map?.((row, id, arr) => {
          return (
            <React.Fragment key={id}>
              <NamesAnchor
                to={getLocationDescriptor(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, {
                    id: row.employee.id,
                  }),
                )}
              >
                {row.employee.display_name}
              </NamesAnchor>
              {id !== arr.length - 1 ? `, ` : ''}
            </React.Fragment>
          )
        }) || []}
      </NamesContainer>
    )
  },
  title: 'Recipient Name',
}

export const documentsRulesSentStatus: ColumnInterface<SendRuleDocuments> = {
  type: CellTypes.text,
  colors: data => {
    if (data.status === Statuses.pending) {
      return Token.color.warning
    }
    return Token.color.foreground
  },
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.sent_envelope_statuses,
  title: 'Document Status',
}

export const documentsRulesSentDate: ColumnInterface<SendRuleDocuments> = {
  type: CellTypes.date,
  idPoint: 'sent_date_time',
  dataPoint: 'sent_date_time',
  sortKey: 'sent_date_time',
  filterKey: 'sent_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Sent Date',
}

export const documentRequestsName: ColumnInterface<SentRequestsInterface> = {
  type: CellTypes.text,
  idPoint: 'document_name',
  dataPoint: 'document_name',
  sortKey: 'document_name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Document Name',
}

export const documentRequestsCreationDate: ColumnInterface<SentRequestsInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Creation date',
}

export const documentRequestsRecipient: ColumnInterface<SentRequestsInterface> = {
  type: CellTypes.insert,
  idPoint: '',
  dataPoint: '',
  sortKey: 'recipient__full_name',
  filterKey: 'recipient__id',
  selectorsKey: selectorKeys.all_employees,
  title: 'Recipient',
  insert: ({ data }) => <UserWithAvatar {...data.recipient} />,
}

export const documentRequestsStatus: ColumnInterface<SentRequestsInterface> = {
  type: CellTypes.text,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.uploaded_documents_status_choices,
  title: 'Status',
  colors: data => getDocumentStatusColor(data.status.id),
}
