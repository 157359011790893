import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { Text } from '@revolut/ui-kit'
import React from 'react'
import { cancelInterviewFeedback } from '@src/api/recruitment/interviews'

interface Props {
  id: number
  isOpen: boolean
  onClose: () => void
  onSuccess: () => void
}

const CancelRequestPopup = ({ id, isOpen, onClose, onSuccess }: Props) => {
  const confirmHandler = async () => {
    await cancelInterviewFeedback(id)
    onSuccess()
    onClose()
  }

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={onClose}
      onConfirm={confirmHandler}
      onReject={onClose}
      label={`Are you sure you want to cancel the feedback?`}
      body={
        <Text use="p" color="red" fontWeight="600" pt="s-6">
          Warning: You can't undo this action.
        </Text>
      }
    />
  )
}

export default CancelRequestPopup
