import React, { ReactNode, useRef, useState } from 'react'
import {
  ActionButton,
  ActionButtonProps,
  Dropdown,
  HStack,
  Icon,
  TextButton,
  Text,
} from '@revolut/ui-kit'

import { OptionInterface } from '@src/interfaces/selectors'
import { IdAndName } from '@src/interfaces'

interface DropdownButtonProps<T = OptionInterface>
  extends Omit<ActionButtonProps, 'onSelect'> {
  children: ReactNode
  options: T[]
  onSelect: (option: T) => void
  useTextButton?: boolean
}

export const DropdownButton = <T extends IdAndName<number | string>>({
  children,
  options,
  onSelect,
  variant,
  useTextButton,
  ...props
}: DropdownButtonProps<T>) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  const buttonProps = {
    ref,
    onClick: () => setOpen(!isOpen),
    useEndIcon: 'ChevronDown' as const,
    ...props,
  }

  return (
    <>
      {useTextButton ? (
        <TextButton {...buttonProps}>
          <HStack space="s-8" align="center">
            <Text>{children}</Text>
            <Icon name={isOpen ? 'ChevronUp' : 'ChevronDown'} size={16} />
          </HStack>
        </TextButton>
      ) : (
        <ActionButton variant={variant} {...buttonProps}>
          {children}
        </ActionButton>
      )}
      <Dropdown
        open={isOpen}
        anchorRef={ref}
        onClose={() => setOpen(false)}
        data-testid="dropdown-buttons"
      >
        {options.map(option => (
          <Dropdown.Item
            key={option.id}
            onClick={() => {
              onSelect(option)
              setOpen(false)
            }}
            use="button"
            data-testid={`dropdown-button-item-${option.id}`}
          >
            {option.name}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </>
  )
}
