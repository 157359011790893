import React from 'react'
import AutoStepper from '@components/Stepper/AutoStepper'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { Queries, selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { Box, Link, Text, Widget } from '@revolut/ui-kit'
import LapeEmployeeSelector from '@components/Inputs/LapeFields/LapeEmployeeSelector'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useQuery } from '@src/utils/queryParamsHooks'
import SideBar from '@components/SideBar/SideBar'
import Loader from '@components/CommonSC/Loader'
import { Grid } from '@components/CommonSC/Grid'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'

const HiringStageSideBarForm = () => {
  const { deleteQueryParam, query } = useQuery()
  const form = useLapeContext<HiringProcessInterface>()
  const isOpen = query[Queries.StageId] !== undefined

  return (
    <SideBar
      isOpen={isOpen}
      variant="wide"
      onClose={() => deleteQueryParam(Queries.StageId)}
      title={form.values?.title || 'New stage'}
    >
      {form.loading ? (
        <Loader />
      ) : (
        <>
          <AutoStepper>
            <Text use="div" pb="s-16" color="grey-tone-50">
              General details
            </Text>
            <Widget p="s-16" mb="s-16">
              <Grid gap={24}>
                <Grid flow="column" justifyContent="space-between">
                  <Text>Playbook</Text>
                  <Link target="_blank" use="a" href={form.values.playbook_link}>
                    Link
                  </Link>
                </Grid>
                <Grid flow="column" justifyContent="space-between">
                  <Text>Duration</Text>
                  <Text>
                    {form.values.duration
                      ? `${getDuration(
                          form.values.duration,
                          form.values.duration_unit.name,
                        )}`
                      : '-'}
                  </Text>
                </Grid>
                <Grid flow="column" justifyContent="space-between">
                  <Text>Skills evaluated</Text>
                  <Box style={{ textAlign: 'right' }}>
                    {form.values.skills?.map(skill => (
                      <Text key={skill.id} use="div">
                        {skill.name}
                      </Text>
                    )) || '-'}
                  </Box>
                </Grid>
                <Grid flow="column" justifyContent="space-between">
                  <Text>Scorecard</Text>
                  <Link
                    target="_blank"
                    use="a"
                    href={pathToUrl(ROUTES.FORMS.INTERVIEW_SCORECARD_TEMPLATE.PREVIEW, {
                      id: form.values?.scorecard_template?.id,
                    })}
                  >
                    {form.values?.scorecard_template?.name}
                  </Link>
                </Grid>
              </Grid>
            </Widget>
            <Text use="div" pb="s-16" color="grey-tone-50">
              Eligible interviewers
            </Text>
            {form.values?.interviewer_type?.id === 'groups' ? (
              <LapeNewMultiSelect<HiringProcessInterface>
                disabled
                name="interviewers_groups"
                placeholder="Select groups"
                selector={selectorKeys.dynamic_groups}
              />
            ) : (
              <LapeEmployeeSelector
                required
                label="Select interviewer"
                disabled
                name="interviewers"
              />
            )}
          </AutoStepper>
        </>
      )}
    </SideBar>
  )
}

export default HiringStageSideBarForm
