import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { AssignUserToPermissionInputs } from '@src/pages/Forms/AssignUserToPermission/AssignUserToPermissionInputs'
import { useFetchUser } from '@src/pages/Forms/AssignUserToPermission/hooks'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PermissionManagementInterface } from '@src/interfaces/accessManagement'

const AssignUserToPermission = () => {
  const { userId } = useParams<{ userId: string }>()
  const { values } = useLapeContext<PermissionManagementInterface>()
  const backUrl = pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_USER.PERMISSIONS, {
    id: userId,
  })
  const user = useFetchUser(userId)

  useEffect(() => {
    if (user) {
      values.user = user
    }
  }, [user])

  return (
    <PageWrapper>
      <PageHeader
        title="Assign new permission"
        subtitle={
          user?.user_type === 'employee' ? (
            <UserWithAvatar {...user.employee} />
          ) : (
            user?.email
          )
        }
        backUrl={backUrl}
      />
      <PageBody>
        <AutoStepper>
          <AssignUserToPermissionInputs />
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          afterSubmitUrl={backUrl}
          useValidator
          successText="Permission has been saved successfully"
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(AssignUserToPermission)
