import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import {
  ExpandableCell,
  Group,
  Header,
  InputGroup,
  Link,
  MoreBar,
  Portal,
  Token,
  Widget,
} from '@revolut/ui-kit'
import {
  BaseBulkActionsInterface,
  CandidateBulkArchiveInterface,
  CandidateBulkEditInterface,
  CandidateBulkEmailsInterface,
  CandidateBulkMoveStageInterface,
  CandidateBulkSendOnlineTestInterface,
} from '@src/interfaces/hiringProccess'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import DOMPurify from 'dompurify'
import { navigateTo } from '@src/actions/RouterActions'
import { groupFiltersIntoURLQuery } from '@src/utils/table'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  onClose: () => void
  item:
    | CandidateBulkEmailsInterface
    | CandidateBulkArchiveInterface
    | CandidateBulkEditInterface
    | CandidateBulkMoveStageInterface
    | CandidateBulkSendOnlineTestInterface
}

const BulkActionsResultSidebar = ({ item, onClose }: Props) => {
  return (
    <Portal>
      <SideBar
        title="Bulk actions result"
        onClose={onClose}
        headerContent={
          <Header.Bar>
            <MoreBar>
              {!!item.failed_candidate_ids.length && (
                <MoreBar.Action
                  onClick={() => {
                    const searchQuery = groupFiltersIntoURLQuery([
                      {
                        columnName: 'id',
                        filters: item.failed_candidate_ids.map(id => ({
                          name: String(id),
                          id,
                        })),
                      },
                      {
                        columnName: 'active_interview_round__state',
                        filters: [
                          {
                            id: 'active',
                            name: 'active',
                          },
                          {
                            id: 'archived',
                            name: 'archived',
                          },
                          {
                            id: 'hired',
                            name: 'hired',
                          },
                        ],
                      },
                    ])

                    const queryString = new URLSearchParams(searchQuery).toString()

                    navigateTo(`${ROUTES.RECRUITMENT.CANDIDATES}?${queryString}`)
                  }}
                >
                  View failed candidates in the table
                </MoreBar.Action>
              )}
            </MoreBar>
          </Header.Bar>
        }
      >
        <InputGroup>
          <FormPreview<BaseBulkActionsInterface> data={item}>
            <Group>
              <FormPreview.Item
                title="Triggered by"
                field="request_employee"
                type="employee"
              />
              <FormPreview.Item
                title="Start date time"
                field="creation_date_time"
                type="dateTime"
              />
              <FormPreview.Item
                title="Finish date time"
                field="completed_date_time"
                type="dateTime"
              />
              <FormPreview.Item title="Status" field="status" type="capitalized" />
            </Group>
          </FormPreview>

          <ExpandableCell prefersExpanded={!!item.failed_candidate_ids.length}>
            <ExpandableCell.Title>Failed candidate ids</ExpandableCell.Title>
            <ExpandableCell.Content
              color={item.failed_candidate_ids.length ? Token.color.red : undefined}
            >
              {item.failed_candidate_ids.length}
            </ExpandableCell.Content>
            <ExpandableCell.Note whiteSpace="pre-line">
              {item.failed_candidate_ids.length
                ? item.failed_candidate_ids.map(id => (
                    <Link
                      key={id}
                      target="_blank"
                      to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}
                      use={InternalLink}
                      style={{ display: 'block ' }}
                    >
                      {id}
                    </Link>
                  ))
                : 'No items'}
            </ExpandableCell.Note>
          </ExpandableCell>

          <ExpandableCell>
            <ExpandableCell.Title>All candidate ids</ExpandableCell.Title>
            <ExpandableCell.Content>{item.candidate_ids.length}</ExpandableCell.Content>
            <ExpandableCell.Note whiteSpace="pre-line">
              {item.candidate_ids.length
                ? item.candidate_ids.map(id => (
                    <Link
                      key={id}
                      target="_blank"
                      to={pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id })}
                      use={InternalLink}
                      style={{ display: 'block ' }}
                    >
                      {id}
                    </Link>
                  ))
                : 'No items'}
            </ExpandableCell.Note>
          </ExpandableCell>

          {'email_body' in item && (
            <FormPreview<CandidateBulkEmailsInterface> data={item}>
              <Group>
                <FormPreview.Item title="Subject" field="subject" />
                <FormPreview.Item title="Email template" field="email_template.name" />
                <ExpandableCell prefersExpanded>
                  <ExpandableCell.Title>Email body</ExpandableCell.Title>
                  <ExpandableCell.Note>
                    <Widget
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.email_body),
                      }}
                    />
                  </ExpandableCell.Note>
                </ExpandableCell>
              </Group>
            </FormPreview>
          )}

          {'archive_all_rounds' in item && (
            <FormPreview<CandidateBulkArchiveInterface> data={item}>
              <Group>
                <FormPreview.Item
                  title="Archive all rounds"
                  field="archive_all_rounds"
                  type="boolean"
                />
                <FormPreview.Item title="Archived reason" field="archived_reason.name" />
                <FormPreview.Item<CandidateBulkArchiveInterface>
                  title="Archived comment"
                  insert={data => data.archived_comment || '-'}
                />
              </Group>
            </FormPreview>
          )}

          {'edit_fields' in item && (
            <FormPreview<CandidateBulkEditInterface> data={item}>
              <Group>
                <ExpandableCell prefersExpanded>
                  <ExpandableCell.Title>Edit fields</ExpandableCell.Title>
                  <ExpandableCell.Note>
                    {item.edit_fields ? JSON.stringify(item.edit_fields, null, 2) : '-'}
                  </ExpandableCell.Note>
                </ExpandableCell>
              </Group>
            </FormPreview>
          )}
        </InputGroup>
      </SideBar>
    </Portal>
  )
}

export default BulkActionsResultSidebar
