import { Flex, Text, Grid, Widget, TextButton } from '@revolut/ui-kit'
import { isEmpty } from 'lodash'
import React from 'react'
import Loader from '@components/CommonSC/Loader'
import { SidebarOption, SidebarSelection } from '../types'
import { Item } from './Item'

interface Props {
  disabled?: boolean
  selection: SidebarSelection
  pending?: boolean
  handleClearAll: VoidFunction
  handleItemClick: (item: SidebarOption, checked: boolean) => void
}

export const SelectMode = ({
  disabled,
  selection,
  pending,
  handleClearAll,
  handleItemClick,
}: Props) => {
  const selectedItemsArr = Object.values(selection)
  return (
    <>
      <Flex justifyContent="space-between" mb="s-16">
        <Text color="grey-tone-50">Showing selected items</Text>
        {!isEmpty(selectedItemsArr) && (
          <TextButton disabled={disabled} fontWeight={500} onClick={handleClearAll}>
            Clear all
          </TextButton>
        )}
      </Flex>
      <Widget p="18px">
        {pending && <Loader />}
        {!pending && (
          <Grid gap="s-32">
            {selectedItemsArr.length ? (
              selectedItemsArr.map(item => {
                return (
                  <Item
                    key={item.id}
                    disabled={disabled}
                    checked={!!selection[item.id]}
                    option={item}
                    handleItemClick={handleItemClick}
                  />
                )
              })
            ) : (
              <Text color="grey-tone-50">No selected items...</Text>
            )}
          </Grid>
        )}
      </Widget>
    </>
  )
}
