import React from 'react'
import {
  Color,
  DataPoint,
  DataPointSkeleton,
  Ellipsis,
  Highlights,
} from '@revolut/ui-kit'

export interface StatFilter<T = string> {
  id: T
  title: React.ReactNode
  value: React.ReactNode
  loading?: boolean
  color?: Color
  ariaLabel?: string
}

export interface StatFiltersProps<T = string> {
  filters: StatFilter<T>[]
  onClick?: (id: T) => void
  selectedFilter?: string
  maxItemWidth?: number
}

export const StatFilters = <T extends string>({
  filters,
  onClick,
  selectedFilter,
  maxItemWidth,
}: StatFiltersProps<T>) => {
  return (
    <Highlights variant={onClick ? 'tabs' : undefined} value={selectedFilter}>
      {filters.map(({ id, title, value, loading: itemLoading, ariaLabel, color }) => (
        <DataPoint
          aria-label={ariaLabel || `${title} count`}
          maxWidth={maxItemWidth}
          to={id}
          key={id}
          onClick={onClick ? () => onClick(id) : undefined}
        >
          {itemLoading ? (
            <DataPointSkeleton.Value />
          ) : (
            <DataPoint.Value use="h5" color={color} aria-label="Value">
              <Ellipsis tooltip="auto">{value == null ? '-' : value}</Ellipsis>
            </DataPoint.Value>
          )}
          <DataPoint.Label whiteSpace="pre-line" aria-label="Label">
            <Ellipsis tooltip="auto">{title}</Ellipsis>
          </DataPoint.Label>
        </DataPoint>
      ))}
    </Highlights>
  )
}
