import React from 'react'
import { compensationSettingsRequests } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { Bands } from './Bands'
import { Bonus } from './Bonus'
import { General } from './General'

const routes = [
  {
    url: ROUTES.SETTINGS.COMPENSATION.GENERAL,
    path: ROUTES.SETTINGS.COMPENSATION.GENERAL,
    component: General,
    title: 'General',
    canView: [PermissionTypes.ViewTotalCompensationPreferences],
  },
  {
    url: ROUTES.SETTINGS.COMPENSATION.BANDS,
    path: ROUTES.SETTINGS.COMPENSATION.BANDS,
    component: Bands,
    title: 'Compensation bands',
    canView: [PermissionTypes.ViewTotalCompensationPreferences],
  },
  {
    url: ROUTES.SETTINGS.COMPENSATION.BONUS,
    path: ROUTES.SETTINGS.COMPENSATION.BONUS,
    component: Bonus,
    title: 'Bonus',
    canView: [PermissionTypes.ViewTotalCompensationPreferences],
  },
]

export const CompensationSettings = () => {
  return (
    <SettingsForm
      api={compensationSettingsRequests}
      multiInputFields={['bonus_categories']}
      routes={routes}
      subtitle="Settings for the compensation functionalities"
      title="Compensation app settings"
    />
  )
}
