import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import { PerformanceSelector, ReviewCategory } from '@src/interfaces/performance'
import React, { Dispatch, SetStateAction } from 'react'
import SummaryWidget from '@src/pages/EmployeeProfile/Preview/Performance/Summary/SummaryWidget'
import { AnytimeFeedbackListInterface } from '@src/interfaces/anytimeFeedback'

interface Props {
  data: EmployeeInterface
  stats: EmployeeStats | undefined
  selectedPeriod: PerformanceSelector
  periodBeforeOngoing?: PerformanceSelector
  setPerformanceLink: Dispatch<SetStateAction<string | undefined>>
  feedback?: AnytimeFeedbackListInterface
  refetchFeedbackList: () => void
}

export const PerformanceSummarySection = ({
  data,
  stats,
  selectedPeriod,
  periodBeforeOngoing,
  setPerformanceLink,
  feedback,
  refetchFeedbackList,
}: Props) => {
  const cycleId = selectedPeriod?.id

  return (
    <SummaryWidget
      cycleId={cycleId !== undefined ? String(cycleId) : undefined}
      cycleName={selectedPeriod?.name}
      category={ReviewCategory.Performance}
      employee={data}
      stats={stats}
      selectedPeriod={selectedPeriod}
      periodBeforeOngoing={periodBeforeOngoing}
      setPerformanceLink={setPerformanceLink}
      feedback={feedback}
      refetchFeedbackList={refetchFeedbackList}
    />
  )
}
