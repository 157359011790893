import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import isNumber from 'lodash/isNumber'
import {
  Cell,
  InputGroup,
  MoreBar,
  Subheader,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SelectTemplatesSidebar } from '@src/apps/People/Documents/BulkRequest/Edit/components/SelectTemplatesSidebar'
import { TemplateItem } from '@src/apps/People/Documents/BulkRequest/Edit/components/TemplateItem'
import { pathToUrl } from '@src/utils/router'
import { TaskSidebar } from './components/TaskSidebar'
import { TaskItem } from './components/TaskItem'

const EmptyCellContent = () => (
  <Text color={Token.color.greyTone50} variant="h6">
    Nothing selected yet
  </Text>
)

export const General = () => {
  const params = useParams<{ id?: string }>()
  const { values } = useLapeContext<EmployeeOnboardingTemplateInterface>()
  const [taskId, setTaskId] = useState<number>()
  const [taskSideBarOpen, setTaskSiderOpen] = useState(false)
  const [templatesSidebar, setTemplatesSidebar] = useState<'esignature' | 'request'>()

  const requestedTemplates =
    values?.document_templates?.filter(
      template => template.template_type.id === 'request',
    ) || []
  const signedTemplates =
    values?.document_templates?.filter(
      template => template.template_type.id === 'esignature',
    ) || []

  const handleDelete = (templateId: number) => {
    const newData = values.document_templates.filter(t => t.id !== templateId)
    values.document_templates = newData
  }

  return (
    <>
      <PageHeader
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.PREVIEW, params)
            : ROUTES.SETTINGS.EMPLOYEES.ONBOARDING
        }
        title={params.id ? values.name : 'New onboarding template'}
      />
      <PageBody>
        <VStack gap="s-32">
          <InputGroup>
            <Subheader>
              <Subheader.Title>How should the template be called?</Subheader.Title>
            </Subheader>
            <LapeNewInput name="name" label="Template name" required />
          </InputGroup>
          <Text color={Token.color.greyTone50} variant="h6">
            Documents to be requested
          </Text>
          <Cell>
            <VStack space="s-16" width="100%">
              {!!requestedTemplates.length && (
                <VStack>
                  {requestedTemplates.map(template => (
                    <TemplateItem
                      canPreview
                      handleDelete={() => handleDelete(template.id)}
                      key={template.id}
                      template={template}
                    />
                  ))}
                </VStack>
              )}
              {!requestedTemplates.length && <EmptyCellContent />}
              <MoreBar>
                <MoreBar.Action
                  onClick={() => setTemplatesSidebar('request')}
                  useIcon="Plus"
                >
                  Select new document
                </MoreBar.Action>
              </MoreBar>
            </VStack>
          </Cell>

          <Text color={Token.color.greyTone50} variant="h6">
            Documents to be signed
          </Text>
          <Cell>
            <VStack space="s-16" width="100%">
              {!!signedTemplates.length && (
                <VStack>
                  {signedTemplates.map(template => (
                    <TemplateItem
                      canPreview
                      handleDelete={() => handleDelete(template.id)}
                      key={template.id}
                      template={template}
                    />
                  ))}
                </VStack>
              )}
              {!signedTemplates.length && <EmptyCellContent />}

              <MoreBar>
                <MoreBar.Action
                  onClick={() => setTemplatesSidebar('esignature')}
                  useIcon="Plus"
                >
                  Select new template
                </MoreBar.Action>
              </MoreBar>
            </VStack>
          </Cell>

          <Text color={Token.color.greyTone50} variant="h6">
            Tasks
          </Text>
          <Cell>
            <VStack space="s-16" width="100%">
              {!!values.task_definitions?.length && (
                <VStack>
                  {values.task_definitions.map((task, index) => (
                    <TaskItem
                      handleDelete={() => {
                        const newData = values.task_definitions.filter(
                          (_, i) => i !== index,
                        )
                        values.task_definitions = newData
                      }}
                      handleClick={() => {
                        setTaskId(index)
                        setTaskSiderOpen(true)
                      }}
                      key={index}
                      task={task}
                    />
                  ))}
                </VStack>
              )}
              {!values.task_definitions?.length && <EmptyCellContent />}
              <MoreBar>
                <MoreBar.Action onClick={() => setTaskSiderOpen(true)} useIcon="Plus">
                  Add task
                </MoreBar.Action>
              </MoreBar>
            </VStack>
          </Cell>
        </VStack>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          hideWhenNoChanges={false}
          successText="Onboarding template saved successfully"
          previewUrl={ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.PREVIEW}
          useValidator
        />
      </PageActions>

      {!!templatesSidebar && (
        <SelectTemplatesSidebar
          allowCreate={templatesSidebar === 'request'}
          attachedTemplates={values.document_templates}
          filterByTypes={[templatesSidebar]}
          isOpen={!!templatesSidebar}
          onAddTemplate={newTemplate => {
            values.document_templates = [
              ...(values.document_templates ?? []),
              newTemplate,
            ]
          }}
          onClose={() => setTemplatesSidebar(undefined)}
        />
      )}
      <TaskSidebar
        isOpen={taskSideBarOpen}
        onClose={() => {
          setTaskSiderOpen(false)
          setTaskId(undefined)
        }}
        onAdd={newTask => {
          values.task_definitions = [...(values.task_definitions ?? []), newTask]
        }}
        onEdit={newData => {
          if (isNumber(taskId)) {
            values.task_definitions[taskId] = newData
          }
        }}
        task={isNumber(taskId) ? values.task_definitions[taskId] : undefined}
      />
    </>
  )
}
