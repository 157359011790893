import { Color } from '@revolut/ui-kit'

export const NON_ANSWERED_KEY = "I don't know"
export const SCORE_KEYS = ['5', '4', '3', '2', '1', NON_ANSWERED_KEY]

export const mapScoreToColor = {
  1: Color.RED,
  2: Color.RED,
  3: Color.YELLOW,
  4: Color.GREEN,
  5: Color.GREEN,
  [NON_ANSWERED_KEY]: Color.GREY_20,
}
