import { ActionButton, Box, Details, Text, Token, VStack } from '@revolut/ui-kit'
import { getProbationTemplates } from '@src/api/probationTemplate'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  probationManagersRulesColumn,
  probationTemplateCheckpointsColumn,
  probationTemplateNameColumn,
  probationTemplatePolicyColumn,
  probationTemplateStatusColumn,
} from '@src/constants/columns/probation'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { ProbationTemplateInterface } from '@src/interfaces/probationTemplate'
import { pathToUrl } from '@src/utils/router'
import React, { ChangeEvent, useState } from 'react'
import { CollapsibleRadioSwitch } from '@src/pages/Settings/common/CollapsibleRadioSwitch'
import { Managers } from '@src/pages/Settings/Performance/Managers'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

export const ProbationSettings = ({ addTemplateLink }: { addTemplateLink: string }) => {
  const { values, submit } = useLapeContext<PerformanceSettingsInterface>()
  const [disableQuery, setDisableQuery] = useState(!values.enable_probation)
  const table = useTable<ProbationTemplateInterface>(
    { getItems: getProbationTemplates },
    undefined,
    undefined,
    { disableQuery, disable: disableQuery },
  )
  const row: RowInterface<ProbationTemplateInterface> = {
    cells: [
      {
        ...probationTemplateNameColumn,
        width: 200,
      },
      {
        ...probationTemplateCheckpointsColumn,
        width: 50,
      },
      {
        ...probationTemplatePolicyColumn,
        width: 50,
      },
      {
        ...probationManagersRulesColumn,
        width: 150,
      },
      {
        ...probationTemplateStatusColumn,
        width: 80,
      },
    ],
  }

  const handleProbationSwitch = async (e: ChangeEvent<HTMLInputElement>) => {
    values.enable_probation = e.target.checked
    if (!e.target.checked) {
      values.enforce_probation_committee_review = false
    }
    await submit()
    setDisableQuery(!values.enable_probation)
  }
  return (
    <>
      <CollapsibleRadioSwitch
        itemTypeProps={{
          title: 'Enable probation',
          description: 'Allows newly onboarded employees to go trough a probation period',
        }}
        name="enable_probation"
        onChange={handleProbationSwitch}
      >
        <Box>
          <Managers type="probation" />

          <Details style={{ paddingBottom: 0, paddingTop: Token.space.s32 }}>
            <Details.Title pl="s-16">
              <VStack>
                <Text variant="primary" color={Token.color.foreground}>
                  Define probation templates
                </Text>
                <Text variant="caption" color={Token.color.greyTone50}>
                  New probation cycles can be started based on pre-defined templates.
                </Text>
              </VStack>
            </Details.Title>

            <Details.Content pr="s-16">
              <ActionButton
                use={InternalLink}
                to={pathToUrl(addTemplateLink)}
                useIcon="Plus"
              >
                Add new template
              </ActionButton>
            </Details.Content>
            <Details.Note pb={0}>
              <AdjustableTable
                hideCount
                name={TableNames.ProbationTemplates}
                row={row}
                noDataMessage="Probation templates will appear here."
                {...table}
                onRowClick={data =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.PROBATION.TEMPLATE_VIEW, { id: data.id }),
                  )
                }
              />
            </Details.Note>
          </Details>
        </Box>
      </CollapsibleRadioSwitch>
    </>
  )
}
