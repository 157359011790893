import React, { useMemo } from 'react'
import { EntityTypes } from '@src/constants/api'
import {
  analyticsDashboardNameColumn,
  analyticsDashboardRelatedKpisColumn,
  analyticsDashboardOwnerColumn,
  analyticsDashboardRatingColumn,
  analyticsDashboardSourceColumn,
  analyticsDashboardRelatedGoalsColumn,
} from '@src/constants/columns/analyticsDashboard'
import { GenericAnalyticsDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { TeamInterface } from '@src/interfaces/teams'
import { AnalyticsDashboardsTab } from '@src/features/AnalyticsDashboardsTab'
import { OrderingDataType } from '@src/interfaces/ordering'
import { useIsNewTable } from '@components/TableV2/hooks'

interface Props {
  data: TeamInterface
}

const getRow = (
  isNewTable?: boolean,
): RowInterface<GenericAnalyticsDashboardInterface> => ({
  cells: [
    {
      ...analyticsDashboardNameColumn,
      width: 200,
    },
    {
      ...analyticsDashboardRelatedKpisColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRelatedGoalsColumn,
      width: isNewTable ? 100 : 300,
    },
    {
      ...analyticsDashboardRatingColumn,
      width: 40,
    },
    {
      ...analyticsDashboardSourceColumn,
      width: 40,
    },
    {
      ...analyticsDashboardOwnerColumn,
      width: 80,
    },
  ],
})

export const AnalyticsDashboards = ({ data }: Props) => {
  const isNewTable = useIsNewTable()
  const row = useMemo(() => getRow(isNewTable), [isNewTable])
  const initialFilter = [
    {
      filters: [
        {
          name: OrderingDataType.Teams,
          id: OrderingDataType.Teams,
        },
      ],
      columnName: 'parent_type',
      nonResettable: true,
    },
    {
      filters: [{ name: data.name, id: data.id }],
      columnName: 'parent_id',
      nonResettable: true,
    },
  ]

  const initialSortBy = [
    {
      sortBy: 'position_number',
      direction: SORT_DIRECTION.DESC,
      nonResettable: true,
    },
  ]

  return (
    <AnalyticsDashboardsTab
      data={data}
      entity={EntityTypes.team}
      row={row}
      initialFilter={initialFilter}
      initialSort={initialSortBy}
    />
  )
}
