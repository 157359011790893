import React, { useContext } from 'react'
import { connect } from 'lape'
import { useLocation } from 'react-router-dom'
import { chain, Text } from '@revolut/ui-kit'
import {
  PageHeader,
  PageHeaderProps,
  PageHeaderRouterAction,
} from '@components/Page/Header/PageHeader'
import { KPIFormLocationState, KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Status } from '@components/CommonSC/General'
import {
  TargetsApprovalsContext,
  TargetsApprovalsValue,
} from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import { InternalUIKitLink } from '@src/components/InternalLink/InternalLink'
import { useQuery } from '@src/utils/queryParamsHooks'
import {
  KPIQueryParams,
  useIsApprovalsEnabled,
} from '@src/pages/Forms/KpiForm/common/common'
import startCase from 'lodash/startCase'
import upperFirst from 'lodash/upperFirst'
import styled from 'styled-components'

interface Props extends Omit<PageHeaderProps, 'title' | 'backUrl'> {
  isNewInstance: boolean
}

const RawStatus = styled(Status)`
  text-transform: none;
`

const getTitleByQuery = (query: KPIQueryParams) => {
  switch (query.type) {
    case UpdateTypes.sql:
      return 'Create auto-update KPI'
    case 'inherit':
      return 'Cascade a KPI'
    default:
      return `Create ${startCase(query.type)} KPI`
  }
}

export const getBackUrl = (
  data: KpiInterface,
  locationState: KPIFormLocationState | null,
): string => {
  if (locationState?.backUrl) {
    return locationState.backUrl
  }
  if (data.team) {
    return pathToUrl(ROUTES.FORMS.TEAM.KPI, { id: data.team.id })
  }
  if (data.department) {
    return pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, { id: data.department.id })
  }
  if (data.is_employee && data.owner) {
    return pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.KPI.PERSONAL, {
      id: data.owner.id,
    })
  }
  if (data.function) {
    return pathToUrl(ROUTES.FORMS.FUNCTION.KPI, { id: data.function.id })
  }
  if (data.role) {
    return pathToUrl(ROUTES.FORMS.ROLE.KPI, { id: data.role.id })
  }
  return ROUTES.FORMS.COMPANY.KPI.GOALS
}

const Header = ({ isNewInstance, ...props }: Props) => {
  const { query } = useQuery<KPIQueryParams>()
  const location = useLocation<KPIFormLocationState>()
  const { values, initialValues } = useLapeContext<KpiInterface>()
  const { status } = useContext<TargetsApprovalsValue>(TargetsApprovalsContext)

  const approvalsEnabled = useIsApprovalsEnabled()

  let title: React.ReactNode
  let additionalInfo: React.ReactNode | null = null

  if (!isNewInstance) {
    title = chain(
      values.name,
      status && approvalsEnabled ? (
        <RawStatus status={status}>{upperFirst(status).replaceAll('_', ' ')}</RawStatus>
      ) : null,
    )
  } else if (query.type) {
    title = getTitleByQuery(query)
  } else {
    title = `New ${values.kpi_type?.name} KPI`
  }

  if (initialValues.team?.name) {
    additionalInfo = (
      <InternalUIKitLink
        color="inherit"
        // @ts-expect-error object works fine here, but UI kit expects string
        to={pathToUrl(ROUTES.FORMS.TEAM.KPI, { id: initialValues.team.id })}
      >
        {initialValues.team.name}
      </InternalUIKitLink>
    )
  } else if (initialValues.department?.name) {
    additionalInfo = (
      <InternalUIKitLink
        color="inherit"
        // @ts-expect-error object works fine here, but UI kit expects string
        to={pathToUrl(ROUTES.FORMS.DEPARTMENT.KPI, {
          id: initialValues.department.id,
        })}
      >
        {initialValues.department.name}
      </InternalUIKitLink>
    )
  }

  const subTitle = (
    <>
      {additionalInfo && (
        <Text color="foreground" use="p" fontSize="primary" lineHeight="22px">
          {additionalInfo}
        </Text>
      )}
      {initialValues.owner && (
        <InternalUIKitLink
          color="inherit"
          // @ts-expect-error object works fine here, but UI kit expects string
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, {
            id: initialValues.owner.id,
          })}
        >
          {initialValues.owner.name}
        </InternalUIKitLink>
      )}
    </>
  )

  return (
    <PageHeader
      variant="narrow"
      noWrap={false}
      title={title}
      backUrl={getBackUrl(values, location.state)}
      subtitle={subTitle}
      routerAction={
        isNewInstance ? PageHeaderRouterAction.navigate : PageHeaderRouterAction.goBack
      }
      {...props}
    />
  )
}

export default connect(Header)
