import React from 'react'
import { InputGroup, Skeleton } from '@revolut/ui-kit'

export const InputSkeleton = () => <Skeleton height={56} borderRadius={12} />

const InputsSkeleton = () => {
  return (
    <InputGroup>
      <InputSkeleton />
      <InputSkeleton />
    </InputGroup>
  )
}

export default InputsSkeleton
