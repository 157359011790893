import { EmployeeInterface } from '@src/interfaces/employees'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

export enum KPICategoryExtraKeyWord {
  Team = 'team',
  Department = 'department',
  Employee = 'personal',
}

export enum SetKPICategoryExtra {
  Team = 'team_kpi_to_assign',
  Department = 'department_kpi_to_assign',
  Employee = 'personal_kpi_to_assign',
}

export enum ApproveKPICategoryExtra {
  Team = 'team_kpi_to_approve',
  Department = 'department_kpi_to_approve',
}

export interface KPIsToApproveNotification {
  id: 4
  recipient: EmployeeInterface
  status: { id: string; name: string }
  team: { id: number; name: string }
  description: string
  deadline: string
  created_date_time: string
  updated_date_time: string
  review_cycle: ReviewCyclesInterface
  approval_step: { id: string; name: string }
}
