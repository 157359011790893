export enum BannerSeverity {
  info = 'info',
  warning = 'warning',
  major = 'major',
  critical = 'critical',
}

export interface BannerInterface {
  id?: string
  title: string
  message: string
  severity: BannerSeverity
}
