import React from 'react'
import { matchPath, useLocation, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Button } from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'

interface NextButtonProps {
  tabs: TabBarTableNavigationInterface[]
  disabled?: boolean
}

export const NextButton = connect(({ tabs, disabled = false }: NextButtonProps) => {
  const location = useLocation()
  const params = useParams<{ id?: string }>()

  const currentTabIndex = tabs.findIndex((tab: TabBarTableNavigationInterface) =>
    matchPath(location.pathname, tab.path!),
  )
  const isLastTab = currentTabIndex >= tabs.length - 1

  if (isLastTab) {
    return null
  }

  const handleNext = () => {
    navigateReplace(
      pathToUrl(tabs[currentTabIndex + 1].path as string, params) + location.search,
      location,
      true,
    )
  }

  return (
    <Button elevated onClick={handleNext} disabled={disabled}>
      Next
    </Button>
  )
})
