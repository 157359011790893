import { Flex, Token, Text, Circle, Icon, Box, Tag, Image } from '@revolut/ui-kit'
import {
  FinalGradeInterface,
  PerformanceType,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import React, { ReactNode } from 'react'
import { formatDate } from '@src/utils/format'

interface Props {
  type: PerformanceType | undefined
  actionsElements: ReactNode
  reviewsElements: ReactNode
  finalGrade: FinalGradeInterface | undefined
  publishedResults: SingleTimelineEventInterface[]
  isEmptyReviewers: boolean
  eventStartDate?: string
  eventEndDate?: string
  isClosedCycle?: boolean
  isManualCycle?: boolean
}

interface EmptyTimelineElementProps {
  type?: PerformanceType
  isClosedCycle?: boolean
  eventStartDate?: string
  eventEndDate?: string
  isManualCycle?: boolean
}

export const EmptyTimelineElement = ({
  type,
  eventStartDate,
  eventEndDate,
  isClosedCycle,
  isManualCycle,
}: EmptyTimelineElementProps) => {
  if (type && ['review', 'calibration'].includes(type)) {
    return (
      <Flex pr="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
        <Flex gap="s-16">
          <Circle bg={Token.color.greyTone10} size={40}>
            <Icon name="16/SandWatch" color={Token.color.onAccent} size={24} />
          </Circle>
          <Flex flexDirection="column" justifyContent="center" gap="s-2">
            {isClosedCycle ? (
              <Text whiteSpace="nowrap" variant="emphasis1">
                {type === 'review' ? 'Performance review' : 'Calibration'} has no data
              </Text>
            ) : (
              <Text whiteSpace="nowrap" variant="emphasis1">
                {type === 'review' ? 'Performance review' : 'Calibration'} has no reviews
                yet
              </Text>
            )}
            {eventStartDate && !isClosedCycle && !isManualCycle && (
              <Flex alignItems="center" mt="s-8" gap="s-4">
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  Start date: {formatDate(eventStartDate, 'dd MMM yyyy')}
                </Text>
              </Flex>
            )}
            {eventEndDate && !isClosedCycle && !isManualCycle && (
              <Flex alignItems="center" mt="s-8" gap="s-4">
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  End date: {formatDate(eventEndDate, 'dd MMM yyyy')}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex flex="1" justifyContent="space-between">
      <Flex gap="s-8">
        <Image
          size={50}
          image={{
            default: `https://assets.revolut.com/assets/3d-images-v2/3D257.png`,
            '2x': `https://assets.revolut.com/assets/3d-images-v2/3D257@2x.png`,
            '3x': `https://assets.revolut.com/assets/3d-images-v2/3D257@3x.png`,
          }}
        />
        <Flex gap="s-2" flexDirection="column">
          <Text whiteSpace="nowrap" variant="emphasis1">
            Something went wrong
          </Text>
          <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
            We don’t have data for this event
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export const PerfomanceStepDetails = ({
  type,
  actionsElements,
  reviewsElements,
  finalGrade,
  publishedResults,
  isEmptyReviewers,
  eventStartDate,
  eventEndDate,
  isClosedCycle,
  isManualCycle,
}: Props) => {
  const renderContent = () => {
    if (type === 'review_results') {
      return (
        <Flex pr="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
          <Flex gap="s-16">
            {finalGrade ? (
              <Circle bg={Token.color.success} size={40}>
                <Icon name="Trophy" color={Token.color.onAccent} size={24} />
              </Circle>
            ) : (
              <Circle bg={Token.color.greyTone10} size={40}>
                <Icon name="16/SandWatch" color={Token.color.onAccent} size={24} />
              </Circle>
            )}
            <Flex gap="s-2" justifyContent="center" flexDirection="column">
              <Text whiteSpace="nowrap" variant="emphasis1">
                {finalGrade
                  ? finalGrade.label
                  : isClosedCycle
                  ? 'There is no grade for this cycle'
                  : 'Grade not yet published'}
              </Text>
              {finalGrade && (
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  Achieved grade - {finalGrade.label}
                </Text>
              )}
              {!isClosedCycle &&
                !isManualCycle &&
                publishedResults.map(result => {
                  return (
                    <Flex alignItems="center" mt="s-8" gap="s-4" key={result.title}>
                      <Text
                        color={Token.color.greyTone50}
                        whiteSpace="nowrap"
                        variant="body2"
                      >
                        {result.title}: {formatDate(result.start_period, 'dd MMM yyyy')}
                      </Text>
                    </Flex>
                  )
                })}
            </Flex>
          </Flex>

          {finalGrade && (
            <Tag variant="outlined" color={Token.color.success} useIcon="Check">
              Completed
            </Tag>
          )}
        </Flex>
      )
    }
    if (type && ['kpi', 'goal', 'review', 'calibration'].includes(type)) {
      if (isEmptyReviewers) {
        return reviewsElements ? (
          <Box px="s-16" width="100%">
            {reviewsElements}
          </Box>
        ) : (
          <EmptyTimelineElement
            isManualCycle={isManualCycle}
            isClosedCycle={isClosedCycle}
            type={type}
            eventStartDate={eventStartDate}
            eventEndDate={eventEndDate}
          />
        )
      }
      return (
        <Box width="100%">
          <Flex flexDirection="column" gap="s-6">
            {reviewsElements}
          </Flex>
        </Box>
      )
    }
    return (
      <Flex pr="s-20" style={{ flexGrow: 1 }} justifyContent="space-between">
        <Flex gap="s-16">
          <Circle bg={Token.color.greyTone10} size={40}>
            <Icon name="Megaphone" color={Token.color.accent} size={24} />
          </Circle>
          <Flex gap="s-2" flexDirection="column">
            <Text whiteSpace="nowrap" variant="emphasis1">
              Nominate for promotion
            </Text>
            <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
              Line managers and Functional managers can nominate
            </Text>
          </Flex>
        </Flex>
        {actionsElements}
      </Flex>
    )
  }

  return (
    <Flex style={{ flexGrow: 1 }} justifyContent="space-between">
      {renderContent()}
    </Flex>
  )
}
