import React, { useMemo } from 'react'
import { Text } from '@revolut/ui-kit'
import { useGetNotificationStats } from '@src/api/notifications'
import {
  commonDashboardSubtabs,
  NotificationsContextProvider,
} from '@src/apps/General/Todo/common'
import { APPLICATIONS } from '@src/constants/hub'
import HubApp from '@src/features/HubApp/HubApp'
import { HubAppTabInterface } from '@src/interfaces/hub'
import { useLocation, matchPath } from 'react-router-dom'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const Todo = () => {
  const { data: stats, isLoading, refetch } = useGetNotificationStats()
  const location = useLocation()

  const tabs = useMemo(() => {
    const newTabs: HubAppTabInterface[] = []
    commonDashboardSubtabs.forEach(dashboardSubtab => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      const stat = stats?.[dashboardSubtab.statPath]
      if (stat) {
        newTabs.push({
          quickSummary: <Text>{stat}</Text>,
          ...dashboardSubtab,
          component: () => <dashboardSubtab.component refreshStats={refetch} />,
        })
      }
    })

    /** If no to do's, need to show 1 empty tab  */
    if (!newTabs.length) {
      newTabs.push({
        quickSummary: '0',
        ...commonDashboardSubtabs[0],
      })
    }

    /** If current tab is not found (e.g. all items were closed) - redirect to first tab */
    const match = newTabs.find(
      tab =>
        !!matchPath(location.pathname, {
          path: tab.path,
          exact: true,
        }),
    )
    if (!match) {
      navigateReplace(pathToUrl(commonDashboardSubtabs[0].path))
    }

    return newTabs
  }, [stats])

  return (
    <NotificationsContextProvider>
      <HubApp app={{ ...APPLICATIONS.todo, tabs }} loading={isLoading} />
    </NotificationsContextProvider>
  )
}

export default Todo
