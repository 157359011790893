import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import Form from '@src/features/Form/Form'
import { faqFormRequests } from '@src/api/faq'
import { HelpCenterQuestionPreview } from './Preview'
import { HelpCenterQuestionForm } from './General'

export const HelpCenterQuestion = connect(() => {
  return (
    <PageWrapper>
      <Form api={faqFormRequests}>
        <Switch>
          <Route path={ROUTES.APPS.HELP_CENTER.QUESTION.PREVIEW}>
            <HelpCenterQuestionPreview />
          </Route>
          <Route path={ROUTES.APPS.HELP_CENTER.QUESTION.EDIT}>
            <HelpCenterQuestionForm />
          </Route>
        </Switch>
      </Form>
    </PageWrapper>
  )
})
