import { selectorKeys } from '@src/constants/api'
import React, { useEffect } from 'react'
import { useGetSelectors } from '@src/api/selectors'
import { getCurrentTimezone } from '@src/utils/timezones'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { IdAndName } from '@src/interfaces'
import { useScheduleInterviewContext } from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import { Timezone } from '@src/interfaces/selectors'

type Props = {
  onChangeTimeZone?: (timeZone?: Timezone | null) => void
  disabled?: boolean
}

const TimezoneSelect = ({ disabled, onChangeTimeZone }: Props) => {
  const { setTimeZone } = useScheduleInterviewContext()
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { data: timezones } = useGetSelectors<Timezone>(selectorKeys.timezones)
  const currentTimezoneId = getCurrentTimezone()

  const timeZoneId = values.scheduling_timezone
    ? String(values.scheduling_timezone.id)
    : currentTimezoneId

  useEffect(() => {
    const currentTimezone = timezones?.find(item => item.id === timeZoneId)

    if (currentTimezone) {
      values.scheduling_timezone = currentTimezone
      setTimeZone(values.scheduling_timezone)
    }
  }, [timezones])

  return (
    <LapeRadioSelectInput<IdAndName<string>>
      label="Time zone"
      name="scheduling_timezone"
      selector={selectorKeys.timezones}
      disabled={disabled}
      onAfterChange={val => {
        onChangeTimeZone?.(val)

        if (val) {
          setTimeZone(val)
        }
      }}
      useQuery
    />
  )
}

export default TimezoneSelect
