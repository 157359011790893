import React, { useMemo } from 'react'
import AdjustableTable, { AdjustableTableProps } from '@components/Table/AdjustableTable'
import { CellTypes, ColumnCellInterface, RowInterface } from '@src/interfaces/data'
import {
  TableCellInputProps,
  TableCellInputType,
} from '@components/Inputs/TableCellInput/TableCellInput'
import { WrapperCss } from '@components/Table/AdvancedCells/EditableCell/EditableCell'
import { EditableTableContext } from '@components/Table/EditableTable/common'
import EditableTableCell from '@components/Table/EditableTable/EditableTableCell'
import { Color } from '@revolut/ui-kit'

export interface EditableRowInterface<T, S = {}>
  extends Omit<RowInterface<T, S>, 'cells' | 'isNotNested'> {
  cells: (EditableCellInterface<T> | ColumnCellInterface<T>)[]
}

export interface ChangeInfo {
  fieldValue: any
  fieldDataPath: string
  rowPath: string
  columnTitle: string
}

export interface EditableTableProps<T, S = {}>
  extends Omit<AdjustableTableProps<T, S>, 'row'> {
  row: EditableRowInterface<T>
  onChange: (info: ChangeInfo) => void
  disableFilters?: boolean
  disableSorting?: boolean
  allowChildren?: boolean
}

export interface EditableCellInterface<T>
  extends Omit<ColumnCellInterface<T>, 'type' | 'insert' | 'colors'> {
  inputType: TableCellInputType | 'insert'
  placeholder?: TableCellInputProps['placeholder']
  isHidden?: (data: T) => boolean
  isEditable?: (data: T) => boolean
  notEditableInsert?: (data: T) => JSX.Element
  inactiveEditableInsert?: (data: T) => React.ReactNode
  suffix?: string
  cleanZeroOnFocus?: boolean
  // TODO: remove it if ordering works
  getFieldDataPath?: (data: T) => string
  insert?: (props: {
    onChange: (fieldValue: any) => void
    data: T
    content: any
  }) => JSX.Element
  colors?: (data: T) => Color
}

export type EditableColumnInterface<T> = Omit<EditableCellInterface<T>, 'width'>

const parseCell = <T,>({
  inputType,
  isHidden = () => false,
  isEditable = () => true,
  notEditableInsert,
  inactiveEditableInsert,
  suffix,
  getFieldDataPath,
  insert,
  cleanZeroOnFocus,
  ...cell
}: EditableCellInterface<T>): ColumnCellInterface<T> => {
  const cellInsert: ColumnCellInterface<T>['insert'] = ({
    data,
    children: content,
    dataPath,
    parentIndexes,
  }) => {
    const fieldDataPath = getFieldDataPath ? getFieldDataPath(data) : dataPath
    const rowPath = parentIndexes.join('.')

    if (isHidden(data)) {
      return null
    }
    if (!isEditable(data)) {
      return notEditableInsert ? notEditableInsert(data) : content
    }
    if (inputType === 'insert') {
      return (
        <EditableTableContext.Consumer>
          {context => (
            <>
              {insert!({
                onChange: fieldValue =>
                  context.onChange({
                    fieldValue,
                    fieldDataPath,
                    rowPath,
                    columnTitle: cell.title,
                  }),
                data,
                content,
              })}
            </>
          )}
        </EditableTableContext.Consumer>
      )
    }
    return (
      <EditableTableCell
        fieldDataPath={fieldDataPath}
        type={inputType}
        suffix={suffix}
        rowPath={rowPath}
        columnTitle={cell.title}
        color={cell.colors?.(data)}
        inactiveEditableInsert={() => inactiveEditableInsert?.(data)}
        cleanZeroOnFocus={cleanZeroOnFocus}
      />
    )
  }
  return {
    wrapperCss: () => WrapperCss,
    notHoverable: true,
    ...cell,
    type: CellTypes.insert,
    insert: cellInsert,
    filterKey: null,
  }
}

const parseRow = <T,>(
  row: EditableRowInterface<T>,
  disableFilters: boolean,
  disableSorting: boolean,
  allowChildren: boolean,
): RowInterface<T> => {
  const cells = row.cells.map(cell => {
    let resultCell: ColumnCellInterface<T> = cell as ColumnCellInterface<T>

    if ((cell as EditableCellInterface<T>).inputType) {
      resultCell = parseCell(cell as EditableCellInterface<T>)
    }
    resultCell = disableFilters ? { ...resultCell, filterKey: null } : resultCell
    return disableSorting ? { ...resultCell, sortKey: null } : resultCell
  })
  return {
    ...row,
    isNotNested: !allowChildren,
    cells,
  }
}

const EditableTable = <T extends { [prop: string]: any; id?: number | string }, S = {}>({
  row,
  onChange,
  disableFilters = false,
  disableSorting = false,
  allowChildren = false,
  ...props
}: EditableTableProps<T, S>) => {
  const parsedRow = parseRow<T>(row, disableFilters, disableSorting, allowChildren)

  const contextValue = useMemo(
    () => ({
      onChange,
      data: props.data,
    }),
    [onChange, props.data],
  )

  return (
    <EditableTableContext.Provider value={contextValue}>
      <AdjustableTable<T, S> {...props} row={parsedRow} />
    </EditableTableContext.Provider>
  )
}

export default EditableTable
