import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { API } from '@src/constants/api'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JiraPreferencesInterface } from '@src/interfaces/integrations'
import { TransitionCollapse, VStack } from '@revolut/ui-kit'

export const GoogleInternalForm = () => {
  const queryClient = useQueryClient()
  const permissions = useSelector(selectPermissions)
  const { values } = useLapeContext<JiraPreferencesInterface>()

  const disableEdit = !permissions.includes(
    PermissionTypes.ChangeGoogleCalendarIntegration,
  )

  return (
    <PageWrapper>
      <PageHeader
        title="Google Calendar integration settings"
        subtitle="Setup Google Calendar"
        backUrl={pathToUrl(ROUTES.FEATURES.INTEGRATIONS)}
      />
      <PageBody mb="s-64">
        <AutoStepper>
          <NewStepperTitle title="General" />
          <VStack space="s-16">
            <LapeNewSwitch
              itemTypeProps={{
                title: 'Enable Google Calendar integration',
                description: 'Enable integration with Google Calendar for all features.',
              }}
              name="enabled"
              disabled={disableEdit}
            />
            <TransitionCollapse in={values.enabled}>
              <LapeNewInput
                required
                name="credentials"
                label="Credentials"
                disabled={disableEdit}
              />
            </TransitionCollapse>
          </VStack>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Settings saved successfully"
          onAfterSubmit={() => {
            queryClient.invalidateQueries(API.GOOGLE_CALENDAR_INTEGRATION_PREFERENCES)
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}
