import { Box, Cell, TabBar, VStack } from '@revolut/ui-kit'
import React, { useEffect, useState } from 'react'
import SectionTitle from '../components/SectionTitle'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { RecommendedLabel } from '../components/Recommended'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ApprovalProcess, PerformanceSettingsInterface } from '@src/interfaces/settings'
import { ApprovalSteps } from '@src/pages/Settings/common/ApprovalSteps'

const ApprovalStepsTabs = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  const [currentTab, setCurrentTab] = useState<keyof typeof tabs>(
    'employee_kpis_approvals',
  )
  const tabs = {
    employee_kpis_approvals: {
      title: 'Employee KPIs',
      approvalProcess: 'employee_kpis_approvals',
      entity: 'Employee KPI',
      field: 'enable_employee_kpi_approvals',
    },
    team_kpis_approvals: {
      title: 'Team KPIs',
      approvalProcess: 'team_kpis_approvals',
      entity: 'Team KPI',
      field: 'enable_team_kpi_approvals',
    },
    department_kpis_approvals: {
      title: 'Department KPIs',
      approvalProcess: 'department_kpis_approvals',
      entity: 'Department KPI',
      field: 'enable_department_kpi_approvals',
    },
    company_kpis_approvals: {
      title: 'Company KPIs',
      approvalProcess: 'company_kpis_approvals',
      entity: 'Company KPI',
      field: 'enable_company_kpi_approvals',
    },
  }
  const selectedTab = tabs[currentTab!]

  return (
    <Cell width="100%">
      <VStack width="100%" space="s-16">
        <TabBar
          value={currentTab}
          onChange={v => v && setCurrentTab(v)}
          variant="segmented"
        >
          {Object.values(tabs).map(tab => (
            <TabBar.Item to={tab.approvalProcess} key={tab.approvalProcess}>
              {tab.title}
            </TabBar.Item>
          ))}
        </TabBar>
        <Box m="-s-16">
          <ApprovalSteps
            formInPopup
            hideSwitch
            switchField={selectedTab.field}
            approvalProcess={selectedTab.approvalProcess as ApprovalProcess}
            entity={selectedTab.entity}
            formRef={values}
          />
        </Box>
      </VStack>
    </Cell>
  )
}

export const Hierarchy = () => {
  const { values } = useLapeContext<PerformanceSettingsInterface>()
  useEffect(() => {
    values.enable_team_kpi_approvals = values.enable_employee_kpi_approvals
    values.enable_department_kpi_approvals = values.enable_employee_kpi_approvals
    values.enable_company_kpi_approvals = values.enable_employee_kpi_approvals
  }, [values.enable_employee_kpi_approvals])

  const showApprovalSteps =
    values.enable_employee_kpi_approvals ||
    values.enable_team_kpi_approvals ||
    values.enable_department_kpi_approvals ||
    values.enable_company_kpi_approvals
  return (
    <VStack space="s-24">
      <SectionTitle
        title="Do you want to enforce parent KPIs and hierarchy?"
        subtitle="Enabling this will require all KPIs to be connected to a higher level parent KPI (e.g. a department KPI set as the parent for a team KPI)."
      />
      <LapeBooleanRadioSwitch
        variant="horizontal"
        yesLabel={<RecommendedLabel label="Yes" />}
        yesDescription="I want to establish clear parent KPIs and a structured hierarchy for better tracking and alignment"
        noDescription="I would like to keep the KPIs independent without a strict parent-child relationship for greater flexibility"
        name="enable_mandatory_parent_kpi"
      />
      <SectionTitle
        title="Do you want to allow KPI setting for the functional organisation?"
        subtitle="If enabled, the KPI feature will be present inside of Functions and Roles"
      />
      <SectionTitle
        title="Do you want KPIs to require approvals?"
        subtitle="If enabled, KPIs will require approvals when they are created or edited"
      />
      <LapeBooleanRadioSwitch
        variant="horizontal"
        yesDescription="I want to approve all KPIs to ensure oversight and control"
        noLabel={<RecommendedLabel label="No" />}
        noDescription="I would like to streamline the process without approvals"
        name="enable_employee_kpi_approvals"
      />

      {showApprovalSteps ? <ApprovalStepsTabs /> : null}
    </VStack>
  )
}
