import React from 'react'
import styled from 'styled-components'
import Tooltip from '@components/Tooltip/Tooltip'
import { Link, Icon, Token } from '@revolut/ui-kit'

interface Props {
  id: string
}

const StyledTooltip = styled(Tooltip)`
  text-align: left;
  justify-content: left;
`

const links = {
  has_adequate_description: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574240327/Adequate+Description',
    tooltip:
      'Checks the KPI description has the recommended structure, and there are at least 50 characters entered for each heading. The recommended structure is: \\n' +
      'Summary (be clear and concise):\\n' +
      'How will it be measured: \\n' +
      'Success criteria or business outcome:',
  },
  goals_did_not_change: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574240336/Goals+have+not+changed',
    tooltip:
      'Checks if initial and target values have not changed. Changing the targets or initial value can seriously affect the outcome of the KPI, changing this can expose people that are trying change their KPIs to perform better. To make sure this test passes please set the targets when the cycle starts and do not alter your KPI until the start of the new cycle.',
  },
  is_stable: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574207548/Is+stable',
    tooltip:
      'Checks if the variations in the results are not to high. High variations in values can point out errors in the measurement process that can be translated into misleading results causing the false impression of good or bad performance. To fix this the measurement technique must be improved. ',
  },
  history_did_not_change: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574240356/History+does+not+change',
    tooltip:
      'Checks if the historic values of the KPIs changes between runs. This can indicate people are cheating by changing their metrics in the past to make their performance look better.  To pass the test make sure the query returns consistent historical data.',
  },
  is_updated_periodically: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574174778/Updated+periodically',
    tooltip:
      'Checks if the result has been updated periodically, last 48 hours for automatic updates and once a week for manual updates. To pass this test automatically is recomended you choose either SQL or rodmap progression KPIs.',
  },
  has_sql_query: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574174787/Has+SQL+query',
    tooltip:
      'Checks if the KPI is updated using a SQL query or not. If there is no SQL query the test will fail. ',
  },
  has_optimal_sql_query: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574240405/Has+Optimal+SQL+Query',
    tooltip: null,
  },
  query_returns_time_series: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574207568/Query+returns+time+series',
    tooltip:
      'Checks if the query returns a single value or time series. To pass this test make sure the query returns the result as time series, whch means multiple rows with two columns one for date and time and one for the values (ideally a flotting point number). ',
  },
  has_12_months_history: {
    link: 'https://revolut.atlassian.net/wiki/spaces/REV/pages/1574240387/Has+12+months+history',
    tooltip:
      'Checks if there are at least 12 data points in the past, covering a time frame of 12 months. This can be achieved by making sure the SQL query returns a time series result with at least 12 months time frame. ',
  },
}

const QualityLink = ({ id }: Props) => {
  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  if (!links?.[id]) {
    return <Icon name="Link" size={16} color={Token.color.greyTone20} />
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  if (links?.[id].tooltip) {
    return (
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      <StyledTooltip text={links?.[id].tooltip} placement="right">
        {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
        <Link href={links?.[id].link} target="_blank" rel="noreferrer noopener">
          <Icon size={16} color={Token.color.blue} />
        </Link>
      </StyledTooltip>
    )
  }

  return (
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    <Link href={links?.[id].link} target="_blank" rel="noreferrer noopener">
      <Icon size={16} color={Token.color.blue} />
    </Link>
  )
}

export default QualityLink
