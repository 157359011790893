import React from 'react'
import { useGetEmployee } from '@src/api/employees'
import { Link } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { BaseHeaderProps } from '@src/pages/Forms/AssignKPIs/common'

const EmployeeHeader = ({ id }: BaseHeaderProps) => {
  const { data: employee, isLoading, isError } = useGetEmployee(id)
  return (
    <PageHeader title="Assign KPIs" backUrl="/">
      <Link
        href={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id })}
        target="_blank"
        color="inherit"
      >
        <UserWithAvatar
          {...(employee || {})}
          status={undefined}
          isLoading={isLoading || isError}
          mb="28px"
        />
      </Link>
    </PageHeader>
  )
}

export default EmployeeHeader
