import React from 'react'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Token, Box } from '@revolut/ui-kit'
import { Route, Switch } from 'react-router-dom'
import { OffersTable } from '@src/pages/Forms/OfferForms/OffersTable/OffersTable'
import { OfferPlaceholders } from '@src/pages/Forms/OfferForms/OfferPlaceholders/OfferPlaceholders'
import { OfferTemplates } from '@src/pages/Forms/OfferForms/OfferTemplates/OfferTemplates'
import { PermissionTypes } from '@src/store/auth/types'

export const OffersPage = () => {
  const tabs = [
    {
      id: 'offers',
      path: ROUTES.FORMS.OFFER_FORMS.OFFERS,
      to: ROUTES.FORMS.OFFER_FORMS.OFFERS,
      title: 'Offers',
      component: OffersTable,
      permission: PermissionTypes.ViewOfferForm,
    },
    {
      id: 'offer_placeholders',
      path: ROUTES.FORMS.OFFER_FORMS.OFFER_PLACEHOLDERS,
      to: ROUTES.FORMS.OFFER_FORMS.OFFER_PLACEHOLDERS,
      title: 'Offer placeholders',
      component: OfferPlaceholders,
      permission: PermissionTypes.ViewOfferFormTemplate,
    },
    {
      id: 'offer_templates',
      path: ROUTES.FORMS.OFFER_FORMS.OFFER_TEMPLATES,
      to: ROUTES.FORMS.OFFER_FORMS.OFFER_TEMPLATES,
      title: 'Offer templates',
      component: OfferTemplates,
      permission: PermissionTypes.ViewOfferFormTemplate,
    },
  ]
  return (
    <PageWrapper>
      <PageHeader title="Offers" backUrl={pathToUrl(ROUTES.RECRUITMENT.CANDIDATES)}>
        <Box pb="s-8" maxWidth={Token.breakpoint.xl}>
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>
      <PageBody maxWidth="100%">
        <Switch>
          {tabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component />
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
}
