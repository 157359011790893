import React, { useState } from 'react'
import ActionWidget, { ActionWidgetProps } from '@components/ActionWidget/ActionWidget'
import { ActionButton, Link, StatusPopup, Text } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import { approveProbationGoals } from '@src/api/probationReview'
import { useParams } from 'react-router-dom'
import { ProbationCheckpoint } from '@src/interfaces/probationReview'
import { PermissionTypes } from '@src/store/auth/types'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { PIP_PLAYBOOK, PROBATION_PLAYBOOK } from '@src/constants/externalLinks'
import { approvePipGoals } from '@src/api/pip'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export interface PendingGoalsBannerProps extends ActionWidgetProps {
  employee: EmployeeInterface
  checkpoint: ProbationCheckpoint
  refetch: () => Promise<any>
  variant: 'Pip' | 'Probation'
}

const ApproveGoalsWidget = ({
  checkpoint,
  employee,
  refetch,
  variant,
  ...props
}: PendingGoalsBannerProps) => {
  const { cycleId } = useParams<{ cycleId: string }>()
  const [isApproving, setIsApproving] = useState<boolean>()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>()
  const isCommercial = useIsCommercial()
  const showStatusPopup = useShowStatusPopup()

  const canApprove = checkpoint.user_permissions?.includes(
    PermissionTypes.ApproveProbationCheckpoint,
  )

  return (
    <>
      <ActionWidget
        title="Goals pending review by HR manager"
        text={
          <Text>
            {variant} goals needs to be reviewed and checked if they are set to the
            standards
            {!isCommercial && (
              <Text>
                {' '}
                described in this{' '}
                <Link
                  target="_blank"
                  href={variant === 'Probation' ? PROBATION_PLAYBOOK : PIP_PLAYBOOK}
                >
                  playbook
                </Link>
              </Text>
            )}
            .{' '}
            {canApprove && (
              <Text>
                If you are not happy with the goals, please talk with the Line manager of
                this employee: {employee.line_manager.full_name}
              </Text>
            )}
          </Text>
        }
        {...props}
      >
        {canApprove && (
          <ActionButton
            pending={isApproving}
            onClick={() => {
              setIsApproving(true)
              if (variant === 'Probation') {
                approveProbationGoals(employee.id, cycleId, checkpoint.id)
                  .then(refetch)
                  .catch(er => {
                    showStatusPopup({
                      title: 'Goals not approved',
                      description: getStringMessageFromError(er),
                      status: 'error',
                    })
                  })
                  .finally(() => setIsApproving(false))
              } else {
                approvePipGoals(employee.id, checkpoint.id, cycleId)
                  .then(refetch)
                  .catch(er => {
                    showStatusPopup({
                      title: 'Goals not approved',
                      description: getStringMessageFromError(er),
                      status: 'error',
                    })
                  })
                  .finally(() => setIsApproving(false))
              }
            }}
          >
            {variant === 'Probation' ? 'Mark as reviewed' : 'Approve & Initiate PIP'}
          </ActionButton>
        )}
      </ActionWidget>

      <StatusPopup
        variant="success"
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>{variant} goals approved</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default ApproveGoalsWidget
