import { useSelector } from 'react-redux'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { notReachable } from '@src/utils/notReachable'
import { EntityTypes } from '@src/constants/api'
import { selectUser } from '@src/store/auth/selectors'

export const useOwnerId = () => {
  const user = useSelector(selectUser)
  const { entity } = useOrgEntity()

  const fallbackOwnerId = user.id

  if (!entity) {
    return fallbackOwnerId
  }

  switch (entity.type) {
    case EntityTypes.employee:
    case EntityTypes.employees:
      return entity.data.id
    case EntityTypes.team:
    case EntityTypes.teams:
      return entity.data.team_owner.id
    case EntityTypes.department:
      return entity.data.owner?.id ?? fallbackOwnerId
    case EntityTypes.company:
    case EntityTypes.companyV2:
    case EntityTypes.function:
    case EntityTypes.role:
    case EntityTypes.specialisation:
      return fallbackOwnerId
    default:
      return notReachable(entity)
  }
}
