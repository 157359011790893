import React from 'react'
import { connect } from 'lape'
import {
  Box,
  HStack,
  Radio,
  RadioGroup,
  Tag,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { Time } from '@revolut/icons'
import { ReportInterface, ReportTargetTypes } from '@src/interfaces/dataAnalytics'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const TargetForm = connect(() => {
  const { values } = useLapeContext<ReportInterface>()

  return (
    <PageBody>
      <AutoStepper>
        <NewStepperTitle noAutoStep title="Select a report type" />
        <Widget p="s-16">
          <RadioGroup<ReportTargetTypes>
            onChange={value => {
              if (value) {
                values.report_type = value
              }
            }}
            value={values?.report_type}
          >
            {group => (
              <VStack gap="s-16">
                <Radio {...group.getInputProps({ value: 'download' })}>
                  <Radio.Label>A download-only report</Radio.Label>
                  <Radio.Description>
                    Allows a user to download the report output from the reports tab of
                    the Reporting App
                  </Radio.Description>
                </Radio>
                <Radio {...group.getInputProps({ value: 'ui' })}>
                  <Radio.Label>
                    A UI report to be displayed on a tab in the application
                  </Radio.Label>
                  <Radio.Description>
                    A report whose data will be shown in a tab in the application. e.g. A
                    team customer satisfaction report
                  </Radio.Description>
                </Radio>
              </VStack>
            )}
          </RadioGroup>
        </Widget>
        <Widget mt="s-16" p="s-16">
          <HStack space="s-16">
            <Radio disabled>
              <Radio.Label>A data delivery report</Radio.Label>
              <Radio.Description>
                Push data to a third party or a vendor so that they can provide you with
                bespoke services
              </Radio.Description>
            </Radio>
            <Box flex="1 1">
              <Tag color={Token.color.greyTone20} useIcon={Time}>
                Coming soon
              </Tag>
            </Box>
          </HStack>
        </Widget>
      </AutoStepper>
    </PageBody>
  )
})
