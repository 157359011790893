import React from 'react'
import { Popup } from '@revolut/ui-kit'
import { OnboardingChecklistRoute } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'

type Props = {
  open: boolean
  onClose: () => void
  routes?: OnboardingChecklistRoute[]
}

export const SetupGuide = ({ routes, open, onClose }: Props) => {
  if (!routes) {
    return null
  }
  return (
    <Popup open={open} onClose={onClose} size="lg">
      {routes[0].component}
    </Popup>
  )
}
