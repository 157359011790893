import { HiringProcessSettingsInterface } from '@src/interfaces/settings'

export const getHiringSectionIndexes = (
  isScreenCall: boolean,
  settings?: HiringProcessSettingsInterface,
  customSections?: Array<unknown>,
) => {
  let predefinedSectionsLength = 0

  if (isScreenCall) {
    if (settings?.enable_compensation_collecting) {
      predefinedSectionsLength += 1
    }

    if (settings?.enable_location_collecting) {
      predefinedSectionsLength += 1
    }

    if (settings?.enable_right_to_work_collecting) {
      predefinedSectionsLength += 1
    }
  }

  let globalSectionsLength = isScreenCall ? settings?.global_sections?.length || 0 : 0
  let customSectionsLength = customSections?.length || 0
  const summaryIndex = predefinedSectionsLength + customSectionsLength + 1

  return {
    predefinedSectionsLength,
    globalSectionsLength,
    customSectionsLength,
    summaryIndex,
  }
}
