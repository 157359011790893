import React, { useState } from 'react'
import styled from 'styled-components'
import { DeliverableJustificationPreview } from '@src/interfaces/performance'
import { DeliverablesJustificationsView } from '@components/ScorecardGeneral/DeliverablesJustificationsView'
import { BottomSheet, Token } from '@revolut/ui-kit'

const CustomDialog = styled(BottomSheet)`
  padding: 24px;
  max-width: 550px;
  max-height: 70vh;
  overflow: scroll;
`

interface Props {
  data: DeliverableJustificationPreview[]
}

const Wrapper = styled.div`
  color: ${Token.color.blue};
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const SummaryJustificationsPopup = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Wrapper
        onClick={() => {
          setIsOpen(true)
        }}
      >
        View justification comments
      </Wrapper>
      <CustomDialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <DeliverablesJustificationsView viewJustifications={data} />
      </CustomDialog>
    </>
  )
}
