import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import { engagementSurveyFormRequests } from '@src/api/engagement'
import Form from '@src/features/Form/Form'
import { SurveyForm } from './General'
import { Preview } from './Preview'

export const EngagementSurvey = connect(() => (
  <Form api={engagementSurveyFormRequests}>
    <Switch>
      <Route path={ROUTES.APPS.ENGAGEMENT.SURVEY.EDIT}>
        <SurveyForm />
      </Route>
      <Route path={ROUTES.APPS.ENGAGEMENT.SURVEY.ANY}>
        <Preview />
      </Route>
    </Switch>
  </Form>
))
