import React from 'react'
import { MoreBar, Dropdown } from '@revolut/ui-kit'

import { EmployeeButtonProps } from '../../types'
import { GetMagicLinkPopup } from './GetMagicLinkPopup'
import { useEmployeeMagicLinkAction } from './hooks'
import { useCanViewGetMagicLink } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'

type GetMagicLinkActionProps = EmployeeButtonProps & {
  onClick: () => void
  pending: boolean
}
export const GetMagicLinkAction = ({
  data,
  isDropdownItem,
  onClick,
  pending,
}: GetMagicLinkActionProps) => {
  const canView = useCanViewGetMagicLink({ data })

  if (!canView) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item use="button" onClick={onClick} useIcon="Cleaning" disabled={pending}>
        Get magic link
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action onClick={onClick} useIcon="Cleaning" pending={pending}>
      Get magic link
    </MoreBar.Action>
  )
}

export const GetMagicLink = ({ data }: EmployeeButtonProps) => {
  const { link, showPopup, setShowPopup, onClick, pending } = useEmployeeMagicLinkAction({
    employee: data,
  })
  return (
    <>
      <GetMagicLinkAction data={data} onClick={onClick} pending={pending} />
      <GetMagicLinkPopup
        link={link}
        open={showPopup}
        onClose={() => setShowPopup(false)}
      />
    </>
  )
}
