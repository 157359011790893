import React, { useEffect, useState } from 'react'
import { Button, useStatusPopup, StatusPopup } from '@revolut/ui-kit'

import { payInvoice, usePayInvoiceState } from '@src/api/plans'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const useRetryPaymentFlow = ({
  onReviewPaymentDetails,
  onSuccessPopupClose,
}: {
  onReviewPaymentDetails?: () => void
  onSuccessPopupClose?: () => void
}) => {
  const [paymentId, setPaymentId] = useState<number>()

  const statusPopup = useStatusPopup()

  const { data: invoiceState, isError } = usePayInvoiceState(undefined, paymentId)

  useEffect(() => {
    if (invoiceState?.status.id === 'failure') {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Payment failed</StatusPopup.Title>
          <StatusPopup.Description>
            There was a problem with your payment method. Please check payment details.
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button
              onClick={() => {
                statusPopup.hide()
                onReviewPaymentDetails?.()
              }}
              elevated
            >
              Review payment details
            </Button>
            <Button onClick={statusPopup.hide} variant="secondary">
              Close
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }

    if (isError) {
      statusPopup.show(
        <StatusPopup variant="error" onClose={statusPopup.hide}>
          <StatusPopup.Title>Payment failed, you were not charged</StatusPopup.Title>
          <StatusPopup.Description>
            Something went wrong on our side. Try again later
          </StatusPopup.Description>
          <StatusPopup.Actions>
            <Button onClick={statusPopup.hide} elevated>
              Got it
            </Button>
          </StatusPopup.Actions>
        </StatusPopup>,
      )
    }

    if (invoiceState?.status.id === 'success') {
      statusPopup.show(
        <StatusPopup variant="success" onClose={onSuccessPopupClose}>
          <StatusPopup.Title>Success</StatusPopup.Title>
          <StatusPopup.Description>
            Your payment was processed successfully
          </StatusPopup.Description>
        </StatusPopup>,
      )
    }
  }, [invoiceState, isError])

  const onRetryPayment = (invoiceId: number) => {
    statusPopup.show(
      <StatusPopup variant="loading" preventUserClose>
        <StatusPopup.Title>Processing payment</StatusPopup.Title>
        <StatusPopup.Description>
          This should take a couple of seconds
        </StatusPopup.Description>
      </StatusPopup>,
    )

    payInvoice(invoiceId)
      .then(response => {
        setPaymentId(response.data.id)
      })
      .catch(err => {
        statusPopup.show(
          <StatusPopup variant="error" onClose={statusPopup.hide}>
            <StatusPopup.Title>Payment failed</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(err)}
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button onClick={statusPopup.hide} elevated variant="secondary">
                Close
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      })
  }

  return { onRetryPayment }
}
