import React from 'react'
import {
  chain,
  Group,
  Text,
  MoreBar,
  Box,
  Subheader,
  VStack,
  Item,
  Token,
} from '@revolut/ui-kit'
import { newTabTo, pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { EngagementDriverInterface } from '@src/interfaces/engagement'
import { archiveEngagementDriver, unarchiveEngagementDriver } from '@src/api/engagement'
import { PageWrapper } from '@components/Page/Page'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { EditButton } from '@src/features/SettingsButtons'
import { mapStatusToColor } from '../helpers'
import { ActionToggleButton } from '../components/ActionToggleButton'
import { formatLastAnswered } from '../helpers'

export const DriverPreview = () => {
  const { initialValues } = useLapeContext<EngagementDriverInterface>()
  const permissions = initialValues.field_options?.permissions || []
  const canEdit = permissions.includes(PermissionTypes.ChangeEngagementDriver)
  const canArchive = permissions.includes(PermissionTypes.ArchiveEngagementDriver)
  const hasConnectedQuestions = !!initialValues.active_questions_count

  const title = chain(
    initialValues.name,
    <Text color={mapStatusToColor(initialValues)}>{initialValues.status?.name}</Text>,
  )

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        subtitle={
          <Text variant="primary" color={Token.color.foreground}>
            Driver
          </Text>
        }
        backUrl={ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE}
      />
      <PageBody>
        {(canEdit || canArchive) && (
          <Box mb="s-24">
            <MoreBar>
              <EditButton
                route={pathToUrl(ROUTES.APPS.ENGAGEMENT.DRIVER.EDIT, {
                  id: initialValues.id,
                })}
                isVisible={canEdit}
              />
              {canArchive && (
                <ActionToggleButton<EngagementDriverInterface>
                  isActive={initialValues.status?.id !== 'archived'}
                  activeButton={{
                    text: 'Archive',
                    icon: 'Archive',
                    action: archiveEngagementDriver,
                    successText: 'Driver successfully archived',
                  }}
                  activeSubmit={{
                    title: 'Are you sure you want to archive this driver?',
                  }}
                  activeWarn={{
                    title:
                      'Archiving driver will cause archiving all related questions and deleting ' +
                      'them from scheduled surveys',
                    subtitle: 'Are you sure you want to continue?',
                  }}
                  inactiveButton={{
                    text: 'Unarchive',
                    icon: 'Unarchive',
                    action: unarchiveEngagementDriver,
                    successText: 'Driver successfully unarchived',
                  }}
                  inactiveSubmit={{
                    title: 'Are you sure you want to unarchive this driver?',
                  }}
                  inactiveWarn={{
                    title:
                      'Unarchiving driver will cause unarchiving all related questions and adding ' +
                      'them to scheduled surveys',
                    subtitle: 'Are you sure you want to continue?',
                  }}
                  showWarn={hasConnectedQuestions}
                />
              )}
            </MoreBar>
          </Box>
        )}
        <FormPreview data={initialValues} title="Driver details">
          <Group>
            <FormPreview.Item<EngagementDriverInterface>
              title="Owner"
              field="owner"
              insert={d => <UserWithAvatar {...d.owner} />}
            />
            <FormPreview.Item title="Created on" type="date" field="creation_date_time" />
            <FormPreview.Item
              title="Last update on"
              type="date"
              field="update_date_time"
            />
            <FormPreview.Details title="Description" field="description" />
          </Group>
          <Box mt="s-16">
            {!!initialValues.questions?.length && (
              <Subheader variant="nested">
                <Subheader.Title>
                  {chain('Related questions', initialValues.questions.length)}
                </Subheader.Title>
              </Subheader>
            )}
            <VStack space="s-16">
              {initialValues.questions?.map(question => (
                <Item
                  key={question.id}
                  use="button"
                  onClick={() =>
                    newTabTo(
                      pathToUrl(ROUTES.APPS.ENGAGEMENT.QUESTION.PREVIEW, {
                        id: question.id,
                      }),
                    )
                  }
                >
                  <Item.Content>
                    <Item.Title>{question.question_text}</Item.Title>
                    <Item.Description>
                      {chain(
                        <Text color={mapStatusToColor(question)}>
                          {question.status.name}
                        </Text>,
                        formatLastAnswered(question.last_answered_on),
                      )}
                    </Item.Description>
                  </Item.Content>
                </Item>
              ))}
            </VStack>
          </Box>
        </FormPreview>
      </PageBody>
    </PageWrapper>
  )
}
