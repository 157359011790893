import React, { useState } from 'react'
import { VStack } from '@revolut/ui-kit'
import { isArray } from 'lodash'

import { PageBody } from '@src/components/Page/PageBody'
import ImportFile from '@src/pages/Forms/ImportData/common/ImportFile'
import {
  importTimeOffBalancesFile,
  getTimeOffBalancesTemplateUrl,
} from '@src/api/importData'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { saveFile } from '@src/utils'
import { HowToUploadGuide } from '@src/features/BulkDataImport/BulkDataImportUploadFile'

interface TimeOffBalancesUploadFileProps {
  afterUploadUrl?: string
}

export const TimeOffBalancesUploadFile = ({
  afterUploadUrl,
}: TimeOffBalancesUploadFileProps) => {
  const [importPending, setImportPending] = useState(false)
  const [downloadTemplatePending, setDownloadTemplatePending] = useState(false)

  const onFileUpload = (fileUpload: File | null) => {
    if (fileUpload) {
      setImportPending(true)
      importTimeOffBalancesFile(fileUpload)
        .then(response => {
          navigateReplace(
            pathToUrl(
              afterUploadUrl || ROUTES.FORMS.IMPORT_DATA.TIME_OFF.BALANCES_SESSION,
              { id: response.data.id },
            ),
          )
        })
        .catch(() => setImportPending(false))
    }
  }

  const onDownloadTemplate = () => {
    setDownloadTemplatePending(true)
    getTimeOffBalancesTemplateUrl()
      .then(details => saveFile(details, 'time-off-balances'))
      .finally(() => setDownloadTemplatePending(false))
  }

  return (
    <PageBody>
      <VStack space="s-16">
        <HowToUploadGuide importName="time off balances" />

        <ImportFile
          importPending={importPending}
          downloadTemplatePending={downloadTemplatePending}
          supportedFormats={['CSV', 'XLSX']}
          onDownloadTemplate={onDownloadTemplate}
          onFileUpload={file => {
            if (isArray(file)) {
              onFileUpload(file[0])
            } else {
              onFileUpload(file)
            }
          }}
        />
      </VStack>
    </PageBody>
  )
}
