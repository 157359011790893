import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import React, { useState } from 'react'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

interface PermissionTransferRevokeDialogueProps {
  isOpen: boolean
  setOpen: (open: boolean) => void
  permission?: PermissionsInterface
  revoke: (id: number) => void
  onAfterSubmit: () => void
}

const PermissionTransferRevokeDialogue = ({
  isOpen,
  setOpen,
  permission,
  onAfterSubmit,
  revoke,
}: PermissionTransferRevokeDialogueProps) => {
  const [pending, setPending] = useState(false)

  const onSubmit = async () => {
    try {
      setPending(true)
      if (permission) {
        await revoke(permission.id)
        pushNotification({
          value: 'Permission was revoked.',
          duration: SUCCESS_DEFAULT_DURATION,
          type: NotificationTypes.success,
        })
        onAfterSubmit()
      }
    } finally {
      setPending(false)
    }
  }

  return (
    <ConfirmationDialog
      open={isOpen}
      onClose={() => setOpen(false)}
      onConfirm={onSubmit}
      loading={pending}
      label="Revoke permission ?"
      yesMessage="Revoke"
      noMessage="Cancel"
      body={`You are revoking ${permission?.permission?.name} for ${permission?.employee?.full_name}.`}
      onReject={() => setOpen(false)}
    />
  )
}

export default PermissionTransferRevokeDialogue
