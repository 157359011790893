import React from 'react'
import { Box, Button, Color, Flex, Text, Token } from '@revolut/ui-kit'
import { Statement, Time } from '@revolut/icons'
import { Link, useLocation } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import SchedulingLinkExpired from '@src/pages/CandidateScheduling/SchedulingLinkExpired'
import Loader from '@components/CommonSC/Loader'
import ConfirmedAppointment from '@src/pages/CandidateScheduling/ConfirmedAppointment'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { useFetchChosenSlot } from '@src/pages/CandidateScheduling/hooks'
import { getCurrentTimezone } from '@src/utils/timezones'

const General = () => {
  const location = useLocation()
  const {
    interview,
    loadingInterview: isLoading,
    loadingSlots,
    chosenSlot,
  } = useFetchChosenSlot()

  if (isLoading || loadingSlots) {
    return <Loader />
  }

  if (!interview) {
    return <SchedulingLinkExpired />
  }

  if (chosenSlot || interview?.scheduling_type === 'custom_scheduling') {
    const date = chosenSlot?.event_start_datetime || interview?.event_date_time
    const timezone = interview?.candidate_timezone?.id || getCurrentTimezone()
    return <ConfirmedAppointment cancel date={date} timeZone={timezone} />
  }

  return (
    <Box mt="s-40" maxWidth={505} color={Token.color.foreground} px="s-16">
      <Box pt={{ all: '0px', lg: '90px' }}>
        <Text variant="h5" color={Token.color.foreground} display="inline-block" pb="s-8">
          Hey {interview.candidate?.full_name},
        </Text>
        <Text display="inline-block" variant="h1">
          Let’s get a suitable date booked for your next interview
        </Text>
        <Box my="s-56">
          <Flex alignItems="center">
            <Statement color={Color.GREY_TONE_50} size={16} />{' '}
            <Text ml="s-8" fontSize="h5">
              Interview:{' '}
              {interview.is_adhoc ? 'Catch-up call' : interview.interview_stage.title}
            </Text>
          </Flex>
          <Flex alignItems="center">
            <Time color={Color.GREY_TONE_50} size={16} />{' '}
            <Text ml="s-8" fontSize="h5" fontWeight={500}>
              Duration:{' '}
              {getDuration(
                interview.duration || interview.interview_stage.duration,
                interview.duration_unit || interview.interview_stage.duration_unit,
              )}
            </Text>
          </Flex>
        </Box>
        <Button
          use={Link}
          elevated
          maxWidth={340}
          // @ts-expect-error object works fine here, but UI kit expects string
          to={{
            ...getLocationDescriptor(
              pathToUrl(PUBLIC_ROUTES.CANDIDATE_SCHEDULING.SELECT),
            ),
            search: location.search,
          }}
        >
          Start
        </Button>
      </Box>
    </Box>
  )
}

export default General
