import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { EntityTypes } from '@src/constants/api'
import { useGetTeam } from '@src/api/teams'
import { useGetDepartment } from '@src/api/department'
import Header from '@src/pages/Forms/ApproveKPIs/Header'
import ApproveKPIsForm from '@src/pages/Forms/ApproveKPIs/ApproveKPIsForm'
import { Flex } from '@revolut/ui-kit'
import Loader from '@components/CommonSC/Loader'
import { useGetKPIsApproveNotification } from '@src/api/kpiNotifications'
import { useGetEntityWeightModes } from '@src/api/kpiWeights'
import { KPIWeightModes } from '@src/interfaces/kpis'

interface Params {
  entity: EntityTypes
  id: string
  notificationId: string
}

const ApproveKPIs = () => {
  const { notificationId, id, entity: entityType } = useParams<Params>()
  const { data: notification, isLoading } = useGetKPIsApproveNotification(
    notificationId,
    entityType,
  )
  const { data: entityWeightModes } = useGetEntityWeightModes(entityType, id)

  const defaultWeightMode = useMemo(() => {
    if (entityWeightModes && notification) {
      const currentCycleWeightMode = entityWeightModes.results.find(
        weightMode => weightMode.review_cycle.id === notification.review_cycle.id,
      )?.weight_mode
      return currentCycleWeightMode || KPIWeightModes.auto
    }
    return null
  }, [notification, entityWeightModes])

  return (
    <PageWrapper>
      <Header
        id={id}
        useGetEntity={entityType === EntityTypes.team ? useGetTeam : useGetDepartment}
        entityType={entityType}
      />
      {notification && !isLoading && defaultWeightMode ? (
        <ApproveKPIsForm
          id={id}
          notification={notification}
          entityType={entityType}
          defaultWeightMode={defaultWeightMode}
        />
      ) : (
        <Flex
          color="grey-tone-50"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="50%"
        >
          <Loader size="massive" />
        </Flex>
      )}
    </PageWrapper>
  )
}

export default ApproveKPIs
