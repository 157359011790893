import {
  TableProps,
  FilterTableType,
  TableFilter,
} from '@src/pages/Forms/CustomFieldsForm/FilterTable/types'
import { FilterByInterface } from '@src/interfaces/data'
import React from 'react'
import { DepartmentsFilterTable } from '@src/pages/Forms/CustomFieldsForm/FilterTable/DepartmentsFilterTable'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { Box } from '@revolut/ui-kit'
import { RolesFilterTable } from '@src/pages/Forms/CustomFieldsForm/FilterTable/RolesFilterTable'
import { RequisitionsFilterTable } from '@src/pages/Forms/CustomFieldsForm/FilterTable/RequisitionsFilterTable'
import { SectionOptions } from '@src/interfaces/customFields'

interface FilterTableProps extends TableProps {
  type: FilterTableType
  filter?: TableFilter | null
}

const titleMap = {
  [SectionOptions.Departments]: 'Departments',
  [SectionOptions.Roles]: 'Roles',
  [SectionOptions.Requisitions]: 'Requisitions',
}

export const FilterTable = ({ type, filter, ...rest }: FilterTableProps) => {
  const initialFilters: FilterByInterface[] = filter
    ? Object.keys(filter).map(key => ({
        columnName: key,
        filters: filter![key].map((prop: string | number) => ({
          id: prop,
          name: `${prop}`,
        })),
      }))
    : []

  return (
    <>
      <NewStepperTitle
        title={titleMap[type]}
        subtitle={`${titleMap[type]} where this field will be included.`}
      />
      <Box mb="s-56">
        {type === SectionOptions.Departments && (
          <DepartmentsFilterTable filter={initialFilters} {...rest} />
        )}
        {type === SectionOptions.Roles && (
          <RolesFilterTable filter={initialFilters} {...rest} />
        )}
        {type === SectionOptions.Requisitions && (
          <RequisitionsFilterTable filter={initialFilters} {...rest} />
        )}
      </Box>
    </>
  )
}
