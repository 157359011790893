import React from 'react'
import { connect } from 'lape'
import { Button, InputGroup, Input, Header, HStack, Popup } from '@revolut/ui-kit'
import {
  ParameterDefaultValue,
  ReportInterface,
  QueryParameterTypes,
} from '@src/interfaces/dataAnalytics'
import { DatePickerInput } from '@src/components/Inputs/DatePickerInput/DatePickerInput'
import { useLapeContext } from '@src/features/Form/LapeForm'

export interface ExecutionParameters {
  [key: string]: ParameterDefaultValue
}

interface Props {
  executionParameters: ExecutionParameters
  isOpen: boolean
  handleClose: (isOpen: boolean) => void
  handleRun: () => void
  requiredParameters: string[]
  setExecutionParameters: React.Dispatch<React.SetStateAction<ExecutionParameters>>
}

export const RequiredParametersPopup = connect(
  ({
    executionParameters,
    isOpen,
    handleClose,
    handleRun,
    requiredParameters,
    setExecutionParameters,
  }: Props) => {
    const { values } = useLapeContext<ReportInterface>()

    return (
      <Popup
        onClose={() => {
          handleClose(false)
        }}
        open={isOpen}
        variant="bottom-sheet"
      >
        <Header variant="item">
          <Header.Title>We are missing some information</Header.Title>
          <Header.Subtitle>
            Please add the information below to run this report
          </Header.Subtitle>
        </Header>
        <InputGroup>
          {requiredParameters.map(parameter => {
            const isDateInput =
              values.query.parameters.find(item => item.name === parameter)?.type ===
              QueryParameterTypes.Date

            return (
              <React.Fragment key={parameter}>
                {isDateInput ? (
                  <DatePickerInput
                    data-name={parameter}
                    label={parameter}
                    onChange={date => {
                      setExecutionParameters(prev => ({
                        ...prev,
                        [parameter]: date,
                      }))
                    }}
                    value={executionParameters[parameter] as Date}
                  />
                ) : (
                  <Input
                    label={parameter}
                    name={parameter}
                    onChange={e => {
                      setExecutionParameters(prev => ({
                        ...prev,
                        [parameter]: e.currentTarget.value || null,
                      }))
                    }}
                    value={executionParameters[parameter] as string}
                  />
                )}
              </React.Fragment>
            )
          })}
        </InputGroup>
        <Popup.Actions>
          <HStack space="s-16">
            <Button
              onClick={() => {
                handleClose(false)
              }}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button onClick={handleRun}>Run Report</Button>
          </HStack>
        </Popup.Actions>
      </Popup>
    )
  },
)
