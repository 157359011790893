import React from 'react'
import styled from 'styled-components'
import RoadmapProgress from '../../ColumnInserts/RoadmapProgress/RoadmapProgress'
import Icon from '../../Icon/Icon'
import { KpiTargetEpics } from '@src/interfaces/kpis'
import { Token, Widget } from '@revolut/ui-kit'

const Container = styled(Widget)`
  width: 100%;
  background: ${Token.color.widgetBackground};
  box-shadow: none;
  display: grid;
  grid-gap: 32px;
  padding: 16px 16px 32px 16px;
`

const Title = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: bold;
  color: ${Token.color.foreground};
`
const CycleName = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: normal;
  color: ${Token.color.greyTone50};
  background: ${Token.color.greyTone20};
  padding: 2px 6px;
  margin: 0 10px;
`
const Legend = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 64px;
  align-content: center;
  justify-content: center;
  color: ${Token.color.greyTone50};
`
const LegendText = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 8px;
`
const Circle = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${props => props.color};
`
const EmptyChart = styled(Widget)`
  background: ${Token.color.widgetBackground};
  box-shadow: none;
  width: 100%;
  height: 156px;
  border-radius: 4px;
  display: grid;
  grid-gap: 8px;
  justify-items: center;
  align-content: center;
  color: ${Token.color.greyTone20};
`

interface Props {
  targets: KpiTargetEpics[]
}

const RoadmapProgressChart = ({ targets }: Props) => {
  let todo = 0
  let progress = 0
  let done = 0
  const currentTarget = targets?.find(
    target => target.review_cycle?.offset && +target.review_cycle.offset === 0,
  )

  if (targets?.length) {
    if (currentTarget?.epics?.length) {
      currentTarget.epics.forEach(epic => {
        todo += epic.todo
        progress += epic.progress
        done += epic.done
      })
      todo /= currentTarget.epics.length
      progress /= currentTarget.epics.length
      done /= currentTarget.epics.length
    }
  }

  const noCurrentEpics = todo === 0 && progress === 0 && done === 0

  if (noCurrentEpics) {
    return (
      <EmptyChart>
        <Icon type="Graph" />
        <div>
          Select <b>Epics</b> above to see the chart.
        </div>
      </EmptyChart>
    )
  }

  return (
    <Container>
      <Title>
        All epics progress <CycleName>{currentTarget?.review_cycle?.name}</CycleName>
      </Title>
      <RoadmapProgress todo={todo} progress={progress} done={done} large />
      <Legend>
        <LegendText>
          <Circle color={Token.color.green_60} />
          Done
        </LegendText>
        <LegendText>
          <Circle color={Token.color.blue} />
          In Progress
        </LegendText>
        <LegendText>
          <Circle color={Token.color.greyTone20} />
          To Do
        </LegendText>
      </Legend>
    </Container>
  )
}

export default RoadmapProgressChart
