import React, { useState } from 'react'
import { MoreBar, StatusPopup } from '@revolut/ui-kit'
import { reopenPromotion, addPromotionComment } from '@src/api/promotions'
import PopupWithComment from '../common/PopupWithComment'

export type ReopenState =
  | {
      openedPopup: null
    }
  | {
      openedPopup: 'status'
      reopenStatus: 'success' | 'error'
    }
  | {
      openedPopup: 'confirm'
      pendingReopen?: boolean
    }

export interface ReopenButtonProps {
  nominationId: string
  employeeId: string
  onSuccess: () => void
}

export const ReopenButton = ({
  nominationId,
  employeeId,
  onSuccess,
}: ReopenButtonProps) => {
  const reopenPopupTitles = {
    success: 'Nomination successfully re-opened',
    error: 'Failed to re-open nomination. Please try again',
  }
  const [state, setState] = useState<ReopenState>({
    openedPopup: null,
  })

  const reopen = async (comment: string) => {
    try {
      setState({ openedPopup: 'confirm', pendingReopen: true })
      if (comment.length) {
        // agreed with BE to ignore failed comment
        addPromotionComment(nominationId, comment).catch(() => {})
      }
      await reopenPromotion({
        nominationId,
        employeeId,
      })
      setState({
        openedPopup: 'status',
        reopenStatus: 'success',
      })
      onSuccess()
    } catch (err) {
      setState({
        openedPopup: 'status',
        reopenStatus: 'error',
      })
    }
  }
  return (
    <>
      <MoreBar.Action onClick={() => setState({ openedPopup: 'confirm' })}>
        Re-open nomination
      </MoreBar.Action>
      {state.openedPopup === 'status' && (
        <StatusPopup
          variant={state.reopenStatus}
          open
          onClose={() => setState({ openedPopup: null })}
          preventUserClose={state.reopenStatus === 'success'}
        >
          <StatusPopup.Title>{reopenPopupTitles[state.reopenStatus]}</StatusPopup.Title>
        </StatusPopup>
      )}
      {state.openedPopup === 'confirm' && (
        <PopupWithComment
          title="Re-open nomination"
          subtitle="Please comment on the reason for this action. This will be added to the nomination comments."
          isOpen
          onSubmit={reopen}
          onClose={() => setState({ openedPopup: null })}
          isSubmitting={!!state.pendingReopen}
        />
      )}
    </>
  )
}
