import LapeNewSwitch from '@src/components/Inputs/LapeFields/LapeNewSwitch'
import React from 'react'
import { GroupWithRequired } from '@components/RequiredItem/RequiredItem'

export const ReviewTypeSettings = () => {
  const items = {
    required: [
      {
        title: 'Manager reviews',
        description:
          'Review feedback from managers are the foundation of the performance result',
      },
      {
        title: 'Self reviews',
        description:
          'Allows the employee to submit a review of their own performance and behaviours',
      },
    ],
    controls: [
      {
        title: 'Peer reviews',
        description: 'Allow peers to review each other',
        name: 'enable_peer_reviews',
      },
      {
        title: 'Upwards reviews',
        description:
          'Direct and functional reports are allowed to submit anonymous reviews of their managers',
        name: 'enable_upwards_reviews',
      },
    ],
  }

  return (
    <GroupWithRequired requiredItems={items.required} highlightRequired={false}>
      {items.controls.map(({ title, description, name }) => (
        <LapeNewSwitch key={title} itemTypeProps={{ title, description }} name={name} />
      ))}
    </GroupWithRequired>
  )
}
