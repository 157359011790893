import React from 'react'
import { IconButton, Token } from '@revolut/ui-kit'

import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'

export const LoginThemeToggle = () => {
  const { loginTheme, setLoginTheme } = useAppTheme()

  const { sendAnalyticsEvent } = useAnalytics()

  const isDark = loginTheme === 'dark'

  return (
    <IconButton
      onClick={() => {
        const mode = isDark ? 'light' : 'dark'
        sendAnalyticsEvent(AnalyticsEvents.change_login_theme, { mode })
        setLoginTheme(mode)
      }}
      useIcon={isDark ? 'Lightbulb' : 'Moon'}
      color={isDark ? Token.color.greyTone50 : Token.color.foreground}
    />
  )
}
