import React from 'react'
import {
  ChangeScorecardInterface,
  ReviewScorecardInterface,
  ScorecardError,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  SelectedFieldInterface,
  updateValueRating,
} from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import set from 'lodash/set'
import { getNormalizedCards, getUpdatedRatingCard } from '@src/utils/performance'
import { CultureValuesCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/CultureValuesCommon'

export interface ValuesProps {
  selectedField: SelectedFieldInterface
}

export const CultureValues = connect(({ selectedField }: ValuesProps) => {
  const { values, errors, submitFailed, fields } =
    useLapeContext<ReviewScorecardInterface>()

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const hasCultureValues = !!values.review_data.culture_values

  const cultureValuesCards = values.review_data?.culture_values?.cards || []

  const cultureSkillsCards = getNormalizedCards(
    values.review_data.culture_skills?.cards || [],
  )

  const valuesCards = hasCultureValues ? cultureValuesCards : cultureSkillsCards
  const card = valuesCards[selectedField.cardIndex]

  if (!card) {
    return null
  }

  const onChange = async ({
    cardIndex,
    sectionIndex,
    optionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_skills.cards.${cardIndex}`
    set(values, `${path}.sections.${sectionIndex}.items.${optionIndex}.value`, value)

    const updatedCard = await getUpdatedRatingCard(
      values.id,
      values.category,
      values.reviewed_employee.id!,
      values.review_data?.culture_skills?.cards?.[cardIndex],
    )
    if (updatedCard) {
      set(values, `${path}.rating`, updatedCard.rating)
    }
  }

  const onChangeCultureValues = async ({
    cardIndex,
    sectionIndex,
    value,
  }: ChangeScorecardInterface) => {
    const path = `review_data.culture_values.cards.${cardIndex}`
    set(values, `${path}.sections.${sectionIndex}.value`, value)

    updateValueRating({ values, path })
  }

  const allErrors = !submitFailed
    ? undefined
    : hasCultureValues
    ? (errors?.review_data?.culture_values?.cards as ScorecardError[])
    : (errors?.review_data?.culture_skills?.cards as ScorecardError[])

  const touched = Object.values(fields).some(state => state.touched)

  return (
    <CultureValuesCommon
      valuesCards={valuesCards as ValueBasedCardInterface[]}
      selectedField={selectedField}
      hasCultureValues={hasCultureValues}
      previousReviews={values.review_data.previous_reviews_by_cycle}
      allErrors={allErrors}
      onChange={onChange}
      onChangeCultureValues={onChangeCultureValues}
      touched={touched}
    />
  )
})
