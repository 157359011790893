import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { CandidateScoringExplanationTableInterface } from '@src/interfaces/interviewTool'
import { Text, Token } from '@revolut/ui-kit'

export const candidateCVScoreColumn: ColumnInterface<CandidateScoringExplanationTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'score',
    dataPoint: 'score',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Score',
    insert: ({ data }) =>
      data.score === 0 ? (
        <Text color={Token.color.greyTone50}>0%</Text>
      ) : (
        <Text color={Token.color.success}>+{data.score}%</Text>
      ),
  }

export const candidateCVScoreTypeColumn: ColumnInterface<CandidateScoringExplanationTableInterface> =
  {
    type: CellTypes.text,
    idPoint: 'type',
    dataPoint: 'type',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Type',
    isBig: true,
  }

export const candidateCVScoreDescriptionColumn: ColumnInterface<CandidateScoringExplanationTableInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'description',
    dataPoint: 'description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Description',
    insert: ({ data }) => (
      <Text color={Token.color.greyTone50} variant="emphasis2">
        {data.matches ? 'Matches the archetype' : "Doesn't match the archetype"}
      </Text>
    ),
  }
