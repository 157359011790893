import {
  CustomFieldsForSectionInterface,
  DynamicGroupIDs,
  SectionOptions,
} from '@src/interfaces/customFields'
import { useState } from 'react'
import { useCustomFields } from '@components/Stepper/NewStepperSectionCustomFields'
import {
  DiversityInterface,
  EMPLOYEE_PROFILE_SUB_SECTIONS,
  EmployeeAboutInterface,
  EmployeeBioInterface,
  EmployeeContactsInterface,
  EmployeeEmergencyContactInterface,
  EmployeeLaptopDelivery,
  PayrollInterface,
} from '@src/interfaces/employees'
import {
  useGetEmployeeAbout,
  useGetEmployeeBankDetails,
  useGetEmployeeBio,
  useGetEmployeeContacts,
  useGetEmployeeDiversity,
  useGetEmployeeEmergencyContact,
  useGetEmployeeLaptopDeliveryAddress,
} from '@src/api/employees'
import { getSectionPermissions } from '@src/pages/EmployeeProfile/Preview/common'
import { SectionData } from '@src/pages/EmployeeProfile/Preview/hooks'

export type EmployeePersonalDetails = {
  sections: {
    widget: SectionData<Pick<EmployeeContactsInterface, 'personal_email'>>
    bio: SectionData<EmployeeBioInterface>
    contacts: SectionData<EmployeeContactsInterface>
    bankDetails: SectionData<PayrollInterface>
    emergencyContact: SectionData<EmployeeEmergencyContactInterface>
    laptopDelivery: SectionData<EmployeeLaptopDelivery>
    about: SectionData<EmployeeAboutInterface>
    diversity: SectionData<DiversityInterface>
  }
  customFields: {
    identification: CustomFieldsForSectionInterface[]
    correspondenceAddress: CustomFieldsForSectionInterface[]
  }
}
export const useEmployeePersonalDetails = (
  employeeId: number | string | undefined,
  dynamicGroups: DynamicGroupIDs,
): EmployeePersonalDetails => {
  const [loadWidgetData, setLoadWidgetData] = useState(false)
  const [loadBioData, setLoadBioData] = useState(false)
  const [loadContactsData, setLoadContactsData] = useState(false)
  const [loadBankDetailsData, setLoadBankDetailsData] = useState(false)
  const [loadEmergencyContactData, setLoadEmergencyContactData] = useState(false)
  const [loadLaptopDeliveryData, setLoadLaptopDeliveryData] = useState(false)
  const [loadAboutData, setLoadAboutData] = useState(false)
  const [loadDiversityData, setLoadDiversityData] = useState(false)

  // Currently we have an "Identification" preview section that consists only of one custom field,
  // so we have to fetch the data beforehand to decide if it should be visible at all.
  // This unoptimized request must be removed as soon as the "Identification"
  // section begin to contain anything except the single custom field
  const identificationCustomFields = useCustomFields({
    sectionId: SectionOptions.EmployeeProfile,
    subSectionId: loadBioData ? EMPLOYEE_PROFILE_SUB_SECTIONS.IDENTIFICATION_DETAILS : '',
    dynamicGroups,
  })
  // the same as above
  const correspondenceAddressCustomFields = useCustomFields({
    sectionId: SectionOptions.EmployeeProfile,
    subSectionId: loadBioData ? EMPLOYEE_PROFILE_SUB_SECTIONS.CORRESPONDENCE_ADDRESS : '',
    dynamicGroups,
  })

  const {
    data: widgetData,
    refetch: refetchWidgetData,
    isRefetching: isWidgetDataRefetching,
    status: widgetDataStatus,
  } = useGetEmployeeContacts(loadWidgetData ? employeeId : undefined, ['personal_email'])

  const {
    data: bioData,
    refetch: refetchBioData,
    isRefetching: isBioDataRefetching,
    status: bioDataStatus,
  } = useGetEmployeeBio(loadBioData ? employeeId : undefined, [
    'legal_sex',
    'languages',
    'marital_status',
    'birth_date',
    'hide_birthday',
    'nationalities',
    'custom_fields',
  ])

  const {
    data: contactsData,
    refetch: refetchContactsData,
    isRefetching: isContactsDataRefetching,
    status: contactsDataStatus,
  } = useGetEmployeeContacts(loadContactsData ? employeeId : undefined, [
    'personal_email',
    'phone_number',
    'address_line_1',
    'address_line_2',
    'address_line_3',
    'post_code',
    'city',
    'county',
    'country',
    'custom_fields',
  ])

  const {
    data: bankDetailsData,
    refetch: refetchBankDetailsData,
    isRefetching: isBankDetailsDataRefetching,
    status: bankDetailsDataStatus,
  } = useGetEmployeeBankDetails(loadBankDetailsData ? employeeId : undefined, [
    'bank_name',
    'account_name',
    'bank_currency',
    'custom_fields',
  ])

  const {
    data: emergencyContactData,
    refetch: refetchEmergencyContactData,
    isRefetching: isEmergencyContactDataRefetching,
    status: emergencyContactDataStatus,
  } = useGetEmployeeEmergencyContact(loadEmergencyContactData ? employeeId : undefined, [
    'emergency_contact_full_name',
    'emergency_contact_relationship',
    'emergency_contact_email',
    'emergency_contact_mobile_phone',
    'custom_fields',
  ])

  const {
    data: laptopDeliveryData,
    refetch: refetchLaptopDeliveryData,
    isRefetching: isLaptopDeliveryDataRefetching,
    status: laptopDeliveryDataStatus,
  } = useGetEmployeeLaptopDeliveryAddress(
    loadLaptopDeliveryData ? employeeId : undefined,
    [
      'laptop_delivery',
      'laptop_delivery_address_line_1',
      'laptop_delivery_address_line_2',
      'laptop_delivery_address_line_3',
      'laptop_delivery_post_code',
      'laptop_delivery_city',
      'laptop_delivery_county',
      'laptop_delivery_country',
      'custom_fields',
    ],
  )

  const {
    data: aboutData,
    refetch: refetchAboutData,
    isRefetching: isAboutDataRefetching,
    status: aboutDataStatus,
  } = useGetEmployeeAbout(loadAboutData ? employeeId : undefined, [
    'about',
    'custom_fields',
  ])

  const {
    data: diversityData,
    refetch: refetchDiversityData,
    isRefetching: isDiversityDataRefetching,
    status: diversityDataStatus,
  } = useGetEmployeeDiversity(loadDiversityData ? employeeId : undefined, [
    'age_range',
    'gender',
    'sexual_orientation',
    'career_types',
    'disabilities',
    'ethnicities',
    'socioeconomic_background',
    'educational_background',
    'custom_fields',
  ])

  return {
    sections: {
      widget: {
        data: widgetData,
        handler: {
          load: () => setLoadWidgetData(true),
          refetch: () => refetchWidgetData(),
          isRefetching: isWidgetDataRefetching,
          status: widgetDataStatus,
          enabled: loadWidgetData,
        },
      },
      bio: {
        data: bioData,
        permissions: getSectionPermissions(true, bioData),
        handler: {
          load: () => setLoadBioData(true),
          refetch: () => refetchBioData(),
          isRefetching: isBioDataRefetching,
          status: bioDataStatus,
          enabled: loadBioData,
        },
      },
      contacts: {
        data: contactsData,
        permissions: getSectionPermissions(true, contactsData),
        handler: {
          load: () => setLoadContactsData(true),
          refetch: () => refetchContactsData(),
          isRefetching: isContactsDataRefetching,
          status: contactsDataStatus,
          enabled: loadContactsData,
        },
      },
      bankDetails: {
        data: bankDetailsData,
        permissions: getSectionPermissions(true, bankDetailsData),
        handler: {
          load: () => setLoadBankDetailsData(true),
          refetch: () => refetchBankDetailsData(),
          isRefetching: isBankDetailsDataRefetching,
          status: bankDetailsDataStatus,
          enabled: loadBankDetailsData,
        },
      },
      emergencyContact: {
        data: emergencyContactData,
        permissions: getSectionPermissions(true, emergencyContactData),
        handler: {
          load: () => setLoadEmergencyContactData(true),
          refetch: () => refetchEmergencyContactData(),
          isRefetching: isEmergencyContactDataRefetching,
          status: emergencyContactDataStatus,
          enabled: loadEmergencyContactData,
        },
      },
      laptopDelivery: {
        data: laptopDeliveryData,
        permissions: getSectionPermissions(true, laptopDeliveryData),
        handler: {
          load: () => setLoadLaptopDeliveryData(true),
          refetch: () => refetchLaptopDeliveryData(),
          isRefetching: isLaptopDeliveryDataRefetching,
          status: laptopDeliveryDataStatus,
          enabled: loadLaptopDeliveryData,
        },
      },
      about: {
        data: aboutData,
        permissions: getSectionPermissions(true, aboutData),
        handler: {
          load: () => setLoadAboutData(true),
          refetch: () => refetchAboutData(),
          isRefetching: isAboutDataRefetching,
          status: aboutDataStatus,
          enabled: loadAboutData,
        },
      },
      diversity: {
        data: diversityData,
        permissions: getSectionPermissions(true, diversityData),
        handler: {
          load: () => setLoadDiversityData(true),
          refetch: () => refetchDiversityData(),
          isRefetching: isDiversityDataRefetching,
          status: diversityDataStatus,
          enabled: loadDiversityData,
        },
      },
    },
    customFields: {
      identification: identificationCustomFields,
      correspondenceAddress: correspondenceAddressCustomFields,
    },
  }
}
