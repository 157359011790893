import React from 'react'
import { API } from '@src/constants/api'
import {
  importBonusesV2BonusCategoryColumn,
  importBonusesV2BonusCurrencyISOCodeColumn,
  importBonusesV2BonusCycleColumn,
  importBonusesV2BonusDateColumn,
  importBonusesV2EmployeeEmailColumn,
  importBonusesV2TargetColumn,
  importBonusesV2TaxabilityColumn,
} from '@src/constants/columns/importBonusesV2'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { RowInterface } from '@src/interfaces/data'
import { ImportBonusesV2Interface } from '@src/interfaces/importBonuses'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { BulkDataUploadV2 } from '@src/features/BulkDataUpload'
import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'

const row = (
  options: CommonGenericEditableTableRowOptions,
): RowInterface<ImportInterface<ImportBonusesV2Interface>> => ({
  cells: [
    {
      ...importBonusesV2EmployeeEmailColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2BonusCycleColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2BonusCategoryColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2TargetColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2BonusCurrencyISOCodeColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2BonusDateColumn(options.onChange),
      width: 200,
    },
    {
      ...importBonusesV2TaxabilityColumn(options.onChange),
      width: 200,
    },
  ],
})

export const ImportBonusesV2 = () => {
  return (
    <BulkDataUploadV2
      apiEndpoint={API.EMPLOYEE_BONUSES_BULK_UPLOAD}
      apiVersion="v2"
      anyRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.ANY}
      importRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.UPLOAD_FILE}
      sessionRoute={ROUTES.FORMS.IMPORT_DATA.BONUSES.REVIEW_DATA}
      category="cash_bonuses_bulk_upload"
      entity="bonus"
      name="bonus"
      row={row}
      tableName={TableNames.UploadCashBonuses}
      header={<PageHeader backUrl={ROUTES.MAIN} title="Import cash bonuses" />}
      hiddenColumns={{ select: true }}
    />
  )
}
