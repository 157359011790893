import React from 'react'
import styled from 'styled-components'
import icons from './iconList'
import { color, ColorProps } from 'styled-system'

export type IconSizes =
  | 'micro'
  | 'tiny'
  | 'small'
  | 'medium'
  | 'big'
  | 'massive'
  | 'x96'
  | 'x120'

const sizesMap: { [key in IconSizes]: string } = {
  micro: '0.6rem', // 9.6px
  tiny: '1rem', // 16px
  small: '1.25rem', // 20px
  medium: '1.5rem', // 24px
  big: '1.75rem', // 28px
  massive: '2.5rem', // 40px
  x96: '6rem', // 96px
  x120: '7.5rem', // 120px
}

const Wrapper = styled.div<{ size: IconSizes; customSize: boolean } & ColorProps>`
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: ${({ size, customSize }) => (customSize ? 'inherit' : sizesMap[size])};
  ${color}
`

export type Icons = keyof typeof icons

export interface IconProps {
  /** Size of the icon */
  size?: IconSizes
  /** Enable to change the size of the icon flag */
  customSize?: boolean
  /** Changes what icon you use */
  type: Icons
  className?: string
  color?: string
}

const Icon = ({ size = 'medium', type, className, customSize, ...props }: IconProps) => {
  const Component = icons[type]
  return (
    <Wrapper
      customSize={!!customSize}
      size={size}
      data-testid={`icon_${type}`}
      {...props}
    >
      <Component className={className} />
    </Wrapper>
  )
}

export default Icon
