import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import React from 'react'
import { useTableReturnType } from '@components/TableV2/hooks'

import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { RowInterface } from '@src/interfaces/data'
import {
  karmaCountColumn,
  karmaCreatedAtColumn,
  karmaIssueIdColumn,
  karmaOwnerColumn,
  karmaSourceColumn,
  karmaSummaryColumn,
  karmaTeamColumn,
} from '@src/constants/columns/karma'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

interface QuarterKarmaTableProps {
  table: useTableReturnType<KarmaInterface, KarmaStatsInterface, {}>
}

export const QuarterKarmaTable = ({ table }: QuarterKarmaTableProps) => {
  const karmaRow: RowInterface<KarmaInterface> = {
    cells: [
      {
        ...karmaCountColumn,
        width: 120,
      },
      {
        ...karmaTeamColumn,
        width: 300,
      },
      {
        ...karmaOwnerColumn,
        width: 200,
      },
      {
        ...karmaSummaryColumn,
        width: 500,
      },
      {
        ...karmaCreatedAtColumn,
        width: 160,
      },
      {
        ...karmaIssueIdColumn,
        width: 120,
      },
      {
        ...karmaSourceColumn,
        width: 120,
      },
    ],
  }

  return (
    <AdjustableTable<KarmaInterface, KarmaStatsInterface>
      name={TableNames.DepartmentKarma}
      row={karmaRow}
      hideCountAndButtonSection
      hideHeader={table.data?.length === 0}
      emptyState={<EmptyTableRaw title="Karma scores will appear here" />}
      dataType="Karma Score"
      {...table}
    />
  )
}
