import React from 'react'
import { connect } from 'lape'
import { InputGroup, Text, VStack, List } from '@revolut/ui-kit'
import {
  GradeCalculationMethods,
  ReviewCyclesInterface,
} from '@src/interfaces/reviewCycles'
import { reviewCyclesRequestsNew } from '@src/api/reviewCycles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import Form from '@src/features/Form/Form'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router'
import { navigateReplace } from '@src/actions/RouterActions'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { getFormTitle } from '@src/pages/Forms/ReviewCycle/GeneralInfoForm'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { GradeCalculationMethodToTitle } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/constants'

const calculatingOptions = [
  'The grade the line manager recommends in the scorecard',
  'The grade the functional manager recommends in the scorecard',
  'A calculation based on both manager’s ratings against the expected rating in the competency matrix.',
  'The average of both manager recommendations and the calculated grade above.',
  'The average above, adjusted for grade trajectory and weighted net individual promoter score (NIPS).',
]

const GradeLogic = connect(() => {
  const { values } = useLapeContext<ReviewCyclesInterface>()
  const { data: performanceSettings } = useGetPerformanceSettings()
  const params = useParams<{ id: string }>()

  const options = [
    {
      value: {
        name: GradeCalculationMethodToTitle[
          GradeCalculationMethods.LineManagerSuggestion
        ],
        id: GradeCalculationMethods.LineManagerSuggestion,
      },
      label: GradeCalculationMethodToTitle[GradeCalculationMethods.LineManagerSuggestion],
    },
    performanceSettings?.enable_functional_management
      ? {
          value: {
            name: GradeCalculationMethodToTitle[
              GradeCalculationMethods.FunctionalManagerSuggestion
            ],
            id: GradeCalculationMethods.FunctionalManagerSuggestion,
          },
          label:
            GradeCalculationMethodToTitle[
              GradeCalculationMethods.FunctionalManagerSuggestion
            ],
        }
      : null,
    {
      value: {
        name: GradeCalculationMethodToTitle[GradeCalculationMethods.CalculatedGrade],
        id: GradeCalculationMethods.CalculatedGrade,
      },
      label: GradeCalculationMethodToTitle[GradeCalculationMethods.CalculatedGrade],
    },
    {
      value: {
        name: GradeCalculationMethodToTitle[
          GradeCalculationMethods.PerformanceGradeSuggestion
        ],
        id: GradeCalculationMethods.PerformanceGradeSuggestion,
      },
      label:
        GradeCalculationMethodToTitle[GradeCalculationMethods.PerformanceGradeSuggestion],
    },
    {
      value: {
        name: GradeCalculationMethodToTitle[
          GradeCalculationMethods.PerformanceExtraGradeSuggestion
        ],
        id: GradeCalculationMethods.PerformanceExtraGradeSuggestion,
      },
      label:
        GradeCalculationMethodToTitle[
          GradeCalculationMethods.PerformanceExtraGradeSuggestion
        ],
    },
  ].filter(Boolean)

  return (
    <PageWrapper>
      <PageHeader
        title={getFormTitle(values)}
        backUrl={pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, params)}
      />

      <>
        <PageBody>
          <VStack space="s-4" mb="s-32">
            <Text variant="primary">
              How should the final grade for an employee be calculated?
            </Text>
            <Text variant="caption" color="grey-tone-50">
              The output of the performance cycle is a final grade for all eligible
              employees included in the review, ranging from Unsatisfactory (1) through to
              Exceptional (5).
            </Text>
            <Text variant="caption" color="grey-tone-50">
              This grade can be calculated the following ways:
              <br />
              <List use="ol">
                {calculatingOptions.map((item, index) => (
                  <Text key={index} variant="caption" color="grey-tone-50">
                    {index + 1}. {item}
                  </Text>
                ))}
              </List>
            </Text>
          </VStack>
          <InputGroup>
            <LapeRadioSelectInput
              name="grade_calculation_method"
              label="Recommended grade logic"
              options={options}
              onChange={option => {
                values.grade_calculation_method = option?.id
              }}
              value={
                options.find(
                  option => option.value.id === values.grade_calculation_method,
                )?.value
              }
              message="This defines how the performance recommended grade is determined for each employee. It will be used as the default input for calibration."
            />
          </InputGroup>
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            onAfterSubmit={data => {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.REVIEW_CYCLES.GENERAL, { id: data.id }),
              )
            }}
            successText={
              values.id ? 'Performance cycle updated' : 'New performance cycle created'
            }
            useValidator
          >
            {values.id ? 'Save changes' : 'Create'}
          </NewSaveButtonWithPopup>
        </PageActions>
      </>
    </PageWrapper>
  )
})

const GradeLogicForm = () => {
  return (
    <Form api={reviewCyclesRequestsNew} disableDataCleanup>
      <GradeLogic />
    </Form>
  )
}

export default GradeLogicForm
