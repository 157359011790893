import React, { useRef, useState } from 'react'
import {
  Box,
  DetailsCell,
  Flex,
  Group,
  Spinner,
  TextButton,
  Token,
  useIntersectViewport,
} from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useGetEmployeeDirectHierarchy } from '@src/api/functions'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { Hierarchy } from './Hierarchy'

type Props = {
  data: EmployeeInterface
}
export const Reports = ({ data }: Props) => {
  const ref = useRef(null)

  const [loadReports, setLoadReports] = useState(false)
  const reports = useGetEmployeeDirectHierarchy(loadReports ? data.id : undefined)

  useIntersectViewport(ref, isIntersecting => {
    if (isIntersecting) {
      setLoadReports(true)
    }
  })

  const isLoading = reports.status === 'loading'

  return (
    <Group ref={ref}>
      <DetailsCell>
        <DetailsCell.Title>Reports</DetailsCell.Title>
        <DetailsCell.Content>
          <TextButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_NEW_LAYOUT.TALENT.HIERARCHY, {
              id: data.id,
            })}
          >
            Show all
          </TextButton>
        </DetailsCell.Content>
      </DetailsCell>
      {isLoading ? (
        <Flex
          height={300}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box mt="-s-40">
            <Spinner size={30} color={Token.color.blue} />
          </Box>
        </Flex>
      ) : (
        <Flex p="s-24" flexDirection="column" alignItems="center" justifyContent="center">
          <Hierarchy node={reports.data} employeeId={data.id} />
        </Flex>
      )}
    </Group>
  )
}
