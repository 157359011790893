import React, { useState } from 'react'
import {
  Box,
  Button,
  Color,
  Header,
  Item,
  MoreBar,
  Popup,
  StatusPopup,
  Text,
} from '@revolut/ui-kit'
import { DocumentsPair, ExclamationTriangle } from '@revolut/icons'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import {
  CompleteStageButton,
  DocumentsPreview,
  findProcessStage,
  SendFollowUpEmailButton,
  SLAProgress,
  TabProps,
  useRouteParams,
} from './common'
import { PageActions } from '@src/components/Page/PageActions'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectUser } from '@src/store/auth/selectors'
import { completeStage } from '@src/api/onboardingEmployees'
import { ProcessStages } from '@src/interfaces/onboarding'
import { ScreeningCheck } from '../../EmployeeProfile/Preview/ProfileSummary/components/ScreeningCheck'

export const DocumentsStage = (props: TabProps) => {
  const { data, stage, updateStage, setOpenedSidebar } = props
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [contractingPending, setContractingPending] = useState(false)
  const [successPopupOpen, setSuccessPopupOpen] = useState(false)

  const user = useSelector(selectUser)
  const params = useRouteParams()

  const showContractCandidate = stage.status.id !== 'completed'

  const screeningStageExists = !!findProcessStage(data, ProcessStages.Screening)

  const onConfirmContracting = () => {
    setContractingPending(true)
    completeStage(data.employee.id, stage.id)
      .then(response => {
        setConfirmationOpen(false)
        setSuccessPopupOpen(true)
        updateStage(response.data)
      })
      .finally(() => {
        setContractingPending(false)
      })
  }

  return (
    <>
      <PageBody mt="s-16">
        <SLAProgress
          elapsedSla={stage.sla}
          sla={stage.stage.sla_in_hours}
          caption="Confirm screening checks have started. Verify upload of requested documents, follow up candidate if necessary and proceed to issue contract once all the crucial documents were uploaded."
          onSidebarOpen={() => props.setOpenedSidebar('deadline')}
        />

        {screeningStageExists ? (
          <Box mb="s-24">
            <ScreeningCheck employeeId={data.employee.id} hideTableRoute />
          </Box>
        ) : null}

        <DocumentsPreview
          employee={data.employee}
          type="requested"
          suffix={
            <Item>
              <MoreBar>
                <SendFollowUpEmailButton
                  onClick={() => setOpenedSidebar('send-follow-up-email')}
                />
                <MoreBar.Action
                  onClick={() =>
                    navigateTo(
                      pathToUrl(ROUTES.FORMS.DOCUMENT_REQUEST.GENERAL, {
                        employeeId: data.employee.id,
                      }),
                      {
                        initialValues: {
                          employee: {
                            id: data.employee.id,
                            name: data.employee.full_name,
                          },
                          issuer: { id: user.id, name: user.full_name },
                        },
                      },
                    )
                  }
                  useIcon={DocumentsPair}
                >
                  Request more documents
                </MoreBar.Action>
              </MoreBar>
            </Item>
          }
        />
      </PageBody>

      {showContractCandidate && (
        <PageActions>
          <CompleteStageButton onClick={() => setConfirmationOpen(true)} {...props} />
        </PageActions>
      )}

      <Popup
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        variant="bottom-sheet"
      >
        <Header variant="bottom-sheet" displayMode="inline">
          <Header.Actions>
            <ExclamationTriangle color={Color.BLUE} size={40} />
          </Header.Actions>
          <Text fontSize="h5">
            Employees cannot receive contracts before providing mandatory documents
          </Text>
          <Header.Subtitle>
            <Box mt="s-16" mb="-s-16">
              Before proceeding with contract issuing and signature, please make sure that
              you comply with the regulations regarding mandatory documentation
            </Box>
          </Header.Subtitle>
        </Header>
        <Popup.Actions horizontal>
          <Button onClick={() => setConfirmationOpen(false)} elevated variant="secondary">
            Cancel
          </Button>
          <Button onClick={onConfirmContracting} elevated pending={contractingPending}>
            Continue
          </Button>
        </Popup.Actions>
      </Popup>

      <StatusPopup
        variant="success"
        open={successPopupOpen}
        onClose={() => {
          setSuccessPopupOpen(false)
          navigateReplace(pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.CONTRACT, params))
        }}
        // @ts-expect-error
        labelButtonClose="Close success popup"
      >
        <StatusPopup.Title>
          Documents stage successfully marked as completed
        </StatusPopup.Title>
      </StatusPopup>
    </>
  )
}
