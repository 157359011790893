import React from 'react'
import { Box, HStack, ProgressCircle, Text, Token, VStack } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'

type Props = {
  points: number
  maxScore: number | null
}

export const SummaryItemProgress = ({ points, maxScore }: Props) => {
  if (maxScore === null) {
    return null
  }

  return (
    <Box
      padding="s-16"
      radius={Token.radius.widget}
      backgroundColor={Token.color.segmentedBackground}
      width="100%"
    >
      <HStack gap="s-16" align="center">
        <ProgressCircle strokeWidth={3} size={44} value={points / maxScore}>
          <ProgressCircle.Text style={{ fontSize: '14px' }}>
            {maxScore ? formatPercentage(points / maxScore) : '0%'}
          </ProgressCircle.Text>
        </ProgressCircle>
        <VStack gap="s-4">
          <Text variant="heading3" use="div" color={Token.color.foreground}>
            {points} of {maxScore}
          </Text>
          <Text variant="body2" use="div" color={Token.color.greyTone50}>
            Total score
          </Text>
        </VStack>
      </HStack>
    </Box>
  )
}
