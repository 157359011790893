import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import General from './General'
import Preview from './Preview'
import { schedulingPolicyShiftsRequests } from '@src/api/attendance'
import Form from '@src/features/Form/Form'

const SchedulingPolicyShift = () => {
  return (
    <Switch>
      <Route
        exact
        path={ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.GENERAL}
        component={General}
      />
      <Route
        exact
        path={ROUTES.FORMS.SCHEDULING_POLICY_SHIFT.PREVIEW}
        component={Preview}
      />
    </Switch>
  )
}

export default connect(() => (
  <Form api={schedulingPolicyShiftsRequests}>
    <SchedulingPolicyShift />
  </Form>
))
