import React, { useEffect, useMemo } from 'react'
import { connect } from 'lape'
import { InputGroup, Text, Token, Widget } from '@revolut/ui-kit'
import {
  paygroupsBulkEdit,
  paygroupRequests,
  usePaygroups,
  usePayCycles,
} from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { IdAndName } from '@src/interfaces'
import { PaygroupInterface } from '@src/interfaces/payroll'
import { PageBody } from '@components/Page/PageBody'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { payrollConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { pathToUrl } from '@src/utils/router'
import OnboardingActions from '../components/OnboardingActions'
import {
  PAY_AUTHORITIES_FREQUENCY_DEFAULT,
  PAY_AUTHORITIES_DATE_DAY_OF_MONTH_DEFAULT,
  REPORT_SUBMISSION_DATE_DAYS_AFTER_CYCLE_ENDS_DEFAULT,
  REPORT_SUBMISSION_DATE_TRIGGER_DEFAULT,
  PAY_DATE_LANDS_ON_WEEKEND,
  PAY_DATE_SCHEDULE,
  CUT_OFF_DATE_TRIGGER_NUMBER_OF_DAYS,
  CUT_OFF_DATE_TRIGGER,
} from './config'

const BulkEditForm = () => {
  const { values } = useLapeContext<PaygroupInterface>()
  const { data: paygroups } = usePaygroups()
  const { data: paycycles } = usePayCycles()

  const { data: monthDaySelectorOptions } = useGetSelectors<IdAndName>(
    selectorKeys.month_days,
  )
  const { data: weekDayOptions = [] } = useGetSelectors<IdAndName>(
    selectorKeys.days_of_the_week,
  )

  const getName = (name: string, isLast: boolean) =>
    `${isLast ? 'Last day' : name} of Month`

  const monthDayOptions = useMemo(
    () =>
      monthDaySelectorOptions?.map((item, i) => ({
        label: getName(item.name, i === monthDaySelectorOptions.length - 1),
        value: {
          id: item.id,
          name: getName(item.name, i === monthDaySelectorOptions.length - 1),
        },
      })) || [],
    [monthDaySelectorOptions],
  )

  const isWeekly = ['weekly', 'bi_weekly'].includes(values?.pay_frequency?.id)

  const findWeekDayById = (value: number | null) =>
    weekDayOptions.find(option => option.id === value) || null
  const findMonthDayById = (value: number | null) =>
    monthDayOptions.find(option => option.value.id === value)?.value || null

  useEffect(() => {
    if (!values.pay_frequency) {
      values.pay_frequency = PAY_AUTHORITIES_FREQUENCY_DEFAULT
    }

    if (!values.pay_authorities_frequency) {
      values.pay_authorities_frequency = PAY_AUTHORITIES_FREQUENCY_DEFAULT
    }

    if (!values.pay_authorities_date_day_of_month) {
      values.pay_authorities_date_day_of_month = PAY_AUTHORITIES_DATE_DAY_OF_MONTH_DEFAULT
    }

    if (!values.report_submission_date_days_after_cycle_ends) {
      values.report_submission_date_days_after_cycle_ends =
        REPORT_SUBMISSION_DATE_DAYS_AFTER_CYCLE_ENDS_DEFAULT
    }

    if (!values.report_submission_date_trigger) {
      values.report_submission_date_trigger = REPORT_SUBMISSION_DATE_TRIGGER_DEFAULT
    }

    if (!values.pay_date_lands_on_weekend) {
      values.pay_date_lands_on_weekend = PAY_DATE_LANDS_ON_WEEKEND
    }

    if (!values.pay_date_schedule) {
      values.pay_date_schedule = PAY_DATE_SCHEDULE
    }

    if (!values.cut_off_date_trigger_number_of_days) {
      values.cut_off_date_trigger_number_of_days = CUT_OFF_DATE_TRIGGER_NUMBER_OF_DAYS
    }

    if (!values.cut_off_date_trigger) {
      values.cut_off_date_trigger = CUT_OFF_DATE_TRIGGER
    }
  }, [])

  useEffect(() => {
    if (values.pay_period_start_day) {
      if (isWeekly) {
        const startDayIndex = weekDayOptions.findIndex(
          option => option.id === values.pay_period_start_day,
        )
        const endDayIndex =
          startDayIndex === 0 ? weekDayOptions.length - 1 : startDayIndex - 1
        values.end_date = weekDayOptions[endDayIndex]?.id
      } else {
        const startDayIndex = monthDayOptions.findIndex(
          option => option.value.id === values.pay_period_start_day,
        )
        const endDayIndex =
          startDayIndex === 0 ? monthDayOptions.length - 1 : startDayIndex - 1
        values.end_date = monthDayOptions[endDayIndex]?.value.id
      }
    }
  }, [isWeekly, values.pay_period_start_day, monthDayOptions, weekDayOptions])

  const prepareData = () => {
    if (!paygroups) {
      return []
    }

    return paygroups.results.map(paygroup => {
      return {
        ...values,
        id: paygroup.id,
      }
    })
  }

  const renderContent = () => {
    return (
      <AutoStepper>
        <Widget pb="s-16" pt="s-4" px="s-16" mt="s-16">
          <NewStepperTitle title="Payroll Schedule" />
          <Text variant="primary">How often do you pay your employees?</Text>
          <Text color={Token.color.greyTone50} my="s-16" variant="h6" use="div">
            Define how often your employees are paid currently
          </Text>
          <LapeRadioSelectInput
            label="Payment schedule"
            name="pay_frequency"
            selector={selectorKeys.pay_frequencies}
          />
          <Text mt="s-16" variant="primary" use="div">
            When does your payroll cycle begin?
          </Text>
          <Text color={Token.color.greyTone50} my="s-16" variant="h6" use="div">
            {`Define from which day of the ${
              isWeekly ? 'week' : 'month'
            } until which day of the ${isWeekly ? 'week' : 'month'} your payroll goes to`}
          </Text>
          <InputGroup variant="horizontal">
            {isWeekly ? (
              <>
                <LapeRadioSelectInput
                  label="Beginning of pay cycle"
                  name="pay_period_start_day"
                  onChange={value => {
                    values.pay_period_start_day = value?.id || null
                    values.pay_date_day = value?.id || null
                  }}
                  selector={selectorKeys.days_of_the_week}
                  value={findWeekDayById(values.pay_period_start_day)}
                />
                <LapeRadioSelectInput
                  disabled
                  label="End of pay cycle"
                  name="end_data"
                  onChange={value => {
                    values.end_date = value?.id || null
                  }}
                  selector={selectorKeys.days_of_the_week}
                  value={findWeekDayById(values.end_date)}
                />
              </>
            ) : (
              <>
                <LapeRadioSelectInput
                  label="Beginning of pay cycle"
                  name="pay_period_start_day"
                  options={monthDayOptions}
                  onChange={value => {
                    values.pay_period_start_day = value?.id || null
                    values.pay_date_day = value?.id || null
                  }}
                  value={findMonthDayById(values.pay_period_start_day)}
                />
                <LapeRadioSelectInput
                  disabled
                  label="End of pay cycle"
                  name="end_data"
                  options={monthDayOptions}
                  onChange={value => {
                    values.end_date = value?.id || null
                  }}
                  value={findMonthDayById(values.end_date)}
                />
              </>
            )}
          </InputGroup>
        </Widget>
      </AutoStepper>
    )
  }

  return (
    <>
      <PageBody>{renderContent()}</PageBody>
      <OnboardingActions
        config={payrollConfig}
        currentStep="Settings"
        disableNext={false}
        onBeforeSubmit={() => paygroupsBulkEdit(prepareData())}
        pendingNext={false}
        nextButtonLabel="Finish"
        nextRoute=""
        isForm={false}
        isLastStep
        updateSteps
        statusPopupAction={
          paycycles?.results[0] && {
            title: 'Go to payroll details',
            route: pathToUrl(ROUTES.APPS.PAYROLL.PAY_CYCLE.REPORTS, {
              id: paycycles.results[0].id,
            }),
          }
        }
      />
    </>
  )
}

export const PayrollSettings = connect(() => (
  <Form api={paygroupRequests}>
    <BulkEditForm />
  </Form>
))
