import React from 'react'
import { useSelector } from 'react-redux'
import { Spacer, Widget } from '@revolut/ui-kit'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

export const LifecycleHrContact = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeLifecycleSettings)

  return (
    <>
      <SectionTitle
        title="When somebody contacts your HR, which email should they use?"
        subtitle="This will be the email account used to send and receive emails from your employees"
      />
      <Widget p="s-16">
        <LapeNewInput
          required
          label="Contact HR email"
          name="contact_hr_email"
          disabled={disableEdit}
        />
      </Widget>

      <Spacer height="s-24" />

      <SectionTitle
        title="What name should appear as the recipient when employees contact this email?"
        subtitle="This is the email your new employees will see in the onboarding portal"
      />
      <Widget p="s-16">
        <LapeNewInput
          required
          label="Contact HR display name"
          name="contact_hr_name"
          disabled={disableEdit}
        />
      </Widget>
    </>
  )
}
