import React from 'react'
import { Props } from 'recharts/types/component/DefaultLegendContent'
import { Box, Flex, Text, Token } from '@revolut/ui-kit'
import { formatMoney } from '@src/utils/format'

interface LegendItemProps {
  color?: string
  currency?: string
  name: string
  value?: any
}

export const LegendItem = ({ color, currency, name, value }: LegendItemProps) => {
  return (
    <Flex flexDirection="column" gap="s-4">
      <Box
        border={`1px solid ${Token.color.greyTone10}`}
        borderRadius={Token.radius.r2}
        bg={color}
        height="s-12"
        width="s-12"
      />
      <Text color={Token.color.greyTone50} fontSize="small">
        {name}
      </Text>
      <Text color={Token.color.greyTone50} fontSize="small">
        {formatMoney(value, currency)}
      </Text>
    </Flex>
  )
}

export const PieLegend = (props: Props & { currency?: string }) => {
  const { currency, payload } = props

  return (
    <Flex gap="s-16" justifyContent="center" pt="s-8" px="s-16">
      {payload?.map(entry => (
        <LegendItem
          key={String(entry.value)}
          color={entry.color}
          currency={currency}
          name={entry.value}
          value={entry.payload?.value}
        />
      ))}
    </Flex>
  )
}
