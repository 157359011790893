import React from 'react'
import { VStack } from '@revolut/ui-kit'
import FunctionsTable from '@src/pages/Organisation/OrganisationSubTabs/Functions/FunctionsTable'
import Message from '../components/Message'

const RolesFunctions = () => {
  return (
    <VStack gap="s-16">
      <Message
        title="We have created functions just for you."
        description="We've put together a list of functions based on your earlier input. Feel free to take a look and make any adjustments to suit your preferences. Employee function relates to the broader purpose or contribution that an employee makes to the organization. It encompasses the tasks, responsibilities, and activities that an employee performs as part of their role to help the organization achieve its objectives."
      />

      <FunctionsTable />
    </VStack>
  )
}

export default RolesFunctions
