export const PAY_AUTHORITIES_FREQUENCY_DEFAULT = {
  id: 'monthly' as const,
  name: 'Monthly',
}
export const PAY_AUTHORITIES_DATE_DAY_OF_MONTH_DEFAULT = 1
export const REPORT_SUBMISSION_DATE_DAYS_AFTER_CYCLE_ENDS_DEFAULT = 0
export const REPORT_SUBMISSION_DATE_TRIGGER_DEFAULT = {
  id: 'before_pay_date' as const,
  name: 'Before the pay date',
}
export const PAY_DATE_LANDS_ON_WEEKEND = {
  id: 'pay_on_the_previous_business_day' as const,
  name: 'Pay on the previous business day',
}
export const PAY_DATE_SCHEDULE = {
  id: 'current_month' as const,
  name: 'Current Month',
}

export const CUT_OFF_DATE_TRIGGER_NUMBER_OF_DAYS = 0
export const CUT_OFF_DATE_TRIGGER = {
  id: 'before_pay_date' as const,
  name: 'Before the pay date',
}
