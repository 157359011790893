import React from 'react'
import { PageBody } from '@src/components/Page/PageBody'
import { FilterByInterface, RowInterface } from '@src/interfaces/data'
import { CountryHiringInterface } from '@src/interfaces/settings'
import {
  countriesCountryHiringColumn,
  countriesHiringLocationColumn,
  countriesHiringEmploymentRiskColumn,
  countriesHiringPreferredSpecialisationsColumn,
  countriesHiringHiringColumn,
  countriesHiringActionsColumn,
} from '@src/constants/columns/countryHiring'
import { useTable } from '@src/components/TableV2/hooks'
import { countryHiringRequests } from '@src/api/settings'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const row: RowInterface<CountryHiringInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING_FORM, { id })),
  cells: [
    { ...countriesCountryHiringColumn, width: 100 },
    { ...countriesHiringLocationColumn, width: 150 },
    { ...countriesHiringEmploymentRiskColumn, width: 100 },
    { ...countriesHiringPreferredSpecialisationsColumn, width: 100 },
    { ...countriesHiringHiringColumn, width: 80 },
    { ...countriesHiringActionsColumn, width: 80 },
  ],
}

export const CountryHiring = () => {
  const initialFilter: FilterByInterface[] = [
    {
      columnName: 'country_has_locations',
      filters: [
        {
          id: 'true',
          name: 'true',
        },
      ],
      nonResettable: true,
    },
  ]
  const table = useTable<CountryHiringInterface>(countryHiringRequests, initialFilter)
  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.SETTINGS.JOBS.LIST}
        title="Hiring countries"
        subtitle="Define your hiring policy for each of locations"
      />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable<CountryHiringInterface>
              name={TableNames.CountryHiring}
              useWindowScroll
              dataType="Hiring country"
              row={row}
              {...table}
              hideCount
              emptyState={<EmptyTableRaw title="Hiring countries will appear here" />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
