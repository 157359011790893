import { API } from '@src/constants/api'
import { api, apiWithoutHandling } from '.'
import { useFetch } from '@src/utils/reactQuery'
import {
  BulkEditItemsResponse,
  BulkEditRequest,
  BulkEditResponse,
  ContractSelectorResponse,
} from '@src/interfaces/bulkEdit'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { groupFiltersIntoURLQuery } from '@src/utils/table'

export const createContractsBulkEdit = (request: BulkEditRequest) => {
  return api.post(API.CONTRACTS_BULK_EDIT, request)
}

export const applyContractsBulkEdit = (id: string | number) => {
  return api.post(`${API.CONTRACTS_BULK_EDIT}/${id}/apply`)
}

export const useBulkEdit = (id: string | number) => {
  return useFetch<BulkEditResponse>(`${API.CONTRACTS_BULK_EDIT}/${id}`)
}

export const getBulkEditItems = (id: string | number) => {
  return api.get<BulkEditItemsResponse>(`${API.CONTRACTS_BULK_EDIT}/${id}/items`)
}

export const useContractsSelector = () => {
  return useFetch<ContractSelectorResponse>(API.CONTRACTS_SELECTOR)
}

export const getFilteredContractsSelector = async (filters: FilterByInterface[]) => {
  return apiWithoutHandling.get<{ options: OptionInterface[] }>(API.CONTRACTS_SELECTOR, {
    params: groupFiltersIntoURLQuery(filters),
  })
}
