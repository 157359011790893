import { useState } from 'react'
import { getPrefilledPlaceholdersEmail } from '@src/api/hiringProcess'

const useGetPrefilledPlaceholdersEmail = () => {
  const [loading, setLoading] = useState(false)
  return {
    loading,
    fetchEmail: (templateId: number | string, candidateId?: number) => {
      setLoading(true)
      return getPrefilledPlaceholdersEmail(templateId, candidateId).finally(() => {
        setLoading(false)
      })
    },
  }
}

export default useGetPrefilledPlaceholdersEmail
