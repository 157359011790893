import React from 'react'
import { connect } from 'lape'
import { useParams } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { employeeTimeOffRequests, useEmployeeTimeOffStats } from '@src/api/timeOff'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

import General from './General'

const EmployeeTimeOffAbsence = () => {
  const params = useParams<{ id: string; employeeId: string }>()
  const stats = useEmployeeTimeOffStats(params.employeeId)

  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.TIME_OFF.REQUESTS, {
    id: params.employeeId,
  })

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title="Record absence"
        subtitle={<UserWithAvatar {...stats.data?.employee} asText mb="s-16" />}
        backUrl={backUrl}
      />

      <General recordAbsenceMode />
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeeTimeOffRequests('absences')} disableLocalStorageCaching>
    <EmployeeTimeOffAbsence />
  </Form>
))
