import Form from '@src/features/Form/Form'
import React from 'react'
import LookerModel from './LookerModel'
import { formLookerAccessRequest } from '@src/api/accessRequests'

const LookerModelRouter = () => {
  return (
    <Form api={formLookerAccessRequest}>
      <LookerModel />
    </Form>
  )
}

export default LookerModelRouter
