import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import React from 'react'
import { EmployeeEmailTemplateInterface } from '@src/interfaces/employeeEmails'
import Table from '@components/TableV2/Table'
import { getDefaultStatusVariant } from '@components/TableV2/Cells/StatusCell'

export const employeeEmailTemplateNameColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.employee_email_templates,
    title: 'Template name',
  }

export const employeeEmailTemplateSubjectColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'subject',
    dataPoint: 'subject',
    sortKey: 'subject',
    filterKey: 'subject',
    selectorsKey: selectorKeys.employee_email_template_subjects,
    title: 'Subject',
  }

export const employeeEmailTemplateOwnerColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'owner.id',
    dataPoint: 'owner.full_name',
    sortKey: 'owner',
    filterKey: 'owner',
    selectorsKey: selectorKeys.employee,
    title: 'Owner',
    insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
  }

export const employeeEmailTemplateCategoryColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'category.id',
    dataPoint: 'category.name',
    sortKey: 'category',
    filterKey: 'category',
    selectorsKey: selectorKeys.employee_email_template_categories,
    title: 'Category',
  }

export const employeeEmailTemplateCreatedDateColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Created on',
  }

export const employeeEmailTemplateStatusColumn: ColumnInterface<EmployeeEmailTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'status',
    filterKey: 'status',
    selectorsKey: selectorKeys.employee_email_template_statuses,
    title: 'Status',
    insert: ({ data }) => (
      <Table.StatusCell
        status={data.status.name}
        variant={getDefaultStatusVariant(data.status.id)}
      />
    ),
  }
