import { Token } from '@revolut/ui-kit'
import { GrowthPlanDecision, GrowthPlanInterface } from '@src/interfaces/growthPlans'

export const getGrowthPlanDecisionColor = (growthPlan: GrowthPlanInterface): string => {
  switch (growthPlan.decision) {
    case GrowthPlanDecision.Completed:
      return Token.color.success
    case GrowthPlanDecision.Missed:
      return Token.color.danger
    default:
      return Token.color.foreground
  }
}

export const growthPlanDecisionToLabelMap: Record<GrowthPlanDecision, string> = {
  [GrowthPlanDecision.Ongoing]: 'Ongoing',
  [GrowthPlanDecision.Missed]: 'Missed',
  [GrowthPlanDecision.Completed]: 'Completed',
}
