import React from 'react'
import { connect } from 'lape'
import { linkedFormRequests } from '@src/api/linkedAccounts'
import Form from '@src/features/Form/Form'
import { AccountForm } from './General'

export default connect(() => {
  return (
    <Form api={linkedFormRequests}>
      <AccountForm />
    </Form>
  )
})
