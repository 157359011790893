import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectUser } from '@src/store/auth/selectors'
import { getUserCountryByLocation } from '@src/api/locations'
import { OptionInterface } from '@src/interfaces/selectors'

export const useUserCountryByLocation = () => {
  const user = useSelector(selectUser)

  const [country, setCountry] = useState<OptionInterface>()

  useEffect(() => {
    // @TODO understand why location could be undefined
    if (user.location?.id) {
      getUserCountryByLocation(user.location.id).then(res => {
        setCountry(res.data.country)
      })
    }
  }, [user.location])

  return country
}
