import React from 'react'
import { Cell, Color, Flex } from '@revolut/ui-kit'

import { useTable } from '@src/components/Table/hooks'
import {
  getScheduleShiftBreakdown,
  getScheduleShiftBreakdownStats,
  getShiftBreakdownExport,
} from '@src/api/attendance'
import { RowInterface } from '@src/interfaces/data'
import { ScheduleShiftBreakdownInterface } from '@src/interfaces/attendance'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import {
  shiftBreakdownApprovalColumn,
  shiftBreakdownCompensationChoiceTypeColumn,
  shiftBreakdownCompensationTypeColumn,
  shiftBreakdownDurationColumn,
  shiftBreakdownTypeColumn,
  shiftSummaryDateColumn,
  shiftSummaryEmployeeColumn,
  shiftSummaryEndColumn,
  shiftSummaryLineManagerColumn,
  shiftSummaryLocationColumn,
  shiftSummaryStartColumn,
  shiftSummaryTeamColumn,
  shiftSummaryUpdatedOnColumn,
} from '@src/constants/columns/attendance'
import Stat from '@src/components/Stat/Stat'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import SettingsButtons from '@src/features/SettingsButtons'
import { filterSortPageIntoQuery } from '@src/utils/table'
import ExportMenu from '@src/features/ExportMenu/ExportMenu'
import { ApprovalStatuses } from '@src/interfaces/approvalFlow'
import { TableNames } from '@src/constants/table'

const Row: RowInterface<ScheduleShiftBreakdownInterface> = {
  linkToForm: data =>
    navigateTo(
      pathToUrl(ROUTES.FORMS.SCHEDULE_SHIFT.PREVIEW, {
        employeeId: data.employee.id,
        id: data.shift_id,
      }),
    ),
  cells: [
    {
      ...shiftSummaryDateColumn,
      width: 150,
    },
    {
      ...shiftSummaryEmployeeColumn,
      width: 200,
    },
    {
      ...shiftSummaryLineManagerColumn,
      width: 70,
    },
    {
      ...shiftSummaryTeamColumn,
      width: 200,
    },
    {
      ...shiftSummaryLocationColumn,
      width: 100,
    },
    {
      ...shiftBreakdownTypeColumn,
      width: 100,
    },
    {
      ...shiftSummaryStartColumn,
      width: 100,
    },
    {
      ...shiftSummaryEndColumn,
      width: 100,
    },
    {
      ...shiftBreakdownDurationColumn,
      width: 100,
    },
    {
      ...shiftBreakdownCompensationChoiceTypeColumn,
      width: 180,
    },
    {
      ...shiftBreakdownCompensationTypeColumn,
      width: 180,
    },
    {
      ...shiftSummaryUpdatedOnColumn,
      width: 150,
    },
    {
      ...shiftBreakdownApprovalColumn,
      width: 150,
    },
  ],
}

const ShiftBreakdown = () => {
  const table = useTable(
    {
      getItems: getScheduleShiftBreakdown,
      getStats: getScheduleShiftBreakdownStats,
    },
    [
      {
        columnName: 'approval_status',
        filters: [
          { id: ApprovalStatuses.Pending, name: ApprovalStatuses.Pending },
          { id: ApprovalStatuses.Approved, name: ApprovalStatuses.Approved },
          { id: ApprovalStatuses.NoStatus, name: ApprovalStatuses.NoStatus },
        ],
      },
    ],
  )

  const filterQuery = filterSortPageIntoQuery(table.sortBy, table.filterBy, 1)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24" gap="s-32">
          <Stat
            label="Shifts"
            val={table?.stats?.total != null ? table.stats.total : undefined}
          />
          <Stat
            label="Pending approval"
            val={table?.stats?.pending != null ? table.stats.pending : undefined}
            color={Color.ORANGE}
          />
          <Stat
            label="Approved"
            val={table?.stats?.approved != null ? table.stats.approved : undefined}
            color={Color.GREEN}
          />
          <Stat
            label="Rejected"
            val={table?.stats?.rejected != null ? table.stats.rejected : undefined}
            color={Color.RED}
          />
        </Flex>

        <SettingsButtons mb="s-16">
          <ExportMenu
            fileName="Shift Breakdowns"
            request={getShiftBreakdownExport}
            filterQuery={filterQuery}
          />
        </SettingsButtons>

        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable
            name={TableNames.ShiftBreakdown}
            row={Row}
            useWindowScroll
            {...table}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default ShiftBreakdown
