import React from 'react'
import { EngagementResultsComment } from '@src/interfaces/engagement'
import {
  Avatar,
  Box,
  chain,
  Circle,
  HStack,
  Item,
  Tag,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'
import { ScoreGrade, scoreToColor, scoreToGrade } from '../../helpers'

const scoreToBgColor = (score: number) => {
  switch (scoreToGrade(score)) {
    case ScoreGrade.LOW:
      return Token.color.red_10
    case ScoreGrade.MID:
      return Token.color.yellow_10
    case ScoreGrade.HIGH:
      return Token.color.green_10
    default:
      return Token.color.foreground
  }
}

const scoreToAvatar = (score: number | null) => {
  const isOpenEnded = score === null

  if (isOpenEnded) {
    return <Avatar useIcon="Paragraph" />
  }
  return (
    <Avatar bg={scoreToBgColor(score)}>
      <Circle size={24} variant="outlined" color={scoreToColor(score)}>
        {score}
      </Circle>
    </Avatar>
  )
}

type Props = {
  comment: EngagementResultsComment
}
export const CommentItem = ({ comment }: Props) => {
  return (
    <Item>
      <Item.Avatar>{scoreToAvatar(comment.answer_score)}</Item.Avatar>
      <Item.Content>
        <Item.Title>Q: {comment.question.question_text}</Item.Title>
        <Item.Description>
          <VStack space="s-4">
            <Box>
              {chain(
                <Text fontSize="primary" color={Token.color.foreground}>
                  A: {comment.answer_text}
                </Text>,
                formatDateTime(comment.creation_date_time),
              )}
            </Box>
            {!!comment.categories?.names?.length && (
              <HStack space="s-4">
                {comment.categories.names.map(name => (
                  <Tag
                    key={name}
                    variant="faded"
                    bg={Token.color.blue_20}
                    color={Token.color.foreground}
                  >
                    {name}
                  </Tag>
                ))}
              </HStack>
            )}
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
