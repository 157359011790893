import React, { useEffect } from 'react'
import { useGetSchedulingCalendars } from '@src/api/recruitment/interviewerScheduling'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

interface CalendarSelectProps {
  roundId: number
}

const CalendarSelect = ({ roundId }: CalendarSelectProps) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const { data, isLoading, isFetched } = useGetSchedulingCalendars(roundId)
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!isFetched || values.calendar) {
      return
    }

    values.calendar = data?.find(item => item.calendar_id === user.email) || null
  }, [isFetched, values.calendar, data])

  return (
    <LapeRadioSelectInput
      name="calendar"
      label="Calendar"
      loading={isLoading}
      labelPath="summary"
      options={
        data?.map(item => ({
          label: item.summary,
          value: item,
        })) || []
      }
    />
  )
}

export default CalendarSelect
