import React from 'react'
import { ActionWidget, InputGroup } from '@revolut/ui-kit'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { goBack, navigateReplace } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewMultiSelect from '@src/components/Inputs/LapeFields/LapeNewMultiSelect'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SchedulingPolicyInterface } from '@src/interfaces/attendance'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'

const General = () => {
  const { values } = useLapeContext<SchedulingPolicyInterface>()
  const configurationCompleted = values.configuration_step?.id === 'completed'

  return (
    <>
      <PageBody>
        {configurationCompleted ? null : (
          <ActionWidget>
            <ActionWidget.Title>Scheduling policy configuration</ActionWidget.Title>
            <ActionWidget.Content>
              In this step you need to define basic characteristics of the scheduling
              policy and its main limitations based on the labour law in the country to
              which this policy applies.
            </ActionWidget.Content>
          </ActionWidget>
        )}
        <AutoStepper>
          <NewStepperTitle title="Basic information" />
          <InputGroup>
            <LapeNewInput name="name" label="Policy name" required />
            <LapeRadioSelectInput
              name="location"
              label="Location"
              selector={selectorKeys.location}
            />
            <LapeNewMultiSelect
              name="eligibility_groups"
              placeholder="Eligibility groups"
              selector={selectorKeys.dynamic_groups}
              required
            />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
            />
            <LapeNewSwitch
              checked={!!values.default_work_time_editable}
              onChange={e => {
                values.default_work_time_editable = e.target.checked
              }}
              itemTypeProps={{ title: 'Allow employee to edit default shift hours' }}
            />
          </InputGroup>

          <NewStepperTitle title="Time characteristics" />
          <InputGroup>
            <LapeNewInput
              name="daily_nominal_hours"
              label="Daily nominal hours"
              required
            />
            <LapeNewInput
              name="weekly_nominal_hours"
              label="Weekly nominal hours"
              required
            />
            <LapeNewInput
              name="consecutive_working_days_limit"
              label="Consecutive working days limit"
              required
            />
            <LapeNewInput
              name="unsocial_time_start"
              label="Unsocial time start"
              required
              type="time"
            />
            <LapeNewInput
              name="unsocial_time_end"
              label="Unsocial time end"
              required
              type="time"
            />
            <LapeNewInput
              name="work_default_start_time"
              label="Work default start time"
              required
              type="time"
            />
            <LapeNewInput
              name="work_default_end_time"
              label="Work default end time"
              required
              type="time"
            />
            <LapeNewSwitch
              checked={!!values.allow_regular_work_on_non_working_days}
              onChange={e => {
                values.allow_regular_work_on_non_working_days = e.target.checked
              }}
              itemTypeProps={{ title: 'Allow regular work on non-working days' }}
            />
          </InputGroup>

          <NewStepperTitle title="Overtime limits" />
          <InputGroup>
            <LapeNewInput
              name="minimum_rest_period"
              label="Minimum rest period in hours"
              type="number"
            />
            <LapeNewInput
              name="maximum_daily_overtime"
              label="Maximum daily overtime (in hours)"
              required
            />
            <LapeNewInput
              name="maximum_weekly_overtime"
              label="Maximum weekly overtime (in hours)"
              required
            />
            <LapeNewInput
              name="maximum_monthly_overtime"
              label="Maximum monthly overtime (in hours)"
              required
            />
            <LapeNewInput
              name="maximum_yearly_overtime"
              label="Maximum yearly overtime (in hours)"
              required
            />
            <LapeRadioSelectInput
              label="Accounting period"
              name="accounting_period"
              selector={selectorKeys.scheduling_policies_accounting_periods}
            />
            <LapeRadioSelectInput
              label="Time-off policy for Time-off in Lieu (TOIL)"
              name="toil_policy"
              selector={selectorKeys.time_off_toil_policies}
              message="Please specify which time-off policy should be incremented if employee choose TOIL as a preferred compensation method for overtime."
            />
            <LapeNewInput
              type="number"
              onClear={() => {
                values.notification_threshold_overtime = undefined
              }}
              min={1}
              max={100}
              name="notification_threshold_overtime"
              label="Notification Trigger (%)"
              message="Specify the percentage of the overtime limit that, when reached, will trigger a notification to the people partner of the location"
            />
          </InputGroup>
        </AutoStepper>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={response => {
            if (configurationCompleted) {
              goBack()
            } else {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.SHIFTS, { id: response.id }),
              )
            }
          }}
          useValidator
          noPopup
        >
          {configurationCompleted ? 'Save changes' : 'Next'}
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}

export default General
