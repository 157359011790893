import { Statuses } from '@src/interfaces/index'
import {
  CommentsPopupDataInterface,
  PerformanceRating,
  ReviewCategory,
  ReviewerRelation,
} from '@src/interfaces/performance'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'

export enum IssueTypes {
  Subtask = 'Subtask',
  Task = 'Task',
  Bug = 'Bug',
  Epic = 'Epic',
  Story = 'Story',
  Other = 'Other',
  SCA = 'SCA',
}

export enum ItemType {
  Clickup = 'clickup',
  Manual = 'manual',
  Jira = 'jira',
}

export enum DeliverableScorecardStatus {
  Pending = 'pending',
  InProgress = 'in_progress',
  Completed = 'completed',
  Ignored = 'ignored',
}

export enum DeliverableCriteriaType {
  Complexity = 'complexity',
  Speed = 'speed',
  Quality = 'quality',
}

export interface DeliverablesReviewer {
  id: number
  full_name: string
  display_name: string
  status: IdStatuses
  avatar?: AvatarType
}

export interface DeliverableQualityItem {
  id: string
  value: PerformanceRating
}

export interface FunctionalDeliverableScorecardInterface {
  comment?: string
  created_date?: string
  id: number
  ignored?: boolean
  functional_issue?: string
  reviewed_employee?: {
    id: number
    full_name: string
    display_name: string
    status: Statuses
  }
  reviewer?: DeliverablesReviewer
  reviewer_relation: ReviewerRelation
  updated_date?: string
  status?: DeliverableScorecardStatus
  user_rating?: PerformanceRating
}

export interface DeliverableScorecardInterface {
  category: ReviewCategory
  comment: string
  complexity: PerformanceRating
  created_date: string
  id: number
  ignored: boolean
  functional_issue: string
  quality: PerformanceRating
  quality_preview: PerformanceRating
  quality_items: DeliverableQualityItem[]
  reviewed_employee: {
    id: number
    full_name: string
    display_name: string
    status: Statuses
  }
  reviewer?: DeliverablesReviewer
  reviewer_relation: ReviewerRelation
  speed: PerformanceRating
  updated_date: string
  is_aggregation: boolean
  status: DeliverableScorecardStatus
  score: number
  probation_checkpoint?: {
    id: number
    number: number
  }
  performance_checkpoint?: {
    id: number
    number: number
    cycle_id: number
  }
  current_user_can_edit_review?: boolean
}

export interface DeliverableQualityCriteria {
  id: string
  name: string
}

export interface DeliverableRating {
  value: PerformanceRating
  review: DeliverableScorecardInterface
  id?: string
}

export interface DeliverableSettingsInterface {
  is_deliverable_review_enabled: boolean
  deliverable_review_quality_criteria: DeliverableQualityCriteria[] | null
  permissions: {
    can_review: boolean
    relation?: ReviewerRelation
  }
}

export interface DeliverableStatsInterface {
  total_count: number
  pending_count: number
  in_progress_count: number
  completed_count: number
  ignored_count: number
}

export interface DeliverableRefreshInterface {
  accepted: boolean
}

export interface DeliverablesAveragesInterface {
  avg_complexity_score: number
  avg_complexity_rating: PerformanceRating
  avg_speed_score: number
  avg_speed_rating: PerformanceRating
  avg_quality_score: number
  avg_quality_rating: PerformanceRating
}

export interface DeliverablesAggregationInterface {
  score: number
  rating: PerformanceRating
  complexity: PerformanceRating
  speed: PerformanceRating
  quality: PerformanceRating
  ignored: boolean
  comment: string
  quality_items: DeliverableQualityItem[]
  quality_preview: PerformanceRating
  locked: boolean
}

export interface CommonDeliverableInterface {
  id: number
  issue_type: IssueTypes
  key: string
  title: string
  name?: string
  description?: string
  status: Statuses
  url: string
  due_date?: string
  deadline_date_time?: string
  resolution_date_time?: string
  cycle?: {
    id: number
    name: string
    start_date_time: string
    end_date_time: string
    offset: number
    reviews: []
  }
  filtered_aggregation?: DeliverablesAggregationInterface
  user_review_status: DeliverableScorecardStatus
  loading?: boolean
}

export interface DeliverableInterface extends CommonDeliverableInterface {
  deliverable_scorecards: DeliverableScorecardInterface[]
}

export interface DeliverableInterfaceRequest {
  results: DeliverableInterface[]
}

export interface FunctionalDeliverableInterface extends CommonDeliverableInterface {
  deliverable_scorecards: FunctionalDeliverableScorecardInterface[]
  loading?: boolean
}

export enum COMMENT_ACTIONS {
  SET_VALUE = 'SET_VALUE',
  SET_OPEN = 'SET_OPEN',
  SET_VIEW_OPEN = 'SET_VIEW_OPEN',
  SET_SCORECARD = 'SET_SCORECARD',
  SET_VIEW_DATA = 'SET_VIEW_DATA',
  SET_PLACEHOLDER = 'SET_PLACEHOLDER',
}

export interface CommentStateInterface {
  open?: boolean
  viewOpen?: boolean
  value?: string
  viewData?: CommentsPopupDataInterface
  scorecard?: DeliverableScorecardInterface
  ticketId?: number
  placeholder?: string
}

export interface CommentActionInterface {
  type: COMMENT_ACTIONS
  payload: CommentStateInterface
}
