import React from 'react'
import { Box, Color, Text, Flex, VStack, Widget, Action } from '@revolut/ui-kit'
import { ReviewCategory } from '@src/interfaces/performance'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import ActionCard from '@components/ActionCard/ActionCard'
import { navigateTo } from '@src/actions/RouterActions'
import { EmployeeGoal } from '@src/interfaces/employees'
import { formatDate } from '@src/utils/format'

type Props = {
  goals?: EmployeeGoal[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
}

const title = {
  [ReviewCategory.Probation]: 'Probation goals',
  [ReviewCategory.PIP_V2]: 'PIP goals',
}

const route = {
  [ReviewCategory.Probation]: ROUTES.FORMS.PROBATION_OVERVIEW.PROBATION_GOALS,
  [ReviewCategory.PIP_V2]: ROUTES.FORMS.PIP.GOAL_SETTINGS,
}

const ProbationGoalsJiraDisabled = ({
  goals,
  employeeId,
  cycleId,
  reviewCategory,
}: Props) => {
  if (!goals?.length) {
    return (
      <Box mt="s-48">
        <ActionCard
          /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
          caption={title[reviewCategory]}
          title="No goals set"
          text="It is mandatory for the line manager to set probation goals before starting to assess the employee"
          actionLabel="Set goals"
          onClickAction={() => {
            navigateTo(
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              pathToUrl(route[reviewCategory], {
                employeeId,
                id: cycleId,
                cycleId,
              }),
            )
          }}
        />
      </Box>
    )
  }

  return (
    <Box mt="s-48">
      <Flex justifyContent="space-between" alignItems="center">
        <Text color={Color.GREY_TONE_50} fontWeight={500}>
          {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
          {title[reviewCategory]}
        </Text>
        <Action
          onClick={() =>
            navigateTo(
              /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              pathToUrl(route[reviewCategory], {
                employeeId,
                id: cycleId,
                cycleId,
              }),
            )
          }
        >
          Edit
        </Action>
      </Flex>

      <Widget p="s-16" mt="s-16">
        <VStack space="s-12">
          {goals.map(goal => (
            <Flex justifyContent="space-between" key={goal.id}>
              <Text variant="secondary" maxWidth="75%">
                {goal.name}
              </Text>
              <Flex>
                {goal.deadline_date_time && (
                  <Text variant="caption" color="grey-tone-50">
                    Due {formatDate(goal.deadline_date_time)}
                  </Text>
                )}
              </Flex>
            </Flex>
          ))}
        </VStack>
      </Widget>
    </Box>
  )
}

export default ProbationGoalsJiraDisabled
