import React from 'react'
import { EditForm } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/EditTimeline/EditForm'
import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'
import TimelineForm from '@src/pages/Forms/ReviewCycle/TimelineForm'

export const EditTimelinePage = () => {
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()

  if (!isTestCycleEnabled) {
    return <TimelineForm />
  }

  return <EditForm />
}
