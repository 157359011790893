import React from 'react'
import { Box } from '@revolut/ui-kit'

import Stat from '@components/Stat/Stat'
import Table from '@components/TableV2/Table'
import {
  candidatesHiringManagerOwnershipTransfer,
  getCandidatesHiringManagerOwnership,
} from '@src/api/ownership'
import {
  candidateOwnershipHiringManagerNameColumn,
  candidateOwnershipNameColumn,
  candidateOwnershipSpecialisationColumn,
} from '@src/constants/columns/ownership'
import { SORT_DIRECTION } from '@src/interfaces/data'
import { EmployeeInterface, EmployeeStats } from '@src/interfaces/employees'
import {
  OwnershipTransferProvider,
  SmallBanner,
  TransferSelections,
  TransferTable,
  TrasferAllButton,
} from './common'

interface Props {
  data: EmployeeInterface
  stats?: EmployeeStats
  refreshStats: () => void
}

const CandidatesHiringManagerOwnership = ({ data, stats, refreshStats }: Props) => {
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]

  const transferApi = (selections: TransferSelections) =>
    candidatesHiringManagerOwnershipTransfer(
      data.id,
      Object.entries(selections).map(([id, selection]) => ({
        id,
        hiring_manager: selection.id,
      })),
    ).then(refreshStats)

  return (
    <OwnershipTransferProvider
      getApi={getCandidatesHiringManagerOwnership(data.id)}
      sortByInitial={sortBy}
      defaultOwner={data.line_manager}
      transferApi={transferApi}
    >
      <SmallBanner
        title="Employee scheduled for termination. Transfer candidates to a new hiring manager"
        hidden={!data.has_termination}
      />
      <Box mb="s-20">
        <Table.Widget>
          <Table.Widget.Info>
            <Stat label="Candidates" val={stats?.hiring_manager_interview_round_count} />
          </Table.Widget.Info>
          <Table.Widget.Actions>
            <TrasferAllButton />
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <TransferTable
              cells={[
                { ...candidateOwnershipNameColumn, width: 200 },
                { ...candidateOwnershipHiringManagerNameColumn, width: 200 },
                { ...candidateOwnershipSpecialisationColumn, width: 200 },
              ]}
              noDataMessage="Candidates hiring managers ownership will appear here"
              ownerColTitle="New hiring manager"
            />
          </Table.Widget.Table>
        </Table.Widget>
      </Box>
    </OwnershipTransferProvider>
  )
}

export default CandidatesHiringManagerOwnership
