import React, { useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { MoreBar } from '@revolut/ui-kit'

import { API } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PermissionTypes } from '@src/store/auth/types'
import { employeeRecordsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { SelectBulkUploadFlow } from '@src/apps/People/Employees/BulkUpload/SelectBulkUploadFlow'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { EditableContractsBulkSession } from '@src/features/EditableContractsTable/BulkSession'
import { EditableEmployeesTableDetailed } from '@src/features/EditableEmployeesTable/TableDetailed'
import { EmployeesBulkSessionDetailed } from '@src/features/EditableEmployeesTable/TableDetailed/BulkSession'
import { EmployeesBulkSessionDynamic } from '@src/features/EditableEmployeesTable/TableDynamic/BulkSession'
import { IS_EMPLOYEE_UPDATE_QUERY_KEY } from '@src/features/EditableEmployeesTable/common/constants'
import { useQuery } from '@src/utils/queryParamsHooks'
import { EmployeeRecordsIntro } from './EmployeeRecordsIntro'
import { isOnboardingPath } from '../common/helpers'

const getRoutes = (isOnboarding: boolean) => [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.INTRO,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: EmployeeRecordsIntro,
  },
  {
    title: 'Update',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE,
    canView: [PermissionTypes.ViewEmployeePreferences],
    component: () => (
      <EditableEmployeesTableDetailed
        statsView="onboarding"
        sessionRoute={
          ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPDATE_EMPLOYEES.SESSION
        }
        renderEditActionsLeft={() =>
          isOnboarding ? null : (
            <>
              <MoreBar.Action
                use={InternalLink}
                to={
                  ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES
                    .SELECT_FLOW
                }
                useIcon="Upload"
              >
                Import data
              </MoreBar.Action>
              <MoreBar.Action
                use={InternalLink}
                to={ROUTES.SETTINGS.EMPLOYEES.ATTRIBUTES}
                useIcon="Gear"
              >
                Configure attributes
              </MoreBar.Action>
              <MoreBar.Action
                use={InternalLink}
                to={ROUTES.SETTINGS.LIFECYCLE}
                useIcon="Gear"
              >
                Configure onboarding
              </MoreBar.Action>
            </>
          )
        }
      />
    ),
    isWide: true,
  },
]

export const EmployeeRecords = () => {
  const { query } = useQuery()
  const isUpdatingExistingEmployees = !!query[IS_EMPLOYEE_UPDATE_QUERY_KEY]

  const BASE_ROUTE = ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS
  const employeesUpdateFallbackTitle = 'Update existing employees’ information'
  const isOnboarding = isOnboardingPath()

  const routes = useMemo(() => getRoutes(isOnboarding), [isOnboarding])

  return (
    <Switch>
      <Route path={BASE_ROUTE.UPDATE_EMPLOYEES.ANY}>
        <Route
          path={[BASE_ROUTE.UPDATE_EMPLOYEES.IMPORT, BASE_ROUTE.UPDATE_EMPLOYEES.SESSION]}
        >
          <EmployeesBulkSessionDetailed
            isUpdatingExistingEmployees
            importRoute={BASE_ROUTE.UPDATE_EMPLOYEES.IMPORT}
            sessionRoute={BASE_ROUTE.UPDATE_EMPLOYEES.SESSION}
            anyRoute={BASE_ROUTE.UPDATE_EMPLOYEES.ANY}
            onAfterConfirmRoute={BASE_ROUTE.UPDATE}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={title => (
              <PageHeader
                title={title || employeesUpdateFallbackTitle}
                backUrl={pathToUrl(BASE_ROUTE.UPDATE)}
              />
            )}
          />
        </Route>
      </Route>
      <Route path={BASE_ROUTE.UPLOAD_EMPLOYEES.ANY}>
        <Route path={BASE_ROUTE.UPLOAD_EMPLOYEES.SELECT_FLOW}>
          <SelectBulkUploadFlow
            importEmployeesRoute={BASE_ROUTE.UPLOAD_EMPLOYEES.IMPORT}
            importContractsRoute={BASE_ROUTE.UPLOAD_CONTRACTS.IMPORT}
          />
        </Route>
        <Route
          path={[
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.IMPORT,
            ROUTES.ONBOARDING_CHECKLIST_V2.EMPLOYEE_RECORDS.UPLOAD_EMPLOYEES.SESSION,
          ]}
        >
          <EmployeesBulkSessionDynamic
            isUpdatingExistingEmployees={isUpdatingExistingEmployees}
            importRoute={BASE_ROUTE.UPLOAD_EMPLOYEES.IMPORT}
            sessionRoute={BASE_ROUTE.UPLOAD_EMPLOYEES.SESSION}
            anyRoute={BASE_ROUTE.UPLOAD_EMPLOYEES.ANY}
            onAfterConfirmRoute={BASE_ROUTE.UPDATE}
            apiEndpoint={API.EMPLOYEE_UPLOADS}
            getHeader={title => {
              const fallbackTitle = isUpdatingExistingEmployees
                ? employeesUpdateFallbackTitle
                : 'Create new employees'
              return (
                <PageHeader
                  title={title || fallbackTitle}
                  backUrl={pathToUrl(BASE_ROUTE.UPDATE)}
                />
              )
            }}
          />
        </Route>
      </Route>
      <Route path={BASE_ROUTE.UPLOAD_CONTRACTS.ANY}>
        <Route
          path={[BASE_ROUTE.UPLOAD_CONTRACTS.IMPORT, BASE_ROUTE.UPLOAD_CONTRACTS.SESSION]}
        >
          <EditableContractsBulkSession
            importRoute={BASE_ROUTE.UPLOAD_CONTRACTS.IMPORT}
            sessionRoute={BASE_ROUTE.UPLOAD_CONTRACTS.SESSION}
            anyRoute={BASE_ROUTE.UPLOAD_CONTRACTS.ANY}
            onAfterConfirmRoute={BASE_ROUTE.UPDATE}
            apiEndpoint={API.CONTRACTS_UPLOAD}
            renderHeader={() => (
              <PageHeader
                title="Create new contracts"
                backUrl={pathToUrl(BASE_ROUTE.UPDATE)}
              />
            )}
          />
        </Route>
      </Route>
      <Route>
        <OnboardingChecklistContent config={employeeRecordsConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
