import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { FormValidatorContextType } from '@src/features/Form/FormValidator'
import { LapeFormInterface } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'

export type TargetFormInterface = LapeFormInterface<KpiInterface & { tempId?: number }>

export type TargetFormGetter = () => {
  form: TargetFormInterface
  validator: FormValidatorContextType
}

interface FormObserverContextInterface {
  registerForm: (getter: TargetFormGetter, id: number) => void
  unregisterForm: (id: number) => void
  getFormsGetters: () => TargetFormGetter[]
  getFormById: (id?: number) => TargetFormGetter | null
  hasDirtyForms: () => boolean
}

const FormObserverContext = createContext<FormObserverContextInterface>({
  registerForm: () => {},
  unregisterForm: () => {},
  getFormsGetters: () => [],
  getFormById: () => null,
  hasDirtyForms: () => false,
})

export const FormObserverProvider = ({ children }: PropsWithChildren<{}>) => {
  const forms = useRef<Record<number, TargetFormGetter>>({})

  const context = useMemo<FormObserverContextInterface>(() => {
    return {
      registerForm: (getter, id) => {
        forms.current[id] = getter
      },
      unregisterForm: id => {
        delete forms.current[id]
      },
      getFormsGetters: () => {
        return Object.values(forms.current)
      },
      getFormById: id => {
        return id ? (forms.current[id] ? forms.current[id] : null) : null
      },
      hasDirtyForms: () =>
        !!Object.values(forms.current).find(getter => getter().form.dirty),
    }
  }, [])

  return (
    <FormObserverContext.Provider value={context}>
      {children}
    </FormObserverContext.Provider>
  )
}

export const useFormObserver = () => {
  return useContext(FormObserverContext)
}
