import ActionWidget from '@components/ActionWidget/ActionWidget'
import { Text } from '@revolut/ui-kit'
import React from 'react'
import { formatDateTime } from '@src/utils/format'
import differenceInDays from 'date-fns/differenceInDays'

interface AnonymizeNoticeProps {
  date?: string
}

const AnonymizeNotice = ({ date }: AnonymizeNoticeProps) => {
  if (!date) {
    return null
  }

  const dayDiff = differenceInDays(new Date(date), new Date())

  if (dayDiff > 30) {
    return null
  }

  return (
    <ActionWidget
      title="Profile to be anonymized soon"
      text={
        <Text mb="s-8" use="div">
          In accordance with the data retention policy, this profile will be anonymized on{' '}
          {formatDateTime(date)}.
        </Text>
      }
    />
  )
}

export default AnonymizeNotice
