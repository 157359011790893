import React from 'react'

import styled from 'styled-components'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { formatWithoutTimezone } from '@src/utils/format'

const P = styled.p`
  font-size: 16px;
  margin-bottom: 0;
`

const Ul = styled.ul`
  margin-top: 16px;
  margin-bottom: 0;
`

export const KPISettingDates = ({
  cycle,
  teamOnly,
}: {
  cycle: ReviewCyclesInterface
  teamOnly?: boolean
}) => {
  const dates =
    !cycle?.department_kpi_period_end_day || !cycle?.team_kpi_period_end_day || teamOnly
      ? 'date'
      : 'dates'
  return (
    <P>
      The {dates} for {cycle?.name} KPI-setting are as follows:
      <Ul>
        {cycle?.department_kpi_period_end_day && !teamOnly && (
          <li>
            <b>Department KPIs submitted:</b>{' '}
            {formatWithoutTimezone(cycle?.department_kpi_period_end_day)}
          </li>
        )}
        {cycle?.team_kpi_period_end_day && (
          <>
            <li>
              <b>Team KPIs submitted: </b>{' '}
              {formatWithoutTimezone(cycle?.team_kpi_period_end_day)}
            </li>
          </>
        )}
        {cycle?.individual_kpi_setting_deadline_day && (
          <li>
            <b>Personal KPIs submitted:</b>{' '}
            {formatWithoutTimezone(cycle?.individual_kpi_setting_deadline_day)}
          </li>
        )}
      </Ul>
    </P>
  )
}
