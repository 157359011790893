import React, { useEffect, useState } from 'react'
import BudgetImpactGraph from '@src/pages/Forms/RequisitionForm/General/BudgetImpactGraph'
import { DetailsSkeleton, ErrorWidget, Text, VStack, Widget } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  RequisitionDepartmentBudgetImpactInterface,
  RequisitionInterface,
} from '@src/interfaces/requisitions'
import { getDepartmentBudgetImpact } from '@src/api/requisitions'
import BudgetImpactError from '@src/pages/Forms/RequisitionForm/General/BudgetImpactError'
import SearchIcon from '@src/assets/3D/Search@2x.png'

const BudgetImpact = () => {
  const { values } = useLapeContext<RequisitionInterface>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [budget, setBudget] = useState<
    RequisitionDepartmentBudgetImpactInterface | undefined
  >()

  useEffect(() => {
    if (
      values.locations &&
      values.locations.length > 0 &&
      values.seniority_max &&
      values.specialisation &&
      values.team?.department_id &&
      values.headcount
    ) {
      fetchBudgetImpact()
    } else {
      setError(undefined)
      setBudget(undefined)
    }
  }, [
    values.team?.department_id,
    values.locations,
    values.seniority_min,
    values.seniority_max,
    values.specialisation,
    values.headcount,
  ])

  const fetchBudgetImpact = async () => {
    try {
      setLoading(true)
      const result = await getDepartmentBudgetImpact(values)
      setBudget(result.data)
      setError(undefined)
    } catch (e) {
      setBudget(undefined)
      setError(e)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <DetailsSkeleton />
  }

  if (error) {
    return <BudgetImpactError error={error} />
  }

  return (
    <VStack gap="s-16">
      {/* TODO: return back when the logic on the BE will be fixed */}
      {/* {!budget && ( */}
      {/*  <ActionWidget */}
      {/*    title="Complete position details section to view budget impact" */}
      {/*    text="Role (specialisation), seniority, headcount and location is needed to determine budget impact" */}
      {/*  /> */}
      {/* )} */}
      {/* {budget && ( */}
      {/*  <BudgetImpactActionWidget */}
      {/*    budget={budget} */}
      {/*    departmentId={values.team?.department_id} */}
      {/*  /> */}
      {/* )} */}

      {/* {budget && <BudgetImpactTable budget={budget} />} */}

      {budget && values.seniority_min && values.seniority_max ? (
        <Widget p="s-16">
          <Text fontWeight={500} pb="s-16" use="div">
            Budget impact based on locations (amounts in GBP/month)
          </Text>

          <BudgetImpactGraph
            data={budget.requisition_impact_by_location}
            availableBudget={budget.available_budget.staff_cost}
            requisitionCost={budget.requisition_impact.staff_cost}
            seniorityMin={values.seniority_min}
            seniorityMax={values.seniority_max}
          />
        </Widget>
      ) : (
        <ErrorWidget>
          <ErrorWidget.Image src={SearchIcon} />
          <ErrorWidget.Title>No data</ErrorWidget.Title>
        </ErrorWidget>
      )}
    </VStack>
  )
}

export default BudgetImpact
