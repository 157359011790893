import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { Box, chain, Text } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { KeyPersonSuccessionPlanInterface } from '@src/interfaces/keyPerson'
import { getSuccessionPlanStatusColor } from '@src/apps/People/KeyPersons/helpers'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

import { Preview } from './Preview'
import { Changelog } from './Changelog'

type Props = {
  setEmployeeId: (id: number) => void
  showResponsibilitiesSidebar: () => void
}
export const PreviewSuccessionPlan = (props: Props) => {
  const { initialValues } = useLapeContext<KeyPersonSuccessionPlanInterface>()

  const params = useParams<{ id?: string }>()

  const tabs = [
    {
      title: 'Preview',
      path: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS,
      to: pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.DETAILS, params),
      component: Preview,
    },
    {
      title: 'Changelog',
      path: ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.CHANGELOG,
      to: pathToUrl(ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLAN.PREVIEW.CHANGELOG, params),
      component: Changelog,
    },
  ]

  return (
    <>
      <PageHeader
        title={chain(
          'Succession planning',
          initialValues.status?.name ? (
            <Text color={getSuccessionPlanStatusColor(initialValues.status?.id)}>
              {initialValues.status.name}
            </Text>
          ) : undefined,
        )}
        subtitle={
          initialValues.employee ? (
            <EmployeeUserWithAvatar
              id={initialValues.employee.id}
              full_name={initialValues.employee.full_name}
            />
          ) : undefined
        }
        backUrl={ROUTES.APPS.KEY_PERSONS.SUCCESSION_PLANS_TABLE}
      >
        <Box my="s-16">
          <TabBarNavigation tabs={tabs} />
        </Box>
      </PageHeader>

      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component {...props} />
          </Route>
        ))}
      </Switch>
    </>
  )
}
