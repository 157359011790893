import React, { useContext, useEffect, useState } from 'react'
import capitalize from 'lodash/capitalize'
import omit from 'lodash/omit'

import {
  CalibrationFactor,
  KPIFormLocationState,
  KpiInterface,
  KpiReviewCycle,
  KpiSqlLabel,
  RunQueryResponse,
  UpdateTypes,
} from '@src/interfaces/kpis'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { selectorKeys } from '@src/constants/api'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Statuses } from '@src/interfaces'
import { BaseChartInner } from '@components/Charts/BaseChart/BaseChartInner'
import Icon from '@components/Icon/Icon'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import {
  changelogKPIRequests,
  fieldChangelogKPIRequests,
  getPendingKpiChangesRequest,
} from '@src/api/changelog'
import { mergeFormValues, useChangelog, useChangelogApi } from '@src/utils/form'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { connect, useLape } from 'lape'
import {
  fixChangelogTargets,
  fixPreviousValuesTargets,
  getEntity,
  renderParentSelectorOption,
} from '@src/utils/kpi'
import { Box, Flex, Input, InputGroup, MoreBar, Text, Widget } from '@revolut/ui-kit'
import { ArrowUp, SwitchOff, SwitchOn } from '@revolut/icons'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { IconButtonWithBorders } from '@components/CommonSC/TableStatistics'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import AutoStepper from '@components/Stepper/AutoStepper'
import { EmployeeInterface } from '@src/interfaces/employees'
import CollapsibleStepperSection from '@components/Stepper/CollapsibleStepperSection'
import RoadmapTargetsTable from '@src/features/FormTabs/Kpi/KPITargets/RoadmapTargets/RoadmapTargetsTable'
import ValueTargetsTable from '@src/features/FormTabs/Kpi/KPITargets/ValueTargets/ValueTargetsTable'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { OptionInterface } from '@src/interfaces/selectors'
import { getKPIParentSelector } from '@src/features/FormTabs/Kpi/KPITargets/common/ParentSelector'
import {
  RoadmapCalibrationBanner,
  submitForm,
} from '@src/pages/Forms/KpiForm/common/common'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import {
  TargetsApprovalsContext,
  TargetsApprovalsValue,
} from '@src/pages/Forms/KpiForm/common/TargetsApprovals'
import { KPITypes } from '@src/constants/table'
import SettingsButtons from '@src/features/SettingsButtons'
import useKPISidebars from '@src/pages/Forms/KpiForm/common/useKPISidebars'
import RadioSelectInput, {
  RadioSelectInputProps,
} from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import RadioSelectOption from '@components/Inputs/RadioSelectInput/RadioSelectOption'
import DropdownAction from '@components/Inputs/DropdownAction/DropdownAction'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { QueryField } from '@src/pages/Forms/GoalForm/Form/SidebarForms/QueryField'

export interface State {
  response: RunQueryResponse | null
  hasTypeSwitched: boolean
  loadingQuery: boolean
  viewPendingChanges: boolean
  responseTime?: number
  previousValues?: KpiInterface
  parentEntity?: string
  cycleOffset?: number | string
}

interface CycleState {
  cycle_id?: KpiReviewCycle['id']
  id: KpiReviewCycle['offset']
  name: KpiReviewCycle['name']
}

export const changelogApiKPI = {
  form: changelogKPIRequests,
  field: fieldChangelogKPIRequests,
}

export const getKPIFormInitialValues = (
  owner: KpiInterface['owner'] | EmployeeInterface,
  initialData: Omit<Partial<KpiInterface>, 'owner'>,
): KPIFormLocationState['initialValues'] => {
  return {
    department: null,
    team: null,
    is_company: false,
    role: null,
    function: null,
    parent: null,
    is_inherited: null,
    is_global: false,
    is_employee: false,
    owner,
    ...initialData,
  }
}

export const typeSwitchOptions = Object.values(UpdateTypes).map(type => ({
  value: {
    id: type,
  },
  label: capitalize(type),
}))

const selectDefaultCycleOffset: RadioSelectInputProps<CycleState>['selectDefaultOption'] =
  options => options.find(option => option.value.id === -1)

const General = () => {
  const { changelogActive, clearHistory } = useChangelogApi()
  const changelogFromUrl = useChangelog(changelogApiKPI)
  const form = useLapeContext<KpiInterface>()
  const { values, changelog, initialValues } = form
  const { sidebars, buttons } = useKPISidebars()
  const [cycleValue, setCycleValue] = useState<CycleState>()

  const { data: settings } = useGetPerformanceSettings()
  const sqlTypeEnabled = !!settings?.allowed_kpi_types.find(type => type.id === 'sql')
  const manualTypeEnabled = !!settings?.allowed_kpi_types.find(
    type => type.id === 'manual',
  )
  const roadmapTypeEnabled = !!settings?.allowed_kpi_types.find(
    type => type.id === 'roadmap',
  )

  const { status } = useContext<TargetsApprovalsValue>(TargetsApprovalsContext)

  const state = useLape<State>({
    hasTypeSwitched: false,
    loadingQuery: false,
    viewPendingChanges: true,
    response: null,
    responseTime: values?.extra?.query_time_seconds,
    previousValues: changelog && mergeFormValues(values, changelog),
    parentEntity: values.is_employee ? getEntity(values.parent || {}) : undefined,
    cycleOffset: values.parent ? undefined : -1,
  })

  const canSeePendingChanges = values.field_options?.actions?.includes(
    EntityPermissions.Change,
  )

  const canSetCalibrationFactor = values.field_options?.permissions?.includes(
    PermissionTypes.SetCalibrationFactor,
  )

  useEffect(() => {
    if (changelogFromUrl.data) {
      form.changelog = fixChangelogTargets(changelogFromUrl.data, form.values)
      form.changelogApi = changelogApiKPI
      form.values = mergeFormValues(
        values,
        omit(changelogFromUrl.data, ['field_options', 'status']),
      )
      form.disabled = true
    }
  }, [changelogFromUrl.data])

  useEffect(() => {
    const fetchChanges = async () => {
      const resp = await getPendingKpiChangesRequest(values.id)
      // this should never happen
      if (!resp.data.previous_values) {
        return
      }
      state.previousValues = resp.data.previous_values

      form.changelog = fixChangelogTargets(
        resp.data.fields_to_change,
        form.values,
      ) as KpiInterface
      form.changelogApi = changelogApiKPI
    }

    if (status === Statuses.pending && canSeePendingChanges && !changelogActive) {
      fetchChanges()
    }
  }, [status, canSeePendingChanges, changelogActive])

  const changePendingView = () => {
    if (state.viewPendingChanges) {
      const oldValues = { ...form.values }
      form.reset(
        mergeFormValues(
          form.values,
          omit(state.previousValues, ['field_options', 'status']) || {},
        ),
      )
      state.previousValues = oldValues
      form.disabled = true
    } else {
      const oldValues = form.values
      form.reset(fixPreviousValuesTargets(state.previousValues!, values))
      state.previousValues = oldValues
      form.disabled = false
    }
    state.viewPendingChanges = !state.viewPendingChanges
  }

  const noTargetsSet =
    values.update_type === UpdateTypes.roadmap
      ? !initialValues.target_epics?.length && !values.is_inherited
      : !initialValues.targets?.length && !values.is_inherited

  useEffect(() => {
    const latestTargetReviewCycle = values.targets?.[0]?.review_cycle
    const inheritedDefaultValue = latestTargetReviewCycle
      ? {
          cycle_id: latestTargetReviewCycle?.id,
          id: latestTargetReviewCycle?.offset,
          name: latestTargetReviewCycle?.name,
        }
      : undefined
    setCycleValue(inheritedDefaultValue)
    state.cycleOffset = inheritedDefaultValue?.id
  }, [values.targets])

  const saveButton = (
    <PageActions>
      <NewSaveButtonWithPopup<KpiInterface>
        useValidator
        onClick={() => submitForm(form)}
        previewUrl={res => {
          return pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: (res as KpiInterface).id })
        }}
      />
    </PageActions>
  )

  if (values.is_inherited) {
    return (
      <>
        {sidebars}
        <PageBody>
          <SettingsButtons mb="s-16">{buttons}</SettingsButtons>
          <AutoStepper>
            <Box>
              <NewStepperTitle title="General info" />
              <InputGroup>
                <RadioSelectInput<CycleState>
                  label="Cycle"
                  selector={selectorKeys.cycle_offsets}
                  value={cycleValue}
                  onChange={option => {
                    state.cycleOffset = option?.id
                    values.parent = null
                    if (option) {
                      setCycleValue(option)
                    }
                  }}
                  selectDefaultOption={
                    values.parent ? undefined : selectDefaultCycleOffset
                  }
                />
                <LapeRadioSelectInput<KpiInterface>
                  name="parent"
                  label="Parent KPI"
                  selector={getKPIParentSelector(values, state.cycleOffset)}
                  referenceText={values.parent ? 'Open' : ''}
                  referenceUrl={
                    values.parent
                      ? getLocationDescriptor(
                          pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: values.parent.id }),
                          {
                            returnAs: 'parent',
                          },
                        )
                      : ''
                  }
                  onChange={selectedKPI => {
                    if (!selectedKPI) {
                      return
                    }
                    if (values.kpi_type.id === KPITypes.employee_kpi) {
                      state.parentEntity = getEntity(selectedKPI)
                    }
                    values.parent = selectedKPI
                  }}
                >
                  {renderParentSelectorOption}
                </LapeRadioSelectInput>
                <LapeRadioSelectInput
                  name="owner"
                  label="Owner"
                  disabled={
                    values.kpi_type.id === KPITypes.employee_kpi &&
                    !values.is_assigned_from_relevant
                  }
                  selector={selectorKeys.employee}
                />
                {values.kpi_type.id === KPITypes.employee_kpi && (
                  <Input placeholder="Entity" value={state.parentEntity || ''} disabled />
                )}
              </InputGroup>
            </Box>
            <Box>
              <NewStepperTitle title="Preview" />
              <Box
                mt="14px"
                mb="s-20"
                p="s-16"
                bg="grey-tone-10"
                color="black-opaque-90"
                radius="widget"
              >
                <Text fontSize="caption">
                  Information below is gathered from the chosen higher level KPI.
                </Text>
              </Box>
              {values.parent ? (
                <Widget height={300} pt="s-24" mb="s-8">
                  <BaseChartInner
                    fetchData={getKpiPerformanceGraph}
                    id={values.parent.id}
                  />
                </Widget>
              ) : (
                <Widget p="s-16">
                  <Flex
                    height={224}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <ArrowUp color="grey-tone-50" />
                    <Text use="p" color="grey-tone-50" pt="s-8">
                      Select Parent KPI to preview its parameters
                    </Text>
                  </Flex>
                </Widget>
              )}
              {canSetCalibrationFactor && (
                <CollapsibleStepperSection title="Advanced settings">
                  <LapeRadioSelectInput<CalibrationFactor>
                    name="calibration_factor"
                    label="Calibration factor"
                    selector={selectorKeys.kpi_calibration_factors}
                  >
                    {option => (
                      <RadioSelectOption
                        label={`${option.label} (${option.value.highlight})`}
                      />
                    )}
                  </LapeRadioSelectInput>
                </CollapsibleStepperSection>
              )}
            </Box>
          </AutoStepper>
        </PageBody>
        {saveButton}
      </>
    )
  }
  const targetsTitle =
    values.update_type === UpdateTypes.roadmap ? 'deliverables' : 'targets'

  return (
    <>
      {sidebars}
      <PageBody data-testid="main_kpi_form">
        <SettingsButtons>
          {!changelogFromUrl.data &&
            !changelogFromUrl.loading &&
            !values?.is_new &&
            canSeePendingChanges &&
            status === Statuses.pending && (
              <MoreBar.Action
                useIcon={state.viewPendingChanges ? SwitchOn : SwitchOff}
                onClick={changePendingView}
              >
                Show pending changes
              </MoreBar.Action>
            )}
          {buttons}
        </SettingsButtons>
        {changelogFromUrl.data && (
          <Flex alignItems="center" mb="s-16">
            <IconButtonWithBorders onClick={clearHistory}>
              <Icon type="Close" />
            </IconButtonWithBorders>
            <Text ml="s-8">Close changelog mode.</Text>
          </Flex>
        )}
        {values.id && noTargetsSet && (
          <ActionWidget
            mt="s-24"
            title={`This KPI is missing ${targetsTitle}`}
            text={`Please use the Set ${targetsTitle} button below to add ${targetsTitle} to this kpi`}
          />
        )}
        <AutoStepper>
          <NewStepperTitle title="General info" />
          <InputGroup>
            <LapeNewTextArea label="Name" name="name" required />
            <LapeRadioSelectInput
              name="unit"
              label="Unit of measure"
              disabled={values.update_type === 'roadmap'}
              message={
                values.update_type !== 'roadmap' &&
                'Select from the list the most appropriate unit for the metric, or type your own'
              }
              selector={selectorKeys.kpi_units}
              value={
                values.unit
                  ? {
                      name: values.unit,
                    }
                  : null
              }
              onChange={option => {
                if (option?.name) {
                  values.unit = option.name
                }
              }}
            />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              disabled={values.kpi_type.id === KPITypes.employee_kpi}
              selector={selectorKeys.employee}
            />
            <LapeNewTextArea name="description" label="Description" required rows={1} />
            {values.update_type === UpdateTypes.manual && (
              <LapeNewInput label="Proof URL" name="metabase_url" hideOptional />
            )}
          </InputGroup>
          {values.update_type === UpdateTypes.manual && (
            <>
              <NewStepperTitle title="KPI updates" />
              <InputGroup>
                <LapeNewInput
                  type="number"
                  name="current_progress"
                  label="Current value"
                  required
                />
              </InputGroup>
            </>
          )}
          {(values.update_type === UpdateTypes.manual || state.hasTypeSwitched) && (
            <Text use="div" fontWeight={500} pl="s-16" pb="s-24" mt="s-8">
              <RadioSelectInput
                value={{
                  id: values.update_type,
                }}
                onChange={option => {
                  if (option) {
                    values.update_type = option.id
                    state.hasTypeSwitched = true
                  }
                }}
                options={typeSwitchOptions.filter(option => {
                  if (option.value.id === values.update_type) {
                    return false
                  }
                  if (option.value.id === UpdateTypes.sql && !sqlTypeEnabled) {
                    return false
                  }
                  if (option.value.id === UpdateTypes.roadmap && !roadmapTypeEnabled) {
                    return false
                  }
                  if (option.value.id === UpdateTypes.manual && !manualTypeEnabled) {
                    return false
                  }

                  return true
                })}
                renderInput={(open, setOpen, ref) => (
                  <DropdownAction open={open} onClick={() => setOpen(!open)} ref={ref}>
                    Change kpi type
                  </DropdownAction>
                )}
                searchable={false}
              />
            </Text>
          )}
          {values.update_type === UpdateTypes.sql && (
            <>
              <NewStepperTitle title="KPI updates" />
              <InputGroup>
                <LapeNewInput
                  name="current_progress"
                  label="Current value"
                  hideOptional
                  disabled
                  message="Current value will be calculated automatically."
                />
                <LapeRadioSelectInput<OptionInterface>
                  name="kpi_sql_type"
                  label="SQL label"
                  selector={selectorKeys.kpi_sql_types}
                  selectDefaultOption={options =>
                    options.find(opt => opt.value.id === KpiSqlLabel.General)
                  }
                />
                <QueryField previousQuery={state.previousValues?.sql_query} />
              </InputGroup>
            </>
          )}
          {(values.update_type === UpdateTypes.sql ||
            values.update_type === UpdateTypes.manual) && (
            <>
              <NewStepperTitle title="Targets" />
              <InputGroup>
                <ValueTargetsTable />
              </InputGroup>
            </>
          )}
          {values.update_type === UpdateTypes.roadmap && (
            <>
              <NewStepperTitle title="Targets" />
              <RoadmapCalibrationBanner my="s-16" />
              <InputGroup>
                <RoadmapTargetsTable />
              </InputGroup>
            </>
          )}
          {canSetCalibrationFactor && (
            <CollapsibleStepperSection title="Advanced settings">
              <LapeRadioSelectInput<CalibrationFactor>
                name="calibration_factor"
                label="Calibration factor"
                selector={selectorKeys.kpi_calibration_factors}
              >
                {option => (
                  <RadioSelectOption
                    label={`${option.label} (${option.value.highlight})`}
                  />
                )}
              </LapeRadioSelectInput>
            </CollapsibleStepperSection>
          )}
        </AutoStepper>
        {saveButton}
      </PageBody>
    </>
  )
}

export default connect(General)
