import { NavigationConfigInterface } from '@components/StatNavigation/types'
import { ROUTES } from '@src/constants/routes'
import { JobsStatsInterface } from '@src/interfaces/jobPosting'
import { GlobalSettings, PermissionTypes } from '@src/store/auth/types'

export const jobsConfig: NavigationConfigInterface<JobsStatsInterface>[] = [
  {
    key: 'job_postings_count',
    label: 'Job postings',
    to: ROUTES.RECRUITMENT.JOBS.JOB_POSTINGS,
    canView: {
      permissions: [PermissionTypes.ViewJobdescriptions],
      globalSettings: [GlobalSettings.JobPostingsEnabled],
    },
  },
  {
    key: 'requisitions_count',
    label: 'Requisitions',
    to: ROUTES.RECRUITMENT.JOBS.REQUISITIONS,
    canView: {
      permissions: [PermissionTypes.ViewRequisitions],
      globalSettings: [GlobalSettings.RequisitionsEnabled],
    },
  },
]
