import React from 'react'
import capitalize from 'lodash/capitalize'
import { Box, HStack, Icon, Text, Token, VStack } from '@revolut/ui-kit'

import {
  WorkScheduleTime,
  WorkScheduleWorkingDayInterface,
} from '@src/interfaces/workSchedule'
import Tooltip from '@components/Tooltip/Tooltip'

export const getWorkingDaysString = (
  workingDays: WorkScheduleWorkingDayInterface[] | null,
) => {
  if (!workingDays?.length) {
    return '-'
  }
  return workingDays.map(daySettings => capitalize(daySettings.day)).join(', ')
}

export const getHoursAndMinutes = (time: WorkScheduleTime) => {
  if (!/[0-9]{2}:[0-9]{2}:[0-9]{2}/.test(time)) {
    throw new Error('Wrong time string format, hh:mm:ss expected')
  }
  return time.split(':').slice(0, 2).join(':')
}

export const formatTimeInterval = (
  start: WorkScheduleTime | null,
  end: WorkScheduleTime | null,
) => {
  if (!start || !end) {
    return '-'
  }
  return `${getHoursAndMinutes(start)} to ${getHoursAndMinutes(end)}`
}

export const getWorkingHoursNode = (
  workingDays: WorkScheduleWorkingDayInterface[] | null,
) => {
  if (!workingDays?.length) {
    return <Text>Unset</Text>
  }
  const formattedIntervals = workingDays.map(day =>
    formatTimeInterval(day.work_start_time, day.work_end_time),
  )
  if (formattedIntervals.some(interval => interval !== formattedIntervals[0])) {
    return (
      <HStack space="s-4" align="center">
        <Text>Custom</Text>
        <Tooltip
          placement="left"
          body={
            <Box width={180} p="s-8">
              <VStack>
                {workingDays.map(daySettings => (
                  <Text key={daySettings.day} color={Token.color.greyTone2}>
                    {capitalize(daySettings.day)}:{' '}
                    {formatTimeInterval(
                      daySettings.work_start_time,
                      daySettings.work_end_time,
                    )}
                  </Text>
                ))}
              </VStack>
            </Box>
          }
        >
          <Icon name="InfoOutline" color={Token.color.greyTone50} size={16} />
        </Tooltip>
      </HStack>
    )
  }
  return <Text>{formattedIntervals[0]}</Text>
}
