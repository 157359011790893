import React from 'react'
import { HStack } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { TableActionButton } from '@src/components/Button/TableActionButton'
import { pathToUrl } from '@src/utils/router'

export const employeeOnboardingTemplateNameColumn: ColumnInterface<EmployeeOnboardingTemplateInterface> =
  {
    type: CellTypes.text,
    idPoint: 'id',
    dataPoint: 'name',
    sortKey: 'name',
    filterKey: 'id',
    selectorsKey: selectorKeys.employee_onboarding_templates,
    title: 'Template name',
  }

export const employeeOnboardingTemplateCreationDateColumn: ColumnInterface<EmployeeOnboardingTemplateInterface> =
  {
    type: CellTypes.date,
    idPoint: 'creation_date_time',
    dataPoint: 'creation_date_time',
    sortKey: 'creation_date_time',
    filterKey: 'creation_date_time',
    selectorsKey: selectorKeys.none,
    title: 'Creation date',
  }

export const employeeOnboardingTemplateActionsColumn: ColumnInterface<EmployeeOnboardingTemplateInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'action',
    dataPoint: 'action',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Action',
    insert: ({ data }) => {
      return (
        <HStack space="s-16">
          <TableActionButton
            onClick={e => {
              e.stopPropagation()
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.PREVIEW, {
                  id: data.id,
                }),
              )
            }}
          >
            View
          </TableActionButton>
          <TableActionButton
            onClick={e => {
              e.stopPropagation()
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.GENERAL, {
                  id: data.id,
                }),
              )
            }}
          >
            Edit
          </TableActionButton>
        </HStack>
      )
    },
  }
