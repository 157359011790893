import React from 'react'
import { ReviewCyclesInterface, ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { CommonActions } from './CommonActions'
import { notReachable } from '@src/utils/notReachable'
import { ClosedCycleActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/ClosedCycleActions'
import { OngoingCycleActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/OngoingCycleActions'
import { PlannedCycleActions } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleSummary/Actions/PlannedCycleActions'

interface Props {
  cycle: ReviewCyclesInterface
}

export const Actions = ({ cycle }: Props) => {
  const { status } = cycle

  if (status === undefined) {
    return <CommonActions cycle={cycle} />
  }

  switch (status) {
    case ReviewCycleStatus.closed:
      return <ClosedCycleActions cycle={cycle} />
    case ReviewCycleStatus.ongoing:
      return <OngoingCycleActions cycle={cycle} />
    case ReviewCycleStatus.planned:
      return <PlannedCycleActions cycle={cycle} />
    default:
      return notReachable(status)
  }
}
