import React from 'react'
import { Action, StatusPopup, Button, useStatusPopup } from '@revolut/ui-kit'

import { useNotificationRequestRetry } from '@src/api/notificationTemplate'
import { useTableReturnType } from '@components/Table/hooks'
import { NotificationSendingHistoryInterface } from '@src/interfaces/notificationTemplate'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface RetryNotificationActionProps {
  data: NotificationSendingHistoryInterface
  table: useTableReturnType<NotificationSendingHistoryInterface, {}>
}

const RetryNotificationAction = ({ data, table }: RetryNotificationActionProps) => {
  const statusPopup = useStatusPopup()
  const { mutateAsync: retryNotification, isLoading: isRetrying } =
    useNotificationRequestRetry(data.id)

  if (data.status.id === 'canceling' || data.status.id === 'canceled') {
    return null
  }

  const handleRetry = async () => {
    statusPopup.show(
      <StatusPopup variant="pending">
        <StatusPopup.Title>Retrying...</StatusPopup.Title>
      </StatusPopup>,
    )

    retryNotification([undefined, undefined])
      .then(() => {
        statusPopup.show(
          <StatusPopup variant="success">
            <StatusPopup.Title>Attempt to retry was initiated</StatusPopup.Title>
          </StatusPopup>,
        )
        table.refresh()
      })
      .catch(e => {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to retry, try again later</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(e)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <Action
      onClick={e => {
        e.stopPropagation()

        statusPopup.show(
          <StatusPopup variant="warning">
            <StatusPopup.Title>Are you sure you want to retry?</StatusPopup.Title>
            <StatusPopup.Description>
              Only failed notifications will be sent.
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button elevated onClick={handleRetry} pending={isRetrying}>
                Yes, retry
              </Button>
              <Button variant="secondary" onClick={() => statusPopup.hide()}>
                No
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      }}
      iconOnly
      useIcon="Retry"
      aria-label="Retry"
    />
  )
}

export default RetryNotificationAction
