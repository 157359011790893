import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
} from '@src/interfaces'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  DocumentsRuleInterface,
  SendRuleDocuments,
  SentRequestsInterface,
} from '@src/interfaces/documentsRule'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { OptionInterface } from '@src/interfaces/selectors'

export const documentRulesRequests: RequestInterface<DocumentsRuleInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.DOCUMENTS}/templateRules`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getItem: async id => api.get(`${API.DOCUMENTS}/templateRules/${id}`),
  patchItem: async (data, id) => api.patch(`${API.DOCUMENTS}/templateRules/${id}`, data),
  deleteItem: async id => api.delete(`${API.DOCUMENTS}/templateRules/${id}`),
  postItem: async data => api.post(`${API.DOCUMENTS}/templateRules`, data),
  putItem: async (data, id) => api.put(`${API.DOCUMENTS}/templateRules/${id}`, data),
}

export const documentRulesFormRequest: RequestInterfaceNew<DocumentsTemplatesInterface> =
  {
    get: async ({ id }) => {
      return api.get(`${API.DOCUMENTS}/templateRules/${id}`)
    },
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.DOCUMENTS}/templateRules/${id}`, data),
    submit: async data => apiWithoutHandling.post(`${API.DOCUMENTS}/templateRules`, data),
    delete: async (_, { id }) => api.delete(`${API.DOCUMENTS}/templateRules/${id}`),
  }

export const dummySendRule = (id: number) =>
  api.post(`${API.DOCUMENTS}/templateRules/${id}/testSend`)

export const getSendDocuments =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<SendRuleDocuments>> =>
    api.get(`${API.DOCUMENTS}/templateRules/${id}/sentEnvelopes`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const ruleDocumentsNameSelector = (id: number) =>
  api.get<{ options: OptionInterface[] }>(
    `${API.DOCUMENTS}/templateRules/${id}/sentEnvelopes/documentNames`,
  )

export const sendRule = (id: number) =>
  api.post(`${API.DOCUMENTS}/templateRules/${id}/send`)

export const changeRuleStatus = (id: number, status: Statuses) =>
  api.patch<DocumentsRuleInterface>(`${API.DOCUMENTS}/templateRules/${id}`, {
    status: { id: status },
  })

export const getSentRequests =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<SentRequestsInterface>> =>
    api.get(`${API.DOCUMENTS}/templateRules/${id}/requestedDocuments`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
