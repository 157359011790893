import React from 'react'
import { InputGroup, Skeleton, Widget } from '@revolut/ui-kit'
import { useCareersApplicationSettings } from '@src/api/recruitment/careers'
import {
  PredefinedSectionsProps,
  useGetCareersPredefinedSections,
} from '@src/pages/Careers/hooks/useCareersPredefinedSections'
import { connect } from 'lape'

const SectionsSkeleton = () => {
  const BORDER_RADIUS = 12

  return (
    <>
      <Widget p="s-16" mt="s-48" width="100%">
        <Skeleton height={30} width={300} mb="s-16" borderRadius={BORDER_RADIUS} />
        <InputGroup>
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
        </InputGroup>
      </Widget>

      <Widget p="s-16" mt="s-48">
        <Skeleton height={30} width={240} mb="s-16" borderRadius={BORDER_RADIUS} />
        <InputGroup>
          <Skeleton height={88} borderRadius={BORDER_RADIUS} />
          <Skeleton height={56} borderRadius={BORDER_RADIUS} />
        </InputGroup>
      </Widget>
    </>
  )
}

const CareersApplicationPredefinedSections = (props: PredefinedSectionsProps) => {
  const { isLoading: settingsLoading, error } = useCareersApplicationSettings()
  const sections = useGetCareersPredefinedSections(props)

  if (settingsLoading) {
    return <SectionsSkeleton />
  }

  if (error) {
    return null
  }

  return <>{sections.map(item => item.node)}</>
}

export default connect(CareersApplicationPredefinedSections)
