import React from 'react'
import { Badge, Flex } from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { LabelInterface } from '@src/interfaces/labels'
import { pathToUrl } from '@src/utils/router'
import { selectorKeys } from '../api'
import { ROUTES } from '../routes'
import { getDefaultStatusVariant } from '@src/components/TableV2/Cells/StatusCell'

export const labelNameColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.text,
  idPoint: 'label_name',
  dataPoint: 'label_name',
  sortKey: 'label_name',
  filterKey: null,
  selectorsKey: selectorKeys.manual_employee_labels,
  title: 'Label',
  dynamicHyperlinks: data =>
    data.dynamic_group
      ? pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, {
          id: data.dynamic_group.id,
        })
      : null,
}

export const labelBadgeColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.insert,
  idPoint: 'badge',
  dataPoint: 'badge',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Badge',
  insert: ({ data }) => (
    <Flex alignItems="center">
      <Badge image={data.badge?.url} backgroundColor="transparent" size={24} />
    </Flex>
  ),
}

export const labelDescriptionColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.text,
  idPoint: 'description',
  dataPoint: 'description',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Description',
}

export const labelAddedByColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.insert,
  idPoint: 'added_by.id',
  dataPoint: 'added_by.name',
  sortKey: 'added_by__id',
  filterKey: 'added_by__id',
  selectorsKey: selectorKeys.employee,
  title: 'Added by',
  insert: ({ data }) => <Table.EmployeeCell employee={data.added_by} />,
}

export const labelModeColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.text,
  idPoint: 'mode.id',
  dataPoint: 'mode.name',
  sortKey: 'mode',
  filterKey: 'mode',
  selectorsKey: selectorKeys.employee_label_modes,
  title: 'Mode',
}

export const labelAssignedOnColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.date,
  idPoint: 'assigned_on',
  dataPoint: 'assigned_on',
  sortKey: 'assigned_on',
  filterKey: 'assigned_on',
  selectorsKey: selectorKeys.none,
  title: 'Assigned on',
}

export const labelVisibilityColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.text,
  idPoint: 'visibility.id',
  dataPoint: 'visibility.name',
  sortKey: 'visibility',
  filterKey: 'visibility',
  selectorsKey: selectorKeys.employee_label_visibilities,
  title: 'Visibility',
}

export const labelStatusColumn: ColumnInterface<LabelInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.employee_label_statuses,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status.name}
      variant={getDefaultStatusVariant(data.status.id)}
    />
  ),
}
