import React from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'
import { Anonymous } from '@revolut/icons'

import { useCanViewAnonymiseProfile } from '../../common'
import { EmployeeButtonProps } from '../../types'
import { useAnonymiseProfileAction } from './hooks'
import { AnonymiseProfileConfirmation } from './AnonymiseProfileConfirmation'

type AnonymiseProfileActionProps = EmployeeButtonProps & {
  pending: boolean
  setShowConfirm: (show: boolean) => void
}
export const AnonymiseProfileAction = ({
  data,
  isDropdownItem,
  pending,
  setShowConfirm,
}: AnonymiseProfileActionProps) => {
  const canView = useCanViewAnonymiseProfile({ data })

  if (!canView) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use="button"
        onClick={() => setShowConfirm(true)}
        disabled={pending}
        useIcon={Anonymous}
        color={Color.RED}
      >
        [TESTING ONLY] Anonymise profile
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      onClick={() => setShowConfirm(true)}
      disabled={pending}
      variant="negative"
      useIcon={Anonymous}
    >
      [TESTING ONLY] Anonymise personal data
    </MoreBar.Action>
  )
}

export const AnonymiseProfile = ({
  data,
  refreshData,
}: EmployeeButtonProps & { refreshData: () => void }) => {
  const { pending, showConfirm, setShowConfirm, onConfirm } = useAnonymiseProfileAction({
    employeeId: data.id,
    refreshData,
  })
  return (
    <>
      <AnonymiseProfileAction
        data={data}
        pending={pending}
        setShowConfirm={setShowConfirm}
      />
      <AnonymiseProfileConfirmation
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        pending={pending}
        onConfirm={onConfirm}
        onReject={() => setShowConfirm(false)}
      />
    </>
  )
}
