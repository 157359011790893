import * as React from 'react'
import { StatusPopup, Button } from '@revolut/ui-kit'

export interface DescriptionDialogPropsInterface {
  onClose: () => void
  open: boolean
  children: React.ReactNode
  withoutModal?: boolean
}

const IncompleteFormWarningDialog = ({
  onClose,
  open,
  children,
  withoutModal,
}: DescriptionDialogPropsInterface) => {
  const container = (
    <StatusPopup.Description>
      {children}
      <StatusPopup.Actions>
        <Button
          onClick={() => {
            onClose()
          }}
          data-testid="dialog_close"
        >
          Go back
        </Button>
      </StatusPopup.Actions>
    </StatusPopup.Description>
  )

  if (withoutModal) {
    if (!open) {
      return null
    }

    return container
  }

  return (
    <StatusPopup variant="warning" open={open} onClose={onClose}>
      {container}
    </StatusPopup>
  )
}

export default IncompleteFormWarningDialog
