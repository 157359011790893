import React, { useContext, useMemo, useState } from 'react'

type NextButtonStateType = 'active' | 'pending' | 'disabled'

interface OnboardingContextInterface {
  nextButtonState: NextButtonStateType
  setNextButtonState: (state: NextButtonStateType) => void
}

export const OnboardingContext = React.createContext<OnboardingContextInterface>({
  nextButtonState: 'active',
  setNextButtonState: () => {},
})

export const OnboardingContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [nextButtonState, setNextButtonState] = useState<NextButtonStateType>('active')

  const value = useMemo(
    () => ({
      nextButtonState,
      setNextButtonState,
    }),
    [nextButtonState, setNextButtonState],
  )

  return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>
}

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext)
  if (context === null) {
    console.warn('You are using useOnboardingContext outside OnboardingContext')
  }

  return context
}
