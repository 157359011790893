import React, { useState } from 'react'
import MeetingComments from '@src/pages/EmployeeProfile/Preview/OneToOnes/MeetingComments'
import { MeetingsTableEmptyState } from '@src/pages/Forms/MeetingsWith/common'
import { RowInterface, Stats } from '@src/interfaces/data'
import { MeetingInterface } from '@src/interfaces/meetings'
import {
  meetingDateColumn,
  meetingDurationColumn,
  meetingLinkColumn,
  meetingsEmployeeNameColumn,
  meetingStatusColumn,
  getMeetingsNotesColumn,
} from '@src/constants/columns/meetings'
import { Flex } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTableReturnType } from '@components/Table/hooks'
import { TableNames } from '@src/constants/table'

interface Props {
  table: useTableReturnType<MeetingInterface, Stats>
}

const getRow = (
  onViewNotes: (meetingId: number) => void,
): RowInterface<MeetingInterface> => ({
  cells: [
    {
      ...meetingDateColumn,
      width: 200,
    },
    {
      ...meetingDurationColumn,
      width: 200,
    },
    {
      ...meetingStatusColumn,
      width: 120,
    },
    {
      ...meetingsEmployeeNameColumn,
      width: 200,
      filterKey: null,
      sortKey: null,
    },
    {
      ...meetingLinkColumn,
      width: 200,
    },
    {
      ...getMeetingsNotesColumn(onViewNotes),
      width: 180,
    },
  ],
})

const MeetingsTable = ({ table }: Props) => {
  const [commentsMeetingId, setCommentsMeetingId] = useState<number | null>(null)
  const onViewNotes = (meetingId: number) => {
    setCommentsMeetingId(meetingId)
  }

  return (
    <>
      <Flex flexDirection="column" width="100%">
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<MeetingInterface>
            name={TableNames.Meetings}
            useWindowScroll
            hideCount
            dataType="Meeting"
            row={getRow(onViewNotes)}
            emptyState={<MeetingsTableEmptyState />}
            {...table}
          />
        </Flex>
      </Flex>
      {commentsMeetingId && (
        <MeetingComments
          meetingId={commentsMeetingId}
          onClose={() => setCommentsMeetingId(null)}
        />
      )}
    </>
  )
}

export default MeetingsTable
