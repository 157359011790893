import React, { useState } from 'react'
import { selectorKeys } from '@src/constants/api'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import PotentialSlots from '@src/pages/Forms/Candidate/ScheduleSidebar/PotentialSlots'
import TimezoneSelect from '@src/pages/Forms/Candidate/ScheduleSidebar/TimezoneSelect'
import { Box, Cell, InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import LapeSingleCheckbox from '@components/Inputs/LapeFields/LapeSingleCheckbox'

type Props = {
  roundId: number
  customSlot?: string
  onChangeCustomSlot: (date?: string) => void
  onClose: () => void
}

const EditCustomDate = ({ roundId, customSlot, onChangeCustomSlot, onClose }: Props) => {
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const [showNonClashing, setShowNonClashing] = useState(true)

  return (
    <>
      <Box>
        <InputGroup>
          <LapeRadioSelectInput<EmployeeOptionInterface>
            label="Lead interviewer"
            name="interviewer"
            selector={selectorKeys.employee}
            data-testid="lead-select"
          />
          <LapeNewMultiSelect<EmployeeOptionInterface>
            name="additional_interviewers"
            placeholder="Additional interviewers"
            selector={selectorKeys.employee}
            variant="grey"
          />
          <LapeSingleCheckbox name="is_candidate_involved" label="Invite candidate" />
          {!!values.additional_interviewers?.length && (
            <Cell color="grey">
              <NewCheckbox
                label="Show non clashing slots"
                checked={showNonClashing}
                onChange={e => {
                  setShowNonClashing(e.target.checked)
                }}
              />
            </Cell>
          )}
          <TimezoneSelect />
        </InputGroup>

        <PotentialSlots
          roundId={roundId}
          showNonClashing={showNonClashing}
          customSlot={customSlot}
          onChangeCustomSlot={onChangeCustomSlot}
          onClose={onClose}
        />
      </Box>
    </>
  )
}

export default EditCustomDate
