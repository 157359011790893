import React from 'react'
import { useGlobalSettings } from '@src/api/settings'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const OffersAction = () => {
  const globalSettings = useGlobalSettings()
  const permissions = useSelector(selectPermissions)
  const offerPermissionKeys = [
    PermissionTypes.ViewOfferForm,
    PermissionTypes.ViewOfferFormTemplate,
    PermissionTypes.ViewOfferFormTemplate,
  ]
  const canViewOffers = permissions.some(p => offerPermissionKeys.includes(p))
  if (!globalSettings.settings.offers_templates_enabled || !canViewOffers) {
    return null
  }
  return (
    <MoreBar.Action
      useIcon="Statement"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.OFFER_FORMS.OFFERS)}
    >
      Offer forms
    </MoreBar.Action>
  )
}
