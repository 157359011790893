import React, { useState, useEffect } from 'react'
import pluralize from 'pluralize'

import { CalendarCheck, StatusActive } from '@revolut/icons'
import {
  Avatar,
  Box,
  chain,
  Color,
  InputGroup,
  Item,
  List,
  SelectOptionItemType,
  Text,
  VStack,
} from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { backUrl, subtitle, useRouteParams } from '../common'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { hirerightScreeningRequests, useHirerightPackages } from '@src/api/screening'
import {
  HirerightPackageInterface,
  HirerightScreeningChecksInterface,
} from '@src/interfaces/screeningChecksInterface'
import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

export const HirerightScreeningForm = () => {
  const params = useRouteParams()
  const { values, submit } = useLapeContext<HirerightScreeningChecksInterface>()

  const [employee, setEmployee] = useState<EmployeeOptionInterface | undefined>()
  const [hirerightAccount, setHirerightAccount] = useState<IdAndName<string> | null>(null)
  const [selectedPackage, setSelectedPackage] =
    useState<HirerightPackageInterface | null>(null)

  useEffect(() => {
    values.source = { id: 'hireright' } as IdAndName<'hireright'>
  }, [])

  const { data: packageOptions = [], isLoading } = useHirerightPackages(
    hirerightAccount?.id,
  )

  useEffect(() => {
    if (selectedPackage) {
      values.selected_package_id = String(selectedPackage.id)
    }
  }, [selectedPackage?.id])

  return (
    <>
      <PageHeader
        title="Order HireRight screening check"
        backUrl={backUrl(params.employeeId)}
        subtitle={subtitle(params.employeeId)}
      />
      <PageBody>
        <Box mb="s-16">
          <InputGroup>
            {!params.employeeId && (
              <RadioSelectInput<EmployeeOptionInterface>
                label="Select employee"
                selector={selectorKeys.employee}
                value={employee}
                onChange={data => {
                  if (data) {
                    setEmployee(data)
                  }
                }}
              />
            )}
            <RadioSelectInput<IdAndName<string>>
              label="Select Hireright Account"
              selector={selectorKeys.hireright_revolut_accounts}
              value={hirerightAccount}
              onChange={data => {
                if (data) {
                  setHirerightAccount(data)
                }
              }}
            />
            {!!hirerightAccount && !isLoading && (
              <RadioSelectInput<HirerightPackageInterface>
                label="Select screening package"
                options={packageOptions.map(option => ({
                  label: option.package_name,
                  value: option,
                }))}
                value={selectedPackage}
                onChange={value => {
                  setSelectedPackage(value)
                }}
                labelPath="package_name"
              >
                {(item: SelectOptionItemType<HirerightPackageInterface>) => {
                  const { value } = item
                  return (
                    <VStack space="s-4">
                      <Text variant="tile">{value.package_name}</Text>
                      <Text fontSize="caption" color={Color.GREY_TONE_50}>
                        {chain(
                          value.products?.length &&
                            `${pluralize('check', value.products.length, true)} included`,
                          value.price,
                        )}
                      </Text>
                    </VStack>
                  )
                }}
              </RadioSelectInput>
            )}
          </InputGroup>
        </Box>

        {selectedPackage && (
          <Item>
            <Item.Avatar>
              <Avatar useIcon={CalendarCheck} />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>{selectedPackage.package_name}</Item.Title>
              <Item.Description>
                {selectedPackage.products?.length &&
                  `${pluralize('check', selectedPackage.products.length, true)} included`}
              </Item.Description>
              <List variant="compact" color="grey-tone-50" mt="s-8">
                {selectedPackage.products.map(product => (
                  <List.Item key={product.name} useIcon={StatusActive}>
                    {product.name}
                  </List.Item>
                ))}
              </List>
            </Item.Content>
          </Item>
        )}
      </PageBody>
      {selectedPackage && (
        <PageActions>
          <NewSaveButtonWithPopup
            useValidator
            successText="Screening successfully saved"
            previewUrl={employee ? undefined : ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW}
            onClick={async () => {
              if (employee) {
                const { data } = await hirerightScreeningRequests.submit(values, {
                  employeeId: String(employee.id),
                })
                return navigateTo(
                  pathToUrl(ROUTES.FORMS.SCREENING.HIRERIGHT.PREVIEW, {
                    id: data.id,
                    employeeId: String(employee.id),
                  }),
                )
              }
              return submit()
            }}
          />
        </PageActions>
      )}
    </>
  )
}
