import React, { useEffect, useState } from 'react'
import { defaultTheme } from '@src/styles/theme'
import SideBar from '@components/SideBar/SideBar'
import {
  ActionButton,
  Box,
  Button,
  chain,
  FilterButton,
  Flex,
  Input,
  Side,
} from '@revolut/ui-kit'
import { CrossCircle, SwitchOff, SwitchOn } from '@revolut/icons'
import { Grid } from '@components/CommonSC/Grid'
import { OptionInterface } from '@src/interfaces/selectors'
import { SidebarMultiselectProps, SidebarOption, SidebarSelection } from './types'
import { SelectMode } from './components/SelectMode'
import { SearchMode } from './components/SearchMode'

const parseInitialSelection = (initialSelection: SidebarOption[]): SidebarSelection => {
  return initialSelection.reduce((result, current) => {
    result[current.id] = current

    return result
  }, {} as SidebarSelection)
}

const SidebarMultiselect = <T,>({
  options,
  onSearch,
  searchPending,
  onCreate,
  onSubmit,
  dataType = 'item',
  isOpen,
  filters,
  onClose,
  submitButtonTitle = 'Add to list',
  submitPending,
  optionsPending,
  emptySearchMessage = 'Search results',
  emptySearchDescription,
  initialSearchDescription,
  searchActions,
  title,
  subtitle,
  description,
  initialSelection,
  selectedText,
  disabled,
}: SidebarMultiselectProps<T>) => {
  const [searchValue, setSearchValue] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<OptionInterface>()
  const [showSelected, setShowSelected] = useState(false)
  const [result, setResult] = useState<SidebarSelection>({})

  const selectedCount = Object.keys(result).length

  useEffect(() => {
    if (!isOpen) {
      reset()
    }
  }, [isOpen])

  useEffect(() => {
    if (isOpen && initialSelection) {
      setResult(parseInitialSelection(initialSelection))
    }
  }, [isOpen, initialSelection])

  const checkIfSelectionChanged = (): boolean => {
    if (initialSelection) {
      const containsAllFromInitial = initialSelection.reduce((acc, option) => {
        if (option.id in result) {
          return acc
        }
        return false
      }, true)

      return !containsAllFromInitial || selectedCount !== initialSelection.length
    }
    return !!selectedCount
  }

  const handleSearchChange = (value: string) => {
    setSearchValue(value)
    onSearch(value, selectedFilter)
  }

  const handleItemClick = (item: SidebarOption, checked: boolean) => {
    const copyOfResult = { ...result }
    if (checked) {
      delete copyOfResult[`${item.id}`]
      setResult(copyOfResult)

      if (showSelected && !Object.keys(copyOfResult).length) {
        setShowSelected(false)
      }
    } else {
      copyOfResult[`${item.id}`] = item
      setResult(copyOfResult)
    }
  }

  const handleClearAll = () => {
    setResult({})
    setShowSelected(false)
  }

  const reset = () => {
    setSearchValue('')
    setShowSelected(false)
    setResult({})
  }

  const emptyDescription =
    searchValue === '' && initialSearchDescription
      ? initialSearchDescription
      : emptySearchDescription

  return (
    <SideBar
      title={
        showSelected ? selectedText || `Selected items` : title || `Select ${dataType}`
      }
      subtitle={subtitle}
      isOpen={isOpen}
      onClose={onClose}
      zIndex={defaultTheme.zIndex.sideBar}
    >
      {selectedCount > 0 && (
        <ActionButton
          onClick={() => setShowSelected(!showSelected)}
          useIcon={showSelected ? SwitchOn : SwitchOff}
          mb="s-16"
        >
          {chain('View selected items', selectedCount)}
        </ActionButton>
      )}
      {/* Height 0 is a magic hack to make our position fixed work with ui-kit's native scroll */}
      <Flex height={0} flexDirection="column">
        <Box mb="s-24">
          <Input
            placeholder={`Search ${dataType}`}
            value={searchValue}
            data-testid="sidebar-multiselect-new-input"
            pending={searchPending}
            onChange={e => handleSearchChange(e.currentTarget.value)}
            renderAction={() =>
              searchValue && (
                <CrossCircle
                  size={24}
                  cursor="pointer"
                  color="grey-tone-50"
                  hoverColor="grey-tone-50"
                  onClick={e => {
                    e.preventDefault()
                    setSearchValue('')
                    handleSearchChange('')
                    onSearch('', selectedFilter)
                  }}
                />
              )
            }
          />
        </Box>
        {filters && (
          <Grid gap={8} pb="s-8" mb="s-8" flow="column">
            {filters.map(filter => (
              <FilterButton
                active={filter.id === selectedFilter?.id}
                onClear={() => {
                  setSelectedFilter(undefined)
                  onSearch(searchValue, undefined)
                }}
                onClick={() => {
                  onSearch(searchValue, filter)
                  setSelectedFilter(filter)
                }}
                key={filter.id}
              >
                {filter.name}
              </FilterButton>
            ))}
          </Grid>
        )}
        {onCreate && (
          <ActionButton mb="s-16" onClick={onCreate}>
            Create new {dataType}
          </ActionButton>
        )}
        {description}
        {showSelected ? (
          <SelectMode
            selection={result}
            disabled={disabled}
            pending={optionsPending}
            handleClearAll={handleClearAll}
            handleItemClick={handleItemClick}
          />
        ) : (
          <SearchMode
            disabled={disabled}
            pending={optionsPending}
            selection={result}
            emptyDescription={emptyDescription}
            searchValue={searchValue}
            searchActions={searchActions}
            handleClearAll={handleClearAll}
            handleItemClick={handleItemClick}
            options={options}
            emptySearchMessage={emptySearchMessage}
          />
        )}
        <Side.Actions>
          {checkIfSelectionChanged() && isOpen && !disabled && (
            <Button
              data-testid="sidebar-multiselect-submit-button"
              onClick={() => onSubmit(Object.values(result))}
              pending={submitPending}
              elevated
            >
              {chain(submitButtonTitle, selectedCount)}
            </Button>
          )}
        </Side.Actions>
      </Flex>
    </SideBar>
  )
}

export default SidebarMultiselect
