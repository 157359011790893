import React from 'react'
import {
  Avatar,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  Cell,
  Tag,
  Token,
  abbreviate,
  Icon,
  chain,
  opacity,
} from '@revolut/ui-kit'
import { getStatusThemeColor } from '@components/CommonSC/General'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { getAvatarUrl } from '@src/utils/employees'
import capitalize from 'lodash/capitalize'
import { Statuses } from '@src/interfaces'
import { useGetOrganisationSettings } from '@src/api/settings'

type Props = {
  data: EmployeeInterface
  actions?: React.ReactNode
  status: Statuses
  reviewTitle: string
  variant?: 'onboarding' | 'general'
}
export const FormTitle = ({
  data,
  actions,
  status,
  reviewTitle,
  variant = 'general',
}: Props) => {
  const isExternal = data?.employee_type === 'external'
  const linkOrText = variant === 'general' ? InternalLink : 'span'
  const { data: orgSettings } = useGetOrganisationSettings()

  const companyRelation = isExternal ? (
    <Tag variant="outlined" color={Token.color.greyTone50}>
      External
    </Tag>
  ) : null

  const review = data.specialisation?.id ? (
    <HStack align="center">
      <Text color={opacity(Token.colorChannel.foreground, 0.8)} variant="caption">
        {reviewTitle}
      </Text>
    </HStack>
  ) : null

  const seniority =
    !!orgSettings?.enable_multiple_levels_per_seniority &&
    data.specialisation_seniority_sublevel?.id ? (
      <HStack align="center">
        <Text color={opacity(Token.colorChannel.foreground, 0.8)} variant="caption">
          {data.specialisation_seniority_sublevel.name}
        </Text>
      </HStack>
    ) : null

  const specialisation = data.specialisation?.id ? (
    <HStack align="center">
      <Icon name="RepairTool" size={15} color={Token.color.greyTone50} />
      <Text
        use={linkOrText}
        to={pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
          id: data.specialisation.id,
        })}
        target="_blank"
        color={opacity(Token.colorChannel.foreground, 0.8)}
        variant="caption"
        textDecoration="none"
      >
        {isExternal
          ? data.specialisation.name
          : (data as EmployeeInterface).job_title || data.specialisation.name}
      </Text>
    </HStack>
  ) : null

  const team = data.team?.name ? (
    <HStack gap="s-4" align="center">
      <Icon name="People" size={15} color={Token.color.greyTone50} />
      <Text
        use={linkOrText}
        to={pathToUrl(ROUTES.FORMS.TEAM.SUMMARY, {
          id: data.team.id,
        })}
        target="_blank"
        color={opacity(Token.colorChannel.foreground, 0.8)}
        variant="caption"
        textDecoration="none"
      >
        {data.team.name}
      </Text>
    </HStack>
  ) : null

  return (
    <Box mt="s-8">
      <Cell pt="s-24" px="s-16" pb="s-16">
        <VStack gap="s-16" overflow="hidden">
          <Flex flex={1} alignItems="center" gap="s-16">
            <Box alignSelf="flex-start">
              {data.id && (
                <Avatar
                  size={56}
                  image={getAvatarUrl(data.avatar, 'md')}
                  label={abbreviate(data.full_name)}
                />
              )}
            </Box>
            <VStack gap="s-4">
              <Box
                use={linkOrText}
                to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: data?.id })}
                target="_blank"
                color="inherit"
                style={{ textDecoration: 'none' }}
              >
                <Text variant="h1" whiteSpace="pre-wrap">
                  {data.id ? data.full_name : `New Employee`}
                </Text>
              </Box>
              <Flex flexWrap="wrap" gap={{ md: 's-4', sm: 's-8' }}>
                <Tag variant="outlined" color={getStatusThemeColor(status)}>
                  {capitalize(status)} review
                </Tag>
                {review}
                {companyRelation}
                <Flex alignItems="center" style={{ fontWeight: 400, fontSize: 14 }}>
                  {chain(specialisation, seniority)}
                </Flex>
                {team}
              </Flex>
            </VStack>
          </Flex>
          {actions}
        </VStack>
      </Cell>
    </Box>
  )
}
