import React, { useContext } from 'react'
import { Text, AvatarSize } from '@revolut/ui-kit'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'
import { IdStatuses, AvatarType } from '@src/interfaces/employees'
import { useIsNewTable } from '@components/TableV2/hooks'
import { TableDensityContext, TableDensityContextValue } from '@components/TableV2/Table'
import { getAdjustedAvatarSize } from '@components/TableV2/utils'
import { EmployeeEventDetailsInterface } from '@src/interfaces/employeeEvents'
import {
  employeeEventToIcon,
  employeeEventToColor,
} from '@components/EmployeeEvent/common'

interface EmployeeCellProps {
  employee?: {
    id: number
    name?: string
    full_name?: string
    avatar?: AvatarType
    status?: IdStatuses
  } | null
  size?: AvatarSize
  event?: EmployeeEventDetailsInterface
  compact?: boolean
  disableLink?: boolean
}

export const EmployeeCell = ({
  employee,
  size,
  event,
  compact,
  disableLink,
}: EmployeeCellProps) => {
  const isNewTable = useIsNewTable()
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  if (employee?.full_name === 'Pending Employee') {
    return <Text style={{ fontStyle: 'italic' }}>{employee.full_name}</Text>
  }

  const adjustedSize = getAdjustedAvatarSize(size, density)

  const badgeProps = event
    ? {
        useIcon: employeeEventToIcon[event.event_type.id],
        bg: employeeEventToColor[event.event_type.id],
      }
    : undefined

  return (
    <UserWithAvatar
      {...employee}
      size={isNewTable ? adjustedSize : undefined}
      compact={compact}
      disableLink={disableLink}
      badgeProps={badgeProps}
      progress={event ? 1 : undefined}
      progressColor={event ? employeeEventToColor[event?.event_type.id] : undefined}
    />
  )
}
