import { AxiosError, AxiosPromise, AxiosRequestConfig } from 'axios'
import { FetchDataQueryInterface, Stats } from './data'
import { OptionInterface } from './selectors'
import { ExportTypes } from '../constants/export'
import { UseInfiniteQueryResult, UseQueryResult } from 'react-query'

export interface RequestInterface<T, S = Stats, GetItemsT = T> {
  getItems: (
    requestData: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestInterface<GetItemsT>>
  getStats?: (requestData: FetchDataQueryInterface, data?: Partial<T>) => AxiosPromise<S>
  getItem: (id: number | string, employeeId?: number | string) => AxiosPromise<T>
  patchItem: (data: Partial<T>, id: number | string) => AxiosPromise<T>
  deleteItem: (id: number | string) => AxiosPromise<T>
  postItem: (data: Partial<T>, parentData?: { id: string }) => AxiosPromise<T>
  putItem?: (data: Partial<T>, id: number | string) => AxiosPromise<T>
  getSelectors?: (name: string) => AxiosPromise<{ options: OptionInterface[] }>
  getExport?: ExportRequest
}

export interface TableRequestInterface<
  T,
  S = Stats,
  M extends Record<string, any> = { [key: string]: any },
> {
  getItems: (
    requestData: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestInterface<T, M>>
  getStats?: (requestData: FetchDataQueryInterface, data?: Partial<T>) => AxiosPromise<S>
}

export interface Params {
  [key: string]: string | undefined
}

export type RequestUpdateType<T> = (
  diff: Partial<T>,
  params: Params,
  data?: Partial<T>,
  initialData?: Partial<T>,
) => AxiosPromise<T>

export interface RequestInterfaceNew<T> {
  get: (params: Params) => AxiosPromise<T>
  update: RequestUpdateType<T>
  submit: (data: Partial<T>, params: Params) => AxiosPromise<T>
  delete?: (data: Partial<T>, params: Params) => AxiosPromise<T>
}

export interface tableRequests<T, S, M extends Record<string, any> = {}> {
  getItems: (
    requestData: FetchDataQueryInterface,
  ) => AxiosPromise<GetRequestInterface<T, M>>
  getStats?: (requestData: FetchDataQueryInterface) => AxiosPromise<S>
  getSelectors?: (name: string) => AxiosPromise<{ options: OptionInterface[] }>
}

export interface ExportRequest {
  (exportType: ExportTypes, filterQuery?: Object): AxiosPromise<string>
}

export type UseFetchResult<T, E = AxiosError> = UseQueryResult<T, E>
export type UseInfiniteFetchResult<T, E = AxiosError> = UseInfiniteQueryResult<T, E>

export enum Statuses {
  opened = 'opened',
  open = 'open',
  running = 'running',
  template = 'template',
  completed = 'completed',
  complete = 'complete',
  completed_late = 'completed_late',
  in_review = 'in_review',
  approved = 'approved',
  accepted = 'accepted',
  preapproved = 'preapproved',
  pending_reapproval = 'pending_reapproval',
  funcapproved = 'funcapproved',
  recapproved = 'recapproved',
  pending = 'pending',
  applied = 'applied',
  pending_approval = 'pending_approval',
  pending_completion = 'pending_completion',
  onboarding = 'onboarding',
  scheduled = 'scheduled',
  revoked = 'revoked',
  active = 'active',
  inactive = 'inactive',
  cancelled = 'cancelled',
  canceled = 'canceled',
  hired = 'hired',
  rejected = 'rejected',
  ongoing = 'ongoing',
  planned = 'planned',
  closed = 'closed',
  archived = 'archived',
  expired = 'expired',
  extended = 'extended',
  draft = 'draft',
  skipped = 'skipped',
  failed = 'failed',
  no_account = 'no_account',
  linked = 'linked',
  progress = 'progress',
  created = 'created',
  unassigned = 'unassigned',
  future = 'future',
  terminated = 'terminated',
  deactivated = 'deactivated',
  requires_changes = 'requires_changes',
  on_track = 'on_track',
  at_risk = 'at_risk',
  delayed = 'delayed',
  on_hold = 'on_hold',
  hidden_draft = 'hidden_draft',
}

export interface GetRequestPaginationInterface {
  next: number | null
  previous: number | null
  total: number
  page_size: number
}

export interface GetRequestData<T> {
  results: T[]
  count: number
  pages: GetRequestPaginationInterface
}

export type GetRequestInterface<
  T,
  M extends Record<string, any> = {},
> = GetRequestData<T> & M

export interface FieldOptions {
  actions?: string[]
  permissions?: string[]
  no_access: string[]
  read_only: string[]
  synchronised?: string[]
  disableSubmit?: boolean
  required?: boolean
}

export type ApiVersion = 'v1' | 'v2' | 'v3'

export type Id<ID = number> = { id: ID }

export type Name<NAME = string> = { name: NAME }
export type OptionalName<NAME = string> = { name?: NAME }

export type IdAndName<ID = number, NAME = string> = Id<ID> & Name<NAME>
export type IdAndOptionalName<ID = number, NAME = string> = Id<ID> & OptionalName<NAME>

export interface ApiHandlerInterface {
  get: <T>(
    route: string,
    config?: AxiosRequestConfig | undefined,
    apiVersion?: ApiVersion,
    isExternal?: boolean,
  ) => AxiosPromise<T>
  post: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
    apiVersion?: ApiVersion,
    isExternal?: boolean,
  ) => AxiosPromise<T>
  patch: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
    apiVersion?: ApiVersion,
    isExternal?: boolean,
  ) => AxiosPromise<T>
  put: <T = any>(
    route: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
    apiVersion?: ApiVersion,
    isExternal?: boolean,
  ) => AxiosPromise<T>
  delete: <T = any>(
    route: string,
    config?: AxiosRequestConfig | undefined,
    apiVersion?: ApiVersion,
    isExternal?: boolean,
  ) => AxiosPromise<T>
}

export type WithOptionalId<T extends { id: unknown }> = Omit<T, 'id'> & {
  id?: T['id']
}
