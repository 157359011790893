import React from 'react'
import { MoreBar } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

type Props = {
  disabled: boolean
  checked: boolean
  onChange: (value: boolean) => void
  buttonLabel?: string
}

const OrderingModeCheckbox = ({ disabled, checked, onChange, buttonLabel }: Props) => {
  return (
    <Tooltip
      placement="top"
      hide={!disabled}
      text="Prioritisation not possible when filters are applied. Clear filters to activate prioritisation"
    >
      <MoreBar.Action
        onClick={() => onChange(!checked)}
        useIcon={checked ? 'SwitchOn' : 'SwitchOff'}
        disabled={disabled}
      >
        {buttonLabel || 'Edit priorities'}
      </MoreBar.Action>
    </Tooltip>
  )
}

export default OrderingModeCheckbox
