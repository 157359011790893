import { GetRequestInterface } from '@src/interfaces'
import { KpiEntityWeightMode, KpiWeightsInterface } from '@src/interfaces/kpis'
import { api } from '@src/api/index'
import { API, EntityTypes } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'

export const kpiWeightsSubmitRequest = (
  data: KpiWeightsInterface,
): Promise<KpiWeightsInterface> => {
  return api.post(API.KPI_WEIGHTS, data).then(response => response.data)
}

export const useGetEntityWeightModes = (
  entityType: EntityTypes,
  entityId: number | string,
) => {
  return useFetch<GetRequestInterface<KpiEntityWeightMode>>(
    entityId && entityType ? `${API.KPIS}/entityWeightModes` : null,
    'v1',
    { params: { entity_id: entityId, entity_type: entityType } },
  )
}
