import React from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RoleInterface } from '@src/interfaces/roles'
import { connect } from 'lape'
import { Box, H4, Link, Spinner, Text, Widget } from '@revolut/ui-kit'
import {
  useGetRoleJobDescription,
  useGetSpecialisationJobDescription,
} from '@src/api/jobPosting'
import { LocationInterface } from '@src/interfaces/requisitions'

interface JobDescriptionProps {
  type: 'specialisation' | 'role'
  id?: number
  roleName?: string
  locations?: LocationInterface[]
}

const JobDescription = ({ type, locations, id, roleName }: JobDescriptionProps) => {
  const { values } = useLapeContext<RoleInterface>()
  const { data: RoleData, isLoading: RoleLoading } = useGetRoleJobDescription(
    type === 'role' ? id || values.id : null,
  )
  const { data: SpecData, isLoading: SpecLoading } = useGetSpecialisationJobDescription(
    type === 'specialisation' ? id || values.id : null,
  )

  const data = RoleData || SpecData?.sections
  const loading = RoleLoading || SpecLoading

  if (loading) {
    return (
      <Widget p="s-16">
        <Spinner />
      </Widget>
    )
  }

  return (
    <Widget p="s-16">
      <Box py="s-24">
        <H4 mb="s-20" textAlign="center">
          {values?.name || roleName || '<Role name>'}
        </H4>
        <Text use="div" textAlign="center" color="grey-tone-50" fontSize="primary">
          {locations?.length
            ? locations?.reduce(
                (prev, curr) => (prev ? `${prev} | ${curr.name}` : curr.name),
                '',
              )
            : `<Location from requisition>`}
        </Text>
      </Box>
      {data?.map?.(section => {
        const link = section?.section_url || section.section?.section_url
        const linkTitle = section?.section_url_title || section.section?.section_url_title
        return (
          <Box key={section.id} py="s-16">
            <Text use="div" variant="primary" mb="10px">
              {section.title}
            </Text>
            <Text
              pb="s-16"
              use="pre"
              textAlign="justify"
              whiteSpace="pre-wrap"
              variant="caption"
              color="grey-tone-50"
            >
              {section.content}
            </Text>
            {!!link && (
              <Link href={link} target="_blank" rel="noopener noreferrer">
                {linkTitle || 'See more'}
              </Link>
            )}
          </Box>
        )
      })}
    </Widget>
  )
}

export default connect(JobDescription)
