import React, { useEffect, useMemo } from 'react'
import { Route, Switch, useParams, useLocation, matchPath } from 'react-router-dom'
import { connect } from 'lape'
import { Box, Badge, Token } from '@revolut/ui-kit'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { internalDashboardRequests } from '@src/api/analyticsDashboards'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@components/Page/Header/PageHeader'
import Form from '@src/features/Form/Form'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { NextButton } from '../DataAnalyticsDashboardForm/components/NextButton'
import { Details } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/Details'
import { Metrics } from '@src/pages/Forms/DataAnalyticsInternalDashboardForm/Metrics'
import { SubmitButton } from '@src/pages/Forms/DataAnalyticsDashboardForm/components/SubmitButton'
import { z } from 'zod'
import { InternalDashboardInterface } from '@src/interfaces/analyticsDashboards'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EntityTypes } from '@src/constants/api'
import { EntityData } from '@src/pages/Forms/DataAnalyticsDashboardForm/DashboardForm'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

const tabsConfig = [
  {
    title: 'Basic info',
    path: ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.DETAILS,
    component: Details,
  },
  {
    title: 'Metrics',
    path: ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.METRICS,
    component: Metrics,
  },
]

const validateForm = (values: InternalDashboardInterface) =>
  z
    .object({
      name: z.string().min(1),
    })
    .safeParse(values)

const useTabs = () => {
  const location = useLocation()
  const params = useParams<{ id?: string }>()

  const currentRouteIndex = tabsConfig.findIndex(
    tab =>
      !!matchPath(location.pathname, {
        path: tab.path,
        exact: true,
      }),
  )

  return useMemo(() => {
    return tabsConfig.map((tab, idx) => {
      const completed = idx < currentRouteIndex
      const disabled = idx > currentRouteIndex

      return {
        ...tab,
        disabled: idx > currentRouteIndex,
        to: pathToUrl(tab.path, params),
        preTitle: (
          <Box mr="s-8">
            <Badge
              size={16}
              bg={
                completed
                  ? Token.color.green
                  : disabled
                  ? Token.color.greyTone50
                  : Token.color.foreground
              }
            >
              {idx + 1}
            </Badge>
          </Box>
        ),
      }
    })
  }, [currentRouteIndex])
}

const Dashboard = connect(() => {
  const tabs = useTabs()
  const { values } = useLapeContext<InternalDashboardInterface>()
  const location = useLocation<EntityData | undefined>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
    if (!values.id && location.state?.goalId) {
      values.related_goals = [{ id: location.state?.goalId }]
    }
    if (!location.state?.entityId || !location.state.entityName) {
      return
    }
    if (!values.id && location.state?.entityType === EntityTypes.department) {
      values.related_departments = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }

    if (
      !values.id &&
      (location.state?.entityType === EntityTypes.employees ||
        location.state?.entityType === EntityTypes.employee)
    ) {
      values.related_employees = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }

    if (
      !values.id &&
      (location.state?.entityType === EntityTypes.teams ||
        location.state?.entityType === EntityTypes.team)
    ) {
      values.related_teams = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }

    if (
      !values.id &&
      (location.state?.entityType === EntityTypes.roles ||
        location.state?.entityType === EntityTypes.role)
    ) {
      values.related_roles = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }

    if (
      !values.id &&
      (location.state?.entityType === EntityTypes.specialisations ||
        location.state?.entityType === EntityTypes.specialisation)
    ) {
      values.related_specialisations = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }

    if (
      !values.id &&
      (location.state?.entityType === EntityTypes.functions ||
        location.state?.entityType === EntityTypes.function)
    ) {
      values.related_functions = [
        { id: location.state.entityId, name: location.state.entityName },
      ]
    }
  }, [])
  const isValidForm = validateForm(values).success

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.APPS.DATA_ANALYTICS.DASHBOARD}
        title={values.id ? `Edit ${values.name} dashboard` : 'Create new dashboard'}
        subtitle={
          values.id
            ? 'Edit dashboard by assigning some metrics'
            : 'Create dashboard by assigning some metrics'
        }
      >
        <TabBarNavigation mb="s-16" tabs={tabs} />
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
      <PageActions>
        <NextButton tabs={tabs} disabled={!isValidForm} />
        <SubmitButton tabs={tabs} />
      </PageActions>
    </PageWrapper>
  )
})

const DashboardForm = () => {
  return (
    <Form
      api={internalDashboardRequests}
      localStorageProps={{
        followUrlTransitions: true,
        removeDataAfterTransitionFromUrls: [
          pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.DETAILS),
          pathToUrl(ROUTES.FORMS.DATA_ANALYTICS_INTERNAL_DASHBOARD.METRICS),
        ],
      }}
    >
      <Dashboard />
    </Form>
  )
}

export default connect(DashboardForm)
