import React, { useState } from 'react'
import {
  HiringProcessInterface,
  SpecialisationHiringProcess,
  StageReference,
} from '@src/interfaces/hiringProccess'
import {
  HiringStagesBankSidebar,
  OnConfirmArguments,
} from '@src/components/HiringStagesBank/HiringStagesBankSidebar'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { HiringStage } from '@src/components/HiringStagesBank/HiringStage'
import { addStageReferencesToHiringProcess } from '@src/api/hiringProcess'

const mapToStageReference = (
  stages: HiringProcessInterface[],
  stage_level: 'company' | 'role',
): StageReference[] =>
  stages.map(({ id }) => ({
    stage_id: id,
    stage_level,
  }))

const isFulfilled = <T,>(p: PromiseSettledResult<T>): p is PromiseFulfilledResult<T> =>
  p.status === 'fulfilled'
const isRejected = <T,>(p: PromiseSettledResult<T>): p is PromiseRejectedResult =>
  p.status === 'rejected'

type AddFromHiringStagesBankProps = {
  pending: boolean
  onAddHiringStagesFromBank: (hiringStages: HiringProcessInterface[]) => void
  onClose: () => void
}

export const AddFromHiringStagesBank = ({
  pending,
  onAddHiringStagesFromBank,
  onClose,
}: AddFromHiringStagesBankProps) => {
  const { values } = useLapeContext<SpecialisationHiringProcess>()
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const hasCVScreening = (values.hiring_process_stages || []).some(
    stage => stage.stage_type.id === 'cv_screening',
  )
  const handleAddHiringStages = async ({
    companyStages,
    roleStages,
  }: OnConfirmArguments) => {
    setLoading(true)
    try {
      const stageReferences = [
        ...mapToStageReference(companyStages, 'company'),
        ...mapToStageReference(roleStages, 'role'),
      ]
      const results = await Promise.allSettled(
        stageReferences.map(stageReference =>
          addStageReferencesToHiringProcess(
            values.specialisation.id,
            values.id,
            stageReference,
          ),
        ),
      )
      const fulfilledValues = results.filter(isFulfilled).map(({ value }) => value.data)
      const rejectedReasons = results
        .filter(isRejected)
        .map(({ reason }) => JSON.parse(reason.config.data))
      onAddHiringStagesFromBank(fulfilledValues)
      if (rejectedReasons.length) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>
              There was an error adding the following hiring stages from bank
            </StatusPopup.Title>
            <StatusPopup.Description>
              {rejectedReasons.map(data => (
                <HiringStage key={data.id} disabled hiringStage={data} />
              ))}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      } else {
        onClose()
      }
    } catch {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>
            There was an error adding hiring stages from bank
          </StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <HiringStagesBankSidebar
      confirmLabel="Add stages"
      disableCVScreening
      hasCVScreening={hasCVScreening}
      includeRoles
      loading={loading}
      pending={pending}
      singleSelect={false}
      specialisationId={values.specialisation?.id}
      onConfirm={handleAddHiringStages}
      onClose={() => {
        onClose()
      }}
    />
  )
}
