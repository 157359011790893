import React from 'react'
import { useSelector } from 'react-redux'

import { useParams } from 'react-router-dom'
import { Item, VStack, Avatar } from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'
import { useGetScreeningSettings } from '@src/api/settings'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { navigateTo } from '@src/actions/RouterActions'
import { subtitle } from './common'
import Icon from '@components/Icon/Icon'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

export const SelectScreeningType = () => {
  const { employeeId } = useParams<{ employeeId: string }>()
  const featureFlags = useSelector(selectFeatureFlags)

  const { data: settings } = useGetScreeningSettings()

  const automaticScreeningsEnabled =
    settings?.automatic_check_enabled &&
    featureFlags.includes(FeatureFlags.AutomaticScreenings)

  return (
    <>
      <PageHeader
        title="How do you want to add screening check?"
        subtitle={subtitle(employeeId)}
        backUrl={
          employeeId
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE.GENERAL.SCREENING, {
                id: employeeId,
              })
            : pathToUrl(ROUTES.APPS.SCREENING.SCREENING_TABLE)
        }
      />
      <PageBody>
        <VStack mt="s-16" space="s-16">
          {automaticScreeningsEnabled && (
            <Item
              use="label"
              variant="disclosure"
              onClick={() =>
                navigateTo(
                  pathToUrl(
                    ROUTES.FORMS.SCREENING.HIRERIGHT.GENERAL,
                    employeeId ? { employeeId } : undefined,
                  ),
                )
              }
            >
              <Item.Avatar>
                <Avatar useIcon={<Icon type="HireRight" size="medium" />} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Order through HireRight</Item.Title>
                <Item.Description>
                  Choose one of the screening packages offered by provider
                </Item.Description>
              </Item.Content>
            </Item>
          )}
          {settings?.manual_check_enabled && (
            <Item
              use="label"
              variant="disclosure"
              onClick={() =>
                navigateTo(
                  pathToUrl(
                    ROUTES.FORMS.SCREENING.MANUAL.GENERAL,
                    employeeId ? { employeeId } : undefined,
                  ),
                )
              }
            >
              <Item.Avatar>
                <Avatar useIcon={Pencil} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>Add manually</Item.Title>
                <Item.Description>
                  Record any screening and adjust status manually
                </Item.Description>
              </Item.Content>
            </Item>
          )}
        </VStack>
      </PageBody>
    </>
  )
}
