import React from 'react'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Button, VStack } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import { useParams } from 'react-router-dom'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { JobPostingFlowParams } from '@src/features/JobPostingFlow/types'
import { HiringStagesList } from '@src/features/JobPostingFlow/HiringProcess/HiringStagesList'
import { PageActions } from '@components/Page/PageActions'
import { InternalLink } from '@components/InternalLink/InternalLink'

interface HiringProcessProps {
  onAfterSubmit: VoidFunction
}

export const HiringProcess = ({ onAfterSubmit }: HiringProcessProps) => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams<JobPostingFlowParams>()

  return (
    <>
      <PageBody>
        <VStack gap="s-16">
          {values.specialisation?.id && (
            <HiringStagesList specialisationId={Number(values.specialisation.id)} />
          )}
        </VStack>
      </PageBody>
      <PageActions>
        <Button
          use={InternalLink}
          onClick={() => {
            navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.APPLICATION_FORM, params))
          }}
          variant="secondary"
          elevated
        >
          Back
        </Button>
        <NewSaveButtonWithPopup<JobPostingInterface>
          useValidator
          hideWhenNoChanges={false}
          noPopup
          onAfterSubmit={() => {
            onAfterSubmit()
            navigateTo(pathToUrl(ROUTES.FORMS.JOB_POSTING_FLOW.PUBLISH, params))
          }}
        >
          Next
        </NewSaveButtonWithPopup>
      </PageActions>
    </>
  )
}
