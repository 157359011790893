import { PageHeader } from '@components/Page/Header/PageHeader'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageWrapper } from '@components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { PermissionTransferProps } from '@src/pages/PermissionTransfer'
import { PermissionsInterface } from '@src/interfaces/permissions'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import { InputGroup, MoreBar, TextWidget } from '@revolut/ui-kit'
import addMonths from 'date-fns/addMonths'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import Form from '@src/features/Form/Form'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageActions } from '@components/Page/PageActions'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { Grid } from '@components/CommonSC/Grid'
import PermissionTransferRevokeDialogue from '@src/pages/PermissionTransfer/Dialogue/PermissionTransferRevokeDialogue'
import { navigateTo } from '@src/actions/RouterActions'
import { Dot } from '@src/pages/Forms/FormTitles'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

interface PermissionTransferFormProps extends PermissionTransferProps {}

const PermissionTransferForm = connect(
  ({ entityName, selector, formApi }: PermissionTransferFormProps) => {
    const [isDialogueOpen, setDialogueOpen] = useState(false)
    const params = useParams<{ entityId: string }>()
    const { values } = useLapeContext<PermissionsInterface>()
    const backUrl = pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.TABLE, params)
    const isExisting = !!values.id

    useEffect(() => {
      if (!values?.start_date_time) {
        values.start_date_time = new Date().toISOString()
        values.end_date_time = addMonths(new Date(), 3).toISOString()
      }
    }, [])

    return (
      <PageWrapper data-testid="permission-transfer-container">
        <PermissionTransferRevokeDialogue
          permission={values}
          revoke={id => formApi?.delete?.({ id }, params)}
          isOpen={isDialogueOpen}
          setOpen={setDialogueOpen}
          onAfterSubmit={() =>
            navigateTo(pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.TABLE, params))
          }
        />
        <PageHeader
          title={
            <>
              Assign permission
              <span>
                {values.status?.name && <Dot>·</Dot>}
                {values.status?.name}
              </span>
            </>
          }
          subtitle={
            <>
              {values.content_object?.name
                ? `${entityName}: ${values.content_object?.name || ''}`
                : ''}
              {entityName === 'Candidate' && (
                <>
                  {values.content_object?.name && <br />}
                  Provide candidate access to key stakeholders only if this is strictly
                  necessary
                </>
              )}
            </>
          }
          backUrl={backUrl}
        />
        <PageBody>
          <AutoStepper>
            {values.id && values?.status?.id !== 'expired' && (
              <Grid
                mb="s-32"
                flow="column"
                justifyContent="start"
                alignItems="center"
                gap={16}
              >
                <MoreBar>
                  <MoreBar.Action
                    variant="negative"
                    onClick={() => setDialogueOpen(true)}
                  >
                    Revoke permission
                  </MoreBar.Action>
                </MoreBar>
              </Grid>
            )}
            <InputGroup>
              <LapeRadioSelectInput<PermissionsInterface>
                name="permission"
                disabled={isExisting}
                label="Permission"
                selector={selector}
              />
              {values.permission?.extra?.description && (
                <TextWidget bg="action-background">
                  <TextWidget.Content>
                    This permission includes:
                    <br />
                    {values.permission?.extra?.description}
                  </TextWidget.Content>
                </TextWidget>
              )}
              <LapeRadioSelectInput<PermissionsInterface>
                name="employee"
                disabled={isExisting}
                label="Employee name"
                selector={selectorKeys.employee}
              />
              <InputGroup variant="horizontal">
                <LapeDatePickerInput
                  disabledDays={{ before: new Date() }}
                  defaultValue={new Date()}
                  required
                  disabled={isExisting}
                  name="start_date_time"
                  label="From Date"
                />
                <LapeDatePickerInput
                  disabledDays={
                    values.start_date_time
                      ? { before: new Date(values.start_date_time) }
                      : undefined
                  }
                  defaultValue={addMonths(new Date(), 3)}
                  required
                  name="end_date_time"
                  label="To Date"
                />
              </InputGroup>
              <LapeNewTextArea name="reason" label="Notes" rows={2} />
            </InputGroup>
          </AutoStepper>
        </PageBody>
        <PageActions>
          <NewSaveButtonWithPopup
            afterSubmitUrl={pathToUrl(ROUTES.FORMS.PERMISSION_MANAGEMENT.TABLE, params)}
            useValidator
          />
        </PageActions>
      </PageWrapper>
    )
  },
)

const PermissionTransferFormRouter = (props: PermissionTransferFormProps) => {
  return (
    <Form api={props.formApi}>
      <PermissionTransferForm {...props} />
    </Form>
  )
}

export default PermissionTransferFormRouter
