import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import { useGetGrowthPlan, useGetGrowthPlanRelation } from '@src/api/growthPlans'

interface Props {
  data?: GrowthPlanInterface
  isFetching: boolean
  refetch: VoidFunction
}

export const useGetEmployeeGrowthPlan = (employeeId: number): Props => {
  const {
    data: relation,
    isFetching: isRelationFetching,
    refetch: refetchRelation,
  } = useGetGrowthPlanRelation(employeeId)
  const {
    data,
    isFetching: isGrowthPlanFetching,
    refetch: refetchGrowtPlan,
  } = useGetGrowthPlan(relation?.current_growth_plan?.growth_plan?.id)

  const refetch = () => {
    refetchRelation()
    refetchGrowtPlan()
  }

  return {
    isFetching: isRelationFetching || isGrowthPlanFetching,
    data,
    refetch,
  }
}
