import React, { useMemo } from 'react'
import { addDays } from 'date-fns'

import { Box, Color, Flex, Text } from '@revolut/ui-kit'
import { Day, DayBox } from './Day'

const DAYS_IN_WEEK = 7

export const WeekDaysTitles = () => {
  return (
    <Flex gap="s-4">
      {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((dayTitle, idx) => (
        <DayBox key={idx}>
          <Text fontWeight={500} color={Color.GREY_TONE_50}>
            {dayTitle}
          </Text>
        </DayBox>
      ))}
    </Flex>
  )
}

export const WeekDivider = () => <Box height={1} bg={Color.GREY_TONE_10} width="100%" />

type Props = {
  firstDay: Date
  isDayOfCurrentMonth: (day: Date) => boolean
  renderDay?: (day: Date) => React.ReactNode
}
export const Week = ({ firstDay, isDayOfCurrentMonth, renderDay }: Props) => {
  const days = useMemo(() => {
    return new Array(DAYS_IN_WEEK).fill(null).reduce<Date[]>((acc, _, dayIdx) => {
      acc.push(addDays(firstDay, dayIdx))
      return acc
    }, [])
  }, [firstDay])

  return (
    <Flex flexDirection="column" gap="s-4">
      <Flex gap="s-4">
        {days.map(day => (
          <Day
            key={day.toString()}
            day={day}
            isDayOfCurrentMonth={isDayOfCurrentMonth(day)}
            renderContent={renderDay}
          />
        ))}
      </Flex>
      <WeekDivider />
    </Flex>
  )
}
