import React, { useState } from 'react'
import { Button, Grid } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import OnboardingActions from '../../components/OnboardingActions'
import { timeOffConfig } from '../../common/constants'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack } from '@src/actions/RouterActions'
import { TimeOffRequestsSession } from '@src/pages/Forms/ImportData/TimeOff/TimeOffRequests/TimeOffRequestsSession'
import { TimeOffBalancesSession } from '@src/pages/Forms/ImportData/TimeOff/TimeOffBalances/TimeOffBalancesSession'

interface UploadProps {
  type: 'requests' | 'balances'
}

const typeToComponent = {
  requests: TimeOffRequestsSession,
  balances: TimeOffBalancesSession,
}

export const Upload = ({ type }: UploadProps) => {
  const Component = typeToComponent[type]

  return (
    <Component
      customSuccessActions={
        <OnboardingActions
          config={timeOffConfig}
          currentStep="Balance adjustments"
          isLastStep
          disableBack={false}
          pendingBack={false}
          disableNext={false}
          pendingNext={false}
          nextRoute=""
          previousRoute={ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.IMPORT}
          updateSteps
          isForm={false}
        />
      }
      customActions={props => <CustomActions {...props} />}
    />
  )
}

interface CustomActionsProps {
  refetch: () => void
  disabled: boolean
  onSubmit: () => Promise<any>
}

const CustomActions = ({ refetch, disabled, onSubmit }: CustomActionsProps) => {
  const [pending, setPending] = useState(false)

  const onSubmitClick = async () => {
    setPending(true)

    try {
      await onSubmit()
      refetch()
    } finally {
      setPending(false)
    }
  }

  return (
    <PageActions>
      <Grid columns={2} gap="s-8" maxWidth={375}>
        <Button
          variant="secondary"
          onClick={() => {
            goBack(
              pathToUrl(ROUTES.ONBOARDING_CHECKLIST.TIME_OFF.BALANCE_ADJUSTMENTS.IMPORT),
            )
          }}
        >
          Back
        </Button>
        <Button onClick={onSubmitClick} pending={pending} disabled={disabled} elevated>
          Apply adjustments
        </Button>
      </Grid>
    </PageActions>
  )
}
