import React from 'react'
import { connect } from 'lape'
import { Box, BoxProps } from '@revolut/ui-kit'

import { useLapeField } from '@src/features/Form/LapeForm'
import { useNewChangelogProps } from '@src/utils/form'
import { getCommonInputProps } from './helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'
import { localDateToUtc, utcToLocalDate } from '@src/utils/timezones'
import {
  DatePickerInput,
  DatePickerInputProps,
} from '@src/components/Inputs/DatePickerInput/DatePickerInput'

interface Props extends DatePickerInputProps {
  name: string
  /** Marks field as optional otherwise */
  required?: boolean
  wrapperProps?: BoxProps
  onAfterChange?: (date: string | null) => void
  /** To display the date value as if user is in UTC timezone */
  displayInUtc?: boolean
  hasError?: boolean
}

export const onDatePickerInputChange = (
  date: Date | null,
  handler: (date: string | null) => void,
  onAfterChange?: (date: string | null) => void,
) => {
  if (!date) {
    handler(null)
    onAfterChange?.(null)
    return
  }
  const newDate = localDateToUtc(date)
  handler(newDate)
  onAfterChange?.(newDate)
}

const LapeDatePickerInput = ({
  name,
  wrapperProps,
  onAfterChange,
  displayInUtc = true,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const formValidator = useFormValidator()
  const changelogProps = useNewChangelogProps(name)
  const { hidden, value, onChange, touched, error } = lapeProps
  const validated = !!formValidator?.validated

  if (hidden) {
    return null
  }

  const { disabled, ...commonInputProps } = getCommonInputProps(
    { ...props, name },
    lapeProps,
    validated,
  )

  const pickerValue = (() => {
    if (value == null) {
      return null
    }
    return displayInUtc ? utcToLocalDate(value) : value
  })()

  return (
    <Box style={{ position: 'relative', flex: 1 }} {...wrapperProps}>
      <DatePickerInput
        value={pickerValue}
        onChange={date => onDatePickerInputChange(date, onChange, onAfterChange)}
        disabled={disabled}
        renderAction={() => <>{changelogProps.renderAction?.()}</>}
        {...props}
        {...commonInputProps}
        aria-invalid={(touched && error) || commonInputProps.hasError}
      />
    </Box>
  )
}

export default connect(LapeDatePickerInput)
