import React, { useState } from 'react'
import { RowInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable } from '@components/TableV2/hooks'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { MoreBar } from '@revolut/ui-kit'
import { Pencil, Plus } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { EntityInterface } from '@src/interfaces/enitities'
import {
  entityContractingCountriesColumn,
  entityGenericNameColumn,
  entityPaygroupCountriesColumn,
  entityRegistrationCountryColumn,
  entityRegistrationNumberColumn,
  entityStatusColumn,
} from '@src/constants/columns/entity'
import { entitiesRequests } from '@src/api/entities'
import { TableNames } from '@src/constants/table'
import { useGetOrganisationSettings } from '@src/api/settings'
import { EntitySidebarForm } from '@src/pages/Forms/EntityForm/EntitySidebarForm'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { useIsNewTable } from '@components/TableV2/hooks'

const RowNotifications: RowInterface<EntityInterface> = {
  cells: [
    {
      ...entityGenericNameColumn,
      width: 150,
    },
    {
      ...entityRegistrationCountryColumn,
      width: 120,
    },
    {
      ...entityRegistrationNumberColumn,
      width: 120,
    },
    {
      ...entityPaygroupCountriesColumn,
      width: 300,
    },
    {
      ...entityContractingCountriesColumn,
      width: 300,
    },
    {
      ...entityStatusColumn,
      width: 100,
    },
  ],
}

const EntityTable = () => {
  const isNewTable = useIsNewTable()
  const [addSidebarOpen, setAddSidebarOpen] = useState(false)
  const [addSidebarRenderKey, setAddSidebarRenderKey] = useState(0)
  const [enityId, setEnityId] = useState<number | undefined>()

  const table = useTable<EntityInterface>(entitiesRequests)
  const permissions = useSelector(selectPermissions)

  const { data: settings } = useGetOrganisationSettings()

  const canAdd = permissions.includes(PermissionTypes.AddEntity)
  const canChange = permissions.includes(PermissionTypes.ChangeEntity)

  return (
    <>
      <Table.Widget>
        {isNewTable ? null : (
          <Table.Widget.Info>
            <Stat label="Total" val={table?.loading ? undefined : table?.count} />
          </Table.Widget.Info>
        )}
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            {canAdd && (
              <PrimaryAction
                onClick={() => {
                  setAddSidebarOpen(true)
                  setAddSidebarRenderKey(prev => prev + 1)
                  setEnityId(undefined)
                }}
                useIcon={Plus}
              >
                Add Entity
              </PrimaryAction>
            )}
            {canChange && (
              <MoreBar.Action
                to={pathToUrl(ROUTES.FORMS.UPDATE_ORGANISATION_STRUCTURE.ENTITY)}
                use={InternalLink}
                useIcon={Pencil}
              >
                Edit
              </MoreBar.Action>
            )}
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable<EntityInterface>
            dataType="Entity"
            hiddenCells={{
              [entityPaygroupCountriesColumn.idPoint]:
                !settings?.enable_location_limitations,
              [entityContractingCountriesColumn.idPoint]:
                !settings?.enable_location_limitations,
            }}
            name={TableNames.Entities}
            emptyState={<EmptyTableRaw title="Entities will appear here" />}
            onRowClick={data => {
              setAddSidebarOpen(true)
              setAddSidebarRenderKey(prev => prev + 1)
              setEnityId(data.id)
            }}
            row={RowNotifications}
            useWindowScroll
            {...table}
          />
        </Table.Widget.Table>
      </Table.Widget>

      <EntitySidebarForm
        enityId={enityId}
        isOpen={addSidebarOpen}
        key={addSidebarRenderKey}
        onAfterSave={() => {
          setAddSidebarOpen(false)
          setEnityId(undefined)
          table.refresh()
          table.refreshStats()
        }}
        onClose={() => {
          setAddSidebarOpen(false)
          setEnityId(undefined)
        }}
      />
    </>
  )
}

export default EntityTable
