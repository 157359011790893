import React, { useEffect } from 'react'
import { InputGroup, Color } from '@revolut/ui-kit'

import { useEmployeePolicyAssigntmentFirstAccrual } from '@src/api/timeOff'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { selectorKeys } from '@src/constants/api'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { getExitUrl, HeaderSubtitle, usePolicyAssignmentParams } from './common'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useEmployeeFields } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'

export const General = () => {
  const params = usePolicyAssignmentParams()
  const { values } = useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()

  const { data: firstAccrual, isLoading: isFirstAccrualLoading } =
    useEmployeePolicyAssigntmentFirstAccrual(
      params.employeeId,
      !values.id ? values.policy?.id : undefined,
      values.start_date,
    )

  const { data: employeeData } = useEmployeeFields<
    Pick<EmployeeInterface, 'joining_date_time'>
  >(params.employeeId, ['joining_date_time'])

  useEffect(() => {
    if (!values.start_date && employeeData?.joining_date_time) {
      values.start_date = employeeData?.joining_date_time.split('T')[0]
    }
  }, [employeeData?.joining_date_time])

  useEffect(() => {
    if (typeof firstAccrual?.amount === 'number') {
      values.first_accrual_amount = firstAccrual.amount
    }
  }, [firstAccrual])

  const showFirstAccrualInfo =
    !values.id && (values.first_accrual_amount != null || firstAccrual?.amount != null)

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit policy assignment' : 'Assign policy'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW, params)
            : getExitUrl(params.employeeId)
        }
        subtitle={<HeaderSubtitle />}
      />

      <PageBody>
        <InputGroup>
          <LapeRadioSelectInput
            name="policy"
            label="Policy"
            selector={selectorKeys.active_time_off_policies}
            onAfterChange={() => {
              if (values.first_accrual_amount) {
                delete values.first_accrual_amount
              }
            }}
          />
          <LapeDatePickerInput name="start_date" label="Start date" required />
          <LapeDatePickerInput name="end_date" label="End date" />

          {showFirstAccrualInfo ? (
            <>
              <LapeNewInput
                name="first_accrual_amount"
                label="First accrual amount"
                required
                disabled={isFirstAccrualLoading}
              />
              <ActionWidget
                title="First accrual of this time-off policy was automatically calculated"
                text="Based on policy type and start date the first accrual was automatically calculated. However, if number of days doesn't seem correct, it may be manually changed."
                avatarColor={Color.BLUE}
              />
            </>
          ) : null}
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          previewUrl={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}
