import { history } from '../utils/router'
import { LocationState } from 'history'
import { useWorkspaceContext } from '@src/features/Workspaces/WorkspaceContext'

export const getBackUrl = (fallbackUrl?: string, state: LocationState = {}) => {
  const currentRoute = getLocationPathnameWithoutWorkspace() + history.location.search

  const originalHistory = history.location.state?.history
  const historyStateCopy = history.location.state?.history?.slice()
  const historyLength = originalHistory?.length

  /** To avoid circular links to the same place - e.g. I'm on `/some-route` and in that route I navigate to same `/same-route` path.
   *  Back button should not break and take me to where I actually came from. This removes the current route duplicates from the end of the history stack */
  if (typeof historyLength === 'number') {
    for (let i = historyLength; i > 0; i--) {
      if (originalHistory[i - 1] !== currentRoute) {
        break
      }
      historyStateCopy.pop()
    }
  }

  const historyStack = historyStateCopy ? historyStateCopy.slice(0, -1) : undefined
  const path = (historyStateCopy?.length ? historyStateCopy.at(-1) : fallbackUrl) || '/'

  const [url, search] = path.split('?')
  const newState = JSON.parse(
    JSON.stringify({ history: historyStack, search, ...(state as object) }),
  )

  return { pathname: url, search, state: newState }
}

export const goBack = (
  fallbackUrl?: string,
  state: LocationState = {},
  replace?: boolean,
) => {
  const { pathname, search, state: prevState } = getBackUrl(fallbackUrl, state)
  const path = search ? `${pathname}?${search}` : pathname

  if (replace) {
    history.replace(path, prevState)
  } else {
    history.push(path, prevState)
  }
}

export const getLocationPathnameWithoutWorkspace = () => {
  return history.location.pathname
}

export const getLocationDescriptor = (url: string, state: LocationState = {}) => {
  const newRoute = getLocationPathnameWithoutWorkspace() + history.location.search
  const historyStack = history.location.state?.history
    ? history.location.state.history.concat(newRoute)
    : [newRoute]
  return { pathname: url, state: { history: historyStack, ...(state as object) } }
}

export const getPrevLocationDescriptor = (
  url: string | object,
  state: LocationState = {},
) => {
  const historyStack = history.location.state?.history || []
  if (typeof url === 'object') {
    return { ...url, state: { history: historyStack, ...(state as object) } }
  }

  return { pathname: url, state: { history: historyStack, ...(state as object) } }
}

export const navigateTo = (
  url: string,
  state: LocationState = {},
  withSearch = false,
) => {
  const descriptor = getLocationDescriptor(url, state)
  const newState = JSON.parse(
    JSON.stringify({ history: descriptor.state.history, ...(state as object) }),
  )

  history.push(
    `${descriptor.pathname}${withSearch ? history.location.search : ''}`,
    newState,
  )
}

export const navigateReplace = (
  url: string,
  state: LocationState = {},
  withSearch = false,
) => {
  const newState = JSON.parse(
    JSON.stringify({ history: history.location?.state?.history, ...(state as object) }),
  )

  history.replace(`${url}${withSearch ? history.location.search : ''}`, newState)
}

export const useGetPathWithWorkspace = () => {
  const workspaceContext = useWorkspaceContext()

  return (path: string) =>
    workspaceContext?.workspace
      ? `/${workspaceContext.workspace}${path.startsWith('/') ? path : `/${path}`}`
      : path
}

export const useOpenNewTab = () => {
  const getPathWithWorkspace = useGetPathWithWorkspace()

  return (actionUrl: string) => {
    const isExternalLink = !!actionUrl.match(/^https?:\/\//i)

    if (isExternalLink) {
      window.open(actionUrl, '_blank')
    } else {
      window.open(getPathWithWorkspace(actionUrl), '_blank')
    }
  }
}
