import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { Group, Flex, Box } from '@revolut/ui-kit'
import { getIconImage } from '@src/pages/Hub/utils'
import { APPLICATIONS } from '@src/constants/hub'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import { LapeBooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanyPreferencesInterface, HRAppsToSetup } from '@src/interfaces/settings'

interface App {
  id: HRAppsToSetup
  title: string
  image: string
  description: string
  isRecommended: boolean
}

const apps: App[] = [
  {
    id: 'timeOff',
    title: APPLICATIONS.timeOff.title,
    image: APPLICATIONS.timeOff.image,
    description: 'I want to setup time off policies and allow employees to book time off',
    isRecommended: true,
  },
  {
    id: 'documents',
    title: APPLICATIONS.documents.title,
    image: APPLICATIONS.documents.image,
    description:
      'I want to upload my employee documents, such as ID’s, certificates and others',
    isRecommended: true,
  },
  {
    id: 'contracts',
    title: APPLICATIONS.contracts.title,
    image: APPLICATIONS.contracts.image,
    description: `I want to create and manage my employees' salaries and contracts details`,
    isRecommended: true,
  },
  {
    id: 'lifecycle',
    title: APPLICATIONS.lifecycle.title,
    image: APPLICATIONS.lifecycle.image,
    description: 'I want to setup on and offboarding workflows',
    isRecommended: false,
  },
  {
    id: 'payroll',
    title: APPLICATIONS.payroll.title,
    image: APPLICATIONS.payroll.image,
    description:
      'I want to setup payroll and be able to export reports for my payroll providers',
    isRecommended: false,
  },
]

const InitialHrApps = () => {
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCompanySettings)

  const { values } = useLapeContext<CompanyPreferencesInterface>()

  useEffect(() => {
    if (values.hr_apps_to_setup.selected == null) {
      values.hr_apps_to_setup.selected = true
      values.hr_apps_to_setup.timeOff = true
      values.hr_apps_to_setup.contracts = true
      values.hr_apps_to_setup.documents = true
      values.hr_apps_to_setup.lifecycle = true
    }
  }, [])

  return (
    <>
      <Box pb="s-16">
        <SectionTitle title="Do you want to setup the HR product now?" />

        <LapeBooleanRadioSwitch
          name="hr_apps_to_setup.selected"
          disabled={disableEdit}
          yesLabel={
            <Flex>
              Yes
              <Recommended />
            </Flex>
          }
          yesDescription="I want to setup the HR product during onboarding"
          noDescription="I’d like to skip this step for now. I will set up my HR processes later"
        />
      </Box>

      {values.hr_apps_to_setup.selected ? (
        <>
          <SectionTitle title="Which HR applications you would like to setup during onboarding?" />

          <Group>
            {apps.map(app => (
              <LapeNewSwitch
                itemTypeProps={{
                  title: app.isRecommended ? (
                    <Flex>
                      {app.title} <Recommended />
                    </Flex>
                  ) : (
                    app.title
                  ),
                  description: app.description,
                  image: getIconImage(app.image, 60, 'png'),
                }}
                name={`hr_apps_to_setup.${app.id}`}
                disabled={disableEdit}
                key={app.id}
              />
            ))}
          </Group>
        </>
      ) : null}
    </>
  )
}

export default InitialHrApps
