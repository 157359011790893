import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { HStack, Text, TextButton } from '@revolut/ui-kit'
import React from 'react'
import { DataRetentionCountriesInterface } from '@src/interfaces/dataRetentionSettings'
import { ExclamationMark } from '@revolut/icons'
import Table from '@components/TableV2/Table'

export const locationColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'name',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: 'name',
  selectorsKey: selectorKeys.countries,
  title: 'Location',
  insert: ({ data }) => (
    <HStack gap="s-4" align="center">
      {data.status === 'Draft' && <ExclamationMark color="orange" size={16} />}
      <Text>{data.name}</Text>
    </HStack>
  ),
}

const Section = ({
  data,
  sectionId,
}: {
  data: DataRetentionCountriesInterface
  sectionId: string
}) => {
  const sections = data.gdpr_policy?.sections
  const section = sections?.find(el => el.category.id === sectionId)

  if (!section) {
    return <Text>-</Text>
  }

  return (
    <Text>
      {section?.retention_period} {section?.time_unit?.name}
    </Text>
  )
}

export const employmentDataColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'employment',
  dataPoint: 'employment',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employment Data',
  insert: ({ data }) => <Section data={data} sectionId="employment" />,
}

export const personalDataColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'personal',
  dataPoint: 'personal',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Personal Data',
  insert: ({ data }) => <Section data={data} sectionId="personal" />,
}

export const benefitsDataColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'benefits',
  dataPoint: 'benefits',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Benefits Data',
  insert: ({ data }) => <Section data={data} sectionId="benefits" />,
}

export const timeAttendanceDataColumn: ColumnInterface<DataRetentionCountriesInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'timeAttendance',
    dataPoint: 'timeAttendance',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Time & Attendance Data',
    insert: ({ data }) => <Section data={data} sectionId="time_attendance" />,
  }

export const trainingDataColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'training',
  dataPoint: 'training',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Training Data',
  insert: ({ data }) => <Section data={data} sectionId="training" />,
}

export const payrollDataColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'payroll',
  dataPoint: 'payroll',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Payroll Data',
  insert: ({ data }) => <Section data={data} sectionId="payroll" />,
}

export const statusColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Status',
  insert: ({ data }) => (
    <Table.StatusCell
      status={data.status}
      variant={data.status === 'Active' ? 'success' : 'neutral'}
    />
  ),
}

export const actionsColumn: ColumnInterface<DataRetentionCountriesInterface> = {
  type: CellTypes.insert,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => (
    <TextButton>{data.status === 'Draft' ? 'Set Policy' : 'Review'}</TextButton>
  ),
}
