import React from 'react'
import { General } from '@src/pages/Forms/SpecialisationHiringProcess/General'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { HiringStage } from '@src/pages/Forms/HiringStage'

export const SpecialisationHiringProcess = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.GENERAL}>
        <General />
      </Route>
      <Route exact path={ROUTES.FORMS.SPECIALISATION_HIRING_PROCESS.HIRING_STAGE}>
        <HiringStage type="specialisation-hiring-process" />
      </Route>
    </Switch>
  )
}
