import {
  useGetEmployeeFreeBusySlots,
  useGetEmployeeMeetingEvents,
} from '@src/api/meetings'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'
import { NavigationWeek } from '@components/NavigateWeek/NavigateWeek'
import { OneToOneMeeting } from '@src/interfaces/meetings'
import {
  adaptFreeBusySlotsToCalendarEvents,
  adaptOneToOneMeetingToCalendarEvent,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ScheduleOneToOne/adaptors/events'

interface CalendarEvents {
  isLoading: boolean
  events: LargeWeeklyCalendarEventInterface[]
}

export const useCalendarEvents = (
  meeting: OneToOneMeeting,
  freeBusyOverlap: boolean,
  week: NavigationWeek,
): CalendarEvents => {
  const dateRange = [week.start, week.end].join(',')
  const { manager, employee } = meeting

  const { data: oneToOnes, isLoading: isEventsLoading } = useGetEmployeeMeetingEvents({
    employee_id: manager.id,
    is_assigned: true,
    is_series: false,
    start: dateRange,
  })
  const { data: freeBusySlots, isLoading: isFreeBusySlotsLoading } =
    useGetEmployeeFreeBusySlots({
      employee_ids: freeBusyOverlap
        ? [manager.id, employee.id].join(',')
        : manager.id.toString(),
      time_min: week.start,
      time_max: week.end,
    })

  const oneToOneEvents = oneToOnes?.results ?? []
  const oneToOneCalendarEvents = oneToOneEvents.map(adaptOneToOneMeetingToCalendarEvent)
  const freeBusyCalendarEvents = freeBusySlots
    ? adaptFreeBusySlotsToCalendarEvents(freeBusySlots, oneToOneEvents)
    : []
  const events = [...oneToOneCalendarEvents, ...freeBusyCalendarEvents]

  return {
    isLoading: isEventsLoading || isFreeBusySlotsLoading,
    events,
  }
}
