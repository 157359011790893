import React from 'react'
import styled from 'styled-components'
import { TeamInterface } from '@src/interfaces/teams'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { Token } from '@revolut/ui-kit'
import { formatNumberMillions } from '@src/utils/format'
import { DepartmentCompensationReviewInterface } from '@src/interfaces/compensation'
import { CXTooltip } from './CXCirclesTooltip'

const CircleContainer = styled.div`
  margin-right: 6px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
`

const ColoredCircle = styled.div<{ color: string }>`
  display: inline-block;
  flex-shrink: 0;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.color};
`

const ColoredText = styled.span<{ color: string }>`
  color: ${props => props.color};
`

const AuditColoredText = styled(ColoredText)`
  margin-left: 2px;
`

interface Props {
  data: TeamInterface | DepartmentInterface | DepartmentCompensationReviewInterface
}

const CXCircles = ({ data }: Props) => {
  if (
    data.cx_complaints_last_30_days === 0 &&
    data.cx_csat_detractors_last_30_days === 0 &&
    data.cx_support_load_last_30_days === 0 &&
    data.cx_trustpilot_last_30_days === 0
  ) {
    return <ColoredText color={Token.color.greyTone20}>0</ColoredText>
  }
  return (
    <CXTooltip data={data}>
      {!!data.cx_csat_detractors_last_30_days && (
        <CircleContainer>
          <ColoredCircle color={Token.color.greyTone50} />
          <AuditColoredText color={Token.color.greyTone50}>
            {formatNumberMillions(data.cx_csat_detractors_last_30_days)}
          </AuditColoredText>
        </CircleContainer>
      )}
      {!!data.cx_support_load_last_30_days && (
        <CircleContainer>
          <ColoredCircle color={Token.color.greyTone50} />
          <AuditColoredText color={Token.color.greyTone50}>
            {formatNumberMillions(data.cx_support_load_last_30_days)}
          </AuditColoredText>
        </CircleContainer>
      )}
      {!!data.cx_complaints_last_30_days && (
        <CircleContainer>
          <ColoredCircle color={Token.color.greyTone50} />
          <AuditColoredText color={Token.color.greyTone50}>
            {formatNumberMillions(data.cx_complaints_last_30_days)}
          </AuditColoredText>
        </CircleContainer>
      )}
    </CXTooltip>
  )
}

export default CXCircles
