import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { usePerformanceStats } from '@src/api/goals'
import { FilterByInterface } from '@src/interfaces/data'
import { StatNavigation } from '@src/components/StatNavigation/StatNavigation'
import { NavigationConfigInterface } from '@src/components/StatNavigation/types'
import { useGetCompany } from '@src/api/company'
import { useGetNPS } from '@src/pages/Organisation/components/CompanyNavigation/useGetNPS'
import { FeatureFlags, GlobalSettings, PermissionTypes } from '@src/store/auth/types'

interface Props {
  goalFilters?: FilterByInterface[]
  roadmapFilters?: FilterByInterface[]
  eNPS?: number | null
}

interface ConfigInterface {
  overall_progress: number
  avg_done: number
  talent: number
  dashboard_count: number
  issues: number
  risk: number
  engagement: number | null
}

const config: NavigationConfigInterface<ConfigInterface>[] = [
  {
    key: 'overall_progress',
    label: 'Goals',
    to: ROUTES.ORGANISATION.COMPANY.GOALS.GENERAL,
    isPercent: true,
    canView: {
      featureFlags: [FeatureFlags.CanAddGoals],
    },
  },
  {
    key: 'avg_done',
    label: 'Roadmaps',
    to: ROUTES.ORGANISATION.COMPANY.ROADMAP,
    isPercent: true,
    canView: {
      globalSettings: [GlobalSettings.RoadmapsEnabled],
    },
  },
  {
    key: 'talent',
    label: 'Talent',
    to: ROUTES.ORGANISATION.COMPANY.TALENT.PERFORMANCE,
    isPercent: true,
    canView: {
      permissions: [PermissionTypes.GlobalTalentViewerPermissions],
    },
  },
  {
    key: 'dashboard_count',
    label: 'Analytics',
    to: ROUTES.ORGANISATION.COMPANY.ANALYTICS,
    canView: {
      globalSettings: [GlobalSettings.CommercialProductDisabled],
    },
  },
  {
    key: 'issues',
    label: 'Issues',
    to: ROUTES.ORGANISATION.COMPANY.ISSUES,
    canView: {
      featureFlags: [FeatureFlags.Findings],
    },
  },
  {
    key: 'risk',
    label: 'Risk',
    path: ROUTES.ORGANISATION.COMPANY.RISK.ANY,
    to: ROUTES.ORGANISATION.COMPANY.RISK.SUMMARY,
    canView: {
      permissions: [PermissionTypes.RiskManagerPermissions],
      globalSettings: [GlobalSettings.CommercialProductDisabled],
    },
  },
  {
    key: 'engagement',
    label: 'Engagement',
    path: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.ANY,
    to: ROUTES.ORGANISATION.COMPANY.ENGAGEMENT.CATEGORIES,
    isPercent: true,
    canView: {
      permissions: [PermissionTypes.ViewEngagementTabs],
    },
  },
]

export const CompanyNavigation = ({ goalFilters, roadmapFilters, eNPS }: Props) => {
  const api = usePerformanceStats({
    goalFilters,
    roadmapFilters,
    isDepartmentsRoadmap: true,
  })
  const { data, isLoading } = useGetCompany()

  const { score, isLoading: isLoadingNPS } = useGetNPS()

  const stats = {
    data: {
      overall_progress: api.data?.overall_progress || 0,
      avg_done: api.data?.avg_done || 0,
      dashboard_count: data?.dashboard_count || 0,
      talent: (data?.talent || 0) * 100,
      issues: data?.issues || 0,
      risk: data?.risk_incidents || 0,
      engagement: eNPS === null ? null : score || null,
    },
    isLoading: api.isLoading || isLoading || isLoadingNPS,
  }

  return <StatNavigation config={config} api={() => stats} />
}
