import { BottomSheet, Flex, useStatusPopup, StatusPopup } from '@revolut/ui-kit'
import { FeedbackCard } from '@src/features/Feedback/FeedbackCard'
import React, { useState } from 'react'
import { postFeedbackRequest } from '@src/api/user'
import { useGetFeedbackLabels } from '@src/api/feedback'
import { IdAndName } from '@src/interfaces'

interface TicketFeedbackRatePopupProps {
  ticketId: number
  isOpen: boolean
  onClose: () => void
}

export const TicketFeedbackRatePopup = ({
  ticketId,
  isOpen,
  onClose,
}: TicketFeedbackRatePopupProps) => {
  const statusPopup = useStatusPopup()
  const [labelsValue, setLabelsValue] = useState<IdAndName<string | number>[]>([])
  const [commentValue, setComment] = useState<string | undefined>()
  const [isPending, setIsPending] = useState(false)
  const [scoreValue, setScoreValue] = useState<number | null>(null)
  const ratingLabels = useGetFeedbackLabels('support', scoreValue, isOpen)

  const handleSubmit = async () => {
    if (!scoreValue) {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Please rate before submitting</StatusPopup.Title>
        </StatusPopup>,
      )
      return
    }

    setIsPending(true)
    try {
      await postFeedbackRequest({
        status: { id: 'completed' },
        score: scoreValue * 0.2,
        text: commentValue,
        topic: { id: 'support' },
        labels: labelsValue.map(label => label.id),
        object_id: ticketId,
      })
      onClose()
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Thank you</StatusPopup.Title>
          <StatusPopup.Description>
            Your feedback is helping us to improve
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } catch {
      statusPopup.show(
        <StatusPopup onClose={statusPopup.hide} variant="error">
          <StatusPopup.Title>Feedback was not submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  return (
    <BottomSheet onClose={onClose} open={isOpen}>
      <Flex alignItems="center" data-autofocus="true" flexDirection="column">
        <FeedbackCard
          pending={isPending}
          placeholder="Add comment (optional)"
          score={scoreValue}
          text={commentValue}
          title={`Rate your experience`}
          onSubmit={handleSubmit}
          onScoreChange={setScoreValue}
          onTextChange={setComment}
          labels={labelsValue}
          labelsOptions={ratingLabels}
          onLabelClick={option => {
            const checked = !!labelsValue.find(label => label.id === option.id)

            if (checked) {
              setLabelsValue(prev => prev.filter(label => label.id !== option.id))
            } else {
              setLabelsValue(prev => [...prev, option])
            }
          }}
        />
      </Flex>
    </BottomSheet>
  )
}
