import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { General } from '@src/pages/Settings/Requisitions/General'
import { PageWrapper } from '@src/components/Page/Page'
import { Route, Switch } from 'react-router-dom'
import { NoAccessError } from '@src/features/Form/FormErrorGuard'
import { CountryHiring } from '@src/pages/Settings/Requisitions/CountryHiring/CountryHiring'
import { CountryHiringForm } from '@src/pages/Settings/Requisitions/CountryHiring/CountryHiringForm'
import { ApprovalSettings } from '@src/pages/Settings/Requisitions/ApprovalSettings'

export const RequisitionsSettings = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.REQUISITIONS.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING}>
          <CountryHiring />
        </Route>
        <Route exact path={ROUTES.SETTINGS.REQUISITIONS.COUNTRY_HIRING_FORM}>
          <CountryHiringForm />
        </Route>
        <Route exact path={ROUTES.SETTINGS.REQUISITIONS.APPROVAL}>
          <ApprovalSettings />
        </Route>
        <Route>
          <NoAccessError />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
