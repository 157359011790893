import { Dispatch, RefObject, useEffect, useRef } from 'react'

import {
  ActionInterface,
  AddMessageToHistory,
  buildHelpDeskUrl,
  buildPreFilledHelpDeskUrl,
  DEFAULT_ISSUE_TYPE,
  DEFAULT_PROJECT_KEY,
  defaultContext,
  StateInterface,
  topicNameToCategory,
} from './common'
import {
  createTicketFromChatConversation,
  notifyChatBotSessionClosed,
  notifyChatBotTicketRaised,
} from '@src/api/faq'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectUser } from '@src/store/auth/selectors'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { FeatureFlags } from '@src/store/auth/types'

export const useSendGreetingMessageOnOpen = ({
  addMessageToHistory,
  inputRef,
}: {
  addMessageToHistory: AddMessageToHistory
  inputRef: RefObject<HTMLInputElement>
}) =>
  useEffect(() => {
    inputRef.current?.focus()

    addMessageToHistory({
      from: 'chatbot',
      text: "<div><p>I'm Rita, the chatbot.</p><p>How can I help you?</p></div>",
    })
  }, [])

export const useAutoScrollAndFocusInputOnUpdate = ({
  state,
  inputRef,
  dialogBottomRef,
}: {
  state: StateInterface
  inputRef: RefObject<HTMLInputElement>
  dialogBottomRef: RefObject<HTMLDivElement>
}) =>
  useEffect(() => {
    if (!state.pending && !state.confirmEndConversation) {
      inputRef.current?.focus()
    }
    setTimeout(() => {
      dialogBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }, [
    state.pending,
    state.confirmEndConversation,
    state.showCommands,
    state.showSuggestions,
    state.history.length,
    state.inputMode,
  ])

export const useCreateBackgroundTicket = ({
  state,
  dispatch,
  addMessageToHistory,
  faqTopic,
}: {
  state: StateInterface
  dispatch: Dispatch<ActionInterface>
  addMessageToHistory: AddMessageToHistory
  faqTopic?: FaqTopicInterface
}) => {
  const user = useSelector(selectUser)
  const featureFlags = useSelector(selectFeatureFlags)

  const handlePrefilledTicket = () => {
    addMessageToHistory({
      text:
        '<div>' +
        `<p>A ticket has been pre-filled for you in the <a href="${buildPreFilledHelpDeskUrl(
          {
            summary: state.questionContext.summary || '',
            employeeId: user.id,
            categoryId: faqTopic?.title ? topicNameToCategory[faqTopic.title] : undefined,
          },
        )}">HR Service Desk</a>.` +
        '<p>For the fastest resolution, please use the link to provide all necessary details, including mandatory fields.</p>' +
        '<p>More information will enable us to expedite the process. Thanks!</p>' +
        '</div>',
      from: 'chatbot',
    })
  }

  const handleEndConversation = () => {
    dispatch({
      type: 'setQuestionContext',
      data: { questionContext: defaultContext },
    })
    dispatch({ type: 'resetPending' })
    dispatch({ type: 'endConversation' })
  }

  return useEffect(() => {
    if (state.questionContext.create && state.questionContext.summary) {
      dispatch({ type: 'setPending' })

      if (featureFlags.includes(FeatureFlags.ChatbotBackgroundTickets)) {
        createTicketFromChatConversation({
          userId: user.id,
          userEmail: user.email,
          summary: state.questionContext.summary,
          description: state.history
            .map(({ from, text }) => `${from}: ${text}`)
            .join('\r\n'),
          issueType: DEFAULT_ISSUE_TYPE,
          projectKey: faqTopic?.category.project_key || DEFAULT_PROJECT_KEY,
          categoryId: faqTopic?.title ? topicNameToCategory[faqTopic.title] : undefined,
        })
          .then(res => {
            notifyChatBotTicketRaised('raised')

            const linkToIssue = buildHelpDeskUrl({
              helpDeskUrl: faqTopic?.customer_portal,
              key: res.data.key,
            })

            if (linkToIssue) {
              addMessageToHistory({
                text:
                  '<div>' +
                  `<p>A ticket has been opened for you in the HR Service Desk <a href=${linkToIssue}>${res.data.key}</a>.</p>` +
                  `<p>HR manager will review your query and try to answer it as soon as possible. Follow the ticket for more details</p>` +
                  '</div>',
                from: 'chatbot',
              })
            } else {
              addMessageToHistory({
                text:
                  '<div>' +
                  `<p>A ticket has been opened for you in the HR Service Desk.</p>` +
                  `<p>HR manager will review your query and try to answer it as soon as possible. Check your email for more details</p>` +
                  '</div>',
                from: 'chatbot',
              })
            }
          })
          .catch(() => {
            handlePrefilledTicket()
          })
          .finally(() => {
            handleEndConversation()
          })
      } else {
        handlePrefilledTicket()
        handleEndConversation()
        notifyChatBotTicketRaised('prefilled')
      }
    }
  }, [state.questionContext.create])
}

export const useOnIssueResolved = (state: StateInterface, callback: () => void) => {
  const issueResolved = useRef(state.issueResolved)

  useEffect(() => {
    return () => {
      notifyChatBotSessionClosed(issueResolved.current)
    }
  }, [])

  useEffect(() => {
    if (state.issueResolved) {
      callback()
    }
    issueResolved.current = state.issueResolved
  }, [state.issueResolved])
}
