import React, { useState } from 'react'
import { Box, Group, MoreBar, Subheader } from '@revolut/ui-kit'

import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SchedulingPolicyInterface } from '@src/interfaces/attendance'
import SettingsButtons from '@src/features/SettingsButtons'
import { Archive, Pencil, Unarchive } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { DeleteSchedulingPolicyButton, ShiftTable, useTitle } from './common'
import { useTable } from '@src/components/Table/hooks'
import { archivePolicy, getScheduleShifts, unarchivePolicy } from '@src/api/attendance'
import { Statuses } from '@src/interfaces'

const Preview = () => {
  const { values, reset } = useLapeContext<SchedulingPolicyInterface>()

  const table = useTable({ getItems: getScheduleShifts(values.id) })

  const [archivePending, setArchivePending] = useState(false)

  const title = useTitle(values)

  const isActive = values.status.id === Statuses.active
  const isArchived = values.status.id === Statuses.archived

  const onArchiveClick = (action: 'archive' | 'unarchive') => {
    setArchivePending(true)

    const callback = action === 'archive' ? archivePolicy : unarchivePolicy

    callback(values.id)
      .then(response => reset(response.data))
      .finally(() => setArchivePending(false))
  }

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.APPS.ATTENDANCE.POLICIES}>
        <SettingsButtons>
          <MoreBar.Action
            onClick={() =>
              navigateTo(
                pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.GENERAL, { id: values.id }),
              )
            }
            useIcon={Pencil}
          >
            Edit
          </MoreBar.Action>
          {isArchived ? (
            <MoreBar.Action
              onClick={() => onArchiveClick('unarchive')}
              useIcon={Unarchive}
              pending={archivePending}
            >
              Unarchive
            </MoreBar.Action>
          ) : null}
          {isActive ? (
            <MoreBar.Action
              onClick={() => onArchiveClick('archive')}
              useIcon={Archive}
              variant="negative"
              pending={archivePending}
            >
              Archive
            </MoreBar.Action>
          ) : null}
          <DeleteSchedulingPolicyButton />
        </SettingsButtons>
      </PageHeader>

      <PageBody>
        <FormPreview title="Basic information" data={values}>
          <Group>
            <FormPreview.Item title="Location" field="location.name" />
            <FormPreview.Item
              title="Eligibility groups"
              insert={() => values.eligibility_groups.map(g => g.name).join(', ')}
            />
            <FormPreview.Item title="Owner" field="owner" type="employee" />
            <FormPreview.Item
              title="Created on"
              field="creation_date_time"
              type="dateTime"
            />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <FormPreview title="Time characteristics" data={values}>
            <Group>
              <FormPreview.Item title="Daily nominal hours" field="daily_nominal_hours" />
              <FormPreview.Item
                title="Weekly nominal hours"
                field="weekly_nominal_hours"
              />
              <FormPreview.Item
                title="Consecutive working days limit"
                field="consecutive_working_days_limit"
              />
              <FormPreview.Item title="Unsocial time start" field="unsocial_time_start" />
              <FormPreview.Item title="Unsocial time end" field="unsocial_time_end" />
              <FormPreview.Item
                title="Work default start time"
                field="work_default_start_time"
              />
              <FormPreview.Item
                title="Work default end time"
                field="work_default_end_time"
              />
              <FormPreview.Item
                type="boolean"
                title="Employee can edit default work time"
                field="default_work_time_editable"
              />
              <FormPreview.Item
                type="boolean"
                title="Allow regular work on non-working days"
                field="allow_regular_work_on_non_working_days"
              />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-16">
          <FormPreview title="Overtime limits (in hours)" data={values}>
            <Group>
              <FormPreview.Item<SchedulingPolicyInterface>
                title="Minimum rest period"
                field="minimum_rest_period"
              />
              <FormPreview.Item title="Daily" field="maximum_daily_overtime" />
              <FormPreview.Item title="Weekly" field="maximum_weekly_overtime" />
              <FormPreview.Item title="Monthly" field="maximum_monthly_overtime" />
              <FormPreview.Item title="Yearly" field="maximum_yearly_overtime" />
              <FormPreview.Item
                title="Accounting period"
                field="accounting_period.name"
              />
              <FormPreview.Item
                title="Time-off policy for Time-off in Lieu (TOIL)"
                field="toil_policy.name"
              />
              <FormPreview.Item
                title="Notification trigger"
                field="notification_threshold_overtime"
                insert={() =>
                  values?.notification_threshold_overtime
                    ? `${values.notification_threshold_overtime}%`
                    : '-'
                }
              />
            </Group>
          </FormPreview>
        </Box>

        <Box mt="s-32">
          <Subheader variant="nested">
            <Subheader.Title>Shifts</Subheader.Title>
          </Subheader>
          <ShiftTable policyId={values.id} table={table} hideActions />
        </Box>
      </PageBody>
    </PageWrapper>
  )
}

export default Preview
