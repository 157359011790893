import { Flex, AvatarSkeleton, TextSkeleton } from '@revolut/ui-kit'
import React from 'react'

export const HeaderSummarySkeleton = () => (
  <Flex
    pr="s-72"
    pb="s-4"
    gap="s-72"
    style={{ flexGrow: 1 }}
    justifyContent="space-between"
  >
    <Flex ml="s-4" gap="s-16" alignItems="flex-end">
      <AvatarSkeleton size={76} />
      <Flex gap="s-2" mb="s-6" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex gap="s-16" flexDirection="column">
      <TextSkeleton size={14} variant="emphasis2" />

      <Flex gap="s-16" alignItems="center">
        <AvatarSkeleton size={40} />
        <Flex gap="s-2" flexDirection="column">
          <TextSkeleton size={16} variant="emphasis1" />
          <TextSkeleton size={14} variant="body2" />
        </Flex>
      </Flex>
    </Flex>

    <Flex gap="s-16" alignItems="flex-end">
      <AvatarSkeleton size={40} />
      <Flex gap="s-2" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>

    <Flex gap="s-16" alignItems="flex-end">
      <AvatarSkeleton size={40} />
      <Flex gap="s-4" flexDirection="column">
        <TextSkeleton size={16} variant="emphasis1" />
        <TextSkeleton size={14} variant="body2" />
      </Flex>
    </Flex>
  </Flex>
)
