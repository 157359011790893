import React from 'react'
import {
  AvatarSkeleton,
  chain,
  Flex,
  Grid,
  HStack,
  Tag,
  Text,
  TextButton,
  TextSkeleton,
  Token,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import {
  InterviewFeedbackInterface,
  InterviewStageWithoutRoundInterface,
} from '@src/interfaces/interviewTool'
import { formatDateTime } from '@src/utils/format'
import { getFeedbackBadge } from '@src/pages/Forms/Candidate/StagesTable/StagesTableFeedback'
import { UserAvatar } from '@src/components/UserWithAvatar/UserAvatar'
import { UserLink } from '@src/components/UserWithAvatar/UserLink'

export const OverallImpressionFeedbackSkeleton = () => {
  return (
    <Widget p="s-16">
      <VStack gap="s-8">
        <Flex justifyContent="space-between" alignItems="center">
          <HStack align="center">
            {chain(
              <HStack gap="s-4">
                <AvatarSkeleton size={24} />
                <TextSkeleton width="s-48" />
              </HStack>,
              <TextSkeleton width="s-48" />,
            )}
          </HStack>
          <HStack gap="s-4">
            <Tag variant="outlined">
              <TextSkeleton width="s-48" />
            </Tag>
            <Tag variant="outlined">
              <TextSkeleton width="s-48" />
            </Tag>
          </HStack>
        </Flex>
        <TextSkeleton width="100%" />
      </VStack>
    </Widget>
  )
}

type ResultTagProps = {
  interviewFeedback: InterviewFeedbackInterface
}

const ResultTag = ({ interviewFeedback }: ResultTagProps) => {
  const badge = getFeedbackBadge(
    interviewFeedback?.result || undefined,
    interviewFeedback.status,
  )
  return (
    <Tag
      variant="outlined"
      useIcon={badge.icon}
      color={Token.color.deepGrey}
      style={{ whiteSpace: 'nowrap' }}
    >
      {badge.text}
    </Tag>
  )
}

type OverallImpressionFeedbackProps = {
  compact?: boolean
  interviewFeedback: InterviewFeedbackInterface
  stage: InterviewStageWithoutRoundInterface
  onViewScorecard: (feedbackIds: number[]) => void
}

export const OverallImpressionFeedback = ({
  compact = false,
  interviewFeedback,
  stage,
  onViewScorecard,
}: OverallImpressionFeedbackProps) => {
  return (
    <Widget p="s-16">
      <VStack gap="s-8">
        <Grid
          columns={{ all: 1, md: compact ? 1 : 2 }}
          placeContent="space-between"
          rowGap="s-8"
        >
          <HStack gap="s-8">
            <UserAvatar {...interviewFeedback.interviewer} size={40} />
            <VStack>
              <UserLink {...interviewFeedback.interviewer} />
              <Text variant="body2" color={Token.color.greyTone50}>
                {formatDateTime(interviewFeedback.updated_date_time)}
              </Text>
            </VStack>
          </HStack>
          <Grid
            placeItems={{
              all: 'center start',
              md: compact ? 'center start' : 'center end',
            }}
          >
            <HStack gap="s-8" align="end">
              <Tag
                variant="outlined"
                useIcon="Microphone"
                color={Token.color.deepGrey}
                style={{ whiteSpace: 'nowrap' }}
              >
                {stage.title}
              </Tag>
              <ResultTag interviewFeedback={interviewFeedback} />
            </HStack>
          </Grid>
        </Grid>
        <Text whiteSpace="break-spaces">{interviewFeedback.overall_impressions}</Text>
        {!!interviewFeedback.scorecard && (
          <TextButton
            onClick={() => {
              onViewScorecard([interviewFeedback.id])
            }}
          >
            View scorecard
          </TextButton>
        )}
      </VStack>
    </Widget>
  )
}
