import React from 'react'
import { Box, Flex, Text, Token, Widget } from '@revolut/ui-kit'
import PredefinedLabel from '@src/pages/Careers/CareersApplication/PredefinedSectionLabel'

export interface ApplicationFormWidgetSectionInterface {
  locked?: boolean
  onClickEdit?: (idx: number) => void
  idx?: number
  actions?: React.ReactNode
  title?: React.ReactNode
  description?: string | null
  children?: React.ReactNode
  disableEditing?: boolean
}

const ApplicationFormWidgetSection = ({
  locked,
  onClickEdit,
  idx,
  actions,
  title,
  description,
  children,
  disableEditing = false,
}: ApplicationFormWidgetSectionInterface) => {
  return (
    <Widget
      style={{
        cursor: locked || disableEditing ? 'auto' : 'pointer',
      }}
      onClick={() => {
        if (!locked && !disableEditing && idx !== undefined) {
          onClickEdit?.(idx)
        }
      }}
      width="100%"
      display="block"
      p="s-16"
      data-testid={`preview_section_${idx}`}
    >
      <Box>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex justifyContent="space-between" alignItems="center" gap="s-8" mb="s-4">
            <Text variant="h4" display="block">
              {title || 'Section'}
            </Text>
            {locked && <PredefinedLabel />}
          </Flex>
          {actions}
        </Flex>
        {description && (
          <Box mb="s-16">
            <Text variant="body2" color={Token.color.greyTone50}>
              {description}
            </Text>
          </Box>
        )}
      </Box>
      {children}
    </Widget>
  )
}

export default ApplicationFormWidgetSection
