import React from 'react'
import styled from 'styled-components'
import {
  PreviousDeliverablesValue,
  PreviousReviewInterface,
} from '@src/interfaces/performance'
import {
  getQuarterAndYearFromCycle,
  PerformanceRatingTitle,
} from '@src/constants/performance'

const Wrap = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  margin-left: auto;
`

interface Props {
  previousReviews?: PreviousReviewInterface[]
  previousValues?: PreviousDeliverablesValue[] | null
}

const PreviousDeliverablesValues = ({ previousReviews, previousValues }: Props) => {
  if (!previousReviews?.length || !previousValues?.length) {
    return null
  }

  const value = previousValues
    .map(item => {
      const cycle = previousReviews.find(
        reviewItem => reviewItem.review.id === item.review_id,
      )?.cycle

      const name = getQuarterAndYearFromCycle(cycle)

      return name && item.value ? `${name}: ${PerformanceRatingTitle[item.value]}` : ''
    })
    .join(', ')

  return <Wrap>({value})</Wrap>
}

export default PreviousDeliverablesValues
