import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys, selectorToUrl } from '../api'
import { KpiInterface } from '@src/interfaces/kpis'
import GraphIconChart from '../../components/Charts/GraphIconChart/GraphIconChart'
import {
  ColoredPercent,
  getPercentColor,
} from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import Icon from '../../components/Icon/Icon'
import React from 'react'
import { PercentageWrapper } from '@components/CommonSC/TableStatistics'
import { ColoredText } from '@components/CommonSC/General'
import { KPITypes } from '../table'
import { Statuses } from '@src/interfaces'
import Tooltip from '../../components/Tooltip/Tooltip'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import styled from 'styled-components'
import { getCompanyGoalsPerformanceGraph } from '@src/api/companyGoals'
import { getKpiPerformanceGraph } from '@src/api/kpis'
import { formatNumberMillions } from '@src/utils/format'
import { Flex, Token, Icon as UIKitIcon } from '@revolut/ui-kit'
import { kpiWeightColumn } from '@src/constants/columns/kpi'
import get from 'lodash/get'
import { ROUTES } from '@src/constants/routes'
import TableCellLink from '@components/TableCellLink/TableCellLink'

const UnlinkedKPIContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  align-items: center;
  font-style: italic;
  color: ${Token.color.greyTone50};
`
const UnlinkedKPITitle = styled.div`
  margin-top: 1px;
`
const UnlinkedKPIIcon = styled(Icon)`
  margin-right: 6px;
  color: ${Token.color.orange};
`

export const companyGoalsName: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'name',
  sortKey: 'name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.id !== -1) {
      let to = getLocationDescriptor(
        pathToUrl(ROUTES.FORMS.KPI.PREVIEW, { id: get(data, 'id') }),
      )
      if (data.type === KPITypes.company_goal || !data.id) {
        to = getLocationDescriptor(
          pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, { id: get(data, 'id') }),
        )
      }

      return <TableCellLink to={to}>{data.name}</TableCellLink>
    }

    return (
      <UnlinkedKPIContainer>
        <UnlinkedKPIIcon size="tiny" type="WarningOutlined" />
        <UnlinkedKPITitle>{data.name}</UnlinkedKPITitle>
      </UnlinkedKPIContainer>
    )
  },
  title: 'KPI name',
}

export const companyGoalsKPILevel: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'team_kpi',
  dataPoint: 'team_kpi',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  dynamicHyperlinks: data => {
    if (data.type === KPITypes.team_kpi) {
      return pathToUrl(selectorToUrl[selectorKeys.team] as string, {
        id: data.team?.id,
      })
    }

    if (data.type === KPITypes.department_kpi) {
      return pathToUrl(selectorToUrl[selectorKeys.department] as string, {
        id: data.department?.id,
      })
    }

    return null
  },
  title: 'Organisational unit',
}

export const companyKPICategory: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'kpi_type',
  dataPoint: 'kpi_type',
  sortKey: 'kpi_type__name',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.id === -1) {
      return ' '
    }

    switch (data.type) {
      case KPITypes.company_goal:
      case KPITypes.company_kpi:
        return 'Company'
      case KPITypes.department_kpi:
        return 'Department'
      case KPITypes.team_kpi:
        return 'Team'
      case KPITypes.employee_kpi:
        return 'Employee'
      case KPITypes.function_kpi:
        return 'Function'
    }

    return ' '
  },
  title: 'Level',
}

export const companyGoalsPerformance: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'performance',
  dataPoint: 'performance',
  sortKey: 'performance',
  filterKey: 'performance',
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    if (data.id === -1) {
      return ' '
    }
    return data.type === KPITypes.company_goal ? (
      <GraphIconChart fetchData={getCompanyGoalsPerformanceGraph} id={data.id}>
        <Flex width={75} justifyContent="space-between">
          <PercentageWrapper>
            <ColoredPercent percent={data.performance * 100} />
            <Icon type="Graph" size="tiny" />
          </PercentageWrapper>
        </Flex>
      </GraphIconChart>
    ) : (
      <GraphIconChart id={data.id} fetchData={getKpiPerformanceGraph}>
        <Flex width={75} justifyContent="space-between">
          <PercentageWrapper>
            <ColoredPercent percent={data.performance * 100} />
            <Icon type="Graph" size="tiny" />
          </PercentageWrapper>
        </Flex>
      </GraphIconChart>
    )
  },
  title: 'Progress',
}

export const companyGoalsWeight: ColumnInterface<KpiInterface> = kpiWeightColumn

export const companyGoalInitialValueColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'initial_value',
  dataPoint: 'initial_value',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.type !== KPITypes.company_goal ? formatNumberMillions(data.initial_value) : ' ',
  title: 'Initial',
}

export const companyGoalCurrentValueColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_progress',
  dataPoint: 'current_progress',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.type !== KPITypes.company_goal
      ? formatNumberMillions(data.current_progress)
      : ' ',
  title: 'Current',
}

export const companyGoalTargetColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target',
  dataPoint: 'target',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.type !== KPITypes.company_goal ? formatNumberMillions(data.target) : ' ',
  title: 'Target',
}

export const companyGoalTarget1YColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target_1Y',
  dataPoint: 'target_1Y',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) =>
    data.type !== KPITypes.company_goal ? formatNumberMillions(data.target_1Y) : ' ',
  title: 'Target 1Y',
}

export const companyGoalUnitColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'unit',
  dataPoint: 'unit',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (data.type !== KPITypes.company_goal ? data.unit : ''),
  title: 'Unit',
}

export const companyGoalStatusColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.insert,
  idPoint: 'target_status',
  dataPoint: 'target_status',
  insert: ({ data }) => {
    if (data.type === KPITypes.company_goal) {
      return ' '
    }
    switch (data.target_status) {
      case Statuses.approved:
        return (
          <Tooltip text="Approved" placement="left">
            <ColoredText color={Token.color.green_60}>
              <Icon type="Check" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.pending:
        return (
          <Tooltip text="Pending" placement="left">
            <ColoredText color={Token.color.warning}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.archived:
        return (
          <Tooltip text="Archived" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Delete" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.rejected:
        return (
          <Tooltip text="Rejected" placement="left">
            <ColoredText color={Token.color.greyTone20}>
              <Icon type="Close" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.requires_changes:
        return (
          <Tooltip text="Requires changes" placement="left">
            <ColoredText color={Token.color.warning}>
              <UIKitIcon name="ArrowExchange" size={16} />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.completed:
        return (
          <Tooltip text="Completed" placement="left">
            <ColoredText
              color={getPercentColor(
                (data.calibrated_progress || data.performance) * 100,
              )}
            >
              <UIKitIcon name="CalendarCheck" size={16} />
            </ColoredText>
          </Tooltip>
        )
      case Statuses.future:
        return (
          <Tooltip text="Future" placement="left">
            <ColoredText color={Token.color.lightBlue_60}>
              <Icon type="Time" size="tiny" />
            </ColoredText>
          </Tooltip>
        )
      default:
        return data.target_status || ''
    }
  },
  sortKey: null,
  filterKey: 'target_status',
  selectorsKey: selectorKeys.kpi_targets_statuses,
  title: 'Status',
}

export const companyKPICompanyGoalColumn: ColumnInterface<KpiInterface> = {
  type: CellTypes.text,
  idPoint: 'company_goal__name',
  dataPoint: 'company_goal__name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Company goal',
  dynamicHyperlinks: data => {
    const goalId = data.company_goal?.id

    if (goalId) {
      return pathToUrl(ROUTES.FORMS.COMPANY_GOALS.PREVIEW, { id: goalId })
    }

    return null
  },
}
