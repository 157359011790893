import addDays from 'date-fns/addDays'

function getTimezoneOffset(input: Date): number {
  const date = new Date(
    Date.UTC(
      input.getFullYear(),
      input.getMonth(),
      input.getDate(),
      input.getHours(),
      input.getMinutes(),
      input.getSeconds(),
      input.getMilliseconds(),
    ),
  )
  date.setUTCFullYear(input.getFullYear())
  return input.getTime() - date.getTime()
}

export function diffPeriod(left: Date, right: Date, period: number = 1): number {
  const timestampLeft = left.getTime() - getTimezoneOffset(left)
  const timestampRight = right.getTime() - getTimezoneOffset(right)

  return Math.round((timestampLeft - timestampRight) / period)
}

export const getAllDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const dates = []
  let currentDate = startDate
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate))
    currentDate = addDays(currentDate, 1)
  }
  return dates
}

export const getFormattedTime = (date: Date) =>
  `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
