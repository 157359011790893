import {
  useGetEmployeeOnboardingSettings,
  useGetLifecycleSettings,
} from '@src/api/settings'

export const useSenderOptions = (user?: string, useOnboardingSettings?: boolean) => {
  const { data: settings } = useGetLifecycleSettings(useOnboardingSettings)
  const { data: onboardingSettings } = useGetEmployeeOnboardingSettings(
    !useOnboardingSettings,
  )

  const getSenderOption = () => {
    if (
      useOnboardingSettings &&
      onboardingSettings?.contact_hr_email &&
      onboardingSettings.contact_hr_display_name
    ) {
      return {
        id: 'onboarding_settings' as const,
        name: onboardingSettings.contact_hr_display_name,
        subtitle: onboardingSettings.contact_hr_email,
      }
    }

    if (
      !useOnboardingSettings &&
      settings?.contact_hr_email &&
      settings?.contact_hr_name
    ) {
      return {
        id: 'generic' as const,
        name: settings.contact_hr_name,
        subtitle: settings.contact_hr_email,
      }
    }

    return null
  }

  return [
    {
      id: 'user' as const,
      name: user || 'Sender’s email',
      subtitle: user ? undefined : 'Sender’s email address',
    },
    getSenderOption(),
  ]
    .filter(Boolean)
    .map(value => ({
      label: value.name,
      value,
    }))
}
