import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew, GetRequestData } from '@src/interfaces'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '.'

export const communicationGroupsFormRequests: RequestInterfaceNew<
  Partial<CommunicationGroupInterface>
> = {
  get: async ({ id }) => api.get(`${API.COMMUNICATION_GROUPS}/${id}`),
  update: async (data, { id }) => {
    return apiWithoutHandling.patch(`${API.COMMUNICATION_GROUPS}/${id}`, data)
  },
  submit: async data => {
    data.audience_type = { id: 'dynamic_group', name: 'Dynamic group' }
    return apiWithoutHandling.post(`${API.COMMUNICATION_GROUPS}`, data)
  },
}

export const archiveCommunicationGroup = (
  id: number,
): AxiosPromise<CommunicationGroupInterface> =>
  api.post(`${API.COMMUNICATION_GROUPS}/${id}/archive`)

export const unarchiveCommunicationGroup = (
  id: number,
): AxiosPromise<CommunicationGroupInterface> =>
  api.post(`${API.COMMUNICATION_GROUPS}/${id}/unarchive`)

export const getCommunicationGroupsList = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<CommunicationGroupInterface>
> =>
  api.get(API.COMMUNICATION_GROUPS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getCommunicationGroups = (filterParams: Object) =>
  api.get<GetRequestData<CommunicationGroupInterface>>(API.COMMUNICATION_GROUPS, {
    params: filterParams,
  })
