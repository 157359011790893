import React from 'react'
import { copyToClipboard } from '@revolut/ui-kit'
import { IdAndName } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { employeeOnboardingPlaceholderNameColumn } from '@src/constants/columns/employeeOnboardingPlaceholders'
import { TableNames } from '@src/constants/table'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { pushNotification } from '@src/store/notifications/actions'
import { NotificationTypes } from '@src/store/notifications/types'
import { INVITATION_EMAIL_PLACEHOLDERS } from '../config'

const Row: RowInterface<IdAndName> = {
  cells: [
    {
      ...employeeOnboardingPlaceholderNameColumn,
      width: 100,
    },
  ],
}

export const PlaceholdersTable = () => {
  const tableData = INVITATION_EMAIL_PLACEHOLDERS.map((placeholder, i) => ({
    id: i,
    name: placeholder,
  }))

  const onRowClick = async (row: IdAndName) => {
    await copyToClipboard(row.name)
    pushNotification({
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
      value: 'Placeholder copied to clipboard',
    })
  }

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<IdAndName>
          count={tableData.length}
          data={tableData}
          hideCount
          name={TableNames.InvitationEmailPlaceholders}
          noDataMessage="Placeholders will appear here."
          onRowClick={onRowClick}
          row={Row}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
