import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import Preview from './Preview'
import General from './General'
import { PageWrapper } from '@src/components/Page/Page'
import { timeOffRegimesRequests } from '@src/api/timeOff'

const Regime = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.TIME_OFF_REGIMES.PREVIEW}>
          <Preview />
        </Route>
        <Route exact path={ROUTES.FORMS.TIME_OFF_REGIMES.GENERAL}>
          <General />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={timeOffRegimesRequests}>
    <Regime />
  </Form>
))
