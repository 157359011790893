import { DynamicGroupIDs } from '@src/interfaces/customFields'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { EmployeeOnboardingSettingsInterface } from '@src/interfaces/settings'

export enum OnboardingSteps {
  start = 'start',
  basic_info = 'basic_info',
  personal_info = 'personal_info',
  payroll = 'payroll',
  diversity = 'diversity',
  documents = 'documents',
  summary = 'summary',
}

export interface TabProps {
  dynamicGroups?: DynamicGroupIDs
  isCompleted: boolean
  isLoadingBasicInfo: boolean
  isLoadingOnboardingSettings: boolean
  isPreview: boolean
  joiningDateTime?: string
  onboardingSettings?: EmployeeOnboardingSettingsInterface
  name?: string
  nextRoute?: string
  prevRoute?: string
  onComplete?: VoidFunction
}

export interface TabBarNavigationInterface extends TabBarTableNavigationInterface {
  id: OnboardingSteps
}

export interface StepInfoInterface {
  isCompleted?: boolean
  isFocused: boolean
  type: OnboardingSteps
}
