import {
  TimeOffPolicyBasicsStep,
  TimeOffPolicyDraftInterface,
  TimeOffPolicyInterface,
} from '@src/interfaces/timeOff'

export const COPY_FROM_POLICY_ID = 'copy_id'

export type NextTabButtonCommonProps = {
  onNavigateToNextTab: () => void
  nextTabPath?: string
}

type InitDraftDataProps = {
  basicStepInfo: TimeOffPolicyBasicsStep | undefined
  refetchBasicStepInfo: () => void
}

export type TabKey = 'basics' | 'details' | 'requests' | 'eligibility' | 'review'
type TabFormDataProps = {
  copyFrom: TimeOffPolicyInterface | undefined
  setIsCopied: () => void
}

export type PolicyEditTabCommonProps = NextTabButtonCommonProps &
  InitDraftDataProps &
  TabFormDataProps

export const isReadonly = <T extends TimeOffPolicyDraftInterface>(
  values: T,
  fieldName: keyof T,
) => !!values.field_options?.read_only.includes(fieldName as string)
