import { ROUTES } from '@src/constants/routes'
import React, { PropsWithChildren } from 'react'
import { Route } from 'react-router-dom'
import { InnerRoute } from '../../common/InnerRoute'
import { Actions } from './PageActions'

export const InnerSettingsRoute = ({
  children,
  path,
  title,
  description,
  backPath = ROUTES.PERFORMANCE.SCORECARD_SETUP.SETTINGS.GENERAL,
  showActions = true,
}: PropsWithChildren<{
  path: string
  title: string
  description?: string
  backPath?: string
  showActions?: boolean
}>) => {
  return (
    <Route path={path}>
      <InnerRoute title={title} description={description} backPath={backPath}>
        {children}
      </InnerRoute>
      {showActions && !!backPath && <Actions backPath={backPath} />}
    </Route>
  )
}
