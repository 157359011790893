import React, { useEffect } from 'react'
import {
  Button,
  InputGroup,
  Side,
  StatusPopup,
  useStatusPopup,
  VStack,
} from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import Form from '@src/features/Form/Form'
import SideBar from '@src/components/SideBar/SideBar'
import { customFieldsRequest } from '@src/api/customFields'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CustomFieldsInterface, SectionOptions } from '@src/interfaces/customFields'
import { selectUser } from '@src/store/auth/selectors'
import { selectorKeys } from '@src/constants/api'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import {
  renderExtraFields,
  useFieldSettings,
} from '@src/pages/Forms/CustomFieldsForm/General'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'

const CreateCustomFieldSidebarFormContent = ({ onClose }: Pick<Props, 'onClose'>) => {
  const currentUser = useSelector(selectUser)
  const { values } = useLapeContext<CustomFieldsInterface>()

  useFieldSettings()

  useEffect(() => {
    values.created_by = { id: currentUser.id, name: currentUser.full_name }
    values.section = { id: SectionOptions.EmployeeProfile, name: '' }
  }, [])

  const popup = useStatusPopup()

  return (
    <>
      <VStack space="s-16">
        <LapeNewInput name="name" label="Field name" required />
        <LapeNewTextArea
          name="tooltip"
          label="Helper text"
          rows={2}
          message="Text that will appear below the text field, providing additional information."
        />
        <LapeRadioSelectInput
          name="sub_section"
          label="Category"
          message="Which category does this field fall under?"
          selector={selectorKeys.employee_profile_sub_sections}
        />
        <InputGroup>
          <LapeNewRadioButtons
            name="mandatory"
            options={[
              { label: 'Mandatory', value: true },
              { label: 'Optional', value: false },
            ]}
            defaultOptionIndex={0}
            variant="cell"
          />
          <LapeRadioSelectInput
            name="employee_access"
            label="Employee access"
            selector={selectorKeys.custom_field_employee_accesses}
          />
        </InputGroup>
        <LapeRadioSelectInput
          name="input_type"
          label="Field type"
          selector={selectorKeys.custom_field_input_types}
          required
        />
        <LapeNewMultiSelect
          name="dynamic_groups"
          selector={selectorKeys.dynamic_groups}
          placeholder="Groups"
          message="Which groups should have this field?"
          required
        />
        {renderExtraFields(values)}
      </VStack>
      <Side.Actions>
        <NewSaveButtonWithPopup
          useValidator
          successText="New custom field was successfully created"
          onAfterSubmit={onClose}
          errorHandler={e => {
            const nonFieldError = e.response?.data?.non_field_errors?.[0]

            if (nonFieldError) {
              popup.show(
                <StatusPopup variant="error" onClose={popup.hide}>
                  <StatusPopup.Title>Failed to create new custom field</StatusPopup.Title>
                  <StatusPopup.Description>{nonFieldError}</StatusPopup.Description>
                  <StatusPopup.Actions>
                    <Button data-testid="close-error-popup" onClick={popup.hide}>
                      Close
                    </Button>
                  </StatusPopup.Actions>
                </StatusPopup>,
              )
            } else {
              throw e
            }
          }}
        >
          Confirm
        </NewSaveButtonWithPopup>
      </Side.Actions>
    </>
  )
}

type Props = {
  isOpen: boolean
  onClose: () => void
}
export const CreateCustomFieldSidebar = ({ isOpen, onClose }: Props) => (
  <SideBar title="New custom field" isOpen={isOpen} onClose={onClose}>
    <Form disableLocalStorageCaching api={customFieldsRequest}>
      <CreateCustomFieldSidebarFormContent onClose={onClose} />
    </Form>
  </SideBar>
)
