import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { todoSettings } from '@src/api/settings'
import { Todo } from '@src/pages/Settings/Todo/Todo'

const routes = [
  {
    title: 'To do’s app settings',
    description: 'Settings related to To do functionalities',
    path: ROUTES.SETTINGS.TODO,
    url: ROUTES.SETTINGS.TODO,
    canView: [PermissionTypes.ViewTodoPreferences, PermissionTypes.ChangeTodoPreferences],
    component: Todo,
  },
]

export const TodoSettings = () => {
  return <SettingsForm routes={routes} api={todoSettings} />
}
