import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { useEmployeeEmailThread } from '@src/api/employeeEmails'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { EmployeeUserWithAvatar } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { General } from './General'
import { View } from './View'

export const OnboardingSendEmailV2 = () => {
  const params = useParams<{ employeeId: string; threadId?: string; emailId?: string }>()
  const { data, isLoading } = useEmployeeEmailThread(params.employeeId, params.threadId)

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.PEOPLE.EMPLOYEES}
        isLoading={isLoading}
        pb="s-8"
        subtitle={<EmployeeUserWithAvatar id={params.employeeId} />}
        title={data?.subject ? 'Send reply' : 'Send email'}
      />
      <Switch>
        <Route exact path={ROUTES.FORMS.ONBOARDING_SEND_EMAIL_V2.GENERAL}>
          <General />
        </Route>
        <Route exact path={ROUTES.FORMS.ONBOARDING_SEND_EMAIL_V2.VIEW}>
          <View />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
