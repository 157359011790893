import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { OfferPlaceholderInterface } from '@src/interfaces/offerTemplates'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const offerPlaceholderNameColumn: ColumnInterface<OfferPlaceholderInterface> = {
  type: CellTypes.text,
  idPoint: 'placeholder',
  dataPoint: 'placeholder',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Placeholder',
  dynamicHyperlinks: data =>
    pathToUrl(ROUTES.FORMS.OFFER_PLACEHOLDER.GENERAL, {
      id: data.id,
    }),
}

export const offerPlaceholderLabelColumn: ColumnInterface<OfferPlaceholderInterface> = {
  type: CellTypes.text,
  idPoint: 'label',
  dataPoint: 'label',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Label in form',
}

export const offerPlaceholderPredefinedColumn: ColumnInterface<OfferPlaceholderInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'is_predefined',
    dataPoint: 'is_predefined',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Custom/Predefined',
    insert: ({ data }) => (data.is_predefined ? 'Predefined' : 'Custom'),
  }

export const offerPlaceholderTypeColumn: ColumnInterface<OfferPlaceholderInterface> = {
  type: CellTypes.text,
  idPoint: 'field_type.id',
  dataPoint: 'field_type.name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Type',
}
