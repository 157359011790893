import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar, TableWidget } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import { RegimeInterface } from '@src/interfaces/timeOff'
import {
  regimeApproverGroupColumn,
  regimeDefaultApproverColumn,
  regimeGroupsColumn,
  regimeNameColumn,
  regimePublicHolidaysColumn,
  regimeWorkingDaysEndColumn,
  regimeWorkingDaysStartColumn,
} from '@src/constants/columns/timeOff'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/Table/hooks'
import { ROUTES } from '@src/constants/routes'
import { getTimeOffRegimes } from '@src/api/timeOff'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const ROW: RowInterface<RegimeInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.PREVIEW, { id })),
  cells: [
    {
      ...regimeNameColumn,
      width: 200,
    },
    {
      ...regimeGroupsColumn,
      width: 300,
    },
    {
      ...regimeDefaultApproverColumn,
      width: 150,
    },
    {
      ...regimeApproverGroupColumn,
      width: 150,
    },
    {
      ...regimeWorkingDaysStartColumn,
      width: 100,
    },
    {
      ...regimeWorkingDaysEndColumn,
      width: 100,
    },
    {
      ...regimePublicHolidaysColumn,
      width: 100,
    },
  ],
}

const Regimes = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddTimeOffRegimes)

  const table = useTable({ getItems: getTimeOffRegimes })

  return (
    <>
      <PageHeader
        title="Time management settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL}
      />
      <PageBody>
        <TableWidget>
          <TableWidget.Info>
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </TableWidget.Info>
          <TableWidget.Actions>
            {canAdd && (
              <MoreBar>
                <MoreBar.Action
                  use={InternalLink}
                  to={pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.GENERAL)}
                  useIcon="Plus"
                >
                  Add regime
                </MoreBar.Action>
              </MoreBar>
            )}
          </TableWidget.Actions>
          <TableWidget.Table>
            <AdjustableTable
              name={TableNames.UserTimeOffRegimes}
              useWindowScroll
              row={ROW}
              {...table}
              noDataMessage="Regimes will appear here"
            />
          </TableWidget.Table>
        </TableWidget>
      </PageBody>
    </>
  )
}

export default Regimes
