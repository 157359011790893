import React from 'react'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import SupportToolWhitelist from '@src/pages/Performance/SupportTool/Whitelist'

export const Whitelist = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Whitelist"
        subtitle="Manage whitelisted reviewers"
        backUrl={pathToUrl(ROUTES.PERFORMANCE.REVIEWS.GENERAL)}
      />
      <SupportToolWhitelist />
    </PageWrapper>
  )
}
