import React, { useState } from 'react'
import { Action, Button, Popup, VStack } from '@revolut/ui-kit'
import LapeScorecardField from '@src/pages/Forms/HiringStage/FormSections/LapeScorecardField'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { useLapeContext } from '@src/features/Form/LapeForm'
import PreviewScorecard from '@src/pages/OnboardingChecklist/HiringProcess/components/PreviewScorecard'

type UseExistingScorecardProps = {
  disableNext: boolean
  onCancel: () => void
  onNext: () => void
}

const UseExistingScorecard = ({
  disableNext,
  onCancel,
  onNext,
}: UseExistingScorecardProps) => {
  const { values } = useLapeContext<HiringProcessInterface>()
  const [previewScorecard, setPreviewScorecard] = useState(false)
  return (
    <>
      <VStack gap="s-16" mt="s-16">
        <LapeScorecardField isClearable={false} isDisabled={false} isRequired />
        <Action
          useIcon={previewScorecard ? 'ChevronUp' : 'ChevronDown'}
          disabled={!values?.scorecard_template?.id}
          onClick={() => {
            setPreviewScorecard(!previewScorecard)
          }}
        >
          Show scorecard preview
        </Action>
        {previewScorecard && values?.scorecard_template && (
          <PreviewScorecard
            id={+values.scorecard_template.id}
            name={values?.scorecard_template.name}
          />
        )}
      </VStack>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={disableNext} onClick={onNext}>
          Next
        </Button>
      </Popup.Actions>
    </>
  )
}

export default UseExistingScorecard
