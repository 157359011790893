import { HStack, Tag, Text, Token } from '@revolut/ui-kit'
import {
  InterviewRoundInterface,
  InterviewStageWithoutRoundInterface,
  InterviewStatuses,
} from '@src/interfaces/interviewTool'
import React from 'react'
import { AutomatedStageIndicator } from '@src/pages/Forms/Candidate/StagesTable/AutomatedStageIndicator'

type StageTitleProps = {
  round: InterviewRoundInterface
  stage: InterviewStageWithoutRoundInterface
}

export const CurrentStage = () => {
  return <Tag color={Token.color.accent}>Current</Tag>
}

export const StageTitle = ({ round, stage }: StageTitleProps) => {
  return (
    <HStack gap="s-6" align="center">
      <Text
        variant="emphasis1"
        wrap="nowrap"
        color={
          stage.scheduling_status === InterviewStatuses.not_started
            ? Token.color.greyTone50
            : undefined
        }
      >
        {stage.title}
      </Text>
      <AutomatedStageIndicator stage={stage} />
      {round?.latest_interview_stage?.id === stage.id && <CurrentStage />}
    </HStack>
  )
}
