import React from 'react'
import {
  Token,
  Text,
  Ellipsis,
  TextTypographyProps,
  EllipsisProps,
} from '@revolut/ui-kit'
import { IdStatuses } from '@src/interfaces/employees'

export interface UserNameProps {
  name?: string
  full_name?: string
  status?: IdStatuses
  textProps?: TextTypographyProps
  ellipsisProps?: EllipsisProps
}

export const UserName = ({ name, full_name, status, textProps = {} }: UserNameProps) => {
  const nameString = full_name || name
  const isTerminated = status === IdStatuses.terminated
  const displayName = nameString + (isTerminated ? ' · Deactivated' : '')
  return (
    <Text {...textProps} color={isTerminated ? Token.color.greyTone50 : undefined}>
      <Ellipsis>{displayName}</Ellipsis>
    </Text>
  )
}

export default UserName
