import React from 'react'
import GenericNotificationTable from '@src/pages/Forms/Notifications/GenericNotificationTable'
import { RowInterface, TabComponentProps } from '@src/interfaces/data'
import { NotificationsInterface } from '@src/interfaces/notifications'
import {
  notificationsActionColumn,
  notificationsApproveKPILevelColumn,
  notificationsDeadline,
  notificationsOrgUnitColumn,
  notificationsPriorityColumn,
  notificationsTextColumn,
} from '@src/constants/columns/notifications'
import { notificationsKPIsToApprove } from '@src/api/notifications'

const RowNotifications: RowInterface<NotificationsInterface> = {
  cells: [
    {
      ...notificationsTextColumn,
      width: 180,
    },
    {
      ...notificationsOrgUnitColumn,
      width: 150,
    },
    {
      ...notificationsApproveKPILevelColumn,
      width: 60,
    },
    {
      ...notificationsDeadline,
      width: 100,
    },
    {
      ...notificationsPriorityColumn,
      width: 100,
    },
    {
      ...notificationsActionColumn,
      width: 70,
    },
  ],
}

const KPIsToApprove = ({
  refreshStats,
  type,
  employeeId,
  filterBy,
  tableRef,
}: TabComponentProps) => {
  return (
    <GenericNotificationTable
      rows={RowNotifications}
      request={notificationsKPIsToApprove(employeeId)}
      refreshStats={refreshStats}
      type={type}
      filterBy={filterBy}
      tableRef={tableRef}
    />
  )
}

export default KPIsToApprove
