import { ActionButton, Bar, InputGroup } from '@revolut/ui-kit'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import React, { useEffect, useState } from 'react'
import { FetchColumn } from '../SidebarForms/FetchColumn'
import useIsCommercial from '@src/hooks/useIsCommercial'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { KpiInterface } from '@src/interfaces/kpis'
import { LookerPreviewPopup } from './LookerPreviewPopup'
import { lookerValidator, tryToMakeEmbedLookerUrl } from './helpers'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { UnitOfMeasure } from '../SidebarForms/components/UnitOfMeasure'
import { ProgressDirection } from '../SidebarForms/components/ProgressDirection'
import { InitTargetValues } from '../SidebarForms/components/InitTargetValues'

export const LookerForm = () => {
  const isCommercial = useIsCommercial()
  const { values } = useLapeContext<KpiInterface>()
  const [isLookerPreviewOpen, setShownLookerPreview] = useState(false)
  const embedLookUrl = tryToMakeEmbedLookerUrl(values.look_url)
  const [isLookUrlValid, setIsLookUrlValid] = useState(false)

  const tooltipText =
    !!values.look_url && !!embedLookUrl
      ? `Click here to check whether your URL works`
      : `A valid Look URL is required`

  useEffect(() => {
    if (values.look_url) {
      lookerValidator
        .parseAsync(values.look_url)
        .then(() => {
          setIsLookUrlValid(true)
        })
        .catch(() => {
          setIsLookUrlValid(false)
        })
    } else {
      setIsLookUrlValid(false)
    }
  }, [values.look_url])

  return (
    <>
      <InputGroup>
        <LapeNewInput
          name="look_url"
          label="Look URL"
          required
          hasError={!!values.look_url && !isLookUrlValid}
          description={`Provide the URL for the Look you want to connect to, e.g. ${
            isCommercial
              ? 'https://company.looker.com/looks/12345'
              : 'https://revolut.cloud.looker.com/looks/24523'
          }`}
        />
        {isLookUrlValid ? (
          <>
            <FetchColumn type="looker" variant="date" />
            <FetchColumn type="looker" variant="target" />
            <UnitOfMeasure />
            <ProgressDirection />
            <InputGroup variant="horizontal">
              <InitTargetValues />
            </InputGroup>
          </>
        ) : null}
        <Bar>
          <Tooltip text={tooltipText} placement="right">
            <ActionButton
              onClick={() => setShownLookerPreview(true)}
              disabled={!values.look_url && !embedLookUrl}
              useIcon="EyeOutline"
            >
              Preview data
            </ActionButton>
          </Tooltip>
        </Bar>
      </InputGroup>
      {!!values.look_url && !!embedLookUrl && (
        <LookerPreviewPopup
          open={isLookerPreviewOpen}
          url={embedLookUrl}
          onClose={() => setShownLookerPreview(false)}
        />
      )}
    </>
  )
}
