import {
  Banner,
  Text,
  FilterButton,
  FilterButtonSkeleton,
  Box,
  RadioSelect,
  SelectOptionItemType,
  VStack,
  ActionButton,
  Flex,
} from '@revolut/ui-kit'
import React, { useEffect, useRef, useState } from 'react'
import { PreviewScorecardState, previewState } from './previewState'
import { IdAndName, Statuses } from '@src/interfaces'
import { ReviewCategory, ReviewerRelation } from '@src/interfaces/performance'
import { selectorKeys } from '@src/constants/api'
import set from 'lodash/set'
import { ContributorType } from '@src/interfaces/talent/performance'
import { useGetSelectors } from '@src/api/selectors'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { PerformanceSettingsInterface } from '@src/interfaces/settings'

const FilterWithSearch = <T extends number | string>({
  value,
  options,
  onChange,
}: {
  value?: IdAndName<T>
  options: SelectOptionItemType<IdAndName<T, string>>[]
  onChange: (value: IdAndName<T> | null) => void
}) => {
  const [selectOpen, setSelectOpen] = useState(false)
  const selectRef = useRef<HTMLButtonElement>(null)

  return value ? (
    <Box>
      <FilterButton
        active
        useIcon="ChevronDown"
        ref={selectRef}
        onClick={() => setSelectOpen(!selectOpen)}
        style={{ height: '30px' }}
        px="s-12"
      >
        {value.name}
      </FilterButton>
      <RadioSelect
        open={selectOpen}
        onClose={() => setSelectOpen(false)}
        options={options}
        value={value}
        onChange={option => {
          if (option) {
            onChange(option)
            setSelectOpen(false)
          }
        }}
        labelList="Roles"
        labelSearch="Search roles"
        labelNoResults="No results found"
        indicatorStyle="highlight"
        width={300}
        searchable
      >
        {option => (
          <>
            <Text variant="caption" fontWeight={500}>
              {option.value.name}
            </Text>
          </>
        )}
      </RadioSelect>
    </Box>
  ) : (
    <FilterButtonSkeleton />
  )
}

interface ReviewCategoryPreset {
  name: string
  description?: string // todo: Make description required after PO provides wording REVC-6126
  state: Partial<PreviewScorecardState>
}

enum ReviewStatePreset {
  SELF_REVIEW = 'SELF_REVIEW',
  LM_REVIEW = 'LM_REVIEW',
  FM_REVIEW = 'FM_REVIEW',
  PEER_REVIEW = 'PEER_REVIEW',
  UPWARDS_REVIEW = 'UPWARDS_REVIEW',
}

const getPresets = (settings?: PerformanceSettingsInterface) => {
  const presetsList: { [key in ReviewStatePreset]?: ReviewCategoryPreset } = {
    [ReviewStatePreset.SELF_REVIEW]: {
      name: 'Self review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Self, name: 'Self' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    },
    [ReviewStatePreset.LM_REVIEW]: {
      name: 'Line manager review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.LineManager, name: 'Line manager' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    },
  }

  if (settings?.enable_functional_management) {
    presetsList[ReviewStatePreset.FM_REVIEW] = {
      name: 'Functional manager review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.FunctionalManager, name: 'Functional manager' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    }
  }

  if (settings?.enable_peer_reviews) {
    presetsList[ReviewStatePreset.PEER_REVIEW] = {
      name: 'Peer review',
      state: {
        category: { id: ReviewCategory.Performance, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Other, name: 'Other' },
        contributorType: { id: ContributorType.IC, name: 'Individual contributor' },
      },
    }
  }

  if (settings?.enable_upwards_reviews) {
    presetsList[ReviewStatePreset.UPWARDS_REVIEW] = {
      name: 'Upwards review',
      state: {
        category: { id: ReviewCategory.Upwards, name: 'Performance review' },
        seenBy: { id: ReviewerRelation.Other, name: 'Other' },
        contributorType: { id: ContributorType.Mgr, name: 'High impact individual' },
      },
    }
  }

  return presetsList
}

export const PreviewSetupBanner = () => {
  const { role } = previewState
  const { data: allSpecialisations } = useGetSelectors<SpecialisationInterface>(
    selectorKeys.approved_pending_specialisations,
  )
  const { data: performanceSettings } = useGetPerformanceSettings()
  const [specialisations, setSpecialisations] = useState<SpecialisationInterface[]>()
  const [presets, setPresets] =
    useState<{ [key in ReviewStatePreset]?: ReviewCategoryPreset }>()
  const [preset, setPreset] = useState<IdAndName<ReviewStatePreset> | undefined>()

  useEffect(() => {
    if (allSpecialisations) {
      setSpecialisations(
        allSpecialisations.filter(item => item.status !== Statuses.archived),
      )
    }
  }, [allSpecialisations])

  useEffect(() => {
    if (performanceSettings) {
      const presetsList = getPresets(performanceSettings)
      setPresets(presetsList)
      setPreset({
        id: ReviewStatePreset.SELF_REVIEW,
        name: presetsList[ReviewStatePreset.SELF_REVIEW]?.name || '',
      })
    }
  }, [performanceSettings])

  if (!presets) {
    return null
  }

  const changePreset = (id: ReviewStatePreset, newPreset: ReviewCategoryPreset) => {
    Object.entries(newPreset.state).forEach(([stateKey, value]) => {
      set(previewState, stateKey, value)
    })
    setPreset({ id, name: newPreset.name })
  }

  return (
    <Banner>
      <Banner.Content>
        <Banner.Title>
          <VStack space="s-16">
            <Flex gap="s-16" flexWrap="wrap">
              {Object.entries(presets).map(([id, newPreset]) => (
                <ActionButton
                  key={id}
                  size="xs"
                  variant={id === preset?.id ? 'accent' : 'primary'}
                  onClick={() => changePreset(id as ReviewStatePreset, newPreset)}
                >
                  {newPreset.name}
                </ActionButton>
              ))}
            </Flex>
            <FilterWithSearch<number | string>
              value={role}
              options={
                specialisations?.map(item => ({
                  key: item.id,
                  value: item,
                  label: item.name,
                })) || []
              }
              onChange={newRole => {
                if (newRole) {
                  previewState.role = newRole
                }
              }}
            />
          </VStack>
        </Banner.Title>
      </Banner.Content>
    </Banner>
  )
}
