import { IconName } from '@revolut/ui-kit'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface Props {
  label: string
  iconName: IconName
}

const finishGoalsStageProps: Props = {
  label: 'Finish stage',
  iconName: 'ArrowThinRight',
}

const finishReviewStageProps: Props = {
  label: 'End performance review',
  iconName: 'ArrowThinRight',
}

const finishCalibrationStageProps: Props = {
  label: 'Finish calibration period',
  iconName: 'ArrowThinRight',
}

const finishPublishStageProps: Props = {
  label: 'Publish results',
  iconName: 'Megaphone',
}

const reopenGoalsStageProps: Props = {
  label: 'Reopen goal setting',
  iconName: 'ArrowRepeat',
}

const reopenReviewStageProps: Props = {
  label: 'Reopen performance review',
  iconName: 'ArrowRepeat',
}

const reopenCalibrationStageProps: Props = {
  label: 'Reopen calibration period',
  iconName: 'ArrowRepeat',
}

const reopenPublishStageProps: Props = {
  label: 'Reopen Publish results',
  iconName: 'ArrowRepeat',
}

export const nextStageToPropsMap: Record<ReviewCycleStage, Props | null> = {
  [ReviewCycleStage.DepartmentGoals]: finishGoalsStageProps,
  [ReviewCycleStage.TeamGoals]: finishGoalsStageProps,
  [ReviewCycleStage.Review]: finishReviewStageProps,
  [ReviewCycleStage.Calibration]: finishCalibrationStageProps,
  [ReviewCycleStage.ManagersPublish]: finishPublishStageProps,
  [ReviewCycleStage.EmployeesPublish]: finishPublishStageProps,
  [ReviewCycleStage.Completed]: null,
}

export const previousStageToPropsMap: Record<ReviewCycleStage, Props | null> = {
  [ReviewCycleStage.DepartmentGoals]: reopenGoalsStageProps,
  [ReviewCycleStage.TeamGoals]: reopenGoalsStageProps,
  [ReviewCycleStage.Review]: reopenReviewStageProps,
  [ReviewCycleStage.Calibration]: reopenCalibrationStageProps,
  [ReviewCycleStage.ManagersPublish]: reopenPublishStageProps,
  [ReviewCycleStage.EmployeesPublish]: null,
  [ReviewCycleStage.Completed]: reopenPublishStageProps,
}
