import { AxiosPromise } from 'axios'
import isBefore from 'date-fns/isBefore'

import {
  FetchDataQueryInterface,
  FilterByInterface,
  SORT_DIRECTION,
} from '@src/interfaces/data'
import {
  GetRequestInterface,
  IdAndName,
  RequestInterfaceNew,
  Statuses,
  TableRequestInterface,
} from '@src/interfaces'
import {
  AdjustmentFieldInterface,
  BulkAssignInterface,
  EmployeeTimeOffAdjustBalanceInterface,
  EmployeeTimeOffBalanceSelectorOption,
  EmployeeTimeOffBalancesInterface,
  EmployeeTimeOffBalanceTransaction,
  EmployeeTimeOffPolicyAssignmentFirstAccrual,
  EmployeeTimeOffPolicyAssignmentInterface,
  EmployeeTimeOffRequestInterface,
  EmployeeTimeOffRequestsCalendarInterface,
  EmployeeTimeOffRequestSummaryInterface,
  EmployeeTimeOffStats,
  NonWorkingDay,
  PublicHolidayInterface,
  RegimeInterface,
  RegimeMembershipsInterface,
  RequestOfReportsInterface,
  RequestOfReportsStatsInterface,
  TeamTimeOffRequestsInterface,
  TimeOffCategoryInterface,
  TimeOffPolicyBasicsStep,
  TimeOffPolicyDetailsStep,
  TimeOffPolicyRequestsStep,
  TimeOffPolicyEligibilityStep,
  TimeOffReviewStats,
  TempPolicyAssignmentInterface,
  CreateTempPolicyAssignmentInterface,
  TimeOffTableFilterInterface,
  TimeOffProcessingStatus,
  TimeOffPoliciesStatsInterface,
  TimeOffPolicyEditStepStatusesInterface,
  TimeOffPolicyReviewEligibleEmployeeInterface,
  TimeOffUnit,
  TimeOffPolicyTableInterface,
  TimeOffPolicyInterface,
  NormalizedTempPolicyAssignmentsEmployeeInterface,
  TempPolicyAssignmentsEmployeeInterface,
  TempPolicyAssignmentsStatusInterface,
} from '@src/interfaces/timeOff'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { api, apiWithoutHandling } from '.'
import { useFetch } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { FileInterface } from '@src/interfaces/files'
import { ChangelogInterface } from '@src/interfaces/changelog'
import { uploadFile } from '@src/api/files'
import { getCommentsAPI } from './comments'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

export const getPublicHolidays = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<PublicHolidayInterface>> =>
  api.get(`${API.TIME_OFF}/publicHolidays`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const publicHolidaysRequest: RequestInterfaceNew<PublicHolidayInterface> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/publicHolidays/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/publicHolidays/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.TIME_OFF}/publicHolidays`, data),
  delete: async ({ id }) => api.delete(`${API.TIME_OFF}/publicHolidays/${id}`),
}

export const archivePublicHoliday = (id: number) =>
  api.post<PublicHolidayInterface>(`${API.TIME_OFF}/publicHolidays/${id}/archive`)

export const getTimeOffRegimes = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<RegimeInterface>> =>
  api.get(`${API.TIME_OFF}/regimes`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getTimeOffCategories = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<TimeOffCategoryInterface>
> =>
  api.get(`${API.TIME_OFF}/policyCategories`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useGetTimeOffCategories = () => {
  const context = useFetch<{ results: TimeOffCategoryInterface[] }>(
    `${API.TIME_OFF}/policyCategories`,
  )

  return { ...context, data: context.data?.results }
}

export const timeOffCategoriesRequests: RequestInterfaceNew<TimeOffCategoryInterface> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/policyCategories/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/policyCategories/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.TIME_OFF}/policyCategories`, data),
  delete: async ({ id }) => api.delete(`${API.TIME_OFF}/policyCategories/${id}`),
}

export const timeOffRegimesRequests: RequestInterfaceNew<RegimeInterface> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/regimes/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/regimes/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.TIME_OFF}/regimes`, data),
  delete: async ({ id }) => api.delete(`${API.TIME_OFF}/regimes/${id}`),
}

export const refreshTimeOffRegimes = (id: number) =>
  api.post(`${API.TIME_OFF}/regimes/${id}/refresh`)

export const getTimeOffRegimeMemberships =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<RegimeMembershipsInterface>
  > =>
    api.get(`${API.TIME_OFF}/regimes/${id}/memberships`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getEmployeeTimeOffRequests =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffRequestInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useGetEmployeeTimeOffRequests = (
  employeeId: number | string,
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestInterface<EmployeeTimeOffRequestInterface>>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests`,
    undefined,
    filters?.length
      ? {
          params: filterSortPageIntoQuery(
            [{ sortBy: 'from_date_time', direction: SORT_DIRECTION.DESC }],
            filters,
          ),
        }
      : undefined,
  )

export const useGetPendingTimeOffRequestsByCurrentUser = (
  filters?: FilterByInterface[],
) => {
  const user = useSelector(selectUser)

  return useFetch<EmployeeTimeOffRequestInterface[]>(
    user.id
      ? `${API.EMPLOYEES}/${user.id}${API.TIME_OFF}/requests/pendingApproval`
      : null,
    undefined,
    filters?.length
      ? {
          params: filterSortPageIntoQuery(
            [{ sortBy: 'from_date_time', direction: SORT_DIRECTION.DESC }],
            filters,
          ),
        }
      : undefined,
  )
}

export const useGetEmployeeTimeOffApprovableRequests = (
  employeeId: number,
  filters?: FilterByInterface[],
) =>
  useFetch<EmployeeTimeOffRequestSummaryInterface[]>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/showAll`,
    undefined,
    filters?.length ? { params: filterSortPageIntoQuery(undefined, filters) } : undefined,
  )

export const useEmployeeTimeOffStats = (employeeId?: number | string) =>
  useFetch<EmployeeTimeOffStats>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/stats` : null,
  )

export const useEmployeeTimeOffDuration = (values: EmployeeTimeOffRequestInterface) => {
  const params = (() => {
    if (
      values.from_date_time &&
      values.to_date_time &&
      isBefore(new Date(values.to_date_time), new Date(values.from_date_time))
    ) {
      return null
    }
    if (
      values.balance?.unit?.id === 'day' &&
      values.from_date_time &&
      values.to_date_time &&
      values.from_time_period &&
      values.to_time_period
    ) {
      return {
        balance_id: values.balance.id,
        from_date_time: values.from_date_time,
        to_date_time: values.to_date_time,
        from_time_period: values.from_time_period.id,
        to_time_period: values.to_time_period.id,
      }
    }
    if (
      values.balance?.unit?.id === 'hour' &&
      values.from_date_time &&
      values.to_date_time
    ) {
      return {
        balance_id: values.balance.id,
        from_date_time: values.from_date_time,
        to_date_time: values.to_date_time,
      }
    }
    return null
  })()

  return useFetch<{ duration: number | null; unit: TimeOffUnit } | { error: string }>(
    params ? `${API.TIME_OFF}/requests/calculateDuration` : null,
    'v1',
    { params },
    true,
  )
}

export interface TimeOffRequestYears {
  default: boolean
  id: number
  name: number
}

export const useEmployeeTimeOffRequestYearOptions = (employeeId?: number) =>
  useFetch<{ options: TimeOffRequestYears[] }>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/years` : null,
  )

export const useTeamTimeOffRequestYearOptions = (id: number) =>
  useFetch<{ options: TimeOffRequestYears[] }>(
    `${API.TEAMS}/${id}${API.TIME_OFF}/requests/years`,
  )

export const useDepartmentTimeOffRequestYearOptions = (id: number) =>
  useFetch<{ options: TimeOffRequestYears[] }>(
    `${API.DEPARTMENT}/${id}${API.TIME_OFF}/requests/years`,
  )

export const employeeTimeOffRequests = (
  submitEndpoint: 'requests' | 'absences',
): RequestInterfaceNew<EmployeeTimeOffRequestInterface> => ({
  get: async ({ id, employeeId }) =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}`),
  update: async (data, { id, employeeId }) => {
    const { attachment, ...form } = data
    let file: FileInterface | null = null

    if (attachment) {
      const fileCreateResult = await uploadFile(
        attachment as File,
        'time_off_request_attachment',
        true,
      )
      file = fileCreateResult.data
    }

    return apiWithoutHandling
      .patch(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}`, {
        ...form,
        ...(attachment || attachment === null ? { attachment: file } : {}),
      })
      .catch(error => {
        if (file?.id) {
          apiWithoutHandling.delete(`${API.FILES}/${file.id}`, undefined, undefined, true)
        }
        throw error
      })
  },
  submit: async (data, { employeeId }) => {
    const { attachment, ...form } = data
    let file: FileInterface | null = null

    if (attachment) {
      const fileCreateResult = await uploadFile(
        attachment as File,
        'time_off_request_attachment',
        true,
      )
      file = fileCreateResult.data
    }

    return apiWithoutHandling
      .post(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/${submitEndpoint}`, {
        ...form,
        attachment: file,
      })
      .catch(error => {
        if (file?.id) {
          apiWithoutHandling.delete(`${API.FILES}/${file.id}`, undefined, undefined, true)
        }

        throw error
      })
  },
  delete: async (_, { id, employeeId }) =>
    api.delete(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}`),
})

export const useNextTimeOffRequest = () =>
  useFetch<EmployeeTimeOffRequestInterface>(`${API.TIME_OFF}/requests/next`)

export const getTimeOffRequestCommentsAPI = (id: number) =>
  getCommentsAPI({
    baseUrl: `${API.TIME_OFF}/requests/${id}/comments`,
  })

export const getTimeOffRequestChangelog =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ChangelogInterface<EmployeeTimeOffRequestInterface>>
  > =>
    api.get(`${API.TIME_OFF}/requests/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useTimeOffRequestApprovals = (
  employeeId: string,
  id: number,
  disabled?: boolean,
) =>
  useFetch<ApprovalFlowResponse>(
    disabled
      ? null
      : `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}/approvals`,
  )

export const useTimeOffOverlappingRequests = (id?: number) =>
  useFetch<{ id: number; employee: EmployeeOptionInterface }[]>(
    id ? `${API.TIME_OFF}/requests/${id}/overlappingRequests` : null,
  )

export const approveTimeOffRequest = (employeeId: string, id: number) =>
  api.post<EmployeeTimeOffRequestInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}/approve`,
  )

export const rejectTimeOffRequest = (
  employeeId: string,
  id: number,
  rejection_comment: string,
) =>
  api.post<EmployeeTimeOffRequestInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}/reject`,
    {
      rejection_comment,
    },
  )

export const cancelTimeOffRequest = (employeeId: string, id: number) =>
  api.post(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests/${id}/cancel`)

export const useNonWorkingDays = (
  employee_id: string,
  from_date?: string,
  to_date?: string,
  balance_id?: number,
) =>
  useFetch<NonWorkingDay[]>(
    from_date && to_date && !isBefore(new Date(to_date), new Date(from_date))
      ? `${API.TIME_OFF}/requests/nonWorkingDays`
      : null,
    'v1',
    {
      params: {
        employee_id,
        from_date,
        to_date,
        balance_id,
      },
    },
  )

export const timeOffAllPoliciesTableRequests: TableRequestInterface<
  TimeOffPolicyTableInterface,
  TimeOffPoliciesStatsInterface
> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.TIME_OFF}/policies`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
  getStats: async ({ sortBy, filters, page }) =>
    api.get(`${API.TIME_OFF}/policies/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const getTimeOffPolicies = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<TimeOffPolicyTableInterface>
> =>
  api.get(`${API.TIME_OFF}/policies`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const bulkAssignPolicyRequests: RequestInterfaceNew<BulkAssignInterface> = {
  get: async () => api.get(`${API.TIME_OFF}/policies/bulkAssign`),
  update: async data =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/policies/bulkAssign`, data),
  submit: async data =>
    apiWithoutHandling.post(`${API.TIME_OFF}/policies/bulkAssign`, data),
}

export const timeOffPolicyTempAssignments = (
  data?: CreateTempPolicyAssignmentInterface[],
) => apiWithoutHandling.post(`${API.TIME_OFF}/policies/tmpAssignments`, data)

export const timeOffPolicyTempAssignmentsAssign = () =>
  apiWithoutHandling.post(`${API.TIME_OFF}/policies/tmpAssignments/assign`, {})

export const useGetTimeOffPolicyTempAssignmentsStatus = () => {
  const data = useFetch<TempPolicyAssignmentsStatusInterface>(
    `${API.TIME_OFF}/policies/tmpAssignments/status`,
    undefined,
    undefined,
    true,
    {
      refetchInterval: session => (session?.state === 'in_progress' ? 1000 : false),
    },
  )

  return data
}

export const getTimeOffPolicyTempAssignments = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<TempPolicyAssignmentInterface>
> =>
  api.get(`${API.TIME_OFF}/policies/tmpAssignments`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getTimeOffPolicyTempAssignmentsEmployees = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<NormalizedTempPolicyAssignmentsEmployeeInterface>
> => {
  return api
    .get<GetRequestInterface<TempPolicyAssignmentsEmployeeInterface>>(
      `${API.TIME_OFF}/policies/tmpAssignments/employees`,
      {
        params: { ...filterSortPageIntoQuery(sortBy, filters, page), page_size: 50 },
      },
    )
    .then(response => ({
      ...response,
      data: {
        ...response.data,
        results: response.data.results.map(row => {
          return {
            ...row,
            children:
              row.children?.map(child => ({
                id: child.id,
                data: child,
                errors: {},
                error_message: null,
                loading: {},
                state: { id: 'pending', name: 'Pending' },
              })) || [],
          }
        }),
      },
    }))
}

export const archiveTimeOffPolicy = (id: number, archive_assignments: boolean) =>
  api.post<TimeOffPolicyInterface>(`${API.TIME_OFF}/policies/${id}/archive`, {
    archive_assignments,
  })

export const unarchiveTimeOffPolicy = (id: number) =>
  api.post<TimeOffPolicyInterface>(`${API.TIME_OFF}/policies/${id}/unarchive`)

export const timeOffPoliciesRequests: RequestInterfaceNew<TimeOffPolicyInterface> = {
  get: async ({ id }) => api.get(`${API.TIME_OFF}/policies/${id}`),
  update: async (data, { id }) =>
    apiWithoutHandling.patch(`${API.TIME_OFF}/policies/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.TIME_OFF}/policies`, data),
  delete: async ({ id }) => api.delete(`${API.TIME_OFF}/policies/${id}`),
}

export const useGetTimeOffPolicy = (id?: number | string) =>
  useFetch<TimeOffPolicyInterface>(id ? `${API.TIME_OFF}/policies/${id}` : null)

export const updateTimeOffPolicyAccrualAmount = (
  id: number,
  accrual_amount: string | number,
) => apiWithoutHandling.patch(`${API.TIME_OFF}/policies/${id}`, { accrual_amount })

export const getTimeOffPolicyChangelog =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<ChangelogInterface<TimeOffPolicyInterface>>
  > =>
    api.get(`${API.TIME_OFF}/policies/${id}/changelogFields`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const timeOffPoliciesBasicRequests: RequestInterfaceNew<TimeOffPolicyBasicsStep> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/policies/basicStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/policies/basicStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/policies/basicStep`, data),
  }

export const useTimeOffPolicyBasicStep = (id?: number | string) =>
  useFetch<TimeOffPolicyBasicsStep>(
    id ? `${API.TIME_OFF}/policies/basicStep/${id}` : null,
  )

export const timeOffPoliciesDetailsRequests: RequestInterfaceNew<TimeOffPolicyDetailsStep> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/policies/detailsStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/policies/detailsStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/policies/detailsStep`, data),
  }

export const timeOffPoliciesRequestSettingsRequests: RequestInterfaceNew<TimeOffPolicyRequestsStep> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/policies/requestsStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/policies/requestsStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/policies/requestsStep`, data),
  }

export const timeOffPoliciesEligibilityRequests: RequestInterfaceNew<TimeOffPolicyEligibilityStep> =
  {
    get: async ({ id }) => api.get(`${API.TIME_OFF}/policies/eligibilityStep/${id}`),
    update: async (data, { id }) =>
      apiWithoutHandling.patch(`${API.TIME_OFF}/policies/eligibilityStep/${id}`, data),
    submit: async data =>
      apiWithoutHandling.post(`${API.TIME_OFF}/policies/eligibilityStep`, data),
  }

export const useTimeOffPolicyProcessingStatus = (id: number | string) =>
  useFetch<TimeOffProcessingStatus>(`${API.TIME_OFF}/policies/${id}/processingStatus`)

export const timeOffPolicyEligibilityCustomFiltersRequests = {
  get: async (id: string | number, groupId: string | number) =>
    api.get<DynamicGroupInerface>(
      `${API.TIME_OFF}/policies/eligibilityStep/${id}/tableFilter/${groupId}`,
    ),
  update: async (
    data: TimeOffTableFilterInterface,
    id: string | number,
    groupId: string | number,
  ) =>
    api.patch<DynamicGroupInerface>(
      `${API.TIME_OFF}/policies/eligibilityStep/${id}/tableFilter/${groupId}`,
      data,
    ),
  submit: async (data: TimeOffTableFilterInterface, id: string | number) =>
    api.post<DynamicGroupInerface>(
      `${API.TIME_OFF}/policies/eligibilityStep/${id}/tableFilter`,
      data,
    ),
}

export const getTimeOffPolicyEligibilityCustomFiltersWithoutHandling = (
  id: string | number,
  groupId: string | number,
) =>
  apiWithoutHandling.get<DynamicGroupInerface>(
    `${API.TIME_OFF}/policies/eligibilityStep/${id}/tableFilter/${groupId}`,
  )

export const getTimeOffPoliciesEligibleEmployeesTableRequests = (
  id: number | string,
): TableRequestInterface<TimeOffPolicyReviewEligibleEmployeeInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.TIME_OFF}/policies/autoAssignmentsStep/${id}/eligibleEmployees`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
})

export const useTimeOffPolicyEditStepStatuses = (id?: number | string) =>
  useFetch<TimeOffPolicyEditStepStatusesInterface>(
    id ? `${API.TIME_OFF}/policies/${id}/draftStepsStatus` : null,
  )

export const useTimeOffReviewStats = (id: number | string) =>
  useFetch<TimeOffReviewStats>(`${API.TIME_OFF}/policies/autoAssignmentsStep/${id}/stats`)

export const activateTimeOffPolicy = ({
  id,
  updatePolicyAssignments = true,
}: {
  id: string | number
  updatePolicyAssignments: boolean
}) =>
  apiWithoutHandling.post(`${API.TIME_OFF}/policies/${id}/activate`, {
    update_policy_assignments: updatePolicyAssignments,
  })

export const getEmployeePolicyAssignments =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffPolicyAssignmentInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useEmployeePolicyAssigntmentFirstAccrual = (
  employeeId: string,
  policy_id?: number,
  start_date?: string,
) =>
  useFetch<EmployeeTimeOffPolicyAssignmentFirstAccrual>(
    policy_id && start_date
      ? `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/calculateFirstAccrual`
      : null,
    undefined,
    {
      params: {
        policy_id,
        start_date,
      },
    },
    true,
    { refetchOnWindowFocus: false },
  )

export const employeePolicyAssignmentRequest: RequestInterfaceNew<EmployeeTimeOffPolicyAssignmentInterface> =
  {
    get: async ({ id, employeeId }) =>
      api.get(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/${id}`),
    update: async (data, { id, employeeId }) =>
      apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/${id}`,
        data,
      ),
    submit: async (data, { employeeId }) =>
      apiWithoutHandling.post(
        `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments`,
        data,
      ),
    delete: async (_, { id, employeeId }) =>
      api.delete(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/${id}`),
  }

export const archiveEmployeePolicyAssignment = (id: number, employeeId: string) =>
  api.post<EmployeeTimeOffPolicyAssignmentInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/${id}/archive`,
  )

export const unassignEmployeePolicyAssignment = (
  id: number,
  employeeId: number,
  end_date?: string,
  last_accrual_amount?: string,
) =>
  apiWithoutHandling.post<EmployeeTimeOffPolicyAssignmentInterface>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/policyAssignments/${id}/unassign`,
    {
      end_date,
      last_accrual_amount,
    },
  )

export const getEmployeeTimeOffBalances =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffBalancesInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/balances`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useGetEmployeeTimeOffBalances = (
  employeeId?: number,
  filters?: FilterByInterface[],
) =>
  useFetch<GetRequestInterface<EmployeeTimeOffBalancesInterface>>(
    employeeId ? `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/balances` : null,
    undefined,
    { params: filterSortPageIntoQuery(undefined, filters) },
  )

export const useTimeOffBalanceDetails = (id: string) =>
  useFetch<EmployeeTimeOffBalancesInterface>(`${API.TIME_OFF}/balances/${id}`)

export const getTimeOffBalanceTransactions =
  (id: string) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffBalanceTransaction>
  > =>
    api.get(`${API.TIME_OFF}/balances/${id}/transactions`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const employeeTimeOffBalancesSelector = (employeeId: string | number) =>
  api.get<{ options: EmployeeTimeOffBalanceSelectorOption[] }>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/balances/selector`,
    {
      params: { balance_type: 'limited', status: Statuses.open },
    },
  )

export const useBalanceAdjustmentFields = (id?: number) =>
  useFetch<{ options: AdjustmentFieldInterface[] }>(
    id ? `${API.TIME_OFF}/balances/${id}/adjustmentFields` : null,
  )

export const useEmployeeTimeOffBalanceSelector = (employeeId: string | number) =>
  useFetch<{ options: EmployeeTimeOffBalanceSelectorOption[] }>(
    `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/balances/selector`,
    undefined,
    {
      params: { status: Statuses.open },
    },
  )

export const adjustEmployeeTimeOffBalance = (
  data: EmployeeTimeOffAdjustBalanceInterface,
) =>
  apiWithoutHandling.post<EmployeeTimeOffAdjustBalanceInterface>(
    `${API.TIME_OFF}/balanceAdjustments`,
    data,
  )

export const getRequestsOfReports =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<RequestOfReportsInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${id}${API.TIME_OFF}/requestsOfReports`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getRequestsOfReportsStats =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<RequestOfReportsStatsInterface> =>
    api.get(`${API.EMPLOYEES}/${id}${API.TIME_OFF}/requestsOfReports/stats`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getRequestsOfReportsCalendar =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffRequestsCalendarInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${id}${API.TIME_OFF}/requestsOfReports/calendar`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getTeamTimeOffRequests =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<TeamTimeOffRequestsInterface>
  > =>
    api.get(`${API.TEAMS}/${id}${API.TIME_OFF}/requests`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getTeamTimeOffCalendar =
  (id: number | undefined) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffRequestsCalendarInterface>
  > => {
    if (!id) {
      throw new Error("Can't request team calendar data without team id specified")
    }
    return api.get(`${API.TEAMS}/${id}${API.TIME_OFF}/requests/calendar`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
  }

export const getDepartmentTimeOffCalendar =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<EmployeeTimeOffRequestsCalendarInterface>
  > =>
    api.get(`/departments/${id}${API.TIME_OFF}/requests/calendar`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getDepartmentTimeOffRequests =
  (id: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<TeamTimeOffRequestsInterface>
  > =>
    api.get(`${API.DEPARTMENT}/${id}${API.TIME_OFF}/requests`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const useLocationRegions = (countryId: string | number | undefined) =>
  useFetch<{ options: IdAndName[] }>(
    countryId ? `${API.TIME_OFF}/regions/selector` : null,
    undefined,
    {
      params: {
        country_id: countryId,
      },
    },
  )

export const useEmployeeTimeOffCalendar = (
  employeeId: string | number,
  { sortBy, filters, page }: FetchDataQueryInterface,
) => {
  const context = useFetch<GetRequestInterface<EmployeeTimeOffRequestsCalendarInterface>>(
    `${API.EMPLOYEES}/${employeeId}/timeOff/requests/calendar`,
    undefined,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  )

  return { ...context, data: context.data?.results }
}

export const getEmployeesTimeOffCalendar = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<EmployeeTimeOffRequestsCalendarInterface>
> =>
  api.get(`${API.TIME_OFF}/requests/calendar`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const useEmployeeTimeOffRequests = (
  timeOffEnabled: boolean,
  { sortBy, filters, page }: FetchDataQueryInterface,
  employeeId?: string | number,
) => {
  const result = useFetch<GetRequestInterface<EmployeeTimeOffRequestInterface>>(
    timeOffEnabled && employeeId
      ? `${API.EMPLOYEES}/${employeeId}${API.TIME_OFF}/requests`
      : null,
    undefined,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  )
  return result.data?.results
}

export const bulkEditTimeOffPolicies = (
  ids: number[],
  fields: Record<string, number | string>,
) =>
  apiWithoutHandling.patch(`${API.TIME_OFF}/policies/bulkEdit`, {
    ids,
    fields,
  })

export const bulkDeleteTimeOffPolicies = (ids: number[]) =>
  apiWithoutHandling.post(`${API.TIME_OFF}/policies/bulkDelete`, {
    ids,
  })

export const adjustBalanceTmpAssignment = (
  id: number,
  expected_amount?: string | number,
) =>
  apiWithoutHandling.post(
    `${API.TIME_OFF}/policies/tmpAssignments/employees/adjustBalance/${id}`,
    {
      expected_amount,
    },
  )

export const createEmployeeTmpPolicyAssignment = (employeeId: number, policyId: number) =>
  apiWithoutHandling.post(
    `${API.TIME_OFF}/policies/tmpAssignments/employees/${employeeId}/${policyId}/assign`,
  )

export const deleteTmpAssignment = (id: number) =>
  apiWithoutHandling.delete(`${API.TIME_OFF}/policies/tmpAssignments/${id}`)

export const createInitialPolicies = (policy_categories: number[]) =>
  apiWithoutHandling.post(`${API.TIME_OFF}/policies/initials`, {
    policy_categories,
  })
