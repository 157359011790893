import { useGetSpecialisation } from '@src/api/specialisations'
import { useGetRole } from '@src/api/roles'
import { CompetencyMatrixInterface } from '@src/interfaces/roles'

interface Props {
  functionalSkillMatrix: CompetencyMatrixInterface[]
  isLoading: boolean
}

export const useSkillMatrix = (specialisationId?: number): Props => {
  const { data: specialisation, isLoading: isSpecialisationLoading } =
    useGetSpecialisation(specialisationId)
  const { data: role, isLoading: isRoleLoading } = useGetRole(specialisation?.role?.id)

  const functionalSkillMatrix: CompetencyMatrixInterface[] = [
    ...(specialisation?.functional_competency_matrix ?? []),
    ...(role?.functional_competency_matrix ?? []),
  ]

  return {
    isLoading: isSpecialisationLoading || isRoleLoading,
    functionalSkillMatrix,
  }
}
