import React from 'react'
import { TalentType } from '@src/interfaces/talent/talent'
import { useGetEnableDownloadSetting } from '@src/utils/settings'
import ExportMenu, { ExportMenuProps } from './ExportMenu'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

interface AllowedExportMenuProps extends ExportMenuProps {
  type: TalentType
  permission?: PermissionTypes
}

export const AllowedExportMenu = ({
  type,
  permission,
  ...props
}: AllowedExportMenuProps) => {
  const settingEnabled = useGetEnableDownloadSetting(type)
  const permissions = useSelector(selectPermissions)
  const hasPermission = permission ? permissions.includes(permission) : true
  const canDownload = settingEnabled && hasPermission

  return canDownload ? <ExportMenu {...props} /> : null
}
