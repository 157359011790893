import React from 'react'
import { HStack } from '@revolut/ui-kit'

type Props = {
  children: React.ReactNode
}

export const PageHeaderLabelsBar = ({ children }: Props) => {
  return (
    <HStack align="center" gap="s-8">
      {children}
    </HStack>
  )
}
