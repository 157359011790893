import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import InterviewFeedbackForm from '@src/features/InterviewFeedbackForm'
import HeadCard from '@src/pages/Forms/InterviewScorecardTemplate/Preview/components/HeadCard'
import { Flex, Spinner } from '@revolut/ui-kit'
import { getInterviewScorecardTemplatePreview } from '@src/api/interviewScorecardTemplate'
import { InterviewFeedbackInterface, InterviewType } from '@src/interfaces/interviewTool'
import { InterviewScorecardTemplateInterface } from '@src/interfaces/interviewScorecardTemplates'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'

type Props = {
  data?: InterviewScorecardTemplateInterface
  id?: number
  stageType?: InterviewType
}

const PreviewScorecardInner = ({ id, data, stageType }: Props) => {
  const form = useLapeContext<InterviewFeedbackInterface>()
  const [loading, setLoading] = useState(true)

  const fetchPreview = async (
    template?: InterviewScorecardTemplateInterface,
    templateId?: number,
  ) => {
    setLoading(true)
    const resp = await getInterviewScorecardTemplatePreview(template, templateId)
    form.reset(resp.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchPreview(data, id)
  }, [])

  if (loading) {
    return (
      <Flex p="s-16" justifyContent="center">
        <Spinner />
      </Flex>
    )
  }

  return (
    <>
      <HeadCard data={form.values?.scorecard.interview_objective} />
      <InterviewFeedbackForm stageType={data?.stage_type?.id || stageType} />
    </>
  )
}

const PreviewScorecard = (props: Props) => (
  <Form validator={{}}>
    <PreviewScorecardInner {...props} />
  </Form>
)

export default connect(PreviewScorecard)
