import React, { useEffect, useState } from 'react'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'
import { Flex, Input, InputGroup } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { SendCandidateEmailInterface, WhenToSend } from '@src/interfaces/hiringProccess'
import LapeDatePickerInput from '@components/Inputs/LapeFields/LapeDatePickerInput'
import EmailForm from '@src/features/EmailForm/EmailForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendEmailCommonProps } from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { getDate, getTime, getCurrentTimezone } from '@src/utils/timezones'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useGetCandidateSettings } from '@src/api/settings'
import { OptionInterface } from '@src/interfaces/selectors'
import { isBeforeCurrent } from '../Candidate/ScheduleSidebar/utils'

interface Props
  extends Pick<
    SendEmailCommonProps,
    'children' | 'noEditor' | 'bottom' | 'emailFormProps'
  > {
  reducedTitlePadding?: boolean
  showInvalidPlaceholdersWarning?: boolean
  onEmailTemplateChange?: (emailTemplate: OptionInterface | null) => void
}

const SendEmailCommonContent = ({
  children,
  noEditor,
  bottom,
  reducedTitlePadding,
  showInvalidPlaceholdersWarning,
  emailFormProps,
  onEmailTemplateChange,
}: Props) => {
  const user = useSelector(selectUser)
  const { values } = useLapeContext<SendCandidateEmailInterface>()
  const [date, setDate] = useState(getDate(new Date()))
  const [time, setTime] = useState(
    getTime(
      values.custom_sending_datetime
        ? new Date(values.custom_sending_datetime)
        : new Date(),
    ),
  )
  const { data: candidateSettings } = useGetCandidateSettings()
  const timezoneId = getCurrentTimezone()
  const onCustomSendingDateTimeChange = (newDate: string, newTime: string) => {
    const utc = zonedTimeToUtc(`${newDate} ${newTime}`, timezoneId)
    values.custom_sending_datetime = utc.toISOString()
  }

  useEffect(() => {
    if (
      candidateSettings?.default_automatic_scheduling_email_template &&
      !values.email_template
    ) {
      values.email_template =
        candidateSettings.default_automatic_scheduling_email_template

      onEmailTemplateChange?.(values.email_template)
    }
  }, [candidateSettings])

  useEffect(() => {
    if (
      values.when_to_send?.id === WhenToSend.CUSTOM &&
      !values.custom_sending_datetime
    ) {
      onCustomSendingDateTimeChange(date, time)
    }
  }, [values.when_to_send])

  const timeError = isBeforeCurrent({ day: date, time }, timezoneId)
    ? 'The time is in the past'
    : ''

  return (
    <AutoStepper>
      {children}

      {!noEditor && (
        <>
          <NewStepperTitle
            title="Email details"
            type={NewStepperTitleType.compact}
            mt={reducedTitlePadding ? 0 : 's-24'}
          />
          <InputGroup>
            <LapeRadioSelectInput<OptionInterface>
              name="email_template"
              label="Email template"
              selector={selectorKeys.email_templates_active}
              onAfterChange={onEmailTemplateChange}
            />
            <LapeRadioSelectInput
              name="when_to_send"
              label="When to send"
              selector={selectorKeys.candidate_email_when_to_send}
            />
            {values.when_to_send?.id === WhenToSend.CUSTOM && (
              <InputGroup variant="horizontal">
                <Flex flex="1">
                  <LapeDatePickerInput
                    clearable={false}
                    name="custom_sending_datetime"
                    label="Schedule date"
                    disabledDays={{ before: new Date() }}
                    required
                    displayInUtc={false}
                    onChange={e => {
                      if (e) {
                        const value = getDate(e)
                        setDate(value)
                        onCustomSendingDateTimeChange(value, time)
                      }
                    }}
                  />
                </Flex>
                <Flex flex="1">
                  <Input
                    value={time}
                    label="Schedule time"
                    type="time"
                    required
                    onChange={e => {
                      const value = e.currentTarget.value
                      setTime(value)
                      onCustomSendingDateTimeChange(date, value)
                    }}
                    errorMessage={timeError}
                    aria-invalid={!!timeError}
                  />
                </Flex>
              </InputGroup>
            )}

            <EmailForm<SendCandidateEmailInterface>
              userEmail={user.email}
              showInvalidPlaceholdersWarning={showInvalidPlaceholdersWarning}
              {...emailFormProps}
            />
          </InputGroup>
        </>
      )}

      {bottom}
    </AutoStepper>
  )
}

export default SendEmailCommonContent
