import React from 'react'
import { getEmployeeGrants } from '@src/api/totalCompensation'
import {
  grantCancelledSharesColumn,
  grantDateColumn,
  grantGrantedSharesColumn,
  grantIdColumn,
  grantReasonColumn,
  grantScheduleColumn,
  grantStartDateColumn,
  grantSoldSharesColumn,
  grantUnvestedSharesColumn,
  grantVestedSharesColumn,
} from '@src/constants/columns/totalCompensation'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTotalCompensationGrantInterface } from '@src/interfaces/totalCompensation'
import { useTable } from '@components/Table/hooks'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'

const ROW: RowInterface<EmployeeTotalCompensationGrantInterface> = {
  cells: [
    {
      ...grantIdColumn,
      width: 100,
    },
    {
      ...grantDateColumn,
      width: 100,
    },
    {
      ...grantReasonColumn,
      width: 100,
    },
    {
      ...grantScheduleColumn,
      width: 300,
    },
    {
      ...grantStartDateColumn,
      width: 100,
    },
    {
      ...grantGrantedSharesColumn,
      width: 100,
    },
    {
      ...grantVestedSharesColumn,
      width: 100,
    },
    {
      ...grantUnvestedSharesColumn,
      width: 100,
    },
    {
      ...grantCancelledSharesColumn,
      width: 100,
    },
    {
      ...grantSoldSharesColumn,
      width: 100,
    },
  ],
}

interface Props {
  id: number
}

export const GrantsTable = ({ id }: Props) => {
  const table = useTable<EmployeeTotalCompensationGrantInterface>({
    getItems: getEmployeeGrants(id),
  })

  return (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable
          dataType="Grant"
          hideCount
          name={TableNames.TotalCompensationGrants}
          noDataMessage="Grants will appear here"
          row={ROW}
          useWindowScroll
          {...table}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
