import { Avatar, Widget, IconName, Item, VStack } from '@revolut/ui-kit'
import React, { PropsWithChildren, ReactNode } from 'react'

export const CellWithItem = ({
  icon,
  avatar,
  title,
  description,
  side,
  children,
}: PropsWithChildren<{
  icon?: IconName
  avatar?: ReactNode
  title: string
  description?: string
  side?: ReactNode
}>) => (
  <Widget>
    <VStack space="s-16" width="100%">
      <Item px="s-8" pb="s-8">
        <Item.Avatar>{avatar || <Avatar useIcon={icon} />}</Item.Avatar>

        <Item.Content>
          <Item.Title>{title}</Item.Title>
          {description ? <Item.Description>{description}</Item.Description> : null}
        </Item.Content>
        {!!side && <Item.Side>{side}</Item.Side>}
      </Item>
      <VStack gap="s-8">{children}</VStack>
    </VStack>
  </Widget>
)
