import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { ActionButton, Flex, Image, Text, Token } from '@revolut/ui-kit'
import { PageWrapper } from '@src/components/Page/Page'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'

export const WelcomePage = () => {
  return (
    <PageWrapper>
      <Flex alignItems="center" flex="1 1" flexDirection="column" justifyContent="center">
        <Text mb="s-16" variant="h1" use="h1">
          <FormattedMessage
            id="onboarding.welcome.header"
            defaultMessage="Congratulations! You’re ready to roll."
          />
        </Text>
        <Text color={Token.color.greyTone50} variant="caption">
          Welcome on board! We have sent an email with instructions to your employees.
          Your account is set up and ready for business
        </Text>
        <Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images/3D266.png',
            '2x': 'https://assets.revolut.com/assets/3d-images/3D266@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images/3D266@3x.png',
          }}
          maxWidth={300}
          m="s-32"
        />
        <ActionButton onClick={() => navigateTo(pathToUrl(ROUTES.MAIN))} variant="accent">
          Go to Dashboard
        </ActionButton>
      </Flex>
    </PageWrapper>
  )
}
