import React, { useMemo } from 'react'
import sortBy from 'lodash/sortBy'
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Item,
  Placeholder,
  Token,
  VStack,
  Widget,
  Text,
  IconName,
  TextSkeleton,
} from '@revolut/ui-kit'

import { CurrencySelect } from '@components/CurrencySelect/CurrencySelect'
import {
  TotalErrorIcon,
  useCompensationTotal,
  YearFilter,
} from '@src/pages/EmployeeProfile/Preview/Rewards/common'
import {
  useGetEmployeeLastContractValue,
  useGetEmployeeRewardsSummary,
} from '@src/api/rewards'
import { EmployeeInterface } from '@src/interfaces/employees'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { RewardsSummary, RewardType } from '@src/interfaces/rewards'
import AvatarItemSkeleton from '@components/Skeletons/AvatarItemSkeleton'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { CompensationParams } from '@src/pages/EmployeeProfile/Layout/common/types'
import { formatMoney } from '@src/utils/format'

const rewardTypeToItemProps = (reward: RewardType): { icon: IconName; route: string } => {
  switch (reward) {
    case 'contract_salary':
      return { icon: 'Credit', route: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY }
    case 'bonus':
      return { icon: 'CashIncome', route: ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES }
    case 'benefits':
      return { icon: 'Present', route: ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS }
    case 'salary_sacrifice':
      return { icon: 'CashFlow', route: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE }
    default:
      return { icon: 'Question', route: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY }
  }
}

type Props = {
  data: EmployeeInterface
  compensationParams: CompensationParams
}
export const CompensationLayoutTab = ({ data, compensationParams }: Props) => {
  const { year, setYear, currency, setCurrency } = compensationParams
  const { data: rewardsSummaryData, isLoading: isLoadingRewardsSummary } =
    useGetEmployeeRewardsSummary(year ? data.id : undefined, year, currency)

  const REWARDS_ORDER: RewardType[] = [
    'contract_salary',
    'bonus',
    'benefits',
    'salary_sacrifice',
  ]

  const rewardsOrdered = useMemo(
    () =>
      sortBy(rewardsSummaryData?.results, [
        reward => REWARDS_ORDER.indexOf(reward.reward_type.id),
      ]),
    [rewardsSummaryData?.results],
  )

  const { total, totalError } = useCompensationTotal(
    rewardsSummaryData?.results,
    currency,
    isLoadingRewardsSummary,
  )

  const { data: lastContractValue, isLoading: isLastContractValueLoading } =
    useGetEmployeeLastContractValue(data.id, year)

  const renderTotalAmount = (reward: RewardsSummary) => {
    let totalAmount: string | React.ReactNode

    if (reward.reward_type.id === 'contract_salary' && lastContractValue) {
      totalAmount = isLastContractValueLoading ? (
        <HStack space="s-8">
          <TextSkeleton width={72} />
          <TextSkeleton width={32} />
        </HStack>
      ) : (
        `${lastContractValue.annual_salary} ${lastContractValue.result_currency}`
      )
    }
    if (reward.exchanged_total_amount) {
      totalAmount = formatMoney(reward.exchanged_total_amount, reward.result_currency)
    }
    return totalAmount ? <Text variant="primary">{totalAmount}</Text> : null
  }

  const renderContent = () => {
    if (isLoadingRewardsSummary) {
      return (
        <>
          <AvatarItemSkeleton />
          <AvatarItemSkeleton />
          <AvatarItemSkeleton />
        </>
      )
    }
    if (!isLoadingRewardsSummary && !rewardsSummaryData?.results.length) {
      return (
        <Placeholder>
          <Placeholder.Image
            src="https://assets.revolut.com/assets/3d-images/3D086.png"
            srcSet="https://assets.revolut.com/assets/3d-images/3D086@2x.png 2x, https://assets.revolut.com/assets/3d-images/3D086@3x.png 3x"
          />
          <Placeholder.Title>
            Compensation data for this period was not found
          </Placeholder.Title>
        </Placeholder>
      )
    }
    return (
      <>
        {rewardsOrdered.map(reward => {
          const { icon, route } = rewardTypeToItemProps(reward.reward_type.id)
          return (
            <Item
              key={reward.reward_type.id}
              use={InternalLink}
              to={pathToUrl(route, { id: String(data.id) })}
            >
              <Item.Avatar>
                <Avatar useIcon={icon} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{reward.reward_type.name}</Item.Title>
                <Item.Description>{reward.description}</Item.Description>
              </Item.Content>
              <Item.Side>
                <HStack align="center" gap="s-8">
                  {renderTotalAmount(reward)}
                  <IconButton useIcon="ChevronRight" color={Token.color.greyTone50} />
                </HStack>
              </Item.Side>
            </Item>
          )
        })}
      </>
    )
  }

  return (
    <Widget>
      <HStack gap="s-32" p="s-16">
        <Box minWidth="s-64">
          <CurrencySelect
            value={totalError ? <TotalErrorIcon /> : total}
            label="Total"
            onCurrencyChange={({ iso_code }) => setCurrency(iso_code)}
          />
        </Box>
        <YearFilter value={year} onChange={setYear} />
      </HStack>
      <VStack>{renderContent()}</VStack>
    </Widget>
  )
}
