import React, { useEffect } from 'react'
import { Group, Subheader, Text, Token, VStack } from '@revolut/ui-kit'
import { ItemSwitch } from '@components/Inputs/ItemSwitch/ItemSwitch'
import NewCheckboxGroup from '@components/Inputs/NewCheckboxGroup/NewCheckboxGroup'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import NewCheckbox from '@components/Inputs/NewCheckbox/NewCheckbox'
import { useArchiving } from '@components/ArchivingCandidateSidebar/common'
import { useGetUnarchivedInterviewRounds } from '@src/api/recruitment/interviews'

type Props = {
  candidateId: number | null
}

export const CandidateOpportunitiesList = ({ candidateId }: Props) => {
  const { data: rounds, isLoading: isRoundsLoading } =
    useGetUnarchivedInterviewRounds(candidateId)

  const { archiveAll, setArchiveAll, setSelectedRounds, selectedRounds } = useArchiving()

  useEffect(() => {
    if (rounds?.[0]) {
      setSelectedRounds([rounds[0]])

      if (rounds.length === 1) {
        setArchiveAll(true)
      }
    }
  }, [rounds])

  return (
    <VStack gap="s-12">
      <Subheader>
        <Subheader.Title>Candidate opportunities</Subheader.Title>
      </Subheader>
      <Group>
        <ItemSwitch
          title="Archive all"
          checked={archiveAll}
          onChange={e => {
            const checked = e.currentTarget.checked
            setArchiveAll(checked)
            setSelectedRounds(checked ? rounds : [])
          }}
        />
        {(!!rounds?.length || isRoundsLoading) && (
          <VStack space="s-16" p="s-16">
            <NewCheckboxGroup<InterviewRoundInterface>
              options={
                rounds?.map(round => ({
                  label: round.specialisation.name,
                  value: round,
                })) || []
              }
              value={selectedRounds}
              onChange={selected => {
                setSelectedRounds(selected)
                setArchiveAll(selected.length === rounds.length)
              }}
              renderCheckbox={(o, inputProps) => (
                <NewCheckbox
                  {...inputProps}
                  label={o.label}
                  description={
                    o.value.active ? (
                      <Text color={Token.color.success}>Main posting</Text>
                    ) : null
                  }
                />
              )}
              loading={isRoundsLoading}
            />
          </VStack>
        )}
      </Group>
    </VStack>
  )
}
