import React from 'react'
import { Checkbox, Item, Link, Text, Token, VStack } from '@revolut/ui-kit'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface RequisitionItemProps {
  requisition: RequisitionInterface
  selected: boolean
  onClick: (id: number | string) => void
}

export const RequisitionItem = ({
  requisition,
  selected,
  onClick,
}: RequisitionItemProps) => {
  const seniorityRange =
    requisition.seniority_min?.id !== requisition.seniority_max?.id
      ? `${requisition.seniority_min?.name} - ${requisition.seniority_max?.name}`
      : requisition.seniority_min?.name ?? ''
  return (
    <Item
      style={{ backgroundColor: 'transparent' }}
      use="label"
      onClick={e => {
        e.preventDefault()
        onClick(requisition.id)
      }}
    >
      <Item.Prefix>
        <Checkbox checked={selected} />
      </Item.Prefix>
      <Item.Content>
        <Item.Title>{requisition.team?.name}</Item.Title>
        <Item.Description>
          <VStack gap="s-8">
            {seniorityRange && <Text>{seniorityRange}</Text>}
            <Link
              to={pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, { id: requisition.id })}
              target="_blank"
              use={InternalLink}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              View details
            </Link>
          </VStack>
        </Item.Description>
      </Item.Content>
      <Item.Side>
        <Text variant="body2" color={Token.color.greyTone50}>
          {requisition.headcount}
        </Text>
      </Item.Side>
    </Item>
  )
}
