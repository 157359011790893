import React from 'react'
import { Widget, Text, VStack, Token } from '@revolut/ui-kit'
import { Video } from '@components/Video/Video'

export const DocumentsIntro = () => {
  return (
    <VStack gap="s-16">
      <Video
        poster="/intros/Intro%20-%20Documents.png"
        url="/intros/Intro%20-%20Documents.mov"
      />
      <Widget p="s-16">
        <VStack space="s-8">
          <Text variant="primary">How to setup Documents?</Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            Welcome to our Documents feature, where you can effortlessly centralise all
            your files in a secure, organised repository. From crucial documents like
            employee contracts, promotion letters, and offers to personal items like
            passports, our platform provides a single, safe location for all your files.
          </Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            Worried about privacy? With our system, you have full control. You determine
            who can access each stored document, ensuring the utmost privacy and security
            for your important files.
          </Text>
        </VStack>
      </Widget>
    </VStack>
  )
}
