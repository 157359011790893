import { ImportFieldType } from '@src/interfaces/bulkDataImport'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { ImportJobPostingsDataInterface } from '@src/interfaces/importJobPostings'
import { EmployeeEmails } from '@src/interfaces/selectors'

export const fields: ImportFieldType<ImportJobPostingsDataInterface>[] = [
  {
    type: 'input',
    field: 'title',
    label: 'title',
  },
  {
    type: 'radio-select',
    field: 'specialisation',
    selector: selectorKeys.specialisations,
    label: 'Specialisation',
    allowCreateNewOption: true,
    valueKey: 'name',
  },
  {
    type: 'radio-select',
    field: 'recruiter',
    selector: selectorKeys.employee_emails,
    label: 'Recruiter',
    valueKey: 'name',
  },
  {
    type: 'multi-select',
    field: 'locations',
    selector: selectorKeys.location,
    label: 'Locations',
    valueKey: 'name',
  },
  {
    type: 'html',
    field: 'about_the_role',
    label: 'About the role',
  },
  {
    type: 'html',
    field: 'nice_to_have',
    label: 'Nice to have',
  },
  {
    type: 'html',
    field: 'what_youll_be_doing',
    label: "What you'll be doing",
  },
  {
    type: 'html',
    field: 'what_youll_need',
    label: "What you'll need",
  },
]

export const useOptions = () => {
  const { options: employeeOptions } = useFetchOptions<EmployeeEmails>(
    selectorKeys.employee_emails,
    true,
  )
  return { employeeOptions }
}
