import React, { useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { cloneDeep, isEmpty, union } from 'lodash'
import { QueryObserverResult } from 'react-query'

import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { useTable } from '@src/components/Table/hooks'
import { ProcessingFileState } from '@src/features/BulkDataImport/ProcessingFileState'
import {
  ImportFieldType,
  ImportInterface,
  ImportSessionInterface,
} from '@src/interfaces/bulkDataImport'
import {
  applyImportSession,
  deleteImportSessionRow,
  editImportSessionRow,
  getUploadSessionTable,
  useGetImportSessionData,
} from '@src/api/bulkDataImport'
import { importExtraFieldColumn } from '@src/constants/columns/bulkDataImport'
import { useGetSectionCustomFields } from '@src/api/customFields'
import { SectionOptions } from '@src/interfaces/customFields'
import { importEmployeesExtraFieldColumn } from '@src/constants/columns/importEmployees'
import { BulkDataImportSessionSidebar } from './BulkDataImportSessionSidebar'
import { BulkDataImportActionsProps } from './BulkDataImportActions'
import { SessionTable } from './SessionTable'

interface UploadSessionTableProps<T> {
  actions: (params: BulkDataImportActionsProps) => React.ReactNode
  apiEndpoint: string
  sessionData: ImportSessionInterface
  refetchSessionData: () => Promise<QueryObserverResult<ImportSessionInterface, Error>>
  tableName: TableNames
  row: Partial<RowInterface<ImportInterface<T>>>
  fields: ImportFieldType<T>[]
  disabled: boolean
  customFieldDropdownOptionKey?: string
}

const UploadSessionTable = <T,>({
  actions,
  apiEndpoint,
  sessionData,
  refetchSessionData,
  tableName,
  row,
  fields,
  disabled = false,
  customFieldDropdownOptionKey,
}: UploadSessionTableProps<T>) => {
  const [state, setState] = useState(sessionData.state.id)

  const table = useTable({
    getItems: getUploadSessionTable<T>(apiEndpoint, sessionData.id),
  })

  const [submitPending, setSubmitPending] = useState(false)
  const [sidebarRowData, setSidebarRowData] = useState<ImportInterface<T>>()

  useEffect(() => {
    if (sessionData.state.id !== 'applying') {
      // table data should be refreshed once after we leave applying state
      if (state !== sessionData.state.id) {
        table.refresh()
      }
      setState(sessionData.state.id)

      return undefined
    }

    const interval = setInterval(() => {
      table.refresh()
    }, 2000)

    return () => clearInterval(interval)
  }, [sessionData.state.id, state])

  const fieldValues = useMemo(() => {
    const hasErrors = table.data?.some(r => !isEmpty(r.errors))
    const predefinedFieldKeys = fields.map(f => f.field) as string[]
    const visibleFields = union(
      Object.keys(table.data?.[0]?.data || {}),
      Object.keys(table.data?.[0]?.errors || {}),
    )
    const hiddenFields = predefinedFieldKeys.filter(f => !visibleFields.includes(f))
    const extraFields = visibleFields.filter(f => !predefinedFieldKeys.includes(f))

    return { hasErrors, visibleFields, hiddenFields, extraFields }
  }, [table.data])

  const { data: customFields, isLoading: isLoadingCustomFields } =
    useGetSectionCustomFields(
      fieldValues.extraFields.length > 0 ? SectionOptions.EmployeeProfile : undefined,
      fieldValues.extraFields,
    )

  const onEditRow = (id: number) => {
    const rowToEdit = table.data.find(r => r.id === id)

    if (rowToEdit && !disabled) {
      setSidebarRowData(cloneDeep(rowToEdit))
    }
  }

  const onDeleteSuccess = () => {
    table.refresh()
  }

  const onSubmit = async () => {
    setSubmitPending(true)

    try {
      await applyImportSession(apiEndpoint, sessionData.id)
      await refetchSessionData()
    } finally {
      setSubmitPending(false)
    }
  }

  return (
    <>
      <SessionTable
        disableActionColumn={disabled}
        disableStateColumn={!disabled}
        hiddenCells={fieldValues.hiddenFields.reduce(
          (allHidden, hiddenKey) => ({ [hiddenKey]: true, ...allHidden }),
          {},
        )}
        sessionRow={{
          createExtraFieldColumn: importExtraFieldColumn,
          createCustomFieldColumn: importEmployeesExtraFieldColumn,
          customFields: customFields?.results,
          isLoadingCustomFields,
          extraFields: fieldValues.extraFields,
          row,
          sessionId: sessionData.id,
          onDelete: deleteImportSessionRow(apiEndpoint),
          onDeleteSuccess,
          onEdit: onEditRow,
        }}
        table={table}
        tableName={tableName}
        onRowClick={r => onEditRow(r.id)}
      />
      {actions({
        onSubmit,
        submitPending,
        sessionData,
        disableActions: fieldValues.hasErrors,
        empty: !table.data.length,
      })}
      <BulkDataImportSessionSidebar
        key={sidebarRowData?.id}
        fields={fields}
        fieldValues={fieldValues}
        rowData={sidebarRowData}
        sessionId={sessionData.id}
        onClose={() => setSidebarRowData(undefined)}
        apiEndpoint={apiEndpoint}
        onEditImportSessionRow={editImportSessionRow}
        onTableRefresh={table.refresh}
        customFields={customFields?.results || []}
        customFieldDropdownOptionKey={customFieldDropdownOptionKey}
      />
    </>
  )
}

type BulkDataImportSessionProps<T> = Pick<
  UploadSessionTableProps<T>,
  | 'apiEndpoint'
  | 'row'
  | 'tableName'
  | 'fields'
  | 'actions'
  | 'customFieldDropdownOptionKey'
>

export const BulkDataImportSession = <T,>({
  row,
  apiEndpoint,
  tableName,
  fields,
  actions,
  customFieldDropdownOptionKey,
}: BulkDataImportSessionProps<T>) => {
  const params = useParams<{ id: string }>()

  const { data, refetch } = useGetImportSessionData(apiEndpoint, params.id)

  if (!data) {
    return <PageLoading />
  }

  if (data.state.id === 'pending' || data.state.id === 'processing_file') {
    return <ProcessingFileState />
  }

  return (
    <UploadSessionTable
      apiEndpoint={apiEndpoint}
      refetchSessionData={refetch}
      tableName={tableName}
      row={row}
      fields={fields}
      sessionData={data}
      actions={actions}
      disabled={
        data.state.id === 'success' ||
        data.state.id === 'failure' ||
        data.state.id === 'applying'
      }
      customFieldDropdownOptionKey={customFieldDropdownOptionKey}
    />
  )
}
