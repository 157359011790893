import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { KPI } from '@src/pages/Settings/KPI/KPI'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { performanceSettings } from '@src/api/performanceSettings'

const routes = [
  {
    title: 'KPI app settings',
    description: 'Setting for all KPIs in the system',
    path: ROUTES.SETTINGS.KPI,
    url: ROUTES.SETTINGS.KPI,
    canView: [
      PermissionTypes.ViewPerformancePreferences,
      PermissionTypes.ChangePerformancePreferences,
    ],
    component: KPI,
  },
]

export const KPISettings = () => {
  return (
    <SettingsForm
      routes={routes}
      api={performanceSettings}
      multiInputFields={['kpi_measuring_units']}
    />
  )
}
