import React from 'react'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { SurveyResultsPublished } from '@src/pages/Performance/Engagement/components/SurveyResultsPublished'
import { CompanyInterface } from '@src/interfaces/company'

const DEFAULT_COMPANY_ID = 1

export const Engagement = ({ data }: { data: CompanyInterface }) => {
  return (
    <SurveyResultsPublished
      entity={{
        type: EngagementResultsScope.Company,
        id: DEFAULT_COMPANY_ID,
        permissions: data.field_options.permissions,
      }}
    />
  )
}
