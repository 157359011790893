import React from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { PermissionTypes } from '@src/store/auth/types'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

export const CRMAction = () => {
  const permissions = useSelector(selectPermissions)
  const canView = permissions.includes(PermissionTypes.ViewInterviewRoundCRM)
  if (!canView) {
    return null
  }
  return (
    <HideIfCommercial>
      <MoreBar.Action
        useIcon="Database"
        use={InternalLink}
        to={pathToUrl(ROUTES.FORMS.CRM)}
      >
        CRM
      </MoreBar.Action>
    </HideIfCommercial>
  )
}
