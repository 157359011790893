import React from 'react'
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Text,
  textChain,
  Token,
  Widget,
} from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { Cross, Pencil } from '@revolut/icons'

type Props = {
  title?: string
  subtitle?: string
  startDate: string
  endDate?: string | null
  onRemove: () => void
  onEdit: () => void
}

const InformationSection = ({
  title,
  subtitle,
  startDate,
  endDate,
  onRemove,
  onEdit,
}: Props) => {
  return (
    <Widget bg={Token.color.greyTone2} p="s-16" data-testid={`information-${title}`}>
      <Flex justifyContent="space-between" alignItems="center">
        <Box>
          <Text variant="primary" display="block">
            {textChain(title, subtitle)}
          </Text>
          <Text variant="caption" color={Token.color.greyTone50}>
            {formatDate(startDate, 'MMM yyyy')} -{' '}
            {endDate ? formatDate(endDate, 'MMM yyyy') : 'Present'}
          </Text>
        </Box>
        <HStack gap="s-16">
          <IconButton
            useIcon={Pencil}
            color={Token.color.greyTone50}
            size={22}
            onClick={onEdit}
            aria-label="Edit"
          />
          <IconButton
            useIcon={Cross}
            color={Token.color.greyTone50}
            size={22}
            onClick={onRemove}
            aria-label="Remove"
          />
        </HStack>
      </Flex>
    </Widget>
  )
}

export default InformationSection
