import React from 'react'
import { Color, ErrorWidget, Flex, Text } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'

type Props = {
  noLink?: boolean
}

const Page404 = ({ noLink }: Props) => {
  return (
    <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
        <ErrorWidget.Title>
          Page not found
          <Text
            color={Color.GREY_TONE_50}
            variant="caption"
            use="p"
            textAlign="center"
            width="100%"
            mt="1em"
          >
            The URL may be misspelled or the page you're looking for is no longer
            available.
          </Text>
        </ErrorWidget.Title>
        {!noLink && (
          <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
            Go to Revolut People dashboard
          </ErrorWidget.Action>
        )}
      </ErrorWidget>
    </Flex>
  )
}

export default Page404
