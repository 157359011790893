export enum SystemHealthStatus {
  ok = 'ok',
  unhealthy = 'unhealthy',
  down = 'down',
  disabled = 'disabled',
}

export interface SystemHealthInterface {
  status: SystemHealthStatus
  response_time: string | null
}

export interface HealthInterface {
  status: 'string'
  version: 'string'
  db_health: SystemHealthInterface
  async_health: SystemHealthInterface
  caching_health: SystemHealthInterface
  releases: {
    version: string
    features: string[]
    fixes: string[]
  }[]
}
