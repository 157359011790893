import { FieldOptions, IdAndName, Statuses } from '@src/interfaces/index'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'
import { InterviewType } from '@src/interfaces/interviewTool'

export enum InterviewScorecardEnumSectionIds {
  Checkbox = 'checkbox',
  Option = 'option',
  Thumbs = 'thumbs',
  Text = 'text',
  Level = 'levels',
  Dropdown = 'dropdown',
}

export const EnumSectionOptions = [
  { id: InterviewScorecardEnumSectionIds.Checkbox, name: 'Checkbox' },
  { id: InterviewScorecardEnumSectionIds.Option, name: 'Option' },
  { id: InterviewScorecardEnumSectionIds.Dropdown, name: 'Dropdown' },
  { id: InterviewScorecardEnumSectionIds.Text, name: 'Text' },
  { id: InterviewScorecardEnumSectionIds.Thumbs, name: 'Thumbs' },
  { id: InterviewScorecardEnumSectionIds.Level, name: 'Level based' },
]

export type InterviewScorecardSectionQuestionType<T extends number | string> = {
  text: string
  points?: number | string
  id?: T
}

export type InterviewScorecardSectionOptionType = {
  id: InterviewScorecardEnumSectionIds
  name: string
}

export interface InterviewScorecardTemplateSectionInterface {
  title?: string
  id?: number
  subtitle?: string
  section_type?: InterviewScorecardSectionOptionType
  is_default?: boolean
  optional?: boolean
  placeholder_text?: string
  questions?: InterviewScorecardSectionQuestionType<number>[]
  basic_level_questions?: string[]
  intermediate_level_questions?: string[]
  advanced_level_questions?: string[]
  expert_level_questions?: string[]
  skills?: OptionInterface[]
}

export enum InterviewScorecardType {
  CV = 'cv_screening',
  General = 'general',
  Delivery = 'delivery',
  Skill = 'skill',
  Value = 'value',
  HiringPanel = 'hiring_panel',
}

export type InterviewScorecardTypeInterface = IdAndName<InterviewScorecardType>

export interface InterviewScorecardTemplateInterface {
  id: number
  name?: string
  seniority_min?: SeniorityInterface
  seniorities_points?: InterviewScorecardSectionQuestionType<number>[]
  seniority_max?: SeniorityInterface
  status?: Statuses
  interview_objective?: string
  owner?: EmployeeOptionInterface
  sections?: InterviewScorecardTemplateSectionInterface[]
  scorecard_type?: InterviewScorecardTypeInterface
  stage_type?: IdAndName<InterviewType>
  skills?: OptionInterface[]
  basic_points?: number
  intermediate_points?: number
  advanced_points?: number
  expert_points?: number
  field_options?: FieldOptions
}
