import React from 'react'
import { connect } from 'lape'
import { Route, Switch } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { manualScreeningRequests } from '@src/api/screening'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { ManualScreeningForm } from './General'
import { ManualScreeningPreview } from './Preview'

const ManualScreening = () => {
  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.SCREENING.MANUAL.PREVIEW}>
          <ManualScreeningPreview />
        </Route>
        <Route exact path={ROUTES.FORMS.SCREENING.MANUAL.GENERAL}>
          <ManualScreeningForm />
        </Route>
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={manualScreeningRequests} disableLocalStorageCaching>
    <ManualScreening />
  </Form>
))
