import { PermissionTypes } from '@src/store/auth/types'
import { ROUTES } from '@src/constants/routes'
import { SettingsLinkItemConfig } from '@src/pages/Settings/common/config'

type PerformanceSettingsItemId =
  | 'performanceGeneral'
  | 'performanceCycleDuration'
  | 'performanceReviewTypes'
  | 'performancePIP'
  | 'performanceProbation'

export const performanceSettingsLinkConfig: SettingsLinkItemConfig<PerformanceSettingsItemId>[] =
  [
    {
      id: 'performanceGeneral',
      section: 'Performance settings',
      icon: 'Gear',
      title: 'General settings',
      searchTitle: 'Performance general settings',
      to: ROUTES.PERFORMANCE.SETTINGS.GENERAL,
      permissions: [PermissionTypes.ViewPerformancePreferences],
    },
    {
      id: 'performanceCycleDuration',
      section: 'Performance settings',
      icon: 'Time',
      title: 'Cycle duration',
      searchTitle: 'Performance cycle duration',
      to: ROUTES.PERFORMANCE.SETTINGS.CYCLE,
      permissions: [PermissionTypes.ViewPerformancePreferences],
    },
    {
      id: 'performanceReviewTypes',
      section: 'Performance settings',
      icon: 'Switches',
      title: 'Review types',
      searchTitle: 'Performance review types',
      to: ROUTES.PERFORMANCE.SETTINGS.REVIEWS,
      permissions: [PermissionTypes.ViewPerformancePreferences],
    },
    {
      id: 'performancePIP',
      section: 'Performance settings',
      icon: 'RatesCircle',
      title: 'PIP settings',
      to: ROUTES.PERFORMANCE.SETTINGS.PIP,
      permissions: [PermissionTypes.ViewPerformancePreferences],
    },
    {
      id: 'performanceProbation',
      section: 'Performance settings',
      icon: '16/SandWatch',
      title: 'Probation settings',
      to: ROUTES.PERFORMANCE.SETTINGS.PROBATION,
      permissions: [PermissionTypes.ViewPerformancePreferences],
    },
  ]
