import React from 'react'
import { Text, List, MoreBar, Widget } from '@revolut/ui-kit'
import { Dot, LinkExternal } from '@revolut/icons'
import { REVIEWING_CULTURE_FIT } from '@src/constants/externalLinks'
import MoreInfoButton from '@components/MoreInfoButton/MoreInfoButton'

export const ManagementBehavioursHelp = () => {
  return (
    <>
      <MoreBar>
        <MoreInfoButton
          href={REVIEWING_CULTURE_FIT}
          useIcon={LinkExternal}
          useMoreBar={false}
        >
          View playbook
        </MoreInfoButton>
      </MoreBar>
      <Widget mt="s-24" p="s-16">
        <Text variant="primary" use="p">
          Management Behaviours
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          At Revolut, we have a steadfast commitment to recruiting top-tier professionals,
          and we hold our managers to the same exacting standards.
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          Our managers are entrusted with the crucial task of leading our people, and as
          such, we expect them to be fully dedicated to their managerial duties and
          responsibilities.
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          We hold our managers accountable for their actions, and they must consistently
          strive to improve by incorporating feedback and setting an example for the
          entire organisation.
        </Text>
        <Text mt="s-24" variant="primary" use="p">
          Assessment levels
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          We have established three levels of alignment with our philosophy of managerial
          excellence:
        </Text>
        <List variant="compact">
          <List.Item>
            1. <Text fontWeight="bold">Improvement focus:</Text> The primary area of
            development for your manager.
          </List.Item>
          <List.Item>
            2. <Text fontWeight="bold">Performing:</Text> This signifies that your manager
            consistently meets the expectations of their managerial duties and
            responsibilities.
          </List.Item>
          <List.Item>
            3. <Text fontWeight="bold">Superpower:</Text> Indicates your manager
            consistently exceeds expectations, setting an exemplary standard for their
            peers within the organisation.
          </List.Item>
        </List>
        <Text mt="s-24" variant="primary" use="p">
          Requirements
        </Text>
        <Text mt="s-8" variant="caption" use="p">
          To promote a culture of continuous improvement, we encourage all reviewers to
          choose:
        </Text>
        <List variant="compact">
          <List.Item useIcon={<Dot size={16} />}>
            <Text fontWeight="bold">up to three behaviours</Text> as “Superpowers”. We ask
            you to recognise and celebrate your manager who may be exhibiting exceptional
            alignment in their duties and responsibilities and sets standards for others
            to follow.
          </List.Item>
        </List>
      </Widget>
    </>
  )
}
