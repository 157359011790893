import React, { useEffect, useState } from 'react'
import { Cell, Flex, Input, MultiSelectInput, VStack } from '@revolut/ui-kit'

import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  WorkScheduleBasicStepInterface,
  WorkScheduleWorkingDayInterface,
} from '@src/interfaces/workSchedule'
import { daysOptions } from './constants'
import {
  DayOption,
  isEmptyWorkDaysError,
  parseWorkDaySettingsErrors,
  WorkDaySettingsErrors,
} from './common'

const mapDayOptionToWorkDay = (
  dayOption: DayOption,
  timeValues: {
    timeStart?: string
    timeEnd?: string
    breakStart?: string
    breakEnd?: string
  },
): WorkScheduleWorkingDayInterface | null => {
  return {
    day: dayOption.id,
    work_start_time: timeValues.timeStart || null,
    work_end_time: timeValues.timeEnd || null,
    break_start_time: timeValues.breakStart || null,
    break_end_time: timeValues.breakEnd || null,
  }
}

type Props = {
  selectedDays: DayOption[]
  updateSelectedDays: (days: DayOption[]) => void
  errors: Array<string | WorkDaySettingsErrors>
  clearErrors: () => void
}
export const DaysMultiSelectWidget = ({
  selectedDays,
  updateSelectedDays,
  errors,
  clearErrors,
}: Props) => {
  const { values } = useLapeContext<WorkScheduleBasicStepInterface>()
  const workingDay = values.working_days?.[0]

  const [timeStart, setTimeStart] = useState<string>(workingDay?.work_start_time || '')
  const [timeEnd, setTimeEnd] = useState<string>(workingDay?.work_end_time || '')
  const [breakStart, setBreakStart] = useState<string>(workingDay?.break_start_time || '')
  const [breakEnd, setBreakEnd] = useState<string>(workingDay?.break_end_time || '')

  useEffect(() => {
    const selectedDaysFormatted = selectedDays
      ?.map(selectedDay =>
        mapDayOptionToWorkDay(selectedDay, {
          timeStart,
          timeEnd,
          breakStart,
          breakEnd,
        }),
      )
      .filter(Boolean)

    values.working_days = selectedDaysFormatted.length ? selectedDaysFormatted : null
  }, [timeStart, timeEnd, breakStart, breakEnd, selectedDays])

  const daysMultiselectOptions = daysOptions.map(day => ({
    key: day.id,
    label: day.name,
    value: day as DayOption,
  }))

  const parsedErrors = parseWorkDaySettingsErrors(errors[0])

  return (
    <Cell>
      <VStack space="s-16" width="100%">
        <MultiSelectInput
          label="Select work days"
          labelList="Work days"
          value={selectedDays}
          onChange={newValue => {
            clearErrors()
            updateSelectedDays(newValue)
          }}
          options={daysMultiselectOptions}
          aria-invalid={isEmptyWorkDaysError(errors)}
          errorMessage={
            isEmptyWorkDaysError(errors) ? 'Please, select work days' : undefined
          }
        />
        <Flex gap="s-8">
          <Input
            type="time"
            label="Start"
            value={timeStart}
            onChange={e => {
              clearErrors()
              setTimeStart(e.currentTarget.value)
            }}
            aria-invalid={!!parsedErrors.workStartTime}
            errorMessage={parsedErrors.workStartTime}
          />
          <Input
            type="time"
            label="End"
            value={timeEnd}
            onChange={e => {
              clearErrors()
              setTimeEnd(e.currentTarget.value)
            }}
            aria-invalid={!!parsedErrors.workEndTime}
            errorMessage={parsedErrors.workEndTime}
          />
          <Input
            type="time"
            label="Break start"
            value={breakStart}
            onChange={e => {
              clearErrors()
              setBreakStart(e.currentTarget.value)
            }}
            aria-invalid={!!parsedErrors.breakStartTime}
            errorMessage={parsedErrors.breakStartTime}
          />
          <Input
            type="time"
            label="Break end"
            value={breakEnd}
            onChange={e => {
              clearErrors()
              setBreakEnd(e.currentTarget.value)
            }}
            aria-invalid={!!parsedErrors.breakEndTime}
            errorMessage={parsedErrors.breakEndTime}
          />
        </Flex>
      </VStack>
    </Cell>
  )
}
