import React from 'react'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import DeliverablesForm, { DeliverablesProps } from './Deliverables'

const Deliverables = (props: DeliverablesProps) => {
  return (
    <Form validator={{}}>
      <DeliverablesForm {...props} />
    </Form>
  )
}

export default connect(Deliverables)
