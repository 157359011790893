import React from 'react'
import AddBubbles from '@src/assets/3D/AddBubbles@2x.png'
import { ErrorWidget } from '@revolut/ui-kit'

type Props = {
  filtersApplied?: boolean
}

const NoReviewsWidget = ({ filtersApplied }: Props) => {
  return (
    <ErrorWidget>
      <ErrorWidget.Image src={AddBubbles} />
      <ErrorWidget.Title>
        {filtersApplied ? 'No reviews for the selected filters' : 'No reviews added'}
      </ErrorWidget.Title>
    </ErrorWidget>
  )
}

export default NoReviewsWidget
