import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Avatar,
  Bar,
  Button,
  Cell,
  Checkbox,
  FilterButton,
  FilterButtonSkeleton,
  Flex,
  Group,
  Item,
  ItemSkeleton,
  Search,
  Side,
  Text,
  useIntersectViewport,
  VStack,
} from '@revolut/ui-kit'
import FilterButtonCheckboxSelect from '@components/FilterButtonCheckboxSelect/FilterButtonCheckboxSelect'
import pluralize from 'pluralize'
import { People } from '@revolut/icons'
import debounce from 'lodash/debounce'
import { filterSortPageIntoQuery } from '@src/utils/table'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { IdAndName } from '@src/interfaces'
import { selectorKeys } from '@src/constants/api'
import { OptionInterface } from '@src/interfaces/selectors'
import { getCommunicationGroups } from '@src/api/communicationGroups'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'

const MAX_FILTERS_SHOWN = 3
const DEFAULT_PAGE_SIZE = 25

type GroupType = IdAndName<React.Key, React.ReactNode>

const toggleArrayItemByKey = <T,>(items: T[], toggleItem: T, key: keyof T) => {
  if (items?.some(item => item[key] === toggleItem[key])) {
    return items.filter(item => toggleItem[key] !== item[key])
  }
  return [...items, toggleItem]
}

const mapFiltersToParams = (search: string, filters: GroupType[]) =>
  filterSortPageIntoQuery(undefined, [
    {
      columnName: 'search',
      filters: [{ id: search, name: search }],
    },
    {
      columnName: 'audience_type',
      filters: filters.map(({ id }) => ({ id, name: String(id) })),
    },
    {
      columnName: 'page_size',
      filters: [{ id: DEFAULT_PAGE_SIZE, name: String(DEFAULT_PAGE_SIZE) }],
    },
  ])

type Props = {
  groups: CommunicationGroupInterface[]
  onAddGroups: (groups: CommunicationGroupInterface[]) => void
  onClose: () => void
}
export const SidebarContent = ({
  groups: selectedGroups,
  onAddGroups,
  onClose,
}: Props) => {
  const [groupsOptions, setGroupsOptions] = useState<CommunicationGroupInterface[]>([])

  const [selectedGroupsOptions, setSelectedGroupsOptions] = useState<
    CommunicationGroupInterface[]
  >([])

  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isReloadingFilters, setIsReloadingFilters] = useState(false)
  const [page, setPage] = useState<number>(1)
  const [hasMoreData, setHasMoreData] = useState<boolean>(false)
  const ref = useRef(null)

  useIntersectViewport(ref, isIntersecting => {
    if (isReloadingFilters) {
      return
    }
    if (hasMoreData && isIntersecting) {
      setPage(page + 1)
    }
  })

  const [searchValue, setSearchValue] = useState('')
  const [textFilter, setTextFilter] = useState('')
  const [groupTypesFilters, setGroupTypesFilters] = useState<GroupType[]>([])
  const [moreGroupTypesFilters, setMoreGroupTypesFilters] = useState<GroupType[]>([])

  const setTextFilterDebounced = useCallback(debounce(setTextFilter, 500), [])

  const filterParams = useMemo(() => {
    return mapFiltersToParams(textFilter, [
      ...groupTypesFilters,
      ...moreGroupTypesFilters,
    ])
  }, [textFilter, groupTypesFilters, moreGroupTypesFilters])

  const { options, asyncState } = useFetchOptions<GroupType>(
    selectorKeys.communication_group_audience_types,
  )
  const groupTypes = options.map(({ value, label }) => ({
    id: value.id,
    name: label,
  }))
  const moreFiltersButtons = groupTypes.slice(MAX_FILTERS_SHOWN, groupTypes.length)

  const filteredGroupsOptions = useMemo(
    () =>
      groupsOptions.filter(
        group => !selectedGroups?.some(selected => selected.id === group.id),
      ),
    [groupsOptions],
  )

  const loadGroups = (newPage = 1) => {
    setPage(newPage)

    return getCommunicationGroups({ ...filterParams, page: newPage }).then(({ data }) => {
      const { results, pages } = data

      setHasMoreData(!!pages.next)

      return results
    })
  }

  useEffect(() => {
    if (isReloadingFilters || page === 1) {
      return
    }
    setIsLoadingMore(true)

    loadGroups(page)
      .then(newGroups => {
        setGroupsOptions([...groupsOptions, ...newGroups])
      })
      .finally(() => {
        setIsLoadingMore(false)
      })
  }, [page])

  useEffect(() => {
    if (isLoadingMore) {
      return
    }
    setIsReloadingFilters(true)

    loadGroups()
      .then(filteredGroups => {
        setGroupsOptions(filteredGroups)
      })
      .finally(() => {
        setIsReloadingFilters(false)
      })
  }, [filterParams])

  return (
    <Flex style={{ flex: '1 0' }} flexDirection="column" justifyContent="space-between">
      <VStack space="s-16">
        <Search
          value={searchValue}
          placeholder="Search"
          onChange={newValue => {
            setSearchValue(newValue)
            setTextFilterDebounced(newValue)
            setSelectedGroupsOptions([])
          }}
        />
        {asyncState === 'pending' ? (
          <Bar>
            <FilterButtonSkeleton />
            <FilterButtonSkeleton />
            <FilterButtonSkeleton />
          </Bar>
        ) : (
          <Bar>
            {groupTypes.slice(0, MAX_FILTERS_SHOWN).map(filter => (
              <FilterButton
                key={filter.id}
                onClick={() => {
                  setGroupTypesFilters(
                    toggleArrayItemByKey<GroupType>(groupTypesFilters, filter, 'id'),
                  )
                  setSelectedGroupsOptions([])
                }}
                active={groupTypesFilters.some(
                  selectedFilter => selectedFilter.id === filter.id,
                )}
              >
                {filter.name}
              </FilterButton>
            ))}
            {groupTypes.length > MAX_FILTERS_SHOWN && (
              <FilterButtonCheckboxSelect
                options={moreFiltersButtons as OptionInterface[]}
                label="More filters"
                value={
                  moreFiltersButtons.filter(filterButton =>
                    moreGroupTypesFilters.some(({ id }) => id === filterButton.id),
                  ) as OptionInterface[]
                }
                onChange={value => {
                  const formattedValue = value
                    ? value.map(({ id, name }) => ({ id: String(id), name }))
                    : []
                  setMoreGroupTypesFilters(formattedValue)
                  setSelectedGroupsOptions([])
                }}
              />
            )}
          </Bar>
        )}
        {
          <Group>
            <Cell>
              <Checkbox
                onChange={value => {
                  if (value.target.checked) {
                    setSelectedGroupsOptions(filteredGroupsOptions || [])
                  } else {
                    setSelectedGroupsOptions([])
                  }
                }}
              >
                <Text variant="secondary">Select all</Text>
              </Checkbox>
            </Cell>
            {isReloadingFilters ? (
              <>
                <ItemSkeleton />
                <ItemSkeleton />
                <ItemSkeleton />
              </>
            ) : (
              filteredGroupsOptions.map(groupOption => {
                const title = groupOption.name
                const description = `${pluralize(
                  'employee',
                  groupOption.members_count,
                  true,
                )}`

                return (
                  <Item key={groupOption.id} use="label">
                    <Item.Prefix>
                      <Checkbox
                        aria-labelledby={title}
                        aria-describedby={description}
                        checked={selectedGroupsOptions.some(
                          ({ id }) => id === groupOption.id,
                        )}
                        onClick={() =>
                          setSelectedGroupsOptions(
                            toggleArrayItemByKey<CommunicationGroupInterface>(
                              selectedGroupsOptions,
                              groupOption,
                              'id',
                            ),
                          )
                        }
                      />
                    </Item.Prefix>
                    <Item.Avatar>
                      <Avatar useIcon={People} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title id="item-title">{title}</Item.Title>
                      <Item.Description id="item-description">
                        {description}
                      </Item.Description>
                    </Item.Content>
                  </Item>
                )
              })
            )}
            <VStack ref={ref} space="s-12">
              {isLoadingMore && hasMoreData && (
                <>
                  <ItemSkeleton />
                  <ItemSkeleton />
                  <ItemSkeleton />
                </>
              )}
            </VStack>
          </Group>
        }
      </VStack>
      {!!selectedGroupsOptions.length && (
        <Side.Actions>
          <Button
            elevated
            onClick={() => {
              onAddGroups(selectedGroupsOptions)
              onClose()
            }}
          >
            Add selected
          </Button>
        </Side.Actions>
      )}
    </Flex>
  )
}
