import React from 'react'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import SettingsButtons from '@src/features/SettingsButtons'
import { Group, MoreBar } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Pencil } from '@revolut/icons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DataRetentionSettingInterface } from '@src/interfaces/dataRetentionSettings'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { FormPreview } from '@components/FormPreview/FormPreview'

const Preview = () => {
  const { values } = useLapeContext<DataRetentionSettingInterface>()

  const backUrl = ROUTES.SETTINGS.CANDIDATES.DATA_RETENTION_SETTINGS
  const permissions = useSelector(selectPermissions)
  const canEdit =
    !!values.id && permissions.includes(PermissionTypes.ChangeDataRetentionPeriod)

  return (
    <>
      <PageHeader pb="s-8" title={values.country.name} backUrl={backUrl}>
        {canEdit ? (
          <SettingsButtons>
            <MoreBar.Action
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.GENERAL, {
                    id: values.id,
                  }),
                )
              }
              useIcon={Pencil}
            >
              Edit
            </MoreBar.Action>
          </SettingsButtons>
        ) : null}
      </PageHeader>
      <PageBody>
        <FormPreview title="Unsuccessful candidates" data={values}>
          <Group>
            <FormPreview.Item
              title="Triggering event"
              insert={() => 'Date of decision'}
            />
            <FormPreview.Item<DataRetentionSettingInterface>
              title="Retention period"
              insert={data =>
                `${data.not_hired_candidate_retention_duration} ${data.not_hired_candidate_retention_duration_unit}`
              }
            />
          </Group>
        </FormPreview>
        <FormPreview title="Successful candidates" data={values}>
          <Group>
            <FormPreview.Item
              title="Triggering event"
              insert={() => 'End of employment'}
            />
            <FormPreview.Item<DataRetentionSettingInterface>
              title="Retention period"
              insert={data =>
                `${data.hired_candidate_retention_duration} ${data.hired_candidate_retention_duration_unit}`
              }
            />
          </Group>
        </FormPreview>
      </PageBody>
    </>
  )
}

export default Preview
