import React, { useState } from 'react'
import {
  BottomSheet,
  Button,
  Header,
  InputGroup,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { createEmployeeTmpPolicyAssignment } from '@src/api/timeOff'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { getStringMessageFromError } from '@src/store/notifications/actions'

interface AssignPolicyPopupProps {
  open: boolean
  onClose: () => void
  onSuccess: () => void
}

export const AssignPolicyPopup = ({
  open,
  onClose,
  onSuccess,
}: AssignPolicyPopupProps) => {
  const statusPopup = useStatusPopup()
  const [employeeValue, setEmployeeValue] = useState<IdAndName | null>(null)
  const [policyValue, setPolicyValue] = useState<IdAndName | null>(null)
  const [pending, setPending] = useState(false)

  const onSubmit = () => {
    if (!employeeValue || !policyValue) {
      return
    }
    setPending(true)

    createEmployeeTmpPolicyAssignment(employeeValue.id, policyValue.id)
      .then(() => {
        setPending(false)
        setEmployeeValue(null)
        setPolicyValue(null)
        onSuccess()
      })
      .catch(error => {
        setPending(false)
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to assign</StatusPopup.Title>
            <StatusPopup.Description>
              {getStringMessageFromError(error)}
            </StatusPopup.Description>
          </StatusPopup>,
        )
      })
  }

  return (
    <BottomSheet open={open} onClose={onClose}>
      <Header>
        <Header.Title>Assign policy</Header.Title>
      </Header>
      <InputGroup>
        <RadioSelectInput
          label="Policy"
          onChange={option => {
            setPolicyValue(option)
          }}
          selector={selectorKeys.active_time_off_policies}
          value={policyValue}
        />
        <RadioSelectInput
          label="Employee"
          onChange={option => {
            setEmployeeValue(option)
          }}
          selector={selectorKeys.employee}
          value={employeeValue}
        />
      </InputGroup>
      <BottomSheet.Actions>
        <Button
          disabled={!employeeValue || !policyValue}
          elevated
          onClick={onSubmit}
          pending={pending}
        >
          Assign policy
        </Button>
      </BottomSheet.Actions>
    </BottomSheet>
  )
}
