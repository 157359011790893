import { CommonGenericEditableTableRowOptions } from '@src/features/GenericEditableTable/GenericEditableTable'
import { RowInterface } from '@src/interfaces/data'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { EmployeesSimpleInterface } from '@src/interfaces/employees'
import {
  employeesEmailColumn,
  employeesEntityColumn,
  employeesFirstNameColumn,
  employeesLastNameColumn,
  employeesLineManagerColumn,
  employeesLocationColumn,
  employeesNameColumn,
  employeesRoleColumn,
  employeesSeniorityColumn,
  employeesStatusColumn,
  employeesTeamColumn,
} from '@src/constants/columns/employeesV2'
import { EditableTableRenderMode } from '@src/features/GenericEditableTable/components'
import {
  baseBulkEditFieldsDependencies,
  baseFieldsForBulkEdit,
  CreateCallback,
} from '../common'

export const getRow =
  (showEmployeeNameColumn?: boolean) =>
  (onCreate: CreateCallback, mode: EditableTableRenderMode) =>
  (
    options: CommonGenericEditableTableRowOptions,
  ): RowInterface<ImportInterface<EmployeesSimpleInterface>> => ({
    cells: [
      mode !== 'bulkSession' &&
        showEmployeeNameColumn && {
          ...employeesNameColumn(options.onChange),
          width: 200,
        },
      ...(mode !== 'preview'
        ? [
            {
              ...employeesEmailColumn(options.onChange),
              width: 200,
            },
            {
              ...employeesFirstNameColumn(options.onChange),
              width: 120,
            },
            {
              ...employeesLastNameColumn(options.onChange),
              width: 120,
            },
          ]
        : []),
      {
        ...employeesTeamColumn(
          options.onChange,
          onChangeAction => onCreate('team', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesRoleColumn(
          options.onChange,
          onChangeAction => onCreate('role', onChangeAction),
          mode,
        ),
        width: 150,
      },
      {
        ...employeesSeniorityColumn(options.onChange, mode),
        width: 150,
      },
      {
        ...employeesLocationColumn(
          options.onChange,
          onChangeAction => onCreate('location', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesEntityColumn(
          options.onChange,
          onChangeAction => onCreate('companyEntity', onChangeAction),
          mode,
        ),
        width: 200,
      },
      {
        ...employeesLineManagerColumn(options.onChange, mode),
        width: 200,
      },
      mode === 'preview' && {
        ...employeesStatusColumn(options.onChange, mode),
        width: 200,
      },
    ].filter(Boolean),
  })

export const mandatoryBulkSessionFields = ['email', 'first_name', 'last_name']
export const fieldsForBulkEdit = [...baseFieldsForBulkEdit, 'line_manager', 'location']
export type FieldsForBulkEdit = typeof fieldsForBulkEdit[number]

export const bulkFieldsDependencies = {
  ...baseBulkEditFieldsDependencies,
  location: ['location', 'entity'],
  entity: ['location', 'entity'],
  line_manager: ['line_manager'],
}
