import { CSSObject } from 'styled-components'

export const editorCss: CSSObject = {
  fontSize: '16px',
  p: {
    margin: '0px',
  },
  ol: {
    padding: '0 0 0 40px',
  },
  ul: {
    padding: '0 0 0 40px',
  },
}
