import React from 'react'
import { HStack, Text, TextWidget, Token } from '@revolut/ui-kit'
import NewRadioButtons, {
  RadioOption,
} from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import {
  CycleMode,
  makeCycleModeOptions,
} from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/EditTimeline/CycleMode/CycleModeOptions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCyclesInterface, ReviewCycleStage } from '@src/interfaces/reviewCycles'
import { cycleModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/CycleModel'
import NewWarningMessage, {
  NewWarningTypes,
} from '@components/NewWarningMessage/NewWarningMessage'
import { timelineModel } from '@src/pages/Forms/ReviewCycle/ReviewCycle/models/TimelineModel'
import { stageToLabelMap } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CycleStages/constants'
import { OptionInterface } from '@src/interfaces/selectors'
import { FormattedMessage } from 'react-intl'

interface Props {
  stages: ReviewCycleStage[]
}

export const CycleModeSwitcher = ({ stages }: Props) => {
  const { values, dirty } = useLapeContext<ReviewCyclesInterface>()
  const options = makeCycleModeOptions(values)

  const selectedOption = options.find(
    ({ value: { id } }) =>
      id === (cycleModel.isManual(values) ? CycleMode.Manual : CycleMode.Scheduled),
  )

  const isManualModeWarningVisible = dirty && cycleModel.isManual(values)
  const currentStageByDates = timelineModel.getCurrentStageByDates(values, stages)

  const handleChange = ({ value: { id } }: RadioOption<OptionInterface<CycleMode>>) => {
    if (id === CycleMode.Manual) {
      values.current_stage = currentStageByDates
      values.is_manual_stage_switch_enabled = true
    } else {
      values.current_stage = null
      values.is_manual_stage_switch_enabled = false
    }
  }

  return (
    <TextWidget>
      <TextWidget.Title>How to process cycle stages?</TextWidget.Title>
      <HStack pt="s-16" space="s-72">
        <NewRadioButtons
          options={options}
          value={selectedOption?.value}
          onChange={handleChange}
        />
      </HStack>
      {isManualModeWarningVisible && (
        <NewWarningMessage
          type={NewWarningTypes.warning}
          mt="s-16"
          style={{ backgroundColor: Token.color.orange_10 }}
        >
          <Text color={Token.color.warning}>
            <FormattedMessage
              id="performance.reviews.cycle.manual_mode.warning"
              values={{ stage: stageToLabelMap[currentStageByDates] }}
              defaultMessage='Based on cycle timeline current stage for manual cycle will be set to "{stage}"'
            />
          </Text>
        </NewWarningMessage>
      )}
    </TextWidget>
  )
}
