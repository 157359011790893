import React from 'react'
import { chain, Color, Text, Token } from '@revolut/ui-kit'

import {
  WorkScheduleCustomApprovalFlowInterface,
  WorkScheduleStatusId,
} from '@src/interfaces/workSchedule'

const statusTitleToColor: Record<WorkScheduleStatusId, Color> = {
  active: Token.color.green,
  draft: Token.color.greyTone50,
  archived: Token.color.greyTone50,
}

type Props = {
  data: Partial<WorkScheduleCustomApprovalFlowInterface>
}
export const TitleWithStatus = ({ data }: Props) => {
  return (
    <>
      {chain(
        data.name,
        <Text color={data.status ? statusTitleToColor[data.status.id] : undefined}>
          {data.status?.name}
        </Text>,
      )}
    </>
  )
}
