import React, { useState } from 'react'
import { isArray } from 'lodash'
import { Cell, Flex, Text, Token, VStack } from '@revolut/ui-kit'

import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import {
  attachFileToTemplate,
  documentsTemplateFormRequests,
} from '@src/api/documentsTemplates'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DocumentsTemplatesInterface } from '@src/interfaces/documentsTemplates'

type Props = {
  onAfterUploaded?: () => void
}
export const UploadTemplateFile = ({ onAfterUploaded }: Props) => {
  const { values, reset } = useLapeContext<DocumentsTemplatesInterface>()

  const [importPending, setImportPending] = useState(false)
  const [error, setError] = useState<string>()

  const onFileUpload = (file: File) => {
    setImportPending(true)
    attachFileToTemplate(values.id, file)
      .then(() => documentsTemplateFormRequests.get({ id: String(values.id) }))
      .then(res => {
        reset(res.data)
        onAfterUploaded?.()
      })
      .catch(err => {
        const errorMessages =
          Array.isArray(err?.response?.data) &&
          err.response.data.every((d: unknown) => typeof d === 'string')
            ? err.response.data
            : typeof err?.response?.data === 'string'
            ? [err.response.data]
            : Array.isArray(err?.response?.data?.file) &&
              err.response.data.file.every((d: unknown) => typeof d === 'string')
            ? err.response.data.file
            : typeof err?.response?.data?.file === 'string'
            ? [err.response.data.file]
            : undefined
        if (errorMessages) {
          setError(errorMessages.join('. '))
        } else {
          setError('Something went wrong, please try again later')
        }
      })
      .finally(() => {
        setImportPending(false)
      })
  }

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <FileUploader
          attachButtonText="click to upload file"
          loading={importPending}
          onChange={file => {
            if (!file) {
              return
            }
            onFileUpload(isArray(file) ? file[0] : file)
          }}
          hideOptional
          name="file"
          error={error}
          showDropAreaImage
          noDropAreaHighlight
          uploadInstructions={
            <VStack align="center" mt="s-4">
              <Text variant="small" color={Token.color.greyTone50}>
                Allowed file-type: .pdf
              </Text>
              <Text variant="small" color={Token.color.greyTone50}>
                Max size 20MB
              </Text>
            </VStack>
          }
        />
      </Flex>
    </Cell>
  )
}
