import React from 'react'
import { Flex, Ellipsis, Token, Icon } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'

export interface WithErrorProps {
  error: string | string[]
  children: React.ReactNode
}

const WithError = ({ error, children }: WithErrorProps) => {
  const errorText = typeof error === 'string' ? error : error.join('\n')

  return (
    <Flex justifyContent="space-between">
      <Ellipsis>{children}</Ellipsis>
      <Flex flex="0 0 20px" justifyContent="flex-end">
        <Tooltip placement="top" text={errorText}>
          <Icon name="16/Warning" size={14} color={Token.color.red} />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default WithError
