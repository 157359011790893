import { Token } from '@revolut/ui-kit'
import { InterviewRoundSummaryRatingInterface } from '@src/interfaces/interviewTool'

export const getGradeColor = (grade?: string) => {
  switch (convertOperators(grade, '', '')) {
    case 'unsatisfactory':
    case 'poor':
      return Token.color.warning
    case 'developing':
    case 'basic':
      return Token.color.greyTone50
    case 'performing':
    case 'intermediate':
      return Token.color.success
    case 'exceeding':
    case 'advanced':
      return Token.color.cyan
    case 'exceptional':
    case 'expert':
      return Token.color.purple
    default:
      return Token.color.neutral
  }
}

export const convertOperators = (str?: string | null, minus = '-', plus = '+') =>
  str ? str.replace('_minus', minus).replace('_plus', plus) : ''

export const removeStageTypes = (stageType: string) => {
  return !['cv_screening', 'screen_call', 'prep_call'].includes(stageType)
}

export const getOnlineTestFromSummary = (data: InterviewRoundSummaryRatingInterface) =>
  data?.interviewer_ratings.find(({ interview_types }) =>
    interview_types?.includes('online_test'),
  )
