import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { AffectedCandidatesInterface } from '@src/interfaces/jobPostings/automation'
import { selectorKeys } from '@src/constants/api'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const affectedCandidatesNameColumn: ColumnInterface<AffectedCandidatesInterface> =
  {
    type: CellTypes.text,
    idPoint: 'full_name',
    dataPoint: 'full_name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    dynamicHyperlinks: data => pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, { id: data.id }),
    title: 'Candidate name',
  }

export const affectedCandidatesDateColumn: ColumnInterface<AffectedCandidatesInterface> =
  {
    type: CellTypes.date,
    idPoint: 'ran_on_data_time',
    dataPoint: 'ran_on_data_time',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Ran on',
  }
