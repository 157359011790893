import React from 'react'
import {
  Avatar,
  DetailsCell,
  Token,
  Text,
  Group,
  VStack,
  HStack,
  Icon,
} from '@revolut/ui-kit'

import { SubscriptionInvoiceInterface } from '@src/interfaces/plans'
import { formatMoney } from '@src/utils/format'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { invoiceStatusColor } from '@src/constants/columns/plans'

interface CardItemAvatarProps {
  variant: 'default' | 'error' | 'warning'
  type: 'card' | 'revolut-pay' | 'customPlan'
}

export const CardItemAvatar = ({ variant, type = 'card' }: CardItemAvatarProps) => {
  if (variant === 'error') {
    return (
      <Avatar
        useIcon={type === 'card' ? 'Card' : 'LogoRevolut'}
        progress={1}
        color={Token.color.danger}
        progressColor={Token.color.danger}
      >
        <Avatar.Badge
          useIcon="16/ExclamationMarkSign"
          backgroundColor={Token.color.danger}
        />
      </Avatar>
    )
  }

  if (variant === 'warning') {
    return (
      <Avatar
        useIcon={type === 'card' ? 'Card' : 'LogoRevolut'}
        progress={1}
        color={Token.color.warning}
        progressColor={Token.color.warning}
      >
        <Avatar.Badge useIcon="16/SandWatch" backgroundColor={Token.color.warning} />
      </Avatar>
    )
  }

  return <Avatar useIcon={type === 'customPlan' ? 'StarFilled' : 'Card'} />
}

export const NO_PAYMENT_METHOD_MESSAGE = 'Payment method not selected'

export const FAILED_PAYMENT_MESSAGE = 'Payment was declined'

interface InvoiceTotalProps {
  invoice: SubscriptionInvoiceInterface
}

export const InvoiceTotal = ({ invoice }: InvoiceTotalProps) => {
  const originalTotalPrice = formatMoney(Number(invoice.original_total), invoice.currency)

  return (
    <DetailsCell>
      <DetailsCell.Title alignSelf="center">Total</DetailsCell.Title>
      <DetailsCell.Content>
        <Text variant="h5">
          {invoice.total !== invoice.original_total ? (
            <>
              <s
                style={{
                  color: Token.color.greyTone50,
                  fontSize: Token.fontSize.secondary,
                }}
              >
                {originalTotalPrice}
              </s>{' '}
              {formatMoney(Number(invoice.total), invoice.currency)}
            </>
          ) : (
            originalTotalPrice
          )}
        </Text>
      </DetailsCell.Content>
    </DetailsCell>
  )
}

interface InvoiceEmployeesPreviewProps {
  invoice: SubscriptionInvoiceInterface
}

export const InvoiceEmployeesPreview = ({ invoice }: InvoiceEmployeesPreviewProps) => {
  return (
    <FormPreview data={invoice}>
      <Group>
        <FormPreview.Item
          title="Active employees"
          insert={() => (
            <VStack>
              <Text>{invoice.active_count}</Text>
              <Text color={Token.color.greyTone50}>
                {formatMoney(Number(invoice.active_total), invoice.currency)}
              </Text>
            </VStack>
          )}
        />
        {invoice.new_active_total && invoice.new_active_count ? (
          <FormPreview.Item
            title="New active employees"
            insert={() => (
              <VStack>
                <Text>{invoice.new_active_count}</Text>
                <Text color={Token.color.greyTone50}>
                  {formatMoney(Number(invoice.new_active_total), invoice.currency)}
                </Text>
              </VStack>
            )}
          />
        ) : null}
      </Group>
    </FormPreview>
  )
}

interface InvoiceSummaryProps {
  invoice: SubscriptionInvoiceInterface
}

export const InvoiceSummary = ({ invoice }: InvoiceSummaryProps) => {
  return (
    <FormPreview data={invoice}>
      <Group>
        <FormPreview.Item title="Date" type="date" field="creation_date_time" />
        <FormPreview.Item
          title="Status"
          field="status.name"
          color={() => invoiceStatusColor(invoice.status)}
        />
        <FormPreview.Item title="Order number" field="order_number" />
        <FormPreview.Item
          title="Payment method"
          insert={() =>
            invoice.payment_type ? (
              <HStack space="s-8" align="center">
                {invoice.payment_type.id === 'CARD' ? (
                  <Icon name="Card" />
                ) : (
                  <Icon name="LogoRevolut" />
                )}
                <Text>
                  {invoice.payment_type.id === 'CARD'
                    ? `···· ${invoice.last4}`
                    : 'Revolut Pay'}
                </Text>
              </HStack>
            ) : (
              '-'
            )
          }
        />
        <FormPreview.Item title="Retailer" field="retailer" />
        <FormPreview.Item title="Address" field="address" />
        <FormPreview.Item title="Product" field="product" />
        <FormPreview.Item
          title="Settlement reference"
          field="settlement_reference"
          hidden={!invoice.settlement_reference}
        />
      </Group>
    </FormPreview>
  )
}
