import React from 'react'
import { css } from 'styled-components'
import { Flex, Token } from '@revolut/ui-kit'

export const WrapperCss = css`
  margin: 0 auto;
  max-width: 1000px;
  padding: ${Token.size.s16};

  @media screen and ${Token.media.lg} {
    padding: unset;
  }
`

export const PageContentWrapper: React.FC = ({ children }) => (
  <Flex css={WrapperCss} flexDirection="column" flex="1" width="100%">
    {children}
  </Flex>
)
