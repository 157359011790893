import React from 'react'
import { Color, Dropdown, MoreBar } from '@revolut/ui-kit'
import { Envelope } from '@revolut/icons'

import { EmployeeButtonProps } from '../types'

export const Email = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  if (!data.id || !data.email) {
    return null
  }
  if (isDropdownItem) {
    return (
      <Dropdown.Item
        use="a"
        useIcon={<Envelope color={Color.BLUE} />}
        href={`mailto:${data.email}`}
        target="_blank"
        color={Color.FOREGROUND}
      >
        Email
      </Dropdown.Item>
    )
  }
  return (
    <MoreBar.Action
      useIcon={Envelope}
      use="a"
      href={`mailto:${data.email}`}
      target="_blank"
    >
      Send email
    </MoreBar.Action>
  )
}
