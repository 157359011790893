import React from 'react'
import {
  CandidateSidebarTypes,
  CandidateInterface,
  InterviewRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { Box, Group, Item, Icon, Token, ItemSkeleton, Avatar } from '@revolut/ui-kit'
import { useGetCV } from '@src/api/recruitment/interviews'
import EngagementInfo from '@src/features/InterviewTool/EngagementInfo'
import WorkExperienceInfo from '@src/features/InterviewTool/WorkExperienceInfo'
import EducationInfo from '@src/features/InterviewTool/EducationInfo'
import { getLinkedinUrl } from '@src/utils/hiring'
import CandidateTags from './CandidateTags'
import {
  CandidateAppliedFor,
  CandidateCoordinator,
  CandidateCreatedBy,
  CandidateCurrentCompany,
  CandidateCurrentCountry,
  CandidateCurrentLocation,
  CandidateCurrentPosition,
  CandidateCurrentStage,
  CandidateDesiredSalary,
  CandidateEmail,
  CandidateHiringManager,
  CandidateHiringProcess,
  CandidateHometask,
  CandidateInterviewer,
  CandidateJobBoard,
  CandidateLinks,
  CandidateName,
  CandidateOrigin,
  CandidatePhone,
  CandidatePreferredLocations,
  CandidateProblemSolving,
  CandidateRecruiter,
  CandidateRequisition,
  CandidateRightToWork,
  CandidateSeniority,
  CandidateSpecialisation,
  CandidateYearsExp,
} from '@src/features/InterviewTool/components/CandidateDataAtoms'

interface Props {
  round?: InterviewRoundInterface
  interviewer?: InterviewToolInterviewer
  title?: string
  candidate: CandidateInterface
  hometask?: string
  problemSolving?: string
  onOpenSidebar?: (type: CandidateSidebarTypes) => void
  hideCandidate?: boolean
  onShowCV?: () => void
  permissions: CandidateDataPermissions
}

interface CandidateDataPermissions {
  canAddRound: boolean
  canEditRound: boolean
  canViewCandidate: boolean
  canViewAppliedJobs: boolean
  canOpenAppliedJobs: boolean
  canEditCandidateSidebar: boolean
  canEditPersonalDetails: boolean
}

const CandidateData = ({
  round,
  interviewer,
  title,
  candidate,
  hometask,
  problemSolving,
  onOpenSidebar = () => {},
  hideCandidate,
  onShowCV,
  permissions,
}: Props) => {
  const { data: cv, isLoading: loadingCV } = useGetCV(onShowCV ? candidate.id : null)
  const linkedIn = getLinkedinUrl(candidate.links)
  const isArchived = round?.state === 'archived'
  const engagementInfo = <EngagementInfo candidate={candidate} isArchived={isArchived} />

  return (
    <>
      {(loadingCV || (onShowCV && cv) || linkedIn) && (
        <Group>
          {linkedIn && (
            <Item
              use="a"
              useIcon="LogoLinkedIn"
              href={linkedIn}
              target="_blank"
              aria-label="LinkedIn profile"
            >
              <Item.Content>
                <Item.Title>LinkedIn profile</Item.Title>
              </Item.Content>
              <Item.Side>
                <Icon name="LinkExternal" color={Token.color.blue} />
              </Item.Side>
            </Item>
          )}
          {loadingCV ? (
            <ItemSkeleton>
              <ItemSkeleton.Content>
                <ItemSkeleton.Title />
              </ItemSkeleton.Content>
              <ItemSkeleton.Side />
            </ItemSkeleton>
          ) : (
            <>
              {onShowCV && cv && (
                <Item
                  use="button"
                  useIcon="Document"
                  onClick={() => {
                    onShowCV?.()
                  }}
                >
                  <Item.Content>
                    <Item.Title>Candidate CV</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <Icon name="ChevronRight" color={Token.color.blue} />
                  </Item.Side>
                </Item>
              )}
            </>
          )}
        </Group>
      )}

      {isArchived && engagementInfo}
      <CandidateTags candidate={candidate} />
      <Group>
        <Item
          use={permissions.canEditPersonalDetails ? 'button' : undefined}
          onClick={
            permissions.canEditPersonalDetails
              ? () => onOpenSidebar('personalData')
              : undefined
          }
          aria-label="Personal details"
        >
          <Item.Avatar>
            <Avatar useIcon="Profile" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Personal details</Item.Title>
          </Item.Content>
          <Item.Side>
            {permissions.canEditPersonalDetails && (
              <Icon name="ChevronRight" color={Token.color.blue} />
            )}
          </Item.Side>
        </Item>
        <CandidateEmail candidate={candidate} />
        <CandidatePhone candidate={candidate} />
        <CandidateLinks candidate={candidate} />
        <CandidateProblemSolving url={problemSolving} />
        <CandidateHometask url={hometask} />
        <CandidateCurrentCompany candidate={candidate} />
        <CandidateCurrentLocation candidate={candidate} />
        <CandidateCurrentCountry candidate={candidate} />
        <CandidateCurrentPosition candidate={candidate} />
        <CandidateYearsExp candidate={candidate} />
        <CandidateRightToWork candidate={candidate} />
      </Group>
      <Box>
        <Group>
          <Item
            use={
              permissions.canEditRound || permissions.canAddRound ? 'button' : undefined
            }
            onClick={
              permissions.canEditRound || permissions.canAddRound
                ? () => onOpenSidebar('candidate')
                : undefined
            }
            aria-label="Opportunity details"
          >
            <Item.Avatar>
              <Avatar useIcon="Microphone" />
            </Item.Avatar>
            <Item.Content>
              <Item.Title>Opportunity details</Item.Title>
            </Item.Content>
            <Item.Side>
              {(permissions.canEditRound || permissions.canAddRound) && (
                <Icon name="ChevronRight" color={Token.color.blue} />
              )}
            </Item.Side>
          </Item>

          <CandidateCurrentStage stage={title} />
          {!hideCandidate && (
            <CandidateName candidate={candidate} canView={permissions.canViewCandidate} />
          )}
          <CandidateInterviewer interviewer={interviewer} />
          <CandidateSeniority round={round} />
          <CandidateSpecialisation round={round} />
          <CandidateHiringProcess
            round={round}
            candidate={candidate}
            canViewJobs={permissions.canViewAppliedJobs}
          />
          <CandidateRecruiter recruiter={round?.recruiter} />
          <CandidateCoordinator coordinator={round?.coordinator} />
          <CandidateHiringManager hiringManager={round?.hiring_manager} />
          <CandidateOrigin round={round} />
          <CandidateJobBoard round={round} />
          <CandidateCreatedBy round={round} />

          {permissions.canViewAppliedJobs && (
            <CandidateAppliedFor
              candidate={candidate}
              onOpen={
                permissions.canOpenAppliedJobs ? () => onOpenSidebar('rounds') : undefined
              }
            />
          )}
          <CandidateRequisition round={round} />
          <CandidatePreferredLocations round={round} />
          <CandidateDesiredSalary round={round} />
        </Group>
      </Box>
      <WorkExperienceInfo
        data={candidate.work_experiences}
        years={candidate.years_of_experience}
      />
      <EducationInfo data={candidate.educations} />
      {!isArchived && engagementInfo}
    </>
  )
}

export default CandidateData
