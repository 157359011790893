import { useParams } from 'react-router-dom'

export const useDepartmentBudgetParams = () =>
  useParams<{ id: string; sessionId: string }>()

export interface DepartmentBudgetRouteProps {
  name?: string
  cycle?: number
  headcount?: number
  errors?: number
  bonusTotal?: number
  salaryTotal?: number
}
