import React from 'react'

import { InviteEmployees } from '@src/features/InviteEmployees/InviteEmployees'
import { PageWrapper } from '@src/components/Page/Page'
import { OnboardingChecklistHeader } from '../components/OnboardingChecklistHeader'
import { ROUTES } from '@src/constants/routes'
import { useRouteMatch } from 'react-router-dom'

export const MAX_INVITE_LIMIT = 20

interface InviteTeamPageProps {
  variant: 'employees' | 'admins' | 'completeOnboarding'
}

const variantToRoutes = {
  employees: {
    emailRoute: ROUTES.INVITE_TEAM.EMAILS,
    reviewRoute: ROUTES.INVITE_TEAM.REVIEW,
  },
  completeOnboarding: {
    emailRoute: ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_EMPLOYEES.EMAILS,
    reviewRoute: ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_EMPLOYEES.REVIEW,
  },
  admins: {
    emailRoute: ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.EMAILS,
    reviewRoute: ROUTES.ONBOARDING_CHECKLIST_V2.INVITE_ADMINS.REVIEW,
  },
}

export const InviteTeamPage = ({ variant }: InviteTeamPageProps) => {
  const { emailRoute, reviewRoute } = variantToRoutes[variant]

  const isEmailsRoute = !!useRouteMatch(emailRoute)

  return (
    <PageWrapper>
      <OnboardingChecklistHeader
        title="Invite employees"
        description={
          isEmailsRoute
            ? `Type in invitee emails below or paste a list of emails to invite. You can invite maximum ${MAX_INVITE_LIMIT} employees at a time`
            : 'An invitation email with onboarding instructions will be sent to your employees to get started'
        }
        backUrl={ROUTES.MAIN}
      />
      <InviteEmployees
        emailListRoute={emailRoute}
        reviewTableRoute={reviewRoute}
        assignAdminGroup={variant === 'admins'}
        closeRoute={ROUTES.MAIN}
        isCompletingOnboarding={variant === 'completeOnboarding'}
      />
    </PageWrapper>
  )
}
