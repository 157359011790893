import React from 'react'
import { useSelector } from 'react-redux'
import { FilterButton, Token } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import Stat from '@src/components/Stat/Stat'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { useTable } from '@src/components/Table/hooks'
import { RowInterface } from '@src/interfaces/data'
import { navigateTo } from '@src/actions/RouterActions'
import { BenefitEnrolmentTableInterface } from '@src/interfaces/benefits'
import { getBenefitEnrolments, getBenefitEnrolmentsStats } from '@src/api/benefits'
import {
  benefitEnrolmentChangedOnColumn,
  benefitEnrolmentEmployeeColumn,
  benefitEnrolmentEnrolmentMethodColumn,
  benefitEnrolmentLocationColumn,
  benefitEnrolmentManagerColumn,
  benefitEnrolmentNameColumn,
  benefitEnrolmentPackageColumn,
  benefitEnrolmentStatusColumn,
  benefitsCategoryColumn,
} from '@src/constants/columns/benefits'
import { selectUser } from '@src/store/auth/selectors'
import { LOCAL_STORAGE } from '@src/constants/api'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { TableNames } from '@src/constants/table'

const Row: RowInterface<BenefitEnrolmentTableInterface> = {
  linkToForm: ({ enrolment_status, template_id, employee }) =>
    navigateTo(
      pathToUrl(
        enrolment_status.id === 'pending_employee'
          ? ROUTES.FORMS.EMPLOYEE_BENEFIT.SUMMARY
          : ROUTES.FORMS.EMPLOYEE_BENEFIT.PREVIEW,
        { employeeId: employee.id, id: template_id },
      ),
    ),
  cells: [
    {
      ...benefitEnrolmentNameColumn,
      width: 100,
    },
    {
      ...benefitsCategoryColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentPackageColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentStatusColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentEnrolmentMethodColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentChangedOnColumn,
      width: 100,
    },
    {
      ...benefitEnrolmentEmployeeColumn,
      width: 140,
    },
    {
      ...benefitEnrolmentManagerColumn,
      width: 140,
    },
    {
      ...benefitEnrolmentLocationColumn,
      width: 100,
    },
  ],
}

const Enrolment = () => {
  const [assignedToMe, setAssignedToMe] = useLocalStorage(
    LOCAL_STORAGE.SHOW_BENEFITS_ENROLMENT_ASSIGNED_TO_ME,
    false,
  )

  const user = useSelector(selectUser)

  const getFilterAssignedToMe = (setFilter: boolean) => ({
    filters: setFilter
      ? [
          {
            name: user.display_name,
            id: user.id,
          },
        ]
      : [],
    columnName: 'rewards_manager_id',
    nonResettable: true,
  })

  const getInitialFilters = () => {
    const filters: any = []

    if (assignedToMe) {
      filters.push(getFilterAssignedToMe(true))
    }

    return filters
  }

  const table = useTable(
    {
      getItems: getBenefitEnrolments,
      getStats: getBenefitEnrolmentsStats,
    },
    getInitialFilters(),
  )

  const onToggleAssignedToMe = () => {
    setAssignedToMe(!assignedToMe)
    table.onFilterChange(getFilterAssignedToMe(!assignedToMe))
  }

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat label="Assigned benefits" val={table?.stats?.assigned} />
        <Stat
          label="Pending approval"
          val={table?.stats?.pending_approval}
          color={Token.color.warning}
        />
      </Table.Widget.Info>
      <Table.Widget.Filters>
        <FilterButton onClick={onToggleAssignedToMe} active={assignedToMe}>
          Assigned to me
        </FilterButton>
      </Table.Widget.Filters>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.BenefitsEnrolment}
          useWindowScroll
          row={Row}
          {...table}
          noDataMessage="Benefit enrolments will appear here."
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Enrolment
