import Loader from '@components/CommonSC/Loader'
import { Flex } from '@revolut/ui-kit'
import React from 'react'

export const SectionLoader = () => {
  return (
    <Flex alignItems="center" pt="s-16">
      <Loader size="big" />
    </Flex>
  )
}
