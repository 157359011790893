import React, { useEffect, useState } from 'react'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { Box, TabBarProps } from '@revolut/ui-kit'
import { TabsInterface } from '@src/interfaces/data'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { tabToSchema } from '@src/utils/form'
import ValidationPre from '@src/features/TabBarNavigation/ValidationPre'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface Props extends TabBarProps {
  tabs: TabsInterface<any>[]
}

const withValidation = (tabs: TabsInterface<any>[], values: ReviewScorecardInterface) => {
  let isPreviousValid = true

  return tabs.map(tab => {
    const isValid = tabToSchema(tab).isValidSync(values)

    const result = {
      ...tab,
      disabled: !isPreviousValid,
      preTitle: <ValidationPre isVisible isValid={isValid} />,
    }

    if (isPreviousValid && !isValid) {
      isPreviousValid = false
    }

    return result
  })
}

const Navigation = ({ tabs, ...rest }: Props) => {
  const [tabsWithValidation, setTabsWithValidation] = useState<TabsInterface<any>[]>()
  const { values, valid } = useLapeContext<ReviewScorecardInterface>()

  useEffect(() => {
    setTabsWithValidation(withValidation(tabs, values))
  }, [valid, tabs])

  return (
    <Box mb="s-16">
      <TabBarNavigation tabs={tabsWithValidation} {...rest} mr={0} />
    </Box>
  )
}

export default Navigation
