import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Absolute,
  Box,
  chain,
  Color,
  Flex,
  InputGroup,
  Text,
  Token,
  ActionButton,
} from '@revolut/ui-kit'

import Table from '@components/TableV2/Table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { useTable } from '@src/components/Table/hooks'
import { PublicHolidayInterface, RegionInterface } from '@src/interfaces/timeOff'
import {
  archivePublicHoliday,
  getPublicHolidays,
  publicHolidaysRequest,
} from '@src/api/timeOff'
import {
  publicHolidaysCountryColumn,
  publicHolidaysCreationDateColumn,
  publicHolidaysFromDateColumn,
  publicHolidaysNameColumn,
  publicHolidaysRegionColumn,
  publicHolidaysToDateColumn,
} from '@src/constants/columns/timeOff'
import Stat from '@src/components/Stat/Stat'
import { selectPermissions } from '@src/store/auth/selectors'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import SideBar from '@components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import { Statuses } from '@src/interfaces'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

const ROW: RowInterface<PublicHolidayInterface> = {
  cells: [
    {
      ...publicHolidaysNameColumn,
      width: 300,
    },
    {
      ...publicHolidaysRegionColumn,
      width: 100,
    },
    {
      ...publicHolidaysCountryColumn,
      width: 100,
    },
    {
      ...publicHolidaysFromDateColumn,
      width: 100,
    },
    {
      ...publicHolidaysToDateColumn,
      width: 100,
    },
    {
      ...publicHolidaysCreationDateColumn,
      width: 100,
    },
  ],
}

type SidebarState = { open: boolean; selectedHoliday?: PublicHolidayInterface }

const PublicHolidays = () => {
  const table = useTable({ getItems: getPublicHolidays }, [
    {
      filters: [{ name: Statuses.active, id: Statuses.active }],
      columnName: 'status',
      nonResettable: true,
    },
  ])
  const permissions = useSelector(selectPermissions)

  const [sidebarState, setSidebarState] = useState<SidebarState>({ open: false })

  const canAdd = permissions.includes(PermissionTypes.AddPublicHolidays)

  const title = sidebarState?.selectedHoliday?.name
    ? chain(
        sidebarState.selectedHoliday.name,
        sidebarState.selectedHoliday.status?.id === Statuses.archived && (
          <Text color={Color.GREY_TONE_20}>Archived</Text>
        ),
      )
    : 'New public holiday'

  return (
    <>
      <PageHeader
        title="Time management settings"
        subtitle="Settings for the time off functionalities"
        backUrl={ROUTES.SETTINGS.TIME_OFF.GENERAL}
      />
      <PageBody maxWidthMd={Token.breakpoint.xxl}>
        <Table.Widget>
          <Table.Widget.Info>
            <Stat
              label="Total"
              val={table?.loading ? undefined : table?.count}
              mr="s-32"
            />
          </Table.Widget.Info>
          {canAdd && (
            <Table.Widget.Actions>
              <ActionButton
                onClick={() => setSidebarState({ open: true })}
                mr="s-16"
                useIcon="Plus"
              >
                Add public holiday
              </ActionButton>
            </Table.Widget.Actions>
          )}
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.PublicHolidays}
              useWindowScroll
              row={ROW}
              onRowClick={selectedHoliday =>
                setSidebarState({ open: true, selectedHoliday })
              }
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
      <SideBar
        title={title}
        isOpen={sidebarState.open}
        onClose={() => setSidebarState({ open: false })}
      >
        <Form
          api={publicHolidaysRequest}
          forceParams={{
            ...(sidebarState?.selectedHoliday
              ? { id: `${sidebarState.selectedHoliday.id}` }
              : { new: 'new' }),
          }}
          key={sidebarState.selectedHoliday?.id}
        >
          <PublicHolidayForm
            onAfterSubmit={selectedHoliday => {
              table.refresh()
              setSidebarState({ open: true, selectedHoliday })
            }}
          />
        </Form>
      </SideBar>
    </>
  )
}

interface PublicHolidayFormInterface {
  onAfterSubmit: (result: PublicHolidayInterface) => void
}

const PublicHolidayForm = ({ onAfterSubmit }: PublicHolidayFormInterface) => {
  const { values, reset } = useLapeContext<PublicHolidayInterface>()

  const [archivePending, setArchivePending] = useState(false)

  const { options: regions, asyncState } = useFetchOptions<RegionInterface>(
    selectorKeys.time_off_regions,
  )

  const regionOptions = useMemo(() => {
    return regions.filter(
      r => r.value.country != null && r.value.country.id === values.country?.id,
    )
  }, [values.country, regions])

  const onArchive = async () => {
    setArchivePending(true)
    archivePublicHoliday(values.id)
      .then(result => {
        reset(result.data)
        onAfterSubmit(result.data)
      })
      .finally(() => setArchivePending(false))
  }

  return (
    <Flex flexDirection="column" height={0}>
      {values.status?.id === Statuses.active &&
        values.field_options.permissions?.includes(
          EntityPermissions.ArchivePublicHoliday,
        ) && (
          <Box mb="s-16">
            <ActionButton
              onClick={onArchive}
              variant="negative"
              pending={archivePending}
              useIcon="Archive"
            >
              Archive
            </ActionButton>
          </Box>
        )}

      <Box pb="92px">
        <InputGroup>
          <LapeNewInput name="name" label="Name" required />
          <LapeRadioSelectInput
            name="country"
            label="Country"
            selector={selectorKeys.countries}
            onAfterChange={() => {
              values.region = null
            }}
          />
          <LapeRadioSelectInput
            name="region"
            label="Region"
            disabled={asyncState !== 'ready' || !values.country}
            options={regionOptions}
          />
          <LapeDatePickerInput name="from_date" label="From date" required />
          <LapeDatePickerInput name="to_date" label="To date" required />
        </InputGroup>
      </Box>

      <Absolute bottom="s-24" left="s-24" right="s-24">
        <NewSaveButtonWithPopup
          onAfterSubmit={result => onAfterSubmit(result as PublicHolidayInterface)}
          useValidator
        />
      </Absolute>
    </Flex>
  )
}

export default PublicHolidays
