export const HERO_IMG = 'https://assets.revolut.com/revoluters/assets/hero-bg.jpg'
export const HERO_ICONS = 'https://assets.revolut.com/revoluters/assets/hero-icons.png'
export const HERO_MOBILE = 'https://assets.revolut.com/revoluters/assets/hero-mobile.png'
export const HERO_LG = 'https://assets.revolut.com/revoluters/assets/hero-lg.png'

export const CUSTOMER_1 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-1.png'
export const CUSTOMER_2 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-2.png'
export const CUSTOMER_3 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-3.png'
export const CUSTOMER_4 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-4.png'
export const CUSTOMER_5 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-5.png'
export const CUSTOMER_6 =
  'https://assets.revolut.com/revoluters/assets/landing/customer-6.png'

export const GOALS_IMG = 'https://assets.revolut.com/revoluters/assets/landing/goals.png'
export const HR_IMG = 'https://assets.revolut.com/revoluters/assets/landing/hr.png'
export const RECRUITMENT_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/recruitment.png'
export const REVIEWS_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/reviews.png'

export const LOGO_GOOGLE_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-google.png'
export const LOGO_JIRA_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-jira.png'
export const LOGO_LINKEDIN_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-linkedin.png'
export const LOGO_LOOKER_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-looker.png'
export const LOGO_MICROSOFT_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-microsoft.png'
export const LOGO_SLACK_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/logo-slack.png'

export const LOGO_TECHSCALE_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/icon-techscale.png'
export const LOGO_WHEELY_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/icon-wheely.png'

export const STORY_TECHSCALE_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/story-techscale.jpg'
export const STORY_WHEELY_IMG =
  'https://assets.revolut.com/revoluters/assets/landing/story-wheely.jpg'

export const DEMO_GOALS_URL = 'https://revolut.navattic.com/dl3209yu'
export const DEMO_PERFORMANCE_URL = 'https://revolut.navattic.com/e7320bxj'
export const DEMO_RECRUITMENT_URL = 'https://revolut.navattic.com/513709cr'
export const DEMO_HR_URL = 'https://revolut.navattic.com/3c3201xv'
