import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { General } from './General'
import { FAQCategories } from './FAQCategories'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PageWrapper } from '@src/components/Page/Page'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageHeader } from '@src/components/Page/Header/PageHeader'

export const HelpCenterSettings = () => {
  useSetDocumentTitle('Settings', 'Help center')

  return (
    <PageWrapper>
      <PageHeader
        title="Help center app settings"
        subtitle="Settings for all help center functionalities"
        backUrl={ROUTES.SETTINGS.ALL}
      />

      <TabBarNavigation
        tabs={[
          {
            to: ROUTES.SETTINGS.HELP_CENTER.GENERAL,
            path: ROUTES.SETTINGS.HELP_CENTER.GENERAL,
            title: 'General',
          },
          {
            to: ROUTES.SETTINGS.HELP_CENTER.FAQ_CATEGORIES,
            path: ROUTES.SETTINGS.HELP_CENTER.FAQ_CATEGORIES,
            title: 'FAQ categories',
          },
        ]}
      />

      <Switch>
        <Route path={ROUTES.SETTINGS.HELP_CENTER.GENERAL} exact>
          <General />
        </Route>
        <Route path={ROUTES.SETTINGS.HELP_CENTER.FAQ_CATEGORIES} exact>
          <FAQCategories />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
