import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { DocusignUsersInterface } from '@src/interfaces/integrations'
import Table from '@components/TableV2/Table'

export const docusignUsersEmailColumn: ColumnInterface<DocusignUsersInterface> = {
  type: CellTypes.insert,
  idPoint: 'email',
  dataPoint: 'email',
  sortKey: 'email',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Email',
  insert: ({ data }) => data.email,
}

export const docusignUsersStatusColumn: ColumnInterface<DocusignUsersInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.docusign_users_statuses,
  title: 'DocuSign Status ',
  insert: ({ data }) => {
    const variant = data.status.id === 'active' ? 'success' : 'default'
    return <Table.StatusCell status={data.status.id} variant={variant} />
  },
}
