import { Cell, Flex, Link, Text } from '@revolut/ui-kit'
import { useGetContactHRSettings } from '@src/api/settings'
import SideBar from '@src/components/SideBar/SideBar'
import React from 'react'

interface MagicLinkInstructionsSidebarProps {
  isOpen: boolean
  onClose: () => void
}
const MagicLinkInstructionsSidebar = ({
  isOpen,
  onClose,
}: MagicLinkInstructionsSidebarProps) => {
  const { data: settings } = useGetContactHRSettings()

  return (
    <SideBar
      title="How to resolve issues with receiving your login link by email"
      isOpen={isOpen}
      onClose={onClose}
    >
      <Cell>
        <Flex flexDirection="column" gap="s-8">
          <Text>
            Magic login links can take some time to be delivered to you. Please wait up to
            15 minutes before checking your email inbox.
          </Text>
          <Text>
            In case you do not see the email in your inbox, please make sure to check your
            spam folder.
          </Text>
          {settings?.contact_hr_email ? (
            <Text>
              If you still are unable to find your login link, please write to{' '}
              <Link href={`mailto:${settings.contact_hr_email}`}>
                {settings.contact_hr_email}
              </Link>{' '}
              and provide an alternative email address to use for logging in
            </Text>
          ) : null}
        </Flex>
      </Cell>
    </SideBar>
  )
}

export default MagicLinkInstructionsSidebar
