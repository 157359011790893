import React from 'react'
import { InterviewFeedbackInterface } from '@src/interfaces/interviewTool'
import { Item, List, Tag, Token } from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { getFeedbackBadge } from '@src/pages/Forms/Candidate/StagesTable/StagesTableFeedback'
import { formatDateTime } from '@src/utils/format'

type InterviewFeedbackInformationProps = {
  feedback?: InterviewFeedbackInterface
}

export const InterviewFeedbackInformation = ({
  feedback,
}: InterviewFeedbackInformationProps) => {
  if (!feedback) {
    return null
  }
  const feedbackBadge = getFeedbackBadge(feedback.result || undefined, feedback.status)
  return (
    <Item>
      <Item.Avatar>
        <UserWithAvatar compact disableTooltip size={40} {...feedback.interviewer} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{feedback.interviewer?.full_name}</Item.Title>
        <List variant="compact" color={Token.color.greyTone50} mt="s-8">
          <List.Item useIcon="16/Time">
            Submitted on {formatDateTime(feedback.created_date_time)}
          </List.Item>
          <List.Item useIcon="Microphone">{feedback.scorecard.interview_name}</List.Item>
        </List>
      </Item.Content>
      <Item.Side>
        <Item.Value>
          <Tag
            useIcon={feedbackBadge.icon}
            color={feedbackBadge.color}
            style={{ whiteSpace: 'nowrap' }}
          >
            {feedbackBadge.text}
          </Tag>
        </Item.Value>
      </Item.Side>
    </Item>
  )
}
