import React, { useState } from 'react'
import { Box, Carousel, Image, Flex, Token, ThemeProvider } from '@revolut/ui-kit'
import { Button } from '@src/pages/Landing/components/Button'
import styled from 'styled-components'

const screens = [
  'Homepage',
  'Goals',
  'Performance',
  'Analytics',
  'Candidate profile',
  'Scorecard',
  'Time off',
  'Compensation',
]

const StyledCarousel = styled(Carousel)`
  button {
    display: none;
  }
`

export const FeaturesCarousel = () => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  return (
    <Box mx="auto">
      <StyledCarousel
        aria-label="Images"
        align="center"
        alignWidth="200px"
        radius="image-clip"
        hideButtonsPreview
        index={currentSlideIndex}
        onIndexChange={setCurrentSlideIndex}
      >
        {screens.map((screen, id) => (
          <Carousel.Item key={screen} width={1}>
            <Flex justifyContent="center">
              <Image
                src={`https://assets.revolut.com/revoluters/assets/landing/screen-${
                  id + 1
                }.png`}
                aria-label={screen}
                radius="r8"
                style={{
                  boxShadow: `0px 0px 10px 0px ${Token.color.greyTone50}`,
                }}
                my="s-8"
              />
            </Flex>
          </Carousel.Item>
        ))}
      </StyledCarousel>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap="s-12"
        pt="s-24"
        flexWrap="wrap"
        display={{ all: 'none', md: 'flex' }}
      >
        <ThemeProvider mode="light">
          {screens.map((screen, id) => (
            <Button
              onClick={() => setCurrentSlideIndex(id)}
              key={screen}
              // @ts-ignore TODO: REVPI-28 Migrate LandingPage to standard UI Kit
              variant={currentSlideIndex === id ? 'primary' : 'secondary'}
            >
              {screen}
            </Button>
          ))}
        </ThemeProvider>
      </Flex>
    </Box>
  )
}
