import { filterSortPageIntoQuery } from '../utils/table'
import { api, apiWithoutHandling, onboardingHandleError } from './index'
import { API } from '../constants/api'
import { GetRequestData, RequestInterface, tableRequests } from '../interfaces'
import {
  NotificationsInterface,
  NotificationsStatisticsInterface,
} from '@src/interfaces/notifications'
import { AxiosPromise } from 'axios'
import { FilterByInterface } from '@src/interfaces/data'
import { OptionInterface } from '@src/interfaces/selectors'
import { useFetch } from '@src/utils/reactQuery'

const getPath = (employeeId?: number) =>
  employeeId == null
    ? API.NOTIFICATIONS
    : `${API.EMPLOYEES}/${employeeId}${API.NOTIFICATIONS}`

export const getEmployeeNotificationStats = (
  employeeId: number,
  filters: FilterByInterface[],
): AxiosPromise<NotificationsStatisticsInterface> =>
  api.get<NotificationsStatisticsInterface>(
    `${getPath(employeeId)}/statistics`,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const notificationsRequests = (
  employeeId?: number,
): RequestInterface<NotificationsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    apiWithoutHandling
      .get<GetRequestData<NotificationsInterface>>(
        getPath(employeeId),
        {
          params: filterSortPageIntoQuery(sortBy, filters, page),
        },
        'v2',
      )
      .catch(onboardingHandleError),
  getItem: () => {
    return api.get(`${API.NOTIFICATIONS}`, {}, 'v2')
  },
  patchItem: (data, id) => api.patch(`${API.NOTIFICATIONS}/${id}`, data, {}, 'v2'),
  deleteItem: id => api.delete(`${API.NOTIFICATIONS}/${id}`, {}, 'v2'),
  postItem: data => api.post(API.NOTIFICATIONS, data, {}, 'v2'),
})

export const useGetNotificationStats = () => {
  return useFetch<NotificationsStatisticsInterface>(
    `${API.NOTIFICATIONS}/statistics`,
    'v2',
    undefined,
    true,
  )
}

export const notificationsReviewsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/reviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsFindingsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/findings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsServiceDeskRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/serviceDesk`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsUpwardRequestDelete = async (id: number) =>
  api.delete(`${API.PERFORMANCE}/pendingUpwardsReviews/${id}`)

export const notificationsUpwardReviewsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/upwards_reviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsPipRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/pip`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsProbationRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/probation`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsKpisRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/kpis`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsRequisitionsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/requisitions`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsRolesRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/roles`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsTrainingsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/trainings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsDataAccessRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/dataAccess`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsSkillsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/skills`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsDeliverablesRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/deliverable_reviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsPendingDocuments = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/documents`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsInterviews = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/interviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsCatchupInterviews = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/adhocInterviews`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsCandidates = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/candidates`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsOwnershipTransferRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/ownership_transfer`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsKPIsToAssign = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/kpisToAssign`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsKPIsToApprove = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/kpisToApprove`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsKPIsUpload = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/kpisBulkUpload`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsGoalsToSet = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/goalsToSet`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsGoalsToApprove = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/goalsToApprove`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsGoalsToCalibrate = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/goalsToCalibrate`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsOwnershipTransferArchive = (id: number) =>
  api.post(`${API.OWNERSHIP_TRANSFER_NOTIFICATIONS}/${id}/archive`)

export const notificationsMoverArchive = (id: number) =>
  api.post(`${API.EMPLOYEE_CHANGING_TEAM_NOTIFICATIONS}/${id}/archive`)

export const employeeInboxTitleSelector = async (employeeId: number) => {
  const result = await api.get<{ options: OptionInterface[] }>(
    `${API.EMPLOYEES}/${employeeId}${API.NOTIFICATIONS}/titles`,
    {},
    'v2',
  )
  return result?.data
}

export const notificationsJobPostingsRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/jobPostings`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsTimeOffRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/timeOff`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsBulkUploadRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/bulkUpload`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsGroupRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/group`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsSuccessionPlansRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/successionPlans`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsGroupAccessRequests = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/groupAccessRequest`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsComments = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/comments`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsPromotion = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/promotion`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsMeetings = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/oneToOne`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const notificationsReviewCalibration = (
  employeeId?: number,
): tableRequests<NotificationsInterface, NotificationsStatisticsInterface> => ({
  getItems: ({ sortBy, filters, page }) =>
    api.get(
      `${getPath(employeeId)}/calibration`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const completeTaskNotification = (taskId: number) =>
  api.post(`${API.TASK_NOTIFICATIONS}/${taskId}/complete`)

export const completeCourseEnrolmentNotification = (employeeId: number, itemId: number) =>
  api.post(`${API.EMPLOYEES}/${employeeId}/courseEnrolments/${itemId}`)
