import { connect } from 'lape'
import set from 'lodash/set'
import get from 'lodash/get'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { goBack } from '@src/actions/RouterActions'
import React, { useState } from 'react'
import { Archive, Unarchive } from '@revolut/icons'
import { FieldOptions, Statuses } from '@src/interfaces'
import { SettingsButtonsInstanceProps } from '@src/features/SettingsButtons'
import { EntityPermissions } from '@src/store/auth/types'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import MoreBarButton, { SettingsButtonProps } from '../MoreBarButton'
import { ButtonVariant } from '@revolut/ui-kit'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

interface ArchiveButtonProps extends SettingsButtonsInstanceProps, SettingsButtonProps {
  afterSubmitUrl?: string
  onBeforeArchive?: () => void
  onAfterArchive?: () => void
  onError?: () => void
  archiveButtonLabel?: string
  dialogText?: string
  dialogLabel?: string
  yesMessage?: string
  noMessage?: string
  yesBtnVariant?: ButtonVariant
  noBtnVariant?: ButtonVariant
  showDialog?: boolean
  statusFieldName?: string
  unArchiveStatus?: Statuses
}

const LapeArchiveButton = ({
  useMoreButton,
  afterSubmitUrl,
  onBeforeArchive,
  onAfterArchive,
  onError,
  isVisible = true,
  globalPermissions,
  entityPermissions = EntityPermissions.Archive,
  dialogText,
  dialogLabel,
  archiveButtonLabel = 'Archive',
  yesMessage,
  noMessage,
  yesBtnVariant,
  noBtnVariant,
  showDialog,
  statusFieldName = 'status',
  unArchiveStatus = Statuses.pending,
}: ArchiveButtonProps) => {
  const { values, submit } = useLapeContext<{
    status: Statuses
    field_options: FieldOptions
    id: number
    name?: string
  }>()
  const [loading, setLoading] = useState(false)
  const showStatusPopup = useShowStatusPopup()
  const isArchived = get(values, statusFieldName) === Statuses.archived

  const [isDialogOpen, setDialogOpen] = useState<boolean>(false)
  const permissions = useSelector(selectPermissions)

  const canArchive =
    values.field_options?.actions?.includes(entityPermissions) ||
    globalPermissions?.some(p => permissions.includes(p))

  const archive = async (status = Statuses.archived) => {
    onBeforeArchive?.()
    const fallbackStatus = get(values, statusFieldName)
    set(values, statusFieldName, status)
    setLoading(true)

    try {
      showStatusPopup({
        status: 'pending',
        title: isArchived ? 'Un-archiving.' : 'Archiving.',
        description: 'It may take some time',
      })
      const result = await submit()
      if (afterSubmitUrl) {
        goBack(afterSubmitUrl)
      }

      showStatusPopup({
        status: 'success',
        title: !isArchived ? 'Archived successfully' : 'Unarchived successfully',
      })

      onAfterArchive?.()
      return result
    } catch (e) {
      const defaultErr = isArchived
        ? 'Un-archive was unsuccessful'
        : 'Archive was unsuccessful'

      showStatusPopup({
        title: 'Something went wrong',
        description: get(e?.response?.data, statusFieldName)?.[0] || defaultErr,
        status: 'error',
      })

      set(values, statusFieldName, fallbackStatus)
      onError?.()
    } finally {
      setLoading(false)
    }

    return null
  }

  if (!isVisible || !canArchive) {
    return null
  }

  if (isArchived) {
    return (
      <MoreBarButton
        useMoreButton={useMoreButton}
        useIcon={Unarchive}
        pending={loading}
        onClick={() => archive(unArchiveStatus)}
      >
        Unarchive
      </MoreBarButton>
    )
  }

  return (
    <>
      {showDialog ? (
        <ConfirmationDialog
          open={isDialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={() => {
            archive()
            setDialogOpen(false)
          }}
          loading={loading}
          onReject={() => setDialogOpen(false)}
          label={dialogLabel ?? `Archiving${values.name ? ` "${values.name}"` : ''}`}
          body={dialogText}
          yesMessage={yesMessage}
          noMessage={noMessage}
          yesBtnVariant={yesBtnVariant}
          noBtnVariant={noBtnVariant}
          variant="compact"
        />
      ) : null}
      <MoreBarButton
        useMoreButton={useMoreButton}
        useIcon={Archive}
        variant="negative"
        pending={loading}
        onClick={showDialog ? () => setDialogOpen(true) : () => archive()}
      >
        {archiveButtonLabel}
      </MoreBarButton>
    </>
  )
}

export default connect(LapeArchiveButton)
