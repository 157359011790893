import React from 'react'
import { useSelector } from 'react-redux'
import startCase from 'lodash/startCase'
import { ActionButton, Avatar, Group, Item, Text, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { getEmployeeDocuments } from '@src/api/accessRequests'
import { useGetDocumentsSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import {
  DocumentSources,
  EmployeeDocumentListItemInterface,
} from '@src/interfaces/documents'
import { TablePreview } from '@src/components/TablePreview/TablePreview'
import { getDocumentLink } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { selectUser } from '@src/store/auth/selectors'
import { pathToUrl } from '@src/utils/router'
import { getDocumentStatusColor, getDocumentStatusToIcon } from '../utils'

interface DocumentsPreviewProps {
  employeeId: number
}

export const DocumentsGeneratedPreview = ({ employeeId }: DocumentsPreviewProps) => {
  const user = useSelector(selectUser)
  const { data: documentsSettings } = useGetDocumentsSettings()

  return (
    <Group>
      <Item>
        <Item.Content>
          <Item.Title color={Token.color.greyTone50}>Generated documents</Item.Title>
        </Item.Content>
        {documentsSettings?.enable_docusign_integration && (
          <Item.Side>
            <ActionButton
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.DOCUMENT_GENERATE.GENERAL, {
                    employeeId,
                  }),
                  {
                    initialValues: {
                      generated_by: { id: user.id, name: user.full_name },
                    },
                  },
                )
              }
              useIcon="Plus"
            >
              Add new
            </ActionButton>
          </Item.Side>
        )}
      </Item>
      <TablePreview<EmployeeDocumentListItemInterface>
        api={{
          getItems: requestData =>
            getEmployeeDocuments(
              {
                ...requestData,
                filters: [
                  {
                    filters: [
                      { id: DocumentSources.Embedded, name: DocumentSources.Embedded },
                      { id: DocumentSources.Docusign, name: DocumentSources.Docusign },
                    ],
                    columnName: 'source',
                  },
                ],
              },
              employeeId,
            ),
        }}
        emptyState={{
          icon: 'Document',
          title: 'No documents generated',
        }}
        row={document => {
          return (
            <Item
              use="button"
              onClick={() => getDocumentLink(document)}
              key={document.id}
            >
              <Item.Avatar>
                <Avatar useIcon="Document">
                  <Avatar.Badge
                    bg={getDocumentStatusColor(document.status)}
                    useIcon={getDocumentStatusToIcon(document.status)}
                  />
                </Avatar>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{document.document_name}</Item.Title>
              </Item.Content>
              <Item.Side>
                <Item.Title color={getDocumentStatusColor(document.status)}>
                  <Text fontWeight="normal">{startCase(document.status)}</Text>
                </Item.Title>
              </Item.Side>
            </Item>
          )
        }}
      />
    </Group>
  )
}
