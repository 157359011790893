import React from 'react'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import {
  VStack,
  Item,
  Checkbox,
  Avatar,
  Text,
  Token,
  ToggleButton,
  Flex,
} from '@revolut/ui-kit'
import { LogoGoogle, Pencil } from '@revolut/icons'

import { getIconProps } from '@src/pages/Hub/utils'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CompanyPreferencesInterface } from '@src/interfaces/settings'

const integrations = [
  {
    id: 'slack',
    title: 'Slack',
    image: 'slack',
    description: 'Setup custom notifications for your employees',
  },
  {
    id: 'jira',
    title: 'Jira',
    image: 'jira',
    description: 'Sync roadmaps and deliverables to track company projects',
  },
  {
    id: 'calendar',
    title: 'Google Calendar',
    image: 'calendar',
    description: 'Track meetings and schedule interviews with candidates',
  },
  {
    id: 'gmail',
    title: 'Gmail',
    icon: LogoGoogle,
    description:
      'Send messages to candidates and employees directly through the platform',
  },
  {
    id: 'docusign',
    title: 'DocuSign',
    image: 'docusign',
    description: 'Generate and send e-sign docs and see updates in real time',
  },
]

const OTHER_OPTION = 'Other'

const tools = [
  'Microsoft Teams',
  'Notion',
  'Mailchimp',
  'HubSpot',
  'Trello',
  'Asana',
  OTHER_OPTION,
]

const InitialIntegrations = () => {
  const { values } = useLapeContext<CompanyPreferencesInterface>()

  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCompanySettings)

  return (
    <>
      <SectionTitle
        title="Which tools would you like to integrate?"
        subtitle="Select tools that you would like to use within Revolut People Platform and configure them during set up"
      />
      <VStack gap="s-16">
        {integrations.map(integration => {
          const checked = !!values.tools?.[integration.id]

          return (
            <Item use="label" key={integration.id} disabled={disableEdit}>
              <Item.Prefix>
                <Checkbox
                  checked={checked}
                  onChange={() => {
                    values.tools[integration.id] = !checked
                  }}
                />
              </Item.Prefix>
              <Item.Avatar>
                <Avatar
                  variant="brand"
                  useIcon={integration.icon}
                  {...(integration.icon
                    ? { useIcon: integration.icon }
                    : getIconProps(integration.image, 'transparent', 60, 'png'))}
                  bg="transparent"
                  size={40}
                />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{integration.title}</Item.Title>
                <Item.Description>{integration.description}</Item.Description>
              </Item.Content>
            </Item>
          )
        })}
        <Text variant="caption" color={Token.color.greyTone50}>
          Do you use any other tools in your workflow and would like to integrate with?
          Let us know!
        </Text>
        <Flex gap="s-8" rowGap="s-8" flexWrap="wrap">
          {tools?.map(option => {
            const checked = values.other_tools?.includes(option)
            return (
              <ToggleButton
                checked={checked}
                onClick={() => {
                  if (checked) {
                    values.other_tools = values.other_tools.filter(o => o !== option)
                  } else {
                    values.other_tools = [...values.other_tools, option]
                  }
                }}
                useIcon={option === OTHER_OPTION ? Pencil : undefined}
                key={option}
              >
                {option}
              </ToggleButton>
            )
          })}
        </Flex>
        {values.other_tools?.includes(OTHER_OPTION) ? (
          <LapeNewInput
            label="List of tools I’d like to integrate with"
            name="other_tools_text"
            required
          />
        ) : null}
      </VStack>
    </>
  )
}

export default InitialIntegrations
