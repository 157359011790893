import React, { useState } from 'react'
import { ActionButton, Header, Button, Token } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { DiversityInterface } from '@src/interfaces/employees'
import {
  DiversityFAQSidebar,
  DiversityFormFields,
  IncompletePopup,
  useIncompletePopupState,
} from '@src/pages/EmployeeProfile/Forms/Diversity/General'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { TabProps } from '../common/interface'
import { BackButton, NextButton } from '../components/Buttons'

export const Diversity = ({ isPreview, nextRoute, onComplete, prevRoute }: TabProps) => {
  const showStatusPopup = useShowStatusPopup()
  const {
    confirmationPopupOpen,
    incompleteFields,
    onClose,
    onExited,
    setConfirmationPopupOpen,
  } = useIncompletePopupState()
  const { submit, isSubmitting } = useLapeContext<DiversityInterface>()

  const [diversityFaqOpen, setDiversityFaqOpen] = useState(true)

  const navigateToPrevStep = () => {
    if (prevRoute) {
      navigateTo(prevRoute)
    }
  }

  const navigateToNextStep = () => {
    if (nextRoute) {
      navigateTo(nextRoute)
      onComplete?.()
    }
  }

  const onConfirmIncompletePopup = async () => {
    try {
      submit()
      navigateToNextStep()
    } catch (e) {
      showStatusPopup({
        title: 'Something went wrong',
        description: getStringMessageFromError(e),
        status: 'error',
      })
    }
  }

  return (
    <>
      <PageBody pb="s-24">
        <Header variant="main">
          <Header.Title color={Token.color.foreground}>Diversity</Header.Title>
          <Header.Subtitle>
            This information will be anonymised and is completely optional.
          </Header.Subtitle>
        </Header>
        <ActionButton
          mb="s-24"
          onClick={() => setDiversityFaqOpen(true)}
          useIcon="InfoOutline"
        >
          FAQs
        </ActionButton>

        <DiversityFormFields isOnboarding />
      </PageBody>

      <PageActions alignSelf="center" maxWidthMd={Token.breakpoint.sm}>
        <BackButton onClick={navigateToPrevStep} />
        {!isPreview && incompleteFields.length > 0 ? (
          <Button onClick={() => setConfirmationPopupOpen(true)}>Next</Button>
        ) : (
          <NextButton afterSubmit={navigateToNextStep} />
        )}
      </PageActions>

      <IncompletePopup
        loading={isSubmitting}
        onClose={onClose}
        onConfirm={onConfirmIncompletePopup}
        onExited={onExited}
        onReject={onClose}
        open={confirmationPopupOpen}
      />

      <DiversityFAQSidebar
        isOpen={diversityFaqOpen}
        onClose={() => setDiversityFaqOpen(!diversityFaqOpen)}
      />
    </>
  )
}
