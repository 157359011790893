import React from 'react'
import { Spacer, VStack, Widget } from '@revolut/ui-kit'
import { EmployeeInterface } from '@src/interfaces/employees'
import Loader from '@components/CommonSC/Loader'
import { useGetEmployeeGrowthPlan } from '@src/features/GrowthPlans/hooks/useGetEmployeeGrowthPlan'
import { EmptyGrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/EmptyGrowthPlan'
import { GrowthPlan } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlan'

interface Props {
  employee: EmployeeInterface
}

export const PreviewGrowthPlan = ({ employee }: Props) => {
  const {
    data: growthPlan,
    isFetching: isGrowthPlanFetching,
    refetch,
  } = useGetEmployeeGrowthPlan(employee.id)

  if (isGrowthPlanFetching) {
    return (
      <Widget>
        <VStack height={300}>
          <Spacer />
          <Loader />
          <Spacer />
        </VStack>
      </Widget>
    )
  }

  if (!growthPlan) {
    return <EmptyGrowthPlan employee={employee} />
  }

  return <GrowthPlan employee={employee} growthPlan={growthPlan} refetch={refetch} />
}
