import React, { useEffect, useState } from 'react'
import { InterviewFeedbackSidebar } from '@src/pages/Forms/Candidate/InterviewProgress/components/InterviewFeedbackSidebar/InterviewFeedbackSidebar'
import { interviewFeedbackFormRequests } from '@src/api/recruitment/interviewFeedback'
import {
  InterviewRoundInterface,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import { getInterviewRound } from '@src/api/recruitment/interviews'
import TimelineSidebarLoader from '@src/pages/Forms/Candidate/Timeline/TimelineSidebars/TimelineSidebarLoader'

interface TimelineScorecardSidebarProps {
  onClose: () => void
  scorecardId: number
  roundId?: number
}

const TimelineScorecardSidebar = ({
  onClose,
  scorecardId,
  roundId,
}: TimelineScorecardSidebarProps) => {
  const [scorecard, setScorecard] = useState<InterviewFeedbackInterface>()
  const [round, setRound] = useState<InterviewRoundInterface>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    interviewFeedbackFormRequests
      .get({ id: scorecardId.toString() })
      .then(res => setScorecard(res.data))
      .finally(() => setIsLoading(false))

    if (roundId) {
      getInterviewRound(roundId).then(res => setRound(res.data))
    }
  }, [])

  if (isLoading) {
    return <TimelineSidebarLoader onClose={onClose} />
  }

  if (!scorecard) {
    return null
  }

  return (
    <InterviewFeedbackSidebar
      isOpen
      onExit={onClose}
      scorecard={scorecard}
      title={scorecard.interview_stage.title}
      round={round}
    />
  )
}

export default TimelineScorecardSidebar
