import React from 'react'
import { Box, InputGroup, Side } from '@revolut/ui-kit'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useEmployeeDetailsOnAfterSubmit } from '@src/pages/EmployeeProfile/Forms/hooks'
import { LapePhoneInput } from '@components/Inputs/LapeFields/LapePhoneInput'

export type Props = {
  isSidebarContent?: boolean
  refreshData?: () => void
}
const EmergencyContactsForm = ({ isSidebarContent, refreshData }: Props) => {
  const { onAfterSubmit } = useEmployeeDetailsOnAfterSubmit(refreshData)
  const ActionsWrapper = isSidebarContent ? Side.Actions : PageActions

  return (
    <PageBody>
      <AutoStepper>
        <Box mb="s-32">
          <InputGroup>
            <LapeNewInput
              name="emergency_contact_full_name"
              label="Emergency contact full name"
              hideOptional
            />
            <LapeNewInput
              name="emergency_contact_email"
              label="Emergency contact email"
              hideOptional
            />
            <LapePhoneInput
              prefixName="emergency_contact_phone_country_code"
              phoneName="emergency_contact_phone_number"
              message="Please provide emergency contact mobile number"
              required
            />
            <LapeNewInput
              name="emergency_contact_relationship"
              label="Emergency contact relationship"
              hideOptional
            />
          </InputGroup>
        </Box>
        <ActionsWrapper>
          <NewSaveButtonWithPopup
            useValidator
            successText="Emergency contact successfully updated."
            onAfterSubmit={onAfterSubmit}
          />
        </ActionsWrapper>
      </AutoStepper>
    </PageBody>
  )
}

export default EmergencyContactsForm
