import React from 'react'
import {
  Dropdown,
  Flex,
  Calendar,
  FilterButton,
  useDropdown,
  Ellipsis,
  Text,
  Icon,
} from '@revolut/ui-kit'
import format from 'date-fns/format'

interface MonthSelectProps {
  value: string | number
  onChange: (date: Date | null) => void
}

export const MonthSelect = ({ value, onChange }: MonthSelectProps) => {
  const dropdown = useDropdown()

  return (
    <FilterButton
      useIcon="Calendar"
      aria-haspopup="listbox"
      aria-expanded={dropdown.state}
      {...dropdown.getAnchorProps()}
    >
      <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
        <Ellipsis>
          <Text variant="emphasis2">{format(new Date(value), 'MMMM yyyy')}</Text>
        </Ellipsis>
        <Icon name={dropdown.state ? 'ChevronUp' : 'ChevronDown'} />
      </Flex>
      <Dropdown {...dropdown.getTargetProps()}>
        <Calendar
          variant="month"
          labelEmptyValue="Select month"
          labelPrev="Previous month"
          labelNext="Next month"
          value={new Date(value)}
          onChange={date => {
            onChange(date)
            dropdown.toggle(false)
          }}
        />
      </Dropdown>
    </FilterButton>
  )
}
