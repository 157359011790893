import { Box } from '@revolut/ui-kit'
import React from 'react'
import {
  InterviewOptionInterface,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import { useLapeContext } from '@src/features/Form/LapeForm'
import get from 'lodash/get'
import { Section } from './Section'
import { setTimeCodeHandler } from '@src/utils/hiring'

type Props = {
  data: InterviewOptionInterface[]
  answerName: string
  titleIndexNumber: number
  title: string
  notesName?: string
  subtitle?: string
  isViewMode?: boolean
  isOptional?: boolean
}

const RadioCard = ({
  data,
  answerName,
  isViewMode,
  isOptional,
  title,
  subtitle,
  notesName,
  titleIndexNumber,
}: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const points = get(values, answerName)?.points || 0

  return (
    <Section
      index={titleIndexNumber}
      title={title}
      subtitle={subtitle}
      points={points}
      isViewMode={isViewMode}
      isOptional={isOptional}
      notesName={notesName}
      onAddTimeCode={setTimeCodeHandler(notesName, values)}
    >
      <LapeNewRadioButtons
        name={answerName}
        options={data.map(option => ({
          label: option.points < 0 ? `🚩\u00A0 ${option.option}` : option.option,
          value: option,
        }))}
        renderRadio={radio => <Box py="s-8">{radio}</Box>}
        readOnly={isViewMode}
      />
    </Section>
  )
}

export default RadioCard
