import Form from '@src/features/Form/Form'
import { googleCalendarRequests } from '@src/api/integrations'
import React from 'react'
import { GoogleInternalForm } from '@src/pages/Hub/Integrations/Google/GoogleInternal'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import ShowIfCommercial from '@components/ShowIfCommercial/ShowIfCommercial'
import { GoogleCommercial } from '@src/pages/Hub/Integrations/Google/GoogleCommercial'

export const GoogleIntegration = () => {
  return (
    <>
      <HideIfCommercial>
        <Form api={googleCalendarRequests} forceParams={{ id: '1' }}>
          <GoogleInternalForm />
        </Form>
      </HideIfCommercial>
      <ShowIfCommercial>
        <GoogleCommercial />
      </ShowIfCommercial>
    </>
  )
}
