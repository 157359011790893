import { matchPath } from 'react-router-dom'
import {
  OnboardingCheckpointCategory,
  OnboardingCheckpointInterface,
  OnboardingCheckpointStateId,
} from '@src/interfaces/onboardingChecklistV2'
import { firstStepsCategories } from './constants'
import { history } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'

export const getFirstStepsCheckpoints = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => checkpoints.filter(checkpoint => firstStepsCategories.includes(checkpoint.category))

export const getNextStepsCheckpoints = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => checkpoints.filter(checkpoint => !firstStepsCategories.includes(checkpoint.category))

export const checkIfFirstStepsCompleted = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => {
  const paymentMethodCheckpoint = checkpoints.find(
    checkpoint => checkpoint.category === 'paymentMethod',
  )
  if (paymentMethodCheckpoint && paymentMethodCheckpoint.state.id !== 'completed') {
    return false
  }
  return getFirstStepsCheckpoints(checkpoints).every(
    checkpoint => checkpoint.state.id === 'completed',
  )
}

const countCheckpointsByState = (
  checkpoints: OnboardingCheckpointInterface[] = [],
  stateId: OnboardingCheckpointStateId,
) => checkpoints.filter(checkpoint => checkpoint.state.id === stateId).length

export const getCompletedCheckpointsNum = (
  checkpoints: OnboardingCheckpointInterface[] = [],
) => countCheckpointsByState(checkpoints, 'completed')

export const isOnboardingPath = () =>
  !!matchPath(history.location.pathname, [ROUTES.ONBOARDING_CHECKLIST_V2.ANY])

export const isCheckpointDisabled = (
  category: OnboardingCheckpointCategory,
  checkpoints: OnboardingCheckpointInterface[],
) => {
  const isCompleted = (checkpointCategory: OnboardingCheckpointCategory) =>
    checkpoints.find(c => c.category === checkpointCategory)?.state.id === 'completed'

  const hasPaymentMethodCheckpoint = checkpoints.find(
    checkpoint => checkpoint.category === 'paymentMethod',
  )

  if (
    (category === 'importEmployees' || category === 'teams' || category === 'roles') &&
    hasPaymentMethodCheckpoint &&
    !isCompleted('paymentMethod')
  ) {
    return true
  }
  if (category === 'teams') {
    return !isCompleted('importEmployees')
  }
  if (category === 'roles') {
    return !isCompleted('importEmployees') || !isCompleted('teams')
  }
  return false
}
