import React from 'react'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { HStack, Icon, TextButton, Text } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'

interface Props {
  cycleId?: number | string
}

export const EmptyRoadmapsTable = ({ cycleId }: Props) => {
  const permissions = useSelector(selectPermissions)
  const canAddRoadmaps = [
    PermissionTypes.CanAddCompanyRoadmap,
    PermissionTypes.CanAddDepartmentRoadmap,
    PermissionTypes.CanAddTeamRoadmap,
  ].some(permission => permissions.includes(permission))

  return (
    <EmptyTableRaw
      title={
        <FormattedMessage
          defaultMessage="Your roadmap goes here"
          id="performance.roadmaps.emptyTable.title"
        />
      }
      description={
        <FormattedMessage
          defaultMessage="All your epics will appear here"
          id="performance.roadmaps.emptyTable.description"
        />
      }
      action={
        canAddRoadmaps ? (
          <TextButton
            onClick={() =>
              navigateTo(ROUTES.FORMS.ROADMAP.SELECT_LEVEL, {
                roadmapSelectCycleID: cycleId,
              })
            }
          >
            <HStack space="s-8">
              <Icon name="Plus" size={16} />
              <Text>
                <FormattedMessage
                  id="performance.roadmaps.create"
                  defaultMessage="Create roadmap"
                />
              </Text>
            </HStack>
          </TextButton>
        ) : null
      }
    />
  )
}
