import React from 'react'

import { Box, Popup, Token } from '@revolut/ui-kit'

export const LookerPreviewPopup = ({
  url,
  open,
  onClose,
}: {
  url: URL
  open: boolean
  onClose: () => void
}) => {
  return (
    <Popup open={open} size="lg" onClose={onClose} shouldKeepMaxHeight>
      <Box
        height="100%"
        width="100%"
        border={`1px solid ${Token.color.greyTone20}`}
        borderRadius={Token.radius.r16}
      >
        <iframe
          src={url.toString()}
          height="100%"
          width="100%"
          title="Looker preview"
          style={{
            border: 0,
            borderRadius: Token.radius.r16,
          }}
        />
      </Box>
    </Popup>
  )
}
