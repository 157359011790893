import { filterSortPageIntoQuery } from '../utils/table'
import { api } from './index'
import { API } from '../constants/api'
import { FetchDataQueryInterface } from '../interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '../interfaces'
import { AuditInterface } from '../interfaces/audit'
import { EntityTypes } from '@src/constants/api'

const categoryApi = {
  [EntityTypes.company]: API.AUDIT,
  [EntityTypes.department]: API.DEPARTMENT,
  [EntityTypes.employee]: API.EMPLOYEES,
  [EntityTypes.team]: API.TEAMS,
}

const getAuditUrl = (category: EntityTypes, id?: number) => {
  if (category === EntityTypes.company) {
    return categoryApi[category]
  }

  /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
  return `${categoryApi[category]}/${id}/audit`
}

export const getAuditTickets =
  (category: EntityTypes, id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${getAuditUrl(category, id)}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getStaticCounts =
  (category: EntityTypes, id?: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<AuditInterface>> =>
    api.get(`${getAuditUrl(category, id)}/counts`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })
