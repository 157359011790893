import React from 'react'

export const locationSetupGuide = [
  {
    question: 'How to setup Locations?',
    answer: (
      <ul>
        <li>
          Title Locations functionality in a Revolut People plays a critical role in
          controlling the hiring process and managing employee policies effectively,
          especially for organizations with multiple physical sites or offices.
        </li>
      </ul>
    ),
  },
]
