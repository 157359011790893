import { Color, Token } from '@revolut/ui-kit'
import { EmployeeInterface, NameIdInterface } from '@src/interfaces/employees'
import { PromotionNominationInterface } from '@src/interfaces/promotions'
import { IdAndName, Statuses } from '@src/interfaces'
import startCase from 'lodash/startCase'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  KeeperGrade,
  KeeperGradeToString,
  RankGrade,
  RankGradeToString,
} from '@src/interfaces/performance'
import { RadioSelectOption } from '@components/Inputs/RadioSelectInput/RadioSelectInput'

export type PromotionParams = { id: string; employeeId: string; stageId: string }

export interface PromotionCommonProps {
  employee: EmployeeInterface
  nominationId?: string
  editMode?: boolean
}

export const getPromotionStatusTextAndColor = (
  nomination: PromotionNominationInterface,
): { status: string; statusColor: Color } => {
  if (nomination.status === Statuses.in_review) {
    return { status: 'In Review', statusColor: 'warning' }
  }
  let color: Color
  switch (nomination.status) {
    case Statuses.cancelled:
    case Statuses.rejected:
      color = 'error'
      break
    case Statuses.completed:
      color = 'success'
      break
    default:
      color = Token.color.foreground
      break
  }
  return { status: startCase(nomination.status), statusColor: color }
}

export const getTargetSeniorityAndSpecialisation = (
  nomination: PromotionNominationInterface,
): {
  targetSeniority: SeniorityInterface
  targetSpecialisation: OptionInterface | NameIdInterface
} => {
  return {
    targetSeniority: nomination.target_seniority || nomination.initial_seniority,
    targetSpecialisation:
      nomination.target_specialisation || nomination.initial_specialisation,
  }
}

export const answerOptions: RadioSelectOption<IdAndName<KeeperGrade>>[] = [
  KeeperGrade.No,
  KeeperGrade.ProbablyNo,
  KeeperGrade.Yes,
  KeeperGrade.StrongYes,
].map(grade => ({
  value: { id: grade, name: KeeperGradeToString[grade] },
  label: KeeperGradeToString[grade],
}))

export const answerRankOptions: RadioSelectOption<IdAndName<RankGrade>>[] = [
  RankGrade.Top_10,
  RankGrade.Top_25,
  RankGrade.Top_50,
  RankGrade.Bottom_50,
].map(grade => ({
  value: { id: grade, name: RankGradeToString[grade] },
  label: RankGradeToString[grade],
}))
