import React, { ReactChild, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { CellTypes } from '@src/interfaces/data'
import get from 'lodash/get'
import { format } from 'date-fns'
import { Box, BoxProps, Token } from '@revolut/ui-kit'
import { bulkNormalizeWidth } from '@src/utils/table'
import { TableTypes } from '@src/interfaces/table'
import { defaultTheme } from '@src/styles/theme'

export interface MatrixCellInsertParams<T> {
  data: T
  children: string
  index: number
}

export interface MatrixColumnCellInterface<T> {
  dataPoint?: string
  insert?: (params: MatrixCellInsertParams<T>) => ReactChild
  idPoint?: string
  type: CellTypes
  title: string | ReactNode
  width: number
  background?: (data: T) => string | null
  headerCentered?: boolean
  noHover?: boolean
}

export type MatrixColumnInterface<T> = Omit<MatrixColumnCellInterface<T>, 'width'>

export interface MatrixRowInterface<T> {
  cells: MatrixColumnCellInterface<T>[]
  highlight?: (data: T) => string
}

export interface MatrixTableProps<T> extends Omit<BoxProps, 'rows'> {
  rows: MatrixRowInterface<T>
  data: T[]
  disabled?: boolean
  noPadding?: boolean
  smallHeaderPadding?: boolean
  noHover?: boolean
  noHeader?: boolean
  autoResize?: boolean
  noDataMessage?: string
  width?: number
  noBorder?: boolean
  onRowClick?: (data: T) => void
}

const Table = styled.div<{
  noBorder?: boolean
}>`
  position: relative;
  border-collapse: collapse;
  max-height: 100%;
  min-height: 100%;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  border-width: ${props => {
    if (props.noBorder) {
      return 0
    }

    return '1px'
  }};
  border-style: solid;
  border-color: ${Token.color.greyTone20};
  border-radius: 12px;
  overflow: hidden;
`

const TableHeader = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex: 0 0 auto;
  margin-bottom: -1px;
  overflow: hidden;
`

const HeaderCell = styled.div<{
  width?: number
  disabled?: boolean
  centered?: boolean
  smallHeaderPadding?: boolean
}>`
  text-transform: uppercase;
  position: relative;
  text-align: ${props => (props.centered ? 'center' : 'left')};
  font-size: 11px;
  line-height: 18px;
  white-space: nowrap;
  font-weight: 500;
  width: ${props => props.width}px;
  color: ${Token.color.greyTone50};
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: ${Token.color.greyTone5};
  border: 1px solid transparent;
  padding: ${props => (props.smallHeaderPadding ? '12px 2px' : '12px')};
`

const CellCoverHoverCss = css<{ noPadding?: boolean }>`
  &:hover {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    flex-grow: 1;
    display: grid;
    align-items: center;
    background-color: inherit;
    overflow: visible;
    z-index: ${defaultTheme.zIndex.aboveMain};
    padding: 0 ${props => (props.noPadding ? 0 : 12)}px;
  }
`

const Cell = styled.div<{
  width?: number
  background?: string | null
  noPadding?: boolean
}>`
  position: relative;
  text-align: left;
  font-size: 14px;
  line-height: 18px;
  width: ${props => props.width}px;
  color: ${Token.color.foreground};
  background-color: ${props => props.background || Token.color.background};
  box-sizing: border-box;
  min-height: 50px;
  display: flex;
  align-items: center;
  padding: 0 ${props => (props.noPadding ? 0 : 12)}px;
`

const CellCover = styled.span<{
  noPadding?: boolean
  noHover?: boolean
}>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100%;
  ${props => !props.noHover && CellCoverHoverCss}
`

const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -1px;
`
const TableRow = styled.div<{
  noBorder?: boolean
}>`
  display: flex;
  margin-bottom: -1px;
  position: relative;
  border-width: ${props => {
    if (props.noBorder) {
      return 0
    }

    return '1px 0'
  }};
  border-style: solid;
  border-color: ${Token.color.greyTone20};
`

const MatrixTable = <T extends {}>({
  rows,
  data,
  disabled,
  noPadding,
  smallHeaderPadding = false,
  noHeader,
  autoResize,
  noDataMessage,
  width,
  noBorder,
  onRowClick,
  ...props
}: MatrixTableProps<T>) => {
  const cellsAdjustedWidths = autoResize
    ? (bulkNormalizeWidth(
        rows.cells,
        TableTypes.Matrix,
        width,
      ) as MatrixColumnCellInterface<T>[])
    : rows.cells

  return (
    <Box {...props}>
      <Table noBorder={noBorder}>
        {!noHeader && (
          <TableHeader>
            {cellsAdjustedWidths.map((column, i) => (
              <HeaderCell
                key={i}
                disabled={disabled}
                width={column.width}
                centered={column.headerCentered}
                data-testid={`header-cell_${i}`}
                smallHeaderPadding={smallHeaderPadding}
              >
                {column.title}
              </HeaderCell>
            ))}
          </TableHeader>
        )}
        <TableBody>
          {(!data || !data.length) && noDataMessage && (
            <TableRow noBorder={noBorder} data-testid="row_no_data">
              <Cell
                width={cellsAdjustedWidths.reduce((acc, cell) => {
                  return acc + cell.width
                }, 0)}
                background={Token.color.greyTone2}
              >
                {noDataMessage}
              </Cell>
            </TableRow>
          )}
          {data.map((target, index) => (
            <TableRow
              key={index}
              noBorder={noBorder}
              onClick={() => onRowClick && onRowClick(target)}
              data-testid={`row_${index}`}
            >
              {cellsAdjustedWidths.map((column, i) => {
                let content

                if (column.dataPoint) {
                  content = get(target, column.dataPoint)
                }
                if (column.type === CellTypes.insert) {
                  content = column.insert!({
                    data: target,
                    children: content,
                    index,
                  })
                }
                if (column.type === CellTypes.date) {
                  content = format(new Date(content), 'd MMM yyyy')
                }
                return (
                  <Cell
                    key={i}
                    width={column.width}
                    background={column.background && column.background(target)}
                    noPadding={noPadding}
                    data-testid={`cell_${index}_${i}`}
                  >
                    <CellCover noPadding={noPadding} noHover={column.noHover}>
                      {content}
                    </CellCover>
                  </Cell>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

/** @deprecated Legacy component, don't use for the new features. Use Table instead */
export default MatrixTable
