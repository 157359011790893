import React, { useState } from 'react'
import { Button, useStatusPopup, StatusPopup, chain, Grid } from '@revolut/ui-kit'
import { useIntl } from 'react-intl'

import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageActions } from '@src/components/Page/PageActions'
import { goBack, navigateTo } from '@src/actions/RouterActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ConfirmationDialogInterface } from '@src/features/Popups/ConfirmationDialog'
import {
  OnboardingChecklistSectionConfig,
  useNextConfig,
} from '@src/pages/OnboardingChecklist/common/constants'
import {
  completeOnboardingCheckpointCategory,
  updateOnboardingCheckpointCategoryCurrentStep,
  useInvalidateOnboardingCheckpoints,
} from '@src/api/onboardingChecklist'
import { getStringMessageFromError } from '@src/store/notifications/actions'

type OnboardingActionsProps = {
  config: OnboardingChecklistSectionConfig
  confirmationDialogue?: Partial<ConfirmationDialogInterface>
  currentStep: string
  disableBack: boolean
  disableNext: boolean
  isForm: boolean
  isLastStep: boolean
  isLastCheckpoint?: boolean
  nextRoute: string
  pendingBack: boolean
  pendingNext: boolean
  previousRoute: string
  updateSteps: boolean
  onBeforeSubmit?: () => Promise<unknown>
  nextButtonLabel?: string
}

const OnboardingActions = ({
  config,
  confirmationDialogue,
  currentStep,
  disableBack,
  disableNext,
  isForm,
  isLastStep,
  isLastCheckpoint,
  nextRoute,
  pendingBack,
  pendingNext,
  previousRoute,
  updateSteps,
  onBeforeSubmit,
  nextButtonLabel = 'Next',
}: OnboardingActionsProps) => {
  const nextConfig = useNextConfig(config)

  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()
  const navigateToPath = (path: string | null) => {
    navigateTo(pathToUrl(path ?? ROUTES.ONBOARDING_CHECKLIST.ALL))
  }
  const invalidateOnboardingCheckpoints = useInvalidateOnboardingCheckpoints()

  const showSuccess = () => {
    statusPopup.show(
      <StatusPopup variant="success-result" onClose={statusPopup.hide}>
        <StatusPopup.Title>Completed</StatusPopup.Title>
        <StatusPopup.Description>{config.popup.description}</StatusPopup.Description>
        <StatusPopup.Actions>
          {nextConfig ? (
            <Button
              elevated
              onClick={() => {
                statusPopup.hide()
                navigateToPath(nextConfig.path)
              }}
              data-event-key="onboarding.general.nextOnboardingStepButton"
            >
              {chain(
                formatMessage({
                  id: 'onboarding.general.nextOnboardingStepButton',
                  defaultMessage: 'Next',
                }),
                nextConfig.title,
              )}
            </Button>
          ) : null}
          <Button
            variant="secondary"
            onClick={() => {
              statusPopup.hide()
              navigateTo(ROUTES.ONBOARDING_CHECKLIST.ALL)
            }}
          >
            {formatMessage({
              id: 'onboarding.general.viewOnboardingChecklistButton',
              defaultMessage: 'View Onboarding Checklist',
            })}
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>,
    )
  }

  const navigateToNext = async () => {
    setLoading(true)
    try {
      if (updateSteps) {
        await updateOnboardingCheckpointCategoryCurrentStep(config.category, currentStep)
      }
      if (onBeforeSubmit) {
        await onBeforeSubmit()
      }
      if (isLastStep) {
        await completeOnboardingCheckpointCategory(config.category)

        if (isLastCheckpoint) {
          navigateToPath(ROUTES.ONBOARDING_CHECKLIST.WELCOME)
        } else {
          showSuccess()
        }
      } else {
        navigateToPath(nextRoute ?? nextConfig?.path)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      if (updateSteps || isLastStep) {
        invalidateOnboardingCheckpoints()
      }
    }
  }

  return (
    <PageActions>
      <Grid columns={2} gap="s-8" maxWidth={375}>
        <Button
          variant="secondary"
          onClick={() => {
            if (!previousRoute) {
              goBack(ROUTES.ONBOARDING_CHECKLIST.ALL)
            } else {
              navigateToPath(previousRoute)
            }
          }}
          disabled={disableBack}
          pending={pendingBack || loading}
        >
          {formatMessage({
            id: 'onboarding.general.previousStepButton',
            defaultMessage: 'Back',
          })}
        </Button>
        {isForm ? (
          <NewSaveButtonWithPopup
            noPopup
            onAfterSubmit={navigateToNext}
            hideWhenNoChanges={false}
            disabled={disableNext}
            pending={pendingNext || loading}
            confirmationDialogue={confirmationDialogue}
            useValidator
            data-event-key="onboarding.general.nextOnboardingStepButton"
          >
            {nextButtonLabel}
          </NewSaveButtonWithPopup>
        ) : (
          <Button
            onClick={navigateToNext}
            disabled={disableNext}
            pending={pendingNext || loading}
            data-event-key="onboarding.general.nextOnboardingStepButton"
          >
            {nextButtonLabel}
          </Button>
        )}
      </Grid>
    </PageActions>
  )
}

export default OnboardingActions
