import React, { useState } from 'react'
import { Box, Flex, Item, VStack } from '@revolut/ui-kit'
import { CandidateSettingsInterface } from '@src/interfaces/settings'
import { BooleanRadioSwitch } from '@src/pages/OnboardingChecklist/components/RadioSwitch'
import { PermissionTypes } from '@src/store/auth/types'
import { Recommended } from '@src/pages/OnboardingChecklist/components/Recommended'
import { selectPermissions } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useSelector } from 'react-redux'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'

const CandidatesFreezingPeriod = () => {
  const { values } = useLapeContext<CandidateSettingsInterface>()
  const [state, setState] = useState(values.freezing_period_months !== null)
  const permissions = useSelector(selectPermissions)
  const disableEdit = !permissions.includes(PermissionTypes.ChangeCandidatePreferences)
  return (
    <VStack gap="s-16">
      <SectionTitle
        title="How much time needs to pass before a candidate can reapply for the same position?"
        subtitle="The freezing period is the duration after which a previously unsuccessful candidate can reapply for the same specialisation. A new interview round will be initiated for any subsequent attempt once this freezing period has elapsed."
      />
      <BooleanRadioSwitch
        value={state}
        onChange={setState}
        disabled={disableEdit}
        variant="horizontal"
        yesLabel={
          <Flex>
            Yes
            <Recommended />
          </Flex>
        }
        yesDescription="I want to set up a duration after which a candidate, previously unsuccessful in an attempt, can apply again for the same specialisation"
        noDescription="I do not want to setup freezing period"
      />
      {state && (
        <Item use="label">
          <Item.Content>
            <Item.Title>Define the Freezing Period</Item.Title>
            <Item.Description>
              A new interview round will be initiated for any subsequent application once
              the period has elapsed.
            </Item.Description>
            <Box mt="s-16">
              <LapeNewInput
                type="number"
                label="Enter number of months"
                name="freezing_period_months"
                required
                disabled={disableEdit}
              />
            </Box>
          </Item.Content>
        </Item>
      )}
    </VStack>
  )
}

export default CandidatesFreezingPeriod
