import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { candidatesConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { CandidatesIntro } from './CandidatesIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { CandidatesImport } from '@src/pages/OnboardingChecklistV2/Candidates/CandidatesImport'
import { Route, Switch } from 'react-router-dom'
import { CandidatesFlow } from '@src/pages/OnboardingChecklistV2/Candidates/CandidatesFlow'
import { NewCandidateForm } from '@src/pages/OnboardingChecklistV2/Candidates/NewCandidateForm'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.INTRO,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesIntro,
  },
  {
    title: 'Import',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.IMPORT,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.IMPORT,
    canView: [PermissionTypes.ViewCandidatePreferences],
    component: CandidatesImport,
    isWide: true,
  },
]

export const Candidates = () => {
  return (
    <Switch>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.UPLOAD.ANY}>
        <CandidatesFlow />
      </Route>
      <Route path={ROUTES.ONBOARDING_CHECKLIST_V2.CANDIDATES.NEW}>
        <NewCandidateForm />
      </Route>
      <Route>
        <OnboardingChecklistContent config={candidatesConfig} routes={routes} />
      </Route>
    </Switch>
  )
}
