import React from 'react'
import { useSelector } from 'react-redux'
import { Plus } from '@revolut/icons'

import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import {
  employeeEmailTemplateCategoryColumn,
  employeeEmailTemplateCreatedDateColumn,
  employeeEmailTemplateNameColumn,
  employeeEmailTemplateOwnerColumn,
  employeeEmailTemplateStatusColumn,
  employeeEmailTemplateSubjectColumn,
} from '@src/constants/columns/employeeEmailTemplate'
import { EmployeeEmailTemplateInterface } from '@src/interfaces/employeeEmails'
import { getEmployeeEmailTemplates } from '@src/api/employeeEmails'
import { Statuses } from '@src/interfaces'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const ROW: RowInterface<EmployeeEmailTemplateInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.PREVIEW, { id })),
  cells: [
    {
      ...employeeEmailTemplateNameColumn,
      width: 410,
    },
    {
      ...employeeEmailTemplateSubjectColumn,
      width: 400,
    },
    {
      ...employeeEmailTemplateOwnerColumn,
      width: 250,
    },
    {
      ...employeeEmailTemplateCategoryColumn,
      width: 250,
    },
    {
      ...employeeEmailTemplateCreatedDateColumn,
      width: 150,
    },
    {
      ...employeeEmailTemplateStatusColumn,
      width: 150,
    },
  ],
}

export const EmployeeEmailTemplates = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddEmployeeEmailTemplate)

  const table = useTable({ getItems: getEmployeeEmailTemplates }, [
    {
      columnName: 'status',
      filters: [{ id: Statuses.active, name: Statuses.active }],
      nonResettable: true,
    },
  ])

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd ? (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.GENERAL)}
              useIcon={Plus}
            >
              Create new
            </PrimaryAction>
          ) : null}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>

      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.EmployeeEmailTemplates}
          useWindowScroll
          row={ROW}
          {...table}
          emptyState={<EmptyTableRaw title="Email templates will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}
