import React from 'react'
import ScorecardGeneral from '@components/ScorecardGeneral/ScorecardGeneral'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  ReviewScorecardViewInterface,
} from '@src/interfaces/performance'
import { getCardsForView } from '@src/utils/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'

interface Props {
  category: ReviewCategory
}

const Skills = ({ category }: Props) => {
  const { values } = useLapeContext<ReviewScorecardViewInterface>()

  const initialType = values.summary?.manager_values
    ? PerformanceReviewTypes.managerValuesSkills
    : PerformanceReviewTypes.skills

  const cards = getCardsForView(values?.summary?.functional_skills?.cards || [])
  const managerValuesCards = values?.summary?.manager_values?.cards || []

  if (!cards) {
    return null
  }

  return (
    <ScorecardGeneral
      cards={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? managerValuesCards
          : cards
      }
      type={initialType}
      rating={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? values.summary.manager_values?.rating
          : values.summary.functional_skills?.rating
      }
      justifications={
        initialType === PerformanceReviewTypes.managerValuesSkills
          ? values.summary.manager_values?.skipped_section_justifications
          : values.summary.functional_skills?.skipped_section_justifications
      }
      title="Skills"
      category={category}
      viewMode
    />
  )
}

export default Skills
