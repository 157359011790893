import React, { useEffect, useState } from 'react'
import PageLoading from '@components/PageLoading/PageLoading'
import { useQuery } from '@src/utils/queryParamsHooks'
import { validateInvitedEmployeeLink } from '@src/api/reviewCycles'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { ErrorWidget, Flex } from '@revolut/ui-kit'

export const SandboxPage = () => {
  const [pending, setPending] = useState(true)
  const [errorMsg, setErrorMsg] = useState(true)
  const { query } = useQuery()

  useEffect(() => {
    validateInvitedEmployeeLink(query.invite)
      .then(() => {
        navigateTo(pathToUrl(ROUTES.MAIN))
      })
      .catch(err => {
        const errText =
          err.response?.data?.detail ||
          err.response?.data?.[0] ||
          'Could not validate invite'
        setErrorMsg(errText)
        setPending(false)
      })
  }, [query.invite])

  return pending ? (
    <PageLoading />
  ) : (
    <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
      <ErrorWidget>
        <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
        <ErrorWidget.Title>{errorMsg}</ErrorWidget.Title>
        <ErrorWidget.Action onClick={() => navigateTo(ROUTES.MAIN)}>
          Go to Dashboard
        </ErrorWidget.Action>
      </ErrorWidget>
    </Flex>
  )
}
