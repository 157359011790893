import React from 'react'
import {
  useTooltip,
  HStack,
  Text,
  Spinner,
  IconButton,
  copyToClipboard,
  Tooltip,
  Token,
} from '@revolut/ui-kit'
import { Copy } from '@revolut/icons'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { ImportInterface } from '@src/interfaces/bulkDataImport'

interface ImportStateProps<T> {
  data: ImportInterface<T>
}

export const ImportState = <T,>({ data }: ImportStateProps<T>) => {
  const copyTooltip = useTooltip()
  const errorTooltip = useTooltip()

  if (!data.state) {
    return <>-</>
  }

  switch (data.state.id) {
    case 'pending':
      return (
        <HStack gap="s-8" align="center">
          <Text fontWeight={500} color={Token.color.greyTone50}>
            {data.state.name}
          </Text>
          <Spinner color="blue" size={14} />
        </HStack>
      )
    case 'success':
      return (
        <Text fontWeight={500} color={Token.color.green}>
          {data.state.name}
        </Text>
      )
    case 'failure':
      return (
        <HStack gap="s-8" align="center">
          <Text
            fontWeight={500}
            color={Token.color.red}
            {...errorTooltip.getAnchorProps()}
          >
            {data.state.name}
          </Text>
          <Tooltip {...errorTooltip.getTargetProps({ placement: 'top' })}>
            {data.error_message}
          </Tooltip>
          {data.error_message ? (
            <>
              <IconButton
                useIcon={Copy}
                size={14}
                aria-label="Copy"
                color={Token.color.blue}
                onClick={e => {
                  e.stopPropagation()
                  copyToClipboard(data.error_message!).then(() => {
                    pushNotification({
                      value: 'Copied to clipboard',
                      duration: SUCCESS_DEFAULT_DURATION,
                      type: NotificationTypes.success,
                    })
                  })
                }}
                {...copyTooltip.getAnchorProps()}
              />
              <Tooltip {...copyTooltip.getTargetProps({ placement: 'top' })}>
                Copy error details
              </Tooltip>
            </>
          ) : null}
        </HStack>
      )
    default:
      return <Text fontWeight={500}>{data.state.name}</Text>
  }
}
