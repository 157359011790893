import React, { useState } from 'react'
import {
  Button,
  Cell,
  InputGroup,
  Header,
  Popup,
  Radio,
  RadioGroup,
} from '@revolut/ui-kit'
import { scheduleShiftsRequests } from '@src/api/attendance'
import Form from '@src/features/Form/Form'
import { LargeWeeklyCalendarEventInterface } from '@src/components/LargeWeeklyCalendar'
import { EventForm } from './EventForm'

export type EventTypes = 'shifts' | 'breaks'
type Props = {
  employeeId: number
  onAfterSubmit: VoidFunction
  onClose: VoidFunction
  selectedEvent: LargeWeeklyCalendarEventInterface
}

export const AddEventPopup = ({
  employeeId,
  onAfterSubmit,
  onClose,
  selectedEvent,
}: Props) => {
  const isExistingForm = !!selectedEvent.id
  const [eventPopupOpen, setEventPopupOpen] = useState(false)
  const [eventType, setEventType] = useState<EventTypes | undefined>(
    selectedEvent.calendarId === 'work'
      ? 'shifts'
      : selectedEvent.calendarId === 'break'
      ? 'breaks'
      : undefined,
  )

  return (
    <>
      <Popup open={!isExistingForm} onClose={onClose} variant="bottom-sheet">
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>What event would you like to add?</Header.Title>
        </Header>
        <InputGroup>
          <RadioGroup
            onChange={value => {
              if (value) {
                setEventType(value)
              }
            }}
            value={eventType}
          >
            {group => (
              <>
                <Cell>
                  <Radio {...group.getInputProps({ value: 'shifts' })} defaultChecked>
                    <Radio.Label>Create a shift</Radio.Label>
                  </Radio>
                </Cell>
                <Cell>
                  <Radio {...group.getInputProps({ value: 'breaks' })}>
                    <Radio.Label>Create a break</Radio.Label>
                  </Radio>
                </Cell>
              </>
            )}
          </RadioGroup>
        </InputGroup>
        <Popup.Actions horizontal>
          <Button onClick={onClose} variant="secondary">
            Cancel
          </Button>
          <Button disabled={!eventType} elevated onClick={() => setEventPopupOpen(true)}>
            Next
          </Button>
        </Popup.Actions>
      </Popup>

      <Popup
        open={isExistingForm || eventPopupOpen}
        onClose={() => {
          setEventPopupOpen(false)
          onClose()
        }}
      >
        <Header>
          <Header.Title>{isExistingForm ? 'Edit' : 'Create'} event</Header.Title>
        </Header>
        <Form
          api={scheduleShiftsRequests(eventType!)}
          disableLocalStorageCaching
          forceParams={{
            employeeId: String(employeeId),
            id: isExistingForm ? selectedEvent.id : undefined,
          }}
        >
          <EventForm
            calendarEvent={selectedEvent}
            employeeId={employeeId}
            eventType={eventType!}
            onAfterSubmit={onAfterSubmit}
            onCancel={() => {
              setEventPopupOpen(false)
              onClose()
            }}
          />
        </Form>
      </Popup>
    </>
  )
}
