import React from 'react'
import {
  ActionButton,
  MoreBar,
  MoreBarActionProps,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'

interface Props extends MoreBarActionProps {
  children: React.ReactNode
  useMoreBar?: boolean
  tooltipContent?: React.ReactNode
}

const SwitchButton = ({
  children,
  useMoreBar = true,
  checked,
  tooltipContent,
  ...props
}: Props) => {
  const tooltip = useTooltip()
  const Component: React.ElementType = useMoreBar ? MoreBar.Action : ActionButton
  return tooltipContent ? (
    <>
      <Component
        {...tooltip.getAnchorProps()}
        size="sm"
        variant="secondary"
        useIcon={checked ? 'SwitchOn' : 'SwitchOff'}
        {...props}
      >
        {children}
      </Component>
      <Tooltip {...tooltip.getTargetProps()}>{tooltipContent}</Tooltip>
    </>
  ) : (
    <Component
      size="sm"
      variant="secondary"
      useIcon={checked ? 'SwitchOn' : 'SwitchOff'}
      {...props}
    >
      {children}
    </Component>
  )
}

export default SwitchButton
