import React, { useState } from 'react'
import styled from 'styled-components'
import {
  DeliverableJustificationValue,
  SkillCardInterface,
  ValueBasedCardInterface,
} from '@src/interfaces/performance'
import { A } from '@components/CommonSC/General'
import { Popup, Token } from '@revolut/ui-kit'
import CommentsPopupHeader from '@components/ColumnInserts/Justification/CommentsPopupHeader'
import { Box, Cell, IconButton } from '@revolut/ui-kit'

interface Props {
  data: SkillCardInterface | ValueBasedCardInterface
}

const Comment = styled.div`
  line-height: 22px;
  white-space: pre-wrap;
`

const Justification = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  if (
    !(
      data.justification ||
      (data?.justifications as DeliverableJustificationValue[])?.some(o => !!o.comment)
    )
  ) {
    return null
  }

  return (
    <Box width={20}>
      <IconButton
        onClick={() => {
          setIsOpen(true)
        }}
        useIcon="Message"
        size={14}
        color={Token.color.greyTone50}
      />
      <Popup
        open={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        variant="bottom-sheet"
      >
        {data.name && <CommentsPopupHeader name={data.name} rating={data.rating} />}
        <Cell mb="s-20">
          {data.justification ? (
            <Comment>{data.justification}</Comment>
          ) : (
            (data.justifications as DeliverableJustificationValue[])?.map(
              (justification, i) => (
                <Comment key={i}>
                  {justification.reference_url && (
                    <>
                      <A href={justification.reference_url} target="_blank">
                        Link {i + 1}
                      </A>
                      {': '}
                    </>
                  )}
                  {justification.comment}
                </Comment>
              ),
            )
          )}
        </Cell>
      </Popup>
    </Box>
  )
}

export default Justification
