import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { InviteEmployeesEmailList } from './InviteEmployeesEmailList'
import { InviteEmployeesReviewTable } from './InviteEmployeesReviewTable'

interface InviteEmployeesProps {
  assignAdminGroup: boolean
  emailListRoute: string
  reviewTableRoute: string
  closeRoute: string
  isCompletingOnboarding: boolean
}

export const InviteEmployees = ({
  emailListRoute,
  reviewTableRoute,
  assignAdminGroup,
  closeRoute,
  isCompletingOnboarding,
}: InviteEmployeesProps) => {
  return (
    <Switch>
      <Route exact path={emailListRoute}>
        <InviteEmployeesEmailList
          nextRoute={reviewTableRoute}
          assignAdminGroup={assignAdminGroup}
          isCompletingOnboarding={isCompletingOnboarding}
        />
      </Route>
      <Route exact path={reviewTableRoute}>
        <InviteEmployeesReviewTable
          emailListRoute={emailListRoute}
          closeRoute={closeRoute}
          isCompletingOnboarding={isCompletingOnboarding}
        />
      </Route>
    </Switch>
  )
}
