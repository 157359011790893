import React, { useRef, useState } from 'react'
import {
  Avatar,
  AvatarProps,
  AvatarSkeleton,
  Box,
  DetailsCell,
  Flex,
  LineClamp,
  Placeholder,
  Text,
  TextButton,
  useIntersectViewport,
  Widget,
} from '@revolut/ui-kit'
import { Pencil } from '@revolut/icons'

import Tooltip from '@components/Tooltip/Tooltip'
import { useGetEmployeeBadges } from '@src/api/employees'
import { EmployeeInterface } from '@src/interfaces/employees'
import { canAssignLabel } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

type Props = {
  data: EmployeeInterface
}
export const Badges = ({ data }: Props) => {
  const ref = useRef(null)
  const canAssignLabels = useSelector(canAssignLabel)

  const [loadBadges, setLoadBadges] = useState(false)
  const badges = useGetEmployeeBadges(loadBadges ? data.id : undefined)

  useIntersectViewport(ref, isIntersecting => {
    if (isIntersecting) {
      setLoadBadges(true)
    }
  })

  const isLoading = badges.status === 'loading'
  const isEmpty = loadBadges && !badges.data?.count
  const avatarProps = { size: 56, mb: 's-8' } as AvatarProps

  const header = (
    <DetailsCell>
      <DetailsCell.Title>Badges</DetailsCell.Title>
      {canAssignLabels && (
        <DetailsCell.Content>
          <TextButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.EMPLOYEE.LABELS, { id: String(data.id) })}
          >
            Edit
          </TextButton>
        </DetailsCell.Content>
      )}
    </DetailsCell>
  )

  const emptyStatePlaceholder = (
    <Widget>
      {header}
      <Placeholder>
        <Placeholder.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D109.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D109@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D109@3x.png',
          }}
        />
        <Placeholder.Title>No badges here yet, do you want to edit?</Placeholder.Title>
        <Placeholder.Action
          use={InternalLink}
          to={pathToUrl(ROUTES.FORMS.EMPLOYEE.LABELS, { id: String(data.id) })}
          useIcon={Pencil}
        >
          Edit
        </Placeholder.Action>
      </Placeholder>
    </Widget>
  )

  if (!isLoading && isEmpty) {
    return canAssignLabels ? emptyStatePlaceholder : null
  }
  return (
    <Widget ref={ref}>
      {header}
      <Box p="s-16" pt="s-12">
        <Flex gap="s-16" flexWrap="wrap">
          {isLoading ? (
            <>
              <AvatarSkeleton {...avatarProps} />
              <AvatarSkeleton {...avatarProps} />
              <AvatarSkeleton {...avatarProps} />
            </>
          ) : (
            badges.data?.results.map(badge => (
              <Box key={badge.id} data-testid={`badge-${badge.name}`}>
                <Tooltip text={badge.name} placement="bottom">
                  <Flex flexDirection="column" alignItems="center">
                    <Avatar image={badge.employee_badge.url} {...avatarProps} />
                    <Box maxWidth="s-72" style={{ textAlign: 'center' }}>
                      <LineClamp max={2}>
                        <Text fontSize="small">{badge.name}</Text>
                      </LineClamp>
                    </Box>
                  </Flex>
                </Tooltip>
              </Box>
            ))
          )}
        </Flex>
      </Box>
    </Widget>
  )
}
