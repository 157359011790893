import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { GetRequestInterface } from '@src/interfaces'
import { api } from '@src/api/index'
import { API } from '@src/constants/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import {
  MeetingTrackerCategory,
  MeetingTrackerInterface,
} from '@src/interfaces/meetingsTracker'

export const getUserMeetings = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<MeetingTrackerInterface>> =>
  api.get(`${API.MEETINGS_TRACKER}`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const updateMeetingCategory = (data: {
  category: MeetingTrackerCategory | null
  id?: number
  parent_event_id?: string
}) => api.patch(`${API.MEETINGS_TRACKER}/update`, data)

export const addMeetingRate = (data: {
  rating: number
  id?: number
  parent_event_id?: string
}) => api.post(`${API.MEETINGS_TRACKER}/rate`, data)

export const updateMeetingAgenda = (data: {
  agenda: string
  id?: number
  parent_event_id?: string
}) => api.patch(`${API.MEETINGS_TRACKER}/update`, data)

export const refreshMeetings = () => api.post(`/meetings/newMeetings/fetch`)
