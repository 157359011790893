import React from 'react'
import { Box, Flex, Text, Widget } from '@revolut/ui-kit'
import differenceInMinutes from 'date-fns/differenceInMinutes'
import OnlineTestResultStatus from '@src/features/InterviewTool/OnlineTestResultStatus'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'

type Props = {
  score?: number
  percentage?: number
  startTime?: string
  endTime?: string
  plagiarism?: boolean
  passed?: boolean
}

const OnlineTestResultWidget = ({
  percentage,
  score,
  startTime,
  endTime,
  plagiarism,
  passed,
}: Props) => {
  return (
    <Widget p="s-16">
      <Flex pb="s-32">
        <Box width={1 / 2}>
          <Text use="div" color="grey-tone-50" variant="caption">
            Result
          </Text>
          <Text use="div" variant="h5">
            <OnlineTestResultStatus passed={passed} />
          </Text>
        </Box>
        <Box width={1 / 2}>
          <Text use="div" color="grey-tone-50" variant="caption">
            Score
          </Text>
          <Text use="div" variant="h5">
            <Text>{isNumber(percentage) ? `${percentage}%` : 'N/A'}</Text>{' '}
            {isNumber(score) && isNumber(percentage) && (
              <Text color="grey-tone-50">
                ({score}/{Math.trunc((score * 100) / percentage)})
              </Text>
            )}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Box width={1 / 2}>
          <Text use="div" color="grey-tone-50" variant="caption">
            Plagiarism check
          </Text>
          <Text use="div" variant="h5">
            {isBoolean(plagiarism) ? <>{plagiarism ? 'Yes' : 'No'}</> : 'N/A'}
          </Text>
        </Box>
        <Box width={1 / 2}>
          <Text use="div" color="grey-tone-50" variant="caption">
            Total time taken
          </Text>
          <Text use="div" variant="h5">
            {endTime && startTime
              ? `${differenceInMinutes(new Date(endTime), new Date(startTime))} min`
              : 'N/A'}
          </Text>
        </Box>
      </Flex>
    </Widget>
  )
}

export default OnlineTestResultWidget
