import React from 'react'
import { Avatar, Item } from '@revolut/ui-kit'
import { Envelope } from '@revolut/icons'

import { getSenderDetails } from '@src/features/EmailThread/utils'
import { SendEmployeeEmailInterface } from '@src/interfaces/employeeEmails'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { getAvatarUrl } from '@src/utils/employees'

interface EmailThreadItemAvatarProps {
  email: SendEmployeeEmailInterface | SendCandidateEmailInterface
}

const EmailThreadItemAvatar = ({ email }: EmailThreadItemAvatarProps) => {
  const direction = email.direction
  const details = getSenderDetails({
    ...email,
    direction: typeof direction === 'string' ? direction : direction.id,
  })
  return (
    <Item.Avatar>
      <Avatar image={getAvatarUrl(details.avatar)}>
        {!details.avatar && details.initials}
        <Avatar.Badge useIcon={Envelope} bg="deep-grey" />
      </Avatar>
    </Item.Avatar>
  )
}

export default EmailThreadItemAvatar
