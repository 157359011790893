import React from 'react'
import { PreviousReviewInterface } from '@src/interfaces/performance'
import { Flex, Text, Token, VStack } from '@revolut/ui-kit'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import UserWithAvatar from '@components/UserWithAvatar/UserWithAvatar'

interface Props {
  data?: PreviousReviewInterface[]
}

export const PreviousOverallFeedback = ({ data }: Props) => {
  const user = useSelector(selectUser)

  if (!data?.length) {
    return null
  }

  return (
    <VStack mt="s-16" space="s-12">
      {data.map((feedback, i) => {
        return (
          <VStack key={i} space="s-8">
            <Text variant="caption" color={Token.color.greyTone50} fontWeight={500}>
              {feedback.cycle
                ? `${feedback.cycle.name} feedback`
                : `CP${feedback.checkpoint?.number} feedback`}
            </Text>
            <OverallFeedback
              key={i}
              title={
                <Flex alignItems="center" mb="s-16">
                  <UserWithAvatar
                    size={24}
                    avatar={user.avatar}
                    name={user.full_name}
                    id={user.id}
                    compact
                    width={40}
                  />
                  <Text variant="h6" fontWeight={500} ml="-s-8">
                    {user.full_name}
                  </Text>
                </Flex>
              }
              reviewerId={user.id}
              pros={feedback.review.overall_feedback?.pros}
              cons={feedback.review.overall_feedback?.cons}
              disabled
              noMargin
              isSidebar
            />
          </VStack>
        )
      })}
    </VStack>
  )
}
