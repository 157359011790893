import React, { useEffect, useState } from 'react'
import {
  ActionButton,
  Avatar,
  Button,
  Header,
  Item,
  ItemSkeleton,
  Popup,
  Text,
} from '@revolut/ui-kit'
import { useCareersSettings } from '@src/api/recruitment/careers'
import DOMPurify from 'dompurify'
import { careerPageSettings } from '@src/api/settings'
import HTMLEditor from '@components/HTMLEditor/HTMLEditor'
import { isHTMLFieldEmpty } from '@src/utils/string'

export const AboutCompanyWidget = () => {
  const [openPopup, setOpenPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: careersSettings, isLoading, refetch } = useCareersSettings()
  const [aboutTheCompany, setAboutTheCompany] = useState<string | undefined>()

  useEffect(() => {
    setAboutTheCompany(careersSettings?.about_the_company)
  }, [careersSettings?.about_the_company])

  if (isLoading) {
    return <ItemSkeleton />
  }

  const onSave = async () => {
    setLoading(true)

    try {
      await careerPageSettings.update(
        {
          about_the_company: aboutTheCompany,
        },
        // for settings endpoints we always send id=1
        { id: '1' },
      )
      setOpenPopup(false)
      refetch()
    } finally {
      setLoading(false)
    }
  }

  const aboutTheCompanySettings = careersSettings?.about_the_company
  const isEmpty = isHTMLFieldEmpty(aboutTheCompanySettings)

  return (
    <>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Time" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>About company</Item.Title>
          <Item.Description>
            {!isEmpty ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(aboutTheCompanySettings),
                }}
              />
            ) : (
              '“About company” section will be shown above every job posting description for all your jobs. You can add it once so it appears at every current and future job posting, and edit it later in the settings.'
            )}
          </Item.Description>
          <Item.Actions>
            <ActionButton
              onClick={() => {
                setOpenPopup(true)
              }}
            >
              {isEmpty ? 'Add' : 'Edit'} “About company” section
            </ActionButton>
          </Item.Actions>
        </Item.Content>
      </Item>

      <Popup open={openPopup} onClose={() => setOpenPopup(false)} size="md">
        <Header>
          <Header.CloseButton aria-label="Close" />
          <Header.Title>About company</Header.Title>
          <Header.Description>
            This text will be added to each job posting’s “About the company” section
          </Header.Description>
        </Header>
        <HTMLEditor
          placeholder={`Example:
At our company, we are driven by a single goal: to be the benchmark of excellence in our industry. Since our founding, we've been at the forefront of innovation, committed to not only providing exceptional products and services but also to making a positive impact on our community and the environment.

We want exceptional candidates that love great companies.`}
          maxCharacters={5000}
          value={aboutTheCompany}
          onChange={val => setAboutTheCompany(val)}
        />
        <Popup.Actions>
          <Button elevated onClick={onSave} pending={loading} disabled={loading}>
            Save
          </Button>
        </Popup.Actions>
      </Popup>
    </>
  )
}
