import { ImageProps } from '@revolut/ui-kit'
import { ReviewCycleStage } from '@src/interfaces/reviewCycles'

interface Props extends Pick<ImageProps, 'image'> {
  title: string
  description: string
  confirmLabel: string
  cancelLabel: string
  note?: string
}

export const nextStageToPropsMap: Record<ReviewCycleStage, Props | null> = {
  [ReviewCycleStage.TeamGoals]: {
    title: 'Finish goal phase',
    description:
      'Make sure your teams have set up all goals required for this review cycle',
    note: 'Once you confirm, your teams will not be able to add more goals to this review cycle',
    confirmLabel: 'Yes, confirm goals',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D373.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D373@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D373@3x.png',
    },
  },
  [ReviewCycleStage.DepartmentGoals]: {
    title: 'Finish goal phase',
    description:
      'Make sure your departments have set up all goals required for this review cycle',
    note: 'Once you confirm, your departments will not be able to add more goals to this review cycle',
    confirmLabel: 'Yes, confirm goals',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D373.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D373@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D373@3x.png',
    },
  },
  [ReviewCycleStage.Review]: {
    title: 'End review period',
    description: 'Make sure all required reviews have been provided',
    confirmLabel: 'End review period',
    cancelLabel: 'Don’t end',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D332.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@3x.png',
    },
  },
  [ReviewCycleStage.Calibration]: {
    title: 'Finish Calibration period',
    description: 'Make sure the calibrated grades match your targets',
    note: 'Once you confirm this, grades cannot be calibrated anymore',
    confirmLabel: 'Confirm & continue',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D332.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D332@3x.png',
    },
  },
  [ReviewCycleStage.ManagersPublish]: {
    title: 'Publish to managers',
    description: 'Managers can see the results on their Performance Review overview page',
    confirmLabel: 'Publish to managers',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D021.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@3x.png',
    },
  },
  [ReviewCycleStage.EmployeesPublish]: {
    title: 'Publish to employees',
    description:
      'Employees can see the results of the Performance Review on their profile page',
    confirmLabel: 'Send to employees',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D021.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D021@3x.png',
    },
  },
  [ReviewCycleStage.Completed]: null,
}

export const previousStageToPropsMap: Record<ReviewCycleStage, Props | null> = {
  [ReviewCycleStage.TeamGoals]: {
    title: 'Reopen team goal phase',
    description:
      'Reopening the phase will allow department managers to submit and change their goals for this quarter. Don’t forget to close this phase once all changes have been done.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
  [ReviewCycleStage.DepartmentGoals]: {
    title: 'Reopen department goal phase',
    description:
      'Reopening the phase will allow department managers to submit and change their goals for this quarter. Don’t forget to close this phase once all changes have been done.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
  [ReviewCycleStage.Review]: {
    title: 'Reopen Performance review phase',
    description:
      'Reopening the phase will allow employees and  managers to submit and change their reviews for this quarter. Don’t forget to close this phase once all changes have been done.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
  [ReviewCycleStage.Calibration]: {
    title: 'Reopen Calibration phase',
    description:
      'Reopening the phase will allow Heads of Department and Heads of Function to calibrate final grades for this quarter. Don’t forget to close this phase once all changes have been done.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
  [ReviewCycleStage.ManagersPublish]: {
    title: 'Reopen Publish to managers',
    description:
      'Reopening this stage will hide the grades for this cycle and the managers won’t be able to see the grades of their reports anymore.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
  [ReviewCycleStage.EmployeesPublish]: null,
  [ReviewCycleStage.Completed]: {
    title: 'Reopen Publish to employees',
    description:
      'Reopening this stage will hide the grades for this cycle and the employees won’t be able to see them anymore.',
    confirmLabel: 'Reopen',
    cancelLabel: 'Cancel',
  },
}
