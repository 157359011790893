import React, { useState } from 'react'
import {
  meetingRecurrence,
  MeetingTrackerCategoryColors,
  MeetingTrackerCategoryTitles,
  MeetingTrackerInterface,
} from '@src/interfaces/meetingsTracker'
import SideBar from '@components/SideBar/SideBar'
import { MeetingRatingPopup } from '@src/pages/Forms/MeetingsTracker/MeetingRatingPopup'
import { addMeetingRate, updateMeetingAgenda } from '@src/api/meetingsTracker'
import {
  Flex,
  StatusPopup,
  useStatusPopup,
  VStack,
  Text,
  Token,
  Avatar,
  MoreBar,
  Group,
  Box,
  Tag,
  Cell,
  useTooltip,
  Tooltip,
  Link,
} from '@revolut/ui-kit'
import { Dot, Profile, StarFilled } from '@revolut/icons'
import { FormPreview } from '@components/FormPreview/FormPreview'
import {
  formatDateTimePeriod,
  formatRRule,
  getTotalTimeFromMinutes,
} from '@src/utils/format'
import round from 'lodash/round'
import HTMLContent from '@components/HTMLContent/HTMLContent'
import { MeetingAgendaPopup } from '@src/pages/Forms/MeetingsTracker/MeetingAgendaPopup'
import { DateWithTooltip } from '@src/constants/columns/meetingsTracker'
import { QualityWithTooltip } from '@src/pages/Forms/MeetingsTracker/utils'

type Props = {
  data: MeetingTrackerInterface
  open: boolean
  onClose: () => void
  onAfterRatingChange: (
    responseData: MeetingTrackerInterface[],
    object_id: number,
  ) => void
  onChangeRatingError: () => void
}

interface RatingPopupProps {
  object_id: number
  id?: number
  meetingTitle: string
  parentId?: string
}

interface AgendaPopupProps {
  object_id: number
  id?: number
  parentId?: string
  agenda: string
}

export const MeetingSidebar = ({
  data,
  open,
  onClose,
  onAfterRatingChange,
  onChangeRatingError,
}: Props) => {
  const [ratingPopup, setRatingPopup] = useState<RatingPopupProps>()
  const [agendaPopup, setAgendaPopup] = useState<AgendaPopupProps>()
  const statusPopup = useStatusPopup()
  const tooltip = useTooltip()

  const onChangeAgenda = async (
    agendaValue: string,
    object_id: number,
    id?: number,
    parentId?: string,
  ) => {
    try {
      const payload = parentId
        ? { agenda: agendaValue, parent_event_id: parentId }
        : { agenda: agendaValue, id }
      const response = await updateMeetingAgenda(payload)
      onAfterRatingChange(response.data.results, object_id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Agenda successfully updated</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (e) {
      onChangeRatingError()
    }
  }

  const onChangeRating = async (
    ratingValue: number,
    object_id: number,
    ratingComment?: string,
    id?: number,
    parentId?: string,
  ) => {
    try {
      const payload = parentId
        ? { rating: ratingValue, comment: ratingComment, parent_event_id: parentId }
        : { rating: ratingValue, comment: ratingComment, id }
      const response = await addMeetingRate(payload)
      onAfterRatingChange(response.data.results, object_id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Rating successfully submitted</StatusPopup.Title>
        </StatusPopup>,
      )
    } catch (e) {
      onChangeRatingError()
    }
  }

  const meetingCategory = data.category
  const labelText = meetingCategory ? MeetingTrackerCategoryTitles[meetingCategory] : ''
  const labelColor = meetingCategory
    ? MeetingTrackerCategoryColors[meetingCategory]
    : null

  return (
    <>
      <SideBar
        variant="wide"
        isOpen={open}
        headerContent={
          <Flex justifyContent="space-between" alignItems="center">
            <VStack space="s-4">
              <Text variant="h5">{data.title}</Text>
              {data.organizer && (
                <Flex alignItems="center">
                  <Profile size={16} color={Token.color.greyTone50} />
                  <Text variant="caption" color={Token.color.greyTone50}>
                    {data.organizer.full_name}
                  </Text>
                </Flex>
              )}
            </VStack>
            <Avatar color={Token.color.deepGrey} useIcon="Chat" />
          </Flex>
        }
        onClose={onClose}
      >
        <MoreBar>
          <MoreBar.Action
            useIcon="Pencil"
            onClick={() => {
              setAgendaPopup({
                object_id: data.object_id,
                id: data.id,
                parentId: data.parent_event_id,
                agenda: data.agenda,
              })
            }}
          >
            Edit agenda
          </MoreBar.Action>
          {data.can_rate_meeting && (
            <MoreBar.Action
              useIcon="StarFilled"
              onClick={() => {
                setRatingPopup({
                  object_id: data.object_id,
                  id: data.id,
                  meetingTitle: data.title,
                  parentId: data.parent_event_id,
                })
              }}
            >
              Rate meeting
            </MoreBar.Action>
          )}
        </MoreBar>
        <Box mt="s-16">
          <FormPreview<MeetingTrackerInterface> title="Meeting details" data={data}>
            <Group>
              <FormPreview.Item
                title="Participants"
                insert={() => data.attendees.length}
              />
              <FormPreview.Item
                title="Date"
                insert={() => formatDateTimePeriod(data.start, data.end)}
              />
              <FormPreview.Item
                title="Recurrence"
                insert={() =>
                  !data.parent_event_id ? (
                    meetingRecurrence.Single
                  ) : (
                    <DateWithTooltip data={data}>
                      {data.recurrence
                        ? formatRRule(data.recurrence)
                        : meetingRecurrence.Custom}
                    </DateWithTooltip>
                  )
                }
              />
              <FormPreview.Item
                title="Type"
                insert={() => (
                  <>
                    {labelText.length ? (
                      <Tag color={Token.color.black_10}>
                        {labelColor && (
                          <Box mr="s-4">
                            <Dot color={labelColor} size={10} />
                          </Box>
                        )}
                        <Text color={Token.color.foreground}>{labelText}</Text>
                      </Tag>
                    ) : (
                      '-'
                    )}
                  </>
                )}
              />
              <FormPreview.Item
                title="Total time (week)"
                insert={() => getTotalTimeFromMinutes(data.duration)}
              />
              <FormPreview.Item
                title="Meeting quality"
                insert={() => <QualityWithTooltip data={data} />}
              />
              <FormPreview.Item
                title="Rating"
                insert={() =>
                  data.rating ? (
                    <Flex
                      justifyContent="flex-end"
                      alignItems="center"
                      gap="s-4"
                      style={{ cursor: 'default' }}
                      {...tooltip.getAnchorProps()}
                    >
                      <Text>{round(data.rating, 1)}</Text>
                      <StarFilled size={16} color="yellow" />
                      <Tooltip {...tooltip.getTargetProps()} placement="left-end">
                        Average from participants
                      </Tooltip>
                    </Flex>
                  ) : (
                    <Box mr="s-6">-</Box>
                  )
                }
              />
            </Group>
          </FormPreview>
        </Box>
        <Box mt="s-16">
          <Cell>
            <VStack space="s-16">
              <Text variant="h6" color={Token.color.greyTone50}>
                Agenda
              </Text>
              {!!data.agenda?.length && <HTMLContent html={data.agenda} />}
            </VStack>
          </Cell>
        </Box>
        <Box mt="s-16">
          <Cell>
            <Flex justifyContent="space-between" alignItems="center" width="100%">
              <Text variant="h6" color={Token.color.greyTone50}>
                Attachments
              </Text>
              <Flex gap="s-4">
                {data.attached_notes?.map((link, ind) => (
                  <Link target="_blank" href={link} key={ind}>
                    <Avatar
                      variant="app"
                      color={Token.color.accent}
                      bg={Token.color.actionBackground}
                      useIcon="Document"
                    />
                  </Link>
                ))}
              </Flex>
            </Flex>
          </Cell>
        </Box>
      </SideBar>
      {agendaPopup && (
        <MeetingAgendaPopup
          agenda={data.agenda}
          onSubmit={async (agendaValue: string) => {
            await onChangeAgenda(
              agendaValue,
              agendaPopup?.object_id,
              agendaPopup.id,
              agendaPopup?.parentId,
            )
            setAgendaPopup(undefined)
          }}
          onClose={() => setAgendaPopup(undefined)}
        />
      )}
      {ratingPopup && (
        <MeetingRatingPopup
          onSubmit={async (ratingValue: number, ratingComment?: string) => {
            await onChangeRating(
              ratingValue,
              ratingPopup?.object_id,
              ratingComment,
              ratingPopup.id,
              ratingPopup?.parentId,
            )
            setRatingPopup(undefined)
          }}
          meetingTitle={ratingPopup.meetingTitle}
          onClose={() => setRatingPopup(undefined)}
        />
      )}
    </>
  )
}
