import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { PayGroupEdit } from './Edit'

export const PayGroup = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.PAYROLL_V2.PAY_GROUP.ANY}>
        <Route
          path={[
            ROUTES.APPS.PAYROLL_V2.PAY_GROUP.EDIT,
            ROUTES.APPS.PAYROLL_V2.PAY_GROUP.CONFIRM,
          ]}
        >
          <PayGroupEdit />
        </Route>
      </Route>
    </Switch>
  )
}
