import React from 'react'
import { Box, Widget } from '@revolut/ui-kit'
import { ReviewSummaryInterface } from '@src/interfaces/performance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import Cards from '@src/pages/EmployeeProfile/Preview/Performance/Summary/Cards'
import { useParams } from 'react-router-dom'
import SideBar from '@components/SideBar/SideBar'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const PerformanceViewSummarySidebar = ({ isOpen, onClose }: Props) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()
  const { id: cycleId } = useParams<{ id: string }>()

  return (
    <SideBar title="Summary" variant="wide" isOpen={isOpen} onClose={onClose}>
      <Box width="100%">
        <Widget p="s-16">
          <Cards
            data={values}
            cycleId={cycleId}
            employeeId={values.reviews[0].reviewed_employee?.id!}
            employeeSeniorityId={values.reviews[0].reviewed_employee?.seniority?.id!}
            category={values.reviews[0].category!}
          />
        </Widget>
      </Box>
    </SideBar>
  )
}

export default PerformanceViewSummarySidebar
