import SideBar from '@src/components/SideBar/SideBar'
import React from 'react'
import { EditQuestionForm } from './EditQuestionForm'
import { EngagementQuestionInterface } from '@src/interfaces/engagement'

interface Props {
  onClose: (result?: EngagementQuestionInterface) => void
  questionId?: number
}

export const EditQuestionSidebar = ({ onClose, questionId }: Props) => {
  if (!questionId) {
    return null
  }
  return (
    <SideBar variant="wide" isOpen={!!questionId} title="Edit question" onClose={onClose}>
      <EditQuestionForm questionId={questionId} afterSubmit={onClose} />
    </SideBar>
  )
}
