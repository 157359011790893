import React from 'react'
import { SwitcherButtons } from '@src/components/SwitcherButtonsWIthSidebar/SwitcherButtonsWithSidebar'
import { useReviewNavigation } from '@src/pages/Forms/Candidate/CRMReview/utils'

type CRMReviewCandidateSwitcherProps = {
  interviewRounds: number[]
}

export const CRMReviewCandidateSwitcher = ({
  interviewRounds,
}: CRMReviewCandidateSwitcherProps) => {
  const { loading, next, prev, handleNavigateToNext, handleNavigateToPrev } =
    useReviewNavigation(interviewRounds)
  return (
    <SwitcherButtons
      loading={loading}
      next={next}
      prev={prev}
      onNextClick={() => {
        handleNavigateToNext()
      }}
      onPrevClick={() => {
        handleNavigateToPrev()
      }}
    />
  )
}
