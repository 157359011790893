import { ItemType, IssueTypes } from '@src/interfaces/deliverables'
import { Flex, Avatar, Text, Ellipsis, Token } from '@revolut/ui-kit'
import { getIconKeyByIssue } from '@src/utils/performance'
import RoadmapChangelog from '@src/features/RoadmapChangelog/RoadmapChangelog'
import React, { useContext } from 'react'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'

interface RoadmapNameProps {
  data: RoadmapInterface
}

const checkIfFieldHasChanged = (
  data: RoadmapInterface,
  field: keyof RoadmapInterface,
): boolean => {
  if (!data.fields_changed_after_deadline) {
    return false
  }
  return data.fields_changed_after_deadline.includes(field)
}

export const RoadmapName = ({ data }: RoadmapNameProps) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  const size = density && density !== 'large' ? 24 : 40

  if (data.item_type === ItemType.Manual) {
    return (
      <Flex alignItems="center" gap="s-8">
        <Avatar useIcon="Map" size={size} />
        <Text>
          <Ellipsis>{data.name}</Ellipsis>
        </Text>
      </Flex>
    )
  }
  if (data.item_type === ItemType.Clickup) {
    return (
      <Flex alignItems="center" gap="s-8">
        <Flex
          alignItems="center"
          justifyContent="center"
          width={size}
          height={size}
          bg={Token.color.actionBackground}
          borderRadius={size}
        >
          <Avatar
            image={{
              default: 'https://assets.revolut.com/assets/apps/ClickUp.webp',
              '2x': 'https://assets.revolut.com/assets/apps/ClickUp@2x.webp',
              '3x': 'https://assets.revolut.com/assets/apps/ClickUp@3x.webp',
            }}
            size={24}
          />
        </Flex>
        <Text>
          <Ellipsis>{data.name}</Ellipsis>
        </Text>
      </Flex>
    )
  }
  const iconType = getIconKeyByIssue(data?.issue_type || IssueTypes.Epic)

  return (
    <RoadmapChangelog
      column="name"
      data={data}
      hasChanged={checkIfFieldHasChanged(data, 'name')}
    >
      <Flex alignItems="center">
        <Avatar useIcon={iconType} size={size} />
        <Text use="p" pl="s-8">
          <Ellipsis>{data.name}</Ellipsis>
        </Text>
      </Flex>
    </RoadmapChangelog>
  )
}
