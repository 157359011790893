import React from 'react'
import SectionTitle from '../components/SectionTitle'
import { Cell, Flex, VStack, Text, Token } from '@revolut/ui-kit'
import { ValueBasedCardInterface } from '@src/interfaces/performance'
import { LapeBooleanRadioSwitch } from '../components/RadioSwitch'
import { RecommendedLabel } from '../components/Recommended'
import { ValuesTab } from '../Performance/ScoreCardPreview/Values'
import { valueExample } from '@src/pages/OnboardingChecklist/Performance/ScoreCardPreview/example'
import { SkillsTab } from '../Performance/ScoreCardPreview/SkillsTab'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillSettingsInterface } from '@src/interfaces/settings'
import { SkillInterface } from '@src/interfaces/skills'

const exampleBehaviours: ValueBasedCardInterface = {
  ...valueExample,
  name: `Example value: ${valueExample.name}`,
  description:
    'When using values based on competencies, I can set a company value and the rating level criteria that define it, just like skills. When assessing an employee’s competency on a particular value, I can rate each item of the criteria Yes or No in turn, unlocking the next level once all are met. I can add my comments at the end against each value.',
  sections: valueExample.sections.map(section => ({
    ...section,
    text: `Example criterion: ${section.text}`,
  })),
}
export const ValuesDefinition = () => {
  const { values } = useLapeContext<SkillSettingsInterface>()

  return (
    <>
      <Flex gap="s-16">
        <VStack flex={3}>
          <SectionTitle
            title="Do your cultural values depend on expected behaviours, or competencies like skills?"
            subtitle="What defines your cultural values - a set of expected behaviours, or a set of objective competencies (such as skills)"
          />
          <LapeBooleanRadioSwitch
            yesLabel={<RecommendedLabel label="As behaviours" />}
            yesDescription="Specific examples of observable actions and interactions that reflect the company's guiding principles"
            noLabel="As competencies"
            noDescription="Specific skills and qualities that employees possess, aligned with the company's core principles"
            name="behaviours_assessment_enabled"
          />
        </VStack>
        <Cell style={{ width: '100%', display: 'flex', flex: 2 }}>
          <VStack>
            <Text variant="caption" color={Token.color.greyTone50}>
              Values preview
            </Text>
            {values?.behaviours_assessment_enabled ? (
              <ValuesTab values={exampleBehaviours} />
            ) : (
              <SkillsTab
                skill={
                  {
                    id: 1,
                    name: 'Example value: Customer First',
                    description:
                      'When using values based on competencies, I can set a company value and the rating level criteria that define it, just like skills. When assessing an employee’s competency on a particular value, I can rate each item of the criteria Yes or No in turn, unlocking the next level once all are met. I can add my comments at the end against each value.',
                    mastery_level_definition_basic: [
                      'Example criterion: Put customers first',
                      'Example criterion: Keep it simple',
                    ],
                    mastery_level_definition_intermediate: [
                      'Example criterion: Always above and beyond',
                      'Example criterion: Personalised response',
                    ],
                  } as SkillInterface
                }
              />
            )}
          </VStack>
        </Cell>
      </Flex>
    </>
  )
}
