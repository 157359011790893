import React from 'react'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { Item, Avatar, Text, Color } from '@revolut/ui-kit'
import { Link } from 'react-router-dom'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import revbets from '@src/assets/images/revbets.png'
import { LinkExternal } from '@revolut/icons'

export const RevBetsCategory = () => {
  return (
    <HideIfCommercial>
      <Item padding="s-16" use={Link} to={pathToUrl(ROUTES.REVBETS)} target="_blank">
        <Item.Avatar mr="s-16">
          <Avatar variant="brand" image={revbets} />
        </Item.Avatar>
        <Item.Content>
          <Text>RevBets - Pitch your idea</Text>
        </Item.Content>
        <Item.Side>
          <LinkExternal color={Color.GREY_TONE_50} />
        </Item.Side>
      </Item>
    </HideIfCommercial>
  )
}
