import React, { useEffect, useRef } from 'react'
import { Flex, FlexProps } from '@revolut/ui-kit'
import { PageWrapper } from '@components/Page/Page'
import { useLocation, useParams } from 'react-router-dom'
import PerformanceHeader from '@src/pages/Forms/EmployeePerformance/components/PerformanceHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'

import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { Statuses } from '@src/interfaces'
import { getLastTabLocalStorageKey } from '@src/utils/performance'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'

export interface Props extends FlexProps {
  children: React.ReactNode | React.ReactNode[]
  showHeader?: boolean
}

const PerformancePage = ({ children, showHeader = true }: Props) => {
  const { values } = useLapeContext<ReviewScorecardInterface>()
  const params = useParams<{ id: string; employeeId: string }>()
  const location = useLocation()
  const scrollRef = useRef<HTMLDivElement | null>(null)

  const updateLocalStorage = () => {
    if (values && params) {
      workspaceLocalStorage.setItem(
        getLastTabLocalStorageKey(values.category, params.employeeId, params.id),
        location.pathname,
      )
    }
  }

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo(0, 0)
    }
    if (values.status === Statuses.pending || values.status === Statuses.draft) {
      updateLocalStorage()
    }
  }, [location.pathname])

  return (
    <PageWrapper>
      {showHeader && <PerformanceHeader />}
      <Flex flexDirection="column" width="100%" flex={1} minHeight={0} ref={scrollRef}>
        {children}
      </Flex>
    </PageWrapper>
  )
}

export default PerformancePage
