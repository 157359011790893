import React, { useEffect } from 'react'
import { ReviewScorecardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { Avatar, Flex, Text, VStack, Widget } from '@revolut/ui-kit'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import set from 'lodash/set'

export const Visibility = connect(() => {
  const { values } = useLapeContext<ReviewScorecardInterface>()

  const showVisibility = values.visible_to_reviewee !== undefined

  useEffect(() => {
    if (values.visible_to_reviewee === null) {
      set(values, 'visible_to_reviewee', false)
    }
  }, [])

  if (!showVisibility) {
    return null
  }

  return (
    <>
      <Widget p="s-16">
        <VStack space="s-16">
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center" gap="s-16" width="30%">
              <Avatar useIcon="EyeShow" />
              <Text variant="primary">Visibility</Text>
            </Flex>
          </Flex>
          <VStack space="s-4">
            <Text variant="primary">
              Allow {values.reviewed_employee.display_name} to see your review?
            </Text>
            <Text variant="caption">
              You can let {values.reviewed_employee.display_name} see your review. If you
              select 'No', only {values.reviewed_employee.display_name}'s managers will
              see this review.
            </Text>
          </VStack>
          <LapeNewRadioButtons
            name="visible_to_reviewee"
            options={[
              {
                label: 'Yes',
                value: true,
              },
              {
                label: 'No',
                value: false,
              },
            ]}
            variant="cell"
          />
        </VStack>
      </Widget>
    </>
  )
})
