import {
  AppIcon,
  ContextMenuController,
  DragAndDrop,
  Dropdown,
  SideNav,
} from '@revolut/ui-kit'
import { Unpin } from '@revolut/icons'
import { matchPath, NavLink, useLocation } from 'react-router-dom'
import React, { useCallback, useState } from 'react'
import { UNPIN_APP_LABEL, usePinnedApps } from '@src/pages/Hub/Apps/common'
import { applicationPathsMap, APPLICATIONS } from '@src/constants/hub'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { HubAppType } from '@src/interfaces/hub'
import { getAppOrTab } from '@src/features/HubAppIcon/utils'
import { AnalyticsEvents, useAnalytics } from '@src/utils/analytics'
import { getIconProps } from '@src/pages/Hub/utils'
import { move } from '@src/utils/move'

export const SideBarPinnedApps = () => {
  const { sendAnalyticsEvent } = useAnalytics()
  const permissions = useSelector(selectPermissions)
  const { pinnedApps, setPinnedApps } = usePinnedApps()
  const [activeId, setActiveId] = useState(null)

  const location = useLocation()

  const onDragStart = useCallback(event => setActiveId(event.active.id), [])
  const onDragCancel = useCallback(() => setActiveId(null), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      sendAnalyticsEvent(AnalyticsEvents.reorder_apps)
      setPinnedApps(current =>
        startIndex === endIndex ? current : move(current, startIndex, endIndex),
      )
    }

    setActiveId(null)
  }, [])

  const activeApp = activeId ? (APPLICATIONS[activeId] as HubAppType) : undefined

  return (
    <SideNav.Items>
      <DragAndDrop.Provider
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDragCancel={onDragCancel}
      >
        <DragAndDrop.Sortable id="pinned-apps" items={pinnedApps}>
          {sortable => {
            /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
            const app = APPLICATIONS[sortable.id] as HubAppType
            const item = getAppOrTab(permissions, app)

            if (!item?.url) {
              return null
            }
            const selected = !!matchPath(location.pathname, {
              path: applicationPathsMap[app.id],
              exact: true,
            })

            return (
              <ContextMenuController key={sortable.id}>
                {contextMenu => {
                  return (
                    <>
                      <SideNav.Item
                        // @ts-expect-error UI kit actually has this
                        exact
                        to={item.url}
                        use={NavLink}
                        aria-selected={selected}
                        {...sortable.attributes}
                        {...sortable.listeners}
                        {...contextMenu.getAnchorProps()}
                        ref={sortable.setNodeRef}
                        style={{
                          transform: sortable.transform
                            ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                            : undefined,
                          transition: sortable.transition || 'none',
                          opacity: sortable.isDragging ? 0 : undefined,
                        }}
                      >
                        <SideNav.ItemAvatar>
                          <AppIcon
                            use="span"
                            {...(app?.icon
                              ? { useIcon: app.icon, bg: app.bg }
                              : getIconProps(app.image, app.bg, 40))}
                            size={24}
                          />
                        </SideNav.ItemAvatar>
                        <SideNav.ItemLabel>{app.title}</SideNav.ItemLabel>
                      </SideNav.Item>
                      <Dropdown
                        {...contextMenu.getTargetProps()}
                        width={160}
                        data-testid="Pinned apps dropdown"
                      >
                        <Dropdown.Item
                          use="button"
                          useIcon={Unpin}
                          onClick={() => {
                            sendAnalyticsEvent(AnalyticsEvents.unpin_app, {
                              app_id: app.id,
                            })
                            setPinnedApps(prev =>
                              prev.filter(pinnedAppId => pinnedAppId !== app.id),
                            )
                          }}
                        >
                          {UNPIN_APP_LABEL}
                        </Dropdown.Item>
                      </Dropdown>
                    </>
                  )
                }}
              </ContextMenuController>
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeApp && (
            <SideNav.Item>
              <SideNav.ItemAvatar>
                <AppIcon
                  use="span"
                  {...(activeApp.icon
                    ? { useIcon: activeApp.icon, bg: activeApp.bg }
                    : getIconProps(activeApp.image, activeApp.bg, 40))}
                  size={24}
                />
              </SideNav.ItemAvatar>
              <SideNav.ItemLabel>{activeApp.title}</SideNav.ItemLabel>
            </SideNav.Item>
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
    </SideNav.Items>
  )
}
