import React, { useCallback, useEffect } from 'react'
import { Absolute, DetailsCell, Flex, InputGroup } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import pluralize from 'pluralize'

import {
  EmployeeTimeOffAdjustBalanceInterface,
  EmployeeTimeOffBalanceSelectorOption,
  EmployeeTimeOffBalancesInterface,
} from '@src/interfaces/timeOff'
import {
  adjustEmployeeTimeOffBalance,
  employeeTimeOffBalancesSelector,
  useBalanceAdjustmentFields,
} from '@src/api/timeOff'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { selectorKeys } from '@src/constants/api'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import SideBar from '../SideBar/SideBar'
import LapeNewTextArea from '../Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

interface AdjustBalanceSidebarProps {
  employeeId: number
  isOpen: boolean
  onClose: () => void
  onAfterSubmit: () => void
  balance?: EmployeeTimeOffBalancesInterface
}

export const AdjustBalanceSidebar = ({
  employeeId,
  isOpen,
  onClose,
  onAfterSubmit,
  balance,
}: AdjustBalanceSidebarProps) => {
  return (
    <SideBar title="Adjust balance" isOpen={isOpen} onClose={onClose}>
      <LapeForm<EmployeeTimeOffAdjustBalanceInterface>
        onSubmit={form =>
          adjustEmployeeTimeOffBalance(form.values).then(response => response.data)
        }
      >
        <AdjustBalanceSidebarForm
          employeeId={employeeId}
          onAfterSubmit={onAfterSubmit}
          balance={balance}
        />
      </LapeForm>
    </SideBar>
  )
}

interface AdjustBalanceSidebarFormProps {
  employeeId: number
  onAfterSubmit: () => void
  balance?: EmployeeTimeOffBalancesInterface
}

const AdjustBalanceSidebarForm = ({
  employeeId,
  onAfterSubmit,
  balance,
}: AdjustBalanceSidebarFormProps) => {
  const { values } = useLapeContext<EmployeeTimeOffAdjustBalanceInterface>()

  const isUnlimitedBalance = balance?.balance_type.id === 'unlimited'

  const balanceSelector = useCallback(
    () =>
      employeeTimeOffBalancesSelector(employeeId).then(response => response.data.options),
    [employeeId],
  )

  const balanceAdjustmentFields = useBalanceAdjustmentFields(
    isUnlimitedBalance ? undefined : values.balance?.id,
  )

  const balanceAdjustmentFieldsSelector = async () =>
    balanceAdjustmentFields.data?.options || []

  useEffect(() => {
    if (balance && !values.balance) {
      values.balance = { id: balance.id }
      values.unit = balance.unit
    }
  }, [])

  const newBalance = (() => {
    const adjustment = Number(values.amount)

    if (values.balance == null || values.adjustment_type == null || isNaN(adjustment)) {
      return null
    }

    const sign = values.adjustment_type.id === 'subtract' ? -1 : 1
    const getValue = (prevValue: number) => prevValue + adjustment * sign

    if (isUnlimitedBalance) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      const prevValue = values.field ? balance[values.field.id] : null
      return isNaN(prevValue) ? null : getValue(prevValue)
    }

    if (values.field?.available != null) {
      return getValue(values.field.available)
    }

    if ('balance_rounded' in values.balance) {
      return getValue(values.balance.balance_rounded)
    }

    return null
  })()

  return (
    <FormValidatorProvider>
      <Flex flexDirection="column" height={0}>
        <InputGroup>
          {balance ? null : (
            <LapeRadioSelectInput<EmployeeTimeOffBalanceSelectorOption>
              name="balance"
              label="Balance"
              selector={balanceSelector}
              onAfterChange={value => {
                values.field = undefined
                if (value) {
                  values.unit = value.unit
                }
              }}
            />
          )}

          {isUnlimitedBalance ? (
            <LapeRadioSelectInput
              label="Field"
              name="field"
              selector={selectorKeys.time_off_balance_adjustment_fields_unlimited}
              disabled={values.balance == null}
            />
          ) : (
            <LapeRadioSelectInput
              label="Field"
              name="field"
              selector={balanceAdjustmentFieldsSelector}
              disabled={values.balance == null}
            />
          )}
          <LapeNewRadioButtons
            name="adjustment_type"
            options={[
              {
                label: 'Add',
                value: { id: 'add' },
              },
              {
                label: 'Subtract',
                value: { id: 'subtract' },
              },
            ]}
            variant="cell"
          />
          <InputGroup variant="horizontal">
            <Flex flex="1">
              <LapeNewInput name="amount" label="Amount" required />
            </Flex>
            <Flex flex="1">
              <LapeRadioSelectInput
                name="unit"
                label="Unit"
                selector={selectorKeys.time_off_units}
                disabled
              />
            </Flex>
          </InputGroup>
          <LapeDatePickerInput name="effective_date_time" label="Effective date" />
          <LapeNewTextArea name="description" label="Reason" required />

          {newBalance ? (
            <DetailsCell>
              <DetailsCell.Title>
                New balance will be{' '}
                {pluralize(values.unit.name.toLowerCase(), newBalance, true)}
              </DetailsCell.Title>
              <DetailsCell.Content>
                <InfoOutline color="primary" />
              </DetailsCell.Content>
            </DetailsCell>
          ) : null}
        </InputGroup>

        <Absolute bottom="s-24" left="s-24" right="s-24">
          <NewSaveButtonWithPopup onAfterSubmit={onAfterSubmit} useValidator />
        </Absolute>
      </Flex>
    </FormValidatorProvider>
  )
}
