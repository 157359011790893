import find from 'lodash/find'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { selectorKeys } from '@src/constants/api'
import { NameIdInterface } from '@src/interfaces/employees'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { useMemo } from 'react'

const readOption = {
  value: 'read',
  label: 'View',
}
const getWriteOption = (disabled?: boolean) => ({
  value: 'write',
  label: 'SQL',
  disabled,
})

const useMetabasePermissionOptions = (
  selectedDb: NameIdInterface | null,
): RadioOption[] => {
  const readonlyDbs = useFetchOptions<NameIdInterface>(
    selectorKeys.helios_metabase_read_only_databases,
  )

  const isReadonly =
    !!selectedDb && !!find(readonlyDbs.options, opt => opt.value.id === selectedDb.id)

  return useMemo(() => [readOption, getWriteOption(isReadonly)], [isReadonly])
}

export default useMetabasePermissionOptions
