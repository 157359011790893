import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TextAreaField from '@components/Inputs/TextAreaField/TextAreaField'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

const CommentField = styled(TextAreaField)`
  width: 100%;
  border: 0;
  margin-top: -15px;
`

const ConfirmationDialogStyled = styled(ConfirmationDialog)`
  padding: 16px;
  width: 600px;
`

interface Props {
  value?: string
  onClose: () => void
  open?: boolean
  onSubmit: (value?: string) => void
  placeholder?: string
}

const CommentDialog = ({
  value,
  onClose,
  open,
  onSubmit,
  placeholder = 'Add the reasoning behind your rating',
}: Props) => {
  const [fieldElement, setFieldElement] = useState<HTMLTextAreaElement | null>(null)
  const [text, setText] = useState(value)

  const handleClose = () => {
    setText(value)
    onClose()
  }

  useEffect(() => {
    if (fieldElement) {
      fieldElement.focus()
    }
  }, [fieldElement])

  useEffect(() => {
    setText(value)
  }, [value, open])

  return (
    <ConfirmationDialogStyled
      open={!!open}
      onClose={handleClose}
      onConfirm={() => {
        onSubmit(text)
      }}
      onReject={handleClose}
      showHeader={false}
      yesMessage="Submit"
      noMessage="Cancel"
      submitDisabled={!text}
      body={
        <CommentField
          placeholder={placeholder}
          value={text}
          onChange={val => {
            setText(val)
          }}
          onGetElement={ref => setFieldElement(ref)}
        />
      }
    />
  )
}

export default CommentDialog
