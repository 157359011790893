import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'lape'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { SettingsForm } from './General'
import { Changelog } from './Changelog'
import { engagementSurveySettingsRequests } from '@src/api/engagement'

export const EngagementSettings = connect(() => (
  <Form api={engagementSurveySettingsRequests} forceParams={{ id: '1' }}>
    <Switch>
      <Route exact path={ROUTES.SETTINGS.ENGAGEMENT.EDIT}>
        <SettingsForm />
      </Route>
      <Route exact path={ROUTES.SETTINGS.ENGAGEMENT.CHANGELOG}>
        <Changelog />
      </Route>
    </Switch>
  </Form>
))
