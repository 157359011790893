import React from 'react'
import { Group, Link } from '@revolut/ui-kit'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@components/Page/PageBody'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { CommunicationDetails } from '@src/features/CommunicationGroups/CommunicationDetails'
import { FunctionInterface } from '@src/interfaces/functions'
import { PermissionTypes } from '@src/store/auth/types'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const Summary = () => {
  const { values } = useLapeContext<FunctionInterface>()
  const { data: settings } = useGetOrganisationSettings()
  const showCostControlAssignment = !!settings?.enable_cost_control_assignment
  return (
    <PageBody>
      <FormPreview<FunctionInterface>
        data={values}
        title="Details"
        onEdit={() =>
          navigateTo(pathToUrl(ROUTES.FORMS.FUNCTION.SETTINGS, { id: values.id }))
        }
        hideEdit={d =>
          !d?.field_options?.permissions?.includes(PermissionTypes.ChangeFunction)
        }
      >
        <Group>
          <FormPreview.Item<FunctionInterface> field="name" title="Department name" />
          <FormPreview.Item<FunctionInterface>
            field="owner"
            title="Owner"
            type="employee"
          />
          <FormPreview.Item<FunctionInterface>
            field="delegated_requisition_approver"
            title="Requisition approver"
            type="employee"
          />
          <FormPreview.Item<FunctionInterface>
            field="delegated_offer_approver"
            title="Requisition approver"
            type="employee"
          />
          {showCostControlAssignment && (
            <FormPreview.Item<FunctionInterface>
              field="cost_control"
              title="Cost control"
              type="employee"
            />
          )}
          <FormPreview.Item<FunctionInterface>
            field="documentation_link"
            title="Link to playbooks"
            insert={({ documentation_link }) =>
              documentation_link ? (
                <Link href={documentation_link} target="_blank">
                  Open
                </Link>
              ) : (
                '-'
              )
            }
          />
          <FormPreview.Item<FunctionInterface> field="mission" title="Mission" />
          <CommunicationDetails
            group={values.communication_group}
            linkedGroups={values.linked_communication_groups}
          />
        </Group>
      </FormPreview>
    </PageBody>
  )
}
