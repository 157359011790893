import React from 'react'
import { Box, chain, Header } from '@revolut/ui-kit'
import PerformanceRatingLabelTag from '@components/PerformanceRatingLabelTag/PerformanceRatingLabelTag'
import { PerformanceRating } from '@src/interfaces/performance'

type Props = {
  name: string
  rating?: PerformanceRating | null
}

const CommentsPopupHeader = ({ name, rating }: Props) => {
  return (
    <Header variant="bottom-sheet">
      <Header.Title>
        {chain(
          name,
          rating ? (
            <Box display="inline-flex">
              <PerformanceRatingLabelTag fontWeight={700} rating={rating} />
            </Box>
          ) : (
            'N/A'
          ),
        )}
      </Header.Title>
    </Header>
  )
}

export default CommentsPopupHeader
