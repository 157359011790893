import React, { useState } from 'react'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { cancelEngagementSurvey } from '@src/api/engagement'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const useDeactivateSurvey = ({ surveyId }: { surveyId?: number }) => {
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()

  const deactivateSurvey = async () => {
    setIsPending(true)
    try {
      if (!surveyId) {
        throw new Error('Survey ID missing')
      }
      await cancelEngagementSurvey(surveyId)
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't deactivate test survey</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }
  return { deactivateSurvey, isPending }
}
