import { ActionButton, Button, StatusPopup } from '@revolut/ui-kit'
import { getQueryResultExportUrl } from '@src/api/dataAnalytics'
import { saveFile } from '@src/utils'
import Tooltip from '@components/Tooltip/Tooltip'
import React, { useState } from 'react'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'

export const ExportFileButton = ({
  url,
  format,
  queryId,
  runId,
}: {
  url: string
  format: 'xlsx' | 'csv'
  queryId: string
  runId: number
}) => {
  const showStatusPopup = useShowStatusPopup()
  const [isLoading, setIsLoading] = useState(false)
  const fileName = (fileUrl: string) =>
    fileUrl ? fileUrl.slice(fileUrl.lastIndexOf('/') + 1) : ''

  return (
    <Tooltip
      placement="left"
      text="You’ll be able to download the report when it’s done"
      hide={!!url}
    >
      <ActionButton
        disabled={!url}
        size="xs"
        pending={isLoading}
        onClick={() => {
          setIsLoading(true)
          getQueryResultExportUrl({
            queryId,
            runId,
            fileType: format,
          })
            .then(res => {
              if (res.data?.url) {
                saveFile(res.data.url, fileName(url))
              } else {
                showStatusPopup({
                  status: 'error',
                  title:
                    'This query run has no results to download. Please try to run again.',
                  actions: statusPopup => (
                    <StatusPopup.Actions>
                      <Button onClick={statusPopup.hide} variant="secondary">
                        Close
                      </Button>
                    </StatusPopup.Actions>
                  ),
                })
              }
            })
            .finally(() => {
              setIsLoading(false)
            })
        }}
      >
        {format || 'ccc'}
      </ActionButton>
    </Tooltip>
  )
}
