import React, { ChangeEvent, useEffect, useState } from 'react'
import LapeNewSwitch from '@components/Inputs/LapeFields/LapeNewSwitch'
import isEmpty from 'lodash/isEmpty'
import { SeniorityInterface } from '@src/interfaces/seniority'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { RequisitionInterface } from '@src/interfaces/requisitions'
import SenioritiesRange from '@src/pages/Forms/RoleForm/CompetencyMatrix/SenioritiesRange'
import { getSpecialisationSeniorities } from '@src/api/specialisations'
import { checkSeniorityRange } from '@src/features/SenioritySelector/useSenioritySelector'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

type Props = {
  alwaysMultipleSeniorities?: boolean
}

const SenioritiesSelect = ({ alwaysMultipleSeniorities }: Props) => {
  const { initialValues, values, errors } = useLapeContext<RequisitionInterface>()
  const [seniorities, setSeniorities] = useState<SeniorityInterface[]>([])
  const [isMultipleSeniorities, setMultipleSeniorities] = useState(
    initialValues.seniority_min?.id !== initialValues.seniority_max?.id,
  )

  useEffect(() => {
    const fetchSeniorities = async (specialisationId: string | number) => {
      const response = await getSpecialisationSeniorities(specialisationId)
      const sortedSeniorities = response.data.sort((a, b) => a.level - b.level)
      setSeniorities(sortedSeniorities)
    }
    if (values.specialisation?.id) {
      fetchSeniorities(values.specialisation.id)
    }
  }, [values.specialisation?.id])

  // Reset seniority if it's not supported by the selected specialisation
  useEffect(() => {
    const isMinInRange = checkSeniorityRange(values.seniority_min || null, seniorities)
    if (!isMinInRange) {
      values.seniority_min = undefined
    }

    const isMaxInRange = checkSeniorityRange(values.seniority_max || null, seniorities)
    if (!isMaxInRange) {
      values.seniority_max = undefined
    }
  }, [values.seniority_min, values.seniority_max, seniorities])

  const onMultipleSenioritiesChange = (e: ChangeEvent<HTMLInputElement>) => {
    values.seniority_min = values.seniority_max
    setMultipleSeniorities(e.target.checked)
  }

  return (
    <>
      {!alwaysMultipleSeniorities && (
        <LapeNewSwitch
          checked={isMultipleSeniorities}
          onChange={onMultipleSenioritiesChange}
          disabled={values?.field_options?.read_only?.includes('seniority_max')}
          itemTypeProps={{ title: 'Allow multiple seniorities' }}
        />
      )}

      {alwaysMultipleSeniorities || isMultipleSeniorities ? (
        <SenioritiesRange
          mb={0}
          seniorities={seniorities}
          disabled={isEmpty(seniorities)}
          message={
            !values.specialisation?.id
              ? 'Please, select a role (specialisation) first'
              : undefined
          }
          seniorityMaxValue={values.seniority_max}
          seniorityMinValue={values.seniority_min}
          onChangeMax={val => {
            values.seniority_max = val
            delete errors.seniority_max
          }}
          onChangeMin={val => {
            values.seniority_min = val
            delete errors.seniority_min
          }}
          prefill={false}
          minError={errors?.seniority_min as string}
          maxError={errors?.seniority_max as string}
        />
      ) : (
        <>
          <LapeRadioSelectInput
            name="seniority_max"
            disabled={isEmpty(seniorities)}
            message={
              !values.specialisation?.id && 'Please, select a role (specialisation) first'
            }
            label="Seniority"
            options={seniorities.map(o => ({ label: o.name, value: o }))}
            onAfterChange={() => {
              values.seniority_min = values.seniority_max
            }}
            loading={!seniorities}
          />
          <div
            data-name="seniority_min"
            style={{ opacity: 0, position: 'absolute', display: 'none' }}
          />
        </>
      )}
    </>
  )
}

export default SenioritiesSelect
