import React from 'react'
import styled from 'styled-components'
import DOMPurify from 'dompurify'
import { Text, Widget } from '@revolut/ui-kit'

const Content = styled(Text)`
  img {
    width: 100px;
  }
`

type Props = {
  content: string
}

const StyledHTMLLetter = ({ content }: Props) => {
  return (
    <Widget p="s-16">
      <Content
        whiteSpace="pre-line"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content),
        }}
      />
    </Widget>
  )
}

export default StyledHTMLLetter
