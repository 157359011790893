import { MoreBar } from '@revolut/ui-kit'
import {
  NotificationStatus,
  NotificationTemplateInterface,
} from '@src/interfaces/notificationTemplate'
import { Play, Unarchive, Archive } from '@revolut/icons'
import React, { useState } from 'react'
import { successNotification } from '@src/store/notifications/actions'
import { useLapeContext } from '@src/features/Form/LapeForm'

const ArchiveButton = () => {
  const [statusPending, setStatusPending] = useState(false)
  const form = useLapeContext<NotificationTemplateInterface>()
  const { values, submit } = form

  const isArchived = values.status.id === NotificationStatus.archived
  const isCreated = values.status.id === NotificationStatus.created

  const onStatusChange = (status: NotificationStatus) => {
    setStatusPending(true)
    values.status = { id: status, name: status }
    submit()
      .then(() => successNotification(`Notification set to ${status}`))
      .finally(() => setStatusPending(false))
  }

  return (
    <>
      {isCreated && !isArchived ? (
        <MoreBar.Action
          onClick={() => onStatusChange(NotificationStatus.active)}
          pending={statusPending}
          useIcon={Play}
        >
          Make active
        </MoreBar.Action>
      ) : null}
      <MoreBar.Action
        onClick={() =>
          onStatusChange(
            isArchived ? NotificationStatus.active : NotificationStatus.archived,
          )
        }
        pending={statusPending}
        useIcon={isArchived ? Unarchive : Archive}
        variant={isArchived ? 'primary' : 'negative'}
      >
        {isArchived ? 'Un-archive' : 'Archive'}
      </MoreBar.Action>
    </>
  )
}

export default ArchiveButton
