import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Color, H1, Paragraph, Widget } from '@revolut/ui-kit'
import { submitOptIn, useGetEngagementConsent } from '@src/api/engagementConsent'
import PublicPageLayout from '@src/components/CommonSC/PublicPageLayout'
import Loader from '@src/components/CommonSC/Loader'

type EngagementConsentParams = {
  token: string
}

const EngagementConsent = () => {
  const { token } = useParams<EngagementConsentParams>()
  const { data } = useGetEngagementConsent(token)
  const [loading, setLoading] = useState(true)
  const handleSubmit = async () => {
    try {
      await submitOptIn(token)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (data) {
      if (data.is_enabled) {
        handleSubmit()
      } else {
        setLoading(false)
      }
    }
  }, [data])
  if (loading) {
    return <Loader />
  }
  return (
    <PublicPageLayout noPadding>
      <Box mt="s-40" maxWidth={505} color={Color.FOREGROUND} px="s-16">
        <Widget p="s-24" mb="s-32">
          <H1 mb="s-8">Thank you!</H1>
          <Paragraph variant="secondary">
            Our recruitment team will contact you once we have a position matching your
            skills.
          </Paragraph>
        </Widget>
      </Box>
    </PublicPageLayout>
  )
}

export default EngagementConsent
