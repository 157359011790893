import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { chain, Text } from '@revolut/ui-kit'
import { connect } from 'lape'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getStatusColor } from '@src/components/CommonSC/General'
import { companyCompensationReviewsRequest } from '@src/api/compensation'
import { CompanyCompensationReviewsFormInterface } from '@src/interfaces/compensation'

import { Preview } from './Preview'
import { General } from './General'

const BudgetReviewCycle = () => {
  const { initialValues } = useLapeContext<CompanyCompensationReviewsFormInterface>()

  const title = initialValues.id
    ? chain(
        initialValues?.year,
        <Text color={getStatusColor(initialValues.status?.id)}>
          {initialValues.status?.name}
        </Text>,
      )
    : 'Add new review cycle'

  return (
    <PageWrapper>
      <PageHeader
        title={title}
        backUrl={ROUTES.APPS.COMPENSATION.REVIEW_CYCLES}
        subtitle={initialValues.id ? 'Review cycle' : null}
      />
      <Switch>
        <Route
          exact
          path={ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.GENERAL}
          component={General}
        />
        <Route
          exact
          path={ROUTES.FORMS.COMPANY_COMPENSATION_REVIEW_CYCLE.PREVIEW}
          component={Preview}
        />
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={companyCompensationReviewsRequest}>
    <BudgetReviewCycle />
  </Form>
))
