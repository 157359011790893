import { Group, Item, Avatar, chain, IconButton, Color, Box } from '@revolut/ui-kit'
import { People, Delete, Plus } from '@revolut/icons'
import pluralize from 'pluralize'
import React, { useState } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { SidebarContent } from '@src/features/CommunicationGroupSelector/SidebarContent'
import { CommunicationGroupInterface } from '@src/interfaces/communicationGroup'

interface CommunicationGroupSelectorProps {
  selectedGroups: CommunicationGroupInterface[]
  setSelectedGroups: (groups: CommunicationGroupInterface[]) => void
}

const CommunicationGroupSelector = ({
  selectedGroups,
  setSelectedGroups,
}: CommunicationGroupSelectorProps) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

  return (
    <>
      <Item use="button" type="button" onClick={() => setSidebarOpen(true)}>
        <Item.Avatar>
          <Avatar useIcon={Plus} />
        </Item.Avatar>
        <Item.Content color="blue">Add communication group</Item.Content>
      </Item>
      <Box mt="s-16">
        <Group>
          {selectedGroups.map(audience => (
            <Item key={audience.id}>
              <Item.Avatar>
                <Avatar useIcon={People} />
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{audience.name}</Item.Title>
                <Item.Description>
                  {chain(
                    audience.audience_type.name,
                    `${audience.members_count} ${pluralize(
                      'employee',
                      audience.members_count,
                    )}`,
                  )}
                </Item.Description>
              </Item.Content>
              <Item.Side>
                <IconButton
                  useIcon={Delete}
                  color={Color.GREY_TONE_50}
                  onClick={() => {
                    const updatedAudiencesValue = selectedGroups.filter(
                      currentAudience => currentAudience.id !== audience.id,
                    )
                    setSelectedGroups(updatedAudiencesValue)
                  }}
                />
              </Item.Side>
            </Item>
          ))}
        </Group>
      </Box>

      <SideBar
        variant="wide"
        isOpen={sidebarOpen}
        title="Select communication group"
        onClose={() => setSidebarOpen(false)}
      >
        <SidebarContent
          onClose={() => setSidebarOpen(false)}
          groups={selectedGroups}
          onAddGroups={addedAudiences => {
            const newAudiences = [...selectedGroups, ...addedAudiences]
            setSelectedGroups(newAudiences)
          }}
        />
      </SideBar>
    </>
  )
}

export default CommunicationGroupSelector
