import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormattedCurrency } from '@components/ColumnInserts/ColoredPercent/ColoredPercent'
import {
  CycleFilter,
  CycleFilterType,
} from '@components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { formatNumber } from '@src/utils/format'
import { TalentStatsInterface } from '@src/interfaces/functions'
import { getTalentTypes, TalentType } from '@src/interfaces/talent/talent'
import { BudgetStats, FilterByInterface } from '@src/interfaces/data'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import { Flex, H4 } from '@revolut/ui-kit'
import { BarChart, ChevronDown, ChevronUp } from '@revolut/icons'
import Stat from '@components/Stat/Stat'
import { noScrollBar } from '@src/components/CommonSC/General'
import { selectorKeys } from '@src/constants/api'
import { Currency } from '@src/interfaces/selectors'
import useFetchOptions from '@src/components/Inputs/hooks/useFetchOptions'
import isNumber from 'lodash/isNumber'
import { Period } from './Talent'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/FilterSelect'
import TalentHeaderNIPS from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderNIPS'
import TalentHeaderStats from '@src/pages/Forms/CommonTalentTab/TalentHeader/TalentHeaderStats'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetOrganisationSettings } from '@src/api/settings'
import { useIsNewTable } from '@src/components/TableV2/hooks'

const InlineFormattedCurrency = styled(FormattedCurrency)`
  display: inline-block;
`

type Props = {
  filterBy: FilterByInterface[]
  onFilterChange?: (filter: FilterByInterface) => void
  id: number
  tableStats?: BudgetStats
  type: TalentType
  showPerformanceStats: boolean
  showExpensesStats: boolean
  onCurrencyChange: (currency: string) => void
  period: Period
  showExpandedPerformanceStats?: boolean
}

const TalentHeader = ({
  tableStats,
  type,
  id,
  onFilterChange,
  filterBy,
  showPerformanceStats,
  showExpensesStats,
  onCurrencyChange,
  period,
  showExpandedPerformanceStats,
}: Props) => {
  const TalentTypes = getTalentTypes(id)
  const [stats, setStats] = useState<TalentStatsInterface>()

  const { options, asyncState } = useFetchOptions<Currency>(selectorKeys.currencies)
  const { data: settings } = useGetOrganisationSettings()

  const { stat, nipsGraphRequest, budgetsGraphRequest } = TalentTypes[type]

  useEffect(() => {
    getStats()
  }, [])

  const getStats = async () => {
    if (!stat) {
      return
    }
    const result = await stat()
    if (result.data) {
      setStats(result.data)
    }
  }

  const NIPS = stats?.nips ? +formatNumber(stats?.nips * 100, 1) : 0

  const isNewTable = useIsNewTable()

  const getSpendValue = () => {
    const expenses =
      period === 'annual'
        ? tableStats?.total_annual_expense
        : tableStats?.total_monthly_expense

    if (tableStats && isNumber(expenses)) {
      return (
        <RadioSelectInput<Currency>
          options={options}
          onChange={val => {
            if (val) {
              onCurrencyChange(val.iso_code)
            }
          }}
          disabled={asyncState !== 'ready'}
          renderInput={(open, setOpen, ref) => (
            <button type="button" onClick={() => setOpen(!open)} ref={ref}>
              <Flex alignItems="center" data-testid="spent">
                <H4 use="span" fontWeight="bold">
                  <InlineFormattedCurrency
                    amount={expenses}
                    currency={tableStats.expense_currency}
                  />
                </H4>
                {open ? (
                  <ChevronUp color="foreground" hoverColor="grey-tone-50" size={24} />
                ) : (
                  <ChevronDown color="foreground" hoverColor="grey-tone-50" size={24} />
                )}

                {budgetsGraphRequest && (
                  <GraphIconChart
                    id={id}
                    vertical="right"
                    fetchData={budgetsGraphRequest}
                  >
                    <Flex pr="s-12" ml="5px">
                      <BarChart cursor="pointer" size={16} color="grey-tone-20" />
                    </Flex>
                  </GraphIconChart>
                )}
              </Flex>
            </button>
          )}
        />
      )
    }

    if (expenses === null) {
      return 'N/A'
    }

    return undefined
  }

  return (
    <Flex mb={isNewTable ? 0 : 's-24'} style={{ overflowX: 'auto' }} css={noScrollBar}>
      <Stat label="Reports" val={tableStats?.headcount} mr="s-32" />
      {showPerformanceStats && nipsGraphRequest && (
        <TalentHeaderNIPS id={id} nips={NIPS} nipsGraphRequest={nipsGraphRequest} />
      )}
      {showPerformanceStats && showExpandedPerformanceStats && (
        <TalentHeaderStats
          aboveExpectations={stats?.above_expectations_and_exceptional_percentage}
          belowExpectations={stats?.unsatisfactory_and_below_expectations_percentage}
        />
      )}
      {showExpensesStats && !!settings?.enable_budget_management && (
        <Stat
          label={period === 'annual' ? 'Total yearly spend' : 'Total monthly spend'}
          val={getSpendValue()}
          mr={isNewTable ? 's-8' : 's-32'}
        />
      )}
      {!isNewTable && onFilterChange && (
        <CycleFilter
          type={CycleFilterType.NewUI}
          onFilterChange={onFilterChange}
          columnName="review_cycle__offset"
          filter={filterBy}
          filterInputType={FilterSelectType.SingleSelect}
        />
      )}
    </Flex>
  )
}

export default TalentHeader
