import React from 'react'
import { TableBodySkeleton } from '@components/Skeletons/TableBodySkeleton'
import { RowHeight } from '@src/interfaces/table'

type TableLoaderProps = {
  loading?: boolean
  containerWidth?: number
  rowHeight: RowHeight
}

const TableLoader = ({ loading, containerWidth, rowHeight }: TableLoaderProps) => {
  if (!loading) {
    return null
  }

  return <TableBodySkeleton containerWidth={containerWidth} rowHeight={rowHeight} />
}

export default TableLoader
