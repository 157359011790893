import React from 'react'
import { Text, Color, Box, Token } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { OnboardingQueueInterface } from '@src/interfaces/onboarding'
import { selectorKeys } from '../api'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { processStatusToColor } from '@src/pages/Forms/OnboardingTimeline/common'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '../routes'
import TableCellLink from '@src/components/TableCellLink/TableCellLink'

export const onboardingQueueEmployeeColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'employee.id',
  dataPoint: 'employee.full_name',
  sortKey: 'employee__full_name',
  filterKey: 'employee__id',
  selectorsKey: selectorKeys.hr_onboarding_employees,
  title: 'Employee',
  insert: ({ data }) => (
    <TableCellLink
      to={pathToUrl(ROUTES.FORMS.ONBOARDING_TIMELINE.START, {
        id: data.id,
        employeeId: data.employee.id,
      })}
    >
      <UserWithAvatar
        {...data.employee}
        /** To disable link to profile */
        id={undefined}
      />
    </TableCellLink>
  ),
}

export const onboardingQueueEmployeeStatusColumn: ColumnInterface<OnboardingQueueInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee_status.id',
    dataPoint: 'employee_status.name',
    sortKey: 'employee__status',
    filterKey: 'employee__status',
    selectorsKey: selectorKeys.employee_status_pending,
    title: 'Status',
  }

export const onboardingQueueStatusColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'status.id',
  dataPoint: 'status.name',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.hr_onboarding_process_statuses,
  title: 'Onboarding status',
  insert: ({ data }) => (
    /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
    <Text color={processStatusToColor[data.status.id]}>{data.status.name}</Text>
  ),
}

export const onboardingQueueStageColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'stage.id',
  dataPoint: 'stage.name',
  sortKey: 'current_stage__stage__name',
  filterKey: 'current_stage__stage__id',
  selectorsKey: selectorKeys.hr_onboarding_stages,
  title: 'Stage',
}

export const onboardingQueueStartDateColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.date,
  idPoint: 'start_date',
  dataPoint: 'start_date',
  sortKey: 'employee__joining_date_time',
  filterKey: 'employee__joining_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Start date',
}

export const onboardingQueueSeniorityColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'seniority.id',
  dataPoint: 'seniority.name',
  sortKey: 'employee__seniority__name',
  filterKey: 'employee__seniority__id',
  selectorsKey: selectorKeys.seniority,
  title: 'Seniority',
}

export const onboardingQueueRoleColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'role.id',
  dataPoint: 'role.name',
  sortKey: 'employee__position__name',
  filterKey: 'employee__position__id',
  selectorsKey: selectorKeys.role,
  title: 'Role (Specialisation)',
}

export const onboardingQueueTeamColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'team.id',
  dataPoint: 'team.name',
  sortKey: 'employee__team__name',
  filterKey: 'employee__team__id',
  selectorsKey: selectorKeys.team,
  title: 'Team',
}

export const onboardingQueueLocationColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.text,
  idPoint: 'location.id',
  dataPoint: 'location.name',
  sortKey: 'employee__location__location_name',
  filterKey: 'employee__location__id',
  selectorsKey: selectorKeys.location,
  title: 'Location',
}

export const onboardingQueueHrManagerColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'hr_manager.id',
  dataPoint: 'hr_manager.full_name',
  sortKey: 'hr_manager__full_name',
  filterKey: 'hr_manager__id',
  selectorsKey: selectorKeys.employee,
  title: 'HR manager',
  insert: ({ data }) => <UserWithAvatar {...data.hr_manager} />,
}

const getSLAColor = (numerator: number, denominator: number) => {
  const progress = numerator / denominator

  if (progress > 1) {
    return Token.color.red
  }
  if (progress >= 0.7 && progress <= 1) {
    return Token.color.orange
  }
  return Token.color.foreground
}

export const onboardingQueueTotalSLAColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'sla',
  dataPoint: '',
  sortKey: 'sla',
  filterKey: 'sla',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Total SLA',
  headerTooltip: (
    <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
      Amount of time consumed for the onboarding process in reference to the estimated
      process' SLA.
    </Box>
  ),
  insert: ({ data }) => (
    <Text color={getSLAColor(data.sla, data.max_sla)}>
      {data.sla}/{data.max_sla}h
    </Text>
  ),
}

export const onboardingQueueTimeLeftColumn: ColumnInterface<OnboardingQueueInterface> = {
  type: CellTypes.insert,
  idPoint: 'current_stage__sla',
  dataPoint: '',
  sortKey: 'current_stage__sla',
  filterKey: 'current_stage__sla',
  filterType: FilterType.range,
  selectorsKey: selectorKeys.none,
  title: 'Time left',
  headerTooltip: (
    <Box color={Color.BACKGROUND} p="s-8" minWidth={250}>
      Amount of time that is left to complete the onboarding stage within its SLA.
    </Box>
  ),
  insert: ({ data }) => (
    <Text color={getSLAColor(data.stage_sla, data.stage.sla_in_hours)}>
      {data.stage_sla}/{data.stage.sla_in_hours}h
    </Text>
  ),
}
