import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'lape'
import { dashboardRequestsNew } from '@src/api/analyticsDashboards'
import { ROUTES } from '@src/constants/routes'
import Form from '@src/features/Form/Form'
import { DashboardForm } from './DashboardForm'

const DashboardView = connect(() => {
  return (
    <Route exact path={ROUTES.FORMS.VIEW_DATA_ANALYTICS_DASHBOARD}>
      <DashboardForm />
    </Route>
  )
})

const DashboardViewForm = () => {
  return (
    <Form api={dashboardRequestsNew}>
      <DashboardView />
    </Form>
  )
}

export default connect(DashboardViewForm)
