import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { AnnouncementInterface } from '@src/interfaces/announcements'
import { AxiosPromise } from 'axios'
import { api } from '@src/api/index'

export const useGetUnreadAnnouncements = () => {
  const context = useFetch<{ results: AnnouncementInterface[] }>(
    API.ANNOUNCEMENTS,
    'v1',
    undefined,
    undefined,
  )
  return { ...context, data: context.data?.results }
}

export const markAnnouncementAsRead = (id: number): AxiosPromise<AnnouncementInterface> =>
  api.post(`${API.ANNOUNCEMENTS}/${id}/read`)

export const dismissAnnouncement = (id: number): AxiosPromise<AnnouncementInterface> =>
  api.post(`${API.ANNOUNCEMENTS}/${id}/dismiss`)
