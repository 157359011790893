import React, { Dispatch } from 'react'

import { Chat, Check, Cross } from '@revolut/icons'
import { Color } from '@revolut/ui-kit'
import {
  CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL,
  CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL_GROUP_PRE_FILLED,
} from '@src/constants/externalLinks'
import { ServiceDeskCategoryInterface } from '@src/interfaces/faq'

// types
//

export type MessageFrom = 'chatbot' | 'user' | 'suggestion' | 'command'

export interface MessageInterface {
  id: number
  from: MessageFrom
  text: string
  time: string
}

export interface SuggestionInterface extends MessageInterface {
  from: 'suggestion'
}

export interface CommandInterface extends MessageInterface {
  from: 'command'
}

export interface QuestionContextInterface {
  summary?: string
  description?: string
  create?: boolean
}

export interface DataInterface {
  message?: MessageInterface
  confirmEndConversation?: boolean
  commands?: CommandInterface[]
  questionContext?: QuestionContextInterface
  inputMode?: InputMode
  sdCategories?: ServiceDeskCategoryInterface[]
  selectedCategory?: ServiceDeskCategoryInterface
}

export interface ActionInterface {
  type:
    | 'addMessageToHistory'
    | 'setPending'
    | 'resetPending'
    | 'endConversation'
    | 'restartConversation'
    | 'showSuggestions'
    | 'hideSuggestions'
    | 'showCommands'
    | 'hideCommands'
    | 'setInputMode'
    | 'setQuestionContext'
    | 'triggerTicketCreation'
    | 'conversationStarted'
    | 'issueResolved'
    | 'setSDCategories'
    | 'setSelectedCategory'
  data?: DataInterface
}

export type AddMessageToHistory = ({
  text,
  from,
}: {
  text: string
  from: 'user' | 'chatbot'
}) => void

export type SendUserMessage = ({
  callApi,
  addToHistory,
  text,
}: {
  callApi?: boolean
  addToHistory?: boolean
  text?: string
}) => Promise<void>

export type InputMode =
  | 'default'
  | 'waitForCmdOptionSelect'
  | 'getTicketSummary'
  | 'getTicketDescription'

export interface StateInterface {
  pending: boolean
  confirmEndConversation: boolean
  showSuggestions: boolean
  suggestions: SuggestionInterface[]
  showCommands: boolean
  commands: CommandInterface[]
  history: MessageInterface[]
  questionContext: QuestionContextInterface
  inputMode: InputMode
  isStartOfConversation: boolean
  issueResolved: boolean
  sdCategories: ServiceDeskCategoryInterface[]
  selectedCategory?: ServiceDeskCategoryInterface
  categoriesOptions: CommandInterface[]
  subcategoriesOptions: CommandInterface[]
}

// constants
//

export const CMD_GOT_IT = 'Got it, thanks'
export const CMD_NEED_MORE_HELP = 'I need more help'
export const CMD_REACH_OUT_HR_SD = 'Contact Revolut Shared Services (via service desk)'
export const CMD_SUBMIT_TICKET_CREATION_YES = 'Yes'
export const CMD_SUBMIT_TICKET_CREATION_NO = 'No'
export const CMD_SOMETHING_ELSE = 'Something else'

const TEXT_SERVICE_DESK_OPENED =
  'I have opened a new tab with the appropriate service desk. Let me know if you need help with something else!'

export type CommandText =
  | typeof CMD_GOT_IT
  | typeof CMD_NEED_MORE_HELP
  | typeof CMD_REACH_OUT_HR_SD
  | typeof CMD_SUBMIT_TICKET_CREATION_YES
  | typeof CMD_SUBMIT_TICKET_CREATION_NO
  | typeof CMD_SOMETHING_ELSE

export const DEFAULT_SUGGESTIONS = [
  'How do I get access to my payslips?',
  'How do I book leave?',
  'What is the ergonomic budget for?',
  'I’ve been in a work accident. What should I do?',
]

export const defaultContext: QuestionContextInterface = {
  summary: '',
  description: '',
  create: false,
}

// TODO: replace hardcoded data with values from global settings when implemented on BE

export const DEFAULT_ISSUE_TYPE = 'Triage'
export const DEFAULT_PROJECT_KEY = 'SSC'

export const topicNameToCategory: Record<string, string> = {
  'Environmental Health & Safety': '71763',
  'Facilities Management (WX)': '71764',
  'Global Mobility': '71765',
  HR: '71766',
  IT: '71767',
  'Learning & Development': '71768',
  Payroll: '71769',
  Performance: '71770',
  Recruitment: '71771',
  'Rewards & Benefits': '71772',
  Security: '71773',
  'Slack Support': '71774',
  Workforce: '71775',
}

// helpers
//

export const getNewMessageId = () => Math.floor(Math.random() * 1000000)

export const mapTextToSuggestion = (text: string): SuggestionInterface => ({
  id: getNewMessageId(),
  from: 'suggestion',
  text,
  time: '',
})

export const mapTextToCommand = (text: string): CommandInterface => ({
  id: getNewMessageId(),
  from: 'command',
  text,
  time: '',
})

export const getCategoryNamesWithSubcategories = (
  categories: ServiceDeskCategoryInterface[],
) => categories?.map(category => category.name) || []

export const getSubcategoryNamesByCategoryName = (
  categories: ServiceDeskCategoryInterface[],
  name: string,
) => {
  const foundCategory = categories.find(category => category?.name === name)
  return foundCategory?.subcategories?.map(subcategory => subcategory.name) || []
}

export const END_CONVERSATION_COMMANDS = [CMD_GOT_IT, CMD_NEED_MORE_HELP].map(
  mapTextToCommand,
)

export const SUBMIT_TICKET_CREATION_COMMANDS = [
  CMD_SUBMIT_TICKET_CREATION_NO,
  CMD_SUBMIT_TICKET_CREATION_YES,
].map(mapTextToCommand)

export const commandToIcon = (cmd: CommandText) => {
  const iconProps = { size: 18, color: Color.BLUE }

  switch (cmd) {
    case CMD_GOT_IT:
    case CMD_SUBMIT_TICKET_CREATION_YES:
      return <Check {...iconProps} />
    case CMD_NEED_MORE_HELP:
    case CMD_SUBMIT_TICKET_CREATION_NO:
      return <Cross {...iconProps} />
    case CMD_REACH_OUT_HR_SD:
      return <Chat {...iconProps} />
    default:
      return null
  }
}

export const buildPreFilledHelpDeskUrl = ({
  summary,
  employeeId,
  categoryId,
}: {
  summary: string
  employeeId: number
  categoryId: string | undefined
}) =>
  `${CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL_GROUP_PRE_FILLED}?summary=${summary}&customfield_34442=${employeeId}${
    categoryId ? `&customfield_34443=${categoryId}` : ''
  }`

export const buildHelpDeskUrl = ({
  key,
  helpDeskUrl = CHAT_BOT_HELP_SERVICE_DESK_CUSTOMER_PORTAL,
}: {
  key?: string
  helpDeskUrl?: string | null
}) => {
  if (!key) {
    return undefined
  }
  return `${helpDeskUrl}/${key}`
}

export const CREATE_JIRA_TICKET_COMMANDS = [CMD_REACH_OUT_HR_SD].map(mapTextToCommand)

export const createTicketDataCollectionFlow =
  (dispatch: Dispatch<ActionInterface>, addMessageToHistory: AddMessageToHistory) =>
  (state: StateInterface) => {
    switch (state.inputMode) {
      case 'default':
      case 'waitForCmdOptionSelect':
        dispatch({ type: 'hideCommands' })
        dispatch({ type: 'hideSuggestions' })

        if (!state.questionContext.summary) {
          addMessageToHistory({
            from: 'chatbot',
            text: 'Please, provide the ticket summary',
          })
          dispatch({
            type: 'setInputMode',
            data: { inputMode: 'getTicketSummary' },
          })
        } else {
          dispatch({
            type: 'setInputMode',
            data: { inputMode: 'default' },
          })
          dispatch({ type: 'triggerTicketCreation' })
        }
        break

      case 'getTicketSummary':
        addMessageToHistory({
          from: 'chatbot',
          text: 'Please, provide the ticket description',
        })
        dispatch({
          type: 'setInputMode',
          data: { inputMode: 'getTicketDescription' },
        })
        break

      case 'getTicketDescription':
        // this step is for future use, skipped for now
        dispatch({
          type: 'setInputMode',
          data: { inputMode: 'default' },
        })
        dispatch({ type: 'triggerTicketCreation' })
        break

      default:
        dispatch({ type: 'setInputMode', data: { inputMode: 'default' } })
        dispatch({
          type: 'setQuestionContext',
          data: { questionContext: defaultContext },
        })
        break
    }
  }

export const handleCommandClick = async (
  cmd: CommandInterface,
  state: StateInterface,
  dispatch: Dispatch<ActionInterface>,
  addMessageToHistory: AddMessageToHistory,
  collectTicketData: ReturnType<typeof createTicketDataCollectionFlow>,
) => {
  const { sdCategories, selectedCategory, categoriesOptions, subcategoriesOptions } =
    state

  const subcategoriesByName = (name: string) =>
    getSubcategoryNamesByCategoryName(sdCategories, name).map(mapTextToCommand)

  addMessageToHistory({ from: 'user', text: cmd.text })

  if (cmd.text === CMD_GOT_IT) {
    dispatch({ type: 'hideSuggestions' })
    dispatch({ type: 'hideCommands' })
    dispatch({ type: 'endConversation' })
  } else if (cmd.text === CMD_NEED_MORE_HELP) {
    dispatch({ type: 'showSuggestions' })
    dispatch({
      type: 'showCommands',
      data: {
        commands: CREATE_JIRA_TICKET_COMMANDS,
        inputMode: 'default',
      },
    })
  } else if (cmd.text === CMD_REACH_OUT_HR_SD) {
    dispatch({ type: 'hideSuggestions' })
    dispatch({ type: 'hideCommands' })
    addMessageToHistory({
      from: 'chatbot',
      text: 'Have you used search in Confluence to find an answer to your question?',
    })
    dispatch({
      type: 'showCommands',
      data: {
        commands: SUBMIT_TICKET_CREATION_COMMANDS,
        inputMode: 'waitForCmdOptionSelect',
      },
    })
  } else if (cmd.text === CMD_SUBMIT_TICKET_CREATION_NO) {
    dispatch({ type: 'hideSuggestions' })
    dispatch({ type: 'hideCommands' })
    addMessageToHistory({
      from: 'chatbot',
      text: 'Please, use Confluence search first',
    })
    dispatch({ type: 'restartConversation' })
    dispatch({ type: 'showSuggestions' })
  } else if (cmd.text === CMD_SUBMIT_TICKET_CREATION_YES) {
    dispatch({ type: 'hideSuggestions' })
    dispatch({ type: 'hideCommands' })

    addMessageToHistory({
      from: 'chatbot',
      text: 'What category is your problem related to?',
    })
    dispatch({
      type: 'showCommands',
      data: {
        commands: categoriesOptions,
        inputMode: 'waitForCmdOptionSelect',
      },
    })
  } else if (categoriesOptions.some(category => category.text === cmd.text)) {
    const SELECTED_CATEGORY = sdCategories.find(category => category.name === cmd.text)
    dispatch({
      type: 'setSelectedCategory',
      data: { selectedCategory: SELECTED_CATEGORY },
    })
    if (SELECTED_CATEGORY?.subcategories?.length === 0) {
      dispatch({ type: 'hideSuggestions' })
      dispatch({ type: 'hideCommands' })
      addMessageToHistory({
        from: 'chatbot',
        text: TEXT_SERVICE_DESK_OPENED,
      })
      window.open(SELECTED_CATEGORY?.link, '_blank')
      dispatch({ type: 'endConversation' })
    } else {
      addMessageToHistory({
        from: 'chatbot',
        text: 'Thanks. Please also select a subcategory:',
      })
      dispatch({
        type: 'showCommands',
        data: {
          commands: subcategoriesByName(cmd.text),
          inputMode: 'waitForCmdOptionSelect',
        },
      })
    }
  } else if (subcategoriesOptions.some(subcategory => subcategory.text === cmd.text)) {
    const SELECTED_SUBCATEGORY = selectedCategory?.subcategories?.find(
      subcategory => subcategory.name === cmd.text,
    )
    dispatch({ type: 'hideSuggestions' })
    dispatch({ type: 'hideCommands' })
    addMessageToHistory({
      from: 'chatbot',
      text: TEXT_SERVICE_DESK_OPENED,
    })
    window.open(SELECTED_SUBCATEGORY?.link, '_blank')
    dispatch({ type: 'endConversation' })
  } else if (cmd.text === CMD_SOMETHING_ELSE) {
    collectTicketData(state)
  }
}
