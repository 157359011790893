import React from 'react'
import { GetRequestData } from '@src/interfaces'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  OnboardingStatus,
  OnboardingTemplateInterface,
} from '@src/interfaces/onboardingV2'
import { DataHandlerInterface } from '@components/FormPreview/FormPreview'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { ProfileSectionsData } from '@src/pages/EmployeeProfile/Preview/hooks'
import { EmployeeInformation } from './components/EmployeeInformation'
import { OnboardingInformation } from './components/OnboardingInformation'

type Props = {
  data: EmployeeInterface
  documentsSignature?: GetRequestData<OnboardingTemplateInterface>
  documentsUpload?: GetRequestData<OnboardingTemplateInterface>
  status: OnboardingStatus
  onRefetchDocumentsSignature: VoidFunction
  onRefetchDocumentsUpload: VoidFunction
  primaryDataHandler: DataHandlerInterface<EmployeeInterface>
  sectionsData: ProfileSectionsData
}

export const OverviewTab = ({
  data,
  documentsSignature,
  documentsUpload,
  status,
  onRefetchDocumentsSignature,
  onRefetchDocumentsUpload,
  primaryDataHandler,
  sectionsData,
}: Props) => {
  return (
    <TwoColumnsLayout
      left={
        <OnboardingInformation
          documentsSignature={documentsSignature}
          documentsUpload={documentsUpload}
          onRefetchDocumentsSignature={onRefetchDocumentsSignature}
          onRefetchDocumentsUpload={onRefetchDocumentsUpload}
          status={status}
        />
      }
      right={
        <EmployeeInformation
          data={data}
          primaryDataHandler={primaryDataHandler}
          sectionsData={sectionsData}
        />
      }
    />
  )
}
