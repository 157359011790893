import React from 'react'
import { OptionInterface } from '@src/interfaces/selectors'
import {
  Avatar,
  Icon,
  Item,
  ItemSkeleton,
  RadioSelect,
  Token,
  useDropdown,
} from '@revolut/ui-kit'

type SelectTimezoneProps = {
  loading: boolean
  options: OptionInterface[]
  timezone?: OptionInterface
  onChange: (timezone: OptionInterface<string | number> | null) => void
}

export const SelectTimezone = ({
  loading,
  options,
  timezone,
  onChange,
}: SelectTimezoneProps) => {
  const dropdown = useDropdown()
  if (loading) {
    return <ItemSkeleton />
  }
  return (
    <>
      <Item use="label" aria-label="Select time zone" {...dropdown.getAnchorProps()}>
        <Item.Avatar>
          <Avatar bg="transparent" color={Token.color.foreground} useIcon="Globe" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{timezone?.name || ''}</Item.Title>
          <Item.Description>Time Zone</Item.Description>
        </Item.Content>
        <Item.Side>
          <Icon name="ChevronDown" />
        </Item.Side>
      </Item>
      <RadioSelect
        {...dropdown.getTargetProps()}
        autoClose
        labelList="Timezones"
        fitInAnchor
        options={options.map(val => ({
          key: val.id,
          label: val.name,
          value: val,
        }))}
        searchable
        value={timezone}
        onChange={onChange}
      />
    </>
  )
}
