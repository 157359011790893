import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'lape'
import { Route, Switch, useParams } from 'react-router-dom'
import { Bar, Box, Flex, Sticky } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ReviewCategory, ReviewSummaryInterface } from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import PerformancePage from '@src/pages/Forms/EmployeePerformance/components/PerformancePage'
import ViewNavigation from '@src/pages/Forms/EmployeePerformance/components/ViewNavigation'
import Filters from '@src/pages/Forms/EmployeePerformance/components/Filters'
import NextButton from '@src/pages/Forms/EmployeePerformance/components/NextButton'
import { TabsInterface } from '@src/interfaces/data'
import { Queries } from '@src/constants/api'
import { useQuery } from '@src/utils/queryParamsHooks'
import EditButton from '@src/pages/Forms/EmployeePerformance/components/EditButton'
import NoReviewsWidget from '@src/pages/EmployeeProfile/Preview/Performance/Common/NoReviewsWidget'
import { ROUTES } from '@src/constants/routes'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

type Props = {
  getTabs: (
    params: any,
    isManagerReview: boolean,
  ) => TabsInterface<ReviewSummaryInterface>[]
  category: ReviewCategory
}

const PerformanceViewContent = ({ getTabs, category }: Props) => {
  const { query } = useQuery()
  const params = useParams<{ id: string; employeeId: string }>()
  const [loading, setLoading] = useState(true)
  const [isBadFilter, setBadFilter] = useState(false)
  const form = useLapeContext<ReviewSummaryInterface>()
  const { initialValues } = form
  const cycleId = params.id
  const { data: performanceSettings } = useGetPerformanceSettings()

  useEffect(() => {
    const initializeForm = async () => {
      if (!!query[Queries.ReviewerId] || !!query[Queries.CheckpointNumber]) {
        const data = await fetchSummary()
        if (data) {
          form.values = data
        }
      } else {
        form.values = initialValues as ReviewSummaryInterface
        setBadFilter(false)
      }
      setLoading(false)
    }

    initializeForm()
  }, [query])

  const fetchSummary = async () => {
    const perfFilters = [
      {
        filters: query[Queries.ReviewerId]
          ? query[Queries.ReviewerId].split(',').map(val => ({
              name: `${val}`,
              id: `${val}`,
            }))
          : [],
        columnName: category === ReviewCategory.Upwards ? 'id' : 'reviewer__id',
      },
      {
        filters: query[Queries.CheckpointNumber]
          ? query[Queries.CheckpointNumber].split(',').map(val => ({
              name: `${val}`,
              id: `${val}`,
            }))
          : [],
        columnName: 'probation_checkpoint__number',
      },
    ]
    const result = await getPerformanceReviewSummary(
      cycleId,
      +params.employeeId,
      category,
      perfFilters,
    )

    if (!result.data.summary) {
      setBadFilter(true)
      return null
    }

    setBadFilter(false)
    return result.data
  }

  const isManagerReview = !!form.values.summary?.kpis_section

  const tabs = useMemo(
    () =>
      getTabs(params, isManagerReview).filter(tab =>
        tab.visibilityCheck(initialValues as ReviewSummaryInterface, {
          performanceSettings,
        }),
      ),
    [loading, performanceSettings],
  )

  if (loading) {
    return null
  }

  return (
    <PerformancePage>
      <ViewNavigation tabs={tabs} />
      <Filters cycleId={cycleId} />
      {isBadFilter ? (
        <Box maxWidth={628}>
          <NoReviewsWidget filtersApplied />
        </Box>
      ) : (
        <>
          <Flex flex="1 0">
            <Switch>
              {tabs.map(tab => (
                <Route exact path={tab.path} key={tab.path}>
                  <tab.component isView category={category} />
                </Route>
              ))}
              <InternalRedirect to={ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL} />
            </Switch>
          </Flex>
          <Sticky bottom={24}>
            <Flex justifyContent="center" width={628}>
              <Bar>
                <EditButton />
                <NextButton tabs={tabs} />
              </Bar>
            </Flex>
          </Sticky>
        </>
      )}
    </PerformancePage>
  )
}

export default connect(PerformanceViewContent)
