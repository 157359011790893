import React from 'react'
import { ActionButton, ActionButtonProps, MoreBar } from '@revolut/ui-kit'
import { ReviewCategory } from '@src/interfaces/performance'
import { useLocation } from 'react-router-dom'
import { getReviewsUrl } from './common'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface Props extends ActionButtonProps {
  category?: ReviewCategory
  cycleId?: string
  employeeId?: number
  useMoreBar?: boolean
}

const OpenReviewButton = ({
  category,
  cycleId,
  employeeId,
  useMoreBar,
  ...buttonProps
}: Props) => {
  const location = useLocation()

  const Component: React.ComponentType<ActionButtonProps> = useMoreBar
    ? MoreBar.Action
    : ActionButton

  return (
    <Component
      use={InternalLink}
      useIcon="LinkExternal"
      to={getReviewsUrl(category, cycleId, employeeId, location)}
      target="_blank"
      data-testid="open-review-btn"
      {...buttonProps}
    >
      Open Review
    </Component>
  )
}

export default OpenReviewButton
