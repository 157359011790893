import React, { ReactNode, useState } from 'react'
import { Button, Header, Popup } from '@revolut/ui-kit'
import { FilterByInterface, RowInterface, SORT_DIRECTION } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { useTable, useTableReturnType } from '@components/Table/hooks'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { Plus } from '@revolut/icons'
import { PermissionTypes } from '@src/store/auth/types'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { ownerNameColumn } from '@src/constants/columns/employee'
import {
  skillCategoryColumn,
  skillFunctionColumn,
  skillGenericNameColumn,
  skillSpecialisationsCountColumn,
  skillStatusColumn,
  skillTalentCountColumn,
  skillValidUntilColumn,
} from '@src/constants/columns/skill'
import { skillRequests, skillRequestsNew } from '@src/api/skills'
import { SkillInterface, SkillType } from '@src/interfaces/skills'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { SkillForm } from '@src/pages/Forms/SkillForm/General'
import Table from '@src/components/TableV2/Table'
import { PrimaryAction } from '@src/components/PrimaryAction/PrimaryAction'
import { SkillsAndValuesStatNavigation } from '@src/apps/Performance/Skills/SkillsAndValuesStatNavigation'

interface SkillsTableProps {
  createRow?: (table: useTableReturnType<SkillInterface>) => RowInterface<SkillInterface>
  createVariant?: 'popup' | 'navigation'
  skillForm?: ReactNode
  popupTitle?: ReactNode
  initialFilter?: FilterByInterface[]
  asValue?: boolean
}

const SkillsTable = ({
  createVariant,
  createRow,
  skillForm,
  popupTitle = 'Create new skill',
  initialFilter = [],
}: SkillsTableProps) => {
  const sortBy = [
    {
      sortBy: 'name',
      direction: SORT_DIRECTION.DESC,
    },
  ]
  const [showNewPopup, setShowNewPopup] = useState(false)
  const table = useTable<SkillInterface>(skillRequests, initialFilter, sortBy)
  const permissions = useSelector(selectPermissions)

  const canAdd = permissions.includes(PermissionTypes.AddSkillset)

  const defaultRow: RowInterface<SkillInterface> = {
    linkToForm: ({ id }) => navigateTo(pathToUrl(ROUTES.FORMS.SKILL.PREVIEW, { id })),
    cells: [
      {
        ...skillGenericNameColumn,
        width: 230,
      },
      {
        ...ownerNameColumn,
        width: 170,
      },
      {
        ...skillFunctionColumn,
        width: 140,
      },
      {
        ...skillCategoryColumn,
        width: 140,
      },
      {
        ...skillSpecialisationsCountColumn,
        width: 100,
      },
      {
        ...skillTalentCountColumn,
        width: 100,
      },
      {
        ...skillStatusColumn,
        width: 180,
      },
      {
        ...skillValidUntilColumn,
        width: 120,
      },
    ],
  }

  const moreActionProps =
    createVariant === 'popup'
      ? { onClick: () => setShowNewPopup(true) }
      : {
          use: InternalLink,
          to: pathToUrl(ROUTES.FORMS.SKILL.GENERAL),
        }

  return (
    <>
      <Table.Widget>
        <Table.Widget.Info>
          <SkillsAndValuesStatNavigation skillsTableFilters={table.filterBy} />
        </Table.Widget.Info>
        {canAdd && (
          <Table.Widget.Actions>
            <Table.Widget.MoreBar>
              <PrimaryAction {...moreActionProps} useIcon={Plus}>
                {popupTitle}
              </PrimaryAction>
            </Table.Widget.MoreBar>
          </Table.Widget.Actions>
        )}
        <Table.Widget.Table>
          <AdjustableTable<SkillInterface>
            name={TableNames.Skills}
            useWindowScroll
            dataType="Skill"
            row={createRow ? createRow(table) : defaultRow}
            {...table}
            noDataMessage="Skills will appear here."
            hideCount
          />
        </Table.Widget.Table>
      </Table.Widget>
      <Popup open={showNewPopup} onClose={() => setShowNewPopup(false)} size="md">
        <Header displayMode="inline">
          <Header.CloseButton aria-label="close" />
          <Header.Title>{popupTitle}</Header.Title>
        </Header>
        <Form api={skillRequestsNew}>
          {skillForm || (
            <SkillForm
              hidePlaybookInput
              typeOptions={[SkillType.Functional, SkillType.Executive]}
            />
          )}
          <Popup.Actions horizontal>
            <Button variant="secondary" onClick={() => setShowNewPopup(false)}>
              Cancel
            </Button>
            <NewSaveButtonWithPopup
              useValidator
              onAfterSubmit={() => {
                table.refresh()
                setShowNewPopup(false)
              }}
            />
          </Popup.Actions>
        </Form>
      </Popup>
    </>
  )
}

export default SkillsTable
