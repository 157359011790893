import React, { useEffect, useMemo, useState } from 'react'
import { Button, Header, InputGroup, Popup, Widget } from '@revolut/ui-kit'
import {
  InterviewStageWithoutRoundInterface,
  InterviewToolInterviewer,
} from '@src/interfaces/interviewTool'
import { selectorKeys } from '@src/constants/api'
import { createFeedback } from '@src/api/recruitment/interviewFeedback'
import GenerateLinkSuccessPopup from '@src/pages/Forms/Candidate/InterviewProgress/components/Popups/GenerateLinkSuccessPopup'
import { useGetSelectors } from '@src/api/selectors'
import { useGetEligibleInterviewers } from '@src/api/recruitment/interviewerScheduling'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { NON_ELIGIBLE_FEEDBACK_STAGES } from '@src/pages/Forms/Candidate/utils'

enum InterviewerType {
  Eligible = 'eligible',
  Any = 'any',
}

const interviewerTypeOptions = [
  {
    value: {
      id: InterviewerType.Eligible,
      name: 'Eligible interviewers',
    },
    label: 'Eligible interviewers',
  },
  {
    value: {
      id: InterviewerType.Any,
      name: 'Any employee',
    },
    label: 'Any employee',
  },
]

type Props = {
  data: InterviewStageWithoutRoundInterface[]
  roundId: number
  isOpen: boolean
  onClose: () => void
  onRefresh?: () => void
  initialStageId?: number
  currentStageId?: number
}

const GenerateLinkPopup = ({
  data,
  roundId,
  isOpen,
  onClose,
  onRefresh,
  initialStageId,
  currentStageId,
}: Props) => {
  const [stageId, setStageId] = useState<number | undefined>(initialStageId)
  const [interviewId, setInterviewId] = useState<number>()
  const [user, setUser] = useState<InterviewToolInterviewer | null>(null)
  const [interviewerType, setInterviewerType] = useState<InterviewerType>(
    InterviewerType.Eligible,
  )
  const [pending, setPending] = useState(false)
  const [success, setSuccess] = useState(false)
  const { data: eligibleReviewersData, isLoading: eligibleReviewersLoading } =
    useGetEligibleInterviewers(isOpen ? stageId : undefined, roundId)
  const employeeSelector = useGetSelectors(isOpen ? selectorKeys.employee : null)
  const employees = useMemo(
    () =>
      employeeSelector.data?.map(item => ({
        value: item as InterviewToolInterviewer,
        label: item.full_name!,
      })) || [],
    [employeeSelector],
  )

  const eligibleStages = useMemo(
    () =>
      data
        .filter(item => !NON_ELIGIBLE_FEEDBACK_STAGES.includes(item.interview_type))
        .map(option => {
          const label = `${option.title} · ${
            data.findIndex(item => item.id === option.id) + 1
          }/${data.length}`

          return {
            label,
            value: {
              id: option.id,
              name: label,
            },
          }
        }),
    [data, currentStageId],
  )

  const eligibleReviewers = useMemo(
    () =>
      eligibleReviewersData?.map(item => ({
        value: item as InterviewToolInterviewer,
        label: item.full_name!,
      })) || [],
    [eligibleReviewersData],
  )

  useEffect(() => {
    setUser(null)
  }, [interviewerType])

  const onExit = () => {
    setStageId(initialStageId)
    setUser(null)
    setPending(false)
    onClose()
  }

  const generateLink = async () => {
    setPending(true)
    try {
      const resp = await createFeedback(stageId!, user!)
      setInterviewId(resp.data.id)
      setSuccess(true)
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      <Popup open={isOpen} onClose={onExit} variant="bottom-sheet">
        <Header variant="bottom-sheet">
          <Header.Title>Select interview details</Header.Title>
        </Header>
        <Widget bg="layout-background">
          <InputGroup>
            <RadioSelectInput
              value={eligibleStages.find(item => item.value.id === stageId)?.value}
              options={eligibleStages}
              onChange={option => {
                if (option) {
                  setStageId(option.id)
                }
              }}
              label="Select stage"
              clearable={false}
            />
            <RadioSelectInput
              options={interviewerTypeOptions}
              onChange={val => {
                if (val) {
                  setInterviewerType(val.id)
                }
              }}
              label="Interviewer type"
              value={
                interviewerTypeOptions.find(item => item.value.id === interviewerType)
                  ?.value
              }
              searchable={false}
            />
            <RadioSelectInput<InterviewToolInterviewer>
              options={
                interviewerType === InterviewerType.Any ? employees : eligibleReviewers
              }
              onChange={setUser}
              label="Select interviewer"
              value={user}
              loading={eligibleReviewersLoading}
            />
          </InputGroup>
        </Widget>
        <Popup.Actions horizontal>
          <Button variant="secondary" onClick={onExit} autoFocus>
            Cancel
          </Button>
          <Button
            elevated
            disabled={pending || !user || !stageId}
            onClick={generateLink}
            pending={pending}
          >
            Generate link
          </Button>
        </Popup.Actions>
      </Popup>

      {success && interviewId && (
        <GenerateLinkSuccessPopup
          interviewId={interviewId}
          onClose={() => {
            setSuccess(false)
            onRefresh?.()
            onClose()
          }}
        />
      )}
    </>
  )
}

export default GenerateLinkPopup
