import { IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { AttachedTemplateInterface } from '@src/interfaces/documentsTemplates'
import { DocumentStatuses } from '@src/interfaces/documents'

export type AssigneeType = 'relationship' | 'employee' | 'group'

export enum OnboardingStatus {
  not_started = 'not_started',
  in_progress = 'in_progress',
  completed = 'completed',
  cancelled = 'cancelled',
}

export enum OnboardingTemplateStatus {
  pending_request = 'pending_request',
  pending_upload = 'pending_upload',
  pending_approval = 'pending_approval',
  pending_signature = 'pending_signature',
  rejected = 'rejected',
  completed = 'completed',
}

export interface OnboardingInterface {
  id: number
  cancel_reason: string | null
  employee: EmployeeOptionInterface
  hr_manager: EmployeeOptionInterface
  status: IdAndName<OnboardingStatus>
  creation_date_time: string
  update_date_time: string
}

export interface OnboardingStatsInterface {
  emails: number
  comments: number
  timeline: number
  documents_upload_requests_approved: number
  documents_upload_requests_to_approve: number
  documents_upload_requests_to_upload: number
  documents_signature_requests_signed: number
  documents_signature_requests_to_send: number
  documents_signature_requests_to_sign: number
  tasks_total: number
  tasks_completed: number
}

interface DocumentTemplateInterface extends AttachedTemplateInterface {
  status: IdAndName<DocumentStatuses>
  category?: IdAndName
}

export interface OnboardingTaskInterface {
  id: number
  name: string
  status: IdAndName<OnboardingStatus>
  description?: string
  default_assignee: IdAndName | null
  default_assignee_group: IdAndName | null
  default_assignee_relationship: IdAndName | null
  default_assignee_type: IdAndName<AssigneeType>
  assignee: IdAndName
}

export interface OnboardingTemplateInterface {
  id: number
  created_by: EmployeeOptionInterface
  name: string
  status: IdAndName<OnboardingTemplateStatus>
  document_template: DocumentTemplateInterface
  uploaded_document: IdAndName | null
  docusign_envelope_id?: string
  creation_date_time: string
  update_date_time: string
}

export interface OnboardingProgress {
  created_date_time: string
  updated_date_time: string
  is_basic_info_screen_completed: boolean
  is_diversity_info_screen_completed: boolean
  is_documents_info_screen_completed: boolean
  is_payroll_info_screen_completed: boolean
  is_personal_info_screen_completed: boolean
  is_welcome_screen_completed: boolean
}

export interface OnboardingBasicInfo {
  id: number
  first_name: string
  last_name: string
  middle_name: string | null
  display_name: string
  email: string
  joining_date_time: string
}
