import { CustomFieldTypes } from '@src/constants/customFields'

export const IS_EMPLOYEE_UPDATE_QUERY_KEY = 'employeeUpdate'

export const supportedEmployeeCustomFieldsTypes: CustomFieldTypes[] = [
  CustomFieldTypes.Text,
  CustomFieldTypes.Number,
  CustomFieldTypes.Date,
  CustomFieldTypes.Dropdown,
  CustomFieldTypes.MultipleSelector,
]

export const employeesSimpleTableFilterByInitial = [
  {
    columnName: 'status',
    filters: [
      { id: 'active', name: 'Active' },
      { id: 'hired', name: 'Hired' },
      { id: 'onboarding', name: 'Onboarding' },
      { id: 'pending', name: 'Pending' },
    ],
    nonResettable: true,
  },
]
