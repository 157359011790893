import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { formatTimeAgo } from '@src/utils/format'

export const updatedAtColumn: ColumnInterface<{ updated_date_time?: string }> = {
  type: CellTypes.insert,
  idPoint: 'updated_date_time',
  dataPoint: 'updated_date_time',
  sortKey: 'updated_date_time',
  filterKey: 'updated_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Update date',
  insert: ({ data }) =>
    data.updated_date_time ? formatTimeAgo(data.updated_date_time) : null,
}

export const startedAtColumn: ColumnInterface<{}> = {
  type: CellTypes.date,
  idPoint: 'joining_date_time',
  dataPoint: 'joining_date_time',
  sortKey: 'joining_date_time',
  filterKey: 'joining_date_time',
  selectorsKey: selectorKeys.none,
  textAlign: 'right',
  title: 'Start date',
}
