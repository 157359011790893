import { useTable } from '@src/components/Table/hooks'
import { Statuses } from '@src/interfaces'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { tableInitialFiltersRouter } from '../../AssignKPIs/routers'
import { KpiInterface } from '@src/interfaces/kpis'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { GradesMapInterface } from '@src/utils/grades'
import { EntityTypes } from '@src/constants/api'
import { PerformanceSelector } from '@src/interfaces/performance'
import { kpisRequests } from '@src/api/kpis'
import { SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  getKpiGradeColumn,
  kpiGenericNameColumn,
  kpiPerformanceColumn,
  kpiWeightColumn,
} from '@src/constants/columns/kpi'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface Props {
  gradesMap: GradesMapInterface
  employeeId: number
  cycle: Pick<PerformanceSelector, 'offset' | 'id'>
  setKpiTableCount?: Dispatch<SetStateAction<undefined | number>>
  isScrollable?: boolean
}

const initialSort: SortByInterface[] = [
  {
    sortBy: 'kpi_performance_type',
    direction: SORT_DIRECTION.DESC,
  },
]

const row = (gradesMap: GradesMapInterface) => ({
  cells: [
    {
      ...kpiGenericNameColumn,
      width: 110,
    },
    { ...kpiWeightColumn, width: 110 },
    {
      ...ownerNameWithAvatarColumn,
      width: 200,
    },
    {
      ...kpiPerformanceColumn,
      width: 130,
    },
    {
      ...getKpiGradeColumn(gradesMap),
      width: 110,
    },
  ],
})

export const EmployeeKpisCardTable = ({
  employeeId,
  cycle,
  gradesMap,
  setKpiTableCount,
  isScrollable = true,
}: Props) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  const initialFilters = tableInitialFiltersRouter(
    String(employeeId),
    EntityTypes.employee,
    cycle.offset,
    cycle.id,
    goalsEnabled,
  ).filter(({ columnName }) => columnName !== 'status')

  const table = useTable<KpiInterface>(
    kpisRequests,
    [
      ...initialFilters,
      {
        filters: [
          { name: Statuses.pending, id: Statuses.pending },
          { name: Statuses.approved, id: Statuses.approved },
          { name: Statuses.future, id: Statuses.future },
          { name: Statuses.completed, id: Statuses.completed },
        ],
        columnName: 'target_status',
        nonResettable: true,
      },
    ],
    initialSort,
  )

  useEffect(() => {
    if (setKpiTableCount) {
      setKpiTableCount(table.count)
    }
  }, [table.count])
  return (
    <>
      <AdjustableTable<KpiInterface>
        name={TableNames.PerformanceKPIsSummary}
        row={row(gradesMap)}
        {...table}
        useWindowScroll={isScrollable}
        hideCountAndButtonSection
        hideHeader={!table.loading && table.data?.length === 0}
        emptyState={
          <EmptyTableRaw
            imageId="3D304"
            title="No goals"
            description="Manager needs to provide goals"
          />
        }
      />
    </>
  )
}
