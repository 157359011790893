import React, { Suspense, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, LocalStorageKeys } from '@src/store/auth/types'

const AppSwitcher = React.lazy(() =>
  import('@revolut-internal/backoffice-ui-provider').then(module => ({
    default: module.AppSwitcher,
  })),
)

const useBackofficeAppSwitcherInit = () => {
  const featureFlags = useSelector(selectFeatureFlags)

  const [shouldRender, setShouldRender] = useState(false)

  const isCommercial = featureFlags?.includes(FeatureFlags.CommercialProduct)

  useEffect(() => {
    if (isCommercial) {
      return
    }
    /** We do not store app permissions in local storage.
     * But we need this key because @revolut-internal/backoffice-ui-provider package checks it to display apps in TopNav navigation */
    if (localStorage.getItem(LocalStorageKeys.PERMISSIONS) == null) {
      localStorage.setItem(LocalStorageKeys.PERMISSIONS, JSON.stringify({}))
    }
    setShouldRender(true)
  }, [])

  return shouldRender
}

export const TopNavAppSwitcher = () => {
  const shouldRender = useBackofficeAppSwitcherInit()

  return shouldRender ? (
    <Suspense fallback={null}>
      <AppSwitcher />
    </Suspense>
  ) : null
}
