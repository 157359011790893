import React from 'react'
import { Box, Text, LineClamp, Token } from '@revolut/ui-kit'
import styled from 'styled-components'
import css from '@styled-system/css'
import { SectionTitle } from '../common'

interface BlogPost {
  id: number
  feature_image: string
  title: string
  url: string
}

const blogPosts: BlogPost[] = [
  {
    id: 1,
    feature_image: 'https://blog.revolut.com/content/images/2021/04/SiJiaWen-Blog.png',
    title: 'A week in London, UK with our Core Payments Operations Manager',
    url: 'https://blog.revolut.com/careers-core-payments-ops/',
  },
  {
    id: 2,
    feature_image:
      'https://blog.revolut.com/content/images/2021/03/ValeriaAlunni--1-.jpg',
    title: 'A week in Milano, Italy with our Communications Manager',
    url: 'https://blog.revolut.com/career-communications-valeria/',
  },
  {
    id: 3,
    feature_image:
      'https://blog.revolut.com/content/images/2021/04/NeringaNarbute--Blog.png',
    title:
      'Congratulations, Neringa Narbutė, for being awarded Company Lawyer of the Year!',
    url: 'https://blog.revolut.com/careers-company-lawyer-award/',
  },
]

const Container = styled(Box)(
  css({
    gridColumn: '1 / -1',
    display: 'grid',
    gridTemplateColumns: [null, null, null, 'repeat(3, 1fr)'],
    gridAutoFlow: 'row',
    gap: ['s-16', null, null, 's-32'],
    height: 'fit-content',
  }),
)

const Card = styled(Box)(() =>
  css({
    bg: Token.color.widgetBackground,
    borderRadius: 24,
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    gridTemplateRows: 'repeat(2, max-content) 1fr',
    height: '100%',
    maxWidth: [null, null, null, 312],
    overflow: 'hidden',
    paddingBottom: 's-24',
    width: '100%',
  }),
)

const Image = styled(Box)<{ image?: string }>(({ image }) =>
  css({
    backgroundColor: Token.color.greyTone10,
    backgroundImage: image ? `url(${image})` : undefined,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    gridColumn: '1 / -1',
    gridRow: 1,
    height: 240,
    width: '100%',
  }),
)

const TitleText = styled(Text)(() =>
  css({
    gridColumn: '1 / -1',
    color: Token.color.foreground,
    mt: 's-16',
    px: 's-24',
    whiteSpace: 'pre-wrap',
  }),
)

const Title: React.FC<{ lineClamp?: number }> = ({ children, lineClamp = 2 }) => (
  <TitleText variant="h5">
    <LineClamp max={lineClamp}>{children}</LineClamp>
  </TitleText>
)

const ReadMore = styled(Text)(
  css({
    marginTop: 'auto',
    marginX: 's-24',
    gridColumn: '1 / -1',
    color: 'blue',
  }),
)

const Anchor = styled(Box)`
  text-decoration: none;
`

export const BlogPosts = () => {
  return (
    <>
      <SectionTitle
        title="Meet the Revoluters"
        subtitle="Learn more about the team you’re joining"
      />
      <Container>
        {blogPosts.map(({ id, feature_image, title, url }) => (
          <Anchor use="a" href={url} target="_blank" key={id}>
            <Card use="span" minHeight={396}>
              <Image image={feature_image} />
              <Title lineClamp={3}>{title}</Title>
              <ReadMore>Read more</ReadMore>
            </Card>
          </Anchor>
        ))}
      </Container>
    </>
  )
}
