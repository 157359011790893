import React from 'react'
import { Box, Cell, Flex, FlexProps, Text } from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import { formatDate } from '@src/utils/format'
import { compareDesc } from 'date-fns'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'

interface RoadmapChangesBannerProps extends Omit<FlexProps, 'variant'> {
  reviewCycle: ReviewCyclesInterface | null
}

const RoadmapDeadlineBanner = ({ reviewCycle, ...rest }: RoadmapChangesBannerProps) => {
  const deadline = reviewCycle?.roadmap_start_date

  if (!reviewCycle || !deadline) {
    return null
  }

  if (compareDesc(new Date(), new Date(deadline)) <= 0) {
    return null
  }

  return (
    <Box data-testid="roadmap-deadline-banner" width="100%" {...rest}>
      <Cell display="block">
        <Flex justifyContent="space-between" mb="s-8">
          <Text variant="primary">
            Last date to make changes to roadmap for {reviewCycle.name} is{' '}
            <Text color="orange">{formatDate(deadline)}</Text>
          </Text>
          <InfoOutline color="error" size={16} />
        </Flex>
        <Text use="p" color="grey-tone-50">
          Roadmaps will be locked after {formatDate(deadline)}. Kindly review your
          roadmaps and discuss with your managers to finalise them in time.
        </Text>
      </Cell>
    </Box>
  )
}

export default RoadmapDeadlineBanner
