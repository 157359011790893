import React from 'react'
import Candidate from '@src/pages/Forms/SendEmail/Candidate/Candidate'
import Form from '@src/features/Form/Form'
import { candidateEmailsRequests } from '@src/api/hiringProcess'

const CandidateIndex = () => {
  return (
    <Form api={candidateEmailsRequests} disableLocalStorageCaching>
      <Candidate />
    </Form>
  )
}

export default CandidateIndex
