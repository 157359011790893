import { Input, Item, VStack } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import RadioSelectOptionComponent from '@src/components/Inputs/RadioSelectInput/RadioSelectOption'
import { selectorKeys } from '@src/constants/api'
import { IdAndName } from '@src/interfaces'
import { CYCLE_DURATION } from '@src/interfaces/settings'
import React, { FormEvent, useEffect, useRef } from 'react'
import { ItemInput } from './ItemInput'
import { TimelineUIFields, useTimelineMappings } from '../useTimelinesMapping'
import { CollapsibleRadioSwitch } from '../../common/CollapsibleRadioSwitch'

export type TimelineError = {
  culprit_field: string
  field: string
  reason: string
}
interface Props {
  timeline: ReturnType<typeof useTimelineMappings>
  disabled?: boolean
  errors?: TimelineError[]
}

export const TimelinesSettingsWidget = ({ disabled, timeline, errors }: Props) => {
  const publishingDayRef = useRef<HTMLDivElement | null>(null)
  const getChangeHandler =
    (field: TimelineUIFields) => (e: FormEvent<HTMLInputElement>) => {
      const value = (e.target as HTMLInputElement).value
      timeline.onChange(field, value)
    }

  const managerPublishingDayError = errors?.find(
    ({ culprit_field }) => culprit_field === 'managers_publishing_day',
  )

  useEffect(() => {
    if (managerPublishingDayError && publishingDayRef.current) {
      publishingDayRef.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [managerPublishingDayError])

  return (
    <VStack space="s-16">
      <Item>
        <Item.Content>
          <Item.Title>Define performance review cycle frequency</Item.Title>
          <Item.Description>
            The periodic frequency or duration at which performance review cycles are run
          </Item.Description>
          <VStack space="s-16" mt="s-24">
            <LapeRadioSelectInput<IdAndName<CYCLE_DURATION>>
              label="Cycle duration"
              name="cycle_duration"
              selector={selectorKeys.cycle_durations}
              searchable={false}
              disabled={disabled}
            >
              {option => {
                const subtitles: Record<CYCLE_DURATION, string> = {
                  quarterly:
                    'Conducted every three months, these reviews offer a more frequent opportunity for feedback and goal adjustments',
                  biannually:
                    'Held twice a year, these reviews provide a midpoint check-in to assess progress and set goals for the next period',
                  annually:
                    "Traditional annual reviews are conducted once a year. They offer a comprehensive overview of an employee's performance over the past year",
                }

                return (
                  <RadioSelectOptionComponent
                    label={option.label}
                    subtitle={subtitles[option.value.id]}
                  />
                )
              }}
            </LapeRadioSelectInput>
            <ItemInput title="Open the performance review prior to cycle close">
              <Input
                type="number"
                onChange={getChangeHandler('openReviewDay')}
                value={timeline.openReviewDay}
                label="Days before cycle end"
                disabled={disabled}
                required
                width={350}
              />
            </ItemInput>
            <ItemInput title="Duration of performance review">
              <Input
                type="number"
                value={timeline.reviewDuration}
                label="Review duration (days)"
                onChange={getChangeHandler('reviewDuration')}
                disabled={disabled}
                required
                width={350}
              />
            </ItemInput>
          </VStack>
        </Item.Content>
      </Item>
      <CollapsibleRadioSwitch
        itemTypeProps={{
          title: 'Allow a fixed goal setting period',
          description:
            'If enabled, goal setting dates will be defined during a fixed window',
        }}
        name="enable_kpi_setting_dates"
      >
        <VStack space="s-16" pl="s-16" pr="s-12" pb="s-12">
          <ItemInput title="Start goal setting prior to cycle start">
            <Input
              type="number"
              label="Days before cycle start"
              required
              onChange={getChangeHandler('goalSettingOpenDay')}
              disabled={disabled}
              value={timeline.goalSettingOpenDay}
              width={350}
            />
          </ItemInput>
          <ItemInput title="Duration of goal setting">
            <Input
              type="number"
              label="Goal setting duration (days)"
              disabled={disabled}
              required
              onChange={getChangeHandler('goalSettingDuration')}
              value={timeline.goalSettingDuration}
              width={350}
            />
          </ItemInput>
        </VStack>
      </CollapsibleRadioSwitch>

      <CollapsibleRadioSwitch
        itemTypeProps={{
          title: 'Enable calibration of performance results',
          description:
            'If enabled, calibration of grades can occur at department and function level by their owners',
        }}
        name="enable_calibration"
      >
        <VStack space="s-16" pl="s-16" pr="s-12" pb="s-12">
          <ItemInput title="Start calibration after reviews end">
            <Input
              type="number"
              label="Days after reviews close"
              disabled={disabled}
              required
              value={timeline.calibrationStartDay}
              onChange={getChangeHandler('calibrationStartDay')}
              width={350}
            />
          </ItemInput>
          <ItemInput title="Duration of calibration">
            <Input
              type="number"
              label="Calibration duration (days)"
              disabled={disabled}
              required
              value={timeline.calibrationDuration}
              onChange={getChangeHandler('calibrationDuration')}
              width={350}
            />
          </ItemInput>
        </VStack>
      </CollapsibleRadioSwitch>
      <CollapsibleRadioSwitch
        blockRef={publishingDayRef}
        reversed
        alwaysShownContent={
          <ItemInput
            title="Publish grades to all after calibration end"
            px="s-16"
            pb="s-16"
          >
            <Input
              type="number"
              label="Days after calibration end"
              disabled={disabled}
              value={timeline.globalPublishDay}
              onChange={getChangeHandler('globalPublishDay')}
              required
              width={350}
            />
          </ItemInput>
        }
        itemTypeProps={{
          title: 'Publish grades to managers before employees',
          description:
            'If enabled, final grades will be available to the managers of reports on a separate prior date',
        }}
        name="publish_grades_to_managers_before_employees"
      >
        <VStack space="s-16" pl="s-16" pr="s-12" pb="s-12">
          <ItemInput title="Publish grades to managers after calibration end">
            <Input
              type="number"
              label="Days after calibration end"
              disabled={disabled}
              value={timeline.managersPublishDay}
              onChange={getChangeHandler('managersPublishDay')}
              required
              width={350}
              aria-invalid={!!managerPublishingDayError}
              message={managerPublishingDayError?.reason}
            />
          </ItemInput>
        </VStack>
      </CollapsibleRadioSwitch>
    </VStack>
  )
}
