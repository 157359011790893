import React from 'react'
import { Text } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  CommitteeResultInterface,
  ProbationResults,
} from '@src/interfaces/probationReview'
import { ConfirmationDialogInterface } from '@src/features/Popups/ConfirmationDialog'
import { formatDate } from '@src/utils/format'
import { SeniorityInterface } from '@src/interfaces/seniority'

type Props = {
  backUrl: string
  lineManager: string
  endProbationDate?: string
  employeeSeniority?: SeniorityInterface
}

const SaveButton = ({
  backUrl,
  lineManager,
  endProbationDate,
  employeeSeniority,
}: Props) => {
  const { values, submit } = useLapeContext<CommitteeResultInterface>()

  const onSubmit = () => {
    if (values.result === ProbationResults.ChangeSeniority) {
      return submit()
    }

    // if we don't change seniority we still have to send it to the BE from employee profile, because it's required ¯\_(ツ)_/¯
    const fallbackSeniority = values.seniority

    if (employeeSeniority) {
      values.seniority = { ...employeeSeniority }
    }
    return submit().catch(() => {
      values.seniority = fallbackSeniority
    })
  }

  const getConfirmationDialog = (): Partial<ConfirmationDialogInterface> | undefined => {
    switch (values.result) {
      case ProbationResults.Extend: {
        return {
          label: 'Confirm probation extension',
          body: (
            <>
              <Text use="p" variant="caption" fontWeight={500} mb="s-16">
                This will create a new probation cycle in the employee’s profile
                {values.end_of_probation_date_time && endProbationDate ? (
                  <>
                    {' '}
                    from {formatDate(new Date(endProbationDate))} -{' '}
                    {formatDate(new Date(values.end_of_probation_date_time))}
                  </>
                ) : (
                  ''
                )}
                .
              </Text>
              <Text use="p" variant="caption" color="grey-tone-50">
                By clicking on Submit, you are confirming that you have talked with the
                employee’s line manager: {lineManager} and understand the impact of this
                process
              </Text>
            </>
          ),
          yesMessage: 'Proceed',
          noMessage: 'Cancel',
        }
      }

      case ProbationResults.Failed: {
        return {
          label: 'Fail probation?',
          body: (
            <>
              <Text use="p" variant="caption" fontWeight={500} mb="s-16">
                This will terminate the employee.
              </Text>
              <Text use="p" variant="caption" color="grey-tone-50">
                By clicking on Submit, you are confirming that you have talked with the
                employee’s line manager: {lineManager} and understand the impact of this
                process
              </Text>
            </>
          ),
          yesMessage: 'Proceed',
          yesBtnVariant: 'negative',
          noMessage: 'Cancel',
        }
      }

      default:
        return undefined
    }
  }

  return (
    <NewSaveButtonWithPopup
      onClick={onSubmit}
      useValidator
      afterSubmitUrl={backUrl}
      successText="Committee result for Probation Review successfully updated."
      confirmationDialogue={getConfirmationDialog()}
      data-testid="submit-btn"
    />
  )
}

export default SaveButton
