import React from 'react'
import { Icon, MoreBar } from '@revolut/ui-kit'

export const HeaderActions = ({
  onChangeSchedule,
}: {
  onChangeSchedule: VoidFunction
}) => {
  return (
    <MoreBar maxCount={2}>
      <MoreBar.Action
        onClick={onChangeSchedule}
        useIcon={<Icon size={16} name="Calendar" />}
      >
        Change Schedule
      </MoreBar.Action>
    </MoreBar>
  )
}
