import { useEffect } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { IdAndName } from '@src/interfaces'

export const useSaveSwitcherItems = <
  T extends { full_name?: string; name?: string; id: number },
>(
  localStorageKey: string,
  tableData: T[],
  labelField: 'name' | 'full_name' = 'name',
  idGenerateFunc?: (item: T) => string,
) => {
  const [, setContext] = useLocalStorage<{
    data: IdAndName<number | string>[]
  }>(localStorageKey, { data: [] })

  useEffect(() => {
    setContext({
      data: tableData.slice(0, 10000).map(item => ({
        id: idGenerateFunc ? idGenerateFunc(item) : item.id,
        name: item[labelField] || `#${item.id}`,
      })),
    })
  }, [tableData])
}
