import React, { useEffect, useState } from 'react'
import { connect } from 'lape'

import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import AnytimeFeedbackGeneral from './General'
import { pathToUrl } from '@src/utils/router'
import { Box, Flex } from '@revolut/ui-kit'
import { AnytimeFeedbackFormSubtitle } from '@src/pages/Forms/FormTitles'
import { getEmployeeWithManagerFilter } from '@src/api/employees'
import { Route, Switch, useParams } from 'react-router-dom'
import { EmployeeInterface } from '@src/interfaces/employees'
import { FeedbackCategory } from '@src/interfaces/anytimeFeedback'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'

const AnytimeFeedback = () => {
  const params = useParams<{ userId?: string; id: string }>()
  const backUrl = pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE_SUMMARY, {
    id: params.userId,
  })
  const user = useSelector(selectUser)
  const [data, setData] = useState<EmployeeInterface>()

  useEffect(() => {
    if (params.userId && !isNaN(parseFloat(params.userId))) {
      getEmployeeWithManagerFilter(params.userId, 'line_manager__id').then(res => {
        setData(res.data)
      })
    }
  }, [params.userId])

  const canViewAnytimeFeedback = params.userId !== String(user.id)
  const canViewOneToOneFeedback =
    data?.line_manager?.id === user.id ||
    data?.quality_control?.id === user.id ||
    params.userId === String(user.id)

  const tabs = [
    {
      title: 'Anytime feedback',
      path: ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ANYTIME, params),
      component: AnytimeFeedbackGeneral,
      category: FeedbackCategory.Anytime,
      canView: canViewAnytimeFeedback,
    },
    {
      title: '1:1 notes',
      path: ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE,
      to: pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE, params),
      component: AnytimeFeedbackGeneral,
      category: FeedbackCategory.OneToOne,
      canView: canViewOneToOneFeedback,
    },
  ]

  const filteredTabs = tabs.filter(tab => tab.canView)

  return (
    <PageWrapper>
      <PageHeader
        pb="s-8"
        title={params.id ? 'Edit feedback' : 'Give feedback'}
        subtitle={
          data && (
            <Flex alignItems="flex-start" mt="s-8">
              <AnytimeFeedbackFormSubtitle data={data} />
            </Flex>
          )
        }
        backUrl={backUrl}
      >
        {!params.id && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={filteredTabs} />
          </Box>
        )}
      </PageHeader>
      <Flex pt="s-8" flex="1 0" flexDirection="column" width="100%" minHeight={0}>
        <Switch>
          {filteredTabs.map(tab => (
            <Route exact path={tab.path} key={tab.path}>
              <tab.component category={tab.category} />
            </Route>
          ))}
        </Switch>
      </Flex>
    </PageWrapper>
  )
}

export default connect(AnytimeFeedback)
