import React from 'react'
import {
  ActionButton,
  Avatar,
  Box,
  CounterWidget,
  CounterWidgetSkeleton,
  DetailsCell,
  Group,
  HStack,
  Item,
  ItemSkeleton,
  Separator,
  Tooltip,
  VStack,
  useTooltip,
} from '@revolut/ui-kit'
import {
  useGetJobPostingRequisitionStats,
  useGetJobPostingRequisitions,
} from '@src/api/requisitions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import uniqBy from 'lodash/uniqBy'
import { LineManagerInterface } from '@src/interfaces/requisitions'

type HiringManagersProps = {
  hiringManagers: LineManagerInterface[]
}

const HiringManagers = ({ hiringManagers }: HiringManagersProps) => {
  const tooltip = useTooltip()
  const avatars = hiringManagers.slice(0, 8)
  const moreAvatars = hiringManagers.slice(8)
  return (
    <HStack space="s-4">
      {avatars.map(hiringManager => (
        <UserWithAvatar key={hiringManager.id} compact {...hiringManager} />
      ))}
      {!!moreAvatars.length && (
        <Box {...tooltip.getAnchorProps()}>
          <Avatar label={`+${moreAvatars.length}`} size={24} />
          <Tooltip {...tooltip.getTargetProps()}>
            <VStack space="s-4">
              {moreAvatars.map(hiringManager => (
                <UserWithAvatar key={hiringManager.id} {...hiringManager} />
              ))}
            </VStack>
          </Tooltip>
        </Box>
      )}
    </HStack>
  )
}

const RequisitionsOverview = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const params = useParams()
  const { data: stats, isLoading: loadingStats } = useGetJobPostingRequisitionStats(
    values.id,
  )
  const { data: requisitionsData, isLoading: loadingRequisitions } =
    useGetJobPostingRequisitions(values.id)
  if (loadingStats || loadingRequisitions) {
    return (
      <Group>
        <ItemSkeleton />
        <CounterWidget>
          <CounterWidget.Column>
            <CounterWidgetSkeleton.Description />
            <CounterWidgetSkeleton.Subtitle />
          </CounterWidget.Column>
          <CounterWidget.Column>
            <CounterWidgetSkeleton.Description />
            <CounterWidgetSkeleton.Subtitle />
          </CounterWidget.Column>
        </CounterWidget>
      </Group>
    )
  }
  const totalHeadcount = stats?.requisition_total_headcount ?? 0
  const remainingHeadcount = stats?.requisition_remaining_headcount ?? 0
  const hiredHeadcount = totalHeadcount - remainingHeadcount
  const requisitions = requisitionsData?.results ?? []
  const hiringManagers = uniqBy(
    requisitions.map(({ line_manager }) => line_manager),
    'id',
  ).filter(Boolean)
  return (
    <Group>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Inbox" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Requisitions</Item.Title>
          <Item.Description>Requisitions connected to this job posting</Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={pathToUrl(ROUTES.FORMS.JOB_POSTING.REQUISITIONS, params)}
          >
            View
          </ActionButton>
        </Item.Side>
      </Item>
      <CounterWidget>
        <CounterWidget.Column>
          <CounterWidget.Description>{totalHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">
            Total headcount
          </CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{hiredHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Hired</CounterWidget.Subtitle>
        </CounterWidget.Column>
        <CounterWidget.Column>
          <CounterWidget.Description>{remainingHeadcount}</CounterWidget.Description>
          <CounterWidget.Subtitle textAlign="center">Remaining</CounterWidget.Subtitle>
        </CounterWidget.Column>
      </CounterWidget>
      {!!hiringManagers.length && (
        <>
          <Separator />
          <DetailsCell>
            <DetailsCell.Title>Hiring managers</DetailsCell.Title>
            <DetailsCell.Content>
              <HiringManagers hiringManagers={hiringManagers} />
            </DetailsCell.Content>
          </DetailsCell>
        </>
      )}
    </Group>
  )
}

export default RequisitionsOverview
