import React, { useEffect } from 'react'
import { Avatar, Flex, Item, Token, VStack } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  ProbationTemplateInterface,
  ProbationTemplatePreviewRequest,
} from '@src/interfaces/probationTemplate'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import { CheckSuccess, Time } from '@revolut/icons'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetProbationTemplateData } from '@src/pages/Forms/ProbationTemplate/common'
import pluralize from 'pluralize'

const getMonthsOptions = ({ min, max }: { min: number; max: number }) => {
  const options = []
  for (let i = min; i <= max; i++) {
    const name = pluralize('month', i, true)
    options.push({
      value: {
        name,
        id: i,
      },
      label: name,
    })
  }
  return options
}

export const CycleSetup = () => {
  const { values } = useLapeContext<ProbationTemplateInterface>()

  const {
    previewData,
    numberOfCheckpointsOptions,
    onMonthsChange,
    recalculatePreview,
    loading,
  } = useGetProbationTemplateData()

  const minProbationDurationMonths = 1
  const maxProbationDurationMonths = 12
  const monthsOptions = getMonthsOptions({
    min: minProbationDurationMonths,
    max: maxProbationDurationMonths,
  })

  useEffect(() => {
    if (!values.name) {
      values.name = 'Probation template 1'
    }
  }, [])

  return (
    <VStack space="s-16">
      <VStack space="s-8">
        <SectionTitle
          title="How long will the probation period be for new joiners?"
          subtitle="This will set the end of probation date from their joining date"
        />
        <Item>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex alignItems="center" width="140%">
              <Avatar
                useIcon={Time}
                color={Token.color.deepGrey}
                backgroundColor={Token.color.greyTone10}
              />
              <Item.Title ml="s-16">Cycle duration</Item.Title>
            </Flex>
            <LapeRadioSelectInput<{ id: number }>
              name="amount_of_months"
              label="Probation cycle duration"
              message={
                previewData?.total_weeks && `Total weeks: ${previewData?.total_weeks}`
              }
              options={monthsOptions}
              value={
                monthsOptions.find(el => el.value.id === values.amount_of_months)?.value
              }
              onChange={onMonthsChange}
              disabled={loading}
              required
            />
          </Flex>
        </Item>
      </VStack>
      <VStack space="s-8">
        <SectionTitle
          title="During this time, how many performance assessment points do you want to complete?"
          subtitle="Managers will be invited to complete a review scorecard at each checkpoint"
        />
        <Item>
          <Flex alignItems="center" justifyContent="space-between" width="100%">
            <Flex alignItems="center" width="140%">
              <Avatar
                useIcon={CheckSuccess}
                color="deep-grey"
                backgroundColor={Token.color.greyTone10}
              />
              <Item.Title ml="s-16">Checkpoints</Item.Title>
            </Flex>
            <LapeRadioSelectInput<{ id: number }>
              name="num_checkpoints"
              label="Number of checkpoints"
              options={numberOfCheckpointsOptions}
              value={
                numberOfCheckpointsOptions.find(
                  el => el.value.id === values.num_checkpoints,
                )?.value
              }
              onChange={option => {
                if (option) {
                  values.num_checkpoints = option?.id
                  recalculatePreview({
                    ...previewData,
                    num_checkpoints: option.id,
                  } as ProbationTemplatePreviewRequest)
                }
              }}
              disabled={loading}
            />
          </Flex>
        </Item>
      </VStack>
    </VStack>
  )
}
