import React, { useCallback, useState } from 'react'
import {
  DragAndDrop,
  Flex,
  Group,
  HStack,
  IconButton,
  Item,
  Text,
  Token,
} from '@revolut/ui-kit'
import { Drag, MinusCircle, Pencil, PlusCircle } from '@revolut/icons'
import { move } from '@src/utils/move'
import { ReportColumnsTableInterface } from '@src/interfaces/dataAnalytics'
import { ReorderSettingsInterface } from '../../hooks/useTableSettings'

interface Props {
  setRenameItem: (value: string | null) => void
  setTableSettings: React.Dispatch<React.SetStateAction<ReorderSettingsInterface>>
  tableSettings: ReorderSettingsInterface
}

export const ReorderColumnsSettings = ({
  setRenameItem,
  setTableSettings,
  tableSettings,
}: Props) => {
  const [activeId, setActiveId] = useState(null)
  const activeItem = activeId
    ? tableSettings.visible.find(item => item.name === activeId)
    : null

  const onDragCancel = useCallback(() => setActiveId(null), [])
  const onDragStart = useCallback(event => setActiveId(event.active.id), [])

  const onDragEnd = useCallback(event => {
    if (event.over) {
      const startIndex = event.active.data.current.sortable.index
      const endIndex = event.over.data.current.sortable.index
      setTableSettings(current => ({
        ...current,
        visible:
          startIndex === endIndex
            ? current.visible
            : move(current.visible, startIndex, endIndex),
      }))
    }

    setActiveId(null)
  }, [])

  const handleAdd = (item: ReportColumnsTableInterface) => {
    setTableSettings(current => ({
      visible: [...current.visible, item],
      hidden: current.hidden.filter(t => {
        return t !== item
      }),
    }))
  }

  const handleRemove = (item: ReportColumnsTableInterface) => {
    setTableSettings(current => ({
      visible: current.visible.filter(t => {
        return t !== item
      }),
      hidden: [...current.hidden, item],
    }))
  }

  return (
    <Flex flexDirection="column" gap="s-16">
      <Text>Included in report, rearrange columns to set column order</Text>
      <Group>
        <DragAndDrop.Provider
          onDragCancel={onDragCancel}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
        >
          <DragAndDrop.Sortable
            id="sortable-table-preferences"
            items={tableSettings.visible.map(item => item.name)}
          >
            {sortable => {
              const sortableItem = tableSettings.visible.find(
                item => item.name === sortable.id,
              )

              return sortableItem ? (
                <Item
                  ref={sortable.setNodeRef}
                  style={{
                    opacity: sortable.isDragging ? 0 : undefined,
                    transform: sortable.transform
                      ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                      : undefined,
                    transition: sortable.transition || 'none',
                  }}
                >
                  <Item.Prefix>
                    <IconButton
                      aria-label="Remove"
                      color={Token.color.red}
                      onClick={() => handleRemove(sortableItem)}
                      size={16}
                      useIcon={MinusCircle}
                    />
                  </Item.Prefix>
                  <Item.Content>
                    <Item.Title>{sortableItem.alias || sortableItem.name}</Item.Title>
                  </Item.Content>
                  <Item.Side>
                    <HStack align="center" gap="s-8">
                      <IconButton
                        aria-label="Rename"
                        color={Token.color.blue}
                        onClick={() => {
                          setRenameItem(sortableItem.name)
                        }}
                        size={16}
                        useIcon={Pencil}
                      />
                      <IconButton
                        aria-label="Drag"
                        color={Token.color.greyTone20}
                        size={16}
                        useIcon={Drag}
                        {...sortable.attributes}
                        {...sortable.listeners}
                      />
                    </HStack>
                  </Item.Side>
                </Item>
              ) : null
            }}
          </DragAndDrop.Sortable>
          <DragAndDrop.DragOverlay>
            {activeItem && (
              <Item>
                <Item.Prefix>
                  <IconButton
                    aria-label="Remove"
                    color={Token.color.red}
                    size={16}
                    useIcon={MinusCircle}
                  />
                </Item.Prefix>
                <Item.Content>
                  <Item.Title>{activeItem.alias || activeItem.name}</Item.Title>
                </Item.Content>
                <Item.Side>
                  <IconButton
                    aria-label="Rename"
                    color={Token.color.greyTone20}
                    size={16}
                    useIcon={Pencil}
                  />
                  <IconButton
                    aria-label="Drag"
                    color={Token.color.greyTone20}
                    size={16}
                    useIcon={Drag}
                  />
                </Item.Side>
              </Item>
            )}
          </DragAndDrop.DragOverlay>
        </DragAndDrop.Provider>
      </Group>

      <Text>Not included in report, press + button to add to report</Text>
      <Group>
        {tableSettings.hidden.map(item => {
          return (
            <Item key={item.name}>
              <Item.Prefix>
                <IconButton
                  aria-label="Add"
                  color={Token.color.blue}
                  onClick={() => handleAdd(item)}
                  size={16}
                  useIcon={PlusCircle}
                />
              </Item.Prefix>
              <Item.Content>
                <Item.Title>{item.alias || item.name}</Item.Title>
              </Item.Content>
            </Item>
          )
        })}
      </Group>
    </Flex>
  )
}
