import React from 'react'
import { format } from 'date-fns'
import { ProgressStep } from '@revolut/ui-kit'

import { getCheckpointOpenDate, isCheckpointOpened } from '@src/utils/performance'
import ClosedCheckpointMessage from './ClosedCheckpointMessage'

type Props = {
  title: string
  checkpointDate: string
  children: React.ReactNode
  checkpointNumber?: number
}

const GenericTimelineStep = ({
  title,
  checkpointDate,
  children,
  checkpointNumber,
}: Props) => {
  const checkpointOpenDate = getCheckpointOpenDate(checkpointDate)
  const checkpointOpened = isCheckpointOpened(checkpointOpenDate)

  return (
    <ProgressStep state={checkpointOpened ? 'done' : 'pending'}>
      <ProgressStep.Title>{title}</ProgressStep.Title>
      <ProgressStep.Description>
        {checkpointOpened ? (
          children
        ) : (
          <ClosedCheckpointMessage checkpointOpenDate={checkpointOpenDate} />
        )}
      </ProgressStep.Description>
      <ProgressStep.Caption>
        {format(new Date(checkpointDate), 'd MMM')} <br />
        {checkpointNumber !== undefined ? <>CP{checkpointNumber}</> : null}
      </ProgressStep.Caption>
    </ProgressStep>
  )
}

export default GenericTimelineStep
