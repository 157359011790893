import React from 'react'
import { closeNotification } from '@src/store/notifications/actions'
import Notification from './Notification'
import { Modal } from '../Modals/Modal'
import styled from 'styled-components'
import { navigateTo } from '@src/actions/RouterActions'
import { NotificationInterface } from '@src/store/notifications/types'
import notificationState from '@src/store/notifications/state'
import { connect } from 'lape'
import { defaultTheme } from '@src/styles/theme'

const Wrapper = styled.div`
  position: fixed;
  bottom: 32px;
  left: 64px;
  display: flex;
  flex-direction: column-reverse;
  z-index: ${defaultTheme.zIndex.popup};
`
const CountWrapper = styled.div`
  position: relative;
  user-select: none;
`
const Count = styled.div`
  position: absolute;
  bottom: -32px;
  right: -16px;
  height: 32px;
  width: 32px;
  font-size: 18px;
  border-radius: 50%;
  display: grid;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14);
`

const GoBack = styled.span`
  text-decoration: underline;
  cursor: pointer;
`

const LIMIT = 3
function NotificationSystem() {
  const handleClose = (id: string) => () => {
    closeNotification(id)
  }

  const handleGoBack = (notification: NotificationInterface) => {
    if (notification.backUrl) {
      navigateTo(notification.backUrl)
    }

    handleClose(notification.id)()
  }

  return (
    <Modal>
      <Wrapper>
        {notificationState.notifications.slice(0, LIMIT).map(notification => {
          return (
            <Notification
              key={notification.id}
              open={!!notification.open}
              autoHideDuration={notification.duration}
              onClose={handleClose(notification.id)}
              type={notification.type}
            >
              {notification.value}
              {` `}
              {notification?.backUrl && (
                <GoBack
                  onClick={() => {
                    handleGoBack(notification)
                  }}
                >
                  Go back
                </GoBack>
              )}
            </Notification>
          )
        })}
        {notificationState.notifications.length > LIMIT && (
          <CountWrapper>
            <Count>{notificationState.notifications.length - LIMIT}</Count>
          </CountWrapper>
        )}
      </Wrapper>
    </Modal>
  )
}

export default connect(NotificationSystem)
