import React from 'react'
import { Color, Flex, Text, Box } from '@revolut/ui-kit'
import { ExclamationMarkOutline } from '@revolut/icons'

type Props = {
  message: React.ReactNode
}

const InputErrorMessage = ({ message }: Props) => {
  return (
    <Text color={Color.RED} mt="s-4" ml="s-16" display="inline-block" fontSize="0.75rem">
      <Flex alignItems="center">
        <ExclamationMarkOutline size={12} />
        <Box ml="s-8">{message}</Box>
      </Flex>
    </Text>
  )
}

export default InputErrorMessage
