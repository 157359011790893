import React from 'react'
import { ActionButton, Avatar, Item, ItemSkeleton, Widget } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetJobPostingSettings } from '@src/api/settings'
import { useSelector } from 'react-redux'
import { selectFeatureFlags, selectPermissions } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import {
  LinkedInJobBoardDetails,
  IndeedJobBoardDetails,
  MonsterJobBoardDetails,
} from '@src/pages/Forms/JobPosting/JobBoards/JobBoardDetails'

export const JobBoards = () => {
  const featureFlags = useSelector(selectFeatureFlags)
  const permissions = useSelector(selectPermissions)
  const { data: jobPostingSettings, isLoading: loadingJobPostingSettings } =
    useGetJobPostingSettings()
  const canViewLinkedInPostings = permissions.includes(
    PermissionTypes.ViewLinkedInApiPosting,
  )
  const linkedinIntegration = featureFlags.includes(FeatureFlags.LinkedinIntegration)
  const showLinkedin =
    jobPostingSettings?.enable_linkedin_integration &&
    canViewLinkedInPostings &&
    linkedinIntegration
  const indeedIntegration = featureFlags.includes(FeatureFlags.IndeedIntegration)
  const showIndeed = jobPostingSettings?.indeed_connected && indeedIntegration
  const showMonster = jobPostingSettings?.enable_monster_integration
  if (loadingJobPostingSettings) {
    return <ItemSkeleton />
  }
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Newspaper" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>Job boards</Item.Title>
        </Item.Content>
        <Item.Side>
          <ActionButton
            use={InternalLink}
            to={
              !indeedIntegration
                ? pathToUrl(ROUTES.SETTINGS.JOBS.LIST)
                : pathToUrl(ROUTES.SETTINGS.INTEGRATIONS)
            }
          >
            Manage Integrations
          </ActionButton>
        </Item.Side>
      </Item>
      {showLinkedin && <LinkedInJobBoardDetails />}
      {showIndeed && <IndeedJobBoardDetails />}
      {showMonster && <MonsterJobBoardDetails />}
    </Widget>
  )
}
