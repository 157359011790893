import React, { useState } from 'react'
import { connect } from 'lape'
import { Button, StatusPopup, useStatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import { paygroupRequests, paygroupsBulkEdit } from '@src/api/payroll'
import { usePaygroups } from '@src/api/payroll'
import { ROUTES } from '@src/constants/routes'
import { PaygroupInterface } from '@src/interfaces/payroll'
import { PaygroupEditForm } from '@src/apps/People/Payroll/Paygroup/General'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import PageLoading from '@src/components/PageLoading/PageLoading'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { pathToUrl } from '@src/utils/router'

const BulkEditForm = () => {
  const statusPopup = useStatusPopup()
  const { values } = useLapeContext<PaygroupInterface>()
  const { data: paygroups, isLoading: isLoadingPaygroups } = usePaygroups()

  const [isLoading, setIsLoading] = useState(false)

  const showError = (text?: string) => {
    statusPopup.show(
      <StatusPopup variant="error">
        <StatusPopup.Title>Failed to save changes</StatusPopup.Title>
        {text && <StatusPopup.Description>{text}</StatusPopup.Description>}
      </StatusPopup>,
    )
  }

  const showSuccess = (text: string) => {
    statusPopup.show(
      <StatusPopup
        onClose={() => {
          statusPopup.hide()
          navigateTo(pathToUrl(ROUTES.ONBOARDING_CHECKLIST.PAYROLL.SETTINGS))
        }}
        variant="success-result"
      >
        <StatusPopup.Title>{text}</StatusPopup.Title>
      </StatusPopup>,
    )
  }

  const prepareData = () => {
    if (!paygroups) {
      return []
    }

    return paygroups.results.map(paygroup => {
      return {
        ...values,
        id: paygroup.id,
      }
    })
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    try {
      await paygroupsBulkEdit(prepareData())

      showSuccess('Your changes were saved')
    } catch (err) {
      const errorMsg = getStringMessageFromError(err)
      showError(errorMsg)
    } finally {
      setIsLoading(false)
    }
  }

  if (isLoadingPaygroups) {
    return <PageLoading />
  }

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.ONBOARDING_CHECKLIST.PAYROLL.SETTINGS}
        title="Edit payroll definitions"
      />
      <PageBody>
        <PaygroupEditForm bulkEdit />
      </PageBody>
      <PageActions>
        <Button onClick={handleSubmit} pending={isLoading}>
          Submit
        </Button>
      </PageActions>
    </PageWrapper>
  )
}

export const PayrollDefinitionsBulkEdit = connect(() => (
  <Form api={paygroupRequests} disableLocalStorageCaching>
    <BulkEditForm />
  </Form>
))
