import React from 'react'
import { ErrorWidget } from '@revolut/ui-kit'
import { PageErrorProps } from '@components/PageError'

interface Props extends PageErrorProps {
  onSkip: () => void
}

const CvScreeningError = ({ title, text, onSkip }: Props) => {
  return (
    <ErrorWidget>
      <ErrorWidget.Image src="https://assets.revolut.com/media/business/illustrations-repository/alert@2x.png" />
      <ErrorWidget.Title>{title}</ErrorWidget.Title>
      <ErrorWidget.Description>{text}</ErrorWidget.Description>
      <ErrorWidget.Action onClick={onSkip}>Skip</ErrorWidget.Action>
    </ErrorWidget>
  )
}

export default CvScreeningError
