import React from 'react'
import { UseQueryResult } from 'react-query'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { Link } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { EntityTypes } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

interface Props {
  id: string | number
  useGetEntity: (id: number | string) => UseQueryResult<{ name: string }>
  entityType: EntityTypes
}

const Header = ({ useGetEntity, entityType, id }: Props) => {
  const { data } = useGetEntity(id)
  return (
    <PageHeader
      title={`Approve ${entityType} kpis`}
      backUrl="/"
      subtitle={
        <Link
          use={InternalLink}
          to={pathToUrl(
            entityType === EntityTypes.department
              ? ROUTES.FORMS.DEPARTMENT.KPI
              : ROUTES.FORMS.TEAM.KPI,
            { id },
          )}
          target="_blank"
          color="inherit"
        >
          {data?.name}
        </Link>
      }
    />
  )
}

export default Header
