import { IdAndName } from '@src/interfaces'
import { WorkScheduleWorkingDay } from '@src/interfaces/workSchedule'

export type WorkDaySettingsErrors = {
  work_start_time?: string[]
  work_end_time?: string[]
  break_start_time?: string[]
  break_end_time?: string[]
}

export type DayOption = IdAndName<WorkScheduleWorkingDay>

export const isEmptyWorkDaysError = (errors: Array<string | WorkDaySettingsErrors>) => {
  // TODO: REVPI-3 Use BE error codes when implemented
  return errors[0] === 'This field may not be null.'
}

export const parseWorkDaySettingsErrors = (
  workDaySettingsErrors: WorkDaySettingsErrors | string | undefined,
) => {
  const settingsErrors =
    typeof workDaySettingsErrors === 'object'
      ? workDaySettingsErrors
      : {
          work_start_time: [],
          work_end_time: [],
          break_start_time: [],
          break_end_time: [],
        }
  const workStartTime =
    'work_start_time' in settingsErrors && Array.isArray(settingsErrors.work_start_time)
      ? settingsErrors.work_start_time[0]
      : undefined
  const workEndTime =
    'work_end_time' in settingsErrors && Array.isArray(settingsErrors.work_end_time)
      ? settingsErrors.work_end_time[0]
      : undefined
  const breakStartTime =
    'break_start_time' in settingsErrors && Array.isArray(settingsErrors.break_start_time)
      ? settingsErrors.break_start_time[0]
      : undefined
  const breakEndTime =
    'break_end_time' in settingsErrors && Array.isArray(settingsErrors.break_end_time)
      ? settingsErrors.break_end_time[0]
      : undefined

  return {
    workStartTime,
    workEndTime,
    breakStartTime,
    breakEndTime,
  }
}
