import { history } from './router'
import { useMemo, useState } from 'react'

export const entriesToObject = (entries: [string, string][]) =>
  entries.reduce(
    (prev: object, curr: [string, string]) => ({ ...prev, [curr[0]]: curr[1] }),
    {},
  )

export const getQueries = () =>
  entriesToObject(Array.from(new URLSearchParams(history.location.search).entries()))

export interface UseQueryReturnInterface<T> {
  query: T
  changeQueryParam: (key: keyof T, changeTo: string, setEmpty?: boolean) => void
  deleteQueryParam: (key: keyof T) => void
  deleteQuery: () => void
}

export const useQuery = <T = { [key: string]: string }>(
  push?: boolean,
  disabled?: boolean,
): UseQueryReturnInterface<T> => {
  // used just for rerendering
  const [, setStateQuery] = useState({})
  const query = useMemo(getQueries, [history.location.search])

  if (disabled) {
    return {
      query: {} as T,
      changeQueryParam: () => {},
      deleteQueryParam: () => {},
      deleteQuery: () => {},
    }
  }

  const changeQueryInURL = (q: URLSearchParams) => {
    // makes query more readable
    const newQuery = q.toString().replace(/%2C/gi, ',')
    if (push) {
      history.push({
        search: newQuery,
        hash: history.location.hash,
        state: history?.location?.state || {},
      })
    } else {
      history.replace({
        search: newQuery,
        hash: history.location.hash,
        state: history?.location?.state || {},
      })
    }
  }

  const change = (key: keyof T, value: string, setEmpty?: boolean) => {
    const newQuery = new URLSearchParams(history.location.search)
    if (value || setEmpty) {
      newQuery.set(key as string, value)
    } else {
      newQuery.delete(key as string)
    }
    setStateQuery(entriesToObject(Array.from(newQuery.entries())))
    changeQueryInURL(newQuery)
  }

  const del = (key: keyof T) => {
    const newQuery = new URLSearchParams(history.location.search)
    newQuery.delete(key as string)
    setStateQuery(entriesToObject(Array.from(newQuery.entries())))
    changeQueryInURL(newQuery)
  }

  const deleteQuery = () => {
    setStateQuery(entriesToObject(Array.from(new URLSearchParams('').entries())))
    changeQueryInURL(new URLSearchParams(''))
  }

  return {
    query: query as unknown as T,
    changeQueryParam: change,
    deleteQueryParam: del,
    deleteQuery,
  }
}
