import { Flex, Icon, TextButton, Token } from '@revolut/ui-kit'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { selectorKeys } from '@src/constants/api'
import { TableNames } from '@src/constants/table'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { CellInsertParams, CellTypes } from '@src/interfaces/data'
import { GoalDashboardDetails, GoalsInterface } from '@src/interfaces/goals'
import React, { useState } from 'react'
import { useSubmitFlowHelpers } from '../../../common/utils'
import { captureException } from '@sentry/core'
import { updateDashboard } from '@src/api/analyticsDashboards'
import Table from '@src/components/TableV2/Table'
import { TableTypes } from '@src/interfaces/table'

export const DashboardsList = ({
  onViewRequested,
  viewMode = false,
}: {
  onViewRequested: (dashboard: GoalDashboardDetails) => void
  viewMode: boolean
}) => {
  const { values } = useLapeContext<GoalsInterface>()
  const { showError, confirmationDialog, confirm } = useSubmitFlowHelpers({
    confirmProps: { variant: 'compact' },
  })
  const [pendingDelete, setPendingDelete] = useState<number>()

  return values.dashboards?.length ? (
    <Table.Widget>
      <Table.Widget.Table>
        <AdjustableTable<GoalDashboardDetails & { actions_column?: never }>
          hideCount
          type={TableTypes.Contained}
          name={TableNames.AnalyticsDashboards}
          data={values.dashboards}
          count={values.dashboards.length}
          dataType="Dashboard"
          row={{
            cells: [
              {
                title: 'Name',
                type: CellTypes.insert,
                idPoint: 'name',
                dataPoint: 'name',
                sortKey: 'name',
                filterKey: null,
                selectorsKey: selectorKeys.none,
                insert: ({ data }: CellInsertParams<GoalDashboardDetails>) => (
                  <TextButton
                    onClick={() => onViewRequested(data)}
                    color={Token.color.foreground}
                  >
                    {data.name}
                  </TextButton>
                ),
                width: 300,
              },
              viewMode
                ? null
                : {
                    type: CellTypes.insert,
                    idPoint: 'actions_column',
                    dataPoint: 'actions_column',
                    sortKey: null,
                    filterKey: null,
                    selectorsKey: selectorKeys.none,
                    insert: ({ data }: CellInsertParams<GoalDashboardDetails>) => {
                      return (
                        <Flex gap="s-8" justifyContent="flex-end">
                          <TextButton
                            disabled={!!pendingDelete}
                            onClick={async () => {
                              try {
                                const confirmed = await confirm({
                                  body: `Are you sure you want to delete ${data.name} dashboard`,
                                })
                                if (confirmed.status === 'confirmed') {
                                  setPendingDelete(data.id)
                                  await updateDashboard(
                                    {
                                      id: data.id,
                                      related_goals: data.related_goals?.filter(
                                        g => g.id !== values.id,
                                      ),
                                    },
                                    data.dashboard_type === 'tableau'
                                      ? 'tableau'
                                      : undefined,
                                  )
                                  values.dashboards = values.dashboards.filter(
                                    d => d.id !== data.id,
                                  )
                                }
                              } catch (err) {
                                captureException(err)
                                showError(
                                  'Failed to delete dashboard',
                                  'Please try again.',
                                )
                              } finally {
                                setPendingDelete(undefined)
                              }
                            }}
                          >
                            <Icon
                              name={pendingDelete === data.id ? 'Loading' : 'Delete'}
                              size={16}
                              color={Token.color.greyTone50}
                            />
                          </TextButton>
                        </Flex>
                      )
                    },
                    title: '',
                    width: 40,
                  },
            ].filter(Boolean),
          }}
        />
        {confirmationDialog}
      </Table.Widget.Table>
    </Table.Widget>
  ) : null
}
