import React from 'react'
import { StatusPopup } from '@revolut/ui-kit'
import { CareersApplicationStatusCode } from '@src/interfaces/careers'

interface CareersApplicationWidgetErrorPopupProps {
  open: boolean
  errorCode: CareersApplicationStatusCode
  onClose: () => void
}

export const CareersApplicationErrorPopup = ({
  open = false,
  errorCode,
  onClose,
}: CareersApplicationWidgetErrorPopupProps) => {
  const errorsMap = {
    [CareersApplicationStatusCode.Unknown]: 'Something went wrong. Try again later.',
    [CareersApplicationStatusCode.PostingMissing]:
      'We are not able to accept new applications for this opportunity at the moment.',

    [CareersApplicationStatusCode.BadCaptcha]:
      "We couldn't process your captcha. Please try again.",
    [CareersApplicationStatusCode.ValidationError]:
      'Some of the fields are invalid. Please check the form.',
  }

  return (
    <StatusPopup variant="error" open={open} onClose={onClose}>
      <StatusPopup.Title>Application submit failed</StatusPopup.Title>
      <StatusPopup.Description>{errorsMap[errorCode]}</StatusPopup.Description>
    </StatusPopup>
  )
}
