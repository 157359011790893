import { AxiosPromise } from 'axios'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { OnboardingData } from '@src/interfaces/onboarding'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { EmployeeDocumentListItemInterface } from '@src/interfaces/documents'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getOnboardingSteps = (id: number | string): AxiosPromise<OnboardingData> =>
  api.get(`${API.EMPLOYEES}/${id}/onboarding/steps`, undefined, 'v1', true)

export const onboardingWelcomeStepApi: RequestInterfaceNew<{}> = {
  // there is no get for welcome screen
  get: () => Promise.resolve({ data: {} } as any),
  update: async (_, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/welcomeStep`,
      undefined,
      undefined,
      'v1',
      true,
    ),
  submit: async (_, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/welcomeStep`,
      undefined,
      undefined,
      'v1',
      true,
    ),
}

export const onboardingBasicInfoApi: RequestInterfaceNew<{}> = {
  get: ({ id }) =>
    api.get(`${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`, undefined, 'v1', true),
  update: async (_, { id }, data) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async (data, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/basicInfoStep`,
      data,
      undefined,
      'v1',
      true,
    ),
}

export const onboardingGetEmailPropositions = (id: number) =>
  api.get<string[]>(
    `${API.EMPLOYEES}/${id}/onboarding/basicInfoStep/getEmailPropositions`,
    undefined,
    'v1',
    true,
  )

export const onboardingPersonalInfoApi: RequestInterfaceNew<{}> = {
  get: ({ id }) =>
    api.get(`${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`, undefined, 'v1', true),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/personalInfoStep`,
      data,
      undefined,
      'v1',
      true,
    ),
}

export const onboardingPayrollApi: RequestInterfaceNew<{}> = {
  get: ({ id }) =>
    api.get(`${API.EMPLOYEES}/${id}/onboarding/payrollStep`, undefined, 'v1', true),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/payrollStep`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/payrollStep`,
      data,
      undefined,
      'v1',
      true,
    ),
}

export const onboardingDiversityApi: RequestInterfaceNew<{}> = {
  get: ({ id }) =>
    api.get(`${API.EMPLOYEES}/${id}/onboarding/diversityStep`, undefined, 'v1', true),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/diversityStep`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async (data, { id }) =>
    apiWithoutHandling.put(
      `${API.EMPLOYEES}/${id}/onboarding/diversityStep`,
      data,
      undefined,
      'v1',
      true,
    ),
}

export const onboardingDocumentsApi: RequestInterfaceNew<{}> = {
  get: ({ id }) =>
    api.get(`${API.EMPLOYEES}/${id}/onboarding/documentsStep`, undefined, 'v1', true),
  update: async (_, { id }, data) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/documentsStep`,
      data,
      undefined,
      'v1',
      true,
    ),
  submit: async (data, { id }) =>
    api.put(
      `${API.EMPLOYEES}/${id}/onboarding/documentsStep`,
      data,
      undefined,
      'v1',
      true,
    ),
}

export const getOnboardingDocuments = (
  requestData: FetchDataQueryInterface,
  employeeId?: number | string,
): AxiosPromise<GetRequestInterface<EmployeeDocumentListItemInterface>> => {
  const { sortBy, filters, page } = requestData
  return api.get(
    `${API.EMPLOYEES}/${employeeId}/onboarding/documentsStep`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v1',
    true,
  )
}
