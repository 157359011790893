import { useCoreNavigation } from '@src/features/MainLayout/useCoreNavigation'

export const useAnnouncementWidth = () => {
  const isCoreNavigation = useCoreNavigation()

  const ANNOUNCEMENT_WIDTH = 150
  const ANNOUNCEMENT_WIDTH_V2 = 448

  return isCoreNavigation ? ANNOUNCEMENT_WIDTH_V2 : ANNOUNCEMENT_WIDTH
}
