import { GradeCalculationMethods } from '@src/interfaces/reviewCycles'

export const GradeCalculationMethodToTitle = {
  [GradeCalculationMethods.LineManagerSuggestion]: 'Line Manager Suggestion',
  [GradeCalculationMethods.FunctionalManagerSuggestion]: 'Functional Manager Suggestion',
  [GradeCalculationMethods.CalculatedGrade]: 'Scorecard ratings vs competency matrix',
  [GradeCalculationMethods.PerformanceGradeSuggestion]:
    'Combined recommendations and calculation',
  [GradeCalculationMethods.PerformanceExtraGradeSuggestion]:
    'Combined grades and complex adjustments',
}

export const getCalibrationMethodTitle = (method: GradeCalculationMethods): string => {
  return GradeCalculationMethodToTitle[method]
}
