import React, { useEffect, useMemo } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: React.ReactNode
}

export const Modal = ({ children }: Props) => {
  const el = useMemo(() => document.createElement('div'), [])
  useEffect(() => {
    document.body.appendChild(el)
    return () => {
      document.body.removeChild(el)
    }
  }, [])
  return createPortal(children, el)
}
