import React, { useEffect, useMemo, useState } from 'react'
import { format, isBefore } from 'date-fns'
import { ProgressStep, ProgressSteps } from '@revolut/ui-kit'

import { CheckpointType, ProbationCheckpoints } from '@src/interfaces/probationReview'
import {
  PerfReviewRequestFeedbackInterface,
  ReviewCategory,
  ReviewerRelation,
  ReviewSummaryInterface,
} from '@src/interfaces/performance'
import { getPerformanceReviewSummary } from '@src/api/performanceReview'
import TimelineRecommendationCheckpoint from './TimelineRecommendationCheckpoint'
import TimelineDecisionCheckpoint from './TimelineDecisionCheckpoint'
import TimelineCheckpoint from './TimelineCheckpoint'
import GoalsCheckpoint from './GoalsCheckpoint'

type Props = {
  checkpoints: ProbationCheckpoints
  ticketsCount?: number
  endDate: string
  requests?: PerfReviewRequestFeedbackInterface[]
  employeeId: number
  cycleId: string
  reviewCategory: ReviewCategory
}

const progressStepTitle = {
  [ReviewCategory.Probation]: 'End of probation',
  [ReviewCategory.PIP_V2]: 'End of PIP',
}

const ProbationTimeline = ({
  checkpoints,
  ticketsCount,
  endDate,
  requests,
  employeeId,
  cycleId,
  reviewCategory,
}: Props) => {
  const [summaryCheckpoint, setSummaryCheckpoint] = useState<
    ReviewSummaryInterface | undefined
  >()
  const [loading, setLoading] = useState(false)

  const filteredRequests = useMemo(
    () =>
      requests?.filter(
        item =>
          item.reviewer_relation === ReviewerRelation.FunctionalManager ||
          item.reviewer_relation === ReviewerRelation.LineManager,
      ),
    [requests],
  )

  const fetchSummary = async () => {
    setLoading(true)

    const result = await getPerformanceReviewSummary(
      cycleId,
      employeeId,
      reviewCategory,
      [
        {
          columnName: 'reviewer_relation',
          filters: [
            {
              id: 'line_manager',
              name: 'line_manager',
            },
            {
              id: 'functional_manager',
              name: 'functional_manager',
            },
          ],
        },
      ],
    )

    setSummaryCheckpoint(result.data)
    setLoading(false)
  }

  useEffect(() => {
    fetchSummary()
  }, [])

  return (
    <ProgressSteps variant="vertical" data-testid="progress_steps">
      {checkpoints.checkpoints.map((checkpoint, i) => {
        switch (checkpoint.checkpoint_type) {
          case CheckpointType.Goals:
            return (
              <GoalsCheckpoint
                key={i}
                employeeId={employeeId}
                cycleId={cycleId}
                reviewCategory={reviewCategory}
                startDate={checkpoint.checkpoint_date_time}
                ticketsCount={ticketsCount}
              />
            )
          case CheckpointType.Review:
            return (
              <TimelineCheckpoint
                grades={summaryCheckpoint?.summary?.final_grades}
                requests={filteredRequests}
                key={i}
                checkpoint={checkpoint}
                loading={loading}
                employeeId={employeeId}
                cycleId={cycleId}
                reviewCategory={reviewCategory}
              />
            )

          case CheckpointType.Recommendation:
            return (
              <TimelineRecommendationCheckpoint
                checkpoint={checkpoint}
                employeeId={employeeId}
                cycleId={cycleId}
                key={i}
              />
            )

          default:
            return null
        }
      })}

      {checkpoints.decision_checkpoints?.map((checkpoint, i) => (
        <TimelineDecisionCheckpoint
          key={i}
          checkpoint={checkpoint}
          employeeId={employeeId}
          cycleId={cycleId}
          reviewCategory={reviewCategory}
        />
      ))}

      <ProgressStep state={isBefore(new Date(endDate), new Date()) ? 'done' : 'pending'}>
        {/* @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */}
        <ProgressStep.Title>{progressStepTitle[reviewCategory]}</ProgressStep.Title>
        <ProgressStep.Caption>
          {format(new Date(endDate), 'd MMM')} <br />
        </ProgressStep.Caption>
      </ProgressStep>
    </ProgressSteps>
  )
}

export default ProbationTimeline
