import React, { useEffect, useState } from 'react'

import Loader from '@components/CommonSC/Loader'
import {
  KpiInterface,
  KPIPerformanceTypes,
  KPIWeightModes,
  KpiWeightsInterface,
} from '@src/interfaces/kpis'
import { kpisRequests } from '@src/api/kpis'
import WarningMessage from '@components/WarningMessage/WarningMessage'
import { formatNumber } from '@src/utils/format'
import { getSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { Statuses } from '@src/interfaces'
import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import AutoStepper from '@components/Stepper/AutoStepper'
import { Box, Cell, InputGroup } from '@revolut/ui-kit'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { RadioOption } from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import LapeNewRadioButtons from '@components/Inputs/LapeFields/LapeNewRadioButtons'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import { kpiWeightsSubmitRequest } from '@src/api/kpiWeights'
import { goBack } from '@src/actions/RouterActions'
import { useLocation } from 'react-router-dom'
import FormLocalstorageLape from '@src/features/Form/FormLocalstorageLape'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { ReviewCyclesSelectorInterface } from '@src/interfaces/reviewCycles'
import { FormValidatorProvider } from '@src/features/Form/FormValidator'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import { Grid } from '@src/components/CommonSC/Grid'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'

const getFilter = (data: KpiWeightsInterface) => {
  let columnName = ''
  let isEmployee = 'False'
  if (data.entity_type === EntityTypes.department) {
    columnName = 'department__id'
  }
  if (data.entity_type === EntityTypes.team) {
    columnName = 'team__id'
  }
  if (data.entity_type === EntityTypes.function) {
    columnName = 'function__id'
  }
  if (data.entity_type === EntityTypes.role) {
    columnName = 'role__id'
  }
  if (data.entity_type === EntityTypes.employee) {
    columnName = 'owner__id'
    isEmployee = 'True'
  }

  return [
    {
      filters: [{ name: String(data.entity_id), id: data.entity_id }],
      columnName,
    },
    {
      filters: [
        {
          name: String(data.review_cycle?.offset || 0),
          id: data.review_cycle?.offset || 0,
        },
      ],
      columnName: 'review_cycle__offset',
    },
    {
      filters: [{ name: Statuses.active, id: Statuses.active }],
      columnName: 'status',
    },
    {
      filters: [{ name: KPIPerformanceTypes.business, id: KPIPerformanceTypes.business }],
      columnName: 'kpi_performance_type',
      nonResettable: true,
    },
    {
      filters: [{ name: isEmployee, id: isEmployee }],
      columnName: 'is_employee',
    },
  ]
}

const calculateEnforcedWeights = (
  kpis: KpiInterface[],
): { sum: number; quantity: number } => {
  let sum = 0
  let quantity = 0

  kpis.forEach(({ enforce_weight, weight }) => {
    if (enforce_weight) {
      sum += weight || 0
      quantity += 1
    }
  })
  return { sum, quantity }
}

const autoCalculateWeight = (
  kpi: KpiInterface,
  numKpis: number,
  enforcedWeights: ReturnType<typeof calculateEnforcedWeights>,
) => {
  if (kpi.enforce_weight) {
    return kpi.weight || 0
  }
  return (100 - enforcedWeights.sum) / (numKpis - enforcedWeights.quantity)
}

const calculateTotal = (companyGoals: KpiWeightsInterface['kpis']): number => {
  return (
    companyGoals.reduce((prev, curr) => {
      return prev + Number(curr.weight)
    }, 0) || 0
  )
}

const General = () => {
  const { values } = useLapeContext<KpiWeightsInterface>()
  const [options, setOptions] = useState<RadioOption[]>([])
  const [total, setTotal] = useState<number>(0)
  const [fetchedKpis, setFetchedKpis] = useState<KpiInterface[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const isManual = values?.kpi_weight_mode.id !== KPIWeightModes.auto

  useEffect(() => {
    const fetchOptions = async () => {
      const result = await getSelectors(selectorKeys.kpi_weight_modes)
      if (result && result.data) {
        const parsedOptions = result.data.options.map(option => ({
          label: option.name,
          value: option.id,
        }))
        setOptions(parsedOptions)

        if (!values.kpi_weight_mode.id) {
          values.kpi_weight_mode.id = KPIWeightModes.manual
        }
      }
    }

    fetchOptions()
  }, [])

  useEffect(() => {
    if (values.review_cycle) {
      fetchKpis()
    }
  }, [values.review_cycle])

  useEffect(() => {
    const enforcedWeights = calculateEnforcedWeights(fetchedKpis)

    const kpis = fetchedKpis.map(kpi => ({
      id: kpi.id,
      name: kpi.name,
      enforce_weight: kpi.enforce_weight,
      weight: isManual
        ? Number(kpi.weight).toFixed(0)
        : Number(autoCalculateWeight(kpi, fetchedKpis.length, enforcedWeights)).toFixed(
            0,
          ),
    }))

    setTotal(calculateTotal(kpis))
    values.kpis = kpis
  }, [fetchedKpis, isManual])

  const cycleOffset = Number(values?.review_cycle?.offset)
  const disabled = !isManual || cycleOffset > 0
  const invalidTotal = total !== 100 && total !== 0 && isManual

  const fetchKpis = (): Promise<void> => {
    setIsLoading(true)
    return kpisRequests
      .getItems({ filters: getFilter(values) })
      .then(resp => {
        setFetchedKpis(resp.data.results)
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PageWrapper>
      <PageHeader title="Edit KPI Weights" backUrl="/" />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Mode" />
          <Grid flow="column" gap={16}>
            <LapeNewRadioButtons
              options={options}
              name="kpi_weight_mode.id"
              renderRadio={radio => <Cell>{radio}</Cell>}
            />
          </Grid>

          <NewStepperTitle
            title="Weights"
            subtitle={
              isManual
                ? 'Set weights for KPIs'
                : 'Set mode to manual to be able to edit weights'
            }
          />
          <InputGroup>
            <LapeRadioSelectInput<ReviewCyclesSelectorInterface>
              label="Cycle"
              name="review_cycle"
              selector={selectorKeys.review_cycles}
              selectDefaultOption={opts => {
                const found = opts.find(opt => opt.value.offset === '0')

                if (found) {
                  return {
                    ...found,
                    value: {
                      ...found.value,
                      name: `Current cycle (${found.label})`,
                    },
                  }
                }
                return undefined
              }}
              width="50%"
            />
            {isLoading ? (
              <Box use="span" p="s-16">
                <Loader />
              </Box>
            ) : (
              values.kpis.map((kpi, id) => (
                <InputGroup variant="horizontal" key={`row_${kpi.id}`}>
                  <LapeRadioSelectInput
                    name="kpi"
                    label="KPI"
                    value={{ id: kpi.id, name: kpi.name }}
                    message={
                      kpi.enforce_weight &&
                      'This KPI has an enforced weight and can not be changed'
                    }
                    disabled
                  />
                  <LapeNewInput
                    name={`kpis[${id}].weight`}
                    hideOptional
                    label="Weight"
                    hasError={invalidTotal}
                    message=""
                    renderAction={() => '%'}
                    disabled={disabled || kpi.enforce_weight}
                    onAfterChange={() => {
                      setTotal(calculateTotal(values.kpis))
                    }}
                    width="30%"
                  />
                </InputGroup>
              ))
            )}
            {invalidTotal && (
              <WarningMessage>
                The sum of all weights should be 100%, currently {formatNumber(total)}%
              </WarningMessage>
            )}
          </InputGroup>
        </AutoStepper>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup afterSubmitUrl="/" useValidator />
      </PageActions>
    </PageWrapper>
  )
}

export default () => {
  const location = useLocation<{ initialValues: any; returnAs: string }>()
  const initialValues = location?.state?.initialValues || {}

  if (!initialValues.entity_id) {
    goBack('/')
    return null
  }

  return (
    <LapeForm<KpiWeightsInterface>
      initialValues={initialValues}
      onSubmit={form => kpiWeightsSubmitRequest(form.values)}
    >
      <FormLocalstorageLape
        isExistingData={!!initialValues}
        url={ROUTES.FORMS.KPI_WEIGHTS.GENERAL}
      />
      <FormValidatorProvider>
        <General />
      </FormValidatorProvider>
    </LapeForm>
  )
}
