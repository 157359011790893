import { useEffect, useRef } from 'react'

interface UseHeroBlockImageAnimationProps {
  transform?: boolean
  opacity?: boolean
}

function modulate(
  value: number,
  rangeA: number[],
  rangeB: number[],
  limit = true,
): number {
  const [fromLow = 0, fromHigh = 0] = rangeA
  const [toLow = 0, toHigh = 0] = rangeB
  const result = toLow + ((value - fromLow) / (fromHigh - fromLow)) * (toHigh - toLow)

  if (limit) {
    const min = Math.min(toLow, toHigh)
    if (result < min) {
      return min
    }

    const max = Math.max(toLow, toHigh)
    if (result > max) {
      return max
    }
  }
  return result
}

export function useHeroBlockImageAnimation({
  transform: animateTransform,
  opacity: animateOpacity,
}: UseHeroBlockImageAnimationProps) {
  const imageRef = useRef<HTMLDivElement | null>()

  useEffect(() => {
    const handleScroll = () => {
      const image = imageRef.current
      const imageHeight = image?.clientHeight
      const { scrollY } = window

      if (image && imageHeight !== undefined && scrollY < 2 * imageHeight) {
        const scale = modulate(scrollY, [0, imageHeight + 64], [1, 1.2])
        const opacity = modulate(scrollY, [imageHeight / 2, imageHeight + 64], [1, 0])

        if (animateTransform) {
          image.style.transform = `scale(${scale})`
        }
        if (animateOpacity) {
          image.style.opacity = String(opacity)
        }
      }
    }

    if (imageRef.current) {
      imageRef.current.style.willChange = [
        animateTransform && 'transform',
        animateOpacity && 'opacity',
      ]
        .filter(Boolean)
        .join(', ')

      if (animateTransform) {
        imageRef.current.style.transform = 'scale(1)'
      }
      if (animateOpacity) {
        imageRef.current.style.opacity = '1'
      }

      handleScroll()
    }

    const target = window
    target.addEventListener('scroll', handleScroll, { passive: true })
    return () => target.removeEventListener('scroll', handleScroll)
  }, [animateTransform, animateOpacity])

  return imageRef as React.Ref<HTMLDivElement>
}
