import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'lape'
import { Box, BoxProps, Search } from '@revolut/ui-kit'
import { FilterByInterface } from '@src/interfaces/data'
import debounce from 'lodash/debounce'
import { useQuery } from '@src/utils/queryParamsHooks'

export type OnFilterHandler = (
  filter: FilterByInterface,
  resetDefaultFilters?: boolean,
  forceReset?: boolean,
) => void

interface SearchTableProps extends BoxProps {
  placeholder?: string
  onFilter: OnFilterHandler
  columnName?: string
}

const SearchTableInput = ({
  onFilter,
  placeholder = 'Search',
  columnName = 'search',
}: SearchTableProps) => {
  const [val, setVal] = useState('')
  const { query } = useQuery()
  const searchThrottled = useCallback(
    debounce(
      (q: string) => {
        return onFilter(
          {
            columnName,
            filters: q
              ? [
                  {
                    id: q,
                    name: q,
                  },
                ]
              : [],
          },
          true,
          true,
        )
      },
      1000,
      { leading: false, trailing: true },
    ),
    [],
  )

  useEffect(() => {
    if (query?.search) {
      setVal(query.search)
    }
  }, [])

  return (
    <Box width="100%" minWidth={200} maxWidth={443}>
      <Search
        onChange={q => {
          setVal(q)
          searchThrottled(q)
        }}
        value={val}
        placeholder={placeholder}
      />
    </Box>
  )
}

export default connect(SearchTableInput)
