import React from 'react'
import { Avatar, Item, VStack, Widget } from '@revolut/ui-kit'
import { GrowthPlanHeader } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/GrowthPlanLayout/GrowthPlanHeader'
import { TwoColumnsLayout } from '@src/pages/EmployeeProfile/Layout/common/TwoColumnsLayout'
import { PromotionEligibility } from '@src/features/GrowthPlans/GrowthPlan/components/PromotionEligibility/PromomtionEligibility'
import { ActionItemsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/ActionItems/ActionItemsWidget'
import { CommentsWidget } from '@src/features/GrowthPlans/GrowthPlan/PreviewGrowthPlan/Comments/Comments'
import { EmployeeInterface } from '@src/interfaces/employees'
import { GrowthPlanInterface } from '@src/interfaces/growthPlans'
import {
  getGrowthPlanCommentsAPI,
  useGetGrowthPlanApprovals,
  useUpdateGrowthPlan,
} from '@src/api/growthPlans'
import { EditorView } from '@components/Editor/EditorView'
import HideIfCommercial from '@components/HideIfCommercial/HideIfCommercial'
import { useCanAccessGrowthPlanComments } from '@src/features/GrowthPlans/hooks/useIsGrowthPlansEnabled'
import useApprovalFlow from '@src/features/ApprovalFlow/useApprovalFlow'

interface Props {
  employee: EmployeeInterface
  growthPlan: GrowthPlanInterface
  refetch: VoidFunction
}

export const GrowthPlan = ({ employee, growthPlan, refetch }: Props) => {
  const commentsAPI = getGrowthPlanCommentsAPI(growthPlan.id)
  const comments = commentsAPI.useGetComments()
  const canAccessComments = useCanAccessGrowthPlanComments(growthPlan)
  const { approvalFlow, refetchApproval } = useApprovalFlow({
    data: { id: growthPlan.id, field_options: growthPlan.field_options },
    useGetApprovals: useGetGrowthPlanApprovals,
    api: useUpdateGrowthPlan,
  })

  const handleOnMarkAction = async () => {
    await comments.refetch()
  }

  return (
    <VStack space="s-16">
      <Widget p="s-16">
        <GrowthPlanHeader
          employee={employee}
          growthPlan={growthPlan}
          refetch={refetch}
          refetchApproval={refetchApproval}
        />
      </Widget>
      <TwoColumnsLayout
        left={
          <VStack space="s-16">
            {approvalFlow}
            {growthPlan.description && (
              <Item>
                <Item.Avatar>
                  <Avatar useIcon="Document" />
                </Item.Avatar>
                <Item.Content>
                  <Item.Title>Description</Item.Title>
                  <Item.Description>
                    <EditorView html={growthPlan.description} />
                  </Item.Description>
                </Item.Content>
              </Item>
            )}
            <HideIfCommercial>
              <PromotionEligibility employee={employee} growthPlan={growthPlan} />
            </HideIfCommercial>
            {growthPlan.actions.length > 0 && (
              <ActionItemsWidget
                growthPlan={growthPlan}
                onMarkAction={handleOnMarkAction}
              />
            )}
            {canAccessComments && <CommentsWidget api={commentsAPI} />}
          </VStack>
        }
        right={null}
        leftFlex={1.5}
      />
    </VStack>
  )
}
