import React, { useState } from 'react'
import { chain, Text } from '@revolut/ui-kit'
import { upperFirst } from 'lodash'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IdAndName, Statuses } from '@src/interfaces'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import PreviewDocumentSidebar from '@src/features/PreviewDocumentSidebar/PreviewDocumentSidebar'
import { FileInterface } from '@src/interfaces/files'
import SettingsButtons, { ArchiveButton, CopyButton } from '@src/features/SettingsButtons'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { getStatusColor } from '@src/components/CommonSC/General'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { selectPermissions } from '@src/store/auth/selectors'
import EmailTemplatePreviewContent from '@src/pages/Forms/EmailTemplate/EmailTemplatePreviewContent'

const Preview = () => {
  const { initialValues } = useLapeContext<EmailTemplateInterface>()
  const permissions = useSelector(selectPermissions)

  const [documentId, setDocumentId] = useState<number>()

  const params = useParams<{ id?: string }>()

  const canAdd = permissions.includes(PermissionTypes.AddEmailTemplate)

  return (
    <>
      <PageHeader
        title={chain(
          initialValues?.name,
          <Text color={getStatusColor(initialValues?.status)}>
            {upperFirst(initialValues.status)}
          </Text>,
        )}
        backUrl={ROUTES.FORMS.EMAIL_TEMPLATES}
      />

      <PageBody>
        <SettingsButtons mb="s-16">
          <EditButton
            route={pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.GENERAL, params)}
            isVisible={canAdd}
          />
          <CopyButton
            afterSubmitUrl={pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.GENERAL, {})}
          />
          <ArchiveButton
            entityPermissions={EntityPermissions.Change}
            dialogText={`You or any employee will no longer be able to use this email template when sending emails. Are you sure you want to proceed?`}
            showDialog
            unArchiveStatus={Statuses.active}
          />
        </SettingsButtons>

        <EmailTemplatePreviewContent setDocumentId={setDocumentId} />
      </PageBody>

      {!!initialValues.attachments && documentId && (
        <PreviewDocumentSidebar
          file={
            (initialValues.attachments as FileInterface[]).find(
              attachment => attachment.id === documentId,
            ) as IdAndName
          }
          onClose={() => setDocumentId(undefined)}
        />
      )}
    </>
  )
}

export default Preview
