import { AxiosPromise } from 'axios'
import { api } from '@src/api/index'
import { API, EntityTypes } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'
import { KPIsToApproveNotification } from '@src/interfaces/kpiNotifications'

export const useGetKPIsApproveNotification = (
  notificationId: string | number,
  entityType: EntityTypes,
) => {
  let endpoint: string | null

  if (!notificationId) {
    endpoint = null
  } else if (entityType === EntityTypes.team) {
    endpoint = `${API.KPI_NOTIFICATIONS}/teamKpisToApprove/${notificationId}`
  } else {
    endpoint = `${API.KPI_NOTIFICATIONS}/departmentKpisToApprove/${notificationId}`
  }
  return useFetch<KPIsToApproveNotification>(endpoint)
}

export const removeNotificationPersonalKPIsToAssign = (
  notificationId: string | number,
): AxiosPromise<void> => {
  return api.post(
    `${API.KPI_NOTIFICATIONS}/personalKpisToAssign/${notificationId}/disable`,
  )
}

export const sendNotificationPersonalKPIsToAssign = (
  reviewCycleId: string | number,
  deadlineDate: string,
): AxiosPromise<void> => {
  return api.post(`${API.KPI_NOTIFICATIONS}/personalKpisToAssign/send`, {
    review_cycle: { id: reviewCycleId },
    deadline: deadlineDate,
  })
}

export const removeNotificationTeamKPIsToAssign = (
  notificationId: string | number,
): AxiosPromise<void> => {
  return api.post(`${API.KPI_NOTIFICATIONS}/teamKpisToAssign/${notificationId}/submit`)
}

export const removeNotificationDepartmentKPIsToAssign = (
  notificationId: string | number,
): AxiosPromise<void> => {
  return api.post(
    `${API.KPI_NOTIFICATIONS}/departmentKpisToAssign/${notificationId}/submit`,
  )
}

export const removeNotificationTeamKPIsToApprove = (
  notificationId: string | number,
): AxiosPromise<void> => {
  return api.post(`${API.KPI_NOTIFICATIONS}/teamKpisToApprove/${notificationId}/submit`)
}

export const removeNotificationDepartmentKPIsToApprove = (
  notificationId: string | number,
): AxiosPromise<void> => {
  return api.post(
    `${API.KPI_NOTIFICATIONS}/departmentKpisToApprove/${notificationId}/submit`,
  )
}
