import React, { ReactNode } from 'react'
import { Ellipsis, Flex, Text } from '@revolut/ui-kit'
import { getCycleOffsetTitleAndColor } from '@src/utils/performance'
import { Capitalize } from '@components/CommonSC/General'
import { Cycle } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'

export const OptionItem = ({ label, value }: { label?: ReactNode; value: Cycle }) => {
  const { title, color } = getCycleOffsetTitleAndColor(value.review_cycle_offsets[0])

  return (
    <Flex flexWrap="nowrap" gap="s-8" alignItems="center">
      <Ellipsis>
        <Text variant="emphasis2">{label}</Text>
      </Ellipsis>
      <Text variant="emphasis2" color={color}>
        <Capitalize>{title}</Capitalize>
      </Text>
    </Flex>
  )
}
