import React from 'react'
import { Action, MoreBar } from '@revolut/ui-kit'
import { refreshInterviewStages } from '@src/api/recruitment/interviews'

type RefreshInterviewStagesProps = {
  roundId: number
  variant?: 'moreBar' | 'action'
  onRefresh: () => Promise<void>
}

const RefreshInterviewStages = ({
  roundId,
  variant = 'moreBar',
  onRefresh,
}: RefreshInterviewStagesProps) => {
  const handleClick = async () => {
    await refreshInterviewStages(roundId)
    onRefresh()
  }
  if (variant === 'moreBar') {
    return (
      <MoreBar.Action useIcon="ArrowRepeat" onClick={handleClick}>
        Refresh stages
      </MoreBar.Action>
    )
  }
  return (
    <Action useIcon="ArrowRepeat" onClick={handleClick}>
      Refresh stages
    </Action>
  )
}

export default RefreshInterviewStages
