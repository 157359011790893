import React, { useState } from 'react'
import { Button, Popup, Text, Stars, Flex, Cell, TextArea, Box } from '@revolut/ui-kit'

type Props = {
  onSubmit: (score: number, comment?: string) => void
  onClose: () => void
  meetingTitle: string
}

export const MeetingRatingPopup = ({ onClose, onSubmit, meetingTitle }: Props) => {
  const [score, setScore] = useState<number | null>(null)
  const [comment, setComment] = useState<string>()

  return (
    <Popup variant="bottom-sheet" onClose={onClose} open>
      <Text variant="h4" fontWeight={600}>
        Is this meeting an effective use of time?
      </Text>
      <Text variant="caption" my="s-16" color="grey-tone-50">
        How would you rate “{meetingTitle}”?
      </Text>
      <Cell backgroundColor="grey-tone-50">
        <Flex justifyContent="center" width="100%">
          <Stars value={score} onChange={setScore} size={26} />
        </Flex>
      </Cell>
      <Box mt="s-8">
        <TextArea
          value={comment}
          onChange={e => setComment(e.currentTarget.value)}
          label="Leave your comment..."
          rows={3}
        />
      </Box>

      <Popup.Actions horizontal>
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => score && onSubmit(score, comment)} disabled={!score}>
          Submit
        </Button>
      </Popup.Actions>
    </Popup>
  )
}
