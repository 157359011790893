import React from 'react'
import { Box } from '@revolut/ui-kit'
import { connect } from 'lape'
import { Route, Switch, useParams } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@src/components/Page/Page'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import General from './General'
import { groupRequest, useGetGroupStats } from '@src/api/accessManagement'
import { GroupAccessManagementInterface } from '@src/interfaces/accessManagement'
import Details from '@src/pages/Forms/AccessManagementGroup/Details'
import Users from '@src/pages/Forms/AccessManagementGroup/Users'
import { PermissionTypes } from '@src/store/auth/types'
import { Header, checkIsExpiring } from './common'
import { Recertify } from './Recertify'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'

const AccessManagementGroupTabs = () => {
  const { values, initialValues } = useLapeContext<GroupAccessManagementInterface>()
  const params = useParams<{ id?: string }>()

  const { data: groupStats } = useGetGroupStats(params.id)

  const canViewUserGroups = values?.field_options?.permissions?.includes(
    PermissionTypes.ViewUserGroups,
  )
  const isExpiring = checkIsExpiring(
    initialValues.settings?.certification_expiration_date_time,
  )

  const tabs = [
    {
      title: 'Details',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.DETAILS, params),
      component: Details,
      canView: true,
    },
    {
      title: 'Users',
      path: ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.USERS,
      to: pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.USERS, params),
      component: Users,
      quickSummary: groupStats?.users,
      canView: canViewUserGroups,
    },
  ].filter(tab => tab.canView)

  if (isExpiring && canViewUserGroups) {
    return (
      <InternalRedirect
        to={pathToUrl(ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.RECERTIFY, params)}
      />
    )
  }

  return (
    <>
      <Header name={values.name} status={values.settings?.status}>
        {tabs.length > 1 && (
          <Box pb="s-8" maxWidth="100vw">
            <TabBarNavigation tabs={tabs} />
          </Box>
        )}
      </Header>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </>
  )
}

export default connect(() => (
  <Form api={groupRequest}>
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.RECERTIFY}>
          <Recertify />
        </Route>
        <Route exact path={ROUTES.FORMS.ACCESS_MANAGEMENT_GROUP.GENERAL}>
          <General />
        </Route>
        <AccessManagementGroupTabs />
      </Switch>
    </PageWrapper>
  </Form>
))
