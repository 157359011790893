import React from 'react'
import { Box, Widget } from '@revolut/ui-kit'
import OpenInNewTab from '@src/components/OpenInNewTab/OpenInNewTab'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle, {
  NewStepperTitleType,
} from '@src/components/Stepper/NewStepperTitle'
import { TABLEAU_LOGIN } from '@src/constants/externalLinks'

export const TableauHelperContent = () => {
  return (
    <Widget mb="s-16">
      <AutoStepper>
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title="Login to your Tableau account"
          bulletVariant="filled"
          subtitle={
            <Box pt="s-8">
              <OpenInNewTab link={TABLEAU_LOGIN} label="Open Tableau" />
            </Box>
          }
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title="Navigate to Settings and go to “Connected Apps” tab"
          subtitle="You need admin rights to do this"
          bulletVariant="filled"
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title="Click “New Connected App”, select “Direct Trust” and give it a name"
          bulletVariant="filled"
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title="Once the Connected app is created, hit “Generate New Secret”"
          subtitle="These details (i.e. Client ID, Secret ID and Secret Value) will be used to configure Tableau in Revolut People"
          bulletVariant="filled"
        />
        <NewStepperTitle
          type={NewStepperTitleType.coreUI}
          title="Make sure to enable the newly created Connected app if it’s disabled"
          bulletVariant="filled"
        />
      </AutoStepper>
    </Widget>
  )
}
