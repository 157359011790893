import { Reducer } from 'redux'
import { produce } from 'immer'
import { FeedbackActionsTypes, FeedbackState } from './types'

const initialFeedbackState: FeedbackState = {
  open: false,
  loading: false,
}

const reducer: Reducer<FeedbackState> = (state = initialFeedbackState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case FeedbackActionsTypes.CLOSE_FEEDBACK:
        draft.open = false
        break
      case FeedbackActionsTypes.SET_FEEDBACK_OPEN: {
        draft.open = action.payload
        break
      }
      case FeedbackActionsTypes.SET_LOADING: {
        draft.loading = action.payload
        break
      }
      default:
        break
    }
  })
}

export { reducer as FeedbackReducer }
