import React from 'react'
import {
  PerformanceReviewTypes,
  ReviewCategory,
  SummarySkillCardInterface,
} from '@src/interfaces/performance'
import RatingTooltip from '@src/pages/EmployeeProfile/Preview/Performance/Common/RatingTooltip'
import { pathToUrl } from '@src/utils/router'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { RatingLabelTypes } from '@src/features/EmployeePerformance/RatingLabel'
import SummaryJustification from '@components/ColumnInserts/Justification/SummaryJustification'
import { Box, Flex, Text } from '@revolut/ui-kit'
import { getLocationDescriptor } from '@src/actions/RouterActions'

interface Props {
  data: SummarySkillCardInterface
  cycleId?: string
  employeeId?: number
  type?: PerformanceReviewTypes
  category: ReviewCategory
  hideJustification?: boolean
}

const SummaryCardRating = ({
  data,
  cycleId,
  employeeId,
  type,
  category,
  hideJustification,
}: Props) => {
  const location = useLocation()

  if (!data.rating) {
    return <>N/A</>
  }

  const isUpwards = category === ReviewCategory.Upwards

  let path = isUpwards
    ? ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SUMMARY
    : ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SUMMARY
  switch (type) {
    case PerformanceReviewTypes.skills:
    case PerformanceReviewTypes.managerValuesSkills:
      if (category === ReviewCategory.Upwards) {
        path = ROUTES.FORMS.UPWARDS_REVIEW_VIEW.SKILLS
      }
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.SKILLS
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW_VIEW.SKILLS
      }
      if (category === ReviewCategory.PIP_V2) {
        path = ROUTES.FORMS.PIP_REVIEW_VIEW.SKILLS
      }
      break
    case PerformanceReviewTypes.deliverables:
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW_VIEW.DELIVERABLES
      }
      if (category === ReviewCategory.PIP_V2) {
        path = ROUTES.FORMS.PIP_REVIEW_VIEW.DELIVERABLES
      }
      break
    case PerformanceReviewTypes.cultureFit:
    case PerformanceReviewTypes.cultureValuesFit:
      if (category === ReviewCategory.Upwards) {
        path = ROUTES.FORMS.UPWARDS_REVIEW_VIEW.CULTURE_FIT
      }
      if (category === ReviewCategory.Performance) {
        path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.CULTURE_FIT
      }
      if (category === ReviewCategory.Probation) {
        path = ROUTES.FORMS.PROBATION_REVIEW_VIEW.CULTURE_FIT
      }
      if (category === ReviewCategory.PIP_V2) {
        path = ROUTES.FORMS.PIP_REVIEW_VIEW.CULTURE_FIT
      }
      break
    case PerformanceReviewTypes.kpi:
      path = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_VIEW.GENERAL
      break
  }

  const hashKey = data.skill_id || data.criteria_key

  return (
    <Flex justifyContent="space-between" width="100%">
      <Flex>
        {data.ratings?.length ? (
          <Link
            to={{
              ...getLocationDescriptor(pathToUrl(path, { id: cycleId, employeeId })),
              hash: hashKey ? `#${hashKey}` : '',
              search: location.search,
            }}
          >
            <RatingTooltip
              ratings={data.ratings!}
              totalRating={data.rating}
              placement="top"
              type={RatingLabelTypes.InverseUnderline}
            />
          </Link>
        ) : (
          <RatingTooltip
            ratings={data.ratings!}
            totalRating={data.rating}
            placement="top"
            type={RatingLabelTypes.InverseUnderline}
          />
        )}
        {data.ratings?.length ? (
          <Text fontSize="tiny" pl="s-8">
            {data.ratings.length}
          </Text>
        ) : null}
      </Flex>
      {hideJustification ? null : (
        <Box width="h5" ml="10px">
          <SummaryJustification data={data} />
        </Box>
      )}
    </Flex>
  )
}

export default SummaryCardRating
