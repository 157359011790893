import React, { useState } from 'react'
import {
  BottomSheet,
  Header,
  Button,
  Popup,
  PopupProps,
  Input,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { createStrategicGoal } from '@src/api/goals'
import { selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { captureException } from '@sentry/react'
import { useGetGoals } from '@src/api/goals'

export const StrategicGoalPopup = (props: PopupProps & { onClose: VoidFunction }) => {
  const user = useSelector(selectUser)
  const [strategicGoalName, setStrategicGoalName] = useState<string>('')
  const [error, setError] = useState<string>()
  const statusPopup = useStatusPopup()
  const { refetch } = useGetGoals({
    page: 1,
    filters: [{ columnName: 'is_top_level', filters: [{ id: 'True', name: 'True' }] }],
  })

  const handleClose = () => {
    setStrategicGoalName('')
    setError(undefined)
    props.onClose()
  }

  const handleCreateStrategicGoal = async () => {
    try {
      await createStrategicGoal({
        name: strategicGoalName,
        owner: { id: user.id },
      })
      refetch()
      handleClose()
    } catch (err) {
      captureException(err)
      if (err.response?.data?.name) {
        setError(err.response.data.name)
      } else {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Failed to create goal</StatusPopup.Title>
            <StatusPopup.Description>
              Something went wrong. Please try again.
            </StatusPopup.Description>
          </StatusPopup>,
        )
      }
    }
  }
  return (
    <Popup {...props} onClose={handleClose} variant="bottom-sheet">
      <Header>
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Add Strategic goal</Header.Title>
      </Header>
      <Input
        label="Name"
        aria-invalid={!!error}
        errorMessage={error}
        value={strategicGoalName}
        onChange={e => {
          if (error) {
            setError(undefined)
          }
          setStrategicGoalName(e.currentTarget.value)
        }}
      />
      <BottomSheet.Actions horizontal>
        <Button
          elevated
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            handleCreateStrategicGoal()
          }}
        >
          Confirm
        </Button>
      </BottomSheet.Actions>
    </Popup>
  )
}
