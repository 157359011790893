import { Chat } from '@revolut/icons'
import { ActionButton, MoreBar, chain } from '@revolut/ui-kit'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import { CommentsAPIInterface } from '@src/interfaces/chat'
import React, { useState } from 'react'

type Props = {
  api: CommentsAPIInterface
  disableTodolistFeature?: boolean
  showArchived?: boolean
  useMoreBar?: boolean
}

const CommentsActionButton = ({
  api,
  disableTodolistFeature = true,
  showArchived = false,
  useMoreBar = false,
}: Props) => {
  const [commentsOpen, setCommentsOpen] = useState(false)

  const { data } = api.useGetComments(showArchived)

  const Component: React.ElementType = useMoreBar ? MoreBar.Action : ActionButton

  return (
    <>
      <Component useIcon={Chat} onClick={() => setCommentsOpen(true)}>
        {chain('Comments', data?.results.length)}
      </Component>
      <ChatSidebar
        type={ChatMessageType.Comment}
        isOpen={commentsOpen}
        onClose={() => {
          setCommentsOpen(false)
        }}
        data={api.useGetComments(showArchived, !commentsOpen).data?.results || []}
        isLoading={api.useGetComments(showArchived, !commentsOpen).isLoading}
        refetch={api.useGetComments(showArchived, !commentsOpen).refetch}
        onAddMessage={api.addComment}
        onEdit={api.editComment}
        onArchive={api.archiveComment}
        onResolve={api.resolveComment}
        disableTodolistFeature={disableTodolistFeature}
      />
    </>
  )
}

export default CommentsActionButton
