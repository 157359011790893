import { EmployeeInterface } from '@src/interfaces/employees'
import {
  useGetEmployeeFreeBusySlots,
  useGetEmployeeMeetingEvents,
} from '@src/api/meetings'
import { LargeWeeklyCalendarEventInterface } from '@components/LargeWeeklyCalendar'
import { NavigationWeek } from '@components/NavigateWeek/NavigateWeek'
import { FreeBusyCalendar, MeetingEvent, TimeSlot } from '@src/interfaces/meetings'
import {
  calendarEventTextColor,
  getCalendarEventBackgroundColor,
  otherCalendarEventBackgroundColor,
} from '@src/pages/EmployeeProfile/Layout/Meetings/OneToOne/EmployeeMeetings/MeetingsCalendar/ReviewEvents/constants'

interface CalendarEvents {
  isLoading: boolean
  events: LargeWeeklyCalendarEventInterface[]
}

export const useCalendarEvents = (
  employee: EmployeeInterface,
  week: NavigationWeek,
): CalendarEvents => {
  const dateRange = [week.start, week.end].join(',')

  const { data: oneToOnes, isLoading: isEventsLoading } = useGetEmployeeMeetingEvents({
    employee_id: employee.id,
    is_assigned: true,
    is_series: false,
    start: dateRange,
  })
  const { data: freeBusySlots, isLoading: isFreeBusySlotsLoading } =
    useGetEmployeeFreeBusySlots({
      employee_ids: employee.id.toString(),
      time_min: week.start,
      time_max: week.end,
    })

  const oneToOneEvents = oneToOnes?.results ?? []
  const events = [
    ...oneToOneEvents.map(adaptOneToOneMeetingToCalendarEvent),
    ...(freeBusySlots
      ? adaptFreeBusySlotsToCalendarEvents(freeBusySlots, oneToOneEvents)
      : []),
  ]

  return {
    isLoading: isEventsLoading || isFreeBusySlotsLoading,
    events,
  }
}

const adaptOneToOneMeetingToCalendarEvent = (
  event: MeetingEvent,
): LargeWeeklyCalendarEventInterface => ({
  id: event.id.toString(),
  title: event.title,
  start: event.start,
  end: event.end,
  color: calendarEventTextColor,
  backgroundColor: getCalendarEventBackgroundColor(event),
})

const adaptFreeBusySlotsToCalendarEvents = (
  calendar: FreeBusyCalendar,
  excludeSlots: TimeSlot[],
): LargeWeeklyCalendarEventInterface[] =>
  Object.values(calendar.calendars).reduce<LargeWeeklyCalendarEventInterface[]>(
    (events, busySlot) => [
      ...events,
      ...busySlot.busy
        .filter(
          ({ start, end }) =>
            !excludeSlots.some(slot => slot.start === start && slot.end === end),
        )
        .map(timeSlot => ({
          ...timeSlot,
          color: calendarEventTextColor,
          backgroundColor: otherCalendarEventBackgroundColor,
          raw: {
            hideTime: true,
          },
        })),
    ],
    [],
  )
