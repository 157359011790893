import React from 'react'
import { connect } from 'lape'
import { Button, ButtonProps } from '@revolut/ui-kit'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface NextButtonProps extends ButtonProps {
  afterSubmit?: (data: object) => void
}

export const NextButton = connect(
  ({ afterSubmit, onClick, children, ...props }: NextButtonProps) => {
    const formValidator = useFormValidator()
    const { isSubmitting, submit } = useLapeContext<{ id?: number }>()

    const formSubmit = async () => {
      const result = await submit()
      afterSubmit?.(result)
      return result
    }

    const onSubmit = formValidator?.validate
      ? formValidator.validate(formSubmit)
      : formSubmit

    const buttonOnClick = onClick || onSubmit

    return (
      <Button onClick={buttonOnClick} pending={isSubmitting} {...props}>
        {children || 'Next'}
      </Button>
    )
  },
)

export const BackButton = (props: ButtonProps) => {
  return (
    <Button {...props} variant="secondary">
      Back
    </Button>
  )
}
