import React from 'react'
import PayrollForm, { validator, Props } from './General'
import Form from '@src/features/Form/Form'
import { payrollRequests } from '@src/api/employees'

const Payroll = (props: Props) => {
  return (
    <Form api={payrollRequests} validator={validator}>
      <PayrollForm {...props} />
    </Form>
  )
}

export default Payroll
