import React from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import { BoxInnerProps } from '@revolut/ui-kit/types/dist/components/Box/Box'
import { KpiInterface, UpdateTypes } from '@src/interfaces/kpis'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { LapeFormInterface, useLapeContext } from '@src/features/Form/LapeForm'
import { KPITypes } from '@src/constants/table'

export const RoadmapCalibrationBanner = (props: Omit<BoxInnerProps, 'bg'>) => {
  return (
    <ActionWidget
      title="The final performance might be different from the actual progress"
      text="The progress of the roadmap epics will be reviewed at the end of the quarter
        and calibrated by the heads of departments. The final performance might be
        different from the actual progress based on the assessment of speed, quality
        and complexity."
      {...props}
    />
  )
}

export type KPIQueryParams = {
  type?: UpdateTypes | 'inherit'
}

export const useIsApprovalsEnabled = () => {
  const { data: settings } = useGetPerformanceSettings()
  const { values } = useLapeContext<KpiInterface>()

  return (() => {
    if (values.kpi_type.id === KPITypes.employee_kpi) {
      return !!settings?.enable_employee_kpi_approvals
    }
    if (values.kpi_type.id === KPITypes.team_kpi) {
      return !!settings?.enable_team_kpi_approvals
    }
    if (values.kpi_type.id === KPITypes.department_kpi) {
      return !!settings?.enable_department_kpi_approvals
    }
    if (values.kpi_type.id === KPITypes.company_kpi) {
      return !!settings?.enable_company_kpi_approvals
    }
    if (values.kpi_type.id === KPITypes.function_kpi) {
      return !!settings?.enable_function_kpi_approvals
    }
    if (values.kpi_type.id === KPITypes.role_kpi) {
      return !!settings?.enable_role_kpi_approvals
    }
    return true
  })()
}

export const submitForm = ({
  values,
  submit,
}: Pick<LapeFormInterface<KpiInterface>, 'values' | 'submit'>): Promise<KpiInterface> => {
  // we want to clean up payload before submit to keep user input as is
  if (values.sql_query_db?.id === 'looker') {
    values.sql_query = null
  } else {
    values.look_url = null
  }
  return submit()
}
