import { useGetGoalContentTypeFilter } from '@src/features/Goals/useGoalFilters'
import { OrgEntityInterface } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { Statuses } from '@src/interfaces'
import { PerformanceSelector } from '@src/interfaces/performance'
import { NameIdInterface } from '@src/interfaces/requisitions'
import { useMemo } from 'react'

export const useGetGoalsFilters = (
  selectedPeriod: PerformanceSelector,
  entity?: OrgEntityInterface | null,
  team?: NameIdInterface,
) => {
  const { filter: contentTypeFilter } = useGetGoalContentTypeFilter(entity?.type)

  return useMemo(() => {
    const employeeGoalsFilters = [
      {
        columnName: 'cycle__id',
        filters: [{ id: selectedPeriod.id, name: selectedPeriod.name }],
        nonResettable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      ...(entity
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: entity.data.id, name: entity.data.name || '' }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
      ...(contentTypeFilter ? [contentTypeFilter] : []),
    ]

    const teamGoalsFilters = [
      {
        columnName: 'cycle__id',
        filters: [{ id: selectedPeriod.id, name: selectedPeriod.name }],
        nonResettable: true,
      },
      {
        columnName: 'approval_status',
        nonResettable: true,
        filters: [
          { id: Statuses.pending, name: Statuses.pending },
          { id: Statuses.approved, name: Statuses.approved },
          { id: Statuses.requires_changes, name: Statuses.requires_changes },
        ],
      },
      ...(team
        ? [
            {
              columnName: 'object_id',
              filters: [{ id: team.id, name: team.name || '' }],
              nonResettable: true,
              nonInheritable: true,
            },
          ]
        : []),
      ...(contentTypeFilter ? [contentTypeFilter] : []),
    ]

    return { employeeGoalsFilters, teamGoalsFilters }
  }, [selectedPeriod, team, entity, contentTypeFilter])
}
