import React, { useRef, useState } from 'react'
import { Retry } from '@revolut/icons'
import { Dropdown, MoreBar, Tooltip, useTooltip } from '@revolut/ui-kit'

import { EmployeeInterface } from '@src/interfaces/employees'
import { useCanViewCreateProbation } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'
import { isBefore } from 'date-fns'
import { createProbationCycle } from '@src/api/probationEmployees'
import { successNotification } from '@src/actions/NotificationActions'

type Props = {
  data: EmployeeInterface
  refreshData?: () => void
  isDropdownItem?: boolean
}

export const CreateProbation = ({ data, refreshData, isDropdownItem }: Props) => {
  const canView = useCanViewCreateProbation({ data })

  const [pending, setPending] = useState(false)
  const probationTooltip = useTooltip()
  const { performance_cycles } = data

  const probationRef = useRef(null)

  if (!canView) {
    return null
  }

  const currentCycle = performance_cycles
    ?.filter(cycle => cycle.category === 'probation')
    .find?.(cycle => {
      const now = new Date()
      const end = new Date(cycle.end_date_time)
      return isBefore(now, end)
    })

  const handleCreateCycle = async () => {
    setPending(true)
    try {
      await createProbationCycle(data.id)
      successNotification('Probation cycle created')
      refreshData && refreshData()
    } finally {
      setPending(false)
    }
  }

  if (currentCycle) {
    return isDropdownItem ? (
      <Dropdown.Item
        use="button"
        useIcon={Retry}
        aria-disabled
        {...probationTooltip.getAnchorProps()}
        ref={probationRef}
      >
        Create probation cycle
        <Tooltip
          {...probationTooltip.getTargetProps()}
          anchorRef={probationRef}
          maxWidth="250px"
        >
          This employee is already on an active probation cycle.
        </Tooltip>
      </Dropdown.Item>
    ) : (
      <MoreBar.Action
        useIcon={Retry}
        aria-disabled
        {...probationTooltip.getAnchorProps()}
        ref={probationRef}
      >
        Create probation cycle
        <Tooltip
          {...probationTooltip.getTargetProps()}
          anchorRef={probationRef}
          maxWidth="250px"
        >
          This employee is already on an active probation cycle.
        </Tooltip>
      </MoreBar.Action>
    )
  }

  return isDropdownItem ? (
    <Dropdown.Item use="button" useIcon={Retry} onClick={handleCreateCycle}>
      Create probation cycle
    </Dropdown.Item>
  ) : (
    <MoreBar.Action useIcon={Retry} onClick={handleCreateCycle} pending={pending}>
      Create probation cycle
    </MoreBar.Action>
  )
}
