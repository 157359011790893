import React from 'react'
import { connect } from 'lape'
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom'
import { Box, MoreBar } from '@revolut/ui-kit'

import Form from '@src/features/Form/Form'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { schedulingPoliciesRequests } from '@src/api/attendance'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SchedulingPolicyInterface } from '@src/interfaces/attendance'
import Preview from './Preview'
import General from './General'
import Shifts from './Shifts'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import { PreTitle } from '@src/features/TabBarNavigation/PreTitle'
import { DeleteSchedulingPolicyButton, useTitle } from './common'

const SchedulingPolicy = () => {
  const { values } = useLapeContext<SchedulingPolicyInterface>()

  const params = useParams<{ id?: string }>()

  const isPreviewRoute = !!useRouteMatch(ROUTES.FORMS.SCHEDULING_POLICY.PREVIEW)

  const configurationCompleted = values.configuration_step?.id === 'completed'
  const backUrl = ROUTES.APPS.ATTENDANCE.POLICIES

  const title = useTitle(values)

  if (isPreviewRoute) {
    return <Preview />
  }

  const tabs = [
    {
      title: 'General',
      path: ROUTES.FORMS.SCHEDULING_POLICY.GENERAL,
      to: pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.GENERAL, params),
      component: General,
      preTitle: <PreTitle complete={!!values.id} />,
    },
    {
      title: 'Shifts',
      path: ROUTES.FORMS.SCHEDULING_POLICY.SHIFTS,
      to: pathToUrl(ROUTES.FORMS.SCHEDULING_POLICY.SHIFTS, params),
      component: Shifts,
      preTitle: <PreTitle complete={configurationCompleted} />,
      disabled: !values.id,
    },
  ]

  return (
    <PageWrapper>
      <PageHeader pb="s-8" title={title} backUrl={backUrl}>
        <Box pb="s-8" maxWidth="100vw">
          <TabBarNavigation tabs={tabs} />
        </Box>
        {values.id && !configurationCompleted ? (
          <Box my="s-8">
            <MoreBar>
              <DeleteSchedulingPolicyButton />
            </MoreBar>
          </Box>
        ) : null}
      </PageHeader>
      <Switch>
        {tabs.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={schedulingPoliciesRequests}>
    <SchedulingPolicy />
  </Form>
))
