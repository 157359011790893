import React, { useState } from 'react'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SkillInterface } from '@src/interfaces/skills'
import { ROUTES } from '@src/constants/routes'
import { successNotification } from '@src/actions/NotificationActions'
import { Button, ButtonProps } from '@revolut/ui-kit'
import Tooltip from '@components/Tooltip/Tooltip'
import { pathToUrl } from '@src/utils/router'
import { Statuses } from '@src/interfaces'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { navigateReplace } from '@src/actions/RouterActions'

interface SaveDraftButtonProps extends ButtonProps {}

const SaveDraftButton = (props: SaveDraftButtonProps) => {
  const { values, submit } = useLapeContext<SkillInterface>()
  const [pending, setPending] = useState<boolean>()
  const isExistingData = !!values.id
  const isDisabled = !values.name

  if (values.id && values.status !== Statuses.draft) {
    return null
  }

  const onSaveDraft = async () => {
    values.status = Statuses.draft
    setPending(true)

    try {
      const res = await submit()
      workspaceLocalStorage.removeItem(pathToUrl(ROUTES.FORMS.SKILL.GENERAL, {}))
      successNotification(
        isExistingData ? 'Skill draft has been updated' : 'Skill draft has been created',
      )
      navigateReplace(pathToUrl(ROUTES.FORMS.SKILL.GENERAL, { id: res.id }))
    } finally {
      setPending(false)
    }
  }

  return (
    <Tooltip placement="top">
      <Button
        pending={pending}
        variant="secondary"
        elevated
        disabled={isDisabled}
        onClick={onSaveDraft}
        {...props}
      >
        {values.status === Statuses.draft ? 'Update draft' : 'Save to Drafts'}
      </Button>
    </Tooltip>
  )
}

export default SaveDraftButton
