import React from 'react'
import { InterviewStatuses } from '@src/interfaces/interviewTool'
import { Color, Text } from '@revolut/ui-kit'

type Props = {
  status?: InterviewStatuses
}

const ScheduledStatus = ({ status }: Props) => {
  const commonProps = {
    fontSize: 'primary',
    fontWeight: 500,
    'data-testid': 'scheduling-status',
  }
  switch (status) {
    case InterviewStatuses.pending_cv_screening:
      return (
        <Text {...commonProps} color={Color.ORANGE}>
          Pending CV screening
        </Text>
      )

    case InterviewStatuses.awaiting_interviewer_availability:
      return (
        <Text {...commonProps} color={Color.ORANGE}>
          Awaiting interviewer availability
        </Text>
      )

    case InterviewStatuses.scheduling_expired:
      return (
        <Text {...commonProps} color={Color.RED}>
          Slots expired
        </Text>
      )

    case InterviewStatuses.interviewer_rejected:
      return (
        <Text {...commonProps} color={Color.RED}>
          Interviewer rejected the interview
        </Text>
      )

    case InterviewStatuses.interview_cancelled:
      return (
        <Text {...commonProps} color={Color.RED}>
          Interview cancelled
        </Text>
      )

    case InterviewStatuses.pending_candidate_response:
      return (
        <Text {...commonProps} color={Color.ORANGE}>
          Pending candidate response
        </Text>
      )

    case InterviewStatuses.awaiting_feedback:
      return (
        <Text {...commonProps} color={Color.ORANGE}>
          Awaiting feedback
        </Text>
      )

    case InterviewStatuses.interview_scheduled:
      return (
        <Text {...commonProps} color={Color.FOREGROUND}>
          Interview scheduled
        </Text>
      )

    default:
      return null
  }
}

export default ScheduledStatus
