import React, { useMemo } from 'react'
import groupBy from 'lodash/groupBy'

import { ChevronRight } from '@revolut/icons'
import {
  Color,
  DetailsCellSkeleton,
  Group,
  Item,
  Text,
  TextSkeleton,
  VStack,
} from '@revolut/ui-kit'
import { FaqInterface } from '@src/interfaces/faq'
import { getFaqQuestionsOptions } from '@src/api/faq'
import { FiltersState, useFaqSearch } from '../hooks'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmptySearchResults } from './EmptySearchResults'

type Props = {
  filtersState: FiltersState
  country?: OptionInterface
  onSelect: (question: FaqInterface) => void
  onEmptySearchResults: {
    onGoToAllCategoriesClick: () => void
    onChatWithUsClick: () => void
  }
  instantSearch?: boolean
}
export const QuestionsSidebarContent = ({
  filtersState,
  country,
  onSelect,
  onEmptySearchResults,
  instantSearch,
}: Props) => {
  const {
    options: questions,
    isLoadingMore,
    isReloadingFilters,
    ref,
    hasMoreData,
  } = useFaqSearch({
    getPaginatedOptions: getFaqQuestionsOptions,
    filtersState,
    sidebarView: 'questions',
    country,
    instantSearch,
  })

  const groupedQuestions: Record<string, FaqInterface[]> = useMemo(
    () => groupBy(questions, 'topic.title'),
    [questions],
  )

  if (!isLoadingMore && !isReloadingFilters && !questions.length) {
    return (
      <EmptySearchResults
        onGoToAllCategoriesClick={onEmptySearchResults.onGoToAllCategoriesClick}
        onChatWithUsClick={onEmptySearchResults.onChatWithUsClick}
      />
    )
  }

  if (instantSearch) {
    return isReloadingFilters ? (
      <VStack space="s-8">
        <TextSkeleton variant="h5" width={180} />
        <Group>
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
          <DetailsCellSkeleton />
        </Group>
      </VStack>
    ) : (
      <VStack space="s-24">
        {Object.entries(groupedQuestions).map(([topic, questionsGroup]) => (
          <VStack key={topic} space="s-8">
            <Text color={Color.GREY_TONE_50} fontWeight={500}>
              {topic}
            </Text>
            <Group>
              {questionsGroup.map(question => (
                <Item
                  key={question.id}
                  use="button"
                  padding="s-16"
                  onClick={() => {
                    onSelect(question)
                  }}
                >
                  <Item.Content>
                    <Text>{question.title}</Text>
                  </Item.Content>
                  <Item.Side>
                    <ChevronRight color={Color.GREY_TONE_50} />
                  </Item.Side>
                </Item>
              ))}
            </Group>
          </VStack>
        ))}
      </VStack>
    )
  }

  return (
    <>
      <Group>
        {!isLoadingMore && isReloadingFilters ? (
          <>
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
            <DetailsCellSkeleton />
          </>
        ) : (
          <>
            {questions.map(question => (
              <Item
                key={question.id}
                use="button"
                padding="s-16"
                onClick={() => {
                  onSelect(question)
                }}
              >
                <Item.Content>
                  <Text>{question.title}</Text>
                </Item.Content>
                <Item.Side>
                  <ChevronRight color={Color.GREY_TONE_50} />
                </Item.Side>
              </Item>
            ))}
          </>
        )}
      </Group>
      <Group>
        <VStack ref={ref} space="s-12">
          {isLoadingMore && hasMoreData && (
            <>
              <DetailsCellSkeleton />
              <DetailsCellSkeleton />
              <DetailsCellSkeleton />
            </>
          )}
        </VStack>
      </Group>
    </>
  )
}
