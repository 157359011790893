import React from 'react'
import { Box } from '@revolut/ui-kit'

import { CareersPositionLocation } from '@src/interfaces/careers'
import CareersPositionLocationsRow from '@src/pages/Careers/CareersPosition/CareersPositionLocationsRow'
import { CareersLocationsVariant } from '@src/interfaces/careers'

interface CareersPositionWidgetLocationsListProps {
  variant?: CareersLocationsVariant
  locations: CareersPositionLocation[]
}

const CareersPositionLocationsList = ({
  variant = CareersLocationsVariant.Default,
  locations,
}: CareersPositionWidgetLocationsListProps) => {
  const officeLocations = locations.filter(location => location.type === 'office')
  const remoteLocations = locations.filter(location => location.type === 'remote')

  return (
    <Box>
      {Boolean(officeLocations.length) && (
        <CareersPositionLocationsRow
          locations={officeLocations}
          type="office"
          variant={variant}
        />
      )}
      {Boolean(remoteLocations.length) && (
        <CareersPositionLocationsRow
          locations={remoteLocations}
          type="remote"
          variant={variant}
        />
      )}
    </Box>
  )
}

export default CareersPositionLocationsList
