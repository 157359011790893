import React from 'react'
import { useIsTestPerformanceCycleEnabled } from '@src/pages/Forms/ReviewCycle/ReviewCycle/useIsTestPerformanceCycleEnabled'
import GradeLogicForm from '@src/pages/Forms/ReviewCycle/GradeLogicForm'
import { EditForm } from '@src/pages/Forms/ReviewCycle/ReviewCycle/CalibrationLogic/EditCalibrationLogic/EditForm'

export const EditCalibrationLogicPage = () => {
  const isTestCycleEnabled = useIsTestPerformanceCycleEnabled()

  if (!isTestCycleEnabled) {
    return <GradeLogicForm />
  }

  return <EditForm />
}
