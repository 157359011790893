import React from 'react'
import { SystemHealthInterface, SystemHealthStatus } from '@src/interfaces/health'
import { Status, VStack, Widget, Text, Color } from '@revolut/ui-kit'
import { Live, Limit } from '@revolut/icons'
import { formatNumber } from '@src/utils/format'

interface PlatformStatusWidgetParams {
  title: string
  data?: SystemHealthInterface
}

export const statusToColor = {
  [SystemHealthStatus.ok]: Color.GREEN,
  [SystemHealthStatus.disabled]: Color.GREY_TONE_50,
  [SystemHealthStatus.unhealthy]: Color.ORANGE,
  [SystemHealthStatus.down]: Color.RED,
}

export const PlatformStatusWidget = ({ title, data }: PlatformStatusWidgetParams) => {
  if (!data) {
    return null
  }

  return (
    <Widget p="s-16">
      <VStack space="s-16">
        <Status>{title}</Status>
        <Status useIcon={Live} color={statusToColor[data.status] || 'grey-tone-50'}>
          {data.status}
        </Status>
        {data.response_time ? (
          <Status useIcon={Limit}>
            <Text style={{ wordBreak: 'break-word' }}>
              {formatNumber(Number(data.response_time.slice(0, -1)) * 1000, 5)} ms
            </Text>
          </Status>
        ) : null}
      </VStack>
    </Widget>
  )
}
