import React from 'react'
import BulkActions from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActions'
import { candidateBulkSendOnlineTestRequests } from '@src/api/hiringProcess'
import { CandidateBulkSendOnlineTestInterface } from '@src/interfaces/hiringProccess'

const CandidateBulkSendOnlineTest = () => {
  return (
    <BulkActions<CandidateBulkSendOnlineTestInterface>
      tableRequest={candidateBulkSendOnlineTestRequests}
      columns={[]}
      dataType="Candidate send online test bulk actions"
    />
  )
}

export default CandidateBulkSendOnlineTest
