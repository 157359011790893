import React from 'react'
import SideBar from '@src/components/SideBar/SideBar'
import { FormattedMessage } from 'react-intl'
import Form from '@src/features/Form/Form'
import { interviewAvailabilityPreferencesFormRequests } from '@src/api/interviewAvailability'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { Button, Side, VStack } from '@revolut/ui-kit'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InterviewAvailabilityPreferencesInterface } from '@src/interfaces/interviewAvailability'

type InterviewAvailabilityFormProps = {
  onClose: () => void
  onSuccess: () => void
}

const InterviewAvailabilityForm = ({
  onClose,
  onSuccess,
}: InterviewAvailabilityFormProps) => {
  const { values } = useLapeContext<InterviewAvailabilityPreferencesInterface>()
  return (
    <>
      <VStack gap="s-16">
        <LapeNewInput
          description={
            <FormattedMessage
              id="recruitment.interviewAvailability.availabilityPreferences.dailyLimit.description"
              defaultMessage="Maximum number of interviews per day"
            />
          }
          hideOptional
          label="Daily limit"
          name="max_interviews_per_day"
          type="number"
          onAfterChange={val => {
            if (!val) {
              values.max_interviews_per_day = null
            }
          }}
        />
        <LapeNewInput
          description={
            <FormattedMessage
              id="recruitment.interviewAvailability.availabilityPreferences.weeklyLimit.description"
              defaultMessage="Maximum number of interviews per week"
            />
          }
          hideOptional
          label="Weekly limit"
          name="max_interviews_per_week"
          type="number"
          onAfterChange={val => {
            if (!val) {
              values.max_interviews_per_week = null
            }
          }}
        />
      </VStack>
      <Side.Actions horizontal>
        <Button
          variant="secondary"
          onClick={() => {
            onClose()
          }}
        >
          <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
        </Button>
        <NewSaveButtonWithPopup
          hideWhenNoChanges
          successText="Availability preferences saved successfully"
          onAfterSubmit={() => {
            onSuccess()
            onClose()
          }}
        />
      </Side.Actions>
    </>
  )
}

type InterviewAvailabilityPreferencesSidebarProps = InterviewAvailabilityFormProps & {
  employeeId: string | number
}

export const InterviewAvailabilityPreferencesSidebar = ({
  employeeId,
  onClose,
  onSuccess,
}: InterviewAvailabilityPreferencesSidebarProps) => {
  return (
    <SideBar
      useLayout
      isOpen
      title={
        <FormattedMessage
          id="recruitment.interviewAvailability.availabilityPreferences.title"
          defaultMessage="Availability preferences"
        />
      }
      subtitle={
        <FormattedMessage
          id="recruitment.interviewAvailability.availabilityPreferences.subtitle"
          defaultMessage="You can set up Maximum number of interviews you want to conduct in a day and in a week to manage workload."
        />
      }
      variant="wide"
      onClose={onClose}
    >
      <Form api={interviewAvailabilityPreferencesFormRequests(employeeId)} isExistingForm>
        <InterviewAvailabilityForm onClose={onClose} onSuccess={onSuccess} />
      </Form>
    </SideBar>
  )
}
