import React, { useMemo } from 'react'
import {
  addDays,
  endOfISOWeek,
  getMonth,
  getWeeksInMonth,
  startOfISOWeek,
} from 'date-fns'

import { Flex, Text } from '@revolut/ui-kit'
import { Week, WeekDaysTitles, WeekDivider } from './Week'
import { MONTHS } from './constants'

type Props = {
  idx: number
  firstDay: Date
  renderDay?: (day: Date) => React.ReactNode
}
export const Month = ({ idx, firstDay, renderDay }: Props) => {
  const weeksFirstDays = useMemo(() => {
    return new Array(getWeeksInMonth(firstDay, { weekStartsOn: 1 }))
      .fill(null)
      .reduce<Date[]>((acc, _, weekIdx) => {
        if (weekIdx === 0) {
          return [startOfISOWeek(firstDay)]
        }
        const endOfPrevWeek = endOfISOWeek(acc[weekIdx - 1])

        acc.push(addDays(endOfPrevWeek, 1))

        return acc
      }, [])
  }, [firstDay])

  return (
    <Flex flexDirection="column" gap="s-4" aria-label={MONTHS[idx]}>
      <Flex justifyContent="center">
        <Text fontSize="primary" fontWeight={500} mb="s-12">
          {MONTHS[idx]}
        </Text>
      </Flex>
      <WeekDaysTitles />
      <WeekDivider />
      {weeksFirstDays.map(firstDayOfWeek => (
        <Week
          key={firstDayOfWeek.toString()}
          firstDay={firstDayOfWeek}
          isDayOfCurrentMonth={(day: Date) => getMonth(day) === idx}
          renderDay={renderDay}
        />
      ))}
    </Flex>
  )
}
