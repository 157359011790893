import React from 'react'
import { employeeOnboardingSettings } from '@src/api/settings'
import { ROUTES } from '@src/constants/routes'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { PermissionTypes } from '@src/store/auth/types'
import { General } from './General'
import { OnboardingTemplates } from './OnboardingTemplates'
import { EmailTemplates } from './EmailTemplates'

const routes = [
  {
    url: ROUTES.SETTINGS.ONBOARDING.GENERAL,
    path: ROUTES.SETTINGS.ONBOARDING.GENERAL,
    component: General,
    title: 'General',
    canView: [PermissionTypes.HRManagerPermissions],
  },
  {
    url: ROUTES.SETTINGS.ONBOARDING.ONBOARDING_TEMPLATES,
    path: ROUTES.SETTINGS.ONBOARDING.ONBOARDING_TEMPLATES,
    component: OnboardingTemplates,
    title: 'Onboarding Templates',
    canView: [PermissionTypes.HRManagerPermissions],
  },
  {
    url: ROUTES.SETTINGS.ONBOARDING.EMAILS_TEMPLATES,
    path: ROUTES.SETTINGS.ONBOARDING.EMAILS_TEMPLATES,
    component: EmailTemplates,
    title: 'Email Templates',
    canView: [PermissionTypes.HRManagerPermissions],
  },
]

export const OnboardingSettings = () => {
  return (
    <SettingsForm
      api={employeeOnboardingSettings}
      routes={routes}
      title="Onboarding app settings"
      subtitle="Settings for onboarding new employees"
    />
  )
}
