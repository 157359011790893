import { useEffect, useRef, useState } from 'react'
import { useIntersectViewport } from '@revolut/ui-kit'
import * as Sentry from '@sentry/react'

export function useViewportVideoRef(active = true) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  const [inViewport, setInViewport] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  useIntersectViewport(videoRef, isIntersecting => setInViewport(isIntersecting), 0.1)

  useEffect(() => {
    const video = videoRef.current
    if (!video) {
      return
    }

    if (inViewport && active) {
      if (!isPlaying) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current)
        }
        video
          .play()
          .then(() => setIsPlaying(true))
          .catch(error => {
            Sentry.captureException(error, {
              tags: { component: 'useViewportVideoRef' },
            })
          })
      }
    } else if (isPlaying) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
      timeoutRef.current = setTimeout(() => {
        video.pause()
        video.currentTime = 0
        setIsPlaying(false)
      }, 500) // need to play a video a little bit longer to prevent a disappearing video from being paused
    }
  }, [active, inViewport, isPlaying])

  return { videoRef, inViewport }
}
