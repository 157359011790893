import React, { useMemo } from 'react'

import { InputGroup } from '@revolut/ui-kit'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FaqTopicInterface } from '@src/interfaces/faq'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { PageActions } from '@components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { useFaqCategories } from '@src/api/faq'

export const HelpCenterTopicForm = () => {
  const { values } = useLapeContext<FaqTopicInterface>()
  const { data: categoriesOptionsData, status: categoriesOptionsDataStatus } =
    useFaqCategories()

  const categoriesOptions = useMemo(
    () =>
      categoriesOptionsData?.results.map(category => ({
        label: category.name,
        value: {
          id: category.id,
          name: category.name,
        },
      })),
    [categoriesOptionsDataStatus],
  )

  return (
    <>
      <PageHeader
        backUrl={
          values.id
            ? pathToUrl(ROUTES.APPS.HELP_CENTER.TOPIC.PREVIEW, { id: String(values.id) })
            : pathToUrl(ROUTES.APPS.HELP_CENTER.FAQ_TOPICS_TABLE)
        }
        title={values.id ? 'Edit topic' : 'Add topic'}
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="General" />
          <InputGroup>
            <LapeNewInput required name="title" label="Topic name" />
            <LapeRadioSelectInput
              name="category"
              options={categoriesOptions}
              label="Topic category"
            />
            <LapeNewInput required name="customer_portal" label="Customer portal" />
            <LapeNewInput required name="link_to_support" label="Link to support" />
            <LapeRadioSelectInput
              name="maintainer"
              selector={selectorKeys.employee}
              label="Maintainer"
            />
            <LapeNewTextArea rows={3} name="description" label="Description" />
          </InputGroup>
        </AutoStepper>
        <PageActions>
          <NewSaveButtonWithPopup
            useValidator
            previewUrl={ROUTES.APPS.HELP_CENTER.TOPIC.PREVIEW}
          />
        </PageActions>
      </PageBody>
    </>
  )
}
