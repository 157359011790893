import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { ValidationError } from 'yup'
import { ConnectionInterface } from '@src/interfaces/dataAnalytics'
import { CheckSuccess, CrossCircle } from '@revolut/icons'

export const checkIsDirty = (
  initialValues: Partial<ConnectionInterface>,
  values: ConnectionInterface,
  randomPassword: string,
) => {
  const checkExtraField = () => {
    if (initialValues.extra) {
      return JSON.stringify(initialValues.extra) !== values.extra
    }
    return false
  }

  const oldValues = omit(initialValues, [
    'name',
    'description',
    'owner',
    'password',
    'extra',
  ])
  const newValues = omit(values, ['name', 'description', 'owner', 'password', 'extra'])

  return (
    !isEqual(newValues, oldValues) ||
    values.password !== randomPassword ||
    checkExtraField()
  )
}

export const getStatusIcon = (isTested: boolean, error: string) => {
  if (isTested && !error) {
    return CheckSuccess
  }

  if (error) {
    return CrossCircle
  }

  return null
}

export const getStatusText = (isTested: boolean, error: string, id?: number) => {
  if (isTested && !error) {
    return 'Connection established successfully'
  }

  if (error) {
    return 'Connection test failed, the connection cannot be saved until it succeeds'
  }

  if (!isTested && !id) {
    return 'Connection not tested, please test to confirm it works to save'
  }

  return ''
}

export const safeJsonParse = (str: string) => {
  try {
    return [null, JSON.parse(str.replace(/'/g, '"'))]
  } catch (err) {
    return [err]
  }
}

export function extraFieldValidation(this: {
  path: string
  originalValue: string
  createError(param: { path: string; message: string }): ValidationError
}) {
  if (!this.originalValue) {
    return true
  }

  const [err] = safeJsonParse(this.originalValue)
  if (err) {
    return this.createError({
      path: this.path,
      message: err.message,
    })
  }

  return true
}
