import { ImageProps } from '@revolut/ui-kit'

export enum PopupState {
  Default = 'Default',
  Valid = 'Valid',
  Invalid = 'Invalid',
}

interface Props extends Pick<ImageProps, 'image'> {
  title: string
  description: string
  confirmLabel: string
  cancelLabel: string
}

export const popupStateToPropsMap: Record<PopupState, Props> = {
  [PopupState.Default]: {
    title: 'Review eligible employees',
    description: 'Scorecards for these employees will be validated',
    confirmLabel: 'Validate scorecards',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D262.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D262@3x.png',
    },
  },
  [PopupState.Valid]: {
    title: 'Scorecards are valid',
    description: 'You are able to create scorecards for the following employees',
    confirmLabel: 'Generate scorecards',
    cancelLabel: "Don't send",
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D151A.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D151A@3x.png',
    },
  },
  [PopupState.Invalid]: {
    title: 'We found some issues',
    description: 'We cannot create scorecards for some of the roles',
    confirmLabel: 'Validate again',
    cancelLabel: 'Cancel',
    image: {
      default: `https://assets.revolut.com/assets/3d-images-v2/3D354.png`,
      '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@2x.png',
      '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D354@3x.png',
    },
  },
}
