import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { probationCycleSettingsRequests } from '@src/api/probationEmployees'
import { useParams } from 'react-router'
import { Settings } from '@src/pages/Forms/Overview/Settings/General'
import { ReviewCycleCategory } from '@src/interfaces/reviewCycles'
import { getReviewCycleIdWithoutPrefix } from '@src/utils/reviewCycles'

const ProbationSettings = () => {
  const params = useParams<{ cycleId?: string }>()
  const idValue = params?.cycleId
    ? getReviewCycleIdWithoutPrefix(params.cycleId)
    : undefined

  return (
    <Form
      api={probationCycleSettingsRequests}
      forceParams={{ id: idValue }}
      disableDataCleanup
    >
      <Settings type={ReviewCycleCategory.Probation} />
    </Form>
  )
}

export default connect(ProbationSettings)
