import React, { useEffect, useMemo, useRef } from 'react'
import { CycleSummaryData } from '@src/pages/Forms/DepartmentForm/Performance/interfaces'
import { Avatar, Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { Stats } from '@src/interfaces/data'
import { useTable } from '@components/Table/hooks'
import { QuarterGoalsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterGoalsTable'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import { QuarterRoadmapsTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterRoadmapTable'
import { KarmaInterface, KarmaStatsInterface } from '@src/interfaces/karma'
import { QuarterKarmaTable } from '@src/pages/Forms/DepartmentForm/Performance/Summary/QuarterKarmaTable'
import { gradeSettings } from '@src/pages/Forms/DepartmentForm/Performance/utils'
import { CollapsableTableWrapper } from '@src/pages/Forms/DepartmentForm/Performance/Summary/CollapsableTableWrapper'

const getFilter = (columnName: string, id: number, name: string) => {
  return {
    columnName,
    filters: [{ id, name }],
    nonResettable: true,
    disableQueryParam: true,
  }
}

const QuarterSummary = ({
  goalTableRequests,
  goalsSettings,
  roadmapsTableRequests,
  roadmapsSettings,
  karmaTableRequests,
  karmaSettings,
  selectedCycle,
  cycleStats,
}: CycleSummaryData) => {
  const mounted = useRef(false)

  const goalsFilters = useMemo(
    () => [
      ...goalsSettings.initialFilters,
      getFilter('cycle__id', selectedCycle.review_cycle_ids[0], selectedCycle?.name),
    ],
    [selectedCycle],
  )
  const roadmapsFilters = useMemo(
    () => [
      ...roadmapsSettings.initialFilters,
      getFilter(
        'review_cycle__offset',
        selectedCycle.review_cycle_offsets[0],
        selectedCycle?.name,
      ),
    ],
    [selectedCycle],
  )
  const karmaFilters = useMemo(
    () => [
      ...karmaSettings.initialFilters,
      getFilter(
        'review_cycle__offset',
        selectedCycle.karma_cycle_ids[0],
        selectedCycle?.name,
      ),
    ],
    [selectedCycle],
  )

  const goalsTable = useTable(
    goalTableRequests,
    goalsFilters,
    goalsSettings.initialSort,
    {
      parentIdFilterKey: 'parent_id',
      childrenFetchOmitFilterKeys: ['object_id'],
    },
  )
  const roadmapsTable = useTable<RoadmapInterface, Stats>(
    roadmapsTableRequests,
    roadmapsFilters,
    roadmapsSettings.initialSort,
  )
  const karmaTable = useTable<KarmaInterface, KarmaStatsInterface>(
    karmaTableRequests,
    karmaFilters,
    karmaSettings.initialSort,
  )

  useEffect(() => {
    // avoid multiple requests on initial load
    if (!mounted.current) {
      mounted.current = true
      return
    }

    goalsTable.onFilterChange(goalsFilters)
    roadmapsTable.onFilterChange(roadmapsFilters)
    karmaTable.onFilterChange(karmaFilters)
  }, [selectedCycle])

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center" gap="s-16">
          <Avatar useIcon="BarChart" />
          <Text variant="emphasis1">Department Performance Summary</Text>
        </Flex>
      </Flex>
      <VStack space="s-16">
        <CollapsableTableWrapper
          headerTitle="Goals"
          icon="Target"
          headerStat={cycleStats?.[0].goal_progress.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].goal_progress.grade].color
              : Token.color.foreground
          }
        >
          <QuarterGoalsTable table={goalsTable} />
        </CollapsableTableWrapper>
        <CollapsableTableWrapper
          headerTitle="Roadmap"
          icon="Map"
          headerStat={cycleStats?.[0].roadmap_progress.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].roadmap_progress.grade].color
              : Token.color.foreground
          }
        >
          <QuarterRoadmapsTable table={roadmapsTable} />
        </CollapsableTableWrapper>
        <CollapsableTableWrapper
          headerTitle="Karma"
          icon="ArrowsSort"
          headerStat={cycleStats?.[0].karma_percentage.percent}
          headerStatColor={
            cycleStats
              ? gradeSettings[cycleStats[0].karma_percentage.grade].color
              : Token.color.foreground
          }
        >
          <QuarterKarmaTable table={karmaTable} />
        </CollapsableTableWrapper>
      </VStack>
    </>
  )
}

export default QuarterSummary
