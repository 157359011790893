import React, { PropsWithChildren, useContext } from 'react'
import {
  Flex,
  Text,
  Token,
  IconName,
  Avatar,
  Ellipsis,
  AvatarProps,
} from '@revolut/ui-kit'
import { TableDensityContextValue, TableDensityContext } from '@components/TableV2/Table'

interface ItemCellProps {
  useIcon?: IconName
  iconProps?: Omit<AvatarProps, 'useIcon'>
}

const ItemCellBase = ({
  useIcon,
  iconProps,
  children,
}: PropsWithChildren<ItemCellProps>) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  return (
    <Flex gap="s-16" alignItems="center">
      {useIcon || iconProps ? (
        <Avatar
          useIcon={useIcon}
          size={density !== 'large' ? 24 : undefined}
          {...(iconProps || {})}
        />
      ) : null}
      <Ellipsis>{children}</Ellipsis>
    </Flex>
  )
}

const ItemCellTitle = ({ children }: PropsWithChildren<{}>) => {
  const { density } = useContext<TableDensityContextValue>(TableDensityContext)

  return (
    <Text variant={!density || density === 'large' ? 'primary' : 'h6'}>
      <Ellipsis>{children}</Ellipsis>
    </Text>
  )
}

const ItemCellValue = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Text variant="caption" color={Token.color.greyTone50}>
      <Ellipsis>{children}</Ellipsis>
    </Text>
  )
}

export const ItemCell = Object.assign(ItemCellBase, {
  Title: ItemCellTitle,
  Value: ItemCellValue,
})
