import React from 'react'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { ROUTES } from '@src/constants/routes'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { IndeedPosting, JobPostingInterface } from '@src/interfaces/jobPosting'
import { pathToUrl } from '@src/utils/router'
import { EntityAvatar } from '@src/features/EntityAvatar/EntityAvatar'
import { FUNCTION_DEFAULT_ICON } from '@src/constants/common'
import { HStack, Text } from '@revolut/ui-kit'
import { RowInterface } from '@src/interfaces/data'
import {
  indeedPostingLocation,
  indeedPostingStatus,
} from '@src/constants/columns/indeedPostings'
import { useTable } from '@src/components/Table/hooks'
import { indeedPostingsRequests } from '@src/api/jobPosting'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import Table from '@src/components/TableV2/Table'
import { TableNames } from '@src/constants/table'

const row: RowInterface<IndeedPosting> = {
  cells: [
    { ...indeedPostingLocation, width: 100 },
    { ...indeedPostingStatus, width: 100 },
  ],
}

export const IndeedTable = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const table = useTable(indeedPostingsRequests, [
    {
      filters: [
        {
          name: String(values.id),
          id: String(values.id),
        },
      ],
      columnName: 'job_posting',
      nonResettable: true,
    },
  ])
  return (
    <PageWrapper>
      <PageHeader
        title="Indeed postings"
        subtitle={
          <HStack align="center" gap="s-8">
            {values?.specialisation && (
              <EntityAvatar
                data={{
                  id: +values.specialisation.id,
                  icon: values.specialisation.icon,
                  avatar: values.specialisation.avatar,
                }}
                defaultIcon={FUNCTION_DEFAULT_ICON}
                canEdit={false}
                size={24}
              />
            )}
            <Text variant="emphasis1">{values.name}</Text>
          </HStack>
        }
        backUrl={pathToUrl(ROUTES.FORMS.JOB_POSTING.PREVIEW, {
          specId: values.specialisation?.id,
          id: values.id,
        })}
      />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Table>
            <AdjustableTable<IndeedPosting>
              name={TableNames.IndeedPostings}
              useWindowScroll
              dataType="Indeed postings"
              row={row}
              {...table}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
