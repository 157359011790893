import * as React from 'react'
import { useLapeField } from '@src/features/Form/LapeForm'
import { connect } from 'lape'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import EmployeeSelector from '@components/Inputs/EmployeeSelector/EmployeeSelector'
import { EmployeeOptionInterface } from '@src/interfaces/employees'
import { useFormValidator } from '@src/features/Form/FormValidator'
import toString from 'lodash/toString'

interface Props {
  name: string
  value?: string
  disabled?: boolean
  onChange?: (value?: EmployeeOptionInterface[]) => void
  required?: boolean
  label: string
  error?: string
}

const LapeEmployeeSelector = ({ name, label, ...props }: Props) => {
  const lapeProps = useLapeField(name)
  const { value, error: formError, touched, apiError, onChange, hidden } = lapeProps
  const formValidator = useFormValidator()
  const commonInputProps = getCommonInputProps(
    { ...props, name, label },
    lapeProps,
    !!formValidator?.validated,
  )

  if (hidden) {
    return null
  }

  const error = formError || apiError
  const errorMessage = error ? toString(error) : undefined
  const validated = !!formValidator?.validated

  return (
    <EmployeeSelector
      onChange={onChange}
      error={validated && errorMessage ? errorMessage : (touched && error) || props.error}
      value={value || null}
      disabled={commonInputProps.disabled}
      label={label}
      message={errorMessage || props.error}
      inputProps={{
        'data-name': name,
      }}
    />
  )
}

export default connect(LapeEmployeeSelector)
