import React from 'react'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useGetRequisitionsSelector } from '@src/api/requisitions'
import { RequisitionSelectorInterface } from '@src/interfaces/requisitions'

type ConfidentialRequisitionSelectProps = {
  requisition?: RequisitionSelectorInterface
  onChange: (requisition: RequisitionSelectorInterface | null) => void
}

const ConfidentialRequisitionSelect = ({
  requisition,
  onChange,
}: ConfidentialRequisitionSelectProps) => {
  const {
    data: confidentialRequisitionData,
    isLoading: loadingConfidentialRequisitions,
  } = useGetRequisitionsSelector()
  const confidentialRequisitionsOptions =
    confidentialRequisitionData?.map(item => {
      const label = `${item.requisition_title} #${item.id}`
      return {
        label,
        name: label,
        value: {
          ...item,
        },
      }
    }) ?? []
  return (
    <LapeRadioSelectInput
      name="requisition"
      label="Requisition"
      optional
      options={confidentialRequisitionsOptions}
      labelPath="requisition_title"
      disabled={loadingConfidentialRequisitions}
      loading={loadingConfidentialRequisitions}
      referenceUrl={
        requisition?.id
          ? pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
              id: requisition.id,
            })
          : undefined
      }
      onAfterChange={onChange}
    />
  )
}

export default ConfidentialRequisitionSelect
