import React, { ReactNode, useState } from 'react'
import { Item, Token, Avatar, ActionButton, Popup } from '@revolut/ui-kit'
import { ImageProp } from '@revolut/ui-kit/types/dist/types'

interface Props {
  learnMoreComponent: ReactNode
  title: ReactNode
  description: ReactNode
  image: ImageProp
}

export const OnboardingLearnMore = ({
  learnMoreComponent,
  title,
  description,
  image,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <Item>
        <Item.Avatar
          size={76}
          backgroundColor={Token.color.white_20}
          borderRadius={Token.radius.r8}
        >
          <Avatar variant="square" size={76} image={image} />
        </Item.Avatar>
        <Item.Content use="div">
          <Item.Title>{title}</Item.Title>
          <Item.Description style={{ marginBottom: 'unset' }}>
            {description}
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton variant="primary" onClick={() => setIsOpen(true)}>
            Learn more
          </ActionButton>
        </Item.Side>
      </Item>
      <Popup open={isOpen} onClose={() => setIsOpen(false)} size="lg">
        {learnMoreComponent}
      </Popup>
    </>
  )
}
