import React from 'react'
import ChatSidebar from '@src/components/Chat/ChatSidebar'
import { ChatMessageType } from '@src/components/Chat/common'
import { getResignationCommentsAPI } from '@src/api/employeeResignation'

type Props = {
  employeeId: string
  resignationId: string
  isCommentsShown: boolean
  setIsCommentsShown: (isShown: boolean) => void
}
export const CommentsSidebar = ({
  employeeId,
  resignationId,
  isCommentsShown,
  setIsCommentsShown,
}: Props) => {
  const commentsApi = getResignationCommentsAPI(employeeId, resignationId)
  const getComments = commentsApi.useGetComments()

  return (
    <ChatSidebar
      type={ChatMessageType.Comment}
      isOpen={isCommentsShown}
      onClose={() => setIsCommentsShown(false)}
      data={getComments.data?.results || []}
      isLoading={getComments.isLoading}
      refetch={getComments.refetch}
      onAddMessage={commentsApi.addComment}
      onEdit={commentsApi.editComment}
      onArchive={commentsApi.archiveComment}
      onResolve={commentsApi.resolveComment}
      disableTodolistFeature
    />
  )
}
