import { usePublicGlobalSettings } from '@src/api/settings'

const useIsCommercial = () => {
  const {
    settings: { commercial_product_disabled },
  } = usePublicGlobalSettings()

  return commercial_product_disabled === undefined
    ? undefined
    : !commercial_product_disabled
}

export default useIsCommercial
