import React from 'react'
import SideBar from '@components/SideBar/SideBar'
import { emailTemplatesRequests } from '@src/api/emailTemplates'
import Form from '@src/features/Form/Form'
import EmailTemplatePreviewContent from '@src/pages/Forms/EmailTemplate/EmailTemplatePreviewContent'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmailTemplateInterface } from '@src/interfaces/emailTemplates'
import { Portal } from '@revolut/ui-kit'

type Props = {
  onClose: () => void
}

const EmailTemplatePreview = ({ onClose }: Props) => {
  const { values } = useLapeContext<EmailTemplateInterface>()
  return (
    <SideBar isOpen onClose={onClose} title={`${values.name} preview`} variant="wide">
      <EmailTemplatePreviewContent />
    </SideBar>
  )
}

export default ({ id, ...props }: Props & { id: number | string }) => (
  <Portal>
    <Form api={emailTemplatesRequests} forceParams={{ id: String(id) }}>
      <EmailTemplatePreview {...props} />
    </Form>
  </Portal>
)
