import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { WorkScheduleList } from './List'
import { EditWorkSchedule } from './Edit'
import { PreviewWorkSchedule } from './Preview'

export const WorkSchedule = () => {
  return (
    <Switch>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.LIST}>
        <WorkScheduleList />
      </Route>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT.ANY}>
        <EditWorkSchedule />
      </Route>
      <Route path={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.PREVIEW}>
        <PreviewWorkSchedule />
      </Route>
    </Switch>
  )
}
