import React from 'react'
import isNumber from 'lodash/isNumber'
import toNumber from 'lodash/toNumber'
import BudgetChartInner from '@components/Charts/BudgetChart/BudgetChartInner'
import { Widget, Color, Flex, Skeleton, Text, Token } from '@revolut/ui-kit'
import {
  useGetDepartmentStaffCost,
  getDepartmentStaffCostGraph,
} from '@src/api/staffCost'
import Stat from '@components/Stat/Stat'
import { StaffCostInterface } from '@src/interfaces/staffCost'
import { formatMoney } from '@src/utils/format'
import { useParams } from 'react-router-dom'

const STAFF_COST_CURRENCY = 'GBP'

const getBonusImpactColor = (value?: number) => {
  if (!value) {
    return Color.GREY_TONE_20
  }
  if (value < 0) {
    return Color.RED
  }
  return Color.GREEN
}

const getExpensesColor = (stats?: StaffCostInterface) => {
  if (
    stats === undefined ||
    !isNumber(stats?.current_expenses) ||
    !isNumber(stats?.budget)
  ) {
    return Token.color.greyTone20
  }
  if (stats.current_expenses === 0) {
    return Color.GREY_TONE_20
  }

  const budgetLeft = stats.budget - stats.current_expenses

  if (budgetLeft > 0 && budgetLeft < stats.budget / 20) {
    return Color.YELLOW
  }

  if (budgetLeft < 0) {
    return Color.RED
  }
  return Color.GREEN
}

export const SpentQuickSummary = ({
  staffCost,
  isLoading = true,
}: {
  staffCost?: StaffCostInterface
  isLoading?: boolean
}) => {
  if (isLoading) {
    return <Skeleton height={16} width={30} />
  }
  if (!staffCost) {
    return null
  }

  return (
    <Text color={getExpensesColor(staffCost)}>
      {formatMoney(staffCost.current_expenses)}
    </Text>
  )
}

const Spent = () => {
  const params = useParams<{ id: string }>()
  const { data: stats } = useGetDepartmentStaffCost(params.id)

  return (
    <Widget p="s-24" flex="1" display="flex">
      <Flex flexDirection="column" flex="1">
        <Flex mb="s-24">
          <Stat
            label="Bonus impact"
            val={stats && `${toNumber(stats.bonus_impact)}%`}
            mr="s-32"
            color={getBonusImpactColor(stats?.bonus_impact)}
          />
          <Stat
            label="Current expenses"
            val={stats && formatMoney(stats.current_expenses)}
            mr="s-32"
            color={getExpensesColor(stats)}
          />
          <Stat
            label="Budget"
            val={stats && formatMoney(stats?.budget)}
            color="foreground"
          />
        </Flex>

        <Flex flexDirection="column" flex="1">
          <BudgetChartInner
            fetchData={getDepartmentStaffCostGraph}
            id={Number(params.id)}
            currency={STAFF_COST_CURRENCY}
            targetLayerTitle="Budget"
            targetLayerColor={Color.RED_30}
          />
        </Flex>
      </Flex>
    </Widget>
  )
}

export default Spent
