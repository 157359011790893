import { Relative } from '@revolut/ui-kit'
import React, { type VideoHTMLAttributes, useEffect } from 'react'
import styled from 'styled-components'

import { useViewportVideoRef } from '@src/pages/Landing/hooks/useViewportVideoRef'

const Video = styled.video({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  // removes https://stackoverflow.com/questions/52508598/html5-video-element-on-iphone-has-border
  backfaceVisibility: 'hidden',
  webkitMaskImage: '-webkit-radial-gradient(#fff, #000)',
})

interface FeatureBlockVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  video: string
  active?: boolean
}

export const FeatureBlockVideo = ({
  video,
  active = true,
  ...rest
}: FeatureBlockVideoProps) => {
  const { videoRef } = useViewportVideoRef(active)

  useEffect(() => {
    videoRef.current?.load()
  }, [videoRef, video])

  return (
    <Relative width="100%" height="100%">
      <Video ref={videoRef} muted playsInline {...rest}>
        <source src={video} type="video/mp4" />
      </Video>
    </Relative>
  )
}
