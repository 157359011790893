import { useGetTickets } from '@src/api/tickets'
import { VStack, Text, Item, Group } from '@revolut/ui-kit'
import React from 'react'
import { BugReportTicket } from '@components/HelpCenter/components/BugReportTicket'
import { TicketStatus, ticketStatuses } from '@src/interfaces/tickets'
import bugReportSideState from '@components/HelpCenter/components/BugReportSideState'
import { useOpenHelpCenter } from '@components/HelpCenter/hooks'

export const BugReport = () => {
  const { closeHelpCenter } = useOpenHelpCenter()
  const archivedStatus: TicketStatus = 'closed'
  const activeStatuses = ticketStatuses
    .filter(status => status !== archivedStatus)
    .map(status => ({ id: status, name: status }))

  const { data: tickets, isLoading } = useGetTickets([
    {
      filters: activeStatuses,
      columnName: 'current_status_name',
    },
  ])

  return (
    <>
      {!isLoading && tickets && tickets?.count > 0 ? (
        <VStack space="s-8">
          <Text variant="primary" use="div" pb="s-8">
            Active issues
          </Text>
          <Group>
            {tickets.results.map(ticket => (
              <BugReportTicket ticket={ticket} key={ticket.id} />
            ))}
          </Group>
        </VStack>
      ) : null}

      <VStack space="s-8">
        <Text variant="primary" use="div" pb="s-8">
          Get help
        </Text>
        <Item
          use="button"
          onClick={() => {
            bugReportSideState.open = true
            closeHelpCenter()
          }}
          useIcon="Bug"
        >
          <Item.Content>
            <Item.Title>Report a bug</Item.Title>
          </Item.Content>
        </Item>
      </VStack>
    </>
  )
}
