import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Action, Flex, ProgressStep, ProgressSteps } from '@revolut/ui-kit'
import { getPerformanceTimelinEevents } from '@src/api/performance'
import {
  PerformanceTimelineEventsInterface,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { selectUser } from '@src/store/auth/selectors'
import { getStepDescription, getStepState, getStepTitle } from './utils'
import useFetchOptions from '@components/Inputs/hooks/useFetchOptions'
import { OptionInterface } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import { EmployeeInterface } from '@src/interfaces/employees'
import { ViewNominationButton } from './components/ViewNominationButton'
import { NominateForPromotion } from '../../components/Buttons/Actions/NominateForPromotion'

export interface SortedTimelineEventsInterface extends SingleTimelineEventInterface {
  type: string
  meetingType?: string
}

interface Props {
  employeeId: number
  data: EmployeeInterface
  onViewNotesClick?: () => void
}

export const PerformanceTimeline = ({ employeeId, data, onViewNotesClick }: Props) => {
  const user = useSelector(selectUser)
  const [loading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [timelineEvents, setTimelineEvents] = useState<
    PerformanceTimelineEventsInterface | undefined
  >()
  const { options } = useFetchOptions<OptionInterface>(selectorKeys.cycle_offsets)
  const currentCycle = options?.find(option => option.value.id === 0)

  const sortedTimelineEvents = useMemo(() => {
    const result: SortedTimelineEventsInterface[] = []

    for (let key in timelineEvents) {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      if (timelineEvents[key]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        timelineEvents[key].forEach((item: SingleTimelineEventInterface) =>
          result.push({ ...item, type: key, meetingType: item.type }),
        )
      }
    }

    return result.sort(
      (a, b) => new Date(a.start_period).getTime() - new Date(b.start_period).getTime(),
    )
  }, [timelineEvents])

  const currentTimelineEvents = sortedTimelineEvents.filter(
    event =>
      getStepState(event.start_period, event.end_period, event.meetingType) === 'pending',
  )

  const displayTimelineEvents = expanded ? sortedTimelineEvents : currentTimelineEvents
  const showAction = sortedTimelineEvents.length > currentTimelineEvents.length

  const fetchSummary = async () => {
    setLoading(true)
    const result = await getPerformanceTimelinEevents(employeeId)

    if (result.data) {
      setTimelineEvents(result.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchSummary()
  }, [employeeId])

  if (!timelineEvents && loading) {
    return null
  }

  const renderPromotionNominationButton = (event: SortedTimelineEventsInterface) => {
    if (event.type !== 'promotion_nominations') {
      return null
    }
    return (
      <ProgressStep.Side css={{ opacity: 1 }}>
        <NominateForPromotion data={data} />
        <ViewNominationButton data={data} />
      </ProgressStep.Side>
    )
  }

  return (
    <>
      <ProgressSteps variant="vertical-compact">
        {displayTimelineEvents.length > 0 &&
          displayTimelineEvents.map((event, i) => {
            const title = getStepTitle(event, data, currentCycle?.value.name)
            const state = getStepState(
              event.start_period,
              event.end_period,
              event.meetingType,
            )
            const description = getStepDescription(
              employeeId,
              event,
              user.id,
              onViewNotesClick,
            )

            return (
              <ProgressStep data-testid={`${title}--${state}`} key={i} state={state}>
                <ProgressStep.Title>{title}</ProgressStep.Title>
                <ProgressStep.Description>{description}</ProgressStep.Description>
                {renderPromotionNominationButton(event)}
              </ProgressStep>
            )
          })}
        {sortedTimelineEvents.length && showAction ? (
          <Flex justifyContent="center" mt="s-8">
            <Action onClick={() => setExpanded(!expanded)}>
              {expanded ? 'Hide' : 'Show'} company performance timeline
            </Action>
          </Flex>
        ) : null}
      </ProgressSteps>
    </>
  )
}
