import React, { useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import ExistingPlaceholdersTable from '@src/pages/Forms/OfferTemplate/ExistingPlaceholdersTable'
import {
  DetectPlaceholdersInterface,
  OfferTemplateInterface,
} from '@src/interfaces/offerTemplates'
import { detectPlaceholdersFromFile, offerTemplateRequest } from '@src/api/offerTemplates'
import SideBar from '@components/SideBar/SideBar'
import { MoreBar, Header, VStack, Skeleton } from '@revolut/ui-kit'
import { Download, Pencil } from '@revolut/icons'
import { downloadFile } from '@src/api/files'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { saveFile } from '@src/utils'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  templateId?: number
  onClose: () => void
}

const OfferPreviewSidebar = ({ templateId, onClose }: Props) => {
  const [loading, setLoading] = useState(false)
  const [template, setTemplate] = useState<OfferTemplateInterface>()
  const [placeholdersLoading, setPlaceholdersLoading] = useState(false)
  const [detectedPlaceholders, setDetectedPlaceholders] =
    useState<DetectPlaceholdersInterface>()

  const detectPlaceholders = async (id: number) => {
    setPlaceholdersLoading(true)

    try {
      const resp = await detectPlaceholdersFromFile(id)
      setDetectedPlaceholders(resp.data)
    } finally {
      setPlaceholdersLoading(false)
    }
  }

  const fetchTemplate = async (id: number) => {
    setLoading(true)

    try {
      const resp = await offerTemplateRequest.get({ id: String(id) })
      setTemplate(resp.data)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (templateId) {
      fetchTemplate(templateId)
    }
  }, [templateId])

  useEffect(() => {
    if (template?.template_file?.id) {
      detectPlaceholders(template.template_file.id)
    }
  }, [template?.template_file])

  const renderContent = () => {
    if (placeholdersLoading) {
      return (
        <VStack gap="s-16">
          <Skeleton width={210} />
          <Skeleton height={180} borderRadius={8} />
          <Skeleton width={210} />
          <Skeleton height={180} borderRadius={8} />
        </VStack>
      )
    }

    if (isEmpty(detectedPlaceholders?.known_placeholders)) {
      return null
    }

    return (
      <>
        <ExistingPlaceholdersTable
          data={detectedPlaceholders!.known_placeholders}
          isCustom
        />
        <ExistingPlaceholdersTable data={detectedPlaceholders!.known_placeholders} />
      </>
    )
  }

  return (
    <SideBar
      title={template?.name}
      isOpen={!!templateId}
      onClose={onClose}
      loading={loading}
      subtitle={`Uploaded by ${template?.owner?.name}`}
      variant="wide"
      headerContent={
        <Header.Bar>
          <MoreBar>
            <MoreBar.Action
              useIcon={Pencil}
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.OFFER_TEMPLATE.GENERAL, {
                id: template?.id,
              })}
            >
              Edit
            </MoreBar.Action>
            {template?.template_file?.id && (
              <MoreBar.Action
                useIcon={Download}
                onClick={() =>
                  downloadFile(template.template_file!.id).then(details => {
                    saveFile(details, template.template_file!.name)
                  })
                }
              >
                Download
              </MoreBar.Action>
            )}
          </MoreBar>
        </Header.Bar>
      }
      data-testid="offer-template-preview-sidebar"
    >
      {renderContent()}
    </SideBar>
  )
}

export default OfferPreviewSidebar
