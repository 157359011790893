import React from 'react'
import { Box, Text, Token } from '@revolut/ui-kit'

interface CareersApplicationWidgetQuestionTitleProps {
  title: string
  subtitle?: string
  optional: boolean
}

const CareersApplicationQuestionTitle = ({
  title,
  subtitle,
  optional,
}: CareersApplicationWidgetQuestionTitleProps) => {
  return (
    <Box>
      <Text use="div" fontWeight={500} pt="s-16">
        {title}
        {optional ? ` (optional)` : ''}
      </Text>
      {subtitle && (
        <Text use="div" pt="s-8" variant="secondary" color={Token.color.greyTone50}>
          {subtitle}
        </Text>
      )}
    </Box>
  )
}

export default CareersApplicationQuestionTitle
