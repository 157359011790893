import {
  Attendee,
  OneToOneEmployeeRelation,
  OneToOneMeeting,
} from '@src/interfaces/meetings'
import { EmployeeInterface } from '@src/interfaces/employees'

interface OneToOneModel {
  getDependantAttendee: (
    employee: EmployeeInterface,
    meeting: OneToOneMeeting,
  ) => Attendee
  isScheduled: (meeting: OneToOneMeeting) => boolean
  isMeetingWithReport: (meeting: OneToOneMeeting) => boolean
  isMeetingManagedBy: (employee: EmployeeInterface, meeting: OneToOneMeeting) => boolean
  isMeetingParticipant: (employee: EmployeeInterface, meeting: OneToOneMeeting) => boolean
}

const getDependantAttendee = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): Attendee => {
  if (employee.id === meeting.employee.id) {
    return meeting.manager
  }

  return meeting.employee
}

const isMeetingManagedBy = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): boolean => meeting.manager.id === employee.id

const isScheduled = (meeting: OneToOneMeeting): boolean => meeting.meeting_series !== null

const isMeetingWithReport = (meeting: OneToOneMeeting): boolean =>
  meeting.employee_relation_type === OneToOneEmployeeRelation.Report

const isMeetingParticipant = (
  employee: EmployeeInterface,
  meeting: OneToOneMeeting,
): boolean => meeting.manager.id === employee.id || meeting.employee.id === employee.id

const createOneToOneModel = (): OneToOneModel => {
  return {
    getDependantAttendee,
    isScheduled,
    isMeetingWithReport,
    isMeetingManagedBy,
    isMeetingParticipant,
  }
}

export const oneToOneModel = createOneToOneModel()
