import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { AxiosError } from 'axios'
import { getStringMessageFromError } from '@src/store/notifications/actions'

export const useGenerationStatusPopup = () => {
  const showStatusPopup = useShowStatusPopup()

  const showGenerationCompleted = () => {
    showStatusPopup({
      status: 'success',
      title: 'Background jobs were triggered to generate scorecards',
    })
  }

  const showGenerationFailed = (err: AxiosError) => {
    showStatusPopup({
      status: 'error',
      title: getStringMessageFromError(err, 'Background jobs failed to start'),
    })
  }

  return { showGenerationCompleted, showGenerationFailed }
}
