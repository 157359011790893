import React from 'react'
import pluralize from 'pluralize'
import { Text } from '@revolut/ui-kit'

import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { PayCycleReportTableItemInterface } from '@src/interfaces/payrollV2'
import { selectorKeys } from '../api'
import { PayrollStatusTag } from '@src/apps/People/PayrollV2/common'

export const payCycleReportsEmployeeNameColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.name',
    sortKey: null,
    filterKey: 'employee_id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee name',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const payCycleReportsGrossSalaryColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'salary_amount',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Gross salary',
  }

export const payCycleReportsRoleColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.specialisation.id',
    dataPoint: 'employee.specialisation.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Role',
  }

export const payCycleReportsDepartmentColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.text,
    idPoint: 'employee.team.department.id',
    dataPoint: 'employee.team.department.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.department,
    title: 'Department',
  }

export const payCycleReportsChangesColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'id',
    dataPoint: 'total_changes',
    sortKey: null,
    filterKey: 'total_changes',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    title: 'Changes',
    insert: ({ data }) => <Text>{pluralize('change', data.total_changes, true)}</Text>,
  }

export const payCycleReportsStatusColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: null,
    filterKey: 'total_issues',
    filterType: FilterType.range,
    selectorsKey: selectorKeys.none,
    title: 'Status',
    insert: ({ data }) => {
      return (
        <PayrollStatusTag
          issuesLevel={data.issues_level}
          totalIssues={data.total_issues}
        />
      )
    },
  }

export const payCycleReportsActionsColumn: ColumnInterface<PayCycleReportTableItemInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'actions',
    dataPoint: 'actions',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Actions',
  }
