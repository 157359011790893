import React from 'react'
import { HStack, Text } from '@revolut/ui-kit'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { OptionInterface } from '@src/interfaces/selectors'
import { EmployeeOptionInterface } from '@src/interfaces/employees'

type HiringStageRoundInterviewersProps = {
  interviewerType: OptionInterface
  interviewers?: EmployeeOptionInterface[]
  interviewersGroups?: OptionInterface[]
}

const HiringStageInterviewers = ({
  interviewerType,
  interviewers,
  interviewersGroups,
}: HiringStageRoundInterviewersProps) => {
  if (interviewerType?.id === 'employees') {
    return (
      <HStack space="s-4">
        {interviewers?.map(item => (
          <UserWithAvatar key={item.id} {...item} compact />
        ))}
      </HStack>
    )
  }
  if (interviewerType?.id === 'groups') {
    return <Text>{interviewersGroups?.map(({ name }) => name).join(', ')}</Text>
  }
  return null
}

export default HiringStageInterviewers
