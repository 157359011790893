import { Banner } from '@revolut/ui-kit'
import React from 'react'

export const TemplateBanner = () => {
  return (
    <Banner mb="s-16">
      <Banner.Content>
        <Banner.Title>Creating new probation templates</Banner.Title>
        <Banner.Description>
          You can create templates for probation cycles, for use when onboarding new
          employees. By defining the duration of the cycle, and the number of probation
          checkpoints within it, this will generate a timeline which can be previewed
          below. The deadline for goal setting, as well the the final probation decision,
          can be adjusted too.
        </Banner.Description>
      </Banner.Content>
    </Banner>
  )
}
