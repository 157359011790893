import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'

export const promotionTimelineStepsMap = {
  1: '1',
  2: '2a',
  3: '2b',
  4: '2c',
  5: '3',
  6: '4',
  7: '5',
  8: '6',
}

export const useCanNominateAnyTime = () => {
  const permissions = useSelector(selectPermissions)
  return permissions.includes(PermissionTypes.PromotionsAnyTimeNomination)
}
