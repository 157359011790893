import React, { useEffect } from 'react'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotificationTemplateInterface,
  SlackBotInterface,
} from '@src/interfaces/notificationTemplate'
import { connect } from 'lape'
import { useGetSelectors } from '@src/api/selectors'

const DEFAULT_BOT_NAME = 'People Ops Bot'

const BotSelect = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()
  const { data: bots, isFetched } = useGetSelectors<SlackBotInterface>(
    selectorKeys.slack_bots,
  )

  useEffect(() => {
    if (isFetched && !values.slack_bot) {
      values.slack_bot = bots?.find(bot => bot.name === DEFAULT_BOT_NAME)
    }
  }, [isFetched])

  return (
    <LapeRadioSelectInput
      name="slack_bot"
      label="Bot"
      selector={selectorKeys.slack_bots}
      optional
    />
  )
}

export default connect(BotSelect)
