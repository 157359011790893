import { useDelete, useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import { BannerInterface } from '@src/interfaces/banners'
import { RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'

export const useGetBanners = () =>
  useFetch<BannerInterface>(`${API.BANNERS}`, undefined, undefined, undefined, {
    cacheTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 90,
    refetchOnWindowFocus: false,
  })

export const bannersRequests: RequestInterfaceNew<BannerInterface> = {
  get: async () => api.get(API.BANNERS),
  update: async data => apiWithoutHandling.patch(API.BANNERS, data),
  submit: async data => apiWithoutHandling.post(API.BANNERS, data),
}

export const useDeleteBanners = () => useDelete(API.BANNERS)
