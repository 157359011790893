import React from 'react'
import { ActionButton } from '@revolut/ui-kit'
import { Grid } from '@components/CommonSC/Grid'

interface Props {
  onDelete?: VoidFunction
  onEdit?: VoidFunction
  onCopy?: VoidFunction
  isView?: boolean
  disableDelete?: boolean
  disableEdit?: boolean
  disableCopy?: boolean
}

export const ScorecardSectionButtonControls = ({
  onDelete,
  onEdit,
  isView = false,
  onCopy,
  disableDelete = false,
  disableEdit = false,
  disableCopy = false,
}: Props) => {
  return (
    <Grid gap={8} flow="column" pl="s-8" alignItems="center">
      {onCopy && (
        <ActionButton
          useIcon="Copy"
          size="xs"
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onCopy()
          }}
          disabled={disableCopy}
          aria-label="Copy"
        />
      )}
      {isView && (
        <ActionButton
          useIcon="Pencil"
          size="xs"
          onClick={e => {
            if (onEdit) {
              e.preventDefault()
              e.stopPropagation()
              onEdit()
            }
          }}
          disabled={disableEdit}
          aria-label="Edit"
        />
      )}
      <ActionButton
        useIcon="Delete"
        size="xs"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onDelete?.()
        }}
        disabled={disableDelete}
        aria-label="Delete"
      />
    </Grid>
  )
}
