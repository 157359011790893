import React from 'react'
import { CircleButton, Grid } from '@revolut/ui-kit'
import {
  visualisationOptions,
  VisualisationType,
} from '@src/pages/Forms/QueryForm/components/VisualisationSidebar/common'

interface VisualisationSidebarTypeSelectProps {
  selectedType?: VisualisationType
  onChange: (chartType: VisualisationType) => void
}

export const VisualisationSidebarTypeSelect = ({
  selectedType = 'line',
  onChange,
}: VisualisationSidebarTypeSelectProps) => {
  return (
    <Grid gap="s-16" columns={visualisationOptions.length} alignSelf="center" mt="s-16">
      {visualisationOptions.map(option => (
        <CircleButton
          variant={selectedType === option.type ? 'primary' : 'widget-action'}
          useIcon={option.icon}
          key={option.type}
          onClick={() => onChange(option.type)}
        >
          {option.name}
        </CircleButton>
      ))}
    </Grid>
  )
}
