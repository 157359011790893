import React from 'react'
import { Banner, chain, Text } from '@revolut/ui-kit'
import { Statuses } from '@src/interfaces'
import { getPromotionStatusTextAndColor } from '@src/pages/Forms/PromotionNominationForm/common/common'
import { PromotionNominationInterface } from '@src/interfaces/promotions'

const NominationStatusBanner = ({
  nomination,
}: {
  nomination: PromotionNominationInterface
}) => {
  const { status, statusColor } = getPromotionStatusTextAndColor(nomination)
  return (
    <Banner>
      <Banner.Content>
        <Banner.Title fontSize="14px">
          {chain('Approval status', <Text color={statusColor}>{status}</Text>)}
        </Banner.Title>
        {nomination.status === Statuses.in_review && (
          <Banner.Description mt="s-8">
            This nomination is still under review. Where a nomination meets all the
            eligibility criteria, this does not mean it will be automatically accepted.
            Performance Review Committee approval is required in all cases.
          </Banner.Description>
        )}
      </Banner.Content>
    </Banner>
  )
}

export default NominationStatusBanner
