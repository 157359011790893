import React from 'react'
import { useSelector } from 'react-redux'
import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { useTable } from '@src/components/TableV2/hooks'
import { ROUTES } from '@src/constants/routes'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { DataRetentionSettingInterface } from '@src/interfaces/dataRetentionSettings'
import { dataRetentionSettingsRequests } from '@src/api/dataRetentionSettings'
import {
  dataRetentionCountryColumn,
  dataRetentionSuccessfulColumn,
  dataRetentionUnsuccessfulColumn,
} from '@src/constants/columns/dataRetentionSettings'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageBody } from '@src/components/Page/PageBody'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import Table from '@src/components/TableV2/Table'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'

const ROW: RowInterface<DataRetentionSettingInterface> = {
  linkToForm: ({ id }) =>
    navigateTo(pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.PREVIEW, { id })),
  cells: [
    {
      ...dataRetentionCountryColumn,
      width: 200,
    },
    {
      ...dataRetentionUnsuccessfulColumn,
      width: 200,
    },
    {
      ...dataRetentionSuccessfulColumn,
      width: 200,
    },
  ],
}

export const DataRetentionSettingsTable = () => {
  const permissions = useSelector(selectPermissions)
  const canAdd = permissions.includes(PermissionTypes.AddDataRetentionPeriod)

  const table = useTable<DataRetentionSettingInterface>(dataRetentionSettingsRequests)

  return (
    <Table.Widget>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {canAdd && (
            <PrimaryAction
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.DATA_RETENTION_SETTINGS.GENERAL)}
              useIcon="Plus"
            >
              Create new
            </PrimaryAction>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          name={TableNames.DataRetentionSettings}
          useWindowScroll
          row={ROW}
          {...table}
          emptyState={<EmptyTableRaw title="Data retention settings will appear here" />}
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export const DataRetentionSettingsPage = () => {
  return (
    <PageWrapper>
      <PageHeader
        title="Data retention settings"
        backUrl={pathToUrl(ROUTES.SETTINGS.CANDIDATES.LIST)}
      />
      <PageBody maxWidth="100%">
        <DataRetentionSettingsTable />
      </PageBody>
    </PageWrapper>
  )
}
