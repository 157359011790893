import React, { useState } from 'react'
import { Popup, Text } from '@revolut/ui-kit'
import {
  performanceTimelineReviewersColumn,
  performanceSkillsAssessedtRatingColumn,
  performanceSkillsExpectedRatingColumn,
  performanceSkillsNameColumn,
  performanceSkillsNotesColumn,
} from '@src/constants/columns/performance'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  ReviewerRelationToShortTitle,
  ReviewersScoresInterface,
  SkillsReviewsInterface,
} from '@src/interfaces/performance'
import Stat from '@components/Stat/Stat'
import AdjustableTable from '@components/Table/AdjustableTable'
import { TableWrapper } from '@components/Table/TableWrapper'
import { useSkillsTable } from './hooks'
import SummarySidebar from './SummarySidebar'
import { PerformanceRatingTitle } from '@src/constants/performance'
import SkillsNotes from '@src/pages/Forms/EmployeePerformance/SkillsNotes'

interface SkillsProps {
  data: EmployeeInterface
}

export const ROW = (
  onMessageClick: (scores: ReviewersScoresInterface[]) => void,
): RowInterface<SkillsReviewsInterface> => ({
  noChildrenRequest: true,
  cells: [
    {
      ...performanceSkillsNameColumn,
      width: 200,
    },
    {
      ...performanceSkillsAssessedtRatingColumn,
      width: 140,
    },
    {
      ...performanceSkillsExpectedRatingColumn,
      width: 140,
    },
    {
      ...performanceTimelineReviewersColumn,
      width: 140,
    },
    {
      ...performanceSkillsNotesColumn(onMessageClick),
      width: 140,
    },
  ],
})

export const Skills = ({ data }: SkillsProps) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const [popupDetails, setPopupDetails] = useState<ReviewersScoresInterface[]>()
  const [sidebarDetails, setSidebarDetails] = useState<SkillsReviewsInterface>()

  const table = useSkillsTable(data)

  const handleRowClick = (rowData: SkillsReviewsInterface) => {
    if (rowData?.cycle_id) {
      setSidebarDetails(rowData)
      setIsSidebarOpen(true)
    } else if (rowData.children[0]) {
      setSidebarDetails(rowData.children[0] as SkillsReviewsInterface)
      setIsSidebarOpen(true)
    }
  }

  const onMessageClick = (scores: ReviewersScoresInterface[]) => {
    setPopupDetails(scores)
    setIsPopupOpen(true)
  }

  return (
    <>
      <TableWrapper>
        {table.timelineStats == null ? null : (
          <Stat
            label={`Latest skill rating${
              table.timelineStats?.cycle?.name
                ? ` (${table.timelineStats.cycle.name})`
                : ''
            }`}
            val={
              table.timelineStats?.latest_skills_rating
                ? PerformanceRatingTitle[table.timelineStats.latest_skills_rating]
                : 'N/A'
            }
          />
        )}
        <AdjustableTable
          expandableType="chevron"
          name={TableNames.EmployeePerformanceSkills}
          noDataMessage="Skills will appear here"
          onRowClick={handleRowClick}
          row={ROW(onMessageClick)}
          useWindowScroll
          {...table.table!}
          count={table.table?.data?.length || 0}
        />
      </TableWrapper>
      <SummarySidebar
        employeeId={data.id}
        isOpen={Boolean(isSidebarOpen && sidebarDetails)}
        onClose={() => setIsSidebarOpen(false)}
        sidebarDetails={sidebarDetails}
      />
      {isPopupOpen && (
        <Popup
          onClose={() => setIsPopupOpen(false)}
          open={Boolean(isPopupOpen && popupDetails)}
          variant="bottom-sheet"
        >
          <Text fontSize="primary" fontWeight={500}>
            Justification notes:
          </Text>
          {popupDetails?.map((score, ind) => (
            <SkillsNotes
              key={ind}
              title={score.reviewer_name}
              name={score.reviewer_name}
              subtitle={ReviewerRelationToShortTitle[score.reviewer_relation]}
              note={score.note}
            />
          ))}
        </Popup>
      )}
    </>
  )
}
