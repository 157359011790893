import { useSortable } from '@dnd-kit/sortable'
import React, { MouseEvent } from 'react'
import SortableRowContent from '@components/SortableList/SortableRowContent'

interface Props<T> {
  disabled?: boolean
  index?: number
  useDragOverlay?: boolean
  row: T
  children: React.ReactNode
  onClick: (e: MouseEvent<HTMLDivElement>) => void
}

const SortableRow = <T extends { id?: number | string }>({
  disabled,
  useDragOverlay,
  row,
  children,
  onClick,
}: Props<T>) => {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: String(row.id),
      disabled,
    })

  return (
    <SortableRowContent
      ref={setNodeRef}
      transform={transform}
      transition={!useDragOverlay && isDragging ? 'none' : transition}
      listeners={listeners}
      dragOverlay={!useDragOverlay && isDragging}
      onClick={onClick}
      {...attributes}
    >
      {children}
    </SortableRowContent>
  )
}

export default SortableRow
