import React from 'react'
import { HStack, Icon, Token, Text } from '@revolut/ui-kit'

import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { TimeOffPolicyReviewEligibleEmployeeInterface } from '@src/interfaces/timeOff'
import Tooltip from '@src/components/Tooltip/Tooltip'
import { selectorKeys } from '../api'

export const timeOffPolicyReviewEmployeeColumn: ColumnInterface<TimeOffPolicyReviewEligibleEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee.id',
    dataPoint: 'employee.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data} />,
  }

export const timeOffPolicyReviewCurrentBalanceColumn: ColumnInterface<TimeOffPolicyReviewEligibleEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'auto_assignment_details.current_balance',
    dataPoint: 'auto_assignment_details.current_balance',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Current Balance',
    insert: ({ data }) => {
      const currentBalance = data.auto_assignments_details.current_balance

      if (!currentBalance) {
        return '-'
      }
      if (typeof currentBalance === 'number') {
        return currentBalance.toFixed(2)
      }
      return currentBalance
    },
  }

export const timeOffPolicyReviewNewBalanceColumn: ColumnInterface<TimeOffPolicyReviewEligibleEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'auto_assignment_details.new_balance',
    dataPoint: 'auto_assignment_details.new_balance',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'New Balance',
    insert: ({ data }) => {
      const newBalance = data.auto_assignments_details.new_balance

      if (!newBalance) {
        return '-'
      }
      if (typeof newBalance === 'number') {
        return newBalance.toFixed(2)
      }
      return newBalance
    },
  }

export const timeOffPolicyReviewStatusColumn: ColumnInterface<TimeOffPolicyReviewEligibleEmployeeInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'status.id',
    dataPoint: 'status.name',
    sortKey: 'auto_assignments_details__status__id',
    filterKey: 'auto_assignments_details__status__id',
    selectorsKey: selectorKeys.time_off_policy_automatic_assignment_eligibility_statuses,
    title: 'Status',
    insert: ({ data }) => {
      const errors = data.auto_assignments_details.errors || []
      // TODO: BE sends warnings in the `errors` field, should be refactored after the API is adjusted
      const forceIgnoreErrors =
        data.auto_assignments_details.status.id === 'policy_will_partially_apply'
      const hasErrors = !forceIgnoreErrors && !!errors.length

      return (
        <HStack gap="s-8">
          {hasErrors && (
            <Tooltip
              title="Errors"
              text={data.auto_assignments_details.errors?.join(', ')}
              placement="bottom"
            >
              <Icon name="16/Warning" size={14} color={Token.color.orange} />
            </Tooltip>
          )}
          <Text>
            {data.auto_assignments_details.status.name
              ? data.auto_assignments_details.status.name
              : '-'}
          </Text>
        </HStack>
      )
    },
  }
