import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { interviewsConfig } from '@src/pages/OnboardingChecklistV2/common/checkpointsConfig'
import { InterviewsIntro } from './InterviewsIntro'
import { OnboardingChecklistContent } from '@src/pages/OnboardingChecklistV2/components/OnboardingChecklistContent'
import { PermissionTypes } from '@src/store/auth/types'
import { SetupInterviews } from '@src/pages/OnboardingChecklistV2/Interviews/SetupInterviews'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.INTRO,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: InterviewsIntro,
  },
  {
    title: 'Interviews',
    path: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.SETUP,
    url: ROUTES.ONBOARDING_CHECKLIST_V2.INTERVIEWS.SETUP,
    canView: [PermissionTypes.ViewHiringProcessPreferences],
    component: SetupInterviews,
    hideActions: true,
  },
]

export const Interviews = () => {
  return <OnboardingChecklistContent config={interviewsConfig} routes={routes} />
}
