import React from 'react'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import {
  InterviewFeedbackStatus,
  InterviewFeedbackInterface,
} from '@src/interfaces/interviewTool'

interface SaveToDraftButtonProps {
  afterSubmitUrl: string
}

const SubmitButton = ({ afterSubmitUrl }: SaveToDraftButtonProps) => {
  const { values, submit } = useLapeContext<InterviewFeedbackInterface>()

  const onClick = () => {
    const currentStatus = values.status
    values.status = InterviewFeedbackStatus.completed

    return submit().catch(error => {
      values.status = currentStatus
      throw error
    })
  }

  return (
    <NewSaveButtonWithPopup
      successText="Interview scorecard saved successfully"
      onClick={onClick}
      afterSubmitUrl={afterSubmitUrl}
      hideWhenNoChanges={false}
      useValidator
    >
      {values.status === InterviewFeedbackStatus.pending ||
      values.status === InterviewFeedbackStatus.draft
        ? 'Submit'
        : 'Save Changes'}
    </NewSaveButtonWithPopup>
  )
}

export default connect(SubmitButton)
