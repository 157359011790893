import { Box } from '@revolut/ui-kit'
import { UserCompanyAvatar } from '@src/features/UserAvatarWithMenu/UserCompanyAvatar'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { MainHeaderButton } from '@src/features/MainHeader/MainHeaderButton'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { InternalLink } from '@components/InternalLink/InternalLink'

// TODO: https://revolut.atlassian.net/browse/REVCOR-2756 remove
export const UserProfileLink = () => {
  const user = useSelector(selectUser)

  return (
    <Box
      hide="md-*"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.EMPLOYEE.PROFILE, { id: user?.id })}
    >
      <MainHeaderButton>
        <UserCompanyAvatar user={user} size={20} />
      </MainHeaderButton>
    </Box>
  )
}
