import { useEffect } from 'react'

const usePreventUserClose = (preventUserClose: boolean) => {
  useEffect(() => {
    const onBeforeUnload = (event: Event) => {
      if (preventUserClose) {
        // The HTML specification states that authors should use the event.preventDefault() method instead of using event.returnValue to prompt the user. However, this is not yet supported by all browsers. When this event returns (or sets the returnValue property to) a value other than null or undefined, the user will be prompted to confirm the page unload.
        // @ts-ignore
        event.returnValue = 'Sure you want to leave?'
      }
    }
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [preventUserClose])
}

export default usePreventUserClose
