import React from 'react'
import { Avatar, Group, Item, Text, VStack } from '@revolut/ui-kit'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import { HiringStageDetailsInterviewers } from '@src/features/JobPostingFlow/HiringProcess/HiringStageDetailsInterviewers'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import OpenInNewTab from '@components/OpenInNewTab/OpenInNewTab'

interface Props {
  stage: HiringProcessInterface
}

export const HiringStageDetails = ({ stage }: Props) => {
  return (
    <VStack gap="s-16">
      <Group>
        <Item aria-label="Stage details">
          <Item.Avatar>
            <Avatar useIcon="IndustrialGear" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Stage details</Item.Title>
          </Item.Content>
        </Item>
        <FormPreview data={stage}>
          <FormPreview.Item title="Stage name" field="title" />
          <FormPreview.Item title="Stage type" field="stage_type.name" />
          <FormPreview.Item<HiringProcessInterface>
            title="Duration"
            insert={data => getDuration(data.duration, data.duration_unit.name)}
          />
        </FormPreview>
      </Group>
      <Group>
        <Item aria-label="Playbook">
          <Item.Avatar>
            <Avatar useIcon="Document" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Playbook</Item.Title>
          </Item.Content>
        </Item>
        <FormPreview data={stage}>
          <FormPreview.Item<HiringProcessInterface>
            title="Playbook link"
            insert={data =>
              data.playbook_link ? (
                <OpenInNewTab link={data.playbook_link} label="Interview Playbook" />
              ) : (
                'n/a'
              )
            }
          />
        </FormPreview>
      </Group>
      <Group>
        <Item aria-label="Purpose">
          <Item.Avatar>
            <Avatar useIcon="InfoOutline" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Purpose</Item.Title>
            <Item.Description>{stage.notes}</Item.Description>
          </Item.Content>
        </Item>
      </Group>
      <Group>
        <Item aria-label="Interviewers">
          <Item.Avatar>
            <Avatar useIcon="People" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Interviewers</Item.Title>
            <Item.Description>Eligible interviewers for this stage</Item.Description>
          </Item.Content>
        </Item>
        <FormPreview data={stage}>
          <FormPreview.Item<HiringProcessInterface>
            title="Interviewers"
            insert={data => (
              <HiringStageDetailsInterviewers
                type={data.interviewer_type}
                groups={data.interviewers_groups}
                employees={data.interviewers}
              />
            )}
          />
          <FormPreview.Item<HiringProcessInterface>
            title="Interviewer seniority"
            insert={data => {
              if (data?.seniority_min || data?.seniority_max) {
                if (data?.seniority_min?.name === data?.seniority_max?.name) {
                  return <Text>{data?.seniority_min?.name}</Text>
                }
                return (
                  <Text>
                    {data?.seniority_min?.name} - {data?.seniority_max?.name}
                  </Text>
                )
              }

              return 'All'
            }}
          />
        </FormPreview>
      </Group>
      <Group>
        <Item aria-label="Scheduling preferences">
          <Item.Avatar>
            <Avatar useIcon="CalendarRecurring" />
          </Item.Avatar>
          <Item.Content>
            <Item.Title>Scheduling preferences</Item.Title>
          </Item.Content>
        </Item>
        <FormPreview data={stage}>
          <FormPreview.Item<HiringProcessInterface>
            title="Schedule interview automatically"
            insert={data =>
              data.schedule_interview_automatically ? 'Enabled' : 'Disabled'
            }
          />
          <FormPreview.Item<HiringProcessInterface>
            title="Email template"
            to={data =>
              data.candidate_scheduling_email_template
                ? pathToUrl(ROUTES.FORMS.EMAIL_TEMPLATE.PREVIEW, {
                    id: data.candidate_scheduling_email_template.id,
                  })
                : undefined
            }
            insert={data =>
              data.candidate_scheduling_email_template
                ? data.candidate_scheduling_email_template.name
                : 'n/a'
            }
          />
          <FormPreview.Item<HiringProcessInterface>
            title="Only allow unique interviewers"
            insert={data => (data.exclude_previous_interviewers ? 'Enabled' : 'Disabled')}
          />
        </FormPreview>
      </Group>
    </VStack>
  )
}
