import * as React from 'react'
import { useEffect, useState } from 'react'
import { selectorKeys } from '@src/constants/api'
import { eligibleEmployeesRequests } from '@src/api/supportTool/eligibleEmployees'
import { CycleOption, OptionInterface } from '@src/interfaces/selectors'
import { Header, Popup, Button } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'

interface Props {
  open: boolean
  onClose: () => void
  onSubmit: (employee: OptionInterface, cycle: OptionInterface) => void
  onSelectCycle: (cycle: CycleOption | null) => void
  cycles: CycleOption[]
  selectedCycle: CycleOption | null
}

const AddEligibleEmployeePopup = ({
  open,
  onClose,
  onSubmit,
  onSelectCycle,
  cycles,
  selectedCycle,
}: Props) => {
  const [employee, setEmployee] = useState<OptionInterface | undefined>()
  const [error, setError] = useState<string | undefined>()

  const fetchEmployees = async () => {
    if (employee && selectedCycle) {
      const resp = await eligibleEmployeesRequests.getItems({
        filters: [
          {
            columnName: 'employee__id',
            filters: [{ id: employee.id, name: String(employee.id) }],
          },
          {
            columnName: 'cycle__id',
            filters: [{ id: selectedCycle.id, name: String(selectedCycle.id) }],
          },
        ],
      })

      if (resp.data.results.length) {
        setError('This employee already exists in the list')
      } else {
        setError(undefined)
      }
    }
  }

  const handleClose = () => {
    onClose()
    setError(undefined)
  }

  useEffect(() => {
    fetchEmployees()
  }, [employee, selectedCycle])

  return (
    <Popup variant="bottom-sheet" open={open} onClose={handleClose}>
      <Header variant="bottom-sheet">
        <Header.Title>Add an eligible employee</Header.Title>
      </Header>
      <RadioSelectInput
        value={employee}
        label="Employee name"
        onChange={(option: OptionInterface | null) => setEmployee(option!)}
        selector={selectorKeys.employee}
        message={error}
        hasError={!!error}
      />
      <br />
      <RadioSelectInput
        value={selectedCycle}
        onChange={onSelectCycle}
        options={cycles.map(item => ({ label: item.name, value: item }))}
        label="Cycle"
      />
      <Popup.Actions>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (employee && selectedCycle) {
              onSubmit(employee, selectedCycle)
              setEmployee(undefined)
            }
          }}
          disabled={!employee || !selectedCycle || !!error}
        >
          Add employee
        </Button>
      </Popup.Actions>
    </Popup>
  )
}

export default AddEligibleEmployeePopup
