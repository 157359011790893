import React from 'react'
import { Cell, Flex, MoreBar } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { CulturalSkillInterface, SkillInterface, SkillType } from '@src/interfaces/skills'
import { useTableReturnType } from '@components/Table/hooks'
import { RowInterface, Stats } from '@src/interfaces/data'
import {
  culturalSkillsActionColumn,
  culturalSkillsAverageColumn,
  culturalSkillsNameColumn,
  culturalSkillsStatusColumn,
  culturalSkillsValidUntilColumn,
} from '@src/constants/columns/culturalSkills'
import { ownerNameWithAvatarColumn } from '@src/constants/columns/employee'
import { PermissionTypes } from '@src/store/auth/types'
import { Plus } from '@revolut/icons'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import SettingsButtons from '@src/features/SettingsButtons'
import { TableNames } from '@src/constants/table'

interface Props {
  table: useTableReturnType<CulturalSkillInterface, Stats>
}

const row: RowInterface<CulturalSkillInterface> = {
  cells: [
    {
      ...culturalSkillsNameColumn,
      width: 220,
    },
    {
      ...culturalSkillsAverageColumn,
      width: 160,
    },
    {
      ...ownerNameWithAvatarColumn,
      width: 220,
      filterKey: null,
      sortKey: null,
    },
    {
      ...culturalSkillsStatusColumn,
      width: 120,
    },
    {
      ...culturalSkillsValidUntilColumn,
      width: 120,
    },
    {
      ...culturalSkillsActionColumn,
      width: 140,
    },
  ],
}

const CulturalSkills = ({ table }: Props) => {
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)
  const canCreate = permissions.includes(PermissionTypes.CanManageCulturalSkills)

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        {canCreate && (
          <SettingsButtons pb="s-16">
            <MoreBar.Action
              useIcon={Plus}
              onClick={() => {
                const initialValues: Partial<SkillInterface> = {
                  skill_type: SkillType.Culture,
                  owner: { id: user.id, full_name: user.full_name },
                }
                navigateTo(pathToUrl(ROUTES.FORMS.SKILL.GENERAL), { initialValues })
              }}
            >
              Create new
            </MoreBar.Action>
          </SettingsButtons>
        )}
        <Flex style={{ position: 'relative' }} flex="1 0">
          <AdjustableTable<CulturalSkillInterface>
            name={TableNames.CompanyCulturalSkills}
            useWindowScroll
            dataType="value"
            row={row}
            hideCount
            {...table}
          />
        </Flex>
      </Flex>
    </Cell>
  )
}

export default CulturalSkills
