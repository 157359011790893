import { useReviewCycles } from '@src/api/reviewCycles'
import { CycleOption } from '@src/interfaces/selectors'

import { FilterByInterface, SORT_DIRECTION } from '@src/interfaces/data'
import { ReviewCyclesInterface } from '@src/interfaces/reviewCycles'
import { BaseOptionId } from '@src/interfaces/selectors'
import { useEffect } from 'react'

const initialSorting = [{ sortBy: 'start_date_time', direction: SORT_DIRECTION.ASC }]
const CURRENT_OFFSET = 0

const reviewCycleToCycleOptionMapper = (
  reviewCycle: ReviewCyclesInterface,
): CycleOption => {
  return {
    id: reviewCycle.id,
    name: reviewCycle.name,
    performance_reviews_selected_cycle: Boolean(
      reviewCycle.performance_reviews_selected_cycle,
    ),
    is_test: reviewCycle.is_test,
    offset: `${reviewCycle.offset}`,
  }
}

export const useReviewCycleData = (
  activeFilterCycleId: BaseOptionId,
  setSelectedReviewCycle: (data: FilterByInterface[]) => void,
  employeeId: number,
  managerId: number,
) => {
  const { data: reviewCycles, isLoading: isLoadingReviewCycles } = useReviewCycles({
    sortBy: initialSorting,
    filters: [
      {
        columnName: 'employees',
        filters: [
          { id: employeeId, name: `${employeeId}` },
          { id: managerId, name: `${managerId}` },
        ],
      },
    ],
  })

  useEffect(() => {
    const currentCycle = reviewCycles?.results?.find(
      item => item.offset === CURRENT_OFFSET,
    )
    if (currentCycle) {
      setSelectedReviewCycle([
        { columnName: '', filters: [reviewCycleToCycleOptionMapper(currentCycle)] },
      ])
    }
  }, [reviewCycles])

  const reviewCycleOptions = {
    options:
      reviewCycles?.results?.map(cycle => {
        return {
          id: cycle.id,
          name: cycle.name,
          offset: cycle.offset,
        }
      }) || [],
  }
  const reviewCycleRecordById = reviewCycles?.results?.reduce(
    (
      acc: Record<number | string, ReviewCyclesInterface>,
      item: ReviewCyclesInterface,
    ) => {
      acc[item.id] = item
      return acc
    },
    {},
  )

  const dateRanges = {
    startDate: reviewCycleRecordById?.[activeFilterCycleId]?.start_date_time,
    endDate: reviewCycleRecordById?.[activeFilterCycleId]?.end_date_time,
  }
  return {
    reviewCycleOptions,
    isLoading: isLoadingReviewCycles,
    dateRanges,
  }
}
