import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { InterviewerAvailability } from '@src/pages/Forms/Candidate/ScheduleSidebar/useGetInterviewersAvailability'
import { formatDate, formatDateTime } from '@src/utils/format'
import { TextProps } from '@revolut/ui-kit'
import { notReachable } from '@src/utils/notReachable'

type Props = {
  availability?: InterviewerAvailability
  textVariant?: TextProps['variant']
}

export const InterviewerAvailabilityLabel = ({ availability, textVariant }: Props) => {
  const baseTextProps = {
    variant: textVariant,
  }
  if (!availability) {
    return (
      <Text color={Token.color.greyTone50} {...baseTextProps}>
        Unavailable
      </Text>
    )
  }

  switch (availability.id) {
    case 'SlotAvailable': {
      if (availability.slot === 'interview') {
        return (
          <Text color={Token.color.green} {...baseTextProps}>
            Free [interview-slot] on{' '}
            {formatDateTime(new Date(availability.selectedSlot.start))}
          </Text>
        )
      }

      return (
        <Text color={Token.color.green} {...baseTextProps}>
          Available on {formatDateTime(new Date(availability.selectedSlot.start))}
        </Text>
      )
    }

    case 'WeekAvailable': {
      return (
        <Text
          color={
            availability.interviewSlotsCount ? Token.color.green : Token.color.warning
          }
          {...baseTextProps}
        >
          {availability.interviewSlotsCount} free [interview-slot] this week
        </Text>
      )
    }

    case 'LimitOverDay':
      return (
        <Text color={Token.color.greyTone50} {...baseTextProps}>
          Over the limit on {formatDate(availability.selectedSlot.start)} (
          {availability.booked}/{availability.limit} interviews booked)
        </Text>
      )

    case 'LimitOverWeek':
      return (
        <Text color={Token.color.greyTone50} {...baseTextProps}>
          Over the limit for this week ({availability.booked}/{availability.limit}{' '}
          interviews booked)
        </Text>
      )

    case 'SlotUnavailable':
      return (
        <Text color={Token.color.greyTone50} {...baseTextProps}>
          Unavailable on {formatDateTime(availability.selectedSlot.start)}
        </Text>
      )

    case 'WeekUnavailable':
      return (
        <Text color={Token.color.greyTone50} {...baseTextProps}>
          Unavailable for this week
        </Text>
      )

    default:
      throw notReachable(availability)
  }
}
