import React, { useEffect } from 'react'
import { BottomSheet, Button, InputGroup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'

import LapeForm, { useLapeContext } from '@src/features/Form/LapeForm'
import {
  FormValidatorProvider,
  useSafeFormValidator,
} from '@src/features/Form/FormValidator'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import {
  MergeTeamsPayloadInterface,
  MergeTeamsResponseInterface,
} from '@src/interfaces/updateOrganisationStructure'
import { mergeTeams } from '@src/api/updateOrganisationStructure'
import { selectUser } from '@src/store/auth/selectors'

interface MergeTeamsProps {
  unitsToMerge: { id: number }[]
  onSuccess: (data: MergeTeamsResponseInterface) => void
  showMissionField: boolean
  setCurrentUserAsOwner: boolean
}

export const MergeTeams = ({
  unitsToMerge,
  onSuccess,
  showMissionField,
  setCurrentUserAsOwner,
}: MergeTeamsProps) => {
  return (
    <LapeForm<MergeTeamsPayloadInterface>
      onSubmit={form =>
        mergeTeams({ ...form.values, teams: unitsToMerge }).then(response => {
          onSuccess(response.data)
          return form.values
        })
      }
    >
      <FormValidatorProvider>
        <MergeTeamsForm
          showMissionField={showMissionField}
          setCurrentUserAsOwner={setCurrentUserAsOwner}
        />
      </FormValidatorProvider>
    </LapeForm>
  )
}

interface MergeTeamsFormProps {
  showMissionField: boolean
  setCurrentUserAsOwner: boolean
}

const MergeTeamsForm = ({
  showMissionField,
  setCurrentUserAsOwner,
}: MergeTeamsFormProps) => {
  const { isSubmitting, submit, values } = useLapeContext<MergeTeamsPayloadInterface>()
  const { validate } = useSafeFormValidator()

  const user = useSelector(selectUser)

  useEffect(() => {
    if (setCurrentUserAsOwner) {
      values.team_owner = { id: user.id, name: user.full_name }
    }
  }, [setCurrentUserAsOwner])

  return (
    <>
      <InputGroup>
        <LapeNewInput name="name" label="New team name" required />
        <LapeRadioSelectInput
          name="team_owner"
          label="Owner"
          selector={selectorKeys.employee}
        />
        {showMissionField && (
          <LapeNewTextArea
            name="mission"
            label="Mission"
            message="A short summary explaining the goals and the scope of the team."
            rows={2}
            required
          />
        )}
      </InputGroup>
      <BottomSheet.Actions>
        <Button onClick={validate(() => submit())} pending={isSubmitting} elevated>
          Save
        </Button>
      </BottomSheet.Actions>
    </>
  )
}
