import React from 'react'
import { Item, Text, VStack } from '@revolut/ui-kit'

export const RolesIntro = () => {
  return (
    <Item>
      <Item.Content>
        <Item.Title>How to setup Functions & Roles?</Item.Title>
        <Item.Description>
          <VStack gap="s-16">
            <Text>
              Welcome to the Roles & Specialisations Setup feature. Define and manage
              roles within your organisational framework. Whether you're shaping new
              positions or refining existing ones, our tool streamlines the process,
              ensuring the distribution of responsibilities throughout your workforce.
              Promote a workplace environment where every team member understands their
              role and mission in the organisation.
            </Text>
          </VStack>
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
