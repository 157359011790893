import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { Box, ErrorWidget } from '@revolut/ui-kit'
import Lock from '@src/assets/3D/Lock@2x.png'
import { pathToUrl } from '@src/utils/router'

interface Props {
  employeeId: number
}

const PerformanceCycleClosed = ({ employeeId }: Props) => {
  return (
    <Box width={510}>
      <ErrorWidget>
        <ErrorWidget.Image src={Lock} />
        <ErrorWidget.Title>You cannot add a review</ErrorWidget.Title>
        <ErrorWidget.Description>
          Review window has closed or you don't have permissions
        </ErrorWidget.Description>
        <ErrorWidget.Action
          onClick={() =>
            navigateTo(
              pathToUrl(ROUTES.FORMS.EMPLOYEE.PERFORMANCE.PERFORMANCE, {
                id: employeeId,
              }),
            )
          }
        >
          Go to employee performance page
        </ErrorWidget.Action>
      </ErrorWidget>
    </Box>
  )
}

export default PerformanceCycleClosed
