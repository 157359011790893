import React from 'react'
import { TeamInterface } from '@src/interfaces/teams'
import { initialRequisitionStatusFilter } from '@src/interfaces/requisitions'
import {
  CommonRequisitionTable,
  requisitionSortBy,
} from '@src/features/CommonRequisitionTable/CommonRequisitionTable'

interface StructureProps {
  data: TeamInterface
  navigation?: React.ReactElement
}

export const getRequisitionsFilter = (data: TeamInterface) => [
  {
    filters: [{ name: data.id.toString(), id: data.id }],
    columnName: 'team__id',
    nonResettable: true,
  },
  {
    filters: initialRequisitionStatusFilter,
    columnName: 'status',
    nonResettable: true,
  },
]

const Requisitions = ({ data, navigation }: StructureProps) => {
  const filterBy = getRequisitionsFilter(data)
  const statsData = {
    filters: [
      {
        filters: [{ name: data.id.toString(), id: data.id }],
        columnName: 'team__id',
      },
    ],
  }
  return (
    <CommonRequisitionTable
      filterBy={filterBy}
      sortBy={requisitionSortBy}
      statsData={statsData}
      newItemInitialValues={{ team: { name: data.name, id: data.id } }}
      type="team"
      navigation={navigation}
    />
  )
}

export default Requisitions
