import React from 'react'
import {
  CandidateInterface,
  InterviewRoundInterface,
} from '@src/interfaces/interviewTool'
import { HStack, Skeleton, Tag, Text, Token, Icon } from '@revolut/ui-kit'
import { formatDateTime } from '@src/utils/format'

type CandidateSubtitleProps = {
  candidate?: CandidateInterface
  round?: InterviewRoundInterface
  loading?: boolean
}

const CandidateSubtitle = ({ candidate, round, loading }: CandidateSubtitleProps) => {
  const isArchived = round?.state === 'archived'
  return (
    <HStack align="center" gap="s-16" data-testid="candidate-subtitle">
      {loading ? (
        <>
          <Skeleton width={150} /> <Skeleton width={250} />
        </>
      ) : (
        <>
          {candidate && round && (
            <>
              {isArchived ? (
                <Tag
                  variant="outlined"
                  color={candidate.can_re_engage ? Token.color.green : Token.color.red}
                >
                  {candidate.can_re_engage ? 'Can be contacted' : 'Cannot be contacted'}
                </Tag>
              ) : (
                <HStack gap="s-4" align="center">
                  <Icon name="Microphone" size={16} color={Token.color.greyTone50} />
                  <Text use="div" color={Token.color.greyTone50} variant="caption">
                    Current stage: {round.latest_interview_stage?.title || '-'}
                  </Text>
                </HStack>
              )}
            </>
          )}
          <HStack gap="s-4" align="center">
            <Icon name="Time" size={16} color={Token.color.greyTone50} />
            <Text use="div" color={Token.color.greyTone50} variant="caption">
              {candidate?.latest_activity_date_time
                ? `Last activity at ${formatDateTime(
                    candidate?.latest_activity_date_time,
                  )}`
                : ''}
            </Text>
          </HStack>
        </>
      )}
    </HStack>
  )
}

export default CandidateSubtitle
