import { ActionButton } from '@revolut/ui-kit'
import React from 'react'
import { PageActions } from '@src/components/Page/PageActions'
import { InternalLink } from '@src/components/InternalLink/InternalLink'

type Props = {
  closeRoute: string
}

const TrackProgressPageActions = ({ closeRoute }: Props) => {
  return (
    <PageActions>
      <ActionButton use={InternalLink} to={closeRoute} variant="accent">
        Close bulk edit task
      </ActionButton>
    </PageActions>
  )
}

export default TrackProgressPageActions
