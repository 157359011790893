import React from 'react'
import { Spacer, VStack } from '@revolut/ui-kit'

import { LayoutTabProps } from '../common/types'
import {
  canViewTimeOffBalances,
  canViewTimeOffRequests,
} from '../../Preview/ProfileSummary/common'
import { ApprovalsWidget } from './ApprovalsWidget'
import { BalancesWidget } from './BalancesWidget'
import { CalendarsWidget } from './CalendarsWidget'

export const TimeManagementLayoutTab = ({ data }: LayoutTabProps) => {
  return (
    <VStack space="s-16">
      {canViewTimeOffRequests(data) && <ApprovalsWidget data={data} />}
      {canViewTimeOffBalances(data) && <BalancesWidget data={data} />}
      <CalendarsWidget data={data} />
      <Spacer height="30vh" />
    </VStack>
  )
}
