import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import React from 'react'
import OnboardingChecklistContent, {
  OnboardingChecklistRoute,
} from '../components/OnboardingChecklistContent'
import { roadmapsConfig } from '../common/constants'
import { Intro } from './Intro'
import { Integration } from './Integration'
import Preview from './Preview'
import { roadmapSettings } from '@src/api/settings'
import { API } from '@src/constants/api'
import { jiraPreferencesRequests } from '@src/api/integrations'

export const Roadmaps = () => {
  const routes: OnboardingChecklistRoute[] = [
    {
      title: 'Intro',
      path: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.INTRO,
      url: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.INTRO,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Intro,
      form: {
        api: roadmapSettings,
        invalidateQueries: [API.ROADMAP_SETTINGS],
      },
    },
    {
      title: 'BI integration',
      path: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.JIRA_INTEGRATION,
      url: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.JIRA_INTEGRATION,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Integration,
      form: {
        api: {
          ...jiraPreferencesRequests,
          update: async (...args) => {
            const result = await jiraPreferencesRequests.update(...args)
            if (result.data.enabled) {
              // we don't want to show also a global jira toggle for user, but have to make sure it's enabled if jira preferences are enabled
              await roadmapSettings.update({ jira_epics_enabled: true }, { id: '1' })
            }

            return result
          },
        },
        invalidateQueries: [API.JIRA_INTEGRATION_PREFERENCES, API.ROADMAP_SETTINGS],
      },
    },
    {
      title: 'Preview',
      path: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.PREVIEW,
      url: ROUTES.ONBOARDING_CHECKLIST.ROADMAPS.PREVIEW,
      canView: [PermissionTypes.ViewPerformancePreferences],
      component: Preview,
      isWide: true,
    },
  ]
  return <OnboardingChecklistContent config={roadmapsConfig} routes={routes} />
}
