import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Cell, Group, MoreBar, Token, VStack, Text } from '@revolut/ui-kit'
import { employeeOnboardingTemplateRequests } from '@src/api/employeeOnboardingTemplateRequests'
import { TemplateItem } from '@src/apps/People/Documents/BulkRequest/Edit/components/TemplateItem'
import { ROUTES } from '@src/constants/routes'
import { EmployeeOnboardingTemplateInterface } from '@src/interfaces/employeeOnboardingTemplate'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DeleteButton, EditButton } from '@src/features/SettingsButtons'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import { TaskItem } from './components/TaskItem'

const EmptyCellContent = () => (
  <Text color={Token.color.greyTone50} variant="h6">
    Nothing selected
  </Text>
)

export const Preview = () => {
  const params = useParams()
  const permissions = useSelector(selectPermissions)
  const canEdit = permissions.includes(PermissionTypes.HRManagerPermissions)
  const { values } = useLapeContext<EmployeeOnboardingTemplateInterface>()

  const requestedTemplates = values.document_templates.filter(
    template => template.template_type.id === 'request',
  )
  const signedTemplates = values.document_templates?.filter(
    template => template.template_type.id === 'esignature',
  )

  return (
    <>
      <PageHeader backUrl={ROUTES.SETTINGS.EMPLOYEES.ONBOARDING} title={values.name} />
      <PageBody>
        <MoreBar>
          <EditButton
            isVisible={canEdit}
            route={pathToUrl(ROUTES.FORMS.EMPLOYEE_ONBOARDING_TEMPLATE.GENERAL, params)}
          />
          <DeleteButton
            backUrl={ROUTES.SETTINGS.EMPLOYEES.ONBOARDING}
            deleteApi={employeeOnboardingTemplateRequests.delete!}
            globalPermissions={[PermissionTypes.HRManagerPermissions]}
            title="onboarding template"
          />
        </MoreBar>
        <VStack gap="s-32" mt="s-16">
          <FormPreview data={values}>
            <Group>
              <FormPreview.Item field="created_by" title="Created by" type="employee" />
            </Group>
          </FormPreview>
          <Text color={Token.color.greyTone50} variant="h6">
            Documents for the employee to upload
          </Text>
          <Cell p={requestedTemplates.length ? 0 : 's-16'}>
            {!!requestedTemplates.length && (
              <VStack width="100%">
                {requestedTemplates.map(template => (
                  <TemplateItem canPreview key={template.id} template={template} />
                ))}
              </VStack>
            )}
            {!requestedTemplates.length && <EmptyCellContent />}
          </Cell>

          <Text color={Token.color.greyTone50} variant="h6">
            Documents for the employee to sign
          </Text>
          <Cell p={signedTemplates.length ? 0 : 's-16'}>
            {!!signedTemplates.length && (
              <VStack width="100%">
                {signedTemplates.map(template => (
                  <TemplateItem canPreview key={template.id} template={template} />
                ))}
              </VStack>
            )}
            {!signedTemplates.length && <EmptyCellContent />}
          </Cell>

          <Text color={Token.color.greyTone50} variant="h6">
            Tasks
          </Text>
          <Cell p={values.task_definitions.length ? 0 : 's-16'}>
            {!!values.task_definitions.length && (
              <VStack width="100%">
                {values.task_definitions.map(task => (
                  <TaskItem key={task.id} task={task} />
                ))}
              </VStack>
            )}
            {!values.task_definitions.length && <EmptyCellContent />}
          </Cell>
        </VStack>
      </PageBody>
    </>
  )
}
