import React, { useEffect } from 'react'
import { Route } from 'react-router'
import { Switch, useParams } from 'react-router-dom'
import { connect } from 'lape'
import { Badge, Box, TextSkeleton, Token } from '@revolut/ui-kit'

import { ROUTES } from '@src/constants/routes'
import { TabBarTableNavigationInterface } from '@src/interfaces/data'
import { pathToUrl } from '@src/utils/router'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import TabBarNavigation from '@src/features/TabBarNavigation/TabBarNavigation'
import {
  useGetWorkScheduleBasics,
  workScheduleApprovalsRequests,
  workScheduleBasicRequests,
  workScheduleEligibilityRequests,
  workScheduleExtraHoursRequests,
  workScheduleReviewEligibilityRequests,
} from '@src/api/workSchedule'
import { RequestInterfaceNew } from '@src/interfaces'
import { WorkScheduleStepsStatusesInterface } from '@src/interfaces/workSchedule'
import Form from '@src/features/Form/Form'

import { CommonTabsProps } from './common'
import { WorkScheduleBasics } from './Basics'
import { WorkScheduleExtraHours } from './ExtraHours'
import { WorkScheduleApprovals } from './Approvals'
import { WorkScheduleReview } from './Review'
import { WorkScheduleEligibility } from './Eligibility'
import { useQuery } from '@src/utils/queryParamsHooks'

const BASE_URL = ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.EDIT

const tabsOrder = [
  {
    key: 'basics' as const,
    path: BASE_URL.BASICS,
    title: 'Basics',
    component: WorkScheduleBasics,
    api: workScheduleBasicRequests,
    statusKey: 'basic_step_completed' as const,
  },
  {
    key: 'extraHours' as const,
    path: BASE_URL.EXTRA_HOURS,
    title: 'Extra hours',
    component: WorkScheduleExtraHours,
    api: workScheduleExtraHoursRequests,
    statusKey: 'extra_hours_step_completed' as const,
  },
  {
    key: 'approvals' as const,
    path: BASE_URL.APPROVALS,
    title: 'Approvals',
    component: WorkScheduleApprovals,
    api: workScheduleApprovalsRequests,
    statusKey: 'approvals_step_completed' as const,
  },
  {
    key: 'eligibility' as const,
    path: BASE_URL.ELIGIBILITY,
    title: 'Eligibility',
    component: WorkScheduleEligibility,
    api: workScheduleEligibilityRequests,
    statusKey: 'eligibility_step_completed' as const,
  },
  {
    key: 'review' as const,
    path: BASE_URL.REVIEW,
    title: 'Review',
    api: workScheduleReviewEligibilityRequests,
    component: WorkScheduleReview,
  },
]

type TabKey = 'basics' | 'extraHours' | 'approvals' | 'eligibility' | 'review'
type EditTab = TabBarTableNavigationInterface & {
  key: TabKey
  path: string
  title: string
  component: React.FC<CommonTabsProps>
  api?: RequestInterfaceNew<{}>
  statusKey?: keyof WorkScheduleStepsStatusesInterface
}
type TabParams = { id?: string; subtab: TabKey }

const getTabs = (
  params: TabParams,
  stepsStatuses: WorkScheduleStepsStatusesInterface | undefined,
  query: Record<string, string>,
) => {
  return tabsOrder.reduce<EditTab[]>((tabsWithStatuses, tab, idx) => {
    const isTabCompleted = (t: EditTab) =>
      t.statusKey ? !!stepsStatuses?.[t.statusKey] : false
    const isAllPrevTabsCompleted = tabsWithStatuses.every(t => isTabCompleted(t))
    const isCompleted = isTabCompleted(tab)
    const isPending = !isCompleted && isAllPrevTabsCompleted
    const disabled = !isCompleted && !isPending

    tabsWithStatuses.push({
      ...tab,
      disabled,
      to: {
        pathname: pathToUrl(tab.path, params),
        search: new URLSearchParams(query).toString(),
      },
      preTitle: (
        <Box mr="s-8">
          <Badge
            size={16}
            bg={
              isCompleted
                ? Token.color.green
                : isPending
                ? Token.color.foreground
                : Token.color.greyTone50
            }
          >
            {idx + 1}
          </Badge>
        </Box>
      ),
    })

    return tabsWithStatuses
  }, [])
}

export const EditWorkSchedule = connect(() => {
  const params = useParams<TabParams>()
  const { query } = useQuery()

  const { data: basics, refetch: refetchBasics } = useGetWorkScheduleBasics(params.id)
  const tabs = getTabs(params, basics?.steps_statuses, query)

  useEffect(() => {
    if (params.subtab !== 'basics') {
      refetchBasics()
    }
  }, [params.subtab])

  const titleFallback = params.id ? (
    <TextSkeleton width={280} height={24} />
  ) : (
    'New work schedule'
  )

  return (
    <PageWrapper>
      <PageHeader
        title={basics?.name || titleFallback}
        backUrl={ROUTES.APPS.TIME_MANAGEMENT.WORK_SCHEDULES.LIST}
      />
      <PageBody>
        <TabBarNavigation tabs={tabs} mb="s-24" />
        <Switch>
          {tabs.map((tab, idx) => (
            <Route exact key={tab.key} path={tab.path}>
              <Form api={tab.api} disableLocalStorageCaching>
                <tab.component nextTabPath={tabs[idx + 1]?.path} />
              </Form>
            </Route>
          ))}
        </Switch>
      </PageBody>
    </PageWrapper>
  )
})
