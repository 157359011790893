import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import Regimes from '@src/pages/People/PeopleSubTabs/TimeOffPage/Regimes'
import PublicHolidays from '@src/pages/People/PeopleSubTabs/TimeOffPage/PublicHolidays'
import { TimeOffCategories } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Categories'
import { TimeOffPolicies } from '@src/pages/People/PeopleSubTabs/TimeOffPage/Policies'
import { General } from './General'
import { PermissionTypes } from '@src/store/auth/types'

export const TimeOffSettings = () => {
  useSetDocumentTitle('Settings', 'Timeoff')

  const routes = [
    {
      to: ROUTES.SETTINGS.TIME_OFF.GENERAL,
      path: ROUTES.SETTINGS.TIME_OFF.GENERAL,
      component: General,
      title: 'General',
      canView: true,
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.REGIMES,
      path: ROUTES.SETTINGS.TIME_OFF.REGIMES,
      component: Regimes,
      title: 'Regimes',
      canView: [PermissionTypes.ViewTimeOffRegimes],
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.HOLIDAYS,
      path: ROUTES.SETTINGS.TIME_OFF.HOLIDAYS,
      component: PublicHolidays,
      title: 'Public Holidays',
      canView: [PermissionTypes.ViewPublicHolidays],
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      path: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      component: TimeOffCategories,
      title: 'Categories',
      canView: [PermissionTypes.ViewTimeOffPolicyCategory],
    },
    {
      to: ROUTES.SETTINGS.TIME_OFF.POLICIES,
      path: ROUTES.SETTINGS.TIME_OFF.CATEGORIES,
      component: TimeOffPolicies,
      title: 'Policies',
      canView: [PermissionTypes.ViewTimeOffPolicies],
    },
  ]
  const filteredRoutes = routes.filter(route => route.canView)

  return (
    <PageWrapper>
      <Switch>
        {filteredRoutes.map(tab => (
          <Route exact path={tab.path} key={tab.path}>
            <tab.component />
          </Route>
        ))}
      </Switch>
    </PageWrapper>
  )
}
