import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useSelector } from 'react-redux'

import { overrideAPIMapWithTenant } from '@src/constants/api'
import { history as defaultHistory, updateHistory } from '@src/utils/router'
import { ROUTES, WORKSPACES } from '@src/constants/routes'
import { LocalStorageKeys } from '@src/store/auth/types'
import { selectAuthenticated, selectUser } from '@src/store/auth/selectors'
import { navigateReplace } from '@src/actions/RouterActions'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { isWorkspacesEnabled } from '@src/utils'
import { getWorkspaceFromPathname } from './common'

/** Very important this component gets rendered and redirect happens only once per whole app render to avoid infinite loops during rerender cycles.
 * We can't guarantee parent components won't trigger rerenders, thus this guard variable to prevent it */
let redirectGuardTriggered = false

interface WorkspaceContextProps {
  workspace: string | undefined
  workspaces: Workspace[]
  setWorkspaceFieldValue: (args: {
    field: 'icon' | 'companyName' | 'email'
    value: string
  }) => void
  removeWorkspace: (workspace: string) => void
}

interface WorkspaceActionState {
  workspace?: string
  action: 'redirect-to-main' | 'redirect-to-login' | 'redirect-to-workspace' | null
}

export interface Workspace {
  name: string
  companyName: string | null
  icon: string | null
  email: string | null
}

const WorkspaceContext = createContext<WorkspaceContextProps | null>(null)

export const useWorkspaceContext = () => {
  return useContext(WorkspaceContext)
}

const WorkspaceContextProvider: React.FC = ({ children }) => {
  const authenticated = useSelector(selectAuthenticated)
  const user = useSelector(selectUser)

  const [workspaces, setWorkspaces] = useLocalStorage<Workspace[]>(
    LocalStorageKeys.WORKSPACE_ACCOUNTS,
    [],
    false,
  )

  const [{ workspace, action }] = useState<WorkspaceActionState>(() => {
    const activeWorkspace = localStorage.getItem(LocalStorageKeys.ACTIVE_WORKSPACE)

    const isRootPath = window.location.pathname === '/'
    const isLoginPath =
      window.location.pathname.startsWith(ROUTES.LOGIN.MAIN) &&
      window.location.pathname !== ROUTES.LOGIN.REDIRECT

    if (isRootPath) {
      return { workspace: undefined, action: 'redirect-to-main' }
    }

    if (activeWorkspace && authenticated && isLoginPath) {
      return { workspace: activeWorkspace, action: 'redirect-to-main' }
    }
    if (activeWorkspace && !authenticated && isLoginPath) {
      return { workspace: activeWorkspace, action: 'redirect-to-login' }
    }

    if (isLoginPath) {
      return { action: 'redirect-to-workspace' }
    }

    return { workspace: getWorkspaceFromPathname(), action: null }
  })

  useEffect(() => {
    if (user?.email && workspace) {
      const currentWorkspace = workspaces.find(w => w.name === workspace) || {
        name: workspace,
        companyName: null,
        icon: null,
        email: null,
      }

      currentWorkspace.email = user.email

      setWorkspaces([currentWorkspace, ...workspaces.filter(w => w.name !== workspace)])
    }
  }, [user, workspace])

  const history = useMemo(
    () =>
      typeof window === 'undefined'
        ? ({} as any)
        : createBrowserHistory({ basename: workspace }),
    [workspace],
  )

  const setWorkspaceFieldValue = ({
    field,
    value,
  }: {
    field: 'icon' | 'companyName' | 'email'
    value: string
  }) => {
    if (workspace) {
      setWorkspaces(
        workspaces.map(ws => (ws.name === workspace ? { ...ws, [field]: value } : ws)),
      )
    }
  }

  updateHistory(history)

  if (workspace) {
    localStorage.setItem(LocalStorageKeys.ACTIVE_WORKSPACE, workspace)
    overrideAPIMapWithTenant(workspace)
  }

  if (!redirectGuardTriggered) {
    if (action === 'redirect-to-main') {
      navigateReplace(ROUTES.MAIN)
      redirectGuardTriggered = true
    }

    if (action === 'redirect-to-login') {
      navigateReplace(ROUTES.LOGIN.MAIN)
      redirectGuardTriggered = true
    }

    if (action === 'redirect-to-workspace') {
      navigateReplace(WORKSPACES.MAIN)
      redirectGuardTriggered = true
    }
  }

  const removeWorkspace = (ws: string) => {
    setWorkspaces(workspaces.filter(w => w.name !== ws))
  }

  const value = useMemo(
    () => ({
      workspace,
      workspaces,
      removeWorkspace,
      setWorkspaceFieldValue,
    }),
    [workspace, workspaces, removeWorkspace, setWorkspaceFieldValue, workspaces],
  )

  return (
    <WorkspaceContext.Provider value={value}>
      <Router history={history}>{children}</Router>
    </WorkspaceContext.Provider>
  )
}

const WorkspaceContextFeatureGuard: React.FC = ({ children }) => {
  const workspacesEnabled = isWorkspacesEnabled()

  if (workspacesEnabled) {
    return <WorkspaceContextProvider>{children}</WorkspaceContextProvider>
  }

  return <Router history={defaultHistory}>{children}</Router>
}

export { WorkspaceContextFeatureGuard as WorkspaceContextProvider }
