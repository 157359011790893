import React from 'react'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { Box, Group } from '@revolut/ui-kit'
import { ApplicationSectionInterface } from '@src/interfaces/applicationQuestions'

type Props = {
  section: ApplicationSectionInterface
}

const CandidateApplicationFormSection = ({ section }: Props) => {
  return (
    <Box mt="s-16">
      <FormPreview data={section} title={section.title} titleVariant="default">
        <Group>
          {section.questions.map((question, idx) => {
            if (
              question.internal_data_type === 'option_application_form_question' ||
              question.internal_data_type === 'dropdown_application_form_question'
            ) {
              return (
                <FormPreview.Item
                  key={question.internal_data_id}
                  title={question.title}
                  field={`questions.${idx}.answer.option`}
                />
              )
            }
            if (question.internal_data_type === 'checkbox_application_form_question') {
              return (
                <FormPreview.Item<ApplicationSectionInterface>
                  key={question.internal_data_id}
                  title={question.title}
                  insert={() =>
                    question.options
                      .filter(option => option.answer)
                      .map(option => option.option)
                      .join(', ') || '-'
                  }
                />
              )
            }
            return (
              <FormPreview.Item
                key={question.internal_data_id}
                title={question.title}
                field={`questions.${idx}.answer`}
              />
            )
          })}
        </Group>
      </FormPreview>
    </Box>
  )
}

export default CandidateApplicationFormSection
