import React from 'react'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import OnboardingChecklistContent from '@src/pages/OnboardingChecklist/components/OnboardingChecklistContent'
import { jobPostingsConfig } from '@src/pages/OnboardingChecklist/common/constants'
import JobPostingsIntro from '@src/pages/OnboardingChecklist/JobPostings/JobPostingsIntro'
import JobPostingsApprovals from '@src/pages/OnboardingChecklist/JobPostings/JobPostingsApprovals'
import { applicationFormSettings, jobPostingSettings } from '@src/api/settings'
import JobPostingsCompensationBands from '@src/pages/OnboardingChecklist/JobPostings/JobPostingsCompensationBands'
import { ApplicationFormBody } from '@src/pages/Settings/JobPosting/ApplicationForm/ApplicationForm'
import { JobPostingsBulkUpload } from './JobPostingsBulkUpload'

const routes = [
  {
    title: 'Intro',
    path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.INTRO,
    url: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.INTRO,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobPostingsIntro,
  },
  {
    title: 'Job postings approvals',
    path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.APPROVALS,
    url: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.APPROVALS,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobPostingsApprovals,
    form: {
      api: jobPostingSettings,
    },
  },
  {
    title: 'Compensation Bands',
    path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.COMPENSATION_BANDS,
    url: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.COMPENSATION_BANDS,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobPostingsCompensationBands,
    form: {
      api: jobPostingSettings,
    },
  },
  {
    title: 'Application form questions',
    path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.APPLICATION_FORM,
    url: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.APPLICATION_FORM,
    canView: [PermissionTypes.ViewApplicationFormPreferences],
    component: ApplicationFormBody,
    form: {
      api: applicationFormSettings,
    },
  },
  {
    title: 'Upload job postings',
    path: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.UPLOAD.ANY,
    url: ROUTES.ONBOARDING_CHECKLIST.JOB_POSTINGS.UPLOAD.ANY,
    canView: [PermissionTypes.ViewJobPostingPreferences],
    component: JobPostingsBulkUpload,
    hideActions: true,
    hidePageBody: true,
  },
]

const JobPostings = () => {
  return <OnboardingChecklistContent config={jobPostingsConfig} routes={routes} />
}

export default JobPostings
