import React, { useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import { A } from '@components/CommonSC/General'
import { selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import styled from 'styled-components'
import LapeNewMultiSelect from '@components/Inputs/LapeFields/LapeNewMultiSelect'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { OptionInterface } from '@src/interfaces/selectors'
import { DocumentInterface } from '@src/interfaces/documents'
import { getEmployeeDocumentNames } from '@src/api/documents'
import { Grid } from '@src/components/CommonSC/Grid'
import { NewMultiSelectProps } from '@src/components/Inputs/NewMultiSelect/NewMultiSelect'

const UploadLink = styled(A)`
  display: block;
  align-self: center;
  cursor: pointer;
`

interface Props<T> extends NewMultiSelectProps<T> {
  initialValues?: object
  employee?: Partial<EmployeeInterface>
}

const RETURN_AS_KEY = 'returned_uploaded_document'

const EmployeeDocSectionLape = <T extends { id: string | number }>({
  employee,
  initialValues,
  ...props
}: Props<T>) => {
  const { values } = useLapeContext<{
    uploaded_documents: OptionInterface[]
    [RETURN_AS_KEY]?: DocumentInterface
  }>()
  const user = useSelector(selectUser)

  // This hack puts returned value into form, because returnAs does not support arrays
  useEffect(() => {
    if (values[RETURN_AS_KEY]) {
      if (!values.uploaded_documents) {
        values.uploaded_documents = []
      }
      values.uploaded_documents.push(values[RETURN_AS_KEY]!)
      delete values[RETURN_AS_KEY]
    }
  }, [values[RETURN_AS_KEY]])

  const employeeDocSelector = useCallback(async () => {
    if (employee) {
      const result = await getEmployeeDocumentNames({}, employee.id)
      if (result?.data) {
        return result.data.options.map(doc => ({ id: doc.id, name: doc.name }))
      }
    }

    return []
  }, [employee?.id])

  const handleNewRow = (e: React.MouseEvent) => {
    e.preventDefault()
    navigateTo(pathToUrl(ROUTES.FORMS.DOCUMENT.GENERAL, { employeeId: employee?.id }), {
      returnAs: RETURN_AS_KEY,
      initialValues: {
        employee,
        issuer: { id: user.id, name: user.full_name },
        ...(initialValues || {}),
      },
    })
  }

  if (!employee) {
    return null
  }

  return (
    <Grid gap={24} flow="column">
      <LapeNewMultiSelect
        name="uploaded_documents"
        placeholder="Link document"
        selector={employeeDocSelector}
        data-testid="uploaded_documents"
        {...props}
      />
      <UploadLink onClick={handleNewRow}>Upload new document</UploadLink>
    </Grid>
  )
}

export default EmployeeDocSectionLape
