import React from 'react'
import capitalize from 'lodash/capitalize'
import { chain, SelectOptionItemType, Text } from '@revolut/ui-kit'

import { EmployeeContractType } from '@src/interfaces/employees'

export const ContractTypeOption = ({
  option,
}: {
  option: SelectOptionItemType<EmployeeContractType>
}) => {
  return (
    <>
      {chain(
        <Text>{option.value.name}</Text>,
        <Text color="grey-tone-50">
          {option.value.employee_type ? capitalize(option.value.employee_type) : ''}
        </Text>,
      )}
      <Text use="p" variant="caption" color="grey-tone-50">
        {option.value.description ? capitalize(option.value.description) : ''}
      </Text>
    </>
  )
}
