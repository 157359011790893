import { IntegrationPostingStatuses, LinkedInPosting } from '@src/interfaces/jobPosting'

export const isRejected = ({ status }: LinkedInPosting) =>
  status === IntegrationPostingStatuses.rejected

export const isPending = ({ status }: LinkedInPosting) =>
  status === IntegrationPostingStatuses.pending

export const isPublished = ({ status }: LinkedInPosting) =>
  status === IntegrationPostingStatuses.published

export const isPromoted = ({ is_promoted, status }: LinkedInPosting) =>
  is_promoted && status === IntegrationPostingStatuses.published

export const isBasic = ({ is_promoted, status }: LinkedInPosting) =>
  !is_promoted && status === IntegrationPostingStatuses.published

export const isClosed = ({ status }: LinkedInPosting) =>
  status === IntegrationPostingStatuses.closed

export const isLocationError = ({ status_detail }: LinkedInPosting) =>
  status_detail ===
  'Insufficient data for office type location. Please ensure that city and country are provided for the location and try publishing again.'
