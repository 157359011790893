import { EntityPermissions } from '@src/store/auth/types'
import { FieldOptions } from '@src/interfaces'

export type EmployeeProfileSectionKey = 'name' | 'position' | 'organisation' | 'notes'

export interface SectionInterface {
  field_options: FieldOptions
}

export const canEditSection = (section?: SectionInterface) => {
  const permissions = section?.field_options?.permissions
  return Boolean(
    permissions?.includes(EntityPermissions.CanEditAllFieldsInSection) ||
      permissions?.includes(EntityPermissions.CanEditFieldsInSection),
  )
}

export const canRequestChangeInSection = (section?: SectionInterface) =>
  !!section?.field_options?.permissions?.includes(
    EntityPermissions.CanRequestChangesInSection,
  )

export type SectionPermissions = {
  canRequestChange?: boolean
  canEdit?: boolean
}
export const getSectionPermissions = (
  enabled: boolean,
  section?: SectionInterface,
): SectionPermissions => {
  const canRequestChange = enabled && canRequestChangeInSection(section)
  const canEdit = canEditSection(section)
  return { canRequestChange, canEdit }
}

export const getProfileSectionFields = (section: EmployeeProfileSectionKey) => {
  switch (section) {
    case 'name':
      return [
        'first_name',
        'middle_name',
        'last_name',
        'display_name',
        'email',
        'custom_fields',
      ]
    case 'position':
      return [
        'contract_type',
        'employee_type',
        'status',
        'specialisation',
        'seniority',
        'specialisation_seniority_sublevel',
        'job_title',
        'location',
        'entity',
        'joining_date_time',
        'end_of_probation_date_time',
        'termination_date_time',
        'custom_fields',
      ]
    case 'organisation':
      return ['team', 'line_manager', 'quality_control', 'mentor']
    case 'notes':
      return [
        'info',
        'work_authorisation_status',
        'relocation_type',
        'is_regulated',
        'custom_fields',
      ]
    default:
      return []
  }
}
