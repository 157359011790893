import { EngagementResultsEntities } from '@src/interfaces/engagement'

export const mapTableTypeToStatEntity = (
  tableType: 'categories' | 'questions' | 'comments' | undefined,
): EngagementResultsEntities => {
  switch (tableType) {
    case 'categories':
      return EngagementResultsEntities.Drivers
    case 'comments':
      return EngagementResultsEntities.Feedback
    case 'questions':
      return EngagementResultsEntities.Questions
    default:
      // default redirect goes to drivers
      return EngagementResultsEntities.Drivers
  }
}
