import React from 'react'
import EmploymentPoliciesTable from '@src/apps/General/DataRetention/EmploymentPoliciesTable'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

export const DataRetention = () => {
  return (
    <>
      <PageHeader
        backUrl={pathToUrl(ROUTES.SETTINGS.EMPLOYEES.GENERAL)}
        subtitle="Configure Data Retention Policy for Employee's locations"
        title="Data Retention Settings"
      />
      <EmploymentPoliciesTable />
    </>
  )
}
