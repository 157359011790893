import React, { ReactNode } from 'react'
import { Avatar, IconName, Item, Token } from '@revolut/ui-kit'

interface Props {
  title: ReactNode
  subtitle: ReactNode
  actionElement?: ReactNode
  iconName: IconName
}

export const PreviewSectionHeader = ({
  title,
  subtitle,
  actionElement,
  iconName,
}: Props) => {
  return (
    <Item p="s-4">
      <Item.Avatar>
        <Avatar color={Token.color.accent} useIcon={iconName} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        <Item.Description>{subtitle}</Item.Description>
      </Item.Content>
      <Item.Side>
        <Item.Value>{actionElement}</Item.Value>
      </Item.Side>
    </Item>
  )
}
