import React, { useState } from 'react'
import { endOfYear, format, startOfYear } from 'date-fns'
import { Text, Token } from '@revolut/ui-kit'

import { Calendar } from '@src/features/Calendar/Calendar'
import { useEmployeeTimeOffCalendar } from '@src/api/timeOff'
import { TimeOffTableMonthCell } from '@src/features/TimeOffCalendarTable/TimeOffTableMonthCell'
import { DayBox } from '@src/features/Calendar/Day'
import { toIdAndName } from '@src/utils/toIdAndName'
import { END_DATE_FILTER_KEY, START_DATE_FILTER_KEY } from './constants'

const defaultFormat = 'yyyy-MM-dd'

type Props = {
  employeeId: string | number
  canViewPolicy?: boolean
}
export const EmployeeTimeOffCalendar = ({ employeeId, canViewPolicy }: Props) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  const startDateFilter = format(startOfYear(currentDate), defaultFormat)
  const endDateFilter = format(endOfYear(currentDate), defaultFormat)

  const { data: calendarData } = useEmployeeTimeOffCalendar(employeeId, {
    filters: [
      {
        filters: [toIdAndName(startDateFilter)],
        columnName: START_DATE_FILTER_KEY,
        nonResettable: true,
      },
      {
        filters: [toIdAndName(endDateFilter)],
        columnName: END_DATE_FILTER_KEY,
        nonResettable: true,
      },
    ],
  })
  const [employeeCalendarData] = calendarData || []

  const renderDay = (day: Date) => {
    if (!employeeCalendarData) {
      return null
    }

    return (
      <TimeOffTableMonthCell
        day={day}
        requestsCalendar={employeeCalendarData}
        emptyRequestBody={
          <DayBox withBorder>
            <Text color={Token.color.greyTone50}>{format(day, 'd')}</Text>
          </DayBox>
        }
        nonWorkingBody={
          <DayBox withBackground>
            <Text color={Token.color.greyTone20}>{format(day, 'd')}</Text>
          </DayBox>
        }
        canViewPolicy={canViewPolicy}
      />
    )
  }

  return (
    <Calendar
      isLoading={!employeeCalendarData}
      renderDay={renderDay}
      onCurrentDateChange={newDate => setCurrentDate(newDate)}
    />
  )
}
