import React, { MouseEvent, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import { isBefore, isSameDay } from 'date-fns'
import { Widget } from '@revolut/ui-kit'

import { useGetPerformanceSelector } from '@src/api/performance'
import {
  PerformanceSelector,
  PerformanceSidebarManager,
  ReviewCategory,
} from '@src/interfaces/performance'
import { OptionInterface } from '@src/interfaces/selectors'
import { useQuery } from '@src/utils/queryParamsHooks'
import { upwardsReviewRequests } from '@src/api/performanceReview'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeInterface } from '@src/interfaces/employees'
import PerformanceReviewPopup from '@src/features/Popups/PerformanceReviewPopup'
import { Statuses } from '@src/interfaces'
import {
  checkCanRequestFeedback,
  checkIsProbationPeriod,
  isNewPerformancePeriod,
  NewFlowRequestsResponse,
  useFetcherPerformanceRequests,
} from '../Common/utils'
import RequestFeedback from '../PerformanceReview/RequestFeedback'
import SummarySidebar from '../Summary/SummarySidebar'
import PerformanceCycleSelect from '../Common/PerformanceCycleSelect'
import PerformanceActions from '../Common/PerformanceActions'
import UpwardsReviews from './UpwardsReviews'
import { defaultTheme } from '@src/styles/theme'

const Wrap = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 676px auto;
`

const Content = styled.div``
const Side = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: ${defaultTheme.zIndex.tooltip};
  height: 100%;
`

type RequestsResponseType = NewFlowRequestsResponse | undefined

export interface UpwardsFormProps {
  data: EmployeeInterface
}

const UpwardsReview = ({ data }: UpwardsFormProps) => {
  const { query } = useQuery(true)
  const queryClient = useQueryClient()

  const { data: performanceSelectorData } = useGetPerformanceSelector(data.id)
  const [selectedPeriod, setSelectedPeriod] = useState<PerformanceSelector>()
  const [performanceSelector, setPerformanceSelector] = useState<PerformanceSelector[]>()
  const [submitReviewDisabledReason, setSubmitReviewDisabledReason] = useState<
    string | null
  >('You are not allowed to submit review at this time')
  const [userReviewStatus, setUserReviewStatus] = useState<Statuses | null | undefined>(
    null,
  )
  const [showWelcomePopup, setShowWelcomePopup] = useState(false)
  const [sidebarOpen, setSidebarOpen] = useState<PerformanceSidebarManager>(
    PerformanceSidebarManager.SummaryOpened,
  )

  const canRequestFeedback = checkCanRequestFeedback(data)
  const isProbationPeriod = checkIsProbationPeriod(data)

  const category = ReviewCategory.Upwards

  const requestsContext = useFetcherPerformanceRequests({
    category,
    isNewFlow: true,
    id: data.id,
    performanceCycle: selectedPeriod,
  })

  const { data: requestsData, isLoading: fetchingRequests } = requestsContext

  const requests = (requestsData as RequestsResponseType)?.results

  useEffect(() => {
    setUserReviewStatus(
      (requestsData as RequestsResponseType)?.requester_upwards_review_status,
    )
  }, [requests])

  const fetchPerformanceSelector = () => {
    if (performanceSelectorData) {
      let selectors = performanceSelectorData.filter(
        selector =>
          isNewPerformancePeriod(selector) &&
          isBefore(new Date(selector.start_date_time), new Date()),
      )

      const changeSelectedPeriod =
        !!selectedPeriod && !selectors.find(selector => selector.id === selectedPeriod.id)

      if ((!selectedPeriod || changeSelectedPeriod) && !isProbationPeriod) {
        if (query.cycle_id) {
          setSelectedPeriod(
            selectors.find(selector => {
              return `${selector.id}` === query.cycle_id
            }),
          )
        } else {
          setSelectedPeriod(
            selectors.find(selector => selector.performance_reviews_selected_cycle),
          )
        }
      }
      return selectors as OptionInterface[]
    }

    return []
  }

  const checkAddReviewAvailability = () => {
    if (!selectedPeriod) {
      setSubmitReviewDisabledReason('Please select review cycle')
      return
    }

    if (selectedPeriod.requester_can_submit_upwards_review) {
      setSubmitReviewDisabledReason(null)

      const now = new Date()
      const startDate = new Date(selectedPeriod.review_period_start_day)
      if (isBefore(now, startDate) && !isSameDay(now, startDate)) {
        setSubmitReviewDisabledReason('This performance cycle has not started yet')
      } else {
        setSubmitReviewDisabledReason(null)
      }

      return
    }

    setSubmitReviewDisabledReason('You are not allowed to submit review at this time')
  }

  const onAddRequest = () => {
    queryClient.invalidateQueries()
  }

  useEffect(() => {
    const fetchSelector = () => {
      const result = fetchPerformanceSelector()
      setPerformanceSelector(result as PerformanceSelector[])
    }
    fetchSelector()
  }, [category, performanceSelectorData])

  useEffect(() => {
    checkAddReviewAvailability()
  }, [selectedPeriod, category])

  const onClickViewReview = () => {
    if (sidebarOpen === PerformanceSidebarManager.SummaryOpened) {
      setSidebarOpen(PerformanceSidebarManager.Closed)
    } else {
      setSidebarOpen(PerformanceSidebarManager.SummaryOpened)
    }
  }

  const onClickRequestReview = () => {
    if (sidebarOpen === PerformanceSidebarManager.RequestOpened) {
      setSidebarOpen(PerformanceSidebarManager.Closed)
    } else {
      setSidebarOpen(PerformanceSidebarManager.RequestOpened)
    }
  }

  const navigateToReview = async () => {
    if (!selectedPeriod) {
      return
    }

    const resp = await upwardsReviewRequests.submit(
      {
        reviewed_employee: data,
        cycle: {
          id: selectedPeriod?.id,
        },
      },
      {},
    )

    navigateTo(
      pathToUrl(ROUTES.FORMS.UPWARDS_REVIEW_LAYOUT, {
        employeeId: data.id,
        id: resp.data.id,
      }),
    )
  }

  const handleAddReviewButtonClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()

    if (!selectedPeriod) {
      return
    }

    // const isPopupSeen = localStorage.getItem(COOKIE.UPWARDS_INFO_SEEN)
    //
    // if (!isPopupSeen) {
    //   setShowWelcomePopup(true)
    //   return
    // }

    await navigateToReview()
  }

  if (showWelcomePopup) {
    return (
      <PerformanceReviewPopup
        name={data?.display_name}
        firstName={data?.first_name}
        jobTitle={data?.job_title}
        onClose={() => setShowWelcomePopup(false)}
        onSubmit={navigateToReview}
        category={category}
      />
    )
  }

  return (
    <Wrap>
      <Content>
        <Widget p="s-16">
          <PerformanceCycleSelect
            selectedPeriod={selectedPeriod}
            performanceSelector={performanceSelector}
            onChange={selector => setSelectedPeriod(selector)}
          />
          <PerformanceActions
            submitReviewDisabledReason={submitReviewDisabledReason}
            onClickAddReview={handleAddReviewButtonClick}
            onClickViewReview={onClickViewReview}
            onClickRequestReview={onClickRequestReview}
            userReviewStatus={userReviewStatus}
            disabled={!selectedPeriod}
            canRequestFeedback={canRequestFeedback}
            canAddReview
          />
          {selectedPeriod && (
            <UpwardsReviews fetching={fetchingRequests} requests={requests} />
          )}
        </Widget>
      </Content>
      <Side>
        {selectedPeriod && sidebarOpen === PerformanceSidebarManager.RequestOpened && (
          <RequestFeedback
            isNewFlow
            canRequest={canRequestFeedback}
            performanceCycle={selectedPeriod}
            category={category}
            onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
            id={data.id}
            requests={requests}
            onAddRequest={onAddRequest}
            fetching={fetchingRequests}
          />
        )}
      </Side>
      <SummarySidebar
        isOpen={Boolean(
          selectedPeriod && sidebarOpen === PerformanceSidebarManager.SummaryOpened,
        )}
        cycleId={selectedPeriod?.id !== undefined ? String(selectedPeriod.id) : undefined}
        employeeId={data.id}
        cycleName={selectedPeriod?.name}
        category={category}
        onClose={() => setSidebarOpen(PerformanceSidebarManager.Closed)}
      />
    </Wrap>
  )
}

export default UpwardsReview
