import React from 'react'
import { CellTypes, ColumnInterface, FilterType } from '../../interfaces/data'
import { FeedbackInterface } from '../../store/feedback/types'
import { selectorKeys } from '../api'
import { FeedbackInput } from '../../components/Inputs/FeedbackInput/FeedbackInput'
import { Status } from '../../components/CommonSC/General'
import { Statuses } from '../../interfaces'

export const feedbackEmail: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'user_email',
  dataPoint: 'user_email',
  sortKey: 'user_email',
  filterKey: 'user_email',
  selectorsKey: selectorKeys.automatic,
  title: 'Email',
}

export const feedbackUserID: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'user_uuid',
  dataPoint: 'user_uuid',
  sortKey: 'user_uuid',
  filterKey: 'user_uuid',
  selectorsKey: selectorKeys.automatic,
  title: 'User ID',
  insert: ({ data }) => <>{data.user_uuid}</>,
}

export const feedbackScore: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'score',
  dataPoint: 'score',
  sortKey: 'score',
  filterKey: 'score',
  selectorsKey: selectorKeys.none,
  filterType: FilterType.range,
  title: 'Score',
  insert: ({ data }) => {
    if (data.score) {
      return <FeedbackInput disabled value={data.score} onChange={() => {}} />
    }

    return '-'
  },
}

export const feedbackStatus: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.insert,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.user_feedback_statuses,
  title: 'Status',
  insert: ({ data }) => {
    return <Status status={data.status.id as Statuses}>{data.status.id}</Status>
  },
}

export const feedbackDate: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.date,
  idPoint: 'creation_date_time',
  dataPoint: 'creation_date_time',
  sortKey: 'creation_date_time',
  filterKey: 'creation_date_time',
  selectorsKey: selectorKeys.none,
  title: 'Date',
}

export const feedbackText: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'text',
  dataPoint: 'text',
  sortKey: 'text',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Text',
}

export const feedbackTopic: ColumnInterface<FeedbackInterface> = {
  type: CellTypes.text,
  idPoint: 'topic.id',
  dataPoint: 'topic.name',
  sortKey: 'topic',
  filterKey: 'topic',
  selectorsKey: selectorKeys.user_feedback_topics,
  title: 'Topic',
}
