import React, { useState } from 'react'
import { Button, MoreBar, StatusPopup } from '@revolut/ui-kit'
import { Reverted } from '@revolut/icons'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { cancelOffer } from '@src/api/offerCreation'
import { SigningStatuses } from '@src/interfaces/offerCreation'

type Props = {
  id: number
  signingStatus?: SigningStatuses
  onRefresh: () => void
  onArchive: () => void
}

const CancelOfferButton = ({ id, signingStatus, onRefresh, onArchive }: Props) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState<boolean | null>(null)

  const onConfirm = async () => {
    setLoading(true)

    try {
      await cancelOffer(id)
      setSuccess(true)
    } catch {
      setSuccess(false)
    } finally {
      setOpenConfirmation(false)
      setLoading(false)
    }
  }

  const onClose = () => {
    setSuccess(null)
    onRefresh()
  }

  return (
    <>
      <ConfirmationDialog
        open={openConfirmation}
        onClose={() => setOpenConfirmation(false)}
        onReject={() => setOpenConfirmation(false)}
        onConfirm={onConfirm}
        loading={loading}
        label="Cancel offer"
        body={
          signingStatus === 'offer_signed'
            ? "This action will mark offer as declined to free up the requisition. This won't cancel offer on the DocuSign, since it has already been signed by the candidate"
            : "Candidate won't be able to sign it any more. You still will be able to send a new one."
        }
        yesMessage={
          signingStatus === 'offer_signed' ? 'Mark as declined' : 'Cancel offer'
        }
        noMessage="Go back"
      />
      <StatusPopup
        variant="error"
        open={success === false}
        onClose={() => {
          setSuccess(null)
        }}
      >
        <StatusPopup.Title>Something went wrong</StatusPopup.Title>
        <StatusPopup.Description>
          Please refresh the page and try again
        </StatusPopup.Description>
      </StatusPopup>
      <StatusPopup
        variant="success-result"
        open={success === true}
        // @ts-expect-error
        labelButtonClose="Close success popup"
        onClose={onClose}
      >
        <StatusPopup.Title>Offer successfully cancelled</StatusPopup.Title>
        <StatusPopup.Description>
          In case offer was declined, please proceed to archiving this candidate
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button onClick={onClose}>Go back</Button>
          <Button variant="secondary" onClick={onArchive}>
            Archive candidate
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>
      <MoreBar.Action
        useIcon={Reverted}
        variant="negative"
        onClick={() => setOpenConfirmation(true)}
      >
        Cancel offer
      </MoreBar.Action>
    </>
  )
}

export default CancelOfferButton
