import { FieldOptions, IdAndName, Statuses } from '.'
import { ApprovalStatuses } from './approvalFlow'
import { EmployeeOptionInterface } from './employees'
import { PublicHolidayCalendarItem } from '@src/interfaces/timeOff'

export type SchedulingPolicyConfigurationStep = 'completed' | 'shifts'

export type SchedulingPolicyStatus = Statuses.draft | Statuses.active | Statuses.archived

export type AttendanceApprovalStatus = ApprovalStatuses | 'none'

export interface SchedulingPolicyInterface {
  id: number
  name: string
  location: IdAndName & { location_name: string }
  eligibility_groups: IdAndName[]
  owner: EmployeeOptionInterface
  daily_nominal_hours: number
  weekly_nominal_hours: number
  consecutive_working_days_limit: number
  maximum_daily_overtime: number
  maximum_weekly_overtime: number
  maximum_monthly_overtime: number
  maximum_yearly_overtime: number
  accounting_period: IdAndName
  toil_policy: {
    id: number
    name: string
    category: IdAndName
    paid: IdAndName
    unit: IdAndName
    details_url: string | null
  }
  status: IdAndName<SchedulingPolicyStatus>
  configuration_step: IdAndName<SchedulingPolicyConfigurationStep>
  unsocial_time_start: string
  unsocial_time_end: string
  work_default_start_time: string
  work_default_end_time: string
  creation_date_time: string
  minimum_rest_period?: number
  default_work_time_editable: boolean
  allow_regular_work_on_non_working_days: boolean
  notification_threshold_overtime?: number
}

export interface SchedulingPolicyTableInterface {
  id: number
  creation_date_time: string
  eligibility_groups: IdAndName[]
  location: IdAndName
  name: string
  owner: EmployeeOptionInterface
  status: IdAndName<SchedulingPolicyStatus>
  total_members: number
  total_shifts: number
}

export interface SchedulePolicyShiftInterface {
  id: number
  additional_information: string
  compensation_level: number | null
  compensation_type: IdAndName
  day_period: IdAndName
  end: string
  minimum_hours: number
  name: string
  paid_shift: boolean
  requires_approval: boolean
  start: string
  status: IdAndName<SchedulingPolicyStatus>
  type: IdAndName
  week_period: IdAndName
}

export interface EmployeesDefaultHours {
  default_start_time: string
  default_end_time: string
  id?: number
  field_options: FieldOptions
}

interface TimeRangeInterface {
  start: string
  end: string
  duration_hours: number
  duration_minutes: number
}

type SubShiftType = 'regular' | 'overtime' | 'unsocial_time'

interface SubShiftInterface {
  time_range: TimeRangeInterface
  type: IdAndName<SubShiftType>
}

export interface EmployeeShiftInterface {
  approval_status: IdAndName<ApprovalStatuses> | null
  id: number
  sub_shifts?: SubShiftInterface[]
  time_range: TimeRangeInterface | null
  type: IdAndName<'time_off' | 'work' | 'break'>
  category?: string
}

export interface ApprovalsInfoInterface {
  count: number
  pending: number
  approved: number
  rejected: number
}

export interface EmployeeShiftDetailsInterface {
  approvals_info: ApprovalsInfoInterface
  date: string
  duration_hours: number
  duration_minutes: number
  children: EmployeeShiftInterface[]
  time_range: TimeRangeInterface
  employee: EmployeeOptionInterface
}

export interface EmployeeShiftsStatsInterface {
  non_working_days: string[]
  public_holidays: PublicHolidayCalendarItem[]
  stats: {
    work: {
      hours: number
      minutes: number
    }
    time_off: {
      days: number
      hours: number
      minutes: number
    }
    overtime: {
      hours: number
      minutes: number
    }
  }
}

export interface ShiftBreakdownInterface {
  time_range: TimeRangeInterface
  configuration: SchedulePolicyShiftInterface & { policy_id: number }
}

export type Compensation = IdAndName<'toil' | 'remuneration'>

export interface ScheduleShiftInterface {
  id?: number
  approval_status: IdAndName<AttendanceApprovalStatus>
  breakdowns: ShiftBreakdownInterface[]
  start_time: string
  end_time: string
  preferred_compensation: Compensation | null
  date: string
  duration_hours: number
  duration_minutes: number
  overtime_hours: number
  overtime_minutes: number
  unsocial_hours: number
  unsocial_minutes: number
  requires_approval: boolean
  field_options?: FieldOptions
}

export interface ShiftOverviewInterface {
  compensation_allowed: boolean
  approval_required: boolean
  toil_allowed: boolean
  errors: string[]
  shift: EmployeeShiftInterface
}

export type DefaultWorkingHourRequirementsResponse = Record<
  string,
  {
    message: string
    valid: boolean
  }
>
export interface EmployeeShiftDetails {
  date: string
  approvals_info: ApprovalsInfoInterface
  summaries: EmployeeShiftDetailsInterface[]
}

export interface EmployeeShiftApprovalInfoResponse {
  date: string
  approvals_info: ApprovalsInfoInterface
}

export enum EmployeeShiftChildrenType {
  DirectReportShifts = 'direct_report_shifts',
  TeamShifts = 'team_shifts',
}

export interface EmployeeShiftApprovalInfoData extends EmployeeShiftApprovalInfoResponse {
  children_type: EmployeeShiftChildrenType
  children: (EmployeeShiftDetailsInterface | null)[]
  renderKey: number
}

export interface ScheduleShiftSummaryInterface {
  id: number
  date: string
  start_time: string
  end_time: string
  duration_hours: number
  duration_minutes: number
  regular_hours: number
  regular_minutes: number
  overtime_hours: number
  overtime_minutes: number
  unsocial_hours: number
  unsocial_minutes: number
  approval_status: IdAndName<AttendanceApprovalStatus>
  employee: EmployeeOptionInterface
  team: IdAndName
  location: IdAndName
  /** Helper prop for table, not in the response */
  actions: unknown
}

export interface ScheduleShiftStatsInterface {
  approved: number
  pending: number
  rejected: number
  total: number
}

export interface ScheduleShiftBreakdownInterface {
  shift_id: number
  date: string
  start_time: string
  end_time: string
  duration_hours: number
  duration_minutes: number
  employee: EmployeeOptionInterface
  line_manager: EmployeeOptionInterface
  team: IdAndName
  location: IdAndName
  type: IdAndName<SubShiftType>
  compensation_level: string
  preferred_compensation: Compensation | null
  approval_status: IdAndName<AttendanceApprovalStatus>
}

export interface ScheduleShiftBreakdownStatsInterface {
  approved: number
  pending: number
  rejected: number
  total: number
}
