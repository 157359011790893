import React from 'react'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import { CommunicationGroup } from './General'
import { communicationGroupsFormRequests } from '@src/api/communicationGroups'

export const CommunicationGroupView = connect(() => (
  <Form api={communicationGroupsFormRequests}>
    <CommunicationGroup />
  </Form>
))
