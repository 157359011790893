import React from 'react'
import { Box, InputGroup, Subheader } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { useGetLocationsByIds } from '@src/api/locations'
import {
  JobPostingSalaryBandInterface,
  JobPostingSalaryBandsByLocationInterface,
} from '@src/interfaces/jobPosting'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { LocationInterface } from '@src/interfaces/locations'
import InputsSkeleton from '@src/components/Skeletons/InputsSkeleton'
import { FormError } from '@src/features/Form/LapeForm'
import { getPostingPeriodLabel } from '@src/components/LocationCompensationBand/CompensationLocationItem'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

type ManualCompensationBandFormProps = {
  locationIds: number[]
  values?: JobPostingSalaryBandsByLocationInterface
  errors?: FormError<JobPostingSalaryBandsByLocationInterface>
  onChange: (salaryBandsByLocation: JobPostingSalaryBandsByLocationInterface) => void
}

const defaultCompensationPeriod = 'yearly'

const defaultCurrency = {
  name: 'GBP - British Pound Sterling',
  iso_code: 'GBP',
  symbol: '£',
}

const ManualCompensationBandForm = ({
  locationIds,
  values,
  errors,
  onChange,
}: ManualCompensationBandFormProps) => {
  const { data: locations, isLoading } = useGetLocationsByIds(locationIds)

  const handleChange = (
    location: LocationInterface,
    salaryBand: Partial<JobPostingSalaryBandInterface>,
  ) => {
    const {
      currency,
      location_name,
      posting_compensation_period: postingCompensationPeriod,
    } = location
    const posting_compensation_period =
      postingCompensationPeriod ?? defaultCompensationPeriod
    const { iso_code, name, symbol } = currency ?? defaultCurrency
    const currSalaryBand = values?.[location_name] ?? {
      lower_band: 0,
      upper_band: 0,
    }
    const lower_band = salaryBand.lower_band ?? currSalaryBand?.lower_band
    const upper_band = salaryBand.upper_band ?? currSalaryBand?.upper_band
    const salaryBandOfLocation = {
      iso_code,
      name,
      posting_compensation_period,
      symbol,
      lower_band,
      upper_band,
    }
    onChange({
      ...values,
      [location_name]: salaryBandOfLocation,
    })
  }

  if (!locationIds?.length) {
    return null
  }

  return (
    <>
      <Box my="-s-8">
        <Subheader variant="nested">
          <Subheader.Title>Manual compensation bands</Subheader.Title>
        </Subheader>
      </Box>
      <InputGroup>
        {isLoading && locationIds?.map(id => <InputsSkeleton key={`location_${id}`} />)}
        {locations?.results.map(location => {
          const { currency, location_name, posting_compensation_period } = location
          const postingCompensationPeriod =
            posting_compensation_period ?? defaultCompensationPeriod
          const { iso_code } = currency ?? defaultCurrency
          const period = getPostingPeriodLabel(postingCompensationPeriod)
          return (
            <Box key={`salary_bands_by_location_inputs_${location_name}`}>
              <InputGroup>
                <RadioSelectInput
                  value={{ id: location_name, name: location_name }}
                  label="Location"
                  disabled
                  message={
                    !currency && (
                      <Box>
                        Currency missing for {location_name}, using default currency of
                        GBP, please set currency in{' '}
                        <InternalLink to={pathToUrl(ROUTES.APPS.LOCATIONS)}>
                          Locations app.
                        </InternalLink>
                      </Box>
                    )
                  }
                />
                <InputGroup variant="horizontal">
                  <LapeNewInput
                    required
                    name={`salary_bands_by_location["${location_name}"].lower_band`}
                    label={`Minimum ${period} salary in ${iso_code}`}
                    errorMessage={errors ? errors[location_name] : null}
                    type="number"
                    onChange={e => {
                      const lower_band = Number(e.currentTarget.value)
                      handleChange(location, { lower_band })
                    }}
                  />
                  <LapeNewInput
                    required
                    name={`salary_bands_by_location["${location_name}"].upper_band`}
                    label={`Maximum ${period} salary in ${iso_code}`}
                    errorMessage={errors ? errors[location_name] : null}
                    type="number"
                    onChange={e => {
                      const upper_band = Number(e.currentTarget.value)
                      handleChange(location, { upper_band })
                    }}
                  />
                </InputGroup>
              </InputGroup>
            </Box>
          )
        })}
      </InputGroup>
    </>
  )
}

export default ManualCompensationBandForm
