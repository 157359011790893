import React from 'react'
import {
  Widget,
  Item,
  Avatar,
  ActionButton,
  Token,
  Toggle,
  Action,
  TransitionCollapse,
  Flex,
  chain,
} from '@revolut/ui-kit'
import { EngagementSurveyInterface } from '@src/interfaces/engagement'
import { FormattedMessage } from 'react-intl'

const INITIAL_QUESTION_DISPLAY_NUMBER = 5

const QuestionsList = ({
  questions,
}: {
  questions: EngagementSurveyInterface['survey_questions']
}) => (
  <Flex flexDirection="column" gap="s-8">
    {questions.map(question => (
      <Item
        key={question.id}
        style={{
          border: `1px solid ${Token.color.greyTone10}`,
          backgroundColor: Token.color.widgetBackground,
        }}
      >
        <Item.Avatar>
          <Avatar useIcon="StarSemi" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>{question.question.question_text}</Item.Title>
          <Item.Description>
            {chain(question.question.driver.name, question.question.type?.name)}
          </Item.Description>
        </Item.Content>
      </Item>
    ))}
  </Flex>
)

export const Questions = ({
  survey_questions,
  onEdit,
}: Pick<EngagementSurveyInterface, 'survey_questions'> & { onEdit: VoidFunction }) => {
  return (
    <Widget>
      <Item>
        <Item.Avatar>
          <Avatar useIcon="Chat" />
        </Item.Avatar>
        <Item.Content>
          <Item.Title>
            {chain(
              <FormattedMessage
                id="engagement.questions.title"
                defaultMessage="Questions"
              />,
              survey_questions.length,
            )}
          </Item.Title>
          <Item.Description>
            <FormattedMessage
              id="engagement.questions.description"
              defaultMessage="What questions will be send to your employees"
            />
          </Item.Description>
        </Item.Content>
        <Item.Side>
          <ActionButton onClick={onEdit}>
            <FormattedMessage id="common.edit" defaultMessage="Edit" />
          </ActionButton>
        </Item.Side>
      </Item>
      <Flex p="s-16" gap="s-8" pt="0" flexDirection="column">
        <Toggle>
          {({ state, toggle }) => (
            <>
              <QuestionsList
                questions={survey_questions.slice(0, INITIAL_QUESTION_DISPLAY_NUMBER)}
              />
              <TransitionCollapse in={state}>
                <QuestionsList
                  questions={survey_questions.slice(INITIAL_QUESTION_DISPLAY_NUMBER)}
                />
              </TransitionCollapse>
              {survey_questions.length > INITIAL_QUESTION_DISPLAY_NUMBER && (
                <Flex justifyContent="center">
                  <Action onClick={() => toggle()}>
                    {state ? 'Collapse' : 'See all'}
                  </Action>
                </Flex>
              )}
            </>
          )}
        </Toggle>
      </Flex>
    </Widget>
  )
}
