import React from 'react'
import {
  InterviewFeedbackInterface,
  InterviewThumbsQuestionInterface,
} from '@src/interfaces/interviewTool'
import { Flex, Text } from '@revolut/ui-kit'
import { Section } from './Section'
import ThumbsButtons from '@components/ThumbsButtons/ThumbsButtons'
import { Grid } from '@components/CommonSC/Grid'
import { getThumbsQuestionPoints } from '@src/utils/interview'
import { setTimeCodeHandler } from '@src/utils/hiring'
import { useLapeContext } from '@src/features/Form/LapeForm'

type Props = {
  data: InterviewThumbsQuestionInterface[]
  name: string
  titleIndexNumber: number
  title: string
  notesName?: string
  subtitle?: string
  isViewMode?: boolean
  isOptional?: boolean
}

const ThumbsCard = ({
  data,
  name,
  isViewMode,
  isOptional,
  title,
  subtitle,
  notesName,
  titleIndexNumber,
}: Props) => {
  const { values } = useLapeContext<InterviewFeedbackInterface>()
  const points = data.reduce((acc, val) => acc + getThumbsQuestionPoints(val), 0) || 0

  return (
    <Section
      index={titleIndexNumber}
      title={title}
      subtitle={subtitle}
      points={points}
      isViewMode={isViewMode}
      isOptional={isOptional}
      notesName={notesName}
      onAddTimeCode={setTimeCodeHandler(notesName, values)}
    >
      <Grid gap={18}>
        {data.map((option, id) => (
          <Flex alignItems="start" justifyContent="space-between" key={id}>
            <Text pr="s-16">{option.question}</Text>
            <ThumbsButtons name={`${name}.${id}.answer`} readOnly={isViewMode} />
          </Flex>
        ))}
      </Grid>
    </Section>
  )
}

export default ThumbsCard
