import React, { useState } from 'react'
import { Box, Group, MoreBar, Subheader } from '@revolut/ui-kit'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { PageBody } from '@src/components/Page/PageBody'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { ROUTES } from '@src/constants/routes'
import { EntityPermissions, PermissionTypes } from '@src/store/auth/types'
import { RegimeInterface } from '@src/interfaces/timeOff'
import {
  getTimeOffRegimeMemberships,
  refreshTimeOffRegimes,
  timeOffRegimesRequests,
} from '@src/api/timeOff'
import AdjustableTable from '@src/components/Table/AdjustableTable'
import { useTable } from '@src/components/Table/hooks'
import {
  regimeMembershipCreationTimeColumn,
  regimeMembershipEmployeeColumn,
} from '@src/constants/columns/timeOff'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { EditButton } from '@src/features/SettingsButtons/EditButton/EditButton'
import { pathToUrl } from '@src/utils/router'
import { TableNames } from '@src/constants/table'

const membershipRow = {
  cells: [
    {
      ...regimeMembershipEmployeeColumn,
      width: 420,
    },
    {
      ...regimeMembershipCreationTimeColumn,
      width: 300,
    },
  ],
}

const Preview = () => {
  const { values, initialValues } = useLapeContext<RegimeInterface>()

  const [refreshMembershipPending, setRefreshMembershipPending] = useState(false)

  const canEdit =
    values.field_options.actions?.includes(EntityPermissions.Change) &&
    values.field_options.permissions?.includes(PermissionTypes.ChangeTimeOffRegime)
  const canDelete =
    values.field_options.actions?.includes(EntityPermissions.Delete) &&
    values.field_options.permissions?.includes(PermissionTypes.DeleteTimeOffRegime)

  const membershipTable = useTable({ getItems: getTimeOffRegimeMemberships(values.id) })

  const onMembershipRefresh = async () => {
    setRefreshMembershipPending(true)

    refreshTimeOffRegimes(values.id)
      .then(() => membershipTable.refresh())
      .finally(() => setRefreshMembershipPending(false))
  }

  return (
    <>
      <PageHeader
        pb="s-8"
        title={initialValues?.name}
        backUrl={ROUTES.SETTINGS.TIME_OFF.REGIMES}
      />

      <PageBody>
        <SettingsButtons mb="s-32">
          {canEdit && (
            <EditButton
              route={pathToUrl(ROUTES.FORMS.TIME_OFF_REGIMES.GENERAL, { id: values.id })}
            />
          )}
          {canDelete && (
            <DeleteButton
              title="regime"
              deleteApi={timeOffRegimesRequests.delete!}
              backUrl={ROUTES.SETTINGS.TIME_OFF.REGIMES}
            />
          )}
          <MoreBar.Action
            onClick={onMembershipRefresh}
            pending={refreshMembershipPending}
          >
            Refresh memberships
          </MoreBar.Action>
        </SettingsButtons>

        <FormPreview data={initialValues}>
          <Group>
            <FormPreview.Item title="Name" field="name" />
            <FormPreview.Item<RegimeInterface>
              title="Groups"
              insert={regime => regime.groups?.map(group => group.name).join(', ')}
            />
            <FormPreview.Item title="Default approver" field="default_approver.name" />
            {initialValues.default_approver?.id === 'dynamic_group' && (
              <FormPreview.Item
                title="Default approver group"
                field="default_approver_group.name"
              />
            )}
            <FormPreview.Item
              title="Working days start"
              field="working_days_start.name"
            />
            <FormPreview.Item title="Working days end" field="working_days_end.name" />
            <FormPreview.Item title="Public holidays" field="public_holidays.name" />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Memberships</Subheader.Title>
          </Subheader>
          <AdjustableTable
            name={TableNames.TimeOffRegimes}
            row={membershipRow}
            {...membershipTable}
            useWindowScroll
          />
        </Box>
      </PageBody>
    </>
  )
}

export default Preview
