import { MoreBar, chain } from '@revolut/ui-kit'
import React from 'react'
import pluralize from 'pluralize'

type Props = {
  onClick: () => void
  feedbackCount: number
  title?: string
}

export const AnytimeFeedbackSidebarButton = ({
  onClick,
  feedbackCount,
  title,
}: Props) => {
  return (
    <MoreBar.Action onClick={onClick} useIcon="Chat">
      {chain(title || `Show ${pluralize('feedback', feedbackCount)}`, feedbackCount)}
    </MoreBar.Action>
  )
}
