import React, { MouseEvent, useState } from 'react'
import { ActionButton, ActionButtonProps, TextButton } from '@revolut/ui-kit'
import omit from 'lodash/omit'

interface OnLoadingButtonProps extends ActionButtonProps {
  onSubmit: () => Promise<any>
  useTextButton?: boolean
}

const NewLoadingButton = ({ onSubmit, useTextButton, ...rest }: OnLoadingButtonProps) => {
  const [loading, setLoading] = useState(false)
  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    try {
      setLoading(true)
      await onSubmit()
    } finally {
      setLoading(false)
    }
  }

  if (useTextButton) {
    return (
      <TextButton {...omit(rest, 'variant')} disabled={loading} onClick={handleClick} />
    )
  }
  return <ActionButton {...rest} pending={loading} onClick={handleClick} />
}

export default NewLoadingButton
