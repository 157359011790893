import React from 'react'
import useCandidatePipelineData from '@src/pages/Forms/JobPosting/Components/useCandidatePipelineData'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import CommonCandidatesTable from '@src/features/CommonCandidatesTable/CommonCandidatesTable'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { StatusWidget } from '@revolut/ui-kit'

export const Candidates = () => {
  const { values } = useLapeContext<JobPostingInterface>()
  const { hiringPipeline, loading } = useCandidatePipelineData(values.id)
  if (loading) {
    return <PageLoading />
  }
  if (!hiringPipeline) {
    return (
      <StatusWidget>
        <StatusWidget.Image
          image={{
            default: 'https://assets.revolut.com/assets/3d-images-v2/3D086.png',
            '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@2x.png',
            '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D086@3x.png',
          }}
        />
        <StatusWidget.Title>No candidates found for this job posting</StatusWidget.Title>
      </StatusWidget>
    )
  }
  return <CommonCandidatesTable type="hiringPipeline" data={hiringPipeline} />
}
