import React from 'react'
import { Color, ErrorWidget, Flex, Spinner, Text } from '@revolut/ui-kit'
import { ExclamationTriangle } from '@revolut/icons'
import {
  RunQueryResponseInterface,
  QueryRunStatuses,
  QueryRunResultInterface,
  QueryViewType,
  ChartSettings,
  ReportColumnsTableInterface,
} from '@src/interfaces/dataAnalytics'
import Icon from '@src/components/Icon/Icon'
import { VisualisationResult } from './VisualisationResult'
import { RowInterface } from '@src/interfaces/data'
import { useTableReturnType } from '@components/Table/hooks'

interface QueryResultProps {
  fetchingError: string
  fetchingErrorDescription?: string
  isLoading: boolean
  runStatus?: QueryRunStatuses
  runResponse?: RunQueryResponseInterface
  tab?: QueryViewType
  chartSettings: ChartSettings
  table: useTableReturnType<QueryRunResultInterface>
  tableRows: RowInterface<QueryRunResultInterface>
  tableColumns: ReportColumnsTableInterface[]
}

export const QueryResult = ({
  fetchingError,
  fetchingErrorDescription,
  isLoading,
  runStatus,
  runResponse,
  tab,
  chartSettings,
  table,
  tableRows,
  tableColumns,
}: QueryResultProps) => {
  if (
    runStatus === QueryRunStatuses.Success &&
    runResponse?.id &&
    runResponse?.query?.id
  ) {
    return (
      <VisualisationResult
        runId={runResponse.id}
        queryId={runResponse.query.id}
        tab={tab}
        chartSettings={chartSettings}
        table={table}
        tableRows={tableRows}
        tableColumns={tableColumns}
      />
    )
  }

  const renderResult = () => {
    if (isLoading) {
      return <Spinner size={48} color={Color.BLUE} />
    }
    if (runStatus === QueryRunStatuses.Error || fetchingError) {
      return (
        <ErrorWidget>
          <ErrorWidget.Title>
            <ExclamationTriangle color={Color.RED} />
          </ErrorWidget.Title>
          <ErrorWidget.Description>
            <Text use="p">{runResponse?.detail || fetchingError}</Text>
            {fetchingErrorDescription && <Text>{fetchingErrorDescription}</Text>}
          </ErrorWidget.Description>
        </ErrorWidget>
      )
    }
    return (
      <>
        <Icon type="Graph" color={Color.GREY_20} />
        <Text color={Color.GREY_20}>No data to display</Text>
      </>
    )
  }

  return (
    <Flex alignItems="center" flex="1 0" flexDirection="column" justifyContent="center">
      {renderResult()}
    </Flex>
  )
}
