import { ROUTES, WORKSPACES } from '@src/constants/routes'
import CreateAccount from '@src/pages/SignUp/CreateAccount/CreateAccount'
import CreateCompany from '@src/pages/SignUp/CreateCompany/CreateCompany'
import { WaitingList } from '@src/pages/SignUp/WaitingList/WaitingList'
import { Route, Switch } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import { Absolute, HStack, IconButton, Layout, Token } from '@revolut/ui-kit'
import { signupState, removeSignupStateCookie } from './common'
import { navigateReplace } from '@src/actions/RouterActions'
import Suspended from '@src/pages/SignUp/Suspended/Suspended'
import { SignupRedirect } from './SignupRedirect'
import { logOutAction } from '@src/store/auth/actions'
import { useDispatch } from 'react-redux'
import { isWorkspacesEnabled } from '@src/utils'
import { LoginThemeToggle } from '@src/features/UIKitWithThemeProvider/LoginThemeToggle'
import { useAppTheme } from '@src/features/UIKitWithThemeProvider/UIKitWithThemeProvider'

const LogOutButton = () => {
  const { loginTheme } = useAppTheme()
  const dispatch = useDispatch()

  return (
    signupState.cookie && (
      <IconButton
        onClick={() => {
          removeSignupStateCookie()
          dispatch(logOutAction())
        }}
        useIcon="LogoutDoor"
        color={loginTheme === 'dark' ? Token.color.greyTone50 : Token.color.foreground}
      />
    )
  )
}

const SignupPage = () => {
  const redirected = useRef(false)

  useEffect(() => {
    if (signupState.cookie && !redirected.current) {
      redirected.current = true

      if (signupState.cookie?.state === 'company_details') {
        navigateReplace(ROUTES.SIGNUP.COMPANY_DETAILS)
      } else if (signupState.cookie?.state === 'email_confirmation') {
        navigateReplace(ROUTES.SIGNUP.MAIN, undefined, true)
      } else if (signupState.cookie?.state === 'waiting_list') {
        navigateReplace(ROUTES.SIGNUP.WAITING_LIST)
      } else if (signupState.cookie?.state === 'suspended_or_closed') {
        navigateReplace(ROUTES.SIGNUP.SUSPENDED)
      } else {
        removeSignupStateCookie()
        navigateReplace(isWorkspacesEnabled() ? WORKSPACES.MAIN : ROUTES.LOGIN.MAIN)
      }
    }
  }, [signupState.cookie])

  return (
    <>
      <Absolute zIndex={1} right={50} top={30}>
        <HStack space="s-16">
          <LoginThemeToggle />
          <LogOutButton />
        </HStack>
      </Absolute>

      <Switch>
        <Route exact path={ROUTES.SIGNUP.REDIRECT} component={SignupRedirect} />
        <Route exact path={ROUTES.SIGNUP.WAITING_LIST}>
          <WaitingList />
        </Route>
        <Route>
          <Layout>
            <Layout.Main css={{ position: 'relative' }}>
              <Switch>
                <Route exact path={ROUTES.SIGNUP.MAIN}>
                  <CreateAccount />
                </Route>
                <Route exact path={ROUTES.SIGNUP.COMPANY_DETAILS}>
                  <CreateCompany />
                </Route>
                <Route exact path={ROUTES.SIGNUP.SUSPENDED}>
                  <Suspended />
                </Route>
              </Switch>
            </Layout.Main>
          </Layout>
        </Route>
      </Switch>
    </>
  )
}

export default SignupPage
