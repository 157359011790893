import { Flex, Text, Token } from '@revolut/ui-kit'
import { FinalGrade, ReviewCategory } from '@src/interfaces/performance'
import React from 'react'
import { gradeSettings } from '../utils'
import GradeCircle from '@components/GradeCircle'

interface Props {
  grade: FinalGrade
  periodName: string | undefined
  subTitle: string
  category?: ReviewCategory
}

export const GradeSection = ({ grade, periodName, subTitle, category }: Props) => {
  const avatarColor =
    periodName || gradeSettings?.[grade]?.title === 'Pending'
      ? Token.color.foreground
      : gradeSettings?.[grade]?.color

  const calculatedPeriodName = () => {
    if (category === ReviewCategory.Probation) {
      return 'P'
    }

    if (category === ReviewCategory.PIP) {
      return 'PIP'
    }
    return periodName?.split(' ')[0]
  }

  const progressColor =
    gradeSettings?.[grade]?.title === 'Pending'
      ? Token.color.greyTone8
      : gradeSettings?.[grade]?.color
  return (
    <Flex ml="s-4" gap="s-16" alignItems="flex-end">
      <GradeCircle
        progressColor={progressColor}
        avatarColor={avatarColor}
        periodName={periodName && calculatedPeriodName()}
        gradeSettings={gradeSettings}
        grade={grade}
      />
      <Flex minWidth={110} gap="s-2" mb="s-6" flexDirection="column">
        <Text
          variant="heading3"
          whiteSpace="nowrap"
          color={gradeSettings?.[grade]?.color}
        >
          {gradeSettings?.[grade]?.title}
        </Text>
        <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="caption">
          {subTitle}
        </Text>
      </Flex>
    </Flex>
  )
}
