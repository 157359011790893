import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { api, apiWithoutHandling } from '.'
import {
  ProbationTemplateInterface,
  ProbationTemplatePreviewRequest,
  ProbationTemplatePreviewResponse,
  ProbationTemplateRules,
} from '@src/interfaces/probationTemplate'
import { AxiosPromise } from 'axios'
import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useFetch } from '@src/utils/reactQuery'
import { ProbationTemplateOptionInterface } from '@src/interfaces/employees'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { RevolutersInterface } from '@src/interfaces/revoluters'

export const probationTemplateRequests: RequestInterfaceNew<ProbationTemplateInterface> =
  {
    get: async ({ id }) => api.get(`${API.PROBATION_TEMPLATES}/${id}`, undefined, 'v2'),
    update: async (data, { id }) => {
      return apiWithoutHandling.put(
        `${API.PROBATION_TEMPLATES}/${id}`,
        data,
        undefined,
        'v2',
      )
    },
    submit: async data => {
      return apiWithoutHandling.post(`${API.PROBATION_TEMPLATES}`, data, undefined, 'v2')
    },
  }

export const useGetProbationTemplate = (id: string) => {
  return useFetch<ProbationTemplateInterface>(`${API.PROBATION_TEMPLATES}/${id}`, 'v2')
}

export const useGetProbationTemplates = ({
  ...templateParams
}: { [key in ProbationTemplateRules]?: string | number }) => {
  const { data: performanceSettings } = useGetPerformanceSettings()

  return useFetch<ProbationTemplateOptionInterface[]>(
    `${API.PROBATION_TEMPLATES}/eligible`,
    'v2',
    {
      params: templateParams,
    },
    undefined,
    { enabled: !!performanceSettings?.enable_probation },
  )
}

export const getProbationTemplates = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<ProbationTemplateInterface>
> =>
  api.get(
    API.PROBATION_TEMPLATES,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
    'v2',
  )

export const getEligibleEmployeesRequests = (
  templateId?: number | string,
): TableRequestInterface<RevolutersInterface> => ({
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.PROBATION_TEMPLATES}/${templateId}/eligibleEmployees`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
})

export const applyProbationTemplateToEmployees = (
  templateId: number | string,
  filters: FilterByInterface[],
) =>
  api.post(
    `${API.PROBATION_TEMPLATES}/${templateId}/eligibleEmployees/createCycles`,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    'v2',
  )

export const getProbationTemplatePreview = (data: ProbationTemplatePreviewRequest) =>
  api.get<ProbationTemplatePreviewResponse>(
    `${API.PROBATION_TEMPLATES}${API.TEMPLATES_PREVIEW}`,
    {
      params: {
        goals_setting_week_after_start: data.goals_setting_week_after_start,
        final_decision_week_before_end: data.final_decision_week_before_end,
        num_checkpoints: data.num_checkpoints,
        months: data.months,
      },
    },
    'v2',
  )
