import React from 'react'

import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import {
  RiskControlsInterface,
  RiskIncidentsInterface,
  RiskIndicatorInterface,
  RiskLevel,
  RiskSummaryInterface,
} from '@src/interfaces/risk'
import styled from 'styled-components'
import { A, Capitalize } from '@components/CommonSC/General'
import { IconUrl } from '@components/ColumnInserts/IconLink/IconLink'
import GraphIconChart from '@components/Charts/GraphIconChart/GraphIconChart'
import Icon from '@components/Icon/Icon'
import { getRiskValueGraph } from '@src/api/risk'
import { Box, Token } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import Table from '@components/TableV2/Table'

const TooltipContainer = styled.div`
  padding: 11px;
  width: 300px;
`

export const PercentageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: space-between;
  color: ${Token.color.greyTone20};
  min-height: 28px;
  position: relative;
`

const ValueWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${Token.color.foreground};
`

const mapResidualScoreColor = (color: 'green' | 'amber' | 'red') => {
  switch (color) {
    case 'amber':
      return Token.color.warning
    case 'green':
      return Token.color.success
    case 'red':
      return Token.color.danger
  }

  return undefined
}

export const riskLevelColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface | RiskIndicatorInterface
> = {
  type: CellTypes.text,
  idPoint: 'risk_level',
  dataPoint: 'risk_level',
  sortKey: 'risk_level_order',
  filterKey: 'risk_level',
  selectorsKey: selectorKeys.risk_levels,
  title: 'Priority',
  colors: data => {
    switch (data.risk_level) {
      case RiskLevel.low:
        return Token.color.success
      case RiskLevel.medium:
        return Token.color.warning
      case RiskLevel.high:
        return Token.color.danger
      default:
        return Token.color.foreground
    }
  },
  headerTooltip: (
    <TooltipContainer>
      <p>
        High - The incident needs imediate attention. Please take apropriate action as
        this can have serious consequences at company level. Failure to comply will result
        in bonus reduction.{' '}
      </p>
      <p>
        Medium - The incident has major impact. This may be escalated if action is not
        taken in mentioned time interval.
      </p>
    </TooltipContainer>
  ),
}

export const riskLevelIndicatorsColumn: ColumnInterface<RiskIndicatorInterface> = {
  ...riskLevelColumn,
  selectorsKey: selectorKeys.risk_all_levels,
  headerTooltip: (
    <TooltipContainer>
      <p>
        High - These are breaches of Limit 2 and 3, please click on the risk indicators
        that are breaching and ensure appropriate action plan is in place
      </p>
      <p>
        Medium - These are breaches of Limit 1, please click on the risk indicators to
        ensure appropriate actions in place to avoid a limit 2 breach
      </p>
      <p>Low - These are operating within risk appetite, no immediate action required</p>
    </TooltipContainer>
  ),
}

export const riskCategoryColumn: ColumnInterface<
  RiskSummaryInterface | RiskIndicatorInterface
> = {
  type: CellTypes.text,
  idPoint: 'category',
  dataPoint: 'category',
  sortKey: 'category_order',
  filterKey: 'category',
  selectorsKey: selectorKeys.risk_categories,
  title: 'Category',
  headerTooltip: (
    <TooltipContainer>
      <p>
        KRI - Risk and Compliance Committee approved Key Risk Indicators. They are
        automatic detective controls that help Revolut identify issues before they become
        much larger and costlier.
      </p>
      <p> RAS - Board approved Key Risk Indicators.</p>
      <p>
        Risk Incident - These tell us when something has gone wrong and needs to be fixed.
        The longer the issue isn't fixed, the longer Revolut suffers either financially or
        reputationally. Additionally, the board and regulators have expectations on timely
        response to incidents.
      </p>
    </TooltipContainer>
  ),
}

export const riskSummaryColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface | RiskIndicatorInterface
> = {
  type: CellTypes.text,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Summary',
}

export const riskSeverityColumn: ColumnInterface<RiskIncidentsInterface> = {
  type: CellTypes.insert,
  idPoint: 'severity',
  dataPoint: 'severity',
  sortKey: 'severity',
  filterKey: 'severity',
  selectorsKey: selectorKeys.risk_severities,
  title: 'Severity',
  insert: ({ data }) => <Capitalize>{data.severity?.replace(/_/g, ' ')}</Capitalize>,
  headerTooltip: (
    <TooltipContainer>
      <p>Sev 4 - Escalated to Board/Crisis team</p>
      <p>Sev 3 - Escalated to Executive Risk Committee</p>
      <p>Sev 2 - Escalated to Sub-Committee</p>
      <p>Sev 1 - Escalated to 2LOD(Second Line of Defence) Risk Committee</p>
    </TooltipContainer>
  ),
}

export const riskAssigneeColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface | RiskIndicatorInterface
> = {
  type: CellTypes.insert,
  idPoint: 'assignee.id',
  dataPoint: 'assignee.full_name',
  sortKey: 'assignee__full_name',
  filterKey: 'assignee__id',
  selectorsKey: selectorKeys.employee,
  title: 'Assignee',
  insert: ({ data }) => <Table.EmployeeCell employee={data.assignee} />,
}

export const riskReasonColumn: ColumnInterface<
  RiskSummaryInterface | RiskIndicatorInterface
> = {
  type: CellTypes.insert,
  idPoint: 'reason',
  dataPoint: 'reason_display',
  sortKey: 'reason',
  filterKey: 'reason',
  selectorsKey: selectorKeys.risk_reasons_severities,
  insert: ({ data }) => <A href={data.jira_url}>{data.reason_display}</A>,
  title: 'Reason',
}

export const riskReasonIndicatorColumn: ColumnInterface<RiskIndicatorInterface> = {
  ...riskReasonColumn,
  selectorsKey: selectorKeys.risk_reasons,
}

export const riskSolutionColumn: ColumnInterface<RiskSummaryInterface> = {
  type: CellTypes.insert,
  idPoint: 'confluence_url',
  dataPoint: 'solution_display',
  sortKey: 'confluence_url',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => <A href={data.confluence_url}>{data.solution_display}</A>,
  title: 'Solution Guidance',
}

export const riskActionColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface
> = {
  type: CellTypes.insert,
  idPoint: 'jira_url',
  dataPoint: 'jira_url',
  sortKey: 'jira_url',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <IconUrl type="Link" href={data.jira_url} />
  },
  title: 'Action',
}

export const riskUrlColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.insert,
  idPoint: 'url',
  dataPoint: 'url',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <IconUrl type="Link" href={data.url} />
  },
  title: 'Link to Risk',
}

export const riskIncidentLinkColumn: ColumnInterface<RiskIncidentsInterface> = {
  type: CellTypes.insert,
  idPoint: 'backoffice_url',
  dataPoint: 'backoffice_url',
  sortKey: 'backoffice_url',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => {
    return <IconUrl type="Link" href={data.backoffice_url} />
  },
  title: 'Action',
}

export const riskReportedDateColumn: ColumnInterface<RiskIncidentsInterface> = {
  type: CellTypes.date,
  idPoint: 'reporting_date',
  dataPoint: 'reporting_date',
  sortKey: 'reporting_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reported date',
}

export const riskDueDateColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface | RiskIndicatorInterface
> = {
  type: CellTypes.insert,
  idPoint: 'due_date',
  dataPoint: 'due_date',
  sortKey: 'due_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (data.due_in_days ? <span>{data.due_in_days} days</span> : '-'),
  title: 'Due in',
}

export const riskStatusColumn: ColumnInterface<
  RiskSummaryInterface | RiskIncidentsInterface
> = {
  type: CellTypes.text,
  idPoint: 'status',
  dataPoint: 'status',
  sortKey: 'status',
  filterKey: 'status',
  selectorsKey: selectorKeys.risk_status,
  title: 'Status',
}

export const riskTypeColumn: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.text,
  idPoint: 'type',
  dataPoint: 'type',
  sortKey: 'type',
  filterKey: 'type',
  selectorsKey: selectorKeys.risk_types,
  title: 'Type',
  headerTooltip: (
    <TooltipContainer>
      <p>RAS - Board approved Key Risk Indicators</p>
      <p>KRI - Committee/2LOD approved Key Risk Indicators</p>
      <p>BCI - Business Control Indicators created and managed by Teams/Departments</p>
    </TooltipContainer>
  ),
}

export const riskValueColumn: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.insert,
  idPoint: 'formatted_value',
  dataPoint: 'formatted_value',
  sortKey: 'current_value',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  insert: ({ data }) => (
    <GraphIconChart id={data.id} fetchData={getRiskValueGraph}>
      <PercentageWrapper>
        <ValueWrapper>{data.formatted_value}</ValueWrapper>
        <Icon type="Graph" size="small" />
      </PercentageWrapper>
    </GraphIconChart>
  ),
  title: 'Value',
}

export const riskLimit1Column: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.text,
  idPoint: 'formatted_limit_1',
  dataPoint: 'formatted_limit_1',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Limit 1',
}

export const riskLimit2Column: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.text,
  idPoint: 'formatted_limit_2',
  dataPoint: 'formatted_limit_2',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Limit 2',
}

export const riskLimit3Column: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.text,
  idPoint: 'formatted_limit_3',
  dataPoint: 'formatted_limit_3',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Limit 3',
}

export const riskQualityColumn: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.text,
  idPoint: 'quality_score',
  dataPoint: 'quality_score',
  sortKey: 'quality_score_order',
  filterKey: 'quality_score',
  colors: data => {
    switch (data.quality_score) {
      case RiskLevel.low:
        return Token.color.red
      case RiskLevel.medium:
        return Token.color.warning
      case RiskLevel.high:
        return Token.color.green
      default:
        return Token.color.foreground
    }
  },
  selectorsKey: selectorKeys.risk_indicator_quality_scores,
  title: 'Quality',
}

export const riskLastBreachColumn: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.date,
  idPoint: 'last_breach_date',
  dataPoint: 'last_breach_date',
  sortKey: 'last_breach_date',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Last In Breach',
}

export const riskIdColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.text,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: 'id',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'ID',
}

export const riskTypeL2Column: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.text,
  idPoint: 'risk_type_2',
  dataPoint: 'risk_type_2',
  sortKey: 'risk_type_2',
  filterKey: 'risk_type_2',
  selectorsKey: selectorKeys.risk_types_2,
  title: 'Risk type L2',
}

export const riskNameColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.text,
  idPoint: 'title',
  dataPoint: 'title',
  sortKey: 'title',
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Name',
}

export const riskResidualScoreColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.insert,
  idPoint: 'residual_score',
  dataPoint: 'residual_score',
  sortKey: 'residual_score',
  filterKey: 'residual_score',
  insert: ({ data }) => {
    return (
      <Box
        borderRadius="50%"
        width={6}
        height={6}
        bg={mapResidualScoreColor(data.residual_score)}
      />
    )
  },
  selectorsKey: selectorKeys.risk_residual_scores,
  title: 'Residual score',
}

export const riskOwnerColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.insert,
  idPoint: 'owner.id',
  dataPoint: 'owner.full_name',
  sortKey: 'owner__full_name',
  filterKey: 'owner__id',
  selectorsKey: selectorKeys.employee,
  title: 'Risk Owner',
  insert: ({ data }) => <Table.EmployeeCell employee={data.owner} />,
}

export const riskControlEffectivenessColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.insert,
  idPoint: 'control_effectiveness',
  dataPoint: 'control_effectiveness',
  sortKey: 'control_effectiveness',
  filterKey: 'control_effectiveness',
  insert: ({ data }) => {
    return formatPercentage(data.control_effectiveness)
  },
  filterType: FilterType.percentRange,
  selectorsKey: selectorKeys.none,
  title: 'Control Effectiveness',
}

export const riskTargetControlDateColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.date,
  idPoint: 'target_controls_date',
  dataPoint: 'target_controls_date',
  sortKey: 'target_controls_date',
  filterKey: 'target_controls_date',
  selectorsKey: selectorKeys.none,
  title: 'Target Control Date',
}

export const riskNextReviewDateColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.date,
  idPoint: 'next_review_date',
  dataPoint: 'next_review_date',
  sortKey: 'next_review_date',
  filterKey: 'next_review_date',
  selectorsKey: selectorKeys.none,
  title: 'Next Review Date',
}

export const riskActionsColumn: ColumnInterface<RiskControlsInterface> = {
  type: CellTypes.text,
  idPoint: 'actions',
  dataPoint: 'actions',
  sortKey: 'actions',
  filterKey: 'actions',
  selectorsKey: selectorKeys.risk_control_actions,
  title: 'Actions',
}

export const riskJiraColumn: ColumnInterface<RiskIndicatorInterface> = {
  type: CellTypes.insert,
  idPoint: 'jira_url',
  dataPoint: 'jira_url',
  sortKey: null,
  filterKey: null,
  insert: ({ data }) => {
    return <IconUrl type="Link" href={data.jira_url} />
  },
  selectorsKey: selectorKeys.none,
  title: 'Action',
}
