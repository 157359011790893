import React from 'react'
import { useGetInterviewRounds } from '@src/api/recruitment/interviews'
import AdjustableTable from '@components/Table/AdjustableTable'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'
import { RowInterface } from '@src/interfaces/data'
import { Flex } from '@revolut/ui-kit'
import {
  interviewRoundSpecialisationColumn,
  interviewRoundStageColumn,
  interviewRoundStatusColumn,
} from '@src/constants/columns/interviewRound'
import MultipleDetailsSkeleton from '@components/Skeletons/MultipleDetailsSkeleton'
import { TableNames } from '@src/constants/table'

type Props = {
  candidateId: number
}

const ROW: RowInterface<InterviewRoundInterface> = {
  cells: [
    {
      ...interviewRoundSpecialisationColumn,
      width: 250,
    },
    {
      ...interviewRoundStageColumn,
      width: 250,
    },
    {
      ...interviewRoundStatusColumn,
      width: 250,
    },
  ],
}

const AppliedPostingsTable = ({ candidateId }: Props) => {
  const { data: rounds, isLoading } = useGetInterviewRounds(candidateId)

  if (isLoading) {
    return <MultipleDetailsSkeleton count={1} />
  }

  return (
    <Flex flexDirection="column" width="100%" mb="s-32">
      <AdjustableTable<InterviewRoundInterface>
        name={TableNames.AppliedPostings}
        row={ROW}
        data={rounds || []}
        count={rounds?.length || 0}
        noReset
        hideCount
      />
    </Flex>
  )
}

export default AppliedPostingsTable
