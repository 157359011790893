import React, { useCallback, useMemo } from 'react'
import SideBar from '@components/SideBar/SideBar'
import { RoadmapInterface } from '@src/interfaces/roadmaps'
import {
  Box,
  Flex,
  Text,
  Action,
  DetailsCell,
  Group,
  Button,
  Tooltip,
  useTooltip,
} from '@revolut/ui-kit'
import { InfoOutline } from '@revolut/icons'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { formatDate } from '@src/utils/format'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { roadmapsRequestsNew } from '@src/api/roadmaps'
import Form from '@src/features/Form/Form'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import NewWarningMessage from '@src/components/NewWarningMessage/NewWarningMessage'
import { NewWarningTypes } from '@src/components/NewWarningMessage/NewWarningMessage'
import { getRoadmapEntityName, usePriorityColor } from './utils'

export interface RoadmapSideBarProps {
  onClose: () => void
  data: RoadmapInterface
  organisationalUnit?: { name: string }
  onAfterSubmit?: () => void
  canEdit: boolean
}

export const RoadmapSideBarComponent = ({
  onClose,
  data,
  onAfterSubmit = () => {},
  canEdit,
}: RoadmapSideBarProps) => {
  const { values, submit, dirty } = useLapeContext<RoadmapInterface>()
  const priotityColor = usePriorityColor(data.priority)
  const tooltip = useTooltip()
  const entityName = getRoadmapEntityName(values)

  const renderDate = (date?: string) => {
    return date ? formatDate(date) : ''
  }

  const navigateToEdit = useCallback(() => {
    const editUrl = pathToUrl(ROUTES.FORMS.ROADMAP.GENERAL, { id: data.id })
    navigateTo(editUrl, { name: entityName })
  }, [data.id, entityName])

  const percentageSum = useMemo(() => {
    const { done, progress, todo } = values || {}
    const orZero = (value?: number) => Number(value) || 0

    return orZero(done) + orZero(progress) + orZero(todo)
  }, [values?.done, values?.progress, values?.todo])

  const progressTitle = useMemo<React.ReactNode>(() => {
    if (canEdit) {
      return (
        <>
          <Flex {...tooltip.getAnchorProps()}>
            <Text color="grey-tone-50" use="span" mr="s-8">
              Progress
            </Text>
            <InfoOutline size={15} color="grey-tone-50" />
          </Flex>

          <Tooltip {...tooltip.getTargetProps()}>
            <Text fontSize="larger" textAlign="justify">
              You can use this section to update the
              <br />
              progress of your roadmap and the
              <br />
              sub-tasks that it may contain.
            </Text>
          </Tooltip>
        </>
      )
    }
    return (
      <Text color="grey-tone-50" use="span" mr="s-4">
        Progress
      </Text>
    )
  }, [canEdit, tooltip])

  return (
    <SideBar
      variant="wide"
      title={data.name}
      onClose={onClose}
      subtitle={data.review_cycle.name}
      headerContent={
        <Text use="div" mt="s-12" gridArea="subtitle">
          {entityName}
        </Text>
      }
    >
      <Flex flexDirection="column">
        <Box>
          <Flex flexDirection="row" justifyContent="space-between" mb="s-16">
            <Text color="grey-tone-50">Details</Text>
            {canEdit && <Action onClick={navigateToEdit}>Edit</Action>}
          </Flex>
          <Group>
            <DetailsCell>
              <DetailsCell.Title>Owner</DetailsCell.Title>
              <DetailsCell.Content>
                <UserWithAvatar {...data.owner} />
              </DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Priority</DetailsCell.Title>
              <DetailsCell.Content color={priotityColor}>
                {data.priority}
              </DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Start Date</DetailsCell.Title>
              <DetailsCell.Content>{renderDate(data.start_date)}</DetailsCell.Content>
            </DetailsCell>
            <DetailsCell>
              <DetailsCell.Title>Due Date</DetailsCell.Title>
              <DetailsCell.Content>{renderDate(data.due_date)}</DetailsCell.Content>
            </DetailsCell>
          </Group>
        </Box>

        <Box mt="s-16">
          {progressTitle}
          <Box mt="s-16">
            <LapeRadioSelectInput
              name="status"
              label="Roadmap status"
              selector={selectorKeys.roadmap_status}
              disabled={!canEdit}
            />
          </Box>
          <Box mt="s-16" />
          <Flex flexDirection="column">
            <Text color="grey-tone-50" variant="small" use="div" mb="s-12">
              The percentage of the roadmap tasks in each status
            </Text>
            <Flex flexDirection="row" justifyContent="space-between">
              <Box mr="s-16" flex={1}>
                <LapeNewInput
                  label="To Do (%)"
                  name="todo"
                  hideOptional
                  type="number"
                  disabled={!canEdit}
                />
              </Box>
              <Box mr="s-16" flex={1}>
                <LapeNewInput
                  label="In Progress (%)"
                  name="progress"
                  hideOptional
                  disabled={!canEdit}
                  type="number"
                />
              </Box>
              <Box flex={1}>
                <LapeNewInput
                  label="Done (%)"
                  name="done"
                  hideOptional
                  type="number"
                  disabled={!canEdit}
                />
              </Box>
            </Flex>
            {dirty && percentageSum !== 100 && percentageSum !== 0 && (
              <Box mt="s-16">
                <NewWarningMessage type={NewWarningTypes.warning}>
                  <Text fontWeight="bolder">
                    Progress should sum 100%, currently {percentageSum}%
                  </Text>
                </NewWarningMessage>
              </Box>
            )}
            <Box mt="s-16">
              <LapeDatePickerInput
                disabled={!canEdit}
                name="resolution_date_time"
                label="Resolved On"
                hideOptional
              />
            </Box>
          </Flex>
        </Box>
        {canEdit && (
          <Box mt="s-16">
            <Button
              onClick={() =>
                submit().then(() => {
                  onAfterSubmit()
                  onClose()
                })
              }
              disabled={!dirty || (percentageSum !== 100 && percentageSum !== 0)}
            >
              Save Changes
            </Button>
          </Box>
        )}
      </Flex>
    </SideBar>
  )
}

export const RoadmapSideBar = (props: RoadmapSideBarProps) => {
  return (
    <Form api={roadmapsRequestsNew} forceParams={{ id: String(props.data.id) }}>
      <RoadmapSideBarComponent {...props} />
    </Form>
  )
}
