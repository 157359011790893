import React from 'react'
import { Box, Tag, Token } from '@revolut/ui-kit'
import {
  ExistingCandidatesParamsInterface,
  CandidateInterface,
} from '@src/interfaces/interviewTool'

type Props = {
  fields?: CandidateInterface['matching_fields']
}

const mapFieldLabel: { [key in keyof ExistingCandidatesParamsInterface]: string } = {
  full_name: 'name',
  email: 'email',
  linkedin_url: 'Linkedin URL',
  phone: 'phone',
}

// some factors are more important than others
const order: CandidateInterface['matching_fields'] = [
  'email',
  'phone',
  'linkedin_url',
  'full_name',
]

const ExistingCandidatesMatching = ({ fields }: Props) => {
  if (!fields?.length) {
    return null
  }

  return (
    <Box mt="s-8">
      {order
        ?.filter(field => fields?.includes(field))
        .map(field => (
          <Box display="inline-block" mr="s-8" mb="s-4" key={field}>
            <Tag
              color={Token.color.blue}
              useIcon="Check"
              key={field}
              display="inline-block"
            >
              Matching {mapFieldLabel[field]}
            </Tag>
          </Box>
        ))}
    </Box>
  )
}

export default ExistingCandidatesMatching
