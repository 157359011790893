import React from 'react'
import { Box, BoxProps, Color, Flex, Text } from '@revolut/ui-kit'
import { ExclamationMark } from '@revolut/icons'

export enum NewWarningTypes {
  warning = 'warning',
  error = 'error',
  info = 'info',
}

export interface NewWarningMessageProps extends BoxProps {
  children: React.ReactNode
  type?: NewWarningTypes
  title?: string
}

const getColors = (type: NewWarningTypes): { color: Color; bg: Color } => {
  switch (type) {
    case NewWarningTypes.error:
      return { color: 'red', bg: 'red-opaque-10' }
    case NewWarningTypes.info:
      return { color: 'blue', bg: 'blue-opaque-10' }
    case NewWarningTypes.warning:
    default:
      return { color: 'orange', bg: 'orange-opaque-10' }
  }
}

const NewWarningMessage = ({
  children,
  type = NewWarningTypes.error,
  title,
  ...props
}: NewWarningMessageProps) => {
  const { color, bg } = getColors(type)

  return (
    <Box p="s-12" borderRadius={12} bg={bg} {...props}>
      <Flex alignItems={title ? 'flex-start' : 'center'}>
        <Box mr="s-8" color={color}>
          <ExclamationMark size={18} />
        </Box>
        <Box>
          {title && (
            <Text mb="1em" fontWeight={500}>
              {title}
            </Text>
          )}
          <Flex alignItems="center" minHeight={title ? undefined : '2.2em'}>
            <Text color={title ? 'grey-tone-50' : undefined} mt="s-2">
              {children}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

export default NewWarningMessage
