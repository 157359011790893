import React from 'react'
import { connect } from 'lape'
import { Box, InputGroup } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'

import Form from '@src/features/Form/Form'
import { faqCategoriesRequest } from '@src/api/faq'
import { PageWrapper } from '@src/components/Page/Page'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { CategoryIcon } from '@src/components/HelpCenter/components/CategoryIcon'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { FaqCategoryInterface } from '@src/interfaces/faq'
import SettingsButtons, { DeleteButton } from '@src/features/SettingsButtons'
import { PermissionTypes } from '@src/store/auth/types'
import { iconList } from '@src/components/HelpCenter/components/iconWhitelist'
import { navigateReplace } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'

const FaqCategory = () => {
  const { values } = useLapeContext<FaqCategoryInterface>()

  const params = useParams<{ id?: string }>()

  const backUrl = ROUTES.SETTINGS.HELP_CENTER.FAQ_CATEGORIES

  return (
    <PageWrapper>
      <PageHeader title="FAQ category" backUrl={backUrl} />

      <PageBody>
        {values.id ? (
          <SettingsButtons mb="s-16">
            <DeleteButton
              isVisible
              backUrl={backUrl}
              deleteApi={faqCategoriesRequest.delete!}
              title="FAQ category"
              globalPermissions={[PermissionTypes.ChangeHelpCenterPreferences]}
            />
          </SettingsButtons>
        ) : null}

        <InputGroup>
          <LapeNewInput label="Name" name="name" required />
          <LapeNewTextArea label="Description" name="description" rows={2} required />
          <LapeNewInput label="Link to support" name="link_to_support" required />
          <LapeRadioSelectInput
            label="Icon"
            name="icon_id"
            options={iconList.map(opt => ({
              label: opt.name,
              value: opt,
            }))}
            onChange={option => {
              values.icon_id = option?.name || null
            }}
            value={iconList.find(opt => opt.name === values.icon_id)}
            inputProps={{
              renderPrefix: () =>
                values.icon_id ? (
                  <Box mr="s-8">
                    <CategoryIcon name={values.icon_id} size={30} />
                  </Box>
                ) : null,
            }}
          >
            {option => <CategoryIcon name={option.value.name} showIconName />}
          </LapeRadioSelectInput>
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onAfterSubmit={res => {
            if (!params.id) {
              navigateReplace(
                pathToUrl(ROUTES.FORMS.FAQ_CATEGORY.GENERAL, { id: res.id }),
              )
            }
          }}
          useValidator
        />
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={faqCategoriesRequest}>
    <FaqCategory />
  </Form>
))
