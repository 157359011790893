import React from 'react'
import { Box } from '@revolut/ui-kit'

import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { PageActions } from '@components/Page/PageActions'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'

export const SettingsForm = () => {
  return (
    <>
      <PageHeader title={'Engagement surveys'} backUrl={ROUTES.SETTINGS.ALL} />
      <PageBody>
        <Box mt="-s-24">
          <AutoStepper>
            <NewStepperTitle title="Anonymity threshold" />
            <LapeNewInput
              required
              type="number"
              name="min_number_of_responses"
              label="Min. number of responses"
              message="Please enter minimal required number of responses to show the survey results."
            />
            <LapeNewInput
              required
              type="number"
              name="min_group_size"
              label="Min. group size"
              message="Please enter minimal required group size to show the survey results."
            />
          </AutoStepper>
          <PageActions mt="s-64">
            <NewSaveButtonWithPopup
              useValidator
              successText="Changes were successfully saved"
            >
              Save changes
            </NewSaveButtonWithPopup>
          </PageActions>
        </Box>
      </PageBody>
    </>
  )
}
