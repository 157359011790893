import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

import { EmployeeInterface } from '@src/interfaces/employees'
import { selectPermissions } from '@src/store/auth/selectors'
import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'

import { Salary } from '../../Preview/Rewards/Salary'
import { Bonuses } from '../../Preview/Rewards/Bonuses'
import { Benefits as NewBenefits } from '../../Preview/Rewards/NewBenefits'
import { Benefits } from '../../Preview/Rewards/Benefits'
import { SalarySacrifice } from '../../Preview/Rewards/SalarySacrifice'
import { CompensationParams } from '../common/types'
import { EmployeePageHeader } from '../common/EmployeePageHeader'

type Props = {
  data: EmployeeInterface
  compensationParams: CompensationParams
}
export const CompensationPages = ({ compensationParams, ...props }: Props) => {
  const permissions = useSelector(selectPermissions)
  const tabs = [
    {
      title: 'Salary',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY,
      component: Salary,
    },
    {
      title: 'Bonuses',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.BONUSES,
      component: Bonuses,
    },
    {
      title: 'Benefits',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.BENEFITS,
      component: permissions.includes(PermissionTypes.ViewBenefits)
        ? NewBenefits
        : Benefits,
    },
    {
      title: 'Salary Sacrifice',
      path: ROUTES.FORMS.EMPLOYEE.REWARDS.SALARY_SACRIFICE,
      component: SalarySacrifice,
    },
  ]

  return (
    <Switch>
      {tabs.map(tab => {
        return (
          <Route exact path={tab.path} key={tab.path}>
            <EmployeePageHeader title={tab.title} data={props.data} />
            <tab.component
              {...props}
              currency={compensationParams?.currency}
              setCurrency={compensationParams?.setCurrency}
            />
          </Route>
        )
      })}
    </Switch>
  )
}
