import React, { useState } from 'react'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { sendOnlineTestInvite } from '@src/api/hiringProcess'
import { useFetchCandidateInterviewStages } from '@src/api/recruitment/interviews'
import {
  Button,
  ErrorWidget,
  Flex,
  Side,
  StatusPopup,
  useStatusPopup,
} from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import SendOnlineTestPreview from '@src/pages/Forms/SendOnlineTest/SendOnlineTestPreview'
import { IdAndName } from '@src/interfaces'
import SideBar from '@components/SideBar/SideBar'

interface SendOnlineTestProps {
  onSuccess: () => void
  roundId?: number
  stageId?: number
  candidateId?: number | string
  candidateEmail?: string
  onClose: () => void
}

export const SendOnlineTestSidebar = ({
  candidateId,
  candidateEmail,
  stageId,
  roundId,
  onClose,
  onSuccess,
}: SendOnlineTestProps) => {
  const [onlineTest, setOnlineTest] = useState<IdAndName>()
  const [loading, setLoading] = useState(false)
  const statusPopup = useStatusPopup()

  const { data: stages } = useFetchCandidateInterviewStages(
    {
      filters: stageId
        ? [
            {
              filters: [
                {
                  id: stageId,
                  name: String(stageId),
                },
              ],
              columnName: 'id',
            },
          ]
        : [],
    },
    roundId,
  )

  const stage = stages?.[0]

  if (!stage) {
    return null
  }

  if (!onlineTest && stage.online_test) {
    setOnlineTest(stage.online_test)
  }

  const onSend = async () => {
    if (!roundId || !stageId) {
      return
    }

    setLoading(true)

    try {
      await sendOnlineTestInvite(+roundId, +stageId, onlineTest?.id)
      statusPopup.show(
        <StatusPopup variant="success">
          <StatusPopup.Title>Online test has been sent</StatusPopup.Title>
        </StatusPopup>,
      )
      setLoading(false)
      onSuccess()
    } catch (e) {
      const hasTaken =
        e?.response?.data?.errors?.[0] === 'Candidate has already taken the test.'

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Can’t send test to candidate</StatusPopup.Title>
          {hasTaken && (
            <StatusPopup.Description>
              This test was already sent to the candidate
              {candidateEmail ? ` with this email (${candidateEmail})` : ''}. Cancel/reset
              test on the platform or send a different test
            </StatusPopup.Description>
          )}
        </StatusPopup>,
      )

      setLoading(false)
    }
  }

  const title =
    stage.scheduling_status === 'test_sent' ? 'Re-send online test' : 'Send online test'

  return (
    <SideBar isOpen onClose={onClose} useLayout title={title}>
      {stage.scheduling_status === 'test_completed' ? (
        <Flex width="100%" height="80vh" justifyContent="center" alignItems="center">
          <ErrorWidget>
            <ErrorWidget.Image />
            <ErrorWidget.Title>You cannot send the invite</ErrorWidget.Title>
            <ErrorWidget.Description>
              The candidate already completed the test for this stage
            </ErrorWidget.Description>
            <ErrorWidget.Action
              onClick={() =>
                navigateTo(
                  pathToUrl(ROUTES.FORMS.CANDIDATE.SUMMARY, {
                    id: candidateId,
                  }),
                )
              }
            >
              Go to the candidate profile
            </ErrorWidget.Action>
          </ErrorWidget>
        </Flex>
      ) : (
        <SendOnlineTestPreview
          hiringStageId={stage.hiring_stage_id}
          onlineTest={onlineTest}
          candidateId={candidateId}
          displayCandidateInfo
          onChange={setOnlineTest}
        />
      )}
      <Side.Actions>
        <Button onClick={onSend} disabled={loading} pending={loading}>
          Send test
        </Button>
      </Side.Actions>
    </SideBar>
  )
}
