import React from 'react'
import { useParams } from 'react-router-dom'

import { selectorKeys } from '@src/constants/api'
import { BulkEditAction } from '@src/features/GenericEditableTable/components'
import {
  getHiddenSessionColumnsBySessionField,
  getVisibleSessionActionsBySessionField,
} from '@src/features/GenericEditableTable/helpers'
import { BulkEmployeeUploadFlow } from '../common/BulkSession'
import {
  getBulkSessionHeaderTitle,
  BaseFieldsForBulkEdit,
  BulkSessionTableWrapperProps,
} from '../common'
import {
  bulkFieldsDependencies,
  FieldsForBulkEdit,
  fieldsForBulkEdit,
  getRow,
} from './common'

export const EmployeesBulkSessionDetailed = (props: BulkSessionTableWrapperProps) => {
  const params = useParams<{ type?: FieldsForBulkEdit }>()

  const headerTitle =
    params.type === 'location'
      ? 'Review bulk assigned location'
      : params.type === 'line_manager'
      ? 'Review bulk assigned line manager'
      : getBulkSessionHeaderTitle(params?.type as BaseFieldsForBulkEdit)

  const hiddenColumnsBySessionField =
    getHiddenSessionColumnsBySessionField<FieldsForBulkEdit>(
      params.type as FieldsForBulkEdit,
      fieldsForBulkEdit,
      bulkFieldsDependencies,
    )
  const visibleActionsBySessionField =
    getVisibleSessionActionsBySessionField<FieldsForBulkEdit>(
      params.type as FieldsForBulkEdit,
      bulkFieldsDependencies,
    )

  return (
    <BulkEmployeeUploadFlow
      row={getRow(props.isUpdatingExistingEmployees)}
      hiddenColumns={hiddenColumnsBySessionField}
      headerTitle={headerTitle}
      visibleActions={visibleActionsBySessionField}
      renderMoreActions={tableActionsProps => (
        <>
          {visibleActionsBySessionField.line_manager && (
            <BulkEditAction
              buttonIcon="Profile"
              field="line_manager"
              label="manager"
              selector={selectorKeys.all_employees_avatar_email}
              selectorField="email"
              {...tableActionsProps}
            />
          )}
          {visibleActionsBySessionField.location && (
            <BulkEditAction
              buttonIcon="LocationPin"
              field="location"
              label="location"
              selector={selectorKeys.location}
              {...tableActionsProps}
            />
          )}
        </>
      )}
      {...props}
    />
  )
}
