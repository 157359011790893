import React from 'react'
import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '../api'
import { ImportInterface } from '@src/interfaces/bulkDataImport'
import { ImportCell } from '@src/constants/columns/bulkDataImport'
import { SelectOptionItemType } from '@revolut/ui-kit'
import { EmployeeEmails } from '@src/interfaces/selectors'
import { IdAndName } from '@src/interfaces'

export interface ImportLegacyTimelineDataInterface {
  employee_email: string
  grade: string
  completion_date: string
  cycle_name: string
  reviewer_email?: string
  reviewer_relation?: string
  feedback?: string
}

export interface ImportLegacyTimelineCellOptions {
  employeeOptions: SelectOptionItemType<EmployeeEmails>[]
  relationOptions: SelectOptionItemType<IdAndName<string>>[]
}

type Cell = ColumnInterface<ImportInterface<ImportLegacyTimelineDataInterface>>

type CellWithOptions = (options: ImportLegacyTimelineCellOptions) => Cell

export const importLegacyTimelineEmail: CellWithOptions = options => ({
  type: CellTypes.insert,
  idPoint: 'employee_email',
  dataPoint: 'employee_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Employee Email',
  insert: ({ data }) => (
    <ImportCell
      field="employee_email"
      data={data}
      options={options.employeeOptions}
      type="employee"
    />
  ),
})

export const importLegacyTimelineGrade: Cell = {
  type: CellTypes.insert,
  idPoint: 'grade',
  dataPoint: 'grade',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Grade',
  insert: ({ data }) => <ImportCell field="grade" data={data} />,
}

export const importLegacyTimelineCompletionDate: Cell = {
  type: CellTypes.insert,
  idPoint: 'completion_date',
  dataPoint: 'completion_date',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Completion Date',
  insert: ({ data }) => <ImportCell field="completion_date" data={data} type="date" />,
}

export const importLegacyTimelineCycleName: Cell = {
  type: CellTypes.insert,
  idPoint: 'cycle_name',
  dataPoint: 'cycle_name',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Cycle Name',
  insert: ({ data }) => <ImportCell field="cycle_name" data={data} />,
}

export const importLegacyTimelineReviewerEmail: CellWithOptions = options => ({
  type: CellTypes.insert,
  idPoint: 'reviewer_email',
  dataPoint: 'reviewer_email',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reviewer Email',
  insert: ({ data }) => (
    <ImportCell
      field="reviewer_email"
      data={data}
      type="employee"
      options={options.employeeOptions}
    />
  ),
})

export const importLegacyTimelineReviewerRelation: CellWithOptions = options => ({
  type: CellTypes.insert,
  idPoint: 'reviewer_relation',
  dataPoint: 'reviewer_relation',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Reviewer Relation',
  insert: ({ data }) => (
    <ImportCell
      field="reviewer_relation"
      data={data}
      type="option"
      options={options.relationOptions}
    />
  ),
})

export const importLegacyTimelineFeedback: Cell = {
  type: CellTypes.insert,
  idPoint: 'feedback',
  dataPoint: 'feedback',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Feedback',
  insert: ({ data }) => <ImportCell field="feedback" data={data} />,
}
