import React from 'react'
import InputErrorMessage from '@components/InputErrorMessage/InputErrorMessage'
import { Color, Text } from '@revolut/ui-kit'

type Props = {
  hasError?: boolean
  message?: React.ReactNode
}

const BottomInputMessage = ({ hasError, message }: Props) => {
  if (!message) {
    return null
  }

  if (hasError) {
    return <InputErrorMessage message={message} />
  }

  return (
    <Text use="div" py="s-2" px="s-16" variant="small" color={Color.GREY_TONE_50}>
      {message}
    </Text>
  )
}

export default BottomInputMessage
