import { GetRequestInterface, RequestInterfaceNew } from '@src/interfaces'
import { api, apiWithoutHandling } from '@src/api/index'
import { API } from '@src/constants/api'
import {
  AllScreeningChecksInterface,
  AllScreeningChecksStatsInterface,
  HirerightExternalLinkInterface,
  HirerightPackageInterface,
  HirerightScreeningChecksInterface,
  HirerightScreeningStatusTimelineStep,
  ManualScreeningChecksInterface,
  ManualScreeningStatusTimelineStep,
} from '@src/interfaces/screeningChecksInterface'
import { useFetch } from '@src/utils/reactQuery'
import { ApprovalFlowResponse } from '@src/interfaces/approvalFlow'
import { FetchDataQueryInterface } from '@src/interfaces/data'
import { AxiosPromise } from 'axios'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const getAllEmployeeScreeningChecks =
  (employeeId: number) =>
  ({
    sortBy,
    filters,
    page,
  }: FetchDataQueryInterface): AxiosPromise<
    GetRequestInterface<AllScreeningChecksInterface>
  > =>
    api.get(`${API.EMPLOYEES}/${employeeId}/allScreeningChecks`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    })

export const getAllScreeningChecks = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<
  GetRequestInterface<AllScreeningChecksInterface>
> =>
  api.get(`/employees/screeningChecks`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getAllScreeningChecksStats = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface) =>
  api.get<AllScreeningChecksStatsInterface>(`/employees/screeningChecks/stats`, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const manualScreeningRequests: RequestInterfaceNew<ManualScreeningChecksInterface> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(`${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}`)
    },
    update: async (data, { employeeId, id }) => {
      return apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}`,
        data,
      )
    },
    submit: async (data, { employeeId }) => {
      return apiWithoutHandling.post(
        `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks`,
        data,
      )
    },
    delete: async (data, { employeeId }) => {
      return api.delete(`${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${data.id}`)
    },
  }

export const useManualScreeningStatusTimeline = (
  id?: number | string,
  employeeId?: number | string,
) =>
  useFetch<ManualScreeningStatusTimelineStep[]>(
    id && employeeId
      ? `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}/statusTimeline`
      : null,
  )

export const useManualScreeningApprovals = (employeeId?: string, id?: string | number) =>
  useFetch<ApprovalFlowResponse>(
    employeeId && id
      ? `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}/approvals`
      : null,
  )

export const approveManualScreeningStage = (employeeId: string, id: string | number) =>
  api.post<ManualScreeningChecksInterface>(
    `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}/approve`,
  )

export const rejectManualScreeningStage = (
  employeeId: string,
  id: string | number,
  rejection_comment: string,
) =>
  api.post<ManualScreeningChecksInterface>(
    `${API.EMPLOYEES}/${employeeId}/manualScreeningChecks/${id}/reject`,
    {
      rejection_comment,
    },
  )

export const hirerightScreeningRequests: RequestInterfaceNew<HirerightScreeningChecksInterface> =
  {
    get: async ({ id, employeeId }) => {
      return api.get(`${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks/${id}`)
    },
    update: async (data, { employeeId, id }) => {
      return apiWithoutHandling.patch(
        `${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks/${id}`,
        data,
      )
    },
    submit: async (data, { employeeId }) => {
      return apiWithoutHandling.post(
        `${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks`,
        data,
      )
    },
    delete: async (data, { employeeId }) => {
      return api.delete(
        `${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks/${data.id}`,
      )
    },
  }

export const useHirerightPackages = (account?: string) =>
  useFetch<HirerightPackageInterface[]>(
    account ? `${API.EMPLOYEES}/hirerightPackages` : null,
    'v1',
    {
      params: { account },
    },
  )

export const useHirerightScreeningStatusTimeline = (
  id?: number | string,
  employeeId?: number | string,
) =>
  useFetch<HirerightScreeningStatusTimelineStep[]>(
    id && employeeId
      ? `${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks/${id}/statusTimeline`
      : null,
  )

export const getHirerightExternalLink = (
  employeeId: string | number,
  screeningId: string | number,
) =>
  api.get<HirerightExternalLinkInterface>(
    `${API.EMPLOYEES}/${employeeId}/automaticScreeningChecks/${screeningId}/getExternalUrl`,
  )
