import React from 'react'
import { Action, Color, Flex, Text } from '@revolut/ui-kit'
import { ArrowThinLeft, ArrowThinRight } from '@revolut/icons'

export const PAGE_SIZE = 10

type OptionsPaginationProps = {
  page: number
  total?: number
  onPageChange: (page: number) => void
}

const OptionsPagination = ({ page, total = 0, onPageChange }: OptionsPaginationProps) => {
  const start = PAGE_SIZE * page + 1
  const last = PAGE_SIZE * page + PAGE_SIZE
  const end = last > total ? total : last
  const paginationStateMessage = `showing ${start} to ${end} of ${total} options`
  return (
    <Flex alignItems="center">
      <Text mx="s-8">{paginationStateMessage}</Text>
      <Action
        useIcon={ArrowThinLeft}
        aria-label="Paginate options back"
        color={Color.BLUE}
        onClick={e => {
          e.stopPropagation()
          onPageChange(page - 1)
        }}
        disabled={page <= 0}
      />
      <Action
        useIcon={ArrowThinRight}
        aria-label="Paginate options forward"
        color={Color.BLUE}
        onClick={e => {
          e.stopPropagation()
          onPageChange(page + 1)
        }}
        disabled={last >= total}
      />
    </Flex>
  )
}

export default OptionsPagination
