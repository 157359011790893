import React from 'react'
import { ItemSkeleton } from '@revolut/ui-kit'
import { ItemProps } from '@revolut/ui-kit/types/dist/components/Item/Item'

const AvatarItemSkeleton = (props: ItemProps) => {
  return (
    <ItemSkeleton {...props}>
      <ItemSkeleton.Avatar />
      <ItemSkeleton.Content />
      <ItemSkeleton.Side />
    </ItemSkeleton>
  )
}

export default AvatarItemSkeleton
