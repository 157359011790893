import React, { CSSProperties } from 'react'
import { Flex } from '@revolut/ui-kit'
import { BoxInnerProps } from '@revolut/ui-kit/types/dist/components/Box/Box'

interface Props extends BoxInnerProps {
  children: React.ReactNode
  noPadding?: boolean
  style?: CSSProperties
}

const PublicPageLayout = ({ children, noPadding, style, ...props }: Props) => {
  return (
    <Flex
      width="100%"
      padding={{ all: noPadding ? '0px' : '40px', lg: '0px' }}
      justifyContent="center"
      style={style}
      {...props}
    >
      {children}
    </Flex>
  )
}

export default PublicPageLayout
