import React, { useState } from 'react'
import {
  activateEngagementSurvey,
  engagementSurveyFormRequests,
  useGetEngagementSurvey,
} from '@src/api/engagement'
import { useParams } from 'react-router-dom'
import Loader from '@src/components/CommonSC/Loader'
import {
  Widget,
  Text,
  Token,
  Flex,
  Button,
  useStatusPopup,
  StatusPopup,
  MoreBar,
} from '@revolut/ui-kit'
import { OnboardingIntro } from '@src/components/OnboardingIntro/OnboardingIntro'
import { navigateReplace, navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageActions } from '@src/components/Page/PageActions'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { EngagementEditStep } from '@src/pages/Performance/Engagement/types'
import { Statuses } from '@src/interfaces'
import { Timing } from '@src/pages/Forms/Engagement/summaryComponents/Timing'
import { Questions } from '@src/pages/Forms/Engagement/summaryComponents/Questions'
import { Audience } from '@src/pages/Forms/Engagement/summaryComponents/Audience'
import { Notification } from '@src/pages/Forms/Engagement/summaryComponents/Notification'
import { engagementConfig } from '../common/checkpointsConfig'
import { completeOnboardingCheckpoint } from '@src/api/onboardingChecklistV2'
import { workspaceLocalStorage } from '@src/features/Workspaces/workspaceLocalStorage'
import { LOCAL_STORAGE } from '@src/constants/api'
import { InternalRedirect } from '@src/components/InternalLink/InternalRedirect'
import { useTestSurvey } from '@src/pages/Forms/Engagement/hooks/useTestSurvey'
import { StartSurveyForm } from '@src/apps/People/Engagement/Survey/Preview/StartSurveyForm'
import { renderFinishPopup } from '@src/pages/Forms/Engagement/editComponents/renderFinishPopup'
import { useDeactivateSurvey } from '@src/pages/Forms/Engagement/hooks/useDeactivateSurvey'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { completeOnboardingCheckpointCategory } from '@src/api/onboardingChecklist'

export const EngagementSetup = () => {
  const { id } = useParams<{ id?: string }>()
  const [isPending, setIsPending] = useState(false)
  const statusPopup = useStatusPopup()
  const featureFlags = useSelector(selectFeatureFlags)
  const isOnboardingV2 = featureFlags.includes(FeatureFlags.OnboardingFlowV2)
  const [adHocPopupVisible, setAdHocPopupVisibile] = useState(false)

  const { data: surveyData, isLoading } = useGetEngagementSurvey(id)
  const { deactivateSurvey } = useDeactivateSurvey({
    surveyId: surveyData?.id,
  })

  const { runTestSurvey, isPending: testSurveyPending } = useTestSurvey({
    surveyId: String(surveyData?.id),
    preSubmit: false,
  })

  const onEdit = async (
    step?: EngagementEditStep,
    options?: { goBackOnSave?: boolean },
  ) => {
    if (!surveyData?.id) {
      return
    }
    navigateTo(
      pathToUrl(ROUTES.ONBOARDING_CHECKLIST_V2.ENGAGEMENT.CUSTOMISE, {
        step,
        id: surveyData?.id,
      }),
      { onSaveGoBack: options?.goBackOnSave },
    )
  }

  const handleLaunchSurvey = async () => {
    if (!surveyData?.id) {
      return
    }
    setIsPending(true)
    try {
      if (surveyData.status.id !== Statuses.active) {
        await activateEngagementSurvey(surveyData.id)
      }

      if (isOnboardingV2) {
        await completeOnboardingCheckpoint(engagementConfig.category)
      } else {
        await completeOnboardingCheckpointCategory('engagement')
      }
      workspaceLocalStorage.removeItem(
        LOCAL_STORAGE.ONBOARDING_ENGAGEMENT_CURRENT_DRAFT_ID,
      )

      if (
        surveyData.sent_every.id === 'manual' &&
        surveyData.round_status?.id !== Statuses.running &&
        surveyData.round_status?.id !== Statuses.planned
      ) {
        setAdHocPopupVisibile(true)
      } else {
        statusPopup.show(
          renderFinishPopup({
            onClose: () => {
              statusPopup.hide()
              navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
            },
            isOnboarding: true,
          }),
        )
      }
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  const handleSaveDraft = async () => {
    if (!surveyData?.id) {
      return
    }
    setIsPending(true)
    try {
      await engagementSurveyFormRequests.update(
        { status: { id: Statuses.inactive, name: Statuses.inactive } },
        { id: String(surveyData.id) },
      )
      navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
    } catch (error) {
      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Couldn't complete the step</StatusPopup.Title>
          <StatusPopup.Description>
            {getStringMessageFromError(error)}
          </StatusPopup.Description>
        </StatusPopup>,
      )
    } finally {
      setIsPending(false)
    }
  }

  if (!isLoading && !surveyData) {
    return <InternalRedirect to={ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD} />
  }

  return (
    <OnboardingIntro>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Widget p="s-16">
            <Flex flexDirection="column" width="100%" gap="s-4">
              <Text variant="h5">{surveyData.title}</Text>
              <Text color={Token.color.greyTone50} pb="s-16">
                Review, test and customise the engagement survey we tailored for you
              </Text>
              <MoreBar>
                <MoreBar.Action useIcon="Pencil" onClick={() => onEdit('basic')}>
                  Customise
                </MoreBar.Action>
                <MoreBar.Action
                  disabled={testSurveyPending}
                  useIcon="Play"
                  onClick={runTestSurvey}
                >
                  Test survey
                </MoreBar.Action>
              </MoreBar>
            </Flex>
          </Widget>
          <Timing
            {...surveyData}
            onEdit={() => onEdit('basic', { goBackOnSave: true })}
          />
          <Questions
            {...surveyData}
            onEdit={() => onEdit('questions', { goBackOnSave: true })}
          />
          <Audience
            {...surveyData}
            onEdit={() => onEdit('audience', { goBackOnSave: true })}
          />
          <Notification
            {...surveyData}
            onEdit={() => onEdit('notifications', { goBackOnSave: true })}
          />

          {surveyData?.id && (
            <PageActions maxWidthMd={Token.breakpoint.lg}>
              <Button onClick={handleSaveDraft} variant="secondary" pending={isPending}>
                Save as draft
              </Button>
              <Button onClick={handleLaunchSurvey} pending={isPending}>
                Launch survey
              </Button>
            </PageActions>
          )}
        </>
      )}
      {!!surveyData && (
        <StartSurveyForm
          surveyId={surveyData.id}
          surveyStatus={{ id: Statuses.active, name: Statuses.active }}
          cancelButtonText="Set up later"
          isOpen={adHocPopupVisible}
          onReject={async () => {
            await deactivateSurvey()
            setAdHocPopupVisibile(false)
            statusPopup.show(
              renderFinishPopup({
                onClose: () => {
                  statusPopup.hide()
                  navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
                },
                isOnboarding: true,
              }),
            )
          }}
          onClose={() => {
            setAdHocPopupVisibile(false)
            statusPopup.show(
              renderFinishPopup({
                onClose: () => {
                  statusPopup.hide()
                  navigateReplace(ROUTES.PERFORMANCE.ENGAGEMENT.DASHBOARD)
                },
                isOnboarding: true,
              }),
            )
          }}
        />
      )}
    </OnboardingIntro>
  )
}
