import React, { useEffect } from 'react'
import { Box, HStack, Text } from '@revolut/ui-kit'
import { connect } from 'lape'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import ViewPlaceholders from '@src/pages/Forms/NotificationTemplate/components/ViewPlaceholders/ViewPlaceholders'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { useLapeContext } from '@src/features/Form/LapeForm'
import {
  NotificationTemplateInterface,
  NotificationAction,
} from '@src/interfaces/notificationTemplate'
import { NotificationsIcon } from '@src/features/Notifications/NotificationsIcon'
import { iconsMap } from '@src/features/Notifications/icons'
import { actionOptions } from '@src/pages/Forms/NotificationTemplate/common'
import LapeEditor from '@components/Inputs/LapeFields/LapeEditor'

const SystemNotificationContent = () => {
  const { values } = useLapeContext<NotificationTemplateInterface>()

  useEffect(() => {
    if (!values.system_action) {
      values.system_action = NotificationAction.url
    }
  }, [values.system_action])

  return (
    <>
      <LapeNewInput
        name="system_title"
        label="Main content (title)"
        message="Make it short and engaging"
        required
      />
      <LapeNewInput
        name="system_description"
        label="Short description"
        message="Extra information visible right below title"
        required
      />
      <LapeRadioSelectInput
        label="Icon"
        name="icon_id"
        options={Object.entries(iconsMap).map(([name, icon]) => ({
          label: name,
          value: { name, ...icon },
        }))}
        onChange={option => {
          values.system_category = option?.name
        }}
        value={
          values.system_category
            ? /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
              { name: values.system_category, ...iconsMap[values.system_category] }
            : undefined
        }
        inputProps={{
          renderPrefix: () =>
            values.system_category ? (
              <Box mr="s-8">
                <NotificationsIcon name={values.system_category} size={32} />
              </Box>
            ) : null,
        }}
      >
        {option => (
          <HStack space="s-16" align="center">
            <NotificationsIcon name={option.value.name} size={32} />
            <Text>{option.value.name}</Text>
          </HStack>
        )}
      </LapeRadioSelectInput>
      <RadioSelectInput
        name="action_type"
        label="Action when clicking on notification"
        message="Define what should happen when user clicks on notification"
        options={actionOptions}
        onChange={val => {
          values.system_action = val?.id
        }}
        value={
          actionOptions.find(option => option.value.id === values.system_action)?.value
        }
        searchable={false}
        required
      />
      {values.system_action === NotificationAction.url ? (
        <LapeNewInput
          name="system_action_url"
          label="Url"
          message="Define URL where user should navigate after clicking on notification"
          required
        />
      ) : (
        <>
          <ViewPlaceholders />
          <LapeEditor name="template_text" placeholder="Description" />
        </>
      )}
    </>
  )
}

export default connect(SystemNotificationContent)
