import React, { useEffect } from 'react'
import { ViewType } from '../types'
import { IdAndName } from '@src/interfaces'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import {
  getSurveyAdminResultCommentsTableRequests,
  getSurveyPublishedResultCommentsTableRequests,
} from '@src/api/engagement'
import { useTable } from '@src/components/Table/hooks'
import {
  EngagementResultsComment,
  EngagementResultsScope,
} from '@src/interfaces/engagement'
import {
  engagementCommentAnswerText,
  engagementResultsFeedbackAnswerScoreColumn,
  engagementResultsFeedbackCategoryColumn,
  engagementResultsFeedbackCreationDateColumn,
  engagementResultsFeedbackQuestionColumn,
} from '@src/constants/columns/engagementResults'
import { useIsNewTable } from '@src/components/TableV2/hooks'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'

interface Props {
  view: ViewType
  selectedRound: IdAndName
  surveyId: number
  entity?: { type: EngagementResultsScope; id: number }
}

const ROW = {
  cells: [
    {
      ...engagementCommentAnswerText,
      width: 200,
    },
    {
      ...engagementResultsFeedbackAnswerScoreColumn,
      width: 90,
    },
    {
      ...engagementResultsFeedbackQuestionColumn,
      width: 200,
    },
    {
      ...engagementResultsFeedbackCategoryColumn,
      width: 120,
    },
    {
      ...engagementResultsFeedbackCreationDateColumn,
      width: 100,
    },
  ],
}

export const ResultComments = ({ view, surveyId, selectedRound, entity }: Props) => {
  const table = useTable<EngagementResultsComment>(
    entity
      ? getSurveyPublishedResultCommentsTableRequests({
          scope: entity.type,
          scopeId: entity.id,
        })
      : getSurveyAdminResultCommentsTableRequests(),
    [
      {
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      },
      {
        columnName: 'survey__id',
        filters: [{ id: surveyId, name: String(surveyId) }],
        nonResettable: true,
        nonInheritable: true,
      },
    ],
  )
  const isNewTable = useIsNewTable()

  useEffect(() => {
    const currentSurveyRound = table.filterBy
      .find(({ columnName }) => columnName === 'survey_round_id')
      ?.filters.at(0)?.id
    if (selectedRound.id !== currentSurveyRound) {
      table.onFilterChange({
        columnName: 'survey_round_id',
        filters: [{ id: selectedRound.id, name: String(selectedRound.id) }],
        nonInheritable: true,
        nonResettable: true,
      })
    }
  }, [selectedRound])

  return view === 'table' ? (
    <AdjustableTable
      name={TableNames.EngagementSurveysResultComments}
      row={ROW}
      hideCount={isNewTable}
      useWindowScroll
      emptyState={<EmptyTableRaw title="This survey has no results yet" />}
      {...table}
    />
  ) : null
}
