import React from 'react'
import Form from '@src/features/Form/Form'
import { rightToWorkRequests } from '@src/api/employees'
import RightToWorkForm from './General'

const RightToWork = () => {
  return (
    <Form api={rightToWorkRequests}>
      <RightToWorkForm />
    </Form>
  )
}

export default RightToWork
