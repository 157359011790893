import React from 'react'
import { Box, Flex, HStack, Text, VStack, Icon, Token } from '@revolut/ui-kit'
import { formatPercentage } from '@src/utils/format'
import { getTotalAnswersFromAverage } from '@src/apps/People/Engagement/Results/components/DistributionChart/helpers'
import { EngagementResultsAverageScoreInterface } from '@src/interfaces/engagement'

type Props = {
  averageScore: EngagementResultsAverageScoreInterface
}
export const Summary = ({ averageScore }: Props) => {
  const total = getTotalAnswersFromAverage(averageScore)

  return (
    <Box p="s-4" minWidth={200}>
      <VStack>
        <Flex justifyContent="space-between">
          <HStack space="s-8" align="center">
            <Icon name="SocialLike" size={16} color={Token.color.green} />
            <Text>Promoters</Text>
          </HStack>
          <Text>
            {averageScore.promoters} ({formatPercentage(averageScore.promoters / total)})
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <HStack space="s-8" align="center">
            <Icon name="LikeDislike" size={16} color={Token.color.yellow} />
            <Text>Passives</Text>
          </HStack>
          <Text>
            {averageScore.passives} ({formatPercentage(averageScore.passives / total)})
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <HStack space="s-8" align="center">
            <Icon name="SocialDislike" size={16} color={Token.color.red} />
            <Text>Detractors</Text>
          </HStack>
          <Text>
            {averageScore.detractors} ({formatPercentage(averageScore.detractors / total)}
            )
          </Text>
        </Flex>
        <Flex justifyContent="space-between">
          <HStack space="s-8" align="center">
            <Icon name="QuestionOutline" size={15} color={Token.color.greyTone20} />
            <Text>I don't know</Text>
          </HStack>
          <Text>
            {averageScore.unanswered} ({formatPercentage(averageScore.unanswered / total)}
            )
          </Text>
        </Flex>
      </VStack>
    </Box>
  )
}
