import React from 'react'
import InputsSkeleton from '@components/Skeletons/InputsSkeleton'

type Props = {
  loading?: boolean
  fields?: React.ReactNode[]
}

const OfferCreationCustomFields = ({ loading, fields }: Props) => {
  if (loading) {
    return <InputsSkeleton />
  }

  return <>{fields}</>
}

export default OfferCreationCustomFields
