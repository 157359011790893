import React, { useMemo } from 'react'
import {
  PageHeader,
  PageHeaderRouterAction,
} from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import CRMTable from '@src/features/CRM/CRMTable'
import { useParams } from 'react-router-dom'
import { useGetCRMReview } from '@src/api/CRM'
import PageLoading from '@src/components/PageLoading/PageLoading'
import { FilterByInterface } from '@src/interfaces/data'

type CRMReviewSummaryParams = {
  id: string
}

export const CRMReviewSummary = () => {
  const { id } = useParams<CRMReviewSummaryParams>()
  const { data, isLoading: loadingCRMReview } = useGetCRMReview(id)
  const initialFilterBy: FilterByInterface[] = useMemo(() => {
    if (data?.interview_round_ids) {
      return [
        {
          filters: [
            {
              name: 'id',
              id: data.interview_round_ids.join(','),
            },
          ],
          columnName: 'id',
          nonResettable: true,
        },
      ]
    }
    return []
  }, [data])
  if (loadingCRMReview) {
    return <PageLoading />
  }
  return (
    <PageWrapper>
      <PageHeader
        title="Review summary"
        backUrl={pathToUrl(ROUTES.FORMS.CRM)}
        routerAction={PageHeaderRouterAction.navigate}
      />
      <PageBody maxWidth="100%">
        <CRMTable filterBy={initialFilterBy} reviewId={id} />
      </PageBody>
    </PageWrapper>
  )
}
