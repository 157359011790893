import React from 'react'

import { ROUTES } from '@src/constants/routes'
import { PermissionTypes } from '@src/store/auth/types'
import { SettingsForm } from '@src/pages/Settings/common/SettingsForm'
import { screeningSettings } from '@src/api/settings'
import { Screening } from './General'

const routes = [
  {
    title: 'Screening settings',
    description: 'Settings for the screening app',
    path: ROUTES.SETTINGS.SCREENING,
    url: ROUTES.SETTINGS.SCREENING,
    canView: [PermissionTypes.ViewScreeningPreferences],
    component: Screening,
  },
]

export const ScreeningSettings = () => {
  return <SettingsForm routes={routes} api={screeningSettings} />
}
