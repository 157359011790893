import { api, apiWithoutHandling } from './index'
import { API } from '../constants/api'
import {
  HireInterface,
  RequisitionBySpecialisationInterface,
  RequisitionDepartmentBudgetImpactInterface,
  RequisitionInterface,
  RequisitionTitlePostingInterface,
  StartDateOffsetInterface,
  RequisitionSelectorInterface,
  LocationInterface,
  RequisitionsJobPostingStats,
} from '../interfaces/requisitions'
import { filterSortPageIntoQuery } from '../utils/table'
import {
  GetRequestData,
  GetRequestInterface,
  RequestInterface,
  RequestInterfaceNew,
  Statuses,
} from '../interfaces'
import { AxiosResponse } from 'axios'
import { FetchDataQueryInterface, FilterByInterface } from '../interfaces/data'
import { UpdateOrderingInterface } from '@src/interfaces/ordering'
import { getCommentsAPI } from '@src/api/comments'
import { useFetch } from '@src/utils/reactQuery'
import { JobPostingInterface } from '@src/interfaces/jobPosting'
import { LegacyApprovalStep } from '@src/interfaces/approvalFlow'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'
import { OptionInterface } from '@src/interfaces/selectors'

export const publishRequisitionApi = (
  id: number,
  unsavedData: Partial<RequisitionInterface>,
) =>
  api.patch(`${API.REQUISITIONS}/${id}`, {
    ...unsavedData,
    status: Statuses.opened,
  })

export const getDepartmentBudgetImpact = (
  requisition: RequisitionInterface,
): Promise<AxiosResponse<RequisitionDepartmentBudgetImpactInterface>> => {
  return apiWithoutHandling.put(
    `${API.REQUISITIONS}/${requisition.team.department_id}/fullDepartmentBudgetImpact`,
    {
      specialisation: requisition?.specialisation,
      locations: requisition?.locations,
      seniority_min: requisition?.seniority_min,
      seniority_max: requisition?.seniority_max,
      headcount: requisition?.headcount,
      requisition_id: requisition?.id,
    },
    undefined,
    'v2',
  )
}

export const requisitionsRequests: RequestInterface<RequisitionInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    apiWithoutHandling.get(
      API.REQUISITIONS,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v2',
    ),
  getStats: async ({ filters, page }) =>
    apiWithoutHandling.get(
      API.REQUISITIONS_STATS,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.REQUISITIONS}/${id}`),
  patchItem: async (data, requisitionId) =>
    api.patch(`${API.REQUISITIONS}/${requisitionId}`, data),
  deleteItem: async id => api.delete(`${API.REQUISITIONS}/${id}`),
  postItem: async data => api.post(API.REQUISITIONS, data),
  getExport: async (exportType, filterQuery) =>
    api.get(
      `${API.REQUISITIONS}/${exportType}`,
      {
        params: filterQuery,
        responseType: 'blob',
      },
      'v2',
    ),
}

export const requisitionsRequestsNew: RequestInterfaceNew<RequisitionInterface> = {
  get: async ({ id }) => api.get(`${API.REQUISITIONS}/${id}`),
  update: async (diff, { id }, data) => {
    const { backfill_employees, ...rest } = diff
    const payload = data?.backfill ? diff : rest
    return apiWithoutHandling.patch(`${API.REQUISITIONS}/${id}`, payload)
  },
  submit: async data => {
    const { backfill_employees, ...rest } = data
    const payload = data.backfill ? data : rest
    return apiWithoutHandling.post(API.REQUISITIONS, payload)
  },
  delete: async ({ id }) => api.delete(`${API.REQUISITIONS}/${id}`),
}

export const requisitionsOrderingRequests: RequestInterface<RequisitionInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(
      `${API.REQUISITIONS}/ordering`,
      {
        params: filterSortPageIntoQuery(sortBy, filters, page),
      },
      'v1',
    ),
  getStats: async ({ filters, page }) =>
    api.get(
      API.REQUISITIONS_STATS,
      {
        params: filterSortPageIntoQuery(undefined, filters, page),
      },
      'v2',
    ),
  getItem: async id => api.get(`${API.REQUISITIONS}/${id}`),
  patchItem: async data =>
    api.patch(`${API.REQUISITIONS}/ordering/itemPositionUpdate`, data),
  deleteItem: async id => api.delete(`${API.REQUISITIONS}/${id}`),
  postItem: async data =>
    api.post(`${API.REQUISITIONS}/ordering/itemPositionUpdate`, data),
}

export const updateRequisitionsOrder = (
  data: UpdateOrderingInterface,
  filters: FilterByInterface[],
) =>
  api.post(`${API.REQUISITIONS}/ordering/itemPositionUpdate`, data, {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const getStartDateOffset = (seniorityId: number) =>
  api.get<StartDateOffsetInterface>(`${API.REQUISITIONS}/minimumStartDateOffsetDays`, {
    params: { seniority_id: seniorityId },
  })

export const getRequisitionsCommentsAPI = (requisitionId: string | number) =>
  getCommentsAPI({ baseUrl: `${API.REQUISITIONS}/${requisitionId}/comments` })

export const useGetJobDescriptionOfRequisition = (id?: number) =>
  useFetch<{ job_posting: JobPostingInterface }>(
    id ? `${API.REQUISITIONS}/requisitionPostings/${id}` : null,
    undefined,
    undefined,
    true,
  )

export const patchJobDescriptionOfRequisition = (
  id: number | string,
  data: { job_posting: Partial<JobPostingInterface> },
) =>
  apiWithoutHandling.patch(`${API.REQUISITIONS}/requisitionPostings/${id}`, {
    ...data,
  })

export const getRequisitionHires = (
  requisitionId: string | number,
  { sortBy, filters, page }: FetchDataQueryInterface,
) =>
  api.get<GetRequestInterface<HireInterface>>(
    `${API.REQUISITIONS}/${requisitionId}/hires`,
    {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    },
  )

export const useGetRequisitionHires = (
  requisitionId?: string | number,
  filters?: FetchDataQueryInterface['filters'],
) =>
  useFetch<GetRequestInterface<HireInterface>>(
    requisitionId ? `${API.REQUISITIONS}/${requisitionId}/hires` : null,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
  )

export const moveRequisitionHire = (
  employeeId: number,
  requisitionId: number | string,
  newRequisitionId: number | string,
) =>
  api.patch(`${API.REQUISITIONS}/${requisitionId}/hires/${employeeId}/moveHire`, {
    requisition: {
      id: Number(newRequisitionId),
    },
  })

export const cancelRequisitionHire = (
  employeeId: number,
  requisitionId: number | string,
) =>
  apiWithoutHandling.patch(
    `${API.REQUISITIONS}/${requisitionId}/hires/${employeeId}/cancelHire`,
  )

export const toggleRequisitionRegulation = (
  requisitionId: number | string,
  isRegulated: boolean,
) =>
  api.post(`${API.REQUISITIONS}/${requisitionId}/setIsRegulated`, {
    is_regulated: isRegulated,
  })

export const useGetRequisitionApprovals = (id?: number) => {
  return useFetch<LegacyApprovalStep[]>(id ? `${API.REQUISITIONS}/${id}/approvals` : null)
}

export const getRequisitionTitlePosting = async (specialisationId: number) =>
  api.get<RequisitionTitlePostingInterface>(
    `${API.SPECIALISATIONS}/${specialisationId}/requisition_title_job_postings`,
  )

export const checkAddRequisitionPermitted = (teamId: number | string) =>
  apiWithoutHandling.get(`/requisitions/has_permission_to_create_requisition`, {
    params: {
      team_id: teamId,
    },
  })

export const useGetRequisitionsBySpecialisation = (id?: number | string) =>
  useFetch<RequisitionBySpecialisationInterface[]>(
    id ? `/specialisations/${id}/requisitions` : null,
  )

export const useGetOpenRequisitionsByJobPosting = (id?: number | string) =>
  useFetch<GetRequestData<RequisitionInterface>>(id ? API.REQUISITIONS : null, 'v2', {
    params: {
      requisition_posting__job_posting: id,
      status: 'opened',
    },
  })

export const useGetPendingRequisitionsByJobPosting = (id?: number | string) =>
  useFetch<GetRequestData<RequisitionInterface>>(id ? API.REQUISITIONS : null, 'v2', {
    params: {
      requisition_posting__job_posting: id,
      status: 'pending',
    },
  })

export const publishRequisition = (
  id: number,
  unsavedData: Partial<RequisitionInterface>,
) => {
  publishRequisitionApi(id, unsavedData).then(res => {
    let backUrl
    if (res.data?.id) {
      backUrl = pathToUrl(ROUTES.FORMS.REQUISITION.ROLE, {
        id: res.data?.id,
      })
    }
    pushNotification({
      backUrl,
      value: 'Requisition successfully published',
      duration: SUCCESS_DEFAULT_DURATION,
      type: NotificationTypes.success,
    })
    return res.data
  })
}

export const changeConfidentiality = (id: number | string, is_confidential: boolean) =>
  apiWithoutHandling.patch(`${API.REQUISITIONS}/${id}/changeConfidentiality`, {
    is_confidential,
  })

export const getRequisitionsSelector = async (
  isConfidential?: boolean,
  hideIds?: boolean,
) => {
  try {
    const result = await api.get<RequisitionSelectorInterface[]>(
      API.REQUISITIONS_SELECTOR,
      isConfidential === undefined
        ? undefined
        : {
            params: {
              is_confidential: isConfidential ? 'True' : 'False',
            },
          },
      'v2',
    )
    return (result?.data?.map(r => {
      const name = `${r.requisition_title}${hideIds ? '' : ` #${r.id}`}`
      return {
        id: r.id,
        name,
        value: {
          ...r,
          name,
        },
      }
    }) ?? []) as OptionInterface[]
  } catch (e) {
    return []
  }
}

export const getRequisitionsSelectorOptions = async (
  isConfidential?: boolean,
  hideIds?: boolean,
) => ({
  options: await getRequisitionsSelector(isConfidential, hideIds),
})

export const useGetRequisitionsSelector = () =>
  useFetch<RequisitionSelectorInterface[]>(
    `${API.REQUISITIONS_SELECTOR}?is_confidential=True`,
    'v2',
  )

export const useGetConfidentialRequisitionPermissions = () =>
  useFetch<{ display_confidential_button: boolean }>(
    API.REQUISITIONS_VIEW_CONFIDENTIAL_BUTTON,
    'v2',
  )

export const useGetHiringEnabledLocations = () =>
  useFetch<LocationInterface[]>(API.HIRING_ENABLED_LOCATIONS)

export const useGetRequisition = (id?: number | string) =>
  useFetch<RequisitionInterface>(id ? `${API.REQUISITIONS}/${id}` : null)

export const useGetJobPostingRequisitions = (
  jobPostingId?: number,
  isConfidential = false,
) =>
  useFetch<{ results: RequisitionInterface[] }>(
    jobPostingId ? API.REQUISITIONS : null,
    'v1',
    {
      params: {
        status: 'pending,approved,opened',
        requisition_posting__job_posting: jobPostingId,
        is_confidential: isConfidential ? 'True' : 'False',
      },
    },
  )

export const useGetJobPostingRequisitionStats = (
  jobPostingId?: number,
  isConfidential = false,
) =>
  useFetch<RequisitionsJobPostingStats>(
    jobPostingId ? API.REQUISITIONS_STATS : null,
    'v2',
    {
      params: {
        status: 'pending,approved,opened',
        requisition_posting__job_posting: jobPostingId,
        is_confidential: isConfidential ? 'True' : 'False',
      },
    },
  )

export const requisitionPostingSimpleRequests: RequestInterfaceNew<RequisitionInterface> =
  {
    get: async ({ id }) =>
      api.get(`${API.REQUISITION_POSTING_SIMPLE}/${id}`, undefined, 'v2'),
    update: async (diff, { id }) =>
      apiWithoutHandling.patch(
        `${API.REQUISITION_POSTING_SIMPLE}/${id}`,
        diff,
        undefined,
        'v2',
      ),
    submit: async data =>
      apiWithoutHandling.post(API.REQUISITION_POSTING_SIMPLE, data, undefined, 'v2'),
    delete: async ({ id }) =>
      api.delete(`${API.REQUISITION_POSTING_SIMPLE}/${id}`, undefined, 'v2'),
  }
