import React from 'react'
import Form from '@src/features/Form/Form'
import { formHiringProcessStagesRoleRequest } from '@src/api/hiringProcess'

import { connect } from 'lape'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import HiringStageSidebarPreview from '@src/features/HiringStageSidebarPreview/HiringStageSidebarPreview'
import { RoleInterface } from '@src/interfaces/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { updateStagesAfterDelete } from '@src/utils/hiring'

interface Props {
  id?: number
  onClose: () => void
}

const HiringStagePreviewForm = ({ id, onClose }: Props) => {
  const context = useLapeContext<RoleInterface>()

  if (!id) {
    return null
  }

  const onSuccessDelete = () => {
    updateStagesAfterDelete<RoleInterface>(context, id!)
    onClose()
  }

  return (
    <Form<HiringProcessInterface>
      api={formHiringProcessStagesRoleRequest}
      forceParams={{ id: String(id) }}
      disableLoading
    >
      <HiringStageSidebarPreview
        type="role"
        onSuccessDelete={onSuccessDelete}
        onClose={onClose}
      />
    </Form>
  )
}

export default connect(HiringStagePreviewForm)
