import { selectorKeys } from '@src/constants/api'
import { useEngagementResultStats, useEngagementSurveyRounds } from '@src/api/engagement'
import { useGetSelectors } from '@src/api/selectors'
import { EngagementResultsScope } from '@src/interfaces/engagement'
import { useParams } from 'react-router-dom'
import { mapTableTypeToStatEntity } from '@src/pages/Performance/Engagement/helpers/mapTableTypeToStatEntity'

export const useGetNPS = () => {
  const { data: surveys, isLoading: isSurveysLoading } = useGetSelectors(
    selectorKeys.engagement_survey_titles_sorted,
  )
  const { data: roundsData, isLoading: isRoundsLoading } = useEngagementSurveyRounds(
    surveys?.[0]?.id,
  )

  const { subtab: tableType } =
    useParams<{ subtab: 'categories' | 'questions' | 'comments' }>()

  const { data: stats, isLoading } = useEngagementResultStats({
    surveyId: surveys?.[0]?.id,
    roundId: roundsData?.results?.[0]?.id,
    entity: mapTableTypeToStatEntity(tableType),
    // this is used on the company tab always with id 1
    scope: { type: EngagementResultsScope.Company, id: 1 },
  })

  return {
    isLoading: isLoading || isSurveysLoading || isRoundsLoading,
    score: stats?.nps_score,
  }
}
