import React from 'react'
import { JiraIssueInterface } from '@src/interfaces/probationReview'
import { searchJiraTickets } from '@src/api/probationReview'
import FetchingDropdown from '@components/FetchingDropdown/FetchingDropdown'
import { Link } from '@revolut/ui-kit'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

type Props<T> = {
  onChange: (option?: JiraIssueInterface) => void
  onFilter: (issue: JiraIssueInterface) => boolean
  disabled?: boolean
  data: T[]
}

const AddTicketDropdown = <T extends {}>({
  onFilter,
  onChange,
  disabled,
  data,
}: Props<T>) => {
  const searchJiraTicketsInstance = searchJiraTickets()

  return (
    <FetchingDropdown<JiraIssueInterface, T>
      onFilter={onFilter}
      onChange={onChange}
      api={searchJiraTicketsInstance}
      disabled={disabled}
      message={
        <HideIfCommercial>
          Cant find your ticket? Make sure jira.api@revolut.com user has read access to
          your project.{' '}
          <Link href={FETCH_JIRA_ROADMAP} target="_blank" rel="noreferrer noopener">
            Click here for help
          </Link>
        </HideIfCommercial>
      }
      placeholder="Enter the ticket key, url or title (minimum 4 characters) to search"
      data={data}
    />
  )
}

export default AddTicketDropdown
