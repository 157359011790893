import React, { useEffect } from 'react'
import { connect } from 'lape'
import { InputGroup } from '@revolut/ui-kit'
import { PageBody } from '@src/components/Page/PageBody'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { InternalDashboardInterface } from '@src/interfaces/analyticsDashboards'

export const Details = connect(() => {
  const { values } = useLapeContext<InternalDashboardInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  return (
    <PageBody>
      <InputGroup>
        <LapeNewInput label="Dashboard name" name="name" required />
        <LapeNewTextArea label="Description" name="description" rows={3} />
      </InputGroup>
    </PageBody>
  )
})
