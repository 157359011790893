import React from 'react'
import { PreviousReviewInterface } from '@src/interfaces/performance'
import { Box, Color, Text } from '@revolut/ui-kit'
import OverallFeedback from '@src/pages/Forms/EmployeePerformance/OverallFeedback'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

interface Props {
  data?: PreviousReviewInterface[]
}

const PreviousOverallFeedback = ({ data }: Props) => {
  const user = useSelector(selectUser)

  if (!data?.length) {
    return null
  }

  return (
    <Box py="s-32">
      <Text color={Color.GREY_TONE_50} fontWeight={500} fontSize="caption">
        Previous feedbacks
      </Text>

      <Box mt="s-16">
        {data.map((feedback, i) => {
          return (
            <OverallFeedback
              key={i}
              avatar={user.avatar}
              title={
                feedback.cycle ? feedback.cycle.name : `CP${feedback.checkpoint?.number}`
              }
              name={user.full_name}
              reviewerId={user.id}
              pros={feedback.review.overall_feedback?.pros}
              cons={feedback.review.overall_feedback?.cons}
              disabled
            />
          )
        })}
      </Box>
    </Box>
  )
}

export default PreviousOverallFeedback
