import React from 'react'
import { Tooltip, useTooltip } from '@revolut/ui-kit'
import LapeRadioSelectInput from '@src/components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SpecialisationInterface } from '@src/interfaces/roles'

export const ParentRoleField = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const tooltip = useTooltip()
  const disableRoleChange = !!values.performance_weights?.length // REVC-7546 changing role when weights are set is broken. BE ignores update and keeps weights for previous parent skills
  return (
    <div {...tooltip.getAnchorProps()}>
      <LapeRadioSelectInput
        disabled={disableRoleChange}
        name="role"
        label="Parent role"
        selector={selectorKeys.role}
        onAfterChange={value => {
          values.name = value?.name ? `${value.name} (specialisation)` : ''
        }}
      />
      {disableRoleChange && (
        <Tooltip width="350px" {...tooltip.getTargetProps()}>
          Unable to change the parent role for existing specialisations. Please create a
          new specialisation inside the desired role, or duplicate an existing one.
        </Tooltip>
      )}
    </div>
  )
}
