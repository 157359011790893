import React, { useEffect } from 'react'
import { Header, Flex, Button, ActionButton, Token } from '@revolut/ui-kit'
import { connect } from 'lape'

import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { onboardingSteps } from '@src/interfaces/onboarding'
import { navigateTo } from '@src/actions/RouterActions'
import { PageBody } from '@src/components/Page/PageBody'
import {
  DiversityFormFields,
  IncompletePopup,
  useIncompletePopupState,
} from '@src/pages/EmployeeProfile/Forms/Diversity/General'
import { BackButton, NextButton, TabProps, useOnboardingPreviewMode } from '../common'
import { PageActions } from '@src/components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { DiversityInterface } from '@src/interfaces/employees'
import { pushError } from '@src/store/notifications/actions'

const Diversity = ({ data, onComplete, setDiversityFaqOpen, prevRoute }: TabProps) => {
  useEffect(() => {
    setDiversityFaqOpen(true)

    return () => {
      setDiversityFaqOpen(false)
    }
  }, [])

  const { submit, isSubmitting } = useLapeContext<DiversityInterface>()

  const {
    incompleteFields,
    onExited,
    onClose,
    confirmationPopupOpen,
    setConfirmationPopupOpen,
  } = useIncompletePopupState()

  const navigateToNextStep = () => {
    navigateTo(pathToUrl(ROUTES.ONBOARDING.DOCUMENTS, { id: data.id }))
    onComplete(onboardingSteps.diversity)
  }

  const onConfirmIncompletePopup = () => {
    submit().then(navigateToNextStep).catch(pushError)
  }

  const isPreviewMode = !!useOnboardingPreviewMode().config

  return (
    <>
      <PageBody pb="s-24">
        <Header color={Token.color.foreground} variant="main">
          <Header.Title>Diversity</Header.Title>
          <Header.Subtitle>
            This information will be anonymised and is completely optional.
          </Header.Subtitle>
        </Header>

        <ActionButton
          onClick={() => setDiversityFaqOpen(true)}
          useIcon="InfoOutline"
          mb="s-24"
        >
          FAQs
        </ActionButton>

        <DiversityFormFields isOnboarding />
      </PageBody>

      <PageActions>
        <Flex gap="s-16">
          <BackButton onClick={() => (prevRoute ? navigateTo(prevRoute) : undefined)} />
          {!isPreviewMode && incompleteFields.length > 0 ? (
            <Button onClick={() => setConfirmationPopupOpen(true)}>Next</Button>
          ) : (
            <NextButton
              onClickInPreviewMode={navigateToNextStep}
              afterSubmit={navigateToNextStep}
              elevated
            />
          )}
        </Flex>
      </PageActions>

      <IncompletePopup
        open={confirmationPopupOpen}
        onConfirm={onConfirmIncompletePopup}
        onReject={onClose}
        onClose={onClose}
        onExited={onExited}
        loading={isSubmitting}
      />
    </>
  )
}

export default connect(Diversity)
