import React from 'react'
import { useSelector } from 'react-redux'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'
import { TeamGoalsCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/TeamGoalsCardTable'
import { DeliverablesCardTable } from '@src/pages/Forms/EmployeePerformanceLayout/components/DeliverablesCardTable'
import { KPIsCardTableProps } from '@src/pages/Forms/EmployeePerformanceLayout/components/KPIsCardTable'

export const TeamGoalsList = ({ ...props }: KPIsCardTableProps) => {
  const featureFlags = useSelector(selectFeatureFlags)
  const goalsEnabled = featureFlags.includes(FeatureFlags.CanAddGoals)
  return goalsEnabled ? (
    <TeamGoalsCardTable {...props} />
  ) : (
    <DeliverablesCardTable {...props} />
  )
}
