import { VStack, Text, Flex, Box, Token } from '@revolut/ui-kit'
import React from 'react'
import { DataKey } from '@src/pages/Forms/QueryForm/components/Charts/types'
import { formatIfNumber } from '@src/pages/Forms/QueryForm/components/Charts/helpers'

interface TooltipValueInterface {
  label: string
  value: string
  color?: string
}

export const DefaultTooltip = (
  props: any,
  dataKeys?: DataKey<any>[],
  colors?: string[],
) => {
  const values = dataKeys
    ?.reduce((acc: TooltipValueInterface[], dataKey) => {
      if (props[dataKey.value]) {
        acc.push({
          label: dataKey.label,
          value: props[dataKey.value],
        })
      }
      return acc
    }, [])
    .map((val, id) => (colors?.[id] ? { ...val, color: colors[id] } : val))

  return (
    <VStack space="s-4">
      <Text variant="h6">{props.label || props.name}</Text>

      {dataKeys ? (
        values?.map((value, id) => (
          <Flex justifyContent="flex-start" key={id} alignItems="center" gap="s-6">
            {value?.color ? (
              <Box
                bg={value.color}
                width="s-12"
                height="s-12"
                border={`1px solid ${Token.color.greyTone50}`}
                borderRadius={Token.radius.r2}
              />
            ) : null}
            <Text fontSize="small" color={Token.color.background} use="div">
              {value.label}:
            </Text>
            <Text fontSize="small" color={Token.color.background} use="div" ml="auto">
              {formatIfNumber(value.value)}
            </Text>
          </Flex>
        ))
      ) : (
        <Flex justifyContent="space-between">
          <Text fontSize="small" color={Token.color.background} pr="s-16">
            Value:
          </Text>
          <Text fontSize="small" color={Token.color.background}>
            {formatIfNumber(props.value)}
          </Text>
        </Flex>
      )}
    </VStack>
  )
}
