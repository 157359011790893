export const rowWrapperMinHeight = {
  small: 32,
  medium: 48,
  large: 72,
}

export const firstColumnFontSize = {
  small: 14,
  medium: 14,
  large: 16,
}

export const rowHeightToWidthMultiplier = {
  small: 0.7,
  medium: 0.85,
  large: 1,
}
