import React from 'react'
import { VStack } from '@revolut/ui-kit'
import SectionTitle from '@src/pages/OnboardingChecklist/components/SectionTitle'
import SpecialisationList from '@src/pages/OnboardingChecklist/HiringProcess/CustomiseHiringProcess/SpecialisationList'

const CustomiseHiringProcess = () => {
  return (
    <VStack gap="s-16">
      <SectionTitle
        title="Customise hiring process"
        subtitle="Review and adjust hiring stages for each specialisation"
      />
      <SpecialisationList />
    </VStack>
  )
}

export default CustomiseHiringProcess
