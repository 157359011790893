import React, { useEffect, useState } from 'react'
import { connect } from 'lape'
import {
  ActionButton,
  Cell,
  ExpandableCell,
  Input,
  InputGroup,
  StatusWidget,
  Text,
  Token,
  VStack,
} from '@revolut/ui-kit'

import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageWrapper } from '@components/Page/Page'
import { PageActions } from '@components/Page/PageActions'
import { PageBody } from '@components/Page/PageBody'
import AutoStepper from '@components/Stepper/AutoStepper'
import NewStepperTitle from '@components/Stepper/NewStepperTitle'
import { paygroupRequests } from '@src/api/payroll'
import { useGetSelectors } from '@src/api/selectors'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'
import SideBar from '@src/components/SideBar/SideBar'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'
import { IdAndName } from '@src/interfaces'
import { PaygroupInterface, PayGroupTrigger } from '@src/interfaces/payroll'
import { OptionInterface } from '@src/interfaces/selectors'
import { PaycyclesPreview } from '../../PayCycle/Preview'
import { DetailsTabInputName } from '../constants'
import { formatValuesBeforeSubmit, formatValuesOnLoad } from '../helpers'
import { PayElementsTable } from '../PayElementsTable'
import { AddPayElementSidebar } from './AddPayElementSidebar'

const PAY_AUTHORITIES_FREQUENCY_DEFAULT = {
  id: 'monthly' as const,
  name: 'Monthly',
}

interface PaygroupEditFormProps {
  bulkEdit?: boolean
}

export const PaygroupEditForm = ({ bulkEdit }: PaygroupEditFormProps) => {
  const { values, submit, isSubmitting } = useLapeContext<PaygroupInterface>()

  const [addPayElementModalOpen, setAddPayElementModalOpen] = useState(false)
  const [deletePayElementId, setDeletePayElementId] = useState<number | undefined>(
    undefined,
  )
  const [editPayElementId, setEditPayElementId] = useState<number | undefined>(undefined)
  const [showPreviewSidebar, setShowPreviewSidebar] = useState(true)
  const [expandedPayAuthorities, setExpandedPayAuthorities] = useState(false)

  const triggerOptions = useGetSelectors<PayGroupTrigger>(selectorKeys.pay_group_triggers)
  const triggerSelector = () => Promise.resolve(triggerOptions.data as OptionInterface[])

  const weekDayOptions = useGetSelectors<IdAndName>(selectorKeys.days_of_the_week)
  const monthDayOptions = useGetSelectors<IdAndName>(selectorKeys.month_days)
  const monthOptions = useGetSelectors<IdAndName>(selectorKeys.months)

  const isWeekly = ['weekly', 'bi_weekly'].includes(values?.pay_frequency?.id)
  const isTwice = ['bi_monthly', 'bi_weekly'].includes(values?.pay_frequency?.id)
  const isPayingAuthoritiesQuarterly =
    values?.pay_authorities_frequency?.id === 'quarterly'

  const findWeekDayId = (value: number | null) =>
    weekDayOptions.data?.find(option => option.id === value) || null
  const findMonthDayId = (value: number | null) =>
    monthDayOptions.data?.find(option => option.id === value) || null
  const findMonthId = (value: number | null) =>
    monthOptions.data?.find(option => option.id === value) || null

  const canShowPreview = [
    values.pay_frequency,
    values.pay_period_start_day,
    values.pay_date_schedule,
    values.pay_date_day,
    values.pay_date_lands_on_weekend,
    values.pay_authorities_frequency,
    values.pay_authorities_date_day_q4 || values.pay_authorities_date_day_of_month,
    values.cut_off_date_trigger_number_of_days,
    values.cut_off_date_trigger,
    values.report_submission_date_days_after_cycle_ends,
    values.report_submission_date_trigger,
  ].every(Boolean)

  useEffect(() => {
    if (!values.pay_authorities_frequency && !values.id) {
      values.pay_authorities_frequency = PAY_AUTHORITIES_FREQUENCY_DEFAULT
    }
    if (values.pay_authorities_frequency?.id === 'quarterly') {
      setExpandedPayAuthorities(true)
    }
  }, [values.pay_authorities_frequency, values.pay_authorities_frequency?.id])

  useEffect(() => {
    if (!values.pay_authorities_date_day_of_month && values.pay_date_day) {
      values.pay_authorities_date_day_of_month = values.pay_date_day
    }
  }, [values.pay_authorities_date_day_of_month, values.pay_date_day])

  const renderContent = () => {
    return (
      <AutoStepper>
        <NewStepperTitle title="Basic information" />
        <InputGroup>
          {!bulkEdit && (
            <>
              <LapeRadioSelectInput
                name={DetailsTabInputName.Country}
                label="Country"
                selector={selectorKeys.countries}
              />
              <LapeRadioSelectInput
                name={DetailsTabInputName.Entity}
                label="Entity"
                selector={selectorKeys.entity}
              />
              {values.country && (
                <LapeRadioSelectInput
                  name={DetailsTabInputName.Currency}
                  label="Currency"
                  selector={selectorKeys.currencies}
                  disabled
                />
              )}
              <LapeRadioSelectInput
                name={DetailsTabInputName.Owner}
                label="Owner"
                selector={selectorKeys.employee}
              />
            </>
          )}
          <LapeRadioSelectInput
            label="Payroll provider"
            name={DetailsTabInputName.Provider}
            selector={selectorKeys.payroll_providers}
          />
        </InputGroup>

        <NewStepperTitle title="Schedule" />

        <InputGroup>
          <LapeRadioSelectInput
            name="pay_frequency"
            label="Pay frequency"
            selector={selectorKeys.pay_frequencies}
          />
          {isWeekly ? (
            <LapeRadioSelectInput
              name="pay_period_start_day"
              label="Pay period start (day of week)"
              selector={selectorKeys.days_of_the_week}
              value={findWeekDayId(values.pay_period_start_day)}
              onChange={value => {
                values.pay_period_start_day = value?.id || null
              }}
            />
          ) : (
            <LapeRadioSelectInput
              name="pay_period_start_day"
              label="Pay period start (day of month)"
              selector={selectorKeys.month_days}
              value={findMonthDayId(values.pay_period_start_day)}
              onChange={value => {
                values.pay_period_start_day = value?.id || null
              }}
            />
          )}
          {!isWeekly && isTwice && (
            <LapeRadioSelectInput
              name="pay_period_start_day"
              label="Second pay period start (day of month)"
              selector={selectorKeys.month_days}
              value={findMonthDayId(values.pay_period_second_start_day)}
              onChange={value => {
                values.pay_period_second_start_day = value?.id || null
              }}
            />
          )}
          <ExpandableCell aria-label="Advanced pay authorities frequency settings">
            <ExpandableCell.Title>Advanced schedule settings</ExpandableCell.Title>
            <ExpandableCell.Note>
              <LapeRadioSelectInput
                name="pay_authorities_frequency"
                label="Pay authorities frequency"
                selector={selectorKeys.pay_authorities_frequencies}
              />
            </ExpandableCell.Note>
          </ExpandableCell>
        </InputGroup>

        <NewStepperTitle title="Deadlines" />

        <Text use="div" variant="h6" color={Token.color.greyTone50} my="s-16">
          Pay date
        </Text>
        <InputGroup>
          <InputGroup variant="horizontal" data-testid="pay_date_section">
            {isWeekly ? (
              <LapeRadioSelectInput
                name="pay_date_day"
                label="Pay date (day of week)"
                selector={selectorKeys.days_of_the_week}
                value={findWeekDayId(values.pay_date_day)}
                onChange={value => {
                  values.pay_date_day = value?.id || null
                }}
              />
            ) : (
              <LapeRadioSelectInput
                name="pay_date_day"
                label="Pay date"
                selector={selectorKeys.month_days}
                value={findMonthDayId(values.pay_date_day)}
                onChange={value => {
                  values.pay_date_day = value?.id || null
                }}
              />
            )}
            {!isWeekly && isTwice && (
              <LapeRadioSelectInput
                name="pay_date_second_day"
                label="Second"
                optional
                selector={selectorKeys.month_days}
                value={findMonthDayId(values.pay_date_second_day)}
                onChange={value => {
                  values.pay_date_second_day = value?.id || null
                }}
              />
            )}

            <LapeRadioSelectInput
              name="pay_date_lands_on_weekend"
              label="If pay date lands on weekend or holiday"
              selector={selectorKeys.pay_date_lands_on_weekend_choices}
            />
          </InputGroup>
          <LapeRadioSelectInput
            name="pay_date_schedule"
            label="Pay date schedule"
            selector={selectorKeys.pay_date_schedules}
          />
        </InputGroup>

        <Text use="div" variant="h6" color={Token.color.greyTone50} my="s-16">
          Employee changes (cut off)
        </Text>
        <InputGroup variant="horizontal" data-testid="cut_off_date_section">
          <LapeNewInput
            required
            type="number"
            name="cut_off_date_trigger_number_of_days"
            label="Number of business days"
          />
          <Input disabled value="days" />
          <LapeRadioSelectInput
            name="cut_off_date_trigger"
            label="Trigger"
            selector={triggerSelector}
          />
        </InputGroup>

        <Text use="div" variant="h6" color={Token.color.greyTone50} my="s-16">
          Report submission
        </Text>
        <InputGroup variant="horizontal" data-testid="report_submission_date_section">
          <LapeNewInput
            required
            type="number"
            name="report_submission_date_days_after_cycle_ends"
            label="Number of business days"
          />
          <Input disabled value="days" />
          <LapeRadioSelectInput
            name="report_submission_date_trigger"
            label="Trigger"
            selector={triggerSelector}
          />
        </InputGroup>

        <ExpandableCell
          mt="s-24"
          expanded={expandedPayAuthorities}
          onToggle={setExpandedPayAuthorities}
          aria-label="Advanced pay authorities schedule"
        >
          <ExpandableCell.Title>Advanced deadlines settings</ExpandableCell.Title>
          <ExpandableCell.Note>
            <Text use="div" variant="h6" color={Token.color.greyTone50} mb="s-16">
              Pay authorities
            </Text>
            {isPayingAuthoritiesQuarterly ? (
              <InputGroup variant="vertical">
                <InputGroup variant="horizontal">
                  <LapeRadioSelectInput
                    name="pay_authorities_date_month_q1"
                    label="Q1 Month"
                    selector={selectorKeys.months}
                    value={findMonthId(values.pay_authorities_date_month_q1)}
                    onChange={value => {
                      values.pay_authorities_date_month_q1 = value?.id || null
                    }}
                  />

                  <LapeRadioSelectInput
                    name="pay_authorities_date_day_q1"
                    label="Q1 Day"
                    selector={selectorKeys.month_days}
                    value={findMonthDayId(values.pay_authorities_date_day_q1)}
                    onChange={value => {
                      values.pay_authorities_date_day_q1 = value?.id || null
                    }}
                  />
                </InputGroup>

                <InputGroup variant="horizontal">
                  <LapeRadioSelectInput
                    name="pay_authorities_date_month_q2"
                    label="Q2 Month"
                    selector={selectorKeys.months}
                    value={findMonthId(values.pay_authorities_date_month_q2)}
                    onChange={value => {
                      values.pay_authorities_date_month_q2 = value?.id || null
                    }}
                  />

                  <LapeRadioSelectInput
                    name="pay_authorities_date_day_q2"
                    label="Q2 Day"
                    selector={selectorKeys.month_days}
                    value={findMonthDayId(values.pay_authorities_date_day_q2)}
                    onChange={value => {
                      values.pay_authorities_date_day_q2 = value?.id || null
                    }}
                  />
                </InputGroup>

                <InputGroup variant="horizontal">
                  <LapeRadioSelectInput
                    name="pay_authorities_date_month_q3"
                    label="Q3 Month"
                    selector={selectorKeys.months}
                    value={findMonthId(values.pay_authorities_date_month_q3)}
                    onChange={value => {
                      values.pay_authorities_date_month_q3 = value?.id || null
                    }}
                  />

                  <LapeRadioSelectInput
                    name="pay_authorities_date_day_q3"
                    label="Q3 Day"
                    selector={selectorKeys.month_days}
                    value={findMonthDayId(values.pay_authorities_date_day_q3)}
                    onChange={value => {
                      values.pay_authorities_date_day_q3 = value?.id || null
                    }}
                  />
                </InputGroup>

                <InputGroup variant="horizontal">
                  <LapeRadioSelectInput
                    name="pay_authorities_date_month_q4"
                    label="Q4 Month"
                    selector={selectorKeys.months}
                    value={findMonthId(values.pay_authorities_date_month_q4)}
                    onChange={value => {
                      values.pay_authorities_date_month_q4 = value?.id || null
                    }}
                  />

                  <LapeRadioSelectInput
                    name="pay_authorities_date_day_q4"
                    label="Q4 Day"
                    selector={selectorKeys.month_days}
                    value={findMonthDayId(values.pay_authorities_date_day_q4)}
                    onChange={value => {
                      values.pay_authorities_date_day_q4 = value?.id || null
                    }}
                  />
                </InputGroup>
              </InputGroup>
            ) : (
              <InputGroup variant="horizontal" data-testid="pay_authorities_date_section">
                <LapeRadioSelectInput
                  name="pay_authorities_date_day_of_month"
                  label="Pay authorities date (day of month)"
                  selector={selectorKeys.month_days}
                  value={findMonthDayId(values.pay_authorities_date_day_of_month)}
                  onChange={value => {
                    values.pay_authorities_date_day_of_month = value?.id || null
                  }}
                />

                <LapeRadioSelectInput
                  name="pay_date_lands_on_weekend"
                  label="If pay date lands on weekend or holiday"
                  selector={selectorKeys.pay_date_lands_on_weekend_choices}
                />
              </InputGroup>
            )}
          </ExpandableCell.Note>
        </ExpandableCell>

        {!bulkEdit && (
          <HideIfCommercial>
            <NewStepperTitle title="Add payroll elements" />

            <Cell>
              <VStack gap="s-16">
                <ActionButton
                  useIcon="Plus"
                  onClick={() => setAddPayElementModalOpen(true)}
                >
                  Add pay element
                </ActionButton>
                <PayElementsTable
                  payments={values.payroll_elements}
                  onEdit={id => {
                    setEditPayElementId(id)
                    setAddPayElementModalOpen(true)
                  }}
                  onDelete={id => {
                    setDeletePayElementId(id)
                  }}
                  isLoading={isSubmitting}
                />
              </VStack>
            </Cell>
          </HideIfCommercial>
        )}
      </AutoStepper>
    )
  }

  if (bulkEdit) {
    return <>{renderContent()}</>
  }

  return (
    <PageWrapper>
      <PageHeader
        title={values.id ? 'Edit payroll definitions' : 'Add payroll definitions'}
        subtitle={values.name}
        backUrl={ROUTES.SETTINGS.PAYROLL.CONFIGURATION}
      />
      <ActionButton
        onClick={() => setShowPreviewSidebar(prev => !prev)}
        useIcon="EyeShow"
      >
        Preview Paycycles
      </ActionButton>
      <PageBody>{renderContent()}</PageBody>
      <PageActions mt="s-64">
        <NewSaveButtonWithPopup
          useValidator
          previewUrl={ROUTES.APPS.PAYROLL.PAY_GROUP.PREVIEW}
          onClick={() => {
            formatValuesBeforeSubmit(values)
            return submit().catch(e => {
              formatValuesOnLoad(values)
              throw e
            })
          }}
          confirmationDialogue={
            values.id
              ? {
                  yesMessage: 'Confirm',
                  noMessage: 'Cancel',
                  label: '',
                  body: (
                    <VStack space="s-16">
                      <Text variant="h5">
                        New pay schedule will be applied to all scheduled pay cycles. The
                        pay schedule for ongoing pay cycle cannot be changed.
                      </Text>
                      <Text color={Token.color.greyTone50}>Do you want to continue?</Text>
                    </VStack>
                  ),
                }
              : undefined
          }
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>

      <SideBar
        variant="wide"
        title="Preview paycycles"
        onClose={() => setShowPreviewSidebar(false)}
        isOpen={showPreviewSidebar}
      >
        {canShowPreview ? (
          <PaycyclesPreview />
        ) : (
          <StatusWidget>
            <StatusWidget.Image
              image={{
                default: 'https://assets.revolut.com/assets/3d-images/3D026.png',
                '2x': 'https://assets.revolut.com/assets/3d-images/3D026@2x.png',
                '3x': 'https://assets.revolut.com/assets/3d-images/3D026@3x.png',
              }}
            />
            <StatusWidget.Title>Previews will show up here</StatusWidget.Title>
            <StatusWidget.Description>
              You need to complete the Schedule and Deadlines sections before previews can
              be generated.
            </StatusWidget.Description>
          </StatusWidget>
        )}
      </SideBar>
      <AddPayElementSidebar
        isOpen={addPayElementModalOpen}
        onClose={() => {
          setEditPayElementId(undefined)
          setAddPayElementModalOpen(false)
        }}
        editPayElementId={editPayElementId}
      />
      <ConfirmationDialog
        open={!!deletePayElementId}
        onClose={() => setDeletePayElementId(undefined)}
        onConfirm={() => {
          values.payroll_elements = values.payroll_elements.filter(
            elt => elt.id !== deletePayElementId,
          )
          setDeletePayElementId(undefined)
        }}
        onReject={() => setDeletePayElementId(undefined)}
        label="Are you sure you want to delete pay element?"
        body=""
      />
    </PageWrapper>
  )
}

export const PayGroupEdit = connect(() => (
  <Form api={paygroupRequests}>
    <PaygroupEditForm />
  </Form>
))
