import React from 'react'
import SuccessWidget from '@components/SuccessWidget/SuccessWidget'
import { format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { Button, Flex, Text, TextSkeleton, VStack } from '@revolut/ui-kit'
import { Link, useLocation } from 'react-router-dom'
import { getLocationDescriptor } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { PUBLIC_ROUTES } from '@src/constants/routes'
import { useCandidateSchedulingFeatureFlags } from '@src/pages/CandidateScheduling/hooks'

type Props = {
  cancel?: boolean
  date?: string
  timeZone?: string
}

const ConfirmedAppointment = ({ cancel = false, date, timeZone }: Props) => {
  const location = useLocation()
  const { canCancel, isLoading: loadingFeatureFlags } =
    useCandidateSchedulingFeatureFlags()
  if (loadingFeatureFlags) {
    return (
      <VStack aria-labelledby="loading" gap="s-16">
        <TextSkeleton />
      </VStack>
    )
  }
  return (
    <Flex alignItems="center" height="100vh">
      <SuccessWidget
        type="planned"
        title={`Availability confirmed${
          date && timeZone
            ? ` for ${format(
                utcToZonedTime(new Date(date), timeZone),
                'ccc, d LLLL, hh:mmaaa',
              )} (${timeZone})`
            : ''
        }`}
        text={
          <VStack gap="s-16">
            <Text>
              Thank you for confirming your availability. We will reach out to you soon to
              share further details about the interview
            </Text>
            {cancel && canCancel && (
              <Button
                elevated
                maxWidth={340}
                // @ts-expect-error object works fine here, but UI kit expects string
                to={{
                  ...getLocationDescriptor(
                    pathToUrl(PUBLIC_ROUTES.CANDIDATE_SCHEDULING.CANCEL),
                  ),
                  search: location.search,
                }}
                use={Link}
                variant="secondary"
              >
                Cancel interview
              </Button>
            )}
          </VStack>
        }
      />
    </Flex>
  )
}

export default ConfirmedAppointment
