import React from 'react'

import { useTable } from '@src/components/TableV2/hooks'
import Table from '@src/components/TableV2/Table'
import { getFaqCategories } from '@src/api/faq'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { RowInterface } from '@src/interfaces/data'
import { FaqCategoryInterface } from '@src/interfaces/faq'
import {
  faqCategoryDescriptionColumn,
  faqCategoryIconColumn,
  faqCategoryLinkToSupportColumn,
  faqCategoryNameColumn,
} from '@src/constants/columns/faqCategory'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'
import { PageBody } from '@components/Page/PageBody'

const row: RowInterface<FaqCategoryInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.FAQ_CATEGORY.GENERAL, { id: data.id })),
  cells: [
    {
      ...faqCategoryNameColumn,
      width: 200,
    },
    {
      ...faqCategoryDescriptionColumn,
      width: 300,
    },
    {
      ...faqCategoryLinkToSupportColumn,
      width: 100,
    },
    {
      ...faqCategoryIconColumn,
      width: 200,
    },
  ],
}

export const FAQCategories = () => {
  const table = useTable({ getItems: getFaqCategories })

  return (
    <PageBody maxWidth="100%" mt="s-16">
      <Table.Widget>
        <Table.Widget.Actions>
          <Table.Widget.MoreBar>
            <PrimaryAction
              to={pathToUrl(ROUTES.FORMS.FAQ_CATEGORY.GENERAL)}
              use={InternalLink}
              useIcon="Plus"
            >
              Add category
            </PrimaryAction>
          </Table.Widget.MoreBar>
        </Table.Widget.Actions>
        <Table.Widget.Table>
          <AdjustableTable name={TableNames.FaqCategories} row={row} {...table} />
        </Table.Widget.Table>
      </Table.Widget>
    </PageBody>
  )
}
