import React, { useEffect, useState } from 'react'
import { BottomSheet, Header, InputGroup, Item, Portal, Switch } from '@revolut/ui-kit'
import {
  addNewInterviewRoundRequest,
  changeInterviewRound,
  getFullInterviewRounds,
} from '@src/api/recruitment/interviews'
import { API, selectorKeys } from '@src/constants/api'
import { JobPostingLocationTypeInterface } from '@src/interfaces/jobPosting'
import { renderOption } from '@components/JobPostingOption/JobPostingOption'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { connect } from 'lape'
import Form from '@src/features/Form/Form'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { pathToUrl } from '@src/utils/router'
import { useQueryClient } from 'react-query'
import { IdAndName } from '@src/interfaces'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { AddInterviewRoundInterface } from '@src/interfaces/interviewTool'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type Props = {
  onClose: () => void
  onSuccess: (jobPosting: IdAndName, main: boolean) => void
  onRefresh?: () => void
  candidateId: number
}

const CVScreeningChangeJobPosting = ({
  candidateId,
  onSuccess,
  onRefresh,
}: Omit<Props, 'onClose'>) => {
  const [pending, setPending] = useState(false)
  const [main, setMain] = useState(true)
  const queryClient = useQueryClient()
  const { values } = useLapeContext<AddInterviewRoundInterface>()
  const user = useSelector(selectUser)

  const onMarkAsMain = async (jobPostingId: number) => {
    setPending(true)
    // TODO: simplify it when the BE is ready https://revolut.atlassian.net/browse/PERF-5000
    try {
      const { data } = await getFullInterviewRounds(candidateId)

      const roundId = data.find(item =>
        item.application_forms?.some(form => form.job_posting?.id === jobPostingId),
      )?.id

      if (roundId) {
        await changeInterviewRound(candidateId, roundId)
      }
    } finally {
      setPending(false)
    }
  }

  useEffect(() => {
    if (!values.created_by) {
      values.created_by = {
        id: user.id,
        name: user.display_name,
      }
    }
  }, [user])

  return (
    <>
      <Header variant="compact">
        <Header.CloseButton aria-label="Close" />
        <Header.Title>Assign a new job posting</Header.Title>
      </Header>
      <InputGroup>
        <LapeRadioSelectInput<JobPostingLocationTypeInterface>
          name="job_posting"
          label="Select job posting"
          selector={selectorKeys.job_postings_locations_type}
        >
          {renderOption}
        </LapeRadioSelectInput>
        <LapeRadioSelectInput
          name="created_by"
          label="Sourced by"
          selector={selectorKeys.employee}
        />
        <Item use="label">
          <Item.Content>
            <Item.Title>Make new job posting the main one</Item.Title>
            <Item.Description>
              This candidate might disappear from your current pipeline and might be
              assigned to a different recruiter
            </Item.Description>
          </Item.Content>
          <Item.Side>
            <Switch
              onChange={e => {
                setMain(e.currentTarget.checked)
              }}
              checked={main}
            />
          </Item.Side>
        </Item>
      </InputGroup>
      <BottomSheet.Actions>
        <NewSaveButtonWithPopup
          successText="New job posting added"
          useValidator
          noPopup
          disabled={pending}
          pending={pending}
          onAfterSubmit={async result => {
            const jobPosting = (result as { job_posting: IdAndName }).job_posting

            if (main) {
              await onMarkAsMain(jobPosting.id)
              onRefresh?.()
            }

            queryClient.invalidateQueries(
              pathToUrl(API.FULL_INTERVIEW_ROUNDS, { id: candidateId }),
            )
            onSuccess(jobPosting, main)
          }}
        />
      </BottomSheet.Actions>
    </>
  )
}

export default connect((props: Props) => (
  <Portal>
    <BottomSheet open onClose={props.onClose}>
      <Form
        api={addNewInterviewRoundRequest}
        isExistingForm={false}
        forceParams={{ candidateId: String(props.candidateId) }}
      >
        <CVScreeningChangeJobPosting {...props} />
      </Form>
    </BottomSheet>
  </Portal>
))
