import React from 'react'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'

import { navigateTo } from '@src/actions/RouterActions'
import { getBenefits } from '@src/api/benefits'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import Stat from '@src/components/Stat/Stat'
import Table from '@src/components/TableV2/Table'
import AdjustableTable from '@src/components/TableV2/AdjustableTable'
import { useTable } from '@src/components/TableV2/hooks'
import {
  benefitsCategoryColumn,
  benefitsCreatedByColumn,
  benefitsCreatedOnColumn,
  benefitsDescriptionColumn,
  benefitsGroupsColumn,
  benefitsNameColumn,
  benefitsStatusColumn,
} from '@src/constants/columns/benefits'
import { ROUTES } from '@src/constants/routes'
import { TableNames } from '@src/constants/table'
import { Statuses } from '@src/interfaces'
import { BenefitsTableInterface } from '@src/interfaces/benefits'
import { RowInterface } from '@src/interfaces/data'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

const Row: RowInterface<BenefitsTableInterface> = {
  linkToForm: data =>
    navigateTo(pathToUrl(ROUTES.FORMS.BENEFITS.PREVIEW, { id: data.id })),
  cells: [
    {
      ...benefitsNameColumn,
      width: 150,
    },
    {
      ...benefitsCategoryColumn,
      width: 150,
    },
    {
      ...benefitsGroupsColumn,
      width: 150,
    },
    {
      ...benefitsDescriptionColumn,
      width: 300,
    },
    {
      ...benefitsCreatedOnColumn,
      width: 150,
    },
    {
      ...benefitsCreatedByColumn,
      width: 150,
    },
    {
      ...benefitsStatusColumn,
      width: 90,
    },
  ],
}

const Templates = () => {
  const permissions = useSelector(selectPermissions)

  const table = useTable({ getItems: getBenefits }, [
    {
      columnName: 'status',
      filters: [{ name: Statuses.active, id: Statuses.active }],
      nonResettable: true,
    },
  ])

  return (
    <Table.Widget>
      <Table.Widget.Info>
        <Stat
          label="Benefits templates"
          val={table?.loading ? undefined : table?.count}
          mr="s-16"
        />
      </Table.Widget.Info>
      <Table.Widget.Actions>
        <Table.Widget.MoreBar>
          {permissions.includes(PermissionTypes.AddBenefitsTemplate) && (
            <MoreBar.Action
              use={InternalLink}
              to={pathToUrl(ROUTES.FORMS.BENEFITS.GENERAL)}
              useIcon="Plus"
            >
              Add benefit
            </MoreBar.Action>
          )}
        </Table.Widget.MoreBar>
      </Table.Widget.Actions>
      <Table.Widget.Table>
        <AdjustableTable
          hideCount
          name={TableNames.BenefitsTemplates}
          useWindowScroll
          row={Row}
          {...table}
          noDataMessage="Benefits will appear here."
        />
      </Table.Widget.Table>
    </Table.Widget>
  )
}

export default Templates
