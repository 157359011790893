import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'

import { apiWithoutHandling } from '@src/api'
import { UseFetchResult } from '@src/interfaces'
import { useFetch } from '@src/utils/reactQuery'
import { API } from '@src/constants/api'
import {
  OnboardingCheckpointCategory,
  OnboardingCheckpointInterface,
} from '@src/interfaces/onboardingChecklistV2'
import { companyPreferencesOnbV2 as companyPreferencesApi } from './settings'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags } from '@src/store/auth/types'

type UseGetOnboardingCheckpoints = Pick<
  UseFetchResult<{ results: OnboardingCheckpointInterface[] }>,
  'isError' | 'isLoading'
> & {
  checkpoints?: OnboardingCheckpointInterface[]
  refetchCheckpoints: () => void
}

export const useGetOnboardingCheckpoints = (
  disable?: boolean,
): UseGetOnboardingCheckpoints => {
  const featureFlags = useSelector(selectFeatureFlags)

  const {
    data: onboardingCheckpoints,
    refetch: refetchCheckpoints,
    isLoading,
    isError,
  } = useFetch<{
    results: OnboardingCheckpointInterface[]
  }>(`${API.ONBOARDING_CHECKPOINTS_V2}`, 'v2', undefined, true, {
    enabled: featureFlags.includes(FeatureFlags.OnboardingFlowV2) && !disable,
  })

  return {
    isLoading,
    isError,
    checkpoints: onboardingCheckpoints?.results,
    refetchCheckpoints,
  }
}

export const useGetOnboardingCheckpointCategory = (
  category: OnboardingCheckpointCategory,
) => {
  const response = useGetOnboardingCheckpoints()

  return {
    ...response,
    data: response.checkpoints?.find(checkpoint => checkpoint.category === category),
  }
}

export const useInvalidateOnboardingCheckpoints = () => {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries(API.ONBOARDING_CHECKPOINTS_V2)
  }
}

export const updateOnboardingCheckpointCategoryCurrentStep = (
  category: OnboardingCheckpointCategory,
  current_step: string,
) =>
  apiWithoutHandling.patch(
    `${API.ONBOARDING_CHECKPOINTS_V2}/${category}`,
    {
      current_step,
    },
    undefined,
    'v2',
  )

export const completeOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING_CHECKPOINTS_V2}/${category}/complete`,
    undefined,
    undefined,
    'v2',
  )

export const hideOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING_CHECKPOINTS_V2}/${category}/hide`,
    undefined,
    undefined,
    'v2',
  )

// For development and testing only
export const resetOnboardingCheckpoint = (category: OnboardingCheckpointCategory) =>
  apiWithoutHandling.post(
    `${API.ONBOARDING_CHECKPOINTS_V2}/${category}/reset`,
    undefined,
    undefined,
    'v2',
  )

// For development and testing only
export const enableAllCheckpoints = () =>
  Promise.all([
    companyPreferencesApi.update(
      {
        tools: {},
        other_tools: [],
        other_tools_text: null,
        hr_apps_to_setup: {
          payroll: true,
          documents: true,
          timeManagement: true,
          employeeRecords: true,
          selected: true,
        },
        performance_apps_to_setup: {
          goals: true,
          reviews: true,
          selected: true,
        },
        recruitment_apps_to_setup: {
          jobs: true,
          candidates: true,
          interviews: true,
          selected: true,
        },
        enable_departments: true,
        enable_multi_specialisations: false,
        enable_functions: false,
      },
      { id: '1' },
    ),
  ])
