import React, { MouseEvent, useState } from 'react'
import {
  Avatar,
  Color,
  IconButton,
  Item,
  Spinner,
  HStack,
  ActionButton,
} from '@revolut/ui-kit'
import { Document, Clip, Download, Delete } from '@revolut/icons'
import { saveFile } from '@src/utils'
import { downloadFile } from '@src/api/files'
import { formatFileSize } from '@src/utils/format'

export type UploadedFileProps = {
  title: string
  size?: number
  disabled?: boolean
  onDelete?: (e: MouseEvent<HTMLButtonElement>) => void
  onOpen?: () => void
}

const UploadedFile = ({ title, size, disabled, onDelete, onOpen }: UploadedFileProps) => (
  <Item>
    <Item.Avatar>
      <Avatar useIcon={Document} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title
        style={{
          wordBreak: 'break-word',
        }}
      >
        {title}
      </Item.Title>
      {size && <Item.Description>{formatFileSize(size)}</Item.Description>}
    </Item.Content>
    <Item.Side>
      <HStack gap="s-8">
        {onOpen && <ActionButton onClick={onOpen}>Open</ActionButton>}
        {!disabled && (
          <ActionButton onClick={onDelete} useIcon={Delete}>
            Delete
          </ActionButton>
        )}
      </HStack>
    </Item.Side>
  </Item>
)

interface UploadedFileToDownloadProps {
  id: number
  title: string
  size?: number
}

export const UploadedFileToDownload = ({
  id,
  title,
  size,
}: UploadedFileToDownloadProps) => {
  const [pending, setPending] = useState(false)

  const onDownloadClick = () => {
    setPending(true)
    downloadFile(id)
      .then(details => {
        saveFile(details, title)
      })
      .finally(() => setPending(false))
  }

  return (
    <Item>
      <Item.Avatar>
        <Avatar useIcon={Clip} />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>{title}</Item.Title>
        {size && <Item.Description>{formatFileSize(size)}</Item.Description>}
      </Item.Content>
      <Item.Side>
        {pending ? (
          <Spinner color={Color.BLUE} size={18} />
        ) : (
          <IconButton
            onClick={onDownloadClick}
            disabled={pending}
            useIcon={Download}
            color={Color.BLUE}
            size={18}
          />
        )}
      </Item.Side>
    </Item>
  )
}

export default UploadedFile
