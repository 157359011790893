import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { VStack } from '@revolut/ui-kit'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { PageBody } from '@components/Page/PageBody'
import { PageActions } from '@components/Page/PageActions'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EngagementDriverInterface } from '@src/interfaces/engagement'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import { selectorKeys } from '@src/constants/api'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectUser } from '@src/store/auth/selectors'

export const DriverForm = () => {
  const { values } = useLapeContext<EngagementDriverInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner) {
      values.owner = { id: user.id, full_name: user.full_name }
    }
  }, [])

  const title = values.id ? 'Edit driver' : 'Add new driver'

  return (
    <PageWrapper>
      <PageHeader title={title} backUrl={ROUTES.APPS.ENGAGEMENT.DRIVERS_TABLE} />
      <PageBody>
        <VStack space="s-16">
          <LapeNewInput required name="name" label="Name" />
          <LapeRadioSelectInput
            required
            name="owner"
            label="Owner"
            selector={selectorKeys.employee}
          />
          <LapeNewTextArea name="description" label="Description" rows={2} />
        </VStack>
      </PageBody>
      <PageActions>
        <NewSaveButtonWithPopup
          useValidator
          successText={
            values.id ? 'Driver successfully updated' : 'Driver successfully added'
          }
          onAfterSubmit={res => {
            navigateReplace(
              pathToUrl(ROUTES.APPS.ENGAGEMENT.DRIVER.PREVIEW, { id: res.id }),
            )
          }}
        >
          Save
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}
