import React from 'react'
import { Flex, Text, Token, VStack } from '@revolut/ui-kit'
import { KPITypes } from '@src/constants/table'
import { IdAndName } from '@src/interfaces'

export interface OptionInterface {
  id: number
  kpi_type: KPITypes
  department: IdAndName | null
  team: IdAndName | null
}

interface Props {
  label: React.ReactNode
  value: OptionInterface
}

const getKpiType = (type: KPITypes) => {
  switch (type) {
    case KPITypes.company_kpi:
      return 'Company KPI'
    case KPITypes.function_kpi:
      return 'Function KPI'
    case KPITypes.role_kpi:
      return 'Role KPI'
    case KPITypes.department_kpi:
      return 'Dept: '
    case KPITypes.team_kpi:
      return 'Team: '
  }

  return ''
}

export const CustomOption = ({ label, value }: Props) => {
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <VStack gap="s-4">
        <Text>{label}</Text>
        <Text color={Token.color.greyTone50} variant="caption">{`${getKpiType(
          value.kpi_type,
        )}${value.department?.name || value.team?.name || ''}`}</Text>
      </VStack>
      <Text color={Token.color.greyTone50} variant="caption">
        {value.id}
      </Text>
    </Flex>
  )
}
