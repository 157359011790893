import SideBar from '@src/components/SideBar/SideBar'
import React, { useState } from 'react'
import { Avatar, Group, Item, Search, Text, VStack } from '@revolut/ui-kit'
import { getInitials, getAvatarUrl } from '@src/utils/employees'
import { useContractsSelector } from '@src/api/contractsBulkEdit'
import pluralize from 'pluralize'

type SelectedContractsSidebarProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  selectedIds: number[]
}

const SelectedContractsSidebar = ({
  isOpen,
  setIsOpen,
  selectedIds,
}: SelectedContractsSidebarProps) => {
  const [search, setSearch] = useState('')

  const { data: contracts } = useContractsSelector()

  const selectedContracts =
    contracts?.options.filter(
      contract =>
        selectedIds.includes(Number(contract.id)) &&
        contract.employee.name.toLowerCase().includes(search.toLowerCase()),
    ) || []

  const pendingContractsSelected = selectedIds.length - selectedContracts.length

  return (
    <SideBar
      title={`${pluralize('contract', selectedIds.length, true)} selected`}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      <VStack gap="s-16">
        <Text color="grey" size="small">
          All selected contracts will be subject to change during the bulk edit operation.
        </Text>
        <Search placeholder="Search" value={search} onChange={setSearch} />
        <Group>
          {selectedContracts?.map(contract => (
            <Item key={contract.id}>
              <Item.Avatar>
                <Avatar image={getAvatarUrl(contract.employee.avatar)}>
                  {!contract.employee.avatar && getInitials(contract.employee.name || '')}
                </Avatar>
              </Item.Avatar>
              <Item.Content>
                <Item.Title>{contract.employee.name}</Item.Title>
              </Item.Content>
            </Item>
          ))}
        </Group>
        {!!pendingContractsSelected && !search && (
          <Text color="grey" size="small">
            + {pluralize('hidden contract', pendingContractsSelected, true)}
          </Text>
        )}
      </VStack>
    </SideBar>
  )
}

export default SelectedContractsSidebar
