import React, { useState } from 'react'
import ActionWidget from '@components/ActionWidget/ActionWidget'
import {
  Color,
  HStack,
  MoreBar,
  Text,
  VStack,
  Header,
  Popup,
  Item,
  Avatar,
  Flex,
  Group,
} from '@revolut/ui-kit'
import { ArrowThinRight, Copy, ExclamationMark, Plus } from '@revolut/icons'
import { ContractStatuses, EmployeeContractsInterface } from '@src/interfaces/employees'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { formatDate } from '@src/utils/format'
import { Link, useParams } from 'react-router-dom'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { getPermissions } from '@src/pages/Forms/EmployeeContracts/Preview/getPermissions'

type Props = {
  onCopyContract: () => void
}
export const ContractActionWidgets = ({ onCopyContract }: Props) => {
  const { values } = useLapeContext<EmployeeContractsInterface>()
  const { employeeId } = useParams<{ employeeId: string }>()

  const [isCreateNewPopupOpen, setIsCreateNewPopupOpen] = useState<boolean>(false)

  const permissions = getPermissions(values.field_options?.permissions)
  const isFixedContract = values.contract_term?.id === 'fixed'
  const hasPendingApprovalWarnings =
    !!Object.keys(values.warnings || {}).length &&
    values.contract_status?.id === ContractStatuses.PendingApproval
  const isExpiringSoon =
    values.contract_status.id === ContractStatuses.Active &&
    values.is_expiring_soon &&
    values.end_date

  return (
    <VStack mt="s-24" space="s-16">
      {isExpiringSoon && (
        <>
          <ActionWidget
            title="This contract will expire soon"
            text={
              <VStack space="s-8">
                <Text>Expiry date: {formatDate(values.end_date)}</Text>
                <Text>
                  If the employee should continue working after this date, please create a
                  new contract at least one week before this contract expires.
                </Text>
                {permissions.canCreate && (
                  <MoreBar>
                    <MoreBar.Action
                      useIcon={ArrowThinRight}
                      onClick={() => setIsCreateNewPopupOpen(true)}
                    >
                      Create new contract
                    </MoreBar.Action>
                  </MoreBar>
                )}
              </VStack>
            }
          />
          <Popup
            variant="bottom-sheet"
            open={isCreateNewPopupOpen}
            onClose={() => setIsCreateNewPopupOpen(false)}
          >
            <Header variant="bottom-sheet">
              <Header.Title>How you want to create the new contract?</Header.Title>
            </Header>
            <VStack space="s-16">
              <Text variant="caption" color={Color.GREY_TONE_50}>
                If you only need to record a couple of changes, such as new salary, new
                seniority, new location, etc you can make a copy of the current contract
                and apply the new details.
              </Text>
              <Text variant="caption" color={Color.GREY_TONE_50}>
                If more details need to be changed, you can also create a new contract and
                start from scratch.
              </Text>
              <Flex flexDirection="column">
                <Group>
                  <Item use="button" onClick={onCopyContract}>
                    <Item.Avatar>
                      <Avatar useIcon={Copy} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title color={Color.BLUE}>Copy current contract</Item.Title>
                    </Item.Content>
                  </Item>
                  <Item
                    use={Link}
                    target="_blank"
                    to={pathToUrl(ROUTES.FORMS.EMPLOYEE_CONTRACT.GENERAL, { employeeId })}
                  >
                    <Item.Avatar>
                      <Avatar useIcon={Plus} />
                    </Item.Avatar>
                    <Item.Content>
                      <Item.Title color={Color.BLUE}>Create new contract</Item.Title>
                    </Item.Content>
                  </Item>
                </Group>
              </Flex>
            </VStack>
          </Popup>
        </>
      )}
      {isFixedContract && (
        <ActionWidget
          title="Employee will be terminated automatically when this contract ends"
          text={
            <>
              This contract has been marked as ‘fixed term’ therefore please be aware that
              when the end date specified in the form below has been reached, the employee
              will be automatically terminated.{' '}
              <b>
                The automatic termination will be skipped only if they have another
                contract starting on the next day.
              </b>
            </>
          }
        />
      )}
      {hasPendingApprovalWarnings && (
        <ActionWidget
          title="Please review the following warnings"
          avatarColor={Color.ORANGE}
          text={
            <>
              The warnings below indicate deviations from standard contractual terms in
              the selected hiring location. Please double check that all the details have
              been entered correctly. Some of these warnings will trigger additional
              approvals once the contract is submitted for review.
            </>
          }
        >
          <VStack mt="s-16">
            {Object.values(values.warnings || {}).map(warning => (
              <HStack key={warning} align="center">
                <ExclamationMark size={16} color={Color.ORANGE} />
                <Text use="div" ml="s-8">
                  {warning}
                </Text>
              </HStack>
            ))}
          </VStack>
        </ActionWidget>
      )}
    </VStack>
  )
}
