import React from 'react'

import { PageHeader } from '@components/Page/Header/PageHeader'
import { ROUTES } from '@src/constants/routes'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@components/Page/PageBody'
import Table from '@src/components/TableV2/Table'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { RowInterface } from '@src/interfaces/data'
import { SlackBotInterface } from '@src/interfaces/integrations'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import {
  slackBotNameColumn,
  slackBotDescriptionColumn,
  slackBotActionsColumn,
  slackBotScopesColumn,
} from '@src/constants/columns/slackBots'
import SlackBotActions from '@components/ColumnInserts/SlackBotActions/SlackBotActions'
import AdjustableTable from '@components/TableV2/AdjustableTable'
import { TableNames } from '@src/constants/table'
import { useTable } from '@components/TableV2/hooks'
import { getSlackIntegrationBots } from '@src/api/integrations'
import { useSelector } from 'react-redux'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EmptyTableRaw } from '@components/Table/EmptyTableRaw'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { PrimaryAction } from '@components/PrimaryAction/PrimaryAction'

const getRows = (
  refresh: () => void,
  canEdit: boolean,
  botUrl: string,
): RowInterface<SlackBotInterface> => ({
  linkToForm: ({ id }) => navigateTo(pathToUrl(botUrl, { id })),
  cells: [
    {
      ...slackBotNameColumn,
      width: 100,
    },
    {
      ...slackBotDescriptionColumn,
      width: 200,
    },
    {
      ...slackBotScopesColumn,
      width: 200,
    },
    {
      ...slackBotActionsColumn,
      insert: ({ data }) =>
        canEdit ? <SlackBotActions id={data.id} onDelete={refresh} /> : '-',
      width: 80,
    },
  ],
})

export const SlackBots = () => {
  const isOnboarding = isOnboardingPath()
  const permissions = useSelector(selectPermissions)
  const table = useTable({ getItems: getSlackIntegrationBots })

  const canEdit = permissions.includes(PermissionTypes.ChangeSlackIntegration)

  const botUrl = isOnboarding
    ? ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.BOT
    : ROUTES.FEATURES.INTEGRATION.SLACK.BOT

  return (
    <PageWrapper>
      <PageHeader
        backUrl={
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.INTEGRATIONS.SLACK.ALL
            : ROUTES.FEATURES.INTEGRATION.SLACK.ALL
        }
        title="Manage custom bots"
        subtitle="Design custom Slack bots to send targeted messages for specific use cases"
      />
      <PageBody maxWidth="100%">
        <Table.Widget>
          <Table.Widget.Actions>
            <PrimaryAction
              useIcon="Plus"
              use={InternalLink}
              to={pathToUrl(botUrl)}
              disabled={!canEdit}
            >
              Register Slack bot
            </PrimaryAction>
          </Table.Widget.Actions>
          <Table.Widget.Table>
            <AdjustableTable
              name={TableNames.SlackBots}
              useWindowScroll
              row={getRows(table.refresh, canEdit, botUrl)}
              {...table}
              emptyState={<EmptyTableRaw title="Slack bots will appear here" />}
            />
          </Table.Widget.Table>
        </Table.Widget>
      </PageBody>
    </PageWrapper>
  )
}
