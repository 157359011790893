import { CellTypes, ColumnInterface } from '@src/interfaces/data'
import { selectorKeys } from '@src/constants/api'
import { InterviewRoundInterface } from '@src/interfaces/interviewTool'

export const interviewRoundSpecialisationColumn: ColumnInterface<InterviewRoundInterface> =
  {
    title: 'Posting',
    type: CellTypes.text,
    idPoint: 'specialisation.id',
    dataPoint: 'specialisation.name',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    noResize: true,
  }

export const interviewRoundStageColumn: ColumnInterface<InterviewRoundInterface> = {
  title: 'Interview Stage',
  type: CellTypes.insert,
  idPoint: 'latest_interview_stage.id',
  dataPoint: 'latest_interview_stage.title',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
  insert: ({ data }) =>
    data.latest_interview_stage
      ? `${data.latest_interview_stage.interview_number}/${data.stage_count} · ${data.latest_interview_stage.title}`
      : '-',
}

export const interviewRoundStatusColumn: ColumnInterface<InterviewRoundInterface> = {
  title: 'Status',
  type: CellTypes.text,
  idPoint: 'state',
  dataPoint: 'state',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  noResize: true,
}
