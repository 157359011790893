import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { ROUTES } from '@src/constants/routes'
import { useSetDocumentTitle } from '@src/hooks/useSetDocumentTitle'
import { PageWrapper } from '@src/components/Page/Page'
import { PlanList } from './PlanList'
import { PlanDetails } from './PlanDetails'

export const PlanManagementSettings = () => {
  useSetDocumentTitle('Settings', 'Plan management')

  return (
    <PageWrapper>
      <Switch>
        <Route exact path={ROUTES.SETTINGS.PLAN_MANAGEMENT.LIST}>
          <PlanList />
        </Route>
        <Route exact path={ROUTES.SETTINGS.PLAN_MANAGEMENT.DETAILS}>
          <PlanDetails />
        </Route>
      </Switch>
    </PageWrapper>
  )
}
