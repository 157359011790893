import React from 'react'
import { AxiosError } from 'axios'
import isEmpty from 'lodash/isEmpty'
import { StatusPopup, useStatusPopup } from '@revolut/ui-kit'

export const useHandleNonFieldError = (fieldsToExclude: string[]) => {
  const statusPopup = useStatusPopup()

  return (err: AxiosError) => {
    if (err.response?.status === 400) {
      const errData = { ...err.response.data } || {}

      if (isEmpty(errData)) {
        return
      }
      fieldsToExclude.forEach(field => {
        delete errData[field]
      })

      const isKeyValue =
        typeof errData === 'object' ? !Array.isArray(errData) && errData !== null : false
      const errorMessageToShow = isKeyValue
        ? errData.non_field_errors || errData[Object.keys(errData)[0]]
        : 'Please, refresh the page or try again later'

      if (errorMessageToShow) {
        statusPopup.show(
          <StatusPopup variant="error">
            <StatusPopup.Title>Something went wrong</StatusPopup.Title>
            <StatusPopup.Description>{errorMessageToShow}</StatusPopup.Description>
          </StatusPopup>,
        )
      } else {
        throw err
      }
    } else {
      throw err
    }
  }
}
