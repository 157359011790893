import React from 'react'

import { Flex, Spacer } from '@revolut/ui-kit'
import { DepartmentInterface } from '@src/interfaces/deparment'
import { TimeOffCalendar } from '@src/features/TimeOffCalendarTable/TimeOffCalendar'
import {
  useCalendarFilters,
  useTableCalendarSwitcher,
  useWeekMonthSwitcher,
} from '@src/features/TimeOffCalendarTable/hooks'
import { getDepartmentTimeOffCalendar } from '@src/api/timeOff'
import { TableNames } from '@src/constants/table'
import { TimeOffTable } from './TimeOffTable'
import {
  TableCalendarTabs,
  WeekMonthTabs,
} from '@src/features/TimeOffCalendarTable/constants'
import Table from '@src/components/TableV2/Table'
import { useIsNewTable } from '@src/components/TableV2/hooks'

interface TimeOffProps {
  data: DepartmentInterface
  navigation?: React.ReactElement
}
export const TimeOff = ({ data, navigation }: TimeOffProps) => {
  const { isMonthView, clearCalendarFilters } = useCalendarFilters()
  const weekMonthSwitcher = useWeekMonthSwitcher(isMonthView)

  const tableCalendarSwitcher = useTableCalendarSwitcher(tab => {
    if (tab === TableCalendarTabs.Table) {
      clearCalendarFilters()
    }
  })

  const isNewTable = useIsNewTable()

  return (
    <Table.Widget>
      {!isNewTable && (
        <Table.Widget.Info>
          {navigation}
          <Spacer />
          <Flex gap="s-8">
            {tableCalendarSwitcher.tabBar}
            {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar
              ? weekMonthSwitcher.tabBar
              : null}
          </Flex>
        </Table.Widget.Info>
      )}

      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <TimeOffTable
          data={data}
          navigation={isNewTable ? navigation : undefined}
          tabBarSwitcher={isNewTable ? tableCalendarSwitcher.tabBar : undefined}
        />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <TimeOffCalendar
          navigation={isNewTable ? navigation : undefined}
          tableName={TableNames.DepartmentTimeOffCalendar}
          tabBarSwitcher={
            <Flex gap="s-8">
              {weekMonthSwitcher.tabBar}
              {tableCalendarSwitcher.tabBar}
            </Flex>
          }
          data={data}
          weekMonthTab={weekMonthSwitcher.currentTab || WeekMonthTabs.Week}
          getItems={getDepartmentTimeOffCalendar(data.id)}
        />
      )}
    </Table.Widget>
  )
}
