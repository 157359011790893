import { useGetSelectors } from '@src/api/selectors'
import LapeNewRadioButtons from '@src/components/Inputs/LapeFields/LapeNewRadioButtons'
import { selectorKeys } from '@src/constants/api'
import { CYCLE_DURATION } from '@src/interfaces/settings'
import React, { useMemo } from 'react'
import { cycleOptionMap } from './common/cycleOptionMap'
import { IdAndName } from '@src/interfaces'
import { Flex, Tag, Text } from '@revolut/ui-kit'

export const CycleSettings = () => {
  const { data: durations } = useGetSelectors<IdAndName<CYCLE_DURATION>>(
    selectorKeys.cycle_durations,
  )
  const options = useMemo(() => {
    if (durations && durations.length) {
      return durations.map(option => ({
        value: option,
        description: cycleOptionMap[option.id].description,
        label: (
          <Flex justifyContent="space-between">
            <Text>{option.name}</Text>
            {option.id === 'quarterly' && (
              <Tag variant="outlined" useIcon="Cleaning">
                Recommended
              </Tag>
            )}
          </Flex>
        ),
      }))
    }
    return []
  }, [durations])

  return (
    <>
      <LapeNewRadioButtons
        variant="group-items"
        highlightSelected
        name="cycle_duration"
        options={options}
      />
    </>
  )
}
