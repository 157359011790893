import React, { useState } from 'react'
import { Group, createChain, Flex, Color, MoreBar, Box } from '@revolut/ui-kit'
import { Archive, ExclamationMark, Pencil, Unarchive } from '@revolut/icons'
import { useParams } from 'react-router-dom'

import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PageBody } from '@src/components/Page/PageBody'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { BudgetPoolInterface } from '@src/interfaces/budgetPools'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import Tooltip from '@src/components/Tooltip/Tooltip'
import SettingsButtons from '@src/features/SettingsButtons'
import { changeBudgetPoolStatus } from '@src/api/budgetPools'
import { EntityPermissions } from '@src/store/auth/types'
import {
  InternalLink,
  InternalUIKitLink,
} from '@src/components/InternalLink/InternalLink'

export const Preview = () => {
  const [archivePending, setArchivePending] = useState(false)

  const { values, reset } = useLapeContext<BudgetPoolInterface>()
  const params = useParams<{ id?: string }>()

  const onChangeStatus = (status: 'archive' | 'unarchive') => {
    setArchivePending(true)

    changeBudgetPoolStatus(values.id, status)
      .then(response => reset(response.data))
      .finally(() => setArchivePending(false))
  }

  const canChange = values.field_options?.actions?.includes(EntityPermissions.Change)
  const isCustomType = values.pool_type.id === 'custom'

  return (
    <PageBody>
      {canChange && (
        <SettingsButtons mb="s-24">
          {isCustomType ? (
            <MoreBar.Action
              to={pathToUrl(ROUTES.FORMS.BUDGET_POOL.GENERAL, { id: params.id })}
              useIcon={Pencil}
              use={InternalLink}
            >
              Edit
            </MoreBar.Action>
          ) : null}
          {values.status.id === 'archived' ? (
            <MoreBar.Action
              onClick={() => onChangeStatus('unarchive')}
              useIcon={Unarchive}
              pending={archivePending}
            >
              Unarchive
            </MoreBar.Action>
          ) : null}
          {values.status.id === 'active' ? (
            <MoreBar.Action
              onClick={() => onChangeStatus('archive')}
              useIcon={Archive}
              variant="negative"
              pending={archivePending}
            >
              Archive
            </MoreBar.Action>
          ) : null}
        </SettingsButtons>
      )}

      <FormPreview data={values}>
        <Group>
          <FormPreview.Item title="Type" field="pool_type.name" />
          <FormPreview.Item title="Owner" field="owner" type="employee" />
          <FormPreview.Item<BudgetPoolInterface>
            title="Members"
            insert={data => {
              if (!isCustomType) {
                return data.headcount ?? '-'
              }

              const groups = data.dynamic_groups

              if (groups.length === 0) {
                return '-'
              }

              return (
                <Flex gap="s-8">
                  <Box>
                    {createChain(', ')(
                      ...groups.map(group => (
                        <InternalUIKitLink
                          // @ts-expect-error object works fine here, but UI kit expects string
                          to={pathToUrl(ROUTES.FORMS.DYNAMIC_GROUPS.GENERAL, {
                            id: group.id,
                          })}
                          key={group.id}
                        >
                          {group.name}
                        </InternalUIKitLink>
                      )),
                    )}
                  </Box>
                  <Tooltip
                    placement="right"
                    text="Employees in custom budget pools will not appear in their department pool. Employee can only be a part of a single budget pool."
                  >
                    <ExclamationMark color={Color.GREY_TONE_50} size={16} />
                  </Tooltip>
                </Flex>
              )
            }}
          />
        </Group>
      </FormPreview>
    </PageBody>
  )
}
