import { AxiosPromise } from 'axios'

import { FetchDataQueryInterface, FilterByInterface } from '@src/interfaces/data'
import { GetRequestInterface, RequestInterfaceNew, Statuses } from '@src/interfaces'
import { DynamicGroupInerface } from '@src/interfaces/dynamicGroups'
import { api, apiWithoutHandling } from '@src/api'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { API } from '@src/constants/api'
import { useFetch } from '@src/utils/reactQuery'

export const getDynamicGroups = ({
  sortBy,
  filters,
  page,
}: FetchDataQueryInterface): AxiosPromise<GetRequestInterface<DynamicGroupInerface>> =>
  api.get(API.DYNAMIC_GROUPS, {
    params: filterSortPageIntoQuery(sortBy, filters, page),
  })

export const getDynamicGroupsForEmployee = (
  id: number | string,
): AxiosPromise<GetRequestInterface<{ id: number }>> => {
  const filters: FilterByInterface[] = [
    {
      columnName: 'members',
      filters: [{ id, name: `${id}` }],
    },
    {
      columnName: 'status',
      filters: [{ id: Statuses.active, name: Statuses.active }],
    },
  ]

  return api.get(
    API.DYNAMIC_GROUPS_IDS,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    undefined,
    true,
  )
}

export const useGetEmployeeDynamicGroups = (id: number | string | undefined) => {
  const filters: FilterByInterface[] | undefined = id
    ? [
        {
          columnName: 'members',
          filters: [{ id, name: `${id}` }],
        },
        {
          columnName: 'status',
          filters: [{ id: Statuses.active, name: Statuses.active }],
        },
      ]
    : undefined

  return useFetch<GetRequestInterface<{ id: number }>>(
    id ? API.DYNAMIC_GROUPS_IDS : null,
    undefined,
    {
      params: filterSortPageIntoQuery(undefined, filters),
    },
    undefined,
    undefined,
    true,
  )
}

export const dynamicGroups: RequestInterfaceNew<DynamicGroupInerface> = {
  get: async ({ id }) => api.get(`${API.DYNAMIC_GROUPS}/${id}`),
  update: async (data, { id }) => {
    const { file, ...groupData } = data
    let employeeBadgeId: number | undefined

    if (file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      formData.append('category', 'employee_badge')

      const createBadgeResult = await api.post<{ id: number }>(API.FILES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      employeeBadgeId = createBadgeResult.data.id
    }

    const updateData = {
      ...groupData,
      employee_badge: employeeBadgeId ? { id: employeeBadgeId } : undefined,
    }

    return apiWithoutHandling
      .patch(`${API.DYNAMIC_GROUPS}/${id}`, updateData)
      .catch(error => {
        if (employeeBadgeId) {
          api.delete(`${API.FILES}/${employeeBadgeId}`, undefined, undefined, true)
        }

        throw error
      })
  },
  submit: async data => {
    const { file, ...groupData } = data
    let employeeBadgeId: number | undefined

    if (file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      formData.append('category', 'employee_badge')

      const createBadgeResult = await api.post<{ id: number }>(API.FILES, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      employeeBadgeId = createBadgeResult.data.id
    }

    const submitData = {
      ...groupData,
      employee_badge: employeeBadgeId ? { id: employeeBadgeId } : undefined,
    }

    return apiWithoutHandling.post(API.DYNAMIC_GROUPS, submitData).catch(error => {
      if (employeeBadgeId) {
        api.delete(`${API.FILES}/${employeeBadgeId}`, undefined, undefined, true)
      }

      throw error
    })
  },
  delete: async data => api.delete(`${API.DYNAMIC_GROUPS}/${data.id}`),
}

interface RunQueryResponse {
  message: string
  results: Record<string, string | number | null>[]
}

export const runDynamicGroupSQL = (
  query?: DynamicGroupInerface['sql_query'],
  query_db?: DynamicGroupInerface['sql_query_db'],
) =>
  apiWithoutHandling
    .post<RunQueryResponse>(`${API.DYNAMIC_GROUPS}/runQuery`, {
      query,
      query_db,
    })
    .then(response => ({
      ...response,
      data: { ...response.data, data: response.data.results },
    }))

export const updateMembers = (id: number | string) =>
  api.post(`${API.DYNAMIC_GROUPS}/${id}/updateMembers`)
