import React from 'react'
import { Box, HStack, Icon, Text, VStack } from '@revolut/ui-kit'
import { HiringProcessInterface } from '@src/interfaces/hiringProccess'
import { getDuration } from '@src/pages/Forms/Candidate/ScheduleSidebar/utils'
import HiringStageInterviewers from '@src/components/HiringStages/HiringStageInterviewers'

type HiringStageDetailsProps = {
  hiringStage: HiringProcessInterface
}

const HiringStageDetails = ({ hiringStage }: HiringStageDetailsProps) => {
  return (
    <VStack gap="s-8" pt="s-8">
      {hiringStage.stage_type && (
        <HStack align="center" gap="s-8">
          <Icon name="Info" size={16} />
          <Box>
            <Text>Stage type: </Text>
            <Text>{hiringStage.stage_type.name}</Text>
          </Box>
        </HStack>
      )}
      {hiringStage.stage_type.id !== 'cv_screening' && (
        <HStack align="center" gap="s-8">
          <Icon name="Time" size={16} />
          <Box>
            <Text>Duration: </Text>
            <Text>
              {getDuration(hiringStage.duration, hiringStage.duration_unit.name)}
            </Text>
          </Box>
        </HStack>
      )}
      {hiringStage.scorecard_template?.name && (
        <HStack align="center" gap="s-8">
          <Icon name="Document" size={16} />
          <Box>
            <Text>Scorecard: </Text>
            <Text>{hiringStage.scorecard_template?.name}</Text>
          </Box>
        </HStack>
      )}
      {!!(
        hiringStage.interviewers?.length || hiringStage.interviewers_groups?.length
      ) && (
        <HStack align="center" gap="s-8">
          <Icon name="Profile" size={16} />
          <HStack gap="s-4">
            <Box>
              <Text>Interviewers: </Text>
            </Box>
            <HiringStageInterviewers
              interviewerType={hiringStage.interviewer_type}
              interviewers={hiringStage.interviewers}
              interviewersGroups={hiringStage.interviewers_groups}
            />
          </HStack>
        </HStack>
      )}
    </VStack>
  )
}

export default HiringStageDetails
