import React from 'react'
import { ActionButton, Token, Flex, MoreBar } from '@revolut/ui-kit'
import { Link } from 'react-router-dom'

import { EmployeeInterface } from '@src/interfaces/employees'
import { useTable } from '@components/Table/hooks'
import {
  getEmployeeTimeOffRequests,
  useEmployeeTimeOffRequestYearOptions,
  useEmployeeTimeOffStats,
} from '@src/api/timeOff'
import AdjustableTable from '@components/Table/AdjustableTable'
import { RowInterface } from '@src/interfaces/data'
import { EmployeeTimeOffRequestInterface } from '@src/interfaces/timeOff'
import {
  timeOffRequestApprovalColumn,
  timeOffRequestApprovedOnColumn,
  timeOffRequestApproverColumn,
  timeOffRequestDurationColumn,
  timeOffRequestEndDateColumn,
  timeOffRequestNameColumn,
  timeOffRequestRequestedOnColumn,
  timeOffRequestStartDateColumn,
  timeOffRequestStatusColumn,
} from '@src/constants/columns/timeOff'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import Stat from '@components/Stat/Stat'
import { canAddTimeOffAbsences, canAddTimeOffRequests } from '../ProfileSummary/common'
import { EmptyTableState, UserGuidesButton } from './common'
import { getDefaultYearOption, TimeOffYearSelect } from '@src/features/TimeOff/YearSelect'
import { InternalLink } from '@components/InternalLink/InternalLink'
import { TableNames } from '@src/constants/table'
import { useTableCalendarSwitcher } from '@src/features/TimeOffCalendarTable/hooks'
import { TableCalendarTabs } from '@src/features/TimeOffCalendarTable/constants'
import { TableWrapper } from '@components/Table/TableWrapper'
import { EmployeeTimeOffCalendar } from '@src/features/TimeOffCalendarTable/EmployeeTimeOffCalendar'

const ROW: RowInterface<EmployeeTimeOffRequestInterface> = {
  cells: [
    {
      ...timeOffRequestNameColumn,
      width: 120,
    },
    {
      ...timeOffRequestStartDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestEndDateColumn,
      width: 120,
    },
    {
      ...timeOffRequestDurationColumn,
      width: 100,
    },
    {
      ...timeOffRequestRequestedOnColumn,
      width: 150,
    },
    {
      ...timeOffRequestApprovedOnColumn,
      width: 150,
    },
    {
      ...timeOffRequestApproverColumn,
      width: 150,
    },
    {
      ...timeOffRequestApprovalColumn,
      width: 120,
    },
    {
      ...timeOffRequestStatusColumn,
      width: 120,
    },
  ],
}

interface Props {
  data: EmployeeInterface
}

const EmployeeTimeOffRequests = ({ data }: Props) => {
  const table = useTable({ getItems: getEmployeeTimeOffRequests(data.id) }, [
    {
      columnName: 'year',
      filters: [getDefaultYearOption()],
      nonResettable: true,
    },
  ])

  const tableCalendarSwitcher = useTableCalendarSwitcher()

  const canAddRequest = canAddTimeOffRequests(data)
  const canAddAbsence = canAddTimeOffAbsences(data)
  const canPerformAnyAction = canAddRequest || canAddAbsence

  const stats = useEmployeeTimeOffStats(data.id)

  const requestTimeOffLink = pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.GENERAL, {
    employeeId: data.id,
  })

  const requestYearOptions = useEmployeeTimeOffRequestYearOptions(data.id)

  return (
    <TableWrapper
      maxWidth={
        tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar ? 1024 : undefined
      }
    >
      <Flex mb="s-16" gap="s-24" alignItems="center">
        {tableCalendarSwitcher.tabBar}
        {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar &&
          canAddRequest && (
            <ActionButton use={InternalLink} to={requestTimeOffLink} useIcon="Plus">
              Request time off
            </ActionButton>
          )}
      </Flex>
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Calendar && (
        <EmployeeTimeOffCalendar employeeId={data.id} />
      )}
      {tableCalendarSwitcher.currentTab === TableCalendarTabs.Table && (
        <>
          <Flex mb="s-24" gap="s-32">
            <TimeOffYearSelect
              api={requestYearOptions}
              onChange={option => {
                table.onFilterChange(
                  {
                    columnName: 'year',
                    filters: [{ id: option.id, name: `${option.id}` }],
                  },
                  false,
                )
              }}
            />
            <Stat
              label="Pending approval"
              val={stats.data?.pending_request_count}
              color={Token.color.orange}
            />
          </Flex>

          <Flex mb="s-16">
            {canPerformAnyAction && (
              <MoreBar>
                {canAddRequest && (
                  <MoreBar.Action
                    use={InternalLink}
                    to={requestTimeOffLink}
                    useIcon="Plus"
                  >
                    Request time off
                  </MoreBar.Action>
                )}
                {canAddAbsence && (
                  <MoreBar.Action
                    use={InternalLink}
                    to={pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_ABSENCE.GENERAL, {
                      employeeId: data.id,
                    })}
                    useIcon="Plus"
                  >
                    Record absence
                  </MoreBar.Action>
                )}
              </MoreBar>
            )}
          </Flex>
          <Flex style={{ position: 'relative' }} flex="1 0">
            <AdjustableTable
              name={TableNames.EmployeeTimeOffRequests}
              useWindowScroll
              row={{
                ...ROW,
                linkToForm: row =>
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.EMPLOYEE_TIME_OFF_REQUEST.PREVIEW, {
                      employeeId: data.id,
                      id: row.id,
                    }),
                  ),
              }}
              {...table}
              emptyState={
                <EmptyTableState
                  title="No time off requests made yet"
                  description="Once submit your first time off request it will be shown here."
                  actions={
                    <>
                      {canAddRequest && (
                        <ActionButton use={Link} useIcon="Plus" to={requestTimeOffLink}>
                          Request time off
                        </ActionButton>
                      )}
                      <UserGuidesButton />
                    </>
                  }
                />
              }
            />
          </Flex>
        </>
      )}
    </TableWrapper>
  )
}

export default EmployeeTimeOffRequests
