import styled, { createGlobalStyle } from 'styled-components'
import css from '@styled-system/css'
import { Box, Token } from '@revolut/ui-kit'

export const MainPage = styled.main(() =>
  css({
    position: 'relative',
    overflow: 'hidden',
    padding: [0, 0, Token.size.s64],
  }),
)

export const MainContainer = styled(Box)(
  css({
    border: [0, 0, '#abb2e8 solid 1px'],
    borderRadius: [0, 0, '19px'],
  }),
)

export const GlobalStyle = createGlobalStyle`
*, *::before, *::after {
  box-sizing: border-box;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #FFFFFF;
  background: #0A0119;
  margin: 0;
  min-height: 100%;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}
`
