import React from 'react'
import { Box, DetailsCell, HStack, Icon, Subheader } from '@revolut/ui-kit'
import AdjustableTable from '@components/Table/AdjustableTable'
import { useTable } from '@components/Table/hooks'
import { AxiosResponse } from 'axios'
import { GetRequestData } from '@src/interfaces'
import { RowInterface } from '@src/interfaces/data'
import {
  JobPostingInterface,
  RecruiterLocationInterface,
} from '@src/interfaces/jobPosting'
import {
  recruiterLocationLocations,
  recruiterLocationName,
} from '@src/constants/columns/jobDescription'
import Tooltip from '@components/Tooltip/Tooltip'
import { Text } from '@revolut/ui-kit'
import { TableNames } from '@src/constants/table'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import HideIfCommercial from '@src/components/HideIfCommercial/HideIfCommercial'

type Props = Pick<JobPostingInterface, 'coordinator'> & {
  data?: RecruiterLocationInterface[]
}

const row: RowInterface<RecruiterLocationInterface> = {
  cells: [
    {
      ...recruiterLocationName,
      width: 300,
    },
    {
      ...recruiterLocationLocations,
      width: 600,
    },
  ],
}

const RecruitersAssigned = ({ data = [], coordinator }: Props) => {
  const table = useTable<RecruiterLocationInterface>({
    getItems: () =>
      Promise.resolve({
        data: {
          results: data || [],
        },
      } as AxiosResponse<GetRequestData<RecruiterLocationInterface>>),
  })

  if (!data.length && !coordinator) {
    return null
  }

  return (
    <>
      {!!data.length && (
        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>
              <HStack align="center" space="s-8">
                <Text>Recruiters assigned</Text>
                <Tooltip
                  placement="top"
                  text="Recruiters are assigned based on automation rules. To change a recruiter, check automation rule set for the specific location"
                >
                  <Icon name="InfoOutline" size={16} />
                </Tooltip>
              </HStack>
            </Subheader.Title>
          </Subheader>
          <AdjustableTable
            name={TableNames.RecruitersAssigned}
            hideCount
            row={row}
            {...table}
          />
        </Box>
      )}
      <HideIfCommercial>
        {!!coordinator && (
          <Box mt="s-4">
            <DetailsCell>
              <DetailsCell.Title>Coordinator</DetailsCell.Title>
              <DetailsCell.Content>
                <UserWithAvatar key={coordinator.id} {...coordinator} />
              </DetailsCell.Content>
            </DetailsCell>
          </Box>
        )}
      </HideIfCommercial>
    </>
  )
}

export default RecruitersAssigned
