import { addMonths } from 'date-fns'

export const getBarMonthFilter = (date: string | number) => {
  const from = addMonths(new Date(date), -1).toISOString()
  const to = date
  return {
    filters: [
      { name: from, id: from },
      { name: to as string, id: to },
    ],
    columnName: 'creation_date_time',
  }
}
