import { Route, Switch as RouteSwitch } from 'react-router-dom'
import React from 'react'
import { ROUTES } from '@src/constants/routes'
import General from '@src/pages/Forms/AnonymizeCandidate/General/General'

const AnonymizeCandidate = () => {
  return (
    <RouteSwitch>
      <Route exact path={ROUTES.FORMS.ANONYMIZE_CANDIDATE.GENERAL}>
        <General />
      </Route>
    </RouteSwitch>
  )
}

export default AnonymizeCandidate
