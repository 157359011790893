import React, { ReactNode, useState } from 'react'
import { Calendar, Input, Popup } from '@revolut/ui-kit'
import { formatDate } from '@src/utils/format'
import { CalendarDateProps } from '@revolut/ui-kit/types/dist/components/Calendar/CalendarDate'
import { Calendar as CalendarIcon } from '@revolut/icons'
import { DatePickerLocaleProvider } from '@src/components/Inputs/DatePickerInput/DatePickerLocaleProvider'
import { DateValue } from '@revolut/ui-kit/types/dist/components/Calendar/types'

export interface NewDatePickerProps
  extends Omit<CalendarDateProps, 'value' | 'onChange'> {
  value?: DateValue
  onChange: (value?: DateValue) => void
  disabled?: boolean
  error?: string
  label?: string
  required?: boolean
  name?: string
  hasError?: boolean
  message?: ReactNode
  onCaptionClick?: () => void
}

const MonthPicker = ({
  value,
  onChange,
  disabled,
  error,
  label = 'Select a date',
  required,
  name,
  hasError,
  message,
  onCaptionClick,
  ...props
}: NewDatePickerProps) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <Input
        readOnly
        label={label}
        onClick={() => setOpen(true)}
        value={value ? formatDate(value, 'MMM yyyy') : ''}
        data-testid="month-input"
        disabled={disabled}
        required={required}
        useIcon={CalendarIcon}
        aria-invalid={hasError}
        message={error || message}
        data-name={name}
      />
      <Popup open={isOpen} onClose={() => setOpen(false)} variant="bottom-sheet">
        <DatePickerLocaleProvider>
          <Calendar
            variant="month"
            value={value}
            onChange={val => {
              onChange(val)
              setOpen(false)
            }}
            labelEmptyValue="Select date"
            labelButtonClear="Clear"
            onCaptionClick={onCaptionClick}
            {...props}
          />
        </DatePickerLocaleProvider>
      </Popup>
    </>
  )
}

export default MonthPicker
