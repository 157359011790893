import { ImportSessionStatus } from '@src/interfaces/importData'

export enum ImportKPIsActions {
  Create = 'create',
  Update = 'update',
}

type ObjectWithStrings = { [key: string]: string }

export type ImportKPIsData = {
  action: ImportKPIsActions | null
  errors?: { [key: string]: string[] }
} & ObjectWithStrings

export interface ImportKPIsSession {
  status: { id: ImportSessionStatus; name: string }
  results: ImportKPIsData[]
  columns: string[]
  count: number
}

export interface ImportKPIsDataStats {
  all: number
  rowsWithErrors: number
  rowsForCreate: number
  rowsForUpdate: number
  errorRowsIndexes: number[]
}

export type ImportKPIsMeta = Omit<ImportKPIsSession, 'results'>

export interface ImportKPIsSessionStats {
  error_count: number
  errors: ImportKPIsData['errors'][]
  id: number
  invalid_count: number
  kpis_to_create_count: number
  kpis_to_update_count: number
  progress: number
  status: { id: ImportSessionStatus; name: string }
  total_count: number
}
