import React from 'react'
import { Item, Image } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'
import { FormattedMessage } from 'react-intl'

export const SelectCustomTile = () => {
  return (
    <Item
      variant="disclosure"
      use={InternalLink}
      to={pathToUrl(ROUTES.FORMS.ENGAGEMENT.EDIT, { step: 'basic' })}
    >
      <Item.Avatar size={60}>
        <Image src="https://assets.revolut.com/assets/3d-images/3D257.png" />
      </Item.Avatar>
      <Item.Content>
        <Item.Title>
          <FormattedMessage
            id='engagement.selectNew.custom.title"'
            defaultMessage="Build your own"
          />
        </Item.Title>
        <Item.Description>
          <FormattedMessage
            id='engagement.selectNew.custom.description"'
            defaultMessage="Select questions and built your own survey from scratch"
          />
        </Item.Description>
      </Item.Content>
    </Item>
  )
}
