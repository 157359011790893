import React, { useRef, useState } from 'react'
import { MoreBar, Tooltip, useTooltip } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { canAddPipCycle, selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { EmployeeInterface, IdStatuses } from '@src/interfaces/employees'
import { format, isBefore, isSameDay } from 'date-fns'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { createProbationCycle } from '@src/api/probationEmployees'
import { successNotification } from '@src/actions/NotificationActions'

interface EmployeeButtonProps {
  data: EmployeeInterface
  refreshData?: () => void
}

export const EmployeeCyclesButtons = ({ data, refreshData }: EmployeeButtonProps) => {
  const { id, status } = data
  const canAdd = useSelector(canAddPipCycle)
  const statusActive = status.id === IdStatuses.active

  const { data: performanceSettings } = useGetPerformanceSettings()
  const pipEnabled = performanceSettings?.enable_pip
  const probationEnabled = performanceSettings?.enable_probation

  const canManagePip = pipEnabled && canAdd

  const permissions = useSelector(selectPermissions)
  const canManageProbation =
    probationEnabled &&
    permissions.includes(PermissionTypes.ProbationCommitteeHRPermissions)

  if (!id || !statusActive || (!canManageProbation && !canManagePip)) {
    return null
  }

  return (
    <>
      {canManageProbation && <ProbationButton data={data} refreshData={refreshData} />}
      {canManagePip && <PIPButtons data={data} />}
    </>
  )
}

const ProbationButton = ({ data, refreshData }: EmployeeButtonProps) => {
  const [pending, setPending] = useState(false)
  const probationTooltip = useTooltip()
  const { performance_cycles } = data

  const probationRef = useRef(null)
  const styles = probationRef?.current
    ? getComputedStyle(probationRef?.current)
    : undefined

  const currentCycle = performance_cycles
    ?.filter(cycle => cycle.category === 'probation')
    .find?.(cycle => {
      const now = new Date()
      const end = new Date(cycle.end_date_time)
      return isBefore(now, end)
    })

  if (currentCycle) {
    return (
      <MoreBar.Action
        aria-disabled
        {...probationTooltip.getAnchorProps()}
        ref={probationRef}
      >
        Create probation cycle
        {styles?.visibility !== 'hidden' && (
          <Tooltip
            {...probationTooltip.getTargetProps()}
            anchorRef={probationRef}
            maxWidth="250px"
          >
            This employee is already on an active probation cycle from{' '}
            {format(new Date(currentCycle.start_date_time), 'd MMM')} -{' '}
            {format(new Date(currentCycle.end_date_time), 'd MMM')}.
          </Tooltip>
        )}
      </MoreBar.Action>
    )
  }

  const handleCreateCycle = async () => {
    setPending(true)
    try {
      await createProbationCycle(data.id)
      successNotification('Probation cycle created')
      refreshData?.()
    } finally {
      setPending(false)
    }
  }

  return (
    <>
      <MoreBar.Action onClick={handleCreateCycle} pending={pending}>
        Create probation cycle
      </MoreBar.Action>
    </>
  )
}

const PIPButtons = ({ data }: EmployeeButtonProps) => {
  const pipTooltip = useTooltip<HTMLButtonElement>()
  const { id, performance_cycles } = data
  const permissions = useSelector(selectPermissions)

  const pipRef = useRef(null)
  const styles = pipRef?.current ? getComputedStyle(pipRef?.current) : undefined

  const canManageCycle = permissions.includes(PermissionTypes.ManagePipCycles)
  const canManageCommitteeDecision = permissions.includes(
    PermissionTypes.ManagePipCommitteeDecision,
  )

  const currentCycle = performance_cycles
    ?.filter(cycle => cycle.category === 'PIP')
    .find?.(cycle => {
      const now = new Date()
      const end = new Date(cycle.end_date_time)
      return isBefore(now, end) || isSameDay(now, end)
    })

  if (currentCycle) {
    return (
      <MoreBar.Action aria-disabled {...pipTooltip.getAnchorProps()} ref={pipRef}>
        Create PIP cycle
        {styles?.visibility !== 'hidden' && (
          <Tooltip {...pipTooltip.getTargetProps()} anchorRef={pipRef} maxWidth="250px">
            The employee is already on a PIP from{' '}
            {format(new Date(currentCycle.start_date_time), 'd MMM')} -{' '}
            {format(new Date(currentCycle.end_date_time), 'd MMM')}. You can not initiate
            another PIP till the current PIP ends
          </Tooltip>
        )}
      </MoreBar.Action>
    )
  }

  return (
    <>
      {(canManageCycle || canManageCommitteeDecision) && (
        <MoreBar.Action
          onClick={() =>
            navigateTo(pathToUrl(ROUTES.FORMS.PIP.SETTINGS, { employeeId: id }))
          }
        >
          Create PIP cycle
        </MoreBar.Action>
      )}
    </>
  )
}
