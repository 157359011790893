import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import { useLocalStorage } from '@src/hooks/useLocalStorage'
import { LocalStorageKeys } from '@src/store/auth/types'
import { APPLICATIONS } from '@src/constants/hub'

export const PIN_APP_LABEL = 'Pin app'
export const UNPIN_APP_LABEL = 'Unpin app'

const availableApps = Object.values(APPLICATIONS).map(app => app.id) as string[]

type SetPinnedAppsAction = (apps: string[]) => string[]

/** Handle case when app was removed, but it's still in localStorage from before, filter those out */
const filter = (apps: string[]) => apps.filter(app => availableApps.includes(app))

interface PinnedAppsContextInterface {
  pinnedApps: string[]
  setPinnedApps: (callback: SetPinnedAppsAction) => void
}

export const PinnedAppsContext = createContext<PinnedAppsContextInterface>({
  pinnedApps: [],
  setPinnedApps: () => {},
})

export const usePinnedApps = () => useContext(PinnedAppsContext)

export const PinnedAppsProvider = ({ children }: { children: ReactNode }) => {
  const [localStoragePinnedApps, setLocalStoragePinnedApps] = useLocalStorage<string[]>(
    LocalStorageKeys.PINNED_APPS,
    [],
  )

  const contextValue = useMemo(() => {
    const setPinnedApps = (callback: SetPinnedAppsAction) => {
      setLocalStoragePinnedApps(apps => filter(callback(apps)))
    }

    return {
      pinnedApps: filter(localStoragePinnedApps),
      setPinnedApps,
    }
  }, [localStoragePinnedApps, setLocalStoragePinnedApps])

  return (
    <PinnedAppsContext.Provider value={contextValue}>
      {children}
    </PinnedAppsContext.Provider>
  )
}
