import { StatusPopup, Button, Box, Copyable } from '@revolut/ui-kit'
import React, { useState } from 'react'
import { useGenerateSecretKey } from '@src/api/employees'
import { NotificationTypes } from '@src/store/notifications/types'
import { pushNotification } from '@src/store/notifications/actions'
import { SUCCESS_DEFAULT_DURATION } from '@src/constants/notifications'

interface GenerateSecretPopupProps {
  open: boolean
  serviceAccountId: number
  onClose: () => void
}

export const GenerateSecretPopup = ({
  open,
  serviceAccountId,
  onClose,
}: GenerateSecretPopupProps) => {
  const [secretKey, setSecretKey] = useState('')
  const {
    mutateAsync: generateSecretKey,
    isLoading,
    isSuccess,
    isError,
  } = useGenerateSecretKey(serviceAccountId)

  const handleGenerateKey = () => {
    generateSecretKey(serviceAccountId).then(data => {
      setSecretKey(data?.data.secretkey)
    })
  }

  const handleOnClose = () => {
    onClose()
    setSecretKey('')
  }

  if (isSuccess && secretKey) {
    return (
      <StatusPopup variant="warning" open={open}>
        <StatusPopup.Title>
          The secret key will be displayed only once, please, copy it and store securely
        </StatusPopup.Title>
        <StatusPopup.Description>
          <StatusPopup.Description>
            <Box m="auto" width={'fit-content'}>
              <Copyable
                justifyContent={'center'}
                value={secretKey}
                labelButtonCopy="Copy"
                onCopy={() => {
                  pushNotification({
                    value: 'Secret key copied to clipboard',
                    duration: SUCCESS_DEFAULT_DURATION,
                    type: NotificationTypes.success,
                  })
                }}
              >
                {secretKey}
              </Copyable>
            </Box>
          </StatusPopup.Description>
        </StatusPopup.Description>
        <StatusPopup.Actions>
          <Button elevated onClick={handleOnClose}>
            Confirm
          </Button>
        </StatusPopup.Actions>
      </StatusPopup>
    )
  }

  if (isError) {
    return (
      <StatusPopup variant="error" open={open}>
        <StatusPopup.Title>Failed to generate secret key</StatusPopup.Title>
        <StatusPopup.Description>
          Something went wrong. Please try again.
        </StatusPopup.Description>
      </StatusPopup>
    )
  }

  return (
    <StatusPopup variant="warning" open={open}>
      <StatusPopup.Title>
        Are you sure you want to generate the secret key?
      </StatusPopup.Title>
      <StatusPopup.Description>
        The previous secret key will be invalidated
      </StatusPopup.Description>
      <StatusPopup.Actions>
        <Button elevated onClick={handleGenerateKey} pending={isLoading}>
          Continue
        </Button>
        <Button variant="secondary" onClick={handleOnClose}>
          Cancel
        </Button>
      </StatusPopup.Actions>
    </StatusPopup>
  )
}
