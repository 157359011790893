import React from 'react'
import { Cell, Flex, HStack } from '@revolut/ui-kit'
import Stat from '@components/Stat/Stat'
import {
  CommonProps,
  getStatColor,
  MeetingsWithPageParams,
} from '@src/pages/Forms/MeetingsWith/common'
import MeetingsTable from '@src/pages/Forms/MeetingsWith/MeetingsTable'
import { FilterByInterface, SORT_DIRECTION, SortByInterface } from '@src/interfaces/data'
import { useParams } from 'react-router-dom'
import { useTable } from '@components/Table/hooks'
import { MeetingInterface, MeetingStatus } from '@src/interfaces/meetings'
import { getMeetingsWithCompanionRequests } from '@src/api/meetings'

const sorting: SortByInterface[] = [
  {
    sortBy: 'start',
    direction: SORT_DIRECTION.ASC,
  },
]

const filtering: FilterByInterface[] = [
  {
    filters: [{ id: MeetingStatus.Completed, name: MeetingStatus.Completed }],
    columnName: 'status',
    nonResettable: true,
  },
]

const Past = ({ data }: CommonProps) => {
  const { companionId, employeeId } = useParams<MeetingsWithPageParams>()
  const table = useTable<MeetingInterface>(
    getMeetingsWithCompanionRequests(employeeId, companionId),
    filtering,
    sorting,
  )

  return (
    <Cell>
      <Flex flexDirection="column" width="100%">
        <HStack mb="s-16" space="s-16">
          <Stat
            label="Avg days between meetings"
            color={getStatColor(data.avg_days_between_meetings)}
            val={data.avg_days_between_meetings || '-'}
          />
        </HStack>
        <MeetingsTable table={table} />
      </Flex>
    </Cell>
  )
}

export default Past
