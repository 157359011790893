import React, { useRef, useState } from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import FileUploader from '@components/Inputs/FileUploader/FileUploader'
import {
  Box,
  Button,
  Header,
  Image,
  Popup,
  Text,
  Token,
  VStack,
  StatusWidget,
  IconName,
} from '@revolut/ui-kit'
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop'
import { handleError } from '@src/api'
import isArray from 'lodash/isArray'
import { IconSelect } from '@components/AvatarUploadPopup/components/IconSelect'

export interface AvatarUploadPopupProps {
  circularCrop?: boolean
  open: boolean
  onClose: () => void
  onSave: (file: File) => void
  onSaveIcon?: (iconName: IconName) => void
  onRemove?: () => Promise<void>
  minHeight?: number
  minWidth?: number
  title?: string
  icon?: IconName | null
  withFile?: boolean
  withIcons?: boolean
}

const getCanvasBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> => {
  return new Promise(resolve => {
    canvas.toBlob(blob => {
      resolve(blob)
    })
  })
}

const centerAspectCrop = (
  mediaWidth: number,
  mediaHeight: number,
  minWidth: number,
  minHeight: number,
) => {
  let width
  let height

  if (mediaWidth < mediaHeight) {
    width = mediaWidth / minWidth > 1.1 ? mediaWidth * 0.9 : mediaWidth
  } else {
    height = mediaHeight / minHeight > 1.1 ? mediaHeight * 0.9 : mediaHeight
  }

  return centerCrop(
    makeAspectCrop(
      {
        unit: 'px',
        width,
        height,
      },
      1,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const AvatarUploadPopup = ({
  circularCrop = true,
  open,
  onClose,
  onSave,
  onSaveIcon,
  onRemove,
  minHeight = 80,
  minWidth = 80,
  title,
  icon,
  withFile = true,
  withIcons = false,
}: AvatarUploadPopupProps) => {
  const [file, setFile] = useState<File | null>()
  const [uploading, setUploading] = useState(false)
  const [removing, setRemoving] = useState(false)
  const [crop, setCrop] = useState<Crop>()
  const [image, setImage] = useState<string>()
  const [selectedIcon, setSelectedIcon] = useState<IconName | null>(icon || null)
  const [error, setError] = useState<string>()
  const imgRef = useRef<HTMLImageElement>(null)

  const onFileChange = (data: File | File[] | null) => {
    const fileData = isArray(data) ? data[0] : data
    setError(undefined)
    setFile(fileData)
    if (fileData) {
      setImage(URL.createObjectURL(fileData))
    }
  }

  const handleUpload = async () => {
    setUploading(true)
    try {
      await uploadCroppedImg()
    } finally {
      setUploading(false)
    }
  }

  const handleSetIcon = async () => {
    if (!onSaveIcon || !selectedIcon) {
      return
    }

    setUploading(true)
    try {
      await onSaveIcon(selectedIcon)
    } finally {
      setUploading(false)
    }
  }

  const handleRemove = async () => {
    setRemoving(true)
    try {
      await onRemove?.()
    } finally {
      setRemoving(false)
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    if (width < minWidth || height < minHeight) {
      setError('Image is too small')
    }
    setCrop(centerAspectCrop(width, height, minWidth, minHeight))
  }

  const uploadCroppedImg = async () => {
    if (!imgRef?.current || !crop) {
      return
    }

    try {
      const canvas = document.createElement('canvas')
      const scaleX = imgRef.current.naturalWidth / imgRef.current.width
      const scaleY = imgRef.current.naturalHeight / imgRef.current.height
      canvas.width = circularCrop ? minWidth : crop.width
      canvas.height = circularCrop ? minHeight : crop.height
      const ctx = canvas.getContext('2d')

      ctx?.drawImage(
        imgRef.current,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height,
      )

      const blob = await getCanvasBlob(canvas)

      if (blob) {
        await onSave(new File([blob], 'logo.jpg', { type: 'image/jpeg' }))
      }
    } catch (e) {
      handleError(e)
    }
  }

  return (
    <Popup
      variant="bottom-sheet"
      open={open}
      onClose={onClose}
      closeOnOverlayClick={!uploading}
    >
      <Header variant="bottom-sheet">
        <Header.Title data-autofocus="true">{title || 'Upload image'}</Header.Title>
      </Header>
      <VStack gap="s-16">
        {error ? (
          <StatusWidget>
            <StatusWidget.Image src="https://assets.revolut.com/assets/3d-images/3D083.png" />
            <StatusWidget.Title>{error}</StatusWidget.Title>
          </StatusWidget>
        ) : null}

        {withIcons && !file ? (
          <>
            {withFile ? <Text>Pick one of these icons...</Text> : null}
            <IconSelect onChange={setSelectedIcon} selectedIcon={selectedIcon} />
          </>
        ) : null}

        {file && !error ? (
          <Box alignSelf="center">
            <ReactCrop
              crop={crop}
              onChange={setCrop}
              aspect={circularCrop ? 1 : undefined}
              circularCrop={circularCrop}
              minHeight={minHeight}
              minWidth={minWidth}
              keepSelection
            >
              <Image
                src={image}
                ref={imgRef}
                width="auto"
                maxWidth="100%"
                // we need minHeight, as in some cases where the image is long, but has a small height it will be resized to height < minHeight and raise the error
                minHeight={minHeight}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </Box>
        ) : null}

        {withFile ? (
          <>
            {withIcons ? <Text>...or upload your own</Text> : null}

            <Box>
              <FileUploader
                name="logo"
                value={file}
                onChange={onFileChange}
                disabled={uploading}
                accept={'image/jpeg, image/png'}
                attachButtonText="Click to attach image"
                uploadInstructions={
                  <Text variant="small" color={Token.color.greyTone50} pt="s-4">
                    {`Image should be at least ${minWidth}x${minHeight} pixels`}
                  </Text>
                }
              />
            </Box>
          </>
        ) : null}
      </VStack>
      <Popup.Actions horizontal>
        <Button variant="secondary" onClick={onClose} disabled={uploading}>
          Cancel
        </Button>
        {onRemove ? (
          <Button onClick={handleRemove} variant="negative" pending={removing}>
            Remove
          </Button>
        ) : null}
        {withFile && !(!file && selectedIcon) ? (
          <Button
            onClick={handleUpload}
            disabled={!file || uploading}
            pending={uploading}
          >
            Upload
          </Button>
        ) : null}
        {(withIcons && !file && selectedIcon) || !withFile ? (
          <Button
            onClick={handleSetIcon}
            disabled={!selectedIcon || uploading}
            pending={uploading}
          >
            Confirm
          </Button>
        ) : null}
      </Popup.Actions>
    </Popup>
  )
}

export default AvatarUploadPopup
