import React, { useState } from 'react'
import {
  Avatar,
  abbreviate,
  Icon,
  Relative,
  Circle,
  Flex,
  Tooltip,
  useTooltip,
  Box,
  Color,
  IconName,
} from '@revolut/ui-kit'
import { getAvatarUrl } from '@src/utils/employees'
import { UserAvatarUpload } from '@src/features/UserAvatarUpload/UserAvatarUpload'
import { EmployeeInterface } from '@src/interfaces/employees'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { formatRequestDate } from '@src/constants/columns/timeOff'
import { EmployeeTimeOffRequestInterface, TimeOffUnit } from '@src/interfaces/timeOff'
import {
  employeeEventToColor,
  employeeEventToIcon,
  formatAnniversary,
} from '@src/components/EmployeeEvent/common'
import { formatPeriod } from '@src/utils/format'

interface FormTitleAvatarProps {
  data: EmployeeInterface
  refreshData: () => void
  timeOff?: EmployeeTimeOffRequestInterface
}

export const FormTitleAvatar = ({ data, refreshData, timeOff }: FormTitleAvatarProps) => {
  const user = useSelector(selectUser)
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false)
  const [isMouseOver, setMouseOver] = useState(false)

  if (user.id !== data.id) {
    return <EmployeeAvatar timeOff={timeOff} data={data} />
  }

  return (
    <>
      <Relative
        onMouseEnter={() => setMouseOver(true)}
        onMouseLeave={() => setMouseOver(false)}
      >
        <EmployeeAvatar timeOff={timeOff} data={data} />
        {isMouseOver ? (
          <Circle
            bg="modal-overlay"
            size={38}
            style={{
              position: 'absolute',
              top: 'calc(50% + 3px)',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              cursor: 'pointer',
            }}
            onClick={() => setAvatarUploadOpen(true)}
          >
            <Icon name="Camera" color="white" size={24} />
          </Circle>
        ) : null}
      </Relative>
      <UserAvatarUpload
        open={avatarUploadOpen}
        onClose={() => setAvatarUploadOpen(false)}
        onSuccess={refreshData}
      />
    </>
  )
}

interface EmployeeAvatarProps {
  data: EmployeeInterface
  timeOff: EmployeeTimeOffRequestInterface | undefined
}

const EmployeeAvatar = ({ data, timeOff }: EmployeeAvatarProps) => {
  const tooltip = useTooltip()

  const eventProps = (() => {
    let bg: Color
    let useIcon: IconName
    let tooltipContent = ''

    const formatTimeOffWithUnit = (toDateTime: string, unit: TimeOffUnit) => {
      return `OOO until ${formatRequestDate(toDateTime, unit.id)}`
    }

    if (!data.event) {
      /** Keeping it backwards compatible, current event implementation doesn't support all the previous functionality */
      if (timeOff?.to_date_time && timeOff?.unit) {
        return {
          bg: employeeEventToColor.time_off,
          useIcon: employeeEventToIcon.time_off,
          tooltipContent: formatTimeOffWithUnit(timeOff.to_date_time, timeOff.unit),
        }
      }
      return null
    }

    bg = employeeEventToColor[data.event.event_type.id]
    useIcon = employeeEventToIcon[data.event.event_type.id]

    if (data.event.event_type.id === 'time_off') {
      if (timeOff?.to_date_time && timeOff?.unit) {
        tooltipContent = formatTimeOffWithUnit(timeOff.to_date_time, timeOff.unit)
      } else {
        tooltipContent = `OOO ${formatPeriod(data.event.start_date, data.event.end_date, {
          showOneDateIfStartAndEndDatesAreSame: true,
        })}`
      }
    }
    if (data.event.event_type.id === 'birthday') {
      tooltipContent = 'Celebrating birthday!'
    }
    if (data.event.event_type.id === 'anniversary') {
      tooltipContent = `${formatAnniversary(
        data.event.start_date,
        data.joining_date_time,
      )}!`
    }

    return { bg, useIcon, tooltipContent }
  })()

  return (
    <Box pt={eventProps ? 's-6' : undefined} px={eventProps ? 's-6' : undefined}>
      <Avatar
        size={56}
        image={getAvatarUrl(data.avatar, 'md')}
        label={abbreviate(data.full_name)}
        {...tooltip.getAnchorProps()}
        progress={eventProps ? 1 : undefined}
        progressColor={eventProps ? eventProps.bg : undefined}
        aria-label={`${data.full_name} avatar`}
      >
        {eventProps && (
          <Avatar.Badge bg={eventProps.bg} useIcon={eventProps.useIcon}>
            {eventProps.tooltipContent ? (
              <Tooltip {...tooltip.getTargetProps()}>
                <Flex style={{ whiteSpace: 'break-spaces' }}>
                  {eventProps.tooltipContent}
                </Flex>
              </Tooltip>
            ) : null}
          </Avatar.Badge>
        )}
      </Avatar>
    </Box>
  )
}
