import React, { useRef, useState } from 'react'
import { chain, CheckboxSelect, FilterButton, SelectOptionType } from '@revolut/ui-kit'

import { IdAndName } from '@src/interfaces'
import {
  PAYROLL_ISSUES_LEVEL_CRITICAL,
  PAYROLL_ISSUES_LEVEL_WARNING,
  PayrollIssuesLevelId,
} from '@src/interfaces/payrollV2'

const FILTER_OPTIONS: SelectOptionType<IdAndName<PayrollIssuesLevelId>>[] = [
  {
    key: PAYROLL_ISSUES_LEVEL_WARNING,
    label: 'Warning',
    value: { id: PAYROLL_ISSUES_LEVEL_WARNING, name: 'Warning' },
  },
  {
    key: PAYROLL_ISSUES_LEVEL_CRITICAL,
    label: 'Critical',
    value: { id: PAYROLL_ISSUES_LEVEL_CRITICAL, name: 'Critical' },
  },
]

type Props = {
  value: IdAndName<PayrollIssuesLevelId>[]
  setValue: (newValue: IdAndName<PayrollIssuesLevelId>[]) => void
}
export const IssueLevelFilter = ({ value, setValue }: Props) => {
  const [isOpen, setIsOpen] = useState(false)

  const anchorRef = useRef(null)

  return (
    <>
      <FilterButton
        ref={anchorRef}
        useIcon="Filter"
        onClick={() => setIsOpen(!isOpen)}
        onClear={value.length ? () => setValue([]) : undefined}
        active={!!value.length}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        {chain(
          'Issue level',
          value.length ? value.map(filter => filter.name).join(', ') : undefined,
        )}
      </FilterButton>
      <CheckboxSelect<
        IdAndName<PayrollIssuesLevelId>,
        SelectOptionType<IdAndName<PayrollIssuesLevelId>>
      >
        anchorRef={anchorRef}
        open={isOpen}
        type="confirm"
        onClose={() => setIsOpen(false)}
        options={FILTER_OPTIONS}
        value={value}
        onChange={setValue}
        labelList="Issue levels"
      />
    </>
  )
}
