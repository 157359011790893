import React from 'react'
import { Text, Token } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { ROUTES } from '@src/constants/routes'
import { pathToUrl } from '@src/utils/router'

type LocationLinkProps = {
  id: number
  locationName: string
}

export const LocationLink = ({ id, locationName }: LocationLinkProps) => {
  return (
    <InternalLink
      key={id}
      to={pathToUrl(ROUTES.FORMS.LOCATION.GENERAL, { id })}
      target="_blank"
      style={{ textDecoration: 'none' }}
    >
      <Text color={Token.color.blue}>{locationName}</Text>
    </InternalLink>
  )
}
