import {
  CalendarEventInterface,
  CurrentWeekType,
  ScheduleInterviewContextInterface,
} from '@src/pages/Forms/Candidate/ScheduleInterview/ScheduleInterviewContext'
import isSameDay from 'date-fns/isSameDay'
import { notReachable } from '@src/utils/notReachable'
import { EligibleInterviewerInterface } from '@src/interfaces/interviewTool'
import { useGetInterviewersCalendarSlotsAvailability } from '@src/pages/Forms/Candidate/ScheduleSidebar/useGetInterviewersCalendarSlotsAvailability'

type SlotAvailable = {
  id: 'SlotAvailable'
  slot: 'interview' | 'free'
  selectedSlot: CalendarEventInterface
  interviewer: EligibleInterviewerInterface
}
type LimitOverWeek = {
  id: 'LimitOverWeek'
  booked: number
  limit: number
  currentWeek: CurrentWeekType
  interviewer: EligibleInterviewerInterface
}
type LimitOverDay = {
  id: 'LimitOverDay'
  booked: number
  limit: number
  selectedSlot: CalendarEventInterface
  interviewer: EligibleInterviewerInterface
}
type SlotUnavailable = {
  id: 'SlotUnavailable'
  selectedSlot: CalendarEventInterface
  interviewer: EligibleInterviewerInterface
}
type WeekAvailable = {
  id: 'WeekAvailable'
  interviewer: EligibleInterviewerInterface
  interviewSlotsCount: number
  freeSlotsCount: number
}
type WeekUnavailable = {
  id: 'WeekUnavailable'
  interviewer: EligibleInterviewerInterface
}

export type InterviewerAvailability =
  | SlotAvailable
  | LimitOverWeek
  | LimitOverDay
  | SlotUnavailable
  | WeekAvailable
  | WeekUnavailable

export type UseGetInterviewersAvailabilityProps = {
  interviewers: EligibleInterviewerInterface[]
  roundId?: string | number
  enabled: boolean
} & Pick<
  ScheduleInterviewContextInterface,
  | 'currentWeek'
  | 'calendarEvent'
  | 'timeZone'
  | 'duration'
  | 'durationUnit'
  | 'interviewStage'
>

export const isInterviewerAvailable = (availability: InterviewerAvailability) =>
  availability.id === 'SlotAvailable' || availability.id === 'WeekAvailable'

export const useGetInterviewersAvailability = ({
  interviewers,
  roundId,
  calendarEvent,
  timeZone,
  duration,
  durationUnit,
  interviewStage,
  currentWeek,
  enabled,
}: UseGetInterviewersAvailabilityProps): InterviewerAvailability[] => {
  const interviewersCalendarSlotsAvailability =
    useGetInterviewersCalendarSlotsAvailability({
      roundId,
      currentWeek,
      calendarEvent,
      timeZone,
      duration,
      durationUnit,
      interviewStage,
      enabled,
    })

  if (!enabled) {
    return []
  }

  return interviewers.map(interviewer => {
    const limits = interviewer.employee_interviews_availability_limits

    // first we check if the interviewer has limits and didn't exceed the limits for interview per current week and day
    if (limits) {
      const booked = interviewer.interviewer_interview_counts
      const weekLimit = limits.max_interviews_per_week
      const dayLimit = limits.max_interviews_per_day

      const interviewsScheduledPerWeek =
        booked.find(item => item.period_type === 'week')?.n_interviews_for_period || 0

      if (weekLimit !== null && interviewsScheduledPerWeek >= weekLimit) {
        return {
          id: 'LimitOverWeek',
          booked: interviewsScheduledPerWeek,
          limit: weekLimit,
          currentWeek,
          interviewer,
        }
      }

      if (dayLimit !== null && calendarEvent) {
        const interviewsScheduledPerDay =
          booked.find(
            item =>
              item.period_type === 'day' &&
              isSameDay(new Date(item.period_start_datetime), calendarEvent.start),
          )?.n_interviews_for_period || 0

        if (interviewsScheduledPerDay >= dayLimit) {
          return {
            id: 'LimitOverDay',
            booked: interviewsScheduledPerDay,
            limit: dayLimit,
            selectedSlot: calendarEvent,
            interviewer,
          }
        }
      }
    }

    // then we check if the interviewer has free slot or interview slot in their calendar
    const interviewerCalendarSlotsAvailability =
      interviewersCalendarSlotsAvailability.find(
        item => item.interviewer.id === interviewer.id,
      )

    // this case normally should not happen, only if some unexpected issues with the BE
    // just mark as unavailable
    if (!interviewerCalendarSlotsAvailability) {
      if (!calendarEvent) {
        return {
          id: 'WeekUnavailable',
          interviewer,
        }
      }

      return {
        id: 'SlotUnavailable',
        selectedSlot: calendarEvent,
        interviewer,
      }
    }

    // if the slot was selected (calendarEvent) we are getting slot: 'free' | 'interview' | 'unavailable'
    if ('slot' in interviewerCalendarSlotsAvailability) {
      switch (interviewerCalendarSlotsAvailability.slot) {
        case 'interview':
          return {
            id: 'SlotAvailable',
            slot: 'interview',
            selectedSlot: interviewerCalendarSlotsAvailability.date,
            interviewer,
          }

        case 'free':
          return {
            id: 'SlotAvailable',
            slot: 'free',
            selectedSlot: interviewerCalendarSlotsAvailability.date,
            interviewer,
          }

        case 'unavailable':
          return {
            id: 'SlotUnavailable',
            selectedSlot: interviewerCalendarSlotsAvailability.date,
            interviewer,
          }

        default:
          throw notReachable(interviewerCalendarSlotsAvailability.slot)
      }
    }

    // if the slot WAS NOT selected (calendarEvent) we are getting counts of interviews and free slots per week
    if (
      !interviewerCalendarSlotsAvailability.weekInterviewSlotsCount &&
      !interviewerCalendarSlotsAvailability.weekFreeSlotsCount
    ) {
      return {
        id: 'WeekUnavailable',
        interviewer,
      }
    }

    return {
      id: 'WeekAvailable',
      interviewer,
      interviewSlotsCount: interviewerCalendarSlotsAvailability.weekInterviewSlotsCount,
      freeSlotsCount: interviewerCalendarSlotsAvailability.weekFreeSlotsCount,
    }
  })
}
