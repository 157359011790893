import { Flex, TextButton, Widget, Grid, Bar, Text, chain } from '@revolut/ui-kit'
import Loader from '@src/components/CommonSC/Loader'
import { isEmpty, result } from 'lodash'
import React, { ReactNode } from 'react'
import { Item } from './Item'
import { SidebarMultiselectProps, SidebarOption, SidebarSelection } from '../types'

interface Props {
  pending?: boolean
  selection: SidebarSelection
  emptyDescription: ReactNode
  searchActions?: React.ReactNode
  searchValue: string
  disabled?: boolean
  handleClearAll: VoidFunction
  handleItemClick: (item: SidebarOption, checked: boolean) => void
  options: SidebarMultiselectProps['options']
  emptySearchMessage: SidebarMultiselectProps['emptySearchMessage']
}

export const SearchMode = ({
  pending,
  options,
  searchActions,
  emptyDescription,
  searchValue,
  selection,
  handleClearAll,
  disabled,
  handleItemClick,
  emptySearchMessage,
}: Props) => {
  return (
    <>
      <Flex justifyContent="space-between" mb="s-16">
        <Text color="grey-tone-50">
          {searchValue ? chain('Search results', options.length) : emptySearchMessage}
        </Text>
        <Bar>
          {searchActions}
          {!isEmpty(result) && (
            <TextButton
              disabled={disabled}
              pl="s-6"
              fontWeight={500}
              onClick={handleClearAll}
            >
              Clear all
            </TextButton>
          )}
        </Bar>
      </Flex>
      <Widget p="18px">
        {pending ? (
          <Loader />
        ) : (
          <Grid gap="s-32">
            {options.length ? (
              options.map((item, key) => {
                // render only first 20 for performance reasons, use search for showing the rest
                if (key > 20) {
                  return false // break
                }
                return (
                  <Item
                    key={item.id}
                    checked={!!selection[item.id]}
                    option={item}
                    handleItemClick={handleItemClick}
                  />
                )
              })
            ) : (
              <>
                {!!searchValue && (
                  <Text data-testid="sidebar-multiselect-no-items" color="grey-tone-50">
                    No matching results found...
                  </Text>
                )}
                {emptyDescription}
              </>
            )}
          </Grid>
        )}
      </Widget>
    </>
  )
}
