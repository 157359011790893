import React from 'react'
import { navigateTo } from '@src/actions/RouterActions'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { TabProps } from '../common/interface'
import { NextButton } from '../components/Buttons'
import { StartPageView } from '../components/StartPageView'

export const Start = ({
  joiningDateTime,
  isLoadingBasicInfo,
  isLoadingOnboardingSettings,
  name,
  nextRoute,
  onboardingSettings,
  onComplete,
}: TabProps) => {
  const text = onboardingSettings?.welcome_page_html
  const title = onboardingSettings?.welcome_page_title
  const videoUrl = onboardingSettings?.welcome_page_video_url

  const navigateToNextStep = () => {
    if (nextRoute) {
      navigateTo(nextRoute)
      onComplete?.()
    }
  }

  return (
    <>
      <PageBody>
        <StartPageView
          joiningDateTime={joiningDateTime}
          isLoading={isLoadingOnboardingSettings}
          isLoadingBasicInfo={isLoadingBasicInfo}
          name={name}
          text={text}
          title={title}
          videoUrl={videoUrl}
        />
      </PageBody>
      <PageActions>
        <NextButton afterSubmit={navigateToNextStep}>Let's start</NextButton>
      </PageActions>
    </>
  )
}
