import React from 'react'
import { selectPermissions } from '@src/store/auth/selectors'
import { useSelector } from 'react-redux'
import { MoreBar } from '@revolut/ui-kit'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { pathToUrl } from '@src/utils/router'
import { bulkTabs } from '@src/pages/Recruitment/RecruitmentSubTabs/BulkActions/BulkActionsPage'

export const ViewBulkAction = () => {
  const permissions = useSelector(selectPermissions)
  const tab = bulkTabs.find(p => permissions.includes(p.permission))
  if (!tab) {
    return null
  }
  return (
    <MoreBar.Action useIcon="20/Groups" use={InternalLink} to={pathToUrl(tab.to)}>
      Bulk actions
    </MoreBar.Action>
  )
}
