import { Token } from '@revolut/ui-kit'
import {
  ScorecardSections,
  ScorecardRadioValues,
  scorecardRadioHeaders,
} from '../../../interfaces/scorecard'
import React from 'react'
import styled from 'styled-components'

const AllSectionReviewsContainer = styled.div`
  padding: 15px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 92px auto;
  grid-gap: 8px;
  color: white;
`
const AllSectionReviewsThumb = styled.div`
  white-space: nowrap;
  & > div > svg {
    stroke: ${Token.color.foreground};
  }
`
const AllSectionReviewsLineContainer = styled.div``
const AllSectionReviewsLine = styled.div<{ ratio: number }>`
  height: 12px;
  background-color: ${Token.color.widgetBackground};
  border-radius: 2px;
  width: ${props => props.ratio * 92}px;
`
const AllSectionReviewsCount = styled.div``

interface AllOptionsReviewsTooltip {
  section: ScorecardSections
}

export const AllSectionReviewsTooltip = ({ section }: AllOptionsReviewsTooltip) => {
  const counts = {
    [ScorecardRadioValues.unsatisfactory]: 0,
    [ScorecardRadioValues.below_expectations]: 0,
    [ScorecardRadioValues.meets_expectations]: 0,
    [ScorecardRadioValues.above_expectations]: 0,
    [ScorecardRadioValues.exceptional]: 0,
    [ScorecardRadioValues.neutral]: 0,
  }
  const order = [
    ScorecardRadioValues.unsatisfactory,
    ScorecardRadioValues.below_expectations,
    ScorecardRadioValues.meets_expectations,
    ScorecardRadioValues.above_expectations,
    ScorecardRadioValues.exceptional,
    ScorecardRadioValues.neutral,
  ]
  let max = 0

  section.fields.forEach(field => {
    order.forEach(key => {
      /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
      if (field.value && field.value[key]) {
        /** @ts-ignore TODO: Fix required after `suppressImplicitAnyIndexErrors` rule was removed */
        counts[key] += field.value[key].length
        if (max < counts[key]) {
          max = counts[key]
        }
      }
    })
  })

  return (
    <AllSectionReviewsContainer>
      {order.map((key, i) => {
        if (counts[key] === 0) {
          return null
        }

        return (
          <React.Fragment key={i}>
            <AllSectionReviewsThumb>{scorecardRadioHeaders[key]}</AllSectionReviewsThumb>
            <AllSectionReviewsLineContainer>
              <AllSectionReviewsLine ratio={counts[key] / max} />
            </AllSectionReviewsLineContainer>
            <AllSectionReviewsCount>{counts[key]}</AllSectionReviewsCount>
          </React.Fragment>
        )
      })}
    </AllSectionReviewsContainer>
  )
}
