import React from 'react'
import { CookieConsentProvider } from '@src/pages/Landing/hooks/useCookieConsentContext'
import { CookieConsent } from '@src/pages/Landing/components/CookieConsent'
import { useNeedAnalytics } from '@src/utils'

export const CookieConsentWrapper: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const needAnalytics = useNeedAnalytics()
  return (
    <CookieConsentProvider>
      <CookieConsent disableCookieConsent={!needAnalytics} />
      {children}
    </CookieConsentProvider>
  )
}
