import React from 'react'
import produce from 'immer'
import { Action, StatusPopup, Button, useStatusPopup } from '@revolut/ui-kit'

import { useNotificationRequestDelete } from '@src/api/notificationTemplate'
import { useTableReturnType } from '@components/Table/hooks'
import {
  NotificationSendingHistoryInterface,
  NotificationType,
} from '@src/interfaces/notificationTemplate'
import { pushNotification } from '@src/store/notifications/actions'
import { ERROR_DEFAULT_DURATION } from '@src/constants/notifications'
import { NotificationTypes } from '@src/store/notifications/types'

interface CancelNotificationActionProps {
  data: NotificationSendingHistoryInterface
  table: useTableReturnType<NotificationSendingHistoryInterface, {}>
  type?: NotificationType
}

const CancelNotificationAction = ({
  data,
  table,
  type,
}: CancelNotificationActionProps) => {
  const statusPopup = useStatusPopup()
  const { mutateAsync: deleteNotification, isLoading: isLoadingDelete } =
    useNotificationRequestDelete(data.id)

  if (data.status.id === 'canceling' || data.status.id === 'canceled') {
    return null
  }

  const handleCancel = async () => {
    deleteNotification(undefined)
      .then(() => {
        table.setData(
          produce(table.data, draft => {
            const found = draft.find(item => item.id === data.id)

            if (found) {
              found.status = { id: 'canceling', name: 'Canceling' }
            }
          }),
        )
      })
      .catch(() => {
        pushNotification({
          value: 'Failed to cancel, try again later',
          duration: ERROR_DEFAULT_DURATION,
          type: NotificationTypes.error,
        })
      })
      .finally(() => {
        statusPopup.hide()
      })
  }

  const typeName =
    type === NotificationType.announcement ? 'announcement' : 'notification'

  return (
    <Action
      onClick={e => {
        e.stopPropagation()

        statusPopup.show(
          <StatusPopup variant="warning">
            <StatusPopup.Title>
              Are you sure you want to cancel this {typeName}?
            </StatusPopup.Title>
            <StatusPopup.Description>
              Canceling will remove the {typeName} for everybody who received it.
            </StatusPopup.Description>
            <StatusPopup.Actions>
              <Button elevated onClick={handleCancel} pending={isLoadingDelete}>
                Yes, remove {typeName}
              </Button>
              <Button variant="secondary" onClick={() => statusPopup.hide()}>
                No, keep {typeName}
              </Button>
            </StatusPopup.Actions>
          </StatusPopup>,
        )
      }}
      iconOnly
      useIcon="Cross"
      color="red"
      aria-label={`Cancel ${typeName}`}
    />
  )
}

export default CancelNotificationAction
