import React, { useEffect, useState } from 'react'
import { InputGroup } from '@revolut/ui-kit'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { PageBody } from '@src/components/Page/PageBody'
import { PageActions } from '@src/components/Page/PageActions'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import { ROUTES } from '@src/constants/routes'
import AutoStepper from '@src/components/Stepper/AutoStepper'
import NewStepperTitle from '@src/components/Stepper/NewStepperTitle'
import LapeNewTextArea from '@src/components/Inputs/LapeFields/LapeNewTextArea'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { selectorKeys } from '@src/constants/api'
import SideBar from '@components/SideBar/SideBar'
import PlaceholdersTable from '@src/pages/Forms/EmployeeEmailTemplate/PlaceholdersTable'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { EmployeeEmailTemplateInterface } from '@src/interfaces/employeeEmails'
import { selectUser } from '@src/store/auth/selectors'
import EmployeeEmailForm from '@src/features/EmployeeEmailForm/EmployeeEmailForm'
import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { pathToUrl } from '@src/utils/router'

const General = () => {
  const { values } = useLapeContext<EmployeeEmailTemplateInterface>()
  const params = useParams<{ id?: string }>()

  const user = useSelector(selectUser)

  const [isPlaceholderSidebarOpen, setIsPlaceholderSidebarOpen] = useState(false)

  useEffect(() => {
    if (!values.owner) {
      values.owner = {
        id: user.id,
        full_name: user.full_name,
        name: user.display_name,
        status: user.status.id,
        avatar: user.avatar,
        employee_type: user.employee_type,
      }
    }
  }, [])

  return (
    <>
      <PageHeader
        title={params.id ? 'Edit email template' : 'New email template'}
        backUrl={
          params.id
            ? pathToUrl(ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.PREVIEW, params)
            : ROUTES.APPS.COMMUNICATION.EMAIL_TEMPLATES
        }
      />
      <PageBody>
        <AutoStepper>
          <NewStepperTitle title="Template details" />
          <InputGroup>
            <LapeNewInput name="name" label="Template name" required />
            <LapeRadioSelectInput
              name="category"
              label="Category"
              selector={selectorKeys.employee_email_template_categories}
              filter={({ id }) => id !== null}
            />
            <LapeNewTextArea name="description" label="Description" rows={2} />
            <LapeRadioSelectInput
              name="owner"
              label="Owner"
              selector={selectorKeys.employee}
              required
            />
          </InputGroup>

          <NewStepperTitle title="Email details" />
          <EmployeeEmailForm
            onShowPlaceholders={() => setIsPlaceholderSidebarOpen(true)}
          />
        </AutoStepper>
      </PageBody>

      <SideBar
        isOpen={isPlaceholderSidebarOpen}
        onClose={() => setIsPlaceholderSidebarOpen(false)}
        title="Placeholders"
      >
        <PlaceholdersTable />
      </SideBar>

      <PageActions>
        <NewSaveButtonWithPopup
          successText="Email template saved successfully"
          previewUrl={ROUTES.FORMS.EMPLOYEE_EMAIL_TEMPLATE.PREVIEW}
          useValidator
        />
      </PageActions>
    </>
  )
}

export default General
