import React, { useState } from 'react'
import get from 'lodash/get'
import { Color, Flex, IconButton, Spinner, Token } from '@revolut/ui-kit'

export interface DeleteActionProps {
  handleDelete: (id: string | number) => Promise<any>
  data: Object
  primaryKey?: string
  color?: Color
}

const DeleteAction = ({
  handleDelete,
  data,
  primaryKey = 'id',
  color = Token.color.greyTone50,
}: DeleteActionProps) => {
  const [isPending, setIsPending] = useState<boolean>(false)

  if (isPending) {
    return (
      <Flex width="100%" justifyContent="center">
        <Spinner size={16} color={color} />
      </Flex>
    )
  }
  return (
    <Flex width="100%" justifyContent="center">
      <IconButton
        data-testid="delete-button"
        color={color}
        size={16}
        onClick={async e => {
          e.stopPropagation()
          setIsPending(true)
          try {
            await handleDelete(get(data, primaryKey))
          } finally {
            setIsPending(false)
          }
        }}
        useIcon="Delete"
      />
    </Flex>
  )
}

export default DeleteAction
