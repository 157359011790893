import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ROUTES } from '@src/constants/routes'
import { Grid } from '@components/CommonSC/Grid'
import NewSelectionCard from '@components/NewSelectionCard/NewSelectionCard'
import { PageWrapper } from '@components/Page/Page'
import { PageBody } from '@src/components/Page/PageBody'
import { PageHeader } from '@components/Page/Header/PageHeader'
import { selectPermissions } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

export const EmployeesBulkImport = () => {
  const permissions = useSelector(selectPermissions)
  const canImportEmployees = permissions.includes(PermissionTypes.AddEmployeeUpload)
  const canImportExternalEmployees = permissions.includes(
    PermissionTypes.AddEmployeeUploadingSession,
  )

  return (
    <PageWrapper>
      <PageHeader
        backUrl={ROUTES.PEOPLE.EMPLOYEES}
        subtitle="Upload a .xlsx or .csv file to import your employees"
        title="Bulk import employee records"
      />
      <PageBody>
        <Grid flow="row" gap={24}>
          <NewSelectionCard
            disabled={!canImportEmployees}
            icon="ShareIOs"
            title="Import employees"
            to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.EMPLOYEES.UPLOAD_FILE)}
            subtitle="Used to bulk import any internal employees"
            use={props => <Link {...props} replace />}
            transparent={false}
          />
          <NewSelectionCard
            disabled={!canImportExternalEmployees}
            icon="ShareIOs"
            title="Import external employees"
            to={pathToUrl(ROUTES.FORMS.IMPORT_DATA.EXTERNAL_EMPLOYEES.UPLOAD_FILE)}
            subtitle="Used to bulk import external employees"
            use={props => <Link {...props} replace />}
            transparent={false}
          />
        </Grid>
      </PageBody>
    </PageWrapper>
  )
}
