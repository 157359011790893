import React from 'react'
import { Box, TextArea, Input } from '@revolut/ui-kit'

import { OptionSection } from './OptionSection'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import {
  PerformanceScorecardOption,
  PerformanceScorecardQuestionTypeOptions,
} from '@src/interfaces/settings'

type QuestionContentProps = {
  isPreview?: boolean
  type?: PerformanceScorecardQuestionTypeOptions
  options?: PerformanceScorecardOption[]
  placeholder?: string
  onChangeQuestions?: (options: PerformanceScorecardOption[]) => void
  onChangePlaceholder?: (placeholder: string) => void
  optionsKey?: string
}

export const QuestionContent = ({
  isPreview = false,
  type,
  options,
  placeholder,
  onChangeQuestions,
  onChangePlaceholder,
  optionsKey,
}: QuestionContentProps) => {
  switch (type) {
    case 'checkbox':
      return (
        <OptionSection
          type="checkbox"
          previewMode={isPreview}
          value={options}
          onChange={onChangeQuestions}
          optionsKey={optionsKey}
        />
      )
    case 'option':
      return (
        <OptionSection
          type="radio"
          previewMode={isPreview}
          value={options}
          onChange={onChangeQuestions}
          optionsKey={optionsKey}
        />
      )
    case 'textarea':
      return isPreview ? (
        <TextArea label={placeholder} rows={3} onChange={() => {}} value="" />
      ) : (
        <Input
          value={placeholder}
          label="Placeholder (optional)"
          onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
        />
      )
    case 'text':
      return isPreview ? (
        <Input label={placeholder} onChange={() => {}} value="" />
      ) : (
        <Input
          value={placeholder}
          label="Placeholder (optional)"
          onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
        />
      )
    case 'dropdown':
      return isPreview ? (
        <RadioSelectInput
          label={placeholder}
          options={options?.map(item => ({
            label: item.text,
            value: item,
          }))}
        />
      ) : (
        <>
          <Box mb="s-16">
            <Input
              value={placeholder}
              label="Placeholder (optional)"
              onChange={e => onChangePlaceholder?.(e.currentTarget.value)}
            />
          </Box>

          <OptionSection
            type="radio"
            previewMode={isPreview}
            value={options}
            onChange={onChangeQuestions}
            optionsKey={optionsKey}
          />
        </>
      )
    default:
      return null
  }
}
