import React from 'react'
import { Box, DataPoint, DataPointSkeleton } from '@revolut/ui-kit'

import useFetchOptions from '../Inputs/hooks/useFetchOptions'
import { Currency, SelectorType } from '@src/interfaces/selectors'
import { selectorKeys } from '@src/constants/api'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import DropdownStatAction from '@components/Inputs/DropdownStatAction/DropdownStatAction'

interface CurrencySelectProps {
  onCurrencyChange: (currency: Currency) => void
  label?: string
  value?: React.ReactNode
  selector?: SelectorType
  disabled?: boolean
}

export const CurrencySelect = ({
  onCurrencyChange,
  label = 'Currency',
  value,
  selector = selectorKeys.currencies,
  disabled,
}: CurrencySelectProps) => {
  const { options, asyncState } = useFetchOptions<Currency>(selector)

  return (
    <DataPoint>
      <DataPoint.Value>
        <RadioSelectInput
          options={options}
          onChange={val => {
            if (val) {
              onCurrencyChange(val)
            }
          }}
          disabled={asyncState !== 'ready' || disabled}
          renderInput={(open, setOpen, ref) => (
            <Box ref={ref}>
              {value ? (
                <DropdownStatAction open={open} onClick={() => setOpen(!open)} ref={ref}>
                  {value}
                </DropdownStatAction>
              ) : (
                <DataPointSkeleton.Value width="s-64" />
              )}
            </Box>
          )}
        />
      </DataPoint.Value>
      <DataPoint.Label>{label}</DataPoint.Label>
    </DataPoint>
  )
}
