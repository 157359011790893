import * as React from 'react'
import { Flex, FlexProps, Token } from '@revolut/ui-kit'

interface TableWrapperProps extends FlexProps {
  useWrapper?: boolean
}

/** @deprecated Use UI Kit TableWidget */
export const TableWrapper = ({
  children,
  useWrapper = true,
  ...rest
}: TableWrapperProps) => {
  if (!useWrapper) {
    return <>{children}</>
  }

  return (
    <Flex
      p="s-16"
      bg={Token.color.widgetBackground}
      flexDirection="column"
      radius={Token.radius.widget}
      width="100%"
      {...rest}
    >
      {children}
    </Flex>
  )
}
