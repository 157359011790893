import React from 'react'
import { Avatar, Item, Token } from '@revolut/ui-kit'
import { GlobalSearch, GlobalSearchTypes } from '@src/interfaces/globalSearch'
import StarItem from './StarItem'
import { InternalLink } from '@src/components/InternalLink/InternalLink'
import { getInitials } from '@src/utils/employees'
import { getGlobalSearchItemFormUrl, typeToIconMap } from './common'
import {
  useAddFavourite,
  useDeleteFavourite,
  useGetFavourites,
} from '@src/api/favourites'
import globalSearchState from '@components/GlobalSearchSidebar/GlobalSearchSidebarState'

interface GlobalSearchItemProps {
  type: GlobalSearchTypes
  data: GlobalSearch
  onClose: () => void
  isFavouritesMode: boolean
}

const GlobalSearchItem = ({
  type,
  data,
  onClose,
  isFavouritesMode,
}: GlobalSearchItemProps) => {
  const { data: favourites, isLoading } = useGetFavourites()
  const addFavourite = useAddFavourite()
  const deleteFavourite = useDeleteFavourite()

  const favourite = favourites?.results.find(f => f.favourite_object?.id === data.id)

  const url = getGlobalSearchItemFormUrl(type, data.object_id)

  const handleFavourite = () => {
    globalSearchState.showAction = true

    if (favourite) {
      deleteFavourite.mutateAsync(favourite.id)
    } else {
      addFavourite.mutateAsync({ favourite_object: { id: data.id } })
    }
  }

  const onStarClick = () => {
    if (!isFavouritesMode) {
      handleFavourite()
    }
  }

  const handleOnClose = () => {
    if (!isFavouritesMode) {
      onClose()
    }
  }

  if (type === 'employee') {
    return (
      <Item
        use={isFavouritesMode ? 'button' : InternalLink}
        to={isFavouritesMode ? undefined : url}
        onClick={isFavouritesMode ? handleFavourite : undefined}
      >
        <Item.Avatar onClick={handleOnClose} color={Token.color.white}>
          <Avatar image={data?.thumbnail_url}>
            {!data.thumbnail_url ? getInitials(data?.object_name || '') : ''}
          </Avatar>
        </Item.Avatar>
        <Item.Content onClick={handleOnClose}>
          <Item.Title>{data?.object_name}</Item.Title>
          <Item.Description>{data?.subtitle}</Item.Description>
        </Item.Content>
        <Item.Side
          onClick={e => {
            e.preventDefault()
          }}
        >
          <StarItem
            onClick={onStarClick}
            isLoading={isLoading || addFavourite.isLoading || deleteFavourite.isLoading}
            name={data?.object_name}
            isFavourite={!!favourite}
          />
        </Item.Side>
      </Item>
    )
  }

  const icon = typeToIconMap[type]

  return (
    <Item
      use={isFavouritesMode ? 'button' : InternalLink}
      to={isFavouritesMode ? undefined : url}
      onClick={isFavouritesMode ? handleFavourite : undefined}
    >
      {icon ? (
        <Item.Avatar onClick={handleOnClose}>
          <Avatar useIcon={icon} />
        </Item.Avatar>
      ) : null}
      <Item.Content onClick={handleOnClose}>
        <Item.Title>{data?.object_name}</Item.Title>
        <Item.Description>{data?.subtitle}</Item.Description>
      </Item.Content>
      <Item.Side
        onClick={e => {
          e.preventDefault()
        }}
      >
        <StarItem
          onClick={onStarClick}
          isLoading={isLoading || addFavourite.isLoading || deleteFavourite.isLoading}
          name={data?.object_name}
          isFavourite={!!favourite}
        />
      </Item.Side>
    </Item>
  )
}

export default GlobalSearchItem
