import React from 'react'
import {
  Box,
  Cell,
  chain,
  Flex,
  HStack,
  IconButton,
  Text,
  TextSkeleton,
  Token,
} from '@revolut/ui-kit'

type Props<T> = {
  items: T[]
  selectedItem: T | undefined
  setSelectedItem: (newItem: T) => void
}
export const PreviewDocSelectorBar = <T extends { id: number; name: string }>({
  items = [],
  selectedItem,
  setSelectedItem,
}: Props<T>) => {
  const previewIdx = items.findIndex(d => d.id === selectedItem?.id)

  if (previewIdx === -1) {
    return null
  }
  return (
    <Cell>
      <Flex flex={1} justifyContent="space-between" alignItems="center">
        {items.length ? (
          <Box>
            {chain(
              <Text variant="h6">{selectedItem?.name}</Text>,
              <Text variant="h6" color={Token.color.greyTone50}>
                {previewIdx + 1}/{items.length}
              </Text>,
            )}
          </Box>
        ) : (
          <TextSkeleton width="s-64" />
        )}
        <HStack space="s-12">
          <IconButton
            size={20}
            color={Token.color.greyTone50}
            useIcon="ChevronLeft"
            aria-label="Prev document"
            onClick={() => {
              if (!items.length) {
                return
              }
              if (previewIdx > 0) {
                setSelectedItem(items[previewIdx - 1] || null)
              } else {
                setSelectedItem(items[items.length - 1] || null)
              }
            }}
          />
          <IconButton
            size={20}
            color={Token.color.greyTone50}
            useIcon="ChevronRight"
            aria-label="Next document"
            onClick={() => {
              if (!items.length) {
                return
              }
              if (previewIdx < items.length - 1) {
                setSelectedItem(items[previewIdx + 1] || null)
              } else {
                setSelectedItem(items[0] || null)
              }
            }}
          />
        </HStack>
      </Flex>
    </Cell>
  )
}
