import React, { useRef } from 'react'
import { Wealth } from '@revolut/icons'
import { Dropdown, MoreBar, Tooltip, useTooltip } from '@revolut/ui-kit'
import { useCanViewCreatePip } from '@src/pages/EmployeeProfile/Preview/components/Buttons/common'
import { isBefore, isSameDay } from 'date-fns'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmployeeButtonProps } from '@src/pages/EmployeeProfile/Preview/components/Buttons/types'

export const CreatePip = ({ data, isDropdownItem }: EmployeeButtonProps) => {
  const canView = useCanViewCreatePip({ data })

  const pipTooltip = useTooltip<HTMLButtonElement>()
  const { id, performance_cycles } = data

  const pipRef = useRef(null)

  const currentCycle = performance_cycles
    ?.filter(cycle => cycle.category === 'PIP')
    .find?.(cycle => {
      const now = new Date()
      const end = new Date(cycle.end_date_time)
      return isBefore(now, end) || isSameDay(now, end)
    })

  if (!canView) {
    return null
  }

  const handleCreateCycle = async () => {
    navigateTo(pathToUrl(ROUTES.FORMS.PIP.SETTINGS, { employeeId: id }))
  }

  if (currentCycle) {
    return isDropdownItem ? (
      <Dropdown.Item
        use="button"
        useIcon={Wealth}
        aria-disabled
        {...pipTooltip.getAnchorProps()}
        ref={pipRef}
      >
        Create PIP cycle
        <Tooltip {...pipTooltip.getTargetProps()} anchorRef={pipRef} maxWidth="250px">
          This employee is already on an active PIP cycle.
        </Tooltip>
      </Dropdown.Item>
    ) : (
      <MoreBar.Action
        useIcon={Wealth}
        aria-disabled
        {...pipTooltip.getAnchorProps()}
        ref={pipRef}
      >
        Create PIP cycle
        <Tooltip {...pipTooltip.getTargetProps()} anchorRef={pipRef} maxWidth="250px">
          This employee is already on an active PIP cycle.
        </Tooltip>
      </MoreBar.Action>
    )
  }

  return isDropdownItem ? (
    <Dropdown.Item use="button" useIcon={Wealth} onClick={handleCreateCycle}>
      Create PIP cycle
    </Dropdown.Item>
  ) : (
    <MoreBar.Action useIcon={Wealth} onClick={handleCreateCycle}>
      Create PIP cycle
    </MoreBar.Action>
  )
}
