import { API } from '@src/constants/api'
import { GetRequestInterface } from '@src/interfaces'
import { FilterByInterface, SortByInterface } from '@src/interfaces/data'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'

export const useGetRolesStats = (
  filters?: FilterByInterface[],
  sortBy?: SortByInterface[],
) => {
  const result = useFetch<GetRequestInterface<SpecialisationInterface>>(
    API.SPECIALISATIONS,
    undefined,
    {
      params: {
        ...filterSortPageIntoQuery(sortBy, filters),
        page_size: 1000,
      },
    },
  )

  const errorRows = result.data?.results.filter(role => role.owner == null)

  return {
    isLoading: result.isLoading,
    errors: errorRows?.length,
    errorRows,
    total: result.data?.count,
    refetch: result.refetch,
  }
}
