import { useQuery } from '@src/utils/queryParamsHooks'

export type Sidebar = 'departmentDetails' | 'teamDetails'

export const useDepartmentSidebar = () => {
  const { query, changeQueryParam, deleteQueryParam } =
    useQuery<{ sidebar: Sidebar; edit: string }>(true)

  return {
    sidebar: query.sidebar,
    editMode: query.edit,
    setSidebarQueryParam: (sidebar: Sidebar, isOpen: boolean, editId?: number) => {
      if (!isOpen) {
        deleteQueryParam('sidebar')
        deleteQueryParam('edit')
        return
      }
      if (editId) {
        changeQueryParam('edit', String(editId))
      } else {
        deleteQueryParam('edit')
      }
      changeQueryParam('sidebar', sidebar)
    },
  }
}
