import React from 'react'
import { Avatar, Cell, Item, VStack, Token, ActionButton, Link } from '@revolut/ui-kit'

import ActionWidget from '@components/ActionWidget/ActionWidget'
import { useGetDocusignConsent } from '@src/api/integrations'

export const DocuSignConsentAccountItem = () => {
  const { data: docusignConsentData } = useGetDocusignConsent()
  const isEnabled = docusignConsentData?.enabled
  const docusignConsentUrl = docusignConsentData?.url

  if (!isEnabled || !docusignConsentUrl) {
    return null
  }
  return (
    <Cell p={0}>
      <VStack flex="1 0">
        <ActionWidget
          title="Why do we need consent from DocuSign?"
          text="To be able to issue documents from within Revolut People, we need to get consent from DocuSign"
          avatarColor={Token.color.greyTone20}
          bg="transparent" // TODO: REVCOR-3046 revisit after ui-kit update
        />
        {/** TODO: REVCOR-3046 revisit after ui-kit update */}
        <Item style={{ background: 'transparent' }}>
          <Item.Avatar>
            <Avatar image="https://assets.revolut.com/revoluters/hub-icons/docusign-60.png">
              <Avatar.Badge
                useIcon="16/SandWatch"
                color="background"
                bg={Token.color.orange}
              />
            </Avatar>
          </Item.Avatar>
          <Item.Content>
            <Item.Title>DocuSign</Item.Title>
            <Item.Description color={Token.color.orange}>Pending action</Item.Description>
          </Item.Content>
          <Item.Side>
            <ActionButton
              useIcon="LinkExternal"
              use={Link}
              href={docusignConsentUrl}
              target="_blank"
            >
              Give consent
            </ActionButton>
          </Item.Side>
        </Item>
      </VStack>
    </Cell>
  )
}
