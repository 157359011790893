import { MeetingEvent, OneToOneMeeting } from '@src/interfaces/meetings'
import { useGetEmployeeMeetingEvents } from '@src/api/meetings'

interface MeetingEvents {
  isLoading: boolean
  events: MeetingEvent[]
}

export const useMeetingEvents = (meeting: OneToOneMeeting): MeetingEvents => {
  const { manager, employee } = meeting

  const { data, isLoading } = useGetEmployeeMeetingEvents({
    employee_id: manager.id,
    attendees: [employee.id, manager.id].join(','),
    is_assigned: false,
    is_series: true,
  })

  return {
    isLoading,
    events: data?.results ?? [],
  }
}
