import { lape } from 'lape'

import { NotificationsState } from '@src/store/notifications/types'

const initialNotificationsState: NotificationsState = {
  notifications: [],
  // hack around the fact that logout is redux action
  shouldLogOut: false,
}

export default lape(initialNotificationsState)
