import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'lape'
import { contractingCountriesRequests, entitiesRequestsNew } from '@src/api/entities'
import { ContractingCountryInterface } from '@src/interfaces/enitities'
import { PaygroupInterface } from '@src/interfaces/payroll'
import SideBar from '@components/SideBar/SideBar'
import Form from '@src/features/Form/Form'
import { Entities, RegionSideHelpForm } from '@src/pages/Forms/EntityForm/General'

interface EntitySidebarFormProps {
  enityId?: number
  isOpen: boolean
  onAfterSave: () => void
  onClose: () => void
}

export const EntitySidebarForm = connect(
  ({ enityId, isOpen, onAfterSave, onClose }: EntitySidebarFormProps) => {
    const [entityId, setEntityId] = useState<number | undefined>()
    const [regionFormVisible, setRegionFormVisible] = useState(false)
    const [selectedPaygroup, setSelectedPaygroup] = useState<PaygroupInterface>()
    const [selectedRegion, setSelectedRegion] = useState<ContractingCountryInterface>()
    const [sideHelpRenderKey, setSideHelpRenderKey] = useState(0)

    const refreshPaygroups = useRef(() => {})
    const refreshRegions = useRef(() => {})

    useEffect(() => {
      setSideHelpRenderKey(prev => prev + 1)
    }, [selectedPaygroup, selectedRegion])

    const onCloseRegion = () => {
      setSelectedRegion(undefined)
      setRegionFormVisible(false)
    }

    return (
      <>
        <Form
          api={entitiesRequestsNew}
          disableLocalStorageCaching
          forceParams={{ ...(enityId ? { id: String(enityId) } : { new: 'new' }) }}
        >
          <Entities
            isOpen={isOpen}
            onAfterSave={onAfterSave}
            onClose={onClose}
            setSelectedPaygroup={setSelectedPaygroup}
            setRegionFormVisible={setRegionFormVisible}
            setSelectedRegion={setSelectedRegion}
            setEntityId={setEntityId}
            refreshPaygroups={refreshPaygroups}
            refreshRegions={refreshRegions}
            useSidebar
          />
        </Form>
        <SideBar
          isOpen={regionFormVisible}
          onClose={onCloseRegion}
          title={`${selectedRegion ? 'Edit' : 'Add'} region`}
        >
          <Form
            api={contractingCountriesRequests}
            key={sideHelpRenderKey}
            forceParams={{
              ...(selectedRegion?.id ? { id: `${selectedRegion.id}` } : { new: 'new' }),
            }}
          >
            <RegionSideHelpForm
              entityId={entityId}
              onRegionRefresh={refreshRegions.current}
              onCloseRegion={onCloseRegion}
            />
          </Form>
        </SideBar>
      </>
    )
  },
)
