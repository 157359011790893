import React from 'react'
import { Box, Group, Input, InputGroup, Subheader } from '@revolut/ui-kit'
import { useParams } from 'react-router-dom'
import { connect } from 'lape'

import { PageHeader } from '@src/components/Page/Header/PageHeader'
import { PageWrapper } from '@src/components/Page/Page'
import { PageActions } from '@src/components/Page/PageActions'
import { PageBody } from '@src/components/Page/PageBody'
import { EmployeeTimeOffPolicyAssignmentInterface } from '@src/interfaces/timeOff'
import { useLapeContext } from '@src/features/Form/LapeForm'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import Form from '@src/features/Form/Form'
import {
  employeePolicyAssignmentRequest,
  unassignEmployeePolicyAssignment,
} from '@src/api/timeOff'
import { FormPreview } from '@src/components/FormPreview/FormPreview'
import { PolicyCategoryItem, PolicyNameItem, StartDateItem } from './common'
import LapeDatePickerInput from '@src/components/Inputs/LapeFields/LapeDatePickerInput'
import LapeNewInput from '@src/components/Inputs/LapeFields/LapeNewInput'
import ActionWidget from '@src/components/ActionWidget/ActionWidget'

export const UnassignEmployeeTimeOffPolicyAssignment = () => {
  const { values } = useLapeContext<EmployeeTimeOffPolicyAssignmentInterface>()
  const params = useParams<{ id: string; employeeId: string }>()

  const backUrl = pathToUrl(
    ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.GENERAL,
    params,
  )

  const onUnassign = async () => {
    return unassignEmployeePolicyAssignment(
      values.id,
      values.employee.id,
      values.end_date || undefined,
      values.last_accrual_amount || undefined,
    ).then(resp => resp.data)
  }

  return (
    <PageWrapper>
      <PageHeader
        title="Unassign policy"
        backUrl={backUrl}
        subtitle={
          values?.employee ? (
            <UserWithAvatar {...values.employee} asText mb="s-16" />
          ) : null
        }
      />

      <PageBody>
        <FormPreview data={values}>
          <Group>
            <PolicyNameItem />
            <PolicyCategoryItem />
            <StartDateItem />
          </Group>
        </FormPreview>

        <Box mt="s-16">
          <Subheader variant="nested">
            <Subheader.Title>Please fill in the below details</Subheader.Title>
          </Subheader>
        </Box>

        <InputGroup>
          <LapeDatePickerInput name="end_date" label="End date" required />
          {values.end_date ? (
            <>
              <InputGroup variant="horizontal">
                <Box flex={3}>
                  <LapeNewInput
                    name="last_accrual_amount"
                    label="Last accrual amount"
                    required
                  />
                </Box>
                <Box flex={1}>
                  <Input label="Unit" value={values.policy.unit?.name} disabled />
                </Box>
              </InputGroup>

              <ActionWidget
                title="Last accrual for this time-off policy needs to be manually calculated"
                text="Based on policy type and its end date."
              />
            </>
          ) : null}
        </InputGroup>
      </PageBody>

      <PageActions>
        <NewSaveButtonWithPopup
          onClick={onUnassign}
          successText="Policy unassigned"
          previewUrl={ROUTES.FORMS.EMPLOYEE_TIME_OFF_POLICY_ASSIGNMENT.PREVIEW}
          useValidator
        >
          Submit
        </NewSaveButtonWithPopup>
      </PageActions>
    </PageWrapper>
  )
}

export default connect(() => (
  <Form api={employeePolicyAssignmentRequest}>
    <UnassignEmployeeTimeOffPolicyAssignment />
  </Form>
))
