import React, { useState } from 'react'
import styled from 'styled-components'
import { Box, Text, chain, Flex, Token } from '@revolut/ui-kit'

const MAX_VISIBLE_ITEMS = 4

interface ItemInterface {
  text: string
  link?: {
    title: string
    url: string
  }
  index?: number
}

interface ListInterface {
  title: string
  additionalInfo?: string
  items: ItemInterface[]
  icon?: React.ReactNode
}

interface ExpandableTextProps {
  list: ListInterface[]
  breakText?: boolean
}

const ListItem = styled.div`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`

const Item = styled.div<{ oneLine?: boolean }>`
  padding-bottom: 4px;
  display: ${props => (props.oneLine ? 'flex' : 'block')};
`

const A = styled.a`
  color: ${Token.color.blue};
  text-decoration: none;
  flex-shrink: 0;
  margin-right: 6px;
`
const DottedText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PreWrap = styled.span`
  white-space: pre-wrap;
`

const ShowMore = styled.button`
  color: ${Token.color.blue};
  text-decoration: none;
  flex-shrink: 0;
  margin-left: 6px;
`

const ExpandableText = ({ list, breakText }: ExpandableTextProps) => {
  const [open, setOpen] = useState(false)

  const count = list.reduce((acc, listItem) => acc + listItem.items.length, 0)
  const visibleItems = list.flatMap(({ items }) => items).slice(0, MAX_VISIBLE_ITEMS)
  const visibleList = list
    .map(listItem => ({
      ...listItem,
      items: listItem.items.filter(item => visibleItems.includes(item)),
    }))
    .filter(listItem => listItem.items.length)
  const lastItem = visibleItems[visibleItems.length - 1]
  const showMore = !open && count > MAX_VISIBLE_ITEMS

  const normalizedList = open ? list : visibleList

  if (!normalizedList.length) {
    return null
  }

  return (
    <Box backgroundColor={Token.color.greyTone8} p="s-16" borderRadius={12}>
      {normalizedList.map((listItem, listItemIndex) => (
        <ListItem key={listItemIndex}>
          <Flex justifyContent="space-between" alignItems="center">
            {listItem.title && (
              <Text
                use="div"
                fontWeight={500}
                fontSize="primary"
                lineHeight="22px"
                pb="10px"
              >
                {chain(
                  listItem.title,
                  listItem.additionalInfo ? (
                    <Text color={Token.color.greyTone50}>{listItem.additionalInfo}</Text>
                  ) : undefined,
                )}
              </Text>
            )}
            {!!listItem.icon && listItem.icon}
          </Flex>
          <div>
            {listItem.items.map((item, itemIndex) => (
              <Item key={itemIndex} oneLine={showMore && item === lastItem}>
                {item.link && (
                  <A href={item.link.url} target="_blank" rel="noopener noreferrer">
                    {item.link.title}:
                  </A>
                )}
                {showMore && item === lastItem ? (
                  <>
                    <DottedText>{item.text}</DottedText>
                    <ShowMore
                      type="button"
                      onClick={() => {
                        setOpen(true)
                      }}
                    >
                      See more
                    </ShowMore>
                  </>
                ) : (
                  <>
                    {breakText && !!item.link && <br />}
                    <PreWrap>{item.text}</PreWrap>
                  </>
                )}
              </Item>
            ))}
          </div>
        </ListItem>
      ))}
    </Box>
  )
}

export default ExpandableText
