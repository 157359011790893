import React, { useMemo } from 'react'
import { Group } from '@revolut/ui-kit'
import { connect } from 'lape'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { navigateTo } from '@src/actions/RouterActions'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import { FormPreview } from '@components/FormPreview/FormPreview'
import { getCandidate } from '@src/api/recruitment/interviews'
import { useParams } from 'react-router-dom'
import {
  getPrefilledPlaceholdersEmail,
  useGetCandidateEmailThread,
} from '@src/api/hiringProcess'
import { CandidateInterface } from '@src/interfaces/interviewTool'
import EmailThread from '@src/features/EmailThread/EmailThread'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import NewStepperTitle, { NewStepperTitleType } from '@components/Stepper/NewStepperTitle'

const Candidate = () => {
  const { candidateId, emailId, threadId } =
    useParams<{ candidateId: string; emailId?: string; threadId?: string }>()

  const { data: thread } = useGetCandidateEmailThread(candidateId, threadId)

  const onSuccess = (email: SendCandidateEmailInterface) => {
    if (threadId) {
      navigateTo(
        pathToUrl(ROUTES.FORMS.SEND_EMAIL.VIEW, {
          candidateId,
          threadId,
          emailId: email.id,
        }),
      )
    } else {
      navigateTo(pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, { id: candidateId }))
    }
  }

  const replyEmail = useMemo(
    () =>
      emailId ? thread?.candidate_emails?.find(item => item.id === +emailId) : undefined,
    [thread, emailId],
  )

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, +candidateId)
  }

  return (
    <SendEmailCommon
      onSuccess={onSuccess}
      fetchEmail={fetchEmail}
      replyEmail={replyEmail}
      bottom={
        thread ? (
          <>
            <NewStepperTitle
              title="You are replying to the below email"
              type={NewStepperTitleType.compact}
            />
            <EmailThread emails={thread.candidate_emails} emailId={emailId} />
          </>
        ) : null
      }
    >
      <FormPreview<CandidateInterface> api={() => getCandidate(candidateId)}>
        <Group>
          <FormPreview.Item title="Candidate" field="full_name" />
          <FormPreview.Item<CandidateInterface>
            title="Interviewing for"
            field="active_interview_round.specialisation.name"
            to={data =>
              pathToUrl(ROUTES.FORMS.SPECIALISATIONS.PREVIEW, {
                id: data.active_interview_round?.specialisation?.id,
              })
            }
          />
          <FormPreview.Item
            title="Current stage"
            field="active_interview_round.latest_interview_stage.interview_type_display"
          />
        </Group>
      </FormPreview>
    </SendEmailCommon>
  )
}

export default connect(Candidate)
