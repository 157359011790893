import * as React from 'react'
import { useEffect } from 'react'
import { connect } from 'lape'

import { useLapeField } from '@src/features/Form/LapeForm'
import NewRadioButtons, {
  NewRadioButtonsProps,
  RadioOption,
} from '@components/Inputs/NewRadioButtons/NewRadioButtons'
import { getCommonInputProps } from '@components/Inputs/LapeFields/helpers'
import { useFormValidator } from '@src/features/Form/FormValidator'

interface Props extends NewRadioButtonsProps {
  name: string
  useMappingAsValue?: boolean
  onAfterChange?: (option: RadioOption) => void
}

const LapeNewRadioButtons = ({
  name,
  useMappingAsValue,
  onAfterChange,
  ...props
}: Props) => {
  const lapeProps = useLapeField(name)
  const { value, onChange, hidden } = lapeProps
  const formValidator = useFormValidator()

  const onChangeHandler: NewRadioButtonsProps['onChange'] = option => {
    if (!option) {
      onChange(option)
      onAfterChange?.(option)
      return
    }

    const newValue = useMappingAsValue
      ? { id: option.value, name: option.label }
      : option.value

    onChange(newValue)
    onAfterChange?.(option)
  }

  useEffect(() => {
    if (value === undefined && props.defaultOptionIndex !== undefined) {
      const index = props.defaultOptionIndex || 0
      onChangeHandler(props.options[index], props.defaultOptionIndex)
    }
  }, [value])

  if (hidden) {
    return null
  }

  return (
    <NewRadioButtons
      value={useMappingAsValue ? value?.id : value}
      onChange={onChangeHandler}
      {...getCommonInputProps({ ...props, name }, lapeProps, !!formValidator?.validated)}
      {...props}
      data-testid={`RadioButtons-${name}`}
    />
  )
}

export default connect(LapeNewRadioButtons)
