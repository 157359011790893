import { MoreBar, StatusPopup } from '@revolut/ui-kit'
import { navigateTo } from '@src/actions/RouterActions'
import React, { useState } from 'react'
import { Cross } from '@revolut/icons'
import { cancelCandidateEmail } from '@src/api/hiringProcess'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { EmailThreadInterface } from '@src/interfaces/hiringProccess'
import ConfirmationDialog from '@src/features/Popups/ConfirmationDialog'

interface CancelEmailProps {
  candidateId: number
  thread: EmailThreadInterface
}

const CancelEmail = ({ thread, candidateId }: CancelEmailProps) => {
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)

  const handleCancel = async () => {
    if (!candidateId || !thread?.candidate_emails[0].id) {
      return
    }
    try {
      setCancelLoading(true)
      const result = await cancelCandidateEmail(
        candidateId,
        thread?.candidate_emails[0].id,
      )
      if (result?.data) {
        setConfirmationOpen(false)
        setStatusOpen(true)
      }
    } finally {
      setCancelLoading(false)
    }
  }

  return (
    <>
      <MoreBar.Action
        onClick={() => setConfirmationOpen(true)}
        useIcon={Cross}
        variant="negative"
      >
        Cancel & delete email
      </MoreBar.Action>
      <ConfirmationDialog
        open={confirmationOpen}
        onClose={() => setConfirmationOpen(false)}
        label="Cancel sending this email & delete it?"
        body={
          <p>
            Canceling this scheduled email will also delete it. Please confirm you want to
            cancel & delete it
          </p>
        }
        loading={cancelLoading}
        onConfirm={handleCancel}
        onReject={() => setConfirmationOpen(false)}
        yesMessage="Cancel email"
        noMessage="Go back"
      />
      <StatusPopup
        variant="success"
        open={statusOpen}
        onClose={() => {
          setStatusOpen(false)
          navigateTo(
            pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, {
              id: candidateId,
            }),
          )
        }}
      >
        <StatusPopup.Title>Email cancelled & deleted</StatusPopup.Title>
      </StatusPopup>
    </>
  )
}

export default CancelEmail
