import React from 'react'
import Form from '@src/features/Form/Form'
import { assignUserGroupRequest } from '@src/api/accessManagement'
import AssignPermissionGroupToUser from '@src/pages/Forms/AssignPermissionGroupToUser/AssignPermissionGroupToUser'

const Index = () => {
  return (
    <Form api={assignUserGroupRequest}>
      <AssignPermissionGroupToUser />
    </Form>
  )
}

export default Index
