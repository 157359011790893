import { useLapeContext } from '@src/features/Form/LapeForm'
import { PaygroupInterface, PayrollElementInterface } from '@src/interfaces/payroll'
import React, { useEffect, useState } from 'react'
import { IdAndName } from '@src/interfaces'
import { Button, Input, Side, VStack } from '@revolut/ui-kit'
import RadioSelectInput from '@components/Inputs/RadioSelectInput/RadioSelectInput'
import { PaymentCategoriesOptions } from '@src/apps/People/Payroll/Paygroup/constants'
import SideBar from '@components/SideBar/SideBar'
import { YesOrNoRadioSelect } from '@components/Inputs/YesNoRadioSelect/YesNoRadioSelect'
import { TEMP_PREFIX } from '../helpers'

const EMPTY_VALUE = {
  id: 0,
  name: '',
  category: undefined,
  is_mandatory: false,
  is_payable: false,
  settlement_profile: null,
  gl_internal: '',
}

type Props = {
  isOpen: boolean
  onClose: () => void
  editPayElementId?: number
}
export const AddPayElementSidebar = ({ isOpen, onClose, editPayElementId }: Props) => {
  const { values } = useLapeContext<PaygroupInterface>()

  const [newPayment, setNewPayment] = useState<PayrollElementInterface>(EMPTY_VALUE)

  useEffect(() => {
    if (editPayElementId) {
      const editablePayElement = values.payroll_elements.find(
        elt => elt.id === editPayElementId,
      )
      setNewPayment(editablePayElement || EMPTY_VALUE)
    } else {
      setNewPayment(EMPTY_VALUE)
    }
  }, [editPayElementId])

  const updatePayment = (
    field: keyof PayrollElementInterface,
    value: string | boolean | IdAndName<string> | null,
  ) => {
    setNewPayment(prevState => ({ ...prevState, [field]: value }))
  }

  const isSubmitDisabled =
    !newPayment.name ||
    !newPayment.category ||
    !newPayment.gl_internal ||
    (newPayment.is_payable && !newPayment.settlement_profile)

  return (
    <SideBar
      title={newPayment.id ? 'Edit pay element' : 'Add pay element'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <VStack gap="s-12" data-testid="add-payment-sidebar">
        <Input
          label="Name"
          value={newPayment.name}
          onChange={e => updatePayment('name', e.currentTarget.value)}
        />
        <RadioSelectInput<IdAndName<string>>
          label="Category"
          options={PaymentCategoriesOptions}
          value={newPayment.category}
          onChange={(value: IdAndName<string> | null) => updatePayment('category', value)}
        />
        <Input
          label="GL internal"
          value={newPayment.gl_internal}
          onChange={e => updatePayment('gl_internal', e.currentTarget.value)}
        />
        <YesOrNoRadioSelect
          label="Is mandatory"
          value={newPayment.is_mandatory}
          onChange={value => {
            updatePayment('is_mandatory', value)
          }}
        />
        <YesOrNoRadioSelect
          label="Is payable"
          value={newPayment.is_payable}
          onChange={value => {
            updatePayment('is_payable', value)

            if (!value) {
              updatePayment('settlement_profile', null)
            }
          }}
        />
        {newPayment.is_payable && (
          <Input
            label="Settlement profile"
            value={newPayment.settlement_profile || ''}
            onChange={e => {
              if (e.currentTarget.value) {
                updatePayment('settlement_profile', e.currentTarget.value)
              }
            }}
          />
        )}

        <Side.Actions>
          <Button
            mt="s-12"
            onClick={() => {
              values.payroll_elements = newPayment.id
                ? values.payroll_elements.map(elt => {
                    if (elt.id === newPayment.id) {
                      return newPayment
                    }
                    return elt
                  })
                : [
                    ...(values.payroll_elements || []),
                    {
                      ...newPayment,
                      id: `${TEMP_PREFIX} ${Math.floor(Math.random() * 1000000)}`,
                    },
                  ]

              onClose()
              setNewPayment(EMPTY_VALUE)
            }}
            disabled={isSubmitDisabled}
          >
            {newPayment.id ? 'Update' : 'Add'}
          </Button>
        </Side.Actions>
      </VStack>
    </SideBar>
  )
}
