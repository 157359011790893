import React from 'react'
import { ReviewSummaryInterface, SkillCardInterface } from '@src/interfaces/performance'
import { connect } from 'lape'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SelectedFieldInterface } from '@src/pages/Forms/EmployeePerformanceLayout/utils'
import { getNormalizedCards } from '@src/utils/performance'
import { CultureValuesCommon } from '@src/pages/Forms/EmployeePerformanceLayout/Sections/common/CultureValuesCommon'

export interface ValuesProps {
  selectedField: SelectedFieldInterface
}

export const CultureValuesView = connect(({ selectedField }: ValuesProps) => {
  const { values } = useLapeContext<ReviewSummaryInterface>()

  if (selectedField.cardIndex === undefined) {
    return null
  }

  const hasCultureValues = !!values.summary?.culture_values

  const cultureValuesCards = values.summary?.culture_values?.cards || []

  const cultureSkillsCards = getNormalizedCards(
    (values.summary?.culture_skills?.cards as SkillCardInterface[]) || [],
  )

  const valuesCards = hasCultureValues ? cultureValuesCards : cultureSkillsCards
  const card = valuesCards[selectedField.cardIndex]

  if (!card) {
    return null
  }

  return (
    <CultureValuesCommon
      valuesCards={valuesCards as SkillCardInterface[]}
      selectedField={selectedField}
      hasCultureValues={hasCultureValues}
      isViewMode
    />
  )
})
