import React, { forwardRef } from 'react'
import { ChevronDown, ChevronUp } from '@revolut/icons'
import { HStack, TextButton } from '@revolut/ui-kit'

type Props = {
  open: boolean
  disabled?: boolean
  onClick: () => void
  children: React.ReactNode
}

const DropdownAction = forwardRef<HTMLButtonElement, Props>(
  ({ children, open, onClick, disabled, ...props }, ref) => (
    <TextButton
      ref={ref}
      onClick={onClick}
      style={{ flexShrink: 0, ...(disabled && { opacity: 0.5 }) }}
      aria-disabled={disabled}
    >
      <HStack align="center">
        <span {...props}>{children}</span>
        {open ? <ChevronUp size={22} /> : <ChevronDown size={22} />}
      </HStack>
    </TextButton>
  ),
)

export default DropdownAction
