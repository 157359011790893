import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { connect } from 'lape'
import cloneDeep from 'lodash/cloneDeep'
import {
  Avatar,
  Token,
  InputGroup,
  Details,
  Text,
  HStack,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { navigateReplace } from '@src/actions/RouterActions'
import { selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { QueryInterface } from '@src/interfaces/dataAnalytics'
import { useLapeContext } from '@src/features/Form/LapeForm'
import LapeNewInput from '@components/Inputs/LapeFields/LapeNewInput'
import LapeNewTextArea from '@components/Inputs/LapeFields/LapeNewTextArea'
import LapeRadioSelectInput from '@components/Inputs/LapeFields/LapeRadioSelectInput'
import { PageBody } from '@src/components/Page/PageBody'
import NewSaveButtonWithPopup from '@src/features/Form/Buttons/NewSaveButtonWithPopup'
import { selectFeatureFlags } from '@src/store/auth/selectors'
import { FeatureFlags, PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'

export const MetaData = connect(() => {
  const { initialValues, values } = useLapeContext<QueryInterface>()

  const permissions = values.field_options?.permissions || []
  const featureFlags = useSelector(selectFeatureFlags)
  const canChangeSavedQuery =
    permissions.includes(PermissionTypes.CanChangeSavedQuery) &&
    featureFlags?.includes(FeatureFlags.ReportingQueriesManagement)

  useEffect(() => {
    if (!values.sql && initialValues.sql) {
      values.sql = initialValues.sql
    }

    if (!values.connection && initialValues.connection) {
      values.connection = cloneDeep(initialValues.connection)
    }

    if (!values.parameters && initialValues.parameters) {
      values.parameters = cloneDeep(initialValues.parameters)
    }
  }, [])

  return (
    <PageBody>
      <Widget px="s-16" pb="s-16">
        <Details>
          <Details.Title>
            <HStack gap="s-8" align="center">
              <Avatar useIcon="Document" />
              <VStack>
                <Text variant="primary" color={Token.color.foreground}>
                  General information
                </Text>
                <Text variant="caption">Basic query characteristics</Text>
              </VStack>
            </HStack>
          </Details.Title>
          <Details.Content>
            {canChangeSavedQuery && (
              <NewSaveButtonWithPopup
                hideWhenNoChanges={false}
                onAfterSubmit={res => {
                  navigateReplace(pathToUrl(ROUTES.FORMS.QUERY.META_DATA, { id: res.id }))
                }}
                successText={`Query ${values.name} successfully saved`}
                variant="secondary"
                useIcon="Plus"
                useValidator
                small
              >
                Save
              </NewSaveButtonWithPopup>
            )}
          </Details.Content>
        </Details>
        <InputGroup>
          <LapeNewInput
            disabled={!canChangeSavedQuery}
            label="Query name"
            name="name"
            required
          />
          <LapeNewTextArea
            disabled={!canChangeSavedQuery}
            label="Description"
            name="description"
            rows={3}
          />
          <LapeRadioSelectInput
            disabled={!canChangeSavedQuery}
            label="Owner"
            name="owner"
            required
            selector={selectorKeys.employee}
          />
        </InputGroup>
      </Widget>
    </PageBody>
  )
})
