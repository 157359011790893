import React from 'react'
import ButtonFilter from '@components/ButtonFilters/ButtonFilter'
import { FilterSelectType } from '@components/Inputs/Filters/FilterSelect/NewFilterSelect'
import { meetingRecurrence } from '@src/interfaces/meetingsTracker'
import { OptionInterface } from '@src/interfaces/selectors'

export interface RecurrenceOption extends OptionInterface<meetingRecurrence> {}

const makeRecurrenceOption = (value: meetingRecurrence): RecurrenceOption => ({
  id: value,
  name: String(value),
})

interface Props {
  value: meetingRecurrence
  onChange: (value: RecurrenceOption) => void
}

const recurrenceSelector = async (): Promise<{ options: RecurrenceOption[] }> => ({
  options: [
    makeRecurrenceOption(meetingRecurrence.Weekly),
    makeRecurrenceOption(meetingRecurrence.BiWeekly),
    makeRecurrenceOption(meetingRecurrence.Monthly),
  ],
})

export const RecurrenceSelector = ({ value, onChange }: Props) => {
  const recurrenceOption = makeRecurrenceOption(value)
  const title = `Set ${recurrenceOption.name.toLowerCase()} meeting`

  return (
    <ButtonFilter<RecurrenceOption>
      value={[recurrenceOption]}
      type={FilterSelectType.SingleSelect}
      selector={recurrenceSelector}
      title={title}
      useIcon="Retry"
      onChange={([singleSelectedValue]) => onChange(singleSelectedValue)}
    />
  )
}
