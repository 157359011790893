import React from 'react'
import { Action, Avatar, Box, Color, Icon, IconName, Item } from '@revolut/ui-kit'

export interface BaseStatsItemProps {
  title: string
  iconName: IconName
  description?: string
  statusIconName?: IconName
  color?: Color
}

export const BaseStatsItem = ({
  title,
  description,
  iconName,
  statusIconName,
  color,
}: BaseStatsItemProps) => (
  <Item useIcon={iconName}>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      {description && <Item.Description color={color}>{description}</Item.Description>}
    </Item.Content>
    {statusIconName && (
      <Item.Side>
        <Item.Value>
          <Icon name={statusIconName} color={color} />
        </Item.Value>
      </Item.Side>
    )}
  </Item>
)

export interface GoalsStatsItemProps extends BaseStatsItemProps {
  actionIconName?: IconName
  actionLabel?: string
  onActionClick?: VoidFunction
}

export const GoalsStatsItem = ({
  title,
  description,
  iconName,
  actionIconName,
  statusIconName,
  actionLabel,
  color,
  onActionClick,
}: GoalsStatsItemProps) => (
  <Item>
    <Item.Avatar>
      <Avatar useIcon={iconName} />
    </Item.Avatar>
    <Item.Content>
      <Item.Title>{title}</Item.Title>
      {description && (
        <Item.Description color={color}>
          {description}
          {onActionClick && (
            <Box mt="s-8">
              <Action useIcon={actionIconName} onClick={onActionClick}>
                {actionLabel}
              </Action>
            </Box>
          )}
        </Item.Description>
      )}
    </Item.Content>
    <Item.Side>
      <Item.Value>
        <Icon name={statusIconName} color={color} />
      </Item.Value>
    </Item.Side>
  </Item>
)
