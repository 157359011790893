import { useEffect } from 'react'
import { SpecialisationInterface } from '@src/interfaces/roles'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'
import { rolesRequests } from '@src/api/roles'
import { useLapeContext } from '@src/features/Form/LapeForm'

export const useMountSpecialisationForm = () => {
  const { values } = useLapeContext<SpecialisationInterface>()
  const user = useSelector(selectUser)

  useEffect(() => {
    if (!values.owner && user) {
      values.owner = { id: user.id, full_name: user.full_name }
    }

    if (!values.id && values.role?.id) {
      prePopulate()
    }
  }, [])

  useEffect(() => {
    if (!values.id && values.performance_weights?.length) {
      // clean up performance weights if saved in browser
      values.performance_weights = []
    }
  }, [])

  const prePopulate = async () => {
    const role = await rolesRequests.getItem(values.role?.id)

    if (role.data) {
      values.goals = role.data.role_goals || []
      values.mission = role.data.role_description
      values.candidate_expectations = {
        must_have: role.data?.candidate_expectations?.must_have || [],
        good_to_have: role.data?.candidate_expectations?.good_to_have || [],
        red_flags: role.data?.candidate_expectations?.red_flags || [],
        example_profiles: role.data?.candidate_expectations?.example_profiles || [],
        relevant_sectors: role.data?.candidate_expectations?.relevant_sectors || [],
      }
    }
  }
}
