import { api, apiWithoutHandling } from '@src/api'
import { API } from '@src/constants/api'
import {
  GetRequestInterface,
  RequestInterfaceNew,
  TableRequestInterface,
} from '@src/interfaces'
import { CultureValueInterface } from '@src/interfaces/cultureValues'
import { FilterByInterface } from '@src/interfaces/data'
import { useFetch } from '@src/utils/reactQuery'
import { filterSortPageIntoQuery } from '@src/utils/table'
import { useUpdate } from '@src/utils/reactQuery'

export const cultureValuesRequests: TableRequestInterface<CultureValueInterface> = {
  getItems: async ({ sortBy, filters, page }) =>
    api.get(`${API.VALUES}${API.COMPANY}`, {
      params: filterSortPageIntoQuery(sortBy, filters, page),
    }),
}

export const useGetCompanyValues = ({ filters }: { filters?: FilterByInterface[] }) =>
  useFetch<GetRequestInterface<CultureValueInterface>>(`${API.VALUES}/company`, 'v1', {
    params: filterSortPageIntoQuery(undefined, filters),
  })

export const cultureValuesRequestsNew: RequestInterfaceNew<CultureValueInterface> = {
  get: async ({ id }) => api.get(`${API.VALUES}${API.COMPANY}/${id}`),
  update: async (_, { id }, data) =>
    apiWithoutHandling.put(`${API.VALUES}${API.COMPANY}/${id}`, data),
  submit: async data => apiWithoutHandling.post(`${API.VALUES}${API.COMPANY}`, data),
}

export const deleteCultureValues = (id: number) =>
  apiWithoutHandling.delete(`${API.VALUES}${API.COMPANY}/${id}`)

export const deleteValue = (id: number) => api.delete(`${API.VALUES}${API.COMPANY}/${id}`)

export const useUpdateValue = () =>
  useUpdate<CultureValueInterface, CultureValueInterface>(
    `${API.VALUES}${API.COMPANY}`,
    undefined,
    undefined,
    false,
    (_oldData, newData) => newData,
    true,
  )
