import { FieldOptions, IdAndName } from '@src/interfaces'
import { EmployeeOptionInterface } from './employees'

export enum ScreeningCheckStatuses {
  sentToApplicant = 'Sent to Applicant',
  pending = 'pending',
  pendingApplicant = 'Pending Applicant',
  pendingRequestor = 'Pending Requestor',
  inProgress = 'InProgress',
  progress = 'progress',
  requiresAdjudication = 'Requires Adjudication',
  needsAdjudication = 'needs_adjudication',
  Completed = 'Completed', // Hireright status comes upper case
  completed = 'completed', // our internal status comes lower case
  inviteExpired = 'Invite Expired',
  cancelled = 'Cancelled',
  expired = 'expired',
  failed = 'failed',
}

export enum ScreeningCheckStatusCategories {
  pendingAdjudication = 'pending_adjudication',
  inProgress = 'in_progress',
  pendingCompletion = 'pending_completion',
  completed = 'completed',
  canceled = 'canceled',
  expired = 'expired',
  failed = 'failed',
  other = 'other',
}

export enum HirerightAdjudicationStatus {
  meetsCompanyStandards = 'Meets Company Standards',
  doesntMeetCompanyStandards = 'Does Not Meet Company Standards',
  clientReviewRequired = 'Client Review Required',
  pendingPotentialConflict = 'Pending - Potential Conflict',
  canceled = 'Canceled',
  customStatus = 'Custom Status',
}

export interface ManualScreeningChecksInterface {
  id: number
  category: IdAndName<string>
  check_type: IdAndName
  completion_date_time: string | null
  created_on_date_time: string | null
  update_date_time: string | null
  status: IdAndName<ScreeningCheckStatuses>
  employee: EmployeeOptionInterface
  triggered_by: EmployeeOptionInterface
  field_options: FieldOptions
  link_to_proof: string | null
  uploaded_documents?: IdAndName[]
  type: IdAndName<'manual' | 'hireright'>
  provider?: string
  adjudication_needed?: boolean
  adjudication_status?: string
}

export interface AllScreeningChecksInterface {
  id: number
  screening_name: string
  screening_id: string
  type: IdAndName<'manual' | 'hireright'>
  status: IdAndName<ScreeningCheckStatuses> | null
  status_category: IdAndName<ScreeningCheckStatusCategories> | null
  update_date_time: string | null
  completion_date_time: string | null
  employee: EmployeeOptionInterface
  approver: EmployeeOptionInterface
  triggered_by: EmployeeOptionInterface
  field_options: FieldOptions
  package_name: string
}

export interface AllScreeningChecksStatsInterface {
  total: number
  pending_adjudication: number
  in_progress: number
  pending_completion: number
  completed: number
  canceled: number
  expired: number
  failed: number
  other: number
}

export interface ManualScreeningStatusTimelineStep {
  event: IdAndName<ScreeningCheckStatuses>
  date: string | null
  employee: EmployeeOptionInterface | null
}

interface HirerightProductInterface {
  id: number
  name: string
  status?: IdAndName
  adjudication_status?: IdAndName<HirerightAdjudicationStatus>
  update_date_time?: string
  count: number
}

export interface HirerightPackageInterface {
  id: string
  package_name: string
  products: HirerightProductInterface[]
  price?: string
}

export interface HirerightScreeningChecksInterface {
  id: number
  package_name: string
  employee: EmployeeOptionInterface
  status: IdAndName<ScreeningCheckStatuses>
  adjudication_status?: IdAndName<HirerightAdjudicationStatus>
  selected_package_id: string
  source: IdAndName<'hireright'>
  triggered_by: EmployeeOptionInterface
  completion_date_time: string | null
  products: HirerightProductInterface[]
  field_options: FieldOptions
}

export interface HirerightScreeningStatusTimelineStep {
  date: string | null
  status: IdAndName<ScreeningCheckStatuses>
}

export interface HirerightExternalLinkInterface {
  id: string
  external_url: string
}
