import React from 'react'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getLocationPathnameWithoutWorkspace,
  navigateTo,
} from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { KPIFormLocationState, KPIWeightModes } from '@src/interfaces/kpis'
import { getKPIFormInitialValues } from '@src/pages/Forms/KpiForm/General'
import AssignKPIsForm from '@src/pages/Forms/AssignKPIs/AssignKPIsForm'
import { EntityTypes } from '@src/constants/api'
import ApproveGoalsWidget from '@src/pages/Forms/ProbationOverview/ProbationGoals/ApproveGoalsWidget'
import { ProbationCheckpoints } from '@src/interfaces/probationReview'
import { Statuses } from '@src/interfaces'

type Props = {
  data: EmployeeInterface
  cycleId: string
  checkpoints?: ProbationCheckpoints
  refetch: () => Promise<any>
}

const ProbationKPI = ({ data, cycleId, checkpoints, refetch }: Props) => {
  const locationState: KPIFormLocationState = {
    initialValues: {
      ...getKPIFormInitialValues(
        {
          id: data.id,
          name: data.full_name,
          team: {
            // @ts-ignore FIXME: REVPI-19 support optional team value
            id: data.team.id,
            // @ts-ignore FIXME: REVPI-19 support optional team value
            name: data.team.name,
          },
        },
        { is_employee: true },
      ),
    },
    probationId: cycleId,
    backUrl: getLocationPathnameWithoutWorkspace() + window.location.search,
  }
  const handleNewRow = () => {
    navigateTo(pathToUrl(ROUTES.FORMS.KPI.GENERAL), locationState)
  }

  if (!cycleId) {
    return null
  }

  const checkpoint = checkpoints?.checkpoints?.[0]
  const isPendingApproval = checkpoint?.goals?.status === Statuses.pending_approval

  return (
    <>
      {isPendingApproval && checkpoint && (
        <ApproveGoalsWidget
          variant="Probation"
          mb="s-16"
          employee={data}
          refetch={refetch}
          checkpoint={checkpoint}
        />
      )}
      <AssignKPIsForm
        id={String(data.id)}
        noNotification
        probationCycleId={cycleId}
        entityType={EntityTypes.employee}
        defaultWeightMode={KPIWeightModes.manual}
        onAdd={handleNewRow}
        noSwitchMode
        employeeData={data}
      />
    </>
  )
}

export default ProbationKPI
